import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiSpe075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48.2 11.4q-4.25-1.3-6.6-2.6-3.95-2.25-6.1-4.75-1.35-1.6-2.65-4.05v.05Q32.6.6 32.1 3.2q-.4 2-.5 3.4-.05.65-.05 1.15 0 3.75 1.45 7.4.75 1.85 1.45 3.05l1.6 1.95H36l.1.1q-1.55 1.15-3.9 3.1-2.45 1.95-7.1 3.6-3.2 1.15-8.6 2.45h-2.55q-1.85 0-6.7-1.3 2.1 2.25 3.8 3.4h-.45q-3.35 0-6-1.05-.45-.25-4.6-2.35l.35.95q.65 2.1 3.5 5.7 2.85 3.45 6.4 5.55 3.65 2 6.55 2.85 3.35 1.7 4.05 2.15.45.25-.05.1 0 .05.05.1h.1q.15-.05.4-.05.35.25.9.4.5.15 1.7.45.05 0 4.3 1.8 1.15.5 2.5 2.05 1.35 1.55 1.35 2.55 0 2-.65 4.3-.55 1.85-1.25 2.85-.35.4-.65.7-.3.25-.8.6l-1.75 1q-1.45.8-1.75 1.2h-.15q-3.9.9-7.75 2.9l-.05.05q-1.1.45-2.1 1.2-2.3 1.45-4.15 3.75-2.2 2.65-2.7 4.65 5.55-1.75 5.6-1.85Q17.7 73 20.7 73q2.1 0 4.15.4.75.2 1.9.5l.2.1q.1.1.3.2 0-.05.05-.05.65.35 1.8.9 2.05.65 6.95.65l1.65-.15q3.75 0 6.4-4.75v-.05q-.05-.1 0-.15v-.05q.05-.55.15-1.05.25-1.45.75-2.9l.3-.8q0-.05.05-.05l-.4-2.85q0-.9.45-1.2.25-.15.75-.3l1.95-.95q.9-1.4 2-2.85.9-1.2 1.85-2.35v-.05q.9-4.5 1.35-5.25.75-1.35 3.25-1.35.85 0 1.45.3.05.05.15.05h.6v.1q.05-.05.15-.1 1.9-1.4 4-2.5 5.45-2.9 10.05-3.85h.1v-.2q.85-7.6-1.55-11.8 0-.05-.05-.1v.05q-.002-.052-.05-.1 0-.1-.05-.25-.05-.2-.15-.45-.95-2.75-3-7.2-3-6.25-9.35-8.05-6.4-1.8-10.65-3.1z"
                    id="HaiSpe075__Symbol_214_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M25.4 54.95q-.25-.1-.45-.2Q13.7 50.2 0 58.05q5.55-1.75 5.6-1.85 3.75-1.05 6.75-1.05 2.1 0 4.15.4.75.2 1.9.5l.2.1q.1.1.3.2 0-.05.05-.05.65.35 1.8.9 2.05.65 6.95.65l1.65-.15-4.2-2.75h.25M17 24.75q-3.1.1-4.7.3-1.75.1-2.9.15-1 .05-.95.1 3.35 1.7 4.05 2.15.45.25-.05.1 0 .05.05.1h.1q.15-.05.4-.05.35.25.9.4.5.15 1.7.45.05 0 4.3 1.8 1.15.5 2.5 2.05 1.35 1.55 1.35 2.55 0 2-.65 4.3Q22.55 41 21.85 42h2.05q10.9 0 10.9-5.3v-.55q.002.052.05.1-.05-.1-.05-.2l-.05-.05q-2.3-4-4.35-6.5-2.15-2.7-6.2-3.85-4.15-1.15-7.2-.9m25.1 1.65q-.15.65 0 1.6v-.65-.95m8.05-4.55q.05-.45.05-.85v-1.7q-.45-10.95-5-14.25-4.55-3.4-9.1-4.2-6.85-1.4-10-.5l1.6 1.95h-.05l.1.1q2.9 3.85 4.95 4.45 2.1.6 5.95 2.4 1.9.9 4.35 3.2 1.75 1.7 2.9 3.2 2.5 3.3 3.25 4.45.4.55.7 1.1-.05-.25-.05-.45.05.25.1.55.05.55.15 1.2.05-.35.1-.65z"
                    id="HaiSpe075__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M38.55 62.95h-2.3q-.65-.05-2.3.55-1.8.65-1.9.85-2.15 1.1-5.15 3.7-3.05 2.6-6.7 10L21.45 77q2.85-2.95 4.35-4.05 1.55-1.1 5.5-2.85-3.4 1.95-5.4 6.4-2 4.4-4 5.95Q20 84 18.75 84.3q-1.15.35-1.9.85.05.3 4.5-.4h.2q.15-.05.35-.05h.15q2.3-.3 4.4-.7.15-.1.35-.1Q42.7 80 47.7 64.2q.85-1.85 1.2-3.3-.05-.1 0-.15v-.05q-2.85 1.55-5.35 1.9-2.65.3-5 .35m11.25-16.7q-.7-.85-1.9-2.25.05.15.1.25-.4-.5-.85-1.05l-.45-.45q-.05-.05-.1-.2-1.05-1.25-1.95-2-3.9-3.5-8.2-4.6-2.05-.45-4.15-.45h-4.1q-1.45 0-1.75.05-.4.05-.7.1h-.1q-.05-.05-.05-.1-1.4.1-2.4.35-1.9.4-5.35 1.75-.95.35-5.6 2.85-2.55 1.35-6.4 1.7-.25.05-.55.05H4.2q-2.15.1-4.2.1 2.5 1.5 6.65 4.15.85.5 3.7 1.35-.3-.1-.65-.25 2.95.85 6.45.95 3.4.1 8.75-.7-1.55.75-3.15 1.35-1.55.55-6.85 1.4 3.9.95 6.1 1.3 2.05.45 3.5.7 1.4.25 3.1.55 1.25.2 2.3.35 5.991-1.448 11.9-.25l-.05-.1q.7.2 1.45.35l.45.1q.2.1.5.15 1 .25 2 .6 1.8.5 2.95 1.1.25.15.7.35.15.1.3.15 1-2.55 2.8-5.35-1.15-1.9-2.3-3.35-.15-.25-.8-1m-6.25-35.9q-1.4 0-2.7-.05-15.2-.75-22.2-10.3h-.25v1.85q.55 6.45 3.45 9.55 1.7 1.8 3.2 2.3-2.95-.35-5.2-1.65-2.9-1.75-3.3-1.9l-.1-.1q.05.05.4 1.7.35 1.75 2.1 4.25l1.1 1.85-.35-.3q.65.9 1.55 2.05 1.15-.15 3.6-.4 3.4-.35 6.45-.35 12.85 0 18.5 5.45.7.7 1.3 1.4.2.25.3.45 2.55 3.65 3.85 11.65V36q-.15-.95 0-1.6V36q.45 3 1.5 9.4 3.2-3.6 6.8-6.2-.1-1.8-.2-4.35-.3-3.95-.4-6.1-.15-.75-.35-1.55-.05-.25-.1-.6-1.45-5.3-4.85-9.35-5.65-6.95-14.1-6.9m38.1 22.25q1.15.15 4.2.6 1.6-6.45-.8-12.9-1.2-2.75-4.85-5.85 1.05 2.9 0 5.8 0-.6-.6-2.2-1.25-3.5-5.85-8.85 1.05 2.1 1.85 6 .85 3.95.45 4.75l.1.4q.05.25.1.35v-.05q.05.05.05.15V20.95q.6 3.3.8 4.75.2 1.65.35 3.3.2 1.55.3 3.6-.05.05 0 .15.4-.1.6-.1.15-.05 1.2-.15 1.05-.05 2.1.1z"
                    id="HaiSpe075__Symbol_215_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.45 34.65q-4.4.3-11.45-.55 3.9.95 6.1 1.3 2.05.45 3.5.7 1.4.25 3.1.55 1.25.2 2.3.35 5.991-1.448 11.9-.25l-.05-.1q-.55-.3-.85-.35-.35 0-1.65-.4-1.35-.45-5.05-1-3.55-.6-7.85-.25m27.8-24.8q-1.15-3.7-4.8-6.2-3.6-2.55-9-3.25Q20-.25 13.75.2 7.4.55 5.15 1.35l-.35-.3q.65.9 1.55 2.05 1.15-.15 3.6-.4 3.4-.35 6.45-.35 12.85 0 18.5 5.45.7.7 1.3 1.4.2.25.3.45 2.55 3.65 3.85 11.65v-1.8q-.15-.95 0-1.6-.1-4.7-1.1-8.05z"
                    id="HaiSpe075__Symbol_217_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe075__Symbol_214_0_Layer0_0_FILL"
                transform="translate(147.3 21.75)"
                id="HaiSpe075__col1n"
            />
            <use
                xlinkHref="#HaiSpe075__Symbol_216_0_Layer0_0_FILL"
                transform="translate(155.65 39.6)"
                id="HaiSpe075__col1d"
            />
            <use
                xlinkHref="#HaiSpe075__Symbol_215_0_Layer0_0_FILL"
                transform="translate(142.5 31.6)"
                id="HaiSpe075__col2n"
            />
            <use
                xlinkHref="#HaiSpe075__Symbol_217_0_Layer0_0_FILL"
                transform="translate(157.4 48.1)"
                id="HaiSpe075__col2d"
            />
        </g></g>
    )
}
export default HaiSpe075
