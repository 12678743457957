import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E4B670",
    "col1n": "#E7D4B6",
    "col2d": "#435125",
    "col2n": "#617433"
}
function AccMor101({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.05 4.1q-.1 0-.15.05v-.2l-6.15 1 6.1-2.4-.5-2.55q-.6.15-1.15.3-19.65 4-40.45 6.05-22.9 1.75-47.35 0Q23.25 4.5 4.1.7L2.65.4Q1.95.2 1.2.05L.75 2.3H.8v.05l-.05.25q2.9.9 4.6 1.4l3.5 1.35L1.6 4.2Q1.15 4.1.65 4q-.1-.05-.2-.05L0 6.15q2.85.65 9.3 2.55 6.45 1.9 12.7 2.65 6.2.75 9.55 1.25 3.35.5 9.5 1.25 2.05.25 3.35.35 1.3.05 12.9.8 11.55.7 36.65-1.7l6.8-.8q4.1-.5 8-1.05 8.2-1.1 14.5-2.85 6.3-1.75 9.1-2.45h.1l-.35-2.05h-.05z"
                    id="AccMor101__Symbol_207_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M131.4 1.45L131.1 0q-.6.15-1.15.3-19.65 4-40.45 6.05-22.9 1.75-47.35 0Q23 4.5 3.85.7L2.4.4Q1.7.2.95.05L.65 1.5q2.25.45 3.95.85 10.5 2.4 21 3.95l1.2.2q.4.05.85.1 6.05.85 12.05 1.45 5.55.55 11.15.85 4.75.3 9.55.4h1.1q.3 0 .6.05.15-.05.3 0h2.4l-.05.05h1.8l1.5-.05h3.1q.35-.05.7 0l.4-.05h.8L83 8.85q1.05-.1 2.1-.15l1.1-.1q2.95-.2 5.9-.5.25-.05.5-.05l.9-.1q.4-.05.85-.1 6.1-.65 12.2-1.55 10.4-1.55 20.75-3.95 1.7-.35 4.1-.9m.4 2.65q-.1 0-.15.05v-.2L94.5 9.8l-26.35 2.1h-1.8l-33.9-2.85L1.35 4.2Q.9 4.1.4 4q-.1-.05-.2-.05L0 5.05q.25.1.5.15.1 0 .15.05 2.25.45 3.95.85 10.5 2.4 21 3.95.55.1 1.1.15 1.15.2 2.4.35 5.3.75 10.6 1.25 5.55.55 11.1.85 4.85.3 9.75.4h.85q.2 0 .4.05h.3q.15-.05.3 0h2.7l.05.05h1.4l1.5-.05h4.05q4.95-.15 9.9-.45l1-.05 1.9-.1.9-.1q3.4-.25 6.8-.6.45-.05.9-.05.1-.05.3-.05h.15q.2-.05.4-.05 6.1-.7 12.2-1.6 10.4-1.55 20.75-3.95 1.7-.35 4.1-.9.1 0 .2-.05.2-.1.4-.1l-.15-.95h-.05z"
                    id="AccMor101__Symbol_208_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M132.15 5.3v-.1q0-.05.1.05v.05q0 .05.05.15h.05l-.25-1.55q-2.5.6-4.3.95-10.35 2.4-20.75 3.95-6.1.9-12.2 1.6-.2 0-.4.05h-.15q-.2 0-.3.05-.45 0-.9.05-3.4.35-6.8.6-8.85.75-17.75.85V9.3q6.95-.05 13.95-.5l2-.1 8.6-.75q.85-.1 1.75-.2 6.1-.7 12.2-1.6 10.4-1.55 20.75-3.95 1.65-.35 3.8-.85L131.35 0h-.1q-1.95.45-3.45.75-16.45 3.8-32.95 5.55-.9.05-1.75.15l-8.6.75q-1 0-2 .1-6.95.4-13.95.5h-.05q-.7-.05-1.4 0h-2.05q-5.75-.05-11.55-.35-.85-.05-1.65-.1l-11.65-.9Q22.65 4.75 5.1.75 3.7.45 1.9.05h-.1L1.65.6H1.6l-.15.8q2.1.45 3.65.8 10.5 2.4 21 3.95.5.1 1.05.15.5.1 1 .15 7 1 13.95 1.65l1.15.1q2.45.2 4.9.35l3.7.3q.8.05 1.65.1 5.75.3 11.55.35h2V12q-9.85 0-19.8-.85-1.35-.1-2.65-.2l-4.4-.4q-4.95-.5-9.9-1.15l-4.2-.6q-10.5-1.55-21-3.95-1.75-.4-4.1-.9L.7 5.3l4.4.95q10.5 2.4 21 3.95.55.1 1.05.15.7.1 1.35.2 5.6.8 11.15 1.35 7.8.8 15.65 1.1l5.35.2h1.4q.3 0 .55.05.15-.05.3 0h4.15v2.35H62.9q-.15-.05-.3 0-.25-.05-.55-.05h-.15l-7.1-.2q-7.55-.35-15.15-1.1-4.65-.45-9.35-1.1l-4.2-.6Q15.6 11 5.1 8.6q-2-.45-4.85-1.1L0 8.8q3 .7 5.1 1.15 10.5 2.4 21 3.95.5.1 1.05.15.65.1 1.35.2 5.6.8 11.2 1.35 2.8.3 5.65.5 10.85.85 21.7.85h1.5q9.75-.1 19.55-.85 3.35-.25 6.75-.6 6.1-.7 12.2-1.6 10.4-1.55 20.75-3.95 2-.4 4.8-1.1.15-.05.35-.1l-.25-1.25h-.1q-2.8.7-4.8 1.1-10.35 2.4-20.75 3.95-6.1.9-12.2 1.6-11.55 1.2-23.1 1.45h-3.2v-2.35H71.95q.5-.05 1.05-.05 10.95-.25 21.85-1.4 6.1-.7 12.2-1.6 10.4-1.55 20.75-3.95 1.85-.35 4.35-.95z"
                    id="AccMor101__Symbol_209_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M132.95 8.75l-.25-1.25h-.1q-2.8.7-4.8 1.1-10.35 2.4-20.75 3.95-6.1.9-12.2 1.6-11.55 1.2-23.1 1.45h-3.2V9.3q6.95-.05 13.95-.5l2-.1 8.6-.75q.85-.1 1.75-.2 6.1-.7 12.2-1.6 10.4-1.55 20.75-3.95 1.65-.35 3.8-.85L131.35 0h-.1q-1.95.45-3.45.75-16.45 3.8-32.95 5.55-.9.05-1.75.15l-8.6.75q-1 0-2 .1-6.95.4-13.95.5h-.05q-.7-.05-1.4 0h-2.05q-5.75-.05-11.55-.35-.85-.05-1.65-.1l-11.65-.9Q22.65 4.75 5.1.75 3.7.45 1.9.05h-.1L1.65.6H1.6l-.15.8q2.1.45 3.65.8 10.5 2.4 21 3.95.5.1 1.05.15.5.1 1 .15 7 1 13.95 1.65l1.15.1q2.45.2 4.9.35l3.7.3q.8.05 1.65.1 5.75.3 11.55.35h2v6.3H62.9q-.15-.05-.3 0-.25-.05-.55-.05h-.15l-7.1-.2q-7.55-.35-15.15-1.1-4.65-.45-9.35-1.1l-4.2-.6Q15.6 11 5.1 8.6q-2-.45-4.85-1.1L0 8.8q3 .7 5.1 1.15 10.5 2.4 21 3.95.5.1 1.05.15.65.1 1.35.2 5.6.8 11.2 1.35 2.8.3 5.65.5 10.85.85 21.7.85h1.5q9.75-.1 19.55-.85 3.35-.25 6.75-.6 6.1-.7 12.2-1.6 10.4-1.55 20.75-3.95 2-.4 4.8-1.1.15-.05.35-.1z"
                    id="AccMor101__Symbol_210_0_Layer0_0_FILL"
                />
                <path
                    fill="#999"
                    d="M193.1 308.75q-.116.025-.25 0h-1.6q-.1.025-.2 0v.05q-1.836.034-3.15 1.3-1.3 1.35-1.3 3.3 0 1.9 1.3 3.2.983.983 2.25 1.25.483.1 1 .1h.1q.327-.009 1.85 0 .548-.003 1.25 0-.16-.006-.35-.05h-.25q-.077-.007-.2-.05-.03.01-.1 0h.1l.9.1h.1v-1.4h-3q-.35-.01-.7-.1-.956-.206-1.75-1-.7-.7-1.05-2.65l-.1.2q.195-.956.95-1.75.762-.693 1.7-.9.232-.054.45-.1v.05q.4-.002.8 0H194.55v-1.55h-.05q-.7.024-1.4 0z"
                    id="AccMor101__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor101__Symbol_207_0_Layer0_0_FILL"
                transform="translate(126.25 302.35)"
                id="AccMor101__col1n"
            />
            <use
                xlinkHref="#AccMor101__Symbol_208_0_Layer0_0_FILL"
                transform="translate(126.5 302.35)"
                id="AccMor101__col1d"
            />
            <use
                xlinkHref="#AccMor101__Symbol_209_0_Layer0_0_FILL"
                transform="translate(126 301)"
                id="AccMor101__col2n"
            />
            <use
                xlinkHref="#AccMor101__Symbol_210_0_Layer0_0_FILL"
                transform="translate(126 301)"
                id="AccMor101__col2d"
            />
            <use xlinkHref="#AccMor101__Layer1_0_FILL" />
        </g></g>
    )
}
export default AccMor101
