import { AssetSvgProps } from "../../../shared/assets"

function PlaInd012({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#444"
                    d="M63.55 116.7H55.2L2.9 130.4l7.25 56.7L0 206.9v30.15l4.3-7.75h.2q-.013-.098-.05-.2l8.95-16.3 1.75 13.3q-.22.246-.45.45l.5-.1L16.55 237h8.3l21.8-13.2v.05q.05.15 0 .25.4-.15 1.75-.1h.6l.95-.65q-2.233.044-2.95.2l28.15-17.1-11.6-89.75z"
                    id="PlaInd012__Layer7_2_FILL"
                />
                <path
                    d="M210.8 318.4q-.05-.35-.15-.65-.4-1.8-1.25-2.95h-9.25l-.2 16.2h9.35q.55-.75.95-1.75.6-1.8.6-6.45l.1-.8q.15-1.85-.15-3.6m-6.85.1h.5l-.5.2v-.2m-57.4 1.75q.2-.6.2-1.35v-.4q-.05-.5-.2-.9v-2.8h-9.25q-.15.45-.25.85-.35.95-.6 2.1-.8 3.6-.8 8.4l.05.2q.15.6.75 2.9.6 1.05 1.5 1.75h8.3q.15-.15.3-.25v-10.5z"
                    id="PlaInd012__Layer7_3_FILL"
                />
                <path
                    fill="#C4C4C4"
                    d="M206.4 224.2q-1.7-.3-3.15-2.15h-.1v28.25h3.25v-26.1m-67 87.4q-1.25 1.1-2.1 3.2h72.1q-.05-.1-.05-.15-.15-.35-.55-.65-.45-.35-1.2-.7-.95-.45-2.3-.8-.15-.5-.55-.9h-31.1v-46h-5.25v23.5q.3 4.25 0 7.2-.1.65-.15 1.25-.3 2.85-.25 4.65-.05.25 0 3.85 0 .85.05 1.95h.15l.2.7v2.9h-29z"
                    id="PlaInd012__Layer6_1_FILL"
                />
                <path
                    d="M385 32.7V32h-50.45v258.3H385V289h-49.75V32.7H385z"
                    id="PlaInd012__Layer5_1_FILL"
                />
                <path
                    fill="#806630"
                    d="M5.95 289.1h30.7v-2.85H5.95v2.85m78.45 0v-2.85H59.15v2.85H84.4m21.6-2.85v2.85h62.4v-2.85H106m222.45 2.85v-2.85l-146.3.1v2.75h146.3z"
                    id="PlaInd012__Layer5_2_FILL"
                />
                <g id="PlaInd012__Layer8_0_FILL">
                    <path
                        fill="#FEF39A"
                        d="M385 290.5V0H0v290.5h385m-202.45-4.25h3.4l-3.4.1v-.1z"
                    />
                    <path fill="#A2BFA1" d="M385 385v-94.5H0V385h385z" />
                </g>
                <g id="PlaInd012__Layer7_0_FILL">
                    <path
                        fill="#999"
                        d="M203.95 318.7l.5-.2h-.5v.2m-64.55-7.1q-1.25 1.1-2.1 3.2 6.228 1.875 9.25 2.45v.35q.15.4.2.9h53.2v-.3q2.972-.696 9.45-3.4-.05-.1-.05-.15-.15-.35-.55-.65-.45-.35-1.2-.7-.95-.45-2.3-.8-.15-.5-.55-.9h-22.6v-47.05q-.1-.2-.2-.3v-1.45q2.101-.048 4.2-.15l24.9-10.9q.018-.447 0-.9.05-.35.1-.55h-.3V224l-.2-1.8q.431-.531.85-1.2h-9q.38.574.75 1.05h-.1v28.25l-38.1 12.45q1.672.026 3.35.05V289.1q.3 4.25 0 7.2-.1.65-.15 1.25-.3 2.85-.25 4.65-.05.25 0 3.85 0 .85.05 1.95h.15l.2.7v2.9h-29m42.35-47.85v.2q-.05-.1-.15-.2h.15z"
                    />
                    <path
                        fill="#C19E4F"
                        d="M106 287.75v9.8h62.25q.05-.6.15-1.25.3-2.95 0-7.2v-1.35H106m-46.85 9.8H84.4v-9.8H59.15v9.8m-22.5-9.8H5.95v9.8h30.7v-9.8z"
                    />
                    <path
                        fill="#FEF39A"
                        d="M181.75 263.95v-.2h-.15q.1.1.15.2m-107-31.5l.1-.2-.25.15.15.05z"
                    />
                    <path
                        fill="#444"
                        d="M63.3 284.4h.85q-.438-.09-.85-.2v.2M0 259.6v1.4q.13-.062.25-.15-.117-.57-.25-1.25m63.3 15.2q.412-.197.85-.4h-.85v.4z"
                    />
                    <path
                        fill="#836549"
                        d="M107.6 222.7q-.15-.12-.3-.25-7.64-.046-12.25.25h-16.9q-.6-.108-.9-.2H70.6q-3.552.093-8 .2H48.45l.3-.2H14.7l.05.2H8l.1-.2H0v37.1q.133.68.25 1.25-.12.088-.25.15v49.7q2.975-3.574 5.95 0-4.286-6.575 0-13.15V284.4q15.35-6.67 30.7 0v13.15q6.99 30.95 0 61.9 7.76-10.193 14.55 0v-.05l7.95-7.95V284.4h4.15v-.2q.412.11.85.2 8.524-14.38 20.25-18.35 6.468 10.85 0 21.7V310.7q6.65-4.01 13.3 0v-.05l8.3-8.7v-55.6q6.81-12.386 15.55-14.15-4.44-4.749-.05-9.5h-13.9m-32.75 9.55l-.1.2-.15-.05.25-.15m-10.7 42.15q-.438.203-.85.4v-.4h.85z"
                    />
                    <path
                        fill="#B49348"
                        d="M328.45 297.55v-9.8l-146.3.1v9.7h146.3z"
                    />
                    <path
                        fill="#55311A"
                        d="M385 26.4h-56.55V297.55H385V26.4z"
                    />
                </g>
                <g id="PlaInd012__Layer7_1_FILL">
                    <path
                        fill="#595959"
                        d="M30.85 253.8h.05q7.5 10.716 32.15 10l36.6-30.8-25-8.75q-24.549 11.243-43.8 29.55m43.9-21.35l-.15-.05.25-.15-.1.2m32.35-10.15q-2.2-1.95-4.8-1.95-2.45 0-4.7.8-1.9.6-2.55 1.55-.45.5-.45 1.2 0 1.2.2 2.3.706-.352 2.8-.95 2.132-.544 6.55 1.1 4.468 1.694 4.75 1.5.294-.148 1.15.75.95-.65.95-1.6 0-1.4-1.45-3-.25-.2-.4-.35-.7-.3-1.55-.95l-.5-.4z"
                    />
                    <path
                        fill="#444"
                        d="M68.6 224.25l-24.95 16.3H0v10.4h27.65l-4.5 2.9v6.1q-.107.005-.25 0 .148.513.25 1.05v4.8h39.9v-2q-24.65.716-32.15-10h-.05q19.251-18.307 43.8-29.55H68.6m29 1q-2.094.598-2.8.95l-.15.55q.117-.02.25-.05.2 1.25.7 2.25 7-2.475 14 0 .15-.15.45-.35-.856-.898-1.15-.75-.282.194-4.75-1.5-4.418-1.644-6.55-1.1z"
                    />
                    <path
                        fill="#333"
                        d="M99.65 235.65V233l-36.6 30.8v2l36.6-30.15z"
                    />
                </g>
                <g id="PlaInd012__Layer6_0_FILL">
                    <path
                        fill="#423024"
                        d="M51.2 284.4H36.65v75.05H51.2V284.4m-45.25 0H0v26.3h5.95v-26.3m91.75-30.5l-13.3 12.15v44.65h13.3v-56.8z"
                    />
                    <path
                        fill="#574031"
                        d="M0 284.4H63.3v-10H0v10m121.55-52.2v-9.55l-57.4 51.75v10l20.25-18.35 13.3-12.15 23.85-21.7z"
                    />
                    <path
                        fill="#2B2B2B"
                        d="M63.55 116.7H55.2L2.9 130.4h9v.05l51.65-13.75z"
                    />
                </g>
                <g id="PlaInd012__Layer5_0_FILL">
                    <path
                        fill="#BBB"
                        d="M44 254.2l17.95 6.35 25.7-20.8-15.55-5.65L44 254.2m26.35-20.7l-2.75-1-26.5 17.95 3.3 1 25.95-17.95M90 237.85l4.6-3.75-15.7-5.5-4.05 3.65-.1.2 15.25 5.4z"
                    />
                    <path d="M64.15 284.4v-10h-.85v10h.85m-33.3-30.6l-7.7.05v6.1q-6.85.3-19.2-.15-2.245-.116-3.95-.2v1.4q5.638.2 14.2.2 5.8 0 8.95-.2v4.8h39.9v-2l-32.15-10h-.05m109.75 7.6q.4.1.9.2 13.2 1 26.9 1.2 6.6.1 13.35 0 .1-.05.2 0 8.8-.2 17.85-.7.1-.05.3-.05.05-.05.15-.05 11.2-.9 10.8-11.15.05-.35.1-.55H141.8q-2.9 1.1-4.05 2.1-1.5 1.2-1.5 3.5 0 2.7 2.45 5.05.4.1.9.25.45.1 1 .2m-45.8-35.05q-.35-.1-1.3-.1-2.25-.2-2.65-.3l-2.15-.6q-.35-.15-.7-.25-1.85-.65-3.4-1-3.5-.95-6.45-1.4-1.95-.35-3.6-.5-1.55-.15-2.9-.1-4.1-.05-9.05.6h-.05q-.225.006-.4.05-2.6.2-3.8.4-.15-.1-.5-.05.65.2-4.6.25-5.1-.05-6.25.2-.4.1-.4.2l.05.05v.05q.05.15 0 .25.4-.15 1.75-.1 7.15 0 9.2-.35l.05.05q1 0 5.55-.55 2.55-.35 4.95-.45 1.75-.15 3.45-.1 1.1-.05 2.2.05.4 0 .85.05 5.1.35 10.05 2 1.45.5 2.75.9 4.3 1.2 6.75 1.2.25 0 .7-.1.2 1.25.7 2.25h14q.15-.15.45-.35-.85-.9-2.2-1.9-3.55-2.55-6.7-2.55-2.1-.05-3.75.8-1.9.9-2.6 1.25v.15M11.9 130.4h-9l12.25 95.7q-8.4 1.95-10.7 3-.2.05-.25.1 0 .05.1.1h.35l10.55-2.85L16.55 237h8.3L11.9 130.45v-.05m2.1 3.5v.05l12.55 92.5 45.7-25.9-9.75-79.35L14 133.9m183.1 39.25q-.85 6.6-.8 14.3-.05 7.75.8 14.4.75 6.3 2.35 11.65 1.7 5.85 3.8 8.55 1.45 1.85 3.15 2.15.25.05.6.05.3 0 .65-.05 1.6-.3 3-2 2.15-2.65 3.9-8.7 3.15-10.8 3.2-26.05-.05-15.25-3.2-26-3.1-10.75-7.55-10.75-1.1 0-2.1.65-3.1 1.9-5.45 10.1-1.6 5.25-2.35 11.7z" />
                    <path
                        fill="#CCC"
                        d="M29.25 228.85q.3.6 1.15.6 1.25 0 1.3-1.3l-.1-.3q-.3-.95-1.2-.95-.7 0-1 .35-.25.3-.25.9 0 .45.1.7z"
                    />
                    <path
                        fill="#2B2B2B"
                        d="M74.65 224.25H68.6l-45.45 29.6 7.7-.05 43.8-29.55z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaInd012__Layer8_0_FILL" />
            <use xlinkHref="#PlaInd012__Layer7_0_FILL" />
            <use xlinkHref="#PlaInd012__Layer7_1_FILL" />
            <use xlinkHref="#PlaInd012__Layer7_2_FILL" />
            <use xlinkHref="#PlaInd012__Layer7_3_FILL" />
            <use xlinkHref="#PlaInd012__Layer6_0_FILL" />
            <use xlinkHref="#PlaInd012__Layer6_1_FILL" />
            <use xlinkHref="#PlaInd012__Layer5_0_FILL" />
            <use xlinkHref="#PlaInd012__Layer5_1_FILL" />
            <use xlinkHref="#PlaInd012__Layer5_2_FILL" />
        </g></g>
    )
}

export default PlaInd012
