import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#1F9900",
    "col1n": "#00B600",
    "col1s": "#034803",
    "col2n": "#008300"
}

function AccHat012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M217.5 9.85q-1.05-4.25-3.55-6.65-1.6-1.55-3.75-2.4l-.6-.2q-.9-.25-1.75-.45H196.5q-5.05.05-10.15.05h-2.05q-9.1-.15-18.15-.05-6.35.05-12.65-.1-1.25-.05-2.45-.05H96.4q-1.25 0-2.5.05-6.3.15-12.65.1Q78.9.1 74.6.15L64.55.2H59L39.55.15q-.4.1-.75.2-.8.2-1.6.45-2.35.9-3.95 2.65-2.35 2.4-3.35 6.4h-.05q0 .2-.1.4l-.2.6V73.8h.05v3.6q-.1.05-.15.2-.05.1-.1.25l-.5.5v.1h-.1l-1.3 1.3q-2.35 2.45-4.5 5Q8.55 101.8 3.6 123.1.45 133 .05 140.75q-.5 9.8 3.45 16 .05.05.1.15 2.25 2.4 5.65 3.2.05 0 .05.05.2 0 .4.05 2.55-.1 4.95-.9 1.25-.4 2.45-.95 2.15-1 4.3-2.6 4.7-3.55 9-9.95 1.85-2.75 3.6-6 3.25-6.1 6.25-14 .25-.65.5-1.35 3.95-10.75 7.4-24.7l.8-3.5q.6-2.9 1.1-5.9.1-.65.2-1.25.05-.35.1-.65v-1.1q2.75-.7 5.5-1.35 34.45-8.2 69.1-8 28.45.1 57 5.9 5.15 1.05 10.35 2.3 2.15.5 4.3 1.05l.4.05v1q.05.3.1.65.1.6.2 1.25h.05q.5 3.05 1.1 6.05.4 1.75.85 3.5 3.4 13.95 7.4 24.7.25.7.5 1.35 3.2 8.55 6.8 15.05 1.5 2.65 3.05 4.95 3.9 5.85 8.15 9.3 3.25 2.65 6.75 3.95.4.1.8.25 2.4.8 4.95.9.25-.05.45-.1.45-.1.9-.25 2.75-.85 4.7-2.9.4-.4.75-.9l.1-.1q1.9-2.3 2.4-6.95.8-7.8-2.4-22.25-4.55-23.5-20.2-42.05v-.1q-2.15-2.55-4.5-5-.6-.6-1.85-2.05l-.1-.1q-.05-.1-.15-.15v-3.5-7.6-6.1q.05-25.3.05-49.25l-.2-.6q-.1-.2-.1-.4h-.05m.55 67.85q-.05-.1-.05-.15l.25.4q-.1-.15-.2-.25z"
                    id="AccHat012__Symbol_230_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M217.5 9.85q-1.05-4.25-3.55-6.65-1.6-1.55-3.75-2.4l-.6-.2q-.9-.25-1.75-.45H196.5q-5.05.05-10.15.05h-2.05q-9.1-.15-18.15-.05-6.35.05-12.65-.1-1.25-.05-2.45-.05H96.4q-1.25 0-2.5.05-6.3.15-12.65.1Q78.9.1 74.6.15L64.55.2H59L39.55.15q-.4.1-.75.2-.8.2-1.6.45-2.35.9-3.95 2.65-2.35 2.4-3.35 6.4h-.05q0 .2-.1.4l-.2.6V73.8h.05v3.6q-.1.05-.15.2-.05.1-.1.25l-.5.5v.1h-.1l-1.3 1.3q-2.35 2.45-4.5 5Q8.55 101.8 3.6 123.1.45 133 .05 140.75q-.5 9.8 3.45 16 .05.05.1.15 2.25 2.4 5.65 3.2.05 0 .05.05.2 0 .4.05 2.55-.1 4.95-.9 1.25-.4 2.45-.95 2.15-1 4.3-2.6-.6.2-1.15.4l-.9.3q-2 .5-4.05.6-.25-.05-.45-.1-1.9-.45-3.45-1.4-1.2-.75-2.2-1.8-3.35-5.1-3.6-12.7-.3-8.85 3.6-21.1 4.95-21.3 19.35-38.35 2.15-2.55 4.5-5l1.3-1.3h.1v-.1q.25-.2.45-.45.05 0 .05-.05.05-.3.25-.45v-3.6h-.05V68.4v-.5-3.7-6.6V13q.05-.15.05-.3h.05q1-3.95 3.3-6.35 1.6-1.7 3.85-2.6.05-.05.15-.1 1.1-.4 2.25-.6.05-.05.1-.05l13.6.05H69.9L79.95 3q3-.05 5.05 0h1.6q6.35.05 12.65-.1.8-.05 1.6-.05h55.55q1.2 0 2.45.05l4.55.1q4.05.05 8.1 0h3.75q6.5-.05 13.05.05h6.85l6.7-.05h1.35q.35.05.75.2.8.15 1.6.45.75.3 1.5.7 1.6.9 2.8 2.25 2.05 2.35 3 6.1h.05q0 .15.05.3 0 21.45-.05 43.95v17.2q.1.05.15.15.05.05.1.15 1.25 1.4 1.85 2 1.35 1.4 2.65 2.9.95 1 1.85 2.1v.1q15.65 18.55 20.2 42.05 2.7 12.15 2.55 19.6-.15 6.7-2.55 9.6l-.1.1q-2.4 3.1-6.35 4.05-.2.05-.45.1-2.05-.1-3.95-.6l-1-.3q-1.35-.45-2.65-1.05 3.25 2.65 6.75 3.95.4.1.8.25 2.4.8 4.95.9.25-.05.45-.1.45-.1.9-.25 2.75-.85 4.7-2.9.4-.4.75-.9l.1-.1q1.9-2.3 2.4-6.95.8-7.8-2.4-22.25-4.55-23.5-20.2-42.05v-.1q-2.15-2.55-4.5-5-.6-.6-1.95-2.15-.05-.1-.15-.15v-3.5-7.6-6.1q.05-25.3.05-49.25l-.2-.6q-.1-.2-.1-.4h-.05m.5 67.7l.25.4q-.1-.15-.2-.25-.05-.1-.05-.15z"
                    id="AccHat012__Symbol_512_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M163.75 6.75v-2.8q-.4-4.25-5.2-3.9H5.3Q.5-.3.1 3.95v24q-.05.1-.1.15l.1.05.1 8.45v12.8q.3 4.15 4.6 3.55l10.35-1.85q.25-.05.55-.1.25-.05.55-.1 3.35-.8 6.75-1.45l13.6-2.35q5.6-.9 11.25-1.55l8.35-.9q9.05-.8 18.1-1.05h.5q.5-.05 1.05-.05 2.45-.05 4.95-.05h2.25q2.5 0 4.95.05.55 0 1.05.05h.5q9.05.25 18.1 1.05l8.35.9q5.65.65 11.25 1.55l13.6 2.35q3.4.65 6.75 1.45.3.05.55.1.3.05.55.1l10.35 1.85q4.3.6 4.6-3.55v-3.2V40.1v-3.25-.25l.1-8.45.1-.05q-.05-.05-.1-.15v-2.5-5.8-2.3-3.25-7.35z"
                    id="AccHat012__Symbol_57_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat012__Symbol_112_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M12.25.15h-.3Q7.25.7 2.9 2.95q-.8.4-1.55.9-.15.05-.25.15-.45.25-.9.55-.1.05-.2.15V6q1.15-.8 2.35-1.45.25-.15.55-.3Q7.4 1.9 12.25 1.4V.75v-.6m163.7 1.1L175.9.6v.65h.05m11.8 4.35q.2.1.4.25.048 0 .05.05V3.55h-.05v1q-.1-.1-.2-.15-.45-.3-.85-.55-.15-.1-.25-.15-.8-.5-1.6-.9-1.05-.55-2.15-1-3.35-1.4-6.85-1.8h-.35v.35l.05.9q3.65.4 7.15 1.85 1.1.45 2.15 1l.6.3q.95.55 1.9 1.2z" />
                    <path d="M175.9.6l.05.65-.05-.9V.6z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat012__Symbol_230_0_Layer0_0_FILL"
                transform="translate(68.8 54.6)"
                id="AccHat012__col1n"
            />
            <use
                xlinkHref="#AccHat012__Symbol_512_0_Layer0_0_FILL"
                transform="translate(68.8 54.6)"
                id="AccHat012__col1d"
            />
            <use
                xlinkHref="#AccHat012__Symbol_112_0_Layer0_0_FILL"
                transform="translate(98.4 126)"
                id="AccHat012__col1s"
            />
            <use
                xlinkHref="#AccHat012__Symbol_57_0_Layer0_0_FILL"
                transform="translate(110.6 89.9)"
                id="AccHat012__col2n"
            />
        </g></g>
    )
}

export default AccHat012
