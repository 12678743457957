import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1l": "#BFBFBF",
    "col1n": "#999999"
}

function PetPet010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.25 0Q32.3 0 31 1.25q-.25.15-.4.3Q28.95.1 26.35.1 24.4.1 24.2 1.65q-.05.05-.05.1-2.45-.6-9.15-.35-6.75.25-9.35 3.25-2.6 3-3 5.5-.4 1-.5 1.85-.05.45-.05 1.15-2.1.7-2.1 3l.15.9q.35.65 1.15.8.3.3 1 .3.65 0 1.1-.2.2.25.4.4.05.55.2 1.8.05.1.05.25h.05v.05q.15.45.85.45.85 0 1.65-.65.55-.45.7-.8h1.1q1-.2 1.35-.4.25-.25.25-.75 0-.4-.05-.65H10q1.15 0 1.55 1.2.1-.05.25-.1.4-.2.75-.3.6-.15 1-.15.2 0 1.35.55.25.1.5.25.75.3 1.05.3.3 0 1.3-.55.15-.15.35-.2l.3-.2.2-.1q.1-.05.15-.05.9-.45 1.7-.45h.15q.1-.05.35.1.5.2 1.35.95.05 0 .1.05.05 0 .05.05.1 1.65 2.05 1.65.9 0 1.3-.4.15.1.4.25 1.05.6 2.15.6 1.35 0 1.8-.2.3-.15.3-.55t-.1-.55q-.2-.65-.8-1.45l-.1-.1h.1q.85 0 3.25-.65.5-.1.9-.25 1.5.1 3.6.1.45 0 .7-.1.65-.05.8-1.75v-.1q.55-.25.95-.75.5-.7.5-1.45 0-.2-.05-.4 0-.55-.15-1.15-.55-2.15-3.15-5.2-1.15-1.35-2.4-2.3v-.6Q34.95 0 33.25 0M5.05 20.25v.05H4.9q.05-.05.15-.05z"
                    id="PetPet010__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M2.2 14.9q-.05-.65-.05-1.15 0-.35-.05-.6-2.1.7-2.1 3l.15.9q.35.65 1.15.8.3.3 1 .3.65 0 1.1-.2-.25-.3-.45-.65v-.1q-.2-.4-.35-.85-.1-.2-.15-.45l-.1-.3q-.05-.35-.15-.7m18.25 2.95h.15q.1-.05.35.1.5.2 1.35.95-.05-.45-.1-.75V16q-.75-.3-1-1.65-.05-.15-.05-.3-.55 1.2-.85 1.2-.55 0-1.5-1.6-.4-.65-.6-1.05-.25.35-.25 1.8v.1q0 .1.05.35-1.1-.75-1.5-.8-.1-.1-.3-.1-.25 0-.7.6-.4.6-1.1.6-.25 0-.3-.45 0-.45-.5-.45-.2 0-.5.2-.1.35-.25.5-.2.3-.95.3-.25 0-.5-.05h-.15q-.1 0-.2-.05-.55-.2-.95-.65-.4-.4-.5-.85-.05 0-.1.05l-.05 2.35q0 .8.2 1.1 0 .05.05.15.05.15.15.35H10q1.15 0 1.55 1.2.1-.05.25-.1.4-.2.75-.3.6-.15 1-.15.2 0 1.35.55.25.1.5.25.75.3 1.05.3.3 0 1.3-.55.15-.15.35-.2l.3-.2.2-.1q.1-.05.15-.05.9-.45 1.7-.45m7.45.35q.7 0 1.55.05h.1q.85 0 3.25-.65.5-.1.9-.25 1.5.1 3.6.1.45 0 .7-.1.65-.05.8-1.75v-.1l-.4.1q-.1 0-.2.05h-.35q-.15 0-.25-.05v.2l-.1-.1q-.2-.15-.35-.25-.45-.3-.65-.3H36q-2.55-.05-3.95-.7-1.1-.75-1.5-.75h-.2q.1.4.15 1.15 0 .85-2.2.95.1.2.2.55-.05.75-1.95.8-.25 0-.5-.05 0 .35.05.85.65.2 1.8.25m3.6-15.6q.35.1.75.25.15.05.3.15l.6.3q.7.4 1.35.95v-.6Q34.95 0 33.25 0 32.3 0 31 1.25q-.25.15-.4.3Q28.95.1 26.35.1 24.4.1 24.2 1.65q-.05.05-.05.1-.05.05-.05.1-.1.25-.1 1.2v.35q.1.95.7 2.05.2.25.35.5.05.1.15.2.9 1.05 2.2 1.05 1 0 1-.9l2.95-1.8q.15-.1.25-.2.25-.25.25-.75 0-.15-.05-.35-.05-.3-.3-.6z"
                    id="PetPet010__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.95 1.4q-.1 0-.2-.05-.15-.05-.35-.1h-.1q-.05 0-.1.05-.1-.05-.2-.05-.35-.15-.75-.25Q1.1 1 1 .95.7.85.45.7.2.6 0 .4q.05.55.2 1.8.05.1.05.25H.3v.05q.15.45.85.45.85 0 1.65-.65.55-.45.7-.8h-.3q-.05-.05-.25-.1m-1.85.95q.05-.05.15-.05v.05H1.1m25.55.05q0-.4-.1-.55-.2-.65-.8-1.45l-.1-.1Q24.8.25 24.1.25 22.95.2 22.3 0v.7q.05.25.05.6v.2q0 .1-.05.25-.05.2-.15.4L22 2.3q.15.1.4.25 1.05.6 2.15.6 1.35 0 1.8-.2.3-.15.3-.55z"
                    id="PetPet010__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill="#093"
                    d="M390.65 238.75q.4.4.75.8 2.65 2.85 4.3 6.1 2.6 5.25 2.6 11.55 0 10.8-7.65 18.45-.5.5-1 .95-2.9 2.65-6.25 4.25-2.8 1.35-5.85 1.95-5.94 1.9.35 2.9 8.6-1.55 15.1-8.05 8.6-8.6 8.6-20.7 0-12.15-8.6-20.7-8.55-8.6-20.7-8.6-12.1 0-20.7 8.6-8.55 8.55-8.55 20.7 0 12.1 8.55 20.7 5.65 5.6 12.85 7.55 4.852-.675.4-2.9-6.2-1.75-11.05-6.65l-.55-.55q-2.85-2.95-4.5-6.4-2.6-5.2-2.6-11.5 0-10.8 7.65-18.45l.25-.25q2.75-2.65 5.9-4.4 5.5-2.95 12.25-2.95 10.8 0 18.45 7.6z"
                    id="PetPet010__Layer3_2_FILL"
                />
                <path
                    fill="#F60"
                    d="M363.35 292.3v1h15.5l-4.8-38.85h-4.95l-5.75 37.85m7.15-35.55q.5-.55 1.25-.5.7-.05 1.2.5.55.5.55 1.2 0 .75-.55 1.25-.5.55-1.2.55-.75 0-1.25-.55-.55-.5-.5-1.25-.05-.7.5-1.2z"
                    id="PetPet010__Layer3_3_FILL"
                />
                <path
                    fill="#304654"
                    d="M278.55 276.7q-.05 1.3-.05 1.45 0 .15.15.25.1.05.25.05t.25-.1q.1-.15.1-.3-.05-.15 0-1.35.05-.4-.3-.65-.25-.15-.75-.15-1.55 0-1.55 1.5 0 .05.05.35v.4q0 1.25.05 4.75v2.05q0 .15.1.25.15.1.3.1t.25-.1q.1-.1.1-.25-.05-.8-.05-1.5v-.25q-.05-3.3-.05-4.7v-.35-.75q0-.75.8-.75.2 0 .35.05m129.8-65.8q0-.15-.15-.25-.1-.15-.25-.15H281.8q-.15 0-.3.15-.1.1-.1.25v80.45q0 .05.05.05 0 .1.05.2.15.1.3.1.1 0 .2-.1h.05q.1-.1.1-.25v-80.1H407.6v79.15q.25-.35.7-.35l.05-79.15z"
                    id="PetPet010__Layer3_4_FILL"
                />
                <path
                    fill="#999"
                    d="M285.85 282.15q-.3.1-.55.5-.4.55-.4 1.2 0 .9.15 1.2.25.75.85.95.15.1.35.1h.35q.45-.1.8-.6.45-.6.45-1.45t-.45-1.5q-.4-.6-1-.6-.3 0-.55.15v.05z"
                    id="PetPet010__Layer3_5_FILL"
                />
                <path
                    fill="#5797C1"
                    d="M277.4 266.15v-8.95h-12.6v8.95h12.6z"
                    id="PetPet010__Layer3_6_FILL"
                />
                <path
                    fill="#FFF"
                    d="M326.3 280.15q-.2.2-.2.5 0 .35.2.55.25.2.55.2.3 0 .5-.2.25-.2.25-.55 0-.3-.25-.5-.2-.2-.5-.2t-.55.2z"
                    id="PetPet010__Layer3_7_FILL"
                />
                <path
                    fill="#BBDAEE"
                    fillOpacity={0.443}
                    d="M413.05 305.75v-102.5H273.4v102.5h139.65z"
                    id="PetPet010__Layer3_8_FILL"
                />
                <g id="PetPet010__Layer3_0_FILL">
                    <path
                        fill="#BFBFBF"
                        d="M272.85 266.15q-1.825-2.35-3.65 0v3.8q.9 5.05 2.55 7.3t3.45 4.25q.7.75 1.55 1.4-.004-.292 0-.6.495.89.7 1.15 1.8 1.2 3.95 1.85v-.45q.344.324.75.65 1.552.355 3.3.45.288.607.35.05.049-.003.1 0-.055-.018-.1-.05.027-.396-.05-1.35-.197-2.356.1-2.45v-.05q-2-.3-3.7-.9-.406.004-.75 0v-.25q-2.2-.9-3.8-2.3-.1-.05-.2-.15-.374-.224-.7-.4v-.35q-3-3.4-3.6-9.6l-.25-2m8.55-14.05h-16.6v5.1h16.6q.638-2.641 0-5.1m-4-18.6h4q.69-2.725 0-5.45h-16.6v5.45h12.6z"
                    />
                    <path
                        fill="#5797C1"
                        d="M277.4 238.6h-12.6v13.5h12.6v-13.5z"
                    />
                    <path
                        fill="#8DBAD8"
                        d="M264.8 238.6h12.6v-5.1h-12.6v5.1m12.6-10.55v-4.75h-12.6v4.75h12.6z"
                    />
                    <path
                        fill="#304654"
                        d="M275.85 205.35q-.15-.1-.25-.1-.15 0-.3.1-.1.1-.1.25 0 .1.1.25l4.2 4.55q.1.1.25.1t.25-.05q.1-.15.1-.3 0-.1-.05-.25l-4.2-4.55m133.1 4.6q.15 0 .25-.15l2.7-4.2q.1-.1.1-.25-.05-.15-.2-.25-.1-.05-.25-.05-.15.05-.25.15l-2.75 4.2q-.05.15 0 .3 0 .15.1.2.15.1.3.05z"
                    />
                    <path
                        fill="#FCF"
                        d="M333.85 286.1q0-.284-.05-.45.05-.45.05-1h-.05q-.2.7-.9.7-.124 0-.45-.55-.03-.85-.6.1-.6 1-.6 1.75v.2q.025.248.05.65.132.662.5.75h.15q.85 0 1.45-.9.45-.7.45-1.25m-1.9 1.45l-.05-.1.05.05v.05m-7.3-12.25q-.05-.3-.15-.55-.1-.2-.15-.35-.296-.606-1.35-1.1-1.013-.483-1.4-.65-.387-.167-1.2-.2-.804.005-1.1 1.3-.05.15-.1.35v.45q0 .35.15.75.15.35.4.7.05.05.05.1l.4.4q.7.6 1.85.7.05.05.2.05h.35q.9 0 1.5-.6.35-.35.5-.85 0-.1.05-.15v-.35z"
                    />
                    <path fill="#CCC" d="M331.9 287.45l.05.1v-.05l-.05-.05z" />
                    <path d="M325.9 280q-.55.55-.55 1.35t.55 1.3q.6.6 1.35.6.8 0 1.35-.6.55-.5.55-1.3t-.55-1.35q-.55-.55-1.35-.55-.75 0-1.35.55m47.75-23.25q-.15-.2-.3-.35-.65-.7-1.55-.65-1-.05-1.65.65-.7.65-.65 1.55-.05.7.35 1.3.15.1.3.3.65.7 1.65.7.9 0 1.55-.7.7-.65.7-1.6v-.1q-.031-.64-.4-1.1z" />
                    <path
                        fill="#43DE43"
                        d="M395.7 245.65q.015-4.36-4.3-6.1l-19.8 14.9-11.65-20.35q-5.57-1.347-5.9 4.4l14.8 17.8-20.1 12.4q-.59 4.346 4.5 6.4l14.95-14.65.45-2.85 14.75 23.25q3.804.218 6.25-4.25l-15.1-18.3v.15l-.5-.6v.1q0 .95-.7 1.6-.65.7-1.55.7-1 0-1.65-.7-.15-.2-.3-.3-.4-.6-.35-1.3-.05-.9.65-1.55.65-.7 1.65-.65.9-.05 1.55.65.15.15.3.35l22.05-11.1z"
                    />
                    <path
                        fill="#CD971C"
                        d="M410.35 291.25q-.45-1.2-2.05-1.2-.45 0-.7.35-.05 0-.1.05-.8-1.15-1.3-1.15-2.45 1.45-3.1 1.45-.45 0-1.15-.9t-1.4-.9q-.4 0-2.25 1.55-1.9 1.55-2.1 1.55-.25 0-1.35-1.1t-1.7-1.1q-.4 0-1.35.55-.35.2-.65.35-2.1-1.8-3.55-1.8-1.5 0-2.8.55-.2.1-.35.2-1.1-1.2-3.4-1.5-.998-.136-2.55-.15-4.324-.432-14.4-.55-2.4.5-2.8 1.7-.9-.1-1.3-.1-2.2 0-3.7 1.15-.225.181-.45.35-1.23.994-2 1.55-.15-.15-.35-.3-1.037-.912-1.55-1.25-.257-.2-.35-.2-.29 0-.8.2-.125.065-.3.15-.496.24-1.15.65-.9-.05-2.1-.85-.2-.15-.4-.25-.1-.25-.25-.45-.95-1.25-3.6-1.25-3.45 0-4.65 1.65-1.05-.4-3.25-.4-1.3 0-1.35 1.75h-.65q-.3 0-1.65-.55-1.4-.55-1.8-.55-.7 0-2 .65-1.35.7-2.05.7-.3-.05-1.55-.6l-.6-.3q-1.35-.65-2.25-.8-.25-.5-1-.5-.2 0-.45.1-.7.15-1.5.85-.35.3-.55.55-.35 0-1.65-1.1-.05-.05-.1-.05-.85-.75-1.35-.95-.25-.15-.35-.1h-.15q-.8 0-1.7.45-.05 0-.15.05l-.2.1-.3.2q-.2.05-.35.2-1 .55-1.3.55-.3 0-1.05-.3-.25-.15-.5-.25-1.15-.55-1.35-.55-.4 0-1 .15-.35.1-.75.3-.15.05-.25.1-.4-1.2-1.55-1.2h-.15q-1.95.05-3.6 1.4-.15.1-.25.2-.65.55-.95 1-.1 0-.15.05-.45 0-.8.1h-.05q-.95.1-1.3.1h-.1q-.95 0-1.55-.75-.65-.8-1.25-.8-1.35.55-2.45 1.05v.15q-.25.1-.5.25 0-1.45-.95-1.45t-2.3 1.55q-.764.891-1.05 1.35-.02.082-.05.15-.05-.04-.2-.15l-2.05-2q-.2 0-.2-.15.05 1.15-.3 1.15-.4-.25-.45-.25-.2 0-.4.15-.15.2-.3.2-.2-.2-.3-.45-.1.1-.2.1-.15 0-.3-.1-.05-.1-.05-.2-.45.2-1.1.65-1.05.7-1.35.7-.141 0-.35.2-.097.083-.2.2-.04-.104-.1-.2-.5-1.25-1.15-1.25-1.85.35-2.3.35-.8-.35-1.25-.35h-.25v14.05h139.65v-14.6q-.55.65-2 .85h-.55q0-.4-.15-.75z"
                    />
                </g>
                <g id="PetPet010__Layer3_1_FILL">
                    <path d="M323.35 294.25q-.5.55-1 .9h.45q.25 0 .75-.45.6-.45.7-.9l-.4-.2q-.2.3-.5.65m-8-.15l.1.25q0 .45.05.25 3.1.1 3.15.15 0 .05-.15.3-.1.3.1.65.15-.1.2-.25 0-.15.1-.3h.1q-.05-.05-.05-.1t.05-.15q.2-.25.15-.4-.25-.35-3.5-.35l-.3-.05m-8.6-1v-.15h-.5q-.2.6-1.15 1.2l-.7-.2q0-.3-.25-.5l-.2.15q0 .4.4.75.45.45 1.1.45.35 0 .85-.75.45-.7.45-.95m-14.05 3.05q.85-1.45 3.3-1.65-.2-.15-1.85-.15-1.05 0-1.7 1.25-.55 1-.55 1.7v.15h.45q.15-.9.35-1.3m-9.85-.55q-.35.35-1.9.65.1.2 1.35.2 1.5 0 1.1-1.6 0-1.25-.75-1.4 0 .05-.25.25.55.9.55 1.1 0 .65-.1.8m116.85-2q.026 0 .1.25.055.224.15.65.15 1 .35 1.2l.15-.1q.05-.15.05-.6.25-.5-.2-1-.066-.078-.15-.15-.917-.8-3.9-.8-.35 0-.6.7-.014.05-.05.1-.1.496-.1.95 0 .2.1.35h.2q.2-.3.05-.65-.2-.4-.15-.5.031-.084.2-.15.756-.25 3.8-.25m-18.1-1.55h-.35v2.65q.25.2.5.85.35.45 1.05.5.15-.05.2-.2-1.15-.65-1.3-1.95v-.05q.047-1.31-.1-1.8m2.35 1.25q-.25.05-.3.05v1.9q5 .3 5-.4 0-.3-.05-.3l-.1.05q-.5 0-.5.05.05.05.15.2-.1.25-4 .2-.076-.76-.15-1.2-.025-.427-.05-.55m-15.6 3.05q.25.15.6.6.25-.2.35-.6-.5-.5-.55-.5-.45-.25-1.85-.25-.15.1-.25.1v.2q1.25.1 1.7.45m-4.7-2q-.092-.092-.3-.15-.245-.05-.65-.05-.1 0-.4.35-.35.35-.35.6 0 .2.25 1.85l.35-.05q0-.05.05-1.1 0-.55.75-1.2.137-.126.3-.25m-5.8-.25q.1 0 .5-.05l.4-.2q.793-.37 1.85-.8.15-.05.15-.4 0-.5-.15-.6h-.3q0 .55-.3.9-.2.25-1.3.45-.95.25-.95.4 0 .3.1.3m-15.45.1v.3q0 .35.2.65.6.9 2.6.9h.2l.05-.25q-2.6-.5-2.6-1.6.1 0 .1-.05.05-.05-.3-.55-.25.15-.25.6m-12.7.8q0-1.35-.1-1.7h-.3v2.55q.1.15.1.7 0 .3.5.45.15-.05.2-.3-.4-.55-.4-1.7m79.1-.5q-.15.3.6.85.2-.1.2-.2-.35-.05-.35-.5 0-.15.05-.25-.65 0-.9-.4h-2.45q-.85 0-1.15.35 0 .25.1.45 1.15-.3 3.9-.3z" />
                    <path fill="#F60" d="M363.35 293.3v-1l-.15 1h.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet010__Symbol_82_0_Layer0_0_FILL"
                transform="translate(293.65 271.1)"
                id="PetPet010__col1n"
            />
            <use
                xlinkHref="#PetPet010__Symbol_69_0_Layer0_0_FILL"
                transform="translate(293.65 271.1)"
                id="PetPet010__col1l"
            />
            <use
                xlinkHref="#PetPet010__Symbol_60_0_Layer0_0_FILL"
                transform="translate(297.45 289.05)"
                id="PetPet010__col1d"
            />
            <use xlinkHref="#PetPet010__Layer3_0_FILL" />
            <use xlinkHref="#PetPet010__Layer3_1_FILL" />
            <use xlinkHref="#PetPet010__Layer3_2_FILL" />
            <use xlinkHref="#PetPet010__Layer3_3_FILL" />
            <use xlinkHref="#PetPet010__Layer3_4_FILL" />
            <use xlinkHref="#PetPet010__Layer3_5_FILL" />
            <use xlinkHref="#PetPet010__Layer3_6_FILL" />
            <use xlinkHref="#PetPet010__Layer3_7_FILL" />
            <use xlinkHref="#PetPet010__Layer3_8_FILL" />
        </g></g>
    )
}

export default PetPet010
