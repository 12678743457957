import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#9A3200",
    "col1n": "#621F00",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function ObjToo027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M102.8 98.35q-.9-2.3-2.4-4.75-14.9-25.4-34.7-54.05-1.6 3.55-3.45 5.9-1.95-.9-2.9-1.2-.8-.4-2.3-1.4-1.4-1.1-2.6-3.8-1.1-2.75.15-4.85 1-.4 6.95-.8-9-13-15.75-20.9l-2.25 7.8q-4.35-.2-6.9-3.25-2.3-2.65-3.1-7.6l8.4-1.2q-6.5-7-10.7-8.25-7.45-.25-13.3 2.1-1 .45-1.95.95-.15.1-.4.15-1 .6-2 1.25-1.5 1.05-2.95 2.25-.25.3-.5.5-2.55 2.45-4.5 5.3l-.9 1.3Q1.3 19.25 0 26.1q-.05 4.75 7.45 14.15l2.6-8.1q.15 0 .25.05 3.65.8 5.85 3.7 1.85 2.35 2.8 6l-8.4 1.95Q17.65 52 29.65 63l1.45-7.95q8.2 1.55 9.9 10.6 0 .15.05.3l-6.6 1.35q18.95 17.15 48.5 40.6l1.95 1.55q.2-1 .85-1.8 1.9-2.45 5.95-2 4.1.35 8.05 3.35 3.9 3 5.3 6.9 1.45 3.8-.45 5.7-1.9 1.9-2.85 1.8 3.75 4.05 5.45 7.55.2.3.45.65.6.8 1.25 1.7.6.9 2.2 1.35 1.55.45 2 .4 1.1-.05 2-.25 2.1-.4 3.65-1.4.95-.65 1.7-1.5 2-2.25 2.1-4.25.1-2.05-.75-3.6-.85-1.6-1.8-2.6-1-1-2.45-2.3-3.85-3.6-4.85-4.75l-1.55-1.45q-.2-.25-.35-.45-.55.05-1.15.05-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-2.65 1.6-4.65-.45-.95-.8-1.9M41.7 37.9q.5 2.55-.85 3.8-1.4 1.25-3.8.5-2.5-.65-4.6-3-2.1-2.25-2.6-4.7-.5-2.5.85-3.8 1.35-1.2 3.85-.5 2.45.7 4.55 2.95 2.1 2.3 2.6 4.75z"
                    id="ObjToo027__Symbol_187_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M104.25 113.3q-.3 1.15-.3 2.6 0 1.25.4 2.9.45 2 .6 2.65.2.3.4.65 1.9 3.45 3.8 4.95.95.7 2.05 1.15 2.1-.4 3.65-1.4-.6-.5-1.2-1.15-.25-.3-3.3-4.75-3.2-4.5-4.4-6.25-.75-1.05-1.55-2.1-.05.35-.15.75M7.25.1h-.5q-.25.3-.5.5-2.55 2.45-4.5 5.3l-.9 1.3Q-2.3 12.95 6.4 25.6q3.65.8 5.85 3.7 1.85 2.35 2.8 6l-.9.2q5.4 6.3 12.9 13.8l.15-.85q8.2 1.55 9.9 10.6Q50.75 71.9 69.5 87.7q2.6 2.5 4.15 4 4.5 4.3 7.75 7.4.2.15.4.35h.05l.9.75q1.85-1.5 5.05-1.15 4.1.35 8.05 3.35 1.2.95 2.2 2-.65-1.2-.8-1.65l-1.8-2.75q-.15-.05-.25-.15-.1-.15-.3-.3Q50.25 38.8 27.75 15.1 15.6 2.3 9.95.4 9.2.1 8.55.1q-.7-.15-1.3 0m19.55 24q1.35-1.2 3.85-.5 2.45.7 4.55 2.95 2.1 2.3 2.6 4.75.5 2.55-.85 3.8-1.4 1.25-3.8.5-2.5-.65-4.6-3-2.1-2.25-2.6-4.7-.5-2.5.85-3.8z"
                    id="ObjToo027__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.2 54.35l-2.45 13.5 12.4-2.6q-.05-.15-.05-.3-1.7-9.05-9.9-10.6M38.8 37.2q-.5-2.45-2.6-4.75-2.1-2.25-4.55-2.95-2.5-.7-3.85.5-1.35 1.3-.85 3.8.5 2.45 2.6 4.7 2.1 2.35 4.6 3 2.4.75 3.8-.5 1.35-1.25.85-3.8M7.4 31.5q-.1-.05-.25-.05L3.25 44.1l12.8-2.9q-.95-3.65-2.8-6-2.2-2.9-5.85-3.7m5.7-29.15q-.65-.25-.75-.25Q8.3 1.4 0 0l1.7 7.5q.15.45.25.95.5 1.8.8 3.35 1.95-2.85 4.5-5.3.25-.2.5-.5Q9.2 4.8 10.7 3.75q1-.65 2-1.25.25-.05.4-.15M58.65 32.7q-5.95.4-6.95.8-1.25 2.1-.15 4.85 1.2 2.7 2.6 3.8 1.5 1 2.3 1.4.95.3 2.9 1.2 1.85-2.35 3.45-5.9.25-.5.4-.9.15-.25 2.15-5.5-2.1.1-6.7.25m-18-13.1L44.3 6.65l-13.65 2.1q.8 4.95 3.1 7.6 2.55 3.05 6.9 3.25z"
                    id="ObjToo027__Symbol_68_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M33.4 60.8q-.4-3.6-2.55-4.45l-5.1 7.4 7.7-2.25q0-.35-.05-.7M32.5 35q1.1-.9.7-2.15l-.45-.95q-.2-.25-.45-.4-1.4-1.05-2.6.3-1.55 1.7-.15 3.15.25.35.75.55.25.05.45.1 1.05.15 1.75-.6m-21.6 2.3q-.2-3.15-2.7-4.2l-5.15 7.45 7.9-2.45q0-.4-.05-.8M5.4 4.1q1.45-1.2 2.95-2.25L0 0l5.4 4.1m31.55 10.75l2.85-8-7.4 4.75q1.2 3.25 4.55 3.25M60.6 32.4l-8.55 3.3q.85 3.35 4.15 4.5l4.4-7.8z"
                    id="ObjToo027__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo027__Symbol_187_0_Layer0_0_FILL"
                transform="translate(22.3 186.7)"
                id="ObjToo027__col1n"
            />
            <use
                xlinkHref="#ObjToo027__Symbol_151_0_Layer0_0_FILL"
                transform="translate(26.2 193.3)"
                id="ObjToo027__col1l"
            />
            <use
                xlinkHref="#ObjToo027__Symbol_68_0_Layer0_0_FILL"
                transform="translate(25.2 187.4)"
                id="ObjToo027__col2n"
            />
            <use
                xlinkHref="#ObjToo027__Symbol_32_0_Layer0_0_FILL"
                transform="translate(27.55 189.3)"
                id="ObjToo027__col2l"
            />
        </g></g>
    )
}

export default ObjToo027
