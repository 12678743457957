import { AssetSvgProps } from "../../../shared/assets"

function ShiMot096({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0000A4"
                    d="M159.2 257.8v35.8q19.28-3.271 27.25-3.85v-3.65q19.625.97 39.25-4.35V269.6q-19.625-4.442-39.25-3.65v-2.15q-7.36-.416-27.25-6z"
                    id="ShiMot096__Layer1_0_FILL"
                />
                <path
                    fill="red"
                    d="M192.7 275.85l-11.3 5.9h-.2l-22 11.85h66.5v-17.75h-33z"
                    id="ShiMot096__Layer1_1_FILL"
                />
                <path
                    fill="#FFF"
                    d="M181.15 269.6l11.55 6.25h33V257.8h-66.5l21.9 11.8h.05z"
                    id="ShiMot096__Layer1_1_MEMBER_0_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot096__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot096__Layer1_1_FILL" />
            <use xlinkHref="#ShiMot096__Layer1_1_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot096
