import { AssetSvgProps } from "../../../shared/assets"

function AccJew025({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#E59700"
                    d="M192.15 265.15q-1.95 0-3.7.25-2.85.45-5.15 1.6l-.2.1q-.55.3-1.05.6l-.05.05q-4.35 2.8-6.8 8.6h-.05l.05.05q0 .049-.05.05h.05q1.5 3.55 3.75 6 1.4 1.55 3.1 2.6 4.15 2.65 10.1 2.6 2.7.05 5.05-.5l.85-.25q4.65-1.1 7.45-4.15.1-.2.3-.3 2.2-2.45 3.75-6h.05l-.05-.05v-.05q-1.75-4.15-4.4-6.7L203.1 268l-.1-.1q-.3-.2-.65-.4-.143-.082-.3-.2-.193-.082-.4-.2-2.45-1.3-5.55-1.7-.4-.05-.85-.1-1.45-.2-3.1-.15m-3.15 6.2q1.5-.55 3.35-.7 1.35.1 2.55.4l-4.2 2.2-1.7-1.9m11.75 4.05q.25.45.45.85h.05v.1h-.05q-1.45 2.9-3.9 4.25l-2.85-3.25 6.3-1.95m-.5-.75l-6.55 2-2.3-2.65 4.8-2.5q2.45.95 4.05 3.15m-6.85 3.05l2.95 3.4q-1.75.7-4 .8-3.55-.1-5.95-2l7-2.2m-7.9 1.45q-.65-.7-1.2-1.45l6.2-3.3 2.2 2.6-7.2 2.15m-1.8-2.4l-.2-.4v-.1q1.7-3.25 4.55-4.6l1.75 1.95-6.1 3.15z"
                    id="AccJew025__Layer5_1_FILL"
                />
                <path
                    fill="#B67905"
                    d="M188.25 276.35v-.1q0-.35.1-.7l-4.05 2.15q.55.75 1.2 1.45l3.2-1q-.45-.8-.45-1.8m1.2-2.9l.1-.1-1.5-1.7q-2.85 1.35-4.55 4.6v.1l.2.4 5.2-2.65q.2-.35.55-.65m6.9 7.65l-1.45-1.6q-.75.65-1.65.75-.35.05-.7.05h-.35q-.35 0-.65-.05-1.25-.2-2.1-1.1-.15-.1-.2-.15l-2.85.9q2.4 1.9 5.95 2 2.25-.1 4-.8m-2.3-8.45q.7.25 1.3.8 1 1.05 1.15 2.35l3.75-1.15q-1.6-2.2-4.05-3.15l-2.15 1.15m7.15 3.7h.05v-.1h-.05q-.2-.4-.45-.85l-4.25 1.35q-.1 1.15-.8 2.05l1.6 1.8q2.45-1.35 3.9-4.25m-6.3-5.3q-1.2-.3-2.55-.4-1.85.15-3.35.7l1.3 1.45q.6-.35 1.25-.45.3-.05.65-.05h.25l2.45-1.25z"
                    id="AccJew025__Layer5_2_FILL"
                />
                <path
                    fill="#20FF00"
                    d="M195.5 279.4q1.25-1.3 1.25-3.05 0-1.8-1.25-3.05-1.3-1.25-3.05-1.25-1.8 0-3.05 1.25-1.25 1.25-1.25 3.05 0 1.75 1.25 3.05 1.25 1.25 3.05 1.25 1.75 0 3.05-1.25z"
                    id="AccJew025__Layer5_3_FILL"
                />
                <g id="AccJew025__Layer5_0_FILL">
                    <path
                        fill="#20FF00"
                        d="M188.25 276.35q0 1 .45 1.8l4-1.15-2.2-2.6-2.15 1.15q-.1.35-.1.7v.1m1.2-2.9q-.35.3-.55.65l.9-.5-.25-.25-.1.1m2.1-1.1q-.65.1-1.25.45l.4.45 1.75-.95h-.25q-.35 0-.65.05m1.7 7.9q.9-.1 1.65-.75l-1.5-1.8-4.15 1.3q.05.05.2.15.85.9 2.1 1.1.3.05.65.05h.35q.35 0 .7-.05m3.25-3.5l-2.05.6 1.25 1.45q.7-.9.8-2.05m-2.8-.1l2.8-.85q-.15-1.3-1.15-2.35-.6-.55-1.3-.8L191.4 274l2.3 2.65z"
                    />
                    <path
                        fill="#333"
                        d="M227.45 243.4l-2.2.7-23.2 23.2q0 .308-.4 1.25-.396.95 1.35-.65l.1.1 24.35-24.6m-67.5.7l-2.35-.7 23.95 24.35 1.1.95q.812.186.85-.3.088-.436.6-.2l-24.15-24.1z"
                    />
                </g>
                <g id="AccJew025__Layer5_4_FILL">
                    <path
                        fill="#FFC433"
                        d="M186.75 267.1q-.6.2-1.1.4-2 .8-3.6 2.15-2.9 2.45-4.65 6.6h-.05v.1h.05q1.35 3.2 3.3 5.35.65.65 1.35 1.25 4.05 3.35 10.3 3.3 4.15 0 7.3-1.4.5-.2.95-.5 1.1-.6 2.1-1.4.7-.6 1.35-1.3 1.95-2.1 3.25-5.3h.05v-.15h-.05q-.45-1-.9-1.95-1.45-2.35-3.7-4.6-.6-.5-1.2-.9-1.15-.75-2.45-1.25-.6-.2-1.15-.4-2.55-.75-5.55-.75-3.1-.05-5.6.75m-.95 1.9q.3-.35.65-.6.7-.5 1.45-.9.4-.2.85-.4 1.65-.65 3.6-.65 1.95 0 3.65.65l.9.4q1.1.55 2.1 1.45l.35.35q.2.2.35.4 1.8 1.95 2.35 4.4.1.45.15.85.05.65.05 1.4 0 3.95-2.7 6.8-.05.1-.2.15-.25.3-.55.6-2.75 2.3-6.45 2.3-4.05 0-6.95-2.9-1.15-1.15-1.85-2.5-.25-.45-.4-.95-.3-.65-.4-1.35-.15-.6-.2-1.15-.1-.55-.1-1 0-4.1 2.95-7.05.15-.15.4-.3z"
                    />
                    <path
                        fill="#FFD97E"
                        d="M186.45 268.4q-.35.25-.65.6l3.75 4.35.25.25-7.25 3.75q.05.55.2 1.15l7.75-4.1 2.2 2.6-9.55 2.85q.15.5.4.95l9.85-3.1 5.4 6.2q.3-.3.55-.6.15-.05.2-.15l-5.1-5.8 7.75-2.4q-.05-.4-.15-.85l-8.35 2.55-2.3-2.65 8.3-4.3q-.15-.2-.35-.4l-.35-.35-8.3 4.3-4.25-4.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccJew025__Layer5_0_FILL" />
            <use xlinkHref="#AccJew025__Layer5_1_FILL" />
            <use xlinkHref="#AccJew025__Layer5_2_FILL" />
            <use xlinkHref="#AccJew025__Layer5_3_FILL" />
            <use xlinkHref="#AccJew025__Layer5_4_FILL" />
        </g></g>
    )
}

export default AccJew025
