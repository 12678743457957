import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou082({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M167.15 1.4q.3 0 .5-.2t.2-.5q0-.3-.2-.5t-.5-.2H.7Q.4 0 .2.2T0 .7q0 .3.2.5t.5.2h166.45z"
                    id="FacMou082__Symbol_107_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou082__Symbol_107_0_Layer0_0_FILL"
                transform="translate(108.7 199.4)"
                id="FacMou082__col1n"
            />
        </g></g>
    )
}

export default FacMou082
