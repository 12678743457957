import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00FF00"
}

function ObjToo048({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.169}
                    d="M47.6 116.3q-1.65 4.25-.1 8.55.5 1.9 1.2 3.8l55.75 151.05 19.6-7.25L68 120.65q-.35-1.15-.7-2.05-.15-.4-.35-.9-1.55-4.3-5.55-6.45-3.95-2-7.9-.55-3.9 1.4-5.9 5.6z"
                    id="ObjToo048__Symbol_208_0_Layer0_0_MEMBER_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.251}
                    d="M49.4 124.9q.45 1.55 1.05 3.1l55.9 151.45 16.05-5.9L66.25 121.5q-.3-1-.55-1.75-.15-.35-.3-.75-1.3-3.55-4.6-5.25-3.3-1.7-6.5-.5-3.2 1.15-4.8 4.65-1.4 3.45-.1 7z"
                    id="ObjToo048__Symbol_208_0_Layer0_0_MEMBER_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M64.95 122.8q-.25-.75-.45-1.4-.1-.25-.25-.6-1.05-2.8-3.65-4.2-2.65-1.35-5.2-.4-2.6.95-3.85 3.75-1.15 2.8-.1 5.6.35 1.25.8 2.45l55.6 150.65 12.85-4.75-55.75-151.1z"
                    id="ObjToo048__Symbol_208_0_Layer0_0_MEMBER_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M62.65 122.55q-.1-.2-.15-.4-.75-1.95-2.6-2.9-1.8-.9-3.55-.25-1.8.65-2.65 2.5-.7 1.95.05 3.9.2.8.5 1.65L109.9 277.9l8.8-3.25L62.9 123.5q-.1-.55-.25-.95z"
                    id="ObjToo048__Symbol_208_0_Layer0_0_MEMBER_3_FILL"
                />
                <path
                    fill="url(#ObjToo048__Gradient_1)"
                    d="M129.95 299q-1.902-.502-3.4-2-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.495.188-1 .3l3.3 8.9 12.4-4.6-5.35-14.5m-24.6-19.65l1.05 2.85 2.85-1.05 1.95 5.3q3.447-.57 7.55.75 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-2.414 1.3-4.3l-3.95-10.75 2.55-.95-1.05-2.85-17.8 6.6z"
                    id="ObjToo048__Layer5_0_FILL"
                />
                <path
                    d="M124.65 310.95q.35.85 1.25 1.3.9.5 1.8.15.95-.35 1.35-1.35.4-.9.05-1.75-.35-.95-1.25-1.35-.85-.4-1.8-.05-.9.35-1.35 1.25-.4.85-.05 1.8m-15.4-29.85l.9 2.45L122.5 279l-.9-2.45-12.35 4.55z"
                    id="ObjToo048__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M56.15 122.9q-.35.7-.15 1.45l56.3 152.45 3.9-1.4-56.3-152.5-.05.05q-.3-.75-1-1.05-.75-.35-1.55-.05t-1.15 1.05z"
                    id="ObjToo048__Layer5_1_FILL"
                />
                <linearGradient
                    id="ObjToo048__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={127.7}
                    y1={295.275}
                    x2={117}
                    y2={299.225}
                    spreadMethod="pad"
                >
                    <stop offset="1.176%" stopColor="#555" />
                    <stop offset="57.647%" stopColor="#F5F5F5" />
                    <stop offset="100%" stopColor="#757575" />
                </linearGradient>
            </defs>
            <g id="ObjToo048__col1n">
                <use xlinkHref="#ObjToo048__Symbol_208_0_Layer0_0_MEMBER_0_FILL" />
                <use xlinkHref="#ObjToo048__Symbol_208_0_Layer0_0_MEMBER_1_FILL" />
                <use xlinkHref="#ObjToo048__Symbol_208_0_Layer0_0_MEMBER_2_FILL" />
                <use xlinkHref="#ObjToo048__Symbol_208_0_Layer0_0_MEMBER_3_FILL" />
            </g>
            <use xlinkHref="#ObjToo048__Layer5_0_FILL" />
            <use xlinkHref="#ObjToo048__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToo048__Layer5_1_FILL" />
        </g></g>
    )
}

export default ObjToo048
