import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FF008C"
}
function HaiHig023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M16.2 32.2q-1.15-15.65-2.15-22Q13 3.85 13 .35 12.95.2 12.9 0q-.15.4-.25.75l-.3.7q-8.1 16.7-11.1 37.9-1.3 5.7-1.25 11.5Q.1 62.7 6 74.9q.5.95 1 1.85Q17.25 95.8 17.8 104.7q.5 8.9-2 17.7-.65 2.3-1.35 4.15-2.1 5.25-4.35 7.3 1.5-1.2 3-2.35 1.35-1.1 2.65-2.2.8-.9 1.7-1.65 3.15-2.95 5.85-6 1.75-2 3.35-4.1.15-.2.35-.4 4.25-9.5 4.75-17.6.5-8.15-.45-14.8-1-6.65-7.5-21.75-6.45-15.15-7.6-30.8z"
                    id="HaiHig023__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.35.7q-8.1 16.7-11.1 37.9Q-.05 44.3 0 50.1q.1 11.85 6 24.05.5.95 1 1.85 10.25 19.05 10.8 27.95.5 8.9-2 17.7-.65 2.3-1.35 4.15-2.1 5.25-4.35 7.3 1.5-1.2 3-2.35 1.85-2.95 4.2-6.5 2.3-3.7 3.25-6.25 1.95-5.25 1.95-20.55 0-6.2-2.55-12.7-1.1-2.85-5.55-11.7-8.15-16-6.95-29.55Q8.65 29.95 11 16.05 13.3 2.1 12.65 0l-.3.7z"
                    id="HaiHig023__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.7 9.6q.7 6.05 1 21Q2 45.55 9.15 62.05q7.15 16.45 8.15 23.1.95 6.65.95 14.6 0 7.55-4.25 17.05 3.2-4.25 5.25-8.15Q29 86.8 19.25 65q-1.5-3.4-4.3-10.25-2.85-6.85-6.5-22.35Q4.75 17 0 0q0 3.5.7 9.6z"
                    id="HaiHig023__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig023__Symbol_45_0_Layer0_0_FILL"
                transform="translate(274 134.9)"
                id="HaiHig023__col1n"
            />
            <use
                xlinkHref="#HaiHig023__Symbol_46_0_Layer0_0_FILL"
                transform="translate(274 135.65)"
                id="HaiHig023__col1d"
            />
            <use
                xlinkHref="#HaiHig023__Symbol_44_0_Layer0_0_FILL"
                transform="translate(287 135.25)"
                id="HaiHig023__col2n"
            />
        </g></g>
    )
}
export default HaiHig023
