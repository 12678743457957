import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF7B00",
    "col2n": "#FFFFFF"
}

function LegSki007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M142.3 15.85q2.8-.2 5.6-.45-4.6-4.9-7.7-13.2h-.05v-.1l-.5-2.1h-.3q-2.05.5-4.15.95-.4.1-.85.2-30.1 6.8-60.45 6.8-30.2 0-60.55-6.8-.45-.1-.85-.2Q10.4.5 8.35 0H8.1l-.55 2.1v.25H7.5Q4.75 10.05 0 15.4h.4q2.6.2 5.25.4 68.3 5.15 136.65.05z"
                    id="LegSki007__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.35 1.15q-.45-.1-.85-.2Q10.4.5 8.35 0H8.1l-.55 2.1v.25H7.5Q4.75 10.05 0 15.4h.4q2.6.2 5.25.4 6.95-7.75 7.7-14.65m128.95 14.7q2.8-.2 5.6-.45-4.6-4.9-7.7-13.2h-.05v-.1l-.5-2.1h-.3q-2.05.5-4.15.95-.4.1-.85.2.95 6.7 7.65 14.15l.3.55z"
                    id="LegSki007__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki007__Symbol_73_0_Layer0_0_FILL"
                transform="translate(118.6 307.1)"
                id="LegSki007__col1n"
            />
            <use
                xlinkHref="#LegSki007__Symbol_21_0_Layer0_0_FILL"
                transform="translate(118.6 307.1)"
                id="LegSki007__col2n"
            />
        </g></g>
    )
}

export default LegSki007
