import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D9D9D9",
    "col1s": "#999999",
    "col2n": "#666666"
}

function BodMor008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M61.3 22.2q.75 0 1.05-.65.05-.25.05-.5-.05-.2-.1-.35-.1-.3-.3-.55-2.15-5.9.8-11.5l-1.7-.1v-.1h-.5Q31.45 8.1 1.45.35.7.15 0 0q.1.2.25.35Q.5.8.9 1.2 6.1 8.8 0 17.4v2.25q10.2-1.65 19.9-2 21.7-.9 41.1 4.5.15.05.3.05z"
                    id="BodMor008__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M62.8 8.65l-1.7-.1q0 .75-.5 1.4-.7.75-2.1 1.25-.85.25-1.9.5-4.5.8-13.4.25-1.75-.1-3.7-.25-1.2-.15-2.5-.25-5.85-.7-10.8-1.55-3.35-.55-6.3-1.15Q11.2 6.9 6.2 4.7q-1.05-.5-1.95-1-1.35-.75-2.3-1.5Q.85 1.3.25.35.1.2 0 0v17.4q.95-.35 1.95-.6 1.15-.4 2.3-.7.95-.25 1.95-.5 6.55-1.55 13.7-1.95 3.1-.2 6.3-.15 6.4.05 13.3 1 1.85.25 3.7.55 6.75 1.1 13.95 3.05 1 .25 2 .55.15.05.35.1 1.4.4 2.1 1 .55.4.7.95.15.4.05.85l.45.45V8.65m-19.6 4.2v1.25q-1.85-.3-3.7-.55-6.9-.95-13.3-1-3.2-.05-6.3.15V9.65q2.95.6 6.3 1.15 4.95.85 10.8 1.55 1.3.1 2.5.25 1.95.15 3.7.25m12.95 4.05q-1.8-.55-2.5-.8-1.15-.4-1.15-1.65 0-.8.65-1.1.4-.2 1.45-.25.65-.05 1.2-.15l.35 3.95m-45.4-9q1.05.8 1.05 2.85 0 2-1.4 2.55-1.65.35-3.2.7V6.65q2.85.7 3.55 1.25z"
                    id="BodMor008__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M9.8 16.8q0-.4-.25-.7-.3-.3-.75-.3-.4 0-.7.3-.25.25-.3.55v.15q0 .45.3.75.3.25.7.25.25 0 .45-.05l.3-.2q.25-.3.25-.75M6.2 4.7q-1.05-.5-1.95-1v12.4q.95-.25 1.95-.5V4.7M1.95 2.2Q.85 1.3.25.35.1.2 0 0v17.4q.95-.35 1.95-.6V2.2m6.85.5q-.4 0-.7.3-.25.25-.3.55v.15q0 .45.3.75.3.25.7.25.25 0 .45-.05l.3-.2q.25-.3.25-.75 0-.4-.25-.7-.3-.3-.75-.3m45.3 15.1q-.4 0-.7.3-.25.25-.3.55v.15q0 .45.3.75.3.25.7.25.25 0 .45-.05l.3-.2q.25-.3.25-.75 0-.4-.25-.7-.3-.3-.75-.3m4.4-6.6q-.85.25-1.9.5l.55 6.4q1 .25 2 .55l-.65-7.45m-5.4-1.15v.15q0 .45.3.75.3.25.7.25.25 0 .45-.05l.3-.2q.25-.3.25-.75 0-.4-.25-.7-.3-.3-.75-.3-.4 0-.7.3-.25.25-.3.55M62.8 22V8.65l-1.7-.1q0 .75-.5 1.4l1 9.8q.55.4.7.95.15.4.05.85l.45.45z"
                    id="BodMor008__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodMor008__Symbol_19_0_Layer0_0_FILL"
                transform="translate(130 310.25)"
                id="BodMor008__col1n"
            />
            <use
                xlinkHref="#BodMor008__Symbol_18_0_Layer0_0_FILL"
                transform="translate(130 310.25)"
                id="BodMor008__col1s"
            />
            <use
                xlinkHref="#BodMor008__Symbol_17_0_Layer0_0_FILL"
                transform="translate(130 310.25)"
                id="BodMor008__col2n"
            />
        </g></g>
    )
}

export default BodMor008
