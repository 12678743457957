import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1l": "#999999",
    "col1n": "#666666",
    "col1s": "#121212",
    "col2l": "#FFEAAD",
    "col2n": "#FFD446"
}

function ObjMus002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M82.35 21.05l1.95-6.2-3.05-8.15L61.2 1.3q-.2-.05-.4-.05-18.65-2.5-37.3.05L2.05 5.6 0 15.3l2.7 8.65L23.5 34.6h37.7l21.15-13.55z"
                    id="ObjMus002__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M97.35.9H84.1q-3.35-1.45-4.5 0h-62q-.85-1.75-4.5 0H0v49.35h97.35V.9M87.5 18.15q.15.25.35.55 1.2 2.2 1.25 5.15-.05.9-.15 1.8-.2 1.05-.55 2.05-.35.85-.9 1.7-1.6 2.35-3.85 2.4-2.3-.05-3.9-2.4-1.6-2.3-1.6-5.55 0-1.65.4-3.05.35-1.45 1.2-2.65 1.6-2.3 3.9-2.3 2.25 0 3.85 2.3M67.8 11v33.3H30.1V11q18.85-2.55 37.7 0M8.55 23.85q0-1.65.4-3.05.35-1.45 1.2-2.65 1.55-2.3 3.85-2.3 2.25 0 3.9 2.3.15.25.3.55 1.25 2.2 1.25 5.15 0 .9-.15 1.8-.15 1.05-.5 2.05-.35.85-.9 1.7-1.65 2.35-3.9 2.4-2.3-.05-3.85-2.4-1.6-2.3-1.6-5.55m7.65.1q-.05-1.7-.6-2.9-.55-1.1-1.3-1.1-.8 0-1.4 1.1-.5 1.2-.5 2.9 0 1.65.5 2.75.6 1.15 1.4 1.2.75-.05 1.3-1.2.55-1.1.6-2.75m69.6 0q0-1.7-.6-2.9-.5-1.1-1.3-1.1t-1.35 1.1q-.5 1.2-.5 2.9 0 1.65.5 2.75.55 1.15 1.35 1.2.8-.05 1.3-1.2.6-1.1.6-2.75z"
                    id="ObjMus002__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M19.75 4.65Q16.35 0 11.6 0q-2.15-.05-4 .9-1.35.65-2.5 1.8-.95.85-1.75 1.95Q-.05 9.4 0 16.2q-.05 6.75 3.35 11.4 3.45 4.75 8.25 4.75 4.75 0 8.15-4.75 3.45-4.65 3.45-11.4 0-6.8-3.45-11.55M15.4 10.7q.15.25.3.55 1.25 2.2 1.25 5.15 0 .9-.15 1.8-.15 1.05-.5 2.05-.35.85-.9 1.7-1.65 2.35-3.9 2.4-2.3-.05-3.85-2.4-1.6-2.3-1.6-5.55 0-1.65.4-3.05.35-1.45 1.2-2.65Q9.2 8.4 11.5 8.4q2.25 0 3.9 2.3m73.95-6.05Q87 1.4 84.05.4q-1.35-.45-2.8-.4-4.8 0-8.25 4.65-3.4 4.75-3.4 11.55 0 .8.05 1.6.05.85.15 1.65.35 2.65 1.4 5 .55 1.3 1.35 2.5.2.3.45.65 3.45 4.75 8.25 4.75 4.75 0 8.1-4.75 3.5-4.65 3.5-11.4 0-6.8-3.5-11.55m-8.2 3.75q2.25 0 3.85 2.3.15.25.35.55 1.2 2.2 1.25 5.15-.05.9-.15 1.8-.2 1.05-.55 2.05-.35.85-.9 1.7-1.6 2.35-3.85 2.4-2.3-.05-3.9-2.4-1.6-2.3-1.6-5.55 0-1.65.4-3.05.35-1.45 1.2-2.65 1.6-2.3 3.9-2.3z"
                    id="ObjMus002__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M52.95 39.45V28.2H19.5v13.25q.6-.45 1.2-.8 1.3-.8 2.65-1.4 6.6-3.25 13.2-3.15 2.8 0 5.6.65 5.4 1.2 10.8 2.7M60.2 19.6H12.5v6.6h47.7v-6.6m6.3-3.5H71L54.9 0H16.1L0 16.1h4.5L16.9 3.7h37.2l12.4 12.4z"
                    id="ObjMus002__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M31.05 3.75Q27.7 1.35 23.65.5 21.4 0 18.95 0q-2.65 0-5 .55-5.65 1.3-10 5.6-1.45 1.45-2.5 3Q.6 10.3 0 11.55q1.3-.8 2.65-1.4Q9.25 6.9 15.85 7q2.8 0 5.6.65 5.4 1.2 10.8 4.7V4.7q-.6-.5-1.2-.95z"
                    id="ObjMus002__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M0 .55L1.9 7q2.8 0 5.6.65L9.7.5Q7.45 0 5 0 2.35 0 0 .55z"
                    id="ObjMus002__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus002__Symbol_88_0_Layer0_0_FILL"
                transform="translate(281.85 336.3)"
                id="ObjMus002__col1n"
            />
            <use
                xlinkHref="#ObjMus002__Symbol_72_0_Layer0_0_FILL"
                transform="translate(275.25 326.6)"
                id="ObjMus002__col1d"
            />
            <use
                xlinkHref="#ObjMus002__Symbol_61_0_Layer0_0_FILL"
                transform="translate(277.75 334.05)"
                id="ObjMus002__col1l"
            />
            <use
                xlinkHref="#ObjMus002__Symbol_51_0_Layer0_0_FILL"
                transform="translate(288.35 311.4)"
                id="ObjMus002__col1s"
            />
            <use
                xlinkHref="#ObjMus002__Symbol_19_0_Layer0_0_FILL"
                transform="translate(309.05 340.5)"
                id="ObjMus002__col2n"
            />
            <use
                xlinkHref="#ObjMus002__Symbol_5_0_Layer0_0_FILL"
                transform="translate(323 340.5)"
                id="ObjMus002__col2l"
            />
        </g></g>
    )
}

export default ObjMus002
