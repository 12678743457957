import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E4B670",
    "col1l": "#F0E8DA",
    "col1n": "#E7D4B6",
    "col2n": "#D19532"
}

function PlaSim002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M385 290.5V0H0v290.5h385z"
                    id="PlaSim002__Symbol_5_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M21.9 0h-7.05v290.5h7.05V0m-9.55 0H0v290.5h12.35V0m56.2 0h-15.1v290.5h15.1V0m37.15 0H90.1v290.5h15.6V0M87.6 0h-4.55v290.5h4.55V0m27.6 0h-7v290.5h7V0m46.65 0H146.8v290.5h15.05V0m93.35 0h-15.05v290.5h15.05V0m-46.65 0h-7v290.5h7V0m-9.5 0h-15.6v290.5h15.6V0M180.9 0h-4.5v290.5h4.5V0m111.45 0h-15.6v290.5h15.6V0m-18.1 0h-4.5v290.5h4.5V0m27.65 0h-7.05v290.5h7.05V0m65.65 0h-4.5v290.5h4.5V0M348.5 0h-15.05v290.5h15.05V0M385 0h-14.95v290.5H385V0z"
                    id="PlaSim002__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.6}
                    d="M161.85 290.5h14.55V0h-14.55v290.5M83.05 0h-14.5v290.5h14.5V0m-68.2 0h-2.5v290.5h2.5V0M27.9 0h-2.5v290.5h2.5V0m20.6 0H46v290.5h2.5V0m59.7 0h-2.5v290.5h2.5V0m13.05 0h-2.55v290.5h2.55V0m20.55 0h-2.5v290.5h2.5V0m59.75 0h-2.5v290.5h2.5V0m13.05 0h-2.5v290.5h2.5V0m20.6 0h-2.55v290.5h2.55V0m34.55 0H255.2v290.5h14.55V0m25.1 0h-2.5v290.5h2.5V0m13.05 0h-2.5v290.5h2.5V0m20.6 0H326v290.5h2.5V0m34.55 0H348.5v290.5h14.55V0z"
                    id="PlaSim002__Symbol_6_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.902}
                    d="M8.5 0H6v290.5h2.5V0m-6 0H0v290.5h2.5V0m99.35 0H99.3v290.5h2.55V0M95.8 0h-2.5v290.5h2.5V0m99.4 0h-2.5v290.5h2.5V0m-6.05 0h-2.5v290.5h2.5V0m93.35 0H280v290.5h2.5V0m6 0H286v290.5h2.5V0z"
                    id="PlaSim002__Symbol_8_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M385 52.35h-2.35l2.35-.05V0H0v91.5h385V52.35z"
                    id="PlaSim002__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill="#5F1D01"
                    d="M385 298.9v-13.5H0v13.5h385z"
                    id="PlaSim002__Layer1_0_FILL"
                />
                <path
                    fill="#3A1201"
                    d="M385 286.8v-3H0v3h385z"
                    id="PlaSim002__Layer1_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaSim002__Symbol_5_0_Layer0_0_FILL"
                id="PlaSim002__col1n"
            />
            <g id="PlaSim002__col1l">
                <use xlinkHref="#PlaSim002__Symbol_6_0_Layer0_0_FILL" />
                <use xlinkHref="#PlaSim002__Symbol_6_0_Layer0_1_FILL" />
            </g>
            <use
                xlinkHref="#PlaSim002__Symbol_8_0_Layer0_0_FILL"
                transform="translate(19.4)"
                id="PlaSim002__col1d"
            />
            <use
                xlinkHref="#PlaSim002__Symbol_10_0_Layer0_0_FILL"
                transform="translate(0 293.5)"
                id="PlaSim002__col2n"
            />
            <use xlinkHref="#PlaSim002__Layer1_0_FILL" />
            <use xlinkHref="#PlaSim002__Layer1_1_FILL" />
        </g></g>
    )
}

export default PlaSim002
