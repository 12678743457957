import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col1s": "#3E576D"
}

function FacNos010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.35 19.6q.1-1.05.1-2 0-7.3-6.45-12.4Q31.45 0 22.25 0 13 0 6.45 5.2 1.35 9.25.3 14.7 0 16.1 0 17.6q0 .95.15 2 .3 2.95 1.35 5.45v.05q3.2 5.7 9.1 4.8 1.95-.7 3.9-1.1 8.5-2.1 16.35-.05v-.1q1.35.3 2.7.8Q40 30.5 43.9 22.15q.25-1.25.45-2.55z"
                    id="FacNos010__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M45.6 23.05q0-.05.05-.1.3-1.35.5-2.7v-.05q.1-1 .1-2.05 0-7.55-6.85-12.85Q32.7 0 23.15 0 13.55 0 6.8 5.3 1.4 9.55.3 15.15q-.3 1.45-.3 3 0 1.05.15 2.1.3 3 1.45 5.6 0 .05.05.05 3.5 6.25 10.05 5.25h.1q1.95-.7 3.9-1.15 8.15-2 15.75-.05 1.3.25 2.65.75h.1q7.1 1.25 11.4-7.65M44.75 18q0 .9-.1 1.9-.2 1.2-.45 2.4-3.75 7.8-10 6.85-1.35-.5-2.65-.75v.05q-7.6-1.9-15.8.05-1.9.4-3.8 1.05-5.7.85-8.8-4.5V25q-1-2.35-1.3-5.1-.15-1-.15-1.9 0-1.4.3-2.7 1-5.1 5.95-8.9 6.35-4.9 15.3-4.9 8.9 0 15.25 4.9 6.25 4.8 6.25 11.6z"
                    id="FacNos010__Symbol_8_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.2 2.35q0-.95-.65-1.7Q3.1.1 2.45.05 2.25 0 2.1 0 1.25 0 .6.65q-.5.65-.55 1.4-.05.15-.05.3 0 .95.6 1.6.25.3.55.5.45.25.95.25.9 0 1.45-.75.65-.65.65-1.6m15.5 0q0-.95-.65-1.7Q18.5 0 17.6 0q-.85 0-1.5.65-.6.75-.6 1.7t.6 1.6q.65.75 1.5.75.9 0 1.45-.75.65-.65.65-1.6z"
                    id="FacNos010__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos010__Symbol_19_0_Layer0_0_FILL"
                transform="translate(170.3 187.45)"
                id="FacNos010__col1n"
            />
            <use
                xlinkHref="#FacNos010__Symbol_8_0_Layer0_0_FILL"
                transform="translate(169.4 186.8)"
                id="FacNos010__col1d"
            />
            <use
                xlinkHref="#FacNos010__Symbol_5_0_Layer0_0_FILL"
                transform="translate(182.95 200)"
                id="FacNos010__col1s"
            />
        </g></g>
    )
}

export default FacNos010
