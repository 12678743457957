import { AssetSvgProps } from "../../../shared/assets"

function ShiMot057({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#F1BF00"
                    d="M159.2 266.9v18.05q33.25 3.828 66.5 0V266.9q-33.25-5.57-66.5 0z"
                    id="ShiMot057__Layer5_0_FILL"
                />
                <path
                    fill="#AA151B"
                    d="M159.2 258.05v8.85h66.5v-8.85h-66.5m0 26.9v8.9h66.5v-8.9h-66.5z"
                    id="ShiMot057__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot057__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot057__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot057
