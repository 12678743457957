import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999",
    "col1s": "#434343"
}

function ShiSpe045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <linearGradient
                    id="ShiSpe045__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={250.75}
                    y1={301.912}
                    x2={206.45}
                    y2={268.988}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#333" />
                    <stop offset="100%" stopColor="#333" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ShiSpe045__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={250.775}
                    y1={290.35}
                    x2={260.925}
                    y2={290.35}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#333" />
                    <stop offset="100%" stopColor="#333" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ShiSpe045__Gradient_3"
                    gradientUnits="userSpaceOnUse"
                    x1={232.413}
                    y1={243.075}
                    x2={203.688}
                    y2={253.425}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#333" />
                    <stop offset="100%" stopColor="#333" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ShiSpe045__Gradient_4"
                    gradientUnits="userSpaceOnUse"
                    x1={154.8}
                    y1={243.65}
                    x2={185}
                    y2={254.95}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#333" />
                    <stop offset="100%" stopColor="#333" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ShiSpe045__Gradient_5"
                    gradientUnits="userSpaceOnUse"
                    x1={133.088}
                    y1={303.975}
                    x2={177.013}
                    y2={269.225}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#333" />
                    <stop offset="100%" stopColor="#333" stopOpacity={0} />
                </linearGradient>
                <path
                    fill={colors.col1n}
                    d="M128.7.1q-1.1.8-1.75 1.35.35-.35.7-.65-2.3 1.85-4.65 3.5-.55.35-1.05.75-.3.15-.6.4-12.9 8.65-27.8 12.45-11.55 3-24.25 3.05h-.65l-7.9-.25q-10.2-.8-19.55-3.5-14.05-4.05-26.25-12.5L8.75.05 8.6 0l.2.9q3 13.1 3.2 28.05l-.5 10.6q-.05.85-.15 1.65l-.2 1.1-.3.1q-.45 2.7-.95 5.4L2.45 79.5l-.4 1.2v-.05L0 86.1q31.8 8.1 66.3 7.9l2.25-4.6h.15l2.05 4.6q32.45.2 62.95-7.2l2.8-.7 1.05-.25-2.95-6.55-8.2-33.4q-.3-1.4-.5-2.7l-.05-.05-.15-.25q-.05-.1-.05-.2-.7-2.35-.75-2.85v-.05q-.05-.4-.1-.85L124 26.9q-.35-13.4 4.7-26.8z"
                    id="ShiSpe045__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M66.9 18.25H68.45q13.05-.4 23.2-4.5-11.55 3-24.25 3.05h-.65l-7.9-.25q-10.2-.8-19.55-3.5 10.6 4.7 24.6 5.15 1.5.05 3 .05M123.3 38l-.05-.05Q116 25.85 120.05.9q-.3.15-.6.4l-2.5 4.65q-1.3 2.25-3.35 4.35-2.65 2.6-6.5 5-1.6 1-3.45 1.95-1.65.9-3.5 1.7h-.05q-.35.2-.75.35h-.05l-.9.45q-12.7 5.15-31.35 5.8H63.9q-16.75-.55-29.65-5.8l-.9-.45h-.05q-.4-.15-.75-.35h-.05q-1.85-.8-3.5-1.7-1.85-.95-3.45-1.95-3.45-2.15-5.9-4.5-2.1-2-3.45-4.1-.3-.35-.5-.75L12.75.3l-.4-.3q.55 3.45.95 6.7 2.4 20.7-3.9 31.25-.05.1-.05.2-.05.1 0 .2 0 .05.05.1 0 .1.15.2.2.1.4.05.15-.05.3-.25.05-.1.15-.2.15-.35.4-.7Q17.6 25.4 14.7 6.7q-.05-.25-.1-.55.15.3.35.55 2.05 3.4 5.75 6.45h.05q2.1 1.7 4.8 3.35 1.4.85 3 1.65 1.7.9 3.55 1.7.35.2.8.35l.05.05.8.35q.05.05.15.05 8.8 4 15.8 8.9l.05.05q7.65 5.25 13.2 11.55.5.5.95 1.1 1.4 1.6 2.5 3.1v.55l-.05 38.35q-17.65-.05-35.7-2.45h-.05q-6.4-.8-12.75-1.9-.85-.15-1.65-.3Q8.45 78.15.3 76.2l-.3 1q8.35 1.95 16 3.4.8.15 1.7.3 6.4 1.1 12.8 1.9 18.1 2.4 35.85 2.45h1q18.25 0 36-2.45 6.15-.8 12.45-1.9 1.3-.2 2.6-.45 3-.55 6-1.15l6.4-1.4h.05q.85-.2 2.9-.6l-.55-1.05q-1.45.35-2.6.6l-.9.2q-2.75.65-5.5 1.25-3 .6-6 1.15-1.3.25-2.55.45-6.25 1.1-12.45 1.9-17.7 2.45-35.85 2.45l.05-38.35v-.2l.1-.1-.1.3q1.85-2.5 3.85-4.8 5.4-6.25 12-11.2l.05-.05q7.05-5.35 15.45-9.2.1 0 .15-.05l.8-.35.05-.05q.45-.15.8-.35 1.85-.8 3.55-1.7 1.6-.8 3-1.65 3.15-1.9 5.6-4 3.45-3 5.35-6.35Q115 25 121.7 37.25q.15.25.35.55.15.3.35.65.15.2.3.25.1.05.2 0 .1 0 .2-.05.05-.05.1-.05.1-.15.1-.25l.05-.05q0-.05.05-.1l-.1-.2m-42.45-7.75v.05Q75 35 69.8 41.1q-1.4 1.7-2.8 3.5v-.05q-1.3-1.75-2.7-3.4-.2-.25-.4-.45-5.05-5.75-11.85-10.7-4.95-3.6-10.8-6.8 10.3 3 22.65 3.4h4.65q12.85-.5 22.85-3.4-5.5 3-10.55 7.05m-13.2-4.55l.9.05h-1l.1-.05z"
                    id="ShiSpe045__Symbol_75_0_Layer0_0_FILL"
                />
                <g id="ShiSpe045__Layer11_0_FILL">
                    <path
                        fill="#666"
                        d="M193.35 258.35l-.1.05h1l-.9-.05m-.25 20.2l.1-.3-.1.1v.2z"
                    />
                    <path
                        fillOpacity={0.329}
                        d="M241.5 313.55q-6.3 1.1-12.45 1.9-17.75 2.45-36 2.45h-.55l2.05 4.6q33.95.2 65.75-7.9l1-.25-2-4.45-.95.2-.35-1v.05l.25 1-.45.1q-.224.055-.45.1-.399.105-.8.2h-.05l-6.4 1.4q-3 .6-6 1.15-1.3.25-2.6.45m11-84.95q-1.103.8-1.8 1.3.392-.29.75-.6-2.307 1.834-4.7 3.5-.5.368-1.05.7-3.981 25.015 3.25 37.1l.05.05.1.2q-.05.05-.05.1l-.05.05q0 .1-.1.25l.45.35q-.05-.3-.1-.55-.25-.9-.4-1.7-.1-.5-.15-1.05-.05-.4-.1-.85l-.8-12.05q-.344-13.4 4.7-26.8m-96.3 86.85q-6.4-.8-12.8-1.9-.9-.15-1.7-.3-7.65-1.45-16-3.4l.15-.55.4-1.3-.4 1.2v-.05l-2.05 5.45q31.8 8.1 66.3 7.9l2.25-4.6h-.3q-17.75-.05-35.85-2.45m-18.2-82.8l-5.45-4.1h-.05l-.1-.05q.1.437.2.9 2.982 13.103 3.2 28.05l-.5 10.6q-.05.85-.15 1.65l-.2 1.1-.1.45.25-.15q-.05-.05-.05-.1-.05-.1 0-.2 0-.1.05-.2 6.3-10.55 3.9-31.25-.409-3.226-1-6.7z"
                    />
                    <path
                        fill="url(#ShiSpe045__Gradient_1)"
                        d="M247.75 270.45q-.2-.3-.35-.55-6.7-12.25-3.65-31.1-1.9 3.35-5.35 6.35-2.45 2.1-5.6 4-1.4.85-3 1.65-1.7.9-3.55 1.7-.35.2-.8.35l-.05.05-.8.35q-.05.05-.15.05-8.4 3.85-15.45 9.2l-.05.05q-6.6 4.95-12 11.2-2 2.3-3.85 4.8l-.05 38.35q18.15 0 35.85-2.45 6.2-.8 12.45-1.9 1.25-.2 2.55-.45 3-.55 6-1.15 2.75-.6 5.5-1.25l.9-.2q.36-.078.65-.15.672-.131 1.05-.2v-.05l.9-.2-.5-1.1-8.2-33.3q-.266-1.453-.5-2.8l-.05-.05-.4-.6q.05.25.1.55l-.45-.35q-.05 0-.1.05-.1.05-.2.05-.1.05-.2 0-.15-.05-.3-.25-.2-.35-.35-.65m2.05 3.35q-.05-.1-.05-.15.1.1.2.25-.1-.05-.15-.1m7.8 33.65l-.05.05-.05-.05h.1z"
                    />
                    <path
                        fill="url(#ShiSpe045__Gradient_2)"
                        d="M257.55 307.5l.05-.05h-.1l.05.05z"
                    />
                    <path
                        fill="#2E2E2E"
                        fillOpacity={0}
                        d="M249.75 273.65q0 .05.05.15.05.05.15.1-.1-.15-.2-.25z"
                    />
                    <path
                        fill="url(#ShiSpe045__Gradient_3)"
                        d="M239.3 242.95q2.05-2.1 3.35-4.35l2.5-4.7q-12.91 8.675-27.8 12.5-10.141 4.103-23.2 4.5h-1.4v7.3q18.65-.65 31.35-5.8l.9-.45h.05q.4-.15.75-.35h.05q1.85-.8 3.5-1.7 1.85-.95 3.45-1.95 3.85-2.4 6.5-5z"
                    />
                    <path
                        fill="url(#ShiSpe045__Gradient_4)"
                        d="M138.75 233.2l-.35-.25 3 5.65q.2.4.5.75 1.35 2.1 3.45 4.1 2.45 2.35 5.9 4.5 1.6 1 3.45 1.95 1.65.9 3.5 1.7h.05q.35.2.75.35h.05l.9.45q12.9 5.25 29.65 5.8h3.15v-7.3h-.15q-1.5 0-3-.05-13.995-.44-24.65-5.15-14.002-4.052-26.2-12.5z"
                    />
                    <path
                        fill="url(#ShiSpe045__Gradient_5)"
                        d="M140.65 239.35q-.2-.25-.35-.55.05.3.1.55 2.9 18.7-3.9 30.85-.25.35-.4.7-.1.1-.15.2-.15.2-.3.25-.2.05-.4-.05-.15-.1-.15-.2l-.25.15.1-.45-.3.1q-.45 2.7-.95 5.4l-7.45 31.7-.4 1.3.15-.45q8.15 1.95 15.9 3.4.8.15 1.65.3 6.35 1.1 12.75 1.9h.05q18.05 2.4 35.7 2.45l.05-38.35V278q-1.1-1.5-2.5-3.1-.45-.6-.95-1.1-5.55-6.3-13.2-11.55l-.05-.05q-7-4.9-15.8-8.9-.1 0-.15-.05l-.8-.35-.05-.05q-.45-.15-.8-.35-1.85-.8-3.55-1.7-1.6-.8-3-1.65-2.7-1.65-4.8-3.35h-.05q-3.7-3.05-5.75-6.45z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe045__Symbol_74_0_Layer0_0_FILL"
                transform="translate(123.8 228.5)"
                id="ShiSpe045__col1n"
            />
            <use
                xlinkHref="#ShiSpe045__Symbol_75_0_Layer0_0_FILL"
                transform="translate(125.7 232.65)"
                id="ShiSpe045__col1s"
            />
            <use xlinkHref="#ShiSpe045__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe045
