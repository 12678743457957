import { AssetSvgProps } from "../../../shared/assets"

function ObjMor008({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#1E1E1E"
                    d="M240.4 236.8l-5.2 2.9-26.35 32.45q-.155.238-.3.45v-.1l-.35.45-1.05 1.05-1.25 2.55q-1.773 3.51 6.8-4.4l27.7-35.35m-90.55 2.9l-5.2-2.9L174.1 274q7.666 5.134 3.6 0l-27.85-34.3z"
                    id="ObjMor008__Layer14_0_FILL"
                />
                <path
                    fill="#333"
                    d="M182.2 274h-14.5v28.55h49.65V274h-4.15q-2.126-1.392-4.65 0h-5.05q-9.965-2.741-21.3 0z"
                    id="ObjMor008__Layer5_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M209.65 285.1q-.55.55-.55 1.35t.55 1.35q.55.55 1.35.55t1.35-.55q.55-.55.55-1.35t-.55-1.35q-.55-.55-1.35-.55t-1.35.55m2.7-6.4q-.55-.55-1.35-.55t-1.35.55q-.55.55-.55 1.35t.55 1.35q.55.55 1.35.55t1.35-.55q.55-.55.55-1.35t-.55-1.35m-11.7-1.2q.3.25.6.55.807.807 1.4 1.7.094.07.15.15 2.15 3.074 2.15 7 0 5.1-3.7 8.75-1.05 1.1-2.25 1.85-2.9 1.85-6.5 1.85-4 0-7.15-2.25v3.65H193q4.3-.1 5.5-.65 1.35-.7 1.65-.8 5.85-3.05 6.45-9.8v-12h-5.95m-18.75 4.9q-.9 2.098-.9 4.55 0 4.7 3.35 8.05.5.5 1.05.95 3.05 2.45 7.05 2.45 3.35 0 6-1.75 1.05-.7 2-1.65 3.4-3.35 3.4-8.05 0-3.513-1.9-6.3-.641-.941-1.5-1.8-.8-.8-1.6-1.35-.5-.35-1-.65-2.3-1.25-5.1-1.3h-.3q-4.75 0-8.1 3.3-.772.784-1.4 1.6-.611.946-1.05 1.95m10.85-5q3.8.1 6.55 2.8 2.85 2.85 2.85 6.85 0 3.95-2.85 6.8-.85.8-1.75 1.4-2.25 1.45-5.1 1.45-4 0-6.85-2.85-2.8-2.85-2.8-6.8 0-2.16.8-4 .271-.535.6-1.1.573-.908 1.4-1.75 2.85-2.85 6.85-2.8.15-.05.3 0m-20.05-2h-3.6v25.35h3.6V275.4z"
                    id="ObjMor008__Layer5_1_MEMBER_0_FILL"
                />
                <g id="ObjMor008__Layer5_1_FILL">
                    <path
                        fill="#8C8C8C"
                        d="M215.15 275.4h-8.55v25.35h8.55V275.4m-1.95-1.4v-1.85h-4.65V274h4.65m-14.4-4.7h-11.9l-4.7 4.7h21.3l-4.7-4.7z"
                    />
                    <path d="M180.95 282q-.9 2.281-.9 4.9 0 5.1 3.6 8.75.8.8 1.7 1.45 3.15 2.25 7.15 2.25 3.6 0 6.5-1.85 1.2-.75 2.25-1.85 3.7-3.65 3.7-8.75 0-3.926-2.15-7-.056-.08-.15-.15-.593-.893-1.4-1.7-.3-.3-.6-.55-.4-.35-.8-.65-1.15-.9-2.45-1.45-2.15-.9-4.65-.95h-.25q-2.65 0-4.9.95-2.15.9-3.95 2.65-.75.77-1.35 1.6-.805 1.135-1.35 2.35z" />
                </g>
            </defs>
            <use xlinkHref="#ObjMor008__Layer14_0_FILL" />
            <use xlinkHref="#ObjMor008__Layer5_0_FILL" />
            <use xlinkHref="#ObjMor008__Layer5_1_FILL" />
            <use xlinkHref="#ObjMor008__Layer5_1_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMor008
