import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor078__Layer10_0_FILL">
                    <path
                        fill="#8E58A6"
                        d="M236.45 270.25v-.05q-9.15-1.496-17.7 0v.05q0 2.496 1.2 4.5 7.638 2.081 15.25 0 1.25-2.004 1.25-4.5z"
                    />
                    <path
                        fill="#FFF600"
                        d="M235.25 265.8q-.555-.955-1.4-1.8-2.6-2.6-6.25-2.6-3.6 0-6.3 2.6-.829.845-1.4 1.8 7.887 1.699 15.35 0z"
                    />
                </g>
                <g id="AccMor078__Layer10_1_FILL">
                    <path d="M219.95 274.75q.543.928 1.35 1.75 2.7 2.6 6.3 2.6 3.65 0 6.25-2.6.822-.822 1.35-1.75h-15.25z" />
                    <path
                        fill="#FFF"
                        d="M236.45 270.2q-.012-2.434-1.2-4.4H219.9q-1.138 1.966-1.15 4.4h17.7z"
                    />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor078__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor078__Layer10_0_FILL" />
            <use xlinkHref="#AccMor078__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor078__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor078__col1l"
            />
        </g></g>
    )
}

export default AccMor078
