import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M189.7 96.15l3.45.5v.7q0 36.2-23.3 62.75-2.05 2.25-4.2 4.45l-.55.55q-1.6 1.6-3.2 3.1-2.05 1.85-4.1 3.6-.25.15-.4.35 0 .05.05.1h37.4v-62.4q.05-12.4-1.25-24.4Q190 52.6 165.8 28.4 137.35-.05 97.15 0 56.95-.05 28.5 28.4 4.1 52.8.7 85.9v.2q-.6 8.15-.7 15.95v70.3h37.35q0-.05.1-.15l-.5-.4q-2.1-1.75-4.1-3.6-1.65-1.5-3.25-3.1l-.55-.55q-6.9-7-12.05-14.7Q1.55 126.8 1.55 97.35q0-.95.05-1.9l.65-.15h.25q.05-.05.1-.05h.1q.85-.2 1.7-.35l1.65-.35Q58.7 82.5 96.6 41q41.65 45.55 92 54.95l1.1.2z"
                    id="HaiMed009__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.25 148.05L2.7 121.1q-.1-.3-.15-.6v51.85h9.5V152.5q2.5 6.4 5.95 7.8v12.05h17.75q0-.05.1-.15l-.5-.4q-.7-.55-1.35-1.15-1.4-1.2-2.75-2.45-1.65-1.5-3.25-3.1l-.55-.55q-6.9-7-12.05-14.7-.6-.9-1.15-1.8M7 93.95q.5-.2.95-.4.55-.35.3.1h.05q25-17.85 43.25-45.6Q28.75 77.35 0 95.45q4.45-.6 7-1.5M95.7 0h-.15Q79.25 0 64.9 4.65q-14.35 4.65-26.8 14Q57.9 7.4 67.3 4.95 87.45-.75 95.7 8.7 108.95-6.2 154.75 20 129.35 0 95.7 0m94.35 115.1q-.055.538-.15 1.05-6.673 27.224-23.2 45.6-.212.254-.45.5-1.083 1.157-2.2 2.3l-.55.55q-1.6 1.6-3.2 3.1-1.4 1.25-2.75 2.45-.7.6-1.35 1.15-.25.15-.4.35 0 .05.05.1h23.2v-15.7q3-1.75 5-7.5v23.2h6V115.1m-7.6-20.2l.2.05q1 .3 1.95.55 2.05.5 4 .75l1.3.1q-30.65-26.95-50-57.1 16.15 29.55 42.55 55.65m-16.5-4.6l3.2 1.1q.4.15.75.25 1 .4 1.85.55.75.15 1.45.1-25.8-20.05-43.15-42.8 11.8 20.8 36.1 40.35-41.55-20.5-70.8-54.7Q80 53.5 62.55 66.7q-.15.05-.25.15l-.9.7q-10.55 7.6-27.65 15.3l5-2.75Q59 64.95 77.3 37.05q-30 36.35-57.65 53.2-.85.3-1.7.65.7-.25 1.45-.5.45-.15.95-.3l.3-.1Q50.3 80.75 75.5 59.55q.15-.15.35-.3.25-.2.45-.35.2-.2.45-.4l.5-.4q.2-.2.4-.35l.5-.4q.2-.2.45-.4l.7-.6q.2-.2.45-.4.85-.75 1.7-1.55.2-.2.45-.4.4-.35.75-.65.4-.35.75-.7.25-.25.5-.45.35-.35.75-.7.2-.25.45-.45.2-.15.4-.35l.3-.3.5-.5q.25-.2.5-.45l.8-.8q.65-.65 1.35-1.3.25-.3.5-.55l.4-.4.5-.5q.45-.5.9-.95l.4-.4.8-.8q.2-.25.45-.45.45-.5.95-1 .15-.25.35-.4.3-.35.55-.6.1-.15.25-.3 32.6 35.65 70.5 49.15.2.1.45.15z"
                    id="HaiMed009__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed009__Symbol_65_0_Layer0_0_FILL"
                transform="translate(95.1 56.45)"
                id="HaiMed009__col1n"
            />
            <use
                xlinkHref="#HaiMed009__Symbol_36_0_Layer0_0_FILL"
                transform="translate(96.7 56.45)"
                id="HaiMed009__col1d"
            />
        </g></g>
    )
}

export default HaiMed009
