import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.35 12.8q4.2-4.4 1.1-12.2l-1.1 6.25L42.95.1l-1.05 8L39.55.25l-.5 8.35-2.1-7.6-.9 8.35L33.3.5l-.75 9.35L29.8 0l-.6 9.95L26.55.1l-.65 9.65-1.95-8.3V.8l-.05.3-.1-.3v.65l-1.95 8.3L21.2.1l-2.65 9.85-.6-9.95-2.75 9.85L14.45.5 11.7 9.35 10.8 1 8.7 8.6 8.2.25 5.85 8.1 4.8.1 2.4 6.85 1.3.6q-3.1 7.8 1.1 12.2 2.95 1.65 7 2.55 7.3 1.7 14.4 1.75 7.15-.05 14.55-1.75 4.05-.9 7-2.55z"
                    id="BeaChi008__Symbol_47_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi008__Symbol_47_0_Layer0_0_FILL"
                transform="translate(168.65 234.8)"
                id="BeaChi008__col1n"
            />
        </g></g>
    )
}

export default BeaChi008
