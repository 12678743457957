import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000"
}

function ShiDre004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M130.15 2.8l-2.8 1.9-9.2 27.45q-34.05-4.8-41.8 15.9-.05.05-.05.1-7.7-20.8-41.85-16L25.3 4.7q-1.45-.95-2.85-1.9L19.55.65q-.45-.3-.85-.6l11.15 32.9-9.85 2.7h-.05v.1h-.1q-.1.05-.15.05l-2.3 11.8q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8l-.05.2h.05l-.95 3.4V79h-.1v.15L0 95.7h.45q.95-.2 2.25-.35h.25q.45-.2.95-.35.25-.05.5-.15 3.9-1.2 7.3-1.2 2.75 0 9.5 1.5l4.25-.1q2.45-.7 3-.8 1.3-.3 2.6-.45 1.65-.25 3.2-.25 1.95 0 4.3.55.65.15 1.3.35 3.15.85 7.1.85 1.3 0 3.8-.8 2.5-.85 5.05-.85 1.55 0 3.95.85l.7.2q1.9.6 2.8.6.4 0 1.7-.5 0-.05.05-.05 1.3-.6 3.1-.6 1.9 0 2.9.45.35.15.9.25.9.15 2.35.15 1.45 0 2.6-.15.85-.15 1.6-.35 1.7-.55 3.9-.55 2.1 0 4.8.75l.3.1q2.4.65 2.5.65 1.05 0 3.95-.75l.7-.2q3.45-.95 5.3-.95 2.05 0 4.15.65.4.1.8.25 1.75.4 3.85.4 1.95 0 3.95-.7.05 0 .15-.05 2.05-.75 5.6-.75 1.6 0 3.75.4 1.15.2 2.4.5l3.7.9q3.6 0 5.85-.75 2.25-.75 4.9-.75 1.5 0 4.15.75 1.35.35 2.95.9.9.25 2.05.4h4.35l-9.85-16.55v-.3h-.1v-.1l-.9-3.25q.05-.05.1-.05l-5.2-20.95q-.65-3.1-1.3-6.15L132.8 35.7h-.1v-.05l-.05-.15-9.9-2.55L133.95 0l-.7.5q-.75.6-1.55 1.15l-1.55 1.15M19.45 38.15q-.05 0-.1.05.05-.45.15-.95.05-.05.15-.05l-.2.95z"
                    id="ShiDre004__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M133.65 4.4l.1.75q1.15 6.65.75 10.85-.3 3.3-1.6 5.1l-1.6 1.5q-8.65 7.2-22.4 5.55l-1.8-.3q-2.3-.35-4.45-.75-21.95-4.3-26-13.5l-.2-.5q-.5.25-.6.5-4.05 9.2-26 13.5-2.15.4-4.45.75l-1.8.3q-12.85 1.55-21.25-4.7-1.25-.9-2.35-1.95-1.75-2.35-1.95-6.65-.2-3.1.4-7.15l.2-1.4.8-3.9q-.05 0-.1.05.05-.45.15-.95.05-.05.15-.05l.3-1.45h-.1q-.1.05-.15.05l-2.3 11.8q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8l-.05.2h.05L9.95 43v.25h-.1v.15L0 59.95h.45q.95-.2 2.25-.35h.25q.45-.2.95-.35 1.1-1.05 2.55-2.7.85-.95 1.8-2.15 3.2-4 5.55-7.85 1.25-2.05 2.6-4.9 1.6-2.6 3.25-5.05 4.85-7.4 6.95-7.4.25 0 .5.05.1.05.15.15.4.5.4 1.2 0 .25-.9 4.1-.9 3.85-2.25 9.35t-2.4 10.5q-.1 3.35 3.55-1.4Q27 51.4 31.9 42.5q2.3-3.6 3.2-5.05 2.2-3.5 3.35-4.75 2.7-3.05 5.5-3.05.85 0 1.4.85.45.8.45 1.9 0 1.8-.7 4.85-.7 3-1.55 5.9-.9 2.9-.85 7 0 4.1 2.75.1 2.4-6.7 3.2-8.35v-.1q.25-.6.55-1.15l.3-.9q1.75-5.3 1.95-5.75.85-2.15 2.5-4.4 2.6-3.7 5-3.7 1.25 0 1.25 3.3 0 1.6-.3 5.35-.35 3.7.15 8.35.5 4.65 2.2.1 1.7-4.6 3.85-10.55 1.7-4.9 3.6-6.9 2.1.15 2.55 3.65.25 1.9.35 4.6 3.05 36.05 6.45 0 0-.2-.05-.4.45-3.2.8-4.75.65-2.75 2.75-2.75.35 0 .65.1 1.65 2.05 3.2 6.45Q88.55 38.4 90.25 43q1.7 4.55 2.2-.1.5-4.65.15-8.35-.3-3.75-.3-5.35 0-3.3 1.25-3.3 2.4 0 5 3.7 1.65 2.25 2.5 4.4.2.45 1.95 5.75l.3.9q.3.55.55 1.15v.1q.8 1.65 3.2 8.35 2.75 4 2.75-.1.05-4.1-.85-7-.85-2.9-1.55-5.9-.7-3.05-.7-4.85 0-1.1.45-1.9.55-.85 1.4-.85 2.8 0 5.5 3.05 1.15 1.25 3.3 4.75.95 1.45 3.25 5.05 4.9 8.9 6.25 10.65 3.65 4.75 3.55 1.4-1.05-5-2.4-10.5-1.35-5.5-2.25-9.35-.9-3.85-.9-4.1 0-.5.2-.9 2.9.15 3.9.95.95.75 4.3 6.05 1.05 1.65 2 3 1.4 3.2 2.7 5.55.4.7.75 1.3 2.35 3.85 5.55 7.85.95 1.2 1.8 2.15 2 2.3 3.3 3.4h3.15l-9.85-16.55v-.3h-.1V43l-.9-3.25q.05-.05.1-.05l-5.2-20.95q-.65-3.1-1.3-6.15l-1.6-8.2m2.8 15.55l.05.4-.1-.35.05-.05z"
                    id="ShiDre004__Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre004__Symbol_89_0_Layer0_0_FILL"
                transform="translate(116.2 230.45)"
                id="ShiDre004__col1n"
            />
            <use
                xlinkHref="#ShiDre004__Symbol_54_0_Layer0_0_FILL"
                transform="translate(116.2 266.2)"
                id="ShiDre004__col1d"
            />
        </g></g>
    )
}

export default ShiDre004
