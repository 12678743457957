import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFEAAD",
    "col1n": "#FFD446",
    "col1s": "#E09F00"
}

function ObjMus023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M95.4 61.85q-.55-.4-1.2-.8l-21.55-13.8q1 .3 2.35-.4l.7-.4L98.9 61.3q.05 0 .05.05 19.7 12.85 14.75 31 10.15-9.2 15.7-17.95 3.6-5.7 6.05-12.25 1.15-3.15 2.75-9.4-18.05 11.4-34.7 1.4l-24.1-13.5q.05-.35.05-.7.1-2-1.1-2.75-1.2-.75-2.95.15-.5.25-.95.6L72 36.55q.15-.65.2-1.3.05-1.95-1.15-2.7-1.15-.75-2.9.15-.75.35-1.4.9l-2.3-1.3q.25-.9.3-1.75.1-2-1.05-2.75-1.2-.75-3 .15-.95.45-1.75 1.3L39.1 18.15q-5.65-3.05-10.35-2.65-1.65.15-3.2.7-.45.15-.9.35l-6.7-4.35h-.25l-4.15-2.65q.05-.9-.35-1.9-.1-.2-.15-.35-.15-.3-.3-.55-.15-.25-.25-.4-.5-.65-1.1-1.2L8.3 0 0 11.35l6.7.25q.2.1.45.25 1.45.65 2.95.55.85-.05 1.5-.25 0-.05.05-.05l4 2.6.1-.1 5.35 3.45q-2.75 1.5-5.15 3.85-5.05 4.5-5.05 11.75-.9 10.6 9.8 16.85l2.8 2.1 46.15 29.8q.15.1.35.2 3.1 1.75 6.3 3.5 7.8 4.25 16.1 1.75 8.55-4.8 8.4-12.25v-.15q.35-5.4-1.85-9.4-1.3-2.35-3.55-4.2m-2.95 1.9q4.45 3.7 3.4 9.5-.1.65-.3 1.35-1 3.95-3.95 6.65-.3.2-.55.45-5.25 4.9-10.3 1.95l-2.4-1.55L90.8 62.65l1.65 1.1m-3.3-2.15l-1.3 2.05-.1.1-11.05 17.3-9.5-6.1q-.15 0-.3.05l-1.5.15h-.1l-.75-.05q-1.25-.15-2.25-.55l-.3-.1q-1-.45-1.8-1.2-.2-.15-.35-.35-1.75-1.75-2.15-4-.15-.7-.15-1.45 0-.85.15-1.6.45-2.2 2.15-3.85 1.05-1.05 2.3-1.6.75-.35 1.55-.5.2-.05.45-.1h.05q.15-.05.4-.05h.05q.15-.05.4 0 .15-.05.35 0l.8.05q.4 0 .8.1l.3.1.05-.05V60h.05l5.5-8.8 16.25 10.4m-31.2-23.75q1 .3 2.4-.4l.7-.4 2.75 1.75q-.1.4-.1.8-.05 1.55.65 2.35l-6.4-4.1m.4 4.05l2.05 1.3-12.45 19.45-2-1.3 12.4-19.45m-1.95-7.85q-.1.4-.1.8-.05 1 .25 1.7.15.35.45.65L30.2 20.1q3.8.1 8.25 2.5L56.4 34.05m9.4 12.6l1.75 1.1L55.1 67.2l-1.8-1.15 12.5-19.4m1.95-4.45q.3-.2.65-.45l2.7 1.75q-.1.35-.15.75-.05 1.55.7 2.35l-6.3-4q1 .3 2.4-.4M16.2 35.4q-.05-1 .1-2 .3-2.6 1.7-5.4 3.25-5.4 7.55-7.1L53 38.45 40.55 57.9l-16.9-10.85q-7.2-5.25-7.45-11.65z"
                    id="ObjMus023__Symbol_107_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 15.35l1.55 1L11.4 1 9.85 0 0 15.35m18.9-9.5l-1.55-1-9.85 15.3 1.55 1.05L18.9 5.85m2.4 12.3l4.85-7.6-1.55-1-6.05 9.4q.75-.35 1.55-.5.2-.05.45-.1h.05q.15-.05.4-.05v-.1q.15-.05.3-.05m64.5 14.8q3.6-5.7 6.05-12.25-11.7 7.5-37.05-7.6l31 19.85z"
                    id="ObjMus023__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    fillOpacity={0.749}
                    d="M57.95 66.35l1.65 1.05 1.4-2.15-1.7-1.05-1.35 2.15M36.35 52.5l2.6 1.7q-.15-.7-.15-1.45 0-.85.15-1.6l-1.25-.8-1.35 2.15m-13.2-11.45L21.8 43.2l5.4 3.45 1.35-2.15-5.4-3.45m7.45 4.7l-1.4 2.2 5.35 3.4 1.4-2.15-5.35-3.45m2.45-20.05l5.3 3.4 1.25-1.9-5.35-3.45-1.2 1.95M9.75 8.1l4.75-.7-3.05-2Q9 5.3 6.8 6.2l2.95 1.9m-7.4-4.75q1.7-.95 3.5-1.55L3.1 0 0 1.85l2.35 1.5M70.4 46.9l-1.3 2.05-.1.1 1.7 1.05 1.35-2.15-1.65-1.05M48.8 33.05l-1.25 2 5.35 3.45 1.25-2-5.35-3.45m-3 .9l1.25-2-5.4-3.45-1.25 1.95 5.4 3.5m10.85-11.3q-.5.25-.95.6-1.15.85-2.1 2.3-1.05 1.65-1.25 3.25-.1.35-.15.75-.05 1.55.7 2.35.15.2.45.4.25.15.55.25 1 .3 2.35-.4l.7-.4q1.3-.9 2.4-2.5 1.05-1.7 1.3-3.3.05-.35.05-.7.1-2-1.1-2.75-1.2-.75-2.95.15m-.8 2.7q.95-.6 1.55-.2.6.35.5 1.5-.15 1.1-.9 2.3-.8 1.2-1.75 1.8-1 .55-1.55.2-.65-.4-.5-1.5.1-1.15.9-2.35.75-1.2 1.75-1.75M48 18.9q-.95.8-1.7 2-1.05 1.6-1.25 3.2-.1.4-.1.8-.05 1.55.65 2.35.2.2.45.35.25.2.55.3 1 .3 2.4-.4.3-.2.65-.45 1.35-.9 2.4-2.5.9-1.4 1.2-2.7.15-.65.2-1.3.05-1.95-1.15-2.7-1.15-.75-2.9.15-.75.35-1.4.9m2.5 3q-.15 1.1-.9 2.35-.75 1.15-1.75 1.75-.95.6-1.55.2-.6-.4-.5-1.5.15-1.1.9-2.3.75-1.25 1.7-1.8 1-.6 1.6-.2.6.35.5 1.5m-4.8-4.3q.25-.9.3-1.75.1-2-1.05-2.75-1.2-.75-3 .15-.95.45-1.75 1.3-.7.65-1.25 1.6-1.05 1.6-1.3 3.2-.1.4-.1.8-.05 1 .25 1.7.15.35.45.65.15.2.4.4.25.15.55.25 1 .3 2.4-.4l.7-.4q1.3-.9 2.35-2.5.7-1.15 1.05-2.25m-2.95-1.9q.55.4.45 1.55-.1 1.1-.85 2.3-.8 1.2-1.75 1.8-1 .55-1.6.2-.6-.4-.5-1.5.15-1.15.95-2.35.75-1.2 1.7-1.8.95-.6 1.6-.2z"
                    id="ObjMus023__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus023__Symbol_107_0_Layer0_0_FILL"
                transform="translate(187.85 224.15)"
                id="ObjMus023__col1n"
            />
            <use
                xlinkHref="#ObjMus023__Symbol_67_0_Layer0_0_FILL"
                transform="translate(231.45 265.6)"
                id="ObjMus023__col1l"
            />
            <use
                xlinkHref="#ObjMus023__Symbol_56_0_Layer0_0_FILL"
                transform="translate(206.6 238.85)"
                id="ObjMus023__col1s"
            />
        </g></g>
    )
}

export default ObjMus023
