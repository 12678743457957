import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}
function FacMou091({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M43.4.05V0H0v.05q1.3 2.3 3.45 4.1l.3.3q4.2 3.15 11.5 4.4 3.2.65 6.45.6 3.25.05 6.45-.6 7.3-1.25 11.5-4.4l.3-.3q2.15-1.8 3.45-4.1z"
                    id="FacMou091__Symbol_164_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M.65.5q3.33 8.542 8.1 10.35V.5H.65M17 3.95V.5H9.55v3.45H17M26.1.5v10.35Q30.85 9.05 34.2.5h-8.1m-.8 3.45V.5h-7.45v3.45h7.45z"
                    id="FacMou091__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou091__Symbol_164_0_Layer0_0_FILL"
                transform="translate(170.8 221.5)"
                id="FacMou091__col2n"
            />
            <use
                xlinkHref="#FacMou091__Symbol_46_0_Layer0_0_FILL"
                transform="translate(175.1 222)"
                id="FacMou091__col1n"
            />
        </g></g>
    )
}
export default FacMou091
