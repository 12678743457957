import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#435125",
    "col1n": "#617433",
    "col1s": "#252C16"
}

function ShiSpe019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M100.55 29.25l-4.5-.15q-25.45-1.9-45.8-16l-6.2-4.65v.1q-5.35-5.1-8.7-7.45-3.35-2.2-6.7 0-.7.55-1.4 1.35-.4.45-.75.9-.3.45-.55.9-.65 1.1-.9 2.4-.7 3.3.95 7.85l-1.05 2.2.05.05-3.55 7.35q-.25.55-.4 1-8.65 19.4-12 31.95L9 57.1q-.996 3.636-1.55 6.7h.05q-.05.05-.05.15-1.05 6.1-.45 9.9 0 .2.1.45 0 .1.05.2v.5l-.05.25v.1q-.1 1.3-.25 2.55L5.8 83.05Q3.95 90.3 0 97.55l.1.1v-.05q.2.1 8.1 1.7 5.65 1.1 6.55-6.2.35-2.95-.05-7.25-.35 1.1-.55 1.7-.05-.2-.05-.35.5-1.85.95-3.55 4.65-17 6.3-17.7.1-.05.25-.05l.3-.1q8.1-2.3 13.45-3.3 2.7-.55 6.2-2.2 1.5-.75 2.85-1.5 1.5-.9 2.75-1.85.85-.65 1.6-1.3 2.4-1.65 4.95-3.75l.25-.2q3.05-2.45 6.45-3.95l2.7-.9Q90.55 41 98.75 31.6q.7-.9 1.3-1.75l.5-.6M184.3 14.7v-.1q-.7-1.25-1.4-2.4v-.05q.55-5.2-2.7-8.25l-.25-.25-.15-.1q-.5-.45-1.05-.85-.8-.55-1.75-1.05-.45-.2-.9-.35-.35-.15-.7-.25-3.3-.9-5.8 1.1-.25.2-.5.45-1.3 1.35-2.4 2.75-1.1 1.4-2.75 3.1-.45.4-1.4 1.15-.15.1-.3.25-.15.05-.25.15-2.1 1.55-3.7 2.7-22.75 15.95-51.75 16.65l.45.5q.25.35.55.7 8.8 10.65 31.85 14.95 3.15.6 5.2 1.35.25.1.45.2.35.1.7.25 1.3.5 2.25 1 3.5 2.2 6.3 5l.1.1q.45.55.9 1.15 2.4 2.05 4.9 3.45.05 0 .35.2h.2q.05.05.15.1.55.35 1.1.65l1.2.7q3.35 1.9 6.5 3.05 3.75 1.35 10.45 3.2 3.8 1.2 6.2 2.7 1.35 3.55 4.05 20.75.2 1.25.4 2.25.3 4.5.6 6.2-.15.7-.35 1.55 13.15-3.05 19.7-6.05.05-.05.1-.05-5.15-8.4-6.55-17.3v-.1q-.15-.9-.3-1.75l-.2-1.85q2-8.55-1-12.7-.2-.3-.4-.65l.1.1q-1.72-2.707-3.4-5.6-4.85-8.7-8.4-19.15-1.5-10.6-6.4-19.55z"
                    id="ShiSpe019__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.45 63.05v.1q-.1 1.3-.25 2.55L0 71.55l3.3 8.1L1.55 62.3l-.05.25v.25l-.05.25m1.95-18.2l-.05.05q-.996 3.636-1.55 6.7h.05q.95-2.2 2.5-4.45 2.95-4.1 6.4-6.3 4-2.5 5.7-6.7.55-1.5 1.5-5.65-1.2 3.45-3.3 6.1-2.4 2.95-6.75 6-2.35 1.6-4.5 4.25m16.95-42.6v.05L19.3 4.5l.05.05q1.4 2.55 3.35 4.05 3.35 2.5 8.2 1.9-5.7-2.4-8.6-5.45-.85-.9-1.45-1.85-.25-.5-.5-.95M75.8 17.3q-4.85 0-18.65-3.3-.2-.05-.35-.05l-2.1-.5Q38.8 9.6 32.1.9q.25.8.5 1.55.25.35.55.85 1.15 1.7 2.3 2.85l2.8 2.8.9.8q5.4 4.2 13.3 6.2.4.15.7.25 2.5.9 2.5 2.65.15.4.15.85 0 2.2-3.65 3.5-2.15.75-7.6 1.85-2.6.65-7.95 1.9-1.2.35-2.2.8-.65.5-1.35 1.1-3.35 2.9 7.5 1Q57.4 27.2 82 17.25q-.95.05-6.2.05M153.35 7q2.9-1.75 5.05-4.2t2.2-2.55Q147.95 8.7 138 12.5q-4.35 1.7-9.3 2.9-6.6 2.3-13.45 2.95 13.75 9.9 28.95 11.4 3 .35 5.1.35 2.95-.05 4.05-.8v-.25q0-1.45-1.7-2.2-.6-.25-1.3-.45-1.4-.35-6.65-.75-9-.65-9.65-4.5-.3-.6-.3-1.35 0-1.05.5-1.75.1-1.45 4.7-3.2.1-.1.25-.15 4.05-2 4.75-2.35 2.8-1.6 4.65-2.6 1.8-1.05 4.75-2.75M196 78.8q.75-10.85 2.65-15.1v-.05q-.15-.9-.3-1.75l-.2-1.85-1.2 2.25q-1.5 2.7-1.6 8.65-.1 5.9.65 7.85m-13-47.45q.35 1.9.85 3.2 1.3 3.5 2.7 5.15 1.5 1.75 4.2 3.2 1.35.75 4 2.2.35.25.75.45.7.5 1.25 1.15l.1.1q-1.72-2.707-3.4-5.6-3.2-1.1-5.4-3.1-4-3.55-5.4-10.4V29l.35 2.35m-4.35-28.9V2.4q-.7-1.25-1.4-2.4-.5 3.2-7.6 10.5 6.25-2.95 9-8.05z"
                    id="ShiSpe019__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M41.65 37.85v-13.4q-1.25-1.1-1.5-3.1-1.55-.15-3.15-.55l-.7-.2q-.85-.3-1.6-.55-11.2-4.35-13-16.25-.25-1.9-.2-3.8-.15 0-.25.05Q19.45.8 18 1.7q-5.75 5.05-5.8 14.75v.8q-.05.65 0 1.3.05 1.45.25 3.1 0 .05.05.15.1 5.15-2.3 10.15Q5.3 33 .4 31.9l-.4-.2v.2q.25 2.75 9.85 4.8.9.15 1.85.35 10.1 1.9 24.85 1.1h.2q2.1-.15 4.3-.3h.6m165-6.8q4.1-1.7 4-3.65-6.55 3-14.5 1.65-3.25-12.55-3.25-12.95-.85-6.15-3.45-10.35-1.25-1.45-1.5-1.7-.75-.7-1.7-1.35-.1.55-.15 1.1-.35 2.4-1.1 4.5-2.35 6.5-8.35 10.05-4.05 2.15-8.85 2.95-.4 1.65-1.4 2.8l-.2.4v12q3.8.5 7.55.65 9 .3 17.5-1.45 2.9-.55 5.9-1.35 4.65-1.35 9.5-3.3z"
                    id="ShiSpe019__Symbol_99_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe019__Symbol_101_0_Layer0_0_FILL"
                transform="translate(88.5 220.1)"
                id="ShiSpe019__col1n"
            />
            <use
                xlinkHref="#ShiSpe019__Symbol_100_0_Layer0_0_FILL"
                transform="translate(94.15 232.3)"
                id="ShiSpe019__col1d"
            />
            <use
                xlinkHref="#ShiSpe019__Symbol_99_0_Layer0_0_FILL"
                transform="translate(88.6 286)"
                id="ShiSpe019__col1s"
            />
        </g></g>
    )
}

export default ShiSpe019
