import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#621F00",
    "col1n": "#9A3200"
}

function AccMor047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M87.8 1.3q-2.15 1.05-6.5 2.65-2.95 1-6.45 2.25-1.15.4-2.3.7-3.35 1.15-5.35 1.65-2.05.5-4.55 1.05l-1.5.3q-16.3 2.6-32.6 0-2.45-.45-4.85-1.05-1.1-.3-2.25-.6Q19.4 7.7 17.2 7q-.5-.2-1-.3-1.65-.65-3.35-1.25-1.4-.5-2.85-1.15-1.35-.5-2.6-1.1Q3.8 1.85 1.7.8.3.15 0 0q.15.55.7 1.95.1.1.1.2 3.1 7 7.55 12.9Q9.9 17 11.55 18.9q.6.7 1.3 1.4l.15.15q5.35 5.55 12.25 9.9L16.3 62.2l-.25.5 3.35.95 2.35-7.45 2.6.75-2.2 7.4 3 .75L27 57.75l1.55.4-1.85 9.1 3.5.3.25-1.1.9.3L32.6 60l1.35.15-.95 7.7 3.1.25v-.15l1.05.25.9-7.25 2.2.6-.55 6.85.85.1-.1.6 3.4.85.5-7.25 1.15.4.1 5.8.75.1-.1 1.65 2.35.55.3-9.2 2.6.3.3 7.2 3.5.35-.7-7.25 2.45.2.75 7.25 2.45.1L55.1 40.5q4.55.1 8.7-1.25 1.1-.4 2.25-.9.85-.95.85-2.2 0-1.2-1.55-2.15.6-.6 2.05-1.55 2.6-1.8 2.6-3.55 0-1.55-.55-2.05 3.95-3 7.35-6.55l1.85-1.85Q79.8 17 80.9 15.7q4.85-6.3 8.2-13.85l.1-.1Q89.7.4 89.9.3q0-.05-2.1 1z"
                    id="AccMor047__Symbol_144_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M38.95 65.8l-.1 1.65 2.35.55.3-9.2-.8-.1.3 7.2-2.05-.1m-13.75-9l-1.35-.2-.8 6.65.9.3 1.25-6.75m7.65 1.55v-.7l-3.5-.3-.65 7.4 1.05.25.9-7.25 2.2.6m5.25 0l-2.9-.3-.1 7.45-1.95-.2-.1.6 3.4.85.5-7.25 1.15.4v-1.55M14.95 6.9q-.5-.95-.9-1.85Q12 4.5 9.8 3.8q-.5-.2-1-.3-1.65-.65-3.35-1.25-1.4-.5-2.85-1.15Q1.25.6 0 0q.95 1.55 1.95 3.1.3.5.65.9 10.7 14.95 29.8 22.65 14.4 4.55 1.35-4.2Q19.7 15.5 14.95 6.9M47.7 37.3q-2.15-.1-4.35-.5-.9-.1-1.85-.2-4.1-.65-9.8-2.5-3.1-1.15-5.9-2.45-2.75-1.35-5.35-2.8-1.35-.85-2.6-1.65L8.9 59l-.25.5 3.35.95L14.35 53l2.6.75-2.2 7.4 3 .75 1.85-7.35 1.55.4L25.4 33.5l6.4 2.65q3.3 1.55 8.15 2.2 4.75.5 7.95.3l-.2-1.35m7.2-30.6q.2-.15.35-.3l-1.5.3q-16.3 2.6-32.6 0 1.8.35 3.65.6 1 1.05 2.15 2.2 4.05 3.55 10 5.9.2 0 .45.1.2.1.4.25l.2.1q10.75 4.95 22.4 7.2-10.2-3-18.8-8.6 5.05-.9 9.95-4.75.7-.65 1.4-1.25 1-.9 1.95-1.75m19-5.95q-2.95 1-6.45 2.25-1.15.4-2.3.7-.6.95-1.15 1.85-3.2 5.05-7.2 9.05-.75.9-1.25 1.75-2.05 3.4 2.3 2.45 8.95-7 15.25-16.55.65-1.05.8-1.5z"
                    id="AccMor047__Symbol_86_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor047__Symbol_144_0_Layer0_0_FILL"
                transform="translate(147.35 238.3)"
                id="AccMor047__col1n"
            />
            <use
                xlinkHref="#AccMor047__Symbol_86_0_Layer0_0_FILL"
                transform="translate(154.85 241.5)"
                id="AccMor047__col1d"
            />
        </g></g>
    )
}

export default AccMor047
