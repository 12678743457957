import { AssetSvgProps } from "../../../shared/assets"

function ObjMor009({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjMor009__Layer14_0_FILL">
                    <path
                        fill="#333"
                        d="M143.7 259.5v-6.55h-13.05v6.55h2.5q2.25-.472 3.85-.9 1.814.429 4.35.9h2.35z"
                    />
                    <path
                        fill="#999"
                        d="M118 279.35q-.2.95-.3 1.95v19.95l.1-.05q-2.618 4.22 2.55 3.25.995 1.014 1.75 1.8h21.15q3.6-1.45 4.55-3.9.15-.45.25-.95v-12.15q1.854-2.76 0-5.15v-22.2h-6.7q-4.1-3.064-8.2 0h-5.4q-7.5 7.1-9.5 16.4-.15.5-.25 1.05z"
                    />
                </g>
                <g id="ObjMor009__Layer14_0_MEMBER_0_FILL">
                    <path
                        fill="#666"
                        d="M117.8 301.2l-.1.05q-1.05 1.2-2.05 1.95-.05.05-.1.05-.6.4-1.15.7-.15.05-.2.1-2.15.9-4.25.1-.25-.1-.45-.2l-.4-.2q-.6-.35-1.2-.8l-.15-.15q-.8-.65-1.55-1.55v3.2q1.85 1.95 3.7 2.9.85.45 1.7.65 4.15 1.05 8.3-3.15.2-.2.45-.4l-2.55-3.25m35.2-17.1h-4.95v5.15H153v7.5h24.75V276.7H153v7.4m-16.8 6.85v-4.9h-14.3v4.9h14.3m5.15-35.8h-8.2v6.75h8.2v-6.75z"
                    />
                    <path d="M147.5 270.9q0-3.4-2.4-5.75-1.65-1.65-3.75-2.15-1-.25-2-.25-3.45 0-5.8 2.4-2.4 2.35-2.35 5.75-.05 3.4 2.35 5.8 2.35 2.4 5.8 2.4 3.4 0 5.75-2.4 2.4-2.4 2.4-5.8z" />
                </g>
                <path
                    fill="#333"
                    d="M135 275.1q1.8 1.8 4.35 1.8t4.3-1.8q1.8-1.75 1.8-4.3 0-2.5-1.8-4.3-1.75-1.8-4.3-1.8t-4.35 1.8q-1.75 1.8-1.75 4.3 0 2.55 1.75 4.3z"
                    id="ObjMor009__Layer14_0_MEMBER_0_MEMBER_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor009__Layer14_0_FILL" />
            <use xlinkHref="#ObjMor009__Layer14_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor009__Layer14_0_MEMBER_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMor009
