import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#480000",
    "col2n": "#94C0AB",
    "col2s": "#597B6B"
}

function ShiDre058({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M122.75 47.3L123.9.5q.15-.25.4-.5l-1.8 1.35q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-.45.35-.95.65-3 2-6.1 3.8v.05q-.65.35-1.35.75-.15.05-.35.15-2.25 11.1-12.2 18.4-1.5 1.1-3.2 2.15-14.95 7.75-29.25 7.95H64q-14.35-.2-29.3-7.95-1.7-1.05-3.2-2.15-9.95-7.3-12.2-18.4-.2-.1-.35-.15-.7-.4-1.35-.75v-.05q-3.1-1.8-6.1-3.8-.5-.3-.95-.65L9 3.45Q8.2 2.9 7.45 2.3q-.65-.45-1.25-.95L4.4 0l.2.2q.25.3.55.65l.7 46.05q-.05.1-.05.25-.8 2.2-2.15 10.25L.85 69.15q-.4 1.55-.85 3.5l.15-.15-.05.15q64.65 14 128.5 0l-.05-.15.1-.05-.8-3.3L125 57.2q-1.5-7.6-2.25-9.9z"
                    id="ShiDre058__Symbol_255_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.8.25L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-.35.65-.75 1.35-.6 1.2-1.2 2.45l-.2.4q-.2.4-.35.75-.45.85-.85 1.75l-.4.9-.1.2-.8 1.8q-.5 1.1-.95 2.2v.1q-.3.5-.55 1.05v.1q-.8 1.8-1.45 3.7l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.55 1.8-1.05 3.65-.3 1.05-.6 2.15-.05.15-.05.25-.05.15-.1.35l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.1.25-.1.55-.05.15-.1.35-.1.45-.15.9-.1.45-.2.95l-.2 1.1q-.05.2-.05.4v.1q1.05-.9 2.25-1.6.5-.3 1.05-.55 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q.25.1.45.25 1.55.9 2.9 2.25.2.2.4.45 1.6 1.35 2.75-11.05v-.15q4.25-2.1 6.05-6.85.35-.9.6-1.95.25-.85.45-1.65.85-4.7.2-9.9l-.2-2-.2-1.8q-1.35-10.75-6.05-18.15-.45-.75-.85-1.4-.9-1.3-1.8-2.3M162.7 44.9q-.35-1.35-.65-2.7-.1-.2-.1-.35-.05-.15-.1-.25l-.4-1.6q-.1-.3-.15-.55-.7-2.4-1.45-4.75-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65l-1.7-2.3-.05-.05-.05.05q-1 1.1-2 2.5-.4.65-.85 1.4-4.7 7.4-6.05 18.15l-.2 1.8-.2 2q-.65 5.2.2 9.9.2.8.45 1.65.25 1.05.6 1.95 1.8 4.75 6.05 6.85l.05.15q1.1 12.6 2.65 10.85.3-.35.65-.65 1.1-1.05 2.45-1.85.25-.15.5-.25.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6.4.2.85.45 1.15.7 2.2 1.6V56.2q0-.15-.05-.35l-.2-1.1q-.2-1-.35-1.95-.05-.1-.05-.25-.05-.05-.05-.15-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiDre058__Symbol_176_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M4.45.1q-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1M115.4.6v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35z"
                    id="ShiDre058__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill="#854E36"
                    d="M126.55 307.3l-.05.25q-1.04.936-2 1.95-7.873 8.233-10.5 21.1l1.1.05v.05q.56.037 1.1.05 3.838.269 6.6.4l69.7-8.55 71.3 8.55q2.973-.142 7.2-.45-2.365-11.857-9.25-19.8-1.517-1.773-3.3-3.35v-.05q-.024.037-.1.05l-131.8-.25z"
                    id="ShiDre058__Layer10_0_FILL"
                />
                <path
                    fill="#E3DED5"
                    d="M128.25 301.3l.05-.2-.1.2h-.05q-.042.223-.1.45-.008.056-.05.1v.15q-.02.08-.05.15l-.6 2.35q-.138.563-.3 1.1l-.3 1.25-.2.45q4.447.613 4 2.9-.45 2.25-1.3 3.8-2.7 4.1-4.35 8.9-1.4 3.9-2.1 8.25 2.55.15 5.4.25.9 0 1.75.1 32.1 1.65 63.35 1.7 31.25-.05 63.35-1.7.85-.1 1.75-.1 2.85-.1 5.4-.25-.7-4.35-2.1-8.25-1.65-4.8-4.35-8.9-.85-1.55-1.3-3.8-.069-.351-.05-.65.03-.048 0-.1v-.1l1.5-1.5q.436-.193.95-.35l-.15-.55h-.1l-1.4-5.65q-63.663 8.997-128.55 0z"
                    id="ShiDre058__Layer10_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre058__Symbol_255_0_Layer0_0_FILL"
                transform="translate(128.2 228.65)"
                id="ShiDre058__col1n"
            />
            <use
                xlinkHref="#ShiDre058__Symbol_176_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiDre058__col2n"
            />
            <use
                xlinkHref="#ShiDre058__Symbol_137_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiDre058__col2s"
            />
            <use xlinkHref="#ShiDre058__Layer10_0_FILL" />
            <use xlinkHref="#ShiDre058__Layer10_1_FILL" />
        </g></g>
    )
}

export default ShiDre058
