import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#6EB800",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35z"
                    id="AccGla003__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M129.45 28.45v.15q1.25-8.2 0-13.75-1.1-4.6-3.9-7.4Q116.8 0 95.1 0 77.3-.15 69.65 6.95l-.45.45v.05q-2.3 2.5-3.25 5.7h-1.8q-.95-3.2-3.25-5.7V7.4l-.45-.45Q52.8-.15 35 0 13.3 0 4.55 7.45q-2.8 2.8-3.9 7.4Q-.6 20.4.65 28.6v-.15q3.15 12.5 16.9 16.5Q31.7 49 46.5 44.35q10.9-4.75 15-13 .25-.45.45-1 1-2.4 1.65-4.6h2.9q.65 2.2 1.65 4.6.2.55.45 1 4.1 8.25 15 13 14.8 4.65 28.95.6 13.75-4 16.9-16.5z"
                    id="AccGla003__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M165 19.45l26.95-9.3q.85-1.05 0-2.05l-26.85 8.65-.05.05h-2.75v-.05h-.6l-.65-2.9q-1-3.45-3.8-6.15-.05 0-.1-.05Q148.35 0 126.35 0 107.5-.15 99.8 7.8h-.05q-.6.65-1.15 1.3-1.15 1.1-2.3 1.15h-.2q-1.15-.05-2.3-1.15-.55-.65-1.15-1.3h-.05Q84.9-.15 66.05 0q-22 0-30.8 7.65-.05.05-.1.05-2.8 2.7-3.8 6.15l-.65 2.9h-.6v.05h-2.75l-.05-.05L.45 8.1q-.85 1 0 2.05l26.95 9.3v.05q1.8 1.75 2.65 2.35Q30 25.4 30.7 30v.1q3.25 13.25 17.9 17.45 14.25 4.05 29.2-.6.1 0 .15-.05 12.15-5.2 16.2-14.6v-.05q1-1.85 1.95-2 .05 0 .1.05.05-.05.1-.05.95.15 1.95 2v.05q4.05 9.4 16.2 14.6.05.05.15.05 14.95 4.65 29.2.6 14.65-4.2 17.9-17.45V30q.7-4.6.65-8.15.85-.6 2.65-2.35v-.05m-9.45-9.9q2.65 2.65 3.7 7 1.2 5.3 0 13.05v-.1q-3 11.85-16.15 15.65-13.55 3.85-27.75-.6-10.45-4.5-14.35-12.3-.25-.45-.45-.95-6-14.2 1-21.75V9.5q.2-.2.45-.4 7.3-6.75 24.35-6.6 20.8 0 29.2 7.05M90.4 9.1q.25.2.45.4v.05q7 7.55 1 21.75-.2.5-.45.95-3.9 7.8-14.35 12.3-14.2 4.45-27.75.6-13.15-3.8-16.15-15.65v.1q-1.2-7.75 0-13.05 1.05-4.35 3.7-7 8.4-7.05 29.2-7.05Q83.1 2.35 90.4 9.1z"
                    id="AccGla003__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla003__Symbol_94_0_Layer0_0_FILL"
                transform="translate(191.3 159.75)"
                id="AccGla003__col2n"
            />
            <use
                xlinkHref="#AccGla003__Symbol_73_0_Layer0_0_FILL"
                transform="translate(127.45 141.25)"
                id="AccGla003__col2l"
            />
            <use
                xlinkHref="#AccGla003__Symbol_25_0_Layer0_0_FILL"
                transform="translate(96.4 140)"
                id="AccGla003__col1n"
            />
        </g></g>
    )
}

export default AccGla003
