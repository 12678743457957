import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.55 8.55q.3.2.55.45Q12.3-6.25 43 14.85 17.8-10.3 0 7.15l.4.3q.5.6 1.15 1.1m93.7.45q.25-.25.55-.45.65-.5 1.2-1.1.15-.15.35-.3-17.8-17.45-43 7.7Q85.05-6.25 95.25 9z"
                    id="FacBro008__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro008__Symbol_8_0_Layer0_0_FILL"
                transform="translate(143.85 115.25)"
                id="FacBro008__col1n"
            />
        </g></g>
    )
}

export default FacBro008
