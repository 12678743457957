import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiMot038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.45 20.2q0-8.35-5.9-14.3Q28.6 0 20.25 0q-8.4 0-14.3 5.9Q0 11.85 0 20.2q0 7.5 4.75 13.05.55.65 1.2 1.3.8.75 1.65 1.5-.7 1-1 1.5l-.05.05q-.3.5-.6.95-.1.25-.2.45-.15.3-.35.6l-.1.3q-.05.05-.1.15v.05q-.1.25-.25.5-.05.2-.15.4-.05.1-.1.3v.05h-.05q-.15.5-.35.95-.05.1-.05.2 0 .05-.05.05-.05.15-.05.25l-.3.8q-.15.55-.35 1.15-.1.5-.25 1-.15.6-.25 1.15 0 .2-.05.4v.1q-.05 0-.05.05v.15q-.1.65 0 1.35.45 3 3.75 3.7h.05q.05.15.1.25.1.2.25.4v4.1q-.8.15-1.65.5-.3.15-.55.3h30.8q-.25-.15-.55-.3-.8-.35-1.65-.5v-4.1q.15-.2.25-.4.05-.1.1-.25h.05q3.3-.7 3.8-3.7.05-.7 0-1.35-.05-.05-.05-.15v-.05q-.05-.05-.05-.1 0-.2-.05-.4-.1-.55-.25-1.15-.1-.5-.25-1-.15-.6-.35-1.15-.15-.4-.25-.8-.05-.1-.1-.25v-.05l-.1-.2q-.2-.45-.35-.95h-.05v-.05q-.05-.2-.1-.3-.05-.2-.15-.4-.1-.25-.25-.5v-.05q-.05-.1-.1-.15l-.1-.3q-.15-.3-.35-.6-.1-.2-.2-.45-.3-.45-.6-.95 0-.05-.05-.05-.3-.5-1-1.55.85-.7 1.65-1.45.65-.65 1.2-1.3 4.7-5.55 4.7-13.05z"
                    id="ShiMot038__Symbol_45_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot038__Symbol_45_0_Layer0_0_FILL"
                transform="translate(172.3 254.35)"
                id="ShiMot038__col1n"
            />
        </g></g>
    )
}

export default ShiMot038
