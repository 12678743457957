import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#56A689",
    "col2n": "#1B3F32"
}
function AccMor091({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M171.8 20q-1.55-2.9-3.2-5.65-4.2-6.85-9.4-12.95l-.95-1.4h-.05q-56.9 55.2-120 0l-1.05 1.6Q33.3 6.2 29.65 12q-2.2 3.5-4.3 7.45Q19.2 30.95 11.5 49.1 3.75 67.25 1.1 86.35q-.4 2.95-.65 5.2-.156 1.297-.3 2.35-1.019 4.405 2.9 6.8.305.193.6.35 11.65-4.95 24.3-7.2 12.65-2.3 7.25-1.1h132.05q-5.4-1.3 5.55.75 10.9 2.05 17.3 3.6 6.4 1.5 6.5.05v-.2V96.8v-.2q0-.6-.05-1.35-.15-1.5-.4-3.7-.25-2.25-.65-5.2-2.65-19.1-10.3-37.3Q177.5 30.8 171.8 20z"
                    id="AccMor091__Symbol_187_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M182.95 56.4q-.05-.2-.1-.35l-3.5-10.35Q170.6 22.2 157.15.2q.5.6 1.05 1.2l-.95-1.4-6.1 4.8q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L41.9 3.65Q41 3 40.15 2.35L37.2 0l-1.05 1.6 1.2-1.4Q25.5 20.35 16.1 44.15L11.95 55.3Q10.3 61.15 8.7 68.1q-1.4 6.8-2.75 14.8-.5 4.6-.4 8.65 0 .55.05 1.1-1.05.3-1.95.75-1.55.75-2.45 1.7-.284.272-.5.55-1.075 2.193 0 3.9.327.49.85.9 1.6 1.25 4.15 1.25 1.9 0 3.9-1.05 2.05-1.05 3.45-1.05 1.049 0 2.7.75.573.299 1.2.65 2.55 1.35 4.6 1.35 2.55 0 4.65-1.1.771-.395 1.4-.65 1.198-.45 2-.45 1.239-.05 3.6 1l.05.05q2.4 1.1 4.3 1.15 1.9 0 3.95-1 .233-.114.45-.25.16-.022.3-.1.588-.204 1.2-.35.515-.127 1.05-.2l.35-.05q1.758-.191 2.75-.15 1.15.046 3.35.95 1.252.497 2.4.7.925.2 1.8.2 1.5 0 4.45-1.35 2.95-1.4 4.65-1.4 1.4 0 3.65 1.3t5.7 1.3q3.05 0 6.6-1.85t6.5-1.85q2.25 0 5.2 1.35.935.4 2 .7 2.648.805 4.9.8 2.252-.014 4.85-1.3 2.604-1.284 5.8-1.1 1.565.2 3.8 1.3 3.05 1.5 5.5 1.5 2.4 0 5.6-1 3.15-1 5.65-1.05 2.45-.1 4.9 1.1 2.45 1.2 5.15 1.4 2.65.2 6.8-1.5 1.57-.643 3-.8 2.396.023 4.4 1 2.047.996 3.95.95 1.704.016 3.8-.9.242-.081 1.4-.55 1.212-.414 2.3-.5 1.133-.043 1.9.15.776.193 1 .3.212.117.4.1.187.036 2.55 1.2 2.358 1.157 4.85 0 2.492-1.108 3.35-1.5 1.663-.76 3.1-.65 1.438.108 2.9.65 1.458.602 2.5.85.914.247 1.8.25 2.55 0 4.15-1.25 1.4-1.1 1.4-2.55v-.05-.2V96.8v-.2q-.046-.28-.15-.55-.438-1.55-2.05-2.95-1.95-1.65-6.55-.25 1.1-17.6-3.9-36.45M98.1 100.75H98q.052-.002.1-.05v.05z"
                    id="AccMor091__Symbol_186_0_Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor091__Symbol_187_0_Layer0_0_FILL"
                transform="translate(94.2 228.45)"
                id="AccMor091__col1n"
            />
            <use
                xlinkHref="#AccMor091__Symbol_186_0_Layer1_0_FILL"
                transform="translate(95.2 228.45)"
                id="AccMor091__col2n"
            />
        </g></g>
    )
}
export default AccMor091
