import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#0000BB",
    "col1n": "#0000FF",
    "col2n": "#000000"
}

function AccHat070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M197.05 18.5q-2.3-4.3-13-3.7-29.55-2.15-57.75-9.2-39.65-7.45-82.1-5-16.15.4-32.7 1.5Q-.7 1.7.05 7.1l.2.9q.2.7.6 1.5.6 1.2 1.3 2.35l.4.6Q12.45 30.2 14.3 52.1q.05.75.15 1.55l.1 1.8 86.6 2.6 80.3 3.15q.9-.95 1.75-1.95.65-.75 1.3-1.55 10.65-13.3 13.35-30.8.75-5.5-.8-8.4z"
                    id="AccHat070__Symbol_288_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M192.55 31.85q-5.1 9.45-8.6 10.9-3.6 1.4-7.8 5.9-2.55 2.75-4.05 6.4-34.1-5.2-67.3-6.7 3.45-1.4 6.3-4.25 5.65-5.6 5.65-13.45t-5.65-13.5q-5.6-5.6-13.45-5.6t-13.5 5.6q-5.55 5.65-5.55 13.5t5.55 13.45q2.4 2.4 5.2 3.75-32.6-.65-64.25 2.3v-.25q.4-3.3.4-6.25 0-7.1-3.75-11.8-5-4.8-7.45-8-3.45-3.7-5.4-6-3.6-4.3-3.6-7.2 0-2.6 3.35-4.3 2.1-1.05 6.85-2.1Q25.35 1.95 35.95 0 23.85.4 11.5 1.25-.7.85.05 6.25l.2.9q.2.7.6 1.5.6 1.2 1.3 2.35l.4.6q9.9 17.75 11.75 39.65.05.75.15 1.55l.1 1.6v.2l10 .35v-.05l76.6 2.3 69.5 2.75V60l10.8.35.25-.25 1.5-1.7q.15-.2.3-.35l1-1.2q10.65-13.3 13.35-30.8.75-5.5-.8-8.4-2.25-4.2-12.4-3.7 1.75.85 3.05 1.7 3.15 2.1 4.45 3.5 1.75 1.95 2.4 2.65.65.65-2 10.05z"
                    id="AccHat070__Symbol_192_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M170.1 9.15q-1.35-.25-2.7-.45-.5-.1-.95-.15-.1-.05-.15-.05-1.45-.25-2.9-.45l-.95-.15-2.9-.4v-.05q-6.35-1-12.7-1.85h-.2v-.05q-12.9-1.85-25.75-3.1Q106.15 1.05 91.35.5 46.8-1.35 4.9 3.65q-.1.05-.15.1h-.2q-4.75.3-4.5 3.7-.55 4.4 4.5 4.7 1.75.15 3.3.25.6.05 1.15.1 71.25 10.05 145.35 7.2l16.35-.2q1.5-.05 2.95-.05 1.1 0 2.2-.1 3.6.2 3.75-5.45.1-2.85-3.2-3.85-2.3-.35-4.6-.65-.9-.15-1.7-.25z"
                    id="AccHat070__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFC400"
                    d="M183.85 83.2q.05.15.25.2 4.3 3.55 10.05 3.55 6.7 0 11.4-4.75.5-.5 1-1.05 3.75-4.4 3.75-10.4 0-6.65-4.75-11.4-4.7-4.75-11.4-4.75t-11.45 4.75q-4.7 4.75-4.7 11.4 0 6 3.75 10.4.5.55.95 1.05.55.5 1.15 1z"
                    id="AccHat070__Layer8_0_FILL"
                />
                <g id="AccHat070__Layer8_1_FILL">
                    <path d="M194.1 51q-.1-.15-.15-.25-.2-.1-.35-.1-.15 0-.3.1t-.15.25l-4.45 12.75-13.55.35q-.15 0-.3.1t-.15.25q-.05.15 0 .3 0 .15.15.3l10.75 8.25-3.85 12.95q-.05.2 0 .3 0 .15.15.25.15.15.3.15t.3-.15l11.1-7.7 11.25 7.7q.15.15.25.15.2 0 .35-.15.1-.1.2-.25v-.3l-3.9-12.95 10.75-8.25.2-.3v-.3q-.1-.15-.2-.25-.15-.1-.25-.1l-13.6-.35L194.1 51m-7.4 22.25v-.35q-.1-.1-.2-.25l-9.9-7.55 12.45-.35q.15 0 .3-.1t.2-.2l4.05-11.7 4.2 11.7q.05.1.2.2.05.1.25.1l12.5.35-9.9 7.55q-.05.15-.15.25-.05.15 0 .35l3.55 11.95-10.3-7.1q-.2-.1-.35-.1-.15 0-.25.1l-10.2 7.1 3.55-11.95m3.4-5.5q-1.25 1.3-1.25 3.1T190.1 74q1.3 1.25 3.1 1.25 1.85 0 3.1-1.25 1.3-1.35 1.3-3.15 0-1.8-1.3-3.1-1.25-1.25-3.1-1.25-1.8 0-3.1 1.25m-.25 3.1q0-1.35.95-2.4 1-.95 2.4-.95 1.45 0 2.4.95 1 1.05 1 2.4 0 1.4-1 2.45-.95.95-2.4.95-1.4 0-2.4-.95-.95-1.05-.95-2.45z" />
                    <path
                        fill="#FFC400"
                        d="M186.7 72.9v.35l-3.55 11.95 10.2-7.1q.1-.1.25-.1t.35.1l10.3 7.1-3.55-11.95q-.05-.2 0-.35.1-.1.15-.25l9.9-7.55-12.5-.35q-.2 0-.25-.1-.15-.1-.2-.2l-4.2-11.7-4.05 11.7q-.05.1-.2.2-.15.1-.3.1l-12.45.35 9.9 7.55q.1.15.2.25m2.15-2.05q0-1.8 1.25-3.1 1.3-1.25 3.1-1.25 1.85 0 3.1 1.25 1.3 1.3 1.3 3.1T196.3 74q-1.25 1.25-3.1 1.25-1.8 0-3.1-1.25-1.25-1.35-1.25-3.15m1.95-2.4q-.95 1.05-.95 2.4 0 1.4.95 2.45 1 .95 2.4.95 1.45 0 2.4-.95 1-1.05 1-2.45 0-1.35-1-2.4-.95-.95-2.4-.95-1.4 0-2.4.95z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat070__Symbol_288_0_Layer0_0_FILL"
                transform="translate(95.9 40.7)"
                id="AccHat070__col1n"
            />
            <use
                xlinkHref="#AccHat070__Symbol_192_0_Layer0_0_FILL"
                transform="translate(95.9 41.55)"
                id="AccHat070__col1d"
            />
            <use
                xlinkHref="#AccHat070__Symbol_98_0_Layer0_0_FILL"
                transform="translate(105.55 92.5)"
                id="AccHat070__col2n"
            />
            <use xlinkHref="#AccHat070__Layer8_0_FILL" />
            <use xlinkHref="#AccHat070__Layer8_1_FILL" />
        </g></g>
    )
}

export default AccHat070
