import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.65 11q0-3.2-2.25-5.45-1.65-1.65-3.8-2.1-.3-.05-.55-.1-.25-.05-.5-.05-.15-.05-.35 0-.15-.05-.35 0h-.4q-.7.05-1.25.15-.15.05-.35.1Q20.7-.05 15.1 0 9.35-.05 5.2 3.65q-.05.05-.1.15-.2.1-.35.25-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3Q0 9.4 0 15q0 6.2 4.45 10.6Q8.85 30 15.1 30q2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.25-3.25 4.1-7.45.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45z"
                    id="BodHan019__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.5 7.75q0-3.2-2.25-5.45Q11.6.65 9.45.2 9.15.15 8.9.1 8.65.05 8.4.05q-.15-.05-.35 0Q7.9 0 7.7.05h-.4Q6.6.1 6.05.2 5.9.25 5.7.3q-1.95.45-3.45 2Q0 4.55 0 7.75t2.25 5.45q1.7 1.7 3.95 2.1l1.5.15h.1l.75-.05q1.15-.1 2.15-.5.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45M12.45 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-.85.9-1.85 1.4-1 .45-2.05.55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-2-1.95-1.95-4.7Q1.05 4.95 3.05 3q1.45-1.45 3.3-1.8.5-.1 1-.15H7.8q.4 0 .85.1 2.15.25 3.8 1.85z"
                    id="BodHan019__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    id="BodHan019__Layer1_0_1_STROKES"
                    stroke="#2BB439"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M276.1 298.2l-.05.05"
                />
                <g id="BodHan019__Layer1_0_FILL">
                    <path
                        fill="red"
                        d="M265.75 280.85q.15.95.8 1.95.4-.35.75-1.55.5-1.6.5-2.25v-.45q-.4-.8-.9-.85-.55-.05-.9.8-.35.8-.35 1.6 0 .4.1.75m-3.9 2.75q-.1-.05-.15-.05-.35 0-.75.85-.35.85-.9.85-1.25 0-3.45-3.35-.65-1-1.1-1.7-1.2-1.7-1.4-1.7-.25 0-.4.15-.3.3-.3 1.05 0 1.55 1.25 3.25l.1.1q1.15 1.55 1.15 2.1 0 .95-.7 1.3-.75.35-.75.95 0 .45.25.95.3.55.9 1.15l.55.55.2.2q.1.05.35.3.25.2-.05 1.05l-.2.2q-.25.2-.5.2h-.15q-.15-.05-.3-.15-.15-.15-.35-.4-.95-1.2-1.4-.65 1.75 8.85 2.85 15.2.1.7.25 1.25v.3q-.3 0 .15.1.7.2 2.85.25.55 0 1 .05.05-1.8.5-2.95.3-.85.85-1.7.4-.65.85-1l-1.4-4.1q-.1.1-.2.3-.15.2-.15.65 0 .25.15.55.15.3.15.45 0 .25-.55.25-.3 0-.65-.15-.35-.15-.65-.15-.95 0-.95 1.25 0 .5.6 1 .2.2 1 .7l-.8.05q-1.5 0-2.05-1.1-.55-1.15-.75-2.15-.2-1-.15-1.4.05-.45.05-.7 0-1.9 1.85-3.35.15-.15.3-.25.25-.2.5-.35.25-.35.5-.35h.05q.05 0 .1-.05t.1-.05h.65l2.15-.05q.3-.1.65-.3.75-.3 1.5-1.1.85-.8 2.05-1.15 1-.2 2.2-.25.35-.05.75-.05.65.15 1.8.55h1.35q.3-.1.85-.15.45.2.85.5 1.15.95 1.15 2.8 0 .15-.1.45-.1.3-.5 2-.4 1.65-2.25 1.9 1.25-1.65 1.25-3 0-1.6-.5-1.85-.65-.1-.8-.25-.2-.65-.3-.8h-.5l-1.05 1.1 1.4 6.3q.2 0 .45.05.75.05 2 .45l.85-2.05q0-.1.05-.2l.05-.05q1.45-3.3 1.45-7.2 0-4.4-1.8-8-.05-.05-.05-.1-.1-.2-.2-.35-.05-.2-.05-.4-.2-.85-.35-1.9-.5-2.6-1.65-3.85-.2-.85-.5.15-.3.95-.6.95-.25 0-.75-.55-.5-.6-1-.6-.15 0-.3.1-.35.45-.4.95v.15q0 1.05 1.35 1.9 1.35.8 1.35 1.9 0 .65-.25 1.2-.25.55-.9.7-.65.15-.65.7 0 .55.15 1.2.1.65.1 1-.4.8-1 .75-1.15-.2-1.85-.95-.35-.4-.7-.55-.25-.2-.45-.2-.75 0-1.25 1.3-.55 1.3-1.05 1.3-.2 0-.35-.15-.55-.4-1.1-1.95-.65-2-1.45-2.1z"
                    />
                    <path
                        fill="#430000"
                        d="M272.8 291.45q-.15.1-.4.25-.7.5-.85.7-.2.45-.35.8l.1.5 1.05-1.1h.5q.1.15.3.8.15.15.8.25.5.25.5 1.85 0 1.35-1.25 3 1.85-.25 2.25-1.9.4-1.7.5-2 .1-.3.1-.45 0-1.85-1.15-2.8-.55.5-1.05.5-.65-.3-1.05-.4M260 293.4q-.05.05-.1.05.2.2.35 2.9v.15q-1.2-.1-1.35 1.1-.05.7-.15 2.2h-.25q-.25-.2-.25-1.45 0-.65.25-1.55t.25-1q0-.3-.1-.7-.1-.3-.1-.7-1.85 1.45-1.85 3.35 0 .25-.05.7-.05.4.15 1.4.2 1 .75 2.15.55 1.1 2.05 1.1l.8-.05q-.8-.5-1-.7-.6-.5-.6-1 0-1.25.95-1.25.3 0 .65.15.35.15.65.15.55 0 .55-.25 0-.15-.15-.45-.15-.3-.15-.55 0-.45.15-.65.1-.2.2-.3l-1.65-4.8z"
                    />
                    <path
                        fill="#FFF"
                        d="M270.05 290.45q-.4 0-.75.05-1.2.05-2.2.25-.05.15.05.4-.05.1 0 .25v.3q.05.25.35.25.25 0 .6-.4l.05.05q.3.15.4.35.15.3.15.9v2.3q0 1.2.35.5.35-.35.55-3.25.4-1.65.45-1.95m-9.95 2.9q-.05 0-.1.05l1.65 4.8 1.4 4.1q-.45.35-.85 1-.55.85-.85 1.7-.45 1.15-.5 2.95-.05.4-.05.85 0 1.6.7 2.65.75 1.15 2.05 1.15 1.1 0 2.1-.65 1.1-.5 1.7-1.55.6-1.05.85-1.65.2-.6.55-1.45.35-.9 1.1-.9.75 0 1.8.9.2.15.4.35 1.35 1.2 2.5 1.2 1.6 0 2.45-1.3.75-1.15.75-2.85 0-1.35-.55-2.5-.35-.65-.75-1-.4-.4-.9-.55-.2-.1-.4-.15-1.25-.4-2-.45-.25-.05-.45-.05l-1.4-6.3-.1-.5q-.2.2-.3.35-.2.55-.35 1.3-.05.2-.1.65 0 2.25-.05 3.8.2 2.25.2 2.5 0 2.3-.65 2.3-.2 0-.35-.05-.65-.15-.85-1-.25-.85-.3-1.5-.05-.65.05-1.3v-2.2q-.05-.35-.1-.55-.15-.4-.55-.35-.45.05-.7 1.4-.25 1.35-.3 1.35-.1-.05-.2 0-.15 0-.25-.4v-.5q0-.3.05-.55V297.25q.15-.6.1-2.35.15-3-.45-3-.4 0-.45.4v.55q0 .2.05.35l-.2.3q-.05.05-.45.15-.15.05-.25.3-.05.2-.05.4 0 1.25-.5 1.95t-.5 1.3q0 1.05.15 1.9 0 .8-.25.8-.45 0-1.1-1.75-.4-1-.5-1.5-.1-.15-.15-.3-.95-2.45-1.15-3.4h-.65z"
                    />
                    <path
                        fill="#DB8787"
                        d="M259.85 293.45q-.25 0-.5.35-.25.15-.5.35-.15.1-.3.25 0 .4.1.7.1.4.1.7 0 .1-.25 1t-.25 1.55q0 1.25.25 1.45h.25q.1-1.5.15-2.2.15-1.2 1.35-1.1v-.15q-.15-2.7-.35-2.9h-.05m3.05-.15l-2.15.05q.2.95 1.15 3.4.05.15.15.3.1.5.5 1.5.65 1.75 1.1 1.75.25 0 .25-.8-.15-.85-.15-1.9 0-.6.5-1.3.5-.7.5-1.95 0-.2.05-.4.1-.25.25-.3.4-.1.45-.15l.2-.3q-.05-.15-.05-.35v-.55q.05-.4.45-.4.6 0 .45 3 .05 1.75-.1 2.35V298.45q-.05.25-.05.55v.5q.1.4.25.4.1-.05.2 0 .05 0 .3-1.35t.7-1.4q.4-.05.55.35.05.2.1.55v2.2q-.1.65-.05 1.3t.3 1.5q.2.85.85 1 .15.05.35.05.65 0 .65-2.3 0-.25-.2-2.5.05-1.55.05-3.8.05-.45.1-.65.15-.75.35-1.3.1-.15.3-.35.15-.35.35-.8.15-.2.85-.7.25-.15.4-.25.4.1 1.05.4.5 0 1.05-.5-.4-.3-.85-.5-.55.05-.85.15h-1.35q-1.15-.4-1.8-.55-.05.3-.45 1.95-.2 2.9-.55 3.25-.35.7-.35-.5v-2.3q0-.6-.15-.9-.1-.2-.4-.35l-.05-.05q-.35.4-.6.4-.3 0-.35-.25v-.3q-.05-.15 0-.25-.1-.25-.05-.4-1.2.35-2.05 1.15-.75.8-1.5 1.1-.35.2-.65.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#BodHan019__Symbol_33_0_Layer0_0_FILL"
                transform="translate(105.05 280.6)"
                id="BodHan019__col1n"
            />
            <use
                xlinkHref="#BodHan019__Symbol_36_0_Layer0_0_FILL"
                transform="translate(124.2 283.85)"
                id="BodHan019__col1d"
            />
            <g>
                <use xlinkHref="#BodHan019__Layer1_0_FILL" />
                <use xlinkHref="#BodHan019__Layer1_0_1_STROKES" />
            </g>
        </g></g>
    )
}

export default BodHan019
