import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo047({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FEB743"
                    d="M107.35 264.8h-.3q-4.35-.15-6.75 3.95-.15.2-.25.5H100q-1 .25-1.55.5-1.85.75-3.15 1.85-4.05 4.25-4.95 11-.05.4-.05.8 0 .15-.05.75 0 .65.05 1.15.25 1.15 1.1 1.7.6.3 1.35.4 1 .1 2.05-.3.6-.2 1.1-.45.5-.35.7-.45.45-.2 1-.2h.7q.2 0 .6.1.9.2 1.2.25.5.05.95 0h-.1q.6-.05.95-.1h.05q.3.4.55.7.2.2.45.4t.5.35q.418.239.9.4.308.09.65.15v-.05q.487.25.8.25.315 0 .6-.1.37-.057.7-.25.046-.029.1-.05l-.05-.2q-.209-.381-.3-.9h.05q.119.354.25.9.05.096.1.15v.05q.554-.492 1.3-.85 4.45-1.85 10.2 0 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.098.736-.25 1.45.376.347.8.45l.1.05v.1q-.95 2.5 1.2 4.55.15.15.4.25.65.45 1.4.65.8.3 1.7.4-.2.7-.1 1.5.05.5-.25 1.15-.15.45-.65 1.25-.5.9-.7 1.25-.3.7-.3 1.25 0 .85.4 1.25.75.75 3.2.75 1.3 0 2.7-.25 1.15-.05 2.55-.3 10.9-3.3 13.8-10.05.05-.15.05-.2.15-.75.15-.95 0-.85-.35-1.55.9-.6 1.4-1.4.3-.55.45-1.1.05-.1.05-.2 1.15-4.35-1.55-7.95l-.25-.25q.45-1.7.5-2.05.05-.35.1-1.3v-1q0-.7-.15-1.4-.15-1.25-.65-2.4-.75-1.9-2.15-3.35-1.4-1.55-3.3-2.45-.35-.1-.65-.25v-.25q0-1.7-1.05-3.5-.35-.6-.75-1.15-.45-.55-.95-1.15-.6-.65-1.3-1.3-5.8-6.45-13.9-5.85-.05 0-.2.05-.8.1-1.6.3-.1.05-.25.1-.8.2-1.5.6l-.1-.1q-.3-.3-1-.85-.05-.05-.15-.1l-.3-.2q-.2-.05-.4-.2-.6-.25-1.15-.45-5.9-2.2-10.7.9l-.1.1q-.05 0-.05.05-.75-.3-.9-.35l-.3-.1q-.45-.05-.75-.15h-.25q0-.05-.05-.05z"
                    id="ObjFoo047__Layer16_0_FILL"
                />
                <path
                    fill="#F38D2A"
                    d="M146.15 301.75q2.55-.1 3.9.7.8.45 1.2 1.1.9-.6 1.4-1.4-.35-2.95-8.1-1.9-2.85.45-6.5 1.65-.2.05-.35.1-6.1 1.85-8.65 6.05.8.3 1.7.4.2-.55.6-1 .6-.85 1.7-1.5.7-.6 1.85-1.05.2-.05.4-.15 2.7-1.3 5.4-2.05 3-.9 5.45-.95m-8.1-10q1.15-.45 2.3-.85 2.1-.65 3.25-.65 4.95 0 7.5 2.2.15.1.25.2.45-1.7.5-2.05.05-.35.1-1.3v-1q-6.05-3.4-14.7.55-2.25 1.1-4.35 2.75-2.75 2.2-4.9 5.05-.858 1.201-1.6 2.45-.086.194-.2.4l-.85 1.3q-.077.626-.2 1.2.376.347.8.45l.1.05v-.05q.156-.26.4-.65.2-.3.55-.75 4.2-5.7 11.05-9.3m-4.8-15.65l-.2.1q-1.5.95-2.85 2.4-.3.2-.55.55-4.55 5.386-6.05 12.15-.02.098-.05.2.796 1.409 1.25 2.9.13-.466.25-1 .108-.258.2-.55.09-.344.2-.7.972-3.38 2.7-6.2 2.25-3.65 5.35-5.75 3.55-2.4 8-2.4 1.85 0 3.55.65v-.25q0-1.7-1.05-3.5-4.8-1-8.5.3-1.2.5-2.25 1.1m-20.35 4.15q-.45 1.85-.8 3.9-.094.532-.2 1.05-.013.364-.05.7v.05h.1q-.03.217-.05.4.662-.071 1.35-.1.01-.037 0-.1h.1V286l.15-.75q.03-.166.05-.35 2.825-15.533 9.9-18.7-.3-.3-1-.85-.05-.05-.15-.1l-.3-.2q-.2-.05-.4-.2-.6-.25-1.15-.45-5.5 2.85-7.55 15.85M100 269.3v-.05q-1 .25-1.55.5-.45.85-.55 1.6 0 .35-.05.7-.1.6-.15 1.05-.55 6.4 2.75 12.65.2.3.5.6.6-.05.95-.1h.05q-.3-.4-.55-.9-.15-.3-.35-.65-.05-.15-.15-.3v-.1q-.25-.55-.45-1.15-.2-.6-.4-1.15v-.1q-.3-1.05-.45-2.2-.1-.4-.15-.85-.25-1.45-.35-2.8V275v-1.2-.4-.25q.05-.6.1-.95t.05-.6q.1-.5.2-.95.05-.05.05-.1.2-.7.5-1.25m8.4-4.3q-.45-.05-.75-.15-2.95 3.8-3.2 11.7v1.4q.048 5.372 1.5 9.15.085.192.15.35v-.05q.114.37.3.95.37-.057.7-.25.046-.029.1-.05l-.05-.2q-.209-.381-.3-.9h.1q-.07-.27-.15-.55-.957-4.344-.3-11.9 0-.45.05-1 .05-.2.05-.3.55-5.45 3-7.7v-.05q-.75-.3-.9-.35l-.3-.1z"
                    id="ObjFoo047__Layer16_1_FILL"
                />
            </defs>
            <use xlinkHref="#ObjFoo047__Layer16_0_FILL" />
            <use xlinkHref="#ObjFoo047__Layer16_1_FILL" />
        </g></g>
    )
}

export default ObjFoo047
