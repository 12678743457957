import { AssetSvgProps } from "../../../shared/assets"

function ShiMot065({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 257.8v17.95q34.031 7.032 66.5 0V257.8h-66.5z"
                    id="ShiMot065__Layer5_0_FILL"
                />
                <path
                    fill="red"
                    d="M159.2 275.75v17.85h66.5v-17.85h-66.5z"
                    id="ShiMot065__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot065__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot065__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot065
