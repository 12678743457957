import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col1s": "#D2AD00",
    "col2n": "#000000"
}

function ObjToo036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#CDCDCD"
                    d="M92.4 104.95q0-.1-.05-.2-2.7.35-2.2 12.75 2.55 50.95 12.75 102.8 4.25 21.65 9.8 43.4.15.4.2.8.15.45.25.9l1 3.8v.05q.05.2.1.5 0 .05.05.2 0 .2.1.4h8l-.1-.4q-.5-2.3-1-4.55-5.2-22.75-9.65-45.1Q99.7 161.2 92.4 104.95m28.1 163.2v-.05h.2l-.2.05z"
                    id="ObjToo036__Layer14_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M30.35 2v-.15Q29.8-.3 28.4.05q-1.65.3-1.8 1.8-.05.5.05 1.15.2 1 .45 1.35.2.8-.6 1.75-.55.55-1.15.85-.3.15-.65.2l-.7.05-.9.2-3.75-.9q-.95.15-1.85.4-1.75.45-2.85.75l-1.45 1.7-.65.15Q11 9.9 9.5 10.55l-.7.3q-2.25.9-4.3 2.35-.75.5-1.5 1.1Q-1.3 18.65.6 30q1.3 7.6 5.5 12.55 1.25 1.5 2.8 2.8 1.05.7 2.2 1.2 3.65 1.35 8.95.05l-.6 2.15 1.7.45-.45 1.35 6 1.8.7-1.65 1.9-.45.75-1.55q-2.8-4-2.15-9.55v-.2l-.55-2.75v-.05l-.25-1.4v-.15l-1-5.1q-.35-.3-.65-.6-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.05 0-.1.05l.15.9h-.05l.1.35.3.35q-.1-.05-.25-.1-5.15 3.65-9.1 1.5-2.05-1.2-3.75-3.95-.2-.35-.4-.75Q2.25 31.2 2.3 23.3q.05-4.9 2.75-7.25 1.55-1.3 3.9-1.75 2.4-.5 4.75-.95.15-.05.25-.05l.1.85.85 4.3q1.35.2 2.75.65 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-2.1 1-3.75l-.1-.4H24l-.05-.3v-.1l-1.5-7.7q8.45-3.5 7.9-9.25z"
                    id="ObjToo036__Symbol_196_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.45 0q.3.55.95 1.95.15.75-.8 2.2L0 6.3l7.95 1.95 1.9-.45-8.7-3.45Q2.95 3.4 2.5 1.4 2.2.6 2 .05 1.25.3.45 0z"
                    id="ObjToo036__Symbol_106_0_Layer0_0_FILL"
                />
                <g id="ObjToo036__Layer14_1_FILL">
                    <path
                        fill="#EEE"
                        d="M120.7 268.1l.1.5q.7 2.7 1.35 5.45v.05l1.1-.3q0-.05-.05-.1-1-4.15-1.9-8.3-18.85-82.95-28.9-160.45 8.05 80.05 17.8 119.7 8.6 35.15 9.85 40.75l.2 1q.25.8.45 1.7z"
                    />
                    <path
                        fill="#A8A8A8"
                        d="M116.5 263.3q0-.2-.05-.3-8.75-33.8-19.9-99.1 5.3 47.1 17 93.25.8 4.1-.15 6.45-.2.5-.5.9.15.45.25.9l1 3.8v.05q.05.2.1.5.7 2.8 1.35 5.6l6.55-1.25v-.05q-.65-2.75-1.35-5.45l-.1-.5-.2.05v-.05h.2q-.2-.9-.45-1.7-.9-.3-1.75-1-1.05-.75-2-2.1z"
                    />
                </g>
                <g
                    id="ObjToo036__Symbol_76_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M10.15 36.2l.65-.85-3.6-.2q-.15.1-.3.15l-.2.1h-.05q-.05 0-.1.05l.15.9h-.05l.1.35.3.35q.8.3 1.55.05.8-.25 1.55-.9m.75-5.6h-.25q-.7 1.9-1.7 3.15h.25q.5.05 1.05.05l1.7.15 2.55-3.15v-.05l-3.6-.15m.65-5.2q0 .8-.1 1.65-.1.75-.25 1.5l-.1.5 3.15.15-.75-3.75-1.95-.05m-.9-5.2q-.05-.106-.05-.2h-.05l.1.2m2.2 3.55l-.25-.25q-1.2-1.2-1.75-2.6.4 1.35.6 2.8l1.4.05m-5.7-8.6q.85.7 1.6 1.55 0 .05.05.1.15.15.3.35.4.6.7 1.2h.55v-.25q0-1.55.55-2.9h-.2l-3.3-.05h-.25m-3.8-4.7q-1 .05-1.75.05l.5 2.55q.6.1 1.25.25l7.75.25-.65-3.2-7.1.1M1.1 7.9l.1.85 8.9-.25-.5-2.65q-1.6.4-3.2.85-2.65.65-5.3 1.2zM9.95 0l-.7.2q.05.05.05.1L0 2.15l.35 1.8L10.25 2l-.3-2z" />
                </g>
            </defs>
            <use xlinkHref="#ObjToo036__Layer14_0_FILL" />
            <use xlinkHref="#ObjToo036__Layer14_1_FILL" />
            <use
                xlinkHref="#ObjToo036__Symbol_196_0_Layer0_0_FILL"
                transform="translate(101.1 268.1)"
                id="ObjToo036__col1n"
            />
            <use
                xlinkHref="#ObjToo036__Symbol_106_0_Layer0_0_FILL"
                transform="translate(120.55 310.55)"
                id="ObjToo036__col1s"
            />
            <use
                xlinkHref="#ObjToo036__Symbol_76_0_Layer0_0_FILL"
                transform="translate(113.95 273.5)"
                id="ObjToo036__col2n"
            />
        </g></g>
    )
}

export default ObjToo036
