import { AssetSvgProps } from "../../../shared/assets"

function ShiMot046({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0000A4"
                    d="M159.2 258v35.7h66.5V258h-66.5z"
                    id="ShiMot046__Layer5_0_FILL"
                />
                <g id="ShiMot046__Layer5_1_FILL">
                    <path
                        fill="#FFF"
                        d="M210.75 288.45l1.55.15-.2 1.55 1.1-1.15 1.1 1.15-.2-1.55 1.55-.15-1.35-.8.85-1.3-1.5.55-.45-1.5-.5 1.5-1.45-.55.9 1.3-1.4.8M203 275.9l1.1-1.15 1.1 1.15-.2-1.55 1.55-.15-1.35-.8.85-1.3-1.5.55-.45-1.5-.5 1.5-1.45-.55.9 1.3-1.4.8 1.55.15-.2 1.55m14.15.95l-.35-1.05-.35 1.05h-1.05l.85.6-.3 1 .85-.6.9.65-.35-1.05.85-.6h-1.05m4.15-8.25l-.5 1.5-1.5-.55.9 1.3-1.35.8 1.55.15-.2 1.55 1.05-1.15 1.1 1.15-.15-1.55 1.5-.15-1.35-.8.85-1.3-1.45.55-.45-1.5m-9.15-5.15l-1.4.8 1.55.15-.2 1.55 1.1-1.15 1.1 1.2-.2-1.6 1.55-.15-1.35-.8.85-1.25-1.5.55-.45-1.55-.5 1.55-1.45-.6.9 1.3m-39.4 18.05l1.9 2.8-2.95 1.75 3.35.3-.45 3.35 2.35-2.45 2.35 2.5-.4-3.35 3.35-.3-2.95-1.8 1.9-2.75-3.2 1.2-1.05-3.3-1 3.3-3.2-1.25m10.4-11.5h2.65l9.35 4.7v-.7l-7.9-4h7.9v-1.25H178.9v7.15h1.2v-5.4l10.75 5.4h3.95l-11.65-5.9m12-10.3V258h-.4l-11.6 5.9h-2.5l11.4-5.9h-1.45l-10.5 5.35V258h-1.2v7.05h16.25v-1.15H187l8.15-4.2m-35.95 4.2v1.15h16.15V258h-1.2v5.35l-10.5-5.35h-4.25l11.55 5.9h-2.6l-9.15-4.7v.75l7.8 3.95h-7.8m0 12h.35l11.55-5.9h2.6l-11.5 5.9h1.25l10.7-5.45v5.45h1.2v-7.15H159.2V270h7.85l-7.85 4.05v1.85z"
                    />
                    <path
                        fill="red"
                        d="M173.7 270h-2.6l-11.55 5.9h2.65l11.5-5.9m21.05-12h-2.7l-11.4 5.9h2.5l11.6-5.9m-8.95 12h-2.65l11.65 5.9h.35v-1.2l-9.35-4.7m-26.6-12v1.2l9.15 4.7h2.6L159.4 258h-.2m0 7.05v3.7h16.15v7.15h3.55v-7.15h16.25v-3.7H178.9V258h-3.55v7.05H159.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot046__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot046__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot046
