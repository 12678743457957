import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiWou002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.35 12.25q.4-.85.75-1.75.1-.35.3-.95l1.8.55q.2.05.4-.05.2-.15.3-.35.05-.2-.05-.4-.15-.2-.35-.25l-1.85-.55q.25-1.2.6-2.55.45-1.7 1.2-5.95-1.95 4.2-3.9 7.85l-2.3-.7q-.2-.1-.4.05-.2.1-.25.3-.1.2.05.4.1.2.3.3l2.1.6q-.45.95-.9 1.8-.7 1.3-1.4 2.7l-2.35-1.05q-.25-.1-.45 0-.2.05-.3.3-.1.2 0 .4t.3.3l2.2 1q-1.4 2.3-2.95 4.8l-1.7-1.25q-.2-.15-.4-.1-.25.05-.4.25-.15.15-.1.4.05.2.25.35l1.7 1.25h-.05q-1.3 1.9-2.7 3.8l-1.45-1.45q-.15-.15-.4-.15t-.4.15q-.15.15-.15.4t.15.4l1.55 1.55-.3.4-.5.7Q9.2 27.1 8.05 28.5l-1.4-1.45q-.15-.2-.35-.2-.25 0-.4.15-.2.15-.2.4 0 .2.15.4l1.55 1.6q-.15.1-.25.25l-.05.05q-.4.5-2.65 3.3-.05.1-.1.15L3 31.45q-.15-.2-.35-.2-.25 0-.45.15-.2.1-.2.35 0 .2.15.4l1.45 1.9Q1.8 36.2.3 38q-.2.15-.3.35.1-.1.25-.15.8-.5 4.1-3.25l1.3 1.6q.1.2.35.2.2 0 .4-.1.2-.15.2-.35 0-.25-.1-.45l-1.3-1.6q2.9-2.55 3.3-2.95l.1-.1q.15-.1.3-.25l1.45 1.6q.15.15.4.15.2 0 .4-.15.15-.15.15-.35 0-.25-.15-.4l-1.5-1.6q1.35-1.3 2.55-2.55.25-.35.55-.65.15-.2.35-.35l1.45 1.45q.15.15.4.15t.4-.15q.15-.15.15-.4t-.15-.4l-1.45-1.45q1.65-2 3.05-3.9.05-.1.15-.15l1.8 1.3q.15.15.4.1.2-.05.35-.2.15-.2.1-.4-.05-.25-.2-.4l-1.8-1.3q1.85-2.8 3.2-5.45l2.1.9q.2.1.4 0 .2-.05.3-.25.1-.25 0-.45t-.25-.3l-2.1-.9q.6-1.45.95-2.2z"
                    id="SkiWou002__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou002__Symbol_4_0_Layer0_0_FILL"
                transform="translate(238.25 187.5)"
                id="SkiWou002__col1n"
            />
        </g></g>
    )
}

export default SkiWou002
