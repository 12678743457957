import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col2n": "#FFFF00"
}

function AccMas037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M265.45 31.85q-2.15-7.05-4.85-14.3-1.85-6-8.05-17.55-7.1 30.4-57.55 62.95-.15.1-.25.2-.15-.1-.25-.2-57.5-32.55-115.8 0l-.2.2-.3-.2Q27.75 30.4 20.65 0q-6.2 11.55-8.05 17.55-2.7 7.25-4.85 14.3Q-9.1 91.95 10.8 133.3q2.05 4.2 4.4 8.15 14.7 26.1 26.35 29.7l.05.05q.2.05.45.15.05 0 .1.05.25.05.6.15.9.25 1.9.5 1.95 4.1 4.1 7.95 7 15.2 19.8 28 19.3 19.3 44.1 25.4-3.65-3.55-4.6-4.45l-.35-.35q-3.6-3.7-5.8-9.85-3.65-10.25-3.25-27.4 16.5 3.7 37.85 5.9v.05h.2q18.95-1.8 37.85-5.95.4 17.15-3.25 27.4-2.7 7.6-9 12.75l-2.6 2.15q25.3-6 44.95-25.65 12.8-12.8 19.8-28 2.15-3.85 4.1-7.95 1-.25 1.9-.5.35-.1.6-.15.05-.05.1-.05.25-.1.45-.15l.05-.05q11.65-3.6 26.35-29.7 2.35-3.95 4.4-8.15 19.9-41.35 3.05-101.45M183.1 172.2q-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-.7-.75-1.3-1.45-.1-.1-.1-.15h-16.25q-.1.1-.15.2-.65.75-1.3 1.45-7.25 7.75-16.55 9.3-1.25.2-2.5.3H108q-3.3.25-6.3-.3-6.65-1.2-11.5-6.45-7.2-7.6-6.35-19.2.75-11.65 9.05-20.45 8.15-8.85 19.05-9.65 10.65-.85 17.75 6.65l.1.1.2.2h13.25q.05-.05.15-.1.05-.1.1-.15 7.05-7.6 17.8-6.75h.05q10.85.8 19.05 9.65 8.25 8.8 9 20.45.85 11.6-6.3 19.2z"
                    id="AccMas037__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.25 24.6q.2.05.45.15.05 0 .1.05.25.05.6.15.9.25 1.9.5.1.05.2.05Q2.25 13.15 0 0q-.15 12.3 4.2 24.55l.05.05m186.95.85q1-.25 1.9-.5.35-.1.6-.15.05-.05.1-.05.25-.1.45-.15l.05-.05Q198.65 12.3 198.5 0q-2.25 13.15-7.5 25.5.1 0 .2-.05z"
                    id="AccMas037__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M58.15 105.3q-1.35 5.7-2.1 8.1-1.95 5.85-6.15 10.85l-.25 2.6q-1.6 10.6-5.2 16.35l13.75 10.35v-.15l13.55-10.2q-3.6-5.75-5.15-16.35l-.2-2.65q-6.15-7.3-7.5-16.4l-.75-2.5m57.2-85.3q.45-.3.9-.55-.15-.1-.25-.2Q91.05 0 58.1 0T.2 19.25l-.2.2q.4.25.85.55Q40.3 47.05 50.7 83.75q.05.3.15.55l.35 1.6.1.1q3.1 3.35 4.75 7.55.75 2.05 1.95 7l.4-.8.45-1.25q1.65-7.6 6.05-12.4l.3-1.1v-.05q.15-.45.25-.95l.3-.95Q76.25 46.8 115.35 20z"
                    id="AccMas037__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas037__Symbol_117_0_Layer0_0_FILL"
                transform="translate(55.9 13.7)"
                id="AccMas037__col1n"
            />
            <use
                xlinkHref="#AccMas037__Symbol_72_0_Layer0_0_FILL"
                transform="translate(93.25 160.3)"
                id="AccMas037__col1d"
            />
            <use
                xlinkHref="#AccMas037__Symbol_36_0_Layer0_0_FILL"
                transform="translate(134.4 57.4)"
                id="AccMas037__col2n"
            />
        </g></g>
    )
}

export default AccMas037
