import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M72.15 11.55l.5.55q-4.85-5.9-7.5-6.75-7.75-2.8-15.5-4.15-11.7-2.4-24.9 0-7.05 1.35-14.1 4.15-2.4.85-6.1 5.65l-.8 1.35Q.15 18.15 0 29.65-.15 41.1.95 43.7l1.1-1.3q1.45-2.5 1.6-2.55-.7 5.8.65 10.4.55 1.8 1.4 3.4 3 5.7 8.85 6.85l4.65.8q-.6-1.7-1.1-3.35h.95q.45-.05 2.3-.1-1.05-.5-2.4-2.25-1.55-2-2.35-3.8-.35-.75-.6-1.55-.55-1.6-.65-3.3-1.3-7.1-.95-13.2.2-4.85.55-9.15l-.1-6.8q1.45.15 2.15.95.6-2.05 1.4-4.1.8 1.5 2.35 2.75 1.35-1.85 5-5.25.05.8.15 1.45-.1 0-.15.05h.15q.2 1.45.45 2.3.8-.75 3.05-1.95 2.2-1.2 2.65-1.1.45.05 1.25.45.75.35 1.3.6 1.2.45 3.05 1.25v.15q.05-.1.2-.15.25 0 1.1-1.55.7-1.3.9-1.5 2.2 1.1 2.85 1.35 1.45.45 4.15 2.35.6-1.9 1.6-2.85v-.15q.05 0 .1.05.7.6 1.9 1.6 2.2 1.95 3.2 3.2 1.95-1.35 3-3.05.9 2.3 1.6 4.55.75-.5 2.65-1.35 1 17.3.85 19.95.5 5.35-2.5 12.45h-.05q-.3.8-.65 1.55-.9 1.8-2.55 3.8-1.6 1.8-2.75 2.25 2.65.1 2.85.1h.8q-.6 1.65-1.25 3.35l5.15-.8q6.4-1.15 9.75-6.85.85-1.5 1.4-3.15H72q1.6-4.7.85-10.65.15.05 1.75 2.55l1.25 1.3q1.15-2.6 1-14.05-.15-11.5-4.1-17.3l-.6-.8z"
                    id="BeaMus036__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M173.3 258.3q-.55-1.6-1.05-3.1-3.7-2.85-4.85-4.2-1.1-1.2-1.9-2.6l-.1-.1q-.9-1.1-1.35-1.95-1.05-2.05-2.55-6.4-.2 2.6.55 5.55.35 1.45 1 2.75t1.45 2.4q.75 1.05 2.25 2.6t1.8 1.85q1.1 1.1 2.25 1.85.6.35 2.25 1.3l.25.05m-2.25-46.8l.25 3.6q.45-1.4.95-2.8.1-.35.25-.65.1.2.25.45-.75-1.55-1.55-2.5-.15.55-.15 1.9m-2.75 2.75q-1.75-.9-2.25-1.55.05.65.05 1.45.1 3.95-.35 10-.55 7.2-.35 11.85.2 4.6 1 7.65.8 3.05 1.25 4 .15.25.4.65.65 1.05 2.25 2.9 2.25 2.8 4.15 3.25l.6.15q-.9-.6-2-2-1.55-2-2.35-3.8-.15-.25-.2-.45-.25-.55-.4-1.1-.55-1.6-.65-3.3-1.3-7.1-.95-13.2.2-4.85.55-9.15l-.1-6.8q.15 0 .35.05-.45-.3-1-.6m12.95-5.15q.2-.9.45-1.5-.5.15-1.15.8-.2.25-.55.65-.1.05-.15.15.05.75.15 1.4-.1 0-.15.05h.15q.1.9.25 1.6l.2.7q.05-.1.2-.2 0-.25.05-.5.05-.15.05-.3.2-1.7.5-2.85m13.15-.2q-.05.2-.15.4.05.05.1.05 1.85.9 2.45 1.15 1.15.35 3.05 1.6.5.3 1.1.75v-.05q-.35-.35-.75-.7-.6-.6-1.25-1.15-.95-.85-1.75-1.55-1.35-1.15-2.25-1.95-.2.35-.55 1.45m27.55 33.3l-.5 1q-1.9 3.6-3.3 5.6-.25.4-.5.7-2.95 3.95-6.65 5.45-.5 1.35-1.25 3.3 1.5-1 3.2-1.8 1.7-.8 3.5-2.3 1.75-1.55 2.9-3.35 1.1-1.85 1.15-1.95.7-1.95 1.45-5.35V242.2m-4.8-28.25L215 215q1 17.15.85 19.8.25 2.45-.3 5.35-.6 3.3-2.2 7.1h-.05q-.25.75-.6 1.5-.05 0-.05.05-.9 1.8-2.55 3.8-1.6 1.8-2.75 2.25.3 0 .6.05.2-.05.4 0 3.45-2.05 6.1-6.1.3-.55.65-1.15 1.85-3.2 2.65-6.35.55-2.3.55-4.55 0-1-.6-12.45-.4-7.1-.55-10.35m-6.35-2.1q0 .05.05.15.8 1.95 1.45 3.95.4-1 .7-2.55.15-.8.3-1.4.15-.9.25-1.4v-.75q-1.6.8-2.75 2m-7-3.4q-.4.35-.85 1.1l-.2.3q-.05.05-.05.1.7.6 1.85 1.55.3.25.6.55 1.15 1.05 1.9 1.85-.35-.75-.8-1.5-.1-.2-.2-.35-.95-1.55-1.45-2.45-.5-.85-.65-1.25 0 .1-.15.1z"
                    id="BeaMus036__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus036__Symbol_53_0_Layer0_0_FILL"
                transform="translate(154.1 197)"
                id="BeaMus036__col1n"
            />
            <use
                xlinkHref="#BeaMus036__Symbol_15_0_Layer0_0_FILL"
                id="BeaMus036__col1d"
            />
        </g></g>
    )
}

export default BeaMus036
