import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#00B600",
    "col1n": "#008300",
    "col2n": "#E5B000"
}

function ObjFoo037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.35 57.8q-3.15 0-5.4-2.25-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1H19q-3.3 1.25-6 .6l2.1 6.9q.45.85 1.2 1.45.15.15.35.2.1.1.2.1.5.2 1.1.3.35 0 2.2-.45l.2-.05.05-.05 13.5-4.05q.3-.05.55-.1l.4-.1q.1-.05.2-.05 0-.05.05-.05 1.45-.5 2.6-1.35.3-.2.55-.45.1-.15.15-.25.2-.2.4-.45.15-.2.35-.5.35-1.1.1-2.4l-3.6-11.05q-2.2 2.15-5.3 2.15M12.45 1.65v.05l-.6-1.7L0 3.5l1.3 4.95 1.6.35 5.35 24q-.05.05-.05.15-.4 1.1-.95 2.05-.6.8-.65 1.1-.25.2-.4.55-.2.2-.3.4-2 3.1-.7 5.7l1.05 3.4q.3-.2.7-.4 4.45-1.85 10.2 0 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4 2.25-2.25 5.4-2.25.55 0 1.1.1l-2-6.4q-.8-3.25-3.5-4.8-.3-.15-.55-.35-.1-.05-.15 0-.2-.2-.5-.25-.05-.05-.1-.05l-.1-.05q-2-.85-3.1-1.5-.2-.2-.4-.35L12.3 6l1.1-1.4-.95-2.95z"
                    id="ObjFoo037__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M29.15 60.75L26.9 52.9q-1.6.8-3.45.8-.65 0-1.25-.1l2.3 7.75h.05l1.25 4.3q-.2.05-.3.1-.45.15-.85.3-13.95 4.65-15.4 3.9.2.45.6.7 1 .75 3.25.4l13.1-3.9 1-.3q.8-.35 1.35-.8 1.55-1.25 1.25-3.1-.05-.2-.1-.35l-.5-1.85h-.05M13.55 27l-2.25-1.9-6.8 2-.55 2.7 9.6-2.8m.55.7q.1.4.6 1.55.6 1.2.8 1.7.05.55.2.75l2.6 8.75q1.85-1.75 4.35-2l-1.1-3.75-.6-1.2H21q-.15-.25-.25-.4-.65-1.2-1.55-2-1.9-1.7-5.1-3.4M9 24l1.8-.6L3.95 3.95l-1.7.45L9 24M3.55 2.05L3 0 0 .85.6 2.9l2.95-.85z"
                    id="ObjFoo037__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.5 2.5q.15.35.55.45.45.35.95.2V3.1q.2 0 .35-.1.5-1.35-.75-2.65-.7-.5-1.8-.3-4.45.75-7.6 2.1-.65.35-1.4.7Q.5 3.45.05 4.3q-.15.9.25 1.6.15.3.4.65.1 0 .25.05.2.05.45-.05.5-.15.8-.75.15-.25.15-.5.3-1.45 1.3-.5l.05.05q.15.3.4.45.5.3 1.05.2.45-.15.7-.75.15-.25.15-.6h.05v.05l.05-.05q.35-1.55 1.55-.45v.05q.15.25.5.35.4.35.95.2.45-.15.75-.7.15-.3.1-.6.4-1.45 1.55-.5z"
                    id="ObjFoo037__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo037__Symbol_102_0_Layer0_0_FILL"
                transform="translate(101.6 241.45)"
                id="ObjFoo037__col1n"
            />
            <use
                xlinkHref="#ObjFoo037__Symbol_101_0_Layer0_0_FILL"
                transform="translate(108.5 245.55)"
                id="ObjFoo037__col1l"
            />
            <use
                xlinkHref="#ObjFoo037__Symbol_15_0_Layer0_0_FILL"
                transform="translate(101.05 240)"
                id="ObjFoo037__col2n"
            />
        </g></g>
    )
}

export default ObjFoo037
