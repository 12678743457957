import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.55 6.6l-.35-.2q-.95-.4-3.9-2.05-2.95-1.7-3.35-1.9-7.25-4.8-13.2-.1-5.95-4.7-13.15.1-.4.2-3.35 1.9Q1.3 6 .35 6.4L0 6.6l20.75 3h.05l20.75-3z"
                    id="FacMou044__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M37.9 2.1q.7-.45 1.9-.95Q41 .6 41.6.25 38.85-.2 35.65.1q-3.3.35-5.8.65-2.15.35-3.7.9-.2 0-.45.1-.2.1-1.4.35-1.7.55-3.5.55t-3.5-.55q-1.2-.25-1.4-.35-.25-.1-.45-.1-1.55-.55-3.7-.9-2.5-.3-5.8-.65Q2.75-.2 0 .25q.6.35 1.8.9 1.2.5 1.9.95.7.4 1.65 1.1.95.65 1.95 1.5 1.4 1.45 2.35 1.95L11 7.4q.5.35.95.6 4.15 2.05 8.85 2 4.7.05 8.85-2 .45-.25.95-.6l1.35-.75q.95-.5 2.35-1.95 1-.85 1.95-1.5.95-.7 1.65-1.1z"
                    id="FacMou044__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou044__Symbol_69_0_Layer0_0_FILL"
                transform="translate(171.75 214.5)"
                id="FacMou044__col1n"
            />
            <use
                xlinkHref="#FacMou044__Symbol_22_0_Layer0_0_FILL"
                transform="translate(171.7 220.85)"
                id="FacMou044__col1l"
            />
        </g></g>
    )
}

export default FacMou044
