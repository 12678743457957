import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#773900",
    "col1n": "#AD5300"
}

function ObjToo021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.9 11.4V7.2L116.75 0 55.8 18.65q-1.5.5-2.95 1.05-.65.2-1.25.45l-2.15.8-.5.2q-1.4.55-3.8 1.45h.1Q22.1 31.8 4.1 44.85q-.25.15-.4.3-.3.25-.6.45l-1.85 1.55q-.75.7-1 1.75-.4.05-.1.45L2 55.05l9.8 7.3 2.95-.9-.05-.05 6.6-2.6q1.6-.85 3.2-1.6-.4-.2-.8-.45-3.3-2.1-3.3-5 0-2.95 3.3-5 3.35-2.1 8.1-2.1h.9q4.4 0 7.5 2.1 1.55 1 2.35 2.3l1.2-.55q3.95-1.7 5.15-2.25.25-.1.45-.2L60.8 37.2l-.15.15 5.6-4.35 11.6-4.75v-.05l44.05-16.8z"
                    id="ObjToo021__Symbol_181_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M13.35 46.4q-.3.15-.65.35l-.25.25-3.85 2.85h-.05q-.65-.45-1.25-1-3.2-2.5-6-5.35-.5-.5-.9-.95-.25-.2-.4-.4l1.85 5.7 9.8 7.3 2.95-.9-.05-.05 6.6-2.6q1.6-.85 3.2-1.6-.4-.2-.8-.45-3.3-2.1-3.3-5 0-1.5.9-2.75Q19 43 16.9 44.25q-1.8 1.05-3.55 2.15m108.4-42.2V0L62.4 22.1v-.05l-2.95 1q-1.2.45-2.45 1-14 5.6-28.75 13.7 1.6-.3 3.4-.3h.5q.2 0 2.6.3t4.7 1.75q2.25 1.45 2.95 2.35l1.2-.55q3.95-1.7 5.15-2.25.25-.1.45-.2L60.65 30l-.15.15 5.6-4.35 11.6-4.75V21l44.05-16.8z"
                    id="ObjToo021__Symbol_122_0_Layer0_0_FILL"
                />
                <g id="ObjToo021__Layer5_0_FILL">
                    <path d="M157.5 282.85q1.95.8 3.15 2.85 1.25 2 12.4-1.25l14.35-4.85q6.65-3 8.3-5.6v-.6l-.05.05-3.45-.6q-12.8 4.9-26.25 7.15v-.15-.25l-8.45 3.25z" />
                    <path
                        fill="#666"
                        d="M172.95 264.75l-8.2 2.4.6 1.35 1.6 3.65 10.25-3.45-4.25-3.95M91.8 296.5q.15-.15.4-.3-12.3-13.85-15.95-37.9-1.85-.7-.95-1.1.55-.25.75 0v-.05l.05.1q-.15-.7-.2-1.2l-1.35-.8H73.4q-.05.75-.05 1.5-.05.45-.05.9-.65 24.2 12.5 40.2l-13.55 3h-.15v4.8l14.15 17.6 14.15-12.2q1.2.9 2.45 1.75 5.85 3.8 13.2 6.4 3.05 1.1 6.4 1.95 6.7 1.75 14.45 2.6 9.95 1.05 21.55.7v-3.05h-.25q0 .1-.05.2-.55-.05-1-.15v-.1l-.15-.8q-15.65-1.15-27.65-3.75-1.5-.35-2.9-.7-4.5-1.1-8.45-2.4-.35-.15-.7-.25-1.1-.4-2.15-.8-2.35-.9-4.45-1.85-.5-.2-1.05-.5l-2.7-1.75 3.3-1.65q-1.15-1.1-1.55-2.4l-4.4 2.25-2.7-1.8q-.3.15-.65.35l-.25.25-3.85 2.85h-.05q.05.002.1.1l-.05.05-9.5 7.35-9.9-11.25 11.3-2.45-.5-1.5q-.3-.4.1-.45.25-1.05 1-1.75l1.85-1.55q.3-.2.6-.45z"
                    />
                    <path
                        fill="#FFF"
                        d="M147.1 273.15l-.35.95 17.65-7.75-1.8-4.1-14.6 8.2-.9 2.7m.6 3.1l1.25.55 2.3 1 15.85-5.25-.15-.4-1.6-3.65-17.65 7.75z"
                    />
                    <path
                        fill="#252525"
                        d="M146.75 274.1l.35-.95-71-15.9-.05-.05q-.2-.25-.75 0-.9.4.95 1.1l70.5 15.8m.95 2.15l-.25.15.35.05.75 4.9v.05l8.45 39.15.15.8v.1q.45.1 1 .15.05-.1.05-.2v-.15l-8.6-40.1v-.1l-.65-4.25-1.25-.55z"
                    />
                    <path
                        fill="#BFBFBF"
                        d="M110.4 292.7l-.95-2.15-1.8-4.1-8.45 9.3-.8 1.1 13.8-.05-1.8-4.1z"
                    />
                    <path
                        fill="#271200"
                        d="M109.45 290.55l.95 2.15 37.05-16.3.25-.15 17.65-7.75-.6-1.35-.35-.8-17.65 7.75-37.3 16.45z"
                    />
                </g>
                <g id="ObjToo021__Layer5_1_FILL">
                    <path
                        fill="#333"
                        d="M165.95 280q-.1 1-.25 1.9.1.5.2.9 15.4-3.25 29.75-9.35l-3.45-.6q-12.8 4.9-26.25 7.15z"
                    />
                    <path
                        fill="#BFBFBF"
                        d="M174.5 267.8l-1.7-1.3-.15-.15-7.25 2.25.8 1.85 8.3-2.65z"
                    />
                    <path
                        fill="#999"
                        d="M90.05 295.6q.5.55.95 1.15l-18.75 4.1 14.45 17.9 15.05-12.45q.15.1.35.3.2.1 1.15.7.85.6 2.6 1.75.8.55 1.8 1.15l.6.4h.05q3.55 2 8.15 3.7.4.15.85.3 4.55 1.6 10.2 2.85 12.3 2.75 29.65 3.9l-.15-.8q-15.65-1.15-27.65-3.75-1.5-.35-2.9-.7-4.5-1.1-8.45-2.4-.35-.15-.7-.25-1.1-.4-2.15-.8-2.35-.9-4.45-1.85-.5-.2-1.05-.5l-8.05-5.35q-.3.15-.65.35l-.25.25-3.85 2.85.05.1-.05.05-9.5 7.35-12.3-14 14.3-3.4 1.85-1.55q.3-.2.6-.45.15-.15.4-.3-12.3-13.85-15.95-37.9-1.85-.7-.95-1.1.55-.25.75 0v-.05l.05.1q-.15-.7-.2-1.2l-1.35-.8h-.1q.1.85.15 1.7 0 .05.05.1 0 .45.1.95 2.75 23.1 15.3 37.6z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo021__Symbol_181_0_Layer0_0_FILL"
                transform="translate(88.1 251.35)"
                id="ObjToo021__col1n"
            />
            <use
                xlinkHref="#ObjToo021__Symbol_122_0_Layer0_0_FILL"
                transform="translate(88.25 258.55)"
                id="ObjToo021__col1d"
            />
            <use xlinkHref="#ObjToo021__Layer5_0_FILL" />
            <use xlinkHref="#ObjToo021__Layer5_1_FILL" />
        </g></g>
    )
}

export default ObjToo021
