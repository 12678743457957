import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.25 7.75q7.2.15 14.25-2.9.75-.25 1.55-.6 1.3-1.35 1.5-4.25H0q.2 2.9 1.5 4.25.8.35 1.55.6 7.05 3.05 14.2 2.9z"
                    id="FacMou021__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    d="M214.2 221.55v-.05h-43.4v.05q1.3 2.3 3.45 4.1l.3.3q4.2 3.15 11.5 4.4 3.2.65 6.45.6 3.25.05 6.45-.6 7.3-1.25 11.5-4.4l.3-.3q2.15-1.8 3.45-4.1m-5.5.95q-.2 2.35-1.4 3.45-.75.3-1.45.5-6.6 2.45-13.35 2.35-6.75.1-13.35-2.35-.7-.2-1.45-.5-1.2-1.1-1.4-3.45h32.4z"
                    id="FacMou021__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou021__Symbol_46_0_Layer0_0_FILL"
                transform="translate(175.25 222)"
                id="FacMou021__col1n"
            />
            <use xlinkHref="#FacMou021__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou021
