import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSho081({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M189.95 7.3h-.5q-4.35.05-8.45.2-9.25.8-18.25 2.5-3.65.65-7.05 1.8-4.75 1.65-9.4 4.05-2.5 1.3-5.3 2.2-1.1.35-2.05.35-3-.6-3.35-2.85 0-.05.05-.1-.1-.5-.05-1.05v-.3q.1-1.4.35-2.3.2-.95 1.05-2.3.85-1.4 3.75-3.8 2.65-2.2 5.55-4.2-19.55-5.35-37.3 8.45-4.05 3.05-7.95 7.05-.15.1-.25.25l-1.35 1.35Q82.6 9.7 65.4 3.35q-.15 8.5-2.45 29.1-36.7-3.6-56.85 16.25-.15.05-.25.1v.15q0 .15.05.3 3.3.5 6.75.8 2.35.2 4.7.5 1.35.15 3.25.6 1.6.35 2.5.65.05.05.3.25.7.5.75.7 2 6-22.25 24.8-.25.2-.5.45-.75.6-1.4 1.2 5.5 5.5 8.65 12.6l-2.6 34.35 3.95 6.6q2.55 4.3 5.15 8.55l2.25 3.8q.7 1.1 1.4 2.25l.7 1.1q3.05 4.8 5.6 10 .3.65 1.2 2.25v15.75h12.8q-9.7-14.05-12.3-27.3-3.8-21.65 1.55-37.5.6-.4 2.95-1.5 3.1-1.45 6.7-3.3h.05v-.15q-2-13.45 5.15-24.95 5.5-9.3 16.2-10.6 12.7-2.95 24.1 3.8 3.25 1.9 6.3 4.3 2.9 2.3 5.65 2.4 1.65-7 8.1-9.8l.55-.1q.05-.05.15 0 11.9-2.05 15.05 6.55 1.9 2.55 3.7 1.9-.45-1-.65-2.25-.45-3.1.45-5.6 2.05-4.9 6.2-5.3Q147.15 69 158.6 80.6q3.2 2.5 4.75 5.9.9-2.85 2.45-5.4 1.45-2.15 5.4-.55 3.75 1.2 6 5.35 1.9 3.55 4.15 7.05 7.1 10.7 8.6 23.2.3 2.5.4 5.1.05 1.5-.05 2.95 7.3-.9 10.2 5.2.75 1.7.85 3.65l.1 1.9v1.6l-.2 7.7q-.2 3-.55 6l-.15 1.05q-.4 2.3-.95 4.6-2.6 11.8-8.75 20h2.25q.7-.05 1.4 0h.35l11.3-16.45 11-4.25.1-.5q.35-3.05.35-6.25-.2-7.85-.25-15.65-.05-4 0-7.95-.05-1.05.05-2.1v-8.8l-3.45-12.55q-.15-1.75 1.05-1.45l6.55 2.3v-.05q-.4-10.1-5.65-20.2l-19.4-19.7q-1.15-.95-1.9-1.7-1-1-2.5-2.75-.1-.15-.1-.45 0-3.65.05-7.5 0-3.85-.1-5.8-.15-2-.25-4.9-.2-.3-.4-.5-1.7-1.15-3.4-2.15-3.35-1.95-6.7-3.8-.75-.4-1.4-.9l.1.1q-2.75-1.75-3.7-2.35-4.35-3.15-1.35-8.45 3.2-6.55 14.55-13.75.25-.05.55-.1z"
                    id="HaiSho081__Symbol_202_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.8 48.5q-.1-.2-.2-.25-.15-.1-.3 0-.2.05-.25.2-.1.1 0 .3 1.8 5.35 6.3 10.7.1.1.2.15.15.05.25 0t.2-.15Q15.95 45.4 29.25 44.4q.15 0 .25-.15.1-.1.1-.25 0-.2-.15-.3-.1-.1-.25-.1-13.45 1-22.6 14.95Q2.5 53.5.8 48.5M93.35 5q.15 0 .3-.1.1-.15.1-.3t-.1-.25q-.15-.15-.3-.15-24.4.2-49.15 11.65-.15.05-.2.2-.1.15 0 .3 0 .1.15.2t.3.05q16.75-3.15 31.35-2.85-11.1 6.4-20.55 13.95-.15.1-.15.25t.1.3q.1.15.25.15t.3-.05q9.95-7.95 21.7-14.6.1-.05.2-.2.05-.1 0-.25-.05-.1-.15-.2-.1-.1-.2-.1-13.9-.45-29.75 2.25Q70.65 5.2 93.35 5m44.3-4.45q.05-.15 0-.3-.1-.15-.2-.2-.15-.05-.3 0-13.75 3.5-19.35 13.35-.1.15 0 .3 0 .15.15.25t.3.05q.15-.05.25-.2 5.35-9.3 18.15-12.8-3.3 10.3 2.7 19.5.05.1.25.15.15.05.3-.05.1-.1.15-.25 0-.2-.05-.3-5.95-9.15-2.35-19.5m33.4 37.15q-.05-.15-.1-.25-15.15-14.55-31.75-9.2-.2.05-.25.2-.1.1 0 .3.05.15.2.2.1.1.3.05 15.75-5.05 30.15 8.35-5.9-.1-11.4 2.7-.15.05-.2.2-.05.1-.05.2.05.15.15.25.05.05.2.1 5.65 1 9.45 4.65-7.5-.3-14.05 2.95-.15.05-.2.25-.1.15 0 .3.05.1.25.2.15.05.3-.05 6.85-3.35 14.7-2.8.1 0 .2-.05t.15-.15q.05-.15.05-.25 0-.15-.1-.2-3.75-4.1-9.45-5.4 5.35-2.4 11.05-2.1.1 0 .2-.05l.2-.2v-.2z"
                    id="HaiSho081__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho081__Symbol_202_0_Layer0_0_FILL"
                transform="translate(77.7 36.25)"
                id="HaiSho081__col1n"
            />
            <use
                xlinkHref="#HaiSho081__Symbol_11_0_Layer0_0_FILL"
                transform="translate(92.8 61.35)"
                id="HaiSho081__col1s"
            />
        </g></g>
    )
}

export default HaiSho081
