import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#1E1E1E",
    "col1n": "#434343"
}

function BeaMus013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M61.2 39.35q-3.95-1.55-5.2-1.9l-4.35-1.5q-1.2-.6-1.8-.75-9.25-2.95-19 0-.75.2-1.5.5-3.25 1.2-5.05 1.55-1.1.2-2.9.35-.35.05-.65.05-8.85 1.8-15.05 8.25-.55.5-1.05 1.1Q1.7 50.2 0 53.9q4.35-3.05 8-3.6-2.95 3.9-2.15 10.05.1.4.25.8l.05-.05q.1-.15.25-.3 4.1-5.1 8.4-8.05.7-.5 1.4-.9.05-.05.1-.05l-.1.1q-1.85 3.35-.95 7.65l.2-.3q3.1-5.5 7.8-9.3.1-.1.25-.2-.7 2.75.2 6.35l.2.75.45-.75q1.9-2.2 3.45-3.6 2.45-2.35 4.05-2.85h.1q.6 3.75 2.6 7.1v.15q.002-.05.05-.05 2.45-4.5 4.25-6.2-.05.05-.1.15.55-.55 1.05-.85 2.15-1.45 3.25 4.85v.1q.002-.05.05-.05.1-.15.2-.25 8.1-8.85 8.25.6-.05.5-.1 1.35 1.85-2.7 2.4-4.1.3-.85.3-1.95 3.25 2.7 3.8 6 1.15-2.3 2-3.9 0-.1.05-.15 3.7-.5 5.8 2.45 1.7 2.4 2.45 3.2.2.15.3.25-.2-.8-.55-2.7-.4-1.9-2.15-5.15l-.35-.75q6 1.25 9.6 8.5-.05-.2-.1-.35-.1-.35-.15-.65-.05-.05-.05-.1l-1.45-4.55q-.25-.7-.5-1.35-.05-.05-.05-.1-.75-1.8-1.45-3.2-2.7-5.2-8.75-8.05-.6-.25-1.4-.55z"
                    id="BeaMus013__Symbol_30_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M13.4 1.1q.15-.15.25-.3h-.1q-2.2.05-3.1.35-.9.25-1.75.5-.85.2-3.1 1.75Q3.3 4.95 2 6.5.65 8.15.15 8.65q-.1.15-.15.25 4.35-3.05 8-3.6.15-.3.4-.55 1.9-2.2 5-3.65m3.2 4.95q.95-1.65 1.15-2.1-2.1.8-4.7 3.4-3.35 3.1-3.75 3.7-.3.3-1.55 2.3-1 1.7-1.35 2.45 4.1-5.1 8.4-8.05.7-.5 1.4-.9l.4-.8m7.1-1.9q.3-.55.45-1.85-2.8 2.05-5.2 5.1-2.8 3.55-3.5 6.85 3.1-5.5 7.8-9.3.15-.25.45-.8m10.9 7.7q-.048-.048-.05-.1v.15q.002-.05.05-.05m-2.75-7.2h.1q-.4-2.3-.3-4.65-.65.55-2.95 2.8-2.3 2.25-3.1 4.3-.8 2-1.7 4.75l.45-.75q1.9-2.2 3.45-3.6 2.45-2.35 4.05-2.85m11.25 5.2q1-5.35-1.5-9.05-.5.9-1.3 2.3-.35.65-.8 1.4-.3.55-.65 1.15-.05.05-.1.15.55-.55 1.05-.85 2.15-1.45 3.25 4.85v.1q.002-.05.05-.05m15.5-.2q0-2.8-1.4-4.75-.55-.7-1.5-1.55-1-.8-2.35-1.8.75 2.3.8 3.95 3.25 2.7 3.8 6 .65-1.6.65-1.85m8.25.25q-1.05-1.8-2.5-3.05-1.15-1-3.6-2.35-.05 1.05-.2 1.6-.15.5-.55 1.35 3.7-.5 5.8 2.45 1.7 2.4 2.45 3.2-.65-1.85-1.4-3.2m8 2.35q-.05-.05-.05-.1Q71.9 4.2 67.6 1.7q-.65-.25-1.55-.5-1.8-.55-2.9-.95l2.35 4.5q6 1.25 9.6 8.5-.05-.2-.1-.35-.1-.35-.15-.65z"
                    id="BeaMus013__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus013__Symbol_30_0_Layer0_0_FILL"
                transform="translate(152.85 160.45)"
                id="BeaMus013__col1n"
            />
            <use
                xlinkHref="#BeaMus013__Symbol_5_0_Layer0_0_FILL"
                transform="translate(152.85 205.45)"
                id="BeaMus013__col1d"
            />
        </g></g>
    )
}

export default BeaMus013
