import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000"
}

function AccHat155({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#333"
                    fillOpacity={0.4}
                    d="M194.25 152.15v-.05q6.3-.2 12.7-.95.5-.05 1.9-.15.982-.098 2.35-.2 20.437-1.735 28.85 15.25 5.65 9.548 6.85 21.35.012.305 0 .6.357 20.75 14.95 30.85l.4.3q6.128.98 5.35-2.1-.734-3.088-1.1-3.45l-6.9-86.8H130.35l-12.1 86.85q-1.545 7.553 4.3 5.5l.35-.25q14.65-10.15 14.95-30.9.05-.3.05-.6 1.2-11.8 6.8-21.3 8.45-17.05 28.9-15.3 1.35.1 2.35.2 1.4.1 1.9.15 6.4.75 12.7.95v.05h3.7z"
                    id="AccHat155__Layer16_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M184.15 37.65q-1.6-1.25-3.3-2.55-8.95-6.7-22.35-14.45-4.55-2.65-10.65-7.5-4-3.15-6.65-5.15-7-4.6-14-6.6-4.85-1.45-9.9-1.4-5.05-.05-9.9 1.4-7 2-14 6.6-2.65 2-6.65 5.15-6.1 4.85-10.65 7.5Q62.7 28.4 53.75 35.1q-1.7 1.3-3.3 2.55Q31.7 52.9 19.85 78.9q-5.5 11.5-6.5 24.55-.05 1.35-.15 2.9-.05 1.3-.15 2.7l-1.4 37.65q.05.35 0 .7-.15 5.15-2.6 14.4-2.65 9.9-3.95 13.1-1.25 3.2-1.4 3.5-2.55 5.95-3.35 10.45-1.7 12.9 5.15 17.3.097.048.15.05H5.5l3.8 1.8q.1 8.05 4.3 15.35.05 0 .05.05 4.3 6.4 13.85 6.4 10.7 0 23.5-6.5.55-.3 1.1-.55l1.7-.95q14.3-8.35 21.75-20.2-.9-.45-1.95-1.05h-.05q-1-.65-2.25-1.45-4.5-2.7-8.8-5.8-2-1.4-3.95-2.95-.45-.35-.95-.7-3.9-3.1-7.3-6.3l-.4-.4-.05-.05q-1.25-1.2-2.35-2.45h-.05q-2.45-2.7-4.3-5.5.25-.35.4-.7 2-1.85 4.8-7.35 2.7-5.3 5.55-12.8 4.5-11.4 4.5-21.2 0-7 .3-10.7.45-5.4 1.7-10.9.2-.85 1.1-3.05 4-11 11.35-12.85 6.4-1.65 13.3.15 7.6 2.5 8.9 2.85 5.35 1.5 9.55 2.1.1 0 .2.05 6.4 1.5 12.5 1.35 6.1.15 12.5-1.35-1.55.2-2.9.3-.9.15-1.65.3h-.1q.75-.15 1.65-.3 1.45-.1 3.1-.35 4.2-.6 9.55-2.1 1.3-.35 8.95-2.85l.6-.2q1.65-.35 3.5-.7h.1q-1.85.35-3.5.7-.3.1-.65.2 6.9-1.8 13.3-.15 7.35 1.85 11.35 12.85.9 2.2 1.1 3.05 1.25 5.5 1.7 10.9.3 3.7.3 10.7 0 9.8 4.5 21.2 2.85 7.5 5.55 12.8 2.8 5.5 4.8 7.35.15.35.4.7-1.85 2.8-4.3 5.5h-.05q-1.1 1.25-2.35 2.45l-.05.05-.4.4q-3.4 3.2-7.3 6.3-.5.35-.95.7-1.95 1.55-3.95 2.95-4.3 3.1-8.8 5.8-1.25.8-2.25 1.45H161q-1.05.6-1.95 1.05 7.45 11.85 21.75 20.2l1.7.95q.55.25 1.1.55 12.8 6.5 23.5 6.5 9.55 0 13.85-6.4 0-.05.05-.05 4.2-7.3 4.3-15.35l3.8-1.8h-.15q.053-.002.15-.05 6.85-4.4 5.15-17.3-.8-4.5-3.35-10.45-.15-.3-1.4-3.5-1.3-3.2-3.95-13.1-2.45-9.25-2.6-14.4-.05-.35 0-.7l-1.4-37.65q-.1-1.4-.15-2.7-.1-1.55-.15-2.9-1-13.05-6.5-24.55-11.85-26-30.6-41.25z"
                    id="AccHat155__Symbol_497_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.4 101.75q-2.05 2.3-6.8 7.45-9.25 10.3-8.55 23.75l2.7 1.3q-.05-3.4.6-6.95l.1-.4q1.65-6.45 6.85-15.45 1.45-2.45 5.2-8.5 2.35-3.75 2.35-4.35-.95 1.45-2.45 3.15m25.55 4.95h-.05q-2.45-2.7-4.3-5.5v-.05q-2.8 4.9-8.85 12.95-2 2.65-3.65 4.95L15.05 132q-2.35 4.5-1.5 8.7.25 1 .65 2.1.15.4.3.85 3.15 7.7 17.85 3.5.05-.05.1-.05Q47 142.85 62.5 130.5q2.8-2.2 4.5-3.7-1-.65-2.25-1.45-4.5-2.7-8.8-5.8-2-1.4-3.95-2.95-.45-.35-.95-.7-3.9-3.1-7.3-6.3l-.4-.4-.05-.05q-1.25-1.2-2.35-2.45M152.45 2.45q3.2.05 9.2 1.75-5.2-1.95-5.7-2.15-10.4-4.1-25.1 0-2.3.75-4.45 1.4-4.6 1.45-5.95 1.7-1.35.25-3.4.7-2.1.4-4.05.6-1.95.15-2.25.15-.3 0-2.25-.15-1.95-.2-4.05-.6-2.05-.45-3.4-.7-1.35-.25-5.95-1.7-2.15-.65-4.45-1.4-14.7-4.1-25.1 0-.5.2-5.7 2.15 6-1.7 9.2-1.75.1-.05.2 0h.2q7.35-.55 12.55.5 6.05.65 14.8 4.2 6.35 3.15 13.95 3.05 7.6.1 13.95-3.05 8.75-3.55 14.8-4.2 5.2-1.05 12.55-.5h.2q.1-.05.2 0m55.5 138.25q.85-4.2-1.5-8.7l-9.05-12.95q-1.65-2.3-3.65-4.95-6.05-8.05-8.85-12.95v.05q-1.85 2.8-4.3 5.5h-.05q-1.1 1.25-2.35 2.45l-.05.05-.4.4q-3.4 3.2-7.3 6.3-.5.35-.95.7-1.95 1.55-3.95 2.95-4.3 3.1-8.8 5.8-1.25.8-2.25 1.45 1.7 1.5 4.5 3.7 15.5 12.35 30.05 16.6.05 0 .1.05 14.7 4.2 17.85-3.5.15-.45.3-.85.4-1.1.65-2.1M206 102.95q3.75 6.05 5.2 8.5 5.2 9 6.85 15.45l.1.4q.65 3.55.6 6.95l2.7-1.3q.7-13.45-8.55-23.75-4.75-5.15-6.8-7.45-1.5-1.7-2.45-3.15 0 .6 2.35 4.35z"
                    id="AccHat155__Symbol_425_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat155__Layer16_0_FILL" />
            <use
                xlinkHref="#AccHat155__Symbol_497_0_Layer0_0_FILL"
                transform="translate(75.1 38.7)"
                id="AccHat155__col1n"
            />
            <use
                xlinkHref="#AccHat155__Symbol_425_0_Layer0_0_FILL"
                transform="translate(81.65 112.45)"
                id="AccHat155__col1d"
            />
        </g></g>
    )
}

export default AccHat155
