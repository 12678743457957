import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M39 39.8q-5.5-1-6.25-1.1-1.5-.15-3-.25-2.1-.3-4.15-.35-2.15.05-4.4.35-1.5.1-3 .25-.75.1-6.25 1.1-10.65 1.8-11.2 5L0 51.9q-.05 1.05.1 1.3.35.8 2 .9 2.1.15 5-.65.75-.2 1.45-.45 1.6-.4 3.55-.75 1.15-.25 2.2-.4 2-.4 3.6-.75 3.75-.6 7.45-.5 3.8-.1 7.7.5 1.6.35 3.6.75 1.05.15 2.2.4 1.95.35 3.55.75.7.25 1.45.45 2.9.8 5 .65 1.65-.1 2-.9.15-.25.1-1.3l-.75-7.1q-.55-3.2-11.2-5z"
                    id="BeaMus009__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus009__Symbol_26_0_Layer0_0_FILL"
                transform="translate(167.05 160.45)"
                id="BeaMus009__col1n"
            />
        </g></g>
    )
}

export default BeaMus009
