import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#747474",
    "col2n": "#999999"
}

function ObjMor041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#98AEB4"
                    fillOpacity={0.251}
                    d="M106.35 242.05q-2.65-2.3-6.15-3.8-8.95-3.75-17.9-.1-9.05 3.75-12.7 12.85-3.8 9.1-.15 18.25 1.85 4.3 4.7 7.45 2.7 2.25 6.15 3.75 8.95 3.85 17.9.1 9-3.8 12.7-12.85 3.7-9.1.05-18.2-1.7-4.25-4.6-7.45z"
                    id="ObjMor041__Layer10_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.361}
                    d="M107.45 259.7q.3-2.6-.5-4.9-1.05-3-2.4-5.15-2.3 1.7-5.85 3.6.9 1.75 1.35 3.35 0 .05.05.15.95 3.4.35 7.8-.2 1.1-1.65 7.15l-.05.5q3.65-2.05 5.65-4.8 2.15-2.85 2.85-6.25.15-.75.2-1.45z"
                    id="ObjMor041__Layer10_0_MEMBER_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M11.4 11.1q-.3-.6-.45-1.45-.15-.9-.25-3.15L5.05.55q-.6-.65-1.1-.5-.3.05-.55.3L.7 2.95l-.3.25q-.25.25-.3.5-.2.35.1.8l3 3.15h1.35q3.15 0 4.85 1.4 1.35 1.1 2 2.05m11 9.85q1.2-1.15 0-2.2l-3.35-3.55q-.3.15-1.6 0-1.25-.15-1.8-.35l-.55-.2q1.9 2.9 1.9 4.65 0 .35-.15 1.15-.2.75-.3 1-.05.05-.05.1l1.4 1.5q1.15 1.05 2.3 0l2.2-2.1z"
                    id="ObjMor041__Symbol_179_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M49.4 16.9q-2.65-6.5-8-10.4-3-2.75-7.05-4.5-9.45-3.95-18.95-.05Q6 5.8 2 15.2q-3.95 9.45-.05 18.9 2.2 5.45 6.25 9.1.55.5 1.15 1l.15.05q.25.2.4.35.15.15.3.25l.2.15.3.3q.05.05.2.1 2.7 2.15 6.1 3.6 9.45 3.95 18.9.05 2.9-1.15 5.25-2.85l6.85 7q.05-.25.3-.5l.3-.25 2.7-2.6q.25-.25.55-.3L45.2 42.5q2.5-2.9 4.15-6.75 3.9-9.35.05-18.85m-5.25-.05q3.3 8.05-.05 16.1-3.35 8-11.45 11.35-8.05 3.3-16.1-.1-3.1-1.3-5.55-3.3-2.55-2.8-4.2-6.6-3.3-8.05.1-16.1 3.3-8.05 11.45-11.35 8.05-3.25 16.1.1Q37.6 8.25 40 10.3q2.6 2.8 4.15 6.55z"
                    id="ObjMor041__Symbol_180_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M36.4 6.6q-2.95-3.1-7.2-4.85-8-3.4-16.1-.1Q5.05 4.95 1.7 13q-3.4 8-.05 16.1 2 4.9 5.75 8.1-2.55-2.8-4.2-6.6-3.3-8.05.1-16.1Q6.6 6.45 14.75 3.15q8.05-3.25 16.1.1Q34 4.55 36.4 6.6m5.2 32.2q2.5-2.9 4.15-6.75 3.9-9.35.05-18.85-2.65-6.5-8-10.4 4.05 3.55 6.25 8.9 3.85 9.55-.1 18.95-1.65 3.95-4.3 6.95-.1.05-.15.15l-.2.2 8.1 8.5.3-.3q.25-.25.55-.3L41.6 38.8M44.7 49l.3-.25-8.15-8.55-1.15.95q-2.35 1.65-5.15 2.8-9.5 3.85-18.95-.1-2.25-.95-4.25-2.25-.5-.35-1.05-.7.15.15.3.25l.2.15.3.3q.05.05.2.1 2.7 2.15 6.1 3.6 9.45 3.95 18.9.05 2.9-1.15 5.25-2.85l6.85 7q.05-.25.3-.5z"
                    id="ObjMor041__Symbol_181_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor041__Layer10_0_FILL" />
            <use xlinkHref="#ObjMor041__Layer10_0_MEMBER_0_FILL" />
            <use
                xlinkHref="#ObjMor041__Symbol_179_0_Layer0_0_FILL"
                transform="translate(113.55 283.9)"
                id="ObjMor041__col1n"
            />
            <use
                xlinkHref="#ObjMor041__Symbol_180_0_Layer0_0_FILL"
                transform="translate(65.65 234.4)"
                id="ObjMor041__col2n"
            />
            <use
                xlinkHref="#ObjMor041__Symbol_181_0_Layer0_0_FILL"
                transform="translate(69.25 238.1)"
                id="ObjMor041__col2d"
            />
        </g></g>
    )
}

export default ObjMor041
