import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00"
}

function LegSki002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M131.7 0q-.1 0-.2.05Q68.25 12.3 7 .15q-.25-.1-.5-.1L0 12.5h138.2L131.7 0z"
                    id="LegSki002__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <g id="LegSki002__col1n" transform="translate(123.4 310.3)">
                <use
                    xlinkHref="#LegSki002__Symbol_4_0_Layer0_0_FILL"
                    id="LegSki002__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default LegSki002
