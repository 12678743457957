import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF",
    "col2d": "#6D6D3B",
    "col2n": "#9A9956"
}

function ShiSpe044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.5 54.95v.15l.1-.05-.1-.1m-1.5-6.9l5.9-41.2.05-.05-1-1.5-.7-1q-1.4-2.15-2.95-4.2l.05-.05h-.05l-6.2 4.65q-.25.15-.45.35Q99.45 18.8 74.3 20.7l-5.85.2H66.3l-7.9-.25q-4.5-.35-8.85-1.05-.15-.05-.3-.05-17.85-3.05-33-12.5-1.85-1.15-3.65-2.4L6.4 0h-.05l.05.05h-.05q-1.5 2.05-2.9 4.2l-.7 1Q1.4 7.3.2 9.45l8.35 34.3-2.6 13.15q-.05 0-.05.05t-.05.1l-.25.25.1.1v.05L0 80.9q.1.05.25.05.25.1.5.15H.9q2.6.6 5.25 1.2l5 1.1q2.5.5 4.95.95.65.1 1.35.25.15 0 .3.05.15 0 .3.05 3.45.6 6.9 1.1 2.8.4 5.55.75.95.15 1.95.25 2.8.35 5.65.65.7.1 1.45.15l1.8.2h.25l5.65.45q4.5.3 9 .5.6 0 1.2.05.6 0 1.2.05h1l4.6.1H68.45V89l5.6-.1h.9q.65-.05 1.3-.05.5-.05 1-.05 4.1-.2 8.2-.5l5.65-.45h.25l1.8-.2q.75-.05 1.45-.15 3.05-.3 6.05-.7.8-.1 1.55-.2 2.7-.35 5.45-.7 3.2-.5 6.45-1.05.4-.05.85-.15.15-.05.3-.05.7-.15 1.35-.25 2.45-.45 4.95-.95l5-1.1q2.65-.6 5.25-1.2h.15q.25-.05.5-.15.15 0 .25-.05l-5.65-23.4h-.2v-.1l-1.85-9.4z"
                    id="ShiSpe044__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.6 57.3l.1.1v.05L0 80.9q.1.05.25.05.25.1.5.15H.9q2.6.6 5.25 1.2l5 1.1q2.5.5 4.95.95.65.1 1.35.25.15 0 .3.05.15 0 .3.05 3.45.6 6.9 1.1-6.35-1.8-11.4-6.95-7.8-8-7.8-19.3 0-1.3.1-2.5l-.25.25m113.65 21.55q-5.1 5.2-11.6 7.05 3.2-.5 6.45-1.05.25-.05.55-.1l.3-.05q.15-.05.3-.05.7-.15 1.35-.25 2.45-.45 4.95-.95l5-1.1q2.65-.6 5.25-1.2h.15q.25-.05.5-.15.15 0 .25-.05l-5.65-23.4h-.2l-.05.3q.15.55.25 1.1v.6q0 11.3-7.8 19.3m-9-21.4l.5-.3q1.8-1.1 4.55-3.05Q98.2 57.9 83 54q-8.55-2.15-16.45-6.75l-.1.1q-.1-.05-.15-.1-8 4.65-16.6 6.85-15.2 3.8-32.15 0 2.75 1.95 4.55 3.05l.5.3q5.25 2.95 14.2 2.95 7.2 0 14.5-1.15 2.25-.4 4.55-.85 2.65-.55 4.65-.95 5.1-1.05 5.45-1.05h.95q.35 0 5.5 1.05 1.95.4 4.6.95 2.15.45 4.35.8 7.4 1.2 14.7 1.2 8.95 0 14.2-2.95m19.7-52.15l-.7-1q-1.4-2.15-2.95-4.2l.05-.05h-.05l-6.2 4.65q-.25.15-.45.35-.05 0-.1.05-.35.25-.65.55-.4.3-.8.65-2.35 1.9-4.7 3.65-1.65 1.2-3.3 2.35-.5.3-1 .65-2.85 1.9-5.7 3.5-4.35 2.45-8.7 4.3-2.8 1.2-5.65 2.15-8.75 2.85-17.65 3.35-2.2.1-4.45.1-1.8-.05-3.6-.15-10.6-.75-21.45-4.85-2.7-1.05-5.45-2.25-5.45-2.5-11-5.85l-3.3-2.1q-.95-.65-1.85-1.25-.55-.4-1.1-.75-1.15-.8-2.35-1.65-.25-.15-.45-.35-.1-.05-.15-.1-1.85-1.15-3.65-2.4L6.4 0h-.05l.05.05h-.05q-1.5 2.05-2.9 4.2l-.7 1Q1.4 7.3.2 9.45q.25.15.5.35.15.15.35.3 4.85 3.7 7.3 8.7 3.1 7.4 2.05 16.45v.1q.05-.05.15 0h.15q.2 0 .3.2.05.05.05.1l2.05-4.7q.6-1.15 1.15-2.3 4.05-8.9 3.05-16.5-.6-2.25-1.8-4.25-.05-.05-.05-.1-.65-1.05-1.4-2Q15 6.65 16 7.55q.45.4.95.8l.7.6q1.75 1.5 3.55 2.9 38.45 30.2 75.6 10.4 1.2-.7 2.45-1.4 4.3-2.55 8.65-5.75 1.2-.9 2.45-1.85.15-.15.35-.3.6-.45 1.15-.9.4-.35.8-.65 1.1-.9 2.15-1.85 2.05-1.8 4.1-3.75h.05l.7-.7q-.15.2-.25.45-.2.25-.35.55-.85 1.2-1.4 2.55l-.1.3q-.55 1.15-.85 2.4-.2.8-.35 1.65-.5 2.6-.25 5.5l.1.9q.8 7.05 5.3 16.4l.05-.25v-.05-.1q0-.05.05-.1l-.05.25V35.7l.05.25q0-.2.1-.35.1-.2.3-.2.1-.05.2 0l.05-.55q-.05-.15-.05-.25 0-.4.05-.75 1.05-14.9 7.55-25.35.5-.8 1-1.55l.1-.1.05-.05-1-1.5z"
                    id="ShiSpe044__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M15.55 4q-.2.3-.35.55-3 5.3-5.55 11.1-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35-.1.2-.1.4-.1.3-.15.6-.25.8-.4 1.6-1.4 5.6-2.15 11.5-.05.3-.1.65 4-3.6 10-3.7h1.25q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15 2.6.95 4.95 2.85h.05q.2.2.5.4 0 .05.05.1.15.1.25.2v.05q.15.15.35.3.15-.1.3-.35 0-.05.05-.05l4.15-21.15q0-.05.05-.1.05-.15.1-.2h.05q0 .05.05.15v-.2q0-.05.05-.05v-.1Q27.6 19.4 24.5 12q-2.45-5-7.3-8.7-.2-.15-.35-.3-.25-.2-.5-.35l-.1.1q-.35.6-.7 1.25m147.2 33.5q-.2-.8-.4-1.65l-.1-.4q-.1-.25-.15-.55-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-3.05-6.9-6.7-13.1-.05-.05-.05-.1-.05-.05-.05-.1-.75-1.2-1.45-2.4l-.05.05-.1.1q-.5.75-1 1.55-6.5 10.45-7.55 25.35-.05.35-.05.75 0 .1.05.25l-.05.55h.1q.05 0 .05.05.05 0 .1.05t.1.2l.05.05V29L143 50.7v.05h.2l-.05-.1.05-.05q.1-.05.15-.15v-.05q-.1-.05-.1-.1.05.05.15.1l.2-.2v-.05l.3-.2h.05q2.55-2.25 5.45-3.3.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05H155q6 .1 10 3.7-.05-.35-.1-.65-.65-5-1.7-9.75-.25-.9-.45-1.75z"
                    id="ShiSpe044__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.35 4q-.2.3-.35.55 7.1 4.6 9.75 14.85 1.25 4.2 1.55 8.95 0 .05.05.1Q12.4 19.4 9.3 12 6.85 7 2 3.3q-.2-.15-.35-.3-.25-.2-.5-.35l-.1.1Q.7 3.35.35 4M131.75.15q-.5.75-1 1.55-6.5 10.45-7.55 25.35-.05.35-.05.75 0 .1.05.25l-.05.55h.1q.05 0 .05.05.05 0 .1.05v-.1q.05.05.05.1.1-.6.25-1.15v-.15q.1-.45.2-.85 3.05-16.9 9.55-23.95-.05-.05-.05-.1-.05-.05-.05-.1-.75-1.2-1.45-2.4l-.05.05-.1.1z"
                    id="ShiSpe044__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    d="M248.45 263.95l-.1-.05-.2.05q-.2 0-.3.2-.09.136-.1.35v.05l.05.05 3.25 16.8q.05.1.2.85.05.1.05.25v.05q.2.6.4 1.55.03.13.05.25l.05.15q.11.514.25 1.1v.1l-.05.05h.05v.15l.1.3q.187.229.35.25.267.009.5-.4l-4.25-21.7v-.05l-.05-.05q-.05-.15-.1-.2-.05-.05-.1-.05 0-.05-.05-.05m-111.6-.05q-.088-.022-.15-.05-.094.022-.15.05h-.05q.015.126 0 .25-.04-.091-.1-.2v.05q-.05.05-.1.2l-.05.05v.05l-4.3 21.65q.463.77.9.2.036-.167.05-.35.034-.034.05-.1h.05l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85l3.25-16.8.05-.05q0-.175-.05-.3-.02-.055-.05-.1-.1-.2-.3-.2z"
                    id="ShiSpe044__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe044__Symbol_70_0_Layer0_0_FILL"
                transform="translate(126.15 228.55)"
                id="ShiSpe044__col1n"
            />
            <use
                xlinkHref="#ShiSpe044__Symbol_71_0_Layer0_0_FILL"
                transform="translate(126.15 228.55)"
                id="ShiSpe044__col1d"
            />
            <use
                xlinkHref="#ShiSpe044__Symbol_72_0_Layer0_0_FILL"
                transform="translate(110 235.35)"
                id="ShiSpe044__col2n"
            />
            <use
                xlinkHref="#ShiSpe044__Symbol_73_0_Layer0_0_FILL"
                transform="translate(125.2 235.35)"
                id="ShiSpe044__col2d"
            />
            <use xlinkHref="#ShiSpe044__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe044
