import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#008300",
    "col2n": "#FFC400"
}

function PetPet013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M74.1 57.7h.6q6.8 0 10.55-2.85-1.95-4.5-5-6.5-1.35 1.25-3.85 1.25-1.15 0-2.3-.3-3.15 3.7 0 8.4m-62.75.45q.9-4.35-1.2-8.3-1.5.1-3.15.15-1.8 0-3.15.35Q.05 51.2 0 54.5q.7 4.4 6.95 3.95 2.35-.05 4.4-.3M33.7 31q-1.3.35-2.45.85-2.25 1-4.15 2.9-1 1-1.75 2.1-.4 6.1 5.05 7.75 1.1-2.05 2.5-3.25 1.05-.95 2.55-1.6-.7-4.95-1.75-8.75M20.2 46.85q-.45.35-.95.7-.85.5-1.95.9-1.65 4.7 2.8 7.8 1.15-.45 2.1-.95.4-.2.8-.45.35-.25.75-.5.9-.65 1.7-1.45.4-.4.75-.85.4-.5.75-1Q25.6 49 23.75 47q-.95-1.05-2-2.05-.2.25-.45.6-.15.15-.25.35-.2.3-.45.55l-.1.1-.3.3m40.3-8.65q-5.35 1.25-4.3 7.65.95.95 2.05 2.1.85.85 1.65 1.6 1.8 1.7 3.35 3 3.55-2.6 3.8-7.7-1.25-1.25-3.35-3.4-.75-.8-1.6-1.65-.8-.85-1.6-1.6m-8.05-5.8q-2.3-1.05-4.75-1.65-2.35-.55-4.85-.65-6.25 4.25 0 8.15 3.7 0 7.05 1.8 2.25-3.05 2.55-7.65m37.1 9.25q-.05-1.6-.2-3.2-.05-.2-.05-.35-4.5-2.45-8.4-1.5.35 1.4.5 2.8.1 1.1.1 2.25 0 .1.1.6 0 .25.05.6 0 .15.05.35 0 .05 1.9 2.1 1.85 2 6 .9 0-.5.05-1v-.25q-.05-.9-.05-1.55-.1-1.4-.05-1.75m-3.45-13.7q-.1-.15-1.2-2.15-.15-.25-.25-.5-5.35-1.6-10.45-2.15.15.3.3.65 0 .05.05.15.75 1.45 1.75 3.15.95 1.55 1.65 2.85 5.1 3.45 9.35.7-.55-1.35-1.2-2.7M89.5 6.2q.05-.35 0-.6-.05-2-.65-3Q87.25 0 82.3 0q-7.85 0-10.2 4.15-1 1.7-1 6.15 0 2.25.35 4.45 3.65 5.6 11.25 4.4.15-2.6 1-4.2.7-1.45 2.4-2.65 1.9-1.4 2.35-2 .95-1.35 1.05-3.55-.05-.05 0-.25v-.3z"
                    id="PetPet013__Symbol_85_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.35 33.7Q4.6 34.7.2 35.1q-.75 4.15 1.2 8.3 5.2-.55 8.75-1.9-3-3.7-2.8-7.8m7.9-11.35q-.3.4-.5.8-.6 1-.95 2.05-.1.2-.15.45-.2.65-.3 1.3-.55 1.7-1.55 3.2v.05q.85 1.9 2 3.35 1.35 1.7 3.2 2.75.9-1.25 1.65-2.7.85-1.9 1.4-3.05.1-.2.2-.35.1-.2.2-.35-3.6-3-5.05-7.75-.1.1-.15.25M32.9 23.5q-2.45-3.9 0-8.15-.8-.05-1.6-.05-3.85 0-7.05.85-.15 0-.25.05-.15 0-.25.05-1.2 4.4 1.75 8.75 2.9-1.3 7.4-1.5m46.75 7.95q-3.95-.6-7.9-2.9.1 1.45-.15 2.6-.2.9-.6 1.55l-.4.6-.3.3q1.45 3.55 5 6.5.55-.4 1.05-.9 1.45-1.5 2.35-3.55.5-1.4.8-3.05.1-.6.15-1.15M57.1 30.1q-3.4 3.05-3.8 7.7 1.5 1.25 2.75 2.1 4.2 2.8 8.1 3.05-1.1-4.2 0-8.4-.95-.25-1.9-.65-1.7-.75-3.4-2.1-.4-.35-1.75-1.7m-6.55-6.65q-3.55-3.35-6.7-5.1-.7-.35-1.35-.7-2.65 3.85-2.55 7.65.45.3.9.7.3.2.55.45.5.4 1.6 1.45 1.25 1.2 3.25 3.2.95-4.6 4.3-7.65M69.95 19q.4 1.05.75 2.1.1.35.25.75 4.2 1.8 8.4 1.5-.2-1.15-.4-2.25-.55-2.5-1.5-5l-.1-.2q-4.85.75-9.35-.7l.5.9q1 1.8 1.45 2.9m3.8-10.15q-.65-1.2-.9-2-.2-.5-.2-.8 0-.9.1-1.65-7.5-.85-11.25-4.4.4 2.6 1.3 5.1.5 1.5 1.45 3.3 5.2 2.15 10.45 2.15-.45-.75-.95-1.7z"
                    id="PetPet013__Symbol_35_0_Layer0_0_FILL"
                />
                <g id="PetPet013__Layer3_0_FILL">
                    <path
                        fill="red"
                        d="M101.2 329.85h-.25q.05.25 0 .6v.3q-.05.2 0 .25.05 0 .1-.1h.8q.8-.4 2.75-.95l1.45-.1q1.45 0 2.6.95.3 0 .3-.35 0-.1-.05-.2 0-.1-.1-.2-.1-.5-.95-.9-.4-.15-.75-.25 1.2-.7 1.2-1.5 0-.4-.3-.55h-.15q-.4 1-3.25 2-2 .65-2.85 1h-.55z"
                    />
                    <path d="M94.65 330.35q-.25-2.6-2.5-2.6-1.05 0-1.8.85-.7.75-.7 1.65 0 .75.35 1.35.55 1.2 2.15 1.2 1.05 0 1.8-.8.7-.75.7-1.65z" />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet013__Symbol_85_0_Layer0_0_FILL"
                transform="translate(11.45 324.25)"
                id="PetPet013__col1n"
            />
            <use
                xlinkHref="#PetPet013__Symbol_35_0_Layer0_0_FILL"
                transform="translate(21.4 339)"
                id="PetPet013__col2n"
            />
            <use xlinkHref="#PetPet013__Layer3_0_FILL" />
        </g></g>
    )
}

export default PetPet013
