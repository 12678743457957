import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666",
    "col1s": "#252525",
    "col2n": "#F3E200",
    "col2s": "#A38700"
}

function ShiSpe011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.6 11.5q-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-2.4 1.65-4.8 3.15v-.1q-1.3.8-2.55 1.55-6.1 3.45-12.45 5.8v.05l-5.6 14.7L75.9 63l-30-37.9L27.5 6.15q-1.15-.75-2.25-1.5L23.7 3.5q-1.45-1.05-2.8-2.1L19.05 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-2 3.65-3.8 7.5-2.05 4.45-3.8 9.25Q2.6 32 1.4 35.8.65 38.2 0 40.65l18.1 5.25.5 11.35q-.05 0-.1.05h-.1l.05.05v.15L15.6 69.25 11.2 83.3h-.1q-.05.25-.05.5-.15.7-.35 1.4-1.1 3.55-1.5 7.2l.25.85q4.2-1.5 18.1-3.9 13.8-2.4 15.25-1.6L74 94.9l3-5.9q1.1.05 1.95.05L80.9 89l4.3 5.65 46.1-8.25q13.65 2.75 14.5-2.05v-.25l-6-26.5v-.1V57.35l.05-.05-.05-.05-.9-11.05 18-5.8q.45-.1.95-.25.1-.05.2-.05V40q-.75-2.7-1.6-5.3-.65-2-1.3-3.9-.45-1.25-.85-2.45-1.8-4.8-3.85-9.25-1.8-3.95-3.85-7.6z"
                    id="ShiSpe011__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M89.95 22q2.9-6.8 1.75-9.8-1.2-3.05-1.1-3.2v-.05q-.45.15-.9.35-.25.05-.45.15-9.95 3.5-21.2 4.85-2.6 5.05-2.65 5.1l-.2.3h.05l-12 21.15-4.15-7.2-8.65-13.2L37.1 13q-8.85-1.7-17-5.05Q12.2 4.75 4.95 0v.1Q2.9 6.6 0 12q7.3-.45 13.15 1.35.05 0 .1.05h.05q3.6 1.1 6.65 3.1-2.5 2.05-4.85 5.8-1.75 2.9-1.9 5.1 7 2.1 13 5.7.9.55 1.95 1.2 1.1.65 2.4 1.35.8.4 1.75 1.05.65.45 1.4 1 1.75 1.4 4.9 5.8 3.15 4.35 8.2 8.5.65.5 1.35 1.05l.1.1.15.15q.25.15.5.35l-1.75 8.5h.05q-3.15 13.2 7.75-.45L73.6 43.3q1.85-3.3 7.65-8.9 5.8-5.6 8.7-12.4m-13.75-.1l-.15.05H76l.2-.05z"
                    id="ShiSpe011__Symbol_106_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M32.8 10h-.5q-2.25-.2-3.85-.9L30.1.65V.6q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-1.6 8.25q-.25-.2-.5-.35-1.3-.95-1.6-2.1-2.05-.1-5.6.35-5.6.7-13.7-1.15-.1-.05-.25-.05-.25-.1-.5-.15v-.05q-.05-.05-.75-.2-.9-.2-3.05-.55-3.35 7.8.65 15.9v-.05h.05l-.05.05q.2.3.35.65h.05q0 .05.15.15 2.95-2.6 7-3.35.15-.05.3-.05l1.85-.2q7.9-.3 12.4 4.45h.1l.1.1h.1q.05-.05.1-.05v.15q.05.1 0 .2 1.05-1 1.1-1.25.8-.85 1.95-2.4 2.1-2.8 5.05-8.7m50.45 38.5l-1.15.3q.1.4 2.25 2.25 2.55 2.2 3.8 2 1.25-.2 1.45-.2-3.6-1.35-6.35-4.35m-1.9-37.85l-.1.2q-3 6.25-4.45 13l.1.1.15.15q.25.15.5.35 1.7-6.4 4.35-12.8l-.55-1m86.35-7.2l-1.8.7q-.45.2-.95.4-.1.05-.15.1H164.65l-.3.1-.2.1q-.1 0-.2.05-1.05.3-2.1.5-4.9.95-9.75-.1-2.25-.5-4.5-.25-1.15.3-2.75 1.55-1.1.9-2.05 1.4L141.3.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l1.55 7.8q-.25.1-.45.15-.35.1-.7.15h-.05q-.7.35-1.25.35h-.8q-1 0-1.7-.2 3.35 5.2 6.75 10.25.1.2.15.4.05 0 .05.05 0-.05.05-.05.05.1.15.25l.35.45q.3.4.55.7.2.2.35.3l.65.8q.2-.15.35-.3v-.05l.1-.1q2.7-2.35 5.7-3.45.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05h1.25q.4 0 .85.05.85.05 1.7.15.05 0 .15.05.15 0 .3.05l1.95.45q.9.3 1.75.75 1.8.85 3.3 2.2 0-.05.05-.05V21l.05-.05q1.05-3.45 1.05-8-.1-4.1-1.15-9.5m-.65 16.5q-.05-.1-.1-.15l.25.2q-.1-.05-.15-.05z"
                    id="ShiSpe011__Symbol_107_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M134.45 8.9q-.55-3.75-.6-4.05-.4-1.15-2.2-1.9-.3-.1-.5-.15-.15-.35-.25-.7.05-.05.1-.15.25-.4.25-.75 0-1-1.15-1.2l-.6.1h-.35q-6.45.3-11.4 1.8Q109 4.5 100.4 5.7q-6.7.95-13.05.75l-13.9-.75q-3.5-.5-7.1-.5-3.1 0-6.3.3v.05l-.15-.05q-.65.05-1.35.15-8.3.8-17.55 2.25-9.1 1.4-19.1 1-8.4-.35-17.85-.5H3.7q-.65-.05-1.3-.05-.65.2-.65 1.1 0 .196.25 1.2.24 1.114.75 3.1L.9 17.5l-.1.05q-.05 0-.05.05-.05.3-.15.65-.15.5-.4.95-.1.1-.15.2v.3q0 .3-.05.6.25.3.55.55 6.7 6.25 15.8 2.8l.05-.35.7.1q1.7-.65 3.55-1.1h.05l.3-.1q.4-.1 1.85-.4.1 0 .25-.05l.4.05h.05q7.445.807 27.25-.65 19.854-1.405 21.1-1.55 1.299-.098 1.5-.15 4.65.45 9.45.85.9.1 1.8.15 6.4.5 10.7.55 4.9.05 6.45 0 .8-.05 1.05-.05 9.2-.6 17.05-2.85 3.55-1 7.75 1.75h.05q.05.05.15.1 1.65 1.1 4.6 1.45 1 .1 2.2.15v.25q.2-.25.3-.75.05-.15.05-1.55v-.6q-.2-1.8-1.2-2.65h-.05l-.1-.1-.05-.1q-.85-1.5-1.6-2.75l1.75-3.15q.15-.05.25 0h.05q.4-.4.4-1.25m-2.75 9.85v.05h-.25q.1-.05.25-.05M59.6 7.1q.1 0 .2.05-.7-.1-1.45-.15.65 0 1.25.1z"
                    id="ShiSpe011__Symbol_108_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M38.25.1q-.1.05-.2.05-.05 0-.1.05-.25.05-.25.1-.15.05-.3.15-.1 0-.2.05Q32 2.4 24.6 4.25 16.85 6.2 7.95 7.2l-7.9 1v.05q0 .05-.05.1 1.8-.1 5.45-.1 12.9-1.4 20.3-3.2Q32 3.45 37.55 1.4h.05l.6-.2q0-.05.1-.05.15-.1.3-.15h.05q-.05.05.2-.05V.9Q38.7.55 38.6.2l-.1-.2-.25.1z"
                    id="ShiSpe011__Symbol_109_0_Layer0_0_FILL"
                />
                <g id="ShiSpe011__Layer11_0_FILL">
                    <path
                        fill="#37438C"
                        d="M256.25 309.95v-.05q-.15 0-.25.05h.25z"
                    />
                    <path
                        fill="#BD0021"
                        d="M212.15 256.55l-.2.05h.05l.15-.05z"
                    />
                    <path fill="#999" d="M184.6 296.65h-.15l.15.05v-.05z" />
                    <path d="M190.45 289.95q-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .7.4 1.25.1.2.25.35.65.65 1.6.65.95 0 1.6-.65.15-.15.3-.35.35-.55.35-1.25v-.3q-.041-.308-.15-.6-.176-.376-.5-.7m2.35-5.9q-.057-.427-.25-.8-.163-.263-.4-.5-.65-.65-1.6-.65-.95 0-1.6.65l-.15.15q-.407.5-.45 1.15-.05.15-.05.3 0 .7.4 1.25.1.2.25.35.65.65 1.6.65.95 0 1.6-.65.15-.15.3-.35.35-.55.35-1.25v-.3m9-30.9q-.313-.125-.65-.2-2.7-.7-5.7 1-1.45.8-2.95 2.2-1.25 1.15-2.55 2.7-.1.1-.2.25-.15-.15-.25-.2-2.85-3.45-5.5-4.95-3.5-2-6.6-.7-.45.15-.85.4-.1 0-.15.05-.258.14-.5.3-3.886 2.436-2.85 7.9.5 2.9 1.45 5.45.45 1.25 1 2.4l.1.2q.7 1.55 1.65 2.95 3.05 4.55 5.15-1.15.35-.9.8-1.8.15-.35.3-.65.583-1.121 1.3-2.15.112-.132.2-.25 0-.05.05-.05 1.15-1.65 2.35-2.85 1.1-1.1 2.05-2.1.65.3.85.5.55.45 1.05.95l.35.35q1.15 1.1 2.05 2.3.17.195.3.4.196.22.35.45.05 0 .05.05.85 1.15 1.5 2.4.2.3.35.65.4.9.75 1.8 2.1 5.7 5.15 1.15.95-1.4 1.65-2.95l.1-.2q.55-1.15 1-2.4.95-2.55 1.45-5.45 1.1-5.8-3.35-8.2-.05-.05-.15-.05-.4-.25-.85-.4-.113-.05-.25-.1z" />
                    <path
                        fill="#E30000"
                        d="M192.25 249.2v.05h.05v-.1q-.002.05-.05.05z"
                    />
                    <path
                        fill="#FFF"
                        d="M191.7 263.7l-.35-.35q-.5-.5-1.05-.95-.2-.2-.85-.5-.95 1-2.05 2.1-1.2 1.2-2.35 2.85-.05 0-.05.05-.088.118-.2.25.135.53.25 1.1v.05l4.15 7.2 4.55-8.1q.169-.51.3-1-.13-.205-.3-.4-.9-1.2-2.05-2.3m9.45-10.75q.337.075.65.2.67-1.24 2.2-4.25-1.735.213-3.5.35l-5.9.2v-.05q-.1.028-.2 0-.648.046-1.3.05h-.6l-1.45-.05v.1H191v-.1l-2.55-.1v.2h-.05q.011-.034 0-.1.041-.038.05-.1l-3.95-.15q-5.85-.412-11.45-1.5l.05.05 2.8 6.3q.242-.16.5-.3.05-.05.15-.05.4-.25.85-.4 3.1-1.3 6.6.7 2.65 1.5 5.5 4.95.1.05.25.2.1-.15.2-.25 1.3-1.55 2.55-2.7 1.5-1.4 2.95-2.2 3-1.7 5.7-1m-11.85 5.25v-.05q.1 0 .15.05h-.15m.7 0q.05-.05.15-.05v.05H190z"
                    />
                    <path
                        fill="#7B4A42"
                        d="M188.45 249.3q-.009.062-.05.1.011.066 0 .1h.05v-.2m2.55-.05v.25h.05v-.1q-.048-.097-.05-.15z"
                    />
                    <path
                        fill="#6F3F35"
                        d="M190.15 258.15q-.1 0-.15.05h.15v-.05m-.85 0v.05h.15q-.05-.05-.15-.05z"
                    />
                    <path
                        fill="#8F8F8F"
                        d="M274.5 283.85l-.25-.2q.05.05.1.15.05 0 .15.05m-20.1 7.3l-.15.05h.05l.35-.05h-.25M122.4 322v.05q.2-.1.4-.15v-.05l-.2-.85q-.1.55-.2 1m2.1-10.1l.6.1q-.3-.25-.55-.55 0 .2-.05.45z"
                    />
                    <path
                        fill="#8A8A8A"
                        d="M122.8 321.9q.001-.05.05-.05l-.25-.85.2.85v.05m26.45-73.85h-.05q-.05-.05-.1-.05l.1.1.05-.05z"
                    />
                    <path
                        fill="#FC0"
                        d="M141.65 314.55l-.7-.1-.05.35q.35-.15.75-.25z"
                    />
                    <path
                        fill="#755004"
                        d="M126.55 301.6l-1.1 6.3v.7l50.8-.55q-.08-.132.05-.3 5.157.132 13.35.15l68.5-.7q.054-.013.1-.05h.3v-5.85q-.1-.05-.25 0h-.1q-.45-.05-.95-.1l-.45-.05h-.1l-.1-.05-34.3.2q-3.65 0-5.45.1.05-.05.05-.1l-12.95.05q-.15-.143-.55-.25-1.1-.2-1.85-.35h-3.4q-1.5.2-2.05.35-.496.117-.7.3l-6.05.05q.05.073.1.15-5.636-.082-13.05-.1l-48.85.15v-.05h-1z"
                    />
                    <path
                        fill="#252525"
                        d="M148.15 311.95h-.05l-22.95-2.55q-.15.5-.4.95l23.3 2.6h.05q7.4.8 11.1 1.3 5.15.65 10.95.6 3.815-.07 6.95-.3 5.947-.366 9.45-1.25.917-.23 1.85-.45.215-.051.4-.1.175-.042.3-.1h.05q.122-.02.25-.05h-.05q1.17-.227 1.15-.25 1.296-.329 2.65-.65 2.323-.56 4.8-1.1-4.9-.5-9.65-1.05l-.1.1-.1.1q-1.57-.039-8.8 1.1-1.127.12-2.2.2-10.016 1.08-15.3 1.35-5.85.25-11.7-.2-.8-.1-1.95-.25z"
                    />
                </g>
                <g id="ShiSpe011__Layer11_1_FILL">
                    <path
                        fill="#CCC"
                        d="M188.2 300.1q-1.75-1.55-4.05-1.85-.6-.1-1.25-.1h-.05q-3.1 0-5.25 1.95-.7.65-1.2 1.4-1 1.5-1 3.4 0 1.75.85 3.15.353.6.85 1.15.25.23.5.45.8.7 1.7 1.2 1.6.8 3.6.8 1.1 0 2.1-.25.05-.05.15-.05 1.45-.4 2.7-1.4.05-.05.15-.1 0-.05.05-.05l.15-.15.1-.1q.194-.171.35-.35.614-.605 1-1.3.75-1.35.75-3 0-1.95-1.05-3.45-.063-.104-.15-.2-.394-.63-1-1.15z"
                    />
                    <path
                        fill="#BE8530"
                        d="M226.55 243.6h-.05q-.041.194-.1.35-2.75 6.35-5.75 12.7-3.55 7.35-7.45 14.7-1.8 3.3-3.65 6.6-2.5 4.35-5.15 8.7-3.1 5.1-6.4 10.2-1.5 2.3-3 4.55l9.55-.05q1.35-2.05 2.65-4.1.07-.11.15-.25.386-.586.75-1.2l5.45-8.65q.147-.25.3-.5.255-.417.5-.85l3.8-6.4q11.268-19.751 19.95-41.1-4.39 2.405-9 4.3-1.272.523-2.55 1z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe011__Symbol_105_0_Layer0_0_FILL"
                transform="translate(113.4 228.6)"
                id="ShiSpe011__col1n"
            />
            <use
                xlinkHref="#ShiSpe011__Symbol_106_0_Layer0_0_FILL"
                transform="translate(135.95 234.65)"
                id="ShiSpe011__col1d"
            />
            <use
                xlinkHref="#ShiSpe011__Symbol_107_0_Layer0_0_FILL"
                transform="translate(107.3 263.85)"
                id="ShiSpe011__col1s"
            />
            <use
                xlinkHref="#ShiSpe011__Symbol_108_0_Layer0_0_FILL"
                transform="translate(124.55 291.15)"
                id="ShiSpe011__col2n"
            />
            <use
                xlinkHref="#ShiSpe011__Symbol_109_0_Layer0_0_FILL"
                transform="translate(216.85 293.05)"
                id="ShiSpe011__col2s"
            />
            <use xlinkHref="#ShiSpe011__Layer11_0_FILL" />
            <use xlinkHref="#ShiSpe011__Layer11_1_FILL" />
        </g></g>
    )
}

export default ShiSpe011
