import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M206.55 225.5q0-1.1-.25-2.2-.1-.3-.15-.6-.263-.703-.6-1.4-.978-2.338-3.55-3.35-2.35-1.129-5.6-1.55-.035.003-.1 0-3.26-.467-6.65.1h.1q-.674.059-1.15.15h.05q-.53.13-1.1.25-4.82 1.114-7 3.75-1.01 1.445-1.8 3.8-1.179 3.696.35 6.5.49.766 1.3 1.35 2.02 1.393 5.3.3.799-.347 1.8-.95 2.717-1.39 6.3-1.2h.55q2.2.05 3.95.35 6.15.9 8-3.9.15-.4.2-.8.05-.3.05-.6z"
                    id="FacMou026__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M0 2.15V5.7h5.85V.35q-.25.05-.4.1H5.4Q2.85.95.3 2q-.15.05-.3.15M12.65.2h-.1q-2.9-.4-5.9.05V5.7h6.3V.25h-.2Q12.7.2 12.65.2m6.95 5.5V2.3q-.15-.1-.25-.15-1.45-.65-2.8-1.05h.05L14.5.55h-.05q-.35-.15-.7-.2V5.7h5.85z"
                    id="FacMou026__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou026__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou026__Symbol_51_0_Layer0_0_FILL"
                transform="translate(183.55 217.6)"
                id="FacMou026__col1n"
            />
        </g></g>
    )
}

export default FacMou026
