import { AssetSvgProps } from "../../../shared/assets"

function ShiMot060({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M180.5 257.85q-12.868 18.254 0 35.75h22.6q8.5-17.505 0-35.75h-22.6z"
                    id="ShiMot060__Layer5_0_FILL"
                />
                <g id="ShiMot060__Layer5_1_FILL">
                    <path
                        fill="#FF8000"
                        d="M225.7 293.6v-35.75h-22.6v35.75h22.6z"
                    />
                    <path
                        fill="#008C45"
                        d="M159.2 257.85v35.75h21.3v-35.75h-21.3z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot060__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot060__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot060
