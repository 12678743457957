import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#999999",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.15 2.1q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4z"
                    id="AccGla009__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M53.7 4.3q-1.55-2.35-4.5-2.1-13.35.25-25.55-.5-.85-.1-1.75-.15-.45 0-.85-.05-4.05-.35-5.5-.45-3.15-.3-6.9-.9Q7.6.05 6.8 0 2.8-.05 1 3.3.2 5.1 0 7.45h.05l.1 4.8H.1L.15 38.1q.15 1.1.6 1.95 1.25 1.75 7.9 3 17.85 1.9 36.65 1.7.95-.1 1.95-.15.65-.05 1.35-.1h.1q3.35-.15 4.95-1.3 1.45-1.1 1.4-3.05l.25-25.45v-.1L55 9.7q-.2-3.4-1.3-5.4m54.5-4q-3.75.55-6.85.9-1.45.1-5.5.45-.4.05-.8.05-.9.05-1.75.1-12.1.8-25.35.5-2.9-.25-4.45 2.1-1.1 2.05-1.25 5.4l-.35 4.9v.1l.3 25.4q-.1 2 1.35 3.1 1.6 1.15 4.9 1.3h.1q.7.05 1.35.1.95.05 1.95.1 18.6.2 36.35-1.65 6.6-1.25 7.8-3.05.45-.8.6-1.9l.05-25.85h-.05l.15-4.75h.05q-.25-2.35-1.05-4.2-1.8-3.3-5.7-3.25-.85.05-1.85.15z"
                    id="AccGla009__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M56.65 14.2q.15-6.35-1.3-9.25-1.4-2.9-6.25-2.85-12.95.25-24.8-.5-1.15-.1-2.5-.2-4-.4-5.6-.45Q13.05.75 9.95.2 6.6-.3 4.35.45q-.6.2-1.15.6-.1.1-.3.25Q.25 3.95 0 8.8v29.95h.05q.1 1.45.6 2.4.65 1.15 1.75 1.7.05.05.1.05.2.1.4.15Q11.65 46 34.7 46.4q7.45.2 13.95-.3h.1q3.85-.2 5.75-1.55 2.05-1.55 1.9-4.4l.25-25.95m-3.2-8.4q1.1 1.95 1.25 5.2l.3 4.7v.1l-.25 24.4q.05 1.9-1.35 2.95-1.55 1.1-4.8 1.25h-.1l-1.3.1-1.9.1q-18.2.2-35.55-1.6-6.45-1.2-7.65-2.9-.45-.8-.6-1.85l-.05-24.8h.05l-.1-4.6h-.05q.2-2.25 1-4 1.75-3.2 5.6-3.15.8.05 1.8.15 3.65.55 6.7.85 1.4.1 5.35.45.4.05.8.05.9.05 1.7.1 11.85.75 24.8.5 2.85-.25 4.35 2m64.55 3q-.25-4.85-2.9-7.5-.2-.15-.3-.25-.55-.4-1.15-.6-2.25-.75-5.6-.25-3.1.55-6.25.75-1.6.05-5.6.45-1.35.1-2.5.2-11.85.75-24.8.5-4.85-.05-6.25 2.85-1.45 2.9-1.3 9.25l.25 25.95q-.15 2.85 1.9 4.4 1.9 1.35 5.75 1.55h.1q6.5.5 13.95.3 23.05-.4 31.8-3.35.2-.05.4-.15.05 0 .1-.05 1.1-.55 1.75-1.7.5-.95.6-2.4h.05V8.8m-2.35-3.95q.8 1.75 1 4h-.05l-.1 4.6h.05l-.05 24.8q-.15 1.05-.6 1.85-1.2 1.7-7.65 2.9-17.35 1.8-35.55 1.6l-1.9-.1-1.3-.1h-.1q-3.25-.15-4.8-1.25-1.4-1.05-1.35-2.95L63 15.8v-.1l.3-4.7q.15-3.25 1.25-5.2 1.5-2.25 4.35-2 12.95.25 24.8-.5.8-.05 1.7-.1.4 0 .8-.05 3.95-.35 5.35-.45 3.05-.3 6.7-.85 1-.1 1.8-.15 3.85-.05 5.6 3.15z"
                    id="AccGla009__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 2.75L38.35 6.8l-.1-4.6L0 0v2.75m91.6 1.6l.3 4.7h7.95l.3-4.7V4.2H91.6q0 .1.05.15h-.05m100.1-1.6V0l-38.2 2.2h-.05l-.1 4.6h.05l1.4-.15h.1l36.8-3.9z"
                    id="AccGla009__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla009__Symbol_100_0_Layer0_0_FILL"
                transform="translate(191.3 153.55)"
                id="AccGla009__col2n"
            />
            <use
                xlinkHref="#AccGla009__Symbol_79_0_Layer0_0_FILL"
                transform="translate(134 141.85)"
                id="AccGla009__col2l"
            />
            <use
                xlinkHref="#AccGla009__Symbol_32_0_Layer0_0_FILL"
                transform="translate(133.5 141.2)"
                id="AccGla009__col1n"
            />
            <use
                xlinkHref="#AccGla009__Symbol_16_0_Layer0_0_FILL"
                transform="translate(96.65 147.85)"
                id="AccGla009__col1d"
            />
        </g></g>
    )
}

export default AccGla009
