import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1n": "#E4B670",
    "col1s": "#957032"
}

function AccMor001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M66.6 32.25q-.35-1.55-1.9-3.55v-.25q.3-6.25-3.85-13.15-5.1-8.75-13.65-12.6-15.85-6.4-30.35 2.25-4.55 2.55-7.95 6.9Q2 20.7 1.65 29.4q-.45.4-.8 1.15-.45.9-.65 1.7-.8 2.9 1.35 4.15h.1v.1q.05.35.1.75.05.2.05.5.05.45.15.95.05.35.05.65h.05l.15 1.25q.05.25.15.6l.1.55q.05.4.15.85l.2.9h-.05l.25.95h.05Q4.1 48.6 5.8 52.3q.85 1.9 3.2 4.75V57l.05.05.9 1.25H10q.25.2.5.5l.15.15.45.45q1.7 1.6 3.8 3.1 2.25 1.6 5.3 2.7 5.75 2.05 14.2 2.2 8.95.15 14.8-2.2 2.55-1.05 4.55-2.55 2.8-2.2 4.8-4.45.1-.15.3-.3l.95-1.25h.05q.15-.2.25-.35 1.45-2 2.3-4 1-2.35 1.65-4.95.35-1.4.65-2.9.05-.25.05-.45l.2-1h-.05q.05-.55.15-1.05.05-.5.1-.9v-.25l.1-.7.05-1.15q0-.4.05-.75v-1.75-.2-.7q1.6-1.3 1.25-3.3m-6.85-5.7q-1.85-.55-4.3-1.05-9.2-1.9-22.3-1.9-13.1 0-22.35 1.9-2.35.45-4.1 1h-.1q.3-.8.6-2.05.3-1.25.7-2 .3-.8.55-1.3Q12.6 11.7 20.7 7.25q9.5-4.3 18.4-3.15 2.9.65 5.9 1.95 13.15 5.85 14.75 20.5z"
                    id="AccMor001__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22.9 30.25q-3.95.5-7.25-.8 3.5 3.55 7.25.8m.55-3.85q-4 .5-7.35-.8 3.55 3.6 7.35.8m-8.8 10.85q3.5 3.6 7.3.8-3.95.55-7.3-.8m.5-3.85q3.5 3.6 7.25.85-3.95.5-7.25-.85m1.5-11.8q3.5 3.6 7.3.85-4 .5-7.3-.85m-4.35 3.35q-4-.05-7.1-1.9 2.95 4.1 7.1 1.9m23.05 6.65q-3.5.95-7.35-.15 3.4 3.35 7.35.15m0-3.95q-3.5.9-7.35-.15 3.4 3.35 7.35.15M28 35.55q3.4 3.35 7.35.2-3.5.9-7.35-.2m0 4q.5.5 1.05.85 2.5 1.7 5.3 0 .5-.3 1-.7-3.5.9-7.35-.15M4.15 26.8q2.95 4.05 7.1 1.9-4-.1-7.1-1.9m3.25 5.45l.9 1.25h.05q1.3 0 2.7-.8-1.95 0-3.65-.45m7.05-14.9q-3.95 0-7.1-1.8 3 4.05 7.1 1.8M6.3 19.2q2.95 4.05 7.15 1.95-4.05-.1-7.15-1.95m-5.2-.5l.25.95h.05q1.05-.1 2.25-.7-1.3-.05-2.5-.25H1.1m3.6-3.5q-2.3-.05-4.3-.65l.15 1.25q1.9.6 4.15-.6m30.65 8.35q-3.5.9-7.35-.15 3.4 3.3 7.35.15M21.3 19.8q1.55-.05 3.1-1.2-3.95.5-7.3-.8 1.75 1.75 3.6 2h.6m6.7-.35q.25.25.5.45 3.15 2.6 6.8-.2 0-.05.05-.1-.2.05-.35.1-2.4.55-4.95.2-1-.15-2.05-.45m24.3 5.5q4.15 2.2 7.1-1.9-3.1 1.85-7.1 1.9M49.8 37.3q-3.35 1.35-7.3.8 3.75 2.8 7.3-.8m-1.5-11.6Q45 27 41 26.45q3.8 2.85 7.3-.75m.5 3.8q-3.35 1.35-7.3.8 3.75 2.8 7.3-.8m-6.75 4.8q3.75 2.8 7.25-.8-3.3 1.3-7.25.8m18.4-7.5q-3.1 1.8-7.1 1.9 4.15 2.15 7.1-1.9m-2.3 5.05q-1.7.45-3.65.5 1.4.75 2.7.75l.95-1.25m.15-12.65q-3.1 1.85-7.15 1.95 4.15 2.1 7.15-1.95m-10.5 2.5q-3.3 1.35-7.3.8 3.8 2.8 7.3-.8m-7-2.5q3.1 1.75 6.1-.9.25-.2.45-.4-3.4 1.3-7.35.8.4.3.8.5m9.35-1.85l.4.2q3.85 1.85 6.7-2-2.6 1.5-5.75 1.75-.65.05-1.35.05m13.55-5.9V10.65q-2.15.85-4.35 1.55Q34 20.2 0 11.6v.1q.05.35.1.75Q33.95 21 59.25 13q2.25-.7 4.45-1.55m-2.65-4l.2-.1q1.85-1.2.3-2.5Q59.7 3.7 55.1 2.4q-.15-.05-.35-.05-.1-.05-.2-.05-22.95-5-49.7.8-.2 1.8-.2 3.5Q6.5 5.85 9.2 5.2 18.3 3 31.2 3q12.85 0 21.95 2.2 4.2 1 6.5 2.25l.6.4q.45-.2.8-.4M63.3 18.2h-.05q-1.25.2-2.65.25 1.35.65 2.5.75l.2-1m.3-2.9l.05-1.15q-1.9.5-4.05.55 2.15 1.15 4 .6z"
                    id="AccMor001__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M55.6 4.85l-.6-.4Q52.7 3.2 48.5 2.2 39.4 0 26.55 0q-12.9 0-22 2.2-2.7.65-4.55 1.4v.85Q0 5 .05 5.5q1.85.6 4.45 1.1 9.25 1.9 22.35 1.9 13.1 0 22.3-1.9 4.15-.8 6.45-1.75z"
                    id="AccMor001__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor001__Symbol_100_0_Layer0_0_FILL"
                transform="translate(244.5 277.75)"
                id="AccMor001__col1n"
            />
            <use
                xlinkHref="#AccMor001__Symbol_60_0_Layer0_0_FILL"
                transform="translate(246.15 302.55)"
                id="AccMor001__col1d"
            />
            <use
                xlinkHref="#AccMor001__Symbol_34_0_Layer0_0_FILL"
                transform="translate(250.8 305.55)"
                id="AccMor001__col1s"
            />
        </g></g>
    )
}

export default AccMor001
