import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacEye025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.4 1.6q.25-.3.25-.65 0-.4-.3-.65-.3-.3-.65-.3-.4 0-.7.3l-9.15 9.6L1.7.3Q1.4 0 1.05 0q-.4 0-.7.3-.3.25-.3.65 0 .35.3.65l9.2 9.65-9.3 9.7q-.25.3-.25.7 0 .35.3.65.3.25.7.25.35 0 .65-.3l9.2-9.6 9.2 9.6q.3.3.7.3.35 0 .65-.25.3-.3.3-.65 0-.4-.25-.7l-9.25-9.7 9.2-9.65M76.3 0q-.4 0-.7.3l-9.15 9.6L57.3.3Q57 0 56.65 0q-.4 0-.7.3-.3.25-.3.65 0 .35.3.65l9.2 9.65-9.3 9.7q-.25.3-.25.7 0 .35.3.65.3.25.7.25.35 0 .65-.3l9.2-9.6 9.2 9.6q.3.3.7.3.35 0 .65-.25.3-.3.3-.65 0-.4-.25-.7l-9.25-9.7L77 1.6q.25-.3.25-.65 0-.4-.3-.65-.3-.3-.65-.3z"
                    id="FacEye025__Symbol_69_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye025__Symbol_69_0_Layer0_0_FILL"
                transform="translate(153.85 153.6)"
                id="FacEye025__col1n"
            />
        </g></g>
    )
}

export default FacEye025
