import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#016564",
    "col2n": "#02E3E3"
}

function PlaAbs007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M385 385V0H0v385h385z"
                    id="PlaAbs007__Symbol_7_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M81.7 0H70v385h11.7V0M35.05 0h-11.7v385h11.7V0M11.7 0H0v385h11.7V0m46.65 0H46.7v385h11.65V0m70.05 0h-11.7v385h11.7V0m-23.35 0h-11.7v385h11.7V0m46.65 0h-11.65v385h11.65V0m93.35 0H233.4v385h11.65V0m-46.7 0H186.7v385h11.65V0M175 0h-11.6v385H175V0m46.7 0h-11.65v385h11.65V0m70 0h-11.6v385h11.6V0m-23.3 0h-11.65v385h11.65V0m46.65 0H303.4v385h11.65V0m46.7 0H350.1v385h11.65V0M338.4 0h-11.65v385h11.65V0z"
                    id="PlaAbs007__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaAbs007__Symbol_7_0_Layer0_0_FILL"
                id="PlaAbs007__col1n"
            />
            <use
                xlinkHref="#PlaAbs007__Symbol_11_0_Layer0_0_FILL"
                transform="translate(11.65)"
                id="PlaAbs007__col2n"
            />
        </g></g>
    )
}

export default PlaAbs007
