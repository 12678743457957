import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400",
    "col2n": "#FFC400"
}

function ObjMor044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.6 5.6q.3-1.15 2-5.1V0q-6.95 2.75-11.7 7.2l-.9.9q-4.5 4.55-6.4 10.35-.15.75-.35 1.05-.25-.25-3.9-5.45 1.45 6.3.75 9.75-.95 4.55-2.6 9.85-1 2.55-2.35 5-2.2 3.7-3.25 5.75Q-3 55.75 4.7 61.25q-.05-.5.05-1 1 .6 3.05 1.45 2.9 1.2 4.95 1.85.65.25 5.15 2.2 4.45 1.9 5.75 1.9h.3l-.2.3q5-.25 8.55-4.45 1-1.25 1.85-2.6 2.45-3.9 3.75-8.7 1.55-5.8 1.85-13.85.15-7.65-1.9-16.95-.2 5.75-.85 8.25-.55 1.95-1.05 2.55-.15.2-.3.25.1-2.5-.45-4.95-.6-2.75-1.7-5.6-1.8-3.9-2.45-5.45Q30 13.95 30 11q0-3.3.6-5.4m-4.35 7.55v.05l-.05-.05h.05m-8.1 16.35l-.15-.05.1-.1q.05.1.05.15z"
                    id="ObjMor044__FL_0_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M24.55 0h-.1q-2.5 1.15-4.95 5.1-.7 1.05-1.4 2.4-.8 1.5-1.4 3-.3.7-.55 1.4-2.45 5.6-1.85 11.95.05.1.05.15l-.15-.05q-1-1.2-2.4-3.35-1.45-2.15-2.65-3.4.05 2.45.15 4.8.1 3.55-.55 6.05-.8 2.95-4 7.6-3.2 4.65-4 7.55Q-.4 47.5.25 50q.25 1.2 1.25 2.95.2-.25.45-.5.8.65 2.9 1.55 1.75.75 3.95 1.6 1 .55 3.3 2.55 2.6 1.75 5.85 1.75 1.85 0 3-.2-.05.05-.05.15 1.85-.55 3.6-2.25 2.95-2.85 4.75-8 1.8-5.15 2-9.35.1-4.2-.45-7.5-2.2 7.15-3.85 7.5 1.05-3 1.65-8.15.6-5.15-2.9-11.75t-3.5-9.75q0-1.3.25-2.9l-.05-.05h.05q.2-1.2.5-2.6.7-3.15 1.6-5.05M15.9 30q.05.4.05 2.45 0 3-.7 5.3v.05q-.05.1-.05.2-.35.1-1.1.4-1.3.45-1.95.45-1.25 0-1.45-.75v-.2q0-.05.05-.05l.9-.1.7-1.4q2.7-5.15 2.7-9.1.8 2.2.85 2.75m7.55 16.95q1.15-1.25 1.5-2.95.1.8.25 1.4.2.95.4 1.4.2.55.2 1.95 0 1.85-1.5 3.65-1.1 1.25-2.1 1.65-.2-.45-1.05-.7-1.6-.55-1.95-.75.2-.4 2.3-3.25.85-1.15 1.35-1.85.1-.15.25-.25.15-.15.35-.3z"
                    id="ObjMor044__Symbol_186_0_Layer0_0_FILL"
                />
                <g id="ObjMor044__Layer1_0_FILL">
                    <path
                        fill="#853F00"
                        d="M290.9 234.8q0-.1.05-.1.05-.25.2-.45l.1-.2q.15-.4.3-.75l.3-.7q.042-.17.05-.35.25-.41.25-1.25 0-1.85-1.6-3.55-2.4-2.5-7.15-2.5-2.95 0-4.15 1.7-.7.95-.7 2.1v.05q-.05.05-.05.15-.15.5-.25 1.15-.05.05-.05.1-.5 1.65-1.15 4-.65 2.3-1.55 5.85-.9 3.5-.9 3.95 0 .95.2 1.7.15.7.15 1.4 0 2.1-1.55 4.25-1.85 2.6-2.5 5.15-.15.6-.1 2.3.05 1.55-.35 2.35-.3.55-1.05 2.45-.15.4-.25.7-.3.5-.65 1.05-1.65 2.35-1.65 3.4 0 .7.65 2.15.6 1.5.6 3.05 0 2.65-1.95 4.85-2.05 2.35-3.05 5.15-.25 6.15-.55 7.3-.15.5-2.95 4.65-1.25 1.85-3.05 4.45-.1.2-.2.45-3.45 8.2-4 9.8-.45 1.3-1.55 5.7-.15.45-.25.95.8.65 1.65 1.1.45.2.95.35 3.35 1.6 6 1.25 1.7-.2 2.45-.75.7-.55.75-.6.05-.15.55-.7.45-.6.55-.75.1-.2.15-.25.5-1 .4-2.55-.1-1 1-2.5.6-.9.9-1.55h-.05q-.6 0-1.35-.75-4.35-2.95-4.2-9v-1q.05-.85.2-1.6 1.5-8.7 14.7-9l.6-2.4q.05-.15.1-.25-.05-.15 0-.25l-.05-.45q0-3.3 3.4-8.55 3.35-5.2 3.35-12.6v-.85q.058-1.719.3-2.85.4-2.1 1.7-4.05.8-1.2.65-3.65-.15-3.75-.15-3.85.4-2.6 1.95-5.1 1.5-3.15 2.15-5.5.2-.55.5-1.2.4-.8.7-1.5.3-.55.5-1.1z"
                    />
                    <path
                        fill="#4D4D4D"
                        d="M286.3 207.3l-.9.1q-2.8.9-3.6 3.95-.1.45-.05 1 0 .6-.1.9l.15.05q-.1.05-.1 0-.9.2-1.5.45-1.9 1.2-1.2 4.5.2 1.55.05 2.15v.05l.05.15h-.05v.05q-2 .6-2.9 1.85-.25.45-.4 1-.25.9-.2 1.8-.05.1 0 .25v.25q.1.6.3 1.25.1.3.3.65.55 1.2 1.65 2.2l.4.3q0-.05.05-.1.1-.65.25-1.15 0-.1.05-.15v-.05q0-1.15.7-2.1 1.2-1.7 4.15-1.7 4.75 0 7.15 2.5 1.6 1.7 1.6 3.55 0 .84-.25 1.25-.008.18-.05.35l-.3.7q-.15.35-.3.75.05 0 .1.05.95.2 1.55-.2.3-.15.55-.45.2-.15.35-.3.75-.8.8-1.15.7-1.25.9-1.9.05-.3.05-.7.1-.9-.2-2.25-.4-1.85 0-3 .1-.25.2-.45.3-.7.6-1.05.35-.55.5-.75.15-.2 2.15-2.3 1.4-1.45-.05-2.65-.3-.25-.65-.45-.2.15-.35.3-1.6 1.4-3.55 1.4-.5 0-1-.05v-.05h-.05q-1.15-.25-2-1-.4-.35-.7-.8-.45-.6-.7-1.35-.35-.9-.5-1.95-.05-.35-.05-.7v-.15q.1-2.85.7-4.8-1.6-.25-3.6-.05z"
                    />
                </g>
                <g id="ObjMor044__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#AD5300"
                        d="M256.6 310.95q-1.75 1.9-2.25 3.1-1.15 2.45-2.15 4.3.45.2.95.35 3.35 1.6 6 1.25 2.2-1.2 2.2-4.65 0-2.3-.4-4-.5-2.25-1.55-2.55-.6.15-1.4.8-.65.55-1.4 1.4m30.65-79.9q-.15-.25-1.05-.85-.55-.2-.9-.35h-1.35q-1.15.25-1.9.75-.7.8-1.45 2.25-1.4 2.85-2.3 6.8-.35 1.35-.35 5.3-.05 3.3-.95 5.2-3.25 6.55-3.05 10.5-.377 1.373-1.05 2.95-.512 1.35-1.25 2.85-.95 1.95-.95 4.7 0 1.7.5 3.3.5 1.6.5 2.05 0 .85-2.65 3.65-2.1 2.15-2.55 4.15.05.2.15.4.55 1.45 2.75 1.45t3.45-1.9q.55-3.1 2.6-7.25 3.2-6.45 3.75-9.8v-6.3q.35-1.1 1.35-3.3 1.1-2.5 1.4-3.25V249q.95-3.4 1.8-5.15l1.2-2.3.45-1.3q.75-2.05 1.2-3.6.95-3.05.95-4.15 0-1.05-.3-1.45z"
                    />
                    <path
                        fill="#2F2F2F"
                        d="M292.1 227.3q-.8-1.05-2.3-1.85-2.8-1.45-6.6-1.45-5.65 0-5.65 3.8 0 .6.1 1.05.15.85.6 1.25.1-.65.25-1.15 0-.1.05-.15 1.55-4.85 10.45-.75 3.55 1.7 2.85 4.55l-.3.7q-.15.35-.3.75.05 0 .1.05.25-.25.4-.5 1.3-2 1.3-3.5 0-1.55-.9-2.75l-.05-.05m-4.4-8.55q-.3-.3-.85-.5-.6-.2-.8-.25-.2-.1-.85-.1-.65-.05-1.15-.05-.5-.05-2.45.2-1.95.25-2.55 2.35v.05l.05.15q1.35-.4 3.25-.6 4.5-.35 6.9 1l-.05-.1q-.35-.65-.65-1.2-.3-.4-.85-.95m1.55-5.75q-.05-.35-.05-.7-2.7-1.1-5.25.85.65.1 1.75.1 1.05 0 1.8.3t1.5 1.55q.7 1.25 1.3 1.6.6.3.85.4-.4-.35-.7-.8-.45-.6-.7-1.35-.35-.9-.5-1.95z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor044__FL_0_0_Layer0_0_FILL"
                transform="translate(270.85 164.05)"
                id="ObjMor044__col1n"
            />
            <use
                xlinkHref="#ObjMor044__Symbol_186_0_Layer0_0_FILL"
                transform="translate(274.65 169.55)"
                id="ObjMor044__col2n"
            />
            <use xlinkHref="#ObjMor044__Layer1_0_FILL" />
            <use xlinkHref="#ObjMor044__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMor044
