import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D9D9D9",
    "col2n": "#999999"
}

function ObjToy004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M52.6 17q-.15-.25-.25-.45Q50.4 11.9 46.5 8q-3.6-3.6-7.85-5.6l-.3-.15h-.05l-.55-.2-.1-.1Q32.85 0 27.25 0h-.4q-.65 0-1.25.05-3.05.15-5.75.95-2.3.6-4.45 1.6-.4.2-.75.4-.45.25-.85.45Q10.7 5.25 7.95 8q-2.25 2.25-3.9 4.8-.05.15-.2.35Q0 19.4 0 27.25q0 2.2.35 4.3-.05.15 0 .3.05.3.15.75 1 5.55 4.35 10.25.45.5.8 1.05.7.9 1.65 1.85.3.4.65.75 3.15 3.15 6.85 5.1 1.15.55 2.3 1.05 3.9 1.5 8.2 1.8.95 0 1.95.05 1.55 0 3.1-.2 7.6-.75 13.5-5.45l1-.8q.35-.35.7-.65.1-.1.2-.15.3-.35.7-.7l.05-.05q3.8-3.8 5.75-8.3.3-.6.55-1.2v-.05q0-.1.05-.15 1.65-4.45 1.65-9.55 0-1.15-.05-2.2-.05-1.2-.3-2.3-.5-3.05-1.55-5.75z"
                    id="ObjToy004__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M43.55 43.2q.95 1.9 1.9 4.05.3-.35.7-.7-.9-2-1.85-3.9l.05-.05q-.35-.75-.4-.75-2.8-5.25-6.1-8.8-3.85-4-8.35-5.85-.05-.05-.2-.1-.15-5.1.7-9.4 14.95 4.1 21.95 20.5.3-.6.55-1.2v-.05q-7.2-16.15-22.35-20.1 0-.2.1-.3 1.1-4.8 3.75-8.9 13.95 5.75 20.15 17.4-.05-1.2-.3-2.3-6.4-10.5-19.3-15.9 1.65-2.4 3.75-4.4-.15-.15-.25-.2H38l-.55-.2-.1-.1Q35.35 4 33.8 6.3q-.15.2-.25.4l-.1.1q-3 4.3-4.15 9.6-.05.2-.1.5 0 .1-.05.15-1.05 4.7-.7 10.2-.1-.1-.25.05-4.5 2.5-9.45 3.8-3.7-17.65 7.8-31.1-.65 0-1.25.05-11.2 13.6-7.5 31.3-4.65 1.05-9.7 1-2.95-12.85 7-29.75-.4.2-.75.4-.45.25-.85.45-9.1 16.3-6.35 28.9-3.45-.1-7.1-.8-.05.15 0 .3.05.3.15.75 3.1.5 6.05.65 6.15.05 9.9-.55l-.1-.05 2.65-.55q5.2-1.4 10-4l.15-.15.1-.1.2.2q4.1 1.75 7.7 5.35-11.5 12.25-32.3 9.45.45.5.8 1.05 20.6 2.45 32.1-9.8 3.05 3.35 5.65 8.15Q31.45 52.8 14.5 51.6q1.15.55 2.3 1.05 15.7.4 26.75-9.45z"
                    id="ObjToy004__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy004__Symbol_98_0_Layer0_0_FILL"
                transform="translate(239.05 250.55)"
                id="ObjToy004__col1n"
            />
            <use
                xlinkHref="#ObjToy004__Symbol_19_0_Layer0_0_FILL"
                transform="translate(239.35 250.55)"
                id="ObjToy004__col2n"
            />
        </g></g>
    )
}

export default ObjToy004
