import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#045B95",
    "col1n": "#006AC9",
    "col2d": "#F4C402",
    "col2n": "#FFFF00"
}

function AccHat010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M193.5 93.9q-.531-10.57-2.5-19.95-3.226-15.338-10.3-27.5-13.15-21.95-36.75-33.7-47.6-25.5-92 0-24.65 13.3-37.9 34.4-6.495 12.757-9.85 26.8-.986 4.12-1.7 8.35-.05.3-.1.65l-.1.6Q-.1 98.8 0 110.2q0 .65.05 1.3-.05.25 0 .5-.25 35.15 6.05 68.85 0 .6.05 1.15.15 2.45 1 3.5 1.05 1.2 3.45 1.2 1.85 0 2.45-2.35.35-1.35.35-4.3 0-2.55-.95-10-.6-4.35-1.05-7.35-.1-1.05-.25-2.1-2.3-21.1-.5-42.2.3-4.85 1.75-9.05.85-2.55 2.15-4.8Q21.7 91.85 41.7 87q60.2-12.2 120.35 2 13.55 4 15.2 21.85l.75 10.7q-.05.25.1 6.25.3 11.2-2.4 23.4-2.75 12.15-4.3 19.65-1.1 5.8-1.1 8.95 0 3.85 1 6.05.95 2.1 2.55 2.1 2.05 0 2.35-4.4.6-9 1.45-13.2.85-4.2 5.35-19.75 1.9-6.55 3.7-12.85 7.75-17.15 6.85-33.8.1-4.65-.05-10.05z"
                    id="AccHat010__Symbol_229_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M185.3 85.45q1.05 7 1.4 11.8.3 4.8.3 12.1 0 8.7-.85 14.55-.85 5.8-3.15 15.65-2.35 9.8-5.8 29.9 0 1.1.3 1.85.05-.5.15-.95.4-1.95 1.6-6.4 1.35-5.1 3.75-13.35 1.9-6.55 3.7-12.85 7.75-17.15 6.85-33.8.1-4.65-.05-10.05-.55-10.55-2.5-19.95-3.25-15.35-10.3-27.5-13.15-21.95-36.75-33.7-47.6-25.5-92 0-3.55 1.9-6.85 4-19.7 12.35-31.05 30.4-6.5 12.75-9.85 26.8-1 4.1-1.7 8.35-.05.3-.1.65l-.1.6Q1.2 90.7.6 97q-.65 7.15-.6 13.2 0 .65.05 1.3-.05.25 0 .5v3.3q.05 30.05 4.9 59.1-.4-3.55-1.15-22.7-.8-20-.8-26.75 0-5.6 1-16l.3-3.1Q5.4 96.4 7.75 87.6q12.15-50.55 52.2-68.75.9-.5 1.85-.9 16.1-7 35.05-7 24.85 0 44.7 11.95 6.25 3.75 12 8.7 2.75 2.4 5.45 5.1 20.15 20.15 25.95 47.25-.7-5.5.35 1.5z"
                    id="AccHat010__Symbol_513_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M63.4 14.95q0-2.7-2.25-5.25-2.6-2.85-6.75-3.75-2.35-.25-3.9-.55-2.95-.55-4.25-1.85-.85-.8-1.6-1.15-.55-.25-2.4-.85-2.2-.9-3.55-1.2-.3-.1-1.8-.25-1.55-.2-1.55-.05-1.3.05-3.15.9-2 .95-5.05 1.05-3.65-.1-5.3-.05-2.7.05-5.5 1.05-1.35.45-2.7 1.55Q11.5 6.2 11 6.5q-1.4.85-6.35 2.55-1.85.6-2.45 1.2-.25.25-1.25 2.05-.4.65-.7 1.95-.25 1.2-.25 1.9 0 2.8 2.15 3.85.75.4 5.3 1.35 1.95.45 4.35-.05 1.4-.3 2.8-.5 1.4-1.9 5.45-7h.25q0 3.2.05 3.45.15 1.5.65 2.6 1.6-.2 3.25-.3 1.3-.45 3.25-1 5.2-1.5 6.55-1.5 1.4 0 3.05 1.65 1.7 1.6 4.05 1.6 1.3 0 3.05-.75 1.75-.75 2.35-.75.05 0 7.15 2.05 2.85.8 5 1.35 2.1-.7 3.25-2.4.7-1 1-2.2.05-.3.15-.6.3-.95.3-2.05z"
                    id="AccHat010__Symbol_56_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M6.55 17.4Q3.284 16.876 2 16.1.77 15.378.3 14.05q-.028.105-.05.2-.25 1.2-.25 1.9 0 2.8 2.15 3.85.75.4 5.3 1.35 1.95.45 4.35-.05 1.4-.3 2.8-.5 1.359-1.844 5.2-6.7-.404.132-.95.5-1.415.936-3.25 1.55-1.83.668-3.8 1.2-1.982.574-5.25.05M63 12.75q-.142 1.913-1.35 3.25-1.4 1.5-3.55 1.5-2.3 0-4.8-1.6t-4.6-1.6q-1.35 0-3.8.5-2.5.5-2.55.5-1.55 0-3.15-.9-1.556-.875-4.2-.9-.472.035-1.65.1-2.752.151-4.55.55-1.45.296-3.2 1.3-1.752 1.012-3.4.95-1.494-.063-1.9-2.35.002 2.96.05 3.2.15 1.5.65 2.6 1.416.442 3.25-.3 1.3-.45 3.25-1 5.2-1.5 6.55-1.5 1.4 0 3.05 1.65 1.7 1.6 4.05 1.6 1.3 0 3.05-.75 1.75-.75 2.35-.75.05 0 7.15 2.05 2.85.8 5 1.35 2.1-.7 3.25-2.4.7-1 1-2.2.05-.3.15-.6.3-.95.3-2.05 0-1.104-.4-2.2z"
                    id="AccHat010__Symbol_511_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat010__Symbol_229_0_Layer0_0_FILL"
                transform="translate(95.2 51.25)"
                id="AccHat010__col1n"
            />
            <use
                xlinkHref="#AccHat010__Symbol_513_0_Layer0_0_FILL"
                transform="translate(95.2 51.25)"
                id="AccHat010__col1d"
            />
            <use
                xlinkHref="#AccHat010__Symbol_56_0_Layer0_0_FILL"
                transform="translate(159.2 39.7)"
                id="AccHat010__col2n"
            />
            <use
                xlinkHref="#AccHat010__Symbol_511_0_Layer0_0_FILL"
                transform="translate(159.2 39.7)"
                id="AccHat010__col2d"
            />
        </g></g>
    )
}

export default AccHat010
