import { AssetSvgProps } from "../../../shared/assets"

function ShiMot081({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M180.5 257.85q-9.244 18.26 0 35.75h22.6q6.472-17.113 0-35.75h-22.6z"
                    id="ShiMot081__Layer5_0_FILL"
                />
                <path
                    fill="red"
                    d="M225.7 293.6v-35.75h-22.6v35.75h22.6m-66.5-35.75v35.75h21.3v-35.75h-21.3z"
                    id="ShiMot081__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot081__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot081__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot081
