import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M66.6 15.05V15q.05.05.15.1 1.8 1.4 4.85 4.15 3.5 2.25 3.95-1.5-.5-1.2 1.2-2.15 2.35-3.35-1.8-5.05-4.2-1.65-8-3.95-3.85-2.3-9-4.7-10.75-3.8-15.75 0-.25.2-.45.45-.4.15-.75.3-.5.4-1.15.5h-.8q-.7-.1-1.2-.5l-.7-.3q-.25-.25-.5-.45-5-3.8-15.7 0-5.2 2.4-9.1 4.25Q7.95 8 3.4 10.5q-4.55 2.45-3 3.95 1.3 1.3.85 2.9.6 5.25 5.5 2.75.2-.4.35-.65 3.15-2.9 5.05-4.35l.1-.1.05.05q26.65-11.35 54.3 0z"
                    id="BeaMus015__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus015__Symbol_32_0_Layer0_0_FILL"
                transform="translate(153.7 199.45)"
                id="BeaMus015__col1n"
            />
        </g></g>
    )
}

export default BeaMus015
