import { AssetSvgProps } from "../../../shared/assets"

function ShiMot076({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot076__Layer5_0_FILL">
                    <path
                        fill="#0000A4"
                        d="M194.1 280.8l-24.9 13h56.5v-13h-31.6z"
                    />
                    <path
                        fill="red"
                        d="M169.05 258l25.65 13.1h31V258h-56.65z"
                    />
                    <path d="M159.2 263.6v24.1l22.4-11.6-22.4-12.5z" />
                    <path
                        fill="#008C45"
                        d="M159.2 258v5.6l22.4 12.5-22.4 11.6v6.1h10l24.9-13h31.6v-9.7h-31L169.05 258h-9.85z"
                    />
                </g>
                <g id="ShiMot076__Layer5_1_FILL">
                    <path
                        fill="#FFF"
                        d="M172.45 293.8l22.15-11.9h31.1v-2.4h-31.95l-26.6 14.3h5.3m-5.3-35.8l26.6 14.3h31.95v-2.35h-31.1L172.45 258h-5.3z"
                    />
                    <path
                        fill="#FFCE00"
                        d="M159.2 262.35v2.85l19.85 10.75-19.85 10.7v2.9l25.15-13.6-25.15-13.6z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot076__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot076__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot076
