import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g
                    id="FacMou012__Symbol_37_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path
                        fillOpacity={0.4}
                        d="M3.7 2.85q-.55.5-1.95 2.9-1.8 3-1.75 4.35 0 1.85.7 2.7.5.8 2.25 1.55.15.05.3 0 .15-.1.25-.25.05-.15 0-.3-.1-.15-.25-.2-1.45-.6-1.9-1.25l-.05-.05q-.5-.7-.5-2.2.05-1.25 1.65-3.95 1.3-2.3 1.8-2.75.1-.15.1-.3t-.1-.25q-.15-.15-.3-.15t-.25.15m29.35 15.5q-.2 0-.3.1-.15.1-.15.3 0 .15.1.25.95 1.2 3.5 1.2.15 0 .3-.1.1-.15.1-.3t-.1-.25q-.15-.15-.3-.15-2.1 0-2.9-.9-.1-.15-.25-.15m-9.4-.45q-.15 0-.3.1-1.25.9-3.2.9l-.75-.05q-.15 0-.3.1t-.15.25q0 .15.1.3t.25.15l.75.05h.05q2.3 0 3.75-1.05.15-.15.15-.3t-.05-.3q-.15-.15-.3-.15m17.45.2q.15-.05.25-.2.1-.15.05-.3t-.2-.25q-.15-.1-.3 0-.5.1-1.75.1-.05 0-.25.05-.25-.05-.6-.05h-.05q-1.05-.05-2.2-.3l.05.05q-1.8-.65-2.2-.75-1.48-.712-2.4-1.1h.05q-1.85-.85-3.3-.85-1.55 0-4.9 1.25h.05q-3.25 1.15-5.85 1.15-.75 0-3.2-.35h-.3q-.15 0-.25.15-.15.1-.15.25t.15.3q.1.1.25.1h.2q2.55.35 3.3.35 2.7 0 6.1-1.2 3.15-1.2 4.6-1.2 1.25 0 2.95.8.95.35 2.45 1.1 0 .05.05.05.4.1 2.15.7h.05q1.2.25 2.35.3.35 0 .5.05h.2q.15-.05.2-.05 1.35 0 1.95-.15M53.45 4.7q-.15.05-.2.25-.1.15 0 .3.05.05.8 2.25.7 2.2.7 3.15 0 1.7-.8 2.6H54q-.8.7-2.65 1.15-.15.05-.25.2-.1.15-.05.3t.2.25q.15.1.3.05 2.15-.55 3-1.4 1-1.05 1-3.15 0-1.05-.75-3.4Q54 5 53.95 4.9q-.05-.15-.2-.2-.2-.1-.3 0z"
                    />
                    <path d="M51.75 6.1q.05-.4-.55-1.2-.55-.8-.7-1.4-.05-.15-.2-.25-.15-.1-.3-.05t-.25.2q-.1.15-.05.3.15.7.85 1.65.35.55.4.75-.05.4-.6 1-.5.5-.7 1.2l-.2-.1q-1.35-.75-2.75-1 .2-.65.65-1.5v-.05q.3-.85.3-1.35.05-.4-.85-1.45-.75-.85-.9-1.35-.1-.2-.2-.25-.15-.1-.3 0-.2.05-.25.2-.1.1 0 .3.2.65 1.05 1.65v-.05q.6.75.65.95 0 .4-.25 1.05-.5 1-.65 1.7-.5-.05-1-.05-1.25 0-2.6.2.15-.4.15-.7v-.1q-.2-.7-.25-.9-.05-.2-.15-.25-.15-.1-.3-.1-.2.05-.25.2-.1.1-.1.3.05.2.25.85 0 .3-.25.85-3.25.55-6.75 2.2-2.05.95-3.55 1.55v.05q-2.1.75-2.95.75-1.75 0-3.1-.45-.1-.05-.15-.05l-.1-.05q-.35-.1-.65-.25-1.75-1.25-2.55-1.65Q19.6 8.4 17.9 7.8h-.1v-.1q.05-.85 1-1.4.15-.1.2-.25.05-.15-.05-.3t-.25-.15q-.15-.1-.3 0-1.3.75-1.4 1.95-.6-.2-1.15-.3-1.05-.3-2.2-.4-.2-.45-.25-.55v-.05q-.15-.3-.15-.75 0-.35.15-.5.6-.35.9-.6.1-.1.1-.25.3-2.1.4-2.35.1-.15.05-.3-.1-.2-.2-.25-.15-.1-.3 0-.2.05-.25.2-.15.3-.45 2.5-.25.15-.65.35v.05q-.55.3-.55 1.15 0 .6.15 1 0 .1.1.3-.55-.05-1.15-.05-1.6 0-2.85.15-.25-.6-.25-1.05.05-.35.8-.8 1.05-.8.75-1.55-.05-.9 0-1.4.05-.85.7-1.4.15-.1.15-.25t-.1-.3Q10.65 0 10.5 0t-.3.1q-.95.75-1 1.95-.05.55 0 1.55 0 .05.05.1 0 .3-.45.65-1.2.75-1.15 1.5 0 .5.25 1.15-4.4.45-4.25 2.5-.05 1.5 1.7 2.45.05 0 .15.05l1.65.4h.2l5.7-.35q-.25.45-.25.95 0 1.3.9 1.65.15.05.3 0 .15-.1.25-.25.05-.15 0-.3-.1-.15-.25-.2-.4-.2-.4-.9 0-.5.35-.85.05-.1.15-.2h.3q2.6-.2 4.25-.2h.65q.25 0 .55.05l-.15.2h.05q-.5.7-.5 1.4 0 .55.45 1 .1.1.25.1t.3-.1q.1-.15.1-.3t-.1-.25q-.2-.2-.2-.45 0-.5.35-.95v-.05l.35-.55q.1 0 .25-.05.85.1 1.45.3.05 0 .05.05l2.05.6h.1q1.55.55 3.55.55 1.05 0 3.45-.9h.05q1.95-.7 3.6-1 .4-.1 1.15-.15.45 1.5.45 2.25 0 .15.15.3.1.1.25.1t.3-.1q.1-.15.1-.3 0-.8-.4-2.3.65-.05 1.6-.1 0 .15.1.3.7.9.6 2.6 0 .15.1.3.1.1.3.1.15 0 .3-.1.1-.1.1-.25.1-1.85-.65-2.95 2.1.1 4.9.9h.05q3.35.85 3.9.85 3.35 0 3.2-2 .1-1.05-1.45-2.1.15-.65.6-1.1.85-.95.8-1.55z" />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou012__Symbol_37_0_Layer0_0_FILL"
                transform="translate(165.1 212.9)"
                id="FacMou012__col1n"
            />
        </g></g>
    )
}

export default FacMou012
