import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00AFAF",
    "col1n": "#00DEDE"
}

function LegSki016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M150.9 12.8q2.05-1.65 0-3.7L140 0l-1.2.3q-1.25.25-2.55.55-.45.1-.95.25-10.7 2.4-21.45 3.95-2.85.4-5.75.75-2.7.35-5.35.65-1.45.1-2.85.25-12.45 1.15-24.95 1.15-10.2 0-20.4-.75-1.55-.15-3.05-.25-2.8-.25-5.65-.55-3.05-.35-6.05-.75Q26.75 3.85 13.7.9q-.05-.05-.15-.05-.8-.15-1.6-.35L9.85 0 .7 9.05q-1.4 1.8 0 3.1.55.25 1.5.3h.6q1-.05 2.4-.3-1.05 1.95-.85 3.35.85 6.75 30.15 1.75.3-.05.65-.1Q38.4 16.6 42 15.9l-.3.85q0 .45.25.8.8 1.25 4.65.15.65-.2 1.4-.45.55-.25 1.25-.5.3-.1.6-.15 1.3-.45 3.2-.3 3.15.15 7.8 1.7 1.4.45 2.9 1.05 3.2 1.25 7.5 1.5.2 0 .45.05 4.3.2 9.65-.55h.05q4.75-.7 7.2-1.45 2.4-.8 5.45-1.85 3.05-1.1 5.9-.5 2.8.55 3.5.7 1.8.35 3.3.6l1.2.2q6.15.9 6.1-.45 0-.3-.25-.65l-.35-.7q3.05.55 5.85 1l.5.1q17.15 2.55 23.5 1.3 4.65-.95 4.65-3.05-.05-.9-.85-1.95.45.05.85.05.25-.05.55-.05 1.45-.1 2.4-.55z"
                    id="LegSki016__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.4 11.85L10.9.6q-.05-.05-.15-.05Q9.95.4 9.15.2q-.65 1.05-1.4 2.2Q4.95 6.5 0 12.15q1-.05 2.4-.3M37 5.3l-.1.3h.05l-.1.4-.05.05q-.05.15-.1.35-.05.05-.05.15-.15.5-.3 1.05-1.35 3.95-4 9.25 3.25-.55 6.85-1.25L43.05 6Q40 5.65 37 5.25v.05m8.2 11.65q.55-.25 1.25-.5.3-.1.6-.15 1.3-.45 3.2-.3.1-3.85.8-6.85.3-1.25.7-2.35-1.55-.15-3.05-.25l-3.5 10.4m65.85-12.2q-2.85.4-5.75.75l5.35 10.15q3.05.55 5.85 1-3.45-5.4-4.8-9.5-.4-1.25-.65-2.4m-7.1 12.5l1.2.2-5.2-11.3q-1.45.1-2.85.25.55 1.1 1.1 2.4 1.4 3.35 2.45 7.85 1.8.35 3.3.6m40.3-4.2h.05q.45.05.85.05.25-.05.55-.05-1.35-1.6-2.55-3.1-3.45-4.2-5.6-7.5-.05-.1-.15-.2-.8-1.2-1.4-2.25-1.25.25-2.55.55-.45.1-.95.25l11.45 11.85.3.4z"
                    id="LegSki016__Symbol_45_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki016__Symbol_82_0_Layer0_0_FILL"
                transform="translate(117.55 307.2)"
                id="LegSki016__col1n"
            />
            <use
                xlinkHref="#LegSki016__Symbol_45_0_Layer0_0_FILL"
                transform="translate(120.35 307.5)"
                id="LegSki016__col1d"
            />
        </g></g>
    )
}

export default LegSki016
