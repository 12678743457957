import { AssetSvgProps } from "../../../shared/assets"

function ShiMot073({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 281.9v5.95h66.5v-5.95h-66.5m0-18.25v6.15h66.5v-6.15h-66.5z"
                    id="ShiMot073__Layer5_1_FILL"
                />
                <g id="ShiMot073__Layer5_0_FILL">
                    <path
                        fill="red"
                        d="M159.2 258v8.4h66.5V258h-66.5m0 26.6v9.2h66.5v-9.2h-66.5z"
                    />
                    <path
                        fill="#0000A4"
                        d="M159.2 266.4v18.2h66.5v-18.2h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot073__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot073__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot073
