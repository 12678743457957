import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00B600",
    "col1l": "#17FF17",
    "col1n": "#00DE00"
}

function LegSki026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M116.75 3.3l.25-.05q-.15 0-.3-.05 0 .05.05.1M144 2.95q-.55-1.45-1.1-2.95l-2.5.6q-.85.15-1.65.35-1 .25-2 .45-1.15.25-2.35.5l-.9.2q-.3.05-.6.15l-4.5.9q-.5.05-1 .2-1.75.3-3.45.6-.85.1-1.65.25-.15 0-.25.05H122l-3.2.5-1.8.3q-.35 0-.65.1-.6.05-1.25.15l-3.5.5q-1 .1-2 .25-.55.05-1.05.1-2.95.35-5.85.65-.4 0-.75.05-.7.05-1.35.15-.4 0-.8.05l-2.8.2q-1.2.1-2.45.2-.1-.05-.2 0-1 .05-1.95.1-1.35.05-2.7.15h-.55l-5.85.2h-1.35q-.65 0-1.35.05h-5.5q-1.3 0-2.55-.05H70.9q-.35-.05-.7-.05-.9 0-1.85-.05l-2.1-.1q-.65 0-1.25-.05-1.75-.05-3.45-.15-2.05-.15-4.05-.25-.6-.1-1.15-.1-.7-.1-1.35-.15h-.35q-2.15-.2-4.25-.4-.45-.05-.85-.1-2.4-.25-4.8-.55-.55-.05-1.05-.1-.5-.1-1.05-.15-1.7-.2-3.35-.45h-.1q-.35-.05-.65-.1-1.9-.3-3.75-.55-.7-.15-1.35-.25-.75-.1-1.45-.2-1.65-.3-3.25-.6Q28 3.4 27.3 3.3q-1.5-.3-2.95-.6-.7-.15-1.35-.25-1.5-.3-2.95-.6-1-.25-1.95-.45-.55-.1-1.05-.2-.5-.15-1-.25-.3-.05-.55-.1-1.7-.4-3.35-.8-.1 0-.2.05h-.35q-.15.4-.3.85Q5.6 16.25 0 27.5h1.65q2.05.4 4.6 2.55 2.35 2 4.6 2.15h1.3q2.65-.6 4.5-1.05.35-.05.7-.05.4 0 .8.05 2 .1 3.35.95 1.6 1 3.2 1h.65q.25-.05.5-.05h.05q.05-.05.1-.05.55-.15 1.2-.45.25-.1.5-.25 1.7-.9 2.95-.9H31q1.75.15 3.7 1.65 1.6 1.25 3.25 1.55.5.1 1 .1 1 0 3.15-.9t4.75-.9q1.9 0 3.75 1.05l.4.1q2.9 1.2 4.4 1.4h3.5q.55-.15 1.15-.35.15-.05.4-.15l.7-.3q2.7-1.2 3.7-1.2 2.75 0 4.4 1.6.4.4.9.7 1.5.85 3.85.85 2.55 0 6.3-1.95 1.15-.6 2.1-1 2-.95 2.7-.95.3 0 .6.05.7.05 1.35.3.75.3 1.4.85 1.45 1.2 5 1.2 4.15 0 6.6-1.3 1.65-.9 3.25-1.2l.6-.1q.45-.05.9-.05 4.1 0 5.45 1.2.4.35.9.65 1.05.55 2.45.55 2.55 0 5.2-1.05.5-.4 1.3-.9.05-.05.15-.05.25-.15.55-.3 1.75-.95 3.05-.95 1 0 2.2.7l.2.1q.35.2.8.35.2.05.45.1.55.1 1.2.1.9 0 1.8-.25 0-.05.05-.05.9-.4 1.7-1.15.65-.65 1.65-1 1.15-.45 2.8-.45 1.2 0 2.4.6 1.15.6 2.65.6 1 0 5.2-1.85.6-.45 2.1-1.5.2-.15.4-.25.7-.15 1.5-.2 2.2-.2 3.7.05v-.05l.6.1q-5.45-9.6-11.25-24.6z"
                    id="LegSki026__Symbol_92_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M31 4.95v-.9q-.05-.3-.25-.1-.2.15-2.35 4.95-2.2 4.8-2.85 4.8-.65-.05-.95-2.85-.3-2.3-.3-5.2-.1 0-.1-.1.05-4-2.6 2.45-2.45 5.85-3.5 6.6-.25-1.2-.75-3.45-1.7-7.1-1.95-7.5-.95 1.25-3.05 5.75-1.75 3.85-2.55 4.95-.1-1.2-.5-3.8-1.2-6.9-1.6-8-.85.55-1.5 2.15-.4.9-2.05 3.6-.55.75-1.6 1.8L3.6.6q-.2.35-.45.95 0 .4-.7 2.2-1 2.6-1.35 4-.4 1.4-.6 3.65-.4 4.45-.5 4.75v.7h.7q2.95-5.1 5.1-6.8.85.2 1.55 5.85.6 5.15 1.55 5.15.05 0 2.6-5.15 2.5-5.15 2.95-4.65.45.5 1.55 4.95 1.05 4.45 1.65 4.45.75 0 2.55-3.4 1.8-3.4 2.4-2.9.55.45 3.1 7.5 1.8-2.65 3.6-8.65Q31 7.65 31 4.95M22.75 6.7v-.25q.05.1.1.25h-.1M49.3 3.15q-.8 2.9-2.9 7.6-1.45 3.05-2.75 3.1-1.35.05-1.95-1.25-.65-1.3-2.35-5.2-1.15-3.95-1.3-4.5-.4 4.65 0 6.25.45 1.55.55 2.3.85 2.35 1.95 5.1 2.65 6.5 3.4 6.5.85 0 3.65-9.5 1.7-6 1.7-8.35V3.15M90.5 0q-.1 6.7-.7 10.45-2.75-3.6-6.3-9.1.15 3-.6 7.45-.75 4.4-.95 5.5-.1-.15-.2-.25-3.95-4.4-6.2-7.35-2.3-2.95-3.95-5.45 0 1.95-1.6 6.35-1.75 4.85-1.8 5.15v-.1q-2.45-7.05-3.3-6.7-.85.35-2.3 3.7-1.5 3.3-2.4 4.4l-.1-.1q-.8-3-1.3-4.95-1-3.6-2.1-5.55-.15.55-.15 3.2-.05 2.65.45 4.3.45 1.6 1.4 4.9.9 3.25 1.1 4.5.15 1.25 2.25-2.9 2.05-4.15 3.05-6.7 1.35 4.2 2.4 8.45 1.05 4.25 1.3 2.05.9-3.4 1.7-6.45 1.55-6.05 2.5-7.25.95 1.1 4.65 6.45 3.85 5.2 6.75 7.05.1-.5.75-5.8.6-5.15.75-5.5.8.75 3.7 3.55 2.65 2.45 3.4 2.75h.6v-.5Q92.65 9.35 90.5 0m.1 7.55l.1-.1v.25q-.05-.1-.1-.15z"
                    id="LegSki026__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M37.95 34.5q-2.2-6.4-1.85-9.5-.45.5-2.55 3.55-1.4 2.05-2.55 2.75 1.75.15 3.7 1.65 1.6 1.25 3.25 1.55m-6.6-22.8l-4.9 10q-.2.55-.25.65-.25.45-.5.95l-.1.1q-4.3 7.4-6.4 7.4-.35 0-.45-.3-.1-.3-.1-1.35 0-.7.2-2.35 1.1-7 1.8-9.25.7-2.35 1.3-8.7-2.3 7.75-3.45 10.45-3.65 8.3-5.05 8.3-.6 0-.7-.7V26v-.15q2.4-11.4 2.4-14.3V8.9q-.05.15-.1.25-.05 1.4-3.3 7.2-3.45 6.25-4.9 6.25-.9 0-.9-.9 0-.35.75-2.1-.05-.2-.05-.3 1.05-1.95 2.1-4.45 2.2-5 2.5-8.45.55-3.25.6-4.2.05-1.05.1-2.2h-.35q-.15.4-.3.85Q5.6 16.15 0 27.4h1.65q2.05.4 4.6 2.55 2.35 2 4.6 2.15h1.3q2.65-.6 4.5-1.05.35-.05.7-.05.4 0 .8.05 2 .1 3.35.95 1.6 1 3.2 1h.65q.3-.55.85-1.1.5-.55 1.3-2.5.2-1.1 1.5-5.65 1.25-4.55 2.3-11.85 0-.091.05-.2M64.1 23h-.25q-.15.5-1.45 6.55-.75 3.65-1.95 5.3l.7-.3q2.7-1.2 3.7-1.2 2.75 0 4.4 1.6l.2-.2q-1.25-1.6-2.1-3.15-1.65-3-3.25-8.6m40.7 9.35q4.1 0 5.45 1.2.4.35.9.65-.55-1.2-1.15-2.3-1.6-2.95-4.65-6.55-.3 2.15-1.45 7.05.45-.05.9-.05m-20.15-7.1Q83.6 28.4 83.35 30q-.25 1.55-.75 3-.1.3-.2.55 2-.95 2.7-.95.3 0 .6.05.7.05 1.35.3-.8-.7-1.2-3.2-.45-2.5-1.2-4.5m40.8 5.9q-.6-.55-1.1-1-2.55-2.4-5.3-5.45.4 1.45.65 3.7.3 2.45.55 4.35.25-.15.55-.3 1.75-.95 3.05-.95 1 0 2.2.7l.2.1q-.35-.85-.8-1.15M144 2.85l-.05 1.4q0 3.5 1.35 8.8.75 2.75 1.65 5.95v.2h-.7q-.7-.8-1.75-3.1-1.1-2.3-3.75-9.1-1.2-2.9-.85 0-.05 3.1 1.85 9.1.6 1.5 1.5 4.05.1.35.25.75 1 2.8 2.25 5.9v.6h-.6q-1.2-.5-2.95-3.35-.8-1.35-1.75-3.2-2.95-5.9-5.4-10.15.4 3.4.75 5 .35 1.55.35 1.65 0 .1.2.9.2.75.95 2.65.7 1.9 1.3 3.9.55 2 .55 2.5 0 .45-.1 1v.5l-.7.1q-.45 0-.9-.45-.7-.65-1.5-2.15-1.75-2.5-2.95-4.25-2.1-3.15-3.7-5.9-.65-1.15-1.25-2.25v.1q1.85 7.05 2.05 7.95.3 1.25.5 2.4.55 2.8.55 4.65 0 2.45-.6 3.5v.05q.9-.4 1.7-1.15.65-.65 1.65-1 1.15-.45 2.8-.45 1.2 0 2.4.6 1.15.6 2.65.6 1 0 5.2-1.85.6-.45 2.1-1.5.2-.15.4-.25.7-.15 1.5-.2 2.2-.2 3.7.05v-.05l.6.1q-5.45-9.6-11.25-24.6z"
                    id="LegSki026__Symbol_52_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki026__Symbol_92_0_Layer0_0_FILL"
                transform="translate(115.05 307.1)"
                id="LegSki026__col1n"
            />
            <use
                xlinkHref="#LegSki026__Symbol_64_0_Layer0_0_FILL"
                transform="translate(146.9 316.85)"
                id="LegSki026__col1l"
            />
            <use
                xlinkHref="#LegSki026__Symbol_52_0_Layer0_0_FILL"
                transform="translate(115.05 307.2)"
                id="LegSki026__col1d"
            />
        </g></g>
    )
}

export default LegSki026
