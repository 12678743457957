import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF9AFF"
}

function ShiSho064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M111.15 23.3q4.05-1.2 7.85-2.65.05-.05.15-.05.25-.15.5-.25l-1.05-4.95q-1.45-7.35-2.2-12.25-.9-.05-1-.05-.75-.1-1.2-.2Q95.4.9 76.6.3 59.85-.25 43.15.3q-7.2.25-14.4.7-12.45.75-24.9 2.1h-.1l-.5.1q-.6 3.95-1.3 7.9-.25 1.4-.5 2.75L0 20.55q.2.05.4.15.05 0 .15.05Q4 22.1 7.95 23.3q10.4 2.55 20.8 4.05 40.9 5.85 82.4-4.05z"
                    id="ShiSho064__Symbol_178_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho064__Symbol_178_0_Layer0_0_FILL"
                transform="translate(132.7 262.45)"
                id="ShiSho064__col1n"
            />
        </g></g>
    )
}

export default ShiSho064
