import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#008300",
    "col1n": "#00B600",
    "col1s": "#034803",
    "col2n": "#FFFFFF"
}

function AccHat020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M187.5 47.8q-1.1-1.45-2.15-2.9-13.15-16.8-31.5-29.85-6.35-3.95-13.35-6.85-2.35-.95-4.75-1.8v.05q-2.65-.95-5.4-1.75-.75-.25-1.45-.45-.95-.3-1.95-.6-3.1-1-6.25-1.7-17.15-3.9-35.55 0-3.4.7-6.85 1.7-1 .3-1.95.6-.7.2-1.35.45-2.7.75-5.3 1.7-2.4.85-4.75 1.8-7 2.9-13.35 6.85Q33.25 28.1 20.1 44.9q-1.05 1.45-2.15 2.9Q8.25 60.8 1.6 76q-3.55 10.15.75 20.7.05.15.2.35.35.75.7 1.45.05.15.15.3.35.75.75 1.45l.15.15.3.3q1.1 1 2.55 1 .6 0 1.15-.15h.05q.45-.2.9-.5.25-.2.55-.45.3-.4.65-.8.2-.25.4-.45.25-.3.5-.55.45-.45 1-.9 1-.9 1.65-1.35l.1-.1q.9-.65 1.9-1.25 3.4-2.15 7.75-4 76-37.4 155.2-1.15 6 2.3 10.55 5.15.95.6 1.85 1.25l.1.1q.65.45 1.65 1.35.55.45 1 .9.5.5 1 1.05.35.4.65.85l.05.05q.6.5 1.3.75.55.2 1.2.2.6 0 1.15-.15h.05q.45-.2.9-.5.25-.2.55-.45.05-.1.15-.2.15-.2.35-.4l.7-1.3q.35-.8.75-1.65.15-.2.2-.35 4.3-10.55.75-20.7-6.65-15.2-16.35-28.2z"
                    id="AccHat020__Symbol_238_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M176.1 42.3q-1.65-1.7-3.55-2.85-8.5-5.25-13.1-7.2-.85-.35-1.8-.75-4.2-1.7-10.75-3.85-3.2-1.1-5.9-1.95-2.25-.7-4.15-1.2-1.45-.45-2.7-.8-4.4-1.25-6.75-1.65v.05q-.35-.1-.65-.15-2.35-.4-6.95-1.45 2.8-2.7 3.5-7.2 1.05-5.8-2.2-12.8 1.3 3.1 1.3 6.65 0 4-1.65 7.45-1.25 2.65-3.55 5.1-11.55-1.5-22.95-1.5-11.35 0-22.95 1.5-2.2-2.45-3.55-5.1-1.55-3.45-1.55-7.45 0-3.85 1.45-7.15-3.5 7.35-2.45 13.3.75 4.5 3.55 7.2-4.6 1.05-7 1.45l-.4.1q-2.35.4-6.75 1.65-2.9.8-6.85 2-2.7.85-5.9 1.95Q35.3 29.8 31.1 31.5q-.95.4-1.8.75-4.6 1.95-13.1 7.2-1.9 1.15-3.55 2.85-1.25 1-4.95 6.4-2.8 4-5.55 11.4l-.1.2q-.8 2.15-1.6 4.65-.15.3-.25 1.15l-.1.2q-.05.2-.1.45.45-.2.9-.5.25-.2.55-.45.3-.4.65-.8.2-.25.4-.45.25-.3.5-.55 0-4.2 5.1-12.95.35-.65.75-1.3.4-.7.8-1.35 2.45-3.9 7.5-7.35 1.65-1.15 3.55-2.2Q40.85 29.5 61.35 25.8q4.05-.75 8.15-1.25.1-.05.25-.05 1.1-.2 2.3-.35l.8-.1 5-.65q.1 0 .25.05.1-.05.2-.05.75-.15 1.5-.15l1.6-.1q3.35-.2 6.6-.35 1.55-.1 2.95-.1H91q1.25-.1 2.5-.1H95q1.3 0 2.45.1h.15q1.4 0 2.85.1 3.35.15 6.65.35l1.65.1q.75 0 1.5.15.1 0 .25.05.1-.05.2-.05l3.3.35q3.55.55 4.85.75h.1q.15 0 .35.05.65.1 1.25.2l6.85 1.05q4.75.85 9.45 2 15.7 3.85 31.2 11.05 1.9 1.05 3.55 2.2 5.05 3.45 7.5 7.35.4.65.8 1.35.4.65.75 1.3 5.1 8.75 5.1 12.95.5.5 1 1.05.35.4.65.85l.05.05q.6.5 1.3.75l-.1-.4q-.2-.5-.9-2.85l-.2-.7q-.1-.2-.15-.4l-.1-.3q-.05-.25-.15-.55l-.1-.3q-.2-.55-.4-1.15-2.75-7.4-5.6-11.35-3.7-5.4-4.95-6.4z"
                    id="AccHat020__Symbol_150_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M177.05 33.7l-.3-.65q-5.15-13.5-17.05-19.7-3.85-1.85-8.8-3.25-10.5-3.1-19.8-5.15-1.3-.3-2.6-.55-1.55-.35-3.35-.65-1.65-.3-3.5-.6v.05q-8.9-1.45-21.55-2.75Q99.3.3 98.4.3 95.05.1 91.7 0h-6.45Q82 .1 78.7.3q-.9 0-1.8.15-12.55 1.3-21.3 2.7-1.85.3-3.5.6-1.8.3-3.35.65-10.45 2.15-22.4 5.7-4.95 1.4-8.8 3.25Q5.65 19.55.5 33.05l-.3.65-.2.2q5.4-3.8 13.35-6.75l.2-.05q.15-.1.35-.15.9-.3 1.9-.6 4.15-1.4 8.25-2.6 4.45-1.35 8.85-2.5l12.2-2.95q2-.4 4.05-.8h.15l.1-.1q3.1-.6 6.2-1.05 9-1.4 18.15-2l.05.05q.4-.05.85-.1Q99 12.75 124.1 16.95q1.05.15 2.95.55l1 .2q3.75.7 10.35 2.15l5.95 1.4q4.4 1.15 8.85 2.5 4.1 1.2 8.25 2.6 1 .3 1.9.6.2.05.35.15l.2.05q7.95 2.95 13.35 6.75l-.2-.2z"
                    id="AccHat020__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M52.05 10.6q-1.9-2.4-4.55-4.4Q44.95 4.3 42 3q-6.75-3-13.95-3Q20.9 0 14.1 3q-2.85 1.3-5.45 3.2-2.65 2-4.6 4.4-.8 1-1.4 2-.05.05-.1.15-.65.95-1.1 2.05Q0 18.1 0 21.95q0 4 1.55 7.45 1.35 2.65 3.55 5.1Q16.7 33 28.05 33q11.4 0 22.95 1.5 2.3-2.45 3.55-5.1 1.65-3.45 1.65-7.45 0-3.55-1.3-6.65-.05 0-.05-.05l-.2-.45q-.45-1.1-1.05-2.05-.65-1.05-1.55-2.15m-11.1 12.75v7.05H21.8l-.85-26.75h8.15L27.75 24.7l13.2-1.35z"
                    id="AccHat020__Symbol_63_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat020__Symbol_238_0_Layer0_0_FILL"
                transform="translate(89.8 39.55)"
                id="AccHat020__col1n"
            />
            <use
                xlinkHref="#AccHat020__Symbol_150_0_Layer0_0_FILL"
                transform="translate(98.15 74.35)"
                id="AccHat020__col1d"
            />
            <use
                xlinkHref="#AccHat020__Symbol_117_0_Layer0_0_FILL"
                transform="translate(103.9 102.1)"
                id="AccHat020__col1s"
            />
            <use
                xlinkHref="#AccHat020__Symbol_63_0_Layer0_0_FILL"
                transform="translate(164.35 59.55)"
                id="AccHat020__col2n"
            />
        </g></g>
    )
}

export default AccHat020
