import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C4C389"
}

function FacMou067({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M209.9 218.2l-.55-.55q-1.7-1.95-4.75-2.35-2.15-.35-3.75-.6-8.6-1.8-17.35 0-2.45.6-6.4 1.7-5.05 1.45-6.5 3.3-.75 1.3.1 3.85.8 2.5 2.8 5.45 1.95 2.9 2.6 4.4.6 1.45.8 1.8 1.7 2.4 5.1 2.1 3.75-.25 8.3-2.6 5.1-2.7 7.9-2.7.5 0 3.8.4 6.6 0 10.25-2.65 1.35-1 2.1-2.1 1.05-1.6 0-3.55-.3-.55-.65-1.15-1.55-2.55-3.8-4.75z"
                    id="FacMou067__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M18.15 14.25q-.05-.15-.1-.25-.55-.1-1.25-.2-.9-.1-1.55.05-1.3.8-1.3 2.25 0 .8.5 1.6.55.55 1.3.55h.15q.85-.35 1.75-.8.95-.45 1.8-.8 0-.05-.05-.1-.5-.55-1.25-2.3M15 16.65v-.05q.001.05.05.05v.1q-.002-.052-.05-.1m-9.8.7q.2.3.35.55 1.5 2 4.6 1.75 1.65-.1 3.55-.6-.05-.1-.1-.15-.35-.4-.8-1.15-.95-1.75-1.2-2.15-.35-.55-.65-1-.65.05-1.5.3-1.4.45-2.9 1.45-1.2.75-1.35 1m.9-14.4V2.5q-1.05.35-2.45.75-2.55 1-3 1.3-.5.35-.65.85.15.7.4 1.45 1.05 3.85 1.85 3.85.95 0 2.7-.8 1.8-.8 1.8-1.45 0-.85-.3-2.7-.35-1.9-.35-2.8m12.8-.9q.2-.55.2-.9.1-.85-.3-1.15h-.1q-2.4.15-3.1.3-2.55.4-3.25.5h-.05q-.9.3-2.45 1.35Q8.2 3.3 8.2 3.8l.05.1q1 .35 2.8 1.5.9-.8 1.7-1.25 2.7-1.45 2.7 2.2v.25q.1.05.25.1.05 0 .05.05.5-.65 1.1-1.5.5-.65.85-1.15.4-.4.8-1.15l.2-.4q.2-.35.2-.5m6.4 12.75q0-.05-.15-.65l-.4-1.2q-.35-.9-1.1-.9-.8 0-1.95 1.1-.65.65-.85 1.05-.2.2-.2.4-.05.1-.05.2v.05q0 .5.3 1l.1.2q2.3-.85 3.85-.85.15 0 .6.05-.05-.25-.15-.45M30 5.45v-.4q-.05-.1-.1-.25-.8-2.85-.85-3.8-2.5-.4-3.95-.6-2.3-.3-4.55-.35-.05.05-.05.15 0 .25.3 1.05.15.35.25.6.7 1.7 1.4 3.5.15.4.35.75v.05q.05-.1.15-.15.65-.5 1.65-.5.55 0 1.5.4.9.35 2.05.35 1.6 0 1.85-.8m2.9 5.65q-1.45.55-2 1.05-.45-.4-1.4-1.45-.3-.35-.5-.55-1.05.5-1.55 1.2-.35.5-.45 1.05v.05q.1.75.45 1.5.05.05.05.15 0 .05.05.1.7 1.1 1.9 1.05h2.25q2.95-.25 3.4-.85.3-.4.6-.85l.75-1.2q.4-.75.4-1.3.1-.6-.3-.75-.1-.05-.3-.05-1.2 0-3.35.85m4.25-5.75q-.8-.9-1.7-1.75-.3-.25-.5-.45-1.35-1.35-3.55-1.8l.05.15q.4 1.7.5 1.9l.9 3.05q.35.5.75.5.35 0 1.25-.25l1.45-.3q.85-.4.85-1v-.05z"
                    id="FacMou067__Symbol_92_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou067__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou067__Symbol_92_0_Layer0_0_FILL"
                transform="translate(172.55 215.55)"
                id="FacMou067__col1n"
            />
        </g></g>
    )
}

export default FacMou067
