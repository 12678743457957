import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function ShiSho070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.15 45.65q-4.75.55-9.75 1l-6.75.55q-.15 0-.25.05l-.4 1.8h.3l47.1.45q-7.45-4.7-30.25-3.85M106.9 0q-2.2 1.2-4.4 2.3l-8.05 14.65-.05.2q.35-.6.65-1.15h.05q-.9 1.75-2.2 3.4l-11.1 7.8q-11.65 8.1-21.3 10.35h.05l1.2.4q9.15 7.55 38.9 7.35.65.05 3.9.45l-.5-.1q.65.1 1.35.2 8.9-3.1 11.65-7.65.35-.55.55-1.15 0-.05.05-.1l1.1-1.85-1.45-7.25-2.7-2.15q-.65-.65-1.35-1.25-5.5-4.65-14.75-7.5l.1-.3L106.9 0z"
                    id="ShiSho070__Symbol_187_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M101.4 46.05l.5.1q-3.25-.4-3.9-.45l-30.05-5.1-8.85-2.25-1.2-.4h-.05q-.9-.25-1.7-.5-9.25-2.85-16.05-6.3-11.55-5.9-16.15-13.6L15.2 2.05q-1.9-1-3.75-2.05l8.4 17.35Q9.45 20.6 3.9 26.1l-2 1.85q-.15.1-.25.25L0 36.65q.1.1.15.2 2.7 5.9 14.35 9.2 11 3.1 30.25 3.85l12 .15H58.2q.3 0 .6.05h.15l59.8-.75h.2l-.4-1.7h-.15l-1.15-.05q-.25-.05-.5-.05h-.05q-.35 0-.7-.05-1.1-.1-2.15-.2l-11.1-1.05q-.7-.1-1.35-.2z"
                    id="ShiSho070__Symbol_188_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho070__Symbol_187_0_Layer0_0_FILL"
                transform="translate(131.65 238.1)"
                id="ShiSho070__col1n"
            />
            <use
                xlinkHref="#ShiSho070__Symbol_188_0_Layer0_0_FILL"
                transform="translate(134.3 237.7)"
                id="ShiSho070__col2n"
            />
        </g></g>
    )
}

export default ShiSho070
