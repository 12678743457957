import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo010({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#00B200"
                    d="M93.1 228.45q-.2.1-.35.2-.85 1-1 1.95-.1-.1-.25-.15-.3-.5-.65-.95-.5-.35-1.2-.6-.55.1-1.25-.3-.25-.05-1.1-.75 0 .75-.35 2.5-.2 1.35.3 2.25v.15h-.15q-.1 0-.5.05-.6.2-.8.3-.95.6-1 1-.05.25.55 1.2.3.75.8 1.1-.6-.35-1.6-.65h-.1q-2.95-1-4.3-.25 0 .15.1.2l.25.25q.35.55.15.75-.1.8-.25.95.1.3.45 1.6v.15q-.65-.35-1.2-.35-.7-.2-2.4.15-.15-.05-.55-.1-.45.35-.7.85.15.05.3.05.25.15.35.45.2.3.5 1.45.5 1.1.75 1.55.2.3.35.45.35.2.6.5l-.45.25q-.1 0-.45.2-.25.1-.55 1.05-.4.75-1.3 1.2-.2.1-.3.25 0 .55.35 1.3.45.75 1.9 1.4 1.15.45 1.65.25h.1v.1q.2.6.55 1.2.6 1.4 1.1 1.85 1.15.9 2.05.45.45-.05.75-.6.5-.5.7-.85l.35.45q-.2.55.35 1.6.65 1.5 1.7 1.75.35-.6.55-.9l1.05-.4q.3-.2.8-.85l.05-.15 6.8 6.2.05-.15 7.3.65 1.45-5.65-2.95-9.4q.45.05.85 0 .2-.3.55-1.05l.7-.9q.35-.35.65-.6.35-.25.45-.2.5-.2.9-.3l-.1-.4q-.2-.35-1.4-.95-.5-.2-1.35-.45 1.35-.5 1.6-2.3.25-1.65.5-2.45.2-.2.35-.5-.25-.05-.95-.15-.8-.1-1.1-.15-.95-.3-1.2-.3-.3-.05-.55.05-.15.15-.35.25.3-.8.5-2.15-.1-1.55-.4-2.3 0-.15-.1-.35l-.2-.45q-.2.15-2.2.8-.3 0-.45.25-1.65.45-1.9.55l-.65.3q.25-.6.15-1.75-.1-.3-.05-.7-.35-1.2-.4-1.9-.1-.45-.2-.7-1.2-.2-3 1.65z"
                    id="ObjFoo010__Layer7_0_FILL"
                />
                <path
                    fill="#070"
                    d="M97.65 258.75q.2-.4.5-.6l-6.2-5.8 5.65 6.5.05-.1m-.7.9l.15-.15q-1.25-1.15-3.2-3.1-3.3-3.15-3.75-3.05-.05.1-.35.6l6.8 6.2q.05-.15.15-.4.15.05.2-.1m5.75-4.4q.35-.05.5 0l-5.35-11.3 4.75 11.45.1-.15m-.25-9.65q-.45.1-.8-.15.4.95 1.95 6.05.5 1.9 1.25 3.5l.15.05q.15 0 .4-.05l-2.95-9.4m-8.6-1.6h-.2q-.471 0-.75-.1-.492-.02-.6-.45-.2-.5.1-1.65-.2.35-.35.6-.9 1.15-.65 1.6.05.45.9.4.065.004.1 0 .85-.15 1.15-.25l.3-.15m4.4-7q-1.1 0-1.55.1l-.05.1q1.75.7 2.35 1.6l.2.45q-.05.15-.1.7l-.2.65q.1.05-.05.1.1.05.3-.05.9-.55.75-1.55l-.3-.75q-.3-.8-.45-.8-.25-.45-.9-.55m-15.55 9.95q-.75.15-1 .1-.3 0-.35-.2-.1-.4.35-.9.5-.65.4-1.5-.4.55-.7 1.05-.2.1-1.05 1.1-.2.5 0 1 .25.3 2.6.35.35-.4.5-.6l-.25-.05q-.35-.2-.5-.35m3.6-10.4q-.15 0-.15-.15-.6-.35-1.6-.65h-.1q.35.6.5.6.3.85-.45 1.9l-.15.25q-1.35 1.35-.65 2.6.2.25.85.75.15.2.35.4l.7.9q.25.5 0 .85 0 .25.1.6-.05.4.1.95.25.45 1.4 1.2.1 0 .1.15.15.05.15.2.15 0-.05.25l-.05.15q-.35.75-.05 1.5 0 .15.2.3.15.15.25.35.2.55.95 1.25.096 0 1.1 1.05.166-.152.4-.35.375-.321.7-.5.077-.007.1 0 .05-.01.05-.05.474-.29 1.4.1.9.4 1.6-.35-.05.3.15.75-.1.4.05.7-.05.15.1.15.05.45.1 1 .4.05.7.2 2.5.95 3.1 1.75.75.65.95 1.25.1.15.25.3.3-.05.4-.2.05-.25.2-.15l.15-.05q-.2-.75-.75-1.55-1.05-1.7-2.65-2.05-1.45-.5-1.6-.5l.05-.55q.05-.4-.1-1-.1-.45-.45-.8-.3-.6-.4-1l-.2.35q-.05.25-.2.25-.3.25-1.35-.2-1.05-.4-1.8-.25-.15.45-.35.5-.8-.35-1.1-.95-.45-.8.1-1.95.151-.172.25-.3-1.159-.17-1.9-1.2-.65-.95-.65-1.8 0-.602.1-.95-1.303-.605-1.6-1.2-.55-1.2.4-2.6 1.15-1.25.9-1.55.05-.15-.6-.65m7.3-2q-.15-.15-.15-.3-.05-.2-.75-.4-.4-.05-.65-.25-.55-.35-.8-.4-.1-.15-.15-.05-.295-.126-.55 1 .073-.076.1.25.1.15.1.25-.1.55-.2 1.55-.05.25.25.85.95 1.25 1.5 1.35-.05-.25-.45-.8-.15-.55-.35-2.3-.3-1 .75-1.1.6-.2 1.1.3.1.05.25.05z"
                    id="ObjFoo010__Layer7_1_FILL"
                />
                <path
                    fill="#F90"
                    d="M125.05 302.6q-.9 3.4-2.6 5.1-.108.101-.25.2.23.289.4.55 2.043 2.63 3.35 2.75.285 2.136 1.5 4 1.958 2.476 3.4 3.9 4.813 4.66 5.2 5.25.396.595 1.05 1.65.4.45.8 1.4 2.1 3.75 2.3 5.5-.2-2.55-.65-5.4-.2-1.45-.65-5.25-.25-3.75-1.35-6.65-.071-.145-.15-.3-.252-.526-.45-.95-1.566-4.313-4.9-7.3.054-4.003-1.15-7.9-2.485-.285-4.35-2.15-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55m-12.2-43.45q-.212-.424-.5-.85-2.038-2.5-4.7-3.2-5.877-1.345-11.1 5.65-1.424 6.654 3.95 14.1v.05q2.78 7.98 7.75 12.45.143-.075.3-.15 4.45-1.85 10.2 0 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-2.665 1.6-4.7-.27-.852-.55-1.35-.3-.85-.95-2.1-.8-1.35-1-1.8-.6-.8-1.55-1.9l-.1-.05v-.15q.2-1.2-.7-3.95-.75-3.5-1.4-4.55-.75-1.55-1.25-2.05-.6-.65-1.95-2.1-.75-.65-1.15-.8-.15-.05-.25-.2.05-.15.05-.25-.25-1.3-.5-2.35-1.35-3.3-1.75-4.15z"
                    id="ObjFoo010__Layer7_2_FILL"
                />
                <path
                    fill="#E55B00"
                    d="M122.8 307.3q-.175.225-.35.4-.453.423-.95.7 1.735 1.704 3.75 2.75.597 4.019 3.85 6.85.22.246.45.45 2.25 1.8 4.6 3.75-.7-.9-1.9-2.2-2.65-2.9-3.65-4.75-1.2-2.1-1-3.65.45-2 2.9-3.45-.45.05-1.05.15-.598.15-1.25.35-.602.2-1.3.9l-.65.75q-.95.4-3.15-2.55-.159-.214-.3-.45m-16.3-52.4l-.9-.05q-3.872-.451-6.55 1.85-2.945 2.28-3.55 5.65-.38 1.85.2 3.95.05.4.15 1 1.25 3.95 3.1 6.2.75.8 1.5 1.3.15.15.2.1-.096 3.16 1.55 6.1 2.415 4.837 5.7 6.55.298-.181.65-.35 1.376-.572 2.85-.8.871-1.719 3.05-2.85 1.25-.8 3.7-1.55-2.05-.45-3.6-.25-1.7.3-3.6 1.55-1.7 1.2-2.55 3.3-1-.85-1.9-1.95-1.25-1.45-2.1-3.1-2.1-3.95-.5-7.3 1.65-3.55 7-5.75-1.95-.15-2.75-.1-1.5.2-2.95.95-2.6 1.3-4.2 4.7-3.824-4.55-3.55-9.1.014-.84.15-1.65.087-.287.15-.6 1.786-6.675 8.75-7.8z"
                    id="ObjFoo010__Layer7_3_FILL"
                />
            </defs>
            <use xlinkHref="#ObjFoo010__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo010__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo010__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo010__Layer7_3_FILL" />
        </g></g>
    )
}

export default ObjFoo010
