import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou080({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M77.1 8.4q2.55-2.75 2.9-7.35.05-.4-.2-.7-.3-.3-.65-.35-.4-.05-.7.25-.3.25-.35.65-.25 3.85-2.4 6.2-2.35 2.55-6 1.95-4.6-.85-7.9 2.8-2.5 2.75-4.4 2.85-2.05-.1-3.7-.7-1.95-.7-3.9-.7-2-.05-5.1 2.3-.8.55-1.45 1-3.95 1.95-6.5.05h-.05q-.65-.45-1.35-1h-.05q-3.1-2.35-5.1-2.3-1.95 0-3.85.7-1.7.6-3.7.7-1.9-.05-4.45-2.85-3.3-3.65-7.9-2.8-3.65.6-6-1.95Q2.15 4.8 1.9.95 1.85.55 1.6.3 1.25 0 .9.05.5.1.25.4q-.3.3-.25.7.35 4.6 2.9 7.35 3.05 3.25 7.7 2.5 3.6-.6 6.2 2.25 3.3 3.6 5.9 3.45 2.35-.1 4.3-.8 1.55-.6 3.2-.6 1.5.05 4 1.9v.05q.75.55 1.4 1 3.4 2.7 8.6.05l.1-.05q.7-.45 1.55-1.1 2.45-1.85 3.95-1.9 1.65 0 3.25.6 1.9.7 4.25.8h.05q2.55.15 5.85-3.45 2.6-2.85 6.2-2.25 4.65.75 7.7-2.5z"
                    id="FacMou080__Symbol_105_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou080__Symbol_105_0_Layer0_0_FILL"
                transform="translate(152.65 210)"
                id="FacMou080__col1n"
            />
        </g></g>
    )
}

export default FacMou080
