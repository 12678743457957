import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#954A3C",
    "col1n": "#C66450"
}

function BodTai006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M57 59.3q0-1.35.5-4t.5-3.25v-.25q-1.35 2.2-3 6.4-2 5.15-2 7.95 0 2.25 1.2 5.15 1.5 3.7 4.05 5.25-.2.25-.3.35-.2.15-1.45.8t-4.7-.8q-2.95-1.2-3.25-2.55.35 5.1 5.8 7.7 3.4 1.65 5.1 1.9 1.65.2.8 5.05-.85 4.85-5.75 13.95-4.9 9.05-15.15 10 2.1 1.25 4.25 1.3 1.15 0 2.9-.2.2-.05-.5 1.05-.8 1.05-6.05 5.1-18.35 12.5-35.85 5.05-1.9-.5-4.1-1.1 5.35 5 14.9 7.05 17.8 3.9 29.25-2.4 17.15-8.2 19.9-26-2.4 6.6-8.3 10.3 4.05-5.7 6.35-10.55 3.4-5.65 4.05-11.45.15-2.5-.15-5-.95-6.25-.05-9.25-4.95-2.95-6.7-5.9Q57 67.15 57 59.3M47.3 0l-2.1.8Q26.85 8.85 16.6 31.6q-.25.75-.45 1.55l-.5 2.15q-.05.1-.1.25.45.95 1.45 1.65.6.4 3.5 1.85 0-4 2.05-9.9 1.75-5 4.7-10.6Q32.55 8.45 47.3 0m61.65 18.95q-7.25-8.55-18.5-12.9-.05-.05-.1-.05.05 0 .1-.05.2-.05.4-.15-1.6.3-6.35 3.75 12.7-1.05 24.45 9.4z"
                    id="BodTai006__Symbol_22_0_Layer0_0_FILL"
                />
                <g
                    id="BodTai006__Symbol_30_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M95.1 5.45q-.95-.5-1.85-1Q73-4 51.95 3.65l-2.1.8q-18.35 8.05-28.6 30.8-.25.75-.45 1.55l-.5 2.15q-.05.1-.1.25l-.05.05q-.2-.4-.3-.75-.1-.2-.15-.35-.6-2-.65-5 .2-1.25.3-2.3-1.3-.1-2.45 1.95-.75 1.3-1.25 2.35-.95 2.5-1.5 5.05-2.7 12.4 1.1 30.1 2.1 9.65 2.45 12.8.85 6.8-.3 12.25-2 9.05-6.25 15.35-2.6 3.7-7.65 7.85-1.9 1.6-3.5 2.7.7 1.65 1.9 3.45l.7.9q.9 1.15 2.05 2.2 5.35 5 14.9 7.05 17.8 3.9 29.25-2.4 17.15-8.2 19.9-26-2.4 6.6-8.3 10.3 4.05-5.7 6.35-10.55 3.4-5.65 4.05-11.45.15-2.5-.15-5-.95-6.25-.05-9.25v-.05q.15.05.35.1 9.3 1.95 17.3-2.2 1.15-.8 2.05-1.9-1.8 4.8-8.3 6.5 6.6.4 12.75-1.25 10.9-2.55 16.45-11.7.45-.7.85-1.5 1.25-2.4 2.7-5.05 2-4 3.05-8.2.05.45.15 1.15.4 4.2-1.35 10.35 3.3-4.8 4.9-10.75.2-.85.4-1.8 3.25-15-5.75-28.25-1.2-1.75-2.55-3.35-7.25-8.55-18.5-12.9-.05-.05-.1-.05.05 0 .1-.05.2-.05.4-.15.15-.05.4-.1 1.45-.45 2.7-.35 1.65.25 2.1.2-.25-.3-.65-.6-2.4-1.75-4.95-3.15m-75.95 124.5v.15q-.097-.145-.3-.15h.3z" />
                    <path d="M19.15 130.1v-.15h-.3q.203.005.3.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#BodTai006__Symbol_30_0_Layer0_0_FILL"
                transform="translate(218.85 156.05)"
                id="BodTai006__col1n"
            />
            <use
                xlinkHref="#BodTai006__Symbol_22_0_Layer0_0_FILL"
                transform="translate(223.5 159.7)"
                id="BodTai006__col1d"
            />
        </g></g>
    )
}

export default BodTai006
