import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#170700",
    "col1n": "#2E0E00",
    "col1s": "#000000",
    "col2n": "#A37831"
}

function PetPet015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M18.9 16.8q-.5 8.7-6.55 13.2Q9.6 32.05.45 36.1q-.2.65-.45 1.3l23.65-4.9q-.3-3.65-1.15-6.6-.95-3.65-3.4-9.1h-.2m33.8 11.05q-5.75-12.7-4.15-25.35L49.4 0q-2.15 3.3-3.3 10.1-1.2 6.75 1.2 18.25 9.4 5.1 5.4 8.3 8.2-3.55 0-8.8z"
                    id="PetPet015__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M22.25 70.6q.55.75 1.1 1.65 3.35 5.8 3.35 14 0 5.75-1.65 10.35-.2.45-.35.85l2-.45q.25-.65.45-1.3 1.35-4.3 1.35-9.5 0-9.1-4.2-15.5-4.15-6.45-10.05-6.4-5.9-.05-10.1 6.4Q0 77.1 0 86.2q0 9.05 4.15 15.45.45.75 1 1.4 1.55.65 3.25 1.2.95.25 2.25.15-1.9-1.45-3.5-4.2-3.4-5.8-3.35-13.95-.05-8.2 3.35-14 3.35-5.8 8.1-5.75 4-.05 7 4.1M81.4 44.2l1.15-3.35q-8 5.1-18.05 5.15-14.05 0-23.95-9.95Q30.6 26.1 30.6 12.1q0-5.75 1.65-10.8l.2-.6L30.3 0q-.1.25-.15.5-1.75 5.3-1.7 11.35-.05 15 10.6 25.65 10.65 10.65 25.7 10.65 9.1 0 16.65-3.95z"
                    id="PetPet015__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M8.1 111.25q-1.1-2.05-2.6-2-1.55-.05-2.65 2-1.1 2.05-1.05 5-.05 2.9 1.05 4.95 1.1 2.05 2.65 2.05 1.4 0 2.5-1.8.05-.1.1-.25 1.1-2.05 1.1-4.95 0-2.95-1.1-5m-5.35-8.95q-.5-1.35-1.15-1.35-.65 0-1.1 1.35-.5 1.35-.5 3.25t.5 3.25q.45 1.35 1.1 1.35.65 0 1.15-1.35.45-1.35.5-3.25-.05-1.9-.5-3.25m4.65 0q-.05-1.95-.5-3.3-.5-1.35-1.15-1.3-.65-.05-1.1 1.3-.5 1.35-.5 3.3 0 1.9.5 3.2.45 1.35 1.1 1.35.65 0 1.15-1.35.45-1.3.5-3.2m4.3 3.25q0-1.9-.45-3.25-.5-1.35-1.15-1.35-.7 0-1.15 1.35-.45 1.35-.45 3.25t.45 3.25q.45 1.35 1.15 1.35.65 0 1.15-1.35.45-1.35.45-3.25m22.2-99.5q-.2-.35-.35-.7-.75-1.3-1.85-2.4Q28.75 0 24.65 0 20.5 0 17.5 2.95q-2.95 2.95-2.9 7.15-.05 4.1 2.9 7.05 1.4 1.4 3.05 2.15.2.1.45.15 2.35-4.3 6.1-8.05 3.05-3.1 6.55-5.2.1-.05.25-.15m15.3 40.4q.65.15 1.75.35.35.05 1.6.35h-.35q-1.1 0-1.95-.25v.15q6.5.45 7.3 6.2-.1 4.8 4.65 5 3.8.2 3.8-9.1-.4-2.85-.95-5.25-3-12.2-12-13.55-9.85-.5-15.45 9.35-1.3 2.3-2.4 5.3-2.1 9.25 1.75 10.15 4.15.7 6.1-4.65 1.95-4.4 7.1-3.45l.05-.15q-.6-.2-1-.45m42.05-33.5q-2.1-3.6-6.1-4.75-4.05-1.05-7.65 1.05-1.5.85-2.6 2.1.05 0 .1.05 7.2 7.2 9.2 16.5 1.7-.25 3.35-1.2 1.9-1.1 3.1-2.75.2-.3.45-.65.75-1.25 1.15-2.75 1.05-4.05-1-7.6z"
                    id="PetPet015__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M367.9 223.8q-1-2.35-2.95-4.15-1.65-1.45-3.45-2.2-2.25-.9-4.8-.75-.6 0-1.2.1-2.7.5-4.75 2.3-.8.65-1.5 1.55-1 1.2-1.55 2.6-.15-1.25-.55-2.4-1.15-3.75-4.25-5.7-.15-.1-.2-.15-.55-.3-1.15-.55-4.2-1.95-8.7-.2-1.05.4-1.95 1-1.3.75-2.3 1.8-.85.8-1.45 1.85-.4.45-.65 1.05-.2.25-.3.55-2.25 4.4-.85 8.9.75 2.35 2.3 4.05l.45.45q1.2 1.15 2.9 1.9.4.2.85.4 5.6-9.85 15.45-9.35 9 1.35 12 13.55 1.6-.15 3.05-.7.25-.15.55-.3 2-1.05 3.6-2.95 2.6-3.35 2.5-7.75-.05-.45-.05-.85-.05-.45-.1-.8-.3-1.75-.95-3.25z"
                    id="PetPet015__Layer3_0_FILL"
                />
                <path
                    d="M341.35 237.2q-.2.1-.4.15-.1.05-.25.15-.45.5-.6 1.35-.2.5-.15 1.15v.4q-.05.3.05.5.1.95.55 1.25.1.1.3.1.35 0 .5-.25.55-.35.25-1.9V239q.1-.05.3-.1.1.1.3.25.5.45.55 1.25 0 .05-.05 1.25-.05.95.5 1.1.5.4 1.25.65h.05q.85.25 1.95.25.35 0 .7-.05l.6-.2q.3-.05.5-.25h-.05q.3-.4.35-2-.2-1.35.6-1.4l.1-.05q.15.3.4.95.05.15-.15 1.35 0 1.05.7 1.15.1.05.3.1.3-.05.45-.25.15-.1.15-.3l-.05-.05q.3-.45.3-2.4-.05-1-.3-1.65-.15-.05-.2-.15-.1-.05-.15-.05-1.3-.85-2.55-1.3-.1-.05-.15-.05-.2-.1-.35-.1-2.3-.7-4.6-.2-.85.15-1.7.45z"
                    id="PetPet015__Layer3_1_FILL"
                />
                <path
                    d="M352.95 227.7q.7.7 1.65.7t1.6-.7q.7-.65.7-1.6 0-.95-.7-1.65-.65-.65-1.6-.65-.95 0-1.65.65-.65.7-.65 1.65t.65 1.6m-11.55-5.45q-.65-.65-1.6-.65-.95 0-1.65.65-.65.7-.65 1.65t.65 1.6q.7.7 1.65.7t1.6-.7q.7-.65.7-1.6 0-.95-.7-1.65z"
                    id="PetPet015__Layer3_2_FILL"
                />
                <g
                    id="PetPet015__Symbol_87_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M125.05 37.85q2.45-1.4 4.2-3.4 2.35-2.75 3.35-6.45 1.75-6.5-1.6-12.35-3.4-5.9-9.9-7.65t-12.35 1.65q-3.2 1.85-5.15 4.6Q95.4 8.75 85 8.8q-6.5-.05-12.1 2.1-1.15-3.4-3.9-6.15Q64.25 0 57.5 0q-6.8 0-11.55 4.75Q41.2 9.5 41.2 16.3q0 6.75 4.75 11.5 1.85 1.85 4.05 3-13.4 2.85-24.05 12.15Q10.5 54.3 3.75 71.7q-7.45 17.5 0 36.85 6.6 18.9 21.9 25.1 1.55.65 3.25 1.2.95.25 2.25.15 2.1 1.6 4.6 1.6.9 0 1.75-.2 3.6-.9 6.35-5.6.75-1.3 1.35-2.75l25.65-5.35v4.15q14.5 6.8 29.05 0 8.2-3.55 0-8.8-5.75-12.7-4.15-25.35l7.3-21.25q3.1-2 5.9-4.8 9.95-9.95 9.95-23.95 0-1.4-.1-2.7 3.2-.4 6.25-2.15M84.2 54.35h-.1l.05-.15h.05v.15z" />
                    <path d="M84.1 54.35h.1v-.15h-.05l-.05.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet015__Symbol_87_0_Layer0_0_FILL"
                transform="translate(260.3 189.2)"
                id="PetPet015__col1n"
            />
            <use
                xlinkHref="#PetPet015__Symbol_63_0_Layer0_0_FILL"
                transform="translate(307.5 279.4)"
                id="PetPet015__col1d"
            />
            <use
                xlinkHref="#PetPet015__Symbol_52_0_Layer0_0_FILL"
                transform="translate(280.8 219.8)"
                id="PetPet015__col1s"
            />
            <use
                xlinkHref="#PetPet015__Symbol_37_0_Layer0_0_FILL"
                transform="translate(294.25 196.5)"
                id="PetPet015__col2n"
            />
            <use xlinkHref="#PetPet015__Layer3_0_FILL" />
            <use xlinkHref="#PetPet015__Layer3_1_FILL" />
            <use xlinkHref="#PetPet015__Layer3_2_FILL" />
        </g></g>
    )
}

export default PetPet015
