import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF7B00",
    "col1n": "#FFA24D",
    "col1s": "#C96100",
    "col2d": "#F7E700",
    "col2n": "#FFFF00",
    "col2s": "#CDBA18"
}

function LegSki028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M10.45.05q-.1 0-.15.05-5.65 6.75-8.75 14.85-.95 2.4-1.55 5.1 1.3 2.7 6.05 4.8 3.4 1.5 7.55 2.25-1.1-2.35-2.15-6.55-1.5-5.95-1.5-13.65 0-2.95.35-4.25.25-1 .85-2.65-.35 0-.7.05m32.4 29.65q.75.35 1.6.65L40 12.75 38.7 27.2v.05l.05.05q1.55 1.3 4.1 2.4m68.85-.25q2.55-1.25 4.1-2.7.1-.15.25-.3l-.8-12.75-3.65 15.75h.1M142.5.3q.85 3 1.1 6.2.1 1.45.1 6.9 0 7.45-.5 10.45-.2 1.35-.6 2.85 3.45-.75 6.35-2.05 4.55-2 6-4.55-.15-.2-.25-.4-1.1-3.1-2.6-6.2-.05-.15-.15-.3-.05-.1-.1-.25Q148.05 5.3 142.5.3z"
                    id="LegSki028__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1.7 19.4v-.05l1.5-16.8V2.3l-3.15 15-.05.15.1.25 1.6 1.7m37.15 2.1q-.1 0-.1.05l.1.05v-.05l1.4-.5.85.4L40.05 0h-.15l-1.05 21.5m40.2-2.9l1.3-1.6-2.3-14.15 1 15.75z"
                    id="LegSki028__Symbol_35_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M140.2 7.15q-3.15.4-6.75.4-9.05 0-15.55-2.7V4.8l-1.3 1.6q-.15.15-.25.3-1.55 1.45-4.1 2.7h-.1q-.3.1-.6.25-6.6 2.95-16.05 2.95-9.4 0-16.05-2.95-.2-.1-.4-.15l-.4-.2v-.05l-.85-.4-1.4.5v.05l-.1-.05-.8.3q-6.6 2.95-16.05 2.95-8.3 0-14.45-2.3-.85-.3-1.6-.65-2.55-1.1-4.1-2.4h-.05V7.2l-1.6-1.7-.1-.25q-.05 0-.1.05-.3.1-.6.25-6 2.2-14.2 2.2-2 0-3.9-.1-2.4-.2-4.6-.6Q10 6.3 6.6 4.8 1.85 2.7.55 0 .4.5.3 1.1q-.15.7-.3 1.45v.2Q0 6.9 6.6 9.8q4.9 2.2 11.35 2.75 2.25.2 4.7.2 8.05 0 14-2.15.45-.15.9-.35.4.7 1.1 1.4 1.6 1.65 4.75 3 1.4.6 2.95 1.1.55.15 1.1.35 5.3 1.5 12 1.5 8.35 0 14.45-2.3.8-.3 1.6-.65.3-.15.6-.25.75-.35 1.4-.7.6.35 1.35.7.05 0 .1.05l.5.2q.65.3 1.35.55 6.2 2.4 14.7 2.4 8.45 0 14.65-2.35l.7-.3q.3-.15.65-.25 0-.05.05-.05 3.7-1.6 5.35-3.6.45-.65.8-1.3.55.2 1.15.45 6.2 2.35 14.6 2.35 4.3 0 8.05-.6 4.4-.75 8-2.35 6.65-2.9 6.65-7.05 0-1.3-.65-2.5-1.45 2.55-6 4.55-2.9 1.3-6.35 2.05-1.4.3-2.95.5z"
                    id="LegSki028__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M14.4 7.6q-.15-.25-.25-.55Q10 6.3 6.6 4.8 1.85 2.7.55 0 .4.5.3 1.1q-.15.7-.3 1.45v.2Q0 6.9 6.6 9.8q4.9 2.2 11.35 2.75-1.6-1.2-3.55-4.95m33.05 8.5L45 10.3q-.85-.3-1.6-.65-2.55-1.1-4.1-2.4h-.05l-.6 4.4q1.6 1.65 4.75 3 1.4.6 2.95 1.1.55.15 1.1.35m69.45-5.05l-.3-4.6V6.4q-.15.15-.25.3-1.55 1.45-4.1 2.7l-2.1 5.85.7-.3q.3-.15.65-.25 0-.05.05-.05 3.7-1.6 5.35-3.6m32.6-6.45q-2.9 1.3-6.35 2.05-.6 2.45-1.65 5.3 4.4-.75 8-2.35 6.65-2.9 6.65-7.05 0-1.3-.65-2.5-1.45 2.55-6 4.55z"
                    id="LegSki028__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M2 6.85l.6-4.4V2.4L.9.6 0 5.8q.45-.15.9-.35.4.7 1.1 1.4M40.85 8.9q.6.35 1.35.7L42 4.45l-.85-.4-1.4.5-.3 5.05q.75-.35 1.4-.7m40.2-3.95q.55.2 1.15.45L81.25.05V0l-1.3 1.6v.05l.3 4.6q.45-.65.8-1.3z"
                    id="LegSki028__Symbol_8_0_Layer0_0_FILL"
                />
                <g
                    id="LegSki028__Symbol_94_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M152.45 13.6q-.05-.15-.15-.3-.05-.1-.1-.25Q148.4 5.4 142.85.4q-.25-.2-.45-.4l-2.5.6q-.85.15-1.65.35-1 .25-2 .45l-2.7.6q-.6.1-1.15.25-.85.15-1.65.35-1.45.25-2.85.55-2.25.4-4.45.8-4.45.75-8.85 1.35l-3.5.5q-2.5.3-4.95.6-2 .2-3.95.4-1.05.05-2.1.2-1.2.05-2.4.2-10.15.75-20.3.75h-.75q-9.45 0-18.95-.65-.95-.1-1.85-.15-.85-.1-1.7-.15-2.8-.25-5.6-.55-3.2-.35-6.4-.75-1.7-.25-3.45-.45-2.9-.45-5.75-.9-2.4-.4-4.7-.8-1.65-.3-3.2-.6-.65-.15-1.2-.25h-.1q-2.1-.45-4.2-.85-1.5-.35-3-.65-.5-.15-1-.25-.3-.05-.55-.1-1.7-.4-3.35-.8-.05 0-.15.05-.35 0-.7.05-.1 0-.15.05Q5 6.95 1.9 15.05q-.95 2.4-1.55 5.1-.15.5-.25 1.1-.05.25-.1.55.9 3.25 6.4 5.65 4.9 2.2 11.35 2.75 2.25.2 4.7.2 8.05 0 14-2.15.45-.15.9-.35.4.7 1.1 1.4 1.6 1.65 4.75 3 1.4.6 2.95 1.1.55.15 1.1.35 5.3 1.5 12 1.5 8.35 0 14.45-2.3.8-.3 1.6-.65.3-.15.6-.25.75-.35 1.4-.7.6.35 1.35.7.05 0 .1.05l.5.2q.65.3 1.35.55 6.2 2.4 14.7 2.4 8.45 0 14.65-2.35l.7-.3q.3-.15.65-.25 0-.05.05-.05 3.7-1.6 5.35-3.6.45-.65.8-1.3.55.2 1.15.45 6.2 2.35 14.6 2.35 4.3 0 8.05-.6 4.4-.75 8-2.35 5.65-2.45 6.5-5.8-.15-.65-.5-1.25-.15-.2-.25-.4-1.1-3.1-2.6-6.2M39.1 27.4h-.05v-.05l.05.05z" />
                    <path d="M39.05 27.4h.05l-.05-.05v.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#LegSki028__Symbol_94_0_Layer0_0_FILL"
                transform="translate(115.55 307.1)"
                id="LegSki028__col1n"
            />
            <use
                xlinkHref="#LegSki028__Symbol_54_0_Layer0_0_FILL"
                transform="translate(115.9 307.2)"
                id="LegSki028__col1d"
            />
            <use
                xlinkHref="#LegSki028__Symbol_35_0_Layer0_0_FILL"
                transform="translate(152.9 315.05)"
                id="LegSki028__col1s"
            />
            <use
                xlinkHref="#LegSki028__Symbol_29_0_Layer0_0_FILL"
                transform="translate(115.35 327.25)"
                id="LegSki028__col2n"
            />
            <use
                xlinkHref="#LegSki028__Symbol_12_0_Layer0_0_FILL"
                transform="translate(115.35 327.25)"
                id="LegSki028__col2d"
            />
            <use
                xlinkHref="#LegSki028__Symbol_8_0_Layer0_0_FILL"
                transform="translate(152 332.05)"
                id="LegSki028__col2s"
            />
        </g></g>
    )
}

export default LegSki028
