import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col1s": "#A80F0F"
}

function FacMou038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M36.55 11q-.85-.6-2.2-1.9-4.7-6.05-10.6-8.65-1.45-.7-2.15-.3-.65.35-.8.45-.2.1-.45.15-.2.05-.9.05t-.9-.05Q18.3.7 18.1.6q-.15-.1-.8-.45-.7-.4-2.15.3-5.9 2.6-10.6 8.65-1.35 1.3-2.2 1.9-.9.55-1.9 1.15-1.05.55.25 1.05 1.3.45 2.35.45h.2v.05h.1v.1q4.2 5 7.05 5.85l.3.1q.3.1.65.2 4.05.8 8.1.8t8.1-.8q.35-.1.65-.2l.3-.1q2.85-.85 7.05-5.85v-.1h.1v-.05h.2q1.05 0 2.35-.45 1.3-.5.25-1.05-1-.6-1.9-1.15z"
                    id="FacMou038__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M32.3 4.7q-2.2-.55-3.95-1.6l-.45-.25q-1.1-.5-1.65-.7-.2-.1-.35-.15-4.65-2.05-9.75-2-5.1-.05-9.75 2-.15.05-.35.15-.55.2-1.65.7l-.45.25Q2.2 4.15 0 4.7l1.3.05q2-.25 5.4-1.9 1.75.3 3.4.6 3.1.45 6.05.5 2.95-.05 6.05-.5 1.65-.3 3.4-.6Q29 4.5 31 4.75l1.3-.05z"
                    id="FacMou038__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou038__Symbol_63_0_Layer0_0_FILL"
                transform="translate(173.05 210.3)"
                id="FacMou038__col1n"
            />
            <use
                xlinkHref="#FacMou038__Symbol_6_0_Layer0_0_FILL"
                transform="translate(176.35 219.25)"
                id="FacMou038__col1s"
            />
        </g></g>
    )
}

export default FacMou038
