import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ObjToy001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M87.7 51.8q.6-3.7.6-7.65 0-18.3-12.95-31.25-4.25-4.3-9.1-7.15Q56.4 0 44.15 0 35.4 0 27.9 2.9q-8.25 3.25-15 10-6.75 6.8-10 15.05Q0 35.45 0 44.15q0 17.5 11.85 30.05.5.55 1.05 1.15Q25.85 88.3 44.15 88.3q7.65 0 14.3-2.25 9.35-3.15 16.9-10.7Q85.5 65.25 87.7 51.8z"
                    id="ObjToy001__Symbol_95_0_Layer0_0_FILL"
                />
                <g id="ObjToy001__Layer5_0_FILL">
                    <path
                        fill="#FE0"
                        d="M295.1 276.65q-6.75 6.8-10 15.05 6.65-6.8 19.45-6.7.65-.05 1.4 0 .4-2.35 2.2-4.1 1.75-1.75 4-2.2.05-.8.05-1.55.05-5.25-2.1-10.5-8.25 3.25-15 10z"
                    />
                    <path
                        fill="red"
                        d="M326.35 352.05q7.65 0 14.3-2.25-15.9-18.3-22.85-54.45-.15-.65-.25-1.3-1.6.8-3.6.8-3.3 0-5.7-2.3-.35.5-.75 1.05-19.85 26.35-13.45 44.35.5.55 1.05 1.15 12.95 12.95 31.25 12.95z"
                    />
                    <path
                        fill="#005DFF"
                        d="M357.55 276.65q-4.25-4.3-9.1-7.15-13.85-2.05-27.7 10.25-.6.5-1.1 1 0 .05.1.15 2.3 2.3 2.4 5.5.7.15 1.4.35 40.65 11.2 46.35 28.8.6-3.7.6-7.65 0-18.3-12.95-31.25z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy001__Symbol_95_0_Layer0_0_FILL"
                transform="translate(282.2 263.75)"
                id="ObjToy001__col1n"
            />
            <use xlinkHref="#ObjToy001__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToy001
