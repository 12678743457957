import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#7973B7",
    "col2n": "#000000"
}

function SkiMak007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.6 8.9q4.4 1.55 5 3.6l3.25.7q-1.6-3.4-4.2-6.2Q25.4-.75 14.4.1 6.7.65.35 5.25.2 5.3.1 5.4q-.05 0-.1.05l.8-.1 9.85 2.15q2.7-.35 5.85-.35 7.1 0 12.1 1.75m35-8.8Q52.55-.75 45.3 7q-2.55 2.7-4.1 6l4.35-.9q.4-2.1 5.2-3.6Q56 6.85 63.45 6.85q3.3 0 6.15.35L78 5.45Q71.45.65 63.6.1z"
                    id="SkiMak007__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M54.9 8.55h-.05Q46.2.9 28.9 0 23.5 0 18 .8q.048-.048.1-.05H18q-.85.1-1.65.25Q7.6 2.45 0 6.9q.45 4.4 6 6.6.25.1.55.2.05 0 .15.05h.35q1-2.3 2.4-3.9Q18.2 2.6 32.5 3.1q10.2.2 17.3 4.2 4.45 2.85 6.85 6.6l-.35-2.45q-.4-1.65-.65-2.05-.1-.2-.2-.35-.3-.25-.55-.5m52.45 5.15q.3-.1.55-.2 5.55-2.2 6-6.6-7.6-4.45-16.35-5.9-.8-.15-1.65-.25h-.1q.052.002.1.05Q90.4 0 85 0 67.7.9 59.05 8.55H59q-.25.25-.55.5-.1.15-.2.35-.25.4-.65 2.05l-.35 2.45q2.4-3.75 6.85-6.6 7.1-4 17.3-4.2 14.3-.5 23.05 6.75 1.4 1.6 2.4 3.9h.35q.1-.05.15-.05z"
                    id="SkiMak007__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak007__Symbol_13_0_Layer0_0_FILL"
                transform="translate(153.55 136.15)"
                id="SkiMak007__col1n"
            />
            <use
                xlinkHref="#SkiMak007__Symbol_8_0_Layer0_0_FILL"
                transform="translate(135.55 140.8)"
                id="SkiMak007__col2n"
            />
        </g></g>
    )
}

export default SkiMak007
