import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo002({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#C6965E"
                    d="M124.3 291.6q0-1.642.6-3.05l-48.4-15-.65 1.85L124.3 292v-.4m15.15 1.45q-.35 2.008-1.75 3.55l3.7 1.25q1 .3 1.65.5.05 0 .15.05h.05q.05.05.15.05.048 0 .05.05v-.05q.5 0 1-.3.55-.45.8-1.25.2-.8-.05-1.5-.25-.55-.65-.75l-5.1-1.6M88 296.25l-.1.05 53.8 6.45h.35q.65 0 1.1-.6.45-.55.45-1.4 0-.8-.45-1.4-.45-.6-1.05-.6h-.05l-6.2-.55q-1.742 1.05-3.9 1.05-2.864 0-5-1.85l-35.35-3.2 1.85.1L73 292.4l-.2 2 15.2 1.85z"
                    id="ObjFoo002__Layer7_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M88.85 294.25l.2-.2q2.1-2.25 2.1-5.35 0-3.25-2.3-5.6-1.75-1.7-4-2.15-.75-.15-1.6-.15-3.25 0-5.55 2.3-2.2 2.2-2.35 5.2v.4q0 3.25 2.35 5.55 2.3 2.35 5.55 2.35.85 0 1.65-.15 2.2-.45 3.95-2.2z"
                    id="ObjFoo002__Layer7_1_MEMBER_0_FILL"
                />
                <path
                    fill="#003800"
                    d="M89.55 282.7q.35.3.6.7 1.85 2.25 1.85 5.35 0 .3-.1.7-.05.9-.25 1.75-.05.05-.05.2l-1.15 2.3q-.2.3-.5.6 0 .05-.05.1l-.45.5q-.6.65-1.3 1.1-.15.1-.35.25l.1.05 9.15-3.25q.05-.05.1-.05.6-.5 1.15-1.05 2.25-2.2 2.5-5.25l-11.25-4z"
                    id="ObjFoo002__Layer7_1_MEMBER_1_FILL"
                />
                <g id="ObjFoo002__Layer7_1_FILL">
                    <path d="M85.9 278.85l-4.3 1.3q-1.05.15-1.95.65-1.4.6-2.55 1.75-2.4 2.4-2.55 5.75v.45q0 2.1.85 3.9.65 1.2 1.7 2.25v.05q2.55 2.5 6.15 2.5.85 0 1.65-.1h.15q1.35-.3 2.5-.95.1-.05.25-.15l.1.05 9.15-3.25q.05-.05.1-.05.6-.5 1.15-1.05 2.25-2.2 2.5-5.25.05-.45.05-.9 0-1-.2-1.85l-14.75-5.1z" />
                    <path
                        fill="#002B00"
                        d="M99.15 280.6q-.4-.5-.85-1-2.55-2.5-6.2-2.5h-.35l-1.95.6 9.35 2.9z"
                    />
                </g>
                <g id="ObjFoo002__Layer7_1_MEMBER_0_MEMBER_0_FILL">
                    <path
                        fill="#F93"
                        d="M82.8 289.45l-.1-.1q-.15-.2-.45-1.05 0-.1-.05-.25-.5-1.05-1.6-1.05H80.45q-.9.05-1.2 1.3-.1.55-.1 1.3 0 1.6 1.6 2.9 1.45 1.1 2.75 1.1 1.05 0 1.4-1 .15-.4.15-1-.05-.05-.05-.1-.05-.1-.1-.15-.15-.3-.2-.35-.35-.4-1.3-.75-.4-.25-.6-.8z"
                    />
                    <path
                        fill="#6C3"
                        d="M87.65 290.45q.55-1.05.55-1.6 0-1.15-1.15-2.8-1.2-1.7-2.15-1.7-.45 0-.9.4-.2.15-.35.4-.1.15-.25.35-.2.3-.4.7-.35.7-.35 1.9v.2q0 .65.15 1.15.2.55.6.8.95.35 1.3.75.05.05.2.35.05.05.1.15 0 .05.05.1.3.3.75.3h.1q1.1-.05 1.75-1.45z"
                    />
                    <path
                        fill="#FF0"
                        d="M83.5 284.45q-.3-.1-.6-.1-.95 0-1.55.15-.6 0-1.05 1.05-.5.9.1 1.35l.2.1q1.1 0 1.6 1.05.05.15.05.25.3.85.45 1.05l.1.1q-.15-.5-.15-1.15v-.2q0-1.2.35-1.9.2-.4.4-.7.15-.2.25-.35.15-.25.35-.4l-.05-.05q-.2-.15-.45-.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo002__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo002__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo002__Layer7_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjFoo002__Layer7_1_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjFoo002__Layer7_1_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ObjFoo002
