import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#1E1E1E"
}

function PlaAbs001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M385 385V0H0v385h385z"
                    id="PlaAbs001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaAbs001__Symbol_1_0_Layer0_0_FILL"
                id="PlaAbs001__col1n"
            />
        </g></g>
    )
}

export default PlaAbs001
