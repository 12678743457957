import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E1E1E1",
    "col1n": "#FFFFFF",
    "col2n": "#1E1E1E"
}

function AccHat033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M259.6 48.65q-.05.15-.1.25h-49.95l-7.7-10.7.95-12.35L195 0v.3H64.65l-7.2 26.1v10.5l-7 12H.15q-.05-.1-.1-.25v5.9Q0 54.7 0 54.9v.05q0 .15.05.3 1.35 4.6 38 7.9 4.4.4 9.05.75 14.85 1.15 31.95 1.8 23.35.9 50.8.9 27.45 0 50.8-.9 18-.7 33.6-1.9 3.75-.3 7.35-.65 36.7-3.3 38-7.9v-6.6z"
                    id="AccHat033__Symbol_251_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M135.15 22.15q14.7-1.95 20.4-4.4L150.2 0q-1.9 3.6-21.2 6.1Q107.6 9 77.35 9q-30.3 0-51.7-2.9Q7.7 3.75 4.85.55L0 18.05q5.85 2.3 19.6 4.1 23.95 3.2 57.75 3.2 33.85 0 57.8-3.2z"
                    id="AccHat033__Symbol_74_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat033__Symbol_162_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M64.6 9.3l.1-.9q-.15.3-.15.6 0 .15.05.3M54 47.75h-.05v.05l.05-.05z" />
                    <path d="M175.85 15.35q19.1-2.6 19.1-6.35 0-.45-.25-.85-1.95-3.2-18.85-5.55-19.1-2.6-46.1-2.6t-46.1 2.6Q66.1 5.05 64.7 8.4l-.1.9q.65 3.55 19.05 6.05 19.1 2.65 46.1 2.65t46.1-2.65m83.7 42.3l.05-.5q-.6-4.8-38.05-8.2-7.15-.65-14.9-1.15H53.95v-.05q-8.3.55-15.95 1.2-37 3.35-38 8.1v.6q.45 1.55 4.8 2.95 8.75 2.75 33.2 4.95 5.25.5 10.8.9Q83.45 69 129.8 69q47.3 0 82.45-2.65 4.75-.4 9.3-.8 24.45-2.2 33.2-4.95 4.35-1.4 4.8-2.95z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat033__Symbol_251_0_Layer0_0_FILL"
                transform="translate(62.55 52.3)"
                id="AccHat033__col1n"
            />
            <use
                xlinkHref="#AccHat033__Symbol_162_0_Layer0_0_FILL"
                transform="translate(62.6 43.3)"
                id="AccHat033__col1d"
            />
            <use
                xlinkHref="#AccHat033__Symbol_74_0_Layer0_0_FILL"
                transform="translate(115.15 78.15)"
                id="AccHat033__col2n"
            />
        </g></g>
    )
}

export default AccHat033
