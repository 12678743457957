import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400"
}

function LegSho007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M186.5 23.5q-1.95-3.95-4.85-6.3-1.65-1.35-1.6-1.2l-.1-.1Q168.9 5.7 154.65 2.95q-5.25-1.45-11.5-2.2-13.1-1.45-27.4 0-7 .8-14.2 2.45l-8.3 2.05h-.1v.05l-8.2-2.05Q77.75 1.6 70.75.8q-14.3-1.45-27.4 0-6.25.75-11.5 2.2Q17.6 5.75 6.55 15.95l-.1.1q.05-.15-1.6 1.2-2.9 2.35-4.85 6.3 12.7-7.7 19.65-6.2 5.4 1.35 6.2 6.95 8.45-7.5 16.1-7.05 6.95 1.25 9.2 7.3 4.6-6.95 12.5-7.05 8.1.3 10.95 7.25 2.85-5.65 11.7-8.5.4-.15.75-.2l.3-.1q4.85-.8 5.6-6.15.15-1 .25-1.6.15.8.5 2.3.95 4.65 5.45 5.4l.3.1q.35.05.75.2 8.85 2.85 11.7 8.5 2.85-6.95 10.95-7.25 7.9.1 12.5 7.05 2.25-6.05 9.2-7.3 7.65-.45 16.1 7.05.8-5.6 6.2-6.95 6.95-1.5 19.65 6.2z"
                    id="LegSho007__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho007__Symbol_12_0_Layer0_0_FILL"
                transform="translate(99.25 325.95)"
                id="LegSho007__col1n"
            />
        </g></g>
    )
}

export default LegSho007
