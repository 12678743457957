import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#3A1201",
    "col1n": "#5F1D01",
    "col2l": "#FFD95B",
    "col2n": "#FFC400"
}

function AccJew003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125 10.2q2.3-1.95 4.45-4.05l.7-.7-3.75-5.2-.95.7V.9l-.1.1-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-3.55 2.45-7.25 4.55-17.6 9.9-38.6 11.45l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-21-1.55-38.55-11.45-3.75-2.1-7.3-4.55L9.9 4.5Q8.45 3.45 7.1 2.4L3.8 0 0 4.9q.35.4.75.8 2.3 2.35 4.8 4.5 15 13 37.5 18.4 21.75 4.95 43.9 0 22.7-5.55 38.05-18.4M66.25 24.6v-.05q.05.05.1.15l-.1-.1z"
                    id="AccJew003__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M123.35.25l-.95.7V.9l-.1.1-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-3.55 2.45-7.25 4.55-17.6 9.9-38.6 11.45l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-21-1.55-38.55-11.45-3.75-2.1-7.3-4.55L6.85 4.5Q5.4 3.45 4.05 2.4L.75 0 0 .95 4.05 4.1q1.35 1.05 2.8 2.1L8.4 7.35q3.55 2.45 7.3 4.55 17.55 9.9 38.55 11.45l1.1.05q3.1.2 6.3.2h1.2q3.2 0 6.3-.2l1.1-.05q21-1.55 38.6-11.45 3.7-2.1 7.25-4.55l1.55-1.15q1.45-1.05 2.8-2.1l1.85-1.4.1-.1v.05l1.75-1.3-.8-1.1z"
                    id="AccJew003__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M9.4 2q-.1-.85-.5-1.35-2-1.3-3.75 0-.45.5-.45 1.3.05.8.05 1.6l-.05 6.9q-.05.65-.1 1.25v.1q-1.45.5-2.65 1.65Q0 15.5 0 18.35q0 2.85 1.95 4.85Q4 25.25 6.9 25.25q2.85 0 4.85-2.05 2.05-2 2.05-4.85 0-2.85-2.05-4.9-1.1-1.1-2.4-1.55v-.05H9.3q-.05-.6-.05-1.2l.05-7.1v-.2q.05-.65.1-1.25V2z"
                    id="AccJew003__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M9.3 18.35q0-1.75-1.15-3.05.3 1 .3 2.3 0 1.6-1.05 2.7-1.05 1-2.4 1.1h-.45Q3 21.35 1.9 20.3.85 19.2.85 17.6q0-1.3.3-2.3Q0 16.6 0 18.35q0 1.85 1.3 3.15 1.3 1.3 3 1.35h.5q1.85 0 3.2-1.35 1.3-1.3 1.3-3.15M7.15 2q-.1-.85-.5-1.35-2-1.3-3.75 0-.45.5-.45 1.3.05.8.05 1.6l-.05 6.9q-.05.65-.1 1.25v.1q.1.85.55 1.35.55.6 1.65.7h.1q.15 0 .3.05.1-.05.25 0 .1-.05.2-.05h.1q.15-.1.35-.2l-.25.15q.7-.25 1.05-.65.45-.5.45-1.3h-.05q-.05-.6-.05-1.2l.05-7.1v-.2q.05-.65.1-1.25V2z"
                    id="AccJew003__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew003__Symbol_74_0_Layer0_0_FILL"
                transform="translate(127.2 227.4)"
                id="AccJew003__col1n"
            />
            <use
                xlinkHref="#AccJew003__Symbol_61_0_Layer0_0_FILL"
                transform="translate(130.25 227.4)"
                id="AccJew003__col1d"
            />
            <use
                xlinkHref="#AccJew003__Symbol_23_0_Layer0_0_FILL"
                transform="translate(185.6 247.65)"
                id="AccJew003__col2n"
            />
            <use
                xlinkHref="#AccJew003__Symbol_12_0_Layer0_0_FILL"
                transform="translate(187.85 247.65)"
                id="AccJew003__col2l"
            />
        </g></g>
    )
}

export default AccJew003
