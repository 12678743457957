import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M181.05 32.9l-.3-.3q-2-2.25-4.2-4.45Q148.35 0 108.5 0q-39.85 0-68 28.15-2.35 2.35-4.45 4.75-5.2 5.65-9 11.75L20.9 56q-5 10.55-7.05 22.35-.05.25-.1.45-.35 1.35-.8 4-.65 3.4-1.6 8.85Q10.1 99 8.4 105.8q-.6 2.25-1.25 4.45-2.65 8.85-3.6 11.5-.35.85-.65 1.8-.95 2.85-1.65 5.6-.3 1.35-.55 2.65-1.2 6.5-.3 11.95l.3 1.6q.4 1.7 1 3.35 1.7 4.5 5.1 8.15.55.6 1.15 1.2 3.5 3.4 8.6 5.8 2.3 1.4 5 2.65-.05-.45-.1-.95-.2-2.2-.55-5.4.05.2.15.4l.75 1.95q1.35 2.9 3.6 4.9l.5.4.2.2q.3.2.65.45.6.15 1.2.35 1.2.35 2.45.7 3.7.95 7.9 1.6-.85-1.85-1.55-3.7-2.1-5.25-3.55-10.55-.15-.55-.3-1.15-.45-1.4-.75-2.85-2.6-11.4-2.4-23.5.15-11.45 2.85-23.55.5-2.25 1.1-4.5 3.7-9.3 6.45-18.5.8-2.85 1.55-5.7.45-1.65.85-3.3l.7-3q.2-1.05.45-2.1.55-2.6 1-5.15.1-.6.2-1.15.05-.35.15-.75.05-.4.15-.85 4.65-.3 8.65-1.45Q67 55.55 73.2 42.5q-1.5 3.9-2.3 7.55-.85 3.65-.95 7.15-.15 3.1.25 6.05.4.1.85.2.1 0 .25.05 15.7 2.6 34.65 2.3h1q-.3-.5-.6-.95-.45-.75-.85-1.5-4.3-8.45-1.8-18.85.45 3.3 1.25 6.5 1.2 4.65 3.1 9.05.4.9.85 1.8.7 1.5 1.5 2.9.25.55.55 1.05 14.8 1.15 27.6-.4 3.65-.45 7.2-1.15 1.1-.2 2.2-.45-1.55-1.9-2.85-3.85-2.15-3.2-3.75-6.65-2.35-5.1-3.4-10.8 2.3 4.6 5.55 8.15 9.5 10.45 26.8 11.1.7.05 1.4.05.05.4.1.75.05.1.05.25l.05.25q.05.1.05.25.3 1.75.45 2.85.35 1.3.65 2.6.3 1.05.6 2.15 2.4 9.3 5.4 18.1.2.65.45 1.3 1.95 5.65 4.1 11.05.6 2.25 1.1 4.45.1.65.25 1.25 5.15 24.15.2 45.85-.25 1.15-.55 2.3-1.6 6.15-4 12.2-.75 1.9-1.6 3.75 3.6-.55 6.85-1.3 1.6-.4 3.1-.85h.05q.6-.3 1.25-.95l.25-.25q.15-.2.35-.4 2.1-2.55 4.45-8.2-.75 4.75-1.2 7.4-.1.35-.15.7 6.15-2.45 10.45-5.95 9.8-6.85 11.55-17.6 1.05-6.55-.85-14.6-.7-2.75-1.65-5.6-.3-.95-.65-1.8-.95-2.65-3.6-11.5l-.9-3.2q-.15-.6-.3-1.25-1.75-6.8-3-14.15-.1-.7-.2-1.35-.2-1.15-.4-2.2-.9-12.35-4.7-23.5l-.1-.35q-.1-.35-.2-.65-5.65-16.5-18.35-30.7z"
                    id="HaiMed007__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.65 96.65q-.3-4.85-.25-9.55.05-5.2.55-10.25-.95 5-1.5 10.25-.3 2.95-.45 6-.65 10.35.25 21.55l.3 3.3q0 .1.05.15.05.2.15.4l.75 1.95q1.35 2.75 3.55 4.7l.5.4.2.2q.3.2.65.45l-.1-.4q-.1-.35-.15-.65-1.35-5.3-2.3-10.5-.35-1.95-.65-3.9l-.05-.15q-1.1-7.15-1.5-13.95m-2.8-37.8Q8.8 61.3 7.9 63.6q-1.85 4.65-3.25 8.8Q1.8 80.75.7 87.1q-2.4 13.75 3.4 18.25Q2 98.5 3.4 87.1q.6-5.15 1.95-11.25 1.2-5.75 3.1-12.25.65-2.3 1.4-4.75m18.9-46.35q-2.7 2.2-5.5 4.2-.8.55-1.65 1.1 1.6 3.45 11.4 2.65.85-.05 1.75-.15l.8-.1q.05-.35.15-.75.05-.4.15-.85-.1 0-.15.05-.75.05-1.45.15-5.85.5-9.4-1.2l1.9-1.2q1.8-1.25 3.5-2.6 4.75-4 8.15-9.15Q39.9 2.4 41.1 0q-5.75 6.95-12.35 12.5m164.8 61.4q-.25-.7-.45-1.35.2.8.4 1.65 4.65 21.7-.4 37.2 9.3-9.7.45-37.5m-12.5-25.85q-1.2-2.95-2.45-5l3.65 15.45q.6 2.55 1.1 5.1.1.6.25 1.25 2.95 16.3 1.6 33.1-.55 6.45-1.75 13-1.3 7.05-3.4 14.2l-.2.8q-.15.4-.25.8h.05q.6-.3 1.25-.95l.25-.25q.15-.2.35-.4 2.1-2.5 4.45-8.15 1.8-11.65 1.75-24.35-.1-11.25-1.6-23.3-.35-2.35-.75-4.5-.1-.65-.2-1.25-1.85-9.65-4.1-15.55m-13.1-33.2q1.7 1.35 3.5 2.6l1.9 1.2q-3.55 1.7-9.4 1.2-.7-.1-1.45-.15-.05-.05-.15-.05 0 .05.05.1.05.4.1.75.05.1.05.25l.05.25q.05.1.05.25l.8.1q.9.1 1.75.15 9.8.8 11.4-2.65-.85-.55-1.65-1.1-2.8-2-5.5-4.2-6.6-5.55-12.35-12.5 1.2 2.4 2.7 4.65 3.4 5.15 8.15 9.15z"
                    id="HaiMed007__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed007__Symbol_63_0_Layer0_0_FILL"
                transform="translate(83.95 57.6)"
                id="HaiMed007__col1n"
            />
            <use
                xlinkHref="#HaiMed007__Symbol_34_0_Layer0_0_FILL"
                transform="translate(93.25 99.65)"
                id="HaiMed007__col1d"
            />
        </g></g>
    )
}

export default HaiMed007
