import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#EEEEEE",
    "col1s": "#AFAFAF",
    "col2n": "#666666"
}

function AccMor006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 76.55L28.4 91.7l9.55-17.95v-.05L9.55 58.55 0 76.55M58.7.05q.55.25.55.2-.15-.15-.3-.2-.15-.05-.25 0z"
                    id="AccMor006__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 1.55q0 .65.45 1.1.45.45 1.1.45.65 0 1.1-.45.45-.45.45-1.1 0-.6-.45-1.05Q2.2 0 1.55 0 .9 0 .45.5 0 .95 0 1.55z"
                    id="AccMor006__Symbol_15_0_Layer0_0_FILL"
                />
                <g
                    id="AccMor006__Symbol_37_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M29 80.8h-.1L10 82.25.6 65.65l-.6 1.1L9.4 83.4l19-1.5.6-1.1zM37.2.75q-.1 0-1.1-.75 9.6 44.75-8.2 80.2l1.1.6Q47.75 41.85 37.2.75z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccMor006__Symbol_104_0_Layer0_0_FILL"
                transform="translate(96.25 242.05)"
                id="AccMor006__col1n"
            />
            <use
                xlinkHref="#AccMor006__Symbol_37_0_Layer0_0_FILL"
                transform="translate(105.2 234.95)"
                id="AccMor006__col1s"
            />
            <use
                xlinkHref="#AccMor006__Symbol_15_0_Layer0_0_FILL"
                transform="translate(116 311.8)"
                id="AccMor006__col2n"
            />
        </g></g>
    )
}

export default AccMor006
