import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFEAAD",
    "col1n": "#FFC400"
}
function FacMou098({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M14.3.45Q12.8 0 11.25 0 9.7 0 8.2.45 5.1 1.9 3.25 4.6q-.1.1-.15.25-1.05 1.6-1.7 3.7-2.8 8.75 0 16.45.35 1 .8 1.95.3.65 2.3 4.5 1.95 3.8 4.3 8.6 1.55 3.05 2 4 .25.3.45.55.2-.25.45-.55.45-.95 2-4 2.2-4.5 4.05-8.1.15-.25.25-.5 2-3.85 2.3-4.5.45-.95.8-1.95 2.8-7.7 0-16.45-.65-2.1-1.7-3.7-.05-.15-.15-.25-.65-1-1.5-1.8Q16.3 1.4 14.3.45z"
                    id="FacMou098__Symbol_161_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M5.45 7.95Q5 3 3.75.7 3.4.3 3.1.1 3.05.05 3 .05 2.9 0 2.75 0T2.5.05q-.05 0-.1.05-.3.2-.65.6Q.5 3 .05 7.95-.05 10.2 0 13.2q.1 2 .25 4.5v.4q.2 2.5.3 4.4.01.2 0 .4H.6q.05 1.5.25 3.05.1 1 .25 2.35.1.85.2 1.85.3 2.45 1.45 5.2 1.15-2.75 1.45-5.2.1-1 .2-1.85.15-1.35.25-2.35.2-1.55.3-3.05H4.9q.04-.2.05-.4.1-1.9.3-4.4v-.4q.15-2.5.25-4.5.05-3-.05-5.25z"
                    id="FacMou098__Symbol_162_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou098__Symbol_161_0_Layer0_0_FILL"
                transform="translate(181.25 195.15)"
                id="FacMou098__col1n"
            />
            <use
                xlinkHref="#FacMou098__Symbol_162_0_Layer0_0_FILL"
                transform="translate(189.75 196.8)"
                id="FacMou098__col1l"
            />
        </g></g>
    )
}
export default FacMou098
