import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.7 1.3l-.1-.05L44.25 0 33.2 2.85q-1 0-1.6.95 0 .05-.05.1-.2.85-1.2.85-.8 0-1.75-.9-.8.45-1.4 2-.3.35-1.5 1.5 0-.4-.1-.75-.2-.75-.65-.75-2.5 1.95-3.85 3.05-.1-.25-.3-.5-.75-1.2-1.6-1.2-1.3 0-4.85 3.05-1.6-1.3-2.85-1.3-.95 0-3.9 3.9v-.05q-.2-2.05-1.2-2.2-.25.25-.6.5-2.6 1.85-3.2 2.4-.5.5-1.7.95-.45.35-.7.75-.15.15-.2.35v.2q.1 1.45 0 3v1.15h.15q1.1-.1 2.7-.1 1.45-2.05 2.4-2.9 1.05 1 2.2 1 .25 0 .45-.1h.25q1.55-1.6 1.8-1.85l1.2-.9.3-.1q.45.3 1.3.4 0 .05.05.05h1.15l.1-.05q.5-.25 1.15-1.05l.2-.1q.25-.3.45-.5.65-.6 1.55-1.4.45-.25 1-.25.45 0 .9.4l1.35-.6.55-.55q2-1.9 2.6-1.9.5.25 1.1.4.45-.7 1.55-.9.75-.15 1.25-.9.45-.6 1.35-.6.25.25.5.45l1.8-.8.85-.9q.35-.4.55-.5l.25-.1h1.55L41.7 1.3m43.85 9.6l-.2-.1q-1.5-.9-1.95-1.35-3.35-2.65-3.95-2.65-1.35 0-1.55 2.05-1.35-1.05-3.8-3-.45 0-.65.75-.1.35-.1.75-1.2-1.15-1.5-1.5-.6-1.55-1.4-2-.95.9-1.75.9-1 0-1.2-.85-.05-.05-.05-.1-.6-.95-1.6-.95L54.8 0l3 1.35q-.25-.1-.45-.05l7.15 4.25h1.55l.25.1q.2.1.55.5l.9.9 1.75.8q.25-.2.5-.45.9 0 1.35.6.5.75 1.25.9 1.1.2 1.55.9.6-.15 1.1-.4.6 0 2.6 1.9.3.3.6.55l1.3.6q.45-.4.9-.4.55 0 1 .25.9.8 1.55 1.4.2.2.45.5l.2.1q.65.8 1.15 1.05l.1.05H86.3l.05-.05q.8-.1 1.25-.4l.3.1 1.2.9q.25.25 1.8 1.85h.3q.15.1.4.1 1.15 0 2.25-1 .8.7 1.95 2.35.05.1.1.35.45.55 2.1.55 2.3 0 2.3-1.7v-.85q-1.7-.65-3.85-2.8-2.1-1.75-2.2-1.75-2.75 0-2.9 1.1-1.2-2.15-2.05-3.25-.9-.85-1.15-1-.15-.1-.25-.2-.85.05-2.25 1.1l-.1.1z"
                    id="FacBro018__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro018__Symbol_18_0_Layer0_0_FILL"
                transform="translate(142.35 121.85)"
                id="FacBro018__col1n"
            />
        </g></g>
    )
}

export default FacBro018
