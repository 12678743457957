import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E92424",
    "col1n": "#FF5D5D",
    "col2n": "#FFE69A"
}

function ObjToy049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.05 27l-15.8-10.1L26.6 0q.9.8 1.55 1.75L17.2 16.45q-.1-.1-.2-.15L0 39q.55-.6 1.2-1.1l15.45 10.2L39.7 65.25q-.95-.85-1.65-1.8L65.7 26.3q-.3.35-.65.7z"
                    id="ObjToy049__Symbol_133_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M60.45 33.25q5 .1 8.3-3.5l.5-.7Q71.8 24.3 65.9 0q-6.05 10.65-13.6 20.35-4.9 6.4-11.55 13.4h-.05L39.4 35l-.2-3.9q-.3.1-1.1.55-.75.3-1.75 1.1l-2.9-3.3-.25-17.7q0-5-3.55-8.3l-.7-.45Q24.15.45 0 6.35q10.35 5.9 20.05 13.4 7.1 4.6 13.45 11 .4.4.95.9.55.55 1.3 1.45-.55.5-1.05.85-.45.45-.8.65-.3.25-1.25 1.1-1.05.85-3 2.8l-18.3.3q-4.95 0-8.3 3.65l-.5.65Q0 47.9 6 72.15q6.05-10.75 13.7-20.6l-.05-.05q4.9-7.25 11.1-13 1.35-1.3 2.85-2.6.05 0 .1-.05 1.3-1.05 2.55-2.05h.05q.35-.2.6-.4.85-.65 1.75-1.3l.55 28.35q-.05 5.05 3.55 8.25l.7.5q.5.3 1.25.5.65.15 1.55.25h.3q.7 0 1.4.1h.2q.2-.05.35-.1.5.05 1.1 0 7.6-.3 22.9-4.15Q61.85 59.9 52 52.2q-6.45-4.95-12.5-10.6l-.1-5.85q1.2-1 2.35-2.1l18.7-.4z"
                    id="ObjToy049__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.4 70.4q.3 1.4.3 2.8 0 .15-.05 3.1-.45 2.25-2.15 2.75l4.45 13.25 4.35-1.45-6.9-20.45M5 1.2q-.7-.1-1.4-.1h-.3Q2.4 1 1.75.85 1 .65.5.35L0 0l19.25 57.4q.95.65 1.95 1.55 4 3.6 5.85 7.4l-5-14.95-.05-.05L5.2 1.2H5z"
                    id="ObjToy049__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M91.95 197.5q-.6-.3-1.2-.1-.55.2-.85.8-.25.55-.05 1.15.2.55.75.85h.05q.1.1.3.1.35.1.75-.05.35-.1.55-.25.25-.2.4-.5l-.05-.05q.3-.5.1-1.05-.2-.6-.75-.9z"
                    id="ObjToy049__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy049__Symbol_133_0_Layer0_0_FILL"
                transform="translate(58.05 165.45)"
                id="ObjToy049__col1n"
            />
            <use
                xlinkHref="#ObjToy049__Symbol_82_0_Layer0_0_FILL"
                transform="translate(55 162)"
                id="ObjToy049__col1d"
            />
            <use
                xlinkHref="#ObjToy049__Symbol_45_0_Layer0_0_FILL"
                transform="translate(97.95 230.85)"
                id="ObjToy049__col2n"
            />
            <use xlinkHref="#ObjToy049__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToy049
