import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou079({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.3.7q-.15 2.85-2.2 4.9-2.25 2.25-5.4 2.25-3.2 0-5.4-2.25-1.35-1.3-2.35-3.2-.15-.25-.4-.35-.25-.1-.5 0t-.35.35q-.95 1.95-2.25 3.2-2.25 2.25-5.4 2.25-3.2 0-5.4-2.25Q1.55 3.55 1.4.7q0-.3-.2-.5Q.95 0 .7 0 .4 0 .2.25q-.2.2-.2.5.15 3.4 2.65 5.85 2.6 2.65 6.4 2.65 3.75 0 6.4-2.65 1.05-1.05 1.9-2.45.9 1.4 2 2.45h-.05q2.6 2.65 6.4 2.65 3.75 0 6.4-2.65 2.45-2.45 2.6-5.85 0-.3-.15-.5-.25-.25-.5-.25-.3 0-.5.2-.25.2-.25.5z"
                    id="FacMou079__Symbol_104_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou079__Symbol_104_0_Layer0_0_FILL"
                transform="translate(175.2 217)"
                id="FacMou079__col1n"
            />
        </g></g>
    )
}

export default FacMou079
