import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1n": "#252525",
    "col2d": "#747474",
    "col2l": "#BFBFBF",
    "col2n": "#999999",
    "col2s": "#434343"
}

function ObjToo060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M9.2 34.05l.05.05q0-.048.05-.05h-.1M23.7 7.5q1.55-.65 2.5-.9.9-.35 2.3-.55l-.3-1.9q-2.05-.35-3-1.05-.65-.5-1.1-1.25-.2-.8-.25-1.6-11.3-1.2-22 3.65-2.4 4-1.65 8.2v.5q0 .9.85 3.6.6 1.95 1 2.9.7 1.95 1.25 2.95.1-.05.25-.1 1.1-.35 1.45-.4h.2q.95-.3 1.35-.35 4.3-.15 6.15 3.85 1.15 2.45 1.15 5.65 0 2.1-1.15 3.9-1.05 1.6-2.45 1.85l-.05.05 1.05 2.5q1.15 2.3 4.2 2l17.15-2.85q1.55-2.45.05-6.55l-4.7-7.95q-2 1.55-4.65 1.55-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4 1.85-1.85 4.3-2.15.3-1.55 1.5-2.5z"
                    id="ObjToo060__Symbol_219_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22.7 34.6q-1.05 1.6-2.45 1.85l-.05.05 1.05 2.5q1.15 2.3 4.2 2l.3-.05-2.85-6.65-.2.3m-3.5-.55l.05.05q0-.048.05-.05h-.1M0 12.65q1.05.65 1.45 3.25.25 1.5.05 2.65v.1q-.75 1.95 1.7 0 .85-1.05 1.35-2 1-1.9.9-4.2-2.5-2.45-3.8-1.15Q.3 12.55 0 12.65M32.2 10q.3-1.55 1.5-2.5 1.55-.65 2.5-.9.9-.35 2.3-.55l-.3-1.9q-2.05-.35-3-1.05-.65-.5-1.1-1.25-.2-.8-.25-1.6-11.3-1.2-22 3.65-2.4 4-1.65 8.2v.5q0 .9.85 3.6.6 1.95 1 2.9.7 1.95 1.25 2.95.1-.05.25-.1 1.1-.35 1.45-.4h.2q.95-.3 1.35-.35h.65l-3.95-9.05 14.45-2.4.9 1.8q1.6-1.3 3.6-1.55m10.4 28.15q1.55-2.45.05-6.55l-4.7-7.95q-1.15.9-2.55 1.3l6.75 13.3.45-.1z"
                    id="ObjToo060__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M83.95 8.7l-.65-4-4.8-3.05-3.25.55Q74.8.15 74.75.05q-1.5-.2-2.6 1-.25.3-1.25 1.85L55.4 5.5v.75l-44.05 7.4-2.15-1.5-5.15.85-1.25 2.2-2.8.5.95 5.55 2.8-.5.75 4.75 6.7-1.1.4 2.1q7.95-1.55 8.05-1.4 6.25-1.05 7.45-1.15 4.05-.25 7.7.75 2 .6 3.8 2.4 1.7 1.75 1.95 3.25.1.65-.05 1.2-.1.35-.5 1.45.95.2 1.75.3 2.75.55 3.85.65 2.3.25 4.6-.15.15 0 2.9-.5 1.95-.35 3.55-.6.25-.2.6-.2h.3q.45-.05.85-.15h.05q.3-.15.75-.3.4-.2 1.15-.3.75-.1 2.2-.75 1.4-.7 1.7-.75l-3.8-9.05 1.5-8.85 13.35-2.2v-.05h.05q.15 0 .3-.05v.05l8.3-1.4m-33.3 12.25q2.8-.5 4.9-.05 1.55.3 2.7 1.05 2.2 1.4 2.65 3.95.35 2.15-1.7 3.7-1 .75-2.3 1.25-.25.05-.45.05-5.1.85-8.65.35-4.7-.65-5.2-3.55-.85-5.05 7.5-6.6.3-.1.55-.15z"
                    id="ObjToo060__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M75.7 0L52.6 3.85v2.3l-42.25 7.1L6.4 10.5l-5.15.85L0 13.55l.5 2.85 80-13.35L75.7 0M58.05 17.05l.85-4.9-50.8 8.5.3 2.1 20.1-3.35q4.55-.1 6.85 1.45l22.7-3.8z"
                    id="ObjToo060__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M58.4 7.9l.25-1.45-51.3 8.6-.65-4-6.7 1.1 1.2 7.45 6.7-1.1-.3-2.1 50.8-8.5m1.7-1.7l-.65-4-1.45.25.65 4 1.45-.25m1.1-4.25l.65 4 2-.35-.65-4-2 .35M67.4 5l-.65-4-1.95.35.65 4L67.4 5m3.35-.55l-.65-4-1.65.25.65 4 1.65-.25m1.6-.25L73.8 4l-.65-4-1.45.2.65 3.95v.05z"
                    id="ObjToo060__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M6.7 0L0 1.1l.25 1.35 6.7-1.1L6.7 0z"
                    id="ObjToo060__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo060__Symbol_219_0_Layer0_0_FILL"
                transform="translate(108.65 274.05)"
                id="ObjToo060__col1n"
            />
            <use
                xlinkHref="#ObjToo060__Symbol_141_0_Layer0_0_FILL"
                transform="translate(98.65 274.05)"
                id="ObjToo060__col1d"
            />
            <use
                xlinkHref="#ObjToo060__Symbol_93_0_Layer0_0_FILL"
                transform="translate(48.55 265.6)"
                id="ObjToo060__col2n"
            />
            <use
                xlinkHref="#ObjToo060__Symbol_47_0_Layer0_0_FILL"
                transform="translate(51.35 267.25)"
                id="ObjToo060__col2l"
            />
            <use
                xlinkHref="#ObjToo060__Symbol_22_0_Layer0_0_FILL"
                transform="translate(51.85 271.5)"
                id="ObjToo060__col2d"
            />
            <use
                xlinkHref="#ObjToo060__Symbol_7_0_Layer0_0_FILL"
                transform="translate(52.5 286.55)"
                id="ObjToo060__col2s"
            />
        </g></g>
    )
}

export default ObjToo060
