import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1n": "#D19532",
    "col1s": "#644D27"
}

function ObjMus025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M98.5 87.3q1.3-.85 2.25-2t1.5-2.5l.3-.8q.5-1.4.75-3.05-4.4-1.8-7.95-3.95-2.25-1.5-4.15-3.15l-51.15-43.8q-.1-.75-.35-1.5-.15-.55-.45-1.1-.3-.4-.65-.75-.95-1.05-2.7-1.85-3.85-1.9-7.2-4.55-1.25-.95-2.4-2-1.5-1.35-3-2.85l-5.55-6.4q-.1-.2-.25-.3Q12.5-.4 6.45 0 2.4 3.15 0 7.75q.7 6 8.55 9.7.15.05.35.2l7.35 4.25q1.75 1.2 3.3 2.45 1.25 1 2.4 2 3.2 2.9 5.8 6.25 1.05 1.65 2.2 2.4.45.25.95.45.55.25 1.15.3.75.15 1.55.05l35.05 28.6q.15-.4 2.95.8 2.8 1.2 3.55 3t.85 2.2l9.75 7.95q2.05 1.6 3.9 3.55 2.7 3.05 5.35 7 1.75-.55 3.1-1.4l.4-.2z"
                    id="ObjMus025__Symbol_109_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M8.55 0Q7.75.65 7 1.3q-.05 0-.05.05-2.1 1.95-3.4 3.55-1.15 1.35-2.1 2.85Q.65 9 0 10.25l.75.45Q1.4 9.6 2.2 8.4q.45-.65.9-1.35.55-.8 1.4-1.7 1.1-1.3 3.05-3.25.8-.75 1.55-1.45L8.55 0m3.5 11.6q.9-.9.8-2.5l-4.2-3.5q-3.1 1.1-3.1 3.7l4.2 3.5q1.55-.35 2.3-1.2m18 8.45q-.15.1-.3.3l-.5.4q-.05.05-.1.05-.05.1-.1.15l-2.1 2.1-2.65 3.55q-.1.15-.25.35l-.3.55q-.1.15-.15.3l.7.6.05-.05q0-.15.1-.25l.05-.05q.5-.9 1.05-1.65l2.4-3.05q.8-.85 1.7-1.7l.1-.1q.05-.1.1-.15h.05l.6-.5q.1-.15.25-.2v-.05l-.7-.6M92.55 74q.5-1.4.75-3.05-.15.2-.2.35-1.1 2.05-2.45 3.9l-2 2.35q-1.6 1.7-3.55 3.2.05 0 .15-.05-.15.15-.25.2 1.75-.55 3.1-1.4l.4-.2q1.3-.85 2.25-2t1.5-2.5l.3-.8z"
                    id="ObjMus025__Symbol_83_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M63.2 53.45q-.05 0-.1.05-.4.45-.4 1.05.05.6.5.95.4.35.95.25.6-.1 1-.55.05-.05.05-.1.35-.45.35-1-.05-.55-.45-.9-.45-.35-1-.3-.5.1-.9.55m9.25 7.85q-.4-.35-1-.25-.6.1-1 .55-.4.5-.35 1.1 0 .55.4.9t1 .3q.55-.1.95-.6.4-.45.4-1.05 0-.6-.4-.95M35.9 30.45l-.1.1q-.3.4-.25.95-.05.6.4.95.4.35 1 .25.55-.1.85-.45l.1-.1q.4-.5.4-1.1 0-.55-.4-.9-.45-.35-1-.3-.6.1-1 .6M0 3.7l1.85 1.6 3.1-3.7L3.1 0 0 3.7m31.25 20.9q-.4-.35-1-.3-.6.1-1 .6l-.1.1q-.25.45-.25.95 0 .6.4.95.45.35 1.05.25.5-.1.8-.45l.1-.1q.4-.5.45-1.1 0-.55-.45-.9M55.8 48.35q0 .6.45.95.4.35 1 .25.45-.1.8-.4l.15-.15q.4-.5.4-1.1 0-.6-.4-.95-.45-.35-1.05-.25-.55.1-.95.6l-.15.15q-.25.4-.25.9m-7.15-6q0 .6.4.95t1 .25q.45-.1.8-.45.1-.05.15-.1.4-.5.4-1.1 0-.6-.4-.95t-1-.25q-.6.1-1 .6l-.15.15q-.25.4-.2.9m-4-6.55q-.05-.2-.3-.4-.4-.35-1-.25-.6.1-1 .6l-.15.15q-.25.4-.2.9 0 .6.4.95.25.2.45.2.3.1.55.05.45-.1.8-.4.15-.1.2-.15.4-.5.35-1.1.05-.25-.1-.55z"
                    id="ObjMus025__Symbol_57_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus025__Symbol_109_0_Layer0_0_FILL"
                transform="translate(184.75 219.6)"
                id="ObjMus025__col1n"
            />
            <use
                xlinkHref="#ObjMus025__Symbol_83_0_Layer0_0_FILL"
                transform="translate(194.75 227.6)"
                id="ObjMus025__col1d"
            />
            <use
                xlinkHref="#ObjMus025__Symbol_57_0_Layer0_0_FILL"
                transform="translate(198.45 231.6)"
                id="ObjMus025__col1s"
            />
        </g></g>
    )
}

export default ObjMus025
