import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.85 6.5q1-.4 2.05-.95-5.3-.6-9.4-3-1.9-1.1-3.7-1.7-4.1-1.7-8.6 0l-.4.2q-1.5.8-2.35 1.65-.85-.85-2.35-1.65-.2-.1-.35-.2-4.25-1.65-8.7 0-1.75.6-3.65 1.7-4.1 2.4-9.4 3 1.05.55 2.05.95 2.05.65 3 1Q8.4 8.7 11 12.1q.35.45.7.95 1.15 1.75 3.55 2.75.5.2 1.05.35 8.25 2.85 16.35 0 .5-.15 1-.35 2.4-1 3.55-2.75.35-.5.7-.95 2.6-3.4 5.95-4.6.95-.35 3-1z"
                    id="FacMou043__Symbol_68_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M20.85 1.7q1.9-1.6 0-1.65-.4-.05-.95 0-.2.05-.35.05Q10.6.8 2.3.1q-.2 0-.4-.05-3.25-.3-.95 1.65 2.15 1.7 5.95 2 3.8.25 4 .15.2.1 4-.15 3.8-.3 5.95-2z"
                    id="FacMou043__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou043__Symbol_68_0_Layer0_0_FILL"
                transform="translate(168.05 214.3)"
                id="FacMou043__col1n"
            />
            <use
                xlinkHref="#FacMou043__Symbol_21_0_Layer0_0_FILL"
                transform="translate(181.6 223.2)"
                id="FacMou043__col1l"
            />
        </g></g>
    )
}

export default FacMou043
