import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col2d": "#9C3AFF",
    "col2n": "#B46BFF"
}
function BodHan003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.8 1.55q.2-.3.2-.6Q2-.15 1 0 0 .1 0 .95q0 1 1 1 .5 0 .8-.4z"
                    id="BodHan003__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1 1.95q.5 0 .8-.4.2-.3.2-.6Q2-.15 1 0 0 .1 0 .95q0 1 1 1z"
                    id="BodHan003__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M30.55 9.25q.35-.75.35-1.65 0-1.6-1.1-2.7-1.1-1.05-2.65-1.05-1.6 0-2.75 1.05-.1.1-.2.25.1-.45.1-1 0-1.6-1.1-2.7Q22.1.4 20.55.4q-1.6 0-2.75 1.05-.7.75-.95 1.75-.15-1.25-1.05-2.15Q14.7 0 13.15 0q-1.65 0-2.8 1.05-1.05 1.1-1.05 2.7v.2q-.2-.35-.55-.7Q7.65 2.2 6.1 2.2q-1.6 0-2.75 1.05-1.05 1.1-1.05 2.7 0 .7.2 1.3l.05.1q.25.7.8 1.25l.15.15q.9 1.55 1.1 3.2v-.1l.25 1.05q-.05 0-.1.05-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3Q0 18.3 0 23.9q0 6.2 4.45 10.6 4.4 4.4 10.65 4.4 2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.15-3.15 4.05-7.15 0-.15.05-.3l.35-1.25q.75-.5 1.4-1.2 1.95-1.95 1.95-4.7 0-1.75-.75-3.2-.5-.85-1.2-1.55-1.65-1.6-3.8-1.85h-.15q1.05-2.05 1.45-2.35.15-.2.35-.35.2-.15.35-.35.5-.45.75-1m141.95-2q.2-.6.2-1.3 0-1.6-1.05-2.7-1.15-1.05-2.75-1.05-1.55 0-2.65 1.05-.35.35-.55.7v-.2q0-1.6-1.05-2.7Q163.5 0 161.85 0q-1.55 0-2.65 1.05-.9.9-1.05 2.15-.25-1-.95-1.75Q156.05.4 154.45.4q-1.55 0-2.65 1.05-1.1 1.1-1.1 2.7 0 .55.1 1-.1-.15-.2-.25-1.15-1.05-2.75-1.05-1.55 0-2.65 1.05-1.1 1.1-1.1 2.7 0 .9.35 1.65.25.55.75 1 .15.2.35.35.2.15.35.35.4.3 1.5 2.35h-.2q-2.15.25-3.8 1.85-.7.7-1.15 1.55-.8 1.45-.75 3.2-.05 2.75 1.9 4.7.75.75 1.65 1.25l.1 1.2-.05-.05q0 .1.05.2.85 4.1 4.05 7.3 1.35 1.35 2.9 2.3.05 0 .1.05t.15.1q.35.2.75.4.35.2.75.35l.2.1h.05q.05.05.15.1.1 0 .2.05 2.5.95 5.4.95 6.25 0 10.65-4.4t4.45-10.6q-.05-3.95-1.85-7.2-.75-1.35-1.8-2.55-.15-.15-.25-.3l-.1-.1q-.25-.25-.45-.5-.15-.1-.25-.2V13l-.1-.1.25-1.05v.1q.2-1.65 1.1-3.2l.15-.15q.55-.55.8-1.25l.05-.1z"
                    id="BodHan003__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M20.95 4.15l-.7-.7Q16.2-.05 10.55 0 4.65-.05.3 3.8l-.15.15L0 4.1v1.3l1-.9Q5.05.95 10.55 1 15.8.95 19.6 4.2q.15.1.5.45-.65.15-1.25.45-1.1.5-2.05 1.5-2.25 2.25-2.3 5.45.05 3.2 2.3 5.45 1.7 1.7 3.9 2.1l1.5.15h.1l.75-.05q1.25-.15 2.25-.55l.3-.1q1.2-.55 2.15-1.55 2.3-2.25 2.3-5.45t-2.3-5.45Q26.1 4.95 23.9 4.5q-.2-.05-.45-.1h-.05q-.2-.05-.45-.05-.15-.05-.4 0-.15-.05-.35 0h-1.05q-.05-.1-.2-.2m-.1 1.35q.5-.1 1-.15H22.3q.45 0 .85.1h.05q2.15.25 3.8 1.85 1.95 1.95 1.9 4.75.05 2.75-1.9 4.7-.9.9-1.95 1.4-.9.45-2 .55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-1.95-1.95-1.95-4.7 0-2.8 1.95-4.75 1.45-1.45 3.3-1.8m144.8-1.55l-.15-.15Q161.15-.05 155.25 0q-5.65-.05-9.7 3.45l-.7.7q-.15.1-.2.2h-1.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05h-.05q-.25.05-.45.1-2.2.45-3.85 2.1-2.3 2.25-2.3 5.45t2.3 5.45q.95 1 2.15 1.55l.3.1q1 .4 2.25.55l.75.05h.1l1.5-.15q2.2-.4 3.9-2.1 2.25-2.25 2.3-5.45-.05-3.2-2.3-5.45-.95-1-2.05-1.5-.6-.3-1.25-.45.35-.35.5-.45Q150 .95 155.25 1q5.5-.05 9.55 3.5l1 .9V4.1l-.15-.15m-23.05 1.5h.05q.4-.1.85-.1H143.95q.5.05 1 .15 1.85.35 3.3 1.8 1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-1.45 1.45-3.35 1.85l-1.3.15h-.1l-.75-.05q-1.1-.1-2-.55-1.05-.5-1.95-1.4-1.95-1.95-1.9-4.7-.05-2.8 1.9-4.75 1.65-1.6 3.8-1.85z"
                    id="BodHan003__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan003__Symbol_45_0_Layer0_0_FILL"
                transform="translate(130 290.65)"
                id="BodHan003__col1n"
            />
            <use
                xlinkHref="#BodHan003__Symbol_46_0_Layer0_0_FILL"
                transform="translate(134.8 290.65)"
                id="BodHan003__col1d"
            />
            <use
                xlinkHref="#BodHan003__Symbol_4_0_Layer0_0_FILL"
                transform="translate(105.05 271.7)"
                id="BodHan003__col2n"
            />
            <use
                xlinkHref="#BodHan003__Symbol_5_0_Layer0_0_FILL"
                transform="translate(109.65 279.55)"
                id="BodHan003__col2d"
            />
        </g></g>
    )
}
export default BodHan003
