import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M168.4 42.65q1.65 2.4 3.1 5.05.25.45.55.9.9 1.45 1.5 3 1.3 3.35 1.25 7.3v.45q-.05 1-.15 2.05-.1 1-.25 2-.05.15-.05.3-.1.25-.15.55l-1.25 9.9q.1-.3.2-.55l-.65 5.15q-.4 2.65-.7 5.15l11.95 28 7.5-4.05q.25-1.75.45-3.5 1.05-9.75-.2-19.45-.6-5.55-1.9-11.15-1-4.55-2.3-8.8l-3.75-9.55q-6.35-13.4-17.2-24.95l-.05-.05q-1.1-1.15-2.2-2.25-1.3-1.3-2.65-2.55-22.2-20.75-51.4-24.65-6.8-.95-14-.95-19.1 0-35.5 6.45Q44.3 12.85 30.7 25.6q-.35.3-.65.6l-.9.9q-.55.5-1.1 1.05-1.1 1.1-2.2 2.25-10.2 10.8-16.4 23.3-4.4 8.6-6.75 18.8Q1.3 78.7.65 84.9q-1.25 10.2 0 20.5.1 1.2.3 2.45l7.5 4.05 11.95-28q-.3-2.5-.7-5.15l-.65-5.15q.1.25.2.55L18 64.25q-.05-.3-.1-.55l-.1-.3q-.15-1-.25-2-.1-1.05-.15-2.05v-.55q-.05-2.45.5-4.65l.5-1.85q.05-.2.15-.4.55-1.3 1.25-2.45.1-.2.2-.35 2.15-3.5 4.25-6.45-1.9 2.55-3.8 5.55-.25.45-.55.9-.35.6-.7 1.25.45-.9.95-1.75.3-.45.55-.9 8.25-14.95 22.85-21.2 5.75-1.65 11.5.5 2.8 1.1 5.5 2.15.9.3 1.8.65 1.15.4 2.3.85l5.15 1.7q13.35 5.25 26.3 5.4 6.9-.1 13.95-1.6 6.15-1.35 12.35-3.8l5.15-1.7q1.15-.45 2.3-.85 3.55-1.3 7.3-2.8 5.75-2.15 11.5-.5 5 2.15 9.25 5.3 5.95 4.4 10.5 10.85z"
                    id="HaiSho019__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M19.7 59.1L7.25 84.8 0 81.2q.1 1 .25 1.95l7.5 4.05 11.95-28v-.1M42.85 1.8q4.15-1.2 9.7-.1-9.65-4.9-19.7 4.2-6.627 5.94-13.4 18 .3-.45.55-.9 5.5-10 13.9-16.1 4.15-3.05 8.95-5.1M95.4 13.05q6.9-.1 13.95-1.6 5.95-1.3 12-3.65-6.1 1.55-12 2.45-21 3.1-39.85-2.45 13.15 5.1 25.9 5.25M137.05 2.1q6-1.7 10.9-.3 5 2.15 9.25 5.3 5.95 4.4 10.5 10.85-4.45-6.85-8.8-11.05-.55-.55-1.05-1-2.3-2.05-4.55-3.4-3.35-2-6.65-2.4-4.3-.55-9.6 2m53.8 78.75l-6.95 3.7-12.8-25.35 11.95 28 7.5-4.05.3-2.3z"
                    id="HaiSho019__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M164.75 32.2q-.55-.6-1.05-1.15-1.35-1.45-2.8-2.9Q137.8 5.1 106.85.95q-6.8-.95-14-.95-19.1 0-35.5 6.45-17.85 7.05-32.5 21.7-1.55 1.55-3 3.15-6.05 6.5-10.6 13.6l-.7 1.1q-.5.8-.95 1.6l-.1.15q-.15.15-.2.35l-.2.3-.45.8q-.3.45-.55 1l-.1.15-.05.15q-.45.75-.85 1.55-.45.8-.85 1.65l-.15.35q-.15.2-.2.4l-.05.05Q2.95 60.3 1 66.9l-.05.05q-.05.15-.1.35Q.4 68.85 0 70.45q.5-1.6 1.05-3.15 6.85-18.95 23.4-34.25 3.85-3.55 7.9-6.6 11.6-8.75 25-13.6Q73.8 6.9 92.9 6.9q7.15 0 13.95.85 30.45 3.75 53.35 24.3l1.1 1q5.15 4.75 9.4 9.85 9.35 11.35 14.1 24.45l-.7-2.4-3.75-9.55q-.2-.4-.4-.75-.15-.3-.25-.6l-.65-1.3q-.15-.2-.25-.4l-.05-.2q-.1-.15-.15-.25-.1-.2-.2-.35-.1-.2-.15-.35-.1-.2-.2-.35-.1-.15-.15-.3-5.2-9.6-13.15-18.35z"
                    id="HaiSho019__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho019__Symbol_140_0_Layer0_0_FILL"
                transform="translate(96.4 57.6)"
                id="HaiSho019__col1n"
            />
            <use
                xlinkHref="#HaiSho019__Symbol_69_0_Layer0_0_FILL"
                transform="translate(97.1 82.3)"
                id="HaiSho019__col1d"
            />
            <use
                xlinkHref="#HaiSho019__Symbol_21_0_Layer0_0_FILL"
                transform="translate(99.6 57.6)"
                id="HaiSho019__col1l"
            />
        </g></g>
    )
}

export default HaiSho019
