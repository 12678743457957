import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M106.35 18.7q-.6-8.65-5.2-15.85L86.25 0l-32.5 8.25L16.5 3.2l-14 5Q.45 13.15.1 18.75q-.8 11.15 5.7 18.7.35.45.75.85 7.25 7.75 18.25 6.9 9.7-.7 17.35-7.75 1.05-.95 2.05-2 6.85-7.4 8.65-16.65.4-.35.55-1.3 1.5 10.05 8.85 17.95.95 1.05 2 2 7.7 7.05 17.4 7.75 11 .85 18.25-6.9.4-.4.75-.85 6.45-7.55 5.7-18.75z"
                    id="FacEye013__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.75 5.75q1-1 1-2.4Q6.75 2.2 6 1.3q-.15-.15-.25-.3Q4.8.05 3.4.1 1.95.05 1 1 0 2 0 3.35q0 1.4 1 2.4.95.95 2.4 1 .7-.05 1.35-.3.5-.25 1-.7m54-2.45q0-1.4-1-2.35-.95-1-2.35-.95-1.45-.05-2.45.95-1 .95-1 2.35 0 1.35 1 2.35T56.4 6.7q1.4-.05 2.35-1.05 1-1 1-2.35z"
                    id="FacEye013__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.251}
                    d="M192.95 156.35l32.5-8.25 25.05 4.8q-10.475-3.995-25.4-6.4l-32.4 8.1-37.2-5q-15.493 5.527-22 9.5l22.2-7.8 37.25 5.05z"
                    id="FacEye013__Layer6_0_FILL"
                />
            </defs>
            <g id="FacEye013__col1n" transform="translate(139.2 148.1)">
                <use
                    xlinkHref="#FacEye013__Symbol_16_0_Layer0_0_FILL"
                    id="FacEye013__col1n_FL"
                />
            </g>
            <g id="FacEye013__col2n" transform="translate(175.7 159.6)">
                <use
                    xlinkHref="#FacEye013__Symbol_24_0_Layer0_0_FILL"
                    id="FacEye013__col2n_FL"
                />
            </g>
            <use xlinkHref="#FacEye013__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye013
