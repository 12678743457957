import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000",
    "col2d": "#FF5D5D",
    "col2n": "#FF8E8E"
}
function FacMou094({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M56.4 0q-2.6.35-5.25.65Q40.7 1.7 29.5 1.7q-11.4 0-22-1.1Q4.9.3 2.4 0 .7 3.25.2 5.75-.6 9.6 1.45 11.6q3.3 3.3 11.05 7.05 7.7 3.75 17.05 3 9.3-.8 15-2.95 5.6-2.2 10.1-6.45 2.65-2.5 2.8-6.25.1-2.7-1.05-6z"
                    id="FacMou094__Symbol_146_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M52.7 6.75Q55 6.4 57.25 6q.1-2.7-1.05-6-2.6.35-5.25.65Q40.5 1.7 29.3 1.7q-11.4 0-22-1.1Q4.7.3 2.2 0 .5 3.25 0 5.75q2.1.4 4.25.75.2.05.4.05 11.7 1.9 24.55 1.9 12 0 23.05-1.6.25-.05.45-.1z"
                    id="FacMou094__Symbol_145_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M152.9 1.6q.35-.55.7-1.05l.4-.5V0q-.95.4-1.85.8-21.45 10.95-48.2 14.55-.7 3.45-2.85 6.5l-.1.2q-.2.15-.35.4-1.75 2.25-4.35 4.25-1.85 1.45-3.9 2.55-6.75 3.65-15.45 3.65-9.05 0-16-3.95-1.75-1-3.35-2.25-2.75-2.15-4.55-4.55L52.9 22q-.15-.2-.25-.4-2.05-2.9-2.7-6.25-26.65-3.6-48-14.5l-.4-.2Q.95.45.45.25.2.15 0 .05q.1.1.15.25Q2.4 3.45 4.9 6.55q.3.4.6.75 0 .05.05.1 2.9 3.45 6.15 6.7 14.95 14.95 33.35 21.6 14.8 5.4 31.9 5.4 17.1 0 31.95-5.35 18.35-6.7 33.3-21.65 1.25-1.25 2.5-2.55 1.75-1.9 3.4-3.8.5-.6 1-1.15 2-2.5 3.8-5z"
                    id="FacMou094__Symbol_147_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M52.65 21.6q-2.05-2.9-2.7-6.25-26.65-3.6-48-14.5l-.4-.2Q.95.45.45.25.2.15 0 .05q.1.1.15.25.55.4 1.15.8l1.1.8q20 14.15 45.35 19.2 2.1.4 4.25.75.2.05.4.05l.5.1q-.15-.2-.25-.4m99.6-20.25q.35-.3.7-.55.5-.4 1.05-.75V0q-.95.4-1.85.8-21.45 10.95-48.2 14.55-.7 3.45-2.85 6.5l-.1.2 4-.7q26-4.95 46.45-19.45.4-.3.8-.55z"
                    id="FacMou094__Symbol_148_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou094__Symbol_146_0_Layer0_0_FILL"
                transform="translate(163.1 212.05)"
                id="FacMou094__col2n"
            />
            <use
                xlinkHref="#FacMou094__Symbol_145_0_Layer0_0_FILL"
                transform="translate(163.3 212.05)"
                id="FacMou094__col2d"
            />
            <use
                xlinkHref="#FacMou094__Symbol_147_0_Layer0_0_FILL"
                transform="translate(115.55 196.7)"
                id="FacMou094__col1n"
            />
            <use
                xlinkHref="#FacMou094__Symbol_148_0_Layer0_0_FILL"
                transform="translate(115.55 196.7)"
                id="FacMou094__col1d"
            />
        </g></g>
    )
}
export default FacMou094
