import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccMas011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M96.6 10.85q-6.5 3.8-13.2 3.6-.5 0-1-.05-5.55-.35-10.95-3.5-8.4-5.3-17.3-7.9-19.8-5.9-39.65 0-26.1 11.65-5.25 51Q26.2 85.05 48.8 83.75q6.65-.95 13.8-5.05 7.1-4.05 7.3-4.15 1.6-.85 3.15-1.5 4.2-1.75 8.35-2.15l1.1-.05q.6-.05 1.15-.05.25 0 .5-.05h.25q.55 0 1.15.05l1.1.05q4.15.4 8.35 2.15 1.55.65 3.15 1.5.2.1 7.3 4.15 7.15 4.1 13.8 5.05 22.6 1.3 39.55-29.75 20.8-39.35-5.25-51-19.85-5.9-39.65 0-8.9 2.6-17.3 7.9m31.2 15.55q8.25 8.8 9 20.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-7.3-7.8-8.8-17.85l-.75-2.5q-1.35 5.7-2.1 8.1-2.25 6.7-7.45 12.3-7.25 7.75-16.55 9.3-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-6.65-1.2-11.5-6.45-7.2-7.6-6.35-19.2.75-11.65 9.05-20.45 8.15-8.85 19.05-9.65 10.75-.85 17.85 6.75 3.1 3.35 4.75 7.55.75 2.05 1.95 7l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75h.05q10.85.8 19.05 9.65z"
                    id="AccMas011__Symbol_95_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas011__Symbol_95_0_Layer0_0_FILL"
                transform="translate(108.5 119.85)"
                id="AccMas011__col1n"
            />
        </g></g>
    )
}

export default AccMas011
