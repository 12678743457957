import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#1189F3",
    "col1n": "#43A6FF",
    "col1s": "#005CAF",
    "col2n": "#FFFFFF"
}

function LegPan019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M128.6 73.25V59.6Q63.5 73.15 0 59.6v13.65q1.8-.2 3.9-.25 14.2-.55 41 2.05 8.4.8 18 1.95.2.05.4.05h2q.2 0 .4-.05 9.6-1.15 18-1.95 26.8-2.6 41-2.05 2.1.05 3.9.25z"
                    id="LegPan019__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.8 0H0l.4 4.15h2L2.8 0z"
                    id="LegPan019__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M128.6 4.3V0Q63.5 13.55 0 0v4.3q63.5 13.55 128.6 0z"
                    id="LegPan019__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.15 1q-.2-.2-.4-.45Q10 0 9 0T7.25.55q-.05.05-.2.1-.15 0-1.5.05-1.4.1-2.6 1.2-1.15 1.15-1.2 2.3-.25.9.35 1.75 0 .05.05.1.35.65 1 1.05-.3.3-1 .9-.7.65-1.3 1.05-.65.3-.75.4-.1.05-.1.3-.05.3.85.5 1.3-.75 3.65-2.9.05-.05.1-.05 1.05.05 2.4-.6 1.5-.65 2.2-1.85.4.85 1 1.5.65.7 2.3 1.35.05.05.2.1.25.45.3 1.05.1.45.1 1.15v.3q.65.75 1.05 0v-.55q0-.85-.15-1.7.1 0 .3.1h.45q2.6 0 2.6-1.9 0-.45-.15-.8-1.4-3.3-6-4.4L11.15 1m.05 4.75q-.5-.6-1-1.75-.1-.2-.15-.45.2 0 .35-.05 1 1.2 1.6 2.45.05.25.15.5-.55-.25-.95-.7m0-3.05q.1-.2.15-.35.65.05 2.3.75 1.7.75 2.45 2.65 0 .9-.75 1.3h-.6q-.25 0-1-.25-.15-.1-.25-.1-.25-.55-.75-1.6-.55-1.15-1.55-2.3v-.1m-5.3-.75q.4 0 .65-.05 0 .3.15.6-.9 1.7-1.6 2.4-.4.45-1.05 1.25-.05-.1-.2-.15-.95-.6-1.05-1.45 0-.3.05-.6.05-.6.5-.95.35-.35.95-.6 1-.5 1.6-.45m.2 3.95q-.25.1-.5.25 1.25-1.6 2-2.75.2.15.5.3-.45 1.45-2 2.2z"
                    id="LegPan019__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan019__Symbol_43_0_Layer0_0_FILL"
                transform="translate(128.2 246.75)"
                id="LegPan019__col1n"
            />
            <use
                xlinkHref="#LegPan019__Symbol_44_0_Layer0_0_FILL"
                transform="translate(191.1 319.65)"
                id="LegPan019__col1d"
            />
            <use
                xlinkHref="#LegPan019__Symbol_45_0_Layer0_0_FILL"
                transform="translate(128.2 306.35)"
                id="LegPan019__col1s"
            />
            <use
                xlinkHref="#LegPan019__Symbol_46_0_Layer0_0_FILL"
                transform="translate(183.8 311.2)"
                id="LegPan019__col2n"
            />
        </g></g>
    )
}

export default LegPan019
