import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#480000",
    "col1n": "#830000",
    "col1s": "#190000"
}

function ShiSho003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.85 24.25L123 4.7q-.3-.1-.5-.15l-.45-.15q-.15-.05-.25-.1l-.05-.05q-.5-.25-1.05-.45h-.1l-.3-.1q-.05 0-.1-.05-.75-.25-1.55-.45-.15-.1-.3-.1-.35-.15-.7-.25-4.9-1.65-10-2.35-7.7-1.05-15.9.05-6.9.9-14.2 3.25h-.1q-1.3.5-2.5 1-1.75.7-3.4 1.45l-5.2 2.2-5.2-2.2q-1.65-.75-3.4-1.45-1.2-.5-2.5-1h-.1q-7.1-2.3-13.8-3.2-8.8-1.2-16.95 0-4.8.7-9.35 2.25-.35.1-.7.25-.15 0-.3.1-.85.2-1.65.5l-.3.1H12q-.55.2-1.05.45l-.1.1q-.1 0-.2.05l-.7.25v.1h-.2l-1 5.1-.1.6L5.95 24 0 47.05v.1q.05.1.15.2h.1q.25.1.5.15H.9q2.6.6 5.25 1.2l3.5.8q.4.05.85.15.3.1.65.15.05 0 .1.05.9.15 1.8.35v.05q1.55.25 3.05.55.65.1 1.35.25.15 0 .3.05 6.4 1.1 12.75 1.9 3.8.5 7.6.9.7.1 1.45.15l1.8.2h.25l5.65.45q.3 0 .6.05 5.4.35 10.8.55.4 0 .8.05 2.4 0 4.8.05v-.05h4.2v.05q2.4-.05 4.8-.05.25-.05.5-.05h.3q5.4-.2 10.8-.55.3-.05.6-.05l5.65-.45h.25l1.8-.2q.75-.05 1.45-.15 3.8-.4 7.6-.9 6.35-.8 12.75-1.9.15-.05.3-.05.7-.15 1.35-.25 1.5-.3 3.05-.55v-.05q.85-.2 1.75-.35.05-.05.15-.05.35-.05.7-.15.4-.1.85-.15 1.7-.4 3.45-.8 2.65-.6 5.25-1.2h.15q.25-.05.5-.15h.2l-5.8-23.1z"
                    id="ShiSho003__Symbol_9_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.55 4.7l-.2-.7q-.25-.8-.55-1.65l-.95-2.3h-.1q-.05.05-.1.05l-.7.25v.1h-.2l-1 5.1-.1.6-2.7 13.55L0 42.75v.1q.05.1.15.2h.1q.25.1.5.15H.9q2.6.6 5.25 1.2l3.5.8v-.1q9.75-20.4 2.9-40.4m114.3 15.25L123 .4q-.3-.1-.5-.15l-.45-.15q-.15-.05-.25-.1l-.1.15q-9.7 22.5 1.35 44.95l.05.1q1.7-.4 3.45-.8 2.65-.6 5.25-1.2h.15q.25-.05.5-.15h.2l-5.8-23.1z"
                    id="ShiSho003__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M3.55 2.05l-.4-1L2.7 0l-.3.1h-.1q-.55.2-1.05.45.15.5.35 1 .05.05.05.15.2.6.45 1.25.3.85.55 1.65 4.65 14.95 1.3 29.55-1.2 5-3.3 10-.05.2-.15.4l-.5 1.1 1.55.4h.05V46q.2-.5.45-.95.2-.55.45-1.05 2.05-4.95 3.2-9.85 3.8-16.1-2.15-32.1M57.3 4.5l-.65.25L56 4.5v47.1h.1v.05h1.1v-.05h.1V4.5m54.15-2.25q.05-.15.15-.35.05-.15.1-.35.05-.15.1-.25.05-.15.1-.25 0-.05.05-.05.05-.25.1-.45-.5-.25-1.05-.45h-.1l-.3-.1q-.5 1.2-.9 2.4l-.3.8q-5.45 15.4-1.8 30.95 1.2 5 3.3 10 0 .05.05.15.05.1.1.25l.1.25q.25.6.55 1.2v.05h.05l1.55-.4q-.4-.85-.75-1.7-.15-.35-.25-.65-1.85-4.55-2.9-9.15-3.4-14.65 1.3-29.6.05-.2.1-.35.2-.65.4-1.25.15-.35.25-.7z"
                    id="ShiSho003__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho003__Symbol_9_0_Layer0_0_FILL"
                transform="translate(126.15 262.15)"
                id="ShiSho003__col1n"
            />
            <use
                xlinkHref="#ShiSho003__Symbol_10_0_Layer0_0_FILL"
                transform="translate(126.15 266.45)"
                id="ShiSho003__col1d"
            />
            <use
                xlinkHref="#ShiSho003__Symbol_11_0_Layer0_0_FILL"
                transform="translate(135.85 265.85)"
                id="ShiSho003__col1s"
            />
        </g></g>
    )
}

export default ShiSho003
