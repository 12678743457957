import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M34.05 12.35q-4.2-6.8-6.2-9.55-2-2.75-3.95-2.8-1.7 0-5.2 4.85Q10.65 15.2 5.35 25.6 4.2 27.8 3.2 30.05l-.1.25q-7.1 16.65 2 27.4.15.1.25.25 1.55 1.3 2.75 2.9.05.1.1.15l.8 1.3q1.8 3.2 2.2 7.4.05.95.1 2.05l.9-.5q5.2-7.65 11.1-11.6 2.2-1.5 5.9-2.95.1-.05.25-.05l.15-.05.05-.05.85-.3q.25-.15.55-.25h.1l.1-.1q1.3-.65 2.45-1.45 10.9-7.8 5.65-29.7-1.2-3.8-3-7.75-.95-2.45-2.3-4.65m-12.95 6.9q-.1.05-.2.15l.15-.25q0 .05.05.1m127.45 6.35q-5.3-10.4-13.35-20.75Q131.7 0 130 0q-1.95.05-3.95 2.8t-6.2 9.55q-1.35 2.2-2.3 4.65-1.8 3.95-3 7.75-5.25 21.9 5.65 29.7 1.15.8 2.45 1.45l.1.1h.1q.3.1.55.25l.85.3.05.05.15.05q.15 0 .25.05 3.7 1.45 5.9 2.95 5.9 3.95 11.1 11.6l.9.5q.05-1.1.1-2.05.4-4.2 2.2-7.4l.8-1.3q.05-.05.1-.15 1.2-1.6 2.75-2.9.1-.15.25-.25 9.1-10.75 2-27.4l-.1-.25q-1-2.25-2.15-4.45m-15.7-6.45l.15.25q-.1-.1-.2-.15.05-.05.05-.1z"
                    id="FacEar011__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M21.7 12.75l-.1.1q.05.3.1.55.35 1.8.35 2.55 0 2.45-.6 4.55-.65 2.3-2.3 5.4-.8-1.75-1.35-3.2-1.05-2.7-1.4-5.7-.3-2.1-.25-6.25 0-4.25 4.4-10.75Q12 4.3 6.6 22.4l-1 2.15q-1.75-3.75-1.3-11.7-6.3 9.7-3.35 21.4l.8 1.3q1.8 3.2 2.2 7.4.05.95.1 2.05l.9-.5q5.2-7.65 11.1-11.6 2.2-1.5 5.9-2.95.1-.05.25-.05l.15-.05.05-.05.85-.3q.25-.15.55-.25h.1l.1-.1q2.65-7.8-2.3-16.4m101.55-2q.05 4.15-.25 6.25-.35 3-1.4 5.7-.55 1.45-1.35 3.2-1.65-3.1-2.3-5.4-.6-2.1-.6-4.55 0-.75.35-2.55.05-.25.1-.55l-.1-.1q-4.95 8.6-2.3 16.4l.1.1h.1q.3.1.55.25l.85.3.05.05.15.05q.15 0 .25.05 3.7 1.45 5.9 2.95 5.9 3.95 11.1 11.6l.9.5q.05-1.1.1-2.05.4-4.2 2.2-7.4l.8-1.3q2.95-11.7-3.35-21.4.45 7.95-1.3 11.7l-1-2.15Q127.4 4.3 118.85 0q4.4 6.5 4.4 10.75z"
                    id="FacEar011__Symbol_16_0_Layer0_0_FILL"
                />
                <g
                    id="FacEar011__Symbol_29_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M47.55 19.35l-.3-.7Q46.6 17.3 46 16q-2.75-4.9-5.85-9.8-.15-.25-.3-.45-.7-.95-1.35-1.8-4.25-5.4-8.9-2.4Q9.65 21 2.35 50.4 0 60.35 0 68.1q0 6.4 2.9 13.15 2.75 6.35 2.85 14 3.8-5.15 7-8.85l1.3-1.55q.3-.35.6-.65 2.05-2.3 4.25-4.45l1.5-1.4q0-.05.05-.05l.55-.55.7-.6q.15-.2.3-.3.05-.05.1-.05 10.35-9.4 22.6-15.35l5.7-2.6q.3-.15.6-.25l1.6-.6 4.75-1.65q-1.05-17.2-8.85-35l-.95-2M30 24.5q0 .05.05.1-.1.05-.2.15l.15-.25m9.9 36.9q-.35.15-.65.3-.3.1-.65.2l1.3-.5M20.25 77.1l.9-.5q-.4.65-.85 1.3l-.05.05v-.85m145.8 18.15q.1-7.65 2.85-14 2.9-6.75 2.9-13.15 0-7.75-2.35-17.7-7.3-29.4-27.25-48.85-4.65-3-8.9 2.4-.65.85-1.35 1.8-.15.2-.3.45-3.1 4.9-5.85 9.8-.6 1.3-1.25 2.65l-.3.7-.95 2q-7.8 17.8-8.85 35L119.2 58l1.6.6q.3.1.6.25l5.7 2.6q12.25 5.95 22.6 15.35.05 0 .1.05.15.1.3.3l.7.6.55.55q.05 0 .05.05l1.5 1.4q2.2 2.15 4.25 4.45.3.3.6.65l1.3 1.55q3.2 3.7 7 8.85m-24.3-70.65q.05-.05.05-.1l.15.25q-.1-.1-.2-.15m-8.55 37.3q-.35-.1-.65-.2l-.2-.1.85.3m17.45 14.7l.9.5v.85l-.05-.05q-.45-.65-.85-1.3z" />
                    <path d="M21.15 76.6l-.9.5v.85l.05-.05q.45-.65.85-1.3m18.1-14.9q.3-.15.65-.3l-1.3.5q.35-.1.65-.2m112.3 15.4l-.9-.5q.4.65.85 1.3l.05.05v-.85m-19-15.4q.3.1.65.2l-.85-.3.2.1zM30.05 24.6q-.05-.05-.05-.1l-.15.25q.1-.1.2-.15m111.75-.1q0 .05-.05.1.1.05.2.15l-.15-.25z" />
                </g>
            </defs>
            <use
                xlinkHref="#FacEar011__Symbol_29_0_Layer0_0_FILL"
                transform="translate(106.6 6.4)"
                id="FacEar011__col1n"
            />
            <use
                xlinkHref="#FacEar011__Symbol_21_0_Layer0_0_FILL"
                transform="translate(115.55 11.25)"
                id="FacEar011__col2n"
            />
            <use
                xlinkHref="#FacEar011__Symbol_16_0_Layer0_0_FILL"
                transform="translate(122.8 38)"
                id="FacEar011__col2d"
            />
        </g></g>
    )
}
export default FacEar011
