import { AssetSvgProps } from "../../../shared/assets"

function AccMor002({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor002__Layer5_0_FILL">
                    <path
                        fill="#E6E6E6"
                        d="M267.8 316.15v16.15l6.25 3.95h11.15q-3.97-17.934-17.4-20.1z"
                    />
                    <path
                        fill="#0EE"
                        d="M302.95 340.1l3.45 2.5h8.45l9.6-11.85q-1.285-3.806-9.95-3.45-9.868 2.21-11.55 12.8m-13.2-64.55l3.4 1.1h.05q6.267 5.784 16.2 5.15l-11.6-9.05h-6.45l-3.1 3.5 1.4-.7v.05l.1-.05m-7.9 11.45l.15-.9h-.05l.6-3.3-4.7 5.35q.627 1.304 1 2.45.107.261.15.5.535 1.587.6 2.95.01.264 0 .5v2.15l.7-.7q3.34-4.52 1.25-7.65h.05l.2-1.35h.05z"
                    />
                </g>
                <g id="AccMor002__Layer5_0_MEMBER_0_FILL">
                    <path
                        fill="#0CC"
                        d="M306.45 336.15q-3.29.52-3.5 3.95l3.45 2.5h8.45l-8.4-6.45z"
                    />
                    <path
                        fill="#009191"
                        d="M281.5 288.65l.05-.3 13.2-12.6-.25.2 3.3-3.2h-6.45l-3.1 3.5h.1l-10.5 11.9q.627 1.304 1 2.45.107.261.15.5l2.5-2.45z"
                    />
                </g>
                <g id="AccMor002__Layer5_0_MEMBER_2_MEMBER_0_FILL">
                    <path
                        fill="#B1B101"
                        d="M282 286.1l-.15.9h-.05l-.2 1.35h-.05L280.3 296q5.154 2.095 6.45-6.75l1.65-8.4h-.05l1.3-5.25v-.05l-6.4 3.25-1.3 7.3h.05z"
                    />
                    <path
                        fill="#E1E004"
                        d="M327.7 331.8l9.3-2.45-18.75-6.15q-5.036-.113-3.75 4.1l13.2 4.5z"
                    />
                </g>
                <g id="AccMor002__Layer5_0_MEMBER_3_MEMBER_0_FILL">
                    <path
                        fill="#FEA64E"
                        d="M292.2 344.35l9-2.4-28-31.5-8.35 2.25 27.35 31.65z"
                    />
                    <path
                        fill="#CA6502"
                        d="M284.9 291.3l-.25.2-6.1 6.35.05-.1-4.95 5.2h.05l-8.85 9.75 8.35-2.25 6.5-6.75 3.15-3.2q-.112.123-.25.25l11.65-11.8.05.05 8.9-8.95-10.2 2.75-2.5 2.6q.11-.033.2-.1l-4.9 4.95q-.4.35-.9 1.05z"
                    />
                </g>
                <g id="AccMor002__Layer5_0_MEMBER_4_MEMBER_0_FILL">
                    <path
                        fill="#643302"
                        d="M280.85 283.95h-.7q-.972-.142-2.95.6-1.26.473-1.75.85h-.05q-.933.257-1.7.7l-2.4.6q-3.75 1.05-5.15 4.8-.671 1.74-.75 3.2-.014.932.2 1.75.05.15.1.35.15-.95.2-1.35.3-1.201.55-1.95 1.452-2.757 4-4.75.354-.174.7-.3-.448.474-.75.95-1.898 2.844.3 7.1.64 1.182 1.55 2.45v.05q.102.083.15.15.035.053.05.05.115.168.25.3l.25.35q.424.493.95 1.1.2.257.4.5l.4.4q.672.756 1.35 1.45.195.203.4.4.319.333.65.65.03.021.55.55.505.531 1.05 1.15h.05q.049.062.1.1 1.4.95 2.1.55.65-.7 0-1.7-.2-.35-.5-.65-1.15-1.35-3.65-3.6-.808-.846-1.6-1.75-.208-.299-.45-.6-.696-.974-1.25-1.9-1.008-1.82-1.3-3.45-.269-3.84 3.15-5.1 4.687-1.374 8.9 3.95l.4-.4.25-.2q.12-.168.2-.35.25-.254.45-.45-1.937-3.097-5.35-4.35l.55-.15q.6.05 1.25.1.6.1 1.25.3 1.6.4 3.45 1.2.55.25 1.2.5 1.3.65 2.75 1.5 1 .6 1.9 1.2.65.397 1.25.75 1.262.662 1.95.2.448-.782-.4-1.75-.425-.483-1.05-1-1.6-1.2-4.4-2.65-.1-.05-.15-.1-1.35-.55-2.4-1.1-1.15-.45-2.15-.7-1.227-.317-2.3-.4-.258-.02-.45-.05-.076-.013-.15-.05h-.6q-.334-.033-.6 0h-.25z"
                    />
                    <path
                        fill="#FFF"
                        d="M280.15 283.95h.95q.266-.033.6 0h.6q.074.037.15.05.103-.02.15-.05 3.405-1.35 7.6-.7.859.091 1.3-.25.274-.55 0-1.15-.46-.664-1.55-.85-7.162-.842-12.75 3.1-.976.582-1.8 1.3h.05q.49-.377 1.75-.85 1.978-.742 2.95-.6z"
                    />
                </g>
                <path
                    fill="#CCC"
                    d="M274.05 323.35q.252-6.012-6.25-7.2v16.15l6.25 3.95v-12.9z"
                    id="AccMor002__Layer5_0_MEMBER_1_FILL"
                />
                <path
                    fill="#FCFC61"
                    d="M327.7 331.8l9.3-2.45 6.5-36.75-50.3-15.95h-.05l-3.4-1.1-.1.05v-.05l-6.4 3.25-1.3 7.3h.05l-.15.9h-.05l-.2 1.35h-.05L280.3 296l4.35-4.5.25-.2q.5-.7.9-1.05l17.75-4.7 21.4 21.65-10.45 20.1 13.2 4.5z"
                    id="AccMor002__Layer5_0_MEMBER_2_FILL"
                />
                <path
                    fill="#FC9"
                    d="M293 282.8l-2.5 2.6q.11-.033.2-.1l-4.9 4.95q-.4.35-.9 1.05l-.25.2-6.1 6.35.05-.1-4.95 5.2h.05l-8.85 9.75 27.35 31.65 9-2.4 29.75-32.85-27.75-29.05-10.2 2.75z"
                    id="AccMor002__Layer5_0_MEMBER_3_FILL"
                />
                <path
                    id="AccMor002__Layer5_0_MEMBER_4_MEMBER_0_1_STROKES"
                    stroke="#5D342C"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M280.175 283.95h.7"
                />
            </defs>
            <use xlinkHref="#AccMor002__Layer5_0_FILL" />
            <use xlinkHref="#AccMor002__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#AccMor002__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#AccMor002__Layer5_0_MEMBER_2_FILL" />
            <use xlinkHref="#AccMor002__Layer5_0_MEMBER_2_MEMBER_0_FILL" />
            <use xlinkHref="#AccMor002__Layer5_0_MEMBER_3_FILL" />
            <use xlinkHref="#AccMor002__Layer5_0_MEMBER_3_MEMBER_0_FILL" />
            <g>
                <use xlinkHref="#AccMor002__Layer5_0_MEMBER_4_MEMBER_0_FILL" />
                <use xlinkHref="#AccMor002__Layer5_0_MEMBER_4_MEMBER_0_1_STROKES" />
            </g>
        </g></g>
    )
}

export default AccMor002
