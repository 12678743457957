import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00"
}

function ShiSpe087({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#3A0077"
                    d="M161.8 272.75h-.05v.1q37.7 6.85 67.85-2.75 10.55-3.15 17-9.2 4.2-3.4 6.8-7.85.25-.35.45-.65.15-.2.25-.35 1.95-3.1 3.65-4.65.15-.15.3-.25 1.155-.649 2.2-.75 1.083-.054 2.15.7 1.35.95 3.15 3.4l-.15-.5q.063.176.15.35.284.426.55.85.034.026.05.05l9.1 24.4 4.2 29.7-.6 14.15 20.5 1.35q-.013.215-.05.4.16-.18.3-.35.2-.26.35-.5v.05q.4-.75.65-1.45 3.15-8.35 0-16.65-1.5-5.3-5.55-13.2-2.8-5.55-11-14.55-4.95-5.5-9.9-16.05l-.2-.5q-1.05-2.3-2.95-6.8v-.05q-3.75-10.55-8.45-11.65-.75-.25-1.6-.35H260.8l-2.35-4.35q-3.287-5.128-5.25-6.85-.314.309-.65.6l-1.85 1.4q-.61.475-1.25.95-.756.575-1.55 1.15l-1.55 1.15q-1.007.696-2.05 1.35 1.984 8.706-3.3 17.4-6.05-2.15-13.9-.55-.75 6.1 4.45 10.3-5.6 5.45-15.25 8.45-10.35 3.25-24.3 3.85-13.95.6-29.75-1.2h.3q-.4-.05-.75-.1m90.1-32.9q.05-.1.15-.2-.05.15-.05.35-.05-.05-.1-.15m-113.35-6.7l-1.45-1.05q-1.45-1.05-2.8-2.1l-1.85-1.4-.05-.05-.4-.35q-.327-.292-.65-.6-1.995 1.473-5.7 7.25l-2.35 4.35h-.15q-.85.1-1.6.35-4.7 1.1-8.45 11.65v.05q-1.9 4.5-2.95 6.8l-.2.5q-4.95 10.55-9.9 16.05-8.2 9-11 14.55-4.05 7.9-5.55 13.2-3.15 8.3 0 16.65.25.7.65 1.45v-.05q.25.4.65.85-.09-.444-.15-.85l21.25-.4-4.15-24.95 17.45-45.6q.322-.403.65-.8 1.45-1.75 2.8-2.7 2-.6 2.65-.6.55 0 1.7.65.15.1.3.25 1.7 1.55 3.65 4.65.1.15.25.35.2.3.45.65 3.6 6.15 10.35 10.35 3.05 1.9 6.75 3.45 10.4 4.75 21.7 5.9.2-.05.8-.1-1.55.05-6.95-1.55-5.65-1.7-12.8-8.15 5.2-4.2 4.45-10.3-7.85-1.6-13.9.55-5.312-8.772-3.5-18.9M132.6 240v-.1h.1l-.1.1z"
                    id="ShiSpe087__Layer21_0_MEMBER_0_MEMBER_0_FILL"
                />
                <path
                    fill="#FA4476"
                    d="M269.3 256.7q-1.6-3.15-3.15-5.45l-.6-.9q-.087-.174-.15-.35 3.742 11.108 3.8 11.35.34.968.65 1.95.83 2.603 1.55 5.3.244.79.45 1.55.084.337.15.65.37 1.343.7 2.7.184.749.35 1.5.08.3.15.6.163.713.3 1.4.082.287.15.55.088.424.15.85.19.797.35 1.6.01.05 0 .1l-1.9.4q-4.4 1.052-4.55 1l-2.05-.15q-4.798-.207-8.35 1.45-.253.145-.5.25-1.878 1.018-3.4 2.55-.198.226-.4.45l.55 2.45v-.05l5.2 20.75v.1l.05.15v.2q-.35.05-.65.2-.05.05-1.2.35-.35.05-.6.15-.1 0-.2.05-.15 0-.2.05h-.1l-.35.15h-.05q-.1 0-.2.05-.15 0-.25.05v12.45q2.05-.4 3.95-1.05 3.55-1.4 5.3-1.95.85-.25 1.7-.45 2.05-.45 3.95-.45 5.35 0 10.7 2.95 5.35 2.85 9.3 2.85 2.75 0 6.1-1.5 2.2-1 3.3-2.3 1-4.95-1.95-6.35-1.7-.55-4.35-.45h-.35q-3.55.2-7.75 2.7t-3.7 1.3q.5-1.25 2.1-3.9 1.65-2.7 2.2-4.8.55-2.25.8-5.45.3-3.25-.05-7.1-.35-3.9-3.2-11.55-6.4-13.25-8.95-19.1l-.8-1.6q-2.45-5.25-4-8.25m-149.8-7.35q.185-.342.35-.7-.95 1.15-1.9 2.6-1.55 2.3-3.15 5.45-1.55 3-4 8.25l-.8 1.6q-2.55 5.85-8.95 19.1-2.85 7.65-3.2 11.55-.35 3.85-.05 7.1.25 3.2.8 5.45.55 2.1 2.2 4.8 1.6 2.65 2.1 3.9.5 1.2-3.7-1.3t-7.75-2.7h-.35q-2.65-.1-4.35.45-2.95 1.4-1.95 6.35 1.1 1.3 3.3 2.3 3.35 1.5 6.1 1.5 3.95 0 9.3-2.85 5.35-2.95 10.7-2.95 1.9 0 3.95.45.85.2 1.7.45 1.75.55 5.3 1.95 1.9.65 4.9 1.05v-12.8q-1.56-.345-3.1-.7H126.8q-.25-.05-.5-.15h-.05v-1.05.3l.8-3.15q.483-1.8.95-3.6.521-2.101 1.05-4.2l2.8-11.75q-.313-.346-.65-.65l-.1-.15q-.029-.005-.05-.05-1.345-1.237-2.95-2.1-.1-.048-.2-.1-3.663-1.864-8.7-1.7l-1.85.25q-.147-.004-3.5-.6-1.642-.291-3.05-.55.016-.078 0-.15.373-1.646.7-3.25.188-.702.35-1.4.068-.3.1-.6.154-.501.25-1h-.05q.084-.246.15-.5.331-1.362.7-2.7.069-.3.15-.6.778-2.96 1.65-5.8.461-1.5 4.6-14.8.078-.105.1-.25m10.4 35.9v-.05q.05 0 .15.05h-.15z"
                    id="ShiSpe087__Layer21_0_MEMBER_0_MEMBER_1_FILL"
                />
                <path
                    fill="#009CE0"
                    d="M256.8 283.05q.247-.105.5-.25 3.552-1.657 8.35-1.45l2.05.15q.15.047.3.05 1.306.27 2.5.7.025.01.05 0 .747.3 1.45.65 1.3.648 2.45 1.55l.6.3q-.01-.139-.05-.3-.276-1.54-.6-3.05-.01-.2-.05-.4-.036-.2-.1-.4-.036-.3-.1-.6-4.25-2.6-9.5-2.6-6.8 0-11.8 4.35-.4.3-.8.75H252v.05l.2.95q0-.05.1-.05 0 .05.05.1-.825 3.62 1.05 2.05 1.522-1.532 3.4-2.55m-143.5-.25q1.505-.714 3.2-1.1.1-.004.2-.05.174-.017.35-.05.15-.05.3-.05l1.85-.25q5.037-.164 8.7 1.7.1.052.2.1 1.605.863 2.95 2.1.021.045.05.05l.1.15q2.669 2.467 2-1.85h-.65v-.25.05l.2-.75h.05l.05-.4q-.03-.074-.05-.15-.9-.8-1.9-1.5l-.5-.4q-.05 0-.1-.05-4.3-2.7-9.65-2.7-5.482 0-9.85 2.85.016.072 0 .15-.007.088-.05.15-.067.486-.15.95-.091.448-.2.9-.168 1.017-.35 2.05-.05.2-.05.4.145-.072.25-.15 1.433-1.142 3.05-1.9m39.35-41.6q-.1.71-.1 1.9 0 4.95 9.05 8.15.05 0 .2.05l.05.05q1.2.45 1.85 1.2.65.85.75 1.1 5.4 6.1 16.75 10.3.75.35 1.5.75.9.4 1.8.85 1.4.75 2.95 1.45.85.45 1.8.9.6.25 1.7.75.05 0 .05.05.1 0 .2.05l.45-.1q.1-.05.2-.05.05-.05.15-.1 3.5-1.4 6.55-3 3-1.45 5.75-2.9 1-.55 1.95-1.1 9.5-3.4 11.5-7.75 2.65-2.9 4.35-3.6.05-.05.1-.05 6.665-3.126 7.1-7.55-5.666 2.338-11.7 3.9-7.747 2.017-16.05 2.7-.524.06-1.05.1l-1.1.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-3.884-.29-7.65-.9-4.808-.705-9.45-1.9-7.617-1.976-14.75-5.25z"
                    id="ShiSpe087__Layer21_0_MEMBER_0_MEMBER_2_FILL"
                />
                <path
                    fill="#006693"
                    d="M181.2 263.95q.75.35 1.5.75 11.7 1.85 21.6-2.1 1-.55 1.95-1.1-13.4 3.15-25.05 2.45m20.3-8.15l-.35.1q8.5.65 16.6-2.15 2.65-2.9 4.35-3.6-10.25 4-22.75 5.9l2.15-.25m-3.45-1.8q-.6 0-5.05.5-4.25.5-6.6.5-11.65 0-21.6-2.75-1.6-.45-2.95-.9 1.2.45 1.85 1.2.65.85.75 1.1 1.55 1.05 6.1 1.95 2.7.5 5.25.75 1.25 1.55 3.9 2.7 3.3 1.4 6.75 1.4 1.85 0 4.85-.4 3.05-.35 4.7-.85h-.5q-4.15.25-5.2.25-4.9 0-7.6-.6-2.95-.7-4.4-2.35h1.95q4.55-.15 10.65-1.05 6.05-.9 7.4-1.45h-.25z"
                    id="ShiSpe087__Layer21_0_MEMBER_0_MEMBER_3_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m11.9-25l.35-.05h-.05l-.9-1.65q-3.75.6-7.6 3.95-3.85 3.3-3.9 7.15 6.65-8.55 12.1-9.4M137.4 264.5v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4m23.5 6.75h-.05q-7.059 20.48-7.55 43.75.15 0 .4.05h.2q.387.048.4 1.2.01-.677 0-1.4.533-21.879 7.45-42v-.1h.05q.35.05.75.1.5 0 .65.05 8.3.35 13.35-.35 7.3-1.05 14.4-3.9-1.1-.5-1.7-.75-8.9 3.1-17.85 3.6h-.15q-.6.05-.8.1-6.15 0-9.55-.35m-34.35-34.2q-1.796-.03-2.1-.05l-1.15 2.2q.95-.1 2-.1 3.2 0 6 1.75 1.95 1.25 2.45 2.45-.299-2.745-2.85-4.5-2.503-1.715-4.35-1.75z"
                    id="ShiSpe087__Layer21_0_MEMBER_0_MEMBER_4_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M2.3 6q-.25 0-.45.05-.7.1-1.2.6Q0 7.35 0 8.3t.65 1.6q.5.5 1.2.65.2.05.45.05.95 0 1.6-.7.65-.65.65-1.6 0-.95-.65-1.65Q3.25 6 2.3 6m4.25-3.7q0-.95-.65-1.65Q5.25 0 4.3 0q-.25 0-.45.05-.7.1-1.2.6Q2 1.35 2 2.3t.65 1.6q.5.5 1.2.65.2.05.45.05.95 0 1.6-.7.65-.65.65-1.6M58.7 38.6q-.05.35-.05.75V39.65q.05 1.05.55 1.9.3.55.8 1 1.35 1.4 3.25 1.4 1.85 0 3.2-1.4.5-.45.8-1 .55-.85.55-1.9v-.15-.15q0-.4-.05-.75t-.15-.65q-.35-1.05-1.15-1.85-1.35-1.35-3.2-1.35-1.9 0-3.25 1.35-.8.8-1.15 1.85-.1.3-.15.65m9.1-20.1v-.15-.15q0-.4-.05-.75t-.15-.65q-.35-1.05-1.15-1.85-1.35-1.35-3.2-1.35-1.9 0-3.25 1.35-.8.8-1.15 1.85-.1.3-.15.65-.05.35-.05.75V18.5q.05 1.05.55 1.9.3.55.8 1 1.35 1.4 3.25 1.4 1.85 0 3.2-1.4.5-.45.8-1 .55-.85.55-1.9m28.45 17.6q-1.35-1.35-3.2-1.35-1.9 0-3.25 1.35-.8.8-1.15 1.85-.1.3-.15.65-.05.35-.05.75V39.65q.05 1.05.55 1.9.3.55.8 1 1.35 1.4 3.25 1.4 1.85 0 3.2-1.4.5-.45.8-1 .55-.85.55-1.9v-.15-.15q0-.4-.05-.75t-.15-.65q-.35-1.05-1.15-1.85M89.8 14.95q-.8.8-1.15 1.85-.1.3-.15.65-.05.35-.05.75V18.5q.05 1.05.55 1.9.3.55.8 1 1.35 1.4 3.25 1.4 1.85 0 3.2-1.4.5-.45.8-1 .55-.85.55-1.9v-.15-.15q0-.4-.05-.75t-.15-.65q-.35-1.05-1.15-1.85-1.35-1.35-3.2-1.35-1.9 0-3.25 1.35M156.4 6q-.5-.25-1.1-.25-.95 0-1.6.65-.65.7-.65 1.65t.65 1.6q.65.7 1.6.7h.1q.9-.05 1.55-.7.65-.65.65-1.6 0-.95-.65-1.65-.25-.25-.55-.4m-.4-3.7q0-.95-.65-1.65-.55-.55-1.3-.6-.15-.05-.35-.05-.95 0-1.6.65-.65.7-.65 1.65t.65 1.6q.65.7 1.6.7h.35q.75-.15 1.3-.7.65-.65.65-1.6z"
                    id="ShiSpe087__Symbol_147_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.35 23.3q1.35-1.15 3.25-1.15 1.85 0 3.2 1.15.8.7 1.15 1.6.1.25.15.55.046.233.05.4v-.1q0-.4-.05-.75t-.15-.65Q8.6 23.3 7.8 22.5q-1.35-1.35-3.2-1.35-1.9 0-3.25 1.35-.8.8-1.15 1.85-.1.3-.15.65-.05.35-.05.75v.1q.004-.167.05-.4.05-.3.15-.55.35-.9 1.15-1.6M7.8 2.15q.8.7 1.15 1.6.1.25.15.55.046.233.05.4v-.1q0-.4-.05-.75t-.15-.65Q8.6 2.15 7.8 1.35 6.45 0 4.6 0 2.7 0 1.35 1.35.55 2.15.2 3.2q-.1.3-.15.65Q0 4.2 0 4.6v.1q.004-.167.05-.4.05-.3.15-.55.35-.9 1.15-1.6Q2.7 1 4.6 1q1.85 0 3.2 1.15m26.6 19q-1.9 0-3.25 1.35-.8.8-1.15 1.85-.1.3-.15.65-.05.35-.05.75v.1q.004-.167.05-.4.05-.3.15-.55.35-.9 1.15-1.6 1.35-1.15 3.25-1.15 1.85 0 3.2 1.15.8.7 1.15 1.6.1.25.15.55.046.233.05.4v-.1q0-.4-.05-.75t-.15-.65q-.35-1.05-1.15-1.85-1.35-1.35-3.2-1.35M38.9 4.3q.046.233.05.4v-.1q0-.4-.05-.75t-.15-.65q-.35-1.05-1.15-1.85Q36.25 0 34.4 0q-1.9 0-3.25 1.35-.8.8-1.15 1.85-.1.3-.15.65-.05.35-.05.75v.1q.004-.167.05-.4.05-.3.15-.55.35-.9 1.15-1.6Q32.5 1 34.4 1q1.85 0 3.2 1.15.8.7 1.15 1.6.1.25.15.55z"
                    id="ShiSpe087__Symbol_146_0_Layer0_0_FILL"
                />
                <g id="ShiSpe087__Layer21_0_MEMBER_0_FILL">
                    <path
                        fill="#391D00"
                        d="M126.25 308.75v-.3.85h.05l-.05-.55z"
                    />
                    <path
                        fill="#7900C2"
                        d="M138.35 234.3q-.393.1-.85.5-.6.45-3.4 1.55-4.9 2.1-6.75 3.4-1.25.85-3.6 3.6-2.217 2.608-2.5 3.75-.691.695-1.4 1.55-.328.397-.65.8l-17.45 45.6 3.2 19.35 21.4-6.05-.1.4.05.55h-.05v.2h.05q.25.1.5.15H126.95q1.54.355 3.1.7.524.13 1.05.25 11.116 2.518 22.2 4.4.15 0 .4.05h.2q.387.048.4 1.2v.7q19.593 2.804 37.25 2.85.601.026 1.2 0 17.803-.142 34.4-3.05 13.14-2.084 26.1-5.65l.75-.15.05-.05h.1q.2-.1.4-.1.05-.05.1-.05H254.8q.1-.05.2-.05.1-.05.25-.05.1-.05.2-.05h.05l.35-.15h.1q.05-.05.2-.05.1-.05.2-.05.25-.1.6-.15.654-.17.95-.25l21.25 1.95.3-6.55-4.2-29.7-9.1-24.4q-.016-.024-.05-.05-.266-.424-.55-.85-.087-.174-.15-.35l.15.5q-1.669-2.272-2.95-3.25-.149-.38-.35-.85-.85-1.85-1-2.25-.45-1.5-.65-2.25-.45-1.4-1.4-2.3-1-.95-4.05-1.7-3.1-.7-3.65-.85-3.25-1.1-4.9-1.55-1.416-.39-2.15-.25-.053-.328-.15-.65-2.72 1.803-5.55 3.35-4.599 2.591-9.45 4.6-.435 4.424-7.1 7.55-.05 0-.1.05-1.7.7-4.35 3.6-2 4.35-11.5 7.75-.95.55-1.95 1.1-2.75 1.45-5.75 2.9-3.05 1.6-6.55 3-.1.05-.15.1-.1 0-.2.05l-.45.1q-.1-.05-.2-.05 0-.05-.05-.05-1.1-.5-1.7-.75-.95-.45-1.8-.9-1.55-.7-2.95-1.45-.9-.45-1.8-.85-.75-.4-1.5-.75-11.35-4.2-16.75-10.3-.1-.25-.75-1.1-.65-.75-1.85-1.2l-.05-.05q-.15-.05-.2-.05-9.05-3.2-9.05-8.15 0-1.19.1-1.9-2.782-1.251-5.5-2.75-4.354-2.335-8.5-5.2l-.1-.1-.2 1.15m-5.75 5.7v-.1h.1l-.1.1m4.2 26.3v-.05h.05l-.05.05m-.4-2.1h.05q-.05.05-.05.2l-3.25 16.15v.05h-.05v-.05l3.3-16.35m-3.85 19.1v.2-.2m.35-1.55v.25q-.05.05-.05.1v.1q-.03-.074-.05-.15l.1-.3m-2.85 3.5h-.15v-.05q.05 0 .15.05m122-45.6q-.05.15-.05.35-.05-.05-.1-.15.05-.1.15-.2m.15 43.85q0-.05.1-.05 0 .05.05.1h-.15v-.05m-1.7-8.4l-.05-.4.55 2.95-.55-2.6.05.05z"
                    />
                    <path
                        fill="#666"
                        d="M250.45 274.7l.05.4-.05-.05.55 2.6-.55-2.95m1.85 8.75q-.1 0-.1.05v.05h.15q-.05-.05-.05-.1m-122.4 1.8h.15q-.1-.05-.15-.05v.05m3-3.25v-.25l-.1.3q.02.076.05.15v-.1q0-.05.05-.1m-.35 1.35v-.05.2-.15m3.9-19.15h-.05l-3.3 16.35v.05h.05v-.05l3.25-16.15q0-.15.05-.2m.35 2.05v.05l.05-.05h-.05z"
                    />
                    <path
                        fill="#16002C"
                        d="M252 240q0-.2.05-.35-.1.1-.15.2.05.1.1.15m-119.4-.1v.1l.1-.1h-.1z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiSpe087__Layer21_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiSpe087__Layer21_0_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiSpe087__Layer21_0_MEMBER_0_MEMBER_1_FILL" />
            <use xlinkHref="#ShiSpe087__Layer21_0_MEMBER_0_MEMBER_2_FILL" />
            <use xlinkHref="#ShiSpe087__Layer21_0_MEMBER_0_MEMBER_3_FILL" />
            <use xlinkHref="#ShiSpe087__Layer21_0_MEMBER_0_MEMBER_4_FILL" />
            <use
                xlinkHref="#ShiSpe087__Symbol_147_0_Layer0_0_FILL"
                transform="translate(113.25 265.75)"
                id="ShiSpe087__col1n"
            />
            <use
                xlinkHref="#ShiSpe087__Symbol_146_0_Layer0_0_FILL"
                transform="translate(171.9 279.35)"
                id="ShiSpe087__col1d"
            />
        </g></g>
    )
}

export default ShiSpe087
