import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF0000"
}

function PlaFor024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2.35 294.75H.05v72.55H0v.05h.05v2.25h74.8v-2.3H2.35v-72.55M74.85 2.3V0H.05v2.25H0v.05h.05v72.55h2.3V2.3h72.5M52.5 13.25h-3.45V9.4h-38.5v17.65h38.5V23.1h3.45v-9.85m-6.05-1.7v13.4H13.2v-13.4h33.25m-9.6 11.55v-9.85h-6v9.85h6m-15.5 0v-9.85h-6v9.85h6m7.8-9.85H23.1v9.85h6.05v-9.85m340.65 281.5h-2.3v72.55H295v2.3h74.8v-2.25h.05v-.05h-.05v-72.55m-40.25-277.4q.8-1.15.8-2.65 0-2.55-1.65-3.8t-5.1-1.25h-4.95v16.9h3.6V20.1h1.9l4.05 6.45h3.95Q331 24.9 327.2 19.2q1.5-.7 2.35-1.85m-2.85-2.55q0 1.2-.8 1.75-.8.6-2.45.6h-1.2V12.6h1.1q1.75 0 2.55.5.8.5.8 1.7m17.35-5.15h-9.75v16.9h9.75V23.6h-6.15v-4.35h5.65V16.3h-5.65v-3.7h6.15V9.65m15.65.95q-2.5-1.2-5-1.2-2.4 0-4.2 1.05-1.8 1.1-2.7 3.05-1 2-1 4.65 0 4.15 1.95 6.4t5.65 2.25q2.55 0 4.65-.9v-3q-2.55.85-4.35.85-4.2 0-4.2-5.6 0-2.75 1.1-4.2 1.1-1.6 3.1-1.6.95 0 1.9.4.95.3 1.9.75l1.2-2.9m10.15-8.35h-.05V0H295v2.3h72.5v72.55h2.3V2.3h.05v-.05z"
                    id="PlaFor024__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.5 8.7q0-3.6-2.6-6.2Q12.35 0 8.75 0 5.1 0 2.55 2.5 0 5.1 0 8.7t2.55 6.1q2.55 2.55 6.2 2.55 3.6 0 6.15-2.55 2.6-2.5 2.6-6.1z"
                    id="PlaFor024__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor024__Symbol_33_0_Layer0_0_FILL"
                transform="translate(7 7.6)"
                id="PlaFor024__col1n"
            />
            <use
                xlinkHref="#PlaFor024__Symbol_32_0_Layer0_0_FILL"
                transform="translate(300.65 17.15)"
                id="PlaFor024__col2n"
            />
        </g></g>
    )
}

export default PlaFor024
