import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#C6C56C"
}

function SkiMor016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M8.25 4.15q0-1.7-1.25-2.95Q5.8 0 4.1 0T1.2 1.2Q0 2.45 0 4.15q0 1.7 1.2 2.9t2.9 1.2q1.7 0 2.9-1.2 1.25-1.2 1.25-2.9z"
                    id="SkiMor016__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.7 4.3q.75.75 1.8.75t1.75-.75q.8-.7.8-1.75t-.8-1.8Q3.55 0 2.5 0T.7.75Q0 1.5 0 2.55T.7 4.3z"
                    id="SkiMor016__Symbol_29_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor016__Symbol_28_0_Layer0_0_FILL"
                transform="translate(142.35 207.4)"
                id="SkiMor016__col1n"
            />
            <use
                xlinkHref="#SkiMor016__Symbol_29_0_Layer0_0_FILL"
                transform="translate(143.9 209.2)"
                id="SkiMor016__col2n"
            />
        </g></g>
    )
}

export default SkiMor016
