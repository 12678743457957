import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#411F00",
    "col1n": "#773900"
}

function ObjMor036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M6.3 2Q5.25 1.1 5.1.65 4.85.05 4.8 0 .9 3.2.15 8.3q-.75 5.5 2.55 9.95 2.05 2.8 5.05 4.15l2.3-4.35Q9 17.6 8.15 16.9q-.7-.6-1.3-1.4-2-2.7-1.5-6t3.2-5.3q.25-.2.55-.4-1-.4-1.8-1-.05-.05-.15-.1-.5-.4-.85-.7m17.1 1.3q-.95.85-1.95 1.15h-.05q-.15.05-.25.1-.35.05-.65.15h-.1q-.65.1-1.4.2.45.4.85.9L55.4 53.5l4.4-3.6L24.4 2.4q.05.05-1 .9z"
                    id="ObjMor036__Symbol_142_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.35 1.1q-1-.4-1.8-1Q3.5.05 3.4 0q-.15.15-.35.3-.2.15-.4.35Q0 3.35 0 7.1t2.65 6.4q1.05 1.05 2.25 1.7.6.3 1.2.55l.2-.4q-1.05-.45-1.9-1.15-.7-.6-1.3-1.4-2-2.7-1.5-6t3.2-5.3q.25-.2.55-.4m12.05.75q-.35.05-.65.15h-.1q-.65.1-1.4.2.45.4.85.9l35.55 47.7 1.55-1.25-35.8-47.7z"
                    id="ObjMor036__Symbol_80_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor036__Symbol_142_0_Layer0_0_FILL"
                transform="translate(254.85 296.4)"
                id="ObjMor036__col1n"
            />
            <use
                xlinkHref="#ObjMor036__Symbol_80_0_Layer0_0_FILL"
                transform="translate(258.6 299.1)"
                id="ObjMor036__col1d"
            />
        </g></g>
    )
}

export default ObjMor036
