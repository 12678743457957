import { AssetSvgProps } from "../../../shared/assets"

function ShiMot069({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot069__Layer5_0_FILL"
                />
                <g id="ShiMot069__Layer5_1_FILL">
                    <path d="M202.6 289l2.4-3.6-1.2-.85-2.45 3.6 1.25.85m.65.4l1.2.85 2.45-3.6-1.25-.85-2.4 3.6m4.3-2.3l-2.4 3.6 1.2.8 2.45-3.6-1.25-.8m-.95-6.75l-2.4 3.65 1.2.8 2.45-3.6-1.25-.85m5 3.35l-1.2-.8-2.45 3.65 1.2.8 2.45-3.65m-1.85-1.25l-1.25-.85-2.4 3.65 1.2.8 2.45-3.6m-4.35-14.95l-1.2.85 2.4 3.6 1.25-.85-2.45-3.6m-1.6.25l1.2-.8-2.4-3.65-1.25.8 2.45 3.65m1.35-6.15l2.4 3.6 1.25-.8-2.45-3.65-1.2.85m-.7.45l-1.2.8 5.25 7.8 1.25-.8-5.3-7.8m4.7 2.9l-1.2.85 2.45 3.6 1.2-.8-2.45-3.65m-29.1 24.45l-2.4-3.6-1.25.85 2.45 3.6 1.2-.85m-3.05 2.1l1.15-.8-5.2-7.8-1.25.8 5.3 7.8m-.3-11.15l-1.25.85 5.25 7.8 1.25-.85-5.25-7.8m-1.9 1.25l-1.25.85 2.45 3.6 1.25-.8-2.45-3.65m-1.85-12.2l5.2-7.8-1.15-.85-5.3 7.85 1.25.8m9-5.3l-1.25-.8-5.25 7.8 1.25.85 5.25-7.85m-1.9-1.25l-1.2-.8-5.3 7.8 1.25.8 5.25-7.8z" />
                    <path
                        fill="red"
                        d="M182.55 278.4q-.135-.493-.2-.95.225 1.842 1.1 3.35-.6-1.1-.9-2.4m15.15-9.45q-3-2.5-6.9-2.15-3.9.35-6.35 3.35-.55.65-.95 1.35l-.15.2q-.715 1.522-.25 3.15.45 1.9 2.1 2.9 1.7 1 3.55.5 1.9-.45 2.9-2.05.95-1.7 2.9-2.15 1.9-.45 3.55.55 1.65.95 2.1 2.85.45 1.9-.55 3.55-.7 1.2-1.65 2.1.45-.4.85-.9 2.5-3 2.15-6.9-.35-3.9-3.3-6.35z"
                    />
                    <path
                        fill="#0052CC"
                        d="M198 283.1q.95-.9 1.65-2.1 1-1.65.55-3.55-.45-1.9-2.1-2.85-1.65-1-3.55-.55-1.95.45-2.9 2.15-1 1.6-2.9 2.05-1.85.5-3.55-.5-1.65-1-2.1-2.9-.465-1.628.25-3.15-.15.35-.3.65l-.35.9-.4 3.75q0 .15.05.35.005.052 0 .1.065.457.2.95.3 1.3.9 2.4 1.15 2.05 3.3 3.35 1.1.7 2.3 1.05l.85.2q1.9.4 4-.1.55-.15 1.1-.35l3-1.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot069__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot069__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot069
