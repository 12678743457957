import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function ObjMor031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.25 15.8q-1.05-1.95-2.3-3.4-4.55-4.85-6.55-7-2.1-2.3-5.55-3.9Q9.8 0 7.85 0 4.25 0 1.7 2q-.4.3-.7.55-.95.9-1 1.55-.05.6.05.6.05 0 .2-.05Q.4 4.6.5 4.6q.45-.1.95-.1 3.2 0 5.45 2.25t2.25 5.45q0 .85-.1 1.7-.05.15-.1.35l-.05.1q-.15.45-.25.95-.9 1.85-2.1 3.55-.7.9-1.2 1.8-.45.65-.85 1.35-.6 1.05-1.05 2-1.3 2.95-1.3 5.35 0 3.8.9 6.05.9 2.3 4.2 6.55 1.3 1.7 3.35 4.1 5.3 6.6 11.25 8.25.15 0 .35.05.05.05.15.05.15 0 .25.1v-.75q0-3.45 2-3.5.05 0 .2.05.05-.05.1-.05.2 0 .5.15.2.1 1.15.1.65 0 2.15-.3l.2-.1q.2-.1.4-.15.25-.1.5-.15.05-.05.1-.05 5.6-2.85 8.05-7.15l.4-.55q.05-.1.1-.15.1-.3.2-.45.1-.2.15-.35.15-.15.25-.3.1-.3.3-.6.5-2.05.5-4.2l-.2-2.65q-.3.05-1.55.9-1.4.9-2.4.9-1.95 0-3.55-1.1-.5-.6-.95-1.2-1.6-2.65-1.9-6.05-.15-1.55-.25-2.8-.05-.45-.1-.95v-.55q0-1.75-.3-3.35-.1-.2-.1-.4-.8-1.9-1.3-2.95z"
                    id="ObjMor031__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.25 25.75q0 .05-.05.15-.05.1-.05.25-.8 2.05.85 4.95.3.5.55.75 1.25 1.2 2.9 1.8.2.05 1.15.25-1.05-.65-2.2-2.25-1.75-2.75-1.75-2.8-.75-1.35-1.15-3.05-.05-.3-.1-.5-.05.15-.1.4l-.05.05M9.45 35.6q-1-.7-1.8-1.4-3.05-1.95-3.85-3.7-.05.15-.05.4 0 2.25 3.2 4.9h.1l2.35 1.4q2.65 1.45 3.8 1.95 3.35 1.35 7.15 1.7l2.3.1q-.6.9-.9 2.7-.35 1.8-.4 3.85 0 2 .3 4.05.1.75.2 1.35.15 0 .25.1v-.75q0-3.45 2-3.5.05 0 .2.05.05-.05.1-.05.2 0 .5.15.2.1 1.15.1.65 0 2.15-.3l.2-.1q.2-.1.4-.15.25-.1.5-.15.05-.05.1-.05 5.6-2.85 8.05-7.15l-1.05 1.2q-.45.5-1 .95-1.8 1.8-3.65 2.75-1.25.65-2.5.95-.9.3-1.55.3-.85 0-1.5-.1-2.15-.5-2.15-2.4 0-1.05.6-3.4.3-1.25.65-2.05h-2.15q-1.6-.15-3.1-.4-2.6-.25-5.45-1.3-2.95-.7-5.15-2M8.3 2.55q-.15-.25-.35-.45Q6.25 0 4.4 0 3.15 0 1.5 1.05q-.3.2-.5.5-.25.3-1 1.55.45-.1.95-.1 3.2 0 5.45 2.25t2.25 5.45q0 .71-.1 1.4.039-.016.05-.05l.05-.1q.05-.35.15-.65.2-1 .35-1.85 0-.15.05-.25.1-.45.15-.85.75-3.3-1.05-5.8m6.4 6.9q0 .1 1.25 2.7 1.2 2.65 1.2 5.4 0 1.7-.9 4.25l-.2.7q-.75 2-.75 2.85 0 .9.25 1.5.05.05.95 1.7 1.4 2.5 2.5 3 .8 1 1.95 1.75.3.15.55.35h.15q-.25-.35-.55-.85-.25-.4-1-2.05-.1-.25-.2-.55-.7-1.45-.9-3-.1-.2-.1-.3-.4-2.45-.4-2.55v-.05q0-.2.05-.4 1.1-5.65.3-9-.1-.2-.15-.35-.85-2.15-2.25-4.1-2.15-2.35-2.9-3.35-.05.1-.05.25t1.25 1.9q-.05.05-.05.2z"
                    id="ObjMor031__Symbol_78_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor031__Symbol_139_0_Layer0_0_FILL"
                transform="translate(251.8 279.3)"
                id="ObjMor031__col1n"
            />
            <use
                xlinkHref="#ObjMor031__Symbol_78_0_Layer0_0_FILL"
                transform="translate(252.3 280.8)"
                id="ObjMor031__col1d"
            />
        </g></g>
    )
}

export default ObjMor031
