import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF5D5D"
}

function FacMou058({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M153.1 215.4q.193.267.4.5l11.45 9.5 61.55-6.3q3.25-4.023 4.7-6.9 2.41-4.264 0-5.8-1.335-.516-2.7-.75-2-.45-4-.55-.9-.1-1.75-.15-.15-.05-.25 0h-1.1q-2.188.003-4.4 0-27.336.274-55.2 2.9-5.133.47-7.15 1.1-2.122.618-2.7 3-.194 1.673 1.15 3.45z"
                    id="FacMou058__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M48.1 24.85l13.75-15.8q-9.3-4-20.15-6.75Q25.45-2.8 6.55 3.4l-.05.05q-.95.7-1.75 1.55Q.65 9.05 0 15.15l15.2 12.6q9.1 7.05 17.95 6.05 9.3-1.9 14.95-8.95z"
                    id="FacMou058__Symbol_125_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M70.95.05Q69.5 0 68.1 0 37.75-.25 5.5 2.95-.3 3.4 0 5.4q.05.95.35 1.8 8.35.3 16.7.3 17.75 0 35.15-1.1l4.1-.3q9.8-.75 19.4-1.85.75-2.05 0-2.9-.75-.85-3.45-1.2L71.8.1q-.6-.05-.85-.05z"
                    id="FacMou058__Symbol_83_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou058__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou058__Symbol_125_0_Layer0_0_FILL"
                transform="translate(164.65 210.1)"
                id="FacMou058__col2n"
            />
            <use
                xlinkHref="#FacMou058__Symbol_83_0_Layer0_0_FILL"
                transform="translate(154.15 206)"
                id="FacMou058__col1n"
            />
        </g></g>
    )
}

export default FacMou058
