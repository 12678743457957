import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#545D4F",
    "col1n": "#778072",
    "col2l": "#434343",
    "col2n": "#252525"
}

function AccHat032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M206.4 45.6q-.3-.2-.55-.35l.1-.15.05-.05q-.95-.65-1.95-1.25-8.55-4.45-22.4-7.75-6-1.4-12.3-2.4l-3.7-13.25q-1.8-5.9-7.1-9.55-18.3-9.7-35-3.65-1.55.55-3.1 1.25l-.15-.15q-1.4-1.25-3.95-2.75-.9-.5-1.7-.95-2.5-1.3-4.45-1.95l-.9-.5q-12.5-4.25-25.25 0-9.45 2.9-13.65 9.55-.1.1-.2.15L61.55 28.3l-5.6 12.25q-4.3 1.15-8.4 2.45-1.3.4-2.55.8-9.05 3.4-17.05 7.55Q9.6 60.95 3.4 71.15q-.1.15-.15.3v-.1q-.002.052-.05.1Q-.75 77.9.2 84.6q.1.65.25 1.3.05.4.15.8 2.95 11.4 20.2 19 6.65 2.85 15.4 5.2 33.6 8.9 78.6 4.15 4.25-.45 8.35-1 21.3-2.85 39.05-8.3 15.15-4.65 27.7-11.2.35-.2.75-.35.45-.25.9-.45 19.9-10.5 25.95-26.5.35-2.25.2-4.45v-.15q-.05-.6-.15-1.15v-.15q-1.35-9-11.15-15.75z"
                    id="AccHat032__Symbol_250_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M115.15 7.45q2.2-1.25 4.55-2.6-4.3-1.6-8.5-2.9l-1.35-.4q-2.6-.7-5.15-1.1-9.1-1.35-18.15 1.5-14.4 6.35-9.7 11.75 7.6 6.5 27.85-.8.2-.1.4-.15 4.7-2.35 10.05-5.3M189.9 90.4q.35-.2.75-.35.45-.25.9-.45 19.9-10.5 25.95-26.5.35-2.25.2-4.45v-.15q-.05-.6-.15-1.15v-.15q-1.35-9-11.15-15.75 10.2 12.45 4.75 22.85-2.25 4.25-7.2 8.25-23.4 22.05-80.65 30.05-16.15 2.8-33.4 3.2-25.35 1.6-45.95-3Q28.9 99.4 16.3 92.75-1 80 3.25 67.3H3.2q-3.95 6.45-3 13.15.1.65.25 1.3.05.4.15.8 2.95 11.4 20.2 19 6.65 2.85 15.4 5.2 33.6 8.9 78.6 4.15 4.25-.45 8.35-1 21.3-2.85 39.05-8.3 15.15-4.65 27.7-11.2z"
                    id="AccHat032__Symbol_161_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M129.3 13.15L126.05 0l-.4.1q-4.75 3.6-14.7 6.55-4.2 1.25-8.8 2.25v-.05q-1.1.25-2.2.45l-16.8 2.35H83Q34.6 14.3 5.95 1.15H5.9l-.1.15L.45 13.9Q0 15.1 0 16.35q0 1.8.95 3.55 12.55 10.4 36.4 13.9Q59 37.45 82.1 35.25l.9-.1.05.2q1.95-.3 3.9-.55l3.5-.4q6.55-.85 12.45-2.15l1.85-.25v-.25l.05.1q3.15-.8 6.15-1.7 19.1-5.7 19.1-13.8 0-1.65-.75-3.2z"
                    id="AccHat032__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M21.75 23.15v-.25L19.15.05V0q-1.1.25-2.2.45L.15 2.8H0v23.5l.05.2q1.95-.3 3.9-.55l3.5-.4Q14 24.7 19.9 23.4l1.85-.25M3.85 5.85l12.1-1.8 1.75 16.6L3.85 22.8V5.85z"
                    id="AccHat032__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat032__Symbol_250_0_Layer0_0_FILL"
                transform="translate(83.05 25.75)"
                id="AccHat032__col1n"
            />
            <use
                xlinkHref="#AccHat032__Symbol_161_0_Layer0_0_FILL"
                transform="translate(83.05 29.9)"
                id="AccHat032__col1d"
            />
            <use
                xlinkHref="#AccHat032__Symbol_73_0_Layer0_0_FILL"
                transform="translate(130.25 74.15)"
                id="AccHat032__col2n"
            />
            <use
                xlinkHref="#AccHat032__Symbol_40_0_Layer0_0_FILL"
                transform="translate(213.25 83)"
                id="AccHat032__col2l"
            />
        </g></g>
    )
}

export default AccHat032
