import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C40000",
    "col2n": "#000000"
}

function ShiSpe058({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.65 24l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95l-.2-.5q-.1 0-.35-.25-.25-.2-7.45-4.75-7.05-4.5-19.15-.15H32.6q-9.8-2.75-16.45 0-.95.4-1.85.9-7.8 4.35-8.55 4.8-.5 1.5-.95 3-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05L143 32.5q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V31.25q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85z"
                    id="ShiSpe058__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M144.25 11.5q-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15Q126.9 7.2 123 9.4q-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95Q41.05 15.1 31.4 9.9q-4.35-2.4-8.5-5.25L21.35 3.5q-1.45-1.05-2.8-2.1L16.7 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25-.45 1.1-.85 2.25 0 .05-.05.15-.15.4-.3.85-.1.35-.25.75-.1.2-.15.45.5-.1.75-.2 12.65-4.95 36.95-1.2 3.95.5 7.9.9 6.45.6 12.85 1.05l21.45.5 18.45-.6q5.55-.3 11.1-.8h-.5q4.45-.55 8.4-1.05 22.65-3.45 35.15.6l.6.2q.1.05.2.05-.1-.3-.2-.55-.1-.3-.15-.55-.1-.2-.15-.35l-.3-.8q-.3-.85-.55-1.65-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6z"
                    id="ShiSpe058__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiSpe058__Layer12_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe058__Symbol_139_0_Layer0_0_FILL"
                transform="translate(110 253.55)"
                id="ShiSpe058__col1n"
            />
            <use
                xlinkHref="#ShiSpe058__Symbol_140_0_Layer0_0_FILL"
                transform="translate(115.75 228.6)"
                id="ShiSpe058__col2n"
            />
            <use xlinkHref="#ShiSpe058__Layer12_0_FILL" />
        </g></g>
    )
}

export default ShiSpe058
