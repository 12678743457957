import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col1s": "#BD7B00",
    "col2n": "#000000"
}

function ObjMor015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFD79F"
                    d="M135.85 320l.2-5.5q-3.7-1.184-7.4 2.1v.05l3.25 4.5q2.712 1.634 3.95-1.15z"
                    id="ObjMor015__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M2.3 1.7q-1.25-.55-2.3.65L6.75 25.8q.5.5 1.2 1.3.35.4.75.85 1.1 1.25 1.9 2.6.1.15.2.35 1.05 1.9 1.4 2.9.35 1.2.35 3v.5q-.05 1.15-.3 2.35-.4 1.65-.95 2.1l2.2 7.55 7.4-2.1-4.4-15.25q-1.55-.05-2.8-.55-1.65-.7-2.8-2.15-.85-1.05-1.25-2.15-.45-1.15-.45-2.3 0-2.55 1.25-4.6.45-.75 1-1.4.55-.65 1-1L7.4.25q-2-.7-2.45.7Q3.35.1 2.3 1.7z"
                    id="ObjMor015__Symbol_125_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M13.5 49.3l2.3-.65-.8-2.8-.4-1.5q-.1-.2-.1-.35l-1.95-6.7q-.05 1.15-.3 2.35-.4 1.65-.95 2.1l2.2 7.55m3-17.35q-1.55-.05-2.8-.55l4.75 16.5 2.45-.7-4.4-15.25M2.3 1.7q-1.25-.55-2.3.65L6.75 25.8q.5.5 1.2 1.3.35.4.75.85 1.1 1.25 1.9 2.6L2.3 1.7m9.15 17.1q.55-.65 1-1L7.4.25q-2-.7-2.45.7l5.5 19.25q.45-.75 1-1.4z"
                    id="ObjMor015__Symbol_68_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M7.4 0Q3.4.15 0 2.1l.35 1.25 7.4-2.1L7.4 0z"
                    id="ObjMor015__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.8 5l.15-5L0 1.15l2.95 4.1q.1.05.2.15.1 0 .3-.05.1 0 .2-.1.1-.15.15-.25z"
                    id="ObjMor015__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M113.35 263.45l1.45 4.95 7.4-2.1-1.45-4.95q-5.167-2.251-7.4 2.1z"
                    id="ObjMor015__Layer4_0_FILL"
                />
                <path
                    fill="#F99"
                    d="M120.75 261.35l-1.6-5.55q-4.7-1.35-7.4 2.1l1.6 5.55 7.4-2.1z"
                    id="ObjMor015__Layer4_1_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor015__Layer13_0_FILL" />
            <use
                xlinkHref="#ObjMor015__Symbol_125_0_Layer0_0_FILL"
                transform="translate(115.15 267.3)"
                id="ObjMor015__col1n"
            />
            <use
                xlinkHref="#ObjMor015__Symbol_68_0_Layer0_0_FILL"
                transform="translate(115.15 267.3)"
                id="ObjMor015__col1d"
            />
            <use
                xlinkHref="#ObjMor015__Symbol_53_0_Layer0_0_FILL"
                transform="translate(114.8 266.3)"
                id="ObjMor015__col1s"
            />
            <use
                xlinkHref="#ObjMor015__Symbol_29_0_Layer0_0_FILL"
                transform="translate(131.9 320)"
                id="ObjMor015__col2n"
            />
            <use xlinkHref="#ObjMor015__Layer4_0_FILL" />
            <use xlinkHref="#ObjMor015__Layer4_1_FILL" />
        </g></g>
    )
}

export default ObjMor015
