import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400"
}

function ShiMot011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.05 11.95q0-2.15-1.75-3.9-1.7-1.65-4.9-2.8-2.6-.9-5.85-1.25h-.2V0h-2.4v3.8h-2.6l-.2.05V0h-2.4v4.1q-2.9.45-5.2 1.65Q5.7 7.2 4.1 9.45t-1.6 4.8q0 2.25 1.2 4.1 1.25 1.85 3.95 3.5 2.4 1.55 6.1 3.05v18.7q-2.5-.55-4.15-1.6-1.95-1.3-1.95-3.05l.05-1.75q0-1.45-.95-2.3-.95-.85-2.75-.85-1.75 0-2.9.95Q0 36 0 37.5q0 1.9 1.8 3.6 1.75 1.65 5.1 2.75 2.9 1 6.85 1.45v4.35h2.4V45.5h2.8v4.15h2.4V45.3q.1-.05.2-.05 3.9-.55 7-2 3.55-1.6 5.55-4.3 1.95-2.6 1.95-5.8 0-2.6-1.5-4.8-1.5-2.15-4.7-4.1-3.2-1.95-8.3-3.8 0-.05-.05-.05-.05-.05-.15-.05V5.2q.1 0 .2.05 2.2.25 3.25 1.05 1.4 1 1.8 3.2l.4 1.9q.35 1.75 1.2 2.6.85.8 2.45.8 1.55 0 2.45-.8.95-.8.95-2.05M18 5h.95v14.45l-.2-.05q-1.05-.45-1.3-.6-.3-.1-.65-.25-.35-.15-.45-.2l-.2-.1V5.1Q16.95 5 18 5m-7.45 2.4q1.3-1.3 3.2-1.85v11.6q-2.35-1.25-3.5-2.55-1.4-1.5-1.4-3.35 0-2.2 1.7-3.85m8.2 19.5l.2.1v16.9q-.95.1-1.55.1-.9 0-1.25-.05v-18.1l.45.15q.15.05.35.15.15.05.2.1l.3.15q.1 0 .15.05l1.15.45m2.6 1.15l.2.1q3.45 1.65 5.15 3.4 1.9 2 1.9 4.55 0 2.2-1.4 3.95-1.4 1.8-3.85 2.8-1 .4-1.8.65-.1 0-.2.05v-15.5z"
                    id="ShiMot011__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot011__Symbol_17_0_Layer0_0_FILL"
                transform="translate(174.45 256.3)"
                id="ShiMot011__col1n"
            />
        </g></g>
    )
}

export default ShiMot011
