import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#999999",
    "col2l": "#E2E2E2",
    "col2n": "#BFBFBF"
}

function ObjToo073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.7 2.2v.05Q-.55 3.6.45 5.9L5.1 18.1q2.1.1 4.35.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.35.3-.65.55 1.4 5.25 2.75 7.65 1.05 1.9 3.2 2.05 3.65-.15 6.3-2.45 1.5-1.5 1.1-3.65-.95-3.95-5.85-12.8-.65-1.2-1.2-2.5-1.1-2.4-2.05-4.95l.05-.05L8.9 2.65Q8.1.25 6.35.1 6.3.05 6.25.05 2.55-.25.7 2.2z"
                    id="ObjToo073__Symbol_234_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M44.85 11.45q-.05-.05-.3-.2Q34.2-2.65 21.9.65q-.6.15-1.15.35l-1.4.5v.05L13.8 3.7v-.05l-.05.05Q11 7.9 6.5 6.45L0 8.95 4.45 20.5l6.5-2.5q2.65-4 7.25-2.8l7.55 19.7 5.55-2.15-7.5-19.7 1.4-.5q.55-.25 1.1-.4 10.45-3.6 18.55-.7z"
                    id="ObjToo073__Symbol_233_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M0 10.15l1.3 3.4 6.5-2.5-1.3-3.4-6.5 2.5M41.7 4.5q-.05-.05-.3-.2Q33.35-1.85 24.3.75l-5 1.9 1.35 3.45 1.4-.5q.55-.25 1.1-.4Q33.6 1.6 41.7 4.5z"
                    id="ObjToo073__Symbol_231_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.75 11.55L7.3.05V0l-.05.05Q4.5 4.25 0 2.8l4.45 11.55q2.65-4 7.25-2.8h.05z"
                    id="ObjToo073__Symbol_232_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo073__Symbol_234_0_Layer0_0_FILL"
                transform="translate(108.1 270.25)"
                id="ObjToo073__col1n"
            />
            <use
                xlinkHref="#ObjToo073__Symbol_233_0_Layer0_0_FILL"
                transform="translate(83.05 237.55)"
                id="ObjToo073__col2n"
            />
            <use
                xlinkHref="#ObjToo073__Symbol_231_0_Layer0_0_FILL"
                transform="translate(86.2 244.5)"
                id="ObjToo073__col2l"
            />
            <use
                xlinkHref="#ObjToo073__Symbol_232_0_Layer0_0_FILL"
                transform="translate(89.55 241.2)"
                id="ObjToo073__col2d"
            />
        </g></g>
    )
}

export default ObjToo073
