import { AssetSvgProps } from "../../../shared/assets"

function ShiMot070({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M177.15 269.05q-1.75 0-3.2.75-.9.45-1.7 1.25-1.95 2-1.95 4.9 0 2.8 1.95 4.85.7.65 1.45 1.1 1.55.9 3.45.9 1.9 0 3.45-.9.65-.35 1.2-.9l.2-.2q2-2.05 2-4.85 0-2.9-2-4.9l-.2-.2q-.7-.65-1.45-1.05-1.45-.75-3.2-.75m0 9.4l-3.75 2.9 1.4-4.65-3.75-2.9h4.65l1.45-4.65 1.45 4.65h4.65l-3.75 2.9 1.45 4.65-3.8-2.9M159.2 264.5v2.2h66.55v-2.2H159.2m0 20.6v2.2h66.55v-2.2H159.2z"
                    id="ShiMot070__Layer5_1_FILL"
                />
                <g id="ShiMot070__Layer5_0_FILL">
                    <path
                        fill="#0052CC"
                        d="M159.2 258v7.4h66.55V258H159.2m0 28.4v7.4h66.55v-7.4H159.2z"
                    />
                    <path fill="red" d="M159.2 265.4v21h66.55v-21H159.2z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot070__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot070__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot070
