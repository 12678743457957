import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#006AC9",
    "col1l": "#43A6FF",
    "col1n": "#0087FF"
}
function LegPan035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7 0q-.2.05-.35.1Q62.1 12.35.85.2.6.1.35.1.2.05.1.05.05 0 0 0q0 .05.05.1v12.7l.05.05q63.65 3.25 125.15 0 .25-.05.45-.05V.05l.05-.05h-.05z"
                    id="LegPan035__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M129.75 323.1q-1.05-.15-1.45.1-1.15 1.5 0 2.6.35.3 1.45.25H255.6q.55-.05.75-.25 1.15-1.1 0-2.6-.3-.2-1-.15-.2 0-.45.05H129.75z"
                    id="LegPan035__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.4 6.4h2L2.8 0H0l.4 6.4z"
                    id="LegPan035__Symbol_83_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan035__Symbol_82_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan035__col1n"
            />
            <use
                xlinkHref="#LegPan035__Symbol_84_0_Layer0_0_FILL"
                id="LegPan035__col1l"
            />
            <use
                xlinkHref="#LegPan035__Symbol_83_0_Layer0_0_FILL"
                transform="translate(191.1 319.65)"
                id="LegPan035__col1d"
            />
        </g></g>
    )
}
export default LegPan035
