import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacNos006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4 0Q2.3 0 1.1 1.15-.05 2.35 0 4q-.05 1.65 1.1 2.8Q2.3 8 4 8q1.65 0 2.8-1.2Q8 5.65 8 4T6.8 1.15Q5.65 0 4 0z"
                    id="FacNos006__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos006__Symbol_15_0_Layer0_0_FILL"
                transform="translate(188.5 196.85)"
                id="FacNos006__col1n"
            />
        </g></g>
    )
}

export default FacNos006
