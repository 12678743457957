import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiMed029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.5 196.05q-1.85-1.85-3.55-3.7-1.7 2.65-.95 12.35.7 9.7 12.45 33.35-7.1-29.25-3.2-37.55-.75-.65-1.5-1.35-1.65-1.5-3.25-3.1m138.85-3.65q-1.75 1.85-3.55 3.65-1.6 1.6-3.2 3.1-1.25 1.15-2.55 2.3 5.75 5.8 2.55 35.85 12.05-30.75 6.75-44.9m-160.2-28.05l-.4-.95q1.6 7.9 3.5 23.35 1.9 15.45 14.2 45.2-3.3-37.65-1.45-41.85l.1-.2Q29.8 177.25 24.45 165q-.15-.35-.3-.65M201.1 68q13.2.25 30.95 27.05-12.9-59.7-62.9-44.95 16.1-14.85 44.4 1.1-31.5-30.6-57.6-8.75-13-35.05-35.2-42.25-.35-.1-.65-.2l.3.2q20.95 15.5 21.7 36.85Q127.05 9.9 104.8 2.2q23.7 16.35 25.8 32Q112 4.6 71.6 3.9q40.9 11.6 43.45 28.8Q83.75 5.1 52.2 12.9q-5.3 1.3-10.6 3.6 5.6-.8 10.6-.95 26.7-.7 36.7 17.55-16.5-8.55-36.7-9.75-13.7-.85-29.1 1.7 16.35-.95 29.1 2.9 8.3 2.45 15.05 7-8-.9-15.05.2Q19.9 40 7.8 85.9 27.2 50 52.2 46.55q4.7-.65 9.55-.15-5.15.6-9.55 1.95-7.25 2.2-12.55 6.5-8.5 6.8-11.7 10.85-36.2 40.75-25.5 85.05 10.7 44.35 27.6 66.8-8.1-36.2-6.3-54.15-.1.55-.3.05l.3-.05v-.1q.65-21.5 5.8-31.85-.75 22.65 7.95 39.7l.1-.05v.3q1.05 2.05 2.25 4.05 2.65 4.55 5.75 8.65-7.8-46.45 12.65-83.9 3.9-7.15 8.85-14-4.2 6.75-7.45 14-16.5 36.75-9.05 86.4 10.4-59.9 38.25-86.4 6.2-5.9 13.25-10.15-3.75 4.9-6.3 10.15-6.75 14-4.7 30.35 2.7-16.15 13.3-30.35 9.65-13.05 25.95-24.45-11.1 13.2-16.7 24.45-4.9 9.75-5.7 18.1 4.5-9.65 10.55-18.1 12.95-18.25 33-30.9 20.7 9.05 26.3 30.9 2.3 8.95 2.05 20.1 2.65-10.9 2.5-20.1-.35-20.8-15.1-32.9 17.3 10.55 18.9 32.9 1.1 15.75-5.55 37.35 12.75-19.55 14.15-37.35.4-5.3-.2-10.45 3.55 4.5 5.3 10.45 7.85 26.5-19.25 82.4 26.15-29.35 23.85-47.55.75 4.15 1.2 10.05 0 .75.1 1.5l.35 5.6q-5.3 21.5-20.55 38.85l-.1.1q4.05 5 4.3 36.7 6.6-34 7.2-42.85 0 .85.05 1.7.3 4.7.85 30.15 6.45-28.85 8.4-54.65.35 13 1 38.4Q232.2 136.3 223 100.2q-5.3-20.75-21.9-32.2z"
                    id="HaiMed029__Symbol_85_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M70.3 16.7q1.3 0 2.8.15Q54.6 5.65 34.55 0 44.05 6.05 50 12.8q-15.9-4.5-28.15-1.25 11.05 1.2 20.2 5.8Q35 17.8 24.2 21.5q-10.85 3.7-16.9 9.7-6.05 5.95-7.3 7.3 12.25-10.35 35.55-11.45 1.65-.15 3.1-.15 3.55.05 5.85 1.1-15.45 6.6-20 13.65-2.2 3.4-4.3 6.95 2.05-1.6 5-3.7 7.95-5.65 12.25-6.3 9.2-3.5 20.1-2.65-13.25 15.3-18.4 30.75 14.05-15.55 25.6-18.8Q76.3 44.6 92.3 31.8 78.05 40.1 66.5 35q2-3.9 13-10.5-8.55-1.8-14.4-2-5.8-.2-6.65-.6 2.5-3.95 11.85-5.2m-6.6 1.2h-.05q.25-.25.55-.45l-.5.45m93.9 5.55q-9.8-1.35-17.55-.3-6.85.95-8 .85.9.25 5.05 1.65 5.75 1.85 10.4 5.85-9.95-1.8-19.9 0 9.15.45 14.6 7.5 5.85 7.2 7.65 13.2 1.75 6.05 3 11.8.75-10.8-1.85-22 18.05 8 22.35 27.45 1.75-13.85-18.25-37.1.1-.1 5.5-1t17 5.25q-10.35-11.85-20-13.15z"
                    id="HaiMed029__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed029__Symbol_85_0_Layer0_0_FILL"
                transform="translate(79.3 25.3)"
                id="HaiMed029__col1n"
            />
            <use
                xlinkHref="#HaiMed029__Symbol_28_0_Layer0_0_FILL"
                transform="translate(116.9 55.9)"
                id="HaiMed029__col1l"
            />
        </g></g>
    )
}

export default HaiMed029
