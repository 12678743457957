import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col1s": "#49657E",
    "col2n": "#000000"
}
function SkiSki013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.5 95.8q0-39.7-28.05-67.75-16.2-16.2-36.3-23.05-14.7-5-31.4-5t-31.4 5q-20.1 6.85-36.3 23.05Q0 56.1 0 95.8q0 32.25 18.5 56.85l.05.05q.6.75 1.2 1.55l1 1.2L28 163.5q0 .05.05.05l.7.7q3.5 3.4 7.15 6.4v.05l-.05-.05q-5.81 7.718-10.6 17.75-.078.183-.2.35l-.3.7q-.06.17-.15.35-.117.253-.25.5l-2.6 6.15q-1.717 4.388-3.2 9.05-3.094 9.855-5.1 21.05-.05.2-.05.4-.15 2.45.15 4.85Q15.7 245.9 31 249.35q.2.05.45.1l.05-.05q.15.05.25.05.2.6.4 1.15.4.95 1.1 1.75 0 .05.05.1v19.45q-3.9.7-7.8 2.5-.6.267-1.2.55-.94.67 0 .8h143.25q1 0 0-.6-.775-.405-1.55-.75-3.9-1.8-7.8-2.5v-19.45q.05-.05.05-.1.7-.8 1.1-1.75.2-.55.4-1.15.1 0 .25-.05l.05.05q.25-.05.45-.1 15.3-3.45 17.45-17.55.3-2.4.15-4.85 0-.2-.05-.4-3.7-20.65-11.15-36.75-4.8-10.45-11.15-18.95h-.05q0-.1-.1-.2 3.65-3 7.15-6.4l.7-.7q.05 0 .05-.05l7.25-8.05 1-1.2q.6-.8 1.2-1.55l.05-.05q18.5-24.6 18.5-56.85z"
                    id="SkiSki013__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M-36.05 227.75h-.2l-3.9.15-1.3.1q-8.5.6-13.15 1.65-4.7 1-5.35 1.3-.15.05-.25.1 8-1.3 16.1-1.8.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q15.4-.65 30.05 2l4.15.7q1.15.2 2.3.45 1.75.35 3.45.75l.3.1q.1 0 .2.05.15 0 .3.05-.4-.15-.8-.25-1.7-.6-3.45-1.05-.55-.15-1.05-.3-.15-.05-.25-.05-10.8-3-22-3.6-.4-.05-.8-.05-.55-.05-1.15-.05l-6.5-.05m80.85 1.3h.3q.15 0 .35.05l2 .1q.35 0 .65.05 8.1.5 16.1 1.8-.1-.05-.25-.1-.65-.3-5.35-1.3-4.65-1.05-13.15-1.65l-1.3-.1-3.9-.15h-.2l-6.5.05q-.6 0-1.15.05-.4 0-.8.05-11.2.6-22 3.6-.1 0-.25.05-.5.15-1.05.3-1.75.45-3.45 1.05-.4.1-.8.25.15-.05.3-.05.1-.05.2-.05l.3-.1q1.7-.4 3.45-.75 1.15-.25 2.3-.45l4.15-.7q14.65-2.65 30.05-2z"
                    id="SkiSki013__Kopie_von_hll_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M-54 165.45q-.05.05-.05.1l-4.3 21.85q-.45 2.1-.85 4.05l-.1.6q-.2.8-.35 1.6-1.5 7.35-2.3 12.15-.75 4.4.45-.6.4-2.5 1.45-6.25.1-.6.25-1.25.7-3.15 1.3-5.65.65-3.05 1.15-5.1h.05l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85l3.25-16.8.05-.05q0-.25-.1-.4-.1-.2-.3-.2-.2-.05-.3 0-.05 0-.05.05-.05 0-.1.05t-.1.2m81.8 53.1q5.828-.808 11.3-1.95-.15 0-.3.05-.95.05-1.9.15-.75.05-1.5.15-13.1 1.2-26.3 1.45-4.5.1-9 .1-17-.1-34.1-1.8-.65-.1-1.2-.15 5.808 1.176 11.85 2 11.28 1.525 23.45 1.85h.55v.1l1.4 12.2v-.4l.05.4 1.4-12.2v-.1h.05q.15 0 .35.05l5.2-.2q9.762-.455 18.7-1.7m30.1-53.2l-.05-.05q-.15-.05-.35 0-.2 0-.3.2-.1.15-.05.4l3.25 16.85q.1.2 1.05 4.3v.05q.45 2 1.1 4.95.55 2.4 1.2 5.4l.1.4q.25 1.2.5 2.25 1 3.35 1.35 5.75 1.05 4.3.3.1-.9-5.3-2.65-13.9-.4-2.15-.9-4.5L58.1 165.7v-.1q-.1-.2-.2-.25m2.25-34.1q-1.35 1.05-2.8 2.1l-1.55 1.15q-20.35 14.1-45.8 16l-1.1.05q-3.35.2-6.8.2h-.2q-3.45 0-6.8-.2l-1.1-.05q-25.45-1.9-45.8-16l-1.55-1.15q-1.45-1.05-2.8-2.1l-1.15-.9q10.019 8.81 21.45 14.1 17.928 7.84 37.75 7.85h.2q19.823-.01 37.75-7.85 11.45-5.3 21.45-14.05l-1.15.85z"
                    id="SkiSki013__hdd_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M54.5 264.45q2.7-2.7 2.7-6.55 0-3.85-2.7-6.55-2.7-2.7-6.55-2.7-3.85 0-6.55 2.7-2.7 2.7-2.7 6.55 0 3.85 2.7 6.55 2.7 2.7 6.55 2.7 3.85 0 6.55-2.7M64 226.1q6.3 0 10.75-4.5 4.5-4.45 4.5-10.75t-4.5-10.8Q70.3 195.6 64 195.6t-10.8 4.45q-4.45 4.5-4.45 10.8 0 6.3 4.45 10.75 4.5 4.5 10.8 4.5m78.95 8.3q-5 0-8.5 3.5t-3.5 8.5q0 5 3.5 8.5t8.5 3.5q5 0 8.5-3.5t3.5-8.5q0-5-3.5-8.5t-8.5-3.5M130.8 193q-1.6 1.6-1.6 3.9t1.6 3.9q1.6 1.6 3.9 1.6t3.9-1.6q1.6-1.6 1.6-3.9t-1.6-3.9q-1.6-1.6-3.9-1.6t-3.9 1.6m-94.75-13.45q-3.3 0-5.65 2.3-2.3 2.4-2.3 5.7 0 3.25 2.3 5.6 2.35 2.35 5.65 2.35 3.3 0 5.6-2.35 2.4-2.35 2.4-5.6 0-3.3-2.4-5.7-2.3-2.3-5.6-2.3m-9.1-68.8q-8.55-8.6-20.7-8.6-1.1 0-2.1.1-2.15.15-4.15.6 1.95 25.7 16.45 46.3.85 1.2 1.75 2.4l.05.05q.6.75 1.2 1.55l1 1.2 1.6 1.8q2.6-1.75 4.9-4.05 8.55-8.55 8.55-20.7 0-12.1-8.55-20.65m38.1-84.85q-4.25-4.25-10.2-4.25-5.95 0-10.25 4.25-4.25 4.25-4.25 10.25t4.25 10.2q4.3 4.25 10.25 4.25t10.2-4.25q4.25-4.2 4.25-10.2 0-6-4.25-10.25m21.45-4.25q-2.3 0-3.9 1.6t-1.6 3.9q0 2.3 1.6 3.9t3.9 1.6q2.3 0 3.9-1.6t1.6-3.9q0-2.3-1.6-3.9t-3.9-1.6m46.05 3.45q9.15 0 15.6-6.5 1.25-1.25 2.25-2.55-2.2-1.55-4.4-2.95-9.1-5.75-19.15-9.2-7.8-2.65-16.2-3.9-.2 1.45-.2 3 0 9.15 6.45 15.6 6.5 6.5 15.65 6.5m34.05 163.6q-2.1-4.6-4.5-8.8-1.25-2.15-2.55-4.2-.3-.5-.6-.95-5 6.05-5 14.15 0 9.3 6.55 15.85 5.95 5.95 14.15 6.55-.1-.35-.2-.65-3.2-11.85-7.85-21.95m7.15-48.85q0-3.65-2.55-6.2-2.55-2.55-6.25-2.55-3.65 0-6.2 2.55-2.6 2.55-2.6 6.2 0 3.7 2.6 6.25 2.55 2.55 6.2 2.55 3.7 0 6.25-2.55t2.55-6.25z"
                    id="SkiSki013__Symbol_11_0_Layer0_0_FILL"
                />
                <filter
                    id="SkiSki013__Filter_1"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    colorInterpolationFilters="sRGB"
                >
                    <feColorMatrix
                        in="SourceGraphic"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 0.75 0"
                        result="result1"
                    />
                </filter>
            </defs>
            <use
                xlinkHref="#SkiSki013__Symbol_10_0_Layer0_0_FILL"
                transform="translate(96.75 58)"
                id="SkiSki013__col1n"
            />
            <use
                xlinkHref="#SkiSki013__Kopie_von_hll_12_0_Layer0_0_FILL"
                transform="matrix(1.00052 0 0 1 190.5 98.75)"
                id="SkiSki013__col1d"
            />
            <use
                filter="url(#SkiSki013__Filter_1)"
                xlinkHref="#SkiSki013__hdd_0_Layer0_0_FILL"
                transform="matrix(1.00052 0 0 1 190.5 98.75)"
                id="SkiSki013__col1s"
            />
            <use
                xlinkHref="#SkiSki013__Symbol_11_0_Layer0_0_FILL"
                transform="translate(97.05 59.1)"
                id="SkiSki013__col2n"
            />
        </g></g>
    )
}
export default SkiSki013
