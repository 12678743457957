import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M176.3 38.2q-5.2-6.25-11.75-10-.1-.05-.25-.15-6.65-3.65-13.3-7.15-7.05-3.65-13.85-6.7-6.15-2.85-11.7-4.65l.8-8.7q.1-.45.25-.85-.9.4-1.8.9-5.25 2.9-9.5 6.7-1.05-.75-2.1-1.4-5-3.2-10.55-5.05-.05-.05-.1-.05.45 1.3.95 2.6l.45 1.2Q104.4 6.45 105 8q.05.1.1.25.05.05.05.1-7.75-.3-16.25-1.05h-.1q-8.5-.8-14.25 1.85-5.8 2.6-13.75 3.8-7.85 1.15-14.55 4.8-6.8 3.65-12.65 8.8-5.75 4.9-11.45 10.5Q16.5 42.6 11.2 48.5h-.05q-1 2.4-1.8 4.8-2.55 7.2-4.4 14.85-1.8 7.6-3.7 15.3-1.95 7.5-.9 15.5.75 6.45 2.3 12.55 2.7-6.05 4-12.55Q8.1 91.3 9.8 83.35q1.6-7.5 5.6-13.4l.6-.9q4.3-5.9 9.95-11.3 3.15-3 7.3-5.85 4.75-3.3 11.4-5.1 0 .6-.05 1.2-.1 2.2-.55 4.35-.25 1.05-.55 2.05v.1l-.05.15q-.4 1.05-.8 2.15l-.8 1.7q-1.5 3.15-3.4 6l-1.8 2.55q-.3.35-.6.75 0 .05-.05.05l-.6.7q5.75-1.55 10.35-3.8 5.75-2.95 10.05-8.8 2.75-3.7 5.5-7.1 8.6-8.8 10.4-8.35.25.35.45.65.3.45.6.95l1.8 7.85q0 .6-.05 1.15 0 .2-.05.45-.6 5.5-4.15 9 5.15-2.4 9.8-5.95 5.1-3.95 8.7-8.95.55-.85 1.1-1.65.05-.1.15-.15-.2.35-.4.75l5.6-8.55 9.2 9.85q-1.4-1.85-2.6-3.65l1.4 1.9q2.15 3 4.55 6 4.7 5.7 10.7 10.1 3.65 2.7 7.9 4.25-1.45-1.5-2.6-3.2l.1.1q-.3-.45-.6-.95l-2.75-6.35q-.85-3.1-1.4-6.25 0-.15-.05-.3-.4-2.25-.65-4.5l-.25-2.2q-.1-1.15-.15-2.25 5.2.4 12.45 6.4 2.25 2.2 4.25 4.4 5.8 6.2 11.7 10.45 6.2 4.35 13.85 5.95 1.95.4 4 .6.35 0 .75.05h.35q-.55-.4-1.1-.85-4.1-3.35-7.8-7.65-2.75-3.25-4.2-7.15-.75-2.1-1.15-4.4l-.15-.75-.1-.8q0-.1-.05-.25v-.3q-.05-.2-.05-.4-.05-.1-.05-.15-.15-1.5-.35-2.8-.1-.4-.15-.75l-.1-.4q5.95 2.75 10.05 8.85 1.8 2.65 4.1 5.25 1.45 1.55 3.05 3.1 5.8 5.65 11.45 10.95 2.6 2.55 4.5 5.4 2.45 3.7 3.6 7.95 2.15 7.75 3.85 15.5 1.45 7.1 2.2 12.1.7 4.95 1.55 2.05.85-2.9 1.9-9.15 1.05-6.25 1.3-14.05.25-7.75-1.4-15.05-1.2-5.05-2.65-10.05-.65-1.95-1.35-3.9-2-5.65-6.3-10-5.15-5.2-9.7-10.55z"
                    id="HaiSho029__Symbol_150_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M29.3 46.45q0 .6-.05 1.2-.1 2.2-.55 4.35-.25 1.05-.55 2.05v.1l-.05.15q-.4 1.05-.8 2.15l-.8 1.7q-1.5 3.15-3.4 6l-1.8 2.55q-.3.35-.6.75.972-.77 1.85-1.55 3.8-3.4 5.85-6.9.9-1.45 3.05-4.6.85-1.3 1.95-2.8 7.15-14-3.25-11.15-7 1.6-17.05 10.95Q1.85 61.8 0 68.2v1.4h.05l.6-.9q4.3-5.9 9.95-11.3 3.15-3 7.3-5.85 4.75-3.3 11.4-5.1m16.65 2.05q8.6-8.8 10.4-8.35.25.35.45.65.3.45.6.95l1.8 7.85q0 .6-.05 1.15 0 .2-.05.45 0 .3-.05.6-.6 4.2-3 7.2 3.8-4.3 4.95-7.4.15-.45.25-.85 1.15-3.8 1.75-6.05 1-4 1-6.85 0-1.6-.35-3.3-.2-1-.65-2.9l-5.1 3q-.5.3-1 .75-.1.05-.2.15-8.6 5.75-12.95 15.2 1.15-1.2 2.2-2.25m51.95-3.75q.55 1.45 1.5 3.2.15.25.3.55 2.05 3.5 2.3 4 1.35 2.6 4.25 5.95 1.25 1.4 2.2 2.5l.1.1q-.3-.45-.6-.95l-2.75-6.35q-.85-3.1-1.4-6.25 0-.15-.05-.3-.4-2.25-.65-4.5l-.25-2.2q-.1-1.15-.15-2.25 5.2.4 12.45 6.4 1.4 1.15 2.9 2.55-11.3-13.55-22.4-13.8v3.5q.2 2.6 2.25 7.85m-8.8.8q-1.4-1.85-2.6-3.65Q82 35 79.65 28.15 78 36.25 74.7 43.5q-.2.35-.4.75l5.6-8.55 9.2 9.85M93.75 7.8q0-.45-1.55-2.1-.6-.65-1.35-1.4-1.5-1.5-3.65-3.5-.05-.05-.1-.05.45 1.3.95 2.6l.45 1.2q.55 1.55 1.15 3.1.05.1.1.25.05.05.05.1.3.4 1.15.5 1.15.05 2 .05.8-.05.8-.75m14-1.25q0 1.15.9 1.85 1 .75 1.4 1.15l.9-9.55q-.55.5-.65.6-1.5 1.7-2.1 3.45-.25.65-.35 1.35-.1.55-.1 1.15m55.7 61.05q2.6 2.55 4.5 5.4-2.05-4.95-4.65-7.8-2.6-2.85-5.55-6.25-3-3.4-4.7-6.5-1.75-3.1-2-3.75-.25-.55-2.1-2.95-.35-.45-.7-.95-2.55-3.3-2.75-3.55-1.85-2.2-1.9-2.25-1.15-1.3-2.35-2.15-.85-.6-2.65-1.25-.35-.1-.65-.2-9.45-3.45-8.5 6.35-.05 1.3.9 3.35.95 2.05 2.9 5.55 1.95 3.45 5.2 6.65 3.25 3.15 4.55 4.25 2.85 2.35 5.95 4.15.35.2.75.4h.35q-.55-.4-1.1-.85-4.1-3.35-7.8-7.65-2.75-3.25-4.2-7.15-.75-2.1-1.15-4.4l-.15-.75-.1-.8q0-.1-.05-.25v-.3q-.05-.2-.05-.4-.05-.1-.05-.15-.15-1.5-.35-2.8-.1-.4-.15-.75l-.1-.4q5.95 2.75 10.05 8.85 1.8 2.65 4.1 5.25 1.45 1.55 3.05 3.1 5.8 5.65 11.45 10.95z"
                    id="HaiSho029__Symbol_74_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho029__Symbol_150_0_Layer0_0_FILL"
                transform="translate(94.15 49.6)"
                id="HaiSho029__col1n"
            />
            <use
                xlinkHref="#HaiSho029__Symbol_74_0_Layer0_0_FILL"
                transform="translate(109.5 49.95)"
                id="HaiSho029__col1d"
            />
        </g></g>
    )
}

export default HaiSho029
