import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M211.25 217.9q.073-.184.05-.4-.032-.157-.15-.3l-1.55-1.85q-.105-.114-.25-.2-.143-.046-.3-.05H182.8q-.172-.004-.35.05l-10.4 5.55q-.196.146-.3.35-.093.245-.05.45.084.256.25.4l13.65 10.2q.172.118.35.15l18.65 2.35q.254.03.45-.1.225-.124.3-.35l5.9-16.25z"
                    id="FacMou027__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M0 2.6v2.35h6.25V0H4.9L0 2.6m14.1 2.35V0H7.05v4.95h7.05m7.5 0V0h-6.7v4.95h6.7m.8 0h6.95V0H22.4v4.95z"
                    id="FacMou027__Symbol_52_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou027__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou027__Symbol_52_0_Layer0_0_FILL"
                transform="translate(178.1 216.5)"
                id="FacMou027__col1n"
            />
        </g></g>
    )
}

export default FacMou027
