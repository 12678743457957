import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0q15 53.45 14.15 135.5Q32.25 24.8 0 0z"
                    id="HaiHig010__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig010__Symbol_14_0_Layer0_0_FILL"
                transform="translate(242.7 77.85)"
                id="HaiHig010__col1n"
            />
        </g></g>
    )
}

export default HaiHig010
