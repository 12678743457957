import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E4B670",
    "col2n": "#000000"
}
function FacMou100({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M9.5 8.45h44.05q7.828-1.896 11.65-8.9H-.7Q.823 4.934 9.5 8.45z"
                    id="FacMou100__Symbol_175_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M56.35 7.05q-2.85-.45-6.2-2.2l-.3-.2q-2.1-1.35-3.7-2.25-.65-.3-1.35-.6-5-1.85-9.6-1.8h-.1q-4.6 0-9.6 1.8-.7.3-1.35.6-1.6.9-3.7 2.25l-.3.2q-3.35 1.75-6.2 2.2Q3.65 8.55.6 19.9q-1.65 5.9 1.35 11.25 1.3 2.1 2.2 3.2.887 1.035 3.2 2.7h.05l.05.05q2.4 1.6 5.7 2.6 3.3 1 5.4 2.25 2.2 1.2 3.25 2.2 1.35 1.25 3.35 2.1 9.9 4.05 20 0 2-.85 3.35-2.1 1.05-1 3.25-2.2 2.1-1.25 5.4-2.25 3.3-1 5.7-2.6.05 0 .1-.05h-.05q2.363-1.665 3.25-2.7.9-1.1 2.2-3.2 3-5.35 1.35-11.25Q66.65 8.55 56.35 7.05M46.1 35.85l-.6-.2q-4.4-1.1-8.75-1.25h-.3q-.15-.05-.3-.05h-2q-.15 0-.3.05h-.3q-4.35.15-8.75 1.25l-.6.2q-6.9 1.8-13.4 0-1.4-.4-2.55-1.05-1.2-.65-1.7-1t-1.15-.95q-.7-.65-1.7-1.95-1-1.35-.5-1.05 1.15 1 3.35 2.25 3.1 1.8 7.1 2.3 6.45.45 11.15-1 1.7-.4 3.35-.7 7-1.2 14 0 1.65.3 3.35.7 4.7 1.45 11.15 1 4-.5 7.1-2.3 2.2-1.25 3.35-2.25.5-.3-.5 1.05-1 1.3-1.7 1.95-.65.6-1.15.95-.5.35-1.7 1-1.15.65-2.55 1.05-6.5 1.8-13.4 0z"
                    id="FacMou100__Symbol_174_0_Layer0_0_FILL"
                />
                <path
                    d="M208.1 202.75q0-2.65-3.05-3.9-12.05-4-25.05 0-.85.4-1.5.85-1.6 1.15-1.6 3.05.5 3.05 5.3 6.95 2.55 2.15 4.8 3.4.8.45 1.6.8 3.55 1.7 7.9 0 .7-.3 1.55-.8.8-.45 1.75-1.05 1.45-1 3.1-2.35.25-.15.45-.3 4.3-3.75 4.75-6.65z"
                    id="FacMou100__Layer1_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.902}
                    d="M186.3 204.55q5.85 1.55 12.45 0 2.4-.6 2.4-1.45 0-.4-.55-.55-.75-.1-3.65-.15h-8.9q-2.9.05-3.65.15-.55.15-.55.55 0 .85 2.45 1.45z"
                    id="FacMou100__Layer1_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou100__Symbol_175_0_Layer0_0_FILL"
                transform="translate(160.4 219.55)"
                id="FacMou100__col2n"
            />
            <use
                xlinkHref="#FacMou100__Symbol_174_0_Layer0_0_FILL"
                transform="translate(157.35 189.75)"
                id="FacMou100__col1n"
            />
            <use xlinkHref="#FacMou100__Layer1_0_FILL" />
            <use xlinkHref="#FacMou100__Layer1_1_FILL" />
        </g></g>
    )
}
export default FacMou100
