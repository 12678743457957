import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col2d": "#252525",
    "col2n": "#434343"
}
function LegSho016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M149.6 8.2q-1.6-2.05-4.05-2.3-1.85-.35-3.25-1-3.5-2-4.05-4.45-1.95-1.2-11.25.9h-.05q-.2.05-.35.05-.2.05-.45.1h-.2q-1.8.25-4.1.3-2.25.05-4.45-.45-.45-.1-.95-.25-.3-.1-.6-.15-6.05-1.25-11.7 0-.25.05-.45.1-.55.1-1.4.3-.65.15-1.4.3-1.6.3-2.95.3-1.5 0-3.9-.5-1.35-.3-2.35-.5-5.65-1-10.55 0-1.5.4-2.65 1.05-2.05 1.15-2.85 3.15-.8-2-2.85-3.15-1.15-.65-2.65-1.05-4.9-1-10.55 0-1 .2-2.35.5-2.4.5-3.9.5-1.35 0-2.95-.3-.75-.15-1.4-.3-.85-.2-1.4-.3-.2-.05-.45-.1-5.65-1.25-11.7 0-.3.05-.6.15-.5.15-.95.25-2.2.5-4.45.45-2.3-.05-4.1-.3-.1 0-.25-.05-.3-.05-.55-.05Q15-.75 13 .45q-.6 2.6-4.45 4.65-1.25.5-2.8.8-2.45.25-4.05 2.3-1.9 1.85-1.65 4.25v.15q0 .7.35 1.4v.05l-.1.4q1.35 1.25 2.35 2.25.1-.05.25-.1 2.3 1.4 5.3 1.1 1.65-.15 2.95-.75l.5-.2q1.15-.5 2.25-1.35.5-.45.95-1 2.05-2.45 2.8-2.85.7-.4 1.25-.75t1.4-.5h.25q.45-.05 1.15.1v.6q-.05 1.2 0 1.6.05.55.25 1.1-.05.1-.05.2-.05.05-.05.1v.05l2.15 2.7q1.95 1.5 5.45 1.5 2.25 0 4.55-1.25 1.75-1 2.55-2.1.15-.2.25-.4.3-.55 1.3-1.7.95-1.15 2.35-1.95 1.35-.8 2.35-.95.95-.15 1.55-.05.7.1 1.45.35-.1.65-.1 1.45 0 1.05.25 1.8-.05.1-.05.25-.05.1-.05.2-.05.05-.05.1v.05l-.1.4q1.2 1.3 2.15 2.35.05 0 .1-.05 1.95 1.5 5.45 1.5 2.25 0 4.55-1.25 2.05-1.15 2.8-2.5 1.8-3.45 2.45-4.1.25-.25.55-.45.1-.1.2-.15H64q1.6-.7 2.35-.6.65.05 1.25.15.55.1 1.25.35h.1q2.2.65 3.85.55 1.05-.1 1.85-.55.35-.2.75-.5.15-.15.25-.35.1.2.25.35.4.3.75.5.85.45 2 .55 1.6.1 3.7-.55h.1q.7-.25 1.25-.35.6-.1 1.25-.15.75-.1 2.35.6h.05q.1.05.2.15.3.2.55.45.65.65 2.45 4.1.75 1.35 2.8 2.5 2.3 1.25 4.55 1.25 3.5 0 5.45-1.5 1.15-1.9 2.05-3v-.05q0-.15-.05-.25.25-.75.25-1.8 0-.8-.1-1.45.75-.25 1.45-.35.6-.1 1.55 0 .9.05 2.4.9 1.45.85 2.4 2.05.9 1.15 1.2 1.7.1.2.25.4.8 1.1 2.55 2.1 2.3 1.25 4.55 1.25 3.5 0 5.45-1.5.95-1.6 2.1-3l-.05-.05q.2-.55.25-1.1.05-.4 0-1.6v-.6q.7-.15 1.15-.1h.25q.85.15 1.4.5.55.35 1.25.75.75.4 2.8 2.85.45.55.95 1 1.1.85 2.25 1.35l.5.2q1.3.6 2.95.75 3 .3 5.8-1.75 1.45-1.1 2.15-1.35-.05-.1-.05-.15l-.1-.4V14q.35-.7.35-1.4v-.15q.25-2.4-1.65-4.25z"
                    id="LegSho016__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.35 1.6Q11.6.75 11.7 0q-.85.8-2 1.1-1.15.25-3.8.85-2.7.55-4.25 2.15Q.55 5.25.15 6.7q-.05.2-.1.45Q0 7.3 0 7.5v.05h.15q.45-.05 1.15.1h.15V7.6q.2-1.4.9-2.3.9-1.3 2.9-2.05Q5.9 3 7.5 2.7q1.55-.35 2.85-1.1M35.65 0q-1.3.95-2.25 1.2-.95.2-3.6.75-2.65.55-4.2 2.15-.75.8-1.2 1.75l-.1.3q-.1.15-.35.95.7.1 1.45.35.05-.1.05-.25 0-.05.05-.05.1-.5.3-.9.2-.55.5-.95.9-1.3 2.9-2.05.65-.25 2.2-.55 1.55-.3 2.8-1Q35.4.95 35.65 0m20.3 4.8q-.05-.25-.2-.55l-.5-1.85-.3 1.95Q54.7 6.3 52.4 7.5q1.05-.1 1.85-.55.35-.2.75-.5.15-.15.25-.35.1.2.25.35.4.3.75.5.85.45 2 .55v-.2q-1.95-1-2.3-2.5M86.2 6.15l-.1-.3q-.45-.95-1.2-1.75-1.55-1.6-4.2-2.15-2.65-.55-3.6-.75-.95-.25-2.25-1.2.25.95 1.45 1.7 1.25.7 2.8 1 1.55.3 2.2.55 2 .75 2.9 2.05.3.4.5.95.2.4.3.9.05 0 .05.05 0 .15.05.25.75-.25 1.45-.35-.25-.8-.35-.95M103 2.7q1.6.3 2.25.55 2 .75 2.9 2.05.7.9.9 2.3v.05h.15q.7-.15 1.15-.1h.15V7.5q0-.2-.05-.35-.05-.25-.1-.45-.4-1.45-1.5-2.6-1.55-1.6-4.25-2.15-2.65-.6-3.8-.85-1.15-.3-2-1.1.1.75 1.35 1.6 1.3.75 2.85 1.1z"
                    id="LegSho016__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M9.05 4.75V4.6Q9 3.1 8.55 1.85 7.8-.05 6.3 0 4.75 0 3.65 1 2.5 2 2.3 2.35q-.2.3-.3.4Q1.2 3.9.85 5.25q-.2.45-.25 1-.05.05-.05.1v.05l-.1.4Q.15 8.25 0 10.1v1.1q.45-.95 2.3-1.9.35-.2.75-.35.25-.15.55-.2.55-.2 1.05-.25.35-.1.65-.05.8.2 1.3.2 2.45 0 2.45-3.9m21.45.1v-.1q0-.85-.15-1.6V3q-.05-.1-.05-.2-.1-.5-.3-.95-.75-1.9-2.25-1.85-1.55 0-2.65 1-1.15 1-1.35 1.35-.2.3-.3.4-.8 1.15-1.15 2.5-.1.25-.15.55-.012-.035-.05-.1-.038.346-.1.65v.05l-.1.4q-.3 1.45-.45 3.3v1.1l.1-.2q.55-.85 2.2-1.7l.4-.2q.15-.1.35-.15.25-.15.55-.2.55-.2 1.05-.25.35-.1.65-.05.8.2 1.3.2 2.45 0 2.45-3.8M52 8.65q2.45 0 2.45-3.8v-.1q0-.85-.15-1.6V3q-.05-.1-.05-.2-.05-.35-.15-.65-.1-.15-.15-.3Q53.2-.05 51.7 0q-1.55 0-2.65 1-.5.45-.8.75-.45.4-.55.6-.2.3-.3.4-.8 1.15-1.15 2.5-.1.25-.15.55-.05.1-.05.25-.05.1-.05.2-.05.05-.05.1v.05l-.1.4q-.3 1.45-.45 3.3v1.1l.1-.2q.55-.85 2.2-1.7l.4-.2q.15-.1.35-.15.25-.15.55-.2.55-.2 1.05-.25.35-.1.65-.05.8.2 1.3.2m52.2-5.9q-.1-.1-.3-.4-.1-.2-.55-.6-.3-.3-.8-.75-1.1-1-2.65-1-1.5-.05-2.25 1.85-.05.15-.15.3-.1.3-.15.65 0 .1-.05.2v.15q-.15.75-.15 1.6v.1q0 3.8 2.45 3.8.5 0 1.3-.2.3-.05.65.05.5.05 1.05.25.3.05.55.2.2.05.35.15l.4.2q1.65.85 2.2 1.7l.1.2v-1.1q-.15-1.85-.45-3.3l-.1-.4v-.05q0-.05-.05-.1 0-.1-.05-.2 0-.15-.05-.25-.05-.3-.15-.55-.35-1.35-1.15-2.5m25.3 2.9l-.05.15q-.05-.3-.15-.55-.35-1.35-1.15-2.5-.1-.1-.3-.4-.2-.35-1.35-1.35-1.1-1-2.65-1-1.5-.05-2.25 1.85-.2.45-.3.95 0 .1-.05.2v.15q-.15.75-.15 1.6v.1q0 3.8 2.45 3.8.5 0 1.3-.2.3-.05.65.05.5.05 1.05.25.3.05.55.2.2.05.35.15l.4.2q1.65.85 2.2 1.7l.1.2v-1.1q-.15-1.85-.45-3.3l-.1-.4v-.05q0-.046-.05-.15.015-.007-.05-.55m21.25-.4q-.35-1.35-1.15-2.5-.1-.1-.3-.4-.2-.35-1.35-1.35-1.1-1-2.65-1-1.5-.05-2.25 1.85-.45 1.25-.5 2.75v.15q0 3.9 2.45 3.9.5 0 1.3-.2.3-.05.65.05.5.05 1.05.25.3.05.55.2.4.15.75.35 1.85.95 2.3 1.9v-1.1q-.15-1.85-.45-3.3l-.1-.4v-.05q0-.05-.05-.1-.05-.55-.25-1z"
                    id="LegSho016__Symbol_40_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M9.05.15V0q-.3.75-.95 1.15-.6.35-2.55.6-1.8.15-3.25 1.4Q.8 4.4 0 6.35v.25q.45-.95 2.3-1.9.35-.2.75-.35.25-.15.55-.2.55-.2 1.05-.25.35-.1.65-.05.8.2 1.3.2 2.45 0 2.45-3.9M26.1 3.9q.35-.1.65-.05.8.2 1.3.2 2.45 0 2.45-3.8-.3.75-1.45 1.25-1.25.5-2.6.35Q24.9 1.65 23.4 3q-1.6 1.4-1.85 3.4.55-.85 2.2-1.7l.4-.2q.15-.1.35-.15.25-.15.55-.2.55-.2 1.05-.25m25.9.15q2.45 0 2.45-3.8Q54.15 1 53 1.5q-1.25.5-2.6.35-1.55-.2-3.05 1.15-1.6 1.4-1.85 3.4.55-.85 2.2-1.7l.4-.2q.15-.1.35-.15.25-.15.55-.2.55-.2 1.05-.25.35-.1.65-.05.8.2 1.3.2m51.9.65q1.65.85 2.2 1.7-.25-2-1.85-3.4-1.5-1.35-3.05-1.15-1.35.15-2.6-.35Q97.45 1 97.15.25q0 3.8 2.45 3.8.5 0 1.3-.2.3-.05.65.05.5.05 1.05.25.3.05.55.2.2.05.35.15l.4.2m23.2-.35q.2.05.35.15l.4.2q1.65.85 2.2 1.7-.25-2-1.85-3.4-1.5-1.35-3.05-1.15-1.35.15-2.6-.35Q121.4 1 121.1.25q0 3.8 2.45 3.8.5 0 1.3-.2.3-.05.65.05.5.05 1.05.25.3.05.55.2m19.85-.45q.5.05 1.05.25.3.05.55.2.4.15.75.35 1.85.95 2.3 1.9v-.25q-.8-1.95-2.3-3.2-1.45-1.25-3.25-1.4-1.95-.25-2.55-.6-.65-.4-.95-1.15v.15q0 3.9 2.45 3.9.5 0 1.3-.2.3-.05.65.05z"
                    id="LegSho016__Symbol_41_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho016__Symbol_39_0_Layer0_0_FILL"
                transform="translate(116.85 324.75)"
                id="LegSho016__col1n"
            />
            <use
                xlinkHref="#LegSho016__Symbol_38_0_Layer0_0_FILL"
                transform="translate(137.25 327.5)"
                id="LegSho016__col1d"
            />
            <use
                xlinkHref="#LegSho016__Symbol_40_0_Layer0_0_FILL"
                transform="translate(116.7 332.4)"
                id="LegSho016__col2n"
            />
            <use
                xlinkHref="#LegSho016__Symbol_41_0_Layer0_0_FILL"
                transform="translate(116.7 337)"
                id="LegSho016__col2d"
            />
        </g></g>
    )
}
export default LegSho016
