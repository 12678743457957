import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C67D9A",
    "col1s": "#693349"
}

function SkiWou010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4.2 11.35q1.35 3.25 2.45 6.55.75 1.85 1.75 4.5 3.3 8.9 2.8 12.35-.15 1.15-.6 1.95-.45.85-.55 1.9Q9.7 41 13.3 52.55q3.5 11.4 3.45 11.65-.1.7-.75 1.55-.75.8-.8 1.65-.15.7 3.7 11.45 3.7 10.7 3.25 13.95l-.65 1.1q-.45 1.15-.65 1.7 6.25 21.4 6.6 22.3.2-2.85.2-5.4-.1-1.85-.35-6.2 0-.5.2-2.95.15-2-.05-2.65-1.25-2.55-1.45-2.85-.05-.45.25-2.45 1-3.55 1.05-4.15.35-2.5-2-10.8t-2.1-10.5q.2-1.2 1.1-3.4.85-2.2.85-2.35.6-4.5-4-14.6-5-10.95-5.15-12.25-.25-2.05.8-7.3.9-3.7-1.5-9.7-1.35-3.4-7.3-11.2Q2.2 1.35 0 0q1.1 4.05 4.2 11.35z"
                    id="SkiWou010__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.3.75q1 2.25 2.9 6.2 2.3 4.95 3.65 9.5 1.7 5.75.6 11.65-.55 3.35 3.9 15 4.45 11.6 4.2 12.75-.4 1.2-.65 2.45-.2 1.15-.4 2.35-.4 2.5 2.4 12.5 1.15 4.7 2.95 13.55-.5-4.7-.5-5.55-.3-3.3-2.1-10.2-1.8-7-1.7-8.9.1-1.95.65-3.1.5-1.2 1.1-2.85.45-1.6-4.2-13.2-4.6-11.65-4.05-16.2.4-4.6-.9-9.65-.7-3.6-3.45-8.5 0-.25-2.8-5.25Q-.75-1.55.3.75z"
                    id="SkiWou010__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou010__Symbol_18_0_Layer0_0_FILL"
                transform="translate(239.8 89.2)"
                id="SkiWou010__col1n"
            />
            <use
                xlinkHref="#SkiWou010__Symbol_17_0_Layer0_0_FILL"
                transform="translate(245.05 98.5)"
                id="SkiWou010__col1s"
            />
        </g></g>
    )
}

export default SkiWou010
