import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFE9E9"
}

function ShiSho076({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M122.1.25q.05-.15.1-.25l-1.85 1.4q-1.35 1.05-2.8 2.1L116 4.65q-13.15 9.1-28.35 13.1-2.2.55-4.45 1.05-.8 9.2-6.05 16.05-6.2 8.15-15 8.15t-15-8.15Q41.9 28 41.1 18.8q-2.25-.5-4.45-1.05-13-3.45-24.45-10.55-2-1.2-3.9-2.55L6.75 3.5Q5.3 2.45 3.95 1.4L2.15.05l.05.1V.2q5.1 10.35 4.4 27.2l-.1 2.4q-.15 1.85-.35 3.8l-.1 1q-.05.4-.1.85l-2.7 14q-.55 2.7-1.1 5.45L0 63.8q63.1 4.45 124.25 0l-1.85-7.45q-.65-3.1-1.3-6.15l-2.85-14.75q-.05-.45-.1-.85l-.1-1q-.3-3.2-.45-6.2-.05-1.2-.05-2.4Q117.3 9.8 122.1.25z"
                    id="ShiSho076__Symbol_215_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho076__Symbol_215_0_Layer0_0_FILL"
                transform="translate(130.35 228.6)"
                id="ShiSho076__col1n"
            />
        </g></g>
    )
}

export default ShiSho076
