import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#773900",
    "col1l": "#D96900",
    "col1n": "#AD5300",
    "col2n": "#FF5400"
}

function PetPet019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2.85 5.7q1.2 0 2-.85.85-.8.85-2T4.85.8q-.8-.8-2-.8T.8.8Q0 1.65 0 2.85q0 1.2.8 2 .85.85 2.05.85z"
                    id="PetPet019__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M7.5.6l.05-.05Q7.35.25 7.2 0q-.25.3-.5.75Q4.5 4.2 2.6 9.5.65 14.85.15 18.9q-.5 4.1.7 4.55 1.2.4 3.4-3.05 1.7-2.65 3.2-6.35.45-3.7.65-7.45.15-3.8-.6-6M29.3.55v.1l.05.05q-1.5 4-.8 7.45t.65 6.4q1.4 3.4 2.95 5.85 2.2 3.45 3.4 3.05 1.2-.45.7-4.55-.5-4.05-2.4-9.4Q31.9 4.2 29.7.75q-.15-.2-.25-.4-.05.1-.15.2z"
                    id="PetPet019__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M23.05 21.1l-.05-.05-6.45-9.95h.05q1.75-3.9 1.3-7.95v-1.3q-.15-2.85-1.3 0-.25.6-.55 1.45l-1.8 5.25-.75-5.25q-.15-.85-.25-1.45-.85-3.7-1.65 0-.15.6-.3 1.45l-.7 5.8-2.3-5.8q-.3-.85-.55-1.45-1.1-2.45-1.2 0-.05.6-.05 1.45-.25 3.95 1.65 7.9l-6.9 9.75-.05.05Q0 24.1 0 27.8q-.05 3.65 1.15 6.65.85 2.2 2.4 4.1 1.2 1.5 2.6 2.55.65.45 1.35.85 2.1 1.1 4.6 1.1 1.95 0 3.65-.65.7-.3 1.4-.7 1.95-1.1 3.55-3.15 1.35-1.65 2.2-3.6 1.35-3.2 1.35-7.15 0-3.65-1.2-6.7z"
                    id="PetPet019__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.2 18.25L15.25 5.9 10.2 0 4.25 5.9l5.95 12.35m3.65 6.5v7.1L9.7 36l.1.1h9.7l-4.25-4.25v-7.8q-.7.4-1.4.7M5.6 24.3q-.7-.4-1.35-.85v8.4L0 36.1h9.65l.05-.1-4.1-4.15V24.3z"
                    id="PetPet019__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M217.75 340.9l.1.1q1.2 1.75 3.05 2.3.4.1.85.15.45.05.9.05 1.9 0 3.55-1.3 1.95-1.6 2.35-4.2.047-.328.05-.6.006.272.1.6v.05q.35 2.6 2.35 4.2 1.2.95 2.6 1.25.9.15 1.85 0 .2-.05.45-.05 2.1-.5 3.45-2.35l.1-.1v-.1q.1-.1.15-.2 1.2-1.95.85-4.3-.35-2.6-2.35-4.2-2-1.65-4.45-1.3-.3.05-.6.15h-.05q-1.6.4-2.7 1.6-.3.3-.55.7-1.1 1.55-1.2 3.4-.05-1.9-1.15-3.45-.25-.35-.5-.65-1-1.05-2.3-1.5-.55-.2-1.1-.3-2.45-.35-4.45 1.25t-2.35 4.25q-.35 2.15.65 4 .15.25.35.55z"
                    id="PetPet019__Layer3_0_FILL"
                />
                <path
                    d="M224.1 336.9q-.4.35-.35.75 0 .35.15.7.3.55 1.05.55 1.2 0 1.2-1.15-.15-1.25-1.2-1.25-.5 0-.85.4m7.15 1.25q.3.55 1.05.55 1.15 0 1.15-1.15-.1-1.25-1.15-1.25-.5 0-.9.4-.35.35-.35.8 0 .3.2.65z"
                    id="PetPet019__Layer3_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#PetPet019__Symbol_91_0_Layer0_0_FILL"
                transform="translate(225.7 356.45)"
                id="PetPet019__col1n"
            />
            <use
                xlinkHref="#PetPet019__Symbol_73_0_Layer0_0_FILL"
                transform="translate(210.2 340.35)"
                id="PetPet019__col1l"
            />
            <use
                xlinkHref="#PetPet019__Symbol_66_0_Layer0_0_FILL"
                transform="translate(216.5 319.95)"
                id="PetPet019__col1d"
            />
            <use
                xlinkHref="#PetPet019__Symbol_41_0_Layer0_0_FILL"
                transform="translate(218.4 337.6)"
                id="PetPet019__col2n"
            />
            <use xlinkHref="#PetPet019__Layer3_0_FILL" />
            <use xlinkHref="#PetPet019__Layer3_1_FILL" />
        </g></g>
    )
}

export default PetPet019
