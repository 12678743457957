import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#CCCCCC",
    "col1n": "#FFFFFF"
}

function AccHat077({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#191919"
                    fillOpacity={0.769}
                    d="M197.15 55.3q-2.15-.1-4.35-.1-19.6 0-36.2 8-14.85 7.15-27.3 20.75-26.3 28.7-26.3 69.25 0 40.6 26.3 69.3 12.45 13.55 27.3 20.7 16.6 7.9 36.2 7.9h1.15q36.5-.35 62.4-28.6 26.35-28.7 26.35-69.3 0-40.55-26.35-69.25Q243.1 69.5 227.2 62.4q-14-6.25-30.05-7.1z"
                    id="AccHat077__Layer17_0_FILL"
                />
                <path
                    fill="#191919"
                    d="M196.9 58.25q-2.1-.1-4.25-.1-19.15 0-35.3 7.75-14.5 6.9-26.65 20.05-25.65 27.75-25.65 66.95 0 39.25 25.65 67 12.15 13.1 26.65 20 16.15 7.65 35.3 7.65h1.1q35.6-.35 60.85-27.65 25.7-27.75 25.7-67 0-39.2-25.7-66.95Q241.7 72 226.2 65.1q-13.65-6.05-29.3-6.85m54.1 44.3q24.4 23.5 24.4 56.75 0 33.3-24.4 56.75-24.35 23.5-58.85 23.5-18.9 0-34.8-7.05-13.1-5.85-24.1-16.45-24.35-23.45-24.35-56.75 0-33.25 24.35-56.75 11-10.6 24.1-16.4 15.9-7.1 34.8-7.1 34.5 0 58.85 23.5z"
                    id="AccHat077__Layer17_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M195.55 97.75q0-40.5-28.7-69.15Q138.25 0 97.75 0 77.1 0 59.55 7.45 42.7 14.6 28.65 28.6 0 57.25 0 97.75 0 137 27 165.2q.9 2 1.55 4.05 2.05 6.65 1.05 13.3-.05.2-.05.45-.55 3.95 4.15 7.1 1.75 1.15 4.25 2.25 12.8 7 24.65 11.3 18.65 6.8 34.8 6.85h1.75q26.45-.1 59.45-18.15 2.5-1.1 4.25-2.25 4.7-3.15 4.15-7.1 0-.25-.05-.45-.45-3-.25-5.95.05-1.15.2-2.25l.5-5.55q.6-1.95 1.4-3.95l1.8-1.9q24.95-27.5 24.95-65.2m-64.1-87.8q15.5 6.9 28.4 20.85 25.7 27.75 25.7 66.95 0 39.25-25.7 67Q134.6 192.05 99 192.4h-1.1q-19.15 0-35.3-7.65-14.5-6.9-26.65-20Q10.3 137 10.3 97.75q0-39.2 25.65-66.95Q48.1 17.65 62.6 10.75 78.75 3 97.9 3q2.15 0 4.25.1 15.65.8 29.3 6.85z"
                    id="AccHat077__Symbol_294_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M141.8 0q-.95 1-1.95 2-21.95 22-50.9 27.1-8.15 1.45-16.8 1.55h-1.4q-9.4 0-18.15-1.55-8.85-1.55-17-4.65Q16.95 17.3 1.65 2L0 .35q.9 2 1.55 4.05l.1.1q15.3 15.3 33.95 22.45 8.2 3.1 17 4.65 8.6 1.5 17.8 1.55h1.75q4.35-.05 8.55-.4 4.2-.45 8.25-1.15 28.95-5.1 50.9-27.1l.55-.55Q141 2 141.8 0z"
                    id="AccHat077__Symbol_198_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat077__Layer17_0_FILL" />
            <use xlinkHref="#AccHat077__Layer17_1_FILL" />
            <use
                xlinkHref="#AccHat077__Symbol_294_0_Layer0_0_FILL"
                transform="translate(94.75 55.15)"
                id="AccHat077__col1n"
            />
            <use
                xlinkHref="#AccHat077__Symbol_198_0_Layer0_0_FILL"
                transform="translate(121.75 220)"
                id="AccHat077__col1d"
            />
        </g></g>
    )
}

export default AccHat077
