import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}
function FacEye039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M101.4 48.4q2.599-3.323 3.85-7.35.75-2.4 1-5.05.3-2.6.1-5.4-.2-3.2-1-6.2-1.2-4.7-3.8-8.85-1.7-2.75-4.05-5.3-.15-.2-.3-.35Q88.85.9 77.85.1 66.8-.75 59.55 7q-5.35 5.75-6.3 13.7-1-7.95-6.35-13.7Q39.65-.75 28.65.1 17.6.9 9.25 9.9q-.3.3-.55.65l-.3.3q-5 5.65-7 12.4Q.35 26.8.1 30.65q-.2 2.7.05 5.15.25 3.15 1.25 6 1.265 3.564 3.6 6.6 18.823-3.234 38.15 0 .546-.51 1.05-1.05 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25.504.54 1 1.05 19.287-2.811 38.15 0z"
                    id="FacEye039__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.4 6.65Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35 0 1.4 1 2.35.95 1 2.4 1.05M33 5.6q1 1 2.45 1.05 1.4-.05 2.35-1.05 1-.95 1-2.35 0-1.35-1-2.35-.95-.95-2.35-.9Q34-.05 33 .9q-1 1-1 2.35 0 1.4 1 2.35z"
                    id="FacEye039__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye039__Symbol_84_0_Layer0_0_FILL"
                transform="translate(139.3 136.2)"
                id="FacEye039__col1n"
            />
            <g id="FacEye039__col2n" transform="translate(173 163.45)">
                <use
                    xlinkHref="#FacEye039__Symbol_4_0_Layer0_0_FILL"
                    id="FacEye039__col2n_FL"
                />
            </g>
        </g></g>
    )
}
export default FacEye039
