import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho071({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M244.05 93.55q-.15-.3-.3-.55-14.2-24.1-22.3-42.45-4.7-11.45-6.3-13.2l-.4-.4q-.2-.25-.35-.5Q200.3 16.1 186.2 8.5q-.15-.1-.25-.15-27.6-16.7-58.05 0-2.85 1.6-5.7 3.55-.75.5-1.55 1l-2.45 1.6q-.3.2-.55.4l-.2.2q-4.25 3.2-7.1 6.75-7.05-8.3-12.9-9.25-.5-.1-.95-.15-.55-.1-1.1-.1Q80.25 8.5 65.35 11.3 47.6 14 42.5 32.25l-.1.1Q34.1 76.5 0 112.5q.2-.2.4-.3l-.3.3q1.85-1.45 3.65-2.65 1.8-1.15 6.1-3.8 4.3-2.7 13.95-10.3-4.75 12.8-12.05 22.8-4.6 6.2-6.2 8.15 3.6-.15 8.05-2.2 4.95-2.3 6.85-3.4 22.7-14.65 38.8-29.3.95-1 1.85-2.05.25-.3.55-.6-.35 1.5-.65 2.7l-.4.9-.05.1q-.4 1.2-.65 1.65l-.2.2q.45-.9.85-1.85-.4.9-.85 1.8-1.05 2.2-2.2 4.35l-.15-.05q-6.7 13-16.2 21.25-9.45 8.2-17.6 13-8.2 4.75-16.7 8.25 10.8-2.4 20.7-6.6 7.9-3.35 15.35-7.85 16.25-10 25.75-26.05 2.45-4.15 4.55-8.55-8.95 31.65-21 48.95l-4.95 6.15q23.15-23 35.35-50.3 3.75-8.4 6.7-15.35 5.15-12.2 5.5-26.25 16.2-.1 19.55.9 3.35 1 5.2 1.8 1.85.75 2.8 1.3.05.05.15.1.25.2.55.35 2.2 1.25 4 .3 4.15-1.5 7.3-2.9 13.2-4.5 37.25-1.5 11.75 18.3 23.55 56.1.2 1.1.45 2.2.2 1.2.45 2.4l.2 1.2.2 1.5v-.65q.05.3.1.65 0-.8.05-1.5h-.1v-.05q.5-14.45-.05-26.6l-.1-1.4q-.05-.45-.05-.8l-.1-2.2q-.05-.45-.05-.85v-.45l-.5-8.55h.05l.1-.1q-.05.053-.05.1.05-.05.1-.15h.05l-.05-.8q.2-1.25.25-2.45 4.55 12.7 8.3 33.1v-.05q.15.75.3 1.55 1.3 3.6 2.85 9.95.4 1.45.45 1.75 0 .15.05.3.25 1 .35 2.45 1.3 8.55 2.3 14 0 .1.05.2v.2q5.85 31.9 1.15-47 .1-2.2.6-4.25.1-.6.25-1.2.1-1.9.45-3.45l1.15 5.95q.1.45.2 1 1.25 6.25 2.55 10.4 4.15 13.3 13.05 43.15 0 .05.05.15l.1-.2v-.1h.1v-.05q.35-.55.8-1.2l.6-.8q7.65-22.85 4.7-40.75 9.7 17.9 7.3 41.35 11.05-27.1.25-46.45M196 84.15l-.05-.5q-.05-.4-.05-.75l.1 1.25z"
                    id="HaiSho071__Symbol_192_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M59.3 47.05q.6-2.25.9-6.5Q31.6 80.95 0 97.5q3.6-.15 8.05-2.2Q13 93 14.9 91.9q22.7-14.65 38.8-29.3.95-1 1.85-2.05.25-.3.55-.6v-.05q1.2-5 3.2-12.85m51.85-23.95q-.15-.25-.35-.45-3.4-4.7-8.3-11.8-.1-.1-.15-.2-18.85-21.8-18.2 1-1.2 41.85-7.1 56.35 3.75-8.4 6.7-15.35 5.15-12.2 5.5-26.25 16.2-.1 19.55.9 3.35 1 5.2 1.8 1.85.75 2.8 1.3.05.05.15.1.25.2.55.35-2.7-2.75-6.35-7.75m77.45 7.95q.75 4.05 1.2 8.25.75 2.45.75 5v.05q4.55 12.7 8.3 33.1v-.05q-.05-3.95-.45-11.7-.45-7.8-3-20.4-2.6-12.6-6.8-14.25M168.4 9.3q-1-1.2-1.4-1.2-.65-.1-2.45-1.1-5.9-3.3-13.25-3.3-6.8 0-13.15 4.3 0 .05-.05.05l-.3-.3q-2.3 1.9-4.4 4.1-1.15 1.15-2.2 2.4-1.2 2.2-3.9 7.65-.45.9-.95 1.85-.1.2-.2.45-2.15 4.3-3.65 6.2l-1.3.85.3-.1q4.15-1.5 7.3-2.9 13.2-4.5 37.25-1.5 11.75 18.3 23.55 56.1-.85-3.55-2-7.05l-.7-2.1q-9.85-56.75-18.5-64.4M209.7 47q-.45.5-.8 1.2-.8 1.4-1.25 3.7l1.15 5.95q.1.45.2 1 1.25 6.25 2.55 10.4 4.15 13.3 13.05 43.15l.15-.05v-.1h.1v-.05l-.2-1.75q-.7-7.95-1.05-12.55-2.1-13.7-3.25-19.4-.8-6.75-2.5-12.2-1.75-5.45-3.4-11.15T209.7 47z"
                    id="HaiSho071__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M91.7 12.9q1.7-.6 3.6-.55Q80.15 8.5 65.25 11.3 47.5 14 42.4 32.25 34.2 76.2.35 112.15l-.05.05-.3.3q1.85-1.45 3.65-2.65 1.8-1.15 6.1-3.8 4.3-2.7 13.95-10.3 13.5-10.7 25.9-24.55l1.8-2q.95-1.05 1.85-2.15 12.25-14.5 16-30.4.5 1.6 1.1 3.05 2.75 6.45 2.45 18.1-.3 11.65-6.45 31.6-6.15 19.9 2.75.5 5.5-15.15 6.6-17.4 1.05-2.3 3.2-15.15 2.1-12.9 2.25-20.5.1-4.75.6-9.2 3.3-12.4 9.95-14.75m92.25 8.45q-7.4-4.95-16.45-7.75 25.8-3.3 46.8 22.85Q200.2 16.1 186.1 8.5q-.15-.1-.25-.15-27.6-16.7-58.05 0-2.85 1.6-5.7 3.55-.7.5-1.45 1l-2.45 1.6q-.3.2-.55.4l-.2.2q-4.3 3.2-7.1 6.85 5.65 6.7 12.15 18.25.3-.45.6-.85 46.2-37.7 83.5 19.05l1.05 3.95q-4.75-28.3-23.7-41z"
                    id="HaiSho071__Symbol_52_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho071__Symbol_192_0_Layer0_0_FILL"
                transform="translate(54.45 37.9)"
                id="HaiSho071__col1n"
            />
            <use
                xlinkHref="#HaiSho071__Symbol_103_0_Layer0_0_FILL"
                transform="translate(60 67.1)"
                id="HaiSho071__col1d"
            />
            <use
                xlinkHref="#HaiSho071__Symbol_52_0_Layer0_0_FILL"
                transform="translate(54.55 37.9)"
                id="HaiSho071__col1l"
            />
        </g></g>
    )
}

export default HaiSho071
