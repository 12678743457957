import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#775C31",
    "col1l": "#D19532",
    "col1n": "#A37831",
    "col1s": "#43341A",
    "col2d": "#43341A",
    "col2l": "#8C6526",
    "col2n": "#644D27",
    "col2s": "#1C150B"
}

function AccHat037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M249.9 95.25q-.55-4.3-4.7-5.6-4.15-1.35-4.6-1.4-10.55-2.85-22.2-3.85l-5.05-.65q-3.05-8.55-22.4-59.3Q175.9 9.05 156 3.4q-3.2-.9-6.5-1.55-24.25-3.65-49.1 0-3.8.65-7.35 1.65Q72.5 9.05 59.5 24.45h-.05Q38.65 77.5 36.4 84.55q-1.6.2-3.15.45-1.05.1-2.1.3h-.05l.05-.05q-6.4 1.15-12.2 1.5-.45.05-.9.05l-6.2 1q-1.35.15-2.55.45-.45.05-4.6 1.4-4.15 1.3-4.7 5.6.05.1.15.35 1.35 3.55 5.6 5.85 4.45 2.4 13.7 3.9 7.75 1 12.3 1.85.85-4.6 1.6-7.8.75-3.25 1.7-5.45 2.5.4 5.6.8.9.1 1.75.2 4 .45 8 .85h.05q.1 0 .2.05 3.5.35 7 .75 8.1.75 16.25 1.4 4.8.35 9.6.65 10.05.55 20.1.9 3.1.1 20.6.35h1.2q10.05 0 20.05-.3l2.75-.1q14-.45 28-1.45 8.15-.6 16.35-1.35 4.25-.45 8.55-.9.85-.1 1.75-.2 2.35-.25 4.7-.55 1.15-.1 2.35-.25l4.95-.85q1.05-.05 1.45-.1.3-.05.6-.05l3.25 13.05q4.15-.7 10.3-1.5 9.25-1.5 13.7-3.9 4.25-2.3 5.6-5.85.1-.25.15-.35m-59.6-3.6q-1.35.05-2.65.15-.5.05-1 .05l4.15-.25q-.254.028-.5.05z"
                    id="AccHat037__Symbol_255_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M91.25 84.4q4.6-.4 9.15-.85 4-.4 8-.85 2.5.05 4.65.35 1.35.15 2.55.45-10.55-2.85-22.2-3.85l-7.35-.9v-.2q-2.2-3.1-4.5-6.45-4.65-6.7-5-10.8v-.45q1.55-1.75 3.2-3.65l.85.95-.3-.85q.2 0 .45.05l-.55-.25-10.85-28.85Q64 45.6 43.3 40.45 33.85 34.9 26.05 28.5 13.55 18.25 8.25 7.75 2.85-2.8.65 1q-2.2 3.8 3.3 18.6T14.2 40.8q3.9 5.25 9.3 10.8 4.25 1.35 19.95 6.1-19.2 15.75-19.65 15.75L11.75 80.1l-.2.1-.55.3Q4.1 84.45.4 88.8q22.9.05 45.45-.85 1.65-.1 3.4-.2t3.5-.15q1.6-.1 12.25-.9 10.6-.85 13.5-1.1 2.9-.25 4.5-.4 1.55-.2 1.9-.2 2.35-.25 4.7-.45l1.2-.1q.25 0 .45-.05z"
                    id="AccHat037__Symbol_213_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M44.05 64.05q.65.9 9.7 7.65 9 6.7 16.9-12.35Q52.75 56.1 52.4 42.1l-8.35 21.95m78.6-60.55q.8-1.5 1.85-3.5Q107-.3 89.8 4.4 72.6 9.05 59.5 24.45h-.05l-3.65 8.8Q54.35 54.1 81.75 45.5q8.95-5.7 16.55-12.25 11.35-9.95 19.55-22 2.55-3.75 4.8-7.75M249.9 95.25q-.55-4.3-4.7-5.6-4.15-1.35-4.6-1.4-1.2-.3-2.55-.45-2.15-.3-4.65-.35-4 .45-8 .85-4.55.45-9.15.85-.2.05-.45.05l-1.2.1q-2.35.2-4.7.45-.35 0-1.9.2-1.6.15-4.5.4t-13.5 1.1q-10.65.8-12.25.9-1.75.05-3.5.15-1.75.1-3.4.2-22.55.9-45.45.85-19.85-.1-40-.95-3-.1-5.95-.25-4.35-.2-8.65-.45l-7.5-.4q-.25-.05-.5-.05-4.9-.35-9.75-.65l-2.4-.2q-3.35-.3-6.65-.55-1.7-.15-3.35-.25-2.65-.25-5.35-.5l-1.2-.1q-.25 0-.45-.05-4.6-.4-9.15-.85-4-.4-8-.85-2.5.05-4.65.35-1.35.15-2.55.45-.45.05-4.6 1.4-4.15 1.3-4.7 5.6.05.1.15.35 6.65.4 11.6.65.75-.1 1.9-.2 6.05-.55 10.85-.85l1.55-.15q5.9-1.25 6.4-1.25.75 0 1.15.05.4.05 1.45.1 2.5.4 5.6.8.9.1 1.75.2 4 .45 8 .85h.05q.1 0 .2.05 3.5.35 7 .75 8.1.75 16.25 1.4 4.8.35 9.6.65 10.05.55 20.1.9 3.1.1 20.6.35h1.2q10.05 0 20.05-.3l2.75-.1q14-.45 28-1.45 8.15-.6 16.35-1.35 4.25-.45 8.55-.9.85-.1 1.75-.2 2.35-.25 4.7-.55 1.15-.1 2.35-.25l4.95-.85q1.05-.05 1.45-.1.3-.05.6-.05.1-.05.15-.05h.05q.2-.05.6-.05.5 0 17.2 1.95 13.25 1.55 8.95.15 2.65-.05 5.9-.2.1-.25.15-.35m-62.25-3.45q-.5.05-1 .05l4.15-.25q-.254.028-.5.05-1.35.05-2.65.15z"
                    id="AccHat037__Symbol_164_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M33.2 7.9q.75-3.25 1.7-5.45-1.05-.05-1.45-.1-.4-.05-.75-.1-.6-.1-1.2-.15L22 .8Q20.75.6 19.45.4q-.9-.15-1.8-.25Q8.8-.85 0 4.1q1.35 3.55 5.6 5.85 4.45 2.4 13.7 3.9 7.75 1 12.3 1.85.85-4.6 1.6-7.8M244 9.95q4.25-2.3 5.6-5.85-8.8-4.95-17.65-3.95-.9.1-1.8.25-1.3.2-2.55.4l-9.5 1.3q-.45.05-.85.1-.2 0-.35.05-.05 0-.15.05L220 15.35q4.15-.7 10.3-1.5 9.25-1.5 13.7-3.9z"
                    id="AccHat037__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M186.25 28.35L179 6.75l-5.6-.9h-.1q-8.15-1.25-16.25-2.2-6.25-.8-12.5-1.35-1.4-.15-2.8-.25-1.55-.2-3.1-.3Q128.65.9 118.7.5q-.7-.05-1.3-.05-4.55-.2-9.05-.25-7.6-.25-15.2-.15-26.95.2-53.85 3.4h-.05q-12.9 1.5-25.8 3.75-.65.1-1.25.2-.3.05-.6.15-1.6.25-3.15.55L.05 29.35 0 29.4h.05q1.05-.2 2.1-.3 2.55-.4 5.15-.7h.15q6.05-.8 12.1-1.5 7.3-.85 14.5-1.55 30.25-3.05 59.1-3.3 6.75-.1 13.45 0 2.35 0 4.7.05 3.2.05 6.4.2h1q6.85.2 13.65.6l6.3.4 3.1.2q1.4.1 2.8.25.55 0 1.2.1 3.4.25 6.85.55 3.5.4 7.05.75l8.5 1 18.1 2.2z"
                    id="AccHat037__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M24.15 16.55q.15-1.4.2-2.75.2-6.15-1.3-12.25-1.55-.2-3.1-.3Q9.95.4 0 0v21.8q6.85.2 13.65.6l6.3.4 3.1.2q0-.05.05-.15.35-1.45.6-2.9.3-1.7.45-3.4m16.8 8.1l-.05-.1-2.55-21.4q-6.25-.8-12.5-1.35 1.25 5.7 1.05 12-.05 1.55-.2 3.15-.2 2.6-.7 5.35-.05.4-.15.8v.15q.55 0 1.2.1 3.4.25 6.85.55 3.5.4 7.05.75z"
                    id="AccHat037__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M39.3.1V0h-.05q-12.9 1.5-25.8 3.75-.65.1-1.25.2-.3.05-.6.15-1.6.25-3.15.55L.05 25.9l-.05.05h.05q1.05-.2 2.1-.3 2.55-.4 5.15-.7h.15q6.05-.8 12.1-1.5 7.3-.85 14.5-1.55L39.3.1z"
                    id="AccHat037__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M3.85 12.25q.2-6.3-1.05-12Q1.4.1 0 0q1.5 6.1 1.3 12.25-.05 1.35-.2 2.75-.15 1.7-.45 3.4-.25 1.45-.6 2.9-.05.1-.05.15 1.4.1 2.8.25v-.15q.1-.4.15-.8.5-2.75.7-5.35.15-1.6.2-3.15z"
                    id="AccHat037__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat037__Symbol_255_0_Layer0_0_FILL"
                transform="translate(66.85 30.75)"
                id="AccHat037__col1n"
            />
            <use
                xlinkHref="#AccHat037__Symbol_213_0_Layer0_0_FILL"
                transform="translate(191.85 35.5)"
                id="AccHat037__col1l"
            />
            <use
                xlinkHref="#AccHat037__Symbol_164_0_Layer0_0_FILL"
                transform="translate(66.85 30.75)"
                id="AccHat037__col1d"
            />
            <use
                xlinkHref="#AccHat037__Symbol_122_0_Layer0_0_FILL"
                transform="translate(67 122.25)"
                id="AccHat037__col1s"
            />
            <use
                xlinkHref="#AccHat037__Symbol_78_0_Layer0_0_FILL"
                transform="translate(97.95 86.65)"
                id="AccHat037__col2n"
            />
            <use
                xlinkHref="#AccHat037__Symbol_43_0_Layer0_0_FILL"
                transform="translate(216.65 87.15)"
                id="AccHat037__col2l"
            />
            <use
                xlinkHref="#AccHat037__Symbol_20_0_Layer0_0_FILL"
                transform="translate(97.95 90.1)"
                id="AccHat037__col2d"
            />
            <use
                xlinkHref="#AccHat037__Symbol_3_0_Layer0_0_FILL"
                transform="translate(239.7 88.7)"
                id="AccHat037__col2s"
            />
        </g></g>
    )
}

export default AccHat037
