import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function AccMor034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 18.65q7.65-5.85 15-3.3 3.1.9 6.2 3.65.7.65 1.45 1.4l2.6-12.85L4.05.2Q.65 9.6 0 18.65M161.85 0l-20.9 7.2 2.55 12.35v.2l.05.2q3.65-3.7 7.35-4.8 7.35-2.55 15.6 3.9Q165.25 9.4 161.85 0z"
                    id="AccMor034__Symbol_132_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor034__Symbol_132_0_Layer0_0_FILL"
                transform="translate(109.3 266.15)"
                id="AccMor034__col1n"
            />
        </g></g>
    )
}

export default AccMor034
