import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#6BFFFF"
}

function SkiMor029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.6}
                    d="M41.55 3.15l.1-.2q-.15-.35-.4-.55-.35-.75-1.55-.25h-.05q-.5.6-1.1 1.3-.9.7-4.45 1.9-3.9 1.3-6.1 1.3-1.3 0-4.6-1.05-3.25-1.05-5.05-1.05h-.15q-.15.05-.25.1-1.55.6-2.85.65h-2.75q-1.2 0-2.25-.4-2.4-.65-4.15-2.4Q3.4 0 1.6 0 .15 0 0 .95q.05.3.15.6Q1.35 3.8 2.8 6l.2.3q.2 1.45 1.9 3.85 2.65 3.7 6.65 3.7l1.5-.2q.25.05.55.1H16.4q.5.15 1.2.4.15.05.3.05 3.55.75 5.05.6 1-.05 2.05-.35 1.05-.15 1.8-.45.75-.25 1.15-.65.85-.4 1.75-.9.35-.25.75-.4 3.35-1.1 4.25-1.6.45-.25 2.35-2.3 1.3-1.45 1.75-2 2.6-2.6 2.75-3m-5.65 2.6q-.05-.05-.1-.05l.4-.1q-.05.05-.3.15m62.55.85q.7-1.8 1.4-2.85.25-.45.9-1.45.5-.85.5-1.45 0-.8-.75-.8-.9 0-1.8.8l-.15.15q-.05 0-.1.05-1.3 1.3-3.15 2.7-4.9 3.65-7.05 3.65-1.4 0-4.05-1-2.7-1.1-5.9-1.1-1.1 0-2.6.7-1.5.65-3.3.65-3.3 0-6-1.2-.35-.15-.65-.3-1.3-.5-3.5-1.6-.2-.1-.4-.25-.25-.15-.55-.35-1.35-.9-1.95-.9-1.2 0-1.2 1.15 0 1.35 1.95 2.45Q64 7.9 64.55 8.3l.05.05q.35.2.85.6.8.65 1.1 1.05.4.5 1.65 1.95l.75.75q1.1.95 1.75 1.2 1 .35 3.6.65h2.05L78 14.3h3.3q4.95.6 5.15.65h.9q.95-.2 1.7-.45 1.2-.5 2.5-1.2 4.85-2.55 6.25-5.55.4-.6.65-1.15z"
                    id="SkiMor029__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor029__Symbol_30_0_Layer0_0_FILL"
                transform="translate(142.15 180.85)"
                id="SkiMor029__col1n"
            />
        </g></g>
    )
}

export default SkiMor029
