import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function BeaChi035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M78.4 5q-.05-.15-.1-.25l-.1-.3q-.75-1.35-1.4-2.7-.45-.9-.85-1.75v6.2l-5-4.45.1.2q-.95 2.5-3.65 3 .35 2.15 1 4.3-.05.1-.05.2-1.1-.35-2.95-.35-4.95 0-5.45 3.65-.1.6-.15 1.05-.4-.35-1.5-1.95-.7-1.1-1.55-1.7l-1.4-1q-1.55 1.4-1.75 2-2 2.55-2.7 3.5-.35-.85-1.7-2.75-2.05-2.7-2.65-3.6-.2-.3-.3-.75-6.4 2.85-12.8 0-.85 1.5-2 3.6-1.25 2.25-2.1 3.95-.45-.65-3.05-3.95-.5-.85-.85-1.5l-.05.3q-4.95 3.55-5.3 3.85-.05-.45-.15-1.05-.5-3.65-5.45-3.65-1.9 0-2.95.35-.05-.1-.05-.2.6-2.15 1-4.3-2.15-.4-3.7-2.05L4.45 7.6l-1.3-6.05-.1.2q-4.4 8.75-2.4 18 1.55 8.85 8.6 12.3-.15-.5-.15-2l.05-1.65q1.5 1.85 3.9 4.9.05.1.15.2 1.35 1.55 2.95 3.2.4.35.85.65v-.1-.15q-.05-1.85.25-3.2.05-.4.15-.75V33l.05-.1v.05q.3.5.65 1.05 1.45 2.15 3.4 4.15 1.1 1.1 2.1 1.85l.8.4v-3.85l.1.1q.35.4 1.25 1.7.85 1.1 1.55 1.75 1 .9 2.15 2 0 .05.05.05.85.6 2.55 1.55.5-.25 1.6-.7 2.3-.9 3.55-.9.05 0 2.65.55.65 0 1.45-.15l5.15-3.05.05.25q.05.25.15.85.15.75.2 1.4.25-.05.55-.05.35-.05.65-.05 1.8-.4 3.4-1.25 1.25-.65 4.25-2.25.15.3.3.55.15.35.2.6.15.5.15 1.7 1.25-.45 3.1-1.45 1-.55 2.25-1.3.05-.05.15-.1 1.1-1.1 2.05-2 1.65-1.55 2.25-1.8.1-.05.2-.05.4 0 .4 1.9v.1h.1q.05-.05.1-.05.4-.2.8-.35.2-.15.4-.25 2-1.1 3.15-2.8 1.4-2.65 2.25-3.95v.05q.05.05.1.15 0 .1.05.2l.8 2.4q1.75-1.4 2.55-4.6.6-2.35 1.35-3.75 4.6-9.7 0-18.55z"
                    id="BeaChi035__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.05 8.65Q3.6 6.55.55 0 .3 1.5.15 3.25.027 4.201 0 4.7q1.5 1.85 3.9 4.9Q5.3 11.3 7 13q.4.35.85.65v-.1-.15q-1.35-2.65-2.8-4.75m7.3 3.5q-.1-.2-1.4-2.7-.65-1.3-1.5-3.7-.55.7-1.15 3.5 1.6 2.7 4.05 5.2 1.1 1.1 2.1 1.85l.8.4v-.05q-.8-1.25-1.55-2.4-1-1.45-1.35-2.1m17.4 3.9q-1.15-.6-2.05-.9-.95-.35-2.35.35-1.4.7-2.45.75-.95 0-2.6-.85l-.2-.1q-2.7-2.45-4.7-6.3-.45 2.9-.05 3.95.35.4 1.25 1.7.85 1.1 1.55 1.75 1 .9 2.15 2 0 .05.05.05.85.6 2.55 1.55.5-.25 1.6-.7 2.3-.9 3.55-.9.05 0 2.65.55.65 0 1.45-.15l5.15-3.05.05.25q0-.6.05-.7l-.35-3.6q-3.15 3.55-4.5 4.4-.3.15-.5.2-1.2.3-2.3-.25M45.95 11q-.1-1.4-.95.55-.85 1.9-1.85 3.05-1.05 1.1-4.9 3.6.35-.05.65-.05 1.8-.4 3.4-1.25 1.25-.65 4.25-2.25.15.3.3.55-.1-.6-.3-1.25-.5-1.55-.6-2.95m11.2-1.65q-.55-1.9-.75-2.85-1.45 3.7-4.05 7.25-1.7 2-2.05 2.3 1.1-.6 2.4-1.4 1.1-1.1 2.05-2 1.65-1.55 2.25-1.8.1-.05.2-.05.4 0 .4 1.9v.1h.1q0-1.4-.55-3.45m6.45-7.1l-.25-1.2q-.35 3-1.7 6.5-1.4 3.5-2.65 4.6 2-1.1 3.15-2.8 1.4-2.65 2.25-3.95v.05q.05.05.1.15-.15-.5-.25-.8-.05-.05-.65-2.55z"
                    id="BeaChi035__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M21 6.4h-.15q-.45 1.2-1.45 3.25-.75 1.75-.8 2.75-.05.6-.2 2.15-.05.15-.05.35-.3-.15-.55-.35-2.35-1.5-4.55-4.1-2.45-2.9-3.6-5.05-1.55 2-2.3 3.25-.75 1.2-1.55 3.7Q2.3 10.8.5.95q-1.55 8 1.85 11.4 3.4 3.35 3.8 3.35.4 0 1.55-3 1.1-3.05 2.15-3.65 2.35 5.5 7.4 8.1 2.2 1.15 4.95 1.7-1.05-1.6-1.45-4.3-.1-.55-.15-1.15-.3-3.55.4-7m24.05 3.15q-.85-1.2-2.6-2.9-.15 1.05-3 4.7L37.9 13.3q-1.05-1.4-2.5-2.9-2.2-2.35-2.8-3.55 3 9.1 5.6 9.75 1.8-2.35 4.9-5.6.75 1.1 6.3 6.15-.05-.15-.05-.2-.75-1.9-1.8-3.25v-.05q-1.95-3.25-2.5-4.1m9.4-2.4q.35 1.05.95 4.8 1.55-.85 2.7-2.55 1.15-1.75 1.8-3.6.6-1.9.3-3.5T59.9 0q-.85 5.15-3.55 8.55-.85-4.5-3.85-5.6 1.6 3.1 1.95 4.2z"
                    id="BeaChi035__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi035__Symbol_74_0_Layer0_0_FILL"
                transform="translate(152.85 227.3)"
                id="BeaChi035__col1n"
            />
            <use
                xlinkHref="#BeaChi035__Symbol_33_0_Layer0_0_FILL"
                transform="translate(162 251)"
                id="BeaChi035__col1d"
            />
            <use
                xlinkHref="#BeaChi035__Symbol_10_0_Layer0_0_FILL"
                transform="translate(165.05 239.3)"
                id="BeaChi035__col1l"
            />
        </g></g>
    )
}

export default BeaChi035
