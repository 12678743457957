import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}
function AccHat166({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M173 .95q-1.3-.6-2.75-.8-4.8-.8-11.4 2.15-3.6 1.55-8.65 4.7-2.4 1.5-4.4 2.9-7.65 7-9.5 9.05-1.85 2-10.6.95-1.45-.2-7.5-.95-20.6-4.75-42.6 0-6.75.85-7.4 1-10 2.05-14.25-1.05-.5-1.15-6.75-9-2-1.4-4.4-2.9-5.05-3.15-8.65-4.7Q27.5-.65 22.7.15q-1.45.2-2.7.8Q15.5 3.6 13.8 10q4.65 9.15 9 12.05-5.95.3-9.9 2.25.1 1.15.25 2.4.15 1.1.45 2 .65 1.95 1.1 3.65.6 2.55.6 4.35v3.25q3.85 4.3 1.9 8.75.1 1.6-6.85 7.25-.4.65-.8 1.25-1.4 2.15-2.5 4.95-.15.3-.25.65-.3.75-.55 1.6-.3.85-.55 1.8-.35 1.25-.65 2.55-.1.45-.2.95-.5 2.6-.75 5.1v.05Q4 75.6 4 76.3q-.25 2.85-.1 5.55.3-.25-1.05 5-.65.5-2.85 2.45-.2 2.75 1.3 5.4 1.3 1 3.75 1.2 3.75.3 10.15-1.2 13.6-4.3 17.55-5.4.2-.05.35-.1 10.95-3.2 21.85-5.3 1.1-.15 2.15-.3 18.65-2.5 36.85-2.65h5.1q18.2.1 36.85 2.65 1.05.15 2.15.3 10.9 2.1 21.85 5.3.15.05.35.1 3.95 1.1 17.55 5.4 11.45 3.05 13.9 0 1.5-2.65 1.3-5.4-2.3-2.35-2.95-3.55-1.1-2-.95-3.9.15-2.7-.1-5.55 0-.7-.1-1.45v-.05q-.25-2.5-.75-5.1-.35-1.85-.85-3.5-.25-.95-.55-1.8-.25-.85-.55-1.6-.1-.35-.25-.65-1.1-2.8-2.5-4.95-.4-.6-.8-1.25-6.35-6.5-7.05-9.1-.65-2.45 2.1-6.9V38.8v-2.1q0-1.8.6-4.35.45-1.7 1.1-3.65.3-.9.45-2 .15-1.25.25-2.4-1.5-1.55-4.6-1.1 2.55-4.6 3.7-13.2-1.7-6.4-6.2-9.05z"
                    id="AccHat166__Symbol_551_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.2 80.9q3.45-2.2 10.35-5.6l7.95-3.2q.4-.1.75-.2-.15 0-.35.05 3.75-1.3 7.45-2.45 12.85-4 25.9-6.4 1.45-.3 2.95-.55-8.3.8-17.35 2.45Q21.4 68.15 1.2 74.35q-.45.15-.95.3v.05q-.1.75-.1 1.45Q-.1 79 .05 81.7q.5-.4 1.15-.8m8.1-54.35q.15 1.1.45 2 .65 1.95 1.1 3.65.6 2.55.6 4.35v2q2.05-3.2 3.75-5 1.75-1.85 3.2-3 1.35-1.1 4.3-3l.8-.5q-11.1.95-14.45-2.9.1 1.15.25 2.4m9.4-24.9q0-.85.15-1.65-1.45.2-2.7.8-4.5 2.65-6.2 9.05Q12 13.6 23.4 12.9q-4.7-6.35-4.7-11.25M57.2 21.5q-4.7.55-9.4 1.3l-9.75 5.75L57.2 21.5m71.4.05l18.95 7-9.65-5.7q-4.65-.75-9.3-1.3m45.15 53.75q8.2 4.05 11.5 6.4.15-2.7-.1-5.55 0-.7-.1-1.45v-.05q-20.75-6.4-38.6-9.65-9.05-1.65-17.35-2.45 1.5.25 2.95.55 13.05 2.4 25.9 6.4 3.7 1.15 7.45 2.45-.2-.05-.35-.05.35.1.75.2l7.95 3.2m1.8-46.75q.3-.9.45-2 .15-1.25.25-2.4-3.35 3.85-14.5 2.9l.8.5q2.95 1.9 4.3 3 1.45 1.15 3.2 3 1.7 1.8 3.75 5 .048.048.05.1v-2.1q0-1.8.6-4.35.45-1.7 1.1-3.65M169.15.8q-1.3-.6-2.75-.8.15.8.15 1.65 0 4.9-4.7 11.25 11.4.75 13.5-3.05-1.7-6.4-6.2-9.05z"
                    id="AccHat166__Symbol_552_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M189.7 72.4q-.3-.25-.6-.45-3.3-2.35-11.5-6.4l-7.95-3.2q-.4-.1-.75-.2.15 0 .35.05-3.75-1.3-7.45-2.45-12.85-4-25.9-6.4-18.65-2.5-36.85-2.65h-5.1q-18.2.15-36.85 2.65-13.05 2.4-25.9 6.4-3.7 1.15-7.45 2.45.2-.05.35-.05-.35.1-.75.2l-7.95 3.2q-6.9 3.4-10.35 5.6-.65.4-1.15.8-.3.2-.6.45t-.65.5q-.15.2-.3.45-.45.65-.8 1.3Q.15 77.05 0 79.4q.75 2.6 5.05 2.7 3.45.05 9.25-1.5 2-.6 4.05-1.2 37.6-11.7 75.6-12.25h2.55q1.3 0 2.55.05 38 .5 75.6 12.2 2.05.6 4.05 1.2 13 3.5 14.3-1.2-.15-2.35-1.55-4.75-.35-.65-.8-1.3-.15-.25-.3-.45-.35-.25-.65-.5m-11.25-36.9q-.35-1.5-.7-3.35 0-1.15-.05-2.1-4.25 3.35-15.5.55-1-.3-2.05-.55-7.65-2-15.35-3.55-23.2-3.75-45.75-3.85-1.25-.05-2.55 0h-2.55v-.05q-22.5.1-45.75 3.9-7.7 1.55-15.35 3.55-1.05.25-2.05.55-11.25 2.8-15.5-.55-.05.95-.05 2.1-.35 1.85-.7 3.35-.3 1.25-.6 2.3-1.2 4.25-3.6 8.25.8 4.15 12.85.85 1.4-.45 2.85-.85Q36.6 43 47.1 40.8q.55-.1 1.1-.15 23.25-3.8 45.75-3.9h3.8q.65-.05 1.3 0 22.55.1 45.75 3.9.55.05 1.1.15 10.5 2.2 21.05 5.25 1.45.4 2.85.85 12.05 3.3 12.85-.85-2.4-4-3.6-8.25-.3-1.05-.6-2.3M179.2.1q-2.1 3.8-13.5 3.05-7.8-.5-19.9-3.15-.6.4-2.25 1.45-1.7 1-2.95 2.05-1.25 1-5.8 3.7-.85.45-1.65.8-2.45.9-5 .9-2 0-4.7-.5h-.35l-.15-.05q-.15-.05-.25-.1t-.2-.05q-3.7-1.45-7.4-2.55-2.1-.55-4.2-1-13.95-2.45-28.85 0-2.1.45-4.15 1-3.8 1.15-7.6 2.6-.1.05-.25.1l-.5.05q-2.7.5-4.7.5-2.45 0-4.75-.85-1-.35-1.9-.85Q56 5.95 54 4.5 52 3 49.95 1.7 47.8.4 47.2 0 35 2.6 27.25 3.15 15.85 3.85 13.8.1l-.2.7q-1.3 5.5-.7 13.6 3.35 3.85 14.45 2.9 5.2-.45 12.1-1.95 2.05-.5 4.15-.95.55-.15 1.15-.25 3.45-.6 6.9-1.1 4.7-.75 9.4-1.3 16.65-2 32.9-2.1h5.1q16.5.1 33.4 2.15 4.65.55 9.3 1.3 3.25.5 6.5 1.05.6.1 1.15.25 2.1.45 4.15.95 6.85 1.5 12.05 1.95 11.15.95 14.5-2.9.6-8.1-.7-13.6l-.2-.7z"
                    id="AccHat166__Symbol_550_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M20.35 11.45q0-.9-5.25-7.65-.3-.4-.55-.75Q3.15 3.75 1.1 0L.9.7Q-.4 6.2.2 14.3q3.35 3.85 14.45 2.9 1.75-1.15 3.95-2.45 1.75-1.25 1.75-3.3m36.5-3.15q-2.7.5-4.7.5-2.45 0-4.75-.85l-8.45 5q4.7-.75 9.4-1.3l5.75-2.1q1.5-.35 2.75-.75 1.1-.4 1.9-.9-.35.1-.65.15l-.5.1q-.1.05-.25.1l-.5.05m57.35 1.4l5.55 2q4.65.55 9.3 1.3l-8.6-5.1q-2.45.9-5 .9-2 0-4.7-.5h-.35l-.15-.05q-.15-.05-.25-.1-.55-.1-1.15-.25.8.5 1.9.9 1.5.55 3.45.9m53.2 4.6q.6-8.1-.7-13.6l-.2-.7q-2.1 3.8-13.5 3.05-.25.35-.55.75-5.25 6.75-5.25 7.65 0 2.05 1.75 3.3 2.2 1.3 3.95 2.45 11.15.95 14.5-2.9z"
                    id="AccHat166__Symbol_553_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat166__Symbol_551_0_Layer0_0_FILL"
                transform="translate(95.2 32.05)"
                id="AccHat166__col1n"
            />
            <use
                xlinkHref="#AccHat166__Symbol_552_0_Layer0_0_FILL"
                transform="translate(99.05 32.2)"
                id="AccHat166__col1d"
            />
            <use
                xlinkHref="#AccHat166__Symbol_550_0_Layer0_0_FILL"
                transform="translate(95.2 41.95)"
                id="AccHat166__col2n"
            />
            <use
                xlinkHref="#AccHat166__Symbol_553_0_Layer0_0_FILL"
                transform="translate(107.9 42.05)"
                id="AccHat166__col2d"
            />
        </g></g>
    )
}
export default AccHat166
