import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.95.55q-.15-.35-.5-.5-.4-.15-.75.05Q14.55 5.7 1.35.1.95-.1.6.1.25.2.1.6q-.2.35 0 .7.1.35.5.55Q14.65 7.8 27.5 1.8q.35-.15.5-.5.15-.4-.05-.75z"
                    id="FacMou001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacMou001__col1n" transform="translate(178.5 224.8)">
                <use
                    xlinkHref="#FacMou001__Symbol_1_0_Layer0_0_FILL"
                    id="FacMou001__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacMou001
