import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D9D9D9",
    "col1s": "#999999",
    "col2n": "#666666"
}

function BodMor007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.95 10.2q-.1-.3-.2-.55-.05-.3-.05-.55-.1-.1-.2-.15-1.05-.65-2.25-1.25-2.4-1.45-4.7-3.05L27 3.5q-1.45-1.05-2.8-2.1L22.35 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25-.1.25-.2.55-.3.75-.6 1.6-.05.1-.1.25v.05l-.3.8q-.5 1.45-.95 2.95-.2.6-.4 1.25l-.8 2.8q-.15.45-.3.95-.3 1-.55 2.05v.05q-.1.3-.15.55l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.3 1.35-.6 2.75-.1.3-.15.65v.2q-.1.3-.15.6-.05.4-.15.8-.05.2-.05.4-.05.05-.05.15-.55 2.45 0 2.1 1.3-1.1 2.85-1.8Q4.9 53.4 6.95 53q.15-.05.3-.05l1.85-.2q4.5-.2 7.95 1.3l.6.3q1.7.8 3.15 2.15.1.1.25.2l.5.5h.3l.9.75q2.55-10.2 4.3-20.65.15-.9.3-1.75v-.2q1.55-.75 3.05-1.85l1.3-1.1q.85-1.2 1.2-3.3V29q.25-1.5.5-2.5.4-1.9.7-2.4 2.5-3.85 2.5-10.45 0-1.8-.65-3.45z"
                    id="BodMor007__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M7.25 43.3l1.85-.2q4.5-.2 7.95 1.3l.6.3q-.15-.15-.3-.25-.65-.55-1.5-1.05-.15-.15-.4-.2-.65-.35-1.5-.7-3.3-1.3-7.6-.95-3.25.3-5.8 1.4-.05.4-.15.8-.05.2-.05.4-.05.05-.05.15-.55 2.45 0 2.1 1.3-1.1 2.85-1.8 1.75-.85 3.8-1.25.15-.05.3-.05M36.6 4q0-1.8-.65-3.45-.1-.3-.2-.55 1.15 10.7-5.3 17.5-.8.85-1.7 1.65-1.6 1.35-3.5 2.55-2.1 1.25-4.55 2.35-.55.1-1.05.2-11 2.35-12.6-5-.3.75-.6 1.6-.05.1-.1.25v.05q1.05 3.95 5.05 4.9l-.95 3.1q-3.6-.3-6.85.75-.3 1-.55 2.05V32q3.55-2.45 8.8-1.5 3.7.65 8.3 3 .55.3 1.15.65l.15.15q2.45 2.5-.05 11.5-.2.6-.35 1.25l.5.5h.3l.9.75q2.55-10.2 4.3-20.65.15-.9.3-1.75v-.2q1.55-.75 3.05-1.85l1.3-1.1q.85-1.2 1.2-3.3-2.4 2.8-5.45 4.6l.15-2.1q2.9-2.05 5.8-5.1.4-1.9.7-2.4Q36.6 10.6 36.6 4M18.85 25.75q.5-.1 1.05-.2 3.4-.85 4.9-2l-1.8 7.5q.1 2-2.2 1.25-.4-.2-.75-.35-.1-.05-.15-.05-2.5-1.25-4.85-1.9l.95-3.75q1.3-.15 2.85-.5m-6 .55q.75.05 1.6.05l-.8 3.3q-.75-.2-1.5-.3l.7-3.05z"
                    id="BodMor007__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.75 42.7q0-.4-.25-.7-.3-.3-.75-.3-.4 0-.7.3-.25.25-.3.55v.15q0 .45.3.75.3.25.7.25.25 0 .45-.05l.3-.2q.25-.3.25-.75M.7 26.7L0 29.75q.75.1 1.5.3l.8-3.3q-.85 0-1.6-.05m7.8 3q-.3.3-.3.7 0 .45.3.75.3.25.7.25.45 0 .75-.25.25-.3.25-.75 0-.4-.25-.7-.3-.3-.75-.3-.4 0-.7.3m2.5-2.4q0-.4-.25-.7-.3-.3-.75-.3-.4 0-.7.3-.3.3-.3.7 0 .45.3.75.3.25.7.25.45 0 .75-.25.25-.3.25-.75M21.7.3q-.3-.3-.75-.3-.4 0-.7.3-.3.3-.3.7 0 .45.3.75.3.25.7.25.45 0 .75-.25.25-.3.25-.75 0-.4-.25-.7m-.95 19.45q.25-1.5.5-2.5-2.9 3.05-5.8 5.1l-.15 2.1q3.05-1.8 5.45-4.6v-.1z"
                    id="BodMor007__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodMor007__Symbol_14_0_Layer0_0_FILL"
                transform="translate(110.1 228.6)"
                id="BodMor007__col1n"
            />
            <use
                xlinkHref="#BodMor007__Symbol_15_0_Layer0_0_FILL"
                transform="translate(110.1 238.25)"
                id="BodMor007__col1s"
            />
            <use
                xlinkHref="#BodMor007__Symbol_16_0_Layer0_0_FILL"
                transform="translate(122.25 237.85)"
                id="BodMor007__col2n"
            />
        </g></g>
    )
}

export default BodMor007
