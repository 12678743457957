import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C49D89",
    "col1n": "#AA816C"
}
function BodHor006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.9.35q-.6.3-1.15.65-.7.45-1.4.95-1.7 1.2-3.15 2.75-.1.05-.15.1Q9.9 7 8.2 9.8q-.05.15-.15.15-.1.25-.3.55l-1.1 2.1Q6.3 13.3 6 14q-2.5 5.3-3.95 11.1-5.6 28.55 4.2 42.85 9.8 14.35 35.1 14.35 3.45-.05 7.7-.4.35-.05.7-.05 1.3-.15 2.7-.25.7-.1 1.45-.15.65-.1 1.3-.15.3-.05.65-.05 0-.05 1.05-2.4l.05-.05q0-.05.05-.1.65-1.6 1.75-4.05.4-1 .9-2.15.8-1.85 1.8-3.75 1.65-3.35 3.9-6.9.1-.2.25-.45.6-.95 1.3-1.95 1-1.6 2.15-3.2.85-1.25 1.8-2.45-.6.1-1.1.25-.35.05-.7.15-11.2 2.5-20 2.6-16 .1-24.15-7.7Q8.4 34.75 18.75 2.25q0-.25.05-.45 0-.15.05-.3.1-.2.15-.4.05-.35.05-.65.05-.8-1-.25l-.15.15M291.35 25.1q-1.45-5.8-3.95-11.1-.3-.7-.65-1.4l-1.1-2.1q-.2-.3-.3-.55-.1 0-.15-.15-1.7-2.8-3.85-5-.05-.05-.15-.1-1.45-1.55-3.15-2.75-.7-.5-1.4-.95-.55-.35-1.15-.65l-.15-.15q-1.05-.55-1 .25 0 .3.05.65.05.2.15.4.05.15.05.3.05.2.05.45Q285 34.75 268.5 49.1q-8.15 7.8-24.15 7.7-8.8-.1-20-2.6-.35-.1-.7-.15-.5-.15-1.1-.25.95 1.2 1.8 2.45 1.15 1.6 2.15 3.2.7 1 1.3 1.95.15.25.25.45 2.25 3.55 3.9 6.9 1 1.9 1.8 3.75.5 1.15.9 2.15 1.1 2.45 1.75 4.05.05.05.05.1l.05.05q1.05 2.35 1.05 2.4.35 0 .65.05.65.05 1.3.15.75.05 1.45.15 1.4.1 2.7.25.35 0 .7.05 4.25.35 7.7.4 25.3 0 35.1-14.35 9.8-14.3 4.2-42.85z"
                    id="BodHor006__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M41.4 45.65q4.25-.75 4.65-6.2-.25-3.35-2.45-3.55-.25-.05-.5-.05-.7.1-1.25.2l-1.6.2-1.65.15q-18.95 1.55-27.9-7.05Q0 16.4.55 0q-3.5 32.1 15.8 42.45 8.25 3.7 22.25 3.35l1-.05q.5 0 .95-.05h.1q.4 0 .75-.05m218.45-3.2Q279.15 32.1 275.65 0q.55 16.4-10.15 29.35-8.95 8.6-27.9 7.05l-1.65-.15-1.6-.2q-.55-.1-1.25-.2-.25 0-.5.05-2.2.2-2.45 3.55.4 5.45 4.65 6.2.35.05.75.05h.1q.45.05.95.05l1 .05q14 .35 22.25-3.35z"
                    id="BodHor006__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHor006__Symbol_16_0_Layer0_0_FILL"
                transform="translate(45.8 41.65)"
                id="BodHor006__col1n"
            />
            <use
                xlinkHref="#BodHor006__Symbol_17_0_Layer0_0_FILL"
                transform="translate(54.4 69.5)"
                id="BodHor006__col1d"
            />
        </g></g>
    )
}
export default BodHor006
