import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#794D32",
    "col1n": "#AC8268",
    "col1s": "#4D2E1B",
    "col2n": "#E0CEC3"
}

function AccMor024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.35 23.6q-4.75.7-11.2-1.9-.75-.35-1.5-.65l-3.8-2.25q.55-.35 1.15-.85 5.55-4.5 6.4-4.6l-2.05-8.05L89 4 7.85 0l-1.7 6.75 8.15 3.55L4.55 13l-1.3 5.05q.55.75 3.05.7 31.45 2.2 62.3 1.05h.6q.45-.05.95-.05l39.95-1.65 7.7 1.45 3.1 1.95q10.45 6.85 20.8 6.9l1.65-4.8m-21.85-8.35q.05.2.05.45l-.2-.15q.1-.15.15-.3z"
                    id="AccMor024__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M29.5 12.5v.1q-1.35-.2-3.05-.4-3.9-.35-7.7-.75-1.8-.2-3.25-.3v-.05q-7-.75-13.9-1.65L0 15.7q.5.05 1.05.05.85.05 1.75.1 13.05.55 25.95.7 19.15.2 37.95-.55 7.55-.3 15.05-.75-8.8-.15-17.55-.5Q46.7 14 29.5 12.5m-.2 1.1v.05l-2.3-.1q1.25 0 2.3.05m-14.05-1.25l.05-.15q1.4.25 3.2.55l-3.25-.4m93.9 7.2q-1.5-.25-1.7-.4-21.55 2.5-43.4 3.35 24.4.9 49.2-.25l-1.45-.95q-1.15-1.55-2.65-1.75m20.7-3.5q-.85.1-1.7.25-5.6.85-11.2 1.65-.05.15-.15.3l5.9 3.45q4.2-.25 8.4-.6l-1.25-5.05M127.8 8l-.9-3.45q-3.6-.65-15.9-.05-12.35.55-21.05.65-8.75.1-17-.1l-8.8-.3Q34.05 3.55 3.95 0L3.3 2.7q6.8.95 13.65 1.8 1.75.15 2.95.15l-.05.2q1.7.2 3.5.4 2.15.2 4.3.45 1.8.1 3.15.15l-.05.15q16.75 1.65 33.3 2.45Q96.35 9.9 127.8 8z"
                    id="AccMor024__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M14.05 11.8l1.75-9.2-3.1-.2-1.75 9.25 3.1.15m-10.8-.9l1.6-9.6-2.8-.35L0 10.5l3.25.4m86.3-7.55q11.05-.15 22.1-.65L111 0Q91.45 3.05 74.2 3.45q7.65 0 15.35-.1z"
                    id="AccMor024__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.6 8.1q.1-.15.15-.3v-.05q.4-1.05.4-2.2 0-1.95-1.2-3.45l-.35-.35-.1-.1Q7.85 0 5.6 0 3.3 0 1.65 1.65q-.45.45-.8 1-.15.2-.25.45Q0 4.15 0 5.55q0 1.9 1.2 3.4l.05.05q.15.2.4.5 1.65 1.65 3.95 1.65 2.25 0 3.9-1.65.65-.65 1.1-1.4m-5-5.65q.3 0 .6.05.85.15 1.5.8.9.9.9 2.15t-.9 2.1L7.25 8q-.45.25-.9.4-.35 0-.65.05h-.1q-.5 0-.95-.15-.65-.25-1.2-.75-.85-.85-.85-2.1t.85-2.15q.45-.4.95-.6.55-.25 1.2-.25z"
                    id="AccMor024__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor024__Symbol_122_0_Layer0_0_FILL"
                transform="translate(122.8 291.6)"
                id="AccMor024__col1n"
            />
            <use
                xlinkHref="#AccMor024__Symbol_73_0_Layer0_0_FILL"
                transform="translate(127.35 288.9)"
                id="AccMor024__col1d"
            />
            <use
                xlinkHref="#AccMor024__Symbol_44_0_Layer0_0_FILL"
                transform="translate(142.6 290.75)"
                id="AccMor024__col1s"
            />
            <use
                xlinkHref="#AccMor024__Symbol_27_0_Layer0_0_FILL"
                transform="translate(233.55 299.05)"
                id="AccMor024__col2n"
            />
        </g></g>
    )
}

export default AccMor024
