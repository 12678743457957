import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48.25 48.35q5.65-.9 10.75-2.7V7.1q-.75-1.15-2.55-1.95-1-.4-1.8-.7Q43.75.6 33 .05l-3.4 5.5v-.1L26.9 0Q15.55.4 4.35 4.45q-.8.3-1.75.7Q.75 5.95 0 7.1v38.45q5.55 2.4 11.1 2.85-2.4-1.85-3.95-4.7-.25-.35-.45-.7-3.9-6.05-4.9-19.6Q.4 9.35 3.45 8.7q13-3.3 25.8-3.1h.8Q42.7 5.45 55.4 8.55q3.1.9 1.45 14.2-.1.95-.25 1.95-1 12.65-4.85 19.2-1.7 3.1-3.5 4.45z"
                    id="BeaMus033__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus033__Symbol_50_0_Layer0_0_FILL"
                transform="translate(163 199.4)"
                id="BeaMus033__col1n"
            />
        </g></g>
    )
}

export default BeaMus033
