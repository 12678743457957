import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2n": "#FFFFFF"
}

function HaiSpe028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.75 134.2q-1.45 6.3.35 12.65.45 1.55 1.35 2.7h-.05l.6 1.2-.1-.1.1.15h-.2q-.3.1-.55.25-3.55 1.8-3.4 8.9.05 5.45 1.05 8.05.35.95.85 1.5l-.2.3q-5.1 5.7-1.65 13.2-.3.15-.75.5l-.05-.05q0 .05-.05.1-.05 0-.1.05-1.65 1.05-2.85 2.95-.65.75-1.55 2.5-1.35 2.55-1.7 3.65-.3.75-1 3.4-.45 1.7-.65 2.8-.15.85-.15 1.3 0 .65.1 1.2l-3.05 1.55q-.45.2-.85.4Q5 206.5 2.4 212.5q-3.85 8.2-1.5 16l.1.25q.25-.5.9-1.6v-.05q.8-1.05 2.2-2.1 2.9-2.1 5.2-3.5.1-.1.25-.2.45-.3.85-.55.15-.05.25-.15.15-.1.35-.15.05-.05.15-.1l.2-.1-.15.2q-1.45 5.75-6.15 11.75-.2.25-.4.55L.1 237.8q-.05 0-.1.05h.1q10.95.05 15.95-6.05v-.05q.55 2.25 2.8 4.05 1.9 1.45 3.8.85-1.4-1.25-1.8-2.45-.45-1.2.6-3.2 1-1.95 3.75-5.25 2.7-3.35 4.15-6.25.1-.25.2-.45 1.4-4.05.05-7.95l-.1-.45q-.15-.95-.7-1.8v-.1q-.15-.15-.25-.4-.05-.1-.1-.15-.1-.3-.15-.5.2-.25.55-.6 1.1-1.3 1.35-1.55.2-.25 2.65-2.5 4.1-2.95 6.25-5.45.8-.7 1.25-1.15 1.05-1.25 1.45-2 .55-1.05.55-2.1 0-2.2-1.5-3.1l.2-.4q.2-.3.35-.6 8.05-13.9 3.25-16.35-.35-.25-.8-.45.75-.35 1.7-1.2.65-.45 3.35-2.4.3-.25.6-.4.5-.4.95-.7l.5-.4-1.6-1.5-.9-.9q-1.75-1.75-3.35-3.55-1.65-1.75-3.15-3.6-.55-.65-1.05-1.25t-.9-1.15q-2.2-2.8-4.15-5.7-5.55-8.25-9.05-17.3l-.2-.5-.15.8q-.45 1.25-.7 2.5M185.5 28.25q-6.5-6.5-13.6-11.45-5.75-4.05-11.95-7.1-15.8-7.9-34.25-9.35-1.35-.15-2.75-.2h-.75Q119.75 0 117.25 0h-2.2q-2.5 0-4.95.15h-.75q-1.4.05-2.75.2Q88.15 1.8 72.35 9.7q-6.2 3.05-11.95 7.1-7.1 4.95-13.6 11.45-27.15 27.2-28.2 65.2-.45 8.05.7 15.4 1.5 10.1 6 18.9-.05-.5-.05-1.05-.35-7.35.75-13.85 2.7-16.8 14.6-28.45 6.3-6.2 15.2-10.95 16.15-6 30.35-13.8 15.15-8.35 28.1-18.75.75-.6 1.9-1.75 1.15 1.15 1.9 1.75 12.95 10.4 28.1 18.75 14.2 7.8 30.35 13.8 8.9 4.75 15.2 10.95 11.9 11.65 14.6 28.45 1.1 6.5.75 13.85 0 .55-.05 1.05 4.5-8.8 6-18.9 1.15-7.35.7-15.4-1.05-38-28.2-65.2z"
                    id="HaiSpe028__Symbol_160_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22.4 7.7q.4-.65.75-1.15l.05-.05q.05-.15.15-.3.05-.1.1-.1l-.85-.95q.05.05-.15.25-.15.2-.9 1.25-.8 1.05-3.7 5.95-.5-1.05-.75-1.95-.3-.9-2-4.8-1.3-2.9-3-4.5-.65-.7-1.4-1.15-.3-.1-.5-.15Q10.1 0 10 0h-.05l.6 1.2q0 .05.05.1.4.5.9 1 .35.4.75.75.75.75 2.1 4.05 1.5 3.45 2.25 5.65.7 2.1 1.3 3.8.55 2.7 1.35 5.45-.45.2-.75.3l-.35.2q-1.65.9-2.65 2.1-.95-1.75-3.65-3.6-.8-.55-1.8-1.1-.25-.15-.4-.25-1-.85-2.2-1.2.35.95.85 1.5l-.1.15q.25-.05 2.4 1.4 2.25 1.45 3.3 3.3.4.7 1.85 5.6.5 2.85.8 4.1.45 2.65-.1 3.45-4.1.7-5.7 2.7-.05-.55-.1-1-.1-1.2-1.35-3.4L7.55 33.7q-.35-.35-.95-.35-.05.05-.15.1-.3.15-.75.5.2.1.4.25 2.65 2.7 3.45 6.3.05.65.3 6.1.5 3.55.15 5.7l-.5 1.4q-1.55 1.7-2.55 3.2-.15.15-.3.35-.95-.2-2.05-.3H2.65q-.792.198-1.1.5.056-.053.15-.1-.1.15-.2.25.7 2.45.9 7.7.15 5.5-2.4 12.6 6.4-4.35 6.1-15.3 2.95 3.35 1.6 8.3l1.45-2.15q2.05-2.45 3-7v-.2l-.1-.45q-.15-.95-.7-1.8v-.1q-.15-.15-.25-.4-.05-.1-.1-.15-.15-.15-.35-.3-.3-.15-.7-.3Q9.9 58 9.8 58h-.05q-.85-.3-1.75-.5.3-.45.75-1 2-2.6 2.15-2.85.15-.3.4-2.65t.05-3.6q-.1-.8-.2-1.55Q11.1 44 11 42.5l.2-.4 1.7-1.35q.65-.55 2.9-1.3.4-.15 1.2-.2 1.25-.25 1.9-.15h2.75q1 .15 1.75.6l.2-.4q.2-.3.35-.6-2.7-1.3-6.3-.9.55-1 .15-4.3-.4-2.9-.95-3.8-.6-1.4-1-3.4l.95-.95q1.65-1.85 3.1-2.45 2.5-1.1 3.75-1.2.4-.05.7-.05.8-.05 1.3.1l.1.05h.35q.05 0 .1.05h.15l.05-.05q.7-.3 1.6-1.1h-.2q-.8-.15-1.7-.15l-2.6.1q-.8.15-1.3.25-1 .3-1.7.5-.05 0-.15.05 0-.45-.1-1.1-.15-.65-.45-1.6-.4-2.05-.65-2.55-.5-1.25-.8-1.75.65-1.05 1.8-2.85 1.3-2.4 2.25-3.9z"
                    id="HaiSpe028__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M12.1 219.6q-.3.2-.65.4l-.15.2-.1.1q-1.45 5.75-6.15 11.75-.2.25-.4.55l-4.5 5Q2.2 236.3 4 235q4.55-3.4 7.35-7.05 3.9-5.05 3.05-10.85-.45 1.2-2.3 2.5m5.4-14.65q-1.1-.95-1.55-1.6-.6-.85-.8-2.1l-3.05 1.55q-.45.2-.85.4-6.25 3.15-8.85 9.15-3.85 8.2-1.5 16l.1.25v-.05q1.1-19.3 17.25-23-.35-.3-.75-.6M113.45 5.4q-.7-2.05-1.65-3.3-.05-.05-.1-.15l-.05-.05L110.1 0h-.75q-1.4.05-2.75.2-18.45 1.45-34.25 9.35l1.25-.25Q85.4 6 94 5.9q.35 0 .7.05-2.4 1.2-13.35 6.55-9.5 4.65-13.9 9.15 3.45-1.55 12.4-4.8l15.5-5-8.5 5.5Q79.4 21.4 62.35 32.6q20.8-9.6 42.25-15.75L62.05 44.5q17.05-4.65 40.8-18.65Q92.15 37.8 75 50.3q.1-.15-6.6 4.2L42.25 69.25q11-4.45 20.7-9 34.25-16.05 52.4-33.4v-.2h-.05q.45-14.7-1.85-21.25m76.6 63.85L163.9 54.5q-6.7-4.35-6.6-4.2-17.15-12.5-27.85-24.45 23.75 14 40.8 18.65L127.7 16.85Q149.15 23 169.95 32.6q-17.05-11.2-24.5-15.25l-8.5-5.5 15.5 5q8.95 3.25 12.4 4.8-4.4-4.5-13.9-9.15Q140 7.15 137.6 5.95q.35-.05.7-.05 8.6.1 20.4 3.4l1.25.25Q144.15 1.65 125.7.2q-1.35-.15-2.75-.2h-.75l-1.55 1.9-.05.05q-.05.1-.1.15-.95 1.25-1.65 3.3-2.3 6.55-1.85 21.25h-.05v.2q18.15 17.35 52.4 33.4 9.7 4.55 20.7 9z"
                    id="HaiSpe028__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.95 10.2q.2-.1.35-.25.5-.35.55-.85.05-.25.05-.5 0-.7-.15-1.1-.25.3-1.3.45h-.1q-1.75.15-4.6-.55-.25-.1-.45-.15-1.2-.35-2.5-1-1.95-1.2-2.95-2.3-.15-.1-.2-.2Q1.3 1.95 1.55 0 1.3.05 1 .25.15.8 0 1.55-.2 5 3.45 7.6q2.05 1.7 5.5 2.5 3.7.95 6 .1z"
                    id="HaiSpe028__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe028__Symbol_160_0_Layer0_0_FILL"
                transform="translate(76.35 57.4)"
                id="HaiSpe028__col1n"
            />
            <use
                xlinkHref="#HaiSpe028__Symbol_93_0_Layer0_0_FILL"
                transform="translate(93.8 206.95)"
                id="HaiSpe028__col1d"
            />
            <use
                xlinkHref="#HaiSpe028__Symbol_60_0_Layer0_0_FILL"
                transform="translate(76.35 57.55)"
                id="HaiSpe028__col1l"
            />
            <use
                xlinkHref="#HaiSpe028__Symbol_22_0_Layer0_0_FILL"
                transform="translate(90 256.35)"
                id="HaiSpe028__col2n"
            />
        </g></g>
    )
}

export default HaiSpe028
