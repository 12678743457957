import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#4358A8",
    "col2n": "#004D1E"
}

function ShiLon067({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M118.5 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-20.9-3.2-38.2-15.15L4.9 3.5Q3.45 2.45 2.1 1.4L.25 0Q.15.05.1.15.05.25 0 .3l2.8 4.95Q3.6 6.8 4.5 8.3q4.1 6.8 10.75 11.3.75.45 1.55.95 1.95 1.2 4.05 2.2 6.75 3.15 15.45 4.6 2.65.4 5.55.65 1.3.1 2.65.2 1.9.15 3.9.2 1.2-1.05 2.45-2.05 3.7-3.05 7.95-4.15l1.65-.25q4.75 1.05 8.85 4.4 1.25 1 2.45 2.05 16.45-.45 27.55-5.65 2.1-1 4.05-2.2.8-.5 1.55-.95 6.65-4.5 10.75-11.3.9-1.5 1.7-3.05l2.55-4.7v-.1l.05-.05.1-.15-1.55 1.15M59.75 21.15l.1-.1v.1h-.1M56 21.4h-.05q.048-.144.05-.25v.25M6.35 5.2q.4.3.8.55v.05q-.3-.2-.55-.4-.15-.1-.25-.2z"
                    id="ShiLon067__Symbol_414_0_Layer0_0_FILL"
                />
                <path
                    d="M191.9 249.7v19.9h1.2v-19.9h-1.2z"
                    id="ShiLon067__Layer9_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon067__Layer9_1_FILL"
                />
                <path
                    fill="#FF0"
                    d="M161.1 273.4q-.1 0-.15.1-.1.05-.2.15-.9.9-.9 2.15t.9 2.15q.9.9 2.15.9t2.15-.9q.9-.9.9-2.15t-.9-2.15q-.9-.9-2.15-.9-1.05 0-1.8.65z"
                    id="ShiLon067__Layer9_3_FILL"
                />
                <g
                    id="ShiLon067__Symbol_507_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-20.9-3.2-38.2-15.15L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-.05.1-.1.15-3 4-5.6 8.4-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5M142.15.4l.1.1q-.1-.05-.15-.05l.05-.05m-60.1 20.65v.1h-.1l.1-.1M102.2 30l.2-.05-.15.05h-.05m-24-8.6h-.05q.048-.144.05-.25v.25M56.8 41.7q-.1.05-.15.1h-.05v-.05l.2-.05m-5.7 3.1q-.1 0-.15.1-.05-.2-.05-.3l.05.05.15.15m28.8-14.7h-.15q.05-.05.15-.05v.05M28.8 5.4q-.15-.1-.25-.2.4.3.8.55v.05q-.3-.2-.55-.4M53 53.1h-.05q0-.05.05-.05v.05m.65 10.95v-.25l.1.1q-.05.05-.1.15m-1.15-.25q-.25-.35-.55-.65h.55v.65z" />
                    <path d="M51.95 63.15q.3.3.55.65v-.65h-.55m1.7.65v.25q.05-.1.1-.15l-.1-.1m3-22q.05-.05.15-.1l-.2.05.05.05zM52.95 53.1H53v-.05q-.05 0-.05.05m-2-8.2q.05-.1.15-.1l-.15-.15-.05-.05q0 .1.05.3m5.7-3.1l-.05-.05v.05h.05zM28.55 5.2q.1.1.25.2.25.2.55.4v-.05q-.4-.25-.8-.55m49.6 16.2h.05v-.25q-.002.106-.05.25zM79.75 30.1h.15v-.05q-.1 0-.15.05zM102.4 29.95l-.2.05h.05l.15-.05zM82.05 21.15v-.1l-.1.1h.1M142.25.5l-.1-.1-.05.05q.05 0 .15.05z" />
                </g>
                <g id="ShiLon067__Layer9_2_FILL">
                    <path
                        fill="#004D1E"
                        d="M158.05 294.65l.25.1q.337.116.7.2 4.262.896 8.3 0 .302-.067.55-.15.1-.05.25-.1.2-.1.4-.15.8-.45 1.35-1.05 1.05-1.2 1.05-2.75 0-2.6-2.1-2.6-1.05 0-3.35 2-1.3 1.15-1.8 1.8v-.15q.1-2.45.05-4.9h-.05q-.008-1.959-.05-3.9v-.55q-.012-.058-.05-.15-.27-.259-.45-.5-.238.314-.6.65v.9q-.063 1.75-.1 3.5.012 2.45.1 4.9v.1q-.517-.616-1.8-1.75-2.3-2-3.35-2-2.1 0-2.1 2.6 0 1.55 1.05 2.75.573.614 1.35 1 .181.122.4.2z"
                    />
                    <path
                        fill="#FF5BFF"
                        d="M161.8 266.55q-.9.4-1.55 1.35-1.3 1.8-.8 3.35-1.15-.7-3.15-.45-.9.4-1.55 1.35-2.15 3.05.7 5.35.55.45 1.25.35-2 3.25.75 5.4 1.9 1.6 4.15.1.55-.35.9-.75v-.15q.362-.336.6-.65.18.241.45.5.038.092.05.15.25.25.6.55 1.9 1.6 4.15.1 1.6-1 1.5-3-.1-1.55-.7-2.45.45-.2.95-.55 1.6-1 1.5-3-.127-2.204-1.35-3.15-1.693-1.279-3.75 0v-1.45q-.627-3.442-4.7-2.95m-.9 6.65l.05.05.15.15q-.1 0-.15.1-.05-.2-.05-.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon067__Symbol_507_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon067__col1n"
            />
            <use
                xlinkHref="#ShiLon067__Symbol_414_0_Layer0_0_FILL"
                transform="translate(132.2 228.6)"
                id="ShiLon067__col2n"
            />
            <use xlinkHref="#ShiLon067__Layer9_0_FILL" />
            <use xlinkHref="#ShiLon067__Layer9_1_FILL" />
            <use xlinkHref="#ShiLon067__Layer9_2_FILL" />
            <use xlinkHref="#ShiLon067__Layer9_3_FILL" />
        </g></g>
    )
}

export default ShiLon067
