import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#7B5C50",
    "col1n": "#C0A594",
    "col2d": "#4D2E1B",
    "col2n": "#794D32"
}

function AccHat015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M187.75 63.95q.1-.45.2-.85 3.45-16.55-.85-33.05-5.05-17.35-25.25-22.8-.25-.1-.5-.15-.95-.3-1.95-.5-9.25-2.1-18.45-3.55-8.7-1.35-17.3-2.1-8.25-.7-16.4-.85-5.6-.15-11.2 0l-18.15 1Q50.75 3.05 27.65 9.45l-.7.15q-.25.05-.5.15Q7.3 15.35 2.4 32.2q-4.35 16.7-.75 33.4l.1.4Q2.8 71.95 5 77.95 5.9 82 8.5 84q3.75 2.9 11 1.25 1-.15 2.05-.4.7-.15 1.4-.35h.1q6.2-1.65 12.5-3l.85-.15q2.85-.65 5.8-1.2 4.45-.85 8.95-1.55 12.45-1.95 25.1-2.85 24.3-1.7 49.35.55 5.55.5 11.1 1.2 3.65.45 7.3 1 2 .3 4.05.6.45.05.9.15l1.1.2q8.2 1.35 16.45 3.1.25.05.45.1 1.15.25 2.35.5 2.15.5 4.35 1v-.05q.1.05.25.1 1.2.25 2.25.05.8.75 1.55 1.55l.05.05q.2.2.4.45l.1.05q.15.15.3.35.2.25.45.5l.1.1.05.05q4.25 4.8 7.4 10.35 4.25 7.6 7.1 16.7 3.25 7.3 3.8 22.1.35 12.7 7.4 20.3-.35.1.25.75 8.05 9 16.95 14.15 3.2 1.85 6.5 3.15 1.15.45 2.3.85.45.2.9.35l.1.05q6.4 2.35 12.55 3.45 2.25.35 4.45.6 10.55 1.25 19.35-1.85 3.45-1.05 6.9-2.6 1.2-.45 2.45-.95h.05q7.95-3.35 16.35-10.1.1-.1.25-.2.4-.35.85-.7l.2-.2q.15-.15.35-.3-.85.25-1.7.45-.15.05-.25.05-3.9.85-8.3.75h-.05q-1 0-2-.05-2.7-.2-5.6-.7-.15 0-.25-.05-9.9-1.75-18.95-6.85-2.9-1.65-5.5-3.5-2.85-2.05-5.3-4.35-2.7-2.5-4.85-5.35-2.05-2.7-3.5-5.55-3.2-6.1-3.65-12.85-.7-3-.3-7.3.4-4.35.75-5.5.3-1.2.6-3.1 3.25-16.3-1.35-26.7-.7-1.6-1.6-3.05-.85-1.45-1.9-2.7l-.3-.4q-6.75-8-20.45-10.95-5.35-1.15-11.75-1.5-3.05-.2-6.3-.2h-1.15q-.7 0-1.4.05h-.4q-1.2 0-2.4.05z"
                    id="AccHat015__Symbol_233_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M45.9 107.85q3.2 1.85 6.5 3.15 1.15.45 2.3.85 23.95 9.3 44.25 0 9.25-3.3 19.05-11.25l-.5-.7q-7.1 1.55-15.95 0-10.05-1.75-19.2-6.9-2.9-1.65-5.5-3.5-1.35 10.95-11.2 15.9-4.45 2.25-10.6 3.3-4.15.25-9.15-.85m-15.3-36.6q-1.4.3-2.7.45-4.2.5-6.6.95.35 12.7 7.4 20.3 2.7 1.05 12.1-.7 1.05-.2 2.15-.45 7.95-1.9 13.55-6.45 6.3-5.2 6.7-11.1-3.2-6.1-3.65-12.85.1-2.85.2-12.35-6.65 11.3-15.6 16.55-7.4 4.25-13.55 5.65M14 .05q-1.2 0-2.4.05-.6 2.65-2.65 9.4-.5 1.45-1.1 2.8-.25.45-.45.95-2.3 4.1-6.35 6.8l-.7.7.65.65.75.8.3-.1q3.6-1.55 6.9-4.55 1.15-1 2.1-2.1.05-.1.1-.15Q16.65 9.05 17.8 0L14 .05m41.75 13l-.3-.4Q48.7 4.65 35 1.7q1.1 2.2 1.2 4.7.1 1.8-.3 3.75-1.9 12.2-15.15 19.4Q16.2 32 10.4 33.85q4.25 7.6 7.1 16.7.45-.1 2.2-.3 4.6-.55 8.3-1.8 3.1-1.05 5.6-2.55 6.05-3.1 10.85-6.95l2-1.75q11.7-10.95 11.2-21.45-.85-1.45-1.9-2.7z"
                    id="AccHat015__Symbol_58_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat015__Symbol_146_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M35.5 80.75l.05.65.85-.15q2.85-.65 5.8-1.2-3.1.45-5.9.65-.4 0-.8.05M148.05 79l.1-.2q-2-.2-4.15-.4 2 .3 4.05.6m-23-77.75q8.55.85 15.8 2.15l.1-.45Q133 1.7 125.1 1q-.75-.1-1.45-.15-8.25-.7-16.4-.85 9.5.4 17.8 1.25z" />
                    <path d="M11.6 31.3q4.9-16.85 24.05-22.45.25-.1.5-.15l.7-.15Q55.95 3.25 77.9 1 50.75 2.95 27.65 9.35l-.7.15q-.25.05-.5.15Q7.3 15.25 2.4 32.1q-4.35 16.7-.75 33.4l.1.4Q2.8 71.85 5 77.85q.9 4.05 3.5 6.05 3.75 2.9 11 1.25 1-.15 2.05-.4.7-.15 1.4-.35h.1q6.2-1.65 12.5-3l-.05-.65q-19.8 1.2-23.1-9.25l-.05-.25q-.15-.5-.25-.95l-1.05-4.8-.1-.4-.1-.4q-3.6-16.7.75-33.4m164.8-7.1q.85 2 1.5 4.15 4.3 16.5.85 33.05-.1.4-.2.85-.5 2.15-1.95 7.1-.2.65-.4 1.4-3.85 9.95-28.05 8.05l-.05.25q.45.05.85.1l1.1.2q8.2 1.35 16.45 3.1.25.05.45.1 1.15.25 2.35.5 2.15.5 4.35 1V84q.1.05.25.1 1.2.25 2.25.05.8.75 1.55 1.55l.05.05.2.2q.1.1.2.25.05 0 .1.05.15.15.3.35l.6.65q4.25 4.8 7.4 10.35 4.25 7.6 7.1 16.7 3.25 7.3 3.8 22.1.35 12.7 7.4 20.3-.35.1.25.75 8.05 9 16.95 14.15 3.2 1.85 6.5 3.15 1.15.45 2.3.85.45.2.9.35.05 0 .1.05 6.4 2.35 12.55 3.45.2 0 .35.05l3.1.4q.5.1 1 .15.55.05 1.15.05l16.4-1.4q.15-.05.35-.1.35-.1.75-.2.15-.05.35-.1.15-.05.35-.1 3.5-1.05 6.9-2.6 1.2-.45 2.45-.95h.05q.2-.1.45-.2.15-.05.25-.1.25-.1.45-.2.1-.05.25-.1.3-.15.65-.3l13.6-8.65q.75-.6 1.55-1.25l.45-.4q.15-.15.35-.3-.85.25-1.7.45-.15 0-.25.05-4.95 3-9.8 4.7-5.75 2.65-11.8 3.8-9.15 1.7-18.9.05-6.6-1.15-13.55-3.85-1.15-.4-2.3-.85-3.3-1.3-6.5-3.15-8.9-5.15-16.95-14.15-.6-.65-.25-.75-7.05-7.6-7.4-20.3-.55-14.8-3.8-22.1-2.85-9.1-7.1-16.7-3.15-5.55-7.4-10.35l-.15-.15q-.25-.25-.45-.5-.05-.1-.1-.15l-.2-.2-.1-.1-.4-.4-.8-.8q2.7-5.35 4.45-13.9-1.2 0-2.4.05.1-.45.2-.85 3.45-16.55-.85-33.05-5.05-17.35-25.25-22.8-.25-.1-.5-.15-.95-.3-1.95-.5-9.25-2.1-18.45-3.55l-.1.45q13.8 2.45 22.75 6.6.4.25.85.5l8.95 8.35q.1.15.25.35 1.55 2.25 2.7 4.8 0 .1.05.2z" />
                    <path d="M148.05 79l.05.05.05-.25-.1.2z" />
                </g>
                <g
                    id="AccHat015__Symbol_11_0_Layer0_0_FILL"
                    fill={colors.col2d}
                >
                    <path d="M21.3 60.4q.35 12.7 7.4 20.3 2.7 1.05 12.1-.7 1.05-.2 2.15-.45-2.7-2.5-5.25-5.35-.6-.65-.25-.75Q32.1 67.7 30.6 59q-1.4.3-2.7.45-4.2.5-6.6.95m-3.8-22.1q.45-.1 2.2-.3 4.6-.55 8.3-1.8-.75-2.9-1.75-5.15-2.3-7.35-5.5-13.75-4.55 2.45-10.35 4.3 4.25 7.6 7.1 16.7M6.9 1.8q-.1.15-.25.4-2.15 3.3-5.6 5.6l-.7.7.65.65.75.8.3-.1q3.6-1.55 6.9-4.55 1.1-.95 2.05-2.05L9.5 1.7h.05l-.6-.6-.2-.2L7.9 0l-1 1.8M118 88.35l-.5-.7q-4.95 3-9.8 4.7-19.4 8.9-42.05.8-4.45 2.25-10.6 3.3-4.15.25-9.15-.85 3.2 1.85 6.5 3.15 1.15.45 2.3.85 23.95 9.3 44.25 0 9.25-3.3 19.05-11.25z" />
                    <path d="M.35 8.5l.7-.7q-.5.25-1.05.35l.35.35M117.5 87.65l.5.7q.7-.6 1.45-1.2l-1.9.5h-.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat015__Symbol_233_0_Layer0_0_FILL"
                transform="translate(95.95 36.3)"
                id="AccHat015__col1n"
            />
            <use
                xlinkHref="#AccHat015__Symbol_146_0_Layer0_0_FILL"
                transform="translate(95.95 36.4)"
                id="AccHat015__col1d"
            />
            <use
                xlinkHref="#AccHat015__Symbol_58_0_Layer0_0_FILL"
                transform="translate(272.1 100.15)"
                id="AccHat015__col2n"
            />
            <use
                xlinkHref="#AccHat015__Symbol_11_0_Layer0_0_FILL"
                transform="translate(272.1 112.4)"
                id="AccHat015__col2d"
            />
        </g></g>
    )
}

export default AccHat015
