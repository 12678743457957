import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF"
}

function ObjToo041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48.4 225.6q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-2.05.8-3.85.8l.05.2h-.05l1.05 5.5-.1.5q-.1.25-.15.35-.05.05-.15.1-2 1.95-1.65 4 .45 2.15 3 3.15.35.1.75.3 0 .1.1.1l.1.2h.05q.55.9 1.85 1.4 1.8.65 3.85.2 2.1-.5 3.25-1.9.7-.75.85-1.55.05-.05.1-.05.1-.1.05-.2v-.15q.1-.1.2-.15l.35-.35q2.1-1.9 1.65-4.05-.35-2.05-3-3.1-.15-.1-.4-.15-.15-.2-.3-.45-.127-.212-.3-.4.212.219.3.35l-2.7-14.15q-.05.55-.1 1.15-.1.75-.25 1.5l-.1.55M3.2 1.9Q1.25-1.9.45 1.9q-2.1 12.2 5.85 56.15.35 2 .75 4.15l.75 4q3.4 17.6 5.4 32.75.6 4.95 1.15 9.55.65 6.05 1.1 11.55.1 1.85.2 3.75 1 16.65-.3 28.85-.4 4.35-1.15 8.1-.6.1-1 .35-1.05.45-1.85 1.35-.35.35-.55.7-1 1.65-.95 3.5 0 .55.1 1.2.1.6.35 1.15.6 1.75 2.1 2.8.4.3.85.5 1.05.6 2.15.65.7 0 1.4-.1.6-.15 1.1-.3 3.5 6.6 4.35 13.7.25 2.75.2 5.6l-3.65 2.3q-.35.2-1.15 1.4l-.15.15q-1.05 1.55-.7 3.35.35 1.85 1.9 2.8 0 .1.15.15 1.05.65 2.2.7.5.05 1-.05 1.15-.2 1.95-.9l2.95-1.95h.15l.3-.1 3 .65q-.8 1.4-.55 2.85.35 2.3 3.4 3.45.7.25 1.5.45.15 0 .3.05l.1-.05v.15q.05.15.15.4l.35 1.7q2.95-.25 6.45.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.7 1.1 1.2 2.2l-1.95-10.15q.05-.2.05-.3-.05-.1-.05-.2v-.1h.1q.75-.5 1.45-1.1 2.5-2.2 2.05-4.5-.25-1.45-1.65-2.4l2.5-1.6.05-.1q.1 0 .25-.05.2 0 .35-.05l3.35.75q1 .35 2.15.15.45-.1.9-.35 1.1-.45 1.85-1.45 0-.1.1-.2 1.05-1.45.7-3.3-.35-1.8-1.9-2.85l-.1-.1q-1.25-.85-1.65-.85l-4.25-.8q-1.1-2.65-1.75-5.35-1.95-6.85-1.05-14.35.5 0 1.05-.1.75-.15 1.35-.4 1.15-.45 1.9-1.4.2-.35.5-.75.95-1.55 1-3.4-.05-.55-.15-1.15-.1-.65-.3-1.2-.7-1.75-2.15-2.9-.5-.25-.8-.45-1-.55-2.2-.6-.55-.05-1 .05-2.15-3.25-4.15-7.1-5.7-10.9-10.8-26.75-.6-1.8-1.1-3.6Q30.6 111.6 29 105.7q-1.25-4.45-2.45-9.3-3.7-14.8-6.95-32.45l-.75-4q-.4-2.15-.9-4.1Q9.45 13 3.2 1.9m23.7 183.45q-.25 3.45-1.5 7.55.1-3.65-.45-7.15-1.05-7.75-4.7-15.2 7.3 4.7 6.65 14.8m22.5 3q-2.6-3.4-4-6.5-4.3-9.15.7-16.2-.6 8.25 1.15 15.85.85 3.5 2.15 6.85z"
                    id="ObjToo041__Symbol_201_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M49.3 239.95q-1.75-.65-3.75-.15-2.15.45-3.35 1.85-1.2 1.3-.8 2.7 0 .05.05.1 4.55-5.25 10.1-1.8 0-.35-.1-.65-.35-1.4-2.15-2.05m1.15-5q-.15-.2-.3-.45-.127-.212-.3-.4.212.219.3.35l-.3-1.55q-5.7-1-10.3 2.3l.2 1.1-.1.5q-.1.25-.15.35 4.9-3.5 10.95-2.2m-6.2-32.9q-.6-1.1-1.85-1.75-1.6-.85-3.4-.55-.95.2-1.1.25h-.2q-2.05.4-3.2 1.8-1 1.1-1.05 2.4l.2.85.1-.05v.15q4.9-4.7 10.55-1.85-.05-.1-.05-.2v-.1h.05l-.05-.95M29.9 194.5q-2.1 1.3-3.9 3.1l3 .65h.05q.45-.85 1.3-1.65 2.4-2.05 6.15-2.8.35-.05 1.15-.2l.35-.05q3.55-.55 6.3.4 1.1.4 1.8 1.05h.1l2.5-1.6.05-.1q-1.9-.8-3.8-1.25l-1.9-.4q-6.9-1-13.15 2.85M.85 0L0 .15l21.6 114.3.15 2.75q-.05.05-.05.2 1.7 26.75-3.8 40.45v.65q-.05.1-.05.2l.15.15q13.35 12.25 10.45 31.2 0 .15.05.3.05.1.2.15.1.1.25.05l6.8-1.3q.1 0 .2-.05h.3q.05-.05.2-.1h.15l6.8-1.3q.15 0 .25-.1.15-.15.1-.25.05-.15-.05-.3-9.6-16.55-1.65-32.85.05-.1.05-.2t-.05-.2l-.2-.45q0-.1-.1-.1-10.1-10.75-18.3-36.3 0-.1-.05-.1l-1-2.9L.85 0M18.8 158.3l-.05-.15q5.2-12.95 4.05-37.35l12.8 67.55-6.15 1.2Q32.1 170.6 18.8 158.3m22.35-4.4l.05.15q-7.95 16.35 1.4 33l-6.15 1.15-12.8-67.55q7.85 23.1 17.5 33.25z"
                    id="ObjToo041__Symbol_133_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo041__Symbol_201_0_Layer0_0_FILL"
                transform="translate(76.65 77)"
                id="ObjToo041__col1n"
            />
            <use
                xlinkHref="#ObjToo041__Symbol_133_0_Layer0_0_FILL"
                transform="translate(78.05 79.1)"
                id="ObjToo041__col1d"
            />
        </g></g>
    )
}

export default ObjToo041
