import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#BFBFBF"
}

function ObjToo009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M84.65 45.15q1.35.8 3.35 2 .5.3.9.55v-.05l5.05 3q1.35.95 5.6 3.5 3.1 1.85 1.25.7 2.3 1.15 4.95 1.15 1.3 0 3.05-.15 1.8-.2 3.45-.4 1.65-.2 5.75-.85 4.05-.65 5.6-1.6 1.55-1-1.2-1.05-2.75-.05-4.5-.05-1.75-.05-4.9-.05-3.15-.05-5.15-.4-2-.35-4.1-1.25-2.15-.9-2.5-1.25Q99.3 48 96.4 46.3l-7.5-4.45.45.2q-2.15-1.35-4.5-2.75l-13.5-8.05q-.6.1-1.25.1-3.15 0-5.4-2.25-1.3-1.3-1.85-2.85l-35.25-21q-.8-.5-1.55-.95-.05-.05-.1-.05h-.05Q20 .6 13.2.1h-.45q-.3-.05-.55-.05Q2.25-.45.45 4.75q-2 5.95 4.7 10.9l.1.1q2.35 1.5 3.05 1.95 1.6 1.35 2.8 2 1.35 1 1.7 1 .2 0 .4-.05-.6-.9-1.2-3.15v-.05q.5.05.95.05H16.75q-.052-.002-.1-.05-1.1-.7-1.3-.75-.55-.25-.5-.35 0-.05.2.05l-1.75-1.15q-1.75-1.05-2.2-1.5-.75-.5-1.55-1.05-1.5-.95-2.3-2.5-.75-1.35-.75-2.75 0-1.8 2.8-2.3.35-.05.75-.1l.2.05q8.2-.4 17.25 5.85l22.65 13.5q.9-.1 2 0 4.1.35 8.05 3.35 2.95 2.3 4.5 5.1.05.1.1.25l17.6 10.5h.05v.15l2.2 1.35m14.05 8.4q-.6-.5.3.15-.15-.1-.3-.15z"
                    id="ObjToo009__Symbol_169_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo009__Symbol_169_0_Layer0_0_FILL"
                transform="translate(61.85 267.9)"
                id="ObjToo009__col1n"
            />
        </g></g>
    )
}

export default ObjToo009
