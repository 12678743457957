import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.7 4.95l-.1-.1q-.25-.25-.45-.5-.15-.1-.25-.2V4.1l-.05-.05q-.2-.15-.35-.25-.05-.1-.1-.15Q25.2-.05 19.5 0q-5.65-.05-9.7 3.55-.2-.05-.35-.1-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05H6.6q-.25.05-.45.1-2.2.45-3.85 2.1Q0 7.8 0 11t2.3 5.45q.95 1 2.15 1.55l.3.1q.85 4.25 4.1 7.5 1.35 1.35 2.9 2.3.05 0 .1.05t.15.1q.35.2.75.4.35.2.75.35l.2.1h.05q.05.05.15.1.1 0 .2.05 2.5.95 5.4.95 6.25 0 10.65-4.4T34.6 15q-.05-5.6-3.65-9.75-.15-.15-.25-.3z"
                    id="BodHan017__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.55 7.75q-.05-3.2-2.3-5.45-1.5-1.55-3.4-2H9.8Q9.6.25 9.45.2q-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05H6.6q-.25.05-.45.1-2.2.45-3.85 2.1Q0 4.55 0 7.75t2.3 5.45q.95 1 2.15 1.55l.3.1q1 .4 2.25.55l.75.05h.1l1.5-.15q2.2-.4 3.9-2.1 2.25-2.25 2.3-5.45M12.5 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-1.45 1.45-3.35 1.85l-1.3.15h-.1L7 14.4q-1.1-.1-2-.55-1.05-.5-1.95-1.4-1.95-1.95-1.9-4.7Q1.1 4.95 3.05 3q1.65-1.6 3.8-1.85h.05q.4-.1.85-.1H8.2q.5.05 1 .15 1.85.35 3.3 1.8z"
                    id="BodHan017__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill="#D9D9D9"
                    d="M128.2 328q.1.1.25.2-6.05-8.35-9.55-17.65-.05.25-.1.6-.05.4-.4.85-.25.3-.6.65-.15.05-.25.15 3.65 7.7 10.65 15.2m-3.3-17.95v.05q-.3.6-.65 1.05 3.55 8 10.75 15.7l.35.35q-6.2-8.85-10-18.45-.15.45-.45 1.3m13-6.95q.1.5.1.8v.45q-.1.95-.45 2.1 3.35 7.5 9.8 14.8.75.8 1.55 1.65-1-1.2-1.95-2.45-5.35-8.4-9.05-17.35m-5.85 5.4q-.15.35-.3.65-.25.45-.65.75 3.55 7.85 10.6 15.45.35.4.75.8-.55-.7-1.1-1.45-5.55-8.05-9.2-16.8 0 .35-.1.6z"
                    id="BodHan017__Layer1_2_FILL"
                />
                <g id="BodHan017__Layer1_0_FILL">
                    <path
                        fill="#999"
                        d="M128.4 328.3q.05.05.15.1.1.05.2.15-.15-.2-.3-.35-.15-.1-.25-.2-6.021-6.451-10.15-15.65-.1.15-.25.3-.15.05-1-3.35 0 3.9-.75 4.1l-2.6-6.55-.15-.1-1.95-.7q.35.85.55 1.3.15.3.2.45l.1.3q.2.55.45 1.15.9 2.15 2.1 4.25.1.2.25.4 4.75 8.2 13.4 14.4m-4.1-18.6l-.3-.8q-.35 2.9-1.15 3.05l-2.8-6.75-1.8-.75q.25.85.45 1.25.05.2.1.25.1.3.15.55.1.25.15.35l.1.2q.85 2.45 2.1 4.7l.7 1.2q4.7 8.15 13.25 14.35.2.15.45.3-.2-.2-.35-.4l-.35-.35q-6.918-7.399-10.7-17.15m7.45-.6v.05q-.25.45-.5-1.45-.75 2.65-1.65 2.75l-2.8-6.7v-.1l-1.9-.65q.25.6.45 1.05.2.6.3.8l.05.05q.15.45.2.55l.3.7q.8 2.2 1.9 4.2l.7 1.2q4.65 8.1 13.15 14.25.05.05.2.1.15.15.3.25-.4-.4-.75-.8-6.543-7.053-9.95-16.25m2.25-7.15q-.05.05-.1-.1l-1.85-.45q.235.528.5 1.15l.1.25q.041.143.1.25v.05l.05.1v.05q.037.024.05.05.014.037 0 .05.04.05.05.05.012.063 0 .1.601 1.446 1.35 2.85.5 1.05 1.15 2.1 4.75 8.25 13.55 14.55.05.05.15.1l-.2-.2q-.8-.846-4.4-5.55-3.548-4.714-6.95-10.9l-.1.3q-.3.85-.95 1.3l-2.5-6.1z"
                    />
                    <path
                        fill="#6A280D"
                        d="M110.15 284.8q-.05.05-.1.15-.2.1-.35.25-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3q-.5.55-.9 1.15-2.3 3.2-2.65 7.15-.05.45-.05.95v.5q0 2.8 1.1 5.85.1.3.25.65.7 1.8 1.35 3.05.5.95 1 1.6l.95 1.6q.2.3.5.7l.3.4q.25.35.6.8.8 1.15 1.5 1.85 1.1.7 2.25.85-1.2-2.1-2.1-4.25-.25-.6-.45-1.15l-.1-.3q-.05-.15-.2-.45-.2-.45-.55-1.3l1.95.7.15.1 2.6 6.55q.75-.2.75-4.1.85 3.4 1 3.35.15-.15.25-.3.207-.178.35-.35.35-.45.4-.85.05-.35.1-.6.05-.15.05-.25.05-.4.1-.4 1.1 1.4 2.25 1.85-1.25-2.25-2.1-4.7l-.1-.2q-.05-.1-.15-.35-.05-.25-.15-.55-.05-.05-.1-.25-.2-.4-.45-1.25l1.8.75 2.8 6.75q.8-.15 1.15-3.05l.3.8q.381.838.6.4v-.05q.3-.85.45-1.3 0-.15.05-.25 0-.25-.05-.25.5.1 1.3 1.2.5.7 1.45.9-1.1-2-1.9-4.2l-.3-.7q-.05-.1-.2-.55l-.05-.05q-.1-.2-.3-.8-.2-.45-.45-1.05l1.9.65v.1l2.8 6.7q.9-.1 1.65-2.75.25 1.9.5 1.45v-.05q.154-.26.3-.6.1-.25.1-.6 0-.6-.35-1.45 1.25 2.2 3.2 2.05.2 0 .4-.05-.65-1.05-1.15-2.1-.749-1.404-1.35-2.85.012-.037 0-.1-.01 0-.05-.05.014-.013 0-.05-.013-.026-.05-.05v-.05l-.05-.1v-.05q-.059-.107-.1-.25l-.1-.25q-.265-.622-.5-1.15l1.85.45q.05.15.1.1l2.5 6.1q.65-.45.95-1.3l.1-.3q.35-1.15.45-2.1v-.25-.2q0-.3-.1-.8-.1-.45-.25-1.1-.05-.25-.15-.55-.05-.2-.1-.35-.05-.1-.05-.2-.4-1.35-.7-1.65-.2-.3-.4-.8.5-.4 1-.85 2.25-2.25 2.25-5.45t-2.25-5.45q-1.2-1.2-2.6-1.75-.6-.2-1.2-.35-.3-.05-.55-.1-.25-.05-.5-.05-.3-.05-.6 0-.9 0-1.65.15-.15.05-.35.1-4.15-3.6-9.75-3.55-5.75-.05-9.9 3.65z"
                    />
                </g>
                <g id="BodHan017__Layer1_1_FILL">
                    <path
                        fill="#921818"
                        d="M112.65 309.25q-.25-.6-.45-1.15-1.5.75-2.1 1.5l.3.4q.25.35.6.8.8 1.15 1.5 1.85 1.1.7 2.25.85-1.2-2.1-2.1-4.25m6.55-2.2l-.1-.2q-.05-.1-.15-.35-1.3.4-.4 3.15-3.1-2.65-4.8-2.1l2.3 5.85q.75-.2 1.5-.6.1-.1.25-.15.35-.35.6-.65.35-.45.4-.85.05-.35.1-.6.05-.15.05-.25.05-.4.1-.4 1.1 1.4 2.25 1.85-1.25-2.25-2.1-4.7m6.5-2.15q-1.35.35-.45 3.2-3.65-2.5-4.85-2.15l2.45 6q.8-.15 1.4-.8.35-.45.65-1.05v-.05q.3-.85.45-1.3 0-.15.05-.25 0-.25-.05-.25.5.1 1.3 1.2.5.7 1.45.9-1.1-2-1.9-4.2l-.3-.7q-.05-.1-.2-.55m12.2-1.8q-.1-.45-.25-1.1-1.8-.15-3.35.65l2.2 5.4q.65-.45.95-1.3l.1-.3q.35-1.15.45-2.1v-.25-.2q0-.3-.1-.8m-6.15 6.05q.15-.3.3-.65.1-.25.1-.6 0-.6-.35-1.45 1.25 2.2 3.2 2.05.2 0 .4-.05-.65-1.05-1.15-2.1-.8-1.5-1.4-3.05-1.3.2-1.3 2.95-3.25-2.15-4.4-1.7l2.45 5.9q.9-.1 1.5-.55.4-.3.65-.75z"
                    />
                    <path
                        fill="#3E1606"
                        d="M112.2 308.1l-.1-.3q-.05-.15-.2-.45-1.75.5-2.3 1.55.2.3.5.7.6-.75 2.1-1.5m2.3-8.1l-.9.2 2.65 6.4q-1.4-.25-2.8.25l.3.7q1.7-.55 4.8 2.1-.9-2.75.4-3.15-.05-.25-.15-.55-.05-.05-.1-.25-1.05.2-1.7.65l-2.5-6.35m10.75 8.1q-.9-2.85.45-3.2l-.05-.05q-.1-.2-.3-.8-.8.1-1.3.4l-2.8-6.2-1 .25 3 6.6q-1.7-.55-3.2.1l.35.75q1.2-.35 4.85 2.15m12.25-6.65q-.05-.2-.1-.35-2-.15-3.35.75-.05.05-.05.1l.3.7q1.55-.8 3.35-.65-.05-.25-.15-.55m-4.05-16.85q-.3-.05-.55-.1-.25-.05-.5-.05-.3-.05-.6 0-.9 0-1.65.15-.15.05-.35.1-1.15.25-2.15.95-.7.4-1.3 1.05-1.4 1.4-1.9 3.2-.25.65-.3 1.4-.05.4-.05.85 0 2.8 1.8 4.9.15.2.35.4.05.05.1.15 1 1 2.2 1.55l2.05 4.3h-.05q-2.05-.35-3.75.3l.35.8q1.15-.45 4.4 1.7 0-2.75 1.3-2.95-.2-.4-.3-.8l-.3-.15q-.7.1-1.15.45l-1.5-3.25q1 .3 2.2.3.65-.05 1.25-.1.8-.15 1.55-.4.05-.05.1-.05.05-.05.1-.05l.3-.1q.6-.3 1.15-.7.5-.4 1-.85 2.25-2.25 2.25-5.45t-2.25-5.45q-1.2-1.2-2.6-1.75-.6-.2-1.2-.35m-6.4 2.8q1.45-1.45 3.3-1.8.7-.15 1.45-.15.45 0 .95.1 2.15.25 3.8 1.85 1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-.3.3-.6.55-.6.5-1.25.85-.3.1-.5.2-1.15.4-2.4.4-2.55-.05-4.4-1.65-.2-.15-.35-.35-.05-.05-.1-.05-1.9-1.95-1.85-4.65-.05-.55.05-1.05.1-.8.4-1.5.5-1.2 1.5-2.2z"
                    />
                    <path
                        fill="#A44F17"
                        d="M130.35 285.6q-1.85.35-3.3 1.8-1 1-1.5 2.2-.3.7-.4 1.5-.1.5-.05 1.05-.05 2.7 1.85 4.65.05 0 .1.05.15.2.35.35 1.85 1.6 4.4 1.65 1.25 0 2.4-.4.2-.1.5-.2.65-.35 1.25-.85.3-.25.6-.55 1.95-1.95 1.95-4.7 0-2.8-1.95-4.75-1.65-1.6-3.8-1.85-.5-.1-.95-.1-.75 0-1.45.15m-22.7 1.95q-2.3 3.2-2.65 7.15 8-5.1 19.45-4.8.5-1.8 1.9-3.2.6-.65 1.3-1.05-8.8-6.2-20 1.9z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#BodHan017__Symbol_31_0_Layer0_0_FILL"
                transform="translate(245.4 280.6)"
                id="BodHan017__col1n"
            />
            <use
                xlinkHref="#BodHan017__Symbol_37_0_Layer0_0_FILL"
                transform="translate(245.4 283.85)"
                id="BodHan017__col1d"
            />
            <use xlinkHref="#BodHan017__Layer1_0_FILL" />
            <use xlinkHref="#BodHan017__Layer1_1_FILL" />
            <use xlinkHref="#BodHan017__Layer1_2_FILL" />
        </g></g>
    )
}

export default BodHan017
