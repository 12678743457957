import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiHig039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M177.1 79.85q-4.7-4.05-14.35-11.55-14.9-13.45-27.8-28.6-22.25-26.1-27.5-33.9v-.05q-.1-.4-.3-.75v-.1q-.75-2.05-1.75-4.65-1.5-.25-3-.25-1.6 0-3.15.25-11.25 16.9-11.4 17.7-3 4-7.75 10.2-7.6 9.85-44.4 43.8-9.15 8.45-13.6 12.95-.05-1.25-8.05 7.9Q4.75 103.3.3 110.95l-.1.7q-.1.85-.2 1.75.2-.3.45-.55Q5.4 106.4 12.8 102.3l.5-.3q3.85-2.65 10.6-6.55 6.65-3.95 26.55-13l1.1-.5Q97.3 63.3 102.3 43q3.4 16.4 32.3 29.6l6.2 3.35q5.75 3 13.4 6.5 19.9 9.05 26.55 13 6.75 3.9 10.6 6.55 5.65 3.05 9.9 7.45 1.55 1.6 2.95 3.4.25.25.45.55-.1-.9-.2-1.75l-.1-.7q-1.4-2.35-3.1-4.7-5.4-7.4-15.55-18.25-6.75-7.2-8.6-8.15M96.95 6.05l.25-.3v.05l-.25.25z"
                    id="HaiHig039__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M54.35 9.35Q53.5 7.6 52.8 5.8q-1.05-3-1.9-5.8 0 .1-.05.15v.5Q50.8.55 50.8.4q-.05.1-.1.25v-.5Q50.65.1 50.65 0q-.85 2.8-1.9 5.8-.7 1.8-1.55 3.55-3.3 7-8.15 14.45-1 1.5-1.95 2.95-5.4 7.7-11.3 12.6Q18.95 45 0 53.7q45.75-18.65 50.75-38.95 3.4 16.4 32.3 29.6-4.6-2.75-7.3-5-5.9-4.9-11.3-12.6-.95-1.45-1.95-2.95-4.85-7.45-8.15-14.45z"
                    id="HaiHig039__Symbol_124_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M77.55 5.45q-3 7.3-6.8 13.45Q61.7 33.55 39.5 54.45q-8.75 8.2-19.25 16.25Q9 79.05 4.15 82.7 2 84.35 0 86q25.05-14.9 34.35-21.55 9.25-6.75 13-10.95 3.7-4.2 4.5-5.15-.3.9-.75 2.05-1.35 3.35-3.8 6.35-2.45 3-2.7 3.5Q50.55 58.2 55.3 52q4.7-6.25 12.35-19.3Q75.25 19.6 79.7 0l-.1.2h-.05v.05q-1.45 3.8-2 5.2M169.35 86q-2-1.65-4.15-3.3-4.85-3.65-16.1-12-10.5-8.05-19.25-16.25-22.2-20.9-31.25-35.55-3.8-6.15-6.8-13.45-.55-1.4-2-5.2V.2q-.1-.1-.15-.2 4.45 19.6 12.05 32.7 7.65 13.05 12.35 19.3 4.75 6.2 10.7 8.25-.25-.5-2.7-3.5t-3.8-6.35q-.45-1.15-.75-2.05.8.95 4.5 5.15 3.75 4.2 13 10.95 9.3 6.65 34.35 21.55z"
                    id="HaiHig039__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M94.9 10.75q.55-1.4 2-5.2V5.5h.05q.05-.4.25-.75v-.1Q97.95 2.6 98.95 0 94.3.75 89.6 3.65q-2.4 1.1-5.4 2.75-.95.55-1.75 1.1-3.75 2-8.8 4.8-11.1 6.2-22.25 20.65t-15.55 18.5q-4.4 4.05-8.45 7.2-4.05 3.15-9 7.5-3.8 3.3-5.9 6.3-.65.9-1.15 1.8Q9.2 78 7.75 81.7 6.3 85.35 4.1 95.1q-.55 2.35-2.35 8.05-.45.6-1.3 5.2-.25 1.1-.45 2.35 4.45-7.65 12.5-15.15 2.3-2.15 4.85-4.25 2-1.65 4.15-3.3 4.85-3.65 16.1-12 10.5-8.05 19.25-16.25 22.2-20.9 31.25-35.55 3.8-6.15 6.8-13.45m90.75 55.4q-4.95-4.35-9-7.5-4.05-3.15-8.45-7.2-4.4-4.05-15.55-18.5T130.4 12.3q-5.05-2.8-8.8-4.8-.8-.55-1.75-1.1-3-1.65-5.4-2.75-4.7-2.9-9.35-3.65 1 2.6 1.75 4.65v.1q.2.35.3.75v.05q1.45 3.8 2 5.2 3 7.3 6.8 13.45Q125 38.85 147.2 59.75q8.75 8.2 19.25 16.25 11.25 8.35 16.1 12 2.15 1.65 4.15 3.3 8.85 7.3 14.25 14.7 1.7 2.35 3.1 4.7-.2-1.25-.45-2.35-.85-4.6-1.3-5.2-.8-2.55-1.35-4.4-.7-2.35-1-3.65-2.2-9.75-3.65-13.4-1.45-3.7-3.6-7.45-2.1-3.75-7.05-8.1z"
                    id="HaiHig039__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M68.55 9.45Q73.5 4.35 80.7 0q-3.75 2-8.8 4.8Q60.8 11 49.65 25.45T34.1 43.95q-4.4 4.05-8.45 7.2-4.05 3.15-9 7.5t-7.05 8.1Q7.45 70.5 6 74.2q-1.45 3.65-3.65 13.4Q1.8 89.95 0 95.65q4.7-12.3 8.35-17.7 3.6-5.45 7.05-9.9 3.45-4.45 10.95-10.15 7.45-5.7 11-9.55 3.5-3.8 6.1-7.25Q46 37.55 54.7 26.15q8.65-11.5 13.85-16.7m126 64.75q-1.45-3.7-3.6-7.45-2.1-3.75-7.05-8.1-4.95-4.35-9-7.5-4.05-3.15-8.45-7.2-4.4-4.05-15.55-18.5T128.65 4.8Q123.6 2 119.85 0q7.2 4.35 12.15 9.45 5.2 5.2 13.85 16.7 8.7 11.4 11.25 14.95 2.6 3.45 6.1 7.25 3.55 3.85 11 9.55 7.5 5.7 10.95 10.15 3.45 4.45 7.05 9.9 3.65 5.4 8.35 17.7-1.8-5.7-2.35-8.05-2.2-9.75-3.65-13.4z"
                    id="HaiHig039__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M78.7 5.2q.55-1.4 2-5.2-12 16.9-15.95 20.4-3.95 3.45-4.7 4.2v-.35q.15-1.5.65-4.25.45-2.75 1.5-5.55-3.15 1.6-7.25 9.5-4.15 7.85-8.65 14.4-4.5 6.5-8.6 10.85-3.5 3.75-7.35 7.55-8.45 8.3-19.7 19.85Q4.35 83.05 0 86.5q.6-.4 1.15-.75 2-1.65 4.15-3.3 4.85-3.65 16.1-12Q31.9 62.4 40.65 54.2q22.2-20.9 31.25-35.55 3.8-6.15 6.8-13.45m87.65 77.25q2.15 1.65 4.15 3.3.55.35 1.15.75-4.35-3.45-10.65-9.9-11.25-11.55-19.7-19.85-3.85-3.8-7.35-7.55-4.1-4.35-8.6-10.85-4.5-6.55-8.65-14.4-4.1-7.9-7.25-9.5 1.05 2.8 1.5 5.55.5 2.75.65 4.25v.35q-.75-.75-4.7-4.2-3.95-3.5-15.95-20.4 1.45 3.8 2 5.2 3 7.3 6.8 13.45Q108.8 33.3 131 54.2q8.75 8.2 19.25 16.25 11.25 8.35 16.1 12z"
                    id="HaiHig039__Symbol_121_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig039__Symbol_138_0_Layer0_0_FILL"
                transform="translate(90.2 58.5)"
                id="HaiHig039__col1n"
            />
            <use
                xlinkHref="#HaiHig039__Symbol_124_0_Layer0_0_FILL"
                transform="translate(141.75 86.75)"
                id="HaiHig039__col1d"
            />
            <use
                xlinkHref="#HaiHig039__Symbol_123_0_Layer0_0_FILL"
                transform="translate(107.85 64.05)"
                id="HaiHig039__col1l"
            />
            <use
                xlinkHref="#HaiHig039__Symbol_120_0_Layer0_0_FILL"
                transform="translate(90.5 58.75)"
                id="HaiHig039__col2n"
            />
            <use
                xlinkHref="#HaiHig039__Symbol_122_0_Layer0_0_FILL"
                transform="translate(92.25 66.25)"
                id="HaiHig039__col2d"
            />
            <use
                xlinkHref="#HaiHig039__Symbol_121_0_Layer0_0_FILL"
                transform="translate(106.7 64.3)"
                id="HaiHig039__col2l"
            />
        </g></g>
    )
}
export default HaiHig039
