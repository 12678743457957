import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00498A",
    "col2n": "#FF0000"
}

function ShiLon010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-14.2 9.85-30.9 13.75-5.75 1.35-11.75 2-5.7 4.4-10.8 10.8-4.85-6.3-11.45-10.8-2.3-.25-4.6-.6-2.9-.45-5.75-1.05-17.6-3.8-32.45-14.1L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon010__Symbol_284_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.3 17.3q2.1-2.75 4.8-5.5Q35.85 5.9 44.35 0 38.6 1.35 32.6 2q-7.85 1.5-12.3 2.65-3.75-1.4-9.95-2.65-2.3-.25-4.6-.6Q2.85.95 0 .35q11.5 8 18.8 17.3v15.9h6.5V17.3z"
                    id="ShiLon010__Symbol_190_0_Layer0_0_FILL"
                />
                <g id="ShiLon010__Layer11_0_FILL">
                    <path d="M194.35 273.9q-.5-.5-1.2-.5t-1.25.5q-.5.55-.5 1.25t.5 1.2q.55.55 1.25.55t1.2-.55q.55-.5.55-1.2t-.55-1.25m-2.45-7.25q-.5.55-.5 1.25t.5 1.2q.55.55 1.25.55t1.2-.55q.55-.5.55-1.2t-.55-1.25q-.5-.5-1.2-.5t-1.25.5m56.7-2.4q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z" />
                    <path
                        fill="#FFF"
                        d="M200.5 249.25l-1.1.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-1.53-.114-3.05-.3 6.623 4.544 11.45 10.85 5.121-6.422 10.75-10.85-1.035.134-2.1.2-.524.06-1.05.1z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon010__Symbol_284_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon010__col1n"
            />
            <use
                xlinkHref="#ShiLon010__Symbol_190_0_Layer0_0_FILL"
                transform="translate(171.1 247)"
                id="ShiLon010__col2n"
            />
            <use xlinkHref="#ShiLon010__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon010
