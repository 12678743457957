import { AssetSvgProps } from "../../../shared/assets"

function ObjToy059({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#484848"
                    d="M111.65 276.55q-2.25.4-4.35.85-7.35 2.05-12.2 8.45-2.95 3.5-3.1 6.5-.05.3 0 .6-.25 7 5.15 8.7 2.6.85 5.6.8 3.6-.5 7-.4.35-2 2.3-3.5.25-.25.55-.45 2.85-1.95 6.55-2.05 3.95-.1 6.55 2.1 2.85 2.4 2.95 7.05v.35q0 1.35-.6 2.45v.05q4.9 3.8 7.9 9.3 2.35 4.9 7.3 5.1 5.4.5 9.5-2.35 5.05-3.4 6.1-8.3 1-2.25.35-7.35-.15-1-.35-2.05-.45-2.65-1.7-5.2-2.8-6.3-7.25-10.15-.4-.3-.75-.55-1.8-1.4-3.75-2.45-1.6-1.05-3.25-1.9l-.5-.3q-1.6-.85-3.2-1.6l-.5-.2q-5.7-2.45-11.8-3.45-.05-.05-.1-.05h-.3q-4.05-.65-8.25-.6-.2 0-.4.05-2.85.05-5.45.55z"
                    id="ObjToy059__Layer11_0_FILL"
                />
                <path
                    fill="#666"
                    d="M144.55 310.15q2.05-.45 3.4-1.55-.35-1.1-1.5-1.7-.2.05-.3.05-4.05.8-8-1.35-1.6-.85-2.85-2-.3.05-.6.05 2.15 3.3 5.25 5.45 2 1.5 4.6 1.05m-32.9-18.7q-5.5-.3-7.65-2.3 3.9 4.55 9.45 3.85 4.75-1 8.4-5.1.5-1.15-.25-1.35-.5.5-1.05 1-4.05 3.5-8.9 3.9z"
                    id="ObjToy059__Layer2_0_MEMBER_0_FILL"
                />
                <path
                    fill="#2F2F2F"
                    d="M116.7 287.4l-3.4-1.6-2.2 3.4q.85.1 1.85-.1 1.5-.2 2.95-1.15.4-.3.8-.55m-7.05-3.25l-3.1-1.45q-.4 1.1-.1 2.25.3 1.25 1 2.15l2.2-2.95m6.65-5.25q-.85-.4-1.65-.55l-2.6 3.55-3.65-1.65q-.3.25-.6.6-.6.55-.9 1.15l3.8 1.8-2.8 3.85q.4.35.8.65h.1q.6.4 1.3.65l2.75-4.15 4.4 2.1q1.05-1 1.55-2l-4.65-2.05 2.55-3.75-.4-.2m2.6 2.65l.05-.05-.05-.05q-.5-1.15-1.55-1.9l-2 3.1 3.7 1.75q.55-1.45-.15-2.85m-5.8-3.4q-.65 0-1.35.1-1.2.35-2.4 1.15-.15.15-.3.25l2.9 1.4 2.05-2.85q-.45-.05-.9-.05z"
                    id="ObjToy059__Layer2_0_MEMBER_1_FILL"
                />
                <path
                    d="M140.3 288.45q-.836.04-1.7.2-2.3.45-3.85 1.7-.2.15-.3.25-.2.2-.5.45-.6.7-1.15 1.6-1.4 2.65-.65 5.6.35-.5.9-.8 1.15-.75 2.45-.5.65.15 1.15.55.3.2.6.6.15.25.25.5.2.4-.1 1.35-.3 1.05-1.85 1.4-1.25.3-2.15-.2-1.05-.55-1.6-2.1-.1.9.5 1.75.6.8 1.55 1.1.15.05.35.1 1.2.25 2.2-.3 1-.55 1.25-1.55 0-.05.1-1 .4.65.2 1.6-.1.3-.2.55-.35.95-1.25 1.45-.7.5-1.5.5-.3.05-.55 0-.2 0-.35-.05-1.3-.25-2-1.4-.75-1.15-.45-2.45.05-.2.1-.3v-.1q-.45.5-.6 1.1-.25 1.15.6 2.15.75 1.05 2.1 1.35.4.1.85.1.3 0 .6-.05 1.25 1.15 2.85 2 3.95 2.15 8 1.35.1 0 .3-.05 2.35-.5 3.95-1.9.7-.05 1.4-.4 1.2-.7 1.45-1.95.2-.95-.2-1.8.1.6-.05 1.3-.25 1.25-1.35 2-.4.15-.75.3-.85.3-1.7.1-1.3-.3-2-1.4-.7-1.15-.45-2.4l.2-.7v.05q-.25 1.05.45 2t1.9 1.2q1.2.25 2.2-.3.35-.15.65-.5.45-.45.65-1.5.05-.45.05-.7h.05q0-.05-.05-.1.05-.3 0-.35v-.05q.1-1.55-.5-3.35-.3.3-.6.5-.8.5-1.8.3-.95-.2-1.5-1.05-.5-.8-.3-1.8.25-.95 1.05-1.5.1-.05.15-.1l.3-.1q.2-.1.3-.1-1.35-1.5-3.35-2.55-3.107-1.691-6.3-1.6m-2.65 1.9q.05.05.15.05.75.25 1.25.95.5.85.25 1.8-.2 1-1 1.5-.85.55-1.8.35-1-.2-1.5-1.05-.55-.85-.35-1.85.25-.95 1.1-1.45.047-.047.15-.1.15-.05.25-.15.55-.2 1.2-.1h.05q.05.05.15.05h.1m7.05.85q.75.25 1.25.95.55.85.3 1.8-.2 1-1.05 1.5-.85.55-1.8.35-.95-.2-1.5-1.05-.55-.8-.35-1.8.25-.95 1.1-1.5h.05l.1-.1q.15-.05.3-.15.55-.2 1.15-.1h.05q.1.05.15.05.15.05.25.05m8.05 9.15q.05 0 0 .05 0 1.9-3.1 2.3-1.85-.1-2.6-2.8.1-.15.25-.35.3-.45.85-.8 1.15-.7 2.45-.4.6.15 1.15.45.3.3.55.6.35.45.45.95m-7.8-2.35q.3.35.4.7 0 2.4-2.25 2.55-2.35.1-3.5-2.3-.1.9.45 1.7.7.95 1.9 1.25 1.2.25 2.2-.3 1.05-.6 1.4-1.85.1-.15.15-.3.2.5.05 1.2-.3 1.3-1.45 2-1.1.7-2.4.4-1.3-.25-2.05-1.4-.7-1.1-.4-2.4 0-.2.05-.4l.1.1v-.25q0-.05.05-.05.35-.8 1.15-1.4 1.15-.65 2.45-.4.65.15 1.15.55.35.2.55.6z"
                    id="ObjToy059__Layer2_0_MEMBER_2_FILL"
                />
                <path
                    fill="#666"
                    d="M151.25 292.65v.05q.5.25.85.75.45.65.25 1.45-.1.45-.35.8-.2.25-.5.45-.7.45-1.45.3-.8-.2-1.3-.9-.4-.7-.25-1.5.2-.8.85-1.2v-.05q-.05.05-.15.1-.8.55-1.05 1.5-.2 1 .3 1.8.55.85 1.5 1.05 1 .2 1.8-.3.3-.2.6-.5.35-.4.45-1 .25-.95-.3-1.8-.5-.7-1.25-1m-6.55-1.45q.5.25.8.75.5.7.3 1.5-.15.8-.9 1.2-.65.5-1.45.3-.8-.15-1.25-.9-.4-.65-.25-1.45.2-.75.75-1.15h-.05q-.85.55-1.1 1.5-.2 1 .35 1.8.55.85 1.5 1.05.95.2 1.8-.35.85-.5 1.05-1.5.25-.95-.3-1.8-.5-.7-1.25-.95m-8.95-.5v-.05q-.85.5-1.1 1.45-.2 1 .35 1.85.5.85 1.5 1.05.95.2 1.8-.35.8-.5 1-1.5.25-.95-.25-1.8-.5-.7-1.25-.95.5.25.8.75.45.7.25 1.5-.15.8-.85 1.2-.65.5-1.45.3-.8-.15-1.25-.9-.4-.65-.25-1.45.15-.7.7-1.1z"
                    id="ObjToy059__Layer2_0_MEMBER_3_FILL"
                />
                <path
                    fill="#2F2F2F"
                    d="M141.5 278.2q-.703 1.408-.45 1.6.246.192 1.3-.95 1.058-1.146 1.25-1.4.062-.083.1-.2.137-.11.25-.25 1.413-1.674 3.3-2.45h.05q2.592-1.095 7.2-1.1 3.6.004 7.25 2.25v.05q2.768 1.706 4.65 4.2 1.119 1.518 1.9 3.3 1.964 4.295 5.25 12.9.012.049 0 .05 3.675 7.9 10.4 14.3 1.967 1.841 7.05 4.85 5.895 3.416 9.55 4.3 3.09.787 9.45 1.85 6.19 1.037 8.9 1.65 10.11 2.47 14.7 7.3 4.647 4.939 4.65 13.55.002 3.19-1 8-.997 5.037-1 8.7-.005 7.22 5.3 12.4 3.885 3.691 8.4 4.8.46.22 1.1.45 2.34.826 7.65 1.8 6.89 1.994 11.85 6.95 2.134 2.102 5.55 7.2 3.84 5.758 5.95 10.45 1.171 2.605 6.85 14.4 4.507 9.342 6.5 14.55.114.306.4.4.285.157.55.05.306-.114.4-.4.156-.285.05-.6-2.007-5.242-6.55-14.65-5.671-11.755-6.85-14.35-2.141-4.808-6.05-10.7-3.534-5.253-5.75-7.4-5.262-5.266-12.55-7.4-.039.01-.1 0-5.14-.926-7.4-1.7-.67-.237-1.1-.45-.062-.005-.15-.05-4.135-1.041-7.7-4.45-4.845-4.72-4.85-11.3-.003-3.537.95-8.4 1.048-4.99 1.05-8.3.003-9.289-5.05-14.6-4.81-5.12-15.45-7.7-2.74-.637-9-1.7-6.29-1.037-9.35-1.8-3.495-.867-9.15-4.15-4.867-2.891-6.75-4.65-6.503-6.18-10.05-13.8-.013 0-.05-.05-3.27-8.608-5.25-12.9-1.249-2.838-3.25-5-1.671-1.825-3.85-3.2-4.05-2.504-8.05-2.5-4.993-.005-7.8 1.2-2.456 1-4.25 3.25-.007.093-.05.15-.192.245-.9 1.65z"
                    id="ObjToy059__Layer2_1_FILL"
                />
                <g id="ObjToy059__Layer2_0_FILL">
                    <path
                        fill="#2F2F2F"
                        d="M159.2 304.45q-.15-1-.35-2.05.15 1.1.2 2.2-1.15 11.6-10.6 14.4-9.45 3.15-13.6-6.4-.05-.1-.15-.25-2.5-4.2-6.05-7.15v.35q0 1.35-.6 2.45v.05q4.9 3.8 7.9 9.3 2.35 4.9 7.3 5.1 5.4.5 9.5-2.35 5.05-3.4 6.1-8.3 1-2.25.35-7.35m-23.85-16.5q-.7.4-1.3.9v-.05q-4.2 3.7-3.3 9.75v.05q0 1.6.45 3.25 1.2 4.85 5.45 7.4 2.85 1.75 6 1.75h.25q4.25 0 7.55-2.3.15-.1.3-.25.1-.05.15-.05 1.5-1.2 2.5-3.1 2.25-4.05 1.15-7.65-1.15-3.55-2.05-5.1-.05-.1-.15-.2-4.45-5.05-10.4-5.65-2.598-.169-4.7.45-1.005.312-1.9.8m2 2.35h.05q.05.05.15.05h.1q.05.05.15.05.75.25 1.25.95.5.85.25 1.8-.2 1-1 1.5-.85.55-1.8.35-1-.2-1.5-1.05-.55-.85-.35-1.85.25-.95 1.1-1.45.047-.047.15-.1.15-.05.25-.15.55-.2 1.2-.1m-2.6.05q-.2.15-.3.25-.2.2-.5.45-.6.7-1.15 1.6-1.4 2.65-.65 5.6-.15.2-.25.5l-.05.05v.1q0 .05-.05.15h-.05q.002-.05.05-.05v-.05q-1-4.85 2.1-7.95.3-.2.55-.45.1-.15.3-.2m9.55.75q.1.05.15.05.15.05.25.05.75.25 1.25.95.55.85.3 1.8-.2 1-1.05 1.5-.85.55-1.8.35-.95-.2-1.5-1.05-.55-.8-.35-1.8.25-.95 1.1-1.5h.05l.1-.1q.15-.05.3-.15.55-.2 1.15-.1h.05m5.35 1.6q.2-.1.3-.1.45-.1.85-.05.05 0 .1.05h.1q.05 0 .05.05h.2q.75.3 1.25 1 .55.85.3 1.8-.1.6-.45 1-.3.3-.6.5-.8.5-1.8.3-.95-.2-1.5-1.05-.5-.8-.3-1.8.25-.95 1.05-1.5.1-.05.15-.1l.3-.1m-48.5-9.85q-2.65 2.65-1.4 6.35 1.6 5.25 10.35 5.5 6.7-1.1 10.15-4.45v.05q1.8-1.8 3.15-3.25 1.75-1.9 2-3.75.057-.571 0-1.15-.043-.393-.15-.8-.75-2.8-4.35-4.05-1.75-.6-3.65-.6-2.7-.05-5.15.35-4.25.7-7.75 3-.5.35-1 .75-1.3 1-2.2 2.05m11.3-5.5q1.75-.15 3.7-.05 4.25.15 6.6 3.3.75.85.8 1.8.05 1.8-1.95 4.15-.5.5-1.05 1-4.05 3.5-8.9 3.9-5.5-.3-7.65-2.3-.5-.55-.8-1.15-.05-.1-.15-.25-1.4-3.55 1.4-6.6.1-.05.1-.1 1.9-1.75 3.85-2.6 1.9-.8 4.05-1.1m-20.2 17.25q-.25-.7-.25-1.65-.25 7 5.15 8.7 2.6.85 5.6.8 3.6-.5 7-.4.35-2 2.3-3.5-4.35-.35-8.75.6-7 1.8-9.95-2.35l-.35-.5q-.15-.25-.25-.5-.2-.35-.35-.7-.1-.3-.15-.5m-.25-1.65v-.45-.15q-.05.3 0 .6z"
                    />
                    <path
                        fill="#484848"
                        d="M150.95 292.6q.052.002.1.05 0-.05-.05-.05h-.05m.3.1v-.05h-.15q.103.003.15.05m-1.9.15l.3-.15-.3.1v.05m-13.45-2.3q-.103.053-.15.1v.05q.047-.094.15-.15m5.75-8.7q-1.6-.85-3.2-1.6l-.5-.2q-5.7-2.45-11.8-3.45l.05.8q1.95.449 3.8 1 .042.012.05 0 3.83 1.154 7.25 2.7 4.567 2.076 8.45 4.9l3.4.5q-1.8-1.4-3.75-2.45-1.6-1.05-3.25-1.9l-.5-.3z"
                    />
                    <path
                        fill="#999"
                        d="M149.95 292.6q-.1 0-.3.1l-.3.15v-.05q-.05.05-.15.1-.8.55-1.05 1.5-.2 1 .3 1.8.55.85 1.5 1.05 1 .2 1.8-.3.3-.2.6-.5.35-.4.45-1 .25-.95-.3-1.8-.5-.7-1.25-1v.05q-.047-.047-.15-.05h-.05q-.048-.048-.1-.05h-.05q-.05-.05-.1-.05-.4-.05-.85.05m-5.5-1.45q-.05 0-.15-.05h-.05q-.6-.1-1.15.1-.15.1-.3.15l-.1.1h-.05q-.85.55-1.1 1.5-.2 1 .35 1.8.55.85 1.5 1.05.95.2 1.8-.35.85-.5 1.05-1.5.25-.95-.3-1.8-.5-.7-1.25-.95-.1 0-.25-.05m-7.05-.85h-.05q-.65-.1-1.2.1-.1.1-.25.15-.103.056-.15.15v-.05q-.85.5-1.1 1.45-.2 1 .35 1.85.5.85 1.5 1.05.95.2 1.8-.35.8-.5 1-1.5.25-.95-.25-1.8-.5-.7-1.25-.95-.1 0-.15-.05h-.1q-.1 0-.15-.05z"
                    />
                    <path d="M131.85 298.9v-.1q-.05.1-.05.15v.05q-.048 0-.05.05h.05q.05-.1.05-.15m-1.8-20.5q-.008.012-.05 0-1.85-.551-3.8-1l-.05-.8q-.05-.05-.1-.05h-.3l.15 1.05.3.1q1.812.466 3.55 1 3.74 1.215 7.25 2.85 4.47 2.065 8.6 4.85l4.3.65q-.4-.3-.75-.55l-3.4-.5q-3.883-2.824-8.45-4.9-3.42-1.546-7.25-2.7m8.4 1.85q1.6.75 3.2 1.6l.5.3q.45-.5 1.45-1.95 1.273-1.864.8-2.5l-.7-.45q-.038.117-.1.2-.19.252-.5.3-.304.04-.55-.15-.252-.19-.3-.5-.04-.303.15-.55l-.65-.35q-1.126.209-2.1 1.25-.6.7-1.15 1.45-.5.75-.55 1.15l.5.2m-22.3-2.95q-1.95-.1-3.7.05-2.15.3-4.05 1.1-1.95.85-3.85 2.6 0 .05-.1.1-2.8 3.05-1.4 6.6.1.15.15.25.3.6.8 1.15 2.15 2 7.65 2.3 4.85-.4 8.9-3.9.55-.5 1.05-1 2-2.35 1.95-4.15-.05-.95-.8-1.8-2.35-3.15-6.6-3.3z" />
                    <path
                        fill="#666"
                        d="M134.45 290.6q.1-.1.3-.25-.2.05-.3.2-.25.25-.55.45-3.1 3.1-2.1 7.95 0-.05.05-.15l.05-.05q.1-.3.25-.5-.75-2.95.65-5.6.55-.9 1.15-1.6.3-.25.5-.45m-5.65-10.55l-1.9 2.75 7.1 3 1.95-2.8-7.15-2.95z"
                    />
                    <path
                        fill="red"
                        d="M125.3 288.75l3.8 1.65q1.25.35 1.6-.5.4-1-.4-1.35l-3.4-1.55q-1.3-.35-1.8.5-.45.9.2 1.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy059__Layer11_0_FILL" />
            <use xlinkHref="#ObjToy059__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy059__Layer2_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy059__Layer2_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy059__Layer2_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy059__Layer2_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjToy059__Layer2_1_FILL" />
        </g></g>
    )
}

export default ObjToy059
