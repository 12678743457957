import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho063({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M181.8 60.85q3.4 3.05 6.15 8.8-.6-2.1-1.3-4-7.4-19.75-30.1-39 6.4 2.4 8.5 4.4-7.7-10.75-24.85-17.6-35.35-18.3-64.25-2 .95-2.05 5.6-5.25Q86.1 3.05 97.2 0 71.45 1.127 65.65 14.65q.113-1.345-2.9-3.65-3.901-3-12.15-4.65 6 3.95 6.9 6.3-5.499-1.704-9.4-1.6-3.855.112-7.9 2.45 6.85-1.25 10.75-.35.8.2 1.5.5 1.2.4 2.7 1.65-18.1-1.1-30 12-6.05 6.25-11.45 17.4 4.95-7.85 11.25-10.8 1.75-.8 2.25-.8l-4.3 4.2Q9.55 49.95 2.8 69.25q-.7 2.15-1.3 4.45 3.95-4.25 5.15-5.55-4.8 9.7-6.25 19.9Q0 91.697-.1 98.7q-.094 7.061.95 16.8 1.083 9.778 1.95 10.2 2.176-4.27 3.05-6.65L7 123.2q2.1 7.8 8.55 13.25-1.85-5.3-2.35-10.55-.65-5.4.95-15.45 1.15-7.15 2.15-13.15.05 8.1 4.65 14.35-3.1-11.7.25-24.25.15-.25.25-.45-.4 2.6 0 6.9.4 4.3 2.1 6.65 1.9-12 11.35-21.8-2.75 3.05-1.7 7.95 4.1-8.85 10.4-13.45-1.1 2.55-.4 5.15.65-3.35 7.7-10.9.45-.45.9-.95.25-.35.6-.65 4.4-5.5 6.55-16.3.05 10.5 1.95 16.3 1.95 5.75 6.7 9-4.35-3.5-1.35-13.7Q67.75 75.4 79.3 82.1q-.6-2.2-1.1-5.35-1.65-4.7-1.5-7.35.2-2.65.65-6.55 2.2 12.05 7.15 18.2.65 1 1.55 2.05.35.35.75.8.75.7 1.6 1.4 2.2 1.85 3.75 3.15-4.75-9.2-3.35-15.3 1.1 5.15 4 10.3.8 1.65 2.25 3.8 1.25 2.05 4.65 4.55l-2.85-7.6.1.05-1.8-7.7q2.9 7.7 9.35 13.25-2.75-7.4-3.6-21.15 4.65 12.85 10.95 18.1-3.75-6-4.35-12.5 3.7 9.45 12.05 14-4.65-7.95-4.35-12.55 3 7 9.15 12.05 5.7 4.6 9.05 5.75-3.1-3.35-7.25-9.5 7.75 7.2 13 8.55-6.9-3.45-15.5-25 1.45 2.45 8.75 12.4 5.15 7.05 14.05 17.6-8.05-11.65-7.55-16.9 2.95 6.85 17.2 20.45-3.75-6.65-4.85-8.55-2.4-4.4-4.15-9.6-2.6-7.35-2.9-8.45-.45-1.35-.7-2.7 6.6 19.5 23 32.9-6.25-11.9-6.1-20.1 3.35 10.9 12.55 25.25l4.5 9.35h.05q-3.55-11.15-.5-22.35-.6 10.95 4.2 22.35-1.1-6.2 1.9-11.45.05 1.85.15 2.4l.05.6q.05.95.1 2 .05 1.55.05 5.9-.05 4.45-1.4 9.65-1.45 5.25-2.45 6.15 5.2-2 9.4-14.1.85 3.05 2.45 8.55.95-12.25 1-20.15.1-1.95.25-3.95-.15-26-10.9-43.95z"
                    id="HaiSho063__Symbol_184_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M133.9 33.35q-2.4-2.95-9.4-8.8-7-5.9-14.4-9.2-4.35-1.95-7.05-2.8h.2Q95.6 9.95 93.4 9.7q-2.2-.2 3.5-.85 5.7-.75 10.1-.45 3.85.2 10 1.55-5-2.05-9.65-2.7Q98.2 5.2 88.85 5.2q-4.9 0-8.1.5-1.1.1-4.6.8-2.209.473-3.05.6Q78.136 3.317 90.2.15q.046-.046.15-.05h-.1l.5-.1q-.8.1-1.35.15Q60 1.95 48.95 14.6q-.65-1.6-1.2-4.55-.5-2.2-.5-3.55v-.15q-.097.209-.75 1.45-.606 1.281-.9 4.35l-.2 1.65q-.05 1.563-.05 2.7-1.35-1.4-3.85-3.7-1.9-1.8-3.85-3v.25q1.063 3.577 1.8 6.4L35.8 15.2q-5.06-1.784-12.9-.25-4.116.646-10.8 3.8h.45q7.15-2.4 12-1.55 4.8.8 7.8 1.55-9.8 2.7-17.4 7.9-7.6 5.2-14.95 19.1 8.75-9.45 13.85-13.8 5.05-4.45 14.65-6.9l-.8.7q-1.75 1.45-5.15 5-3.45 3.6-5.1 6.75-1.8 3.15-3.5 6.6-1.8 3.4-4.2 14.1 1.9-6.65 4.8-11.55 2.8-4.95 14.5-16.3.55 5.35-7.4 23.05l4.15-6.7q5.35-9.9 9.05-15.5 3.7-5.6 4.15-6.7-.25 3.95 1.15 9.7 1.5 5.8 1.6 7.65.2-.55.45-1.7.25-1.2 1.95-6.35 1.05-3.15 4.5-10.3.45 17.8 2.15 21.15.5-4.5 2.7-10.15 2.3-6.2 5.1-13.4 1.35 7.2 2.7 13.05Q62.55 40 65.9 47.3q-.1-2.1-.2-8.9-.2-6.85 4.55-21.85-.25 11.8 3.25 20.4 1.4 3.4 3.85 11.85-3-14.85.1-32.15Q80.25 35.3 87.5 47.2q2.15 3.55 5.1 9.45-2.85-6.9-4.05-11.25-2.6-9.2-5.1-23.7 3.6 7.65 7.35 13.7 2.8 4.5 8.35 14.35-3.8-15.05-7.9-25.45 12.45 11.55 20.1 26.05 2.7 5.2 4.05 8.25-1.9-15.9-10.45-30.05 5.4 1.95 13.35 9.65 7.85 7.65 11.75 12.85 3.9 5.15 7.55 11.3-1-3.6-5.45-11.05-4.5-7.4-7.7-12.35-3.2-5-15.75-15.95v-.75q13.15 4.95 18.05 8.5 4.85 3.5 6.35 5.25 1.45 1.7 9.4 10.25-6.2-10-8.6-12.9m-26.6-4.2q-.2-.35-.25-.65l.05.05q0 .15.2.6z"
                    id="HaiSho063__Symbol_45_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho063__Symbol_184_0_Layer0_0_FILL"
                transform="translate(95.85 48.2)"
                id="HaiSho063__col1n"
            />
            <use
                xlinkHref="#HaiSho063__Symbol_45_0_Layer0_0_FILL"
                transform="translate(118.75 60.2)"
                id="HaiSho063__col1l"
            />
        </g></g>
    )
}

export default HaiSho063
