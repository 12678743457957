import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}
function FacNos005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.9 13.95q-.05-5.8-4.15-9.9Q19.7 0 13.95 0q-5.8 0-9.9 4.05Q0 8.15 0 13.95q0 5.8 4.05 9.85 4.1 4.1 9.9 4.1 5.75 0 9.8-4.1 4.1-4.05 4.15-9.85z"
                    id="FacNos005__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4 0Q2.3 0 1.1 1.15-.05 2.35 0 4q-.05 1.65 1.1 2.8Q2.3 8 4 8q1.65 0 2.8-1.2Q8 5.65 8 4T6.8 1.15Q5.65 0 4 0z"
                    id="FacNos005__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos005__Symbol_14_0_Layer0_0_FILL"
                transform="translate(178.55 190.3)"
                id="FacNos005__col1n"
            />
            <use
                xlinkHref="#FacNos005__Symbol_25_0_Layer0_0_FILL"
                transform="translate(195.8 197.1)"
                id="FacNos005__col2n"
            />
        </g></g>
    )
}
export default FacNos005
