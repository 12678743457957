import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FF008C"
}
function HaiHig022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M18.9 9.95Q18.2 16 17.15 30.6q-1.1 14.55-7.7 31.3-6.6 16.7-8.35 23.5-1.75 6.75-.75 14.5t5.25 17.25q.2.2.35.4 1.6 2.1 3.35 4.1 2.7 3.05 5.85 6 .9.75 1.7 1.65 1.3 1.1 2.65 2.2 1.5 1.15 3 2.35-2.25-2.05-4.35-7.3-.7-1.85-1.35-4.15-2.5-8.8-2-17.7.55-8.9 10.8-27.95.5-.9 1-1.85 5.9-12.2 6-24.05.05-5.8-1.25-11.5-3-21.2-11.1-37.9l-.3-.7Q19.85.4 19.7 0q-.05.2-.1.35 0 3.5-.7 9.6z"
                    id="HaiHig022__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9.85 0Q9.2 2.1 11.5 16.05q2.35 13.9 3.55 27.45 1.2 13.55-6.95 29.55-4.45 8.85-5.55 11.7Q0 91.25 0 97.45q0 15.3 1.95 20.55.95 2.55 3.25 6.25 2.35 3.55 4.2 6.5 1.5 1.15 3 2.35-2.25-2.05-4.35-7.3-.7-1.85-1.35-4.15-2.5-8.8-2-17.7.55-8.9 10.8-27.95.5-.9 1-1.85 5.9-12.2 6-24.05.05-5.8-1.25-11.5-3-21.2-11.1-37.9l-.3-.7z"
                    id="HaiHig022__Symbol_49_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.45 30.6q.3-14.95 1-21 .7-6.1.7-9.6-4.75 17-8.45 32.4-3.65 15.5-6.5 22.35Q6.4 61.6 4.9 65q-9.75 21.8 0 43.65 2.05 3.9 5.25 8.15-4.25-9.5-4.25-17.05 0-7.95.95-14.6 1-6.65 8.15-23.1 7.15-16.5 7.45-31.45z"
                    id="HaiHig022__Symbol_48_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig022__Symbol_47_0_Layer0_0_FILL"
                transform="translate(78.5 134.9)"
                id="HaiHig022__col1n"
            />
            <use
                xlinkHref="#HaiHig022__Symbol_49_0_Layer0_0_FILL"
                transform="translate(88.6 135.65)"
                id="HaiHig022__col1d"
            />
            <use
                xlinkHref="#HaiHig022__Symbol_48_0_Layer0_0_FILL"
                transform="translate(73.95 135.25)"
                id="HaiHig022__col2n"
            />
        </g></g>
    )
}
export default HaiHig022
