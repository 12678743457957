import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacNos001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M14.85-.85q-.25-.2-.5-.15-.3.05-.45.3-.5.65-1.1 1.25-2.2 2-5.25 2t-5.2-2Q1.7-.05 1.3-.65 1.15-.9.85-.95q-.3-.1-.5.05-.25.15-.3.45-.1.3.05.55.5.75 1.3 1.5v-.05q2.5 2.4 6.15 2.4 3.65 0 6.2-2.4v.05q.75-.75 1.3-1.5.15-.25.1-.5-.05-.3-.3-.45z"
                    id="FacNos001__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos001__Symbol_10_0_Layer0_0_FILL"
                transform="translate(184.95 203.65)"
                id="FacNos001__col1n"
            />
        </g></g>
    )
}

export default FacNos001
