import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#4EB8FE"
}

function FacEye007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M105.35 24.4q-1.2-4.7-3.8-8.85-1.7-2.75-4.05-5.3-.15-.2-.3-.35Q88.85.9 77.85.1 66.8-.75 59.55 7q-5.35 5.75-6.3 13.7-1-7.95-6.35-13.7Q39.65-.75 28.65.1 17.6.9 9.25 9.9q-.3.3-.55.65l-.3.3q-5 5.65-7 12.4Q.35 26.8.1 30.65q-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9 3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.2-3.2-1-6.2z"
                    id="FacEye007__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.55 1.55Q0 3.1 0 5.25q0 2.15 1.55 3.7 1.6 1.55 3.85 1.6 2.15-.05 3.75-1.6 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7Q7.55-.05 5.4 0 3.15-.05 1.55 1.55m3.75.35q1.4-.05 2.35.9.3.3.5.65.5.75.5 1.7 0 1.4-1 2.35-.95 1-2.35 1.05-1.4-.05-2.4-1-.05 0-.05-.05l-.45-.45q-.55-.85-.55-1.9 0-1.35 1-2.35 1-.95 2.45-.9m37.35 3.4q0-2.15-1.6-3.7Q39.45 0 37.3.05 35.05 0 33.45 1.6 31.9 3.15 31.9 5.3q0 2.15 1.55 3.7 1.6 1.55 3.85 1.6 2.15-.05 3.75-1.6t1.6-3.7m-5.3-3.35q1.3 0 2.2.9 1 1 1 2.35 0 1.4-1 2.35-.9.95-2.2 1.05-.1-.05-.15 0-1.45-.05-2.45-1.05-1-.95-1-2.35 0-1.35 1-2.35 1-.95 2.45-.9.05-.05.15 0z"
                    id="FacEye007__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    d="M211.75 166.7q0-1.35-1-2.35-.9-.9-2.2-.9-.1-.05-.15 0-1.45-.05-2.45.9-1 1-1 2.35 0 1.4 1 2.35 1 1 2.45 1.05.05-.05.15 0 1.3-.1 2.2-1.05 1-.95 1-2.35m-32.4-1.75q-.2-.35-.5-.65-.95-.95-2.35-.9-1.45-.05-2.45.9-1 1-1 2.35 0 1.05.55 1.9l.45.45q0 .05.05.05 1 .95 2.4 1 1.4-.05 2.35-1.05 1-.95 1-2.35 0-.95-.5-1.7z"
                    id="FacEye007__Layer6_0_FILL"
                />
            </defs>
            <g id="FacEye007__col1n" transform="translate(139.3 136.2)">
                <use
                    xlinkHref="#FacEye007__Symbol_1_0_Layer0_0_FILL"
                    id="FacEye007__col1n_FL"
                />
            </g>
            <g id="FacEye007__col2n" transform="translate(171.2 161.5)">
                <use
                    xlinkHref="#FacEye007__Symbol_10_0_Layer0_0_FILL"
                    id="FacEye007__col2n_FL"
                />
            </g>
            <use xlinkHref="#FacEye007__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye007
