import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor082({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFD900"
                    d="M219.25 273.25h16.7q.5-1.399.5-3t-.5-3h-16.7q-.5 1.399-.5 3t.5 3z"
                    id="AccMor082__Layer10_1_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor082__Symbol_182_0_Layer0_0_FILL"
                />
                <g id="AccMor082__Layer10_0_FILL">
                    <path
                        fill="#31A8EC"
                        d="M219.25 273.25q.619 1.79 2.05 3.25 2.7 2.6 6.3 2.6 3.65 0 6.25-2.6 1.46-1.46 2.1-3.25-8.16-2.285-16.7 0z"
                    />
                    <path
                        fill="#F50F80"
                        d="M233.85 264q-2.6-2.6-6.25-2.6-3.6 0-6.3 2.6-1.431 1.46-2.05 3.25 8.448 2.342 16.7 0-.64-1.79-2.1-3.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccMor082__Layer10_0_FILL" />
            <use xlinkHref="#AccMor082__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor082__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor082__col1l"
            />
        </g></g>
    )
}

export default AccMor082
