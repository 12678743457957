import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMak002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.8 4.9q9.8 4.5 14.45 15.25-.25-2.2-1.15-4.5Q57.75 4.25 46.6.7q-8.25-2.2-18.4 2.6-5.65 2.9-10.8 7.85-.4.35-.75.8l-.6.65q-.25.25-.5.55-.2.2-.35.4-3.9 4.35-8.35 4.55H6.5q-1.3-.05-2.7-.35l.1.15q2.7 4.05 7.15 3.15-3.3 3.75-9.2 1.65 2.55 4 7.05 3.45Q6.95 29 0 27.65q3.6 4.75 9 3.25.3-.1.65-.2l.3-1.2q1.55-4.8 3.95-8.8Q22.05 7.55 36.75 3.8q5.9-1 11.05 1.1m60.75 7.05q-.35-.45-.75-.8Q102.65 6.2 97 3.3 86.85-1.5 78.6.7 67.45 4.25 64.1 15.65q-.9 2.3-1.15 4.5Q67.6 9.4 77.4 4.9q5.15-2.1 11.05-1.1 14.7 3.75 22.85 16.9 2.4 4 3.95 8.8l.3 1.2q.35.1.65.2 5.4 1.5 9-3.25-6.95 1.35-8.9-1.5 4.5.55 7.05-3.45-5.9 2.1-9.2-1.65 4.45.9 7.15-3.15l.1-.15q-1.4.3-2.7.35h-.35q-4.45-.2-8.35-4.55-.15-.2-.35-.4-.25-.3-.5-.55l-.6-.65z"
                    id="SkiMak002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak002__Symbol_2_0_Layer0_0_FILL"
                transform="translate(129.9 134.7)"
                id="SkiMak002__col1n"
            />
        </g></g>
    )
}

export default SkiMak002
