import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#200F00",
    "col1n": "#411F00",
    "col2n": "#644D27"
}

function PetPet002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M222.3 48.5q-.15-1.5-.45-2.85-.6-3-1.25-5.95-.6-2.35-1.5-4.5.05-.1.15-.2l13.8-22.55-37.7 3.9q-.55-.1-1.05-.2-6.65-1.4-13.4-1.25-2.65.05-5.3.4-5.7.5-11.25 2.9-2.95 1.25-4.05 1.85l-.05-.05-13.4-9-14.2-4.15 10.1 32.85q-.15.1-1.05 2.75-1.35 3.95-1.35 8.2 0 2.4.35 4.65 0 .2.05.4v.05q-7.15 2.2-7.2 2.25-.05-.05-1.85.6-1.8.6-7.5 1.7-5.75 1.05-15.7.8-10-.3-21.25-1.1-11.25-.85-17.5.35-5.95 1.15-7.25 1.9-.1 0-2.35.85-2.7 1.05-5.15 2.7-16.75-6.15-28.95-28.65-.6-1-1.1-2.05-.6-1.25-1.1-2.55-2.35-6-5-11.8-.8-1.8-1.65-3.55-1.35-2.8-2.4-5.8Q11.85 2.8 3.25.05 3.05.1 2.9.1 2.7 0 2.55 0 .35 0 .3 2.25q-.35 2.5-.3 5 .5 11.2 6.25 20.6.85 1.3 1.55 2.7 1.7 3.35 3.8 6.5 1.25 2.15 3.95 5.5 1.5 1.8 2.8 3.45.25.35.5.75 1.9 2.85 4.2 5.45.1.15.2.35l-1.2-1.4 1.6 2q1.7 2.25 3.3 4.3 8.9 11.4 12.4 15.55 2.9 3.3 5.5 5.75l.05.05v.05q-3.9 9.3-4.55 19.7-.75 11.55 2.5 22.3.6 1.9.75 3.75.35 4.8-.35 9.6-1.05 7.45-2.15 14.8-.25 1.95 1.6 2.9 3.65 1.8 7.7 2.3 3.25.15 4.7.15.8 0 1.9.05.7 0 1.6.1 2.05.1 7.25.15.85 0 1.55-.65 2.4-2.25-.8-3.75-1.85-.9-3.8-1.45.1-2 .1-2.05-.05-.05 0-.5 0-.5.2-2.3.35-4.85 2.3-9.2 2.4-5.5 5.55-10.65.8-1.4 1.4-2.85.95-2.55 1.8-4.5 0-.05.05-.15.6.25 3.4.95 36 7.5 67.5 0l-.05 2.8v3.25q-.05.7 0 1.45 0 4.65.1 9.35-.75 6-1.6 12-.9 5.9 4.4 7.6 4.15.85 6.7 1 1.3.05 2 .1h16.85q3-.55 1.4-3.4-.7-1.15-2.7-1.95-1.6-.7-3.05-1.1-.45-.15-.95-.25h-.05q-1.4-2.3-1.9-6.2-.45-4-.75-10.55-.35-6.6.5-12.4.4-2.9.8-4.25 3.4-13.2.85-17.9.35-1.3.8-2.35.1-.3.25-.6l1.3-2.1.1-.1q1.4-1.6 2.1-2.2.65-.65.7-.9.05-.05.15-.1l.05-.05q.95.75 2 1.4 3.5 2.1 7.75 2.65 6.75.85 11.4-2.6 1.25-.9 2.35-2.15.15-.2.3-.35-.1-.1-.1-.15 1.55-1.65 3.05-3.1 7.9-7.7 15.65-11.05 1-1.6 1.9-3.3 1.4-2.65 2.6-5.4 3.1-7.65 2.3-16.15z"
                    id="PetPet002__Symbol_3_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.95 107.9q-2.4-1.3-3.7-2.05.15 4.8-1.75 9.35-1 2.55-4.15 7.75-5.1 8.4-5.35 15.55.05.35.25 2.4.1 2.1.35 5.95.7 0 1.6.1 2.05.1 7.25.15.85 0 1.55-.65 2.4-2.25-.8-3.75-1.85-.9-3.8-1.45.1-2 .1-2.05-.05-.05 0-.5 0-.5.2-2.3.35-4.85 2.3-9.2 2.4-5.5 5.55-10.65.8-1.4 1.4-2.85.95-2.55 1.85-4.65-2.2-.75-2.85-1.15m96.3-18.35q.35-1.3.8-2.35-.55-1.5-.8-2.3v.2q-.15 6.15-.8 9.65-.85 4.55-3.4 10.75-1.55 3.8-2 10.2-.2 2.9-.2 10 0 2.45-.25 10.05.25 4.2 1.65 5.7-.35.75-.75 1.35-.45.65-.45 2.7 0 1.15.35 2.05h11.7q3-.55 1.4-3.4-.7-1.15-2.7-1.95-1.6-.7-3.05-1.1-.45-.15-.95-.25h-.05q-1.4-2.3-1.9-6.2-.45-4-.75-10.55-.35-6.6.5-12.4.4-2.9.8-4.25 3.4-13.2.85-17.9m5.45-8.4q.95.75 2 1.4 2.15-1.05 3.05-2 .85-.9 1.7-3.05 1.45-3.7 1.6-3.95 1-1.55 3.1-1.55 1.9 0 2.95 1.6 1.05 1.6 2 4.2.9 2.55 2.3 3.45 1.4.85 2.45 1.35 1.25-.9 2.35-2.15.15-.2.3-.35-.1-.1-.1-.15 1.55-1.65 3.05-3.1-.05-2.15-.75-4.6-.7-2.55-1.75-4.35-1.25-2.15-1.8-2.85-.95-1.15-2.8-2.45-.6-.45-3.7-1.75-3.6-1.45-5.1-1.45-5.95 0-9.1 6.3-2.5 5-2.5 11.45 0 2.4.7 4.05l.05-.05m-15.95-67.5q-1.9-3-2.65-3.85-1.95-2.4-5.55-5-4.1-2.95-12.2-4.8-1.6.15-2.3.6-1.9 1.1-1.9 5 0 3.3 1.9 7.9.85 2.1 2.1 4.3 3.4 5.65 3.95 6.45 1.75 2.45 4.05 4.5.5-1.4 1.85-3.55 1.6-2.35 3.75-4.8.25-.25 4-3.7 2.4-2.2 3-3.05m66.5 2.05q2.8-2.85 4.35-4.55Q174.45 8 174.45 7q0-.85-.5-1-.25-.1-1.3-.1 0 .15-1.3.1-1.5-.05-1.7 0l-4.1.3q-2.4.15-4.3.45-2.35.35-3.5.45-3.7.3-7.7 1.55-3.7 1.15-4.55 2.3 10 5.75 14.8 12.25 1.85-1.65 3.05-2.95 2.2-2.9 3.9-4.65z"
                    id="PetPet002__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M126.25 146.25q-.7-1.15-2.7-1.95-1.6-.7-3.05-1.1-.45-.15-.95-.25h-.05l-.3-.1q-5.65-1.15-10.95-.55-.15 0-.3.05-.25 0-.45.05-2.05.35-2.95 2.1-.1.1-.15.25-1.7 3.65 2.8 4.7l.9.2h16.75q3-.55 1.4-3.4M3.7 142.7l-.7.1q-.15 0-.3.1-1.1.4-1.75.95-.95.75-.95 1.8 0 .25.05.6.1.7.7 1.6.2.25.45.45.1.1.2.15l.35.3q3.25.15 4.7.15 1.4 0 3.5.15 2.05.1 7.25.15.85 0 1.55-.65 2.4-2.25-.8-3.75-1.85-.9-3.8-1.45-.05-.05-.1-.05-2.6-.75-5.3-.9-2.5-.1-5.05.25v.05M111.45 14.75q.1-.1.2-.15-4.1-5.2-10.95-9.95Q94.05.3 86.4 0q-2.65 0-4.75.85V.9q-.7.55-.55 1.35.75 3.75 2.3 9.05 1.5 5.25 4.3 11.85 2.75 6.5 6.4 11.1 2.05-3.45 2.9-5.75-6.75-9.95-8.8-23.25 6.5.65 10.95 3.85 4.6 3.25 8.6 7.85.8-.55 3.7-2.2m14.3 34.1q-.2-.05-.35-.05-7.9-1.55-13.5 4.35-1.65 1.55-2.95 3.35-3.9 5.25-4.8 12.15l-.1 1.2q-.35 8.35 2.9 12.6 3 3.45 9.05 4 .75-.05 1.55-.15.2-.05.4-.05.05-.05.1-.05h.05q1.1-.25 2.2-.65.15-.1.3-.15 1.4-.55 2.75-1.35.45-.3.9-.65.1-.1.2-.15.7-.65 1.25-1.4 2.45-3.65 4.1-11.85.45-2 1.55-3.1.75-.8 1.8-1.15.2-.1.45-.15l-.35-11.45h-.6q-1.35-.1-2.35-.85-1.05-.8-1.05-2-.1.05-.2.15-.8.65-.95.8-2.2.85-2.9-.9-.4-1.25.55-2.5m40.9 19.3q-.2-1.6-.75-3.3-.45-1.3-.75-2.65-1.3-5.15-4.7-8.8-.85-.9-1.7-1.5-.2-.15-.35-.25-.15-.1-.25-.15-1-.65-3-1.8-6-2.9-13.45-1.2-.3.05-.55.15h-.2l.1.2q.45 1.4-.4 2.8-1 1.3-3 .35-.1-.05-.15-.05-.4-.2-.75-.5l-.05-.1h-.05q0 1.15-1.1 1.95-.4.35-.9.55-.05.1-.05.25 0 .3.05.8v10.6q.75-.05 1.5.2l.2.05q.7.25 1.4.7 1.1 1.05 2.05 2.25 1.65 1.8 2.45 3.8.6 1.1 1.05 2.3 0 .05.05.1.1.4.25.75.25.55.5 1.05 1.7 3.45 4 5.3l.05.05q4.35 3.45 10.8 1.05.35-.25.75-.55.9-.65 1.85-1.55.15-.2.4-.45.5-.6 1.05-1.2.2-.35.45-.6 2.55-3.65 3.15-7.3v-.25q.2-1.5.05-3v-.05M186.7 8.6q.25-2.95-3.8-3.5-7.2-1.3-15.55-.2-11.4 1.75-20.65 6 3.85.95 7.3 2.55 12.6-4.2 26.3-3.9-.85.85-1.75 1.65-.45.35-.75.8-4.1 5.25-8.3 10.45-.95 1.15-1.75 2.35 1.6 2.3 2.7 4.95 2.9-3.45 5.65-7.15 2.55-3.55 5.65-6.65 3.05-3.15 4.85-7.05l.1-.3z"
                    id="PetPet002__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M74.8 291.35q-.158.111-.35.15-1.069.449-2.2.7-.063.005-.15 0-.173.063-.4.1-.05.28-.05.6.003.81.5 1.3.613.622 1.4.6.848.022 1.35-.6.622-.49.6-1.3.022-.824-.6-1.45-.031-.019-.1-.1M56.95 278.2q-.044-.056-.1-.15-.486-.38-1.25-.4-.799.028-1.4.55-.32.367-.45.8-.075.292-.1.6.028.823.55 1.35.601.597 1.4.6.836-.003 1.35-.6.597-.527.6-1.35-.003-.812-.6-1.4m-9.15-8.05q-.296.393-.45.85-.05.28-.05.6.003.81.5 1.3.613.622 1.4.6.848.022 1.35-.6.61-.49.6-1.3.01-.824-.6-1.45-.031-.019-.1-.1-.486-.392-1.25-.4-.787.003-1.4.5m-2.65-10.4q-.461-.417-1.2-.45-.811.028-1.4.55-.32.368-.45.8-.075.305-.1.6.028.836.55 1.35.589.597 1.4.6.823-.003 1.35-.6.597-.514.6-1.35-.003-.799-.6-1.4-.056-.044-.15-.1z"
                    id="PetPet002__Layer3_1_FILL"
                />
                <path
                    d="M110.2 242.1q-.746.826-.75 1.95.004 1.11.75 1.85.814.82 1.9.8 1.136.02 1.85-.8.846-.74.85-1.85-.004-1.124-.85-1.95-.714-.734-1.85-.75-1.086.016-1.9.75m-26.75 3.65q.008-1.111-.8-1.95-.752-.721-1.9-.75-1.086.029-1.9.75-.746.839-.75 1.95.004 1.11.75 1.85.814.82 1.9.8 1.148.02 1.9-.8.808-.74.8-1.85z"
                    id="PetPet002__Layer3_2_FILL"
                />
                <g id="PetPet002__Layer3_0_FILL">
                    <path d="M40.3 256.4q-1.56 1.582-1.1 2.1l2.8 6.85q1.717 5.43 4.4 9.35 2.706 3.913 6.05 7.1 1.787 1.753 3.95 3.6 1.185 1.025 2.45 2.05 6.713 5.777 14.35 10.2 2.77-2.214 3.95-4.4.914-1.515 1.2-3.35.068-.254.1-.55-.427.382-.95.7-1.32.81-2.7 1.3-.158.111-.35.15-1.069.449-2.2.7-.063.005-.15 0-.173.063-.4.1-.745.118-1.5.15-6.032-.53-9.05-4-3.231-4.237-2.9-12.6-.095-.076-.2-.15-2.251-1.832-4.25-4-6.105-6.752-7.5-15.4v-.05q-.033-.2-.1-.4-.285-.618-2.05-.8-2.234-.204-3.85 1.35m54.75-1.8v-.05q-.713-.805-2.3-1.45l-2.55-1h-.05q-1.506-.598-3.45-.25h-.05q-.303.03-.65.05-.618.126-1.25.3-2.307.66-4.6 2.35-.128.158-.25.3-.925 1.25-.5 2.5.688 1.749 2.85.85.22-.108 1-.75.109-.09.2-.2v.05q0 1.2 1.05 2 .99.765 2.35.85h.6l.35 11.4q.49-.152 1.05-.15v-10.5q-.05-.5-.05-.85.004-.094 0-.2.526-.207.95-.55 1.088-.792 1.1-2l.1.15q.362.28.75.45.067.06.1.1 2.051.938 3.05-.35.826-1.412.35-2.85-.02-.067-.1-.15l-.05-.05z" />
                    <path
                        fill="#FFF"
                        d="M119.55 240.7q-.45-1.257-1.1-2.5-5.663-11.14-18.5-11.05l-.15.05q-2.702.118-5.3 1.1-3.99 1.453-5.75 5.2-1.18 2.608-1.6 5.2-1.512-4.153-5.1-7.15-4.231-3.154-8.5-3.6-2.205-.194-4.45.3-8.99 1.465-13.1 9.85-.923 1.659-1.45 3.6v.05q-1.986 7.829 1.85 15 2.507 4.602 6.75 5.75 1.305-1.785 2.95-3.4 5.59-5.827 13.5-4.35.158.058.3.1.122-.142.25-.3 2.293-1.69 4.6-2.35.632-.174 1.25-.3.347-.02.65-.05h.05q1.162-1.97 1.5-4.35.324.956.7 2.2.406 1.288 1.05 2.15l.2.25h.05l2.55 1q1.587.645 2.3 1.45v.05q.099.027.15 0 .086.026.15 0 .263-.058.5-.15 7.497-1.674 13.5 1.2 2.008 1.2 3 1.8.113.095.2.15.202.141.35.25.904.65 1.75 1.55.191-.1.35-.2 5.06-4.066 5.5-10.45.346-4.21-.95-8.05z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet002__Symbol_3_0_Layer0_0_FILL"
                transform="translate(-94.45 200.55)"
                id="PetPet002__col1n"
            />
            <use
                xlinkHref="#PetPet002__Symbol_19_0_Layer0_0_FILL"
                transform="translate(-38.05 208.1)"
                id="PetPet002__col1d"
            />
            <use
                xlinkHref="#PetPet002__Symbol_18_0_Layer0_0_FILL"
                transform="translate(-45.8 206)"
                id="PetPet002__col2n"
            />
            <use xlinkHref="#PetPet002__Layer3_0_FILL" />
            <use xlinkHref="#PetPet002__Layer3_1_FILL" />
            <use xlinkHref="#PetPet002__Layer3_2_FILL" />
        </g></g>
    )
}

export default PetPet002
