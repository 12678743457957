import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00006D",
    "col1n": "#0000BB",
    "col2n": "#FFFF00",
    "col2s": "#D2AD00"
}

function AccHat066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.4 47.7q1.75-8.85-9.65-18.1Q168.8 19.15 139 8.2q-.7-.25-1.4-.45l-5.6-2Q100.4-4.2 70.8 3 60.25 6.35 51.3 9.75q-15.05 5.55-25.65 11.1-11.4 5.9-17.7 11.8-9.55 8.8-7.6 17.45l4.5 14.25.05.05h-.05l1.7 14.15L185 75.75l1.55-14.55 4.85-13.5m-28.1 4.05l-.05-.05.05.05m-72.05 20.3v.05q-.098-.049-.15-.05h.15M58.7 45.8v.1h-.05l.05-.1z"
                    id="AccHat066__Symbol_284_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M191.4 47.7q1.75-8.85-9.65-18.1Q168.8 19.15 139 8.2q-.7-.25-1.4-.45l-5.6-2Q100.4-4.2 70.8 3 60.25 6.35 51.3 9.75q-15.05 5.55-25.65 11.1-11.4 5.9-17.7 11.8-9.55 8.8-7.6 17.45l.4 1.1Q6.2 37 35.2 22.8q13.4-6.6 31.9-13.2 19.95-5.2 40-3.65Q136.6 10.2 168 27.5q19.05 11.15 22.1 22.2.15.45.25.95l1.05-2.95z"
                    id="AccHat066__Symbol_189_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M103.5 18.65q-.55-.6-1.4-.6h-8.15v-3l.7-.2.7-.3h-.05q1.1-.55 2.05-1.45 2.25-2.25 2.25-5.45t-2.25-5.4Q95.15 0 91.95 0T86.5 2.25q-2.2 2.2-2.2 5.4t2.2 5.45q.95.9 2.1 1.45.65.3 1.35.5v3H81.8q-.85 0-1.4.6-.6.55-.6 1.4 0 .85.6 1.45.55.55 1.4.55h8.15v10.4q0 .55-.15 1.1-.45 1.8-1.85 3.25-2 2-4.75 2t-4.75-2q-1.15-1.15-1.65-2.55l3 1.5q.75.35 1.55.1.75-.25 1.15-1 .35-.75.1-1.5-.25-.8-1-1.2l-6.75-3.4q-.55-.25-1.15-.15-.65.05-1.1.5l-5.1 4.6q-.6.55-.65 1.35-.05.85.55 1.45.55.6 1.35.65.85.05 1.45-.5l2.5-2.25q.6 2.95 2.9 5.25 3.15 3.15 7.6 3.15 4.45 0 7.6-3.2v.05q.6-.65 1.15-1.35.5.7 1.15 1.35v-.05q3.15 3.2 7.6 3.2 4.45 0 7.6-3.15 2.3-2.3 2.9-5.25l2.5 2.25q.6.55 1.45.5.8-.05 1.35-.65.6-.6.55-1.45-.05-.8-.65-1.35l-5.1-4.6q-.45-.45-1.1-.5-.6-.1-1.15.15l-6.75 3.4q-.75.4-1 1.2-.25.75.1 1.5.4.75 1.15 1 .8.25 1.55-.1l3-1.5q-.5 1.4-1.65 2.55-2 2-4.75 2t-4.75-2q-1.4-1.45-1.85-3.25-.15-.55-.15-1.1v-10.4h8.15q.85 0 1.4-.55.6-.6.6-1.45t-.6-1.4m-7.9-11q0 1.5-1.1 2.6-1.05 1.05-2.55 1.05-1.5 0-2.6-1.05-1.05-1.1-1.05-2.6t1.05-2.55q1.1-1.1 2.6-1.1t2.55 1.1q1.1 1.05 1.1 2.55m85.85 52.45q-1.9-.25-3.75-.45-.9-.1-1.75-.15l-5.75-.7q-.9-.15-1.75-.2-5.9-.65-11.75-1.15h-.05q-20.4-1.75-40.85-2.15l-.05-.05q-7.5-.15-15-.1-5.1 0-10.25.1-.8.05-1.55.1-2.9 0-5.8.1-4.95.15-9.85.35-17.2.8-34.4 2.5-1.25.1-2.45.25-7.85.8-15.7 1.8-.8.1-1.55.2-.4.05-.75.1-4.1.5-8.25 1.1l-5 .7q-2.6.35-5.1.75-.1.05-.1.1l.1 7.85h.1q.4.1.7.3.7-.15 1.5-.25 3.95-.6 7.95-1.15 1.95-.25 3.85-.5 4.1-.6 8.2-1.1 1.95-.25 3.9-.45 3.25-.4 6.5-.7 1.55-.2 3.1-.35 25.05-2.55 50.2-3.15 2.05-.05 4.1-.05 37.05-.7 74.15 2.8 8.45.8 16.9 1.8l.05.05h.15l.1-.1-.1-8.2z"
                    id="AccHat066__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M170.2 3.65q-.9-.1-1.75-.15-4.35-.5-8.65-.9l-3.1-.3Q136.25.55 115.8.15q-38.65-.8-77.45 3.1-1.45.1-2.95.3-6.6.65-13.25 1.5-1.5.15-3.05.4-1.65.2-3.3.45-2.8.35-5.55.75l-5.1.7q-2.6.4-5.15.85v1.55l1-.2 4.2-.7h.05l5-.7q2.35-.35 4.7-.65 2.6-.4 5.25-.7 1.6-.2 3.25-.4 5.7-.75 11.4-1.3 1.5-.2 3.05-.3 24.05-2.45 48.05-3.1 14.95-.4 29.85-.05 20.45.4 40.9 2.15 2.2.2 4.45.4 4.55.45 9.1.95l5.75.7q2.7.35 5.45.7v-1.4q-.2-.1-.4-.1-2.55-.4-5.1-.7l-5.75-.7z"
                    id="AccHat066__Symbol_5_0_Layer0_0_FILL"
                />
                <g id="AccHat066__Layer1_0_FILL">
                    <path d="M219.95 131.5q9.739-1.408 19.55-3.65 21.255-4.81 42.85-13.5.1-.01.2-.05-.1.029-.2.05v-.15l-.4-.15q-8.45-1-16.9-1.8-12.778-1.205-25.55-1.95-24.31-1.309-48.6-.85-2.05 0-4.1.05-25.15.6-50.2 3.15-1.55.15-3.1.35l-3.25.3-3.25.4q-1.95.2-3.9.45-4.1.5-8.2 1.1-1.906.248-4.95.65-3.035.403-6.5.8-1.488.209-2.1.25l-.25.05h-.05l-.4-.05.35.05h-.05l-.3-.05q.1.04.2.05.05.053.1.05l-.25.05h.3l1.25.5.05.05-.05-.05.3.1q13.784 5.42 27.7 9 11.707 3.045 23.5 4.8 32.687 4.849 66.2 0m-36.05-47h.05q1.98-.018 3.55-1.05-3.425.056-6.85.2 1.27.74 2.8.8.217.05.45.05m-7.6.85q-.675-.675-1.2-1.4-29.439 1.866-59.55 10.1l-15 9.4V109q.075.003.15 0 0-.05.1-.1 2.5-.4 5.1-.75l5-.7q4.15-.6 8.25-1.1.35-.05.75-.1.75-.1 1.55-.2 7.85-1 15.7-1.8 1.2-.15 2.45-.25 17.2-1.7 34.4-2.5 4.9-.2 9.85-.35 2.9-.1 5.8-.1.537-.036 1.1-.1.223.014.45 0 5.15-.1 10.25-.1 7.5-.05 15 .1l.05.05q.3.006.6 0 20.15.426 40.25 2.15h.05q5.85.5 11.75 1.15.85.05 1.75.2l5.75.7q.85.05 1.75.15 1.85.2 3.75.45.043.008.05 0l.05-5.7-11.2-6.45q-28.609-6.722-53.95-9.1-3.46-.324-6.95-.55-.338.485-.75.95-.104.1-.2.2-.04.038-.1.05 0 .05-.05.05-.01.06-.05.1-3.15 3.15-7.6 3.15-4.45 0-7.6-3.2v.05q-.65-.65-1.15-1.35-.55.7-1.15 1.35v-.05q-.366.372-.75.7-2.917 2.5-6.85 2.5-4.45 0-7.6-3.15z" />
                    <path
                        fill="#333"
                        d="M201.4 84.5h.2q1.647-.049 3-.85-3.389-.129-6.8-.2 1.61 1.05 3.6 1.05z"
                    />
                </g>
                <g id="AccHat066__Layer1_1_FILL">
                    <path
                        fill="#838383"
                        d="M231.7 121.45l-12.4-10.35h-26.8l6.25 12.25q-34.272.934-68.5-2.6-13.716-1.38-27.45-3.5-.202-.033-.45-.1h-.1q-.014.005-.05 0 .042.036.05.05h.05q.234.054.45.1 13.722 3.548 27.5 5.9 54.194 9.384 109.25.65 21.36-3.389 42.85-9.5-21.63 4.136-42.85 6.35-3.907.407-7.8.75z"
                    />
                    <path
                        fill="#333"
                        d="M191.5 85.35v-.05q-.366.372-.75.7v14.95q.223.014.45 0 5.15-.1 10.25-.1 7.5-.05 15 .1l.05.05q.3.006.6 0V84.55q-3.46-.324-6.95-.55-.338.485-.75.95-.104.1-.2.2-.04.038-.1.05 0 .05-.05.05-.01.06-.05.1-3.15 3.15-7.6 3.15-4.45 0-7.6-3.2v.05q-.65-.65-1.15-1.35-.55.7-1.15 1.35z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat066__Symbol_284_0_Layer0_0_FILL"
                transform="translate(95.7 39.05)"
                id="AccHat066__col1n"
            />
            <use
                xlinkHref="#AccHat066__Symbol_189_0_Layer0_0_FILL"
                transform="translate(95.7 39.05)"
                id="AccHat066__col1d"
            />
            <use
                xlinkHref="#AccHat066__Symbol_94_0_Layer0_0_FILL"
                transform="translate(100.7 45.7)"
                id="AccHat066__col2n"
            />
            <use
                xlinkHref="#AccHat066__Symbol_5_0_Layer0_0_FILL"
                transform="translate(100.75 104.3)"
                id="AccHat066__col2s"
            />
            <use xlinkHref="#AccHat066__Layer1_0_FILL" />
            <use xlinkHref="#AccHat066__Layer1_1_FILL" />
        </g></g>
    )
}

export default AccHat066
