import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#775C31",
    "col1l": "#D19532",
    "col1n": "#A37831"
}

function PlaFor001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M385 385V0H0v385h385M365.95 18.55V366H18.5V18.55h347.45z"
                    id="PlaFor001__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M385 385V0H0v385h385M379.95 5.05V379.9H5.1V5.15l-.1-.1h374.95M371.6 371.6V13.4H13.35l.05.05V371.6h358.2M360.85 24.15v336.7H24.15V24.15h336.7z"
                    id="PlaFor001__Symbol_2_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M358.25 358.2V0H0l.05.05V358.2h358.2M351.1 7.15v343.9H7.2V7.15h343.9z"
                    id="PlaFor001__Symbol_3_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.2}
                    d="M24.15 24.1l-3.6-3.55v-.05h-.05L0 0v384.95l24.15-24.15V24.1M378.8 378.7l6.2 6.2V-.05L360.85 24.1v336.7l17.9 17.9h.05z"
                    id="PlaFor001__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(0 -.05)"
                id="PlaFor001__col1n"
            />
            <use
                xlinkHref="#PlaFor001__Symbol_2_0_Layer0_0_FILL"
                transform="translate(0 -.05)"
                id="PlaFor001__col1d"
            />
            <use
                xlinkHref="#PlaFor001__Symbol_3_0_Layer0_0_FILL"
                transform="translate(13.35 13.35)"
                id="PlaFor001__col1l"
            />
            <use xlinkHref="#PlaFor001__Layer5_0_FILL" />
        </g></g>
    )
}

export default PlaFor001
