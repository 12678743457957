import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D9D9D9"
}
function AccJew055({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M18.5 2.95l-.65-2Q17.7.45 17.2.2q-.55-.3-1.1-.1-.45.15-.7.7-.05 0 0 .05-.05 0-.05.05-.3.5-.1 1l.6 1.95q.2.55.8.8.05.05.15.1-.25.35-.25.85l.1 2.05q0 .55.5 1-.35.2-.55.55l-.7 1.95q-.2.5-.05 1-.25-.1-.55 0l-2 .75q-.4.15-.7.65-.2-.25-.45-.4l-2-.65q-.55-.2-1.1.1-.1-.55-.5-.85l-1.65-1.3q-.35-.35-.95-.25-.05 0-.1.05.05-.55-.2-.95L4.4 7.6q-.35-.4-1-.45h-.05q.2-.5.05-1l-.75-2q-.15-.55-.7-.85Q1.5 3 1 3.25q-.5.15-.9.65.05.05 0 .05-.2.6-.05 1.05L.8 7.05q.15.55.65.7l.5.2q-.05.1-.1.25.05.05 0 .1 0 .55.3.95L3.4 11q.35.4.9.45h.45v.2q.1.55.55.9l1.5 1.35q.55.4 1.05.3l.5-.1q0 .15.05.3v.05q.35.55.85.65l2 .65q.5.2 1 0 .45-.2.65-.5.1.1.3.2 0 .05.05.05.55.1 1.05-.1l1.95-.7q.5-.25.7-.7.2-.55.1-.95.25 0 .55-.05.1-.05.15 0 .45-.3.65-.8l.75-1.85q.3-.55.05-1.1-.15-.35-.3-.5.05-.1.1-.15l.05-.05q.35-.5.3-1l-.05-2.1q-.05-.6-.5-.95-.25-.2-.45-.3 0-.05.05-.1 0-.05.05-.1.2-.55.05-1.05M16.85.8q.2.15.3.4l.65 2q.1.3 0 .55-.2.25-.45.35-.2.05-.45-.1-.25-.1-.35-.4l-.65-1.95q-.1-.25.05-.5 0-.05.05-.05.1-.25.3-.3.25-.15.55 0m.4 4.75q.05-.25.2-.5L17.5 5q.2-.15.4-.2.25 0 .5.15.15.25.15.55l.1 2.05q-.05.3-.1.5-.3.2-.55.25-.25 0-.35-.2-.3-.15-.3-.45l-.1-2.1m.5 3.65q.25-.15.45-.15.25.15.4.45.1.25-.05.5l-.75 1.95q-.15.25-.35.35-.3.1-.55-.05-.2-.05-.3-.3-.2-.25-.05-.5l.8-1.9q.05-.25.35-.4l.05.05M.7 4.3q.2-.3.45-.35.25-.2.45-.05.3.15.35.4l.75 2.05q.05.2-.1.5l-.05.05q-.05.3-.3.3-.25.1-.5-.1-.2-.05-.3-.3L.7 4.85Q.65 4.6.7 4.3m2.15 3.6q.25-.15.4-.1.35.05.55.25L5.05 9.7q.15.2.15.5l-.05.1q-.05.25-.2.35-.25.15-.6.2-.2-.05-.35-.3l-1.25-1.7q-.15-.2-.2-.5.1-.35.3-.45m3.1 3q.4-.05.55.15l1.65 1.25q.15.2.2.45v.1q.05.25-.05.45-.2.15-.55.25-.25.05-.5-.15L5.7 12.05q-.2-.15-.25-.5.05-.25.2-.4.05-.2.3-.25m3.35 2.35q.35-.15.6-.05l2 .65q.2.1.35.35-.05 0 0 .1.2.2.1.45-.05.2-.35.3-.25.1-.5 0l-2-.65q-.25-.05-.45-.3-.1-.3-.05-.5.1-.25.3-.35m6.65-.5l.15.15q.2.05.25.25.1.2-.05.55-.15.25-.35.35l-1.85.7q-.3.1-.6.1-.2-.2-.3-.4-.1-.25-.1-.45.2-.3.45-.55l2-.65q.2-.05.4-.05z"
                    id="AccJew055__Symbol_135_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew055__Symbol_135_0_Layer0_0_FILL"
                transform="translate(238.35 310.4)"
                id="AccJew055__col1n"
            />
        </g></g>
    )
}
export default AccJew055
