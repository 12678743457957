import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col1s": "#000000",
    "col2n": "#AD5300",
    "col2s": "#271200"
}

function ObjToo055({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M96.05 41.35l4.75-8.05-5.15-3.5-.1-.05h.1l.15-.15-25.65-18.15-.6 1.1-.2-.1-4.05 7.35 10.5 7.35q-.5 1.05-1.15 2.1l-6.6-4.5-6.8 1.95-.85 1.6-11.7-7.05-1.1 1.9-3.35-2-5.7.75L5.4 0q-8 2-4.2 7.5l31.55 21.1 15.4 18.05q-2.05 9.2-18.45 11.9l7.2 23.85h.05q23.55-3.95 32-18.75l2.7 1.6-.75 1.3 10.55 6.35v.2q1.15.85 1.15 2.05v.2q-.05.55-.35 1.4 4.85 1.35 6.65 5.45 1.1 2.5 1.1 6.65 0 2.7-1.05 5.95l-.2.6 11.65-2.55.3-.2q1.05-.9 1.25-2.45-2 1.5-4.6 1.5-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4 2.25-2.25 5.4-2.25.7 0 1.4.15l.8-1.5 15.1 15.5h.1l26.45 27.05.3-.45-.15-.2q.15-1.05.3-2.05.2-1.3.45-2.5 2.65-12 10.6-22.3 1.15-1.45 2.35-2.85 1.4-1.7 2.95-3.3l-60.15-30.4-1.25-9.25-3.6-2.5q.5-1 .8-2l2.25 1.55m7.95 31.6q-.7-2.4.9-5.6l2-3.55 42.5 20.8q-7.25 8.9-12.35 22.6L104 72.95m-14.9-29.1l.6 6.9-23.2-15.5 2.9-5.2 19.7 13.8m47.95 63.35q.55-1.85.1-.5-.05.2-.1.5z"
                    id="ObjToo055__Symbol_214_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 8.55l29.2 19.7 5.05-9.05.9-7.95L33 9.95l-2.25 4.15L10.35 0 3.6 2.1 0 8.55M134.25 69l6.95-12.55-11.55-3.95-5.3 9.05 9.9 7.45m-50.4 8.35L81.6 73.4q-6.2 6.5-15.15 8.6L69 86.4q8.65-1.7 14.85-9.05m4.75 8.05l-2.4-4.15q-6.45 7.25-14.65 9.55l2.6 4.4q8.3-2.95 14.45-9.8M97 69.35q.55.7 1.1 1.45l-1.35-.85-.15.25-.05-.05-1.95 3.6.05.05L116.6 87l1.95-3.5-1.4-.9q.85.2 1.75.3l2.05-3.7-21.9-13.55-2.05 3.7z"
                    id="ObjToo055__Symbol_158_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M59.2 54.5q0-1.15-1.3-1.6.7.85 1.2 1.95.1-.15.1-.35m-6.4-4.85v-.05q.2-.7.1-1.4-.05.55-.35 1.4.1 0 .25.05m-22.85-18.8l1.9-2.9-11.8-7.45.2.15-1.8-1.15Q16.4 28.7 0 31.4l7.2 23.85h.05q23.55-3.95 32-18.75l-9.3-5.65m-19.5 9.2l-2.55-4.4q8.95-2.1 15.15-8.6L25.3 31q-6.2 7.35-14.85 9.05m5.15 8.8l-2.6-4.4q8.2-2.3 14.65-9.55l2.4 4.15q-6.15 6.85-14.45 9.8m48.5-36.2L46.1 0q-.5 1.05-1.15 2.1L63.3 14.65q.5-1 .8-2z"
                    id="ObjToo055__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1.4 1.2q-.5-.05-1.15.4-.65.4.25 1.25 1.05 1 2.35 1.6.25.1.45-.05 1-.8.25-1.8Q1.9 1.2 1.4 1.2m13.15 10.95q.25.1.45-.05 1-.8.25-1.8-1.65-1.4-2.15-1.4-.5-.05-1.15.4-.65.4.25 1.25 1.05 1 2.35 1.6M10.8 3.2q1-.8.25-1.8Q9.4 0 8.9 0q-.5-.05-1.15.4Q7.1.8 8 1.65q1.05 1 2.35 1.6.25.1.45-.05m11.7 7.7q1-.8.25-1.8-1.65-1.4-2.15-1.4-.5-.05-1.15.4-.65.4.25 1.25 1.05 1 2.35 1.6.25.1.45-.05m89.05 63.3l-21.9-13.55-.25.45.05.05-.05.1 21.8 13.55.35-.6z"
                    id="ObjToo055__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.25 12l-.05.15L4.25 0 0 7.75l4.65 3.1v-.05l3.5 2.25-.05.05 4 2.6v.05l4.85 3.2H17l6.35 4.15 4.15-7.4-4.4-2.95v-.15l-.85-.6z"
                    id="ObjToo055__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M3.3.55L2.45 0 0 8.85l.85.6V9.4L3.3.55M7.65 3.5l-.9-.6-2.4 8.75-.05.05.85.5 2.5-8.7m3.75 2.55l-.8-.55-2.3 8.8v.05l.85.5 2.25-8.8m4.15 2.8l-.8-.55-2.4 8.65.8.6h.05q.05-.1.05-.15l2.3-8.55m3.75 2.35l-.85-.6-2.05 9 .9.6 2-9z"
                    id="ObjToo055__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo055__Symbol_214_0_Layer0_0_FILL"
                transform="translate(34.6 207.55)"
                id="ObjToo055__col1n"
            />
            <use
                xlinkHref="#ObjToo055__Symbol_158_0_Layer0_0_FILL"
                transform="translate(5.75 188.35)"
                id="ObjToo055__col1l"
            />
            <use
                xlinkHref="#ObjToo055__Symbol_137_0_Layer0_0_FILL"
                transform="translate(64.3 234.7)"
                id="ObjToo055__col1d"
            />
            <use
                xlinkHref="#ObjToo055__Symbol_110_0_Layer0_0_FILL"
                transform="translate(13.1 197.05)"
                id="ObjToo055__col1s"
            />
            <use
                xlinkHref="#ObjToo055__Symbol_89_0_Layer0_0_FILL"
                transform="translate(64.15 234.2)"
                id="ObjToo055__col2n"
            />
            <use
                xlinkHref="#ObjToo055__Symbol_5_0_Layer0_0_FILL"
                transform="translate(67.95 235.6)"
                id="ObjToo055__col2s"
            />
        </g></g>
    )
}

export default ObjToo055
