import { AssetSvgProps } from "../../../shared/assets"

function ShiMot100({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFD900"
                    d="M159.2 258v36.1h66.5V258h-66.5z"
                    id="ShiMot100__Layer1_0_FILL"
                />
                <path
                    fill="#7C2C83"
                    d="M247.45 273.25q-2.3 0-3.95 1.65-1.65 1.6-1.65 3.95 0 2.3 1.65 3.95 1.65 1.65 3.95 1.65 2.35 0 3.95-1.65v-.05q1.65-1.6 1.65-3.9 0-2.35-1.65-3.95-1.6-1.65-3.95-1.65m-3.1 2.5q1.3-1.3 3.1-1.3 1.85 0 3.1 1.3 1.3 1.25 1.3 3.1 0 1.8-1.3 3.1h-.05q-1.2 1.3-3.05 1.3-1.8 0-3.1-1.3t-1.3-3.1q0-1.85 1.3-3.1z"
                    id="ShiMot100__Layer1_0_MEMBER_0_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot100__Layer1_0_FILL" />
            <use
                xlinkHref="#ShiMot100__Layer1_0_MEMBER_0_FILL"
                transform="matrix(2.11102 0 0 2.11102 -329.9 -312.6)"
            />
        </g></g>
    )
}

export default ShiMot100
