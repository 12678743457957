import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#794D32",
    "col1n": "#AC8268",
    "col1s": "#4D2E1B",
    "col2n": "#FFC400",
    "col2s": "#BD7B00"
}

function AccMor020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M130.8 5.3L130 0l-11.55 3-1.9.4q-34.05 7.75-71.2 8.4-3.15-.4-3.9-.5 0-.45-.6-.55-.65 0-.8-.05h-3.6q-.35.1-.75.15-.4-.3-1.4-.65-1.7-.6-3.1-.6-.8 0-.85.4-.1 0-.15.05l-.05.05v.3l-.45 1.45h-3.5q-.5.15-.75.2h-.25l-.3-.1-1.55-.2q-.15-.05-.3-.05-.25-.05-.5-.05-1.15-.8-7.65-1.15L1.3 9.85q-.1-.05-.15-.05H.9L0 15.55q4.75.5 9.45.9.85.1 1.75.15h.1q3.65.3 7.25.55 2.45.2 4.5.5l-.1-.2q-.1-.15-.15-.35l1.9.75q.05 0 .1.05.15 0 .25.05.15 0 .3.05.05 0 .1.05l.7.1q.15.05.25.05.55.1 1.05.25l.5.1-.8.85-.9 3.9v.05l-.7 2.95 2.5 3.85 3.45-2.05 2.25-6.85 3.7-3.1v1h.9q1.45.05 2.9.05v-1.25q.25-.1.55-.2l.3.2V18h5.85q7.9-.1 15.6-.55h.25q.45-.05.95-.05.2-.05.45-.05h.3q.8-.1 1.65-.1.3-.05.6-.05h.05q.25-.05.55-.05 4.35-.35 8.6-.75 21.25-2.15 40.45-7.2.15-.05.3-.05h.1q.6-.2 1.2-.35l2.2-.6q4.85-1.4 9.6-2.9z"
                    id="AccMor020__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6.95 11.05q-.25.3-.7.3-.4 0-.7-.3-.2-.2-.2-.45-.05-.1-.05-.2 0-.3.1-.5-.9-.3-1.9-.4l-.9 3.9v.05l-.7 2.95 2.5 3.85 3.45-2.05 1.7-6.2q-1.1-.9-2.3-1.45v.05q-.05.25-.3.45m-.75 4.3q0 .4-.3.65-.25.3-.7.3-.4 0-.7-.3-.25-.25-.25-.65 0-.45.25-.75.3-.25.7-.25.45 0 .7.25.3.3.3.75M1.25 2.05q-.35.15-.6.4-.5.85-.65 1.8h1.65q.2-.2.55-.2.4 0 .65.3.35.35.35.7 0 .4-.35.7-.25.3-.65.3-.35 0-.55-.2H.05Q.15 6.4.4 7q.05.2.15.4.25.35.6.6.3.05.55.1.05 0 .1.05l.7.1-.15-.15q-.65-.6-.65-.95 0-.15.95-.75t.95-1.3q0-.85-.7-1.2-.7-.4-.7-.9 0-.25.4-.7-.45-.1-.9-.15-.25-.05-.45-.1M12.7 1.7l-.2-.1q-.95-.4-1.65-.65.1.05.2.15-.05.25-.3.45-.25.3-.7.3-.4 0-.7-.3-.2-.2-.2-.45Q9.1 1 9.1.9q0-.3.1-.5h.05Q7.1-.25 6.55.15L6.5.2v.3l-.6 1.85q3.2.85 5.55 2.75.4-1.55 1.25-2.3.35-.35.75-.55l-.5-.45q-.15-.05-.25-.1m7.55 6.4V2.35H18.3l.15 5.75h1.8z"
                    id="AccMor020__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M20.9 12.05q.75.05 1.55.2 0-.05.05 0 .25-.4.4-.55.25-.3.6-.4-.6-.05-1.15-.05-1.25-.1-2.45-.15-3.05-.2-6.1-.45-.9-.05-1.75-.15h-.35L6.5 10 .4 9.3H.15l-.15 1h.25q.1 0 .25.05 2.75.3 5.45.55 2.75.25 5.55.5h.2q.5.05 1.05.1 1.7.1 3.4.25.25 0 .5.05 1.7.1 3.45.2l.8.05M129.1.5L129 0q-5.05 1.35-10 2.55-1.25.25-2.5.55-20.2 4.6-40.45 6.8-3.55.35-7.05.65-.55 0-.85.15h-.4l-.05-.05q-.4.05-.75.05-.2-.05-.5-.05-.55 0-1 .15h-.1q-.1 0-.15.05H65.05l-1.3.1-1.3.1q-.3 0-.6.05-.45 0-.9.05h-.25l-.05.05q-1.05 0-1.5.15 0-.05.05-.1-6.05.3-12.15.45H45.3q-1.15.05-2.3.05h-2.05v-.85l-5.9.05q-2.2-1.1-4.6-1.35-1.4.5-1.15.95.55-.4 2.7.25v-.05q.95.2 1.55.6h.05q.7.25 1.65.65l.2.1q.1.05.25.1l.5.45q-.4.2-.75.55-.85.75-1.25 2.3-2.35-1.9-5.55-2.75l.2-.75H25.3q-.4.1-.5.1-.45.15-.8.35.2.05.45.1.45.05.9.15 6.75 1.4 10.6 5.25-.2.4-.45.75 0 .05-.05.1-.3.4-.65.8-.95 1.1-2.3 2.05-.95-1.2-3.5-2.05-1.1-.35-2.45-.65l-.1.35-.4.55-.05.05h.25q1 .1 1.9.4l.05-.05q1.15.25 1.8.75v-.05q1.2.55 2.3 1.45l-1.7 6.2 1.05-1L33.2 22q1.3-.9 2.25-1.85.25-.3.5-.55.3-.35.6-.75V11.7q1.85 0 3.7.05l.1 6.75h.85l-.15-5.75h4.25q1.15-.05 2.25-.05 7.15-.1 12.2-.35l1.2-.1q.8-.05 1.5-.1 1.4-.1 2.35-.15h.3q1-.1 1.35-.05h.3q4.95-.35 9.8-.85 20.9-2.15 39.9-7h.1l2.7-.7q5-1.35 9.85-2.9z"
                    id="AccMor020__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.45 1.2l.4-1.1q-2.3-.15-3.8 0Q2.5.25 2.3.35q-.2.1-.2.15-.35.1-.6.4-.15.15-.4.55-.05-.05-.05 0-1.55 2.3-.8 4.5.1.65.4 1.2l.1.2q.55.85 1.55 1.2l2.35.5.4-.55.1-.35Q4.65 8 4.1 7.9q-.1 0-.25-.05l-.7-.1q-.05-.05-.1-.05-.25-.05-.55-.1-.35-.25-.6-.6-.1-.2-.15-.4Q1.5 6 1.4 5.45h2.4q.55-1 0-1.6H1.35q.15-.95.65-1.8.25-.25.6-.4.35-.2.8-.35.1 0 .5-.1h3.55z"
                    id="AccMor020__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M.1 1.6h.6Q.5 1.25.5.85v-.1Q.5.4.7 0H.05q-.1.6 0 1.2 0 .15.05.35v.05z"
                    id="AccMor020__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    d="M152.95 307q.35-.3.35-.7 0-.35-.35-.7-.25-.3-.65-.3-.35 0-.55.2h.8q.55.6 0 1.6h-.8q.2.2.55.2.4 0 .65-.3z"
                    id="AccMor020__Layer5_0_FILL"
                />
                <path
                    d="M154.6 317.25q.3.3.7.3.45 0 .7-.3.3-.25.3-.65 0-.45-.3-.75-.25-.25-.7-.25-.4 0-.7.25-.25.3-.25.75 0 .4.25.65m2.45-4.95q.25-.2.3-.45-.65-.5-1.8-.75l-.05.05q-.1.2-.1.5 0 .1.05.2 0 .25.2.45.3.3.7.3.45 0 .7-.3m-.6-4.95q-.25.3-.25.75 0 .4.25.65.3.3.7.3.45 0 .7-.3.3-.25.3-.65 0-.45-.3-.75-.25-.25-.7-.25-.4 0-.7.25m2.85-5.7q-.1.2-.1.5 0 .1.05.2 0 .25.2.45.3.3.7.3.45 0 .7-.3.25-.2.3-.45-.1-.1-.2-.15h-.05q-.6-.4-1.55-.6v.05h-.05z"
                    id="AccMor020__Layer5_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor020__Symbol_118_0_Layer0_0_FILL"
                transform="translate(126.45 291.35)"
                id="AccMor020__col1n"
            />
            <use
                xlinkHref="#AccMor020__Symbol_69_0_Layer0_0_FILL"
                transform="translate(150.1 301.25)"
                id="AccMor020__col1d"
            />
            <use
                xlinkHref="#AccMor020__Symbol_41_0_Layer0_0_FILL"
                transform="translate(127.35 290.85)"
                id="AccMor020__col1s"
            />
            <use
                xlinkHref="#AccMor020__Symbol_23_0_Layer0_0_FILL"
                transform="translate(148.75 301.65)"
                id="AccMor020__col2n"
            />
            <use
                xlinkHref="#AccMor020__Symbol_1_0_Layer0_0_FILL"
                transform="translate(150.05 305.5)"
                id="AccMor020__col2s"
            />
            <use xlinkHref="#AccMor020__Layer5_0_FILL" />
            <use xlinkHref="#AccMor020__Layer5_1_FILL" />
        </g></g>
    )
}

export default AccMor020
