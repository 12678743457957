import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#006AC9"
}
function ShiSho082({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M148.3 23.15q-.85-2.05-1.8-4.05-1.8-3.95-3.85-7.6-.65-1.2-1.35-2.4l-.6-1q-.1-.1-.15-.2l-.2-.3q-.5-.8-.95-1.6-1.7-2.6-3.5-5.1l-.7-.9-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65l-1.6 1.1Q125.45 7 123.5 8.2q-.6.35-4.5 2.55l.05-.05-.2.1-.05.05q-2.8 1.35-5.7 2.55-.3.15-.65.25-2.5 1-5.1 1.9-.5.15-.95.3-2 .65-4.1 1.2l-.05.05q-3.35 3.1-9 7.85-4.15 3.45-10.5 7.35-2.4 1.45-5.15 3-1.1.55-2.2 1.15-.05 0-.15.05v.05q-.25-.1-.3-.1-3.8-2-7.35-4.2-10.75-6.65-19.65-15.2h.1q-2.1-.55-4.1-1.2-.45-.15-.95-.3-2.6-.9-5.1-1.9-.35-.1-.65-.25-2.5-1-4.85-2.15l-.4-.2-1.9-1q-.85-.45-1.7-.95-.5-.25-.95-.5-3.15-1.85-6.15-3.95L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.3.5-.6 1.05-.55.9-1.05 1.85-1.9 3.4-3.55 7.05-.15.2-.25.45-.9 1.95-1.75 4l-.1.2q-.35.8-.65 1.65-.1.15-.15.3-.25.65-.5 1.35l-.3.8q-.2.45-.35.95l18.95 7.05v.05l-2.7 14q-.2.9-.35 1.75-.2 1-.4 2.05-.2.8-.35 1.65L11.7 69.2l-.9 3.5q-.25 1.1-.55 2.25-.2.95-.45 1.9-.05.05-.05.15l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 1.65.4 3.35.75 4.2.9 8.35 1.65 21.1 3.9 42.15 4.5 4.4.1 8.8.1 5.45 0 10.85-.25 11.9-.55 23.8-2.15 12.7-1.7 25.4-4.6.4-.1.85-.15l3.3-.8H140.75q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.1-.6-.25-1.15-.2-1-.4-1.95l-.4-2q-.15-.55-.25-1.05l-2.85-14.75 19.1-7.1q-.2-.5-.35-.95-.15-.4-.35-.85l-.5-1.3q-.05-.15-.1-.35-.35-.8-.7-1.65 0-.05-.05-.1z"
                    id="ShiSho082__Symbol_231_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.3 14.3l18.55 6.95.45-1.8-18.25-7-.1.2q-.35.8-.65 1.65m-.65 1.65l-.3.8q-.2.45-.35.95l18.95 7.05v.05l.5-1.9-18.8-6.95m139.8 50.25l-.45-1.9q-14.75 3.5-29.5 5.4-42.45 5.5-85.05-2.2-7.6-1.4-15.2-3.2-.2.95-.45 1.9 7.85 1.85 15.65 3.3 42.7 7.75 85.2 2.2 14.9-1.95 29.8-5.5m-53.2 11.9q11.9-.55 23.8-2.15 12.7-1.7 25.4-4.6.4-.1.85-.15l3.3-.8H140.75q.25-.05.5-.15h.2l-.05-.2v-.1l-.45-1.8q-15.05 3.65-30.1 5.65-42.6 5.55-85.4-2.2-8.1-1.5-16.15-3.45l-.5 1.8v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 1.65.4 3.35.75 4.2.9 8.35 1.65 21.1 3.9 42.15 4.5 4.4.1 8.8.1 5.45 0 10.85-.25M106.4 5.2q-2 .65-4.1 1.2l-.05.05q-3.35 3.1-9 7.85-4.15 3.45-10.5 7.35-2.4 1.45-5.15 3-1.1.55-2.2 1.15-.05 0-.15.05v.05q-.25-.1-.3-.1-3.8-2-7.35-4.2-10.75-6.65-19.65-15.2h.1q-2.1-.55-4.1-1.2 8.4 8.7 19.9 16.3 5.2 3.4 11.1 6.65l.3.15-.3.35q.4-.45.45-.5 1.95-1.05 3.8-2.15 3.8-2.2 7.3-4.5 4.75-3.15 8.95-6.45 6-4.75 10.95-9.85m27.55 33.4l-2.2-11.4q-11.85 2.45-23.65 3.85-16 1.95-32.05 2h-.7q1.05-.65 2.05-1.3 5.3-2.95 10.45-6.25.8-.5 1.55-1l8.4-5.85 3.4-2.6q9.55-7.55 17.6-15.8.1-.1.2-.15l.05-.05-.2.1-.05.05q-2.8 1.35-5.7 2.55-.3.15-.65.25-7.2 6.9-15.2 13.05-3.55 2.7-7.25 5.3-5.95 4.1-12.3 7.8l-2.3 1.3q-.05.05-.15.1-.05 0-.05.05-.2-.1-.25-.15-7.6-4.3-14.6-9.15Q48.3 13 37.9 3q-.35-.1-.65-.25-2.5-1-4.85-2.15L32 .4q-.35-.2-.7-.35 9.25 9.55 21.25 18.6l8.4 5.85q.75.5 1.55 1 6.1 3.9 12.45 7.35.05.05.15.1v.1q-28.3-.05-56.6-5.9L16.3 38.6q29.9 6.4 59.75 6.35 16.05-.05 32.05-2 12.9-1.55 25.85-4.35m1.45 7.1q-.1-.6-.25-1.15-13.25 2.9-26.5 4.55-16.3 2-32.6 2.05-30.45.05-60.95-6.6l-.45 1.95q30.75 6.8 61.4 6.7 16.4-.05 32.75-2.05 13.4-1.65 26.8-4.65l-.2-.8m-.65-3.1l-.4-2Q121.3 43.4 108.3 45q-16.15 1.95-32.25 2-30.05.1-60.15-6.45-.2 1-.4 2.05 30.3 6.6 60.55 6.5 16.2-.05 32.4-2 13.15-1.65 26.3-4.5M150 16.75q-.15-.4-.35-.85l-18.9 7 .5 1.9 19.1-7.1q-.2-.5-.35-.95m-.95-2.5q-.35-.8-.7-1.65 0-.05-.05-.1l-18.4 6.9.45 1.85 18.7-7z"
                    id="ShiSho082__Symbol_232_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho082__Symbol_231_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho082__col1n"
            />
            <use
                xlinkHref="#ShiSho082__Symbol_232_0_Layer0_0_FILL"
                transform="translate(117.35 239.25)"
                id="ShiSho082__col2n"
            />
        </g></g>
    )
}
export default ShiSho082
