import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.7 17.7q-1.4-9.65-4.35-17.7-.95 20.25-.95 22.2-.1 5.35.7 14.75.1 8.2-.1 13.85-.2 5.6-4.65 9.55-4.45 3.9-6.85 11.7-2.4 7.75-8.7 12.35-1.05.8-2.1 1.55-5.1 3.75-9.45 7.2-4.95 3.35-8.55 5.4-.95.55-1.9.3-1.25-.25-2.95-.9-1.75-.7-3-1.25-.65-.3-1.1-.5-.35-.25-4.65-2.45-1.1-.6-1.95-.85-2.65-.9-2.5 1.3.9 2.3 1.3 3.1.4.75.45 1.2.05.45.1.7.15 1-.05 2.1-.4 3.25-3.1 6.6-.9 1.3-2.15 2.05-1 .65-2.1 1.2-3.45 1.7-7.45 2.45l-6.95.65h-.35q-4.75 0-7.4-3.35h-8.9q-2.7 3.35-7.45 3.35-8.2 0-14.4-3.1-1.1-.55-2.1-1.2-1.25-.75-2.15-2.05-4.55-5.6-2.6-11 .25-.45.4-1.15 1.3-5.15-5.6-1.85-9.35 6.1-11.9 4.65-3.6-2.05-8.55-5.4-6.55-4.1-11.95-7.2-.1-.05-.15-.05-5.45-3.15-8.1-12.3-2.65-9.2-7.1-13.5-4.45-4.3-4.55-9.6-.1-5.35 0-13.55.8-9.4.7-14.75 0-1.95-1.15-21.7-2.35 8.8-5 17.95-.25 3.7-.3 9.65-.2 4-.05 8.25.1 4.25.15 5 0 .75.05 1.15Q1.9 56.05 3.9 61.55q.15.45.3.8 2.15 5.2 3.1 11.4.95 6.15 1.15 9 .2 2.8.2 3.2v.85q4.6 12.05 13.25 22.3 6.7 6.5 8.3 6.5 1.6-.05 3.75 3.1 3 4.3 4.05 5.25.4.35 1.05 1 2.15 1.55 4.45 3.1 1.15.15 2.6.35.85.15 1.55.3 2.05 2.35 3.35 3.2.45.9 1.6.9 4.85 0 6.2 2.75 1.35 2.75 5.8 2.75 1.55 0 2.55-.2.95.15 1.95.25.45.05 2.8 2 1.35 1.1 1.95 1.85 2.95 2.3 6.75 1.4 2.6-1.5 8.5-1.5 5.3 0 10.75 1.2h5.5q.9-.3 2.1-.65 5.4-1.55 8.9-1.55 1.85 0 3.25.05 3.55.85 7.65-1.95 4.05-2.85 4.8-3.15.85.8 2 1.15 1.7-.5 3.05-1.5 1.95-1.4 4.85-2.85 6.5-3.1 6.8-3.3.5-.35.65-2.15.1-1.8 2.8-2.15.9-.35 2.25-.55 2.45-.35 4.9-2.45 2.15-1.9 2.6-3.55.2-.65 3.3-3.65 1.45-1.4 2.2-2.3.65-.25 1.55-.05t6.3-7.35q5.35-7.6 8.05-15.35.35-1.05.65-2.05l-.7 2q1-4.4 1-4.85 0-.3.1-2.45.1-2.15.5-7.5.3-3.9 1.85-8.3.6-1.65 1.35-3.4 2.8-6.35 4.6-20.95l.1-.8v-2.25q-.45-7.25.05-11.35-.05-5.95-1.15-10.4z"
                    id="BeaChi023__Symbol_62_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M179.85 10.35q-.4 1.1-1 3.75-.65 2.8-1 3.75-.65 1.8-1.5 7.4-1.15 4.85-3.75 5.6-1 .25-1.2 1.5-.05.15-.05 1.75-.55.55-1.4 1.1-1 .65-1.35.9-1.05.75-1.8 3.1-.4 1.3-.95 3.65-2.8-1.1-3.65 1.2-1.15 3.05-1.85 3.3-1.1.35-2.15 2.8-.6 1.35-1.6 3.7-.5.85-3.9.85-3.4-.05-4.1 1.15-1.6 2.55-2.15 3-1.25 1.1-3.1.95-.5-.05-1-.2.2.7.2 1.25l-1.85 2.5-2.25.3q-1.4.2-2.1.7-1 .7-2.7 3.25-.3.45-.6.8-4.7 1.55-4.7 1.45-2.1 0-3.2-.35-1.2-.4-1.9-.4h-.65v.25q-.2 1.15-1.55 2.45-1.55 1.55-3.1 1.55-.45 0-2.2-1-1.75-1-2.55-1-1.35 0-3.8.5-2.5.5-4.45.5-1.15 0-3.4-.2-1.75-.15-2.95-.05-1.75.1-3.05-.8-.8-.7-1.4-1.1-.3-.25-.55-.35v.55q-.15 1.7-.5 2.55-.6 1.4-2 1.4-2.25-.1-6.75 0-1.4 0-4.55.05-2.7-.05-3.95-.55-5.15-2.25-7-2-1.45.15-3.05-1.7-1.85-2.1-2.95-2.3-2.25-.45-3.6-1.7-1.65-1.5-2.4-1.8-.4-.15-2-.2-1.4-.05-2-.55-.35-.35-.75-1.4-.4-1.05-1-1.6-.85-.85-4-.75-2.95 0-3.75-1.25-1.2-2.05-5.75-3.75-2.35-.95-3.35-3.95-1.35.15-2.2.2-3.3.25-5.55-2.3-2.3-2.65-2.65-2.7-2.6-.45-3.65-2.95-.7-2.95-1.1-4.05-.3-.95-2.6-3-2.35-2.05-2.65-2.75-.45-1.2-1.75-3.7-1.1-2.1-1.5-3.55-1.45-5.35-2.2-8.1-1.35-4.95-2.55-8.15-.6-1.65-4-7.15Q1.15 1.55.6.45L0 0q.15.45.3.8Q2.45 6 3.4 12.2q.95 6.15 1.15 9 .2 2.8.2 3.2v.85Q9.35 37.3 18 47.55q6.7 6.5 8.3 6.5 1.6-.05 3.75 3.1 3 4.3 4.05 5.25.4.35 1.05 1 2.15 1.55 4.45 3.1 1.15.15 2.6.35.85.15 1.55.3 2.05 2.35 3.35 3.2.45.9 1.6.9 4.85 0 6.2 2.75 1.35 2.75 5.8 2.75 1.55 0 2.55-.2.95.15 1.95.25.45.05 2.8 2 1.35 1.1 1.95 1.85 2.95 2.3 6.75 1.4 2.6-1.5 8.5-1.5 5.3 0 10.75 1.2h5.5q.9-.3 2.1-.65 5.4-1.55 8.9-1.55 1.85 0 3.25.05 3.55.85 7.65-1.95 4.05-2.85 4.8-3.15.85.8 2 1.15 1.7-.5 3.05-1.5 1.95-1.4 4.85-2.85 6.5-3.1 6.8-3.3.5-.35.65-2.15.1-1.8 2.8-2.15.9-.35 2.25-.55 2.45-.35 4.9-2.45 2.15-1.9 2.6-3.55.2-.65 3.3-3.65 1.45-1.4 2.2-2.3.65-.25 1.55-.05t6.3-7.35q5.35-7.6 8.05-15.35.35-1.05.65-2.05l-.7 2q1-4.4 1-4.85 0-.3.1-2.45.1-2.15.5-7.5.3-3.9 1.85-8.3-2.55 3.65-3 5.05z"
                    id="BeaChi023__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi023__Symbol_62_0_Layer0_0_FILL"
                transform="translate(96.05 122)"
                id="BeaChi023__col1n"
            />
            <use
                xlinkHref="#BeaChi023__Symbol_21_0_Layer0_0_FILL"
                transform="translate(99.95 183.55)"
                id="BeaChi023__col1d"
            />
        </g></g>
    )
}

export default BeaChi023
