import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.5 19.15L34 2.8 32.4 0 0 16.25l1.5 2.9M93 18.3l.55-3.15-35.7-6.2-.75 3.2 35.85 6.15H93z"
                    id="FacBro002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro002__Symbol_2_0_Layer0_0_FILL"
                transform="translate(145.55 119.4)"
                id="FacBro002__col1n"
            />
        </g></g>
    )
}

export default FacBro002
