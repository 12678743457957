import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo013({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#1B871B"
                    d="M86.3 249.15q-2.9 18.6 7.2 35.05 6.759 10.91 16.95 17.15.736-1.718 2.75-3.05 3.1-2.1 7.5-2.1t7.5 2.1q3.1 2.05 3.1 5 0 2.818-2.95 4.85 19.732 3.893 37.2-6.9Q182.1 291 187.85 273l-2.1-4.55-92.5-21.75-6.95 2.45z"
                    id="ObjFoo013__Layer7_0_FILL"
                />
                <path
                    fill="#FD8282"
                    d="M99.6 247.9l-5 3.15L179.4 271l-.7-4.65-79.1-18.45z"
                    id="ObjFoo013__Layer7_2_FILL"
                />
                <path
                    d="M151.65 283.4q-.6.1-1.2.6-.45.4-.55 1.2.2.7 1.05 2.35.85 1.6 1.4.95.55-.7 1-1.7.3-1 .15-1.65-.05-.85-.45-1.25-.55-.5-1.4-.5m9.75-5.25q-.6 1.8.3 1.7.75-.2 1.75-.6.95-.5 1.35-.95.55-.5.45-1.3.05-.65-.5-1.15-.5-.6-1.25-.4-.6.05-1.05.35-.7.5-1.05 2.35m-33.25 6.4q.45 1.75 1 1.2.75-.45 1.35-1.4.6-.95.7-1.65.2-.6-.1-1.3-.4-.55-1.1-.65-.75-.25-1.3.15-.75.45-.85 1.15-.25.75.3 2.5m-2.35-17.8q.4-.7.15-1.3-.15-.8-.8-1-.75-.45-1.35-.2-.8.2-1.2.9-.2.65-.4 2.4.1 1.9 1 1.45.65-.35 1.4-.9.8-.75 1.2-1.35m21.7 1.15q-.7 0-1.2.6-.55.35-1.05 2.2-.55 1.65.35 1.65.9-.25 1.7-.55 1.05-.5 1.65-1 .3-.65.45-1.25-.1-.65-.75-1.25-.4-.45-1.15-.4m-33.25 6.4q.25-.65-.15-1.25-.4-.5-1.15-.75-.5-.2-1.25.25-.7.35-1.45 1.75-1.15 1.65-.15 1.7.8.15 1.75 0 1.15-.15 1.75-.65.55-.35.65-1.05m-10.7-14.35q-.45.55-.55 1.35 0 .75 1.05 2.35.75 1.55 1.35.95.55-.7.9-1.7.3-.9.25-1.65 0-.85-.45-1.25-.55-.5-1.25-.45-.85 0-1.3.4z"
                    id="ObjFoo013__Layer7_3_FILL"
                />
                <g id="ObjFoo013__Layer7_1_FILL">
                    <path
                        fill="#26B825"
                        d="M187.05 268.35l-2.95-.7.7 4.6 3.05.75-.8-4.65m-92.35-21.6l-2.75-.65-5.65 3.05 3.15.7 5.25-3.1z"
                    />
                    <path
                        fill="#CEF5CD"
                        d="M184.1 267.65l-5.4-1.3.7 4.65 5.4 1.25-.7-4.6m-89.5-16.6l5-3.15-4.9-1.15-5.25 3.1 5.15 1.2z"
                    />
                    <path
                        fill="#A4EDA3"
                        d="M131.3 303.3q0 1.046-.4 2 17.535 2.814 33.05-6.9 15.3-9.45 20.85-26.15l-5.4-1.25q-4.95 14.55-18.3 22.85-14.702 9.03-31.2 5.9 1.4 1.574 1.4 3.55m-41.85-53.45q-2.65 17.4 6.9 32.65 6.42 10.397 16.2 16.25.315-.229.65-.45 2.503-1.696 5.85-2.05-11.207-5.223-18.2-16.55-8.35-13.55-6.25-28.65l-5.15-1.2z"
                    />
                    <path
                        fill="#FD3535"
                        d="M119.05 296.25q.802-.05 1.65-.05 4.4 0 7.5 2.1 1.023.677 1.7 1.45 16.498 3.13 31.2-5.9 13.35-8.3 18.3-22.85l-.7-4.65q-41.09-6.689-79.1-18.45l-5 3.15q-2.1 15.1 6.25 28.65 6.993 11.327 18.2 16.55z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo013__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo013__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo013__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo013__Layer7_3_FILL" />
        </g></g>
    )
}

export default ObjFoo013
