import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacEye024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M84.7 5.05l-.05.05q-6.3 2.5-9.7 3.6-5.85 1.9-7.8 2.5-2.9.85-7.05 1.7-5.85 1.1-7.25 1.5-2.95.8-3.35 2.15-.8-.9-2.5-1.75-2.6-1.35-8.05-2.25v.05Q33.7 11.65 28 10.15q-5.7-1.55-11.2-4.1-5.6-2.65-15.55-6Q.9-.1.55.1q-.4.2-.5.55-.15.35.05.75.2.35.55.45Q10.5 5.1 16 7.75 21.6 10.4 27.5 12q5.8 1.55 11.15 2.45 5.05.8 7.45 2.05h.05q1.75.9 2 1.6-.35.6-8 3.95-8.5 3.6-13.45 4.1-5.1.5-11.05 2.05-6 1.5-15.1 5.65-.35.15-.5.55-.15.35.05.7.15.35.55.5.35.15.7 0 8.9-4.1 14.75-5.55 5.8-1.5 10.8-2 5.15-.5 14-4.25 7.85-3.45 9-5.15.55.75 1.85 1.2h.05q1.55.45 4.45 1.35 2.85.85 8.5 3.7 5.8 2.95 16 4.85 9.85 1.8 18.15 5.05.35.15.75 0 .35-.2.5-.55.15-.35 0-.7-.2-.4-.55-.5-8.5-3.3-18.5-5.15-9.85-1.85-15.5-4.7-5.85-2.95-8.8-3.8-2.95-.9-4.45-1.35-.85-.3-1-.8v-.05q.1-.45 2-.95 1.4-.45 7.1-1.5 4.3-.9 7.25-1.75 2-.6 7.85-2.5 3.4-1.1 9.75-3.65 6.05-2.1 14.85-4 .4-.1.6-.45.2-.3.15-.7-.1-.4-.45-.6-.3-.2-.7-.1-8.9 1.95-15.05 4.05z"
                    id="FacEye024__Symbol_68_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye024__Symbol_68_0_Layer0_0_FILL"
                transform="translate(143.05 147.4)"
                id="FacEye024__col1n"
            />
        </g></g>
    )
}

export default FacEye024
