import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2n": "#FFFFFF"
}

function FacEye037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M66.05 28.25q-1.6-6.45-4.7-12.4-.15-.3-.25-.65-4.9-7.9-13.05-12-12.5-6.3-25.55-.15Q9.35 9.25 3.4 24.2-2.65 39.25 2 54.3q4.5 15.05 17.05 21.35 5.05 2.5 10.2 3.05 3.65 1.85 5.85 4.65l.2.3q.15.2.25.4 4.35 8.85 2.4 23.25-1.25 5.75 0 8.5 1 1.95 3.2 3 3.2 1.65 6.95.1 3.8-1.45 5.95-5.05 2.3-3.65 1.6-7.25-.2-1.15-.7-2.2l-1.5-2.7q-.2-.45-.45-.9-4.65-9-5.7-16.75l-.1-.7q-.3-1.85-.1-3.55.3-3.65 2.55-6.8.35-.3.7-.5 4.6-3.35 8.1-8.1 3.1-4.3 5.25-9.8 5.1-12.7 2.6-25.45-.15-.55-.25-.9m76.05-4.05Q136.15 9.25 123 3.05q-13.05-6.15-25.55.15-8.15 4.1-13.05 12-.1.35-.25.65-3.1 5.95-4.7 12.4-.1.35-.25.9-2.5 12.75 2.6 25.45 2.15 5.5 5.25 9.8 3.5 4.75 8.1 8.1.35.2.7.5 2.25 3.25 2.6 6.95.1 1.65-.15 3.4l-.1.7q-1.05 7.75-5.7 16.75-.25.45-.45.9l-1.5 2.7q-.5 1.05-.7 2.2-.7 3.6 1.6 7.25 2.15 3.6 5.95 5.05 3.75 1.55 6.95-.1 2.2-1.05 3.2-3 1.25-2.75 0-8.5-1.95-14.25 2.3-23.05l.1-.2q.2-.35.45-.7 2.2-2.8 5.85-4.65 5.15-.55 10.2-3.05Q139 69.35 143.5 54.3q4.65-15.05-1.4-30.1z"
                    id="FacEye037__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M57.25 48.95q4.6-11.4 2.25-22.9l-.1-.8q-1.45-5.75-4.25-11.05-.15-.3-.35-.55-4.25-7.1-11.65-10.85-11.2-5.55-22.9-.05Q8.4 8.2 3.1 21.7q-5.45 13.5-1.25 26.95Q5.9 62.2 17.2 67.75q11.15 5.65 22.95.15 11.75-5.5 17.1-18.95m79.6-.3q4.2-13.45-1.25-26.95-5.3-13.5-17.15-18.95-11.7-5.5-22.9.05-7.4 3.75-11.65 10.85-.2.25-.35.55-2.8 5.3-4.25 11.05l-.1.8q-2.35 11.5 2.25 22.9Q86.8 62.4 98.55 67.9q11.8 5.5 22.95-.15 11.3-5.55 15.35-19.1z"
                    id="FacEye037__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    d="M217 94.55q-.6 1.6.1 3.05.65 1.6 2.2 2.2 1.6.55 3-.2 1.55-.6 2.1-2.15.6-1.55-.1-3.1-.6-1.4-2.15-2-1.6-.6-3.05 0-1.55.7-2.1 2.2m-51.1-2.2q-1.45-.6-3.05 0-1.55.6-2.15 2-.7 1.55-.1 3.1.55 1.55 2.1 2.15 1.4.75 3 .2 1.55-.6 2.2-2.2.7-1.45.1-3.05-.55-1.5-2.1-2.2z"
                    id="FacEye037__Layer6_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye037__Symbol_80_0_Layer0_0_FILL"
                transform="translate(119.75 58.4)"
                id="FacEye037__col1n"
            />
            <use
                xlinkHref="#FacEye037__Symbol_46_0_Layer0_0_FILL"
                transform="translate(123.15 62.4)"
                id="FacEye037__col2n"
            />
            <use xlinkHref="#FacEye037__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye037
