import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00"
}
function AccMor095({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M36.35 5.35q-.5-.1-.9.05L23.35 10 14.65.5q-.3-.35-.75-.45-.5-.1-.9.1-.45.15-.7.55-.3.4-.25.9l.7 12.8L.9 19.7q-.45.2-.7.6-.25.4-.2.9.05.45.35.85.3.35.8.45l12.45 3.3L15 38.7q.05.5.4.85.3.35.75.45.45.1.9-.05.45-.2.7-.55l7.05-10.9 12.65 2.75q.45.05.9-.1.45-.2.7-.6.25-.45.2-.9-.05-.5-.3-.85l-8.2-10.15 6.5-11.1q.25-.4.2-.85-.05-.5-.35-.85-.3-.4-.75-.5M20.8 22.5q-.3.55-.05 1.15l1.35 3.45-4.55 7.1-1.05-9.75q-.05-.45-.35-.8-.35-.4-.75-.5L6 20.7l8.9-3.95q.45-.2.7-.6.2-.4.2-.85l-.5-9.7 6.55 7.15q.3.35.75.45.45.1.9-.05l9-3.4-4.9 8.3q-.25.4-.2.9.05.45.35.8l6.15 7.65-9.2-2-1.15-2.9q-.25-.6-.8-.8-.6-.3-1.15-.05-.6.25-.8.85z"
                    id="AccMor095__Symbol_211_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor095__Symbol_211_0_Layer0_0_FILL"
                transform="translate(188.95 61.2)"
                id="AccMor095__col1n"
            />
        </g></g>
    )
}
export default AccMor095
