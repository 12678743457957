import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#C1D2E2",
    "col1n": "#8FAFCD"
}
function BodHor001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.6 36L23.1 3.5q-1.9-6.95-3.8 0L.9 71.8q-.25.95-.45 1.8-.85 3.6 0 5.6.2.45.45.8.5.35 1 .8 5.5 3.9 11.1 5.35 12.3 3.25 24.6-5.35.05-.05.1-.05 2.8-2.75 0-13.85l-7.1-30.85V36z"
                    id="BodHor001__Symbol_8_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M33.85 44.5l-.35-1.7q-8.25 11.05-22 12.55-5.05.5-10.85-.25L0 57.25q6.15.85 11.5.25 14.05-1.5 22.35-13M29.1 23.65l-.4-1.6q-4.8 7.8-17.2 7.95-1.95.05-4.1-.15l-.6 2.2q2.45.2 4.7.15 12.9-.1 17.6-8.5v-.05m-5.05-21.9L23.65 0Q19 5.25 14.45 3.55l-.5 2q5 2.3 10.1-3.8z"
                    id="BodHor001__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHor001__Symbol_8_0_Layer0_0_FILL"
                transform="translate(172.95 20)"
                id="BodHor001__col1n"
            />
            <use
                xlinkHref="#BodHor001__Symbol_3_0_Layer0_0_FILL"
                transform="translate(174.45 32.35)"
                id="BodHor001__col1l"
            />
        </g></g>
    )
}
export default BodHor001
