import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M39.05 5.6q-.4-1.95-.85-3.7Q37.75.15 32.7.5q-5.05.3-12.55 1-7.55.65-17.35 2.85-1.7.4-2.8.65 1.6 1.15 3 2.55Q5.15 9.5 4.1 12.4q-.45 1.15-1.35 1.95-.35.3-.5.7-.3.2-.3.5 0 .05.05.35.15.3.75.3 2.6 0 4.6-.5h.7q3.15-.7 8.5-1.9 2.5-.5 4.95-.75 1.6-.2 3.55-.3.35 0 .7.05 2.25.25 4.5.4 2.25-.15 5.05-.55 2.8-.45 4.55-.65.3-.05.3-.75l-.05-.3q-.4-.3-.85-.55-.7-.35-1.45-.5-1-.2-2-.35Q36.45 8 38.05 7q1.2-.65 1-1.4m45.55 8.85q5.85 1.4 6.75-.1.9-1.55.05-4.4 3.3-3.2 2.6-4.9Q90.25 3.2 83.7 2.8q-6.6-.4-13-1-6.45-.6-11.1-.75Q54.95.85 52.05 0q2.15 2.4 3.65 5.1 1.05 1.8-.75 2.55-2.05.8-.9 1.9 1.35 1.35 1.25 3.25 4.75.5 9.35-.2 4.8-.7 9.65-.1 4.45.55 10.3 1.95z"
                    id="FacBro026__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro026__Symbol_26_0_Layer0_0_FILL"
                transform="translate(145.45 120.75)"
                id="FacBro026__col1n"
            />
        </g></g>
    )
}

export default FacBro026
