import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343",
    "col1s": "#121212",
    "col2l": "#666666",
    "col2n": "#434343"
}

function ObjToy076({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M375.45 329.05q2.351-.5 4.85-.2 2.546.302 7.15 1.8 4.657 1.497 6.2 1.95 1.585.504 10.15 2.25 8.564 1.796 18.25-.4 9.695-2.149 14.8-2.15 4.4 0 8.95 1.85h-.05q2.5 1.05 6.3 2.85h.05q5.2 1.8 11.15 2.1 2.45.15 4.2.05h.05l.7-.1q6.3-.15 12.95-2.25 6.85-2.25 14.05-2.25 5.9 0 10.9 2.9 5.25 3.1 14 3.1 6.65 0 19.5-3 6.45-1.55 9.95-2.3v-1.5q-3.6.75-10.3 2.35-12.6 2.95-19.15 2.95-8.25 0-13.25-2.9-5.35-3.1-11.65-3.1-7.4 0-14.5 2.3-6.45 2.05-12.6 2.2h-.1l-.7.1q-1.65.1-3.95-.05-5.7-.3-10.65-1.95-3.85-1.85-6.35-2.9-4.8-1.95-9.5-1.95-5.203-.003-15.7 2.5-10.443 2.509-25.2-1.65-2.966-.78-7.65-2.3-4.638-1.516-7.65-1.9-2.954-.395-5.5.15-.7.1-1.25.2-.05 0-.15 1.05-.05.2-.05.45h.35q.6-.1 1.4-.25z"
                    id="ObjToy076__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M72.9 29.75l2.8-26.3-.1-.1q0-.05-.05-.05l-.1-.1-7.25 1.85L.7 0Q-.1.75 0 2.55V43.6q.45 2.15 2.7 2.5l61.6 7q.95.05 1.45-.2.2-.05.3-.15l.55-.3 6.65-8.5-.35-14.2z"
                    id="ObjToy076__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M9.55 0l-3.4 1.2v36l-4.45 2V2.5l-1.5.45q.35.5.45 1.35v.35l.05 43.4Q.6 49 0 49.4l9.05-5.1q.9-.7 1.05-2.1v-9.6l-2.65 1.3v-7.65l2.65-1.2V1.3Q9.95.55 9.55 0z"
                    id="ObjToy076__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M35.9 15.9l-2.6-.3v16.55l2 .2v-.05q3.15 0 3.6-3.05v-9.4q.2-3.15-3-3.95m.1 7.6q.65 0 1.1.45.45.4.45 1.05 0 .6-.45 1.05-.45.45-1.1.45-.6 0-1.05-.45-.4-.45-.4-1.05 0-.65.4-1.05.45-.45 1.05-.45m-7.35-8.3q-3.15 0-3.55 3.1v9.4q-.25 3.15 3 3.95l4.05.35V15.5l-3.5-.3m-2.4 3.55q.4-2.2 2.5-2.15l1.95.1v13.75l-2.55-.25q-1.75-.3-1.9-2.5v-8.95m3.95 1.35q0-.6-.5-1.05-.35-.45-1.05-.45-.55 0-1 .45-.45.45-.45 1.05 0 .65.45 1.05.45.45 1 .45.7 0 1.05-.45.5-.4.5-1.05m42-13.05l3.4-1.2q-.65-.8-1.9-.95l-3-.2v.1L4.65 0 1.9 1.95q-2.05.3-1.9 3.1v.1q.45-1.8 1.9-1.85l61.95 4.5q1.7 0 2.4 1l1.5-.45v36.7l4.45-2v-36z"
                    id="ObjToy076__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M68.85 47.85V7.05q-.15-2.45-2.1-2.75L2.2 0Q-.35.15.05 2.15v7.1q.55-.05 1.3.05H2l62.35 4.35q.75.1 1.15.7.1.1.15.25l1.1 2v34.25q.3.1.7.1 1.6-.25 1.4-3.1z"
                    id="ObjToy076__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M2.25 1.65q0 .15-.05.4V11l.85 1.55V46.8q.3.1.7.1 1.6-.25 1.4-3.1V3Q5 .55 3.05.25L0 0q2.2.25 2.25 1.65z"
                    id="ObjToy076__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    d="M365 292.1v-7.25l-62.35-4.35v7.25l4.8.3q26.325-.818 52.65 3.7l4.9.35z"
                    id="ObjToy076__Layer4_0_FILL"
                />
                <path
                    fill="#666"
                    d="M360.1 291.75v-3.85l-52.65-3.65v3.8l52.65 3.7z"
                    id="ObjToy076__Layer4_1_FILL"
                />
            </defs>
            <use xlinkHref="#ObjToy076__Layer13_0_FILL" />
            <use
                xlinkHref="#ObjToy076__Symbol_140_0_Layer0_0_FILL"
                transform="translate(298.8 288.25)"
                id="ObjToy076__col1n"
            />
            <use
                xlinkHref="#ObjToy076__Symbol_94_0_Layer0_0_FILL"
                transform="translate(364.85 291.6)"
                id="ObjToy076__col1l"
            />
            <use
                xlinkHref="#ObjToy076__Symbol_65_0_Layer0_0_FILL"
                transform="translate(298.8 285.75)"
                id="ObjToy076__col1s"
            />
            <use
                xlinkHref="#ObjToy076__Symbol_52_0_Layer0_0_FILL"
                transform="translate(300.65 278.45)"
                id="ObjToy076__col2n"
            />
            <use
                xlinkHref="#ObjToy076__Symbol_16_0_Layer0_0_FILL"
                transform="translate(364.35 282.5)"
                id="ObjToy076__col2l"
            />
            <use xlinkHref="#ObjToy076__Layer4_0_FILL" />
            <use xlinkHref="#ObjToy076__Layer4_1_FILL" />
        </g></g>
    )
}

export default ObjToy076
