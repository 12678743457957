import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#666666",
    "col2l": "#333333",
    "col2n": "#000000"
}

function AccMas005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M159.7.45q0-.15.05-.25-4.2 2.85-15.4 9.85-24.2 15.05-52.65 1.05-11-8.75-24.45.35Q36.85 26.75 1.1 1 .6.5.15 0v.05q-1.3 13.8 9.15 27.55.75 1.05 1.45 2 2.85 3.7 4.4 4.9 1.5 1.15 2.75 2.35.3.3.65.6l3 3.05q1.05.8 2.1 1.55.3.25.65.5Q41.75 54.9 62.2 58.5q5.8 1 11.85 1.3 2.75.15 5.55.15h.4q2.8 0 5.55-.15 6.05-.3 11.85-1.3l1.5-.3q13.8-2.65 26.2-9.3 5.55-3.05 10.85-6.85.9-.65 1.75-1.3.2-.15.35-.25l.3-.3 3.35-3.35q1.25-1.2 2.85-2.9t5.4-6.2q1-1.25 1.9-2.45Q160.2 12.85 159.7.45z"
                    id="AccMas005__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M130.15 46.2l-2.3-1.1q-3.65-1.3-7.3 0-1.7.75-3.1 2.15-2.9 2.85-2.9 6.95 0 1.85.6 3.45 1.75 3.05 4.75 5.1.1.05.25.15.25.2.55.45.05.05.15.1l10-.9 4.2-7.9-2.4-7.05q-.55-.4-1.1-.75-.3-.15-.55-.25-.3-.2-.65-.3-.1-.05-.2-.05v-.05m57.3-39.85q.9-3.2 1.25-6.35-.95 1.3-1.85 2.6-8.2 9.7-17.55 17.15-3.55 2.6-7.45 4.85-2.7 1.55-5.6 2.95-1.1.55-2.2 1.05-.2.05-.4.15-2.15.95-4.4 1.85l-1.3.5q-.05 0-.15.05l-.8.3q-.5.15-1 .35-13.15 4.15-27.5 1.25-6-1.7-15.2-6.85-.1-.05-.25-.15-.2-.15-.8-.5-4-2.15-7.8-2.3-4 .15-8 2.3-.6.35-.8.5-.15.1-.25.15-9.2 5.15-15.2 6.85-14.35 2.9-27.5-1.25-.5-.2-1-.35l-.8-.3q-.1-.05-.15-.05l-1.3-.5q-2.25-.9-4.4-1.85-.2-.1-.4-.15-1.1-.5-2.2-1.05-2.9-1.4-5.6-2.95-3.9-2.25-7.45-4.85Q10.05 12.3 1.85 2.6.95 1.3 0 0q.35 3.15 1.25 6.35Q2.4 7.9 3.6 9.4q3.9 4.85 8.45 9 .1 3.05.55 6.1 1.2 7.3 4.65 14.55.8 1.75 1.75 3.5.05 0 .05.05.15.2.3.35l.3.4q1.85 2.2 3.85 4.3 5.95 6.55 12.6 11.75l-3-3.05q-.35-.3-.65-.6-1.25-1.2-2.5-2.5-1.6-1.85-3.1-3.85-4.2-5.55-7.5-12.7-.85-2.8-1.45-5.65-.85-4.25-1.2-8.7 4.8 3.75 10.15 6.85 2.7 1.55 5.6 2.95 1.1.55 2.2 1.05.2.05.4.15 2.15.95 4.4 1.85l1.3.5q.05 0 .15.05l.8.3q.5.15 1 .35 12.6 3.95 26.3 1.5 7.8-1.65 15-6.25l.8-.5q.05 0 .05-.05.35-.2.8-.45.2-.15.8-.5 4-2.15 8-2.3 3.8.15 7.8 2.3.6.35.8.5.45.25.8.45 0 .05.05.05l.8.5q7.2 4.6 15 6.25 13.7 2.45 26.3-1.5.5-.2 1-.35l.8-.3q.1-.05.15-.05l1.3-.5q2.25-.9 4.4-1.85.2-.1.4-.15 1.1-.5 2.2-1.05 2.9-1.4 5.6-2.95 5.35-3.1 10.15-6.85-.35 4.45-1.2 8.7-.6 2.85-1.45 5.65-3.3 7.15-7.5 12.7-1.5 2-3.1 3.85-1.25 1.3-2.5 2.5-.3.3-.65.6l-3 3.05q6.65-5.2 12.6-11.75 2-2.1 3.85-4.3l.3-.4q.15-.15.3-.35 0-.05.05-.05.95-1.75 1.75-3.5 3.45-7.25 4.65-14.55.45-3.05.55-6.1 4.55-4.15 8.45-9 1.2-1.5 2.35-3.05z"
                    id="AccMas005__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M17.05 3.15q-.1-.1-.2-.25-1-.95-2.05-1.6-.3-.15-.55-.25-.3-.2-.65-.3-.1-.05-.2-.05-.25-.15-.45-.2-.15-.05-.2-.1 0-.05-.05-.05v.1Q11.35 0 9.85 0 5.75 0 2.9 2.9 0 5.8 0 9.9q0 3.9 2.7 6.75.05.1.2.2.2.25.5.5.25.2.55.45.9.65 1.85 1.1 1.85.85 4.05.85 2.45 0 4.5-1.05 1.3-.7 2.5-1.85 2.85-2.9 2.85-6.95 0-3.95-2.65-6.75m-4.65.75l-1 2.05q-.6-.2-1.25-.2-1.2 0-2.1.55L7 4.25q1.35-.8 3.05-.8 1.25 0 2.35.45m1.7 5q-.25-1.1-1.1-1.95l1.6-1.5q1.3 1.3 1.65 3l-2.15.45m.05 1.4l2.2.2q-.25 2.1-1.75 3.65l-1.65-1.45.05-.05q1-1 1.15-2.35m-7.2-3q-.7.85-.8 2L3.8 8.9Q4 7.1 5.2 5.7l1.75 1.6M3.8 10.9l2.35-.5q.15 1.15 1 2.15L5.4 14.1q-1.3-1.4-1.6-3.2m6.25 5.25q-1.5 0-2.75-.6l1-2.15q.85.45 1.85.45.85 0 1.6-.3l1 2q-1.25.6-2.7.6z"
                    id="AccMas005__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas005__Symbol_90_0_Layer0_0_FILL"
                transform="translate(112.7 189.75)"
                id="AccMas005__col1n"
            />
            <use
                xlinkHref="#AccMas005__Symbol_22_0_Layer0_0_FILL"
                transform="translate(98.15 170.85)"
                id="AccMas005__col2n"
            />
            <use
                xlinkHref="#AccMas005__Symbol_17_0_Layer0_0_FILL"
                transform="translate(214.9 216.4)"
                id="AccMas005__col2l"
            />
        </g></g>
    )
}

export default AccMas005
