import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ObjMor077({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M16.25 32.5q6.75 0 11.45-4.75 4.8-4.75 4.8-11.5 0-6.7-4.8-11.5Q23 0 16.25 0 9.5 0 4.75 4.75 0 9.55 0 16.25 0 23 4.75 27.75t11.5 4.75z"
                    id="ObjMor077__Symbol_166_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor077__Symbol_166_0_Layer0_0_FILL"
                transform="translate(239.65 268.8)"
                id="ObjMor077__col1n"
            />
        </g></g>
    )
}

export default ObjMor077
