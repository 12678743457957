import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#000000"
}

function FacEye023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M42.45 8.3l.7.1-9.9 5.4-.2 1.95 12.3-6.95-7.8-8.3-.25.05-.7.3L41 6.4Q25-2.35 5.75.9q-.85.15-1.7.35L0 4.2Q22.7.25 42.45 8.3M106 4.2l-4.05-2.95q-.85-.2-1.7-.35Q81-2.35 65 6.4L69.4.85l-.7-.3-.25-.05-7.8 8.3 12.3 6.95-.2-1.95-9.9-5.4.7-.1Q83.3.25 106 4.2z"
                    id="FacEye023__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 0l.75 7.2 46 17.25L0 0m112.05 7.2l.75-7.2-46.75 24.45 46-17.25z"
                    id="FacEye023__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye023__Symbol_67_0_Layer0_0_FILL"
                transform="translate(139.3 159.5)"
                id="FacEye023__col1n"
            />
            <use
                xlinkHref="#FacEye023__Symbol_42_0_Layer0_0_FILL"
                transform="translate(135.9 132.25)"
                id="FacEye023__col2n"
            />
        </g></g>
    )
}

export default FacEye023
