import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}

function ObjFoo026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.75 9.75q-3.35-.6-4.05-4.35-2.2-1.9-4.65-3.15-3.5.6-6.8-2.1Q17.9-.15 15.3.1q-.4 0-.85.05-3.2 1.8-5.1 2.5-1.45.5-2.15.45-2.4 1.7-3.95 4.1.65 2.8-.8 4.15Q.95 12.7.75 13.2-.1 16.35 0 19.4q2.1 3.1 1.3 6.1.45 1.15 1.1 2.3l2.25 3.3q3.95.95 4.05 5.9l4 5.8q4.35 2.1 4.05 5.9L20.4 54q3-.2 6.4.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.15.6-.3 1.15-.05.25-.05.55h-.05l.05.05q1.25 2 .55.8l3.65 5.25q5 1.3 3.7 5.4l2.6 3.75.05.05q.65.95 1.8 1.4 1.5.55 2.95-.05 1.5-.65 2.1-2.15.2-.5.25-1 0-.45-.05-.9v-.1q-.25-.2-.45-.35-.8-.6-1.4-1.15-.8-.8-1.2-1.5-.85-1.35-.15-2.45l-4.1-5.95q-3.6-2.7-3.9-5.7l-3.85-5.65q-3.95-2.65-4.1-6l-3.85-5.65q-5.6-3.15-4.3-6.3L19 37.85q-4.6-3.15-4-5.8l-4-5.85q-3.4-2.15-2.1-5.35-.2-1.4-.1-3 0-.25.05-.55.05-.95.35-1.9-.45-.75-.4-1.35.05-.6 1.2-.65 1.25-2.35 3.55-3.7.35-.85.9-1.1.55-.3 1.2.1 2.35-.75 4.7-.3 1.45-.7 2.05-.15.6.55.95 1.2 1 .5 1.75 1.2h-.05q.95.5 2.4 2.2 1.4.2 1 1.2 1.45 1.75 3.25 4.35l5.05.35q.9-1.35 2.25-3.9l.2-.4q-.2-.35-.4-.65l-2.55-3.35-.5-.7z"
                    id="ObjFoo026__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M50.05 83.35q-.15-.35-.4-.7v-.05l-2.5-3.7q-2.05-.6-3.95-.85-3.3-.5-6.25-.1l3.7 5.4q4.35-.5 9.7 1.1-.05-.55-.3-1.1m-16.2-17.4q-.15-.05-.3-.05 0 1.1-.1 2.2-.1.75-.25 1.5l-.1.55q-.2.8-.45 1.55l.1.15q2.75-.25 5.8.15 2.15.3 4.5.95l-3.9-5.7q-2.9-.9-5.3-1.3m1.45-4.35l-4.1-6q-2.55-.65-4.75-.95.15.05.35.1 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.85 1.3 1.4 2.75 1.35.25 2.8.65M15 31.9q-5.9-2.6-10.35-.95l4.05 5.9q3.7-1.75 10.3.85l-4-5.8m-2.3 10.75l4.05 5.9q1.65-.2 3.6-.05 3.15.2 7 1.45l-4.3-6.3q-5.95-2.5-10.35-1M8.9 20.7Q4.45 17.8 0 19.25q.1 3.2 1.3 6.1 3.4-1.85 9.9 1l-.2-.3Q9.3 23.9 8.9 20.7M3.25 7.05q-1.5 2.3-2.25 5.1-.1.4-.25.9 3.85-.85 8.45 2.2.1-.45.25-.85.25-.6.55-1.15-2.05-4.1-6.75-6.2m12.4 1.5Q16.1 4.3 14.45 0q-1.6.2-3.4.65-2.15.95-3.85 2.3 3.5 1.9 6.35 6.6.55-.35 1.1-.6.55-.2 1-.4M39.2 14.3q-2.15 4.35-7.5 3.95l.9 1.2q3.45 3.7 6.8.9 2-2.45-.2-6.05M20.25 0q1.05 3.45.1 8.25.9.2 1.8.55l1.2.5q3.3-2.85 3.7-7.2Q23.9.45 20.25 0m15.5 9.6Q33.8 7.1 31.7 5.25q-.5 5-4.25 7.45.45.55 1 1.2 4.85.25 7.3-4.3z"
                    id="ObjFoo026__Symbol_92_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo026__Symbol_93_0_Layer0_0_FILL"
                transform="translate(91.95 232.3)"
                id="ObjFoo026__col1n"
            />
            <use
                xlinkHref="#ObjFoo026__Symbol_92_0_Layer0_0_FILL"
                transform="translate(91.95 232.45)"
                id="ObjFoo026__col2n"
            />
        </g></g>
    )
}

export default ObjFoo026
