import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col2n": "#FFFF9C"
}

function ObjMor069({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.7 19.7l8.5-11.8-13.7 4.3L14 0l-.6 14.9L0 19.25l13.55 4.8V38.8l8.8-11.7 13.7 4.85L27.7 19.7z"
                    id="ObjMor069__Symbol_160_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.2 40.05q4 4.2 4.55 7.1.75 3.7.5 11.5 0 1.4-.25 2.55l1.4 4.35 4.25-1.4L4.35 1l-2.8-1L0 2.1l11.35 35.3q1.25 1 2.85 2.65z"
                    id="ObjMor069__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor069__Symbol_160_0_Layer0_0_FILL"
                transform="translate(85 221.9)"
                id="ObjMor069__col1n"
            />
            <use
                xlinkHref="#ObjMor069__Symbol_46_0_Layer0_0_FILL"
                transform="translate(105.8 249)"
                id="ObjMor069__col2n"
            />
        </g></g>
    )
}

export default ObjMor069
