import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function ObjToo035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.8 23.95q1.45-.85 2.85-1.75Q44 13.55 43.05 5.4 35.75.1 28.75 0q-12.3.5-21.3 7.75-7.8 6.4-7.45 15.5l.2.3q3.4 5.35 14.1 5.3 1.35-.1 2.75-.3 1.05-.15 2.1-.35 2.55-.55 5.15-1.55 2.75-1.1 5.5-2.7z"
                    id="ObjToo035__Symbol_195_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M10.25.6l.35-.35Q10.5.2 10.05.1 9.6-.05 8.9 0q-.7 0-2 .45-1.3.4-1.6.55Q.95 3.05.05 8.75q-.15 1.6.2 3.55 0 .15.05.35 1.95.9 5.3.7.35-9.5 4.65-12.75z"
                    id="ObjToo035__Symbol_154_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.8.7L0 0l80.9 124.45q1.05 1.1 2.95.2l.15-.1q.05-.05.15-.05 1.45-.85 2.6-2.15.05-.05.05-.1.7-1.8-.55-4.15L.8.7z"
                    id="ObjToo035__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M62.55 89.2q.7-1.8-.55-4.15L0 0l38.75 55.7 23.8 33.5z"
                    id="ObjToo035__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.251}
                    d="M118.35 285.55q.717-1.814-.6-4.2l-61.95-85 38.7 55.7 23.85 33.5z"
                    id="ObjToo035__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo035__Symbol_195_0_Layer0_0_FILL"
                transform="translate(95.75 274.65)"
                id="ObjToo035__col1n"
            />
            <use
                xlinkHref="#ObjToo035__Symbol_154_0_Layer0_0_FILL"
                transform="translate(103 287.85)"
                id="ObjToo035__col1l"
            />
            <use
                xlinkHref="#ObjToo035__Symbol_75_0_Layer0_0_FILL"
                transform="translate(31.55 163.3)"
                id="ObjToo035__col2n"
            />
            <use
                xlinkHref="#ObjToo035__Symbol_36_0_Layer0_0_FILL"
                transform="translate(55.8 196.35)"
                id="ObjToo035__col2l"
            />
            <use xlinkHref="#ObjToo035__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo035
