import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#DE5500",
    "col1n": "#FF7B00"
}
function FacNos012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.95 31.15q.45-1.4-2.8-6.5-1.3-2.05-3.2-4.7-3.2-4.6-7.8-8.9-1.1-1-2.25-2L26 7.5q-4.2-3.55-7.35-5.4Q16.6.65 14.15.2q-1.1-.2-2.3-.2-4.9 0-8.45 3.45-2.25 2.3-3 5.25-.4 1.5-.4 3.15 0 4.95 3.4 8.4 1.55 1.55 3.45 2.45.05 0 .05.05 4.05 2.15 7.9 3.75 8.9 3.75 16.45 4.7 11.2 1.45 12.7-.05z"
                    id="FacNos012__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M43.95 23.7q.45-1.4-2.8-6.5Q44 22 36.2 20.1q-6.65-1.6-12.15-3.5.45-1.8 2.25-3.75 1.8-2 3.65-3.15-3.3-.3-5.75 2.05-2.5 2.3-2.8 3.85-3.2-1.2-5.95-2.55-1.65-.85-4.8-2.65 1.7-6.45 9-10.25Q18-.15 16.8.1q-.45.05-.8.2Q10.1 2 7.75 8.5q-.45-.35-.85-.65-4-3.05-6.5-6.6Q0 2.75 0 4.4q0 4.95 3.4 8.4 1.55 1.55 3.45 2.45.05 0 .05.05 4.05 2.15 7.9 3.75 8.9 3.75 16.45 4.7 11.2 1.45 12.7-.05z"
                    id="FacNos012__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos012__Symbol_21_0_Layer0_0_FILL"
                transform="translate(180.65 183.9)"
                id="FacNos012__col1n"
            />
            <use
                xlinkHref="#FacNos012__Symbol_22_0_Layer0_0_FILL"
                transform="translate(180.65 191.35)"
                id="FacNos012__col1d"
            />
        </g></g>
    )
}
export default FacNos012
