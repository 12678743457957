import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M212.4 225q-3.012-1.265-6.2-2.2-3.687-1.04-7.55-1.7-3.862-.652-7.9-.9-3.837-.253-7.85-.05-3.55.098-7.3.55-.205.036-.4.2-.15.154-.2.35-.033.245.05.45 1.256 2.613 1.35 5.2.009.23.1.4.14.168.3.25.205.081.4.05 3.187-.5 6.3-.85 3.662-.4 7.3-.6 3.875-.25 7.75-.3 3.737-.05 7.45.1 3.062.15 6.1.4.249.024.45-.15.212-.126.25-.4.09-.235-.05-.5-.09-.203-.35-.3z"
                    id="FacMou016__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M6.25 3.95L5.9.1Q2.95.2 0 .55q.8 2.05 1 4.1 2.65-.4 5.25-.7m7.35-.6l-.05-3.2Q10.15-.1 6.7.1l.35 3.75q3.25-.35 6.55-.5M21.4 3V1Q17.95.45 14.35.2l.05 3.1q3.5-.25 7-.3m.8 0q3.35-.05 6.7.1v-.4q-3.3-.95-6.7-1.55V3z"
                    id="FacMou016__Symbol_41_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou016__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou016__Symbol_41_0_Layer0_0_FILL"
                transform="translate(176.75 221.45)"
                id="FacMou016__col1n"
            />
        </g></g>
    )
}

export default FacMou016
