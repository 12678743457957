import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D9D9D9"
}

function AccMor035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 13.15q7.65-5.85 15-3.3 3.1.9 6.2 3.65.7.65 1.45 1.4l1.75-8.7L2.35 0Q.45 6.65 0 13.15m166.5.4q-.9-6.8-2.85-13.5L141.9 6.2l1.6 7.85v.2l.05.2q3.65-3.7 7.35-4.8 7.35-2.55 15.6 3.9z"
                    id="AccMor035__Symbol_133_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor035__Symbol_133_0_Layer0_0_FILL"
                transform="translate(109.3 271.65)"
                id="AccMor035__col1n"
            />
        </g></g>
    )
}

export default AccMor035
