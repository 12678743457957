import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo019({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#F1C685"
                    d="M144.65 289v4.3q48.673 3.48 96.75 0V289h-96.75z"
                    id="ObjFoo019__Layer7_2_FILL"
                />
                <path
                    fill="#E4E7E7"
                    d="M104.75 293.3q-.06 2.917 4.45 3.25h8.1q1.604-.35 3.4-.35 1.796 0 3.35.35H260.9q1.604-.35 3.4-.35 1.796 0 3.35.35h6.45q5.31-.705 5.1-3.25H104.75z"
                    id="ObjFoo019__Layer7_3_FILL"
                />
                <path
                    fill="#FFC4F3"
                    d="M145.25 285.1q-.35.939-.45 1.2h95.05q-.25-.85-.35-2.1v-2.95h-93.35q-.1.247-.35 1.55-.2 1.358-.55 2.3z"
                    id="ObjFoo019__Layer7_4_FILL"
                />
                <path
                    fill="#F1C685"
                    d="M151.85 276h-.25q-.56.137-1.8.35-1.246.204-2.65 2.6h92.1q-.85-4.1-1.1-4.15-.85.1-1.95.2-3.35 1.5-11.8 1.65-9 1.7-11.25.05-.15-.15-1.25-1.45-.6-.75-1.75-.75-1.8-.05-2.15.45-.2.65-.65 1-1.9 1.3-15.7.45-8.55-.55-10.2.8-1.85 1.35-4.05 1.35-7.35-1.6-8.65-1.7L151.9 276h-.05z"
                    id="ObjFoo019__Layer7_5_FILL"
                />
                <path
                    fill="#FD9BAF"
                    d="M219.65 266.45q-1.6 0-1.6 2.4 0 .7.2 1.25.4 1.15 1.4 1.15 1.5-.05 1.5-2.35-.15-2.45-1.5-2.45m-38 0q-1.6 0-1.6 2.65 0 2.5 1.6 2.5 1.5 0 1.5-2.6-.1-2.55-1.5-2.55m16.9 2.55q.027.575.2 1.05.4 1.15 1.4 1.15 1.362 0 1.5-1.9v-.1-.3q-.15-2.45-1.5-2.45-1.6 0-1.6 2.4v.15m-37.15-2.95q-1.6 0-1.6 2.4 0 .05.2 1.25.4 1.15 1.4 1.15 1.5 0 1.5-2.3-.1-2.5-1.5-2.5z"
                    id="ObjFoo019__Layer7_6_FILL"
                />
                <path
                    fill="#F11"
                    d="M176.65 239.2v15.05q6.074 1.888 11.75 0v-14.9q-.055-.121-.2-.25.019.062 0 .1-.345.348-1.8.2-1.416-.095-3.85.05-2.434.144-4 .15-1.555.002-1.85-.35l-.05-.05m38.05.05q-1.5.35-1.8 0l-.05-.05v15.05q5.875 1.774 11.75 0V239.2q-.05-.11-.15-.25v.2q-.05 0-.05.05-.35.35-1.9.05-1.5-.3-3.9-.3t-3.9.3m-8.15-.05q-.35.35-1.9 0-1.5-.302-3.9-.25-2.4.051-3.9.35-1.5.3-1.8-.05-.052-.035-.1-.1-.022.059-.05.1v15q5.875 1.75 11.75 0v-14.9q-.036-.079-.1-.15m-48.25 0q-.033-.033-.05-.1l-.05.05q-.018.052-.05.1v15q5.875 1.945 11.75 0v-14.9q-.03-.064-.1-.15-.344.355-1.4.05-1.057-.245-3.6-.05-2.545.192-4.35.3-1.802.102-2.1-.25l-.05-.05z"
                    id="ObjFoo019__Layer7_7_FILL"
                />
                <path
                    d="M182.75 232.15q0 .85.2 2.25l-.2 3.45q-.05.1-.05.2-.05.3-.05.75v.35q.05.25.35.25.4 0 .55-1 .1-.15.15-.35v-.1l.05-.2q.19-1.002.1-3.35.15-3-.55-3-.55 0-.55.75m18.8-.75q-.6 0-1.25 2.25-.55 1.85-.55 2.45v.95q.05.3.2.5v.1q.006.025 0 .05.063.175.15.35.05.15.15.3.4.75.8.9.2.25.4.25.35 0 .35-.45 0-.3-.4-.9 0-.05-.05-.05v-.05q-.05-.05-.05-.1-.05-.1-.05-.25-.05-.25-.1-.6-.15-1-.15-1.5l.05-.45 1.05-3q0-.75-.55-.75m-36.85 3v-.15q.65-2.85.65-2.95 0-.75-.45-.75-.55 0-.9 2.1-.2 1.05-.25 2.15l.45 2.5.05.95q0 .05-.05.75 0 .65.35.65.4 0 .55-1.6v-.3-.1-.25l-.2-1.95-.2-1v-.05m54.35-2.3q-.6 0-.6.75t.3 2.55q0 1.05.1 1.7-.05.15-.1.45l-.05.15v.15q-.05.2-.1.45 0 .85.45.85t.55-1.2q.015-.103 0-.25.05-.247.05-.6.05-.55.05-1.45l.15-2.7q0-.85-.8-.85z"
                    id="ObjFoo019__Layer7_9_FILL"
                />
                <g id="ObjFoo019__Layer7_0_FILL">
                    <path
                        fill="#ABB0B1"
                        d="M128.2 298.3q2.279 1.507 2.85 3.5H253.9q.621-1.993 2.9-3.5 3.1-2.1 7.5-2.1t7.5 2.1l.3.2q.955-.862 2-1.95-81.304-4.107-164.9 0 1.434 1.65 2.7 2.75.559-.51 1.3-1 3.1-2.1 7.5-2.1t7.5 2.1z"
                    />
                    <path
                        fill="#FFF"
                        d="M157 254.25q-4.426-.227-4.6 4.75v12.1h-.65q-.9-.8-2.6-.8-3.55 0-5.35 4.2-1.2 2.7-1.25 5.6-.5 2.05-.5 4.35 0 3 1.6 3 .6 0 1-.85v2.4h96.75v-1.15q1.5-.05 1.5-3.2 0-7.1-1.25-10.4-1.8-4.75-6.65-4.7-3 0-4.1 1.3v-13q-.515-3.678-4.15-3.6h-2.15q-5.875 1.774-11.75 0h-6.2q-5.875 1.75-11.75 0h-6.5q-5.676 1.888-11.75 0h-6.75q-5.875 1.945-11.75 0H157z"
                    />
                </g>
                <g id="ObjFoo019__Layer7_1_FILL">
                    <path
                        fill="#F1C685"
                        d="M227.05 263.9q-1.4-3.45-1.9-3.45-1-.05-2.5 1.35-1.5 1.3-2.25 1.35-.5 0-1.5-1.95-.95-1.95-2-1.95-1.65 0-5.3 4.65h15.45m-45.85 0q-.9-.75-1.75-1.95-2.15-3.1-3.95-3.05-10.2 5-10.85 5h16.55m5.5 0h14.7q-.3-3.45-3.15-3.45-3.5-.05-9.1 2.3-1.45.65-2.45 1.15m-31.15-.15q-.05.05-.05.15h9.05q-1.8-.05-3.2-2.1-1.45-2.15-2.45-2.15-1.15 0-3.35 4.1z"
                    />
                    <path
                        fill="#FF0"
                        d="M152.4 267.6v3.5q2.55.1 17.6.9 5.2.25 5.6 1 .3.7 1.65.7.05 0 6-1.25 6.45-1.15 9.15-1 12.8.8 14.2-.45.35-.3.45-1 .4-.45 2.45-.45 1.9-.05 3.55 1.15 1.6 1.05 5.35 1.1 2.5-.05 10.85-.2.55.05 1.35-.3.15-.25.3-.45v-.65q-1.5-1.35-2.75-3.6l-.2-.4q-.5-1.3-.9-2.3H211.6l-.5.6q-.9 1.2-1.7 2.1-2.65 3.1-3.65 3.1-3.4 0-4.15-3.1-.2-.85-.2-1.9v-.8h-14.7q-2.5 1.15-2.7 1.15-1.45 0-2.8-1.15H155.5q-1 1.75-1.85 2.7-.7.75-1.25 1z"
                    />
                </g>
                <g id="ObjFoo019__Layer7_8_FILL">
                    <path
                        fill="#C00"
                        d="M176.7 239.25q.3.35 1.6.6 1.7.35 4.1.35 2.4 0 4.1-.35 1.35-.3 1.7-.65.019-.038 0-.1-.37-.377-1.5-.65-1.15-.3-2.65-.4h-.35q-.05.2-.15.35-.15 1-.55 1-.3 0-.35-.25v-.35q0-.45.05-.75h-1.75q-1.55.1-2.7.4-1.264.34-1.6.75l.05.05m29.85-.05q-.357-.45-1.6-.75-1.15-.3-2.65-.4h-.95v.05q.05 0 .05.05.4.6.4.9 0 .45-.35.45-.2 0-.4-.25-.4-.15-.8-.9-.1-.15-.15-.3h-.9q-1.55.1-2.7.4-1.18.318-1.55.7.048.065.1.1.3.35 1.6.6 1.7.35 4.1.35 2.4 0 4.1-.35 1.35-.3 1.7-.65m15.15-1.15q-.7-.1-1.45-.15h-.2q-.2.05-.35.05h-.1q-.1 1.2-.55 1.2-.45 0-.45-.85.05-.25.1-.45h-.75q-.4 0-.8.05-.75.05-1.45.15-.65.1-1.25.25-1.3.35-1.6.8v.1l.05.05q.3.35 1.6.6 1.7.35 4.1.35 2.4 0 4.1-.35 1.35-.3 1.7-.65 0-.05.05-.05v-.2q-.393-.37-1.55-.65-.55-.15-1.2-.25m-63.35 1.2q.3.35 1.6.6 1.7.35 4.1.35 2.4 0 4.1-.35 1.306-.29 1.65-.65-.017-.04-.1-.1-.387-.381-1.5-.65-1.15-.3-2.65-.4h-.45q-.15 1.6-.55 1.6-.35 0-.35-.65.05-.7.05-.75v-.2h-1.8q-1.55.1-2.7.4-1.129.304-1.5.65.017.067.05.1l.05.05z"
                    />
                    <path
                        fill="#F9CA0F"
                        d="M179.55 227.1q-.15 1.2-.15 2.55 0 3.6.95 6.15.515 1.267 1.1 1.9.144.175.3.3 0 .048.05.05h.9q-.05.3-.05.75v.35q.05.25.35.25.4 0 .55-1 .1-.15.15-.35v-.1q.103-.088.2-.2.588-.627 1.1-1.95 1-2.55 1-6.15 0-1.35-.1-2.55-.2-1.4-.6-2.55-2.6-8.3-5.2 0-.4 1.15-.55 2.55m24.45 0q-.15-1.35-.5-2.55-3.25-8.55-5.75 0-.4 1.2-.5 2.55-.1.95-.1 2 0 3.6 1 6.15.75 1.95 1.8 2.4.006.025 0 .05.063.175.15.35.05.15.15.3.4.75.8.9.2.25.4.25.35 0 .35-.45 0-.3-.4-.9 0-.05-.05-.05v-.05q-.05-.05-.05-.1-.05-.1-.05-.25 1-.45 1.8-2.45 1-2.55 1-6.15 0-1.05-.05-2m12.95 0q-.2 1.3-.2 2.75 0 3.3.85 5.7.5 1.35 1.15 2l-.05.15v.15q-.05.2-.1.45 0 .85.45.85t.55-1.2h.1q.15 0 .35-.05.151-.048.3-.15.77-.476 1.4-2.2.9-2.4.9-5.7 0-1.45-.15-2.75-.25-1.4-.7-2.55-2.1-7.5-4.2 0-.5 1.15-.65 2.55m-55.55 0q-.15 1.2-.15 2.55 0 3.45.85 5.85.614 1.671 1.4 2.2.036.027.05.05.345.2.7.2V238.25q0 .05-.05.75 0 .65.35.65.4 0 .55-1.6v-.3-.1q.7-.55 1.25-2.15.9-2.4.9-5.85 0-1.35-.1-2.55-.2-1.4-.6-2.55-1.9-8.4-4.6 0-.4 1.15-.55 2.55z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo019__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_3_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_4_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_5_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_6_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_7_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_8_FILL" />
            <use xlinkHref="#ObjFoo019__Layer7_9_FILL" />
        </g></g>
    )
}

export default ObjFoo019
