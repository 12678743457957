import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M55.1 5.25q-.75-.85-1.7-1.5-2.25-1.6-5.35-2.7-5.7-2.25-9.9.25-1.25.6-2.95 1.25-3.4 1.3-7.6 1.35-4.3 0-7.45-1.25l-.4-.2Q19 2 17.1.95 15.7 0 13.95 0q-1.4-.05-3 .6-.85.25-1.75.7-.5.25-1 .55-.1.05-.15.1Q4.9 3.85 2.8 7.2q-.35.5-.65 1.1-.15.2-.25.45-.55.95-.95 2.05l-.2.4-.4 1.1q-.2.5-.35 1.05v5h.45q.8-1.45 3.15-3.2.8-.7 1.9-1.4.2-.1.35-.2-.2 1.25-.25 2.45-.15 1.5 0 2.95 0 .7.1 1.4.1.8.3 1.65.05.2.1.45.1.45.25.95.3.95.7 1.85.7 1.65 1.85 3.15.55.8 1.3 1.55 1.65 1.8 3.95 3.35.3.2.65.4 1.85 1.2 4.15 2.3 1.75.75 3.75 1.5.9.3 1.85.6l.05-.1h.05v.1l1.85-.1v-.25q-.65-.25-1.2-.6l-1.15-.8-.2-.2q-.6-.5-1.1-1.05-.2-.3-.35-.65-.2-.55-.35-1.3-.1-.35-.15-.75l-.1-.75v-.15q-.1-1.25-.05-2.8-.05-.25 0-.5v-.1l.05.1.2.6q.3 1.05.95 2 .2.4.5.8.55.85 1.35 1.65.8.8 1.85 1.55.1.05.25.15.7.45 1.45.85.75.4 1.6.8l.5.2q.35.15.8.3.6.2 1.25.4 1.9.55 4.05.9 1.95.25 4.05.3h.6q.45 0 .95-.05-.55-.4-1.05-.8-.15-.1-.3-.25l-.5-.4q-.75-.7-1.35-1.35-.15-.1-.25-.2-1.6-1.7-2.15-3.25l-.1-.35q0-.2-.1-.3v-.1q-.15-.75 0-1.1.2-.4.55-.5h.1l6.85 5.7.25-.2q.9-1.75 2.25-5.2.7-1.85 1.55-4.15.35-.95.75-2.05 0-.15.1-.35 0-.1.05-.25v2.8q-.1 3.75-1 7.1-.35 1.45-.85 2.85-.05.05-.05.15-.1.2-.2.45.05.1.1.3 1.3-.8 2.45-1.95 0-.05.05-.1l.7-.8 1.45-1.95q.05-.2.2-.35.3-.7.65-1.4.15-.25.25-.45l.4-1q.4-1 .7-1.95.35-1.25.6-2.4.15-1.05.25-2v-.5l.1-.8q0-.8-.1-1.7v-.35q-.1-1.55-.45-3.45-.15-.8-.3-1.7.3.3.55.6 2.1 1.9 2.65 2.45.15.15.8 1.65v-.2q.1.25.2.55l.1.15q.05.2.2.45v.05q.1.25.25.65.1-.8.25-1.45.1-.75.15-1.35.25-1.55.25-2.35 0-.95-.1-1.75v-.15q-.05-.85-.15-1.55-.1-.45-.15-.85-.2-.7-.45-1.25-.1-.25-.25-.45-.3-.6-.65-1.25-.95-1.35-2.4-3.05z"
                    id="BeaChi036__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.5 4.55q.2-.1.35-.2L7.1 0Q2.05 3.3 0 8.9v.25h.45q.8-1.45 3.15-3.2.8-.7 1.9-1.4M22 23.2l-.1-.75v-.15q-.1-1.25-.05-2.8-.05-.25 0-.5v-.05l-3.1-5.45q-2.6 10.8 6.55 14.45l-1.15-.8-.2-.2q-.6-.5-1.1-1.05-.2-.3-.35-.65-.2-.55-.35-1.3-.1-.35-.15-.75m19.2 5.45q-.15-.1-.3-.25l-.5-.4q-.75-.7-1.35-1.35-.15-.1-.25-.2-1.6-1.7-2.15-3.25l-.1-.35q0-.2-.1-.3v-.1q-.15-.75 0-1.1.2-.4.55-.5h.1l6.85 5.7.25-.2-13.5-13.8-.3 2.15v.1Q30.2 26.2 42 29.25l-.8-.6m7.7-12.5v1q-.1 3.75-1 7.1-.35 1.45-.85 2.85-.05.05-.05.15-.1.2-.2.45.05.1.1.3 0-.05.05-.05 5.15-8.7 3.45-18.2l-1.5 4.7v1.7m9.5-5.1q0-.15-.05-.25v.05q0 .05.05.2M54.1 5.4q.15.15.3.35 2.1 1.9 2.65 2.45.15.15.8 1.65v-.2q.1.25.2.55l.1.15q.05.2.2.45-1.4-7.85-5.45-9.85l.95 4.2q.15.1.25.25z"
                    id="BeaChi036__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi036__Symbol_75_0_Layer0_0_FILL"
                transform="translate(163.75 235.35)"
                id="BeaChi036__col1n"
            />
            <use
                xlinkHref="#BeaChi036__Symbol_34_0_Layer0_0_FILL"
                transform="translate(163.75 244.55)"
                id="BeaChi036__col1d"
            />
        </g></g>
    )
}

export default BeaChi036
