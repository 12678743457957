import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function ShiLon035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.45 0q-.1.05-.15.15-.05.1-.15.2Q20.5 2.6 18.95 5 17.7 6.8 16.6 8.7q-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q2.55-2.2 5.9-3.1.2-.15.4-.25 1.55-.3 3.35-.35h1.25q.2 0 .45.05 1.2.05 2.45.3l.05.05q4.25.8 7.2 3.55l.45.45q.15.2.35.4l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 17.8 4.05 35.55 5.75l-.15-.15q.2.05.4.05.1-3.3.25-9.1v-2.4q.3-33.9-4.95-59.8-12.25-4-23.05-11.35-.25-.15-.5-.35L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-.35-.5-.65-1-1.7-2.55-3.55-5l-1.85 1.4q-.3.2-.55.45-1.1.8-2.25 1.65l-1.55 1.15q-.25.2-.5.35-10.8 7.35-22.95 11.3-5.2 28.8-4.8 60v2.55q.3 3.9.55 8.85.299-.05.65-.05-.05.002-.05.05 17.3-1.75 34.55-5.7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon035__Symbol_306_0_Layer0_0_FILL"
                />
                <g
                    id="ShiLon035__Symbol_241_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M5.25 35.9v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L.05 57.1v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4m110.95-.55q-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3zM.3 0Q.2.05.15.15.1.25 0 .35l6.35 17v-.1q.05.2.1.3.1.2.3.25l16.3 6.25-12 8.4L35 78.55v-2.4l-22.5-43.3 12.8-9.2-18.2-7L1.35.8.3 0m112.5 17.25v.1l7.2-17-1.45 1.05q-.3.2-.55.45l-5.95 14.8-18.2 7 12.8 9.2-20.7 43.45v2.55l22.15-46.4-12-8.4 16.3-6.25q.2-.05.3-.25.05-.1.1-.3z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon035__Symbol_306_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon035__col1n"
            />
            <use
                xlinkHref="#ShiLon035__Symbol_241_0_Layer0_0_FILL"
                transform="translate(132.15 228.6)"
                id="ShiLon035__col1s"
            />
        </g></g>
    )
}

export default ShiLon035
