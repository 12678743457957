import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400"
}

function ShiMot086({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.75 7.35q.8.65 2.3 1.65.95-.5 2.3-1.95 1.5-1.7 2.05-2.15L23 .8l-4 4.4q.45 1.05 1.75 2.15M42.1 24.3q.4 3.45-.65 5.75-1.4 3.05-4.45 4.4-2.95 1.35-5.45.05-1-.45-1.9-1.8-.9-1.25-.95-2.2 0-.55-.05-.5-.1.05-.4.7-.5 1.35-.2 3.9.4 2.55 1.4 4.1 1.1 1.8 2.55 2.65 1.45.75 3.65.75 3.95 0 6.45-3.15 2.45-3.2 2.85-8.75.35-4.45-1-8.05-1.4-3.65-4.05-5.55-1.1-.7-1.85-.95-.8-.2-2.25-.2-3.15 0-4.9 1.35-1.8 1.4-3.9 5.5-1.5 3-2.95 3.8-1.45.8-3.55-.15l-1.6-.7 1.2-.75q1-.7 2.15-2.6 1.05-1.95 1.35-3.6.326-1.739-.35-3.55 2.132-.056 4.75-.8 1.85-.75 3.75-1.95 1.85-1.25 1.85-1.8-.35-1-1.4-2.3-1.45-1.7-1.75-2.1-.05-.1-.55.25-.45.3-1.1.85-2.95 2.3-5.65 2.85-2.75.55-5.35-.75-1.55-.95-2.75-2.15-1.3-1.35-1.95-2.75-.55 0-.1 2.05.35 2.05 1.2 3.5.112.166.2.3-.662.128-1.4.3-3.65.75-6.15 2.65l-1.3 1 3.1 5.25q4.65-2.5 7.2-2.9 2.5-.35 3.75 1.1.9 1 .9 1.65 0 .6-.75 1.6-.85 1.1-2.45 1.5-1.55.5-3.75.3l-2.35-.25q.45 2.85 1.85 5.15.6 1.25 1.2 1.8 2.85-.3 4.45.2 1.65.6 2.3 2.25.9 2.1.15 4.05-.7 1.95-2.75 2.85-2.55 1.2-6.3.55-3.7-.6-5.5-2.5-1.1-1.15-2.15-3.3-1-2.2-1.7-4.65-.3-1.15-.6-1.95-.3-.85-.45-.85-.4 0-.3 2.65.1 2.55.6 4.6 1.05 4.55 3.65 7.75 2.6 3.15 6.3 4.5 1.3.45 3.4.5 2.15.05 3.6-.4 1.55-.4 3.25-1.9 1.7-1.55 2.5-3.3.95-1.95 1-4.7 0-2.8-.9-4.75l-.8-1.85h2.55q1.7 0 2.35-.2.65-.2 1.5-1.05.55-.5 1.25-1.5.75-.95 1.25-1.85 1.4-2.5 2.55-3.55 1.2-1.05 2.5-1.05.65 0 1.25.15.65.1 1 .2 1.45.85 1.9 4.3z"
                    id="ShiMot086__Symbol_47_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot086__Symbol_47_0_Layer0_0_FILL"
                transform="translate(169.3 258.6)"
                id="ShiMot086__col1n"
            />
        </g></g>
    )
}

export default ShiMot086
