import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M172.25 35.95l.35.4q-.05-.1 0-.3 0-.65.05-1.8l.15-5.15q.85 1.05 1.6 2.05.7 1 1.8 3.3-.25-3.2-.75-5.2l3.75 3.5q-1.95-7.65-5-13.5Q163.6 4.1 150.95 0q-.85.8-1.85 1.55l-2.95 1.85q-.65.35-1.35.7l-.2.1q-2.15 1.05-4.75 2-.7.2-1.45.45-3.55 1.1-7.75 1.95-2.9.6-6.1 1.05-7.1 1.25-14.2 2Q79.95 14.8 49.2 8.3q-3.85-.75-6.95-1.6-4-1.15-6.65-2.45V4.2l-.9-.45-.3-.2Q31.65 1.9 30.65 0 24.1.7 12.2 11.3 5.45 17.1 0 31.95q3.55-4.8 7.6-6.45v.2q0 .1-1 2-1.05 1.9-1.25 2.75-.15.5-.4 1.15-.05.1-.1.25l-.2.4q1.1-1.35 2.45-2 .65-.25 3.15-1.2-.1.15-.15.35v.05q0 .2-.2.45-.15.35-.3.75-.35 1.05-.45 3.3v.35h.05l2.05-1.65q.25-.2 2.85-1.75-.85 2.8 0 5.25.8-1.4 3.3-3.5l.2-.2 7.35-5.05q0 .05-.05.05-.35.35-.7.75-3.35 3.8-4.15 8.6v.75l1.6.45 1.4.35.6.1q2.8.55 9.5 1.8 1.35-1.55 2.5-2.65 1.15-1.1 3.55-2.2v6.05h.25q2.05-3.65 3.85-5.7-.2 2-.95 6.95 4.25-7.4 5.6-9.15l1.8-2.4q.4-.65 2.5-3.3l-3.3 15.65q.5.05 1.1.2l2.6.5q.2 0 .4.05 3.35.55 6.85 1.05 1.05.1 2.15.25.7.1 2.25.2.3.05.7.05h.1q.2-.5.55-1.2 2.05-4.2 3.4-6.9.05.15.1.35.05.2.15.45.1.4.2.85 1.7 5 1.7 6.85v.2l3.8.2q4.05.2 8.3.25l.25-6.65q0 .15 3 6.65V41.1q.15 0 .3.1 1.15 3.25 2.3 5.75.2-2.35.6-4.3.3-1.95.15-3.05 1.35.3 3.4 1.95 2 1.65 3.35 3.25 1.3 1.6 1.5 1.7v.1q6.8-.3 14-.9.7-.1 1.4-.15l.45-.05q.95-4.3-.1-8.25 2.45 2.35 7.65 7.1.25-9.1.25-10.3v-.25q.5 1.05 3.5 5.95 1.4 2.5 2 4.3 4.85-.6 9.9-1.35 1.25-.2 2.55-.4.45-2.75-.55-9.1l-.3-1.9q-.45-3.4.95 1.7 2.25 5.6 2.95 7.5.35 1 .45 1.35 1.95-.4 2.7-.55 1.9-.3 3.85-.65l2.35-.55q-.35-1.45-1.25-4.65-.95-3.25-1.45-4.4-.5-1.2-1.5-3.9l-1.25-2.55q-.25-.55-.5-1.05l-.05-.05v-.05q-.1-.15-.2-.45 4.05 5.2 5.85 8.45 1.8 3.25 2.7 5.5.95-3.25.95-3.4 1.8 1.25 5.25 5.1h.15q.95-4.75.95-5.1.05 0 .1.05.55.2 1.05.45l.85.5q1.7 1.2 2.2 3.05.3-.05.65-.1 1.45-5.2-4.4-13.5 5.65 6.1 7.6 8.75 1.95 2.65 2.4 3.2M145.7 22.5v.05l-.15-.3q.069.128.15.25z"
                    id="HaiSho005__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M179.2 32.75q-1.95-7.65-5-13.5Q163.6 4.1 150.95 0q-.85.8-1.85 1.55l-2.95 1.85q-.65.35-1.35.7l-.2.1q-2.15 1.05-4.75 2-.7.2-1.45.45-3.55 1.1-7.75 1.95-2.9.6-6.1 1.05-7.1 1.25-14.2 2Q79.95 14.8 49.2 8.3q-3.85-.75-6.95-1.6-4-1.15-6.65-2.45V4.2l-.9-.45-.3-.2Q31.65 1.9 30.65 0 24.1.7 12.2 11.3 5.45 17.1 0 31.95q3.55-4.8 7.6-6.45v.2q0 .1-1 2-1.05 1.9-1.25 2.75-.15.5-.4 1.15.05-.1.15-.15 6.15-14.6 24.15-25.85-5.6 4.8-9.15 10.45 3.4-2.75 5.3-4.1 4.35-3.15 7.75-4.65h.1q-3.45 4.65-5.85 9.35-1.45 2.65-2.95 6.95 3.05-3.95 8.9-7.95 5.05-3.5 8.05-4.4-.35.5-.9 1.25-1.05 1.4-1.25 1.8-1.4 2.4-1.6 3-.5 1.2-4 7.35v.15q2.3-1.8 3.8-2.7.55-.35 2.7-1.45v.65q-1.65 5.65-2.4 9.6v.15Q41 24.4 42.9 22.1q1.7-2.05 7.4-7.15l-1.65 5.95v.5q.75-.55 2.65-2.45 1.8-1.85 2.95-2.65 1.15-.8 3.15-1.9h.1q-1.3 4.25-1.75 7v3q.4-.35 3.15-3.5h.1q.05 1.05-.15 2.7-.25 1.65-1.35 12.6l7-14.15q1.25 3.75 2.9 6.85 1.55-1.15 5.75-7.15t4.5-6.2q-.25.85.3 2.7l1.7 15.3 2.6-14.05q.1-.25.15-.35 1.75 2.85 2.25 4.65.25 1.05.75 3.35.65-1 1.45-3.15.85-2.35.9-8.7L91 27.5q1.35 3.45 2.25 5.9l.15.15 1.85-11q2.45 5.2 4.75 12.6.5 1.6.9 4.25.9-4 1.55-5.9.7-2.25.3-12.7 3 8.95 3.9 12.75.85 3.75 1.35 4.85 1.25-2.45 1.75-11.95.25 1.9.4.2 1.45 2.9 2.6 5.5l.15.15q.5-4.35.5-4.95 0-3.7-.45-5.9-.2-.85-.9-7.4l6.1 14.35V17q.3-1.35.35-1.45 1.35 1.9 2.45 3.9 1.05 1.95 1.3 2.95h.1q1 2.15 2.5 5.8 1.35 3.2 1.2 3.75 1-1.65 1.3-4.25.2-2.75-.95-9.15 4.65 7.85 5.85 11 1.15 3.15 2.15 5.35h.25q0-1.8.4-3.65.35-1.85.35-2.65 0-3.3-1.55-12.25-.1-.35-.1-.6 3.95 4.95 6.9 11.05.85-3.75-1.1-10.4l4.35 5.9q-.25-5.85-2.65-10.5 1.2.65 3.35 1.85 4.9 2.9 5.8 4.4l1.6 1.85-1.5-5.5q-.1-.95-.55-2.15-.55-1.35-.55-1.45l-.15-.25q.15.1 2.05 1.35 6.2 3.75 11.85 9.65-2.75-8.25-7.55-15.15 11.05 8.5 17.2 23.15v-.45q.85 1.05 1.6 2.05.7 1 1.8 3.3-.25-3.2-.75-5.2l3.75 3.5M133.05 14.9q.05.05.1.15.05.45.1 1-.15-.7-.2-1.15z"
                    id="HaiSho005__Symbol_64_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho005__Symbol_126_0_Layer0_0_FILL"
                transform="translate(101.6 79.05)"
                id="HaiSho005__col1n"
            />
            <use
                xlinkHref="#HaiSho005__Symbol_64_0_Layer0_0_FILL"
                transform="translate(101.6 79.05)"
                id="HaiSho005__col1d"
            />
        </g></g>
    )
}

export default HaiSho005
