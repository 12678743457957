import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#794D32",
    "col2d": "#D19532",
    "col2n": "#E4B670",
    "col2s": "#957032"
}

function ObjMor027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.05 103.3q-.3-.25-.55-.5-.95-.9-1.45-1.95.25 2 .4 5.2.1 3.15-.75 5.6-.95 2.45-3.4 4.25l3.2 23.2 7.3-1-4.75-34.8M4.45.65Q1.95-1.55 0 3.95L12 91.6q2.1.35 3.65 1.9 1.5 1.5 2 2.45.3.65.5 1.4h.1q-.05-2.5 1.4-4.45L7.3 2.95Q6.95 2.8 4.45.65z"
                    id="ObjMor027__Symbol_135_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M53 8.4q0-.9.2.75l-3.45-4.2Q48 2.85 47.2 1.5L33.8 2.85q2.4 7.25 5.2 14.6.25.65.55 1.35l.3.8-.35-.75v-.05q-.4-.25-2.85-3.95-5.95-9-8.45-11.45v-.05h-.35v.05q-.2 1 .2 3.95.2 1.7.3 2.15.2.75.3 2.25Q27.4 10.3 26.2 8.6q-1.85-2.75-2.5-4.75l-.05-.05-.7.1q-4.5.45-8.95.9h-.25L11 5.15q.75 5.55.9 7.8Q9.4 9.9 6.4 5.1q1.75 7.75 2.25 10.65.55 1.45 1.5 4.05-.7-1.35-1.25-2.4-.25-.4-.4-.75-1.85-3.3-2.55-4.3-.6-.8-1.5-2.25Q3.6 8.75.5 6.85q-.05-.1-.15-.2 0 .05.05.15-.2-.15-.4-.25.25.2.45.3l.05.05Q1 8 1 8.2 6.55 19.7 10.95 32l.1.2q3.4 9.5 6.1 19.55 1.8 6.65 3.35 13.6 1.35 4.9 3.55 8.7 7.05 12.4 22.2 13.15l.4 2.95q-3.6 5.4-.4 10.2.6.9 1.4 1.8.65.65 1.5 1.35l.05.05.6.2.8.2q.45.1.95.2 3.5.65 6.7-.75.3-.15.6-.25 1-.55 2.05-1.25.5-.8.9-1.55l.3-.6q1.5-8.5-3.45-11.15l-.6-3.05q2.25-.6 3.2-1.1Q69.5 80 72.9 69.65q1.2-3.9 1.8-8.55.95-10.65.7-19.35-.05-.6-.05-1.1v-.35Q75.15 13.6 63.2 0l-2.55.1v.05l-4.25.6q3.8 6.15 5.05 9.85-1.6-1.85-4.4-4.9-3.1-3.3-4.45-4.75L53 8.4m10.75-1.7q.05.1.15.25l-.75-.85q-.05-.15.6.6m-24.1 73.2l.8.8-.5-.25q-.15-.25-.3-.55m20.3-18.4q.05.2.05.45h-.05v-.45m-16.4 15.95q-2.74-3.787.05-.05l-.05.05z"
                    id="ObjMor027__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M62.7 0q3.8 6.6 6 13.8 3.65 11.8 4.15 25.4v2.55q-.05 3.55-.05 7.55v1.25q-.25 5.7-1 10.5-1.1 6.7-3.1 11.65.2-3.55.2-11V51.1q0-4.35-.75-9.35-1.95-12.95-8.9-30.35 5.65 16.3 6.95 30.35.45 5.55.3 10.75-.6 15.55-3.85 22.2.6-15.3 1.15-24.4.15-3.35-.6-8.55-1.35-9.05-5.5-23.6 1.95 14.8 2.6 23.6.5 6.75.25 9.9-.6 7.2-1.1 9.85.05.2.05.45h-.05v-.45q-.65-5.65-2.9-11.95-.35-.85-2.45-7.8-1.35-4.35-3.35-11 1.55 5.85 2.6 11 2.25 10.8 2.25 18.4 0 .45-.15 8.7l-.5-2.3q-1.5-5-2.55-7.55-1.05-2.6-1.7-10.25-.35-3.4-1.25-7-1.2-4.65-3.4-9.65.8 3.35 1.5 9.65 1.35 12.1 2.5 35.15-3.15-10.05-6.15-19.45-1.1-3.4-3.65-15.7-1-5.05-2.25-11.5.85 6.1 1.6 11.5 3.3 25 3.5 34.8L22.35 24.6 33.5 64.8q1.8 6.5 3.35 10.1 1.55 3.55 2.3 5l.8.8-.5-.25q-.15-.25-.3-.55-5.6-5.75-12.6-25.1L14 17.85q6.5 25.3 9.55 37.1 2.3 8.9 2.65 10.1.85 2.95 3.05 8.15 2.1 5.2 2.1 6.2-5.05-8.3-7.8-14.65-2.45-5.6-3.1-9.7Q16.95 36.6 0 6.9.5 8 .5 8.2 6.05 19.7 10.45 32l.1.2q3.4 9.5 6.1 19.55 1.8 6.65 3.35 13.6 1.35 4.9 3.55 8.7 7.05 12.4 22.2 13.15l.4 2.95q-3.6 5.4-.4 10.2.6.9 1.4 1.8-.25-.95-.45-1.8-.3-1.2-.5-2.35-.35-2 1.3-6.35.25 2.4 3.15 7.3 0-6.45.3-9.5l1.35-.15h.05q.85 3.25 3.5 9.4v-9.1q.85.85 1.35 1.4.55.3 2.7 3.7 1.45 2.5 1.45 3.9.05.45.05.75l.2.4q1.5-8.5-3.45-11.15l-.6-3.05q2.25-.6 3.2-1.1Q69 80 72.4 69.65q1.2-3.9 1.8-8.55.95-10.65.7-19.35-.05-.6-.05-1.1v-.35Q74.65 13.6 62.7 0M43.1 77.4l-.05.05q-2.74-3.787.05-.05z"
                    id="ObjMor027__Symbol_9_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M6.6 3.75l5.8-.7L11.8 0 0 1.65.4 4.6l6.15-.85h.05z"
                    id="ObjMor027__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor027__Symbol_135_0_Layer0_0_FILL"
                transform="translate(106.25 194.05)"
                id="ObjMor027__col1n"
            />
            <use
                xlinkHref="#ObjMor027__Symbol_36_0_Layer0_0_FILL"
                transform="translate(54.7 93.85)"
                id="ObjMor027__col2n"
            />
            <use
                xlinkHref="#ObjMor027__Symbol_9_0_Layer0_0_FILL"
                transform="translate(55.2 93.85)"
                id="ObjMor027__col2d"
            />
            <use
                xlinkHref="#ObjMor027__Symbol_4_0_Layer0_0_FILL"
                transform="translate(100.95 179.4)"
                id="ObjMor027__col2s"
            />
        </g></g>
    )
}

export default ObjMor027
