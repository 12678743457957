import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M168.85 38.85q-2.05-2.2-4.05-4.25l-41.9-22.95h-.15q.05-.35.15-.7.25-3.25.25-3.4 0-1.15-.05-2.1-.1-1.6-.35-2.5-.05-.5-.1-.9-.05-.1-.05-.2-.1-.45-.2-.8l-.1-.2q-.1-.15-.15-.25-.05-.05-.15-.1-.95-1.15-2.85.35-.1.05-.2.15-.35.45-.75.85-1.8 2-3.7 3.6l-.35.35-.15.1-11.3 5.95q-4.2.4-5.1.4L73 11.4v.05q-9.3 1.2-14.9 2.2-.95.2-1.85.4-.4.05-.45.1-1.4.55-3.85.9-.1 0-.2.05l-1.9.5q-.5.2-.95.5-1 .6-2.1 1.8-1.15 1.3-1.95 1.85-2.6 1.4-4 2.15-.85.65-1.2 1.15-1 1.4-3.35 4.65-2.4 3.25-3 4.35-.75 1.5-2.4 3.25l-.55.55q-1.35 1.35-4 3.6l-.2.1q-.8.3-1.45.5-1.95.4-2.75 2.5-1.35 3.7-1.55 3.95-2.9 4.65-4.35 6.95-1.95 2.95-2.3 3.5-1.05 1.7-1.85 3.6-.6 1.25-.9 4-.2 1.4-.3 2.45-1.4.95-2.45 1.65Q7.7 69 3.9 70.8q-2.15.85-2.85 1.15-1.05.6-1.05 1.7 0 2.9 1.5 6.1 1.1 2.25 1.2 3.8.15.45 2.6.45.1 0 2-.2 1.35 0 2.45.3.4.1 1 .35.75.3.85.5Q31.85 65 53.7 54.15q1.9-.9 2.35-1 .35-.05 2.75-.5 2.4-.5 3.75-.8 1.35-.4 3.2-2.4 1.1-1.15 1.55-2h2.8q.25 2.75 1.3 10.7 1.4 10.7 1.5 15.45v1.25q.4.3 1.15.3 1.6 0 5.65-1.4 1.45-.5 3.25-1.2 3.35-1.35 6.45-2.7-9.6 4.45 1.55-.65 2.3-1.1 5.55-2.55 4.65-2.1 4.65-3.9 0-1.45 1.4-2.7.85-.75 3.15-2.8.5-.55.95-1.05.25-.25.45-.5.1 0 .2.05.55.15 1.3.35l1.6.45q2.2.55 3.4.8.5.95.7 1.35.9 1.45 3.25 3.95 1.1 1.15 1.75 1.8.05.1.15.25.05 0 .05.05 4.75 1.45 8.4 0h.1q.25-.1.45-.25.3-.2.45-.5l.6-1.75q.2-.55.4-1.15 2.35-1 7.1-3.25.25-.1 2.85-1.05.05 0 .15-.05 1.65 1.4 4.65 3.15.55.3 1.25.65 1.45.7 3.5 1.45 4.1 1.4 5.1 1.8 5.8 2.45 10.25 3.9 3.5 1.15 6.95 2 .65.15 1.25.3 2 .45 4 .8h3.45q1.75 2.35 3.5 4.25l.15.15 3.15 3.05q.25.25.5.45 1.278 1.068 2.6 1.9 1.25.8 2.5 1.4l-.4 2.7 5.45 3.95q-.2-1.75-.5-3.5-2.55-9.55-7.05-17.9-1.9-3.5-4.1-6.8-2.2-3.2-4.65-6.15-4.3-5.8-8.6-10.8-.65-.75-1.25-1.45-1.4-1.65-2.85-3.2z"
                    id="HaiSho030__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M16.45 34.85l-.2.1q-.8.3-1.45.5-1.95.4-2.75 2.5-1.35 3.7-1.55 3.95-2.9 4.65-4.35 6.95-1.95 2.95-2.3 3.5-1.05 1.7-1.85 3.6-.6 1.25-.9 4-.2 1.4-.3 2.45-.4.3-.8.55l4.8-2.25q3.1-1.5 5.65-2.55-.55-1.4-.55-2.7 0-4.35 3.6-8.3 3.6-3.95 3.6-5.05 0-.7-.7-1.55-.7-.9-.7-1.95 0-1.55 1.15-2.3 1.95-1.25 2.2-1.55 1.05-1.25 1.75-3.35.15-.45.4-.9l-.2.2-.55.55q-1.35 1.35-4 3.6m41.2 8h2.55q.25 2.75 1.3 10.7Q62.9 64.25 63 69v1.25q.4.3 1.15.3 1.6 0 5.65-1.4h-.75q-2.25 0-3.4-3.5-.85-2.65-.85-6.1 0-2 .55-5.6.05-.25.1-.55 1.4-3.65 1.6-4.3.65-1.1 1.15-2 .95-1.7.95-2.45 0-1.8-1.8-2.25-1.8-.4-1.8-1.35 0-.45 2.55-5.55v-1.4q-2.6 1.35-6.75 3.35-2.4 1.6-3.7 5.4m41.15 5.1l-.2.4q-.5.6-1.55 2.65l-.3.6q.25-.25.45-.5.1 0 .2.05.55.15 1.3.35l1.6.45q2.2.55 3.4.8.5.95.7 1.35.9 1.45 3.25 3.95 1.1 1.15 1.75 1.8.05.1.15.25.05 0 .05.05 4.75 1.45 8.4 0h.1q.25-.1.45-.25-3.8-.6-6.1-1.55-1.4-.6-2.35-1.35-.9-1.15-1.6-2.75-.25-2.35-.45-3.5-.35-2.15-2.3-2.05-.9.05-3.3-1.45-2.2-1.55-2.3-1.55-.2 0-1.35 2.25m5.45-46.75l-.15.1-11.3 5.95q-4.2.4-5.1.4L63.1 6.8v.05q-9.3 1.2-14.9 2.2-.95.2-1.85.4-.4.05-.45.1-1.4.55-3.85.9-.1 0-.2.05l-1.9.5q-.5.2-.95.5-1 .6-2.1 1.8-1.15 1.3-1.95 1.85-.4.2-.7.4 2.7-1.05 4-1.05.7 0 2.6.25 1.95.2 2.6.2 1.95-1.15 5.5-2.3 6.75-2.1 20.45-2.25h1.65q.05 0 6.35.2 1.6.05 3.65.1 2.1.05 4.6.15h.75q11.35-.2 16.15-5.9 1.15-1.4 2.6-4.1.2-.4.45-.85-.5.45-.95.85-.25.2-.4.35m26.4 15.55q1.95 1.1 6.15 4.2 4.6 3.35 5.7 4.05 1.4.85 3.45 1.45 2.35.75 3.3 1.2 1.05.5 3.55 1.55 1.7.8 2.7 1.45-.3-.3-.6-.65L113 7.05q-.05 1.6-.6 4.65v1.85q7.45-.6 11.6.3 1.7.4 3.85 1.4.9.45 1.9 1l.9.5m41 44.75h-.75q-2.35.65-2.65.7-.2.05-1.05.05h-.8q-1.15 0-3.35-.4-.6-.15-1.25-.3-4.8-1.1-13.4-3.8-2.95-1.8-5.9-3.45-4.2-2.4-6.5-3.05-.9-.3-1.55-.3-2.7 0-4.5 1.25.05 0 .15-.05 1.65 1.4 4.65 3.15.55.3 1.25.65 1.45.7 3.5 1.45 4.1 1.4 5.1 1.8 5.8 2.45 10.25 3.9 3.5 1.15 6.95 2 .65.15 1.25.3 2 .45 4 .8h3.45q1.75 2.35 3.5 4.25l.15.15 3.15 3.05q.25.25.5.45l-.05-.05q-1.2-1.4-2.2-3.2-.85-1.45-3.9-9.35z"
                    id="HaiSho030__Symbol_75_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho030__Symbol_151_0_Layer0_0_FILL"
                transform="translate(94.5 45.55)"
                id="HaiSho030__col1n"
            />
            <use
                xlinkHref="#HaiSho030__Symbol_75_0_Layer0_0_FILL"
                transform="translate(104.4 50.15)"
                id="HaiSho030__col1d"
            />
        </g></g>
    )
}

export default HaiSho030
