import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FF008C"
}
function HaiHig019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.7 3.7q0-1.1-.5-2.5-.1-.2-.15-.35Q22 .7 21.9.45q-.1-.2-.2-.45-1.45 5.2-3.15 9.65-.1.2-.15.45-.35.8-.65 1.55l-.1.2q-.3.75-.65 1.5-.05.15-.15.3-.25.6-.55 1.25l-.1.2q-.25.6-.55 1.15-2.85 5.8-6.55 11.3-.35.45-.65.95-.25.3-.5.65l-2.2 3Q3.4 35.2.75 38.1q-.35.4-.65.75-.1.05-.1.1l.3.4q.2.35.35.75.2.4.5.85.45.65 1.05 1.35-.2 0-.3.1-.3.2-.5.4-.1.05-.15.1Q.2 43.6.2 45.45q0 1.1.25 2.15.2.75.9 3.25.3 1.15 1.3 2.8.45.8.8 1.35.35.6.5.95-.35.1-.75.35-1.45.8-2.1 1.95-2.35 4.2 4.9 14.4.25.35.55.8.15.2 1.2 1.85-.4 1.7-1.85 2.65-.2.15-.35.3-4.6 5.1-3.3 12.15 1.4 7.7 5.35 13.15v-2.2q.3-1.8.9-5.2.9 3.25 1.8 4.95.85 1.7 2.35 4 1.45 2.3 3.8 4.95 1.25 1.35 2.6 2.9v-.1q.65-4.35.8-5.2.65-3.25 2.05-6.6.15-.4.35-.8.65-1.55 1.55-3.45.5-1.05.85-1.8.8-1.75.95-2.05.15-.4.3-.85 1.55-7.15-1.25-11.95-.55-.9-1.2-1.75-2.45-2.5-2.7-3.45.55-1.35.8-1.5 0-.05.05-.1l.2-.25q.4-.5.8-1.15.7-1.15 1.65-3 1.15-1.9 2.15-5.3 1.1-3.5 1.1-5.5 0-1.9-.8-3.55-1-2-2.65-2.05h.15q0-.05-.35 0v-.05l.65-1.15q2.15-4.5.35-12.05v-.1q-.15-.7-.35-1.3-1-2.95-3.4-3.15h-.05l.65-1.4q1.95-4.25 2.4-7.75.75-4.85-.8-6.75-1.8-2.15-2.2-2.4-.4-.3-.5-.4.3-.5.5-1 .8-1.95 1.4-3.8.2-.6.4-2.1.15-1.45.15-2.3 0-1.8-.25-2.75l-.1-.4z"
                    id="HaiHig019__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M7.95 13.2q-.35.45-.65.95 1.8 1.55 3.15 3.45 1.25 1.75 2.45 4.4l-.1.2q-.65.65-1.2 1.1-.05 0-.05.05l-.75 1.05q-.3.35-.5.6l-.2.35q-1.1 1.6-1.4 3.1l-.5-.3Q3.2 25.7 0 26.6q.45.65 1.05 1.35.05-.05.1-.05v-.1h.35q.25 0 .55.05.2.05.45.05.2.05.4.05.65.05 1.25.2.9.15 1.75.5.8.3 1.45.75.15.1.4.3.55.35 1.1.8 1 .75 2.1 1.75 4 3.6 5.1 7.25v.05l-.4.25v.05q-3.3 2.5-3.8 5.05-.3-.5-.5-.85-.65-1.05-2.8-2.4l-2.7-1.5q-.1-.05-.2-.05h-.2q-.25 0-1.25-.1-.6 0-1.4.35l-.5.3q.35.6.5.95.75-.35 1.3-.35h.65q.8.25 1.5.6 1.3.6 2.45 1.5.25.15.45.35.85.7 1.6 1.6.35.55 2.85 4.9.1.2.2.35.2.25.4.55 3 4.95.5 10.4-.25.75-1.35 3.4l-.5.9q-.6.95-1.2 1.85-.2.35-.3.75-.2.25-.25.35-1.6 2.6-1.6 6.45 0 3.1 2.95 7.85 1.55 2.5 3.2 6.25-.3-2.55-.4-5.85-.1-3.35.4-5.3.5-1.95 1-4.1.45.7 1.05 2.2.75 1.9.55 2.65 1.65-2.65 2.45-4.85l.3-1.1q.35-6.05-3.15-9.45-1.4-1.15-2.1-1.4.5-1 .6-1 .75-1.75.95-3.45.3-2.5-.6-4.85-.6-1.45-1.2-2.2-.45-.7-.9-1.3-.85-1.65-1.65-2.9l-.05-.45.9-1.95q.35-.8 2-2.5.25-.35.95-.75 1-.8 1.65-1.05l2.45-1.25q.75-.25 1.55-.25h.25l.05-.05.65-1.15-.65.05q-.2 0-.3.05-2.7.15-5.3 1.8-1-3.75-5.15-7.5-1.1-1-2.1-1.75.15-.5.35-1.15.6-2.4 1.6-3.6 1.6-2.05 2.65-2.65 0-.05.05-.05 0-.05.05-.05.25-.2.55-.3.05-.1.15-.15 1.45-.75 2.65-1.1.05-.05.15-.05.8-.2 1.45-.2.2 0 .4.05l.65-1.4h-.65q-.65 0-1.25.05-.7 0-.8.05-1.4.4-2.4.9-1.05.55-1.7 1.1-.8-2.35-2.95-5-1.4-1.7-2.85-2.9m9.5-11.9q1.05 0 1.95.5 0-.05.05-.1.3-.5.5-1-2.25-1.3-4.8-.15l-.1.2q-.25.6-.55 1.15 2.9-.95 2.9-.6h.05z"
                    id="HaiHig019__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.2 4.9q1.3-.45 2.25-1.25 1.75-2.25.15-3.6l-.4.3-.3.3-.05.05q-.55.45-1.15.75-4.8 2.6-10.2.35-.45-.15-.8-.35Q2 1.1 1.45.7 1.4.6 1.35.55 1.05.3.8 0-.85 1.35.9 3.65q.9.8 2.2 1.25 5.4 1.65 10.1 0z"
                    id="HaiHig019__Symbol_30_0_Layer0_0_FILL"
                />
                <path
                    id="HaiHig019__Layer8_0_1_STROKES"
                    stroke="#900"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M265.425 221.675q.225-.125.375-.125"
                />
            </defs>
            <use xlinkHref="#HaiHig019__Layer8_0_1_STROKES" />
            <use
                xlinkHref="#HaiHig019__Symbol_31_0_Layer0_0_FILL"
                transform="translate(263.25 179.4)"
                id="HaiHig019__col1n"
            />
            <use
                xlinkHref="#HaiHig019__Symbol_29_0_Layer0_0_FILL"
                transform="translate(264.4 193.75)"
                id="HaiHig019__col1d"
            />
            <use
                xlinkHref="#HaiHig019__Symbol_30_0_Layer0_0_FILL"
                transform="translate(269.05 252.85)"
                id="HaiHig019__col2n"
            />
        </g></g>
    )
}
export default HaiHig019
