import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFCDE8",
    "col1n": "#FF4DAD",
    "col2l": "#BDFFFF",
    "col2n": "#06ECEC"
}
function AccJew057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M122.8 275.6q-.6.7-.6 1.6 0 1 .6 1.55.7.65 1.6.65.95 0 1.55-.65l.3-.3-.4-.75q-.2-.1-.2-.25-.1-.4.1-.95.1-.4.35-.7-.05-.1-.15-.2-.6-.6-1.55-.6-.9 0-1.6.6m11.45 2.9q-.6-.65-1.55-.65-.45 0-.75.15 0 .3-.05.55-.2.4-.5.65-.15.05-.35.2l-.5.35q-.05.15-.05.35 0 .9.6 1.55.65.65 1.6.65.95 0 1.55-.65.65-.65.65-1.55 0-.95-.65-1.6m-21.9-1.25q-.3-.25-.4-.8-.05-.45.1-.9-.35-.1-.75-.1-1 0-1.65.6-.6.7-.6 1.6 0 .95.6 1.55.65.7 1.65.7.9 0 1.55-.7.45-.5.6-1.1l-.85-.6q-.2-.05-.25-.25m5.7-.55l-.2.3q.1.65.55 1.1.65.65 1.6.65.9 0 1.55-.65.65-.6.65-1.55 0-.95-.65-1.6-.65-.65-1.55-.65-.95 0-1.6.65-.1.1-.15.25l.1.3q.05.4-.05.8-.1.15-.25.4z"
                    id="AccJew057__Layer10_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M6.3 1.1Q6.1.65 5.7.3 5.2-.1 4.55 0 3.9.05 3.4.7q-.2.25-.2.7-.25-.35-.55-.6Q2.05.35 1.4.45.75.55.25 1.1q-.1.2-.15.35-.15.45-.1.9.1.55.4.8.05.2.25.25l3.1 2.05L5.9 2.9l.2-.3q.15-.25.25-.4.1-.4.05-.8l-.1-.3z"
                    id="AccJew057__Symbol_136_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M4.35 2.25q0-.95-.65-1.6Q3.05 0 2.15 0 1.2 0 .55.65.45.75.4.9l.1.3q.05.4-.05.8-.1.15-.25.4l-.2.3q.1.65.55 1.1.65.65 1.6.65.9 0 1.55-.65.65-.6.65-1.55m12.7 3.55q0-.95-.65-1.6-.6-.65-1.55-.65-.45 0-.75.15 0 .3-.05.55-.2.4-.5.65-.15.05-.35.2l-.5.35q-.05.15-.05.35 0 .9.6 1.55.65.65 1.6.65.95 0 1.55-.65.65-.65.65-1.55z"
                    id="AccJew057__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.35 2.65q0-.4-.25-.8-.2-.5-.9-.75-.65-.3-1.4.05-.25.2-.4.55 0-.35-.2-.75Q2.85.3 2.15.05q-.6-.15-1.2.1Q.65.3.5.45q-.25.3-.35.7-.2.55-.1.95 0 .15.2.25l1.8 3.25 2.9-1.2.5-.35q.2-.15.35-.2.3-.25.5-.65.05-.25.05-.55z"
                    id="AccJew057__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M2.9 1.45Q2.85 1 3 .55q-.35-.1-.75-.1-1 0-1.65.6-.6.7-.6 1.6Q0 3.6.6 4.2q.65.7 1.65.7.9 0 1.55-.7.45-.5.6-1.1l-1.1-.8v-.05Q3 2 2.9 1.45M16.6 2.5v-.05q-.1-.4.1-.95.1-.4.35-.7Q17 .7 16.9.6q-.6-.6-1.55-.6-.9 0-1.6.6-.6.7-.6 1.6 0 1 .6 1.55.7.65 1.6.65.95 0 1.55-.65l.3-.3-.6-.95z"
                    id="AccJew057__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.4}
                    d="M122.8 275.6q-.6.7-.6 1.6 0 1 .6 1.55.7.65 1.6.65.95 0 1.55-.65l.3-.3-.4-.75q-.2-.1-.2-.25-.1-.4.1-.95.1-.4.35-.7-.05-.1-.15-.2-.6-.6-1.55-.6-.9 0-1.6.6m11.45 2.9q-.6-.65-1.55-.65-.45 0-.75.15 0 .3-.05.55-.2.4-.5.65-.15.05-.35.2l-.5.35q-.05.15-.05.35 0 .9.6 1.55.65.65 1.6.65.95 0 1.55-.65.65-.65.65-1.55 0-.95-.65-1.6m-21.9-1.25q-.3-.25-.4-.8-.05-.45.1-.9-.35-.1-.75-.1-1 0-1.65.6-.6.7-.6 1.6 0 .95.6 1.55.65.7 1.65.7.9 0 1.55-.7.45-.5.6-1.1l-.85-.6q-.2-.05-.25-.25m5.5-.25q.1.65.55 1.1.65.65 1.6.65.9 0 1.55-.65.65-.6.65-1.55 0-.95-.65-1.6-.65-.65-1.55-.65-.95 0-1.6.65-.1.1-.15.25l.1.3q.05.4-.05.8-.1.15-.25.4l-.2.3z"
                    id="AccJew057__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew057__Layer10_0_FILL"
                transform="translate(0 .6)"
            />
            <use
                xlinkHref="#AccJew057__Symbol_136_0_Layer0_0_FILL"
                transform="translate(111.95 274.7)"
                id="AccJew057__col1n"
            />
            <use
                xlinkHref="#AccJew057__Symbol_138_0_Layer0_0_FILL"
                transform="translate(117.85 274.9)"
                id="AccJew057__col1l"
            />
            <use
                xlinkHref="#AccJew057__Symbol_137_0_Layer0_0_FILL"
                transform="translate(125.6 275.95)"
                id="AccJew057__col2n"
            />
            <use
                xlinkHref="#AccJew057__Symbol_139_0_Layer0_0_FILL"
                transform="translate(109.05 275.6)"
                id="AccJew057__col2l"
            />
            <use
                xlinkHref="#AccJew057__Layer1_0_FILL"
                transform="translate(0 .6)"
            />
        </g></g>
    )
}
export default AccJew057
