import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M91.9 47.15q-.05-.05-.05-.1h-.2q.05.05.2.15 0-.05.05-.05m90.95-43.4q.4-1.55.65-3.15-4.55 9.45-11.6 17.45-2 4.1-4.55 7.85-7.1 9.7-18.9 16-6.1 2.1-12.3 4.35-.2.1-.4.15-4.3 2.45-8.7 4.8-.5.25-1 .45-1.1.4-2.15.45l-2.8.05q-1 0-2.65.9-1.4.75-2.55 1.65-.25.2-1.45 1.65-.35.4-.7.7-.7 1-1.95 1.55-2 .9-3.95 1.55-3.8 2.15-8.7 2.05-1.35-.25-2.45-.95-.3-.2-.65-.5-.75-.3-1.5-.75-2.35-1.35-2.85-1.35-.35 0-.7.1-.7.8-1.85 1.55-1.05.7-2.65.8-1.1.05-2.15 0-1.5.05-2.75-.25-5.65-.9-10.05-4.6-.15-.15-.35-.3-.75-.5-1.6-1.2-.65-.55-1.1-.95-2.25-1.05-3.65-1.05l-4.2.2h-.6q-2.2.5-4.7-1.1-2.25-1.5-4.65-2.7-4.2-.95-7.85-3.1-.05-.05-.1-.05-2-.6-3.8-1.75-4.8-3.15-9.05-6.5-2.1-1.7-4.05-3.55l-.25-.25q-8.95-7.75-16.35-18-1-2.25-3.05-6.7-1.25-.95-1.6-2.1Q4.25 5.95 0 0q1.65 4.65 2.1 6.2.55 2.05 1.55 4 0 .2.05.35.3 1.2.7 2.35 6.6 12.95 16.05 24 11.8 11.65 24.3 21.8.35.3.7.55 1.35.95 9.95 3.1 8.55 2.15 19.15 5 8.55.8 15.6 5.65 1.85 1.3 2.6 3.2.05 0 .1.05 1.1-1.1 4.1-2.05 1.65-.5 2.75-.55Q116.65 70 131.3 61.2l-.1.05q11.25-6.65 21.2-13.2 4.4-2.85 8.25-6.55 7.45-7.1 13.05-15.35.2-.5.4-1.05 1.75-4.6 4.55-8.4v-.25q.15-.2.35-.4.6-.85 1.15-1.85 0-.2.05-.35.3-1.1.7-2.15.35-1.25.65-2.5.25-.9.45-1.8.4-1.8.85-3.65z"
                    id="BeaChi017__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi017__Symbol_56_0_Layer0_0_FILL"
                transform="translate(100.75 182.25)"
                id="BeaChi017__col1n"
            />
        </g></g>
    )
}

export default BeaChi017
