import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiLon005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.8 214.3q.05-.2.15-.4h-.15v.4m-28.6-73.6l.05.2h.05l-.1-.2M195.5 46q-4-6.45-14.05-10.85-10.1-4.4-12.3-5.3l-3.4-2.4q-.95-.75-2.1-1.7-1.6-1.35-4.05-3.4h-.05l-.1-.1q-.8-.7-1.55-1.45l-.1-.1q-.25-.25-.45-.5-4.05-4-10.7-5.85-1.8-.5-3.8-.85-1.25-.2-2.6-.35l-1.6-.4q-1.25-.3-2.45-.55-.25-.05-.45-.1l-.7-.3q-.05 0-.1-.05-.75-.5-1.45-.9l-.85-.55q-.3-.2-.55-.35l-1.25-.8q-.2-.1-.35-.2-.4-.25-.75-.45-1.3-.8-2.1-1.15-2.2-1.3-4.45-2.35Q104.35-5 82 5q-2.25 1.05-4.5 2.35-.1.05-.15.1-.05.05-.15.1l-.15.15q-.5.3-1.05.65-3 2-6.1 3.95h-.35v.2q-1.45.1-2.15.25-2.3.35-4.55.75-5.65 1-11.15 2.45-5.75 1.4-10.25 4.75-1.1.8-2.1 1.7-1 1.15-2 2.25Q33.85 28.5 30 32q-.75.55-1.55 1.05-1.4 1.25-2.95 2.35-.1.05-.2.15-14.05 7.1-20 17.45-2.65 4.65-3.7 10-3.2 13.75.1 35.9 2.35 15.8 8 35.85 1.1 2.7 2.6 6.15v10.2q-.35 4.7-.5 9-1.25 38.3 14.35 47.6l.45.35q.05-.25.2-.5.4-1.1.85-2.2l.4-1 .1-.2v-.05q.3-1 .45-1.45l.8-1.9q.25-.6.55-1.2l.1-.25.4-.8.1-.2v-.05q.05-.15.1-.25l.3-.7.65-1.25q.75-1.55 1.5-3l1.1-2q1.3-2.3 2.7-4.5.15-.25.3-.45.35-.5.8-1.2l2.7-4.1q.55-.85 1.2-1.8h-.05q-2.35-1.95-4.55-4-1.65-1.5-3.25-3.1-5.65-5.65-10.15-11.8-6.25-8.45-10.3-17.8l-.4-.9-.2-.5h.15l.05-.2.4-7.1-.3 2.95q.922-12.152 2.35-22.15 1.2-8.55 2.8-15.5.85-3.8 1.8-7.15 3.35-11.6 8.05-17.45 7-11.25 18.55-12.95L89 55.1h1.5q13.9-3.45 24.4-1.5l41.95 7.75q11.55 1.7 18.55 12.95 4.7 5.85 8.05 17.45.95 3.35 1.85 7.15 3.65 16.3 5.35 41.25.05.1.05.15v.1h-.05q-.05.1-.1.25 0 .1.05.25-.05.1-.05.2-.1.15-.15.3-4.15 9.85-10.65 18.7-4.55 6.15-10.2 11.8-1.6 1.6-3.2 3.1-2.25 2.05-4.5 3.95 1.65 2.5 2.8 4.3l1.85 2.85q.15.2.3.45 1.4 2.2 2.7 4.5l1.1 2q.75 1.45 1.5 3l.65 1.25.3.7q.05.1.1.25v.05q.05.1.15.2l.5 1.1.5 1.15.8 1.9q.15.45.45 1.45v.05l.1.2.4 1 .05-.05q.4 1.05.8 2.15.05.15.15.35 14.15-7.2 14.9-47.7.15-8-.25-17.3v-.05q.05-.65.1-1.35v-.25q0-.05.05-.05 0-.1.05-.15v-.1q0-.05.05-.1 19.5-54.65 9.85-83.3-.95-2.1-2.2-4.45-1.75-3.25-4.1-7m-4.95 96.5q.05.1.2.2l-.2-.1v-.1z"
                    id="HaiLon005__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M35.45 14.65q.1 0 .8-.15-1.45-.1-6.1.1-1.65.05-3.1.2-.55 0-1.05.05-.35 0-.65.05-4.15.35-6.95 1-1.75.45-5.9 1.4-3.5 3.85-7.35 7.35-.75.55-1.55 1.05-1.4 1.25-2.95 2.35-.1.05-.2.15-.15.1-.25.2H.15l-.1.1-.05.05q9.25-3.95 16-6.45.8-.3 3.45-1 2.7-.75 9.15-2.2 6.4-1.5 19.2-3.15-6.4-1.1-12.35-1.1M64.45 4q1.45-.1 2-.1-2.1-.95-5.35-1.7-3.95-.9-10.65-.7L52.2.35q-.5.3-1.05.65-3 2-6.1 3.95h-.35v.45h1.5q.55-.05 1.4-.05 2.8-.2 8.75-.7 5.5-.5 8.1-.65m46.45.75l-.7-.3q-.05 0-.1-.05-.75-.5-1.45-.9l-.85-.55q-.3-.2-.55-.35L106 1.8q-.2-.1-.35-.2-.4-.25-.75-.45-1.3-.8-2.1-1.15v.05h.05q.5.25.95.5.25.1.45.25-2.6.25-4.85.65-1.5.2-2.8.45-4.35.8-7 2 1.05 0 5.95.1 2.05.05 4.8.1 1.9.05 3.5.1 4.1.15 6.4.5.75.15 1.35.3l-.7-.25m35.2 18.75q-.15-.05-1.8-1l-3.4-2.4q-.95-.75-2.1-1.7-1.6-1.35-4.05-3.4h-.05l-.5-.1q-.7-.15-1.8-.6-.9-.4-5.25-.8-.65-.1-1.3-.15-.55-.05-1.15-.1-3.2-.25-6.2 0-.7.05-1.35.1-2.7.3-5.25 1-5.4 1.45-7.3 1.8-.3 0-.5.05.7-.05 1.25-.05 1.9-.1 2.15-.1 13.05.15 22.2 2.4 2.15.5 4.85 1.35 4.1 1.2 9.4 3.1 1.15.4 2.4.85v-.1h-.05q-.1-.1-.2-.15z"
                    id="HaiLon005__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiLon005__Symbol_64_0_Layer0_0_FILL"
                transform="translate(90.65 49.65)"
                id="HaiLon005__col1n"
            />
            <use
                xlinkHref="#HaiLon005__Symbol_35_0_Layer0_0_FILL"
                transform="translate(115.5 57)"
                id="HaiLon005__col1d"
            />
        </g></g>
    )
}

export default HaiLon005
