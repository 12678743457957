import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E92424",
    "col1n": "#FF5D5D",
    "col1s": "#A80F0F",
    "col2d": "#EEAA00",
    "col2l": "#FFD95B",
    "col2n": "#FFC400"
}

function ShiLon019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.15-.7-.35-1.4l-.3-1.3-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35 0-.05-.05-.1-.4-1.2-.8-2.35-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.15-2.5-2.35-4.85-.4-.7-.75-1.35-.35-.7-.75-1.4-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-1.95.1-3.95.2H81.9q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-1.75-.3-3.4-.6-7.55-1.45-14.55-4-3.2-1.15-6.25-2.55-1.75-.85-3.45-1.7-5.45-2.75-10.55-6.3L27.1 3.5q-.35-.25-.65-.5-1.1-.8-2.15-1.6L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.15.3-.3.55h.1q-.15.3-.3.65v-.2q-.25.45-.45.95-1.65 3.6-3.1 7.4-.4.9-.7 1.85l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.95-.3 12.5 4.55.1.05.15.15l-2.8 11.75v.25l.1.3q-.15.1-.25.2-.45 1.75-.9 3.45l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1l.5-.1v-.05h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q.1-.1.2-.15 4.5-4.9 12.45-4.55l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5M15.9 53.4q-1.05-.2-2.4-.45-.35-.05-.65-.1 1.35.1 2.7.45.15.05.35.1z"
                    id="ShiLon019__Symbol_293_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M87.3 0q-.15 0-.4.1-.45.1-.95.3-.2.05-.45.1-2.05.65-5.7 1.9-9.95 3.45-21.9 5.25-12.05 1.8-17.35 1.9-1.5 0-6 .05H33.3l-.9-.1q-2.3-.15-5.4-.65l-1.3-.2q-.25-.05-.45-.1Q23.8 8.3 22.2 8H18.45q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-1.75-.3-3.4-.6l5.4 1.9q.45.1.85.25 2.1.65 4.25 1.25.2.05.45.1 2.1.6 4.25 1.1 3.7.85 7.4 1.45 6.45 1.05 12.9 1.35 1.1 0 2.2.05 2.15 0 4.3-.05h1.05q1.15-.1 2.3-.15h.05q.85-.05 1.75-.1 3.2-.3 6.35-.7 1.05-.2 2.05-.35 3.15-.5 6.2-1.25h.05Q66.65 10 71.5 8.4q1.1-.4 2.2-.75 3.15-1.15 6.3-2.5 1.9-.85 3.8-1.75 2.05-.95 4.1-2 .05-.05.15-.05-.4-.7-.75-1.35z"
                    id="ShiLon019__Symbol_262_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65M116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4z"
                    id="ShiLon019__Symbol_230_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M143.45 14.25q-.1 0-.15.05-2.05 1.05-4.1 2-1.9.9-3.8 1.75-3.15 1.35-6.3 2.5-1.1.35-2.2.75-4.85 1.6-9.7 2.75h-.05q-3.05.75-6.2 1.25-1 .15-2.05.35-3.15.4-6.35.7-.9.05-1.75.1h-.05q-1.15.05-2.3.15H97.4q-2.15.05-4.3.05-1.1-.05-2.2-.05-6.45-.3-12.9-1.35-3.7-.6-7.4-1.45-2.15-.5-4.25-1.1-.25-.05-.45-.1-2.15-.6-4.25-1.25-.4-.15-.85-.25l-5.4-1.9q-7.55-1.45-14.55-4-3.2-1.15-6.25-2.55-1.75-.85-3.45-1.7Q25.7 8.2 20.6 4.65L19.05 3.5q-.35-.25-.65-.5-1.1-.8-2.15-1.6L14.4 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.15.3-.3.55h.1q-.15.3-.3.65v-.2q-.25.45-.45.95-1.65 3.6-3.1 7.4 0 .05.05.05-.05.05-.05.1l19.15 8.8L14.8 56.5l-.3.45-.05.05-.3.4-.05-.05-.1-.1q-.2 0-.35.05.1.05.15.15L11 69.2v.25l.1.3.2-.2.15-.75h.2q.05 0 .1.05l.3.1.2.1q.15.05.35.15.95.35 1.9.75.1.05.3.1 1.35.5 2.75 1 .45.2.9.35l.05.05q6.8 2.45 13.8 4.5 2.85.85 5.8 1.6 2.55.65 5.15 1.25.4.1.85.2.4.1.85.2Q50.9 80.55 57 81.55l2.5.4q1.95.3 4 .6.5.05 1.1.15 1.6.2 3.25.4 5.05.6 10.25.95 9.8.7 19.95.6h1.1q3.8-.05 7.7-.25l2.2-.1q2.65-.15 5.35-.35l1.5-.1q.6-.05 1.2-.05l.7-.1q1.5-.15 3.05-.3h.3q5.75-.6 11.65-1.4l1.6-.2-.95.15 5.65-.75h.05q.4-.1.9-.2h.1q.15-.05.4-.05v-.05h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q.1-.1.2-.15-.2-.15-.45-.5l-2.15-10.65 21.75-2.65-.3-1.3-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35 0-.05-.05-.1-.4-1.2-.8-2.35-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.15-2.5-2.35-4.85z"
                    id="ShiLon019__Symbol_195_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M25 19.75q-1.15 1.2-1.15 2.85 0 1.65 1.15 2.8 1.2 1.2 2.85 1.2 1.65 0 2.8-1.2 1.2-1.15 1.2-2.8t-1.2-2.85q-1.15-1.15-2.8-1.15T25 19.75m53.55-5.8H77.5q-2.15.05-4.3.05-1.1-.05-2.2-.05.2.45.45.9l.3.8q1.15 2.85 1.15 6.1 0 9.4-9.9 16.05t-23.85 6.65q-13.95 0-23.85-6.65-7.75-5.25-9.4-12.15-.45-1.9-.45-3.9 0-9.45 9.85-16.1l.5-.3q1.4-1 2.95-1.75.35-.2.65-.35.7-.35 1.5-.65 0-.05.05-.05Q17.75 1.4 14.7 0h-.05l-.4.2q-.3.15-.55.35-.65.4-1.25.8-.2.1-.4.25-.55.35-1.05.75-11 7.9-11 18.9 0 .95.1 1.95.9 10.25 11.95 17.65 12 8.1 29.05 8.1t29.1-8.1q12.05-8.1 12.1-19.6-.05-3.95-1.4-7.45h-.05q-1.15.05-2.3.15m37.35 35.2v.1l-.2.3-.1.2-.05.1q-.25.3-.5.65-.15.25-.3.45-.05.05-.05.1-4.9 6.65-12.5 12.4-5.8 4.5-12.15 7.75-.45.25-.9.45 2.65-.15 5.35-.35l1.5-.1q.95-.05 1.9-.15 1.5-.15 3.05-.3h.3q4.35-2.5 8.35-5.55 3.85-2.9 7.05-6l.6-.6q.3-.35.6-.65l.15-.15.05-.05q.05-.15.15-.25l-2.15-8.65q-.1.1-.1.25-.05 0-.05.05m7.65-47.55q-.1 0-.15.05-2.05 1.05-4.1 2 4.4 9.25 4.4 19.85 0 4.45-.75 8.7l7.95-.95q.3-2.65.3-5.45 0-3.2-.4-6.2-.1-.8-.25-1.55-.4-1.2-.8-2.35-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.15-2.5-2.35-4.85z"
                    id="ShiLon019__Symbol_177_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M247.6 264.45v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35m-110.95-.5q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1z"
                    id="ShiLon019__Layer11_0_FILL"
                />
                <g
                    id="ShiLon019__Symbol_172_0_Layer0_0_FILL"
                    fill={colors.col2l}
                >
                    <path d="M77.6 15.65q-6.45-.3-12.9-1.35-3.7-.6-7.4-1.45-2.15-.5-4.25-1.1-.25-.05-.45-.1-2.15-.6-4.25-1.25-.4-.15-.85-.25l-5.4-1.9q-7.55-1.45-14.55-4-.05 0-.05.05-.8.3-1.5.65-.3.15-.65.35-1.55.75-2.95 1.75l-.5.3q-9.85 6.65-9.85 16.1 0 2 .45 3.9 1.65 6.9 9.4 12.15 9.9 6.65 23.85 6.65 13.95 0 23.85-6.65 9.9-6.65 9.9-16.05 0-3.25-1.15-6.1l-.3-.8q-.25-.45-.45-.9M63.35 25.5q0 5.7-6.9 9.7-6.85 4.05-16.6 4.05-9.75 0-16.65-4.05-4.35-2.55-5.9-5.85-.9-1.8-.9-3.85 0-5.7 6.8-9.75 6.9-4 16.65-4 9.75 0 16.6 4 6.9 4.05 6.9 9.75m25.9-10.1q-.9.05-1.75.1 1.35 3.5 1.4 7.45-.05 11.5-12.1 19.6-12.05 8.1-29.1 8.1t-29.05-8.1Q7.6 35.15 6.7 24.9q-.1-1-.1-1.95 0-11 11-18.9.5-.4 1.05-.75.2-.15.4-.25.6-.4 1.25-.8.25-.2.55-.35l.4-.2h.05Q19.55.85 17.85 0q-.4.2-.7.4-.3.1-.5.25-1.25.7-2.45 1.45-.3.2-.55.4Q1.3 10.65 0 21.8l5.85 2.7-2.5 12 .65.95v.05q.4.6.9 1.2 3.45 4.4 9.3 8.15 14.35 9.3 34.65 9.3 20.3 0 34.65-9.3Q97.9 37.6 97.9 24.5q0-5.2-2.3-9.8-3.15.4-6.35.7m41.05 9.8q0-10.6-4.4-19.85-1.9.9-3.8 1.75-3.15 1.35-6.3 2.5-1.1.35-2.2.75 1.75 5.7 1.75 11.95 0 7.15-2.3 13.55-4.25 11.9-16.45 21.3-14.25 11-33.05 13.65-5.9.8-12.25.8v.15q1.6.2 3.25.4 5.05.6 10.25.95 9.8.7 19.95.6h1.1q3.8-.05 7.7-.25l2.2-.1q.45-.2.9-.45 6.35-3.25 12.15-7.75 7.6-5.75 12.5-12.4 0-.05.05-.1.15-.2.3-.45.25-.35.5-.65l.05-.1.1-.2.2-.3v-.1q0-.05.05-.05 0-.15.1-.25l-.4-1.6h-.05l-.55-2.45q.1-.1.2-.15-.2-.15-.45-.5l-2.15-10.65 10.3-1.3q.75-4.25.75-8.7z" />
                    <path d="M31.6 21.45q-1.15 1.2-1.15 2.85 0 1.65 1.15 2.8 1.2 1.2 2.85 1.2 1.65 0 2.8-1.2 1.2-1.15 1.2-2.8t-1.2-2.85q-1.15-1.15-2.8-1.15t-2.85 1.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon019__Symbol_293_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon019__col1n"
            />
            <use
                xlinkHref="#ShiLon019__Symbol_262_0_Layer0_0_FILL"
                transform="translate(173.45 241.5)"
                id="ShiLon019__col1d"
            />
            <use
                xlinkHref="#ShiLon019__Symbol_230_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiLon019__col1s"
            />
            <use
                xlinkHref="#ShiLon019__Symbol_195_0_Layer0_0_FILL"
                transform="translate(118.05 228.6)"
                id="ShiLon019__col2n"
            />
            <use
                xlinkHref="#ShiLon019__Symbol_177_0_Layer0_0_FILL"
                transform="translate(137.95 241.25)"
                id="ShiLon019__col2d"
            />
            <use
                xlinkHref="#ShiLon019__Symbol_172_0_Layer0_0_FILL"
                transform="translate(131.35 239.55)"
                id="ShiLon019__col2l"
            />
            <use xlinkHref="#ShiLon019__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon019
