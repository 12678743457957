import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFD446"
}

function AccJew044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M14.4 2q0-.85-.6-1.45Q13.2 0 12.35 0T10.9.55q-.6.6-.6 1.45 0 .45.15.8.15.35.45.6.35.35.85.5.05.15.1.25-.15 1.65-1.4 2.8-.35.45-.85.75-1.1.7-2.55.7-2 0-3.45-1.45-1.1-1.1-1.35-2.6.05-.05.05-.15l.1-.2q.6-.1 1.1-.6.6-.55.6-1.4 0-.85-.6-1.45Q2.9 0 2.05 0T.6.55Q0 1.15 0 2q0 .45.15.8-.1.55-.1 1.2 0 2.9 2.05 4.95 2.1 2.1 5 2.1t4.95-2.1q2.1-2.05 2.1-4.95 0-.5-.05-.9v-.15l.05.05q.25-.45.25-1z"
                    id="AccJew044__Symbol_112_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew044__Symbol_112_0_Layer0_0_FILL"
                transform="translate(185.3 202.45)"
                id="AccJew044__col1n"
            />
        </g></g>
    )
}

export default AccJew044
