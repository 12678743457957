import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo006({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#ECB73E"
                    d="M115.1 286.35q1.766.244 3.65.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.681 2.573-1.85 4.15 4.456-.628 10.35-2.4.3-.05.5-.15 10.45-2.85 16.35-5.15.915-.406 1.7-.8 5.304-1.444 5.4-7.15.393-3.49-1.65-7-2.165-4.474-8.15-5.45-1.4-.3-3.2-.45-2.7-.05-5.75.35-3.35.45-7.2 1.35-1.5.4-3.1.8-3.7.95-6.8 2.2-3.464 1.266-6.55 3.45m14.95 7.15q-.1.05-.15.05l-.35.1q.25-.1.5-.15z"
                    id="ObjFoo006__Layer7_0_FILL"
                />
                <g id="ObjFoo006__Layer7_1_FILL">
                    <path
                        fill="#FAEBC9"
                        d="M151.85 284.1q-.05-.55-.3-.9-.35-.35-.85-.3-.45 0-.75.3-.35.3-.35.85.036.357.2.6.064.114.15.2.35.4.8.4.5 0 .85-.35.052-.072.1-.15.15-.293.15-.65m-5.6-4.45q-.45-.05-.75.25-.35.4-.3.95-.043.426.2.65.048.063.1.1.3.4.8.45.45 0 .8-.4.037-.037.05-.1.25-.262.25-.7-.05-.5-.3-.9-.35-.35-.85-.3m-10.8 7.45q-.05-.5-.35-.85-.334-.334-.75-.35h-.05q-.35 0-.6.2-.1.05-.2.15-.35.35-.3.85 0 .391.2.75.06.048.1.1.1.1.25.15.25.15.6.15.45 0 .8-.35.3-.3.3-.8m4.45.25q0 .55.35.95.35.25.8.3.45 0 .8-.35.25-.3.3-.85-.05-.5-.35-.85-.35-.3-.85-.35-.4-.05-.75.35-.35.4-.3.8m-14.5 5.55q.35.4.85.35.5.05.7-.3.35-.3.4-.85-.1-.45-.4-.8-.3-.4-.8-.45-.5.1-.75.4-.35.3-.35.8.05.55.35.85m2.05-4.5q.05-.5-.35-.9-.35-.35-.8-.4-.45 0-.8.35-.23.23-.3.55-.017.162 0 .35 0 .146.05.3.078.246.3.5.35.4.8.4.45 0 .8-.35.35-.35.3-.8m3.05-6.3q-.3-.3-.8-.3-.45-.05-.75.35-.4.35-.3.8-.05.5.25.9.4.25.85.25.5.05.8-.3.3-.3.35-.85-.05-.45-.4-.85m7.55 1.05q.4.35.85.4.45 0 .75-.35.3-.4.35-.8-.05-.55-.4-.95-.3-.25-.75-.25-.45-.05-.8.25-.3.3-.25.85-.05.5.25.85m-17.65 2.75q.3.3.8.3.45.05.75-.3.35-.3.35-.8 0-.55-.35-.85-.35-.35-.8-.4-.45 0-.8.35-.3.35-.35.8 0 .5.4.9z"
                    />
                    <path
                        fill="#FFD3B8"
                        d="M157.1 287.5q.35-.45.25-.95-.25-.75-.85-.75-.15 0-.35.05l-.1-.05q-.699.3-1.35.55.2.378.2.8 0 .65-.3 1.1-.338.527-.5.8.134-.058.25-.15.2 0 .45-.1 1.8-.5 2.3-1.3m-18.5 3.65q.049.04.1.05 1.334.292 2.75 1 .385.12.6.3.419.275.55.75.35 1.25.15 1.8.05.2.15.45h.05q.35.6 1 .5.55-.25.65-.95-.05 0 0-.05-.1-.55-.25-1.4-.6-2.05-.2-2.15.65-.15 1.05.9.4 1 1.35.8.65-.25.3-1.6-.116-.26-.1-.5.016-.182.1-.35.268-1.102.7-2l-6.45 1.75q-.19.047-2.5.7m-8 4.2h-.05q.375 1.3 1.9 1.3 1.5 0 1.95-1.25.6-1.7 1.35-2.25l.2-.1 2.65-.75-1.6-.7q-.63.158-1.3.35-.99.283-2.2.65-.114.288-.25.65-.132.357-.3.6l-.05.05q-1.2.85-2.3 1.45z"
                    />
                    <path
                        fill="#4FBF1E"
                        d="M143.95 296q-.65.1-1-.5-1.95.5-4.1 1.1l-2.25.65q-1.35.35-2.8.75-.413.092-8.4 2.25.014.15 0 .3-.088.662-.25 1.3 1.066-.338 2.25-.7 2.7-.8 3-.65.25.15.95 1 .65.7 1.75 2.05 1.25-.95 2.25-1.6 1.85-1.45 2.95-2.25.05-.15.45-.25.95-.4 4.5-1.35 3-.85 7.25-1.05.8-.25 1.6-1.1.05-.15.2-.3.85-1.35 2.4-2.3 1.1.15 2.9.7h.2q2.45.75 3.7.45 1.1-.35 1.5-.6l.1-.1q-2.5-.85-3.75-1.55-.35-.65-.65-1.1l-1.65.55q-.85.15-2 .5-.1 0-.2.05h-.05q-1.7.5-4 1.15-2.65.7-6.2 1.65-.1.7-.65.95z"
                    />
                    <path
                        fill="#563521"
                        d="M147.45 290.35q-.241.193-.6.35-.084.168-.1.35-.016.24.1.5.35 1.35-.3 1.6-.95.2-1.35-.8-.4-1.05-1.05-.9-.4.1.2 2.15.15.85.25 1.4-.05.05 0 .05 3.55-.95 6.2-1.65 2.3-.65 4-1.15h.05q.1-.05.2-.05 1.15-.35 2-.5l1.65-.55.05-.05q.45-.35.7-.9.3-.6.05-1.4-.15-.6-.6-1.1-.15-.1-.75-.3-.1-.1-1.05.1-.5.8-2.3 1.3-.25.1-.45.1-.116.092-.25.15-1.037.824-1.85 1.05-3.3.1-3.35.1-.8.05-1.45.15m-11.5 2.7l-.2.1q-.75.55-1.35 2.25-.45 1.25-1.95 1.25-1.525 0-1.9-1.3-3.133 1.318-5.2 1.7.198 1.555.05 3.2 7.987-2.158 8.4-2.25 1.45-.4 2.8-.75l2.25-.65q2.15-.6 4.1-1.1h-.05q-.1-.25-.15-.45.2-.55-.15-1.8-.131-.475-.55-.75-.215-.18-.6-.3-.744-.156-2 .05h-.55q-.119.031-.25.05h-.05l-2.65.75z"
                    />
                    <path
                        fill="red"
                        d="M147.55 288.7q-.432.898-.7 2 .359-.157.6-.35.65-.1 1.45-.15.05 0 3.35-.1.813-.226 1.85-1.05.162-.273.5-.8.3-.45.3-1.1 0-.422-.2-.8-1.862.812-3.1 1.3l-4.05 1.05m-8.1 3.55q1.256-.206 2-.05-1.416-.708-2.75-1-.051-.01-.1-.05-.125.063-.25.1-.55.15-1.3.35-.028.006-.05 0l1.6.7h.05q.131-.019.25-.05h.55m-9.4 1.25q-.1.05-.15.05l-4.95 1.4q.296 1.03.4 2.1 2.067-.382 5.2-1.7h.05q1.1-.6 2.3-1.45l.05-.05q.168-.243.3-.6.136-.362.25-.65-.075.027-.15.05l-3.3.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo006__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo006__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo006
