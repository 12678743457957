import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiHig016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M16.6 11.5q-1.35-1.05-1.8-1.45-.6-.5-1.05-.85-.65-.6-1.1-1Q11 6.7 9.4 5.1 6.9 2.6 4.6 0 1.85 8 1.4 10.3.85 12.6.15 16.8q-.25 1.4 0 2.65-.2 3.45 2.55 19.85Q5 50.35 8.2 56.65q3.2 6.25 9.45 12.5l-2.2-7.65q-.5-.5-1.65-8.1-.1-.5-.25-4.5 4.2 11.9 11.1 20.95-3.4-17.5-4.6-26.55-2.1-15.55-2.8-31.1l.05-.15-.7-.55m-3.4 13.4v-.05h.15l-.15.05z"
                    id="HaiHig016__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.3 10.05q-.6-.5-1.05-.85-.65-.6-1.1-1Q7.5 6.7 5.9 5.1 3.4 2.6 1.1 0 .4 6.15 0 9.65v.1Q1.4 22.7 2.7 27.4 4 32.05 6 38.55q-.6-9.95-.05-12.85.6-3.1 1.55-8.45 3.45 27.95 13.65 52.6-3.4-17.5-4.6-26.55-2.1-15.55-2.8-31.1l.05-.15-.7-.55q-1.35-1.05-1.8-1.45m-1.6 14.8h.15l-.15.05v-.05z"
                    id="HaiHig016__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.75 0Q4.6 9.85 3.6 14.8q-.95 4.9-2.1 11.65Q.35 33.1.1 49.25t.25 22.8H.6q.5-1.3 2.1-4.45 1.55-3.05 2.4-4.7-3.25 7.35 1.35 38.85 1-5.45 1.25-6.15 1.95-4.85 2.65-6.8v.5q-.2 11.35 1.8 20.65 2 9.25 2.95 13.25 1.35-8.9 3.5-16.65-.1 16.05 4.15 22.6 4.25 6.5 7.1 8.9-1.3-1.6-1.9-5.3-.3-1.45-.6-4.6-.25-2.85-.25-4.1l.25-22.7Q27.1 97.8 27.9 87v-.1q.4-3.5 1.1-9.65-6-6.8-10.4-14.25-.5-.8-.95-1.6v-.05q-4.35-7.65-7.1-15.95-4.2-12.55-4.7-26.6-.1-2.1-.1-4.2 0-4.75.4-9.3l.6-5.3z"
                    id="HaiHig016__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.45 0L0 .15q.4 1.5 1.25 12.45Q2 23.1 2 26.15q.75-2.3 5.45-10.1-.25.5-.75 3.25-.5 2.9-.65 4.6-.2 1.7-.3 5-.1 3.3-.25 6.15l-.25 2.65.05-.4q2.25-3.6 6.5-8.95v.15l-.05.75q-.1 1.55-.25 6-.15 5.95-.15 8.1 0 4.6.55 9.8l.2 1.7q.75-4.65 5.55-11.8l.15-1.45v-.1q.4-3.5 1.1-9.65-6-6.8-10.4-14.25-.5-.8-.95-1.6l-.05.05q0-.05.05-.1Q3.2 8.3.45 0z"
                    id="HaiHig016__Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig016__Symbol_51_0_Layer0_0_FILL"
                transform="translate(115.55 216.45)"
                id="HaiHig016__col1n"
            />
            <use
                xlinkHref="#HaiHig016__Symbol_50_0_Layer0_0_FILL"
                transform="translate(119.05 216.45)"
                id="HaiHig016__col1d"
            />
            <use
                xlinkHref="#HaiHig016__Symbol_53_0_Layer0_0_FILL"
                transform="translate(91.15 139.2)"
                id="HaiHig016__col2n"
            />
            <use
                xlinkHref="#HaiHig016__Symbol_54_0_Layer0_0_FILL"
                transform="translate(101.25 184.6)"
                id="HaiHig016__col2d"
            />
        </g></g>
    )
}
export default HaiHig016
