import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M83.05 33.3q-.4-.55-.75-1.1Q60.05 4.05 22.35.35q-5.2-.5-9.25-.3h-.5q6.05 4.55 7.2 4.5L8.35 5.3q6 3.4 6.75 4.5-.25 0-.25.25-4.25.05-10.75 1.25 4.85 2.35 7.75 5.25l-9.75.75q5.7 5.05 7.8 6.3-7.1-.6-9.9.55 21.5 11.8 33.45 51.65 7.9 23.7 7 50.15.55-.9 2.9-5.3 2.35-4.45 3.75-9.6 1.4-5.2 2.25-20.95 4.2 4.05 6.2 21.1 1.95 17-.7 25.95 7.35-6.55 10.6-15.45 3.25-8.9 4.65-24.2 4.5 15.1-1.25 42.65 7.75-7.1 10.6-18.15 2.8-11.05 1.75-30.55 5 16.55 5.1 26.75.1 10.2-.45 23.95 8.7-16.95 10.65-23.85 2.35-8.4 2.35-21.45 0-40.5-15.8-63.55m-53.2-1.8q.75 0 1.5.05h-1.5v-.05z"
                    id="HaiHig006__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig006__Symbol_10_0_Layer0_0_FILL"
                transform="translate(182.45 59.55)"
                id="HaiHig006__col1n"
            />
        </g></g>
    )
}

export default HaiHig006
