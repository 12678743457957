import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#000000"
}

function ShiSpe001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#EFDDA5"
                    d="M250.05 297.05l-4.5 2.4v8.9l4.5-2.25v-9.05m-65.65 35.2v-9.05l-4.65-2.35v9.1l4.65 2.3m1.9 1.25l.2.1h4.4v-7.1l-4.6-2.35v9.35m12.2.1l.05-.05v-9.3l-4.7 2.55v6.8h4.65M178 319.95l-4.6-2.45v9.05l.05.05 4.55 2.2v-8.85m-6.5-3.45l-4.65-2.55v9.3l4.65 2.3v-9.05m33.65 13.5v-9.1l-4.75 2.45v9l4.75-2.35m6.1-12.3l-4.5 2.4v8.85l4.5-2.25v-9m6.7-3.55l-4.65 2.45v9.05l4.65-2.3v-9.2m21.25-11.3v8.9l4.25-2.1v-9.1l-4.25 2.3m-2.15 10.25V304l-4.4 2.4v8.9l4.4-2.2m-12.75 6.8v-9.25l-4.45 2.4v9.05l4.45-2.2m1.9-10.25v9.05l4.45-2.2v-9.05l-4.45 2.2m-91.6-3.7l4.65 2.3v-8.9l-4.65-2.45v9.05m10.9 5.65v-8.95l-4.55-2.4v9.1l4.55 2.25m6.55-5.4l-4.55-2.45v9.15l4.55 2.25v-8.95m8.25 4.2v9.3l4.65 2.3v-9.05l-4.65-2.55m-6.35 5.9l4.6 2.25v-9.05l-4.6-2.25v9.05z"
                    id="ShiSpe001__Layer20_1_FILL"
                />
                <path
                    fill="#CA9979"
                    d="M249.4 306.45l1-.5v-9.05l-1 .55v9m-5.35-6.2l-1 .5v9.1l1-.5v-9.1m-59.45 32.1v-9.05l-.95-.45v9.05l.95.45m6.55 1.25v-6.95l-.95-.45v7.4h.95m6.55-8.9v8.9h.8l.2-.1v-9.3l-1 .5m-19.45-4.6l-.95-.5v8.9l.95.45v-8.85m-6.55-3.5l-.9-.5v9.1l.9.45v-9.05m-6.55-3.55l-.95-.5v9.1l.95.45v-9.05m53 1l-1 .5v9.2l1-.5v-9.2m-6.55 3.45l-1 .6v8.95l1-.5v-9.05m-6.35 3.35l-1 .45v9.15l1-.5v-9.1m19.45-10.4l-1 .6v9.15l1-.5v-9.25m6.35-3.2l-1 .55v9l1-.5v-9.05m6.45-3.5l-1 .6v9.05l1-.5v-9.15m-98.05-4.3l-.95-.5v8.95l.95.45v-8.9m12.9 6.95l-.95-.45v8.9l.95.45v-8.9m6.45 12.3v-9.05l-.8-.4-.15 9 .95.45m-13-15.9l-.95-.5v9l.95.45v-8.95z"
                    id="ShiSpe001__Layer20_1_MEMBER_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.2}
                    d="M76.2 95.65v2.6l4.85 2.5v-2.6l-4.85-2.5m-6.55-3.3v2.55l4.85 2.5v-2.6l-4.85-2.45m-1.5 1.75v-2.5L63.3 89v2.6l4.85 2.5m-11.4-8.65v2.8l4.85 2.5V88.1l-4.85-2.65M50.2 81.9v2.95l4.85 2.5v-2.8L50.2 81.9m-6.35-.3l4.85 2.5v-2.95l-4.85-2.4v2.85m-8.15-4.2v-3.1l-4.85-2.55v3.15l4.85 2.5m1.7-2.15v3l4.85 2.5V77.9l-4.85-2.65M24.5 71.6l4.85 2.5v-3.15L24.5 68.4v3.2m98.05 9.05l4.85-2.55v-2.85l-4.85 2.65v2.75m6.55-3.4l4.85-2.55v-2.95l-4.85 2.6v2.9m6.35-3.35l4.85-2.5v-3l-4.85 2.55v2.95M103.2 88.1v2.65l4.85-2.55v-2.65l-4.85 2.55m11.4-3.3v-2.85l-4.85 2.6v2.75l4.85-2.5m6.35-6.05l-4.85 2.4V84l4.85-2.55v-2.7M96.65 91.6v2.55l4.85-2.55V89l-4.85 2.6m-8.05 6.75V95.7l-4.85 2.6v2.55l4.85-2.5m1.7-3.5v2.6l4.85-2.55v-2.55l-4.85 2.5z"
                    id="ShiSpe001__Symbol_1_0_Layer0_0_FILL"
                />
                <g id="ShiSpe001__Layer20_0_FILL">
                    <path
                        fill="#F47B02"
                        d="M118.05 254.95q-.359 1.005-.75 2-1.324 3.628-2.5 7.45-.872 2.84-1.65 5.8-.444 1.633-.85 3.3-.066.254-.15.5h.05q-.096.499-.25 1-.032.3-.1.6-.162.698-.35 1.4-.344 1.68-.7 3.4-.007.088-.05.15-.155.931-.35 1.85.017.04 0 .05l82.45 43 81.7-43.1q-.01-.266-.05-.55-.034-.195-.1-.4-.01-.2-.05-.4-.036-.2-.1-.4-.338-1.802-.75-3.6-.137-.687-.3-1.4-.07-.3-.15-.6-.166-.751-.35-1.5-.408-1.674-.85-3.35-.206-.76-.45-1.55-.72-2.697-1.55-5.3-.626-1.977-1.3-3.9-.427-1.233-.9-2.45-.379-1.097-.8-2.2l-74.4 37.95-74.4-37.75m8.55-17.65q-.84 1.43-1.65 2.9-2.021 3.628-3.8 7.5-.363.784-.75 1.55l72.2 36.2 72.1-35.8q-.399-.976-.85-1.95-1.804-3.927-3.85-7.6-1.022-1.837-2.1-3.6-.58-.965-1.2-1.9-1.971-3.101-4.15-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-19.767 13.662-44.3 15.85-.756.087-1.55.15h-.05l-1.05.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16l-1.55-1.15q-1.45-1.05-2.8-2.1l-1.85-1.4-.05-.05q-.04.11-.1.2-3.033 4.08-5.7 8.55z"
                    />
                    <path
                        fill="#1D7AB8"
                        d="M119.5 251.4l-.35.8q-.547 1.388-1.1 2.75l74.4 37.75 74.4-37.95q-.876-2.229-1.85-4.4h.05l-.35-.7-72.1 35.8-72.2-36.2q-.175.477-.4.9l-.05.2-.3.7q-.045.136-.15.3v.05z"
                    />
                </g>
                <g id="ShiSpe001__Layer20_2_FILL">
                    <path
                        fill="#673210"
                        d="M192.5 253v-.05l7.7-3.7-.8.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-.8-.05 7.7 3.75m-77.85 11.8q-.32 1.156-.65 2.3l78.5 40.7 78.5-40.7q-.33-1.148-.7-2.3l-77.8 40.25-77.85-40.25z"
                    />
                    <path
                        fill="#D01515"
                        d="M111.45 277.3q-.12.567-.25 1.1-.056.38-.15.7l81.45 41.75 81.45-41.8q-.092-.494-.2-1.05l-.2-.75-81.05 41.15v.05l-81.05-41.15m11.65-33.55q-.014.075-.05.15-.03-.007-.05-.05-.96 1.913-1.85 3.85-.568 1.227-1.15 2.45-.01.109-.05.2l72.55 36.5 72.5-36.5h.05l-.15-.35q-.517-1.148-1.05-2.3-.961-2.093-2-4.15-.022.066-.05.1l.05.1-69.35 34.85-69.4-34.85m7.65-12.35v-.05l-.15-.05q-.052-.023-.1-.05l-.25.35q-.184.284-.4.55.082.062.15.1l62.5 29.1v-.05l62.5-29.05-.05-.05q-.273-.45-.6-.9l-.1.05v.05l-21.65 9.7q-.356.164-.75.3-2.48 1.157-5.05 2.1l-34.3 15.15v.05l-34.35-15.2q-4.597-1.726-9-4l-18.4-8.1z"
                    />
                    <path
                        fill="#B85A1D"
                        d="M111.05 279.1q-.119.664-.25 1.3-.007.088-.05.15-.155.931-.35 1.85-.122.775-.3 1.55v.05q.065.147.05.25l29.3 15.3q.025.013.05 0 .725.413 1.45.8l4.85 2.55v.05q.025.013.05 0 .825.463 1.65.9l4.85 2.65q.025.014.05 0 .775.462 1.55.85l4.05 2v-.05l.8.45q.025.013.05 0 .725.413 1.45.75l4.85 2.65q.025.013.05 0 .825.463 1.65.9l4.85 2.65q.025.013.05 0 .825.463 1.65.9l17.8 9.15 1.3.85 19.1-10q.855-.448 1.7-.9l4.85-2.55q.855-.545 1.7-1l4.85-2.6q.754-.4 1.5-.8v-.05l4.85-2.35q.804-.422 1.6-.85l4.85-2.65q.855-.446 1.7-.9l4.85-2.65v.05q.754-.397 1.5-.8l29.25-15.35q-.065-.685-.15-1.35-.064-.486-.15-.95-.025-.249-.1-.5-.01-.2-.05-.4-.036-.2-.1-.4-.129-.762-.3-1.55l-81.45 41.8-81.45-41.75m6.3-22.2q-.015.024-.05.05-1.324 3.628-2.5 7.45-.067.218-.15.4l77.85 40.25 77.8-40.25q-.207-.736-.45-1.5-.626-1.977-1.3-3.9-.427-1.233-.9-2.45.01-.042 0-.1L192.5 294.7l-75.15-37.8M130 232.25q-.068-.038-.15-.1-.32.528-.7 1l-1.95 3.1q-.296.541-.6 1.05-.283.483-.6.95.068.06.1.1L192.5 270l66.35-31.65q-.027-.088-.1-.2.094.04.15.05-.486-.86-1-1.7-.58-.965-1.2-1.9-.774-1.24-1.6-2.45-.068.02-.15.05l.05.05-62.5 29.05v.05l-62.5-29.1z"
                    />
                    <path
                        fill="#F4DC02"
                        d="M114 267.1q-.024.084-.05.15-.342 1.227-.7 2.45-.02.187-.1.35v.15q-.01.035-.05.05l79.4 41.55v-.05l79.35-41.55q.008.005 0-.05v-.05q-.022-.034-.05-.1-.177-.687-.4-1.4-.183-.754-.4-1.5l-78.5 40.7-78.5-40.7m10.9-26.75h-.05q-.877 1.618-1.75 3.25.048.042.05.05.001.05-.05.1l69.4 34.85 69.35-34.85-.05-.1q-.013-.025-.05-.05.057-.025.05-.05-.76-1.56-1.6-3.1-.038.043-.1.05-.068-.13-.15-.3L192.5 273 125 240.25l-.1.1z"
                    />
                    <path
                        fill="#81DB24"
                        d="M118.5 253.8l-1 2.7q-.079.205-.15.4l75.15 37.8 75.15-37.85q-.576-1.536-1.2-3.05l-73.95 37.75-74-37.75z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiSpe001__Layer20_0_FILL" />
            <use xlinkHref="#ShiSpe001__Layer20_1_FILL" />
            <use xlinkHref="#ShiSpe001__Layer20_1_MEMBER_0_FILL" />
            <use xlinkHref="#ShiSpe001__Layer20_2_FILL" />
            <use
                xlinkHref="#ShiSpe001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(110.1 228.6)"
                id="ShiSpe001__col1d"
            />
        </g></g>
    )
}

export default ShiSpe001
