import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#BD9100"
}

function ShiMot026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.35 12.5l.05-.05.95-1.55h-12.7L18.7 2.2l-.05-.05L17.6 0h-.05L16.5 2.15l-.1.05-4.9 8.7H-1.2l.95 1.55.05.05 5.45 9.45-5.45 9.4q0 .05-.05.1L-1.2 33h12.7l4.9 8.7.1.05 1.05 2.15h.05l1.05-2.15.05-.05 4.95-8.7h12.7l-.95-1.55q-.05-.05-.05-.1l-5.45-9.4 5.45-9.45m-6.7 7.25l-3.8-6.65h7.65l-3.85 6.65m-1.9 1.05q.15.25.3.55l.3.6-.3.6q-.15.25-.3.6l-4.4 7.65H12.8l-4.35-7.65q-.2-.35-.35-.6l-.35-.6.35-.6q.15-.3.35-.55l4.35-7.7h9.55l4.4 7.7M14 10.9l3.6-6.3 3.5 6.3H14m-7.5 8.85L2.65 13.1h7.65l-3.8 6.65M17.6 39.3L14 33h7.1l-3.5 6.3m-7.3-8.5H2.65l3.85-6.65 3.8 6.65m22.2 0h-7.65l3.8-6.65 3.85 6.65z"
                    id="ShiMot026__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot026__Symbol_32_0_Layer0_0_FILL"
                transform="translate(174.95 261.85)"
                id="ShiMot026__col1n"
            />
        </g></g>
    )
}

export default ShiMot026
