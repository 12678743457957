import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M51.05.05Q50.75 0 50.5 0H26.9q-.25 0-.6.05Q10.35.75 0 13.3h77.4Q67.05.75 51.05.05z"
                    id="BeaMus012__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.05 1q-.1.05-2.05 1-1.95.95-2.5 1.3-.6.35-1.15.75-.55.35-1.3.8Q2.3 5.3.55 7.6q-.3.35-.55.75h8.9q.05-.35.15-.65.1-.4.25-.75.65-1.45.75-1.85h-.1l-3.9 2.4v-.35q0-1.1 1.3-3.05.1-.2.2-.35.1-.15.25-.3Q9.05 1.65 10.05 1M20.5 3.5q.2-2.15 1.05-3.4-1.8 1.55-3.05 3.4-.2.25-.35.5-.05.1-.1.25-1.1 1.8-1.4 3.5-.05.3-.05.6h4.45q-.1-.25-.15-.55-.45-1.55-.45-3.1v-.45q0-.4.05-.75M37.15 8q-1.3-2.25-1.65-3.5 0-.15-.05-.25h-.15q-.1.1-.2.25-2.5 2.85-2.75 2.85-.85 0-2.8-2.35 0 .1-.1.1-.2.9-.75 2.8-.1.2-.15.45h8.8q-.1-.15-.2-.35m9.8-2.15q-.55-.65-1-1.2-.55-.6-.95-1.1Q42.55.7 41.95 0v.6l.9 7.75h7.05q0-.1-.05-.15-.15-.6-.35-1.15-.6-1.3-1.05-2.35-.25-.65-.45-1.15-.3-.75-.45-1.3 0 .3-.15 1.3-.05.2-.1.45-.05.35-.1.65-.05.7 0 1.2h-.25m10.7-.6q-.8-.65-1.2-1.05-.4-.45-.85-.65-1.85-1.05-3.5-1.85Q49.95.65 48.8 0l.15.1q1.5 1.1 4.65 3.45.65.6 1.2 1.25 1.3 1.55 1.8 3.45v.1h3.9q0-.05-.05-.05-.4-.6-1.2-1.5-.8-.95-1.6-1.55m5.15-.35q.2.2.45.4 1.2 1.05 1.45 3.05h3.1l-.3-.3q-.3-.3-1.55-1.2-.8-.55-2.55-1.95-.8-.6-1.75-1.35-.3-.2-.6-.45l.15.15q.05.1.25.3.35.45 1.35 1.35z"
                    id="BeaMus012__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus012__Symbol_29_0_Layer0_0_FILL"
                transform="translate(153.8 199.4)"
                id="BeaMus012__col1n"
            />
            <use
                xlinkHref="#BeaMus012__Symbol_4_0_Layer0_0_FILL"
                transform="translate(159.05 204.35)"
                id="BeaMus012__col1d"
            />
        </g></g>
    )
}

export default BeaMus012
