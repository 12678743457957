import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M205.2 221.15q-.265-.165-.5-.3-.462-.214-.95-.5l-.05-.05q-2.92-1.493-4.9-2.25-.027-.014-.05-.05-.821-.213-1.45-.45-.026-.013-.05-.05-2.12-.594-4.6-.8-1.874-.202-3.95-.2-1.013 0-2 .05-3.495.058-6 .55h.05q-1.369.208-2.5.55-3.887 1.318-4.35 5.15-.056.393-.05.7-.077 3.492 4.5 4.2 1.262.15 2.4.3 2.623.35 4.55.35h.05q.7.05 1.4.05 2.86.056 5.95-.45h-.05q.565-.06 1.05-.2l-.05.05q2.618-.342 4.75-.2 2.082.096 3.6.75v-.05q.974.488 1.95.75.26.112.55.2 1.907.649 3.45.8 1.444.188 2.1-.25.936-.49.85-1.75-.017-.189-.15-.35-.404-1.948-1.7-3.65-.994-1.26-3.85-2.9z"
                    id="FacMou017__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M3.25 8.4q1 .1 1.9.25v-3.9Q2.55 5.1 0 5.8q.15 2.15 3.25 2.6m1.9-4.5V.7q-.95.15-1.7.45Q.45 2.1.05 5q2.55-.7 5.1-1.1m5.9 5.2v-5q-2.5.1-5.1.5v4.15q2.35.3 4.1.3h.05q.5.05.95.05m0-5.8V.05Q8.1.1 5.95.55V3.8q2.6-.4 5.1-.5m.95.8h-.15v5q2.45 0 5.05-.4V4.35q-2.4-.3-4.9-.25m4.9-.55V.2Q15.25 0 13.4 0q-.8 0-1.55.05V3.3H12q2.5-.05 4.9.25m5.85 4.75V5.45q-2.5-.65-5.05-1V8.6q.2-.05.4-.05l.05-.05q2.5-.3 4.6-.2m4.55.9q.6.25 1.25.45v-2.1q-2.45-1.15-5-1.85v2.65q2.1.1 3.75.8v.05m-4.55-4.55l.1-3.25q-.75-.2-1.3-.4-1.8-.55-3.85-.75v3.4q2.55.35 5.05 1m5-1l.05.05q-2.45-1.25-4.15-2l-.1 3.2q2.55.65 5.05 1.75l.1-2.45q-.5-.25-.95-.55m6.45 6.75v-.05q-2.55-1.4-4.8-2.45h-.05l-.05 2q.2.05.4.15 1.7.55 3.15.7.8.1 1.2-.05.15-.15.15-.3m-.3-1.15q-.45-1.15-1.25-2.2-.85-1.1-3.2-2.45l-.1 2.45.3.1h.05q2 .95 4.2 2.1z"
                    id="FacMou017__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou017__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou017__Symbol_42_0_Layer0_0_FILL"
                transform="translate(175.3 217.9)"
                id="FacMou017__col1n"
            />
        </g></g>
    )
}

export default FacMou017
