import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col2n": "#FFFFFF"
}

function FacMou033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M29.9 7.35q.15-.1.2-.2 4-4.25 9.2-7.15-3.25.85-6.65.75-3.9.2-8.15 1.85-2 .8-4.85.7-2.8.1-4.85-.7Q10.55.95 6.65.75 3.3.85 0 0q5.25 2.9 9.2 7.15.05.1.25.2.1.15.3.35 4.45 3.85 9.9 3.9 5.45-.05 9.9-3.9.25-.2.35-.35z"
                    id="FacMou033__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M41.7 5q.25-.35.4-.65-.95.3-1.85.4-2.35.2-4.65-.85-1.55-.7-3.1-1.95Q27.65-2 21.05 2q-6.6-4-11.45-.05-3.45 2.75-6.8 2.8-.6 0-1.2-.05-.8-.1-1.6-.35.1.2.3.45l.1.2q.8 1 2.5 2.25 2.2 1.7 4.25 4.85 4.9 8.85 13.9 8.35 9 .5 13.9-8.35Q37 8.95 39.2 7.25 40.9 6 41.7 5M33 6.35q3.1.05 6.1-.6-4.8 2.25-8.45 5.5-.05.05-.2.15-.1.1-.3.25-4.1 2.95-9.1 3-5-.05-9.1-3-.2-.15-.3-.25-.15-.1-.2-.15Q7.8 8 3 5.75q3 .65 6.1.6 3.6.15 7.5 1.4 1.85.6 4.45.55 2.6.05 4.45-.55 3.9-1.25 7.5-1.4z"
                    id="FacMou033__Symbol_58_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou033__Symbol_118_0_Layer0_0_FILL"
                transform="translate(172.85 216.45)"
                id="FacMou033__col2n"
            />
            <use
                xlinkHref="#FacMou033__Symbol_58_0_Layer0_0_FILL"
                transform="translate(171.45 211.65)"
                id="FacMou033__col1n"
            />
        </g></g>
    )
}

export default FacMou033
