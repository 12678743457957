import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.75 10.25Q54.15 2.4 62.7.5q-5-1.25-15.65.75Q12.8 6.95 1.1 24.95-.1 28.2 0 30.3l.05.8q1.5-2.4 4.95-3.5 1-.3 2.35-.55 2-.35 4.75-.5 6.7-.05 11.65-.3 2.6-.15 4.55-.45 13.9-3.45 21.45-15.55m77 16.3q2.75.15 4.75.5 1.35.25 2.35.55 3.45 1.1 4.95 3.5l.05-.8q.1-2.1-1.1-5.35-11.7-18-45.95-23.7-10.65-2-15.65-.75 8.55 1.9 12.95 9.75 7.55 12.1 21.45 15.55 1.95.3 4.55.45 4.95.25 11.65.3z"
                    id="BeaMus022__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M254.05 224.75q-4.9-2.05-9.95-2.35-5.05-.35-9.3-1-4.15-.7-7.8-2.1-3.55-1.45-8.7-6.35v-.1q1.6.95 3.65 1.35 1.55.25 4.2.25 2.35 0 3.15-.35-7.55-2.4-15.35-8.5-3.15-2.5-8.5-3.45-2.05-.4-5-.65h-.15q-.65.1-1.2.25 8.55 1.9 12.95 9.75 7.55 12.1 21.45 15.55 1.95.3 4.55.45.3 0 .65.05l11 .25q2.75.15 4.75.5 1.35.25 2.35.55 3.05.95 4.55 2.95l-.1-.3q-1.4-4.3-7.2-6.75M155.6 214.1q.8.35 3.15.35 2.65 0 4.2-.25 2.05-.4 3.65-1.35v.1q-5.15 4.9-8.7 6.35-3.65 1.4-7.8 2.1-4.25.65-9.3 1-5.05.3-9.95 2.35-6 2.55-7.3 7.05 1.5-2 4.55-2.95 1-.3 2.35-.55 2-.35 4.75-.5 6.7-.05 11.65-.3 2.6-.15 4.55-.45 13.9-3.45 21.45-15.55 4.4-7.85 12.95-9.75-.55-.15-1.2-.25-3.05.25-5.15.65-5.35.95-8.5 3.45-7.8 6.1-15.35 8.5z"
                    id="BeaMus022__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus022__Symbol_39_0_Layer0_0_FILL"
                transform="translate(123.1 201.25)"
                id="BeaMus022__col1n"
            />
            <use
                xlinkHref="#BeaMus022__Symbol_9_0_Layer0_0_FILL"
                id="BeaMus022__col1d"
            />
        </g></g>
    )
}

export default BeaMus022
