import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48 15.4q6.75-3.8 5.85-12.8-.05-.45-.1-.85-.15-.7-.4-1.75-1.85 5-5.45 8.7-3.2 3.15-6.35 2.4-1.85-.55-5.55-2.15-.65-.25-1.45-.55l-.5-.25q-5.85-3-14.1 0-.25.1-.5.25-.85.3-1.45.55-3.15 1.35-4.9 1.95-3.05 1.2-6.35-1.6 0-.05-.05-.05Q2.1 5.65.65 0 .4 1.05.25 1.75q-.1.45-.1.95-1.05 8.85 5.5 12.5.4.2.8.45.5.25 1.1.5 7.5 4.1 19.1 4.05h.7q11.6.05 19.1-4.05.6-.25 1.1-.5.2-.15.45-.25z"
                    id="BeaChi010__Symbol_49_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi010__Symbol_49_0_Layer0_0_FILL"
                transform="translate(165.55 232.35)"
                id="BeaChi010__col1n"
            />
        </g></g>
    )
}

export default BeaChi010
