import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#5F00C2",
    "col1n": "#7B00F7",
    "col1s": "#340962",
    "col2n": "#FFC400"
}

function ShiDre027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M4.25 41.1l.2.4h-.3L0 58.9v5.2q.3-.1.55-.2l1.9-.7q2.6-.55 11.85-3.95 9.4-3.5 10.9-6.7-.3-.85-1.65-.75-2.85 1.25-3.85 1.4-.55.1-3.45.1-5.55 0-5.55-2.6 0-3.65 9.4-6.05 4.75-1.3 6.35-2 2.95-1.35 2.95-3.35-.4-.7-.4-1.05h-2.4q-12.75 2.8-20.3 3.25-.2 0-.35.05-.8.05-1.7-.45m99.7 8.2q1.4-2.05 4.65-10.05l-6.4 42.3q.9.05 1.75.1l4.35.3q11.6.95 22.85 3.95v-1.85l-24.4-6.95 6.8-44.65-112 46.2v4.9q5.3-.85 10.3-1.45l-.15-6 11.5-4.75q.45 1.25.7 3.5.25 1.75.55 2.85.55 2.1 1.4 2.1 1.65 0 2.9-1.9.45-.7.8-1.6.7-1.8 1.3-3.55.1 0 2.6-5.55l21.6-8.75q-.05 1.8-.1 3.9.6 14.85 4.75 14.85 1.95 0 3.35-3.6.7-1.85 2.25-8.1 2.5-10.25 5.9-13.6.75-.8 1.55-1.2.1.2.2.45.75 1.95 1.75 7.4 1.45 5.55 3.9 6.5 2.8 1.65 3.35-7.7.5-9.35.55-10.05l20.3-8.25q0 .1-.95 6.8t2.1 3.45m36.5-5.4q-4.2.2-6.05 5.05-.55 1.5-.5 3-.4 3.8-.5 9.1.05.85.1 1.6l.05.55q.25 2.45.8 4.4 1.2 4.55 3.9 6.15.15.05.25.1V63.5q.85-12.2 2.5-4.35 1.45 3.1 1.7 8.45.45 8.15.6 7.15 1.45 1.7 2-.3.55-1.95.55-5.7Q144.4 58.3 144.5 51q.1-7.3-4.05-7.1m-14.3-25.5q-2.65-.35-4.65-1.6-.05-.05-.1-.05l.3 1.25h-.2q-.15-.1-.35-.2.2-.05.35 0-.05-.55-.1-1.05-2-.45-3.05-2.3-.85-1.45-.85-2.75 0-2.65 2.6-5.9.1-.1.2-.25 1.45-1.85 2-3Q121.9.5 120.4.1q-.4-.1-.85-.1-2.25 0-3.05 2.95-.45 1.5-.4 4.05 0 2.5-1.25 3.7-1.3 1.2-1.65.35Q108.25 8 104 7.5q-.95-.15-1.85-.15h-.95q-1.95 0-4.05.3-3.7.5-9.05 3-4.55 2.1-12.35 6.05-1.45.7-2.95 1.45-7.2 3.6-9.25 4.45-4.85 2.2-8.75 4.7-3.95 2.5 6.4-.3 10.3-2.85 13.75-4.95 2.35-1.9 8.35-4.9 4.8-2.4 8.25-3.6 1.55-.6 2.8-.9 1.75-.45 3-.45h.15l.8-.2q-.15.1-.3.25 3.1.1 2.65 1.85 0 1.95-6.75 5.45-5.7 3-6.7 3.65-1 .6-4.65 3-.4.5-4.15 3.7-3.8 3.2 11-3.5 14.8-6.75 19.65-7.65 4.9-.95 5.4-.9 2.25.85 2.4 2.85l4.05-1.75q2.25 6.15 1.9 9.6l.55 2.65q-.4 7.55-.7 20.95v5.95q.35 4 2.2 4 3.2 0 3.25-12.4-.05-1.1-.05-2.3-1.65-17.85.5-14.7 2.2 1.1 4.75 1.1 1.85 0 2.2-.35V32q-2-1.05-4.75-3.25-3.25-2.7-3.65-4.4-.05-.25-.05-.45 0-1.3 2.9-3.05Q133 19 133.3 17.3q0-1.65-1.8 0-1.05 1.1-5.35 1.1z"
                    id="ShiDre027__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M112 3.75l-6.8 44.65 24.4 6.95V52.9l-22-6.25L115.3 0 0 48.6v1.35l112-46.2z"
                    id="ShiDre027__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M148.05 98.2v-4.3l-30.85-8.25 5.6-42.45q.35-3.45-1.9-9.6L0 86.2v3.5L120.1 38l-8.05 49.85 36 10.35M129.9 2.85h.1l.05-.05q-.1-.15-.15-.3-.35-.55-.7-1.05h-.2q.1-.05.15-.05l-.9-1.4q-.15.05-.25.05l-.05-.05-.05.05-16.6 6.2q-11.1 6.05-23.55 8.85L6.4 46.2l-1 4.55 124.5-47.9z"
                    id="ShiDre027__Symbol_40_0_Layer0_0_FILL"
                />
                <g
                    id="ShiDre027__Symbol_112_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M4.25 59.25l-.1.4h.3l-.2-.4zM148.8 56.9l.05.05q-.049-.052-.05-.1v.05m0 .1l-.05-.1-.05.15.1-.05zM129.2 4.95l-.05-.05q-.05 0-.15.05h.2m-7.7 31l.05.05q.05 0 .1.05 0 .05.05.1l-.3-1.25q.05.5.1 1.05z" />
                    <path d="M129.9 6q-.35-.55-.7-1.05h-.2q.1-.05.15-.05l-.9-1.4q-.4-.55-.8-1.15-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-4 2.75-8.2 5.1-11.1 6.05-23.55 8.85-6.2 1.35-12.8 1.95-4.2 1.35-15.7 5.15Q51.5 28.25 41.4 32q-9.3 3.45-20.6 7.95l-1.6.6L7.35 45.1l-.5 2.6-2.6 11.55.2.4h-.3L0 77.05v19.1l1.55-.7v6.25q5.3-.85 10.3-1.45Q31.5 98 45.8 100.5q17.85 3.15 18.15 3.1.3-.1.6-.1 19.25-4.45 37.65-3.8.9.05 1.75.1l4.35.3q11.6.95 22.85 3.95v-4.3l16.55 4.7v-2.85l.35.1.75-44.7-.1.05.05-.15v-.05l.05.05v-.05q-.05-.3-.15-.65h.1q-.05-.25-.05-.45-.05-.1-.05-.15-.05-.3-.05-.6-.05-.15-.05-.25-.05-.2-.05-.35-.1-.45-.15-.9l-.2-1.3-.1-.4-.1-.55q0-.1-.05-.25-.05-.2-.05-.45v-.05q-7.9-24.55-8.85-26.9-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6l-1-1.6q-.1-.15-.15-.3m-8.2 30.15h-.2q-.15-.1-.35-.2.2-.05.35 0-.05-.55-.1-1.05l.3 1.25z" />
                    <path d="M121.55 36l-.05-.05q-.15-.05-.35 0 .2.1.35.2h.2q-.05-.05-.05-.1-.05-.05-.1-.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiDre027__Symbol_112_0_Layer0_0_FILL"
                transform="translate(126.85 228.6)"
                id="ShiDre027__col1n"
            />
            <use
                xlinkHref="#ShiDre027__Symbol_72_0_Layer0_0_FILL"
                transform="translate(126.85 246.75)"
                id="ShiDre027__col1d"
            />
            <use
                xlinkHref="#ShiDre027__Symbol_52_0_Layer0_0_FILL"
                transform="translate(128.4 275.45)"
                id="ShiDre027__col1s"
            />
            <use
                xlinkHref="#ShiDre027__Symbol_40_0_Layer0_0_FILL"
                transform="translate(126.85 232.1)"
                id="ShiDre027__col2n"
            />
        </g></g>
    )
}

export default ShiDre027
