import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaStu002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.2}
                    d="M191.3.35q-2.1 5.6-4.2 14.4-2.25 9.2-8.25 15.1l-.7 1.2q-.45.85-.6 1.6-.15.65-.6 2.35-.45 1.5-1.05 2.3-.05 0-.05.05-.45.8-2.3 2.85-3.1 3.35-6.65 7.7-1.8 2.15-3 4.45l-.15.25q-.5 1.05-.6 1.4-.45 1.25-1.05 3.6-.35.95-1.05 1.85l-.1.15q-.8 1.05-2.25 2.6-3.5 3.7-9.3 7.6-5.85 3.85-9.95-3.4-4.1-7.35-18.75-11.7-.05-.05-.1-.05-23.8-7.75-47.55 0-5.6 1.75-11.45 5.45-6.9 4.3-9.65 8.1-.25.25-.5.55-2.8 3.1-5.2 3.1-5.15 0-8.95-5.05-1.7-2.55-2.35-3.5-.7-1.05-1.2-1.4-1.55-2.15-4.7-4.4-3.8-2.65-5.85-7.8-2.1-5.15-6.05-7.55-4-2.45-6.45-8.1-2.5-5.7-2.5-5.6-.45-1.05-.9-2.2l-.2-.5q-.2-.5-.35-.95l-.85-2.35q-.6-1.85-1.1-3.8Q1.6 7.5.6 0 .5.1.45.45-.8 13.65 1.55 26.9q.2 1.1.45 2.25 2.1 10.8 6.7 20.9.25.55.55 1.1v-.05q.1.25.25.5 1.15 2.3 2.4 4.55 1.15 2.05 2.45 4 1.35 2.15 7.1 9.4Q27.15 76.8 36 84.2l1.75 1.3q1.35 1.05 2.8 2.1l8.2 5.35q17.8 10.2 39.2 11.8l1.1.05q3.35.2 6.8.2h.2q3.45 0 6.8-.2l1.1-.05q25.45-1.9 45.85-16l1.55-1.15q1.45-1.05 2.8-2.1l1-.7.65-.65q13.75-11.35 22.7-25.9 5.6-8.75 8.85-19.35Q193.2 19.5 191.3.35z"
                    id="BeaStu002__Symbol_3_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.251}
                    d="M178.4 44.1q9.25-14.55 12.15-34.2l-.05.05q-4.8 26.55-26.8 46.65-28.1 25.75-67.85 25.75-39.75 0-67.9-25.75Q1.95 32.8 0 0q.15 9.05 1.9 18.45l.1.3.5 1.7 1.45 4.3Q4.8 27.2 5.8 29.6q.3.7.65 1.45L8.2 35.1q.45.95.95 1.9v-.05q.1.25.25.5 1.15 2.3 2.4 4.55 1.15 2.05 2.45 4 1.35 2.15 7.1 9.4 5.7 7.25 14.55 14.65l1.75 1.3q1.35 1.05 2.8 2.1l8.2 5.35q17.8 10.2 39.2 11.8l1.1.05q3.35.2 6.8.2h.2q3.45 0 6.8-.2l1.1-.05q25.45-1.9 45.85-16l1.55-1.15q1.45-1.05 2.8-2.1l1-.7.65-.65q13.75-11.35 22.7-25.9z"
                    id="BeaStu002__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaStu002__Symbol_3_0_Layer0_0_FILL"
                transform="translate(96.55 144.5)"
                id="BeaStu002__col1n"
            />
            <use
                xlinkHref="#BeaStu002__Symbol_7_0_Layer0_0_FILL"
                transform="translate(96.65 158.65)"
                id="BeaStu002__col1d"
            />
        </g></g>
    )
}

export default BeaStu002
