import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col2n": "#FFFFFF"
}

function FacMou036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M37.5 4.75L30.2 0H13.6L0 6.75l7.7 3.1h21.05l8.75-5.1z"
                    id="FacMou036__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    d="M204.55 220.7q-.063.013-.15 0 .06.007.1 0h-.15q-12.076 2.531-23.1-.15-.05 0-.1.05-.2.15-.35.25-.3.15-.55.3-2.95 1.5-6.25 1.7-.6.05-1.25.05h-.1q-.1-.05-.15 0h-.15q-.3-.05-.65-.05h-.2q-.1-.05-.2-.05.45.35 1.2.55.4.1.85.2.05 0 .15.05 2.5-.1 4.65-1 3.55.75 6.25 1 2.236.207 2.55.35.05 0 .05.05.05-.05.15-.1.35-.3.5-.5.45-.75.55-1.05.3 0 .6.05 6.7.5 13.75-.7 1.2-.15 2.4-.35h.05l.3.1q1.05.5 1.45.65.4.15 1.35.5.9.3 2.15.45 1.15.15 3.15-.2h.15v-.05q-4.8-.15-8.25-2.2v-.05q-.15.05-.25.1-.25.05-.5.05z"
                    id="FacMou036__Layer5_0_FILL"
                />
                <g
                    id="FacMou036__Symbol_61_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M42.25 14.45q.65-.95 0-1.85-1.5-2.3-7.75-8.95-.35-.35-.7-.6-1.15-1-1.95-1.5Q30.9.9 29.75.5q-.1-.05-.3-.1Q27.3-.15 25.5.1q-.8.1-1.55.3-.1.05-.3.1-.8.25-1.45.55-.35.25-1 .2-.65-.05-1.45-.3l-.85-.3q-.7-.3-2.2-.5-1.55-.3-3.7.25-.15.05-.35.1-1.1.4-2.05 1.05-1.05.7-2.75 2.2-.4.3-1.75 1.7Q1.45 10.65 0 13q.25 1 .8 1.85Q7.25 27 20.1 20.95l.4-.2q.75-.35 1.05-.45 3.25-1.7 5.15-2.3 1.15-.4 7.15-1.25 3.35-.7 3.7-.75h.1q3.5-.45 4.6-1.55m-9.1-3.5q0-.044.05-.05-.056.006-.1.05-12.15 2.5-23.15-.2Q11.6 9.8 13.15 9q.1-.05.25-.1.3-.15.65-.25 2.85-1.1 5.45-.2l.3.05q.9.25.95.25.05 0 .15.05h.25l.45.05q.55 0 1.1-.1 3.1-1.25 6.8 0 .05 0 .15.05 1.2.2 4.35 1.95l-.8.15h.05q-.052.002-.1.05M2.2 13.8q2.5-.1 4.65-1 3.55.75 6.25 1 2.25.2 2.55.35.05 0 .05.05l.2-.1q-1.05.95-2.05 1.45-.15.05-.3.15-3.4 1.15-11.35-1.9z" />
                    <path d="M33.2 10.9q-.05.006-.05.05.048-.048.1-.05h-.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou036__Symbol_121_0_Layer0_0_FILL"
                transform="translate(172.25 216.8)"
                id="FacMou036__col2n"
            />
            <use
                xlinkHref="#FacMou036__Symbol_61_0_Layer0_0_FILL"
                transform="translate(171.3 209.8)"
                id="FacMou036__col1n"
            />
            <use xlinkHref="#FacMou036__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou036
