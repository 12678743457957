import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function ObjToo004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.8 16.85q0-.55.1-1.1Q16.7 8.35 10.95.3 9.7-.3 8.5.3L7.4 1.4q-.7-.35-2.8.95Q1.55 4.2 1.55 6.4q0 .25.05.45L.45 7.95q-.15.2-.2.45l-.1.2Q0 9 0 9.4q0 .75.85 1.3 1.3.3 4.65 2.3.8.5 1.1 1.4.2.45.45 1.4.2.5 1.6 2.25 1.55-.7 3.85-.45 4.1.35 8.05 3.35 2.95 2.3 4.5 5.1 2.05 4.9-.25 7.6-.1.05-.2.15l.6.6q5.2 3.95 7.05 1.6.85-1.1 1.3-1.7 1.35-3.15 0-5.95-1.3-2-2.95-3.75l-.1-.1h-.05q-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4z"
                    id="ObjToo004__Symbol_164_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.45 14.5q.15.4.35.8v.05q8.25 19.75 23.25 31h.4v.35q.4.25.8.55l6.2-5.9L1.45 0q-2.85 7.25 0 14.5z"
                    id="ObjToo004__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M25.85 32.4Q11.3 16.2 2.65 0L0 1.8v.05q8.25 19.75 23.25 31h.4v.35q.4.25.8.55l1.4-1.35z"
                    id="ObjToo004__Symbol_25_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.251}
                    d="M101.95 281.05v.35q.4.25.8.55l1.4-1.35q-14.55-16.2-23.2-32.4L78.3 250v.05q8.25 19.75 23.25 31h.4z"
                    id="ObjToo004__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo004__Symbol_164_0_Layer0_0_FILL"
                transform="translate(101.5 274.75)"
                id="ObjToo004__col1n"
            />
            <use
                xlinkHref="#ObjToo004__Symbol_51_0_Layer0_0_FILL"
                transform="translate(76.5 234.7)"
                id="ObjToo004__col2n"
            />
            <use
                xlinkHref="#ObjToo004__Symbol_25_0_Layer0_0_FILL"
                transform="translate(78.3 248.2)"
                id="ObjToo004__col2l"
            />
            <use xlinkHref="#ObjToo004__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo004
