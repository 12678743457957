import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E4B670",
    "col2d": "#434343",
    "col2l": "#999999",
    "col2n": "#666666"
}

function ObjToo014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.2 30.3q-5.65-9.35-11.5-28.7Q-.05-4 0 9.8l42 71.65-.05.05 6.25 10.55q2.85-.15 6.2.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.75 2.8-2 4.45.55 1.6 1 3.2.9 5.05.45 10.2 4.1 5.3 11.05 6.3 7.2-.15 9.85-7.3L25.2 30.3z"
                    id="ObjToo014__Symbol_174_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M70.8 0Q60.3 6.4 50.95 11.3q-17.5 9.2-30.9 13.25Q8.55 28.05 0 27.8q6.75 25.05 30 45.25 4.65-9.6 8.35-16.6 10.1-18.85 13.6-19.6l29.7-17.9L70.8 0z"
                    id="ObjToo014__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M38.35 31.9L20.05 0Q8.55 3.5 0 3.25 6.75 28.3 30 48.5q4.65-9.6 8.35-16.6z"
                    id="ObjToo014__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.05 30.85l19.65-11.9L19.85 0Q9.35 6.4 0 11.3l11.05 19.55z"
                    id="ObjToo014__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.251}
                    d="M31 192.6q-11.5 3.5-20.05 3.25 6.75 25.05 30 45.25 4.65-9.6 8.35-16.6L31 192.6z"
                    id="ObjToo014__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo014__Symbol_174_0_Layer0_0_FILL"
                transform="translate(64.35 194.25)"
                id="ObjToo014__col1n"
            />
            <use
                xlinkHref="#ObjToo014__Symbol_58_0_Layer0_0_FILL"
                transform="translate(10.95 168.05)"
                id="ObjToo014__col2n"
            />
            <use
                xlinkHref="#ObjToo014__Symbol_29_0_Layer0_0_FILL"
                transform="translate(10.95 192.6)"
                id="ObjToo014__col2l"
            />
            <use
                xlinkHref="#ObjToo014__Symbol_10_0_Layer0_0_FILL"
                transform="translate(61.9 168.05)"
                id="ObjToo014__col2d"
            />
            <use xlinkHref="#ObjToo014__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo014
