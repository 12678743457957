import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#583E2E"
}

function SkiMor015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2 3.95q.85 0 1.35-.6.6-.6.6-1.4 0-.8-.6-1.35Q2.85 0 2 0 1.2 0 .6.6 0 1.15 0 1.95t.6 1.4q.6.6 1.4.6z"
                    id="SkiMor015__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor015__Symbol_25_0_Layer0_0_FILL"
                transform="translate(251.9 203.55)"
                id="SkiMor015__col1n"
            />
        </g></g>
    )
}

export default SkiMor015
