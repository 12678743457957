import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#004D1E",
    "col1n": "#007E31"
}

function AccHat055({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M100.8.15q-4.6.65-12.85 4.4Q38.1 23.95 5.7 67.1q-.05.05-.6.8Q-.45 75.4.05 82.95q4.7 17.95 40.35 20.45 17.2 1.45 34.45-1.75 36.1-6.35 29.65-25.85-.9-4.2-1.5-8.35-4.4-31.4 3.65-62.9.85-5.4-5.85-4.4z"
                    id="AccHat055__Symbol_273_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M100.8.15q-4.6.65-12.85 4.4Q38.1 23.95 5.7 67.1q-.05.05-.6.8Q-.45 75.4.05 82.95q4.7 17.95 40.35 20.45 17.2 1.45 34.45-1.75 36.1-6.35 29.65-25.85-.9-4.2-1.5-8.35-4.4-31.4 3.65-62.9.85-5.4-5.85-4.4 4.85-.5 3.05 2.8-16.3 34.8-9.35 76.65 0 .25.05.55 1.1 7.6-6.85 11.1-34.75 14.65-72.95.9Q9.3 88.45 9.6 83q.05-.55.1-1.05.5-3.35 2.95-7.3 20-44.4 88.15-74.5z"
                    id="AccHat055__Symbol_181_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat055__Symbol_273_0_Layer0_0_FILL"
                transform="translate(142.4 2.8)"
                id="AccHat055__col1n"
            />
            <use
                xlinkHref="#AccHat055__Symbol_181_0_Layer0_0_FILL"
                transform="translate(142.4 2.8)"
                id="AccHat055__col1d"
            />
        </g></g>
    )
}

export default AccHat055
