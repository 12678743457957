import { useEffect, useState } from "react"
import { getPhrase } from "../../shared/translation"
import { Circle } from "../assets/Circle"

export function AppBottomPlaceholder() {
    const [showHelpWithLoading, setShowHelpWithLoading] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowHelpWithLoading(true)
        }, 3000)
    }, [])

    return (
        <div className="app-bottom">
            <div className="h-100">
                <div className="overview category-overview loading-placeholder">
                    <div className="p-3">
                        {getPhrase("Loading")}
                        {showHelpWithLoading && (
                            <div>
                                {getPhrase("Having loading problems?")}{" "}
                                <a href="https://www.sp-studio.de/w/faq/help-with-loading-problems/">
                                    {getPhrase("Click here to get help")}
                                </a>
                            </div>
                        )}
                    </div>

                    {[...Array(9)].map((e, i) => (
                        <div key={i} style={{ height: "100px" }} />
                    ))}
                </div>
            </div>

            <div
                className="app-bottom-nav with-icon-svg"
                style={{ minHeight: "40px" }}
            >
                {[...Array(5)].map((e, i) => (
                    <a key={i} href="#" className="app-bottom-nav-item h-100">
                        <Circle />
                    </a>
                ))}
            </div>
        </div>
    )
}
