import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#984969",
    "col1n": "#C67D9A"
}

function AccHat002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M193.45 83.15q-3.15-17.4-11.65-32.6-10.85-19.7-37.3-34.5v.05q-.15-.1-.25-.15-.95-.7-1.85-1.45-1.5-1.25-2.85-2.65-.7-.7-1.2-1.45-.15-.35-.35-.6-14.25-12.25-29.55-7.7l-.3.1q-.05-.05-.1-.05-4.05 1.2-8.2 3.6-.1.1-.15.2-.1.1-1.35.45-.45 0-.8.1h-.25q-1.05.1-2.1.15-.2-.05-.4 0-3.35-2.7-7.25-4.25-1.35-.55-2.7-1.15Q81.9-.1 78.8 0l-1.7.1q-1.05.25-2.35.6-2.25.6-3.85 1.3-6.8 2.85-9.15 8.1-2.35 5.15-9.65 9.55l-1.85.8q-2.7 1.2-5.8 1.95-3.75.9-6.6 1.95-3.25 1.2-5.9 3Q19 38.3 9.8 62.65q-3.3 7-5.6 14.15-.15.55-.4 1.15-1 3.2-1.15 6.3-.25 4.05-.8 10.65l.1 3.15L0 100.8v47.7q1.35 8.1 16.1 11.8l-2.75-5q-1.7-2.85-2.3-4.2-.65-1.4-1.5-3.05v.15q-3.7-8.9 1.1-19.65 2.35-5.2 6.6-10.8 5.85-7.8 15.4-16.25 64.5-60.55 129.85.9l3.55 3.55q10.25 10.7 15.05 20.55.5 1 .95 2.05l.25.55q5.8 13.85.5 25.5-.1.15-.15.3-1 2.15-2.4 4.2l-2.85 5.05q10.1.45 15-5.45 4.4-7 3.3-18.3v-21.55h-.1q.05-.4.1-.75v-15.45l-1.1-1.55q-.25-7.25-1.05-16.75l-.1-1.2z"
                    id="AccHat002__Symbol_222_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6.15 130.45q-2.3 4.65-3.9 10.25-1.15 4.15 1 7.15 2.7 3.75 7.15 5.1.25.05.55.15.15 0 .3.05.05.05.15.05-6.6-11.45-4.8-20.5l.25-.95q.05-.35.2-.7.6-2.25 1.65-4.6-1.1 1.4-2 2.95-.3.5-.55 1.05M126.5 13.8q-1.35-1.8-1.55-2.1-.8-1.2-.95-2.1-.8-3.65-4.75-5.15Q112 2.65 106.6.05q-.05-.05-.1-.05-3.25.95-6.55 2.7-.35.15-.9.4-2.65 1.1-2.9 1.2-.25.05-.4.05-.15 0-.3.05h-.1q-1.05.1-2.1.15-.2-.05-.4 0 .2.15.4.35.35 0 1.45.75 1.05.7 2.55 1.25 1.45.5 7.15.35h2.1q4.35-.05 7.25 1.25 2.85 1.3 4.3 2.85 2.15 2.3 3.5 3.3 2.75 1.95 6.45 2.45h1.95q-.5-.35-1.1-.9-1.6-1.4-2.35-2.4m60.95 130.6q-1.55-5.55-3.35-10.65-1.15-3.2-3.05-5.95-.35-.55-.75-1.4 5.35 13.3.7 24.7l.1-.4-3.45 7.75q4.6 1.05 7.95-3.15 3.55-4.6 1.85-10.9m5.3-45.5v-2.4l-.2-.3Q94.8-39.7 2.25 90.5l-.6.9-.3.4L0 93.85v2.3q.7-1 1.35-1.95.35-.5.75-1 .05-.1.1-.15 0-.05.05-.1Q94.8-37.3 192.6 98.7q.1.1.15.2z"
                    id="AccHat002__Symbol_140_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat002__Symbol_222_0_Layer0_0_FILL"
                transform="translate(94.55 43.45)"
                id="AccHat002__col1n"
            />
            <use
                xlinkHref="#AccHat002__Symbol_140_0_Layer0_0_FILL"
                transform="translate(96.35 45.6)"
                id="AccHat002__col1d"
            />
        </g></g>
    )
}

export default AccHat002
