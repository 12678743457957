import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400",
    "col2n": "#EEEEEE"
}

function ObjToy026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.65 5.1q-9.2-6.5-15.1-4.7L13.5.35q-2 1.4-3.65 2.95-1.65 1.45-3 3.1Q-1.8 16.9.45 33.85q3.25-.1 6.35.3 1.85.25 3.65.65Q22.9 37.75 33.8 49.5q11.55-12.95 10.25-31.25-10.25-1.55-18.85-7.3 1.65-3.3 3.45-5.85m155.1 29.15Q186 17.3 177.35 6.8 176 5.15 174.4 3.7q-1.7-1.55-3.65-2.95h-.05V.8q-5.95-1.8-15.15 4.7 1.8 2.55 3.45 5.85-8.6 5.75-18.85 7.3-1.3 18.3 10.3 31.25 10.85-11.75 23.35-14.7 1.75-.4 3.6-.65 3.1-.4 6.35-.3z"
                    id="ObjToy026__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 .4q5.35 6.15 11.65 10.55 1.65-3.3 3.45-5.85Q5.9-1.4 0 .4m142 5.1q1.8 2.55 3.45 5.85 6.3-4.4 11.7-10.55Q151.2-1 142 5.5z"
                    id="ObjToy026__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.8 1.85L3.15 0 0 2.75l1.8 2.2q1.35-1.65 3-3.1m169.3 1.3L171 .4l-1.65 1.85q1.6 1.45 2.95 3.1l1.8-2.2z"
                    id="ObjToy026__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy026__Symbol_115_0_Layer0_0_FILL"
                transform="translate(100.4 228.95)"
                id="ObjToy026__col1n"
            />
            <use
                xlinkHref="#ObjToy026__Symbol_70_0_Layer0_0_FILL"
                transform="translate(113.95 228.95)"
                id="ObjToy026__col1d"
            />
            <use
                xlinkHref="#ObjToy026__Symbol_30_0_Layer0_0_FILL"
                transform="translate(105.45 230.4)"
                id="ObjToy026__col2n"
            />
        </g></g>
    )
}

export default ObjToy026
