import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A37831",
    "col2d": "#252525",
    "col2n": "#000000"
}

function ObjToo019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M55.8 33.1l-4.6-20.05q.15.55.3 1.15L48.45 1q-4.3-2.5-9.15 1.4v.05l1.1 9.05h-.05l2.7 23q.15 4.95-5.35 4.4L2.9 29.6q-4.25 4.1-2.25 9.5l10.3 3.6-.2-.1 6.7 1.9q1 .3 1.8.55l16.8 4.75q6.15 1.85 15.2 8.45 1.35 1.35 2.65 2.7 3.1.7 6.1 3 2.95 2.3 4.5 5.1 1.25 3.05.85 5.25l1.3 1.8L71.7 83q9.45-.2 10.4-8.45l-6.85-9.2Q73 67.5 69.9 67.5q-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4.55-.55 1.15-.95-.4-.6-.8-1.15-4.4-6.35-6.9-12.55-1.65-4.6-2.15-6.7z"
                    id="ObjToo019__Symbol_179_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M48.85 1.45q-.85-1-3-1.3-.3 0-.55-.1-2.4-.15-5.1.4-2.4.45-3.75 1.3-.2.15-.35.2-1.55 1.1-.65 2.2.3.35 1.25.25l.2 1.3q-.1.1-.2.15-1.35 1.15-.55 2.1.3.35 1.05.3.8-.05 2.1-.5 1.7-.7 2.55-.95l.05.05q.35.4.65.55l3.2 3.95q.6.55 1.65 1.85.85 1.05 2.25 3.8l.1.1q1.6 3.25 2.75 6.55 2.55 7.2 1.8 11.55-.25 1.35-.8 2.45-1.55 3.05-2.55 4.95-1.8 3.4-3.1 5.45-1.55 2.3-2.9 4.55-.35.55-.7 1.05-1.7 2.95-2.5 4.35-1.5 2.6-2 6.05-.7 4.75-.5 7.75.25 2.95.8 5.05.5 2.1.5 2.25.2 1.35.3 2.1.2 3.05-1.35 3.65-3.1.95-5.65.25-1.05-.3-3.5-1.75-.3-.2-.55-.2-.6-.65-.85-.95-4.05-4.95-5.75-10.05-.3-.9-.55-1.9-.8-8.95-1.15-12.45-.55-5.9-1.05-7.75-1-4.25-3.8-7.7-.2-.25-.55-.55-.7-.75-2-2.1-1.85-1.9-2.2-2.05-2.35-2.15-4.65-2.75-.2-.15-.45-.15 0-.15-.05-.3.2-1.55 2.05-3.8.8-.9.95-1.35.05-.15.1-.25.1-.6-.25-1.05-.8-.95-2.15.1l-.25.25-.85-.2q.2-.9-.2-1.4-.75-.9-2.2.25-.2.2-.45.35-1.1 1.1-2.05 3.05Q.2 32.8 0 35.15q-.05.85.05 1.6.1 1.55.95 2.55.25.3.65.5.65.2 1.25-.25.45-.4.5-1.55l.6.2q.25.7.65 1.2.3.35.6.55.7.35 1.35-.2.2-.15.3-.6.15-.7.05-2.1v-.95q.85.55 2.7 1.5 2.6 1.6 4.65 3.65.85.85 1.6 1.75 2.6 3.2 3.55 7.3.4 1.55.9 5.1.45 3.55.55 4.85.1 1.25.2 1.95.05.65.15 1.5-.1 4.2.8 8.4.05-.05.2.45 1.8 5.2 5.95 10.25l.55.7q-.15.35-.2.9 0 .1.2 2.4.1.9.6 1.75.25.35 1.1 1.4 2.35 2.85 6.75 4.15 5.4 1.5 9.35-1.7 4.4-3.6 3.15-8.8-.65-2.7-2.45-4.95-.75-.9-.95-1-.6-.45-1.55-1.15-.75-.55-2.05-.9-1.2-.35-1.7-.1-.45-1.95-.6-2.85-.2-.95-.2-2.35 0-1.45.45-4.9.4-3.45 1.4-5.5 1-2.05 3.85-6.65 1.35-2.25 3.25-5.45 4.3-7.05 5.4-10.25.15-.45.25-1 1.1-4.65-1.3-12.6-2.1-6.75-4.55-10.4-.45-.55-.8-1-1.2-1.45-1.85-2.05l-3.2-3.9q.8.15 2.4.5 1.35.35 2 .4.55 0 .75-.15.55-.4.45-1.05-.05-.5-.4-.9-.6-.7-1.5-1.1l-.2-.85q2 .3 2.25.1.55-.45.4-1.15-.05-.5-.4-.95z"
                    id="ObjToo019__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M32.6 86.7q2.75.7 5.55-.5 1.7-.5 2.75-2.25 1.9-2.15 1.95-4.55 0-1.95-1-3.15-.55-.7-.9-.95-.6-.4-1.65-.65 1.15 1.85 1.5 3.65.65 3.3-2.15 5.6-2.1 1.7-5.75 1.15-2.55-.4-3.9-1.3-.2.05-.45-.1.1.15.2.35.25.45.5.7 1.1 1.4 3.35 2m-31-59.35q-1.4 1.7-1.55 2.7-.2 1 .15 3.65.1.8.05 1.35l.6.2Q.5 34.2.65 32.7q.2-2.1 1.3-4.35 1-1.95 2.1-2.95l-.85-.2q-.2.45-1.6 2.15m40-26.5q1.15.1 2.05.5l-.2-.85q-.2-.15-1.7-.4-1.5-.3-2.95.15-1.45.4-2.95.8-1.4.4-2.3.4l.2 1.3q1.2-.9 3.35-1.5 2.4-.6 4.5-.4z"
                    id="ObjToo019__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo019__Symbol_179_0_Layer0_0_FILL"
                transform="translate(62.05 231.75)"
                id="ObjToo019__col1n"
            />
            <use
                xlinkHref="#ObjToo019__Symbol_63_0_Layer0_0_FILL"
                transform="translate(65.2 235)"
                id="ObjToo019__col2n"
            />
            <use
                xlinkHref="#ObjToo019__Symbol_13_0_Layer0_0_FILL"
                transform="translate(68.35 237.95)"
                id="ObjToo019__col2d"
            />
        </g></g>
    )
}

export default ObjToo019
