import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSpe004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M101 14.9L90.45 0h-79.8l-.05.05V0L0 14.95l3.05 38.8q4 1.6 7.05 5.35 4.95 6.15 8.45 13.2.65 1.3 1.25 2.65l.05.1q1.55 2.8 2.2 5.6 0-.05.05-.05 2-4.15 6.6-6.55 1.85 2.2 3.2 4.15 2.05 3 5.05 5.35 1.5 1.15 3.25 1.65 1.5.45 2.75.55h.15l-.1-.4q-.45-2.05.2-3.55 2.6-5 9.2-3.55 3.2 1 5.05 4.65 1.35 2.65 1.4 5.8v.55q9.1-4.05 10.9-12.45v-1q2.35 1.05 3.3 2.65.95 1.55 1.05 3.4 2.35-1.25 5.6-6.15l5.7-8.7q.9-1.5 1.9-2.95 1.7-2.55 3.65-4.95 3.05-3.75 6.95-5.3l3.1-38.9z"
                    id="HaiSpe004__Symbol_136_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.75 0L0 14.8l3.1 39.15q4.1 1.4 7.15 5.15 4.95 6.15 8.45 13.2.65 1.3 1.25 2.65l.05.1q1.55 2.8 2.2 5.6L10.75.05V0m90.6 14.8L90.6 0v.05L79.85 75.7l5.7-8.7q.9-1.5 1.9-2.95 1.7-2.55 3.65-4.95 3.05-3.75 7.15-5.15l3.1-39.15z"
                    id="HaiSpe004__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M21.75 85.75h.15l-.1-.4-.05.4M0 0l1.35 42.2L2.4.05 0 0m21.25 49.4L23.2 0h-2.95l.9 49.75q.1-.15.1-.35M50.8 27.2L52.3 0h-2.15l.6 27.35q.05-.05.05-.15m6.55-5.5L58.9 0h-1.6v22.5q.05-.75.05-.8z"
                    id="HaiSpe004__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe004__Symbol_136_0_Layer0_0_FILL"
                transform="translate(141.85 16.45)"
                id="HaiSpe004__col1n"
            />
            <use
                xlinkHref="#HaiSpe004__Symbol_76_0_Layer0_0_FILL"
                transform="translate(141.7 16.45)"
                id="HaiSpe004__col1d"
            />
            <use
                xlinkHref="#HaiSpe004__Symbol_42_0_Layer0_0_FILL"
                transform="translate(163.05 16.45)"
                id="HaiSpe004__col1s"
            />
        </g></g>
    )
}

export default HaiSpe004
