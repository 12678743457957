import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#E4B670"
}

function ObjToy051({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#999"
                    d="M306.8 149.6q-.238.118-.35.35-3.619 7.695-5.2 12.5-2.289 7.051-4.45 19.25-.577 3.25-.85 7.5-.304 5.548-.1 12.8.04.261.05.5.442 12.734-1.1 20.55-2.08 10.696-5 17.2-4.01 9.003-11.85 16.5h.05q-12.38 11.733-15.15 18-2.166 4.788-3.55 9.45v.05q-.675 2.599-1.7 6.6-.044.238.05.45.156.214.4.25.238.095.45-.05.213-.106.25-.35 1.025-3.999 1.7-6.6 1.366-4.588 3.5-9.3 2.743-6.134 14.85-17.65 8.01-7.654 12.1-16.85 2.98-6.596 5.1-17.45 1.558-7.934 1.1-20.85-.008-.213-.05-.45-.2-7.264.1-12.8.287-4.131.85-7.3 2.138-12.101 4.4-19.1 1.569-4.745 5.15-12.35.108-.222 0-.45-.067-.238-.3-.35-.221-.108-.45-.05z"
                    id="ObjToy051__Layer11_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M92.4 61.05l-1.2-1.75Q73.85 34.9 63.05 19.55q-1.85-2.7-3.45-4.95l.25.4q-6.1-7.75-8.35-10.55-.2-.2-.35-.4v.05L47.4 0l-2.1 5-.1.3-.05.1q-18.5 40.95-43.3 70.35l.05.05L0 79l2.05 3.9q.05.1.15.25 0 .05.05.15.05.15.15.3 13.25 27.55 19.1 44.8 3.3 9.65 4.5 13.2l.05.1q0 .15.1.3l1.2 4.8 6.7-5.4q.3-.2.7-.55l6.5-6.5q6.05-6.1 18.35-19.7v-.3q16.5-21.5 32.1-45.15.1-.2.2-.35l2.85-4.35-1.95-2.9q-.2-.3-.4-.55m-67.25 77.5l.15.45q-.1-.2-.15-.4v-.05z"
                    id="ObjToy051__Symbol_180_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M91.75 62.55Q94.2 60 83.6 64.4q-10.65 4.4-25.5 7.65-.1 0-.25.05v.05l-2.4.4q-.15 0-.35.05-1.6.3-3.2.5-.4.05-.8.15-.55.05-1.15.15 4.95-34.9-1.05-71-1.85-5.15-2.25.8 6.4 35.65.5 70.5-23.7 3-45 .65-3 .8-1.85 1.95T2 77.45q21.15 1.7 45.15-1.1-3.85 22.15-11 40.8-5.3 14.65-9.95 22.95.9-.1 2.2-.2.25-.05.5-.15.55-.1 1.65-1.75 12.7-27.25 19.1-62 22.3-3.25 41-12.4.5-.45 1.1-1.05z"
                    id="ObjToy051__Symbol_181_0_Layer0_0_FILL"
                />
                <path
                    fill="#F3AD00"
                    d="M337.4 181.75q9.05 3.75 15.55 11.05l.2.3q2.75 4.3 2.75 10.7 0 4.9-2.5 8.25-2.6 3.5-6.65 3.5-1.2 0-3.65-1.05-.05-.05-.1-.05 2.565 2.397 7.65 2.75l.35.05q5.755.213 10.4-5.8 1.5-3.036 1.5-7.15 0-8.2-4.4-13.9-1.55-1.95-3.6-3.65-1.1-.9-2.45-1.75-2.45-1.15-5.45-1.9-4.587-1.197-9.8-1.4.114.007.2.05z"
                    id="ObjToy051__Layer2_1_FILL"
                />
                <path
                    fill="#F3AD00"
                    d="M253.95 85.05q-.112.091-.25.15-3.636 4.03-1.7 10.65-.122-2.795 3.25-4.85 3.422-2.064 5.55-2.1 2.12-.037 3.05.05.778.075 1.25.1-.266-.276-.55-.9-.45-.85-.6-1.7-.25-1.2-.25-3.2 0-1.305.15-1.95-1.338-.446-2.7 0-4.3 1.45-7.2 3.75z"
                    id="ObjToy051__Layer2_2_FILL"
                />
                <path
                    fill="#F3AD00"
                    d="M370.65 92.55q-2.519.126-4.45.9-4.522 2.67-3.2 6.75.552 1.31 1.35 2.45.1.1.2.25.05-2.45 2.7-3.75 2.35-1.15 5.15-.85v-.25q-2.15-.8-1.75-5.5z"
                    id="ObjToy051__Layer2_3_FILL"
                />
                <path
                    fill="#F3AD00"
                    d="M276.95 79.75q-.925.61-1.2 1.8-.2.675-.2 1.4 0 1.445.8 2.5.19.24.4.45.912.912 2.1 1.15.427.1.9.1 1.75 0 2.95-1.25 1.25-1.2 1.25-2.95 0-1.75-1.25-3-1.2-1.2-2.95-1.2-1.598 0-2.8 1m95.6-14.95q-1.6 0-2.75 1-.95.6-1.25 1.85-.2.6-.2 1.35 0 1.45.85 2.55.15.2.35.4.85.85 1.55.95.483.186.55.2.45.1.9.1.32 0 .6-.05 1.37-.179 2.35-1.2 1.25-1.2 1.25-2.95 0-1.75-1.25-3-1.2-1.2-2.95-1.2z"
                    id="ObjToy051__Layer2_4_FILL"
                />
                <path
                    fill="#010287"
                    d="M295.1 140.8q-.975-.34-1.25.9-.222 1.287 1.5 3.1 1.718 1.813 2.2 2.6.05.346.05.65 0 2-3.2 2.8-3.2.85-3.2 1.3 0 .74.45 1.15.29.225.7.35.7.2 2.35.2 1.35 0 2.4-.15.75-.1 3.45-.55h.25q-.248-.938-.3-1.9v-.05q-.15-.55-.1-1.1-.15-.5-.15-1.05-.05-.1-.05-.2-.1-1.7.45-2.95.2-.9.8-1.55.55-.55 1.1-.95-1.719.138-4.1-1.05-2.38-1.205-3.35-1.55m30.7 3.6q-1.244-.004-1.2.1-.388-.102-2 .1-1.85.2-3.1.45-3.45 1.05-4.85 1.35.65.95 1 2 0 .2.1.35.3 1.35-.2 2.9-.3.85-.75 1.7-.219.306-.5.65h.2q3.5 0 6.1 1.55 1.641.979 2.55 1.35 1.054.34 1.25.2.2-.144.4-.25.2-.099.2-.5 0-1.25-1.7-2.8-1.7-1.6-1.7-2.85 0-.85.15-1.05l.4-.4q.103-.222.35-.45.622-.405 2-.75 2.41-.602 2.65-2.1.231-1.491-1.35-1.55z"
                    id="ObjToy051__Layer2_5_FILL"
                />
                <g id="ObjToy051__Layer11_1_FILL">
                    <path
                        fill="#00F"
                        d="M287.55 240q-1-1.2-2.35-.4-3 2.2-3.5 5.8 0 .6.6 1 .9.55 2.6.1.2 0 .45.05.05.05.15-.05.15.2.25.35.2.15.4.35.1.05.25.05.1.15.25.2.05.05.15.1.25.2.45.25.3.1.5.05l.1.2q0 .2.1.35.45 1.75 1.3 2.3.65.35 1.25.05 2.95-2.1 3.5-5.7.1-1.55-1.5-1.9-1-.25-2.4.25-.35.1-.7.25l-.2-.2q-.05 0-.05-.05l-.2-.05q-.05-.05-.25-.15-.15-.1-.25-.15.05-.3.05-.75-.3-1.45-.95-2.3m5.9-46.2q-1.4-.55-2.15.8-1.55 3.4-.2 6.8.3.5 1.1.55 1 .05 2.3-1.2.15-.1.35-.2.1 0 .15-.1.2.1.4.15.2.05.5.1.1 0 .2-.05.2.05.35.05h.2q.3.05.5 0 .3-.1.45-.2l.2.1q.1.15.25.25 1.3 1.3 2.3 1.35.7 0 1.05-.55 1.55-3.3.2-6.75-.65-1.4-2.2-.9-1 .35-2 1.4-.25.3-.45.65-.1-.05-.25-.05-.05 0-.15-.05l-.1.05q-.2-.05-.4-.05-.1.05-.2.05-.15-.3-.4-.7-.95-1.1-2-1.5z"
                    />
                    <path
                        fill="#FD0202"
                        d="M295.15 228q-.1-.05-.25-.1-.05-.05-.1-.05l-.15-.05q-.15-.1-.35-.1-.1-.05-.2 0-.1-.4-.25-.75-.6-1.35-1.45-2-1.25-1-2.35.15-2.4 2.8-2 6.45.2.6.85.8.95.3 2.55-.45.2-.05.35-.1.1-.05.2-.05.1.15.35.25.2.15.45.2l.2.1.4.1q.1.05.2.05.25.1.45.1.25.05.45 0 .1.05.15.15.15.15.2.3.9 1.5 1.85 1.8.7.25 1.15-.15 2.4-2.8 2-6.45-.2-1.55-1.85-1.45-1.05.05-2.25.8-.35.2-.6.45m3.15-47.3q-.05-.1-.2-.1-.05 0-.1-.05h-.15q-.15-.1-.35-.05-.15-.05-.3 0-.15-.35-.3-.75-.8-1.25-1.65-1.75-1.4-.8-2.35.45-2 3.1-1.2 6.65.25.6.95.7 1 .2 2.5-.85.15 0 .3-.1.15 0 .25-.05.1.15.35.25.2.05.5.1.1.05.2.05.2.05.4.05.1.05.2.05.3.05.45.05.25 0 .45-.1l.15.15.3.2q1.05 1.45 2.05 1.65.65.1 1.1-.35 2.05-3.05 1.2-6.65-.45-1.5-2-1.2-1.1.1-2.2 1.05-.3.3-.55.6z"
                    />
                    <path
                        fill="#18EE00"
                        d="M291.25 216.75q.3.55 1.05.6 1 .05 2.35-1.15.15-.1.35-.2.1 0 .15-.1l.4.2q.2.05.5.1.1 0 .2-.05.2.05.35.05h.2q.3.05.5 0 .3-.05.45-.15l.2.1q.1.15.25.25 1.25 1.35 2.25 1.4.7 0 1.1-.5 1.6-3.3.35-6.75-.6-1.4-2.2-.95-1 .3-2 1.35l-.5.6q-.1-.05-.25-.05-.05 0-.1-.05l-.15.05q-.15-.05-.35-.05-.15.05-.25.05-.1-.3-.35-.7-.95-1.15-1.95-1.55-1.4-.6-2.2.75-1.6 3.35-.35 6.75m13.35-44.5q2.2-2.9 1.55-6.55-.35-1.5-2-1.3-1 .1-2.15.95-.3.3-.55.5-.1-.05-.25-.05-.05 0-.1-.05l-.15-.05q-.15-.05-.35-.05-.15 0-.25-.05-.15-.3-.3-.7-.7-1.35-1.6-1.9-1.3-.8-2.3.35-2.2 2.95-1.55 6.6.2.6.9.75.95.25 2.5-.7.15-.1.4-.15.1 0 .2-.05.1.1.3.25.2.1.5.15.1.05.2.05.2.05.4.05.1.1.2.1.3.05.5.1.3-.05.45-.1.1.1.2.15.05.15.15.25 1 1.55 1.95 1.75.7.2 1.15-.3z"
                    />
                </g>
                <g id="ObjToy051__Layer11_2_FILL">
                    <path
                        fill="#010287"
                        d="M286.9 243.4q-.35.2-.6.55l-.1.2h-.05q-.1.1-.15.35-.15.1-.2.25-.25.35-.3.7-.15.5-.1.95 0 .05.1.1.15.2.25.35.2.15.4.35.1.05.25.05.1.15.25.2.05.05.15.1.25.2.45.25.3.1.5.05.1.05.2.05.35-.15.7-.5.25-.2.45-.55l.25-.25q.05-.25.1-.35.05.05.05-.05t.1-.15q.2-.5.25-.8.25-1-.45-1.6l-.2-.2q-.05 0-.05-.05l-.2-.05q-.05-.05-.25-.15-.15-.1-.25-.15-.75-.25-1.55.35m10.05-47.35q-.1-.05-.25-.05-.05 0-.15-.05l-.1.05q-.2-.05-.4-.05-.1.05-.2.05-.8.15-1.2 1.05-.2.35-.25.8 0 .1.05.2-.05.05 0 .05-.1.15-.05.35-.05.2-.05.35-.05.4.05.75.2.5.45.85.05.05.15.05.2.1.4.15.2.05.5.1.1 0 .2-.05.2.05.35.05h.2q.3.05.5 0 .3-.1.45-.2.1-.05.15-.1.3-.25.4-.75.1-.3.15-.7 0-.15.05-.35-.05-.2-.05-.35.05 0 0-.05 0-.1.05-.2-.15-.55-.25-.85-.25-.95-1.15-1.1z"
                    />
                    <path
                        fill="#C40000"
                        d="M295.15 228q-.1-.05-.25-.1-.05-.05-.1-.05l-.15-.05q-.15-.1-.35-.1-.1-.05-.2 0-.9-.15-1.45.6-.3.3-.5.75 0 .1-.05.2-.05.05 0 .1-.1.15-.15.35-.1.15-.15.3-.1.4-.1.75 0 .45.15.85l.15.15q.1.15.35.25.2.15.45.2l.2.1.4.1q.1.05.2.05.25.1.45.1.25.05.45 0 .1-.05.15-.05.4-.25.65-.6.2-.3.3-.7.05-.15.15-.3.05-.2.05-.35.05-.05 0-.1.05-.1.05-.2.1-.5.15-.9-.1-.95-.85-1.35m3.15-47.3q-.05-.1-.2-.1-.05 0-.1-.05h-.15q-.15-.1-.35-.05-.15-.05-.3 0-.85 0-1.35.85-.2.3-.35.75-.05.1-.05.2-.05.05 0 .1-.1.15-.1.35-.05.2-.1.35-.05.4 0 .75.05.5.3.85.05.05.15.1.1.15.35.25.2.05.5.1.1.05.2.05.2.05.4.05.1.05.2.05.3.05.45.05.25 0 .45-.1.1 0 .15-.05.35-.25.6-.7.15-.3.2-.7.05-.15.1-.35v-.4q.05 0 0-.1 0-.1.05-.2.05-.45-.05-.8-.2-.95-1-1.25z"
                    />
                    <path
                        fill="#14C900"
                        d="M296.95 211.5q-.05 0-.1-.05l-.15.05q-.15-.05-.35-.05-.15.05-.25.05-.8.1-1.2 1-.2.35-.25.8v.2q-.05.05 0 .05-.1.15-.05.35-.05.2-.05.35-.05.4.05.75.15.5.4.85.05.05.15.05l.4.2q.2.05.5.1.1 0 .2-.05.2.05.35.05h.2q.3.05.5 0 .3-.05.45-.15.1-.05.15-.1.3-.25.45-.75.1-.3.15-.7 0-.15.05-.35-.05-.2-.05-.35.05 0 0-.05 0-.1.05-.2-.1-.55-.2-.85-.25-.95-1.15-1.15-.1-.05-.25-.05m3.4-45.9q-.85 0-1.45.75-.2.3-.35.8-.05.1-.05.2-.05 0 0 .05-.15.1-.15.3-.05.2-.1.35-.1.4-.1.75.05.5.3.9.05.05.15.1.1.1.3.25.2.1.5.15.1.05.2.05.2.05.4.05.1.1.2.1.3.05.5.1.3-.05.45-.1.1 0 .15-.05.35-.25.55-.7.2-.25.3-.65.05-.15.1-.35 0-.2.05-.35.05 0 0-.05 0-.1.05-.2.05-.55 0-.9-.1-.9-.9-1.3-.1-.05-.25-.05-.05 0-.1-.05l-.15-.05q-.15-.05-.35-.05-.15 0-.25-.05z"
                    />
                </g>
                <g id="ObjToy051__Layer2_0_FILL">
                    <path
                        fill="#FFE600"
                        d="M252.1 96.15q-.048-.145 0-3.55.043-3.364 1.6-7.4-2.297 2.01-3.3 4.35-4.192 10.487.45 15.75 3.31 2.337 6.6 3.25 8.377 1.352 15.05-3 6.594-4.383 5.95-12.65.065-1.317.1-2.85.045-1.656.3-3 1.411-3.839-.45-2.6-1.86 1.24-2.05 1-.787 1.036-1.25 2.1-1.2 2.7-1.2 7.5 0 3.25-3.5 5.1-2.042 1.07-4.8 1.45-7.999 1.136-12.5-3.6-.56-.825-1-1.85m106.25 119.2q0-.05.05-.05 1.893-1.64 3-3.85-3.35 2.61-7.8 4.65-8.062.722-10.6-1.65-.35-.3-.65-.65-3.098-3.534-2.5-11.5-.27.189-.7.35-1.25.4-1.45.45h-2.45l-1.55-.3q-.95-.4-1-.45-.318-.232-.55-.45-.161-.026-.25-.1v.05q-1.676 11.36 6.55 15.85 3.014 1.35 8.45 1.35 7.2 0 11.45-3.7m-29.2-33.55q4.2-.3 8.05-.1 5.773 1.668 9.5 2.45 3.733.833 5.75.85-5.4-3.45-15.15-6.6-14.05-4.5-17.65-6.5-8-4.55-8-12.2 0-1.6.35-3.6-.842.491-1.9.8-.131.05-.3.1l-.15.05-.45.1q-.025.012-.05 0-.045.04-.1.05H309q-.734.147-1.4.2-.467.08-.9.1 0 .05-.05.1 0 1.039-.25 2.45-1.088 7.954 6.45 14.25 1.145.979 2.5 1.95 1.5 1.05 2.9 1.95 6.4 3.95 10.9 3.6m43.6-109.6q-.105-.822-.65 0-.512.881-1 .7-.25 1.434-.5 2.35-.45 1.55-.45 3.55 0 5.8 3 11.25 3 5.4 3 7.25 0 3.75-2.5 6-2.2 2-5.35 1.75-2.25-.15-3.75-2.15-.1-.146-.35-.75-.193-.605.1-3.25.332-2.601 1.9-5.45-1.697.716-2.95 1.9-3.292 2.848-2.5 8.6.737 4.478 4.95 6.6 5.305 2.255 9.8 0 2.21-1.221 4.3-3.55 2.1-2.9 2.1-7.45 0-3.85-4.35-10.95-4.4-7.15-4.4-13.4v-2.05q-.23-.133-.4-.95z"
                    />
                    <path
                        fill="#010287"
                        d="M301.45 154.8q-.09.032-.2.05.127.065.3.1l-.05-.1-.05-.05z"
                    />
                    <path
                        fill="#00F"
                        d="M329.9 144.35q-.55-2.4-1.3-3.05-2.3-2.25-8.45.6-1.56.746-6 3.9l-.2-.2-.2-.2q-4.252-4.283-10.15-2.65-.165.11-.4.2-.231.164-.5.35-2.15-2.6-4.25-4.2-6.5-5.15-9 .95-.615 1.478-1.1 2.85-1.863 6.4 0 12.75.711 1.074 1.35 1.5.8.5 2.1.5 1.7 0 5.75-1.4 1.83-.67 3.7-1.4.11-.018.2-.05 2.428 2.92 6.15 2.6.666-.053 1.4-.2h.05q.055-.01.1-.05.025.012.05 0l.45-.1.15-.05q.169-.05.3-.1 1.058-.309 1.9-.8 1-.6 1.75-1.4.75 1.1 1.8 2.65 4.9 5.7 8.75 4.85 3.75-.9 5.2-9.05.95-6.4.4-8.8z"
                    />
                    <path
                        fill="#F3AD00"
                        d="M327.55 2.45q-1.6 0-2.75 1-.95.6-1.25 1.85-.2.6-.2 1.35 0 1.45.85 2.55.15.2.35.4.9.9 2.1 1.15.45.1.9.1 1.75 0 2.95-1.25 1.25-1.2 1.25-2.95 0-1.75-1.25-3-1.2-1.2-2.95-1.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy051__Layer11_0_FILL" />
            <use xlinkHref="#ObjToy051__Layer11_1_FILL" />
            <use xlinkHref="#ObjToy051__Layer11_2_FILL" />
            <use
                xlinkHref="#ObjToy051__Symbol_180_0_Layer0_0_FILL"
                transform="translate(279.4 3.7)"
                id="ObjToy051__col1n"
            />
            <use
                xlinkHref="#ObjToy051__Symbol_181_0_Layer0_0_FILL"
                transform="translate(280.9 7.1)"
                id="ObjToy051__col2n"
            />
            <use xlinkHref="#ObjToy051__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy051__Layer2_1_FILL" />
            <use xlinkHref="#ObjToy051__Layer2_2_FILL" />
            <use xlinkHref="#ObjToy051__Layer2_3_FILL" />
            <use xlinkHref="#ObjToy051__Layer2_4_FILL" />
            <use xlinkHref="#ObjToy051__Layer2_5_FILL" />
        </g></g>
    )
}

export default ObjToy051
