import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo048({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#46003D"
                    d="M97.5 272.65q-8.5-.75-14.55 1.2-4.55 1.4-7.55 4.35-1.9 2-3.05 4.8-.2.35-.4.6-3.2 10.6 4.4 17.85 5.75 5.05 15.55 5.85 4.5.35 9.75-.05 4-.1 7.8-.7.846-.082 1.65-.2-1-1.381-1-3.05 0-2.95 3.1-5 3.1-2.1 7.5-2.1t7.5 2.1q2.031 1.343 2.7 3.05.116-.013.2-.05 15.5-6.2 25.6-18.85.1-.45.1-.9.004-.034 0-.1 1.772-2.49 4.4-9.85 0-1.7-5.6-4.8-5.95-3.35-9.6-2.55-3.75.3-5.15.95-.576.258-.95.65-.263-.104-.6-.2-.05 0-.1.05-16.65 8.8-41.7 6.95z"
                    id="ObjFoo048__Layer16_0_FILL"
                />
                <path
                    fill="#94DB28"
                    d="M159 259.65h-.05q-.7-.5-1.5-.8-4.6-2.25-9.6 0-.65.25-1.3.6-1 .5-3.25 1.7h-.05q-5.45 2.15-8.7 2.35 1.15.65 3.25 1.55v.1q.3.15.6.2.5.15.9.3.35.1.65.2.3.05.5.2.75.15 1.3.25 3.35.85 7.15-.6-3.35 5.95-2.75 12.65.2.6.25 1.05 1.55-3.05 3.75-4.3 2.3-1.35 4.35-.75 1.9.55 3.2.25.25 0 .35-.05.9-.1 1.25-.2-.95 1.2-1.35 2.25-.85 2-1.15 4.95 0 .45-.1.9-.2 2.5-.35 3.55l-.15.35h.05q1.25-.85 4.75-5.4.4-.6.8-1.05.1-.1.15-.2l.25-.25q1.45-2 2.4-3.5.95-1.45 1.35-2.45.1-.25.1-.45.5-1.1.5-2.4.2-2.35-.95-3.95-.25-.4-.65-.75.25-.1.4-.15.15-.05.25-.1 1.6-.75 2.65-1.35 1.2-.65 1.6-.9 1.7-1.15 2.25-2.3.3-.6.3-1.2 0-1-.15-1.4-.2-.6-.85-1.4-5.35 3.25-8.3 3.25-2.55 0-4.1-.75z"
                    id="ObjFoo048__Layer16_1_FILL"
                />
                <path
                    fill="#7E1C72"
                    d="M87.6 286.05q-2.3 1-3.35 3.3-.8 1.9-.55 3.85.6 3.25 4.8 5.1.65.2 1.25.4 4.7 1.45 9.3 1.7 3.65.15 7.5-.2 2.532-.13 4.9-.45.704-.758 1.75-1.45 3.1-2.1 7.5-2.1 2.288 0 4.2.55 16.37-5.37 30.2-19.25-.65-.5-.95-.65-1.75-.7-4.15 1.5-1.9 1.95-2.6 4.15-2.35-2.05-3.05-4.55-.3-.85-.45-2.1-9.3 6.25-19.6 9.15-12.35 2.75-28.8.3-.05 0-.05.05-5.15-.4-7.85.7z"
                    id="ObjFoo048__Layer16_2_FILL"
                />
                <path
                    fill="#6AA60F"
                    d="M162.6 266.25q.1.1.2.1.2.15.45.4 3.05 2.25 1.4 7.7l-.05.1q0 .2-.2.55.1.05-.05.2-.8 2.2-2.15 4.15 1.45-2 2.4-3.5.95-1.45 1.35-2.45.1-.25.1-.45.5-1.1.5-2.4.2-2.35-.95-3.95-.25-.4-.65-.75-1.2.4-2.35.3m-3.6-6.6h-.05q-.7-.5-1.5-.8-4.6-2.25-9.6 0-.65.25-1.3.6-1 .5-3.25 1.7 4.45-1.75 5.7-1.8 1.3-.15 1.55-.15.35 0 1.05.05.8 0 1.8.3 1 .2 1.9.45.4.1.75.3.05.05.1.05-1.1.45-2.25 1.55-1.65 1.7-2.65 4.3l.05.05q.9-1.2 1.6-1.75 1.35-1.2 3.25-1.7 1.5-.35 3.6-.15h.55q3.7-.4 6.25-1.5.8-.35 3.15-1.75.75-.5 1.55-.95.7.4.8 2.25 0 .2.05.45.3-.6.3-1.2 0-1-.15-1.4-.2-.6-.85-1.4-5.35 3.25-8.3 3.25-2.55 0-4.1-.75z"
                    id="ObjFoo048__Layer16_3_FILL"
                />
            </defs>
            <use xlinkHref="#ObjFoo048__Layer16_0_FILL" />
            <use xlinkHref="#ObjFoo048__Layer16_1_FILL" />
            <use xlinkHref="#ObjFoo048__Layer16_2_FILL" />
            <use xlinkHref="#ObjFoo048__Layer16_3_FILL" />
        </g></g>
    )
}

export default ObjFoo048
