import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E4B670",
    "col2n": "#000000"
}
function FacMou074({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M28.75 11.4q1.52-3.166 1.5-6.9-.034-.698-.35-1.15-.15-.15-.35-.3Q28.4 2.2 26.1 1.5q-1.2-.4-2.6-.65-8.3-1.7-16.6 0-1.35.25-2.6.65Q2 2.2.85 3.05q-.204.156-.55.55-.16.217-.25.85.022 4.445 4.3 8.7.108.058.2.1 4.75 2.2 10.65 2.5 6.3-.35 11.1-2.8 1.5-.773 2.45-1.55z"
                    id="FacMou074__Symbol_132_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M43.35.45l-.1.4Q42.3 5 38.6 7.35q-4.8 3.05-10.3 3.75-5.5-.7-10.3-3.75Q14.3 5 13.35.85l-.1-.4V0q-6.8 2.15-10.4 7.2-4.85 6.45-1.4 14.85.85 1.3 2.05 2.45 1.2 1.15 2.55 1.95Q8.1 27.6 10.5 28q0 .65.05 1.25.3 3.05 1.75 4.75 3.3 4 7.05 4.7 8.85 1.25 17.9 0Q41 38 44.3 34q1.45-1.7 1.75-4.75.05-.6.05-1.25 2.4-.4 4.45-1.55 1.35-.8 2.55-1.95 1.2-1.15 2.05-2.45 3.45-8.4-1.4-14.85Q50.3 2.3 43.8.15q-.2-.1-.45-.15v.45z"
                    id="FacMou074__Symbol_99_0_Layer0_0_FILL"
                />
                <g id="FacMou074__Layer1_0_FILL">
                    <path
                        fill="#FFF"
                        fillOpacity={0.902}
                        d="M200.05 199.05q-.7-.1-3.4-.15h-8.3q-2.7.05-3.4.15-.5.15-.5.5 0 .78 2.25 1.35 5.506 1.468 11.55 0 2.3-.57 2.3-1.35 0-.35-.5-.5z"
                    />
                    <path d="M210.7 226.25q-.1-.4-.4-.65-.298-.213-.6-.15-4.87.776-9.1-1.05-4.146-1.543-5.95-3.85-.327-.296-.6-.9-.48-1.08-.75-3.1-.084-.69-.15-1.5l-.65-4.3-.65 4.3q-.05.8-.15 1.5-.25 2-.75 3.1-.25.6-.6.95-1.8 2.25-5.95 3.8-4.25 1.85-9.1 1.1-.3-.1-.6.1-.3.25-.4.65-.1.4.15.7.2.3.55.4 4.75.5 8.8-.65 2-.7 4.15-1.75 2.15-1.1 4.55-2.55 2.38 1.467 4.55 2.55 2.163 1.065 4.1 1.75 4.088 1.175 8.85.6.333-.046.55-.35.25-.3.15-.7z" />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou074__Symbol_132_0_Layer0_0_FILL"
                transform="translate(177.3 194.8)"
                id="FacMou074__col2n"
            />
            <use
                xlinkHref="#FacMou074__Symbol_99_0_Layer0_0_FILL"
                transform="translate(164.2 199.45)"
                id="FacMou074__col1n"
            />
            <use xlinkHref="#FacMou074__Layer1_0_FILL" />
        </g></g>
    )
}
export default FacMou074
