import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}

function AccMas026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M191.35 225v-5.5H180.2v5.5h11.15m-13.65 8.55v-6.15h-11.4l.9 6.15h10.5m2.5-6.15v6.15h11.05v-6.15H180.2m-2.5-7.9h-12.6l.85 5.5h11.75v-5.5m27.15 5.5v-5.5h-11v5.5h11m7.9 21.25q4.15-3.7 4.8-10.55H167.5q.65 6.869 4.8 10.55 5.883 1.586 11.5 2 4.923.363 8.25.35.32.004.6 0 .185.003.35 0 3.316-.001 8.2-.35 5.65-.4 11.55-2m-7.9-12.7v-6.15H193.8v6.15h11.05m12.05 0h.95l.9-6.15h-11.4v6.15h9.55m3.05-14.05h-12.6v5.5h11.75l.85-5.5m40.3-126.95q-1.85-2.4-3.95-4.7-12.605-14.286-27.8-21.75-37.667-14.234-72 0-15.145 7.464-27.75 21.75-2.1 2.3-3.95 4.7-2 2.5-3.75 5.1-.2 0-.4.05-.2.55-.5 1-.55.75-.85 1.5-.2.25-.35.55-1 1.45-1.9 3-1.466 2.474-2.8 5-10.074 17.813-12.3 38.1-3.256 21.016 6.3 36.65 10.6 18.35 35.95 23.15.184.065.35.1 13.545 1.677 16.35 4.6.41.574.85 1.2.95 1.3 1.95 3 .55.9 1.1 1.95h55.45q.55-1.05 1.1-1.95 1-1.7 1.95-3 .45-.65.85-1.15 2.8-2.95 16.3-4.65.2-.05.4-.05 25.35-4.85 35.95-23.15 9.55-15.7 6.25-36.65-2.2-20.35-12.25-38.1-1.35-2.6-2.8-5.05-.9-1.55-1.9-3-.15-.3-.35-.55-.3-.75-.85-1.5-.3-.45-.5-1-.2-.05-.4-.05-1.75-2.6-3.75-5.1m-27.9-22.95v.05h-.05l.05-.05m-13.45 64.55q11.7.95 20.55 11.1 8.8 10.15 9.6 23.6.05 1.1.05 2.25.15 12-6.85 20.2-2.85 3.35-6.35 5.3-9.15 4.1-18.75 2.3-9.6-1.65-17.15-10.05-3.3-3.8-5.45-8-.1-.3-.25-.6-.724-1.525-1.3-3.15-.207-.542-.4-1.15l-.7 1.5q-.05.205-.15.4-.444 1.229-1 2.4-.15.3-.25.6-2.15 4.2-5.45 8-7.535 8.388-17.2 10-9.537 1.863-18.75-2.3-3.428-1.915-6.3-5.25-7-8.2-6.85-20.2 0-1.15.05-2.25.8-13.45 9.6-23.6 8.85-10.15 20.55-11.1l1.2-.1h2.5q4.75.1 9.5 1.85 4.45 1.95 8 6.1 3.2 3.65 4.9 8.1.075.18.25.7.092-.145.15-.3.076-.196.15-.4 1.7-4.45 4.9-8.1 3.55-4.15 8-6.1 4.75-1.75 9.5-1.85h2.5l1.2.1m-28.55 55.65h4.35l3.85 16.1H186.5l3.85-16.1m91.45-21.55l.05-.05v.2q-.002-.053-.05-.15m-129.1-98.6v-.05l.05.05h-.05m-49.5 98.55l.05.05q-.048.097-.05.15v-.2z"
                    id="AccMas026__Symbol_31_0_Layer0_0_FILL"
                />
                <g
                    id="AccMas026__Symbol_109_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M192.5 96.25q0-39.85-28.2-68.05-12.55-12.55-27.35-19.5Q118.4 0 96.25 0 74.1 0 55.6 8.7q-14.85 6.95-27.4 19.5Q3 53.4.35 87.95 0 92.05 0 96.25q0 39.85 28.2 68.05 24 24 56.4 27.6 5.7.6 11.65.6 39.85 0 68.05-28.2 28.2-28.2 28.2-68.05m-6.95 14.6l.05-.05v.2q-.002-.053-.05-.15M96.2 95l.25-.45q6.7-18.8 26.45-16.8 10.4 1.3 18.35 10.45 8.4 9.75 9.15 22.55.05 1.1.05 2.2.15 11.45-6.55 19.3-2.9 3.35-6.4 5.3-8 3.6-17.55 2-9.15-1.6-16.3-9.7-3.15-3.55-5.15-7.6-.1-.3-.25-.55-.85-1.8-1.5-3.7-.05-.1-.05-.15-.1-.25-.15-.45.05-.1-.2-.85l-.55 1.55v-.1q-.1.35-.25.7l-.05.2q-.55 1.4-1.2 2.8-.15.25-.25.55-2 4.05-5.15 7.6-7.15 8.1-16.3 9.7-9.55 1.6-17.55-2-3.5-1.95-6.4-5.3-6.7-7.85-6.55-19.3 0-1.1.05-2.2.75-12.8 9.15-22.55 7.95-9.15 18.35-10.45Q89.4 75.75 96.2 95M6.95 110.8l.05.05q-.048.097-.05.15v-.2m39.8 67.95q-.2-.15-.35-.25l-.05-.05q.2.1.4.25v.05m99.05-.05q.2-.15.4-.25l-.05.05q-.15.1-.35.25v-.05m-49.1-63.9l.05.1q0-.05-.05-.15v.05z" />
                    <path d="M146.2 178.45q-.2.1-.4.25v.05q.2-.15.35-.25l.05-.05m-99.8.05q.15.1.35.25v-.05q-.2-.15-.4-.25l.05.05zM7 110.85l-.05-.05v.2q.002-.053.05-.15m178.6-.05l-.05.05q.048.097.05.15v-.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccMas026__Symbol_109_0_Layer0_0_FILL"
                transform="translate(96.25 57.4)"
                id="AccMas026__col1n"
            />
            <use
                xlinkHref="#AccMas026__Symbol_31_0_Layer0_0_FILL"
                id="AccMas026__col2n"
            />
        </g></g>
    )
}

export default AccMas026
