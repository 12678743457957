import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#004D1E",
    "col2l": "#00B145",
    "col2n": "#007E31"
}

function AccHat078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.502}
                    d="M156.4 64.15q7.35-31.7-1.6-64.15H5.35q-10.4 33-.6 63.45 72.75 43.05 151.65.7z"
                    id="AccHat078__Symbol_295_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M188.4 146.3q10.4-19.5 11.15-43 .05-1.75.05-3.55 0-41.3-29.25-70.55Q141.1 0 99.8 0T29.2 29.2Q0 58.45 0 99.75v.65q.15 25.85 11.75 46.9.1.2.2.35 4.45 8 10.55 15.25 3.1 3.75 6.7 7.35 29.3 29.25 70.6 29.25t70.55-29.25l15.6-19.7q1.1-1.8 2.15-3.65l.3-.6M169.3 75q8.4 30.1 1.5 59.5-66.4 35.25-128.05 6.75-.4-.2-.75-.35-1.35-.65-2.65-1.25Q32 136.2 28.8 134.5q-6.9-29.4 1.5-59.5h139z"
                    id="AccHat078__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M118.5 26.5q-4.6-6.15-11.7-11.55Q87.2 0 59.4 0 31.6 0 11.95 14.95 4.9 20.35.45 26.5q-.25.3-.45.6.35-.3.85-.6 58.35-27.05 116.7 0 1.05.7 1.85 1.3l-.9-1.3z"
                    id="AccHat078__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M175.15 164.2q-2.3 2.45-4.8 4.8-26.1 24.65-61.8 27.35-4 .35-8.1.35-2.15 0-4.25-.1-39.05-1.15-67-27.6-10.5-9.95-17.25-21.35 4.45 8 10.55 15.25 3.1 3.75 6.7 7.35 29.3 29.25 70.6 29.25t70.55-29.25l4.8-6.05m24.4-61.9v1q.05-1.75.05-3.55 0-41.3-29.25-70.55Q141.1 0 99.8 0T29.2 29.2Q0 58.45 0 99.75v.65q.7-37.8 29.2-64.85Q56.85 9.4 95.45 8.05l4.3-.1h.7q1.95 0 3.85.1l4.1.25q35.8 2.6 61.95 27.25 29.2 27.7 29.2 66.75m-12.7-18.05q-4.35-11.15-12.2-11.75H24.95q-7.85.6-12.2 11.75-5.45 16.85-2.4 37.05 2.35 12.75 17.8 20.85 7 2.95 14.6 5.4 63.35 22.3 128.7-5.4 15.45-8.1 17.8-20.85 3.05-20.2-2.4-37.05M169.3 75q8.4 30.1 1.5 59.5-66.4 35.25-128.05 6.75-.4-.2-.75-.35-1.35-.65-2.65-1.25Q32 136.2 28.8 134.5q-6.9-29.4 1.5-59.5h139z"
                    id="AccHat078__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat078__Symbol_295_0_Layer0_0_FILL"
                transform="translate(113.75 122.85)"
                id="AccHat078__col1n"
            />
            <use
                xlinkHref="#AccHat078__Symbol_100_0_Layer0_0_FILL"
                transform="translate(92.7 50.55)"
                id="AccHat078__col2n"
            />
            <use
                xlinkHref="#AccHat078__Symbol_48_0_Layer0_0_FILL"
                transform="translate(132.7 71.75)"
                id="AccHat078__col2l"
            />
            <use
                xlinkHref="#AccHat078__Symbol_34_0_Layer0_0_FILL"
                transform="translate(92.7 50.55)"
                id="AccHat078__col2d"
            />
        </g></g>
    )
}

export default AccHat078
