import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666",
    "col2d": "#121212",
    "col2l": "#434343",
    "col2n": "#252525"
}

function ObjMus009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.25 31.65l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1H8.7q-3.55 1.35-6.45.45H2.1q-.25-.1-.5-.25-.2-.1-.35-.2L0 43.8q.05.1.15.15l.3 6.75Q3.1 54 7.7 52.25l3.55-6.1.1-.1L16.9 28.2q-1.2-.55-2.25-1.6-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5m8.15-18l3.05-9.8q-2.6.15-5.05-.2-4.95-.7-9.3-3.65L6.3 16.65q.25.05.55.15 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4 2.25-2.25 5.4-2.25.7 0 1.35.1z"
                    id="ObjMus009__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M13.2 21.3l-.1.35q.5 2.3.25 4.85-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.45.4-.9.75l-2.55 8.85Q2 42.35 0 40.3l.3 6.75q2.65 3.3 7.25 1.55l3.55-6.1.1-.1 5.55-17.85q-1.2-.55-2.25-1.6-.8-.8-1.3-1.65M21.25 10L24.3.2q-2.6.15-5.05-.2l-3.1 10.9q1.7-1 3.75-1 .7 0 1.35.1z"
                    id="ObjMus009__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M24.25 5.1l-.5-.6q-2.05-2.3-5-3.45-.75-.3-1.6-.55-.8-.2-1.5-.3-.15 0-.25-.05-1.5-.2-2.95-.1-1.9.2-3.85.9l-.05.1q-.85.35-1.7.8-.7.45-1.35.85-2.6 1.95-4.05 4.85 0 .05-.25.15-.3.05-.55.55-.3.4 0 1.3-.1.3-.15.55-1.45 5.45 1.35 10.3.4.65.8 1.25.05.05.1.2-1.05 1.45.1 2.95 4.35 2.95 9.3 3.65 2.45.35 5.05.2 1.85-.75 1.95-2.6.65-.3 1.3-.65 4.9-2.85 6.3-8.25.1-.2.1-.4.45-.15.65-.75.15-.6.1-1-.1-.45-.4-.6.25-3.25-1.15-6.25-.3-.7-.65-1.35-.55-.9-1.15-1.7z"
                    id="ObjMus009__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M13.9 8q-.3-.7-.65-1.35-.55-.9-1.15-1.7l-.5-.6Q9.55 2.05 6.6.9 5.85.6 5 .35q-.8-.2-1.5-.3-.15 0-.25-.05 5.5 5.15 3.05 14.55l.05.05q.65 1.7-.6 2.75H5.7q-1.2 4.3-4.65 8.45h.1Q1.3 27.7 0 28.3q2.45.35 5.05.2Q6.9 27.75 7 25.9q.65-.3 1.3-.65 4.9-2.85 6.3-8.25.1-.2.1-.4.45-.15.65-.75.15-.6.1-1-.1-.45-.4-.6Q15.3 11 13.9 8z"
                    id="ObjMus009__Symbol_12_0_Layer0_0_FILL"
                />
                <g id="ObjMus009__Symbol_7_0_Layer0_0_FILL" fill={colors.col2l}>
                    <path
                        fillOpacity={0.651}
                        d="M12.8 18.4q.15 1.9-1.15 2.5 2.45.35 5.05.2 1.85-.75 1.95-2.6-3.1.25-5.85-.1M27.1 7.45q-.1-.45-.4-.6-4.6.7-8.5.3 0 .15-.25 0l.05.05q.65 1.7-.6 2.75h.05q3.05.35 6.05-.05 1.25-.2 2.25-.5.35-.1.6-.2.45-.15.65-.75.15-.6.1-1z"
                    />
                    <path d="M2.25 14.15q-.05-.05-.1-.05.05.05.1.2-1.05 1.45.1 2.95 4.35 2.95 9.3 3.65 1.3-.6 1.15-2.5h-.1Q7 17.6 2.25 14.15M18 7.2l-.05-.05Q8.7 6 .95 0 .95.05.7.15.4.2.15.7q-.3.4 0 1.3.8 1.1 2.25 2.25 3.85 3 10.05 4.7 2.35.6 4.9 1h.05q1.25-1.05.6-2.75z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus009__Symbol_95_0_Layer0_0_FILL"
                transform="translate(111.9 270.4)"
                id="ObjMus009__col1n"
            />
            <use
                xlinkHref="#ObjMus009__Symbol_75_0_Layer0_0_FILL"
                transform="translate(112.05 274.05)"
                id="ObjMus009__col1d"
            />
            <use
                xlinkHref="#ObjMus009__Symbol_26_0_Layer0_0_FILL"
                transform="translate(119.15 245.6)"
                id="ObjMus009__col2n"
            />
            <use
                xlinkHref="#ObjMus009__Symbol_12_0_Layer0_0_FILL"
                transform="translate(131.3 245.75)"
                id="ObjMus009__col2d"
            />
            <use
                xlinkHref="#ObjMus009__Symbol_7_0_Layer0_0_FILL"
                transform="translate(119.65 253.15)"
                id="ObjMus009__col2l"
            />
        </g></g>
    )
}

export default ObjMus009
