import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#AC8268",
    "col1n": "#C0A594"
}

function ObjFoo033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#ABACFE"
                    d="M146.7 206.1h-.15l-12-3.05q-.1-.05-.35-.05-2.9-.4-3.5 1.95l-.6 4.5v.15l-1.05 7.75v.05l-2.3 17.2v.05q.05.3.35.5.1.1.3.15.5.3 1.25.3t1.35-.3q.25-.1.35-.25.05-.15.1-.25.1-.05.1-.1h.1l1.95-13.55q.15-.9.3-1.7l-.15.65.4-2.9.05-.1v-.25q.4-2.4.6-4.45l.4-3.05.05-.1q0-.25.2-.4.2-.55.6-.65.2-.15.55-.15h.1q.35-.05.95.1l8.95 2.15h.3q.35-.05.8-.2.1-.05.2-.15.3-.2.55-.6 0-.1.1-.1.15-.3.2-.6.35-.85-.05-1.65-.35-.8-1-.9z"
                    id="ObjFoo033__Layer16_2_FILL"
                />
                <path
                    fill="#5051FC"
                    d="M146.55 206.1q-.7-.15-1.25.3-.55.45-.85 1.3-.25.8.05 1.55.25.65.75.95.1 0 .3.05.05 0 .35.05.35-.05.8-.2.1-.05.2-.15.3-.2.55-.6 0-.1.1-.1.15-.3.2-.6.35-.85-.05-1.65-.35-.8-1-.9h-.15z"
                    id="ObjFoo033__Layer16_3_FILL"
                />
                <path
                    fill="#FEC94E"
                    d="M119.2 236.15q.05 0 .1-.05l-8.2-15.55q-3.93-6.603-1.55.6l8.2 14.95q.75.45 1.45.05z"
                    id="ObjFoo033__Layer16_4_FILL"
                />
                <path
                    fill="#FE5FFE"
                    d="M91.2 226.2q1.95 1.2 18.2-5l7.35-2.95q9.05-4.15 8.7-6.2l-19.1-3.15-.5-.15-2.75 1.2-11.9 16.25z"
                    id="ObjFoo033__Layer16_5_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M34.35 5.2q-.1-.3-.25-.65-.9-1.65-4.8-2.95-1.65-.55-3.65-.9-.25-.1-.5-.15L23.4.45l-.45 2.85h-.1q0 .05-.1.1-.05.1-.1.25-.1.15-.35.25-.6.3-1.35.3-.75 0-1.25-.3-.2-.05-.3-.15-.3-.2-.35-.5V3.2L19.5.1l-.3-.05Q18.2 0 17.1 0q-3.55 0-6.55.4-.6.1-1.1.2l2.15 4.1q-.05.05-.1.05-.7.4-1.45-.05L7.95.85q-1.9.4-3 .75Q.9 3 .15 4.75q-.15.2-.15.4 1.1.25 2.4.65 3.95.95 8.9 1.25.55 0 1.15.1H12.9q2.15.1 4.5.1h1.45l3.3-.1h.3q5.6-.3 9.95-1.35 1.05-.25 1.95-.6z"
                    id="ObjFoo033__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.45 1.5q-.7.4-1.45-.05l1 1.9h.05l.2.45q.55 0 1.15.1h.35l-.25-.45.05.05-1-2.05q-.05.05-.1.05M12.4 3.9l.5-3.85h-.1q0 .05-.1.1-.05.1-.1.25-.1.15-.35.25-.6.3-1.35.3-.75 0-1.25-.3-.2-.05-.3-.15Q9.05.3 9 0l-.7 4h.5l3.3-.1h.3z"
                    id="ObjFoo033__Symbol_44_0_Layer0_0_FILL"
                />
                <g id="ObjFoo033__Layer16_0_FILL">
                    <path
                        fill="#FFF"
                        d="M102.2 207.65l.9 2.3 2.75-1.2.5.15-.55-1.15-.35.2-.65-1.35-2.6 1.05z"
                    />
                    <path
                        fill="#EEE"
                        d="M139.9 229.4q-4.15-.95-8.25-1.35l-1 6.65h-.1q0 .05-.1.1-.05.1-.1.25-.1.15-.35.25-.6.3-1.35.3-.75 0-1.25-.3-.2-.05-.3-.15-.3-.2-.35-.5v-.05l.9-6.85q-6.25-.25-12.3.85l3.95 7.5q-.05.05-.1.05-.7.4-1.45-.05l-3.95-7.2q-1.15.2-2.3.5-.8.15-1.4.3-6.2 1.55-6.2 3.8 0 .3.15.6.05.1.15.15.1.25.3.45.15.25.25.5 3.85 7.2 2.45 15.45-.7 4.15-1.45 8.55-.85 2.55-.85 5.3 0 .5.05 1.15.4 6.7 5.85 11.55 4.05 3.55 9.1 4.75.2.05.45.1v9.45q-.55.05-1.1.15-2.7.35-5 1.1-4.2 1.45-4.2 3.45 0 2.05 4.2 3.45 4.35 1.5 10.35 1.5 6.05 0 10.35-1.5 4.25-1.4 4.25-3.45 0-.61-.4-1.2-.539 1.089-1.45 2-2.25 2.25-5.4 2.25-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4 1.26-1.26 2.8-1.85v-2.1q.25-.05.55-.15 1.1-.25 2.1-.5l4.15-1.8q1.65-1.05 3.25-2.45 5.5-4.85 5.9-11.65v-1.05q0-2.75-.85-5.3-.7-4.4-1.4-8.55-.75-4.65.15-8.95.25-1.55.8-3.15.7-2 1.85-4l.15-.15q.3-.55.3-1.15v-.3q-.05-.3-.25-.65-1.1-1.65-5.95-3-.1 0-.2-.05m-12.25 9.2l2.2-.05q-.55.05-1.05.05-.7 0-1.4.05l.25-.05z"
                    />
                </g>
                <g id="ObjFoo033__Layer16_1_FILL">
                    <path
                        fill="#DDD"
                        d="M120.35 282.05v2.25q2.4.45 4.95.45 1.4 0 2.75-.15.3 0 .6-.05v.05l.7-.05v-2.3l-.7.1q-1.6.2-3.35.2-2.55 0-4.95-.5m0 12.9v-3.45q-.55.05-1.1.15l1.1 3.3m19.75-65.5q-.1 0-.2-.05-4.15-.95-8.25-1.35-2.216-.08-4.05-.1l.05-.2q-6.25-.25-12.3.85l.05.15q-.76.067-1.6.15-1.15.2-2.3.5-.8.15-1.4.3-6.2 1.55-6.2 3.8 0 .3.15.6.05.1.15.15.1.25.3.45.9.95 3.2 1.85 1.1.25 2.4.65 3.95.95 8.9 1.25.55 0 1.15.1H120.6q2.15.1 4.5.1h1.45l3.3-.1h.3q5.6-.3 9.95-1.35 1.05-.25 1.95-.6 2.4-.75 3.5-1.6.2-.15.3-.3l.15-.15q.3-.55.3-1.15v-.3q-.05-.3-.25-.65-1.1-1.65-5.95-3m-9.65 5.35q-.05.1-.1.25-.1.15-.35.25-.6.3-1.35.3-.268 0-.5-.05-8.766 1.566-5 .75 3.72-.766 7.4-1.6-.023.061-.1.1z"
                    />
                    <path
                        fill="#FFF"
                        d="M131.15 246.45v.75q0 1.15.1 2.3v.05q1.85 13.55-5.7 22.6 2-1.4 4.25-2.75 12.55-9.35 8-16.9-1.4-2.35-4.4-4.6-1-.75-2.25-1.45z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo033__Layer16_0_FILL" />
            <use xlinkHref="#ObjFoo033__Layer16_1_FILL" />
            <use xlinkHref="#ObjFoo033__Layer16_2_FILL" />
            <use xlinkHref="#ObjFoo033__Layer16_3_FILL" />
            <use xlinkHref="#ObjFoo033__Layer16_4_FILL" />
            <use xlinkHref="#ObjFoo033__Layer16_5_FILL" />
            <use
                xlinkHref="#ObjFoo033__Symbol_64_0_Layer0_0_FILL"
                transform="translate(107.7 231.4)"
                id="ObjFoo033__col1n"
            />
            <use
                xlinkHref="#ObjFoo033__Symbol_44_0_Layer0_0_FILL"
                transform="translate(117.75 234.65)"
                id="ObjFoo033__col1d"
            />
        </g></g>
    )
}

export default ObjFoo033
