import { AssetSvgProps } from "../../../shared/assets"

function ObjToy058({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#2F2F2F"
                    d="M387.5 330.45v-7.75h-13.75v-6.4q-19.002-8.788-42.4-14.85l-18.25 7.15-31.8 25.85-1.7 4.25q.275 1.188.65 2.35.35 1.15.85 2.35 0 .05.05.1.4 1.35 2 1.8l15.8 6.55h.05q.15.25.3.4l.05.1q-1.319 1.76-4.7 4.05-4.65 3-4.6 7.65 0 1.15.55 3.25.45 1.9.45 3.4 0 2.75-2.2 5.5-1.1 1.3-5.2 5.05-8 7.2-7.95 14.25 0 4.3 3.4 7.85h.05q2.05 2.1 7.2 5.25 5.2 3.2 7 4.8 2.9 2.8 2.95 6.05 0 1.75-.7 2.9l-.05.05q-.15.2-2.3 2.6-4.2 4.6-4.65 5.2-1.2 1.55-2.1 3.25-1.05 1.7-1.05 5.7 0 3.95 1 5.6.9 1.5 12.4 16.2.2.25.5.3.3.05.55-.15.25-.2.3-.5.05-.3-.15-.55-11.4-14.6-12.3-16.05-.8-1.4-.8-4.85 0-3.5.85-4.95v-.05q.9-1.6 2-3.05.45-.6 4.55-5.1 2.35-2.6 2.5-2.85.95-1.45.95-3.7.05-3.85-3.4-7.15h-.05q-1.8-1.7-7.2-5-4.95-3.05-6.95-5-3-3.1-3-6.8.05-6.45 7.45-13.15 4.2-3.85 5.35-5.2 2.55-3.2 2.55-6.45 0-1.7-.5-3.75-.5-1.85-.5-2.9.05-3.85 3.95-6.4 3.838-2.583 5.25-4.65.205.048.4.05h.55q.1.05.3.05l27.1 11.2q5.75 2.6 6 2.25v.1q.252-.009.5-.05v-3.7h9.1v2.05q.1-.013.2-.05v-.2l39.05-30.7.3-.2 2.3-1.85q.85-.7.95-1.5m-52.45 27.2l.05-.05-.05.05z"
                    id="ObjToy058__Layer11_0_FILL"
                />
                <path
                    d="M372.3 314.3l-.7-.3q-.35-.15-.75-.25-.4-.2-.75-.35l-.7-.3q-.35-.15-.7-.25-.35-.2-.75-.35-.4-.1-.75-.3-.35-.1-.75-.25l-.7-.3q-.35-.15-.75-.3-.35-.1-.75-.25-.4-.2-.75-.3-.4-.15-.75-.3-.4-.1-.75-.3-.4-.15-.75-.25-.4-.15-.75-.3l-.8-.3q-.4-.15-.8-.25-.4-.15-.75-.3-.4-.1-.8-.25-.35-.15-.75-.25-.4-.2-.8-.35-.4-.1-.8-.25t-.8-.25q-.4-.15-.75-.3-.4-.1-.8-.25t-.8-.25q-.4-.15-.85-.3-.4-.1-.8-.25t-.85-.2q-.4-.2-.8-.3-.4-.1-.8-.25t-.85-.3q-.4-.1-.8-.25-.45-.15-.85-.2l-.8-.3q-.45-.1-.85-.25-.45-.15-.85-.2-.4-.15-.85-.3-.4-.15-.8-.2-.45-.15-.85-.3-.45-.1-.9-.25-.4-.1-.85-.2-.45-.15-.85-.25-.45-.15-.9-.25t-.9-.25q-.45-.1-.85-.2l-.9-.3q-.45-.05-.85-.2-.45-.15-.9-.2-.45-.15-.9-.25-.45-.15-.85-.2l-1.85 1.25q25.1 7.5 49 17.6l1.8-1.7.15-.1v-.05q-.5-.2-1-.45-.4-.1-.75-.3-.35-.15-.65-.3-.4-.2-.75-.3-.35-.2-.7-.35-.35-.1-.7-.3-.35-.1-.7-.3l-.7-.3q-.4-.15-.75-.3l-.7-.3z"
                    id="ObjToy058__Layer2_0_MEMBER_0_FILL"
                />
                <path
                    fill="#666"
                    d="M330.85 301.95q.45.15.9.25.4.15.85.3l1.5-1.15q-.45-.05-.85-.2-.45-.15-.9-.2l-1.5 1m12.75 2.1q-.45-.15-.85-.2l-1.65 1.25.8.3q.45.1.85.25l1.7-1.35q-.45-.1-.85-.25m-5.95-1.7q-.45-.15-.9-.25t-.9-.25l-1.5 1.15q.4.1.85.25.4.15.85.25l1.6-1.15m2.6.7q-.45-.1-.9-.25l-1.6 1.25q.4.15.85.25.4.15.85.3l1.65-1.25q-.45-.15-.85-.3m22.5 7.45q-.4-.1-.75-.3l-1.75 1.55q.4.15.75.3.4.1.75.3l1.75-1.55q-.4-.15-.75-.3m-10.35-1.6l1.8-1.45q-.4-.15-.75-.3-.4-.1-.8-.25l-1.8 1.5q.4.1.8.25.35.15.75.25m-4.75-1.65l.8.3q.4.1.8.3l1.75-1.5q-.4-.1-.8-.25t-.85-.2l-1.7 1.35m-1.55-2.45l-1.7 1.4q.4.1.8.25t.85.3l1.7-1.4q-.4-.15-.85-.3-.4-.1-.8-.25m10.5 3.5q-.4-.2-.8-.35l-1.8 1.55q.4.1.8.25.4.2.8.3l1.75-1.5q-.35-.15-.75-.25m3.1 1.05q-.4-.15-.8-.25l-1.7 1.5q.35.15.75.3t.75.3l1.8-1.55-.8-.3m18.25 7.4q-.35-.15-.65-.3-.4-.2-.75-.3l-1.45 1.45.7.3q.4.15.75.3l1.4-1.45m-8.55-3.65q-.35-.15-.7-.25-.35-.2-.75-.35l-1.65 1.55q.4.15.75.3t.75.25l1.6-1.5m-3.65-1.45q-.35-.15-.75-.3l-1.7 1.55q.4.15.75.3t.75.25l1.65-1.5-.7-.3m5.85 2.35q-.35-.15-.75-.25l-1.6 1.45q.35.2.75.3.4.15.75.3l1.55-1.5-.7-.3m2.85 1.2l-.7-.3-1.55 1.5q.35.15.75.3l.7.3 1.5-1.5q-.35-.1-.7-.3z"
                    id="ObjToy058__Layer2_0_MEMBER_0_MEMBER_0_FILL"
                />
                <path
                    d="M365.9 329.75q1.55-.85 2.45-1.95.65-.85.9-1.9 1.2-4.65-6.55-10-7.65-5.3-19.75-8.25-1.7-.45-3.35-.75-9.45-1.8-17.05-.25-7.6 1.5-10 5.05-2.45 3.6 1.9 7.6 1.3 1.15 3.1 2.4 4.45 3.15 10.4 5.4 4.3 1.65 9.35 2.9 12.05 2.9 21.4 1.7 4.6-.6 7.2-1.95m2.45-4.3q-.3 1.1-1.1 1.95-1.25 1.5-3.85 2.4-2.15.8-5.3 1.15-9.05 1.15-20.7-1.6-9.55-2.35-16.3-6.1-1.5-.85-2.85-1.8-1.7-1.15-2.95-2.3-4.25-3.75-1.9-7.15 2.4-3.4 9.75-4.85 7.35-1.4 16.45.3 1.6.3 3.25.7 11.7 2.75 19.15 7.85 7.5 5.05 6.35 9.45m-26.6-17.25q-1.45-.35-2.75-.65-7.8-1.5-14.05-.2-6.25 1.3-8.25 4.3-2.05 3 1.6 6.4 1.05 1 2.55 2 6.3 4.5 16.2 7 9.95 2.45 17.7 1.45 5.45-.7 7.5-2.9.85-.9 1.15-2 .95-3.9-5.35-8.4-6.4-4.5-16.3-7M339 308q1.3.3 2.65.6 9.65 2.35 15.75 6.65 6.15 4.3 5.2 8-.3 1.15-1.25 2.05-2.05 1.9-7.1 2.55-7.45.95-17.1-1.35-9.65-2.4-15.75-6.65-1.4-.95-2.4-1.95-3.55-3.2-1.55-6.05 1.5-2.2 5.45-3.4 1.2-.4 2.5-.65 6.1-1.2 13.6.2m-11.8 2.9l-.8 1.05-1.7-.65-1.3 1.4q-.35.75.35 1.45.35.05.7.2 14.5 4.6 28.2 10.35l.15.1.75.25q1.6.15 2.85-.55l1.2-1.35-1.6-.55.85-1.2-29.65-10.5z"
                    id="ObjToy058__Layer2_0_MEMBER_1_FILL"
                />
                <path
                    fill="#999"
                    d="M317.6 337.3l-.8.95.75.3.75-1-.7-.25m-2.3.4l.7.25.85-.95-.65-.25-.9.95m-7.2-3.05l-.1.2 6.65 2.55.8-.95-7.05-2.8q.05.5-.3 1m55.3-4.85q-7.2 1.25-16.95.25-12.75-1.95-25.35-6.8 6.75 3.75 16.3 6.1 11.65 2.75 20.7 1.6 3.15-.35 5.3-1.15m-38.6-17.9l-.45.45 32 11.75.4-.5-31.95-11.7m-.35 29.35q.45-.65 1.4-.7l-6.65-2.65-.85 1 6.1 2.35z"
                    id="ObjToy058__Layer2_0_MEMBER_2_FILL"
                />
                <path
                    fill="red"
                    d="M301.4 331q-1.65-.65-2.45.5-.2.2-.35.4-.5.8.75 1.3l5.55 2.2q.85.3 1.5-.15l.1-.05.6-.5q.1-.1.1-.2.7-.9-.45-1.45-.05-.05-.1-.05l-5.25-2m23.55 11.3q-.5.75.75 1.2l5.45 2.3q.9.3 1.6-.2h.1q.3-.25.55-.5.15-.15.2-.25.6-.9-.7-1.45l-5.25-2.05q-1.35-.5-2.2.25l-.05.05q-.05.1-.15.2-.25.25-.3.45z"
                    id="ObjToy058__Layer2_0_MEMBER_4_FILL"
                />
                <g id="ObjToy058__Layer2_0_FILL">
                    <path d="M298.45 346.1q-.65-.3-.85.15-.1.15-.15.45l.05.95-16.75-6.85q-.05-1.75.2-3.2.15-.65.3-1.15 0-.05.05-.1v-.05l-.15-.1q-1.3-.15-2.05-1.5.15 3.25 1.15 6.35.35 1.15.85 2.35v-.05q-.2-1.05-.3-2.05l16.75 6.85.05 1.3q-.05.9.65 1.1h.1l.6 1.3h.05q.15.25.3.4.05.1.1.15l.05-.05q.084-.18.2-.4.5-.85.5-.9.378-1.09.7-1.05h.3q.7 0 .65.95 0 .35-.25 1-.28.702-.65.75-.091.178-.2.3.219.048.45.05h.55q.1.05.3.05l1.55-.2.3-3.4-4.9-1.85q.5-.65.8-1l-1.25-.55h-.05m35.55-4.35q-.2-.1-.35-.15l-31.2-11.95q-.2-.1-.4-.15-2.95-.8-4.45.9-1.85 1.95.3 3.2l32.25 12.55q3.05 1.1 4.4-.9v-.05q.35-.55.45-1.1.4-1.6-1-2.35m.05-9q-.4-.1-.8-.25-2.9-.9-5.75-1.9-.9-.3-1.8-.7-1.8-.7-3.55-1.4-3-1.35-5.9-2.8l-.2-.1q-2.5-1.3-4.9-2.75-.4-.2-.85-.45-.5-.35-1-.55-1.05-.55-2.1-.8-4.4-1.2-9.2 1.4-9.75 6.05-16.7 13.7v.15h.1q7.7-7.8 17.1-13.7 2.25-1.2 4.15-1.4h.8q2.35-.35 5.4 1.2.25.05.45.15.7.35 1.35.8l3.45 1.95-2.3 5.7 15.5 6.05 6.1-3.9.1.05q5.65 1.7 11.3 2.8.75.15 1.3.5 2.3 1.25 1.8 3.95-.2.95-.6 1.85l-.6 1.25q-.3.5-.65 1 .15-.2.3-.35l1.3-1.9q2.55-5-2.3-6.7-.25-.1-.55-.2-.4-.15-.8-.2-5.1-1.05-9.95-2.45m50.2 1.05v-2.1l3.25-2.55v-.4l-42.65 33.05-.05-2.85q-4.85.85-9.7-1.15-.05.2-.1.35l-.3 1.3q-.35 1.65-.25 3.35l-18.75-7.7v-1q.094-.935-.6-1.2-.048.013-.1 0l-9.85-4.05-.2 3.3q-.15.05-.3.15h-.1q-.2.05-.7.4-.05 0-.1.05l11.2 4.5q.079.033.15.05.47.101.6-.55v-1.2l18.8 7.7q.1 1.6.6 3.3v.1q5.6-.2 9.8-1.75l-.05-2.7 12.55-9.65v2.35l.25-.2v-2.4l26.3-20.35V334l.3-.2m-48.35-12.9l-.65-2.05-3.2.65 3.85 1.4z" />
                    <path
                        fill="#484848"
                        d="M334.95 357.8l.1-.15-53.65-21.35h-.1v.05q-.05.05-.05.1-.15.5-.3 1.15l53.75 21.85.3-1.3q.05-.15.1-.35h-.15m44.75-40.25l-.15.1q-2.324.364-3.9.65-20.696-10.09-44.25-15.9-.24-.74-.8-1.9-.4-.1-.8-.25-4.85-1.1-7.8 1.05l-17.5 11.65q-15.6 10.05-25.4 21.7v.05q.75 1.35 2.05 1.5l.15.1v-.15q6.95-7.65 16.7-13.7 4.8-2.6 9.2-1.4 1.05.25 2.1.8.5.2 1 .55.45.25.85.45 2.4 1.45 4.9 2.75l.2.1q2.9 1.45 5.9 2.8 1.75.7 3.55 1.4.9.4 1.8.7 2.85 1 5.75 1.9.4.15.8.25 4.85 1.4 9.95 2.45.4.05.8.2.3.1.55.2 4.85 1.7 2.3 6.7l-1.3 1.9q-.15.15-.3.35l-.2.3q-4.75 6.7-10.75 12.75v.2q4.85 2 9.7 1.15.05-.05.1-.15 9-10.95 22.1-20.55l20.5-15.15.05-.65q-.75-1.65-2.65-2.5-2.55-1.25-5.2-2.4m-10.45 8.35q-.25 1.05-.9 1.9-.9 1.1-2.45 1.95-2.6 1.35-7.2 1.95-9.35 1.2-21.4-1.7-5.05-1.25-9.35-2.9-5.95-2.25-10.4-5.4-1.8-1.25-3.1-2.4-4.35-4-1.9-7.6 2.4-3.55 10-5.05 7.6-1.55 17.05.25 1.65.3 3.35.75 12.1 2.95 19.75 8.25 7.75 5.35 6.55 10m-10.2-4.65q-.2-.15-.5-.35-7.8-4.7-16.5-7.65-7.35-2.5-15.3-3.75-.65-.15-1.2-.15-.9.05-1.6.3-1.8.55-3.1 1.85-1.25 1.25.6 1.85l2.3.8q-.7-.7-.35-1.45l1.3-1.4 1.7.65.8-1.05 29.65 10.5-.85 1.2 1.6.55-1.2 1.35q-1.25.7-2.85.55 1.1.3 2.15 0l1.1-.4q.55-.25 1.1-.65.6-.5 1.25-1.15.8-.85-.1-1.6z"
                    />
                </g>
                <g id="ObjToy058__Layer2_0_MEMBER_3_FILL">
                    <path
                        fill="#FFF"
                        d="M320.85 347.55l-.55-.25-.7 1.05.35.15.5-.85-.3.9.3.15.8-.7-.6.8.35.1.75-1-.55-.25-.65.6.3-.7m1.4 1.85l.2-.25-.7-.25.1-.15.55.2.2-.25-.5-.2.05-.1.65.25.2-.25-1.05-.4-.7 1 1 .4m-24.6-10.05l.45-.55-.85-.35-.4.5.8.4m-2-1.7q.2-.15.35-.2.15-.05.35.05l.2.1q.05.1 0 .15l.7.3q.2-.3.05-.6-.1-.25-.6-.45-.55-.2-1-.1-.45.15-.85.7-.4.5-.3.85.05.3.65.55.45.2.85.15.35-.1.6-.4.25-.3.2-.6-.15-.2-.55-.4-.2-.05-.3-.05-.15-.05-.35-.05m-.35.7q-.1-.05 0-.15.1-.1.25-.1.05 0 .25.05t.25.15q0 .1-.05.2-.1.05-.2.05h-.3q-.2-.1-.2-.2m6.8.95l-.65-.3-1.55 1.9.65.25 1.55-1.85m-.25.5l.6.3-1.15 1.35.6.25 1.15-1.35.65.25.4-.45-1.85-.8-.4.45m-1.7-.15q.15 0 .35-.1l.2-.2q.25-.3.2-.4-.05-.2-.4-.35l-1.35-.55-1.55 1.85 1.4.6q.35.15.6.1.2-.1.45-.4.15-.15.15-.3.05-.1-.05-.25m-.35-.4q-.05 0-.15-.05l-.45-.2.2-.25.45.2q.15 0 .15.05.05.05-.05.15-.05.1-.15.1m-.45.35q.1.05.15.15 0 .05-.1.1-.05.1-.1.1t-.25-.05l-.45-.15.3-.35.45.2m-5.75-3.5l-.4.5q.1.05.2.05t.2.05l-.9 1.2.55.25 1.5-1.9-.5-.15q-.15 0-.3.05-.15 0-.35-.05m35.3 14.8v-.1l-.1-.1q-.1-.1-.15-.1l-.8-.3-.85 1 .4.1.25-.25.2.1q.05 0 .1.05 0 .05-.05.05-.05.05-.05.1-.05.1-.05.15l.4.2v-.05q-.05-.05.1-.25 0-.05.05-.05.05-.1.1-.1v-.1q.05 0 .2-.1l.15-.15.1-.1m-.6.05h-.2l-.25-.05.15-.2.25.1q.05 0 .1.05 0 .05-.05.1m-1.85-1.2l-.85 1 .6.2q.35.15.6.1.25-.1.45-.3.2-.25.2-.45-.05-.15-.4-.3l-.6-.25m-.05.95l-.2-.1.45-.45.15.05q.15.05.2.15 0 .1-.1.2-.15.15-.25.15-.1.05-.25 0m-1.05-1.4l-.5-.2-1.2.85.4.2.1-.1.4.1-.05.15.4.2.45-1.2m-.7.65l-.2-.15.4-.3-.2.45m-.5-.65q.05-.2 0-.4-.1-.15-.4-.25-.35-.15-.55-.05-.3.05-.5.3-.2.3-.15.5.05.2.4.3l.3.1q.05 0 .2-.05l-.05.1.2.1.45-.6-.55-.2-.2.2.2.1q-.1.05-.2.05h-.15q-.15-.1-.15-.2 0-.05.1-.2.1-.15.2-.2.15-.05.3 0 .05 0 .1.05t0 .15l.45.2m7.15 3.15l.25-.2-.55-.2.15-.15.65.3.2-.25-1.05-.45-.9 1 1.05.45.25-.3-.7-.25.1-.15.55.2m-2.15-.3l.4.15 1.35-.8-.4-.15-.85.55.4-.7-.4-.2-.5 1.15m.45-1.2l-.4-.1-.9.95.4.15.9-1z"
                    />
                    <path
                        fill="#4F4F4F"
                        d="M324.2 334.4l1.85-2.35-6.75-2.75-2.15 2.3 7.05 2.8z"
                    />
                </g>
                <g id="ObjToy058__Layer2_0_MEMBER_5_FILL">
                    <path
                        fill="#484848"
                        d="M315.7 354.1q.094-.935-.6-1.2-.048.013-.1 0l-9.85-4.05-.2 3.3q-.15.05-.3.15l11.05 4.45v-2.65m-1.6-1.35q.55.25.55.75v1.9q-.05.1-.1.1-.25.4-.9.2l-4.2-1.75q-.55-.2-.6-.9v-1.55q0-.1.05-.15.05-.35.2-.4.1-.05.15-.05h.1q.05 0 .1.05l4.65 1.8m-15.6-6.65h-.05v2.6q.05-.2.5-1.05.5-.65.8-1l-1.25-.55z"
                    />
                    <path
                        fill="#666"
                        d="M314.55 355.5q.05 0 .1-.1l-4.8-1.95q-.4-.15-.5-.45-.05-.1-.05-.25l-.05-1.85q-.05 0-.15.05l.05 1.95q.05.5.55.7l4.85 1.9m-4.2-2.45q.05.05.15.05.05 0 .15-.05.05-.05.05-.15 0-.1-.05-.15-.1-.1-.15-.1-.1 0-.15.1-.05.05-.1.15 0 .1.1.15m1.2.4h.05q.05-.1.05-.15v-.05q0-.1-.1-.15-.05-.05-.15-.05-.05 0-.15.05-.05.05-.05.15v.05q0 .1.05.15.1.05.15.05.1 0 .15-.05m.7-.05q-.1 0-.15.05-.05.05-.05.15 0 .1.05.2.05.05.15.05.1 0 .15-.05.05 0 .05-.05.05-.05.05-.15 0-.1-.1-.15-.05-.05-.15-.05m.75.75q.05.05.15.05.15 0 .2-.05.05-.1.05-.2t-.05-.1q-.05-.1-.2-.1-.1 0-.15.1V354.15m-.2-1.1q.05-.05.05-.15 0-.1-.05-.15-.1-.1-.2-.1-.05 0-.1.1-.1.05-.1.15 0 .1.1.15.05.05.1.05.1 0 .2-.05m.9.4q.1-.1.1-.2t-.1-.15q-.05-.05-.15-.05-.1 0-.15.05-.05.05-.05.15 0 .1.05.2h.3m-1.7-.9q0-.1-.05-.15-.1-.1-.2-.1-.05 0-.1.1-.1.05-.1.15 0 .05.1.15.05.05.1.05.1 0 .2-.05.05-.1.05-.15m-1.35-.35q0 .05.05.15.1.05.2.05.05 0 .1-.05.1-.1.1-.15 0-.1-.1-.15-.05-.1-.1-.1-.1 0-.2.1-.05.05-.05.15m-.8-.5q-.05.05-.05.1 0 .1.05.2.05.05.2.05.05 0 .1-.05.05-.1.05-.2 0-.05-.05-.1-.05-.1-.1-.1-.15 0-.2.1z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy058__Layer11_0_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_MEMBER_4_FILL" />
            <use xlinkHref="#ObjToy058__Layer2_0_MEMBER_5_FILL" />
        </g></g>
    )
}

export default ObjToy058
