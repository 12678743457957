import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C69C5D",
    "col2n": "#666666"
}

function ObjMor012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M53.8 12.5Q54.85 3.35 48 .7 43.85-.85 37.65 1q-.7.2-1.45.5-2.25.85-4.5 1.8Q15.55 9.9 5.2 23.5q-.25.3-.45.6Q-2.6 35.2 1.4 47.15q1.25 3.2 3.75 6 4.65 4.75 10.75 7.1 8.4 2.65 16.45 2.1Q60 59.3 69.5 42.25q1.2-3.15 1.2-6.5 0-5.35-3.75-7.8-1.45-.95-4.3-1.8-2.75-.4-5.45-1.15l-.4-.1q-5.35-1.65-3.6-8.45.15-1.3.4-2.55l.1-.7.1-.7m1.05 32.4q-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4 2.25-2.25 5.4-2.25 3.15 0 5.4 2.25 2.25 2.25 2.25 5.4 0 3.15-2.25 5.4-2.25 2.25-5.4 2.25m127.3-3.45Q180.65 44 178 44.5q-2.65 7.75-4.85 16.25 18.3-26 26.3-51.3-1.25-2.15-3.6-1.7-7.65 11.2-13.45 25.15.5.55.75 1.45.95 3.8-1 7.1z"
                    id="ObjMor012__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.5.8L0 8.05l3.6 1.7 3.5-7.2q-.45-4-3.6-1.75z"
                    id="ObjMor012__Symbol_26_0_Layer0_0_FILL"
                />
                <g id="ObjMor012__Layer4_0_FILL">
                    <path d="M292.6 228.7q.2-.6.5-1.25.55-1.1 1.2-2.15-.391.345-.75.65-2.472 4.467-12 10.1-10.498 7.985-5.4 17.95.074.166.1.3.11.293.2.55l3.6 1.75q.2-.3 1.3-.65.75-.25 1.65-.85.35-.25.8-.6 1.3-1.1 2.25-1.95.9-.9 1.95-2.45 1-1.55 1.45-3.25.35-1.35.55-2.65.25-1.75.7-3.45-.05 0-.1.05l-.05.05q-.012.062-.05.1l-.05.15q-2.727 5.363-3.7 3.2-.025-.147-.05-.4.273-1.857 3.4-4.85.36-.28.75-.6.245-.193.45-.45 1.704-2.109 1.1-6.55-.004-.312-.05-.65v-.05q-.146-1.06.25-2.05M92.55 302.65q-.95-.6-.95-.8 0-.2.15-.4.15-.1.2-.15.25-.2.25-.6v-.1q0-.35-.35-.6-.1-.15-.25-.25-.25-.15-1.7-.6-.45-.2-1.55-.55-1.4-.4-1.9-.4-3.95 0-3.95 2.6 0 1 .15 1.4.15.35.55.85.45.8 1.2 1.95.45 1.3.7 1.65.9 1.35 2.85 1.35.75 0 .9-.5.1-.2.15-.5.2-.3.9-.55h1.4l.5-.1h.35q.5-.2.65-.4.2-.15.35-.35.3-.4.3-1.35 0-1.05-.9-1.6z" />
                    <path
                        fill="#FFF"
                        d="M90.4 286.95l-.05-.05q-1.4-.55-3.35-.8h-1.35q-1.6.25-2.4 1.25-.35.35-2 .7-.1 0-.15.1-.65.15-.65 1.15 0 .3.55 1.15.5.9.5 1 0 .15-.2.6-.2.45-.2.7 0 1.1 1.6 1.75 1.2.5 4.3 1.05h2.5q1.2-.4 1.4-.75.1-.15.1-1 0-.55-.25-1.25-.3-.7-.3-.8 0-.4.75-1.05.75-.7.75-1.95 0-.8-.3-1.1-.25-.3-1.25-.7z"
                    />
                    <path
                        fill="#00F"
                        d="M95.3 277.85q-.2-.35-.5-.7-1.9-2.35-3.5-2.6h-1.55q-.85.05-1.5.6-.3.25-.45.45-.25.2-.4.55-.2.45-.15.95-.1.35-.2.45-.5.45-1.4.95-.65.45-.65 1.35 0 .55.25.9.2.3 1 .8.35.2 1.1 1 .4.4 1.25.4.65 0 .95-.1.35-.1.75-.1.55 0 1.1.3.5.25 1.55.25 1.55 0 2.65-.85.35-.3.55-.55l.1-.1q.3-.35.3-1.05v-.2-.25q-.4-1.35-1.25-2.45z"
                    />
                    <path
                        fill="red"
                        d="M105.75 270.65q-.7-.95-.7-1.15l.1-.9q0-.75-.4-1.45-.7-1.15-2.25-1.15-1.8 0-2.75 1.25-.2.25-.3.5-.25.25-.35.6-.3.65-.45.8l-.6.1q-.7-.1-1.25.25-.35.2-.35.7-.2 2.05 1.3 4 .35.55.85 1 2.1 1.9 2.55-.9h.25q.45 0 .9.2.4.35.65.85.35.8 1.1.7 2-.15 1.5-1.8-.05-.25 0-.35.2-.4.6-.85.3-.3.3-.75 0-.7-.7-1.65z"
                    />
                    <path
                        fill="#FF0"
                        d="M115.95 259.3h-1.55q-.6.05-1 .6-.45.6-.85.65h-2.45q-1.5.5-1.95 1.15-.3.45-.3 1.5 0 1.8.85 2.65 2.2 2.2 3.15 2.2.8 0 1.25-.3.25-.2.65-.75.45-.7 1.45-.85.35.05.95.35l.05-.05q0 .05.05.1.2.1.45.25 1.15.65 1.95.65h.5q.85 0 1.15-.35.15-.2.15-.75 0-.5-.5-1.35-.55-.85-.55-1.3 0-.65.35-1.3.35-.6.35-1.5 0-.7-.3-1.3l-.1-.4q-.4-1.3-1.45-1.3-.95 0-1.5.55-.3.3-.8.85z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor012__Symbol_122_0_Layer0_0_FILL"
                transform="translate(77.1 254.35)"
                id="ObjMor012__col1n"
            />
            <use
                xlinkHref="#ObjMor012__Symbol_26_0_Layer0_0_FILL"
                transform="translate(272.95 254.05)"
                id="ObjMor012__col2n"
            />
            <use xlinkHref="#ObjMor012__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjMor012
