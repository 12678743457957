import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaSid004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.75 0q-.55 1.05-1.2 2.1-.85 1.35-2.1 2.35-6.5 5.05-14-.55L0 4q1.6 6.9 7.75 10.2.1-.6.1-.25 0 .55-2.75 3.65-2.7 3.05-3.55 7.75Q.8 30 .95 34.2q1.95-4.75 6.75-7.05-4.65 4.15-6.1 10.3 1.85-1.9 3.9-2.65 3.95-1.35 6.5-1.35 1.6.25 2.1.25-2.9 2.75-4.1 5.15-1.15 2.25-1.15 4.95 0 1.4.3 3.15.05.6.2 1.25.3-.4 1.9-1.25.6-.35 1.45-.75 3-1.5 6.65-1.5l4.25.5q-1.55.55-2.6 1.75-.65.7-1.1 1.65-1.25 2.55-1.55 5.1 4.15-3.8 12.25-1.3 4.05 1.2 6.9 2.55 1.5.75 2.65 1.5.6.35 1.1.75-.6-.55-1.3-1.25-.4-.5-.75-1-3-3.9-5.55-8-10.85-17.15-15-37.3-.75-4.95-.9-9.65M206.8 34.2q.15-4.2-.6-8.85-.85-4.7-3.55-7.75-2.75-3.1-2.75-3.65 0-.35.1.25 6.15-3.3 7.75-10.2l-.45-.1q-7.5 5.6-14 .55-1.25-1-2.1-2.35-.65-1.05-1.2-2.1-.15 4.7-.9 9.65-4.15 20.15-15 37.3-2.6 4.1-5.55 8-.4.5-.75 1-.7.7-1.3 1.25.5-.4 1.1-.75 1.15-.8 2.7-1.5 2.8-1.35 6.85-2.55 8.1-2.5 12.25 1.3-.3-2.55-1.55-5.1-.45-.95-1.05-1.65-1.1-1.2-2.65-1.75l4.25-.5q3.65 0 6.65 1.5.85.4 1.5.75 1.55.85 1.85 1.25.15-.65.25-1.25.25-1.75.25-3.15 0-2.7-1.15-4.95-1.2-2.4-4.1-5.15.5 0 2.1-.25 2.55 0 6.5 1.35 2.05.75 3.9 2.65-1.45-6.15-6.1-10.3 4.8 2.3 6.75 7.05z"
                    id="BeaSid004__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaSid004__Symbol_5_0_Layer0_0_FILL"
                transform="translate(88.65 168.65)"
                id="BeaSid004__col1n"
            />
        </g></g>
    )
}

export default BeaSid004
