import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiHig040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#434343"
                    d="M225.75 132.7q.107-1.114-.25-.15.136.084.25.15z"
                    id="HaiHig040__Layer8_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M165.25 57.05q-1.45-3.05 1.8 1.9 3.2 4.95 3.95 6 .65.9 4.1 6.6h.15q-6.4-21.7-20.75-39.45-.65-.85-1.35-1.65-1.15-1.4-2.35-2.75-14.7-16.55-32.6-23.85l1.1 1.3q.5.55 1.05 1.1 1.1 1.1 2.1 2.2t1.35 1.75q.35.6.45.7h-.05l.05.05-5.9 11.95-16.3-15.05q-1.2-1.35-7.8-7.85.85 9.25 1.75 13.35-.45-.65-3.7-5.3-1.9-2.7-3.75-4.5-.15.35-.3 1.9-.15 1.5-.7 6.55l-3.1-7.5-.1.3q-1.1 2.2-1.5 3.75Q82.5 7.9 81.9 7q-2.75-4.35-3.65-4.75-2.1 3.15-6.85 10.2l-.1-.3-.1.3L55.75 11q4.55-5.05 9.5-8.55-1.6-.15-6.3 1.6-1.95.7-4.35 1.7-4.15 1.65-8.3 4.2-2.3 1.4-4.55 3.05-5.1 3.65-10.15 8.55Q20.2 32.7 18.45 35 9.5 46.8 3.95 60.25 1.7 65.75 0 71.55l5.65-9.95Q4.3 65.2 3.5 71.55h3.2v.05h1.55q1.05-4.8 5.85-15.5l.15-.15q0 2.85-.5 15.65l5.6-13.35q-.9 5.7.4 13.35h9.8L43.3 48.1l17 23.5h7.6l2.1-9.75q.25 2.95.2 6.7-.05 1.3.25 3.05h6q.15-.8.55-2.75.35-1.95.55-3.65.2-1.75.55-4.75.3-3.05 1.65-10.7v15.6q-.1 1.5 0 6.25H97.9q.15-1.6.2-4.3v-6.1q.6.55 2 3.6 1.45 3.1 1.5 4.15l.15.15V50.35q4.2 10.55 5.85 15.35.7 2.05 1.7 5.9h5.55l4.8-15.9 21.65-1.2 9.7 17.05v.05h11.6v-.05h3.45l.05-3.45q1.05 1.25 2.4 2.75.3.35.55.7.35-2.3 0-4.3t-1.35-4.6q-1.05-2.6-2.45-5.6z"
                    id="HaiHig040__Symbol_128_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M20.3 12l.75-6.2-1.1.9q-2.2 1.9-4.15 3.7-5.15 4.75-8.3 8.5Q1.95 25.4 0 30.35 9.15 21.3 13.6 17.5q.65-.6 1.25-1.05L13.1 27.2q1.35-3.55 2.95-6.95.2-.5.45-1 1.75-3.7 3.8-7.25m66.6 10.75q-.55-2.7-1.1-5.3-1.7-1.95-3.85-4.7-5.2-6.7-5.45-6.9V17.7q-1.1-2.2-3.45-6.85Q72 15.15 72 15.6q-.55-.75-2.25-6.4-1.7-5.7-2.25-9.2-.3 4.65-3 17.15-.05-.25-2.85-8.85-3.5 6.55-4.9 8.15-1.4 1.6-1.75 2.4h-.25q-.15-.8-.3-3.35t.05-4.55q.2-2 .5-4.2.25-2.25.25-4.9V1.6q-.15 2.15-2.45 7.95-2.35 5.8-4.85 13.75-.25-7.5-.95-11.7l-3.5 8.2q-.65-5-.9-7.4-1.85 7.15-3.15 15v.15L41 32.6q1.05-2.95 2.45-4.95 1.1-1.6 1.7-2.35.1 1.15.3 2.9 1.05 8.35 1.05 10.4v.5q.8-4.45 7.9-14.95 2.1-3.1 3.9-5.6.1 3.85 2.45 12.3.15 0 .25.15.15.1.25.1 1.65-3.95 4.7-9.45 1.55-2.75 2.35-4.6.65 1.95 1.75 6.65 1.45 6.05 1.95 8.65-.05-4.05.8-9.35.95-4.65.95-4.9 1.5 2.45 3.35 4.25 1.85 1.8 2.4 3.75.05-2.25.8-5.9.7-3.5.7-4.85.95 1.2 5.9 7.4m35.8 3.7q2.15 2.15 4.4 4.7Q121.3 21 117.7 16q-3.4-4.7-7.2-7.9-.5-.25-.75-.25l.1.2.25.35q.1.15.2.35 3.95 6.35 7.05 13.3 2.25 1.15 5.35 4.4z"
                    id="HaiHig040__Symbol_125_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M42.35 5.35q.5-2.95.4-4.1-.95.95-3.25 2.8-2.3 1.85-2.75 2.4-.4.5-1.85 1.5Q33.45 9 33.1 9.2q.55-1.5 1.25-4L26.2 9.75q-1.35 1.45-2.65 3.05-1 1.25-2 2.55-1.55 2.05-3.05 4.3-.9 1.35-1.8 2.75-1 1.6-1.95 3.25-2.05 3.55-3.8 7.25-.25.5-.45 1-1.6 3.4-2.95 6.95Q7 42.25 6.5 43.7 1.95 56.15 0 70.35h3.4q.75-5.15 2.75-8.9-.65 3.85-.95 8.9h3.2q.85-3.5 2.25-7.95 2.3-7.1 4.55-11.45-1.1 9.75-1.15 10.4-.3 2.8-.45 5.2 1.4-1.25 3.95-4.7v2.85q-.1 1.15-.5 4.25-.1.75-.15 1.4h3.75q.55-1.15 1.6-2.6l.1-.1q.6-.85.95-1.25-.2.95.15 3.95h7.3q.5-8.85 1.45-17.05.7-6.3 1.7-12.1v-.15q1.3-7.85 3.15-15 .65-2.65 1.35-5.15 1.6-5.55 3.35-10 .05-2.35.6-5.55m62.4 17.05q-.1-.2-.2-.35l-.25-.35q-2.7-3.75-5.75-7.5-1.9-2.3-3.9-4.55-3.4-1.6-6.75-3.35-3.45-1.75-8.45-5.3 3.75 5.05 4.6 6.9.55 1.25 1.75 4.1-1.3-1.3-3.5-3.2Q74.75 2.3 71.95 0q.25 2.9.6 5.5.15 1.05.4 1.65-.2-.2-.3-.35-.05-.1-.15-.2l.7 1.6q2.35 5.4 4.45 12.7.55 1.85 1.1 3.9.8 3.1 1.5 6.3.55 2.6 1.1 5.3 2.1 11 3.2 23.5.45 5.1.75 10.45h2.4V65.8q2 2.2 3.3 3l1.55-5.1q1.15 2.45 2.6 6.65h10.4v-4.9q-.7-3.85-1.4-9.35 2.7 6.35 4.75 10.8.1.2.2.45.7 1.45 1.3 3h6.55V67.7q-.2-1.3-.25-1.85-.15-.95-.4-1.65v-.1q.9.3 3.8 4.4.7 1 1.3 1.85h.05q-2.6-18.8-9.65-34.6v-.05q-3.1-6.95-7.05-13.3z"
                    id="HaiHig040__Symbol_127_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M29.65.25V0q-3.3 2.95-10.1 8.3h-.05q-6.15 4.7-7.6 6.2h-.25V13q.95-3.1 1.45-4.95.15-.5.25-.85l1.1-4.35-7.25 6.8Q5.15 13.2 3.4 16.9q-.25.5-.45 1-1.6 3.4-2.95 6.95l7.4-11.1q0 .75-.7 6.2-.7 5.75-1.05 6.8V27q1.2-3.3 9.2-9.6 1-.8 1.95-1.5 6.05-4.8 6.1-4.9-.2 8.35-.75 11.05-.55 2.7-.8 4.55-.25 1.8-1.45 9.75 2.25-2.05 4.85-7.7.9-2 1.6-3.45v-.15q1.3-7.85 3.15-15-.25-2.05-.25-3.95.4-4.4.4-5.85m74.6 19.5v-.05q-3.05-6.8-6.9-13.05 3.55 6.15 3.4 14-.2-.3-.45-.65-1.85-2.75-5.45-7.8Q90.8 6.45 86.4 1.5q4 8.05 3.75 10.25-2.75-.25-9.5-6.5l3.5 9.25q-4.6-2.4-5.55-3.2-.35-.3-.5-.45 0 .4-.1 1.35-.2 2.2.55 9.15-3.4-3.5-5.85-6.25.55 2.6 1.1 5.3.25.3.55.65 5.4 6.8 8.05 12.7l.15.05q.097.048.1.2 0-15.25-.25-17.25 1.15 0 6.5 3.75v-6.25q3.15 1.95 8.5 8.5 2.4 2.95 4.25 5.1 3.5 4.15 4.95 5.6-.75-2.7-1.7-5.3-.95-2.65-.65-8.4z"
                    id="HaiHig040__Symbol_126_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#HaiHig040__Layer8_0_FILL" />
            <use
                xlinkHref="#HaiHig040__Symbol_128_0_Layer0_0_FILL"
                transform="translate(105 62.25)"
                id="HaiHig040__col1n"
            />
            <use
                xlinkHref="#HaiHig040__Symbol_125_0_Layer0_0_FILL"
                transform="translate(129 77.15)"
                id="HaiHig040__col1l"
            />
            <use
                xlinkHref="#HaiHig040__Symbol_127_0_Layer0_0_FILL"
                transform="translate(134.55 63.5)"
                id="HaiHig040__col2n"
            />
            <use
                xlinkHref="#HaiHig040__Symbol_126_0_Layer0_0_FILL"
                transform="translate(142.1 79.5)"
                id="HaiHig040__col2l"
            />
        </g></g>
    )
}
export default HaiHig040
