import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ShiSho020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.5 19.1q-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.3.5-.6 1.05-.55.9-1.05 1.85-2 3.65-3.8 7.5-1.4 3-2.65 6.15-.4 1.05-.8 2.15-.2.45-.35.95l18.95 7.05v.05l-2.7 14q-.55 2.7-1.1 5.45L11.7 69.2q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H140.75q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75 19.1-7.1q-.2-.5-.35-.95-.45-1.1-.85-2.15-1.25-3.15-2.65-6.15z"
                    id="ShiSho020__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho020__Symbol_28_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho020__col1n"
            />
        </g></g>
    )
}

export default ShiSho020
