import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor080({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor080__Layer10_0_FILL">
                    <path
                        fill="#40489D"
                        d="M220.6 275.7q.32.412.7.8 2.7 2.6 6.3 2.6 3.65 0 6.25-2.6.388-.388.7-.8-7.25-3.065-13.95 0z"
                    />
                    <path
                        fill="#A54396"
                        d="M236.25 272.05q.2-.865.2-1.8 0-.934-.2-1.8-8.94-2.607-17.35 0-.15.866-.15 1.8t.15 1.8q8.798 1.856 17.35 0z"
                    />
                    <path
                        fill="#F87297"
                        d="M234.65 264.9q-.365-.465-.8-.9-2.6-2.6-6.25-2.6-3.6 0-6.3 2.6-.427.435-.8.9 7.463 1.78 14.15 0z"
                    />
                </g>
                <g id="AccMor080__Layer10_1_FILL">
                    <path
                        fill="#FFF"
                        d="M220.5 264.9q-1.204 1.6-1.6 3.55h17.35q-.355-1.95-1.6-3.55H220.5z"
                    />
                    <path d="M234.55 275.7q1.336-1.64 1.7-3.65H218.9q.407 2.01 1.7 3.65h13.95z" />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor080__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor080__Layer10_0_FILL" />
            <use xlinkHref="#AccMor080__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor080__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor080__col1l"
            />
        </g></g>
    )
}

export default AccMor080
