import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2n": "#FFFF00"
}

function ObjMor096({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.1 4.75L31.8.15q-.55.75-2 1.45-11 1.9-12.4.95Q13.75.15 7.9 0L6.65 4.95q-1.6 4.85-2.8 9.65Q7.5 22 1.35 26.75q-1.55 9.8-1.3 19 .2 8.25 1.9 16v.05l.05.1v.05h35.8v-.25q1.65-7.7 1.9-15.95.1-3.9-.1-7.9-.1-1.9-.25-3.8-3.2-6.45-2.05-13.7-.05-.25-.1-.45t-.05-.35q-.3-1.4-.65-2.75-1.15-4.8-2.65-9.75-.4-1.15-.75-2.3m-20-1.45q-1.6-1 1.8 1-.75-.35-1.8-1z"
                    id="ObjMor096__Symbol_239_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M33.85 58.45q-.05 0-.7.4-.65.4-2.4.1-1.75-.3-2.55-.9-.85-.6-1.15-1.45-.05-.15-.05-.25-.75-.15-1.5-.2-1.9-.3-4-.35-1.75-.55-3-1.05-1.8.7-1.8 1-2.65.05-5 .35-3.2.4-5.85 1.25Q1.2 58.9.7 61q-.1.25-.1.5 0 .1.05.25v.2q.05.1.1.25l.05.1q.1.35.35.65 1.15 1.5 4.7 2.6.1.05.25.1 2.55.8 5.6 1.2 3.15.4 6.85.4h.15q3.65 0 6.8-.4 3.1-.45 5.7-1.3l.4-.1q3.05-1.05 4.2-2.35l.3-.4q.35-.6.35-1.2v-.05q-.05-1.7-2.6-3m1.7-21.35q-.7-.75-1.8-.75-1.05 0-1.85.75-.7.9-.7 1.95 0 1 .7 1.75.8.7 1.85.7 1.1 0 1.8-.7.8-.75.8-1.75 0-1.05-.8-1.95m-23-5.25q-.45.1-.85.2-2.05.65-3.7 2.25-2.65 2.7-2.65 6.5 0 2.6 1.3 4.7.55.95 1.35 1.75 1.65 1.65 3.7 2.3 1.3.4 2.8.4.7 0 1.35-.05.6.3 1.15.55.5-.3 1.55-3.3 1-3.05-.5-4.45-1.55-1.4-1.6-5.45-.05-4.05-.15-4.2-1.65-.5-3.75-1.2M9.35 21.1q0-2.85-1.7-4.9-1.65-2-4.05-2-.55 0-1.1.15Q.9 20.55 0 26.5 1.55 28 3.6 28q.65 0 1.25-.15 1.6-.4 2.8-1.9 1.25-1.5 1.6-3.4.1-.7.1-1.45m22.35-9.9q0-1.35-.35-2.5-.25-.7-.6-1.3-.95-1.55-2.3-1.55-1.35 0-2.3 1.55l-.3.6q-.55 1.2-.6 2.7-.05.25-.05.5 0 1.8.65 3.2.15.3.3.55.95 1.6 2.3 1.6 1.35 0 2.3-1.6.95-1.55.95-3.75m.05-6.7L30.5 0l-7.7.1q.8-.05 1.75-.05L8.5 0q-.45.4-.85.85-.45 1.05-.45 2.3 0 3.15 2.15 5.1 1.05.95 2.35 1.4 1.1.4 2.4.5h.6q2.7 0 4-1l.05-.05q.75-.55 1.8-2.5.35-.65.8-1.25.05-.05.1-.15.15-.2.3-.35 1.25-1.15 2.3-1.5Q25 3 26.95 3q2.5 0 4.2 1.9.6.7 1.35 1.9-.4-1.15-.75-2.3z"
                    id="ObjMor096__Symbol_240_0_Layer0_0_FILL"
                />
                <path
                    fill="url(#ObjMor096__Gradient_1)"
                    d="M367.6 222.15q-.95.1-1.95.15-1.048.049-2.7-.8-1.607-.846-3.35-.75-1.743.096-2.8.4-1.053.351-1.9.05l-.3-.1q-10.8 35.9-3.95 63.65l.3.2q.05.005 1.4 1.5 1.401 1.484 4.5 1.85 3.099.365 5.95-.7 2.85-1.005 4.8-1.1v-64.35z"
                    id="ObjMor096__Layer1_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M361.55 287.6q-2.093.996-5.1.1-3.001-.895-5.35-2.7-.15-.05-.25-.1-3.55-1.1-4.7-2.6-.264-.308-.4-.65l4.55 15.95q0 .4.2.75.286.55 1 1.05 12.232 7.666 24.05 0 .596-.404.9-.85v-.05q.3-.4.3-.9v-.15l4.35-15.4-.3.4q-1.15 1.3-4.2 2.35l-.4.1q-2.6.85-5.7 1.3-3.136.392-6.8.4-.069.001-2.15 1m2.15-89.7h-.4q-.5 0-.95.05-1.45.2-2.7.9-1.45.85-2.65 2.35-2.05 2.7-2.45 6.25l-3 11.65q.25.6 1.1 1.1.45.3 1.1.6.4.15.85.3l.3.1q.85.3 1.8.5.755.145 2.8-1.05 2.034-1.135 3.05.25 1.072 1.38 1.15 1.45.996-.004 1.95-.05 2-.1 3.7-.4.6-.15 1.15-.25.8-.2 1.55-.45.3-.1.65-.2 2.1-.8 2.75-1.75l-3.3-11.9q-.35-2.7-1.65-4.85-.4-.7-.85-1.3-1.15-1.45-2.55-2.25-1-.6-2.05-.85-.66-.155-1.35-.2z"
                    id="ObjMor096__Layer1_1_FILL"
                />
                <path
                    fill="#E5E5E5"
                    d="M356.7 286.2q-3.05-.4-5.6-1.2l4.45 15.85v.15q1.86 1.996 4.1 1.55 2.236-.447 3.1-.6.871-.101.95-.1V286.6h-.15q-3.7 0-6.85-.4m0-64.5q.75.15 1.6.3 2.35.35 5.2.35h.2V198.7q-3.7-.429-5.35 5.75l-3.75 16.65.3.1q.85.3 1.8.5z"
                    id="ObjMor096__Layer1_2_FILL"
                />
                <path
                    fill="#B4B4B4"
                    d="M372.9 300.6q-.4.15-.85.25-2.4.65-5.35.9-1.432.096-3 .1h-.15q-1.6 0-3.05-.1-2.75-.2-4.95-.75l-.05-.05q-.22-.054-.45-.1-.45-.1-.85-.25-3.05-1-3.7-2.25l-4.7 14.5q-.1.1-.1.25-.1.3-.1.6 0 2.1 4.1 3.65.383.153.85.3.145.05 1.55-.2 1.462-.195 4.7-.4 3.282-.206 5.05 1.1 1.773 1.302 1.85 1.3 3.964-.01 7.35-.5 2.8-.45 5.15-1.2 1-.3 1.85-.65 3.4-1.45 3.4-3.4v-.3q-.05-.25-.15-.55l-4.85-14.3q-.75 1.1-3.25 1.95l-.3.1z"
                    id="ObjMor096__Layer1_3_FILL"
                />
                <path
                    fill="#D7D7D7"
                    d="M363.7 301.85h-.15q-1.6 0-3.05-.1-2.75-.2-4.95-.75l-5 16.65q.148.053.3.1 2.45.8 5.35 1.2 3.35.5 7.35.5h.15v-17.6z"
                    id="ObjMor096__Layer1_4_FILL"
                />
                <linearGradient
                    id="ObjMor096__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={348.7}
                    y1={253.25}
                    x2={365.2}
                    y2={253.25}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFF" stopOpacity={0.659} />
                    <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
                </linearGradient>
                <g
                    id="ObjMor096__Symbol_241_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M26.95 45.6q.25.8.8 1.5 1.2 1.35 2.8 1.35 1.55 0 2.6-1.1.05-.05.1-.05 0-.1.1-.2 1.15-1.35 1.15-3.15 0-1.85-1.15-3.2-1.1-1.25-2.8-1.25-1.6 0-2.8 1.25-1.1 1.35-1.1 3.2 0 .8.25 1.4 0 .1.05.25m-21-26.3q.8-.8.8-2 0-1.1-.8-1.9-.8-.85-1.95-.85-1.2 0-2 .85-.8.8-.8 1.9 0 1.2.8 2t2 .8q1.15 0 1.95-.8m19.35-1.55q-.9-.65-2-1.15-2.5-1.15-4.8-.85-2.45.25-4.7 2.15-.9 1-1.45 2.35-.65 1.5-.85 3.45-.1.95-.1 1.95t.1 1.9q.3 2.65 1.45 4.5.55.95 1.05 1.85.6 1.25 1.05 2.4.1.2.15.45l.4 1.3q0 .1.05.25.85 3.3.85 5.05v.8q0 1.9-.15 2.3-.3 1.1-1.6 2.7-4.9 4.7-12.5 2.95-.6-.3-1.3-.75 1.15 1.5 3.05 2.4 1.9.9 3.4 2 .65.45 1.65.75 9.75 3.7 20.3 0l.4-.2q1.35-.8 3.05-2.15 1.65-1.35 2.8-2.65-.3.1-.6.15-5.85 1.2-11.35-1.95-2.4-1.75-2.4-4.85 0-.3.05-.65.35-2.9 3.4-8.3l.6-1.1.5-.9q1.6-3.25 2.1-5.95 1.45-7.1-2.6-10.2M35.6 7.7q-1.55.5-2.75 1.9-1.75 2.35-1.75 5.5 0 1.4.35 2.5 0 .15.05.4.45 1.3 1.35 2.5 1.8 2.35 4.4 2.35h.6q-.6-7.4-2.25-15.15z" />
                    <path
                        fillOpacity={0.6}
                        d="M36.25 33.95q.5-.45.5-1.15t-.5-1.2q-.5-.45-1.2-.45-.65 0-1.2.45-.45.5-.45 1.2t.45 1.15q.55.55 1.2.55.7 0 1.2-.55M3.05 32.8q-1.3 0-2.2 1.45Q0 35.7 0 37.8q0 2.05.85 3.55.9 1.45 2.2 1.45 1.25 0 2.1-1.45.95-1.5.95-3.55 0-2.1-.95-3.55-.85-1.45-2.1-1.45M12.2 0q-.75 0-1.35.55-.5.65-.5 1.4 0 .75.5 1.3.6.5 1.35.5.8 0 1.3-.5.6-.55.6-1.3t-.6-1.4Q13 0 12.2 0m14.2 8.8q0-1.95-1.4-3.35-1.3-1.3-3.25-1.3-2 0-3.4 1.3-1.3 1.4-1.3 3.35 0 2 1.3 3.3 1.4 1.4 3.4 1.4 1.95 0 3.25-1.4 1.4-1.3 1.4-3.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor096__Symbol_239_0_Layer0_0_FILL"
                transform="translate(327.7 263.15)"
                id="ObjMor096__col1n"
            />
            <use
                xlinkHref="#ObjMor096__Symbol_240_0_Layer0_0_FILL"
                transform="translate(329.05 263.4)"
                id="ObjMor096__col1d"
            />
            <use
                xlinkHref="#ObjMor096__Symbol_241_0_Layer0_0_FILL"
                transform="translate(329.25 275)"
                id="ObjMor096__col2n"
            />
            <use
                xlinkHref="#ObjMor096__Layer1_0_FILL"
                transform="translate(-15.95 44.05)"
            />
            <use
                xlinkHref="#ObjMor096__Layer1_1_FILL"
                transform="translate(-15.95 44.05)"
            />
            <use
                xlinkHref="#ObjMor096__Layer1_2_FILL"
                transform="translate(-15.95 44.05)"
            />
            <use
                xlinkHref="#ObjMor096__Layer1_3_FILL"
                transform="translate(-15.95 44.05)"
            />
            <use
                xlinkHref="#ObjMor096__Layer1_4_FILL"
                transform="translate(-15.95 44.05)"
            />
        </g></g>
    )
}

export default ObjMor096
