import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000"
}
function FacNos008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.7 10.55q4-5 1.4-6.9-.6-.4-1.25-.7-.05 0-.15-.05-2.85-1.45-6.15-2.15Q16.3-.7 8.6.75 5.25 1.5 2.4 2.9q-.1.05-.15.05-.65.3-1.25.7-2.6 1.9 1.4 6.9 3.5 4.35 8.85 6 .6.15 1.25.3 4.4 1 8.1 0 .65-.15 1.25-.3 5.35-1.65 8.85-6z"
                    id="FacNos008__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M32.1.75q-.6-.4-1.25-.7-.05 0-.15-.05 1.6 2.25-4.7 2.9-4.8.7-9.45.45-4.65.25-9.45-.45Q.8 2.25 2.4 0q-.1.05-.15.05-.65.3-1.25.7-2.6 1.9 1.4 6.9 3.5 4.35 8.85 6Q6.7 11.25 6 7.45q-.65-1.6 2.05-1.85 4.05-.8 8.5-.55 4.45-.25 8.5.55 2.7.25 2.05 1.85-.7 3.8-5.25 6.2 5.35-1.65 8.85-6 4-5 1.4-6.9z"
                    id="FacNos008__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos008__Symbol_17_0_Layer0_0_FILL"
                transform="translate(175.95 191.2)"
                id="FacNos008__col1n"
            />
            <use
                xlinkHref="#FacNos008__Symbol_3_0_Layer0_0_FILL"
                transform="translate(175.95 194.1)"
                id="FacNos008__col1l"
            />
        </g></g>
    )
}
export default FacNos008
