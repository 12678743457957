import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M177.25 81.05q2.3 2.7 4.15 6 .8 1.4 1.5 2.9 3.8 8.1 5.1 19.05h.05q2.45 21.15-4.5 53.15 12.5-12.5 15.7-33.1L200.7 117q11.6 28.3 9.85 66.75 18.05-25.4 16.15-71.35 6.85 3.25 10.6 18.65 2.45-44.55-32.5-71.9 8.8.15 18.1 4.95-15-20.05-32.15-29.25-18.4-9.8-33.35-12.65-.1-.05-.25-.05.05-.05.1-.05 5.35-3.8 21.1-6.85-19.3-2.1-40.05 2.3-10.8-5.9-25.65-17.55 12.45 17.3 6.8 17.05-2.8.8-14.7-2.8-6.15-1.85-12.35-2.5-3.6-.35-7.2-.3-11.75.15-23.7 4.65Q51 20.05 40.35 27.4l-1.4 1q-10.5 7.45-21.05 18.15l.2-.1q-.15.1-.25.2 16.3-12.2 33.9-18.25l3.1-1q3.1-.95 6.3-1.75 7.4-1.85 15.15-2.6-6.35 1.9-12.2 4.35l-2.3 1q-5.45 2.45-10.5 5.45Q11.45 57.2 0 112.1q16.5-37.4 41.85-54.65-36.7 38.5-33 107.7Q12 129.4 25.9 108.55q-10.15 40.8 10.45 74.35 2.85 4.55 6.2 8.75-2.5-30.45 1.75-49.2 5.55 21.8 17.5 37.5-10.8-38.3-5.45-50.2 3.1 30.35 18.05 48.7-.8-1.05-2.85-38.3-1.15-20.65 2.75-39.5 1.2-10.9 5.4-19.3 3-6.05 7.55-10.8-8.55 21.65-9.65 49.8 1.95-10.7 5-20.25l.4-1.3 7.4-18.2 5.95-10.45q.2-.3.4-.65L98 72.15q.05-.05.05-.1l3.65-5.25q1.2-1.3 2.5-2.3.4-.3.75-.5l.05-.1q1.1-.75 2.35-1.3Q99.85 78.45 96 95.65q12.35-27.3 29.9-46Q147 60.85 155 86.4q3.6-18.75-4.3-33.35 2.05 1.95 5.5 5.15 3.4 3.7 3.35 3.7l17.7 19.15z"
                    id="HaiSho073__Symbol_194_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M83.65 41.6q1-4.15 5-5.95-11-1.2-23 5.8Q75.95 30.2 87 26.65q-13.55.15-27.05 5.85 9.15-8.4 21-9.5-8.75-3.5-19.95-.25 8.3-5.55 19-5.9-4.05-3.45-10.05-4.7Q75 9.9 80 11.35q0-.05.05-.1-9.2-6.1-19.5-5.3 12.7-4.45 25.4-3.3Q80.25 1 74.55.4V.3q-3.6-.35-7.2-.3-11.75.15-23.7 4.65-10.5 3.95-21.15 11.3l-1.4 1h.05q-10.4 7.4-20.9 18V35q-.15.1-.25.2Q16.3 23 33.9 16.95l3.1-1q3.1-.95 6.3-1.75 7.4-1.85 15.15-2.6-6.35 1.9-12.2 4.35l-2.3 1h.2q-5.6 2.5-10.75 5.55 7.4-2.65 11.65-1.4-11.25 6.55-18.3 15.75 10-4.3 18-4.75-14.65 7.7-20.5 25.85Q31.7 49.6 38.5 46.5q-4.8 10.1-6.2 20.85 7.25-14.25 13.75-14.8-5.35 12.45-4.5 29 5.95-17.7 14.8-27.35Q53 61.75 53.9 71.85q12.15-19 21.6-24.3-8 19.25-10.8 41.2.2-.7.45-1.4l7.4-18.2L78.5 58.7q.2-.3.4-.65l1.25 2.65q4.3-14.2 13.25-19.1-4.25-.85-9.75 0M181.8 46q-.15-.25-.3-.45-4.95-7.9-12.4-14.5 4.65-1.45 14.2 3.9-3.4-7.05-10.45-11.45-18.15-9.7-32.95-12.55l6.45 3.4q-10.6-1.45-18.9 3.3l-8.35 3.7q13.85-1.7 22.3 2.65-9.85-2.5-21.4 2 18.85 0 29.9 9.85-10.25-4.5-24.4-3.95 34.2 13.35 42 35.2-12.15-15-25.7-19.4 11.4 8.2 21.75 27.9.8 1.4 1.5 2.9 3.8 8.1 5.1 19.05l.75-.05.45-.55q1.05-11.8-2.2-23 6.15 6.1 9 14.9.7-11.55-2.5-23.1 5.7 8.7 8.85 18.2.9-12.6-3.9-25.15 7.95 7 9.85 13.3-1.55-14.45-8.65-26.1z"
                    id="HaiSho073__Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho073__Symbol_194_0_Layer0_0_FILL"
                transform="translate(68.4 39.4)"
                id="HaiSho073__col1n"
            />
            <use
                xlinkHref="#HaiSho073__Symbol_54_0_Layer0_0_FILL"
                transform="translate(86.25 50.85)"
                id="HaiSho073__col1l"
            />
        </g></g>
    )
}

export default HaiSho073
