import { AssetSvgProps } from "../../../shared/assets"

function ShiMot050({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFCE00"
                    d="M180.55 258q-13.412 18.283 0 35.8h22.6q10.71-18.297 0-35.8h-22.6z"
                    id="ShiMot050__Layer5_0_FILL"
                />
                <g id="ShiMot050__Layer5_1_FILL">
                    <path fill="red" d="M203.15 293.8h22.6V258h-22.6v35.8z" />
                    <path d="M159.25 258v35.8h21.3V258h-21.3z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot050__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot050__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot050
