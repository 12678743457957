import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E0F0FF"
}

function FacMou062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M211.65 219.85h.25q.538-.39-.1-.8-4.295-.424-8.75-.2-5.804.162-13.45 2.3-4.68.814-9.85-.05-.174-.039-.4-.1h-.05q-.05.005-.15-.05-.588-.085-1.1-.15-3.55-.215-4.25 2.6.006.094 0 .15-.1.399-.1.7 0 1.45.9 2.85 1.25 1.95 3.6 1.95 2.644 0 5.3-.6 4.466-.823 6.95-3.45.488-.457.85-.8.158-.154.3-.3 2.527-2.081 7.1-3.15.37-.053.7-.15h.2q5.74-.849 12.05-.75z"
                    id="FacMou062__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M15 2.35q0-1.7-.7-2.05-.4-.35-1.15-.3-1.3 0-4.05.15-5.75-.2-6.95 1.75-.25.4-.45.95V5.5q-.1.75-.8 2.55Q.45 9.25.3 9.7q-.3.75-.3 1.15 0 .5.15.95.4.85 1.5 1.15h.4q1.1 0 1.45-1.4.05-.55.1-.95V10q0-.8.2-1.5.3-1.2 1-1.2h.35q.45.15.45 1.55-.1.7-.25 1.25-.05.3-.1.65-.4 1.7-.45 2.3-.2 1-.2 2.1v.65q0 1.25.05 1.45.2 1.05 1.1 1.1h.5q1.7 0 1.7-2.65 0-.4-.2-1.9-.2-1.45-.2-3.25 0-3.25.35-4.3.05-.25.15-.45.2-.3.5-.65 1.15-1.5 2.25-1.5.1 0 .15.05.25.3.25.85t-.45 1.6q-.3.55-.4 1.2v.1q-.05.05-.05.15-.05.35-.05.7 0 .8.55 1.85.05.05.05.15.45.4 1.15.4 1.05 0 1.55-1.05.3-.65.3-1.3-.15-1.4-.15-2.25.1-.5.15-1.05.15-.55.5-1.05.65-.95.65-1.65z"
                    id="FacMou062__Symbol_87_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou062__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou062__Symbol_87_0_Layer0_0_FILL"
                transform="translate(173.75 225.75)"
                id="FacMou062__col1n"
            />
        </g></g>
    )
}

export default FacMou062
