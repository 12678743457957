import { AssetSvgProps } from "../../../shared/assets"

function FacEye019({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="FacEye019__Layer6_0_FILL">
                    <path
                        fillOpacity={0.251}
                        d="M192.75 165.9q.017.05 0 .1.637 3.755 2.1 7.45v.05q2.35 5.4 6.6 10.15 1.3 1.35 2.7 2.6 7.45 6.4 16.75 7.05 11.05.85 18.35-6.9 7.35-7.85 6.5-19.6-.024-.406-.05-.8-.004-.05 0-.1h-1.65q.02.05 0 .1.072.448.1.9.85 11.05-6.1 18.4-6.7 7.2-17.05 6.4-8.75-.65-15.8-6.65-1.35-1.25-2.6-2.5h.05q-4.15-4.55-6.35-9.7h.05q-1.328-3.382-1.9-6.85-.008-.05-.05-.1h-1.65m-.5 0h-1.7q.008.05 0 .1-.572 3.468-1.9 6.85h.05q-2.2 5.15-6.35 9.7h.05q-1.25 1.25-2.6 2.5-7.05 6-15.8 6.65-10.35.8-17.05-6.4-6.95-7.35-6.1-18.4.028-.452.05-.9.03-.05 0-.1h-1.6q.004.05 0 .1-.026.394-.05.8-.85 11.75 6.5 19.6 7.3 7.75 18.35 6.9 9.3-.65 16.75-7.05 1.4-1.25 2.7-2.6 4.25-4.75 6.6-10.15v-.05q1.463-3.695 2.05-7.45.033-.05.05-.1m12.8-25.55h.05q5.25-3.05 12-2.5 10.5.8 18.3 9.35 7.032 7.382 8.45 16.9h1.65q-1.435-10.176-8.95-18-8.25-9.05-19.35-9.85-7.25-.55-12.9 2.7-2.95 1.6-5.5 4.35-4.7 5.2-6.05 12.2-.05.2-.05.35l-.2 1.8-.2-1.8q0-.15-.05-.35-1.35-7-6.05-12.2-2.55-2.75-5.5-4.35-5.65-3.25-12.9-2.7-11.1.8-19.35 9.85-7.515 7.824-8.95 18h1.65q1.418-9.518 8.45-16.9 7.8-8.55 18.3-9.35 6.75-.55 12 2.5h.05q2.7 1.5 5.1 4.05 4.4 4.9 5.65 11.45v.2q.543 4.08.1 8.05h1.65q.034-.175.05-.35.016.175 0 .35h1.65q-.393-3.97.15-8.05v-.1-.1q1.25-6.55 5.65-11.45 2.4-2.55 5.1-4.05z"
                    />
                    <path d="M190.55 165.9h55.15q-.086-.909-.2-1.8h-106q-.114.891-.2 1.8h51.25z" />
                </g>
            </defs>
            <use xlinkHref="#FacEye019__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye019
