import { AppPlaceholder } from "./AppPlaceholder"

export function AppWrapperPlaceholder() {
    return (
        <>
            <header className="app-header d-none d-lg-block mb-3">
                <div style={{
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                    height: "150px"
                }} />
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between site-links-horizontal bg-white">

                    </div>
                </div>
            </header>

            <AppPlaceholder />
        </>
    )
}