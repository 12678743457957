import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#AC8268",
    "col1n": "#E4B670"
}

function LegPan007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M4.95 2.55q-.1-.15-.2-.25-.35-.35-.85-.35t-.85.35q-.1.1-.2.25-.15.25-.15.6 0 .5.35.85t.85.35q.5 0 .85-.35t.35-.85q0-.35-.15-.6m5.45 3.6V0H0v1h9.65v4.25H0v.9h10.4m48.9 7.8l1.4.3-1.4-.4Q35 7.45 10.4 8.75 5.2 9 0 9.6v.75q5.2-.45 10.4-.65 25.2-.9 48.9 4.25M62 17.1v.15q-.1.75-.35 1.45l-.05.05v.05q.05-.05.05 0 .15.05.25.05v.1q.2 0 .4.1l.5.1.5-.1q.1-.05.2-.05v-.1q.1-.05.15-.05h.05q.1-.05.15-.05 0-.05-.05-.1v-.05q-.25-.7-.3-1.4-.3-2.9 1.65-6.3-2.4 2.1-4.45.05 1.5 3.45 1.3 6.1m-.3-13.9q-.05-.05-.1 0l.55 6.1h1.15l.55-6.1h-.05v-.15h-2.1v.15m4.8 1.05l-.05-.05q-.35-.3-.8-.3-.5 0-.85.35l-.1.1q-.25.3-.25.75 0 .5.35.85t.85.35q.5 0 .85-.35t.35-.85q0-.5-.35-.85m59.4 6.1l.2.05v-.05h-.2m-.25.05v-.75q-11.5-1.3-22.85-.95-16 .45-31.65 4.1-3.05.7-6.05 1.5 3-.65 6.05-1.2 15.75-2.95 31.65-3.35 11.4-.35 22.85.65m-3.6-7.35q0-.25-.1-.5-.1-.2-.25-.35-.35-.35-.85-.35t-.85.35q-.15.15-.25.35-.1.25-.1.5 0 .5.35.85t.85.35q.5 0 .85-.35t.35-.85m3.6 3.1v-.9H115.4V1h10.25V0h-11v6.15h11z"
                    id="LegPan007__Symbol_9_0_Layer0_0_FILL"
                />
                <g id="LegPan007__Symbol_8_0_Layer0_0_FILL" fill={colors.col1n}>
                    <path d="M128.55 13.55h-.2l.2.05v-.05z" />
                    <path d="M128.85 5.75q-.15-.8-.75-1.55V.05l.05-.05Q105.2 4.5 82.6 5.75q-8.2.45-16.35.5h-2.1q-8.55 0-17.1-.5Q24.6 4.5 2.4 0q0 .05.05.1v4.1q-.7.8-.85 1.55-.35 1.35.85 2.7v5.1q-.35 0-.75.05-3.1 0-.6 6.35l3-.6q31.05-5 60.25 2.8.2 0 .4.1l.5.1.5-.1q.1-.05.2-.05 29.4-7.85 61.6-2.7v.15q.3-.05.55 0 .35 0 .65.05 2.75-6.15-.2-6.1h-.45V8.45q.95-1.4.75-2.7M117.9 2q.05 0 .1.05-.098 0-.1.05V2z" />
                </g>
            </defs>
            <use
                xlinkHref="#LegPan007__Symbol_8_0_Layer0_0_FILL"
                transform="translate(127.25 310.25)"
                id="LegPan007__col1n"
            />
            <use
                xlinkHref="#LegPan007__Symbol_9_0_Layer0_0_FILL"
                transform="translate(129.7 313.45)"
                id="LegPan007__col1d"
            />
        </g></g>
    )
}

export default LegPan007
