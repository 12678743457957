import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#666666"
}

function ObjToy020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M105.05 4.95q-.2-.3-.35-.5-1.9-2.45-4.55-4-.25-.1-.6-.25-.1-.15-.3-.2-1.4-.4-5.85 6.5-7.1 8.95-14.5 17.05 0 .3-.05.65-.1 1.3-.45 2.75-.45 2.05-1.6 4.2-1 1.8-2.25 3.35-.2.2-.5.6-1.65 1.75-3.85 3-.45.2-.85.45-3.2 1.45-6.05 1-2.2 2-1.95 3.5v.05L14 95.85q-.2.15-.35.3-1.2 1.15-2.65.7-1.95-.65-1.3-4 .3-.8.3-1.7-.2-1.9-1.65-3.15Q7 86.75 5.1 86.85 3.15 87 1.95 88.4q-.3.35-.55.8-1.6 2.7-1.4 6 .35 4.75 3.8 7.8 3.2 2.75 7.25 2.9 4.55-.2 8.2-3.35 1.6-1.4 3.75-3.8l27.65-31.3-1.15 1.2L67.2 48.3h.4q1.5-.3 3.3-2.95 1-1.2 1.95-2.45 3.85-5.15 8.05-10.2-.3-.35-.5-.7-.55-.9-.85-1.9-.25-1-.25-2.1 0-1.95.85-3.5.5-1.05 1.4-1.95 1.5-1.55 3.45-2 .2-.05.35-.1.55-.1 1.25-.15h.4q.2-.05.35 0h.05q.15-.05.3 0 .25 0 .5.05t.55.1q1.05.2 2 .75 4.25-4.7 8.75-9.35 6.3-5.2 5.7-6.6-.05-.25-.15-.3z"
                    id="ObjToy020__Symbol_162_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.1 9.25L9.6 5.2h.4L3.75 0 0 4.15l6.1 5.1z"
                    id="ObjToy020__Symbol_161_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy020__Symbol_162_0_Layer0_0_FILL"
                transform="translate(44.9 263.6)"
                id="ObjToy020__col1n"
            />
            <use
                xlinkHref="#ObjToy020__Symbol_161_0_Layer0_0_FILL"
                transform="translate(102.5 306.7)"
                id="ObjToy020__col2n"
            />
        </g></g>
    )
}

export default ObjToy020
