import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#4F4FFF",
    "col1s": "#0101AD",
    "col2n": "#FF7B00",
    "col2s": "#9F3D00"
}

function ShiLon038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.55 48.45q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-.15.1-.25.2v.05q-1.25.9-2.55 1.85l-1.55 1.15q-10.9 7.5-23.2 11.6-.25.05-.45.15l-.1.15Q85.4 28.15 82.8 53.8q-2-17.8-30.45-37.4-12.6-4.1-23.7-11.75L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2.8-.3 1.6-.05.2-.1.45-.05.15-.05.3v.1l.05.1q.1-.1 3-2.35 2.9-2.3 4.35-2.25 1.4 0 2.2-.6 7.3 1.3 11.8 5.8.15 0 .35.05.2.15.35.3l.05.05-.65 2.7q-.2.3-.4.65l-2.05 8.5q-.55 2.1-1.05 4.2l-.9 3.6-.85 3.25.3.2-.1.4q.4.1.75.2h.15l.4.1q.15.1.3.15 1.5.35 3.05.7 19.2 4.35 38.4 6 11.2.95 22.4 1h1.2q11.35 0 22.7-1 19-1.65 38-6 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q3.85-7.2 12.45-6.9-1.3.2.1.2 1.4 0 5.45 2.8l4.05 2.8v-.2q0-.15-.05-.3-.05-.25-.05-.45l-.5-2.6-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5M82.8 63.25q-.3.1-.6 0v-.1l.2.1h.2l.2-.1v.1z"
                    id="ShiLon038__Symbol_309_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.2 23.25q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L0 44.5v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05m110.2 0v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35m-10.4-4.5q.2.05.35-.05.15-.1.25-.25t0-.35L100.25 0l-.75.35q-.1 0-.15.05l5.05 17.1-13.35-4q-.2-.1-.4 0-.15.1-.25.3-.05.2.05.4l5.8 11.9L60.9 50.5q-.15.1-.3.15-.3.1-.6 0-.15-.05-.25-.15l-.05-.05v.05L24.35 26.1l5.8-11.9q.1-.2.05-.4-.1-.2-.25-.3-.2-.1-.4 0l-13.35 4L21.3.4q-.5-.2-.95-.4L15 18.1q-.1.2 0 .35.1.15.25.25t.35.05l13.15-4-5.5 11.3q-.05.1-.05.2v.1q0 .1.1.2.05.1.2.15l.05.05 36.15 25.1V76.4h1.2V51.85l36.15-25.1.05-.05q.15-.05.2-.15.1-.1.1-.2v-.1q0-.1-.05-.2l-5.5-11.3 13.15 4z"
                    id="ShiLon038__Symbol_244_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.85 6.75l.35-.35.05-.05q.3-.35.55-.65L9.35 0 2.9 5.7q.3.35.6.75l.3.3q.7.7 1.55 1.25l.2.1-.25-.05-5.3 20v.05l8.8 13.85.25.05.2.1h.2l.2-.1.3-.05 9.5-15-5.8-19.1-.2.05q.087-.063.2-.1.6-.45 1.2-1.05z"
                    id="ShiLon038__Symbol_208_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M8.5 0l-.2.05-.05.05Q6.45 1.2 4.2 1.2 2.1 1.2.4.25L.15.2 0 .85q1.95 1.3 4.2 1.3 2.4 0 4.55-1.35L8.5 0z"
                    id="ShiLon038__Symbol_167_0_Layer0_0_FILL"
                />
                <path
                    fill="#E0E0E0"
                    d="M162.35 245l20.8 32.85v-.05l-9.55-20.2 8.4 6.4 6.45-6.2v-.1q-.78-.48-1.5-1.2-.157-.157-.3-.35-.322-.328-.6-.75l-3.2 2.8q-.658.714-1.3 1.15L162.35 245m60.25.1l-.15-.05-19.05 14.05q-.286.2-.35.1-.164-.154-1-1l-3.15-2.8q-.203.35-.5.7-.03.027-.05.05l-.35.35q-.582.595-1.25 1l.2.55 6.15 6 8.4-6.45-8.85 18.95 19.95-31.45z"
                    id="ShiLon038__Layer3_1_FILL"
                />
                <path
                    fill="#C2C2C2"
                    d="M162.35 245l1.5 2.35 17.55 13.45 5.25-4.65q-.322-.328-.6-.75l-3.2 2.8q-.658.714-1.3 1.15L162.35 245m59.25 1.7l1-1.6-.15-.05-19.05 14.05q-.286.2-.35.1-.164-.154-1-1l-3.15-2.8q-.203.35-.5.7l4.85 4.7 18.35-14.1z"
                    id="ShiLon038__Layer3_2_FILL"
                />
                <g id="ShiLon038__Layer3_0_FILL">
                    <path
                        fill="#9A540F"
                        d="M188.45 257.7v.05l.25.1q-.108-.055-.25-.15m8.35-.1l-.05-.1q-.09.104-.2.15h.05l.2-.05z"
                    />
                    <path fill="#F59E49" d="M197.05 258.35H197h.05z" />
                    <path
                        fill="#FFF"
                        d="M220.05 245.75q-.42.155-.85.25-6.695 1.892-13.8 2.75-1.91.26-3.85.4-.273.044-.55.05-.248.031-.5.05l-1.1.05q-2.815.168-5.7.2h-2.4q-2.885-.032-5.7-.2l-1.1-.05q-3.087-.23-6.1-.65-.777-.105-1.55-.25-5.731-.846-11.2-2.4-1.646-.442-3.3-1v.05-.05h-.05l.05.05 20.8 32.8 5.3-20v-.1q-.78-.48-1.5-1.2-.157-.157-.3-.35-.322-.328-.6-.75l6.45-5.65 6.4 5.65q-.203.35-.5.7-.03.027-.05.05l-.35.35q-.582.595-1.25 1l.05.1.2.75h.05l5.55 18.3v.05l.05-.15 19.95-31.45-.15-.05-2.4.7m36.5 32.3l-3.75 7.9.2.1q4.55-5.05 12.65-4.7l2.05.15q.15.047.3.05 4.043.784 7 3.35.029.032.05.05v-.2q-.012-.15-.05-.3-.05-.25-.1-.45l-4.7-8.7-13.65 2.75m-139.2 3.5l1.85-.25q7.681-.263 12.15 4.25.212.022.4.05.178.128.35.3l.05.05.05-.05-3.7-7.85-13.7-2.8-4.7 8.75q-.01.221-.05.45-.034.138-.05.25l.25.05q2.914-2.43 6.8-3.15.15-.05.3-.05z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon038__Symbol_309_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon038__col1n"
            />
            <use
                xlinkHref="#ShiLon038__Symbol_244_0_Layer0_0_FILL"
                transform="translate(132.2 241.2)"
                id="ShiLon038__col1s"
            />
            <use
                xlinkHref="#ShiLon038__Symbol_208_0_Layer0_0_FILL"
                transform="translate(183.15 249.75)"
                id="ShiLon038__col2n"
            />
            <use
                xlinkHref="#ShiLon038__Symbol_167_0_Layer0_0_FILL"
                transform="translate(188.3 257.6)"
                id="ShiLon038__col2s"
            />
            <use xlinkHref="#ShiLon038__Layer3_0_FILL" />
            <use xlinkHref="#ShiLon038__Layer3_1_FILL" />
            <use xlinkHref="#ShiLon038__Layer3_2_FILL" />
        </g></g>
    )
}

export default ShiLon038
