import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#5F00C2",
    "col2n": "#7E00FF",
    "col2s": "#340962"
}

function LegPan030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.35 0h-.05Q81.9 3.7 37.85 2.3q-9.75-.3-19.5-.85Q9.25.9.15.15H0l.2 1.6 45.75 9.3L46 11l10.9 2.1h-.05l.6.1H68.8l57.35-11.7.2-1.5z"
                    id="LegPan030__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M128.1.15h-.05Q93.6 5.35 59.25 4.9 30.45 4.5 1.75.15 1.35.05.95 0-.05.05 0 1.3v.05Q.05 2.3.2 3q0 .1.05.2.3 1.2.9 1.35.05 0 .1.05.1 0 .25.05.85.1 1.75.3Q20.7 8.3 38.05 9.7l9.2.7h.1q.3 0 .6.05l.9.05v.05h-.05l-1.35 5 .05.05 4.3 7.15 6.65-5.3.5-6.55h.55l-.7 8.5 5.55 6.35L70 19.4l-.2-1.55v-.25l-.6-6.7h.6l.5 6.85 6.65 5.1 4.35-7.3-1.35-5Q96.8 9.7 112.4 7.3q1.1-.2 2.25-.35.8-.15 1.65-.25 5.05-.85 10.15-1.85l.9-.2.75-.25q.5-.3.85-1.25 0-.1.05-.2.15-.55.25-1.15.2-1.85-1.15-1.65z"
                    id="LegPan030__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M80.4 12.65l-4 5.65-5.6-3.9-1.25-6.65.5 6.85 6.65 5.1 4.35-7.3-1.35-5 .7 5.25M69.05 16.3l-4.75 5-4.95-5-.1-8.55-.7 8.5 5.55 6.35 5.65-6.35-.2-1.55v-.25l-.6-6.7.1 8.55M127.1.3l-.9.2q-5.1 1-10.15 1.85-.85.1-1.65.25-1.15.15-2.25.35Q96.4 5.35 79.4 6.2q-4.9.45-9.95.35h-.6q-1.9.05-3.85.05h-1.8q-1.85 0-3.7-.05h-.75q-3.55-.1-7.1-.25l-3.95-.2q-.3-.05-.6-.05H47l-9.2-.7Q20.45 3.95 3 .6 2.5.5 1.05.25.6.15 0 .05q.3 1.2.9 1.35.05 0 .1.05.1 0 .25.05.85.1 1.75.3 17.45 3.35 34.8 4.75l9.2.7h.1q.3 0 .6.05l3.95.2q3.55.15 7 .25l-.05.6-.95 5.6-5.75 4.35-4-5.7.7-5.2h-.05l-1.35 5 .05.05 4.3 7.15 6.65-5.3.5-6.55h.55q2.05.05 3.95.05H65q2 0 3.95-.05h.6q5.15 0 10.15-.35 16.85-.85 32.45-3.25 1.1-.2 2.25-.35.8-.15 1.65-.25 5.05-.85 10.15-1.85l.9-.2.75-.25q.5-.3.85-1.25l-1.6.3z"
                    id="LegPan030__Symbol_68_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M4.25 1Q3.8.25 2.8.05q-.95-.2-1.75.4-.6.4-.85 1.1-.1.2-.15.4-.05.2-.05.35v.1q0 .15.05.25.05.55.45 1.1v.05q.5.7 1.45.85 1 .15 1.75-.35.55-.35.8-.85v-.1l.1-.2.1-.4v-.6Q4.65 1.5 4.25 1M2.6.8q.65.15.95.65h.05q.25.35.25.7.05.25 0 .5-.05.2-.1.35-.05.1-.1.15-.05.1-.05.15-.15.2-.35.35-.45.3-1.1.15-.65-.05-1.05-.5-.15-.25-.2-.5-.05-.1-.05-.2v-.15q0-.2.05-.35.05-.15.05-.25.15-.45.5-.75Q2 .75 2.6.8m13.35 4.35Q15.9 4.5 15.5 4q-.45-.75-1.45-.95-.95-.2-1.75.4-.6.4-.85 1.1-.1.2-.15.4-.05.2-.05.35v.1q0 .15.05.25.05.55.45 1.1v.05q.5.7 1.45.85 1 .15 1.75-.35.55-.35.8-.85v-.1l.1-.2.1-.4v-.6m-3.8.65q-.05-.1-.05-.2v-.15q0-.2.05-.35.05-.15.05-.25.15-.45.5-.75.55-.35 1.15-.3.65.15.95.65h.05q.25.35.25.7.05.25 0 .5-.05.2-.1.35-.05.1-.1.15-.05.1-.05.15-.15.2-.35.35-.45.3-1.1.15-.65-.05-1.05-.5-.15-.25-.2-.5m15-3.65Q27.1 1.5 26.7 1q-.45-.75-1.45-.95-.95-.2-1.75.4-.6.4-.85 1.1-.1.2-.15.4-.05.2-.05.35v.1q0 .15.05.25.05.55.45 1.1v.05q.5.7 1.45.85 1 .15 1.75-.35.55-.35.8-.85v-.1l.1-.2.1-.4v-.6M26 1.45h.05q.25.35.25.7.05.25 0 .5-.05.2-.1.35-.05.1-.1.15-.05.1-.05.15-.15.2-.35.35-.45.3-1.1.15-.65-.05-1.05-.5-.15-.25-.2-.5-.05-.1-.05-.2v-.15q0-.2.05-.35.05-.15.05-.25.15-.45.5-.75.55-.35 1.15-.3.65.15.95.65z"
                    id="LegPan030__Symbol_70_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan030__Symbol_67_0_Layer0_0_FILL"
                transform="translate(129.35 309.9)"
                id="LegPan030__col1n"
            />
            <use
                xlinkHref="#LegPan030__Symbol_69_0_Layer0_0_FILL"
                transform="translate(127.85 305.4)"
                id="LegPan030__col2n"
            />
            <use
                xlinkHref="#LegPan030__Symbol_68_0_Layer0_0_FILL"
                transform="translate(128.1 308.55)"
                id="LegPan030__col2d"
            />
            <use
                xlinkHref="#LegPan030__Symbol_70_0_Layer0_0_FILL"
                transform="translate(178.95 318.55)"
                id="LegPan030__col2s"
            />
        </g></g>
    )
}

export default LegPan030
