import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}
function AccGla035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M194.8 13.75V0H0v13.75h6.95v6.95h7v7h6.95v6.9h6.9v6.95h41.75V34.6h7v-6.9h6.95v-7h6.95V6.8h13.9v13.9h6.95v7h6.95v6.9h.05v.05h6.9v6.9h41.75V34.6h7v-6.9h6.95v-7h6.9v-6.95h7z"
                    id="AccGla035__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.9 6.95h-7v7h7v-7M0 6.95h6.9V0H0v6.95m20.85 13.9v-6.9H13.9v6.9h6.95m6.95-6.9v6.9h7v-6.9h-7m0-7h-6.95v7h6.95v-7m-6.95 0V0H13.9v6.95h6.95m90.4 7v6.9h7v-6.9h-7m0-7h-6.95v7h6.95v-7m-6.95 0V0h-6.95v6.95h6.95m13.95 0V0h-7v6.95h7m6.95 13.9h6.95v-6.9h-6.95v6.9m0-6.9v-7h-6.95v7h6.95z"
                    id="AccGla035__Symbol_125_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla035__Symbol_126_0_Layer0_0_FILL"
                transform="translate(95.1 148.5)"
                id="AccGla035__col1n"
            />
            <use
                xlinkHref="#AccGla035__Symbol_125_0_Layer0_0_FILL"
                transform="translate(116 162.25)"
                id="AccGla035__col2n"
            />
        </g></g>
    )
}
export default AccGla035
