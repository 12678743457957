import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M112.35 21q4.3-1.45 10.75-4.75-5.7 2-8.2-1.1Q97.05-4.5 69.25 1.35q-4.05.75-7.7 2.4-3.65-1.65-7.7-2.4Q26.05-4.5 8.2 15.15q-2.5 3.1-8.2 1.1 6.45 3.3 10.75 4.75 17.45 5.05 35.4 0 3.2-1.05 6.9-3.85 5.2-3.95 8.5-9.05 3.3 5.1 8.5 9.05 3.7 2.8 6.9 3.85 17.95 5.05 35.4 0z"
                    id="BeaMus021__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M160.25 220.15q-4.25.15-9.2-.25-6.45-.6-14.1-2.25-1.1-.4-2.55-1.05 1.3.65 2.35 1.05 1.9.9 2.8 1.25 1.15.4 2.15.75 2.8.8 5.65 1.35 2.55.5 5.15.8 19.15 2.5 30.2-5.05.65-.5 1.3-.95 2.25-1.7 4.15-3.65l4.3-4.05 4.4 4.05q.05 0 .1.1 1.85 1.9 4.05 3.55l.3.2q10.7 8.2 30.1 5.95 5.95-.6 11.9-2.3l.4-.1q1-.35 2.05-.8.75-.3 2.45-1.1 1.55-.7 3.85-1.75-2.45 1.05-3.6 1.25-4.1 1-7.6 1.75l-2.7.45q-21.95 3.2-39.95-8.55-1.65-1.15-3.55-2.5-1.05-.75-2.15-1.55l-8.45 5.9q-7.95 5.55-22.25 7.4l-1.55.1z"
                    id="BeaMus021__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus021__Symbol_38_0_Layer0_0_FILL"
                transform="translate(130.95 198.65)"
                id="BeaMus021__col1n"
            />
            <use
                xlinkHref="#BeaMus021__Symbol_8_0_Layer0_0_FILL"
                id="BeaMus021__col1d"
            />
        </g></g>
    )
}

export default BeaMus021
