import { AssetSvgProps } from "../../../shared/assets"

function ShiMot053({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="red"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot053__Layer5_0_FILL"
                />
                <g id="ShiMot053__Layer5_1_FILL">
                    <path
                        fill="#FFF"
                        d="M184.7 272.25V258h-2.8v16.75h43.8v-2.5h-41m-2.8 5.1v16.45h2.8v-14h41v-2.45h-43.8m-22.7-5.1v2.5h20.05V258h-2.2v14.25H159.2m0 5.1v2.45h17.85v14h2.2v-16.45H159.2z"
                    />
                    <path
                        fill="#00F"
                        d="M159.2 274.75v2.6h20.05v16.45h2.65v-16.45h43.8v-2.6h-43.8V258h-2.65v16.75H159.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot053__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot053__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot053
