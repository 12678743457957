import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function BodMor001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.75 7.6q-.1.05-.15.15-1.65-1-4.1-1-3.9 0-5.95 2.75Q0 11.6 0 14.15q0 .15 1 3.95 1.9 3.7 6.5 3.7 3.55 0 5.75-2.75Q15 16.8 15 14.3q-.35-3.75-2.2-5.65 15.35-13.5 29.8-1.2 14.8 12.75 28.7 52.1.1.3.4.45.25.1.55 0 .3-.1.45-.35.1-.3 0-.6Q58.6 19.2 43.55 6.3q-15.4-13.2-31.8 1.3m173.9.15q-.1-.1-.2-.15-16.4-14.5-31.8-1.3-15.05 12.9-29.15 52.75-.1.3.05.6.1.25.4.35.3.1.6 0 .25-.15.35-.45 13.9-39.35 28.75-52.1 14.4-12.3 29.75 1.2-1.85 1.9-2.2 5.65 0 2.5 1.75 4.75 2.2 2.75 5.75 2.75 4.6 0 6.5-3.7 1-3.8 1-3.95 0-2.55-1.55-4.65-2.05-2.75-5.95-2.75-2.45 0-4.05 1z"
                    id="BodMor001__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodMor001__Symbol_3_0_Layer0_0_FILL"
                transform="translate(93.9 27.6)"
                id="BodMor001__col1n"
            />
        </g></g>
    )
}

export default BodMor001
