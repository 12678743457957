import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.05 8.5l.6-3.2q0-.05.05-.1 0-.35.25-.85Q12.4-1.25 1.2.4L0 3.9q.2.2.55.5 1.05.95.75 3.85-.3 2.9-.45 3.55-.15.65-.05.65.75-1.65 2.8-5.05.8-1.3 1.5-2.05Q6.25 4 7.2 4.05q1.5.05 1.9 1.45.4 1.45.4 2.55 0 1.8-.6 5.4.2.05.6-.75 2.8-5.5 5.05-6.65.2-.05.35-.05 1.45.05 1.9 1.5.35 1.4.35 2.5 0 1.65-.45 4.8-.05.15-.1.35.048-.048.05-.1 0 .152-.05.35.2.05.55-.7.2-.45.45-.8v-.05q1.35-3.35 3.45-5.35z"
                    id="SkiTat007__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat007__Symbol_7_0_Layer0_0_FILL"
                transform="translate(115.65 258.4)"
                id="SkiTat007__col1n"
            />
        </g></g>
    )
}

export default SkiTat007
