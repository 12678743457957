import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#621F00",
    "col1n": "#2E0E00",
    "col2l": "#999999",
    "col2n": "#666666"
}

function AccHat036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163 67.75v.05l77.75-4.7-1.15-9.55-42.3 2.6L179.1 0 44.5 8.8 37.15 66 0 68.3l.75 9.2L65 73.65v-.1l98-5.8M37.25 66L68.7 47.65 172.55 57.7 37.25 66z"
                    id="AccHat036__Symbol_254_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M129.05 56.15L110.85 0 53.9 3.75q-2.35 1.4-4.7 3.15-1.3.85-3.75 4.75-2.3 3.5-5.4 5.05-5.1 2.35-17.2 1.15-12.55-1.05-16.9.45Q-.3 20.45 0 31.6q.1 5.5.45 16.05L104.3 57.7l24.75-1.55z"
                    id="AccHat036__Symbol_212_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M160.15 16.15L154.9 0 2.1 9.45 0 26.05l160.15-9.9z"
                    id="AccHat036__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M5.4 7.6Q0 7.55 0 13q0 .1.75 2.7 1.5 2.7 4.65 2.7 5.4 0 5.45-5.5-.45-5.3-5.45-5.3m24.9 4.2q0 .95.2 1.5.65 1.3 2.5 1.3 2.7 0 2.7-2.8-.45-2.6-2.7-2.6-2.7 0-2.7 2.6m40-2.7q-.35-5.3-5.4-5.3-5.45-.05-5.4 5.4-.05.1.75 2.8 1.5 2.6 4.65 2.6 5.4 0 5.4-5.5m30.8-1.65q-.45-2.6-2.7-2.55-2.7-.05-2.7 2.55 0 1 .2 1.55.65 1.25 2.5 1.3 2.7-.05 2.7-2.85m25.4 3.4q5.4-.05 5.4-5.55-.3-5.3-5.4-5.3-5.4 0-5.4 5.4 0 .1.75 2.7 1.5 2.7 4.65 2.75z"
                    id="AccHat036__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat036__Symbol_254_0_Layer0_0_FILL"
                transform="translate(72.15 38.75)"
                id="AccHat036__col1n"
            />
            <use
                xlinkHref="#AccHat036__Symbol_212_0_Layer0_0_FILL"
                transform="translate(140.4 38.75)"
                id="AccHat036__col1l"
            />
            <use
                xlinkHref="#AccHat036__Symbol_77_0_Layer0_0_FILL"
                transform="translate(109.3 78.75)"
                id="AccHat036__col2n"
            />
            <use
                xlinkHref="#AccHat036__Symbol_42_0_Layer0_0_FILL"
                transform="translate(123.1 81.9)"
                id="AccHat036__col2l"
            />
        </g></g>
    )
}

export default AccHat036
