import { AssetSvgProps } from "../../../shared/assets"

function ShiMot106({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#18CD62"
                    d="M159.2 270v12.05h66.5V270h-66.5z"
                    id="ShiMot106__Layer1_0_MEMBER_0_FILL"
                />
                <g id="ShiMot106__Layer1_0_FILL">
                    <path
                        fill="#2C91DE"
                        d="M159.2 282.05v12.05h66.5v-12.05q-30.823-7.28-66.5 0z"
                    />
                    <path
                        fill="#EE20BF"
                        d="M159.2 258v12q33.25 4.419 66.5 0v-12h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot106__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot106__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot106
