import { AssetSvgProps } from "../../../shared/assets"

function ObjToo063({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M118.4 250.55q-11.1 2.15-16.95 7.65-1.45 1.55-2 2.2-1.8 2.1-3.1 4.45-.05.1-.1.15-.25.5-.55 1l-.7 1.6q-1.95 4.85-1.95 10.5 0 2.05.25 4 .3 1.95.8 3.8 1.85 6.55 7.05 11.75 5.55 5.55 12.65 7.3 2.7.65 5.6.8h1.3q11.45 0 19.55-8.1 6.65-6.65 7.85-15.55.25-1.95.25-4 0-.65-.05-1.25-.15-4.95-1.95-9.25-2-4.95-6.1-9.05-8.1-8.1-19.55-8.1-1.15 0-2.3.1z"
                    id="ObjToo063__Layer5_0_FILL"
                />
                <path
                    fill="#A68852"
                    d="M70.5 260l-1.2.4q.1.5.25 1.1.3 1.3.85 3.7 2-.3 3.85-1.25l.2-.15q.5-.25.9-.5-.65-1.65-2.95-4.25-.9.55-1.9.95m3.65-2.1q1.85 1.1 2.2 1.3.4.2 1.8 1.8.75-.7 1.45-1.55-1-1.35-1.55-1.8-.55-.5-2.25-1.2-.85.85-1.65 1.45m7.3-.8q.8-1 1.55-1.85-1.3-1.3-3.7-2.55l-.1.1-.1.1q-.6.75-1.25 1.45 1.95 1.1 2.05 1.15.2 0 1.55 1.6m4.65-4.9l.05-.05q.1-.05.15-.1-1.05-1.6-3.55-3.15-.25.3-.6.6-.4.45-.85.9-.05.1-.15.2.3.05 1.45.8 1.15.7 2 2.15.75-.75 1.5-1.35m23.7-2.05q-.45-.8-.9-1.45-.85.7-2.5 1.75-1.15.75-1.8 1.2.35.35.65.8l.5.7q1-.25 2.5-1.15 1.15-.7 1.8-1.35-.1-.25-.25-.5m-4.2-2.7q.7-.7.85-1.15-.4-.35-2.2-1.1-.4 1.1-1.45 2.55-1.05 1.45-1.4 1.7l.25.15 1 .45q.2.1.4.25.55-.35 1.2-1.25.7-.95 1.35-1.6m-5.45-.8q.2-1.7.3-2.7-.85-.15-2.25-.2 0 1.7-.25 2.95-.25 1.2-.6 1.95 1.05.05 2.1.3.4-.9.7-2.3m-8.55-2.4q-.1 0-.4.05.2.15.45 1.3.2 1.15.35 1.95.15.75 0 1.55 1-.3 2-.4v-.6q0-1.25-.1-2.15-.1-.95-.65-2-.3.05-.65.1l-.6.1q-.15.05-.4.1m-5.05 1.8l-.6.35q-.1.05-.25.15-.25.15-.55.35 1.35 1.1 1.6 1.5.05.05 1.05 2.25.05.1.1.25.5-.3.95-.55l1-.5q-.5-2-2.7-4.1l-.6.3z"
                    id="ObjToo063__Layer5_2_FILL"
                />
                <path
                    fill="#F30"
                    d="M53.95 278.35q10.2-6.8 22.55 9.15-4.65-17.2 6.95-22.15-13.2-3.2-12.9-17.75-5.9 13.95-18.6 10.9 9.85 7.8 2 19.85z"
                    id="ObjToo063__Layer5_3_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.6}
                    d="M114.9 251.85q-.4.45-.95.95l-.2.2 3 4.9 1.3-1.6-2.8-4.95q-.05.1-.15.2 0 .05-.1.15 0 .05-.1.15z"
                    id="ObjToo063__Layer5_4_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.6}
                    d="M124.6 254.55q.55 2.05-1.25 4.1 6.95 1.15 12.2 6.4 1.2 1.25 2.2 2.55 4.55 6 4.55 13.8v.7q-.05 1.9-.4 3.7v.95q-.5 1.9-1.35 3.7-1.75 3.75-4.95 6.95-.4.4-.8.75.7-.5 1.4-1.05 1-.85 1.95-1.8.25-.25.5-.55 5.25-5.45 6.4-12.65.1-.65.15-1.3.05-.3.05-.65.05-.6.05-1.15 0-6.25-2.7-11.4-1.7-3.3-4.55-6.15-5.75-5.75-13.45-6.9z"
                    id="ObjToo063__Layer5_5_FILL"
                />
                <path
                    fill="#FFDC00"
                    d="M59.05 273.7q6.4-4.15 13.1 4.9-2.05-9.9 5.15-12.9-8.15-2-7.95-11.25-3.6 8.85-11.45 7 6.05 4.85 1.15 12.25z"
                    id="ObjToo063__Layer5_7_FILL"
                />
                <g id="ObjToo063__Layer5_1_FILL">
                    <path
                        fill="#333"
                        d="M117 259.15q.55-.5.9-.95.2-.2.35-.4 1.3-1.65.85-2.65l-3.95-6.55v-.05q-.6-1.2-3.2-.8-1.45.25-3.15.85l.1.1q.45.65.9 1.45.15.25.25.5.4.65.7 1.45-.1.1-.25.2-.95.75-2.3 1.5-.9.45-1.7.8-.3-.8-.75-1.45l-.5-.7q-.3-.45-.65-.8-.2-.25-.4-.45-.1-.1-.2-.15-.55.4-1.05.8-.95.7-1.6 1.5-1.45 1.55-1.3 2.6l3.9 7.65q1.35.45 3.35.2 2.6-.35 5.7-1.95 2.45-1.2 4-2.7z"
                    />
                    <path
                        fill="#CBAA6E"
                        d="M86.55 246.05l-.6.35q-.1.05-.25.15-.25.15-.55.35-1.1.8-2.4 2-.25.3-.6.6-.4.45-.85.9-.05.1-.15.2-.9.95-1.85 2.1l-.1.1-.1.1q-.6.75-1.25 1.45-1.05 1.2-2.05 2.1-.85.85-1.65 1.45-.65.55-1.3.9-.2.15-.45.25-.9.55-1.9.95l-1.2.4q.1.5.25 1.1.3 1.3.85 3.7 2-.3 3.85-1.25l.2-.15q.5-.25.9-.5.2-.15.35-.25 1.2-.8 2.45-2.05.75-.7 1.45-1.55.65-.8 1.35-1.7.25-.35.5-.65.8-1 1.55-1.85.8-.95 1.6-1.7.75-.75 1.5-1.35l.05-.05q.1-.05.15-.1.8-.65 1.6-1.15.5-.3.95-.55l1-.5q1.05-.5 2.15-.75 1-.3 2-.4.8-.1 1.65-.1.75 0 1.7.05 1.05.05 2.1.3 1.2.2 2.2.65l1 .45q.2.1.4.25.25.15.5.35.25.15.45.4.1.05.2.15.2.2.4.45.35.35.65.8l.5.7q.45.65.75 1.45.8-.35 1.7-.8 1.35-.75 2.3-1.5.15-.1.25-.2-.3-.8-.7-1.45-.1-.25-.25-.5-.45-.8-.9-1.45l-.1-.1q0-.05-.1-.15-.4-.55-.85-1-.45-.45-1.4-1.15-.4-.35-2.2-1.1-1.15-.8-3.8-1.25-.85-.15-2.25-.2-.35-.05-1.2-.05-1.95 0-3.75.25-.3.05-.65.1l-.6.1q-.15.05-.4.1-.1 0-.4.05-2.45.55-4.65 1.75z"
                    />
                </g>
                <g id="ObjToo063__Layer5_6_FILL">
                    <path d="M105.75 253.15l-.5-.7q-.75.5-1.4 1.05-1.1 1.05-.8 1.55.25.55 1.75.1.3-.05.6-.15l1.1-.4q-.3-.8-.75-1.45m4.3-2.5q.4.65.7 1.45.25-.2.45-.4 1.1-1.05.85-1.6-.3-.5-1.8-.1-.2.05-.45.15.15.25.25.5z" />
                    <path
                        fill="#666"
                        d="M114 251.65q.2-.2.35-.4 1.3-1.65.8-2.65v-.05q-.6-1.2-3.2-.8-1.45.25-3.15.85l.1.1q.45.65.9 1.45.25-.1.45-.15 1.5-.4 1.8.1.25.55-.85 1.6-.2.2-.45.4-.1.1-.25.2-.95.75-2.3 1.5-.9.45-1.7.8l-1.1.4q-.3.1-.6.15-1.5.45-1.75-.1-.3-.5.8-1.55.65-.55 1.4-1.05-.3-.45-.65-.8-.2-.25-.4-.45-.1-.1-.2-.15-.55.4-1.05.8-.95.7-1.6 1.5-1.45 1.55-1.3 2.6l.5.95q.85.6 2.85.35 2.6-.35 5.7-1.95 2.45-1.2 4-2.7.55-.5.9-.95z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToo063__Layer5_0_FILL" />
            <use xlinkHref="#ObjToo063__Layer5_1_FILL" />
            <use xlinkHref="#ObjToo063__Layer5_2_FILL" />
            <use xlinkHref="#ObjToo063__Layer5_3_FILL" />
            <use xlinkHref="#ObjToo063__Layer5_4_FILL" />
            <use xlinkHref="#ObjToo063__Layer5_5_FILL" />
            <use xlinkHref="#ObjToo063__Layer5_6_FILL" />
            <use xlinkHref="#ObjToo063__Layer5_7_FILL" />
        </g></g>
    )
}

export default ObjToo063
