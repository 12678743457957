import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2n": "#FF0000",
    "col2s": "#6D0101"
}

function LegSoc004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M144.5 11.95q-.35-.15-.6-.3-.15-.05-3.3-.6-3.15-.6.8-.45-1.6-.6-3.2-1h-.05l-.4-.1-.15-.05q-.3-.05-.55-.1-.35-.1-.65-.15v-.05q-.2-.05-.35-.05-1.4-.75 0-1.5V5.75q-2.9-.9 0-1.85V1.85q-2.1-.95-3.65-1.7Q73.3 2.5 13.85 0L10.4 1.85h.15v.1q-.05-.05-.15-.1V3.9q2.2.75 0 1.85V7.6q1.45.75 0 1.45v.05q-.85.15-1.7.35-.6.15-1.15.3-.55.15-1.05.35-.6.15-1.2.4 1.45.65-.6.9-2 .2-2.1.25-.35.15-.65.3-.9.45-1.8.95H.1q-.05 0-.05.05-.05.05 0 .1l.05.05q0 .05.05.05h146.1q.05 0 .1-.05 0-.05.05-.05v-.1q-.05-.05-.1-.05h-.05q-.9-.5-1.75-.95z"
                    id="LegSoc004__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M53.6 8.15l.9.2q.7.1 1.4.25l1.5.3q.15.05.25.05l.3.1 1.4.3q.15.05.25.1h.05q.1 0 .2.05.15 0 .3.05-.25-.1-.55-.15-.1-.05-.25-.1-.6-.2-1.25-.4-.25-.1-.45-.15-.9-.3-1.75-.5-.45-.1-.85-.25-.1 0-.2-.05-.15-.05-.25-.05h-.1l-.05-.05Q50.6 6.8 46.8 6.05 46.65 6 46.55 6q-6.9-1.3-13.95-1.7-.4-.05-.8-.05-.4-.05-.85-.05l-6.8-.05h-.2l-3.9.15-1.3.1Q10.4 5 5.8 6q-.15 0-.25.05-2.75.6-4.1.95-.3.05-.5.15-.55.1-.75.2l-.2.1q5.4-.9 10.95-1.4.25-.05.55-.05 2.3-.2 4.6-.35.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q10-.4 19.7.55.25 0 .5.05 4.95.5 9.85 1.4l4.15.7m7.65-7.9l.25 2.05q.2.2.4.3-.1.65-.2 1.55L61.9 6h.2l.15 3.05v-.4l.05.4.15-1.2V6h.2l.2-1.85q-.05-.85-.1-1.5.15-.1.35-.35l.2-2.05q-1-.5-2.05 0m60.95 6.9l.55.05q.75.1 1.55.25V7.4q-.05-.05-.1-.05-.65-.3-5.35-1.3-.1-.05-.2-.05-4.65-1-13-1.6l-1.3-.1-3.9-.15h-.2l-6.8.05q-.45 0-.85.05-.4 0-.8.05-7.05.4-13.9 1.7-.15 0-.25.05-3.85.75-7.65 1.8-.1 0-.2.05-.1 0-.25.05-.1.05-.15.05-.45.15-.9.25l-1.4.4q-.2.05-.35.1-.4.1-.8.25l-.9.3q-.05 0-.1.05-.25.05-.5.15h.1q.1-.05.2-.05h.05q.1-.05.15-.05.05-.05.1-.05.15-.05.3-.05.7-.2 1.4-.3.25-.1.5-.1.6-.15 1.25-.3 1.15-.25 2.3-.45l4.15-.7q4.9-.9 9.85-1.4.25-.05.5-.05 9.7-.95 19.7-.55h.3q.15 0 .35.05l2 .1q.35 0 .65.05 2.3.15 4.6.35.25 0 .55.05 4.35.4 8.75 1.1z"
                    id="LegSoc004__Symbol_7_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.95 7.65q.094-.047.2-.05H7.8v1.45l1.55-.3q.2-.1.5-.15 1.35-.35 4.1-.95m52.1 2.9q-.1 0-.25-.05-31.05-2.75-63.1 0-1.25.45-2.5 1.05l-.2.1q34.85-1.2 70.15 0l-3.8-1-.3-.1m20-2.9q.094-.047.2-.05h-31.3q.107.002.25.05 3.8.75 7.65 1.8H78.4q3.8-1.05 7.65-1.8m55.25 4q-.15-.05-.25-.1-1.15-.5-2.25-.95-32.8-3.35-63.65 0-.7.1-1.4.3-.15 0-.3.05-.05 0-.1.05-.05 0-.15.05h-.05q-.1 0-.2.05h-.1q-.1 0-.2.05l-2.1.5q35.15-1.55 70.75 0M132.7 9l1.1.2v-.05q-.2-.05-.35-.05V7.6h-6.4q.105.002.2.05 4.7 1 5.35 1.3.05 0 .1.05m.75-3.25V3.9H7.8v1.85h125.65m0-3.9V0H7.8v1.85h125.65z"
                    id="LegSoc004__Symbol_8_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M.85 7.6l.2 1.85h.35l.2-1.85H.85m.95-1.85l.25-1.85H.45V4l.2 1.75H1.8M2.5 0H0l.2 1.85h2.1L2.5 0z"
                    id="LegSoc004__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSoc004__Symbol_6_0_Layer0_0_FILL"
                transform="translate(119.3 320.75)"
                id="LegSoc004__col1n"
            />
            <use
                xlinkHref="#LegSoc004__Symbol_7_0_Layer0_0_FILL"
                transform="translate(130.3 322.35)"
                id="LegSoc004__col1s"
            />
            <use
                xlinkHref="#LegSoc004__Symbol_8_0_Layer0_0_FILL"
                transform="translate(121.9 320.75)"
                id="LegSoc004__col2n"
            />
            <use
                xlinkHref="#LegSoc004__Symbol_9_0_Layer0_0_FILL"
                transform="translate(191.35 320.75)"
                id="LegSoc004__col2s"
            />
        </g></g>
    )
}

export default LegSoc004
