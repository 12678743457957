import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E1E1E1",
    "col1n": "#FFFFFF"
}

function ShiDre018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M148.9 15.2q-.8.15-1.7.45-3.4.95-8.5 3.6l-.6.3q-1.05.5-2.1 1.05-6.55 3-14.7 5.4-8.55 2.45-18.8 4.3l-2.2.4q-1.1.15-2.25.35-.65.05-1.35.15-14.5 1.8-28.75.15l-2.1-.2q-1.25-.2-2.45-.4L61 30.3Q49.55 28.25 41.05 26q-9.2-2.4-14.95-4.95l-.4-.2q-6.3-3.55-10.35-5.15-.8-.35-1.6-.55-.4-.1-.75-.15-4.25-.85-7.25 2.15-.05 0-.25.25-.4.35-.65.9-2.15 3.4-1.75 9.05 0 .7.1 1.5.2.6.7.95.55.4 1.9.7 3.35 0 7.2 1.3.35.1.75.25.9.35 1.85.8l.05-.05q.3.2.7.4.15.1.35.2 1.4.85 3 1.9.4.3 1 .65.85.5 1.95 1.05.45.25 1 .5.35.2.75.4.05 0 .15.05l-2.6 13.3q-.55 2.7-1.1 5.45L17.4 71q-1 3.9-1.95 7.8L15 80.55q.05 0 .15.05l-.5 2.15L.9 93.9q2.8.85 4.5 1.25L.5 100.9l-.5.5q22.55 2.9 45 4.15 6.6.35 13.2.6 10.95.35 21.95.35 22.65 0 45.2-1.7 16.9-1.25 34-3.45l-3.85-6.05q1.9-.45 4.7-1.2.05-.05.15-.05l-13.6-12.5-.2-.3-.45-2.15h-.15l-.2-.9q.05-.3.15-.7l-4.8-19.35q-.65-3.1-1.3-6.15l-2.75-14.05.2-.1v-.05q.05-.05.15-.1.25-.25.55-.45.15-.15.35-.25.8-.65 1.45-1.2 2.1-1.85 4.1-3.15.1-.1.25-.15h.05q2.45-1.35 4.75-2.05l-.65.25q3.8-1.35 7.45-.8 1.4 0 2.15-.45.55-.3.9-.85.7-6.5-1.7-10.3-.25-.55-.65-.9-.2-.25-.25-.25-3-3-7.25-1.95M25.85 36.4l.05.05-.1-.05h.05M80.8 83.5l-.05-.1.1.05q-.05 0-.05.05z"
                    id="ShiDre018__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M96.35 66.8q.2.1.4.15 6.45 2.2 14.5 3.35l2.15.25q9 1 17.15 0 2.25-.35 4.7-.75 8.45-1.4 18.8-3.5l-1.55-2.35-.5.1q-.85.15-1.65.35-11.75 2.35-23.45 3.95-3.45.15-6.7.1-7.2-.5-13.3-1.65Q87 63.75 77.85 53.4 71 61.3 54.1 65.45l-2.3.55-3.5.7q-.3.05-.55.1l-1.1.2q-6.9 1.1-14.85 1.2-4.55-.15-9.05-.7-8.75-1.35-17.5-3.1-.8-.2-1.6-.35-.65-.1-1.25-.25L0 66.3q14.15 2.6 22.75 3.95 4.5.55 9.05.7 15-.2 26.3-4 .2-.05.45-.15 11.65-4.05 19.3-12V54.65l.45.45q6.3 7.6 18.05 11.7M122.4 0h-.05q-4.25 2.2-8.5 3.95-2.2.9-4.4 1.7-31.15 11.2-61.9 0-1.9-.7-3.8-1.45Q42 3.45 40.2 2.65h-.05l-1.1-.3q-.5-.15-.95-.3-.55-.15-1.05-.35-1.85-.65-3.4-1.35L33.6.4q1.15.6 2.45 1.4.2.1.45.25 1.15.7 2.55 1.6.15.1.35.2V3.8h.05q.05.05.15.1l-.2-.05q2.45 1.1 4.15 1.8 17.7 7 35.25 7 17.45 0 34.75-7l.7-.3q1.5-.65 2.7-1.2.15-.1.25-.15.1-.1 1.15-.6.95-.55 1.8-1.5.85-1 2.25-1.9m14.35 4.45q2.1-1.85 4.1-3.15-3.45 1.75-6.9 3.3-.2.1-.45.2-.1 0-.15.05l-1.8.8q-1.55.65-3.05 1.3-44.9 18.35-89.45 4.3Q33.5 9.5 28 7.25l-3.8-1.6q-.65-.25-1.3-.55l-.1-.05-1.1-.3q-1.05-.3-2.05-.6-.7-.25-1.35-.45-2.3-.8-4.35-1.65-.3-.1-.6-.25 0-.05-.05-.05-.35-.15-.65-.3.3.2.7.4.15.1.35.2 1.4.85 3 1.9.4.3 1 .65.85.5 1.95 1.05.45.25 1 .5.35.2.75.4.05 0 .15.05l.05.05q.1 0 .15.05l.05.05q.05.05.1.05l.05.05q.05 0 .1.05l.7.35q2.5 1.1 4.95 2.1 5.7 2.25 11.35 4 19.65 6.05 39.2 6.05 25.6-.05 50.9-10.5 1.65-.65 3.8-1.75.4-.25.8-.45l-.3.2.5-.25q0-.05.05-.05h.05l.2-.1v-.05q.05-.05.15-.1.25-.25.55-.45.15-.15.35-.25.8-.65 1.45-1.2z"
                    id="ShiDre018__Symbol_65_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre018__Symbol_103_0_Layer0_0_FILL"
                transform="translate(111.65 226.8)"
                id="ShiDre018__col1n"
            />
            <use
                xlinkHref="#ShiDre018__Symbol_65_0_Layer0_0_FILL"
                transform="translate(114.65 258.15)"
                id="ShiDre018__col1d"
            />
        </g></g>
    )
}

export default ShiDre018
