import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2d": "#1082E7",
    "col2n": "#43A6FF"
}

function ShiSpe076({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.85 13.65q-.25-.15-.55-.3l-17.6-3.9h-.4l-.05-.1V9.3l-.1-.25V9q-.1-.2-.15-.4-1.05-2.8-3.5-4.35-.2-.1-.4-.25-.05-.05-.1-.05-.5-.35-1.05-.8-.65-.5-1.4-.85-.4-.2-.75-.35-8.3-3.2-14.25-1.1-6 2.1-8.1 3.1-.3.15-.65.3-1.6.65-3.75 1.35-.45.1-.95.25-3.25.95-5.65 1.15-3.1.55-6.3 1-2.8.4-5.65.7-1.15.15-2.3.25L12.35 13.7l-3.4 16.6v.05L3.25 53.8l-.1.15L0 68.5h136.85l-1.6-17.1v-.05l-5-21v-.05l-3.4-16.65m-31.9 47.4l.15.05-.2-.05h.05z"
                    id="ShiSpe076__Symbol_214_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22.8 16.3q.6-.6.6-1.6 0-1.35-4.2-1.85t-4.2-2.2q0-1.7 2.15-2.9.3-.15.65-.3l-5.45.65-3.4 16.6v.05L3.25 48.2l-.1.15-.8 3.4q.7-1.85 1.8-3.8 1.3-2.25 2.05-3.7.55-1.1 3-3.8 2.1-2.25 2.1-3.3 0-.3-.55-1.5t-.55-1.7q0-2 2.7-4.25t2.7-3.85q0-.65-.85-1.5t-.85-2.15q0-1.65 2.55-3.1 2.6-1.15 2.65-1.15 2.75-.75 3.7-1.65M74.05 0q-.45.1-.95.25-3.25.95-5.65 1.15-3.1.55-6.3 1-2.8.4-5.65.7.45.1.9.15 5.6.9 5.6 2.45 0 .25-.15.85t-.15 1.1q0 .7.1.9h.5q.05 0 5.15-4.5Q69.9 1.9 74.05 0M95.1 55.5V55l-95 7.5-.1.4h40.1l59.75-4.25.15-3.1q-2.45-1.3-4.9-.05m13.6-51.65h-.4l-.05-.1-3.55-.55 2.65.55q-3.05.2-6-.25-3.5-.55-3.9 3.6-2 23.05-2.85 46.15 2.4 1.55 5.5-.2l2-45.2q.05-3.6 8.95-3.45l-2.35-.55m21.55 20.85l-3.4-16.65q-.25-.15-.55-.3l-4.25-.95q1.15.55 1.65.95.7.65.7 1.55 0 1.4-4.45 2.4-4.45.95-4.45 1.65 0 1.2 3.9 2.4 3.9 1.25 3.9 2.85 0 1.15-1.75 2.5-1.75 1.3-1.75 3.65 0 1.85 1.95 3.15 2.35 1.65 2.95 2.95.6 1.45 2.7 6l.1.3q1.4 1.9 4.1 5.6 2.95 4.55 4.25 9.55l-.6-6.5v-.05l-5-21v-.05z"
                    id="ShiSpe076__Symbol_215_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M100.7.05q-4.3-.6-5.2 6.75l-2.45 44.9-92.9 7.35-.15.9 94.8-7.05 2.55-48.35q.4-4.15 3.9-3.6 2.95.45 6 .25L104.6.65l-1-.15q-1.35-.25-2.9-.45z"
                    id="ShiSpe076__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M93.35 59h.05l.15.05 41 .1v.05h.1l-.25-2.6H93.6l-.25 2.4m9.1-57.1q-.5-.35-1.05-.8-.65-.5-1.4-.85Q98.35-.2 96.35.1l-.5.1q-7.45 1.4-10.8 7.1-.4.65-.75 1.4-1.6 3.35-1.7 7.95-.55 6.25-.55 16.4l-.1 15.05L2.2 55.8v.05l-1.6.2-.6 2.8 1.7-.2v.05l83.65-8.05v-26.9q-.05-4.6.45-8.1.65-7.2 5.55-10.55 5.55-3.7 11.1-3.2z"
                    id="ShiSpe076__Symbol_217_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M5.45 0H.25Q.05 1.2 0 2.4h.3l.15.05 4.9.05.1-2.5z"
                    id="ShiSpe076__Symbol_218_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe076__Symbol_214_0_Layer0_0_FILL"
                transform="translate(122.9 255.65)"
                id="ShiSpe076__col1n"
            />
            <use
                xlinkHref="#ShiSpe076__Symbol_215_0_Layer0_0_FILL"
                transform="translate(122.9 261.25)"
                id="ShiSpe076__col1d"
            />
            <use
                xlinkHref="#ShiSpe076__Symbol_216_0_Layer0_0_FILL"
                transform="translate(123 263.8)"
                id="ShiSpe076__col1s"
            />
            <use
                xlinkHref="#ShiSpe076__Symbol_217_0_Layer0_0_FILL"
                transform="translate(124.45 257.7)"
                id="ShiSpe076__col2n"
            />
            <use
                xlinkHref="#ShiSpe076__Symbol_218_0_Layer0_0_FILL"
                transform="translate(217.55 314.3)"
                id="ShiSpe076__col2d"
            />
        </g></g>
    )
}

export default ShiSpe076
