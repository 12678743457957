import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M15.5 6.2q12.1-6.8 22.55-.45Q48.55 12 54.15 17.7 50 10.15 38.6 3.15 27.25-3.9 13.35 4.9-.5 13.7 0 36.1 3.45 13 15.5 6.2m103.95 29.9q.5-22.4-13.35-31.2-13.9-8.8-25.25-1.75-11.4 7-15.55 14.55Q70.9 12 81.4 5.75q10.45-6.35 22.55.45Q116 13 119.45 36.1z"
                    id="FacBro015__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro015__Symbol_15_0_Layer0_0_FILL"
                transform="translate(132.8 108.3)"
                id="FacBro015__col1n"
            />
        </g></g>
    )
}

export default FacBro015
