import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function AccHat138({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.05 16.5q-.2-.1-.4-.15-16.1-5.05-16.1-4.45-1.7-.85-3.45-1.6Q119.05-.2 96 0 72.95-.2 48.9 10.3q-1.75.75-3.45 1.6 0-.4-15.25 1.5-3.35 6.9-8.1 15.9Q7.35 45.7 1.8 71.95l-1.75 6.9q-1.1 6.65 22.4 4 4.35-.6 8.7-1.15 31.2-4 61.35-4.25.4 0 .8.05 1 .1 2.15.1h1.1q1.15 0 2.15-.1.4-.05.8-.05 30.15.25 61.35 4.25 4.35.55 8.7 1.15 23.5 2.65 22.4-4l-1.75-6.9q-5.55-26.25-20.3-42.65-3.8-6.95-6.85-12.8z"
                    id="AccHat138__Symbol_480_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M38 19q0-7.85-5.6-13.45Q26.85 0 19 0 11.15 0 5.55 5.55 0 11.15 0 19t5.55 13.4q4 4 9.1 5.15Q24.3 26.75 38 20.15V19m32.25 27.25q-5.55-5.55-13.4-5.55-7.85 0-13.45 5.55-2.75 2.8-4.15 6.15-1.15 2.7-1.35 5.8-.05.75-.05 1.5 0 7.85 5.55 13.45 4 4 9.1 5.15 2.1.45 4.35.45 7.85 0 13.4-5.6 5.2-5.15 5.6-12.3V59.7q0-.65-.05-1.3-.15-2.95-1.2-5.6-1.4-3.6-4.35-6.55m-5.4 11.45q1.95 1.95 1.95 4.65t-1.95 4.6q-1.9 2-4.6 2-2.7 0-4.65-2-1.9-1.9-1.9-4.6 0-2.7 1.9-4.65 1.95-1.9 4.65-1.9t4.6 1.9m-2.05 7.05q1.05-1 1.05-2.45 0-1.45-1.05-2.5-1-1-2.45-1-1.45 0-2.5 1-1 1.05-1 2.5t1 2.45q1.05 1.05 2.5 1.05t2.45-1.05m25.75 7.8H88q-5.2 0-8.9 1.95-2.6 1.4-3.35 3.2-.3.7-.3 1.5 0 2.75 3.65 4.7 2.6 1.4 5.95 1.8.4 0 .8.05 1 .1 2.15.1h1.1q1.15 0 2.15-.1.4-.05.8-.05 3.35-.4 5.95-1.8 3.65-1.95 3.65-4.7 0-.8-.3-1.5-.75-1.8-3.35-3.2-3.7-1.95-8.9-1.95h-.55m31.7-31.85q-7.85 0-13.4 5.55-2.95 2.95-4.35 6.55-1.05 2.65-1.2 5.6-.05.65-.05 1.3v1.15q.4 7.15 5.6 12.3 5.55 5.6 13.4 5.6 2.25 0 4.35-.45 5.1-1.15 9.1-5.15 5.55-5.6 5.55-13.45 0-.75-.05-1.5-.2-3.1-1.35-5.8-1.4-3.35-4.15-6.15-5.6-5.55-13.45-5.55m-8 17q1.9-1.9 4.6-1.9 2.7 0 4.65 1.9 1.9 1.95 1.9 4.65t-1.9 4.6q-1.95 2-4.65 2t-4.6-2q-1.95-1.9-1.95-4.6 0-2.7 1.95-4.65m4.5 1.1q-1.45 0-2.45 1-1.05 1.05-1.05 2.5t1.05 2.45q1 1.05 2.45 1.05 1.45 0 2.5-1.05 1-1 1-2.45 0-1.45-1-2.5-1.05-1-2.5-1M177.1 19q0-7.85-5.55-13.45Q165.95 0 158.1 0t-13.4 5.55q-5.6 5.6-5.6 13.45v1.15q13.7 6.6 23.35 17.4 5.1-1.15 9.1-5.15 5.55-5.55 5.55-13.4z"
                    id="AccHat138__Symbol_361_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat138__Symbol_480_0_Layer0_0_FILL"
                transform="translate(96.5 51.75)"
                id="AccHat138__col1n"
            />
            <use
                xlinkHref="#AccHat138__Symbol_361_0_Layer0_0_FILL"
                transform="translate(103.95 43.5)"
                id="AccHat138__col2n"
            />
        </g></g>
    )
}

export default AccHat138
