import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.15 4.1q-.6-.8-1.75-1.35-2.05-1-6.2-1.45l-.7-.1v-.1q0-.15-.1-.25-.15-.15-.3-.15t-.25.15q-.1.05-.15.2Q21 .2 18.4.4q0-.15-.1-.25Q18.15 0 18 0t-.25.15q-.15.1-.15.25-.5-.05-1.4-.05-1.45-.05-6 .65-.05-.15-.1-.2-.15-.15-.3-.15T9.55.8q-.15.1-.15.25v.1q-.35 0-.7.05-4.15.45-6.2 1.45-1.1.55-1.7 1.3v.35q-.1.1-.2.25h.05q-.6.9-.6 2 0 .05.05.4-.1.1-.1.25t.15.3H.2v.3Q.4 8.8.8 9v.05q.45.8 1.25 1.3Q3.7 11.4 7.5 11.5q2.35.05 8.3-.2h1.5q1.4.1 2.8.1 4.05.15 6.4.2.15.05.35.05h2.6q.25-.05.6-.15 2.5-.25 3.8-1.05.4-.25.75-.6.05.05.2.05h.15q.3-.45.55-.85.5-.75.75-1.2V7.5q.05-.1.05-.2t-.05-.2q-.05-.4-.15-.75-.2-.5-.45-1-.15-.2-.25-.4t-.25-.35v-.5z"
                    id="FacMou019__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M104.8 190.7q-.172-.203-.45-.2-.293.01-.5.2-.203.222-.2.5.01.293.2.5 14.858 14.343 35 22.9 14.843 6.282 32.5 9.5.063.006.1 0 1.176.206 2.4.4.275.045.5-.15.245-.155.3-.45.046-.275-.15-.5-.155-.244-.45-.3-1.238-.2-2.45-.4-.063-.016-.15-.05-17.417-3.163-32.05-9.35-19.908-8.443-34.6-22.6m176.25.7q.003-.278-.2-.5-.207-.19-.5-.2-.278-.003-.5.2-14.642 14.157-34.6 22.6-12.669 5.397-27.5 8.5-1.329.266-2.7.5-1.575.3-3.25.6l-1.2.25q-.288.085-.45.3-.013.047-.05.05.313.525.45 1 .176.07.35 0 .636-.112 1.15-.2 1.675-.3 3.25-.6 1.245-.234 2.45-.5 15.137-3.106 28.05-8.6 20.191-8.557 35-22.9.24-.207.25-.5z"
                    id="FacMou019__Layer5_0_FILL"
                />
                <path
                    d="M210.5 225.3h-.75q-.015.002-.1 0-3.142-.195-8.65-.6v-5.2q-.004-.162-.15-.3-.088-.096-.25-.1-.162.004-.3.1-.07.114-.1.2v5.25q-4.893-.351-7.25-.35h-.1l.05-5.55q-.015-.121-.15-.25-.088-.096-.25-.1-.16.004-.3.1-.085.128-.1.25l-.05 5.5q-.06-.001-.15-.05-.05.005-.1 0-2.352-.001-7.1.35v-5.15q-.027-.133-.15-.25-.088-.096-.25-.1-.162.004-.3.1-.096.138-.1.3v5.15q-5.333.407-8.4.6H174.85q-.131.025-.25.1-.096.138-.1.3.004.162.1.25.138.146.3.15H175.55q3.052-.192 8.35-.6v3.7q.004.162.1.25.138.146.3.15.162-.004.25-.15.146-.088.15-.25v-3.75q4.749-.349 7.1-.35.14.04.25.05v4.15q.004.162.1.25.138.146.3.15.162-.004.25-.15.146-.088.15-.25v-4.1h.1q2.354.001 7.25.35v3.75q.004.162.1.25.138.146.3.15.162-.004.25-.15.146-.088.15-.25v-3.7q5.496.407 8.6.6h.05q.07 0 .1-.05.089.048.15.05h.5q.162-.004.25-.15.146-.088.15-.25-.004-.162-.15-.3-.051-.057-.15-.1z"
                    id="FacMou019__Layer5_1_FILL"
                />
                <path
                    d="M210.55 222.7q-.599-1.36-2.35-2.2-2.121-1.029-6.45-1.5-.475-.043-1-.2 0 .011-.05 0-5.355-.942-8.15-.7h-.1q-.419-.052-1.75-.05-1.544-.055-6.5.7h-.05q-.5.098-1.05.15-4.279.471-6.4 1.5-1.792.887-2.5 2.2-.233.543-.3 1.1v-.05q-.044.331-.05.7-.005.702.1 1.35.35 2.497 2.2 3.65 1.775 1.148 5.8 1.25 2.386.051 8.35-.2h1.45q1.412.1 2.8.1 6.01.251 8.3.2 4.07-.104 5.85-1.25 1.838-1.147 2.2-3.65.104-.635.1-1.35-.006-.369-.1-.7-.09-.585-.35-1.1v.05m-1.05 2.9q-.247 1.803-1.55 2.65-1.571.954-5.15 1.05-2.26.049-8.2-.2-1.375-.001-2.75-.1h-1.6q-5.89.249-8.25.2-3.525-.098-5.1-1.05-1.3-.847-1.55-2.65v-.05q-.095-.515-.1-1.1-.006-.231 0-.45.038 0 .05-.05.033-.293.15-.6.542-.937 1.85-1.6 1.978-.921 5.95-1.35.6-.049 1.15-.15 4.807-.739 6.25-.7h.05q1.338.003 1.7.05.101.008.15 0 2.655-.232 7.9.7l-.05-.05q.625.193 1.2.25 4.021.429 6 1.35 1.249.61 1.7 1.55.19.335.25.7.056.219.05.45-.004.585-.1 1.1v.05z"
                    id="FacMou019__Layer5_3_FILL"
                />
                <g id="FacMou019__Layer5_2_FILL">
                    <path
                        fill="#999"
                        d="M179 223.4q-.1-.3-.1-.6v-.2q-1.6.2-3.35.35h-.45q-.2.4-.25.85h.45v-.05h.25q2.1-.2 3.45-.35m10.95-1.65h-.05v.3q0 .25-.05.5h.05q1.275-.049 2.55 0 1.35-.05 2.75 0-.05-.15-.05-.3 0-.25.05-.5-1.4-.05-2.75 0h-2.5m-3.05.7q.023.039.05.05-.05-.202-.05-.45v-.15q.007-.088 0-.2-.042.096-.1.2-1.259.05-2.5.15-1.25.1-2.45.25.05.2.05.5v.25q1.15-.1 2.4-.2 1.3-.1 2.7-.15-.06-.121-.1-.25m15.9.6v-.15q0-.35.1-.65-1.15-.15-2.35-.2-1.2-.1-2.35-.15v.35q0 .2-.05.45 1.2.05 2.4.15 1.15.05 2.25.2m3 .3q1.25.15 3.85.5V223q-2-.2-3.8-.45v.35q0 .25-.05.45z"
                    />
                    <path
                        fill="#666"
                        d="M179.35 221.55q-.4.45-.45 1.05v.2q0 .3.1.6t.35.6q.45.55 1.05.55.6 0 1.05-.55.35-.4.45-.95v-.25q0-.3-.05-.5-.1-.45-.4-.75-.45-.5-1.05-.5-.6 0-1.05.5m16.25-.55q-.3.35-.4.75-.05.25-.05.5 0 .15.05.3.05.5.4.9.45.55 1.05.55.65 0 1.1-.55.3-.35.4-.75.05-.25.05-.45v-.35q-.1-.55-.45-.9-.45-.5-1.1-.5-.6 0-1.05.5m-8.65 1.5q.02.113.05.2.1.3.35.55.45.55 1.05.55.6 0 1.05-.55.35-.3.45-.7h-.05q.05-.25.05-.5v-.3h.05q-.1-.55-.5-.95-.45-.5-1.05-.5-.6 0-1.05.5-.346.39-.45.9.007.112 0 .2v.15q0 .248.05.45m18.9.05q-.1-.55-.45-.9-.45-.5-1.05-.5-.65 0-1.1.5-.25.25-.35.6-.1.3-.1.65v.15q.05.6.45 1.05.45.55 1.1.55.6 0 1.05-.55.3-.35.4-.75.05-.2.05-.45v-.35z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou019__Symbol_44_0_Layer0_0_FILL"
                transform="translate(174.5 218.4)"
                id="FacMou019__col1n"
            />
            <use xlinkHref="#FacMou019__Layer5_0_FILL" />
            <use xlinkHref="#FacMou019__Layer5_1_FILL" />
            <use xlinkHref="#FacMou019__Layer5_2_FILL" />
            <use xlinkHref="#FacMou019__Layer5_3_FILL" />
        </g></g>
    )
}

export default FacMou019
