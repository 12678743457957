import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSho085({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M190.95 38.55q-6.25-8.6-15.4-14.8-28.15-19.2-31.95-20.6Q135.55 0 106.5 0 78.4 0 65.3 6.4q-1.5.75-33.9 22.95-4.7 3.1-8.7 9.2-6.4 9.25-11.1 25.6Q8.5 75 3.7 93.2 2.05 99.4.2 106.45q-.15.2-.15.6-.15.55.15 1.4.4 1.35 1.3 3.4 2.35 4.75 8.1 13.65 9.55 14.7 12 18 2.9 4.8 5.2 7.3l-3.25-18.6q-4.4-2.55-7.75-8.2-3.4-5.65-2.9-12.2.45-6.55 4.5-9.45 4.05-2.95 10.2-.55 2.75 1.1 5.2 3.1-.4-6.85-.6-11.7-.05-2.25-.1-4.1-.85-13.95 1.9-21 2.45-7 11.2-12.25.65-.45 1.35-.85 7.2-4 14.95-3.85 13.75 0 23.95 3.5L96.1 57.8q3.25 1 9.7 4.45.1 0 20.75-7.95.6-.25 1.2-.45 4.6-1.8 8.55-2.95 19.15-4.85 29.65 9.3 1.85 2.65 3.6 5.9 1.1 2.1 2.2 4.45 5.45 11.85 7.1 22.65.9 6.2.55 12.05.1-.1.25-.2 2.45-2.1 5.35-3.25 2.95-1.15 5.6-1.05 2.7.1 5.5 2.45 2.75 2.3 3.15 8.85.35 6.55-2.5 11.75-2.9 5.2-6.6 7.75-6.5 18.75-8.8 24.8 1.4-1.25 4.85-4.85 7.2-7.55 15.8-18.9 6.75-12.1 7.45-39.4.05-3.2.05-6.55 0-10-3.95-19.85-5.15-10.5-7.65-16.1-2.8-6.6-6.95-12.15z"
                    id="HaiSho085__Symbol_206_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M15.4 61.6q-.3-.3-.7-.3H.95q-.4 0-.65.3-.3.25-.3.65t.3.7q.25.25.65.25H14.7q.4 0 .7-.25.25-.3.25-.7 0-.4-.25-.65M4.05 44.8q-.35-.2-.7 0-.4.15-.5.5-.2.35 0 .75.15.35.5.5L15.7 51.4q.35.15.75 0 .35-.2.5-.55.15-.35 0-.7-.2-.4-.55-.5L4.05 44.8m15.8-29.35q-.3-.3-.65-.3-.4 0-.7.3-.3.25-.3.65 0 .35.3.65L30.55 29.3q.25.3.65.3.35 0 .65-.25.3-.3.3-.65 0-.4-.25-.7L19.85 15.45M85 31.7q-.05.35.25.7.25.25.65.3.35.05.7-.2.25-.3.3-.65.95-13.7-10.55-24.4-.3-.25-.65-.25-.4 0-.7.3-.25.3-.25.7 0 .35.3.65 10.8 10 9.95 22.85m36.4-30.55q.05-.35-.15-.7t-.6-.4q-.35-.1-.7.1t-.4.6q-3.95 17.2-16 29.25-.3.25-.3.65t.3.7q.25.25.65.25t.7-.25q12.4-12.4 16.5-30.2m57 54.9q.25-.3.25-.7 0-.4-.25-.65-.3-.3-.7-.3h-11.2q-.4 0-.65.3-.3.25-.3.65t.3.7q.25.25.65.25h11.2q.4 0 .7-.25m-14.55-9.8q-.4.05-.65.35-.25.25-.25.65.05.4.35.65.25.25.65.25l13.3-.7q.4-.05.65-.3.25-.3.25-.7-.05-.4-.3-.65-.3-.25-.7-.25l-13.3.7z"
                    id="HaiSho085__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho085__Symbol_206_0_Layer0_0_FILL"
                transform="translate(86.2 56.8)"
                id="HaiSho085__col1n"
            />
            <use
                xlinkHref="#HaiSho085__Symbol_13_0_Layer0_0_FILL"
                transform="translate(99.8 80.2)"
                id="HaiSho085__col1s"
            />
        </g></g>
    )
}

export default HaiSho085
