import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#005100",
    "col1l": "#00B600",
    "col1n": "#008300",
    "col2n": "#252525"
}

function ObjMor024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M91.25 10.8q0-.1-.05-.2-.95-2.8-2-4.7-1.25-2.3-2.6-3.25-2.05-2-6-2.15-4.2.55-7.6 1.85L72.35 0 28.7 11.55q0 .3.05.6h-.05l.25 1q2.85 7.2-.25 9.45-1.35 1.05-4.05.9h-.05q-2.35.2-8.3-2.65-5.15-2.5-12.95-7.3Q-1.4 14.6.55 18.8l28.3 20.35h.05l5.65 4.8V44l4.1 3.15 2.25 8.15q2.75 4.5 16.15 1.7 3.85-.8 8.55-2.15 15.9-4.3 18.9-10.45l-1.25-4.45q.25-.15.5-.25h.05q.85-.5 1.3-.55.4-.1 3.9-1.55.15-.05.3-.15 6.65-2.85 5.15-11.85-.05-1.25-.25-2.65-.1-.05-.2-.05-.9 4.3-5.15 6-.15 2.2-4.05 3.75-.2.05-.45.1-.05 0-.05.05-1.25.4-2.85.55L74.65 8.4q.55-.15 1.2-.35.1-.05.4-.1.3-.1 1.8-.45 1.4-.45 3.75-.1 2.35.3 2.6 4.6l1.4-.05q2.85 0 3.7.9.3.1.5.25.5-1.15.9-1.9.05-.1.1-.15.1-.15.25-.25z"
                    id="ObjMor024__Symbol_132_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M43.75 2.35l-.3-1.1Q42.5.2 40.6.05q-1.95-.2-6.45.25Q29.55.75 20 3.35q-3.4.9-6.35 1.85-3.85 1.25-6.9 2.7-.25.1-.45.2-6.9 2.7-6.25 6.05.15.05.4.15 1.65.55 5.9.2 4.25-.3 9.45-1.4 3.05-.7 6.4-1.6 6.25-1.7 11.15-3.6 2.6-1 4.8-2.05 4.4-2.1 5.6-3.5z"
                    id="ObjMor024__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M21.65 4.25q3.4-1.85 3.05-3.1Q24.35-.1 20.5 0q-3.85.15-8.95 1.55-5.1 1.4-8.5 3.2Q.75 6.05.2 7.05q-.25.45-.15.85Q.4 9.15 4.2 9q3.9-.1 9-1.45.85-.25 1.65-.5 3.95-1.25 6.8-2.8z"
                    id="ObjMor024__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10 0Q4.2 3.55 1.75 8.65-.4 13.15.1 18.8q.05.65.15 1.3l10.5-2.3 5.2 3.75 2.8-5.25q-1.95-1.15-3.95-2.45-.2-.15-.45-.3L10 0z"
                    id="ObjMor024__Symbol_34_0_Layer0_0_FILL"
                />
                <path
                    fill="#BFBFBF"
                    d="M21.5 289.55q-.4 0-.65.25t-.25.65q0 .4.25.65t.65.3q.4-.05.7-.3.25-.25.25-.65t-.25-.65q-.3-.25-.7-.25m.5-2.05q.4-.05.7-.3.25-.25.25-.65t-.25-.65q-.3-.25-.7-.25-.4 0-.65.25t-.25.65q0 .4.25.65t.65.3m4.85.5q-.3-.25-.7-.25-.4 0-.65.25t-.25.65q0 .4.25.65t.65.3q.4-.05.7-.3.25-.25.25-.65t-.25-.65m-.65-4.45q-.25.25-.25.65t.25.65q.25.25.65.3.4-.05.7-.3.25-.25.25-.65t-.25-.65q-.3-.25-.7-.25-.4 0-.65.25m3-4.15q-.4 0-.65.25t-.25.65q0 .4.25.65t.65.3q.4-.05.7-.3.25-.25.25-.65t-.25-.65q-.3-.25-.7-.25m-7.25 3.6q0 .4.25.65t.65.3q.4-.05.7-.3.25-.25.25-.65t-.25-.65q-.3-.25-.7-.25-.4 0-.65.25t-.25.65m2.15-3.35q0 .4.25.65t.65.3q.4-.05.7-.3.25-.25.25-.65t-.25-.65q-.3-.25-.7-.25-.4 0-.65.25t-.25.65m3.9-1.95q.4-.05.7-.3.25-.25.25-.65t-.25-.65q-.3-.25-.7-.25-.4 0-.65.25t-.25.65q0 .4.25.65t.65.3z"
                    id="ObjMor024__Layer4_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor024__Symbol_132_0_Layer0_0_FILL"
                transform="translate(34.75 275.8)"
                id="ObjMor024__col1n"
            />
            <use
                xlinkHref="#ObjMor024__Symbol_102_0_Layer0_0_FILL"
                transform="translate(63.45 273.8)"
                id="ObjMor024__col1l"
            />
            <use
                xlinkHref="#ObjMor024__Symbol_73_0_Layer0_0_FILL"
                transform="translate(79.4 274.65)"
                id="ObjMor024__col1d"
            />
            <use
                xlinkHref="#ObjMor024__Symbol_34_0_Layer0_0_FILL"
                transform="translate(19.35 273.05)"
                id="ObjMor024__col2n"
            />
            <use xlinkHref="#ObjMor024__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjMor024
