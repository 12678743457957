import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.9 3.5q-.1-.2-.15-.4-.65-.5-1-.75-.4-.3-1.65-1.4Q18 .9 17.9.8q-1.5-1.3-.95-.55.25.35.55.7.35.4.8.85.8.8 1.6 1.75l.1.1q-.6.35-.95.6-.7-.6-1.35-1.55.5 1 .9 1.75-1.85 1-3.65 1.8-.05-1.6-.6-4.95l-.2 3.4q-.1.45-.05 1.1-.8-.65-3.35-2.05 1.9 1.95 2.65 2.45.35.25.65.4-4.4 1.75-9.1 2.45.15-.8.4-1.3.9-2.35 1.4-3.25.45-.95-.4.2l-1.8 2.25q-.4-1.15-2.75-2.4l1.1 1.4q.05.15.85 1.45.4.7.4 1.7-1.25.1-2.5.15L.9 7.45Q.7 7 0 6.4q.2.9.55 2.3.45 2.2.55 2.6.4.3 1.15 1.7.4.75.7 1.15-.55-.05-1.05-.05l-.2.1.2 1.15h.3q.5 0 1.05.05.1.3.25.7l.1.2q-.55.05-.55 2.2l.9-1.6H4q.65 1.1 1.2 1.2.5.1 1.85.8-2.6-2.65-3.15-3.5 3.6-.1 7.25-1v.05q.15.4-.1 3.4l.85-.95v-2.55q.05-.1.05-.2.3-.1.6-.15.2.2 0-.05 2.45 2.9 3.2 2.6-2.3-2.55-2.5-2.8 3.05-1 5.9-2.4-.7 4 .25 4.65.15-2.85.85-1.1-.25-2.1-.2-2.9.05.05.95.8.9.7 1.35.7.45 0 2.05.3-3.25-1.8-4.3-2.95 1.3-.7 2.65-1.5l.2-.1q-.2-.65-.4-1.2-.1 0-.2.05-1.3.75-2.55 1.45 0-1.25.1-1.35.05-.1.25-.2.25.4.7.4.25 0 .3-.15V7.8q-.05-.15-.1-.25.05-.05.15-.1.65-.25.8-.85 0-.15-.05-.3-.25-1.05-1.05-2.8m-.15 1.2q.35.95.65 1.45 0 .45-.65.85-.5-.9-1.05-1.7.5-.3 1.05-.6m-6.25 5.7q.35-.5.4-2.4.45.35 1.3.9 1.3.95 2 1.45-2.65 1.25-5.25 2.1-.25 0 .5-.75.7-.8 1.05-1.3m-3.75.55q-.2-.05-.35-.1.25-.5.25-.7v-.5q-.7.6-.95.95-1.2-.45-2.1-.75 3.35-.65 6.75-2-.15.1-.25 1.25t-1.8 2.7v.75q-1.05-1.3-1.55-1.6M17 8.3q-.9-.65-1.5-.95 1.8-.8 3.65-1.75.7 1.55.8 1.85-.9.55-.9 1.9v.55l-.05.05q-.7-.75-2-1.65M1.9 10.4v-.05q1.05 0 1.9-.05.05.7-.25 1.55-.2.4-.3.8-.8-1.7-1.35-2.25m2.35 1.7q.1.15.4.25.3.05 2 .6-1.5-1.6-2.15-1.75.15-.5.25-.95.6-.1 1.25-.15 1.2.5 3.2 1.05l-.3.4-1.1 1.8 2.25-1.95h.05q.55.35 1.25 1.55-3.65 1-7.45 1.15-.15-.3-.25-.55.15-.7.3-1.15.15-.5.3-.3z"
                    id="SkiTat006__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat006__Symbol_6_0_Layer0_0_FILL"
                transform="translate(246.5 253.8)"
                id="SkiTat006__col1n"
            />
        </g></g>
    )
}

export default SkiTat006
