import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#000000"
}

function AccHat141({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.25 29.05Q11.2 17.35 10.9 0-2.4 12.85.55 36.55q22.4 1.1 26.7-7.5m74.75 7.4Q104.5 12.7 91.4.05q-.3 17.35-16.35 29.1 6.9 8.5 26.95 7.3z"
                    id="AccHat141__Symbol_483_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M161.65 24.25Q146.45 14.9 131.3 9.1q-14.75-5.55-26.95-7.3-24.45-3.55-47.8-.1-12.05 1.75-26.7 7.5Q15.2 14.95 0 24.35q39.75-12.1 79.5-12.3h2.75q1.8 0 3.6.05 37.8.7 75.65 12.15l.3.1q-.1-.05-.15-.1z"
                    id="AccHat141__Symbol_363_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat141__Symbol_483_0_Layer0_0_FILL"
                transform="translate(140.9 49.2)"
                id="AccHat141__col1n"
            />
            <use
                xlinkHref="#AccHat141__Symbol_363_0_Layer0_0_FILL"
                transform="translate(111.6 76.55)"
                id="AccHat141__col2n"
            />
        </g></g>
    )
}

export default AccHat141
