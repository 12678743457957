import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#D24400",
    "col1n": "#9A3200",
    "col1s": "#2E0E00",
    "col2n": "#000000"
}

function ObjMus030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M88.9 48.55q0-3.75 3.4-6.45 3.4-2.65 8.25-2.65 4.8 0 8.15 2.6l.8-1.1q.35-.9.7-1.75 2.45-6.05 2.3-11.7-20.1-4.3-38.3 5.9 9.35-6.35 3.75-9.7 16.85 2.45 32.4-7.1-1.95-5.4-6.55-10.4-.7-.75-1.4-1.45-11.95-8.75-25-1.3-2.35 1.2-4.6 1.1-1.6-.15-3.15-.85l-.85.3q3.4 8.2-12.75 8.8-2.9 0-5.15-.35-1.65-.25-2.95-.65l-.05-.05q-3.15-1.65-3.75-2.4-.05 0-.05-.05-1.05-1.25-1.3-2.95l1.6-.6V4.8q-2.75.15-6.5.3-1.9.1-4.4-.35-6.25-2.8-13.05-1.8-4.25.6-7.7 2.55Q8 8.15 4.7 13.35q-.35.5-.6 1-1.2 2-2.05 4.1-.8 2.1-1.45 3.7L.9 22q-.65 2.5-.75 7.45L0 29.5q-.05 9.35 3.65 18.15.95 2.25 2.2 4.25 2.85 4.75 7.25 8.4.45.35.9.75 5.1 3.9 10.65 5.05 3.95.8 8.2.2 6.8-1.05 11.65-5.45 6.25-3.25 9.7-.9-2.4-3.6-1.45-6.45v-.05q.95-2.85 5.25-4.9 1.95-1 4.7-1.8 16.45-4.4 15.3 5.8 1.5-2.05 3.65-2.85 2.05-.75 4.7-.3 1.35.25 2.65.45-.1-.65-.1-1.3m90.2-37.95l-.45-2.15-1.75.25-.55-2.8-2.2.35-3.1 3.45h.05q-.9 1.8-1.2 2.55-.4.5-.55.9l-.1.1h.05q-.1.2-.1.4-.05.1-.05.25 0 .2.1.35.15.2 1.05.85.2.15.45.3.65.45 1.65 1.1v.05l4.25 2.65 2.25-.35-.6-2.8 1.85-.25-.45-2.2 2.95-.45-.6-2.95-2.95.4z"
                    id="ObjMus030__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M2.7 3.3l-.4-1.95Q1.5-.1 0 0l.7 3.5-.3-1.05.35 1.75H.8l.55 2.7H1.3l.15.7h.05l2.1 10.85q1.45-.35 1.65-1.9l-.45-2.2h.1L2.8 3.3h-.1z"
                    id="ObjMus030__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M94.55 53.45q-.2.15-.3.35-.55.6-.4 1.3.15.7.9 1.15.6.35 1.4.25.8-.1 1.3-.65.45-.6.35-1.25 2.75.8.6 2.95-3.2 3.2-9.45 3.25l-5.2.95q-3 .75-4.15.95-.15.05-.25.05-3.85.65-6.2 2.7-2.55 2.1-2.05 4.55.5 2.4 3.65 3.7.2.1.35.15h.05q.05.05.15.1.3.05.65.15.3.05.65 0 .8-.1 1.3-.65.45-.65.3-1.35-.1-.75-.75-1.1-.7-.45-1.5-.35-.85.15-1.25.75-.4.4-.4.9-2.35-.65-2.3-2.35.25-3.35 7.8-4.9l11-1.6q5.65-.8 8.45-4.7 2.75-3.9-2.6-5.55-.55-.25-1.1-.15l-.15.05q-.5.05-.85.35m53.05-52.2l-.05-.1Q147-.05 146.2 0q-16.25 2.2-31.3 6L44.8 32.15q-.1-.05-.25-.05-.6 0-2.3.4-1.45.4-1.85.55-.5.15-1.5.9-.6.4-.9.7L12.9 44Q6.25 47.45 0 51.35q2.25 1.45 2.55 1.85 5.35-3.4 11.35-6.45l22.8-8.45.1.1h2.3q2.05-.25 5-1.55 3.2-1.4 3.4-2.6l16-5.95q-.35.95-.15 2 .3 1.65 1.9 2.8l.4.1q.4.4 1.55.95 2.95 1.15 6.7.65.1-.05.25-.05 1.15-.2 4.25-.3l5.25-.6q5.95-1.75 10.15.4 2.85 1.35.6 2.95-.15-.7-.8-1.1-.7-.4-1.5-.3-.8.1-1.25.7-.5.6-.35 1.3.1.7.85 1.15l.4.2q.45.2 1 .15l.1-.05q.55-.1 1-.45 4.4-3.1.3-6-4.1-2.9-9.75-1.95l-11 1.6q-1.05.1-2 .15-2.2.3-3.05 0-.85-.3-.9-.35-2.45-.6-3.25-2.15-.55-1.25.5-2.25l.9-.35q.2.25.6.45.65.45 1.45.35.8-.1 1.3-.7.4-.6.3-1.3-.05-.1-.05-.15l46.75-17.4q13.9-3.55 28.8-5.65l.85-.3q.05.1.2.45.1.35.35 1l.3.5q.6.75 1.3.5 1.4-.55.05-3.3h.05q-.15-.4-.25-.7z"
                    id="ObjMus030__Symbol_59_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.7 3.7q-.35.5-.6 1-1.2 2-2.05 4.1L9.7 5.95Q7.55 4.6 4.7 3.7m25.5 14q-3.7 2.7-9.3 4.2l-6.75 1q1.7-4.95 1.2-8.75-1.1.05-2.2.25.95-.85 1.85-1.85-.75-2.55-2.65-4.45L.9 12.35Q.2 15.1.05 18.1q1 .3 2.05.4-1.05.6-2.1 1.35Q-.05 29.2 3.65 38q.95 2.25 2.2 4.25-1.85-10 1.4-10.45l15.15-2.25q5.85-.2 10.4 1.3 1.35.75 2.8.6.25-.05.55-.05.05-.05.2-.05l2.9-.45-1.65-9.95h.05l-.55-2.75h.05l-.5-2.5-3.25.45q-.15.05-.25.05-.25.05-.55.1-1.4.25-2.4 1.4M172.4 6.85l-.2-1.15-.05-.05L172 5l-.05-.55-.25-1.25h-.05l-.35-1.8h.05L171.1.05h-.05l-2.75.3q-.15-.35-.8-.35-1 0-1.1.6l-7.1.8v.05h-.05q-.25-.2-.85-.2-.95 0-1.05.45h-.25v-.05l-46.75 5.3L66.9 11.2 70 26.35l42.5-8.5 41.85-7.7h.15q.2.15.65.15.55 0 .95-.3.15-.1.25-.2l6.95-1.3v-.05h.1q.2.15.75.15 1 0 1.25-.5l7-1.25z"
                    id="ObjMus030__Symbol_40_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.302}
                    d="M221.3 275l-.4-1.95q-.8-1.45-2.3-1.35l.7 3.5-.3-1.05.3 1.75h.1l.55 2.7h-.1l.15.7h.1l.6 3.45h.05l.6 2.95h-.05l.15.7h.05l.7 3.75q1.45-.35 1.65-1.9l-.45-2.2.05-.05-2.05-11h-.1z"
                    id="ObjMus030__Layer4_1_FILL"
                />
                <path
                    fill="#D9D9D9"
                    d="M207.5 287.3q-.35-.35-.9-.35t-.95.35q-.35.4-.35.95 0 .55.35.9.4.4.95.4.55 0 .9-.4.4-.35.4-.9t-.4-.95m-1.45-4q-.55 0-.95.35-.35.4-.35.95 0 .55.35.9.4.4.95.4.55 0 .9-.4.4-.35.4-.9t-.4-.95q-.35-.35-.9-.35m.2-3.15q-.35-.35-.9-.35t-.95.35q-.35.4-.35.95 0 .55.35.9.4.4.95.4.55 0 .9-.4.4-.35.4-.9t-.4-.95m-.6-3.55q-.35-.35-.9-.35t-.95.35q-.35.4-.35.95 0 .55.35.9.4.4.95.4.55 0 .9-.4.4-.35.4-.9t-.4-.95z"
                    id="ObjMus030__Layer4_2_FILL"
                />
                <path
                    fill="#D9D9D9"
                    d="M342.85 265.55l-.15-.85L208 287.55q-.167.034-1.4.4-1.226.375-1 .5.22.172 1.3.1 1.09-.07 1.25-.1l134.7-22.9m-.3-1.6l-.2-.9-135.9 21.05q-.162.027-.3.15-.082.184-.05.35.027.212.15.3.184.132.35.1l135.95-21.05m-.3-1.6l-.2-.9-136.3 19.15q-.185.026-.3.15-.12.17-.1.35.026.186.15.3.17.119.35.1l136.4-19.15m-.3-1.45l-.15-.85-136.35 16.9q-.185.026-.3.15-.119.169-.1.35.025.185.15.3.17.119.35.1l136.4-16.95z"
                    id="ObjMus030__Layer4_3_FILL"
                />
                <g id="ObjMus030__Layer4_0_FILL">
                    <path
                        fill="#933006"
                        d="M213.1 253.1l.45-.15q.05-.25.1-.45-.25.35-.55.6z"
                    />
                    <path
                        fill="#AFAFAF"
                        d="M139.3 289.1q.45.75.35 1.7l141.85-52.65-.2-.55q-.25-.65-.35-1L139.3 289.1z"
                    />
                    <path
                        fill="#555"
                        d="M326.8 269.2q-.95.15-1.85.35l.65 3.05-2.35.3.45 2.3 6.8-1.05-.45-2.25-2.35.35-.6-3.1-.3.05m7.1-1.35v.05l.6 3-2.3.35.45 2.25 6.8-1-.45-2.25-2.35.3-.6-3.05-2.15.35m-9.05-9.45l2.3-.35.55 3v.05l2.2-.25v-.05l-.6-3.05 2.35-.35-.45-2.3-6.8 1.05.45 2.25m15.9-2.05l-.4-2.25-6.8.95.45 2.3 2.25-.35.6 3 2.15-.25-.6-3.1 2.35-.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus030__Symbol_114_0_Layer0_0_FILL"
                transform="translate(170.6 249.75)"
                id="ObjMus030__col1n"
            />
            <use
                xlinkHref="#ObjMus030__Symbol_71_0_Layer0_0_FILL"
                transform="translate(218.6 271.7)"
                id="ObjMus030__col1l"
            />
            <use
                xlinkHref="#ObjMus030__Symbol_59_0_Layer0_0_FILL"
                transform="translate(135.15 233.35)"
                id="ObjMus030__col1s"
            />
            <use
                xlinkHref="#ObjMus030__Symbol_40_0_Layer0_0_FILL"
                transform="translate(170.6 259.4)"
                id="ObjMus030__col2n"
            />
            <use xlinkHref="#ObjMus030__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus030__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus030__Layer4_2_FILL" />
            <use xlinkHref="#ObjMus030__Layer4_3_FILL" />
        </g></g>
    )
}

export default ObjMus030
