import { AssetSvgProps } from "../../../shared/assets"

function ShiMot041({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0000A4"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot041__Layer5_0_FILL"
                />
                <path
                    fill="#FECB16"
                    d="M204.95 282.8l-.45-1.45-.45 1.45h-1.55l1.25.9-.45 1.45 1.2-.9 1.2.9-.45-1.45 1.25-.9h-1.55m1.4-8.3l-.45 1.45h-1.55l1.25.85-.5 1.45 1.25-.9 1.25.95-.5-1.5 1.25-.85h-1.55l-.45-1.45m-3.2-3.35l1.2-.95 1.3.95-.5-1.5 1.25-.85h-1.55l-.5-1.45-.45 1.45h-1.5l1.2.85-.45 1.5m-4.6 17.75l-.45 1.5 1.2-.9 1.25.9-.5-1.5 1.25-.9h-1.55l-.45-1.4-.45 1.4h-1.55l1.25.9m-15.25-.85l1.2.9-.45 1.5 1.25-.95 1.2.95-.45-1.5 1.2-.9h-1.5l-.45-1.45-.5 1.45h-1.5m8.65 1.9h-1.5l1.2.9-.45 1.45 1.2-.9 1.25.9-.45-1.45 1.2-.9h-1.5l-.5-1.45-.45 1.45m-9.9-7.15h-1.5l-.45-1.45-.5 1.45h-1.55l1.3.85-.5 1.5 1.25-.9 1.2.9-.45-1.5 1.2-.85m-3.35-6.85l-.45-1.45-.5 1.45h-1.5l1.2.9-.45 1.45 1.25-.9 1.2.9-.45-1.45 1.2-.9h-1.5m3.25-7.05h-1.5l-.5-1.45-.45 1.45H178l1.2.9-.45 1.45 1.2-.9 1.25.9-.5-1.45 1.25-.9m17.45-6.6l-.45 1.4h-1.55l1.25.9-.5 1.5 1.25-.95 1.2.95-.45-1.5 1.2-.9h-1.5l-.45-1.4m-16.25 1.4l1.25.9-.45 1.45 1.2-.9 1.25.9-.5-1.45 1.3-.95-1.55.05-.5-1.45-.45 1.45h-1.55m8.7-1.9h-1.55l1.25.9-.5 1.45 1.25-.9 1.2.9-.45-1.45 1.25-.9h-1.55l-.45-1.45-.45 1.45z"
                    id="ShiMot041__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot041__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot041__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot041
