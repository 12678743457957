import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M203.5 228.5q2.85-2.7 5.45-7.7-4.193-2.194-8.95-3.45-1.155-.303-2.3-.5h-.05q-2.524-.49-4.95-.5h-.35q-2.403.01-4.95.5h-.05q-1.15.2-2.3.5-4.75 1.25-8.95 3.45 2.6 5 5.45 7.7 1.7 1.6 3.65 2.6 1.225.596 2.45 1 5.087 1.598 9.75 0 1.228-.402 2.45-1 1.955-.99 3.65-2.6z"
                    id="FacMou028__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M5.75 5.95V.75Q3.5 1.2 0 2.75q.1 1 .6 1.55.55.55 2 1.05 1.15.35 3.15.6m6.4.25V0Q11.1 0 9.4.15q-.15 0-.3.05-.5.05-.65.1-.1 0-1.9.3v5.45h.35q.55.05 1.2.1 2 .05 4.05.05M16.6.3q-.15-.05-.65-.1-.15-.05-.3-.05Q13.95 0 12.9 0v6.2q2.05 0 4.05-.05.65-.05 1.2-.1h.35V.6Q16.7.3 16.6.3m5.85 5.05q1.45-.5 2-1.05.5-.55.6-1.55-3.5-1.55-5.75-2v5.2q2-.25 3.15-.6z"
                    id="FacMou028__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou028__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou028__Symbol_53_0_Layer0_0_FILL"
                transform="translate(180 217.65)"
                id="FacMou028__col1n"
            />
        </g></g>
    )
}

export default FacMou028
