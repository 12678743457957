import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#EEEEEE",
    "col2n": "#000000"
}

function SkiMak010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M113.4 28.8q-.35-11.7-8.3-20.1Q96.75 0 84.95 0q-11.8 0-20.1 8.7-7.05 7.4-8.15 17.35-1.1-9.95-8.15-17.35Q40.25 0 28.45 0T8.3 8.7Q.35 17.1 0 28.8v35.25q.7 21.05 23.2 22.9 10.55.5 22.2-4.25 9.9-5.1 11.3-12.85Q58.1 77.6 68 82.7q11.65 4.75 22.2 4.25 22.5-1.85 23.2-22.9v-35-.25z"
                    id="SkiMak010__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M51.7 13.6q-.7-1.25-1.5-2.35Q45.5 4.8 38.1 2q-.85-.3-1.7-.55-8.85-2.9-17.3 0Q12.2 3.55 7.15 10q-.95 1.05-1.75 2.25Q3.9 14.3 2.8 17q-.15.25-1.2 2.95-1.1 2.7-1.6 9.5v8.1q.25-11.3 4.15-19.5 1.25-2.75 2.95-5.1 2.55-3.1 5.9-5.2 3-1.95 6.6-3.1 9.35-2.35 17.5 0 7.1 2.35 11.8 7.3 1 1.05 1.9 2.2 4.05 5.25 5.9 12.9-1.2-7.9-5-13.45m54.7-.65q1.7 2.35 2.95 5.1 3.9 8.2 4.15 19.5v-8.1q-.5-6.8-1.6-9.5-1.05-2.7-1.2-2.95-1.1-2.7-2.6-4.75-.8-1.2-1.75-2.25-5.05-6.45-11.95-8.55-8.45-2.9-17.3 0-.85.25-1.7.55-7.4 2.8-12.1 9.25-.8 1.1-1.5 2.35-3.8 5.55-5 13.45 1.85-7.65 5.9-12.9.9-1.15 1.9-2.2Q69.3 7 76.4 4.65q8.15-2.35 17.5 0 3.6 1.15 6.6 3.1 3.35 2.1 5.9 5.2z"
                    id="SkiMak010__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak010__Symbol_16_0_Layer0_0_FILL"
                transform="translate(135.8 116)"
                id="SkiMak010__col1n"
            />
            <use
                xlinkHref="#SkiMak010__Symbol_11_0_Layer0_0_FILL"
                transform="translate(135.8 114.45)"
                id="SkiMak010__col2n"
            />
        </g></g>
    )
}

export default SkiMak010
