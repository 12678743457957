import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#1082E7",
    "col1l": "#77BFFF",
    "col1n": "#43A6FF"
}

function ObjMor094({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.8}
                    d="M867.2 325.6l-6 6.45 12.15 26.05 23.45-16.5-1.85-8.5-5.9-4.15-14.7-3.95-7.15.6z"
                    id="ObjMor094__Symbol_231_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M5.55 4.9l-4.8 7.6 20.3 5.45-.55-9.05 7.25-.8-5.9-4.15L7.15 0 0 .6l5.55 4.3z"
                    id="ObjMor094__Symbol_233_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M273.25 292.55l-.55-9.05-14.95-4-4.8 7.6 20.3 5.45z"
                    id="ObjMor094__Symbol_233_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.8}
                    d="M12.15 26.05L35.6 9.55l-8.55 1.35L12.15 26 6.75 5.45 0 0l12.15 26.05z"
                    id="ObjMor094__Symbol_232_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor094__Symbol_231_0_Layer0_0_FILL"
                transform="translate(-615 -50.4)"
                id="ObjMor094__col1n"
            />
            <g id="ObjMor094__col1l">
                <use
                    xlinkHref="#ObjMor094__Symbol_233_0_Layer0_0_FILL"
                    transform="translate(252.2 274.6)"
                />
                <use xlinkHref="#ObjMor094__Symbol_233_0_Layer0_1_FILL" />
            </g>
            <use
                xlinkHref="#ObjMor094__Symbol_232_0_Layer0_0_FILL"
                transform="translate(246.2 281.65)"
                id="ObjMor094__col1d"
            />
        </g></g>
    )
}

export default ObjMor094
