import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col2n": "#FFFFFF"
}

function HaiSpe035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M262.65 76q-.15-.9-.3-1.75-.1-.35-.15-.65-2.75-14.95-10.9-27.85-3.75-5.95-8.65-11.45-3.7-4.2-8-8.1-4.8-4.35-9.9-7.95-6.85-4.85-14.25-8.4-15.25-7.3-33-9.25L166.6 0h-2.35l-2.4.05q-3.6.1-7.2.4-4.7.4-9.55 1.15Q116.65 6.45 95 26.2q-9.5 8.65-15.9 18.45-12.75 19.7-12.95 44.1v.85q-.1 2.2-1.95 2.55-1.15.1-2.35-.8l-.1-.1Q37.85 71.75 0 113.95q43.3 16.8 62.85-5.85.1.15.25.3v-.05q-.1-.1-.2-.25 1.65-1.35 2.95-1.35.2 0 .35.05 1.3.2 2.1 2.3.05.05.05.15.05.05.05.1l.1.2v.1h-.05q.4 1.85.95 3.65 1.35 4.95 2.9 7.5-3.35-35.65 6.05-39 19.9-9.05 24.25-20.45 3.3-12.3 29.85-16.7 15.15-1.85 22.2-8.1 3.75-3.25 5.2-7.75 14 9.9 33.25 9.75 11.75.65 17.8 3.3 3.65 1.6 5.2 3.9.6 1 .9 2.1 2.7 7.15 8.15 13.7 7.6 6.75 17.5 11.95.65.3 1.3.65l1.1.75q.8.5 1.55 1.1 1.4 1.15 2.7 2.1 6.25 4.65 6 11.7-.7 15.5 3.3 29.05.05-.2.15-.35l.05-.15q.05-.25.15-.5 1.7-4.75 2.8-9.75.095-.047.2-.05h-.2q.45-1.45 1.6-1.55 1.1-.15 1.8.95.05-.05.1-.05l.5.4q7.1 5.05 15.4 7.1 19.8 4.8 46.6-7.75-25.7-25.9-46.6-26.9-8.05-.45-15.35 2.85-2.2.7-2.4-.45-.05-.85-.15-1.65-.25-2.55-.6-5z"
                    id="HaiSpe035__Symbol_167_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.4 10.25q0 .05-.05.1Q0 12.45 0 16.75q0 7.35 2.35 10.3.1.15.25.3.75.85 1.85 1.25h3.5v.05H8q-.05-.2-.15-.4-2.2-9.45-2.2-19.65v-.55-.3q-2.4.7-3 1.15-.3.2-.55.45-.4.35-.7.9M204.65 1.4q-.9-.9-1.9-1.4.4 4.2.4 8.6 0 7.85-1.3 15.25-.15.95-.35 1.85-.15.65-.25 1.35.1-.05.25-.05h.2l-.45.05q1.15-.1 1.5-.15.8-.15 1.5-.35l.4-.1v-.05q.05-.05.1-.05l.5-.2q1.45-.75 2.2-2 1.4-2.1 1.4-8.55 0-6.25-1.9-10.55-.75-1.75-1.65-2.95l-.05-.05q-.3-.35-.6-.65z"
                    id="HaiSpe035__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe035__Symbol_167_0_Layer0_0_FILL"
                transform="translate(27.1 57)"
                id="HaiSpe035__col1n"
            />
            <use
                xlinkHref="#HaiSpe035__Symbol_27_0_Layer0_0_FILL"
                transform="translate(87.6 138)"
                id="HaiSpe035__col2n"
            />
        </g></g>
    )
}

export default HaiSpe035
