import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#7973B7",
    "col1l": "#C6C3E0",
    "col1n": "#A19CCD",
    "col2n": "#FFC4C4"
}

function AccHat047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M269.1 85.2q-1.45-2.45-8.05-3.9-.65-.15-1.35-.25-4.8-.85-9.5-1.95-3.95-.85-8.1-2.05-2.9-.9-5.85-2-.45-.15-.85-.3-2-.7-4.05-1.45-1.35-.5-2.75-1.05-11.05-4.45-21.4-10.6l-1.4-.8q-5.15-3.2-10.2-6.8-1.35-.95-2.65-1.95-5.85-4.4-11.4-9.35-4.15-3.95-7.55-7.95-5.55-6.45-9.05-12.9-1.8-3.4-3.1-6.8-.95-2.5-2.35-4.5-.55-.85-1.2-1.6-2.95-3.65-7.6-5.7-.9-.45-1.9-.8-7.25-2.45-14-2.5-6.75.05-14 2.5-5.95 2.15-9.5 6.5-.65.75-1.2 1.6-1.4 2-2.35 4.5-1.3 3.4-3.1 6.8-3.5 6.45-9.05 12.9-3.4 4-7.55 7.95-5.55 4.95-11.4 9.35-1.3 1-2.65 1.95-5.05 3.6-10.2 6.8l-1.4.8Q52.05 67.8 41 72.25q-1.4.55-2.75 1.05-2.05.75-4.05 1.45l-.8.3q-3 1.1-5.9 2-4.15 1.2-8.1 2.05-4.7 1.1-9.5 1.95-.7.1-1.35.25-6.6 1.45-8.05 3.9-.3.5-.4 1.05-2.25 8 40 16.05 6.1.95 12.55 1.75 48.75 7 98.05 5.65 33.05-.95 66.35-5.65 6.4-.8 12.45-1.75 42.25-8.05 40-16.05-.1-.55-.4-1.05z"
                    id="AccHat047__Symbol_265_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M134.35 6.1q-.95-2.5-2.35-4.5-.55-.85-1.2-1.6-3 .95-7.6 1.55-6.05.8-14.85 1.1h-2.1Q90.7 2.15 83.8 0q-.65.75-1.2 1.6-1.4 2-2.35 4.5-1.3 3.4-3.1 6.8 13.7 2.95 27.4 3.2h5.5q6.55-.1 13.15-.85 7.1-.8 14.25-2.35-1.8-3.4-3.1-6.8m80.25 61.95q-2.9-.9-5.85-2-.45-.15-.85-.3-2-.7-4.05-1.45-1.35-.5-2.75-1.05-20.75 6-58.05 9.65-9.95.75-19.85 1.1-6.6.25-13.15.3h-5.5q-16.45-.15-33-1.4-37.3-3.65-58.05-9.65-1.4.55-2.75 1.05-2.05.75-4.05 1.45l-.8.3q-3 1.1-5.9 2 49.55 14 104.55 14 1.4-.05 2.75-.05t2.75.05q6.6 0 13.15-.2 47.8-1.5 91.4-13.8z"
                    id="AccHat047__Symbol_215_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M148.8 2.5q-7.25-2.45-14-2.5-6.75.05-14 2.5-5.95 2.15-9.5 6.5 6.9 2.15 22.45 2.65h2.1q8.8-.3 14.85-1.1 4.6-.6 7.6-1.55-2.95-3.65-7.6-5.7-.9-.45-1.9-.8m46.8 51.55q-1.35-.95-2.65-1.95-14.4 3.2-42.25 4.75-1.05.05-2.15.1-5.5.15-11 .2-1.4 0-2.75.05-1.35-.05-2.75-.05-5.5-.05-11-.2-29.45-1.55-44.4-4.85-1.3 1-2.65 1.95-5.05 3.6-10.2 6.8 15.45 3.05 30.9 4.75l18.55 1.45h.55q9.15.5 18.25.6 1.4.05 2.75.05t2.75-.05q6.55-.05 13.15-.35 2.55-.1 5.1-.25h.55l18.55-1.45q15.45-1.7 30.9-4.75-5.15-3.2-10.2-6.8m73.9 32.2q-.1-.55-.4-1.05-4 6.7-39.6 13.5-6.05.95-12.45 1.75-33.3 4.7-66.35 5.65-49.3 1.35-98.05-5.65-6.45-.8-12.55-1.75Q4.5 91.9.5 85.2q-.3.5-.4 1.05-2.25 8 40 16.05 6.1.95 12.55 1.75 48.75 7 98.05 5.65 33.05-.95 66.35-5.65 6.4-.8 12.45-1.75 42.25-8.05 40-16.05z"
                    id="AccHat047__Symbol_173_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M133 12.9q-5.55-6.45-9.05-12.9-7.15 1.55-14.25 2.35-6.6.75-13.15.85h-5.5q-13.7-.25-27.4-3.2-3.5 6.45-9.05 12.9 19.75 3.3 30.75 3.7 4.4.15 8.45.15 4.05 0 8.45-.15 3.35-.1 7.45-.5 9.5-.9 23.3-3.2m33.2 26.85l-1.4-.8Q149.35 42 133.9 43.7l-18.55 1.45h-.55q-2.55.15-5.1.25-6.6.3-13.15.35-1.4.05-2.75.05t-2.75-.05q-9.1-.1-18.25-.6h-.55L53.7 43.7Q38.25 42 22.8 38.95l-1.4.8Q11.05 45.9 0 50.35q20.75 6 58.05 9.65 16.55 1.25 33 1.4h5.5q6.55-.05 13.15-.3 9.9-.35 19.85-1.1 37.3-3.65 58.05-9.65-11.05-4.45-21.4-10.6z"
                    id="AccHat047__Symbol_87_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat047__Symbol_265_0_Layer0_0_FILL"
                transform="translate(57.7 19.75)"
                id="AccHat047__col1n"
            />
            <use
                xlinkHref="#AccHat047__Symbol_215_0_Layer0_0_FILL"
                transform="translate(85.2 28.75)"
                id="AccHat047__col1l"
            />
            <use
                xlinkHref="#AccHat047__Symbol_173_0_Layer0_0_FILL"
                transform="translate(57.7 19.75)"
                id="AccHat047__col1d"
            />
            <use
                xlinkHref="#AccHat047__Symbol_87_0_Layer0_0_FILL"
                transform="translate(98.7 41.65)"
                id="AccHat047__col2n"
            />
        </g></g>
    )
}

export default AccHat047
