import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E4B670",
    "col1s": "#957032",
    "col2n": "#000000"
}
function FacMou075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#7E0101"
                    d="M160.7 212.35h.05q.2 1.05 1.45 2.15.6.9 1.3 1.75 1.3 2.3 2.9 4.5 3.95 5.65 5.8 5.65.95 0 1.2-.65l.8.5q32.59 1.107 35.55-.35.16.104.3.2.15.05.3.15.15-.1.35-.2l.3-.2q.25.55 1 .55.5 0 3.35-2.7 2.8-2.65 3.9-4.1 4.9-6.3 4.9-7v-.1q.2-.25.2-.4-.1-1.2-1.25-1.2-1.95 0-4.9 2.45-3.85 3.2-6.65 4.45h-4.9q-3.1-.15-6.7-1.9-6.6-3.15-6.75-3.2h-4q-1.6.4-6.7 2.8-4 1.9-7.6 1.9-.45 0-3.45-1.05-2.7-.95-4.2-1.55-.6-.2-2.9-2.25-2.15-1.95-2.3-1.95-1 0-1.25.7h-.1q-.2 0-.35.05-.25.1-.25.4.1.6.6.6m30.85 8.3q.35 0 .7.05.35-.05.7-.05l.1.25-1.6-.25h.1z"
                    id="FacMou075__Layer9_0_FILL"
                />
                <path
                    fill="#FF5D5D"
                    d="M210.35 226.25q-.15-.1-.3-.15-1.75-1.2-3.5-2.15-1.5-.75-3.05-1.35-4.85-1.85-10.35-1.95h-.2q-.35 0-.7.05-.35-.05-.7-.05h-.2q-5.5.1-10.35 1.95-3.55 1.35-6.8 3.65l.5.3q-.05.15-.05.4 0 2.2 8.05 3.55 3.2.55 12.5 1.4h4.2q2.45-.6 3.75-.75.7-.05 1.55-.45.6-.5 1.25-1 1.35-.95 2-.95.1 0 .9.45.95 0 1.5-.85.45-.65.45-1.1 0-.7-.45-1z"
                    id="FacMou075__Layer9_1_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M31.2 5.9q0-2.65-3.05-3.9Q16.1-2 3.1 2q-.85.4-1.5.85Q0 4 0 5.9q.5 3.05 5.3 6.95 2.55 2.15 4.8 3.4.8.45 1.6.8 3.55 1.7 7.9 0 .7-.3 1.55-.8.8-.45 1.75-1.05 1.45-1 3.1-2.35.25-.15.45-.3 4.3-3.75 4.75-6.65z"
                    id="FacMou075__Symbol_176_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M64.4 24.6l.2-.35q-.15-.15-.3-.25l-.05-.05q-.3.35-.35.4-3.5 5.85-8.85 10.55-1 .85-2 1.65-1.25 1.1-2.35 1.8l-.4.2q-2.1 1.2-4.25 2.1-2.2.9-4.5 1.4-8.9 1.95-18.55 0-4.6-1.05-8.8-3.5-1.25-.7-2.75-2-1.05-.8-1.95-1.65Q4.1 30.2.6 24.35l-.2-.45H.35q-.15.1-.25.2l-.1.1 1.35 2.7q.35.6.85 1.25 1.8 2.5 3.05 6.75.4 1.3.75 2.75Q8.05 48.2 16.65 52.9q10.15 5.3 20 3.85 2.35-.35 4.7-1.05 3.25-1 6.55-2.8 8.5-4.7 10.6-15.25.35-1.45.75-2.75 1.25-4.25 3.05-6.75.45-.6.8-1.1 1-1.6 1.3-2.45m-3.55-2.7q-.052-.002-.1-.05-2.4-.7-2.9-4.1-.25-.95-.5-1.85-3.1-9.9-11.5-13.5-6.7-2.55-13.6-2.4-6.85-.15-13.5 2.4h-.1q-1.15.5-2.2 1.1-6.65 3.85-9.3 12.4-.3.9-.5 1.85-.5 3.35-2.8 4.1H3.8q-.05 0-.1.05-.05 0-.1.05l-.1.05q-1.7.9-2.9 1.75-.05 0-.1.05 1.3 1.3 2.15 2l.9.8q1.25.95 2.15 1.5 5.6 3.1 10.3 3.1 4.7 0 8.75-2.4 1.4-.85 2.6-1.4 5.5-2.3 9.85 0 1.15.55 2.55 1.4 3.05 1.8 6.55 2.25 1.05.15 2.2.15 1.1 0 2.3-.15 3.75-.6 8-2.95 1.8-1.05 5-3.95l.45-.2q-.15-.05-.3-.15-.3-.25-.65-.45-.95-.6-2.1-1.25l-.4-.2h.05m-19.8-9.2l-.4.3q-1.3 1.15-2.5 1.95-.75.5-1.4.9-.65.4-1.25.65-3.5 1.4-6.35 0-.65-.3-1.3-.65-1.8-1.05-3.9-2.85-3.85-3.3-4.25-5.85 0-1.6 1.3-2.6.6-.45 1.5-.8.65-.25 1.3-.45 2.05-.65 3.9-.75 2.45-.1 3.7-.05.05-.05.1 0 .05-.05.1 0H33q.05-.05.1 0 .05-.05.1 0 1.25-.05 3.7.05 2.4.1 5.2 1.2 2.8 1.05 2.8 3.4-.4 2.4-3.85 5.55z"
                    id="FacMou075__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M6.25.05L6.3 0Q3.45-.1 2 1.15.5 2.4 0 4.45q1.2-1.2 2.45-2.05l.1-.1q.1-.1.25-.2h.05l.1-.1-.05-.2.15.15Q4.25 1.1 5.95.2l.1-.05Q6.1.1 6.15.1q.05-.05.1-.05m60.45 2.1l.05.05q.15.1.3.25 0 .05.05.05.95.85 2.55 2.2-.55-1.95-1.85-3.25Q66.45.15 63.3.1h-.05l.4.2q1.15.65 2.1 1.25.35.2.65.45.15.1.3.15z"
                    id="FacMou075__Symbol_9_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.902}
                    d="M188.35 194.3q-2.7.05-3.4.15-.5.15-.5.5 0 .8 2.25 1.35 5.5 1.45 11.65 0 2.25-.55 2.25-1.35 0-.35-.55-.5-.7-.1-3.4-.15h-8.3z"
                    id="FacMou075__Layer1_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou075__Layer9_0_FILL" />
            <use xlinkHref="#FacMou075__Layer9_1_FILL" />
            <use
                xlinkHref="#FacMou075__Symbol_176_0_Layer0_0_FILL"
                transform="translate(176.9 189.35)"
                id="FacMou075__col2n"
            />
            <use
                xlinkHref="#FacMou075__Symbol_100_0_Layer0_0_FILL"
                transform="translate(160.05 187.65)"
                id="FacMou075__col1n"
            />
            <use
                xlinkHref="#FacMou075__Symbol_9_0_Layer0_0_FILL"
                transform="translate(157.6 209.45)"
                id="FacMou075__col1s"
            />
            <use
                xlinkHref="#FacMou075__Layer1_0_FILL"
                transform="matrix(.95357 0 0 .95357 8.9 9)"
            />
        </g></g>
    )
}
export default FacMou075
