import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g
                    id="FacMou010__Symbol_35_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path
                        fillOpacity={0.4}
                        d="M22.5 11.4q.45-.2 2.05-1.15-4.6.85-7.15.35-2.6-.55-4.6-.9-2-.35-1.55.2 1.25.95 4.25 1.7 4.05 1.15 7-.2z"
                    />
                    <path d="M32.55 2.6q2.3-.6 4.9-1.75-.35 0-2.9.3-2.6.25-4.8.25-2.2-.1-6.2-.95-4.05-.95-8.3.15-4.3 1.15-5.25 1.5-.95.35-3.9.8-2.95.35-6.1-.35 0 .3.25.65.45 1 2.25 1.55 2.65.65 4.95 0 2-.55 6.1-1.35.35-.15 1-.2.15-.05.35-.05.25-.05.55-.05Q17.1 2.9 17 3q3.3-.25 6.35-.05 3 .15 3.05.15.65.1 1.4.1h.65q.45 0 .9-.05 1.55-.15 3.2-.55z" />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou010__Symbol_35_0_Layer0_0_FILL"
                transform="translate(173.7 219.4)"
                id="FacMou010__col1n"
            />
        </g></g>
    )
}

export default FacMou010
