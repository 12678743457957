import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0l5.3 44.55q7.45 6 15.45 0L26.8.15Q13.7 2.05 0 0z"
                    id="BeaChi039__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M16.4 9.8q-.75 1.75-1.8 2.35-.6.2-1.15.35-.2.05-.45.1-3.3.8-6.4.2-.95-.2-1.85-.5-1.35-.85-2.15-3L0 .45 1.85 16.2q7.45 6 15.45 0L19.45 0 16.4 9.8z"
                    id="BeaChi039__Symbol_37_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi039__Symbol_78_0_Layer0_0_FILL"
                transform="translate(179.1 244.9)"
                id="BeaChi039__col1n"
            />
            <use
                xlinkHref="#BeaChi039__Symbol_37_0_Layer0_0_FILL"
                transform="translate(182.55 273.25)"
                id="BeaChi039__col1d"
            />
        </g></g>
    )
}

export default BeaChi039
