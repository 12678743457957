import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00FF00"
}

function ShiSpe078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M124.5 3.2q-1.05-1.6-2.2-3.1l.05-.05h-.05l-.9.7q-16.45 24.7-32.95 48.3-4.5 6.5-9.05 12.9-16.5 21.8-33.95 0-4.8-6.45-9.45-12.9Q18.7 25 3.6.9L2.4 0h-.05l.05.05h-.05Q1.15 1.7 0 3.45l53 83.8q9.95 13.75 18.9 0 11.5-19.2 23.35-38.2Q109.6 26 124.5 3.2z"
                    id="ShiSpe078__Symbol_219_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe078__Symbol_219_0_Layer0_0_FILL"
                transform="translate(130.25 228.55)"
                id="ShiSpe078__col1n"
            />
        </g></g>
    )
}

export default ShiSpe078
