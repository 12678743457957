import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiSho013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M9.5 76.85q-.15.1-.2.3L0 114.6q.65.75 1.25 0L9.9 79.65l9.5 34.95q.85.75 1.25 0l-10.2-37.45q-.05-.2-.2-.3-.15-.15-.35-.15-.2 0-.4.15M150.6 47.2q.15-.2.1-.45-.45-2.35-1.1-4.55-3.7-12.25-14.05-21.2-7.4-6.4-18.3-9.1-7.25-1.8-16.05-2Q79.15 9.4 66.6 20q-1.7 1.45-3.3 3-8.15 8.05-12.55 19.2-.95 2.45-1.75 5-.1.2.05.45.1.2.35.3.2.05.45-.05.2-.15.3-.35.85-2.75 1.9-5.35 4-10.1 11.25-17.55 1.9-1.95 4.05-3.75 12.3-10.3 33.85-9.8 8.85.2 16.05 2.05 10.4 2.65 17.5 8.75 10 8.6 13.6 20.3.7 2.3 1.15 4.75.05.25.3.4.2.15.45.1.2-.05.35-.25m3.5-13.05q.2.1.45.05.25-.1.35-.35.1-.2.05-.45-4-11-14.65-19.7-10.25-8.35-23.05-11.6Q109.05 0 99.8 0 78.85 0 63.3 10.7q-2.05 1.4-4 3-10.7 8.7-14.55 19.7-.1.25 0 .45.1.25.35.35.25.05.45-.05.25-.1.35-.35 3.7-10.7 14.15-19.15 1.6-1.3 3.25-2.45 15.45-11 36.5-11 9.25 0 17.45 2.15 12.4 3.2 22.3 11.3 10.4 8.45 14.25 19.15.05.25.3.35m36.2 43q-.05-.2-.2-.3-.2-.15-.4-.15t-.35.15q-.15.1-.2.3l-10.2 37.45q.45.7 1.25 0l9.5-34.95 8.65 34.95q.65.65 1.25 0l-9.3-37.45z"
                    id="HaiSho013__Symbol_134_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho013__Symbol_134_0_Layer0_0_FILL"
                transform="translate(92.7 42.05)"
                id="HaiSho013__col1n"
            />
        </g></g>
    )
}

export default HaiSho013
