import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF8E8E"
}

function ShiMot023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.8 8.05q-.563-.215-1.15-.4-1.85-.6-3.8-.6-1.9 0-3.75.6-.49.15-.95.35l-.65.25q-.881.45-1.7 1.05l-.05-.05q-1.479 1.008-2.6 2.45l.05.05q-.468.6-.85 1.25l-.25.4q-.5.9-.85 1.85-.7 2-.7 4.1 0 2.3.8 4.4.75 2 2.2 3.65 1.4 1.65 3.3 2.7 1.95 1.1 4.15 1.45v4.3h-4.85v3.8H17v4.4h3.75v-4.4h4.85v-3.8h-4.85v-4.3q2.2-.35 4.1-1.45 1.9-1.05 3.3-2.7 1.45-1.65 2.2-3.65.85-2.15.85-4.4 0-2.1-.75-4.05-.06-.177-.15-.35-2.287-4.768-6.5-6.9M27 15.9q.65 1.6.65 3.4 0 1.8-.65 3.45-.7 1.6-1.9 2.8-1.25 1.2-2.8 1.9-1.65.65-3.45.65-1.8 0-3.4-.65-1.6-.7-2.8-1.9-1.25-1.25-1.9-2.8-.7-1.65-.7-3.45 0-1.8.7-3.4.65-1.6 1.9-2.8 1.2-1.25 2.8-1.9 1.6-.7 3.4-.7 1.8 0 3.45.7 1.6.65 2.8 1.9 1.2 1.2 1.9 2.8z"
                    id="ShiMot023__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot023__Symbol_51_0_Layer0_0_FILL"
                transform="translate(173.65 259.2)"
                id="ShiMot023__col1n"
            />
        </g></g>
    )
}

export default ShiMot023
