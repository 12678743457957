import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525",
    "col1s": "#000000",
    "col2n": "#FFFFFF"
}

function ObjToy075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M72.3 15.7q-.4-1.6-1.3-2.95-.3-.45-.55-.8-.75-.95-1.8-1.8-2.7-2.35-7.5-4.15-.6-.2-1.4-.35-2-.35-4.8.3L34.55 2.2Q31.9.45 29.85.1q-.6-.1-1.15-.1-4.7-.05-8.05 1.05-1.5.45-2.75 1.2-1.55.75-3.05 2.8-.35.45-.65.9v.1q-.15.1-.9 1.35-.8 1.2-2.75 4.7l-.1.15h.05l-.1.1h.1q0-.05.05-.1.25.15.55.25 1.1.5 1.75 1.05.9.75 1.55 1.6 1.9 3 1 8.6-2 9.75-7.25 12-2.45 1-5.7 1.05-.85 0-1.8-.05H.6q-.2 0-.5-.15l-.05 1.35q-.3 4.15 2.1 7.1 3.7 4.6 9.05-.4l7.4-6.4q3.5-3.25 8.05-2.05l23.55 4.4q4.65.4 6.7 4.75l4.55 8.55q3.3 6.65 8.45 3.65 4.55-2.6 5.1-9.5 1-6.1.1-15.15-.65-7-2.45-15.7-.05-.2-.05-.35l-.05-.1q-.05-.5-.25-1.05z"
                    id="ObjToy075__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M14.75 20.25q-1.55.15-2.9 1.05-1.4.95-2.1 2.35-.4.8-.55 1.8v.25l-.1.85q-.05.4 0 .85v.1q.05.5.2.95.25 1 .9 1.9 1.45 2.15 4.15 2.65 2.6.5 4.85-1 2.15-1.5 2.65-4.15.1-.5.1-.9 0-.35.05-.65-.05-.1 0-.15-.05-.15-.05-.3-.15-1.5-1.15-2.85-.9-1.35-2.2-2.05-.1-.1-.3-.15-.7-.4-1.55-.55-1-.2-1.85-.05-.1 0-.15.05M4.65 7.45q-2.2 1.5-2.7 4.1-.5 2.65 1.05 4.9 1.5 2.15 4.15 2.65 2.6.5 4.8-1.05 2.2-1.45 2.7-4.1.45-2.6-1-4.9Q12.1 6.9 9.5 6.4q-2.7-.45-4.85 1.05m27.6 8.35q-.6.35-.7 1.15-.2.7.2 1.35.5.6 1.2.7.7.15 1.3-.3.6-.4.75-1.1.1-.8-.25-1.35-.45-.6-1.15-.8-.7-.1-1.35.35m-8.4-1.6q-.4-.6-1.1-.75-.75-.1-1.35.35-.65.35-.75 1.1-.15.75.25 1.4.45.6 1.2.7.7.15 1.25-.3.65-.4.8-1.1.15-.8-.3-1.4M41.5 31.4q.1-.5.1-.9.15-2.1-1.1-3.85-.95-1.35-2.25-2.1-.85-.45-1.9-.7-2.65-.45-4.85 1.1-2.2 1.5-2.65 4.1-.5 2.7 1 4.85 1.45 2.15 4.15 2.65 2.6.5 4.85-1 2.15-1.5 2.65-4.15m1.2-22.45q2.1-1.05 3.75-.45 4.05 1.2 7.35 3.7.3.25.55.45.4.25.75.6.25.15.4.3 1.35 1.35 1.95 3.15-.4-1.6-1.3-2.95-.3-.45-.55-.8-.2-.25-.4-.45l-2.15-2.9-8.2-4.1-4.3.8-20.3-3.7L14.8 0 4.95 1.6l-1.9 1.65Q1.5 4 0 6.05q1.35-1.6 3.4-2.4.45-.1.95-.25.45-.15.95-.35 3.8-1 7.9-.7 1.75 0 3.3 1.8l3.75 5.55q1.25 1.6 3.15 1.9l10.2 1.9q1.85.35 3.65-.65l5.45-3.9z"
                    id="ObjToy075__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.95.25q.9.4 1.55 1Q4.3.9 5.3.85 4.7.2 3.7.05q-.95-.15-1.75.2m-1.5 4.6L.6 5q.75 1 1.95 1.25Q3.9 6.5 5 5.8q.05-.1.1-.15h-.05q-.7-1.95-1.8-3.25-1.5.8-2.8 2.45m.8-4.2Q.3 1.35.05 2.6q-.15 1.05.3 1.95.95-1.95 2.35-2.8-.65-.6-1.45-1.1m4 4.85l.1.05q.8-.8.95-1.8.25-1.3-.5-2.4-.9.15-1.75.65.9 1.25 1.2 3.5z"
                    id="ObjToy075__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill="#585858"
                    d="M177.3 278.65l.05-1.95q-3.05-2.5-7.35-4.15-.55-.25-1.2-.35l-.4 1.95q.8.15 1.4.35 4.8 1.8 7.5 4.15m-33.8-9.4l-.3 1.45 20.4 3.75.25-1.45-20.35-3.75m-5.65-2.6q-4.15-.05-7.75 1.05l-.8 1.85q3.35-1.1 8.05-1.05.55 0 1.15.1l.35-1.95q-.5-.05-1 0z"
                    id="ObjToy075__Layer5_1_FILL"
                />
                <path
                    fill="#333"
                    d="M159.55 293.85q-1.6-.25-3 .65-1.3.85-1.55 2.5-.3 1.55.55 2.9.9 1.35 2.55 1.6 1.55.3 2.85-.55 1.35-.95 1.7-2.55.25-1.6-.65-2.85-.85-1.35-2.45-1.7z"
                    id="ObjToy075__Layer5_2_FILL"
                />
                <path
                    fill="#333"
                    d="M132.65 276.35q-1.6-.25-3 .65-1.3.85-1.55 2.5-.3 1.55.55 2.9.9 1.35 2.55 1.6 1.55.3 2.85-.55 1.35-.95 1.7-2.55.25-1.6-.65-2.85-.85-1.35-2.45-1.7z"
                    id="ObjToy075__Layer5_3_FILL"
                />
                <g id="ObjToy075__Layer5_0_FILL">
                    <path d="M179.25 289.15q.25-1.2-.4-2.15-.7-1-1.85-1.2-1.2-.25-2.2.45-.95.65-1.15 1.85-.25 1.2.4 2.2.65.9 1.85 1.1 1.15.25 2.15-.4t1.2-1.85m-8.8 1.2q-1.2-.25-2.2.4-1 .65-1.25 1.85-.2 1.2.5 2.2.65.95 1.85 1.15 1.15.25 2.15-.45 1-.65 1.2-1.85.2-1.2-.45-2.15-.7-.95-1.8-1.15m-4.5-6.55q-1.2-.25-2.2.4-1 .65-1.25 1.85-.2 1.2.5 2.15.65 1 1.85 1.2 1.15.25 2.1-.45 1.05-.65 1.25-1.85.2-1.2-.5-2.2-.65-.9-1.75-1.1m8.4-3.4q-.7-.9-1.85-1.1-1.2-.25-2.2.4-1 .65-1.25 1.85-.2 1.15.5 2.2.65.95 1.85 1.15 1.15.25 2.15-.45t1.2-1.85q.25-1.2-.4-2.2z" />
                    <path
                        fill="#272727"
                        d="M163.6 274.45q2.8-.65 4.8-.3.684-.975.4-1.95-.9-.2-1.85.1l-3.1.7q-.91.725-.25 1.45m15.5 6l-.3-2.45q-.75-.65-1.45-1.3-.867.694-.05 1.95 1.05.85 1.8 1.8m-49-12.75q-1.2.3-2.3.85l-1.25 2.2q1.25-.75 2.75-1.2 1.222-.925.8-1.85m13.1 3q.792-.725.3-1.45l-2.7-1.8q-.9-.7-1.95-.8-.844.975-.35 1.95 2.05.35 4.7 2.1z"
                    />
                    <path
                        fill="#585858"
                        d="M159.7 292.45q-2.2-.45-4.05.85-1.8 1.2-2.25 3.45-.35 2.2.8 4.05 1.35 1.85 3.55 2.25 2.15.4 4-.9 1.9-1.2 2.3-3.4.1-.5.05-1 .05-1.65-.95-3.1-.65-1-1.6-1.55-.8-.5-1.85-.65m-25.25-1.1q-.492.81-.7 1.85-.164 1.032 0 1.95l2.95.6-.55 3q.875.554 1.95.75.973.181 1.85 0l.6-3.05 3.15.55q.515-.78.7-1.8.1-.5.05-1 .016-.535-.1-1.05l-3.1-.55.55-3.1q-.744-.456-1.75-.6-1.088-.222-2.1-.05l-.55 3.05-2.95-.55m1.85-14.2q-1.3-1.75-3.5-2.2-2.2-.4-4.05.8-1.8 1.3-2.2 3.55-.4 2.15.85 4 1.25 1.9 3.45 2.25 2.2.45 4.1-.85 1.8-1.3 2.2-3.45.45-2.25-.85-4.1z"
                    />
                </g>
                <g id="ObjToy075__Layer5_4_FILL">
                    <path
                        fill="red"
                        d="M177.75 289.6q.05-.4-.1-.55-.05-.3-.4-.4v-.05q.3.05.45-.1.15-.15.2-.5.05-.45-.2-.7-.3-.25-1-.45l-1.05-.15-.65 3.4 1.3.25q.5.1.95-.1.4-.25.5-.65m-1.1-2.15q.3.05.45.25.1.05.1.25-.05.2-.2.25-.2.15-.5.1l-.4-.15.2-.75.35.05m-.65 1.25l.45.1q.6.15.55.55-.05.25-.25.35-.15.1-.45.05l-.45-.1.15-.95z"
                    />
                    <path
                        fill="#95FF20"
                        d="M170.55 291.35l-.9-.15-1.75 3.15.75.1.4-.75 1.25.2.1.9.75.15-.6-3.6m-.5.4v.25q0 .2.15 1.3l-.85-.15q.6-1.1.7-1.4z"
                    />
                    <path
                        fill="#02A9FF"
                        d="M165.55 286.05l-.55-1.4-.75-.1.7 1.8-1.45 1.55.8.15 1.05-1.15.55 1.45.8.15-.8-1.95 1.35-1.45-.8-.2-.9 1.15z"
                    />
                    <path
                        fill="#FF0"
                        d="M172.15 282.55l1.6-1.85-.85-.15-.95 1.3-.45-1.6-.75-.15.7 2.3-.2 1.35.7.15.2-1.35z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy075__Symbol_139_0_Layer0_0_FILL"
                transform="translate(108.65 268.5)"
                id="ObjToy075__col1n"
            />
            <use
                xlinkHref="#ObjToy075__Symbol_64_0_Layer0_0_FILL"
                transform="translate(123.5 267.5)"
                id="ObjToy075__col1s"
            />
            <use
                xlinkHref="#ObjToy075__Symbol_51_0_Layer0_0_FILL"
                transform="translate(149.5 273.1)"
                id="ObjToy075__col2n"
            />
            <use xlinkHref="#ObjToy075__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy075__Layer5_1_FILL" />
            <use xlinkHref="#ObjToy075__Layer5_2_FILL" />
            <use xlinkHref="#ObjToy075__Layer5_3_FILL" />
            <use xlinkHref="#ObjToy075__Layer5_4_FILL" />
        </g></g>
    )
}

export default ObjToy075
