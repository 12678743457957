import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function LegPan023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M130 .85q.1-.4.1-.75V0q-.85.2-2.95.65-.4.05-.9.15-.3.05-.65.15-1.65.3-3.95.75-.35.1-.7.15-.7.15-1.45.3-1.65.25-3.7.55-1.35.2-2.95.4-1.15.15-2.5.35-1.85.2-3.95.45l-1.9.2q-.2 0-.45.05-1.25.1-2.55.25l-.9.1q-1.55.15-3.2.3-1.65.15-3.45.35-1.05.05-2.1.15l-9.2.7h-.1q-.3 0-.6.05l-3.95.2q-1.8.1-3.55.15l-2.8.1h-.55q-1.5 0-3 .05H62q-1.5-.05-3-.05h-.55l-2.8-.1q-1.75-.05-3.55-.15l-3.95-.2q-.3-.05-.6-.05h-.1l-9.2-.7q-1.05-.1-2.1-.15-1.8-.2-3.45-.35-1.65-.15-3.2-.3l-.9-.1q-1.3-.15-2.55-.25-.25-.05-.45-.05l-1.9-.2q-2.1-.25-3.95-.45-1.35-.2-2.5-.35-1.6-.2-2.95-.4-2.05-.3-3.7-.55-.75-.15-1.45-.3-.35-.05-.7-.15-2.3-.45-3.95-.75-.35-.1-.65-.15-.5-.1-.9-.15Q.85.2 0 0v.1q0 .35.1.75.25 2.05 2.25 3.7.85.1 1.7.25 1.55.2 3.1.45l3 .4.7.1q1 .15 2.05.3.1.05.2.05l.6.1 4.5.7q.7.1 1.4.25 3.2.5 6.35 1.1.3 0 .6.1 3.9.7 7.7 1.45h.15q1.05.2 2.15.45 3.75.7 7.45 1.55 1.15.2 2.3.5l2.8.6q1 .25 2.05.5 1.05.2 2.1.45 2.15.5 4.3 1.05.05 0 .15.05 1.2.3 2.45.6 1.5.35 3 .75.95.3 1.9.55.95-.25 1.9-.55 1.5-.4 3-.75 1.25-.3 2.45-.6.1-.05.15-.05 2.15-.55 4.3-1.05 1.05-.25 2.1-.45 1.05-.25 2.05-.5l2.8-.6q1.15-.3 2.3-.5 3.7-.85 7.45-1.55 1.1-.25 2.15-.45h.15q3.8-.75 7.7-1.45.3-.1.6-.1 3.15-.6 6.35-1.1.7-.15 1.4-.25l4.5-.7.6-.1q.1 0 .2-.05 1.05-.15 2.05-.3l.7-.1 3-.4q1.55-.25 3.1-.45.85-.15 1.7-.25 2-1.65 2.25-3.7z"
                    id="LegPan023__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.55 1.4q.4-.4.45-.8Q1.55.3.4.1.2.05.05 0 0 .2 0 .35q0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45M6.4 4.85q.15 0 .35.05 1 .15 2.05.3.1.05.2.05.15.05.3.05v-.1q0-.6-.45-1.05Q8.4 3.7 7.8 3.7q-.6 0-1.05.45-.3.3-.35.7M21.95 3.3h-.15v.05q0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.4-.4.45-.8-.15-.05-.3-.05-1.3-.15-2.55-.25m10.5 6.1q.45.1.95.2v-.25q0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45-.3.3-.35.7.95.15 1.95.4m9.05-1.15q.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45m9.7 2.5q0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05m7-5.05H57.9q-1.35-.05-2.7-.05v.1q0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05V5.7m10.7.75q-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45m-6.6 4.6q-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45m10.2.5q-.6 0-1.05.45-.45.45-.45 1.05 0 .2.05.35l1.7-.4q.6-.15 1.25-.25-.15-.45-.45-.75-.45-.45-1.05-.45M82.55 6q.45-.45.45-1.05v-.1q-.7.05-1.45.1l-1.45.1H80q.05.55.45.95.45.45 1.05.45.6 0 1.05-.45m6.8.1q-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05m8.9 1.65q.6-.15 1.2-.25.3-.1.6-.1.55-.1 1.15-.2-.1-.45-.45-.8-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .15.05.3m7-3.95q.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05 0-.2-.05-.35-.7.1-1.5.2-.7.1-1.45.15 0 .6.45 1.05m14.25.1q.6 0 1.05-.45Q121 3 121 2.4q0-.6-.45-1.05Q120.1.9 119.5.9q-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45z"
                    id="LegPan023__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan023__Symbol_52_0_Layer0_0_FILL"
                transform="translate(127.45 307.25)"
                id="LegPan023__col1n"
            />
            <use
                xlinkHref="#LegPan023__Symbol_53_0_Layer0_0_FILL"
                transform="translate(131.55 308.1)"
                id="LegPan023__col2n"
            />
        </g></g>
    )
}

export default LegPan023
