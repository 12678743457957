import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00FFFF"
}

function ObjToy066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M352.15 222.35l-1.8-1.45h-8.15q-2.425 1.763-4.85-1.1h-.1l-2.3.2q-4.9-.148-9.75-.15h-.1q-8.05 0-16.05.4-13.85.6-27.6 2.4v86.55q13.65 4.5 27.6 7.05 7.95 1.4 16.05 2.2 4.9.45 9.85.65h14.35l2.85-2.3v-10.7q.1-.043.2-.1 3.382-1.104 6.15-1.45 2.604-.298 9.15-.3 5.3.007 12.05 1.65 7.965 1.982 17.95 6.25 1.693.712 17.45 8.45 1.83.928 3.85 1.9v.05q1.498.683 6.35 4v.05q5.8 3.914 11.1 6.9 4.326 2.413 10.05 5.8 4.949 2.838 10.35 5.4 7.434 3.47 12.35 4.9 3.937 1.136 14.55 3.15 15.025 2.754 20.9 3.3 3.61.301 15.4.3 7.075 0 14.4-.5 7.327-.459 10-1.15.26-.042.4-.3.14-.198.05-.45-.042-.26-.3-.4-.198-.14-.45-.1-2.627.659-9.8 1.1-7.275.5-14.3.5-11.71-.001-15.3-.3-5.825-.554-20.75-3.3-10.537-1.986-14.45-3.1-4.834-1.42-12.15-4.85-5.349-2.538-10.25-5.35v.05q-5.726-3.413-10.05-5.85-5.251-2.964-11-6.85-4.998-3.434-6.55-4.15h.05q-2.353-1.137-4.45-2.2-15.27-7.492-16.95-8.2-10.277-4.393-18.45-6.4-6.74-1.607-12.05-1.6-6.654-.002-9.3.3-2.799.344-6.2 1.45v-1.75l.2-.05q-.25-.05-.35.3.1-.85.15-1.05v-79.8m-11.55.1q-.025-.109-.05-.25h.05v.25m-.05 44.45q.025-.138.05-.3v.3h-.05z"
                    id="ObjToy066__Layer11_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M3.45 8.25l.2-.1Q3.7 8.1 3.7 8q0-.1-.05-.15-.1-.05-.2-.05H.3q-.1 0-.15.05Q.1 7.9.1 8q0 .1.05.15.05.05.15.1h3.15M0 4.1q0 .1.1.15l3 2.4q.05.05.15.05.05 0 .15-.1.05-.05.05-.15-.05-.1-.1-.15l-3-2.4Q.3 3.85.2 3.85q-.1 0-.15.1Q0 4 0 4.1m2.1-2.7q0-.1-.05-.15-.1-.05-.15-.05H.25q-.1 0-.15.05-.05.05-.05.15 0 .1.05.15.05.05.15.05H1.9q.05 0 .15-.05.05-.05.05-.15m1 1.1q.4-.45.4-1.05Q3.5.8 3.1.4T2.05 0q-.6 0-1 .4-.1.05-.05.15-.05.1.05.15.05.05.15.1.05-.05.15-.1.25-.3.7-.25Q2.5.4 2.8.7q.25.3.3.75-.05.4-.3.7-.3.3-.75.3t-.7-.3q-.1-.05-.15-.05-.1 0-.15.05-.1.05-.05.15-.05.1.05.2.4.4 1 .4.65 0 1.05-.4z"
                    id="ObjToy066__Symbol_182_0_Layer0_0_FILL"
                />
                <path
                    fill="#D5D5D5"
                    d="M294.65 264.6l-.1.45h.6l-.2.95h.4l.2-.95h2l-.2.95h.4l.2-.95h.6l.05-.45h-.55l.1-.75q-.7-.2-1.35-.15-.7-.05-1.45.15l-.15.75h-.55m.95 0l.1-.4q.55-.1 1-.05.5-.05 1 .05l-.05.4h-2.05m5.25-.2h-.35v.2l-.9-.1.15-.8h-.35l-.25 1.15q.65.15 1.25.15l-.2 1h.4l.2-1q.5-.05 1.15-.15l.25-1.15h-.4l-.2.8q-.4.05-.75.1v-.2m-7.25.75l-.05.35q-.55.05-1.05.05t-1-.05l.35-1.8h-.4l-.4 2.15q.7.15 1.35.15.7 0 1.45-.15l.15-.7h-.4m-3.15-.2l.15-.65.05-.45q-.7-.2-1.35-.15-.8-.05-1.45.15h-.1l-.05.45.05-.05-.3 1.75h.4l.2-.95h.8q.75 0 1.6-.1m-.3-.4q-.5.05-1.2.05h-.85l.05-.4q.6-.1 1.05-.05.45-.05 1.05.05l-.1.35m27.45-.85l-.45 2.3h.4l.45-2.3h-.4m-2.3.45l-.35 1.85h.4l.35-1.85q.5 0 1.15.15l.1-.45q-.75-.2-1.4-.15-.7-.05-1.4.15l-.1.45q.65-.15 1.25-.15M320 266q.65 0 1.4-.15l.35-2q-.7-.2-1.4-.15-.65-.05-1.4.15l-.4 2q.75.15 1.45.15m-.7-1.8q.55-.1 1-.05.45-.05 1.05.05l-.3 1.3q-.55.05-1 .05-.45 0-1-.05l.25-1.3m-13.55.1l.05-.45q-.7-.2-1.4-.15-.65-.05-1.4.15l-.2 1.2h2.4l-.1.45q-.55.05-1 .05-.65 0-1.35-.2l-.1.5q.7.15 1.4.15.65 0 1.4-.15l.25-1.25h-2.4l.05-.4q.55-.1 1-.05.65-.05 1.4.15m4.3.3h-.6l-.1.45h.65l-.25.95h.4l.2-.95h2.05l-.2.95h.35l.2-.95h.6l.05-.45h-.55l.1-.75q-.7-.2-1.35-.15-.7-.05-1.45.15l-.1.75m.35 0l.1-.4q.55-.1 1-.05.5-.05 1.05.05l-.1.4h-2.05m-1.4-.3l.05-.45q-.7-.2-1.4-.15-.7-.05-1.4.15l-.1.45q.7-.15 1.25-.15l-.35 1.85h.4l.35-1.85q.5 0 1.2.15m15.95 1.7l.35-2.15q-.7-.2-1.35-.15-.55 0-1.15.05l.05-.05q-.05-.05-.1 0-.2-.05-.2.05l-.45 2.25h.4l.3-1.8q.6-.1 1.1-.05.4-.05 1 .05l-.35 1.8h.4m5.55-1.85l-.1.45h-1.9l-.1.45h1.9l-.05.45q-.5.05-1.05.05-.75 0-1.5-.1l-.1.45q.55.05 1.4.1.9-.05 1.55-.1l.4-2.15q-.6-.05-1.5-.05t-1.45.05l-.1.45q.8-.1 1.6-.1.55 0 1 .05z"
                    id="ObjToy066__Layer2_0_MEMBER_0_FILL"
                />
                <path
                    fill="#BFBFBF"
                    d="M337.25 219.8l-2.3.2v99.1h2.4v-50h-.1v-49.3z"
                    id="ObjToy066__Layer2_0_MEMBER_1_FILL"
                />
                <path
                    fill="#6F6F6F"
                    d="M352.15 222.35l-1.8-1.45v48.2h-1.05v50l2.85-2.3v-10.7q.006-.93 0-1.4v-1.75l.2-.05q-.25-.05-.35.3.1-.85.15-1.05v-79.8z"
                    id="ObjToy066__Layer2_0_MEMBER_2_FILL"
                />
                <linearGradient
                    id="ObjToy066__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={283.675}
                    y1={268.2}
                    x2={307.325}
                    y2={268.2}
                    spreadMethod="pad"
                >
                    <stop offset="2.353%" stopColor="#363A3B" />
                    <stop offset="39.608%" stopColor="#929A9D" />
                    <stop offset="49.02%" stopColor="#8C9396" />
                    <stop offset="100%" stopColor="#2F2F2F" />
                </linearGradient>
                <g id="ObjToy066__Layer2_0_FILL">
                    <path
                        fill="url(#ObjToy066__Gradient_1)"
                        d="M309.05 220.25q-13.85.6-27.6 2.4v86.55q13.65 4.5 27.6 7.05v-96z"
                    />
                    <path
                        fill="#2F2F2F"
                        d="M349.3 269.1l-7.1-.2v.2h-4.85q-2.454 25 0 50h11.95q2.1-25 0-50M334.95 220q-4.95-.15-9.85-.15v98.6q4.9.45 9.85.65 2.81-49.363 0-99.1z"
                    />
                    <path
                        fill="#6F6F6F"
                        d="M337.35 219.8h-.1q-1.25 24.65 0 49.3H342.2v-48.2l-4.85-1.1m1.65 2.4h1.6v44.7H339v-44.7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy066__Layer11_0_FILL" />
            <use
                xlinkHref="#ObjToy066__Symbol_182_0_Layer0_0_FILL"
                transform="translate(344.4 224.35)"
                id="ObjToy066__col1n"
            />
            <use xlinkHref="#ObjToy066__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy066__Layer2_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy066__Layer2_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy066__Layer2_0_MEMBER_2_FILL" />
        </g></g>
    )
}

export default ObjToy066
