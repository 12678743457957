import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E4B670",
    "col1n": "#E7D4B6",
    "col1s": "#9B7A42"
}

function ObjMor019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M90 2.85l-5.65.1q-5.7.05-5.9.1l-16.1-1Q62 1.9 47.45.95l-.35-.1-9.75-.6Q37.1.3 31.1 0 21 .15 17.35 3.5q-2 1.7-2.2 5.45l.05 1.25q.1 1.5.4 2.85.9 4.2 3.3 4.3 1.1.15 3-.1 0 .35.05.8l.05.45q.014-.009 0-.05.3 1.9.3 1.95l.15.05q.05.4.05.75.1 1.3.1 1.5-.45 6.65-3.3 16.45-.85 2.7-2.45 8.25-1.45 4.8-2.2 8.85-.65 3.05-1.45 8.3-1.05 6.75-1.3 8.45-.55 3.25-2.25 10.2-.45 1.6-.75 2.75 0 .3-.1.7l-.05.15v.05l-.05.05q-.2.5-.4.95-.25.55-.4 1-2.3.45-4.05 1.15-2.3 2.1-3.15 4.65-.05.15-.7 4.15-.15 4.05 2.5 4.95 4.2 1.35 10.8.4 1.6-.25 3.05-.5.1-.05.15-.1 5.05-1.2 14.4-1.6l9 .6q2.45.3 5.7.8 3.05.4 6.35.7l12.8.8q3.8-.35 12.6-.95 6.1-.45 6.9-1.2 2.45-1.05 3.8-4.05 1.05-2.5 1.25-5.5.2-3.35-1.25-8.9v-.3l-.3.1q-.35.15-.7.25-2.05.45-4.1-.3-2-.7-3.25-2.05-.15-.15-.25-.3-.5-.75-.9-1.5-1.35-2.75-.35-5.75.95-3 3.75-4.45 1.2-.55 2.4-.7.3-.05 1-.1-.2.05 0 .05.7-.1 1.2-.05.35.15.8-4.6.45-4.7 3.9-16.75 3.5-12 6.95-22.15 1.9-5.55 1.95-7.05.05-.55.05-1.05V16q-.2-3.45-1.35-6.65-2.6-7-8.85-6.5m.1 8.1v-.05q.047 0 .1.05-.053-.047-.1 0m-6.2 6.25q-.2-.05-.35-.1.5.1 1.05.15l-.7-.05m-1.15 72.7q.05.05.05.1.05.15.05.3-.05-.25-.1-.4z"
                    id="ObjMor019__Symbol_129_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M38.35 83.45L23.9 82.3Q10.65 79.85.4 83.5q-.25.55-.4 1 .2-.05.4-.15.75-.1 1.55-.3 1.25-.1 2.75-.35 5.2-.55 10.25.2 9.25 1.2 10.2 1.6 10.45 1 18.8.65 4.75-.45 10.15-1.2.05-.05.1-.05 7.3-1.35 14.05-3.95l2.3-.9q.15-.05.35-.05 1.4-.3 2.5.15.1.05.25.15.45.25.9.65.4.3.75.8.3.35.65.85.55.8.9 1.9.5 1.65.4 3.5 0 .1-.05.25-.2.2-.4.2t-.35-.15h-.05v-.05q-.1-.1-.15-.25.15-4.1-3-5.9-.25-.1-.5-.1l-.9-.05q-.6.25-1.45.65l-.3.15-.5.75q-1.7 2.25-1.8 4.15-.1 1.05.05 2.15v.35q.1.75.35 1.75 0 .4.15.8 1.3 4.7 4.6 4.85 1.05.1 1.95-.15 1.85-.55 3.05-2.15.15-.15.25-.3.45-.7.85-1.65.6-1.4.9-3.2.2-1.4.3-1.45-.05-.05-.05-.1 0-.6-.1-1.3.05-.15.05-.9-.05-.75.05-2.35.05-1.6.1-2.85-.05-1.25-.45-1.45-.35.15-.7.25-2.05.45-4.1-.3-2-.7-3.25-2.05-3.65 0-12.85 2.95-.9.35-1.6.6-1.15.4-2.4.75-3.05.8-6.65 1.25-4.9.55-9.95.3m36.55 2.2q.05.15.05.3-.05-.25-.1-.4.05.05.05.1m17.4-74q-.2-.8-.55-1.95-1.35-4.55-2-5.4-.7-.9-1.15-1.5-.6-.85-1.75-1.65Q85.4.05 84.4.05 84.35 0 84.3 0h-.2q-3.25.3-4.6 5.3-.1.3.05.9.05.2.15.45l.05.05q.3 3.8 3.3 4.95h.8q1.3-.35 2.05-1.15.35-.4.7-1.45 1.05-2.7 1.05-3.05 0-.15-.1-.2.15-.2.25-.25.2 0 .35.1-.05.1.05.25.05.3.15.35l-.05.7q-.05.8-.25 1.55-.45 3.05-3.55 4.7v.05q-.55.2-1.25.2l-3.2-.25-.2-.05-.2.05Q50.3 7.95 20.6 11.85q-2.95.5-5 .8-.9.1-1.6.25 0 .35.05.8 15.75-1.35 32.1-.8 2.35 0 4.75.1 9.75.45 15.95 1.55 4.85.85 6.8 1.4 1.55.35 2.9.6.25.1.5.15.9.3 1.8.55 8.8 1.55 12-5.35-1.95 8.85-6.3 21.65-4.35 12.8-7.1 31.25-.2.05 0 .05.7-.1 1.2-.05.35.15.8-4.6.45-4.7 3.9-16.75 3.5-12 6.95-22.15 1.9-5.55 1.95-7.05.05-.55.05-1.05v-1.55M82.2 6.6v-.05q.047 0 .1.05-.053-.047-.1 0m-6.55 6.15q.5.1 1.05.15l-.7-.05q-.2-.05-.35-.1z"
                    id="ObjMor019__Symbol_71_0_Layer0_0_FILL"
                />
                <g
                    id="ObjMor019__Symbol_55_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path
                        fillOpacity={0.502}
                        d="M5 79.8l-.9-.05q-.6.25-1.45.65l-.3.15-.5.75q-1.7 2.25-1.8 4.15-.1 1.05.05 2.15 3.75-1.7 8.55-1.55-.1-.1-.15-.25.15-4.1-3-5.9-.25-.1-.5-.1m2.1 3.55q.05.05.05.1.05.15.05.3-.05-.25-.1-.4z"
                    />
                    <path
                        fillOpacity={0.8}
                        d="M7.55 79.55q.3.35.65.85.55.8.9 1.9.5 1.65.4 3.5 0 .1-.05.25-.2.2-.4.2t-.35-.15h-.05v-.05Q3.85 85.9.1 87.6v.35q.1.75.35 1.75.4.7.8 1.15.95.95 2.65 1 .25 0 .45.05 3.95-.15 5.25-3.35.3-.55.5-1.2.05-.05.05-.1.95-3.7-.9-6.6l-.05-.05q-1.15-1.2-2.4-1.85.4.3.75.8z"
                    />
                </g>
                <g
                    id="ObjMor019__Symbol_55_0_Layer0_1_FILL"
                    fill={colors.col1s}
                >
                    <path
                        fillOpacity={0.502}
                        d="M138.3 230.75q1.3-.35 2.05-1.15.35-.4.7-1.45 1.05-2.7 1.05-3.05 0-.15-.1-.2.15-.2.25-.25-3.952 1.342-8.1 1.15.346 3.817 3.35 4.95h.8z"
                    />
                    <path
                        fillOpacity={0.8}
                        d="M136.2 221.9q-.12.083-.25.2-.35.25-.8.65-1.05.85-1.1 2.1 0 .2-.05.45.05.2.15.45v.05q4.148.192 8.1-1.15.2 0 .35.1-.05.1.05.25.05.3.15.35l-.05.7q-.054.777-.3 1.55-.412 3.057-3.5 4.7l.85-.1q.45-.2.9-.35.15-.05.25-.2.036-.015.05-.05 2.31-1.334 2.55-3.95v-.2q.1-1-.1-1.7-.144-.302-.3-.6-.397-2.312-2.1-3.25-2.413-1.224-4.85 0m.45 3.8v-.05q.047 0 .1.05-.053-.047-.1 0z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor019__Symbol_129_0_Layer0_0_FILL"
                transform="translate(46.55 214.75)"
                id="ObjMor019__col1n"
            />
            <use
                xlinkHref="#ObjMor019__Symbol_71_0_Layer0_0_FILL"
                transform="translate(54.45 219.1)"
                id="ObjMor019__col1d"
            />
            <g id="ObjMor019__col1s">
                <use
                    xlinkHref="#ObjMor019__Symbol_55_0_Layer0_0_FILL"
                    transform="translate(122.2 221.3)"
                />
                <use xlinkHref="#ObjMor019__Symbol_55_0_Layer0_1_FILL" />
            </g>
        </g></g>
    )
}

export default ObjMor019
