import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor075__Layer10_0_FILL">
                    <path
                        fill="#0040D4"
                        d="M235.95 273.25q-5.472-.759-10.4 0-3.267.783-3 3 4.815 1.556 11.55 0 .438-.48.8-1 .668-.94 1.05-2z"
                    />
                    <path
                        fill="red"
                        d="M233.85 264q-2.6-2.6-6.25-2.6-3.272 0-5.8 2.15l.1.1h-.05q-.137.417.65.65 5.825 1.535 11.65 0l-.3-.3z"
                    />
                    <path
                        fill="#FF0"
                        d="M235.95 267.25q-5.52-1.288-10.55 0 .523 1.698 3 3 4.185 1.117 8.05 0 0-1.603-.5-3z"
                    />
                </g>
                <g id="AccMor075__Layer10_1_FILL">
                    <path
                        fill="#841D9A"
                        d="M222.55 276.25q-.924-.212-.75.7 2.55 2.15 5.8 2.15 3.65 0 6.25-2.6l.25-.25h-11.55z"
                    />
                    <path
                        fill="#F90"
                        d="M234.9 265.25q-.343-.493-.75-.95H222.5q-.31 1.672 2.9 2.95h10.55q-.382-1.059-1.05-2z"
                    />
                    <path
                        fill="#008E29"
                        d="M228.4 270.25l.1.1q-2.619.872-2.95 2.9h10.4q.5-1.397.5-3h-8.05z"
                    />
                </g>
                <g id="AccMor075__Layer10_2_FILL">
                    <path
                        fill="#FFF"
                        d="M219 268.05q-.25 1.048-.25 2.2 0 1.234.3 2.35 1.662-.435 2.25-2.25-.28-2.118-2.3-2.3z"
                    />
                    <path
                        fill="#66C9EE"
                        d="M220.1 265.5q-.367.572-.65 1.15 1.005 1.955 3.65 3.7-2.238 1.262-3.6 3.55.28.614.65 1.15 1.508-.774 2.75-2.05.257-.242.5-.55.815-.93 1.5-2.1-1.586-2.696-4.8-4.85z"
                    />
                    <path d="M221.85 263.65h.05l-.1-.1q-.254.213-.5.45-.226.23-.45.45 2.168 3.155 5.85 5.9-1.834.619-3.3 2.1-.243.308-.5.55-1.101 1.295-2 3.1.206.203.4.4.263.253.5.45l6.7-6.6-6.65-6.7z" />
                </g>
                <g id="AccMor075__Layer10_3_FILL">
                    <path
                        fill="#82491E"
                        d="M220.5 264.9q-.219.312-.4.6l4.8 4.85-4.75 4.7q.163.28.35.5.202.292.4.55l5.8-5.75-5.85-5.9q-.175.244-.35.45z"
                    />
                    <path
                        fill="#FAB5C7"
                        d="M219.45 266.65q-.267.688-.45 1.4l2.3 2.3-2.25 2.25q.166.685.45 1.3l3.6-3.55-3.65-3.7z"
                    />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor075__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor075__Layer10_0_FILL" />
            <use xlinkHref="#AccMor075__Layer10_1_FILL" />
            <use xlinkHref="#AccMor075__Layer10_2_FILL" />
            <use xlinkHref="#AccMor075__Layer10_3_FILL" />
            <use
                xlinkHref="#AccMor075__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor075__col1l"
            />
        </g></g>
    )
}

export default AccMor075
