import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#411F00",
    "col1n": "#773900"
}
function LegSho014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M142.9 12.15q2.25-.15 3.35-1.25 1.7-3.3 0-6.6-.05-.05-.05-.1l-.2-.3q-.4-.4-.85-.7-5.6-3.7-16.25-3.15H85.25Q82 .2 78.7.4q-1.6.4-2.8 1.3-.05.05-.15.1l-.4.3q-1.3.9-1.8 2.5-.5-1.6-1.8-2.5l-.4-.3q-.1-.05-.15-.1Q70 .8 68.4.4 65.1.2 61.85.05H18.2Q7.55-.5 1.95 3.2q-.45.3-.85.7l-.2.3q0 .05-.05.1-1.7 3.3 0 6.6 1.1 1.1 3.35 1.25h1.3q.85-.05 1.7-.15.1-.05.2-.05.5-.1.9-.15 1.15-.3 1.25-.25l1.55-.25q.1.25.3.45.75.75 3.6.75 1.7 0 3.75-.4 2-.45 2.75-.6.35-.1.65-.15.85-.2 1.4-.3.05.1.15.2.2.35.35.5.75.75 3.25.75 2.45 0 4.55-.45 2.1-.5 2.95-.65l1.1-.1q.1.25.3.45.75.75 3.6.75 1.7 0 5-.75 1.4-.25 4.9-.65.75-.05 1.6-.1 1.3-.05 5.45-.1 1.55 0 7.4.65 5.8.6 7.4.3 1.35-.35 2-1.6.65 1.25 2 1.6 1.6.3 7.4-.3 5.85-.65 7.4-.65 4.15.05 5.45.1.85.05 1.6.1 3.5.4 4.9.65 3.3.75 5 .75 2.85 0 3.6-.75.2-.2.3-.45l1.1.1q.85.15 2.95.65 2.1.45 4.55.45 2.5 0 3.25-.75.15-.15.35-.5.1-.1.15-.2.55.1 1.4.3.3.05.65.15.75.15 2.75.6 2.05.4 3.75.4 2.85 0 3.6-.75.2-.2.3-.45l1.55.25q.1-.05 1.25.25.4.05.9.15.1 0 .2.05.85.1 1.7.15h1.3z"
                    id="LegSho014__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.6 1.9l.1-.1q.15-.2.55-.75Q3.6.45 3.2.6q-.4.15-.65.35-.3.15-.85.8-.3.3-.5.6t-.45.75Q.5 3.55.3 4.2q-.25.65-.3 1.5-.05.85.2 2t.4 1.8v.25l1.55-.25q-.1-.15-.25-.7-.2-.55-.4-1.3-.25-.75-.1-1.85.1-1.15.35-1.95.25-.85.45-1.1.2-.3.25-.35 0-.1.15-.35m12.15 7.55q-1.75-4.9.3-7.5.8-1.05 1.4-1.6-.65.2-1.05.4-2.9 1.85-2.75 5.55 0 .55.2 1.3.25 1.25.35 1.95.85-.2 1.4-.3.05.1.15.2m12.35-7q.15-.3.3-.55.5-.9 2.45-1.9-2.8.5-3.85 2.45-.2.35-.35.8-.1.35-.15.7-.1.25-.1.5-.05.45-.1.95-.05.75-.05 1.55 0 1.95.6 2.65l1.1-.1q-.5-.95-.5-3.1 0-.45.05-1 0-.45.05-.95 0-.15.05-.4.05-.4.2-.8.1-.4.3-.8m74.7-.55q.15.25.3.55.2.4.3.8.15.4.2.8.05.25.05.4.05.5.05.95.05.55.05 1 0 2.15-.5 3.1l1.1.1q.6-.7.6-2.65 0-.8-.05-1.55-.05-.5-.1-.95 0-.25-.1-.5-.05-.35-.15-.7-.15-.45-.35-.8Q102.15.5 99.35 0q1.95 1 2.45 1.9m12.35.05q2.05 2.6.3 7.5.1-.1.15-.2.55.1 1.4.3.1-.7.35-1.95.2-.75.2-1.3.15-3.7-2.75-5.55-.4-.2-1.05-.4.6.55 1.4 1.6m13.85.4q-.2-.3-.5-.6-.55-.65-.85-.8-.25-.2-.65-.35-.4-.15-.05.45.4.55.55.75l.1.1q.15.25.15.35.05.05.25.35.2.25.45 1.1.25.8.35 1.95.15 1.1-.1 1.85-.2.75-.4 1.3-.15.55-.25.7l1.55.25V9.5q.15-.65.4-1.8t.2-2q-.05-.85-.3-1.5-.2-.65-.45-1.1-.25-.45-.45-.75z"
                    id="LegSho014__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho014__Symbol_33_0_Layer0_0_FILL"
                transform="translate(118.95 325.4)"
                id="LegSho014__col1n"
            />
            <use
                xlinkHref="#LegSho014__Symbol_34_0_Layer0_0_FILL"
                transform="translate(127.9 327.2)"
                id="LegSho014__col1d"
            />
        </g></g>
    )
}
export default LegSho014
