import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#0000BB",
    "col1n": "#0000FF"
}

function LegSki027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143 .1l-.5-.1q-.6.15-1.15.3-.7.15-1.35.3-.15.05-.25.05l-1.4.3q-.3.05-.55.15-.75.15-1.45.3-1.45.35-2.95.65V1.8q-.002.107-.05.25-.45.1-.85.2l-4.5.9q-2.25.4-4.45.8-4.45.75-8.85 1.35l-3.5.5q-4.45.55-8.9 1-1.05.05-2.1.2-11.6.95-23.15.95h-.3q-10.4 0-20.8-.8-.85-.1-1.7-.15-6-.55-12-1.3-1.7-.25-3.45-.45-2.9-.45-5.75-.9-2.4-.4-4.7-.8-2.2-.4-4.4-.85-.45-.1-.9-.15-.4-.1-.8-.15-1.3-.3-2.6-.55-1.5-.35-3-.65l-.4-.1q-.3-.1-.6-.15-.3-.05-.55-.1Q15 .8 14.95.8 13.5.5 12.1.15q-.2-.05-.35-.1-.25 0-.55.05Q6.1 22.4 0 26.3q5.05 5.3 15 2.9 4.9-1.7 8.8 1.25 6.95.9 13.75 1.6 49.15 4.9 95.35-1.65 5.2-3.05 10.45-1.65 1.1.3 2.1.45 5.35.7 6.35-3.55Q148.15 20.9 143 .1z"
                    id="LegSki027__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.75.05q-.25 0-.55.05Q6.1 22.4 0 26.3q3.65-.85 4.95-1.05l-.05-.05q2.05-.55 3.8-1.65Q16.6 15.7 14.95.8 13.5.5 12.1.15q-.2-.05-.35-.1M24.7 16.8q-.2-7.6-1.65-14.25-.4-.1-.8-.15-1.1 9.45-2 14.15-.2.95-.4 1.7Q18.2 25 15 29.2q4.9-1.7 8.8 1.25.95-5.2.95-10.85 0-1.4-.05-2.8M133.4 2.05h-.05q-.25 1.75-.6 7.2-.2 3.95-.3 7.3-.05 1.95-.05 3.7 0 6.1.5 10.15 5.2-3.05 10.45-1.65-2.5-1.1-6.45-6.65-1.55-2.15-2.4-5.65-1.4-5.55-1.1-14.4M143 .1l-.5-.1q-.6.15-1.15.3-.7.15-1.35.3-.15.05-.25.05-1.05 14.45 5.95 22 2.6 1.9 6.1 3Q148.15 20.9 143 .1z"
                    id="LegSki027__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki027__Symbol_93_0_Layer0_0_FILL"
                transform="translate(115.45 307.1)"
                id="LegSki027__col1n"
            />
            <use
                xlinkHref="#LegSki027__Symbol_53_0_Layer0_0_FILL"
                transform="translate(115.45 307.1)"
                id="LegSki027__col1d"
            />
        </g></g>
    )
}

export default LegSki027
