import { AssetSvgProps } from "../../../shared/assets"

function ShiMot068({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 257.85v35.75h.1v.2h66.5V258h-.1v-.15h-66.5z"
                    id="ShiMot068__Layer5_0_FILL"
                />
                <path
                    fill="red"
                    d="M181.35 275.65q0 4.45 3.2 7.65t7.7 3.2q4.45 0 7.65-3.2t3.2-7.65q0-4.5-3.2-7.7t-7.65-3.2q-4.5 0-7.7 3.2t-3.2 7.7z"
                    id="ShiMot068__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot068__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot068__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot068
