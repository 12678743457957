import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}
function SkiMor032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M104.95 11.65h.15q-1.85-4.95-7.15-8Q86.55-1.4 79.25.5q-.25.05-.45.1-22.9 6.15-48.5.6-.65-.15-1.25-.25-3.95-.85-7.6-.5-7.05.65-12.4 5-.65.55-1.2 1.1-2.35 2.2-2.75 3.5 1-.45 4.45-1.1 3.05-.55 4.25-.55.197-.05.4 0l.15-.15v.15h-.15q-.21-.046-.8.2-.85.4-2.55 1.35-3 1.6-6.4 4.9Q.95 18.15 0 23.7l.2-.15q3.2-2.4 7.15-3.2-2.7 5.5-2.8 11.1v.25q4.45-4.8 10.4-7.2 1 6.6 6.35 11.85l.8.8q.65-5.3 2.3-8.4 1.3 2.8 4.95 5.8 3.6 3 7.8 4.45.55-2.95 1.8-7 6.2 3.2 13.15 11.2 1.55-2.45 2.5-4.85 3 3 5.1 7.85 5.35-6.4 8.15-14.75 2.55 3.7 4.05 9.05 1.95-1.2 4.3-3.2 3.6-3.15 4.55-4.8.1 0 .25-.1 0 .6.3 2.95.3 2.25.3 3.05 7.25-6.7 7.65-11.25 2.4.3 5.1 4.15 1.4 2 2.65 4.45h.1q.75-2.85.9-4.25.25-.9.1-2.8-.2-1.9-.4-3.3-.15-1.05-.35-1.85 3.25.75 6.55 3.3l.15.15q.05-.55.1-1.2.2-1.8.2-3.25 0-4.2-1.7-7.2-1.7-2.95-5.4-5.45v-.15q1.2-.45 2.75 0 2.1.45 4.6 1.7.15.1.35.2M94.8 19q-.1-.05-.15-.1h.05l.1.1M23.85 1.75h.25q0 .15-.05.1-.05-.05-.1-.05-.048-.048-.1-.05z"
                    id="SkiMor032__Symbol_37_0_Layer0_0_FILL"
                />
                <g
                    id="SkiMor032__Symbol_36_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M7.8 11.55l-.15.15q-.45-.15-1.05.1-.65.25-1.95.9-1.3.65-3.55 2.65L0 16.6q3.2-2.4 7.15-3.2.4-.9.9-1.75l-.3.05q.05-.1.05-.15m5.8-10.1L15.45 0Q8.4.45 4.9 3.1q1-.45 4.45-1.1 3.05-.55 4.25-.55m83.45 1.5V2.8q1.2-.45 2.75 0 2.1.45 4.6 1.7-5.5-4.2-8.75-2.9l1.4 1.35m6.65 16.95q-.35-.35-.85-1.15-.55-.8-2.25-2.1-1.75-1.3-3.1-1.6-1.4-.35-1.35-.25 0 .05.05.3l.1 1.65q.7 4.1.2 7.85-3-4.2-4.35-5.15-1.35-.95-2.1-1.3-.7-.45-.85-.5-.15-.1-.5-.25-.45-.2-1.3-.45.2 1.45-1 4.75-1.2 3.25-3.85 5.45-.2-3.4-.75-4.65-.55-1.25-.75-1.55-.2-.25-.25-.4-1.05 2.35-3.65 5.35-2.65 3-4.8 4.5l-.45-1.7q-.15-.8-2.25-4.25-1.8-3-2.7-3.7-.25 1.4-.7 2.7-.5 1.25-1.55 3.9-1.1 2.6-2.55 4.9-1.45 2.25-2.45 3.35 0-.35-.05 0-.2-.8-.85-1.8-.7-1-1.4-2.05-.75-1.05-1.6-2.15l-.95-.9q-.55-.4-1.05-.75-.4.6-2.5 5-2.2-2.7-5.35-5.4-4.2-3.55-8.55-5.6-1.9 5.95-1.9 6.95-2.4-.5-6.1-3.9-3.75-3.45-4.6-7.3-2.55 2.2-4.1 8.35-3.75-4.7-4.85-11.2l-.1-.05q-7.3 2.6-11.4 9.85v.1q4.45-4.8 10.4-7.2 1 6.6 6.35 11.85l.8.8q.65-5.3 2.3-8.4 1.3 2.8 4.95 5.8 3.6 3 7.8 4.45.55-2.95 1.8-7 6.2 3.2 13.15 11.2 1.55-2.45 2.5-4.85 3 3 5.1 7.85 5.35-6.4 8.15-14.75 2.55 3.7 4.05 9.05 1.95-1.2 4.3-3.2 3.6-3.15 4.55-4.8.1 0 .25-.1 0 .6.3 2.95.3 2.25.3 3.05 7.25-6.7 7.65-11.25 2.4.3 5.1 4.15 1.4 2 2.65 4.45h.1q.75-2.85.9-4.25.25-.9.1-2.8-.2-1.9-.4-3.3-.15-1.05-.35-1.85 3.25.75 6.55 3.3z" />
                    <path d="M59.2 33.65l.05-.05q-.05-.05-.1-.15 0 .1.05.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#SkiMor032__Symbol_37_0_Layer0_0_FILL"
                transform="translate(139.75 245.45)"
                id="SkiMor032__col1n"
            />
            <use
                xlinkHref="#SkiMor032__Symbol_36_0_Layer0_0_FILL"
                transform="translate(139.95 252.4)"
                id="SkiMor032__col1d"
            />
        </g></g>
    )
}
export default SkiMor032
