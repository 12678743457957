import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#640202",
    "col1n": "#830000"
}

function AccHat064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M171.6 46.25q0-5.65-5.55-12.3-6-6.35-9.75-10.85-.45-.7-.9-1.45Q143.05 4.1 125.85 6l-5.9-.05q-4.1-.3-7.65-.9-7.2-2.45-14.25-3.7-10-1.8-19.7-1.2-6.15.15-12 1.2-26.15 4.6-45.75 26.4Q14 37.3 10 50.6q-1.2 3.9-4.5 8-11 16.95 0 29.95.6.45 1.25.95 1.2.9 2.95 2.05 26.1 10.05 55.85-3.25 3.05-1.75 5.9-4l.15-.15 5.95-3.6.1-.1h.25q70-2.45 90.3-18.8 1.35-2.9 2.6-9.95.15-.5.3-1.1.5-2.15.5-4.35z"
                    id="AccHat064__Symbol_282_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat064__Symbol_188_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M.15 29.5l-.05-.05-.1.05h.15M90.5 10.75h.45v-.15q-.15.05-.25.1-.1 0-.2.05z" />
                    <path d="M90.95 10.75h-.45q-2.05.5-4 .95-32.1 7.65-65.95 8.9Q35.45 11.55 38.8 0q-8.35 11.2-24.75 20.8L.1 29.45l.05.05q47.6.95 90.8-9.4v-9.35z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat064__Symbol_282_0_Layer0_0_FILL"
                transform="translate(105.1 30.9)"
                id="AccHat064__col1n"
            />
            <use
                xlinkHref="#AccHat064__Symbol_188_0_Layer0_0_FILL"
                transform="translate(182.6 81.85)"
                id="AccHat064__col1d"
            />
        </g></g>
    )
}

export default AccHat064
