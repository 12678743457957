import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.1 4.5l.4-.1-.1-.05q-1 .05-3.55-.6-3.15-.8-7.15-2.3Q32.65 0 30.25 0q-2.5 0-4.3.8-1.5.55-2.15.9-.8-.25-2.25-.9Q19.7 0 17.2 0q-2.35 0-6.45 1.45Q6.8 2.95 3.2 3.9q-2.1.55-3.2.55l4.65 1.5 19.15 2.8L43.65 6.1l3.45-1.6z"
                    id="FacMou045__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M41.05 3.7q.6-.35 2.3-1.4 1.7-1.1 4.15-2.3h-.25l-9.5 1.9q-.15 0-.25.05-16 2.85-31.4-.75L.25 0H0q2.55 1.25 4.5 2.45 1.9 1.15 2.35 1.5.45.35 1.2 1 .75.6 1.5 1.15.75.55 2.1 1.35Q22.55 12.2 35 7.8q1.05-.35 1.95-.95.45-.3 1.05-.8.55-.55 1.5-1.25.95-.75 1.55-1.1z"
                    id="FacMou045__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou045__Symbol_70_0_Layer0_0_FILL"
                transform="translate(168.7 215.7)"
                id="FacMou045__col1n"
            />
            <use
                xlinkHref="#FacMou045__Symbol_23_0_Layer0_0_FILL"
                transform="translate(168.7 220.15)"
                id="FacMou045__col1l"
            />
        </g></g>
    )
}

export default FacMou045
