import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ObjMor090({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.6}
                    d="M122.15 43.6q16-5.65 19.8-13.2 1.05-2.1 1.2-4.3v-.55q0-10.6-21-18.1Q101.2 0 71.55 0 41.9 0 20.95 7.45 0 14.95 0 25.55q0 .25.05.55.1 2.95 1.95 5.7 4.5 6.65 18.95 11.8Q41.9 51 71.55 51q29.65 0 50.6-7.4z"
                    id="ObjMor090__Symbol_221_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M360.2 351.55q-12.7-4.15-25.1-6.5-12.85-1.95-28.05-1.95-29.65 0-50.6 7.45-15.95 5.7-19.75 13.2 4.5 6.65 18.95 11.8 20.95 7.4 50.6 7.4 29.65 0 50.6-7.4 16-5.65 19.8-13.2-4.1-6-16.45-10.8z"
                    id="ObjMor090__Symbol_221_0_Layer0_1_FILL"
                />
            </defs>
            <g id="ObjMor090__col1n">
                <use
                    xlinkHref="#ObjMor090__Symbol_221_0_Layer0_0_FILL"
                    transform="translate(234.7 331.95)"
                />
                <use xlinkHref="#ObjMor090__Symbol_221_0_Layer0_1_FILL" />
            </g>
        </g></g>
    )
}

export default ObjMor090
