import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00DEDE"
}

function AccMor060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.9 7.25q1.35-1.8 1.85-2.9.85-1.9-.2-3.05-.75-1.6-3-1.25-1.2.2-3.4 1.1-2.5 1.1-3.95 1.6-3.25 1.3-6.8 3.3-2.55 1.4-6.55 4-2.8 1.85-6.5 4.55-4 3-5.9 4.35-1.4 1.05-4.1 4Q.7 25.9.4 27.1q-.45.6-.4 1.85.65 6.15 6.45 7.6.7.1 1.2-.1 1.25 0 4.75-1.9 3.55-1.8 4.9-2.95 1.75-1.45 5.65-4.6 3.65-2.95 6.05-5.2 3.5-3.2 5.5-5.3 2.85-2.95 4.85-5.7.85-1.25 2.55-3.55m-2.7-2.8q.05 0 .1.05-8.85 11.4-19.75 20.4h-.1q-2.35 1.9-3.6 2.9-2.35 1.8-4.1 2.35l-.35.05q-1.1-.1-.8-1.05l1.95-1.5-.05-.6q-.1-.7-.45-1.45-.05-.2-.2-.25-.7-.65-1.6-.55l-.6.1-1.45 1.15q-.9.1-.8-1l.1-.4q1-1.45 3.3-3.3 1.3-1.05 3.75-2.8.45-.4 3.3-2.35Q28.25 9.1 39.2 4.45z"
                    id="AccMor060__Symbol_157_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor060__Symbol_157_0_Layer0_0_FILL"
                transform="translate(123.85 67.7)"
                id="AccMor060__col1n"
            />
        </g></g>
    )
}

export default AccMor060
