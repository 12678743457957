import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M189.85 55.65q-.75-.6-1.45-1.15-.2-.2-.4-.35-3.95-20.05-23-35.1-18.35-14.5-42.5-17.95-4.65-.65-9.5-.9-1.7-.1-3.4-.15-1.35-.05-2.75-.05h-2.2l-2.2.1q-1.55.05-3.1.2-4.25.25-8.35.85-24 3.45-42.3 17.9-19 15.05-22.95 35.1-.95.7-1.9 1.5-1.1.85-2 1.7-33.55 29-15.6 65.8-1.15 2.05-2 4.05-8.1 17.65 1.4 32.1 2.1 3.25 5.1 6.3.55.6 1.15 1.15.25 1.15.6 2.25 1.25 10.95 8.8 19.2 7.4 7.95 17.25 9.5.1 0 .25.05.2-.4.4-.75 1.3-2.3 2.7-4.5.15-.25.3-.45 1.4-2.2 4.7-7.1-13.5-11.65-21.85-25.65-.45-.7-.85-1.4-9.1-15.85-11.75-34.6-.25-2.5-.3-5v-2.75Q24.6 113.25 34 103.6q1.8-1.8 3.3-3.7.15-.2.3-.35 8.75-11.25 8.75-26.15 0-5.9-1.4-11.2.3.1.55.25Q55.1 71 68.05 71.2h.55q3.25 0 6.3-.5 7.45-1.3 13.6-5.65l.7.6q3.7 3.05 8.2 4.65 9.85 3.15 18.95 0 4.45-1.6 8.15-4.65l.7-.6q6.15 4.35 13.6 5.65 3.05.5 6.3.5h.55q12.95-.2 22.55-8.75.25-.15.55-.25-1.4 5.3-1.4 11.2 0 15.1 9 26.45l.05.05q1.5 1.9 3.3 3.7 9.4 9.65 21.85 11.95v2.75q-.1 2.85-.3 5.7-2.8 18.05-12.1 33.9l-.8 1.4q-8.3 13.6-21.45 25.55-.05 0-.05.05 3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5.2.35.4.75.15-.05.25-.05 9.85-1.55 17.25-9.5 7.55-8.25 8.8-19.2.35-1.1.6-2.25 3.75-3.6 6.25-7.45l.9-1.4q8.35-14.15.25-31.35-.8-1.7-1.75-3.4 17.95-36.8-15.6-65.8-.9-.85-2-1.7z"
                    id="HaiMed018__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6.65 64.75q-.35 4.4-2.85 9.05Q0 80.95 0 86.85 0 92.8 3.7 98.3q1.9 2.8 4.5 4.85l.6.5q.65.45 1.35.9H9.9l4.35 3.6q-3.5 21.25 14.85 33.9 1.9.6 3.9.9.1 0 .25.05.2-.4.4-.75 1.3-2.3 2.7-4.5.15-.25.3-.45.2-.3.45-.65.7-1.15 1.9-2.9 1-1.55 2.35-3.55-13.5-11.65-21.85-25.65-.45-.7-.85-1.4l-.3-.5Q9.5 87 6.9 68.55q-.2-1.9-.25-3.8M31 0l.1.3q.4 1.2.9 3.05.25.9 1.3 4.3.3 1.05.7 2.3 1.2 5.05 1.65 8.15.55 3.65.55 7.75 0 10.55-4.1 17.45-1.8 3.1-5.65 7.55l2-2q1.8-1.8 3.3-3.7.15-.2.3-.35 8.75-11.25 8.75-26.15 0-3.75-.55-7.25-.35-1.95-.8-3.75-1.9-1-2.4-1.45Q34.7 3.8 31 0m119.3 13.9q-1.2.05-2.4.05-3.2 0-6.15-.35-4.65-.65-8.7-2.2-3.3-1.3-6.15-3.2-.4-.25-.8-.55-2.75-1.95-4.55-4.15-1.55 2.45-4.4 4.55-6.25 4.6-15.05 4.6-4.05 0-7.6-.95-2.3-.55-3.9-1.5-1.85-.9-3.55-2.15-3.35-2.45-4.9-5.45Q80 5.65 76.1 8.2q-6.9 4.55-16.1 5.55-.65.05-1.25.1h-.15v.1h-8.75q5.85 2.4 12.65 2.5h.55q3.25 0 6.3-.5 7.45-1.3 13.6-5.65l.7.6q3.7 3.05 8.2 4.65 9.85 3.15 18.95 0 4.45-1.6 8.15-4.65l.7-.6q6.15 4.35 13.6 5.65 3.05.5 6.3.5h.55q1.4 0 2.75-.1 5.3-.5 10.05-2.45-.6.05-.95-.1-.8.05-1.65.1m45.1 57.15Q193.05 84.6 187 96.9q-1.6 3.15-3.4 6.25l-.8 1.4q-8.3 13.6-21.45 25.55-.05 0-.05.05 1.35 2.1 2.45 3.7 1.3 2.05 2.1 3.3.05.1.1.15.15.2.3.45 1.4 2.2 2.7 4.5.2.35.4.75.15-.05.25-.05.25-.05.55-.1 20.25-11.7 16.35-33.6 5.3-2.8 8.75-9.05 3.95-7.15 3.95-16.15 0-2.75-2.7-10.15-.4-3.5-.5-10.35-.1 2.85-.3 5.7l-.3 1.8M170.05 0q-.15.1-.3.3-.15.05-.25.2-.2.2-.45.4-5.15 4.8-6.7 7-.05.05-.05.15.1-.1.25-.2V7.8l.1-.1q.05-.05.15-.05l.4-.2v.05q-.5 1.95-.85 4.05-.55 3.4-.55 7.1 0 7.55 2.25 14.2 2.25 6.6 6.75 12.25l.05.05q1.05 1.3 2.2 2.6-1.7-3-3.95-8.25-2.25-5.3-2.4-16.95-.15-11.65 1.45-17.05 1.55-5.45 1.75-5 .15.45.15-.5z"
                    id="HaiMed018__Symbol_43_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed018__Symbol_74_0_Layer0_0_FILL"
                transform="translate(85.65 43.7)"
                id="HaiMed018__col1n"
            />
            <use
                xlinkHref="#HaiMed018__Symbol_43_0_Layer0_0_FILL"
                transform="translate(91.2 98.45)"
                id="HaiMed018__col1d"
            />
        </g></g>
    )
}

export default HaiMed018
