import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D9D9D9"
}

function AccJew046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.8 7.85q.4-2.9-1.4-5.2Q10.6.35 7.7.05q-2.95-.4-5.2 1.4-.15.1-.3.25-.4 1.5 1.3 1.65l.2-.2q1.65-1.3 3.7-1.05 2.05.25 3.35 1.9 1.3 1.65 1.05 3.7-.3 2-1.95 3.3-1.65 1.35-3.65 1.15-2-.25-3.3-1.95-1.1-1.4-.95-2.1-1.1-.95-1.95.4.3 1.55 1.3 2.9 1.8 2.3 4.65 2.6 2.95.4 5.25-1.4 2.25-1.85 2.6-4.75z"
                    id="AccJew046__Symbol_114_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew046__Symbol_114_0_Layer0_0_FILL"
                transform="translate(230.95 125.4)"
                id="AccJew046__col1n"
            />
        </g></g>
    )
}

export default AccJew046
