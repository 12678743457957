import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function FacEar002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.1 6q-1.8-1-4.2-2.45Q19.55 1 15.7.35h-.1q-2.6-.4-4.95 0h.1q-1.6.3-3.1.95-7.1 3.05-7.6 9.6t2.5 11.65q1.95 3.3 8.15 12.15 1 1.55 1.65 3.6v.25q.35 3.7 1.45 8.85.1.95.25 2.1.35 1.95 1.1 3.4 1.6 3.85 8.45 5 .65.1 1.25.15 1.05.1 2 0 1.1-.1 2.1-.45 4.25-1.55 6.6-5.15.35-.55.65-1.1Q30.55 39 28.8 25.1q-.8-6.05-.85-12.3 0-.05-.05-.1l.2-6.7m216.6 16.4q3-5.1 2.5-11.65-.5-6.55-7.6-9.6Q232.5-2 223.35 3.4q-2.4 1.45-4.2 2.45l.2 6.7q-.05.05-.05.1-.05 6.25-.85 12.3 0 .05.05.1-.05.1-.05.25h-.05q-1.75 13.7-7.35 25.9.3.55.65 1.1 2.35 3.6 6.4 4.95 2.45 1 5.55.5 6.85-1.15 8.45-5 .75-1.45 1.1-3.4.15-1.15.25-2.1 1.1-5.15 1.45-8.85v-.25q.65-2.05 1.65-3.6 2.2-3.15 3.9-5.6 3-4.45 4.25-6.55z"
                    id="FacEar002__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M21.6 5.5q0-.05-.05-.1-.95-1.35-3.15-2.75Q14.3.15 9.8.15 5.75.15 3.45 1.3 0 3 0 7.45q0 3.6 1.85 4.45-.1-.3-.15-.6V9.4q0-3.2 1.25-4.7Q5.5 1.55 13.1 2.8q3.45 1.9 4.25 4.2.25.9.25 4.4 0 1.9-.05 2.9-.25.9-.7 1.6-.55.95-3.75 4-1.95 1.9-1.95 5.25 0 2.3 1 3.75.9 1.35 2.5 1.8.45.15.95.4 3.6 1.65 3.6 3.1 0 1.5-.8 2.95-1.15 1.9-3.05 1.9T12.9 37.8v.35q0 1.35 1.4 2.15 1.05.65 2.55.75 1.7-.15 3-.85 2.2-1.2 2.2-3.5 0-1.25-1.7-3.7-1.8-2.45-1.8-4.95 0-2.6 1.85-6.2 1.55-3.05 2.05-4.05-.8-6.05-.85-12.3m212.95 1.8q0-4.45-3.45-6.15Q228.8 0 224.75 0q-4.5 0-8.6 2.5-2.2 1.4-3.15 2.75-.05.05-.05.1-.05 6.25-.85 12.3 0 .05.05.1.05.1.15.25l1.85 3.7q1.85 3.6 1.85 6.2 0 2.5-1.8 4.95-1.7 2.45-1.7 3.7 0 2.3 2.2 3.5 1.3.7 3 .85 1.5-.1 2.55-.75 1.4-.8 1.4-2.15v-.35q-.55 1.25-2.45 1.25-1.9 0-3.05-1.9-.8-1.45-.8-2.95 0-1.45 3.6-3.1.5-.25.95-.4 1.6-.45 2.5-1.8 1-1.45 1-3.75 0-3.35-1.95-5.25-1.05-1-1.8-1.75-1.6-1.6-1.95-2.25-.45-.7-.7-1.6-.05-1-.05-2.9 0-3.5.25-4.4.8-2.3 4.25-4.2 7.6-1.25 10.15 1.9 1.25 1.5 1.25 4.7v1.9q-.05.3-.15.6 1.85-.85 1.85-4.45z"
                    id="FacEar002__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar002__Symbol_4_0_Layer0_0_FILL"
                transform="translate(68.8 142.25)"
                id="FacEar002__col1n"
            />
            <use
                xlinkHref="#FacEar002__Symbol_9_0_Layer0_0_FILL"
                transform="translate(75.15 149.55)"
                id="FacEar002__col1d"
            />
        </g></g>
    )
}

export default FacEar002
