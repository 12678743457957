import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.9.5q-.45.95-1.35 3.55-3.5-1.05-3.4-1.35l-.4 1q-.5 1.3-.85 2.1-1.95-1.6-2.3-2.5L19 5.6q-.45-.9-2-2.6-1.1.8-2.1 1.35-.9.45-.95.6Q13.6 2.8 13 1.8q-.4.3-2.85 1.4L9.2 0 0 16.8q.4 1.3.6 3.4.1-.15.4-.35.25-.25.85-.65t1-.65q.4-.25.6-.35.25-.2.45-.35.05.1.15.2.4.5.75.85h-.05q1 1.4 1.55 2.3.55 0 1.35-.4.8-.35 2.35-1.35.4-.25.7-.45.25.15.7.45.95.8 1.5 1.9.4.4.75 1.05l2.4-1.65q1.9 1.65 2.6 2.25.4-.5 1.45-1.45l.7-.65.1-.15q1.45.95 2.3 1.45.7.45.8.2h.05q.3 0 3.5-3.25.25.2.45.4.25.25 1.4.95l.35.2.2.1.9.5 2.2-3.6 3.9.8q-.3-.45-1.1-2.25L28.9.5z"
                    id="BeaChi009__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M30.85 9.05l2.2-3.6 3.9.8q-.3-.45-1.1-2.25l-.65-1.3L32.05.95q-1.55 2.3-1.6 2.3-.4 0-2.25-1.65-.4 0-1.8 1.25-1.45 1.25-1.9 1.4-.45.1-1.05-.65-.65-.8-1.1-.95-1.85 1.6-2.15 1.6-.3 0-2.5-1.65l-1.65 1.1q-1.3.75-1.65.8-.3.05-2.5-2.55V1.6q-1.75.55-2.1.75-1.5.9-2.05 1.15-.6.2-1.1-.8Q6.1 1.65 5.2 0 3 1.3 1.4 2L0 4.55q.4 1.3.6 3.4.1-.15.4-.35.25-.25.85-.65t1-.65q.4-.25.6-.35.25-.2.45-.35.05.1.15.2.4.5.75.85h-.05q1 1.4 1.55 2.3.55 0 1.35-.4.8-.35 2.35-1.35.4-.25.7-.45.25.15.7.45.95.8 1.5 1.9.4.4.75 1.05l2.4-1.65q1.9 1.65 2.6 2.25.4-.5 1.45-1.45l.7-.65.1-.15q1.45.95 2.3 1.45.7.45.8.2h.05q.3 0 3.5-3.25.25.2.45.4.25.25 1.4.95l.35.2.2.1.9.5z"
                    id="BeaChi009__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi009__Symbol_48_0_Layer0_0_FILL"
                transform="translate(174.7 232.65)"
                id="BeaChi009__col1n"
            />
            <use
                xlinkHref="#BeaChi009__Symbol_13_0_Layer0_0_FILL"
                transform="translate(174.7 244.9)"
                id="BeaChi009__col1d"
            />
        </g></g>
    )
}

export default BeaChi009
