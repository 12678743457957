import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35z"
                    id="AccGla033__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M60.45 20.15q2.25-8.3-.05-12.75-1.95-3.85-7.25-4.85-.8-.25-2.4-.5Q35.85-.7 18.5.85-.9 2.6.1 12q-.25-1.35.05 2.25Q.9 20.2.9 20.3q.15 1.35.3 2.55.05.45.15.95l.1.7q0 .1.05.2.05.15.15.35 2.7 10.65 22.8 12.55l1.3.1q.7.05 1.35.2h.7q17.1.6 25.25-4.75 5.2-3.45 7.4-13m66.9 5.1q.1-.2.2-.35.05-.15.05-.25.05-.35.1-.65.1-.55.15-1 .15-1.25.25-2.6 0-.1.8-6.15.3-3.7.05-2.3 1-9.6-18.75-11.4Q92.55-1 77.3 1.8q-1.55.25-2.4.5-5.4 1-7.4 4.95-2.35 4.5-.05 13Q69.7 29.95 75 33.5q8.3 5.45 25.75 4.8h.65q.7-.1 1.4-.15l1.3-.1q20.5-1.95 23.25-12.8z"
                    id="AccGla033__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192.45.15q-.35-.2-.75-.1l-1.1.25h-.05l-22.1 4.85Q145.3.6 122.15 1.05q-14.55.85-23.05 5.4-.35.2-.75.4h-3.8q-.4-.2-.75-.4-8.5-4.55-23.05-5.4Q47.45.6 24.15 5.2L2.4.3h-.05L1.25.05q-.4-.1-.75.1Q.1.35.05.8q-.1.4.1.75.1.2.35.35.1.05.3.1l23.4 6.25.05.8q.2.35.7.5 1.2.3 1.65.65.4.3.9.75.5.4.8 1.05l1.15 2.8.45 4.4q1.3 5.35 1.85 7.4.8 3.55 2.2 6.25 5.2 6.85 19.9 8.5 4.95.35 9.3.35 9.35-.1 16.05-1.95 1.15-.3 2.05-.7 3.75-1.6 6.45-3.75 6.45-6.25 8.15-22-.1-1.7-.35-3.3h1.9q-.25 1.6-.35 3.3 1.7 15.75 8.15 22 2.7 2.15 6.45 3.75.9.4 2.05.7 6.7 1.85 16.05 1.95 4.35 0 9.3-.35 14.7-1.65 19.9-8.5 1.4-2.7 2.2-6.25.55-2.05 1.85-7.4l.45-4.4 1.15-2.8q.3-.65.8-1.05.5-.45.9-.75.45-.35 1.65-.65.5-.15.75-.55.2-.35.3-.7L192.15 2q.4-.1.65-.4v-.05q.2-.35.1-.75-.05-.45-.45-.65m-33.05 14.5q.25-1.3-.05 2.2-.75 5.7-.75 5.8-.1 1.3-.25 2.45-.05.45-.15.95-.05.3-.1.65 0 .1-.05.2-.05.15-.15.35-2.6 10.25-22 12.1l-1.25.1q-.65.05-1.3.15h-.65q-16.5.6-24.35-4.55-5-3.35-7.15-12.55-2.15-8 .05-12.3 1.9-3.7 7-4.65.8-.25 2.3-.5 14.4-2.65 31.1-1.15 18.7 1.7 17.75 10.75m-77.05-9.6q1.5.25 2.3.5 5.1.95 7 4.65 2.2 4.3.05 12.3-2.15 9.2-7.15 12.55Q76.7 40.2 60.2 39.6h-.65q-.65-.1-1.3-.15l-1.25-.1q-19.4-1.85-22-12.1-.1-.2-.15-.35-.05-.1-.05-.2-.05-.35-.1-.65-.1-.5-.15-.95-.15-1.15-.25-2.45 0-.1-.75-5.8-.3-3.5-.05-2.2Q32.55 5.6 51.25 3.9q16.7-1.5 31.1 1.15z"
                    id="AccGla033__Symbol_121_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla033__Symbol_94_0_Layer0_0_FILL"
                transform="translate(191.3 155.25)"
                id="AccGla033__col2n"
            />
            <use
                xlinkHref="#AccGla033__Symbol_122_0_Layer0_0_FILL"
                transform="translate(128.25 150.6)"
                id="AccGla033__col2l"
            />
            <use
                xlinkHref="#AccGla033__Symbol_121_0_Layer0_0_FILL"
                transform="translate(96.05 148.25)"
                id="AccGla033__col1n"
            />
        </g></g>
    )
}

export default AccGla033
