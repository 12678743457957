import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#411F00",
    "col2n": "#773900"
}

function LegPan029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.6 5.75q12.3.65 0-1.05-10.15-1.1-20.3-2.8L3.45.65Q2.5.5 1.6.3L.65.1Q.35.05 0 0v1.8l30.6 3.95m95-3.75V.3q-15.75 3-31.35 4.55-8.45 1.75 0 1.25L125.6 2z"
                    id="LegPan029__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M63.65 1.4V.15Q31.55 3.25 0 0v1.05l3.55 8.1q28.8 4 57.55 0l2.55-7.75z"
                    id="LegPan029__Symbol_66_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan029__Symbol_65_0_Layer0_0_FILL"
                transform="translate(129.6 310.25)"
                id="LegPan029__col1n"
            />
            <use
                xlinkHref="#LegPan029__Symbol_66_0_Layer0_0_FILL"
                transform="translate(160.2 314.95)"
                id="LegPan029__col2n"
            />
        </g></g>
    )
}

export default LegPan029
