import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF98D0",
    "col1n": "#FFCDE8"
}

function AccMor049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M142 4.8V3.2q0-2.1-1.45-3.2-1.3 1-2.7 1.95-.55.35-1.05.7-.6.35-1.15.75-7.35 4.55-17.25 8.05-.75.3-1.5.55-14.2 4.95-27.8 7.05-.2 0-.35.05h-.05l.3-.1q-.2 0-.35.05-.5.05-1 .15l-3 .45q-.15-.35-.3-.65-.8-1.1-1.15-1.4-1.85-1.75-4.95-1.75-1.45 0-3.35.4-.55.1-1 .2h-2.55q.1.05.2.15-3.1-.25-4.2-.25-1.35-.1-1.85 0-.6.05-2.5.9-2.3.95-3 2.95h-.4Q40 18.55 21.75 10.5q-5.1-2.3-10.05-5-2.6-1.65-5.25-3.4l-.5.65q-.3.25-.5.55-.85 1-.85 2.4 0 .4.45 1.5.4 1.1.7 1.4.25.3.85.8.6.45 1.2.8-.15 0-.8.15t-.95.3q-.35.15-.65.2-.1 0 0 .1-3.5.8-4.7 3.55-.3.65-.45 1.35-.65 2.75.35 7 .55 2.25 2.65 3.55.45.25 1.05.5.35.15.75.3Q26.9 34.55 54 36.15h.5q.1.25.25.5 2.35 4.5 6.35 6.2h.05q.05 0 .1.05l-.1 37.9q11.3 4.75 22.45 1.3v-17h.5q2.7-.1 5.5-.4-.75-2.8-.75-6 0-9.7-.6-19.2-.1-1.75-.25-3.5 1.8.2 3.6.45 1.8.2 16.7-3.2 14.85-3.4 26.5-7.35 11.6-3.95 11.7-8.6v-.35q-.05-.95-.2-1.95-.3-.8-.6-1.65-.15-.3-.25-.6-1.35-3.25-4.9-2.95.65-.7 1-2.25-.1.2.15-.75.25-1 .3-2z"
                    id="AccMor049__Symbol_146_0_Layer0_0_FILL"
                />
                <g
                    id="AccMor049__Symbol_88_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M20.6 9.2q7.9 2.65 12.2 3.2 4.25.6 3.8.45l-2.1-.7q-4.85-1.65-12.25-4-2.6-.85-5.15-2.05Q12.4 3.9 7.9.55 7.75.4 7.55.45q-.15 0-.8.15T5.8.9q-.35.15-.65.2-.1 0 0 .1-3.5.8-4.7 3.55Q.15 5.4 0 6.1q4.75-3.7 12.1-.3l.35.15q.15.1.3.15Q16 7.65 20.6 9.2m53.25-2.5H71.1q.1.05.2.15 4.8 4.5 6.05 15.3 1 9.048 1.05 13.15 0-.05.05-.1.15-.15.15-.35.45-7.95-.5-15.65-.95-7.85-4.25-12.5m8.7 48.6h-1.1q-1.7-.05-3.35-.2-1.85-.2-3.6-.5-2.25-.4-4.4-.95-.25-.1-.45-.15-.4-.1-.3-1.5v-.1q1.5-21.05-3.3-34.6-.35-1-.75-1.7t-.8-1.3q-.85-1.2-1.7-1.9-1.3-1.1-2.75-1.7.15.1.35.25.75.5 1.1.85 1.15 1.15 1.75 2.55.8 1.85 1.1 3.3.3 1.45.3 3.45 0 1.45-.35 2.8 0-.05-.05-.05-.8 2.1-2.85 2.5-2.05.35-3.2.25-1.2-.1-1.45-.1-.75-.05-1.55-.05-.45-.05-.85-.05h-.1q.1.25.25.5 2.35 4.5 6.35 6.2h.05q.05 0 .1.05 2.5 1.1 4.55 1.05 1.85 3.6.4 22.95 9.35 2.55 17.4 2.3v-4.1h.5l-.6-.05h-.7M88.5 9.45l-.2.05-.05-.05q.1-.05.25-.05l-.05-.05.3-.1q-.2 0-.35.05-.5.05-1 .15l-3 .45q1.75 4.05 2.7 9.55.25 2.7.45 5.45.05 0 .1.05l.1 1.3q1.8.2 3.75.25l-.15-2.45v-.4q0-6.15-1.85-11.4l-1-2.8m-3.65.45l.05.45q-.15-.25-.25-.4.1-.05.2-.05M139.7.1V.05q-2.3.65-10.2 4.2-7.95 3.6-10.85 4.4-2.95.8-6.35 2.15-2.044.743-2.9 1.15h.05q.15.1 5.4-1.1 5.2-1.25 9.45-2.8 1.95-.75 7.15-2.8 8.35-3.3 14-1.65-.15-.3-.25-.6-1.45-3.5-5.5-2.9z" />
                    <path d="M84.9 10.35l-.05-.45q-.1 0-.2.05.1.15.25.4m3.4-.85l.2-.05.1-.05h-.1q-.15 0-.25.05l.05.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccMor049__Symbol_146_0_Layer0_0_FILL"
                transform="translate(120 221.15)"
                id="AccMor049__col1n"
            />
            <use
                xlinkHref="#AccMor049__Symbol_88_0_Layer0_0_FILL"
                transform="translate(120.25 230.9)"
                id="AccMor049__col1d"
            />
        </g></g>
    )
}

export default AccMor049
