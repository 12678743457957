import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#617433"
}

function SkiMak013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M109.5 44.1q6.1-28.5-7.6-37.8-3.3-2.3-7.1-3.75-1.55-.65-3.15-1.1-10-2.85-20 0-5.8 1.9-10.05 6.6-4.25 4.45-5.9 10.9-1.65-6.45-5.9-10.9-4.25-4.7-10.05-6.6-10-2.85-20 0-1.6.45-3.15 1.1Q12.8 4 9.5 6.3q-13.7 9.3-7.6 37.8 1.65 6.4 5.4 10.4l.6.6.7.65q10.35 9.65 23.9 4.15Q38.3 58 44.05 53q.65-.55 1.25-1.1 7.95-7.8 10.4-21.5 2.45 13.7 10.4 21.5.6.55 1.25 1.1 5.75 5 11.55 6.9 13.55 5.5 23.9-4.15l.7-.65.6-.6q3.75-4 5.4-10.4z"
                    id="SkiMak013__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak013__Symbol_19_0_Layer0_0_FILL"
                transform="translate(136.8 133.95)"
                id="SkiMak013__col1n"
            />
        </g></g>
    )
}

export default SkiMak013
