import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2l": "#333333",
    "col2n": "#000000"
}

function ObjToy080({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M336.9 310.3q4.6 1.4 10.1 1.4 7 0 25 .15 17.95.15 84.75-3.75.3 0 .5-.25t.2-.55q0-.3-.25-.5t-.55-.2q-66.7 3.9-84.65 3.75-18-.15-25-.15-5.3 0-9.65-1.35-2.35-.75-7.35-3.05-9.85-4.6-20.7-4.6-6.6 0-14.85 1.75-8.05 1.65-11.15 1.65-11.95 0-19.5-3.85-.75-.35-1.35-.75-5.2-3-5.7-3.25-1.3-.6-2.65-.9-2.35-.65-5.1-.65h-.65q-.35 0-.75.05v1.5q.4-.05.75-.05h.65q2.55 0 4.7.6h.05q1.2.25 2.35.8.45.2 5.6 3.2h-.05q.7.4 1.5.8h-.05q7.85 4 20.2 4 3.2 0 11.45-1.7 8.05-1.7 14.55-1.7 10.55 0 20.05 4.45 5.15 2.35 7.55 3.15z"
                    id="ObjToy080__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M171.35 18.4q-1-.05-2.5.6-30 32.5-35.1 27.3v.7l-23.6-15.6-2.2-25.1q-.2-9.7-8.7-4.5Q53 30.6 21.8 60.45q-.2.2-.45.55-.25.3-2.5 2.55t-1.9 7.15q-.45 34.55-1.7 68.75-1.15 31.75-3 63.2-.1 1.45-.15 2.95Q8.3 280 .25 354.4q-1 6.3 2.4 7.65l68.4 10.3.75.35h.25l50.5 5.6q3.35.45 5.35-1.05 2.2-1.75 2.45-4.7l7.3-.4q8.45-2.5 16.9 4.25l13.5-4 .4-88.4q-.45-44.05-4.6-87.3v-.1l13-173.9q.1-3.2-2.4-4.05l-2.7-.25h-.4z"
                    id="ObjToy080__Symbol_146_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M33.45 218.15l-2.2-.85-3.6 114.25 3.1-.9 2.7-112.5m9.8-214.1V3.3Q43.2.3 41.4 0H41q-1-.05-2.5.6L3.35 17.7l.2.05L0 353q.05.6 0 1.15l7.3-.4L24.2 358h.1l2.3-97.1 4.4-96.2L43.25 4.05z"
                    id="ObjToy080__Symbol_147_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M140.15 17.2q-4.1-8.45-12.2-11.05L98.7 0l6.65 76.25 6.3 91.7 6.05 129.9 1.25 42.4q.2 3.75-2.45 5.85-2 1.5-5.35 1.05l-50.55-5.6h-.15Q29.7 335.6 9.8 320.05q-3.9 1.35-4.05 3L0 344.5q.1 3.75 9.55 5.55 40.05 6.3 85.2 2.8 22.15-1.4 31.7-5.4 2.1-1.35 1.35-3.85l-.85-2.4-7.15-1.8 11.15-.65q6.45.1 6.6-5.1 0-.6.05-.85l3.4-132.3 5.8-131.4q2.25-25.6-3.75-42.85-1-5.15-2.9-9.05z"
                    id="ObjToy080__Symbol_148_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M112.85 161.35l.35 11.45 12-.7q6.45.1 6.6-5.1 0-.6.05-.85l.05-5.85-19.05 1.05M123.5 146v-16.7q-1.45-.9-2.9 0V146q1.65 1.1 2.9 0m0-25.2v-12.5q-1.45-1.45-2.9 0v12.5q1.45 1.15 2.9 0M4.05 153.4q-3.9 1.35-4.05 3-.2 1.75 3.95 3.85 25.6 11.9 50.7 14.65l-50.6-21.5M125.1 10.05V.8q-2.65-1.6-5.35 0v9.25q2.7 1.3 5.35 0m-1.35 17.7v-8.3q-1.45-1.45-2.9 0v8.3q1.45 1.15 2.9 0z"
                    id="ObjToy080__Symbol_149_0_Layer0_0_FILL"
                />
                <path
                    fill="url(#ObjToy080__Gradient_1)"
                    d="M332.8 29.2q-1-5.15-2.9-9.05l-40.65-8.25 5.85 67.3 2.7 38.9 36.4 7.5 2.35-53.55q2.25-25.6-3.75-42.85z"
                    id="ObjToy080__Layer4_0_FILL"
                />
                <linearGradient
                    id="ObjToy080__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={313.25}
                    y1={19.162}
                    x2={313.25}
                    y2={118.338}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFF" stopOpacity={0.502} />
                    <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
                </linearGradient>
            </defs>
            <use
                xlinkHref="#ObjToy080__Layer13_0_FILL"
                transform="translate(92 24)"
            />
            <use
                xlinkHref="#ObjToy080__Symbol_146_0_Layer0_0_FILL"
                transform="matrix(.28941 0 0 .28941 320.55 223.6)"
                id="ObjToy080__col1n"
            />
            <use
                xlinkHref="#ObjToy080__Symbol_147_0_Layer0_0_FILL"
                transform="matrix(.28941 0 0 .28941 358.3 228.9)"
                id="ObjToy080__col1d"
            />
            <use
                xlinkHref="#ObjToy080__Symbol_148_0_Layer0_0_FILL"
                transform="matrix(.28941 0 0 .28941 323.85 232.6)"
                id="ObjToy080__col2n"
            />
            <use
                xlinkHref="#ObjToy080__Symbol_149_0_Layer0_0_FILL"
                transform="matrix(.28941 0 0 .28941 325.55 280.85)"
                id="ObjToy080__col2l"
            />
            <use
                xlinkHref="#ObjToy080__Layer4_0_FILL"
                transform="matrix(.28941 0 0 .28941 268.95 231.75)"
            />
        </g></g>
    )
}

export default ObjToy080
