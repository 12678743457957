import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1n": "#E4B670",
    "col2n": "#FF5D5D",
    "col2s": "#A80F0F"
}
function FacMou097({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M25.4-.95H0V4.2q.25.055.5.3.3.284 6.2 2.65 5.95 2.365 12.15-.25 6.25-2.555 6.5-2.6h.15q.05-.05.1-.05-.097-.101-.2-.2v-5z"
                    id="FacMou097__Symbol_159_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M48.2-3.35H10.65l-11.6 6 8.4 9.6h8.15v-4.3q1-1 2.6-1.9 2.45-1.3 5.45-1.85l.6-.1q1.95-.3 4.1-.3 2.15 0 4.15.3.25.05.55.1 3 .55 5.45 1.85 1.517.827 2.5 1.8v4.4h5.15l12.3-9.1-10.25-6.5z"
                    id="FacMou097__Symbol_160_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M45.1 3.65q-.1-.05-.25-.15-2.4-1.3-4.95-2.25-8.7-2.5-17.6 0-2.55.95-4.95 2.25-.15.1-.25.15Q4.8 11.2 1.3 22.05q-.8 3.05-1.1 6-.3 2.8-.15 5.5.15 4 1.25 7.75.15.55.35 1.1.35.95.8 1.85.85 2 1.85 3.85 6.45 10.6 18.65 14.15 9.45 2.1 19.5-1.05 9.35-3.7 14.9-12.1 1.35-2.25 2.45-4.85.4-.9.75-1.85.2-.55.35-1.1 1.1-3.75 1.3-7.75.1-2.7-.15-5.5-.35-2.95-1.15-6-3.5-10.85-15.8-18.4m14.55 32.4q-1.7.15-3.6.85-1.4.45-3.35 1.3-.55.15-2.5 1.05-.6.2-1.5.55-2.45.8-4.35 1.25l-.5.1h-.1q-.05 0-.1.05h-.15q-.25.05-.65.1-12.05 2.75-23.5 0-.4-.05-.65-.1-.3-.05-.55-.1h-.05q-.15-.05-.25-.05-1.9-.45-4.35-1.25-.9-.35-1.5-.55-1.95-.9-2.5-1.05-1.95-.85-3.35-1.3-1.9-.7-3.6-.85h2.9q5.8-.2 10.1-1.3 3.25-.8 6-1.2 9.55-.85 19.1 0 2.75.4 6 1.2 4.3 1.1 10.1 1.3h2.9z"
                    id="FacMou097__Symbol_158_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6 42.75q.45.15.95.25 2.65.55 5.45.55 2.7 0 5.35-.55.5-.1.95-.25 2.95-.8 3.6-1.45l.05-.05q-.25.05-.5.05-4.1.5-8.25.55h-1.2q-5.3-.05-9.55-.55-.25 0-.5-.05l.05.05q.65.65 3.6 1.45m16.6-41.1q-.2-.2-.45-.35-.1-.05-.15-.1-3.45-2.25-6.9-.35-.4.2-.75.45-.95.6-1.3 1.25 0-.05-.05-.05-.55.75-.6 1.35-.05 0-.05-.05 0 .05-.05.05-.05-.6-.6-1.35-.05 0-.05.05-.35-.65-1.3-1.25-.4-.25-.75-.45-3.45-1.9-6.85.35l-.2.1q-.25.15-.45.35L1.75 2Q.65 3.3.2 4.75q-.1.35-.2.75v.05Q0 7.4 1.05 9.3q.2.4.4.75.5.5.95.8.45.25.65.35l.3.2q-.45-.75-.7-1.25-.15-.05-.3-.45-.25-.55-.45-1.25-.05-.2-.1-.45-.1-.65-.1-1.6v-.15q0-.4.1-.8.15-.9.75-1.65 1-1.35 2.9-1.85H7q2.8.8 3.85 2.85.15.05.3.35l.4 1q.2.65.45 2.3.1.75.5 2.7.05-.05.1 0v-.45q0-1.45.45-3.45.4-2 .8-2.6.4-.6 1.25-1.4.8-.8 2.6-1.3h1.55q1.9.5 2.9 1.85.6.75.8 1.65.05.4.05.8v.15q0 .95-.1 1.6-.05.25-.1.45-.2.7-.45 1.25-.2.45-.3.5l-.6 1.2q1.3-.85 1.8-1.3 1-1 1.15-1.65.1-.3.1-1.75 0-.4-.05-.9t-.15-1.1l-.1-.75q-.45-1-1.25-1.95l-.35-.35z"
                    id="FacMou097__Symbol_157_0_Layer0_0_FILL"
                />
                <path
                    d="M186.5 195.9q-.8-.35-1.5.05-.7.4-.85 1.3-.05 1 .5 1.95t1.4 1.3q.9.45 1.6.05.7-.4.75-1.4.1-.9-.45-1.85-.55-.95-1.45-1.4m13.5.05q-.7-.4-1.5-.05-.9.45-1.45 1.4-.55.95-.45 1.85.05 1 .75 1.4.7.4 1.6-.05.85-.35 1.4-1.3.55-.95.5-1.95-.15-.9-.85-1.3z"
                    id="FacMou097__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou097__Symbol_159_0_Layer0_0_FILL"
                transform="translate(179.75 223.25)"
                id="FacMou097__col2n"
            />
            <use
                xlinkHref="#FacMou097__Symbol_160_0_Layer0_0_FILL"
                transform="translate(164.15 219.45)"
                id="FacMou097__col2s"
            />
            <use
                xlinkHref="#FacMou097__Symbol_158_0_Layer0_0_FILL"
                transform="translate(161.6 186.35)"
                id="FacMou097__col1n"
            />
            <use
                xlinkHref="#FacMou097__Symbol_157_0_Layer0_0_FILL"
                transform="translate(180.35 191.25)"
                id="FacMou097__col1d"
            />
            <use xlinkHref="#FacMou097__Layer1_0_FILL" />
        </g></g>
    )
}
export default FacMou097
