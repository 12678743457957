import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col2d": "#6486A6",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.15.35Q1.75 0 1.25 0T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4q.35-.35.35-.85t-.35-.9z"
                    id="AccGla008__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M138.55 11.8V0H0v11.35l2.1 2.1v35.2h56.15q.65-.15.75-.65l10.25-20.5.25.5.25-.5L80 48q.1.5.75.65h56.15v-35.2l1.65-1.65z"
                    id="AccGla008__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.502}
                    d="M134.8 47V1.25h-1.9v42.5H79.45L68.9 22.3V1.25q-1.5-2.45-3 0V22.3L55.35 43.75H1.9V1.25H0V47h56.15q.65-.15.75-.65l10.25-20.5.25.5.25-.5 10.25 20.5q.1.5.75.65h56.15z"
                    id="AccGla008__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192 11.75v-5.6L163.15 0H28.85L0 6.15v5.6l28.6 4.3V5.5h134.8v10.55l28.6-4.3m-6.25-2q-.35-.35-.35-.85t.35-.85q.35-.35.85-.35t.85.35q.35.35.35.85t-.35.85q-.35.35-.85.35t-.85-.35M173.4 8.8q0 .9-.65 1.55t-1.55.65q-.9 0-1.5-.65-.65-.65-.65-1.55t.65-1.5q.6-.65 1.5-.65t1.55.65q.65.6.65 1.5m4.85-1.1q.45-.45 1.15-.45.65 0 1.1.45.5.45.5 1.15 0 .65-.5 1.1-.45.5-1.1.5-.7 0-1.15-.5-.45-.45-.45-1.1 0-.7.45-1.15M20.8 6.65q.9 0 1.5.65.65.6.65 1.5t-.65 1.55q-.6.65-1.5.65t-1.55-.65q-.65-.65-.65-1.55t.65-1.5q.65-.65 1.55-.65m-8.2.6q.7 0 1.15.45.45.45.45 1.15 0 .65-.45 1.1-.45.5-1.15.5-.65 0-1.1-.5-.5-.45-.5-1.1 0-.7.5-1.15.45-.45 1.1-.45M4.2 8.9q0-.5.35-.85t.85-.35q.5 0 .85.35t.35.85q0 .5-.35.85t-.85.35q-.5 0-.85-.35T4.2 8.9z"
                    id="AccGla008__Symbol_31_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla008__Symbol_99_0_Layer0_0_FILL"
                transform="translate(191.3 140.95)"
                id="AccGla008__col2n"
            />
            <use
                xlinkHref="#AccGla008__Symbol_78_0_Layer0_0_FILL"
                transform="translate(123.1 142)"
                id="AccGla008__col2l"
            />
            <use
                xlinkHref="#AccGla008__Symbol_63_0_Layer0_0_FILL"
                transform="translate(125.2 143.65)"
                id="AccGla008__col2d"
            />
            <use
                xlinkHref="#AccGla008__Symbol_31_0_Layer0_0_FILL"
                transform="translate(96.6 139.4)"
                id="AccGla008__col1n"
            />
        </g></g>
    )
}

export default AccGla008
