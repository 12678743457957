import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function AccMor048({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M141.9 8.2l.1-.05V7.9q.1-.3.15-.55-.3-.85-.65-1.7-.6-1.5-1.55-2.65-4.15-5.15-9.45-1.5-.45.35-.85.75-3.9 4-8.85 6.55-3.65 1.85-7 4.25-.25.05-.4.15l-1.1.5q-8 3.6-16 5.7-7.25 1.95-14.5 2.65-6.5.65-13 .35-11.05-.55-21.9-3.85-.7-.3-.8-.2h-.05q-3.4-4.15-7.3-3.85l-.1.05q-.55.05-1.05.25-.35.15-.6.15h-.15q-.05.05-.05.1-.05 0-.05.05-.1-.05-.1.05-.05-.15-.05-.3-1.25-4.8-6.85-6-2.1-.35-4.1.6h-.4q-.05 0-.1-.05l-.05.05q-.3-.35-.65-.7-1.55-1.65-3.6-3.15-2.1-1.5-4.3-3.15Q16 2 15.4 1.65q-4.15-2.7-6.65.15Q1.1 9.35 0 19.9q-.25 2 3.3 2.55 15.5 3.6 21.15 5.95l.3.1q.05.05.1.05v.05q.3.1.4.45.05.1.05.25h.05l-.3 55.95h2.3V72.5h2.5v.1h-.2l-.1 12.65h2.3V72.5h2.5v.1h-.2l-.1 12.65h2.3V72.5h2.5v.1h-.2l-.1 12.65h2.3V72.5h2.45v.1h-.15l-.1 12.65h2.3V72.5h-.05V60h2.05v.1h-.2l-.1 12.65h2.3V60h2.5v.1h-.2l-.1 12.65h2.3V60h2.5v.1h-.2l-.1 12.65h2.3V40q15.55 3.05 34.4 2.25 9.55-.85 16.1-3.55h.05l-.1-.1q.45-.1.8-.3 3.15-1.65 6.05-3.55.95-.6 1.85-1.25 1.1-.85 2.15-1.7h.1q.15-.2.3-.3.65-.25 1.4-.4 3-1.15 6.3-2.45 1.6-.65 3.05-1.25l10.95-6.35q2.45-1.8 1.5-5.5-.35-.55-.55-1.15-.15-.4-.15-.85-1-1.6-1.55-3.4-.05-.1-.05-.2.05-.15.15-.3l.6-1.1q.1-.2.2-.35z"
                    id="AccMor048__Symbol_145_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.8 62.85h-2v.1h-.2l-.1 12.65h2.3V62.85m2.5 0v.1h-.2L18 75.6h2.3V62.85h-2m-13.5.1h-.2L4.5 75.6h2.3V62.85h-2v.1m-4.5 0H.1L0 75.6h2.3V62.85h-2v.1m9 0h-.2L9 75.6h2.3V62.85h-2v.1m24-32.6q-6.5-1.3-12.15-3.05l-.9-.2v23.25h2.05v.1h-.2L22 63.1h2.3V50.35h2.5v.1h-.2l-.1 12.65h2.3V50.35h2.5v.1h-.2L31 63.1h2.3V30.35M116.05 0q-.15.25-.25.45-1.9 3.05-4.8 5.25-2.15 2-5.2 3.05-.35.1-.65.3-3.35 2.25-5.3 6.05-1.95 4-5.15 7.05-.05 0-.1.05.95-.3 2-.55 3-1.15 6.3-2.45 1.6-.65 3.05-1.25l10.95-6.35q2.45-1.8 1.5-5.5-.35-.55-.55-1.15-.15-.4-.15-.85-1-1.6-1.55-3.4-.1-.35-.1-.7z"
                    id="AccMor048__Symbol_87_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M13.75.65L13.7.7q-.3-.35-.65-.7v7.75q-.3.6-1.75.35-.45-.1-.8-.15-.5-.15-.85-.2Q8.15 7.5 0 3.45q6.55 4.8 11.2 5.9.4.05.8.15.7.3-.6 1.35-.1.1-.2.15-.75.55-1.35.85-1.35.6-2.4.8-1.05.15-2.5.45-1.5.25 1.65.15 3.1-.15 4.4-.85 1.25-.65 1.65-.95.35-.3.4-.2v8.4l.3.1q.05.05.1.05v.05q.3.1.4.45V.7q-.05 0-.1-.05m13.6 5.15v-.05l-.1.05q-.55.05-1.05.25-.35.15-.6.15h-.15q-.05.05-.05.1-.05 0-.05.05-.1.05-.15.15-.05.1-.85 1.45-.8 1.3-.85 3.45-.05 2.15.45 2.9.45.7.5.85h.05v-.05q0-.05-.05-.5-.05-.5-.05-1.5-.05-1 .15-2.05.25-1.1.4-1.5.15-.4.85-1.5.55-.85 1.55-2.25m.2-.05q-.1 0-.2.05l-.45.65q.216-.263.45-.45l.25-.2q-.05 0-.05-.05m33.3 17.1Q46.2 20.7 41.8 19.6q-4.45-1.1-5.9-1.65-1.5-.6-1.3-3.4.15-2.8.9-4.75-.7-.3-.8-.2h-.05q-.1 0-.45 1.35-.35 1.3-.65 6.4L33.9 28l.9.2-.2-8.65q.05-.65.55-.75.65.15 1.35.35 2.6.6 6.4 1.55 3.75.95 9.7 1.75 5.95.8 8.25.4z"
                    id="AccMor048__Symbol_48_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor048__Symbol_145_0_Layer0_0_FILL"
                transform="translate(119.05 226.1)"
                id="AccMor048__col1n"
            />
            <use
                xlinkHref="#AccMor048__Symbol_87_0_Layer0_0_FILL"
                transform="translate(144.1 235.75)"
                id="AccMor048__col1d"
            />
            <use
                xlinkHref="#AccMor048__Symbol_48_0_Layer0_0_FILL"
                transform="translate(130.45 234.85)"
                id="AccMor048__col1s"
            />
        </g></g>
    )
}

export default AccMor048
