import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col1s": "#121212"
}
function ObjMus046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M72.9 25.8q.35-1.3 1.25-3.1.9-1.85-.8-2.45L0 91l2.5 2.55L72.9 25.8m7.25 6.4q-1.05-3-3.7-1.4L6.75 97.9l2.5 2.55 70.9-68.25m7.4-2.7q-.5 0-2.6.35t-1.1 2.05l58 24.55q2.45-1 1.4-3.35l-55.7-23.6m58 18.05q3.4-1.2 1.4-3.3L88.75 19.5q-1.65 1.85-.3 3.05 1.35 1.2 1.35 1.3l55.75 23.7m35.3 14.95l-.05.05q2.45-1.75 1.4-3.35l-29-12.3q-2.55 1.2-1.35 3.3l29 12.3m-3.8 8.85q2.8-1.15 1.4-3.3L149.5 55.8q-3.2 1.35-1.35 3.3l28.9 12.25M213.5 27.9l.3 4.8q9.95 2 19.85 0l.3-4.8q-10.7-5.15-20.45 0m-3.4 18.6h4.55q9.3.85 18.2 0h2.3v-2.75h-2.05q-9.2-.6-18.5 0h-1.75q-3.45.65-2.75 2.75m-2.95 1.6l-.1-1.25-.05.05-2.6-1.3-15.75 15.8q.3 2.7 1.95 2.95l16.45-16.2.1-.05M257.4.05q-.45-.05-.95-.05-.45 0-.9.05-.1 0-.2.05-.6.05-1.15.2l-.6.2-2.75.45q-2.15.5-3.5 2.15-5.1 6.8-7.2 15-1.55 10-.75 20 1.8 11.4 7.95 20.25 3.5 2.7 6.45 3.2l2.4-.25h1q.2 0 .45-.05 5.6-.8 9.65-8.95 4.55-8.95 4.55-21.65t-4.55-21.7q-4.1-8.3-9.9-8.9z"
                    id="ObjMus046__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.55 17.65q-1.2 1.2-1.85 2.6-.7 1.55-.7 3.4 0 1.1.25 2.15.55 2.2 2.3 3.95.6.6 1.25 1.05 1.65 1.15 3.7 1.4.55.05 1.1.05 1.35 0 2.6-.35 1.9-.65 3.45-2.15l.25-.25q2.2-2.4 2.25-5.65v-.2q0-2.3-1.05-4.15-.6-1-1.45-1.85Q12.1 15.1 8.6 15.1q-3.55 0-6.05 2.55m157.9 26.1L161 32.7h-19.85l.6 11.05h18.7m-.15 2.75h-18.4l.3 6.7h5.55v2.05h1.95v2q-.05 2.25-.2 4.8-.3 5.15-.6 8.85-.3 3.65-1.8 9.45-1.5 5.35-7.85 5.3-6.5-.1-10.1-7.9-3.95-8.4-4.5-9.25-2.3-3.6-5.5-3.55-.4 0-.8.05l-3.95-6q-1.65-2.9-3.95-1.8-.05 0-.15.05L108.35 62h.05l-4.5 10.5q-.1.6-.1 1-.05 1.95 3.65 1.5l12.05-2.45q3.35-.65 1.5-3.5l-1.7-2.6q2.35.15 4.1 2.85.5.85 4.4 9.1 4.05 8.75 11.45 8.75 7.55.05 9.3-6.4 1.55-6 1.85-9.75.3-3.7.6-8.85.15-2.6.2-4.85v-.05-2h2.05V53.2h6.7l.35-6.7m-20.95-3.9q-.2-.2-.45-.35l-.45-.45q-.15-.15-.35-.25-.25-.2-.5-.3-.65-.35-1.4-.4-.55 0-1.05.2-.4.15-.75.4l-3.4 3.4 2.45 2.45.05-.05.25.25-.05.05.55.55.05-.05.85.85 2.4-2.4h-.1l2.75-2.75h.1l.1-.1-1.05-1.05M179.3 0q-.5 0-.95.05-5.8.6-10 8.9-4.45 9-4.45 21.7t4.45 21.65q4.45 8.8 10.7 9v2.55h1.35Q179.15 83.1 165 97q-15.5 15.35-37.5 15.35-12.05 0-22.15-4.6h-.05Q96 103.95 88.25 97q-13.8-12.4-15.1-36.1l1.35.55 7.1-17.1-6.3-2.6-7.1 17.1 2.4 1q1.1 25.7 16 39 8.1 7.2 17.75 11.2h-.05q10.55 4.8 23.2 4.8 23 0 39.25-16.05 14.9-14.65 16.15-34.95h1.65V61.3h-1q-1 0-2-.3-1.3-.35-2.5-1.1-3.45-2.15-6.2-7.6-4.45-8.95-4.45-21.65t4.45-21.7q2.9-5.75 6.6-7.8 1-.55 2.1-.85.65-.2 1.35-.25h-2.65q-.5-.05-.95-.05m13 10.65q-3.55-8.2-8.6-8.2t-8.65 8.2q-3.55 8.25-3.55 19.85 0 11.6 3.55 19.8 3.6 8.25 8.65 8.25 5.05 0 8.6-8.25 3.6-8.2 3.6-19.8 0-11.6-3.6-19.85z"
                    id="ObjMus046__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 1.45q0 .6.4 1 .45.45 1.05.45.6 0 1-.45.45-.4.45-1T2.45.4q-.4-.4-1-.4T.4.4Q0 .85 0 1.45m106.1 42.4q-.4-.4-1-.4t-1.05.4q-.4.45-.4 1.05 0 .6.4 1 .45.45 1.05.45.6 0 1-.45.45-.4.45-1t-.45-1.05m39.75-25.95q.65-.7.65-1.7t-.7-1.7q-.7-.65-1.65-.65-1 0-1.75.65-.65.7-.65 1.7t.65 1.7q.75.7 1.75.7.95 0 1.65-.7h.05z"
                    id="ObjMus046__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M20.45 7.85v-.1q.05-2.1-.4-3.65-1.4-4.55-7.4-4.05H7.8Q1.8-.45.4 4.1-.05 5.65 0 7.75v14q-.05 1 .05 1.9H20.4q.1-.9.05-1.9V7.85z"
                    id="ObjMus046__Symbol_140_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus046__Symbol_137_0_Layer0_0_FILL"
                transform="translate(-132.35 196.85)"
                id="ObjMus046__col1n"
            />
            <use
                xlinkHref="#ObjMus046__Symbol_138_0_Layer0_0_FILL"
                transform="translate(-59.7 196.85)"
                id="ObjMus046__col1d"
            />
            <use
                xlinkHref="#ObjMus046__Symbol_139_0_Layer0_0_FILL"
                transform="translate(-52.65 219)"
                id="ObjMus046__col1l"
            />
            <use
                xlinkHref="#ObjMus046__Symbol_140_0_Layer0_0_FILL"
                transform="translate(81.1 201.1)"
                id="ObjMus046__col1s"
            />
        </g></g>
    )
}
export default ObjMus046
