import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F"
}

function ShiLon061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.25 1.4L22.4 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05v.2q3.85-3.3 9.65-3.4h1.25q.2 0 .45.05.6 0 1.2.1.15.05.35.1 5.45.75 8.9 4.55L19 69.2q-.55 2.1-1.05 4.2l-.9 3.6-.4 1.45v13.5l39 9.4V17.4Q41.15 13.35 28.6 4.65L27.05 3.5q-1.45-1.05-2.8-2.1m138.4 43.5q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-12.55 8.65-27 12.75v83.9l39.1-9.7V78.95q-.3-1.75-.8-3.15-.05-.15-.05-.2v-.05l-4-15.65h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5M109.8 77.5q-.05-.1-.05-.25H109.9l-.1.25m36.7 4.45q.1-.05.25-.05v.05h-.25z"
                    id="ShiLon061__Symbol_357_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.2 30.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L0 51.6v.2q0 .1.1.15V52q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4M8.8.7Q8.25.35 7.75.05L2.3 6.3q-.2.25-.2.55 0 .3.25.5.2.2.5.2h18.6q-1.25-.55-2.4-1.1H3.65l.1-.1H3.7L8.8.7M7.65.4L7.6.45 7.65.3v.1m15.7 8q-.6-.3-1.15-.5l-6.6 6.65h-.05l-1 1q-.1.05-.1.4l19.05 57.4V69.6L15.65 16.05l7.7-7.65m92.05 21.95v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35m-9.25-14.4q0-.35-.1-.4l-1-1H105L98.45 7.9q-.6.2-1.15.5l7.65 7.65-17.8 53.4v3.75l19-57.25m10.7-9.6l.1.1H101.6l-2.4 1.1h18.55q.3 0 .5-.2.25-.2.25-.5t-.2-.55L112.95 0l-1.1.7 5.05 5.65h-.05z"
                    id="ShiLon061__Symbol_356_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon061__Symbol_357_0_Layer0_0_FILL"
                transform="translate(110.05 228.6)"
                id="ShiLon061__col1n"
            />
            <use
                xlinkHref="#ShiLon061__Symbol_356_0_Layer0_0_FILL"
                transform="translate(132.2 234.1)"
                id="ShiLon061__col1s"
            />
        </g></g>
    )
}

export default ShiLon061
