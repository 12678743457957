import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1l": "#E4B670",
    "col1n": "#D19532",
    "col2d": "#773900",
    "col2n": "#AD5300"
}

function ObjToy015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.15 1.2q-4.3 1.15-8.3 3.4Q2.35 6 0 8.05l30.8 115 8.45 1.6q.25-.4.7-.7.25-.15 3.2-.25 2.9-.15 5.8-1.4 2.9-1.3 3.45-1.15l6.45-5.7L28.05.5q-3.15-.5-6-.5-4.65.05-8.9 1.2z"
                    id="ObjToy015__Symbol_109_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M28.05 0q-.95.15-1.9.2-6.4.85-12.5 2.55-6.2 1.65-12.1 4.1-.8.35-1.55.75l8.45 1.6q.25-.4.7-.7.25-.15 3.2-.25 2.9-.15 5.8-1.4 2.9-1.3 3.45-1.15L28.05 0z"
                    id="ObjToy015__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.9.5q-3.15-.5-6-.5Q4.25.05 0 1.2l31.3 117q6.1-1.7 12.5-2.55.95-.05 1.9-.2L14.9.5z"
                    id="ObjToy015__Symbol_68_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.1 3.75l-.95-3.5Q12.6 0 11.7 0 6.3-.25 1.1 2.8q-.15.1-.4.25-.45.3-.7.7l.95 3.5q.7 2.25 1.55 4.5.55 1.6 1.1 3.3 1.85-.2 3.95.15 6.9 1.2 8.15 6.85 1.05 2.3 1.05 5.15 0 3-1 5.85-1.65 4.7-5.45 5.3.5 2.7 1.05 5.45.6.8 1.95.9 5.05-.3 9.55-2.55 1.2-.85 1.3-1.75-1.65-4.75-3.15-9.55-4.05-12.4-5.95-22.45-.6-2.35-.95-4.65z"
                    id="ObjToy015__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M7.3 4.3q4.25-1.1 6.8-.55l-.95-3.5Q12.6 0 11.7 0 6.3-.25 1.1 2.8q-.15.1-.4.25-.45.3-.7.7l.95 3.5Q2.8 5.5 7.1 4.35q.1 0 .2-.05z"
                    id="ObjToy015__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy015__Symbol_109_0_Layer0_0_FILL"
                transform="translate(69.5 151.05)"
                id="ObjToy015__col1n"
            />
            <use
                xlinkHref="#ObjToy015__Symbol_88_0_Layer0_0_FILL"
                transform="translate(100.3 266.5)"
                id="ObjToy015__col1l"
            />
            <use
                xlinkHref="#ObjToy015__Symbol_68_0_Layer0_0_FILL"
                transform="translate(82.65 151.05)"
                id="ObjToy015__col1d"
            />
            <use
                xlinkHref="#ObjToy015__Symbol_24_0_Layer0_0_FILL"
                transform="translate(108.75 271.95)"
                id="ObjToy015__col2n"
            />
            <use
                xlinkHref="#ObjToy015__Symbol_3_0_Layer0_0_FILL"
                transform="translate(108.75 271.95)"
                id="ObjToy015__col2d"
            />
        </g></g>
    )
}

export default ObjToy015
