import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.55.75q-.15-.4-.45-.6-.35-.2-.7-.1L.75 6.7q-.4.1-.6.45-.2.3-.1.7.1.35.45.6.3.15.7.1L26.85 1.9q.35-.15.6-.45.15-.35.1-.7z"
                    id="FacMou004__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacMou004__col1n" transform="translate(178.8 221.05)">
                <use
                    xlinkHref="#FacMou004__Symbol_4_0_Layer0_0_FILL"
                    id="FacMou004__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacMou004
