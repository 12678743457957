import { AssetSvgProps } from "../../../shared/assets"

function PlaInd009({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="PlaInd009__Layer4_0_FILL">
                    <path
                        fill="#6B86AD"
                        d="M385 385V122.55H0V385h385m-90.7-222.4v-.1l.7.5-.7-.4z"
                    />
                    <path
                        fill="#42271C"
                        d="M385 122.55V0H0v122.55h385M225.05 97.7l-.25.05v-.05h.25m39.8 9.3q-.15-.2-.25-.4h.25v.4z"
                    />
                </g>
                <g id="PlaInd009__Layer3_0_FILL">
                    <path
                        fill="#84B94F"
                        d="M96.2 11.3q.1-.05.25-.15l-.05-.1q-.1.1-.2.25m17.05-.25l-.25.25q.15-.05.3-.15l-.05-.1m18.55.25q.15-.05.3-.15l-.05-.1-.25.25m222.15-.15l-.05-.1q-.1.1-.2.25.1-.05.25-.15m16.55.15q.15-.05.3-.15l-.05-.1-.25.25z"
                    />
                    <path d="M145.4 2.85V.15H90.55v2.7h9.6V5.5q.027 1.147.05 2.25-.05.1 0 .15V8.35q-.55.05-1.15.25-2 .6-3.35 1.95-1.4 1.4-1.5 3 .3-.8.95-1.45.5-.5 1.05-.8.1-.15.2-.25l.05.1q.95-.45 2.1-.45 1.1 0 2.05.45.7.35 1.3.95.7.7 1.05 1.5.3.75.35 1.6v.25q0 1.3-.6 2.35-.1.2-.25.4.15-.15.25-.3.5-.65.85-1.6.6-1.7.6-3.3 0-1.2-.05-1.45 0-.45-.15-.9-.2-1.45-1.45-2.05V5.5 2.85H117V5.5q.027 1.147.05 2.25-.05.1 0 .15V8.35q-.55.05-1.15.25-2 .6-3.35 1.95-1.4 1.4-1.55 3 .35-.8 1-1.45.5-.5 1-.8l.25-.25.05.1q.95-.45 2.05-.45 1.15 0 2.05.45.7.35 1.35.95.7.7 1.05 1.5.3.75.35 1.6v.25q0 1.3-.6 2.35-.15.2-.25.4.1-.15.25-.3.5-.65.8-1.6.65-1.7.65-3.3 0-1.2-.05-1.45 0-.45-.15-.9-.2-1.45-1.45-2.05V5.5 2.85h16.45V5.5q.027 1.147.05 2.25-.05.1 0 .15V8.35q-.55.05-1.15.25-2 .6-3.35 1.95-1.4 1.4-1.5 3 .3-.8.95-1.45.5-.5 1-.8l.25-.25.05.1q.95-.45 2.1-.45 1.1 0 2.05.45.7.35 1.3.95.7.7 1.05 1.5.3.75.35 1.6v.25q0 1.3-.6 2.35-.15.2-.25.4.1-.15.25-.3.5-.65.8-1.6.65-1.7.65-3.3 0-1.2-.05-1.45 0-.45-.15-.9-.2-1.45-1.45-2.05V5.5 2.85h7.25m239.6 0V.15h-36.95v2.7h9.6V5.5q.027 1.147.05 2.25-.05.1 0 .15V8.35q-.55.05-1.15.25-2 .6-3.35 1.95-1.4 1.4-1.5 3 .3-.8.95-1.45.5-.5 1.05-.8.1-.15.2-.25l.05.1q.95-.45 2.1-.45 1.1 0 2.05.45.7.35 1.3.95.7.7 1.05 1.5.3.75.35 1.6v.25q0 1.3-.6 2.35-.1.2-.25.4.15-.15.25-.3.5-.65.85-1.6.6-1.7.6-3.3 0-1.2-.05-1.45 0-.45-.15-.9-.2-1.45-1.45-2.05V5.5 2.85h14.5V5.5q.027 1.147.05 2.25-.05.1 0 .15V8.35q-.55.05-1.15.25-2 .6-3.35 1.95-1.4 1.4-1.55 3 .35-.8 1-1.45.5-.5 1-.8l.25-.25.05.1q.95-.45 2.05-.45 1.15 0 2.05.45.7.35 1.35.95.7.7 1.05 1.5.3.75.35 1.6v.25q0 1.3-.6 2.35-.15.2-.25.4.1-.15.25-.3.5-.65.8-1.6.65-1.7.65-3.3 0-1.2-.05-1.45 0-.45-.15-.9-.2-1.45-1.45-2.05V5.5 2.85H385z" />
                    <path
                        fill="#B09146"
                        d="M261.75 36.75v-8H0V92.4h261.75V58.85h-1.2v-22.1h1.2M385 149.25v-15.7H270.6v6l24.55 9.7H385m-194.75-3.35l-24.85-13H94.1l-25 13v3.35h122v-3.35h-.85M385 92.4v-62H279.15v62H385z"
                    />
                    <path
                        fill="#879CBC"
                        d="M0 302.8V385h363.25l-141.7-183.1h-33.2l-2.55-2.95-14.05-21.05h-74.4L0 302.8m105.75-117.45q6.83-1.467 15.6-1.95 1.728-.093 3.5-.15v.15h10.25v-.1q1.09.044 2.15.1 8.678.48 15.45 1.95 9.75 2.05 9.75 5t-9.75 5.05q-9.75 2.05-23.45 2.05-13.75 0-23.5-2.05-9.75-2.1-9.75-5.05 0-2.95 9.75-5m-94-53.9H0v35.2l11.75-5.15v-30.05m348.65 52l.9-.05q1.668-.091 3.4-.15v.15h10.15v-4.85h-64.6L385 235.5v-35.4l-6.8-2.9q-4.35.25-9.15.25-13.8 0-23.5-2.05-9.75-2.1-9.75-5.05 0-2.95 9.75-5 1.55-.4 3.3-.65 5.258-.864 11.55-1.25z"
                    />
                    <path
                        fill="#3C3C3C"
                        d="M121.35 183.4q-8.77.483-15.6 1.95-9.75 2.05-9.75 5t9.75 5.05q9.75 2.05 23.5 2.05 13.7 0 23.45-2.05 9.75-2.1 9.75-5.05 0-2.95-9.75-5-6.772-1.47-15.45-1.95-1.06-.056-2.15-.1v.1h-10.25v-.15q-1.772.057-3.5.15m239.95 0l-.9.05q-6.292.386-11.55 1.25-1.75.25-3.3.65-9.75 2.05-9.75 5t9.75 5.05q9.7 2.05 23.5 2.05 4.8 0 9.15-.25 3.553-.234 6.8-.6v-12.5q-3.966-.473-8.45-.75-.84-.012-1.7-.05v.1H364.7v-.15q-1.732.059-3.4.15z"
                    />
                    <path
                        fill="#728BAF"
                        d="M385 196.6q-3.247.366-6.8.6l6.8 2.9v-3.5m-10.15-18.05v4.75q.86.038 1.7.05 4.484.277 8.45.75v-5.55h-10.15z"
                    />
                    <path
                        fill="#673E2C"
                        d="M385 133.55v-32.3H261.75V97.7h-36.7l-.25.05v4h-6.55q-5.422.082-10.4 1.75-1.047.362-2.1.8-3.6 1.35-5.75 3.9-1.55 1.8-2.35 4.1v6.45H57.6v-5.6q-.9-4.25-4.65-6.75-1.2-.85-2.75-1.5-6.8-2.85-13.6-3.15h-7.4V97.7H11.75V201.9H64.8l21.65-24H171.75l14.05 21.05 2.55 2.95h109.9v-23.35H385v-29.3h-89.85l-24.55-9.7v-1.7-.4-3.9H385m-219.6-.65l24.85 13h.85v3.35h-122v-3.35l25-13h71.3z"
                    />
                    <path fill="#7A6016" d="M385 30.4v-1.65H279.15v1.65H385z" />
                </g>
                <g id="PlaInd009__Layer2_0_FILL">
                    <path
                        fill="#FFFF80"
                        d="M103.3 15.2q-.05-.85-.35-1.6-.35-.8-1.05-1.5-.6-.6-1.3-.95-.95-.45-2.05-.45-1.15 0-2.1.45-.15.1-.25.15-.55.3-1.05.8-.65.65-.95 1.45-.45.85-.45 1.9 0 2 1.4 3.4t3.4 1.4q1.95 0 3.35-1.4l.3-.3q.1-.2.25-.35.15-.2.25-.4.6-1.05.6-2.35v-.25m12.05-4.5q-1.1 0-2.05.45-.15.1-.3.15-.5.3-1 .8-.65.65-1 1.45-.4.85-.4 1.9 0 2 1.4 3.4t3.35 1.4q2 0 3.4-1.4.15-.15.25-.3.15-.2.3-.35.1-.2.25-.4.6-1.05.6-2.35v-.25q-.05-.85-.35-1.6-.35-.8-1.05-1.5-.65-.6-1.35-.95-.9-.45-2.05-.45m16.75.45q-.15.1-.3.15-.5.3-1 .8-.65.65-.95 1.45-.45.85-.45 1.9 0 2 1.4 3.4t3.4 1.4q1.95 0 3.35-1.4l.3-.3q.1-.2.25-.35.1-.2.25-.4.6-1.05.6-2.35v-.25q-.05-.85-.35-1.6-.35-.8-1.05-1.5-.6-.6-1.3-.95-.95-.45-2.05-.45-1.15 0-2.1.45m227.3.95q-.6-.6-1.3-.95-.95-.45-2.05-.45-1.15 0-2.1.45-.15.1-.25.15-.55.3-1.05.8-.65.65-.95 1.45-.45.85-.45 1.9 0 2 1.4 3.4t3.4 1.4q1.95 0 3.35-1.4l.3-.3q.1-.2.25-.35.15-.2.25-.4.6-1.05.6-2.35v-.25q-.05-.85-.35-1.6-.35-.8-1.05-1.5m16.85 0q-.65-.6-1.35-.95-.9-.45-2.05-.45-1.1 0-2.05.45-.15.1-.3.15-.5.3-1 .8-.65.65-1 1.45-.4.85-.4 1.9 0 2 1.4 3.4t3.35 1.4q2 0 3.4-1.4.15-.15.25-.3.15-.2.3-.35.1-.2.25-.4.6-1.05.6-2.35v-.25q-.05-.85-.35-1.6-.35-.8-1.05-1.5z"
                    />
                    <path
                        fill="#42271C"
                        d="M86.45 177.9v-22.25Q72.85 167 64.8 167.95v33.95l21.65-24m85.3-22.05v22.05l14.05 21.05 2.55 2.95v-32.85h-.2q-2.95-.25-16.4-13.2z"
                    />
                    <path
                        fill="#526F44"
                        d="M38.6 144.45q.55-7.7.55-16.2 0-11.6-1.75-16.15-1.85-4.7-8.2-9.55v65.4h35.4v-.2q-1.2-6.25-10.65-7.6-3.05-.45-8.6-.6-6.6-.1-8.55-.3 1.25-5.35 1.8-14.8m186.2-42.5q-3.1 1.65-5.3 6.35-.9 1.9-3.2 9.15-1.35 4.2-1.7 10.85-.2 3.5-.2 12 0 3.85.35 8.55.1.85.15 1.8.35 3.25.95 9.45-12.1-.2-17.25 1-8.25 1.85-10.25 7.95h36.45v-67.1m160.2 31.6V106.6H264.85v.4q-.15-.2-.25-.4-1.4-2.05-2.85-3.6v66.05h36.5v-1.45h-.2q-.1-2.9-3.05-4.6l-.7-.4q-1.45-.65-7.55-2.1-4.75-1.1-8.15-1.55-4.4-.55-9.05-.35.5-5.55.75-10.3v-.25l.2-4.5q.1-3.05.1-5.7v-.4-3.9H385z"
                    />
                    <path
                        fill="#283223"
                        d="M52.95 106.4q-1.2-.85-2.75-1.5-6.8-2.85-13.6-3.15h-7.4v.8q6.35 4.85 8.2 9.55 1.75 4.55 1.75 16.15 0 8.5-.55 16.2-.55 9.45-1.8 14.8 1.95.2 8.55.3 5.55.15 8.6.6 9.45 1.35 10.65 7.6v.2h.2q8.05-.95 21.65-12.3v-2.55H69.1v-4.25H57.6v-35.7q-.9-4.25-4.65-6.75m165.3-4.65q-6.6.1-12.5 2.55-3.6 1.35-5.75 3.9-1.55 1.8-2.35 4.1v36.55h-6.55v4.25h-19.35v2.75q13.45 12.95 16.4 13.2h.2q2-6.1 10.25-7.95 5.15-1.2 17.25-1-.6-6.2-.95-9.45-.05-.95-.15-1.8-.35-4.7-.35-8.55 0-8.5.2-12 .35-6.65 1.7-10.85 2.3-7.25 3.2-9.15 2.2-4.7 5.3-6.35v-.2h-6.55M385 156.25v-3.15h-78.65l-35.85-9.55-.2 4.5v.25q-.25 4.75-.75 10.3 4.65-.2 9.05.35 3.4.45 8.15 1.55 6.1 1.45 7.55 2.1v-.1l-10.05-6.25H385z"
                    />
                    <path
                        fill="#8A6C28"
                        d="M171.75 153.1h19.35v-7.2h-122v7.2h102.65m25.9-30.55v-3.8H57.6v3.8h66v-1.25h1.8v1.25h72.25m64.1-92.15v-1.65H0v1.65h261.75M385 153.1v-7h-78.65l-35.75-8.25q0 2.65-.1 5.7l35.85 9.55H385z"
                    />
                    <path
                        fill="#D9D9D9"
                        d="M120.2 58.85V56.3H0v37.8h120.2V58.85z"
                    />
                    <path
                        fill="#183D23"
                        d="M260.55 58.85v-22.1H0V56.3h120.2v2.55h140.35m124.45 0v-22.1H279.15v22.1H385z"
                    />
                </g>
                <g id="PlaInd009__Layer1_0_FILL">
                    <path
                        fill="#5E5E5E"
                        d="M135.1 153.1h-10.25v35.2h10.25v-35.2m229.6 0v35.2h10.15v-35.2H364.7z"
                    />
                    <path
                        fill="#CCC"
                        d="M142.85 134.95v-9.75h6.4v-1.65h-8.4v11.4h2m-20.7 0v-1.8h-4.95v3.5h4.95v-1.7m218.25 7.85h2.25v-10.85h7.15v-1.8h-9.4v12.65m33.2-.2v1.85h5.55v-3.85h-5.55v2z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M150.85 125.2h-8v11.2h8v-11.2m191.8 17.6v1.7h8.95v-12.55H342.65v10.85z"
                    />
                    <path
                        fill="#A90101"
                        d="M126.65 134.95h-4.5v1.7h4.5v-1.7m-1.25-12.2h-1.75l-1.45 6.75h-.05v.05h4.5q.05.25 0-.4l-1.25-6.4m243.25 19.85v1.9h4.95V142.6h-4.95m3.55-13.5h-2l-1.55 7.4v.15h4.95q.05.3 0-.45l-1.4-7.1z"
                    />
                    <path
                        fill="#FFF"
                        d="M122.15 129.55v5.4h4.5v-5.4h-4.5m-3.15-111q.15-.2.3-.35.1-.2.25-.4-.2-1.45-1.65-2.8-1.45-1.35-3-1.35t-2.3.35q-.75.3-.75 1.45 0 1.7 1.55 2.7.25.1.5.25.45.25.95.35l.1.1q.5.25 1 .25.9 0 2.05-.25.3-.05.5-.15.15 0 .25-.05v.2q.15-.15.25-.3m-16.8 0q.1-.2.25-.35.15-.2.25-.4-.2-1.45-1.65-2.8-1.45-1.35-3-1.35t-2.3.35q-.7.3-.7 1.45 0 1.7 1.5 2.7.25.1.5.25.45.25.95.35l.1.1q.5.25 1 .25.9 0 2.05-.25.3-.05.55-.15.1 0 .2-.05v.2l.3-.3M131.4 14q-.75.3-.75 1.45 0 1.7 1.55 2.7.25.1.5.25.45.25.95.35l.1.1q.5.25 1 .25.9 0 2.05-.25.3-.05.5-.15.15 0 .25-.05v.2l.3-.3q.1-.2.25-.35.1-.2.25-.4-.2-1.45-1.65-2.8-1.45-1.35-3-1.35t-2.3.35m242.2 126.6v-3.95h-4.95v5.95h4.95v-2M353.25 14q-.7.3-.7 1.45 0 1.7 1.5 2.7.25.1.5.25.45.25.95.35l.1.1q.5.25 1 .25.9 0 2.05-.25.3-.05.55-.15.1 0 .2-.05v.2l.3-.3q.1-.2.25-.35.15-.2.25-.4-.2-1.45-1.65-2.8-1.45-1.35-3-1.35t-2.3.35m19.15-.35q-1.55 0-2.3.35-.75.3-.75 1.45 0 1.7 1.55 2.7.25.1.5.25.45.25.95.35l.1.1q.5.25 1 .25.9 0 2.05-.25.3-.05.5-.15.15 0 .25-.05v.2q.15-.15.25-.3.15-.2.3-.35.1-.2.25-.4-.2-1.45-1.65-2.8-1.45-1.35-3-1.35z"
                    />
                    <path
                        fill="#EAEAEA"
                        d="M125.4 122.75v-1.45h-1.8v1.45h1.8m246.8 6.35v-1.6h-2.05v1.6h2.05z"
                    />
                    <path
                        fill="#A50E0E"
                        d="M42.55 36.75H38.5V56.3h4.05V36.75m-31.85 0h-4V56.3h4V36.75m16.35 0h-4V56.3h4V36.75m48.15 0h-4.05V56.3h4.05V36.75m-16.1 0h-4V56.3h4V36.75m59.85 19.55h1.25v2.55h2.75v-22.1h-4V56.3m-12.5-19.55h-4.1V56.3h4.1V36.75m-15.95 0h-4.05V56.3h4.05V36.75m63.5 0h-4.1v22.1h4.1v-22.1m-15.75 0h-4v22.1h4v-22.1m63.45 0h-4.05v22.1h4.05v-22.1m-16.55 0h-4v22.1h4v-22.1m-15.3 0h-4v22.1h4v-22.1m63.85 0h-4v22.1h4v-22.1m-16.1 0h-4.05v22.1h4.05v-22.1m31.6 0h-4v22.1h4v-22.1m12.55 0h-1.2v22.1h1.2v-22.1m19.95 0h-2.55v22.1h2.55v-22.1m31.8 0h-4.05v22.1h4.05v-22.1m-15.9 0h-4v22.1h4v-22.1m48.2 0h-4.05v22.1h4.05v-22.1m15.25 0H357v22.1h4.05v-22.1m-32.05 0h-4v22.1h4v-22.1m48.4 0h-4v22.1h4v-22.1z"
                    />
                    <path
                        fill="#919191"
                        d="M42.6 74.5v1.1h36.55v-1.1H42.6m0 3.75v1.1h36.55v-1.1H42.6m0 3.75v1.1h36.55V82H42.6m0 7.5v1.1h36.55v-1.1H42.6m0-3.75v1.1h36.55v-1.1H42.6m36.55-25v-1.1H42.6v1.1h36.55m0 7.5v-1.1H42.6v1.1h36.55m0 3.75v-1.1H42.6V72h36.55m0-7.5v-1.1H42.6v1.1h36.55M3.1 85.75v1.1h36.55v-1.1H3.1m0 3.75v1.1h36.55v-1.1H3.1m-2.95 0H0v1.1h.15v-1.1M3.1 82v1.1h36.55V82H3.1M0 82v1.1h.15V82H0m.15 3.75H0v1.1h.15v-1.1m2.95-7.5v1.1h36.55v-1.1H3.1M0 79.35h.15v-1.1H0v1.1m.15-3.75v-1.1H0v1.1h.15m2.95-1.1v1.1h36.55v-1.1H3.1M.15 70.9H0V72h.15v-1.1m0-3.75H0v1.1h.15v-1.1m0-2.65v-1.1H0v1.1h.15m0-3.75v-1.1H0v1.1h.15m39.5 0v-1.1H3.1v1.1h36.55m0 11.25v-1.1H3.1V72h36.55m0-3.75v-1.1H3.1v1.1h36.55m0-3.75v-1.1H3.1v1.1h36.55M82.1 78.25v1.1H117v-1.1H82.1m0 3.75v1.1H117V82H82.1m0-7.5v1.1H117v-1.1H82.1m0 15v1.1H117v-1.1H82.1m0-3.75v1.1H117v-1.1H82.1m34.9-25v-1.1H82.1v1.1H117m0 3.75v-1.1H82.1v1.1H117m0 7.5v-1.1H82.1V72H117m0-3.75v-1.1H82.1v1.1H117z"
                    />
                    <path
                        fill="#564110"
                        d="M270.6 137.85q0 2.65-.1 5.7l35.85 9.55v-7l-35.75-8.25z"
                    />
                    <path
                        fill="#84B94F"
                        d="M261.75 28.75V0H0v28.75h261.75M145.4.15v2.7h-7.25V8.6q1.25.6 1.45 2.05.15.45.15.9.05.25.05 1.45 0 1.6-.65 3.3-.3.95-.8 1.6-.15.15-.25.3-.15.15-.25.35l-.3.3q-1.4 1.4-3.35 1.4-2 0-3.4-1.4t-1.4-3.4q0-1.05.45-1.9.1-1.6 1.5-3 1.35-1.35 3.35-1.95.6-.2 1.15-.25V7.9q-.05-.05 0-.15-.023-1.103-.05-2.25V2.85h-16.45V8.6q1.25.6 1.45 2.05.15.45.15.9.05.25.05 1.45 0 1.6-.65 3.3-.3.95-.8 1.6-.15.15-.25.3-.15.15-.3.35-.1.15-.25.3-1.4 1.4-3.4 1.4-1.95 0-3.35-1.4-1.4-1.4-1.4-3.4 0-1.05.4-1.9.15-1.6 1.55-3 1.35-1.35 3.35-1.95.6-.2 1.15-.25V7.9q-.05-.05 0-.15-.023-1.103-.05-2.25V2.85h-14.5V8.6q1.25.6 1.45 2.05.15.45.15.9.05.25.05 1.45 0 1.6-.6 3.3-.35.95-.85 1.6-.1.15-.25.3t-.25.35l-.3.3q-1.4 1.4-3.35 1.4-2 0-3.4-1.4t-1.4-3.4q0-1.05.45-1.9.1-1.6 1.5-3 1.35-1.35 3.35-1.95.6-.2 1.15-.25V7.9q-.05-.05 0-.15-.023-1.103-.05-2.25V2.85h-9.6V.15h54.85m239.6 0V0H279.15v28.75H385V2.85h-8.15V8.6q1.25.6 1.45 2.05.15.45.15.9.05.25.05 1.45 0 1.6-.65 3.3-.3.95-.8 1.6-.15.15-.25.3-.15.15-.3.35-.1.15-.25.3-1.4 1.4-3.4 1.4-1.95 0-3.35-1.4-1.4-1.4-1.4-3.4 0-1.05.4-1.9.15-1.6 1.55-3 1.35-1.35 3.35-1.95.6-.2 1.15-.25V7.9q-.05-.05 0-.15-.023-1.103-.05-2.25V2.85H360V8.6q1.25.6 1.45 2.05.15.45.15.9.05.25.05 1.45 0 1.6-.6 3.3-.35.95-.85 1.6-.1.15-.25.3t-.25.35l-.3.3q-1.4 1.4-3.35 1.4-2 0-3.4-1.4t-1.4-3.4q0-1.05.45-1.9.1-1.6 1.5-3 1.35-1.35 3.35-1.95.6-.2 1.15-.25V7.9q-.05-.05 0-.15-.023-1.103-.05-2.25V2.85h-9.6V.15H385z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaInd009__Layer4_0_FILL" />
            <use xlinkHref="#PlaInd009__Layer3_0_FILL" />
            <use xlinkHref="#PlaInd009__Layer2_0_FILL" />
            <use xlinkHref="#PlaInd009__Layer1_0_FILL" />
        </g></g>
    )
}

export default PlaInd009
