import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe054({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M178.25 152.25q-.9-.65-1.6-1-2.6 3.95-5.85 7.75l-7.95 8-6 5.4q.45 1 1.3 2.05.6.85 1.3 1.75.05.1.1.15 1.5 2.25 3 4.7 2.7 4.45 5.3 9.45l.8 1.6q.2.5.45 1 3.4 8.2 6.3 16.8l.5 1.8q0 .1.05.25 11.4 3.4 21.05-4.7l.35-.3q.25-.2.55-.45l.1-.1q3.75-3.55 6.05-7.4l.95-1.75q.4-.85.75-1.65-2.15.75-7.45 3.3-5.3 2.5-11.05 1.25-5.7-1.3-5.85-7.2-.2-5.9 1.95-11.1.05-.2.15-.4 3.7-12.85.8-20.95-1.7-4.85-5.7-7.95-.15-.15-.35-.3M165 75.4q.8.3 1.65.55 19.1 8.15 23.45 27.25.65 2.8 1.95 9 1.05-5.85 1.65-9 .8-4.35.95-8.7.25-5.5-.55-10.95-.05-.2-.05-.4l-.1-.4q-.05-.2-.05-.4-.05-.25-.1-.4-.05-.2-.05-.4-.05-.2-.1-.35-.05-.2-.05-.4-.35-1.7-.75-3.35-5.5-27.75-26.7-48.95-22-22-51-27-3.05-.55-6.2-.85Q103.25 0 97.3 0 56.95 0 28.4 28.5 6.6 50.3 1.5 79.05q-2.95 14.6 0 29.2.35 1.35.95 4.05 1.1-4.5 1.4-6.2l.15-.75Q4.65 102 5.7 99q.1-.35.25-.7 1.6-4.3 4.1-7.9 6.3-9.35 18.3-14.45 1.95-.65 3.85-1.3l.25-.05.2-.1.35-.1q1.15-.45 2.3-.85.3-.15.6-.2l.05-.05q11.95-4.35 22.8-9.7l6.3-3.2q.4-.2.85-.45.75-.4 1.55-.85l12.1-7.2 2.3-1.5 3.1-2.05Q91.45 44 97.6 39.4q7.9 5.95 16.75 11.8l.5.3 4.8 3.05q4.3 2.6 8.8 5.05l6.3 3.3q.7.35 1.45.7l22.85 9.7v.05q.3.05.65.2l4.9 1.7q.2.1.4.15M97.2 7.5v-.05l.05.05h-.05z"
                    id="HaiSpe054__Symbol_186_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M132.55 45.15V45Q94 30.85 65.35 0 34.08 30.823.4 44.2l.15-.05q1.15-.45 2.3-.85.3-.15.6-.2l.05-.05q11.95-4.35 22.8-9.7l6.3-3.2q.4-.2.85-.45.75-.4 1.55-.85l12.1-7.2 2.3-1.5 3.1-2.05q6.5-4.35 12.65-8.95 7.9 5.95 16.75 11.8l.5.3 4.8 3.05q4.3 2.6 8.8 5.05l6.3 3.3q.7.35 1.45.7l22.85 9.7v.05q.3.05.65.2l4.9 1.7q.2.1.4.15m40 121.85q.1-.25.2-.45-9.85 9.2-17.2 9.6-7.45.35-10.85-6.4-3.45-6.75-2.8-13.55.5-5.65 1.8-11.6.6-3.1.65-5.9.05-6.1-2.4-10.75-.6-1.05-1.25-2-1.1 1.4-2.35 2.8l-7.95 8-6 5.4q.45 1 1.3 2.05.6.85 1.3 1.75.05.1.1.15 1.5 2.25 3 4.7 2.7 4.45 5.3 9.45l.8 1.6q.2.5.45 1 3.4 8.2 6.3 16.8l.5 1.8q0 .1.05.25 11.4 3.4 21.05-4.7l.35-.3q.25-.2.55-.45l.1-.1q3.75-3.55 6.05-7.4l.95-1.75z"
                    id="HaiSpe054__Symbol_117_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe054__Symbol_186_0_Layer0_0_FILL"
                transform="translate(95.2 56.2)"
                id="HaiSpe054__col1n"
            />
            <use
                xlinkHref="#HaiSpe054__Symbol_117_0_Layer0_0_FILL"
                transform="translate(127.65 86.45)"
                id="HaiSpe054__col1d"
            />
        </g></g>
    )
}

export default HaiSpe054
