import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#794D32",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function ObjToo012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 6.2l14.35 37.05h.15l23.55 61q2.6-.05 5.55.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.4.35-.75.65l.6 1.6h-.1l11.9 30.75 10.45-4.25-3.05-7.95h-.05l-12.8-33.1.05-.05L25.2 39.85h-.15L22.8 34.1l-.05.05-12.3-32.1Q1.4-3.55 0 6.2m63.95 134.5l-.05.05-1-2.65 1.05 2.6z"
                    id="ObjToo012__Symbol_172_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.6 0Q-3.5 33.7.5 43.4l16.25 40.85q6.4 12.65 22.25 6.9l13.9-5.55 2.5 7.05 13.5-5.25-2.6-6.85h.05l-.1-.25 13.8-5.5q15.5-6.7 11.5-20.3L75.3 13.65Q71.5 3.85 25.6 0z"
                    id="ObjToo012__Symbol_56_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M25.55 44.15L0 0l12.15 49.2 2.5 7.05L28.15 51l-2.6-6.85z"
                    id="ObjToo012__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo012__Symbol_172_0_Layer0_0_FILL"
                transform="translate(75.15 182.05)"
                id="ObjToo012__col1n"
            />
            <use
                xlinkHref="#ObjToo012__Symbol_56_0_Layer0_0_FILL"
                transform="translate(18.05 96.15)"
                id="ObjToo012__col2n"
            />
            <use
                xlinkHref="#ObjToo012__Symbol_9_0_Layer0_0_FILL"
                transform="translate(58.8 132.55)"
                id="ObjToo012__col2d"
            />
        </g></g>
    )
}

export default ObjToo012
