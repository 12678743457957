import { AssetSvgProps } from "../../../shared/assets"

function ShiMot049({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M180.55 258q-12.477 17.9 0 35.8h22.6q9.848-17.143 0-35.8h-22.6z"
                    id="ShiMot049__Layer5_0_FILL"
                />
                <g id="ShiMot049__Layer5_1_FILL">
                    <path fill="red" d="M225.7 293.8V258h-22.55v35.8h22.55z" />
                    <path fill="#0000A4" d="M159.2 258v35.8h21.35V258H159.2z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot049__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot049__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot049
