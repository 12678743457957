import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2l": "#333333",
    "col2n": "#151515"
}

function ObjMor043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.6 17.25v.05q-4.75.5-7.6.9-1.95-2.05-3.8-4.05-2.1-.1-4.25.05-5.15.45-8.3 5.85-.1.05-.1.15-.1.15-.25.3L0 79.85q.9 1.45 4.75 5.3 3.85 3.8 10.6 4.8t10.95.15q4.2-.85 5.65-1.75v-.05l1.5-5.55q-.15.05-.4 0-4.45-.4-6.05-4.65-.2-.5-.35-1.1-.8-2.7-.55-5.65.15-1.55.65-2.9.3-.6.55-1.25.5-1.1 1.35-2.05 1.8-2 4.75-2.95 2.05-.6 4.55-.7.85-.1 1.4 0l9.15-33.45q.35-5.3-3.2-7.9-2.05-1.65-4.65-2.9h-.05m-14.85 38.1h-.1v-.25q.1.1.1.25M24.35 53h-.1v-.1l.1.1m-3.1-.65h-.15q.1-.1.1-.15.05.05.05.15M66.9 3.05v-2L66.15 0 41.3.15l-6.65 2.8-.25.8h.1l-.85 2.9 1.2.35-.8 3.05q4.5.6 7.15-1.55l.75-2.85 24.95-2.6z"
                    id="ObjMor043__Symbol_182_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.4 17.25l-.05.05q-4.75.5-7.6.9-1.95-2.05-3.8-4.05-2.1-.1-4.25.05-5.15.45-8.3 5.85-.1.05-.1.15-.1.15-.25.3l-.05.2q.6 1.45 3.25 3.25.25.1.45.2 4.4 2.85 11.1 4.7 6.75 1.85 11.9 1.65h.45q3.75-.25 4.85-1.4l.25-1.05q.35-5.3-3.2-7.9-2.05-1.65-4.65-2.9m26.25-16.2L49.9 0 25.05.15l-6.65 2.8-.25.8h.1l-.85 2.9 1.2.35.65-2.7L24.6 2l26.05-.95z"
                    id="ObjMor043__Symbol_183_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M34.85 2.2q-.2-.05-.35-.05-9.25-.9-18.35 6.15-5.7 4.4-9.35 10.25l-.1-.05Q3.4 23 1.85 28.6 0 35.2.1 40.05L.05 40Q0 43.3 1.3 51.5q.55 3.85 2.25 10.6.7 4.45-.35 8.65-.15.6-.3 1.25v.05q-.45 2-.6 3.4l-1-.3h-.05L0 79.45l5.25 1.45-1.6 5.9q.85 1.5 3.7 3.3 4.6 2.9 11.5 4.8 7.05 1.95 12.35 1.8 3.75-.15 5.15-1.25l3.3-12.1q-.2-.2-.3-.5v.05q-1.45.9-4.85.95-5.2.3-12.15-1.6-6.85-1.9-11.35-4.8-2.7-1.6-3.6-3.05-.25.15-.4.25l-.05.05-.5 1.9-1.85-.5q.75-1.2 1.75-3.05.2-.7.4-1.35Q7.9 67 7 61.85l-.05-.05q-1.55-6.5-2.1-10.4-1.15-7.95-1.2-11.1 0-4.6 1.65-10.75 1.6-5.65 4.95-9.9h.05q3.3-4.85 8.05-8.5 7.7-6.05 15.5-5.45 2.2-1.5 1-3.5m11.1-.8q.15-.4-.1-.85-.3-.4-.7-.5-.45-.15-.9.05-.4.25-.55.7-.1.5.15.9t.65.55q.45.1.95-.1.4-.25.5-.75m20.4 17.4q1.3.15 2.05-.9-1.1-5.3-6.05-8.35l-13.7-6.6-.05.1-7.15 1.55 18.4 6.85q4.7 1.75 6.5 7.35z"
                    id="ObjMor043__Symbol_184_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M27.35 10.35q4.1-.1 5.35-1.4-.2-.2-.3-.5v.05q-1.45.9-4.85.95-5.2.3-12.15-1.6-6.85-1.9-11.35-4.8Q1.35 1.45.45 0 .2.15.05.25L0 .3Q.75 2 3.8 3.95q4.45 2.8 11.35 4.7 6.9 1.95 12.2 1.7z"
                    id="ObjMor043__Symbol_185_0_Layer0_0_FILL"
                />
                <g id="ObjMor043__Layer1_0_FILL">
                    <path
                        fill="#999"
                        d="M227.95 308.1l4.65 1.3.9-3.3-.5-.1-4.15-1.2-.9 3.3m33.75-73.8l-1.35 4.95 3.8 1.05 1.35-4.95-3.8-1.05z"
                    />
                    <path
                        fill="#FFF"
                        d="M260.8 295.95q.3-.6.55-1.25.5-1.1 1.35-2.05 1.8-2 4.75-2.95l2.6-9.5q-4.85 0-11.05-1.7-6.4-1.75-10.65-4.35l-7 25.45q4.2 2.6 10.6 4.35 5 1.35 9.1 1.65-.2-.5-.35-1.1-.8-2.7-.55-5.65.15-1.55.65-2.9m-5.5-16.1h-.15q.1-.1.1-.15.05.05.05.15m4.5 3h-.1v-.25q.1.1.1.25m-1.5-2.45l.1.1h-.1v-.1z"
                    />
                    <path
                        fill="#666"
                        d="M266.65 237.4l.5-1.55-1.65-.5-1.35 4.95.3.05q.1-.05.2-.2l.55-.55q.25-.3.4-.55.45-.55.7-1.05.25-.25.35-.6z"
                    />
                    <path
                        fill="#F90"
                        d="M262.7 242.7q-.2.8.25 1.55.85.65 1.7 1.15 3.25 1.85 6.65 1.95 1.1 0 2.3-.15.8-.4 1-1.25.15-.45.05-1.15v-.05q-.05-.8-.4-1.95-.5-1.9-.3-3.1l-5.85-2.15.8-3.05-1.2-.35-.55 1.7-.5 1.55q-.1.35-.35.6-.25.5-.7 1.05-.15.25-.4.55l-.55.55q-.1.15-.2.2-.25.2-.45.5-.45.45-.75.8-.4.6-.55 1.05z"
                    />
                </g>
                <g id="ObjMor043__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#CCC"
                        d="M231.1 309l.9-3.3-1.8-.5-.85 3.3 1.75.5m30.15-73.05l-.5 1.8 3.8 1.05.5-1.8-3.8-1.05z"
                    />
                    <path
                        fill="#F90"
                        d="M254.6 283.1q-.45.1-1 .2h-.05l.05.05q-4.85 1.65-5.95 8.2-.15 1.6.2 3.3.25.65.55 1.3.1.25.2.35 4.2 5.45 9.75 2.7 1.45-1.2 2.45-3.25.3-.6.55-1.25.15-.6.35-1.2.3-1.95.05-4.3-.1-.9-.45-1.9-.2-.35-.25-.6-.05.3-.1.7v.35q.05.9-.25 1.95-.7 2.5-2.5 4.55-.2.05-.3.15-.65.2-.85.15-.35-.1-.6-.4v-.4q.05-.5.1-.55.15-.7.75-1.4.15-.2.4-.55.85-1.1.85-1.2.3-.5.5-.85 1.55-3.75-.8-7-.15-.1-.2-.2-.55-.7-1.1-1.35.1 1.05 0 2.45-.55 1.9-2.9 3.65-2.1 1.75-2.65 3.4-.25-.6-.3-.7-.1-.4.35-2.1.3-.85 1.75-2.5l1.35-1.65.05-.1z"
                    />
                    <path
                        fill="#FEC878"
                        d="M268.75 239.45q-.2.35-.3.7l-1 1.3q-.75.7-1.15 1.05-.2.3-.35.4-.5.65-1.2 2.3l-.1.2q3.25 1.85 6.65 1.95v-2.45q0-.2-.05-.4.05-.5-.1-1.1-.45-1.55-.5-2.3 0-.7.2-1.7-.95-.4-1.25-.45-.35-.15-.45-.2l-.4.7z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor043__Symbol_182_0_Layer0_0_FILL"
                transform="translate(234.05 227.5)"
                id="ObjMor043__col1n"
            />
            <use
                xlinkHref="#ObjMor043__Symbol_183_0_Layer0_0_FILL"
                transform="translate(250.3 227.5)"
                id="ObjMor043__col1d"
            />
            <use
                xlinkHref="#ObjMor043__Symbol_184_0_Layer0_0_FILL"
                transform="translate(226.65 232.95)"
                id="ObjMor043__col2n"
            />
            <use
                xlinkHref="#ObjMor043__Symbol_185_0_Layer0_0_FILL"
                transform="translate(233.6 307.35)"
                id="ObjMor043__col2l"
            />
            <use xlinkHref="#ObjMor043__Layer1_0_FILL" />
            <use xlinkHref="#ObjMor043__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMor043
