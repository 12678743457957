import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1n": "#EEEEEE"
}

function AccHat092({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.3 36.3L186 14.1q-.5-2.5-3.55-3Q138.85.05 95.7 0 52.55.05 8.95 11.1q-3.05.5-3.55 3L.1 36.3q-.3 1.4.2 1.9.55.55 2 .2 47.7-9.2 93.4-9.55 45.7.35 93.4 9.55 1.45.35 2-.2l.15-.15q0-.05.05-.05.25-.6 0-1.7z"
                    id="AccHat092__Symbol_309_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M177.55 13.7q1.75.3 2.65 1.25.45.25.8.6.3.35.25.7l.3.25-.6-2.4q-.5-2.5-3.55-3Q133.8.05 90.65 0 47.5.05 3.9 11.1q-3.05.5-3.55 3L0 15.65q.8-1.55 3.15-1.95Q47 2.65 90.35 2.6q43.4.05 87.2 11.1z"
                    id="AccHat092__Symbol_206_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat092__Symbol_309_0_Layer0_0_FILL"
                transform="translate(96.7 95.8)"
                id="AccHat092__col1n"
            />
            <use
                xlinkHref="#AccHat092__Symbol_206_0_Layer0_0_FILL"
                transform="translate(101.75 95.8)"
                id="AccHat092__col1d"
            />
        </g></g>
    )
}

export default AccHat092
