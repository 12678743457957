import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.55 3.15Q44.35 2 41.3 1.25 35.95 0 31.95 0q-2.95 0-5 1.05Q25.7 1.7 25 2q-.2 0-.35.05Q24.5 2 24.3 2q-.7-.3-1.95-.95Q20.3 0 17.35 0q-4 0-9.35 1.25-3.05.75-6.25 1.9-1 .35-1.75.65.05 0 .05.05.15 0 1.1.3.9.25 9.95 1.2 5.5.55 8.1.85 1.65.2 2.15.25 1.2.15 2.15.1.9-.05 1-.05.05.05 1.25.05 4.05-.2 10.5-.95l10.5-.4 2.2-1.3q.25-.05.3-.05 0-.05.05-.05-.75-.3-1.75-.65z"
                    id="FacMou047__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M49 0q-.85.45-5.7.5-2.3 0-6.65.25Q34.8.6 30.5 1.6l-1.7.3q-2.6.65-3.85.75h-.7Q23 2.55 20.4 1.9l-1.7-.3q-4.3-1-6.15-.85Q8.2.5 5.9.5 1.05.45.2 0H0q.15.05.3.15Q4.35 2.8 7.65 4.6q3.25 1.45 5.8 2.35Q19.2 8.3 24.6 8.35q5.4-.05 11.15-1.4 2.55-.9 5.8-2.35 3.3-1.8 7.35-4.45.15-.1.3-.15H49z"
                    id="FacMou047__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou047__Symbol_72_0_Layer0_0_FILL"
                transform="translate(167.85 217.05)"
                id="FacMou047__col1n"
            />
            <use
                xlinkHref="#FacMou047__Symbol_25_0_Layer0_0_FILL"
                transform="translate(167.9 220.9)"
                id="FacMou047__col1l"
            />
        </g></g>
    )
}

export default FacMou047
