import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function LegSho004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M60.9 5.4l2.35.1Q63.7 5.25 65 5q1.6-.3.45-1.6Q44.6-1.5 26.5.65l-1.1.1q-4.2.45-8.35 1.3-.6.1-5.4 1.05-4.8.95-8.75 2.85L0 7.2q9.3-2.1 16.65-3 4.55-.55 9.15-.85Q43.3 2.3 60.9 5.4m62.15-2.05q12.2.7 25.45 3.9-1.25-.9-2-1.25-4.15-1.7-13-3.65-2.2-.45-4.45-.8l-4.2-.65q-.45-.05-.85-.1-.95-.05-1.95-.1-18.65-1.95-37.8 2.7-4.15 1.5-1.5 1.85.95.1 1.3.1 1.4.45 4.05.1h.4q17.3-3.05 34.55-2.1z"
                    id="LegSho004__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M147 10.45l1.5-5.65H89.85Q86.6 1.35 82.2.4q-3.45-.8-7.65 0-.15 0-.3.05-.15-.05-.3-.05-4.2-.8-7.65 0-4.4.95-7.65 4.4H0l1.5 5.65h59.35q2.05-3.95 7.5-4.35l.7 4.35h4.35l.85-4.25.85 4.25h4.35l.7-4.35q5.45.4 7.5 4.35H147z"
                    id="LegSho004__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho004__Symbol_6_0_Layer0_0_FILL"
                transform="translate(118 326.15)"
                id="LegSho004__col1n"
            />
            <use
                xlinkHref="#LegSho004__Symbol_7_0_Layer0_0_FILL"
                transform="translate(118.25 328.5)"
                id="LegSho004__col2n"
            />
        </g></g>
    )
}

export default LegSho004
