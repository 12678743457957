import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#2D4A00",
    "col1n": "#4C7E00"
}

function LegSki030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M160.2 13.75q.05-.05.15-.05-9.55-4.75-12.8-11.35l-.35-2.3h-.8l-2.2.5q-.85.15-1.65.35-1 .25-2 .45-1.95.45-3.85.85l-4.5.9q-2.25.4-4.45.8-4.45.75-8.85 1.35l-3.5.5q-4.45.55-8.9 1-1.05.05-2.1.2-9.25.75-18.5.9-1.5.05-3 .05H78.7q-1.7-.05-3.35-.05-7.6-.2-15.2-.75-.85-.1-1.7-.15-6-.55-12-1.3-1.7-.25-3.45-.45-2.9-.45-5.75-.9-2.4-.4-4.7-.8-2.2-.4-4.4-.85-2.2-.4-4.3-.85-1.5-.35-3-.65-.5-.15-1-.25-.3-.05-.55-.1-1.7-.4-3.35-.8-.4.05-.75.1l-.55 2.3Q9.5 9.4.9 13.55q2.8.85 4.5 1.25L.5 20.55l-.5.5q22.55 2.9 45 4.15 6.6.35 13.2.6 10.95.35 21.95.35 22.65 0 45.2-1.7 16.9-1.25 34-3.45l-3.85-6.05q1.9-.45 4.7-1.2z"
                    id="LegSki030__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M58.1 10.1Q68.45 6.6 75.7.05 74 0 72.35 0 62.7 7.2 48.3 9.85q-7.6 1.4-16.5 1.5-4.55-.15-9.05-.7Q14 9.3 5.25 7.55q-.8-.2-1.6-.35Q3 7.1 2.4 6.95L0 9.45q14.15 2.6 22.75 3.95 4.5.55 9.05.7 15-.2 26.3-4m92.25-2.55Q138.6 9.9 126.9 11.5q-3.45.15-6.7.1-7.5-.5-13.85-1.75Q91.5 6.95 82.9 0q-1.5.05-3 .05Q86.15 6.5 96.75 10.1q6.45 2.2 14.5 3.35l2.15.25q9 1 17.15 0 2.25-.35 4.7-.75 8.45-1.4 18.8-3.5L152.5 7.1l-.5.1q-.85.15-1.65.35z"
                    id="LegSki030__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki030__Symbol_96_0_Layer0_0_FILL"
                transform="translate(111.25 307.15)"
                id="LegSki030__col1n"
            />
            <use
                xlinkHref="#LegSki030__Symbol_56_0_Layer0_0_FILL"
                transform="translate(114.25 315)"
                id="LegSki030__col1d"
            />
        </g></g>
    )
}

export default LegSki030
