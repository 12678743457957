import { AssetSvgProps } from "../../../shared/assets"

function ShiMot078({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#008C45"
                    d="M159.2 257.8v35.8h66.5v-35.8h-66.5z"
                    id="ShiMot078__Layer5_0_FILL"
                />
                <path
                    fill="#F8C200"
                    d="M191.35 289.55l.1.05 23.6-14.25-23.8-14.05-23.35 14.1 23.35 14.2.1-.05z"
                    id="ShiMot078__Layer5_1_FILL"
                />
                <g id="ShiMot078__Layer5_2_FILL">
                    <path
                        fill="#27156E"
                        d="M191.5 266.05q-3.95 0-6.75 2.75-.95 1-1.6 2.1 1.8-.2 3.7.05 2 .2 4.05.8 3.8 1.1 6.55 3.05l.8.6q.8.7 1.55 1.5.5.55.9 1.1.15-.5.2-.95.1-.75.1-1.5 0-3.95-2.8-6.75-2.75-2.75-6.7-2.75m2.85 5.65l-.6-.45h.7l.25-.7.25.75h.75l-.6.4.2.75-.6-.45-.6.4.25-.6v-.1m-8 .5q-1.95-.25-3.75 0-.6 1.55-.6 3.35 0 3.95 2.75 6.7 2.8 2.8 6.75 2.8t6.7-2.8q1.35-1.35 2.05-2.95-.4-.55-.95-1.15-.75-.8-1.55-1.5l-.8-.6q-2.75-1.95-6.55-3.05-2.05-.6-4.05-.8m7.4 4l-.6-.45h.7l.25-.7.25.75h.75l-.6.4.2.75-.6-.45-.6.4.25-.6v-.1m2.65 2.45l.25.75h.75l-.6.4.2.75-.6-.45-.6.4.25-.6v-.1l-.6-.45h.7l.25-.7m-2.9 2l-.6.4.2.75-.6-.45-.6.4.25-.6v-.1l-.6-.45h.7l.25-.7.25.75h.75m.8 3.3l.25-.6v-.1l-.6-.45h.7l.25-.7.25.75h.75l-.6.4.2.75-.6-.45-.6.4m-6.05-5.6l.25-.6v-.1l-.6-.45h.7l.25-.7.25.75h.75l-.6.4.2.75-.6-.45-.6.4m-2.15-3.55l-.6.4.2.75-.6-.45-.6.4.25-.7-.6-.45h.7l.25-.7.25.75h.75m-.65 4.95h.75l-.6.4.2.75-.6-.45-.6.4.25-.6v-.1l-.6-.45h.7l.25-.7.25.75m4.1 2.35l-.6.4.2.75-.6-.45-.6.4.25-.6v-.1l-.6-.45h.7l.25-.7.25.75h.75z"
                    />
                    <path
                        fill="#FFF"
                        d="M193.75 271.25l.6.45v.1l-.25.6.6-.4.6.45-.2-.75.6-.4h-.75l-.25-.75-.25.7h-.7m-4.8 11.25l.6-.4h-.75l-.25-.75-.25.7h-.7l.6.45v.1l-.25.6.6-.4.6.45-.2-.75m-2.75-2.75h-.75l-.25-.75-.25.7h-.7l.6.45v.1l-.25.6.6-.4.6.45-.2-.75.6-.4m-.7-4.55l.6-.4h-.75l-.25-.75-.25.7h-.7l.6.45-.25.7.6-.4.6.45-.2-.75m3 2.55l-.25.6.6-.4.6.45-.2-.75.6-.4h-.75l-.25-.75-.25.7h-.7l.6.45v.1m6.05 5.6l-.25.6.6-.4.6.45-.2-.75.6-.4h-.75l-.25-.75-.25.7h-.7l.6.45v.1m-1.65-2.3l.6-.4h-.75l-.25-.75-.25.7h-.7l.6.45v.1l-.25.6.6-.4.6.45-.2-.75m3.75-1.65l-.25-.75-.25.7h-.7l.6.45v.1l-.25.6.6-.4.6.45-.2-.75.6-.4h-.75m-3.5-3.65l.6.45v.1l-.25.6.6-.4.6.45-.2-.75.6-.4h-.75l-.25-.75-.25.7h-.7m-10.55-3.55q1.8-.25 3.75 0 2 .2 4.05.8 3.8 1.1 6.55 3.05l.8.6q.8.7 1.55 1.5.55.6.95 1.15.3-.65.45-1.3-.4-.55-.9-1.1-.75-.8-1.55-1.5l-.8-.6q-2.75-1.95-6.55-3.05-2.05-.6-4.05-.8-1.9-.25-3.7-.05 0 .05-.05.1-.3.55-.5 1.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot078__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot078__Layer5_1_FILL" />
            <use xlinkHref="#ShiMot078__Layer5_2_FILL" />
        </g></g>
    )
}

export default ShiMot078
