import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M39.65 21.7Q34.6 10.5 25.3 4.65 16.05-1.2 10 .75q-6 1.9-8.65 12.1-2.6 10.2-.1 22.65 1.2 5.8 3.85 10.25 3.3-3.15 6.95-5.95l.25-.25q9.25-7.6 19.7-12.4.6-.3 1.2-.55 3.55-1.6 7.3-2.9-.4-1-.85-2m103.55-8.85q-2.65-10.2-8.65-12.1-6.05-1.95-15.35 3.9-9.25 5.85-14.3 17.05-.45 1-.85 2.05 3.75 1.3 7.4 2.9.55.25 1.1.55 5.55 2.55 10.75 5.95l4.35 2.95q1.45 1 2.9 2.1l8.85 7.65q2.65-4.5 3.9-10.35 2.5-12.45-.1-22.65z"
                    id="FacEar007__Symbol_25_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.1 20.55q.6-.3 1.2-.55Q23.05 7.15 5.25 0q.05.05.1.05Q1.2 2.6.45 8.95-.4 13.3.45 20.1q.85 6.75 2.4 9.9 1.55 3.1 1.95 3.45.2-.1.35-.25l.25-.25q9.25-7.6 19.7-12.4M125.4.1l-.05-.05-.1.05v.05q-17.6 7.1-20.7 19.9.55.25 1.1.55l8.15 4.3q4.6 2.7 9 6.05l.55.45q.3.2.6.45l.35.3 1 .8.25.25q.15.15.35.25.4-.35 1.95-3.45 1.55-3.15 2.4-9.9.85-6.8 0-11.15-.75-6.3-4.85-8.85z"
                    id="FacEar007__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 .25Q14.704 9.122 19.85 20.8q.6-.3 1.2-.55Q17.8 7.4 0 .25m100.4 20.6q4.946-11.602 19.9-20.6-17.85 7.15-21 20.05.55.25 1.1.55z"
                    id="FacEar007__Symbol_14_0_Layer0_0_FILL"
                />
                <g id="FacEar007__Layer2_0_FILL">
                    <path
                        fill="#8FAFCD"
                        d="M247.9 76.05q1 .7 2.05 1.45-4.4-3.35-9-6.05 1.3.8 2.6 1.65l4.35 2.95z"
                    />
                    <path
                        fill="#3E576D"
                        d="M252.5 46.6l.05.05q.074-.054.15-.1-.091.037-.2.05z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#FacEar007__Symbol_25_0_Layer0_0_FILL"
                transform="translate(120.25 39.95)"
                id="FacEar007__col1n"
            />
            <use xlinkHref="#FacEar007__Layer2_0_FILL" />
            <use
                xlinkHref="#FacEar007__Symbol_47_0_Layer0_0_FILL"
                transform="translate(127.15 46.55)"
                id="FacEar007__col2n"
            />
            <use
                xlinkHref="#FacEar007__Symbol_14_0_Layer0_0_FILL"
                transform="translate(132.4 46.3)"
                id="FacEar007__col2d"
            />
        </g></g>
    )
}
export default FacEar007
