import { AssetSvgProps } from "../../../shared/assets"

function ObjMor034({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjMor034__Layer14_0_FILL">
                    <path
                        fill="#AAB6C4"
                        d="M271.3 337.2q-2.275-4.524-4.55 0v1.3q.45 0 .9.05h3.65v-1.35m-.2-27.85q-2.1.95-4.55 1.2v16.8h4.55v-18m11.7-43.85h-.1q-1.695 1.925 0 3.85h.1v-.05l.05-.05v-.05q.1 0 .1-.05.4-.1.8-.25.15-.15.25-.2.3-.1.5-.25.05-.05.1-.05t.1-.05q.7-.3 1.3-.65.1-.05.15-.1.35-.55-.3-.85l-.05-.05q-.25-.1-.45-.2t-.45-.15v-.05h-.05q-.5-.2-1.05-.4-.2-.1-.5-.1v-.05l-.05-.05q-.25-.05-.45-.2m-30.65 9.4H246.25v5.4h35.25v-5.4h-29.35m24.9-10.25v-1.4H273.95v1.4h-26.1v5.25H280q1.791-2.625 0-5.25h-2.95m-1.3 2.65v1.55h-21.1v-2.95h19.3v1.4h1.8M118.3 338.5v-1.3q-1.108-4.085-4.55 0v1.35h3.65q.45-.05.9-.05m.2-28q-2.426-.219-4.55-1.2v18.05h4.55V310.5m-16.75-44.7q-.3 0-.5.1-.55.2-1.05.4h-.05v.05q-.25.05-.45.15-.2.1-.45.2l-.05.05q-.65.3-.3.85.05.05.15.1.6.35 1.3.65.05.05.1.05t.1.05q.2.15.5.25.1.05.25.2.4.15.8.25 0 .05.1.05v.05l.05.05v.05h.1q2.157-2.121 0-3.85h-.1q-.2.15-.45.2l-.05.05v.05m6.1 9.1h-4.3v5.4h35.25v-5.4h-30.95m1.4-11.65H108v1.4h-2.95q-1.908 2.784 0 5.25h32.15v-5.25h-26.1v-1.4h-1.85m.05 4.05h1.8v-1.4h19.3v2.95h-21.1v-1.55z"
                    />
                    <path
                        fill="#7A899A"
                        d="M249.75 298.6q-1.15-.55-2.15-1.55-.85-.85-1.4-1.85v5.5h4.5q-.35-.95-.55-1.95-.05-.05-.1-.05l-.3-.1m21.35 28.75h-4.55l-2.8 2.8q5.65 2.874 10.15 0l-2.8-2.8m17.9-32.7v.15q-4.008 1.65 0 3.3v.35h2.5v-3.95H289V294.65m-37.9-14.35v3.85l.3-.1q.3-.05.55-.1h.2v-3.65h-1.05m1.05-10.4h-1.05v5h1.05v-5m25.05 0h-1.1v5h1.1v-5m-.15-15.75h-1.25v-.75h-1.05v.75h-28.5v6.65h4.85v3.85h1.05v-3.85h22.6v2.45h1.05v-2.45h1.25v-6.65m-158.55 73.2h-4.55l-2.8 2.8q5.075 4.495 10.15 0l-2.8-2.8m15.85-26.65h4.5v-5.55q-.533 1.033-1.4 1.9-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.203 1.002-.55 1.95m-38.3-6.1v-.1h-2.5v3.95h2.5v-.25-.1q1.833-1.65 0-3.3v-.2m36.85-14.3v3.6q.1.03.2.05.25.05.55.1.151.032.3.05v-3.8h-1.05m1.05-10.4h-1.05v5h1.05v-5m-25 0h-1.1v5h1.1v-5m1.35-15.75v-.75h-1.05v.75H108v6.65h1.25v2.45h1.05v-2.45h22.6v3.85h1.05v-3.85h4.85v-6.65h-28.5z"
                    />
                </g>
                <g id="ObjMor034__Layer14_1_FILL">
                    <path
                        fill="#474747"
                        d="M262.3 337.2h13.1v-7.05h-13.1v7.05m17.3-45.05q.4 1.65.4 3.45 0 2.7-.85 5.1h7q2.45.05 2.8-1.8.05-.15.05-.3v-.15-.25-3.4-.2-.1q0-2.4-2.85-2.35h-6.55M122.75 337.2v-7.05h-13.1v7.05h13.1M98.9 292.15q-2.862-.048-2.85 2.35v4.1l.05.3q.331 1.842 2.8 1.8h7q-.85-2.376-.85-5.1 0-1.8.35-3.45h-6.5z"
                    />
                    <path
                        fill="#7A899A"
                        d="M280 274.9h2.7v-16.7H280v16.7m-174.95-16.7h-2.7v16.7h2.7v-16.7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor034__Layer14_0_FILL" />
            <use xlinkHref="#ObjMor034__Layer14_1_FILL" />
        </g></g>
    )
}

export default ObjMor034
