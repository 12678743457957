import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#2E0E00",
    "col1n": "#621F00",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function AccMor012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M135 16.45q.3-.45.65-.9 1-1.75.35-3.7L128.2 0l-1.4 1.15q-3.65 4.2-6.1 7.3-9.05 10.75-27.5 24.5-3.75 2.65-8.9 5.85l-4.25 2.55q-2.4 1.4-4.85 2.75-.15.05-.25.15L73 45.3q-3.35 1.75-6.7 3.35-.9.4-1.75.8-16.2 7.35-33.95 11.2-6.7 1.45-13.65 2.4l-.4.05q-5.55.6-9.6.7Q4.5 63.95 4 66L0 80.9q-.2 1.1 1.85 1.2h.1q7.95.2 16.15-1.1 17.2-2.25 32.8-7.05 4.2-1.25 8.3-2.75 4.5-1.65 8.9-3.55Q78.7 63.1 88.55 57.1q3.4-2.05 6.7-4.3l.7-.5q.05 0 .15-.05.05-.05.15-.05.2-.15.45-.3 4.7-3.3 5.15-3.55 4.6-3.25 8.8-6.65 1.5-1.2 2.95-2.45 12.5-10.65 21.4-22.8z"
                    id="AccMor012__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M124.5 0l-1.4 1.15q-3.65 4.2-6.1 7.3-9.05 10.75-27.5 24.5-3.75 2.65-8.9 5.85l-4.25 2.55q-2.4 1.4-4.85 2.75-.15.05-.25.15L69.3 45.3q-3.35 1.75-6.7 3.35-.9.4-1.75.8-16.2 7.35-33.95 11.2-6.7 1.45-13.65 2.4l-.4.05q-5.55.6-9.6.7Q.8 63.95.3 66L0 67.2q.35-1 1.95-1.15Q2.8 66 3.7 66q4.05-.1 8.55-.6l.4-.05q6.95-.95 13.65-2.4 17.75-3.85 33.95-11.2.85-.4 1.75-.8 3.35-1.6 6.7-3.35l1.95-1.05q.1-.1.25-.15 2.45-1.35 4.85-2.75L80 41.1q9.7-6 17.95-12.7 1.5-1.2 2.95-2.45 14.05-12 23.6-25.95z"
                    id="AccMor012__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.55 53.65q-.25 0-.45.05-1.2.15-2.05 1Q0 55.75 0 57.2t1.05 2.5q1.05 1.05 2.5 1.05h.1q1.35 0 2.4-1.05 1.05-1.05 1.05-2.5t-1.05-2.5Q5 53.65 3.55 53.65m24.65-5.5q-1.2.15-2.05 1-1.05 1.05-1.05 2.5t1.05 2.5q1.05 1.05 2.5 1.05h.1q1.35 0 2.4-1.05 1.05-1.05 1.05-2.5t-1.05-2.5q-1.05-1.05-2.5-1.05-.25 0-.45.05m47.2-13.5q1.05-1.05 1.05-2.5t-1.05-2.5q-1.05-1.05-2.5-1.05h-.15q-.15 0-.3.05-1.2.15-2.05 1-1.05 1.05-1.05 2.5t1.05 2.5q1 1 2.35 1.05H73q1.35 0 2.4-1.05M48.3 43.4q0 1.45 1.05 2.5 1 1 2.35 1.05h.25q1.35 0 2.4-1.05 1.05-1.05 1.05-2.5t-1.05-2.5q-1.05-1.05-2.5-1.05h-.1q-.2 0-.35.05-1.2.15-2.05 1-1.05 1.05-1.05 2.5m41.4-27.25q-1.05 1.05-1.05 2.5t1.05 2.5q.4.4.85.65.75.4 1.65.4h.1q1.35 0 2.4-1.05 1.05-1.05 1.05-2.5t-1.05-2.5q-.5-.5-1.05-.75-.65-.3-1.45-.3-.25 0-.45.05-1.2.15-2.05 1m20.5-15.1l-.3-.3q-.95-.75-2.2-.75-.25 0-.45.05-1.2.15-2.05 1-1.05 1.05-1.05 2.5t1.05 2.5q.25.25.55.45.85.6 1.95.6h.1q1.35 0 2.4-1.05 1.05-1.05 1.05-2.5t-1.05-2.5z"
                    id="AccMor012__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M2.2 53.65q-.9 0-1.55.65T0 55.85q0 .9.65 1.55t1.5.65l-.1-.1q-.55-.55-.55-1.35t.55-1.4q.6-.55 1.4-.55.35 0 .65.1-.15-.25-.35-.45-.65-.65-1.55-.65m26.35-4.55q.35 0 .65.1-.15-.25-.35-.45-.65-.65-1.55-.65t-1.55.65q-.65.65-.65 1.55t.65 1.55q.65.65 1.5.65l-.1-.1q-.55-.55-.55-1.35t.55-1.4q.6-.55 1.4-.55m44.9-19.4q-.15-.25-.35-.45-.65-.65-1.55-.65t-1.55.65q-.65.65-.65 1.55t.65 1.55q.65.65 1.5.65l-.1-.1q-.55-.55-.55-1.35t.55-1.4q.6-.55 1.4-.55.35 0 .65.1M48.3 42.05q0 .9.65 1.55t1.5.65l-.1-.1q-.55-.55-.55-1.35t.55-1.4q.6-.55 1.4-.55.35 0 .65.1-.15-.25-.35-.45-.65-.65-1.55-.65t-1.55.65q-.65.65-.65 1.55M90.85 15.1q-.9 0-1.55.65t-.65 1.55q0 .9.65 1.55t1.5.65l-.1-.1q-.55-.55-.55-1.35t.55-1.4q.6-.55 1.4-.55.35 0 .65.1-.15-.25-.35-.45-.65-.65-1.55-.65M107.9.65q-.65-.65-1.55-.65t-1.55.65q-.65.65-.65 1.55t.65 1.55q.65.65 1.5.65l-.1-.1q-.55-.55-.55-1.35t.55-1.4q.6-.55 1.4-.55.35 0 .65.1-.15-.25-.35-.45z"
                    id="AccMor012__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor012__Symbol_110_0_Layer0_0_FILL"
                transform="translate(124.65 228.15)"
                id="AccMor012__col1n"
            />
            <use
                xlinkHref="#AccMor012__Symbol_66_0_Layer0_0_FILL"
                transform="translate(128.35 228.15)"
                id="AccMor012__col1d"
            />
            <use
                xlinkHref="#AccMor012__Symbol_19_0_Layer0_0_FILL"
                transform="translate(139.15 243.85)"
                id="AccMor012__col2n"
            />
            <use
                xlinkHref="#AccMor012__Symbol_11_0_Layer0_0_FILL"
                transform="translate(140.55 245.2)"
                id="AccMor012__col2l"
            />
        </g></g>
    )
}

export default AccMor012
