import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF",
    "col1s": "#747474",
    "col2n": "#252525"
}

function ObjToy033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.6 1.15q-.5-.45-1-.7Q9.7 0 8.7 0 7.5 0 6.55.55l.35.2q-.35.1-.65.3-.3.15-.5.35l-.15-.1q-1.2 1.3-1.2 3 0 1.8 1.25 3.05.2.2.4.35H6q-.05.8-1.7 2.15h-.55q-1.55 0-2.65 1.65Q0 13.15 0 15.55v.25q.05.45.1.85v.15L2 34.45h8q0-2.1.6-12.45l-.1.2q.95-2.5 2.55-10.9.1-.8.25-1.45h-.2L11.35 7.7h-.05q.2-.15.4-.35Q13 6.1 13 4.3q0-1.75-1.3-3.05l-.1-.1m-4.85.9q.05-.1.1-.1-.05.05-.05.1h-.05z"
                    id="ObjToy033__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.2 24.15h3.7v-1.7H0l.2 1.7M7.95 0H7.9q-.8.7-1.8.85-.4.05-.8.05T4.5.85Q3.5.7 2.65 0H2.6Q.75.25.9 2.15h8.8Q9.85-.2 7.95 0m6.1 7.85q0-2.4-1.1-4.05-1-1.55-2.4-1.65h-.35Q8.75 2.2 7.7 3.8q-1 1.55-1.1 3.65v19.3l1.15-1.55V13.55h2.8q1.4-.15 2.4-1.7 1.1-1.65 1.1-4z"
                    id="ObjToy033__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M8.1 0H0l.4 4.2h7.7V0z"
                    id="ObjToy033__Symbol_59_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M32.65 18.25l1.05.7q2.15.9 3.9-.15-.75-.55-1.4-.6-.25-.05-.45-.05-.4 0-.8.1L6.7 2.75Q3.8 1.4 1.35.35q-3-1.1.75 1.35.8.4 1.8.95l28.75 15.6M67.75 1.8q5.75-3.35-2.7 0l-27.4 15.6q.5.25 1 .7l29.1-16.3z"
                    id="ObjToy033__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy033__Symbol_122_0_Layer0_0_FILL"
                transform="translate(183.95 260.1)"
                id="ObjToy033__col1n"
            />
            <use
                xlinkHref="#ObjToy033__Symbol_74_0_Layer0_0_FILL"
                transform="translate(187.35 267.8)"
                id="ObjToy033__col1d"
            />
            <use
                xlinkHref="#ObjToy033__Symbol_59_0_Layer0_0_FILL"
                transform="translate(184.9 275.9)"
                id="ObjToy033__col1s"
            />
            <use
                xlinkHref="#ObjToy033__Symbol_35_0_Layer0_0_FILL"
                transform="translate(156.9 243.15)"
                id="ObjToy033__col2n"
            />
        </g></g>
    )
}

export default ObjToy033
