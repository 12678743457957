import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.9 28.6q5.7-4.95 11.85-8.8-1.15-1.2-2.25-2.3-2.7-2.4-5.4-4.4-9.3-6.85-19.35-9.85-.5-.1-.9-.25Q9.95-1.65 5 2.4 1.6 5.75.6 13.1q-.2 1.45-.25 4 0 .2-.05.4-1.15 10.2 2.05 19.95 2.2 17.1 9.9 29.55l.6 1q1.5-4.05 3.4-7.95 6.85-14.1 18.85-26.1 2.85-2.85 5.8-5.35m162.15 9.85q.05-.55.1-1.05 3.2-10.2 2.25-19.9v-.1q-.05-2.8-.25-4.3-1.05-7.35-4.45-10.7-4.95-4.05-19.85.6-.4.15-.9.25-10.05 3-19.3 9.85-2.75 2-5.4 4.4-1.15 1.15-2.35 2.4 6.2 3.85 11.95 8.85 2.8 2.45 5.55 5.2Q182.45 46 189.35 60.2q1.9 3.95 3.4 8.05 1.5-2.45 2.65-5 5.65-10.85 7.65-24.8z"
                    id="FacEar009__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M30.95 10.2q-2.75-2.35-5.8-4.4-18.6-10.05-23.3-1-.25.5-.45 1-.4.95-.7 1.95-.15.65-.2 1.3-.1.65-.15 1.55v-.7q-.002.11-.05.3-1.15 10.2 2.05 19.95v-.05q.1.35.2.75l.25.65q.1.25.2.45 1.6 4.45 4.1 8.7 3.6 6.35 9.1 12.3 6.85-14.1 18.85-26.1 2.85-2.85 5.8-5.35-.85-1.25-1.75-2.4l-.05-.05q-3.7-4.8-8.1-8.85m174.25-.65q-.1-.8-.25-1.55-.35-1-.75-2-5.85-11-23.7 0-3.1 2.05-5.85 4.4-4.35 4-8 8.75h-.05q-.95 1.2-1.8 2.5 2.8 2.45 5.55 5.2Q182.4 38.9 189.3 53.1q5.4-5.9 9-12.25 2.7-4.6 4.4-9.4.15-.6.35-1.15 3.2-10.2 2.25-19.9v.6q-.05-.85-.1-1.45z"
                    id="FacEar009__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M25.65 7.85q-.85-1.25-1.75-2.4-1.6-.8-6.75-3.2-5.2-2.4-14.4-1.5l1.2.15q2.85.35 6.45 2.5 1.55 1 5.75 4.15v.2Q12.25 7.3 8.4 8.8 3.8 10.45 0 14.55q4.45-2.1 6.5-2.3 2.05-.2 3.95-.2 1.85-.05 2.45.2-5.35 2.6-8 5.95-2.75 3.35-3.3 8.2-.2 1.55-.05 2.05.25-2.05 2.05-4.05 1.95-2 5.3-2.9-4 3-5.15 11.6l-.05.45q-.05.35-.05.75 6.4-11.3 16.2-21.1 2.85-2.85 5.8-5.35m139.25-4.4q3.6-2.15 6.45-2.5l1.2-.15q-9.2-.9-14.4 1.5-5.15 2.35-6.7 3.2h-.05q-.95 1.2-1.8 2.5 2.8 2.45 5.55 5.2 10.05 10.05 16.55 21.65-.05-.7-.1-1.25l-.05-.45q-1.15-8.6-5.15-11.6 3.35.9 5.3 2.9 1.8 2 2.05 4.05.15-.5-.05-2.05-.55-4.85-3.3-8.2-2.65-3.35-8-5.95.6-.25 2.45-.2 1.9 0 3.95.2 2.05.2 6.5 2.3-3.8-4.1-8.4-5.75-3.85-1.5-7.75-1.05v-.2q4.2-3.15 5.75-4.15z"
                    id="FacEar009__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar009__Symbol_27_0_Layer0_0_FILL"
                transform="translate(89.7 52.6)"
                id="FacEar009__col1n"
            />
            <use
                xlinkHref="#FacEar009__Symbol_19_0_Layer0_0_FILL"
                transform="translate(89.75 59.7)"
                id="FacEar009__col2n"
            />
            <use
                xlinkHref="#FacEar009__Symbol_15_0_Layer0_0_FILL"
                transform="translate(104.95 73.35)"
                id="FacEar009__col2d"
            />
        </g></g>
    )
}
export default FacEar009
