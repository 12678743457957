import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A8FFCA",
    "col1s": "#4CD482",
    "col2n": "#FFFFFF"
}

function ShiSho048({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M148 22.5v-.05q-.05-.15-.1-.2l.05-.05q-.1-.3-.2-.55l-.1.05q-.4-.95-.8-1.8-.1-.3-.2-.55-.1-.1-.15-.25-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65Q112.75 15.9 93.25 19.4q-3.25.55-6.6.95L75.4 30.75l-.05-.05h-.05L64.1 20.4q-.15-.05-.3-.05h-.3q-9.1-1.05-16.95-3.5l-1.1-.3q-8.346-2.683-16-6.85-.051-.023-.1-.05-.242-.125-.45-.25l-3.05-1.8-.2-.1q-.2-.1-.35-.2l-.8-.5q-1.586-1.025-3.2-2.15L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-2.75 3.7-5.15 7.7l-.1.2q-.2.2-.35.5l-.1.15q-.05.05-.05.1-.3.45-.55.95-.55.9-1.05 1.85-2 3.65-3.8 7.5-.05.1-.1.25v.05q-.15.25-.25.5-.45.95-.85 1.9h-.05q-.1.25-.2.55l-1.2 2.9q-.2.5-.35.95-.25.6-.45 1.2-.2.45-.35.95l18.3 6.8-.05.15.55.2.05-.15.1.05v.05l-2.25 11.6v.05q2.7 9.2-3.45 15.7l-1.1 4.5q1.3 1.05-.9 4.05h-.05q-.75 2.8-1.45 5.65l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H140.75q.15-.05.3-.05l.2-.1h.2l-.05-.2v-.1l-2.15-8.75q-2.85-1.95-1.2-4.85l-.95-3.75q-2.85-3.25-3.6-16.05l-2.25-11.75 19.1-7.1q-.1-.2-.15-.4-.15-.25-.2-.55l-.65-1.6q-.65-1.7-1.35-3.3m-14.55 24.55q-.05 0-.1-.05h.1v.05M97.9 22.4h-.05l.5-.55v.1l-.45.45M13.4 62.85h-.05v-.05h.05v.05z"
                    id="ShiSho048__Symbol_125_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M18 12.3v-.05L.25 5.45H.2Q.1 5.7 0 6l17.25 6.65-1.35 6.3.55.2 1.5-6.75.05-.1m64.3 14.4q-.1-.3-.25-.55-.15-.25-.35-.45-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6l.15.15q.45.35 1.05.5h.4q.95 0 1.6-.65.65-.65.65-1.6V27q0-.15-.05-.3M103.25 0q-.5.15-1.7.5-12.75 9.15-21 19.9l-7.5-6-.05-.05h-.05l-7.8 6Q57.1 9.95 44.2.5L43.1.2q-.25-.1-.5-.15 13.35 10.2 22.45 21.35l7.15-5.35v20.8h1.2V16l7.3 5.35q6.5-8.2 14.85-15.3h-.05l.5-.55v.1l-.25.3q3.55-3.05 7.5-5.9m42.3 5.9l.05-.05q-.1-.3-.2-.55l-.1.05-17.7 6.75v.05l.05.1 1.5 6.75.55-.2-1.35-6.3 17.2-6.6z"
                    id="ShiSho048__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M126.75 20q-.45.05-1 .1-1.45.15-2.85.3-2.95.3-5.95.6-7.6.7-15.25 1.25-1 .05-2 .15l-12.1.65q-11.8.5-23.65.5-11.75-.05-23.6-.5l-12.1-.65q-.9-.1-1.75-.15-11.55-.8-23.1-2.05L1.9 20q-.05 0-.4-.05h-.35q-.05-.05-.1 0L.5 22.2q-.25 1.05-.5 2.15l4.75.8Q14.1 26.8 26.5 28q.7.05 1.45.15l21.8 1.35q7.3.25 14.55.25 7.05-.05 14.1-.25l20.85-1.3q1.2-.1 2.45-.2 12.05-1.1 24.15-2.85.85-.15 1.7-.25.1-.05.2-.05H128.05l-1.2-4.85h-.1M124.2 9.35q-.65-3.1-1.3-6.15l-.6-3.2h-.05v.05q-.05 0-.1-.05H5.55l-.5 2.45q-.55 2.7-1.1 5.45l-1.9 7.9H2.2v.05q2 .15 5.6.5 3.95.4 8.65.75l6.05.35q1.45.1 2.95.15.5.05 1.05.05 4.85.2 9.8.4l24.35.45 30.55-.45q1-.05 2.05-.1 4.2-.15 8.45-.35 1.95-.1 3.9-.15l6.1-.35q6.05-.35 12.15-.75.95-.1 1.9-.15h.05v.05h.1l-1.7-6.9M64.55 6v.2h-1.2l1.2-.2m-61.8 7.1l.1-.1-.05.1h-.05z"
                    id="ShiSho048__Symbol_127_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.2}
                    d="M202.05 271.95q-.022-.165-.1-.35-.052-.262-.2-.55-.147-.197-.35-.4-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6l.1.1q.493.42 1.05.5.236.05.45.05.95 0 1.6-.65.65-.65.65-1.6v-.3z"
                    id="ShiSho048__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho048__Symbol_125_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho048__col1n"
            />
            <use
                xlinkHref="#ShiSho048__Symbol_126_0_Layer0_0_FILL"
                transform="translate(119.7 244.95)"
                id="ShiSho048__col1s"
            />
            <use
                xlinkHref="#ShiSho048__Symbol_127_0_Layer0_0_FILL"
                transform="translate(128.55 275.6)"
                id="ShiSho048__col2n"
            />
            <use xlinkHref="#ShiSho048__Layer1_0_FILL" />
        </g></g>
    )
}

export default ShiSho048
