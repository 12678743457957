import { AssetSvgProps } from "../../../shared/assets"

function ShiMot062({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0280D2"
                    d="M159.2 258v35.8h66.55V258H159.2z"
                    id="ShiMot062__Layer5_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M159.2 285.85v4h66.55v-4H159.2m19.85-15.9v3.95h46.7v-3.95h-46.7m0-7.95v3.95h46.7V262h-46.7m-19.85 15.9v4h66.55v-4H171.1v-7.95h7.95v-4h-7.95V258h-4v7.95h-7.9v4h7.9v7.95h-7.9z"
                    id="ShiMot062__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot062__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot062__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot062
