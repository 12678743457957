import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#B4DBFF"
}

function SkiMor030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.749}
                    d="M8.85 13.7q-.3 3.55-2 7-2.55 4.95 2.4 4.55 4.95-.4 2.9-5.2l-1.9-4.3q-.5-1-.85-2.05-.3-.75-.5-1.45 0 .7-.05 1.45M1.55.75q-.35.4-.9 1.5Q0 3.3 0 5.3q0 .7.2 1.3.6 1.25 1.85 1.9h1.7q.95-.4 1.15-.95.1-.2.1-1Q5 4.85 3.7 3 2.45 1.25 2.4 0q-.05 0-.15.05-.45.3-.7.7M98.4 24.2q-.1-.15-.85-1.45l-1.3-2.2q-.05-.15-.1-.25-.4-.85-.7-1.75-.25-.65-.45-1.25 0 .6-.05 1.25-.25 3.1-1.75 6.05-2.2 4.3 2.1 3.95 2.65-.2 3-1.85 0-.05.05-.1.2-.35.2-1.1 0-.9-.15-1.3m10.4-6.65q0-1-.15-1.45-.7-1.8-.75-1.9-.2-.65-.8-1.7-.15-.35-.35-.55-.2.95-1 2.9-1 2.35-1 3.65 0 .2.05.4.65 1.3 2.35 1.3.95 0 1.4-1.2.25-.75.25-1.45m-6.05-8.7q0-.8-1-2.8-.55-1.1-.6-1.35-.15-.25-.25-.35-.4-.65-.85-1.25-.35.5-.55 1-.4.25-.6 1.2-.45.4-.7 1.5-.25 1.05-.25 1.8 0 2.9 2.95 2.9 1.85 0 1.85-2.65m-2.9 1.45q-.1-.05-.15-.1.05 0 .1.05l.05.05z"
                    id="SkiMor030__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.8}
                    d="M234.45 209.9q-.1.45-.85 3.2 0 1.55 1.35 1.55 1.4 0 1.4-1.15 0-1.2-1.9-3.6m11.7-3.85q0-.2.1-.4.1-.15.1-.3 0-.35-.75-2.1-.1.35-.35 1.05-.2.4-.2.9 0 .4.05.45.05.2.6.4h.45m-96.35 3.55q0-1.55-.9-2.7-.25-.3-.4-.55.05 1.55-.65 2.85 0 .1-.05.2-.05.65.05 1.3.05.1.1.25.15.2.35.3l.55-.05q.6-.25.85-.65.1-.25.1-.95m-8.2-18.45h-.05q-.1.25-.3.9-.2.55-.2.95 0 .4.05.5.05.2.45.45h.45q.25-.3.25-.45 0-.4-.3-1.3-.3-.8-.35-1.05m97.1 6q.15.05.35.05.9 0 .9-.65 0-1.3-1.1-2.85-.05.1-.2.6-.05.1-.05.2-.1.45-.1 1.25 0 .05.05.95.05.25.15.45z"
                    id="SkiMor030__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor030__Symbol_31_0_Layer0_0_FILL"
                transform="translate(138.9 188.05)"
                id="SkiMor030__col1n"
            />
            <use xlinkHref="#SkiMor030__Layer1_0_FILL" />
        </g></g>
    )
}

export default SkiMor030
