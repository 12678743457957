import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M172.45 34.15q-.9-.05-6.95.45-5.45 0-5.45-2.85-.05-2.1 2.7-2.95h8.45q-2.3-1.85-6.2-6-4.25-3.7-8.35-3.95h-.2q-7.5-.35-21.7.25 1.65-4.6 9.05-8.55 3.65-3.5 7-5.3-29.8-1.65-42.4 7-.05-4.45 3.8-8.3.1-.25.25-.45 1.25-2.05 2.3-3.35-8.95-.8-14.9 3.35-3.15 2.2-5.45 5.9-6.05-4.1-9.35-5.9-1.15-.65-1.9-1.05-4.55-2-9.7-2.4Q73.1 0 72.7 0q.35 1.05.75 2.45.15.5.3 1.05 1.027 3.93 2.25 10-5.427-1.843-8.3-3.65-2.906-1.795-3.1-1.9-9.55-3.2-16.1-2.5 2.85 3.2 3.6 4.15 1.2 1.05 2.7 4.05-5.65-.05-15.95-.35 2.2 3.4 6.8 6.55-13.85.4-14.25 8.95-7.1 1.3-13.15 5.15-1 .55-1.95 1.25-3.3 2.35-8.2 6.4h-.05Q7.2 43 6.1 44.7L.35 53.6l17.3-6.95Q15 53.45 1.75 60.8q-.5.45-1.25 1.05l-.5.4q.1.7 1.25 1 .7.15 2.75.4 1.1.2 4.05 1-.4.85-.75 2.15-.05.05-.05.1l-.1 8.85v1.55q.4-.45.65-1.3.35-.55.6-1.65 2.25-4.9 4.15-6.6.4.1.95.25.4.15 4.6 2 .3 0 .55-.05-.15-.45-.2-1.1V66.6q-.1-1.2 0-2.25.15-1.7.75-2 .1-.25.7-1.2.55-.95 3.65-3.2 5.4-4.45 9.25-6.55-1.25 4.15-1.6 10.45-.1 1.2-.1 2.5 1.1-.95 1.95-2.5.55-.95.95-2.05l.05-.05H34v-.1Q39.15 51 46.55 49.2q-.05 7.8-.15 12.65-.05 1.7-.1 3.05v3l.8-1.1q2.05-2.8 3.5-4.95 2.7-3.9 3.75-5.9 3.45-7.3 6.65-12.9 3.2-.55 6.05-1.8l-3.3 7.9Q70 44.3 71.6 43.3q.9-.55 1.85-.95 1.95-.8 4.1-.75-2.55 2.9-4.1 5.35-1.85 2.95-2.2 5.3v14.9q.05-.05.05-.1.1-.15.2-.25 1.1-1.4 1.95-2.55 1.1-1.4 1.85-2.4 1.35-1.9 1.5-2.1Q84 48.25 87.6 43.9q6.05-7.15 13.75-8.35L101 45.2q0 10.9 3.35 10.9 1.35-.05 4.45-6.25 2.6-5.2 7.05-6.45.65.05 1.1.05.35 0 .6.1h.05q2.75 0 10.2 5.05 5.1 3.45 6.6 5.05 2.55-1.3.85-6.9-.75-5.5-6.65-12.5 17.4 1.15 21.2 13.9.55-.05 1.3-.05 1.35-.35 3.35-.35.9 0 2 .9 3.6 2.8 9.65 14.8 1.7-2.35 2.75-3.85.35.55.75 1.25 2.6 4.45 3.95 6.35 1.6 2.35 4.1 7.75 1.1 2.45 1.8 3.45.05.15.1.35.85-1.95 1.3-3.1 2-5.55 3.25-5.55.15 0 .3.05.85-.15 1.85-.15 2.25 0 5.75 1.4-1.55-2.65-3.95-5.35-6.8-7.6-10.65-10.6-.65-.5-1.2-.85-.05-.3-.75-.55 1.1.3 1.95.5 1.55.25 2.25.25h.6l-.05-.05h.1l-.35-.3-.05.05-4.45-4h3.3q-.75-.75-1.35-1.45-7.55-7.9-7.55-8-1.45-2-2.1-2.7 3.7-.5 4.7-4.2z"
                    id="HaiSho028__Symbol_149_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M14.25 21.1q-.7 0-1.5-.05h-4.2Q11.4 23.9 11.4 25q0 .55-.15 1.05-.8 1.7-4.4 2.1-6.2.6-6.55.75 2.3 2.2 4.2 1.8Q.35 37.25 0 40.4q5.45-4.6 8.2-6.7 4.75-3.7 10.3-2.5-1-1.55-2.95-2.7v-2.25q5.9-1.5 7.05-4.85-2.25 0-5.75-.2-1.25-.05-2.6-.1M47.3 8.45l-3.9-1.4Q41.55 7 34.15.75q2.4 5.1 6.8 11.4v-3.4l6.35-.3m26 5.65q.95.1 1.95-.05.95-.35 1.65-.9V0q-2.75 2.1-9.1 9.65-7-6.1-10.1-9.6-.35 11.15 8.15 15.4 1.1-3 1.15-4.35 2.35 1.6 3.6 2.25 1.2.65 2.7.75m-1.6 5.75h-.45q-5.2.85-7.95-1.75-1.75-1.75-4.95-5.95-1 3.8-2.1 4.3-.55.25-4.9.3-.95 0-1.9-.1-3.95-.3-8.5-1.8h-1.6l-.25.25q.75 2.2 2.95 2.25.25.4.35 1-.15.05-.2.1-3.05 1.2-8.45 5.95-7.7 6.8-7.7 11.05 0 3.2 1.5 3.2V36.8q.25-1.15 6.6-8.95.6-.75 8.65-5.55l.75 3.35q3.7-1.8 5.9-3.6.6-.5 1.05-1v5h.5q5.6-1.7 8.1-3.7.15.15.35.25-1.1 3.1-.65 12.35l.75-.1q1.5-4.25 11.65-14.1.15-.25.3-.45.1-.25.2-.45m32.65-9.3h-.6q-7.45 0-10.7-.45.2 1.5 3.75 2.9-1.15.75-8.55 1.5.8.1 3.65 1.35v2.25l-7.05.1v12.15q3.5-4.6 4.75-7.05l6.45 6.45q.35-.9.35-3.5 1.8 3.2 5.15 4.3Q99.25 27 97 21.4q4.2.35 13.55.4-3.4-1.9-11.2-6.35.4-1.5.75-2.25 2.75.85 4.25-2.65z"
                    id="HaiSho028__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho028__Symbol_149_0_Layer0_0_FILL"
                transform="translate(97 45.95)"
                id="HaiSho028__col1n"
            />
            <use
                xlinkHref="#HaiSho028__Symbol_25_0_Layer0_0_FILL"
                transform="translate(121 53.95)"
                id="HaiSho028__col1l"
            />
        </g></g>
    )
}

export default HaiSho028
