import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}

function ShiSpe077({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.25 10.35q.2-.7.4-1.35.9-3.4 2.05-6.8.2-.6.4-1.15.15-.45.3-.85.05-.1.05-.2-3.75 2-6.9 4.1Q13.6 6 11.2 8.05q-1.9 1.6-3.45 3.3-2.35 2.45-4 5.1-1.8 2.85-2.75 5.9-.55 1.75-.8 3.6-.1.6-.15 1.2Q0 28.05 0 29q0 12.15 12.4 22l.4.3q.3.2.6.45-.2-.5-.35-1 1.3-3.25 5.45-17.85-.15-.45-.25-.8-.05-.15-.05-.3-.25-.8-.4-1.6-.1-.55-.2-1.15-.4-3.4 0-6.35.05-.3.15-.6 0-.2.05-.4l.1-.4q.15-.85.35-1.9v-.1l.3-1.5q.05-.25.35-1.85.05-.2.1-.35.05-.4.15-1 .5-2.15 1.1-4.25M115.7 26.9q-7.3.45-15.3.5h-1.6q-8-.05-15.3-.5-.6-.05-1.15-.1-.35 0-.65-.05l-1.7-.1L42.8 46.8l18.75 24.1q.2 0 .4.05Q79 74.05 98.8 74.2h1.6q19.8-.15 36.85-3.25 1.25-.25 2.45-.5l10.4-27.05-31.7-16.7q-.45 0-.9.05-.3.05-.65.05-.55.05-1.15.1M188 8.05Q185.6 6 182.65 4.1 179.5 2 175.75 0q0 .1.05.2.15.4.3.85.2.55.4 1.15 1.15 3.4 2.05 6.8.2.65.4 1.35.6 2.1 1.1 4.25.1.6.15 1 .05.15.1.35.3 1.6.35 1.85l.3 1.5v.1q.2 1.05.35 1.9l.1.4q.05.2.05.4.1.3.15.6.4 2.95 0 6.35-.1.6-.2 1.15-.15.8-.4 1.6 0 .15-.05.3-.1.35-.25.8.4.35.8.75 4.9 16.7 4.3 18.1 13.4-10.1 13.4-22.75 0-.95-.05-1.85-.05-.6-.15-1.2-.25-1.85-.8-3.6-.95-3.05-2.75-5.9-1.65-2.65-4-5.1-1.55-1.7-3.45-3.3z"
                    id="ShiSpe077__Symbol_253_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M195.15 10.3q0 .55-.05 1.05-.45 7.15-7 12.7-1.6 1.2-3.35 2.35-1.6 1.05-3.3 2.1-1.4.9-2.9 1.75-3.05 1.75-6.2 3.35-3.1 1.55-6.35 3v.05q-6.7 2.8-12.2 4.65-.95.3-1.95.6-4.25 1.3-8.65 2.45-19.65 4.9-42.8 5.15h-1.6q-23.15-.25-42.8-5.15-4.4-1.15-8.65-2.45-1-.3-1.95-.6-3.15-1.05-6.25-2.25h.05L36 37.8q-4.7-1.95-9.15-4.2-3.15-1.6-6.2-3.35-1.5-.85-2.9-1.75-1.7-1.05-3.3-2.1-1.75-1.15-3.35-2.35-6.55-5.55-7-12.7-.05-.5-.05-1.05 0-5.65 3.7-10.3-2.35 2.45-4 5.1Q1.95 7.95 1 11q-.55 1.75-.8 3.6-.1.6-.15 1.2-.05.9-.05 1.85Q0 30.3 13.4 40.4q2.3-.35 9.75 1.65 3.5.95 18.45 5.6 3.65 1.25 6.95 3.2l.8.5q1.6.7 2.35 1.15 3.35 1.9 4.5 3.55.05.1 1.45 1.65.55.7.8 1.2l3.5.7Q79 62.7 98.8 62.85h1.6q19.8-.15 36.85-3.25.6-.1 1.2-.25.5-2.3 6.7-5.7 6.05-3.35 9.75-4 1.1-.2 5.05-1.45 2.25-.85 5.55-2.1 2.25-.85 4.25-1.5 4.95-2.3 9.25-2.6v-.05h3.7q.55-.05.95 0 1.1-.75 2.15-1.55 13.4-10.1 13.4-22.75 0-.95-.05-1.85-.05-.6-.15-1.2-.25-1.85-.8-3.6-.95-3.05-2.75-5.9-1.65-2.65-4-5.1 3.7 4.65 3.7 10.3z"
                    id="ShiSpe077__Symbol_254_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M69.45 8.8Q67.7 8.7 66 8.55 52.25 7.3 41.2 4.5 33.5 2.55 28.45.1 22.5 23.4 21.4 28.3q-3.8-1-6.7-2.7-5.4-3.15-7.8-8.65l.3.6q-.45-1.1-.8-2.05-.05-.3-.1-.4-.05-.1-.05-.15Q3.7 17.2 2.2 19.8q-.05.05-.05.1l-.05.05Q.6 22.6.15 25.55 0 26.7 0 27.85q0 .7.1 1.4.15 2.35 1.05 4.55 6.5 4.9 16.1 9.25Q27.2 47.5 38.6 50.5q2.1.5 4.3 1.05 3.35.75 6.8 1.45-1.8-2.1-3.05-4.35-2.85-5.05-2.9-10.9v-.3q0-2.1.35-4.05.25-1.45.75-2.85.75-2.5 2.15-4.85 3.35-5.6 10.15-10.2 5.55-3.7 12-5.9.5-.2 1-.35l1.1-.3q-.6-.05-1.15-.1-.35 0-.65-.05m99 6.15q0 .05-.05.15-.05.1-.1.4-.35.95-.8 2.05l.3-.6q-2.4 5.5-7.8 8.65-2.9 1.7-6.75 2.75 0-.1-.05-.15v-.05q-1-4.6-6.9-28.15-5.1 2.55-12.8 4.5-11.05 2.8-24.8 4.05-1.7.15-3.45.25-.3.05-.65.05-.55.05-1.15.1l1.1.3q.5.15 1 .35 6.45 2.2 12 5.9 6.8 4.6 10.15 10.2 1.4 2.35 2.15 4.85.5 1.4.75 2.85.35 1.95.35 4.05v.3q-.05 5.85-2.9 10.9Q126.8 50.9 125 53q3.45-.7 6.8-1.45 2.2-.55 4.3-1.05 11.4-3 21.35-7.45 9.6-4.35 16.1-9.25.9-2.2 1.05-4.55.1-.7.1-1.4 0-1.15-.15-2.3-.45-2.95-1.95-5.6l-.05-.05q0-.05-.05-.1-1.5-2.6-4.05-4.85z"
                    id="ShiSpe077__Symbol_255_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M46.65 28.85q-2.85-5.05-2.9-10.9-4.4-1.15-8.65-2.45-1-.3-1.95-.6-3.15-1.05-6.25-2.25h.05l-3.2-1.25q-4.7-1.95-9.15-4.2-3.15-1.6-6.2-3.35Q6.9 3 5.5 2.1 3.8 1.05 2.2 0q-.05.05-.05.1L2.1.15Q.6 2.8.15 5.75 0 6.9 0 8.05q0 .7.1 1.4Q.25 11.8 1.15 14q6.5 4.9 16.1 9.25Q27.2 27.7 38.6 30.7q2.1.5 4.3 1.05 3.35.75 6.8 1.45-1.8-2.1-3.05-4.35M173.55 14q.9-2.2 1.05-4.55.1-.7.1-1.4 0-1.15-.15-2.3-.45-2.95-1.95-5.6l-.05-.05q0-.05-.05-.1-1.6 1.05-3.3 2.1-1.4.9-2.9 1.75-3.05 1.75-6.2 3.35-3.1 1.55-6.35 3v.05q-6.7 2.8-12.2 4.65-.95.3-1.95.6-4.25 1.3-8.65 2.45-.05 5.85-2.9 10.9-1.25 2.25-3.05 4.35 3.45-.7 6.8-1.45 2.2-.55 4.3-1.05 11.4-3 21.35-7.45 9.6-4.35 16.1-9.25z"
                    id="ShiSpe077__Symbol_256_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe077__Symbol_253_0_Layer0_0_FILL"
                transform="translate(92.9 260.6)"
                id="ShiSpe077__col1n"
            />
            <use
                xlinkHref="#ShiSpe077__Symbol_254_0_Layer0_0_FILL"
                transform="translate(92.9 271.95)"
                id="ShiSpe077__col1d"
            />
            <use
                xlinkHref="#ShiSpe077__Symbol_255_0_Layer0_0_FILL"
                transform="translate(105.15 278.55)"
                id="ShiSpe077__col2n"
            />
            <use
                xlinkHref="#ShiSpe077__Symbol_256_0_Layer0_0_FILL"
                transform="translate(105.15 298.35)"
                id="ShiSpe077__col2d"
            />
        </g></g>
    )
}

export default ShiSpe077
