import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#B90000",
    "col2n": "#A37831",
    "col2s": "#43341A"
}

function AccHat114({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M3.85 10.15q0 .75-.2 1.45-.8 2.65-1.2 5.45-.05.2.1.35 2.15 2 4.5 4-.95.5-2 .75-.05 0-.1.05-1.25.35-2.5.8-.25.25-.3.7-.25 1.7-1 4.75-.8 3-.95 7.5Q.05 40.4 0 45.7l.75 11.4v.25l4.65 37.8h9.35l.35-.2 9.2-29.05.9-7.65-7.45-.85q-4.35-.15-4.25-1.1.05-1 .75-1.45.6-.5.8-.6 1.25-.8 2.8-1.4h.05l.05-.05v.05l6.35-1.6-2.65-7.9q-2.25-7.2-2.3-9.25-.1-5.05-.85-12.6l-4.25-.5 2.85-4.95q-1.25-2.6-2.6-5.05Q9.85 5.25 4.15 0q-.2 5.05-.3 10.15z"
                    id="AccHat114__Symbol_351_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M.75 110.3q-.25.1-.45.25-.15.2-.3.45l5 1.75q-1.3-2.15 2.8-6.65H3.3l-.8 1.15L1.25 110q-.1 0-.2.05-.05.1-.1.15-.05.05-.2.1m8.55-96q-.2-.75-.15-2.35 0-1.65-1.2-5.2Q6.75 3.2 4.6 0q.95 4.6 1.45 7.75.75 5.1.8 6.4l.1 4.55q.1 3.5.1 7-.05 5.8 0 11.55.15 5.55 0 11.05v3.35q0 1.5-.2-3.05-.25-4.55 0 2.2t.2 14.3q-.05 7.5.6 11.95.65 4.4-1.1 10.55-.35.95-.7 2H8.9q.25-1 .45-2 1.8-8.25 1.15-15.25-.7-10.3-.55-12.25.15-1.95.25-4.5l.2-5.05v-1.6-2q.1-4.75.1-9.5 0-5.8-.25-11.15-.25-5.45-.5-8.35-.3-2.9-.45-3.65z"
                    id="AccHat114__Symbol_316_0_Layer0_0_FILL"
                />
                <path
                    fill="#E5B000"
                    d="M206.4 112.4v-4h21.5v4h20.5v-4h18.5v4h14v-7.5q-88.75-3.413-177.5 0v3.5h9v4h16v-4h17.5v4h19v-4h19.5v4h22z"
                    id="AccHat114__Layer7_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M280.9 104.9v-3H103.4v3h177.5z"
                    id="AccHat114__Layer7_1_FILL"
                />
                <g
                    id="AccHat114__Symbol_460_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M145.9.15l.1-.1V0l-.1.15zM177.5 56.4V39.9h-34.45l-.35-.15V37.1l-.25-.2-9.55.1v2.7L0 39.9v16.5h132.6v.45l2.25.75 1.65-1.2h41z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat114__Symbol_460_0_Layer0_0_FILL"
                transform="translate(103.4 58.5)"
                id="AccHat114__col1n"
            />
            <use
                xlinkHref="#AccHat114__Symbol_351_0_Layer0_0_FILL"
                transform="translate(231.1 3.25)"
                id="AccHat114__col2n"
            />
            <use
                xlinkHref="#AccHat114__Symbol_316_0_Layer0_0_FILL"
                transform="translate(232.4 8.8)"
                id="AccHat114__col2s"
            />
            <use xlinkHref="#AccHat114__Layer7_0_FILL" />
            <use xlinkHref="#AccHat114__Layer7_1_FILL" />
        </g></g>
    )
}

export default AccHat114
