import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M170.9 35.8q-2.25-2.75-4.75-5.4-1.1-1.15-2.2-2.25-1.3-1.3-2.65-2.55Q133.9 0 95.9 0 74.8 0 56.95 7.9q-14.25 6.3-26.4 17.7-1.35 1.25-2.65 2.55-1.1 1.1-2.2 2.25Q15.5 41.2 9.3 53.7q-4.1 7.95-6.4 17.25Q1.35 78.15.6 86L.05 97.35q-.05.2 0 .45Q0 99.5 0 101.3q1.65-2.3 3.35-5.15l7.85-14.9q.1-.3.25-.6L12 79.5q.7-1.65 1.4-3.2 5.55-12.3 10.25-21 5.15-9.55 9.25-14.8 1.85-2.45 3.5-3.95.1-.1.25-.15.1-.15.25-.25.9-.8 1.8-1.3 7.15-4.55 18.25-2.65 1.5.25 3.1.65.1.05.2.05.95.2 1.95.5 3.35.85 6.8 2.35l1 .4q2.6 1.2 5.3 2.8h-.1q10.55 6.55 20.55 6.5h.45q10 .05 20.55-6.5h-.1q2.7-1.6 5.3-2.8l1-.4q3.45-1.5 6.8-2.35 1-.3 1.95-.5.1 0 .2-.05 13.2-3.2 21.35 2 .9.5 1.8 1.3.15.1.25.25.15.05.25.15 1.65 1.5 3.5 3.95 4.1 5.25 9.25 14.8 1.25 2.35 2.6 4.95 3.6 7.05 7.65 16.05.7 1.55 1.4 3.2l.55 1.15q.15.3.25.6l7.85 14.9q1.546 2.591 3.05 4.7.583-7.331-.3-14.85-.45-4.85-1.6-10.6l-.1-.6-.05-.15q-.05-.25-.1-.45-.95-4.8-2.35-9.25l-3.75-9.55q-4.9-10.35-12.5-19.6z"
                    id="HaiSho018__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M56.3 2.25q1.5.25 3.1.65-1.4-.5-3.1-1.1-.45-.2-.95-.35Q50.6 0 46.7 0 34.65 0 26.6 10.3q-3.7 4.75-10.05 18.85-5.75 13-7.75 19.8-.7 2.5-4.6 11.6L0 70.35q1.35-2 2.7-4.15l7.85-14.9q.1-.3.25-.6l.55-1.15q.7-1.65 1.4-3.2 5.55-12.3 10.25-21 5.15-9.55 9.25-14.8 1.85-2.45 3.5-3.95.1-.1.25-.15.1-.15.25-.25.9-.8 1.8-1.3Q45.2.35 56.3 2.25M116 9q-10.5 4.05-20.45 4h-.45q-9.95.05-20.45-4h-.1q10.55 6.55 20.55 6.5h.45q10 .05 20.55-6.5h-.1m54.25 12q-3.7-7.5-6.2-10.7Q156 0 143.95 0q-3.9 0-8.65 1.45-2.25.8-4.05 1.45 13.2-3.2 21.35 2 .9.5 1.8 1.3.15.1.25.25.15.05.25.15 1.65 1.5 3.5 3.95 4.1 5.25 9.25 14.8 1.25 2.35 2.6 4.95 3.6 7.05 7.65 16.05.7 1.55 1.4 3.2l.55 1.15q.15.3.25.6l7.85 14.9q1.35 2.15 2.7 4.15l-4.2-9.8q-3.9-9.1-4.6-11.6-2-6.8-7.75-19.8-2.05-4.6-3.85-8.15z"
                    id="HaiSho018__Symbol_68_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M167.85 39.65q1.45 1.6 2.85 3.25 9.35 11.35 14.1 24.45l-.7-2.4-3.75-9.55q-.2-.4-.4-.75-.15-.3-.25-.6l-.65-1.3q-.15-.2-.25-.4l-.05-.2q-.1-.15-.15-.25-.1-.2-.2-.35-.1-.2-.15-.35-.1-.2-.2-.35-.1-.15-.15-.3-4.15-7.65-10.05-14.75-1.5-1.85-3.1-3.6-.55-.6-1.05-1.15-1.35-1.45-2.8-2.9Q132.7 0 92.85 0 71.75 0 53.9 7.9q-15.8 7-29.05 20.25-1.55 1.55-3 3.15-6.05 6.5-10.6 13.6l-.7 1.1q-.5.8-.95 1.6l-.1.15q-.15.15-.2.35l-.2.3-.45.8q-.3.45-.55 1l-.1.15-.05.15q-.45.75-.85 1.55-.45.8-.85 1.65l-.15.35q-.15.2-.2.4l-.05.05Q2.95 60.3 1 66.9l-.05.05q-.05.15-.1.35Q.4 68.85 0 70.45q.5-1.6 1.05-3.15 6.85-18.95 23.4-34.25 3.85-3.55 7.9-6.6Q42.45 18.8 53.9 14.2q17.85-7.3 39-7.3 39.3 0 67.3 25.15l1.1 1q3.5 3.2 6.55 6.6z"
                    id="HaiSho018__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho018__Symbol_139_0_Layer0_0_FILL"
                transform="translate(96.55 57.6)"
                id="HaiSho018__col1n"
            />
            <use
                xlinkHref="#HaiSho018__Symbol_68_0_Layer0_0_FILL"
                transform="translate(97.2 87.55)"
                id="HaiSho018__col1d"
            />
            <use
                xlinkHref="#HaiSho018__Symbol_20_0_Layer0_0_FILL"
                transform="translate(99.6 57.6)"
                id="HaiSho018__col1l"
            />
        </g></g>
    )
}

export default HaiSho018
