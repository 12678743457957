import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.2 17.6Q51.382 9.87 53.05.05H.2Q.15.45.1.9q-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75m55.7 2.85q3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.05-.4-.05-.8H53.5q-.05 0 0-.05h-.05q1.6 9.85 8.8 17.6 8.35 8.95 19.4 9.75 11 .85 18.25-6.9z"
                    id="FacEye015__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.8.7q0-.35-.05-.7H.1Q0 .35 0 .7q0 1.4 1 2.35.95 1 2.4 1.05 1.4-.05 2.4-1.05 1-.95 1-2.35M32 .7q0 1.4 1 2.35 1 1 2.45 1.05 1.4-.05 2.35-1.05 1-.95 1-2.35 0-.35-.05-.7H32.1q-.1.35-.1.7z"
                    id="FacEye015__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.251}
                    d="M236.45 146.15q-8.25-9.05-19.35-9.85-7.25-.55-12.9 2.7-2.95 1.6-5.5 4.35-3.98 4.404-5.55 10.1-.293 1.029-.5 2.1-.05.2-.05.35-.053.382-.1.75-.044-.393-.1-.8 0-.15-.05-.35-.202-1.045-.5-2.05-1.552-5.726-5.55-10.15-2.55-2.75-5.5-4.35-5.65-3.25-12.9-2.7-11.1.8-19.35 9.85-8.212 8.55-9.15 19.9h1.6q.974-10.69 8.7-18.8 7.8-8.55 18.3-9.35 6.75-.55 12 2.5h.05q2.7 1.5 5.1 4.05 3.574 3.98 5.05 9.05.363 1.169.45 1.8.144.635.7 3.05.55 2.415.6 3.25.333 3.262.4 4.4l.45.05q.019-.124.4-3.65.387-3.523.7-4.8.308-1.28.4-2.1.141-.81.5-2 1.493-5.04 5.05-9 2.4-2.55 5.1-4.05h.05q5.25-3.05 12-2.5 10.5.8 18.3 9.35 7.708 8.091 8.65 18.75h1.65q-.956-11.319-9.15-19.85z"
                    id="FacEye015__Layer6_0_FILL"
                />
            </defs>
            <g id="FacEye015__col1n" transform="translate(139.3 165.95)">
                <use
                    xlinkHref="#FacEye015__Symbol_18_0_Layer0_0_FILL"
                    id="FacEye015__col1n_FL"
                />
            </g>
            <g id="FacEye015__col2n" transform="translate(173 166)">
                <use
                    xlinkHref="#FacEye015__Symbol_23_0_Layer0_0_FILL"
                    id="FacEye015__col2n_FL"
                />
            </g>
            <use xlinkHref="#FacEye015__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye015
