import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#035203",
    "col1n": "#008300",
    "col2n": "#FF0000"
}

function AccHat054({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M229 39.4q-10.85-18.45-25.6-21.95-7.9-1.8-16.9.65l-3.85 1.25q-2 .65-6.7.8-4.7.15-20.85-6.6-1.05-.6-2.05-1.15Q129.25-.2 98.35 0q-6.8.5-13.15 1.65Q68.55 4.7 54.6 12.4 24.7 28.9 7.15 66.7l-.85 1.95q-.7 1.55-1.4 3.2-2.4 5.45-3.85 10.9-.1.25-.15.5l-.9 4.1 20.1 1.05V68.8l33.5 7.6 1.8-19.1 21.4 12.4 18-20.3 23 23 14.9-13.7 2.45 17.75 33.15-6.35.6 18.5 17.4-1.4.25-4.2q0-.05.05-.05 0-.6.05-1.2.15-3.9.9-7.3 6-9.35 12-6.65.15.05.35.15 1.7.9 3.5 2.4 5.45 4.55 11.7 14.6 10.3 16.5 14 33.6.4 1.85.7 3.7l.2.05q.2.1.2.35l.5.1 1.05-4.2q1.3-5.25 2.15-9.2.95-4.35 1.3-7.1.65-5.15.9-14.25v3.4q.95-23.6-2.2-38.25-1.8-8.3-4.9-13.75z"
                    id="AccHat054__Symbol_272_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M178.7 39.05q0-4.7-.9-8.45-.3-1.4-2.3-7.55-1.3 2.1-3.5 3.8-2.7 2.05-6.8 3.5-2.75 1-6.15 1.7-6.55 1.35-12.35 2.2.5-4.95-.8-11.25-1.25-6.35-6.8-13.95-1.8 3.6-6.55 7.6-4.7 3.9-12.4 8.1Q113.8 9.8 99.5.25l.05-.25q-.1.05-.15.15-13.55 10.2-19.65 24.6-7.7-4.2-12.4-8.1-4.75-4-6.55-7.6Q55.25 16.65 54 23q-1.3 6.3-.8 11.25-5.8-.85-12.35-2.2-12.5-2.6-16.45-9-2 6.15-2.3 7.55-.9 3.75-.9 8.45 0 1 .25 4.5.3 3.9.55 5.5-3.8 1-8.4 0-3.75-.85-6.1-1.75-1.6-.6-3.65-1.9-2.1-1.3-3.45-2.75-.4 2-.4 8.4Q0 58.3 1 62.5q1.2 4.85 4.2 8.2l.45.45h.05q7.5-9.55 21.75-17.55.15-.1.3-.15 66.6-28.7 137.45-2.65 3.4 1.25 6.8 2.6.1 0 .2.05t.25.15q14.25 8 21.8 17.55l.45-.45q3-3.35 4.2-8.2 1-4.2 1-11.45 0-6.4-.4-8.4-1.3 1.35-3.3 2.6-2 1.25-3 1.75-.35.15-.8.3-2.35.9-6.1 1.75-4.3.8-8.4 0 .25-1.6.55-5.5.25-3.5.25-4.5z"
                    id="AccHat054__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill="#C90"
                    d="M338.8 156.8q-1.45-3.3-4.5-5.3-1-.55-2.15-1.15-.2 0-.4-.05-4.35-1.75-8.75.2-2.15.95-3.8 2.45-4.2.7-5.4 3.5-.3.75-.2 1.6-.1 2.25 2.1 4.8.2 1.7.85 3.4 1.35 2.45 3.2 4.15 1.3 1.4 3.05 2 .204.053.45.25 1.15.35 2.35.7 1.05.15 2.05.25 2.3-.1 4.7-1.05 1.95-.8 3.35-2.05 2.7-.3 4.15-1.8.5-.75 1-1.55.9-2.35-1.2-5.5.1-2.5-.85-4.85z"
                    id="AccHat054__Layer8_0_FILL"
                />
                <g
                    id="AccHat054__Symbol_180_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M12.35 15.6q0-.05.05-.05l-.4-.1q.15.05.35.15z" />
                    <path d="M14.15 9.85q.7-1.5 1.7-2.8 3-4.05 8.7-7.05-4.65.45-8.7 2.25-3.25 1.4-6.15 3.7-4.5 3.55-7.1 8.7-.05.05-.05.15Q.85 18.1 0 22.1q6-9.35 12-6.65l.4.1q0-.2.05-.35 0-.1.05-.2.75-3.3 1.65-5.15z" />
                </g>
                <g id="AccHat054__Layer8_1_FILL">
                    <path
                        fill="#6D4D01"
                        d="M330.3 163.85q-.9 2.1-4.25 5.65-1.15 1.3-2.75 2.75-.295.197-.5.15.204.053.45.25 1.15.35 2.35.7 0-.2.3-.35 1.55-1.25 3-2.75 2.8-3.15 4.4-5.85.8-1.55-.5-2.35-1.2-.55-2.5 1.8m4.3-9.85q.05-.4-.3-2.5-1-.55-2.15-1.15-.2 0-.4-.05.35 1.95.5 2.55.55 1.95 1.4 2.05.6.1.95-.9z"
                    />
                    <path
                        fill="#FDB713"
                        d="M334.65 156.9l-.2-.2-2.35-1.15q-.95-.55-1.95-.85-5.5-2.3-10.35-1.7-.4-.05-.6-.05-4.2.7-5.4 3.5-.3.75-.2 1.6-.1 2.25 2.1 4.8-.35-2.3.6-4.9.8-1.55 1.85-1.65 1.25-.25 1.9-.15 4.15-.1 8.9 1.7 1.4.6 2.7 1.4l2.35 1.3 3.75 3.35q.895 1.1.9 1.65.052.55-.35 1.95-.35 1.2-2.6 3 2.7-.3 4.15-1.8.5-.75 1-1.55.9-2.35-1.2-5.5-.35-.45-.7-1.1-1.85-1.9-4.1-3.6l-.2-.05z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat054__Symbol_272_0_Layer0_0_FILL"
                transform="translate(99.1 30.1)"
                id="AccHat054__col1n"
            />
            <use
                xlinkHref="#AccHat054__Symbol_180_0_Layer0_0_FILL"
                transform="translate(286.65 82.45)"
                id="AccHat054__col1d"
            />
            <use
                xlinkHref="#AccHat054__Symbol_91_0_Layer0_0_FILL"
                transform="translate(92.5 66.05)"
                id="AccHat054__col2n"
            />
            <use xlinkHref="#AccHat054__Layer8_0_FILL" />
            <use xlinkHref="#AccHat054__Layer8_1_FILL" />
        </g></g>
    )
}

export default AccHat054
