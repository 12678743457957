import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF7B00"
}

function ShiMot002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.75 0H0v33.75h33.75V0z"
                    id="ShiMot002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <g id="ShiMot002__col1n" transform="translate(175.7 263.1)">
                <use
                    xlinkHref="#ShiMot002__Symbol_2_0_Layer0_0_FILL"
                    id="ShiMot002__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default ShiMot002
