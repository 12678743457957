import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#D0D0FF",
    "col1n": "#ADADFF",
    "col2n": "#B46BFF"
}

function ShiLon087({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.3 44.6v-.75l-.05-.05.05-.1V16.75L104.8.15q-14 6.2-29.95 7.6-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.9q-2.85-.05-5.6-.2l-1.1-.05q-3.9-.3-7.65-.85-12.5-1.9-23.7-6.95L3.65 21.1l1.5 23.5v.1l-2.8 11.65q-.55 2.1-1.05 4.2l-.9 3.6L0 65.6q.2.05.45.05H.8l5.45 4.95 39.65 1.65h44.2l25.95-1.35 11.9-2.4 2.85-3.55q.25-.2.45-.5L126.3 44.6m-.3-.55q-.05-.05-.1-.05l-.15-.6q.02.05.05.05l.05.05q.2.55.15.55m.55 3.85l-.05-.2q.05.05.1.15l-.05.05z"
                    id="ShiLon087__Symbol_527_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M34.95 59l2.25 5.35 20.7-10.3-1.55-5.4L34.95 59m7.6-44.15l.25-.8-.05-.05q.253-.659.4-1.15-7.55-3.4-14.5-8.2L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.7-.3 12.2 4.25.25.2.45.45v.1l.95-.8L27 35.3q8.9-4.7 12.6-13.35l.65-1.25q.7-1.45 1.3-2.95.5-1.4 1-2.9m81.6 48.55l1.85-5-18.7-9.55-2.15 5.2 19 9.35m38.55-18.5q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-7.1 4.9-14.85 8.35v.05q.15 1.15.15 1.2.4 1.45.9 2.8 1.35 3.75 3.2 6.85 5.2 8.55 11.85 11.6.05.05.1.05v-.05-.1q0-.05.05-.05v.05l4.5 20v.3q.05.3.15.5l.05.05q0 .05.05.05l.05.05q.2.55.15.55-.05-.05-.1-.05.15.85.4.6.5-.45.9-.85.4-.45.9-.8.8-.65 1.7-1.15.35-.2.7-.35 2.55-1.25 5.7-1.5 1.3-.1 2.75-.05l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5m-40.4-31.55h.2q-.05.15-.2 0m20.85 43.7l-.05-.05.2-.2-.15.25m-.2-.4l.05-.05.6-1.45q-.412 1.05-.6 1.5h.05q-.048.048-.05.1v-.05l-.05-.05z"
                    id="ShiLon087__Symbol_477_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M131.7 7.45q0 .05.05.1l-.9.2q-.5.15-1 .25-.55.15-1.05.25-12.6 2.95-26.65 4.4.45.1-2.75.05h-.15q-.75-.05-1.7-.05-.85-.05-1.9-.05-.05-.05-.1 0-1-.05-2.2-.1H93q-.25-.05-.55-.05H92l-1.7-.2h-.25q-.85-.15-1.75-.3-1.75-.35-3.7-.85l-.4-.1-1-.3q-.2-.1-.45-.15-.2-.05-.35-.1-.65-.25-1.25-.5-.35-.1-.7-.25l-.2-.1Q75.05 7.2 67.6 0v.6h-1.65V0Q58.2 7.15 53 9.55q-.15.1-.3.15-1 .4-1.95.75-.15.05-.35.1-.25.05-.45.15-.05 0-.1.05-.7.15-1.3.35-1.95.5-3.7.85-.2.05-.35.1-.85.1-1.65.2-.85.1-1.65.2h-.5q-.3 0-.55.05-1.4.05-2.55.1-.05-.05-.1 0-1.05 0-1.9.05-.95 0-1.7.05-3.35.05-2.9-.05Q16.35 11.15 3.35 8l-.9-.2q.05-.1.1-.15-.1.05-.25.1-.2.05-.4.05-.25 0-.45-.05L.9 9.9v.1q-.5 1.6-.9 2.8 6.4 1.55 11.75 2.65Q30.7 19 42.05 18.1q1.95-.15 3.7-.45l1.5-.3q1.65-.35 3.25-.85h32.15q.35.15.75.25 1.2.35 2.5.6 1.2.25 2.5.45 1.85.25 4 .4 11.05.6 29-2.75 5.3-1.1 12.3-2.9.05-.05.15-.05.05-.05.1-.05l-.4-2.55h-.05l-.8-3.3q-.3.4-.6.65l-.4.2M68.05 2.2l-.05.15V2.3l.05-.1m-2.9.15l-.05-.15.05.1v.05z"
                    id="ShiLon087__Symbol_432_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon087__Layer9_1_FILL"
                />
                <g id="ShiLon087__Layer9_0_FILL">
                    <path d="M175.35 316.9q.3-.25.6-.55.4-.4.7-.85-2 .7-4.15 1.15 1.4.1 2.85.25m11-7.3l-.25.25q-.1.05-.1.1 2.8.05 4.75 2 1.15 1.15 1.65 2.55.45-1.6 1.7-2.85 1.5-1.45 3.4-1.85-.15-.1-.25-.25-.35-.35-.7-.65l-4.45-4.4-5.75 5.1m23.15 7.2q.8-.05 1.65-.15-1.3-.25-2.5-.6l.3.3q.25.25.55.45m-23.7-4q-1.65 0-2.85 1.2-1.15 1.15-1.15 2.85 0 .2.05.45 4 .2 7.9.25.05-.35.05-.7 0-1.7-1.15-2.85-1.2-1.2-2.85-1.2m17.15 4.5q.05-.35.05-.75 0-1.7-1.15-2.85-1.2-1.2-2.85-1.2-1.65 0-2.85 1.2-1.15 1.15-1.15 2.85 0 .5.1 1 4-.05 7.85-.25m-16.25-23.5q-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .216.05.4.106.656.6 1.15.65.65 1.6.65.95 0 1.6-.65.494-.494.6-1.15.05-.184.05-.4v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05m.65-17.25v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6m-.65-20.45q-.65-.65-1.6-.65-.95 0-1.6.65-.49.49-.6 1.15-.05.216-.05.45 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6 0-.234-.05-.45-.11-.66-.6-1.15m6.15-6.6h-.95q-.35-.002-.7 0v50.4h1.65v-50.4z" />
                    <path
                        fill="#999"
                        d="M205.85 315.05q-.45-.2-.85-.35-3.95-1.75-7.15-4.6-.15-.1-.3-.25l-.05-.05q-1.9.4-3.4 1.85-1.25 1.25-1.7 2.85-.5-1.4-1.65-2.55-1.95-1.95-4.75-2l-.5.4q-2.5 2.15-5.4 3.65-.35.2-.75.4-1.3.6-2.7 1.1-.3.45-.7.85-.3.3-.6.55-1 .65-2.25.65-1.15 0-2.1-.6-1.75.3-3.7.45.4.6.9 1.1 2.05 2.05 4.95 2.05 2.9 0 4.9-2.05.5-.5.9-1 .15 2.5 1.95 4.35 2.05 2.05 4.95 2.05 2.9 0 4.9-2.05 1.25-1.3 1.75-2.85.5 1.4 1.6 2.5 2.05 2.1 4.95 2.1 2.9 0 4.9-2.1 1.8-1.75 2.05-4.15.35.6.9 1.15 2.05 2.05 4.95 2.05 2.9 0 4.9-2.05.5-.5.9-1-2.15-.15-4-.4-.85.45-1.85.45-1.3 0-2.3-.75-.3-.2-.55-.45l-.3-.3q-1.45-.45-2.8-1m-2.85 1.5q0 .4-.05.75-.2 1.2-1.1 2.05-1.2 1.2-2.85 1.2-1.65 0-2.85-1.2-.8-.75-1.05-1.8-.1-.5-.1-1 0-1.7 1.15-2.85 1.2-1.2 2.85-1.2 1.65 0 2.85 1.2 1.15 1.15 1.15 2.85M182.95 314q1.2-1.2 2.85-1.2 1.65 0 2.85 1.2 1.15 1.15 1.15 2.85 0 .35-.05.7-.2 1.25-1.1 2.1-1.2 1.2-2.85 1.2-1.65 0-2.85-1.2-1-.95-1.1-2.35-.05-.25-.05-.45 0-1.7 1.15-2.85z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon087__Symbol_527_0_Layer0_0_FILL"
                transform="translate(126.7 241.45)"
                id="ShiLon087__col1n"
            />
            <use
                xlinkHref="#ShiLon087__Symbol_477_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon087__col1l"
            />
            <use
                xlinkHref="#ShiLon087__Symbol_432_0_Layer0_0_FILL"
                transform="translate(125.25 299.3)"
                id="ShiLon087__col2n"
            />
            <use xlinkHref="#ShiLon087__Layer9_0_FILL" />
            <use xlinkHref="#ShiLon087__Layer9_1_FILL" />
        </g></g>
    )
}

export default ShiLon087
