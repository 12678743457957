import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF8E8E",
    "col1n": "#FFC4C4"
}

function AccMor059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M62.75 32.55q-.95-5.2-5.75-5.05-.15 0-.25.05l-13.55.5q.15-1.3-.05-2.65-.7-4.45-4.3-7.05-3.6-2.65-8-2-1.05.15-2 .5L25.2 4.35q0-.1-.05-.2V4.1q-1.3-4.7-6.5-4h-.05Q16.7.55 15 1.35 9.55 3.4 6.25 8.5 1.15 15.65.3 21.75l-.3 1.1q.15 5.7 5.7 5.65h.1q-.052-.002-.1-.05.05 0 .15.05 1-.05 2.15-.25l13.85-2.85q-.25 1.5.05 3.2.1.85.35 1.65.1.4.3.8.75 2 2.3 3.55.55.55 1.25 1.05 2.95 2.2 6.5 2.15.75-.05 1.55-.15 2.05-.3 3.75-1.35l1.15 14.8q.3 2.45 1.15 3.95v.05q1.5 2.7 4.8 2.35.7-.05 1.55-.3l1-.6q6.85-3.75 10.85-9.6 3.95-4.9 4.25-10.55l.1-1.85q.05-1 0-2z"
                    id="AccMor059__Symbol_156_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M31.2 36.4q1.25-.15 2.5-1.05.7-.55 1.2-1.2 1.4-1.9.45-2.5-1.9-2.4-6.35 0-1.75.95-4.3-.4-.1-.05-.1-.1-3.1-.7-1.8 2.5.75 1.75 3.5 2.4 2.7.7 3.2.6.45-.15 1.7-.25M22.3 4.15q0 .1.05.2h.05q0-.1-.05-.2V4.1q-1.3-4.7-6.5-4h-.05q-1.9.45-3.6 1.25Q13.9.6 15.8.15h.05q5.15-.65 6.45 4m-1.25 13q-.45-.7-.5-6.35-.1-5.6-4.55-7-4.5-1.4-4.75 1.4-.3 2.7 4 7.15t4 5.85q.75 3.6-5.6 3.45l-8.05-2.8q-.05 0-1.2.45-1.15.4-3.05 2.45Q-.1 23.3 0 24.85q0 .45.15.9 1.75 1.9 19.45-1.7 1.95-4.5 3.5-4.75-.85-.5-1.25-.95-.4-.5-.8-1.2M45 46.45q-.95-.9-3.05-3.1-3.8-4.1-1.6-7.4 2.2-3.35 8.55 1.65 6.3 5 7.75 1.8 1.4-3.25.6-5.55-.85-2.3-9.25-1.6-7.65.6-7.9-1.65-.2.8-1.2 2.3-1.4 2.05-2.85 2.9 1.75 19.85 5.5 19.45 1.7-.35 2.55-1.5 1.8-2.45 1.5-5.2-.15-1.05-.6-2.1m14.45-15.5q.3.75.45 1.7.05.95.05 1.95.05-1 0-2-.15-.9-.45-1.65h-.05z"
                    id="AccMor059__Symbol_97_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor059__Symbol_156_0_Layer0_0_FILL"
                transform="translate(211.35 67.9)"
                id="AccMor059__col1n"
            />
            <use
                xlinkHref="#AccMor059__Symbol_97_0_Layer0_0_FILL"
                transform="translate(214.05 67.85)"
                id="AccMor059__col1d"
            />
        </g></g>
    )
}

export default AccMor059
