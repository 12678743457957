import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M199.95 14.5q-18.15-11.6-35.65.9l-1.05.5q.15-.1.3-.15Q141.3-3.6 106.7.85q-.5.05-.95.15-2 .25-3.95.65-33.25 6-55.9 27.2Q29.2 47.8 24.25 60.6q18.25-22.35 33.8-31.85 3.5-1.95 6.2-3.5-15.3 10.3-28.65 30.4-.25.35-.5.75-.65.85-1.3 1.75-1.25 1.75-2.35 3.55-5.05 7.95-8.05 16.65-3.6 10.35-6.95 20.9-.1.2-.15.45l-.4 1.2q-1 2.95-2.25 5.8-1.75 3.45-9.6 7.35h-.1q-.2.1-.35.1 1.35.4 2.75.4 5-.3 10.1-1.15 2.45-.45 5-.95 2.95-.65 5.95-1.5-.3 1.2-.65 2.35-1.95 6.15-5.3 10.5l-.7.8q-2.1 2.6-4.3 4.9-7.4 7.6-16.45 11.75l.1.2q8.2.65 16.35-1.55 9.75-2.6 19.5-9.3.9 4.4.4 10.95-.55 6.65-2.45 11.35 6.1.95 11.5-1.6-9.75-21.8-7.5-41.7.1-1.1.3-2.15 4.3-1.65 8.65-3.6 8.2-3.75 16.7-8.7-7.4 9.15-16.6 14.15 6-1.55 11.2-4.15 5.95-3 10.85-7.35 7.2-5.65 13.6-12.7l-1.1 1.8q-15.1 27.8-33.45 33.65-.15 0-.25.05l-.05-.05-.6.2-.3.1q-1.1.35-2.15.6l-4.5.7q3.4.3 6.65.4 15.95.5 29.35-3.5.95-.25 1.9-.55 20.5-6.7 35.75-22.75.65-.7 1.3-1.3 12.15-12.9 21.25-25.25-3.45 23.05-18.8 34.25-2.3 1.85-4.85 3.4-.45.2-.85.45l-3.25 1.15q4.5-.1 8.95-1.6 24.65-7.8 38.2-32.4 1.65-2.9 2.85-5.75 2.4-5.85 4.55-11.8 2-5.65 3.85-11.4.85-2.8 1.35-5.35.15.05 7.5 6.2 3.05 2.55 7 6.75l12.4 14.65q13.15 17.4 20.45 36.35l1.1 3.15q1.8 5.2 2.4 7.35 1 3.8 1.5 6.6.5 2.75.7 5.95.05.55.05 1.1 0 1.6-.45 4.35-.5 2.7-1.5 7.9-1.05 5.2-2.25 11.45 2.05 6.4 4.95 9.75 2.85 3.3 3.3 3.9-3.5-11.25-1.15-16.35 1.95-4.45 8.2-4.35 3.1.15 5.2.75.95.3 1.7.65-.95-1.2-1.7-2.55-4-7.45-1.65-19.85.6-3.1 1.65-6.05.2-.65.45-1.25 4.9-14.55 1.4-36.85-.65-5.8-1.85-11.2-3.2-14.3-10.45-26.4-10.1-16.65-27.4-27.65z"
                    id="HaiSho061__Symbol_182_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.65 111.2q-.3 1.2-.65 2.35 5.85-1.4 11.15-3.7.1-1.1.3-2.15-4.75 1.95-10.8 3.5m64.4-14.75q9.8-11.05 13.8-25.1 3.8-13.4 2.7-28.15-3.8 9.85-9.35 19-7.3 13-16.35 23.15-6.2 10.3-14.25 20.4-8.8 10.9-20.55 15.1-.05.05-.1.05l.1.1q25.5-3.85 44-24.55m-34.6-63q5.8-4.95 9.25-7.9 3.4-2.95 6.75-5.05-5.65 3.1-8.95 5Q22.2 35.8 8.85 55.9q5.65-7.7 10.35-12.25 5.4-5.3 11.25-10.2M109.8 69.2q-.05.1-.15.25-5.1 26.4-23.65 37.65 6-3.4 10.8-7.65.55-.5 1.2-1.3.6-.8 6.25-7.5 5.6-6.75 8.2-17.05 2.6-10.3 2.05-12.2-2.1 4.2-2.8 5.2l-1 1.4-.9 1.2m27.1-53.3v-.15q-1.05-1.2-2.6-2.45-.55-.5-1.15-.95-2.85-2-5.85-3.65Q111.5-.05 92.4 0q-.85 0-1.7.05-.05-.05-.1-.05-3.85.2-7.85.7-3.95.5-7.7 1.2Q96-.85 105.45 5.75q3.55 2.5 6.85 6.55-10.9-.2-19.05 1.95-28.45 9.2-62 41-2.15 2.1-4.2 4.25-2.5 2.6-6.1 6.75-.4.5-.85 1Q14.25 74 5.6 84.7q8.5-8.95 14.5-14.6 4.2-3.95 7.2-6.3 2.95-2.3 6.3-5.25Q78.7 18.4 105.25 16.3q5.3-.85 10.8-.85 5.2.05 11.25.3 4.55.15 9.6.45h.1l.1-.1-.2-.2m53.85 55.9q-4.9-10.3-13.8-18-14.2-11.95-28.6-18.6 11.15-4.6 31.05 9.75-19.9-21.55-36.65-12.3-.1 3.45-.9 7.3-.05.15-.1.4.1.1.2.15 13.4 12.85 25.3 27.25 13.8 16.65 22.7 41.05 1.05 2.25 2.15 6.05 1.05 3.8 1.8 6.8.6 2.55.8 5.75.05.45.05.95v.15q4.3-23.45.5-43.6-1.55-7-4.5-13.1z"
                    id="HaiSho061__Symbol_97_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho061__Symbol_182_0_Layer0_0_FILL"
                transform="translate(61.15 44.7)"
                id="HaiSho061__col1n"
            />
            <use
                xlinkHref="#HaiSho061__Symbol_97_0_Layer0_0_FILL"
                transform="translate(87.9 44.45)"
                id="HaiSho061__col1d"
            />
        </g></g>
    )
}

export default HaiSho061
