import { AssetSvgProps } from "../../../shared/assets"

function ShiMot111({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M225.7 276.05V270q-11.384-2.333-22.25 0-1.568 3.488 5.55 6.05 8.363 1.367 16.7 0m-11.15 6q-5.763 3.195 5.55 6 2.813 1.538 5.6 0v-6q-4.588-3.117-11.15 0m-22.15 0q-6.383 3-5.5 6 5.525 2.462 11.05 0 .819-3-5.55-6M159.2 270v6.05q8.535 2.337 16.55 0 7.625-2.346 5.55-6.05-9.692-3.168-22.1 0m0-12v6q12.936 1.68 27.6 0 4.122-.143 5.65-1.35 1.95.765 5.45 1.35 12.916 4.723 27.8 0v-6h-66.5m0 24.05v6q2.775 2.56 5.55 0 12.641-2.55 5.5-6-3.144-3.333-11.05 0z"
                    id="ShiMot111__Layer1_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M225.7 294.1v-6.05h-5.6q-4.539 3.457 5.6 6.05M209 276.05q-5.06 4.768 5.55 6h11.15v-6H209m16.7-6.05v-6h-27.8q-.564 4.145 5.55 6h22.25m-38.8 18.05q-7.457 2.029-5.55 6.05h22.2q1.868-3.492-5.6-6.05H186.9m-27.7-12v6h11.05q7.516-2.725 5.5-6H159.2m0 12v6.05q5.25-3.025 5.55-6.05h-5.55m0-24.05v6h22.1q7.81-2.81 5.5-6h-27.6z"
                    id="ShiMot111__Layer1_1_FILL"
                />
                <g id="ShiMot111__Layer1_2_FILL">
                    <path
                        fill="#9B00C2"
                        d="M203.55 294.1h22.15l-5.6-6.05q-13.18-.962-22.15 0l5.6 6.05m-22.2 0l5.55-6.05q-10.447-2.064-22.15 0l-5.55 6.05h22.15z"
                    />
                    <path
                        fill="#009C2D"
                        d="M192.45 273.45q-7.835.172-16.7 2.6l-5.5 6q10.327 1.74 22.2 0 12.021 1.918 22.1 0l-5.55-6q-7.59-2.776-16.55-2.6z"
                    />
                    <path
                        fill="#F90"
                        d="M186.8 264l-5.5 6q11.075 3.464 22.15 0l-5.55-6q-4.366-3.417-11.1 0z"
                    />
                </g>
                <g id="ShiMot111__Layer1_2_MEMBER_0_FILL">
                    <path
                        fill="#FF0"
                        d="M181.3 270l-5.55 6.05H209l-5.55-6.05H181.3z"
                    />
                    <path
                        fill="#0040D4"
                        d="M192.4 282.05l5.55 6h22.15l-5.55-6H192.4m-22.15 0l-5.5 6h22.15l5.5-6h-22.15z"
                    />
                    <path
                        fill="red"
                        d="M192.35 258h-.05l-5.5 6h11.1l-5.55-6z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot111__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot111__Layer1_1_FILL" />
            <use xlinkHref="#ShiMot111__Layer1_2_FILL" />
            <use xlinkHref="#ShiMot111__Layer1_2_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot111
