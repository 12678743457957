import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M26.15 20.9q4.5 1.35 9.05 0l6.85-6.45q-5.55 4.2-16.8 1.95-11.3-2.25-15.55-4.65-3.25-1.7-5.45-4.2Q2 5.05 1.5 3.35 1.05 2.35 0 0q.95 6.4.45 6.5H.5q2.8 4.35 7.4 7.5 7.05 4.75 18.25 6.9M84.8 3.35q-.55 1.7-2.8 4.2-2.2 2.5-5.4 4.2-4.3 2.4-15.55 4.65-11.3 2.25-16.85-1.95l6.9 6.45q4.5 1.35 9 0 11.2-2.15 18.25-6.9 4.6-3.15 7.45-7.5h.05q-.55-.1.45-6.5-1.1 2.35-1.5 3.35z"
                    id="FacBro016__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro016__Symbol_16_0_Layer0_0_FILL"
                transform="translate(149.35 118.85)"
                id="FacBro016__col1n"
            />
        </g></g>
    )
}

export default FacBro016
