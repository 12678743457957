import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#FFE69A",
    "col2n": "#FFF5D7"
}

function ObjToy084({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M9.9 42.95q-.15 0-.3-.05h-.3q-3.8-.6-6.45-2.1Q.9 39.6.4 38.35q-.35.5-.4 1.1-.25 1.85 2.55 3.5 1.55.9 3.45 1.45 1.4.4 3.05.65h.3q.15.05.25.05h.05q3.85.4 6.8-.5 1.25-.4 2.05-.9 1.15-.8 1.3-1.9.05-.6-.15-1.15-.75 1.05-2.9 1.8-2.95.85-6.85.5M17 32.5l.05-.05q.55-1.8-1.6-2.55.25 2.3-4.25 2.1h-.6v-.05q-4.4-.8-3.65-3.05-2.25.25-2.1 2.1l.05.05q1.25 2.2 5.5 2.75.3 0 .6.05 4.25.45 6-1.35m-7.85-18q-1.45-.7-1.35-1.45 0-.15.05-.3-1.55.5-1.7 1.4-.1 1.1 1.8 2.1.8.4 1.85.75 1.1.35 2.5.55h.3l.3.05q1.4.15 2.6.1 1.05-.15 2-.3 2.05-.55 2.15-1.65.15-.9-1.3-1.75.1.15.1.3-.1.75-1.8 1.1-1.5.35-3.5.15H13q-.05-.05-.1-.05h-.35q-2-.3-3.4-1M13 11.8h.4q.1.05.2.05.25.05.5 0 1.45.1 2.65-.15.4-.1.7-.15.8-.3.85-.8.05-.45-.55-.9-.15.45-1.3.65-1.2.25-2.7.1-.15 0-.3-.05h-.3q-1.5-.25-2.6-.75-1-.45-1.05-.95-.75.3-.8.75-.05.5.65 1 .25.1.6.3 1.1.5 2.55.75.25.05.5.15m7.3-8q.1-.2.15-.5.05-.3-.05-.6-.05-.3-.3-.6-.4-.5-1.05-.55h-.25q-.5 0-.9.3l-.1.1q-.05-.15-.2-.35-.3-.4-.75-.7-.4-.4-.95-.6-.5-.2-1.15-.3-.6-.05-1.2.05-.55.05-1.1.3-.45.2-.9.55-.15.15-.3.25l-.05-.1q-.35-.4-.85-.5-.1-.05-.2-.05Q9.55.4 9 .8l-.2.3q-.3.3-.35.75-.05.15 0 .25.05-.25.25-.3.15-.1.35-.05.7.05 1.2.9v.05q.2.25.4.85 0-.05.05-.05 0-.45.2-.8.1-.05.15-.05.15-.2.45-.25.25-.05.45-.05.85.1 1.75 1.05 0 .05.15.3.15.3.45.85.55-.55 1-.95 0-.05.05-.05.7-.7 1.15-.65.55.1.8.6v.05q.1.4.1.9l.2-.4q.2-.15.45-.4.25-.15.45-.25.45-.3.85-.25.7.1.95.65z"
                    id="ObjToy084__Symbol_211_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.3 8.1q0-.15-.15-.3-.65-.6-1.75-.75-1.1-.2-1.75-.15l-.3-2 1.8-.25-.4-2.85-1.85.25L6.6 0 2.85.55l.3 2.05-1.8.25.4 2.85 1.8-.25.3 2q-.6.15-1.45.6-.9.4-1.55 1.35-.15.2-.15.4-.4-.05-.7-.1l2.8 5.8q-.05.1-.05.2 0 .05 1.25 1.4-.7.4-.9.35.25.9-.5 1.95-.15.1-.25.25l-.1.3q-.05.15 0 .3.1.65 1.4 1 .5.85 1.55 2.3 1.75 7.7.35 12.25 0 .4.1.7-.75.85-1.55 1.9.75.8-.5 2.55-1.25 1.3-1.95 2.95-.05.1-.1.15-.45.75-.35 1.55.1.55.5 1 .8 1.15 3 1.75 2.9.8 6.75.3h.25q.2-.05.35-.05 3.85-.65 6.45-2.25 1.9-1.2 2.4-2.5.15-.55.05-1.1-.1-.8-.75-1.45l-.05-.05q-1.2-1.45-2.8-2.3-1.65-1.3-1.15-2.3-1.1-1.05-2.05-1.85-.05-.1-.1-.25-2.6-3.95-3.15-11.85.85-1.9 1.2-2.85.85-.6.75-1.15l-.1-.3q-.05-.15-.2-.2-.1-.15-.35-.2-1-.8-1.05-1.7-.45-.15-.85-.3.7-1.45.7-1.5v-.15l1-6.4q-.35.05-.7.15z"
                    id="ObjToy084__Symbol_209_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.75 41.6Q2 43.45 5.2 44.35q2.9.85 6.8.3h.25q.15-.05.3-.05h.05q3.8-.6 6.4-2.25l.6-.4q.45-.3.8-.65 1.3-1.25 1.1-2.55-.1-.55-.4-1.05-.5 1.3-2.4 2.5-2.6 1.6-6.45 2.25-.15 0-.35.05h-.25q-3.85.5-6.75-.3-2.2-.6-3-1.75-.25.6-.15 1.15m12.5-13.2q.8 2.2-3.55 3.15-.15 0-.3.05h-.3q-4.5.4-4.35-1.95-2.05.8-1.5 2.55l.05.05q1.8 1.8 6.05 1.2.3 0 .6-.05 4.2-.7 5.45-2.9 0-.05.05-.05.05-1.85-2.2-2.05m-8.85-11q1.2.05 2.6-.15h.3l.3-.05q1.35-.2 2.5-.65 1.05-.35 1.85-.75 1.85-1.05 1.7-2.15-.15-.9-1.7-1.4l.1.3q.1.75-1.4 1.55-1.4.7-3.35 1.05H8l-.05.05H7.7q-2 .25-3.5-.05-1.65-.3-1.75-1.05-.05-.15 0-.3-1.35.9-1.2 1.8.15 1.1 2.2 1.55.9.2 1.95.25m5.8-8.95q0 .45-1.05.95-1.05.5-2.55.8-.15 0-.3.05H7q-1.5.2-2.7 0-1.1-.2-1.3-.65-.65.45-.55.95.05.45.85.75.35.1.7.15 1.2.25 2.65.15l.5-.1h.2l.15-.05h.25l.5-.1q1.45-.3 2.5-.85.4-.15.6-.35.75-.5.7-.95-.1-.5-.85-.75m1.05-7.05Q12.1.5 10.2.15 9.05-.05 7.75 0l.1.75Q8.5.7 9.1.8q1.3.15 1.35.6.05.5-1.15 1-1.25.5-3 .75t-3.05.1q-1.3-.1-1.35-.6-.05-.45 1.15-1 .4-.2 1-.35l-.1-.75Q2.6.9 1.6 1.4-.15 2.25 0 3.15v.05q.05.2.2.35.45.55 1.85.8 1.8.3 4.1 0h.15l.25-.05h.2q2.3-.4 3.9-1.2 1.3-.65 1.55-1.3.05-.15.05-.35V1.4z"
                    id="ObjToy084__Symbol_210_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToy084__Symbol_212_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M15.65 2.35q.3-.3.35-.75.1-.55-.3-1-.35-.5-.9-.6-.6-.05-1.05.35-.5.35-.6.9-.05.45.15.85 0 .85.1 1.5 0 .3.05.55-.15-.05-.3-.05-3.75-.25-4.35-.3-.2-.05-.35.05-.2.05-.25.3v.15q.05.15.05.25l1 6.1q-.05.05-.05.15 0 .05.05.1 0 .1.1.2l-.05.05q-.05.05-.05.5 0 .05.1.95-.2.05-.25.05 0 .85-.95 1.7-.25.05-.35.2-.1.1-.2.25v.1q-.4.05-.4.5-.25.15-.25.5 0 .3.5.8.3.25.95.8l.75.8q.35.35.65.55-.15.1-.25.2-.35 7.75-2.75 11.8-.15 0-.25.1-.1.05-.15.15-.1.05-.25.1-1 .45-1.05 1.55-.1.25-.1.45v.1q-.2-.1-.35-.2.5.95-1.15 2.3-1.55.95-2.65 2.4l-.1.1q-.65.65-.75 1.4-.05.6.15 1.1.35.9 3.35 2.55.2 1 1.6 1.9.7.45 1.55.8 4.85 1.85 9.7 0 .3-.1.65-.2 1.8-.65 2.1-1.55.25-.4.25-.8 0-.15-.05-.25.05-.1.1-.15.35-.45.4-1.05.1-.75-.35-1.55 0-.05-.05-.1-.85-1.7-2.1-2.95-1.25-1.7-.55-2.5-.3.3-.55.5v-.6q.15-2.1-.95-2.35-.05-.1-.05-.15-1.5-4.45.05-12.2l-.1-.1q.25-.05.4-.2.2-.3.3-.4.2-.25.5-.4.5-.25.55-.3.7-.55 1-.8.5-.5.5-.85 0-.45-.25-.6l-.05-.05q0-.15-.1-.3t-.2-.25q-.8-1.05-.6-1.9h-.05q.05-.15.1-.25.2-.55.2-.95v-.35q.05-.1.05-.15v-.1q0-.1-.05-.2l2.6-5.7q-.05-.05 0-.05-.1-.25-.3-.4-.05-.3-.2-.4-.25-.15-1.05-.2-.75-.05-.8-.05h-1.35l-.7-.1-1.2-.15q.1-.15.25-.35.15-.3.35-.6.3-.55.6-1.25m1.5 4.15q.05.1.05.2v.05q-.05-.15-.05-.25z" />
                    <path d="M17.2 6.7q0-.1-.05-.2 0 .1.05.25V6.7z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy084__Symbol_212_0_Layer0_0_FILL"
                transform="translate(119.85 248.6)"
                id="ObjToy084__col1n"
            />
            <use
                xlinkHref="#ObjToy084__Symbol_211_0_Layer0_0_FILL"
                transform="translate(119.6 250.65)"
                id="ObjToy084__col1d"
            />
            <use
                xlinkHref="#ObjToy084__Symbol_209_0_Layer0_0_FILL"
                transform="translate(106.2 246.9)"
                id="ObjToy084__col2n"
            />
            <use
                xlinkHref="#ObjToy084__Symbol_210_0_Layer0_0_FILL"
                transform="translate(106 253.05)"
                id="ObjToy084__col2d"
            />
        </g></g>
    )
}

export default ObjToy084
