import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00498A"
}

function LegPan004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7.05l.05-.05Q61.85 12.5 0 0q0 .05.05.1v19.5q32.85-5.4 62.8 2.75Q92.8 14.2 125.7 19.6V.05m-.85 11.1q-1.35.25-2.8-.1-2.8-.75-6-.9-9.25-.65-2.9-.85 4.9-.2 6.75-.3l4.95 2.15M118 7.25q-.1.1-.25.2l-19.4.25q-2.7.15-2.15 0 .4-.1 1.05-.3l11.55-1.9q.5.25.75.55.4.45 2.4 1l6.05.2m-16.05 4.65q-.15.1-.25.15.1 0 .25.05 7.6.95 15.1 1.25h-.35q-2.4.85-8.05.15-5.65-.75-13.1.85l-.45.05q-.45.05-.75.2-.048-.048-.1 0l.2-.2 1.5-.6q1.1-.55 1.75-.95H82.65q.3.3.75.7.65.55 1.1.95l.1.1q.05.1.15.15l-.05.05q-.3-.1-.35-.1-.55-.2-2.05-.45-1.9-.35-3.05-.35-9.1 1.45-11.45-.8 4.6.55 6.35.3 1.7-.25 3-.45 1.3-.25 2.5-.6-1.9-.95-4.75-1.5h8.15q-.25-.15-1-.7-.75-.6-1.4-1.1-.65-.55.2-.2.8.35 1.2.4 1.4.55 2.95.9 1.5.3 2.15.5.65.2 1.2.15.5-.05 2.95-1.6l16.9.85q-.4.2-1.1.35-1.5.35-2.1.55l-.2.1q-.1 0-.25.05-.1.05-2.6.8m-57.7-2.85q-1 .05-2.3.4-1.35.3-3.5.7-2.2.4-3.05.65 3.1.9 6.15 1.05.35 0 .65.05 4.6-.4 7.6-2.05-.75 1.55-3.3 2.55 3.35.65 7.4 2.4-2.3-.4-5.25-.9-2.95-.45-6.05-.6-3.15-.15-6.55.05Q38.7 14.8 45 16.1q-3.25 0-5.05-.25-1.85-.3-2.5-.45-.7-.15-2.45-.65-1.75-.55-3-1.05-.65-.25-1-.45-.25 0-.5.05-1.45.35-2.7.55-1.3.2-2.6.15h-.65q-.75-.1-1.15-.2-.1-.05 1.1-.25 1.15-.25 1.8-.6.65-.4.8-.5-1-.1-2.05-.15-5.15-.35-12.4-.8l-5.3-.35q.3-.75 5.8-.8 2.4.2 7.75-.05.6-.1 1.15-.2l.05-.05.5-.05q1.8-.35 3.5-.55L20.25 7.8l24 1.25M25.1 5.75H23q-4.35-.4-7.1 1.4-.1.05-.2.15-.5.35-.5.85 0 .35.35.7.1.1.25.2l-1.2-.15q-3-.2-11.55.1h-.1Q5.8 8 11.1 7.95q.9.2 2.25-.65 1.35-.9 4.5-2.65 4.65.55 6.35.85.7.15.9.25M7.4 12.9q.55.15 1.4.35.7.1 1.6.25 4.55.65 9.1.5.05-.1.2.5-4.85 1.85-14.4-2 .35.05.65.05.55.15 1.45.35z"
                    id="LegPan004__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan004__Symbol_4_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan004__col1n"
            />
        </g></g>
    )
}

export default LegPan004
