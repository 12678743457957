import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#479EEC",
    "col1n": "#77BFFF",
    "col1s": "#1074CB",
    "col2d": "#4D2E1B",
    "col2n": "#794D32"
}

function ShiLon070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.05 39.3q-.15-.75-.35-1.5l-.3-1.2-31.75-35.15.85-.75q-16.75 10.1-36.7 12.45-1.6.2-3.25.35-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-2.65-.45-5.25-.95Q45.9 8.45 32.3 0L2.7 36.25q-.2.75-.4 1.55l-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.05.2-.1.45Q1.1 43.2.8 44.7v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8-.35 12.55 4.6l.6-.15L40.2 78.5q.15 0 .3.05v.05q1.4.2 2.8.45l1.5.2q.95.1 1.9.25.05-.05.15-.1l1.45.2h.2q-.05.05-.1.15 6.35.8 12.75 1.3.2 0 .45.05 10.45.8 20.9.8 6.15 0 12.3-.25 12.8-.6 25.6-2.4l2.6-.4h-.1l19.9-28.45.25.1-.05-.15q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V49.1q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.35-.15-.7-.05-.1-.05-.15l-.1-.5q-.05-.2-.1-.3-.1-.6-.25-1.15-.05-.3-.15-.6m-78.7-7.2q.05 0 .15-.05.05.05.05.15l-.2-.1m32.6 13.95l-.05-.25.1.25h-.05m-45.1-16.5l-.05.1q-.05-.05-.05-.1v-.1l.1.1M23.2 48.1q.25 0 .45.05l-.4.15-.05-.2z"
                    id="ShiLon070__Symbol_510_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M68.15 47.7q-4.65 2.05-7.55 3.2-1.15.45-2.3.75-1.2.3-3.2.95-2.05.6-4.25 1.75l-16.95 6.2q-11.7 3.6-21.9 9.3-.05.05-.1.15 6.35.8 12.75 1.3.05-.05.15-.1.15-.05.3-.15l.7-.3q.5-.2 1.05-.4.15-.05.35-.15 7.55-3 12.55-3.55 1.8-.2 3.45-.4 5.45-.7 8.8-1.4.25-.1.45-.2 3.4-1.65 5.85-4 1.15-1.15 2.05-2.4 2.9-4 4.3-6.65v-.05q1.478-2.167 3.5-3.85m-23.2-36.9l15.2 13.35L45.4 5.05l-19.8 18.2L44.95 10.8M83.9 9.45V21.7Q53.2 39.75 9.6 49.4l-.4.1q-1.1.25-2.4.55v-36.2q.05-.9.1-1.65l1.15 1.35q-.2-1.95.6-2.5.6-.45 1.9 0 3.75 1.3 12.95 10.15l1.6 1.6q.25.2.5.45Q16.5 11.3 3.9.05q-5.2 4.3 0 8.55l.6.7q-.3.7-.6 1.6-7.8 26.8 0 57.1l-.2.75q.15 0 .3.05v.05q1.4.2 2.8.45V50.45l-1.2-.1q.6-.05 1.2-.05 1.2-.1 6.8-.05 5.55 0 10.2-.65 4.65-.75 6.6-1.05 1.9-.35 5.8-.45 3.85-.1 8.85-1.55 5-1.5 7.6-2.05 2.6-.55 7.8-1.75 5.15-1.2 6.6-1.85 1.45-.65 3.5-1.2 2-.6 9.85-3.65l.1.25 3.4-1.35V69.5l2.6-.4h-.1q8.15-31.2 0-61.75-.25-.7-.45-1.3l.45-.4q3.5-3.55 0-5.65-13 9.85-23.45 21.6l.5-.4q8.55-7.25 12.9-10.15 5.7-3.8 4.1 0-.05.05-.1.15l3.5-3.2q0 .7.05 1.45z"
                    id="ShiLon070__Symbol_481_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M13.4 26L0 10.05v2.3L13.4 26m50.85 23.15q.05 0 .1-.05 1.1-.7-.75-.15-1.95.5-3 .9-1.05.3-1.65.55l-1.2.4L18.5 65.6l-.3.1q-5.05 2.1-10.4 4.05l-1 .4q-.3.05-.6.2h-.05l-1.65.5-.1.1q.95.1 1.9.25.05-.05.15-.1l1.45.2h.2l20.35-7.9v.1l18.3-7q1.35-.65 4.2-1.8 1.7-.7 3.45-1.4 1.15-.5 2.3-.95 2.9-1.15 7.55-3.2m-17.7-3.1l-.6.1q-9.5 2.3-20.2 3.45l-.6.1q-1.95.25-3.95.5-7.85.9-15.9 1.4-1.2.05-2.4.15-.6 0-1.2.05l1.2.1h.75q.45.05 1 .05 8.3.45 16.55-.1 2.1-.15 4.15-.35 11.9-1.15 20.8-3.4 4.1-1 8.25-2.2 7-2.05 14-4.8.35-.15.75-.3 2.95-1.15 5.9-2.4.7-.35 1.45-.65v-.25q-4.05 1.45-8.1 2.75-.95.25-1.85.55-6 1.85-12.15 3.4-3.9.95-7.85 1.85M82.5 8.8V7.1L64.35 23.7 82.5 8.8m0-7.35V0L56.25 24 41.55 6.15 21.65 23.1 0 0v1.5l21.7 23.2L41.4 7.6l14.85 18L82.5 1.45z"
                    id="ShiLon070__Symbol_451_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.95 4.65L24.4 3.5q-.2-.15-.45-.3l-.1-.1q-1.15-.85-2.25-1.7L19.75 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2Q1.3 38.35.6 41q-.05.3-.15.6-.1.3-.15.6-.15.55-.3 1.15.75-.15 1.5-.25 7.45-1.25 10.7-1l10 3.8-2.55 11.3-.6.15.1.1-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H14.25q2.05.5 4.15.95 8.35 1.9 16.65 3.25.45.1.9.15l1.2.2q.3.05.65.1v-.05q-.15-.05-.3-.05l.2-.75V15.4l-8.1-8.35q-1.85-1.15-3.65-2.4M22.7 69.1v-.05l.1-.1-.1.15m-2.2-13.9q.25 0 .45.05l-.4.15-.05-.2m-4.85 23.6l-.3.1q.05-.05.15-.1h.15m-1.15.55l.05.1h-.05q-.1 0-.15.05h-.1l.05-.1v-.05h.2M147.3 11.5q-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35L138 1.4q-.6.5-1.25.95l-.9.7q-.35.2-.65.45l-1.55 1.15q-2.4 1.65-4.85 3.15l-8.6 7.6v70.55h.35q.85-.15 1.65-.25.65-.15 1.3-.25Q132.3 84 141.1 82q2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.5-2.3-.35-.15-2.35-10.75 8.6-3.75 1.65-.5q2.7-.7 11.8 1.25-.2-.75-.35-1.5l-.2-.6q-.1-.35-.15-.7-.15-.45-.25-.9-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6m-2.9 67.3h-.05l.2.1-.3-.1h.1v-.05l.05.05z"
                    id="ShiLon070__Symbol_417_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M26.65 38l-3.2 1.1 11.6 43.05q.45.1.9.15-3.35-22.5-9.3-44.3M11.6 36.7l-11 1.2q-.05.3-.15.6-.1.3-.15.6-.15.55-.3 1.15.75-.15 1.5-.25 7.45-1.25 10.7-1l9.6 3.65.65-.9L11.6 36.7M25.35 1.1l-.1.45L37.7 18.4v-6.1l-8.1-8.35q-1.85-1.15-3.65-2.4l-.6-.45m-1.4-1l-.1-.1-8.45 26.05 17.15 4.65 1.8-1.1-14.4-5.6 4-23.9M136.1 39l-2.85-1.35Q126.5 59.5 122.2 82.6q.65-.15 1.3-.25L136.1 39m1.05 3.5l.5 1.1 8.6-3.75 1.65-.5q2.7-.7 11.8 1.25-.2-.75-.35-1.5l-.2-.6q-.1-.35-.15-.7l-10.55-1.35-11.3 6.05M135.85 0l1.55 24.6-14.25 6 2.55 1.05 16.25-5.25-6.1-26.4m-2.2 1.55q-2.4 1.65-4.85 3.15l-8.6 7.6v5.5l14.5-16.75-.05-.25-1 .75z"
                    id="ShiLon070__Symbol_398_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon070__Layer9_1_FILL"
                />
                <path
                    fillOpacity={0.4}
                    d="M150.4 244v1.5l21.7 23.2 19.7-17.1 14.85 18 26.25-24.15V244l-26.25 24-14.7-17.85-19.9 16.95L150.4 244z"
                    id="ShiLon070__Layer9_2_FILL"
                />
                <path
                    fill="#7D2F1C"
                    d="M200.55 264.15q.895-.969.35-1.65l-9.15-11.15-11.25 10q.841 2.126 1.35 2.1.2-.01.05.2l1.15-.9 8.45-8.55 8.55 10.45q.17-.112.5-.5m-4.85 3.3q-.801.057-1.6 0-7.591.238-9.85-1.25l-7.4 36.15h5.25l4.8-33.85h10.2q.15-.2.15-.65 0-.254-.1-.4h-1.45z"
                    id="ShiLon070__Layer9_3_FILL"
                />
                <g id="ShiLon070__Layer9_0_FILL">
                    <path
                        fill="#CB4E2E"
                        d="M183.1 265.35q.421.6 1.4 1.1l-2.4 35.9-.9 6.3h19.45l-.9-8.65v-1.55l-2.5-30.8v.15q-.003-.075-.05-.15.143-.077.25-.15 1.15-.8 1.8-1.75.096-.13.15-.25.68-.891 1.1-1.5.025-.038.05-.1.078-.092.15-.2.296-.461.5-.85-.387-.453-.8-1l-8.65-10.4-11.2 9.9q.225.737 1 1.9.6.9 1.55 2.1z"
                    />
                    <path d="M248.55 231.7v-.1q-.326.265-.65.5l-.55.4 2.1 23.4-16.15 6.4 12 5.15-11.75 46.25-.3.8q.83-.102 1.65-.25l11.65-47.5-10.7-4.45 14.95-5.8-2.25-24.8m-110.5 1.1l-.95-.7q-.219-.159-.45-.35l-4.7 24.35 15.1 5.2-8.45 4.95 10 47.75q.638.1 1.25.2l-.1-.85h.05l-9.65-46.7 9.55-5.35-16.35-6 4.7-22.5z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon070__Symbol_510_0_Layer0_0_FILL"
                transform="translate(110 235.7)"
                id="ShiLon070__col1n"
            />
            <use
                xlinkHref="#ShiLon070__Symbol_481_0_Layer0_0_FILL"
                transform="translate(146.5 245.45)"
                id="ShiLon070__col1d"
            />
            <use
                xlinkHref="#ShiLon070__Symbol_451_0_Layer0_0_FILL"
                transform="translate(150.4 244)"
                id="ShiLon070__col1s"
            />
            <use
                xlinkHref="#ShiLon070__Symbol_417_0_Layer0_0_FILL"
                transform="translate(112.7 228.6)"
                id="ShiLon070__col2n"
            />
            <use
                xlinkHref="#ShiLon070__Symbol_398_0_Layer0_0_FILL"
                transform="translate(112.7 231.7)"
                id="ShiLon070__col2d"
            />
            <use xlinkHref="#ShiLon070__Layer9_0_FILL" />
            <use xlinkHref="#ShiLon070__Layer9_1_FILL" />
            <use xlinkHref="#ShiLon070__Layer9_2_FILL" />
            <use xlinkHref="#ShiLon070__Layer9_3_FILL" />
        </g></g>
    )
}

export default ShiLon070
