import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400",
    "col2n": "#9A3200"
}

function BodTai002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M110.3 3.25Q108.4 0 105.65 0q-2.6 0-4.5 2.95-.05-.1-.1-.15-.35-.9-.8-.85-.4.05-.4.65 0 .2.55 1.2.05.15.15.25-1.5 3.05-1.5 7.15 0 4.65 1.9 7.9 1.95 3.3 4.7 3.3h.3v.1q0 .3.1.6 8.55 61.4-94.25 62.5-6.7-.5-10.7 2.25-1.75 1.8-.75 4.05 2 2.95 10.4 2.85 119.1-2.15 98.3-72l-.1-.05q-.05-.25-.1-.45v-.05q1.3-.55 2.2-4.55 1.2-2.8 1.2-6.45 0-4.65-1.95-7.95z"
                    id="BodTai002__Symbol_3_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 0q7.05 3.65 10.15 11.4-.4-.4-3.25-1.65Q4 8.45.65 9.3h.2q4.2 1.8 8.2 7 3.55 4.6 5 9.25 1.1 3.45 2 12.45 1 5.55 8.95 7.05.55.1 1.1.1v-.05q-.3-1.55-.55-3.2 0-.1-.1-.3 0-.2-.1-.45l-.1-.8q-1.5-7.25-4.5-15.3 4.7 9.3 7.5 17.7.4 1.05.75 2.15.45-.15.85-.3 4-1.6 5.05-6.6 1.35-5.15 1-9.6Q33.3 6.5 5.7.6 2.65-.1 0 0z"
                    id="BodTai002__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai002__Symbol_3_0_Layer0_0_FILL"
                transform="translate(220.95 221.3)"
                id="BodTai002__col1n"
            />
            <use
                xlinkHref="#BodTai002__Symbol_4_0_Layer0_0_FILL"
                transform="translate(300.8 198.65)"
                id="BodTai002__col2n"
            />
        </g></g>
    )
}

export default BodTai002
