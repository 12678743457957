import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#621F00",
    "col1s": "#000000",
    "col2n": "#FFFF00"
}

function ShiSpe116({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M138.35 42.45L129.75 0q-4 9.35-24.55 14.2-3.35 1.3-6.75 2.3l-2.9.8q-2.15.55-4.35 1.05-.35.05-.65.1-.35.05-.65.15-5.15.95-10.45 1.45-.5 0-1.05.05l-1.15.05q-3.1.2-6.25.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-2.3-.35-4.55-.8l-.1-.05h-.15q-2.3-.5-4.6-1.1l-2.9-.8q-3.45-1-6.8-2.3Q16.85 9.8 10.25.65L0 43.05l11.5 14.3-1.95 1.05H9.5L6.95 68.65l-.2.8q-.05.15-.05.35l5.2 4.4 64.75 9.05 53.2-8.3 4.3-5-2.65-10.6h-.05l-.2-.95-.8-1 7.9-14.95z"
                    id="ShiSpe116__Symbol_222_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M22.3.15q-.2.25-.35.5Q19.1 4.5 16.6 8.7q-.05.15-.15.25-.75 1.35-1.5 2.65-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q1.7.9 3.1 2.3l.05.05q.15.1.25.25l.1.1.1.1.15.15q.05.05.1.15l4.3-17.65h-.05l.6-9.55q0-.3.05-.6l.1-2.4q.1-2 .1-3.9v-1.95q-.15-4.75-.75-8.9-.15-.8-.25-1.55Q24.9 4.95 22.55.2V.15l-.05-.1-.05-.05q-.1.05-.15.15M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-.7-1.3-1.45-2.55-.35-.55-.65-1.05-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35-.05.1-.1.25-.05.05-.1.15l-.4.9q-2 4.35-3 9.8-.2.75-.3 1.55-.65 4.15-.75 8.9V25q0 1.2.05 2.4.1 1.65.2 3.35l1.05 12.3q.5 2.2 1.1 4.4.4 1.8.85 3.6.35 1.25.65 2.55l.2.2-.35-1.5 1.7 6.65-.35-1.5.25-.25.05-.05.35-.35q1.4-1.4 3.15-2.3.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe116__Symbol_223_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M31.65 14.75q-5.45-2.1-10.6-4.85-4.35-2.4-8.5-5.25L11 3.5Q9.55 2.45 8.2 1.4L6.4.05l.05.1V.2q2.35 4.75 3.5 10.9.1 0 .25.05.35.05.75.15 10.35 2.1 20.7 3.45m93.9 37.55l.35 1.5-.2-.2q-.3-1.3-.65-2.55-.45-1.8-.85-3.6-28.85 6.15-57.8 6.15-28.95 0-58.05-6.2L5.5 58.95q30.55 6.85 60.9 6.85 30.5 0 60.85-6.85l-1.7-6.65M130 70.5l-2.3.5q-30.55 7-61.3 7Q35.8 78 5 71q-1.15-.25-2.25-.5h-.1q-.2.7-.35 1.4l-.4 1.5L1 77l-.95 3.6v.25L0 80.9h.2q.25.1.5.15H.85q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-2.5-10.1H130m-7.55-34.9l-.4-4.85q-.1-1.7-.2-3.35-.05-1.2-.05-2.4v-1.45Q94.2 29.2 66.4 29.2q-27.65 0-55.45-5.7 0 1.9-.1 3.9l-.1 2.4q-.05.3-.05.6l-.3 5.2q28.05 5.8 56 5.8 28.1 0 56.05-5.8m4-35.6l-.15.15V.1l-1.7 1.3q-.6.5-1.25.95-.75.6-1.55 1.15l-2 1.5q-3.5 2.35-7.15 4.4-5.55 3.1-11.45 5.35 10.3-1.35 20.6-3.45.4-.1.75-.15.15-.05.3-.05 1-5.45 3-9.8l.4-.9q.05-.1.1-.15.05-.15.1-.25z"
                    id="ShiSpe116__Symbol_224_0_Layer0_0_FILL"
                />
                <path
                    d="M252.55 228.6l-.15.1v.05l.15-.15z"
                    id="ShiSpe116__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe116__Symbol_222_0_Layer0_0_FILL"
                transform="translate(122.1 229.15)"
                id="ShiSpe116__col1n"
            />
            <use
                xlinkHref="#ShiSpe116__Symbol_223_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe116__col1s"
            />
            <use
                xlinkHref="#ShiSpe116__Symbol_224_0_Layer0_0_FILL"
                transform="translate(126.1 228.6)"
                id="ShiSpe116__col2n"
            />
            <use xlinkHref="#ShiSpe116__Layer1_0_FILL" />
        </g></g>
    )
}

export default ShiSpe116
