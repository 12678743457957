import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF844A"
}

function LegSki019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M152.85 18.95q.4-.05.75-.05L141.7 0q-1.45.35-3 .7-.25.05-.55.1-.5.1-1 .25l-1.3.3q-.3.05-.55.1-.55.1-1.15.25-.4.05-.8.15l.05.05h-.15q-.5.05-.95.2l-.6.1-1 .2q-.45.05-.85.15l-.4.1-3.2.6q-1.45.25-2.9.55-4.45.75-8.85 1.35l-3.5.5-5.9.7-3 .3q-1.05.05-2.1.2h-.35l-4 .3q-9.4.65-18.8.65h-.3q-9.3 0-18.6-.65-1.15-.1-2.2-.15-.85-.1-1.7-.15-.45-.05-.85-.1-2.65-.25-5.25-.5-2.95-.3-5.9-.7-1.7-.25-3.45-.45-2.9-.45-5.75-.9-2.4-.4-4.7-.8-.4-.05-.8-.15l-3.6-.7q-1.25-.25-2.4-.45-.55-.15-1.1-.25-.4-.1-.8-.15-.6-.15-1.15-.25-.9-.2-1.85-.4-.5-.15-1-.25-.3-.05-.55-.1-1.55-.35-3-.7L0 18.9q.35 0 .75.05.95.1 1.95.2l2.3.2q1.45.15 2.9.25 9.75.8 19.45 1.4 7.5.45 15.05.8 2.95.1 5.9.25 6.2.2 12.45.35l7.4.1q3.35.05 6.7.05h4.7q1.9-.05 3.8-.05 3.65-.05 7.3-.05.2-.05.4 0 3.85-.05 7.75-.15l5.6-.2q12.15-.4 24.3-1.15 2.25-.15 4.55-.3v-.1q.6-.05 1.25-.05 7-.5 14.1-1.15l1.2-.1q1.55-.15 3.05-.3z"
                    id="LegSki019__Symbol_85_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.5 18.05q.6-.8.6-1.8v-.1q-.05-1.2-.9-2.05-.9-.9-2.15-.9t-2.15.9Q0 15 0 16.25q0 .75.3 1.35l2.3.2q1.45.15 2.9.25M7.2 5.8q1.25 0 2.15-.9.4-.4.65-.85.25-.6.25-1.3 0-1.25-.9-2.15-.4-.4-.85-.6L5.3 5.1q.8.7 1.9.7m14.85 8.5q.05-.3.05-.65 0-1.25-.9-2.15-.9-.9-2.15-.9t-2.15.9q-.9.9-.9 2.15 0 .35.1.65.15.85.8 1.5.9.9 2.15.9t2.15-.9q.65-.65.85-1.5m12.1-4.6q.65-.65.85-1.5.05-.3.05-.65 0-1.25-.9-2.15-.9-.9-2.15-.9t-2.15.9q-.9.9-.9 2.15 0 .35.1.65.15.85.8 1.5.9.9 2.15.9t2.15-.9m10.9 9.25q-.9-.9-2.15-.9t-2.15.9q-.55.55-.75 1.3 2.95.1 5.9.25-.2-.9-.85-1.55m6.6-13.65q-.45-.05-.85-.1-.85.9-.85 2.1 0 .5.15.9.2.7.75 1.25.9.9 2.15.9t2.15-.9q.55-.55.8-1.25.1-.4.1-.9 0-.95-.5-1.7-1.15-.1-2.2-.15-.85-.1-1.7-.15m11.4 11.9q0-1.1-.7-1.95l-.2-.2q-.9-.9-2.15-.9t-2.15.9q-.15.15-.25.3-.65.8-.65 1.85 0 1.25.9 2.15.9.9 2.15.9t2.15-.9q.9-.9.9-2.15m20.4-2.25q.1-.45.1-.9 0-1.25-.9-2.15-.9-.9-2.15-.9t-2.15.9q-.9.9-.9 2.15 0 .5.15 1 .2.6.75 1.15.9.9 2.15.9t2.15-.9q.55-.55.8-1.25m18.75 4.5q0-1.25-.9-2.15-.9-.9-2.15-.9t-2.15.9q-.9.9-.9 2.15 0 .7.3 1.3l5.6-.2q.2-.5.2-1.1m8.4-4.95q-.05.25-.05.5 0 1.25.9 2.15.9.9 2.15.9t2.15-.9q.9-.9.9-2.15 0-.3-.05-.6-.15-.85-.85-1.55-.9-.9-2.15-.9t-2.15.9q-.7.7-.85 1.65m-8.35-6l.3-.3q.6-.8.6-1.85 0-.3-.05-.55-.1-.55-.4-1l-3 .3q-1.05.05-2.1.2h-.35q-.1.2-.1.4-.1.3-.1.65 0 1.05.65 1.85.1.15.25.3.9.9 2.15.9t2.15-.9m24.05 10.9q2.25-.15 4.55-.3V19q.6-.05 1.25-.05-.25-.55-.7-1-.9-.9-2.15-.9t-2.15.9q-.65.65-.8 1.45m-.65-13.95q0-1.25-.9-2.15-.9-.9-2.15-.9t-2.15.9q-.9.9-.9 2.15 0 .3.05.65.2.85.85 1.5.4.4.85.6.6.3 1.3.3.7 0 1.35-.3.4-.2.8-.6.6-.6.85-1.4.05-.35.05-.75m14.9.35q-.9-.9-2.15-.9t-2.15.9q-.25.25-.45.6-.45.7-.45 1.55v.25q.1 1.1.9 1.9.9.9 2.15.9t2.15-.9q.8-.8.9-1.9v-.25q0-.8-.35-1.45-.25-.4-.55-.7m7.5 8.5q-.9.9-.9 2.15 0 .65.25 1.25 1.55-.15 3.05-.3.4-.05.75-.05l-2.3-3.65q-.2.1-.35.2-.25.15-.5.4z"
                    id="LegSki019__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki019__Symbol_85_0_Layer0_0_FILL"
                transform="translate(115.65 307.25)"
                id="LegSki019__col1n"
            />
            <use
                xlinkHref="#LegSki019__Symbol_26_0_Layer0_0_FILL"
                transform="translate(118.05 308.8)"
                id="LegSki019__col2n"
            />
        </g></g>
    )
}

export default LegSki019
