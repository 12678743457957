import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#332F54",
    "col1n": "#56518C",
    "col1s": "#19172A",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF"
}

function ShiLon110({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.2.15-.4.35-.6.4-1.15.8l-1.55 1.15q-3.7 2.55-7.6 4.75-2.55 1.4-5.2 2.7-2.95 5.4-6.45 10.4.15-.2.35-.4-1.2 1.75-2.5 3.45L91.9 42H72.55L48.8 24.5q-.9-1.2-1.75-2.4-3.3-4.9-5.55-10-2.2-1.05-4.35-2.2-4.3-2.35-8.35-5.15-.1-.05-.15-.1L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.65 2.6.75-2.75L15.4 84q1.65.8 3.7 1.5 12.95 4.55 42.2 6.55 2.65.2 5.4.45 1.65.1 3.35.2.7.05 1.35.1 7.7.3 9.7-3.8H84q2.35 4 9.8 3.85l.3-.05q.65-.05 1.35-.1 3.05-.2 6.1-.45l2.5-.2q30.55-2.3 41.55-6 2.8-.95 4.35-2L143.6 59.9h-.05l-.55-2.45q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon110__Symbol_555_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.8 10.5q.2.05.35-.1.15-.1.2-.3l.85-5.95q.05-.25-.1-.4-.1-.15-.3-.2L1.45 0q-.25-.05-.4.1Q.9.2.85.45L0 6.4q-.05.2.1.35.1.15.35.2L24.8 10.5m.35-6l-.7 4.95L1.1 6.05l.7-4.95 23.35 3.4M117.1.45q-.05-.25-.2-.35-.15-.15-.35-.1L92.2 3.55q-.25.05-.35.2-.15.15-.1.4l.85 5.95q.05.2.2.3.15.15.4.1l24.35-3.55q.2-.05.3-.2.15-.15.1-.35L117.1.45m-.9.65l.7 4.95-23.35 3.4-.7-4.95 23.35-3.4z"
                    id="ShiLon110__Symbol_556_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M52.7 80v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5V80q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55m-2.25-13.95q-.95 0-1.6.65-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6 0-.95-.65-1.6-.65-.65-1.6-.65m2.25-9.7q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3M4.8 35.25q-.2-.05-.35.1-.2.1-.25.3L0 57.1v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25m111.6.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m4.35-34.4q-.15-2.25-1.15 0l-7.9 17H99.5q-.15 0-.3.1t-.15.3q-.05.15 0 .3t.2.25l9 5.1q-1.15 1.45-2.4 2.9-5 5.55-11.5 10.15Q82.25 45.9 64.8 51.4q-2.1.65-4.2 1.25H60.45q-2.15-.65-4.2-1.25Q40 46.3 28.45 38.6 20.8 33.4 15.2 27.2q-1.3-1.45-2.45-2.9l9-5.1q.15-.1.2-.25.05-.15.05-.3-.05-.2-.2-.3-.15-.1-.3-.1H9.3L1.2.85Q-.3-.85.1.85l8.45 18.1q.05.15.2.25.1.05.25.05h10.65l-7.9 4.5q-.15.05-.2.2-.05.1-.05.25t.1.25q1.3 1.7 2.85 3.4 5.65 6.35 13.45 11.6 11.7 7.75 28.05 12.9 1.85.55 3.75 1.1V89h1.2V53.55l4.2-1.2q17.65-5.55 29.85-14.2 6.6-4.7 11.65-10.3 1.5-1.7 2.8-3.4.1-.1.1-.25t-.05-.25q-.05-.15-.2-.2l-7.85-4.5H112q.15 0 .3-.05.1-.1.15-.2l8.3-17.75z"
                    id="ShiLon110__Symbol_557_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M70.05.4Q65.7 4.55 60.5 7.35q-10.75 5.75-25.05 5.6h-.5q-13.05.15-23.1-4.65Q5.3 5.2 0 0q3.45 5.1 8.1 9.95 1.35 1.4 2.8 2.8 9.75 9.45 24.05 17.95v.3q.15-.05.25-.15.15.1.25.15 12.9-7.35 22.75-16.9 1.45-1.4 2.8-2.8.55-.6 1.15-1.15Q66.85 5.2 70.4 0q-.2.2-.35.4z"
                    id="ShiLon110__Symbol_558_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M70.05.4Q65.7 4.55 60.5 7.35 49.95 21.4 35.3 29.65 20.8 20.3 11.85 8.3 5.3 5.2 0 0q3.45 5.1 8.1 9.95 1.35 1.4 2.8 2.8 9.75 9.45 24.05 17.95v.3q.15-.05.25-.15.15.1.25.15 12.9-7.35 22.75-16.9 1.45-1.4 2.8-2.8.55-.6 1.15-1.15Q66.85 5.2 70.4 0q-.2.2-.35.4z"
                    id="ShiLon110__Symbol_559_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon110__Symbol_555_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon110__col1n"
            />
            <use
                xlinkHref="#ShiLon110__Symbol_556_0_Layer0_0_FILL"
                transform="translate(133.55 294.6)"
                id="ShiLon110__col1d"
            />
            <use
                xlinkHref="#ShiLon110__Symbol_557_0_Layer0_0_FILL"
                transform="translate(132.2 228.6)"
                id="ShiLon110__col1s"
            />
            <use
                xlinkHref="#ShiLon110__Symbol_558_0_Layer0_0_FILL"
                transform="translate(157.05 250.7)"
                id="ShiLon110__col2n"
            />
            <use
                xlinkHref="#ShiLon110__Symbol_559_0_Layer0_0_FILL"
                transform="translate(157.05 250.7)"
                id="ShiLon110__col2d"
            />
        </g></g>
    )
}

export default ShiLon110
