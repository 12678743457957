import { AssetSvgProps } from "../../../shared/assets"

function ObjToy042({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#F8AA65"
                    d="M284.1 294.7q-2.25-.8-4.05 1.4l4.65 13.1q-3.203 5.264-13.55 7.85l7.95 20.8.15.3q3.003 2.423 5.95 4.1h20.3q4.179-3.575 7.15-9.6 1.34-1.935 2.05-4.75v-.4l-8.5-23.6q-10.75 5.082-17.95 3.95l-4.15-13.15z"
                    id="ObjToy042__Layer14_0_FILL"
                />
                <path
                    fill="#CB8D4A"
                    d="M314.7 327.9v-.4l-8.5-23.6-19.1 9.7 8.1 21.8q12.704 4.222 17.45-2.75 1.34-1.935 2.05-4.75z"
                    id="ObjToy042__Layer14_0_MEMBER_0_FILL"
                />
                <g id="ObjToy042__Layer14_0_MEMBER_0_MEMBER_0_FILL">
                    <path
                        fill="#875D34"
                        d="M314.7 327.9q-3.25 12.9-19.5 7.5-6.5 10.45-16.1 2.45l.15.3q8.25 10.25 19.95 7.85 12.5-3.45 15.5-18.1m-25.85-18q-1 1.4-3.45 1.2l-.7-1.9-13.55 7.85 15.95-3.45 19.1-9.7-17.95 3.95.6 2.05z"
                    />
                    <path
                        fill="#60F"
                        d="M284 317.7q-.1.1-.15.2-.05.35-.05.7v.8q0 .2.25 1.05v.15q0 .05.05.2.15.2.3.5.2.4.45.8.15.1.25.2.15.3.7 1.2.25.55.5.7v.3q0 .35-.1 1.6-.1 1.35-.15 1.95v2.15q.05.05.05.1 0 .1-.05.15v.05q-.05 0-.05.1t-.2.1h-.75q-.2.1-.35.1-.2 0-.3-.15l-.1-.05q-.45-1.55-.45-3 0-1.2.35-2.35.2-.6.25-.85 0-.15.05-.2.1-.9.1-.95 0-.35-.7-1.2-.1-.15-1-1-.1-.15-.2-.25-.4-.15-.9-.75-.6-.85-.8-1-.55-1-.75-1.45-.05.1-.05.45l-.1 1.35q0 .85.4 2.5.1.35.35.7.15.25.45.65l.4.6.35.35q.05.05.25.1.35.35.8.35v.05q-.1.5-.3 1.4 0 .4-.15 1.6v1.35q0 .35.15 1v.2q.05.4.1.65v.55q-.2-.2-.3-.4 0-.05-.1-.15-.1-.4-.25-.6-.15-.2-.4-.65-.2-.35-.35-.55l-.1-.1q-.15-.3-.3-.75-.4-1.2-.4-1.65 0-.3.15-.95.05-.65.05-1 0-.65-.4-1.4-.3-.95-1-1.35-1.6-1.25-2.25-1.65-.5-.35-.75-.4 0 .25.05.8.1.55.2.85.7 1.8 1.8 2.7.55.45.75.65.2.15.25.4 0 .2-.05.6 0 .2.05 1 .1.6.1 1 .05.05.05.2.05.2.2.6.15.4.15.6v.15q.05 0 .15.1.2.35.25.55l.4 1.25q.25.3.55.8.25.35.7 1.35.6.95.95.95h.2l2.45-.2q1.35-.15 2.7-.2.65-.15.65-.5l-.45-1.4q-.6-1.45-.7-2.25-.2-1.15-.3-2 .05-.4.05-.8v-1.25q.05-.35.25-1.2.3-.75.3-1.2 0-.8-.3-1.3-.25-.45-1.5-1.65-.2-.15-.7-.65-.05-.1-.2-.2-.25-.2-.6-.6-.05-.15-.2-.35v-.05l-.1-.2q-.35-.4-.55-.7H284z"
                    />
                    <path
                        fill="red"
                        d="M292.4 314.5q-.55.15-.55.7 0 .05.6 2.1.85 2.15 2.35 2.15H295.1q.6 0 5.1-1.85.9 3.7 2.05 7 1.25 3.7 2.05 4.25.2-.55.5-1.25.6-1.5.6-2.1.1-1.45.1-2.4-.2-2.85-1.8-4.7-1.1-1.4-1.25-1.6-.6-1-.6-2.3l.25-1.9q0-.75-.65-.7-.95.5-2.25 1-3.1 1.3-4.7 1.5-.2.1-.3.1-.7-.05-1-.05-.6 0-.8.05z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy042__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy042__Layer14_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy042__Layer14_0_MEMBER_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjToy042
