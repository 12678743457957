import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0v16.1h7V0H0z"
                    id="BeaChi003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <g id="BeaChi003__col1n" transform="translate(189 234)">
                <use
                    xlinkHref="#BeaChi003__Symbol_3_0_Layer0_0_FILL"
                    id="BeaChi003__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default BeaChi003
