import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#830000"
}

function FacMou057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M222 205.75q-3.25-3.85-7.4-5.2-.2-.1-.4-.15-.95-.3-1.95-.4-.9-.15-1.8-.2-1.8-.4-3.9-.15h-.05l-1.5.2q-.463.071-.9.15-.1.012-.2 0-.075.038-.15.05-.075.013-.15 0-.05.034-.1.05-2.714.485-5.2 1.25-1.498.416-3.1.65-.323.058-.65.1-.224.02-.45.05-.884.07-1.8.15-1.55.05-3.25 0-3-.1-6-.4-.85-.05-1.75-.15-.9-.1-1.75-.25-2.4-.35-4.7.05-1.7.3-3.35 1.05-.15.05-.3.15-.15.05-.3.15-3.75 1.8-7.3 5.45-5.7 5.9-5.7 14.2 0 8.3 5.7 14.15 5.3 5.4 12.7 5.85.1-.05.2 0 .4-.1.85-.2 1-.25 2-.55 2.5-1 4.85-2.8.45-.35.95-.65.65-.45 1.35-.85 2.55-1.3 5.65-1.65.1-.05.15-.05 1-.1 2-.15 1-.1 2.1-.1 1.2 0 2.5.05 1.3.1 2.6.25.75.1 1.5.25.65.1 1.25.25 2.4.45 4.55 1.45.65.3 1.3.65l.2.1.3.15q3.333 1.564 6.25 0 2.805-1.486 5.15-4.3 5-5.9 5-14.3t-5-14.35z"
                    id="FacMou057__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M33.35 5.2q-.85-2.1-2.4-3.3Q29.3.6 26.8.35q-.2 0-.4-.05-2.75-.3-5.7-.3-4.6-.1-9.05.7-1.05.15-2 .45-1.25.4-2.4.95-2 .9-3.65 2.35-3.25 2.9-3.5 7.5-.2 4.05.1 8.1.1-.05.2 0 .4-.1.85-.2 1-.25 2-.55 2.5-1 4.85-2.8.45-.35.95-.65.65-.45 1.35-.85 2.55-1.3 5.65-1.65.1-.05.15-.05 1-.1 2-.15 1-.1 2.1-.1 1.2 0 2.5.05 1.3.1 2.6.25.75.1 1.5.25.65.1 1.25.25 2.4.45 4.55 1.45.65.3 1.3.65l.2.1q.2-1.55.3-2.6.4-4.3-1.15-8.25z"
                    id="FacMou057__Symbol_124_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M20.9 32.7q.35 1.65.65 3.3 1.2 0 2.5.05 1.3.1 2.6.25.75.1 1.5.25-.15-1.7-.6-3.3-1.2-.35-2.6-.45-2.1-.15-4.05-.1M33.85 37q-.45-.4-.9-.85-1.7-1.5-3.7-2.3.15 1.45.15 2.95 2.4.45 4.55 1.45-.1-.65-.1-1.25M4.5 40.3v1.95q2.5-1 4.85-2.8.45-.35.95-.65-.15-1.65-.4-3.2-1.2.7-2.3 1.6-1.7 1.5-3.1 3.1m14.55-7.45q-1.8.15-3.55.55-2.35.5-4.4 1.5.3 1.55.55 3.05 2.55-1.3 5.65-1.65.1-.05.15-.05 1-.1 2-.15-.25-1.65-.4-3.25M6.45 5.75V2.2q-.9-.1-1.75-.25Q2.3 1.6 0 2v2.15q3.3.95 6.45 1.6m11-3q-1.55.05-3.25 0-3-.1-6-.4v3.7q4.7.75 9.25.65V2.75m11.2 2.4V.55q-2.7.5-5.2 1.25-1.5.4-3.1.65-.3.05-.65.1-.2 0-.45.05v4.05l1.1-.1q4.25-.35 8.3-1.4M35.6.25q-1.8-.4-3.9-.15h-.05l-1.5.2v4.45l1.5-.5q2.9-1 5.75-2.35V.45q-.9-.15-1.8-.2z"
                    id="FacMou057__Symbol_82_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou057__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou057__Symbol_124_0_Layer0_0_FILL"
                transform="translate(176.1 222.5)"
                id="FacMou057__col2n"
            />
            <use
                xlinkHref="#FacMou057__Symbol_82_0_Layer0_0_FILL"
                transform="translate(174.85 199.55)"
                id="FacMou057__col1n"
            />
        </g></g>
    )
}

export default FacMou057
