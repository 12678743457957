import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2d": "#BFBFBF",
    "col2n": "#D9D9D9"
}

function AccHat159({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fillOpacity={0.851}
                    d="M191.95 144.65q-.1-.05-.15 0-29.7-2.15-49.5-9.75-12.05-4.85-24.1-12.1-4.85 2.25-8.95 5.95 2.5 17.9 14.65 33.25 5.15 7.05 11.35 12.55 13.95 12.4 33.15 17 .6.15 1.15.3 1.65.35 3.3.65l19.25 3.95v.05l.15-.05 19.25-3.95q1.65-.3 3.3-.65.55-.15 1.15-.3 19.2-4.6 33.15-17 6.2-5.5 11.35-12.55 12.15-15.35 14.65-33.25-4.1-3.7-8.95-5.95-12.05 7.25-24.1 12.1-19.8 7.6-49.5 9.75-.05-.05-.15 0H191.95z"
                    id="AccHat159__Layer10_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M194 97q0-13.05-2.95-24.8-6.25-24.6-25.45-43.8Q145.2 8 118.75 2.25 108.35 0 97 0 85.5 0 75 2.35 64.95 4.55 55.75 8.9l-.8.4q-.2.05-.4.2-.75.35-1.5.75-.45.2-.9.45h-.05q-5 2.65-9.7 6-.95.65-1.85 1.3-.1.05-.15.1l-1.2.9q-1 .75-1.95 1.55l-.3.2q-2.4 1.95-4.75 4.1-1.9 1.7-3.75 3.55l-.5.5q-2.5 2.5-4.75 5.15Q12.35 46.5 6.6 61q-1.45 3.65-2.55 7.45Q0 81.9 0 97q0 40.15 28.45 68.55 9.25 9.25 19.7 15.5l14.9 8.85q1.096.746 2.2 1.4 3.746 2.24 7.5 3.4 1.8.5 3.65.8 19.75 2.2 39.55.2 3.65-.1 7.5-1.65 2.608-1.06 5.3-2.75.63-.384 1.25-.8l14.4-8.55q1.6-.95 3.25-1.95.25-.2.55-.35 9.2-5.9 17.4-14.1Q194 137.15 194 97m-12.5-18.6q0 6.85-7.95 19.15-7.6 11.8-13.9 16-.95.65-10.65 8.4-7 5.6-13.1 8.25-4.2 1.85-15.1 4.6-14.55 3.65-23.7 3.65-8 0-24.15-4.1-13.85-3.55-16.2-4.9-2.25-1.4-3.2-2.15-1.55-1.2-1.85-2.1-2.05-.6-3.15-.95-2.65-.8-3.2-.95-.6-.25-.8-.75-.3-.75-1.4-2-3.3-2.65-5.85-4.9-6.7-5.6-10.6-11.05-5.95-8.2-9.25-16.15-3.1-7.4-3.1-12.55 0-3.6 2.3-5.35.2-.15.45-.3 1.75-1.15 4.7-1.45.1-.2.25-.35.5-.55 1.25-.7h1.55q.65.35.85.95 4.35.25 9.35 2.2 3.1 1.2 8.2 3.35 3.85 1.2 14.9 6 2.8 1.1 5 1.9.9.3 1.75.6l.9.3q6.3 1.7 12.3 2.8l8.05.9q1.2 0 5.75-.05 2.75 0 3.6.1l.1-.05q.35-.05.35.1 12 0 16.6-.5 6.7-.75 18.05-3.85 6.2-1.7 19.35-8.35 11.35-5.7 14.6-5.7 1.2 0 2.35.1 1.45-.75 2.85-1.2 3.35-1 5.25 1.15 3.05 3.45 3.4 3.6 2.4 1.95 2.9 3.45.2.55.2 2.85z"
                    id="AccHat159__Symbol_514_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M194 87.5q0-13.05-2.95-24.8-7.713 67.469-66.55 118.6-26.763 8.16-53.25.75-60.693-53.32-67.2-123.1Q0 72.4 0 87.5q0 40.15 28.45 68.55 9.25 9.25 19.7 15.5l14.9 8.85q1.309.89 2.6 1.65 4.214 2.515 7.1 3.4.682.19 1.35.35 23.175 4.943 46.3 0 1.525-.386 3.05-1 2.6-1.05 5.35-2.75h-.45q.825-.496 1.65-1.05l14.4-8.55q1.6-.95 3.25-1.95.25-.2.55-.35 9.2-5.9 17.4-14.1Q194 127.65 194 87.5M54 40.9l14.1-5.45 8.65 19.85-13.6 17.35q.9.3 1.75.6l13.95-17.9L54.55 0q-.75.35-1.5.75L67.4 34l-11.7 4.65L42.4 7.2q-.95.65-1.85 1.3-.1.05-.15.1l-1.2.9q-1 .75-1.95 1.55l-.3.2q-2.4 1.95-4.75 4.1-1.9 1.7-3.75 3.55l-.5.5 5.2 13.4q-.55-1.85-.55-4-.05-.6 0-1.4 0-.15.05-.3v-.3q.15-2.1.95-3.8v-.05q1.1-2.3 3.35-2.55 4.6-.65 8.65 5.75.5.75 1 1.55L54 40.9m60.2 19.65h-8.9v2.6h8.9v-2.6m-25.6 2.6v-2.6h-8.9v2.6h8.9m77.5-43.75l-.5-.5q-1.85-1.85-3.75-3.55-2.35-2.15-4.75-4.1l-.3-.2q-.95-.8-1.95-1.55l-1.2-.9q-.05-.05-.15-.1-.9-.65-1.85-1.3l-13.3 31.45-11.7-4.65L141 .75q-.75-.4-1.5-.75l-24.3 55.35 13.95 17.9q.85-.3 1.75-.6L117.3 55.3l8.65-19.85 14.1 5.45 7.4-13.2q.5-.8 1-1.55 4.05-6.4 8.65-5.75 2.25.25 3.35 2.55V23q.8 1.7.95 3.8v.3q.05.15.05.3.05.8 0 1.4 0 2.15-.55 4l5.2-13.4z"
                    id="AccHat159__Symbol_515_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M72 2.6l-.35-.05Q55.1 0 38.75 0 22.4 0 5.8 2.55l-.35.05q-1.95.35-3.2 1.3Q-.1 5.6 0 9.3q1.45 8.95 8.25 17 4.8 7.35 13.65 12.85 8.6 2.85 16.8 2.6h.05q8.25.25 16.8-2.6 8.85-5.5 13.65-12.85 6.8-8.05 8.25-17 .1-3.7-2.25-5.4-1.25-.95-3.2-1.3z"
                    id="AccHat159__Symbol_516_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M45.55 15.5q3.35-.6 3.85-.9.5-.35 2-1.5.7-.6 1.15-1-3.05.65-7.7.6H32.6q-4.65.05-7.7-.6.45.4 1.15 1 1.5 1.15 2.1 1.6.6.45 4.2.95 3.6.45 6.35.25h.05q3.45.2 6.8-.4M59.6 9.95h.15q-.8-.35-2.05-.9-.5-.2-1.05-.45-.4-.3-.75-.55-2.25-.95-5.15-1.8-.15-.05-.35-.1-4.2-1.2-6.65-1.2-1.7 0-2.9.45-.35.15-1 .45-.5.2-1.1.5h-.05V6.3q-.6-.25-1.05-.45-.7-.3-1-.45-1.2-.45-2.9-.45-2.45 0-6.7 1.2-.15.05-.35.1-2.9.85-5.15 1.8-.3.25-.75.55-.55.25-1.05.45-1.25.55-2.05.9h.15q.45 0 5.15-.4 2.55-.25 3.9-.4.3-.05.55-.05.7-.05.9-.1H49.1q.2.05.9.1.25 0 .55.05 1.35.15 3.9.4 4.7.4 5.15.4M75.2 0q.1.75.1 1.6-1.45 8.95-8.25 17-4.8 7.35-13.65 12.85-7.45 2.5-14.65 2.65h-.05q-7.2-.2-14.65-2.65-8.85-5.5-13.65-12.85-6.8-8.05-8.25-17 0-.85.1-1.6Q-.1 1.7 0 5.4q1.45 8.95 8.25 17 4.8 7.35 13.65 12.85 8.6 2.85 16.8 2.6h.05q8.25.25 16.8-2.6 8.85-5.5 13.65-12.85 6.8-8.05 8.25-17 .1-3.7-2.25-5.4z"
                    id="AccHat159__Symbol_517_0_Layer0_0_FILL"
                />
                <path
                    d="M128.1 93.4q.013.143 0 .3-.037.775 0 1.4 0 2.131.5 4 .566 1.886 1.65 3.5.685 1.027 1.5 1.85.8.785 1.7 1.4 1.019.707 2.1 1.1.873.25 1.85.25 2.95 0 5.05-2.1 2.1-2.1 2.1-5.05 0-1.285-.4-2.4l-2.05-3.7q-.51-.775-1-1.5-4.053-6.397-8.7-5.8-2.204.297-3.3 2.6-.818 1.752-.95 3.85-.02.163-.05.3m127.85-4.15q-1.1-2.3-3.35-2.55-4.6-.65-8.65 5.75-.5.75-1 1.55l-2.05 3.65q-.4 1.1-.4 2.4 0 2.95 2.1 5.05 2.1 2.1 5.05 2.1 1 0 1.85-.2 1.1-.45 2.1-1.15.9-.6 1.65-1.35.85-.85 1.55-1.9 1.1-1.6 1.6-3.5.55-1.85.55-4 .05-.6 0-1.4 0-.15-.05-.3v-.3q-.15-2.1-.95-3.8v-.05z"
                    id="AccHat159__Layer1_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M236.35 129.3l-4.95 6.1q-14.35 4.55-39.35 6.65H192q-.05-.05-.05 0-.05-.05-.1 0h-.05q-24.95-2.1-39.35-6.65l-5-6.1q-14.75-4.15-28.9-13.4-7.3 2.95-13.6 8.25l-.8 7.25q1.2 32.3 34 54.55h9.45q8.1 4.95 12.95 5.95l30.7 6.3h1.3l30.75-6.3q4.85-1 13.15-5.95h9.3q32.8-22.25 33.9-54.55l-.7-7.25q-6.3-5.3-13.65-8.25-14.15 9.25-28.95 13.4M192 145.8h.15q.1-.05.15 0 29.05-2.05 48.35-9.3 11.8-4.55 23.65-11.45 4.7 2.15 8.7 5.65-2.45 16.95-14.3 31.5-5.05 6.7-11.1 11.95-13.65 11.75-32.4 16.1-.55.15-1.2.3-1.55.35-3.15.65L192 194.9l-.15.05v-.05l-18.8-3.7q-1.6-.3-3.25-.65-.55-.15-1.15-.3-18.75-4.35-32.35-16.1-6.1-5.25-11.15-11.95-11.85-14.55-14.3-31.5 4-3.5 8.75-5.65 11.8 6.9 23.55 11.45 19.35 7.25 48.4 9.3.05-.05.15 0h.3z"
                    id="AccHat159__Layer1_1_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat159__Layer10_0_FILL" />
            <use
                xlinkHref="#AccHat159__Symbol_514_0_Layer0_0_FILL"
                transform="translate(95.5 56.8)"
                id="AccHat159__col1n"
            />
            <use
                xlinkHref="#AccHat159__Symbol_515_0_Layer0_0_FILL"
                transform="translate(95.5 66.3)"
                id="AccHat159__col1d"
            />
            <use
                xlinkHref="#AccHat159__Symbol_516_0_Layer0_0_FILL"
                transform="translate(153.8 204.65)"
                id="AccHat159__col2n"
            />
            <use
                xlinkHref="#AccHat159__Symbol_517_0_Layer0_0_FILL"
                transform="translate(153.8 208.55)"
                id="AccHat159__col2d"
            />
            <use xlinkHref="#AccHat159__Layer1_0_FILL" />
            <use xlinkHref="#AccHat159__Layer1_1_FILL" />
        </g></g>
    )
}

export default AccHat159
