import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMak004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M54.7 17.5q-.25-.7-.5-1.35-2.8-7.5-9.55-11.1-8.1-5.15-19.5-2-1.05.3-2.8.55-1.75.2-4.35-1.15Q15.35 1.1 14.55 0q.45 3.4 2 4.6 1.5 1.2 2.5 1.05-3.9 1.95-7.05.9l-1.1-.5q-1.35-.7-2.6-1.95.5 3.55 2.45 4.75 1.1.65 2.35 1.05-3.45 1.95-5.75 1.2l-.3-.1Q4.45 10.25 3 9.25q.95 3 3.2 4.05 2.2 1 2.85.8-.35.2-1.15.65-1.65.85-3.05.8-1.45-.05-2.45-.45-1.05-.45-2.4-1.2 1.15 1.7 2.4 2.75.6.5 1.2.85.2.1.45.25 3.35 1.45 6.55-1.5l.15-.15Q25.7.85 42.55 6.95 50.5 10.5 55.8 23l-.1-1.6q-.05-.25-.1-.45-.1-.85-.3-1.65-.15-.2-.15-.5-.25-.65-.45-1.3m54.05-8.25q-1.45 1-4.05 1.75l-.3.1q-2.3.75-5.75-1.2 1.25-.4 2.35-1.05 1.95-1.2 2.45-4.75-1.25 1.25-2.6 1.95l-1.1.5q-3.15 1.05-7.05-.9 1 .15 2.5-1.05 1.55-1.2 2-4.6-.8 1.1-3.45 2.45-2.6 1.35-4.35 1.15-1.75-.25-2.8-.55-11.4-3.15-19.5 2-6.75 3.6-9.55 11.1-.25.65-.5 1.35-.2.65-.45 1.3 0 .3-.15.5-.2.8-.3 1.65-.05.2-.1.45l-.1 1.6q5.3-12.5 13.25-16.05Q86.05.85 101 16.1l.15.15q3.2 2.95 6.55 1.5.25-.15.45-.25.6-.35 1.2-.85 1.25-1.05 2.4-2.75-1.35.75-2.4 1.2-1 .4-2.45.45-1.4.05-3.05-.8-.8-.45-1.15-.65.65.2 2.85-.8 2.25-1.05 3.2-4.05z"
                    id="SkiMak004__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak004__Symbol_4_0_Layer0_0_FILL"
                transform="translate(136.65 133.2)"
                id="SkiMak004__col1n"
            />
        </g></g>
    )
}

export default SkiMak004
