import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1n": "#252525",
    "col1s": "#000000",
    "col2d": "#597B6B",
    "col2l": "#CDDED6",
    "col2n": "#94C0AB"
}

function AccHat041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M186.75 60.35q-1.55 3.35-2.95 6.75-5.3 10.15-11.2 18.3V67.25q1.5-28.8-9.3-43.5-6.15-7.35-15.65-12.55-5.85-3.2-13-5.6l-.3-.1q-4.8-1.9-9.95-3-1.6-.35-3.2-.6l-6.25-.9Q97.1-1.4 84.8 1.95q-9.15 2.5-15.2 8.2-1.15.9-2.05 2.1-.05 0-.05.05-1.05 1.2-2 2.5-4.2 5.6-7.1 12.1l-.3.7q-5.65 13.05-7 36.35 0 .1-.05.25-.1.45-.15.9v.1q-.05.15-.05.3-.15-.6-.3-1.1 0-.15-.05-.25-1.35-5.25-3.4-10.25-3.8-9.3-6.15-18.95-1.6-6-3.75-11.8-.95-2.5-2.45-4.65-1.45-2.05-3.45-3.65-3.95-3.1-9-1.4-5.85 1.75-10 6.25-3.45 3.75-5.85 8.25-4.1 7.7-5.5 16.45Q-.7 54.5.45 64.75q.1 1.05.55 1.95.55 2.9 1.35 5.75 1.5 5.2 4.8 9.6 2.3 3.05 5.1 5.8 1.4 1.35 2.85 2.65 14.5 13.3 56.5 24.3 9.5 2.45 20.45 4.85 18.85 4.75 34.85 7.25 22.85 3.55 39.85 2.6l6.5-.6q.25-.05.55-.05 1-.15 2-.25 7.2-1.05 13.95-3.9.95-.4 1.95-.65 9.1-2.75 14.85-9.9Q213 106.2 214.5 96q.15-1.2.35-2.3 1.65-8.35 1.4-16.65.15-6.3-1-12.6-.65-3.25-1.6-6.15-1.6-5.05-4.35-9.6-3.3-5.4-9.2-5.35-7.45 2.65-13.35 17z"
                    id="AccHat041__Symbol_259_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M19.05 97.25q.25.25.55.5Q46.75 122 87.85 110.5l.5-.1q.15-.1.3-.1-.55-.05-1.05-.1-.8 0-1.6-.05l-64.5-12v-.05q-.15-.05-.2-.05-1.15-.4-2.25-.8M33.9 0q-9.15 2.5-15.2 8.2-1.15.9-2.05 2.1-.05 0-.05.05-1.05 1.2-2 2.5-4.2 5.6-7.1 12.1l-.3.7Q1.55 38.7.2 62q0 .1-.05.25-.1.45-.15.9v.1l3.25 7.7 3.7 5.1q1.3-27.85 10.4-55.7Q23.4 7.4 33.9 0m70.55 14.25q-.35.15.3 1.55 8.3 10.7 8.8 44.2.2 7.25-.05 15t-1.55 15.65q-.4 2.25-.8 3.7l4.05-3.6 6.5-7.3V65.3q1.5-28.8-9.3-43.5-3.45-4.1-7.95-7.55z"
                    id="AccHat041__Symbol_167_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M69.6 13.05q-.55-.05-1.05-.1l-2.75-.1h-.35q-37.5 9.25-63-12Q2.3.8 2.25.8 1.1.4 0 0q27.55 25.1 69.6 13.05z"
                    id="AccHat041__Symbol_125_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M111.8 20.1h-.1q-.3-.05-.55-.05h-.2q-1-.1-2-.15l-10.8-.9Q58.6 16.25 19.9 5.45L2.65.75Q1.8.5.95.3.45.1 0 0q.4 1.1.8 1.9.35.75 1.9 4.2 1.05 2.45 1.95 4 .05.15.15.3.95 1.7 1.8 3.15Q8.1 16 9.45 17.8q.4.6.85 1.15.45.6.9 1.15 3.4 4.45 4.4 6.25.15 0 .3.05 1.1.4 2.25.8.05 0 .2.05 3 1 6 1.95l.6.1-.05.05Q50.95 37.25 77 39h.2q2.1.15 4.15.25h.35l2.75.1q.5.05 1.05.1.3 0 .65.05.1-.05.25-.1.95-.35 1.9-.65.15-.1.35-.15 13.65-5.45 22.75-17.65.1-.25.2-.45l.2-.4z"
                    id="AccHat041__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.15 6.05q.95 1.7 1.8 3.15 1.5 2.45 2.85 4.25.4.6.85 1.15.45.6.9 1.15l4.6-4.2-5.8-11Q5.4.3 5.5 0 2.35 3.2 0 5.75q.05.15.15.3M27.7 15.5L22 3.7l-9.45 8.1 6.35 10.6 8.8-6.9m10.15-7.6l-8.25 8.05 5.65 11.25q6-6.8 8.55-8.75L37.85 7.9m17 3.05l-7.6 8.85 5.4 11.15 8.15-9.45-5.95-10.55m22.7 12.35q0-.25-.05-.45t-.1-.35l-6.85-9.3-7.15 8.85 6.75 11.2 7.4-9.95m10.4-7.8l-6.7 8.5 3.6 5.5q.35.6.8 1.2l.2.3v.1l.7 1.35q-.25 1.25 3-.8L95 25.1l-7.05-9.6z"
                    id="AccHat041__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M2.85 8.05q.75-.2 1.3-1.15.75-1.15.75-2.85 0-1.7-.75-2.9Q3.45 0 2.45 0T.7 1.15Q.05 2.25 0 3.8q1.5 2.45 2.85 4.25m15.45 3.9q1 0 1.7-1.2.75-1.15.75-2.85 0-1.7-.75-2.9-.7-1.15-1.7-1.15T16.55 5q-.7 1.2-.7 2.9t.7 2.85q.75 1.2 1.75 1.2m17.6-2.7q-.7-1.15-1.7-1.15t-1.75 1.15q-.7 1.2-.7 2.9t.7 2.85q.75 1.2 1.75 1.2t1.7-1.2q.75-1.15.75-2.85 0-1.7-.75-2.9m17.55 3.85q-.7-1.15-1.7-1.15T50 13.1q-.7 1.2-.7 2.9t.7 2.85q.75 1.2 1.75 1.2t1.7-1.2q.75-1.15.75-2.85 0-1.7-.75-2.9m15.4 8.9q1 0 1.7-1.2.75-1.15.75-2.85 0-1.7-.75-2.9-.7-1.15-1.7-1.15t-1.75 1.15q-.7 1.2-.7 2.9t.7 2.85q.75 1.2 1.75 1.2M86 23.35q1 0 1.7-1.2.75-1.15.75-2.85 0-1.7-.75-2.9-.7-1.15-1.7-1.15t-1.75 1.15q-.7 1.2-.7 2.9t.7 2.85q.75 1.2 1.75 1.2z"
                    id="AccHat041__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat041__Symbol_259_0_Layer0_0_FILL"
                transform="translate(92 4.7)"
                id="AccHat041__col1n"
            />
            <use
                xlinkHref="#AccHat041__Symbol_167_0_Layer0_0_FILL"
                transform="translate(142.9 6.65)"
                id="AccHat041__col1d"
            />
            <use
                xlinkHref="#AccHat041__Symbol_125_0_Layer0_0_FILL"
                transform="translate(161.95 103.9)"
                id="AccHat041__col1s"
            />
            <use
                xlinkHref="#AccHat041__Symbol_82_0_Layer0_0_FILL"
                transform="translate(146.05 77.5)"
                id="AccHat041__col2n"
            />
            <use
                xlinkHref="#AccHat041__Symbol_44_0_Layer0_0_FILL"
                transform="translate(150.7 81.85)"
                id="AccHat041__col2l"
            />
            <use
                xlinkHref="#AccHat041__Symbol_23_0_Layer0_0_FILL"
                transform="translate(152.65 87.25)"
                id="AccHat041__col2d"
            />
        </g></g>
    )
}

export default AccHat041
