import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}

function ObjFoo058({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.55 10.3Q46.4 8.75 45 7.2l-.35-.35q-.15-.2-.3-.35l-.2-.2q-.4-.3-.7-.6-2.3-1.8-6.1-3.25-.75-.3-1.55-.5-.85-.35-1.7-.55-.55-.2-1.1-.35-2.45-.6-4.7-.85-1.75-.25-3.4-.2-3.5-.05-6.05.25-1.2.2-2.25.5-1.65.4-3.25 1.25-.9.5-1.65 1-.5.3-.9.65-1.6 1.2-2.75 2.45-.8.8-1.4 1.75-.15.15-.2.3-.25.35-.45.7-.7 1.3-.95 2.7-.15.8-.15 1.65.05 2.25-1.65 6.95-.4 1-.8 2.2-.25.65-.5 1.35-.45 1.2-.8 2.5-.6 2.2-.9 4.15-.1.1-.1.3-.2 1.95-.15 3.7.1 3.85.9 6.6.25.7.5 1.2.1.35.2.55.2.4.45.8.5.9 1 1.7.95 1.4 1.75 2.25.9 1 1.8 1.45 2.65 1.2 6.85-.9.2-.15.45-.3.25-.1.45-.25.3-.15.6-.35.5-.25 1.05-.65.1-.15.35-.25.7-.55 1.55-1.3l.75-.75q1.85-1.85 3.5-3.45.6-1 1.5-2.45 1.55-2.35 2.75-4.05.6-.9 2.6-2.2 1.75-1.2 3.6-2 .2-.1 1.1-.95.85-.8 1.4-1.1 1-.55 2-.75 1.6-.35 2.2-.55h2.15q2.45-1.15 2.45-1.1l.15-.1q1.4-.8 2.55-2l.3-.3h.05q0-.05.05-.05 0-.05.05-.1l.05-.05q.2-.2.35-.45.15-.2.35-.4 0-.1.1-.2.05-.1.1-.15 0-.1.1-.15 0-.1.1-.2l.15-.3q.4-.75.7-1.45 1.2-2.95.55-5.5-.15-.4-.3-.85-.2-.5-.5-1.05l-.4-.7q-.15-.2-.3-.45-.25-.35-.55-.8z"
                    id="ObjFoo058__Symbol_128_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M36.2 1.2q-.2-.1-.35-.2-2.85-1.95-8 0-.15.1-.3.1l-4.5 2.6q-.55.4-1 .75Q20 6.1 16.2 9.65q-.5.55-1.3 1.35Q-.3 28.25 0 32.85 0 34 .65 35.1q.35.65 1.1 1.3.45.35.8.6 2 1.3 4.55.1.3-.15.6-.35.5-.25 1.05-.65.1-.15.35-.25.7-.55 1.55-1.15.45-.5.95-.9.45-.35.9-.8.1-.05.15-.15-2.1 1.6-3.75 2.45l-.2.1q-1.65.9-2.9.9-.3 0-.55-.05-.3 0-.55-.15-.65-.15-1.15-.65 0-.05-.05-.05-.25-.35-.4-.75-.15-.35-.2-.65-.2-.55-.3-1.15l-.05-.05h.05q-.1-.3 0-.75 0-.1-.05-.25.15-.5.3-1.15.3-.75.65-1.65.1-.1.2-.25 0-.1.05-.25.2-.35.45-.75.05-.1.15-.15.6-1.1 1.35-2.35.65-.95 1.35-1.95.2-.3.35-.5.15-.3.4-.6.2-.3.45-.7.25-.25.45-.55.65-.9 1.4-1.9.05-.1.2-.3.05-.05.1-.05.3-.45.7-.95.05 0 .05-.1.05-.05.1-.05.8-1 1.65-2 .4-.5.85-.95v-.05q1-1.1 2-2.15.4-.35.75-.7.6-.6 1.35-1.2.3-.4.75-.75.15-.15.35-.3.15-.25.4-.45l.2-.1.7-.6q.6-.55 1.2-1h.05q.75-.65 1.45-1.25.05-.05.1-.05 1.45-1.1 2.55-1.8.5-.35.95-.6.25-.15.5-.25.85-.5 1.75-.7.15-.1.4-.15h.15q.2-.05.35-.1.8-.1 1.7-.1 1 0 2 .25.4.15.75.3 1 .3 1.75.85.25.2.55.35.5.45 1.05.95.25.35.55.85.2.25.35.55.25.4.4.75.25.55.4 1.1.05.25.1.55.1.65.1 1.25-.05.75-.3 1.5-.05 0 0 .05l-.15.3-.1.3q-.25.5-.55.85v.1q-.05.1-.15.25-.55.75-1.4 1.65-.05 0-.1.1l.15-.1q1.4-.8 2.55-2 1.7-1.85 1.5-5.25-.7-4.1-4.65-6.65z"
                    id="ObjFoo058__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.2 41.35q-.4-.25-.75-.4-.35-.15-.8-.25.25.7.5 1.2.1.35.2.55.2.4.45.8.5.9 1 1.7.95 1.4 1.75 2.25.5-1.75-.25-3.35-.15-.3-.35-.65-.65-1.15-1.75-1.85m3.85-4.9q-.1-.05-.25-.1-.7-.15-1.3.2-.55.35-.75 1-.2.7.15 1.3.05.1.15.15.3.45.85.6.7.15 1.25-.2.6-.35.8-1 .2-.65-.15-1.25-.3-.5-.75-.7M12 21.75q-1.1.65-1.4 1.85-.35 1.25.3 2.3.35.65.9 1.05.4.25 1 .45 1.15.25 2.25-.4 1.05-.6 1.45-1.75.35-1.25-.3-2.4-.25-.4-.65-.75-.45-.45-1.25-.65-1.25-.3-2.3.3M3.25 23.5q-.8-.2-1.55-.05-.45 1.2-.8 2.5-.6 2.2-.9 4.15.5.5 1.3.65 1.5.45 2.9-.35.25-.15.5-.35.9-.7 1.2-1.95.3-1.05.05-2.1-.2-.35-.45-.75l-.05-.1q-.35-.6-.8-.95-.55-.45-1.4-.7M8.3 7.55q.2-.7-.15-1.3Q8 6 7.8 5.85q-.8.8-1.4 1.75-.15.15-.2.3-.25.35-.45.7.15.15.45.2.4.15.9 0 .15-.05.4-.15.6-.35.8-1.1m2.85 3.75q-.45-.1-.9-.05-.1 0-.15.05-.35.1-.65.25-.3.2-.55.4-.45.5-.6 1.15-.25 1 .25 1.85.2.35.4.55.45.45 1.1.65.65.15 1.25 0 .35-.1.65-.3.9-.5 1.15-1.45.3-1.05-.25-1.95-.5-.85-1.5-1.1-.1-.05-.2-.05m5.8-6.55Q18.3 4 18.75 2.4q.35-1.2-.15-2.4-1.2.2-2.25.5-1.65.4-3.25 1.25-.9.5-1.65 1 .1.35.3.65.25.45.6.75.7.7 1.75.95 1.5.45 2.85-.35m6.25 10v-.05q.2-.65-.15-1.2-.3-.55-1-.75-.6-.15-1.2.15-.55.35-.75 1-.1.65.2 1.2.35.55.9.75l.05.05q.6.1 1.1-.1l.1-.1q.15-.05.3-.15.35-.35.45-.8M29.4 4.2q-.3-.5-.7-.9l-.15-.15q-.6-.45-1.3-.65-1.5-.35-2.75.35-1.3.75-1.65 2.2-.4 1.4.35 2.7.45.75 1.1 1.2.45.3 1.05.45.9.3 1.65.1.6-.2 1.1-.5 1.25-.7 1.65-2.15.4-1.4-.35-2.65M38.95 9q.15-.05.3-.15.35-.35.4-.8L39.7 8q.15-.6-.15-1.2-.35-.55-1.05-.75-.6-.1-1.15.2-.55.35-.8.95-.15.65.2 1.2.3.6.9.75l.05.05q.6.1 1.15-.1 0-.05.1-.1M37.1 2.2q-.75-.3-1.55-.5-.85-.35-1.7-.55-.55-.2-1.1-.35 0 .5.25.95.1.1.2.25.5.7 1.35.95 1.05.25 1.95-.25.35-.2.6-.5m10.2 7.85q-1.15-1.55-2.55-3.1-.05.3-.15.55-.45 1.75.45 3.3.15.25.35.5.85 1.2 2.3 1.6.7.15 1.35.15-.2-.5-.5-1.05l-.4-.7q-.15-.2-.3-.45-.25-.35-.55-.8z"
                    id="ObjFoo058__Symbol_127_0_Layer0_0_FILL"
                />
                <path
                    fill="#E5CD84"
                    d="M119.9 275.05q-.75.6-1.35 1.2.11.994-.75.7-1 1.05-2 2.15v.05q.648 1.09-.85.95-.85 1-1.65 2-.044 0 .05.15.132.15.25.6.077.159.15.2-1.022.313-1.6.5-.75 1-1.4 1.9-.117.344.4 1.05.085.146.15.25.162-.032.4-.05.765-.058-.35.35-.016.012-.05 0-.735.297-2.2.75-.7 1-1.35 1.95.426.926.35 1.4-.007.092-.05.15-.097.35-.5.45h-.05q-.598.464-1.7 1.25-.05.15-.05.25-.1.15-.2.25-.35.9-.65 1.65 1.8.364 1.2.45-.552.141-.35.2.202.104-1.1 1.5h-.05l.05.05q.1.6.3 1.15 2.078-.038 1.4.45-.678.538-.8.95.05 0 .05.05.5.5 1.15.65.25.15.95-.15.75-.298 1.85-.55 1.15-.252 1.2-.15.1.1.2.05 1.65-.85 3.75-2.45l.05-.05q.1-.05.15-.05.05-.1.1-.15.05-.1.2-.15 1.15-1.15 2-2.1 1.3-1.55 2-2.8.2-.3.45-.55-.05-.1-.1-.15-.2-.3-.4-.65-.497-.594 9.1-6.15 2.822-1.638 4-2.4l.05.1q.05 0 .1-.05.55-.25 1.25-.6.35-.05.7-.15 1.25-.3 2.45-.85.95-.35 1.9-.9.05-.1.1-.1.85-.9 1.4-1.65.1-.15.15-.25v-.1q-.43-.35-.1-.7.335-.3.55-.45.255-.1.35-.3-.05-.05 0-.05.25-.75.3-1.5-.248-.105-.3-.7-.002-.545.1-1.1-.15-.55-.4-1.1-.747-.012-1.5-.05-.752-.04-.15-.9.6-.853.35-1.2-.55-.5-1.05-.95-.3-.15-.55-.35-1.95 2.126-1.75-.85-.35-.15-.75-.3-1-.25-2-.25-1.57 1.593-1.8 1-.185-.545-.3-.7-.055-.102-.1-.1-.25.05-.4.15-.9.2-1.75.7-.25.1-.5.25-.45.25-.95.6-.528 3.557-2.55 1.8-.05 0-.1.05-.7.6-1.45 1.25h-.05q-.363 1.876-.85 1.6-.155-.315-.35-.6l-.7.6-.2.1q-.25.2-.4.45.175.713 0 1.15-.175.437-1.1-.1z"
                    id="ObjFoo058__Layer1_0_FILL"
                />
                <path
                    fill="#FFE69A"
                    d="M140.2 277.95q.1-.15.15-.25v-.1q.3-.35.55-.85l.1-.3.15-.3q-4.15 2.6-9.2 4.3l-.45.15q4.25-.25 8.7-2.65m1.15-4.6q-.05-.3-.1-.55-4.45 3.7-9.7 6.3-.2.05-.85.3.6-.2.65-.2 5.5-1.35 10.1-4.6 0-.6-.1-1.25m-3.4-5.1q-.75-.55-1.75-.85-2.8 5.65-7.65 10.6 5.75-4 9.4-9.75m2.5 2.7q-.15-.3-.35-.55-.3-.5-.55-.85-3.1 4.45-8.45 8.15-.45.25-1.5.85.95-.5 1.25-.6 5.25-2.2 10-6.25-.15-.35-.4-.75m-16.9.9h-.05q-.6.45-1.2 1 1.4 2.05 2.6 5.35-.65-3.7-1.35-6.35m7.7-4.8q-.25.05-.4.15-.75 3.9-2.2 7.85l-.4 1.15q-.25.4-.8 1.55.45-.75.55-.9 3.3-4.7 5.45-10-.9 0-1.7.1-.15.05-.35.1h-.15m-3.6 1.7q-1.1.7-2.55 1.8 1.5 3 1.65 7.25 1.4-5.1.9-9.05m-9.8 23.1q.05-.1.6-1-.6.9-.65 1-.1.1-.15.2-4.7 4.8-10.9 8.55.25.15.55.15.25.05.55.05 1.25 0 2.9-.9l.2-.1q4.75-3.9 6.9-7.95m-.75-2.25q-1.45 1.25-2.3 2-4.25 4.3-9.85 6.9.05.3.2.65.15.4.4.75 6.95-3.85 11.55-10.3m-10.85 2.65q-.25.4-.45.75 5.3-1.9 10.2-5.65-5.2 2.3-8.25 2.4-.75 1.25-1.35 2.35-.1.05-.15.15m-1.35 2.9q-.15.65-.3 1.15.05.15.05.25-.1.45 0 .75 6.85-3.2 11.65-8.95-6.55 4.7-11.4 6.8m5.4-9.15q-.25.4-.45.7-.25.3-.4.6-.15.2-.35.5 2.65.35 6.95-1.7h-.1q-2.3.4-5.2-.65-.2.3-.45.55m3-3.9q-.05 0-.1.05 0 .1-.05.1-.4.5-.7.95-.05 0-.1.05-.15.2-.2.3 2.85.5 4.8-.15l-3.65-1.3m2.5-2.95q-.45.45-.85.95 1.9 1.2 4.4 1.35l-3.55-2.3m4.85-4.85q-.45.35-.75.75l3.1 3.65-2-4.7q-.2.15-.35.3m-2.1 1.95q-.35.35-.75.7 1.55 1.85 3.5 2.65l-2.75-3.35z"
                    id="ObjFoo058__Layer1_0_MEMBER_0_FILL"
                />
                <path
                    fill="#FFF1C6"
                    d="M119.55 291.35q.05.05.1.15 1.45 2.05 1.9 2.8 1.6 2.8 2.85 6.1.863 2.257 1.45 3.5.281.63.5 1 1.95 3.4 5.6 3 2.45-.2 6.4-2.5 1.187-.672 2.15-1.5 2.486-2.066 3.7-5.1 1.85-4.45-.3-8.15-1.05-1.8-3.05-3.05-2.3-1.3-3.55-2.15-.1-.1-.2-.15-.1-.1-2.4-1.45-1-.6-1.6-1.05-.45-.35-.7-.6-.05.05-.1.05 0-.05-.45-.6-.35-.4-.9-1.1-.45-.6-.85-.95-.8-.55-1.55-.8-.9-.25-2.6-.1-.5.05-1 .15-1.25.25-2.15.75-1.9 1.05-3.45 2.6-1.05 1.05-1.55 2.05-1.25 2.3-.1 4.3l.1.2q.1.15.2.25.15.2.4.65t.75 1.05q.2.35.4.65z"
                    id="ObjFoo058__Layer1_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo058__Symbol_128_0_Layer0_0_FILL"
                transform="translate(94.8 254.15)"
                id="ObjFoo058__col1n"
            />
            <use
                xlinkHref="#ObjFoo058__Symbol_126_0_Layer0_0_FILL"
                transform="translate(102.05 264.5)"
                id="ObjFoo058__col1d"
            />
            <use
                xlinkHref="#ObjFoo058__Symbol_127_0_Layer0_0_FILL"
                transform="translate(95.05 254.4)"
                id="ObjFoo058__col2n"
            />
            <use xlinkHref="#ObjFoo058__Layer1_0_FILL" />
            <use xlinkHref="#ObjFoo058__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjFoo058__Layer1_1_FILL" />
        </g></g>
    )
}

export default ObjFoo058
