import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiHig020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M61.9 18.25q.05-.35.05-1.35 0-1.3-.75-3-.3-.75-1.45-2.85-.55-1.05-1.4-1.9-.35-.35-.75-.85-.5-.45-1-.8Q47.1-.6 33.45.05q-3.75.15-7.3 1-.95.15-1.9.45-4.75 1.4-8.9 3.85-3 1.95-5.65 4.45-6.6 6.3-8.65 14.15-.85 3-.95 6.35-.15 1.65 0 3.4.15 2.3.75 4.5.15.5.35 1.05l.3.9q0-.2.05-.4l.4.3q2.35 1.25 3 1.95 1.85 1.85 4.85 7.55 1.45 2.75 3 5.9-.1.2-.15.45-.05.05-.05.1h-.05q-.05.1-.1.25-.45 1.1-.5 2.15v.2q-.15.8-.15 1.25 0 1.95 1.2 3.8.3.4.65.85.55.6 1.25 1.25 1.55 1.1 3.05 2 7.3 4.25 12.3 2.45.25-.15.65-.3 1.35-2.45 2.9-4.8l4.55-6.4q1-1.3 2.05-2.6 1.1-1.35 2.25-2.7 4.3-4.95 9.15-9.35 9.6-8.5 18.7-14.35l.05-1.3v-.6q-.25-2.65-1.85-4.75-.55-.7-1.25-1.25-1.1-1.05-2.5-1.7l-.95.35q-.45-.1-1.05-.2-1.135-.148-1.35-1.2.016.05.05.05.05-.15.05-.2.15-.4.2-.65z"
                    id="HaiHig020__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M18.8 11.85q-3.25 1.25-6 2.8Q3.75 19.7 0 28.05q.2 3.35 1.4 6.45.1-.9.25-1.8 1.6-7.85 9.25-15.05 1.95-1.85 4.15-3.35t4.6-2.65q-.4.1-.85.2m43 .75q.05-.35.05-1.35 0-1.3-.75-3-.3-.75-1.45-2.85-.55-1.05-1.4-1.9-.35-.35-.75-.85-.5-.45-1-.8-2.5-.8-3.6-1.1Q51.25.3 49.3.1q-1.7-.15-3.5-.05Q49 .8 53.15 3.9q.4.35.95.7 1.95 1.6 4.7 4.05-.55-.15-2.2-.3-.1-.05-.15-.1-2.25-.25-4-.2-9-1-21.35.9 1.25-.05 2.45-.05 5.6-.25 12 .7 1.2.15 2.35.35 5.75 1 7.6 1.45 1.5.35 2.85.8.6.15 1.1.4 1.1.4 2.1.85.05-.15.05-.2.15-.4.2-.65z"
                    id="HaiHig020__Symbol_109_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M30.65 42.6q-1.35 1.1-2 1.5-.7.4-1.6 1.2-.95.7-3.5 3.15-2.6 2.35-3.75 3.45-.9.85-1.9 1.9-4.35 4.35-5.3 9.7.55.6 1.25 1.25 1.55 1.1 3.05 2-.25-5.9 2.65-10.45.3-.5.7-1.05 3.05-4.2 4.75-6.15l.05-.05 1.1-1.4q1.3-1.6 4.5-5.05M44.1 9l5.6.9q-3.9-2.5-8.45-3.85-7.45-2.4-15-1.45 5.1-3.15 14.85-2.7Q38.05.55 35.8.25q-3.2-.45-9.95-.1.05-.05-.35-.1h-.4q-.95.15-1.9.45-4.75 1.4-8.9 3.85-3 1.95-5.65 4.45Q2.05 15.1 0 22.95q4.7-5.9 13-9.6Q28.05 7.2 44.1 9m23.55 13.05q-.55-.7-1.25-1.25l-4.45 2.45q-1.05.5-2.05 1.15-.55.25-6.6 3.95-6.05 3.65-12.1 9.7-6.1 6.05-8.9 9.2-2.8 3.15-6.9 9-2.05 3-3 4.45 1.8-1.6 6-6.25 4.25-4.65 9.9-9.45 5.2-4.4 10.7-8.5-.7.65-1.55 1.55-5.75 5.7-9.55 11.75-3.8 6-4.2 6.7.55-.7 1.2-1.35Q41.25 48 45.55 44q.1-.1.25-.2l-.1.15q.11-.157.25-.25.75-.65 1.4-1.3 4.2-3.7 7.15-5.65 5.85-5.3 15-9.95-.25-2.65-1.85-4.75z"
                    id="HaiHig020__Symbol_108_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M52.05 10q4.2-1.95 6.45-2.7l5.2-1.7q-1.2-.55-3.25-1.2 0 .05-.05.2-1-.45-2.1-.85-.5-.25-1.1-.4-1.35-.45-2.85-.8-1.85-.45-7.6-1.45Q45.6.9 44.4.75 38-.2 32.4.05q-10.6.5-18.5 5.4-2.2 1.5-4.15 3.35Q2.1 16 .5 23.85q-.15.9-.25 1.8-.15.6-.2 1.3L0 29.55q-.05 1.7.55 3.8.3.9.7 1.9.3.65.6 1.25.8 1.3 1.85 2.05l.1.1q.45.5 1 .8.5.3 1.1.6.55.25 1.2.5.65.25 1.65.45 1.05.2 2.65.4.1-.3.25-.55.3-.65.7-1.35.15-.3.4-.7h-.05q.5-.8 1.65-1.9 1.15-1.15 2.95-2.65.45-.45.95-.85 8.45-7.8 20.4-15.45 1.65-1 3.3-1.95 1.9-1.3 3.75-2.35 1.3-.8 2.4-1.35.25-.2.5-.3.4-.25.75-.45.1-.1.5-.4h-.05L52.05 10m-38.1 6.95q.052-.105.1-.25h.1l-.05.1q-.097.048-.15.15z"
                    id="HaiHig020__Symbol_113_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M32.4 2.55q1.3-.8 2.4-1.35.25-.2.5-.3.4-.25.75-.45.1-.1.5-.4h-.35q-1.85-.05-2.6 0-1.25 0-2.55.2-3.65.35-7.15 1.4-.7.2-1.4.45l-.8.2q-.55.15-1.1.4-.6.25-1.15.5-9.9 4.05-13.85 8.05Q0 16.85 0 22.2q0 1.35.55 2.6.25.65.5 1Q2.2 24.65 4 23.15q.45-.45.95-.85 8.45-7.8 20.4-15.45 1.65-1 3.3-1.95 1.9-1.3 3.75-2.35z"
                    id="HaiHig020__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M54.7 3.45q-1.05-.35-2.1-.65Q39.7-1 27 .35q-6.2.9-13.55 5.15Q8.65 8.25 6 11.35.35 18.6.05 23.55q-.3 4.95 1.9 8.9-.4-4.4.7-8.75 1.05-4.4 2.75-6.1 1-1.1 1.75-1.65Q6 18.55 6.2 22.9q.2 4.35.85 5.65.6 1.25.8 1.6-2.05-8.5 6.7-16.2Q23.3 6.2 36.9 5.1q7.6-.3 11.35 1.05 4.2-1.95 6.45-2.7m-44.35 9.4l-.05.1q-.097.048-.15.15.052-.104.1-.25h.1z"
                    id="HaiHig020__Symbol_111_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig020__Symbol_110_0_Layer0_0_FILL"
                transform="translate(76.45 39.65)"
                id="HaiHig020__col1n"
            />
            <use
                xlinkHref="#HaiHig020__Symbol_109_0_Layer0_0_FILL"
                transform="translate(76.55 45.3)"
                id="HaiHig020__col1d"
            />
            <use
                xlinkHref="#HaiHig020__Symbol_108_0_Layer0_0_FILL"
                transform="translate(77.5 40.65)"
                id="HaiHig020__col1l"
            />
            <use
                xlinkHref="#HaiHig020__Symbol_113_0_Layer0_0_FILL"
                transform="translate(77.7 54.15)"
                id="HaiHig020__col2n"
            />
            <use
                xlinkHref="#HaiHig020__Symbol_112_0_Layer0_0_FILL"
                transform="translate(91 65.25)"
                id="HaiHig020__col2d"
            />
            <use
                xlinkHref="#HaiHig020__Symbol_111_0_Layer0_0_FILL"
                transform="translate(81.5 58)"
                id="HaiHig020__col2l"
            />
        </g></g>
    )
}
export default HaiHig020
