import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M173.9 78.9q-6.7-7.1-10.25-11.1-7.3-8.25-10.25-14.15-2.25-4.45-10.35-16.85-.4-.6-.75-1.15-6.15-7.95-11.3-11.75.2 2.3.75 5.8 1.2 7.5 1.35 9.35.1.85.15 2.8-3.65-6.1-7.4-11.3-.2-.3-.35-.5-2.75-3.8-5.45-7.15l-.05-.05q-7.9-9.6-16-15.55-.1-.05-.2-.15-3-2.2-6.05-3.9-.05-.05-.1-.05Q94.2 1.3 90.7 0q.6 3.55.8 7.15v.15q.65 12.3-3.3 25 .15-.25-.65 2.5 0 .1-.05.15.8-2.75.65-2.5-.4.7-.75 2.5-.05.2-.05.4-.05.15-.05.3v.1q-.1-.1-.15-.2v-.05q-5.45-8.45-11.7-12.6-.05-.05-.1-.05-1.5-1-3.05-1.75-.7-.35-.6.5-.4 3.65-1.9 8.5-.1.2-.15.45l-.85 2.55q-.25.75-.55 1.6-.85 3.75-2.45 6.8-.85 2.35-1.9 5.15-2 6.85-9.6 13.75-7.55 6.8-18.4 11.8-.2.1-.35.2-13.9 6.9-22.05 22.15-1.15 2.1-2.5 4.35-1.65 2.55-3.3 6.05-.1.2-.15.4-.3.65-.6 1.35l-.1.2q-3.15 7.55-5.1 15.05-.35 1.65-.65 3.25-.7 4.55-.95 8.95-.6 10.25 1.1 20.1.15.9.35 1.85.15.8.35 1.6 1.4-11.65 12.55-15.95l.45-.15q1.3-.4 3.15-.95 1.3-.4 5.15-1.9 5.25-1.7 8.95-4.6 4.25-3.45 6.45-8.65l.1-.3q1.2-2.9 3.3-5.4.2-.3.45-.55.25-.25.55-.5 3.2-3 7.1-4.95.2-.15.45-.25 2-1 4.2-1.7 1.05-.35 2.15-.65 2.05-.55 4.2-.85 14.45-2.25 26.4 2.75 10.65 4.55 20.25 0 11.9-5 26.35-2.75 2.3.35 4.5.95l1.7.5q1.35.45 2.7 1.15.8.25 1.6.55.35.15.7.35 3.8 1.95 6.95 4.9l.5.5.5.6q2.05 2.5 3.25 5.35l.1.3q2.2 5.2 6.5 8.65 3.65 2.9 8.9 4.6 3.85 1.5 5.15 1.9 1.85.55 3.15.95l.45.15q11.15 4.3 12.55 15.95.2-.8.35-1.6.2-.9.35-1.8.65-9.9-.5-20.15-.2-1.9-.5-3.8-.3-2.6-.6-5.15-1.6-10-4-18.3v-.05q-.05-.05-.05-.1-.25-.75-.45-1.45-5.2-16.9-14.05-26.4M40.8 118.95v-.1q.1-.1.2-.15-.15.15-.2.25z"
                    id="HaiSpe011__Symbol_143_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.95 95.55q7.4-18.9 23.5-24.75 10.3-4.9 18.15-11.85 5-5.45 3.25-13.3-.15 8.2-8.95 14.5-6.65 4.75-15.2 8.35-13.25 6-19.45 17.05Q.3 97.1 0 105.55q-.25 5.45 2.3 9.6.1-.15.25-.25 3.25-3.8 5.65-6.2 2.8-2.85 4.4-4.8 1.55-2 3.9-4.95 2.35-3 7.15-6.25 4.8-3.25 19.15-11.9-5.4.55-11.15 4.1-1.5.9-7.15 3.7-5.7 2.85-10.7 8.8-5.05 5.95-10.8 12.7.05-7.3 2.95-14.55m71.15-69.8q.35 2.85.25 8.3v.9q-.3 30.7-15.55 50.8-2.35 2.95-6.4 5.2-3.05 1.65-4.75 2.55 2.5-.4 4.95-.55 7.85-3.1 14.05-13.3 13.85-19.75 12.1-43.7-.55-6.2-2.8-12.15-.75-2.6-2.3-4.9-.1-.15-.2-.25v.05q.05 1.5.15 2.85.1 1.3.5 4.2m4.3 72.05q13.65-22.85 8.7-45.95Q88.55 76.2 71.8 94.5q2.55.7 5.05 1.75 2.3 1 4.55 1.55M92.8 0q9.35 14.9 15.25 33.65.05.25.2.55 3.3 10.3 3.3 20.4 0 6.1-3.2 18.9-1 3.9-3.55 8.05.95-6.65.95-15 0-2.35-.75-6.7-.8-4.75-1.65-6.35-2.05-5.8-4.7-8.65 3.5 6 3.9 18.7.2 1.65.2 3.15 0 2.65-.25 5.35-.6 6.3-1.4 10.3-.8 4.05-1.75 6.85-.95 2.9-1.55 4.25-.45 1-1.9 3.35.4-.2.85-.4 3.25-3.4 5.65-5.6 1.4-1.75 3.05-4.4 3.7-5.95 5.7-11.5 3.4-9.4 3.4-19.95 0-13.55-5.6-28.4-4.45-11.45-10.7-20Q93.8.45 92.8 0m42.45 45.15q-3.55-11.35-12.2-18.85 10.9 24 6.3 43.1-.7 2.85-1.75 5.55-.15.5-.35.95-.25.55-.65 1.5-4.1 10.4-9.5 15.45 3.1.15 6.35.65.95.15 1.9.35 0-.05.05-.05 2.85-2.3 5.25-6.05 12.4-20.35 4.6-42.6m33 37.8q-.3 4.7-1.65 9.65-1.6 5.5-4.6 11.2-.2-20-13.6-41.45 6 15.25 8.75 28.95 2.35 12 2.25 22.7 2.9-3.4 4.9-6.9 6.4-11.3 3.95-24.15z"
                    id="HaiSpe011__Symbol_81_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe011__Symbol_143_0_Layer0_0_FILL"
                transform="translate(94.8 1.6)"
                id="HaiSpe011__col1n"
            />
            <use
                xlinkHref="#HaiSpe011__Symbol_81_0_Layer0_0_FILL"
                transform="translate(105.5 18.45)"
                id="HaiSpe011__col1d"
            />
        </g></g>
    )
}

export default HaiSpe011
