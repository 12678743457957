import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col1s": "#BD7B00",
    "col2d": "#C40000",
    "col2n": "#FF0000",
    "col2s": "#770707"
}

function LegSki018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2.5 2.65l.05.05h.05v-.1h-.05q-.05 0-.05.05m99.8 2.2l-.05-.05v.05h.05M135.35 0l-2.5.6q-.85.15-1.65.35-.7.15-1.4.35-.25 0-.4.05-.1.05-.2.05-1.75.4-3.5.75-.15.05-.25.05-.05 0-.1.05-.6.1-1.2.25-1.65.3-3.3.65l-1.6.3q-1.45.25-2.85.5-1.65.25-3.25.5-2.4.4-4.8.75l-.8.1-3.5.5q-1.55.15-3.1.35-2.9.35-5.8.65-1.05.05-2.1.2-1.65.1-3.3.25l-6.5.4-5.95.2q-1.15.05-2.3.05-1.85.05-3.75.05h-4.1Q65.3 7.9 63.4 7.9l-2.3-.1q-3-.1-6-.25l-6.3-.4q-.1-.05-.15 0-.8-.1-1.55-.15-4.9-.45-9.8-1l-2.2-.3q-1.7-.25-3.45-.45-.9-.15-1.75-.25-2.05-.3-4-.65-.45-.05-.8-.1-2-.35-3.9-.7-.9-.15-1.75-.3-1.35-.3-2.65-.55-1.15-.2-2.25-.45-.3-.05-.5-.1-.8-.15-1.55-.3-1.5-.35-3-.65-.1-.05-.15-.05-.45-.1-.85-.2-.3-.05-.55-.1-1.7-.4-3.35-.8-.25 0-.5.05l-.45 2.05H3.4L0 17.2q.1 0 .25.05 2.2.15 4.45.35.8.05 1.65.15 1.3.05 2.6.15 1.4.05 2.75.2.25 0 .5.05 1.75.05 3.5.2.9 0 1.75.1 1.4.05 2.75.15 1.45.1 2.9.15 3.4.2 6.7.35h.1q.2 0 .4.05 1.85.05 3.8.15h.5q.75 0 1.55.05l4.6.2h.8l2.6.1 4.5.1 8.7.2q2.9 0 5.85.05.1-.05.2 0H75q.1-.05.25 0 1-.05 2.05-.05h3.75q.65-.05 1.25-.05l3.75-.05 3.7-.1 2.1-.05q1-.05 2.05-.05h.9l.55-.05q.75 0 1.55-.05h.8q.45 0 .95-.05h.3l2.5-.1 2.9-.1q1.9-.05 3.8-.15 4.85-.2 9.7-.5 4.05-.25 8.15-.45.1-.05.25-.05 1.2-.05 2.4-.15 2.2-.15 4.45-.3h.4q.4-.05.85-.1 1.4-.1 2.85-.2l2-.2 1.1-.1L135.35 0z"
                    id="LegSki018__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.6 2.6h-.05q-.05 0-.05.05l.05.05h.05v-.1m6.75-1.45q-.45-.1-.85-.2-.3-.05-.55-.1-1.7-.4-3.35-.8-.25 0-.5.05l-.7 2.05L0 17.2q.1 0 .25.05 2.2.15 4.45.35.8.05 1.65.15l3-16.6m60.55 6.8h-2.75V20h4.1V7.95H69.9m32.35 7h.05l-.05-.05v.05m1.55 4.35q-.75 0-1.55.05-.4 0-.8.05l2.9-.1h-.55m-1.55-14.5v.05h.05l-.05-.05m24.45 13.3q-.25 0-.45.05 1.2-.05 2.4-.15v-.05q-.95.05-1.95.15m-.7.05l-3.3.2q-.85 0-1.75.1-1.35.05-2.75.15-.2 0-.35.05 4.05-.25 8.15-.45.094-.047.2-.05h-.2M135.35 0l-2.5.6q-.85.15-1.65.35-.7.15-1.4.35l3.7 16.4q.4-.05.85-.1 1.4-.1 2.85-.2l2-.2 1.1-.1L135.35 0z"
                    id="LegSki018__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.15 0l4.8 4.85L0 9.9l4.85 4.95h.1q.2 0 .4.05 1.85.05 3.8.15L4.8 10.7l5-5.1L4.95.75Q2.9.45.95.1.5.05.15 0m32 5.3l-2-2-6.3-.4q-.1-.05-.15 0v12.6l8.7.2-5.25-5.3 5-5.1M64.8 15.5V3l-6.5.4-1.95 1.9 5.05 5.1-5.3 5.3q.65-.05 1.25-.05l3.75-.05 3.7-.1m18.85-.65l4.9-4.95-5-5.05L88.2.2q-2.4.4-4.8.75L78.7 5.6l5.05 5.1-4.35 4.35q1.9-.05 3.8-.15.2-.05.35-.05h.1z"
                    id="LegSki018__Symbol_34_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M8.2 1.1q-.3-.05-.5-.1Q6.9.85 6.15.7q-1.5-.35-3-.65Q3.05 0 3 0L0 16.6q1.3.05 2.6.15 1.4.05 2.75.2.25 0 .5.05L8.2 1.1m60.45 17.75V6.75q-1.85.05-3.75.05v12.05H68.65m-7.85 0V6.8q-1.85-.05-3.75-.05v12.1h3.75m66.35-2.3l-3.7-16.4q-.25 0-.4.05-.1.05-.2.05-1.75.4-3.5.75-.15.05-.25.05-.05 0-.1.05-.55.1-1 .2l2.45 15.65q.95-.1 1.85-.15v.05q2.2-.15 4.45-.3h.4z"
                    id="LegSki018__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M5 5.85L0 10.9l4.55 4.6q3.4.2 6.7.35L6.4 10.9l4.95-5.05L6.55 1q-2-.35-3.9-.7Q1.75.15.9 0L0 .85l5 5M100.7.2q-1.45.25-2.85.5-1.65.25-3.25.5l-4.65 4.65 5 5.05-4.9 4.95h-.1q-.15 0-.35.05 4.85-.2 9.7-.5l-2.55.1 4.6-4.6-5.05-5.05 5.05-5-.65-.65z"
                    id="LegSki018__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    d="M199.85 314.95q-1.115.03-2.25.05v12.1q.1-.05.25 0l5.1-5.1-5.05-5.1 1.95-1.95m-16.15-.05l2 2-5 5.1 5.1 5.1q.1-.05.2 0v-12.15q-1.145-.003-2.3-.05m31.9-.85q-1.612.163-3.25.25v12.55l2.1-.05q1-.05 2.05-.05h.9l.55-.05q.75 0 1.55-.05h.8l4.55-4.6V322l-5-5.05 3.7-3.7q-2.903.344-5.8.6-1.06.124-2.15.2M158.75 322v.05l4.6 4.6h.8l2.6.1 4.5.1V314.2q-.786-.058-1.6-.15-4.871-.386-9.8-1l3.9 3.9-5 5.05z"
                    id="LegSki018__Layer5_0_FILL"
                />
                <g
                    id="LegSki018__Symbol_25_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M35.1 5.7q-1.7-.25-3.45-.45-.9-.15-1.75-.25l4.85 4.85-5 5.1 4.35 4.35h.5q.75 0 1.55.05l4.6.2-4.6-4.6v-.05l5-5.05L37.3 6l-2.2-.3m68.55 4.15l4.7-4.65-.8.1-3.5.5q-1.55.15-3.1.35l-3.7 3.7 5 5.05.05.05h-.05l-4.55 4.6q.45 0 .95-.05h.3l2.5-.1q.4-.05.8-.05.8-.05 1.55-.05h.55l4.35-4.35-5.05-5.1z" />
                    <path d="M104.35 19.3h-.55q-.75 0-1.55.05-.4 0-.8.05l2.9-.1zM102.3 14.95l-.05-.05v.05h.05m-.05-10.1h.05l-.05-.05v.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#LegSki018__Symbol_84_0_Layer0_0_FILL"
                transform="translate(122.6 307.1)"
                id="LegSki018__col1n"
            />
            <use
                xlinkHref="#LegSki018__Symbol_47_0_Layer0_0_FILL"
                transform="translate(122.6 307.1)"
                id="LegSki018__col1d"
            />
            <use
                xlinkHref="#LegSki018__Symbol_34_0_Layer0_0_FILL"
                transform="translate(147.55 311.35)"
                id="LegSki018__col1s"
            />
            <use
                xlinkHref="#LegSki018__Symbol_25_0_Layer0_0_FILL"
                transform="translate(122.6 307.1)"
                id="LegSki018__col2n"
            />
            <use
                xlinkHref="#LegSki018__Symbol_10_0_Layer0_0_FILL"
                transform="translate(128.95 308.25)"
                id="LegSki018__col2d"
            />
            <use
                xlinkHref="#LegSki018__Symbol_6_0_Layer0_0_FILL"
                transform="translate(141.15 310.35)"
                id="LegSki018__col2s"
            />
            <use xlinkHref="#LegSki018__Layer5_0_FILL" />
        </g></g>
    )
}

export default LegSki018
