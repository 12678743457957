import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacEye020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.65 3.65q6.25-1.55 19 32.4Q33 2.1 28.4 1.45 24.6.05 21.15 0l-8 1.7q-2.35 1.1-4.5 3Q2.9 10.25 0 44.45 10.3 4.8 15.85 4.7l4.8-1.05m66.4 1.05q5.55.1 15.85 39.75-2.9-34.2-8.65-39.75-2.15-1.9-4.5-3l-8-1.7Q78.3.05 74.5 1.45q-4.6.65-11.25 34.6Q76 2.1 82.25 3.65l4.8 1.05z"
                    id="FacEye020__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacEye020__col1n" transform="translate(141.15 142.3)">
                <use
                    xlinkHref="#FacEye020__Symbol_21_0_Layer0_0_FILL"
                    id="FacEye020__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacEye020
