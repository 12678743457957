import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400"
}

function ObjFoo014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <linearGradient
                    id="ObjFoo014__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={41.288}
                    y1={2.975}
                    x2={14.413}
                    y2={49.425}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#D23900" />
                    <stop offset="100%" stopColor="#DE5500" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ObjFoo014__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={56.3}
                    y1={10.812}
                    x2={29.3}
                    y2={57.487}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#D23900" />
                    <stop offset="100%" stopColor="#DE5500" stopOpacity={0} />
                </linearGradient>
                <path
                    fill={colors.col1n}
                    d="M62.8 13.25Q55.3 2.45 41 .15h-.15q-1.5-.3-2.95-.05-3.5.6-6 3.65L1.9 46.8q-.4.55-.7 1.05v.1l-.05.05v.05q-.15.3-.25.55-.3.6-.5 1.15l-.1.2q-.95 2.9 1.15 5.1l30.85 18.4q3.6 1.7 7-2.65h.05v-.05l.05-.05q.2-.3.35-.55.1-.25.2-.55L64.2 22.35l.1-.2q1.55-3.5-.45-7.15-.45-.95-1.05-1.75z"
                    id="ObjFoo014__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill="#F0ECCB"
                    d="M132.1 281.8h-.05l.9-1.5-6.55-3.95-.85 1.5h-.05l-5.8 9.8q1.673.989 3 2.55 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4 1.841-1.841 4.25-2.2l1.3-2.2z"
                    id="ObjFoo014__Layer7_0_FILL"
                />
                <g
                    id="ObjFoo014__Symbol_38_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M1.05 44.95l.05-.1q.05-.15.1-.25v-.05q.1-.2.1-.3Q-1.8 50 2.45 52.5q.1.1.25.1l17.55 10.6.05.05 6.65 4-.2-.15 4.6 2.75q.1.05.25.1 4.25 2.65 7.85-2.95h-.05q-.3.5-.65.75-1.85 1.3-4.85-.6-.15-.1-.25-.15L4.1 49.25q-.1-.05-.15-.05l-.1-.1q-.9-.5-1.55-1-1.4-1.4-1.25-2.85l.05-.05-.05-.05q.05-.15 0-.2zM47.2 6.05q.1-1-.45-2Q46.1 3 44.7 1.95q0-.05-.1-.1h-.05l-1.35-.8-.05-.05q-.1-.05-.15-.1-.05-.05-.15-.1Q39.7-.65 37.9.5q-.1.1-.25.15L9.4 44.5q-1.35 2.45 2.2 4.7l3.8 2.25q3.6 2.1 5.1-.25l26-43.55h-.05l.7-1.3q.05-.1.05-.3zM51.45 8.65q-.05.1-.15.2l-.8 1.2h-.05L24.2 53.4q-1.35 2.45 2.2 4.7l3.8 2.25q3.65 2.05 5.15-.25L60.7 14.5q.05-.1.05-.25.25-2.1-2.65-4.2 0-.05-.05-.1-.05 0-.15-.05 0-.05-.05-.05L56.35 9h.05q-.05-.05-.2-.1-1.45-.7-2.6-.8-1.2 0-2.15.55z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo014__Symbol_51_0_Layer0_0_FILL"
                transform="translate(111.95 214.8)"
                id="ObjFoo014__col1n"
            />
            <use
                xlinkHref="#ObjFoo014__Symbol_38_0_Layer0_0_FILL"
                transform="translate(111.8 218.6)"
                id="ObjFoo014__col1d"
            />
            <use xlinkHref="#ObjFoo014__Layer7_0_FILL" />
        </g></g>
    )
}

export default ObjFoo014
