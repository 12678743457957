import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M42.5 27.05q.45-1.1.6-1.25 1.3-1.5 3.45-3.2 1.05-.85 1.85-1.5 1.15-.95 1.75-1.5 1.7-2.75 0-5.15-2.8-2.4-8.2.2-.4.15-.85.25-.9.3-1.4.4-.3.15-.65.15-1.2 0-1.45-1.15V13q0-1.2.5-5.2v-.2Q38.5.35 34.8.2q-.5-.15-1.15-.2-3.1.1-4.5 3.15Q28.25-.1 25 .05q-3.25-.5-4.95 3.35-.6 1.35-.6 3.65l-.05 3.9v1.65q.35 3.3-2.25.6-8-6.05-11.4-4.75-2.5.85-2.5 4.05l.05.05q-.05.15-.05.35 0 .05.5 2.05.35.75.85 1.2-.95-.05-1.55.2-4.15 2.2-2.65 5.7.5 1.25 2.25 2.3.25.15.5.35 1.35.9 2.15 1.2 6.9 4.8 12 9.7.1.1.55.45.5.4 1.05.8 5.05 3.65 11.2 2.6 2.85-.5 5.2-1.85.05-.05.15-.05.05-.1.15-.1.1-.1.2-.15.3-.25.65-.45.35-.3.75-.55.05-.1.1-.1l.1-.1q1.35-1.2 2.45-2.75 2.1-3 2.65-6.3M183 15.15q-.15-.15-.25-.3l-.1-.1q-.25-.25-.45-.5-.15-.1-.25-.2V14l-.05-.05q-.2-.15-.35-.25-.05-.1-.1-.15-4.2-3.7-9.9-3.65-5.65-.05-9.7 3.55-.2-.05-.35-.1-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05h-.05q-.25.05-.45.1-2.2.45-3.85 2.1-2.3 2.25-2.3 5.45t2.3 5.45q.95 1 2.15 1.55l.3.1q.85 4.25 4.1 7.5 1.35 1.35 2.9 2.3.05 0 .1.05t.15.1q.35.2.75.4.35.2.75.35l.2.1h.05q.05.05.15.1.1 0 .2.05 2.5.95 5.4.95 6.25 0 10.65-4.4t4.45-10.6q-.05-5.6-3.65-9.75z"
                    id="BodHan015__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.55 7.75q-.05-3.2-2.3-5.45-1.5-1.55-3.4-2H9.8Q9.6.25 9.45.2q-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05H6.6q-.25.05-.45.1-2.2.45-3.85 2.1Q0 4.55 0 7.75t2.3 5.45q.95 1 2.15 1.55l.3.1q1 .4 2.25.55l.75.05h.1l1.5-.15q2.2-.4 3.9-2.1 2.25-2.25 2.3-5.45M12.5 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-1.45 1.45-3.35 1.85l-1.3.15h-.1L7 14.4q-1.1-.1-2-.55-1.05-.5-1.95-1.4-1.95-1.95-1.9-4.7Q1.1 4.95 3.05 3q1.65-1.6 3.8-1.85h.05q.4-.1.85-.1H8.2q.5.05 1 .15 1.85.35 3.3 1.8z"
                    id="BodHan015__Symbol_37_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan015__Symbol_29_0_Layer0_0_FILL"
                transform="translate(93.35 270.7)"
                id="BodHan015__col1n"
            />
            <use
                xlinkHref="#BodHan015__Symbol_37_0_Layer0_0_FILL"
                transform="translate(245.4 283.85)"
                id="BodHan015__col1d"
            />
        </g></g>
    )
}

export default BodHan015
