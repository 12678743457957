import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400"
}

function LegPan005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.15 7.15V.05l.05-.05h-.05Q69.3 12.5 7.45 0q0 .05.05.1v7.05h-.05q0 3.05-.95 5.6-.9 2.6-3.05 5.5-1.9 2.55-3.45 3.8 1.15-.5 2.45-.95 1.95-.65 4.15-1.2.7-.2 1.4-.35.1-.05.2-.05v-.05h.2q.15-.05.3-.05l.45-.1q31.95-4.9 61.15 3.05 28.45-7.75 59.6-3.25 1.25.15 2.5.35.1 0 .2.05v.1q.35.05.7.15 3.35.65 4.85 1.35.05 0 .15.05 1.5.7 1.65.75.15 0 .2.05l.15.05q-1.45-1.5-3.15-3.75-2.15-2.9-3.05-5.5-.95-2.55-.95-5.6z"
                    id="LegPan005__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan005__Symbol_5_0_Layer0_0_FILL"
                transform="translate(122.2 310.25)"
                id="LegPan005__col1n"
            />
        </g></g>
    )
}

export default LegPan005
