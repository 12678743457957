import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacEye009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M105.35 24.4q-1.2-4.7-3.8-8.85-1.7-2.75-4.05-5.3-.15-.2-.3-.35Q88.85.9 77.85.1 66.8-.75 59.55 7q-5.35 5.75-6.3 13.7-1-7.95-6.35-13.7Q39.65-.75 28.65.1 17.6.9 9.25 9.9q-.3.3-.55.65l-.3.3q-5 5.65-7 12.4Q.35 26.8.1 30.65q-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9 3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.2-3.2-1-6.2z"
                    id="FacEye009__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    d="M209.95 163.75q-.07-.043-.15-.1-.618-.226-1.35-.2-1.45-.05-2.45.9-.222.222-.4.45-.508.72-.6 1.55v.35q0 .401.05.75.049.107.05.2.034-.027.05-.05.016-.047 0-.1l3.85 1.65 2.7-2.8q.048.067.1.1-.004-.038 0-.1-.13-1.13-1-2-.388-.388-.85-.6m-30.15 2.95v-.15q-.06-1.26-1-2.2-1-.95-2.4-.9-1.45-.05-2.4.9-.954.954-1 2.2V167l3.65 2.05 3-1.45q.15-.422.15-.9z"
                    id="FacEye009__Layer6_0_FILL"
                />
                <path
                    fill="#F90"
                    d="M208.2 164.45h-.05q-.35.05-.35.35t.25.5q.2.25.2.5 0 .246-.2.55-.192.383-.65.85v.05h-.05l.05-.05.05-.5q0-.178-.05-.35-.024-.34-.2-.55-.2-.25-.8-.4h-.4q.05.1.2.2.1.1.1.35 0 .225-.2.4-.057.143-.2.25-.53.337-.75 1-.016.023-.05.05.258.78.85 1.35l.05.05q1 1 2.45 1.05 1.4-.05 2.35-1.05.213-.202.35-.45.65-.798.65-1.9v-.25q-.052-.033-.1-.1-.55-.75-.75-.95h-.4v.05q.25 0 .25.6 0 .161-.05.3-.04.475-.4.55h-.1q-.09 0-.2-.1-.068-.08-.15-.3l-.05-.15q-.138-.329-.3-.45-.8-.65-.8-.7 0-.267.4-.85-.46-.169-.95.1m-30.3.2q.212.424.3.65.05.171.05.25 0 .35-.15.6l-.35.4q-.115.178-.25.35-.021.057-.05.1v-.05q.048-.193 0-.4.005-.425-.3-.9-.7-1-.8-1.45l-.6 1.05q-.292.633-.3 1.3v.05q0 .434.05.65.059.095.1.15l-.05-.05q-.468-.356-.75-.8-.481-.674-.45-1.55-.05.041-.1.05-.763.702-1.1 1.5-.07.246-.15.45.037.18.05.3.11.553.35 1 .24.407.6.75.279.293.6.5.775.515 1.8.55 1.251-.045 2.15-.85.144-.094.25-.2.659-.626.85-1.45-.124-.347-.45-1.05-.033-.142-.1-.3-.5-1.25-1.2-1.65v.05z"
                    id="FacEye009__Layer6_1_FILL"
                />
                <path
                    fill="#FF0"
                    d="M206.35 167.9l-.05.05q-.25.501-.35 1.05l.05.05q1 1 2.45 1.05 1.4-.05 2.35-1.05.213-.202.35-.45-.128-.44-.25-1.2h-.15q-.45 1.1-.85 1.6-.5-1.15-1.3-1.05.2.2.2.45 0 .8-.9.8-.75 0-1.15-.4-.2-.25-.4-.9m-32.15-.6q-.13 1.113.4 2.25.775.515 1.8.55 1.251-.045 2.15-.85-.026-.316-.05-.75-.05-.5-.05-1l-.05.05q-.65 1.5-1.15 1.75v-.05q0-.6-.8-2.15-.05.15-.05 1.15-.05.95-.5.95-.4 0-1.7-1.9z"
                    id="FacEye009__Layer6_2_FILL"
                />
                <path
                    fill="red"
                    d="M206.3 165.95q0 .225-.2.4-.057.143-.2.25-.53.337-.75 1 .4-.15 1.15-.4.5-.25.5-.7v-.15q-.059-.344-.4-.6-.234-.164-.4-.35.05.1.2.2.1.1.1.35m4.4-.5q-.033-.025-.1-.05h-.05v.05q.25 0 .25.6 0 .161-.05.3-.04.475-.4.55l.35.1q.483 0 .5-.65v-.05q0-.55-.5-.85m-2.5-.6q0-.234.5-.6-.177.125-.55.2-.35.05-.35.35t.25.5q.2.25.2.5 0 .246-.2.55-.192.383-.65.85v.05l.5.05q.567 0 .8-.95.036-.043.05-.1 0-.25-.25-.7-.3-.45-.3-.7m-33.95.2q-.763.702-1.1 1.5-.07.246-.15.45.037.18.05.3.11.553.35 1 .118-1.012.3-1.75.032-.039.05-.1.369-.902.5-1.4m2-.65l-.5.85q-.292.633-.3 1.3v.05q0 .434.05.65.113.054.25.1.074-.407.15-.8.22-1.275.35-2.15m1.85 1.75l-.35.4q-.115.178-.25.35.15.251.15.3h.3q.25 0 .45-.3.107-.178.15-.35v-.15q0-.295-.35-1.1.05.171.05.25 0 .35-.15.6z"
                    id="FacEye009__Layer6_3_FILL"
                />
            </defs>
            <g id="FacEye009__col1n" transform="translate(139.3 136.2)">
                <use
                    xlinkHref="#FacEye009__Symbol_1_0_Layer0_0_FILL"
                    id="FacEye009__col1n_FL"
                />
            </g>
            <use xlinkHref="#FacEye009__Layer6_0_FILL" />
            <use xlinkHref="#FacEye009__Layer6_1_FILL" />
            <use xlinkHref="#FacEye009__Layer6_2_FILL" />
            <use xlinkHref="#FacEye009__Layer6_3_FILL" />
        </g></g>
    )
}

export default FacEye009
