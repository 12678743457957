import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#775C31",
    "col1n": "#A37831",
    "col1s": "#43341A",
    "col2n": "#411F00"
}

function ShiLon103({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.6 15.9q-.35-.75-.65-1.45-9.5-3.75-18.05-9.65-.15-.05-.25-.15L27.1 3.5q-1.35-1-2.6-1.95-.1-.1-.2-.15L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2-.45 1.7-.85 3.4-.05.1-.05.2l-.95 3.95V81h.05q-.3 2.5-.45 3.65l.05.05-1.15 10.65q16.7 2.55 31.05 1.25.3-.05.65-.05 4.3-.45 8.35-1.2l.1.05V82.9h-.1V52.3l.05 1.4q0-6.8-.5-12.15 0-.25-.05-.5-.05-.7-.15-1.35-1.35-11.25-5.9-22.45-.3-.7-.55-1.35m115.95 32.55q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-.05.05-1.8 1.35-.05.05-1.2.9q-.75.6-1.55 1.15l-1.55 1.15q-.5.35-1.05.7-8.45 5.7-17.75 9.3-.2.3-.35.65-1.15 2-2.1 4.05l-.2.4q-4.25 9.3-5.6 19.85-.1.45-.1.85-.05.2-.05.35-.05.3-.1.65-.15 1.35-.25 2.8-.25 3.9-.25 6.35.1 2.8-.05 4.7v40.05q20.25 3.9 41.9 0l-1.5-14.2h-.05L143.6 59.9h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5z"
                    id="ShiLon103__Symbol_543_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M17.4 70.35l-.6-.6-1.2 7H.75q-.05.1-.05.2L0 79.6h17.4v-9.25M8.9 7.2q.6 1.8 1.05 3.65.55 2.15.95 4.3.1.75.25 1.5.5 3.5.6 7 .1 2.95-.15 5.9-.2 2.8-1.55 6.35l.85.5q1.55-4.9 1.8-9 .2-2.8.05-5.6-.1-1.25-.2-2.45-.05-.65-.15-1.3-.5-4.3-1.7-8.65-.5-1.8-1.05-3.55v-.1Q8.9 3.65 8.1 1.5q-.1-.1-.2-.15L6.25.1Q7.3 2.55 8.2 5l.7 2.2m107.9 69.55l-1.2-7-.6.6v9.35h17.15l-.75-2.95h-14.6m8.35-74.4q.45-1.2.95-2.35l-1.8 1.35-.05.05q-.55 1.4-1.05 2.8-.3.8-.6 1.65-.55 1.75-1.05 3.55-1.2 4.35-1.7 8.65-.1.65-.15 1.3-.1 1.2-.2 2.45-.15 2.8.05 5.6.25 4.1 1.8 8.95l.9-.45q-1.4-3.55-1.6-6.35-.25-2.95-.15-5.9.1-3.5.6-7 .15-.75.25-1.5.4-2.15.95-4.3.45-1.85 1.05-3.65.65-2 1.4-3.95l.4-.9z"
                    id="ShiLon103__Symbol_504_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.45 31.75q-.2.1-.25.3L0 53.5v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1M5 0q.4 1.65.8 3.25Q9.3 16.8 13.55 22.2l9.35-.1-8.45 6.25q11.25 14.9 18.05 44.1V69.5q-3.2-21.95-15.95-41l9.35-7.25-11.75-.15q-5.35-8.7-7-17.15-.3-1.4-.45-2.75-.15-.05-.25-.15L5 0m110.8 31.65l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1m-1.65-30.6q-.5.35-1.05.7-.1.8-.25 1.6-1.65 8.7-7.15 17.75l-11.75.15 9.35 7.25q-12.4 18.55-17 43.05v4.65q.6-1.9 1.05-3.75 6.8-29.2 18.05-44.1l-8.45-6.25 9.35.1q4.4-5.55 7.95-19.7.2-.9.5-1.85l-.6.4z"
                    id="ShiLon103__Symbol_470_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.15 29.5q-.45 0-.8.15-.4.15-.7.5Q0 30.8 0 31.7t.65 1.55q.6.65 1.5.65t1.55-.65q.65-.65.65-1.55t-.65-1.55q-.15-.15-.35-.25-.55-.4-1.2-.4m0-9.4q-.9 0-1.5.65Q0 21.4 0 22.3t.65 1.55l.3.3q.5.35 1.2.35.25 0 .5-.05.55-.1 1.05-.6.65-.65.65-1.55t-.65-1.55q-.65-.65-1.55-.65m-.3-10.05q-.15 0-.25.05-.55.1-.95.55Q0 11.3 0 12.2q0 .25.05.5.1.35.3.65.1.2.3.4.4.45.95.6.1 0 .25.05h.3q.25 0 .5-.05.6-.15 1.05-.6.65-.65.65-1.55t-.65-1.55q-.5-.5-1.1-.6-.2-.05-.45-.05-.15 0-.3.05m2.5-7.85q0-.9-.65-1.55-.5-.5-1.1-.6Q2.4 0 2.15 0 2 0 1.85.05 1.7.05 1.6.1 1.05.2.65.65 0 1.3 0 2.2q0 .25.05.5.1.35.3.65.1.2.3.4.4.45.95.6.1 0 .25.05h.3q.25 0 .5-.05.6-.15 1.05-.6.65-.65.65-1.55z"
                    id="ShiLon103__Symbol_446_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon103__Symbol_543_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon103__col1n"
            />
            <use
                xlinkHref="#ShiLon103__Symbol_504_0_Layer0_0_FILL"
                transform="translate(126.4 228.65)"
                id="ShiLon103__col1d"
            />
            <use
                xlinkHref="#ShiLon103__Symbol_470_0_Layer0_0_FILL"
                transform="translate(132.2 232.2)"
                id="ShiLon103__col1s"
            />
            <use
                xlinkHref="#ShiLon103__Symbol_446_0_Layer0_0_FILL"
                transform="translate(149.75 278.5)"
                id="ShiLon103__col2n"
            />
        </g></g>
    )
}

export default ShiLon103
