import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#0000BB",
    "col1l": "#4141FF",
    "col1n": "#0000FF",
    "col1s": "#040454",
    "col2d": "#747474",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjFoo043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M37.3 63.55q-5.35-.15-10.75-.2-5.45-.1-10.95.45v21.3q.55.1 2.05.8.2.05.35.15-.05.15-.1.45v12.1q-.1.6-.25 1.2-.05.35-.15.75-.85 3.75-2.5 6.95-1.15 2.3-2.65 4.3-2.3 2.3-3.8 6.3-1.05 2.7-1.7 6.15-.1.4-.15.8v.05l-.1.6q-.15 1-.35 2.05 0 .4-.1.8-.1 1-.2 2.1 0 .35-.05.75-.1 1.25-.15 2.55v12.35q-.65 3.4-4.05 4.45-.7.25-1.55.35-.6 2.9 1.55 5.5.5.55 1.15 1.15 15.65 4.25 31.25 2.9 8-.75 16-2.9 2.35-2.05 2.8-4.25.25-1.1 0-2.25-.05-.1-.05-.15-4.75-.75-5.6-4.85v-12.3q-.05-1.3-.15-2.55 0-.4-.05-.75l-.1-1.2-.1-.9-.1-.8q-.15-1.05-.35-2.05 0-.35-.1-.65-.15-1.05-.4-2.05-.9-4.25-2.45-7.2-1.2-2.4-2.8-4-1.5-2-2.65-4.3-1.6-3.2-2.5-6.95-.05-.4-.15-.75-.15-.6-.25-1.2V86.5q-.1-.3-.15-.6.85-.3 2.35-.8V63.55M94.2 46.4q.45-13.45-.3-22.05-2.5-19-13.55-22.9-6.4-2.85-12.75 0-.8.35-1.6.85-5-2-8.75 1.3 5.35 1.7 8 2.35 1.2 2.95 3 7.65l.05.05q2.7-2.5 2.6-4.95 8.25-2.25 12.3 6.15 3.45 6.25 3.8 22.4-.15 10.2-1.25 19.6-2.8 24.7-16.1 41.45-10 10.95-11.65 25.2-.55 5.85 2.15 9.95 5.4 7.35 17.85 6.6 2.95-.05 5.7-.8 10.95-2.15 23.25-15.2 9.5-11.45 17.2-25.3 4.75-8.25 5.55-9.8 1.1-2.2 2.7-5.25-.15-5.6-5.9-3.5l-7 11.25q-8.05 14.35-18 26.45-5.8 6.2-10.35 9.55-15.25 9.15-22.6 1.9-7.3-7.25 7.55-27.3 14.85-20.1 17.15-41.8.75-6.65.95-13.85z"
                    id="ObjFoo043__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M37.55 56.9q-4.65.5-9.35.75-12.75.65-25.55-.85l-2.3-.3q0 .4-.1.8l2.4.4q12.85 1.8 25.55 1.05 4.7-.3 9.35-.95 1.7-.2 3.4-.5l-.1-.8-3.3.4m0 3.95q.65-.1 1.3-.25 1.2-.2 2.35-.45 0-.4-.05-.75-.7.15-1.4.25-1.1.2-2.2.35-4.65.65-9.35 1-12.75.8-25.55-1.15-1.3-.2-2.6-.45 0 .35-.05.75 1.35.25 2.65.5 12.85 2.4 25.55 1.4 4.7-.4 9.35-1.2m2.85-7.05q-1.4.15-2.85.3-4.65.45-9.35.65-12.7.5-25.55-.75-.9-.1-1.85-.2v.05l-.1.6q1 .15 1.95.3 12.85 1.65 25.55.95 4.7-.25 9.35-.85 1.5-.2 2.95-.4 0-.35-.1-.65M12 25.1v.65q8.6 1.25 17.2 0v-.65q-8.6.85-17.2 0m-.25 3.45q-.05.35-.15.75 9 1.25 18 0-.05-.4-.15-.75-8.85 1.2-17.7 0M31.4 6.4q-1.7.3-5.95.85-4.25.5-9.15 0t-6.6-.8v.7q10.9 2.5 21.7.05v-.8m0-3.35l-3.7.45q-6.8.75-14.2 0-2.05-.25-3.8-.45v1q1.7.2 3.7.5 7.25.9 14.2 0 2.1-.3 3.8-.5v-1m-3.8-1.4q2.1-.3 3.8-.55V.05Q20.6 2.35 9.7 0v1.1q1.7.25 3.7.55 7.25.95 14.2 0z"
                    id="ObjFoo043__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M1.7 79.2q-.75-.25-1.55-.45-.6 2.9 1.55 5.5.5.55 1.15 1.15 15.65 4.25 31.25 2.9 8-.75 16-2.9 2.35-2.05 2.8-4.25.25-1.1 0-2.25-.05-.1-.05-.15-3.55 1-7.2 1.8-5.75.85-11.55 1.2-13.2.75-26.45-1.15l-1.85-.4q-2.05-.45-4.1-1m4.9-25.85q-.15 1-.35 2.05l2.3.3q12.8 1.5 25.55.85 4.7-.25 9.35-.75l3.3-.4q-.15-1.05-.35-2.05-1.45.2-2.95.4-4.65.6-9.35.85-12.7.7-25.55-.95-.95-.15-1.95-.3m-.65 4.95q1.3.25 2.6.45 12.8 1.95 25.55 1.15 4.7-.35 9.35-1 1.1-.15 2.2-.35.7-.1 1.4-.25l-.1-1.2-.1-.9q-1.7.3-3.4.5-4.65.65-9.35.95-12.7.75-25.55-1.05l-2.4-.4q-.1 1-.2 2.1M17.9 26.25q-.1.6-.25 1.2 8.85 1.2 17.7 0-.15-.6-.25-1.2v-1.6q-8.6 1.25-17.2 0v1.6m19.4-23.3q-1.7.2-3.8.5-6.95.9-14.2 0-2-.3-3.7-.5v2.4q1.7.3 6.6.8 4.9.5 9.15 0 4.25-.55 5.95-.85V2.95m0-2.95q-1.7.25-3.8.55-6.95.95-14.2 0-2-.3-3.7-.55v1.95q1.75.2 3.8.45 7.4.75 14.2 0l3.7-.45V0z"
                    id="ObjFoo043__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M3.95 82.65v1q8.6 1.75 17.2 0v-1q-8.3 1.65-17.2 0m18.7-26.55q-.85.15-1.75.25-4.3.6-8.45.7-5 .05-9.7-.6l-.5-.1Q1.15 56.2 0 56v4.65l1.5.3h.15q5.4.95 10.8.9 5.45-.1 10.9-1.15v-.05l1.1-.4V55.8l-1.8.3M54.8 6.75q-.8-2.5-3.2-4.45-2.4-2-4.95-2.25-2.1-.3-3.35.7-.15.1-.35.3-.1.1-.15.2-1.25 1.5-.5 4v.05q.7-.45 1.55-.7.45-.2 1.1.05.15 0 .3.1l2.25-1.5q1.55.7 2.45 1.75.8.85 1.1 2.05l-1.9 2.1v.1q.05.3.05.65v.15q-.4.8-.85 1.4 1.05.4 2.1.55 1.5.15 2.5-.25.35-.15.65-.35.4-.25.7-.65 1.25-1.55.5-4m61.95 67.05q-1.2-.55-2.45-.9-.15-.05-.35-.1h-.05q-.1-.05-.3-.05h-.25q-.55-.05-1.15-.05-3.25.15-4.45 2.3-1.2 2.1.3 5 .6 1.1 1.45 2.1l3.05-4.75q4.3.55 5.9 3.5-1.6 3.05-2.7 5.25 1.9.55 3.7.5 3.25-.15 4.45-2.25 1.25-2.15-.25-5-.4-.85-1.05-1.6h-.05q-2.55-2.55-5.8-3.95z"
                    id="ObjFoo043__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M20.85.1V0h-.05v.1h.05m34.95 48l.1.15v-.3l-.1.15M41.85 22.25q1.55-4.55 7.3-7.35h-19.3l-.15-.2h.05l-1.15-1.85h-.05l-1.35-1.6h-5.25l-1.2 1.6h-.15l-1.2 1.85h.05l-.15.2H0q4.25 1.95 7.5 7.35h8.6l3.45 7.65h-.1q-4 .75-4 6.65.35 2.8 3.5 3.5.05 0 .15.05l2.5 8.75-3.8 6.05q-1.45 4.1 2.65 6.6.05 0 .15.05.35 1.6-1.4 3.25l-.1.1q-.05 0-.1.05-1.5.5-2.8 1.4-.1.1-.25.15l-.1.1-.1.1-3.3-4.45-2.2 1.45 3.35 4.8q0 .1.05.2l-.1.1-.4.6q-.3.5-.55 1.1-.4 1.1-.4 2.3 0 3.7 3.75 6.3l1.3.8q.75.35 1.5.65.3.15.65.25.2.1.45.15H17.5v3.75h-.95v3.55l-.7-.1H14.8v3q10 1.4 19.65-.25v-2.65h-1.6v-3.5h-1.2v-3.8H29.3q.2-.05.4-.15.35-.1.7-.25.75-.3 1.5-.65.65-.4 1.25-.8 3.8-2.6 3.75-6.3.05-1.2-.4-2.3-.3-.9-.9-1.6v-.05q0-.05.05-.05l1.95-2.05.95.6 3.55-3.55.15.15h-.05q.05.05.1.05 1.05.55 2.1.85 2.25.55 3.45-.65.65-.65.8-1.55 0-.05.05-.05.85-.2 1.5-.8 1.25-1.3.7-3.6-.25-1.15-.95-2.2-.55-1.1-1.6-2.1l-.05-.05q-1.45-1.5-3.1-2.2-.6-.25-1.25-.4-2.25-.55-3.55.7-.7.7-.8 1.8h-.15q-.7.2-1.25.7-1.2 1.2-.65 3.5.25 1.05.8 2.05l.1.2.05-.05.1.1-3.55 3.55.9 1.15-1.9 1.85q-.2 0-.3.1-.05 0-.1.05l-.05-.05.05-.05q-.25-.2-.5-.35-1.5-1.05-3.25-1.65l.05.05q-.15-.1-.25-.15-1.5-1.65-1-3.25 4.1-2.5 2.65-6.6l-3.8-6.05L30 40.1q.1 0 .2-.05 3.15-.7 3.5-3.5 0-5.9-4-6.65h-.1l3.5-7.65h8.75M143.4 93.8l3-5.9-2.25-1.05 4.5-9.35-2.25-1.05-4.55 9.25-2.3-1.1-2.9 5.9 6.75 3.3z"
                    id="ObjFoo043__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M19.25 79.05q-5.05-.1-10.45.2l-.05.9.5.1q4.7.65 9.7.6 4.15-.1 8.45-.7.9-.1 1.75-.25v-.75q-4.9 0-9.9-.1M10.5 76.8q4.2 1 8.5.95 4.25-.05 8.4-.95v-.85q-4.15.1-8.35.05-4.25-.05-8.55-.05v.85M9.2 54.1l-.4-.55q-1.65-.1-2.4 1.9l.35.5q1.9-.35 2.45-1.85m-3.85-.15Q6.9 53.7 7.7 52.1l-.45-.6q-1.35.45-2.3 1.85l.4.6M26.3 73.4v-.8q-3.6-.05-7.25-.05-3.7 0-7.4.05v.8q3.65.95 7.35.9 3.65-.05 7.3-.9m3.25-19.05q.25 1.55 1.95 1.95l.6-.6q-.2-1.55-1.95-1.95l-.6.6M15.35 35.7l.2.65q3.5 1 6.85 0l.15-.65q-3.4-1.15-7.2 0M23 1.8L21.85 0H16.1L15 1.8h8m2.05 15l.2-.5q-6-1.8-12.6 0l.25.5q6.25.95 12.15 0m1.15-2.45l.25-.5q-7.3-1-14.95 0l.2.5q7.05 1.1 14.5 0m2.45-3.05v-.05q-9.45-1.05-19.25 0v.05h.9l.25.55q8.7.9 16.85 0l.25-.55h1m8.8-1.9q.25-.35.5-.65Q19 7.5 0 8.75q.25.3.45.65 18.5.85 37 0m8.05 37.7q-.25-1.2-.95-2.3-.6-1.1-1.65-2.15l-.05-.05q-1.5-1.55-3.2-2.25-.6-.25-1.25-.4-2.35-.55-3.65.7-.8.8-.9 2.05.95-.3 2.25.05 1.15.3 2.25.95 1.05.6 2 1.6l.05.05q2 2 2.55 4.3.25 1.1.1 1.95 1.05-.15 1.75-.85 1.3-1.3.7-3.65m94.4 32.1q-3.25-2.65-7.25-3.5l-.5.95q2.6 2.9 7.15 3.7l.6-1.15m-1.2-3.55l.8-1.65q-.55-1.5-2.45-1.2l-.85 1.65q.55 1.8 2.5 1.2z"
                    id="ObjFoo043__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M12.85 51.8l2.4-1.9-1.1-1.45-2.35 1.85 1.05 1.5m2 1.7q.8-.85 1.85-1.6l-1.05-1.45-2.45 1.85 1.2 1.8q.25-.3.45-.6M35.6 72.95l-1.75.2q-4.15.5-8.45.55-4.25-.05-8.45-.55l-1.7-.2v2.65q5.1.8 10.15.8 5.1 0 10.2-.9v-2.55m-1.75-3.3l-1.1.1q-3.65.45-7.35.5-3.65-.05-7.3-.5-.55-.05-1.15-.1v2.65q4.25.6 8.45.65 4.25-.05 8.45-.65v-2.65m-1.1-.7V66.1H18.1v2.85q3.65.4 7.3.45 3.7-.05 7.35-.45m1.7-16.75q1.35.85 1.9 2l.1-.05 1.5-1.5L36 50.7l-1.55 1.5m-8.95-5.05q-2.05-.05-4.2-.35l-2.95 6.7q7.2 1.95 14.1 0l-1.7-3.35-1.2-3.35q-1.95.35-4.05.35m-3.7-15.1H29l2.05-6.7q-2.15.5-5.65.5-3.45 0-5.6-.5l2 6.7m7.05.65H22l.45 1.45h5.95l.45-1.45m-15.5 14.65l-2.4 1.7.45.65 2.3-1.85-.35-.5M20.3 15.2q.6-.15 5.1-.2 4.55.05 5.15.2l.95-2.05H19.35l.95 2.05m12.6-5l.95-2H17l.95 2H32.9m-1.2 2.45l.95-1.95h-14.5l.95 1.95h12.6m13.95 34q-.35-1.45-1.4-2.4-.9-.85-2.35-1.35l-6.25 6.2 3.75 3.8 6.25-6.25m-2.9-39q.55-1.05 1.15-1.9h-37q.65.85 1.2 1.9h7.75V7.6H35.1v.05h7.65M44.5 5q2.55-3.3 6.3-5H0q3.8 1.7 6.35 5l.1.1H44.4l.1-.1M139 76.55l1.2.6 4.05 1.95-.55-.15.3.15.35.05q0 .05-.05.1l.15.1 1.3-2.65-7.15-3.7-1.3 2.65 1.7.9m1.3-6.95l-1.2 2.45 7.25 3.5 1.15-2.35-7.2-3.6m3.2-.45l2.45 1.2 3.8-7.7-2.45-1.2-3.8 7.7z"
                    id="ObjFoo043__Symbol_3_0_Layer0_0_FILL"
                />
                <path
                    fill="#9A3200"
                    d="M60.95 203.9h-.2l-.15-.15-4.8 5.8q1.7.7 3.2 2.25l.05.05q1.05 1.05 1.65 2.15l5.55-4.5-.25-.25q.05-.099.05-.25 0-.15-.4-1.3-.5-1.25-.75-1.6-.7-.8-1.7-1.4-1.25-.8-2.25-.8m87.45 61.7q-.05-.45-.1-1.2-3.4 0-6.5-2.65-.15-.15-.35-.3-.45-.4-.8-.85-.3-.45-.4-.5l-.1-.2q-3.7 1.2-3.9 5.85.2 0 .3.05h.05q.2.05.35.1 1.25.35 2.45.9 3.25 1.4 5.8 3.95h.05q1.6-.85 2.5-2.55.65-1.3.65-2.6m5.6-13.5q.05-.05.05-.1l-.35-.05-.3-.15.55.15-4.05-1.95-1.2-.6-1.7-.9q-3.55 2.75-4.1 4.15-.55 1.4-.65 2.5 0 .05-.05.15v.5q0 .55.05.8.15.4.2.6.3.75.75 1.4.4.5.85.9.5.4 1.1.75l.1.05q2.2 1.05 4.5.35.55-.2 1-.45l.05-.05q1.5-.8 2.35-2.4.9-1.75 1-5.55l-.15-.1m-86.1-54.35q-.15-.1-.3-.1-.65-.25-1.1-.05-.85.25-1.55.7-2.15 1.4-1.8 4.55v.25l.2.15-.15.15q-.15.15-.15.3 0 .45 1.1 1.45 1.2 1.05 2 1.05h.15l.05.05.05-.1q.057-.018.05-.05 2.86.686 4.55-1.65.45-.6.85-1.4v-.15q0-.35-.05-.65v-.1q-.014-.132-.05-.25.088-.14.15-.3.25-.65.25-1.25 0-.95-.4-1.45-.5-.6-1.9-1.25H68.3q-.237 0-.35.1h-.05m-36.5-39.5l.6.1-2.65 10.85h11.5l-2.65-10.85H38q-.067-.341-.4-.6-.4-.25-1.55-.65h-1.7q-.4-.15-1.3.35-.767.469-.9.8-.399-.022-.75-.1v.1m4.25 0l.2.1h-.2q.018-.063 0-.1m9.3-20.9q0 .07-.05.1l-.2-.2h-5.25l-.05-.05h-7.65l-.15-.05h-6q.05-.063.05-.1-.4.15-.85.3-2.2 2.4-.35 6.65 1.9 4.2 6.95 5.4h.1q0 .4.45.45 1.15.25 1.65.4h3.45q1.271-.3 1.6-.85h.15q5.1-1.2 6.95-5.4 1.9-4.25-.35-6.65-.2-.1-.45-.2v.2m-7.45 1.7h.5q-.52.03-1.1.05h-.15q.379-.028.75-.05z"
                    id="ObjFoo043__Layer7_0_FILL"
                />
                <path
                    fill="#621F00"
                    d="M60.6 203.75l5.65 5.75.2-3.1-.1-.15-3-3-2.75.5m81.85 53.45q-.05-.2-.2-.6.05 1.65-1.1 2.85l-.8.4q-.05 0-.15.05 0 .05-.05 0l.1.2q.1.05.4.5.35.45.8.85.2.15.35.3 3.1 2.65 6.5 2.65-.05-.8.4-1.85.6-1.5 2.05-2.35-.45.25-1 .45-2.3.7-4.5-.35l-.1-.05q-.6-.35-1.1-.75-.45-.4-.85-.9-.45-.65-.75-1.4m-72.3-60.95l-2.25 1.5q2 .65 3 2 .75 1 .9 2.4l1.9-2.1q-.3-1.2-1.1-2.05-.9-1.05-2.45-1.75m-38.8-38v-.1h.05q1.35.3 3.7.2h3.1q4.35-.45 4-5.45-.05-3.15-3.4-3.5h-7.4q-3.3.35-3.35 3.5-.3 4.6 3.3 5.35m13.5-21.15q-.4-.6-2.75-1.05-.9-.15-2-.25-2.15-.2-4.85-.2-2.35-.05-4.3.15-1.35.1-2.5.3-2.3.45-2.75 1.05l-.05.1q0 .05-.05.1v.1q.15.7 2.85 1.2 1.25.2 2.65.35l1.5.1q1.15.05 2.4.05h1.9q3-.1 5.2-.5 2.85-.5 2.85-1.25v-.1q-.003-.102-.1-.15z"
                    id="ObjFoo043__Layer7_0_MEMBER_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo043__Symbol_105_0_Layer0_0_FILL"
                transform="translate(8.7 190.15)"
                id="ObjFoo043__col1n"
            />
            <use
                xlinkHref="#ObjFoo043__Symbol_46_0_Layer0_0_FILL"
                transform="translate(14.6 261.4)"
                id="ObjFoo043__col1d"
            />
            <use
                xlinkHref="#ObjFoo043__Symbol_32_0_Layer0_0_FILL"
                transform="translate(8.7 262.5)"
                id="ObjFoo043__col1l"
            />
            <use
                xlinkHref="#ObjFoo043__Symbol_20_0_Layer0_0_FILL"
                transform="translate(22.65 193)"
                id="ObjFoo043__col1s"
            />
            <use
                xlinkHref="#ObjFoo043__Symbol_17_0_Layer0_0_FILL"
                transform="translate(10.55 158.15)"
                id="ObjFoo043__col2n"
            />
            <use
                xlinkHref="#ObjFoo043__Symbol_6_0_Layer0_0_FILL"
                transform="translate(16.15 169.2)"
                id="ObjFoo043__col2d"
            />
            <use
                xlinkHref="#ObjFoo043__Symbol_3_0_Layer0_0_FILL"
                transform="translate(9.7 172.85)"
                id="ObjFoo043__col2l"
            />
            <use xlinkHref="#ObjFoo043__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo043__Layer7_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjFoo043
