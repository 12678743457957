import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function ShiLon001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.95q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.25-.7-.5-1.35-.45-1.1-.85-2.2-.3-.6-.5-1.2-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-.3-.4-.55-.8-1.4-2.1-2.9-4.1-.2-.25-.35-.5-.25-.35-.5-.65l-1.75 1.45q-1.1.85-2.25 1.7-.3.2-.55.4l-1.55 1.15q-8.7 6-18.35 9.85-2.95 1.15-6 2.1-8.95 2.8-18.6 3.8-.9.1-1.85.2-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-1.4-.1-2.8-.25-2.45-.25-4.85-.6-.15-.05-.25-.05h-.15q-8-1.3-15.45-3.8-2.1-.75-4.2-1.55-9.55-3.8-18.15-9.75L27.1 3.55q-1.45-1.05-2.8-2.1L22.45.05q-.1.05-.15.15-.05.05-.1.15-.1.1-.15.2-.2.2-.35.45-.2.25-.35.5-.55.75-1.05 1.5-1.15 1.65-2.25 3.4-.75 1.15-1.45 2.35-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 12.85 2.9 25.7 4.65 17.9 2.35 35.7 2.35 17.8 0 35.5-2.3 12.9-1.75 25.8-4.7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05L143 57.5q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.25q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon001__Symbol_275_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.45.1q-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1M115.4.6v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35z"
                    id="ShiLon001__Symbol_221_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon001__Symbol_275_0_Layer0_0_FILL"
                transform="translate(110 228.55)"
                id="ShiLon001__col1n"
            />
            <use
                xlinkHref="#ShiLon001__Symbol_221_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiLon001__col1s"
            />
        </g></g>
    )
}

export default ShiLon001
