import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo046({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjFoo046__Layer16_0_FILL">
                    <path
                        fill="#00350A"
                        d="M78.65 284.15q-.079-.654-.2-1.45-1.04-8.885-.35-11.4-.23.73-.45 1.45-1.088 3.938-1.3 7.45-.293 2.017-.3 3.95-.05 4.324 1.3 8.2 5.4 13.8 18.6 16.15 7.454 1.424 14.6-1.6l.25-.1q.263-.093.5-.2-1.2-1.495-1.2-3.3 0-2.774 2.75-4.75-11.132 3.268-20.8-.7l-.05-.05q-3.7-1.65-6.85-4.45-4.61-4.095-6.5-9.2m39.85 12.2q1.07-.15 2.2-.15 4.4 0 7.5 2.1.616.407 1.1.85.105-.025.2-.05 1.05-.25 2.15-.45 0-.05.05-.05 3.4-1 6.2-2.25 6.55-3 9.7-7.45.85-1.5 1.2-2.15-.327.354-.7.7.472-.776.7-1.2-.6.65-1.25 1.3-3.75 3.5-8.8 4.05h.1q-.35.05-.55.05-2.7.2-5.35.65-4.45.7-8.65 2-2.952.844-5.8 2.05z"
                    />
                    <path
                        fill="#C5F73D"
                        d="M116.65 257.25h.05q-6.15-3.05-12.5-3.1-1.3 0-2.6.15-1.85.1-3.65.55-1 .2-1.95.5-2.45.7-5 1.9h.05q-2.8 1.45-5.2 3.65-5.8 5.25-7.05 12.25-.35 1.9-.35 3.95 0 9.5 7.4 16.15 3 2.75 6.55 4.4 9.95 4.077 21.45.25 1.15-.673 2.45-1.1l1.15-.45q1.7-.8 3.45-1.35 2.85-1.1 5.8-1.85.9-.25 1.9-.5 4.95-1.15 10.15-1.65H139.2q4.5-.5 7.95-3.75 3.95-3.8 3.95-9.2 0-.65-.05-1.2-.45-4.65-3.9-8h-.05q-3.1-3.05-7.25-3.7H139.55q-12.15-2.25-22.9-7.9m-13.85 9.65q.8-.15 1.65-.15h.5q2.75.15 4.95.95 1.85.75 3.45 2.05.6.45 1.1 1.05.7.6 1.15 1.45 1.3 1.95 1.4 4.25v.4q0 3.45-2.4 6.1-.55.55-1.25 1.1-.85.65-1.75 1.2-3.1 1.8-7.15 1.8-5.25 0-8.9-3-3.7-2.95-3.7-7.2t3.7-7.15q3.05-2.45 7.25-2.85z"
                    />
                    <path
                        fill="#5B1901"
                        d="M109.9 268.05q-2.2-.8-4.85-.9h-.5q-.85 0-1.65.1-4.1.4-7.1 2.75-3.6 2.8-3.6 6.85 0 4.1 3.6 6.9 3.6 2.9 8.75 2.9 3.95 0 7-1.75.9-.5 1.7-1.15.7-.5 1.25-1.05 2.35-2.55 2.35-5.85v-.35q-.1-2.25-1.4-4.1-.4-.8-1.1-1.4-.5-.55-1.1-1-1.55-1.25-3.35-1.95z"
                    />
                </g>
                <g id="ObjFoo046__Layer16_1_FILL">
                    <path
                        fill="#007417"
                        d="M117.25 256.45q-6.85-3.3-13.95-3.05-1.25 0-2.45.15-1.8.2-3.55.6-.95.25-1.9.55-2.4.65-4.75 1.75h-.05q-2.9 1.5-5.45 3.75-5.3 4.75-7.05 10.85v.25q-.75 2.75-.75 5.75 0 1.4.15 2.8.95 8.05 7.65 14 3.15 2.8 6.85 4.45l.05.05q9.267 3.803 19.85.95.576-.521 1.3-1 2.88-1.95 6.85-2.1 2.106-.787 4.25-1.4 4.2-1.3 8.65-2 2.65-.45 5.35-.65.2 0 .55-.05h-.1q5.05-.55 8.8-4.05.65-.65 1.25-1.3 1.2-2.55 1.9-3.9 1.95-5.8 0-10.85-1.15-2.2-3.15-4.1-3.5-3.3-8.05-4l.05.05-.3-.05q-11.7-2.1-22-7.45m-.7 1.55h-.05q10.55 5.45 22.45 7.6H139.25q4.05.6 7.1 3.55h.05q3.4 3.2 3.85 7.65.05.55.05 1.15 0 5.2-3.9 8.85-3.35 3.1-7.8 3.6H138.15q-5.1.45-9.95 1.55-.95.25-1.85.5-2.9.7-5.7 1.75-1.7.55-3.35 1.3-1.4.55-2.85 1.25-11.7 4.05-21.75 0-3.45-1.55-6.4-4.2-7.25-6.4-7.25-15.5 0-1.95.35-3.8 1.2-6.7 6.9-11.75 2.35-2.1 5.1-3.5h-.05q2.5-1.15 4.9-1.8.95-.3 1.9-.5 1.8-.4 3.6-.5 1.25-.15 2.55-.15 6.2.05 12.25 2.95z"
                    />
                    <path
                        fill="#EBFF80"
                        d="M105.1 261.25q-1.8.1-3.65.4-.95.2-1.85.4-1.9.5-3.9 1.25-2.35 1.05-4.35 2.6-6.25 4.8-6.25 11.6t6.25 11.6q2.5 1.9 5.5 3.05v.05q8.5 3 18.35 0l.1-.05q0-.05.1-.05 5.05-2.3 8.9-3.3 2.55-.75 4.6-.8 5.05-.3 7.2-.7 2.1-.45 5.35-2.75 3.25-2.3 3.25-6.45 0-4.05-3.55-6.9-1.55-1.3-3.4-2-1.45-.55-3.05-.75l.1.05-.25-.05q-.05-.05-.05 0-.2-.05-.4-.05-9.1-1.5-17.15-5.1-4.5-1.9-9.2-2.05-1.3-.1-2.65 0m-.05 6.85q2.4.1 4.4.85 1.65.6 3.05 1.75.55.4 1 .9.6.55 1 1.25 1.15 1.7 1.25 3.75v.3q0 3-2.15 5.3-.5.5-1.1.95-.75.6-1.55 1.05-2.75 1.55-6.35 1.55-4.65 0-7.9-2.6-3.25-2.55-3.25-6.25 0-3.65 3.25-6.2 2.7-2.15 6.4-2.5.75-.1 1.5-.1h.45z"
                    />
                    <path
                        fill="#BD5630"
                        d="M105.95 270.85q-.7 0-1.3.1-2.05.3-3.55 1.5-.45.4-.9.9 1.25-.55 2.7-.55 1.8 0 3.25.75.7.4 1.35 1 .3.25.55.55.55.65.9 1.4.45 1 .45 2.25 0 .6-.1 1.15-.15.8-.5 1.45 1.05-.4 2-1.2.5-.35.9-.85 1.05-1.3 1.05-3 0-.8-.25-1.55-.3-.75-.85-1.45-.4-.45-.85-.85-1.8-1.4-4.2-1.55-.35-.05-.65-.05z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo046__Layer16_0_FILL" />
            <use xlinkHref="#ObjFoo046__Layer16_1_FILL" />
        </g></g>
    )
}

export default ObjFoo046
