import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#773900",
    "col2n": "#C40000"
}

function ShiLon048({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.95q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.65-2.5-3.45-4.9-.35-.5-.65-.9-.1-.15-.2-.25l-.05.1-1.7 1.35q-1.35 1.05-2.8 2.1l-1.55 1.15q-11.4 7.85-24.35 11.95-8.95 2.8-18.6 3.8-.9.1-1.85.2-.5 0-1.05.05l-1.1.05q-1.3.1-2.65.15-.75 0-1.45.05h-5.15q-.3-.05-.6-.05h-.15l-2.75-.1q-.5 0-1.05-.05l-1.1-.05q-1.4-.1-2.8-.25l-.8-.1-3.6-.45q-.15-.05-.3-.05h-.15q-5.9-.8-14.25-3.35L51 16Q39.15 11.95 28.65 4.7L27.1 3.55q-1.45-1.05-2.8-2.1L22.55.15l-.1-.1-.1.1q-.05 0-.05.05-.05.05-.1.15-.25.3-.5.65-2.7 3.7-5.1 7.75-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05L143 57.5q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.25q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon048__Symbol_319_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M103.85 15.25q6.35-4.2 10.45-8.5 3.05-3.2 5.3-6.75l-1.7 1.35q-1.35 1.05-2.8 2.1l-1.55 1.15q-11.4 7.85-24.35 11.95-8.95 2.8-18.6 3.8-.9.1-1.85.2-.5 0-1.05.05l-1.1.05q-1.3.1-2.65.15-.75 0-1.45.05h-5.15q-.3-.05-.6-.05h-.15l-2.75-.1q-.5 0-1.05-.05l-1.1-.05q-1.4-.1-2.8-.25l-.8-.1-3.6-.45q-.15-.05-.3-.05h-.15q-5.9-.8-14.25-3.35l-1.6-.5Q16.35 11.85 5.85 4.6L4.3 3.45Q2.85 2.4 1.5 1.35L0 .25q2.15 3.4 5.1 6.5 4.1 4.3 10.45 8.5 3.85 2.55 13.5 6.5 1.55.65 3.05 1.25 10.8 4.05 17.4 4.05h.15q2 0 3.7-.15 1.8-.2 3.25-.55 2.45-.65 3.05-2.05 0-.1.05-.2.45 1.55 3.1 2.25 1.05.25 2.3.45 2 .25 4.5.25h.15q6.6 0 17.35-4.05 1.55-.55 3.25-1.25 9.65-3.95 13.5-6.5z"
                    id="ShiLon048__Symbol_214_0_Layer0_0_FILL"
                />
                <path
                    d="M182.65 307.15q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65m-2.2-22.3q-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3m12.65-31q-.6-2.34-1.2 0v63.75h1.2v-63.75m-8.2 11.55q0-.95-.65-1.6-.65-.65-1.6-.65-.95 0-1.6.65-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6m63.7-1.15q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon048__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon048__Symbol_319_0_Layer0_0_FILL"
                transform="translate(110 228.55)"
                id="ShiLon048__col1n"
            />
            <use
                xlinkHref="#ShiLon048__Symbol_214_0_Layer0_0_FILL"
                transform="translate(132.8 228.65)"
                id="ShiLon048__col2n"
            />
            <use xlinkHref="#ShiLon048__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon048
