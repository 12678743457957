import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col2n": "#000000"
}

function ShiLon002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.65 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6-.1.05-.2.15l-1.65 1.25q-.4.3-.75.6-1 .75-2.05 1.5l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-20.9-3.2-38.2-15.15L27.05 3.5q-1-.75-1.95-1.45-.45-.35-.85-.65L22.4 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05V56l.5-.4q.85-.7 1.85-1.2.9-.5 1.85-.8.6-.25 1.25-.4.4-.1.85-.2.5-.1 1.1-.15-.05.05-.05.1l1.8-.2q7.85-.3 12.4 4.4.1.15.25.3L19 69.2q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.9q1.45.35 2.95.7l1 .2q.1.05.2.05 30.8 7 61.4 7 30.75 0 61.3-7 .1 0 .2-.05l1-.2q.75-.15 1.5-.35l1.45-.35H148.05q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.5-23.4q4.5-4.8 12.4-4.45l2.05.2q.15 0 .3.05 1.6.25 3 .85.15.05.25.1t.15.1q.3.1.6.25 1.05.55 2.05 1.25.05.05.15.1.45.35.85.7V56.2q0-.15-.05-.35-.05-.2-.05-.35-.25-1.4-.5-2.7l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon002__Symbol_276_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M26.6 35.35q-.2.1-.25.3L19 69.2q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.9q1.45.35 2.95.7l1 .2 6.5-46.1q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1M24.25 1.4L22.4 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05V56l.5-.4q.85-.7 1.85-1.2.9-.5 1.85-.8.1-.6.2-1.15.05-.55.2-1.1 4.35-25.55 18.9-47l1-1.5q.2-.3.4-.55.1-.15.2-.25-.45-.35-.85-.65M137.5 35.5q-.1.15-.05.35l6.5 46.1 1-.2q.75-.15 1.5-.35l1.45-.35H148.05q.25-.05.5-.15h.2l-.05-.05v-.25l-.95-3.6-.9-3.6q-.5-2.1-1.05-4.2l-7.25-33.55q-.05-.2-.25-.3-.15-.15-.3-.1h-.15q-.2.05-.35.25m25.15 9.4q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6-.1.05-.2.15l-1.65 1.25q-.4.3-.75.6h.2q-.052.002-.1.05.1.1.2.25.2.25.4.55l1 1.5q14.55 21.45 18.9 47l.2.9v.2q.1.55.2 1.15h.05v.25q.15.05.25.1t.15.1q.3.1.6.25 1.05.55 2.05 1.25.05.05.15.1.45.35.85.7V56.2q0-.15-.05-.35-.05-.2-.05-.35-.25-1.4-.5-2.7l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon002__Symbol_182_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon002__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon002__Symbol_276_0_Layer0_0_FILL"
                transform="translate(110.05 228.6)"
                id="ShiLon002__col1n"
            />
            <use
                xlinkHref="#ShiLon002__Symbol_182_0_Layer0_0_FILL"
                transform="translate(110.05 228.6)"
                id="ShiLon002__col2n"
            />
            <use xlinkHref="#ShiLon002__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon002
