import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF8E8E"
}

function FacMou025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M209.85 230.45q.233-1.077.35-2.2.526-5.88-1-11.6-.108-.205-.25-.4-.961-1.545-2.6-1.65h-26.3q-3.25.1-4.6 2.8-.35.75-.55 1.4-.3.75-.55 1.85-.05.25-.15.6v.05q-.2 1.05-.3 2.1-.25 2.2-.05 4.55.05 1.2.6 2.2 3.15 6.1 9.8 4.4 2.9-.7 5.75-1.85.7-.25 1.3-.45.6-.2 1.1-.2.45 0 1.7.35t1.65.5q1.1.4 1.95.8 3.2 1.6 6.75 1.6 4.35.05 5.4-4.85z"
                    id="FacMou025__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.8 2.8q-.5-1.05-1-1.95-.25-.4-.5-.85H.75Q.6.3.5.7q-.15.45-.25 1-.15.6-.2 1.35v.2q-.2 2.9.55 4.65 1.25 2.35 4.3 2.2 1.3-.15 3.15-.95Q10.45 8.1 10.9 8q.2-.05.4-.05 2.2-.45 4.6.4 1.05.35 2.2.95 2.65 1.55 4.65 1.35 1.75-.2 3.05-1.85.15-.15.25-.5.85-2.75-.25-5.5z"
                    id="FacMou025__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M0 0v5.1h6.25V0H0m13.85 5.1V0h-6.8v5.1h6.8m7.6 0V0h-6.8v5.1h6.8m7.7-5.1h-6.9v5.1h6.9V0z"
                    id="FacMou025__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou025__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou025__Symbol_114_0_Layer0_0_FILL"
                transform="translate(179.2 222.7)"
                id="FacMou025__col2n"
            />
            <use
                xlinkHref="#FacMou025__Symbol_50_0_Layer0_0_FILL"
                transform="translate(178.25 216.2)"
                id="FacMou025__col1n"
            />
        </g></g>
    )
}

export default FacMou025
