import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.1 14.25l-1.25-4.2Q188.8 8 187 6.3l-1.1-.9q-1.25-.95-2.5-1.75v.1q-.1-.05-.15-.1-3.15-1.95-7.05 0-3.15 1.15-6.35 2.25-7.3 2.6-13.25-.45-5.75-2.9-11.7-.4-17 7.1-32.05-3.05-.45-.3-.9-.5-6.25-3-16.4 0-.3.1-.6.25-8.75 3.5-13.35 2.5-5.05-1.7-12.25-.3Q57.9 6 46.7 6.45 35.45 6.9 28 2.1q-.1 0-.15-.05-5.3-3.8-16.1 0-1.4 2.95-3.3 5.7-.55.75-1 1.45-.05 0-.05-.05l-.1.2-1.55 1.9-.5.6q-.5.45-.9 1.05-.25.2-.45.45-.8.9-1.4 2.25Q.2 20.2 0 26.25v79.25q1.25-1 2.55-1.95 2.9-2.25 5.8-4.35.25-.25.6-.55.85-.7 1.6-1.6.8-1 1.55-2.05 2.6-3.75 4.35-7.95.1-.25.2-.45.2-.45.35-.9l.1-.2q.5-1.3 1.4-2.45 2.45-3.1 5.5-5t6.75-2.7q.6-.1 1.15-.15.85-.15 1.6-.3.5-.15 1-.25.85-.3 1.65-.6Q39 72.8 40.6 70.5q.15-.2.3-.35 0-.1.05-.15.05-.1.1-.15 2.5-3.6 6.6-4.7.25-.1.5-.15 3.5-.75 6.85-.5 2.4.2 4.75.9 1.5.45 2.95 1.1.1.05.25.1 4.7 1.9 7.55 0 .15-.05.3-.15 2.9-1.5 5.95-2.7Q87.1 59.7 98.05 59q10.95-.5 20.85 3.45l.2.1q2.2.7 4.25 1.5 2.15.8 4.05 1.65 1.95.85 3.65 1.7 3.05 1.55 5.3 3.25 4.7 3.4 10.7 4.4 1.45.2 3.05.3h.05q.25 0 1.3.1.05 0 .15.05 1.1.05 3.05.25.8.05 1.7.15 3.35.45 6.7 1.15 3.35.75 6.3 2.3 1.25.65 2.4 1.45 2.1 1.5 3.95 3.45.6.6 1.15 1.25 2.65 3.15 4.75 6.7l.05.05.4.7q.65 1.1 1.35 2.15 3.2 5.05 7 9.65.35.3.7.65V14.25z"
                    id="HaiSpe014__Symbol_146_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M55 60.85q2.4.2 4.75.9-.85-44.5-2.25-31.15Q54.85 46 55 60.85M37.1 54q.2-2.1.1-3.75-.25-3.1-1.9-3.45-1.2 1.65-1.5 3.5-.1.7-.1 3.3 0 5-.1 9.1-.1 3.4-.1 8.55.5-.15 1-.25.85-.3 1.65-.6.25-7.2.55-9.8.4-3.7.4-6.6M7.4 5.5l-.1.2-1.55 1.9-.5.6q-.5.45-.9 1.05-.25.2-.45.45-.8.9-1.4 2.25Q.2 16.55 0 22.6v79.25q1.25-1 2.55-1.95 2.9-2.25 5.8-4.35.25-.25.6-.55.85-.7 1.6-1.6.8-1 1.55-2.05 2.6-3.75 4.35-7.95.3-.75.55-1.45l.1-.2q.5-1.3 1.4-2.45 2.45-3.05 5.5-4.95V46.4 35.1q0-11.85-.2-22.1l-4.8.35q.05 10.9.4 21.8.25 7.1.65 14.2.75 14.6-6 19.05-1.5-16.2-1.75-33.15-.1-2.95-.1-5.85-.05-10.05.9-16.8-2.6-.7-5.25-2.2-2.65-1.5-.4-4.85-.05 0-.05-.05m33.55 8.9v51.9q.05-.1.1-.15 2.5-3.6 6.6-4.7-1.5-3-2.65-12.2-1.2-9.2-1.15-14.6 0-5.45-.25-12.4-.3-6.95-2.65-7.85m82.4 46l-.85-30q-2.05-19.75-3.4 6.4v22.1q2.2.7 4.25 1.5m7.7 3.35q-.25-3.7-.5-8.25-.5-9.2-.85-11.9-2.4 2.55-2.3 8.75.05 6.8 0 9.7 1.95.85 3.65 1.7m16.25-46.3q-.2-1.85-1.45-2.05-.9 4.95-.8 10.45 0 2.15.2 4.35l1.8 41.2q1.45.2 3.05.3h.05q.25 0 1.3.1.05 0 .15.05l-3.9-51q-.1-.5-.1-1l-.3-2.4M189.85 6.4Q188.8 4.35 187 2.65l-1.1-.9Q184.65.8 183.4 0v.1q-.05.15 1.3 1.9 1.3 1.7 2.05 5.85.75 4.15-4.95 5.55-3-.85-3.35-1.9.85 10.35.5 22l-.1 2.9q-.55 13.45-2.7 22.45-3.25-2.65-3.1-9.45.15-7.95 0-15.85-.2-10.45-1-20.75-3.8 9.05-4.35 20.8-.15 2.8-.1 5.8.05 2.9.3 6l1.45 30.3q1.25.65 2.4 1.45 2.1 1.5 3.95 3.45.6.6 1.15 1.25 2.65 3.15 4.75 6.7l.05.05.4.7q.65 1.1 1.35 2.15 3.2 5.05 7 9.65.35.3.7.65V10.6l-1.25-4.2z"
                    id="HaiSpe014__Symbol_84_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe014__Symbol_146_0_Layer0_0_FILL"
                transform="translate(96.9 39.15)"
                id="HaiSpe014__col1n"
            />
            <use
                xlinkHref="#HaiSpe014__Symbol_84_0_Layer0_0_FILL"
                transform="translate(96.9 42.8)"
                id="HaiSpe014__col1d"
            />
        </g></g>
    )
}

export default HaiSpe014
