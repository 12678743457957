import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou068({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M207.95 223.5q-1.3-7.4-4.85-10.95-3.25-2.75-7.25-3.7-3.3-.95-6.65 0-4 .95-7.25 3.7-3.55 3.55-4.85 10.95-2.1 9.6-1 11.85 1.15 2.25 3.55 2.05.85-.05 1.65-.15.35-.05.75-.15.25-.05.55-.1 9.95-1.25 19.9 0l.5.1q.1 0 .25.05 1 .2 2.15.25 2.4.2 3.55-2.05 1.1-2.25-1-11.85z"
                    id="FacMou068__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M16.05 23.65q-.05.1-.05.3-.05.2-.05.5l.05.45q.3.4 1.5.4h.5q1.15-.1 1.35-.5.05-.1.05-.2 0-1.1-1.7-1.9-.1-.05-.2-.05-.1-.05-.2-.05-1.05-.25-1.25 1.05m-1.85.8q0-.3-.05-.5 0-.2-.05-.3-.2-1.3-1.25-1.05-.1 0-.2.05-.1 0-.2.05-1.7.8-1.7 1.9 0 .1.05.2.2.4 1.35.5h.5q1.2 0 1.5-.4l.05-.45m-5-.3q0-2.85-1.1-2.4-.2.05-.45.25-1.5 1.2-1.6 2.85.15.7.8.8.3 0 .55-.05.15 0 .3-.05.9-.15 1.25-.5.25-.3.25-.9m-4.65-.7q.15-.95.15-3.75 0-2.55-.6-2.7-.1.1-.2.3-.05.05-.1.15Q1.85 21 .75 22.8 0 23.85 0 24.8q0 .45.2.65.55.5 2.45.5 1.45 0 1.9-2.5M9.6 2.2q-.15-1-1-.85h-.1q-.1 0-.2.05-.1 0-.2.05-.15 0-.35.1-.55.2-1.1.65-.3.25-.55.55-.1.1-.2.25-1.05 2.35.4 4.7l.05.05Q8 10.4 9 10.4h.15q.3-.05.4-.25 0-.05.05-.1V2.2m4.9-.95q.05-.3.05-.85 0-.6-1.8-.35Q11 .25 11 1.45q0 .45.35.95.2.35.5.5.35.2.75.2.55 0 1.2-.8.7-.8.7-1.05m4.3 1.15q.35-.5.35-.95 0-1.2-1.75-1.4-1.8-.25-1.8.35 0 .55.05.85 0 .25.7 1.05.65.8 1.2.8.4 0 .75-.2.3-.15.5-.5m3.25 19.35q-1.1-.45-1.1 2.4 0 .6.25.9.35.35 1.25.5.15.05.3.05.25.05.55.05.65-.1.8-.8Q24 23.2 22.5 22q-.25-.2-.45-.25m7.35 1.05q-1.1-1.8-3.05-5.35-.05-.1-.1-.15-.1-.2-.2-.3-.6.15-.6 2.7 0 2.8.15 3.75.45 2.5 1.9 2.5 1.9 0 2.45-.5.2-.2.2-.65 0-.95-.75-2M24.25 3q-.1-.15-.2-.25-.25-.3-.55-.55-.55-.45-1.1-.65-.2-.1-.35-.1-.1-.05-.2-.05-.1-.05-.2-.05h-.1q-.85-.15-1 .85v7.85q.05.05.05.1.1.2.4.25h.15q1 0 2.65-2.65l.05-.05q1.45-2.35.4-4.7z"
                    id="FacMou068__Symbol_93_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou068__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou068__Symbol_93_0_Layer0_0_FILL"
                transform="translate(177.45 209.95)"
                id="FacMou068__col1n"
            />
        </g></g>
    )
}

export default FacMou068
