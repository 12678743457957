import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.1 44.25q4.15-1.9 8.45-3.35-13-22.85-51.2-40.4Q-5.7 44.15 5.2 84.55q2.95-5.15 6.65-9.95Q16 69.1 21.1 64q12.8-12.8 28-19.75m117.05 30.7q3.55 4.75 6.5 9.8 11-40.45-1.1-84.25-38.25 17.55-51.1 40.35 4.2 1.6 8.35 3.5Q143.9 51.3 156.6 64q5.25 5.25 9.55 10.95z"
                    id="FacEar010__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.5 64.2q4.15-5.5 9.25-10.6 12.8-12.8 28-19.75Q37.75 17.9 4.85.5-5.6 35.95 6.5 64.2M161.05 1q-32.35 15.3-37.6 32.95 15.1 6.95 27.8 19.65 5.25 5.25 9.55 10.95 11.7-27.45.25-63.55z"
                    id="FacEar010__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M5.15 18.15H4.5q2.35.6 6.85 4.45 4.95 4.3 6.65 8.05-.95-.6-3.5-1.35-2.85-.8-4.75-.8-1.55 0-4.3.55-3.2.6-4.3 1.35h.1q5.4.45 8.45 1.3 6.05 1.7 8.7 5.55-3.5-.6-8.3.9-5.8 1.75-7.7 5.5 1.55-.7 5.2-1.45 5.65-1.1 10.9-.55l-.25.25q-2.85 2.55-5.5 6.25-2.5 3.5-2.95 5.65.4-.45.8-.85 12.8-12.8 28-19.75Q32.65 17.3 0 0q13.05 10.85 19 23.3-1.45-.95-3.6-2.4-2.2-1.45-6.2-2.5-4.05-1.1-4.05-.25m146.35 0h-.65q-.1-.8-4.4.4-4.35 1.15-6.15 2.5-1.85 1.3-3.3 2.25 5.75-12.05 18.2-22.6-31.7 15.15-36.9 32.6 14.95 6.9 27.6 19.45-.75-1.9-2.65-4.6-2.65-3.7-5.5-6.25l-.25-.25q5.25-.55 10.9.55 3.65.75 5.2 1.45-1.9-3.75-7.7-5.5-4.8-1.5-8.3-.9 2.65-3.85 8.7-5.55 3.05-.85 8.45-1.3h.1q-1.1-.75-4.3-1.35-2.75-.55-4.3-.55-1.9 0-4.75.8-2.55.75-3.5 1.35 1.7-3.75 6.65-8.05 4.5-3.85 6.85-4.45z"
                    id="FacEar010__Symbol_48_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar010__Symbol_28_0_Layer0_0_FILL"
                transform="translate(103.55 22.55)"
                id="FacEar010__col1n"
            />
            <use
                xlinkHref="#FacEar010__Symbol_20_0_Layer0_0_FILL"
                transform="translate(108.95 32.95)"
                id="FacEar010__col2n"
            />
            <use
                xlinkHref="#FacEar010__Symbol_48_0_Layer0_0_FILL"
                transform="translate(114.1 33.6)"
                id="FacEar010__col2d"
            />
        </g></g>
    )
}
export default FacEar010
