import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1n": "#EEEEEE",
    "col2d": "#A6814A",
    "col2n": "#C69C5D"
}

function ObjMor092({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.65 62.2l-1-6.65-3.25-4.5-1.7-6.35-3.85-3.65-2.05-5.95-5.05-3.5-3.05-5.35-4.4-2.55-3.8-5.2-5.3-2.7-4.7-3.95-5.6-1.7-4.7-3.45-6.3-1.4-4.95-3-5.95.45L92.55 0 86.1 1.55 80.35.25l-5.7 1.9-5.7-.8-5.8 2.75-6.05.05-4.65 2.5-6.1 1.3-4.55 3.5-6 2.35-3.45 3.6-5.9 2.95-3.8 4.55-5.15 3.65-3.25 5L10 37.2l-2.05 5.9-4 4.5-.9 6.25-2.8 4.4L0 60.4l44.2 16.3.2-.5.05-.35.15-.15 1.7-3.7h-.05l.35-.6.05-.2.2-.2.35-.75.1.1 1-1.85.85-1.1-.05-.1 4.55-5.55 2.6-2.25-.05-.05 1.65-1.2v.05l2.6-1.95 1.6-.95.15-.05 3.05-1.6-.05-.05.85-.4 1-.3 1.5-.65 1.45-.4-.05-.1 1.95-.55 5.5-1.05v.1l3.05-.35h-.35l2.3-.1.05.1 3.5.1-.05-.05.2.1 1.75.2h.05l3 .65.15-.15 5.05 1.6.1-.1 1.85.85h.05l1.55.55 1.2.7.95.4.85.6.05.05 3 1.8 5.5 4.6-.15-.05 2.4 2.65.05-.1 1.2 1.6v.1l1.75 2.5.15.05.9 1.65v.05l1.65 3.1v-.05l.45.85.35 1v.05l1.65 4.8 43.85-11.85.1-1.9-1.9-4.15z"
                    id="ObjMor092__Symbol_226_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M46.25 72l.35-.6.05-.2-42.7-23.6-.9 6.25 42.6 19.55.65-1.4h-.05m-46-13.75L0 60.4l44.2 16.3.2-.5.05-.35-44.2-17.6M10 37.2l-2.05 5.9L48.3 68.5l.85-1.1-.05-.1.55-.75L10 37.2m7.5-8.65l-3.25 5 37.1 30.95 1.55-1.9-35.4-34.05M35.8 13.8l-3.45 3.6 26.9 39.9 1.2-.95.75-.45-25.4-42.1m-9.35 6.55l-3.8 4.55L55.1 60.55l1.15-1.05-.05-.05.8-.55-30.55-38.55m19.9-12.4l-4.55 3.5L63.65 54.6l1.6-.85-.05-.05.85-.4-19.7-45.35m10.75-3.8l-4.65 2.5 16.1 45.7 1.45-.4-.05-.1.85-.2-13.7-47.5m11.85-2.8l-5.8 2.75L73.9 50.9l2.35-.5-7.3-49.05m11.4-1.1l-5.7 1.9 4.3 47.95 1.5-.1.05-.15.85.1-1-49.7m19.3 53.5l1.2.7.95.4 23.7-43-5.6-1.7-20.25 43.6m19.5 19.7v-.05l.45.85 43.05-18.7-3.25-4.5-41.1 20.8.85 1.6M103.95 2.3L98 2.75 89.45 50.7l1.45.3.15-.15.8.25 12.1-48.8M92.55 0L86.1 1.55l-2 48.5 1.85.05-.05-.05.2.1.65.05L92.55 0m22.65 6.7l-6.3-1.4-14.4 46.65 1.6.5.1-.1.9.4L115.2 6.7m28.5 19.55l-4.4-2.55-30.85 35.9 1.9 1.6 33.35-34.95m-8.2-7.75l-5.3-2.7-25.8 40.65 1.3.85-.05-.05.75.6 29.1-39.35m16.3 16.6l-5.05-3.5-34.4 31.7 1.1 1.2.05-.1.55.75L151.8 35.1m5.9 9.6l-3.85-3.65-38.25 26.4.85 1.15.15.05.4.75 40.7-24.7m7.85 21.65l-1.9-4.15-.1-.75-43 15.4.75 2.25 44.25-12.75z"
                    id="ObjMor092__Symbol_225_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M62.5 11.85l.05-.1.5-3.05-1.6-1.3.05.05L58.6 9l-.05.2-.05-3.55-.05-.05-.85-.6-.95-.4-2.45 1.9.05-.1-.35-3.05h-.05L52 2.5l-2.2 2.2-.15.4-.2-.65.1-.05-.4-1-.35-1.85L46.85 1l-.15.15-1.85 2.25-.1.45.05-.5L43.7.5h-.05L41.9.3l-.2-.1.05.05-1.65 2.7-.05.2L38.3 0h-2.1l-1.25 2.95L33.2.5V.4l-2.1.3-.85 3.05.05.2-.45-.25-2.15-2.25-1.95.55.05.1-.5 2.9-2.45-1.85-1 .3-.85.4.05.05-.15 3.15.05.1-1.4-.65-1.55-1-.15.05-1.6.95.35 3.2.2.45-.3-.5-1.55-.75-1.3-.45V8.4L12 9.6l.05.05.75 3 .15.2-1.15-.25-.4.05-1.95-.75-1.35 1.55 1.1 2.9-3-.45-.75.8-.55.75 1.55 2.8.3.1-3.65.15-.1-.1-.95 1.75h.05l1.9 2.5.15.1-.3-.15-3.1.4L0 26.85l2.15 2.25 30.3 10.65q.15-.45.4-.95.45-1.45 1.8-2.55.7-.55 1.55-.95 1.45-.85 3.25-1.4.1 0 .2-.05.4-.1.8-.15.05-.05.1-.05.65-.15 1.35-.2h.3q.5-.05 1-.05.3 0 .6.05.55 0 1.15.1l.5.1q.45.1.9.25.15.05.35.15.25.1.55.25.5.25.95.55.3.2.55.45.35.3.6.65.5.65.7 1.5.15.6.15 1.2l25.05-6.15 2.15-2.25-.55-1.85-3.1-.5.1-.05 1.9-2.4v-.05l-.35-1-.45-.85v.05H71.6l1.7-3.1v-.05l-.9-1.65-.15-.05-3.15.35.15-.1 1.25-2.75v-.1l-1.2-1.6-.05.1-3.05.65-.1.15v-.4l.75-3.05.15.05-1.6-1.35-2.9 1.15m-20.8 19.5v.25h-.1l.1-.25m-7.45.55v.05l-.05.1.05-.15z"
                    id="ObjMor092__Symbol_224_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M46.55 33.8q.35.3.6.65l24.4-8.05.1-.05 1.05-1.3-26.15 8.75M60.3 10.35l.05-.1.3-1.75Q50.65 19.9 40 31.95q.5-.05 1-.05l.9-1.05h.15l18.25-20.5M56.35 7.7l-.1-1.65-17.9 26.1q.65-.15 1.35-.2l1.05-1.6h.05L56.35 7.7M42.6 1.85L42 .3l-6.6 32.15 2.45-30.8-.8-1.35-2.55 32.15-1.75-31-1-1.45 1.85 32.7-5.5-30.25-.45-.25-.8-.75 5.95 31.5-9.75-29.3.05-.15-1.35-1.05 9.85 30.2.4.95L18.75 5.65 17.35 5 30.5 32.4q.1.05.05.15l.85 1.65L14.6 8.7v-.05l-.3-.5-1.55-.75L30.8 34.95l-20.05-23.6-1.15-.25-.4.05 19.6 22.8q.05.1.1.1l1.3 1.45L7 14.85l-1.8-.3 24.55 21.6-.55-.25v-.1L4.55 18.85l-1.7.05 26.7 18.25L1.65 23.1 0 23.3l28.75 14.45 1.5.5q.15-.45.4-.95.45-1.45 1.8-2.55.7-.55 1.55-.95 1.45-.85 3.25-1.4l.9-2.55q.05-.15.1-.2v-.05h.05l-.05-.1.1-.1 9.1-25.8-.2-.65.1-.05-.4-1-10.7 30.4.8-3.2v-.05l5.5-26.7.05-.5M52 5l.05-.1-.2-1.8-14.6 29.3q.1 0 .2-.05.4-.1.8-.15l1.15-2.1.1-.25L51.95 5H52m11.9 8.55l.3-1-22.6 19.4q.55 0 1.15.1.05 0 .1-.05l.05-.1 21-17.95v-.4m2.75 4.3l.1-.15.3-.2.65-1.55-24.45 16.2q.45.1.9.25.15.05.35.15l22.15-14.7m2.75 4.25l.8-1.45L45.05 32.8q.5.25.95.55L69.4 22.1z"
                    id="ObjMor092__Symbol_223_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor092__Symbol_226_0_Layer0_0_FILL"
                transform="translate(184.25 213)"
                id="ObjMor092__col1n"
            />
            <use
                xlinkHref="#ObjMor092__Symbol_225_0_Layer0_0_FILL"
                transform="translate(184.25 213)"
                id="ObjMor092__col1d"
            />
            <use
                xlinkHref="#ObjMor092__Symbol_224_0_Layer0_0_FILL"
                transform="translate(228.45 262.85)"
                id="ObjMor092__col2n"
            />
            <use
                xlinkHref="#ObjMor092__Symbol_223_0_Layer0_0_FILL"
                transform="translate(230.65 264.35)"
                id="ObjMor092__col2d"
            />
        </g></g>
    )
}

export default ObjMor092
