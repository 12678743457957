import { AssetSvgProps } from "../../../shared/assets"

function ObjToy041({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M94.8 264.5q-.15 0-.3.15-.1.15-.1.35l3.85 29.05q0 .2.15.3l7.35 5.65q-.05-.55-.05-1.05v-.15q.05-.5.05-1 .15-1.35.6-2.5.3-.75.65-1.4.35-.55.8-1.1.1-.1.15-.2.2-.2.35-.4l.1-.1.15-.15q.4-.4.8-.7 1.55-1.25 3.45-1.65.8-.2 1.7-.15.45 0 .85.05.45 0 .85.1.5.1.95.25l.2.1q.4.1.8.25.45.15.8.35.1 0 .15.05.7.3 1.35.75l.5.3q.3.2.55.45.25.15.4.35.55.45 1.1 1.05 1 .75 1.95 1.95 1.3 1.6 1.95 2.85.2.3.3.6.25.6.3 1.05.05.1.1.3.05.25.15.65.05.1.1.25l11.7-1.55q.2-.05.3-.2.15-.15.1-.35L136.1 270q0-.2-.2-.3l-11.7-9q-.15-.1-.35-.1l-29.05 3.9z"
                    id="ObjToy041__Layer14_0_FILL"
                />
                <g id="ObjToy041__Layer14_1_FILL">
                    <path
                        fill="#00F"
                        d="M137.85 289.95l-8.8 1.2 1.15 8.75 8.8-1.2-1.15-8.75m-31.5 5.35q.3-.75.65-1.4.35-.55.8-1.1l-1.95-1.5.5 4m1.1-11.45l-2.9-2.2 1.15 8.4 2.7 2.05.15-.15-1.1-8.1m7.95-21.15l2.85 2.15 8.35-1.1-2.8-2.2-8.4 1.15z"
                    />
                    <path
                        fill="red"
                        d="M137.7 289.05l-1.15-8.8-8.8 1.2 1.2 8.75 8.75-1.15m-9.55 2.2l-6.65.85q.25.15.4.35.55.45 1.1 1.05 1 .75 1.95 1.95 1.3 1.6 1.95 2.85.2.3.3.6.25.6.3 1.05.05.1.1.3l1.7-.2-1.15-8.8m-26.4-4.25l2.85 2.25-1.1-8.4-2.9-2.25 1.15 8.4m10.5-16.9l-8.4 1.1 2.85 2.25 8.45-1.1-2.9-2.25m1.25-.15l2.9 2.2 8.4-1.1-2.85-2.2-8.45 1.1z"
                    />
                    <path
                        fill="#F60"
                        d="M135.25 270.55l-8.75 1.2 1.15 8.8 8.75-1.2-1.15-8.8m-35.9-1.6l1.1 8.4 2.9 2.2-1.1-8.4-2.9-2.2m13.45 20.65q.8-.2 1.7-.15.45 0 .85.05.45 0 .85.1.5.1.95.25l.2.1q.4.1.8.25l-.95-7.35-8.8 1.15.95 7.25q1.55-1.25 3.45-1.65m-16.05-13.95l1.1 8.4 2.85 2.2-1.1-8.4-2.85-2.2m10.5-9.35l-2.85-2.15-8.4 1.1 2.85 2.2 8.4-1.15z"
                    />
                    <path
                        fill="#0C0"
                        d="M123.2 268.65l2.9 2.25 8.4-1.15-2.9-2.2-8.4 1.1m7.4-1.9l-2.95-2.2-8.4 1.1 2.9 2.2 8.45-1.1m-11.5 23.85q.7.3 1.35.75l7.55-1-1.15-8.8-8.75 1.15 1 7.9m-14.3-.1l-2.9-2.2 1.1 8.4 2.7 2.1q.05-.5.05-1l-.95-7.3m12.25-8.55l-1.15-8.8-8.75 1.15 1.15 8.8 8.75-1.15m-11.35-18l2.85 2.2 8.4-1.15-2.85-2.15-8.4 1.1z"
                    />
                    <path
                        fill="#FF0"
                        d="M107.3 282.55l-1.15-8.4-2.85-2.2 1.1 8.4 2.9 2.2m9.5-9.5l1.15 8.75 8.75-1.15-1.15-8.75-8.75 1.15m-18.5-4.9l-2.85-2.2 1.1 8.45 2.9 2.15-1.15-8.4m12.9 1.15l-2.9-2.2-8.4 1.1 2.9 2.25 8.4-1.15z"
                    />
                    <path
                        fill="#FFF"
                        d="M98.05 285.3l1.1 8.45 2.85 2.15-1.1-8.4-2.85-2.2M120.9 268l-2.95-2.15-8.35 1.1 2.85 2.2 8.45-1.15z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy041__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy041__Layer14_1_FILL" />
        </g></g>
    )
}

export default ObjToy041
