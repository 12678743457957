import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00230C",
    "col1n": "#004D1E"
}
function AccMor103({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.35 1.1q-.85.3-2.5.3h-3.9q-1.05.15-1.65.5-.755.33-.8 1-.059.073-.1.1-.234-.378-.5-.6-.45-.4-1-.4-.9 0-1.25.15-.55.2-.8.75h-.05q-.1-.15-.25-.3-.45-.35-1.05-.35-1.85 0-5.05 1.65-.45.25-.85.45-.15.05-.25.1Q10.4 6.2 9.55 6.5q-.9.25-1.85.6-1.35.5-2.85 1.1-.3.1-.55.25Q3 9 2.15 9.5 0 10.55 0 11.8q0 .7.2 1.5.15.6.4.9.1.4.35.65.45.5.45 1.45 0 1-.2 1.55T1 19q0 .8.5 1.55.1.15.25.3.55.6 1.35.6.2 0 .4-.05.5-.15 1.2-.5.1-.1.25-.15Q6.35 20 7.7 19.3q8.9-4.85 14.2-9.85.2-.2.4-.35.15.2.3.45.05.05.1.15.05.1.15.2.05.15.15.3.55 1 .75 1.2.15.15.45.35v.1q.15.05.25.2.2.1.4.25.25.15.45.35 1.55.85 3.05 1.15.65.1 1.25.1 1.05 0 3.3-.3.2-.05.4-.05.15-.05.35-.05h.05q.15-.1.2-.1-.5-.2-1.25-.6-.75-.4-1.95-1.85-1.25-1.45-1.45-2.2V8.7q-.05-.05-.25-.5-.4-.8-.45-1 2.65.3 4.35-.75 1.2-.75 1.95-2.2l.1-.25q.9-1.45 1.5-3.8V0q-1.3.85-2.1 1.1M28.8 3.8q.05 0 .1.05-.097-.048-.15-.05h.05M24.15 10h.05v.05l-.05-.05z"
                    id="AccMor103__Symbol_221_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M35.95.15V0L34.2 1.95q-.25.25-1.05.75-.55.3-.75.45l-.3.2q-.2.05-.35.1-.95.4-2.1.4h-.9q-.2-.05-.35-.05-.1-.05-.15-.05h-.4q-.3-.1-.55-.1-.9 0-1.3.2-.3.15-.55.55-.048 0-.05.05v-.2q-.4-1.4-1-1.9-.45-.4-1-.4-.9 0-1.25.15-.55.2-.8.75.05.05.15.1l.25.25q.3.35.3.85 0 .65-.7 1.4l-.1.1q-.1.05-.15.1l-.1.1-.15.15q-.5.35-1.35.85-1.1.6-1.65 1.05-.55.45-1.15.75-.6.3-4.65 2.95 4.1-1.85 4.5-2.1.3-.2 2.4-1.3 2.1-1.25 2.75-2.05 0-.05.05-.05l.05-.1q0-.05.05-.1.15 0 .3.1 0-.05.05 0l.1.1.2.3q.55.95.1 1.85v.05q-.1.1-.15.2-.05.05-.1.15-.2.15-.4.35-.05 0-.05.05V9q-.05 0-.1.05.15.2.3.45.05.05.1.15.05.1.15.2.05.15.15.3.55 1 .75 1.2.15.15.45.35v.1q.15.05.25.2.2.1.4.25.25.15.45.35 1.55.85 3.05 1.15.65.1 1.25.1 1.05 0 3.3-.3.2-.05.4-.05l-.5-.15h.1q.2 0 .75.1h.05l-2.5-.6q-.1-.15-.45-.35-.6-.3-1.05-.55-.8-.6-1.35-1.55-.15-.3-.3-.55-.05 0-.05-.1-.8-1.5-1.8-2.45-.1-.05-.15-.1-.35-.25-.75-.45l-.4-.2q-.15-.05-.25-.05l-.2-.1v.05Q22.65 6 22.6 6h.1q.15-.05.25-.05v.1Q22.9 6 22.85 6h-.15l.35.1q.5.1.9.05l.4-.1h.05l.15-.15.2-.2.05-.05q.2-.3.4-.55.1-.1.15-.25h.25q1.25.05 2.3 1.95.05.15.15.35 2.65.3 4.35-.75 1.2-.75 1.95-2.2l.1-.25q.9-1.45 1.5-3.8M23.7 10l-.05-.05h.05V10M1.6 14.4q-.3-.05-.55-.05l-.5-.2q-.25-.1-.45-.3l-.1-.1q0 .2.1.4.1.4.35.65.45.5.45 1.45 0 1-.2 1.55t-.2 1.15q0 .8.5 1.55.1.15.25.3Q1 20.25 1 19.6q0-1.1 1.05-2.1 1-1.05 1-1.65 0-1.3-1.45-1.45z"
                    id="AccMor103__Symbol_220_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor103__Symbol_221_0_Layer0_0_FILL"
                transform="translate(247.7 255.75)"
                id="AccMor103__col1n"
            />
            <use
                xlinkHref="#AccMor103__Symbol_220_0_Layer0_0_FILL"
                transform="translate(248.2 255.8)"
                id="AccMor103__col1d"
            />
        </g></g>
    )
}
export default AccMor103
