import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#435125",
    "col1n": "#617433",
    "col2d": "#A37831",
    "col2n": "#D19532"
}

function LegPan017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.75 0l-1.5.3q-10.15 1.9-20.2 3.25-3.1.4-6.2.75Q92 4.9 86.2 5.35q-4.6.35-9.15.55-3.75.15-7.45.25-4.55.1-9.1.1Q48 6.15 35.6 5.1q-.9-.1-1.8-.15-3.15-.3-6.3-.65-.15-.05-.3-.05h-.1q-5.15-.55-10.25-1.3-3.9-.6-7.8-1.25l-2.8-.5Q3.1.65 0 0q0 .05.05.1v19.5q1.3-.25 2.6-.4.05-.05.1-.05 1.8-.25 3.65-.5h.05q3.9-.5 7.75-.75 2.1-.2 4.2-.3 6.6-.3 13.05-.05 4.8.2 9.5.7 3 .3 5.95.75 8.05 1.2 15.95 3.35 10.65-2.9 21.65-4.05 5.45-.6 11-.8h.1q5.1-.15 10.3.05l3.7.2q2.15.15 4.3.35 3.85.3 7.75.85 2 .3 4.05.65V.05l.05-.05z"
                    id="LegPan017__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.45 9.5q-2.2 0-3.75 1.05Q0 11.65 0 13.4q0 .85.25 2.85.05-.05.1-.05 1.8-.25 3.65-.5h.05q0-.15.05-.35.15-.6.3-1.1.4-.75 1.2-1.35 1.6-1.2 1.6-1.95 0-.85-.4-1.15-.4-.3-1.35-.3m22.15-.9q-1.65 0-2.8 2.05-1.15 2.1-2.65 2.1l-3.45-.6q-1.5 0-2.4 1.85-.15.3-.3.65 6.6-.3 13.05-.05.15-1.25.15-2.15 0-3.85-1.6-3.85m-3.1-7.2l.2-.1Q19.55.75 14.45 0v.05q.05.95.2 1.75.1.65.25 1.2.05.35.15.65l.1.2q.2.6.5.95.35.9 1.4.9.5 0 .85-.2.3-.75 1.05-2.3.25-.65 1.6-1.2 1.55-.65 3.65-.65.15 0 .3.05M82 13.7v-.05-.1q0-.95.1-1.7-3.9 2.55-7.4-.7.1.35.1.75 0 2.75-2.55 2.85-.35.05-.8.05-2.5 0-3.8-3.05-.75-1.65-.85-2.95-.05-.25-.05-.5v-.05q-.2-1-1.45-1.55-1.3-.55-2.85-.35-.7.05-1.7-.35-.8-.35-1.35-.8-.85-.7-1.3-1.9-12.5-.1-24.9-1.15-.9-.1-1.8-.15.5 1.15 1.3 2.1.7.85 1.85 1.95.15.15.3.35.1.05.2.15.6.55 1.75 1.3 1.1.7 2.7 1.15 1.9.6 3 .1.05 0 .1-.05 1-.45 3.75-2.05 3.4-1.6 5.15-.75.05 0 .1.05 1.8.35 1.8 2.1 0 1.85-2.4 2.3h-1.6q-2.9.2-3.85 2.25-.2.35-.65 1.55-.05.85-.35 1.2 0 .2-.05.35 8.05 1.2 15.95 3.35 10.65-2.9 21.65-4.05v-.2-.3q0-.1-.05-.5Q82 14 82 13.7m13-6.95q.5-1.8.5-4 0-.8-.05-1.4-5.85.6-11.65 1.05v.5q0 .25.05.5-.05.1-.05.2 0 .5.05.9 0 .5.1.8.1.2.25.35.35.7 1.1.7.15 0 1-.4.85-.45 1.8-.45 2 0 3 2.45 1 2.4 1.75 2.4 1.45 0 2-2.75.1-.4.15-.85m18.65-3.3q.05-.2.2-.45.2-.35.6-.7-.3.15-.55.25-.4.3-2.15.9-1.5.95-1.5 2.65 0 .95 1.2 2.45 1.25 1.5 1.25 2.6 0 1.35-.5 2.55-.25.6-.7 1.45 3.85.3 7.75.85 0-.15.1-.3l-.1-.5q-.15-.4-.3-1.05-.55-2.55-1.3-4.05-.4-1.05-.7-1.55-.45-.9-1.25-1.35-1.35-.8-1.75-1.2-.45-.5-.45-1.45V4.4v-.35q0-.3.15-.6z"
                    id="LegPan017__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M9.05.85Q9 .65 9 .5L6.2 0q-.45.9-.9 1.5-1.25 1.65-3.6 1.65-.55 0-1.7-.15v2.1q.8 0 1.9-.1 2.5-.2 2.5 1.25 0 .75-.9 1.25-.8.4-1.7.4-.85 0-1.8-.55v3q.8-.15 1.55-.35.6-.2 1.2-.35.5-.1 1.05-.3.65-.25 1.75-.7.75-.3 1.35-.9l.9-1.05q.25-.3.5-.75.25-.6.5-1.4.15-.6.25-1.1 0-.05.05-.05v-.1q.05-.6.05-1.05 0-.8-.05-.95-.05-.3-.05-.45m25.1 6.35q-1.85-.7-3.25-.7-2.55 0-4.65 2.05-2.1 2-3 2-.7 0-2.3-.4-.3-.05-.55-.15l-1.45-.3q-.85 0-1.6.5-.75.75-1.5 2.1-1.2 2.15-1.25 3.05-.05.1-.1.25-.1.25-.15.55l-.2.5v.05q2.1-.2 4.2-.3.15-.35.3-.65.9-1.85 2.4-1.85l3.45.6q1.5 0 2.65-2.1 1.15-2.05 2.8-2.05 1.6 0 1.6 3.85 0 .9-.15 2.15 4.8.2 9.5.7-.8-.45-1.45-2.35-.3-.8-.6-1.55-.15-.4-.35-.75-.45-.95-1-1.75-.65-.9-1.3-1.65-.95-1.05-2.05-1.8m69.8-4.8v-.05q-3.059.401-6.15.75.05.6.05 1.4 0 2.2-.5 4-.05.45-.15.85-.55 2.75-2 2.75-.75 0-1.75-2.4-1-2.45-3-2.45-.95 0-1.8.45-.85.4-1 .4-.75 0-1.1-.7-.15-.15-.25-.35-.1-.3-.1-.8-.05-.4-.05-.9 0-.1.05-.2-.05-.25-.05-.5v-.5q-1.9.15-3.8.25-.25 1.2-.5 2.6-.7 2.65-1.9 2.65-.75 0-1.2-.5-.5-.55-.5-1.45 0-1.25.45-1.8.65-.7 1-1.35-1.35.1-2.7.15-.15.25-.3.7l-.05.05-.2.4q-.2.2-.4.45-1.35 1.65-1.45 2.85.1 1.1.95 2.15.8 1.05 1.5 1.6 3.5 3.25 7.4.7.2-1.75.95-2.65.55-.7 1.35-.9.3-.05.65-.05.25 0 .6.2 1.85.65 6.9 5.15.1.1.25.2h.05l.15.25v.05q.15.05.25.15.25-.5 1.1-1.1 1.5-1.1 3.05-1.1 1.9 0 4.5 1.5.9.55 1.6 1.05l3.7.2v-.05q.05-.15.1-.25-.05-.45-.1-.85-.25-2.25-1-4.25-.35-.75-.7-1.35-.3-.45-2.4-2.5-.9-.9-1.3-1.65 0-.05-.05-.1-.35-.55-.35-1.65 0-.7.2-1.5z"
                    id="LegPan017__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M29.4 10.25q-.1-.25-.2-.35-.2-.35-.3-.55-.15-.2-.3-.35-1.15-1.1-1.85-1.95-.8-.95-1.3-2.1-3.15-.3-6.3-.65-.15-.05-.3-.05h-.1l-.2.1q-.15-.05-.3-.05-2.1 0-3.65.65-1.35.55-1.6 1.2-.75 1.55-1.05 2.3-.35.2-.85.2-1.05 0-1.4-.9-.3-.35-.5-.95l-.1-.2q-.1-.3-.15-.65-.15-.55-.25-1.2-.15-.8-.2-1.75v-.05Q4.6 2.35.7 1.7q0 .15.05.35 0 .15.05.45.05.15.05.95Q.85 3.9.8 4.5v.1q-.05 0-.05.05-.1.5-.25 1.1-.25.8-.5 1.4l.8-.8q.7-.7 2.25-1Q4.6 5 5.4 5.2h.05q1.45 0 1.4 2.25Q6.6 8.75 5.6 10q-1.7 2.2-1.7 4 0 1.05.7 2.35.65 1.1 1.25 1.5l.2-.5q.05-.3.15-.55.05-.15.1-.25.05-.9 1.25-3.05.75-1.35 1.5-2.1.75-.5 1.6-.5l1.45.3q.25.1.55.15 1.6.4 2.3.4.9 0 3-2 2.1-2.05 4.65-2.05 1.4 0 3.25.7 1.1.75 2.05 1.8.65.75 1.3 1.65.55.8 1 1.75.1-1.55-.8-3.35m57.85 7.25q0-.15.05-.25v-.05q-.1-.1-.25-.15V17l-.15-.25h-.05q-.15-.1-.25-.2-5.05-4.5-6.9-5.15-.35-.2-.6-.2-.35 0-.65.05-.8.2-1.35.9-.75.9-.95 2.65-.1.75-.1 1.7V16.65q0 .3.05.65.05.4.05.5v.5q5.45-.6 11-.8h.1M117.35.05l.05-.05-1.5.3q-.4.35-.95.8-.35.25-.75.6h-.05q-1.8.8-3.4 1.8-.9.55-1.6 1.15-.4.25-.65.6-.4.35-.6.7-.15.25-.2.45-.15.3-.15.6v.5q0 .95.45 1.45.4.4 1.75 1.2.8.45 1.25 1.35.3.5.7 1.55.75 1.5 1.3 4.05.15.65.3 1.05l.1.5q-.1.15-.1.3 2 .3 4.05.65v-4.65q-.65-.25-1.15-.55-1.35-.7-1.35-1.4 0-.95.45-1.65.55-.9 1.6-1.05h.45V8.6h-.95q-1.6 0-2.8-.4-1.5-.55-1.5-1.45 0-.75.75-.95.5-.1 1.85-.1.85-.1 1.95-.45.4-.1.7-.25V.05z"
                    id="LegPan017__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan017__Symbol_31_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan017__col1n"
            />
            <use
                xlinkHref="#LegPan017__Symbol_32_0_Layer0_0_FILL"
                transform="translate(132.05 313.2)"
                id="LegPan017__col1d"
            />
            <use
                xlinkHref="#LegPan017__Symbol_33_0_Layer0_0_FILL"
                transform="translate(129.7 311.45)"
                id="LegPan017__col2n"
            />
            <use
                xlinkHref="#LegPan017__Symbol_34_0_Layer0_0_FILL"
                transform="translate(138 310.25)"
                id="LegPan017__col2d"
            />
        </g></g>
    )
}

export default LegPan017
