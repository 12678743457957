import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000"
}

function ObjToy010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4.1 7.4Q8.2 8.45 14 8.45q5.8 0 9.9-1.05 3.55-.85 4-2.05V0H0v5.25q.35 1.3 4.1 2.15z"
                    id="ObjToy010__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M27.9 3.05q-.45-1.2-4-2.05-4.1-1-9.9-1-5.8 0-9.9 1Q.35 1.9 0 3.2v.5q.35 1.25 4.1 2.15.55.15 1.15.25Q9 6.85 14 6.85t8.75-.75q.6-.1 1.15-.25 3.55-.85 4-2.05v-.75z"
                    id="ObjToy010__Symbol_87_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy010__Symbol_104_0_Layer0_0_FILL"
                transform="translate(75.9 371.65)"
                id="ObjToy010__col1n"
            />
            <use
                xlinkHref="#ObjToy010__Symbol_87_0_Layer0_0_FILL"
                transform="translate(75.9 367.85)"
                id="ObjToy010__col1l"
            />
        </g></g>
    )
}

export default ObjToy010
