import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col2n": "#999999"
}

function AccMor008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M14.4 35.4q9.75-19.55-6.3-34.25-2-1.55-3.6-1-.9.55-1.65 1.2-1.5 1.35-2.3 3.1-.8 1.8-.4 4.45 11.1 8.25 10.4 26.5-.15 3.6-1 7.6 3-3.9 4.85-7.6m107.45 0q-.7-18.25 10.4-26.5.4-2.65-.4-4.45-.8-1.75-2.3-3.1-.75-.65-1.65-1.2-1.6-.55-3.6 1-16.05 14.7-6.3 34.25 1.85 3.7 4.85 7.6-.85-4-1-7.6z"
                    id="AccMor008__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    id="AccMor008__Symbol_106_0_Layer0_0_1_STROKES"
                    stroke="#F3C"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M140.45 8.3l.1.1 7.5 7.9"
                />
                <g
                    id="AccMor008__Symbol_106_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M23.6 8.55q.1.1.25.2-.1-.15-.2-.25-.75-1-1.75-1.75L14.4 0q-3.65.9-5.95 2.75-4.45 3.1-6.6 15-1 5.65-1.5 13.3Q-.75 43.4 1.6 53q.15-.45.25-.9Q8.4 28.55 15.9 16.5q0-.094-.2.05l7.9-8M162.3 53q2.35-9.6 1.25-21.95-1.5-23.75-8.1-28.3Q153.15.9 149.5 0L142 6.75q-.85.65-1.55 1.55l.1.1 7.5 7.9q-.05.1-.05.2 7.65 12.3 14.3 36.5z" />
                    <path d="M140.05 8.75q.25-.2.5-.35l-.1-.1q-.2.2-.4.45z" />
                </g>
            </defs>
            <g transform="translate(110.55 221.6)" id="AccMor008__col1n">
                <use xlinkHref="#AccMor008__Symbol_106_0_Layer0_0_FILL" />
                <use xlinkHref="#AccMor008__Symbol_106_0_Layer0_0_1_STROKES" />
            </g>
            <use
                xlinkHref="#AccMor008__Symbol_17_0_Layer0_0_FILL"
                transform="translate(126.3 229.2)"
                id="AccMor008__col2n"
            />
        </g></g>
    )
}

export default AccMor008
