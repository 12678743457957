import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#43A6FF",
    "col1s": "#00539F",
    "col2n": "#FFFFFF"
}

function ShiSpe009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.55 48.45q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.4-1.65-.85-3.3l-.4-1.6q-.75-2.7-1.6-5.3-.65-2-1.3-3.9-.45-1.25-.85-2.45-1.8-4.8-3.85-9.25-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-18.1 12.5-40.15 15.45-2.85.35-5.7.55l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-2.7-.2-5.35-.55-22.25-2.85-40.5-15.45L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-2 3.65-3.8 7.5-2.05 4.45-3.8 9.25Q6 32 4.8 35.8q-.85 2.85-1.65 5.8-.45 1.65-.85 3.3-.05.15-.1.7l-.2.8q-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q1.7-1.5 3.8-2.35 1.5-.7 3.25-1 .15-.05.3-.05l1.85-.2q.05-.05.15 0 7.95-.3 12.45 4.65h.1l-2.85 11.8q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6.95.45 1.8 1.1.65.4 1.25.95V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.4-1.8-.75-3.55m-81.1-18.3q.049-.001.05-.05v.05h-.05m.35 0v-.05q.001.049.05.05h-.05z"
                    id="ShiSpe009__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.45 35.3q-.2.1-.25.3L0 57.05v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1m111 .15q-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15m-2.15-20.2q-.1-.1-.2-.1h-9.5q-.1 0-.15.1-.1.05-.1.15v20.25H95.4V25.9q0-.1-.05-.15-.1-.1-.2-.1H88.4v21.6h-6.5V35.9q0-.1-.05-.15-.1-.1-.2-.1h-9q-.1 0-.15.1-.1.05-.1.15v11.25h-6.5V36.4q0-.1-.05-.15-.1-.1-.2-.1h-10.4q-.1 0-.2.1-.05.05-.05.15v10.75h-6.5V35.9q0-.1-.1-.15-.05-.1-.15-.1h-9q-.1 0-.2.1-.05.05-.05.15v11.35h-6.5v-21.6h-6.75q-.1 0-.2.1-.05.05-.05.15v9.75h-7.95V15.4q0-.1-.1-.15-.05-.1-.15-.1H7.8q-.1 0-.2.1-.05.05-.05.15v10.25H.6V.2L.5.15Q-.05-.3.1.65V25.9q0 .1.05.2.1.05.2.05H7.8q.1 0 .15-.05.1-.1.1-.2V15.65h9V35.9q0 .1.05.2.1.05.2.05h8.45q.1 0 .15-.05.1-.1.1-.2v-9.75h6V47.5q0 .1.05.2.1.05.2.05h7q.1 0 .15-.05.1-.1.1-.2V36.15H48V47.4q0 .1.05.2.1.05.2.05h7q.1 0 .15-.05.1-.1.1-.2V36.65H60v52.2H60.9v-52.2h4.5V47.4q0 .1.1.2.05.05.15.05h7q.1 0 .2-.05.05-.1.05-.2V36.15h8.5V47.5q0 .1.1.2.05.05.15.05h7q.1 0 .2-.05.05-.1.05-.2V26.15h6v9.75q0 .1.1.2.05.05.15.05h8.45q.1 0 .2-.05.05-.1.05-.2V15.65h9V25.9q0 .1.1.2.05.05.15.05h7.45q.1 0 .2-.05.05-.1.05-.2V.75q-.35-.8-.5-.65v25.55h-6.95V15.4q0-.1-.05-.15z"
                    id="ShiSpe009__Symbol_83_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M21.35.55l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2L5.35.55Q2.65.35 0 0l13.55 13.6v-.1L27.05 0q-2.85.35-5.7.55m-8 9.45v.05h-.05q.049-.001.05-.05m.35.05h-.05V10q.001.049.05.05z"
                    id="ShiSpe009__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    d="M186.7 310.75q0-.2-.05-.45-.15-.5-.5-.85-.5-.55-1.3-.55-.75 0-1.3.55-.35.35-.45.85-.05.25-.05.45 0 .75.5 1.3.55.5 1.3.5.8 0 1.3-.5.55-.55.55-1.3m-.55-14.6q-.5-.5-1.3-.5-.75 0-1.3.5-.45.45-.45 1.05-.05.15-.05.25 0 .8.5 1.3.55.55 1.3.55.8 0 1.3-.55.55-.5.55-1.3v-.25q-.1-.6-.55-1.05m.55-11.9q0-.8-.55-1.3-.5-.55-1.3-.55-.75 0-1.3.55-.5.5-.5 1.3 0 .75.5 1.3.55.5 1.3.5.8 0 1.3-.5.55-.55.55-1.3z"
                    id="ShiSpe009__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe009__Symbol_84_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe009__col1n"
            />
            <use
                xlinkHref="#ShiSpe009__Symbol_83_0_Layer0_0_FILL"
                transform="translate(132.2 228.65)"
                id="ShiSpe009__col1s"
            />
            <use
                xlinkHref="#ShiSpe009__Symbol_82_0_Layer0_0_FILL"
                transform="translate(179.15 248.7)"
                id="ShiSpe009__col2n"
            />
            <use xlinkHref="#ShiSpe009__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe009
