import { AssetSvgProps } from "../../../shared/assets"

function AccHat129({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccHat129__Layer16_0_FILL">
                    <path
                        fill="#D19532"
                        d="M54.9 172.7q-1.1.05-2.4.4-.15.05-.4.15-.55.2-1.15.4-3.75 1.5-5.9 5.2-1.2 2.1-1.65 4.4-2.95 3.05-2.2 6 .2.9.8 1.6 1.4 1.8 4.7 2.6 1.25 1.25 2.85 2.2 2.5 1.35 5.1 1.55 1.85.25 3.8-.3.15-.05.4-.05 1.2-.35 2.3-.9 1-.45 1.8-1.1 1.95-1.4 3.35-3.8 1.05-1.7 1.35-3.7 2-2.05 2.3-3.95.1-.95-.1-1.85-.7-2.6-4.25-3.85-1.45-2-3.8-3.4-3.35-1.85-6.9-1.6m183.65-21.75q-.2 0-.45.05-.55 0-1.1.1-4.05.45-7.15 3.45-1.7 1.75-2.7 3.9-3.7 2.15-3.7 5.15 0 .95.4 1.8.85 2.05 3.9 3.7.9 1.5 2.1 2.75 2.1 2.05 4.55 2.9 1.75.7 3.75.7h.4q1.3 0 2.45-.2 1.1-.2 2-.6 2.35-.9 4.25-2.8 1.4-1.4 2.3-3.15 2.45-1.4 3.2-3.3.35-.9.35-1.75 0-2.7-3.05-4.85-.9-2.3-2.8-4.25-2.75-2.7-6.2-3.3-.7-.15-1.35-.2-.55-.1-1.15-.1m87.6 20.4q-.45-.2-.85-.3l-.4-.1q-.15-.05-.25-.05-4.5-1-8.65 1.35-2.1 1.25-3.65 3.05-4.1 1.15-4.9 4.05-.25.85-.1 1.8.25 2.25 2.8 4.6.45 1.65 1.35 3.25 1.45 2.5 3.6 3.9 1.55 1.15 3.45 1.75l.4.1q1.25.3 2.4.4 1.1.1 2.1-.05 2.45-.25 4.8-1.6 1.75-1 3.05-2.5 2.75-.75 3.95-2.35.6-.75.8-1.6.75-2.65-1.7-5.5-.25-2.5-1.6-4.8-1.95-3.3-5.15-4.85-.7-.35-1.45-.55z"
                    />
                    <path
                        fill="#00F"
                        d="M172.8 89.6v.1q-9.65-.65-23.6 2.95-13 3.35-25.8 9.3-12.9 6-20.85 12.45Q94 121.3 94 126.6q0 .083.1.8-3.512 2.725-2.35 1.9-.55.6-1.05 1.15-4.65 4.45 0 9.2 4.2 3 10.85-.9 2.25-1.15 5.25-3.05 61.05-33.45 130.4-14.3-.046-1.295-.1-2.6 1.06.741 2.5 1.6 5.443 3.24 10.45 3.55-.182.66-.4 1.3 13.55 4.65 27.45 11.25l8.8 3.9q4.4 2.35 7.6.05 4.75-3.85 0-9.15l-.8-.7q0-.05-1.1-1.05.2.1.05-.15-.145-.155-.45-.45v-.2q0-5.75-7.1-12.6-6.85-6.65-18.5-12.55-26.45-13.45-54.8-14h-38m91.9 22.25q-.108-.251-.35-.6 2.715 1.46 4.95 3.1-2.295-1.259-4.6-2.5z"
                    />
                </g>
                <g id="AccHat129__Layer16_2_FILL">
                    <path
                        fill="#F03"
                        d="M165 93.8q.35.3.65.65-.2-.35-.35-.7-9.85-16.9-14.45-24.5-.6-1.05-1.2-2.05-4.85-7.9-9.95-14.35-1.8-2.25-3.65-4.4Q112.35 21.1 82.7 22.4q-3.6-.2-7.35.25Q46.3 25.4 31.5 46.25q-26.6 36.3 19.45 127.4.6-.2 1.15-.4.25-.1.4-.15-5.6-39.4-5.7-55.65.1-1.2.25-2.35l.25-1.9q4.15-31.55 25-23.85 13.85 6.2 20.35 39.15-1.25 1-1.95 1.55v.05q.45-.35 1.05-.8-1.55 1.1 5.25-4.1 21.85-16.5 44.35-24.9.2-.1.4-.15 2.25-.8 4.5-1.55 5.8-1.9 11.7-3.3.25-.1.5-.15 3.25-.75 6.55-1.35z"
                    />
                    <path
                        fill="#02DB02"
                        d="M334.1 118.9v.05q-2.55 21.55-9.45 51.95.1 0 .25.05l.4.1q.4.1.85.3 52.4-88.8 25.8-125.1-14.8-20.85-43.85-23.6-4.65-.55-9-.15l-1.25.15q-23.1.55-40.8 17.5 2.1 5.5 3.2 11.9 3.85 22.2-5.1 55.15 13.3 6.1 26.75 14.65 2.25 1.6 4.55 3.35 4.5 3.45 5.2 4.2 1.3-8.75 2.95-18.25 5.75-16.35 21.5-21.25 15.75-4.95 18 29m.05-.25l.2-3.5q.7-2.35-.2 3.5z"
                    />
                </g>
                <g id="AccHat129__Layer16_3_FILL">
                    <path
                        fill="#6D4D01"
                        d="M60.05 182.25q-1.15.2-.8 2.95.55 2.35 0 7.25-.15 1.9-.7 4-.05.3-.1.45.15-.05.4-.05 1.2-.35 2.3-.9.05-.15.05-.35.6-2.2.85-4.15Q62.4 187 62 184q-.35-1.95-1.95-1.75m-5.15-9.55q-1.1.05-2.4.4-.15.05-.4.15.25.25.5.45 1.15 1.1 1.45 1.5 1.7 1.1 2.45.55.5-.2.2-1.2-.3-.45-1.8-1.85m187.05-8.4q-.1 2.5-1.85 7.1-.7 1.8-1.7 3.65-.1.25-.25.4h.4q1.3 0 2.45-.2.15-.15.25-.4 1.05-1.9 1.8-3.75 1.5-4.2 1.9-7.2.2-1.9-1.5-2.15-1.15-.1-1.5 2.55m-2.4-10.9q1.3 1.45 2.2 1.2.55-.1.5-1.15-.2-.5-1.2-2.2-.7-.15-1.35-.2-.55-.1-1.15-.1-.2 0-.45.05.65.9 1 1.55.35.55.45.85m88.55 31.8q.65-1.85-.85-2.5-1.1-.4-2.15 2.15-.7 2.35-3.65 6.3-1.1 1.55-2.55 3.1-.15.25-.35.4l.4.1q1.25.3 2.4.4.15-.1.3-.3 1.55-1.6 2.75-3.25 2.55-3.6 3.7-6.4m-2.8-14.15l-.4-.1q.3 1 .5 1.65.15.7.2 1 .9 1.8 1.85 1.75.5.05.75-1-.05-.5-.6-2.45-.7-.35-1.45-.55-.45-.2-.85-.3z"
                    />
                    <path
                        fill="#E0B774"
                        d="M58.7 176.85q-.15.05-.3 0h-.05l-2.75.45q-1 .2-2.1.55-.15 0-.3.05h-.05q-5.65 1.6-9.15 4.9-.3.25-.6.45-2.95 3.05-2.2 6 .2.9.8 1.6 1.4 1.8 4.7 2.6-1.65-1.85-2.55-4.35-.35-1.8.5-2.65.9-.8 1.5-1.25 3.35-2.65 8.3-3.95 1.5-.4 3-.7l2.75-.25v.05q1.55-.1 2.95.1 1 .2 2.15.45t1.65.7q.55.45 1 1.65.45 1.3-.3 4.1 2-2.05 2.3-3.95.1-.95-.1-1.85-.7-2.6-4.25-3.85-.65-.2-1.35-.35-2.6-.6-5.55-.5m179-21.1q-.5 0-.95.05-5.15.25-8.9 2.25-.3.25-.7.4-3.7 2.15-3.7 5.15 0 .95.4 1.8.85 2.05 3.9 3.7-1.25-2.15-1.4-4.9.15-1.75 1.2-2.35 1.05-.55 1.75-.85 3.85-1.7 9-1.65 1.7-.05 3.1.1l2.8.5q1.4.3 2.75.85.95.4 1.95.95 1 .6 1.4 1.2.45.45.55 1.8.1 1.45-1.3 3.95 2.45-1.4 3.2-3.3.35-.9.35-1.75 0-2.7-3.05-4.85-.6-.35-1.3-.75-2.25-1.25-5.2-1.8-.15-.05-.2-.1l-.1-.05-2.75-.3q-1.1-.05-2.2-.05h-.6m91.05 21.65q-.1-.05-.2-.15l-.05-.05-2.6-1q-1-.35-2.1-.55-.1-.05-.2-.05-5.8-1.6-10.5-.45-.35.1-.75.15-4.1 1.15-4.9 4.05-.25.85-.1 1.8.25 2.25 2.8 4.6-.55-2.45-.1-5.05.65-1.75 1.75-2 1.15-.25 2-.4 4.15-.55 9.1.75 1.55.4 2.95.9l2.55 1.15v.05q1.3.7 2.45 1.55.8.65 1.65 1.45.8.85 1.05 1.45.3.65.05 1.95-.25 1.35-2.3 3.45 2.75-.75 3.95-2.35.6-.75.8-1.6.75-2.65-1.7-5.5-.45-.5-1-1-1.95-1.8-4.6-3.15z"
                    />
                    <path
                        fill="#B80101"
                        d="M71.95 70q-15.3-3.25-21.85 14.75-4.9 17.7-2.8 28.45 4.15-31.55 25-23.85 13.85 6.2 20.35 39.15Q87.3 73.2 71.95 70z"
                    />
                    <path
                        fill="#006"
                        d="M293.5 140.45q-2.45-.15-5.35-1.65-18.75-11.6-36.85-18.65-.8 2.6-1.65 5.1 13.55 4.65 27.45 11.25l8.8 3.9q4.4 2.35 7.6.05m-56.3-19.05q-.1-2.8-.15-5.55-79.85-19.8-138.5 21.5-.5.35-.95.6-3.9 2.35-6.9 1.7 4.2 3 10.85-.9 2.25-1.15 5.25-3.05 61.05-33.45 130.4-14.3z"
                    />
                    <path
                        fill="#FFFFD5"
                        d="M149.65 67.2q.6 1 1.2 2.05 5.2-39.9 40.2-55.7-39.8 10.2-51.35 39.3 5.1 6.45 9.95 14.35z"
                    />
                    <path
                        fill="#FE5656"
                        d="M165.65 94.45q-.2-.35-.35-.7-9.85-16.9-14.45-24.5-.6-1.05-1.2-2.05-4.85-7.9-9.95-14.35-1.8-2.25-3.65-4.4Q112.35 21.1 82.7 22.4q-1.5.05-3.1.2 22.05.05 40.45 24.3 12.9 17.1 26.2 51.7 5.8-1.9 11.7-3.3.25-.1.5-.15 3.25-.75 6.55-1.35.35.3.65.65z"
                    />
                    <path
                        fill="#FBDD0F"
                        d="M199 65.25q-5.35 11.8-.1 26.8 15.95 1.6 26.3 4.3 5.2 1.35 10.5 3.1-1.4-11.45-3.85-20.6-2.95-10.9-6.5-14.55-14-14.2-26.35.95z"
                    />
                    <path
                        fill="#75F262"
                        d="M260.25 52.05q15.5-26.75 37.6-29.4-23.1.55-40.8 17.5 2.1 5.5 3.2 11.9z"
                    />
                    <path
                        fill="#390"
                        d="M334.1 118.9v.05l.05-.3.2-3.5q5.45-19.8-1-31.85-5.5-15.25-20.55-11.35-12.05 3.1-18.2 39.2 5.75-16.35 21.5-21.25 15.75-4.95 18 29z"
                    />
                </g>
                <path
                    fill="#FF0"
                    d="M257.05 40.15q-2.75-7.2-7.35-12.9-17.75-18-45.65-16-6.85.15-13 2.3-39.8 10.2-51.35 39.3.453.572.9 1.15-.6 1.209-.6 2.15 0 3.4 1.7 9.85 1.75 6.75 3.4 8.9 1.6 2.05 7.8 10.9 6.3 7.8 10.5 7.8.928 0 1.65-.25.13.194.25.4 10.85-2 21.9-2.15 8.65-.1 11.7.45 15.95 1.6 26.3 4.3 5.2 1.35 10.5 3.1.9 7.7 1.35 16.4.05 2.75.15 5.55.45 13.65-.2 29.7.55-.1 1.1-.1.25-.05.45-.05.6 0 1.15.1 5.7-13.5 9.95-25.8.85-2.5 1.65-5.1 2.15-6.65 3.85-12.95.352-1.297.65-2.6.649.2 1.4.2 5.05 0 6.8-18.2.8-18.45.8-19.05.1-5.25 0-7.45-.1-3.4-1.2-8.6-1.1-5.35-2.3-8.1-1.975-3.213-4.1-2.8-.058-.21-.15-.45z"
                    id="AccHat129__Layer16_1_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat129__Layer16_0_FILL" />
            <use xlinkHref="#AccHat129__Layer16_1_FILL" />
            <use xlinkHref="#AccHat129__Layer16_2_FILL" />
            <use xlinkHref="#AccHat129__Layer16_3_FILL" />
        </g></g>
    )
}

export default AccHat129
