import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4C7E00",
    "col1n": "#6EB800"
}

function BodTai004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.15 21.75q.3-2.05.3-4.95 0-.85-.05-1.7-.55-9.15-6.9-15.1.15 1.8.2 4.75 0 7.15-2.15 12-.95 2.1-2.25 3.75-1 1.25-2.25 2.4Q107 36 83.5 32.15q-3.3-.5-6.7-1.4-4.65-1.25-9.45-3.3-3.95-1.65-8.05-3.9-3.6-2.05-4.35-2.45-9.6-4.95-14.4-6.75-.7-.25-1.45-.5-23.4-9.2-34.9-.15-1.4 1.05-2.6 2.4-.4.6-.65 1.3-1.1 2.3-.95 4.8-.4 5.5 8.5 8.5.1 0 .2.05 1.6.45 9.95 2.35 6.55 1.5 10.4 2.9 2.5.95 5.95 2.3 36.2 16.3 69.55 7.35 21.55-4.85 27.6-23.9z"
                    id="BodTai004__Symbol_7_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M132.45 3.1q0-.85-.05-1.7-.8 16.25-24.75 24.75-28.8 6.95-49.7-3.65l-.65-.3q-1-.5-1.95-.95Q29.55 8.75 6.9 7.05-1.6 5.15 4.2 0 2.8 1.05 1.6 2.4q-.4.6-.65 1.3Q-.15 6 0 8.5-.4 14 8.5 17q.1 0 .2.05 1.6.45 9.95 2.35 6.55 1.5 10.4 2.9 2.5.95 5.95 2.3 36.2 16.3 69.55 7.35 21.55-4.85 27.6-23.9.3-2.05.3-4.95z"
                    id="BodTai004__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai004__Symbol_7_0_Layer0_0_FILL"
                transform="translate(232.85 285.9)"
                id="BodTai004__col1n"
            />
            <use
                xlinkHref="#BodTai004__Symbol_8_0_Layer0_0_FILL"
                transform="translate(232.85 299.6)"
                id="BodTai004__col1d"
            />
        </g></g>
    )
}

export default BodTai004
