import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#6D6D3B",
    "col2n": "#000000"
}

function ShiLon047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.95q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.3-6.05l-1.75 1.45q-1.35 1.05-2.8 2.1l-1.55 1.15Q118 17.4 95.6 20.2q-.8 1.25-2.9 3.1-2.85 2.5-6.6 4.95-3.75 2.4-3.9 2.5l-.05-.05q-.8-.3-1.55-.6-9.25-3.85-12.5-8.55l-.1-.1q-.15-.15-.2-.35l-.6-1.2q-.2-.05-.35-.05-20.9-3.2-38.2-15.15L27.1 3.55q-1.45-1.05-2.8-2.1L22.45.05q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95Q42.6 86.9 64 88.4l7.45.4 7.5.1 2.4.05.3-.1.05-10.8q.5.05.95.1.05 0 .15.05l.8.1 2 .3q.15.05.25.05t19.65 2.85q19.55 2.8 32.25 1.9 3.05-.65 6.05-1.35 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05L143 57.5q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.25q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5M82 41.2q.6.1 1.15.2 10.9 1.9 9.3 12.2-.15.85-.35 1.7-.85 3.15-3.3 5.8-1.75 1.85-3.9 2.9-.6.3-1.2.5-.05 0-.1.05-.85.2-1.8.5L82 41.2z"
                    id="ShiLon047__Symbol_318_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.25 42.2q-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65m-2.2-6.7q-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3M4.5 10.1q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3M.65 3.85q.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6 0-.95-.65-1.6Q3.2 0 2.25 0 1.3 0 .65.65 0 1.3 0 2.25q0 .95.65 1.6z"
                    id="ShiLon047__Symbol_213_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon047__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon047__Symbol_318_0_Layer0_0_FILL"
                transform="translate(110 228.55)"
                id="ShiLon047__col1n"
            />
            <use
                xlinkHref="#ShiLon047__Symbol_213_0_Layer0_0_FILL"
                transform="translate(183 259.35)"
                id="ShiLon047__col2n"
            />
            <use xlinkHref="#ShiLon047__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon047
