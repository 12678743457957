import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#479EEC",
    "col1n": "#77BFFF",
    "col1s": "#1074CB"
}

function AccHat056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M351.6 141.8q-6.2-2.8-11.5-4.65-7.7-2.7-13.6-3.45-.25-.05-.5-.05-17.4-9.1-31.85-15.1l-.05.05q-7.35-2.6-14.05-4.5-1-.25-1.95-.45-2.5-.55-4.9-1.1-1.15-1.35-2.4-2.6-3.4-3.15-7.5-5.2l.25-.45q-1.9-20.25-15.1-30.35-3.2-2.5-17.9-10.3-6.55-3.5-14.7-5.9-1.8-.75-3-1.15-5.25-1.65-9.35-2.5-4.85-1.05-11.5-1.8l-2.95-.05q-1.75-.05-3.55-.45-3.6-.8-4.4-2.5-.15-.25-.7-1.1-.55-.9-1.15-1.95-.65-1.1-1.5-1.95-2.8-3.1-9.55-6.6-9.75-4.95-11.1-5.5-1.35-.55-2.1-1.4-.75-.85-1.3-1.8-2.6-4.4-6.05-8.3-3.15-3.55-7.6-6.6-4.2-3-10.5-5.55Q125.05 6.7 117 2.6q-8.1-4.15-13.55-1.65Q98 3.4 91.45 4.1q-6.55.6-14.6 1.2-8.05.55-14.1 1.75-.25-.05-.4-.1-1.15-.25-2.5-.5-5.6-1.2-18.15-5.6 20.9 25.2 40.2 21.35 15.9-3.2 18.25-4.1l.05.05q0-.05.05-.05h.1q-.1.1-.1.25-.15.05-.25.1-.8 1.25-1.55 2.5l-.2.4q-2.45 4.15-4.2 7.95-6.85 14.65-3.6 23.85 1.25 4.55 4.05 8.05 2.8 2.05 4.85 4.05.85.85 1.6 1.7.1.05.15.15 2.65 5.8-1.95 11.6l-6.6 8.55q-10 13.5-15.9 25.8-.1.15-.15.3-.1.15-.15.3-7.5 13-21.35 25.15-4.25 2.95-8.15 4.15-3.2.7-6.25 1.55-9.7 2.35-18.25 6.25Q10 156.35 0 165.2v5.6q11.6-1.65 22.35-3.95 31.7-6.75 56.2-19.55 4.45-1.55 9.15-3.6.2-.1.4-.15 1.85-.8 3.7-1.65 22.3-6.45 39.15-3 .35.1.8.2 1.55.1 3.1.25 17.65 1.5 30.95.7 5.2-.3 9.8-.95 10.15-1.5 16.85-4.8 23.25-11.35 47.05-7.95 1.75.2 3.55.55 2.95.25 5.9.6 5.2.6 10.2 1.55 2.25.4 4.45.9 4.6 10.95 17.65 17.25l.15-.2q.1 0 .2-.05v.4q17.7 5.8 58.5 1.45 5.9-.6 12.3-1.45.15-2.65-.8-5.55z"
                    id="AccHat056__Symbol_274_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M110.4 5.35q-2.85 1.05-5.65 2.05.2-.1.4-.25 3.7-2.8 4.9-7.15-2.3 2.65-3.65 3.5-1.4.7-2.1 1.2-.75.45-1.35.8-.6.4-1.35.75-.8.4-1.3.6l-.25.4q-1.3 1.95-1.55 2.5h-.05l-.2.4q-2.45 4.15-4.2 7.95-6.85 14.65-3.6 23.85Q91.7 46.5 94.5 50q2.8 2.05 4.85 4.05.7-2.75 1.25-5.55.2-1.1-.9-1.25-7.05-1.15-6.05-5.3.25-.9 1.15-2 2.25-2.85 2.65-6-.85-.35-1.5-.95-3.55-3.3 2.3-6.1-2.3-5.3 4-9.45-3.65.9-7.45.9.75-1 1.95-1.9 1.1-.75 1.95-1.65 3.9-4.25 11.3-6.85 4.95-1.7 9.9-2.6-5.7-1.55-9.5 0m22.45 43.15h.15q.7-.1 1.4-.35-.75-.1-1.5-.15-4.85-.4-9.7-.9-2.05-.2-3 .85-4.1 4.3-9.2 8.3-1.15.95-3.25.95-3.8.05-6.6-1.25 2.6 5.8-2 11.55l-3.75 4.9-.1.05.3.1q4 2.4 9.05 0 22-5.7 44-7.7.05-.05.15-.05h.4q.3-.05.6-.05h.05q3.15-.5-.1-.8-4.8-.6-8.3-3.15-4.45-3.25-7.35-.75-6.2 5.2-12.55 1.95-4.1 2.25-9.4 4.15-.05.05-.1.05v-.05-.4q.05-.25.1-.55.4-1.7 1.1-2.65l1.25-1.5q3.75-3.15 6.4-6.95 3.6-5.05 11.95-5.6m130.7 44.6q-1.9-20.25-15.1-30.35-3.2-2.5-6.75-4.3Q259.25 71.5 247.2 90l-.1.2-5.55.2q-30.9 1-60.5-3.85 13.2 6.65 51.7 7.3l-4.75.5q-9.6 1.1-11 4.7 13.1-4.1 36.7-1 9 1.1 19.5 3.3-1.15-1.35-2.4-2.6-3.4-3.15-7.25-5.05v-.6m88.85 43.05q.15-2.65-.8-5.55-15.1-6.8-25.1-8.1-.25-.05-.5-.05-5.95-.75-10.05.5-10.65-1-21.85-3.9-.25-.1-.45-.15v-.1q-3.8-.8-7.6-1.7-13.85-3.4-23.3-4.75-3.5-.55-6.35-.75-10.8-.95-13.75-1.2-1.8-.35-3.55-.55-23.8-3.4-40.35 2.9-16.55 6.25-23.5 7.65-6.95 1.35-15.6 2.7-8.65 1.3-28.3-.55-.4-.1-.8-.15-16.85-3.5-39.15 2.95-2.05 1-4.05 1.85-18.85 8.15-34.55 8.35-6.8.1-12.15 1.15h-.05q-10.05 1.6-16.05 5.55Q8.7 152.55 0 154v5.55h.05q2.25-.35 5.15-1.4 7.75-2.8 19.85-10.6 5.85-3.85 15.55-5.5h.05q5.5-1.1 12.55-1.2 11.3-.15 24.15-4.35l1.2-.4q4.45-1.55 9.15-3.6.2-.1.4-.15 1.85-.8 3.7-1.65 22.3-6.45 39.15-3 .35.1.8.2 1.55.1 3.1.25 17.65 1.5 30.95.7 5.2-.3 9.8-.95 10.15-1.5 16.85-4.8 23.25-11.35 47.05-7.95 1.75.2 3.55.55 2.95.25 5.9.6 5.2.6 10.2 1.55 2.25.4 4.45.9.1 0 .2.05.25.05.55.1.1 0 .25.05.4.1.85.2 9.3 2.15 18 5.55 5.1 1.9 10.2 3.1v.1q.2.05.45.1 11.05 3.05 22.15.3 11.3-3.5 36.15 7.85z"
                    id="AccHat056__Symbol_182_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M21.45 176.3q-.1.25.05.45.35 2 1.9 3.9 1.35 1.65 3.1 2.35.1.1.25.2 1.6 1.2 3.6 1.5 3.95 1.6 10.4 1.6 10.15 0 16.65-3.1 5.25-3.4 6.7-4.1.894-.396 4.2-1.9 3.318-1.507 7.25-1.35 3.35-.25 6.55-.25 4.75.45 11.5.95l5.8-.25q-.45-2.3-.75-4.95-.45-9.65 1.3-18.5l-1.2.4q-12.85 4.2-24.15 4.35-7.05.1-12.55 1.2H62q-9.7 1.65-15.55 5.5-12.1 7.8-19.85 10.6-2.9 1.05-5.15 1.4m341.95-15.7q8.495-3.062 10.4-7.7-24.85-11.35-36.15-7.85-11.1 2.75-22.15-.3-.25-.05-.45-.1v-.1q-5.1-1.2-10.2-3.1-8.7-3.4-18-5.55-.45-.1-.85-.2-.15-.05-.25-.05-.3-.05-.55-.1-.1-.05-.2-.05 2.7 8.35 3.15 17.9v.85q0 .45.05.75v.55q0 .15.05.25v.4l2.25.1q5.8 0 11.05 1.75 4.606 1.547 7.6 2.55 28.224 11.956 54.25 0z"
                    id="AccHat056__Symbol_131_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat056__Symbol_274_0_Layer0_0_FILL"
                transform="translate(21.4 5.55)"
                id="AccHat056__col1n"
            />
            <use
                xlinkHref="#AccHat056__Symbol_182_0_Layer0_0_FILL"
                transform="translate(21.4 16.75)"
                id="AccHat056__col1d"
            />
            <use
                xlinkHref="#AccHat056__Symbol_131_0_Layer0_0_FILL"
                id="AccHat056__col1s"
            />
        </g></g>
    )
}

export default AccHat056
