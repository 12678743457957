import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function ShiSpe118({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M165.25 57.7v-1.15q.05-4.9.35-6.6.3-10.85-4.75-18.75-.1-.1-.15-.2l-.15-.2q-1.8-2.85-.8-7.5 1.1-4.95-1.6-12.3-1.9-5.3-8.45-8.7-.9-.5-1.9-.9-.9-.45-1.9-.8l-.3-.1q-.7-.3-1.45-.5l-2.2 2.05-1 .7q-1.1.85-2.3 1.75-.25.15-.5.35L136.6 6q-.25.15-.5.35Q115.9 20.1 90.75 22l-1.1.05q-3.1.2-6.3.2h-1.1q-3.2 0-6.3-.2l-1.1-.05Q49.7 20.1 29.5 6.35q-.25-.2-.5-.35l-1.55-1.15q-.25-.2-.5-.35-1.2-.9-2.3-1.75l-1-.7L21.45 0Q20.7.2 20 .5l-.3.1q-1 .35-1.9.8-1 .4-1.9.9Q9.35 5.7 7.45 11q-2.7 7.35-1.6 12.3 1 4.65-.8 7.5l-.15.2q-.05.1-.15.2Q-.3 39.1 0 49.95q.3 1.7.35 6.6v1.15q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q2.8-.15 5.2.4 1.9.45 3.5 1.25l.2.1.3.2q.75.4 1.4.9.15.1.4.2.85.5.4.3-.3-.2-.4-.2.05.05.15.1.8.65 1.5 1.45 11.65 1.8 20.85 2.45l2.3.15q19.35 2 27.75-.75 4.8-1.2 9.7-1.15 4.9-.05 9.7 1.15 8.4 2.75 27.75.75l2.3-.15q9.2-.65 20.85-2.45.7-.8 1.5-1.45.1-.05.15-.1-.1 0-.4.2-.45.2.4-.3.25-.1.4-.2.65-.5 1.4-.9l.3-.2.2-.1q1.6-.8 3.5-1.25 2.4-.55 5.2-.4l1.85.2q.15 0 .3.05.2.05.35.1.1 0 .2.05 1.7.35 3.2 1.05 1.8.85 3.3 2.15z"
                    id="ShiSpe118__Symbol_204_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M8.75 19.9q3.55-2.4 4.6-7.8.15-.75.15-1.5 0-.55-.05-1.05-1-6.2-5.65-8.4l-.2-.1q4.15 9.25 1.15 18.85M5 23.6q.15.2.35.45 2.25 3.3 2.15 7v.5q-.1 1.2-.4 2.4-1.3 6.6-4.3 10.75-.55.7-1.1 1.35L0 48.15q1.9.45 3.5 1.25l.2.1.3.2q.75.4 1.4.9.15.1.4.2.85.5.4.3-.3-.2-.4-.2.05.05.15.1.8.65 1.5 1.45L9.3 48.8q.85-1.55 1.5-3.15.05-.2.15-.35 1.65-4.5 1.6-9.8-.05-2.2-.4-4.5-.4-2.1-1.3-3.7-2-3.65-5.8-3.7H5m100.5 31.45l2.3-.15q2.45-.25 5.6-.55-13.65.6-22.75-.2-.4-.05-.75-.1-19.35-.95-21.25-12.25-.5-4.55-.6-3.15-.1-1.4-.6 3.15-1.9 11.3-21.25 12.25-.35.05-.75.1-9.1.8-22.75.2 3.15.3 5.6.55l2.3.15q19.35 2 27.75-.75 4.8-1.2 9.7-1.15 4.9-.05 9.7 1.15 8.4 2.75 27.75.75m9.25-45.9q2.2-4.55 6.6-9.15Q101.15 13.75 76 15.65l-1.1.05q-3.1.2-6.3.2h-1.1q-3.2 0-6.3-.2l-1.1-.05Q34.95 13.75 14.75 0q4.4 4.6 6.6 9.15 44.95 6.3 46.6 18.9v1.9q.05-.5.1-.95.05.45.1.95v-1.9q1.65-12.6 46.6-18.9m8 2.95q1.05 5.4 4.6 7.8-3-9.6 1.15-18.85l-.2.1q-4.65 2.2-5.65 8.4-.05.5-.05 1.05 0 .75.15 1.5m8.35 11.5h-.05q-3.8.05-5.8 3.7-.9 1.6-1.3 3.7-.35 2.3-.4 4.5-.05 5.3 1.6 9.8.1.15.15.35.65 1.6 1.5 3.15l1.85 3.65q.7-.8 1.5-1.45.1-.05.15-.1-.1 0-.4.2-.45.2.4-.3.25-.1.4-.2.65-.5 1.4-.9l.3-.2.2-.1q1.6-.8 3.5-1.25l-1.7-2.1q-.55-.65-1.1-1.35-3-4.15-4.3-10.75-.3-1.2-.4-2.4v-.5q-.1-3.7 2.15-7 .2-.25.35-.45z"
                    id="ShiSpe118__Symbol_205_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe118__Symbol_204_0_Layer0_0_FILL"
                transform="translate(109.65 227.25)"
                id="ShiSpe118__col1n"
            />
            <use
                xlinkHref="#ShiSpe118__Symbol_205_0_Layer0_0_FILL"
                transform="translate(124.4 233.6)"
                id="ShiSpe118__col1d"
            />
        </g></g>
    )
}

export default ShiSpe118
