import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M217.3 216.5q.081-.214 0-.45-.07-.19-.25-.35-.18-.103-.4-.1h-47.1q-.277.001-.5.2-.185.195-.2.45-.013.31.15.5l4 4.4h.05l2.8 3h-.05l12.8 13.9q.22.226.5.25.266.02.45-.15l27.5-21.3q.206-.137.25-.35z"
                    id="FacMou029__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M174.2 217l-.2 3.1.05.1 2.8 3 .1.1h5.9V217h-8.65m17.95 0h-8.5v6.3h8.5V217m9.7 0h-8.9v6.3h8.9V217m.8 6.3h3.8l4.55-3.5-.2-2.8h-8.15v6.3z"
                    id="FacMou029__Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou029__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou029__Symbol_54_0_Layer0_0_FILL"
                id="FacMou029__col1n"
            />
        </g></g>
    )
}

export default FacMou029
