import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#AC8268",
    "col2d": "#999999",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function ObjMor095({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M89.85 79.65q3.75-1.9 3.35-4.95-.15-1.2-1.1-2.75L54.75 3.35Q52.3-1.9 47.1 1.05L3.35 24.85q-4.8 2.5-2.7 6.75.2.5.45.9l20.25 37.2q.15-.5.35-.95.15-.45.4-.9.65-1 1.9-1.6 4.45-1.85 10.2 0 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-2.3.9-4.35.8l7.25 13.35q.55.9 1.3 1.4 2.25 2 5.8-.05 1.4-.7 2.1-1.1l41.7-22.7z"
                    id="ObjMor095__Symbol_238_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M23.45 4.35q-.4-.85-1.75-1l-5.55-.6q-.05.1-.1.15-1.3.7-2.35.4-.1 0-.1-.05-.625-.192-1.25-.6-.025.015-.05 0-.85-.45-1.45-1.35l-.1-.1h-.05Q10 .5 9.05.15q-.7-.2-1.4-.15Q7 .1 6.3.45V.5h-.05q-.6.4-1.05.85-.4.55-.65 1.25-.3 1.05 0 1.95-.05.05-.05.1l.1.1q.35.95.3 2 0 .253-.05.5-.022.435-.15.85-.05.05 0 .1-.45 1.1-1.65 1.8-.05.05-.1 0l-2.6 5Q-.3 16.15.3 16.95L1 18.4q.143.04.25.05-.038.759 0 1.75.021.56 0 .85.025.343-.55 2-.567 1.701.1 1.65.706 0 .75.2l14.5-6.5L29.4 9.8q-.096-.245.05-.75.199-.459-2.6-1.5-2.807-1.038-2.95-1.1.164-.36.3-.75l-.75-1.35M9.7 2.3l.3.55q.25.65.05 1.3-.25.85-1 1.25H9l-.1.1q-.7.35-1.55.15-.7-.2-1.1-.65l-.35-.65q-.2-.65 0-1.3.25-.8 1-1.2l.05-.05Q7 1.8 7 1.75h.05q.8-.45 1.55-.2.7.2 1.1.75z"
                    id="ObjMor095__Symbol_235_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M2 10.6q-.2-.4-.6-.5-.4-.15-.8.1-.35.2-.45.65-.15.35.05.7.25.4.6.5.45.15.85-.05.35-.25.4-.65.15-.4-.05-.75m26.05-5.2q-.15-.2-.3-.3-.2-.25-.45-.45L0 19.5q.05.3.1.65.05.15.1.35l.25.4q.2.55 1.15.25 15.62-2.922 26.2-14.3.7-.6.5-1.05l-.25-.4m-7.5-4.1q.2-.4-.05-.75-.15-.4-.6-.5-.35-.15-.75.05t-.5.65q-.15.35.05.7.25.4.65.5.4.15.8-.05.35-.2.4-.6z"
                    id="ObjMor095__Symbol_236_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.25 3.45q.35.95.3 2 1.5 3.2-1.55 5l2.25 4.15q.792 1.393.7-.4L1 10.7q2.85-2.15 1.6-4.6-.05-.1-.15-.2-.05-.1-.1-.25-.15-.3-.25-.65l-.85-1.55m14.35 3.9q.8 1.416.65-.4l-2.2-4.1q-3.25 1.55-5.1-1.5Q8.1.9 7.5 0l.8 1.55q.2.35.4.65.05.15.1.25.1.15.15.25Q10.3 5 13.6 3.85l2 3.5z"
                    id="ObjMor095__Symbol_237_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M130.05 238.15l.25.4q.2.45-.5 1.05l-26.2 14.3q-.95.3-1.15-.25l-.25-.4q-.05-.2-.1-.35-.05-.35-.1-.65-.1-2.45 2.25-4.2l2.6-1.95q.95-.8.45-1.65l-.35-.6-17.9 9.7 18.55 34.1q.39-.231.8-.45 4.524-1.841 10.25 0 2.227 1.015 3.95 3 .025.032.05.05.137.195.25.35 2.897 4.443 2.4 9.9-.086.802-.25 1.55l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.57.219-1.15.35l7.35 13.5 48.45-26.3-37.65-69.15-17.9 9.7.3.6q.4.9 1.55.6l3.1-1.15q2.75-1.05 4.75.35.25.2.45.45.15.1.3.3z"
                    id="ObjMor095__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor095__Symbol_238_0_Layer0_0_FILL"
                transform="translate(84.45 220.95)"
                id="ObjMor095__col1n"
            />
            <use
                xlinkHref="#ObjMor095__Symbol_235_0_Layer0_0_FILL"
                transform="translate(100.65 228.35)"
                id="ObjMor095__col2n"
            />
            <use
                xlinkHref="#ObjMor095__Symbol_236_0_Layer0_0_FILL"
                transform="translate(102 232.75)"
                id="ObjMor095__col2l"
            />
            <use
                xlinkHref="#ObjMor095__Symbol_237_0_Layer0_0_FILL"
                transform="translate(104 229.65)"
                id="ObjMor095__col2d"
            />
            <use xlinkHref="#ObjMor095__Layer1_0_FILL" />
        </g></g>
    )
}

export default ObjMor095
