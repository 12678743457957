import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M56.75 13.2Q56 8.05 55.3 2.15L47.95 6.3q-.2.15-.45.3-1.4.8-4.45 1.7Q33.65 11 30 6.95q-1.6-1.25-2.4-2-.2.05-.4.15-.7 1.5-2.7 2.8-1.05.7-2.65.8-1.1.05-2.15 0-1.5.05-2.75-.25-5.8-.95-10.3-4.85H6.6L1.55 0v.25h-.1Q-1 9.85.75 12.3q2.85 4 8.65 4.05 9.9 1.8 15.6 6L31.15 24q4.9-3.55 12.15-4.9 5.55-.6 13.45-5.9z"
                    id="BeaChi007__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M56.55 2.5q-.2-1.25-.35-2.5-.35.35-1.7 1.3-1.35 1-7.5 2.6t-8.7.75Q35.7 3.8 34.9 4q-.85.2-1.45.45-.65.25-2.45 2.1-1.8 1.85-4.15.6-2.4-1.25-3.5-2.5-1.1-1.3-3.95-.3-2.9.95-10.6-1.5Q1.1.35 0 .15q.15.9.55 1.45 2.85 4 8.65 4.05 9.9 1.8 15.6 6l6.15 1.65q4.9-3.55 12.15-4.9 5.55-.6 13.45-5.9z"
                    id="BeaChi007__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi007__Symbol_46_0_Layer0_0_FILL"
                transform="translate(165.2 234.6)"
                id="BeaChi007__col1n"
            />
            <use
                xlinkHref="#BeaChi007__Symbol_12_0_Layer0_0_FILL"
                transform="translate(165.4 245.3)"
                id="BeaChi007__col1d"
            />
        </g></g>
    )
}

export default BeaChi007
