import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#81CCFE",
    "col2n": "#04B74B"
}

function PlaAbs002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M385 385V0H.25v262H0v123h385z"
                    id="PlaAbs002__Symbol_2_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M385 123V0H0v123h385z"
                    id="PlaAbs002__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaAbs002__Symbol_2_0_Layer0_0_FILL"
                id="PlaAbs002__col1n"
            />
            <use
                xlinkHref="#PlaAbs002__Symbol_3_0_Layer0_0_FILL"
                transform="translate(0 262)"
                id="PlaAbs002__col2n"
            />
        </g></g>
    )
}

export default PlaAbs002
