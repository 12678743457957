import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#68888C",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF"
}

function LegSki020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M132.7 2.5v-.2l-.55-2.3-2.35.6-.4.1q-.85.15-1.65.35-1 .25-2 .45-.5.1-1.05.25l-2.8.6-4.5.9q-2.25.4-4.45.8-4.45.75-8.85 1.35l-3.5.5q-4.45.55-8.9 1-1.05.05-2.1.2-11.6.95-23.15.95h-.3q-10.4 0-20.8-.8-.85-.1-1.7-.15-6-.55-12-1.3-1.7-.25-3.45-.45-2.9-.45-5.75-.9-2.4-.4-4.7-.8-2.2-.4-4.4-.85-2.2-.4-4.3-.85-1.5-.35-3-.65-.5-.15-1-.25-.3-.05-.55-.1-1.7-.4-3.35-.8Q.9.15.65.2l-.6 2.2h.1q.05 0 .1.05.1.05.25.05.25.1.5.15.05.05.1.05h.05l.05.05q.1 0 .2.05h.2l.05.05h.1l.4.1h.1L2.3 3q.05 0 .15.05h.05q.3.05.6.15h.15q.25.05.6.15l1.8.4v.05L0 17.25l6.3.8q11.8 1.45 23.55 2.35 7.9.55 15.75.9h.05q8.75.3 17.5.35 14.15.05 28.35-.7 5.15-.3 10.3-.7 14.05-1.1 28.2-3L123.3 4.6l9.4-2.1z"
                    id="LegSki020__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.1.35Q.123.387.15.4H.2Q.15.386.1.35m.7.2h.1Q.722.512.55.45q.127.059.25.1M129.75 0H129.6q-2.05.5-4.15.95-30.55 7-61.3 7-30.6 0-61.4-7Q2.074.805 1.4.65q.078.03.15.05l1.8.4v.05l-.6 1.4q13.7 3.1 27.35 4.85.2-.05.4-.05.05 0 1.2.2 1.15.15 2.65.3 1.5.2 8.7.9 7.15.7 21.1.7 18.05 0 32.15-2 .9-.05 1.7 0 6.3-.8 12.65-1.9.5-.2 1.5-.35h.3q4.65-.85 9.3-1.8L121 1.95 129.75 0z"
                    id="LegSki020__Symbol_11_0_Layer0_0_FILL"
                />
                <g
                    id="LegSki020__Symbol_86_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M168.75 21.55h-.1q-6.8-11.15-16.8-19.25L151.3 0l-2.35.6-.4.1q-.85.15-1.65.35-1 .25-2 .45-.5.1-1.05.25l-2.8.6-4.5.9q-2.25.4-4.45.8-4.45.75-8.85 1.35l-3.5.5q-4.45.55-8.9 1-1.05.05-2.1.2-11.6.95-23.15.95h-.3q-10.4 0-20.8-.8-.85-.1-1.7-.15-6-.55-12-1.3-1.7-.25-3.45-.45-2.9-.45-5.75-.9-2.4-.4-4.7-.8-2.2-.4-4.4-.85-2.2-.4-4.3-.85-1.5-.35-3-.65-.5-.15-1-.25-.3-.05-.55-.1-1.7-.4-3.35-.8-.25 0-.5.05l-.6 2.2Q7.05 9.25 0 20.7q92.7 10.85 168.75.85m-104-.25h.05q-.047.094-.05.2v-.2z" />
                    <path d="M64.8 21.3h-.05v.2q.003-.106.05-.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#LegSki020__Symbol_86_0_Layer0_0_FILL"
                transform="translate(106.9 307)"
                id="LegSki020__col1n"
            />
            <use
                xlinkHref="#LegSki020__Symbol_27_0_Layer0_0_FILL"
                transform="translate(126.05 307)"
                id="LegSki020__col2n"
            />
            <use
                xlinkHref="#LegSki020__Symbol_11_0_Layer0_0_FILL"
                transform="translate(128.35 309.65)"
                id="LegSki020__col2d"
            />
        </g></g>
    )
}

export default LegSki020
