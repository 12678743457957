import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.7.75l.2-.2q-.15-.3-.5-.45-.4-.2-.75 0L0 16q.25.35.45.8.55 1.1 1.25 2.1L41.7.75M96.25 18.9q.65-1 1.2-2.1.2-.45.5-.8L57.3.1q-.4-.2-.8 0-.35.15-.5.45l.2.2L96.25 18.9z"
                    id="FacBro005__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro005__Symbol_5_0_Layer0_0_FILL"
                transform="translate(143.25 123.15)"
                id="FacBro005__col1n"
            />
        </g></g>
    )
}

export default FacBro005
