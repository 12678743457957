import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2d": "#252525",
    "col2n": "#000000"
}

function AccHat149({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M157 28.05q-7.9-8.2-11.85-15.65-.05-.15-.1-.25-.05-.15-.15-.25-12-5.45-23.95-8.65Q105.4-.1 89.55 0 73.7-.1 58.15 3.25 46.2 6.45 34.2 11.9q-.1.1-.15.25-.05.1-.1.25Q30 19.85 24.1 26.45q-4.3 6.1-7.15 13-2.9 6.9-1.4 13.55 33.65-13.85 68.1-14.35 2.95-.1 5.9-.1t5.9.1q34.45.5 68.1 14.35-.85-14.65-4.15-19.95-3.35-5.3-2.4-5m10.35 54.4v2.25q-.95 34.4-20.15 61-1.6 2.25-3.35 4.45t-3.55 4.2q-8.4 9.35-18.1 15.05-7.75 4.5-16.35 6.7-7.85 2.35-15.7 2.6-.3 0-.6.05-.3-.05-.6-.05-7.85-.25-15.7-2.6-8.6-2.2-16.35-6.7-9.7-5.7-18.1-15.05-1.8-2-3.55-4.2-1.75-2.2-3.35-4.45-19.2-26.6-20.15-61v-2.25q-.05-8 1-15.45Q-3.05 86.8.7 126.2v.05l1.75 4q.45.95.95 1.9v-.05q.1.25.25.5 1.6 3.2 3.4 6.2 2.05 3.5 4.45 6.75l7.15 8.8q1.6 1.8 3.3 3.5l1.25 1.25q1.05.95 2.1 1.95l.5.4q1.4 1.5 2.9 2.9.5.45.95.9l.05.05q24.35 20.9 59.85 21.15 35.5-.25 59.85-21.15l.05-.05q.45-.45.95-.9 1.5-1.4 2.9-2.9l.5-.4q1.05-1 2.1-1.95l1.25-1.25q1.7-1.7 3.3-3.5l7.15-8.8q2.4-3.25 4.45-6.75 1.8-3 3.4-6.2.15-.25.25-.5v.05q.5-.95.95-1.9l1.75-4v-.05q2.75-31.8-12.05-59.2 1.05 7.45 1 15.45z"
                    id="AccHat149__Symbol_491_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M194.65 36.4q-9.85-14.95-24.15-25.05-13.9-6.4-27.85-9.4-11.3-2-21.8-1.95-10.5-.05-21.8 1.95-13.95 3-27.85 9.4-14.3 10.1-24.15 25.05-4 5.4-7.2 11.5-.1.15-.15.3-4.4 7.1-8 15.5-7.15 16.35-11.55 37.65L.05 198.4q-.65 8.5 13.75 10.15 14.55 0 30.35-.1 5.75-17.95 16.8-33.25-.45-.45-.95-.9-1.5-1.4-2.9-2.9l-.5-.4q-1.05-1-2.1-1.95l-1.25-1.25q-1.7-1.7-3.3-3.5l-7.15-8.8q-2.4-3.25-4.45-6.75-1.8-3-3.4-6.2-.15-.25-.25-.5v.05q-.5-.95-.95-1.9l-1.75-4v-.05l12.05-59.2q.85-6.85 2.6-13.25.1-.4.2-.75Q48.7 56.3 52.5 47.9q2.45-5.4 5.7-11.5 3.1-6.6 7.05-14.05.05-.15.1-.25.05-.15.15-.25 12-5.45 23.95-8.65 15.55-3.35 31.4-3.25 15.85-.1 31.4 3.25 11.95 3.2 23.95 8.65.1.1.15.25.05.1.1.25 3.95 7.45 7.05 14.05 3.25 6.1 5.7 11.5 3.8 8.4 5.65 15.05.1.35.2.75 1.75 6.4 2.6 13.25l12.05 59.2v.05l-1.75 4q-.45.95-.95 1.9v-.05q-.1.25-.25.5-1.6 3.2-3.4 6.2-2.05 3.5-4.45 6.75l-7.15 8.8q-1.6 1.8-3.3 3.5l-1.25 1.25q-1.05.95-2.1 1.95l-.5.4q-1.4 1.5-2.9 2.9-.5.45-.95.9 11.05 15.3 16.8 33.25 15.8.1 30.35.1 14.4-1.65 13.75-10.15l-20.1-97.05q-4.4-21.3-11.55-37.65-3.6-8.4-8-15.5-.05-.15-.15-.3-3.2-6.1-7.2-11.5z"
                    id="AccHat149__Symbol_368_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M49.95 28.1l-7.15-8.8q-2.4-3.25-4.45-6.75-1.8-3-3.4-6.2-.15-.25-.25-.5v.05q-.5-.95-.95-1.9L32 0 21.45 60.45Q6.1 53.95.05 62.2-.6 70.7 13.8 72.35q14.55 0 30.35-.1Q49.9 54.3 60.95 39q-.45-.45-.95-.9-1.5-1.4-2.9-2.9l-.5-.4q-1.05-1-2.1-1.95l-1.25-1.25q-1.7-1.7-3.3-3.5m156.8-21.75q-1.6 3.2-3.4 6.2-2.05 3.5-4.45 6.75l-7.15 8.8q-1.6 1.8-3.3 3.5l-1.25 1.25q-1.05.95-2.1 1.95l-.5.4q-1.4 1.5-2.9 2.9-.5.45-.95.9 11.05 15.3 16.8 33.25 15.8.1 30.35.1 14.4-1.65 13.75-10.15-6.05-8.25-21.4-1.75L209.7 0l-1.75 4q-.45.95-.95 1.9v-.05q-.1.25-.25.5z"
                    id="AccHat149__Symbol_333_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat149__Symbol_491_0_Layer0_0_FILL"
                transform="translate(102.95 63.5)"
                id="AccHat149__col1n"
            />
            <use
                xlinkHref="#AccHat149__Symbol_368_0_Layer0_0_FILL"
                transform="translate(71.65 53.55)"
                id="AccHat149__col2n"
            />
            <use
                xlinkHref="#AccHat149__Symbol_333_0_Layer0_0_FILL"
                transform="translate(71.65 189.75)"
                id="AccHat149__col2d"
            />
        </g></g>
    )
}

export default AccHat149
