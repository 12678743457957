import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo040({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M204.9 222.65q-.05 0-.05.05-1 .5-1.8 1.05-1 .75-.9 1.65 0 .55.5 1.2.35.45.75.75.75.45 1.75.3.45-.05.9-.25.95-.4 1.85-.8.05 0 .1-.05l12.25-5.75q.15-.1.35-.15 0-.05.05-.05-.65-.35-1.6-.55V220h.45q.164.05-.9-1.15.81-.76.05-1.35-.754-.547-.65-1-5.65 2.7-11.2 5.3-.95.4-1.9.85z"
                    id="ObjFoo040__Layer7_0_FILL"
                />
                <path
                    fill="red"
                    d="M218.2 216.4l-.2.1q-.1.45-.15.9-.1 1.15.4 2.05.2.1.35.2.25.15.45.35v.05q.95.2 1.6.55.15-.1.35-.2 1.1-.5 1.5-1.6 1.1-3.25-2.45-3.4-.8.5-1.85 1z"
                    id="ObjFoo040__Layer7_1_FILL"
                />
            </defs>
            <use xlinkHref="#ObjFoo040__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo040__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo040
