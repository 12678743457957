import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00AFAF"
}
function AccMor094({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.4 1.05q-.6-1.25-2.4-1-.95.2-2.65.9-2 .85-3.15 1.25-2.55 1.05-5.35 2.6-2 1.1-5.15 3.15-2.25 1.45-5.15 3.6Q7.4 13.9 5.9 15q-1.1.8-3.25 3.15Q.6 20.45.35 21.4 0 21.9 0 22.85q.55 4.85 5.1 6 .55.1.95-.05 1 0 3.75-1.5 2.8-1.45 3.85-2.35 1.4-1.15 4.5-3.6Q21 19 22.9 17.25q2.75-2.55 4.35-4.2 2.25-2.35 3.8-4.5.7-1 2-2.8 1.1-1.4 1.5-2.3.65-1.5-.15-2.4m-3.45 2.5q.05 0 .05.05-6.95 8.95-15.55 16.05h-.1q-1.85 1.5-2.8 2.3-1.9 1.45-3.25 1.85l-.3.05q-.85-.05-.6-.8l1.5-1.2v-.5q-.1-.55-.35-1.1-.05-.2-.2-.2-.55-.55-1.25-.45l-.45.05-1.15.95q-.7.05-.65-.8l.1-.3q.8-1.15 2.6-2.65 1-.8 2.95-2.2.35-.3 2.6-1.85 8.2-5.6 16.85-9.25z"
                    id="AccMor094__Symbol_212_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor094__Symbol_212_0_Layer0_0_FILL"
                transform="translate(138.45 88.2)"
                id="AccMor094__col1n"
            />
        </g></g>
    )
}
export default AccMor094
