import { AssetSvgProps } from "../../../shared/assets"

function ShiMot094({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0000A4"
                    d="M159.2 258v35.7h66.5V258h-66.5z"
                    id="ShiMot094__Layer1_0_FILL"
                />
                <path
                    fill="red"
                    d="M212.4 288.05l-.4 1.35 1.15-.8 1.15.8-.4-1.35 1.1-.85h-1.4l-.45-1.35-.45 1.35h-1.4l1.1.85z"
                    id="ShiMot094__Layer1_1_MEMBER_0_FILL"
                />
                <path
                    fill="red"
                    d="M219.4 270.25l1.1.85-.4 1.35 1.15-.8 1.15.8-.4-1.35 1.1-.85h-1.4l-.45-1.35-.45 1.35h-1.4z"
                    id="ShiMot094__Layer1_1_MEMBER_1_FILL"
                />
                <path
                    fill="red"
                    d="M211.45 262.9l1.1.85-.4 1.35 1.15-.8 1.15.8-.4-1.35 1.1-.85h-1.4l-.45-1.35-.45 1.35h-1.4z"
                    id="ShiMot094__Layer1_1_MEMBER_2_FILL"
                />
                <path
                    fill="red"
                    d="M204.45 273.05l-.45-1.35-.45 1.35h-1.4l1.1.85-.4 1.35 1.15-.8 1.15.8-.4-1.35 1.1-.85h-1.4z"
                    id="ShiMot094__Layer1_1_MEMBER_3_FILL"
                />
                <g id="ShiMot094__Layer1_1_FILL">
                    <path
                        fill="#FFF"
                        d="M212 288.15l-.6 2 1.75-1.15 1.7 1.15-.6-2 1.65-1.25-2.1-.05-.65-1.95-.7 1.95-2.1.05 1.65 1.25m-6.9-14.1l1.65-1.25-2.1-.05-.65-1.95-.7 1.95-2.1.05 1.65 1.25-.6 2 1.75-1.15 1.7 1.15-.6-2m13.35-4.1l1.65 1.25-.6 2 1.75-1.15 1.7 1.15-.6-2 1.65-1.25-2.1-.05-.65-1.95-.7 1.95-2.1.05m-7.95-7.35l1.65 1.25-.6 2 1.75-1.15 1.7 1.15-.6-2 1.65-1.25-2.1-.05-.65-1.95-.7 1.95-2.1.05m-27.35 7.4h2.65l9.35 4.7v-.7l-7.9-4h7.9v-1.25H178.9v7.15h1.2v-5.4l10.75 5.4h3.95l-11.65-5.9m12-10.3V258h-.4l-11.6 5.9h-2.5l11.4-5.9h-1.45l-10.5 5.35V258h-1.2v7.05h16.25v-1.15H187l8.15-4.2m-35.95 4.2v1.15h16.15V258h-1.2v5.35l-10.5-5.35h-4.25l11.55 5.9h-2.6l-9.15-4.7v.75l7.8 3.95h-7.8m0 12h.35l11.55-5.9h2.6l-11.5 5.9h1.25l10.7-5.45v5.45h1.2v-7.15H159.2V270h7.85l-7.85 4.05v1.85z"
                    />
                    <path
                        fill="red"
                        d="M173.7 270h-2.6l-11.55 5.9h2.65l11.5-5.9m21.05-12h-2.7l-11.4 5.9h2.5l11.6-5.9m-8.95 12h-2.65l11.65 5.9h.35v-1.2l-9.35-4.7m-26.6-12v1.2l9.15 4.7h2.6L159.4 258h-.2m0 7.05v3.7h16.15v7.15h3.55v-7.15h16.25v-3.7H178.9V258h-3.55v7.05H159.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot094__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot094__Layer1_1_FILL" />
            <use xlinkHref="#ShiMot094__Layer1_1_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot094__Layer1_1_MEMBER_1_FILL" />
            <use xlinkHref="#ShiMot094__Layer1_1_MEMBER_2_FILL" />
            <use
                xlinkHref="#ShiMot094__Layer1_1_MEMBER_3_FILL"
                transform="translate(0 .05)"
            />
        </g></g>
    )
}

export default ShiMot094
