import { AssetSvgProps } from "../../../shared/assets"

function ObjMor035({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M73 341.2q-3.3 1.651-6.6 4.95h-.2q-4.55.55-5.65 4.3.1.25.3.5.45.6 1.1 1 .55.25 1.35.4 1.55.15 2.8-.75 1.15-.85 1.5-2.1.05-.15.2-.4 0-.75-.05-1.35h.05l47.05-34.05q1.282-2.057-1.1-1.55-17.8 11.175-34.2 24.45l-.55-.8-6.5 4.7.5.7z"
                    id="ObjMor035__Layer14_0_FILL"
                />
                <path
                    fill="#D8D8D8"
                    d="M79 335.8l.55.8 34.2-24.45q1.168-1.614-.7-.95L79 335.8m-13.45 9.4q-.35-.15-.75-.2-.3 0-.5-.05-1.25 0-2.4.85-1.25.9-1.6 2.45-.25 1.2.25 2.2 1.1-3.75 5.65-4.3h.2l6.6-4.95-.5-.7-6.65 4.8-.3-.1z"
                    id="ObjMor035__Layer14_1_FILL"
                />
                <path
                    d="M114.25 279.1l.05.05q-5.8-.15-7.5 1.95-1.85 2.05.4 6.2.11.184.2.4.148-.32.5-.8.046-.061.1-.15-1.747-3.354-.45-5 1.6-1.8 7.05-1.6.1 0 .25-.05 1.6.05 3.7.15 15.7.7 21.7 7.05.35.35.65.75v.05q.05.1.05.2 0 .5-.7.5-.65 0-1.2-.05.1.2.25.45.15.45.85.55.7.05 1.2 0l-1.75 1.3q-.2.45-.2.8 0 1-.25 1.9-.5 1.75-1.9 3.15-2.05 2.15-5 2.15h-.4q-.55-.05-1.35.2-1.2 3.55-5.45 6.4-3.35 2.2-6.4 3-1.65.4-3.2.4-.4 0-.7-.05l-2.2 1.55 2.8 3.85 30.1-21.75q.75-3.4-2.8-3.85-.05-.15-.1-.25-.15-.25-.3-.45l-.05-.05q-.1-.15-.25-.3-.15-.25-.35-.45-6-7.25-23-8.05-2.45-.15-4.35-.15z"
                    id="ObjMor035__Layer14_2_FILL"
                />
                <path
                    fill="red"
                    d="M79 335.8l-6.5 4.7.5.7.05.05L74.3 343l6.5-4.7-1.8-2.5z"
                    id="ObjMor035__Layer14_3_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor035__Layer14_0_FILL" />
            <use xlinkHref="#ObjMor035__Layer14_1_FILL" />
            <use xlinkHref="#ObjMor035__Layer14_2_FILL" />
            <use xlinkHref="#ObjMor035__Layer14_3_FILL" />
        </g></g>
    )
}

export default ObjMor035
