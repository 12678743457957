import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#545429",
    "col1n": "#6D6D3B",
    "col2l": "#C4C389",
    "col2n": "#9A9956"
}

function AccHat022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M188.4 73.75l-1.15-4.6Q170.25 10 108.1 1l-.75-.2q-.95-.15-1.9-.25l-.25.05q-1.6-.2-3.25-.3-.05-.05-.1 0Q92.8-.5 83.5.7q-3.65.45-7.4 1.2H76Q15.35 12.5.6 71q-.3 1.2-.6 2.75h188.4z"
                    id="AccHat022__Symbol_240_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M105.2 8.3q-.6-3.1-3.1-5.4Q98.9-.05 94.35 0q-4.55-.05-7.8 2.9Q84 5.3 83.5 8.45q3.05-.4 6.1-.55 4.9-.3 9.7 0 1.25.05 2.55.15.05-.05.1 0 1.65.1 3.25.3V8.3m83.2 73.2q-3-1.15-6-1.85-8.3-1.95-16.4-3.55-26.75-5.25-51.95-7-21.3-1.5-41.5-.45Q44.8 70.05 19 76.1q-5.2 1.25-10.3 2.65-2.6.7-5.2 1.5-1.25.35-2.85 1-.3.1-.65.25 7.15 9.4 32.35 13.25 64.2 8.8 126.85 0Q182 90.6 188.4 81.5z"
                    id="AccHat022__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M0 79.55q7.15 9.4 32.35 13.25 65 12.7 126.85 0 22.8-4.15 29.2-13.25Q171 93.5 94.45 94.6 19.75 92.8.65 79.3q-.3.1-.65.25M94.45 0q-2.15 0-3.7 2.75-.8 1.45-1.15 3.2 4.9-.3 9.7 0-.4-1.75-1.2-3.2Q96.6 0 94.45 0z"
                    id="AccHat022__Symbol_39_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat022__Symbol_152_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M83.55.9L83.5.7q-3.65.45-7.4 1.2H76Q15.35 12.5.6 71q-.3 1.2-.6 2.75h15.9Q20.8 48.1 37.45 28.2 57.1 4.8 83.55.9M188.4 73.75l-1.15-4.6Q170.25 10 108.1 1l-.05.35Q132.8 6 151.45 28.2q16.65 19.9 21.6 45.55h15.35z" />
                    <path d="M108.05 1.35l.05-.35-.75-.2q-.95-.15-1.9-.25h-.25V.6q-1.25-.15-2.5-.25-.4-.05-.75-.05-.05-.05-.1 0-3.7-.35-7.4-.3Q89 0 83.5.7l.05.2q5.3-.8 10.9-.8 4.2 0 8.2.45 1.25.15 2.55.35 1.4.2 2.85.45z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat022__Symbol_240_0_Layer0_0_FILL"
                transform="translate(98.05 54.6)"
                id="AccHat022__col1n"
            />
            <use
                xlinkHref="#AccHat022__Symbol_152_0_Layer0_0_FILL"
                transform="translate(98.05 54.6)"
                id="AccHat022__col1d"
            />
            <use
                xlinkHref="#AccHat022__Symbol_65_0_Layer0_0_FILL"
                transform="translate(98.05 46.85)"
                id="AccHat022__col2n"
            />
            <use
                xlinkHref="#AccHat022__Symbol_39_0_Layer0_0_FILL"
                transform="translate(98.05 48.8)"
                id="AccHat022__col2l"
            />
        </g></g>
    )
}

export default AccHat022
