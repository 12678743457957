import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E0F0FF"
}

function SkiMor027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.8 61.2L4.25 50.6.45 61.25l-.1.2Q0 62.35 0 63.4q0 1.95 1.2 3.35 1.2 1.4 2.9 1.4t2.9-1.4q1.2-1.4 1.2-3.35 0-1.35-.4-2.2m9.6-36.9l-2.6-7.75-2.75 7.8q-.05.1-.1.15-.25.65-.25 1.45 0 1.4.9 2.45.85 1 2.1 1t2.15-1q.85-1.05.85-2.45 0-1-.3-1.65M35.2 7.55q-.05.05-.1.15-.2.55-.2 1.2 0 1.25.7 2.1.7.9 1.7.9T39 11q.7-.85.7-2.1 0-.85-.2-1.35L37.4.85l-2.2 6.7m8.1 40.3q-.2.55-.2 1.2 0 1.25.7 2.1.7.9 1.7.9t1.7-.9q.7-.85.7-2.1 0-.85-.2-1.35L45.6 41l-2.2 6.7q-.05.05-.1.15M62.25 17.6l-.1.2q-.35.9-.35 1.95 0 1.95 1.2 3.35 1.2 1.4 2.9 1.4t2.9-1.4q1.2-1.4 1.2-3.35 0-1.35-.4-2.2l-3.55-10.6-3.8 10.65M145 64.4q-.05.05-.1.15-.2.55-.2 1.2 0 1.25.7 2.1.7.9 1.7.9t1.7-.9q.7-.85.7-2.1 0-.85-.2-1.35l-2.1-6.7-2.2 6.7M82.6 41.05q1.25 0 2.15-1 .85-1.05.85-2.45 0-1-.3-1.65l-2.6-7.75-2.75 7.8q-.05.1-.1.15-.25.65-.25 1.45 0 1.4.9 2.45.85 1 2.1 1M109.7 9.4q0-1-.3-1.65L106.8 0l-2.75 7.8q-.05.1-.1.15-.25.65-.25 1.45 0 1.4.9 2.45.85 1 2.1 1t2.15-1q.85-1.05.85-2.45m39.65 17.4q-.35.9-.35 1.95 0 1.95 1.2 3.35 1.2 1.4 2.9 1.4t2.9-1.4q1.2-1.4 1.2-3.35 0-1.35-.4-2.2l-3.55-10.6-3.8 10.65-.1.2M122.6 48.75q1.2-1.4 1.2-3.35 0-1.35-.4-2.2l-3.55-10.6-3.8 10.65-.1.2q-.35.9-.35 1.95 0 1.95 1.2 3.35 1.2 1.4 2.9 1.4t2.9-1.4z"
                    id="SkiMor027__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor027__Symbol_32_0_Layer0_0_FILL"
                transform="translate(113.9 82.6)"
                id="SkiMor027__col1n"
            />
        </g></g>
    )
}

export default SkiMor027
