import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M95.05 18.45L94.95.2Q65.15-1.65 42 11.8q-6.1 3.55-11.8 8.15l-.2.1q-5.5 4.55-10.5 10.1-.6.6-1.15 1.25-1.05 1.2-2.35 2.8l.1-.1q-.05.1-.15.2-.2.2-.4.45-1.3 1.6-2.55 3.3-.45.65-.85 1.3Q-1.5 61.05.2 99.5q1.1-25.65 15.75-45.2 1.5-2 3.1-3.9 2.45-2.6 4.85-5.1.15-.1.25-.2l2.95-2.85.05-.05q5.85-5.4 11.5-9.7 1.2-.9 2.4-1.75.1-.1.25-.2 27.95-17.4 53.75-12.1m72.7 26.65q.1.1.25.2 2.4 2.5 4.85 5.1l2.3 2.8q2.25 2.95 4.25 6.1 11.35 17.75 12.3 40.2 1.2-27.4-5.35-46.3-2.8-7.95-6.95-14.35l-.5-.8q-1.25-1.7-2.55-3.3-.3-.35-.55-.65l.1.1q-1.3-1.6-2.35-2.8-5.55-6.3-11.65-11.35l-.2-.1q-5.7-4.6-11.8-8.15-6.7-3.9-14-6.5Q118.1-1.1 96.95.2l-.1 18.25q18.95-3.9 39.05 4.45 7.25 3.05 14.7 7.65.05.05.15.1 1.2.9 2.5 1.85 5.7 4.3 11.55 9.75l.1.1 2.85 2.75z"
                    id="HaiSho016__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M23.1 5.4q2.8-3.8 5.1-5.4-6.15 2.55-8.35 3.9-2.25 1.35-2.35 1.4-6.1 5.05-11.65 11.35-.95 1.1-2.25 2.75-.1.1-.15.2Q.85 23.55 0 27.7v.05h.05q1.65-3 3.4-5.35 2.95-4.05 6.05-6.25-3.75 5-6.05 10.05-.65 1.35-1.15 2.75.55-.75 1.15-1.4 3.7-4.5 7.6-7.4-3.25 3.65-5.4 10.1 3.45-4.75 8-7.2l-4.6 6.9q2.75-1.9 5.5-2.45l.1-.1v.05q5.85-5.4 11.5-9.7 1.2-.9 2.4-1.75l-.05-.05q.05-.1.9-1.45.85-1.35 3.05-3.1-.9 0-6.8 3.35 2.5-3.15 6.8-6.7-3.8 1.25-7.4 2.75 4.8-4.65 6.7-5.55Q27 6.3 24.2 7.95q3.2-3.05 6.65-5.9-4 1.45-7.75 3.35m143.8 21.35q-1-3.65-3.3-7.1-1.3-1.65-2.25-2.75-5.55-6.3-11.65-11.35-.1-.05-2.35-1.4-2.2-1.35-8.35-3.9 2.3 1.6 5.1 5.4-3.75-1.9-7.75-3.35 3.45 2.85 6.65 5.9-2.8-1.65-7.55-2.7 1.9.9 6.7 5.55-3.6-1.5-7.4-2.75 4.3 3.55 6.8 6.7-5.9-3.35-6.8-3.35 2.2 1.75 3.05 3.1.85 1.35.9 1.45l-.05.05q1.2.85 2.4 1.75 5.65 4.3 11.5 9.7v-.05l.1.1q2.75.55 5.5 2.45l-4.6-6.9q4.55 2.45 8 7.2-2.15-6.45-5.4-10.1 4.55 3.35 8.75 8.8-2.45-6.45-7.2-12.8 4.75 3.35 9.2 11.15.15.25.25.45h.05v-.05l-.3-1.2z"
                    id="HaiSho016__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho016__Symbol_137_0_Layer0_0_FILL"
                transform="translate(96.55 57.8)"
                id="HaiSho016__col1n"
            />
            <use
                xlinkHref="#HaiSho016__Symbol_18_0_Layer0_0_FILL"
                transform="translate(109.05 72.55)"
                id="HaiSho016__col1l"
            />
        </g></g>
    )
}

export default HaiSho016
