import { AssetSvgProps } from "../../../shared/assets"

function ShiMot074({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot074__Layer5_0_FILL"
                />
                <path
                    fill="#0280D2"
                    d="M190 269.9h-6.85l3.4 5.85-3.4 5.95H190l3.45 6 3.45-6h6.85l-3.45-5.95 3.45-5.85h-6.85l-3.45-6-3.45 6m-3.35 2h2.15l-1.05 1.9-1.1-1.9m6.8-3.95l1.15 1.95h-2.25l1.1-1.95m4.6 3.95h2.2l-1.05 1.9-1.15-1.9m-6.9 0h4.55l2.3 3.85-2.3 3.9h-4.55l-2.2-3.9 2.2-3.85m8.05 5.9l1.05 1.85h-2.2l1.15-1.85m-4.6 3.9l-1.15 1.95-1.1-1.95h2.25m-6.85-3.9l1.05 1.85h-2.15l1.1-1.85m-28.55 10.65v2.5h66.5v-2.5h-66.5m0-27.8v2.5h66.5v-2.5h-66.5z"
                    id="ShiMot074__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot074__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot074__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot074
