import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M5.95 4.8q.6-1.3 1.2-2.35-.7.2-1.55.55-.9.35-3.55 2.15Q0 6.75 0 8.65q.05.2.2.45.6 1 3.4.75.2-.85.65-1.75 1.15-2.05 1.7-3.3m4.3 3.65q.5-1.9 3.35-6.15.4-.7.8-1.3h-.3q-.15 0-.25.05-1.7.25-3.7.7Q8.6 4.45 5.8 9.5q.55-.15 1.15-.3 1.7-.4 3.3-.75m24.95-1.6q.1-2.1 2.75-5.85Q27.15-.85 16.4.8q-3 5-4.4 7.3 2.85-.55 5.25-.85 3.4-.4 2.8-.4 7.65-.7 15.15 0M75.7 8.1Q74.3 5.8 71.3.8 60.55-.85 49.75 1q2.65 3.75 2.75 5.85 7.5-.7 15.15 0-.6 0 2.8.4 2.4.3 5.25.85m-1.85-7.05Q73.75 1 73.6 1h-.3q.4.6.8 1.3 2.85 4.25 3.35 6.15 1.6.35 3.3.75.6.15 1.15.3-2.8-5.05-4.35-7.75-2-.45-3.7-.7m11.8 4.1Q83 3.35 82.1 3q-.85-.35-1.55-.55.6 1.05 1.2 2.35.55 1.25 1.7 3.3.45.9.65 1.75 2.8.25 3.4-.75.15-.25.2-.45 0-1.9-2.05-3.5z"
                    id="FacBro023__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro023__Symbol_23_0_Layer0_0_FILL"
                transform="translate(148.65 126)"
                id="FacBro023__col1n"
            />
        </g></g>
    )
}

export default FacBro023
