import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#000000"
}

function AccMor066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 17.95q.15 2.65 3.4 3.15l28.3 4.15q3.2.45 4.2-2.05Q18.35 18.7 0 17.95m37.6-5.9l.5-3.35q.6-3.9-3.3-4.45L6.5.1Q2.55-.5 1.95 3.4l-.5 3.35q18.1 4.1 36.15 5.3z"
                    id="AccMor066__Symbol_162_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M26.8 2.35L0 0v8.1l26.8 1.8V2.35z"
                    id="AccMor066__Symbol_163_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M205.3 276.1q-.1.7-.05 1.35l35.9 5.25q.2-.6.3-1.3l1.4-9.85-36.15-5.3-1.4 9.85m18.75-3.95v-.05q-1.6.75-4.05 1.95-3.5 2.15-3.65 2.15-.25-.05-.2-.25v-.2q.05-.35.7-1.6.7-1.2.75-1.3l.05-.3q-.9.4-3.05 2.05-1.65 1.4-2.5 1.25-.5 0-.6-.2-.1-.15-.05-.6.05-.2.05-.25.1-.15.25-.15.25.05.25.5 0 .4.75-.05l-.3.25q.15-.1 2.8-1.95 2.3-1.65 2.7-1.55.45.05.25.75-.1.5-.45 1.3-.25.45-.7 1.2 1.8-1.05 4.25-2.35 1.3-.65 2.2-1.15 1.65-.8 1.5.05-.05.4-2 2.55-2 2.15-1.3 2.3h.25q.45-.25 1.3-1 .5-.4 1.05-.35.55.1.35 1.05-.05.25-.15.55-.7 1.1 2.75-1.1 3.4-2.25 4.1-2.15.4.05.35.5-.1.7-.65 1.3-1.65 1.8-1.3 1.9.1 0 1.1-.45.8-.45 1.05-.4.35.05.15.95-.2.9.6.75h.15q.05.1.15.25 0 .15-.1.3-.25 0-.65-.05-.4-.1-.7-.35-.35-.25.2-1.1-.2-.05-1.1.4-.85.35-1.05.3-.4-.05-.5-.25-.1-.2-.1-.45 0-.3.95-1.45t1.05-1.4v-.05q-.75.35-3.35 1.9-.1.05-1.4.95-1.45.85-2.25.75-.35-.05-.35-.2 0-.15.05-.4t.25-.45q.15-.2.25-.55.1-.4-.1-.45-.2-.05-1 .65-.75.75-1.5.65-.7-.15-.8-.35v-.35q.15-.75 1.45-2.3 1.55-1.45 1.85-1.9m-6.95-6.75l.1-.65-1.6-.2.1-.6 1.45.2.05-.6-1.45-.2.1-.5 1.55.2.1-.65-2.45-.35-.45 3 2.5.35m1.5-.5l.35-2.25-.9-.15-.45 3 2.35.35.1-.75-1.45-.2m-7.45-.35l.95.15.2-1.2 1 .15-.2 1.2.9.1.45-3-.9-.1-.15 1.05-1-.15.15-1.05-.95-.15-.45 3m23.4.35l-1-.15-1.6 2.85.95.15.2-.5 1.05.15.1.55.95.1-.65-3.15m-.5 1.85l-.65-.1.45-1 .2 1.1m5.25-1.15l-1.2-.2-.8 1.8-.15-1.95-1.2-.15-.45 3 .75.1.35-2.25.25 2.35.65.1.95-2.2-.35 2.25.75.15.45-3m-16.55.65l.1-.75-1.45-.2.35-2.25-.9-.15-.45 3 2.35.35m4.6.5l.1-.65-.9-.1-.1.8.4.05q0 .15-.1.25-.1.05-.35.2l.15.35q.35-.15.6-.3.15-.2.2-.6m-4.15-1.95q-.05.55.1.9.15.4.45.6.3.2.8.25.5.1.85-.05.35-.1.6-.45.2-.3.25-.8.15-.75-.2-1.25-.35-.45-1.05-.55-.75-.1-1.2.3-.5.35-.6 1.05m.9.1q.1-.45.3-.6.2-.2.45-.15.3.05.45.25.15.25.05.7-.05.5-.25.6-.15.2-.45.15-.3-.05-.45-.25-.15-.25-.1-.7m6.55-.55l-.9-.15-.45 3 .9.15.45-3z"
                    id="AccMor066__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor066__Symbol_162_0_Layer0_0_FILL"
                transform="translate(205.25 259.5)"
                id="AccMor066__col1n"
            />
            <use
                xlinkHref="#AccMor066__Symbol_163_0_Layer0_0_FILL"
                transform="translate(209.9 269.25)"
                id="AccMor066__col2n"
            />
            <use xlinkHref="#AccMor066__Layer1_0_FILL" />
        </g></g>
    )
}

export default AccMor066
