import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF5D5D"
}

function FacMou060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M12 2.9q.9-.65 1.8-1.4-1.5-3-4.35 0Q4.55-.2 1.5 2.55.8 3.2.2 4.15q-.15.1-.2.25.25.15.6.3 1.65.85 3.45 1.45 2.15.7 5.65-1.6Q10.85 3.8 12 2.9z"
                    id="FacMou060__Symbol_127_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M32.75 10.7l2.15-.4V3.1q-.15-.25-.3-.4-2.3-3.15-4.2.4-4.3-4.9-6.15 0-2.55-5.5-6.05 1.5-2.35-6.7-5.2 0-.55-3.8-2.15-3.55Q9.55.65 7.2 4q.15-.3-.05-1.35-.4-1.4-1.6-2.65Q-1.9-.05.65 6.8 1.3 8 2 9.1h2l1.6-2.6-.1 4q.8.35 1.65.45 1.7.25 3.2-.45l2-3.35v3.7q.15.1.3.15 3.3 1.05 5.5-.15V8l1.35 3q2.7 1.15 4.95 0l1.4-3.4L28 12.25q.7 0 1.3-.2 1.2-.35 2.05-1.35V7.6l1.4 3.1z"
                    id="FacMou060__Symbol_85_0_Layer0_0_FILL"
                />
                <g id="FacMou060__Layer13_0_FILL">
                    <path
                        fill="#FF98D0"
                        d="M212.9 215.45q-.05-1.05-1.45-1.1L202 213.1l-23.85 1.15.65 4.8h28.7l5.2-2.65h.1q.15-.352.15-.55 0-.2-.05-.4z"
                    />
                    <path d="M210.6 220.2q1.717-2.381 2.2-3.8h-.1l-5.2 2.65h-28.7l-.65-4.8q-6.227 2.891-3.55 9.1 1.05 1.65 2.2 3.1 1.45 1.9 3.15 3.55 1.6 1.55 3.4 2.9.85.6 1.8 1.2.749.454 1.55.8.803.334 4.15.75 3.347.476 9.7-3.6 3.344-2.903 4.95-5 1.668-2.092 2.55-3.3.878-1.163 2.55-3.55z" />
                </g>
            </defs>
            <use xlinkHref="#FacMou060__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou060__Symbol_127_0_Layer0_0_FILL"
                transform="translate(186.75 230.55)"
                id="FacMou060__col2n"
            />
            <use
                xlinkHref="#FacMou060__Symbol_85_0_Layer0_0_FILL"
                transform="translate(172.6 214.25)"
                id="FacMou060__col1n"
            />
        </g></g>
    )
}

export default FacMou060
