import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#00284D"
}

function ObjToy037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.7 11.55v-.3Q46.8 9.5 45 8.7q-.4-.15-.9-.3L37 6.95V6.3q0-.4-.1-.8 0-.1-.1-.25-.3-1.7-2.1-2.05-.45-.15-1-.2l-6.2.05-.1-.4q0-.35-.2-.7 0-.15-.15-.25-.6-1.7-2.5-1.65-.45-.05-1.05 0L2.75 4.4Q.1 4.9 0 7.3q.05.05.05.25.1.35.15.7l5.15 25.1q2.35.05 4.95.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.05.5-.15 1.05l17.55 7.55q.5.3.9.4 2.3.45 3.4-2L53.7 20.1q.2-.35.2-.65.1-.15.05-.3.45-1.7-1.15-2.8-.35-.3-.8-.55l-5.6-2.4.2-1.1q.1-.4.1-.75z"
                    id="ObjToy037__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.2 39.5q.6 2.15.6 4.55l19.15 8.3 15.75-36.2-21.3-9.2L14.2 39.5m3.25-36.25l-8.25.1.2 29.5q.8.5 1.55 1.2l6.15-29.6q.15-.35.15-.7.15-.2.1-.3l.1-.2m-10.5.1V3.3L0 4.75l5.45 26.7q.8.15 1.65.4l-.15-27.5Q7 4 6.9 3.6q.05-.2.05-.25m15.9-2.4l-.2-.95L18 1l4.85-.05m4.05 2.2l5.55 1.15V3.1l-5.55.05m-.65 3.1q.1-.15.15-.25.2-.3.5-.55l-7.35-1.5L12.8 36.3q.1.2.2.35L25.95 6.9q.2-.3.3-.65m15.7 4.05l.35-1.65-7.85-1.6 7.5 3.25z"
                    id="ObjToy037__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy037__Symbol_126_0_Layer0_0_FILL"
                transform="translate(108.45 252.95)"
                id="ObjToy037__col1n"
            />
            <use
                xlinkHref="#ObjToy037__Symbol_39_0_Layer0_0_FILL"
                transform="translate(110.7 255.1)"
                id="ObjToy037__col2n"
            />
        </g></g>
    )
}

export default ObjToy037
