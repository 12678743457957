import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiHig025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.5 43.8q-.55-1.55-.85-5.15-.3-3-.25-4.4-.1-7.35-.1-7.6 0-2.35.2-5.7t.2-4.4q0-4.45-1.9-9.45-.95-2.5-1.8-3.95-.05-.75-.1-1.65.15-.75.25-1.4V.05q-.1.1-.2.3-3.1 4.35-9.5 9.5-4.1 3.25-9.55 6.9-.7.4-1.35.9-.25.15-.45.3-.4.2-.75.45l-.1.05q-.1.05-.15.1-3.3 2.1-5.75 5.5Q8 25.9 7 27.95q-2.4 5-2.65 11.45.05.8.1 1.55.15 1.4.6 4 .4 2.5.5 3.95.25 2.55.25 3.85-.05 2-.75 4.05Q4.3 58.7 3 60.35q1.65-.6 3.95-2.45Q9.2 56 9.6 52.05q.65 2 1.5 4.55.04-.078.15 1.65-.037-1.388 0-1.2.25 2.9.2 5.3-.15.05-.3.05H11q-.2.05-.35.05-2 .35-4.4-.2 0 1.45 3 2.95.3.2.8.4.45.25.95.45-.55 2.7-1.8 4.45-2.05 3-7.3 4.9-.9.3-1.9.6v.1q.15 0 .25.05.8.2 1.65.35 1.3.15 2.75.2 2.4.15 4.65-.25 4.3-.65 8.1-3 5.55-3 9.3-8.25-.15.25-.2.45-.15.15-.2.4-.05.1-.05.25-1.3 1.5-2.45 8.3v.05q-.15 4.2.9 7.25 1 3 3.25 4.95 2.25 1.9 9.35 1.6-6.1-3.5-6.05-6.9 0-3.4 1.2-7.7.55-1.9 1.45-3.5-.5 4.25-.15 6.55.3 2.25.7 3.4.8-4.6 7.25-10.55 1.1-.95 2.1-2.25.95-1.2 1.9-2.65.85-1.35 1.4-2.55.15-.4.3-.75h-.3q.45-1.55.45-2.7 0-2.8-2.45-7.55-3.15-6.2-3.6-7.5z"
                    id="HaiHig025__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M26.7 65.2q0-.1.05-.15.1-.65-1.45.4-3.65 2.35-9.9 1.45-.3 0-.65-.05-1.3-.05-2.65-.45-.6-.15-1.1-.35-.55 2.7-1.8 4.45-2.05 3-7.3 4.9-.9.3-1.9.6v.1q.15 0 .25.05.8.2 1.65.35 1.3.15 2.75.2 2.4.15 4.65-.25 4.3-.65 8.1-3 5.55-3 9.3-8.25m7.55-59.65q1.05-1.6 2-3.5l.7-1.5q-.05.45-.05.95.15-.75.25-1.4V.05q-.1.1-.2.3-3.1 4.35-9.5 9.5-4.1 3.25-9.55 6.9-.7.4-1.35.9-.25.15-.45.3-.4.2-.75.45l-.1.05q-.1.05-.15.1-3.3 2.1-5.75 5.5Q8 25.9 7 27.95q-2.4 5-2.65 11.45.05.8.1 1.55.15 1.4.6 4 .4 2.5.5 3.95.25 2.55.25 3.85-.05 2-.75 4.05Q4.3 58.7 3 60.35q1.65-.6 3.95-2.45Q9.2 56 9.6 52.05q.65 2 1.5 4.55.04-.078.15 1.65-.037-1.388 0-1.2.25 2.9.2 5.3 1.2-.45 2.25-1.25h.05q.15-.15.45-.25 1.5-1.55 2.2-4.25.45-1.7.6-3.9.15-4-1.9-7.3-2.15-6.2-1.85-12.5.45-3 1.55-5.55.4-.85.85-1.65h.05q1-2.75 3.75-5.1.1-.1.25-.15 2.1-1.75 5.15-3.25.2-.1.4-.15 3.75-2.75 5.15-5.3.25-.45 1.6-2.6.9-1.45 2.25-3.6z"
                    id="HaiHig025__Symbol_92_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M15.4 0q-.1.55-.25 1.1-.1.4-.25.8-1.15 3.85-1.45 5.2v.1q-.2.7-.95 2.85-.7 2.15-3.05 4.95l-.05-.05q-.35.3-.6.65l-.05.05q-.2.15-.3.35l-.7.7q-3.2 3-4.8 5.55-.6 1.1-1.1 2.3Q.2 28.1 0 32.45q-.1 2.1.55 4.2 1 3.45 3.65 7.05 4.45 5.8 4.35 9.7-.1 3.9-1.65 6.8-1.1 2-1.65 3.35-.05.05-.05.15-.15.25-.2.45-.2.3-.25.65-1.3 1.5-2.45 8.3v.05q-.15 4.2.9 7.25 1 3 3.25 4.95 2.25 1.9 9.35 1.6-6.1-3.5-6.05-6.9 0-3.4 1.2-7.7.55-1.9 1.45-3.5-.5 4.25-.15 6.55.3 2.25.7 3.4.8-4.6 7.25-10.55 1.1-.95 2.1-2.25.95-1.2 1.9-2.65.85-1.35 1.4-2.55.15-.4.3-.75h-.3q.45-1.55.45-2.7 0-2.8-2.45-7.55-3.15-6.2-3.6-7.5-.55-1.55-.85-5.15-.3-3-.25-4.4-.1-7.35-.1-7.6 0-2.35.2-5.7t.2-4.4q0-4.45-1.9-9.45-.95-2.5-1.8-3.95Q15.45.9 15.4 0z"
                    id="HaiHig025__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M23.95 13.5q-.4-.15-.75-.25-6.2-2.2-9.7-5.55Q10.1 4.4 9.25 0v.05Q9.15.7 9 1.45q.3 4.6.15 6.55Q9 9.9 9 11.05q0 1.1-.65 4t-.9 3.7q-.2.8-.55 1.3-.4.5-1.1 2.15-.5 1.2-1.2 2.55-.4.75-2.7 4.7-2.3 4.3-1.8 8.8.5 4.5 1.9 7.25 1.35 2.7 2.4 3.2.1.15.15.3l.1.3q-.3-1.5-.35-3.85-.1-2.4.95-5.55.548-1.745 1.6-2.8-.053.056-.1.15-.2.65-.4 2.95-.4 3.2-.45 3.35.05.15.25 2.45.05.55.15 1.1-.25.25-.25.65.45 4.5 3.35 8.6 2.9 4.05 7.15 4.95 1.4.25 2.95.2-.6 1.55-1.7 3.3-.95 1.45-1.9 2.65.7 2.2.45 4.85-.5 4.1-2.4 7.55l.05.05q-.15.75-3.4 4.2 6.65-2.55 9.2-4.4 2.5-1.85 5.65-6.05 3.15-4.3 4.35-14.8.3-.2.65-.45.15-.05.35-.15.65-.35 1.2-.7 2.4-1.6 5.5-6.2-3.2.5-3.5.55-.35.05-1.25.15-.9.1-2-.05-1.15-.15-1.1-.05-.6-.1-1.05-.2-.05-.05-.05-.15-.1-.35-.3-2.6-.55-5.6 1.65-10.1 2.15-4.5 1.75-8.65-.15-1.8-.65-3.45-.5-1.55-.65-2.05h.1q3 4.25 3.1 6.9.05 2.65.05 2.9.85-1.3 1.5-2.4.9-1.45.7-3.95-.2-2.5-.8-4.2-2.85-7.5-11.05-10.5z"
                    id="HaiHig025__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.25 61.4l-1 .1q-.6 1.55-1.7 3.3-.95 1.45-1.9 2.65.7 2.2.45 4.85-.5 4.1-2.4 7.55l.05.05q-.15.75-3.4 4.2 6.65-2.55 9.2-4.4 2.5-1.85 5.65-6.05 3.15-4.3 4.35-14.8-4.8 2.65-8.9 2.5l-.4.05M0 0q.25 1.75.6 2.8.95 3.15 1 3.45.2 1 .4 1.8.05.2.15.45.15.65.4 1.15.05.1.1.25.15.3.35.6-.1-.05-.15-.05l.05.05q2.05 4.8 6.3 8.85Q13 23 13 28.2q0 3.05-1.25 6t-1.25 5.65q0 3.85 1.85 7.35.5 1 1.2 1.8.45.6 1 1.05.75.6 1.95 1.05.1.05.25.1.65.2 1.35.35.6.1 1.25.2-.05-.05-.05-.15-.1-.35-.3-2.6-.55-5.6 1.65-10.1 2.15-4.5 1.75-8.65-.15-1.8-.65-3.45-.5-1.55-.65-2.05h.1q3 4.25 3.1 6.9.05 2.65.05 2.9.85-1.3 1.5-2.4.9-1.45.7-3.95-.2-2.5-.8-4.2-2.85-7.5-11.05-10.5-.4-.15-.75-.25-6.2-2.2-9.7-5.55Q.85 4.4 0 0z"
                    id="HaiHig025__Symbol_89_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig025__Symbol_93_0_Layer0_0_FILL"
                transform="translate(246.8 183.15)"
                id="HaiHig025__col1n"
            />
            <use
                xlinkHref="#HaiHig025__Symbol_92_0_Layer0_0_FILL"
                transform="translate(246.8 183.15)"
                id="HaiHig025__col1d"
            />
            <use
                xlinkHref="#HaiHig025__Symbol_91_0_Layer0_0_FILL"
                transform="translate(268.3 184.65)"
                id="HaiHig025__col1l"
            />
            <use
                xlinkHref="#HaiHig025__Symbol_90_0_Layer0_0_FILL"
                transform="translate(274.7 183.2)"
                id="HaiHig025__col2n"
            />
            <use
                xlinkHref="#HaiHig025__Symbol_89_0_Layer0_0_FILL"
                transform="translate(283.95 183.2)"
                id="HaiHig025__col2d"
            />
        </g></g>
    )
}
export default HaiHig025
