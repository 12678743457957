import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2d": "#FF98D0",
    "col2n": "#FFCDE8"
}
function ObjMus045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M201.1 12.75q0-1.7-.1-3.45 0-12.95-12.25-7.9-1.5.7-3.15 1.35-.65.3-1.35.65-15.1 6.8-31.05 17.5-.95.75-1.95 1.75-10.35 8-5.85 12.05-15.25-4.9-32.55-4.9-.95 0-1.9.05-.95-.05-1.9-.05-17.45 0-32.85 5 4.25-4.05-5.95-11.95-1-1-1.95-1.75Q52.35 10.4 37.25 3.6q-.7-.35-1.35-.65-1.65-.65-3.15-1.35Q20.5-3.45 20.5 9.5q-.1 1.75-.1 3.45-.05.75-.05 1.5 0 16.6 3.1 35.5.1.3.2.65 2.85 16.3 9.25 12.1-8.9 9.45-14.8 20.25l-7.75 14.4q-2.85 5.95-4.8 13-.15.55-.3 1.15-.15.4-.25.85h-.05l-.05.65q-7.85 23.2-3.1 47.6h-.05q.15 1 .15 1.25h3.9q0-.4-.05-.9 0-.05.05-.35l3-25.7q.4-4.85 1.15-9.65-.45-5.5-.95-12.9.1-.3.2-.55.1-.4.2-.75 1.85-5.65 4.45-11.3 1.95-4.25 4.25-8.4 5.1-9.25 11.75-18.1l1.25-1.65q3.35-4.05 7.15-7.85Q48.5 53.5 60.2 46.9q.3-.2.65-.35h.1q.9-.2 17.7-5.4Q95.4 35.9 102 35.45q.9-.05 1.7-.1h16.85l40.5 11.2h.05q.3.15.6.35 11.7 6.6 21.95 16.85 3.8 3.8 7.15 7.85l1.25 1.65q5.55 7.4 10.05 15.1 3.6 7.25 5.55 11.4 2.55 5.65 4.4 11.3.1.35.2.75.15.25.2.55.95 22.7.85 29.4.9 7.1 2.25 18.85.05.4.05.5.05.35.05.75h3.9q.05-.25.15-1.25 0-.1.05-.2 4.8-23.05-2.85-46.1-.1-.2-.15-.4-.1-.3-.15-.55-.05-.05-.05-.15-.05-.1-.05-.3v-.3q-.05-.05-.05-.15v-.1q-.15-.4-.25-.85-.15-.6-.3-1.15-1.95-7.05-4.8-13l-7.3-14.4q-5.85-10.7-14.7-20.15 6 3.3 8.75-12.4.1-.35.2-.65 3.1-18.9 3.1-35.5 0-.75-.05-1.5M39.8 56q.1-.15.25-.35 1.25-1.05 2.5-2.05-1.4 1.2-2.75 2.4z"
                    id="ObjMus045__Symbol_136_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.4 112.35q.75-3.45.25-5.65-.05-.15-.05-.25-1-3.05-5.5-4.3l-1.95-.65q-3.5-.9-5.7 0-2.25 1-3 3.85-.05.2-.1.35v.1q-.1.3-.2.65 0 .15-.05.25-.1.35-.15.7-.05 0 0 .1l-.1.3q-6.6 14-5.75 24.75.8 10.75 2.45 22.15H2.5q.1.6.15.7.15.55.6 2.05.15.55.25.8 1.2 2.9 4.7 3.95 3.8.6 6.9 0 .5-.15.95-.3 4.4-1.4 3.3-9.9-.15-2.8-.35-6.2-.45-6.4.35-14.35.8-8.65 3.05-19.1M55.95 30.1q8.85-6.6-.05-13-5.35-4-8.65-5.6Q44 9.8 37.5 6.3 32.95 3.8 25.85.9 19.5-1.75 19 5.35q-.3 7.6.1 12.8.45 7.4.7 11 .15 3.7 1.5 10.2 2.15 10.8 11.8 5.5Q43.45 35.8 55.95 30.1m99.4 10.9q-.35-.2-.65-.35-21.55-12.05-48.05-12.05h-1.2q-.45 0-.9.05h-.8v-.05h-1.2q-25.45-.1-47.3 11.65-.35.2-.65.4-.35.15-.7.35-1.2 2.05-.15 3.45.8 1.7 4.5 0 5.9-3.25 11.95-5.55 33.9-11.8 68.85 0 6.05 2.3 12 5.55 3 1.55 4.25 0 1-1.4.05-3.45m53 89.95q-.25-11.7-2.85-17.6-2.6-5.95-2.65-5.95-.05-.15-.05-.3v-.1q-.1-.15-.15-.25v-.05q-.05-.1-.05-.25-.1-.3-.2-.65v-.1q-.05-.15-.1-.35-.75-2.85-3-3.85-2.2-.9-5.7 0l-1.95.65q-4.5 1.25-5.45 4.3-.05.1-.1.25-.5 2.2.25 5.65 2.25 10.45 3.1 19.1.75 7.95.3 14.35-.2 3.4-.35 6.2-1.1 8.5 3.3 9.9.45.15.95.3 3.1.6 6.95 0 3.45-1.05 4.65-3.95.1-.25.25-.8.45-1.5.65-2.4 0-.05.1-.4 0-.2 1.15-6.1 1.15-5.9.95-17.6M189 28.95q.25-3.6.7-11 .4-5.2.1-12.8-.5-7.1-6.85-4.45-7.1 2.9-11.65 5.4-6.5 3.5-9.75 5.2-3.3 1.6-8.65 5.6-8.9 6.4-.05 13 12.5 5.7 22.85 14.75 9.65 5.3 11.8-5.5 1.35-6.5 1.5-10.2z"
                    id="ObjMus045__Symbol_134_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M5.95 6.05q.5-2.4 1.1-4.75L7.1 1q.05-.25.1-1H3.85Q3.8.05 3.8.15 3.75.4 3.7.7l-.05.1-.1.6q-.05.05-.05.1-.35 1.75-.7 3.55-.45 2.5-.85 5Q1 16.15.6 22.15.45 23.6.4 25q-.85 11.8.2 23.25h3.45Q3 36.9 3.65 25q.1-2.25.3-4.5.2-2.45.45-4.8l1.55-9.65m8.5-4.7l.1-.3q-.05-.1 0-.1.05-.35.15-.7.05-.1.05-.25h-3.5v.1q-.05 0-.05.05-.15.65-.3 1.35-.35 1.75-.7 3.55-.45 2.5-.85 5-.95 6.1-1.35 12.1-.15 1.45-.2 2.85-.85 11.8.2 23.25h3.15q-1.05-11.8-.3-23.25.8-12.05 3.6-23.65m201.35 3.7q-.35-1.8-.7-3.55-.15-.7-.3-1.35 0-.05-.05-.05V0h-3.55q0 .15.05.25V.3q.05.1.15.25v.1q0 .15.05.2v.1q.05 0 .05.05 2.85 11.8 3.7 24 .7 11.2-.3 22.75-.05.25-.05.45v.05H218q1.05-11.45.25-23.25-.1-1.4-.25-2.85-.4-6-1.35-12.1-.4-2.5-.85-5m8.25 5q-.4-2.5-.85-5-.35-1.8-.7-3.55 0-.05-.05-.1l-.1-.6-.05-.1q-.05-.3-.1-.55 0-.05-.05-.15h-3.4q0 .15.05.25 0 .55.1.75.1.45.25.95.45 2 .9 4.1l1.55 9.65q.25 2.35.45 4.8.2 2.25.3 4.5.65 11.65-.35 22.8v.25q-.05.1-.05.2h3.45q1.05-11.45.25-23.25-.1-1.4-.25-2.85-.4-6-1.35-12.1z"
                    id="ObjMus045__Symbol_135_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus045__Symbol_136_0_Layer0_0_FILL"
                transform="translate(81.75 20.25)"
                id="ObjMus045__col1n"
            />
            <use
                xlinkHref="#ObjMus045__Symbol_134_0_Layer0_0_FILL"
                transform="translate(88.1 26.15)"
                id="ObjMus045__col2n"
            />
            <use
                xlinkHref="#ObjMus045__Symbol_135_0_Layer0_0_FILL"
                transform="translate(79.5 132.6)"
                id="ObjMus045__col2d"
            />
        </g></g>
    )
}
export default ObjMus045
