import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#3D584C",
    "col1n": "#597B6B",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function ObjToo065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.15 29l.75-5.5L33.65 11 13.1 5l-1.15-1.85.35-.65 4.6.5.55-.9V2h-.1l-.9-.9L5.8 0 .2 5.5l-.2.1 3.1 2.45.05.05 2.95 2.3h.05v.05l14.1 15.4 16 6.85 4.9-3.7z"
                    id="ObjToo065__Symbol_223_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M17.5 3.2l1-1.55L15 1.2v-.05h-.05v.05L5.55 0 0 6.7q.95-.55 1.3-.9 3.4-1.75 4.55-4.1l1.4.15v.05l3.2.4q0-.05.05-.05l1.6.2v.05l3 .4v-.05l2.35.25v.1h.05m24.7 25.85q2.3-6.3.4-8.85-.5-.5-1.15-.8v-.65q0-2.65-1.7-4.1-.9-.6-1.9-.55-.3-2.8-3.65-3.15-.7-2.65-3.9-3.3-1.6-.3-2.8.2-.15.05-.25.15-.1-.3-.25-.55-.8-1.45-2.6-1.2-1.65.3-3.15.9l-.5.2q-.5-.15-1.05-.35-.964-.386-1.75-.65l-.45-.15q-1.093-.392-2.3-.85l-3.25-1.1-.05.05q-.05.1-.05.2-.2.2-.35.45-.9 1.1-2.2 2.85v-.1l-3.1 3.8h-.05q-.05.1-.05.2h-.05l-.25.3.25-.25q-.05.1-.5.6-.3.45-.35.65l6.8 6.1q0 .05.1.1l.05.05q-.15.45-.35 1-.4 1.45-.4 2.5 0 1.35 1.2 2.05h.05q.1.05.15.1.25.15 1.15.15.7 0 1-.15.1 1.4.7 2.3.75 1.05 2.1 1.05.15 0 .55-.1.45-.15 1-.15.15 1.45 1.25 2.35 1.1.9 2.7.9l1.55-.1q.05 1.2.9 1.9t2.25.7q.5 0 1.5-.15.15 0 .5.55.2.25.35.4-.05.1-.05.15l.15.15q.2.15.45.35.4.3.95.55 5.15 1.1 8.2-2.55.8-1.15 1.6-2.8.3-.65.6-1.35m-.95-4.2q0 .05.05.2.35 3.55-1.2 5.45-.35.45-.85.8-1.45 1.1-3.9 1.35-.05 0-.1-.05h-.1q-1.25 0-1.7-.75-.5-.35-.9-.9-.7-.85-.85-1.5-.6.25-1.8.25-2.2 0-3.2-1.2-.7-.8-.7-1.95v-.1q-.4.05-.8.05-2.15 0-3.1-2.45h.05q-.1-.2-.15-.35-.2-.35-.25-.7-.55.1-1.65.1-.8 0-1.8-.6-.8-.5-1.1-.85-.45-.65-.5-1.45-.45.05-1.05.05-.45 0-.9-.25l-.4-.5q-.95-1.2-.95-1.75l.05-.4 5.55-8 .25-.05h1.7q.35.05 1.6.55.45.4.8.9.4.55.65 1.15.25-.15.7-.2.9-.1 1.85.3 1.1.3 2.15 1.45.55.6.95 1.25.25-.1.65-.1 1.85 0 3 1.65.8 1.15.8 1.95v.1h1q1.35.15 2.2 1.3.7.95.7 1.8 0 .4-.05.65 1.3.15 2.65 1.7.55.65.65 1.1M17.4 9.55l-.3.55-3.95 5.25-4.85-4 3.45-4.45 5.65 2.65M9.25 9.2l.05.05h-.1q0-.05.05-.05m18.6 16.6q3.35-2.25 4.65-6.35-3.5 2.3-4.65 6.35M37.2 23q-3.5 1.95-4.45 5.8 3.45-1.9 4.45-5.8m-19.4-3.6q3.8-2.45 5.25-6.9-3.95 2.5-5.25 6.9m10.15-3.8Q24 18.1 22.7 22.5q3.8-2.45 5.25-6.9z"
                    id="ObjToo065__Symbol_145_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M39.9 9.95q-.5-.35-1.15-.75L29.7 2.9 18 1.45q-.15-.05-.25-.05-.95-.2-1.55-.2-.5-.95-1.4-.95h-.2q-.25-.1-.5-.15-.4-.1-.6 0-.45-.25-1.2.05h-.15q-.25.05-.4.2-.25.1-.45.25L6.25 0 2.6 6.05l-.2.2Q1.2 7.2.7 7.75q-.55.65-.65 1.3-.05.2-.05.35 0 .5.2 1.25 0 .25.5.75.3.35.55.45.15.25.35.5.75.85 2 .85 1.15 0 2.15-1.35l-.75-.6-.05-.05L2.2 9.05h.2v-.4q.4-.45.75-.8Q4.3 6.5 4.7 6.05l.3-.4q.15-.2.95-1.6.3-.4.5-.65.1-.15.2-.2l.7-.85 2.4.3L9.1 3.9q-.1.15-.15.3-.3.6-.45 1.1 0 .1-.05.25-.1.2-.1.25-.15.5-.15 1.75V8.6q0 .05.05.15v.15q0 .9.3 1.9.3.95.8 1.4.4.45 1 .5.3.1 1.05.1h.3q.45 0 .9-.3.65-.15 1.35-.6.5-.35.95-.85.45-.35.55-.55.05 0 .15-.25.2-.4.6-1.35.15-.35.3-.65.15-.55.3-.95v-.1q.1-.55.15-1.35v-.2-.75q0-.35-.05-.65v-.5q-.05-.1-.05-.2 0-.15-.05-.35h.05q0 .25.25.4l11 1.45 1.65 1.25q1 .65 1.9 1.2 1.35.8 4.2 2.7.6.35 2.15 1.05.25.1 1.25.6.4.2.75.35 5.2 1.15 2.75-.35-2.4-1.55-2.85-1.9m-27.7-5.5q.05-.1.15-.15.35-.6.65-1.25l.05.05q.4.25.8.25h.05q0 .45.05.95V5.15q0 .35-.25 1.4-.15.75-.45 1.7-.45 1.2-.8 1.9-.05.1-.1.25h-.05q-.15.1-.45.1-.3-.1-.4-.1-.15-.25-.25-.35-.05-.1-.05-.2-.1-.35-.1-.45l-.05-1q0-1.6 1.2-3.95m-1.15 6.85q0-.05.05-.05l.05.05h-.1z"
                    id="ObjToo065__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M2.25 10q-.05 0-.05.05 0 .6-.15 1v.05l-.2.4q-.15.3-.45.4l-.3.1H.6q-.2 0-.35-.15H.2q-.1 0-.2-.05.15.25.35.5.75.85 2 .85 1.15 0 2.15-1.35l-.75-.6-.05-.05L2.25 10m9.5-7l.05.05q.4-.65.4-.7 0-.3-.3-.65-.15-.05-.2-.05-.25 0-.5.15l-.05.05q-.8.75-.85.8l-.2.2q-.35.6-.65 1.2-.2.4-.4.85-.4.95-.55 1.7-.15.7-.15 1.6 0 1.4.6 2.3.2.3.35.55.25.15.5.2 0-.05.05-.05l.05.05q.15-.15.35-.25.7-.4.9-.7l-.05.05h-.05q-.15.1-.45.1-.3-.1-.4-.1-.15-.25-.25-.35-.05-.1-.05-.2-.1-.35-.1-.45l-.05-1q0-1.6 1.2-3.95.05-.1.15-.15.35-.6.65-1.25m3.65-.6v-.05q0-.05-.05-.15-.1-.4-.25-.7-.05-.2-.1-.3l-.05-.05q-.5-.95-1.4-.95h-.2q-.25-.1-.5-.15-.4-.1-.6 0l.05.05q.65.1 1.15.85.2.4.35.85.25.7.35 1.35v.2q.1.45.1 1.2v.1q-.05 1.25-.95 4.1-.1.35-.2.75-.8 2.2-1.75 2.95.65-.15 1.35-.6.5-.35.95-.85.45-.35.55-.55.05 0 .15-.25.2-.4.6-1.35.15-.35.3-.65.15-.55.3-.95v-.1q.1-.55.15-1.35v-.2-.75q0-.35-.05-.65v-.5q-.05-.1-.05-.2 0-.15-.05-.35-.05-.15-.05-.3-.05-.25-.1-.45z"
                    id="ObjToo065__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo065__Symbol_223_0_Layer0_0_FILL"
                transform="translate(97.55 273.2)"
                id="ObjToo065__col1n"
            />
            <use
                xlinkHref="#ObjToo065__Symbol_145_0_Layer0_0_FILL"
                transform="translate(97.55 272.1)"
                id="ObjToo065__col1d"
            />
            <use
                xlinkHref="#ObjToo065__Symbol_96_0_Layer0_0_FILL"
                transform="translate(95.7 270.05)"
                id="ObjToo065__col2n"
            />
            <use
                xlinkHref="#ObjToo065__Symbol_23_0_Layer0_0_FILL"
                transform="translate(96.95 270.1)"
                id="ObjToo065__col2d"
            />
        </g></g>
    )
}

export default ObjToo065
