import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666",
    "col1s": "#252525",
    "col2n": "#FFFFFF"
}

function PetPet008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.1 48.95Q42 44.2 41.5 42.8q-.55-1.4-.75-1.85-.2-.5-1.55-2.65t-4.05-3.1q.05-.8.05-1.6-.15-11.25-4.05-22.5-.6-1.8-1.35-3.6-.95-2.1-1.9-4.15-3.8-6.35-6.4 0-.1.25-.1.55-.1.5-.2 2.75-.3-2.55-.85-3.45-3.95-6.45-7.2.15l-.2.4q-.2.45-.35.9-.05.15-.05.3-.4.75-.6 1.65-.25.8-.5 1.65-.9 2.7-1.2 5.6 0 .2-.05.45-.45 1.85-.75 3.7-.4 2.55-.65 4.35-.25 1.8-.3 2.5-.1.65-.5 3.9-.4 3.2.3 8.05h-.05q0 .05.1.1-4.45 3.6-5.4 9.1-3 11.1 1.85 16.85 1.45 1.45 3.3 2.05l-.05.05-.3.5-.5.8v.05q-.2.25-.3.5-.3.45-.55.9-.3.5-.6 1.05-7.85 12-5.05 24.3 2.2 11.1 11.05 15.8v2.55h20.85v-2.35q.3-.15.55-.3 8.95-5 11-15.65 2.65-12.65-3.55-24-.3-.7-.7-1.4l-.6-.9q-.15-.25-.35-.5v-.05l-.1-.2-.4-.6q-.25-.4-.5-.75 4.3-1.8 5.45-8 .65-3.6-.35-7.75m-21.4-23.4q.3 3 .35 3.6.05.6.1 1.75t.05 2.1q-.05.3 0 .45-.75 0-1.1.05.15-.5.2-1.05l.2-2.3q.05-1 .2-4.6z"
                    id="PetPet008__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.45.2H5.4Q5.2.1 5.05.05 4.95 0 4.85 0 2.95.35 2.1 2.1v.1Q.55 5.35.2 7.85q-.3 2.15-.1 4.3.1 1.7.95 3.2.5 1 1.3 1.85.15.1.35.25h.05q.2.1.4.15.1.05.2.05t.25.05q.2-.05.35-.1.35-.2.65-.65v-.05l.05-.05q0-.05.05-.1.05-.2 0-.35v-.1q-.1-.4-.15-.7-.3-1.3-.4-2.6-.05-.35-.05-.65v-1.2q.05-2.45.85-4.7.3-.85.7-1.65Q6 4 6.3 3.2q.05-.25.1-.45.15-.65.1-1.1-.05-.35-.2-.6Q6.05.5 5.45.2M46.4 35.05l-.6-.1q-.1-.05-.15-.05-1.1-.25-2.25-.35-.35-.05-1.7-.15-.85-.05-1.8-.15l-1.2-.1q-.85-.1-1.7-.15l-1.75-.2-3.45 1.45q-7.15 2-14.15-.1-1.25-.4-2.5-.9l-.4-.1q-.35-.1-.6-.2h-.5q-1.05.1-2.05.2-2.1.25-4.2.6-1.75.25-3.5.55-2.75.95-1.95 3.25l9.65-.1h.65l27.05-.2q.3-.05.65 0h2.45q2.95 0 5.95.05.8-2.3-1.95-3.25M48 2.2v-.1Q47.15.35 45.3 0h-.05q-.3.05-.5.2-.7.3-.95.85-.15.25-.2.6-.05.45.1 1.1.05.2.1.45.3.8.7 1.6.35.8.7 1.65.75 2.25.8 4.7.05 2.25-.4 4.45-.1.3-.15.7-.1.2 0 .45 0 .05.05.1v.05q.45.9 1.3.75.05 0 .2-.05.15-.05.4-.15.2-.1.35-.25.8-.85 1.35-1.85.8-1.5.95-3.2.15-2.15-.15-4.3-.35-2.5-1.9-5.65z"
                    id="PetPet008__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M9.6 4.4q.05-.1.05-.15 0-.15-.1-.2Q9.45 4 9.4 4l-9.15.95q-.15 0-.2.1-.05.1-.05.2t.1.15q.1.05.2.05l9.15-.95q.1 0 .15-.1m.15 1.2q-.1-.05-.2 0l-7.3 3q-.1 0-.1.1-.05.1 0 .2 0 .1.1.15h.2l7.3-3q.1-.05.15-.15v-.2q-.05-.1-.15-.1m.1-2.8q0-.1-.05-.2t-.15-.1L.7 1.15q-.1 0-.2.05t-.1.2q0 .05.05.15.05.1.2.1L9.6 3q.05 0 .15-.05.1-.05.1-.15m5.7.6L17 1.9q.35-.3.35-.75T17 .35Q16.65 0 16.2 0q-.45 0-.8.35-.15.15-.25.4-.1-.25-.25-.4-.35-.35-.8-.35-.45 0-.8.35-.3.35-.3.8 0 .35.2.65.05.05.1.15.1.05.15.1l1.4 1.35h.7m5.55 2.05q-.15-.05-.2.05-.1.05-.15.15-.05.1.05.2.05.05.15.1l7.6 2.2q.1.05.2 0 .05-.1.1-.15.05-.15 0-.2l-.15-.15-7.6-2.2m8.5-5.3q-.1-.1-.2-.05l-8.75 2.3q-.1.05-.15.15-.1.05-.05.2l.15.15q.05.05.2 0L29.55.6q.05-.05.15-.1.05-.1 0-.2t-.1-.15m.95 3.9q0-.1-.05-.15-.1-.1-.2-.1l-9.25.1q-.1 0-.15.1-.1.05-.1.15 0 .1.1.2.05.05.15.05l9.25-.1q.1 0 .2-.05.05-.1.05-.2z"
                    id="PetPet008__Symbol_49_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M26.35 3.75l-.2-.3q-.75-1.25-1.5-2.4l-.1-.1q-.25-.35-.5-.6l-1.7.5-.7.2q-1.05.3-2.05.5-3.85.7-7.65.25-1.8-.25-3.55-.75Q7.95.9 7.5.7h-.05q-.7-.3-1.4-.55-.2-.05-.2-.15-.1.1-.15.25-.4.65-.5.8-.8 1-1.4 2.15-.1.1-.15.25-.15.2-.25.45l-.1.2Q2 6.65 1.2 9.4.25 12.5.15 15.85q0 .5-.05 1-.1 1.85-.1 3.7.1 1.7.3 3.4 0 .2.05.45.05.35.2.7l.2.6q.05.6.15 1.25.05.35.25 1.2.2.8.5 1.55.3.7.7 1.85.1.2.2.5.25.65.45.8.1.2.25.45v.05q.1.1.2.25l.3.4q.5.7.95 1.1l.1.2q1.45 1.8 3.6 2.8 3.15 1.4 6.7 1.3 1.25-.05 2.5-.4 2.45-.8 4.55-2.3.5-.4 1-.75.5-.45.95-.9.75-.55 1.3-1.3.05-.1.15-.2 1.05-1.4 1.65-3 .75-2.15 1.3-4.4.5-2 .6-4.1 0-.15.05-.3.15-1.65.25-3.2.2-3.3-.3-6.45-.25-1.6-.65-3.15-.65-2.55-1.9-4.85-.1-.1-.15-.2-.05-.05-.05-.15z"
                    id="PetPet008__Symbol_32_0_Layer0_0_FILL"
                />
                <g id="PetPet008__Layer3_0_FILL">
                    <path
                        fill="#FFF"
                        d="M351.8 327.55v-.15h-.05q-1.45.2-2.4.75-.319.182-.55.3l-.3.2h-.05v4.4q1.719.464 3.5.5h.3l-.25-.05q-.3-3.45-.2-5.95m4.3 5.45v-4.15h-.05q-.15-.1-.25-.15l-.05-.05q-1.664-1.35-3.4-1.3 0 2.55.25 6.2h.05q1.72-.105 3.45-.55m9.95-19.85q-.05-.3-.15-.6-.4-1.5-1.15-2.7-.95-1.5-2.45-2.5-2.8-1.8-5.8-1-.4.1-.75.25-1.75.65-2.9 2.2-.45.55-.8 1.3-.5.95-.7 2-.25-.85-.65-1.6-1.2-2.5-3.45-3.45-.1-.05-.2-.05-.4-.15-.8-.25-3-.8-5.75 1-1.5 1-2.45 2.5-.65 1-1.05 2.25-.05.2-.1.45-.95 3.35.5 6.3.8 1.55 2.05 2.55.15.1.3.25.95.65 2.15.95 2 .55 3.9-.05l.2-.1q.8-.35 1.65-.85.25-.2.55-.4 1.65-1.3 2.55-3.25.35-.75.55-1.55.05-.25.15-.5 0 .05.05.15.2.75.5 1.5 1.05 2.25 3.15 3.65.9.6 1.8.95 1.9.6 3.95.1 1.15-.35 2.1-1 .2-.15.35-.25 1.2-1 2-2.55 1.3-2.65.7-5.7m-12.95 1.9q0-.6.45-1.05.45-.45 1.05-.45.6 0 1.05.45.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.05.45-.6 0-1.05-.45-.45-.45-.45-1.05m-5-1.5q.6 0 1.05.45.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.05.45-.6 0-1.05-.45-.45-.45-.45-1.05 0-.6.45-1.05.45-.45 1.05-.45z"
                    />
                    <path d="M356.4 328.3l-.25-.25q-.15-.15-.25-.2-1.356-1.05-3.05-1.2h-.5v-.05l.05-.05h-.7q-.053.027-.15 0l.05.05v.05h-.1q-1 .2-1.95.7-.6.3-1.1.7-.1.05-.2.15-.1.05-.1.1.15.15.3.35h.05l.3-.2q.231-.118.55-.3.95-.55 2.4-.75h.05v.15q-.1 2.5.2 5.95l.25.05h.35q-.25-3.65-.25-6.2 1.736-.05 3.4 1.3l.05.05q.1.05.25.15h.05q.2-.4.3-.4v-.15m-7.25-14.3q-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05m4.4 0q-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45z" />
                    <path
                        fill="#FF8E8E"
                        d="M355.3 274.45v-.05q-1.8-3.35-2.3-.3-.2 1-.2 2.65-.2 1.65-.2 3.35.007.365 0 .7-.104 9.863.45 19.7 3.652.098 6.4.75 1.05.25 1.7.5.079-.087.15-.25.223-8.538-2.15-17.1-.586-2.116-1.1-3.6-.3-1.05-.65-2-.7-1.85-1.65-3.55-.25-.4-.45-.8m-13.7 6.25q-.112.483-.25.95-2.199 10.438-1.75 20.85.117.033.25-.05.35-.25 1.45-.6t3.35-.85q1.1-.2 2.95-.35.093.457.7-4.7.601-5.117.05-16.2-.584-4.287-2.15-6.9-.103-.198-.2-.4l-.05-.15q-.288-.423-.6-.6-1.404-.39-3.75 9z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet008__Symbol_80_0_Layer0_0_FILL"
                transform="translate(328.6 267.05)"
                id="PetPet008__col1n"
            />
            <use
                xlinkHref="#PetPet008__Symbol_58_0_Layer0_0_FILL"
                transform="translate(326.35 342.05)"
                id="PetPet008__col1d"
            />
            <use
                xlinkHref="#PetPet008__Symbol_49_0_Layer0_0_FILL"
                transform="translate(336.85 323.15)"
                id="PetPet008__col1s"
            />
            <use
                xlinkHref="#PetPet008__Symbol_32_0_Layer0_0_FILL"
                transform="translate(336.5 333.7)"
                id="PetPet008__col2n"
            />
            <use xlinkHref="#PetPet008__Layer3_0_FILL" />
        </g></g>
    )
}

export default PetPet008
