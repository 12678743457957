import { AssetSvgProps } from "../../../shared/assets"

function PlaFor004({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="PlaFor004__Layer5_1_FILL">
                    <path
                        fill="#D9D9D9"
                        d="M116.5 396.2V463h254.05v-66.8H116.5m-12 11.25V396.2H12.05v11.25h92.45m192.7-126.2q-.45 1.1-.8 2.2h-.05q-1.1.3-1.95.8-.6-.25-1.1-.6-.55-.3-1.25-.35-.9.45-1.4 1.35V285.05h.05q.5 1 .95 2-.55.9-.85 1.95-.05 0-.05.05l-1.5.5q-.45.1-.75.35-.45 1.1 0 2.05.45.5 1.25.65.55.1 1.05.3.35 1.05.85 1.95v.05q-.5.95-.95 1.95h-.05v.45q.5.7 1.15 1.25.05 0 .05.05.2.05.45.05h.05q1-.5 2-.95 0-.05.05-.05.9.5 1.95.85h.05q.35 1.25.95 2.3.8.4 2.05 0l.1-.1q.4-1.05.75-2.2 1.15-.3 2-.8 0-.05.05-.05 1.05.6 2.25.95h.15q.8-.45 1.3-1.25 0-.05.05-.05v-.35-.1l-1-1.9q.5-.9.85-2.05 1.2-.3 2.2-.9.45-1.1 0-2.1-1-.55-2.2-.9-.3-1.15-.85-2 .5-1 1-1.95V285v-.45q-.15-.2-.35-.45-.45-.65-1.35-.8h-.05q-1 .45-2 .95h-.05q-.85-.5-1.95-.85-.4-1.15-.8-2.2-.1-.1-.2-.15-.95-.3-1.9 0-.15.05-.2.15v.05m-3.05 9.7q0-1.7 1.25-2.9 1.2-1.25 2.9-1.25 1.75 0 2.95 1.25 1.2 1.2 1.2 2.9 0 1.75-1.2 2.95-1.2 1.2-2.95 1.2-1.7 0-2.9-1.2-1.25-1.2-1.25-2.95m-149.4 30.8v10h31.5v-10h-31.5m-102.5 0v10h62.25v-10H42.25m28.5-30.8v.05q0 1.45 1.05 2.5.95 1 2.35 1.05h.15l3.25.05.4.4 7.25 5.35V281.5l-7.25 5.35-.4.4-3.25.05h-.15q-1.4.05-2.35 1.05-1.05 1.05-1.05 2.5v.1m-22-9.45H43.8v18.75h4.95V281.5m-10.05 0h-4.95v18.75h4.95V281.5m332.5 91.95V344.2H12v29.25h359.2m-21.95-79.15h-2.45v4h-3.9v2.2h6.35v-6.2m-16.55 0h-2.45v6.2h6.25v-2.2h-3.8v-4m-2.45-6.4h2.45v-4.2h3.8v-2.2h-6.25v6.4m12.65-6.4v2.2h3.9v4.2h2.45v-6.4h-6.35z"
                    />
                    <path
                        fill="#8E8E8E"
                        d="M129.7 319.85q-.55-.1-.95.3-.4.45-.4 1.25v1.15q0 .15-.05.65v.3q0 .55-.05.8-.05.1-.05.25-.1.15-.2.25l-.3.3-.2.1q-.15.05-.8.1-.5.15-.5.5-.05.75-.05 2.25v1.85q.05.6.25.95.4.55 1.5.85h.7q.2.05.55.05h2.95q1.6-.1 2.15-.6.35-.35.35-.95 0-.4-.05-.45 0-.05-.05-.15v-.05q0-.45.4-.75t.4-.75q0-.35-.3-.8l-.25-.4q0-.05.05-.15l.3-.3q.2-.25.2-.55 0-1.1-1.35-1.05h-1l-.1-.05h-1.35l.05-.15q0-.7-.15-1.3-.15-1-.2-1.55-.05-.55-.45-1-.05-.15-.15-.25-.35-.55-.9-.65M11.95 327q-.15.15-.15.3 0 .2.15.35 4.7 5.3 9.4 5.25 4.75.05 9.45-5.25.15-.15.15-.35 0-.15-.15-.3-4.7-5.3-9.45-5.25-4.7-.05-9.4 5.25m4.25.3q0 2.15 1.5 3.65l.15.15q-2.45-1.15-4.9-3.8 2.35-2.5 4.7-3.65-1.45 1.55-1.45 3.65m8.85 3.65q1.55-1.5 1.55-3.65t-1.5-3.65q2.35 1.15 4.7 3.65-2.4 2.65-4.85 3.75l.1-.1m313-2.05v5l6.5-6.5-6.5-6.5v3.6q-15.7-4.05-17.25 9.3 4.6-8.55 17.25-4.9m29.4 3.3q0 .8.55 1.35.6.6 1.4.6.8 0 1.35-.6.55-.55.55-1.35t-.55-1.35q-.55-.55-1.35-.55t-1.4.55q-.55.55-.55 1.35m3.85-4.85q0-.8-.55-1.4-.55-.55-1.35-.55t-1.4.55q-.55.6-.55 1.4 0 .75.55 1.35.6.55 1.4.55.8 0 1.35-.55.55-.6.55-1.35m-.55-6.4q-.55-.55-1.35-.55t-1.4.55q-.55.6-.55 1.4 0 .8.55 1.35.6.55 1.4.55.8 0 1.35-.55t.55-1.35q0-.8-.55-1.4z"
                    />
                    <path
                        fill="#FFF"
                        d="M26.6 327.3q0 2.15-1.55 3.65l-.1.1q2.45-1.1 4.85-3.75-2.35-2.5-4.7-3.65 1.5 1.5 1.5 3.65m-8.9 3.65q-1.5-1.5-1.5-3.65 0-2.1 1.45-3.65-2.35 1.15-4.7 3.65 2.45 2.65 4.9 3.8l-.15-.15M95.65 275v-3H21v3h74.65z"
                    />
                    <path
                        fill="#D6D6D6"
                        d="M482.55 384.7v-1.5h-578.1v1.5h578.1z"
                    />
                    <path fill="#BDBDBD" d="M361.45 275v-3H95.65v3h265.8z" />
                </g>
                <g id="PlaFor004__Layer5_2_FILL">
                    <path
                        fill="#8E8E8E"
                        d="M12.05 26.6h20.5v-4.1h-20.5v4.1m0 4.2v4.1h20.5v-4.1h-20.5m20.5-12.55V14.2h-20.5v4.05h20.5m335.3 13.5q-.8-.45-1.75-.75 1.2-.65 2.2-1.65 2.7-2.7 2.7-6.55 0-3.85-2.7-6.55-2.7-2.7-6.55-2.7-3.85 0-6.55 2.7-2.7 2.7-2.7 6.55 0 3.85 2.7 6.55 1.05 1.05 2.35 1.7-.85.3-1.65.7-2.3 1.35-2.95 4.9h18.15q-.9-3.55-3.25-4.9z"
                    />
                    <path
                        fill="#D6D6D6"
                        d="M280.8 9.5q-.25-.25-.55-.25h-168.7q-.3 0-.5.25-.25.2-.25.5v28.5q0 .3.25.55.2.2.5.2h168.7q.3 0 .55-.2.2-.25.2-.55V10q0-.3-.2-.5m-1.3 1.25v27H112.3v-27h167.2m-7.5 11.8q-.05-3.65-2.6-6.2-2.55-2.55-6.1-2.55-3.6-.05-6.2 2.55-2.55 2.55-2.5 6.2.05 3.55 2.55 6.1 2.55 2.55 6.15 2.6 2.6 0 4.65-1.35l5.05 5 2.75-2.75-5.05-5q1.35-2.1 1.3-4.6m-5.1-3.75q1.5 1.5 1.5 3.7.05 2.15-1.45 3.7-1.5 1.5-3.7 1.45-2.25.05-3.75-1.45-1.5-1.55-1.45-3.7 0-2.2 1.5-3.7 1.5-1.55 3.7-1.5 2.15-.05 3.65 1.5m215.65 30.45v-1.5h-578.1v1.5h578.1z"
                    />
                </g>
                <path
                    fill="#FFF"
                    d="M482.55 463V-21.25h-578.1V463h578.1M371.45 59.5V307h-360V59.5h360z"
                    id="PlaFor004__Layer5_0_FILL"
                />
            </defs>
            <use xlinkHref="#PlaFor004__Layer5_0_FILL" />
            <use xlinkHref="#PlaFor004__Layer5_1_FILL" />
            <use xlinkHref="#PlaFor004__Layer5_2_FILL" />
        </g></g>
    )
}

export default PlaFor004
