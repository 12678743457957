import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#666666",
    "col2n": "#000000"
}

function ObjMus008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48.7.05v15.2q-3.25 26.25-28.6 28.6H.15q0 .15-.05.8-.1.6-.1.95.05.1.05.25H20.3Q47.25 43.4 50.7 15.5V0q-.1.05-.15.1H48.8q-.05-.05-.1-.05z"
                    id="ObjMus008__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.3 80.5q-2.95-2.05-7.1-2.05-2.3 0-4.2.65-1.55.5-2.9 1.4Q0 82.6 0 85.7q0 3.05 3.1 5.2v-.05q1.35.95 2.9 1.5 1.9.6 4.2.6 4.15 0 7.1-2.05 2.35-1.65 2.95-3.8v-.15q0-.15-.05-.25 0-.35.1-.95.05-.65.05-.8v-.05q-.35-2.6-3.05-4.4M68.6 0Q66.95.2 67 2.3v38.1q-.15 1.45-.3 2.95v.15q-.25 2.6 2.2 2.7v-5.05q.05 0 .1.05h1.75l1.45-1.45h1.4V7.15h-1.1l-.05-.05q-.6-.55-1.25-1.05l-.45-3.85Q70.55 0 68.6 0m2.3 36v.1h-.05q.048-.048.05-.1z"
                    id="ObjMus008__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus008__Symbol_94_0_Layer0_0_FILL"
                transform="translate(238.7 173.5)"
                id="ObjMus008__col1n"
            />
            <use
                xlinkHref="#ObjMus008__Symbol_25_0_Layer0_0_FILL"
                transform="translate(218.5 132.4)"
                id="ObjMus008__col2n"
            />
        </g></g>
    )
}

export default ObjMus008
