import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiSho098({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M171.1 25q-1.7-1.9-3.7-2.35-.3-.05-.55-.1-3-.8-5.55-2.3l-.8-.5q-2.1-1.4-3.05-2.95-1.25-2.05-4.1-3.55-3.15-1.7-6.55-1.75-2.55-.05-4.4-.25-2-.25-3.15-.6-.4-.15-.95-.45-.2-.2-.4-.3-2.6-2-3.85-2.95-.85-.6-2.05-1.1-.8-.35-1.8-.65-2.7-1.1-4.7-1.1l-.8.1q-2.7.35-5 .6-.8.05-1.55.1H117.5q-5.4.05-10.85-1.9-.65-.25-1.35-.5Q102 1.15 97.95.55q-2.5-.4-5.25-.5V0q-.65 0-1.25.05Q90.85 0 90.2 0v.05q-1.7.1-3.25.25-4.45.5-8.1 1.75-.65.2-1.25.45-1.6.65-3.2 1.1-1.9.2-4.05.3-.4 0-.9.05Q67.7 4 64.4 4q-.45-.05-.85-.1-10.25-1.15-18.6 4.2-.65.4-1.25.9-1.4.95-2.75 2.15-2.05 1.05-4.65 1.4-1.45.2-3.1.15-3.5-.1-6.9 2.2-1.35.9-2.95 2.55-.35.3-.65.65-2.9 2.9-5.35 3.7-.35.1-.65.15-3.2.7-4.9 2.1-1.7 1.3-2.75 3.9-1.55 3.7-1.45 8.65 0 .75.05 1.45 0 3.85-.25 5.55-.1.3-.15.6-.05.05-.05.15-5.15 16.2-6.45 20.1-.4 1.15-.75 2.3L21.7 55.5q3.15-9.7 21.3-13.85.2-.1.4-.1.95-.25 1.9-.3 4.05-.35 8.6.8 2.6.65 4.15.95 1.15.2 1.75.25.95 0 10.4 2 21.3 6.35 42.6 0 3.9-.85 6.4-1.3 3.35-.7 3.9-.7 1.35-.05 5.9-1.2 3.1-.8 5.95-.85.4-.05.85 0 .55-.05 1.1 0 .35 0 .7.05h1.35q22.45 5.3 19.75 12.5l24.3 13.3q-.4-1.3-.85-2.6-1.3-3.9-6.45-20.1-.2-.8-.45-1.55-.25-1.85-.55-4.8-.35-3.55-.9-6.65-.3-1.75-.9-3.1-.25-.85-.7-1.65-.45-.85-1.1-1.6z"
                    id="HaiSho098__Symbol_224_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M88.55 2.6q-3.95 2.65-6.5 4.25-2.55 1.55-9.2 5.2-7.3 3.95-10.4 4.55-3.1.6-4.7.2 4.7-1 7.8-4.45 3.05-3.45 3.05-7 0-1.05-.35-2.65v-.15q-.1.15-.15.3-.45 1.75-5.9 6.4-7.9 5.6-12.55 5.6-1.5 0-2.85-.75h-.2q-3.7-1.7-8.15-8.05Q36 2.55 34.95 0l-.25 1.75q-.35 2.65-.45 4.45-.15 1.75 1.75 5.4 1.85 3.55 5.4 5.05-.4.1-5.15-.5-4.75-.65-9.55-2.9-4.85-2.25-6.9-3.9-2.1-1.65-3.7-2.5-3.9-1.7-9.2-5.9L10 4.8q.95 2 3.4 5 .1.1.2.25-6.1.6-7.45 1.35-1.4.75-6.15 2.45 1.65-.25 3.3-.4.2-.1.4-.1.95-.25 1.9-.3 4.05-.35 8.6.8 2.6.65 4.15.95 1.15.2 1.75.25.95 0 10.4 2 21.3 6.35 42.6 0 3.9-.85 6.4-1.3 3.35-.7 3.9-.7 1.35-.05 5.9-1.2 3.1-.8 5.95-.85.4-.05.85 0 .55-.05 1.1 0-4.65-2.5-16.65-1.75 5.6-3.65 8.75-7.45 3.2-3.85-.75-1.2z"
                    id="HaiSho098__Symbol_223_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M13.55 14.6q-3-3-7.55-5.25-.35.3-.65.65-2.9 2.9-5.35 3.7.55.15 1.2.35h4.2q6.65.8 10.2 3.05-.95-1.4-2.05-2.5M26.35.9q-1.4.95-2.75 2.15-2.05 1.05-4.65 1.4.6.1 1.15.2h4.6q2.6.25 8.3 2.7.5.2 1 .45-1-2.5-1.9-3.9-1.05-1.6-3.25-3.1-.6-.4-1.25-.8-.65.4-1.25.9m98.7 2.25q-2-.25-3.15-.6-.4-.15-.95-.45-.2-.2-.4-.3-.3.35-.65.75-1.95 2.3-3.35 4.15-1.45 1.75-2.3 3 .8-.6 1.95-1.2 1.3-.75 3-1.6.3-.15.65-.3 3.75-2.75 5.2-3.45M143 15.6q.7-.15 1.45-.3 2.45-.55 5.05-.85-3-.8-5.55-2.3l-.8-.5q-2.7.8-4.8 1.75-.9.4-1.65.8-3.75 2.7-6.45 4.45h.15q.5-.25 1.2-.45 2-.8 6.65-1.75 2.4-.45 4.75-.85z"
                    id="HaiSho098__Symbol_222_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M42.5 3.5q.75-.55 1.55-1Q46.45 1 48.7.4q-1.65.15-3.3.4Q36.95 2 28.9 4.65q-3.8 1.25-6.85 2.6-.15.05-.3.15-4.5 2-7.15 4.15h10.75Q22 13.3 18.85 14.7t-6.25 3.1q-2.9 1.55-6.9 7.7-.1.35-.2.65-.45 1.35-.8 2.7-.3.95-.55 1.9-1 3.4-1.75 6.7-4.05 16.4-1.3 33.3.6 3.7 1.55 7.45Q10 48.95 17.3 43.4q8.15-6.25 11.55-16.3l-.05.65q1.25-6.55 5.25-13.8 4-7.3 8.45-10.45m129.45 3.45q-3.05-1.35-6.85-2.6Q155.15 1.1 144.65 0h-1.35q.95.05 1.9.3 2.5.55 5.05 2.2.75.4 1.45.95.05 0 .1.05 4.45 3.15 8.45 10.45 4 7.25 5.25 13.8l-.05-.65q3.4 10.05 11.55 16.3 7.3 5.55 14.65 34.8 0-.05.05-.05.15-.8.35-1.55.25-1.1.5-2.15 3.85-18.8-.65-37-.7-3.15-1.6-6.4-.1-.3-.15-.55-.7-2.35-1.45-4.7-4.25-6.65-7.3-8.3-3.1-1.7-6.25-3.1-3.15-1.4-6.5-3.15h10.75q-2.75-2.25-7.45-4.3z"
                    id="HaiSho098__Symbol_221_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M4.15 4.4q-1 3.4-1.75 6.7-4.05 16.4-1.3 33.3.6 3.7 1.55 7.45Q.25 27.6 16.4.15 9.75-.8 4.15 4.4m188.4 43.7q3.85-18.8-.65-37-.7-3.15-1.6-6.4-.1-.3-.15-.55-5.5-4.95-12-4 15.6 26.55 13.9 50.1.25-1.1.5-2.15z"
                    id="HaiSho098__Symbol_220_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho098__Symbol_224_0_Layer0_0_FILL"
                transform="translate(100.8 49.75)"
                id="HaiSho098__col1n"
            />
            <use
                xlinkHref="#HaiSho098__Symbol_223_0_Layer0_0_FILL"
                transform="translate(140.5 77.95)"
                id="HaiSho098__col1d"
            />
            <use
                xlinkHref="#HaiSho098__Symbol_222_0_Layer0_0_FILL"
                transform="translate(118.15 57.85)"
                id="HaiSho098__col1l"
            />
            <use
                xlinkHref="#HaiSho098__Symbol_221_0_Layer0_0_FILL"
                transform="translate(95.1 91)"
                id="HaiSho098__col2n"
            />
            <use
                xlinkHref="#HaiSho098__Symbol_220_0_Layer0_0_FILL"
                transform="translate(95.1 117.35)"
                id="HaiSho098__col2d"
            />
        </g></g>
    )
}
export default HaiSho098
