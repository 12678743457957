import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D",
    "col2n": "#830000"
}

function FacMou037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M216.35 215.4l-7.75-2.75-9.75-1.35-16.2.45-13.9 3.8v.1l7 6.55 16.4 4.35 19.2-6.85 5-4.3z"
                    id="FacMou037__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M21.85 3.75q.6-.25 1.2-.55Q24.1 2.65 25 2.1q-.5-.25-1.15-.5-4-1.55-9.6-1.6h-.4Q8 0 3.85 1.6.8 2.8 0 4.35l.9.3L5.15 5.8q2.5.4 5.05.4t5.05-.4l4.25-1.15q1.2-.4 2.35-.9z"
                    id="FacMou037__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M40.5 23q.95-1.65 2-3.1 3.1-4.5 6.9-7.8Q43.85 9 38.65 4.05q-4.1-4.4-8.05-4-2.5.05-5.9 2.45Q21.3.1 18.8.05q-3.95-.4-8.05 4Q5.55 9 0 12.1q5.5 4.8 9.6 12.05 3.5 5.5 9.95 7.3 1.8.45 4.15.45h2q2.35 0 4.15-.45 6.45-1.8 9.95-7.3.35-.6.7-1.15m5.75-9.85q-2.5.9-4.1 2-.55.3-1.05.65-.1.15-.4.25-.65.45-1.35.85-.9.55-1.95 1.1-.6.3-1.2.55-1.15.5-2.35.9L29.6 20.6q-2.5.4-5.05.4t-5.05-.4l-4.25-1.15-.9-.3q-.75-.3-1.45-.6-.6-.25-1.2-.55-1.8-.95-3.3-1.95-.3-.1-.4-.25-.5-.35-1.05-.65-1.6-1.1-4.1-2-.15-.2-1.8-.65l1.6.2q1.55-.05 1.75-.05h.15q4-.2 8.25-1.65.75-.25 1.45-.5h.05q.1-.05.3-.1.45-.15.9-.25l.9-.2q3.6-.6 7.15-.7h2q3.55.1 7.15.7.65.15 1.3.35.2 0 .4.1.9.25 1.9.6 4.25 1.45 8.25 1.65h.15q.2 0 1.75.05l1.6-.2q-1.65.45-1.8.65z"
                    id="FacMou037__Symbol_62_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M28.4 17.25q-.6.35-1.35.85l-.45.25q-.9.45-1.75.8-4.1 1.7-8.15 2.15H13.25q-3.6-.4-7.25-1.8-.3-.1-.55-.2-1.1-.45-2.1-.95l-.45-.25q-.75-.5-1.35-.85Q.5 16.7.2 16.85q-.05 0-.1.05-.35.35.55 2 .25.35.6.75 1.3 1.55 3.65 2.65.75.35 1.55.75.95.4 2 .8 2.85 1 5.6 1.15h1.85q2.75-.15 5.6-1.15 1.15-.45 2.15-.9.6-.25 1.1-.5.15-.05.3-.15 2.35-1.1 3.65-2.65.35-.4.6-.75.9-1.65.55-2-.05-.05-.1-.05-.35-.15-1.35.4m-4-13q2.85.7 3.5.95.05 0 .05.05 1.05.05.75-.55-.05-.05-.1-.15-.05-.05-.1-.15l-.15-.15Q25.75 1.95 24.6 1q-1.1-1-3.3-1-2 0-5.6 1.95l-.75.1-.75-.1Q10.6 0 8.6 0 6.4 0 5.3 1q-1.15.95-3.75 3.25l-.15.15q-.05.1-.1.15-.05.1-.1.15-.3.6.75.55 0-.05.05-.05.65-.25 3.5-.95 2.8-.75 8.7-.75.35-.05.75-.05t.75.05q5.9 0 8.7.75z"
                    id="FacMou037__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou037__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou037__Symbol_122_0_Layer0_0_FILL"
                transform="translate(182 217.9)"
                id="FacMou037__col2n"
            />
            <use
                xlinkHref="#FacMou037__Symbol_62_0_Layer0_0_FILL"
                transform="translate(167.65 203.1)"
                id="FacMou037__col1n"
            />
            <use
                xlinkHref="#FacMou037__Symbol_16_0_Layer0_0_FILL"
                transform="translate(177.05 206.65)"
                id="FacMou037__col1l"
            />
        </g></g>
    )
}

export default FacMou037
