import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#5F00C2",
    "col1s": "#200041",
    "col2n": "#FF5BFF",
    "col2s": "#B80AB8"
}

function LegSoc005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.9 7.75q-.15-.05-.25-.1-1.15-.5-2.25-.95-1.6-.6-3.2-1h-.05l-.4-.1-.15-.05q-.3-.05-.55-.1-.35-.1-.65-.15v-.05q-.2-.05-.35-.05V0H10.4v5.2q-.85.15-1.7.35-.6.15-1.15.3Q7 6 6.5 6.2q-.6.15-1.2.4-1.25.45-2.5 1.05l-.2.1q-.35.15-.65.3-.9.45-1.8.95H.1q-.05 0-.05.05-.05.05 0 .1l.05.05q0 .05.05.05h146.1q.05 0 .1-.05 0-.05.05-.05v-.1q-.05-.05-.1-.05h-.05q-.9-.5-1.75-.95-.35-.15-.6-.3z"
                    id="LegSoc005__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M58.1 5.6q-.25-.1-.45-.15-.9-.3-1.75-.5-.45-.1-.85-.25-.1 0-.2-.05-.15-.05-.25-.05-.1-.05-.15-.05-3.85-1.05-7.65-1.8-.15-.05-.25-.05Q39.65 1.4 32.6 1q-.4-.05-.8-.05-.4-.05-.85-.05l-6.8-.05h-.2l-3.9.15-1.3.1Q10.4 1.7 5.8 2.7q-.15 0-.25.05-2.75.6-4.1.95-.3.05-.5.15-.55.1-.75.2l-.2.1q5.4-.9 10.95-1.4.25-.05.55-.05 2.3-.2 4.6-.35.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q10-.4 19.7.55.25 0 .5.05 4.95.5 9.85 1.4l4.15.7q1.15.2 2.3.45l1.5.3q.15.05.25.05l.3.1 1.4.3q.15.05.25.1h.05q.1 0 .2.05.15 0 .3.05-.25-.1-.55-.15-.1-.05-.25-.1-.6-.2-1.25-.4m4.35-1.05h-.35l.15 1.2v-.4l.05.4.15-1.2m.4-3.7q-.55-1.7-1.15 0l.2 1.85q.2 1.35.75 0l.2-1.85m59.35 3l.55.05q.75.1 1.55.25V4.1q-.05-.05-.1-.05-.65-.3-5.35-1.3-.1-.05-.2-.05-4.65-1-13-1.6l-1.3-.1-3.9-.15h-.2l-6.8.05q-.45 0-.85.05-.4 0-.8.05-7.05.4-13.9 1.7-.15 0-.25.05-3.85.75-7.65 1.8-.1 0-.2.05-.1 0-.25.05-.1.05-.15.05-.45.15-.9.25l-1.4.4q-.2.05-.35.1-.4.1-.8.25l-.9.3q-.05 0-.1.05-.25.05-.5.15h.1q.1-.05.2-.05h.05q.1-.05.15-.05.05-.05.1-.05.15-.05.3-.05.7-.2 1.4-.3.25-.1.5-.1.6-.15 1.25-.3 1.15-.25 2.3-.45l4.15-.7q4.9-.9 9.85-1.4.25-.05.5-.05 9.7-.95 19.7-.55h.3q.15 0 .35.05l2 .1q.35 0 .65.05 2.3.15 4.6.35.25 0 .55.05 4.35.4 8.75 1.1z"
                    id="LegSoc005__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.95 3.75q.094-.047.2-.05H7.8v1.45l1.55-.3q.2-.1.5-.15 1.35-.35 4.1-.95m56.2 4l-3.8-1-.3-.1q-.1 0-.25-.05-31.05-2.75-63.1 0-1.25.45-2.5 1.05l-.2.1q34.85-1.2 70.15 0M69.9.1V0H7.8v1.85h62.3q.85-1-.2-1.75m16.15 3.65q.094-.047.2-.05h-15.2q-.35.2-.55.4l-.2-.4H54.95q.107.002.25.05 3.8.75 7.65 1.8h7.65q.05-.1.1-.25.1.15.25.25h7.55q3.8-1.05 7.65-1.8m47.4-1.9V0H71.5q-1.4.95-.25 1.85h62.2m7.6 5.8q-1.15-.5-2.25-.95-32.8-3.35-63.65 0-.7.1-1.4.3-.15 0-.3.05-.05 0-.1.05-.05 0-.15.05h-.05q-.1 0-.2.05h-.15l-.15.05-2.1.5q35.15-1.55 70.75 0-.15-.05-.25-.1m-7.25-2.4q-.2-.05-.35-.05V3.7h-6.4q.105.002.2.05 4.7 1 5.35 1.3.05 0 .1.05l1.1.2v-.05z"
                    id="LegSoc005__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M1.15 3.7H.4l.2 1.85h.35l.2-1.85M.2 1.85h1.15L1.6 0H0v.1l.2 1.75z"
                    id="LegSoc005__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSoc005__Symbol_10_0_Layer0_0_FILL"
                transform="translate(119.3 324.65)"
                id="LegSoc005__col1n"
            />
            <use
                xlinkHref="#LegSoc005__Symbol_11_0_Layer0_0_FILL"
                transform="translate(130.3 325.65)"
                id="LegSoc005__col1s"
            />
            <use
                xlinkHref="#LegSoc005__Symbol_12_0_Layer0_0_FILL"
                transform="translate(121.9 324.65)"
                id="LegSoc005__col2n"
            />
            <use
                xlinkHref="#LegSoc005__Symbol_13_0_Layer0_0_FILL"
                transform="translate(191.8 324.65)"
                id="LegSoc005__col2s"
            />
        </g></g>
    )
}

export default LegSoc005
