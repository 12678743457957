import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.6 7.55q0-.4.05-.8v-.3q.1-.95.25-2.8-.1 0-.15-.05Q22.3.9 19.05.15q-8.8-1.05-14.1 5Q3.2 7.3 2 10.55 1.3 12.5.85 14.6q-.05.2-.05.4l-.3 1.65q-1.85 11.8 3.1 20.85 2.5 4.25 5.85 7.65 3.35 3.4 6.85 4.55 8 1.85 13.9-3.9-.3-1.4-.4-1.7V44q-.35-1.05-.6-2.1-1.2-4.25-2-8.55-.05-.15-.05-.25-.05-.05-.05-.1-.05-.3-.1-.55 0-.15-.05-.3V32q-.1-.65-.2-1.25-1.1-6.6-1.3-13.35-.15-4.9.15-9.85M237.05 5.3q-5.3-6.05-14.1-5-3.25.75-6.7 3.45-.05.05-.15.05.15 1.85.25 2.8v.3q.05.4.05.8.3 4.95.15 9.85-.2 6.75-1.3 13.35-.1.6-.2 1.25v.15q-.05.15-.05.3-.05.25-.1.55 0 .05-.05.1 0 .1-.05.25-.8 4.3-2 8.55-.25 1.05-.6 2.1v.1l-.6 1.8q6.1 5.65 14.1 3.8 3.5-1.15 6.85-4.55 3.35-3.4 5.85-7.65 4.95-9.05 3.1-20.85l-.3-1.65q0-.2-.05-.4-.45-2.1-1.15-4.05-1.2-3.25-2.95-5.4z"
                    id="FacEar015__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M18.2 11.65q.35-3.45.35-4.75 0-2.35-1.5-4.25Q15.1.15 11.2 0 6.8-.05 3.7 3.5 2.5 5 1.6 7.1.7 9.35.3 11.75q-.8 4.85.55 10.4 1.15 4.8 3.5 8.5 7.35 10.75 15.05 8.2.1-.05.2-.05 2.8-1.2 4.2-5.15.75-3.25.65-6.9 0-.15-.05-.3v-.15q-.1-.65-.2-1.25-.9 0-1.55-.2-1.15-.3-2.6-1.45-1.55-1.2-2-3.15-.25-1.2-.25-4.1 0-1.05.4-4.5m215-8Q230.1.1 225.7.15q-3.9.15-5.85 2.65-1.5 1.9-1.5 4.25 0 1.3.35 4.75.4 3.45.4 4.5 0 2.9-.25 4.1-.45 1.95-2 3.15-1.45 1.15-2.6 1.45-.65.2-1.55.2-.1.6-.2 1.25v.15q-.05.15-.05.3-.1 3.65.65 6.9 1.4 3.95 4.2 5.15.1 0 .2.05 7.7 2.55 15.05-8.2 2.35-3.7 3.5-8.5 1.35-5.55.55-10.4-.4-2.4-1.3-4.65-.9-2.1-2.1-3.6z"
                    id="FacEar015__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M17.9 11.65q.35-3.45.35-4.75 0-2.35-1.5-4.25Q14.8.15 10.9 0 6.5-.05 3.4 3.5 2.2 5 1.3 7.1.4 9.35 0 11.75v.05q0 .4.05.85.15-.55.35-1.15.15-.5 1.5-3.65.65-1.65 2.5-3.35 2.7-2.45 6.1-2.45 1.2 0 2.1.55 3.5 1.4 3.5 6.75 0 1.75-.35 3.9-.2 1.05-.3 1.95-1.6 6.7 3.45 10.15.5.35 1.25.7.05.05.15.05-1.05.35-1.6.45-.55.1-2.65.25-2.15.1-3.85-.5-1.6-.55-3.55-2.1 1.35 2.15 2.95 3.05 1.95 1.1 5.3 1.1 1.75 0 3.4-.45 1.3-.3 2-.8 0 .55-.35 1.15-.65 1.3-3.35 2.95-1.3.7-3 1.15l-2.45.5q-1 .1-1.65.15h-.3q.3.1 1.1.35 1.5.45 2.45.45 4.7 0 7.45-3.35 1.8-2.15 1.9-4.15-.1-.65-.2-1.25-.9 0-1.55-.2-1.15-.3-2.6-1.45-1.55-1.2-2-3.15-.25-1.2-.25-4.1 0-1.05.4-4.5M234.4 8q1.35 3.15 1.5 3.65.2.6.35 1.15.05-.45.05-.85v-.05q-.4-2.4-1.3-4.65-.9-2.1-2.1-3.6-3.1-3.55-7.5-3.5-3.9.15-5.85 2.65-1.5 1.9-1.5 4.25 0 1.3.35 4.75.4 3.45.4 4.5 0 2.9-.25 4.1-.45 1.95-2 3.15-1.45 1.15-2.6 1.45-.65.2-1.55.2-.1.6-.2 1.25.1 2 1.9 4.15 2.75 3.35 7.45 3.35.95 0 2.45-.45.8-.25 1.1-.35h-.3q-.65-.05-1.65-.15l-2.45-.5q-1.7-.45-3-1.15-2.7-1.65-3.35-2.95-.35-.6-.35-1.15.7.5 2 .8 1.65.45 3.4.45 3.35 0 5.3-1.1 1.6-.9 2.95-3.05-1.95 1.55-3.55 2.1-1.7.6-3.85.5-2.1-.15-2.65-.25-.55-.1-1.6-.45.1 0 .15-.05.75-.35 1.25-.7 5.05-3.45 3.45-10.15-.1-.9-.3-1.95-.35-2.15-.35-3.9 0-5.35 3.5-6.75.9-.55 2.1-.55 3.4 0 6.1 2.45 1.85 1.7 2.5 3.35z"
                    id="FacEar015__Symbol_37_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar015__Symbol_39_0_Layer0_0_FILL"
                transform="translate(71.5 139)"
                id="FacEar015__col1n"
            />
            <use
                xlinkHref="#FacEar015__Symbol_38_0_Layer0_0_FILL"
                transform="translate(74.05 144.7)"
                id="FacEar015__col2n"
            />
            <use
                xlinkHref="#FacEar015__Symbol_37_0_Layer0_0_FILL"
                transform="translate(74.35 144.7)"
                id="FacEar015__col2d"
            />
        </g></g>
    )
}
export default FacEar015
