import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}
function SkiMor033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M77 14.55Q71.3.85 51.65.6 38.05-.5 24.5.4 4.55 1.1.4 16.3q-.1.55-.2 1.15v.25q-1.3 11.15 5.3 18.55t10.3 17.7q3.6 10 22.6 10.75h1q19-.75 22.6-10.75 3.7-10.3 10.3-17.7 6.6-7.4 5.3-18.55v-.25q-.2-1.5-.6-2.9z"
                    id="SkiMor033__Symbol_38_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor033__Symbol_38_0_Layer0_0_FILL"
                transform="translate(153.7 251.15)"
                id="SkiMor033__col1n"
            />
        </g></g>
    )
}
export default SkiMor033
