import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212"
}

function BeaChi022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M185.9.05q-.05.05 0 .15 3.15 9.6 4.45 19.65.35 2.7.55 5.45.3 3.25.35 6.55 0 13.25-4 25.7-.4 1.1-.9 2.2-2.05 4.7-6.2 7.05-4.15 2.3-15.25 5.45-.3.75-.55 1.55-.3.95-.5 2-1.7 9.65-7.35 17.15-.15.15-.25.3l-1.2 1.5H155q-11.65 11.1-18.9 22.2-2.8 3-3.35 3.45-.35.25-.65.55-6.8 2.95-14.2 1.9-2.5-.4-4.95-1.35-7.65-3.15-15.45-3.15-7.8 0-15.45 3.15-2.45.95-4.95 1.35-7.4 1.05-14.2-1.9-.3-.3-.65-.55-.55-.45-3.35-3.45-7.25-11.1-18.9-22.2h-.05l-1.2-1.5q-.1-.15-.25-.3-5.65-7.5-7.35-17.15-.2-1.05-.5-2-.25-.8-.55-1.55Q19 69.1 14.85 66.8q-4.15-2.35-6.2-7.05-.5-1.1-.9-2.2-4-12.45-4-25.7.05-3.3.35-6.55.2-2.75.55-5.45Q5.95 9.8 9.1.2q.05-.1 0-.15V0l-.05.05Q8.8.3 8.55.6 7.8 1.75 7.1 3.05 4.05 8.7 2.4 15.95q-.35 1.6-.65 3.25-.2.95-.25 1.15-2.75 16.4-.5 33.35.05.05.05.15.9 7.4 2.5 14.6 0 .15.05.25.5 2.3 1.1 4.6.7 2.8 1.7 5.5 1.45 3.9 3.5 7.55 2.55 4.55 4.75 8.6 1.05 1.85 2.05 3.65 3.35 6.1 4.85 11.9.35 1.45.6 2.85.4 2.35 1 4.65.2.8.45 1.6.05.2.1.35.25.3.7.4l.5.1q21.55 1.15 39.35 17.2l.3.3q1 1.1 1.75 2.45.4.7.85 1.1h60.7q.45-.4.85-1.1.75-1.35 1.75-2.45l.3-.3q17.8-16.05 39.35-17.2l.5-.1q.45-.1.7-.4.05-.15.1-.35.25-.8.45-1.6.6-2.3 1-4.65.25-1.4.6-2.85 1.5-5.8 4.85-11.9 1-1.8 2.05-3.65 2.2-4.05 4.75-8.6 2.05-3.65 3.5-7.55 1-2.7 1.7-5.5.6-2.3 1.1-4.6.05-.1.05-.25 1.6-7.2 2.5-14.6 0-.1.05-.15 2.25-16.95-.5-33.35-.05-.2-.25-1.15-.3-1.65-.65-3.25-1.65-7.25-4.7-12.9-.7-1.3-1.45-2.45-.25-.3-.5-.55L185.9 0v.05z"
                    id="BeaChi022__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M189.25 19.45q.6-2.3 1.1-4.6.05-.1.05-.25Q192 7.4 192.9 0q-3 14.55-3.6 16.4-.6 2.05-2.35 5.05-.3.8-2.25 3.6-2.25 3.15-2.75 4.1-.95 1.75-2.65 5.4l-.4.7q-2.25 4.15-3.7 7.15-.9 1.5-1.6 3.1-2.15 4.85-2.95 9.2-.85 4.3-1.3 11.05-2.5.05-4.6.1-4.05.2-7.5.7-3.25.5-7.75 1.45T137 73.25q-8 4.3-11.25 14.3-5.5-1.7-10.4-2.3-1.6-.2-3.1-.4-15.9-2.25-31.6 0-1.5.2-3.1.4-4.9.6-10.4 2.3-3.25-10-11.25-14.3-8-4.3-12.5-5.25-4.5-.95-7.75-1.45-3.45-.5-7.5-.7-2.1-.05-4.6-.1-.45-6.75-1.3-11.05-.8-4.35-2.95-9.2-.7-1.6-1.6-3.1-1.45-3-3.7-7.15l-.4-.7q-1.7-3.65-2.65-5.4-.5-.95-2.75-4.1-1.95-2.8-2.25-3.6-1.75-3-2.35-5.05Q3 14.55 0 0q.9 7.4 2.5 14.6 0 .15.05.25.5 2.3 1.1 4.6.7 2.8 1.7 5.5 1.45 3.9 3.5 7.55 2.55 4.55 4.75 8.6 1.05 1.85 2.05 3.65 3.35 6.1 4.85 11.9.35 1.45.6 2.85.4 2.35 1 4.65.2.8.45 1.6.05.2.1.35.25.3.7.4l.5.1Q45.4 67.75 63.2 83.8l.3.3q1 1.1 1.75 2.45.4.7.85 1.1h60.7q.45-.4.85-1.1.75-1.35 1.75-2.45l.3-.3q17.8-16.05 39.35-17.2l.5-.1q.45-.1.7-.4.05-.15.1-.35.25-.8.45-1.6.6-2.3 1-4.65.25-1.4.6-2.85 1.5-5.8 4.85-11.9 1-1.8 2.05-3.65 2.2-4.05 4.75-8.6 2.05-3.65 3.5-7.55 1-2.7 1.7-5.5z"
                    id="BeaChi022__Symbol_20_0_Layer0_0_FILL"
                />
                <g id="BeaChi022__Symbol_9_0_Layer0_0_FILL" fill={colors.col1s}>
                    <path d="M.85.55q-.25.7-.55 2Q0 3.85 0 6.7l.1 3.75q0 .8.1 1.95.45 2 1.1 0 .05-.65.05-1.05-.2-4.05-.25-5.55v-.05q.3-4.9.55-5.5Q1.45.1 1.2 0q-.05.1-.1.15l-.1.2Q.95.4.95.45l-.1.1M14.9 9.4q-.8.3-1.95.7-2.2 1.1-2.85 1.6-.65.45-.7.5-2.8 2.55-4.25 5.65.15.2.3.25.4.1.85 0 2.95-5.3 8.35-7.85.15-.2.2-.4.05-.2.05-.45m49.05 30.8q.05 1.5.45 4.45.2 0 .4-.05.45-2.6.05-5.2-.4-.8-.85 0 0 .4-.05.8m18.85 4.45q.4-2.95.45-4.45-.05-.4-.05-.8-.45-.8-.85 0-.4 2.6.05 5.2.2.05.4.05m58.95-26.55q.15-.05.3-.25-1.45-3.1-4.25-5.65-.05-.05-.7-.5-.65-.5-2.85-1.6-1.15-.4-1.95-.7 0 .25.05.45t.2.4q5.4 2.55 8.35 7.85.45.1.85 0m4.5-17.65q0-.05-.05-.1l-.1-.2Q146.05.1 146 0q-.25.1-.45.25.25.6.55 5.5v.05q-.05 1.5-.25 5.55 0 .4.05 1.05.65 2 1.1 0 .1-1.15.1-1.95l.1-3.75q0-2.85-.3-4.15-.3-1.3-.55-2l-.1-.1zM73.85 44.45q.2-1.55.15-5-.1-3.5-.75-3.15-.4 4.3 0 8.55l-.05.15q0 .05.05.1h.55q0-.25.05-.65z" />
                </g>
            </defs>
            <use
                xlinkHref="#BeaChi022__Symbol_61_0_Layer0_0_FILL"
                transform="translate(95 116.8)"
                id="BeaChi022__col1n"
            />
            <use
                xlinkHref="#BeaChi022__Symbol_20_0_Layer0_0_FILL"
                transform="translate(96.05 170.65)"
                id="BeaChi022__col1d"
            />
            <use
                xlinkHref="#BeaChi022__Symbol_9_0_Layer0_0_FILL"
                transform="translate(118.9 207.1)"
                id="BeaChi022__col1s"
            />
        </g></g>
    )
}

export default BeaChi022
