import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#773900"
}
function SkiMak024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M155.25 44.05q-2.1-19.95-16.15-32.7-8.65-7.85-18.75-10.15-3.3-.75-6.75-.9-22.65-2.4-35.85 13.9Q63.45-3.45 38.05.8q-1.45.25-2.85.55Q25.1 3.7 16.5 11.5 2.45 24.25.35 44.2q-2.1 19.9 9.05 35.3 11.15 15.45 29 17.3 5.35.55 10.3-.2 1.05-.15 2-.35 15.6-2.6 27.2-12.3 11.5 9.55 27 12.15.95.2 2 .35 4.95.75 10.3.2 17.85-1.85 29-17.3 11.15-15.4 9.05-35.3z"
                    id="SkiMak024__Symbol_31_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak024__Symbol_31_0_Layer0_0_FILL"
                transform="translate(114.7 116.75)"
                id="SkiMak024__col1n"
            />
        </g></g>
    )
}
export default SkiMak024
