import { AssetSvgProps } from "../../../shared/assets"

function AccHat131({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <pattern
                    id="AccHat131__Pattern_1"
                    patternUnits="userSpaceOnUse"
                    patternTransform="matrix(-.41934 0 0 .41962 479.35 -23.25)"
                    width={121}
                    height={116}
                >
                    <use xlinkHref="#AccHat131__Prof_Chaos1" />
                </pattern>
                <image
                    id="AccHat131__Prof_Chaos1"
                    x={0}
                    y={0}
                    width={121}
                    height={116}
                    xlinkHref="./Bilder/Prof_Chaos1.jpg"
                />
                <path
                    fill="url(#AccHat131__Pattern_1)"
                    d="M268.65 86.2q-5.85-5.4-14.2-11.35-6.65-4.7-25.35-15.1-4.8-2.65-8.85-5.35-15.35-8.05-20.8-30.9-7.6-19.8-15.15 2.75-.5 11.95-15.95 25-5.45 3.8-13.25 8.5-16.35 9.85-24.75 16.45-6.55 5.1-11.8 10-1.5 1.5-2.9 3-6.7 7-10.7 14.6-1.75 3.95-3.6 9.3-1.85 5.3-2 11.8-.15 6.35.05 5.95 1.45.55 4.45-.8 3.3-.8 6.5-1.5.65-.15 1.35-.3l4.45-.95q74.75-16.3 149.5-1.75 5.1.9 10.25 2.05 1.4.3 2.8.65 1.85.4 3.7.9 3 1.25 4.5.8.1.2.15-1.7 0-1.5-.1-4.25-.2-6.5-2-11.8-1.9-5.35-3.6-9.35-4.05-7.55-10.7-14.55-.95-1.05-2-2.1z"
                    id="AccHat131__Layer16_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat131__Layer16_0_FILL" />
        </g></g>
    )
}

export default AccHat131
