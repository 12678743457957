import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFBB",
    "col1n": "#FFFF62",
    "col2n": "#FEBE81"
}

function PlaSim001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M385 289.3V0H0v289.3h385z"
                    id="PlaSim001__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M160.85 0v289.3h20.9V0h-20.9M98 0H77.05v289.3H98V0M56.05 0h-20.9v289.3h20.9V0M14.2 0H0v289.3h14.2V0m125.7 0h-21v289.3h21V0m83.8 0h-20.95v289.3h20.95V0m41.9 0h-21v289.3h21V0m41.85 0h-20.9v289.3h20.9V0m41.95 0h-20.95v289.3h20.95V0M385 0h-14.7v289.3H385V0z"
                    id="PlaSim001__col1l_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M385 88.6V0H0v88.6h385z"
                    id="PlaSim001__col2n_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M385 298.9v-13.95H0v13.95h385z"
                    id="PlaSim001__Layer7_0_FILL"
                />
                <path
                    fill="#E1E1E1"
                    d="M385 286.8v-3H0v3h385z"
                    id="PlaSim001__Layer7_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaSim001__Symbol_4_0_Layer0_0_FILL"
                id="PlaSim001__col1n"
            />
            <use
                xlinkHref="#PlaSim001__col1l_0_Layer0_0_FILL"
                id="PlaSim001__col1l"
            />
            <use
                xlinkHref="#PlaSim001__col2n_0_Layer0_0_FILL"
                transform="translate(0 296.4)"
                id="PlaSim001__col2n"
            />
            <use xlinkHref="#PlaSim001__Layer7_0_FILL" />
            <use xlinkHref="#PlaSim001__Layer7_1_FILL" />
        </g></g>
    )
}

export default PlaSim001
