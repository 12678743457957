import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor077({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFD900"
                    d="M236.45 270.25q0-3.65-2.6-6.25t-6.25-2.6q-3.6 0-6.3 2.6-2.55 2.6-2.55 6.25t2.55 6.25q2.7 2.6 6.3 2.6 3.65 0 6.25-2.6t2.6-6.25z"
                    id="AccMor077__Layer10_0_FILL"
                />
                <path
                    fill="#7C2C83"
                    d="M231.5 266.25q-1.545-1.6-3.9-1.6-2.295.002-4 1.6-1.598 1.649-1.6 4 0 2.299 1.6 3.9 1.704 1.703 4 1.7 2.35-.007 3.9-1.7h.05q1.655-1.618 1.65-3.9.003-2.352-1.7-4m-8.3 4q-.002-1.849 1.25-3.15 1.345-1.252 3.15-1.25 1.845 0 3.05 1.25 1.346 1.302 1.35 3.15-.006 1.8-1.35 3.05v.05q-1.219 1.307-3.05 1.3-1.804-.003-3.15-1.35-1.25-1.249-1.25-3.05z"
                    id="AccMor077__Layer10_1_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor077__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor077__Layer10_0_FILL" />
            <use xlinkHref="#AccMor077__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor077__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor077__col1l"
            />
        </g></g>
    )
}

export default AccMor077
