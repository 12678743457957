import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.65 11.2q.55-.3.65-1.45-1.5.2-3.75.2-14.25 0-24.1-5Q11.4.45 6.7 0 4.6 1.15 2.05 3.5 1.6 4 .75 6.2q-.2.55-.5 1L0 7.3q.65.1 1.2.1 1.9 0 4.05-1.3 2.1-1.35 4.65-1.35 3.6 0 11.6 3.9Q29.45 12.5 38 12.5q7.55 0 9.65-1.3m53.6-4q-.3-.45-.55-1-.85-2.2-1.25-2.7-2.55-2.35-4.7-3.5-4.65.45-13.7 4.95-9.9 5-24.15 5-2.2 0-3.7-.2.1 1.15.65 1.45 2.1 1.3 9.65 1.3 8.5 0 16.5-3.85 7.95-3.9 11.6-3.9 2.55 0 4.65 1.35 2.15 1.3 4.05 1.3.55 0 1.15-.1l-.2-.1z"
                    id="FacBro019__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro019__Symbol_19_0_Layer0_0_FILL"
                transform="translate(141.8 123.9)"
                id="FacBro019__col1n"
            />
        </g></g>
    )
}

export default FacBro019
