import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.85 5.8q-.15-.1-.35-.35-1.75-.85-3.8-1.7Q36.45 2 32 1 25.45-.7 20.25.45 18.2.9 16.35 1.8q-3.1 1.35-5.9 3.1Q5.25 8.25 0 12.6q7.1-4.8 14.45-7.9 7.2-3 15.35-.5 6.95 1.35 11.85 3.85 3.5 1.25 3.8-.1.35-1.05-.6-2.15m45.8-1.1Q98 7.8 105.1 12.6q-5.25-4.35-10.45-7.7-2.8-1.75-5.9-3.1Q86.9.9 84.85.45 79.65-.7 73.1 1q-4.45 1-8.7 2.75-2.05.85-3.8 1.7-.2.25-.35.35-.95 1.1-.6 2.15.3 1.35 3.8.1 4.9-2.5 11.85-3.85 8.15-2.5 15.35.5z"
                    id="FacBro013__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro013__Symbol_13_0_Layer0_0_FILL"
                transform="translate(139.95 120.3)"
                id="FacBro013__col1n"
            />
        </g></g>
    )
}

export default FacBro013
