import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M166.1 37.75q.75 2.65 1.25 7.25.5 4.65.75 11.3.75 7 3.2 21.25 1.7 10.2 4.45 15 .65 1.15 1.4 2 9.5 9.4 11.35 20.4.2.95.35 1.95.4 2.9.35 5.85-.05 6.8-2.55 13.45-2.65 7.2-5.15 10.65-.05 0-.05.05-4 6.25-9.05 12.05-2.3 2.55-4.75 5l-.6.6q-.35.3-.65.65.3-.1.55-.15.15-.05.3-.05 1.45-.3 3-.45 2.1-.25 4.5-.25h3.15q2.95-.1 5.45-.2l.6-.05q10.55.05 14.35-9.3.5-1.15.85-2.35 1.5-7.35 0-13.7-.05-.35-.15-.7-1.85-8.6-2.55-15.45-.25-2.8-.35-5.25-.05-.85-.05-1.65l.1-1.45-.25-9.55q.3-4.2.3-8.5 0-39.85-28.15-68Q142.5 2.65 107.4.25 103.75 0 100 0q-3.75 0-7.4.25-35.1 2.4-60.6 27.9-28.15 28.15-28.15 68 0 3.05.2 6l-.2 13.5q0 .85-.05 1.8-.05 1.85-.25 3.85Q2.9 128.6.9 138q-.1.35-.15.7-1.5 6.35 0 13.7.35 1.2.85 2.35 3.8 9.35 14.35 9.3l.6.05q2.5.1 5.45.2h3.15q2.45 0 4.65.25 1.45.15 2.75.4l.3.1h.1l.4.1-.6-.6-.6-.6q-7.25-7.25-12.6-15.25-1.5-2.2-2.8-4.5-2.3-3.75-4.4-12.95-1.5-4.8-1.55-9.6-.05-2.35.3-4.65.1-1.05.3-2.05.75-4.5 2.8-8.7 2.95-6.15 8.55-11.7.75-.85 1.4-2 2.75-4.8 4.45-15 2.45-14.25 3.2-21.25.25-6.65.75-11.3.5-4.6 1.25-7.25 1.1-3.45 4.5-6.25 3.15-2.5 8.25-4.5 3.2-1.2 6.75-2.05 1.65-.4 3.3-.7 5.25-1.55 10.4-2.1 5.4-.55 10.7 0 6.9.7 13.65 3.35 2.7 1.15 5.55 1.55 2.85.4 5.8 0 2.85-.4 5.85-1.55 6.75-2.65 13.7-3.35 5.25-.55 10.65 0 5.15.55 10.4 2.1 1.65.3 3.3.7 3.55.85 6.75 2.05 5.1 2 8.25 4.5 3.4 2.8 4.5 6.25z"
                    id="HaiMed003__Symbol_3_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.8 3.75Q3.57 1.876 3.55 0q-.07.776-.15 1.55Q2.686 8.251.9 16.6q-.1.35-.15.7-.2.8-.3 1.6-1 5.65.3 12.1.35 1.2.85 2.35 3.387 8.278 12.05 9.2.534.07 1.1.1h1.3l10.2.85q-.931-.828-1.85-1.7-4.685-4.744-8.6-9.85-8.767-12.228-12-28.2M184.5 16.6q-1.845-8.653-2.15-12.05-.146-1.55-.5.6-4.718 21.302-22.65 38-.111.102-.25.2h.15l9.35-.7h2.2q.99-.052 1.9-.2 8.023-1.213 11.25-9.1.5-1.15.85-2.35 1.5-7.35 0-13.7-.05-.35-.15-.7z"
                    id="HaiMed003__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed003__Symbol_3_0_Layer0_0_FILL"
                transform="translate(92.55 57.6)"
                id="HaiMed003__col1n"
            />
            <use
                xlinkHref="#HaiMed003__Symbol_4_0_Layer0_0_FILL"
                transform="translate(99.8 179.25)"
                id="HaiMed003__col1d"
            />
        </g></g>
    )
}

export default HaiMed003
