import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1n": "#D19532",
    "col2n": "#000000"
}

function ObjMor018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M259.55 295.9q.1.25.2.55.25.75.65 1.4.35.7.8 1.2l9.35 14.25-9.25 3.5-.65 10.85 4.15-1.6q12.372-2.606 23.15-8.8l4.15-1.6-7.65-7.65-8.75 3.3-1.15-4.2q-1.138.836-3.05.5-1.238-.367-2.55-1.75-1.45-1.45-1.85-2.55-.75-1.9-.85-2.7v-2.1q0-5.15 2.6-8.05.35-.4.75-.65.05-.15.15-.25-.9-1.1-2.15-1.8-.085-.052-.2-.1-2.334-1.367-4.65-.45-1.5.55-2.4 1.85.65.8.65 2.25 0 2-.9 3.7-.25.5-.55.9m24.9 14.4v.1h-.05l-.1-.05.15-.05z"
                    id="ObjMor018__Symbol_128_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M270.55 313.1q.017.116 0 .2l-9.25 3.5-.65 10.85 2.4-9.3.1.1 8.85-3.35-.05-.15-1.4-1.85m-.8-23.55q-.9-1.1-2.15-1.8-.085-.052-.2-.1-.984 1.242-1.75 2.85-1.35 2.8-1.35 5.4 0 4.765 3.6 9.7.308.38.6.75l5.7 7.75.05.15 6.8-2.6 3.35-1.25-.1-.05.15-.05v.1h-.05l7.7 5.25-7.65-7.65-8.75 3.3-1.15-4.2q-1.138.836-3.05.5-1.238-.367-2.55-1.75-1.45-1.45-1.85-2.55-.75-1.9-.85-2.7v-2.1q0-5.15 2.6-8.05.35-.4.75-.65.05-.15.15-.25z"
                    id="ObjMor018__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M287 319.85v-.05l1.65-.6-.7-1.95-23.15 8.8.7 1.95 21.5-8.15z"
                    id="ObjMor018__Symbol_31_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor018__Symbol_128_0_Layer0_0_FILL"
                id="ObjMor018__col1n"
            />
            <use
                xlinkHref="#ObjMor018__Symbol_70_0_Layer0_0_FILL"
                id="ObjMor018__col1d"
            />
            <use
                xlinkHref="#ObjMor018__Symbol_31_0_Layer0_0_FILL"
                id="ObjMor018__col2n"
            />
        </g></g>
    )
}

export default ObjMor018
