import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M166.2 30.4q-1.1-1.15-2.2-2.25-1.3-1.3-2.65-2.55-15.5-14.5-34.4-20.75Q112.45 0 95.95 0t-31 4.85Q46.05 11.1 30.6 25.6q-1.35 1.25-2.65 2.55-1.1 1.1-2.2 2.25-10.2 10.8-16.4 23.3-10 19.45-9.3 46.85Q6.1 89.05 12.3 79.9q23.15-34.4 47.9-36.8.2-.05.45-.05 2.15-.2 4.3-.35 7.8-.6 15.65-.95l15.1-.25 15.5.25q7.9.35 15.75.95 2.1.15 4.2.35.25 0 .45.05 25.2 2.45 49.1 38.85 5.55 8.4 11 18.65.5-19.7-4.5-35.65l-3.75-9.55q-6.35-13.45-17.25-25z"
                    id="HaiSho020__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M166.75 31.05q-1.35-1.45-2.8-2.9-16.5-16.5-37.05-23.3Q112.4 0 95.9 0t-31 4.85q-20.5 6.8-37 23.3-1.55 1.55-3 3.15-6.05 6.5-10.6 13.6l-.7 1.1q-.5.8-.95 1.6l-.1.15q-.15.15-.2.35l-.2.3-.45.8q-.3.45-.55 1l-.1.15-.05.15q-.45.75-.85 1.55-.45.8-.85 1.65l-.15.35q-.15.2-.2.4l-.05.05Q6 60.3 4.05 66.9l-.05.05q-1.75 5.9-2.75 12.45-.35 2.25-.6 4.55L0 95.1q3.05-18.5 9.8-33.8 19.65-34.15 54.9-47.85.1-.05.2-.05Q79.3 9 95.65 8.95h.4q16.4.05 30.85 4.5h.1q35.25 13.7 54.9 47.85 6.65 15.15 9.8 33.4-.3-16.2-4.55-29.75l-3.75-9.55q-.2-.4-.4-.75-.15-.3-.25-.6l-.65-1.3q-.15-.2-.25-.4l-.05-.2q-.1-.15-.15-.25-.1-.2-.2-.35-.1-.2-.15-.35-.1-.2-.2-.35-.1-.15-.15-.3-5.2-9.6-13.15-18.35-.55-.6-1.05-1.15z"
                    id="HaiSho020__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho020__Symbol_141_0_Layer0_0_FILL"
                transform="translate(96.5 57.6)"
                id="HaiSho020__col1n"
            />
            <use
                xlinkHref="#HaiSho020__Symbol_22_0_Layer0_0_FILL"
                transform="translate(96.55 57.6)"
                id="HaiSho020__col1l"
            />
        </g></g>
    )
}

export default HaiSho020
