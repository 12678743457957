import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C67D9A",
    "col2l": "#FFC4C4",
    "col2n": "#FF8E8E"
}

function AccGla011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.4 2.35q.4.45 1 .45t.95-.45Q2.8 2 2.8 1.4T2.35.35Q2 0 1.4 0T.4.35Q0 .8 0 1.4q0 .6.4.95z"
                    id="AccGla011__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M55.1 24.75V0H0v24.75h55.1m65.4 0V0H65.4v24.75h55.1z"
                    id="AccGla011__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192.45 2.5V0H0v2.5h35.35v25.25h57.1V2.5H100v25.25h57.1V2.5h35.35m-37.85 0v22.75h-52.1V2.5h52.1m-64.65 0v22.75h-52.1V2.5h52.1z"
                    id="AccGla011__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla011__Symbol_101_0_Layer0_0_FILL"
                transform="translate(185.6 157.2)"
                id="AccGla011__col2n"
            />
            <use
                xlinkHref="#AccGla011__Symbol_81_0_Layer0_0_FILL"
                transform="translate(132.25 157.3)"
                id="AccGla011__col2l"
            />
            <use
                xlinkHref="#AccGla011__Symbol_34_0_Layer0_0_FILL"
                transform="translate(96.3 156)"
                id="AccGla011__col1n"
            />
        </g></g>
    )
}

export default AccGla011
