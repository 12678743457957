import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiMed030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M198.75 155.5q21.9-64.95-2.35-105.65-19.1-28.95-47.1-41Q116.7-7 80.8 5.55 51.25.3 32.1 21.9 17.25 38.7 7.8 70.8q-.15.5-.25 1Q6 78.3 4.6 85.3 1.35 112.2.7 125.9q-.65 13.7-.7 23.9-.05 10.15 5.65 42.85V151.7q1.65 26.6 12.75 54.2-8.95-28.7-7.45-57.4 3.9 32.25 21.75 59.45-14.2-29.6-16.2-59.2 9.9 37.7 21.5 48.45-9.5-15.9-10.35-35.25 4.5 13.15 18.65 36.7-10.85-26.8-12.95-58.25-.05-.65-.05-1.25-1.35-21.75 1.55-45.7Q35.95 81.8 39 71.8q.15-.5.35-1Q50.9 40.45 88.5 28.3q53.85 41.95 60.5 83.85 10.2 51.15-16.95 102.35 13.5-12.7 22.2-41.05Q151.9 201.4 136 223.5q15.8-10.5 27.25-37.85-2.5 25.65-14.15 41.7 10.55-5.8 33.15-52-6.05 26.1-15.1 41l8.9-13.55q14.2-20.6 22.7-47.3z"
                    id="HaiMed030__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M53.6 4.1q.45-.45.95-.85-17.6 9.9-32.45 37.8.05-.35.15-.7L27 20.6q.95-2.45 1.95-4.1-.1.05-.2.15.1-.25.25-.45-6.85 4.7-11.15 15.6l.1-2.75.9-7.75 1.95-8.25q1.4-4.2 3.4-7.75-.2.3-.4.55.2-.45.45-.85Q11.15 22.4 5.1 42.6q-.15.5-.25 1-.2.9-.4 1.75L2.4 55.8 0 93.65q.05 3.2.25 6.45 0-.35.05-.75v.45q.05-1.2.15-2.55l.9-7.25L8.3 61.7l.7-2.3q-1.9 32.25 3.6 51.85.2.6.4 1.25 0-.3-.05-.6l.1.3q-.05-.65-.1-1.25-.2-1.95-.3-3.95l2.4-32.9 2.3-12.15q.1-.45.2-.85-1.35 29.6 2.75 33.1v-.3h.05q.05-2.1.15-4.15l2.7-27.1 3.55-16.5q.35-1.15.65-2.25.15-.5.35-1Q36.25 19 54.5 3.55q-.45.25-.9.55M163.9 9q7.25 9.35 12.3 33.65-4.8-12.2-14.35-22.1.1.25.25.6l-.3-.3q1.45 3.8 5.8 18.1-11.3-22.9-29.7-34.75l.95.95q-.5-.35-1-.65 14.2 14.1 18.25 29.65Q141.5 14.25 120.4 0q.55.55 1.15 1.1l-1.2-.8q14 13.35 25.7 40.3-12.55-17.55-25.8-22.15.2.25.45.5l-.5-.2q32.55 35.4 36.75 87.05 0-.3.05-.6v.3q.85-19.7-.2-27.9 6 14.35 7 32.45l.05-.4v.1l2.75-24.25q7.45 19.25 6.05 43.6l.2-.9 1.65-8.85 2.25-34.6q-.1-2.3-.3-4.5 5.1 10.6 4.7 33l.2-.9.75-4.25 1.2-41.15q-.6-4.05-1.55-7.8 8.2 18.6 8.95 37.25.05-.15.05-.3.15-.9.35-1.8l1.05-28.1q-.45-4.55-1.3-9 7.15 10.3 8.6 20.7 0-.45-.05-.9l.1.6q-2.15-50.25-29.45-70.85l.6.8q-.3-.25-.65-.5 8.4 11 13.55 27.45-9.6-15.5-19.6-25.8.55.7 1.1 1.5-.6-.65-1.15-1.2z"
                    id="HaiMed030__Symbol_29_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed030__Symbol_86_0_Layer0_0_FILL"
                transform="translate(92.1 54.2)"
                id="HaiMed030__col1n"
            />
            <use
                xlinkHref="#HaiMed030__Symbol_29_0_Layer0_0_FILL"
                transform="translate(98.45 82.4)"
                id="HaiMed030__col1l"
            />
        </g></g>
    )
}

export default HaiMed030
