import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#666666"
}

function PlaFor026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M150.95 49.05q-.05-20.25-22-34.65Q106.75 0 75.45 0 44.2 0 22.05 14.4.05 28.8 0 49.05q.05 20.25 22.05 34.6.05.05.1.05 3 2 6.25 3.75h.05q.1.05.2.2.05.1 0 .3-3.75 15.35-14.9 25.6-.1.05-.1.15 0 .1.05.2l.15.15H14q20.4-3.75 32.8-19.15 0-.05.05-.05 0-.1.1-.1h.1q.05-.05.15 0h.05q13 3.35 28.2 3.35 31.3 0 53.5-14.45 21.95-14.35 22-34.6z"
                    id="PlaFor026__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M130.75 85.85q22.65-14.7 22.6-35.6.05-20.9-22.6-35.65Q108.35 0 76.65 0 45 0 22.6 14.6-.05 29.35 0 50.25q-.05 20.9 22.6 35.6 2.85 1.9 5.95 3.55-4.05 15.95-16.35 26.15-.2.15-.25.4-.1.2.05.45.05.25.3.35.2.15.45.1 22.5-3.25 35.85-19.65 12.95 3.3 28.05 3.3 31.7 0 54.1-14.65m21.1-35.6q-.05 20.1-21.9 34.35Q107.85 99 76.65 99 61.5 99 48.5 95.65q-.2-.1-.4 0-.25.05-.35.25-12.3 15.3-32.6 19.05 11.25-10.3 15-25.75.05-.25-.05-.5-.15-.25-.35-.35-3.25-1.75-6.3-3.75h-.05Q1.55 70.35 1.5 50.25q.05-20.1 21.9-34.4Q45.5 1.5 76.65 1.5q31.2 0 53.3 14.35 21.85 14.3 21.9 34.4z"
                    id="PlaFor026__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor026__Symbol_39_0_Layer0_0_FILL"
                transform="translate(228.5 6.1)"
                id="PlaFor026__col1n"
            />
            <use
                xlinkHref="#PlaFor026__Symbol_40_0_Layer0_0_FILL"
                transform="translate(227.3 4.9)"
                id="PlaFor026__col2n"
            />
        </g></g>
    )
}

export default PlaFor026
