import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#FFE700",
    "col2n": "#FFFF00"
}

function AccHat046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M185.25 33.25h-5.55q-8.7-.2-15.7-3.35-8.6-3.9-10.45-4.55-1.35-.05-2.85-.1h-2.55q-6.45.1-13.8 6.85-.05.05-.05.1-2.35 2.2-6 5.85l-2.55 2.55q-1.25 1.2-2.55 2.55l.05.05-1.8 1.8q1.1.95 2.4 1.95l-11.2 6.95q-2.7-.55-5.25-1.45-4.75-1.6-8.85-4.25-3.5-2.25-6.5-5.2l-.05-.05-.5-.4q-1.05-.8-2.1-1.7-4.75-4.15-14.45-16.65-9.15-11.8-15.4-16.65Q49.8 0 38.1 0q-5 0-9.45.65-1.9.4-3.6.95-2.25.65-4.2 1.6Q9.05 6.85 4.25 17q-1.35 3.05-2.2 6.25Q0 30.4 0 37.9q0 8.3 1.3 15.6.25 1.55.6 3.05 4.3 23.65 22.4 36.6.65.5 1.35.95 8.65 5.75 21.25 10.15 5.7 2.25 12.4 4.15 10.6 3.05 26.2 5.9 19.3 1.3 53.7 1.4 3.65.2 12.7.4 16 2.1 30.55 7.2 18.2 6.3 34.25 17.35 4.7 2.9 10.1 6.1 5.85 3.8 11.4 8.15 6.3 4.9 12.25 10.45 5.6 5.3 8.95 8.25 1.95 1.7 3.1 2.6 2.6 2.05 4.9 3.55 1.55.95 2.95 1.75h.05q18.9 7 23.8-6.75.3-1.25.5-2.45.3-1.8.3-3.55 0-4.65-2.5-10.95-2.55-6.4-7.6-14.45-10.1-16.2-20.5-23.4-4.6-3.2-14.25-11.3-12.3-10.25-15.95-13.1-4-2.5-7.9-5-1.75-1.15-3.5-2.35-1.4-.9-2.75-1.85-.8-.5-1.55-1.05-1.85-1.25-3.45-2.4-3.95-2.85-6.5-5-.3-.1-.55-.2-1.5-.65-2.95-1.25-1.65-.7-3.25-1.35l-3.55-8.25h.15l.55-.1q.2 0 .4-.05.65-.1 1.25-.2h.1l-.4-3.5h.05q-.05-.45-.1-.85-.75-5.55-1.25-9.05l-.1-.5q-.15-.9-.3-1.75-3.95-17.7-12.55-17.6h-.8z"
                    id="AccHat046__Symbol_264_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M225.55 137.8q-5.4-3.2-10.1-6.1-28.85-19.85-64.8-24.55-9.05-.2-12.7-.4-34.4-.1-53.7-1.4-15.6-2.85-26.2-5.9-17.75-5.1-28.9-12.55-17.2-11.6-22.6-32.35Q4 44.85 4 33.2q0-7.5 2.05-14.65.85-3.2 2.2-6.25.9-1.95 2.1-3.65Q14.2 3 20.85 0 9.05 3.65 4.25 13.8q-1.35 3.05-2.2 6.25Q0 27.2 0 34.7 0 43 1.3 50.3q.25 1.55.6 3.05Q6.2 77 24.3 89.95q.65.5 1.35.95 8.65 5.75 21.25 10.15 5.7 2.25 12.4 4.15 10.6 3.05 26.2 5.9 19.3 1.3 53.7 1.4 3.65.2 12.7.4 16 2.1 30.55 7.2 18.2 6.3 34.25 17.35 4.7 2.9 10.1 6.1 5.85 3.8 11.4 8.15 6.3 4.9 12.25 10.45 5.6 5.3 8.95 8.25 1.95 1.7 3.1 2.6 2.6 2.05 4.9 3.55 1.55.95 2.95 1.75h.05q18.9 7 23.8-6.75.3-1.25.5-2.45.3-1.8.3-3.55 0-4.65-2.5-10.95.5 3.4.5 5.95 0 4.95-1.7 8.75-1.7 3.2-4 4.95-2.1 1.55-4.65 1.95-6.55 1.05-16.5-5.4-2.3-1.5-4.9-3.55-3.15-2.45-12.05-10.85-11.15-10.45-23.65-18.6m-57.7-102.5q.55.2 1.2.4 1.35-.15 2.55-.45 1.5-.45 2.75-1.1l.3-.2q3.1-1.9 6.15-1.9 4.55 0 8.65 3.95 6.55 12.4 5.7 23.85V64q.65-.1 1.25-.15 1.3-.15 2.55-.3.2 0 .4-.05.65-.1 1.25-.2h.1l-.4-3.5h.05q-.05-.45-.1-.85-.75-5.55-1.25-9.05l-.1-.5q-.15-.9-.3-1.75-3.95-17.7-12.55-17.6h-6.35q-8.7-.2-15.7-3.35-8.6-3.9-10.45-4.55-1.35-.05-2.85-.1h-2.55q-6.45.1-13.8 6.85-.05.05-.05.1-2.35 2.2-6 5.85l-2.55 2.55q-1.25 1.2-2.55 2.55l.05.05-1.8 1.8q1.65 1.45 3.75 2.95.6.45 1.3.9.35.3.8.55 1.55 1.05 3.2 2.05 3.45-4.9 8.7-11.35l.6-.7q5.75-2.9 13-2.9 4.3 0 8.2 1 3.8 1 6.85 1z"
                    id="AccHat046__Symbol_172_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M135.75 31.55q-2.1.45-4.05.9-1.1.2-2.15.45l-5.65 1.15q-.45.05-.85.15-.5.1-.95.2-4.35.75-7.95 1.35-4 .6-7.15.95.5 3.5 1.25 9.05.05.4.1.85h-.05l.4 3.5h-.1q-.6.1-1.25.2-.2.05-.4.05-1.25.15-2.55.3-.6.05-1.25.15-5.65.45-11.85.1-1-.05-2.05-.15-8.7-.75-18.45-3.1-.55-.15-1.05-.25-1.25-.35-2.45-.65-4.2-1.15-8.1-2.4l-1.2-.4q-6.55-2.3-12.25-5-.4-.2-.8-.35-1.5-.75-2.95-1.55-1.8-1-3.5-2-1.65-1-3.2-2.05-.45-.25-.8-.55-.7-.45-1.3-.9-2.1-1.5-3.75-2.95l1.8-1.8-.05-.05q1.3-1.35 2.55-2.55l2.55-2.55q-3.75-2.05-7.15-5.1-.25-.2-1.2-1.1l-4.2-4.2-.15-.15-1.1-1.1-.3-.2.05-.05Q19.9 7.4 17 5l-.1-.1q-.3-.3-.55-.6-1.45-1.5-2.25-2.35Q12.25 0 11 0 8.5 0 8.5 5.25q0 1.25 1 6.25l.3.25-.3.35q-.2 2-1.5 3.45-.7.85-3.25 2.6-1.15.8-2.05 1.75Q.35 22.3.05 25.5 0 26 0 26.5v.05l.05.05q3 2.95 6.5 5.2 4.1 2.65 8.85 4.25 2.55.9 5.25 1.45l-.5.5q-8.7 8.55-13.35 12.4l-.05.05q.3.6.65 1.25l.8 1.5q2.15 3.75 5.3 7.1.15.15.3.35 3.45 3.6 8.1 6.8 2.55 1.75 5.5 3.4 1.9 1.05 4 2.05 1.7.85 3.55 1.65 4.55 2 8.5 3.6 9.5 3.8 15.5 4.95l.3.1h.2q4.6.85 14.15 1.2 5.55.15 12.85.15.85 0 4.05-.1 1.85-.1 4.5-.2 6.25-.25 16.95-.7-.45-5.75-.85-10.5-.45-4.85-.8-8.65-.3-3.25-.5-5.7 1.6.65 3.25 1.35 1.45.6 2.95 1.25.25.1.55.2 2.55 2.15 6.5 5 1.6 1.15 3.45 2.4.75.55 1.55 1.05 1.35.95 2.75 1.85 1.75 1.2 3.5 2.35 6.7-5.55 6.7-10.6 0-3.95-4-7.3-4-3.4-4-5.95 0-2.55 3.5-7 3.5-4.4 3.5-7.75 0-4-4.25-4M23.5 36.25l-1-.6q.05-.1.15-.15.45.4.85.75z"
                    id="AccHat046__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M29.15 11.65q-.25-.2-1.2-1.1-.7 3.5-1.9 6.6-.45 1.05-.95 2.15-3.4 7-9.7 11.85 2.55.9 5.25 1.45l-.5.5q7.4-1.7 14.35-5.55-.7-.45-1.3-.9-2.1-1.5-3.75-2.95l1.8-1.8-.05-.05q1.3-1.35 2.55-2.55l2.55-2.55q-3.75-2.05-7.15-5.1m-6.65 19.1q.05-.1.15-.15.45.4.85.75l-1-.6M22.2 4.9l.05-.05Q19.9 2.5 17 .1l-.1-.1Q10.75 12.1.05 20.6q-.05.5-.05 1v.05l.05.05q3 2.95 6.5 5.2 10.9-8.9 15.65-22M58 39.05q-6.55-2.3-12.25-5-.4-.2-.8-.35-4.15 2.65-8.65 4.95-12.65 6.4-28.1 9.65 2.15 3.75 5.3 7.1.15.15.3.35Q36.85 50.6 58 39.05m12.8 3.7q-.55-.15-1.05-.25Q47.6 56.4 21.9 62.55q2.55 1.75 5.5 3.4 1.9 1.05 4 2.05 29.3-8.25 57.85-22.15-8.7-.75-18.45-3.1m19.7 36.8q11.6-4.4 20.6-11.4-.45-4.85-.8-8.65-16.55 12.25-36.7 20 5.55.15 12.85.15.85 0 4.05-.1m17.95-36.7l.25 2.35h-.1q-.6.1-1.25.2-.2.05-.4.05-1.9 1.25-3.8 2.45-27.8 17.6-59.7 25.35 9.5 3.8 15.5 4.95l.3.1h.2l-.15-.25Q81.6 69 103.15 56.4l6.4-3.8.1-.1q.05.6.15 1.3 1.6.65 3.25 1.35-1.25-5.35-1.2-10.65 0-.75.05-1.45.3-6.1 2.25-12.2-4 .6-7.15.95.5 3.5 1.25 9.05.05.4.1.85.05.6.1 1.15M129.55 28l-5.65 1.15q-.45.05-.85.15l-.4 1.6q-.95 3.95-1.35 7.95-.05.2-.05.5-1.1 11.1 1.8 22.25 1.6 1.15 3.45 2.4.75.55 1.55 1.05 1.35.95 2.75 1.85-3.9-19.7.9-39.35-1.1.2-2.15.45z"
                    id="AccHat046__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat046__Symbol_264_0_Layer0_0_FILL"
                transform="translate(59.05 8.25)"
                id="AccHat046__col1n"
            />
            <use
                xlinkHref="#AccHat046__Symbol_172_0_Layer0_0_FILL"
                transform="translate(59.05 11.45)"
                id="AccHat046__col1d"
            />
            <use
                xlinkHref="#AccHat046__Symbol_86_0_Layer0_0_FILL"
                transform="translate(151.05 24.65)"
                id="AccHat046__col2n"
            />
            <use
                xlinkHref="#AccHat046__Symbol_26_0_Layer0_0_FILL"
                transform="translate(151.05 29.55)"
                id="AccHat046__col2d"
            />
        </g></g>
    )
}

export default AccHat046
