import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525",
    "col2n": "#434343"
}

function AccHat067({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M178.5 47.2q.05 0 .15.05.3.1.6.25 4.1 1.55 6.05 4.55.55.85.9 1.85 8.95-19.85 7.35-39.7L131.9 0 34.65 23.25 0 73.9q16.2 18.55 30.8 24.5.2-4.1 2.75-6.75.55-.6 2-1.45 1.45-.9 35.1-12.75 33.6-11.85 107.85-30.2v-.05z"
                    id="AccHat067__Symbol_285_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M124.15 20.1q14.4-2.9 30.85-7h.4q.9-2.7.05-6.45-.35-1-.9-1.85-1.95-3-6.05-4.55-.3-.15-.6-.25h-.15Q73.5 1 8.5 40.45 6.25 41.8 4 43.25q-.65.55-1.2 1.15Q.25 47.05.05 51.15q-.05.8 0 1.65.25 4.7 2.35 8.45.05.05.1.15l.85-.8q39.5-18.5 72.95-28.05t47.85-12.45z"
                    id="AccHat067__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    d="M236.35 107.3l1.4-.7q-6.433-9.25-29.55-18.5-55.85 11.5-103.15 41.15l-.85.8q-.05-.1-.1-.15-.1.05-.15.15 1.5 1.7 3.6 3.1 2.5 1.6 5.85 2.65 2.85.85 6.4 1.3 12 1.6 31.2-2.05.4-.1.85-.2.3-.3.65-.15l24.8-6.25v-.05q.85-.25 1.75-.5 3.95-1.15 8.15-2.45 1.9-.65 3.9-1.35.7-.2 1.4-.45 14.9-4.45 26.45-8.8 9.95-3.8 17.4-7.55m18.05-13.2q4.55-5.75 2.85-11.4-.1-.35-.2-.65l-.1-.3h-.25q-17.95 1.15-35.05 3.95 6.976 8.537 25.6 15.1 4.6-3.35 7.15-6.7z"
                    id="AccHat067__Layer8_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M221.65 85.7q-6.8 1.1-13.45 2.4l29.55 18.5q5.5-2.9 9.5-5.8l-25.6-15.1z"
                    id="AccHat067__Layer8_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat067__Symbol_285_0_Layer0_0_FILL"
                transform="translate(70.95 21.4)"
                id="AccHat067__col1n"
            />
            <use
                xlinkHref="#AccHat067__Symbol_95_0_Layer0_0_FILL"
                transform="translate(101.7 68.65)"
                id="AccHat067__col2n"
            />
            <use xlinkHref="#AccHat067__Layer8_0_FILL" />
            <use xlinkHref="#AccHat067__Layer8_1_FILL" />
        </g></g>
    )
}

export default AccHat067
