import { AssetSvgProps } from "../../../shared/assets"

function ObjToo050({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#515151"
                    d="M115.65 310.6l-.1 1.45 5.25 2.6 3.05-1q-.739-4.182-8.2-3.05m16.6 4.5l2.25 2.95 58.65-9.55.35-.1.8-7 12.15-1.95q1.4.9 2.25 1.35l.7.6.8 3.6q14.754 8.404 47.65-4.25l6.55 1.75 23.45-12.7-.9 3.55 3.7-2.05 2.45-14.6-1.55-1.95-3.35 12.95-5.05 2.15 1.05-.4-20.3 10.05q-23.89-6.56 5.3-15.5l.05-.05q5.05-.45 8.35-1.85l-.55-3.55q-4.424 1.986-9.9 2.75-.597.082-1.2.15l.05.05-2.45.3v-2.35l2.1-2.6v-.15l2.1-.3.45 2.95 4.3-3.75-3.5-21.5-5.2-1.75.45 2.5-2.05.35-2.9-1.75-6.6 1.1.4 2.15-2.75.4-3.35-2.4-6.75 1.1-.6-3.85-1 .15.8-1.9-3.35-.75.1-.25 14.05-14.5 28.05 1.8 7.35 11.2.85-2.35-7-13.05-4.05-.8 2.2 3.45-27.2-4.1-.75.15-1.9 2.65-7.5 8.65-4.85 5.45q-.1-.05-.15-.1l-.1-.05q-.2-.4-.4-.7-.35-.65-.75-1.2-.8-1.45-2-2.8l-1.65-1.95q-.8-.9-1.7-1.75-.1 0-.1-.05-.4-.3-.8-.5-6.95-4-12.7-1.4-3.05 1.3-5.65 4.4-2.75 3.15-5.15 8.2v.05q-3.1 9.3-15.15 13.45l-.95-5.35-.25 2q-20.786 30.76-55.7 55.1m79.4-73.8q1.9-1.95 3.95-2.9 4.6-2.05 10.1.6 1.05.5 2.15 1.25.75.65 1.4 1.4-.75 2 .3 4.1.2.45.4.7.25.85 1.65 1.75.35.25.75.45.05.05.15.1l.9 1.05q.1.25.15.45l.05.05q-.7.85-.4 2.5-.05.1-.05.15l-.15.05.05.05-3.6.6-.05-.05-5.6 5.45.55 3.55-34.5 5.6-.55-3.5q.05 0 .1-.05 12.85-4.4 16.1-14.2 2.75-5.95 6.15-9.15z"
                    id="ObjToo050__Layer14_0_FILL"
                />
                <path
                    fill="#EAEAEA"
                    d="M242.65 297.65l-1.6.2q-.35 1.85-1.2 3.4.05.05 0 .05-.15.25-.3.6-.2.1-.3.35-3.5 4.85-12.45 5.15l-.05.05q-10.85-.8-15.7-4.85-.45-.3-.75-.6-.1-.2-.9-.6l-.4 3.05q.1 6.6 7.1 3.2l.5.25q5.9 3 27.75-2.35 7.35-1.8 13.5-4.8L244.2 297q-.55.35-1.55.65m10.9-26.3q-.55-.35-1.05-.65-5.45-3.35-10.45-3.9-.5-.05-.95-.1-4.75-.35-9.2 1.95-.7.3-2.1 1.2-1.4 1-2.7 2.55-.05 0-.1.1-.45.55-.9 1-1 1.4-1.9 3.1-1.35 2.4-2.5 5.55-3.05 6.85-10.95 10.35l-1.15 7.45q1.15.8 1.45 1.1.4.25.7.45.25.25.35.2 4.7 3.65 14.7 4.4 7.5-.25 10.8-3.95l-.05-.1q.3-.25.5-.55.05-.1.15-.15 1-1.5 1.55-3.4-.05-.1-.05-.2-.15-.1-.15-.45-.05-.05-.05-.15l1.2-8q.8-.7 2.45-.95 1.25-.2 2.25.2 1.15.45 1.3 1.4.1.4-.25.75l-.5.45-.25.5-.05.05q-.3.6-.3.7.1.3.4 1h.05q.1.3.2.45l.1.3 15.4 4.75q2.45-10.75 13.1-10.35l-5.35-4.45-.05.05-15.65-12.65m-27.4 24.2q1.85-.25 3.3.75 1.5 1.15 1.75 2.95.35 1.8-.75 3.2-1.05 1.6-2.85 1.85-1.85.35-3.4-.8-1.4-1-1.7-2.85-.3-1.8.7-3.3 1.15-1.45 2.95-1.8m2.5 5.8q.65-.8.5-1.9-.2-.95-1-1.55-.85-.6-1.8-.4-1.1.1-1.7.95-.55.85-.4 1.8.15 1.05.95 1.65.85.55 1.85.45 1-.15 1.6-1M106.4 286.4q-.202.313-.4.6-4.803 8.742.4 16 2.325 3.25 6.65 6.2 1.3.8 2.6 1.4 4.05 1.9 8.2 3.05 4.05 1.2 8.4 1.45 16.25 1.3 34.9-8.5l1.7-.1q.7-.75 1.25-1.5.85-1.3 1.2-2.55.25-.9.3-1.8l13.5-17.35 3.1-25.3q-18.45-3.35-38.7 2.5-7.8 2.25-16 5.8-17.953 7.289-27.1 20.1z"
                    id="ObjToo050__Layer5_0_FILL"
                />
                <path
                    id="ObjToo050__Layer14_0_1_STROKES"
                    stroke="#000"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M235.45 247.75l4.85-5.45"
                />
                <g id="ObjToo050__Layer14_1_FILL">
                    <path d="M229.45 296.3q-1.45-1-3.3-.75-1.8.35-2.95 1.8-1 1.5-.7 3.3.3 1.85 1.7 2.85 1.55 1.15 3.4.8 1.8-.25 2.85-1.85 1.1-1.4.75-3.2-.25-1.8-1.75-2.95m-18.7-3.8q7.9-3.5 10.95-10.35l-33.25 5.4 4.7 20.95.35-.1.8-7 12.15-1.95q1.4.9 2.25 1.35l.7.6q.8.4.9.6.3.3.75.6 4.85 4.05 15.7 4.85l.05-.05q8.95-.3 12.45-5.15.1-.25.3-.35.15-.35.3-.6.05 0 0-.05.85-1.55 1.2-3.4.15-.5.2-1 .1-.05.05-.1 0-.15.05-.2-.1-.15-.2-.35-.05-.05-.05-.1v-.05q-.1-.05-.2-.05-.1-.05-.15-.05-.25-.05-.5.15-.05.05-.15.05-.1.2-.05.35-.05.1-.05.2-.05.2 0 .3-.15.4-.25.95-.55 1.9-1.55 3.4-.1.05-.15.15-.2.3-.5.55l.05.1q-3.3 3.7-10.8 3.95-10-.75-14.7-4.4-.1.05-.35-.2-.3-.2-.7-.45-.3-.3-1.45-1.1-.3-.3-.45-.55-.2-.15-.3-.3l.9-.2 1-6.4m.9-51.2q1.9-1.95 3.95-2.9 4.6-2.05 10.1.6 1.05.5 2.15 1.25.75.65 1.4 1.4-.75 2 .3 4.1.2.45.4.7.25.85 1.65 1.75.35.25.75.45.05.05.15.1l.9 1.05q.1.25.15.45l.05.05q-.7.85-.4 2.5-.05.1-.05.15l-.15.05.05.05-3.6.6 2.45 15q4.45-2.3 9.2-1.95.45.05.95.1l-2.4-14.85-1 .15.8-1.9-3.35-.75.1-.25-.9-1.55-.1-.05q-.2-.4-.4-.7-.35-.65-.75-1.2-.8-1.45-2-2.8l-1.65-1.95q-.8-.9-1.7-1.75-.1 0-.1-.05-.4-.3-.8-.5-6.95-4-12.7-1.4-3.05 1.3-5.65 4.4-2.75 3.15-5.15 8.2v.05q-3.1 9.3-15.15 13.45l-.95-5.35-2.1 17.25 1.15 7.3 36.95-5.95q.9-1.7 1.9-3.1l-.1-.9-36.15 5.85.15-2.15-.05-.05 35.7-5.75-1.3-7.9-34.5 5.6-.55-3.5q.05 0 .1-.05 12.85-4.4 16.1-14.2 2.75-5.95 6.15-9.15m54 35.4l2.1-.3-3.5-21.55-2.05.35 3.45 21.5m-12.55-20l-2.75.4 2.15 13.6q.5.3 1.05.65l2.15 1.75-2.6-16.4z" />
                    <path
                        fill="#8F8F8F"
                        d="M230 266.05l-.35-2.15-3.4 3.05.35 2.05 3.4-2.95m-1-6.6l-.4-2.05-3.4 2.95.25 2.05 3.55-2.95m-39 16.85l-.15 2.15L226 272.6l-.35-2.1-35.7 5.75.05.05z"
                    />
                    <path
                        fill="#333"
                        d="M277.6 282.1l-.55-3.55q-4.424 1.986-9.9 2.75-.597.082-1.2.15l.05.05 3.2 2.5.05-.05q5.05-.45 8.35-1.85z"
                    />
                </g>
                <g id="ObjToo050__Layer5_1_FILL">
                    <path
                        fill="#FFF"
                        d="M253.55 271.35q-.55-.35-1.05-.65-5.45-3.35-10.45-3.9-.5-.05-.95-.1-4.75-.35-9.2 1.95-.7.3-2.1 1.2-1.4 1-2.7 2.55-.05 0-.1.1-.45.55-.9 1-1 1.4-1.9 3.1-1.35 2.4-2.5 5.55-3.05 6.85-10.95 10.35l-.15.95q12.7 2 25.1.15.55-3.9 5.75-7.4 4.1-2.45 7.2 1.15l2.55 2.5q8.15-2.85 16.15-7.3l-13.8-11.2m-95.7 30.05q-.2.85-.1 1.65 1.2 2.75 5.65 2.65l6.7-.7q.85-1.3 1.2-2.55.25-.9.3-1.8-.05-1.6-.9-3.15l-6.85-.5q-3.85.5-5.6 3.55-.2.4-.4.85m-7.5-35.65q-5.2 1.7-10.8 4.2-14.55 6.5-23.25 14.5-8.65 8.05-6.45 12.9 2.15 4.85 13.9 3.6 11.8-1.2 26.3-7.65 14.45-6.55 23.15-14.55 5.1-4.65 6.4-8.15 1.15-2.65.15-4.75-2.15-4.75-13.95-3.65-7.2.75-15.45 3.55z"
                    />
                    <path
                        fill="#CCC"
                        d="M157.75 303.05q.45 4.15 3.6 3.95l7.5-.5q.7-.75 1.25-1.5l-6.7.7q-4.45.1-5.65-2.65z"
                    />
                </g>
            </defs>
            <g>
                <use xlinkHref="#ObjToo050__Layer14_0_FILL" />
                <use xlinkHref="#ObjToo050__Layer14_0_1_STROKES" />
            </g>
            <use xlinkHref="#ObjToo050__Layer14_1_FILL" />
            <use xlinkHref="#ObjToo050__Layer5_0_FILL" />
            <use xlinkHref="#ObjToo050__Layer5_1_FILL" />
        </g></g>
    )
}

export default ObjToo050
