import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#0000BB",
    "col1n": "#0000FF",
    "col2n": "#00DEDE"
}

function ShiSho006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.25 6.75q.7-1.25 0-2.9-.3-.5-.7-1.05l-.05-.05q-1-.35-2.5-.55-4.35-.4-8.7-.1-.4 0-.75.05-.25-2.15-3.05-.75-3 1.6-3.05 3.6-.15.2-.2.45v28.2q-5.45 1.55-9.35 2.3-1 .2-1.95.4-11.7 2.2-23.45 3.2l-2.6.2q-17.7 1.2-35.35-.45-4.3-.4-8.6-.95-6-.85-11.95-1.95-4.55-.9-9.15-1.95-.45-.1-.95-.2-.1-.05-1.55-.4V5.35q-.1-.35-.3-.65-.35-.7-.95-1.15Q17.4 2.3 14.95.8q-2.85-1.75-3.3.4-2.9-.25-5.85.7-2.7.75-4.2 2.25l-.3.3Q0 5.95 0 9l.15 1.8q1 1.25 2.95 0 4.8-2.7 6.45-3.75l.3-.2-.1.2q-.85 1.55-1.1 6.1-.2 1.7 1.75 1.7 1.6 0 2.85-2.2.6-1.1 1.05-2.05.4-1 .6-1.85.4-1.75.65-3.25 0 .2.05.4.1 4.1-.05 10.1v18.15l-2.8 13.95q-.55 2.7-1.1 5.45L8.3 67.6q.25-.95.5-1.8L8.2 68l-.05.05-1.9 7.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H6.1q1.65.4 3.35.75.1 0 .2.05l1.8.4q.5.1.95.2h-.2q60.79 13.33 120.75-.45 2.1-.45 4.15-.95H137.25q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.7v-27q.4.65.85 1.45.2.3.35.6.5.85.9 1.5.55.9 2.45 2 .15.05.3.15 3.3 1.55 4.75 0 .3-.25.45-.6.2-.4.2-1.95 0-.85-.85-2.55h.05q4.65.45 6.05-1m-1.2-1.3l-.15.1v.05h-.2l-.45.2q-3.05 1.15-5.85.35-.85-.05-2.7-.9-1-.45-1.45-.75.1-.05.45-.15.35-.15 2.3-.45.45 0 2.6-.3 2.4 0 3.5.2 1.1.15 1.4.3.25.1.7.7.45.6-.15.65m-6.5 6.2q-.4.25-1.6-.15-1.15-.45-1.55-.8-.4-.3-.8-.8-.45-.55-1.35-1.8-.4-.55-.7-1.15-.4-.9-.65-1.9.3.2.7.45.15.1.35.2.4.2.9.5.25.15.55.3 4.75 2.65 4.75 3.85.15.4 0 .7-.05.4-.6.6m-27.85 63.3l3.1.1v.05l-3.1-.05v-.1M13.2 5.6q.35.05.4.35v.4q0 .35-.05.75-.15 1.25-.7 2.95-.85 2.8-1.85 2.8-.85 0-.65-1.15 0-1.7.5-3.2 1.45-2.9 2.25-2.9h.1m-1.5-2.4q0 .1.05.25-1.85 1.25-5.2 3.3Q2.8 8.95 1.3 9.8 2 3.25 8.55 3.25l3.15-.05z"
                    id="ShiSho006__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M7.9 2.05q.45-.65.45-1.25l-.1-.8v.05Q7.7 1.3 6.15 1.3q-.35 0-.9-.15t-.6.05Q4.6 1.4 2 3.1l-1.1.8.3-.2-.1.2Q.25 5.45 0 10q-.2 1.7 1.75 1.7 1.6 0 2.85-2.2.6-1.1 1.05-2.05.4-1 .6-1.85.4-1.75.65-3.25 0 .2.05.4V2.5q.5.25.95-.45m-3.45.4h.1q.35.05.4.35v.4q0 .35-.05.75-.15 1.25-.7 2.95-.85 2.8-1.85 2.8-.85 0-.65-1.15 0-1.7.5-3.2 1.45-2.9 2.25-2.9M128.5 4.6h.05q-3.55-.55-5.3-1.15-.55-.2-1.05-.4.25.15.55.3Q127.5 6 127.5 7.2q.15.4 0 .7-.05.4-.6.6-.4.25-1.6-.15-1.15-.45-1.55-.8-.4-.3-.8-.8-.45-.55-1.35-1.8-.4-.55-.7-1.15-.4-.9-.65-1.9l.6.4q-.626-.432-1.1-1-.4.65-.8.85-.35.15-1 .15-.5 0-1.4-.25.1.9 1.55 1.35.6.2 1 .7V4q.4.65.85 1.45.2.3.35.6.5.85.9 1.5.55.9 2.45 2 .15.05.3.15 3.3 1.55 4.75 0 .3-.25.45-.6.2-.4.2-1.95 0-.85-.85-2.55z"
                    id="ShiSho006__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M39.85 34.95l-.95-6.75-3.5 5.85-6.8-1.15 4.5 5.1-3.2 6.1 6.3-2.7 4.8 4.95-.65-6.85 6.15-3-6.65-1.55M0 35.45h.05l.05.05 3.25 3.75-3.3 6.05 6.3-2.65 4.75 5-.6-6.85 6.2-2.9-6.6-1.65-.85-6.75-3.6 5.85-5.3-1.25L0 35.45M2.75 23.8l.2.1H3l3.6-.3-3.05-2v.1l-.05.05h-.25l-.5 2.05M24.3 4.25l-1.05-2.3Q18.7 1.05 14.1 0l-.85 5.85 5-4.65 6.05 3.05m3 9.95l-5.6 3.9-5.7-3.9 2 6.55-5.45 4.2 6.85.15 2.3 6.5 2.2-6.5 6.85-.15-5.4-4.2 1.95-6.55m31.25 18.95L58.4 40l-6.5 2.3 6.45 2.2.2 6.85 4.15-5.4 6.55 1.95-3.9-5.65 3.9-5.6-6.55 1.9-4.15-5.4m-10-22.3l-5.3-4.3.55-1.7q-4.3-.4-8.6-.95l.7 2.45-5.55 4.05 6.85.3 2.1 6.5 2.45-6.35h6.8m5.65 3.45l-4 5.55-6.7-1.75 4.1 5.5-3.75 5.8 6.5-2.15 4.35 5.35-.05-6.9 6.4-2.4-6.5-2.15-.35-6.85m14.15 1.45L61.8 17.9l6.4 2.4.05 6.9 4.25-5.4 6.5 2.15-3.7-5.75 4-5.55-6.6 1.85-4.1-5.6-.25 6.85M79.15 5.3l1.15 3.6 1.45-3.8-2.6.2m39 35.2l-5.4-4.2 1.95-6.55-5.65 3.9-5.65-3.9 1.95 6.55-5.4 4.2 6.8.15 2.3 6.5 2.25-6.5 6.85-.15m-27.4 2.7l-3.1 6.15-6.85-.7 3.8 3.7 4.05-.35h.25l2.35-.25.8-.1h.1q3.05-.3 6.1-.7l-.3-.1-5.75-.95-1.45-6.7m4.55-26.4l-3.7 5.75-6.7-1.5 4.25 5.3.05.05-3.5 5.95 6.4-2.5L96.65 35l-.3-6.85 6.25-2.7-6.6-1.8v-.1l-.7-6.75m9.9-14.9l-.95 3.6-6.85.6 5.7 3.8-1.55 6.7 5.3-4.25 5.85 3.55-2.3-6.45L115.6 5l-6.85.25-1.6-3.75q-1 .2-1.95.4M127.75 37q.15-.05.3-.05h.1l-.65-2.65h-.05q-.2 0-.35.05h-.05l-3.45 1.6 4.1 1.05h.05z"
                    id="ShiSho006__Symbol_70_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho006__Symbol_14_0_Layer0_0_FILL"
                transform="translate(120.85 229.95)"
                id="ShiSho006__col1n"
            />
            <use
                xlinkHref="#ShiSho006__Symbol_52_0_Layer0_0_FILL"
                transform="translate(129.5 233.1)"
                id="ShiSho006__col1d"
            />
            <use
                xlinkHref="#ShiSho006__Symbol_70_0_Layer0_0_FILL"
                transform="translate(128.6 264.4)"
                id="ShiSho006__col2n"
            />
        </g></g>
    )
}

export default ShiSho006
