import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#3C0000",
    "col1n": "#830000",
    "col1s": "#190000",
    "col2d": "#00AFAF",
    "col2n": "#00DEDE",
    "col2s": "#007070"
}

function ShiLon043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.35 7.9q-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-14.9 10.3-32.55 14.1-2.15.45-4.3.85 0 .05-.3.6-.3.5-14.95 9.85-.85.55-1.5 1-2.4-1.85-6.65-4.6Q64.2 19.7 64.2 19.6q-2-.3-3.9-.7-18-3.75-33.2-14.25L25.55 3.5q-1.45-1.05-2.8-2.1L20.9 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8v.1q-.1.25-.15.5l-.7 2.7v.15q-.1.15-.1.35H.7q-.15.5-.25 1-.05.1-.05.2l-.1.4-.3 1.4q-.05.05 16.85 5.8 2.3.75 4.25 1.4-.4 1.15-.9 2.3l-2.7 11.3q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H15.4q.85.2 1.75.4 1.2.3 2.4.55 30.8 7 61.4 7 30.75 0 61.3-7 1.05-.25 2.1-.45 1.05-.25 2.05-.5H146.55q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-3.8-15.25v-.05l-.05-.1-1.3-5.3H142l-.55-2.45.05-.05.05.05-.9-3.95q7.85-1.75 21.3-5.2l-.3-1.3q0-.1-.05-.15-.05-.25-.1-.45-.15-.75-.35-1.5v-.1q-.35-1.3-.65-2.6l-.2-.6v-.1l-.4-1.5q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6z"
                    id="ShiLon043__Symbol_314_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M41.9.15q0-.05.05-.15-2.15.45-4.3.85 0 .05-.3.6-.3.5-14.95 9.85-.85.55-1.5 1-2.4-1.85-6.65-4.6Q3.9.95 3.9.85q-2-.3-3.9-.7.1.55.25 1.1 1.3 6.3 3.65 12.65l.3.8q2.85 5.95 7.2 12 2.05 2.9 4.45 5.8 2.1 2.55 4.45 5.1 2.3-2.5 4.4-5 2.85-3.5 5.35-7 3.8-5.4 6.75-10.9 2.65-6.35 4.65-12.9.2-.85.45-1.65z"
                    id="ShiLon043__Symbol_269_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65M116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4z"
                    id="ShiLon043__Symbol_246_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.8 32.2v.2q-.05.05-.05.1l-.1.7q-.15.5-.2 1-.2 1-.4 2.05-.05.2-.05.4v.1q2.25-1.95 5.05-2.85-.6.15-1.05.25.75-.35 1.55-.55.4-.1.85-.2 1.3-.25 2.75-.3-.35.05-.7.15l.75-.1q7.4-.3 11.85 3.9l.8.8-.1.45q.95-2.15 1.55-4.5.4-1.45.65-3-4.75-3.8-9.55-4.65Q8.05 25 1.5 28.95q-.35 1.6-.7 3.25M99.5 0q-3.9.65-7.95 1-.5 0-1.05.05l-1.1.05q-1.25.1-2.5.15-1.9.05-3.8.05h-1.2q-2.15 0-4.25-.1-1.05-.05-2.05-.1l-1.1-.05Q70.6.75 66.85.2l-1.1-.2q0 .1.05.2 1.3 4.95 3 9.8l.5 1.4q.45 1.25.9 2.45 2.15 4.5 4.5 8.5 1.15 2 3.05 4.6 1.9 2.6 3.25 3.9 1 .95 1.1 1.3.05-.2.35-.5 1.7-2.05 3.35-4.15l1.7-2.3q1.5-2.1 2.85-4.2 2.3-3.55 4.25-7.15.45-1.1.9-2.25l.4-1 .5.15-.4-.4Q97.65 6 99.05 1.6q.2-.7.4-1.45.05-.1.05-.15m64.05 28.85q-.05-.1-.05-.15-11.35-6.55-22.05 1.95l1.65 7.2q.2.1.25-.35 4.5-4.7 12.3-4.35l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V36.6q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.05-.05-.05-.15-.2-1-.4-2.05-.1-.45-.2-.85l-.1-.5z"
                    id="ShiLon043__Symbol_211_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M10.3 6.9l4.35-4.4L12.2.05Q10.3.1 8.4.1H7.2Q5.05.1 2.95 0l-1.7 1.7 4.8 4.8L0 21.15q1.15 2 3.05 4.6 1.9 2.6 3.25 3.9 1 .95 1.1 1.3.05-.2.35-.5 1.7-2.05 3.35-4.15l1.7-2.3q1.5-2.1 2.85-4.2L10.3 6.9z"
                    id="ShiLon043__Symbol_178_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M0 7.1l.5 1.4L8 .85 7.15 0 0 7.1m27.1.6l.5.15L20.55.8l-.7.7 6.85 7.2.4-1z"
                    id="ShiLon043__Symbol_169_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M247.65 264.1q-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15m-111-.15q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1z"
                    id="ShiLon043__Layer11_0_FILL"
                />
                <g id="ShiLon043__Layer11_1_FILL">
                    <path
                        fill="#FF5400"
                        d="M258.75 309.3v-.05l.05.05-.05-.1-3.85-15.3-6.2 6 9.2 9.05q.793.45.15.7h.05q.25-.05.5-.15.109 0 .2-.05l-.05-.15m-1.05-1h.05v.1l-.05-.1m-121.8-8.35v-.05l-5.95-6-.9 3.9q-.529 2.099-1.05 4.2-.467 1.8-.95 3.6l-.9 3.6v.15l9.75-9.4m56 9.3l9.35-9.35-9.35-9.4-9.4 9.4 9.4 9.35m-12.45-9.3v-.05l-9.35-9.4-9.4 9.4 9.4 9.35 9.35-9.3m47.35-.05l9.35 9.35 9.35-9.35-9.35-9.4-9.35 9.4m-21.95 0l9.4 9.35 9.35-9.35-9.35-9.4-9.4 9.4m-47.2.05v-.05l-9.3-9.4-9.4 9.4 9.4 9.35 9.3-9.3z"
                    />
                    <path
                        fill="#00B"
                        d="M130.55 291.4l-.6 2.5 5.95 6v.05l-9.75 9.4-.05.1v.05h.2q.25.1.5.15H126.95q.871.201 1.75.4l8.65-8.65 10.9 10.9 10.85-10.9 10.9 10.9 10.9-10.9 10.9 10.9 11.15-11.2 11.2 11.2 10.95-11 10.95 11 10.95-11 8.65 8.65h.05q.09.06.2.15 1.027-.214 2.05-.45h.1q.643-.25-.15-.7l-9.2-9.05 6.2-6h.05l-.05-.15-.65-2.6-7.25 7.1-10.95-10.95-10.95 10.95-10.95-10.95-11.2 11.15-11.15-11.15-10.9 10.9-10.9-10.9-10.9 10.9-10.85-10.9-10.9 10.9-6.8-6.8m27.1 8.5v.05l-9.3 9.3-9.4-9.35 9.4-9.4 9.3 9.4m56.6 9.35l-9.4-9.35 9.4-9.4 9.35 9.4-9.35 9.35m21.9 0l-9.35-9.35 9.35-9.4 9.35 9.4-9.35 9.35m-56.7-9.35v.05l-9.35 9.3-9.4-9.35 9.4-9.4 9.35 9.4m21.8 0l-9.35 9.35-9.4-9.35 9.4-9.4 9.35 9.4z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon043__Symbol_314_0_Layer0_0_FILL"
                transform="translate(111.55 228.6)"
                id="ShiLon043__col1n"
            />
            <use
                xlinkHref="#ShiLon043__Symbol_269_0_Layer0_0_FILL"
                transform="translate(171.85 247.35)"
                id="ShiLon043__col1d"
            />
            <use
                xlinkHref="#ShiLon043__Symbol_246_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiLon043__col1s"
            />
            <use
                xlinkHref="#ShiLon043__Symbol_211_0_Layer0_0_FILL"
                transform="translate(110 248.2)"
                id="ShiLon043__col2n"
            />
            <use
                xlinkHref="#ShiLon043__Symbol_178_0_Layer0_0_FILL"
                transform="translate(184.7 249.4)"
                id="ShiLon043__col2d"
            />
            <use
                xlinkHref="#ShiLon043__Symbol_169_0_Layer0_0_FILL"
                transform="translate(178.8 251.1)"
                id="ShiLon043__col2s"
            />
            <use xlinkHref="#ShiLon043__Layer11_0_FILL" />
            <use xlinkHref="#ShiLon043__Layer11_1_FILL" />
        </g></g>
    )
}

export default ShiLon043
