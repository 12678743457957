import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.25 9.2l.1-.1q-.4.1-.85.25-6.5 1.55-12.7-3.6Q23.6.55 18.2.05q-5.5-.5-9.65 2.65-1.05.7-1.7 1.45-1.5 1.4-4.25 5-.8.95-1.25 2-.55.95-1.1 2.15-.6 1.15.3.35.85-.85 5.95-4.2 1.9-1.35 3.9-2.1 1-.4 1.8-.5 4.95-.35 9.05 1.75 4.2 2.15 6.65 3.7 6.25 2.9 12.5-1.15Q42.55 10 43.25 9.2M85.1 7.35q2 .75 3.9 2.1 5.1 3.35 5.95 4.2.9.8.3-.35-.55-1.2-1.1-2.15-.45-1.05-1.25-2-2.75-3.6-4.25-5-.65-.75-1.7-1.45Q82.8-.45 77.3.05q-5.4.5-11.6 5.7-6.2 5.15-12.7 3.6-.45-.15-.85-.25l.1.1q.7.8 2.85 1.95 6.25 4.05 12.5 1.15 2.45-1.55 6.65-3.7 4.1-2.1 9.05-1.75.8.1 1.8.5z"
                    id="FacBro025__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro025__Symbol_25_0_Layer0_0_FILL"
                transform="translate(144.75 123.5)"
                id="FacBro025__col1n"
            />
        </g></g>
    )
}

export default FacBro025
