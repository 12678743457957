import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#435125",
    "col1n": "#617433",
    "col2n": "#BFBFBF"
}

function ObjToo017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M36.85 34.6q-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.6 2.2-1.5 3.7l7.05 22.4q1.1 2.25 5.25 1.35 3.75-1.4 3.3-4l-8.3-26.85q-2.45-.35-4.3-2.2M21 15.8q.5-1.2.8-2.55.05-.25.1-.45.7-4.45-1.9-8.15-.1-.15-.2-.25l-.1.05.1-.1Q17.15.9 12.85.15q-1.7-.3-3.3-.05v.1Q9.1.15 8.45.5 8.4.4 8.4.35L8.35.3Q6.4.8 4.65 2.1.95 4.75.2 9.2q-.35 1.9-.05 3.7.4 2.4 1.95 4.5.9 1.3 2.05 2.25.7-1.1 1.6-2.3 3.1-4.4 4.5-6.3l1.1-1.5q.4-.3.8-.3.4-.05.8.25.25.2.5.55.15.25 0 .9-.7 2.4-6.4 10.3 1 .4 2.2.6 4.45.75 8.2-1.9 2.35-1.7 3.55-4.15m8.5-4.75l-3.1 1.1-1.05 4.45-.05.15 2.45 7.7q.6.15 1.3.35 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4l.2-.2-2.9-9.35-.1-.25-4.55-2.95z"
                    id="ObjToo017__Symbol_177_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M20.85 11.4q.5-1.2.8-2.55.05-.25.1-.45.7-4.45-1.9-8.15-.1-.15-.2-.25l-.1.05L12.8 5.1q.05 0 .05.05-.15.1-.3.25-.85.8-1.95 1.15-.5.15-.6.55l-.3.1q.2-.3.4-.55L0 8.5q.4 2.4 1.95 4.5.9 1.3 2.05 2.25.15-.25.3-.45.45.35 1.2.85.9.6 1.65.9-.15.15-.25.3 1 .4 2.2.6 4.45.75 8.2-1.9 2.35-1.7 3.55-4.15M34 9.85l-.1-.25-4.55-2.95q-.1-1.05-1.4-.85-1.4.2-1.4 1.5 0 .2.05.35l-.35.1-1.05 4.45q4.75.25 8.8-2.35z"
                    id="ObjToo017__Symbol_121_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToo017__Symbol_61_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M3 213.7v6.15q.05.1.1.25.45 1.4 1.15 2.05 2.25 1.85 2.4 2.15.2.3.2 1.9 0 1.95-.8 2.7-.45.7-1.45.7-.6-.05-1.1-.15-1.3-.15-1.85-1.55l-.35-1.6v-.3q-1.15.6-1.25 1.2-.05.05-.05.1v.75q-.05 3.05 2.05 4.05.4.2.75.4.8.05 1.75.1 2.6 0 3.9-1.1 4.05-6 0-10.2-.15-.1-.25-.2-1.1-.9-1.5-1.95l-.1-.3q-.1-2.55-.1-5.15H3m62.15 32.8q-.25-.35-.5-.55-.4-.3-.8-.25-.4 0-.8.3l-1.1 1.5q-1.4 1.9-4.5 6.3-.9 1.2-1.6 2.3-1.65 2.5-2.45 4.1-.15-.05-.2-.05-3.35-1.4-4.1-.85-.5.35-.8 1.25-.55 1.35.4 2.7 2.15 3.05 4.95 3.5 2.05.35 3.45-.6 2.25-.9.85-2.85-.7-.95-2-1.8 1.5-2.05 2.8-3.8 5.7-7.9 6.4-10.3.15-.65 0-.9m10.3-6.95l-3.85 1.25.1.3q2.6 3.7 1.9 8.15-.05.2-.1.45l4.6-1.3-2.65-8.85z" />
                    <path d="M5.9 0L4 .65 5.7 6.4v.05l69.75 233.1 2.65 8.85v.2l3.1-1.1.15-.1L5.9 0z" />
                </g>
                <g id="ObjToo017__Layer5_0_FILL">
                    <path fill="#4A6028" d="M109.5 266.8v-.05l-.1.1.1-.05z" />
                    <path d="M43.55 33.2v-.1q-.1-.15-.25-.25h-.05q-.1-.05-.2-.05h-.1q-.1-.05-.1 0h-.05q-.15 0-.2.1-.1.05-.15.1-.2.15-.2.4v135.45q0 .15.1.3-.1.1-.1.25v70.25h1.2V169.4q0-.15-.1-.25.1-.15.1-.3v-131l54.6 224.85.05.05q0 .05.05.15.65-.35 1.1-.3v-.2L44.75 38.15l-1.2-4.95z" />
                    <path
                        fill="#AEAEAE"
                        d="M43.55 33.1v.1l1.2 4.95.65-.2-1.65-5.4-.75.25h.05q.1 0 .2.05h.05q.15.1.25.25z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo017__Symbol_177_0_Layer0_0_FILL"
                transform="translate(89.7 262.4)"
                id="ObjToo017__col1n"
            />
            <use
                xlinkHref="#ObjToo017__Symbol_121_0_Layer0_0_FILL"
                transform="translate(89.85 266.8)"
                id="ObjToo017__col1d"
            />
            <use
                xlinkHref="#ObjToo017__Symbol_61_0_Layer0_0_FILL"
                transform="translate(38 25.95)"
                id="ObjToo017__col2n"
            />
            <use xlinkHref="#ObjToo017__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo017
