import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiSho006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.1 5q1.85 1.2 6.35 2.8 4.5 1.55 7.25 1.6-4.2-1.75-8.6-3.9-4.45-2.15-8.05-5.5v2.2q.5 3.3 2.3 6.3 1.75 2.95 3.85 4.3-4.75.45-11.5 3.65Q6.55 19.35 0 24.4q4.9-1.7 10.2-2.7 5.25-1 10-1-4.35 2-7.6 8.35-.8 1.6-3.45 9.3 1.25-2.8 7.15-5.6 5.85-2.85 9.8-2.45h.3q-.15.15-.3.4-3.2 9.6-.8 19.6Q32.1 38.9 49.55 37q-8.55-.1-11.6.8-3.05.9-5.9 2.55-2.85 1.6-5.65 5.4V41.4q.55-8.1 4.15-13.05-4.45-.3-8.55.35-4.15.65-9.05 3.5l-.2.4q2-4.95 5.4-8.25 3.4-3.3 6.55-4.3v-.3q-7.45-1.05-12.55-.1-5.15.9-4.85 1.4h-.35Q11.8 17.9 17.8 16.1q5.95-1.9 9.7-2.25h.25Q25.7 12.2 24.1 10.6q-3-3.05-3-5.05V5z"
                    id="HaiSho006__Symbol_127_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho006__Symbol_127_0_Layer0_0_FILL"
                transform="translate(158 54.3)"
                id="HaiSho006__col1n"
            />
        </g></g>
    )
}

export default HaiSho006
