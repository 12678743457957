import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525",
    "col2n": "#FFFFFF"
}

function LegPan012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7 19.6V.05l.05-.05q-1.85.4-3.75.75-.3.05-.65.1-.2 0-.4.05-.05 0-.1.05-.4.05-.85.15-4.4.75-8.75 1.45h-.1q-.5.05-1.05.15-4.8.7-9.6 1.3-.6.05-1.15.1-4.8.55-9.6 1-.6.05-1.15.1-4.8.35-9.6.65-.6 0-1.15.05-4.8.2-9.6.35H67.1q-4.8.05-9.6 0-.6-.05-1.15-.05-4.8-.1-9.6-.35-.6 0-1.15-.05-4.8-.25-9.6-.65-.6-.05-1.15-.1-4.8-.45-9.6-1-.6-.1-1.15-.15-4.8-.6-9.6-1.3-.6-.1-1.15-.2l-5-.8-3.8-.7q-.4-.1-.8-.15Q3.7.7 3.6.7l-1-.2Q2.3.45 1.95.4.95.2 0 0q0 .05.05.1v19.5q.95-.2 1.9-.3.35-.1.65-.1.5-.1 1-.15h.15q.4-.1.8-.15 1.9-.25 3.8-.45 2.5-.3 5-.5.6-.05 1.15-.1 4.8-.35 9.6-.4h1.15q4.8 0 9.6.3.6.05 1.15.1 4.8.3 9.6.95.6.1 1.15.2 4.8.7 9.6 1.75.6.1 1.15.25 2.7.6 5.35 1.35 2.1-.6 4.25-1.05.55-.15 1.15-.25 4.8-1.15 9.6-1.9.55-.1 1.15-.15 4.8-.75 9.6-1.1.55-.05 1.15-.1 4.8-.35 9.6-.35h1.15q4.8 0 9.6.35.5 0 1.05.05h.1q4.35.35 8.75.9.4.05.85.1.05 0 .1.05.2 0 .4.05.3.05.65.1 1.85.2 3.7.55z"
                    id="LegPan012__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.8.15Q.4.05 0 0v18.3q.4-.1.8-.15 1.9-.25 3.8-.45 2.5-.3 5-.5.6-.05 1.15-.1V1.85q-.6-.1-1.15-.2l-5-.8L.8.15M21.5 16.7q4.8 0 9.6.3.6.05 1.15.1V4.4q-.6-.05-1.15-.1-4.8-.45-9.6-1v13.4M53.75 5.5q-.6-.05-1.15-.05-4.8-.1-9.6-.35v13.15q4.8.7 9.6 1.75.6.1 1.15.25V5.5m21.5 12.75V5.1q-.6 0-1.15.05-4.8.2-9.6.35v14.8q4.8-1.15 9.6-1.9.55-.1 1.15-.15M95.6 3.35q-4.8.55-9.6 1v12.7q4.8-.35 9.6-.35h1.15V3.25q-.6.05-1.15.1M117.1.2q-.4.05-.85.15-4.4.75-8.75 1.45v15.3q4.35.35 8.75.9.4.05.85.1.05 0 .1.05.2 0 .4.05.3.05.65.1V0q-.3.05-.65.1-.2 0-.4.05-.05 0-.1.05z"
                    id="LegPan012__Symbol_73_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan012__Symbol_72_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan012__col1n"
            />
            <use
                xlinkHref="#LegPan012__Symbol_73_0_Layer0_0_FILL"
                transform="translate(133.4 311)"
                id="LegPan012__col2n"
            />
        </g></g>
    )
}

export default LegPan012
