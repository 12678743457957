import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#FFFFFF",
    "col2d": "#BFBFBF",
    "col2n": "#D9D9D9"
}

function ObjToy068({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 68.45l1.7.2q30.9 3.15 60.05-4.35l5.3-43.4-57-20.9Q2.75 33.35 0 68.45m202.5 43.7q.2.15.5.15.25 0 .45-.2.15-.2.15-.45 0-.3-.2-.45-.75-.75-2.2-1.35-4.8-2.05-6.95-3.05-4.2-1.95-7.6-2.45-2.05-.35-6.65-.35l-11.2.25q-6.5 0-11.95-2.15-3.25-1.3-8.75-4.8-5.6-3.55-9-4.9-5.65-2.25-12.5-2.25l-8.65.5q-4.25 0-7-1.25-2.85-1.3-8.25-5.6-2.85-2.35-9.5-3.9-5.5-1.35-11.6-1.75-3.15-.2-9.8-3.05-5.45-2.3-8.8-3.2l-4.8-.7v.6q4.55 1 4.5 1.35l-4.5-.85v.1q8.85 2.1 13.1 3.9 6.95 2.95 10.2 3.15 6 .4 11.4 1.7 6.25 1.5 9 3.65 5.6 4.5 8.5 5.8 2.85 1.3 7.25 1.35.1.1.3.1.2 0 .35-.1l8.35-.5q6.5 0 11.95 2.15 3.3 1.35 8.8 4.8 5.6 3.6 8.95 4.9 5.65 2.25 12.45 2.25l11.2-.25q4.5 0 6.45.35 3.2.5 7.25 2.35 2.2 1 7 3.05 1.15.5 1.8 1.1z"
                    id="ObjToy068__Symbol_184_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M70.45 71.4h-.3l.3.4v-.4M8.95 1.75L2.7 47.4v.4L0 67.2l9.75 1.25L19.8 0 8.95 1.75m6.2 4.05q.5-.15.9 1.1l-7.7 55.25q-.8.65-1.15.45-.55-.15-.85-.65L13.9 7.5q.7-1.6 1.25-1.7z"
                    id="ObjToy068__Symbol_185_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M66.05 9q.1-.6.2-1.3.15-.35.2-.9v-.6-.4l.7-5.8-62.1 4.5q-8.5 9.2-2.75 18.35l63-7.75.75-6.1m-2-1.35l-.3-.4h.3v.4z"
                    id="ObjToy068__Symbol_187_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 17.4L12.75 20 15.5 1.65 2.75 0 0 17.4z"
                    id="ObjToy068__Symbol_188_0_Layer0_0_FILL"
                />
                <path
                    fill="#35C1FD"
                    d="M308.6 248.45q-.4-1.25-.9-1.1-.55.1-1.25 1.7l-7.55 54.45q.3.5.85.65.35.2 1.15-.45l7.7-55.25z"
                    id="ObjToy068__Layer2_0_FILL"
                />
                <g
                    id="ObjToy068__Symbol_186_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M69.4 64.55l.3.4v-.4h-.3zM2.8 55.35l.4-3.1-2.8-.15-.4 3 2.8.25m6.25-41.1l-3.5.05-.05.5h2.9L4.55 40.55h-2.6v.4L5.1 41l3.95-26.75m.4-5.1l.4-2.9-3.25.2-.4 2.9 3.25-.1v-.1m.7-6.15l.4-3-3.1.35-.4 2.9 3.1-.25z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy068__Symbol_184_0_Layer0_0_FILL"
                transform="translate(302.3 241.55)"
                id="ObjToy068__col1n"
            />
            <use
                xlinkHref="#ObjToy068__Symbol_185_0_Layer0_0_FILL"
                transform="translate(292.55 241.55)"
                id="ObjToy068__col1d"
            />
            <use
                xlinkHref="#ObjToy068__Symbol_186_0_Layer0_0_FILL"
                transform="translate(293.3 248.4)"
                id="ObjToy068__col1s"
            />
            <use
                xlinkHref="#ObjToy068__Symbol_187_0_Layer0_0_FILL"
                transform="translate(298.95 305.7)"
                id="ObjToy068__col2n"
            />
            <use
                xlinkHref="#ObjToy068__Symbol_188_0_Layer0_0_FILL"
                transform="translate(288.5 308.55)"
                id="ObjToy068__col2d"
            />
            <use xlinkHref="#ObjToy068__Layer2_0_FILL" />
        </g></g>
    )
}

export default ObjToy068
