import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.75 13.4Q46.4 7.5 48.7 0L41 2.1q-.45.15-.9.25-.1.05-.2.05-.15.05-.3.05-3.15.75-6.6 1.15-3.55.4-4.8-.1-1.2-.6-1.95-2.2-.05-.1-.15-.2-2.1-1.6-4.3 0-.1.1-.15.2-.7 1.6-1.95 2.2-1.25.5-4.75.1-3.6-.4-6.85-1.2-.3-.1-.6-.15-.3-.1-.5-.15-.65-.15-1.1-.4L.25 0H0q.15 4.75 2.4 8.05 2.2 3.3 4 5.55h.05v-.05q.95-1.4 1.3-2.15.05.15.15.35.05.05.05.15.3.75.65 1.55l2.15 4.6-.95-2.35.3.7q.45 1.3 4.8 11.15v-.15q.05-4.8 1.25-7.05v.05q.05-.2.15-.35l.05-.05q.1-.15.15-.25.6 2.85 2.75 9.45l3.85 11.5 1.35 3.95.45-1.1v.05l8.45-21.45q.55 1.45 1.45 3.1.25-.65.5-1.25.45-1.25.9-2.55l.95-3.05q1.7-4.35 2.35-6.25l1.65 2q.3-.35.6-.75z"
                    id="BeaChi037__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.1 9.25q-.45.5-1.75 1.6-.35.3-.55.65.05.1.1.25.05.05.05.15.3.75.65 1.55l2.15 4.6-.95-2.35.3.7q.45 1.3 4.8 11.15v-.15q-1.1-2.85-1.8-5.25-.7-2.45-1.55-6.45-1.25-5.6-1.45-6.45M.25 0H0q.15 4.75 2.4 8.05 2.2 3.3 4 5.55h.05v-.05Q5.3 10.7 4.9 9.7q-.55-1.6-.55-3.95 0-2.15 1-4.2L.25 0M33.8 23.2q-.1-.3-.15-.6-.7-2.7-2.2-3.95-1 3.6-3.65 12.4-2 7.25-2.85 12.4-.05.05-.05.1v.05l8.45-21.45q.2.5.45 1.05m-13.35-6.95h-.1q-.55.5-2.6 2-1.25 1.1-1.6 2.1v.05q.05-.2.15-.35l.05-.05q.1-.15.15-.25.6 2.85 2.75 9.45l3.85 11.5q.2.55.4 1.15-.15-1.05-.25-2.2-.5-5.4-1.05-8-.85-4.15-1.75-15.4m18-7.1h-.1L37.8 13q-.35 2.15-.85 3.9-1.3 4.35-1.65 7.1.45-1.25.9-2.55l.95-3.05q1.7-4.35 2.35-6.25l.45.55q-.95-1.95-1.5-3.55m3.3 4.25Q46.4 7.5 48.7 0l-4.25 1.15q0 4.35-.6 6.85-.55 2.1-2.1 5.4z"
                    id="BeaChi037__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi037__Symbol_76_0_Layer0_0_FILL"
                transform="translate(168.8 239.1)"
                id="BeaChi037__col1n"
            />
            <use
                xlinkHref="#BeaChi037__Symbol_35_0_Layer0_0_FILL"
                transform="translate(168.8 239.1)"
                id="BeaChi037__col1d"
            />
        </g></g>
    )
}

export default BeaChi037
