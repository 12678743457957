import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}
function SkiWou016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.25 6.7q3.35-2.6 6-6.7-3.6 3-7.2 5.05L17.55 3q-.15-.2-.4-.2-.2-.05-.45.1-.2.15-.25.4.05.2.2.4L18 5.5q-3.5 1.8-7.05 3l-.75-2.05q-.05-.15-.25-.25-.25-.1-.5 0-.2 0-.3.25-.05.15.05.4l.7 1.95Q5.1 10.1 0 10q5.8 1.5 10.65.8l.9 2.3q.1.2.25.35h.45q.2-.1.3-.3.1-.25 0-.4l-.8-2.15q4.2-.75 7.7-3.2L20.7 9q.1.15.4.2.2 0 .35-.1.2-.15.25-.45 0-.15-.1-.3L20.25 6.7z"
                    id="SkiWou016__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou016__Symbol_27_0_Layer0_0_FILL"
                transform="translate(219 190.1)"
                id="SkiWou016__col1n"
            />
        </g></g>
    )
}
export default SkiWou016
