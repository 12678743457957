import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M108.15 17.4q-28.35-20.05-51.4-.7 22.95-14.9 51.4.7M0 22.95Q14.05-.55 46.4.95q-34.65-5.7-46.4 22z"
                    id="FacBro009__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro009__Symbol_9_0_Layer0_0_FILL"
                transform="translate(138.35 116.4)"
                id="FacBro009__col1n"
            />
        </g></g>
    )
}

export default FacBro009
