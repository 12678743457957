import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D2E1B",
    "col1n": "#794D32",
    "col2n": "#2E0E00"
}

function AccHat030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M160.2 61.8q-.15-.45-.4-.75h-.05q-.1.05-.25.1 0-.05-.05-.1h-.05V61q-3.4-4.45-21.85-3.15H137.1l-.35-.05q.25-1.05.1-2.1v-2.05-.1q.3-20-5-33.45-8.35-16.6-33.3-18.7-25.75-4.65-52.6 6.25-21.4 9.85-22.35 28.9V57.8Q-3.25 71.7.45 85.7q2.2 11.15 16.45 17.95 3 1.5 6.15 2.6h.2q18.65 5.45 42.8 0 .15-.05.35-.1 38.3-8.95 75.55-27.45 19.4-8.15 18.3-16.8-.05 0-.05-.1z"
                    id="AccHat030__Symbol_248_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M112.65 1.25q-2.2 4.45-12.15 7.95-.45.2-.95.4l-.3.1Q76.4 16.9 49.6 20.05q-36.4 3.3-49.6-6.6v14.9q15.7 10.1 49.6 7.05 4.35-.55 8.05-2.25Q87.6 19.1 113.6 8.25V.05h-.1l-.35-.05v.05q-.2.6-.5 1.2z"
                    id="AccHat030__Symbol_72_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat030__Symbol_159_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M159.45 0l-.15.05h-.05l-.1.05Q147.7 3.75 138 8.05q-.7.3-1.4.55L79.3 33.95q-16 5.65-31.1 7.4Q12.6 43.7 3.6 24.8q-.3-.95-.5-1.9-1.85-9.1 7.8-18.3Q-2.2 14.6.45 24.65 2.65 35.8 16.9 42.6q2.95 1.45 6 2.6 18.65 5.45 42.8 0 .15-.05.35-.1 38.3-8.95 75.55-27.45Q161 9.5 159.9.85q-.05 0-.05-.1-.15-.45-.4-.75z" />
                    <path d="M159.3.05l.15-.05h-.05q-.05 0-.1.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat030__Symbol_248_0_Layer0_0_FILL"
                transform="translate(110.8 15)"
                id="AccHat030__col1n"
            />
            <use
                xlinkHref="#AccHat030__Symbol_159_0_Layer0_0_FILL"
                transform="translate(111.15 76.05)"
                id="AccHat030__col1d"
            />
            <use
                xlinkHref="#AccHat030__Symbol_72_0_Layer0_0_FILL"
                transform="translate(134.75 72.8)"
                id="AccHat030__col2n"
            />
        </g></g>
    )
}

export default AccHat030
