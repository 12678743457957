import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe055({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M3.35 112.8q-1.6 3.25-2.4 6.7Q-.8 128 .7 136.65q1.35 8.45 4.5 16.6 2.7 6.2 6.35 10.6 13.45 14 30.5 7.55l-.25-.4q-1.55-1.25-3.05-2.5l-2.6-2.4-1.1-1.1-.15-.15q-.85-.85-1.65-1.7l-1.65-1.8q-5.2-5.65-9.25-11.75Q9 129.65 7.2 104.95q-1.95 3.85-3.85 7.85M172 174.2q14.4-1.1 22.45-10.95 11.75-16.05 9.9-37.25-.95-8-6.65-17.55-3.15 29.1-22.7 51.4-2.3 2.55-4.75 5-1.6 1.6-3.2 3.1-2.25 2.05-4.55 4.05 4.45 2.2 9.5 2.2m25-96.25q-.25-1.15-.45-2.25-5.5-26.85-26-47.45Q142.45.05 102.8 0 63.15.05 35.1 28.25q-19.25 19.3-25.25 44.1-1.7 7.05-2.6 14.85 2.7-2.45 4.95-4.9 3.7-4.25 6-8.8 35.85-4.85 58.85-19 13.45 10.2 40 11.7l7.1.2q14.85.9 29.75-2.2h.1q.05 0 .05.05 2.85 2.65 6 5 14.8 11.3 36.05 17.3 1 .3 2 .55h.05q-.1-1.75-.25-3.45-.4-2.9-.9-5.7z"
                    id="HaiSpe055__Symbol_187_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.15 59.1Q.6 64.806 2 81.3q2.15 25.05 20.9 36.1-10.45 2.35-18.9-2.85.1.2.3.4 13.45 14 30.5 7.55l-.25-.4q-1.55-1.25-3.05-2.5l-2.6-2.4-1.1-1.1-.15-.15q-.85-.85-1.65-1.7l-1.65-1.8q-5.2-5.65-9.25-11.75Q2.583 81.974.15 59.1M184.55 92q.35-.75 2.1-5.35t2.9-10.5q1.15-6 .9-16.6-3.15 29.1-22.7 51.4-2.3 2.55-4.75 5-1.6 1.6-3.2 3.1-2.25 2.05-4.55 4.05 4.45 2.2 9.5 2.2 13.75-1.05 21.7-10.1-.075.013-.15.05-10.65 7-20.2 2.95 6.7-4.4 11.3-10.85 4.65-6.6 7.15-15.35m6.35-53.8q-13-4.5-22.2-9.85Q154 19.75 148.9 9q-39.5 15.05-72.3-4.6-3.4-2-6.7-4.4-3 2.35-6.05 4.4-8.95 6.1-18.15 9.7-17.35 6.75-35.4 4.7Q6.8 30.2 0 38.3q2.7-2.45 4.95-4.9 3.7-4.25 6-8.8 19.2-2.6 34.75-7.85 13.45-4.6 24.1-11.15 13.45 10.2 40 11.7l7.1.2q14.85.9 29.75-2.2h.1q.05 0 .05.05 2.85 2.65 6 5 7.2 5.5 15.9 9.75 9.2 4.45 20.15 7.55 1 .3 2 .55h.05z"
                    id="HaiSpe055__Symbol_118_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe055__Symbol_187_0_Layer0_0_FILL"
                transform="translate(89.85 56.5)"
                id="HaiSpe055__col1n"
            />
            <use
                xlinkHref="#HaiSpe055__Symbol_118_0_Layer0_0_FILL"
                transform="translate(97.1 105.4)"
                id="HaiSpe055__col1d"
            />
        </g></g>
    )
}

export default HaiSpe055
