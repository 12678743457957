import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#43A6FF",
    "col2n": "#FFFF00"
}

function ShiSho025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.95 13.85q-.65-1.2-1.3-2.35-.7-1.25-1.4-2.45-.35-.6-1.6-1.35-1.25-.75-1.2-1.55.05-.85.05-1.5-1.2-1.8-2.45-3.5l-.5-.7q-.2-.25-.35-.45l-1.85 1.4q-1.35 1.05-2.8 2.1L129 4.65q-2 2.75-7 4.4-4.8 2.7-9.8 4.8-3.65 4.1-12.75 4.35h-.6q-7.6 1.85-15.7 2.45l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-8.1-.6-15.7-2.45-9.35-.8-13.3-4.35-5.05-2.1-9.85-4.8-5.8-1.35-7-4.4L19.75 3.5q-1.45-1.05-2.8-2.1L15.1 0q-.1.05-.15.15-1.65 2.2-3.15 4.5Q12.85 7 11 7.95q-1.85.9-1.95 1.1Q8.3 10.3 7.6 11.6q-.6 1.1-1.2 2.25 1.5 3-2.2 4.4-.2.4-.4.85Q2.9 21 2.1 23q.85 2.55-1.9 4.8-.1.25-.2.55l10.3 3.85 10.5 2.15q.2.95-1.85 1.9-.05.1-.05.3v.1q-.05.15-.1.35v.05q-.5 2.35-.95 4.75 2.35 1.05 1.45 1.55-.95.45-2.25 2.4l-1 5.25q1.7 2.55-.95 4.75l-1.15 4.85q2.4 3.05-1.2 4.8l-1.25 4.75q3.05 3-1.1 4.4l-1.25 4.7 1.2.2 1.1.2q.4.1.85.15 4.1.75 8.25 1.4 55.5 11.75 107.85 0 4.3-.65 8.6-1.4 1.65-.25 3.3-.55.1-.05.25-.05.1-.05.25-.1.1-.05.2-.05l-1.15-4.6q-4-2.2-1.15-4.4l-.5-1.95V68l-.7-2.75q-2.5-2.2-1.2-4.75l-.7-2.7h.05l-.1-.55-.05.05-.3-1.6q-3.4-2.05-.95-4.8l-.9-4.75q-2.2-1.55-.9-4.45l-.9-4.75-1.8-1.9q3.6-1.4 10.25-2.85l10.35-3.85-.2-.5q-3.25-1.95-1.95-4.9-.8-1.95-1.7-3.85l-.4-.9q-4.45-1.65-2.15-4.35z"
                    id="ShiSho025__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.2 9.2Q5.05 11.35 4 13.6l47.25-.05Q44.4 11.9 37.95 9.2H6.2m21.9-4.8q-3.55-2.05-7-4.4h-9.5q-1.3 2-2.55 4.05-.1.15-.2.35H28.1m68.8 70.55q.7-.05 1.45-.1 19.85-1.35 39.8-4.85h.05l.75-.15q.35-.05.7-.05l-1.15-4.4-.6.1-1.4.2q-19.9 3-39.6 4.15-16.25.95-32.4.55-.65-.05-1.25-.05-25.4-.75-50.55-4.65l-1.4-.2-1.1 4.4q.5.1 1.1.2 18.05 3.15 36.2 4.55 7.85.55 15.75.85.6 0 1.25.05 16.2.45 32.4-.6m0 5q-4.6.35-9.2.55-4 .15-7.95.25-7.65.1-15.25-.1-.65-.05-1.25-.05-26.25-.85-52-5.6l-1.1-.2-1.2-.2-.4 1.6h.05l-.05.05h.2q.25.1.5.15H9.4q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H140.55q.25-.05.5-.15h.2l-.45-1.85q-.1 0-.2.05-.15.05-.25.1t-.2.15l-.05-.1q-1.65.3-3.3.55-20.1 3.5-39.9 4.8m22.6-22.35q-11.35 1.05-22.6 1.6-16.25.75-32.4.45-.65-.05-1.25-.05-14.35-.35-28.65-1.5-9.9-.85-19.85-2-.5-.1-1-.15l-1.2 4.8q.6.1 1.3.2 24.55 3.4 49.4 4.1.6 0 1.25.05 16.15.4 32.4-.5 19-1 38.05-3.65.8-.15 1.6-.25l.75-.1-1.2-4.75q-.25 0-.45.05-.85.05-1.75.2-.1 0-.15.05-5 .55-9.85 1.05l-4.4.4m14.15-11.25q-.75.05-1.45.1-6.75.5-12.7.9-.8.05-1.55.1-10.55.65-21.05 1.05-16.2.55-32.4.3-.65-.05-1.25-.05-16.7-.3-33.4-1.35-5.95-.4-12.8-.95-.6-.1-1.2-.1l-.95 4.75q.3 0 .6.05.2 0 .4.05 23.6 2.5 47.35 3 .6 0 1.25.05 16.15.3 32.4-.4 11.25-.5 22.6-1.4l13.4-1.2q.9-.1 1.75-.2h.35l-.95-4.8-.4.1m-1.75-9.25h-.2q-.6 0-1.15.05-5.55.25-11.05.5-3.7.1-7.4.25-7.65.25-15.2.4-16.2.3-32.4.15h-1.25q-16.7-.15-33.4-.8-5.35-.2-10.7-.45-.75-.05-1.5-.05l-.3 1.6-.1.6-.45 2.25q.65 0 1.35.05 22.45 1.55 45.1 1.9.6 0 1.25.05 16.15.2 32.4-.3 11.25-.3 22.6-.95 5.9-.35 11.85-.75.65-.05 1.35-.1h.45l-.9-4.45q-.15 0-.35.05m7.9-9.55H10.1l8.8 3.25-.15.8q-.05.1-.05.3v.1q-.05.15-.1.35.6.05 1.25.05 21.65.45 43.4.6h1.25q3.25 0 6.5.05h9.15l16.75-.1q11.3-.15 22.6-.35 5-.15 10.1-.25l1.5-.05h.25l-.3-1.5 8.75-3.25m1.25-23.15q-.35-.6-.7-1.15-.6-.95-1.15-1.9-.45-.7-.9-1.35h-9.5q-3.45 2.35-7 4.4h19.25m8.7 18.75q.1 0 .2.05-.95-2.5-1.95-4.9l-146.1.05q-1 2.35-1.9 4.8h149.75m-3.85-9.6q-1.05-2.2-2.15-4.35H112q-6.5 2.7-13.35 4.35h47.25z"
                    id="ShiSho025__Symbol_81_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho025__Symbol_33_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho025__col1n"
            />
            <use
                xlinkHref="#ShiSho025__Symbol_81_0_Layer0_0_FILL"
                transform="translate(117.55 233.25)"
                id="ShiSho025__col2n"
            />
        </g></g>
    )
}

export default ShiSho025
