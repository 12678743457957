import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.35 1q.15-.45.2-1H0q.05.55.25 1 .05.45.2.8.05.25.15.45.45 1.1 1.1 1.8.9.4 1.8.7Q10.2 7.3 16.75 7.4h.05q6.6-.1 13.25-2.65.95-.3 1.85-.7.65-.7 1.05-1.8.1-.2.15-.45.2-.35.25-.8z"
                    id="FacMou020__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    d="M210.75 225.65q2.15-1.8 3.45-4.1v-.05h-43.4v.05q1.3 2.3 3.45 4.1l.3.3q4.2 3.15 11.5 4.4 3.2.65 6.45.6 3.25.05 6.45-.6 7.3-1.25 11.5-4.4l.3-.3m-2.6-3.15q-.05.45-.2.85-.05.35-.2.7-.05.15-.15.35-.4.95-1 1.55l-1.7.6q-6.225 2.141-12.35 2.25h-.05q-6.102-.088-12.35-2.25l-1.7-.6q-.622-.622-1.05-1.6l-.1-.3q-.13-.334-.25-.7l-.2-.85h31.3z"
                    id="FacMou020__Layer5_0_FILL"
                />
                <g id="FacMou020__Layer5_1_MEMBER_0_FILL">
                    <path
                        fill="#666"
                        d="M188.05 227.2q.45.75 1.05.75.6 0 1.05-.75.35-.55.45-1.25-.65-.05-1.25-.05l-1.7-.1v.15q.1.7.4 1.25m2.55-2.25v-.1q-.15-.7-.45-1.25-.45-.75-1.05-.75-.6 0-1.05.75-.3.55-.35 1.2.8.05 1.7.1.6.05 1.2.05m3.85 1q.1.7.45 1.25.45.75 1.05.75.6 0 1.05-.75.3-.55.4-1.25v-.15l-1.7.1q-.6 0-1.25.05m2.55-2.35q-.45-.75-1.05-.75-.6 0-1.05.75-.3.55-.45 1.25v.1q.6 0 1.2-.05.9-.05 1.7-.1-.05-.65-.35-1.2m-15.1 1.65q-.35-.05-.65-.1 0 .15.05.35.1.35.3.6.4.6.95.6.55 0 .95-.6.25-.3.4-.65-1-.15-2-.2m-.6-1.1q.3.05.7.1.95.1 1.95.25 0-.15-.05-.3-.1-.45-.4-.85-.4-.55-.95-.55-.55 0-.95.55-.25.35-.3.8m22.45 0q-.05-.45-.3-.8-.4-.55-.95-.55-.55 0-.95.55-.3.4-.4.85-.05.15-.05.3 1-.15 1.95-.25.4-.05.7-.1m0 1.35q.05-.2.05-.35-.3.05-.65.1-1 .05-2 .2.15.35.4.65.4.6.95.6.55 0 .95-.6.2-.25.3-.6z"
                    />
                    <path
                        fill="#999"
                        d="M207.6 224.4q.1-.2.15-.35.15-.35.2-.7l-.8.2-.4.1q-.8.15-1.6.25-.65.1-1.4.25-.3.05-.7.1-.95.1-1.95.25-1.4.15-2.8.25-.45.05-.95.05-.8.05-1.7.1-.6.05-1.2.05-.974 0-1.9.05h-.05v-.05q-.95.024-1.9 0-.6 0-1.2-.05-.9-.05-1.7-.1-.5 0-.95-.05-1.4-.1-2.8-.25-1-.15-1.95-.25-.4-.05-.7-.1-.75-.15-1.4-.25-.798-.12-1.6-.3-.198-.01-.4-.05l-.85-.2q.12.366.25.7l.1.3q.075.108.2.15.05.05.1.05 1.1.2 2.15.4.65.1 1.4.2.3.05.65.1 1 .05 2 .2 1.4.15 2.85.25l.9.1 1.7.1q.6 0 1.25.05.95.024 1.9 0v.05h.05q.926-.05 1.9-.05.65-.05 1.25-.05l1.7-.1.9-.1q1.45-.1 2.85-.25 1-.15 2-.2.35-.05.65-.1.75-.1 1.4-.2 1.05-.2 2.15-.4.05 0 .1-.05.1-.05.15-.1z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou020__Symbol_45_0_Layer0_0_FILL"
                transform="translate(175.75 222)"
                id="FacMou020__col1n"
            />
            <use xlinkHref="#FacMou020__Layer5_0_FILL" />
            <use xlinkHref="#FacMou020__Layer5_1_MEMBER_0_FILL" />
        </g></g>
    )
}

export default FacMou020
