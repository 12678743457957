import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacEye022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.45 7.15L45.65.9q-.05-.45-.4-.7-.3-.25-.75-.2-.4.05-.65.4-.25.3-.2.75l.6 4.65Q22.35-1.9.5 9.95q-.35.2-.45.6-.1.35.05.75.25.35.6.45.4.1.75-.05Q22.95-.05 44.5 7.95l.65 6.15q.05.4.35.7.35.25.75.2.4-.05.65-.35.3-.35.25-.75l-.7-6.7v-.05m54.65 4.6q.35-.1.6-.45.15-.4.05-.75-.1-.4-.45-.6Q79.45-1.9 57.55 5.8l.6-4.65q.05-.45-.2-.75-.25-.35-.65-.4-.45-.05-.75.2-.35.25-.4.7l-.8 6.25v.05l-.7 6.7q-.05.4.25.75.25.3.65.35.4.05.75-.2.3-.3.35-.7l.65-6.15q21.55-8 43.05 3.75.35.15.75.05z"
                    id="FacEye022__Symbol_66_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye022__Symbol_66_0_Layer0_0_FILL"
                transform="translate(141.5 156.3)"
                id="FacEye022__col1n"
            />
        </g></g>
    )
}

export default FacEye022
