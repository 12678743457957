import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00"
}

function ShiMot007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M37.65 13.65L23.5 13.3 18.8 0l-4.7 13.3-14.1.35 11.2 8.6L7.15 35.8l11.65-8 11.65 8-4-13.55 11.2-8.6z"
                    id="ShiMot007__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot007__Symbol_13_0_Layer0_0_FILL"
                transform="translate(173.65 263.55)"
                id="ShiMot007__col1n"
            />
        </g></g>
    )
}

export default ShiMot007
