import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSho066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M187.2 41.9q-.15-.25-.3-.45-1.35-2.1-2.8-4.1-.2-.3-.4-.55 0-.05-.05-.05-5.3-7.15-11.75-12.85Q152.65 6.7 122.95 2 101-1.95 78.65 2q-25.6 4.4-43.5 19-8.65 7.05-15.5 16.5-1.6 2.2-3.1 4.55-5.35 7.9-9.4 17.35-.3.65-.55 1.25Q1.65 72.2.3 84q-.1.4-.1.8-1.65 30.95 12 49.75.25.25.45.55v-.2q-1.85-23.85.8-34.65v-.05h.05q.1-.5.25-.9 2.3-6.5 8.95-8 10.8-3.15 21.55-5.4l.15-1.7v-.1-.1q0-.45.15-.9.1-.5.35-1.65.2-1 1.25-3.75.15-.45.35-.9 1.35-3.55 3.05-6.4 1.4-2.45 1.45-2.2v.15q-.8 5.1-.95 9.25-.15 3.55.15 6.4 0 .35.05.7 1.3-.25 2.65-.5l1.2-.2q8.3-1.45 16.65-2.45v-1.1h.05q-.05-.85-.05-1.65v-.55-1.05q.05-3.1.3-6.1v-.05q.75-11 3.75-22v4.5q0 2.7-.5 12 0 2.3.05 5.5v.05q.05 2.65.15 6v4.05q1.5-.15 3-.25 14.05-1.3 28.05-1.1v-3.65q.2-2.65.35-5.05v-.05q.4-7.35.65-12.7.75 5.1 1.15 8.6.35 3.45.6 5.5.2 1.7.5 3.95l.1.8.3 2.65q20.4.55 40.8 4.15 3.85.7 7.7 1.5 1.6.3 3.25.65l-.55-5.55-.1-1.3q-.2-1.8-.5-3.95-.35-2-.8-4.3-1.35-7.5-3.9-18.05 4.2 8.5 8.35 22.3.45 1.45.9 3 .549 1.801 1 3.4l.3 1q.1.45.2.85l.8 3.9q8 1.85 16 4.25 7.35 2.5 8.05 10.15v.05q2.1 10.95.4 33.05l.1-.1q.6-.9 1.15-1.75 12.85-20 11.15-49.1-1.35-11.9-6.35-23.5-.25-.6-.55-1.25-4.05-9.45-9.4-17.35z"
                    id="HaiSho066__Symbol_187_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M50.05 77.6q-.15 3.55.15 6.4 0 .35.05.7 1.3-.25 2.65-.5-.8-2.9-1.3-6.6-.493-4.14-.6-9.25-.8 5.1-.95 9.25M74.3 65.55q0 2.3.05 5.5v.05q.05 2.65.15 6v4.05q1.5-.15 3-.25l-2.7-31.85v4.5q0 2.7-.5 12m83.4 19.95q1.6.3 3.25.65l-.55-5.55-.1-1.3q-.2-1.8-.5-3.95-.35-2-.8-4.3-1.35-7.5-3.9-18.05 1.7 11.2 2.3 22.4.25 5.05.3 10.1m26-48.7q0-.05-.05-.05-5.3-7.15-11.75-12.85Q152.65 6.7 122.95 2 101-1.95 78.65 2q-25.6 4.4-43.5 19-8.65 7.05-15.5 16.5-1.6 2.2-3.1 4.55-5.35 7.9-9.4 17.35-.3.65-.55 1.25Q1.65 72.2.3 84q-.1.4-.1.8-1.65 30.95 12 49.75.25.25.45.55v-.2q-4.9-9.35-7.2-19.65v-.05q-2.05-9-2.05-18.8 0-35.05 25.65-60.4 1.55-1.55 3.25-3.1 11.25-10.25 24.35-16.45-12.3 15.75-22.3 52.95 8.95-26.8 20-42 2-2.85 4.15-5.25l1.9-2.05q9.7-9.5 23.15-12 1.45-.25 2.9-.4Q78.2 20 76.6 36.25 84.4 11.8 93.4 7.6q5.05-1.7 10.55 7.55l1.7 3.2.1.1q2.85 5.85 5.35 12.95-1.85-9.5-5.4-19.4-1.7-5 2.15-4.2 1.05.3 2.1.7 5.6 2 10.8 6.3l.65.55q9.35 8.15 16.55 19.25-7.15-14.5-20.3-26.9 30.9 4.15 54.1 25.2 3.2 2.9 6.05 6 22.85 24.4 22.85 57.5 0 10.7-2.4 20.45v.05q-2.05 8.4-5.9 16.2 12.85-20 11.15-49.1-1.35-11.9-6.35-23.5-.25-.6-.55-1.25-4.05-9.45-9.4-17.35-.15-.25-.3-.45-1.35-2.1-2.8-4.1-.2-.3-.4-.55z"
                    id="HaiSho066__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M20.95 23.5q.1-.2.1-.4 0-.25-.15-.4-.2-.15-.35-.15-.25 0-.35.2Q7.85 38.7 2.75 57.55l-.05.05Q.9 63.35 0 69.45q-.05.2.1.35.1.15.35.2.2.05.35-.1.15-.1.2-.3.95-6.15 2.75-12l.05-.05Q8.85 39.1 20.95 23.5m27.5 1.9q-.2.05-.3.25-7.5 14.25-9.5 31.9v.05q-.45 3.3-.6 6.75l-.1 1.8q0 .2.15.35.1.15.35.15.2 0 .35-.1.15-.15.15-.35l.1-1.9q.15-3.4.6-6.7v-.05q2-17.3 9.35-31.3.1-.25.05-.45-.05-.25-.25-.35-.2-.1-.35-.05M55.3 4.45q.15-.15.25-.35.05-.2-.05-.4-.1-.25-.3-.3-.2-.1-.35 0-24.3 14.35-30.3 49.55-.05.2.1.4.1.2.3.25.2.05.4-.1.15-.15.2-.35 5.9-34.6 29.75-48.7m18.15-3q.15-.2.1-.4-.05-.25-.2-.4Q73.2.5 73 .55q-.25 0-.35.2Q63 14.85 61.05 35.5q-.05.2.1.4.15.2.35.2.2 0 .4-.1.15-.2.15-.4Q64 15.3 73.45 1.45M77.25 13q-.2-.1-.35 0-.2.05-.3.3-7.2 18.8-6.55 42.05 0 .2.15.35.15.15.4.15.2 0 .35-.15.1-.2.1-.45-.65-22.95 6.45-41.5.1-.2.05-.4-.1-.25-.3-.35m40.55 11.95q-.15.1-.2.35-.05.2.1.4 8.3 13.05 11.25 31.85v.05q.4 2.35.75 4.85.2 2 .4 4.1 0 .2.2.35.15.1.35.1.2 0 .35-.15.1-.2.1-.4-.2-2.05-.4-4.05-.35-2.45-.75-4.8v-.05q-2.95-19.2-11.45-32.5-.1-.2-.3-.25-.25 0-.4.15M91.35 23.2q-.2.05-.25.25-.1.25 0 .45 5.1 14 4.6 33.65v.05q-.1 2.7-.35 5.55v.1q0 .2.15.4.1.15.3.15.2 0 .4-.1.15-.15.15-.35 0-.1.05-.2.2-2.85.3-5.55v-.05q.5-19.9-4.7-34.1-.05-.2-.25-.25-.2-.1-.4 0M89.45 0q-.2.05-.3.25-.15.2-.05.45 0 .2.2.35 22.5 15.65 24.6 56.5v.05q.1 1.95.15 3.95 0 .2.15.35.15.1.4.1t.4-.15q.1-.15.1-.35-.05-2-.15-3.9v-.05q-2.2-41.6-25.15-57.5-.2-.1-.35-.05m25.7 4.15q.05.2.25.3 30.1 15.05 38.9 53.1v.05h.05q1.4 5.45 2.25 11.5.05.2.2.3.15.15.4.1.2-.05.3-.2.15-.15.1-.35-.85-5.95-2.2-11.35h-.05v-.05Q146.45 18.7 115.8 3.4q-.2-.1-.35 0-.2.05-.3.3-.1.2 0 .45z"
                    id="HaiSho066__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho066__Symbol_187_0_Layer0_0_FILL"
                transform="translate(90.65 54.85)"
                id="HaiSho066__col1n"
            />
            <use
                xlinkHref="#HaiSho066__Symbol_100_0_Layer0_0_FILL"
                transform="translate(90.65 54.85)"
                id="HaiSho066__col1d"
            />
            <use
                xlinkHref="#HaiSho066__Symbol_8_0_Layer0_0_FILL"
                transform="translate(111.8 67.95)"
                id="HaiSho066__col1s"
            />
        </g></g>
    )
}

export default HaiSho066
