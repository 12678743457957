import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#000000"
}

function AccHat004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M273.65 81q-4.75-4.7-9.6-8.75-2.8-2.35-5.6-4.45-5.7-5.3-11.65-9.4-3.85-2.6-7.75-4.75-12.456-6.804-26.15-8.85-4.693-.672-9.55-.8-.85-.05-1.65-.05-2.05 0-4 .05h-1.5q-.758.049-1.5.1-4.05.15-7.6.65-16.55 2.2-30.45 6.55-5.998 1.823-16.1 6.1-.221.143-.55.3-.054.03-.1.05-8.3 4.49-15.95 10.65-4.85 3.85-9.5 8.4-2.2 1.85-4.3 4.2-10.771 10.057-12.3 22.95-.8 4.85-1.05 9.05-.35 5.9.4 10.5.09.625.2 1.25-.276 2.938 5.7 1.85h.1q1.015 0 12.85-3.1.987-.269 2.05-.55 2.094-.563 3.95-1.1.69-.155 1.3-.35 1.023-.248 1.95-.5 7.333-2.009 10.4-2.95 34.728-8.859 50.6-4.05 21.058 5.178 41.15 3.25l-.9.15q16.499-1.001 20.15-.95 3.708.05 7.8 1.3 4.137 1.312 8.1 3.45 3.125 1.697 5.75 2.75.096.028.15.05.7.274 1.35.5 19.298 7.222 20.3 0 .732-1.894 1.25-3.75 1.35-5.1 1.2-7.5-.45-6.15-1.35-8.75-6.075-14.659-17.6-23.5z"
                    id="AccHat004__Layer17_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M258.45 67.8q-5.7-5.3-11.65-9.4-7.773-3.09-14.9-2.05-14.542.468-23.4-.4-8.81-.879-10.25-.95-1.431-.06-3.05-.4-1.626-.35-5.55-.75-16.443-3.318-49.75 3.9-8.3 4.49-15.95 10.65l24.2-6.2q15.174-3.25 35.95-3.7 1.76.377 3.5.7 15.037 2.871 30.75 2.9l22.75.1q10.121.872 17.35 5.6m-7.65 30.4q-.11-.016-.25-.05-2.752-1.302-7.4-2.75-.412-.128-.85-.25-14.55-4.1-22.5-4.1-8.45 0-12.4.75H198q-2.35-.388-4.65-.85-3.03-.402-5.15-.75-14.641-4.438-33.45 0-.722.196-1.35.4-3.677.68-8.1 1.7-5.91 1.354-15.6 5.15l3.25-1.35q-1.6.55-6.65 2.6l-.2.05q-.75.408-1.45.7-.378.193-.8.35.301-.092.55-.2-2.022.946-3.4 1.3-11.632 4.804-24.2 12.1-.35 5.9.4 10.5 3.969-2.599 8.05-4.95 47.81-24.448 70.8-19.75 1.772.286 3.65.8 22.354 3.812 41.5 1.2 16.125-1.12 56.35 16.6 7.642 3.288 13.85 3.35 1.35-5.1 1.2-7.5-9.286-1.503-36.75-13.1-3.283-1.427-5.05-1.95z"
                    id="AccHat004__Layer17_2_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.2}
                    d="M99.5 0q-.75 0-1.5.05-4.05.15-7.6.65-16.55 2.2-30.45 6.55-6 1.8-16.1 6.15-.2.1-.55.25-.05.05-.1.05-8.3 4.5-15.95 10.65-4.85 3.85-9.5 8.4-2.2 1.85-4.3 4.2Q2.7 47 1.15 59.9.35 64.75.1 68.95q-.35 5.9.4 10.5.1.65.25 1.25-.35 2.95 5.65 1.85h.1q1 0 12.85-3.1 1-.25 2.05-.55 2.1-.55 4-1.05.6-.2 1.2-.35h.1l-.05-.1Q5.5 70.6 25.1 40.15q1.05-2.3 2.5-4.6 2.7-4.25 6.2-7.55 2.8-2.65 6.1-4.65Q43.4 21.2 51 16.3q7.45-4.3 15.9-6.95 1.65-.5 8.65-3.45Q83.8 2.95 93.5 1.35q1.45-.25 6-1.35m77.45 36.95q-4.75-4.7-9.6-8.75-2.8-2.35-5.6-4.45-5.7-5.3-11.65-9.4-3.85-2.6-7.75-4.75-12.45-6.8-26.1-8.8 3.95 1.8 12.45 5.35 10.15 4.5 16.05 8.7 4.55 3.2 8.95 8.1 8.7 9.65 14.4 18.6 8.8 13.7 8.8 23.1 0 5.9-2 10.6-1.25 2.95-3.05 4.7l1.3.5q19.3 7.2 20.3 0 .75-1.9 1.25-3.75 1.35-5.1 1.2-7.5-.45-6.15-1.35-8.75-6.05-14.65-17.6-23.5z"
                    id="AccHat004__Symbol_142_0_Layer0_0_FILL"
                />
                <g id="AccHat004__Layer17_1_FILL">
                    <path
                        fill="red"
                        d="M269.9 96.85q-2.68-1.443-7.65-3.15-.12-.006-.25-.05-.255-.073-.5-.15-11.014-3.153-11.65-3.3-2.05-.55-11.8-4.45-2.574-1.03-5-1.8-1.745-.567-3.45-1-.258-.078-.55-.15-2.718-.669-5.25-1h-5.4l-.75.15-5.25.05q-1.7.3-6.9.55-4.3.2-6 .2-.569 0-2.45-.05v-.05q-4.736-.229-11-1.45-7-1.55-7.45-1.6H172v.05q-5.024.553-9.4 1.35h-.05q-2.18.425-4.25.9-1.938.44-3.75.95-9 2.9-16.7 4.9-5.082 1.343-14.1 4.65-.965.35-1.95.7-12.5 4.45-23.95 10.85-.8 4.85-1.05 9.05-.125 2.107-.1 4.05 7.14-4.933 17.55-9.3 33.3-13.95 80.35-13.95t80.3 13.95q10.273 4.303 17.35 9.15.436-2.27.35-3.65-.45-6.15-1.35-8.75-10.92-4.411-21.35-7.65M252.3 62.5q-2.719-2.183-5.5-4.1-3.85-2.6-7.75-4.75-18-4.75-37.35-5.55-2-.1-4-.1-1.5-.05-3-.05-18.45-.1-38.05 3.35-5.998 1.823-16.1 6.1-.221.143-.55.3-.054.03-.1.05-1.609.87-3.2 1.8 23.073-3.75 50.3-3.75 36.365 0 65.3 6.7z"
                    />
                    <path
                        fill="#01984B"
                        d="M258.45 67.8q-3.035-2.822-6.15-5.3-28.935-6.7-65.3-6.7-27.227 0-50.3 3.75-6.583 3.884-12.75 8.85-5.204 15.103-2.15 24.7 16.944-23.306 26.35-30.9 3.93 11.382 14.4 18.8h.05q8.847-13.145 21.5-22.5.845 2.568 1.85 5.05 4.138 10.143 11.1 19.1v.05q9.915-11.202 21.3-20.6 5.767 7.657 10.7 20.7.292.072.55.15 8.036-7.722 11.5-20.75 7.787 7.655 20.4 31.3.245.077.5.15.13.044.25.05 2.708-10.706 1.8-21.45-2.8-2.35-5.6-4.45z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccHat004__Layer17_0_FILL" />
            <use xlinkHref="#AccHat004__Layer17_1_FILL" />
            <use xlinkHref="#AccHat004__Layer17_2_FILL" />
            <use
                xlinkHref="#AccHat004__Symbol_142_0_Layer0_0_FILL"
                transform="translate(96.7 44.05)"
                id="AccHat004__col1d"
            />
        </g></g>
    )
}

export default AccHat004
