import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#830000"
}

function AccMor014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M136.15 13.95l.2-.6Q133.05 6.7 127.6 0q-9.55 13.95-23.6 25.95-1.45 1.25-2.95 2.45-8.25 6.7-17.95 12.7l-4.25 2.55q-2.4 1.4-4.85 2.75-.15.05-.25.15L71.8 47.6q-3.35 1.75-6.7 3.35-.9.4-1.75.8-19.7 9-41.8 12.75-.05 0-.15.05-2.4.4-4.8.75l-.8.1q-5.15.55-11.9.85L0 82.2h.6q5.2.3 10.9-.45Q32 79.65 50.3 74q4.2-1.3 8.3-2.8 4.5-1.65 8.9-3.55Q78.1 63.1 87.95 57.1q3.4-2.05 6.7-4.3l.7-.5q.05 0 .15-.05.05-.05.15-.05.2-.15.45-.3 4.7-3.3 5.15-3.55 4.6-3.25 8.8-6.65 1.5-1.2 2.95-2.45 13.75-11.7 23.15-25.3z"
                    id="AccMor014__Symbol_112_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor014__Symbol_112_0_Layer0_0_FILL"
                transform="translate(125.6 227.7)"
                id="AccMor014__col1n"
            />
        </g></g>
    )
}

export default AccMor014
