import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M101.85 15.85q.15-.25.25-.5.15-.25.25-.55 2.55-8.55-.85-14.8-1.15 1.25-1.75 1.8-2.9 2.5-6.3 4.45-.45.25-.95.5-5.45 2.95-12.25 4.65-.85.15-1.7.4-1.7.45-3.35.9-.6.15-1.2.35-.6.1-1.1.2-.9.25-1.85.45-4.9 1.05-9.8 1.65-2.3.3-4.55.45-.6.05-1.15.05-.6.05-1.15.05l-2.75.1-2.65-.1q-.55 0-1.1-.05-.6 0-1.15-.05-2.3-.15-4.55-.45-4.95-.6-9.85-1.65-.9-.2-1.85-.45-.5-.1-1.05-.2-.6-.2-1.2-.35-1.7-.45-3.35-.9-.95-.25-1.75-.4Q16.3 9.7 10.9 6.75q-.5-.25-.9-.5Q6.55 4.3 3.7 1.8 3.05 1.25 2.5.7L2.3.5Q-.5 5 .1 10.5q0 .2.05.55.1.25.15.6l.1.6q.05.3.1.7.3 1.25.85 2.4l.2.5q.4.85.95 1.65.9 1.35 2.1 2.6.95.95 1.9 1.7 3.95 2.85 9.05 2.85 2.65 0 5-.8 1-.35 1.85-.7l-.05-.25q.05.05.1.2.1.1.15.25.1.15.15.4.15.25.25.35l.05.05q.05.1 1.3 2.45l.1.2q-1.15-2.15-1.15-2.05-.05.05.1.15h-.05q1.25 2.95 3.65 5.3 1.05 1.05 2.15 1.85 4.4 3.25 10.2 3.3.15-.05.4 0 2.05-.05 3.95-.5 3.2-.8 5.9-2.8 1.1-.8 2.1-1.85 1.05 1.05 2.15 1.85 1 .75 2.1 1.35 1.2.6 2.55 1 2.6.95 5.55.95 5.75-.05 10.2-3.3 1.1-.8 2.1-1.85 2.3-2.25 3.6-5.05l.05-.05.3-.6q.3-.65.35-.75.05-.25.1-.4.05-.15.25-.25.05-.15.1-.2l-.05.25q.85.35 1.85.7 2.25.8 4.9.8 5.15 0 9.1-2.85 1-.75 1.9-1.7 1.2-1.25 2.1-2.6.55-.8.95-1.65z"
                    id="BeaMus019__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M103.4 0q-.25 2.5-1.05 5.25-.1.3-.25.55-.1.25-.25.5-.4.85-.95 1.65-.9 1.35-2.1 2.6-.9.95-1.9 1.7-3.95 2.85-9.1 2.85-2.65 0-4.9-.8-1-.35-1.85-.7l.05-.25q-.05.05-.1.2-.2.1-.25.25-.05.15-.1.4-.05.1-.35.75l-.3.6-.05.05q-1.3 2.8-3.6 5.05-1 1.05-2.1 1.85-4.45 3.25-10.2 3.3-2.95 0-5.55-.95-1.35-.4-2.55-1-1.1-.6-2.1-1.35-1.1-.8-2.15-1.85-1 1.05-2.1 1.85-2.7 2-5.9 2.8-1.9.45-3.95.5-.25-.05-.4 0-5.8-.05-10.2-3.3-1.1-.8-2.15-1.85-2.4-2.35-3.65-5.3h.05q-.15-.1-.1-.15 0-.1 1.15 2.05l-.1-.2q-1.25-2.35-1.3-2.45l-.05-.05q-.1-.1-.25-.35-.05-.25-.15-.4-.05-.15-.15-.25-.05-.15-.1-.2l.05.25q-.85.35-1.85.7-2.35.8-5 .8-5.1 0-9.05-2.85-.95-.75-1.9-1.7-1.2-1.25-2.1-2.6-.55-.8-.95-1.65l-.2-.5Q.8 4.65.5 3.4.45 3 .4 2.7l-.1-.6q-.05-.35-.15-.6Q.1 1.15.1.95v-.3q-.2 1.7 0 3.5 0 .2.05.55.1.25.15.6l.1.6q.05.3.1.7.3 1.25.85 2.4l.2.5q.4.85.95 1.65.9 1.35 2.1 2.6.95.95 1.9 1.7 3.95 2.85 9.05 2.85 2.65 0 5-.8 1-.35 1.85-.7l-.05-.25q.05.05.1.2.1.1.15.25.1.15.15.4.15.25.25.35l.05.05q.05.1 1.3 2.45l.1.2Q23.3 18.3 23.3 18.4q-.05.05.1.15h-.05q1.25 2.95 3.65 5.3 1.05 1.05 2.15 1.85 4.4 3.25 10.2 3.3.15-.05.4 0 2.05-.05 3.95-.5 3.2-.8 5.9-2.8 1.1-.8 2.1-1.85 1.05 1.05 2.15 1.85 1 .75 2.1 1.35 1.2.6 2.55 1 2.6.95 5.55.95 5.75-.05 10.2-3.3 1.1-.8 2.1-1.85 2.3-2.25 3.6-5.05l.05-.05.3-.6q.3-.65.35-.75.05-.25.1-.4.05-.15.25-.25.05-.15.1-.2l-.05.25q.85.35 1.85.7 2.25.8 4.9.8 5.15 0 9.1-2.85 1-.75 1.9-1.7 1.2-1.25 2.1-2.6.55-.8.95-1.65.15-.25.25-.5.15-.25.25-.55Q103.7 3.9 103.4 0z"
                    id="BeaMus019__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus019__Symbol_36_0_Layer0_0_FILL"
                transform="translate(140.8 192.15)"
                id="BeaMus019__col1n"
            />
            <use
                xlinkHref="#BeaMus019__Symbol_6_0_Layer0_0_FILL"
                transform="translate(140.8 198.5)"
                id="BeaMus019__col1d"
            />
        </g></g>
    )
}

export default BeaMus019
