import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C40000"
}

function LegSho002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M128.55 328.4l-5.1 1.55-2.2.6q-2.298 1.217-2.85 3.35v1.75l74.1-.9 74.1.9v-1.55q-.552-2.333-2.85-3.55l-2.2-.6-.2-.05-6.5-1.7-20.85-1.6v-.45h.7q-.35-.016-.7-.05-1.297-.03-2.6-.05-.317-.014-2 .4-1.619.45-3 .1-1.346-.29-2.2-.45-1.394.065-2.8.15-3.2.523-4.9.75-1.658.267-1.75 0-.095-.211-.45-.2-1.273.187-2.55.35-.587.108-1.45.55l-17.8 4.35-18.45-4.8q-1.78-.238-3.55-.45v.6l-5-.6-1.95-.6q-1.355-.047-2.7-.1v.4h-5.3l-2.05-.5q-1.204.056-2.4.1v.3l-22.55 2m28.3 5.55q.02.026.05.05h-.1q.02-.024.05-.05z"
                    id="LegSho002__Layer7_0_FILL"
                />
                <path
                    fill="#EEE"
                    d="M118.4 334.05v2.9h64.65q6.9 0 9.35-1.75l.1-.1.1.1q2.45 1.75 9.35 1.75h64.65v-2.9l-63.55-.1q-.9 0-1.8-.05-6.15-.25-8.65-1.25-.05 0-.1-.05-.05.05-.1.05-2.5 1-8.65 1.25-.9.05-1.8.05l-63.55.1z"
                    id="LegSho002__Layer7_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M135.9 3.05l-.7-.2Q124.5.05 110.65.1h-.2q-5.05 1.7-7.8 4.1-.15.15-.35.3-.1-.25-.3-.5-.35-.3-.75-.45 2.4-2.1 6.8-3.55-3.6-.05-7.3.05-3.05 1.35-5.6 3.5-.1-.3-.35-.55-.3-.25-.65-.4 1.8-1.6 3.9-2.4-3 .15-6 .45-.25 0-.5.05-.35.05-.65.1-1.8 1.05-2.5 1.95-.1-.25-.3-.45-.4-.35-.85-.45.45-.4 1.05-.7-5.4.75-10.95 2l-8.3 2.05-8.3-2.05Q55.2 1.85 49.8 1.1q.6.35 1.05.75-.45.1-.85.45-.2.2-.3.45-.7-.9-2.5-1.95-.3-.05-.65-.1-.25-.05-.5-.05-3-.3-6-.45 2.1.8 3.9 2.4-.35.15-.65.4-.25.25-.35.55Q40.4 1.4 37.4.05q-3.75-.1-7.35-.05 4.4 1.45 6.8 3.55-.4.15-.75.45-.2.25-.3.5-.2-.15-.35-.3-2.75-2.4-7.8-4.1h-.9Q13.45.15 3.1 2.8q-.5.1-.9.25-1.2.4-2.2.85 20.75-5.25 35.25 3.95v.05H60.3q6.15-.25 8.65-1.25.05 0 .1-.05.05.05.1.05 2.5 1 8.65 1.25h25.05v-.05q14.5-9.2 35.25-3.95-1-.45-2.2-.85M101.7 5.1q.05.1.05.25 0 .45-.3.75-.35.4-.85.4-.45 0-.75-.4-.4-.3-.4-.75 0-.5.4-.85.3-.3.75-.3.5 0 .85.3.2.25.25.6m-9.05-1.6q.3-.3.75-.3h.1q.45.05.75.3.25.25.25.65.05.1.05.2 0 .45-.3.75-.35.4-.85.4-.45 0-.75-.4-.4-.3-.4-.75 0-.5.4-.85m-5.95-1q.5 0 .85.3.3.35.3.85v.1q-.05.4-.3.65-.35.4-.85.4-.45 0-.75-.4-.4-.3-.4-.75 0-.5.4-.85.25-.25.6-.3h.15M52.55 3.65q0 .45-.4.75-.3.4-.75.4-.5 0-.85-.4-.25-.25-.3-.65v-.1q0-.5.3-.85.35-.3.85-.3h.15q.35.05.6.3.4.35.4.85m-9 .7q0-.1.05-.2 0-.4.25-.65.3-.25.75-.3h.1q.45 0 .75.3.4.35.4.85 0 .45-.4.75-.3.4-.75.4-.5 0-.85-.4-.3-.3-.3-.75m-6.9.15q.35-.3.85-.3.45 0 .75.3.4.35.4.85 0 .45-.4.75-.3.4-.75.4-.5 0-.85-.4-.3-.3-.3-.75 0-.15.05-.25.05-.35.25-.6z"
                    id="LegSho002__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#LegSho002__Layer7_0_FILL" />
            <use xlinkHref="#LegSho002__Layer7_1_FILL" />
            <use
                xlinkHref="#LegSho002__Symbol_3_0_Layer0_0_FILL"
                transform="translate(123.45 326.05)"
                id="LegSho002__col1n"
            />
        </g></g>
    )
}

export default LegSho002
