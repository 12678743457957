import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00B600",
    "col1l": "#17FF17",
    "col1n": "#00DE00"
}

function ObjMor065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#DEDEDE"
                    fillOpacity={0.702}
                    d="M127.7 246.5q.95 0 1.6-.55.3-.25.45-.45.25-.45.25-1 0-.85-.7-1.4-.4-.35-.95-.55H113.7q-.4-.05-.8-.05-1 0-1.65.6-.8.55-.8 1.4 0 .85.8 1.45.65.55 1.6.55v20.25l-6.25 10.85v.1l13.9 2.75 12.85-3.4q.043.065.1.1l-5.75-11V246.5z"
                    id="ObjMor065__Layer14_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M44 17.7q-.05-.05-.05-.15l-8.35-16Q34.45.85 34.25.8q-.4-.2-.9-.2-.6-.05-1 .1-1.05.3-1.15 1.6-.1-.15-.2-.25-.45-.35-1.3-.35-.8 0-1.3.5Q28.05.9 26.35.95q-2.1.05-2.15 1.8h-.35q-.05-.45-.25-.9Q23.05.7 21.6.7q-1.65 0-2.05 1.1-.15-.25-.5-.65-.7-.7-1.4-.7-.5 0-.85.15-.2.1-.4.25-.15.15-.25.3-.049 0-.05.05v-.05q-.25-.75-.85-1-.2-.1-.4-.15h-.5q-.3 0-.55.1-.5.15-.8.4-.25.25-.4.65l-.1.3q-.55-.45-1.5-.3h-.5q-.45.1-.75.25-.4.15-.65.4-.05 0-.05.05l-.3.25L1.4 14.85q-.1.4-.25.8-.35 1.1-.6 2.05-.1.25-.15.45Q.05 19.7 0 21q.3 4.6 8.6 5.35 12.55 1.35 26.55 0 9.4-1.05 9.45-5.6l-.3-1.8q-.15-.6-.3-1.25z"
                    id="ObjMor065__Symbol_158_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M16.4 20.65q0 .9.65 1.55t1.55.65q.9 0 1.55-.65t.65-1.55q0-.9-.65-1.55t-1.55-.65q-.9 0-1.55.65t-.65 1.55m-2-5.5q-.5 0-.5.5t.5.5q.5 0 .5-.5t-.5-.5m-3.3-.8q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m-5.2 7.8q0-.5-.5-.5t-.5.5q0 .5.5.5t.5-.5M.65 14.5Q0 15.15 0 16.05t.65 1.55q.65.65 1.55.65t1.55-.65q.65-.65.65-1.55t-.65-1.55q-.65-.65-1.55-.65t-1.55.65M2.1 8.3q-.5.1-.5.45 0 .5.5.5t.5-.5q0-.6-.5-.45m4.2-3.7q-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95m13.6 5.85q0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6m-2.5-3.9q-.5 0-.5.5t.5.5q.5 0 .5-.5t-.5-.5M12.6 10q.65-.65.65-1.55T12.6 6.9q-.65-.65-1.55-.65T9.5 6.9q-.65.65-.65 1.55T9.5 10q.65.65 1.55.65T12.6 10M11.1.95q.05-.85-.65-.85-.55 0-.9.5-.3.35-.3.65 0 .75.9.75.95 0 .95-1.05M14 2.5q.2-1.3-1.15-1.3-1 0-1.55.6-.45.45-.45.95 0 .35.3.95.4.8 1.1.8.95 0 1.45-.8.3-.55.3-1.2m-1 .05q-.006-.219-.05-.35H13v.35m0 .35h-.05q.047-.081.05-.2v.2m6.45.5q.5-.5.5-1.05-.1-1.6-1.75-1.6-1.75 0-1.75 1.35 0 .5.35 1.1.45.8 1.2.8.85 0 1.45-.6M32.4 22.25q-.5 0-.5.5t.5.5q.5 0 .5-.5t-.5-.5m3.7-4.9q0 .5.55.5t.55-.5q0-.5-.55-.5t-.55.5m-8.45-12.5q-.75.55-1.25.6-.5.05-1.35-.05-.85-.15-1.65-.85-.9-.55-1.7-.65-.1-.05-.2-.05-.6-.05-.8.15 0 .25.05.5.05.95.15 1.1l1.9 13.55q.45 2.6 2.85 2.8.45-.05.9-.05 1.3-.05 2.55-.2 2.2-.3 4-1 1.85-1 1.45-2.95L28.6 3.9h-.05q-.15.4-.9.95M26.75 2q-.65 0-.65.65 0 .25.05.35.1.3.55.3.25 0 .45-.2t.2-.45q0-.65-.6-.65m3.35-.85Q30.25 0 29.2 0q-.55 0-.8.4-.2.25-.2.55 0 .25.15.7.2.6.55.6 1.2 0 1.2-1.1m4 6.35q-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95m-2.5-.75q.5 0 .5-.5 0-.6-.5-.45-.5.1-.5.45 0 .5.5.5m1.7-2.6q0-.5-.5-.5t-.5.5q0 .5.5.5t.5-.5z"
                    id="ObjMor065__Symbol_109_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M16.3 5.35l-.4-2.6h-.2q-.05-.45-.25-.9Q14.9.7 13.45.7 11.8.7 11.4 1.8q-.15-.25-.5-.65-.7-.7-1.4-.7-.5 0-.85.15-.2.1-.4.25-.15.15-.25.3-.049 0-.05.05v-.05Q7.7.4 7.1.15 6.9.05 6.7 0h-.5q-.3 0-.55.1-.5.15-.8.4-.25.25-.4.65l-.1.3q-.55-.45-1.5-.3h-.5q-.45.1-.75.25-.4.15-.65.4-.05 0-.05.05l-.3.25-.6.95q0 .25.15.45.05.85.9.85h.2l.05.05q.1-.1 1-.2l1.3-.15q1.2 0 2.15.85.5.4.7.85.05.15.2.3.4.45 1.2.45.55 0 1.55-.75.1-.05.25-.1 1-.4 1.65-.25.6.1 1.35.4.7.3 1.25.25.5-.05.6-.1.45-.2 1.45-.6h.35M14.7 4.2q-.3.4-.8.4-1 0-1-1 0-.85 1-.95 1-.15 1 .95 0 .3-.2.6M6.2 2.45q0 .45-.5.45t-.5-.45q0-.4.5-.45.5-.2.5.45M8.4 4.6q-.3.4-.8.4-1 0-1-1 0-.85 1-.95 1-.15 1 .95 0 .3-.2.6m19.7-1.7h.05l-.7-1.35Q26.3.85 26.1.8q-.4-.2-.9-.2-.6-.05-1 .1-1.05.3-1.15 1.6.05.15.3 1l.05.05.05.25q0-.05.05 0l.45 1q.1.15.2.5v.05l.05.1q.7-.7.95-1 .2-.35.7-.5.5-.15 1.3.05t.95-.2q.15-.45 0-.7m-3.4-.1q-.5 0-.5-.5 0-.3.5-.45.5-.15.5.45 0 .5-.5.5z"
                    id="ObjMor065__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M126.2 296.4q0 .25.05.35.1.3.55.3.25 0 .45-.2t.2-.45q0-.65-.6-.65-.65 0-.65.65m3.55-6.5q-.7 0-1.2.45-.45.5-.45 1.2t.45 1.15q.5.5 1.2.5t1.15-.5q.5-.45.5-1.15t-.5-1.2q-.45-.45-1.15-.45m-2.45-4.5q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4z"
                    id="ObjMor065__Layer5_0_FILL"
                />
                <g id="ObjMor065__Layer14_1_FILL">
                    <path
                        fill="#FFF"
                        fillOpacity={0.549}
                        d="M120.35 248.2v18.95l1.55 11.2h.15q1.913-.088 3.25-.2-2.502 1-1.6.9 1.109-.118 5.15-1.4l-4.55-10.5.35-18.95h-4.3z"
                    />
                    <path
                        fill="#9A9A9A"
                        fillOpacity={0.651}
                        d="M123.8 246.95v.05l-10.95-.05v1.25H127.7v-1.25h-3.9z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor065__Layer14_0_FILL" />
            <use xlinkHref="#ObjMor065__Layer14_1_FILL" />
            <use
                xlinkHref="#ObjMor065__Symbol_158_0_Layer0_0_FILL"
                transform="translate(97.85 275.6)"
                id="ObjMor065__col1n"
            />
            <use
                xlinkHref="#ObjMor065__Symbol_109_0_Layer0_0_FILL"
                transform="translate(101.6 276.95)"
                id="ObjMor065__col1l"
            />
            <use
                xlinkHref="#ObjMor065__Symbol_90_0_Layer0_0_FILL"
                transform="translate(106 275.6)"
                id="ObjMor065__col1d"
            />
            <use xlinkHref="#ObjMor065__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjMor065
