import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M100.7 36.35Q95 17 82 8.65 60.35-7.1 39.7 5.8q-1.4.85-2.75 1.8Q25.7 17.25 19.6 32 14.95 42.25 4.45 52.75q-.65.7-1.1 1.1Q1.6 55.3 0 56.35q17.7 2.25 24.95-6.85.35-.45.75-.9 2.4-2.6 4.2-4.85.35-.4.65-.8v.35q0 3.85-2.75 8.7-.6 1-1.1 1.85 1.45-.55 2.8-.9l.5-.1q23.1-8.7 28.9-28.9 1.55 3.1 3.2 6 .15.3.35.6l4.65 6.95.05.05q.45.65 1.05 1.45 1.75 2.25 3.2 3.7.5.5 1.5 1.15.25.2.5.45-1.45-1 7.4 6.6.15.1.35.25-.8-1.8-1.4-3.95-.35-1.25-.7-3.8-.4-2.55-.45-3.35-.05-.8 0-2.2 0-1.45.1-1.6 1.2 4.2 2.85 7.35.65 1.2 1.4 2.5 11.1 16.5 26.6 15h.6q-.45-.5-.85-1.2-1.4-2.95-1.75-3.65-1.6-3.05-2.75-6.25-.65-1.75-1.85-5.7-1.15-4.25-2.25-7.95z"
                    id="BeaMus039__Symbol_56_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M148.3 248.65q-.05.1-.1.15h.15q0-.05-.05-.15m9.55-26.9q-2.85 3.5-5.35 9.3-2.55 5.75-6.2 10.4-6.85 8.25-14.4 13.25 5.1 1.3 9.95 1.3 11.4-.45 16.05-5.8.95-1.1 2.15-2.55l.4-.55q.9-1.1 1.6-1.9-.7 1.7-1.05 2.7-.5 1.35-2.6 4.65-.45.65-.85 1.25 11.1-1.9 19.05-8.5 11.6-8.8 17.4-21.55 3.6 8.5 9.6 16 .6.7 1.2 1.45l.35.35q2.7 2.95 5.85 5.75 1.15 1 2.35 1.95l5.15 3.75q-.2-.5-.4-1.2-1.5-3.65-1.75-4.95-.25-1.35-1.8-7.15-.2-.8-.3-1.25.55 1.35 1.4 3.4 1.65 3.9 2.6 5.8 4.95 6.35 11.25 9.45 11.15 4.25 19.65 2.55-.6-.55-1.3-1.55-.6-.7-1.3-1.6-.95-1.25-1.65-2.4-.15-.2-.25-.4l-.3-.7q-3-4.55-4.5-9.4-1.5-4.85-2.65-9.9-1.15-5.05-3.9-8.9-2.8-3.85-3.05-4.1 0 .1.05.25l-.05.05.1.1q.15.55.6 1.45.85 1.7 1.25 2.6.9 2.1 1.85 5.1.55 1.65 1.6 5.05.2.6.6 1.75l1 3.25q1.45 4.8 2.2 6.75 1.15 3.2 2.75 6.25.35.7 1.75 3.65.4.65.8 1.15.05 0 .05.05-2.05.35-5.05-.05l-2.95-.65h-.15Q220.9 254 213.3 233l-.25 3.05q-.05.35-.05 1.05 0 .65.3 3.4t1.15 4.9q.85 2.1 1.05 2.55h-.05l-.5-.5q-.2-.25-2.8-2.3-2.6-2.05-7.05-6.95-3.5-4.2-6.15-9.15l-.4-.7q-6.95-12.3-8.3-22.6-.1.1-.15.25-.3.6-.3 1.45 0 2.7.6 5.4 1.1 4.65 2.85 9.05-10.45 21.75-30.95 28.65l-.9.3q.45-.85 1.05-1.8 2.75-4.85 2.75-8.7V240q-.3.4-.65.8-1.8 2.25-4.2 4.85-1.25 1.45-2.6 2.7-1.2 1.1-2.5 2.05-6.5 4.1-19.05 3.2 11.7-8.3 16.6-20.5l5.05-11.35z"
                    id="BeaMus039__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus039__Symbol_56_0_Layer0_0_FILL"
                transform="translate(134.65 197.1)"
                id="BeaMus039__col1n"
            />
            <use
                xlinkHref="#BeaMus039__Symbol_17_0_Layer0_0_FILL"
                id="BeaMus039__col1d"
            />
        </g></g>
    )
}

export default BeaMus039
