import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo012({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFFF80"
                    d="M109 284.55l-.05-.1v.1h.05m.95-14.15q-.1-.1-.2-.1-7.3-2.15-14.1 10.7-2.5 6.15-1.8 13.65 3.75 10.85 10.2 13.85l.85-.45q-1.45-5.9-1.2-10.7-.3-7.9 5.25-12.9.1-.1.25-.2.05-.05.1-.05.3-.3.65-.55l.5 2.9q3.732-.787 8.2.65 2.227 1.015 3.95 3 .025.032.05.05.137.195.25.35 2.897 4.443 2.4 9.9-.086.802-.25 1.55l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-3.296 1.264-6.05.55l1.65 9.25q.277.312.45.6.123.127.2.25.053.075.1.15h2.6q.125-.198.3-.4l.15-.15q.45-.4.8-.6.1-.05.15-.05.4-2.361 1.15-4.1.865-2.02 2.2-3.2 11.45-8.35 12.8-20.55.7-1.3 1.3-2.6 3.65-7.75 3.55-15.3V271.8l-.05.05q.05-.75 0-2.55-.05-3.15-2.8-5.55l.6.65q-7-6.7-12.45-5.75-3.163 8.875-17.2 11.75m8.8 9.4l.15-.05-.15.1-.1-.05h.1m-9 2.2q.05-.1.15-.15l.1-.05q-.132.082-.25.2m7.9 36.3l-.05-.05.05-.05v.1z"
                    id="ObjFoo012__Layer7_0_FILL"
                />
                <g id="ObjFoo012__Layer7_1_FILL">
                    <path
                        fill="#69351F"
                        d="M117.65 318.3l-.05-.05-1.5.45q.4.45.65.85.6.85.75 1.65.15.5.15 1.35v1.5h.5q.45-.1.95-.15l1.2-.25q-.05-.3-.7-1.45-.4-.8-.45-1.6 0-.7.75-1.45.45-.4.8-.6l-3.05-.25z"
                    />
                    <path
                        fill="#FCE725"
                        d="M113.35 309.1h.05q-1.491-.735-2.85-1.85.074.285.15.55.1.5.25.95.05.25.1.45 1.25 4.9 3.5 7.8.7.95 1.55 1.7l1.5-.45.05-.05.05-.05q.15-.6.3-1.15.6-2.7 1.15-5.4l.5-2.4q-3.056.953-5.6.15h-.15q-.262-.116-.55-.25m28.5-37.25h-.05q.05-.75 0-2.55-.05-3.15-2.8-5.55l.6.65q-7.25-3.35-10.5 8v.2l1.3-.25h.3q1.45-4.4 2.55-5.45 1.05-1.1 1.6-1.5.15-.15.3-.2.55-.45 1.2-.45.6 0 .75-.05 6.4 1 1.2 23.35 3.65-7.75 3.55-15.3v-.9m-20.55 4q-.1 0-.25.05-1 .45-2.15 3.85l-.15.1-.1-.05q-1.45.3-3.05.85-1.3.4-2.45 1-1.75.85-3.2 2-.149 1.554-.25 3.1 4.023-1.134 8.95.45 1.661.757 3.05 2.05v-.1q.047.047.05.15v-.85-.2q.15-3.9.05-7.9-.05-.95-.05-1.85 0-.2.05-.2h.05q.25.2.85 2.45.05.2.1.5 1.7 5.75 1.95 12.1l.05.55q.014.443 0 .9.775 2.695.5 5.7-.086.802-.25 1.55l-.1.55q-.589 2.224-1.55 3.7-.297 1.434-.7 2.9-.4 1.4-.85 2.8H123v-.05q.5-1.4.95-2.75 3.2-10.4 1.95-19-.85-5.5-1.65-8.85-.65-2.6-1.3-3.95-.15-.1-.45-.65l.15.4-.05-.1.05.2v.05l-.2-.4-.05-.1.05.05q0-.1-.05-.1 0-.05-.05-.1t-.05-.1q0-.05-.05-.05-.05-.1-.1-.1-.4-.6-.85-.55z"
                    />
                    <path
                        fill="#F8F898"
                        d="M123.1 312l-.1-.05v.1q.05 0 .05-.05h.05z"
                    />
                    <path
                        fill="#DAC403"
                        d="M123.4 306.3q-.462.812-1.05 1.4-.75.7-1.6 1.1l-.2.1h-.05q-.43.165-.85.3l-.5 2.4q-.55 2.7-1.15 5.4-.15.55-.3 1.15l-.05.05v.1l3.05.25q.1-.05.15-.05l3.35-7.3q-.1.05-.2.15-.45.3-.9.65h-.05q0 .05-.05.05V312h-1.15q.45-1.4.85-2.8.403-1.466.7-2.9m-1.7-17.05l.05.05q-.003-.103-.05-.15v.1m.15-10.95h-.05q-.05 0-.05.2 0 .9.05 1.85.1 4-.05 7.9v1.05q.437.423.85.9.025.032.05.05.137.195.25.35 1.31 2.001 1.9 4.2.014-.457 0-.9l-.05-.55q-.25-6.35-1.95-12.1-.05-.3-.1-.5-.6-2.25-.85-2.45m13-12.9q-.55.4-1.6 1.5-1.1 1.05-2.55 5.45h-.3l1.15.05q2.55.5 3.7 3.25.4 1.2.65 2.4 1.75 6.7 1.1 12.6.7-1.3 1.3-2.6 5.2-22.35-1.2-23.35-.15.05-.75.05-.65 0-1.2.45-.15.05-.3.2m-21.7 14.8q-.25.1-.55.2-1.1.4-2.05 1.05-.2.05-.3.2-.1 0-.15.1h-.05q-.007.02-.05.05-.132.082-.25.2-3.65 2.5-5.25 6.15-2.3 5.05-1.95 10.05 0 5 1.5 10.3l.85-.45q-1.45-5.9-1.2-10.7-.3-7.9 5.25-12.9.1-.1.25-.2.05-.05.1-.05.3-.3.65-.55 1.45-1.15 3.2-2 1.15-.6 2.45-1 1.6-.55 3.05-.85-.25-.15-.55-.25-.1-.05-.3-.1-2-.25-4.65.75z"
                    />
                    <path
                        fill="#F8F7A9"
                        d="M122.65 277.25l-.2-.35.2.4v-.05m-6.15 1.6l.2.1-.05-.05q-.05-.05-.15-.05z"
                    />
                    <path
                        fill="#F5E02C"
                        d="M122.45 276.85l-.05-.05.05.1.2.35-.05-.2q-.05-.1-.15-.2m-13.45 7.7l-.05-.1v.1h.05z"
                    />
                    <path fill="#CCB809" d="M141.8 271.85h.05v-.05l-.05.05z" />
                    <path
                        fill="#F9F2DB"
                        d="M116.4 278.7v.05l.1.1q.05-.05.05-.1-.05 0-.15-.05z"
                    />
                    <path fill="#F1E6B8" d="M110.1 270.1v-.1l-.05.05.05.05z" />
                    <path
                        fill="#F4F4CE"
                        d="M104.85 235.85q-4.3 2.5-.85 10.5 5.25 7.2 6.1 23.65v.1l-.05-.05q-.05.15-.1.35.1 0 .15.05.05.5.15 1.05-.2 1.3.4 2.6.7 1.4 2.5 2.75 1.35.95 3.25 1.9v-.05q.1.05.15.05 0 .05-.05.1.1 0 .15.05l.05.05q.1.15.5.25.35.15.6.25.2.05.3.1.3.1.55.25h.1l.15-.05q1.15-3.4 2.15-3.85.15-.05.25-.05l7.75-3.1q.05.1.05-.15v-.2q.05-.5-.3-4.1-.3-3.7-1.65-9.65-2.3-10.05-8.35-19.1-.6-.7-1.25-1.25-6.35-6.1-12.7-2.45z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo012__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo012__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo012
