import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M214.35 37.3q-2.35-2.4-4.95-4.75-2.8-2.5-5.9-4.9-.45-.4-.95-.75-8.75-6.7-18.4-11.75-10.3-5.4-21.65-8.9-13.25-3.9-26.75-5.35-6.15-.7-12.3-.85Q99.6-.6 75.1 6.25q-2.95.9-5.8 1.95-18.55 6.65-34.25 18.7Q5.5 49.65.85 80.4H.8Q0 85.55 0 90.9q0 29.75 25.4 53.2-1.4-10.4-1.4-19.15.75-23.25 4.45-28.8v-.05q.45-.65 1.05-1.15 3.15-3.2 9.2-4.95.6-.25 1.15-.35.25-.1.5-.1l.5-.1q2.65-.4 3.4-.5 1.65-.25 3.35-.5 10.85-1.5 21.7-2.55 24.45-2.35 49.1-2.35h.8q8.3 0 16.55.3 27.25.85 54.25 4.6 1.7.25 3.35.5.75.1 3.4.5l.5.1q.25 0 .5.1.55.1 1.15.35.6.15 1.2.35l1.6.6q2.85 1.1 4.85 2.65.4.3.85.65l.7.7q.6.5 1.05 1.15.25.35.45.8.6 1.05 1.1 2.65.5 1.65 1 3.95 1.45 7.55 1.9 21.45 0 8.75-1.4 19.15 3.9-3.6 7.25-7.4 18.15-20.65 18.15-45.8v-1.65q-.1-4.25-.7-8.35-.05-.05-.05-.1 0-.2-.05-.4h-.05q-3.5-23.25-21.25-41.9-.6-.6-1.15-1.2z"
                    id="HaiSho047__Symbol_168_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M184.15 15.15q-10.3-5.4-21.65-8.9-13.25-3.9-26.75-5.35-29.8-3.25-60.65 5.35-2.95.9-5.8 1.95-18.55 6.65-34.25 18.7Q5.5 49.65.85 80.4H.8Q0 85.55 0 90.9q0 29.75 25.4 53.2-1.4-10.4-1.4-19.15.75-23.25 4.45-28.8-1.35.5-1.85.75-1.15.55-2.95.55-4.6 0-7.1-6-2-4.7-2-10.4 0-.95.05-1.9 0-.3.05-.6v-.15l.1-1.2Q17.6 47.1 54.4 22.85l3.55-2.3q.75-.5 1.55-.95 4.7-2.85 9.8-5.1 2.2-1 4.5-1.85Q100.6 1.8 135.75 5.2q14.4 1.4 30.15 5.2 8.95 1.95 18.25 4.75z"
                    id="HaiSho047__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M127.9 55.45Q110.45 46.5 89.15 43.8q-14.35-1.85-30.4-.85-1.05.05-2.1.15l-4.45.25q-18.5.55-23.35-5-1-1.5-1.6-3.15-1.3-3.35-1.3-7.3 0-9.8 5.8-16.8Q33.15 9.45 44 0q-7.6 2-13.9 4.2-3.9 1.75-7.4 3.75Q9.6 15.5 2.8 26.55 0 31.8 0 36.1q0 3.95 1.2 7.6 1.2 3.65 3.3 6.1 2.3 2.6 5.1 3.8 2.95 1.2 7.2 1.2 2.25 0 2.75-.05.05 0 2.6-.3.3-.05.55-.05.3-.05.55-.1.25-.05.5-.05.1-.05.25-.05 32.6-4.6 65.15-2.9 19.4.95 38.75 4.15z"
                    id="HaiSho047__Symbol_37_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho047__Symbol_168_0_Layer0_0_FILL"
                transform="translate(73.7 31.95)"
                id="HaiSho047__col1n"
            />
            <use
                xlinkHref="#HaiSho047__Symbol_88_0_Layer0_0_FILL"
                transform="translate(73.7 31.95)"
                id="HaiSho047__col1d"
            />
            <use
                xlinkHref="#HaiSho047__Symbol_37_0_Layer0_0_FILL"
                transform="translate(120.3 54.5)"
                id="HaiSho047__col1l"
            />
        </g></g>
    )
}

export default HaiSho047
