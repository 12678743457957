import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#666666"
}

function PetPet020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M347.7 226.8q-3.85-3.75-8.4-6-7.6-3.75-15.6-3.7h-.5q-8-.05-15.6 3.7-4.55 2.25-8.4 6-10 9.6-10 23.25 0 7.9 3.35 14.45.6 1.2 1.35 2.4.4.65.85 1.3.75 1 1.55 2l.25.25q-30.65 42.7-15.55 70.5 1 1.8 2.35 2.55 1.35.7 5.1-1.95 3.75-2.65 5.75-.95 2.15 1.85 4.45 3.4.255.16.5.35.155.091.3.2-.139-.112-.3-.25l23.05-26.1 25.7 25.95q-.142.134-.3.25l.05.05q.3-.25.6-.45 2.3-1.55 4.45-3.4 2-1.7 5.75.95t5.1 1.95q1.35-.75 2.35-2.55 15.1-27.8-15.55-70.5l.25-.25q.8-1 1.55-2 .45-.65.85-1.3.75-1.2 1.35-2.4 3.35-6.55 3.35-14.45 0-13.65-10-23.25m-11.55 8.6q-.15-.05-.35-.1 4.2.65 6.6 1.6.9.7 1.8 1.5 4 4.05 4 9.8 0 5.75-4 9.75-4.1 4.05-9.85 4.05h-1l1.65-1.8q1.4 0 2.85-.4 4.45-1.2 6.75-5.6 2.25-4.45.9-9.45-1.3-5.05-5.45-7.75-1.3-.85-3.85-1.55 0-.05-.05-.05m-25.4 0q-.05 0-.05.05-2.55.7-3.85 1.55-4.15 2.7-5.45 7.75-1.35 5 .9 9.45 2.3 4.4 6.75 5.6 1.45.4 2.85.4l1.65 1.8h-1q-5.75 0-9.85-4.05-4-4-4-9.75t4-9.8q.9-.8 1.8-1.5 2.4-.95 6.6-1.6-.2.05-.35.1z"
                    id="PetPet020__Layer13_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M347.4 273.55q-3.15 3-6.8 5.05-7.55 4.25-16.9 4.3h-.5q-9.35-.05-16.9-4.3-3.65-2.05-6.8-5.05-9.35 14.65-10.85 30.85-.85 30.75 10.8 40.15 7.95 4.95 17.4 6.15h.05l.9.1q.2 0 .4.05.5 0 .95.05 1.7.1 3.4.1H324.35q1.7 0 3.4-.1.45-.05.95-.05.2-.05.4-.05l.9-.1h.05q9.2-1.15 16.95-5.85.3-.25.6-.45 11.45-8.4 10.65-40.4-1.65-16-10.85-30.45m-16.05-38q-4.55 1.2-6.8 5.6-.15.3-.3.65l-.35.85q-.1.3-.2.65-.05.2-.15.4l-.1.5-.1-.5q-.1-.2-.15-.4-.1-.35-.2-.65l-.35-.85q-.15-.35-.3-.65-2.25-4.4-6.8-5.6-2.45-.65-4.8-.15-.05 0-.05.05-2.55.7-3.85 1.55-.122.08-.25.15-.315-.13-.85-.05-.71.105-1.05.5-.645-.237-2 .8.9-.8 1.8-1.5-3.7 1.4-6.45 4.35-4.85 5.05-4.85 12.2 0 7.95 3.95 13.85.75 1.15 1.7 2.25.3.3.6.65 1.3 1.35 2.85 2.6 1.3 1.05 2.65 1.95 7.65 4.9 18.25 5h.5q10.6-.1 18.25-5 1.35-.9 2.65-1.95 1.55-1.25 2.85-2.6.3-.35.6-.65.95-1.1 1.7-2.25 3.95-5.9 3.95-13.85 0-7.15-4.85-12.2-2.75-2.95-6.45-4.35.9.7 1.8 1.5.172.174.3.35-1.86-.892-3.2-1.4-.717-.256-1.25-.35-1.3-.85-3.85-1.55 0-.05-.05-.05-2.35-.5-4.8.15m-1.8 11.25q.85-.2 1.65.25.85.45 1.05 1.35.2.85-.25 1.6-.5.85-1.35 1.05-.8.2-1.65-.25-.8-.45-1-1.3-.25-.85.25-1.7.45-.75 1.3-1m14.65-5.9q-.187-.04-.35-.05-.038-.068-.1-.15.24.115.45.2m-28.5 6.15q.8-.45 1.65-.25.85.25 1.3 1 .5.85.25 1.7-.2.85-1 1.3-.85.45-1.65.25-.85-.2-1.35-1.05-.45-.75-.25-1.6.2-.9 1.05-1.35m-3.75 13.25q-.107-.051-.2-.1h.15q.015.053.05.1z"
                    id="PetPet020__Layer13_1_FILL"
                />
                <path
                    fill="#C8C8C8"
                    d="M311.1 235.3q-4.2.65-6.6 1.6-.9.7-1.8 1.5-4 4.05-4 9.8 0 5.75 4 9.75 4.1 4.05 9.85 4.05h1l-1.65-1.8q-1.4 0-2.85-.4-4.45-1.2-6.75-5.6-2.25-4.45-.9-9.45 1.3-5.05 5.45-7.75 1.3-.85 3.85-1.55 0-.05.05-.05.15-.05.35-.1m25.1.15q2.55.7 3.85 1.55 4.15 2.7 5.45 7.75 1.35 5-.9 9.45-2.3 4.4-6.75 5.6-1.45.4-2.85.4l-1.65 1.8h1q5.75 0 9.85-4.05 4-4 4-9.75t-4-9.8q-.9-.8-1.8-1.5-2.4-.95-6.6-1.6.2.05.35.1.05 0 .05.05z"
                    id="PetPet020__Layer13_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M53.3 98.3q-.55-1.4-1.05-2.15-.5-.8-1.25-1.55l.2-.1-.05-.05q-.3.2-.6.45-7.75 4.7-16.95 5.85v.1q-.05-.05-.05-.1l-.9.1q-.2 0-.4.05-.5 0-.95.05.1.7.9 1.75.5.6 1.05 1 2.6 1.85 4.85 0 .5-.3.8-.45.25-.05.55-.05h.15q.3.15.9.55l.05.05q2.5 1.9 4.55 0h.05l.7-.6q.55-.5 1.2-.5.35 0 1.25.4.9.35 2.1.8 1.2.4 2.45-.6 1.25-1.05 1.15-2.35-.1-1.3-.7-2.65m-6.55 3.7h-.05v-.05l.05.05m-45-5.85q-.5.75-1.05 2.15-.6 1.35-.7 2.65-.1 1.3 1.15 2.35 1.25 1 2.45.6 1.2-.45 2.1-.8.9-.4 1.25-.4.65 0 1.2.5l.7.6h.05q2.05 1.9 4.55 0l.05-.05q.6-.4.9-.55h.15q.3 0 .55.05.3.15.8.45 2.25 1.85 4.85 0 .55-.4 1.05-1 .8-1.05.9-1.75-.45-.05-.95-.05-.2-.05-.4-.05l-.9-.1q0 .05-.05.1v-.1Q10.95 99.55 3 94.6q-.75.75-1.25 1.55m5.55 5.8v.05h-.05l.05-.05m31.25-91.7q-3 0-5.85-1.85-4.05-2.6-5.45-7.4-.05-.15-.1-.35 0-.05-.05-.1 0-.3-.1-.55-.1.25-.1.55-.05.05-.05.1-.05.2-.1.35-1.4 4.8-5.45 7.4-2.85 1.85-5.85 1.85L27 22.55l11.55-12.3z"
                    id="PetPet020__Symbol_92_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#PetPet020__Layer13_0_FILL" />
            <use xlinkHref="#PetPet020__Layer13_1_FILL" />
            <use xlinkHref="#PetPet020__Layer13_2_FILL" />
            <use
                xlinkHref="#PetPet020__Symbol_92_0_Layer0_0_FILL"
                transform="translate(296.45 249.95)"
                id="PetPet020__col1n"
            />
        </g></g>
    )
}

export default PetPet020
