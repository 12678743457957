import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000"
}

function SkiMak020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M145.35 6.9q-.9 1.05-1.45 1.65-.6.6-2.65 2.6-2.1 2-4.95 4.75-.7-3.15-5.7-5.25 2.2 2.8 2.4 4.4.3.85.3 1.7t-1.2 2.45q-5.25 4.75-6.45 4.75-.55 0-.95-.15-1.05-.6-1.55-1-.7-.6-3.15-1.6l.3.25q.75 1.2.8 2.55.05 1.35-1.1 2.3-.6.4-1.2.85-.65.45-2.3 1.25-1.6.8-3.45 1.55-1.9.75-4.5 2.15-.1.2-1.05.45-.95.15-3.35.45-2.45.25-6.4.6-.5-1.15-1.3-2.25-.85-1.1-.8 0 .05 1.05-.9 2.25-.95 1.2-3.7 1.85-2.05.5-3.85.9.95-.15-1.15.25-.5.1-1.2.2-.75.1-4.1.3-3.35.15-9.25-.1-5.9-.3-13.95-.4-4.55-1.1-5.25-5.4 0 1.5-.75 3-.25.5-.65 1.25-.75.15-1.25.1-.5-.1-3.4-.25-2.95-.15-12.2-4.65-.45-.6-.75-1.25-1-2.2-1-5.45-.95.55-1.7 2.05-.45.75-.5 2.3-3.35-1.1-4.4-3.55-3.8-1.6-9.45-6.45-1-.4-1.3-1.6-.15-1.8-.15-1.85 0-4.1 3.65-5.85-.75-.1-2.3.6-2.75 1.25-3.05 2.25-.95 1.3-1.2 2.45-1.8-1.75-3.75-4.05-.8-.05-1.7-.8H6.4q0-.05-.05-.1Q5.5 8.6 0 1.05 4.65 8.2 4.8 8.7q.55.75 1.6 2.1 1.15 1.55 1.7 2.6 1.15 2.25 4.85 5.85l1.3 1.3q3.45 2.8 7.05 6.3 4.05 4 5.35 7.25 2 .3 4.2 1.3 2.3 1.05 3.75 2 .1.15.25.3 1.6 2.2 1.6 4.5-.15 1.05-.15 2.1.05 1.65 1 2.55 1.1 2.45.6.85-1-4.2 1.4-5.8 1.25-.85 1.85-.55.65.05 1.5.25 2.3.8 3.2 1.6 1.15 1 2.85 2.05 2.15 1.2 3.4 1.95 2.1 1.2 5.7 2.3 17.5 7.2 33.45-.85 1.05-.5 2.05-1.1 1.1-.65 5.85-3.85 4.9-2.9 8.35-2.9 1.1 0 1.45.2.1.05.25.1.4.5.7 1.3.5 1.25.6 2.55 0 .4-.1 2.8 2.05-1.85 1.85-4.85-.25-2.7-.2-4.15.6-.3 2.2-1.15 3.6-2.05 3.95-2.2l4.6-4.9 2.7-.95q1.2-2.9 5.85-6.4 8.1-6.2 10.2-9.2.75-1.05 2.05-2.9 1.3-1.9 2.4-3.9 1.1-2 2.05-3.6.9-1.65 1.7-3.55-1.5 3.25-4.4 6.9z"
                    id="SkiMak020__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak020__Symbol_26_0_Layer0_0_FILL"
                transform="translate(117.65 181.85)"
                id="SkiMak020__col1n"
            />
        </g></g>
    )
}

export default SkiMak020
