import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiHig042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M138.3 90.45q9.85-11.3 17.55-21.5L161.6 0 76.15 13Q65.4 19.7 46.3 42.5l-.05.05q-3.15 5.45-7.1 14.4L38 59.6q-.6 1.25-1.2 2.65-2.9 6.75-6.25 19.9-3.6 14.25-5.8 19.9-.65 1.75-1.4 3.5 7.3-4.5 9.25-8.05v.25l.1.5q.05.2.15.35.4 13.25-16.4 30.8-2.2 2.25-4.6 4.5-5.35 5.05-11.4 10.2-.25.15-.45.35h3q3.7-.1 7.5-.4 25.95-2.25 57.9-15.15 1.5-.65 3-1.25-.9 1.2-1.95 2.3-5.05 5.25-5.1 5.3l2.25.25q13.6-3.35 24.2-7.25 7.35-2.9 14.1-6.4 9.7-4.25 33.4-31.4M117.35 42.5l-.25.25q.1-.15.2-.25h.05z"
                    id="HaiHig042__Symbol_144_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M83.95 15.6q6-6.2 9.95-15.6-5.05 2.65-19.15 9.7l.45-.5q-5.885 5.613-15.65 12.85-13.1 9.65-31.8 23.05 17.4-6.3 25.4-10-4.7 6.8-10.75 13.5-10.9 12.05-23.9 20.9l-.25.25q8.2-1.6 15.8-4.35-2 2.1-4.3 4.6-1.55 1.6-5.25 4.8-4.05 3.45-6.5 5.2-3.2 2.2-7.45 4.7-4.65 2.65-9.9 6.2-.35.3-.65.55 22.3-6.15 40.7-16.4Q55.15 67 69 55.25q7.2-6.1 16.35-15.6 6.45-6.65 11.9-12.9 3-3.45 7.45-9.75-2.4 3-8.2 8.2-5.8 5.2-13.1 7.5 5.9-6.75 9.45-11.8 2.5-3.6 7.5-10.2-6.3 7.35-26.1 13.5 3.65-2.35 9.7-8.6m-2.2-5.6q.1-.15.2-.25H82l-.25.25z"
                    id="HaiHig042__Symbol_142_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M137.15 30q-1.7-4.85-7.6-13.2-1.9-2.7-3.2-4.15-1.65-1.85-3.55-3.1-2.4-2.45-4.65-3.45-.05 0-.05-.05-.3-.15-.55-.35-1.25-.75-2.6-1.45Q100-1.1 78.65.25q-38 2.75-63.6 31.4Q6.45 40.2 0 51.55q17.85-19.5 41.7-30.05-2.55 2.2-6.3 7.1-3.8 4.85-8.1 10.95 9.95-7.5 16.1-9.75 6.1-2.3 7.6-2.65-4.75 4.15-9.8 9.2-17.5 17.45-20 27Q30.15 51.7 48.5 36.6 66.8 21.45 85 13.8q-1.3.9-2.8 2-16.25 11.7-24.8 21.4-8.55 9.65-16.5 20.15-5.55 7.35-7.35 10.2 3.55-3.7 8.55-8.35 4.95-4.7 10.2-8.55 5.2-3.85 10.6-7.45 5.4-3.65 7.1-4.5 4.7-2.5 8.25-4.15l.2-.1q.15-.05.3-.15-8.55 7.8-14.3 16.5l-.1.1-.55.6q14.1-7.05 19.15-9.7Q79 51.2 73 57.4q-6.05 6.25-9.7 8.6 19.8-6.15 26.1-13.5-5 6.6-7.5 10.2-3.55 5.05-9.45 11.8 7.3-2.3 13.1-7.5t9.6-9.55q.15-.25.3-.4-.4.7-.65 1.3-.25.5-.45 1-5.65 11.8-14.2 25.35-12.4 19.75-19.35 25.35l-4.5 5.25 5.75-5q4.55-2.85 9.05-6.8 5.05-4.4 7.7-8.2-.8 4.05-2.4 7.6-.85 1.95-2.55 5.6 5.65-6.5 14.5-15.9 5.25-6.3 11.75-16.5-2.3 5-5.05 9.95-10.2 18.25-19.25 29.1-8.4 10.15-17.2 15.75 2.05-1.05 4.05-2.15 4-2.25 7.8-4.7 24.8-16.1 40.35-41.55l.05-.05q-.75 3.25-2.1 7.05-1.2 3.3-3.1 8.6 1.8-1.2 3.55-2.45 11.9-8.85 19.9-22.25 9.45-15.9 9.45-31.85 0-7.4-1.4-11.55m-47.6 23.8q-.1 0-.25.05.1-.15.25-.3v.25M72.3 12.05l-.4.1q.05-.05.15-.1h.25m7 22.25q-.05.05-.05.15-.1.15-.15.35l.1-.7q.05 0 .1-.05v.25z"
                    id="HaiHig042__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M41.35 7.35q0-2.85-.5-7.35-9.15 17.8-16.9 29.25Q12.9 45.7 0 54.75q24.8-16.1 40.35-41.55 1-2.7 1-5.85z"
                    id="HaiHig042__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M51.75 1.4q-2 3.05-7.15 7.15-6 4.75-13.35 8.35v-.25q1.8-2.35 5.1-7.55Q40.2 3 41.4 0 29.5 9.7 16.2 21.35 1.1 34.55.95 34.6l-.45.5q14.1-7.05 19.15-9.7Q15.7 34.8 9.7 41 3.65 47.25 0 49.6q19.8-6.15 26.1-13.5-5 6.6-7.5 10.2-3.55 5.05-9.45 11.8 7.3-2.3 13.1-7.5t8.2-8.2q1.3-1.9 2.75-4.05.4-.9.65-1.8.15.1.25.2l.1.1q-1.05 1.9-1.8 3.35 1.55-2.5 2.25-4.25 2.5-6.35 3.35-7.8 1-1.7 7.9-12.9 6.35-10.8 6.35-13v-1.1q-.008.25-.5.25m-25.5 36q-.1 0-.25.05.1-.15.25-.3v.25z"
                    id="HaiHig042__Symbol_139_0_Layer0_0_FILL"
                />
                <g id="HaiHig042__Layer8_0_FILL">
                    <path
                        fill="#FF008C"
                        d="M177.75 109l.25-.25q-.042-.007-.1-.05-.066.168-.15.3z"
                    />
                    <path
                        fill="#434343"
                        d="M178.8 69.35q.237-.055.45-.1H179q-.089.046-.2.1z"
                    />
                </g>
                <g
                    id="HaiHig042__Symbol_143_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M53.85 14.75l.4.05q-.25.55.5-.1-.45 0-.9.05zM65.85.5V0Q45.75 9.5 27.2 16.05q-16.95 6-27.2 7.5Q25.95 21.3 57.9 8.4q1.5-.65 3-1.25.05.1.25.25 4.7-5.7 4.7-6.9z" />
                </g>
            </defs>
            <use xlinkHref="#HaiHig042__Layer8_0_FILL" />
            <use
                xlinkHref="#HaiHig042__Symbol_144_0_Layer0_0_FILL"
                transform="translate(60.65 66.25)"
                id="HaiHig042__col1n"
            />
            <use
                xlinkHref="#HaiHig042__Symbol_143_0_Layer0_0_FILL"
                transform="translate(71.15 186.75)"
                id="HaiHig042__col1d"
            />
            <use
                xlinkHref="#HaiHig042__Symbol_142_0_Layer0_0_FILL"
                transform="translate(96 99)"
                id="HaiHig042__col1l"
            />
            <use
                xlinkHref="#HaiHig042__Symbol_140_0_Layer0_0_FILL"
                transform="translate(106.95 57.2)"
                id="HaiHig042__col2n"
            />
            <use
                xlinkHref="#HaiHig042__Symbol_141_0_Layer0_0_FILL"
                transform="translate(177.4 126.5)"
                id="HaiHig042__col2d"
            />
            <use
                xlinkHref="#HaiHig042__Symbol_139_0_Layer0_0_FILL"
                transform="translate(170.25 73.6)"
                id="HaiHig042__col2l"
            />
        </g></g>
    )
}
export default HaiHig042
