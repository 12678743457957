import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSho079({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M185.7 20.85Q179 14 170.05 8.65q-10.5-5.9-23.8-5.6-21.3.65-41.85-2.35-10.9-2.15-22.85 2.25-4.85 1.9-9.7 5.25-7.35 5.15-14.8 11.45-6 5.1-13.45 9.95-7.4 4.85-16.1 13.15-8.85 8.4-14.9 11.3-6.05 2.7-10.65 9.25-4.55 6.6 1.5 12.6.85.95 1.8 1.8 5.4 4.2 13.2 5.5-.1.2-.2.45-1.35 3.6-2.15 7-.4 1.95-.6 3.95-1.2 7.55-.3 15.75 1.25 10.05 6.2 17.35 3.1 4.5 5.2 10.55 1.2 3.65 2.1 7.85 2.25 11.25-5.3 14.85 6.45 2.8 16.1 1.3 3-.55 5.95-1.6.05-.05.15-.05.25-.15.6-.25l-.05-.05.75-.25Q34.75 146.5 29.1 125.1q-4.6-17.85-3.35-34.6l.1-1.2q2.95-19.8 8.85-29.7.3-.55.65-1.05 7.75-1.1 17.1-.45 10.05.5 21.6 1.7 14.9 1.5 22.3 1 7.45-.65 15.6-2.5 8.1-1.75 14.75-3.1 6.8-1.35 14.6-1.75 3.45-.3 7.15-1l.65.25q.1.05.25.15.05 0 .1-.05l.25.1-.2-.1h.1l.1.1q7.1 7.15 10 10 .35.4 1.5-.8.9-1 1.8-1.9l.2-.2h.6q8.15 3.8 11.95 11.85 1.25 2.5 3.45 5.55 5.65 7.7 12.25 12.45 5.25 3.75 10.35 7.85 5.4 4.3 9.1 13.05.85 2.05 1.8 4.05-.2.55-.1 2.9v.05q-.05.5-.1 1.05l-.05.55-.3 1.7q-.5 2.6-1.2 5.25l-.5 1.7-2.3 6.55q-5.95 15.4-17.7 29 .75.15 1.6.4.25.05.55.15 6.65 1.7 16.05 5.3 1.4.45 2.65 1-4.35-6.3-4.4-11.05-.15-1.65.45-4.2.9-3.1 2.85-7.65 2.4-5.2 6.4-12.35 1.75-3.4 3.1-5.5.1 0 .2-.2t.2-.35q1.8 2.15 2.4 3.05 3.3-11.05 4.35-24.4 0-2.1.1-4.15.35-10.7-1.5-20.7-1.75-9.15-5-18.45-2.05-5.95-4.85-11.9-.6-1.25-1.2-2.4-2.25-6.05-7.05-10.6-8.65-8.8-18.4-16-1.7-1.3-3.25-2.7z"
                    id="HaiSho079__Symbol_200_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.55 47.05l1.3.3q2.4-4.85 4.45-7.15.6-.7.95-1.15.2-.35.35-.55.15-.25-2.25 1.55-2.45 1.8-4.8 7m17.15-23l.85-1.55q-2.55 0-6.65.4-5.8.55-11.2 3.1-5.8 2.65-5.7 6.3 0 1.05.5 2.05v-.05q.7 1.65 3 3.1.2.15 1.55.7 1.3.5 1.2.15-.1-.4-.95-1.15-.85-.75-1.05-.9-1.85-1.15-2.5-2.5-.35-.65-.35-1.4.1-2.85 4.9-5.05 5.2-2.4 10.75-2.95 4.05-.4 5.65-.25m130.2-19.2q.7-.85 2.35-1.85l.6-.5q1-.85 2.4-2.2 0-.05.15-.15.1-.35-1.4.25-1.5.55-2.5 1.4-1.85 1.15-2.65 2.15h-.05q-.65.7-2.5 2.55-1.65 1.65-2.3 2.55-1.5 1.85-4.55 4.05-2.85 2-4.6 2.7-1.8.65-1.05.95.7.3.85.35.1 0 .3.1.2.1 1.3-.4 1.05-.5 4-2.55 3.25-2.35 4.85-4.35.65-.85 2.2-2.4l2.6-2.6v-.05m47.8 74.5q-.1.6.05 2.05 3.15 6.6 6.7 13l.9-1.25q-3.8-6.9-7.15-14.05-.15-.25-.3-.3-.15-.05-.2.55m-32-75.95q1.2-1 1.95-1.8t-1.55.4q-2.35 1.15-2.6 1.3-6.1 3.5-8.95 9.8-.7 1.4-2.7 5.25v-.05q-.45.75-1.65 3.05v-.05q-1 1.75-1.8 2.8l1.35.4q.9-1.15 1.65-2.5h.05q1.15-2.25 1.6-3h.05q2.05-3.9 2.7-5.3 2.7-5.9 8.4-9.2.25-.15 1.5-1.1z"
                    id="HaiSho079__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho079__Symbol_200_0_Layer0_0_FILL"
                transform="translate(71.95 55.15)"
                id="HaiSho079__col1n"
            />
            <use
                xlinkHref="#HaiSho079__Symbol_10_0_Layer0_0_FILL"
                transform="translate(83.75 91.05)"
                id="HaiSho079__col1s"
            />
        </g></g>
    )
}

export default HaiSho079
