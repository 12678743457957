import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.05.95q0-.4-.25-.65-.3-.3-.7-.3H.95Q.55 0 .3.3 0 .55 0 .95t.3.7q.25.25.65.25H27.1q.4 0 .7-.25.25-.3.25-.7z"
                    id="FacMou003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacMou003__col1n" transform="translate(178.45 224.6)">
                <use
                    xlinkHref="#FacMou003__Symbol_3_0_Layer0_0_FILL"
                    id="FacMou003__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacMou003
