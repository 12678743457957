import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou071({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M211.75 212.05q-7.943-4.295-19.2-4.3-11.307.005-19.25 4.3h-.05q-8.278 4.601-8.2 10.95v.1q.023 4.561 4.5 8.25.802.748 1.75 1.3.908.651 2 1.2 3.22 1.707 6.85 2.7h-.05q.724.263 1.5.45 2.773.64 5.75.9.535.04 1 .1.052.04.1.05 1.875.151 3.8.15h1.55q3.578-.096 6.7-.65h.05q3.904-.617 7.35-1.95 2.015-.72 3.85-1.75 2.524-1.386 4.25-2.9h.05q4.027-3.473 4-7.95v-.05q.024-6.297-8.3-10.9z"
                    id="FacMou071__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M33.8 26.8q3.1-.5 5.95-1.45L34.2 19.7l-.7 7.15q.1-.05.25-.05h.05m-7.5.65h.95q2.95-.1 5.6-.5l-3.8-6.9-2.75 7.4M22 27.3q1.75.15 3.55.15l-2.8-7.4-3.75 6.9q.95.1 2 .2.5.05 1 .15m-6.55-.95q1.4.3 2.85.5l-.7-7.15-5.45 5.6q.9.3 1.85.6h.05q.7.25 1.4.45m-9.6-4q.75.45 1.6.95 1.9.95 3.9 1.7l.5-6.4-6 3.75M4 21q.45.4 1 .8l2.7-5.55L.9 17.4q1 1.9 3.1 3.6m2.3-7.1l.05-.05L.6 10.8Q0 12.2 0 13.75v.1q.05 1.6.7 3.05l5.65-3H6.3M4.9 5.75Q1.95 7.9.8 10.35l6.9 1.15-2.8-5.75m6.95 3.4l-.5-6.75q-2.05.7-3.9 1.7h.05q-1.05.55-1.9 1.15l6.25 3.9m0-6.95l5.75 5.85.75-7.45q-3.5.5-6.5 1.6m10.9 5.5l2.9-7.7q-3.6 0-6.85.5l3.95 7.2m11.45.35l5.8-5.9Q36.95 1.1 33.5.55l.7 7.5M33 .5Q29.75 0 26.15 0l2.9 7.7L33 .5m11.1 15.75l2.75 5.6q.9-.65 1.7-1.25h-.05q1.7-1.5 2.6-3.15l-7-1.2m.4 7.05q.8-.45 1.55-.9l-6.1-3.8.5 6.5q.2-.1.4-.15 1.9-.7 3.65-1.65m1.75-18.1q-.8-.55-1.75-1.1-1.9-1.05-4.05-1.8l-.5 6.85 6.3-3.95m.7.45L44.1 11.5l7.05-1.15q-1.2-2.55-4.2-4.7M51.8 12q-.15-.6-.4-1.2l-.05.05q-.05-.05-.05-.1l-5.85 3.1.05.05h-.05L51.3 17q0-.05.05-.05.6-1.4.65-2.9v-.15-.2q0-.3-.05-.6L51.8 12z"
                    id="FacMou071__Symbol_96_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou071__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou071__Symbol_96_0_Layer0_0_FILL"
                transform="translate(166.55 209.25)"
                id="FacMou071__col1n"
            />
        </g></g>
    )
}

export default FacMou071
