import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000"
}

function LegSki024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M150.3 7.85Q146.6 3.4 142.65 0q-.2.05-.35.1Q79.05 12.35 17.8.2q-.25-.1-.5-.1-.15-.05-.25-.05Q7.95 7.95 0 21.4q1-.35 2-.45h.3q.3-.05.6-.05.75 0 1.7.2 2.8 1.15 5.95 0 .1-.05.55-.15.25-.1 2-.5-.2.05-.05.05 1.3-.3 1.8-.45L15 20q.15-.05.3-.05 2.5-.45 5-.85h.3q19.2-2.85 38.2-.1 12.05 2.75 17.8 3.05 2.25.1 4.5-.05 5.35-.45 10.7-2.4 14.8-2.65 30-1.9 6.3.25 9.1.85 2.8.55 4.3.85 1.45.3 4.7 1.2 3.2.85 5.85.85 1.15 0 2.1-.65l.3-.2q.1-.05.25-.05 1.55-.4 3.3-.4.3 0 .6.05 1.15.1 2.3.65 1.55.85 2.95 1.25 1.45.4 2.8.3-.3-.55-.65-1.05-4.5-7.65-9.4-13.5z"
                    id="LegSki024__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M142.25 5.85q1.15 2.1 1.15 3.2 0 2.05-.55 2.05-1.7 0-5.1-4.55Q134.3 2 133.55 2q-.45 0-.45 1.35 0 1.7.5 3.05.5 1.35.5 2.65l-.1 1.05h-.6q-1.85-.55-3.7-3.8-2.7-4.85-3.4-5.7 0 .25-.2.9t-.2 1.3q0 1.55.45 3.3.45 1.75.45 3.3 0 1.4-.2 1.8h-.5q-3.2-.9-4.25-2.7-1.35-2.95-3.45-5.8-.75.8-.55 4 .2 2.7-1.15 2.7-2.65 0-4.65-3.25-1.95-3.25-2.1-3.25-.55 0-1.6 3.05-1 3.05-1.3 3.05-1.8 0-3.9-2.3-2.1-2.3-2.5-2.3-1.35 0-1.8 3.35-.5 3.35-2.55 3.35-1.9 0-3.15-1.9t-2-1.9q-2.35 0-2.65 2.5-.3 2.5-1.05 2.5-1.5 0-2.15-2.8-.65-2.8-1.2-2.8-1.45 0-1.95 4.1-.45 4.1-2.85 4.1-.65 0-1.25-.4-.3-.25-.5-.4-.3-.75-.6-1.6-2.15-6.3-2.55-6.3-1.2 0-3.3 3.7-2.1 3.7-2.6 3.7-1.55 0-2.25-2.05-.65-2.05-.9-2.05-.45 0-1.25 1.65t-1.45 1.65q-1.5 0-1.9-3.5-.45-4.2-.75-4.6-.2.15-1.25 1.6-1.05 1.5-1.25 1.9-.3.6-1.45 3.15-.5 1.15-1.05 1.15-1.7 0-2.45-3.25Q51.8 6 51.4 6 49.9 6 48 8.8q-1.85 2.8-2.8 2.8-.95 0-1.3-1.05-.35-1.05-1.1-1.05-1.05 0-2 .9-1 .9-1.6.9-1.9 0-1.9-1.8.3-2.5.3-3.3.25-2.5-.7-2.5-.8 0-4.35 3.4-3.6 3.4-4.45 3.4-1.25 0-1.15-1.4.05-1.4-.4-1.4-.7 0-2.4 1-1.75 1-2.25 1-1.7 0-1.7-1.35 0-.6.6-2.65V3.9h-.2q-2.25 1-4.65 3.3-2.9 2.75-3.95 3.4h-1.5V9.4q.45-2 1.75-4.8 1.4-2.9 1.85-4.1V0q-2.05 1.2-6.6 7.15-4.6 6.1-7.5 8.45.3-.05.6-.05.75 0 1.7.2 2.8 1.15 5.95 0 .1-.05.55-.15.25-.1 2-.5-.2.05-.05.05 1.3-.3 1.8-.45l.15-.05q.15-.05.3-.05 2.5-.45 5-.85h.3q19.2-2.85 38.2-.1 12.05 2.75 17.8 3.05 2.25.1 4.5-.05 5.35-.45 10.7-2.4 14.8-2.65 30-1.9 6.3.25 9.1.85 2.8.55 4.3.85 1.45.3 4.7 1.2 3.2.85 5.85.85 1.15 0 2.1-.65l.3-.2q.1-.05.25-.05 1.55-.4 3.3-.4.3 0 .6.05 1.15.1 2.3.65 1.55.85 2.95 1.25l-.05-.05q-4-4.65-6.9-8.9-1.15-1.7-3.35-3.95-2.15-2.2-3.25-2.95h-.5v1.3q-.1 1.65 1.05 3.65z"
                    id="LegSki024__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki024__Symbol_90_0_Layer0_0_FILL"
                transform="translate(112.7 310.25)"
                id="LegSki024__col1n"
            />
            <use
                xlinkHref="#LegSki024__Symbol_50_0_Layer0_0_FILL"
                transform="translate(115 315.6)"
                id="LegSki024__col1d"
            />
        </g></g>
    )
}

export default LegSki024
