import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMak005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.5 4.6q.35-1.2.65-2.7-4.3 6.9-11.6 7.85H29q.65-.3 1.25-.7.2-.15.4-.25 3.45-2.25 3.8-6.55 0-.9-.25-1.9Q33.65 3 31.8 5l-.4.5q-2.1 2.05-6.8 4.05-.7.25-1.25.5 3.7-3.5 2.6-9.05-.05-.55-.1-1-.45 2.4-2.05 5.4l-1.55 2.75.2-.4Q20.8 10.2 16.8 13.3q.35-.2-3.5 2.85-.3.25-.7.75-.2.15-.4.35-1.65 1.3-3.1 2.9-3.1 3.4-5.15 7.2Q.6 33.6.05 41.15v.1Q0 42.05 0 42.8q2.2-13.7 13.15-23.6 1.15-1.05 2.35-1.95 14.4-9.6 29.6 0 1.1.8 2.2 1.75.85.75 1.65 1.6.6.6 1.15 1.3-.35-.6-.7-1.15-1.2-1.95-2.45-3.5-.3-.3-.55-.6l-.4-.5-.6-.6q-2.9-2.8-6.3-4-1.15-.35-2.75-.9 1.85-1 2.85-2.8.8-1.3 1.3-3.25m43.55 3.15l.2.4L82.7 5.4q-1.6-3-2.05-5.4-.05.45-.1 1-1.1 5.55 2.6 9.05-.55-.25-1.25-.5-4.7-2-6.8-4.05l-.4-.5Q72.85 3 72.3.35q-.25 1-.25 1.9.35 4.3 3.8 6.55.2.1.4.25.6.4 1.25.7h-.55q-7.3-.95-11.6-7.85.3 1.5.65 2.7.5 1.95 1.3 3.25 1 1.8 2.85 2.8-1.6.55-2.75.9-3.4 1.2-6.3 4l-.6.6-.4.5q-.25.3-.55.6-1.25 1.55-2.45 3.5-.35.55-.7 1.15.55-.7 1.15-1.3.8-.85 1.65-1.6 1.1-.95 2.2-1.75 15.2-9.6 29.6 0 1.2.9 2.35 1.95 10.95 9.9 13.15 23.6 0-.75-.05-1.55v-.1q-.55-7.55-3.9-13.8-2.05-3.8-5.15-7.2-1.45-1.6-3.1-2.9-.2-.2-.4-.35-.4-.5-.7-.75-3.85-3.05-3.5-2.85-4-3.1-5.65-5.55z"
                    id="SkiMak005__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak005__Symbol_5_0_Layer0_0_FILL"
                transform="translate(139.3 125.55)"
                id="SkiMak005__col1n"
            />
        </g></g>
    )
}

export default SkiMak005
