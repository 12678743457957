import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#660000",
    "col2n": "#FFFFFF"
}

function ObjToy007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.65 1.25l-.15-.05q-.3-.15-.55-.25h-.15Q18.9.6 18.05.4 16.4 0 14.6 0q-.4 0-.8.05h-.35l-.9.1h-.15q-.65.1-1.25.25t-1.2.35Q4.2 2.8 1.5 8.2.45 10.4.15 12.6L0 14.55q0 1.1.15 2.2l.1.65q.2.95.55 1.95l.1.2v.1q.15.4.3.75.15.4.35.85 1 1.9 2.7 3.6.6.6 1.2 1.1 1 .85 2.15 1.45 3.15 1.75 7 1.75 6.05 0 10.3-4.3 4.25-4.25 4.25-10.3 0-4.8-2.65-8.45-.2-.2-.45-.6-.55-.65-1.15-1.25l-.45-.45q-.9-.8-1.8-1.4-1-.7-2-1.15z"
                    id="ObjToy007__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.75 23.45q-.05.15 0 .3 1 .85 2.15 1.45-.25-.3-.45-.6l-.5-.8-.3-.5q-.15-.2-.3-.2-.2-.1-.4.05-.2.1-.2.3m1.7-2.65q-.2 0-.3.2-.15.15-.1.35.35 1.6 1.05 3.1.1.2.3.3.15.05.35-.05.2-.1.3-.25.05-.2-.05-.4-.6-1.4-.95-2.9-.05-.2-.2-.3-.2-.15-.4-.05M1 17.55q0-.2-.15-.3-.2-.15-.4-.15t-.3.2q-.15.15-.15.35.15 1.65.6 3.15.05.15.2.3.2.1.4.05.15-.05.3-.25.1-.2.05-.35-.4-1.45-.55-3m2.15-2.5q-.15.1-.2.35-.2 1.2-.2 2.55v.3q0 .2.15.35.15.15.35.15.2 0 .35-.15.15-.15.15-.35v-.3q0-1.25.2-2.4.05-.25-.1-.4-.1-.15-.3-.2-.25-.05-.4.1m2.95-5.4q-.05-.2-.2-.3-.2-.15-.4-.1-.2.05-.3.25-.9 1.25-1.45 2.75-.1.15 0 .35.1.2.3.3.15.05.35-.05.2-.1.3-.25.5-1.35 1.35-2.55.1-.2.05-.4m-5.2 1.6q-.2.1-.25.3Q.15 13 0 14.6q0 .2.15.4.1.15.3.15.2 0 .4-.1.15-.15.15-.35.15-1.5.6-2.85.05-.2-.05-.35-.1-.2-.3-.25-.2-.1-.35 0M4.15 6Q4 5.85 3.8 5.9q-.2.05-.35.2-1 1.25-1.7 2.6-.1.15 0 .35.05.2.25.3.15.1.35.05.2-.1.3-.25.7-1.3 1.6-2.45.15-.15.1-.35-.05-.2-.2-.35M8.7 2.85q.15-.15.25-.3.05-.2-.05-.4-.15-.2-.3-.2-.2-.1-.4.05-1.35.75-2.6 1.9-.15.1-.15.35 0 .2.15.35.1.15.35.15.2 0 .35-.1 1.15-1.1 2.4-1.8m1.45 2.9q.15-.1.2-.3.05-.2-.1-.35-.1-.2-.3-.25-.2-.05-.35.1-1.2.8-2.3 1.9l-.2.2q-.15.15-.15.35 0 .2.15.35.15.15.35.15.2 0 .35-.15l.2-.2q1-1 2.15-1.8M14.35.2Q14.2.05 14 .1q-1.55.2-3 .7-.2.05-.3.25-.1.15 0 .35.05.2.25.3.15.1.35.05 1.35-.45 2.85-.65.2-.05.3-.2.15-.15.1-.35-.05-.25-.2-.35m1.25 2.45q-.2-.15-.4-.1-1.55.3-2.95.95-.2.1-.25.3-.1.15 0 .35.1.2.3.3.15.05.35-.05 1.3-.55 2.75-.85.2-.05.35-.2.1-.2.05-.4t-.2-.3m3.55-1.4l.9.2h.15q.25.05.55.15-.9-.8-1.8-1.4-.9-.15-1.8-.2-.25 0-.35.15-.2.1-.2.35 0 .2.15.35.1.15.35.15 1.05.05 2.05.25m2.7 1.55q-.15-.2-.35-.25-1.45-.3-3.05-.3h-.2q-.2 0-.35.15-.15.15-.15.35 0 .2.15.35.15.15.35.15h.2q1.5 0 2.85.3.2.05.4-.05.15-.15.2-.35.05-.2-.05-.35z"
                    id="ObjToy007__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy007__Symbol_101_0_Layer0_0_FILL"
                transform="translate(245.4 276.8)"
                id="ObjToy007__col1n"
            />
            <use
                xlinkHref="#ObjToy007__Symbol_22_0_Layer0_0_FILL"
                transform="translate(249.1 279)"
                id="ObjToy007__col2n"
            />
        </g></g>
    )
}

export default ObjToy007
