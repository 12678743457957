import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#43A6FF",
    "col1s": "#00539F",
    "col2n": "#B4DBFF"
}

function ShiSpe132({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M142.65 11.5l-.25-.5-.9-1.5-.1-.2q-.25-.45-.5-.8-.15-.25-.25-.45L140.6 8q0-.05-.05-.1l-.3-.5q-.15-.25-.25-.45-.3-.5-.6-.95-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.15.1-.25.2l-.05.05-.8.6q-.55.4-.95.7-.5.4-.75.55L129 4.65q-9.45 6.55-20 10.45-4.4 1.65-8.95 2.8-8.15 2.1-16.9 2.75l-1.1.05q-2.2.15-4.4.2h-3.1q-3.2 0-6.3-.2l-1.1-.05q-13.65-1-25.85-5.55-10.55-3.9-20-10.45l-1.75-1.3-.3-.2q-.1-.1-.25-.2-.9-.7-1.8-1.35-.1-.1-.25-.2L15.1 0q-.1.05-.15.15Q12.1 4 9.6 8.15q-.2.3-.35.55-.85 1.45-1.65 2.9-1.05 1.9-2.05 3.9-.35.65-.65 1.3l-.1.15-.9 1.9h.05q-.1.15-.15.25l-.2.5-2.15 4.95-.3.7q-.4 1.05-.8 2.15-.2.45-.35.95l18.95 7.05v.05l-2.7 14q-.55 2.7-1.1 5.45L11.7 69.2q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 3.75.85 7.55 1.6Q79.2 95.15 136.45 82q2.1-.45 4.15-.95H140.75q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75 19.1-7.1q-.15-.35-.25-.7-.05-.15-.1-.25l-.65-1.6-.1-.3q-.05-.05-.05-.1t-.05-.15q-.1-.2-.15-.4l-.5-1.25q-.25-.6-.5-1.15-.15-.3-.3-.75-.2-.35-.35-.75-.1-.25-.2-.45-.25-.6-.5-1.15-.1-.1-.15-.25-.15-.4-.3-.7l-.75-1.55-.2-.4q-.2-.35-.35-.75-.1-.15-.2-.35v-.05q-.1-.1-.15-.25-.1-.25-.25-.5-.3-.55-.55-1.1-.55-1-1.1-1.95z"
                    id="ShiSpe132__Symbol_357_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M2.9 35.2v8.65q2.3 1.1 4.55 1.85 4 1.25 7.9 1.25 4 .05 7.9-1.15 2.2-.7 4.4-1.8v-8.8H2.9m1 8v-7h22.65v7.1q-10.9 5.4-22.35 0l-.3-.1m89.35.8v-8.8H68.5v8.65q2.3 1.1 4.55 1.85 4 1.25 7.9 1.25 4 .05 7.9-1.15 2.2-.7 4.4-1.8m-1.1-7.8v7.1q-10.9 5.4-22.35 0l-.3-.1v-7h22.65M96.2 0h-.1q-4.75 2.8-9.75 4.95L60.1 21.4q-5.2-7.1-11.35-8.85h-1.3q-6.2 1.75-11.4 8.85L9.9 5.05Q4.8 2.85 0 0l35.9 22.5q.15.1.35.05.2-.05.3-.2 5.05-6.95 10.9-8.75v67.1h1.2V13.55q5.9 1.8 10.95 8.8.1.15.3.2.2.05.35-.05L96.2 0z"
                    id="ShiSpe132__Symbol_358_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.3 14.9q0-.9-.65-1.55l-.15-.15q-.5-.4-1.2-.45h-.15q-.8 0-1.35.45-.1.05-.2.15Q0 14 0 14.9q0 .45.2.85.1.35.4.65.65.65 1.55.65h.15q.8-.05 1.35-.65.2-.2.35-.4.3-.5.3-1.1m42.5 26.85q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05M42.95 20.4q-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65M42.3 2.25q0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6 0-.95-.65-1.6Q45.5 0 44.55 0q-.95 0-1.6.65-.65.65-.65 1.6M69.9 14.9q0-.9-.65-1.55l-.15-.15q-.5-.4-1.2-.45h-.15q-.55 0-1 .25-.2.05-.35.2-.1.05-.2.15-.6.65-.6 1.55 0 .45.2.85.1.35.4.65.25.25.55.4.45.25 1 .25h.15q.8-.05 1.35-.65.2-.2.35-.4.3-.5.3-1.1z"
                    id="ShiSpe132__Symbol_359_0_Layer0_0_FILL"
                />
                <g id="ShiSpe132__Layer2_0_FILL">
                    <path
                        fill="#FFFF62"
                        d="M262.05 243.95l-5.25 1.95 2.9 6.9v.05q.14.235.3.45l.25.65.05.05q.34.578.8.95 1.667 1.415 4.95.5.466-.129.95-.35l-.3-.75q-.05-.11-.1-.25-3.943 1.248-5.3-1.2l-2.6-6.4 3.9-1.45q-.184-.369-.4-.75-.06-.171-.15-.35m1.45 3.05v-.05l-2.25.8 1.05 2.55 2.35-.8q-.074-.198-.2-.45-.218-.535-.5-1.1-.04-.12-.1-.25v-.05q-.175-.33-.35-.65m-143 2.05q-.1.2-.2.45l2.4.75 1-2.5-2.2-.85-.05.05q-.15.3-.3.7-.05.15-.15.25-.25.55-.5 1.15m1.9-4.05l3.85 1.45-2.55 6.4q-1.35 2.45-5.3 1.2l-.1.3-.3.75q.5.15.95.35 3.3.85 4.95-.5.45-.4.8-1v-.05l.3-.65q.15-.2.3-.45v-.05l2.9-6.9-5.25-1.95q-.1.2-.2.35-.15.4-.35.75z"
                    />
                    <path
                        fill="#00498A"
                        d="M262.8 245.45l-.2-.4-3.9 1.45 2.6 6.4q1.357 2.448 5.3 1.2-.029-.05-.05-.1-.02-.071-.05-.15-.083-.208-.2-.45l-.5-1.2q-.215-.577-.5-1.15-.105-.325-.25-.75l-.4-.8-2.35.8-1.05-2.55 2.25-.8-.7-1.5M122.4 245l-.2.4-.7 1.5 2.2.85-1 2.5-2.4-.75q-.15.4-.35.75-.15.45-.3.75-.25.55-.5 1.15l-.5 1.25q-.05.2-.15.4-.05.1-.05.15 0 .05-.05.1 3.95 1.25 5.3-1.2l2.55-6.4-3.85-1.45z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe132__Symbol_357_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSpe132__col1n"
            />
            <use
                xlinkHref="#ShiSpe132__Symbol_358_0_Layer0_0_FILL"
                transform="translate(144.45 236.95)"
                id="ShiSpe132__col1s"
            />
            <use
                xlinkHref="#ShiSpe132__Symbol_359_0_Layer0_0_FILL"
                transform="translate(157.5 263.15)"
                id="ShiSpe132__col2n"
            />
            <use xlinkHref="#ShiSpe132__Layer2_0_FILL" />
        </g></g>
    )
}

export default ShiSpe132
