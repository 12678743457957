import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.65 9.15L36.7 4.6 27.5.55q-3.95-1.05-6.75 0-.85.35-1.55.85l-.85.5-.85-.5q-.7-.5-1.55-.85-2.8-1.05-6.75 0L0 4.6l5.95 4.55h25.7z"
                    id="FacMou041__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M33.75.05l-3.4.15q-1.75.15-3.3.35-4.2.5-5.55 1.35-.25.2-1.45.45-.7.2-1.65.15h-.1q-.95.05-1.65-.15-1.2-.25-1.45-.45-1.35-.85-5.55-1.35Q8.1.35 6.35.2L2.95.05Q1.55 0 0 0q4.45 7.75 12.2 8.15 2.95-.4 6.1-.4h.1q3.15.05 6.1.4 7.75-.4 12.2-8.15-1.55 0-2.95.05z"
                    id="FacMou041__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou041__Symbol_66_0_Layer0_0_FILL"
                transform="translate(174.2 216.55)"
                id="FacMou041__col1n"
            />
            <use
                xlinkHref="#FacMou041__Symbol_19_0_Layer0_0_FILL"
                transform="translate(174.2 221.15)"
                id="FacMou041__col1l"
            />
        </g></g>
    )
}

export default FacMou041
