import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#480000"
}

function BodTai001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M84.75 2.65q-2.75 2-3.35 2.8-3.95 4.35-5.25 6.25-4.65 6.6-4.65 12.95 0 .05.05.7 0 .1.05.2-2.85 7.8-1.8 17.2.5 3.4 1.65 6.85.5 1.2 1.05 2.55.2.65.55 1.35 2.25 5.35 3.35 9l.45 1.6q.55 2.25.55 3.65v.3q.7 11.05-9.55 19.4-12 9.75-36.7 4.15l-1.25-.25q-1.3-.35-2.5-.7-3.8-1-11.5-4.2-4.9-2.1-9.3-3.55-.65-.2-1.2-.35-.8-.25-1.45-.35-3.1-.35-3.8 2.1-.35 1.15.2 2.2.7 1.35 2.8 2.5 1.55.9 3.8 2 14.75 8.05 34.35 9.5 2.75.3 5.4.3 10.5 0 20.3-4.35 1.9-.95 3.65-1.95 9.6-6.8 12.75-17.05 2.75-10.45-1.45-19.2l-.2-.45q-.35-.65-.75-1.4-.85-1.45-1.85-3.25-2.55-4.35-4-8.75-2.85-9.1-1.05-17.55.1-.2.3-.55l.5-.8q.5-.6 2.2-1.5.75-.6 1.4-1.55.4-.5 1.1-1.55.9-1.3 3.65-6.15 1.95-3.7 4.2-6.15.6-.65 2.75-2.9 1.9-2.1 1.9-3.3Q92.05 0 90.5 0q-2.1 0-5.75 2.65z"
                    id="BodTai001__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.2 7.25q-1 1.25-1.9 2.4-.15.15-.3.35Q11.4 24.25 9.1 32.55h.1q9.05-2.8 14.5-1.25Q26.1 13.35 33.15 0 15 5.75 0 18.65q5.35 4.2 6.7 12.55 2.45-7.8 15.55-21l.35-.35 2.5-2.5.1-.1z"
                    id="BodTai001__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai001__Symbol_29_0_Layer0_0_FILL"
                transform="translate(217.2 218.65)"
                id="BodTai001__col1n"
            />
            <use
                xlinkHref="#BodTai001__Symbol_16_0_Layer0_0_FILL"
                transform="translate(282.1 212.85)"
                id="BodTai001__col2n"
            />
        </g></g>
    )
}

export default BodTai001
