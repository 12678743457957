import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF2F23",
    "col2n": "#43A6FF"
}

function ObjToy077({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g
                    id="ObjToy077__Symbol_141_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M12.75 38.8q-.05-.1-.1-.15l.1.2v-.05z" />
                    <path d="M21.65 23.65q0-1.3.35-2.4-2.4-8.7-7.75-16.95-1.4-2.15-3.05-4.3L8.35 1.45l-2.05.7q-.15-.1-2.1 1Q2.2 4.2.85 7.5q-1.4 3.3-.5 5.4.1 1.45.65 2.85l4.3 8.5h6.85q.15.05.35.1.5.15 1 .45 2.1 1.2 4.05 4.5.25.5.55 1 0 .05.05.1 2.6 4.85 2.6 7.9 0 1.45-.35 2.1-1 1.85-4.45 1.85-.85 0-1.5-.1L15 43.2q1.7 2.65 4.55 3.9.5.25 1 .45 4.2 1.3 8.05-.65l.2-.1 4.95-2.5-1.25-2.5q-.8-6.2-5.55-10.85-.65-.25-1.25-.55-.65-.4-1.3-.9l-.5-.5q-2.25-2.25-2.25-5.35z" />
                </g>
                <g id="ObjToy077__Layer5_0_FILL">
                    <path
                        fill="#3C3C3C"
                        d="M248.9 300.25l-2.15 6.7q-.2.85-.25 1.65-.25 3.3 2.45 5.05.6-2.35 2.55-1.85l4.1-13.05q-1.2.4-2.55.4-1.95 0-3.55-.85-1.131.975-.6 1.95m10.4 1.6q-.5 0-.85.35t-.35.85q0 .15.05.35.05.25.3.5.35.35.85.35.45 0 .75-.25l.1-.1q.35-.35.35-.85t-.35-.85q-.35-.35-.85-.35m6.3-4.9q-.4-.75-1.2-1-.75-.25-1.55.15-.65.35-.9 1.15-.25.8.1 1.55.4.7 1.15.95.8.25 1.6-.1.05-.05.1-.05.5-1.15.9-2.15-.05-.25-.2-.5m-.5-7.95q-.65.35-.9 1.15-.25.75.05 1.55.45.7 1.2.95.8.25 1.55-.1.75-.45 1-1.2.25-.8-.15-1.5-.4-.8-1.2-1.05-.75-.25-1.55.2m-1.95 3.25q-.4-.75-1.15-1-.75-.2-1.35.1v.2q0 .55-.1 1.05 0 .3-.1.6-.15.75-.4 1.45l-.05.05q.3.3.8.45.75.25 1.5-.25.25-.1.45-.25.4-.3.55-.85.25-.8-.15-1.55m-2.2-24.95q-1.85 1-2.85 3.6l-1 3.1q-2.113 3.6-2.25 7.2l-.9 2.8q2.6.3 4.5 2.15.45.45.8.95l6.3-20.05q-2.75-.75-4.6.25m7.15 4.9l-2.7-.85-.35 1 2.7.85.35-1m-143.7 29.2l1.15 2.25 2.3-1.15-1.15-2.25-2.3 1.15m12.4-1.45l-.05-.15q.27-.9-.95-1.5.067-.045.1-.1v-.05q-.7.4-1.4.65-1.2.4-2.55.4-1.2 0-2.3-.3l5.55 10.85q1.8-.75 2.75 1.4 2.5-2.25 1.5-5.7-.2-.6-.45-1.2l-2.2-4.3m-10.05-19.75q-.306-3.73-3.45-6.75l-1.45-2.9q-2.95-4.9-7.95-2.6l10.8 21.25q.55-1.65 1.9-3 1.1-1.05 2.35-1.6l-2.2-4.4m-18.05 2.15l-.2.1q-.8.4-.95 1.2-.25.8.1 1.5.4.75 1.15 1 .75.3 1.55-.1.15-.1.25-.2.5-.3.7-1 .3-.75-.1-1.5-.35-.7-1.15-1-.7-.2-1.35 0m5.45-2.1q-.2.8.15 1.5.4.75 1.1 1 .85.3 1.55-.05.15-.1.2-.2.6-.3.8-1.05.3-.75-.1-1.5-.35-.7-1.15-1-.6-.15-1.2 0-.15 0-.4.15-.7.35-.95 1.15m.9 3.7q-.8-.2-1.55.15-.75.4-1 1.15-.15.65.05 1.25.05.1.1.3.4.75 1.15 1 .75.2 1.5-.2.75-.35 1.05-1.1.25-.75-.15-1.5-.35-.8-1.15-1.05m-2.2-5.6q-.1-.2-.2-.25-.35-.6-.95-.8-.85-.25-1.6.1-.75.4-.95 1.2-.25.8.15 1.55.35.7 1.1 1 .75.25 1.5-.15.75-.35 1.05-1.15.25-.8-.1-1.5m1.9-4.05l-.4-.75.85-.45-.45-.95-.85.45-.45-.8-.9.45.45.8-.75.4.45.95.75-.4.4.75.9-.45z"
                    />
                    <path
                        fill="#4A4A4A"
                        d="M267.6 293.65q-.4.25-.65.6.25-.3.7-.6h-.05z"
                    />
                    <path d="M264.55 281q0 .85.35 1.65.05.2.15.35.85 1.5 2.5 2.05 1.65.5 3.25-.25 1.4-.75 1.95-2.15.05-.15.1-.35.2-.65.25-1.3 0-1-.5-1.95-.85-1.6-2.5-2.1-1.65-.55-3.25.3-1.55.85-2.1 2.5-.2.65-.2 1.25m4.25-13.8l-.25.8 2.2.7q.15 0 .25.05 4.15 1.3 6.15 5.1 1.1 2 1.2 4.15l.95-.15q-.05-1.8-.75-3.5-.2-.5-.45-.95-2.05-3.95-6.2-5.25-.1 0-.2-.05l-2.9-.9m-148.7 23.25q-.6-.1-1.25-.1-.95-.1-2 .45-.9.45-1.5 1.25-.1.1-.15.25.5.15 1 .45 1.85 1.05 3.65 3.8l.4.7q.25.5.55 1 .95-.55 1.45-1.3.15-.2.25-.4.2-.35.35-.7.1-.45.15-.9.1-1.15-.5-2.3-.4-.8-1.05-1.35-.55-.5-1.35-.85m-9.4-21.75l-2.75 1.4q-.05 0-.2.1-3.85 1.95-5.2 6.1-.75 2.35-.5 4.6l1-.05q-.2-2.05.45-4.2 1.4-4.15 5.25-6.1l.2-.1 2.1-1.05-.35-.7z" />
                    <path
                        fill="#FF2F23"
                        d="M115.35 306.6l.1.2v-.05q-.05-.1-.1-.15z"
                    />
                </g>
                <g id="ObjToy077__Layer5_1_FILL">
                    <path d="M135.9 298.2q-.033.055-.1.1l.95 1.5.05.15 1.4-.8q-.567-1.012-1-1.85-.54.42-1.3.9m-9.15-18l1.35-.7-3.45-6.75-1.35.7 3.45 6.75M119 270l-.75.4-.45-.8-.9.45.45.8-.85.45.45.95.85-.45.4.75.9-.45-.4-.75.75-.4-.45-.95z" />
                    <path
                        fill="#3C3C3C"
                        d="M117.35 291.8q-.7.35-1.15.95 1.85 1.05 3.65 3.8l.4.7q.5-.3.9-.7l.2-.3q.15-.4.35-.75 0-.25.1-.45.15-1-.3-1.9-.35-.7-.95-1.1-.35-.25-.85-.45-.25 0-.4-.1-1.05-.15-1.95.3z"
                    />
                </g>
                <g id="ObjToy077__Layer5_2_FILL">
                    <path d="M248.2 297.4l-.75 2.4 1.45.45.6-1.95q-.65-.4-1.3-.9m5.2-16.65l1.45.45 2.25-7.2-1.45-.45-2.25 7.2m7.45-10.85l-.35 1 2.65.85.35-1-2.65-.85z" />
                    <path
                        fill="#3C3C3C"
                        d="M265.8 281q-.05.75.3 1.45.05.1.15.2.6.95 1.65 1.3 1.25.35 2.3-.2.85-.45 1.25-1.1.25-.35.35-.75.15-.45.15-.9 0-.7-.45-1.4-.5-1.2-1.75-1.55-1.15-.4-2.35.25-1.1.55-1.5 1.75-.15.5-.1.95z"
                    />
                </g>
                <path
                    fill={colors.col2n}
                    d="M27.65 10.95q.5-2.15-1.35-5.25t-3.8-3.6q-2-.55-2.65-.9-.7-.3-2-.25l-3-.95q-1.2 1.75-2.15 3.7h.1l-.35 1-.2-.05q-3.3 6.85-3.7 15.4 1.35 1.85 1.4 4.25v.2q0 .55-.1 1.05 0 .3-.1.6-.15.75-.45 1.5-.55 1.2-1.55 2.2-1.3 1.3-2.85 1.85Q.4 36.8.8 44.75L0 47.4l5.2 1.65q.15 0 .25.05 4.15 1.3 7.95-.7.5-.25.95-.55 2.65-1.7 3.9-4.8-.05-.05-1.6-.15-1.55-.1-2.4-1.75-.85-1.7-.85-4.3 0-2.4.75-4.15v-.05l1.2-2.95q.35-1.1.6-1.5 0-.05.1-.15 2.75-3.45 7.45-4.25.45-.15.85-.2l3.1-9.8q.3-1.5.2-2.85z"
                    id="ObjToy077__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy077__Symbol_141_0_Layer0_0_FILL"
                transform="translate(102.7 267.95)"
                id="ObjToy077__col1n"
            />
            <use
                xlinkHref="#ObjToy077__Symbol_53_0_Layer0_0_FILL"
                transform="translate(250.7 267.05)"
                id="ObjToy077__col2n"
            />
            <use xlinkHref="#ObjToy077__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy077__Layer5_1_FILL" />
            <use xlinkHref="#ObjToy077__Layer5_2_FILL" />
        </g></g>
    )
}

export default ObjToy077
