import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D70076"
}
function AccMor096({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.2 12.75v.05q-.4-3.196-1.95-5.75-3.184-6.787-9.15-7-.632-.08-1.2 0-.351.034-.7.1-2.61.438-4.25 2.3h.1q-1.9 2-1.7 5.55v.05q.05 1 .2 2.5-1.55-.05-2.65.2l.1-.1q-3.5.3-5.25 2.4H1.7Q-.1 15.1 0 18.15v.05q-.025.726.2 1.5 1.348 6.513 9.85 8.5 1.949.55 4.1.55h.05q4.85.25 8.45 1.1 3.85.9 11.6 4.1.4.2.85.1.4-.1.75-.45.3-.4.35-.75.05-.45-.2-.85-4.3-7.35-5.6-10.95h-.05q-1.4-3.55-2.15-8.3m-3.6 1.05h.05q.7 4.55 2.05 8v-.05q.95 2.45 3.4 6.95-4.7-1.9-7.35-2.5-3.45-.85-8.1-1h-.05q-.623 0-1.25-.05-.154-.019-.35-.05l-.4-.05q-7.581-1.109-8.1-6.5-.034-.166-.05-.35-.05-1.75.9-2.9 1-1.1 3.05-1.25h.05q1.1-.191 2.75-.05l2.25 2q.139.104.3.15l.15.15q.4.25.95.25.5 0 .85-.4.3-.4.3-.9-.04-.4-.3-.7-.094-.394-.35-.65L13 11.8q-.204-1.704-.25-2.75-.1-2.05.85-3.15.658-.72 1.55-1.1 5.114-2.002 8.4 5.65.78 1.544 1.05 3.35z"
                    id="AccMor096__Symbol_213_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor096__Symbol_213_0_Layer0_0_FILL"
                transform="translate(230.3 80)"
                id="AccMor096__col1n"
            />
        </g></g>
    )
}
export default AccMor096
