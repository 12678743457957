import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212",
    "col2n": "#FF008C"
}
function HaiHig037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M124.6 45.05q.05-1 .05-2.05 0-8.85-3.65-16.35-1.65-3.4-4-6.55-4.1-5.5-9.7-9.4.7.65 1.4 1.85-1.65-1.05-1.75-1.1-4.75-3.3-10.5-5.35-.8-1-1.5-1.55-2.1-.7-4.25-1.25-1.35-.35-2.75-.65.6.6 1.2 1.35-.6-.2-1.2-.35-1.05-.2-2.05-.4l-1-.2q-1.05-.2-2.05-.35-.65-.65-1.3-1.2-4.15-.65-8.3-1 .1.6.25 1.15-.15-.1-.25-.15-6.3-.6-10.5-.5l-.2.1q.1-.6.2-1.1-4 .05-5.8.15l-.3 1q-1.05.05-3.7-.1.15-.4.35-.7-3.5.25-6.95.75-.95.5-1.95 1.3-7.55 1.1-13.9 3.35-1.95.8-3.65 1.6 1.7-1.6 3.65-2.6-5.85 2-10.65 4.85Q13.6 13.3 9 18.4q-3.55 4.2-5.7 8.9Q0 34.55 0 43q0 10.05 4.7 18.35.5.95 1.1 1.9.25.35.5.75.1.1.15.2l.5.7q.05.1.15.25.05.05.1.15.25-.15.55-.3l1.1 1.4Q20.9 58 35.1 53.95q.55-.2 1.1-.35 9.7-2.65 20.4-3.35h.1q2.1-.15 4.2-.15 15-.4 28.6 3.4.1 0 .2.05.5.15 1.05.35 13.15 3.95 24.95 11.95.15.1.35.25.45-.6.95-1.2.2.15.5.35l.05-.05.6-.9q.7-1 1.3-2.05 1.45-2.4 2.5-4.95.55-1.4 1-2.85 1.4-4.5 1.65-9.4M51.2 11.5q.05-.05.05.1-.05 1.05-.05 2.2-.05-1.25 0-2.3m-20.45.4l.3-.2q-.15.15-.3.25v-.05z"
                    id="HaiHig037__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M82.05 10.85v.2q.5 4.55.7 8.75.05.45.05.9 0 .2.05.45.65 13.3-1 22.75-1.2-8.5-1.45-14.7-.25-6.25-6.3-29.2 0 15-.05 16.1-1.75 20.5-3.15 27.1-.65-1.35-2.4-8.15-1.75-6.8-5.65-18.6v-1.2l-.05 1.5q-3.1 9.75-3.15 24.45-5-15.45-5.45-35.6-1.55 29.25-3 36.75l-.05-.2Q48.55 39 47 31q-1.5-7.25-2.45-15.9-1.2 7.3-2.6 16.5-.9 5.9-1.2 11.65-1.6-9.6-1.95-16.4Q38.45 20 43.65.65q-9.6 19.7-11.15 28.05-1.4-9.15.65-13.45-3.55 6.25-6 13.4-3.25 9.55-4.05 19-.05-.1-.05-.15Q21.1 42.35 20.7 36q-.3-4.35-.05-7.95.1-2.65 1-10.6-3.4 5.3-6.5 14.85T13.2 52.25q-2.25-2.35-3.35-8.2t.8-20.35q-6 11.1-6 14.5v3.95q-1.4-5.8 0-11.8Q6.1 24 6.8 22.55q.65-1.4 4.75-9.75-4.5 4.4-7.7 10.4Q.6 29.25.1 38.9q-.5 9.65 2.3 20.25.2.8.5 1.55.1.25.2.55l.1.35q.05.1.15.25.05.05.1.15.25-.15.55-.3l1.1 1.4q12.05-8.4 26.25-12.45.55-.2 1.1-.35 9.7-2.65 20.4-3.35h.1q2.1-.15 4.2-.15 15-.4 28.6 3.4.1 0 .2.05.5.15 1.05.35 13.15 3.95 24.95 11.95.15.1.35.25.45-.6.95-1.2.2.15.5.35l.05-.05.6-.9q.7-1 1.3-2.05l.1-.4q.1-.45.3-1.85 4.1-11.6 1-25.8-1.9-8.7-5.9-13.1 3.3 11.8 2.65 18.8-.7 7-1.3 9.35-.65 2.3-1.2 3.85-.6 1.55-1.8 6.7-.05-6.25-.3-9.9-.3-3.7-.85-7-.6-3.35-3.6-11.35.2 9.45-1.45 14.95-1.85-8.4-3.1-13.7-1.95-8.15-8.2-23.3 2.7 13.5 3.45 18.75.2 1.8.7 9.25.4 6.3.25 7.85-.15 1.05-.4 7.2-3.15-12.9-5.35-20.45-2.2-7.6-8.65-17.95z"
                    id="HaiHig037__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M108.85 18.85q.6-.8 1.2-1.55.1-.15.25-.3-1.75-1.15-1.8-1.2-11.8-8-24.95-11.95-.55-.2-1.05-.35-.1-.05-.2-.05Q68.7-.35 53.7.05q-2.1 0-4.2.15h-.1Q38.7.9 29 3.55q-.55.15-1.1.35Q15.75 7.35 0 17.05q.8 1.05 1.65 2.1Q13.7 10.75 27.9 6.7q.55-.2 1.1-.35Q38.7 3.7 49.4 3h.1q2.1-.15 4.2-.15 15-.4 28.6 3.4.1 0 .2.05.5.15 1.05.35Q96.7 10.6 108.5 18.6q.15.1.35.25z"
                    id="HaiHig037__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M36.25.35q-1.5 2.5-2.05 10.25-.55 7.7 2.5 23.15-.55-16.55 1.65-28.45.9-2.9 1.55-5.15-1.8.05-3.65.2m-6.95.75q-.45 0-.85.1-8.2 1.15-15 3.55Q3.4 10 0 30.9q3-9 6.3-12.95 4.15-5.15 7.7-7.25-3 11.6-3.85 26.3 4.45-20.6 9.9-27.25Q25.5 3.1 29.3 1.1m28.2 19q.35-5.6-.1-10.15-.45-4.6-.6-6-.2-1.4-.6-3.45-6.3-.6-10.5-.5-.6 2.8-.85 8.25-.3 5.45.4 12.95 1.4-11.3 4.85-17.8 1.3 2.9 3.1 10.55 1.75 7.65 2.25 12.5L57.1 37.2q0-11.55.4-17.1M67.85 2.05q-1.7-.3-3.35-.55 7.75 6.9 13.65 24.3-1.3-7.45-1.45-9.2-1.45-9.85-5.8-13.95-1.05-.2-2.05-.4l-1-.2m30.2 38.2q-1.8-13.2-3.4-19.55-1.95-7.7-4.4-10-.2-.15-.35-.25-5.35-3.7-12-5.9 3.05 2.4 6.8 11.3 3.75 8.85 4.65 15.4 0-8.75-1.1-15.35 2.8 6.5 6.1 14.2 2.05 4.75 3.7 10.15z"
                    id="HaiHig037__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig037__Symbol_21_0_Layer0_0_FILL"
                transform="translate(130.2 7.2)"
                id="HaiHig037__col1n"
            />
            <use
                xlinkHref="#HaiHig037__Symbol_20_0_Layer0_0_FILL"
                transform="translate(133.95 10.5)"
                id="HaiHig037__col1d"
            />
            <use
                xlinkHref="#HaiHig037__Symbol_18_0_Layer0_0_FILL"
                transform="translate(137.4 55.45)"
                id="HaiHig037__col1s"
            />
            <use
                xlinkHref="#HaiHig037__Symbol_19_0_Layer0_0_FILL"
                transform="translate(147.25 7.2)"
                id="HaiHig037__col2n"
            />
        </g></g>
    )
}
export default HaiHig037
