import { AssetSvgProps } from "../../../shared/assets"

function ObjToy054({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M408.6 292.4q1.376.46 2.8 1 20.71 8.038 51.4 35.5l.05.05q34.486 34.966 87.15 37.15 2.16.114 4.35.15.2 0 .35-.15.15-.15.15-.35 0-.2-.15-.35-.15-.15-.35-.15-2.19-.036-4.35-.15-52.264-2.184-86.5-36.9-31.162-27.862-52.1-35.85-1.269-.489-2.5-.9-21.9-7.35-32.15 8.1-.05.05-.05.1l-1.15-.35q-1-.15-1.4.7l-1.85 3.7-.05.05q1.068 2.58 3.7 1.25h.05l2.55-3.45q.6-.75-.3-1.4l-.6-.2q9.95-14.6 30.95-7.55z"
                    id="ObjToy054__Layer14_0_FILL"
                />
                <path
                    fill="#5F5F5F"
                    d="M367.35 374.8q3.05 1.3 4.9-.65l.35-.45 3.05-7.15-2.9-1-3.2 6.85-100.6-38.35-2.65-8.5-.05-.1-3.15-1.1 2.6 7.9q.3 3.45 3.5 4.65l97.8 37.75q.2.1.35.15m28.9-36.25l4.95-18.65-97.35-33.55-10.8 15.4 103.2 36.8m-28.4-23.7q.5-.45 1.15-.5.8-.1 1.4.4.65.5.7 1.3.05.35-.05.7-.05.3-.25.55-.05.1-.1.15l.05.05-2.05 4.55q-.1.3-.35.6-.5.65-1.3.7-.8.1-1.4-.4-.65-.5-.7-1.3-.1-.6.2-1.1l.2-.3-.05-.05.85-1.85-.6-2.25q0-.3.25-.65.35-.45 1-.6.55-.15 1.05 0m9.85 3.5q.45-.45 1.15-.5.8-.1 1.4.4.6.5.7 1.3.05.4-.05.7-.1.3-.25.55-.05.1-.15.2h.05l-2 4.55q-.1.35-.4.6-.5.65-1.25.75-.8.05-1.45-.45-.6-.5-.7-1.3-.05-.6.25-1.1.05-.15.2-.3l-.05-.05.8-1.85-.6-2.25q0-.3.3-.65.35-.4.95-.6.6-.15 1.1 0m-18.2.25q-1.35 1.75-3.85.9l-22.35-8.05q-2.35-1.2-1.4-3.3l1.55-2.9q1.45-2.55 4.45-1.1l21.85 7.7q2.3.65 1.65 3.35l-1.9 3.4m-30.35-15.5h.05l-2.05 4.55q-.1.35-.35.65-.5.6-1.3.7-.8.05-1.4-.45-.65-.5-.7-1.3-.05-.6.2-1.1l.2-.3-.05-.05.85-1.85-.6-2.2q0-.35.25-.7.35-.4 1-.6.6-.15 1.1.05.45-.5 1.1-.55.8-.05 1.45.4.6.55.7 1.3 0 .4-.05.7-.1.3-.25.55-.1.1-.15.2m-12.65-6.15q.45-.5 1.15-.55.8-.05 1.4.4.6.55.7 1.3 0 .4-.05.7-.1.3-.25.55l-.1.2-2 4.55q-.15.35-.4.65-.5.6-1.3.7-.8.05-1.4-.45-.3-.25-.45-.55-.2-.35-.25-.75-.05-.6.25-1.1.05-.15.2-.3l-.1-.05.85-1.85-.6-2.2q0-.35.25-.7.4-.4 1-.6.6-.15 1.1.05m1.45 3.65q-1.15 2.7-2.7 1.55l.45 1.75q.3.4.8.3l1.45-3.6m9.85 3.55q-1.2 2.7-2.75 1.55l.5 1.75q.3.4.8.3l1.45-3.6m49.1 21.2q.35.4.8.3l1.45-3.6q-1.15 2.7-2.7 1.55l.45 1.75m-7.55-6.75q-1.2 2.65-2.75 1.5l.45 1.75q.35.4.8.25l1.5-3.5z"
                    id="ObjToy054__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    fill="#915D2B"
                    d="M372.9 365.6l2.55-2.95L269.6 322.6l-3.25 2.9 106.55 40.1z"
                    id="ObjToy054__Layer5_0_MEMBER_2_FILL"
                />
                <path
                    fill="#FFF"
                    d="M363.95 363l-.1 2.7q-.1 2.45 1.05 3.8.6.6 1.5 1.05.15.05.3.15l.6.25v-1.1l-.45-.2-.15-.05q-.05-.05-.1-.05-1.3-.75-1.65-2.15-.1-.6-.05-1.35l.05-2.7-1-.35m-2.55-1l-.95-.35-.15 2.6q0 .75-.15 1.2-.4 1.1-1.65.8l-.1-.05-.55-.2-.05 1 .55.25.3.1q.8.25 1.4.1 1.15-.35 1.25-2.8l.1-2.65m1.8.7l-.95-.4-.3 6.4 1.05.45.2-6.45z"
                    id="ObjToy054__Layer5_0_MEMBER_3_FILL"
                />
                <path
                    fill="#CCC"
                    d="M316.55 296.95h-.05q-.5-.2-1.1-.05-.6.2-1 .6-.25.35-.25.7l.15.45q.15.25.55.4.2.1.5.1.3.05.65-.05.6-.15.95-.6.3-.3.3-.65l-.15-.45q-.15-.3-.55-.45m8.7 3.5q-.65.2-1 .6-.25.35-.25.7l.1.45q.2.25.55.4.25.1.5.1.35.05.65-.05.65-.15 1-.6.25-.3.25-.65l-.1-.45q-.15-.3-.55-.45h-.05q-.5-.2-1.1-.05m52.5 17.95q-.05 0-.05-.05-.5-.15-1.1 0-.6.2-.95.6-.3.35-.3.65l.15.45q.15.3.55.45.25.1.5.1.3.05.65-.05.6-.2.95-.6.3-.3.3-.65l-.1-.45q-.2-.3-.6-.45m-9.85-3.5q0-.05-.05-.05-.5-.15-1.05 0-.65.15-1 .6-.25.35-.25.65l.1.45q.15.3.55.45.25.1.5.1.3 0 .65-.1.65-.15 1-.6.25-.3.25-.6l-.1-.45q-.2-.35-.6-.45z"
                    id="ObjToy054__Layer5_0_MEMBER_4_FILL"
                />
                <g id="ObjToy054__Layer5_0_FILL">
                    <path
                        fill="#333"
                        d="M367.35 374.8q3.05 1.3 4.9-.65l37.95-44.35 4-8.65-5.5-1.85 2 2.65-14.45 16.6-103.2-36.8 6.75-9.6-36.7 32.2 2.6 7.9q.3 3.45 3.5 4.65l97.8 37.75q.2.1.35.15m-101.1-49.35l.1.05-.05.05-.05-.1M376 305h-.05l-3.7-1.25.05-.05-63.25-21.65-5.2 4.3 97.35 33.55 4.35-4.8L376 305m22.9 16.1q-.1-.15-.25-.15l-93.45-32.7q-.15-.05-.25 0-.15.05-.25.15l-8.55 12q-.1.15-.1.25 0 .15.1.25.05.1.2.15l98.15 34.7q.1.05.2 0 .1 0 .2-.1.05-.05.1-.2l3.9-14.05q.05-.15 0-.3m-33.55-.85l-.05-.05h.05v.05m1.25-.15q1.55 1.15 2.75-1.5l-1.5 3.5q-.45.15-.8-.25l-.45-1.75m10.3 5.25l-.45-1.75q1.55 1.15 2.7-1.55l-1.45 3.6q-.45.1-.8-.3m-50.55-17.6q-.5.1-.8-.3l-.5-1.75q1.55 1.15 2.75-1.55l-1.45 3.6m-9.85-3.55q-.5.1-.8-.3l-.45-1.75q1.55 1.15 2.7-1.55l-1.45 3.6z"
                    />
                    <path
                        fill="#191919"
                        d="M401.2 319.9l-4.95 18.65 14.45-16.6-2-2.65-3.15-4.2-4.35 4.8z"
                    />
                </g>
                <g id="ObjToy054__Layer5_0_MEMBER_1_FILL">
                    <path
                        fill="#5D3610"
                        d="M369.55 372.4l3.2-6.85q-51.348-23.223-106.4-40.05l-.05.05 2.65 8.5 100.6 38.35z"
                    />
                    <path d="M372.9 365.6l2.75.95 38.55-45.4-5.5-1.85 2 2.65-14.45 16.6-103.2-36.8 6.75-9.6-36.7 32.2 3.15 1.1.1.05 5.15-4.6 105.85 39.5-4.45 5.2m22.6-26.2l-1.6 1.8-103.45-37.15 1.75-1.55 103.3 36.9m-2.3 2.65l-1.55 1.8-103.8-37.5 1.75-1.55 103.6 37.25m-2.25 2.65l-1.55 1.75-104.1-37.8 1.75-1.55 103.9 37.6m-2.3 2.6h.05l-1.55 1.8-104.45-38.15 1.75-1.55 104.2 37.9m-2.25 2.6l-1.5 1.8-104.8-38.45 1.75-1.55 104.55 38.2m-2.25 2.65l-1.55 1.75-105.1-38.75 1.75-1.55 104.9 38.55m-2.25 2.6l-1.55 1.8-105.45-39.1 1.75-1.55 105.25 38.85m-2.25 2.65l-1.55 1.75-105.75-39.4 1.75-1.55 105.55 39.2M359 314.15l1 3.55 1.4-2.5q.65-2.7-1.65-3.35l-21.85-7.7q-3-1.45-4.45 1.1l-1.55 2.9q-.95 2.1 1.4 3.3l1.5.55-.6-1.8 2.55-4.15 22.25 8.1z" />
                    <path
                        fill="#E0852D"
                        d="M398.9 321.1q-.1-.15-.25-.15l-93.45-32.7q-.15-.05-.25 0-.15.05-.25.15l-8.55 12q-.1.15-.1.25 0 .15.1.25.05.1.2.15l98.15 34.7q.1.05.2 0 .1 0 .2-.1.05-.05.1-.2l3.9-14.05q.05-.15 0-.3m-.85.45l-3.7 13.3-97.2-34.4 8.05-11.3 92.85 32.4z"
                    />
                    <path
                        fill="#999"
                        d="M317.25 297.85q0 .356-.35.65-.3.333-.8-.35-.45-.633-.75.15-.3.844-.5.75-.4-.15-.55-.4l.45 1.75.5 1.75q1.55 1.15 2.7-1.55l-.7-2.75m7.4 4.75q-.35-.15-.55-.4l.5 1.75.45 1.75q1.55 1.15 2.75-1.55l-.75-2.75q.005.344-.35.35-.366.018-.85.25-.434.282-.7.5-.255.206-.5.1M377 319.7q-.516-.042-.6.4-.095.497-.35.4-.4-.15-.55-.45l.45 1.8.5 1.75q1.55 1.15 2.7-1.55l-.7-2.75q-.005.353-.45.4-.434.042-1 0m-7.65-1.1v-.1l-.75-2.7q.002.296-.55.2-.555-.04-.95.25-.395.34-.55.6-.102.253-.35.15-.4-.15-.55-.45l.5 1.8.45 1.75q1.55 1.15 2.75-1.5z"
                    />
                    <path
                        fill="#191919"
                        d="M319.05 296.8q-.6-.45-1.4-.4-.7.05-1.15.55h.05q.4.15.55.45l.15.45.7 2.75-1.45 3.6q-.5.1-.8-.3l-.45-1.75-.5-1.75-.85 1.85.1.05q-.15.15-.2.3-.3.5-.25 1.1.05.4.25.75.15.3.45.55.6.5 1.4.45.8-.1 1.3-.7.25-.3.4-.65l2-4.55.1-.2q.15-.25.25-.55.05-.3.05-.7-.1-.75-.7-1.3m10.5 5.55q.05-.3.05-.7-.1-.75-.7-1.3-.65-.45-1.45-.4-.65.05-1.1.55h.05q.4.15.55.45l.1.45.75 2.75-1.45 3.6q-.5.1-.8-.3l-.5-1.75-.45-1.75-.85 1.85.05.05-.2.3q-.25.5-.2 1.1.05.8.7 1.3.6.5 1.4.45.8-.1 1.3-.7.25-.3.35-.65l2.05-4.55h-.05q.05-.1.15-.2.15-.25.25-.55m48.15 16q0 .05.05.05.4.15.6.45l.1.45.7 2.75-1.45 3.6q-.45.1-.8-.3l-.45-1.75-.5-1.75-.8 1.85.05.05q-.15.15-.2.3-.3.5-.25 1.1.1.8.7 1.3.65.5 1.45.45.75-.1 1.25-.75.3-.25.4-.6l2-4.55h-.05q.1-.1.15-.2.15-.25.25-.55.1-.3.05-.7-.1-.8-.7-1.3t-1.4-.4q-.7.05-1.15.5m-9.8-3.45q.4.1.6.45l.1.45.75 2.7v.1l-1.5 3.5q-.45.15-.8-.25l-.45-1.75-.45-1.75-.85 1.85h.05v.05l-.2.3q-.3.5-.2 1.1.05.8.7 1.3.6.5 1.4.4.8-.05 1.3-.7.25-.3.35-.6l2.05-4.55-.05-.05q.05-.05.1-.15.2-.25.25-.55.1-.35.05-.7-.05-.8-.7-1.3-.6-.5-1.4-.4-.65.05-1.15.5.05 0 .05.05z"
                    />
                    <path
                        fill="#5F5F5F"
                        d="M357.05 318.3l1.95-4.15-22.25-8.1-2.55 4.15 22.85 8.1z"
                    />
                    <path
                        fill="#333"
                        d="M359 314.15l-1.95 4.15-22.85-8.1.6 1.8 20.85 7.5q2.5.85 3.85-.9l.5-.9-1-3.55z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy054__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy054__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy054__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy054__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy054__Layer5_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy054__Layer5_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjToy054__Layer5_0_MEMBER_4_FILL" />
        </g></g>
    )
}

export default ObjToy054
