import { AssetSvgProps } from "../../../shared/assets"

function ShiMot045({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 257.8v35.6h66.25v-35.6H159.2z"
                    id="ShiMot045__Layer5_0_FILL"
                />
                <g id="ShiMot045__Layer5_1_FILL">
                    <path
                        fill="red"
                        d="M225.45 257.8h-4.9l-21.8 11.95h4.65l22.05-11.95m-17.15 24h-4.9l21.45 11.6h.6v-2.35l-17.15-9.25m-22.35 0h-4.75l-21.4 11.6h4.95l21.2-11.6M159.2 258v2.5l16.85 9.25h4.85L159.2 258m0 14.05v7.2H189v14.15h6.6v-14.15h29.85v-7.2H195.6V257.8H189v14.25h-29.8z"
                    />
                    <path
                        fill="#0000A4"
                        d="M225.45 289.65v-7.85H210.9l14.55 7.85m0-19.9v-8.2l-15 8.2h15M186.8 293.4v-10.75l-19.75 10.75h19.75m11-10.65v10.65h19.8l-19.8-10.65m0-24.95v10.9l20.05-10.9H197.8m-11 10.9v-10.9h-20.1l20.1 10.9m-27.6-6.7v7.75h14.4L159.2 262m0 19.8v7.9l14.5-7.9h-14.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot045__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot045__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot045
