import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#411F00",
    "col1n": "#773900",
    "col2d": "#200F00",
    "col2n": "#411F00"
}

function AccHat039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M250.15 32.5q-1.65-6.2-3.65-12.25-1-3-2.75-5.65-.05-.1-.1-.15-3.5-3.95-4.05 0 0 1.05 1 8.3.9 7.2 1.6 14.15.7 6.9 1.2 10.3.45 3.4.75 9.15.3 5.7-1.45 11.8-.25.75-.5 1.45-.3.95-1.05 1.4v.05h-.1q-.35.2-.8.25-1.55.3-2.75.2v-.05l-.75.05-.05-.15-6.55-15.3h-3.7l-18.9-33.95q-.2-.4-.35-.85-.4-.55-.7-1.1-4.35-6.6-9.35-12.75-.3-.35-.55-.7-4.05-3.85-9.4-5.5-7.85-2.35-18.5 0-.7.15-1.4.35-.55.1-1.05.25-6.35 1.65-13.65 4.9-.75.35-1.45.75L136.65 15q-6-1.85-15.45-5.3-8.8-3.2-18.05-2.45Q97.9 7.7 92.5 9.4q-4.1 1.3-8.25 3.3-.1.05-.15.1-7.65 4.8-11.95 12.85L60.7 69l-1.1.1-3.95 1.1-6 17.6q-1.1.1-2.2.25-2.7.3-10.15.95-7.55.65-15.8 1.6-8.3.95-11.25 1.75-2.95.75-6.65 2-.2.05-.35.15-1.55.55-3.1 2-.85.5 3.4 1.35 4.25.8 11.45.4 7.2-.4 9.8-.35 77.85-1.5 213.15-16.2.5-.05 1.05-.1 7.3-.95 11.15-5.35 6.4-13.15 0-43.75z"
                    id="AccHat039__Symbol_257_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M28.05 24.05q2.05-4.85 4-7.9 2.3-3.65 7.85-6.8-4.1 1.3-8.25 3.3-.1.05-.15.1-7.65 4.8-11.95 12.85L8.1 68.95l-1.1.1-3.95.45L0 79.15l13.3-1.1 1.35-6.3 1.2-.1q4.35-26.2 10.9-44.5.6-1.55 1.3-3.1m85.6-22.3Q107.3 3.4 100 6.65q-.75.35-1.45.75l-14.5 7.55q-6-1.85-15.45-5.3-8.8-3.2-18.05-2.45Q63 6.65 84.65 20.15q13-13.75 29-18.4m67.3 62.1l-3.4-7.85h-3.7l-18.9-33.95q-.2-.4-.35-.85-.4-.55-.7-1.1-4.35-6.6-9.35-12.75-.3-.35-.55-.7-7.2-6.9-18.75-6.65 8.35 1.65 15.9 12.35.9 1.05 1.9 2.3Q156 34.4 167.7 58.4l1.8-.15 1.65 6.45 9.8-.85z"
                    id="AccHat039__Symbol_166_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat039__Symbol_80_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M187.05 15.45v-.15L180.5 0h-3.7q-3.1.2-6.15.4h-.15Q94.45 4.9 18.1 12.3q-3.55.3-7.05.65l-1.1.1L6 14.15l-6 17.6 105.55-9.2q25.5-1.95 36.9-2.75 11.4-.85 18.6-1.55 6.65-.7 13.2-1.7 3.5-.4 5.3-.55 3.65-.35 4.1-.35 1.7-.05 3.4-.2zM187.05 15.3v.15h.05l-.05-.15z" />
                </g>
                <g
                    id="AccHat039__Symbol_22_0_Layer0_0_FILL"
                    fill={colors.col2d}
                >
                    <path d="M21.65 29.85l3.7-18.25q-1.75.15-3.45.3-5.45.5-10.85 1.05l-1.1.1L6 14.15l-6 17.6 21.65-1.9m162-14.2q1.7-.05 3.4-.2v-.15L180.5 0h-3.7l-15 .9 6.1 16.55q3.15-.45 6.35-.9 3.5-.4 5.3-.55 3.65-.35 4.1-.35z" />
                    <path d="M187.1 15.45l-.05-.15v.15h.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat039__Symbol_257_0_Layer0_0_FILL"
                transform="translate(45.35 28.55)"
                id="AccHat039__col1n"
            />
            <use
                xlinkHref="#AccHat039__Symbol_166_0_Layer0_0_FILL"
                transform="translate(97.95 28.6)"
                id="AccHat039__col1d"
            />
            <use
                xlinkHref="#AccHat039__Symbol_80_0_Layer0_0_FILL"
                transform="translate(95 84.6)"
                id="AccHat039__col2n"
            />
            <use
                xlinkHref="#AccHat039__Symbol_22_0_Layer0_0_FILL"
                transform="translate(95 84.6)"
                id="AccHat039__col2d"
            />
        </g></g>
    )
}

export default AccHat039
