import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.65 11q0-3.2-2.25-5.45-1.65-1.65-3.8-2.1-.3-.05-.55-.1-.25-.05-.5-.05-.15-.05-.35 0-.15-.05-.35 0h-.4q-.7.05-1.25.15-.15.05-.35.1Q20.7-.05 15.1 0 9.35-.05 5.2 3.65q-.05.05-.1.15-.2.1-.35.25-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3Q0 9.4 0 15q0 6.2 4.45 10.6Q8.85 30 15.1 30q2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.25-3.25 4.1-7.45.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45m135.6-6.85V4.1l-.05-.05q-.2-.15-.35-.25-.05-.1-.1-.15-4.2-3.7-9.9-3.65-5.65-.05-9.7 3.55-.2-.05-.35-.1-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05h-.05q-.25.05-.45.1-2.2.45-3.85 2.1-2.3 2.25-2.3 5.45t2.3 5.45q.95 1 2.15 1.55l.3.1q.85 4.25 4.1 7.5 1.35 1.35 2.9 2.3.05 0 .1.05t.15.1q.35.2.75.4.35.2.75.35l.2.1h.05q.05.05.15.1.1 0 .2.05 2.5.95 5.4.95 6.25 0 10.65-4.4t4.45-10.6q-.05-5.6-3.65-9.75-.15-.15-.25-.3l-.1-.1q-.25-.25-.45-.5-.15-.1-.25-.2z"
                    id="BodHan001__Symbol_2_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.5 7.75q0-3.2-2.25-5.45Q11.6.65 9.45.2 9.15.15 8.9.1 8.65.05 8.4.05q-.15-.05-.35 0Q7.9 0 7.7.05h-.4Q6.6.1 6.05.2 5.9.25 5.7.3q-1.95.45-3.45 2Q0 4.55 0 7.75t2.25 5.45q1.7 1.7 3.95 2.1l1.5.15h.1l.75-.05q1.15-.1 2.15-.5.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45M12.45 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-.85.9-1.85 1.4-1 .45-2.05.55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-2-1.95-1.95-4.7Q1.05 4.95 3.05 3q1.45-1.45 3.3-1.8.5-.1 1-.15H7.8q.4 0 .85.1 2.15.25 3.8 1.85m124.3 4.75q-.05-3.2-2.3-5.45-1.5-1.55-3.4-2H131q-.2-.05-.35-.1-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05h-.05q-.25.05-.45.1-2.2.45-3.85 2.1-2.3 2.25-2.3 5.45t2.3 5.45q.95 1 2.15 1.55l.3.1q1 .4 2.25.55l.75.05h.1l1.5-.15q2.2-.4 3.9-2.1 2.25-2.25 2.3-5.45M133.7 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-1.45 1.45-3.35 1.85l-1.3.15h-.1l-.75-.05q-1.1-.1-2-.55-1.05-.5-1.95-1.4-1.95-1.95-1.9-4.7-.05-2.8 1.9-4.75 1.65-1.6 3.8-1.85h.05q.4-.1.85-.1H129.4q.5.05 1 .15 1.85.35 3.3 1.8z"
                    id="BodHan001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan001__Symbol_2_0_Layer0_0_FILL"
                transform="translate(105.05 280.6)"
                id="BodHan001__col1n"
            />
            <use
                xlinkHref="#BodHan001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(124.2 283.85)"
                id="BodHan001__col1d"
            />
        </g></g>
    )
}

export default BodHan001
