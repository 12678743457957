import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M202.75 1.75q.25-.85.5-1.75-.7.9-1.55 1.75-2.05 2-5.05 3.5-2.7 1.35-4.4 4.3-1.95 3.4-8.55 3.8-.55 5.4-3.75 11-3.05 5.35-2.7 13.25.3.5.2 1.4v.1q.05.4 0 .9-.4 3.65-1.25 7.25-.75 3.55-1.35 7.2l-.2.9-.4 2.2.8 2.4q-.2.35.65 1.75-3.75 8.6-.65 12.85h.05q.85 1.75 2.15 3.4.65.8 1.4 1.55.8.8 1.15 1.75.4-.1.8-.4.6-.85.9-1.85 1.15-3.25 2.75-6.4l1.55-.85.05-.05q.4-.4.65-.95v-.1q.6-1.6-.4-3.85l-.5-1.8q-.05-.3-.15-.6.4-.2.85-.8.5-.65.75-1.3.45-1.2.45-2.3v-.05l-.45-2.85q-.1-.35-.15-.75-.05-1.15-.05-2.25l-.05-.05q.45-4.95.15-9.85.6-.4 1.25-.75 3-1.55 3.3-4.45l.2-.4q1.65-4.6.9-9.55-.05-.2-.05-.35 4-4.9 6.65-9.8-6.05 1.1-6.75-1.4 7.35-6.2 10.3-15.75m165.5 44.6q0-1.35-.05-2.25-.2-.1-.35-.25-1.2-.95-.4-2.35.3-.5.4-1.1.3-1.6.4-3.2-.4-4.25-4.45-7.35 1-8.8-3.3-13.1-4.3-4.3-8.95-10.45-.45 7.3 1.2 13.7-3.4-2.1-6.65-2.85 3.15 3.45 4.75 7.15.05.15.15.3l1.45 7.4q.05 2.6 1.55 4.3 8.3 9.25 2.4 18.45-1.4 2.15.75 3.5.25.15.55.3.15.05.25.15l.7.4q.3.15.6.35.05.05.15.1l1.55 1.45q.45.6.8 1.25l.4 1.4.05.05q.05.35.05.8.05 1.45-.6 3.1-3.15 8.2 3.1 11.45.95.45 2.15.85l.6.2h.15l.1-.7.4-1.9q.1-.45.15-.9.1-.45.15-.9.05-.25.1-.45 0-.1.05-.1V75l2-10.2q.65-3.3.15-6.55-.4-2.8-1.7-5.55-.35-.75-.5-1.7-.4-2.4-.3-4.65z"
                    id="HaiSho002__Symbol_2n_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M13.3 19.9q.6-.4 1.25-.75 3-1.55 3.3-4.45l.2-.4q1.65-4.6.9-9.55-2.45 4.3-5.05 9.35-2.1 3.1-5.75 3.45v15.3q-.1.55-.3 1.1-2.3 8.2-4.6 5.8-.7-.55-1.2-2.4-.1-.15-.15-.25-.25-.8-.4-1.15l-.1-.3q-.2.35.65 1.75Q-1.7 46 1.4 50.25h.05Q2.3 52 3.6 53.65q.65.8 1.4 1.55.8.8 1.15 1.75.4-.1.8-.4.6-.85.9-1.85Q9 51.45 10.6 48.3l1.55-.85.05-.05q.1-.15.25-.3.25-.35.4-.75.6-1.6-.4-3.85l-.5-1.8q-.05-.3-.15-.6.4-.2.85-.8.5-.65.75-1.3.45-1.2.45-2.3v-.05l-.45-2.85q-.1-.35-.15-.75-.05-1.15-.05-2.25l-.05-.05q.45-4.95.15-9.85m176.35 2.2q0-1.6-3.5-9.5-3.4-7.85-4.4-8.8-2.4-2-4.55-3.8.05.15.15.3 1.45 3.55 1.45 7.4.05 2.6 1.55 4.3 8.3 9.25 2.4 18.45-1.4 2.15.75 3.5.25.15.55.3.15.05.25.15l.7.4q.3.15.6.35.05.05.15.1 1.25.9 1.95 1.95.65 1 .85 2.2.05.35.05.8.05 1.45-.6 3.1-3.15 8.2 3.1 11.45.95.45 2.15.85l.6.2h.15l.1-.7.4-1.9q.1-.45.15-.9.1-.45.15-.9.05-.25.1-.45 0-.1.05-.1v-.15l2-10.2q.65-3.3.15-6.55-.75 10.55-6.9 0-1.3-1.85-1.3-4.5 0-2.35.4-3.95.35-1.65.35-3.4z"
                    id="HaiSho002__Symbol_2d_0_Layer0_0_FILL"
                />
            </defs>
            <g id="HaiSho002__col1n" transform="translate(92.9 91)">
                <use
                    xlinkHref="#HaiSho002__Symbol_2n_0_Layer0_0_FILL"
                    transform="translate(-173.65)"
                    id="HaiSho002__col1n_FL"
                />
            </g>
            <g id="HaiSho002__col1d" transform="translate(92.9 115.3)">
                <use
                    xlinkHref="#HaiSho002__Symbol_2d_0_Layer0_0_FILL"
                    id="HaiSho002__col1d_FL"
                />
            </g>
        </g></g>
    )
}

export default HaiSho002
