import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M74.55 3.3h-.05q-2.8 2.1-5.65 2.6-.65-.85-1.65-2.15-.6.4-1.65 1.3-.45.35-.95.6-.05-.05-.1-.05-1.6-.4-2.6-.8-2.15-1.55-3.25-1.55-.55 0-1 .3-.35.15-.95.25-.45.05-.5 0-.924-.064-3.5-.8-.6-.15-2.35-.9-1.45-.6-1.75-.6-.35 0-.5.5-.2.45-.35.45-.55 0-5.8-2.45-.3 0-1.25.65-.1.05-.15.15-.65.35-1.55.85-1.65.8-2.6.8-.5 0-.95-.45-.45-.5-.7-.5-.35 0-3 1.25-2.65 1.2-3.95 1.2-1 0-1.75-.6-.8-.6-1.15-.6-1.25 0-2.65 1.4-.9.4-2.05.4-1.4 0-2.6-.65l-.1.1q-1.4 1.3-2.8 1.3-2.55 0-4.8-2.5-.6-.1-1.1-.15-.95.15-2.05.15-3.1 0-5.25-1.25Q.75 1.1.35.7.15.6 0 .55q3.35 6.55 13.05 9.5 1.65 1 9 2.5 10.45 2.4 18.2 10.25 1 1.75 1.7 3.6-.25.05-.4.3-.75.55-.95 1.9 0 1.65 1.05 2.25-1.3.45-1.55 2.25 0 2.4 2.1 2.6v.05q-1.8.2-2.1 2.55 0 2.05 1.3 2.7-1.05.5-1.3 2.1 0 1.3.65 1.95-.95.55-1.15 2.05 0 2.25 1.9 2.55-.15.15-.25.35-.3.45-.45.9-.2.25-.8.85-.25.3-.35.55-.55.55-.55.6 0 .55.45.9.65-.35 1.8-.35 2.35 0 3.65.6.15-.1.25-.2.1-.65.1-.7 0-.5-1.05-2-.65-1-1.2-1.4.3-.1.65-.5.35-.3.6-.75.25-.55.25-1.4 0-1.1-.55-1.7.55-.3.8-.9.25-.55.25-1.4 0-1.7-1.2-2.15.65-.4.95-1.2.25-1.45.25-1.5 0-1.95-1.45-2.4.15-.2.35-.4.55-.35.85-.95.25-.55.25-1.4 0-1.5-.95-2.05.8-.3 1.2-1.05.25-.55.25-1.4 0-1.85-1.5-2.25.85-1.95 3.35-4.5 11-8.6 25.2-12.4Q82.1 8 86.9.7q-4.75 1.85-8.85 2.95h-2.6q-.6.05-.9-.35z"
                    id="BeaChi040__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5 8.65Q3.45 7.25 2.35 6.7L.25 5.5q-1.05-.6 2.2 2.35 2.9 2.6 6.05 8.55.05.15.15.3 0 .05-.05.15-.35.05-.65.45-1 .75-1.25 1.2-.2.35-.2 1.55 0 .8.25 1.5.1.35.25.65-.15.25-.2.7l-.1.7q-.3.7-.35.8-.1.15-.1.85 0 .8.4 1.55.15.3.35.5-.45.35-.8 1.2-.45 1.05-.45 1.8 0 1.6.65 2.35-.25.2-.35.45l-.2.6q-.1.55-.1 1.2 0 .6.3 1.3.3.65.75.95-.2.5-.25 1 0 1.05.65 2.05v.05l.7.9q0 .05-1.2 1.15-.75.8-1 1.35.15.15.3.35.3.35.45.6.3-.7.7-.9.25-.3.7-.65.7-.65 1.05-1 .45-.45.55-.75-.1-.05-.15-.1-.35-.2-.55-.8-.05-.05-.1-.15 0-.05-.05-.15-.45-.9-.45-1.65v-.25-.15q0-.25.1-.4.1-.45.4-.65-.3-.2-.55-.75-.3-.65-.3-1.1.05-.7.25-1.35v-.1q.4-1.1 1.05-1.15-.15-.2-.3-.35-.25-.35-.5-.65-.55-.75-.75-1.3.05-1.75 1-2.25.3-.55.85-.55h.2q.05-.05.05-.1t-.85-1.2Q8 25 8 24q0-.3.05-.6.4-.85 1.05-.95l.1-.1q-.4-.3-.7-.95-.35-.75-.35-1.3 0-.5.4-1.3.2-.45.4-.65.2-.25.55-.55.4-.35.5-.9.1-.6.15-.8.1-.25.4-2.15.25-1.9-.2-3.95-.4-2.05-.55-2.35-.15-.3-1-2.35.65 3 .55 4.1-.1 1.05-.05 2.5v1.35q-.05-.15-.65-1.65Q8.1 9.85 7.4 8.2 6.75 6.5 2.85 0 4.7 5.95 5.4 7.65q.55 1.35.5 1.8-.25-.25-.9-.8M19.35 2.8q-5.4 4.65-6.6 8.1-1.15 3.45-1.55 4.55-.45 1.05-.3 1.65.5.35.8 1.15.35.9.35 1.8Q12 22 11.1 22.6q.05.05.1.15.55 1.3.55 2.1 0 .6-.25 1.3-.2.3-.6.75-.2.2-.4.3.55 1 .9 2.95 0 .8-.35 1.45-.3.5-.75.75.3.75.5 1.35.25.8.25 1.35.1 1.35-.45 1.9.25.25.45.65.35.75.35 1.55 0 .05-.2 1.1-.35.75-1.1.95-.05 0-.1.05h-.05q.1.1.15.2.25.35.5.75l.2.4q.4.65.6 1.2.4.85.4 1.3v.05q0 .1-.05.15.1 0 .15.05.65.05.95.15.35-.2.2-.75 0-1.05-1.5-3.7.6-.35.95-.8.5-.6.5-1.4 0-.65-.6-1.65-.2-.35-.45-.6.45-.8.6-2.4 0-.25-.05-.5-.1-.45-.3-.9-.1-.2-.25-.4.45-.4.75-1.2.35-.95.35-1.8 0-1-.35-1.8-.15-.35-.35-.55.35-.3.6-.8.7-1.5 0-3-.2-.5-.45-.8.55-.7.65-2.5 0-.75-.4-1.6-.4-.85-1.1-1.1 1.6-7 7.7-14.45m.9 6q7-5.6 8.35-6.35 1.35-.75.65-.5-.65.2-1.2.45-.6.2-3.35 1.2-2.8 1-3.55 1.45-.8.4-1.8 1.55-.45.55-1.5 2.05-.95 1.25-2.8 4-.45.45-1.6 1.85-.4.5-.7.9.15-.05.75-.45.65-.45 2.2-2.15l3.45-2.9q-.4.3 1.1-1.1z"
                    id="BeaChi040__Symbol_38_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi040__Symbol_79_0_Layer0_0_FILL"
                transform="translate(148.8 238.3)"
                id="BeaChi040__col1n"
            />
            <use
                xlinkHref="#BeaChi040__Symbol_38_0_Layer0_0_FILL"
                transform="translate(181.5 246.85)"
                id="BeaChi040__col1d"
            />
        </g></g>
    )
}

export default BeaChi040
