import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.25 7.35q-.1-.3-.35-.4-.3-.1-.55 0-10.15 4.15-19.7 0-.3-.1-.55 0-.3.1-.4.4-.1.25 0 .5.1.3.4.4 10.1 4.35 20.8 0 .25-.1.35-.35.1-.3 0-.55m9.65-3.1q.2-.2.2-.5t-.2-.45q-.2-.25-.5-.25-3.2-.05-5.45-.75h-.05q-2.35-.75-4.35-1.55Q28.3-.1 26.9 0q-1.4.1-3.6.75h.05q-1.95.5-2.75.45h-.1q-.8.05-2.7-.45Q15.6.1 14.2 0q-1.4-.1-3.65.75-2 .8-4.35 1.55-2.3.7-5.5.75-.3 0-.5.25-.2.15-.2.45 0 .3.25.5.15.2.45.2 3.5-.05 5.95-.8H6.6q2.45-.8 4.45-1.6 1.85-.75 3.05-.65 1.3.1 3.3.7h.05q2.1.55 3.05.5h.1q.95.05 3.1-.5 2-.6 3.3-.7 1.2-.1 3.05.65 2 .8 4.45 1.6 2.4.75 5.9.8.3 0 .5-.2z"
                    id="FacMou011__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou011__Symbol_36_0_Layer0_0_FILL"
                transform="translate(172 220.85)"
                id="FacMou011__col1n"
            />
        </g></g>
    )
}

export default FacMou011
