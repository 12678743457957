import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaSid001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.4 29.7q.3-5.6-1-8.8Q5.1 17.7 4.15 0 2.95 4.9 2.1 9.05q-6.25 29.35 6.05 56.8 56.25 36.95 28.4 13.8-7.7-6.7-15.15-14.1-9.45-9.35-11-16.05-3.25-14.2-3-19.8M188.4 0q-.95 17.7-2.25 20.9-1.3 3.2-1 8.8.25 5.6-3 19.8-1.55 6.7-11 16.05-7.45 7.4-15.15 14.1-27.85 23.15 28.4-13.8 12.3-27.45 6.05-56.8Q189.6 4.9 188.4 0z"
                    id="BeaSid001__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaSid001__Symbol_2_0_Layer0_0_FILL"
                transform="translate(96.25 126.45)"
                id="BeaSid001__col1n"
            />
        </g></g>
    )
}

export default BeaSid001
