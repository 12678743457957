import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#ADFFFF",
    "col1n": "#E2FFFF"
}

function AccJew042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M8.65 18.25L4.5 0 0 18.25 4.5 35.5l4.15-17.25z"
                    id="AccJew042__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M7.95 2.55q.45-.45.45-1.05 0-.6-.45-1.05Q7.5 0 6.9 0q-.6 0-1.05.45Q5.4.9 5.4 1.5q0 .6.45 1.05.3.3.65.4-.8.1-1.4.7-.75.75-.75 1.8t.75 1.8Q5.85 8 6.85 8L0 26.15 6.9 43.4 3.4 26.15 6.9 7.9l3.5 18.25L6.9 43.4l6.9-17.25L6.95 8q1 0 1.75-.75t.75-1.8q0-1.05-.75-1.8-.6-.6-1.35-.7.3-.1.6-.4z"
                    id="AccJew042__Symbol_70_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew042__Symbol_110_0_Layer0_0_FILL"
                transform="translate(279.8 186.1)"
                id="AccJew042__col1n"
            />
            <use
                xlinkHref="#AccJew042__Symbol_70_0_Layer0_0_FILL"
                transform="translate(277.4 178.2)"
                id="AccJew042__col1d"
            />
        </g></g>
    )
}

export default AccJew042
