import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#666666"
}

function PlaFor030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M429.45 15.8q0-15.8-15.8-15.8H15.8Q0 0 0 15.8v101.15q0 15.8 15.8 15.8h315.8q-4.05 16.55-16.75 27 27-3.95 40.6-27h58.2q15.8 0 15.8-15.8V15.8z"
                    id="PlaFor030__Symbol_35_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M406.95-15.45Q406.95-32 390.4-32H-7.45Q-24-32-24-15.45V85.7q0 16.55 16.55 16.55H307.4q-4.123 15.672-16.3 25.65-.168.184-.25.4-.03.27.05.5.128.229.35.35.21.13.45.1 27.142-3.98 40.9-27h57.8q16.55 0 16.55-16.55V-15.45M390.4-30.5q15.05 0 15.05 15.05V85.7q0 15.05-15.05 15.05h-58.2q-.2 0-.4.1-.148.102-.25.25-12.75 21.637-37.45 26.2 11.198-10.188 14.95-25.65.082-.204 0-.45-.083-.193-.3-.35-.167-.104-.4-.1H-7.45q-15.05 0-15.05-15.05V-15.45q0-15.05 15.05-15.05H390.4z"
                    id="PlaFor030__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor030__Symbol_35_0_Layer0_0_FILL"
                transform="translate(-23.25 -31.25)"
                id="PlaFor030__col1n"
            />
            <use
                xlinkHref="#PlaFor030__Symbol_44_0_Layer0_0_FILL"
                id="PlaFor030__col2n"
            />
        </g></g>
    )
}

export default PlaFor030
