import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacNos002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M36.85 3.05q0-1.7-1.2-2.85-.25-.2-.5-.2-.3 0-.5.25-.2.2-.2.5 0 .25.25.45.75.75.75 1.85-.05 1.1-1.15 2.2-1.2 1.1-2.95 1.1-2.25-.05-3.7-1.2-.5-.4-1.3-1.3-.2-.25-.5-.25t-.5.2q-.2.15-.25.45-.15 1.7-1.8 2.95-2.05 1.45-4.85 1.45T13.7 7.2q-.9-.65-1.4-1.4v-.05q-.5-.7-.55-1.45 0-.3-.2-.5-.25-.2-.5-.2-.3 0-.5.25-.85.9-1.3 1.3-1.5 1.15-3.75 1.2-1.75 0-2.9-1.1t-1.2-2.2q0-1.1.8-1.85.2-.2.2-.45 0-.3-.2-.5Q2 0 1.75 0q-.3 0-.5.2Q0 1.35 0 3.05q-.05 1.7 1.65 3.25Q3.2 7.75 5.5 7.75h.05q2.7-.05 4.55-1.5.25-.2.6-.5.15.4.45.8h-.05q.6.9 1.75 1.75 2.3 1.75 5.6 1.75t5.65-1.7l.05-.05q1.5-1.15 2.05-2.55.35.3.6.5 1.8 1.45 4.55 1.5 2.3 0 3.9-1.45 1.65-1.55 1.6-3.25z"
                    id="FacNos002__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos002__Symbol_11_0_Layer0_0_FILL"
                transform="translate(174.1 200.2)"
                id="FacNos002__col1n"
            />
        </g></g>
    )
}

export default FacNos002
