import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#008300"
}

function ShiMot089({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M8.8 34.55q-.4.65-.65 1.55-.2.9-.2 1.5 0 1.1.4 2.1.4.95 1.15 1.7.75.7 1.7 1.1 1 .45 2.05.45h9.3v-11.3h-12.1l-1.65 2.9M3.45 19l-3.1 5.35q-.15.3-.25.65-.1.4-.1.75t.1.7q.1.35.25.65l6.4 11q-.05-.4-.05-.85 0-.65.25-1.55.3-.9.65-1.6l5.5-9.5 3.5 2-5.45-9.75-11.15.2L3.45 19M23.7 6.35l-1.75-2.9q-1.75-3-4.9-3-1.45 0-2.65.7-1.15.65-1.85 1.95l-4.65 8 9.75 5.7L23.7 6.35m4.75 5.95l-3.4 1.95 11.1.15 5.4-9.75-3.35 2-3.15-5.3q-.4-.65-1-1Q33.4 0 32.55 0h-12.6q1.9.8 3.05 2.75l5.45 9.55M42.3 30.5q.75 0 1.55-.15.75-.2 1.35-.5 1.2-.7 2-2 .8-1.35.8-2.7t-.7-2.65l-4.65-8-9.8 5.55L38.9 30.5h3.4m3.95 0q-1.6 1.25-3.85 1.25h-11v-4l-5.7 9.55 5.7 9.6v-3.95h6q.8 0 1.5-.35.65-.4 1-1.05l6.35-11.05z"
                    id="ShiMot089__Symbol_55_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot089__Symbol_55_0_Layer0_0_FILL"
                transform="translate(168.5 260.75)"
                id="ShiMot089__col1n"
            />
        </g></g>
    )
}

export default ShiMot089
