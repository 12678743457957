import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2l": "#004141",
    "col2n": "#002020"
}

function AccGla014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.5 3.05q.55.55 1.3.55t1.25-.55q.55-.5.55-1.25T3.05.5Q2.55 0 1.8 0T.5.5Q0 1.05 0 1.8t.5 1.25z"
                    id="AccGla014__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M66.95 23.4q-.95-2.2-2.2-4.3-1.05-1.85-2.4-3.5-1.4-1.8-3-3.45-1.55-1.65-3.4-3.1-.3-.2-.55-.4Q54.2 7.7 53 6.9 40.65-1.4 26.65.3 15.35 1.6 8.4 8.65q-.25.2-.45.4-1.4 1.4-2.55 3.1-.15.1-.25.25-1.1 1.55-1.95 3.2-.95 1.7-1.55 3.5-.8 2.1-1.2 4.3-.7 3.85-.25 8 .2 2.5.9 5 3.35 13.35 15.65 21.55 12.3 8.3 26.3 6.65Q57 62.95 64.5 52.4q6.6-9.4 4.8-21-.25-1.45-.65-2.9-.65-2.7-1.7-5.1m74.5.75q-.4-2.2-1.2-4.3-.6-1.8-1.55-3.5-.85-1.65-1.95-3.2-.1-.15-.25-.25-1.15-1.7-2.55-3.1-.2-.2-.45-.4-6.95-7.05-18.25-8.35-14-1.7-26.35 6.6-1.2.8-2.4 1.75-.25.2-.55.4-1.85 1.45-3.4 3.1-1.6 1.65-3 3.45-1.35 1.65-2.4 3.5-1.25 2.1-2.2 4.3-1.05 2.4-1.7 5.1-.4 1.45-.65 2.9-1.8 11.6 4.8 21 7.5 10.55 21.45 12.2 14 1.65 26.3-6.65 12.3-8.2 15.65-21.55.7-2.5.9-5 .45-4.15-.25-8z"
                    id="AccGla014__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192.95 22.65q-.4-1.35-2.05-1.3l-19.5 4.6q-1.05-6.05-4.7-11.15-5.95-9.55-17.75-13.15-14.35-4.3-28.85 2.75-9.4 4.5-15.35 12.05-1.05 1.4-2 2.9-.35.45-.6.9-1.65 3.35-3.05 4.4-1.2.8-2.3.85h-.2q-.55-.05-1.15-.25-2.25-1.4-3.8-4.4-1.4-2.3-3-4.4Q82.7 8.9 73.3 4.4 58.8-2.65 44.45 1.65 32.65 5.25 26.7 14.8q-3.65 5.1-4.7 11.15l-19.5-4.6Q.85 21.3.45 22.65l-.05.15q-.2.75-.35 1.55Q-.3 26.3 3.1 26.9l18.45 4.25q-.05 2.85.6 6.35v.05q0 .15.05.3.2 1 .45 1.95.05.35.15.7l.3 1.1q.7 2.5 1.8 5Q31.2 61 45.7 68.05q6.2 3 12.35 3.9 6.75 1.25 13.7-.15 14.3-2.95 21.15-15 .4-.65.75-1.35 1.35-2 2.7-2.15v.05q.3-.05.55-.05.1 0 .15.05v-.05q1.35.15 2.7 2.15.35.7.75 1.35 6.85 12.05 21.15 15 6.95 1.4 13.7.15 6.15-.9 12.35-3.9Q162.2 61 168.5 46.6q1.1-2.5 1.8-5l.3-1.1q.1-.35.15-.7.25-.95.45-1.95.05-.15.05-.3v-.05q.65-3.5.6-6.35l18.45-4.25q3.4-.6 3.05-2.55-.15-.8-.35-1.55l-.05-.15m-30.1 14.95q-.2 2.3-.8 4.65-3 12.4-14.05 20-11 7.7-23.55 6.2-12.5-1.55-19.2-11.35-5.95-8.75-4.3-19.5.2-1.35.55-2.7.6-2.5 1.55-4.75.85-2.05 1.95-4 .95-1.7 2.15-3.25 1.25-1.65 2.7-3.2 1.4-1.5 3.05-2.85l.5-.4q1.05-.85 2.15-1.6 11.05-7.7 23.6-6.15 10.1 1.2 16.35 7.75l.4.4q1.25 1.3 2.3 2.85.1.1.2.25 1 1.45 1.75 2.95.85 1.6 1.4 3.25.7 1.95 1.05 4 .65 3.6.25 7.45m-79.3-17.9q1.45 1.55 2.7 3.2 1.2 1.55 2.15 3.25 1.1 1.95 1.95 4 .95 2.25 1.55 4.75.35 1.35.55 2.7 1.65 10.75-4.3 19.5-6.7 9.8-19.2 11.35-12.55 1.5-23.55-6.2-11.05-7.6-14.05-20-.6-2.35-.8-4.65-.4-3.85.25-7.45.35-2.05 1.05-4 .55-1.65 1.4-3.25.75-1.5 1.75-2.95.1-.15.2-.25 1.05-1.55 2.3-2.85l.4-.4Q44.15 9.9 54.25 8.7q12.55-1.55 23.6 6.15 1.1.75 2.15 1.6l.5.4q1.65 1.35 3.05 2.85z"
                    id="AccGla014__Symbol_37_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla014__Symbol_104_0_Layer0_0_FILL"
                transform="translate(190.95 151.15)"
                id="AccGla014__col2n"
            />
            <use
                xlinkHref="#AccGla014__Symbol_84_0_Layer0_0_FILL"
                transform="translate(121.55 126.6)"
                id="AccGla014__col2l"
            />
            <use
                xlinkHref="#AccGla014__Symbol_37_0_Layer0_0_FILL"
                transform="translate(95.8 122.3)"
                id="AccGla014__col1n"
            />
        </g></g>
    )
}

export default AccGla014
