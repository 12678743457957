import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00",
    "col1s": "#DEC700",
    "col2d": "#00AFAF",
    "col2n": "#00DEDE",
    "col2s": "#007070"
}

function ShiLon065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.75 48.6q-.3-1.3-.65-2.6-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-3-6.8-6.65-13-.55-.95-1.15-1.9-.3-.5-.6-.95.2-.4.4-.75.05-.15.1-.25l.2-.6q.65-2.7.6-4.85-.05-2.55-1-4.4-5.05 2.5-10 4.6-28 11.75-54.95 11.55-32.3-.3-64.25-16l-.5-.25q-1.8 3.3-.35 9.35l.2.6q.25.5.55 1.05v.05q-.3.45-.6.95-.6.95-1.15 2-3.6 6.05-6.55 12.75-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35-.35 1.3-.65 2.6-1.4 5.6-2.15 11.5-.05.3-.1.65 4-3.6 10-3.7h1.25q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15.7.25 1.4.6 2.3 1.05 4.4 2.95v.05q.2.15.4.35l.05.05-3.3 13.6q27.15-16 58.65-17.4V17.6h1v72.65h.05v2.95h.6l1.55.05h3.2q.75-.05 1.55-.05v-2.95h.05v-34.7l1-.3q24.7-16.25 51.65-15.3l.05-.05v-.05q.1-.15.25-.15.2-.05.3 0 .05 0 .05.05.05 0 .1.05t.1.2l.05.05v.05L143 61.8q.05-.05.15-.05l.05-.05q.2-.2.4-.35v-.05q2.2-1.95 4.6-3.05 0-.1-.05-.15.05-.05.15-.05-.05.05-.05.1v.1q.55-.3 1.15-.5.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05H155q6 .1 10 3.7-.05-.35-.1-.65-.75-5.9-2.15-11.5m-136.5-8.55q0-.05.05-.1.05-.15.1-.2.05-.05.1-.05 0-.05.05-.05.05-.05.2 0h.1q.2 0 .3.2.1.15.1.4l-.05.05-3.25 16.8q-.05.1-.2.85-.05.1-.05.25v.05q-.2.6-.4 1.55-.15.65-.35 1.55v.05l.05.05h-.05v.15l-.1.3q-.45.55-.85-.15l4.25-21.7z"
                    id="ShiLon065__Symbol_366_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.3 27.7q0-.05.05-.05.05-.05.2 0v-.05q0-.1-.1-.2-.1-.2-.3-.2-.15-.05-.2 0-.2-.1-.35-.35-.15-.3-2.65-.75t-2.9 1.75q-.45 2.15.25 3.5t.9 2.3q.15.9-1.1 1.95-1.3 1-1.5 2.45-.25 1.4.2 2.4t.3 1.25q-.15.2-1.2 1.65Q1 44.6.85 45.9q-.05.2-.05.4 2.3 1.05 4.4 2.95v.05q.2.15.4.35.1-.05.25-.15l4.2-21.45q0-.05.05-.1.05-.15.1-.2.05-.05.1-.05m29.9-10.1q-1.1-.15-2.05-.25-4.9-.6-16.25-3.5Q10.5 10.9 1.15.05V0Q.55.95 0 2q1 6.65 8.4 10.15 8.15 3.65 16.45 4.7 10.35 1.1 15.35.75M61 13.2v-2.15q-13.1-.3-25.45-2.75 3.2 1.05 6.35 1.95l11.35 2.2q3.85.55 7.75.75m30.5-3.8l3.3-1.1q-11.6 2.05-22.65 2.6-1.1.05-2.15.1V5.6h-1V11l-3 .1h-4v2.15q3.5.15 7 0v6.2l1-.3V13.2q.95-.05 1.95-.1 9.8-.75 19.55-3.7z"
                    id="ShiLon065__Symbol_367_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.25 15.6Q2.4 13.85 1.2 11.9q-.35-.5-.6-.95-.3.45-.6.95v.05Q7 24 29.3 28.15q2.8.5 7.7 1.1.95.1 2.05.25l4.15-.4Q15.25 27.05 4.25 15.6M126.4 2.4q2.2-1.25 3.5-2.4-5.05 2.5-10 4.6-28 11.75-54.95 11.55-32.3-.3-64.25-16 1.2 1 1.45 1.1.25.05 3.15 1.95 2.85 1.85 9.8 4.75 17.75 7.45 45.75 9.55h5.2q24.85-.5 49.5-10.3 2.85-1.1 4.95-2 3.7-1.55 5.9-2.8z"
                    id="ShiLon065__Symbol_368_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.15 53.7v.05q-.2.6-.4 1.55-.15.65-.35 1.55v.05l.05.05H4.4v.15l-.1.3q-.45.55-.85-.15L3.5 57q-.15.1-.25.15l.05.05L0 70.8l58.65-17.4V29.35L6.9 44.95 5.4 52.6q-.05.1-.2.85-.05.1-.05.25m120.9-50.85q-1.3.9-2.85 1.8Q121 6 118.25 7.5l-.2.1q-.25.15-.45.3-6.7 3.95-16.7 7.9-12.2 4.85-29.35 9.7l-4.9 1.45v24.1l52.65-15.6.05-.05v-.05q.1-.15.25-.15.2-.05.3 0 .05 0 .05.05.05 0 .1.05t.1.2l.05.05v.05l4.25 21.7q.05-.05.15-.05l.05-.05q.2-.2.4-.35v-.05q2.2-1.95 4.6-3.05 0-.1-.05-.15.05-.05.15-.05-.05.05-.05.1v.1q.55-.3 1.15-.5.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05h1.25q6 .1 10 3.7-.05-.35-.1-.65-.75-5.9-2.15-11.5-.3-1.3-.65-2.6-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-3-6.8-6.65-13-.55-.95-1.15-1.9-.3-.5-.6-.95.2-.4.4-.75.05-.15.1-.25l.2-.6q.65-2.7.6-4.85-1.5 1.35-3.65 2.85z"
                    id="ShiLon065__Symbol_369_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1 20.2l-1 .3v24.1l1-.3V20.2m59 9.4q0-2.35-2.4-2.35-1.35 0-3.05.7l-.7.3q-.25.1-.45.2-.05-.05-.15-.05-.1 0-.2.05-.15 0-.25.15-.05 0-.05.05-.05.05-.05.1V28.9q.1-.15.25-.15.2-.05.3 0 .05 0 .05.05.05 0 .1.05t.1.2l.05.05v.05l4.25 21.7q.05-.05.15-.05l.05-.05q.2-.2.4-.35v-.05q2.2-1.95 4.6-3.05 0-.1-.05-.15-.1 0-.15.05 0-.15-.05-.35 0-.2-.05-.5-.1-1-.2-1.6-.15-.55-.25-.75-.3-.4-1.2-1.25 0-.05-.05-.05-.5-.45-.5-1.3 0-.9.45-1.5.4-.55.4-1.2 0-.3-.15-.75-.3-.75-1.1-1.75t-1.05-1.8q-.2-.5-.2-.95 0-.95.6-2 .55-1.1.55-1.85m3.5-26.75q-.55-.95-1.15-1.9-.3-.5-.6-.95-.05 0-.05.05-.25.5-.6 1-1.2 1.95-3.05 3.7-11 11.45-38.95 13.5l4.15.4q1.1.1 2.45.1h.65q2.3 0 5.4-.2 3.05-.25 6.85-.65 8.3-1.05 16.45-4.7 7.5-3.55 8.45-10.35z"
                    id="ShiLon065__Symbol_370_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M43.2 1.05q.05-.05.05-.1-.3-.5-.6-.95-.05 0-.05.05-.25.5-.6 1-1.2 1.95-3.05 3.7Q27.95 16.2 0 18.25l4.15.4q.15 0 .3-.05.95-.1 1.75-.2 4.9-.6 7.7-1.1 22.3-4.15 29.3-16.2v-.05z"
                    id="ShiLon065__Symbol_371_0_Layer0_0_FILL"
                />
                <path
                    id="ShiLon065__Layer10_0_1_STROKES"
                    stroke="#C00"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M111 277.85v-.1q-.05 0-.05.05l.05.05z"
                />
                <g id="ShiLon065__Layer10_0_FILL">
                    <path
                        fill="#434343"
                        d="M247.75 264.55q0-.235.1-.4-28.303-10.067-51.65 15.35-3.664 15.775-.95 31.9l1 6.05q3.788-.034 7.55-.2l3.6-.15q.194-.009.35-.05h.7q.363-.027.7-.05l1.5-.1q.593-.012 1.15-.05 2.804-.2 5.6-.45.125-.011.25-.05h.25q1.865-.163 3.7-.35l1.4-.2q.1-.05.2-.05.55-.05 1.1-.15l10-1.3q3.399-.465 6.8-1.05.15-.05.3-.05.7-.15 1.35-.25 2.45-.45 4.95-.95l5-1.1q2.65-.6 5.25-1.2h.15q.25-.05.5-.15.15 0 .25-.05l-5.7-23.5q-.077.01-.15 0v.05q-.102.179-.2.25-1.45-6.868-5-21.7l-.05-.05m-101.95 49q5.4.9 10.75 1.55 3.45.45 6.85.85.4 0 .75.05.7.1 1.45.15l1.8.2h.25l5.65.45q.05.003.1 0l2.6.15q.213.038.4.05.313.017.6 0l1.9.1q2.96.176 5.9.25l1.15.05q.613.034 1.2.05v-1.3q2.541-19.55.05-34-32.294-11.024-58.65 17.35l-2.4 9.95q.123.03.25.05.258.08.5.15h.05q2.1.5 4.2.95.4.1.85.2.1.05.2.05l5 1.1q1.3.25 2.55.5 1.2.25 2.4.45.8.15 1.65.3 1 .15 2 .35z"
                    />
                    <path
                        fill="#060"
                        d="M110.95 277.8l.05.05v-.1q-.05 0-.05.05z"
                    />
                </g>
                <g id="ShiLon065__Layer10_1_FILL">
                    <path
                        fill="#121212"
                        d="M227.75 294.1l-.65.85 14.7 11.45.65-.85-14.7-11.45m-39.55-52.25h-1q-3.12 20.15 0 40.3v28.9h-.05v6.4h1.1v-2.95h-.05q2.69-36.325 0-72.65m60.25 22.1q-.05-.025-.15-.05-.118-.096-.3-.05-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.1q.067.18.1.3.098-.072.2-.25V286q.183-11.472-4.25-21.65v-.05l-.05-.05q-.05-.15-.1-.2-.05-.05-.1-.05 0-.05-.05-.05m-90.5 31l-.65-.85-14.7 11.45.65.85 14.7-11.45m-21.1-31.05q-.054-.013-.1-.05-.127.013-.2.05-.05 0-.05.05-.05 0-.1.05t-.1.2l-.05.05v.05q-4.889 7.693-4.3 21.65.463.77.9.2.036-.167.05-.35.034-.034.05-.1h.05l-.05-.05v-.05l2.45-3.85q.067-.07.1-.2l.05-.05q.198-.296.4-.7.48-1.025 1.1-2.85 1.42-3.91.15-13.4l.05-.05q0-.25-.1-.4-.1-.2-.3-.2z"
                    />
                    <path
                        fill="#252525"
                        d="M196.2 279.5l-1 .3q-4.64 17.737-.75 34.65l.7 3h1.1v-6.4h-.05V279.5z"
                    />
                </g>
            </defs>
            <g>
                <use xlinkHref="#ShiLon065__Layer10_0_FILL" />
                <use xlinkHref="#ShiLon065__Layer10_0_1_STROKES" />
            </g>
            <use xlinkHref="#ShiLon065__Layer10_1_FILL" />
            <use
                xlinkHref="#ShiLon065__Symbol_366_0_Layer0_0_FILL"
                transform="translate(110 224.25)"
                id="ShiLon065__col1n"
            />
            <use
                xlinkHref="#ShiLon065__Symbol_367_0_Layer0_0_FILL"
                transform="translate(126.2 236.25)"
                id="ShiLon065__col1d"
            />
            <use
                xlinkHref="#ShiLon065__Symbol_368_0_Layer0_0_FILL"
                transform="translate(127.35 224.35)"
                id="ShiLon065__col1s"
            />
            <use
                xlinkHref="#ShiLon065__Symbol_369_0_Layer0_0_FILL"
                transform="translate(128.55 228.75)"
                id="ShiLon065__col2n"
            />
            <use
                xlinkHref="#ShiLon065__Symbol_370_0_Layer0_0_FILL"
                transform="translate(195.2 235.2)"
                id="ShiLon065__col2d"
            />
            <use
                xlinkHref="#ShiLon065__Symbol_371_0_Layer0_0_FILL"
                transform="translate(214.3 235.2)"
                id="ShiLon065__col2s"
            />
        </g></g>
    )
}

export default ShiLon065
