import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00"
}

function SkiMak018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.1 56.35L24 55l-3.15-.75-.4-3.15-1.65 2.7-3.15-.5 2.1 2.35-1.5 2.8 2.9-1.15 2.25 2.25-.3-3.2m6.2-27.75l-2.45 4.15-4.75-.8 3.1 3.6-2.3 4.35 4.55-1.9 3.35 3.5-.45-4.85 4.35-2.05-4.7-1.15-.7-4.85M4.2 45.15l2.95-1.2 2.2 2.3-.25-3.15 2.85-1.35-3.1-.75-.4-3.15-1.7 2.7-3.05-.6 2.05 2.45-1.55 2.75m3.65-22.5l-.65-4.8L4.75 22 0 21.15l3.1 3.6L.8 29.1l4.5-1.9 3.4 3.55-.45-4.85 4.35-2.1-4.75-1.15M5.15 7.3L8 6.1l2.25 2.25-.25-3.1 2.85-1.35-3.15-.75L9.3 0 7.65 2.7l-3.1-.6L6.6 4.5 5.15 7.3M27.8 18.7l-3.15-.75-.35-3.15-1.7 2.7-3.1-.55 2.1 2.4-1.5 2.75 2.9-1.2 2.2 2.3-.25-3.15 2.85-1.35m13.85 24.4l2.85-1.35-3.15-.75-.4-3.15-1.65 2.7-3.1-.6 2.05 2.45-1.5 2.75 2.9-1.2 2.25 2.3-.25-3.15m103.6 15.35l-1.5-2.8 2.1-2.35-3.15.5-1.65-2.7-.4 3.15-3.15.75 2.9 1.35-.3 3.2 2.25-2.25 2.9 1.15m7.15-15.35l-.25 3.15 2.2-2.3 2.95 1.2-1.55-2.75 2.05-2.45-3.05.6-1.7-2.7-.4 3.15-3.1.75 2.85 1.35m-27.65 2.05l-1.5-2.75 2.05-2.45-3.1.6-1.65-2.7-.4 3.15-3.15.75 2.85 1.35-.25 3.15 2.25-2.3 2.9 1.2m11.9-12.4l-2.45-4.15-.7 4.85-4.7 1.15 4.35 2.05-.45 4.85 3.35-3.5 4.55 1.9-2.3-4.35 3.1-3.6-4.75.8m1.85-11.85l2.9 1.2-1.5-2.75 2.1-2.4-3.1.55-1.7-2.7-.35 3.15-3.15.75 2.85 1.35-.25 3.15 2.2-2.3m18.25 1.1l-2.45-4.15-.65 4.8-4.75 1.15 4.35 2.1-.45 4.85 3.4-3.55 4.5 1.9-2.3-4.35 3.1-3.6-4.75.85m-2.9-19.3L152.2 0l-.4 3.15-3.15.75 2.85 1.35-.25 3.1 2.25-2.25 2.85 1.2-1.45-2.8 2.05-2.4-3.1.6z"
                    id="SkiMak018__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak018__Symbol_24_0_Layer0_0_FILL"
                transform="translate(112.4 161.6)"
                id="SkiMak018__col1n"
            />
        </g></g>
    )
}

export default SkiMak018
