import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000"
}

function ObjMor017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M262.5 275.65l-3.15 11.75q1.6 1.9 1.6 4.45 0 2.9-2.05 4.9-1.1 1.15-2.5 1.65l-1.2 4.45q1.998 2.691 5.55 1.5-.1-.35-.1-.6 0-3 1.45-5.55.55-1.7 1.65-3.55 1.2-2.05 5.15-4 1.9-.9 2.95-.85l2.9-10.9q-5.335-4.586-12.25-3.25z"
                    id="ObjMor017__Symbol_127_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M258.2 312.7l2.25-8.45-2.95-.8-2.25 8.45 2.95.8m4-5.3l-1.6 5.95 2.45.65 1.15-4.15q-1.05-.75-2-2.45z"
                    id="ObjMor017__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    fill="#E0E0E0"
                    d="M276.85 270.95q-4.9-4.2-12.25-3.25l-.45 1.65 12.25 3.25.45-1.65z"
                    id="ObjMor017__Layer5_0_MEMBER_1_FILL"
                />
                <g id="ObjMor017__Layer5_0_FILL">
                    <path
                        fill="#333"
                        d="M264.65 310.1q-.25-.1-.45-.25-1.05-.75-2-2.45-.05-.1-.1-.15-1.1-1.9-1.35-2.9l-5.55-1.5-2.25 8.45 12.25 3.25 1.05-3.95h-.1q-.85 0-1.5-.5z"
                    />
                    <path
                        fill="#FFF"
                        d="M276.4 272.6q-5.94-4.204-12.25-3.25l-1.65 6.3 12.25 3.25 1.65-6.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor017__Symbol_127_0_Layer0_0_FILL"
                id="ObjMor017__col1n"
            />
            <use xlinkHref="#ObjMor017__Layer5_0_FILL" />
            <use xlinkHref="#ObjMor017__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor017__Layer5_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ObjMor017
