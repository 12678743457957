import { AssetSvgProps } from "../../../shared/assets"

function AccJew032({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#5B5526"
                    d="M112 276.2h-.05q.026.014.05 0m1.1-5.8q-.09.49-.2.9.492.17 1.05.4 2.06.849 2.45 1.2l.15.05q-.058.005-.15 0 .081.06.1.1h.1q.2.05 1.95.45.37.087.55.2.378-.249 1.4-.15h1.8q1.15.05 1.3.15h1.5q.75.1 3.85.8 3.05.55 3.05 1.2 0 .5-.55.5-.4 0-.75-.25-7.932-1.792-13.65-.8-.006.024-.05.05-.25.1-1.15.2-.127.01-.25 0-.036.04-.1.05h-.05q-.27.056-.55.1l-.3.1q-1.499.515-2.5.55-.063.024-.15.05v.5q-.05.25-.5 1.85-.5 1.9-1 3.1v1.2q.525-.313 1.6-.8 1.65-.75 2.5-1 2.3-.85 6.15-1.6h3.05q2.5.1 3.45.4.25.05 1.75.85.041.041.95.55.104-1.037.15-1.45l.15-.1q.115-.11.3-.25-.037.013-.1 0h.15q.451-.202 1.2-.4 1.13-.318 2.2-.4l.05-.1q.07-.454.45-2.05-.265-.08-.45-.45-.15-.35-.15-.6v-.15q-.043-.025-.1-.05h.1q.143-.455.9-.75.167-.079.3-.1.16-.608.25-1.1-.565.265-.75.3h-.05q-.217 0-.35-.1-.048-.012-.1-.05-.132.05-.3.05-.4 0-.45-.05h-2.2l-7.9-1.4q-2.75-.55-3.8-.9-.65-.2-2.5-.4-.183-.015-.35-.05-.394-.02-.8-.05-1.5 0-2.45-.8-.091.24-.2.5z"
                    id="AccJew032__Layer5_0_FILL"
                />
                <g id="AccJew032__Layer5_1_FILL">
                    <path
                        fill="#501F12"
                        d="M110.35 283.6h.2q.8-.45 2.3-1.05 9.2-4.7 16.9-.45l.05.05 2.5 1.25q1 .55 1.65-.05.65-.6.3-1.4-.3-.4-.85-.85-1.25-1-2.5-1.45-.25-.1-.5-.15-.75-.3-1.65-.55-7.65-2.8-17.55 1.5-.35.2-.7.45-1.05.8-1.05 1.55 0 .95.9 1.15z"
                    />
                    <path
                        fill="#D8B90A"
                        d="M121.3 272.45q-2.55-.05-4.75.5-.55.05-.85.2-.3.05-2.45.6-2.1.5-2.35 1.4-.3.85.25 1.4 0 .05.05.05.25-.15.6-.3 8.85-3.95 19.75-.15 1.15.45 2.45.95.4.2.9.4h.05l.3-1.55h-.05q-.75-.35-1.45-.65-6.7-2.9-12.45-2.85z"
                    />
                    <path
                        fill="#58D239"
                        d="M121.15 276.65q-2.4.2-3.95.45-2.4.35-4.75.95-.8.15-1.55.4-.1.1-.15.25-.45.75-.45 1.2 0 .65.2 1 .35-.25.7-.45 9.9-4.3 17.55-1.5.9.25 1.65.55.25 0 .55.05 2.3.3 2.6.3.8 0 1.1-1.05.2-.5.25-1l-.05.2q-.45-.1-.85-.15-4.6-1-9-1.15-.2-.05-.3-.05h-3.55z"
                    />
                    <path
                        fill="#5B5526"
                        d="M117.2 277.1q1.55-.25 3.95-.45h3.55q.1 0 .3.05 4.4.15 9 1.15.4.05.85.15l.05-.2.05-.3h-.05q-.5-.2-.9-.4-1.3-.5-2.45-.95-8.5-2.05-14.5-1-3.65.65-5.25 1.15-.35.15-.6.3-.05 0-.05-.05-.55.25-.65.45-.5.75.25 1.5.05 0 .15-.05.75-.25 1.55-.4 2.35-.6 4.75-.95z"
                    />
                    <path
                        fill="#C06"
                        d="M112.95 270.55l-.4-.15q-1.25 1-.2 1.95l4.2.6q2.2-.55 4.75-.5 5.75-.05 12.45 2.85l1.55.2.35-1.7-1.1-.15q-9.25-2.25-18.8-2.95l-2.8-.15z"
                    />
                    <path
                        fill="#03F"
                        d="M113.2 269q-.821.7-.65 1.4l.4.15 2.8.15q9.6-1.9 18.8 2.95l1.1.15.2-.5q.326-.75.05-1.35.55-.15-6-2.75-6.5-2.6-16.7-.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccJew032__Layer5_0_FILL" />
            <use xlinkHref="#AccJew032__Layer5_1_FILL" />
        </g></g>
    )
}

export default AccJew032
