import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF8E8E"
}

function FacMou088({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M203.1 215.3q-.1-.2-.2-.35-.2-.25-.45-.5-1.2-1.45-2.45-2.45-.1-.1-.25-.15-.2-.15-.45-.35-.05-.05-.15-.1-.5-.3-.95-.55-.1-.05-.25-.1-.25-.15-.5-.25l-.2-.1q-2.2-.9-4.25-1H192.05q-2.05.1-4.25 1l-.2.1q-.25.1-.55.25l-.2.1q-.45.25-.95.55-.1.05-.15.1-.25.2-.5.35-.1.05-.2.15-1.25 1-2.5 2.45-.15.15-.25.35-.05.05-.1.15-.15.15-.25.35-1 1.35-1.9 3.05-3.75 6.9-3.75 12.75 0 3.2 1.15 4.6.95 1.25 2.75.75.65-.25 1.45-.75 1.15-.6 2.3-1h.1q.05-.05.15-.1.25-.1.6-.15.05-.05.15-.05 1.25-.5 2.15-.6.25-.05.55-.05 2.5-.2 4.4-.25h.95q1.9.05 4.4.25.3 0 .5.05.9.1 2.2.6.1 0 .15.05.3.05.6.15.1.05.15.1h.1q1.15.4 2.25 1 .8.5 1.5.75 1.8.5 2.75-.75 1.1-1.4 1.1-4.6 0-5.85-3.7-12.75-.9-1.7-1.9-3.05z"
                    id="FacMou088__Layer9_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M18.3 2.15l.4.65q-.2-.45-.45-.85-.1-.2-.2-.3-.15-.25-.35-.5-.05-.1-.15-.2-.15-.1-.25-.15-1.5-1.35-4.2-.4-.5.25-.95.35-.75.2-1.5.3-.2.05-.45.05h-.1q-.2 0-.4-.05-.75-.1-1.5-.3-.45-.1-.95-.35Q4.55-.55 3 .8q-.1.05-.2.15-.1.1-.15.2-.2.25-.35.5-.1.1-.25.3-.2.4-.4.85L2 2.15 0 6.2q.95-.7 1.9-1.05.1-.05.2-.05.3-.2.55-.25.1-.05.15-.05.1-.05.2-.05h.1q.35-.15.7-.25 1.7-.55 3.4-.75 1.3-.25 2.5-.25h.95q1.2 0 2.5.25 1.7.2 3.35.75.4.1.75.25h.05q.1 0 .25.05.05 0 .15.05.25.05.5.25.1 0 .25.05.95.35 1.9 1.05L18.3 2.15z"
                    id="FacMou088__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M6.9 18.8l-.15 3.85q3.35-.95 7.05-.8V17.6q-3.1.05-6.15.9-.35.15-.75.3m-.95.45Q1.8 21.15 0 22.95l.35 1.2q0 .1.35.55.35.4 1.25 0 1.85-1.1 3.85-1.75l.15-3.7M8 4.2l.15-.6h.05l.25-1.9Q8 2 7.7 2.25l.2-.2Q6.45 3 5.45 4.2H8m6.75 0h4.85l-.4-3Q17.25.15 14.75 0v4.2m-.95 0V0q-2.5.15-4.45 1.2l-.45 3h4.9m8.95 18.75q2 .65 3.85 1.75.9.4 1.2 0 .4-.45.4-.55l.35-1.2q-1.8-1.8-5.95-3.7l.15 3.7m-.95-.3l-.15-3.85q-.4-.15-.75-.3-3.1-.85-6.15-.9v4.25q3.7-.15 7.05.8M20.4 4.2h2.7q-.9-1.1-2.2-1.95h-.05Q20.5 2 20.1 1.7l.3 2.5z"
                    id="FacMou088__Symbol_137_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou088__Layer9_0_FILL" />
            <use
                xlinkHref="#FacMou088__Symbol_138_0_Layer0_0_FILL"
                transform="translate(182.35 223.7)"
                id="FacMou088__col2n"
            />
            <use
                xlinkHref="#FacMou088__Symbol_137_0_Layer0_0_FILL"
                transform="translate(178.25 210.6)"
                id="FacMou088__col1n"
            />
        </g></g>
    )
}

export default FacMou088
