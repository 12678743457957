import { AssetSvgProps } from "../../../shared/assets"

function FacEye027({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fillOpacity={0.2}
                    d="M236.5 146.1q-8.35-9-19.35-9.8-11.05-.85-18.3 6.9-5.35 5.75-6.3 13.7-1-7.95-6.35-13.7-7.25-7.75-18.25-6.9-11.05.8-19.4 9.8-.3.3-.55.65l-.3.3q-5 5.65-7 12.4-1.05 3.55-1.3 7.4-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9 3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.2-3.2-1-6.2-1.2-4.7-3.8-8.85-1.7-2.75-4.05-5.3-.15-.2-.3-.35m-31.55-6.25q8.4-4.4 17.9-.9 9.5 3.45 14.55 12.8 5.05 9.25 2.7 19-2.35 9.7-10.75 14.05-8.3 4.4-17.85.95-9.45-3.5-14.55-12.8-5.05-9.35-2.7-19 2.4-9.7 10.7-14.1m-14.65 14.1q2.35 9.65-2.7 19-5.1 9.3-14.55 12.8-9.55 3.45-17.85-.95-8.4-4.35-10.75-14.05-2.35-9.75 2.7-19 5.05-9.35 14.55-12.8 9.5-3.5 17.9.9 8.3 4.4 10.7 14.1z"
                    id="FacEye027__Layer6_0_FILL"
                />
                <path
                    fillOpacity={0.6}
                    d="M187.6 172.95q5.05-9.35 2.7-19-2.4-9.7-10.7-14.1-8.4-4.4-17.9-.9-9.5 3.45-14.55 12.8-5.05 9.25-2.7 19 2.35 9.7 10.75 14.05 8.3 4.4 17.85.95 9.45-3.5 14.55-12.8m35.25-34q-9.5-3.5-17.9.9-8.3 4.4-10.7 14.1-2.35 9.65 2.7 19 5.1 9.3 14.55 12.8 9.55 3.45 17.85-.95 8.4-4.35 10.75-14.05 2.35-9.75-2.7-19-5.05-9.35-14.55-12.8z"
                    id="FacEye027__Layer6_1_FILL"
                />
                <path
                    d="M183.8 142.95q-6-5-14.55-3.6-8.6 1.4-14.6 8.4-6.1 7.05-6.1 15.55t6 13.55q5.95 5.05 14.55 3.6 8.55-1.35 14.6-8.4 6.05-7 6.05-15.55.05-8.55-5.95-13.55m46.15 4.8q-6-7-14.6-8.4-8.55-1.4-14.55 3.6t-5.95 13.55q0 8.55 6.05 15.55 6.05 7.05 14.6 8.4 8.6 1.45 14.55-3.6 6-5.05 6-13.55t-6.1-15.55z"
                    id="FacEye027__Layer6_2_FILL"
                />
            </defs>
            <use xlinkHref="#FacEye027__Layer6_0_FILL" />
            <use xlinkHref="#FacEye027__Layer6_1_FILL" />
            <use xlinkHref="#FacEye027__Layer6_2_FILL" />
        </g></g>
    )
}

export default FacEye027
