import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#773900",
    "col1l": "#D96900",
    "col1n": "#AD5300",
    "col1s": "#271200",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function AccMor003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M48.65 7.25q-.2-2.8-.35-4.55-.05-.45-.05-.9-.1-.95-.25-1.75V0l-9.3 4.2v.05l-.8.3.1.45q.55 8.9-4.35 15.7-4.45 5.25-13.25 7.5l-2.75-14L0 22.4q.15 1.45.25 3.15L0 44q.05 0 .05.05l.1 21.9q.25 2.3 1.4 2.85h.05q.05.05.15.1 1.15.45 3.15-.75L43.65 43.9q3.4-2.15 4-5.55.1-.55.15-1.05V23.6q0-.1.05-.1.2-.9.45-1.85.05-.3.1-.55 0-.1.05-.2.15-.65.2-1.35V7.25z"
                    id="AccMor003__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M23.75 10.8q-.45.8-.5 1.35v.25q-.25.25-.55.45-.05 0-.05.05-.15 0-.3.1h-.05q-.1 0-.15.05-.05 0-.1.05l-.3.95-3.6-.95-16.7 6.95q.45-.15.65-.2-1.2.4-2.1.85 1.65-.65 3.1-.75.1-.05.2-.05 3.75-.1 6.25 3.45.05.1.15.2l18.6-8.2-1.15-5.75-.05-.05-3.35 1.25M52.05 0q-1.5 0-3.1.5l-.7.2v.05l-.85.3 1.2 5.4 9.15-4.05q-.1-.05-.15-.15Q55.55.05 52.05 0z"
                    id="AccMor003__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M12 3.65q-.1-.1-.15-.2Q9.35-.1 5.6 0q-.1 0-.2.05-1.45.1-3.1.75-.8.45-1.3.85-.2.15-.45.45-.3.35-.55 1.8l.05-.15q.15-.55.4-.95.5-.65 1.45-1.05.6-.2 1.2-.3.15-.05.25-.05.2-.05.4-.05 4.45-.4 7.05 4.1 0 .05.05.15.9 1.55 1.3 3.6.05.35.15.6.3 1.95.15 4.3v10.65q.2 3 1.75 5 .15.2.3.35v.05q.05.05.15.1.65.65 1.35 1.15 1.5 1.1 3.15 1.8 1.65.65 3.45.9 5.5.6 12.8-3.2.3-.2.65-.4.9-.4 1.75-.85V28.1l-1.3.6q-3.65 1.85-6.35 2.75l-.3.1q-8.1 2.65-14.05-2.85-.95-1.2-1.3-2.75-.15-.9-.1-1.95V12.85q-.1-1.7-.25-3.15-.05-.35-.1-.65-.05-.35-.15-.6-.35-1.8-1-3.35-.15-.35-.4-.65-.3-.45-.5-.8m50.8-.8l-.05.05q-.1 3.05-.75 5.6-.05.15-.05.3-.45 1.35-1 2.55-1.75 3.8-5.25 6.15L46 23.4v1.85l1.5-.9h.05l.05-.05q4.2-2.55 8.3-5.3 2.75-1.85 4.4-4.6.95-1.6 1.65-3.5 0-.1.05-.1.2-.9.45-1.85.05-.3.1-.55 0-.1.05-.2.15-.65.2-1.35v-4z"
                    id="AccMor003__Symbol_35_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M19.65 30.1q-1.7.7-1.85 2.5v7.2q.65 1.3 1.95.9l4.7-2.6.05-.05q1.7-.95 1.5-2.85v-6.9q-.2-1.15-1.35-.8l-5 2.6M4.35.8q-.35.05-.6.05h-.7Q2.9.8 2.75.8 2.1.75 1.7.6h-.1L1.3.5Q1.1.4.95.3.85.15.75.1L.65 0 0 2.85q.3.65 1.5.85 1.15.25 2.1-.45L4.35.8z"
                    id="AccMor003__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 6.65l2.5-1.4V0L0 1.2v5.45z"
                    id="AccMor003__Symbol_2_0_Layer0_0_FILL"
                />
                <g
                    id="AccMor003__Symbol_101_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M31.8 19.05V19l-1.2-6.05-6.15 1.6-7.85.05L2.95 18q-.35.15-.65.3-.8.45-1.3.85-.2.15-.45.45-.3.35-.55 1.8v42.4q.25 2.75 3 4.25l12.7 5.55q-.3-.15-.5-.4l.05-.05 16.55-54.1M29.1 5.6L28.15.85q-3.1.95-5.5 3.1-2.4 2.15-2.4 5.35.05.15.15.25 0 .1.05.1 0 .05.15.15l.05.1.1.1q.1.05.2.2.15.1.35.2.05 0 .15.05l1.1 2 1.65-1.7q.05-.05.15-.05.05-.05.1-.05.05-.05.15-.05h.05q.15-.1.3-.1 0-.05.05-.05.3-.2.55-.45v-.25q.05-.55.5-1.35.25-.55.8-1.15.45-.8 2.25-1.65m33.05-.75V4.8q-.1-.4-.15-.85-.7-2.4-1.95-3.95L53.3.4 50.95 4l-.05.05 1.15 5.3 4.65 3.5 5.45-8zM47.6 43.5l-.05.05v1.25q.045.136.05.3v-1.6z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccMor003__Symbol_101_0_Layer0_0_FILL"
                transform="translate(222.6 274.2)"
                id="AccMor003__col1n"
            />
            <use
                xlinkHref="#AccMor003__Symbol_61_0_Layer0_0_FILL"
                transform="translate(236.75 279)"
                id="AccMor003__col1d"
            />
            <use
                xlinkHref="#AccMor003__Symbol_52_0_Layer0_0_FILL"
                transform="translate(224.9 271.8)"
                id="AccMor003__col1l"
            />
            <use
                xlinkHref="#AccMor003__Symbol_35_0_Layer0_0_FILL"
                transform="translate(222.6 291.7)"
                id="AccMor003__col1s"
            />
            <use
                xlinkHref="#AccMor003__Symbol_13_0_Layer0_0_FILL"
                transform="translate(242.6 284.1)"
                id="AccMor003__col2n"
            />
            <use
                xlinkHref="#AccMor003__Symbol_2_0_Layer0_0_FILL"
                transform="translate(263.7 314.6)"
                id="AccMor003__col2d"
            />
        </g></g>
    )
}

export default AccMor003
