import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400",
    "col2n": "#8A6900"
}

function AccHat090({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.8 59.1q-.3-.2-.55-.35-13.95-8.5-27.85-14.1-4.15-2.1-4.45-5.85V21.25Q129.2 10.6 128 7.15q-1-2.95-1.75-4.95-.35-.45-.65-.75Q124.65 0 122.4 0q-1.1 0-2.95 1.25Q102.85 15.4 90.4 34.5q-1.75-.15-3.55-.2-1.8-.05-3.6-.05-.7-.05-1.35 0h-1q-.65-.05-1.35 0-1.8 0-3.6.05t-3.55.2Q59.95 15.4 43.35 1.25 41.5 0 40.4 0q-2.25 0-3.2 1.45-.3.3-.65.75-.75 2-1.75 4.95-1.2 3.45-1.95 14.1V38.8q-.3 3.75-4.45 5.85-13.9 5.6-27.85 14.1-.25.15-.55.35.7-.2 1.35-.4Q41.4 45.85 81.4 46.8q40-.95 80.05 11.9.65.2 1.35.4z"
                    id="AccHat090__Symbol_307_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.4 22.6q-1-.9-2.9-2.05-.45-.3-.95-.6-3-1.85-5.35-2.65 4.15.55 7.95 1.8 3.25 1.2 3.9 1.4-5.6-8.7-18.1-20.5-2.3 7.6-1.9 17.35Q0 23.7.65 30.1q8.25-2.55 16.45-4-2.35-1.05-3.75-1.5-3.5-1.3-7.75-1.15 1.75-.3 6.05-.5.35-.05.7-.05 3.7-.2 5.1-.2l-.05-.1m64.65 7.5q.65-6.4.6-12.75.4-9.75-1.9-17.35-12.5 11.8-18.1 20.5.65-.2 3.9-1.4 3.8-1.25 7.95-1.8-2.35.8-5.35 2.65-.5.3-.95.6-1.9 1.15-2.9 2.05l-.05.1q1.4 0 5.1.2.35 0 .7.05 4.3.2 6.05.5-4.25-.15-7.75 1.15-1.4.45-3.75 1.5 8.2 1.45 16.45 4z"
                    id="AccHat090__Symbol_107_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat090__Symbol_307_0_Layer0_0_FILL"
                transform="translate(111.1 42.85)"
                id="AccHat090__col1n"
            />
            <use
                xlinkHref="#AccHat090__Symbol_107_0_Layer0_0_FILL"
                transform="translate(151.15 53.1)"
                id="AccHat090__col2n"
            />
        </g></g>
    )
}

export default AccHat090
