import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#EEEEEE",
    "col2n": "#D9D9D9"
}

function AccJew034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.95 5.3q-.25-.3-.55-.6Q19-.2 11.85 0 9.2.05 6.8.75q-1.45.4-2.85 1.1-.55.25-1.1.55-.15.1-.25.15-.3.2-.55.35l-.1.3q-1 4-1.8 8.2-.1.15-.05.4-.05.05-.05.2v.05q0 .1-.05.2.15-.15.35-.25.05-.1.1-.15 3.6-3.2 8.35-3.6h.5l1.05-.05q8.15.15 12.65 7.15L24.95 5.3m138.3-2.1l-.1-.3q-.25-.15-.55-.35-.1-.05-.25-.15-.55-.3-1.1-.55-1.4-.7-2.85-1.1-2.4-.7-5.05-.75-7.15-.2-12.55 4.7-.3.3-.55.6l1.95 10.05q4.5-7 12.65-7.15l1.05.05h.5q4.75.4 8.35 3.6.05.05.1.15.2.1.35.25-.05-.1-.05-.2V12q0-.15-.05-.2.05-.25-.05-.4-.8-4.2-1.8-8.2z"
                    id="AccJew034__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.3 1.45q-.95 0-1.6.65-.7.65-.7 1.6 0 .95.7 1.6.65.7 1.6.7.95 0 1.65-.7.6-.65.6-1.6 0-.95-.6-1.6-.7-.65-1.65-.65m9.8.8q0-.95-.6-1.6Q10.8 0 9.85 0t-1.6.65q-.7.65-.7 1.6 0 .95.7 1.6.65.7 1.6.7.95 0 1.65-.7.6-.65.6-1.6m6.5.7q-.7-.65-1.65-.65t-1.6.65q-.7.65-.7 1.6 0 .95.7 1.6.65.7 1.6.7.95 0 1.65-.7.6-.65.6-1.6 0-.95-.6-1.6m125.55 0q-.65-.65-1.6-.65-.95 0-1.65.65-.6.65-.6 1.6 0 .95.6 1.6.7.7 1.65.7t1.6-.7q.7-.65.7-1.6 0-.95-.7-1.6m5.5-2.95q-.95 0-1.65.65-.6.65-.6 1.6 0 .95.6 1.6.7.7 1.65.7t1.6-.7q.7-.65.7-1.6 0-.95-.7-1.6-.65-.65-1.6-.65m9.15 2.1q-.65-.65-1.6-.65-.95 0-1.65.65-.6.65-.6 1.6 0 .95.6 1.6.7.7 1.65.7t1.6-.7q.7-.65.7-1.6 0-.95-.7-1.6z"
                    id="AccJew034__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M3.2 2.1q-.15-.05-.25-.15-.45-.5-1.2-.5-.7 0-1.25.5-.5.6-.5 1.25 0 .7.5 1.2.25.2.55.4v-.25q.1-1 .75-1.7.7-.75 1.25-.75h.15M10.75.65Q10.6.6 10.5.5 10.05 0 9.3 0q-.7 0-1.25.5-.5.6-.5 1.25 0 .7.5 1.2.25.2.55.4V3.1q.1-1 .75-1.7.7-.75 1.25-.75h.15m6.95 2.3h.15q-.15-.05-.25-.15-.45-.5-1.2-.5-.7 0-1.25.5-.5.6-.5 1.25 0 .7.5 1.2.25.2.55.4V5.4q.1-1 .75-1.7.7-.75 1.25-.75m125.45 2.3q.5-.5.5-1.2 0-.65-.5-1.25-.55-.5-1.25-.5-.75 0-1.2.5-.1.1-.25.15h.15q.55 0 1.25.75.65.7.75 1.7v.25q.3-.2.55-.4m7.6-3.5q0-.65-.5-1.25Q149.7 0 149 0q-.75 0-1.2.5-.1.1-.25.15h.15q.55 0 1.25.75.65.7.75 1.7v.25q.3-.2.55-.4.5-.5.5-1.2m7.05 2.65q.5-.5.5-1.2 0-.65-.5-1.25-.55-.5-1.25-.5-.75 0-1.2.5-.1.1-.25.15h.15q.55 0 1.25.75.65.7.75 1.7v.25q.3-.2.55-.4z"
                    id="AccJew034__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew034__Symbol_102_0_Layer0_0_FILL"
                transform="translate(109.9 272.4)"
                id="AccJew034__col1n"
            />
            <use
                xlinkHref="#AccJew034__Symbol_42_0_Layer0_0_FILL"
                transform="translate(112.75 274)"
                id="AccJew034__col2n"
            />
            <use
                xlinkHref="#AccJew034__Symbol_21_0_Layer0_0_FILL"
                transform="translate(113.35 274.45)"
                id="AccJew034__col2l"
            />
        </g></g>
    )
}

export default AccJew034
