import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M68.45 192.35q-2.3-.55-3.55 3.1-.8 2.2-1.2 5.1-.4 2.9-.45 3.25-.7 7.9 5.35 11.35 5.15 2.85 10.3 0 1-.7 1.95-1.7 1.15-1.15 1.85-2.1-1.05-5.05-2.8-9.95l-.95 3.7q-.2.5-.35 1.05-.2.6-.45 1.1-.35 1-1.1 1.85-1.35 1.5-3.25 1.5t-3.25-1.5q-1.35-1.45-1.35-3.5 0-1.45.45-3.35l.95-4.05q.6-2.35.4-3.8-.25-1.5-2.55-2.05M38.4 187.2q-4.4.8-5.9 1.5-11.05 5.2-14.6 17.05-3.95 9.6 1.05 16.25 1.6 3.2 1.35 7.3.35 4.9 3.75 10.05.75 1.15 1.65 2.35 2.1 2.2 5.9 4.45 15.8 7.35 29.65 0 2.6-1.55 4.9-3.6-2.55-12.85-2.8-12.15-.25.7-4.25 2.8-4 2.1-9 1.95-11.15.7-14.9-9.45l.45.95q-.5-1.5-.5-1.75v-1.35q.05-3.6.7-6.1-4.45-4.35-2.85-9.95 1.6-5.65 6.7-9.45 5-3.85 7-5.5 1.95-1.7 5.25-3.95 3.25-2.3 10.65-2.8 7.35-.5 9-1.4.65-.4 1.25-.75-4.05-8.2-9.15-15.35-2.3 1-9.05 6.1-8.15 6.1-7.95 8.7.2 2.6-4.9 3.45-1.2.4-3.4.65M31.45.1l-.3-.1h-.1L0 56.15Q.95 59.3 5.2 66.3l6.5 11.2q1.35 2.1 4.2 6.2 6.2 8.95 7.8 11.9 1.65 3.05 5.9 11.1 4.2 8.05 4.2 7.65 1 2.75 5.3 11.65 4.2 8.85 3.25 16.45-.95 7.55-2.65 11.4-1.7 3.8-1.9 4.35 5-.3 13.25-9.8 4.2-4.8 6.2-9.15 3.3-7.4 0-19.05-.5-2.3-1-4.25-1.6-5.7-6.85-16.15-4.95-9.8-10.3-18.45l-4-6.4q-.5-.8-1.15-1.75-2.35-3.6-3.55-5.65-.4-.65-.65-1.1-.1-.15-.15-.25-.25-.5-1.05-1.75-.35-.5-.75-1.1-1.3-2.05-1.7-2.85-.1-.45-.25-.8-.1-.1-.15-.2 1.85-3.3 3.4-5.7l25.95-42.5Q44.35 4.1 31.45.1z"
                    id="SkiTat008__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat008__Symbol_8_0_Layer0_0_FILL"
                transform="translate(190 62.2)"
                id="SkiTat008__col1n"
            />
        </g></g>
    )
}

export default SkiTat008
