import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400",
    "col2n": "#621F00"
}

function ObjMus006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.35 16.75q.3.15.6.35 11.7 6.6 21.95 16.85 3.8 3.8 7.15 7.85l1.25 1.65q5.55 7.4 10.05 15.1 3.6 7.25 5.55 11.4 2.55 5.65 4.4 11.3.1.35.2.75.15.25.2.55h-3.5q0 .15.05.25v.05q.05.1.15.25 0 .2.05.3 0 .1.4 11.85.2 6.15.7 12.3.4 5.6 1 11.2 1.3 11.8 1.3 12v.05h2.95q.05.4.05.5.05.35.05.75h3.9q.05-.25.15-1.25h3.45q1.05-11.45.25-23.25-.1-1.4-.25-2.85-.4-6-1.35-12.1-.4-2.5-.85-5-.35-1.8-.7-3.55 0-.05-.05-.1l-.1-.6-.05-.1q-.05-.3-.1-.55 0-.05-.05-.15h-3.4q0 .15.05.25 0 .55.1.75.1.45.25.95-.2-.5-.3-.95-.1-.2-.1-.75-.05-.1-.05-.25-.15-.4-.25-.85-.15-.6-.3-1.15-1.95-7.05-4.8-13l-7.3-14.4q-6.6-12.1-17.05-22.55-7.95-7.95-16.8-13.85-3.25-2.15-6.6-4.05Q143 0 115.1 0q-.95 0-1.9.05-.95-.05-1.9-.05-27.9 0-50.5 12.7-3.35 1.9-6.6 4.05-8.85 5.9-16.8 13.85-10.45 10.45-17.05 22.55l-7.75 14.4q-2.85 5.95-4.8 13-.15.55-.3 1.15-.15.4-.25.85h-3.4q-.05.05-.05.15-.05.25-.1.55l-.05.1-.1.6q-.05.05-.05.1-.35 1.75-.7 3.55-.45 2.5-.85 5Q1 98.7.6 104.7q-.15 1.45-.2 2.85-.85 11.8.2 23.25H4q.15 1 .15 1.25h3.9q0-.4-.05-.9 0-.05.05-.35h3.1q1.35-13.7 2.15-23.25.6-7.65.8-12.6.5-11.25.45-11.35-.05-.1 0-.1.05-.35.15-.7.05-.1.05-.25h-3.5q.1-.3.2-.55.1-.4.2-.75 1.85-5.65 4.45-11.3 1.95-4.25 4.25-8.4 5.1-9.25 11.75-18.1l1.25-1.65q3.35-4.05 7.15-7.85Q50.75 23.7 62.45 17.1q.3-.2.65-.35 50.1-20.8 100.25 0z"
                    id="ObjMus006__Symbol_92_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M189.65 41.8l1.25 1.65q5.55 7.4 10.05 15.1 3.6 7.25 5.55 11.4 2.55 5.65 4.4 11.3.1.35.2.75.15.25.2.55h.05v.1q.05 0 .05.05.15.65.3 1.35.35 1.75.7 3.55.45 2.5.85 5 .95 6.1 1.35 12.1.15 1.45.25 2.85.8 11.8-.25 23.25h-.2q.05.4.05.5.05.35.05.75h3.9q.05-.25.15-1.25 0-.1.05-.2v-.25q1-11.15.35-22.8-.1-2.25-.3-4.5-.2-2.45-.45-4.8l-1.55-9.65q-.45-2.1-.9-4.1-.2-.5-.3-.95-.1-.2-.1-.75-.05-.1-.05-.25-.15-.4-.25-.85-.15-.6-.3-1.15-1.95-7.05-4.8-13l-7.3-14.4q-6.6-12.1-17.05-22.55-7.95-7.95-16.8-13.85-3.25-2.15-6.6-4.05Q139.6 0 111.7 0q-.95 0-1.9.05-.95-.05-1.9-.05Q80 0 57.4 12.7q-3.35 1.9-6.6 4.05-8.85 5.9-16.8 13.85-10.45 10.45-17.05 22.55L9.2 67.55q-2.85 5.95-4.8 13-.15.55-.3 1.15-.15.4-.25.85H3.8q-.05.75-.1 1l-.05.3q-.6 2.35-1.1 4.75L1 98.25q-.25 2.35-.45 4.8-.2 2.25-.3 4.5-.65 11.9.4 23.25H.6q.15 1 .15 1.25h3.9q0-.4-.05-.9 0-.05.05-.35H4.6q-1.05-11.45-.2-23.25.05-1.4.2-2.85.4-6 1.35-12.1.4-2.5.85-5 .35-1.8.7-3.55.15-.7.3-1.35 0-.05.05-.05v-.1q.1-.3.2-.55.1-.4.2-.75 1.85-5.65 4.45-11.3 1.95-4.25 4.25-8.4 5.1-9.25 11.75-18.1l1.25-1.65q3.35-4.05 7.15-7.85Q47.35 23.7 59.05 17.1q.3-.2.65-.35 50.1-20.8 100.25 0 .3.15.6.35 11.7 6.6 21.95 16.85 3.8 3.8 7.15 7.85z"
                    id="ObjMus006__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.2 72.9q-3.5-.9-5.7 0-2.25 1-3 3.85-.05.2-.1.35v.1q-.1.3-.2.65 0 .15-.05.25-.1.35-.15.7l-.1.4Q1.1 90.8.3 102.85q-.75 11.45.3 23.25H.55q.1.6.15.7.15.55.6 2.05.15.55.25.8 1.2 2.9 4.7 3.95 3.8.6 6.9 0 .5-.15.95-.3 4.4-1.4 3.3-9.9-.15-2.8-.35-6.2-.45-6.4.35-14.35.8-8.65 3.05-19.1.75-3.45.25-5.65-.05-.15-.05-.25-1-3.05-5.5-4.3l-1.95-.65m135.9-57.05q3 1.55 4.25 0 1-1.4.05-3.45Q131.6 0 104.7 0h-1.2q-.45 0-.9.05h-.8V0h-1.2Q75.15-.1 53.3 11.65q-.65.35-1.35.75-1.2 2.05-.15 3.45.8 1.7 4.5 0 5.9-3.25 11.95-5.55 33.9-11.8 68.85 0 6.05 2.3 12 5.55m48.25 57.05q-2.2-.9-5.7 0l-1.95.65q-4.5 1.25-5.45 4.3-.05.1-.1.25-.5 2.2.25 5.65 2.25 10.45 3.1 19.1.75 7.95.3 14.35-.2 3.4-.35 6.2-1.1 8.5 3.3 9.9.45.15.95.3 3.1.6 6.95 0 3.45-1.05 4.65-3.95.1-.25.25-.8.45-1.5.65-2.4 0-.05.1-.4 0-.2.05-.45 1-11.55.3-22.75-.85-12.2-3.7-24 0-.05-.05-.05-.05-.2-.05-.4-.1-.15-.15-.25v-.05q-.05-.1-.05-.25-.1-.3-.2-.65v-.1q-.05-.15-.1-.35-.75-2.85-3-3.85z"
                    id="ObjMus006__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus006__Symbol_92_0_Layer0_0_FILL"
                transform="translate(79.5 50.05)"
                id="ObjMus006__col1n"
            />
            <use
                xlinkHref="#ObjMus006__Symbol_74_0_Layer0_0_FILL"
                transform="translate(82.9 50.05)"
                id="ObjMus006__col1d"
            />
            <use
                xlinkHref="#ObjMus006__Symbol_23_0_Layer0_0_FILL"
                transform="translate(90.05 54.75)"
                id="ObjMus006__col2n"
            />
        </g></g>
    )
}

export default ObjMus006
