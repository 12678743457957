import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function ObjToo006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.55 38.3q2.15-2.55 0-5.65-1.4-1.95-4.5-4.1l-1.25-.9q-.95.2-1.95.2-3.15 0-5.4-2.25-2.15-2.15-2.25-5.05L13.75 5.4Q10.05 2.5 6.9 0-1.15 1.35.2 7.4l18.15 15h.1q1.9-1.85 5.45-1.45 4.1.35 8.05 3.35 2.95 2.3 4.5 5.1 2.05 4.9-.25 7.6l.35.3q8.65 5.55 12.8 1.2l.2-.2z"
                    id="ObjToo006__Symbol_166_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M75.2 44.65q-14.55-12-26.85-24.25-8.25-8.25-15.5-16.55-.05-.15-.1-.25Q28.65-1.75 22.4.85L0 31.9l.6 2.6Q17.75 59.6 50.5 74l3.45.45L73 55.6l.2.2 6.7-7.4q-.55-.45-1.05-.85l-3.65-2.9m-52.95-32.6q.5-1.8 2.4-2.7 1.85-.8 3.95-.25 2.15.55 3.3 2.25 1.15 1.65.7 3.45-.5 1.8-2.35 2.65-1.85.85-4 .25-2.1-.55-3.25-2.2-1.2-1.65-.75-3.45z"
                    id="ObjToo006__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M49.9 44.8l4.1-4.35Q24.15 24.65 4.15 0L0 5.3q17.15 25.1 49.9 39.5z"
                    id="ObjToo006__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.251}
                    d="M21.85 252.2l-4.15 5.3q17.15 25.1 49.9 39.5l4.1-4.35q-29.85-15.8-49.85-40.45z"
                    id="ObjToo006__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo006__Symbol_166_0_Layer0_0_FILL"
                transform="translate(90.1 271.4)"
                id="ObjToo006__col1n"
            />
            <use
                xlinkHref="#ObjToo006__Symbol_53_0_Layer0_0_FILL"
                transform="translate(17.1 223)"
                id="ObjToo006__col2n"
            />
            <use
                xlinkHref="#ObjToo006__Symbol_27_0_Layer0_0_FILL"
                transform="translate(17.7 252.2)"
                id="ObjToo006__col2l"
            />
            <use xlinkHref="#ObjToo006__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo006
