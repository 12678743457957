import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#BD9100",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.15 2.1q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4z"
                    id="AccGla002__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M6.85 40q6.9 6.9 16.6 6.9 9.7 0 16.55-6.9 6.9-6.85 6.9-16.55 0-9.7-6.9-16.6Q33.15 0 23.45 0q-9.7 0-16.6 6.85Q0 13.75 0 23.45 0 33.15 6.85 40m97.05-16.55q0-9.7-6.9-16.6Q90.15 0 80.45 0q-9.7 0-16.6 6.85Q57 13.75 57 23.45q0 9.7 6.85 16.55 6.9 6.9 16.6 6.9 9.7 0 16.55-6.9 6.9-6.85 6.9-16.55z"
                    id="AccGla002__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192 10.6q1.05-1.15-.1-2.3L151.1 18v-1.55h-2.7q-1.2-3.35-3.45-6.35-1.1-1.5-2.4-2.8Q135.2 0 124.85 0T107.2 7.3q-1.35 1.3-2.45 2.8-3.7 4.8-4.6 10.75l.05-.05q0 .1-.05.2-.6-.25-1.25-.45-1.25-.4-2.6-.4t-2.6.4q-.65.2-1.25.45-.05-.1-.05-.2l.05.05q-.9-5.95-4.6-10.75-1.1-1.5-2.45-2.8Q78.1 0 67.75 0 57.4 0 50.05 7.3q-1.3 1.3-2.4 2.8-2.25 3-3.45 6.35h-2.8V18L.6 8.3Q-.55 9.45.5 10.6l40.9 10v2.25h1.5l-.05 3.9-.05-.1q.55 9.3 7.25 16Q57.4 50 67.75 50t17.65-7.35Q92 36.1 92.7 27.1q.05-.35.05-.75.1-.1-.05-2.05 1.05-.65 2.1-.9.75-.2 1.5-.2t1.5.2q1.05.25 2.1.9-.15 1.95-.05 2.05 0 .4.05.75.7 9 7.3 15.55Q114.5 50 124.85 50q10.35 0 17.7-7.35 6.7-6.7 7.25-16l-.05.1-.05-3.9h1.4V20.6l40.9-10m-51.4-1q.25.25.45.5 4.9 5.2 5.8 12.05.2 1.55.2 3.2v.6q-.2 8.9-6.45 15.15-6.55 6.6-15.75 6.6-9.25 0-15.75-6.6-6.35-6.25-6.55-15.15v-.6q0-1.65.2-3.2.95-6.85 5.85-12.05l.5-.5q6.5-6.5 15.75-6.5 9.2 0 15.75 6.5m-57.1 0l.5.5q4.9 5.2 5.85 12.05.2 1.55.2 3.2v.6q-.2 8.9-6.55 15.15-6.5 6.6-15.75 6.6-9.2 0-15.75-6.6-6.25-6.25-6.45-15.15v-.6q0-1.65.2-3.2.9-6.85 5.8-12.05.2-.25.45-.5 6.55-6.5 15.75-6.5Q77 3.1 83.5 9.6z"
                    id="AccGla002__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla002__Symbol_93_0_Layer0_0_FILL"
                transform="translate(191.3 159.75)"
                id="AccGla002__col2n"
            />
            <use
                xlinkHref="#AccGla002__Symbol_72_0_Layer0_0_FILL"
                transform="translate(140.55 141.2)"
                id="AccGla002__col2l"
            />
            <use
                xlinkHref="#AccGla002__Symbol_24_0_Layer0_0_FILL"
                transform="translate(96.25 139.25)"
                id="AccGla002__col1n"
            />
        </g></g>
    )
}

export default AccGla002
