import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A6814A",
    "col1n": "#C69C5D",
    "col2n": "#583E2E"
}
function ObjFoo060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.55 12.25q-.4-1.15-1.25-2.25-.3-.45-1.8-2.25-.7-.9-1.25-1.65-6-7.45-15-5.8-.75.25-1.55.5-2.9 1.15-4.4 1.65-1.4.45-2.65 2.65-.4.6-.9 1.55-.35.85-.45.95-1.15 1.65-2.15 4.75v.1H1.1v.05q-.05.05-.05.1Q0 15.8 0 17.9q0 1.5.75 3.2.15.2.3.55.15.3.35.5.7.8 1.2 1.5.45.75 1.05 1.45.4.45.9.95.05 0 .1.05.05.1.15.2.35.25.75.45.55.35 1.75 1.1 1.05.7 1.55 1.2.8.8 1.3 1.15.8.7 1.75 1.1 1.25.55 2.15.8 5.35 1.3 10.3-1.65v-.05q1.5-1.2 2.7-1.8 2.25-1.2 3.7-3.1.15-.1.25-.2.55-.5.9-1.2 1.4-2.5 1.5-2.7.5-1.25.5-2.7v-1.05q-.1-.9-.25-1.8-.2-1.45-.5-2.5-.2-.45-.6-1.1z"
                    id="ObjFoo060__Symbol_133_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M32.55 0q.05.15.1.25.5 1.5.5 3.45v.2q0 .6-.05 1.15-.05.15-.05.35-.3 1.9-1.3 3.75-1.6 3-4.7 4.65-1.2.65-2.7 1.8-4.95 3-10.3 1.7-.9-.25-2.15-.8-.95-.45-1.75-1.1-.5-.4-1.3-1.2-.5-.5-1.55-1.15-1.2-.75-1.75-1.1-.4-.25-.75-.5Q3.15 9.9 2.25 8.2q-.65-1.3-1-2.8Q1.1 4.5 1 3.55q0-.4-.05-.8Q.9 2.65.9 2.5q0-.85.2-2.25-.05.05-.05.1Q0 3.55 0 5.65q0 1.5.75 3.2.15.2.3.55.15.3.35.5.7.8 1.2 1.5.45.75 1.05 1.45.4.45.9.95.05 0 .1.05.05.1.15.2.35.25.75.45.55.35 1.75 1.1 1.05.7 1.55 1.2.8.8 1.3 1.15.8.7 1.75 1.1 1.25.55 2.15.8 5.35 1.3 10.3-1.65v-.05q1.5-1.2 2.7-1.8 2.25-1.2 3.7-3.1.15-.1.25-.2.55-.5.9-1.2 1.4-2.5 1.5-2.7.5-1.25.5-2.7V5.4q-.1-.9-.25-1.8-.2-1.45-.5-2.5-.2-.45-.6-1.1z"
                    id="ObjFoo060__Symbol_134_0_Layer0_0_FILL"
                />
                <g
                    id="ObjFoo060__Symbol_132_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M13.6 20.6h-1.5q-1.5.55-1.95 1.25-.25.35-.25 1.3 0 .55.25 1.05.45.9 1.7.9H12q1.7-.05 2.8-1.3.4-.45.65-.95.2-.45.2-.65 0-.55-.45-1-.6-.6-1.6-.6m-7.65-3.2q-.35-.3-.75-.5-.45-.25-.75-.2-.5-.1-1 .05-.65.15-1.15.65-.45.55-.45 1.05v.3q.05.3.15.55 0 .05.05.1.4.95 1.65.95 1 0 1.8-.3.4-.15.75-.35l.1-.1q.5-.35.5-.7v-.25q-.05-.05-.05-.1-.1-.4-.65-.9-.1-.1-.2-.25M1.9 4.65q-.45 0-1.15.6Q0 6 0 6.75q0 .2.1.55.05.25.15.55.25.55.6.95.15.15.4.3.3.15.75.15.4 0 .7-.15.6-.2.9-.95.2-.55.2-1.15v-.7q0-.3-.2-.75-.1-.3-.8-.65l-.3-.2q-.05 0-.1-.05-.25-.05-.5 0m9.8 7.15q.3-.3 1.1-1 .75-.65.75-1.25 0-1.25-1.15-1.25h-.05q-1.4 0-2.55.25Q7.5 9 7.5 10.1q0 .4.15.8v.05q.1.45.4.9.25.35 1.05.5.05 0 .15.05.3.05.6.05h.05q.3.05.5.05.65-.05 1.3-.7m6.4-11q-.7-.6-1.55-.75-.95-.2-1.55.2-.4.3-.55.8-.15.4-.15.75 0 .25.05.4v.1q0 .2.1.4 0 .05.05.1.05 0 .05.05.25.35.7.6.5.2 1.5.2.75 0 1-.1.3-.1.4-.35l.3-.2.05-.05q.25-.25.25-.8 0-.7-.65-1.35m7.05 16.65Q24.65 17 24 17q-.25 0-.5.1-.3.1-.5.3-.25.1-.5.25-.05.05-.1.15-.45.4-.45 1.2l-.05.3q0 .3.1.6v.25q0 .1.05.1.1.4.3.6.3.25.75.25h.05q.6 0 1.05-.3.55-.2.95-.7.4-.5.5-.9V19v-.1q.05-.15.05-.3 0-.7-.55-1.15M22.4 12q0-.45-.25-.75-.15-.35-.65-.65-.65-.3-1.45-.25-.9 0-1.45.5-.1.1-.2.25-.05 0-.15.1-.1.05-.15.15-.4.5-.4 1.1 0 .65.5 1.25.2.15.55.35.7.45 1.15.45.4 0 .75-.1.4-.3.8-.8.95-1.2.95-1.6zM8 5.5q.15.05.55.1h.05L8 5.5z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo060__Symbol_133_0_Layer0_0_FILL"
                transform="translate(253.45 269.5)"
                id="ObjFoo060__col1n"
            />
            <use
                xlinkHref="#ObjFoo060__Symbol_134_0_Layer0_0_FILL"
                transform="translate(253.45 281.75)"
                id="ObjFoo060__col1d"
            />
            <use
                xlinkHref="#ObjFoo060__Symbol_132_0_Layer0_0_FILL"
                transform="translate(258 271.75)"
                id="ObjFoo060__col2n"
            />
        </g></g>
    )
}
export default ObjFoo060
