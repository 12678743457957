import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M37.15 5.45Q36 2.25 34.95 1.8 29.4-.65 23.9.25 16.75 1.4 14.35 7.9q-.45 1.2-.75 2.6-.2 1.65-.1 3.65V53q-.1 1.55-.35 2.9-1.25 7.25-6.1 7.25-4.2 0-6.05-3.6-.55-.9-.8-1.8-.2.75-.2 1.5 0 4 2.25 5.65 4.15 3 8.9 0 4.1-1.85 4.4-9.5 0-.8.05-1.55V53h-.05V16.95q0-.25.05-.55.05-.5.15-1 .2-1.4.55-3.05.8-2.9 1.7-4.45l.2-.3q1.75-3.7 9.9-3.7 4.15 0 9.05 1.55m27.95 4.6q-.15-1.15-.4-2.15-1.8-6.4-8.95-7.2-5.95-1.25-11.9 1.4-1.05.45-2.9 3.25 5.6-1.15 9.75-1.15 8.15 0 9.95 3.7.95 1.55 1.9 4.75.55 2.85.75 4.85L63.2 53v.35q.05 1.15.1 2.35.25 7.1 3.8 9.2 5.6 3.5 9.85 0 1.85-1.7 1.85-5.35 0-.75-.15-1.5-.3.9-.8 1.8-1.9 3.6-6.05 3.6-4.9 0-6.15-7.25-.25-1.5-.35-3.2V17.5q0-1.65.05-3.05.05-2.45-.25-4.4z"
                    id="BeaMus037__Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus037__Symbol_54_0_Layer0_0_FILL"
                transform="translate(153.1 199.45)"
                id="BeaMus037__col1n"
            />
        </g></g>
    )
}

export default BeaMus037
