import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF0000"
}

function ShiSho010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M12.65 5.2q.25.2.55.4v-.05q-.4-.25-.8-.55.1.1.25.2m-7-5.2l.15.15V.1L5.65 0m115.4 35.15v.2l.05-.4q-.05.05-.05.1v.1M86.1 29.8l.15-.05-.2.05h.05m30.85-13.75q-11.25 21.35-30.35 30-19 7.8-41.05 0-3.6-1.75-7.1-4-13.4-8.7-25.1-25.4Q14.1 31.1 8.1 40.3l-.05.05-1.7 8.75q-.55 2.7-1.1 5.45L1.8 68.85q-.4 1.5-.75 3.05-.4 1.4-.75 2.85H.25v.35q-.05.1-.05.2L0 76q1.9.45 3.85.85l.9.2q64 18.6 120.95 0 .4-.1.85-.2 1.95-.4 3.9-.85l-4.95-20q-.5-2.2-.9-4.4-.2-.9-.4-1.75l-1.9-9.7q-6.4-8.7-5.35-24.1z"
                    id="ShiSho010__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M84.9 88.3q.3-.05.6-.05l1.7-.05q20.3-1.55 40.5-6.2 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-1.05-4.25q-1.95.45-3.9.85-20.2 4.65-40.5 6.2l-1.7.05q-.3 0-.6.05-5.4.35-10.8.55-.4 0-.8.05-2.4 0-4.8.05v-.05H64.3v.05q-2.4-.05-4.8-.05-.4-.05-.8-.05-5.4-.2-10.8-.55-.3-.05-.6-.05l-4-.3q-.95-.1-1.9-.15h-.3q-.1-.05-.25-.05l-5.95-.55Q20 80.6 5 77.2q-1.95-.4-3.85-.85-.1.3-.15.65l-.05.1-.9 3.5v.25L0 80.9h.2q.25.1.5.15H.85q2.05.5 4.15.95 15 3.4 29.9 5.2l5.95.55q.15 0 .25.05h.3q.95.05 1.9.15l4 .3q.3 0 .6.05 5.4.35 10.8.55.4 0 .8.05 2.4 0 4.8.05v-.05h4.2v.05q2.4-.05 4.8-.05.4-.05.8-.05 5.4-.2 10.8-.55m37.8-85.45q-.45.3-.9.65l-1.55 1.15q-1.25.85-2.55 1.7-.1.1-.2.15-.35 1.95-.85 3.8-.55 2.1-1.3 4.15l-.5 1.4-.6 1.3q-3.8 8.5-11.2 15.55-15.2 14.45-36.65 14.45T29.75 32.7q-7.8-7.4-11.55-16.4-.25-.5-.45-1.05-.4-1.05-.75-2.1-.45-1.4-.8-2.8-.5-1.85-.85-3.7V6.5q-1.4-.9-2.8-1.85L11 3.5Q9.55 2.45 8.2 1.4L6.95.45V.5L6.8.35l-.4-.3.05.1V.2q.75 1.5 1.4 3.15.35 1 .7 2.05l.3 1.1q.35 1.3.7 2.65.7 3.25 1.05 6.95.5 5.2.25 11.3l-.1 2.3q-.15 1.95-.35 3.95-.05.45-.1.95-.05.4-.1.85l-1 5.25.05-.05q4.65-3.4 6.3-15.65l.25-2.6q1.8 3 4 5.8 3.35 4.8 8.15 9.1 4.65 4.2 9.85 7.15 12.85 7.3 29 7.3t29.05-7.3q5.15-3 9.8-7.15 7.2-6.45 11.2-14.15l.05.45q0 .15.05.3 0 .15.05.3.05.4.15.8 1.45 10.7 5.3 14.65l.1.1q.45.4.9.75l-.95-5.05q-.05-.45-.1-.85-.05-.35-.05-.7-.1-.5-.1-.95-.2-1.85-.3-3.65l-.1-1.9q-.05-.8-.05-1.55-.2-7.65.85-13.9.2-1.1.45-2.15.25-1.35.6-2.6.1-.35.2-.65.15-.55.35-1.1.85-2.8 2.1-5.2.05-.15.1-.25l-1.85 1.4q-.95.7-1.9 1.45m-.45 32.45l-.05.4v-.2-.1q0-.05.05-.1M14.35 5.9v.05q-.3-.2-.55-.4-.15-.1-.25-.2.4.3.8.55z"
                    id="ShiSho010__Symbol_72_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho010__Symbol_18_0_Layer0_0_FILL"
                transform="translate(127.25 228.95)"
                id="ShiSho010__col1n"
            />
            <use
                xlinkHref="#ShiSho010__Symbol_72_0_Layer0_0_FILL"
                transform="translate(126.1 228.6)"
                id="ShiSho010__col2n"
            />
        </g></g>
    )
}

export default ShiSho010
