import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00"
}

function AccHat087({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M101.55 12q.35-.8.35-1.7 0-1.7-1.2-2.9-.9-.9-2.05-1.1-.2-.05-.45-.05v.05l-.35-.05Q96.15 6.2 95 7.4q-.85.85-1.1 1.95-.15.45-.15.95v.2q.1 1.45 1.15 2.55l.1.1q.1.15.25.3L77.45 33.1q-5.3 3.15-6.85 0L64.05 8.05q1.3-.15 2.3-1.15.65-.65.95-1.4.25-.65.25-1.45 0-1.7-1.2-2.85-.6-.6-1.3-.9-.7-.3-1.55-.3-1.7 0-2.9 1.2-.4.4-.7.9-.5.85-.5 1.95 0 1.65 1.2 2.85 1 1 2.4 1.15L53.85 33.1h-.05q-2.85 3.35-5.7 0H48L38.85 8.05q.75-.1 1.45-.4.55-.3 1-.75 1.15-1.2 1.15-2.85 0-1.4-.8-2.4-.15-.25-.35-.45-.35-.35-.75-.6l-.4-.2q-.8-.4-1.75-.4h-.3q-.95.05-1.7.5-.5.3-.9.7-.35.35-.6.7-.5.8-.55 1.8v.35Q34.3 5.7 35.5 6.9q1 1 2.35 1.15L31.3 33.1q-1.65 3.3-6.85 0L6.65 13.45q.1-.15.25-.3 1.2-1.2 1.2-2.85V10q0-.2-.05-.35-.1-.75-.45-1.35-.3-.5-.7-.9-.8-.8-1.8-1.05-.5-.1-1.05-.1l-.35.05-.05-.05q-.1 0-.15.05-1.35.1-2.35 1.1Q.05 8.5 0 10.1v.2q-.05 1.65 1.15 2.85 1.2 1.2 2.9 1.2.45 0 .95-.1.45-.1.95-.35l11.2 37.3q2.65.55 5.3 1.05 12.45 2.2 24.9 2.45 2.25.05 4.5.05 13.75-.1 27.5-2.5.15-.05.3-.05 1.15-.2 2.3-.45l1.3-.25q.7-.15 1.45-.3l.05-.05 11.2-37.25q.85.45 1.9.45 1.65 0 2.85-1.2.55-.55.85-1.15z"
                    id="AccHat087__Symbol_304_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2 9.3q-.05-.3-.05-.6 0-1.3.9-2.25.3-.3.65-.45-1.35.1-2.35 1.1Q.05 8.2 0 9.8v.2q-.05 1.65 1.15 2.85 1.2 1.2 2.9 1.2.45 0 .95-.1.45-.1.95-.35l11.2 37.3q2.65.55 5.3 1.05-3.2-2-1.75-4.3 8.5 2.25 19.45 2.9-6.7-1-11.6-2.15-4.7-.85-7.4-2.95-2.55-1.95-3.35-4.95L6.65 13.15q.1-.15.25-.3 1.2-1.2 1.2-2.85v-.3q-.25.65-.8 1.2-.9.95-2.2.95-1.3 0-2.25-.95-.7-.7-.85-1.6m48.95 27.45q1.8-.6 2.85-3.95-2.85 3.35-5.7 0 .85 3.85 2.85 3.95m-8.5-33q0-1.4-.8-2.4.2.45.2 1.05v.15q-.05 1.2-.95 2.05-.9.95-2.2.95-1.3 0-2.25-.95-.9-.9-.9-2.2 0-.5.15-.95.2-.7.7-1.25-.5.3-.9.7-.35.35-.6.7-.5.8-.55 1.8v.35Q34.3 5.4 35.5 6.6q1 1 2.35 1.15L31.3 32.8q-1.65 3.3-6.85 0 8.2 7.7 9.55 0l4.85-25.05q.75-.1 1.45-.4.55-.3 1-.75 1.15-1.2 1.15-2.85m25.1 0q0-1.7-1.2-2.85-.6-.6-1.3-.9l.15.15q.95.95.95 2.25v.15q-.05 1.2-.95 2.05l-.2.2q-.85.75-2 .75-1.3 0-2.25-.95-.35-.35-.55-.7-.35-.7-.35-1.5 0-.3.05-.6-.5.85-.5 1.95 0 1.65 1.2 2.85 1 1 2.4 1.15l5 25.05q2.75 6.5 7.1 2.45l2.35-2.45q-5.3 3.15-6.85 0L64.05 7.75q1.3-.15 2.3-1.15.65-.65.95-1.4.25-.65.25-1.45m34 7.95q.35-.8.35-1.7 0-1.7-1.2-2.9-.9-.9-2.05-1.1.3.2.55.45.95.95.95 2.25v.15q-.05 1.2-.95 2.05l-.3.3q-.8.65-1.9.65-1.3 0-2.25-.95-.3-.3-.45-.6-.35-.6-.4-1.25-.15.45-.15.95v.2q.1 1.45 1.15 2.55l.1.1q.1.15.25.3L82.9 43.35q-.85 1.15-2.05 2.1-2.7 2.1-7.4 2.95-4.9 1.15-11.6 2.15 10.8-.65 19.25-2.85.1 0 .2-.05 1.45 2.25-1.65 4.25 1.15-.2 2.3-.45l1.3-.25q.7-.15 1.45-.3l.05-.05 11.2-37.25q.85.45 1.9.45 1.65 0 2.85-1.2.55-.55.85-1.15z"
                    id="AccHat087__Symbol_202_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat087__Symbol_304_0_Layer0_0_FILL"
                transform="translate(141.55 19.75)"
                id="AccHat087__col1n"
            />
            <use
                xlinkHref="#AccHat087__Symbol_202_0_Layer0_0_FILL"
                transform="translate(141.55 20.05)"
                id="AccHat087__col1d"
            />
        </g></g>
    )
}

export default AccHat087
