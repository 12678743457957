import { AssetSvgProps } from "../../../shared/assets"

function ShiMot097({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot097__Layer1_0_FILL">
                    <path
                        fill="#0040D4"
                        d="M159.2 285v4.5q33.84 2.165 66.5 0V285q-33.449-2.98-66.5 0z"
                    />
                    <path
                        fill="#FF0"
                        d="M159.2 276v4.5q33.25 1.969 66.5 0V276q-33.25-2.735-66.5 0z"
                    />
                    <path d="M159.2 258v4.5q33.442 4.993 66.5 0V258h-66.5z" />
                    <path
                        fill="red"
                        d="M159.2 267v4.5q33.25 3.116 66.5 0V267q-33.443-2.512-66.5 0z"
                    />
                </g>
                <g id="ShiMot097__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#82491E"
                        d="M159.2 262.5v4.5h66.5v-4.5h-66.5z"
                    />
                    <path
                        fill="#9B00C2"
                        d="M159.2 289.5v4.6h66.5v-4.6h-66.5z"
                    />
                    <path
                        fill="#009C2D"
                        d="M159.2 280.5v4.5h66.5v-4.5h-66.5z"
                    />
                    <path fill="#F90" d="M159.2 271.5v4.5h66.5v-4.5h-66.5z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot097__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot097__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot097
