import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1l": "#FF5D5D",
    "col1n": "#FF0000"
}

function ObjToy044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.1 11.35l-2.8-.85-.2-.75v-.1l-.1-.3q-.3-.8-1.5-1.1-1.3-.3-2.9.15l-.4.1q-.25.05-.4.2l-3.6-1.15-.1-.25v-.15l-.05-.25q-.15-.35-.35-.6-.45-.4-1.2-.55-.8-.15-1.75-.1-.25.1-.65.1-.25.1-.5.1h-.1q-.2.15-.35.2-.45-.2-1.05-.3l-2.75-.9-.2-.65q-.1-.1-.1-.15V4L25 3.75q-.25-.75-1.45-1.1-1.3-.25-2.95.2-.15 0-.35.1-.25.05-.45.1L16.15 2l-.05-.35V1.6q-.05 0-.05-.05v-.3Q15.9.9 15.65.7q-.4-.35-1.1-.5-.85-.25-1.75-.15-.35.05-.7.15-.2.05-.5.1h-.05q-.3.05-.5.15-.3.1-.55.25-.9.3-1.55.95-.55.5-.65 1.05-.15.3-.05.65l.1.3.05.1V4L5.9 6.75Q5.45 6.9 5 7h-.05q-1.6.45-2.6 1.35-.9.85-.7 1.65l.05.2.05.1.25.85-2 2.15q4.1 4.65 2.5 9.3 19.3.3 33.8 6.35 4.4-7.25 12.35-8.25-3.75-3.5-2.5-9.35h-.05z"
                    id="ObjToy044__Symbol_129_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M2.65 22.05l11.7-12.7L11.85 0h-.05L.1 12.7q-.65 4.7 2.55 9.35z"
                    id="ObjToy044__Symbol_92_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.2 9.7q-.55-.2-.95-.45-.2-.2-.35-.4h-.05l.5 2.05q.3.7 1.5 1.05 1.3.25 2.85-.2h.1q1.6-.45 2.55-1.35.9-.85.8-1.55L9.6 6.6v.15q-.1.75-.85 1.4-1 .9-2.6 1.35H6.1q-1.6.4-2.9.2m7.85 1.95L11 11.6l.5 2.1q.25.65 1.5 1.05 1.3.3 2.85-.15h.1q1.55-.45 2.55-1.35.95-.9.75-1.65l-.5-2.2q0 .1-.05.15-.05.7-.8 1.45-.95.9-2.6 1.35h-.05q-1.55.35-2.9.1-.95-.25-1.3-.8m5.75-9.5l-.6-1.8-.05-.35q-.05.05-.05.1 0 .7-.7 1.35-1.05.9-2.6 1.4h-.05q-1.65.4-2.9.15-1-.3-1.35-.8l-.05-.05v.2l.5 1.85q.3.75 1.45 1.05 1.3.25 2.95-.15h.05q1.6-.45 2.55-1.4.95-.85.85-1.55M34.45 22.4h-.05L18 17.3q-.65-.25-1.55-.5L.05 11.65 0 11.6l2.55 9.35 34.4 10.75.05.05-2.55-9.35m-4.1-4.35q-.95-.2-1.3-.7l.45 1.95q.25.7 1.5 1.05 1.25.25 2.85-.15h.05q1.65-.45 2.6-1.4.9-.85.8-1.55L36.7 15q0 .1-.05.2-.05.7-.75 1.4-1 .85-2.6 1.35h-.05q-1.6.35-2.9.1m-3.05-2q.9-.85.85-1.65l-.6-2.25v.25q-.05.7-.8 1.4-.95.85-2.6 1.35h-.05q-1.65.4-2.85.1-.65-.1-.95-.4-.3-.2-.4-.4l-.05-.05.5 2.1q.25.75 1.45 1.05 1.3.3 2.85-.15h.15q1.55-.45 2.5-1.35M25.75 5q.05-.2 0-.35l-.55-2.1q0 .05-.1.1 0 .2-.1.45-.05.35-.35.6l-.3.3q-.95.95-2.55 1.35-1.65.45-2.95.15-.55-.15-.95-.4-.25-.15-.45-.4 0-.05-.05-.15l.55 2.2q.3.75 1.5 1.05 1.35.25 2.9-.15 1.65-.5 2.6-1.35.75-.65.8-1.3m8.5.9l-.1-.25v.05q-.05.7-.8 1.4-1 .9-2.55 1.35h-.1q-.7.15-1.3.2-.85.05-1.55-.05-1.05-.25-1.4-.85l-.05-.05.5 2.1q.3.75 1.55 1.05.75.2 1.65.15.55-.1 1.25-.25 1.65-.45 2.6-1.4.9-.85.8-1.65l-.5-1.8m9.45 4.75q.05-.25 0-.4l-.55-2.15v.15q0 .25-.1.4-.15.35-.35.65-.1.15-.4.3-.95.95-2.55 1.4-1.6.45-2.9.1-.6-.1-.9-.35-.3-.2-.45-.4-.05-.1-.15-.15l.6 2.15q.25.75 1.5 1.05 1.3.3 2.95-.1 1.6-.5 2.6-1.4.7-.65.7-1.25z"
                    id="ObjToy044__Symbol_80_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy044__Symbol_129_0_Layer0_0_FILL"
                transform="translate(238.9 272.65)"
                id="ObjToy044__col1n"
            />
            <use
                xlinkHref="#ObjToy044__Symbol_92_0_Layer0_0_FILL"
                transform="translate(273.2 284)"
                id="ObjToy044__col1l"
            />
            <use
                xlinkHref="#ObjToy044__Symbol_80_0_Layer0_0_FILL"
                transform="translate(238.85 274.3)"
                id="ObjToy044__col1d"
            />
        </g></g>
    )
}

export default ObjToy044
