import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF0000"
}

function AccHat094({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M180.1 32.05q-3.65-6.75-8.85-13.25-1.35-1.75-2.9-3.5-2.3-2.75-5-3.55-1.8-.65-3.65-1.25-16-5.2-32.25-7.8-8.15-1.35-16.4-2Q105.1.2 99.1.1 97.2.05 95.3.05q-.35-.05-.7 0-.35-.05-.7 0-1.9 0-3.8.05-6 .1-11.95.6-8.25.65-16.4 2Q45.5 5.3 29.5 10.5q-1.85.6-3.65 1.25-2.7.8-5 3.55-1.55 1.75-2.9 3.5-5.2 6.5-8.85 13.25-2.75 4.95-4.7 10Q1.1 50.5.1 59.25q-.05.4-.1.85-.05 1.95 2.5 1.4Q23.8 54.55 45 50.75q14.55-2.6 29.1-3.7 5.2-.4 10.4-.6 3.35-.15 6.75-.15 1.3-.05 2.65-.05h1.4q1.35 0 2.65.05 3.4 0 6.75.15 5.2.2 10.4.6 14.55 1.1 29.1 3.7 21.2 3.8 42.5 10.75 2.55.55 2.5-1.4-.05-.45-.1-.85-1-8.75-4.3-17.2-1.95-5.05-4.7-10z"
                    id="AccHat094__Symbol_311_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M171.25 18.8q-1.35-1.75-2.9-3.5-2.3-2.75-5-3.55-1.8-.65-3.65-1.25-25.5-.05-49.15 6.95-.7-2.1-2.45-4.9 8.3-5.9 19.35-9.85-8.15-1.35-16.4-2l-6.55 8.75q-3.95-2.85-7.55-3.4L99.1.1Q97.2.05 95.3.05q-.35-.05-.7 0-.35-.05-.7 0-1.9 0-3.8.05l2.15 5.95q-3.6.55-7.55 3.4L78.15.7q-8.25.65-16.4 2Q72.8 6.65 81.1 12.55q-1.75 2.8-2.45 4.9-23.65-7-49.15-6.95-1.85.6-3.65 1.25-2.7.8-5 3.55-1.55 1.75-2.9 3.5h.15l.9-.1q30.2-2.9 58.75 2.7-.1.9-.1 1.9 0 1.1.15 2.15-34.15-2.5-66.15 6.05-.15 0-.3.05-.45.15-.9.25v-.05l-1.35.3q-2.75 4.95-4.7 10l3.15-.85q.25-.1.45-.15 34.25-8.6 70.65-11.8.55 1.5 1.45 2.95-40.6 7.9-75.75 25.15-.05.05-.1.05-1.85.9-3.95 1.8 0-.05.05-.1l-.25.15q-.05.4-.1.85-.05 1.95 2.5 1.4Q23.8 54.55 45 50.75q17.9-8.7 37.8-15.1.65.65 1.4 1.25-6.6 4.3-10.1 10.15 5.2-.4 10.4-.6 1.6-3.6 3.9-6.95 1.85.75 3.95 1.1l-1.1 5.7q1.3-.05 2.65-.05h1.4q1.35 0 2.65.05l-1.1-5.7q2.1-.35 3.95-1.1 2.3 3.35 3.9 6.95 5.2.2 10.4.6-3.5-5.85-10.1-10.15.75-.6 1.4-1.25 19.9 6.4 37.8 15.1 21.2 3.8 42.5 10.75 2.55.55 2.5-1.4-.05-.45-.1-.85l-.25-.15q.05.05.05.1-2.1-.9-3.95-1.8-.05 0-.1-.05Q149.7 40.1 109.1 32.2q.9-1.45 1.45-2.95 36.4 3.2 70.65 11.8.2.05.45.15l3.15.85q-1.95-5.05-4.7-10l-1.35-.3v.05q-.45-.1-.9-.25-.15-.05-.3-.05-32-8.55-66.15-6.05.15-1.05.15-2.15 0-1-.1-1.9 28.55-5.6 58.75-2.7l.9.1h.15z"
                    id="AccHat094__Symbol_109_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat094__Symbol_311_0_Layer0_0_FILL"
                transform="translate(97.9 73.85)"
                id="AccHat094__col1n"
            />
            <use
                xlinkHref="#AccHat094__Symbol_109_0_Layer0_0_FILL"
                transform="translate(97.9 73.85)"
                id="AccHat094__col2n"
            />
        </g></g>
    )
}

export default AccHat094
