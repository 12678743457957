import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col1s": "#49657E"
}

function SkiSki002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.5 95.8q0-39.7-28.05-67.75-16.2-16.2-36.3-23.05-14.7-5-31.4-5t-31.4 5q-20.1 6.85-36.3 23.05Q0 56.1 0 95.8q0 32.25 18.5 56.85l.05.05q.6.75 1.2 1.55l1 1.2L28 163.5q0 .05.05.05l.7.7q3.5 3.4 7.15 6.4v.05l-.05-.05q-5.81 7.718-10.6 17.75-.078.183-.2.35l-.3.7q-.06.17-.15.35-.117.253-.25.5l-2.6 6.15q-5.345 13.608-8.3 30.1-.05.2-.05.4-.15 2.45.15 4.85Q15.7 245.9 31 249.35q.2.05.45.1l.05-.05q.15.05.25.05.2.6.4 1.15.4.95 1.1 1.75 0 .05.05.1v19.45q-3.9.7-7.8 2.5-.6.267-1.2.55-.94.67 0 .8h143.25q1 0 0-.6-.775-.405-1.55-.75-3.9-1.8-7.8-2.5v-19.45q.05-.05.05-.1.7-.8 1.1-1.75.2-.55.4-1.15.1 0 .25-.05l.05.05q.25-.05.45-.1 15.3-3.45 17.45-17.55.3-2.4.15-4.85 0-.2-.05-.4-3.7-20.65-11.15-36.75-4.8-10.45-11.15-18.95h-.05q0-.1-.1-.2 3.65-3 7.15-6.4l.7-.7q.05 0 .05-.05l7.25-8.05 1-1.2q.6-.8 1.2-1.55l.05-.05q18.5-24.6 18.5-56.85z"
                    id="SkiSki002__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M-61.65 211.65v1.5q9.05 9.6 22.45 1.35-15.85 6.1-22.4-2.8 0-.05-.05-.05m24.5 16.1h-.2l-3.9.15-1.3.1q-8.5.6-13.15 1.65-4.7 1-5.35 1.3-.15.05-.25.1 8-1.3 16.1-1.8.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q15.4-.65 30.05 2l4.15.7q1.15.2 2.3.45 1.75.35 3.45.75l.3.1q.1 0 .2.05.15 0 .3.05-.4-.15-.8-.25-1.7-.6-3.45-1.05-.55-.15-1.05-.3-.15-.05-.25-.05-10.8-3-22-3.6-.4-.05-.8-.05-.55-.05-1.15-.05l-6.5-.05m2.95-56.05q-.5.7-.6 1.4-.2 1.1.8 2.25.1.1.25.2 3.05 1.2 6.25 0 1.6-1.65.35-3.6-.1-.15-.15-.25-3.05-1-6.9 0m-24.5 15.15q-.05.05-.05.1v.2l.05-.3m9.35-62.5q-.56.742 0 .9 1.643.25 2.6-.9 11.85-18.1-4.35-32.1 15.327 15.032 1.75 32.1m51.3 79.95l.1-.6q-1.25-1.15-2.25 0-.05.25 0 .55.25 1.6.15 3.25l.1.05q.7.3 1.05-.35l.1-.2q.15-.45.3-.85.25-.8.4-1.55.05-.15.05-.3M44 229.05q.15 0 .35.05l2 .1q.35 0 .65.05 8.1.5 16.1 1.8-.1-.05-.25-.1-.65-.3-5.35-1.3-4.65-1.05-13.15-1.65l-1.3-.1-3.9-.15h-.2l-6.5.05q-.6 0-1.15.05-.4 0-.8.05-11.2.6-22 3.6-.1 0-.25.05-.5.15-1.05.3-1.75.45-3.45 1.05-.4.1-.8.25.15-.05.3-.05.1-.05.2-.05l.3-.1q1.7-.4 3.45-.75 1.15-.25 2.3-.45l4.15-.7q14.65-2.65 30.05-2h.3m19.35-15.8v-1.45q-6.55 8.7-22.3 2.65 13.3 8.15 22.3-1.2m-61.3-66.6q20.85-.35 41.45-13.3Q22.65 144.5 2.05 145q-22.1.45-43.95-11.4 22.1 13.45 43.95 13.05m58.3 40.55v-.1l-.05-.05.05.15m-21.85-3.5Q5.65 179.95.85 161.55q-4.8 18.4-37.65 22.15 32.35-1.5 37.65-19 5.3 17.5 37.65 19m-2.55-12q-3.05-1-6.9 0-.5.7-.6 1.4-.2 1.1.8 2.25.1.1.25.2 3.05 1.2 6.25 0 1.6-1.65.35-3.6-.1-.15-.15-.25m14.85-46.35q.793.018 0-1.4-13.106-16.836 2.05-31.7-16.2 14-4.35 32.1 1.15 1.088 2.3 1z"
                    id="SkiSki002__Kopie_von_hll_9_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M-58.75 186.95v.2l.05-.3q-.05.05-.05.1m4.05-20.2q2.7 1.4 8.05 1.4-13.2-1.9-7.5-11.5-2 1.25-3.3 3.95l-.1.6q-.4 3.25 1.95 5l-2.75 14q-6.55-2.3-7.95-11.15-.45 8.4 7.4 12.5-2.5 2.6-9.55-3 6 6.4 9.35 5.35l-.7 3.5q-.45 2.1-.85 4.05-.25 1.1-.45 2.2-1.5 7.35-2.3 12.15-.75 4.4.45-.6.4-2.5 1.45-6.25.1-.6.25-1.25 1.55-7 2.45-10.75h.05l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85l3.1-15.85m90.2 50.05q-.75.05-1.5.15-13.1 1.2-26.3 1.45-4.5.1-9 .1-17-.1-34.1-1.8-.65-.1-1.2-.15 5.8 1.2 11.9 2 11.25 1.55 23.4 1.85h.55v.1l.5 4.65v.2H-.3q-.225-.01-.45-.05-.05.023-.1 0-.276.016-.55 0-6.719-.258-12.75-1.55 6.351 2.352 12.75 2.8.275.058.55.05.05.022.1 0 .225.032.45.05.112-.005.2 0v.1h.05l.7 5.95v-.4l.05.4.7-6h.65q.25-.05.55-.05 6.4-.5 12.75-2.85-6.05 1.3-12.75 1.55-.25 0-.55.05v-.05H2q-.23.027-.45 0l.55-4.85v-.1h.05q.15 0 .35.05l5.2-.2q9.75-.45 18.75-1.7 5.8-.8 11.25-1.95-.15 0-.3.05-.95.05-1.9.15M1.95 184.45q20.95.15 41.7 7.95-20.95-9.55-41.7-9.9-22.25-.35-44.2 9.9 22.25-8.15 44.2-7.95m57.8 2.65l-.05-.05.05.15v-.1M48.2 168.15q5 0 7.7-1.2l3.05 15.8q.05.1.3 1l.75 3.3v.05q.8 3.65 2.3 10.35l.1.4q.25 1.2.5 2.25 1 3.35 1.35 5.75 1.05 4.3.3.1-1.15-6.65-3.55-18.4l-.95-4.9q.15.1.4.2 3.35 1.55 9.65-5.2-6.65 5.4-9.5 3 3.75-1.95 5.6-4.9 2-3.2 1.75-7.6-.75 4.75-2.95 7.6-2.05 2.65-5.6 3.65l-2.6-12.95q2.75-1.75 2.3-5.25l-.1-.6q-1.25-2.35-3.3-3.95 5.7 9.6-7.5 11.5m10.45-36.9q-1.35 1.05-2.8 2.1l-1.55 1.15q-20.35 14.1-45.8 16l-1.1.05q-3.35.2-6.8.2H.4q-3.45 0-6.8-.2l-1.1-.05q-25.45-1.9-45.8-16l-1.55-1.15q-1.45-1.05-2.8-2.1l-1.15-.85q10 8.75 21.45 14.05Q-19.4 152.3.4 152.3h.2q19.8 0 37.75-7.85 11.45-5.3 21.45-14.05l-1.15.85z"
                    id="SkiSki002__Kopie_von_hll_0_Layer0_0_FILL"
                />
                <filter
                    id="SkiSki002__Filter_1"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    colorInterpolationFilters="sRGB"
                >
                    <feColorMatrix
                        in="SourceGraphic"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 0.75 0"
                        result="result1"
                    />
                </filter>
            </defs>
            <use
                xlinkHref="#SkiSki002__Symbol_10_0_Layer0_0_FILL"
                transform="translate(96.75 58)"
                id="SkiSki002__col1n"
            />
            <use
                xlinkHref="#SkiSki002__Kopie_von_hll_9_0_Layer0_0_FILL"
                transform="matrix(1.00052 0 0 1 191.65 98.75)"
                id="SkiSki002__col1d"
            />
            <use
                filter="url(#SkiSki002__Filter_1)"
                xlinkHref="#SkiSki002__Kopie_von_hll_0_Layer0_0_FILL"
                transform="matrix(1.00052 0 0 1 191.85 98.75)"
                id="SkiSki002__col1s"
            />
        </g></g>
    )
}

export default SkiSki002
