import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D2E1B",
    "col1n": "#794D32",
    "col1s": "#2A180D",
    "col2n": "#000000"
}

function AccHat017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M199.7 11.8q-.8-.65-1.65-1.25-1.8-1.25-3.8-2.3-26.7-14-64.8-4.25-1.15.3-2.25.6-5.35 1.45-10.85 3.35-.95.35-2.7 1.4-.2.4-3.25 1.45-3.1 1.05-9.15.55l-3.25-.3q.425.031-.45-.1-1.4-.25-8.05-2.7-1.35-.35-2.65-.6-1.75-.45-3.45-.75-22.35-4.45-41.1.2-25 5.2-34.75 19.6-1.3 1.9-2.35 4Q1 37.55.2 46.6q-.4 4.1-.05 8.7Q1 66.85 6.4 81.25l.8 2q.7 2.4 1.95 3.05 4.85 2.65 10.4 0 8.2-3.55 9.9-4.45 2.45-1.35 5.05-2.55.55-.3 1.15-.55Q48.4 73 63.7 70.35q7.75-1.35 16.2-1.9 5-.5 10.1-.35 19.6-.15 42.5 3.65Q162 76.7 196.9 88.3q4.3-.4 8.75-6.9 1.85-3.1 3.7-7.25.2-.7.45-1.4 3.25-9.15 4.05-18.8 2-25.8-14.15-42.15M85.65 16.65h-.05v-.25q.002.107.05.25z"
                    id="AccHat017__Symbol_235_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M199.7 11.8q-.8-.65-1.65-1.25-1.8-1.25-3.8-2.3-22.8-11.95-53.8-6.6-5.65.95-11.55 2.5L119.2 7q-.55.15-1.05.35l-1.8.6q-.95.35-2.7 1.4-.2.4-3.25 1.45-3.1 1.05-9.15.55l-3.4-.3-.3-.1q-1.4-.25-8.05-2.7-1.35-.35-2.65-.6-.45-.15-.9-.2l-.05-.05-1.45-.3q-9.4-1.95-18.15-2.3-5.05-.2-9.85.15-.5 0-.95.05-4.05.3-7.95 1-2.65.45-5.25 1.1-25 5.2-34.75 19.6-1.3 1.9-2.35 4Q1 37.55.2 46.6q-.4 4.1-.05 8.7Q1 66.85 6.4 81.25l.8 2q.7 2.4 1.95 3.05 4.85 2.65 10.4 0h.1l.05-.05.2-.1q.3-.15.6-.25.05-.05.15-.05.1-.05.25-.1l.2-.1q.15-.1.3-.15 6.3-2.7 7.9-3.55h.05q31.1-11.2 60.1-13.85 48.95-4.55 91.85 15.1l-.05.15q.4.1.9.25.35.1.7.25l14.05 4.45q4.3-.4 8.75-6.9 1.85-3.1 3.7-7.25.2-.7.45-1.4 3.25-9.15 4.05-18.8 2-25.8-14.15-42.15M172 6.85q30.1 6.6 29.2 37.65.35 12.2-4.55 28.6-40-31.2-70.5-33.25-50.2-2.9-106.4 41.45-.65.45-1.25.95-3.25-7.7-3.95-15.05Q12.7 59.9 13 53.4q-1.3-29 37.35-42.15l2.1-.7q.2-.1.4-.15 14.9-4.7 33.05-2.6-2.6 1.4-4.05 3.1-1.95 2.25-1.95 4.95 0 .9.25 1.75.95 3.85 6.3 6.65Q93 27.7 102.3 27.7t15.85-3.45q5.35-2.8 6.35-6.65.2-.85.2-1.75 0-3.1-2.6-5.65-1.55-1.5-3.95-2.75l1.1-.2q28.15-5.5 52.75-.4z"
                    id="AccHat017__Symbol_503_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M20.25 2.2q.15-.2.15-.4-.1-.2-.25-.3-.2-.15-.4-.1-4 .75-7.35 3.3Q6.45 9 2.7 14.5v.05q-1.5 2.1-2.65 4.4-.1.15-.05.4.05.2.3.3.15.05.4 0 .2-.05.3-.25 1.1-2.25 2.55-4.3h.05Q7.25 9.75 13 5.55q3.2-2.4 6.95-3.1.2-.05.3-.25M104.7 22q.1.2.3.3.2.05.4-.05t.25-.3q.05-.25 0-.4-2.05-3.85-5.15-7.25v-.05q-1.9-2-4.1-3.85-1.45-1.25-3.1-2.4Q86.05 2.8 77.7.45q-.8-.3-1.65-.45-.2-.05-.4.1-.2.1-.2.3-.05.2.1.4.1.2.3.25.8.15 1.55.4h.05q8.15 2.3 15.2 7.35 1.6 1.15 3.1 2.4Q97.9 13 99.7 15v-.05l.05.05q3 3.3 4.95 7z"
                    id="AccHat017__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M106.3 3.7q-.1-.1-.2-.15-.05-.05-.1-.05Q100.9 0 93.75 0q-7.25 0-12.4 3.55l-.5.4q-.4.25-.7.55-1.45 1.2-2.35 2.55l-.1.2q-.15.2-.3.45 0 .05-.05.1-.1.15-.15.3v.05q-.6 1.15-.8 2.4-.1.45-.1.95l-.05.65v.35l.05.4q.3 4.3 4.55 7.5.2.1.4.25 5.25 3.4 12.65 3.4 5.35 0 9.55-1.75 1.4-.7 2.65-1.55 4.75-3.3 5.15-7.85v-.35-.4q0-1.6-.5-3.05-.1-.2-.15-.4v-.05q-.2-.45-.45-.9-.4-.7-.95-1.35-.9-1.2-2.2-2.2l-.7-.5m89.25 71.25q-2.55-1.8-5.05-3.55-70-47.75-118.05-25.25-14.2 4.8-30.85 13.45-.15.05-.25.15-20.6 10.35-39.1 21.8-3.65 1.8-1.35 6.8.9 4 7.25-1.2 4.9-3.55 10.35-6.45 2.45-1.35 5.05-2.55.55-.3 1.15-.55 12.75-5.75 28.05-8.4 7.75-1.35 16.2-1.9 5-.5 10.1-.35 19.6-.15 42.5 3.65 29.55 4.95 64.5 16.55 2.55.85 4.45 1.25 3.65 1.05 5.3-1.05 2.5-3.8 2.2-8.25-.3-2.95-2.45-4.15z"
                    id="AccHat017__Symbol_60_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat017__Symbol_235_0_Layer0_0_FILL"
                transform="translate(85.35 43.9)"
                id="AccHat017__col1n"
            />
            <use
                xlinkHref="#AccHat017__Symbol_503_0_Layer0_0_FILL"
                transform="translate(85.35 43.9)"
                id="AccHat017__col1d"
            />
            <use
                xlinkHref="#AccHat017__Symbol_115_0_Layer0_0_FILL"
                transform="translate(136.95 56.45)"
                id="AccHat017__col1s"
            />
            <use
                xlinkHref="#AccHat017__Symbol_60_0_Layer0_0_FILL"
                transform="translate(94.7 47.65)"
                id="AccHat017__col2n"
            />
        </g></g>
    )
}

export default AccHat017
