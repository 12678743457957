import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function ObjToo074({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M8 7.1q-.05-.05-.1 0Q4.35 6.25.8 8q-.3-.05-.4.05-.2.2-.2.45-.3 3.55-.1 7.05.1 3.05.65 6.15 1.5.2 3.05.7 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.6 2.35-1.6 3.9 1.55 2.6 3.45 5.2.85 1.75 2.05 2.05 1.25.4 2.9-.65 2.55-1.85 1.35-4.4-.1-.25-.2-.4-.1-.1-.15-.25l-.05-.05Q9.8 29.65 8.7 15.55q-.35-3.95-.1-7.9.05-.25-.15-.4-.15-.2-.4-.2-.05 0-.05.05M19.15.15Q18.95 0 18.7 0q-.2 0-.4.25-3.55 1.65-5.4 4.65-.05 0-.1.1-.15.15-.15.4.05.25.2.4 5.3 4.3 9.45 9.75 6.85 9 10.45 21.1l.05.05q.05.15.1.25.05.2.15.45 1.05 2.6 4.15 1.95h.05q1.85-.5 2.35-1.65.6-1.1-.15-2.9-2.2-10.85-6.9-19.25-5.2-9.15-13.4-15.4z"
                    id="ObjToo074__Symbol_235_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M23.25 23.7q-.1-.05-.1-.15l-2.5-2.45q-.55-.05-1-.1-.6-.05-1.1-.15-.05-1.05-.5-2.05-.7-1.65-2.2-2.55l.5-1.55-.2-.5Q11.55 5.55 5.9 0L4.85.45l1.55 4.9q.1.05.15.05.4.05.75.25 1.1.5 1.6 1.7.55 1.2.2 2.4-.25.65-.7 1.1l1.65 4.8q-.35.1-.7.25l-.6.3-2.45-4.4q-.7.05-1.3-.25Q3.9 11 3.35 9.8q-.5-1.2-.15-2.35.1-.35.3-.7.05-.05.1-.15L1.05 2.1 0 2.6q.25 7.9 3.5 17.15l.2.5 1.55.65q-.3 1.7.4 3.35.45 1 1.2 1.75-.3.45-.65.95-.25.35-.65.8l.2 3.45q-.05.1 0 .25l7.1-.9q.05-.05.1 0-.15-1.1-.25-1.9-.05-.5-.05-.9.85-.2 1.7-.55.8-.35 1.5-.85.25.3.6.65.6.6 1.3 1.45.05-.1.1-.1l5.4-4.65z"
                    id="ObjToo074__Symbol_237_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M4.65 1.1L4.05 0Q1.6 1.4.5 3.9q-.35.75-.5 1.4l1.15.5q.15-.7.45-1.4.9-2.2 3.05-3.3m7.1 10.15q.25.3.6.65 1.7-1.25 2.5-3.2.65-1.4.7-2.8-.6-.05-1.1-.15.05 1.35-.6 2.75-.7 1.7-2.1 2.75z"
                    id="ObjToo074__Symbol_236_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo074__Symbol_235_0_Layer0_0_FILL"
                transform="translate(113.75 266.8)"
                id="ObjToo074__col1n"
            />
            <use
                xlinkHref="#ObjToo074__Symbol_237_0_Layer0_0_FILL"
                transform="translate(108.8 243.35)"
                id="ObjToo074__col2n"
            />
            <use
                xlinkHref="#ObjToo074__Symbol_236_0_Layer0_0_FILL"
                transform="translate(112.9 258.45)"
                id="ObjToo074__col2d"
            />
        </g></g>
    )
}

export default ObjToo074
