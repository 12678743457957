import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.95 93.75v-.05q-.082.082-.1.15.019-.06.1-.1m-4.3-70.45q-.7-4.9-1.4-7.7-.9-4.2-3-8.25Q30.5 4 28.5 2.15q-5.1-4.3-12.85 0-5.2 2.7-9.75 12.5-1.5 3.2-2.2 5.85Q.75 29.25.25 38.05q-.2 2.6-.1 5.2-.15 2.6-.15 5.1v1.1q.05 4.3.55 8.25.6 7.05 1.5 10.6 1 4 5.25 12.4 3.85 7.65 8.7 12.8 5.05 5.4 10.65 7.45-.1.3-.2.65.2-.3.45-.6 10.25-14.05 22.15-24.3.15-1.2-.35-3.7-.55-2.55-2.45-7.55Q44.3 60.4 42 57.5q-.75-.95-1.4-1.85-1.3-2-2-3.85-.85-2.1-.85-4-.05-.7.05-1.35.65-3.85.55-8.4-.1-3.25-.55-6.85-.3-1.4-1.15-7.9M175.5 93.85q-.018-.068-.1-.15v.05q.081.04.1.1m40.85-45.5q0-2.5-.15-5.1.1-2.6-.1-5.2-.5-8.8-3.45-17.55-.7-2.65-2.2-5.85-4.55-9.8-9.75-12.5-7.75-4.3-12.85 0-2 1.85-3.75 5.2-2.1 4.05-3 8.25-.7 2.8-1.4 7.7-.85 6.5-1.15 7.9-.45 3.6-.55 6.85-.1 4.55.55 8.4.1.65.05 1.35 0 1.9-.85 4-.7 1.85-2 3.85-.65.9-1.4 1.85-2.3 2.9-4.25 7.95-1.9 5-2.45 7.55-.5 2.5-.35 3.7 11.9 10.25 22.15 24.3.25.3.45.6-.1-.35-.2-.65 5.6-2.05 10.65-7.45 4.85-5.15 8.7-12.8 4.25-8.4 5.25-12.4.9-3.55 1.5-10.6.5-3.95.55-8.25v-1.1z"
                    id="FacEar016__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.95 5.55q-.25-.45-1.1-2.45-.9-1.95-2.65-2.65-1.8-.7-2.8-.3-1.05.35-2.25.95-1.25.6-2.95 2.65-1.7 2-3.45 5Q1.95 18.55.5 29.8q-.2 2-.3 3.95-2.25 40.15 22.35 52.1 2.5 1.15 5.1 1.15.5 0 1-.1.35-.1.7-.25-.8-.25-1.95-1.45-1.35-1.35-1.9-2.4-.75-1.2-1.65-4.3-.9-3.1-.9-4.1-.05-1.05-.2-4.75-.2-3.75.8-9.65.55-5.25 2.2-11.65 1.4-5.7 1.95-14.8-.55-10.15-.8-11.6l-.1-.65-.1-.25q-.1-.7-.25-1.55-1-5.9-1.45-7.6-.8-3.05-1.8-5.65 0-.2-.25-.7m185 28.2q-.1-1.95-.3-3.95-1.45-11.25-7.25-21.05-1.75-3-3.45-5-1.7-2.05-2.95-2.65-1.2-.6-2.25-.95-1-.4-2.8.3-1.75.7-2.65 2.65-.85 2-1.1 2.45-.25.5-.25.7-1 2.6-1.8 5.65-.45 1.7-1.45 7.6-.15.85-.25 1.55l-.1.25-.1.65q-.25 1.45-.8 11.6.55 9.1 1.95 14.8 1.65 6.4 2.2 11.65 1 5.9.8 9.65-.15 3.7-.2 4.75 0 1-.9 4.1-.9 3.1-1.65 4.3-.55 1.05-1.9 2.4-1.15 1.2-1.95 1.45.35.15.7.25.5.1 1 .1 2.6 0 5.1-1.15 24.6-11.95 22.35-52.1z"
                    id="FacEar016__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M17.25 11.9q-.8-3.05-1.8-5.65 0-.2-.25-.7-.25-.45-1.1-2.45-.9-1.95-2.65-2.65-1.8-.7-2.8-.3Q7.6.5 6.4 1.1q-1.25.6-2.95 2.65-1.7 2-3.45 5 .65-.55 3.05-2.8 2.5-2.1 4.3-2.1 2.95 0 4.4 2.95 1.4 2.95 2.65 7.8 1.2 4.8 1.75 9.55.5 4.75.45 8.25v3.55q0 3.2-.65 4.75-.9 2.1-3 2.1-1.9 0-4.2-1.9-.05.15-.05.3v3.15q0 3.45 1.15 7.55.55 1.85 1.65 5.8-1.15-.55-2.1-1.05-2.4-1.3-3.95-3.05v.15q0 .05 1.1 5.05.75 3.55 3.35 6.9h-.15q-.6-.25-2.4-1.25-1.65-.8-2.15-1 2.45 5.95 2.75 6.6 2.4 4.55 4.95 5.6-.9-.1-3.65-1.05h-.8q.1.2 2.4 3.9 2.25 3.7 4.35 5.35 2.1 1.6 3.1 2.1.95.45 2.6.95.35-.1.7-.25-.8-.25-1.95-1.45-1.35-1.35-1.9-2.4-.75-1.2-1.65-4.3-.9-3.1-.9-4.1-.05-1.05-.2-4.75-.2-3.75.8-9.65.55-5.25 2.2-11.65 1.4-5.7 1.95-14.8-.55-10.15-.8-11.6l-.1-.65-.1-.25q-.1-.7-.25-1.55-1-5.9-1.45-7.6m175.4-3.15q-1.75-3-3.45-5-1.7-2.05-2.95-2.65-1.2-.6-2.25-.95-1-.4-2.8.3-1.75.7-2.65 2.65-.85 2-1.1 2.45-.25.5-.25.7-1 2.6-1.8 5.65-.45 1.7-1.45 7.6-.15.85-.25 1.55l-.1.25-.1.65q-.25 1.45-.8 11.6.55 9.1 1.95 14.8 1.65 6.4 2.2 11.65 1 5.9.8 9.65-.15 3.7-.2 4.75 0 1-.9 4.1-.9 3.1-1.65 4.3-.55 1.05-1.9 2.4-1.15 1.2-1.95 1.45.35.15.7.25 1.65-.5 2.6-.95 1-.5 3.1-2.1 2.1-1.65 4.35-5.35 2.3-3.7 2.4-3.9h-.8q-2.75.95-3.65 1.05 2.55-1.05 4.95-5.6.3-.65 2.75-6.6-.5.2-2.15 1-1.8 1-2.4 1.25h-.15q2.6-3.35 3.35-6.9 1.1-5 1.1-5.05v-.15q-1.55 1.75-3.95 3.05-.95.5-2.1 1.05 1.1-3.95 1.65-5.8 1.15-4.1 1.15-7.55V41.2q0-.15-.05-.3-2.3 1.9-4.2 1.9-2.1 0-3-2.1-.65-1.55-.65-4.75V32.4q-.05-3.5.45-8.25.55-4.75 1.75-9.55 1.25-4.85 2.65-7.8 1.45-2.95 4.4-2.95 1.8 0 4.3 2.1 2.4 2.25 3.05 2.8z"
                    id="FacEar016__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar016__Symbol_42_0_Layer0_0_FILL"
                transform="translate(84.35 1.65)"
                id="FacEar016__col1n"
            />
            <use
                xlinkHref="#FacEar016__Symbol_41_0_Layer0_0_FILL"
                transform="translate(88.45 5.95)"
                id="FacEar016__col2n"
            />
            <use
                xlinkHref="#FacEar016__Symbol_40_0_Layer0_0_FILL"
                transform="translate(96.2 5.95)"
                id="FacEar016__col2d"
            />
        </g></g>
    )
}
export default FacEar016
