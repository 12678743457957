import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSpe008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.25 78.2q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55m1.8-17.6q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2m19.1-7.05q.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25M30 39.4q-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6m-3.15-7.95q0 .35.25.55.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6M14.3 46.2q.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25m1.95-9.8q.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25M159 46.2q0 .35.25.55.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6m1.5-7.75q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.15-.15.2-.35v-.2q0-.35-.2-.6-.25-.2-.6-.2m-8.7 10.75q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2m-6.4-7.85q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2m8-7.7q.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25m-4.05-11.3q0-.2-.05-.35v-3.85q-.05-1.45-1.15-2Q117.15.05 87.45 0 58.2.3 27.65 16.15q-1.1.55-1.15 2v3.35q-.05.25 0 .55v.1q.1 1.65 2.25 4.2Q30.2 28 32.8 30.6q1.75 1.65 2.9 2.85.25.2.45.45.25.2.55.45.2.1.35.25 1.8 1.3 4.9 1.45h91.9q3.1-.15 4.9-1.45.15-.15.35-.25.3-.25.55-.45.2-.25.45-.45 1.15-1.2 2.9-2.85 2.6-2.6 4.05-4.25 2-2.4 2.3-4m27.05 48.6q.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25m-7.7-14.7q0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55z"
                    id="HaiSpe008__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M91.9 11V1q-.2.1-.35.25-.4 1.7-1.95 2.55-.65-1.85-2.15-3-.5 2.2-2.55 3.45-.5-2-1.85-3.45-.65 2.15-1.9 3.35Q80 2.2 78.3 1.25q-.4 2-1.5 3.15-.4-1.7-1.85-3.2-.3 1.6-1.75 3.05-.35-1.9-1.8-3.5-.75 2.15-1.9 2.95-.3-1.8-1.8-3.1-.45 2.35-2.2 3.5Q65 2.2 63.6.8q-.55 2.1-2.05 2.6-.5-1.65-2.5-2.3-.25 1.65-1.65 2.7.1-1.6-.95-3.2-.55 1.95-1.85 3-.4-1.65-1.9-2.6-1.1 2-2.4 2.75L48.45 0Q47.2 1.75 45.8 3.7q-.5-.65-.85-1.4-.4-.8-1.5-2.3L41.6 3.75Q40.3 3 39.2 1q-1.5.95-1.9 2.6-1.3-1.05-1.85-3-1.05 1.6-.95 3.2-1.4-1.05-1.65-2.7-2 .65-2.5 2.3-1.5-.5-2.05-2.6-1.4 1.4-1.9 3.3-1.75-1.15-2.2-3.5-1.5 1.3-1.8 3.1-1.15-.8-1.9-2.95-1.45 1.6-1.8 3.5-1.45-1.45-1.75-3.05-1.45 1.5-1.85 3.2-1.1-1.15-1.5-3.15-1.7.95-2.85 2.9Q9.5 2.95 8.85.8 7.5 2.25 7 4.25 4.95 3 4.45.8q-1.5 1.15-2.15 3Q.75 2.95.35 1.25.2 1.1 0 1v10h91.9z"
                    id="HaiSpe008__Symbol_55_0_Layer0_0_FILL"
                />
                <g
                    id="HaiSpe008__Symbol_79_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path
                        fillOpacity={0.251}
                        d="M43.65 17.5q-1.15-1.2-2.9-2.85-2.6-2.6-4.05-4.25-2.15-2.55-2.25-4.2-3.3 2.75-6.4 5.85Q0 40.1 0 79.8q0 4.5.35 8.85Q3.45 81.4 6.4 73.4q1.55-4.25 3.1-8.7 4.5-13 9.8-14.45.7-.2 1.3-.4 3.55-1.15 7.75-7.1 10.9-14.6 21.55-22.65-3.1-.15-4.9-1.45-.15-.15-.35-.25-.3-.25-.55-.45-.2-.25-.45-.45M23.6 20.2q-.35 0-.55-.25-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6t-.2.55q-.25.25-.6.25M36.4 15q0 .35-.2.55-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6m1.75 8.55q0 .35-.2.55-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6M10.6 44.35q.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2M22.25 28.6q.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2m5.45 7.95q0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55M8.75 60.4q.35 0 .6.2.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2M171.1 49.85q.6.2 1.3.4 5.3 1.45 9.8 14.45 1.55 4.45 3.1 8.7 2.9 7.9 5.95 15.05.35-4.25.35-8.65 0-39.7-28.05-67.75-3.05-3.05-6.25-5.8-.2 1.65-2.3 4.15-1.45 1.65-4.05 4.25-1.75 1.65-2.9 2.85-.25.2-.45.45-.25.2-.55.45-.2.1-.35.25-1.8 1.3-4.9 1.45 10.65 8.05 21.55 22.65 4.2 5.95 7.75 7.1M168.45 22q.35 0 .6.2.2.25.2.6v.2q-.05.2-.2.35-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2m-.1 7.15q.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2m6.7 10.65q0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55m9.3 14.7q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6t-.2.55m-23.8-38.1q0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55m-6.4 9.3q0 .35-.2.55-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2.35 0 .6.2.2.25.2.6m5.6 7.05q.35 0 .6.2.2.25.2.6t-.2.55q-.25.25-.6.25t-.55-.25q-.25-.2-.25-.55 0-.35.25-.6.2-.2.55-.2z"
                    />
                    <path d="M42.65 6.25L41.5 7.4l-1.15-3.5q-.45.95-.85 2-.8-1.75-1.25-2.8-.45-1.2-.55-1.55-.05.2-.55.8-.55.6-.7.65l-.8-3-1.2 2.15v3.4q-.05.3 0 .65.1 1.65 2.25 4.2 1.45 1.65 4.05 4.25 1.75 1.65 2.9 2.85.25.2.45.45.25.2.55.45.2.1.35.25 1.8 1.3 4.9 1.45V10.05q-.1-.1-.25-.2l-.1.1q.05 0 .05.05-.05.05-.2.15l.15-.2-2.05 2.1-1.6-4.15q-.1-.1-.15-.2-.2.4-1.1 1.75h-.15q-.15-.2-1.85-3.2m114.6-.7v-3.4L156.05 0l-.8 3q-.15-.05-.7-.65-.5-.6-.55-.8-.1.35-.55 1.6-.5 1.05-1.25 2.75-.4-1.05-.85-2l-1.15 3.5-1.15-1.15q-1.7 3-1.85 3.2h-.15q-.9-1.35-1.1-1.75-.05.1-.15.2l-1.6 4.15-2.05-2.1.15.2q-.15-.1-.2-.15 0-.05.05-.05l-.1-.1q-.15.1-.25.2V20.1q3.1-.15 4.9-1.45.15-.15.35-.25.3-.25.55-.45.2-.25.45-.45 1.15-1.2 2.9-2.85 2.6-2.6 4.05-4.25 2.1-2.5 2.3-4.15 0-.4-.05-.7z" />
                </g>
            </defs>
            <use
                xlinkHref="#HaiSpe008__Symbol_140_0_Layer0_0_FILL"
                transform="translate(104.6 57.55)"
                id="HaiSpe008__col1n"
            />
            <use
                xlinkHref="#HaiSpe008__Symbol_79_0_Layer0_0_FILL"
                transform="translate(96.65 74)"
                id="HaiSpe008__col1d"
            />
            <use
                xlinkHref="#HaiSpe008__Symbol_55_0_Layer0_0_FILL"
                transform="translate(146.55 83.05)"
                id="HaiSpe008__col1l"
            />
        </g></g>
    )
}

export default HaiSpe008
