import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiMot010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M39.65 19.8q0-8.2-5.8-14Q29.05 1.05 22.7.2 21.3 0 19.85 0 11.6 0 5.8 5.8 0 11.6 0 19.8t5.8 14q5.8 5.8 14.05 5.85 8.2-.05 14-5.85 5.8-5.8 5.8-14m-7.3-12.55q5.2 5.2 5.2 12.55 0 7.35-5.2 12.55-5.15 5.2-12.5 5.2-7.4 0-12.6-5.2Q2.1 27.15 2.1 19.8q0-7.35 5.15-12.55 5.2-5.15 12.6-5.15 7.35 0 12.5 5.15m-18.6 3.25q-1.25 0-2.1.9-.9.85-.9 2.1t.9 2.15q.85.9 2.1.9t2.15-.9q.9-.9.9-2.15t-.9-2.1q-.9-.9-2.15-.9m16.2 11.6q-.35.25-.4.65-.05.3-.1.55-.65 2.4-2.7 4.25v.05q-.7.65-1.45 1.15-2.5 1.6-5.65 1.6-3.15 0-5.65-1.6-.75-.5-1.45-1.15-2.05-1.9-2.65-4.3 0-.05-.05-.1-.05-.25-.05-.45-.1-.4-.45-.65t-.75-.2q-.45.05-.7.4-.25.3-.2.7.05.4.2.8.7 2.8 2.9 4.95.15.1.25.2 3.6 3.35 8.6 3.35t8.6-3.3v-.05l.2-.2q2.3-2.2 3.05-4.95.1-.4.15-.8.05-.4-.2-.7-.3-.35-.7-.4-.45-.05-.8.2M26 10.5q-1.25 0-2.15.9-.9.85-.85 2.1-.05 1.25.85 2.15.9.9 2.15.9t2.15-.9q.9-.9.9-2.15t-.9-2.1q-.9-.9-2.15-.9z"
                    id="ShiMot010__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot010__Symbol_16_0_Layer0_0_FILL"
                transform="translate(172.65 261.3)"
                id="ShiMot010__col1n"
            />
        </g></g>
    )
}

export default ShiMot010
