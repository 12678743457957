import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function ObjToy070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M63.9 18.25q-.2 1.65-.15 3.3-.85-9-2.2-16.05-1.4-7.05-6.65-5.05L5.7 6.6q-3.25.65-4.6 1.75-.5.4-.75.9-.45.8-.35 1.9l3.65 29.4q1.55 4.05 6.8 3.6l9.2-1.15q-.05-.2-.05-.4 0-1.9 1.5-3.25.25-.25.55-.45.55-.4 1.2-.7 2.4-1.25 6.35-1.25l-.35.1q1.35-.15 2.75.05 2.6.4 5.4 2.05h.05q1.25.75 2.1 1.45l18.65-2.3q4.6-.7 5.95-3.35.35-.8.45-1.8.1-5-.3-10 .3 2.3 1.2 4.55 5.25-4 6.65-10.3l-7.85.85m109.15-6.85q-1.55-2-2.45.4l3.35 21.2q1.55 1.25 1.4 5.05 0 1.2-.3 1.85l1.6 9.9 2.45-.4-.2-1.45q-1.3-1.7-1.8-3-1.35-3.3-1.35-6.8 0-2.55.45-5.3.05-.4.15-.8l-3.3-20.65z"
                    id="ObjToy070__Symbol_189_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M26.6 19.55q-.1.05-.1.2l.7 5.85q0 .1.15.2.05.05.2.05l5.85-.7q.1 0 .2-.1.05-.1.05-.2l-.7-5.85q0-.15-.1-.2-.1-.1-.2-.1l-5.85.7q-.15 0-.2.15m5.8-.2l.65 5.25-5.25.65-.65-5.25 5.25-.65m-.35-7.75q0-.15-.1-.2-.1-.1-.2-.1l-5.85.7q-.15 0-.2.15-.1.05-.1.2l.7 5.85q0 .1.15.2.05.05.2.05l5.85-.7q.1 0 .2-.1.05-.1.05-.2l-.7-5.85m-5.15 6.25l-.65-5.25 5.25-.65.65 5.25-5.25.65m44.2 3.9l-.45-3.7L64 28.35 60.85 3.2Q60.05-.25 56.3 0l-52 6.45q-2.2.3-3.25 1.3Q.8 8 .6 8.3q-.3.3-.6.7 1.35-1.1 4.3-1.25l49.5-6.1Q58.7 1 59.45 4.3l3.65 29.5q-.1 1-.45 1.8l1.85-3.15.05.05 6.55-10.75z"
                    id="ObjToy070__Symbol_190_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M50.9 3.8l-.25-1.9L60.7.65 60.6 0 49.8 1.3l.25 1.9-39 4.85-.25-1.9L0 7.5l.1.65L10.15 6.9l.25 1.9 40.5-5z"
                    id="ObjToy070__Symbol_191_0_Layer0_0_FILL"
                />
                <path
                    d="M154.75 272.85l-5.15.65.75 6 4.4-6.65m-18.7 17.8l-.2-1.65-4.85.6.2 1.65 4.85-.6m-7.55 1.95l-.3-2.65-11.05 1.35q2.6.4 5.4 2.05h.05l5.9-.75m-21.3.5q.55-.4 1.2-.7l-1.85.2.1.95q.25-.25.55-.45m-2.4-.25l-1.8.2.2 1.7 1.8-.25-.2-1.65z"
                    id="ObjToy070__Layer2_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy070__Symbol_189_0_Layer0_0_FILL"
                transform="translate(85.55 254.2)"
                id="ObjToy070__col1n"
            />
            <use
                xlinkHref="#ObjToy070__Symbol_190_0_Layer0_0_FILL"
                transform="translate(86.65 253.55)"
                id="ObjToy070__col1d"
            />
            <use
                xlinkHref="#ObjToy070__Symbol_191_0_Layer0_0_FILL"
                transform="translate(88.65 283.2)"
                id="ObjToy070__col1s"
            />
            <use xlinkHref="#ObjToy070__Layer2_0_FILL" />
        </g></g>
    )
}

export default ObjToy070
