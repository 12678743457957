import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#CB9AFF",
    "col2n": "#FFC6FF"
}

function AccMas016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M88.5 17.45l-.1-.1.1.15v-.05m88.1-5q.45-6.007.35-12.45-14.5 11.15-30.75 11.7-9.2.3-19.05-3.65-23.7-9.65-37.4 7.95l-.3.4q-.45.6-.85 1.2l-.1-.1q-.65-.65-1-1.1l-.3-.4Q73.5-1.6 49.8 8.05q-9.85 3.95-19 3.65h-.05q-5.25-.15-10.35-1.45Q9.8 7.55.05 0-.1 6.55.4 12.65q-.05-.15-.1-.25 0 .15.05.25 1.35 17.5 4.9 30.1 4.9 19.7 15.15 31.2 1.2 1.35 2.45 2.55.5.5 1.05 1 .6.65 1.2 1.25Q36.2 87.7 48.45 89.7q12.2 2 21.35-1.55 9.15-3.55 16.4-10.65 1-.9 2-1.95l.3-.1q.05-.05.1-.05 1.1 1.1 2.15 2.1 7.25 7.1 16.4 10.65 9.15 3.55 21.4 1.55 12.2-2 23.35-10.95.55-.6 1.15-1.25l1.1-1q12.1-11.8 17.6-33.75 3.5-12.6 4.9-30.1v-.25l-.05.05M113.15 20h.05q10.85.8 19.05 9.65 8.25 8.8 9 20.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-7.3-7.8-8.8-17.85l-.75-2.5q-1.35 5.7-2.1 8.1-2.25 6.7-7.45 12.3-1.05 1.15-2.15 2.15-6.45 5.85-14.4 7.15-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-6.65-1.2-11.5-6.45-7.2-7.6-6.35-19.2.75-11.65 9.05-20.45.45-.5.9-.95 7.85-7.95 18.15-8.7 10.75-.85 17.85 6.75 3.1 3.35 4.75 7.55.75 2.05 1.95 7l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75z"
                    id="AccMas016__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M165.9 9.3q.45-5.1 1.05-9.3-11.05 8-22.75 8.9-10.05 1.55-22.95-3.9Q116.6 3.25 109 2.05 98.65.4 90.75 8.2l-.85.85q-4.7 4.6-6.35 6.1-.4.3-.4.35-.9-1.3-5.75-6.1-.55-.6-1.2-1.2Q68.3.4 58 2.05q-6.4 1-10.65 2.4Q32.8 10.3 21.9 8.8q-2-.2-4-.6-1.25-.25-2.5-.6Q7.55 5.45 0 0q.6 4.2 1.1 9.3h-.05q1.3 14.6 4.6 25.15Q9.1 46.85 15.4 55.4q1.2 1.6 2.5 3.1 5 5.7 11.6 9.3.9.5 1.9 1 .2.15.45.3 1.25.7 2.6 1.35 5.8 2.85 12.7 4.15 1.05.25 2.2.45 15.25.05 26.05-9.4.55-.5 1.15-1.05 3.4-3.25 7.15-7.65 3.4 4.25 6.6 7.4 1.35 1.35 2.75 2.55 10.85 9.25 25.4 8 .7-.15 1.35-.3 6.9-1.3 12.7-4.15 1.35-.65 2.6-1.35l.5-.3 1.9-1q2.4-1.35 4.65-3 13.45-9.9 19.2-30.35Q164.6 23.9 165.9 9.3m-23.75 9.3q5.85.05 11.85-3.9.1-.1.2-.15l.1 1q0 .35.05.75v1.1q.05 1.7-.1 3.45.05 23.3-16.1 37.7-2.55 3.2-6.2 5.3-2.8 1.55-4.85 2.45-2.05.9-3.4 1.15-2.7.5-3.45.6-.75.05-1.5.15-1 .1-2 .15.5 0-.55.1-1.95.15-4 .05-.1-.05-.25-.05-5.6-.45-10.5-2.95-2.2-1.15-4.25-2.7-2.45-1.9-4.7-4.3-.05-.1-.1-.1-.75-.85-1.4-1.65-1.1-1.4-2.05-2.8-2.35-3.55-3.75-7.4l-.3-1q-.2-.5-.3-1l-.1-.45q-.7-2.45-.95-5.05l-.05-.15q-1.25 6.15-1.4 6.65-.65 1.95-1.45 3.8L76.8 55.7q-1.05 1.35-2.2 2.65-.1.05-.15.15-2.25 2.4-4.7 4.3-2 1.5-4.15 2.65-4.95 2.55-10.6 3-.15 0-.25.05-2.05.1-4-.05-1.05-.1-.5-.1-1.05-.05-2.05-.15-.75-.1-1.45-.15-.8-.1-3.45-.6-1.4-.25-3.75-1.5-2.2-1.15-5.2-3.15-.85-.6-1.65-1.25-21.6-15.85-20.1-44.4l.05-1q0-.8.1-1.6 1.35.9 2.65 1.6 1.25.65 2.5 1.15 8.3 3.2 16.3-1.45 9.55-5.6 21.4-7.65 1-.2 2-.3 4.45-.7 8.25.3 6.85 1.8 11.4 9.05.25.4 1.5 2l-.7-.85q.25.35.5.65.75.95 1.55 2.7 1.55 3.35 3.4 9.6l.15-.55q.3-2.1.95-4.05l.15-.65.05-.05.15-.8q.6-1.6 1.4-3.05.9-1.65 2.05-3.15l-.5.6q1.55-2 1.8-2.4Q94.25 10 101.15 8.2q3.75-1 8.2-.3 1 .1 2.05.3 11.8 2.05 21.4 7.65 4.6 2.7 9.35 2.75z"
                    id="AccMas016__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas016__Symbol_100_0_Layer0_0_FILL"
                transform="translate(104.05 116.6)"
                id="AccMas016__col1n"
            />
            <use
                xlinkHref="#AccMas016__Symbol_27_0_Layer0_0_FILL"
                transform="translate(109.05 125.3)"
                id="AccMas016__col2n"
            />
        </g></g>
    )
}

export default AccMas016
