import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#FFC400",
    "col2n": "#E5B000"
}

function AccGla013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.25 1.4q.2.3.6.3.35 0 .55-.3.3-.2.3-.55Q1.7.5 1.4.2 1.2 0 .85 0q-.4 0-.6.2Q0 .5 0 .85q0 .35.25.55z"
                    id="AccGla013__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M60.3 16.25Q58.45 2.5 44.3.4q-3.05-.45-6.65-.4H15.7q-7.9-.2-11 7.8-.05 0-.05.1Q.5 17.3.2 25.95v.25Q-.85 38.95 3.7 51.1q0 .05.05.15 1.3 3.85 4.5 5.65 3.05 1.65 7.85 1.5h18q7.5.4 12.9-1.4 9.95-3.4 12.7-14.3.35-1.45.6-3.05v-23.4m62.3 23.55q1.1-6.7.5-13.6v-.05-.2q-.2-6.4-2.6-13.15-.8-2.3-1.85-4.6 0-.1-.05-.1-2.4-5.95-7.5-7.3-1.65-.4-3.55-.35H85.4Q81.8.4 78.8.8q-6.3.9-10.1 4.1-.55.5-1.1 1.05-1.7 1.85-2.95 3.95-.45.85-.75 1.85-.8 2.05-1.2 4.65v23q2.1 13.75 14.15 17.25 5.15 1.55 12.2 1.2h18.05q9.85.35 12.45-7 .05-.15.05-.2 2.1-5.35 3-10.85z"
                    id="AccGla013__Symbol_83_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M193.8 15.95q-.3-1-1.3-1.4-.9-.4-1.9-.1L160.95 25.6q-.8-7.5-4.4-15.35Q152.3-.4 141.5 0h-20.65Q102.7-.4 97 10.7 91.25-.4 73.1 0H52.5Q41.7-.4 37.45 10.25q-3.6 7.85-4.4 15.35L3.35 14.45q-.95-.3-1.85.1-1 .4-1.35 1.4-.3.95.1 1.85.4.95 1.4 1.3l31 11.6q-.55 11.65 3.75 22.75 3.3 10.5 16.5 9.95H69.55q21 1.1 27.45-12.75 6.45 13.9 27.45 12.75H141.1q13.2.55 16.5-9.95 4.3-11.1 3.75-22.75l31-11.6q1-.35 1.4-1.3.4-.9.05-1.85m-48.85-10.7q4.8 1.25 7.05 6.8.05 0 .05.1 1 2.15 1.75 4.3 2.25 6.3 2.45 12.25v.25q.55 6.45-.5 12.7-.85 5.15-2.8 10.15 0 .05-.05.15-2.45 6.9-11.7 6.55h-17q-6.6.35-11.45-1.1-11.35-3.3-13.3-16.1V19.8q.35-2.4 1.1-4.35.3-.9.7-1.7 1.4-2.8 3.8-4.7 3.6-2.95 9.5-3.8 2.85-.4 6.25-.35h20.8q1.8-.05 3.35.35M94.55 19.8v21.5q-.2 1.45-.55 2.8-2.6 10-12 13.1-5.1 1.65-12.2 1.3h-17q-4.55.15-7.45-1.4-3-1.65-4.25-5.15-.05-.1-.05-.15-4.3-11.15-3.3-22.85v-.05-.2q.3-7.95 4.2-16.55 0-.1.05-.1Q44.95 4.7 52.4 4.9h20.75q3.4-.05 6.3.35Q92.8 7.2 94.55 19.8z"
                    id="AccGla013__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla013__Symbol_103_0_Layer0_0_FILL"
                transform="translate(190.6 153.65)"
                id="AccGla013__col2n"
            />
            <use
                xlinkHref="#AccGla013__Symbol_83_0_Layer0_0_FILL"
                transform="translate(130.95 135.5)"
                id="AccGla013__col2l"
            />
            <use
                xlinkHref="#AccGla013__Symbol_36_0_Layer0_0_FILL"
                transform="translate(95.55 132.7)"
                id="AccGla013__col1n"
            />
        </g></g>
    )
}

export default AccGla013
