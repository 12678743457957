import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.3 13q2.45-.2 4.45-.7 5-.55 6.05-5.65Q57 4.2 54.65 2q-5.5-4.55-17.6 1.25-3.1 1.05-6.1 2.4-1.15.55-2.25 1.1-3.55 1.6-6.2 2.75-.05 0-.1.05-4.15 2.1-8.05 2.85-4.1.8-6.75.2-3.55-.75-3.5-3.95.1-1.4 1.25-2.45 1.3-1.15 3.1-1.15t3.1 1.15q1.3 1.15 1.3 2.75v1.7q.3-.25.55-.45 2.35-2.9.9-6.3-1-1.75-3.55-2.8Q6.55-.45 2.7 2.95q-.2.15-.35.3-3.2 3.4-2 7.3.35 1.2 1.2 2.35 3.55 4.5 11.75 3.55 4.7-.3 10.4-2.25.8-.35 1.65-.65 1.55-.65 2.6-1 1-.3 2.75-.45 1.75-.15 6.35.55 4.5.7 9.25.35M114 3.25q-.15-.15-.35-.3-3.85-3.4-8.05-1.85-2.55 1.05-3.55 2.8-1.45 3.4.9 6.3.25.2.55.45v-.2-1.1-.4q0-1.6 1.3-2.75t3.1-1.15q1.8 0 3.1 1.15 1.15 1.05 1.25 2.45.05 3.2-3.5 3.95-2.65.6-6.75-.2-3.9-.75-8.05-2.85-.05-.05-.1-.05-2.65-1.15-6.2-2.75-1.1-.55-2.25-1.1-3-1.35-6.1-2.4Q67.2-2.55 61.7 2q-2.35 2.2-2.15 4.65 1.05 5.1 6.05 5.65 2 .5 4.45.7 4.75.35 9.25-.35 4.6-.7 6.35-.55 1.75.15 2.75.45 1.05.35 2.6 1 .85.3 1.65.65 5.7 1.95 10.4 2.25 8.2.95 11.75-3.55.85-1.15 1.2-2.35 1.2-3.9-2-7.3z"
                    id="BeaMus016__Symbol_33_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus016__Symbol_33_0_Layer0_0_FILL"
                transform="translate(134.35 196.6)"
                id="BeaMus016__col1n"
            />
        </g></g>
    )
}

export default BeaMus016
