import { AssetSvgProps } from "../../../shared/assets"

function ShiMot099({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FAB5C7"
                    d="M159.2 279.55v7.3h66.5v-7.3h-66.5m0-14.2v7.1h66.5v-7.1h-66.5z"
                    id="ShiMot099__Layer1_1_FILL"
                />
                <g id="ShiMot099__Layer1_0_FILL">
                    <path
                        fill="#FFF"
                        d="M159.2 272.45v7.1q15.9 3.348 31.45 3.55h1q1.054.031 2.05 0h1q15.68-.174 31-3.55v-7.1q-14.27-2.563-28.55-2.95-4.428.17-8.9 0-14.516.34-29.05 2.95z"
                    />
                    <path
                        fill="#66C9EE"
                        d="M159.2 286.85v7.25h66.5v-7.25q-15.445-3.483-31-3.75h-1q-.996.031-2.05 0h-1q-15.672.233-31.45 3.75m0-28.85v7.35q14.704 3.652 29.05 4.15 4.472.17 8.9 0 14.455-.45 28.55-4.15V258h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot099__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot099__Layer1_1_FILL" />
        </g></g>
    )
}

export default ShiMot099
