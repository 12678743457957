import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400"
}

function ShiMot008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.35 20.5L16.75 0 6.05 2.65 1.6 25.7l11.5-1.85-5.9 14.3-7.2 1 6.4 11.3L20 36.25l-7.4 1.1 8.8-19.75-14.05 2.9z"
                    id="ShiMot008__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot008__Symbol_14_0_Layer0_0_FILL"
                transform="translate(181.75 259.6)"
                id="ShiMot008__col1n"
            />
        </g></g>
    )
}

export default ShiMot008
