import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M36.8 0q-4.65 2.5-9.2 3.9-10.3 3.35-18.4 0Q4.65 2.5 0 0q3.05 2.25 6.2 3.9 1.05.55 2.1 1.05 1.8.7 3.6 1.25 6.5 1.8 13 0 1.8-.55 3.6-1.25 1.05-.5 2.15-1.05 3.1-1.65 6.15-3.9m51.1 6.2q1.8-.55 3.6-1.25 1.05-.5 2.15-1.05 3.1-1.65 6.15-3.9-4.65 2.5-9.2 3.9-10.3 3.35-18.4 0Q67.65 2.5 63 0q3.05 2.25 6.2 3.9 1.05.55 2.1 1.05 1.8.7 3.6 1.25 6.5 1.8 13 0z"
                    id="SkiMor011__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor011__Symbol_15_0_Layer0_0_FILL"
                transform="translate(142.55 275.1)"
                id="SkiMor011__col1n"
            />
        </g></g>
    )
}

export default SkiMor011
