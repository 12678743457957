import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#ADADFF"
}

function ShiSho011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.65 49.15q-.2-.75-.3-1.5-.15-.6-.25-1.15l-1-4.75q-.65-3.35-1.3-6.65L116.35 0q-1.65 1.05-3.3 2-4.05 2.25-8.25 4.1L66.7 42.75v-.05l-.25.25-.3-.25v.05l-38-36.6Q22.1 3.5 16.45 0h-.05L7.3 43.25 2.95 62q-1 3.9-1.95 7.8l-.95 3.6v.25L0 73.7h.2q.25.1.5.15H.85q2.05.5 4.15.95 60.7 13.75 120.65.5h-.1l.8-.2.25-.05q.55-.1 1.1-.25.1-.05.2-.05 2-.45 3.95-.9H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25z"
                    id="ShiSho011__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho011__Symbol_19_0_Layer0_0_FILL"
                transform="translate(126.1 235.8)"
                id="ShiSho011__col1n"
            />
        </g></g>
    )
}

export default ShiSho011
