import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D",
    "col2n": "#FFFFFF",
    "col2s": "#999999"
}

function FacMou030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M7.65 13.75H37.5l.3-.2L45.15 0H0l7.65 13.75z"
                    id="FacMou030__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M44.6 1.2q-.15 0-.25.1-.3.25-.55.45h-.05l-.6.6q-.1.05-.2.15v-.05Q41.2 4.1 39.05 5.4L37.7.4q0-.15-.15-.25t-.3 0q-.15 0-.25.15t-.05.3l1.45 5.2q-2 1.05-4.35 1.85l-.8-7.3q0-.15-.15-.25t-.3-.1q-.15 0-.25.15t-.1.3l.8 7.45q-2.15.55-4.6.9l-.35-7.15q0-.2-.15-.3-.1-.1-.3-.1-.15 0-.25.15-.1.1-.1.3l.3 7.2q-.35 0-.7.05-1.9.15-4.15.1L22.9 1.8q0-.15-.1-.2-.1-.15-.25-.15h-.05q-.15 0-.25.15h-.05q-.1.1-.1.25l-.05 7.2q-2.25.05-4.15-.1-.35-.05-.7-.05l.3-7.2q0-.2-.1-.3-.1-.15-.25-.15-.2 0-.3.1-.15.1-.15.3l-.35 7.15q-2.45-.35-4.6-.9l.8-7.45q0-.15-.1-.3T12.2 0q-.15 0-.3.1t-.15.25l-.8 7.3Q8.6 6.85 6.6 5.8L8.05.6Q8.1.45 8 .3T7.75.15q-.15-.1-.3 0T7.3.4l-1.35 5Q3.8 4.1 2.05 2.45v.05q-.1-.1-.2-.15l-.6-.6H1.2q-.25-.2-.55-.45-.1-.1-.25-.1-.2 0-.3.15-.1.1-.1.3 0 .15.15.25.3.25.55.45.45.45.7.65l.1.05q1.9 1.8 4.2 3.15l-1.1 4.3q-.1.15 0 .3t.25.2q.15.05.3-.05t.2-.25L6.4 6.6q2.05 1.05 4.5 1.85l-.7 5.6v.1l-.05.1L10 15.5q0 .15.1.25.1.15.3.15.15 0 .25-.1.15-.1.15-.25l.15-1.25v-.05l.05-.1.65-5.5q2.2.6 4.65.95l-.35 6.75q0 .15.1.3.1.1.3.1.15 0 .3-.1.1-.1.1-.25l.4-6.7q.3 0 .65.05h.05q1.9.15 4.2.1l.05 6.45q0 .15.1.3.15.1.3.1t.25-.1h.05q.1-.15.1-.3l.05-6.45q2.3.05 4.2-.1h.05q.35-.05.65-.05l.4 6.7q0 .15.1.25.15.1.3.1.2 0 .3-.1.1-.15.1-.3L28.7 9.6q2.45-.35 4.65-.95l.65 5.5.05.1v.05l.15 1.25q0 .15.15.25.1.1.25.1.2 0 .3-.15.1-.1.1-.25l-.15-1.25-.05-.1v-.1l-.7-5.6q2.45-.8 4.5-1.85l1.05 4.1q.05.15.2.25.15.1.3.05t.25-.2q.1-.15 0-.3l-1.1-4.3q2.3-1.35 4.2-3.15l.1-.05q.25-.2.7-.65.25-.2.55-.45.15-.1.15-.25 0-.2-.1-.3-.1-.15-.3-.15z"
                    id="FacMou030__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M44.05 13.85q.55-1.25 1.2-2.8 1.35-3.1 1.65-3.6.55-.95 1.85-2.65-3.4 0-8-1.75-2.05-.75-5-2.05-4.9-2.2-9.05.55-.35.2-.65.45l-1.7 1.3L22.7 2q-.3-.25-.65-.45Q17.9-1.2 13 1q-2.95 1.3-5 2.05Q3.4 4.8 0 4.8q1.3 1.7 1.85 2.65.3.5 1.65 3.6.65 1.55 1.2 2.8Q6.45 18 9.9 21q1.2.95 2.55 1.65 5.95 3 11.9 2.9h.05q2.75 0 5.5-.6 2.4-.55 4.8-1.55.8-.35 1.6-.75 1.35-.7 2.55-1.65 3.45-3 5.2-7.15m.9-7.9q-1.25 4.45-4.35 7.8-.8.9-1.6 1.6-2.2 2.35-9.4 2.8-.5 0-.9.05-2.4.05-4.85.05h-.15q-1.35 0-3.65-.05-.4-.05-.9-.05-7.2-.45-9.4-2.8-.8-.7-1.6-1.6-3.1-3.35-4.35-7.8h2q2.35-.25 3.6-.4h.25q3-.35 5.65.45.3.05.6.15.05 0 .1.05 4.2 1.45 8.35 1.3h.05q4.2.1 8.35-1.3.05-.05.1-.05.3-.1.6-.15 2.65-.8 5.65-.45h.25q1.25.15 3.6.4h2z"
                    id="FacMou030__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M20.2.35q-.1-.45-.85-.3-.35.05-1.2.1-.1.05-2 .15-1.95.1-3.9.15H8Q6.05.4 4.1.3 2.2.2 2.1.15 1.25.1.9.05.15-.1.05.35q-.1.2.15.7Q.7 2.2 6.7 2.4q1.8.05 3.4.05h.05q1.55 0 3.4-.05 1.15-.05 2.1-.1 4-.3 4.4-1.25.15-.25.2-.45 0-.15-.05-.25z"
                    id="FacMou030__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou030__Symbol_115_0_Layer0_0_FILL"
                transform="translate(169.8 214.25)"
                id="FacMou030__col2n"
            />
            <use
                xlinkHref="#FacMou030__Symbol_110_0_Layer0_0_FILL"
                transform="translate(170 213.05)"
                id="FacMou030__col2s"
            />
            <use
                xlinkHref="#FacMou030__Symbol_55_0_Layer0_0_FILL"
                transform="translate(168.15 209.25)"
                id="FacMou030__col1n"
            />
            <use
                xlinkHref="#FacMou030__Symbol_15_0_Layer0_0_FILL"
                transform="translate(182.4 228.5)"
                id="FacMou030__col1l"
            />
        </g></g>
    )
}

export default FacMou030
