import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00498A",
    "col2n": "#EEEEEE"
}

function ObjMus017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFC400"
                    d="M284.45 233q.7.05 1.45.05h1.25q10.15-.05 17.65-3.75.8-.3 1.55-.65-22.866-6.76-43.8 0 .7.35 1.5.65 7.85 3.85 18.65 3.75h.25q.75 0 1.5-.05m21.2-29.35q-.55-.25-1.05-.5-7.4-3.6-17.45-3.65h-1.25q-.6 0-1.15.05h-.3q-.75-.05-1.5-.05h-.25q-10.7-.05-18.45 3.65-.5.25-1.05.5 21.225 5.714 42.45 0m-161.85-19.6q-2.5-3.65-17.8 2.85-6.9 2.95-14.5 7.15-3.8 2.05-7.7 4.45-1.7.95-3.4 2.05-1.65 1.05-3.3 2.15-2.7 1.7-5.5 3.55-19.1 12.85-30.95 24.55-11.8 11.65-9.3 15.35 2.45 3.65 17.7-2.85 12.7-5.45 28.05-15.15 1.65-1.05 3.3-2.15 1.6-1 3.15-2 .1-.1.25-.15 18.9-12.8 30.6-24.4 11.85-11.7 9.4-15.4z"
                    id="ObjMus017__Layer13_1_FILL"
                />
                <path
                    fill="#D49B0B"
                    d="M331.45 210.65q-2.9-1.4-6.05-2.55-1.05-.2-4.65-.8-3.65-.55-8.35-1.65-4.7-1.05-6.75-2H263.2q-2.05.95-6.75 2-4.65 1.1-8.35 1.65-3.6.6-4.65.8-3.15 1.15-6 2.55h94m-5.8 13.7q3.15-1.1 6.1-2.55h-45.7v.05h-3.35v-.05h-45.55q2.9 1.45 6.05 2.55 1.8.55 7.3 1.2 5.5.7 12.05 3.1h43.8q6.5-2.4 12-3.1 5.55-.65 7.3-1.2z"
                    id="ObjMus017__Layer13_1_MEMBER_0_FILL"
                />
                <path
                    fill="#666"
                    d="M282.7 330.75v5.85l-16.75 23.1-1.05.15 7.3-.15 10.5-16.05v16.05h3.95l-.05-28.95q-1.95-4.871-3.9 0m-183.9 11.2l-.8.85v7.85l-23 23h7.9l15.1-15.1v15.1h4.3v-31.7q-2.171-5.479-3.5 0m49.2-62.7q-.1.1-.25.2-.85.75-1.55 1.5-1.95 1.85-3.6 3.8-2.65 3.15-4.75 6.5-1.6 2.4-2.85 4.9-1.1 2.2-2 4.55-3.65 9.3-3.65 20.05 0 21.55 14.8 37.65l.15.15-21.35 21.3h6.4l18.15-18.1q.6.5 1.25 1.1 15.75 14.3 36.9 16.95.2.05.45.05 4.55.55 9.3.55 4.75 0 9.2-.55 21.5-2.5 37.5-17 .1-.1.25-.15.5-.55 1.1-1l18.15 18.15h6.1l-21.25-21.25.25-.25q14.75-16.05 14.75-37.6 0-9.3-2.7-17.55-2.05-6.05-5.55-11.6-1.85-2.95-4.2-5.75-2.95-3.7-6.6-7.1-.2-.1-.3-.2-5.45-4.95-11.5-8.45-.9-.5-1.8-.95-9.35-5.1-20.2-6.95-6.4-1.1-13.2-1.05-4.95-.05-9.7.5-.5.05-.95.1-19.75 2.75-34.85 15.8-.65.45-1.15 1-.4.3-.75.7z"
                    id="ObjMus017__Layer13_2_FILL"
                />
                <path
                    fill="#C6C6C6"
                    d="M286.65 285.5l-3.95.75v44.1l-.3.4h4.25V285.5M100.4 188.25q-1.65-.6-3.3-.55v154.25h1.7l.5-.55v.55h4.5V300.7h-3.4V188.25m185.65-4.85q-1.65-2.05-3.35 0v56.9l3.35-.6v-56.3z"
                    id="ObjMus017__Layer13_3_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M20.1 41.65H0v13.9h20.1l3.3-.85v.85h24.25v-7.9l-8.6-6H23.4v.7q-1.55-.35-3.3-.7m49.1-1.9q.7-.75 1.55-1.5.15-.1.25-.2l2.95-29.4L23.4 0v32.3l45.8 7.45m146.6-2.95L209.85 5l-48.35 8.65 3.9 23.9q3.65 3.4 6.6 7.1l43.8-7.85z"
                    id="ObjMus017__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M100.95 91.2q17.35-15.65 17.35-37.75 0-22.15-17.35-37.8Q83.6 0 59.2 0 34.7 0 17.3 15.65 0 31.3 0 53.45q0 22.1 17.3 37.75.05.05.15.1 17.3 15.6 41.75 15.6 24.35 0 41.65-15.6l.1-.1z"
                    id="ObjMus017__Symbol_31_0_Layer0_0_FILL"
                />
                <g id="ObjMus017__Layer13_0_FILL">
                    <path
                        fill="#9A9A9A"
                        d="M284.75 340.6q.807 1.08 1.9 2.45l17.05 16.65h11.9l-28.95-28.95h-4.25l-22.6 28.95h6.15l18.8-19.1m5.65-95.1l-1.1-6.3-56.95 9.8 1.05 6.75 5.1-.9q10.66 8.08 3.9 23.9 1.59 7.519 5.05 9.15 3.46 1.631 5.75 3.7l43.85-7.95-1.1-6.15-3.15.5q-24.596-9.594-5.95-31.8l3.55-.7M67.1 373.65H75l24-19.05q1.284 1.969 3.3 4.65l15 14.4h11.9l.9-.9-27.5-30.8h-3.3v-.55l-32.2 32.25m33.3-94.2q-1.237 2.46-1.95 4.85-.457-2.412-1.35-4.85H75.3v3.4H77q8.346 6.95 0 13.9h-1.7v3.95h21.8q.861-2.35 1.35-4.7.68 2.372 1.95 4.7h26.05v-3.95h-1.8q-11.696-4.925 0-7.9l10.35 7.3q4.933-.198 2.85-4.9l-11.4-8.1v-1.05l16.15 2.65q4.327-.038 4.7-2 .417-1.96.5-2.7.094-.697.2-.8-9.494-14.7 2.95-29.4l3.45.55.35-4.35-54.35-10q-5.248 21.025 0 42.05l8.6 1.35h-8.6z"
                    />
                    <path fill="#4E3C27" d="M226.65 245.5h.1l-.1-.05v.05z" />
                </g>
            </defs>
            <use xlinkHref="#ObjMus017__Layer13_0_FILL" />
            <use xlinkHref="#ObjMus017__Layer13_1_FILL" />
            <use xlinkHref="#ObjMus017__Layer13_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMus017__Layer13_2_FILL" />
            <use xlinkHref="#ObjMus017__Layer13_3_FILL" />
            <use
                xlinkHref="#ObjMus017__Symbol_103_0_Layer0_0_FILL"
                transform="translate(77 241.2)"
                id="ObjMus017__col1n"
            />
            <use
                xlinkHref="#ObjMus017__Symbol_31_0_Layer0_0_FILL"
                transform="translate(136.4 267.05)"
                id="ObjMus017__col2n"
            />
        </g></g>
    )
}

export default ObjMus017
