import { AssetSvgProps } from "../../../shared/assets"

function ObjToy056({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#333"
                    d="M113.95 278.7q-.05-.15-.2-.25-14.4-11.7-37.2 2.55-2.75 1.75-5.65 3.85-16.65 12.25-37.5 37.4-4.4 5.3-8.95 11.15-2.2 2.35-3.3 3.4-.65.6-.25.15-25.75 23.9-78.85 13.65-.3-.1-.6.1-.2.15-.3.45-.05.3.1.6.2.2.5.3 56.5 10.95 82.55-16.3h-.05l.25-.25q.25-.1.45-.3 4.3-5.55 8.45-10.6 20.8-25.4 37.5-37.85 3.3-2.5 6.45-4.45 21.4-13.45 35.05-3.05l.5-.25q.7-.35 1.05-.3z"
                    id="ObjToy056__Layer14_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M114.55 277.95q-.052-.051-3.55 1.2l1.2-.5-27.45 8.45q4.962 9.983 11.85 18.2 7.458-1.717 15.05-4.9h.05q.95-1.45 2.2-2.25 1.55-1.05 3.55-1.15.35-.05.7-.05 1.85 0 3.5.2 17.179-3.493 34-11.1L142.6 269.3l-28.05 8.65m29.55 5.1h.05l.05.1-.1-.1z"
                    id="ObjToy056__Layer5_0_FILL"
                />
                <path
                    fill="#BBB"
                    d="M111.65 300.45v-.05l-15.05 4.9q-1.283 1.708 0 3.6l.05.05v.7h-.05q-.755 1.075 0 2.15l.05-.05 14.15-4.55q-.15-.2-.25-.4-.35-.65-.4-1.45v-.2-.25-.15-.1q0-1.9 1.5-4.2m44.1-14.4h-.1l-34 11.1q3.95.35 6.75 1.7.25.1.5.25.1.05.2.15 1.25.65 2 1.35l24.65-8v-6.55z"
                    id="ObjToy056__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    fill="#34313A"
                    d="M152.95 285.5l-10-13-54.8 16.5 9 14.5 16.75-5.35q1.55-1.05 3.55-1.15l35.5-11.5M97.9 295l6.95-2.15 3.25 5-6.95 2.2L97.9 295m8.4-2.65l6.8-2.1 3.25 4.95-7 2.2-3.05-5.05m11.8 2.25l-3.3-4.9 16.85-5.25 3.55 4.65-17.1 5.5m21.65-17.5l1.45 2 3-.95 2.9 3.75-3.05 1 .1.15.05.1 1.6 2.4-4.95 1.65-1.8-2.5-3.1 1-2.8-4 3.05-.95-1.45-2.05 5-1.6z"
                    id="ObjToy056__Layer5_0_MEMBER_1_FILL"
                />
                <path
                    fill="#999"
                    d="M84.75 287.1v6.7l11.85 18v-2.15h.05v-.7l-.05-.05v-3.6l-11.85-18.2z"
                    id="ObjToy056__Layer5_0_MEMBER_3_FILL"
                />
                <g id="ObjToy056__Layer5_0_MEMBER_2_FILL">
                    <path
                        fill="#666"
                        d="M155.75 290.1h-.1l-27.25 8.75q.25.1.5.25.1.05.2.15l26.65-8.6v-.55M85 291.9q-.1-.1-.2-.1 0-.05-.05-.05v.8l11.65 17q0 .05.1.1h.2l13.45-4.3v-.2-.25-.15l-13.45 4.3L85 291.9z"
                    />
                    <path d="M125.5 288.25l1.25 1.9 5.5-1.85-1.35-1.8-5.4 1.75m10.5-9.35l1.4 2.15-2.9.9 1.85 2.75 2.85-1 2 2.6 3.55-1.2-1.7-2.6 2.95-1.05-2-2.55-3.05 1.05-1.55-2.15-3.4 1.1m-17.6 11.5l1.15 2.05 5.75-1.85-1.3-1.95-5.6 1.75z" />
                    <path
                        fill="#788387"
                        d="M111.65 285l1.1 1.65 16.65-5.25-1.2-1.65-16.55 5.25m1.7 2.55l.9 1.35 16.85-5.2-1.05-1.45-16.7 5.3m5.2 7.8l.7 1.05 17.2-5.55-.7-.95-17.2 5.45m-8.6-12.9l1 1.5 16.55-5.1-1.05-1.4-16.5 5z"
                    />
                    <path
                        fill="red"
                        d="M109.3 295.5q.85.7 2 .7 1.25 0 2.05-.7.85-.7.85-1.65 0-1-.85-1.7-.8-.7-2.05-.7-1.15 0-2 .7-.85.7-.85 1.7 0 .95.85 1.65m-8.25-.8q-.85.7-.85 1.65 0 1 .85 1.7t2 .7q1.25 0 2.05-.7.85-.7.85-1.7 0-.95-.85-1.65-.8-.7-2.05-.7-1.15 0-2 .7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy056__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy056__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy056__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy056__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy056__Layer5_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy056__Layer5_0_MEMBER_3_FILL" />
        </g></g>
    )
}

export default ObjToy056
