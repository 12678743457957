import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function SkiMor008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M98.3 32.65q0-13.5-14.4-23.1Q75.8 4.2 65.75 1.8 58 0 49.2 0q-9.35 0-17.4 2-9.55 2.4-17.35 7.55Q2.85 17.25.55 27.5 0 30 0 32.65q0 13.45 14.45 23 14.4 9.6 34.75 9.6t34.7-9.6Q96.15 47.6 98 36.7q.3-2.05.3-4.05z"
                    id="SkiMor008__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor008__Symbol_12_0_Layer0_0_FILL"
                transform="translate(143.35 251.1)"
                id="SkiMor008__col1n"
            />
        </g></g>
    )
}

export default SkiMor008
