import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1l": "#FFFFFF",
    "col1n": "#EEEEEE"
}

function AccMor027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.45 13.65q1.2-.3 2.35-.55 2-.4 4.8-1.1.15-.05.35-.1l-.3-4.2q-2.75.7-4.85 1.15-1.15.25-2.35.55-1.5.3-3.05.65-1.5.3-3.05.6-1.5.3-3.05.6-6.9 1.3-13.85 2.2-.1 0-.2.05-1.95.25-3.95.5-1.5.15-3.05.35-1.5.15-3.05.3-1.5.1-3 .25-9.1.75-18.2.95h-6.1q-1.5 0-3-.05-9.1-.15-18.2-.95-1.5-.15-3.05-.25l-3-.3-2.7-.3q-.15-.05-.35-.05-.1-.05-.15-.05-.15 0-.55-.05h-.2q-.5-.1-1-.15-8.15-1-16.3-2.5-1.5-.3-3.05-.6l-3-.6q-1.5-.3-3.05-.65l-2.2-.5Q3 8.4.25 7.7L0 11.95q3 .7 5.1 1.15l2.2.5q1.55.35 3.05.65l3 .6q1.55.3 3.05.6 2.65.45 5.25.9 6.5 1.1 12.95 1.85 1.55.2 3.05.35l3 .3q1.55.15 3.05.3 9.1.75 18.2.95H71q9.1-.2 18.2-.95 1.5-.15 3-.25 1.55-.15 3.05-.3 1.55-.2 3.05-.35 6.6-.8 13.2-1.9 2.4-.4 4.8-.85 1.55-.3 3.05-.6 1.55-.3 3.05-.6 1.55-.35 3.05-.65z"
                    id="AccMor027__Symbol_125_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M30.3 8.85V4.6l-3-.3v4.25l3 .3M0 0v4.25l3 .6V.6L0 0m57.6 5.85h-3.05v4.25h3.05V5.85M109 .65V4.9q1.55-.3 3.05-.6V.05q-1.5.3-3.05.6M81.85 8.9q1.55-.15 3.05-.3V4.35q-1.5.15-3.05.3V8.9z"
                    id="AccMor027__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M3.05.65Q1.55.35 0 0v4.25q1.55.35 3.05.65V.65m3 .6V5.5q1.55.3 3.05.6V1.85q-1.5-.3-3.05-.6m12.4 3.15q-.35-.35-.85-.35-.55 0-.9.35t-.35.9q0 .5.35.85.35.4.9.4.5 0 .85-.4.4-.35.4-.85 0-.55-.4-.9m8.85.2v4.25q1.55.2 3.05.35V4.95l-2.7-.3q-.15-.05-.35-.05m9.1 5.2V5.5q-1.5-.15-3.05-.25V9.5q1.55.15 3.05.3m9.95-1.55q0-.55-.4-.9Q45.6 7 45.1 7q-.55 0-.9.35t-.35.9q0 .5.35.85.35.4.9.4.5 0 .85-.4.4-.35.4-.85m11.25 2.5V6.5q-1.5 0-3-.05v4.3h3m6.1 0V6.5h-3.05v4.25h3.05m9.9-3.4Q73.25 7 72.75 7q-.55 0-.9.35t-.35.9q0 .5.35.85.35.4.9.4.5 0 .85-.4.4-.35.4-.85 0-.55-.4-.9m11.3 2.2V5.3q-1.5.1-3 .25V9.8q1.5-.15 3-.25M87.95 5v4.25q1.55-.2 3.05-.35V4.65q-1.5.15-3.05.35m12.15-.95q-.55 0-.9.35t-.35.9q0 .5.35.85.35.4.9.4.5 0 .85-.4.4-.35.4-.85 0-.55-.4-.9-.35-.35-.85-.35m15 .9q1.55-.35 3.05-.65V.05q-1.5.3-3.05.65v4.25m-6.1 1.2q1.55-.3 3.05-.6V1.3q-1.5.3-3.05.6v4.25z"
                    id="AccMor027__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor027__Symbol_125_0_Layer0_0_FILL"
                transform="translate(126 297.85)"
                id="AccMor027__col1n"
            />
            <use
                xlinkHref="#AccMor027__Symbol_76_0_Layer0_0_FILL"
                transform="translate(136.35 307.85)"
                id="AccMor027__col1d"
            />
            <use
                xlinkHref="#AccMor027__Symbol_53_0_Layer0_0_FILL"
                transform="translate(133.3 307.2)"
                id="AccMor027__col1l"
            />
        </g></g>
    )
}

export default AccMor027
