import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000"
}

function ObjMor042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.35 68.9q-.3 1.45-.35 2.7-.15 2.7.9 4.65 1.2 2.4 4.15 3.8 2.6 1.3 6.6 1.65l9.5-8.05q-.8-.25-1.4-1.1-.85-.8-1.35-2.4-.2-1.3-.35-2.05l-.2-.8V66.85q0-3.4 2.35-5.85-1 1-2.2 1.55-.85.4-1.75.6-.7.1-1.45.1-2.25 0-4-1.15-.8-.45-1.45-1.1l-9 7.9M89.8 14.3q-1.45-3.75-4.7-7.5-.15-.15-.2-.25-3.15-3.55-6.5-5.4Q77.6.7 76.9.4q-2.15-.8-3.25.05L57.4 14.8l-4.5 7.8L20.7 51q.55.7.95 1.5.7 1.45.7 3.2v.2q0 1.2-.35 2.3-.3.85-.8 1.65-.4.5-.85 1 .35-.35.75-.65 1.2-1.3 2.75-2.25 3-1.85 6-1.75 1.85-.25 3.45.7.2.05.4.15 2.85 1.2 3.85 2.75l27.5-23.4 8.35-3.65 16.65-13.7q.15-.2.3-.5.55-1.4-.55-4.25z"
                    id="ObjMor042__Symbol_144_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M10.8 62.1L0 71.6q-.15 2.7.9 4.65l15.35-13.1q-.7.1-1.45.1-2.25 0-4-1.15m44.85-32.65q1.5 2.45 3.85 4.2l7.35-4.05-.15-.15q-3.3-2.45-5.65-6.3l-5.4 6.25q-1.4-2.15-2.25-4.95L21.65 52.5q.7 1.45.7 3.2v.2q0 1.2-.35 2.3l33.65-28.75M85.1 6.8q-.15-.15-.2-.25-3.15-3.55-6.5-5.4Q77.6.7 76.9.4q-2.15-.8-3.25.05l-.35.3q-.85 1-.35 3.2L58.25 17l.6 1.7q1.05 2.4 2.2 4.45l15.1-12.7q.85 1.25 1.95 2.5l.7.8q1.55 1.7 3.15 2.95 1 .8 2.05 1.45.4.25.85.5.2.1.45.25.3.15.65.3 1.15.5 2.05.6.8.1 1.4-.2l.65-.55q.15-.2.3-.5.55-1.4-.55-4.25-1.45-3.75-4.7-7.5m-1 .7q.15.15.3.35 2.75 3.35 3.9 6.35 1.2 3.05.1 4.05-.15.15-.35.2-1.1.45-3.55-1-1.05-.7-2.1-1.6-.15-.1-.3-.25-.5-.45-.95-.9L79 12.35q-.75-.85-1.35-1.7L76.1 8.3q-.7-1.2-1.15-2.35-.55-1.45-.55-2.4-.05-.8.25-1.2.15-.2.3-.35.55-.45 1.5-.25.95.15 2.35 1 2.6 1.65 5.3 4.75z"
                    id="ObjMor042__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.22}
                    d="M305.4 265.35l-.15-.15q-3.3-2.45-5.65-6.3l-5.4 6.25v.05q1.5 2.45 3.85 4.2l7.35-4.05m23.5-11.05q.55-1.4-.55-4.25-1.45-3.75-4.7-7.5-.15-.15-.2-.25-3.15-3.55-6.5-5.4-.8-.45-1.5-.75-2.15-.8-3.25.05l-.35.3q-.85 1-.35 3.2l-.1.05q.624 3.413 3.25 6.45h.05q.14.205.25.4.78 1.055 1.7 2.1l.7.8q1.55 1.7 3.15 2.95 1 .8 2.05 1.45.4.25.85.5.2.1.45.25.3.15.65.3 1.15.5 2.05.6.8.1 1.4-.2l.65-.55q.15-.2.3-.5m-1.95-.3q-.15.15-.35.2-1.1.45-3.55-1-1.05-.7-2.1-1.6-.15-.1-.3-.25-.5-.45-.95-.9l-2.15-2.35q-.75-.85-1.35-1.7l-1.55-2.35q-.7-1.2-1.15-2.35-.55-1.45-.55-2.4-.05-.8.25-1.2.15-.2.3-.35.55-.45 1.5-.25.95.15 2.35 1 2.6 1.65 5.3 4.75.15.15.3.35 2.75 3.35 3.9 6.35 1.2 3.05.1 4.05z"
                    id="ObjMor042__Layer5_0_MEMBER_0_FILL"
                />
                <linearGradient
                    id="ObjMor042__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={419.875}
                    y1={176.25}
                    x2={377.125}
                    y2={138.65}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
                    <stop
                        offset="27.451%"
                        stopColor="#FFF"
                        stopOpacity={0.471}
                    />
                    <stop
                        offset="49.412%"
                        stopColor="#FEFDC5"
                        stopOpacity={0.792}
                    />
                    <stop
                        offset="63.529%"
                        stopColor="#FFF"
                        stopOpacity={0.714}
                    />
                    <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ObjMor042__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={400.463}
                    y1={151.95}
                    x2={433.637}
                    y2={198.25}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
                    <stop
                        offset="27.451%"
                        stopColor="#FFF"
                        stopOpacity={0.471}
                    />
                    <stop
                        offset="49.412%"
                        stopColor="#FEFDC5"
                        stopOpacity={0.8}
                    />
                    <stop
                        offset="63.529%"
                        stopColor="#FFF"
                        stopOpacity={0.714}
                    />
                    <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
                </linearGradient>
                <g id="ObjMor042__Layer5_0_MEMBER_1_FILL">
                    <path d="M267.25 296.65h.25l.15-.15q-.2.05-.4.15z" />
                    <path
                        fill="#FFC"
                        d="M326.95 254q1.1-1-.1-4.05-1.15-3-3.9-6.35-.15-.2-.3-.35-2.7-3.1-5.3-4.75-1.4-.85-2.35-1-.95-.2-1.5.25-.15.15-.3.35-.3.4-.25 1.2 0 .95.55 2.4.45 1.15 1.15 2.35l1.55 2.35q.6.85 1.35 1.7l2.15 2.35q.45.45.95.9.15.15.3.25 1.05.9 2.1 1.6 2.45 1.45 3.55 1 .2-.05.35-.2z"
                    />
                    <path
                        fill="url(#ObjMor042__Gradient_1)"
                        d="M484.45 64.65q-.3-.1-.6-.15-19.1-5.35-30.65-15.1L313.45 233.55l-2.5 3.75 1.25-1.1q1.1-.85 3.25-.05.7.3 1.5.75 3.35 1.85 6.5 5.4l16.05-19.1L484.45 64.65z"
                    />
                    <path
                        fill="#FEFDC5"
                        fillOpacity={0.8}
                        d="M485.35 62.85l-1.5 1.65q.3.05.6.15L339.5 223.2l-16.05 19.1q.05.1.2.25l22.1-15.25L518 98.75q-4.65-27.75-32.65-35.9z"
                    />
                    <path
                        fill="url(#ObjMor042__Gradient_2)"
                        d="M530.05 131.35q-8.7-12.65-12.05-32.6L345.75 227.3l-22.1 15.25q3.25 3.75 4.7 7.5 1.1 2.85.55 4.25l201.15-122.95z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor042__Symbol_144_0_Layer0_0_FILL"
                transform="translate(238.55 235.75)"
                id="ObjMor042__col1n"
            />
            <use
                xlinkHref="#ObjMor042__Symbol_104_0_Layer0_0_FILL"
                transform="translate(238.55 235.75)"
                id="ObjMor042__col1l"
            />
            <use xlinkHref="#ObjMor042__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor042__Layer5_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ObjMor042
