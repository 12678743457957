import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.75 4.2V1.95q-19.5-3.9-40.75.1v2.3Q21.25.3 40.75 4.2z"
                    id="BeaMus002__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <g id="BeaMus002__col1n" transform="translate(172.15 206.55)">
                <use
                    xlinkHref="#BeaMus002__Symbol_3_0_Layer0_0_FILL"
                    id="BeaMus002__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default BeaMus002
