import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#333333"
}

function BeaMus004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M26.7 1.9l.95-1.25Q20.2 4 15.95 3.1q-1.55-.4-3.05-1.3-.35-.25-.7-.45-.35-.25-.75-.4Q7.8-.9 4.25.95q-1.6.9-2.65 2.85Q.55 5.5 0 7.9l.4-.6.05-.05q2.8-3.35 5.95-4.3 3.05-.8 5.15 1 3.9 2.9 9.15 1.65 4-1.1 6-3.7m32.8 6q-.55-2.4-1.6-4.1-1.05-1.95-2.65-2.85-3.55-1.85-7.2 0-.4.15-.75.4-.35.2-.7.45-1.5.9-3.05 1.3Q39.3 4 31.85.65l.95 1.25q2 2.6 6 3.7 5.25 1.25 9.15-1.65 2.1-1.8 5.15-1 3.15.95 5.95 4.3l.05.05.4.6z"
                    id="BeaMus004__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus004__Symbol_21_0_Layer0_0_FILL"
                transform="translate(162.75 203.3)"
                id="BeaMus004__col1n"
            />
        </g></g>
    )
}

export default BeaMus004
