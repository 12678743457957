import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF7B00",
    "col1s": "#9F3D00",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function ObjToo016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M82 36.05q-.1 0-.2.05h-.15q-3.3.3-6.35.55-1.05.05-2.05.15-.4.05-.75.1h-.65q-.65-.5-2.2-.6h-6.8q-5.75.4-8.6 3.2-2.2 2.15-2.2 4.95 0 .05.05.65l-.9.8q-1.65 2.05 1 4.35L74 68.65v.05q-.7.9-.2 2l14.3 12.25q1.7 1 2.8-.05 2-1.6 3.2-2.6 1.2.2 2.55.4 12.6-12.2 15.55-14.55l-8.55-6.5q-1.65-.85-2.7-1.25-.6-.85-1.25-1.7l-4.95-3.55.75-6.5L82 36.05M58.1 39.4q.3-.15.6-.25l-.55.5q-.05-.15-.05-.25z"
                    id="ObjToo016__Symbol_176_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M6.45 1.1l-.85.75L23.55 16.4l-4.7 4.45L.85 6.2 0 7l19 15.2 6.25-5.85L6.45 1.1m52.9 28.85l-1.7-1.25-7.55 6.65-3.4-2.65q-2.2-1.85-3.6-3.1l-.3-.1-8.4-6.85q-1.3-.55-2.45-.6l.95-11.7L19.6 0q-.4.05-.75.1-.85 0-1.55.05h-.05l14.2 10.95L30.6 22q-2.15.15-4.2 1.7l-6.95 7.35.9.8 6.55-7.1Q30 22.2 34 23.9l8.4 6.75v.1q1.7 1.3 3.95 3.1l2.8 2.3-5 4.45L43 43.9q1.65-.4 3.2-.35L58.95 32.4l.15-.25q0-.7.1-1.45.05-.35.15-.75z"
                    id="ObjToo016__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M43.65 24.05l.25-3-.1-.05-4.3-.35.2-2.75-3.3-.25-.15-3.05-.5-.45-4.9-.35.3-3.4-5.15-.05.8-4.35-4.7.5.1-4.4-4.95 1.65L15.8.05 13 2.85h-.05L9.65 0l-.8 4.25L4.1 3.4l1.7 4.05L1 8.55l3.1 3.25L0 15.1l3.8.95-1.05 3.75.2.15 4.1.3-.2 3.05.15.1 5.15.4-.3 3.3 3.95.3-.25 3.3.7.45 4.2.3-.25 3.05.2.15 3.5.25-.2 2.6.25.2 3.05.25-.15 2.15 3.7.25-.2 2.45 2.8.25-.15 2.3.2.2 3.75.3-.2 2.6.15.15 5.1.4-.25 3.35 4.25.35-.25 3.1 3.7.3-.25 2.8 3.4.25-.2 2.6.15.1 4.65.4 8.9-8 .45-.2 1.2-.15h.6q.45 0 .85-.05.25-.05.5-.05 1.7-.15 3.6-.25.35 0 .7.05 1.25-.25 2.65-.3h.3q.7-.05 1.55-.05l.2-2.45-.25-.25-3.4-.25.2-2.5-.4-.25-4.25-.35.25-3.05-4.05-.3.25-2.95-.1-.15-3.85-.3.2-2.8-.35-.35-3.9-.3.2-2.75-4.35-.35.25-3.05-3.35-.25.2-2.6-.55-.55-3.05-.25v-.05l-.3-1.5.05-.85-.35-.45-4.15-.3M6 16.45v-.1L8.95 19Q7.1 18.2 6 16.45z"
                    id="ObjToo016__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M43.65 24.05l.25-3-.1-.05-4.3-.35.2-2.75-3.3-.25-.15-3.05-.5-.45-4.9-.35.3-3.4-5.15-.05.8-4.35-4.7.5.1-4.4-4.95 1.65L15.8.05 13 2.85h-.05L9.65 0l-.8 4.25L4.1 3.4l1.7 4.05L1 8.55l3.1 3.25L0 15.1l3.8.95-1.05 3.75.2.15 4.1.3-.2 3.05.15.1 5.15.4-.3 3.3 3.95.3-.25 3.3.7.45 4.2.3-.25 3.05.2.15 3.5.25-.2 2.6.25.2 3.05.25-.15 2.15 3.7.25-.2 2.45 2.8.25-.15 2.3.2.2 3.75.3-.2 2.6.15.15 5.1.4-.25 3.35 4.25.35-.25 3.1 3.7.3-.25 2.8 3.4.25-.2 2.6.15.1 4.65.4.85-.7L8.95 19Q7.1 18.2 6 16.45v-.1q-1.8-2.55-.8-5.7 0-.1.1-.25.8-3 3.65-4.85 2.9-1.8 6.1-1.4 1.55.25 2.75 1 .2.15.4.35.2.05.35.15.55.45 1.05 1.05L74 53.6q1.25-.25 2.65-.3h.3q.7-.05 1.55-.05l.2-2.45-.25-.25-3.4-.25.2-2.5-.4-.25-4.25-.35.25-3.05-4.05-.3.25-2.95-.1-.15-3.85-.3.2-2.8-.35-.35-3.9-.3.2-2.75-4.35-.35.25-3.05-3.35-.25.2-2.6-.55-.55-3.05-.25v-.05l-.3-1.5.05-.85-.35-.45-4.15-.3z"
                    id="ObjToo016__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill="#252525"
                    d="M137.9 297.9q-.318-.406-.65-.85-2.212 2.2-5.3 2.2-.946 0-1.8-.2 2.58 3.25 5.25 6.5 2.85 3.25 2.3 7.9-.35 1.2-.65 2.15-.3.45-.4.85-1.15 3.15-5.35 1.35l-5.2-3.95.15.1-14.65-11q-1.55-.05-3.2.35l22.4 16.4q2.85 1.35 5.6.45 2.75-1.5 3.35-7.65.45-4.45-1.85-7.3-.7-.9-1.55-1.95-.714-.887-2.15-2.55 1.789-.113 3.2-.8.706-.364 1.35-.95-.4-.505-.85-1.05m-13.55-5.15q.09.986.25 2.3v.1q.05.85.15 1.45v1.5l-.2 1.35q-.15 1.4 1.65 1.55.75.05 2.05-1.4.431-.546.75-.9-1.323-.573-2.45-1.7-1.82-1.82-2.2-4.25m-17.85-17l4.95 3.55 1.85-5.45-6.05-4.6-.75 6.5m13.9-8.6q.35.05.7.3.2.05.35.15 1.4 1.1 1.2 3.5l-2.15 8.3-3.35-2.6-1.75 5.45 9.25 7q.531-1.681 1.9-3.05.53-.53 1.1-.95-.27-.316-.55-.65v-.1l-3.65-2.85q.35-1.4.9-3.45 1.15-4.4 1.5-6.2 1.1-4.65-.9-7.1-2.55-1.7-4.55 2.25m-7.75-8.45q.05-1-.1-1.9.05-.15.05-.25-1.05-.5-1.7-.55-1.65-.15-3.75.4.2.2.35.4l.05.05q.1.45 0 2-.2 2.15-2.15 4.2-.85 1.05-2.7 2.65l3.55 2.75q.6-.6 1.15-1.1 1.25-1.6 2.2-2.55 1.75-1.8 2.3-2.95.55-1.15.75-3.15z"
                    id="ObjToo016__Layer5_1_FILL"
                />
                <g id="ObjToo016__Layer5_0_FILL">
                    <path
                        fill="#252525"
                        d="M73.15 264.75q-.55-.65-1.2-.7-.75-.05-1.4.5-.55.5-.6 1.2-.05.75.4 1.4.55.55 1.3.6.65.05 1.3-.4.6-.55.65-1.3.05-.7-.45-1.3m44.45 21q-.65-.05-1.3.4-.65.5-.7 1.3-.05.75.5 1.3.55.65 1.2.7.75.05 1.4-.5.55-.45.6-1.2.05-.8-.4-1.4-.55-.55-1.3-.6m-9.9-3.5q.8.05 1.35-.5.6-.5.65-1.2.05-.75-.45-1.4-.45-.55-1.25-.6-.75-.05-1.3.4-.65.55-.7 1.3-.05.7.5 1.3.45.65 1.2.7z"
                    />
                    <path
                        fill="#434343"
                        d="M121.1 267.45q.2.05.35.15.333.262.55.6 2.81-2.54 4-1.2-.31-1.194-1.05-2.1-.9-1.05-2.4-1.65-.1-.1-.15-.1-4.1-1.8-6.05 1.85-.1.25-.25.55l-2.8 8.3-1.05-.8q-.602 4.76-1.5 5.75l.7.5-8.8 26.2 25.45 18.8q6.25 3.8 10.55.8 1.05-.7 1.8-1.85 1.05-1.3 1.7-2.2 2.3-3.9 2.55-8 .047-6.4-5.95-14.1-.4-.505-.85-1.05-.194-.248-.4-.5-.158.268-.4.45-1.2.9-5.4 1.5 2.2 2.15 4.3 6.15 2.3 4.3 2.3 6.95 0 1.75-.6 3.5-1.05 3.05-3.5 3.05-2.9 0-8.35-4.05l-4.9-4.1q-1.5-1.35-7.3-5.3-2.024-1.413-2.75-2.6-1.219.04-2.5.35l7-21.05 9.35 7.1q-.1.4-.15.75-.1.75-.1 1.45l-.15.25q0 .947.15 2.4.051-.138.1-.3.9-3 .9-3.8 0-1.55-3.6-5.25-3.505-3.554-3.6-7.15l-1.15-.9 3.15-9.65h.1q.35.05.7.3m-8.5-10.3q-.02-.172-.05-.35.05-.15.05-.25-.1-.7-.3-1.25-1.15-3.45-4.85-3.6-1.2 0-2.45.4-1.65.65-3.8 1.85-.55.2-.95.5-4.55 2.5-6.7 4.25.1-.05.2-.05l4 3.15q.6-.45 1.2-1 1.2-1.05 2.05-1.7 1.3-.9 3.05-1.65 1.7-.7 3.1-1.05.2.2.35.4 1.131-.106 3.25.1 1.082.101 1.85.25z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo016__Symbol_176_0_Layer0_0_FILL"
                transform="translate(11.75 222.6)"
                id="ObjToo016__col1n"
            />
            <use
                xlinkHref="#ObjToo016__Symbol_103_0_Layer0_0_FILL"
                transform="translate(65.4 259.4)"
                id="ObjToo016__col1s"
            />
            <use
                xlinkHref="#ObjToo016__Symbol_60_0_Layer0_0_FILL"
                transform="translate(5.75 206.25)"
                id="ObjToo016__col2n"
            />
            <use
                xlinkHref="#ObjToo016__Symbol_12_0_Layer0_0_FILL"
                transform="translate(5.75 206.25)"
                id="ObjToo016__col2d"
            />
            <use xlinkHref="#ObjToo016__Layer5_0_FILL" />
            <use xlinkHref="#ObjToo016__Layer5_1_FILL" />
        </g></g>
    )
}

export default ObjToo016
