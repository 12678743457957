import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D"
}

function FacMou049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M5.7 7.2q2.7 1.25 5 1.45.65 0 1.25.05v-.05h.35q2.15-.15 4.75-1.35Q19.8 6.05 23 3.6q-6.85-6.45-11.45-1.15Q6.95-2.95 0 3.6q3 2.3 5.7 3.6z"
                    id="FacMou049__Symbol_74_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou049__Symbol_74_0_Layer0_0_FILL"
                transform="translate(181 220.6)"
                id="FacMou049__col1n"
            />
        </g></g>
    )
}

export default FacMou049
