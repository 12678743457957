import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}

function LegPan034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M127.6.55q-.2 0-.4.05-.95.2-1.95.4h-1.05q-.85 0-29.3 2.3-28.45 2.25-56.85.3-28.4-2-28.55-2.05-1.5-.25-2.95-.5-.3-.1-.5-.1-1-.2-1.95-.4Q3.9.5 3.7.5 2.55.25 1.35 0 1.1.8.9 1.55-.2 5.8.05 10.1q1-.05 1.95-.1.3-.05.65-.05 1.05-.05 2-.05.2-.05.45-.05h.15q1.3-.05 2.7-.05h.4q13.9-.25 37.9 2.1 8.4.8 18 1.95.2.05.4.05h2q.2 0 .4-.05 9.6-1.15 18-1.95 24.35-2.35 38.3-2.1v.05q1.4 0 2.7.05h.15q.25 0 .45.05.95 0 2 .05.35 0 .65.05.95.05 1.95.1.25-4.35-.85-8.6-.2-.75-.4-1.45l-.05-.05q-1.2.25-2.35.5z"
                    id="LegPan034__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 0l.4 4.15h2L2.8 0H0z"
                    id="LegPan034__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M123.5 7.25q.3-.1.5-.1 1-.2 1.95-.4.2-.05.4-.05 1.15-.25 2.35-.5l.05.05q.35-3.15-.5-6.25-27.1 5.05-54.35 5.8l-3.65.05q-5.6.1-11.2 0L55.4 5.8Q28.1 5.05.65.05-.3 3 .1 6.15q1.2.25 2.35.5.2 0 .4.05.95.2 1.95.4.2 0 .5.1 1.45.25 2.95.5.15.05.35.1 55.45 9.95 111.95-.05 1.5-.25 2.95-.5z"
                    id="LegPan034__Symbol_78_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan034__Symbol_79_0_Layer0_0_FILL"
                transform="translate(126.85 309.9)"
                id="LegPan034__col1n"
            />
            <use
                xlinkHref="#LegPan034__Symbol_80_0_Layer0_0_FILL"
                transform="translate(191.1 319.65)"
                id="LegPan034__col1d"
            />
            <use
                xlinkHref="#LegPan034__Symbol_78_0_Layer0_0_FILL"
                transform="translate(128.1 303.75)"
                id="LegPan034__col2n"
            />
        </g></g>
    )
}

export default LegPan034
