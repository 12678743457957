import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#621F00",
    "col1n": "#9A3200",
    "col1s": "#2E0E00",
    "col2l": "#F3E4D8",
    "col2n": "#E7D4B6"
}

function ObjMus020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M94.9 44.65q11.65-6.05 11.75-6.25l4.3-25.65q-24.9 5.3-38.6 3-13.7-2.3-14.65-2.6l-.7-.3q-.25-.1-.45-.15l.05.15h-1.5v-.1l-.3.1q-.45.15-.85.3-.8.3-1.55.6-.1 0-14.5 1.75T.75 12.7L4.05 38h.05q20.55 9.65 33.55 5.05 12.95-4.6 13.15-4.65l.8-4.75h7.75L59.9 38h.05Q83.2 50.7 94.9 44.65z"
                    id="ObjMus020__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.5 0l-.1.3L0 20.8h7.75L5 0H3.5z"
                    id="ObjMus020__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M46.7.45h-.2Q23.25 7.7 0 0v5.55q24.9 8.2 46.7 0V.45m55.85 0h-.2Q79.1 7.7 55.85 0v5.55q24.9 8.2 46.7 0V.45z"
                    id="ObjMus020__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M111.9 7.55q-1.2-2.85-8-4.95Q95.7 0 84 0T64.05 2.6Q57.3 4.7 56.1 7.55q-1.25-2.85-8-4.95Q39.85 0 28.15 0T8.2 2.6q-2.8.9-4.65 1.9Q.7 6.05.1 7.9q-.15.45-.1.95-.05.45.1.85h.05v7.4l.7 5.3q25.75 9.75 51.5 1.1.05-.05.15-.05.75-.3 1.55-.6.4-.15.85-.3l.3-.1.55-3.4h.45l.45 3.35q.2.05.45.15l.7.3q.95.3 1.9.65 25.65 8.6 51.35-1.05l.85-5.35v-7q.25-.65.3-1.25-.05-.7-.3-1.3z"
                    id="ObjMus020__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    d="M136.55 307.5l.25 1.9q27.2 10.45 54.45.35h.1q.3-.15.65-.3.05 0 .15-.05v.05h.45v-.05q.1 0 .25.05.4.15.8.35 5.7 2.1 11.45 3.3 1.4.3 2.8.55h.1q20 3.45 40-4.25l.3-1.9q-25.4 9.85-50.75 1.85-2.55-.8-5.05-1.75l-.1-.1v.05q-27.95 10.8-55.85-.05z"
                    id="ObjMus020__Layer4_0_FILL"
                />
                <g id="ObjMus020__Symbol_8_0_Layer0_0_FILL" fill={colors.col2l}>
                    <path d="M55.6 9.2v-.05q-.1.05-.15.05-.35.15-.65.3h-.1Q27.45 19.6.25 9.15l.45 3.4q25.75 9.75 51.5 1.1.05-.05.15-.05.75-.3 1.55-.6.4-.15.85-.3l.3-.1.55-3.4z" />
                    <path d="M110.9 12.6l.85-5.35v-7q-1.2 2.85-8 5-8.2 2.55-19.9 2.55T63.9 5.25q-6.8-2.15-7.95-5-.097-.145-.1-.25v.5q-1.4 2.7-7.9 4.75Q39.7 7.8 28 7.8T8.05 5.25Q.95 3 0 0v7.25l.25 1.9q27.2 10.45 54.45.35h.1q.3-.15.65-.3.05 0 .15-.05v.05h.45l.45 3.35q.2.05.45.15l.7.3q.95.3 1.9.65 25.65 8.6 51.35-1.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus020__Symbol_105_0_Layer0_0_FILL"
                transform="translate(136.5 300.1)"
                id="ObjMus020__col1n"
            />
            <use
                xlinkHref="#ObjMus020__Symbol_81_0_Layer0_0_FILL"
                transform="translate(188.1 312.95)"
                id="ObjMus020__col1d"
            />
            <use
                xlinkHref="#ObjMus020__Symbol_54_0_Layer0_0_FILL"
                transform="translate(140.6 338.1)"
                id="ObjMus020__col1s"
            />
            <use
                xlinkHref="#ObjMus020__Symbol_33_0_Layer0_0_FILL"
                transform="translate(136.4 290.4)"
                id="ObjMus020__col2n"
            />
            <use
                xlinkHref="#ObjMus020__Symbol_8_0_Layer0_0_FILL"
                transform="translate(136.55 300.25)"
                id="ObjMus020__col2l"
            />
            <use xlinkHref="#ObjMus020__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjMus020
