import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#047403",
    "col1n": "#00B600",
    "col1s": "#034803"
}

function AccHat013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g
                    id="AccHat013__Symbol_144_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M208.15 0v.05l.05-.05h-.05z" />
                    <path d="M243.45 81.9q2.05-.15 3.2-1.35l.05-.05q.9-1.05 1.2-2.8.05-.4.1-.85.2-3-1.1-7.7-1.35 2.9-8.55 2.25-7.25-.7-15.2-5.15-8.1-4.6-14.15-10.65 6.15.3 10.1-5.35 1.5-2.25.25-3.2 10.65-8 8.4-25.2-.4-2.55-1.1-4.8l-.55-1.5q-4.45-8.7-12.85-13.45l-4-1.7q-.55-.2-1.05-.4l-.05.05q-2.4 37.9-78 27.7-28.35-1.9-47.4 4.15l-.45.2q-3.2 1.3-7.1 2.8l-2.45.95Q61.9 41.85 55 51.4q-6.95 9.5-31.8 26.9 16.2 5.15 35.9-15.9Q70 52.2 78.65 46.8t23-9.15Q160.55 21.9 209 47q3.85 1.6 3.6 2.8-.3 1.15-13.1.55-7.45-.35-13.75-1.05-2.55-.3-4.9-.6-12.2-1.45-24.15-1.75-4.95.15-10.1.25-27.45-.25-48.5 5.95-1.1.35-2.15.75-7.05 2.45-13.45 5.85l-3.35 1.85-1.2.7q-3.45 2.05-6.5 4.15-7.8 5.2-15.4 8.95-7.3 3.55-15.25 6.15-8.85 2.9-19.2 1.45 5.6 4.45 12.35 5.55-6.85 7.25-13.1 14-7.45 8-17.6 5.3-1.55-.4-3.05-.8-.05-.05-.1-.05l-.1 1.6q2 10.8 6.15 12.85 4.5 2.3 11.6-5.9Q31.2 99.9 34.05 88.6 50.2 87.4 79.8 66.2q28.9-19.3 125.55-11.25 8.7 12 27.2 24 .95.55 1.85 1 1.25.55 2.4 1 3.8 1.4 6.65.95z" />
                </g>
                <g
                    id="AccHat013__Symbol_113_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M4.75 84.1L.4 83.2q5.7 5.5 16.7 6.45-6.75-1.1-12.35-5.55m29.1-56.3q-.9 5.7-5.2 13.15T16.6 52.05Q8.85 55.7.45 55.95q-.2 0-.35.15-.15.15-.1.35 0 .2.15.35.1.1.35.1 8.55-.25 16.65-3.2 9.1-3.35 13.2-11.7 3.8-7.9 5-17.2.3-2.6.55-5.15 0-.2-.1-.35-.15-.2-.3-.2-.2 0-.35.15-.15.1-.15.3-.25 2.55-1.15 8.25M63.7.05q-.15.1-.2.3-1.6 5.85-7.9 10.1l-.2.35q-1.3 9.35.6 18.2.3 1.45 1.8 5.45.25.75.55 1.55.2.55.4 1.05 1.1 2.8 1.45 4.1 2.1 4.15 1.1 0-.7-2.5-1.25-4.65-.15-.55-.3-1.05-.2-.85-.4-1.6-1.5-5.5-2.05-8.1-1.3-6.2.25-14.35 4.35-2.6 5.65-6.8Q64.4.4 64.35.25q-.1-.2-.3-.25-.2-.05-.35.05m100.5 37.2q14.35 3.8 27.95 10.85 3.85 1.6 3.6 2.8l.1.05q1.9-1.2-1.75-3.4-.15-.1-.35-.15-16.05-8.1-33.2-11.75-8.6-1.8-17.5-2.5-19.75-2.45-40.95.75-10 1.5-20.3 4.25-14.35 3.75-23 9.15T39.25 62.9Q23.95 79.25 10.8 79.8q-1.85.05-3.6-.15 3.5.95 7.15.75 12.95-.9 27.9-16.9 10.9-10.2 19.55-15.6t23-9.15q11.4-3.05 22.45-4.55 28.65-3.3 56.95 3.05m39.85 11.3l-1.55-.35q-2.75 9.25-14 7.85 10 2.9 15.55-7.5z" />
                    <path d="M55.4 10.8l.2-.35q-.1.05-.1.15-.1.1-.1.2m140.25 40.25l.2-.1-.1-.05q0 .097-.1.15z" />
                </g>
                <path
                    fill={colors.col1n}
                    d="M126.8 2.1l.1-.05h-.1v.05m119.25 93.4q2.05-.15 3.2-1.35l.05-.05q.9-1.05 1.2-2.8.05-.4.1-.85.2-3.05-1.15-7.9-.05-.05-.05-.1-.2-.65-.35-1.25-3.85-14.7-27.1-20.55 10.65-8 8.4-25.2-.4-2.55-1.1-4.8l-.55-1.5-12.85-13.45-4-1.7q-.55-.2-1.05-.4h-.05v.05q-11-3.7-27.95-3.4-23.9-2.8-64.45-9-40.6-6.25-62.1 19.3Q38.95 40.9 10.9 59.7q-25.65 28.65 8.95 36Q3.95 105 2.8 120.65q-.05-.05-.1-.05v.05q-.05.8-.1 1.55 2 10.8 6.15 12.85 4.5 2.3 11.6-5.9 13.45-15.65 16.3-26.95Q52.8 101 82.4 79.8q28.9-19.3 125.55-11.25 8.7 12 27.2 24 .95.55 1.85 1 1.25.55 2.4 1 3.8 1.4 6.65.95z"
                    id="AccHat013__Symbol_231_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat013__Symbol_231_0_Layer0_0_FILL"
                transform="translate(66.4 47.95)"
                id="AccHat013__col1n"
            />
            <use
                xlinkHref="#AccHat013__Symbol_144_0_Layer0_0_FILL"
                transform="translate(69 61.55)"
                id="AccHat013__col1d"
            />
            <use
                xlinkHref="#AccHat013__Symbol_113_0_Layer0_0_FILL"
                transform="translate(85.85 60.45)"
                id="AccHat013__col1s"
            />
        </g></g>
    )
}

export default AccHat013
