import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#BFBFBF",
    "col1l": "#EEEEEE",
    "col1n": "#D9D9D9",
    "col1s": "#999999"
}

function BodHor003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M44.4 36.05q2.65-2.75 2.65-4.1 0-.6-.1-.85-.25-.5-.95-.5-1.75 0-6.8 2.4-4 1.85-8.65 4.5-2.95 1.65-5.85 6.15-2.9 4.55-2.9 7.65 0 2.05 1.35 4.85 1.15 2.45 2.4 3.7 4.35-2.2 8.9-10.5 2.7-5.1 6.1-9.35 2.4-2.45 3.85-3.95m44.7 8q-.25-.1-.5-.15-6.05-1.75-11.9-2.4-6.15-.7-12.3-.4l3.05-6.65q4.5.2 9.95.6 5.5.35 12 1.55-.05-2.95-.3-5.85-5.25-.85-9.65-1.2-.4-11.4-.15-12.2.25-.95.6-1.3l4.7-5.15Q94.9-.5 93.3.25l.05-.25Q81.3 7.35 71.9 16.55 62.2 26.1 58.05 33.4q-4.2 7.25-7.55 13.15-3.45 5.8-5.25 9.4-.4.7-.9 1.5Q33.2 77.7 19.25 77.6q-5.6.45-9.95-5.7-4.45-6.2-3.5-14.2.9-7.65 3.7-15.05.15-.3.25-.6v-.1q-.05.1-.05.15l-.05.05q-.95 1.3-3.5 4.5-.25.25-.5.55Q-3.8 61.75 2.6 78.85q1.1 2.8 2.85 4.6 11.4 13.6 32.15 3.15 6.7-4 12.45-11.55l4.3-6.6 33.4-16.5q1.1-4.9 1.35-7.9m207.4-.1q-.65-.8-5.6-6.1-2.3-2.4-6.3-4.35-5.2-2.25-8.3-3.65h-1.25v1.25q1.1 3.6 4.9 8.5 2.1 2.75 6.2 7.65 2.05 2.65 5.1 6.45 1.4 1.8 2.5 3.4.05.1.1.15l-.2 1q0 .65.65 1 .6.35 1.65.35 1.15 0 1.65-.35.6-.4 1.2-1.65.9-2.05.95-2.15.55-1.5.55-2.6 0-4-3.8-8.9m19.5 2.7q-2.55-3.2-3.5-4.5l-.05-.05q0-.05-.05-.15v.1q.1.3.25.6 2.8 7.4 3.7 15.05.95 8-3.5 14.2-4.35 6.15-9.95 5.7-13.95.1-25.1-20.15-.5-.8-.9-1.5-1.8-3.6-5.25-9.4-3.35-5.9-7.55-13.15-4.15-7.3-13.85-16.85-9.4-9.2-21.45-16.55l.05.25q-1.6-.75 8.7 10.65l4.7 5.15q.35.35-.7 3.7-.85 2.5-2.55 10.2-2.8.3-5.95.8-.25 2.9-.3 5.85 6.5-1.2 12-1.55 5.45-.4 9.95-.6l3.1 6.65q-6.2-.3-12.35.4-6 .7-12.15 2.5-.1 0-.25.05.25 3 1.35 7.9l33.4 16.5 4.3 6.6q5.75 7.55 12.45 11.55 20.75 10.45 32.15-3.15 1.75-1.8 2.85-4.6 6.4-17.1-3.05-31.65-.25-.3-.5-.55z"
                    id="BodHor003__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M70.35 3.05Q66.25 8.25 62.6 14q3.5.2 8.1.55 4.65.35 10.3 1.25-.7-7-2.7-14.1l-6.5-.6q-.35.35-.75.9-.35.45-.7 1.05M47.8 7.45q4.75-4.5 8.15-7.45l-2.9.8q-2.3.1-9.75 1.8-7.6 1.65-17.55 6.3-10.05 4.55-15.5 9-5.25 4.2-7.5 7.4-.85 1.1-1.1 1.6-.15 0-.3.2l.25-.15q-.35.45-.05.25l.05-.05q0-.05.05-.15v.1q-.1.3-.25.6Q-.65 34.75.35 42.8q1.05 8.5 8.3 10.3 7.3 1.85 11.9-5.25Q25 40.7 29.25 34.7q4.2-5.95 9.05-14.25 4.8-8.4 9.5-13m-29.5 36.4q-1.75-.7-2.35-6.25-.7-5.65 6.25-11.1 6.85-5.5 15.55-9.95Q26.4 29.6 18.3 43.85M227.65 1.7q-2 7.1-2.7 14.1 5.65-.9 10.3-1.25 4.6-.35 8.1-.55-3.65-5.75-7.75-10.95-.35-.6-.7-1.05-.4-.55-.75-.9l-6.5.6m76.95 25.4q-.15-.2-.3-.2-.25-.5-1.1-1.6-2.25-3.2-7.5-7.4-5.45-4.45-15.5-9-9.95-4.65-17.55-6.3Q255.2.9 252.9.8L250 0q3.4 2.95 8.15 7.45 4.7 4.6 9.5 13 4.85 8.3 9.05 14.25 4.25 6 8.7 13.15 4.6 7.1 11.9 5.25 7.25-1.8 8.3-10.3 1-8.05-1.05-15.1-.15-.3-.25-.6V27q.05.1.05.15l.05.05q.3.2-.05-.25l.25.15M290 37.6q-.6 5.55-2.35 6.25-8.1-14.25-19.45-27.3 8.7 4.45 15.55 9.95 6.95 5.45 6.25 11.1z"
                    id="BodHor003__Symbol_5_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M24.7 27q6.75-11.85 8.7-22.7Q22.55-1.15 6.9.3L0 20.8q12.1.05 24.7 6.2m188.75-6.2L206.55.3q-15.65-1.45-26.5 4 1.95 10.85 8.7 22.7 12.6-6.15 24.7-6.2z"
                    id="BodHor003__Symbol_2_0_Layer0_0_FILL"
                />
                <g
                    id="BodHor003__Symbol_10_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M86.85 48.5q.45-2.55.6-4.4.35-3.95.3-7.45-.05-2.95-.3-5.85l-9.4-14.45q.1-.15.2-.25l4.7-5.15Q93.25-.45 91.65.3l.05-.25-.45-.05q-.45.15-.5.2-4 1.45-4.85 1.75Q80.4 4.4 73.8 7.7q-6.6 3.2-10.3 6.35-.55.45-1.1.95-.8.7-1.65 1.45-29.4 3.45-52.95 26.5 0-.15.05-.25.15-.3.25-.6V42q-.05.1-.05.15L8 42.2q-.3.4-.7.95-1 1.3-2.8 3.55-.25.25-.5.55-.05.05-.05.1-.9 2.45-1.9 5.25Q0 58.1 0 62.2q0 6.95 3.5 12.35 1.25 1.95 3.05 3.2 1.45 1 4.95 2.7 1.3.6 5.15 1.05 3.45.4 3.75.1v-.05q7.45-.05 11.5-2.65 5-3.25 10.35-13.1 3.1-5.8 6.05-10 3.1-4.05 4.45-5.85 2.5-3.4 5.45-4.65 2.05-.85 5.3-.85 6.75 0 14.15 1.7 3.65.85 9.2 2.35m228-1.25q-.25-.3-2.65-2.3-.45-.4-.8-.75 0 .047.05.1-.603-.603-1.15-1.2l-.9-.9q-20.5-20.5-49.45-26.35-.35-.05-.6-.05l-2.9-.8q-.5-.5-1.05-.95-3.75-3.15-10.35-6.35-6.6-3.3-12.1-5.75-.85-.3-4.85-1.75-.05-.05-.5-.2l-.45.05.05.25q-1.6-.75 8.7 10.65l4.5 4.95.2.2-.35.05-8.85 14.65q-.25 2.9-.3 5.85-.05 3.5.3 7.45.15 2 .7 4.85 6.1-2.15 12.05-3.3 5.9-1.2 10.05-1.2 6.1 0 10.45 4.55 3.25 3.45 9.6 14.7 4.85 8.6 9.7 13 6.55 6 14.6 6 8.2 0 15.7-8.9 2.1-2.7 3.35-6.85.65-2.55.65-6.2 0-4.4-3.4-13.5z" />
                    <path d="M310.5 42.75l-.2.35.25-.25q-.036-.036-.05-.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#BodHor003__Symbol_10_0_Layer0_0_FILL"
                transform="translate(33.1 76.3)"
                id="BodHor003__col1n"
            />
            <use
                xlinkHref="#BodHor003__Symbol_6_0_Layer0_0_FILL"
                transform="translate(31.45 76.35)"
                id="BodHor003__col1d"
            />
            <use
                xlinkHref="#BodHor003__Symbol_5_0_Layer0_0_FILL"
                transform="translate(39.55 91.3)"
                id="BodHor003__col1l"
            />
            <use
                xlinkHref="#BodHor003__Symbol_2_0_Layer0_0_FILL"
                transform="translate(85.8 124)"
                id="BodHor003__col1s"
            />
        </g></g>
    )
}

export default BodHor003
