import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#F0E8DA",
    "col2n": "#E7D4B6"
}

function ObjMus034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjMus034__Layer4_0_FILL">
                    <path
                        fill="#E30000"
                        d="M258.1 309.1q.05.05.15.1l-.15-.1z"
                    />
                    <path d="M227.6 273.25l-.1-.4.15.55-.05-.15m-24.5-.2l-.25-.75 3.6 12.35.9-.35-1.75-5.55h.05l-.3-.85.25.65-2.45-8.2-.8.25.75 2.45m1.7 3.1h-.05l-.3-1.15.35 1.15m10.5-3.25q-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95m18.7-11.95l.05.35-.3-1.2-.8.25 2.35 8.1 1.6 5.45.9-.3-.85-2.75h.05l-.5-1.7h-.1l-1.65-5.8h-.05l-.7-2.4m-1.15 9.4q-.15-.05-.35 0-.65.05-.85.45-.15.2-.15.5 0 1 1 1h.1l.5-.15q.1-.1.2-.25.2-.3.2-.6 0-.9-.65-.95m-2.65-5.5q-.5.15-.5.75 0 .95.9 1h.1q.4 0 .7-.25.05-.05.1-.15.2-.3.2-.6 0-1.1-1-.95l-.15.05h-.05l-.2.05v.05l-.1.05m-13.2.9l-.8.3 2.4 8.2.3 1 .5 1.65.85 2.9.9-.35-.9-2.85.05.05v-.05l-1.15-3.75q.748 2.255-2.15-7.1m1.45 4.85q.21.588.35 1.05h-.05l-.35-1.05h.05m-7.55.5h-.05l-.85-3.05v.05l-.8.25 4.05 13.9.9-.35-3.25-10.8m14.65-8.15l.55-.15.1-.1-2.15.75.65-.2 3.55 12.1.45 1.45.9-.3-.25-.85.1.25-2.1-7h-.05l-1.75-5.95M192.4 282.4q.2-.3.2-.65 0-1.05-1-.95-1 .15-1 1 .1 1 1 1 .55 0 .8-.4m.4-6.4l.05.2-.65-2.15-.05-.1v-.1l-1.65.55.8-.25.05.15v.05l.25.85-.1-.3 2.2 7.55.05.05 1.75 5.95.9-.35-.5-1.5.35.95-.75-2.4h.05l-2.1-6.9.1.25-.75-2.5m.7 2.95q-.025-.046-.05-.15.042.106.05.15m8.8-1.7q-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95m-4.2 1.05q.817 2.896 2.5 8.4l.9-.3-.9-3h.05l-.55-1.7h-.05l-.25-.9.15.45-.15-.45h.05l-1.7-5.6h-.05l-.85-2.9-.75.25.05.3v.15l1.55 5.3m46.05-14.9h-.05l-1.8-6.05-.85.25 1.5 5.05.85 2.9 1.55 5.35.95-.3-.55-1.65-.05-.25-1.55-5.3m1.3 4.7l-.1-.2h.05l.05.2m3.95-6.4q-.15-.05-.35 0-.55.05-.8.35-.2.25-.2.6 0 1 1 1 .196 0 .35-.05.081-.015.15-.05l.3-.3q.2-.3.2-.6 0-.75-.5-.95h-.15m4.2-2.4v-.15l-1.5-5.05-.85.25 3.85 13.1.9-.3-1.75-5.85.2.65-.5-1.7h-.05l-.3-.95m-64.45 31.5l-.05-.05v.05h.05m-1.2-13.6h.05l-.55-1.85-.5.2-.3.1.85 2.95.55 1.9.3.95.05.05 1.1 3.85v.05l1.15 3.7-.6-2.05.05-.05.55 2.1.25 1q.013.022 0 .05l1-.4v-.05l-.1.05-2.55-8.55h-.05l-.3-1h.05l-1.15-3.75.5 1.9-.35-1.15z" />
                    <path fill="#707070" d="M203.4 270.1h.05v-.05l-.05.05z" />
                    <path
                        fill="#2F2F2F"
                        d="M228.25 261.35l.2-.1q0-.05.1-.1l.3-.2q.35-.3.7-.55 13.35-10.9 16.55-27l-10.2 6.25q-3.95 8.45-14.9 18.4-1.707 1.593-3.6 3.15-2.266 1.852-4.8 3.65l-.4.3-4.95 3.8 1.95-.65v.05l.8-.25v-.05l6.2-2.05v.05l.8-.3 7.05-2.3 2.15-.75.4-.2q.85-.55 1.65-1.15z"
                    />
                    <path
                        fill="#73613C"
                        d="M196.7 288.15v-.1l-.05-.05v.1l.05.05z"
                    />
                    <path
                        fill="#FFF"
                        d="M184.5 276.5v-.05l-.05.05h.05m-66.2 38.6q-.15-.15-.35-.25-1.45-.85-3.45-.15-3.1 1.05-4.3 3.95-.75 1.8-.75 4.35 0 1.05.35 1.75.5.9 1.5 1.3.7.25 1.65.25 1.6 0 3.95-2.6.35-.4.7-.75 1.95-2.55 1.95-5.1 0-1.85-1.25-2.75m15.15-31.1q.11.035.2.05 2.15.45 3.8 2.1 2.25 2.25 2.25 5.45t-2.25 5.45q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.398 1.969-1.35 3.7l-.1.25q-.198.374-.45.75-.599.979-1.4 1.9-.378.428-.8.85-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-.347.132-.7.25-.196.063-.4.1.249 1.396.5 2.9.45 2.55.05 4.75-.55 2.7-2.3 4.9-.1.1-1.75 2.25t1.15 5.15q.4.45.95.9 5.9 3.65 17.7 1.65 9.25-2.05 15.7-8.75 1.2-1.25 2.2-2.6 2.95-3.9 6.5-5.95l.6-.3q.25-.15.55-.25Q173 312 183.15 313q1.3.2 2.6.35 6.9.65 13.5-.95 6.6-1.65 9.35-4.1 1.9-1.75 2.05-1.8l.9-1.1q.45-.9.05-1.65-.45-.75-.55-.85-.65-.55-2.2-.25-.4.05-.65.15-.3.05-1.15.2-.85.15-1.65.25-.8.05-.9.05-10.85 1.7-13.6-7.15l-.4-.9q-.794-2.024-1-3.45-.04-.37-.05-.7v-.05q-.046-.21 0-.35l-.3.1h-.05v-.05l-1.6.55-.05-.05-.35-1.25v-.15q0-.05-.05-.1H187l-3.75-13 3.4-1.1.3-.1q.65-1.85 1-5.5 0-1.65-1.05-2.75-1.65-1.7-5.3.1-1.25.6-3.55 2.45-.3.2-.55.45-1.1.8-2.05 1.4-12.85 8.3-24.2 6.15-.5-.05-1.2-.1l-5.05-.2q-4.55 0-7.7.85-1.354.35-1.7.55-.337.249-.4.15-.018-.048-1.85 1.05l-.8 3.75h.05q.242 0 .45.05h.05q.178.036.35.05m2.75 14.15q-.304.261-.55.35.106-.036.2-.1.18-.118.35-.25z"
                    />
                    <path fill="#960" d="M250.3 264.25h.05l.9-.35-.95.35z" />
                    <path
                        fill="#CCC"
                        d="M274 241.35v2l4.9-.2v-1.95l-4.9.15m-1.55-4.25v3.1l7.8-.3v-3.1l-7.8.3m29.75 3.25l.1 1.85 4.8-.2-.1-1.85-.15.05-4.65.15m-1.65-4.45v3.15l8-.3v-3.2l-8 .35m-12.6 6.65v-.75-1l-5.05.2.1 2 2.4-.1q.056-.004.1 0h.95q.318-.04.6-.1.478-.02.9-.05v-.2m1.4-3.05v-3.15l-7.7.35v3.1l7.7-.3m3.2 1.2l.1 1.9 4.8-.2v-1.9l-4.9.2m-1.55-4.4v3.15l7.65-.3V236l-7.65.3z"
                    />
                    <path
                        fill="#999"
                        d="M272.45 240.2v1.2l1.55-.05 4.9-.15 1.35-.05v-1.25l-7.8.3m28.1.2l1.65-.05 4.65-.15.15-.05 1.55.05v-1.45l-8 .3v1.35m-11.2.35v-1.25l-7.7.3v1.25l1.25-.05 5.05-.2 1.4-.05m1.65 0l1.55-.05 4.9-.2.8-.05.4-.1v-1.2l-7.65.3v1.3z"
                    />
                    <path
                        fill="#333"
                        d="M94.35 311.9l-1.3-4.8-2.1.5 1.05 4.85 2.35-.55z"
                    />
                    <path fill="#C71212" d="M102.95 272.9l-.05.05.05.05v-.1z" />
                </g>
                <g id="ObjMus034__Layer4_0_MEMBER_0_FILL">
                    <path
                        fill="#999"
                        d="M278.4 245.8q-.85-.8-1.95-.8-1.15 0-1.95.8t-.8 1.95q0 1.15.8 1.95t1.95.8q1.1 0 1.95-.8.8-.8.8-1.95t-.8-1.95m-3.1.8q.5-.45 1.15-.45.65 0 1.1.45.5.5.5 1.15 0 .65-.5 1.15-.45.45-1.1.45-.65 0-1.15-.45-.5-.5-.5-1.15 0-.65.5-1.15z"
                    />
                    <path
                        fill="#CCC"
                        d="M276.45 246.15q-.65 0-1.15.45-.5.5-.5 1.15 0 .65.5 1.15.5.45 1.15.45.65 0 1.1-.45.5-.5.5-1.15 0-.65-.5-1.15-.45-.45-1.1-.45z"
                    />
                </g>
                <g id="ObjMus034__Layer4_0_MEMBER_1_FILL">
                    <path
                        fill="#999"
                        d="M285.7 245q-1.15 0-1.95.8t-.8 1.95q0 1.15.8 1.95t1.95.8q1.1 0 1.95-.8.8-.8.8-1.95t-.8-1.95q-.85-.8-1.95-.8m0 1.15q.65 0 1.1.45.5.5.5 1.15 0 .65-.5 1.15-.45.45-1.1.45-.65 0-1.15-.45-.5-.5-.5-1.15 0-.65.5-1.15.5-.45 1.15-.45z"
                    />
                    <path
                        fill="#CCC"
                        d="M286.8 246.6q-.45-.45-1.1-.45-.65 0-1.15.45-.5.5-.5 1.15 0 .65.5 1.15.5.45 1.15.45.65 0 1.1-.45.5-.5.5-1.15 0-.65-.5-1.15z"
                    />
                </g>
                <g id="ObjMus034__Layer4_0_MEMBER_2_FILL">
                    <path
                        fill="#999"
                        d="M294.95 244.75q-1.15 0-1.95.8t-.8 1.95q0 1.15.8 1.95t1.95.8q1.1 0 1.95-.8.8-.8.8-1.95t-.8-1.95q-.85-.8-1.95-.8m0 1.15q.65 0 1.1.45.5.5.5 1.15 0 .65-.5 1.15-.45.45-1.1.45-.65 0-1.15-.45-.5-.5-.5-1.15 0-.65.5-1.15.5-.45 1.15-.45z"
                    />
                    <path
                        fill="#CCC"
                        d="M296.05 246.35q-.45-.45-1.1-.45-.65 0-1.15.45-.5.5-.5 1.15 0 .65.5 1.15.5.45 1.15.45.65 0 1.1-.45.5-.5.5-1.15 0-.65-.5-1.15z"
                    />
                </g>
                <g id="ObjMus034__Layer4_0_MEMBER_3_FILL">
                    <path
                        fill="#999"
                        d="M306.15 245.55q-.85-.8-1.95-.8-1.15 0-1.95.8t-.8 1.95q0 1.15.8 1.95t1.95.8q1.1 0 1.95-.8.8-.8.8-1.95t-.8-1.95m-3.1.8q.5-.45 1.15-.45.65 0 1.1.45.5.5.5 1.15 0 .65-.5 1.15-.45.45-1.1.45-.65 0-1.15-.45-.5-.5-.5-1.15 0-.65.5-1.15z"
                    />
                    <path
                        fill="#CCC"
                        d="M304.2 245.9q-.65 0-1.15.45-.5.5-.5 1.15 0 .65.5 1.15.5.45 1.15.45.65 0 1.1-.45.5-.5.5-1.15 0-.65-.5-1.15-.45-.45-1.1-.45z"
                    />
                </g>
                <g id="ObjMus034__Layer4_1_FILL">
                    <path
                        fill="#CCC"
                        d="M162.8 315.55q.2-.3.2-.55 0-.8-.85-.85-.9-.05-.9.85t.9.9q.4 0 .65-.35m26.8-3.65q0-.8-.85-.85-.9-.05-.9.85t.85.9q.45 0 .7-.35.2-.25.2-.55m19.65-8.3q-.9-.05-.9.85l.1.45q.2.5.75.5.45 0 .7-.35.2-.35.2-.6 0-.8-.85-.85m-21.5-10.5q-.9-.05-.9.85t.85.9q.45 0 .7-.35.2-.25.2-.55 0-.8-.85-.85m-14.05-15.95q-.9.25-1.4 1.1-.35.65-.3 1.25l4.25 16.4v-.1l.2.8q.35.65.9 1 .9.5 1.8.25.95-.25 1.4-1.15.4-.55.35-1.25l-.8-3.3h-.05l-1.85-7.3h-.05l-1.45-5.6q.02-.066 0-.15l-.05-.2-.05-.1q0-.1-.05-.2-.3-.75-1.05-1.2-.85-.45-1.8-.25m9.85-7.9q0 .9.85.9.45 0 .7-.35.2-.25.2-.55 0-.8-.85-.85-.9-.05-.9.85m-59.9 57.4q.9 0 .9-.85t-.85-.9q-.9-.05-.9.9 0 .85.85.85M151.2 289q-.017 0-.05-.05-.035-.505-.35-1-.45-.85-1.4-1.05-.9-.25-1.8.2-.55.35-.9.95l-1.9 6.95.05-.05-1.4 5 .05-.05-.5 1.75-.55 1.95.05-.05-.35 1.7-.1.05q0 .214.05.4.057.379.25.7.5.9 1.4 1.15.95.25 1.8-.25.6-.35.95-.95l.85-2.9h-.05l.45-1.75h.05l.55-1.95h-.05l.4-1.55h.05l1-3.65h-.05l.35-1.35h.05l1.05-4.1h.05q-.003-.037 0-.1m10.5-9.3q.2-.3.2-.55 0-.8-.85-.85-.9-.05-.9.85t.9.9q.4 0 .65-.35m-4.9 3.4q-.35.65-.3 1.25l3 11.5.8 3.1.4 1.4v.05l.05.1v.15l.2.8q.35.65.9 1 .9.5 1.8.25.95-.25 1.4-1.15.4-.55.35-1.25l-1.5-5.95h-.05l-1.05-4.25h-.05l-1.55-6V283.95l-.05-.2-.05-.1q0-.1-.05-.2-.3-.75-1.05-1.2-.85-.45-1.8-.25-.9.25-1.4 1.1m-15.7-3.95v.15q.05.75.85.75.45 0 .7-.35.15-.2.2-.4v-.15q0-.8-.85-.85-.9-.05-.9.85z"
                    />
                    <path
                        fill="#999"
                        d="M112.2 318.4q-.6 0-.95.85-.55 1.1-.7 3.7 0 .05.2 1 .4.8 1.4.95h.3q1.55 0 3.15-1.65.55-.55.85-.95.5-.7.5-1.15 0-.55-1.85-1.65-1.85-1.1-2.9-1.1m24.35 8.65q0-1.5-1.05-2.55-1.05-1.05-2.55-1.05-1.5 0-2.55 1.05-1.05 1.05-1.05 2.55 0 1.5 1.05 2.55 1.05 1.05 2.55 1.05 1.5 0 2.55-1.05 1.05-1.05 1.05-2.55m1.3-11.4q-1.5 0-2.55 1.05-.45.45-.7.95-.35.7-.35 1.6 0 1.5 1.05 2.55 1.05 1.05 2.55 1.05 1.5 0 2.55-1.05 1.05-1.05 1.05-2.55 0-.9-.35-1.6-.25-.5-.7-.95-1.05-1.05-2.55-1.05m-13.25-4.3l-.3-1.3q-1.952.55-4.15.55-1.016 0-2-.15l.8 2.85 5.65-1.95M144 314q.9-.9 1.05-2.05l-7.2-.2q.1 1.3 1.05 2.25 1.05 1.05 2.55 1.05 1.5 0 2.55-1.05m0-5.1q-.6-.6-1.3-.85-.6-.2-1.25-.2t-1.2.2q-.75.25-1.35.85-.25.25-.4.5l-3.2-.15-4.25-.15 1.65-.6-.75-3.55q-.042.035-.1.05-.11.188-.25.35-.382.432-.8.85-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.04.023-.1.05l.35 1.4 32.9.75q.1-.6-.6-1.1l-14.85-.55q-.2-.35-.55-.7z"
                    />
                    <path
                        fill="#666"
                        d="M150.8 322.4q.05.75.75.75.75 0 .75-.75 0-.65-.75-.75-.75-.05-.75.75m3.3-7.7q.05.75.75.75.75 0 .75-.75t-.75-.75q-.75 0-.75.75m-2.45 2.95v.2q-.25.65-.25 1.45-.25 1.05.75 1.05h.45q.45 0 1.2-1.25.15-.25.3-.45l.25-.5q.1-.3.15-.5v-.1-.2q.2-.3.2-.65 0-1.95-1.65-.65-.45.35-.75.75-.6.25-.65.85M133.6 302.6l-.05-.1v-.05l-.1.25q-.198.374-.45.75-.463.765-1.05 1.5l.75 3.55-1.65.6 4.25.15-1.7-6.65m25.65 9.75q.65-.6.75-1.1l-32.9-.75-.35-1.4q-.296.167-.65.3-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-.605.23-1.25.4l.3 1.3-5.65 1.95.4 1.4 9.8-3.2 8.7.25 7.2.2 14.2.4z"
                    />
                </g>
                <g id="ObjMus034__Layer4_2_FILL">
                    <path
                        fill="#666"
                        d="M178.9 293.8q-.35-.25-.8-.1-.45.1-.7.5-.2.35-.1.8.15.45.5.7.4.2.85.1.45-.15.65-.5.25-.4.1-.85-.1-.45-.5-.65m-1.55-5.95q.2-.4.1-.85-.15-.4-.55-.65-.35-.25-.8-.1-.45.1-.7.5-.2.4-.05.8.1.45.5.7.35.25.8.15.45-.15.7-.55m1.05 2.95q-.15-.45-.5-.7-.3-.2-.6-.15-.1 0-.25.05-.45.1-.65.5-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8m-3.85-7.7q-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8-.15-.45-.5-.7-.3-.2-.6-.15-.1 0-.25.05-.45.1-.65.5m.15-1.95q.45-.15.7-.55.2-.4.1-.8-.15-.45-.5-.7-.4-.25-.85-.1-.45.1-.65.5-.25.4-.1.85.1.4.5.65.35.25.8.15m-29.1 23.25q-.15-.45-.5-.7-.3-.2-.6-.15-.1 0-.25.05-.45.1-.65.5-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8m-.2-4.4q-.1 0-.25.05-.45.1-.65.5-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8-.15-.45-.5-.7-.3-.2-.6-.15m16.5-.95q-.2.35-.1.8.15.45.5.7.4.2.85.1.45-.15.65-.5.25-.4.1-.85-.1-.45-.5-.65-.35-.25-.8-.1-.45.1-.7.5m-.05-4.15q-.1 0-.25.05-.45.1-.65.5-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8-.15-.45-.5-.7-.3-.2-.6-.15m.1-3.05q-.15-.4-.55-.65-.35-.25-.8-.1-.45.1-.7.5-.2.4-.05.8.1.45.5.7.05.05.15.1.3.15.65.05.15-.05.3-.15.25-.15.4-.4.2-.4.1-.85m-1.5-4.25q-.25-.15-.6-.1l-.55.2q-.2.1-.35.3-.2.4-.1.85.15.45.5.65.25.15.5.2.15 0 .35-.05.25-.1.4-.2.15-.15.25-.35.25-.35.1-.8-.1-.45-.5-.7M146.1 296q-.4.3-.55.7-.1.45.15.8.2.45.65.55.45.15.85-.15.35-.2.45-.65.15-.4-.05-.85-.25-.35-.7-.5-.45-.1-.8.1m.5-3.2q-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8-.15-.45-.5-.7-.3-.2-.6-.15-.1 0-.25.05-.45.1-.65.5m3-3.3q-.15-.45-.5-.7-.3-.2-.6-.15-.1 0-.25.05-.45.1-.65.5-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8m9.9-5.55q-.3-.2-.6-.15-.1 0-.25.05-.45.1-.65.5-.25.4-.1.85.1.4.5.65.35.25.8.15.45-.15.7-.55.2-.4.1-.8-.15-.45-.5-.7z"
                    />
                    <path
                        fill="#CCC"
                        d="M110.55 322.95q0 .05.2 1 .4.8 1.4.95l2.55-3.65-3.45-2q-.55 1.1-.7 3.7m24.8 4q0-1.15-.8-2l-.1-.1q-.85-.85-2.05-.85-1.25 0-2.15.85-.85.9-.85 2.1 0 1.25.85 2.1.9.9 2.15.9.35 0 .7-.05.75-.2 1.35-.85.9-.85.9-2.1m-.4-5.9l.2.2q.7.7 1.65.85.25.05.5.05 1.2 0 2.05-.9.75-.7.9-1.7v-.4q0-.25-.05-.5-.1-.55-.35-1-.2-.3-.5-.6-.1-.1-.25-.2-.75-.65-1.8-.65-1.25 0-2.15.85-.25.3-.45.6-.4.7-.4 1.5 0 .2.05.4.1.85.6 1.5m8.35-11.5q-.15-.2-.35-.4-.85-.85-2.05-.85-1.25 0-2.15.85-.1.1-.2.25l4.75.15m8.35 8.1v.2q.05 1.25 1.25 1.25.3 0 .6-.2.35-.05.6-.25l.25-.5v-.3-.1-.1q0-1.05-.95-1.05-.7 0-1.1.2-.6.25-.65.85m-8.7-4.3q.65-.6.85-1.45l-5.85-.15q.15.95.8 1.6.9.9 2.15.9 1.2 0 2.05-.9z"
                    />
                </g>
                <path
                    fill={colors.col1n}
                    d="M113.65 2q-2.1-2-5.6-2-6.95 0-10.95 1.55-2.45.9-3.5 1.25v.05Q88.85 5.4 84.3 9q-5.85 5.35-10.95 8.55-.8.45-1.5.9l-1.7.9q-7.9 3.8-16.15 1.95-.45-.15-.95-.25-3.65-1-5.4-1.45-1.8-.45-2.3-.65-.05 0-1.35-.35l-3.7 17.7h.55q.25.05.55.1 1.75.35 3.15 1.5.65.4 1.25 1 2 2 2 4.85 0 1.5-.3 3.35-.3 1.8-1.75 3-1.3 1.1-3.2 1.55-.95 3.85-4 6.9-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-2.5.95-5.4.95-6.25 0-10.65-4.4-4.45-4.4-4.45-10.6 0-5.6 3.6-9.75l.3-.3.05-.05q0-.05.05-.05.2-.25.45-.5l.2-.2.05-.05q0-.05.05-.05.15-.15.35-.25.05-.1.1-.15 1.65-9.2 4.15-17.5Q9.6 23.3 3.9 34.75-2.8 49.8 1.95 64q.5 1.55 1.15 3.05l.45.95q4.9 10.4 17.25 16.85 5 2.25 10.85 3.25 4 .8 8.1.8 5.85 0 11.75-1.7 3.9-.9 7.4-2.75 4.95-2.75 8.95-7.5.05-.1.1-.1l.05-.05q1.15-1.3 2.25-2.6 3-4.6 8.05-5.55 6.6-.8 11.5-.2 10.1 1.2 18.7-1.6 3.05-1.05 6.05-2.7 4.75-2.6 7.15-5.5.2-.3.4-.55 1-1.75 0-3.8-1.15-1.85-4.3-1.6l-7.8.7h-.1q-.6 0-1.2-.1h-.25q-4.25-.3-6.8-4.85-2-3.6-2-7.05v-2.7q.1-.8.8-3.3.05-.25.15-.45.1-.8.3-1.85.5-2.55.75-3.25.25-.65.45-1.3v-.05q-.1-.95 0-1.5.1-.45.45-.7v-1.6q-1.85-1.25-2.65-4.65-.85-3.65 5.2-8.3 3.6-2.35 6.45-3.3 1.4-.5 2.15-1.55 1.45-2.6 0-4.85-.05 0-.05-.05m52.4 59.5h.05l-.2-.1q.05.05.15.1z"
                    id="ObjMus034__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M131.2 8.6q0-3.85-2.75-6.6-1.35-1.35-2.95-2L84.7 1.65q-.2.15-.3.3L83 4.55q-.45 1.15-2.25 2.9-1.1.65-2.2 1.25-.4.25-.8.45L51 18.05V18l-2.95.95.05.05-4.75 1.55-.4.2-8.8 2.95v.1h-.4l-.8.3v-.05l-6.2 2.05v.05l-.8.25v-.05L24 27h-.05l-3.5 1.15h-.05l-6.4 2.1v.05l-5.1 1.6v.1l-.8.25-.05-.05-3.2.95v.05L0 34.8l.85 3H.8l.55 1.85h.05l.3 1h-.05l.85 3.05h.1l.25.95H2.8l.5 1.65h.05l.85 3 .05.05 3.4-1.2v-.05l2.15-.75v.05l3.65-1.3v.05l3.9-1.4v-.05L30 40.3v-.05l10.65-3.75q6.1-2.15 12.25-4.3L82 21.9l-.05-.15 2.25-.45h.2q1.8-.05 3.15.45 1.2.4 2.1 1.2.15.1 1.7 2.05 2 1.85 4.35 1.85.85 0 2.55-.8.35-.3.75-.65.9-1.35 4.25-4.75 4.45-4.6 5.8-5.5 4.75-3.1 8.35.95.45.3.85.7.7.65 2.05 1.05.75.1 1.55.1 1.6 0 3-.45 2-.7 3.6-2.3 2.75-2.75 2.75-6.6M67.1 22.3h-.05l.95-.35-.9.35M1.2 34.55l.05-.05v.05H1.2z"
                    id="ObjMus034__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M0 .5l4.05 12.95 1.75-.65-2.5-7.75.05-.05L1.6 0 0 .5z"
                    id="ObjMus034__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill="#999"
                    d="M127.65 308.65l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-.705.268-1.45.45.073.096.15.15.208.092.4 0l178-60.9q.202-.04.3-.25.092-.158 0-.35-.04-.202-.25-.3-.158-.092-.35-.05L129 307.7q-.536.443-1.1.8l-.1.05q-.05.05-.15.1m6-6.3q-.355.664-.8 1.3L293.7 249.7q.202-.04.3-.25.092-.157 0-.35-.04-.202-.25-.3-.158-.092-.35-.05l-159.75 53.6m1.25-3.6l-.05.25 149.4-48.9q.201-.04.3-.25.092-.157 0-.35-.04-.201-.25-.3-.157-.092-.35-.05L137.4 297.1q-.985.967-2.1 1.5l-.3.1q-.05 0-.1.05m4.7-5.8q-.103.621-.3 1.15l135.9-43.9q.2-.04.3-.25.092-.157 0-.35-.04-.2-.25-.3-.157-.092-.35-.05l-135.3 43.7z"
                    id="ObjMus034__Layer4_3_FILL"
                />
                <path
                    id="ObjMus034__Layer4_0_1_STROKES"
                    stroke="#369"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M196.5 272.55l.75-.25"
                />
            </defs>
            <use
                xlinkHref="#ObjMus034__Symbol_118_0_Layer0_0_FILL"
                transform="translate(92.25 247.65)"
                id="ObjMus034__col1n"
            />
            <use
                xlinkHref="#ObjMus034__Symbol_44_0_Layer0_0_FILL"
                transform="translate(183.25 241.95)"
                id="ObjMus034__col2n"
            />
            <use
                xlinkHref="#ObjMus034__Symbol_10_0_Layer0_0_FILL"
                transform="translate(259.4 251.05)"
                id="ObjMus034__col2l"
            />
            <g>
                <use xlinkHref="#ObjMus034__Layer4_0_FILL" />
                <use xlinkHref="#ObjMus034__Layer4_0_1_STROKES" />
            </g>
            <use xlinkHref="#ObjMus034__Layer4_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMus034__Layer4_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMus034__Layer4_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjMus034__Layer4_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjMus034__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus034__Layer4_2_FILL" />
            <use xlinkHref="#ObjMus034__Layer4_3_FILL" />
        </g></g>
    )
}

export default ObjMus034
