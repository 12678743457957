import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccMas004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M165.3 47.55q11.05-11.75 17.5-26.5.65-1.45 1.2-2.8 2.45-5.8 3.2-9.4.95-4.45 1.15-5.4.25-.95.45-3.4-14.75 21-40.85 30.8-.05 0-.15.05l-.8.3q-.5.15-1 .35-14.45 4.55-30.4.6-5.1-1.95-10.15-4.9v.05l-1.6-1q-.35-.2-.85-.5-.2-.15-.8-.5-3.9-2.1-7.55-2.3h-.4q-3.65.2-7.55 2.3-.6.35-.8.5-.5.3-.85.5l-1.6 1v-.05q-5.05 2.95-10.15 4.9-15.95 3.95-30.4-.6-.5-.2-1-.35l-.8-.3q-.1-.05-.15-.05Q14.85 21.05 0 0q.3 2.5.55 3.45.2.95 1.15 5.4.55 2.45 1.85 6 1.05 2.75 2.55 6.2 6.45 14.75 17.5 26.5 6.85 7.55 14.7 13.3Q60.95 77.2 88.7 78.6q2.75.15 5.55.15h.4q2.8 0 5.55-.15 27.75-1.4 50.4-17.75 7.85-5.75 14.7-13.3z"
                    id="AccMas004__Symbol_89_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas004__Symbol_89_0_Layer0_0_FILL"
                transform="translate(98.05 170.95)"
                id="AccMas004__col1n"
            />
        </g></g>
    )
}

export default AccMas004
