import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#621F00"
}

function AccMor018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.65 0q-2.75.7-4.85 1.15-12.65 2.9-25.35 4.6-.1 0-.2.05-33.55 4.45-67.3.5l-.5-.1q-.15 0-.55-.05h-.2q-.5-.1-1-.15Q18.9 4.3 5.1 1.15 3 .7.25 0L0 4.25q3 .7 5.1 1.15 61.75 14 122.7 0 2-.4 4.8-1.1.15-.05.35-.1l-.3-4.2z"
                    id="AccMor018__Symbol_116_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor018__Symbol_116_0_Layer0_0_FILL"
                transform="translate(126 305.55)"
                id="AccMor018__col1n"
            />
        </g></g>
    )
}

export default AccMor018
