import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF"
}
function BodTai013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M117.85 19.3q.1-1.1.2-2.15.1-1.25.2-2.35.05-.5.1-.9-.05.05-.1.15V12.4L59.95 0q-.15.4-.25.75-.15.35-.25.65-.5 1.4-1.05 4-.5 2.05-1.05 4.85-.5 2.45-1.05 5.5-1.95 10.75-2.9 15.2-.95 4.45-5.3 13.55-2.7 4.75-7.75 9.7-5.15 4.95-13.7 7.65Q14.95 65.7 0 66.05q4.65 8 12.1 13.25 9.95 6.95 24.95 8.95 1.8.25 3.65.4 14.45.9 30.6-4 .5-.2.95-.35l-.3-.1q-4.15-1.25-5.85-1.7l-.4-.1-.25-.25q1.05-.3 2.1-.55 23.2-6.6 33.8-20.05 11-14.05 15.35-33.45.6-2.6.95-6.45.1-.9.15-1.8.05-.3.05-.55z"
                    id="BodTai013__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M105.55 7.9q.1-.9.15-1.8.05-.3.05-.55.1-1.1.2-2.15.1-1.25.2-2.35.05-.5.1-.9-.05.05-.1.15-.25-.75-1.95.45-1.7 1.15-2.75 5.95-.3 2.5-.7 4.4-.05.4-.15.75-4.35 19.4-15.35 33.45-10.85 13.75-34.9 20.35-.5.1-1 .25l.05.05Q46.25 67 42.85 67.9l.25.25q1.5.4 5.85 1.7l.4.1q.05 0 .1.05-13 3.15-24.85 2.4Q10.1 71.15 0 65.55q9.95 6.95 24.95 8.95 1.8.25 3.65.4 14.45.9 30.6-4 .5-.2.95-.35l-.3-.1Q55.7 69.2 54 68.75l-.4-.1-.25-.25q1.05-.3 2.1-.55 23.2-6.6 33.8-20.05 11-14.05 15.35-33.45.6-2.6.95-6.45z"
                    id="BodTai013__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M44.6 56.55v.1q.014-.026 0-.05v-.05m11.65-9.85q1.837-2.12 2.15-2.55.35-3.55.85-6.3.3-1.95.7-3.5Q64.25 16.6 80 19.15q-3.65-8.9-10.7-13.85-2.3-1.6-4.9-2.75l-1-.4q-13.6-4.3-27.6 0Q24.05 5.3 14.7 14.6q-.2.3.45-2.85.65-3.2 1.9-4.45-3.35 2.4-6.25 5.6-2.75 2.9-5.15 6.4-1.3 1.9-2.45 4-.75 1.3-1.4 2.75-1 2-1.8 4.2.25 2.1.75 4.65.55 2.95 2 9.15 1.05-2.55 3.1-4.95 2.05-2.45 2.4-3.3-.55 5.6 2.5 13.45.85-1.7 2.1-4.05 1.25-2.35 2.25-3.4-.2.85 1.55 5.3 1.55 4.05 2.55 8.55 2.4-3.4 4.7-7.85 2.2 3.1 2.65 6.4.4 3.25.15 5 5.8-6.1 7.4-11.65l.1-.15q2.55 3.6 2.7 9.85 1.8-2.5 3.65-5.6 1.85-3.1 3.05-6 2.04 5.583 1.5 10.2.539-.85 1.5-2.1.25-.3.8-.95.6-.7 1.9-2.8 1.3-2.1 2.05-3.8.7-1.75.75-1.9.2 2.4 1 6.85v.25q.15-.2.4-.55-.003-.055.45-1.05.51-.935 2.3-3.1M8 34.05h-.05v-.15q.002.102.05.15z"
                    id="BodTai013__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.45 31.3q.4-1.05 2.25-3.15 1.8-2.15 2.2-2.5.35-3.55.85-6.3.3-1.95.7-3.5Q10.75-1.9 26.5.65 7-3.15 2.7 14.6q-.4 1.55-.7 3.5-.45 2.35-2 14.25 0-.05.45-1.05z"
                    id="BodTai013__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai013__Symbol_42_0_Layer0_0_FILL"
                transform="translate(219.3 228.6)"
                id="BodTai013__col1n"
            />
            <use
                xlinkHref="#BodTai013__Symbol_41_0_Layer0_0_FILL"
                transform="translate(231.4 242.35)"
                id="BodTai013__col1d"
            />
            <use
                xlinkHref="#BodTai013__Symbol_37_0_Layer0_0_FILL"
                transform="translate(279.25 198.35)"
                id="BodTai013__col2n"
            />
            <use
                xlinkHref="#BodTai013__Symbol_40_0_Layer0_0_FILL"
                transform="translate(332.75 216.85)"
                id="BodTai013__col2d"
            />
        </g></g>
    )
}
export default BodTai013
