import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}

function LegSho013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.4 3.2Q58.2 1.55 51.2.75 36.9-.7 23.8.75 9.1 2.5 0 8h73.7V5.25L65.4 3.2z"
                    id="LegSho013__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M73.2 7.7q-9-5.25-23.3-6.95Q36.8-.7 22.5.75q-7 .8-14.2 2.45L0 5.25V8h73.7l-.5-.3z"
                    id="LegSho013__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho013__Symbol_29_0_Layer0_0_FILL"
                transform="translate(118.8 326)"
                id="LegSho013__col1n"
            />
            <use
                xlinkHref="#LegSho013__Symbol_30_0_Layer0_0_FILL"
                transform="translate(192.5 326)"
                id="LegSho013__col2n"
            />
        </g></g>
    )
}

export default LegSho013
