import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48.15 3.75q.3-.45.1-.65-.1-.35-.4-.35-13.95-4.65-24.9-1.4Q14.65 3.6 7.9 10.5 3.75 14.85.15 21q-.2.2-.1.5.1.2.1.45h.3q.1 0 .1.1.1-.1.1-.2l.1-.15q.85-1.15 3.55-4.55 4.25-5.2 8.2-8.25 3.9-3.1 11.9-4.65 8.1-1.5 12.1-.85 4.15.65 4.9.75 5.2 1.65 5.8.9.75-1 .95-1.3M116.1 21.5q.1-.3-.1-.5-3.6-6.15-7.75-10.5-6.75-6.9-15.05-9.15-10.95-3.25-24.9 1.4-.3 0-.4.35-.2.2.1.65.2.3.95 1.3.6.75 5.8-.9.75-.1 4.9-.75 4-.65 12.1.85 8 1.55 11.9 4.65 3.95 3.05 8.2 8.25 2.7 3.4 3.55 4.55l.1.15q0 .1.1.2 0-.1.1-.1h.3q0-.25.1-.45z"
                    id="FacBro014__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro014__Symbol_14_0_Layer0_0_FILL"
                transform="translate(134.45 119.1)"
                id="FacBro014__col1n"
            />
        </g></g>
    )
}

export default FacBro014
