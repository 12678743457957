import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#AC8268",
    "col1n": "#C0A594",
    "col1s": "#794D32",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function ObjMor011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M55.45 34.9l-2.05.85 8.45 33.95-11.4-.4L39.25.75 33.05 0 30.8.85 42.2 68.9l-19.85-.7-3.5-36.35-6-.55-1.95.85L22.35 179.6 0 317.8l3.05 1.5 6.05-.45 12.65-79.55 4.2-.25 5.95 93.9 3.6 2h.05l6.65-.65-3.95-53.6 71.75-4.3 11.1 48.4 3.35 1.75 6.8-.7-21.6-90.35 9.85-.45 7.2-2.25-1.05-6.2-5.95.25-.6-3.05-11.4.35L61.65 35.4l-6.2-.5M23.1 78.4l17.35.25.75 5.2h-4l-5.15 31.9-3.8 6.1 1.25 3.35-.2.3h1.2l-2.65 16.55L23.1 78.4m28.85.45l12.2.2 34.1 145.45-22.85.85-23.45-146.5m-8.4-1.75V77h.2l-.2.1m-2.5 48.4l5.4-7.4L62.8 225.85l-28.65.95-3.55-46.95 8.95-54.2-.4-.15h1.9m4.15-15.8l-4.05 6.2 1.05-5.95 2.4-3.95.6 3.7m55.2 126.05l6.75 30.1-28.25 1.75-4.75-30.6 26.25-1.25m-74.25-8.6l-2.65.05-.2.15 1.95-12.25.9 12.05m43.8 40.95l-32.6 1.8-2.3-31.2 30.45-1.3 4.45 30.7z"
                    id="ObjMor011__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M88.25 199.8l2.25-1.65-74.1 4.65.1 1.3 71.75-4.3m-78.4-39.45L0 162.7l97.75-4.25 7.2-2.25-95.1 4.15m8.3-113.25l-.35 1.8h1.5l5.4-7.4-1.25-8.4-1.05 1.65.8 5.2-5.05 7.15M21.8.5V.4L1.25 0l.1 1.8 17.35.25L21.8.5m-.25 20.6l-1.05 1.8.75 4.95L7.55 48.9h3.15l12.15-19.5-1.3-8.3M42.4 2.45L44 .85 29.9.55l.3 1.7 12.2.2z"
                    id="ObjMor011__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M34.2 156.1q.75-.45 1.4-1.8l5.2-.2-.2-2.1 2.65-1.2 93.9-3.8.4 2.8q7.2 1.15 14.4-.7l-28-146.5L0 0q7.7 77.5 20.3 154.95l12.6 1.15h1.3z"
                    id="ObjMor011__Symbol_25_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M3.4 0L0 2.05l19.5 154.8h.1l4.1-1.9L3.4 0z"
                    id="ObjMor011__Symbol_5_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M0 7.75L19.35 7l5.3-1.55v-.1L24.6 5l-5.2.2q-1.15.05-2.8.15l-12.5.5L0 7.75m121.55-5.7l9.5-.45 4.7-1.6-14.4.7.2 1.35z"
                    id="ObjMor011__Symbol_2_0_Layer0_0_FILL"
                />
                <g
                    id="ObjMor011__Symbol_67_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M28.35 239.05h-2.4l5.95 93.9 3.6 2h.05l-7.2-95.9m39.55-1.75l-2.4.1 4.45 30.7 2.7-.15-4.75-30.65m44.35 37.45L110 276.4l11.1 48.4 3.35 1.75-12.2-51.8m-9.2-39h-2.65l6.75 30.1h3l-7.1-30.1m-37.3-158.3l-1.6 1.6 34.1 145.45 2.2-.05-34.7-147M33.05 0L30.8.85 43.55 77h.2l-3.3 1.65.75 5.2 2.1 13.85-8.75 14.05 4.25-27.9h-1.6l-5.15 31.9-3.8 6.1 1.25 3.35-.2.3h1.2l-2.65 16.55.65 9.15 3.95-25.7L44.6 106l.6 3.7-4.05 6.2-1.6 9.6h1.5l5.4-7.4L62.8 225.85l7.1-.25L33.05 0M23.3 227.35L21 228.5l.75 10.8 9.85-2.35-.5-6.15-1.9.75.25 2.6v.1l-5.1 1.5v-.05l-.85-8.5-.2.15M12.85 31.3l-1.95.85L22.35 179.6 0 317.8l3.05 1.5h.05l19.95-131.6 2.2 27.4.9 12.05 1.4-.05-14.7-195.8m42.6 3.6l-2.05.85 8.45 33.95 2.1.1-8.5-34.9z" />
                    <path d="M43.3 97.7l-2.1-13.85h-2.4l-4.25 27.9L43.3 97.7M21 228.5l2.3-1.15 1.95-12.25-2.2-27.4L3.1 319.3l6-.45 12.65-79.55-.75-10.8z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor011__Symbol_121_0_Layer0_0_FILL"
                transform="translate(18.85 3.8)"
                id="ObjMor011__col1n"
            />
            <use
                xlinkHref="#ObjMor011__Symbol_67_0_Layer0_0_FILL"
                transform="translate(18.85 3.8)"
                id="ObjMor011__col1d"
            />
            <use
                xlinkHref="#ObjMor011__Symbol_52_0_Layer0_0_FILL"
                transform="translate(40.6 80.4)"
                id="ObjMor011__col1s"
            />
            <use
                xlinkHref="#ObjMor011__Symbol_25_0_Layer0_0_FILL"
                transform="translate(7.45 83.5)"
                id="ObjMor011__col2n"
            />
            <use
                xlinkHref="#ObjMor011__Symbol_5_0_Layer0_0_FILL"
                transform="translate(4.05 83.5)"
                id="ObjMor011__col2d"
            />
            <use
                xlinkHref="#ObjMor011__Symbol_2_0_Layer0_0_FILL"
                transform="translate(23.65 232.6)"
                id="ObjMor011__col2s"
            />
        </g></g>
    )
}

export default ObjMor011
