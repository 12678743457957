import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00"
}

function ObjMor072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 94l37.25-5.4L3.5 152.15 76.8 58.6l-38.3 6.55L62.95 0 0 94z"
                    id="ObjMor072__Symbol_163_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M54.05 0l-36.9 73.45 32.6-5.3-49.75 76L68.6 56.6l-38.3 6.55L54.05 0z"
                    id="ObjMor072__Symbol_91_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor072__Symbol_163_0_Layer0_0_FILL"
                transform="translate(299.05 4.95)"
                id="ObjMor072__col1n"
            />
            <use
                xlinkHref="#ObjMor072__Symbol_91_0_Layer0_0_FILL"
                transform="translate(307.25 6.95)"
                id="ObjMor072__col1d"
            />
        </g></g>
    )
}

export default ObjMor072
