import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function SkiMak021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M114.35 6.35q-2.35-3.75-6.9-5h.1q-4.05-.75-7.6.8h-.05q-2.05.8-4.4 2.25-.15.1-.35.2-.2.15-.4.25-14.9 8.7-35.95 8.7-13.85 0-25.05-3.8h-.05q-8.2-3.25-15-7.9-.5-.35-.9-.6-.7-.3-1.25-.6h-.05L16.45.6q-4.1-1.25-8.2.05Q3.85 2.4 2.1 6.5q-.05 0-.05.05-4.65 9.9 1.25 18.05l.2.3q4.3 5.75 13.05 10.6 17.55 9.55 42.3 9.55 24.75 0 42.3-9.55 8.8-4.85 13.1-10.65 0-.05.05-.05h.05q5.1-9.75 0-18.45z"
                    id="SkiMak021__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M116.2 7v-.05q-2.7-4.15-7.95-5.6-.05-.05-.1 0-4.5-.85-8.55.85-2.25.85-4.8 2.35-.097.048-.15.15l.05-.1q-.1.05-.15.1-.1.05-.2.15-.1 0-.15.05-14.25 8-34.35 8-13.3 0-24.05-3.5-7.85-3-14.4-7.35L21.35 2q-.45-.25-.8-.5-.05-.05-.1-.05-.8-.35-1.4-.65-.1-.05-.15-.05-4.7-1.45-9.35 0L9.5.8Q4.3 2.7 2.25 7.35v.05q-5.1 10.5 1.4 19.25l.1.1.1.1v.05q4.45 5.8 13.5 10.65 17.6 9.35 42.55 9.35 24.95 0 42.55-9.35 9.1-4.85 13.55-10.7.15-.15.25-.35v.05q5.55-10.3-.05-19.55m-8.4-3.7h-.1q4.5 1.2 6.8 4.75 5 8.3 0 17.55h-.05q-.05 0-.05.05-4.25 5.55-12.9 10.15-17.25 9.1-41.6 9.1t-41.6-9.1Q9.7 31.2 5.45 25.7q-.1-.15-.2-.25-5.8-7.8-1.2-17.2 0-.05.05-.05 1.7-3.9 6.05-5.55 4-1.25 8.05-.05l.05.05h.05q.55.25 1.2.55.4.25.9.55 6.7 4.45 14.75 7.55h.05q11.05 3.6 24.65 3.6 20.7 0 35.35-8.25.2-.1.4-.25.2-.1.35-.2 2.3-1.4 4.35-2.15h.05q3.5-1.45 7.45-.75z"
                    id="SkiMak021__Symbol_29_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak021__Symbol_27_0_Layer0_0_FILL"
                transform="translate(134.05 197.85)"
                id="SkiMak021__col1n"
            />
            <use
                xlinkHref="#SkiMak021__Symbol_29_0_Layer0_0_FILL"
                transform="translate(133 197)"
                id="SkiMak021__col2n"
            />
        </g></g>
    )
}

export default SkiMak021
