import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.85 4.1q-.8-.3-1.55-.6-12.65-6.35-19.35 0Q42.4 4.95 42 8q-1-3.3-5.45-6.7-4.5-3.45-18.35 2.2-4.55 1.65-9.05 4-6.1 2.9-8.3 8.35-4.25 23.4 12.5 38.95-.2-9.05-2.6-19.05-.1-.1-.15-.2l.15-.05Q7.8 24.45 13 18.6q.2-.1.45-.2 8.15-3.8 17.2-4.05 3.1-.3 5.95-.45v.05q1.1-.05 2.3-.1 1.15-.05 2.35-.05 1.1 0 2.25.05 1.2.05 2.35.1v-.05q2.85.15 5.95.45 8.45.25 15.9 3.65 7 6.3 2.6 18.7l.05.05-.05.05q-2.6 9.95-2.85 18 19.5-21.1 11.45-41.95Q76.5 9 73.3 7.5q-3.75-1.95-7.45-3.4z"
                    id="BeaMus035__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M158 225.1q.35 3.9 3.65 13 3.25 9.05 3.6 10.2-.2-8.95-2.6-18.85-.1-.1-.15-.2l.15-.05q-2.95-11.05 2.25-16.9.2-.1.45-.2 8.15-3.8 17.2-4.05 3.1-.3 5.95-.45v.05q1.1-.05 2.3-.1 1.15-.05 2.35-.05 1.1 0 2.25.05 1.2.05 2.35.1v-.05q2.85.15 5.95.45 8.45.25 15.9 3.65 7 6.3 2.6 18.7l.05.05-.05.05q-2 7.7-2.65 13.65-.7 5.9.1 3.65.8-2.35 2.4-7 1.55-4.7 2.4-7.45.8-2.8 1.15-4.25.3-1.45.5-3.95.2-2.55.2-5.15 0-.85-.05-1.7-1.45-9.65-9.4-11.4-.25-.1-.4-.2-22-3.9-44.4 0-2.85.6-3.85.8-8.45 2.05-10.2 12.05-.35 1.65 0 5.55z"
                    id="BeaMus035__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus035__Symbol_52_0_Layer0_0_FILL"
                transform="translate(151.9 193.7)"
                id="BeaMus035__col1n"
            />
            <use
                xlinkHref="#BeaMus035__Symbol_14_0_Layer0_0_FILL"
                id="BeaMus035__col1d"
            />
        </g></g>
    )
}

export default BeaMus035
