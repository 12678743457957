import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function FacEar001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.15 6.65V5.5q0-2.8.15-5.5-.4.05-.75.15-5.5 1.2-9 6.1-4.2 5.7-3.4 12.9.9 7.2 6.2 11.7 4.95 4.3 11.1 3.9-1-3.15-1.75-6.35Q13.25 18 13.15 6.65m200.8-.4Q210.2 1 204.2 0q.05.9.05 1.85V6.6q-.1 11.4-2.55 21.85-.75 3.2-1.7 6.3 6.2.4 11.15-3.9 5.3-4.5 6.2-11.7.8-7.2-3.4-12.9z"
                    id="FacEar001__Symbol_3_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M93.55 157.9q-3.05 3.85-2.45 8.75.65 4.85 4.45 7.9 1.8 1.5 3.9 2.15-2.45-10.4-2.55-21.75-1.9 1.05-3.35 2.95m197.9 0q-1.45-1.95-3.45-3-.1 11.4-2.55 21.85 2.15-.7 4-2.2 3.8-3.05 4.45-7.9.6-4.9-2.45-8.75z"
                    id="FacEar001__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacEar001__col1n" transform="translate(83.75 148.3)">
                <use
                    xlinkHref="#FacEar001__Symbol_3_0_Layer0_0_FILL"
                    id="FacEar001__col1n_FL"
                />
            </g>
            <use
                xlinkHref="#FacEar001__Symbol_10_0_Layer0_0_FILL"
                id="FacEar001__col1d"
            />
        </g></g>
    )
}

export default FacEar001
