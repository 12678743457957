import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.85 16.15q1.05-1-.05-2.35-.35-.25-.85-.25h-.15q.6.15.6 1.15 0 .5-.2.8-.25.3-.75.3-.95 0-1.35-.75-.3-.35-.55-.85-.95-1.85-2.85-1.95-.95-.1-2.65.1-2.3.25-2.9-.85-.6-1.15-.85-1.55-2.1-3.1-6.65-3.7-.75-.1-1.7-.1-3.45 0-4.25 1.5-1.35-4.15-.4-5.75.05-.2.15-.3.1-.1.25-.2.4-.3.9-.3.55 0 1 .4l.05.05q.05 0 .05.05.45.5.45 1.25T22.7 4.1q-.15.2-.35.3h.05q.75-.2 1.15-.75.25-.35.25-1.45 0-.4-.05-.65l-.1-.2-.2-.4Q21.85-.9 19 .95 17.45 2.3 17.75 5.2q.4 3.85.95 5.3 1.05 5.85-.55 7.1-1.1.7-3.15.8-2.1-.1-2.4-2.65-.5-2.3 1-5-2.5 2.35-2.45 4.45.05 2.1 1.05 3.45-5.35.95-4.3 7.8.1.75.3 1.7.05.35.1.75.55 3.25.7 5.5h-.15q-5.5.4-7 5.25Q1 42 2.1 44.25q.25.65.4.9.2.3.4 1.5.15 1.15-1.1 1.4-1.15.2-1.3-2.5-.6.95-.5 1.5v.2q.2.8.65 1.2 1.55 1.25 3 0l.05-.05q.5-.45.8-1.35.3-1.5-.8-4.1-.25-.55-.25-1-.05-.1-.05-.2-.3-2.95 3-4.4 1.7-.75 2.65-.5-.1 1.8-.35 2.55-1 2.7-4.35 2.9.25.25 1.05.3h.3q4.05-.1 5.45-3.1.35-1.15.5-2.7 1.05.05 1.8-.55.7-.65 1.1-2.15.35-1.5 1.35-1.55 1-.1.8 1.4-.2 1.5-1.4 2.05l.9-.2q1.3-.55 1.45-2.2v-.15q-.05-2.4-1.85-2.15-1.85.25-2.15 1.6-.35 1.35-1.95 1.45V28.8v-.25q-.2-1.45.05-2.5V26q.2-.85.7-1.5 1.15-1.5 2.9-1.15 1.9.75 1.95 3.35v.05q0 .65-.45 1.1-.45.45-1.1.45-.65 0-1.1-.45-.45-.45-.45-1.1 0-.4.2-.75.05-.2.2-.4l.2-.2q.05 0 .05-.05l-.05-.05q-.3.1-.5.25-.6.45-.6 1.5 0 .2.05.4.05.45.15.8 1.05 2.05 3.1 1.05l.15-.15q.3-.25.55-.55 1.05-1.2 1.05-3 0-1.65-.95-2.8.45-.05 1.6-.3 1.75-.35 2.8-1.6.45-.55.7-1.2 1.2-3.75-.3-7.75-.6-1.4-.3-2.8.85-1.4 2.45-1.3h2.05q3.15.05 3.45 1.6.3 1.55-1.35 1.6-1.7.05-2.9-2.45.1.6.2 1.1.4 1.9 2.35 2.45 1.1.2 2.1-.5.7-.8.75-2 .45.2.55.3.05.05.55.8t.6 1.55q.1.8.1 1.25l.1 1.45q.05.35.4.95.35.55.85.85l.1.1q1.4 1 2.55.05.55-.7 0-1.8-.05-.15-.15-.3H37q.1.95-.05 1.3-.15.35-.95.4-.85.05-1.35-.7-.7-.8-.6-1.45v-.9q1.7.6 2.85.35 1.1-.3 2.4-.4 1.1-.15 2.3 1.4.55.85 1.15 1.3 1.8 1.3 3.1 0z"
                    id="SkiTat009__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat009__Symbol_9_0_Layer0_0_FILL"
                transform="translate(113 233)"
                id="SkiTat009__col1n"
            />
        </g></g>
    )
}

export default SkiTat009
