import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col1s": "#121212",
    "col2n": "#FFFFFF"
}

function HaiLon011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M159.9 177.15l.2-.3q-.1.05-.15.2-.05 0-.05.1M119.75 1.65h-.05v.1q.002-.052.05-.1m71.8 41.05q-4.6-9.6-10.95-18.1-1.4-1.9-2.85-3.7-.45-.55-.9-1.05-2.65-2.9-5.7-5.35Q158.9 4.85 139.35 3l-2-.15q-3-.2-6-.25-15-.45-30 1.7h-.15q-.25-.45-.55-.9l-.1-.1Q97.35-.85 89.4.25q-11.95 1.5-23.9 5.8-19.75 6.75-35.6 19.5-5.5 4.9-9.95 10.25-6.6 8.7-11.05 19.15Q.8 77.75.7 101.3L.05 202.85l-.05.6v27.45q3.35-1.9 8.1-6.9 4.66-4.906 4.7-6.5v.25q1.7 1.75 1 9.55 2.3-.85 5.75-4.25.45-2.05 1-4.05.75-2.65 1.6-5.3l1.3-3.8.4-1.2.1-.2q.2-.5.35-1 .85-2.2 1.75-4.4l.1-.2v-.05q.3-1 .45-1.45.2-.5.45-.95.15-.5.35-.95.5-1.15 1.05-2.25.05-.15.1-.25.05-.15.1-.25l.3-.7.65-1.25q.75-1.55 1.5-3l1.1-2q1.3-2.3 2.7-4.5.15-.25.3-.45.3-.5.75-1.15.8-1.25 2-3.05.85-1.3 1.95-2.9-.4-.3-.75-.6-1.95-1.65-3.85-3.4-1.65-1.5-3.25-3.1Q6.3 144.9 4.2 109.4v-.45l2.75-.65.4-.1q8.3-2.5 15.45-6 2.6-1.3 5.05-2.7 2.25-1.3 4.35-2.7Q56 80.9 62.85 50.5q0-.15.05-.25.35.45.75.9 24.5 29.45 54.5 26.2.4-.05.75-.1-.35-.1-.7-.25-33.8-12-41.1-35.35v-.05q-.1-.2-.15-.4l.25.25q.6.5 1.2 1.05 42.8 37.95 114.25 54.9l2.85.35v.55q.05.6.05 1.2.05 1.7.05 3.4 0 2.35-.1 4.7-.5 11.85-3.6 22.65-4.2 14.55-13.1 27.15l-1.8 2.45q-.75 1-1.55 2.05-3.4 4.15-7.25 8.15-.35.3-.65.6-1.15 1.15-2.3 2.25-.15.1-.25.25-.35.3-.65.6-.9.8-1.75 1.6-.95.8-1.9 1.65-.25.15-.5.4-.15.1-.3.25-.05 0-.05.05.05.05.1.2.05 0 .1.1.15.25.35.55.4.6.75 1.2.2.25.4.55 1.95 2.95 2.95 4.55l.1.1q.1.15.2.35 1.4 2.2 2.7 4.5l1.1 2q.35.65.65 1.3.45.85.85 1.7l.65 1.25.3.7q.05.1.1.25.05.1.15.25.5 1.1 1 2.25l.3.7.5 1.2q0 .05.05.15.15.4.4 1.3v.05l.1.2q.9 2.2 1.75 4.4.15.5.35 1l.1.2.4 1.2 1.3 3.8q.85 2.65 1.6 5.3 0 .1.05.2l.9 3.4v.15q.15 0 .15.5h.25q.25-.5.55-1 .05-.05.05-.1 2.8-5.2 4.85-10.4 4.25 12.6 17.45 18.9V92.55q-.5-11.2-2.5-21.5-1-4.95-2.35-9.7-.1-.45-.25-.9-2.7-9.3-6.75-17.75m-6 16l.05-.05-.05.1v-.05z"
                    id="HaiLon011__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M31.1 171.25q-1.65-1.5-3.25-3.1Q2.1 142.4 0 106.9v83.65q2.5-2.65 5.55-6.45 3.65-4.6 4.8-6.7h.2q1.25 2.9 1.75 11.05.45 6.6.25 13.55 2.65-5.65 4.1-9.4 2.65-6.9 2.3-11.4.2 0 .2-.2 2.3 7.35 3.7 16.95.15-.5.35-.95.5-1.15 1.05-2.25.05-.15.1-.25.05-.15.1-.25l.3-.7.65-1.25q.75-1.55 1.5-3l1.1-2q1.3-2.3 2.7-4.5.15-.25.3-.45.3-.5.75-1.15.8-1.25 2-3.05.85-1.3 1.95-2.9-.4-.3-.75-.6-1.95-1.65-3.85-3.4M58.65 48l-2.7-2.25Q49.45 75.15 28 94.3 51.8 78.4 58.65 48m28.9 16.3q9 6.8 26.4 10.55.4-.05.75-.1-.35-.1-.7-.25-33.8-12-41.1-35.35-1.25-1-3.2-2.85-.25-.2-.45-.45.65 8.65 5 15.15 4.3 6.45 13.3 13.3m103.75 40.8q-.5 11.85-3.6 22.65-4.2 14.55-13.1 27.15l-1.8 2.45q-.75 1-1.55 2.05-3.4 4.15-7.25 8.15-.35.3-.65.6-1.15 1.15-2.3 2.25-.15.1-.25.25-.35.3-.65.6-.9.8-1.75 1.6-.95.8-1.9 1.65-.25.15-.5.4-.15.1-.3.25-.05 0-.05.05.05.05.1.2.05 0 .1.1.15.25.35.55.4.6.75 1.2.2.25.4.55 1.95 2.95 2.95 4.55l.1.1q.1.15.2.35 1.4 2.2 2.7 4.5l1.1 2q.35.65.65 1.3.45.85.85 1.7l.65 1.25.3.7q.05.1.1.25.05.1.15.25.5 1.1 1 2.25l.3.7.5 1.2q0 .05.05.15 4.95-12.3 5-21.65 0 .35 1.4 10.3 1.4 10 1.4 12.7l.2-.2q2.2-5 4.2-14.2 2.35-10.8 2.2-18.4 4.85 15.5 7.95 19.1v-81.6m-3.95-64.9q-4.6-9.6-10.95-18.1-1.4-1.9-2.85-3.7-.45-.55-.9-1.05-2.65-2.9-5.7-5.35Q154.7 2.35 135.15.5l-2-.15q-3-.2-6-.25-15-.45-30 1.7v1q0 1.75-.15 2.15-.1.3-1.85 3.25 33.8-5.95 57.3 3.8 6.35 2.65 11.95 6.4 19.2 12.95 29.5 39.45l.2.1q-2.7-9.3-6.75-17.75z"
                    id="HaiLon011__Symbol_40_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M18.2 39.05q-2.15 2.1-3.9 6.65-.95 2.4-2.1 5.75-2.7 5.25-6.4 9.75-3.35 4.2-5.8 5.5l.95.05q4.7 0 10.15-4 5.25-3.85 7.6-8.8 1-2.15 1.7-5.45.85-4.1 1.4-5.75 1.15-3.55 2.2-5.3.65-1.25 2.5-2.9l.4-.3q-.5.1-1.05.25-4.25 1.2-7.65 4.55m62.4-35.6q.9-1.85-1.6-3t-11.4.8Q73-.5 62.9 2.6q-8.95 2.75-10.3 3.45-9.85 4.55-11.35 5.3-5.45 2.6-9.65 5.1l-.2.2q7.4-4.3 22.6-8.8 14.1-4.2 19.1-4.2 1.4 0 2.7 1 1.35 1.05 1.4 2.5.7-.35 1.6-1.1.9-.75 1.8-2.6m25.65 41.85q-3.6-4.25-6.45-6.25Q95 35.6 88.55 33.8q-6.45-1.85-17.2-2.2-.3-.05-.5-.05 1.25.35 2.9.8Q79.7 34 86 37.8q6.25 3.8 14.45 11.4 8.2 7.6 16.65 13.7 8.45 6.05 15.5 8.15l8.4 3.4q-15.5-7.65-18.9-11.4-3.45-3.75-7.85-8.65-4.45-4.9-8-9.1m37.65-24.7q-11.8-6.9-22.3-3.75 7.55 2.7 10.95 4.35 6.25 2.95 10.05 6.85 2.5 2.5 7.9 9.9 6.05 6.75 15.1 9.7-4.2-3.4-7.3-7.6-3.35-4.45-6.3-11-.85-1.9-5-5.75-1.8-1.7-3.1-2.7m-5.7 28.05q-1.55-2.1-6.35-8.75T120.4 30q-6.65-3.25-17.95-7.25 8.3 3.85 12.95 7.7 4.6 3.8 9.35 10.75t10.65 13.45q5.9 6.5 20.4 10.8-9.1-5.8-13.4-11.3-2.7-3.45-4.2-5.5z"
                    id="HaiLon011__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M42.15 19.95h7.05q-.608-.014 1.25-.15-3.6-1.15-8.25-.85-8.5-.8-11.6 9-.45 1.3-2 8.05-1.1 4.7-2.3 6.7Q19 54.25 4.05 56.95q-.2.05-2.2.25-2.05.15-1.8.35.25.2 2.15.35 1.85.1 2.05.05 15.35-2.8 22.9-14.7v-.05q1.25-2.1 2.4-7 1.5-6.65 2-7.9v-.05q2.85-9 10.6-8.3m138.4 36.45q.15.1 3.55 1.25 3.4 1.1.3-.25l-2.8-1.3q.25.1-.15-.15t-7.5-3.8q-7.1-3.55-16.25-11.55-4.45-3.9-17.05-17.95-5.45-6.6-13.05-11.55-7.7-5-11.1-6.45-3.4-1.5-15.15-4.15Q89.6-2.2 61.8 9.45q-.85.4-1.55.75l.1.1q.15.15.35.15h1.2q6.4-.1 18.1-1 14.5 0 22.35 6.6 2.15 1.8 7.5 7.55 5.45 5.85 10.05 9.75 11.9 10.1 19.05 14.85 11.55 7.65 19.8 8.3 8.2.6 9.8.5 1.6-.15-1.25-.55l-8.25-.8q-8.25-.8-19.55-8.3-7.1-4.7-18.95-14.75-4.55-3.85-9.95-9.7-5.4-5.85-7.6-7.6-8.1-6.85-23.05-6.85h-.05q-9.4.7-15.35.95 25.5-10.4 36.6-7.9 11.55 2.55 14.95 4.05 3.4 1.45 10.95 6.4 7.45 4.9 12.85 11.35 12.65 14.1 17.15 18.05 9.45 8.3 23.35 15 .1 0 .15.05z"
                    id="HaiLon011__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M166.7 36.6h-.05q-.15-.25-.3-.45l-.3-.35-.1-.1q-2.25-2.8-4.65-5.35l-2.4-2.5-9.2-7.95q-7.35-5.65-15.85-9.7-6.75-3.25-14.2-5.5-3.9-1.25-7.8-2.15-1.85-.4-3.7-.75L94.4.2Q92.5.1 90.6.15l-.85-.1q-.05 0-.05.05l-2-.1-.1.1q-4.4.1-8.75.6-1.6.2-3.15.45Q50.15 7.4 32.55 20.6q-8.7 6.5-15.4 14.65-.5.65-1 1.35-8.25 11.75-13.2 24Q1.4 64.4 0 67.1l6.25-9.45q.4-.85 9.6-15.8 9.1-15 28.65-18.6Q67.8-7.3 129.6 12.1q19.85 6.75 39.7 30.7 3.4 4.1 6.85 8.75l.05-.05q-1.2-2.25-2.45-4.4-.25-.35-.4-.65l-.5-1-.05-.05-1-1.7-.1-.05q-1.2-1.85-2.45-3.55l-2.55-3.5z"
                    id="HaiLon011__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiLon011__Symbol_70_0_Layer0_0_FILL"
                transform="translate(92.65 50.9)"
                id="HaiLon011__col1n"
            />
            <use
                xlinkHref="#HaiLon011__Symbol_40_0_Layer0_0_FILL"
                transform="translate(96.85 53.4)"
                id="HaiLon011__col1d"
            />
            <use
                xlinkHref="#HaiLon011__Symbol_19_0_Layer0_0_FILL"
                transform="translate(104.8 54.95)"
                id="HaiLon011__col1l"
            />
            <use
                xlinkHref="#HaiLon011__Symbol_11_0_Layer0_0_FILL"
                transform="translate(104.9 72.95)"
                id="HaiLon011__col1s"
            />
            <use
                xlinkHref="#HaiLon011__Symbol_8_0_Layer0_0_FILL"
                transform="translate(101.95 58.3)"
                id="HaiLon011__col2n"
            />
        </g></g>
    )
}

export default HaiLon011
