import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D2E1B",
    "col1n": "#794D32",
    "col2d": "#808080",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF",
    "col2s": "#747474"
}

function ObjToo007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M14.95 33.15q-.35-.25-.65-.55-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-3.55 1.35-6.45.45h-.15l-.4-.2-1.2 3.2q-.5 1.3.65 2.4l5.25 3.95q1.65 1.25 2.65-.95l1.4-3.7q3.05-8.15 4.85-16.5m1.4-28.35Q15.9 1.5 14.6.45q-.25-.15-.45-.3-.35-.1-.6-.15-1.85.35-2.4 2.75-.1.4-.15.9-.05 1.05-.15 2.05v.75q-.1 1.95-.35 3.9-.6 4.75-1.7 9.7-.4 1.75-.85 3.55 1.35.85 2.5 2.2 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4 1.15-1.15 2.55-1.7.1-1 .15-1.95l-.05-.05q.05-1.35.05-2.7h.05q.05-5.3-.7-10.6z"
                    id="ObjToo007__Symbol_167_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.7 45.15l-.8-.2h-.15l-.4-.2-1.2 3.2q-.5 1.3.65 2.4l1.35 1q-1.4-1.4-.75-2.95.65-1.6 1.3-3.25M11 3.65q-.05 1.05-.15 2.05v.75q-.1 1.95-.35 3.9-.6 4.75-1.7 9.7-.4 1.75-.85 3.55.65.4 1.25.95 1.6-6.45 2.65-13.05.05-.9.1-1.75 0-1.2-.05-2 .1-.7.1-1.15.05-1.25-.05-2.05.15-4 2.2-4.4-.35-.1-.6-.15-1.85.35-2.4 2.75-.1.4-.15.9z"
                    id="ObjToo007__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.35.15q-3 .7-6 .6-1.4 0-2.9-.25Q-.3.3.05 1.7L0 1.8q4.15 18.8 12.15 36.8.2.55.4 1 .45 1 1.7.55 2.85-.8 5.45.75 0 .05.05.05.35.15.8.5 2.25 1.6 2.25 3.35 0 1.35-.5 2.9-.5 1.55-.5 2.2 0 2.55 1.55 6.95.6 1.8 2.2 4 .05-.45.05-.9v-.75q.1-1 .15-2.05.05-.5.15-.9.55-2.4 2.4-2.75.25.05.6.15.2.15.45.3-.8-5.45-2.6-11v-.1h.05L12.5.7q-.9-.9-1.85-.65l-.3.1z"
                    id="ObjToo007__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M16.45 37.25Q9.55 19.45 4.35.3q-1.4 0-2.9-.25-1.75-.2-1.4 1.2l-.05.1q4.15 18.8 12.15 36.8l4.3-.9z"
                    id="ObjToo007__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M12.1 28.7h-.05q0 1.35-.05 2.7l.05.05q.55.45 1.25.9l.15.1v-.1q1 .4 1.25 0 .1-.05.1-.15.1-1.3-.3-2.6-1.4-.2-2.4-.9m-8-26.05h.05q-.1-.15-.15-.3-.3-.55-.6-1Q2.9.6 2.2 0L0 .7q3 3.65 1.15 6.95 1.1 8.9 4.4 16 .25-1.95.35-3.9V19l-.65-1.3Q3.6 13.75 3.3 9.35q.8-.8 1.05-3 .15-2.05-.25-3.7z"
                    id="ObjToo007__Symbol_1_0_Layer0_0_FILL"
                />
                <g id="ObjToo007__Symbol_8_0_Layer0_0_FILL" fill={colors.col2d}>
                    <path d="M21.15 68.9h-.05q0 1.35-.05 2.7l.05.05q.55.45 1.25.9l.15.1v-.1q1 .4 1.25 0 .1-.05.1-.15.1-1.3-.3-2.6-1.4-.2-2.4-.9zM16.1 42.95v-.1h.05L1.85.7Q.95-.2 0 .05l12.7 39.7-1.45.45q.5 5 .7 10.3.1 2.5.25 2.4-.3 3.75 2.1 6.3h.65q.1-1 .15-2.05.05-.5.15-.9.55-2.4 2.4-2.75.25.05.6.15.2.15.45.3-.8-5.45-2.6-11z" />
                    <path d="M11.25 40.2l-2.2.7q3 3.65 1.15 6.95 1.1 8.9 4.4 16 .25-1.95.35-3.9v-.75h-.65q-2.4-2.55-2.1-6.3-.15.1-.25-2.4-.2-5.3-.7-10.3z" />
                </g>
                <g id="ObjToo007__Layer5_0_FILL">
                    <path
                        fill="#D5AA00"
                        d="M116.4 313.05q.55.5 1.2.55.65 0 1.15-.45.5-.5.5-1.15 0-.75-.4-1.25-.5-.45-1.25-.45-.65-.1-1.1.35-.5.5-.6 1.2 0 .65.5 1.2m8.25-42.4q-.25.35-.3.7-.05.4.05.75.1.25.25.55 0 .05.05.05.4.55 1 .7h.15q.75.15 1.4-.25.1-.15.25-.3.35-.35.45-.95.1-.75-.3-1.3-.1-.2-.2-.3-.4-.35-1-.45h-.35q-.55 0-1 .3-.3.2-.45.5z"
                    />
                    <path
                        fill="#FFF"
                        fillOpacity={0.251}
                        d="M97.55 212.6l-.05.1q4.15 18.8 12.15 36.8l4.3-.9q-6.9-17.8-12.1-36.95-1.4 0-2.9-.25-1.75-.2-1.4 1.2z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo007__Symbol_167_0_Layer0_0_FILL"
                transform="translate(112.25 264.4)"
                id="ObjToo007__col1n"
            />
            <use
                xlinkHref="#ObjToo007__Symbol_118_0_Layer0_0_FILL"
                transform="translate(112.25 264.4)"
                id="ObjToo007__col1d"
            />
            <use
                xlinkHref="#ObjToo007__Symbol_54_0_Layer0_0_FILL"
                transform="translate(97.5 210.9)"
                id="ObjToo007__col2n"
            />
            <use
                xlinkHref="#ObjToo007__Symbol_28_0_Layer0_0_FILL"
                transform="translate(97.5 211.35)"
                id="ObjToo007__col2l"
            />
            <use
                xlinkHref="#ObjToo007__Symbol_8_0_Layer0_0_FILL"
                transform="translate(108.15 210.9)"
                id="ObjToo007__col2d"
            />
            <use
                xlinkHref="#ObjToo007__Symbol_1_0_Layer0_0_FILL"
                transform="translate(117.2 251.1)"
                id="ObjToo007__col2s"
            />
            <use xlinkHref="#ObjToo007__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo007
