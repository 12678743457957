import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#AC8268"
}

function ObjFoo030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0l1.45 11.1q2.05.15 4.25.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-1.6.6-3.05.75.05.25.15.45.2.65.85 1.3l.05.05q0 .05.05.1l.1.1q.95 1.1 2.4 2.05 4.4 2.95 10.6 2.95 6.2 0 10.6-2.95 1.8-1.2 2.9-2.6.25-.3.4-.55.15-.3.25-.6.05-.25.1-.45v-.2l.6-4.45h-.05L37.3.05 0 0z"
                    id="ObjFoo030__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M133.1 269.8v.05l.25-.05h-.25z"
                    id="ObjFoo030__Layer7_0_FILL"
                />
                <path
                    fill="#E1E1E1"
                    d="M144.25 312q-.088.056-.25.1-3.755 1.915-9 2.7-1.493.15-3.25.15-5.95 0-10.15-1.75-1.428-.563-2.4-1.2-.115-.099-.25-.2-.25-.15-.35-.35l.3 2.45q.7 1.75 3.6 3.05 3.7 1.65 9.1 1.65 5.35 0 9.15-1.65 3.35-1.5 3.65-3.6.05-.1.05-.2V312q.335-.36-.15-.05l-.05.05m-28.7-47.2v.05l-4.15 4.4q.017.152.05.25v3.55q.1.75.75 1.5.3.4.85.7l.7.5q1.4.75 3.5 1.45h.1q4 1.25 9.05 1.7 2.55.2 5.35.2h1.35q7.55-.15 13-1.9 2.2-.7 3.55-1.4.4-.2.7-.5 1.8-1.1 1.8-2.6V269l-4-4.1q-.47-.692-2-2.7l-.25-.35-12.1-3h-.05l-14.45 2.4-3.75 3.55m17.55 5h.25l-.25.05v-.05z"
                    id="ObjFoo030__Layer7_1_FILL"
                />
                <path
                    fill="#FFF"
                    d="M114.2 267.05q.5-.35 1.3-.75v-1.45q-1.7.8-2.65 1.65-1.45 1.15-1.45 2.6v.05q0 .2.05.35.1.8.65 1.55 1.05 1.25 3.4 2.25.35.2.7.35.25.1.45.15.3.05.6.2l.1.05q4 1.3 9.05 1.8 2.55.2 5.35.2h1.35q.65 0 1.25-.05 6.8-.3 11.75-1.95 1.15-.4 2.15-.85 2.15-.95 3.1-2.15.75-.95.8-1.85v-.2q0-.1-.05-.25-.05-.45-.25-.9-.8-1.6-3.6-2.9v1.5q1.5.7 1.85 1.7.15.35.15.75 0 .5-.25 1-.45.8-1.75 1.35-1.35.75-3.4 1.3-4.95 1.5-11.75 1.65h-1.4q-.85 0-1.6-.05-1.95-.05-3.7-.2-3.1-.2-5.8-.8-1-.25-2-.6-.7-.1-1.35-.35-.55-.25-1.05-.35l-.7-.4q-1.5-.7-2.05-1.5-.25-.5-.25-1.05 0-1 1-1.85m1.35-2.2q.15.4.35.75l1.35 2.95q1.65 1.9 5.55 2.4 1.55.2 3.4.15h.2l6.7-1.25v-.05h.25q5.75-.15 10-1.6 1.75-.55 2.75-1.15.85-.45 1.3-1 .5-.5.75-1.15.05-.25.1-.6 0-2.25-4.9-3.9-4.75-1.55-11.45-1.55-6.8 0-11.6 1.55-4.65 1.6-4.8 3.85v.1q0 .25.05.5m3.25-.95v-.4q.35-1.35 3.85-2.4 3.85-1.1 9.35-1.1 5.45 0 9.35 1.1 3.85 1.15 3.85 2.7v.05q0 .6-.7 1.2-.6.55-1.85 1-.6.25-1.3.5-2.1.55-4.55.85h-2.15l-15.85-3.5z"
                    id="ObjFoo030__Layer7_2_FILL"
                />
                <path
                    fill="#CCC"
                    d="M119.2 266.7q.2.25.45.45.75.85 2.05 1.2 1.1.25 2.85.3l2.75-.3 1-.85-7.7-1.65-1.4.85m-3.7-.4q-.8.4-1.3.75-1 .85-1 1.85 0 .55.25 1.05.55.8 2.05 1.5l.7.4q.5.1 1.05.35.65.25 1.35.35 1 .35 2 .6 2.7.6 5.8.8 1.75.15 3.7.2.75.05 1.6.05h1.4q6.8-.15 11.75-1.65 2.05-.55 3.4-1.3 1.3-.55 1.75-1.35.25-.5.25-1 0-.4-.15-.75-.35-1-1.85-1.7v4.2q-1.2.9-4.35 1.7-4.6 1.15-10.8 1.25v-3.75l-6.7 1.25v2.3q-3.85-.3-6.95-1.05-1.3-.35-2.2-.65v-3.15l-1.05-2.3v4.95q-.4-.1-.7-.3v-4.6z"
                    id="ObjFoo030__Layer7_3_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo030__Symbol_94_0_Layer0_0_FILL"
                transform="translate(113.05 275.25)"
                id="ObjFoo030__col1n"
            />
            <use xlinkHref="#ObjFoo030__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo030__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo030__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo030__Layer7_3_FILL" />
        </g></g>
    )
}

export default ObjFoo030
