import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#2C1C12",
    "col1n": "#3A2619",
    "col1s": "#000000",
    "col2n": "#FFC400"
}

function ShiLon109({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.35 12.05l-.4-.2q-5.5-2.7-10.7-6.1-.75-.5-1.45-1-.1-.05-.15-.1L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6-.25.9-.45 1.85-.15.4-.25.85l-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.05q-1.9 6.55-1.8 9.25 0 1.35.55 1.75h1.25q5.35-.25 8.7-1.75 3.15-1.4 4.5-3.9l2.8-10.7 11.7 2.05 3.05-19.4v-.05l.75-4.85.1-.5.1-.5q1.7-10.5.25-20.85-.6-4.1-1.7-8.2l-2-9.95-3.05-1.25m121.7 34.35q-.15-.75-.35-1.5l-.2-.9q-.25-.9-.45-1.8l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-.15.1-.25.2v-.1q-.7.5-1.45 1v.05Q129 9.5 123 12.35q-.2.05-.4.15l-1.8.85-2.6 12.5q-.05.2-.1.45l-.1.45q-.45 2.1-.75 4.25-1.5 10.4-.05 21.55 0 .15-.05.25l.1.5.75 4.85v-.25l3.15 19.95 11.6-2.3 2.8 10.7q1.35 2.5 4.5 3.9 3.35 1.5 8.7 1.75h.6q.6-.25.75-1.75.15-2.65-1.35-9.25h.05l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6z"
                    id="ShiLon109__Symbol_551_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.7 6.1Q5.2 3.4 0 0q.4 6.6 3.7 13.1l9.8 1.1-7.25 5.4q.25.2.4.45 8.1 13.85 10.85 27l.1-.5q1.7-10.5.25-20.85-.6-4.1-1.7-8.2l-2-9.95L11.1 6.3l-.4-.2m82.05.5q-.2.05-.4.15l-1.8.85-2.6 12.5q-.05.2-.1.45l-.1.45q-.45 2.1-.75 4.25-1.5 10.4-.05 21.55 2.75-13.05 10.8-26.75.15-.25.4-.45l-7.25-5.4 9.8-1.1Q104 6.6 104.4.05q-5.65 3.7-11.65 6.55z"
                    id="ShiLon109__Symbol_552_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.8 30.5q-.2-.05-.35.1-.2.1-.25.3L0 52.35v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25M8.05 1Q7.3.5 6.6 0q.9 7.9 4.7 14.95l8.3.75-6.75 4.7q3.2 4.7 7 13.15Q24.7 44.3 24.7 53.4l.75-4.85.1-.5q-2.75-13.15-10.85-27-.15-.25-.4-.45l7.25-5.4-9.8-1.1Q8.45 7.6 8.05 1M115.4 31.1v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35M113.9 0q-.7.5-1.45 1v.05q-.4 6.55-3.7 13.05l-9.8 1.1 7.25 5.4q-.25.2-.4.45Q97.75 34.75 95 47.8q0 .15-.05.25l.1.5.75 4.85v-.25q.05-9 4.85-19.6 3.8-8.45 7-13.15l-6.75-4.7 8.3-.75q3.8-7 4.7-14.85V0z"
                    id="ShiLon109__Symbol_553_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.45 10.3q-.8-.15-1.6.25-.65.6-.8 1.45-.15.75.25 1.45.6.7 1.4.85.8.15 1.5-.3.7-.5.85-1.25.15-.85-.3-1.65-.5-.65-1.3-.8M5.1 3.75q.55-.4.8-.95 0-.2.05-.35.15-.85-.3-1.6Q5.1.2 4.3.05 3.5-.1 2.75.3q-.65.55-.8 1.4-.05.15 0 .35-.05.6.25 1.15.6.65 1.4.8.8.15 1.5-.25M90.9 12q-.15-.85-.8-1.45-.8-.4-1.6-.25-.8.15-1.3.8-.45.8-.3 1.65.15.75.85 1.25.7.45 1.5.3.8-.15 1.4-.85.4-.7.25-1.45m-3.55-8q.8-.15 1.4-.8.3-.55.25-1.15.05-.2 0-.35-.15-.85-.8-1.4-.75-.4-1.55-.25-.8.15-1.35.8-.45.75-.3 1.6.05.15.05.35.25.55.8.95.7.4 1.5.25z"
                    id="ShiLon109__Symbol_554_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon109__Symbol_551_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon109__col1n"
            />
            <use
                xlinkHref="#ShiLon109__Symbol_552_0_Layer0_0_FILL"
                transform="translate(140.25 234.35)"
                id="ShiLon109__col1d"
            />
            <use
                xlinkHref="#ShiLon109__Symbol_553_0_Layer0_0_FILL"
                transform="translate(132.2 233.35)"
                id="ShiLon109__col1s"
            />
            <use
                xlinkHref="#ShiLon109__Symbol_554_0_Layer0_0_FILL"
                transform="translate(146.55 284.95)"
                id="ShiLon109__col2n"
            />
        </g></g>
    )
}

export default ShiLon109
