import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#FF98D0"
}

function FacMou059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M59.3 23.9l-11.75.75q-5.05.2-7.4-1L19.7 25.2l-12.35.25.15.4-1.65 1.1q.2.35.45.75 1 1.9 2.8 3 2.3 1.35 5.9 1.45 1.65.05 3.35-.25 2-.4 4.05-1.35.25-.15.55-.25 2.95-.95 5.8-1.15 2-.15 3.95-.15 1.95 0 3.95.15 2.85.2 5.8 1.15.3.1.55.25 2.05.95 4.05 1.35 1.7.3 3.35.25 3.6-.1 5.9-1.45 1.8-1.1 2.8-3 .2-.3.4-.65l-.95-.8.75-2.35m6.05-16.4q0-1.45-.15-2.9-.05-.2-.1-.35Q63.85-.35 57.6 0q-5.95.8-11.45 2.4Q39.3 4.15 32.7 4.25q-6.6-.1-13.45-1.85Q13.75.8 7.8 0 1.55-.35.3 4.25q-.05.15-.1.35Q.05 6.05.05 7.5q-.05.05 0 .1l2 .4.2.7L9.6 6.8l18.65 3.65H39L62.7 6.6l1.75 1.2.9-.3z"
                    id="FacMou059__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M64.3 13.4h-.05q.1-.4.3-1.1.8-3.55.75-6.95Q63.8.6 60 .05q-6.25-.45-7.3 4.3-1.25-3.15-5.55-1.75-3.9.65-4.5 4.45-.25-3.5-5.35-3.4-4.05 0-4.65 3.25-.6-3.25-4.65-3.25-5.1-.1-5.35 3.4-.6-3.8-4.5-4.45-4.3-1.4-5.55 1.75Q11.55-.4 5.3.05 1.5.6 0 5.35q-.05 3.4.75 6.95.15.05.35 1.15-.05-.05-.1-.05 1.4 5.45 4.75 11.3 1.55 1.85 3.35 2 3 .45 4.65-2.15 1.5 2.85 4.55 2.65 4-.05 5.4-4.05 1.25 3.05 4.95 2.9 3.3-.35 4-2.75.7 2.4 4 2.75 3.7.15 4.95-2.9 1.4 4 5.4 4.05 3.05.2 4.55-2.65 1.65 2.6 4.65 2.15 1.8-.15 3.35-2 3.35-5.85 4.75-11.3z"
                    id="FacMou059__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M63.5 6.4l.3-1.1q-4.9.95-9.95 1.65-1.35.1-1.3-1.25l-.6-5.7-.5 5.8q.2 1.35-1.65 1.65-.2 0-.45.05-2.45.25-5 .45h-.25q-1.4.05-1.55-.85l-.6-5.65-.45 5.75q-.35.9-1.65 1.05-2.45.1-4.95.15h-.35q-1.75 0-2.1-1.2l-.1-.4q-.4-2.75-.45-5.4-.05 2.7-.45 5.4l-.1.4q-.35 1.2-2.1 1.2h-.35q-2.5-.05-4.95-.15-1.3-.15-1.65-1.05l-.45-5.75-.6 5.65q-.15.9-1.55.85h-.25q-2.55-.2-5-.45-.25-.05-.45-.05-1.85-.3-1.65-1.65l-.5-5.8-.6 5.7q.05 1.35-1.3 1.25Q4.9 6.25 0 5.3l.3 1.1q4.9 1 9.95 1.7 1.45.4 1.6 1.4l.9 5.5V9.55q.3-.95.95-1 .2 0 .45.05 2.7.3 5.45.55 1.35.15 1.65 1.25l1.3 4.6v-4.6q.15-.95 1.3-.9 2.5.15 5.05.25.4 0 .9.05 1 .15 1.35 1.05.05.25.15.5.4 1.7.6 3.65.2-1.95.6-3.65.1-.25.15-.5Q33 9.95 34 9.8q.5-.05.9-.05 2.55-.1 5.05-.25 1.15-.05 1.3.9V15l1.3-4.6q.3-1.1 1.65-1.25 2.75-.25 5.45-.55.25-.05.45-.05.65.05.95 1V15l.9-5.5q.15-1 1.6-1.4 5.05-.7 9.95-1.7z"
                    id="FacMou059__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou059__Symbol_126_0_Layer0_0_FILL"
                transform="translate(159.8 206.15)"
                id="FacMou059__col2n"
            />
            <use
                xlinkHref="#FacMou059__Symbol_84_0_Layer0_0_FILL"
                transform="translate(159.85 208.3)"
                id="FacMou059__col1n"
            />
            <use
                xlinkHref="#FacMou059__Symbol_7_0_Layer0_0_FILL"
                transform="translate(160.6 215.3)"
                id="FacMou059__col1s"
            />
        </g></g>
    )
}

export default FacMou059
