import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D4D4D",
    "col1n": "#000000"
}

function ShiSho065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M102.1 17.85L106.25 0q-1.65.9-3.3 1.75l-3.8 15.55q-14 .4-29.1 16.95l-2.9 2.9q-.45.45-.9.95-2.55 3.15-5.15 3.65-.45.15-.9.15t-.9-.15q-2.6-.5-5.15-3.65-.45-.5-.9-.95l-2.9-2.9Q35.25 17.7 21.25 17.3l-3.8-15.55Q15.8.9 14.15 0l4.15 17.85q-4.25 1.75-7.2 5.25-2.95 3.4-4.55 9.7-.15.7-.15 1.45-.05.1 0 .25-.05.4-.05.8 0 .45.05.8-1.35 1.75-4.5 1.65-.1-.05-.15-.05l-.45 2.25Q.75 42.65.2 45.4l-.2.8v.05q6.4 2.95 21.65 6.25l2.05.4q2 .45 4.1.75 2.35.3 4.9.3 10.9 0 18.3-5.55l.75-.55q.95-.65 2.1-1.1.45-.2 1-.35 2.6-.8 5.15-.9H60.4q2.55.1 5.15.9.55.15 1 .35 1.15.45 2.1 1.1l.75.55q7.4 5.55 18.3 5.55 2.55 0 4.9-.3 2.1-.3 4.1-.75l2.05-.4q15.15-3.3 21.55-6.2-.55-2.8-1.15-5.6l-.55-2.95h-.1q-3.15.1-4.5-1.65.05-.35.05-.8 0-.4-.05-.8.05-.15 0-.25 0-.75-.15-1.45-1.6-6.3-4.55-9.7-2.95-3.5-7.2-5.25z"
                    id="ShiSho065__Symbol_198_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.95 1.85q1.55-.65 2.95-.55L14.6 0q-1.35-.1-2.95.5l.3 1.35m32.7 30.55l.75-.55.1-.1.65-.65.35-.35.1-.1q3.6-3.8 3.05-6.15-.15.55-.4 1.2-3.95 6.85-16.6 9.5-6.3 1.2-12.45.2Q5.8 32.3 1.25 23.5q-.75-1.65-1-3.4-.2-.8-.2-1.6-.05.4-.05.8 0 .45.05.8.3 5.5 4.7 9.85.35.35.75.7 1.05.95 2.3 1.8 2.6 1.85 5.6 3.1.55.25 1.15.45.55.2 1.15.4.8.25 1.65.5 2 .45 4.1.75 2.35.3 4.9.3 10.9 0 18.3-5.55m51.1-30.55l.3-1.35q-1.6-.6-2.95-.5l-.3 1.3q1.4-.1 2.95.55m11.9 18.25q.05-.35.05-.8 0-.4-.05-.8 0 .8-.2 1.6-.25 1.75-1 3.4-4.55 8.8-18.95 11.9-6.15 1-12.45-.2-12.65-2.65-16.6-9.5-.25-.65-.4-1.2-.55 2.35 3.05 6.15l.1.1.35.35.65.65.1.1.75.55q7.4 5.55 18.3 5.55 2.55 0 4.9-.3 2.1-.3 4.1-.75.85-.25 1.65-.5.6-.2 1.15-.4.6-.2 1.15-.45 3-1.25 5.6-3.1 1.25-.85 2.3-1.8.4-.35.75-.7 4.4-4.35 4.7-9.85z"
                    id="ShiSho065__Symbol_199_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho065__Symbol_198_0_Layer0_0_FILL"
                transform="translate(132.3 238.1)"
                id="ShiSho065__col1n"
            />
            <use
                xlinkHref="#ShiSho065__Symbol_199_0_Layer0_0_FILL"
                transform="translate(138.65 254.1)"
                id="ShiSho065__col1d"
            />
        </g></g>
    )
}

export default ShiSho065
