import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000"
}

function AccHat028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M179.4 20.9q-4-3.7-9.5-7-.8-.45-1.6-.95-.25-.15-.55-.3-5.95-3.35-12.55-5.8l-1.1-.4q-6.6-2.4-13.85-3.85-.65-.15-1.3-.25-.05-.05-.1-.05-4.55-.85-9.3-1.4h-.45Q113.5-.9 97.75.9q-2.4.25-4.75.65l-4.6.75-1.5.3Q81 3.85 75.55 5.65q-24.65 7.4-37.4 20V63.5l-1.45.65q-29 13.3-35.2 33.1 0 .05-.05.15-.75 2.35-1.1 4.85L.2 103.3q-1.85 15.45 15.4 24.3 4.75-20.7 22-32.8.85-.6 1.75-1.15Q55.6 83 82 79.5l-.05-.05q.2-.05.4-.05 12.15-1.85 25.9-1.7 1.35 0 2.65.05h.25q.65 0 1.25.05l11.1.4q2.65.2 4.6.45 5.5.7 9.35 1.05.4 0 .75.05 1.9.15 3.85.35h.3v.05q27.6 4.3 43.75 16.55v-.15q15.25 11.8 19.95 31 16.95-8.8 15.1-24.25l-.15-1.05q-.35-2.5-1.1-4.85-.05-.1-.05-.15-6.2-19.8-35.2-33.1l-.55-.25V25.95q-2-2.6-4.7-5.05z"
                    id="AccHat028__Symbol_246_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M130.15 12.05q-.25-.15-.55-.3-5.95-3.35-12.55-5.8l-1.1-.4q-7.2-2.6-15.15-4.1-.05-.05-.1-.05Q96.15.55 91.4 0h-.45v45.35Q82 44.25 73 44.15q-36.15-.4-73 16.1v2.35q.15.15.45.1.3-.15.75-.35.45-.2 1-.45 1.4-.75 2.85-1.45.35-.2.65-.3.25-.15.5-.2.65-.35 1.35-.65 11.5-5.1 24.75-8.05 18-4 39.15-4H73q9.2.1 17.8.9.15 0 .35.05 4.55.45 8.95 1.1.15 0 .3.05 8.65 1.3 16.7 3.5h.15q.6.2 1.2.35 3.25.95 6.4 2 1.6.55 3.25 1.15.75.3 1.6.55.8.25 5.05 1.75 4.2 1.45 11.2 4.35v-2.05q-7.7-3.65-15.65-6.4h-.15v-.05q-.05 0-.15-.05l.15-42.4z"
                    id="AccHat028__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.4}
                    d="M236.85 22.25l-1.1-.4q-7.2-2.6-15.15-4.1v45.4q8.15 1.5 16.25 3.85V22.25z"
                    id="AccHat028__Layer7_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat028__Symbol_246_0_Layer0_0_FILL"
                transform="translate(81.65 15.4)"
                id="AccHat028__col1n"
            />
            <use
                xlinkHref="#AccHat028__Symbol_70_0_Layer0_0_FILL"
                transform="translate(119.8 16.3)"
                id="AccHat028__col1l"
            />
            <use xlinkHref="#AccHat028__Layer7_0_FILL" />
        </g></g>
    )
}

export default AccHat028
