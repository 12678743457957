import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function LegPan032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M128.5.15q-1 .25-3.4.8-11.3 3-34.8 3.1h-9.2q-.3-.25-.55-.3-.25-.1-1.3-.1-.55 0-.8.05-.8.25-1 .3-.2 0-6.3.7-1.55.15-3.45.2h-.25q-1.7-.05-3.1-.2-6.1-.7-6.3-.7-.2-.05-1-.3-.25-.05-.8-.05-1.05 0-1.3.1-.25.05-.55.3h-9.2q-23.5-.1-34.8-3.1Q8 .4 7 .15 6.35 0 6.3 0 2.95 0 1.2 2.05 0 3.55 0 5.15 0 5.2.3 6.4q1.2 3.9 12.25 7.25l1.45.4q.45.1.9.25 4.5 1.25 6.65 1.9 9 2.7 17.9 4.5.65.1 1.3.25.75.1 1.55.3.55.1 1.15.2.15.05.35.05.2.05.45.1.25.2.55.4.45.3 1.15.45h-.05q4.05 1.2 18.75 1.1H70.85q14.7.1 18.75-1.1h-.05q.7-.15 1.15-.45.3-.2.55-.4.25-.05.45-.1.2 0 .35-.05.6-.1 1.15-.2.8-.2 1.55-.3.65-.15 1.3-.25 8.9-1.8 17.9-4.5 2.15-.65 6.65-1.9.45-.15.9-.25l1.45-.4Q134 10.3 135.2 6.4q.3-1.2.3-1.25 0-1.6-1.2-3.1Q132.55 0 129.2 0q-.05 0-.7.15z"
                    id="LegPan032__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.45 2.15Q12.3-.1 15.1.75q-2.2-1.5-4.45.05-.8.5-1.6 1.4l-6.3 8.3q-2.4 3.65-2.6 5-.2 1.35-.15 1.55.05.3.15.65.15.35.3.5.15.15.5.45.3.25 2 .65-1.4-1.5-1.55-2.75-.15-1.3.7-2.8.85-1.55 1.55-2.45l6.8-9.15m37.2 8.35l-6.3-8.3q-.8-.9-1.6-1.4Q37.5-.75 35.3.75q2.8-.85 4.65 1.4l6.8 9.15q.7.9 1.55 2.45.85 1.5.7 2.8-.15 1.25-1.55 2.75 1.7-.4 2-.65.35-.3.5-.45.15-.15.3-.5.1-.35.15-.65.05-.2-.15-1.55t-2.6-5z"
                    id="LegPan032__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan032__Symbol_38_0_Layer0_0_FILL"
                transform="translate(124.75 300.9)"
                id="LegPan032__col1n"
            />
            <use
                xlinkHref="#LegPan032__Symbol_39_0_Layer0_0_FILL"
                transform="translate(167.3 304.15)"
                id="LegPan032__col1d"
            />
        </g></g>
    )
}

export default LegPan032
