import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#E5B000",
    "col1n": "#BD9100",
    "col2l": "#EEEEEE",
    "col2n": "#D9D9D9"
}

function ObjToo034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.1 24.15q.35-.25.65-.4 6.8-4.65 7.95-10.1.3-5.05-3.3-8.55-2.05-1.85-4.3-3.05-8.5-4.1-18.05 0Q12.65 3.5 9.7 5.8 1.95 12 .2 20.3q-.75 3.8 1.7 5.65.8.5 1.6.95.85.45 1.75.85 4.3 1.85 9.75 1.8 9.05.05 18.1-5.4z"
                    id="ObjToo034__Symbol_194_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M8.05 11.1q-.1-4.8.45-9.7Q8.7-.3 7.2.05q-8.3 2.9-7 13.75.55.6 1.95.9 3.05.65 6.4.65-.45-2.1-.5-4.25z"
                    id="ObjToo034__Symbol_153_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.85 0L0 44.2q.25 11.55 3.75 23.45.35 1.1.7 2.25l.5.9q1.55 2.9 3.4 6.15 8.95 15.3 15.7 26.9 4.9 8.55 10.5 15.8.65.8 1.25 1.65 1.85 2.25 3.75 4.4l.7.8q.85 1 1.75 2l.2.2.6.6q1.2.75 2.95 0l.2-.1q2.1-1.05 3.55-2.9 1.1-1.45 0-3.75-.05-.1-.1-.25l-8.35-18.9Q30.7 84.95 24.8 66.5 16.9 41.8 14.85 0z"
                    id="ObjToo034__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M6.8 0L0 20.15Q.25 31.7 3.75 43.6q.35 1.1.7 2.25l.5.9q1.55 2.9 3.4 6.15 8.95 15.3 15.7 26.9 4.9 8.55 10.5 15.8.65.8 1.25 1.65 1.85 2.25 3.75 4.4l.7.8q.85 1 1.75 2l.2.2.65.65q1 .6 2.8 0 .15-.05.3-.15-1.6-2.85-3.15-5.6-31.25-55.85-36-96.1-.1-.7-.15-1.4L6.8 0z"
                    id="ObjToo034__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo034__Symbol_194_0_Layer0_0_FILL"
                transform="translate(98.3 274.65)"
                id="ObjToo034__col1n"
            />
            <use
                xlinkHref="#ObjToo034__Symbol_153_0_Layer0_0_FILL"
                transform="translate(105.85 287.45)"
                id="ObjToo034__col1l"
            />
            <use
                xlinkHref="#ObjToo034__Symbol_74_0_Layer0_0_FILL"
                transform="translate(71.05 158.65)"
                id="ObjToo034__col2n"
            />
            <use
                xlinkHref="#ObjToo034__Symbol_35_0_Layer0_0_FILL"
                transform="translate(71.05 182.7)"
                id="ObjToo034__col2l"
            />
        </g></g>
    )
}

export default ObjToo034
