import { AssetSvgProps } from "../../../shared/assets"

function ShiMot080({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0052CC"
                    d="M159.2 275.75v8.75h66.5v-8.75h-66.5z"
                    id="ShiMot080__Layer5_1_FILL"
                />
                <g id="ShiMot080__Layer5_0_FILL">
                    <path fill="red" d="M159.2 279.5v14.1h66.5v-14.1h-66.5z" />
                    <path
                        fill="#FF0"
                        d="M159.2 257.85v21.65h66.5v-21.65h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot080__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot080__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot080
