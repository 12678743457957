import { AssetSvgProps } from "../../../shared/assets"

function ObjToy048({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="red"
                    d="M94.1 259.25l.8 3.3q13.876-.989 26.4-6.2.5-.1 1.05-.25.1 0 .25-.05h.1q.2-.05.35-.15 3.402 1.293 6.35-1.5l-.8-3.3-3.6.85q-20.983 5.032-24.4 5.75-3.418.773-3.4.8l-3.1.75M114.6 212l-25.45 6q-3.978 1.232-3.4 5.8l.45 1.95 34.5-8.15-.35-1.45q-1.5-4.69-5.75-4.15m12.4 32.2l-1.6-6.65-34.5 8.15 1.6 6.65 30.85-7.25-.05-.05 3.7-.85m-3.15-13.3l-1.6-6.65-34.5 8.15 1.6 6.65 34.5-8.15z"
                    id="ObjToy048__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M104.8 242.9q-3.3-.95-6.35.9-3 1.95-4.05 5.6-1.1 3.65.4 6.8 1.45 3.25 4.75 4.25 3.2.95 6.2-.95 3.05-1.9 4.1-5.5.4-1.45.5-2.7.6 1.1 1.55 2.2 2.65 2.75 6.2 3.05 3.55.35 5.95-1.95 2.45-2.4 2.35-5.95-.1-3.5-2.7-6.25-2.65-2.75-6.2-3.15-3.55-.3-6 2.05-2.3 2.1-2.3 5.4-1.45-2.95-4.4-3.8z"
                    id="ObjToy048__Layer5_0_MEMBER_1_FILL"
                />
                <path
                    d="M104.95 250.95q.45.25 1.05.1.5-.1.7-.55.35-.45.2-.95-.15-.55-.6-.8-.4-.3-.9-.2-.6.15-.85.65-.25.4-.1.95.15.5.5.8z"
                    id="ObjToy048__Layer5_0_MEMBER_1_MEMBER_0_FILL"
                />
                <path
                    d="M114.05 247q-.45-.3-.95-.2-.6.15-.8.6-.3.45-.15.95.1.55.55.8.4.3 1 .15.5-.1.75-.55.25-.45.15-1-.15-.5-.55-.75z"
                    id="ObjToy048__Layer5_0_MEMBER_1_MEMBER_1_FILL"
                />
                <path
                    fill="#FCE3BC"
                    d="M149.9 263.75q-.8-2.15-2.2-3.35-2.74-1.522-4.6.4-.46.313-.65.85-.033.078-.05.15-.035.055-.1.1-.58.992-.85 1.95-.966 1.999.1 3.9.05.073.05.15.132.37.3.7.191.327.4.65l1.3 1.85q.2.3.9.85.765.535 1.35.65l.15.05q.72.259 1.25.25.433-.013.75-.2.402-.188.65-.65l.3-.3q1.323-1.647 1.55-3.3.066-.099.1-.3v-.8q0-1.75-.7-3.6M88.35 279.4q-.877-2.723-3.15-3.35-.2-.062-.4-.1-.5-.1-1.05-.1-.083.02-.15.05-2.362.022-3.55 2.2-.8 1.45-.8 2.85 0 1.8.85 3.9.3.9 1.1 2.3.062.103.1.2.17.33.4.65.94 1.324 2.35.6.646-.19 1.65-.65.722-.328 1.15-.6.063.015.1 0 .65-.35 1.3-.9.5-.5.6-1.15.4-2.9-.5-5.9m-7.1-.85v.05q-.202.324-.4.65.159-.332.4-.7m38.15-18.75l-.45-.35q-1.449-1.274-4.75 1.45-.438.39-.85.7-11.96 3.554-14.6 3.7-1.258.03-2.45 0 .275.47.6.9.689.539 2.15 2.1 2.05 1.65 3.9 1.8h2.15q3.55-.6 5.35-1.65 1.2-.85 2-1.4 1.5-1.1 1.8-1.1.65 0 1.75.3 1.25.3 2.45.4h2.85q1.95-.45 3.2-1.35 1.4-1.15 2.35-1.85 1.079-1.15 1.8-1.9l.35-.5q.835-1.8 1.2-3.6l-.1.05q-3.083 1.529-5.2 1.4-.671-.007-1.2-.1 1.632-.638-.55-.1-.748-.116-1.2-.4-.775-.46-1.2 1.1-.601.184-1.35.4m-10.35 4.2q.636-.218 1.4-.5-.752.39-1.35.5h-.05m-.85 1.55q.468-.24 1.5-.75 1.028-.52 2.4-.75 1.37-.17 1 .05-.055.818-.4 1.55-.786.46-3.05 1.9-3.35 2.05-3.9 2.05-.65 0-1.1-1.45-.133-.387-.25-.7.16-.052.35-.15 1.6-.85 1.8-.95 1-.45 1.65-.8m11.4-3.15q.2 0 .4.05 1.999-.093 3.95.25.7-.013.7.5l-.4 1.1q-.05.1-.15.55-.078.104-.2.15v.05h-.05q-.352.2-1.1.2-.75 0-2.1-.25-.53-.095-1-.15l-4.5-.4q-.13-.043-.2-.15-.3-.13-.3-.4 0-.425.5-.45.014-.048 1.3-.55 1.646-.606 2.85-.5.1-.05.2-.05.05 0 .1.05z"
                    id="ObjToy048__Layer5_0_MEMBER_2_FILL"
                />
                <path
                    d="M117.75 280.05q-.292.058-.6.05l4.8 21.65.6-.1-4.8-21.6m-8.7-16.05h.05q1.804-.334 3.7-.75 3.826-.85 7.15-1.85.433-.104.85-.2 1.2-.4 2.4-.85.033-.007.05-.05 3.77-1.271 6.85-2.8l.1-.05q.265-.13.5-.25 5.96-3.031 5.85-5.4-.02-.156-.05-.3-.214-.909-1.35-1.55-2.056-1.072-7-1.2h-.1q.097.364.2.8l1.15 4.85-6.35 1.5q-.15.1-.35.15h-.1q-.15.05-.25.05-.55.15-1.05.25l-26.4 6.2-1.25-5.25q0-.171-.25-1.1-.317.19-.65.35-6.9 3.45-6.3 6 .019.09.05.15.763 2.455 8.15 2.6h.05q.185.005.35 0 .625.015 1.25 0 1.192.03 2.45 0 2.887-.16 6.05-.6 1.97-.272 4-.65.175-.05.25-.05z"
                    id="ObjToy048__Layer5_0_MEMBER_3_FILL"
                />
                <g id="ObjToy048__Layer5_0_FILL">
                    <path
                        fill="#8346F7"
                        d="M97.05 273.85q-.104-.348-.2-.7l-11.65 2.9q1.195 7.12 2.05 10.15.132.464.25.8-.274.202-.55.35.016.093 0 .2.735-.135 1.5-.3l9.4-2.3 2.8 21.85 41.8-9.9-7.5-21 11.15-2.75-.1-.5-.15-.05-.2-.05q-.25-1.513-3.35-10.65l-10.1 2.5q.166.454.3.9.159.676.25 1.35.15.162.15.35 0 1.57-2.65 4.4v.05h-.05q-1.368 1.488-3.45 3.3-5.586 4.888-9 5.3-.292.058-.6.05-.163.013-.35 0h-1.3q-5.4-.35-8.4-.95-2.1-.4-4.65-1.4-4.573-1.838-5.25-3.45-.019-.029-.05-.05-.1-.2-.1-.4m45.1-4.8q.162-.037.3-.1-.022.062-1 .4.742-.272.7-.3z"
                    />
                    <path
                        fill="#BB7B00"
                        d="M120.8 261.2q-.417.096-.85.2-3.324 1-7.15 1.85-1.896.416-3.7.75h-.05q-.075 0-.25.05-2.03.378-4 .65-3.163.44-6.05.6-1.258.03-2.45 0-.625.015-1.25 0l.15.65q.168.869.4 1.75.54 2.504 1.15 5.05.038.053.05.1.03.152.05.3.096.352.2.7 0 .2.1.4.031.021.05.05.677 1.612 5.25 3.45 2.55 1 4.65 1.4 3 .6 8.4.95h1.3q.187.013.35 0 .308.008.6-.05 3.414-.412 9-5.3 2.082-1.812 3.45-3.3h.05v-.05q2.65-2.83 2.65-4.4 0-.188-.15-.35-.091-.674-.25-1.35-.134-.446-.3-.9.016-.046 0-.1-.05-.15-.1-.35-.007-.028-.05-.05-.207-1.027-.45-2 0-.05.05-.1-.137-.219-.25-.45l-.7-4.15q-.235.12-.5.25l-.1.05q-3.08 1.529-6.85 2.8-.017.043-.05.05-1.2.45-2.4.85z"
                    />
                    <path
                        fill="#E0E0E0"
                        d="M86.2 225.75l1.55 6.65q17.625-2.105 34.5-8.15l-1.55-6.65q-18.097 1.249-34.5 8.15m39.2 11.8l-1.55-6.65q-18.088.72-34.5 8.15l1.55 6.65 34.5-8.15m-2.1 7.5l.05.05q-14.363 1.873-30.85 7.25 2.108 8.646 1.15 4.85v.1l.45 1.95q3.013.814 3.1-.75-.016-.026 3.6 0 3.661.075 24.2-6.55.963 1.341 3.6-.85l-.35-1.55-.15-.25q-.179-.988-.5-2.5l-.6-2.6q-2.969-.545-3.7.85m-29.7 11.5v.2q.02.097 0 .2l-.15-.75q.094.162.15.35z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy048__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy048__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy048__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy048__Layer5_0_MEMBER_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy048__Layer5_0_MEMBER_1_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy048__Layer5_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy048__Layer5_0_MEMBER_3_FILL" />
        </g></g>
    )
}

export default ObjToy048
