import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A10058",
    "col1l": "#FF008C",
    "col1n": "#D70076",
    "col2n": "#AAFFFF"
}

function ShiLon108({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.25-1.05-.5-2.05-.1-.35-.15-.65l-.2-.6-.4-1.6q-.6-2.05-1.2-4.05-.2-.65-.4-1.25-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.8-2.15-1.65-4.25-.15-.25-.2-.5-.85-1.95-1.7-3.8l-.3-.7q-1.25-2.75-2.65-5.4l-1.2-2.2-.4-.7-.5-.9-1.2-2q-.6-.95-1.15-1.9-.1-.15-.2-.25-1.1-1.75-2.25-3.4-.9-1.2-1.75-2.35l-1.85 1.4q-.15.1-.25.2-.5.35-1 .75-.75.6-1.55 1.15l-1.55 1.15q-4.7 3.25-9.65 5.85-.35.15-.7.35-.15.05-.25.15-13.3 14.65-22.5 12.4-9.25-2.25-9.95-2.55-.75-.35-1.75-.25-.5 0-1.05.05l-1.1.05h-.1q-.3 0-.6.05-2.8.15-5.6.15h-1.2q-2.95 0-5.8-.15-.25-.05-.5-.05l-1.1-.05q-1.65-.1-2.65.45t-7.35 1q-6.35.4-15.95-5.4l-9.6-5.85q-2-1.05-4-2.2-2.05-1.2-4.1-2.5l-2.2-1.5L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-1.15 1.55-2.2 3.15-.55.7-1 1.45-1.05 1.55-2 3.15l-.5.8q-.7 1.15-1.35 2.4l-.3.5q-.95 1.65-1.8 3.4-.55 1.05-1.05 2.15-.5.95-.95 1.95-1.2 2.6-2.3 5.35-.8 1.9-1.5 3.9l-.9 2.4q-.15.4-.3.85-.15.5-.35 1.05-.5 1.55-1 3.15-.25.75-.45 1.55-.65 2.1-1.2 4.25-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q1.6-.1 3.1.1 3.05.3 5.5 1.5 2.3 1.15 4.05 3.1l-2.8 11.75q-.25 1-.5 2.05-.3 1.05-.55 2.15-.45 1.65-.85 3.35-.05.1-.05.25l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 2.7.6 5.45 1.2 6.7 1.35 13.45 2.45l6.2.9 1.7.2q2.4.3 4.85.6 6.8.75 13.65 1.15 8.05.5 16.1.5 3.8 0 7.6-.1l5.55-.2q10.9-.55 21.75-1.95 13.2-1.7 26.4-4.75 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45q.15-.2.35-.35 1.8-1.9 4.2-3 1.7-.75 3.7-1.1 2.05-.35 4.4-.25l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.25-.1-.45-.05-.1-.05-.15-.15-.75-.35-1.5z"
                    id="ShiLon108__Symbol_548_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M48.4 87.35l-5.5-9.8v.05l-1.05 8.95 1.7.2q2.4.3 4.85.6m5.75-25.45l5.5 19.2h10.8l-16.3-19.2M23.7 74.55l6 4.2 14.25-14.7-20.25 10.5m-9.5-3.3q-.3 1.05-.55 2.15-.45 1.65-.85 3.35-.05.1-.05.25l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H12.6q2.05.5 4.15.95 2.7.6 5.45 1.2l-8-11.95m12.85-18.3l-1.85 7.1L50.75 58l-23.7-5.05m-25.6-20.3q-.5 1.55-1 3.15-.25.75-.45 1.55l7.4 7.3L21.6 28.3 1.45 32.65M15.75 3.3q-.55.7-1 1.45-1.05 1.55-2 3.15L22 22.75 15.75 3.3M48.1 28.4q-2.35-2.8-4.75-5.75L26.75 27l21.35 1.4m-2.85 17.35l2.6-11.35-6.2 8.2 3.6 3.15m78.45 7.6l2.85-7.15-19.25-.55 16.4 7.7m16.85 11.8L121.8 74.4l6.65 2.1 12.4-10.15-.3-1.2M110.3 82.9L93.35 70.1l-6.3 6.15 23.25 6.65m-24.55 6l5.55-.2-5.55-5.8v6m.1-27.3L96 50l-32.2 2.7 22.05 8.9M103 53.1l5.1 16.05 6.15-.75L103 53.1m-2-5.35L81.85 32.9l.3 6.25 18.7 8.6h.15m16.15-22.9l-1.75 4.6L132.2 27l-15.05-2.15m38.75 11.1l-12.55-5.7 8.75 15.15 5.75-2.55q-.1-.35-.15-.65l-.2-.6-.4-1.6q-.6-2.05-1.2-4.05m-19-5.2l.7 10.5 4.85 2.35-5.55-12.85m3.05-28.4q-.9-1.2-1.75-2.35l-1.85 1.4q-.15.1-.25.2-.5.35-1 .75-.75.6-1.55 1.15l-1.4 1.05 10.05 1.2q-1.1-1.75-2.25-3.4m.7 8.75L134.9 13l1.7 14.4 4.05-16.25v-.05m10.85 12.5q-.85-1.95-1.7-3.8l-5.8 3.8 7.7.5q-.15-.25-.2-.5z"
                    id="ShiLon108__Symbol_549_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M54.5 52.2l11.9 14 9.15-2.35L54.5 52.2m24.6 17.9l-2.3 2.25 25.55 4.4L79.1 70.1m-44.15 1.35L27.7 79.5l6.2.9 1.05-8.95M21.2 38.65l-2.1 8.15 23.7 5.05h.1l-21.7-13.2M5.5 48.2l.55-8.75L0 46.7q3.05.3 5.5 1.5M4.3 2.55q-.7 1.15-1.35 2.4l-.3.5Q1.7 7.1.85 8.85l13.2 7.75L4.8 1.75l-.5.8M18.55 0L18 4.3l4.65-1.8Q20.6 1.3 18.55 0M57.6 25.5l8.35 12.95-4.15-20.3-4.2 7.35M74.2 33l.25 5.95L92.9 41.6 74.2 33m51.85 39.05l7.3-10.15-.45-1.7-12.4 10.15 5.55 1.7m-14-15.6l2.2-5.05-12.45-6.15 10.25 11.2m26.9-9.6l-5.25-5 1.55 6.1q1.7-.75 3.7-1.1m-14.7-26h.1l-13.2-7.2-1.95 5.05 15.05 2.15m13.45-15.5l-.4-.7-.5-.9L132.7 5l-3.75 15.05 9.95-12.5-1.2-2.2z"
                    id="ShiLon108__Symbol_550_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M35.55 9.8q-1.65-.1-3.3-.3-2.2-.25-4.35-.55Q13.05 6.65.05 0l2.3 3.3q1.4 1.95 2.85 3.85.05.1.1.15Q7 9.6 8.75 11.8q2.4 2.95 4.75 5.75 3.95 4.6 7.95 8.6 2.1 2.05 4.2 4L37.15 9.9q-.25-.05-.5-.05l-1.1-.05m14.2.1L61.2 30.15q3.9-3.6 7.75-7.7Q78 12.75 86.8.15 72.15 7.6 55.2 9.5q-.3 0-.55.05-1.05.1-2.05.2-.5 0-1.05.05l-1.1.05h-.1q-.3 0-.6.05z"
                    id="ShiLon108__Symbol_547_0_Layer0_0_FILL"
                />
                <path
                    d="M192.4 249.5h-.5v68.1h1.2v-68.1h-.7m56.2 14.75q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon108__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon108__Symbol_548_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon108__col1n"
            />
            <use
                xlinkHref="#ShiLon108__Symbol_549_0_Layer0_0_FILL"
                transform="translate(114.35 228.6)"
                id="ShiLon108__col1d"
            />
            <use
                xlinkHref="#ShiLon108__Symbol_550_0_Layer0_0_FILL"
                transform="translate(122.3 234.75)"
                id="ShiLon108__col1l"
            />
            <use
                xlinkHref="#ShiLon108__Symbol_547_0_Layer0_0_FILL"
                transform="translate(148.95 239.45)"
                id="ShiLon108__col2n"
            />
            <use xlinkHref="#ShiLon108__Layer1_0_FILL" />
        </g></g>
    )
}

export default ShiLon108
