import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#9C3AFF"
}

function ShiMot009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.1 13.9q1.2 2 1.2 4.25 0 1.1-.65 2.35-1.1 2-3.2 2-2.55 0-3.65-2-1.15-2.05-2-2.05-1.65 0-1.65 1.8 0 5.55 7.25 5.55 4.05 0 5.95-3.3 1.2-2.1 1.2-4.35 0-3.1-1.2-5.6Q23.9 7.5 17.5 7.5q-5.7 0-8.8 4.75-2.15 3.25-2.65 6.35-.5 3.05 1.1 6.15 1.55 3.1 5.45 5.85 3.9 2.75 7.6 2.75 5.05 0 9.15-3.7Q34 25.5 34 19.15q0-5.25-1.85-9.95Q28.4 0 19 0 10.15 0 4.35 6.9 0 12.05 0 15.7q0 .4.25.85.45.75 1.4.75.9 0 2-2.2 2.2-4.25 2.55-4.8 4.4-7 12.8-7 10.5 0 11.75 15.65 0 4.1-2.6 7.3-3.1 3.8-8.55 3.8-2.5 0-5.5-2.2-2.6-1.9-3.3-3.35-.75-1.5-1.05-2.8-.3-1.35-.15-3.1t1.75-4.35q2.2-3.55 5.55-3.55 4.3 0 6.2 3.2z"
                    id="ShiMot009__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot009__Symbol_15_0_Layer0_0_FILL"
                transform="translate(175.05 265.45)"
                id="ShiMot009__col1n"
            />
        </g></g>
    )
}

export default ShiMot009
