import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E7E7E7",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2d": "#121212",
    "col2n": "#252525"
}

function ObjToy039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.15 12.2L22.1 3.15Q21.65 1.7 20.85.9q-1.4-1.35-3.8-.7-.3.05-.6.15-.45.1-1 .35-4.55 1-8.15 2.85-2.85 1-4.4 2.1Q1.3 6.7.55 7.9 0 8.8 0 10.5q0 1.45.3 2.75.6 4.2 3.35 8.15.25.55.55.95.05.25.2.45.35.6.85 1.05 1.85 1.8 5.45.2l11.1-5.2q4.95-2.35 3.35-6.65z"
                    id="ObjToy039__Symbol_127_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22 8.55q.55-.25 1-.35.3-.1.6-.15 2.4-.65 3.8.7l-.4-.5-7.25-7.5Q17.55-.5 15.45.3L3.6 5.7q-1.5.65-2.4 1.55-.85.7-1.2 1.7 2.2 6.4 6.95 8 .05-.1.1-.25.55-1.15 2-2.15.55-.3 1.4-.7L22 8.55z"
                    id="ObjToy039__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M7.2 8L.25 0Q-.15 1.6.1 2.7q.05.6.3 1.1l3.65 8.9q.3.75.65 1.25.15.3.4.45l6.1 7.3q-.2-.3-.35-.8L6.95 9.95q0-1.25.25-1.95z"
                    id="ObjToy039__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.6 22.65q-.75-.4-1.7-.15-.9.25-1.4.95-.3.5-.3 1.05 0 .3.1.6.2.75.8 1.15.1 0 .2.05.6.35 1.25.25.25 0 .45-.05.95-.25 1.4-1 .4-.6.35-1.3 0-.15-.05-.3-.2-.85-1.1-1.25m-4.5-7.95q-.95.2-1.35.95-.25.35-.3.75-.1.45.05.9.2.85 1 1.3.8.45 1.75.25.9-.25 1.35-1.1.3-.55.3-1.1l.05-.1q0-.2-.05-.4-.25-.85-1.05-1.35-.3-.1-.65-.2-.55-.1-1.1.1M8.85 9.15q-.1-.5-.75-.75-.75-.15-1.6.05-.55.15-.9.35l-.5.4q-.55.6-.45 1.1.15.45.9.6.7.25 1.6 0 .1 0 .25-.05.65-.3 1.15-.75.45-.5.3-.95m-5.75-.5q.7.2 1.55 0 .35-.1.6-.15.45-.25.85-.65.55-.45.4-1-.15-.45-.85-.65-.65-.15-1.6.1-.25.05-.5.2-.55.15-.9.5-.5.55-.35 1 .15.55.8.65M1.8 4.1q-.15.05-.25.15-.7.2-1.15.6-.5.45-.35.9.1.45.8.65.7.1 1.5-.1.4-.1.8-.25.35-.15.65-.45.5-.5.4-.95-.15-.45-.85-.6-.65-.2-1.55.05m8.85-1.9q.45-.1.8-.3.25-.2.45-.4.55-.45.4-.8-.1-.45-.8-.65-.55-.15-1.4.05h-.05q-.75.25-1.3.7-.45.5-.35.95.15.35.75.5.7.2 1.5-.05m3.9-.05q-.1-.1-.25-.15-.8-.1-1.7.15-.4.1-.7.2-.45.25-.75.5-.6.55-.5 1.05.15.45.95.65.7.15 1.65-.1.15 0 .25-.05h.05q.65-.3 1.2-.7.5-.55.35-1-.1-.4-.55-.55M17.1 5.8q.5-.45.35-.9-.1-.5-.8-.65-.7-.15-1.55.1-.7.15-1.2.5-.1.1-.15.2-.55.45-.45.95.15.45.9.6.5.1 1.1.05l.4-.1q.85-.25 1.4-.75m5.45 16.5q.85-.25 1.35-.9.3-.55.3-1.1 0-.25-.05-.5-.2-.85-.95-1.15-.8-.45-1.65-.2-.95.2-1.35 1-.5.65-.3 1.5.2.75.95 1.2l.05.05q.7.3 1.65.1m-.9-10.2q-.25-.85-1.05-1.35-.8-.35-1.6-.15-.1 0-.25.05-.95.25-1.45.95-.55.85-.3 1.7.2.85 1.1 1.25.1.1.25.1.7.35 1.5.15 1-.25 1.55-1.1 0-.1.05-.15.4-.65.2-1.4v-.05z"
                    id="ObjToy039__Symbol_40_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M4.6 8.85l-.05.05q-.1 0-.05.3.05.15.1.4.15.65.55 1.4 0 .05.05.05.35.75.75 1.15.4.5.55.4.2-.1.05-.75-.25-.6-.6-1.4v-.05q-.4-.7-.8-1.25-.35-.35-.55-.3m-1-.25q-.15-.6-.55-1.25t-.8-1Q2 5.9 1.8 6.05q-.2.05-.05.6.15.55.55 1.25.35.6.65 1.05.4.4.6.3.15-.1.05-.65M2.2 4.5q-.2.05-.05.6.15.55.55 1.25.35.6.7 1.1.4.35.6.25.1-.1-.05-.65-.15-.6-.45-1.2-.4-.7-.8-1.05-.3-.45-.5-.3M0 .65q.2.5.6 1.2.35.65.7 1.05.4.4.55.35.15-.15 0-.7-.15-.5-.5-1.2l-.3-.4Q.95.7.8.6.7.45.6.25.25-.1.1 0q-.15.1-.1.55v.1m3.05 2.4q-.35-.35-.5-.25-.15.1-.1.55v.1q.2.5.6 1.2.35.65.7 1.05.4.4.55.35.15-.15 0-.7-.15-.5-.5-1.2l-.3-.4q-.1-.25-.25-.35-.1-.15-.2-.35z"
                    id="ObjToy039__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy039__Symbol_127_0_Layer0_0_FILL"
                transform="translate(115.8 280.4)"
                id="ObjToy039__col1n"
            />
            <use
                xlinkHref="#ObjToy039__Symbol_78_0_Layer0_0_FILL"
                transform="translate(109.25 272.55)"
                id="ObjToy039__col1d"
            />
            <use
                xlinkHref="#ObjToy039__Symbol_60_0_Layer0_0_FILL"
                transform="translate(109 281.5)"
                id="ObjToy039__col1s"
            />
            <use
                xlinkHref="#ObjToy039__Symbol_40_0_Layer0_0_FILL"
                transform="translate(112.5 274.3)"
                id="ObjToy039__col2n"
            />
            <use
                xlinkHref="#ObjToy039__Symbol_8_0_Layer0_0_FILL"
                transform="translate(111.1 286.5)"
                id="ObjToy039__col2d"
            />
        </g></g>
    )
}

export default ObjToy039
