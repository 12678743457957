import { AssetSvgProps } from "../../../shared/assets"

function ObjMor020({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#999"
                    d="M248.1 250.75q-.15-.05-.35-.15l-.55 1.15q.15.05.25.1 1.35.65 1.8 2.05l.1.3q.55-.35 1.25-.6l-.05-.05q-.65-1.9-2.45-2.8m12.7 6.15q-.15-.1-.35-.15l-.55 1.15q.15.05.25.1 1.25.6 1.75 1.9.4-.25.9-.4.15-.05.35-.1-.7-1.7-2.35-2.5m-7.05-2q1.35.65 1.8 2.05l.15.45q.5-.45 1.2-.7l-.05-.1q-.65-1.9-2.45-2.8-.15-.05-.35-.15l-.55 1.15q.15.05.25.1m25.45 10.9q-.15-.1-.35-.15l-.55 1.15q.15.05.25.1 1.35.65 1.8 2.05.2.45.2.9l.7.35v-.05l.45 1.05q.15-.45.25-.85 0-.85-.3-1.75-.65-1.95-2.45-2.8m-7-1.95q.15.05.25.1 1.35.65 1.8 2.05l.1.1q.45-.35 1.05-.55.05 0 .15-.05-.7-1.8-2.45-2.65-.15-.1-.35-.15l-.55 1.15m-5.6-4.15q-.15-.05-.35-.15l-.55 1.15q.15.05.25.1 1.35.65 1.8 2.05.1.25.15.5.05 0 .15-.05.5-.4 1.1-.65 0-.05-.1-.15-.65-1.9-2.45-2.8z"
                    id="ObjMor020__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    d="M241.3 256.8q-.3-.75-.25-1.4-.25.25-.4.5-.45.95-.1 1.9.4 1 1.3 1.45.85.35 1.65.2-1.6-.9-2.2-2.65m15.85 5.05q-.35-.95-1.3-1.4-.35-.15-.7-.2-.1.2-.1.45-.15.85.2 1.65.45 1.25 1.55 1.9.1-.2.2-.45.45-.95.15-1.95m3.7 1.65q-.15.85.2 1.65.5 1.4 1.8 2.05.05.05.15.05.1-.2.2-.45.45-.95.15-1.95-.35-.95-1.3-1.4-.55-.3-1.1-.25l-.1.3m-1.3.8q-.05-.3 0-.65-.55.35-.85.95-.45.95-.1 1.9.4 1 1.3 1.45.85.4 1.65.2-1.2-.85-1.75-2.4-.3-.75-.25-1.45m-7.05-2.7q-.45.95-.1 1.95.35.95 1.3 1.4.9.45 1.8.2-1.05-.9-1.5-2.2-.3-.75-.25-1.45-.05-.55.05-1.1-.85.35-1.3 1.2m-2.35-3.9q-.7-.35-1.45-.15l-.05.05q-.15.85.2 1.65.5 1.45 1.8 2.05.25.15.5.25.15-.25.25-.5.45-.95.1-1.9t-1.3-1.4q0-.05-.05-.05m-2.9.65q-.25.25-.4.55-.25.6-.25 1.15 0 .4.15.75.4.95 1.3 1.45.85.35 1.65.2-1.5-.9-2.1-2.6-.3-.75-.25-1.45-.05-.05-.05-.1-.05 0-.05.05m-2.1-.3q.45-.95.15-1.9-.35-.95-1.3-1.4-.8-.4-1.65-.2-.15.85.2 1.7.5 1.4 1.8 2 .3.15.6.25.1-.2.2-.45m28.4 11.25q-.05.15-.1.25-.15.85.2 1.7.5 1.4 1.8 2.05.05 0 .15.05l.2-.4.05-.15q.3-.65.25-1.35-.05-.2-.15-.5-.35-.95-1.3-1.4-.55-.3-1.1-.25m-1.15 2.5q-.3-.7-.25-1.4-.05-.35 0-.65-.55.35-.85.95-.45.95-.1 1.9.4 1 1.3 1.45.85.4 1.65.2-1.2-.9-1.75-2.45m-5.05-5.2q-.15.85.2 1.7.5 1.4 1.8 2.05h.05l.2-.5q.45-.95.15-1.9-.35-.95-1.3-1.4-.5-.25-1-.25l-.1.3m-1.05 2.25q-.25-.7-.25-1.4-.05-.4 0-.8-.6.4-.95 1.05-.45.95-.1 1.9.35 1 1.3 1.45.85.4 1.75.2-1.25-.9-1.75-2.4z"
                    id="ObjMor020__Layer5_0_MEMBER_1_FILL"
                />
                <g id="ObjMor020__Layer5_0_FILL">
                    <path
                        fill="#F9F9F9"
                        d="M266.5 265.15q.45-.95 1.15-1.55l-5.25-2.55q-.8.5-1.2 1.4-.2.35-.25.75l-.1.3q-.15.85.2 1.65.5 1.4 1.8 2.05.05.05.15.05-.45.55-1.2.8-.1.1-.25.1-1.2-.85-1.75-2.4-.3-.75-.25-1.45-.05-.3 0-.65.05-.85.45-1.65.45-.95 1.15-1.55l-4.55-2.2q-.8.5-1.2 1.4-.15.3-.25.6-.1.2-.1.45-.15.85.2 1.65.45 1.25 1.55 1.9-.45.55-1.2.8l-.1.1q-1.05-.9-1.5-2.2-.3-.75-.25-1.45-.05-.55.05-1.1.1-.6.4-1.2.45-.95 1.1-1.55l-5.1-2.45q-.8.45-1.2 1.35-.25.45-.3 1l-.05.05q-.15.85.2 1.65.5 1.45 1.8 2.05.25.15.5.25-.45.55-1.2.8-.1.05-.15.1-.1-.05-.1 0-1.5-.9-2.1-2.6-.3-.75-.25-1.45-.05-.05-.05-.1-.05-1.15.5-2.2.3-.7.8-1.15.15-.25.35-.4l-5.05-2.4q-.8.5-1.2 1.4-.25.5-.35 1-.15.85.2 1.7.5 1.4 1.8 2 .3.15.6.25-.45.6-1.2.85-.15.05-.25.1-1.6-.9-2.2-2.65-.3-.75-.25-1.4-.1-1.2.45-2.35.45-.95 1.15-1.5l-3.35-1.65-25.3 52.45q18.799 12.988 41.95 20.3 4.908-5.362 6.45-13.25-.85-.6-1.6-1.5-2.05-2.5-2.05-6t2.05-6q.2-.25.45-.5 1.4-1.8 3.65-2.35 1.8-.8 4.1-.8.75 0 1.5.25 8.465-10.214 10.75-22.3l-.7-.35-5.55-2.7q-.8.5-1.2 1.4-.2.35-.25.75-.05.15-.1.25-.15.85.2 1.7.5 1.4 1.8 2.05.05 0 .15.05-.3.35-.75.6-.15.15-.45.2-.1.05-.25.1-1.2-.9-1.75-2.45-.3-.7-.25-1.4-.05-.35 0-.65.05-.85.45-1.65.45-.95 1.15-1.55l-4.85-2.35q-.8.5-1.2 1.4-.2.35-.25.7l-.1.3q-.15.85.2 1.7.5 1.4 1.8 2.05h.05q-.45.55-1.2.8-.1.05-.15.1-1.25-.9-1.75-2.4-.25-.7-.25-1.4-.05-.4 0-.8.1-.75.45-1.5z"
                    />
                    <path
                        fill="#BFBFBF"
                        d="M266.6 259.7q-.15-.05-.35-.15-1.5-.6-3.1-.15-.7-1.7-2.35-2.5-.15-.1-.35-.15-1.65-.7-3.45-.05h-.1l-.05-.1q-.65-1.9-2.45-2.8-.15-.05-.35-.15-1.65-.7-3.45-.05l-.05-.05q-.65-1.9-2.45-2.8-.15-.05-.35-.15-1.65-.7-3.45-.05-.95.35-1.65 1-.7.55-1.15 1.5-.55 1.15-.45 2.35-.05.65.25 1.4.6 1.75 2.2 2.65.1-.05.25-.1.75-.25 1.2-.85-.3-.1-.6-.25-1.3-.6-1.8-2-.35-.85-.2-1.7.1-.5.35-1 .4-.9 1.2-1.4.4-.25.8-.4 1.3-.5 2.5 0 .15.05.25.1 1.35.65 1.8 2.05l.1.3q-.25.2-.4.35-.2.15-.35.4-.5.45-.8 1.15-.55 1.05-.5 2.2 0 .05.05.1-.05.7.25 1.45.6 1.7 2.1 2.6 0-.05.1 0 .05-.05.15-.1.75-.25 1.2-.8-.25-.1-.5-.25-1.3-.6-1.8-2.05-.35-.8-.2-1.65l.05-.05q.05-.55.3-1 .4-.9 1.2-1.35.3-.25.65-.4.1 0 .15-.05 1.3-.45 2.5.05.15.05.25.1 1.35.65 1.8 2.05l.15.45q-.2.15-.35.25h-.05q-.65.6-1.1 1.55-.3.6-.4 1.2-.1.55-.05 1.1-.05.7.25 1.45.45 1.3 1.5 2.2l.1-.1q.75-.25 1.2-.8-1.1-.65-1.55-1.9-.35-.8-.2-1.65 0-.25.1-.45.1-.3.25-.6.4-.9 1.2-1.4.25-.15.55-.3.15-.1.25-.1 1.3-.45 2.5.05.15.05.25.1 1.25.6 1.75 1.9-.4.25-.75.55-.7.6-1.15 1.55-.4.8-.45 1.65-.05.35 0 .65-.05.7.25 1.45.55 1.55 1.75 2.4.15 0 .25-.1.75-.25 1.2-.8-.1 0-.15-.05-1.3-.65-1.8-2.05-.35-.8-.2-1.65l.1-.3q.05-.4.25-.75.4-.9 1.2-1.4.4-.25.8-.4l.3-.1q1.15-.3 2.2.15.15.05.25.1 1.35.65 1.8 2.05.1.25.15.5l-.25.25q-.7.6-1.15 1.55-.35.75-.45 1.5-.05.4 0 .8 0 .7.25 1.4.5 1.5 1.75 2.4.05-.05.15-.1.75-.25 1.2-.8h-.05q-1.3-.65-1.8-2.05-.35-.85-.2-1.7l.1-.3q.05-.35.25-.7.4-.9 1.2-1.4.2-.15.5-.25.1-.1.3-.15 1.3-.5 2.5.05.15.05.25.1 1.35.65 1.8 2.05l.1.1q-.35.2-.6.45-.7.6-1.15 1.55-.4.8-.45 1.65-.05.3 0 .65-.05.7.25 1.4.55 1.55 1.75 2.45.15-.05.25-.1.3-.05.45-.2.45-.25.75-.6-.1-.05-.15-.05-1.3-.65-1.8-2.05-.35-.85-.2-1.7.05-.1.1-.25.05-.4.25-.75.4-.9 1.2-1.4.4-.25.8-.4 1.3-.5 2.5.05.15.05.25.1 1.35.65 1.8 2.05.2.45.2.9l.7.35v-.05l.45 1.05q.15-.45.25-.85 0-.85-.3-1.75-.65-1.95-2.45-2.8-.15-.1-.35-.15-1.6-.7-3.3-.15-.7-1.8-2.45-2.65-.15-.1-.35-.15-1.65-.7-3.45-.1-.05.05-.15.05 0-.05-.1-.15-.65-1.9-2.45-2.8z"
                    />
                </g>
                <g id="ObjMor020__Layer5_0_MEMBER_2_FILL">
                    <path
                        fill="#E0E0E0"
                        d="M266.05 310.2q-.2.05-.45.05h-.45q-1.05 0-2.1-.5-.4-.2-.65-.4v.05l-6.45 13.25 2.2 5.35 7.9-17.8m15.2-40.05v.05l-10.75 22.3q.9.25 1.75.8.6.4.95.8l9.15-20.7-.65-2.2-.45-1.05z"
                    />
                    <path
                        fill="#EEE"
                        d="M214 302.35l-.1.05 1 4.25L258.15 328l-2.2-5.35-41.95-20.3z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor020__Layer5_0_FILL" />
            <use xlinkHref="#ObjMor020__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor020__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor020__Layer5_0_MEMBER_2_FILL" />
        </g></g>
    )
}

export default ObjMor020
