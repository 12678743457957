import { AssetSvgProps } from "../../../shared/assets"

function ShiMot109({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#8C2984"
                    d="M177.55 272.5l-6.6 3.9 6.7 3.3h48.05v-7.2h-48.15z"
                    id="ShiMot109__Layer1_0_MEMBER_0_FILL"
                />
                <path
                    d="M159.2 258.1v36h.1l22.9-17.95-22.95-18.05h-.05z"
                    id="ShiMot109__Layer1_0_MEMBER_1_FILL"
                />
                <g id="ShiMot109__Layer1_0_FILL">
                    <path
                        fill="#FFF"
                        d="M225.7 272.5v-14.4h-66.45q6.957 12.025 18.3 14.4 25.283 2.819 48.15 0z"
                    />
                    <path
                        fill="#AAA"
                        d="M225.7 294.1v-14.4q-22.791-4.884-48.05 0-11.454 3.533-18.35 14.4h66.4z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot109__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot109__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot109__Layer1_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ShiMot109
