import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#B46BFF",
    "col2n": "#FFFFFF"
}

function ShiLon075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-.9-2.05-1.8-4.05-1.05.75-1.95 1.55-.9-5.1-2.55-10.4-.8-1.4-1.65-2.8-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-4.3 3-8.85 5.45-1.3 5.1-2.05 8.65-1.3-3.1-2.9-6.2-2.7 1.2-5.45 2.3-5.5 2.05-11.25 3.45-4.75 1.15-9.75 1.8-2.1 4.55-3.6 9.15-.85-4.3-1.95-8.6l-1.15.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.15-.05q-1.15 4.25-2 8.5-1.1-3.75-3.55-9.05-1.05-.15-2.05-.3-3.9-.6-7.7-1.5-5.75-1.4-11.25-3.45-2.75-1.1-5.45-2.3-1.9 4.4-2.8 7.85-.9-5.15-2.15-10.3-4.55-2.45-8.85-5.45L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.6 1-1.15 2-1.95 5.85-3.05 9.7-.7-.45-1.45-.85-1.95 4.25-3.6 8.8l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q.95-.05 1.85 0 0-.15.05-.25l1.25-1.4q1.25.95 3.1 2.15-.2-.05-.45-.15v.2q4.1 1.1 6.85 4.15l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 8.2 1.85 16.4 3.25.9-3.65 1.6-7.25 1.1 3.55 3.35 8 3.05.45 6.1.85 5.3.65 10.65 1.15 4.85.4 9.7.65 2.45-3.1 4.15-5.65.6 2.9 1.4 5.8v.1q4 .1 8.05.1 4.05 0 8.1-.1-.05-.05-.05-.1 1.15-4.5 2.05-8.95 1.5 4.4 3.5 8.8 5.05-.25 10.15-.7 5.05-.45 10.15-1.05 3.05-.4 6.15-.85 1.4-2.5 2.6-5 1.05 1.9 2.35 4.2 8.15-1.4 16.3-3.25 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q2.65-2.95 6.55-4.05h.1v-.15h-.1q.75-2.05 1.35-4.1 1.4 1.75 3.15 3.6.8-.05 1.6 0l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon075__Symbol_515_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4 1.5Q2.5 4.2 1.15 7.05.65 8 .2 9q-.1.2-.2.45V42.4h.15q-.05.1-.05.25 2.1.05 3.95.55v-.1-.1q.25.1.45.15V.6l-.5.9m27.5.95Q29 1.25 26.55 0v75.15q2.5.4 4.95.75V2.45m32 8.1q-2.8-.2-5.55-.55v68.55q2.75.15 5.55.25V10.55m21.65 68V10q-2.25.3-4.55.5-.5 0-1 .05V78.7q0 .05.05.1 2.75-.1 5.5-.25M116.55 0q-2.45 1.25-4.95 2.45v73.5q2.5-.4 4.95-.8V0m26.55 9.45q-.1-.25-.2-.45-1.8-3.95-3.85-7.6-.2-.4-.45-.8v42.55h.1v.15q1.75-.5 3.75-.65h.65V9.45z"
                    id="ShiLon075__Symbol_422_0_Layer0_0_FILL"
                />
                <path
                    d="M227.1 263.1q-6.778-6.167-11.5 0v53.45q.312-.026.6 0 5.1-.436 10.15-1.1-.004-.025-.05-.1l.8-.05v-52.2m.05-17.4h-.05v-2.3q-5.477 2.114-11.25 3.5v2.7q6.69 7.978 11.3 0v-3.9m-69.25 69.6l.65.05q-.014.056-.05.1 5.366.67 10.7 1.1.098-.008.2 0V263.1q-5.75-5.538-11.5 0v52.2m0-69.6h-.05v3.9q5.99 7.816 11.3 0v-2.75q-5.773-1.342-11.25-3.45v2.3z"
                    id="ShiLon075__Layer9_0_FILL"
                />
                <path
                    fill="#ACACAC"
                    d="M230.4 263.1v-13.5h-17.5v13.5h17.5m-75.8-13.5v13.5h17.5v-13.5h-17.5z"
                    id="ShiLon075__Layer9_1_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon075__Layer9_2_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon075__Symbol_515_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon075__col1n"
            />
            <use
                xlinkHref="#ShiLon075__Symbol_422_0_Layer0_0_FILL"
                transform="translate(120.95 238.7)"
                id="ShiLon075__col2n"
            />
            <use xlinkHref="#ShiLon075__Layer9_0_FILL" />
            <use xlinkHref="#ShiLon075__Layer9_1_FILL" />
            <use xlinkHref="#ShiLon075__Layer9_2_FILL" />
        </g></g>
    )
}

export default ShiLon075
