import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M100.7 35.1Q95 15.75 82 7.4q-4.8-3.5-9.5-5.55-1.6-.7-3.15-1.2Q63.65-1 58.7 1.4q-6.4-2.3-12.2-.3-1.1.4-2.2.95-2.35 1.05-4.6 2.5-1.4.85-2.75 1.8Q25.7 16 19.6 30.75 14.95 41 4.45 51.5q-.65.7-1.1 1.1Q1.6 54.05 0 55.1q.2 0 .25.05Q18 52.25 34.9 35.6q-.75 4.85-3.45 6.85 17.1-9.65 28.05-27.8Q66.9 30.5 79.75 37.2q-1.45-1.8-2.1-7.45 6.85 16.95 35.65 30.5-3.2-3.1-4.55-5.15-1.4-2.1-3-5.1-1.6-3-2.8-6.95-1.15-4.25-2.25-7.95z"
                    id="BeaMus038__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M135.45 252.75l-2.95 1.75q17.75-1.9 35.3-18.15-1.65 3.9-2.55 4.95 14.6-6.45 28.7-26.95 6.95 15.15 20.9 22.15-.95-1.6-1.25-3.4 11.65 16.75 34.95 26.2l-2.75-2.25q-27.15-13.25-34.25-30.65.3 3.05.8 4.8.65 2.65 1.05 3.7-11.75-6.5-19.05-21.65-.25-.45-.45-.9v.05q-1.5-3.75-2.05-7.75.15 4.95 1.4 8.8-10.8 17.35-25.9 26 1-1.05 1.65-4.2.15-.9.3-1.95-10.5 10.35-21.35 15.4-6.2 2.9-12.5 4.05z"
                    id="BeaMus038__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus038__Symbol_55_0_Layer0_0_FILL"
                transform="translate(134.3 198.35)"
                id="BeaMus038__col1n"
            />
            <use
                xlinkHref="#BeaMus038__Symbol_16_0_Layer0_0_FILL"
                id="BeaMus038__col1d"
            />
        </g></g>
    )
}

export default BeaMus038
