import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.4 5.25h1.55l.2-.05h2.1l.35-.5q0-.7-2.3-1-2.6-.25-3.25-.4L24.3 2.05Q18.65.8 13.4 0h-1.7l-.2.05-4.05.1q-4.05.7-5.8 2.1Q0 3.55 0 5.75q0 1.15.65 1.9.9 1.1 3 1.1 2.65 0 7.9-1.55.4-.05 3.6-.75 2.65-.6 5.45-.8l9.65-.25 1.15-.15z"
                    id="FacMou015__Symbol_40_0_Layer0_0_FILL"
                />
                <g id="FacMou015__Layer5_0_FILL">
                    <path
                        fillOpacity={0.4}
                        d="M198.9 231.85q1.53-1.003 1.8-1.65-6.113 1.659-10.3 1.8-4.143.194-4.1.25.032.054 2.9.75 2.931.696 5.55.25 2.616-.394 4.15-1.4z"
                    />
                    <path d="M218.25 224.45q.08-.093.1-.25.034-.251-.1-.45l-.05-.05q-.133-.223-.4-.3-.05.015-.1 0-6.309-1.293-10-1.85-3.658-.588-6.3-1.25-2.44-.576-3.9-.9h.05q-.231-.055-.4-.05-1.495-.353-3.8-.75-2.232-.352-3.65-.45h-.25q-.07-.04-.2-.05-.032-.008-.1 0-1.401-.05-3-.05-4.83-.002-7.45.75-4.678 1.34-4.6 4.95 0 3.885 3.15 4.3.395.054.85.05.504.003 1.15-.05 1.013-.082 2.35-.3h.1q.06.005.1 0 1.835-.311 4.3-.9 1.617-.31 3.05-.6h.1q.161 0 .3-.1.054.019.1 0 .04.003.05 0 3.995-.748 6.4-1.05.613-.051 1.25-.1 5.449-.498 16.4-.45h.05q.544.003 3.8.25.28.022.5-.15.091-.075.15-.2m-10.75-1.5q.63.092 1.35.2-6.978.063-11.1.4l.05-2.65q1.309.291 3.25.75 2.708.688 6.45 1.3m-21.35-3.5q1.481 0 2.8.05v5.4q-1.48.284-3.15.6-2.057.478-3.65.75l-.05-6.65q1.765-.15 4.05-.15m6.95.55q2.313.38 3.8.7l-.05 2.9q-.433.065-.85.1h-.05q-2.329.284-6.1.95v-5.1q1.315.133 3.25.45m-14 .15q.911-.24 2.1-.45l.05 6.65q-1.98.343-3.15.35-2.6-.033-2.6-2.95.077-2.589 3.6-3.6z" />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou015__Symbol_40_0_Layer0_0_FILL"
                transform="translate(174.95 218.6)"
                id="FacMou015__col1n"
            />
            <use xlinkHref="#FacMou015__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou015
