import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00"
}

function ObjMor087({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.102}
                    d="M301.7 272.05q-2.65-4.15-6.4-7.85-13.1-13.15-31.8-13.15-18.65 0-31.85 13.15-13.15 13.2-13.15 31.85 0 16.8 10.7 29.15 1.15 1.35 2.45 2.65 13.2 13.2 31.85 13.2 18.7 0 31.8-13.2 13.2-13.15 13.2-31.8 0-13.4-6.8-24z"
                    id="ObjMor087__Symbol_203_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.2}
                    d="M303.5 296.05q0-16.6-11.75-28.35-11.7-11.65-28.25-11.65-16.6 0-28.35 11.65-11.65 11.75-11.65 28.35t11.65 28.25q11.75 11.75 28.35 11.75 16.55 0 28.25-11.75 11.75-11.65 11.75-28.25z"
                    id="ObjMor087__Symbol_203_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M298.45 296.1q0-14.55-10.25-24.85-10.25-10.15-24.7-10.15-14.5 0-24.75 10.15-10.2 10.3-10.2 24.85 0 14.5 10.2 24.7 10.25 10.25 24.75 10.25 14.45 0 24.7-10.25 10.25-10.2 10.25-24.7z"
                    id="ObjMor087__Symbol_203_0_Layer0_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.4}
                    d="M293.5 296.05q0-12.45-8.8-21.25-8.8-8.75-21.2-8.75-12.45 0-21.25 8.75-8.75 8.8-8.75 21.25 0 12.4 8.75 21.15 8.8 8.85 21.25 8.85 12.4 0 21.2-8.85 8.8-8.75 8.8-21.15z"
                    id="ObjMor087__Symbol_203_0_Layer0_3_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.502}
                    d="M281.15 278.3q-7.25-7.25-17.65-7.25-10.4 0-17.7 7.25-7.3 7.35-7.3 17.75t7.3 17.6q7.3 7.4 17.7 7.4 10.4 0 17.65-7.4 7.35-7.2 7.35-17.6 0-10.4-7.35-17.75z"
                    id="ObjMor087__Symbol_203_0_Layer0_4_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M264 296.05q0-.2-.15-.35-.15-.15-.35-.15-.2 0-.35.15-.15.15-.15.35 0 .2.15.35.15.15.35.15.2 0 .35-.15.15-.15.15-.35z"
                    id="ObjMor087__Symbol_203_0_Layer0_5_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.6}
                    d="M263.5 276.05q-8.4 0-14.2 5.8-5.8 5.8-5.8 14.2 0 8.3 5.8 14 5.8 6 14.2 6 8.25 0 14.05-6 5.95-5.7 5.95-14 0-8.4-5.95-14.2-5.8-5.8-14.05-5.8z"
                    id="ObjMor087__Symbol_203_0_Layer0_6_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M252.85 285.4q-4.35 4.35-4.35 10.65 0 6.2 4.35 10.5 4.35 4.5 10.65 4.5 6.2 0 10.55-4.5 4.45-4.3 4.45-10.5 0-6.3-4.45-10.65-4.35-4.35-10.55-4.35-6.3 0-10.65 4.35z"
                    id="ObjMor087__Symbol_203_0_Layer0_7_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.102}
                    d="M159.7 272.05q-2.65-4.15-6.4-7.85-13.1-13.15-31.8-13.15-18.65 0-31.85 13.15-13.15 13.2-13.15 31.85 0 16.8 10.7 29.15 1.15 1.35 2.45 2.65 13.2 13.2 31.85 13.2 18.7 0 31.8-13.2 13.2-13.15 13.2-31.8 0-13.4-6.8-24z"
                    id="ObjMor087__Symbol_203_0_Layer0_8_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.2}
                    d="M93.15 267.7Q81.5 279.45 81.5 296.05t11.65 28.25q11.75 11.75 28.35 11.75 16.55 0 28.25-11.75 11.75-11.65 11.75-28.25t-11.75-28.35q-11.7-11.65-28.25-11.65-16.6 0-28.35 11.65z"
                    id="ObjMor087__Symbol_203_0_Layer0_9_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M96.75 271.25q-10.2 10.3-10.2 24.85 0 14.5 10.2 24.7 10.25 10.25 24.75 10.25 14.45 0 24.7-10.25 10.25-10.2 10.25-24.7 0-14.55-10.25-24.85-10.25-10.15-24.7-10.15-14.5 0-24.75 10.15z"
                    id="ObjMor087__Symbol_203_0_Layer0_10_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.4}
                    d="M100.25 274.8q-8.75 8.8-8.75 21.25 0 12.4 8.75 21.15 8.8 8.85 21.25 8.85 12.4 0 21.2-8.85 8.8-8.75 8.8-21.15 0-12.45-8.8-21.25-8.8-8.75-21.2-8.75-12.45 0-21.25 8.75z"
                    id="ObjMor087__Symbol_203_0_Layer0_11_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.502}
                    d="M103.8 278.3q-7.3 7.35-7.3 17.75t7.3 17.6q7.3 7.4 17.7 7.4 10.4 0 17.65-7.4 7.35-7.2 7.35-17.6 0-10.4-7.35-17.75-7.25-7.25-17.65-7.25-10.4 0-17.7 7.25z"
                    id="ObjMor087__Symbol_203_0_Layer0_12_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M121.15 295.7q-.15.15-.15.35 0 .2.15.35.15.15.35.15.2 0 .35-.15.15-.15.15-.35 0-.2-.15-.35-.15-.15-.35-.15-.2 0-.35.15z"
                    id="ObjMor087__Symbol_203_0_Layer0_13_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.6}
                    d="M107.3 281.85q-5.8 5.8-5.8 14.2 0 8.3 5.8 14 5.8 6 14.2 6 8.25 0 14.05-6 5.95-5.7 5.95-14 0-8.4-5.95-14.2-5.8-5.8-14.05-5.8-8.4 0-14.2 5.8z"
                    id="ObjMor087__Symbol_203_0_Layer0_14_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M110.85 285.4q-4.35 4.35-4.35 10.65 0 6.2 4.35 10.5 4.35 4.5 10.65 4.5 6.2 0 10.55-4.5 4.45-4.3 4.45-10.5 0-6.3-4.45-10.65-4.35-4.35-10.55-4.35-6.3 0-10.65 4.35z"
                    id="ObjMor087__Symbol_203_0_Layer0_15_FILL"
                />
            </defs>
            <g id="ObjMor087__col1n">
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_0_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_1_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_2_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_3_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_4_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_5_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_6_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_7_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_8_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_9_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_10_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_11_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_12_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_13_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_14_FILL" />
                <use xlinkHref="#ObjMor087__Symbol_203_0_Layer0_15_FILL" />
            </g>
        </g></g>
    )
}

export default ObjMor087
