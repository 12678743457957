import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.9 88.65Q28.1 71.8 30 53.7 21.25 37.95 15.45 0 9.65 37.95.85 53.75 2.8 71.8 0 88.65l15.45 25.65L30.9 88.65z"
                    id="HaiSpe003__Symbol_7_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.45 0q-.05.3-.1.65Q9.75 39.3.5 55.25 2.8 73.35 0 90.2 14.45 49.1 15.45 2.85q1 46.25 15.45 87.35-2.8-16.85-.5-34.95Q21.15 39.3 15.55.65 15.5.3 15.45 0z"
                    id="HaiSpe003__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe003__Symbol_7_0_Layer0_0_FILL"
                transform="translate(177.05 5.3)"
                id="HaiSpe003__col1n"
            />
            <use
                xlinkHref="#HaiSpe003__Symbol_8_0_Layer0_0_FILL"
                transform="translate(177.05 3.75)"
                id="HaiSpe003__col1d"
            />
        </g></g>
    )
}

export default HaiSpe003
