import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#77BFFF",
    "col2n": "#006AC9"
}

function ShiSho023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M148.6 22.85q-.25-.55-.45-1.1-.65-1.55-1.3-3-.2-.35-.35-.7-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2-.6-.95-1.15-1.9-.05-.05-.1-.15-.7-1.05-1.35-2-.15-.2-.25-.4-.15-.15-.2-.3-.15-.15-.25-.3L114.4 12.55q-3.55 1.8-7 3.25-.35.15-.65.3-31.65 13.1-63.55-.2l-.9-.4-.55-.25q-2.3-1.05-4.55-2.15L13.05 1.8q-.1.1-.2.3-.15.15-.25.3-.15.2-.25.4-1.6 2.35-3.1 4.85-.15.2-.25.45-.2.3-.35.6-.55.9-1.05 1.85-2 3.65-3.8 7.5-1.15 2.5-2.2 5.1-.1.15-.15.35-.1.2-.15.35-.05.05-.05.15l-.05.05q3.65 1.45 17.7 10.3h.05v.05l-2.7 14q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8L9.45 77Q75.9 94.75 140.8 77l-5.4-21.7q-.65-3.1-1.3-6.15l-2.85-14.75q12.6-7.5 17.9-10.25-.05-.05-.05-.1l-.05-.1v-.1q-.2-.35-.3-.7l-.1-.2q-.05-.05-.05-.1m-11.75 38.5v.2l-.05-.25q.021.05.05.05z"
                    id="ShiSho023__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M18.95 35.4l.15.05.85-3.65L1.3 24.9q-.05.05-.05.15-.05.05-.05.1-.05.05-.05.1v.1q-.45 1-.8 2.05-.2.45-.35.95l18.9 7.05h.05m122.3 45.5h.2l-.05-.2v-.1l-.85-3.45q-2 .5-4.1.95-60.95 14-122.7 0-2.1-.45-4.05-.95l-.9 3.45v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H140.75q.25-.05.5-.15m-11-49l1 3.55 19.1-7.1q-.2-.5-.35-.95-.45-1.1-.85-2.15-.05-.1-.05-.15l-.05-.1v-.1l-18.8 7m3.1-30.5l-.6.5-1.9 1.4q-.05 0-.05.05l-.05.05-.2.1L129 4.65q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-.55.75-1.15 1.6-.15.2-.3.45-.35.45-.65.95 20.7 16.5 46.65 20.25 7.6 1.1 15.65 1.1 23.55 0 43.3-9.4 6.85-3.3 13.3-7.7 1.8-1.25 3.6-2.65l.05-.05q1.05-.75 2.1-1.55-.2-.25-.35-.45-.05-.1-.1-.15-.9-1.3-1.85-2.55l-1.85 1.4z"
                    id="ShiSho023__Symbol_79_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho023__Symbol_31_0_Layer0_0_FILL"
                transform="translate(117.35 229.65)"
                id="ShiSho023__col1n"
            />
            <use
                xlinkHref="#ShiSho023__Symbol_79_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho023__col2n"
            />
        </g></g>
    )
}

export default ShiSho023
