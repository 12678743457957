import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D24400",
    "col2n": "#000000"
}

function ObjToy002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.35 2.4q-.3-.1-.55-.2-1.1-.4-2.15-.7-.35-.1-.65-.2Q41.3 0 36.05 0q-9.35 0-17 4.1-.4.2-.8.45-.25.15-.55.35-3.8 2.3-7.15 5.65-4 4-6.5 8.6-.15.3-.3.65-.35.6-.65 1.25-3.15 6.85-3.1 15 0 6 1.7 11.3.05.3.2.6 1.6 4.3 3.65 7.45 2.05 3.2 5 6.15 8.65 8.65 20.25 10.2.5.05 1 .15 1.85.2 3.8.2h.45q9.45 0 17.2-4.25.65-.4 1.3-.75l1.4-.9q0-.05.05-.05l.1-.1q2.45-1.7 4.75-3.85l.1-.1.55-.55q2.65-2.65 4.65-5.55.15-.1.3-.15.15-.35.45-.8.3-.7.55-1.05h-.05q2.55-4.35 3.7-9.25 1-4.15 1-8.7-.05-.75-.05-1.45-.05-1.7-.25-3.35-.05-.35-.1-.65-.25-1.65-.6-3.2-2.25-9.5-9.6-16.85-1.2-1.2-2.4-2.25-.35-.25-.65-.5-.5-.45-1.05-.85l-.3-.2q-3.65-2.7-7.75-4.35z"
                    id="ObjToy002__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M47.1.9Q46 .5 44.95.2 44.6.1 44.3 0q-.05.15 0 .35.05.25.3.4Q58.05 9.5 59.4 27.5v.05q1.4 14.85-5.55 27.65h-.2q-2.75-.3-5.95-1.1 5.75-9.45-6.25-26.9v.05Q27.3 5.6 17.35 2.8q-.4.2-.8.45-.25.15-.55.35h.05q.1.25.4.3 9.7 2.3 23.95 24.05 11.6 16.85 6 25.8-8.55-2.5-20.05-8.75Q4.4 31.35 2.6 19.05q-.05-.3-.25-.45-.15-.1-.3-.1-.35.6-.65 1.25 2.15 12.6 24.3 26.35l.05.05q11.4 6.2 19.95 8.75-6 9.6-26.75 6.2-13.5-3.45-17.8-13.75L.8 46.4q-.1-.25-.35-.35-.25-.1-.45 0 .05.3.2.6 1.6 4.3 3.65 7.45Q9 59.9 18.6 62.35h.05Q40.85 66 47 55.25q3 .8 5.7 1.15-6 12.3-22.65 13.55-.15 0-.25.1l-.7.4q.5.05 1 .15 1.85.2 3.8.2 14.65-2.35 20.15-14.25h.15q2.2.15 4.2 0l-6.85 10q.65-.4 1.3-.75l1.4-.9q0-.05.05-.05l5.8-8.55q2.4-.5 4.35-1.6.15-.1.3-.15.15-.35.45-.8.3-.7.55-1.05h-.05l-.25-.05q-.25-.05-.45.15-1.7 1.25-3.8 1.9 5.9-9 8.2-17.15.6-2.15.95-4.25-.05-1.7-.25-3.35-.05-.35-.1-.65-.2 0-.3.15-.25.15-.25.45 0 .3-.05.6-1 11.4-10.05 24.6-1.9.3-4.1.2 6.85-12.9 5.45-27.85Q59.4 10 47.1.9z"
                    id="ObjToy002__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy002__Symbol_96_0_Layer0_0_FILL"
                transform="translate(24.85 299.95)"
                id="ObjToy002__col1n"
            />
            <use
                xlinkHref="#ObjToy002__Symbol_17_0_Layer0_0_FILL"
                transform="translate(26.55 301.25)"
                id="ObjToy002__col2n"
            />
        </g></g>
    )
}

export default ObjToy002
