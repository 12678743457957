import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#77BFFF"
}

function ShiMot004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.7 29.2q9.45-.05 16.05-4.3 6.65-4.3 6.65-10.3 0-6.05-6.65-10.35Q33.25.75 26 .15 24.4 0 22.7 0 13.3 0 6.6 4.25 0 8.55 0 14.6q0 6 6.6 10.3 6.7 4.25 16.1 4.3z"
                    id="ShiMot004__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <g id="ShiMot004__col1n" transform="translate(169.75 265)">
                <use
                    xlinkHref="#ShiMot004__Symbol_4_0_Layer0_0_FILL"
                    id="ShiMot004__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default ShiMot004
