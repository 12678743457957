import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF"
}

function ObjToo001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M53.6 61.2L52.5 60q.3 1.95.1 4.15-.1.75-.25 1.5l-.1.55q-.05.1-.05.2-.15.55-.3 1.05-.3.85-.65 1.6l3.15 4.8q0 .05.05.1.4.75.8 1.45 1.45 2.35 3.05 3.15 2.05.95 4.6-.7 3.9-4.25-2.05-8.95-2.35-2.5-4.55-4.85l-1.35-1.4q-.805-.947-1.6-1.8.135.158.25.35M22.7 9.45l-.5-.7Q17.85 3 12.25.9q-5.65-2.1-9.2.65-3.55 2.7-3 8.7.25 3.2 1.65 6.35 1.2 2.7 3.2 5.35 4.35 5.65 10 7.75 3.75 1.4 5.6.7 2.4-.25 4.9 2.9l5 6.05q2.95 3.75 5.85 7.75.9 1.15 1.75 2.35.2.3.45.6.45-.1.9-.1.3-.05.65-.05 1.75-.1 3.65.3-1.4-1.6-2.65-3-1.05-1.2-1.95-2.25-3.95-4.65-6.2-7.4-.75-.95-1.4-1.65-.6-.8-2.35-3.05L28 31.2q-2.15-3.15-1.6-5.05 1-2.35.7-5.8-.5-5.6-4.4-10.9z"
                    id="ObjToo001__Symbol_161_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToo001__Symbol_115_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M53.05 69.65l1.1 1.7q2.2 4.5 5.65 4.15-1.75-.75-3.25-2.65-1.5-1.85-2.05-2.65-.55-.8-.95-1.4l-2.8-4q-.15.55-.3 1.05l2.6 3.8M25.75 32.4q-2.65-3.25 3.15 4.55l9 11.4q.3-.05.65-.05l-1-1.2q-.75-.9-1.5-1.75l-6.95-8.8q-.7-.95-3.35-4.15m-3.6-21.15q-.4-.75-.9-1.45-.65-1-1.4-2-3.9-5.15-8.95-7Q9 .05 7.4 0q-2-.05-3.55.65V.7l-.1.05q-.55.25-1 .6-3.2 2.4-2.7 7.75.25 3 1.6 5.9 1 2.3 2.75 4.6 3.95 5.1 9 6.95 5 1.85 8.2-.55.45-.35.85-.8.1-.05.05-.1-6.85-4.05-11.45-10.1-4.4-5.7-6.8-13.25Q8.15-.1 13.9 3.6q3.9 2.85 7.35 7.2.9 1.15 1.8 2.4-.45-.95-.9-1.95zM51.9 59.25q.135.158.25.35l1.35 1.45q-.805-.947-1.6-1.8z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo001__Symbol_161_0_Layer0_0_FILL"
                transform="translate(72.8 236.4)"
                id="ObjToo001__col1n"
            />
            <use
                xlinkHref="#ObjToo001__Symbol_115_0_Layer0_0_FILL"
                transform="translate(74.25 238)"
                id="ObjToo001__col1d"
            />
        </g></g>
    )
}

export default ObjToo001
