import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00",
    "col2d": "#DE5500",
    "col2n": "#FF7B00"
}

function ObjToy047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.85 7.8q-.9-2.4-2.9-4.35Q40.25 0 35.1 0 34 0 33 .2q-3.85.55-6.7 3.25-3.7 3.4-3.7 8.2 0 3.55 1.95 6.3.3.7.65 1.3-1.1-.15-3.6-.15-.05 0-.25.05-.7.05-2.85.5-3.1.55-5.35.55-1.35 0-4.25-1.4-3.05-1.5-3.8-1.6-2.15-.25-2.45 1.45-.15.95-.35 2.95-.55 1.9-1.25 4.9-.9 3.8-1.05 5.4.5 7.15 7.65 11.8 3.05 1.7 6.25 2.95 14.55 6.05 29.15 0 7.45-3.55 10.05-10.5 2.25-8.95-5-12.8-.2-.3-.65-.65-1.1-.75-1.7-1.15-1.05-.8-1.8-1.3-.9-.65-1.25-.85-.15-.1-.25-.2 1.2-1.1 3.05-3.2 1.1-1.25 1.4-3 .1-.7.1-3.1l-.05-1.3H47q0-.15-.05-.3l-.1-.5m-5.8 12.55q.15-.15.35-.25-.05.1-.05.2-.15 0-.3.05M12.2 33.9v-.15l.1.15q-.05-.05-.1 0z"
                    id="ObjToy047__Symbol_132_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.25 15.5q-1 .7-2.25 1.5-1.2.7-3.35.9-1.45.15-2.95.2h-1.3q-1.8 0-4.25-.7-2.4-.8-3.95-1.5-1.5-.8-6.2-5.05 2.1 4.35 4 5.8 2 1.4 3.75 2.05 1.85.55 4.75 1.3 1.5.35 3.2.5 1.2.1 2.45.1 3.2 0 6.6-1.45.8-.3 1.4-.6 2.05-1.1 2.05-2.15v-.05h-.75q-1.75 0-3.2-.85M37.05.2Q36.9.1 36.8 0q-.05.05-.15.1l-.4.4h-.05l-.05.05-.2.2q-.15.05-.2.15-.05.1-.05.2-.15 0-.3.05-.8.5-1.5.8l-.4.1-.3.2q-2.15.95-5.7.1-1.05-.25-2.25-.75Q26.9 3.2 28.7 4q2.6 1.05 5.7.45.95-.2 1.9-.55.05-.05.1-.05V3.7h.05l.35-.15q.05 0 .15.05.25-.2.5-.5v-.05q.25-.45.35-.85v-.3q0-.8-.75-1.7z"
                    id="ObjToy047__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M20.55 7q.2-.5.2-1.1 0-1.25-1.1-2.3-.7-.65-1.8-1.1-.95-.45-2.4-1.05-1.8-.65-3.45-1Q10.8.1 9.7.05h-.1Q8.9 0 8.2 0 6.45.4 5.05 2.7q-.6.95-.9 1.8-.35.3-1.35.95-1.55.9-1.9 1.35Q.15 7.95.05 8.2 0 8.35 0 9.4q0 1.4 2 3.1.05 0 .1.05.65.5 1.25.9.8.4 1.7.75 1.45.45 3.1.45.55 0 1.05-.05.55.05 1.2.05h.05q1.35 0 2.45-.25 2.25-.45 3.5-1.95 1.1-1.3 1.1-2.7v-.2q2.75-.8 3.05-2.55z"
                    id="ObjToy047__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M10.75 4.85q2-.85 2.55-2.2.1-.3.15-.55-.3.05-.6.15-1.6.55-2.9.65-1.45.1-2.75.05-1-.1-2.5-.7-.3-.1-.7-.25-1.9-.9-1.95-.9Q.95.45.6.2.45.1.35 0q-.2.3-.3.7Q0 .9 0 1.15q0 1.05.75 1.9.4.4.95.85.45.25.85.45 1.65.8 4.1.95h1.1q1.9 0 3-.45z"
                    id="ObjToy047__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    d="M133.55 261.6q-.85-.85-2.1-.85t-2.1.85q-.9.9-.9 2.15t.9 2.1q.85.85 2.1.85t2.1-.85q.85-.85.85-2.1t-.85-2.15z"
                    id="ObjToy047__Layer5_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M130.25 262.45q-.45 0-.75.3-.35.35-.35.8v.1q.05.4.35.65.3.4.75.4t.8-.4q.35-.3.35-.75t-.35-.8q-.3-.25-.7-.3h-.1z"
                    id="ObjToy047__Layer5_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy047__Symbol_132_0_Layer0_0_FILL"
                transform="translate(95.2 253.2)"
                id="ObjToy047__col1n"
            />
            <use
                xlinkHref="#ObjToy047__Symbol_81_0_Layer0_0_FILL"
                transform="translate(100.85 272.4)"
                id="ObjToy047__col1d"
            />
            <use
                xlinkHref="#ObjToy047__Symbol_44_0_Layer0_0_FILL"
                transform="translate(132.6 261.75)"
                id="ObjToy047__col2n"
            />
            <use
                xlinkHref="#ObjToy047__Symbol_11_0_Layer0_0_FILL"
                transform="translate(135.7 269.4)"
                id="ObjToy047__col2d"
            />
            <use xlinkHref="#ObjToy047__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy047__Layer5_1_FILL" />
        </g></g>
    )
}

export default ObjToy047
