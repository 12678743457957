import { AssetSvgProps } from "../../../shared/assets"

function ShiMot108({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot108__Layer1_0_FILL">
                    <path
                        fill="#FFA05D"
                        d="M159.2 268.3v5.15q33.25 3.945 66.5 0v-5.15q-32.377-3.492-66.5 0z"
                    />
                    <path
                        fill="#E52B00"
                        d="M159.2 258v5.15q33.25 3.082 66.5 0V258h-66.5z"
                    />
                    <path
                        fill="#9B00C2"
                        d="M159.2 294.05v.05h66.5v-.05h-66.5z"
                    />
                    <path
                        fill="#A60062"
                        d="M159.2 288.9v5.15h66.5v-5.15q-31.986-3.371-66.5 0z"
                    />
                    <path
                        fill="#EE74BE"
                        d="M159.2 278.6v5.15q31.946 4.78 66.5 0v-5.15q-33.25-4.843-66.5 0z"
                    />
                </g>
                <g id="ShiMot108__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#FFF"
                        d="M159.2 273.45v5.15h66.5v-5.15h-66.5z"
                    />
                    <path
                        fill="#C4569A"
                        d="M159.2 283.75v5.15h66.5v-5.15h-66.5z"
                    />
                    <path
                        fill="#F5771D"
                        d="M159.2 263.15v5.15h66.5v-5.15h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot108__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot108__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot108
