import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00006D",
    "col1n": "#0000BB",
    "col1s": "#000000",
    "col2n": "#FFFF00",
    "col2s": "#D2AD00"
}

function ShiSpe062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.7 48.5q-.25-1.25-.5-2.45-.15-.5-.25-1.05-.1-.35-.8-.9-.7-.6-.5-1.1.15-.55.35-.75.2-.25.1-.55-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-4.05-9.2-9.25-17.2l-.7-1Q144 2.15 142.45.1l.05-.05h-.05L136.3 4.7v-.05l-1.55 1.05v.05q-8.65 5.7-18.15 9.3l-.05.05q-3.6 1.3-7.35 2.35L85.6 24q-2.3-1.05-3.05-1.8-.6.75-2.75 1.4l-23.55-6.05q-4-1.15-7.85-2.55-.05-.05-.1-.05-10.3-3.9-19.55-10.3L22.55 0h-.05l.05.05h-.05Q21 2.1 19.6 4.25l-.7 1q-5.2 8-9.25 17.2-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35-.1.2-.1.4.55.25.65.75.1.5-.6 1.2-.7.65-.8 1-.4 1.75-.75 3.5 2.7.1 1.3 1.35Q1.2 51.1.65 52q-.3 1.85-.55 3.75-.05.3-.1.65 4-3.6 10-3.7h1.25q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15 3.05 1.1 5.8 3.55V57q.2.15.4.35l.05.05-5.7 23.5q.1.05.25.05.25.1.5.15h.15q2.6.6 5.25 1.2l5 1.1q2.5.5 4.95.95.65.1 1.35.25.15 0 .3.05 6.4 1.1 12.75 1.9 3.8.5 7.6.9.7.1 1.45.15l1.8.2h.25l5.65.45q4.5.3 9 .5.6 0 1.2.05.6 0 1.2.05h1l4.6.1H84.6V89l5.6-.1h.9q.65-.05 1.3-.05.5-.05 1-.05 4.1-.2 8.2-.5l5.65-.45h.25l1.8-.2q.75-.05 1.45-.15 3.8-.4 7.6-.9 6.35-.8 12.75-1.9.15-.05.3-.05.7-.15 1.35-.25 2.45-.45 4.95-.95l5-1.1q2.65-.6 5.25-1.2h.15q.25-.05.5-.15.15 0 .25-.05l-5.7-23.5.05-.05q.2-.2.4-.35V57q2.75-2.45 5.8-3.55.25-.1.45-.15.75-.2 1.5-.3.8-.2 1.65-.25h.05q.2 0 .4.05.15-.05.3-.05H155q6 .1 10 3.7-.05-.35-.1-.65-.25-1.9-.55-3.8l-.1-.7q-2.85-2.05-.55-2.8z"
                    id="ShiSpe062__Symbol_317_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.75 6.55l1.35-.45L6 0 .1 5.95l1.35.6.05.05v.05q.1.05.25.1.1 0 .2.05.65.2 1.3.3L2.9 8.75h.05L0 19.8q.05 0 .1.05.2.1.35.2l1.6.9Q3.95 22 6 23.05q1.9-.95 3.7-1.95 1-.55 1.95-1.1l.4-.2.1-.1.3-.15-3.1-12.6.4-.1q.25-.1.5-.15.1-.05.2-.05l.3-.1z"
                    id="ShiSpe062__Symbol_318_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M21.8 63.95V55.7l-19 2.4-2 8.5 21-2.65m-.85-7.2v6.45L2.1 65.45l1.4-6.55 4.2-.5q0 .05-.3.1l13.55-1.75M5.15 30.9q-.1-.2-.3-.2-.2-.05-.3 0-.05 0-.05.05-.05 0-.1.05t-.1.2q-.05.05-.05.1L0 52.8q.4.7.85.15l.1-.3v-.15H1l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85l3.25-16.8.05-.05q0-.25-.1-.4m58.7-7.7q-.95.25-2.05.35-.65.05-1.3.05-1.45 0-2.75-.25L57.4 25h.05q1.4.3 3.05.3 1.95 0 3.7-.45l-.35-1.65m56.4 43.4l-2-8.5-19-2.4v8.25l21 2.65m-6.6-8.1q-.3-.052-.3-.1l4.2.5 1.4 6.55-18.85-2.25v-6.45l13.55 1.75m2.85-27.7q0-.05-.05-.05-.1-.05-.3 0-.2 0-.3.2-.1.15-.1.4l.05.05 3.25 16.8q.05.1.2.85.05.1.05.25v.05q.2.6.4 1.55.15.65.35 1.55v.05l-.05.05h.05v.15l.1.3q.45.55.85-.15l-4.25-21.7v-.05l-.05-.05q-.05-.15-.1-.2-.05-.05-.1-.05M114.3.05V0l-1.55 1.05v.05q-.5 1.75-.85 2.8-.4 1-.9 2.35t-.9 2.3l-15.5 5.4 9.2 4.45h.15Q86.55 37 60.3 42.8q-4.8 1.05-9.9 1.7 3.5-1.25 6.4-2.85 1.2-.55 2.55-1.4 1.3-.85 1.35-.85l-.95-.4q-2.3 1.3-4.9 2.65Q32.4 34.9 17.05 18.6l9.25-4.65-15.5-5.4q-.45-.9-1.05-2.8-.45-.8-.7-1.85-.4-1.05-.65-2.6L6.9.2q.3 1.75.8 2.85.05.5.3.9 1 2.8 1.5 4.1l.6 1.3 1.1.45 12.85 4.15-8 3.95q-.1.05-.65.35l.45.6Q31.1 35.25 53.4 42.2l-6.8 2.75q-.05 0-.1.05v.2h-.1v38.7l1.2.05v-38.1q6.7-.7 12.9-2.05 26.45-5.85 44.05-24.55l.65-1.1-8.35-4.2 12.9-4.15 1.05-.45.6-1.3q.55-1.3 1.5-4.1.25-.4.3-.85.5-1.2 1.1-3.05z"
                    id="ShiSpe062__Symbol_319_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M23.45 7.65l.65-3.3Q13.6-3.35 2.25 2.6q-.05.2-.1.35-.1.3-.15.6-.25.8-.4 1.6-.1.35-.2.75Q13.1-.55 23.45 7.65M22.1 14.3l.65-3.25Q12.05 2.7.65 9.4q-.2 1-.4 2.1-.15.7-.25 1.4 11.75-7.15 22.1 1.4m140.2-8.4l-.2-.7q-.2-.8-.4-1.65l-.1-.4q-.1-.25-.15-.55-11.35-5.95-21.85 1.75l.65 3.25q10.3-8.15 22.05-1.7m1.3 6.3q-.25-1.45-.55-2.8-11.4-6.7-22.15 1.65l.65 3.25q10.35-8.55 22.15-1.4l-.1-.7z"
                    id="ShiSpe062__Symbol_321_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M17.95 17.7q-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65m-15.7 0q-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65M4.5 1.95q-.1-.75-.65-1.3Q3.2 0 2.25 0 1.3 0 .65.65q-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3m15.7 0q-.1-.75-.65-1.3Q18.9 0 17.95 0q-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3z"
                    id="ShiSpe062__Symbol_322_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M168.95 246.75q-1.356-.308-2.75-.7 1.243 3.463 3.1 6.65.127.163.2.3.531.85 1.05 1.65.32.43.6.85 3.068 4.209 7.35 7.9 3.566 3.093 7.95 5.8l2.95-11.05.35-1.6q-.671-.112-1.3-.3-.105-.035-.2-.1-.143-.022-.3-.1h.05l-.1-.05-1.35-.65 5.95-5.9-8-.3q-.33-.001-.7-.05-.054.007-.15 0h-.05q-7.522-.622-14.65-2.35m50.25-.75q-1.626.454-3.3.85-6.933 1.677-14.35 2.3h-.05q-.074.01-.2 0-.027.03-.1 0-.363.071-.75.1l-5.85.2v-.05q-.1.028-.2 0-.648.046-1.3.05H192.5l6.05 6.05-1.3.5h-.05l-.25.1q-.102.02-.2.05l-.5.15q-.192.058-.4.1l.35 1.65h-.05l2.75 10.9 2.5-1.5q2.013-1.333 3.8-2.8.39-.262.75-.55.326-.274.65-.55 1.206-1.024 2.3-2.1 1.01-.955 1.9-1.95 1.642-1.756 3-3.65 1.166-1.527 2.15-3.15 1.914-3.188 3.25-6.7z"
                    id="ShiSpe062__Layer1_0_FILL"
                />
                <path
                    fill="#E0E0E0"
                    d="M183.25 261.4l4.65-5.4-1.35-.65-4.1 4.1L169.5 253q.531.85 1.05 1.65.32.43.6.85l10.25 5 .05-.05 1.8.95m15.3-5.9l-1.3.5 5 5.45 11.55-5.6q1.166-1.527 2.15-3.15l-13.4 6.8-4-4z"
                    id="ShiSpe062__Layer1_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe062__Symbol_317_0_Layer0_0_FILL"
                transform="translate(110 228.55)"
                id="ShiSpe062__col1n"
            />
            <use
                xlinkHref="#ShiSpe062__Symbol_318_0_Layer0_0_FILL"
                transform="translate(186.5 249.45)"
                id="ShiSpe062__col1d"
            />
            <use
                xlinkHref="#ShiSpe062__Symbol_319_0_Layer0_0_FILL"
                transform="translate(132 233.2)"
                id="ShiSpe062__col1s"
            />
            <use
                xlinkHref="#ShiSpe062__Symbol_321_0_Layer0_0_FILL"
                transform="translate(110.65 267.65)"
                id="ShiSpe062__col2n"
            />
            <use
                xlinkHref="#ShiSpe062__Symbol_322_0_Layer0_0_FILL"
                transform="translate(182.05 282.7)"
                id="ShiSpe062__col2s"
            />
            <use xlinkHref="#ShiSpe062__Layer1_0_FILL" />
            <use xlinkHref="#ShiSpe062__Layer1_1_FILL" />
        </g></g>
    )
}

export default ShiSpe062
