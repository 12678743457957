import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMak014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M115.9 16.9Q114.7 7.7 101.4 0q8 7.85 6.65 16.75-.15 1.1-.5 2.3v.05q-1.95 5.65-10.05 8.15-5.8 1.6-11.1 4.25-10.35 4.6-12.45 15.05Q71.85 36.1 61.5 31.5q-5.3-2.65-11.1-4.25-8.1-2.5-10.05-8.15v-.05q-.35-1.2-.5-2.3Q38.5 7.85 46.5 0 33.2 7.7 32 16.9q-.75 5.55-2.2 16.2Q27.7 48.35 0 61.3q14.8 8.45 19.85 21.2 5.05 12.7 17.8 13.45-6.55 10.95 6.25 21.8-4.3-8.2-.1-14.7 1.05-1.9 3.15-3.6 1.75-1.4 4.2-2.65l.5-.2q5.5-2.7 10.6-6 6.25-4.05 11.6-14.5l.1-.2.1.2q5.35 10.45 11.6 14.5 5.1 3.3 10.6 6l.5.2q2.45 1.25 4.2 2.65 2.1 1.7 3.15 3.6 4.2 6.5-.1 14.7 12.8-10.85 6.25-21.8 12.75-.75 17.8-13.45 5.05-12.75 19.85-21.2-27.7-12.95-29.8-28.2-1.45-10.65-2.2-16.2z"
                    id="SkiMak014__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak014__Symbol_20_0_Layer0_0_FILL"
                transform="translate(118.55 101.2)"
                id="SkiMak014__col1n"
            />
        </g></g>
    )
}

export default SkiMak014
