import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjToo045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4.35 183.6q-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1l-.2.2v26.25h4.9v-36.6q-.3-.25-.6-.55M.1 1.75L0 31.7h.05v144.6q.25.25.45.5 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.3-.3.6-.55L4.9 1.75Q2.5-1.7.1 1.75z"
                    id="ObjToo045__Symbol_205_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.25 13.45l-.05.15h.05v-.15m48.15.15v-.15l-.05.15h.05m-2.75-3.55l-7.25 31.4h4l-.2 2.3V73.5l-.25-.2h-17.4l-.1-41.95h3.2L31.5 0l-7.25 31.35h4.05v3h-.05L28.1 58.9h.05l-.05 3.8v10.6l-17.85-.05v-32.1h3.1l-6.1-31.4L0 41.15h3.95v17l-.25.55v21.45h24.4v12.7h6.45v-12.7h24v-38.7h3.2l-6.1-31.4m-.05 66.8v.05l-.05-.05h.05z"
                    id="ObjToo045__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M31.35 80.15H28.1v12.7h3.25v-12.7m-3.05-48.8v3h-.05L28.1 58.9h.05l-.05 3.8v10.6l-17.5-.05-3.45 3.45.1-66.95L0 41.15h3.95v17l-.25.55v21.45l3.05-3.05.4-.2h24.2L31.5 0l-7.25 31.35h4.05m27.45 12.4h-.05l-.05-33.7-7.25 31.4h4l-.2 2.3V73.5l-.25-.2h-17.5v3.6l21.15-.05.15-33.1z"
                    id="ObjToo045__Symbol_43_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo045__Symbol_205_0_Layer0_0_FILL"
                transform="translate(122.2 113.4)"
                id="ObjToo045__col1n"
            />
            <use
                xlinkHref="#ObjToo045__Symbol_84_0_Layer0_0_FILL"
                transform="translate(93.4 22.3)"
                id="ObjToo045__col2n"
            />
            <use
                xlinkHref="#ObjToo045__Symbol_43_0_Layer0_0_FILL"
                transform="translate(93.4 22.3)"
                id="ObjToo045__col2l"
            />
        </g></g>
    )
}

export default ObjToo045
