import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#AAA06A"
}

function AccHat072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FEF3CD"
                    d="M272.2 92.5l-9.3-34.05q-8.5-24.55-37.05-31.7-3.95-1.1-7.95-1.95-2.9-.6-5.8-1.05-3.404-.54-6.85-.9-.14.007-.3 0-.216-.03-.45-.05-.318-.022-.65-.05-11.712-7.038-22.65 0-4.15.35-8.25 1-2.9.45-5.8 1.05-4 .85-7.95 1.95-28.55 7.15-37.05 31.7l-9.3 34.05q-15.334 5.834-26.05 11.3-2.142 1.117-4.1 2.2-5.95 2.85-10.2 5.65-2.65 1.65-4.6 3.3-1.046.563-1.25 1.3.204.913 1.25 2 9.445 1.838 18.9 3.4 52.014 8.527 104.35 8.4h2.75q52.206.127 104.1-8.35 1.85-.312 3.7-.65 7.723-1.296 15.45-2.8 1.046-1.087 1.25-2-.204-.737-1.25-1.3-1.95-1.65-4.55-3.3-4.3-2.8-10.25-5.65-1.618-.895-3.4-1.8-10.884-5.664-26.75-11.7z"
                    id="AccHat072__Layer17_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.149}
                    d="M114.6 0q-10.4.3-20.8 3.9.7-.35 1.4-.6l-2.6.7q-.65.15-1.3.35Q63.85 11.7 55.55 35.7l-9.3 34.05Q30.9 75.6 20.2 81.1q-2.15 1.05-4.1 2.15-5.95 2.85-10.2 5.65-2.65 1.65-4.6 3.3-1.05.55-1.25 1.35-.3 1.15 1.25 2.75 9.95 1.95 19.9 3.55V95.8q-.1-.05-.2-.05-.15-.05-.25-.05 2.15-1.25.25.05 27.5-17.1 44.05-23.65 1.5-17.7 6.7-36.2Q74.5 26.05 78 19.4l.2-.35Q85.3 6.35 114.6 0m135.95 96.3q1.55-1.6 1.25-2.75-.2-.8-1.25-1.35-1.95-1.65-4.55-3.3-4.3-2.8-10.25-5.65-1.6-.9-3.35-1.8-10.95-5.65-26.8-11.7l-9.3-34.05q-8.45-24.35-36.55-31.55l-.1-.05-.4-.1q-3.95-1.1-7.95-1.95-2.9-.6-5.8-1.05-3.4-.55-6.8-.85 28.95 6.45 36 19.1l.2.35q3.5 6.65 6.25 16.5 5.2 18.5 6.7 36.2 15.8 6.25 41.6 22.1l1.95 1.35v3.2q.2-.05.4-.05v.8q9.4-1.6 18.75-3.4z"
                    id="AccHat072__Symbol_194_0_Layer0_0_FILL"
                />
                <g id="AccHat072__Layer17_1_FILL">
                    <path
                        fill="#A89A4B"
                        d="M147.1 85.25q-1.35 0-2.35.9-.95.9-.95 2.2 0 1.25.95 2.15 1 .9 2.35.9t2.25-.9q1-.9 1-2.15 0-1.3-1-2.2-.9-.9-2.25-.9m90.85 0q-1.35 0-2.25.9-1 .9-1 2.2 0 1.25 1 2.15.9.9 2.25.9t2.35-.9q.9-.9.9-2.15 0-1.3-.9-2.2-1-.9-2.35-.9m-30.25 0q-1.35 0-2.3.9-1 .9-1 2.2 0 1.25 1 2.15.95.9 2.3.9 1.35 0 2.3-.9.95-.9.95-2.15 0-1.3-.95-2.2-.95-.9-2.3-.9m-30.35 0q-1.35 0-2.3.9-.95.9-.95 2.2 0 1.25.95 2.15.95.9 2.3.9 1.35 0 2.3-.9.95-.9.95-2.15 0-1.3-.95-2.2-.95-.9-2.3-.9m-2.3-38.7q.95.9 2.3.9 1.35 0 2.3-.9.95-.9.95-2.15 0-1.3-.95-2.25-.95-.9-2.3-.9-1.35 0-2.3.9-.95.95-.95 2.25 0 1.25.95 2.15m35.9-2.15q0-1.3-.95-2.25-.95-.9-2.3-.9-1.35 0-2.3.9-1 .95-1 2.25 0 1.25 1 2.15.95.9 2.3.9 1.35 0 2.3-.9.95-.9.95-2.15z"
                    />
                    <path
                        fill="#ECE1A2"
                        d="M192.55 126.8h-1.4q-51.833.126-103.35-8.3-9.956-1.606-19.9-3.55-2.6 1.4 0 4.1 9.944 1.935 19.9 3.55 51.517 8.376 103.35 8.25h2.75q52.412.127 104.5-8.45 9.38-1.526 18.75-3.35 2.6-2.7 0-4.1-9.37 1.831-18.75 3.35-52.088 8.627-104.5 8.5h-1.35m8.2-107.55q-2.9-1.6-6.85-1.75h-2.75q-3.95.15-6.85 1.75-2.7 1.5-3.1 3.5 4.9-.5 9.95-.55h2.75q5.05.1 9.95.55-.4-2-3.1-3.5M266.5 71.8l-.85-3.35q-14.3-4.65-28.35-7.55.25-22.55-19.4-36.1-2.9-.6-5.8-1.05 21.95 14.25 21.05 36.3-.25 0-.55-.05-.2-.05-.35-.1-39.6-5.7-79.6 0-.4.1-.75.15-.05-.05 0-.15-.8-21.95 21.05-36.15-2.9.45-5.8 1.05-19.9 13.7-19.25 36.05-14.15 2.9-28.5 7.6l-.85 3.35q36.25-10.35 72.6-10.35h2.75q36.35 0 72.6 10.35m1.6 5.65l-.6-2.1q-77.1-15.85-149.95 0l-.55 2.1q76.9-12.25 151.1 0m4.1 15.05l-.6-2.3q-85.05 9.1-158.05-.25l-.7 2.55q39.5 5.65 78.3 5.7h2.75q38.8-.05 78.3-5.7m-2.45-8.85l-.45-1.8h-.45q-76.6-8.65-152.65 0h-.4l-.55 1.8q38.1-2.5 57.8-2.2 19.65.25 39.15 0 19.5-.3 57.55 2.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccHat072__Layer17_0_FILL" />
            <use xlinkHref="#AccHat072__Layer17_1_FILL" />
            <use
                xlinkHref="#AccHat072__Symbol_194_0_Layer0_0_FILL"
                transform="translate(66.6 22.75)"
                id="AccHat072__col1d"
            />
        </g></g>
    )
}

export default AccHat072
