import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C40000",
    "col2n": "#480000"
}

function ObjMus035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M122.2 47.8q-.1-1.7-.3-3.35-.3.5-.65.95-.25.3-.55.65-.6.65-1.25 1.2-1.6 1.3-3.7 1.9-.25.05-.4.15l-.9.2q-7.25 1.6-8.95-5.75l-.15-.6q-.05-.25-.05-.45-.1-.65-.05-1.25 0-1.45.5-2.8.5-1.35 1.35-2.45 0-.1.05-.15v-.2q.25-1.9.25-3.2 0-4.4-2.1-8.7-1.2-2.5-3.25-2.2-.6-.05-1.15-.15-.7-.15-1.3-.35-1.95-.9-2.15-3.15-.35-2.25 0-4.4.45-2.05 1.75-3.1 1.25-1.1 2.95-2.1 1.6-1 2.55-1.15.95-.15 1.95-.45 1-.3 3.2-.05l-.7-.85q-1.9-2.55-3.95-4l-.2-.1q-1.4-.65-2.8-1.05-1.8-.6-3.65-.75Q91.9-.5 84.8 3.6q-.8.45-1.6 1-1.35.8-2.7 1.85v.05q-.4.3-.75.7-1.75 1.5-3.5 3.15-.35.25-.65.55-3.1 2.7-6.6 4.95-.3.2-.55.3l-.05.05q-1.55.8-3.15 1.4-2.2.8-4.55 1.15-1.25.15-2.45.2-2.2-.15-5.4-1t-5.8-1.85q-.6-.25-1.9-.4l-3.4 16.95q.7.2.85.2 2.15.45 3.8 2.1 2.25 2.25 2.25 5.45t-2.25 5.45q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.85 4.2-4.1 7.45-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-2.5.95-5.4.95-6.25 0-10.65-4.4Q14 50.6 14 44.4q0-5.6 3.6-9.75l.3-.3.05-.05q0-.05.05-.05.2-.25.45-.5l.2-.2.05-.05q0-.05.05-.05.15-.15.35-.25.05-.1.1-.15 1.7-9.3 4.2-17.7-.7.15-1.3.3-2.65.85-5.15 2.1-4 2.05-5.75 4.1v-.1l-.05.05q-1.35 1.1-2.5 2.4-.65.8-1.2 1.6-2.85 4-4.65 8.2-.15.35-.3.8-1.1 2.7-1.7 5.55-.2.85-.35 1.75-.7 4.15-.3 8.5.25 6.85 2.35 12.35.85 2.3 2 4.35.05-.05.15-.05.3.7.65 1l-.1.1q1.35 2.1 3.1 3.95l.5.5q.1-.05.2-.05.2.25.45.55.5.55.9.8h-.15q2.35 2.05 5.3 3.65.2.1.45.25l.8.4q.9.45 1.85.85 2.4 1.05 5.1 1.85 7.5 2.25 17.45 2.65 6.3-.2 12.8-1.7 1.6-.4 3.15-.95.35-.15.7-.25l1.8-.8q3.45-1.75 6.55-4.55.55-.55 1.15-1.1l.3-.3q.35-.4.8-.75l.05-.05 1.5-1.7q4.1-6.15 11.2-6.75.75-.15 1.45-.25 2.15-.2 4.4.1 2.5.3 5.1.4 3.35.05 6.8-.3 3.1-.4 6.3-.9l1.2-.2q1.5-.3 2.95-.75.7-.25 1.5-.55.2-.1.45-.2 3.4-1.3 6.75-3.45 1.95-1.35 3.1-4.15 1.3-3.45 1.05-6.8z"
                    id="ObjMus035__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M37.1 8.45q-1.65 4.15-5.65 9.35-4.65 6-7.65 8.25t-4.2 3.05q-1.2.8-4.5 2.65-3.3 1.75-3.5 1.85Q5.45 36.4 0 39.15q.9.2 1.9.2 5.8 1.35 8.15.85 1.25 4.05 23.9-11.35Q53.55 15.6 53.45 0q-7 4.5-16.35 8.45m-26.2 28l.1-.05-.1.3q-.1-.1-.15-.2l.05-.05v.05q.002-.05.1-.05z"
                    id="ObjMus035__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M284.5 239.8v-.2q-.2-1.4-1.35-2.3-1.05-.9-2.35-.7-1.35.15-2.2 1.3-.8 1.15-.6 2.55v.2l6.5-.85m6.2-4.75q-1.2-.65-2.45-.2-1.25.4-1.9 1.65-.55 1.3-.1 2.6 0 .1.05.2l6.2-2.05v-.2q-.5-1.35-1.8-2m9.45-1q-.6-1.3-1.95-1.8-1.3-.5-2.5.05-1.25.55-1.7 1.9-.4 1.35.25 2.65.05.1.05.15l5.9-2.8q-.05-.1-.05-.15z"
                    id="ObjMus035__Layer4_1_FILL"
                />
                <path
                    fill="#BFBFBF"
                    d="M146.9 303q.172.084.35 0l121.85-43.6h-.05l23.65-7.2q.207-.032.3-.2.084-.156 0-.35-.032-.157-.2-.25-.156-.084-.35-.05l-23.65 7.2-121.85 43.6q-.172.085-.25.25-.084.172-.05.35.085.172.25.25m-1-3.8q.171.084.35 0l121.9-42.2h-.05l33.1-10.1q.207-.032.3-.2.084-.156 0-.35-.032-.157-.2-.25-.156-.084-.35-.05l-33.1 10.1-121.9 42.2q-.172.084-.25.25-.084.171-.05.35.084.172.25.25m-1.2-3.7q.17.084.35 0l122.15-41.1 32.55-13.4q.2-.05.25-.25.097-.15 0-.35-.05-.15-.25-.25-.15-.046-.35 0l-32.55 13.4h.05l-122.15 41.1q-.171.084-.25.25-.084.17-.05.35.084.171.25.25m-1.3-3.8q.17.084.35 0l122.45-39.8 23.65-9.8q.2-.05.25-.25.097-.15 0-.35-.05-.15-.25-.25-.15-.047-.35 0l-23.65 9.8h.05l-122.45 39.8q-.171.084-.25.25-.084.17-.05.35.083.17.25.25z"
                    id="ObjMus035__Layer4_4_FILL"
                />
                <path
                    fill="#666"
                    d="M285.05 244.55q0-1.45-1-2.5-.95-1-2.3-1-1.35 0-2.3 1-.95 1.05-.95 2.5t.95 2.45q.95 1.1 2.3 1.1 1.35 0 2.3-1.1 1-1 1-2.45z"
                    id="ObjMus035__Layer4_5_FILL"
                />
                <path
                    fill="#666"
                    d="M288.1 239.4q-.95 1.05-.95 2.5t.95 2.45q.95 1.1 2.3 1.1 1.35 0 2.3-1.1 1-1 1-2.45 0-1.45-1-2.5-.95-1-2.3-1-1.35 0-2.3 1z"
                    id="ObjMus035__Layer4_6_FILL"
                />
                <path
                    fill="#666"
                    d="M295.65 236.8q-.95 1.05-.95 2.5t.95 2.45q.95 1.1 2.3 1.1 1.35 0 2.3-1.1 1-1 1-2.45 0-1.45-1-2.5-.95-1-2.3-1-1.35 0-2.3 1z"
                    id="ObjMus035__Layer4_7_FILL"
                />
                <path
                    fill="#666"
                    d="M282 251.5q-.95 1.05-.95 2.5t.95 2.45q.95 1.1 2.3 1.1 1.35 0 2.3-1.1 1-1 1-2.45 0-1.45-1-2.5-.95-1-2.3-1-1.35 0-2.3 1z"
                    id="ObjMus035__Layer4_8_FILL"
                />
                <path
                    fill="#666"
                    d="M290.7 248.5q-.95 1.05-.95 2.5t.95 2.45q.95 1.1 2.3 1.1 1.35 0 2.3-1.1 1-1 1-2.45 0-1.45-1-2.5-.95-1-2.3-1-1.35 0-2.3 1z"
                    id="ObjMus035__Layer4_9_FILL"
                />
                <path
                    fill="#666"
                    d="M301.7 244.8q-1.35 0-2.3 1-.95 1.05-.95 2.5t.95 2.45q.95 1.1 2.3 1.1 1.35 0 2.3-1.1 1-1 1-2.45 0-1.45-1-2.5-.95-1-2.3-1z"
                    id="ObjMus035__Layer4_10_FILL"
                />
                <g id="ObjMus035__Layer4_0_FILL">
                    <path d="M257.4 309.1q.05.05.15.1l-.15-.1m-64.05-63.8v-.05q-.2.05-.35.1.15 0 .35-.05m110.05-13.95l-.2-.05q-1.1.6-2.2 1.25-4.45 2.3-9 3.9-.95.35-1.95.65-.15.2-.4.3-1 .2-1.8.5-1.2.75-3.05.75H284.45q-.75.15-1.45.3-2.678.454-5.4.65-.467.994-3.25 4.15l-2.2 2.15q-.404.33-1.35.85-.01.061-.05.1-.9 1.433-2.1 1.75-1.2.322-2.3 1.35-.041 1.06.15 2.2.399 1.863 1.45 3.95 1.707 3.39 3.45 3.7.577-.225 1.4-.45 2.35-.6 3.55-.6h3.3q2.836.916 4.4 1.6.245-.137.5-.3-.031-.01-.05-.05-.1-.05-.15-.15l5.75-3.1q.05.1.15.15.076.146.1.3.842-.363 1.65-.7-.054-.116-.1-.25-.05-.05-.05-.2l6.2-1.95q.05.1.15.15v.05q.815-.247 1.6-.5-.02-.18-.05-.4 0-.1-.05-.15l6.4-1.2q0 .1.05.15.02.095 0 .2.293-.057.55-.1l1.6-.3q1.35-.2 2.75-.35-1.15-3.7-1.2-6.5-.2-2.95.85-4.9-1.25-.6-2.4-1.6-2.4-2.1-4.35-6.1-.3-.6-.55-1.2m-25.25 24h-.1.1z" />
                    <path
                        fill="#666"
                        d="M290.35 257.2q-.024-.154-.1-.3-.1-.05-.15-.15l-5.75 3.1q.05.1.15.15.019.04.05.05.593 1.216 1.9 1.7 1.35.4 2.55-.25 1.15-.6 1.5-2 .353-1.192-.15-2.3m5.7 1.35q1.3-.4 1.85-1.6.639-1.278.3-2.65v-.05q-.1-.05-.15-.15l-6.2 1.95q0 .15.05.2.046.134.1.25.474 1.127 1.55 1.8 1.25.65 2.5.25m10.1-6.35q-.05-.05-.05-.15l-6.4 1.2q.05.05.05.15.03.22.05.4.325 1.18 1.3 1.9 1.15.8 2.45.5 1.35-.25 2.1-1.4.746-1.119.5-2.4.02-.105 0-.2z"
                    />
                </g>
                <g id="ObjMus035__Layer4_2_FILL">
                    <path
                        fill="#E30000"
                        d="M257.55 309.25l-.2-.1q.05.05.15.1h.05z"
                    />
                    <path fill="#FEFD96" d="M92.7 304.6h.1l-.1-.25v.25z" />
                    <path
                        fill="#FFF"
                        d="M115.5 320.3q0-1.55-1.1-2.65-1.05-1.05-2.55-1.05h-.05q-1.55 0-2.65 1.05-1.05 1.1-1.05 2.65 0 .7.2 1.3-2.5.95-4.95 2.05-.35.1-.8.2-1.4.1-2.75.2-1.9.05-3.75.05-.265-.022-.45-.1-.6-.317-.6-1.3v-.55q.087-.095.15-.2.35-1.25 1.05-2.35l.1-.2q-.034-.1-.1-.2-.294-.452-.45-.75l-.05.05q-.1 0-.2.05-.85.35-1.35 1.3-.05.2-.15.45v.05q-.65 1.136-.65 2.5 0 .912.2 1.35-.005.05 0 .1.65 1.3 2.35 1.35 2.6 0 5.3-.3.65-.05 1.35-.15.9-.1 1.95-.45 2.35-.9 4.4-2.1l.25.25q1.1 1.1 2.65 1.1 1.55 0 2.6-1.1 1.1-1.05 1.1-2.6m68.25-43.75l-.05.05h.05v-.05z"
                    />
                    <path
                        fill="#171717"
                        d="M130.95 326.9q-.9-.85-2.1-.85-1.25 0-2.1.85-.9.9-.9 2.1 0 1.25.9 2.1.85.9 2.1.9 1.2 0 2.1-.9.85-.85.85-2.1 0-1.2-.85-2.1m6.55 0q-.9.9-.9 2.1 0 1.25.9 2.1.85.9 2.1.9 1.2 0 2.1-.9.85-.85.85-2.1 0-1.2-.85-2.1-.9-.85-2.1-.85-1.25 0-2.1.85m-8.65-10.25q-1.25 0-2.1.85-.9.9-.9 2.1 0 1.25.9 2.1.85.9 2.1.9 1.2 0 2.1-.9.85-.85.85-2.1 0-1.2-.85-2.1-.9-.85-2.1-.85m19.55.85q-.9.9-.9 2.1 0 1.25.9 2.1.85.9 2.1.9 1.2 0 2.1-.9.85-.85.85-2.1 0-1.2-.85-2.1-.9-.85-2.1-.85-1.25 0-2.1.85m-6.55 4.2q.85-.85.85-2.1 0-1.2-.85-2.1-.9-.85-2.1-.85h-.15q-1.25 0-2.1.85-.9.9-.9 2.1 0 1.25.9 2.1.85.9 2.1.9h.15q1.2 0 2.1-.9z"
                    />
                    <path
                        fill="#2F2F2F"
                        d="M145.45 287.15l-.3.05q-.312 3.14-.4 5.2-.903-.418.05 1.95.34.85.9 2.05 1.44 2.954 4.3 8l2.95-1.1-4.7-16.95q-.671.292-1.85.55-.65.15-.9.25h-.05m132.25-42.8q-.037.028-.1.05.054.004.1 0v-.05m25.45-13q-1.1.6-2.2 1.25-4.45 2.3-9 3.9-1.05.35-2.05.7-6.1 1.6-6.95 1.8-2.95.5-5.95.7h-.05q-.266.987-1.7 2.7-1.95 2.1-2.25 2.45-1.4 1.25-3.05 2.45-2.53 1.864-3.85 2.05l.2.4.05.2.8 1.65.5-.15q.275-.2.85-.4 1.4-.55 1.6-.7l1.35-1.1q.44-.49 1.45-1.5 1.75-1.75 2.55-2.45.105-.099.2-.2.822-.735 1.95-2.1v.5q0 .771.35 1.4.114.637.35 1.05.55.85 2.05 1.6h1.55q3.05-.55 3.05-3 0-1.7-1.55-2.95-.194-.15-.4-.25.335-.03.65-.05l2-.3q.807-.121 1.6-.3v.55q.002.061 0 .1v.2q0 1 .5 1.7.226.332.55.55.933 1.15 2.4 1.15 1.6 0 2.15-.9.3-.55.3-1.65 0-1.35-.45-2.1-.406-.406-.75-.7 1.577-.486 3.1-1.1v.75q0 .77.3 1.45.095.367.3.7.417.641 1.15.85.757.35 1.75.35 1.45 0 2.1-1 .45-.65.45-1.45 0-1.55-1.4-2.95-.317-.328-.55-.55.816-.388 1.6-.8l.05.05q2.1 4.4 4.95 5.9.2.1.5.2-.45.75-.6 1.7-.1.7-.05 1.5.05 1.95.95 4.6-1 .1-1.95.25.05-.25.15-.45.091-.686-.05-1.25-.412-2.813-3.65-2.9-2.6.2-2.6 2.65 0 .5.4 1.55.376.958.85 1.25-1.838.37-3.65.95.2-.654.2-1 0-1.78-1.7-2.45-.796-.507-2.2-.3-2.45 1.05-2.45 3.2 0 .7.35 1.5.368.803.95 1.05-.95.491-2.55 1.1-.441.31-.9.5-.035.027-.1.05.2-.491.2-1 0-1.25-.7-2.3-1.1-1.65-3.5-1.45-2.55.35-2.55 2.9 0 1.45.4 2.1.49.775 1.75 1.35h-.2q-.15-.05-1.6-.5-.95-.3-2.25-.35h-3.65l-5.4.95-.5.2 1.05 2.6v.1l.1-.05.05.4 4.25-1.3q1.5-.25 2.85-.25 3.25.05 5.9 1.6 2.1-1.2 4.2-2.2.45-.2.85-.4 8.65-4 17.75-5.55.15-.05.35-.1.6-.1 1.25-.2 1.35-.2 2.75-.35-1.15-3.7-1.2-6.5-.2-2.95.85-4.9-1.25-.6-2.4-1.6-2.4-2.1-4.35-6.1-.3-.6-.55-1.2l-.2-.05m-8.85 19.4q-.1 0-.15-.05h.1l.05.05z"
                    />
                    <path fill="#666" d="M150.15 290.5h-.1l-.3.15.4-.15z" />
                    <path
                        fill="#707070"
                        d="M202.85 270.25q.05-.1.05-.15l-.1.05q-.098 0-.1.05.05 0 .15.05m-.2-.05h.05v-.05l-.05.05z"
                    />
                    <path
                        fill="#73613C"
                        d="M195.95 288.15l-.05-.05.05.15v-.1z"
                    />
                    <path
                        fill="#C71212"
                        d="M193.9 273.15l.25-.1h-.35l.1.1m-91.7-.15l-.05.05.05.05v-.1z"
                    />
                    <path
                        fill="#FBD8AE"
                        d="M259.3 264.2l.05-.05V264q-.05.05-.05.15v.05z"
                    />
                    <path d="M267.55 252.65l-.45-1.05h.05l-.8-1.65-.05-.2q-.81 2.08-2 .7l-9.4 3h-.1q.14 2.97-1.55.5l-9 2.9-.1.05q.728 3.117-1.45.45l-9.4 3q-.18 2.719-2.5.8h-.1l-8.45 2.7q-.096 3.997-2.5.8l-9.1 2.95q.455 4.104-2.45.8l-5.3 1.7q0 .05-.05.15-.1-.05-.15-.05h-.05l-1.8.55q.181 5.27-2.55.85l-4.5 1.45h.35l-.25.1h-.05l-4.05 1.3-.05.05q-.236 3.435-2.4.7v.05l.05.15-3.65 1.15v.05q.1.25.2.55.04.143.1.25v.1l.15.35q.05.15.15.4h-.1l2.65 7.9h.05v.05q.01.13.05.25.03.106.05.2.632 2.199 1.3 4.25l3.9-1.45h.05q.364-3.497 2.6-.95l1-.4h.05l7.25-3.05h.05l.1.2q-.322-4.967 2.35-.95l-.05-.15.1-.05 7.3-2.7h.05q.008-4.423 2.4-.9v-.05l8.95-3.3q-.312-3.757 2.5-.9l8.35-3.1q.506-3.205 2.45-.95l9.6-3.5-.75-1.5 2.4.85 8.35-3.15q.451-2.271 1.95-.7v-.05q0-.1.05-.15v.15l9.25-3.45q.475-2.254 2.1-.6v-.1l-1.05-2.6-1.4-3.15-.7-1.6m-21.5 13.25l.1.15-.1.05v-.2m-62.15 20.2l.3.95-.3-.95z" />
                    <path
                        fill="#333"
                        d="M246.15 266.05l-.1-.15v.2l.1-.05M183.5 287.3l-.05.05.15.45-.1-.5m-1.4-6.65q-.046-.232-.05-.4H182l.1.4z"
                    />
                    <path
                        fill="#039"
                        d="M294.15 250.7q.05.05.15.05l-.05-.05h-.1z"
                    />
                    <path
                        fill="#D9D9D9"
                        d="M159.05 272.65q-2 .3-3.5.45-1.55.15-2.3.2-.75 0-1.5.15-.75.1-1.7.05l4.4 14.45q.215.563.4 1l-.05.05.55 1.95q.176.506.35 1.05l1.1 3.55q.196.5.35.95l-.05.05 3.35 10.75q-.332-.133-.7-.25-.107-.017-.25-.05h-.05q-2.157-.15-3.35 1.15-.133.14-.25.3-.12.157-.25.3-1.88 2.78.25 5.1.422.312.8.55 2.175 1.262 4.3 0 .675-.525 1.25-1.4l.7 2.45 4.2-2.65q2.95-1.15 6.3-1.8.35-.1.75-.15 4.95-1 9.95-1.05 5.8-.25 11.7-.9l.4-.1q4.55-.55 8-2.55.15-.15.35-.3-.15 0-.25-.05l-.6-.2q-1.05-.6-2-1.15-.35-.2-.6-.35-11.05-6.75-12.75-13.3-.668-2.051-1.3-4.25-.02-.094-.05-.2-.04-.12-.05-.25v-.05h-.05l-2.65-7.9h.1q-.1-.25-.15-.4l-.15-.35v-.1q-.06-.107-.1-.25-.1-.3-.2-.55h-.05l-.05-.05-4.25-9.9-.05-.05q.1-1.9.85-3.65-2.25 2.1-5.05 3.3-8.55 4.15-12.65 5.55-1.5.5-3.45.85m23 7.6q.004.168.05.4l-.1-.4h.05m2.15 6.8l-.3-.95.3.95m-.75.3l.05-.05.1.5-.15-.45z"
                    />
                </g>
                <g id="ObjMus035__Layer4_3_FILL">
                    <path
                        fill="#FFF"
                        d="M211.8 270.65l-.4-1.15h.05l-.7-1.85h-.05l-.05-.05-2.45.8 4.55 12.2.05-.05.3.85 2.4-.9-.4-1.05h.05l-1.65-4.25-.05-.15-1-2.7h.1l-.65-1.7h-.1m10.45-6.8l-2.5.8 4.7 12.5 2.5-.9-4.7-12.4m15.5 8.35l-4.45-11.85-2.5.8 4.5 12 2.45-.95m-47.95 2.25l-.05.05-2.4.7v.05l.05.15-.2.05 5.05 14h.05l2.6-.95-5.1-14.05m11.05-3.7l-2.55.85 4.9 13.45h.05l.1.2 2.35-.95-.05-.15.1-.05-4.9-13.35m43.4-13.9l-.1.05q-.72.221-1.45.45l-.5.15 3.75 10.1q.35 1.1.55 1.4l.85-.3q.049 0 .05.05v-.05l1.6-.65-4.3-11.35-.45.15m1.8 9.25v-.2l.1.15-.1.05m9.5-12.85l-.65.2h-.1l-1.55.5-.2.1L257.1 265l.25-.1 1.95-.7v-.05q0-.1.05-.15v.15l.25-.1-4.05-10.8m12-.6l-.45-1.05h.05l-.8-1.65-.05-.2-2 .7 4.3 10.25v.05l2.1-.65v-.1l-2.45-5.75-.7-1.6m-120.2 42.05h-.1l-2.1-7.5-5.05 1.65 4.9 17.45 5-1.9-2.65-9.7z"
                    />
                    <path
                        fill="#EAEAEA"
                        d="M127.6 327.8q-.5.5-.5 1.2 0 .75.5 1.25t1.25.5q.7 0 1.2-.5t.5-1.25q0-.7-.5-1.2t-1.2-.5q-.75 0-1.25.5m10.25 1.2q0 .75.5 1.25t1.25.5q.7 0 1.2-.5t.5-1.25q0-.7-.5-1.2t-1.2-.5q-.75 0-1.25.5t-.5 1.2m1.9-11.1h-.15q-.65.05-1.1.5-.5.5-.5 1.2 0 .75.5 1.25.45.45 1.1.5h.15q.7 0 1.2-.5t.5-1.25q0-.7-.5-1.2t-1.2-.5m-12.15 2.95q.5.5 1.25.5.7 0 1.2-.5t.5-1.25q0-.7-.5-1.2t-1.2-.5q-.75 0-1.25.5t-.5 1.2q0 .75.5 1.25m22.9.5q.7 0 1.2-.5t.5-1.25q0-.7-.5-1.2t-1.2-.5q-.75 0-1.25.5t-.5 1.2q0 .75.5 1.25t1.25.5z"
                    />
                    <path d="M157.15 311.9q.5.95 1.7.95 2.05 0 2.05-2.05-.15-2.05-2.05-2.05-2.05 0-2 2.05-.05.55.3 1.1m.15-37.3q-1.55.2-3.05.25l-.5.1q-.7.05-1.35.05v.05l4.05 13.4h.05l.6 1.95h-.05l1.45 4.6q.237.821.45 1.55l5.1 15.95 1-.5q2.1-1.1 4.45-1.9 2.35-.8 4.85-1.4 1.6-.35 3.25-.6 4-.8 8.15-.8 4.15-.1 7.85-.55 2.45-.4 4.9-1.1-2.65-.65-4.75-2.35-2.85-2.25-4.75-4.85-2.1-3.1-3.15-6.3l-1.3-3.9.1.4-2.6-8.4q.004.168.05.4l-.1-.4h.05l-.55-1.8-4.65-10.85-1.4.75q-1.65.65-3.05 1.45-2.6 1.45-6.7 2.9-1.05.35-2.05.6-2.1.6-4.2 1-1.1.15-2.15.3m26.2 12.7l.1.5-.15-.45.05-.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus035__Symbol_119_0_Layer0_0_FILL"
                transform="translate(91.05 251.2)"
                id="ObjMus035__col1n"
            />
            <use
                xlinkHref="#ObjMus035__Symbol_45_0_Layer0_0_FILL"
                transform="translate(191.9 233.7)"
                id="ObjMus035__col2n"
            />
            <use xlinkHref="#ObjMus035__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_2_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_3_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_4_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_5_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_6_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_7_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_8_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_9_FILL" />
            <use xlinkHref="#ObjMus035__Layer4_10_FILL" />
        </g></g>
    )
}

export default ObjMus035
