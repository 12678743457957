import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1n": "#252525",
    "col1s": "#000000",
    "col2d": "#956E5A",
    "col2n": "#AA816C",
    "col2s": "#6D4B3A"
}

function ShiLon099({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M89.35 6.4l-.9.4h-.05l-1.85.8q-.1 0-.15.05L82.6 9q-1.9.7-3.85 1.25-.55.15-1.1.35l-2 .5q-1.05.25-2.15.55-1.9.4-3.8.75-.05 0-.1.05l-.5.1q-1.25.2-2.55.4-3.35.5-6.85.8-.35 0-.75.05l-5.85.2H50.95l-7.9-.25q-.4-.05-.75-.05-3.3-.3-6.45-.75h-.05q-.65-.1-1.25-.2-.75-.15-1.45-.25-2.3-.45-4.6-.95-1.1-.25-2.15-.5-6.6-1.7-12.75-4.25-.1-.05-.15-.1h-.05l-.3-.15-1.4-.6q-.25-.1-.45-.2-.25-.1-.4-.2-.55-.25-1.05-.5L6.7 3.5l-.4-.2Q3.7 1.9 1.2.35 1 .25.85.15.75.05.65 0H.6l1.05 2.5L.3 68.05H.2L0 77.3h.1q.05 0 .15.05l.5.1q.65.1 1.35.25.15 0 .3.05 1.8.3 3.55.6.8.1 1.6.25 3.8.55 7.6 1.05t7.6.9q.7.1 1.45.15l1.8.2h.25l5.65.45q5.7.4 11.4.6l5.6.1H53.1v.05l5.6-.1q5.7-.2 11.4-.6l5.65-.45H76l1.8-.2q.75-.05 1.45-.15 3.8-.4 7.6-.9l3-.4q.35-.05.75-.1h.05q3.85-.55 7.8-1.2h.05q.1-.05.25-.05-.15-.2-.25-.45v-.05q-.05-.05-.05-.15l.95-76.05q-4.2 2.5-8.6 4.55-.75.3-1.45.6z"
                    id="ShiLon099__Symbol_539_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M99.4 1.25q-2.25 1.3-4.5 2.5-2.05 1.05-4.1 2.05-.75.3-1.45.6l-.9.4h-.05l-1.85.8q-.1 0-.15.05L82.6 9q-1.9.7-3.85 1.25-.55.15-1.1.35l-2 .5q-1.05.25-2.15.55-1.9.4-3.8.75-.05 0-.1.05l-.5.1q-1.25.2-2.55.4-3.35.5-6.85.8-.35 0-.75.05l-5.85.2H50.95l-7.9-.25q-.4-.05-.75-.05-3.3-.3-6.45-.75h-.05q-.65-.1-1.25-.2-.75-.15-1.45-.25-2.3-.45-4.6-.95-1.1-.25-2.15-.5-6.6-1.7-12.75-4.25-.1-.05-.15-.1h-.05l-.3-.15-1.4-.6q-.25-.1-.45-.2-.25-.1-.4-.2-.55-.25-1.05-.5L6.7 3.5l-.4-.2Q3.7 1.9 1.2.35 1 .25.85.15.75.05.65 0H.6l1.05 2.5L.3 68.05H.2L0 77.3q.1-.25.25-.45v.5l.5.1q.65.1 1.35.25.15 0 .3.05 1.8.3 3.55.6.8.1 1.6.25 2.95-5.8 5.85-12.45 7.1-16 7.1-25.9 0-5-4.1-15.05L13.5 9.75q.05 0 .1.05 6.15 2.55 12.75 4.25 1.05.25 2.15.5 2.3.5 4.6.95.7.1 1.45.25.6.1 1.25.2h.05q3.15.45 6.45.75.35 0 .75.05l7.9.25h2.15l5.85-.2q.4-.05.75-.05 3.5-.3 6.85-.8 1.3-.2 2.55-.4l.5-.1q.05-.05.1-.05 1.9-.35 3.8-.75 1.1-.3 2.15-.55l2-.5q.55-.2 1.1-.35Q80.7 12.7 82.6 12l3.8-1.35q.05-.05.15-.05l1.85-.8h.05l.9-.4q.15-.05.35-.1-1.2 5.1-4.75 14-3.55 8.8-3.6 17.1-.05 8.3 4.75 23.6 2.75 8.75 5.4 15.1 3.45-.5 6.95-1.1l.95-76.75z"
                    id="ShiLon099__Symbol_501_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M97.45 69.4q0-.05-.05-.05-49 8.45-95.9-.55-.05 0-.05.05l-1 1.8-.2.4q-.15.25-.25.5.45.05.85.15.8.15 1.65.3 1 .15 2 .35l2.9.5 2.8.4q.35 0 .75.1l3.15.4q.6.1 1.15.15.55.05 1.05.15l4.5.55h.2l.2.05.9.1h.25q.25 0 .5.05.7.1 1.45.15l1.8.2h.25l5.65.45 3.1.2 7.2.35q10.05.3 20.1-.05l3.7-.2h.5l1.75-.1q1.1-.1 2.15-.15 4.9-.35 9.8-.85l1.4-.2h.05q.1-.05.15-.05.55-.05 1.1-.15l13.05-1.65q.65-.15 1.35-.25l1.4-.2q-.05-.05-.05-.1-.15-.2-.25-.45-.1-.2-.15-.4-.25-.5-.45-.95-.05-.15-.1-.25-.2-.35-.35-.75M89.35.65q-.45.2-.95.4l-3.45 16.2q-.35.1-.7.25-1.1.4-2.2.75-.7.25-1.35.45-3.4 1.05-6.9 1.9l1.85-15.25q-1.05.25-2.15.55l-1.85 15.15q-6.4 1.35-13.15 1.8L59.7 8q-.35 0-.75.05l-1.35.05-.9 14.85q-2.8.15-5.7.15-2.9 0-5.7-.15l-.85-14.9-1.4-.05q-.4-.05-.75-.05l1.2 14.9q-1.7-.1-3.3-.25l-9.25-1.4q-.3-.1-.6-.15L28.5 5.8q-1.1-.25-2.15-.5l1.85 15.3-1.2-.3-2.05-.5q-1.75-.5-3.5-1.05-.1 0-.15-.05-.1-.05-.2-.05-.1-.05-.15-.05-.1-.05-.15-.05-.25-.1-.5-.15-1.3-.45-2.55-.9-.35-.1-.7-.25L13.6 1.05q-.1-.05-.15-.1h-.05L13.1.8 11.7.2q-.25-.1-.45-.2l3.55 16.35v.05q-.3-.15-.55-.25-.5-.2-.95-.4l-2.05-.9Q10 14.25 8.8 13.7v.05q-6.7-1.8 1.3 3.05 18.65 8.8 40.9 8.8 22.15 0 40.7-8.65 9.6-6.45 1.15-3.05l-3.8 1.7q-.95.4-1.85.75L90.8.05q-.75.3-1.45.6z"
                    id="ShiLon099__Symbol_468_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M31 4.85q-.1-.1-.25-.2L24.55 0h-.05l.05.05h-.05l-.2.3-5.7 6.35q-.65.8-1.3 1.45-.05.1-.15.15-.65.7-1.25 1.25-.35.3-.7.55-1.85 1.4-2.2 1.75-1.55 1.4-2.1 3.1-.25.7-.3 1.5-1 8.95-6.7 14.3-1.95 1.85-2.95 4.7Q.1 37.85 0 40.2q-.25 4.8 2.85 9.25 0-.05.05-.05 0 .05.05.15.05-.05.15-.05l19.6 2.9 1.5 4.8.05.1Q19.4 71.35 14 85.65l-1.35 2.45q-.25.45-.35.85-.5 1.45.4 2.55.6.6 1.45 1.1.3.15.65.3 2.15.85 5.7.9 7.45-.4 12.15-8.25h-.05q.05-.1.1-.15.1-.15.2-.25.15-.35.35-.65l.1-.1q.05-.1.15-.2.1-.25.25-.5l.2-.4 1-1.8q0-.05.05-.05.15-.3.3-.5.25-.55.5-1.05 1.65-3.05 3.2-5.95 2.1-4.05 4.05-8.3 2.7-6.4 5.35-12.9 1.95-7.6-.8-13.45L32.85 6.1q-.5-.35-.95-.65-.45-.35-.9-.6M165.9 32q-.5-.9-.95-1.8-.15-.35-.3-.65-2.25-4.5-3.9-9.25 0-.05-.05-.15-.3-.95-.7-1.85-.35-1.3-.8-2.55-.6-1.55-1.4-3.05l-.4-.7q-.6-.95-1.3-1.8-.85-1-1.85-1.8-.7-.6-1.4-1.05-.25-.15-.45-.3-.15-.1-.3-.15v-.05q-.9-.45-3.2-1.8L144.5.1V.05h-.05l-6.2 4.65q-2.1 1.45-4.3 2.8l-13.3 34.25q-.3.7-.45 1.4v5.2q.25 1.05.65 2.15 2.6 9.9 5.45 19.4 1.5 5.1 3.65 10l.8 1.7q.05.2.15.4.05 0 .05.05.15.4.35.75.05.1.1.25.2.45.45.95.05.2.15.4.1.25.25.45 0 .05.05.1 0 .05.3.6l.4.8q.1.3.3.6l.3.6q0 .05.05.15h.05l-.6-1.2q2.9 5.5 10.3 7.75 10.95 2.15 12.55-.8 1.65-2.35.85-4.5l-1.75-3.3-10.1-28h-.05v-.1l-.1-.35 2.65-5.1 18.5-2.4q3.8-9.25-.05-17.7z"
                    id="ShiLon099__Symbol_442_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M17.05 58.6q.35-.75.45-.9.7-1.5-.7-2.85-.35.4-.8.9l-.4.4q-.8.75-1.55 1.25-1.1.75-2.3 1.1-.9.3-1.65.3-.35 0-.6-.05-1.05-.1-1.75-1.3l-.15.05q.05-.05.1-.05-.2-.4-.35-.8-.25-.6-.4-1.3-.05-.15-.05-.3l-.9-3.5L1.35 63.4 0 65.85q.4-.55.55-.65.1-.1.2-.15.4-.3.9-.55 3.4-1.7 6.85-1.05 3.45.65 6.4-2.45-.5.65.65-.5 1.1-1.2 1.5-1.9m8.15-40.55q0-.4-.45-1.1l-.2-.2q-.45-.25-1-.5-.35-.1-.75-.1-.8 0-2.55.6-1.8.6-2.4.6h-.4q-.7 0-.85-.7-.05-.2-.05-1 0-1.15.25-2.15.35-1.2.6-2.2l.4-1.45q0-.3.05-.6.5-4.15-3.35-7.55-3.9-3.4-4.45 0Q9.5 5.05 12 14.15l.1.3q2.55 9.3-1.55 2.4-.7-1.2-1.45-2.25-3.55-5.15-6.5-5.9-3.1.4-.5 4.45 2.5 4 6.05 5.7.85.45 1.65.9 1.4.85 2.35 2.45.95 1.65.95 3.25-.35 4.4-1 8.2l-.4.9.2-.45-.5 1.2q0 .1.05.05L8.2 45.75l3.25-4.5.2-.3q1.55-2.35 3.55-4.6 1-1.1 1.35-1.55.3-.25.75-.4 1.4-.6 3.85-.4 3.1.2 2.5-1.9-.65-2.1-4.7-5.15-4.05-3.05.3-4.3t5.7-3.2q.05-.05.05-.1.2-.65.2-1.3m110.7 30.8q0 .2.05.45 0 .7-.05 1.4-.2 2.55-1.05 5.4-.35 1.2-.85 2.45-.3.75-.65 1.25v.05q-.15.1-.25.25-1.2 1.35-3.15.7-.2-.05-.35-.15-1.55-.7-3.5-2.55l-.1-.1q-.05.05-.05.15-1 1.75-.95 3.45v.15q.05.8.3 1.55.35 1 1.1 2 2.5 3.45 6.7.75 4.15-2.75 10.05.2.5.25 1 .45l-1.75-3.3-6.65-17v.15q.1 1.15.15 2.3m3.55-39.15q-.25 0-.45.05-1.9.9-3.75 3.7-2.45 3.6-3.65-.2-1.2-3.8.05-8.05 1.25-4.3-2.7-2.3-1.9.9-2.6 3.25-.25.9-.35 2-.1 1.5.1 3.3.35 1.2.35 1.55 0 1.4-1.5 2-3 1.15-3.75 1.65l-.05.05q-3.25 3.55 1.7 4.5 7.05 1.3 2.85 8.7.6-1 .25-.3l-.45.6q-.85 1-2.95 3-2.9 2.75 4.45 3.15 2.9.2 4.7.6l.25.05q.1 0 .2.05l-.8-3.9q.05 2.9-.45-5.1-.15-1.05-.35-2.35l.05-.65q0-4.4 2.25-6.2.3-.3 4-2.1.75-.55 1.6-1.1 4.05-2.6 3.5-4.5-.95-1.45-2.5-1.45z"
                    id="ShiLon099__Symbol_411_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M1.2 49.35h-.05q-.047.095-.05.2v.05l-.3 2.1.05-.65q-.3 2-.4 2.65-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l2.15-.25h.55q.8-.05 1.65 0l.9.05H12.75q.4.05.75.1.8.1 1.55.3 3.85.9 6.85 3.85l.7.55 1.3-5.6-1.3-.85-.7-.4q-7-3.9-13.85-3.15-3.25.35-6.85 1.9M41.3 36.2q1.6.4 4.3 2.95.1.05.2.15l-5.5-13.05v-.05L32.1 6.75Q31 6 29.9 5.3q-.45-.35-.9-.6-.1-.1-.25-.2L25.5 2.05q-.05.05-.05.15-.05.2-.15.5-.4 1.1-1.65 3.2-1.25 1.95-.65 5.85.25 1.8.95 4.05 0 .05.05.1v.05q.05.2.15.5.5 1.65 1.15 2.95 2.25 4.7 5.95 5.4 4.75.85 7.2 7.7.1.1.15.55.05.4.55 1.6.45 1.15 2.15 1.55M25.9 3.7l.05.05v.05l-.05-.1m114.9 12.4q.05-.55.2-1 1-5.05 1.35-15.1l-6.1 4.55Q133.6 6.4 130.9 8l-12.25 33.55v.05q5.4-5.45 8.55-11.35.55-1 1-2 .4-.8.75-1.6.15-.2.3-.35 1.45-1.7 3.75-2.25.75-.15 1.65-.2 3.7-.15 4.6-2.7.4-1.15.65-1.85.3-.9.35-1.1.3-.95.5-1.95 0-.1.05-.15m23.65 36.6q-.05-.2-.35-2.3-.1-.45-.15-.85-11.3-4.4-20.3 1.15l-2.3 1.35.8 5.6.8-.65v.1q.002-.05.05-.05.2-.25.45-.45.75-.7 1.55-1.25 2.3-1.7 5-2.3.75-.2 1.55-.3.4-.05.85-.1 1.35-.1 2.6-.05h.55l2.15.25q.15 0 .3.05 4.05.75 7.05 3.35V56.1q0-.15-.05-.35-.3-1.55-.55-3.05z"
                    id="ShiLon099__Symbol_389_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon099__Layer9_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon099__Symbol_539_0_Layer0_0_FILL"
                transform="translate(141.5 235.45)"
                id="ShiLon099__col1n"
            />
            <use
                xlinkHref="#ShiLon099__Symbol_501_0_Layer0_0_FILL"
                transform="translate(141.5 235.45)"
                id="ShiLon099__col1d"
            />
            <use
                xlinkHref="#ShiLon099__Symbol_468_0_Layer0_0_FILL"
                transform="translate(141.5 241.2)"
                id="ShiLon099__col1s"
            />
            <use
                xlinkHref="#ShiLon099__Symbol_442_0_Layer0_0_FILL"
                transform="translate(108 228.55)"
                id="ShiLon099__col2n"
            />
            <use
                xlinkHref="#ShiLon099__Symbol_411_0_Layer0_0_FILL"
                transform="translate(120.65 250.8)"
                id="ShiLon099__col2d"
            />
            <use
                xlinkHref="#ShiLon099__Symbol_389_0_Layer0_0_FILL"
                transform="translate(110 228.7)"
                id="ShiLon099__col2s"
            />
            <use xlinkHref="#ShiLon099__Layer9_0_FILL" />
        </g></g>
    )
}

export default ShiLon099
