import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function ObjToo005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.45 1Q4.6-2 0 4.05L8 20.3q3.95.4 7.8 3.3 2.95 2.3 4.5 5.1 2.05 4.9-.25 7.6-.5.5-1.05.9-1 .7-2.2 1.05l1.15 2.35q8.3 5.35 10.75-4.15L24.1 27q-2.15-.45-3.8-2.1-2.25-2.25-2.25-5.4 0-1.85.8-3.35L11.45 1z"
                    id="ObjToo005__Symbol_165_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M63.45 125.05q-.25 1.3-1.1 2.35-.15.1-.25.2.1.3.25.55.55.95 1.7.95 1.05 0 1.6-.8.4-.65.4-1.3 0-2-2-2-.35 0-.6.05M0 0q0 15.55 4.45 31.1 9.05 33.6 29.1 66.25l3.5-.9Q11.25 50.8 2.95 4.35q-.2-.25-.25-.4Q1.4 1.9 0 0z"
                    id="ObjToo005__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.251}
                    d="M66.65 184.95q-1.3-2.05-2.7-3.95 0 15.55 4.45 31.1 9.05 33.6 29.1 66.25l3.5-.9q-25.8-45.65-34.1-92.1-.2-.25-.25-.4z"
                    id="ObjToo005__Layer5_0_FILL"
                />
                <g
                    id="ObjToo005__Symbol_52_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M2.95 4.35q8.3 46.45 34.1 92.1l16.7-4.35Q30.4 45.5 2.95 4.35z" />
                    <path d="M2.95 4.35q-.2-.25-.25-.4Q1.4 1.9 0 0q0 15.55 4.45 31.1 9.05 33.6 29.1 66.25l3.5-.9Q11.25 50.8 2.95 4.35z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo005__Symbol_165_0_Layer0_0_FILL"
                transform="translate(106.25 272.1)"
                id="ObjToo005__col1n"
            />
            <use
                xlinkHref="#ObjToo005__Symbol_52_0_Layer0_0_FILL"
                transform="translate(63.95 181)"
                id="ObjToo005__col2n"
            />
            <use
                xlinkHref="#ObjToo005__Symbol_26_0_Layer0_0_FILL"
                transform="translate(63.95 181)"
                id="ObjToo005__col2l"
            />
            <use xlinkHref="#ObjToo005__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo005
