import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFE69A",
    "col2l": "#FFEAAD",
    "col2n": "#FFD446"
}
function AccJew053({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M95.35 20.9q11.5-7.25 17.45-12.05 6.05-4.75 8.6-8.05.15-.15.1-.35-.05-.2-.2-.35-.15-.15-.35-.1-.2.05-.35.2-2.55 3.2-8.55 7.95-6 4.7-17.6 12.05Q82.85 27.5 74 31.35q-5.95 2.55-9.75 3.8-3.5 1.25-7 0-3.85-1.25-9.75-3.8-8.85-3.85-20.45-11.15-11.6-7.35-17.6-12.05Q3.45 3.4.9.2.75.05.55 0 .35-.05.2.1.05.25 0 .45q-.05.2.1.35 2.55 3.3 8.6 8.05 5.95 4.8 17.45 12.05 11.5 7.2 20.6 11.2 4.3 1.85 7.45 3.05 3.45 1.3 5.55 1.8.15 0 .25.05v.75q.1.7.5 2.1.05.1.05.2v.1q-.25.8.05.1.25.85.2 0v-.45q0-.1.05-.15.55-1.55.65-1.9V37q.1-.05.25-.05 2.1-.45 5.6-1.8 3.15-1.2 7.4-3.05 9.1-4 20.6-11.2z"
                    id="AccJew053__Symbol_132_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.15 4.5q.6-.7.6-1.75 0-1.1-.75-1.85Q9.2.1 8.1.1 6.95.1 6.2.9q-.05 0-.05.05l-.1.1q-.6.65-.65 1.5-.05-.95-.75-1.7L4.6.8 4.4.6Q3.7 0 2.7 0 1.6 0 .8.8 0 1.6 0 2.7q0 .95.55 1.6l.1.1q.05.1.15.2l.25.25 4.45 4.3 4.3-4.3q.1-.1.2-.15.05-.1.15-.2z"
                    id="AccJew053__Symbol_133_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.65.65q-.25.25-.4.5Q0 1.65 0 2.2v.25q.05.6.45 1.05 0 .05.05.1l.15.15.2.2 3.6 3.5.05-.05-2.25-4.75q-.85-2 .9-2.4-.4-.25-.9-.25H2.2q-.15 0-.3.05Q1.2.1.65.65z"
                    id="AccJew053__Symbol_134_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew053__Symbol_132_0_Layer0_0_FILL"
                transform="translate(131.75 228.45)"
                id="AccJew053__col1n"
            />
            <use
                xlinkHref="#AccJew053__Symbol_133_0_Layer0_0_FILL"
                transform="matrix(1.16742 0 0 1.16742 186.2 264.25)"
                id="AccJew053__col2n"
            />
            <use
                xlinkHref="#AccJew053__Symbol_134_0_Layer0_0_FILL"
                transform="matrix(1.16742 0 0 1.16742 187.05 264.9)"
                id="AccJew053__col2l"
            />
        </g></g>
    )
}
export default AccJew053
