import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function BeaAni002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.75 11.85q.15 0 .25-.05.15-.15.15-.25 0-.15-.1-.25-.1-.15-.25-.15-23.1-1.55-38.15 8.2-.1.1-.15.25-.05.1.05.25.1.1.2.15.15.05.3-.05 14.85-9.65 37.7-8.1m-1.2-2.9q.15.05.25-.05.15-.1.2-.2.05-.15-.05-.25-.1-.15-.25-.2-25.75-6.3-43.45 4.85-.1.1-.15.25-.05.1.05.25.1.1.2.15.15.05.25-.05Q19.15 2.75 44.55 8.95m1.8-3.25q-.05-.15-.2-.15Q20.45-4.7.25 4.05q-.15.05-.2.2-.05.1 0 .25.1.15.2.2.15.05.3 0 20-8.6 45.35 1.5.15.05.3 0 .15-.1.15-.2.1-.15 0-.3M97.7 20q.1-.05.2-.15.1-.15.05-.25-.05-.15-.15-.25-15.05-9.75-38.15-8.2-.15 0-.25.15-.1.1-.1.25 0 .1.15.25.1.05.25.05 22.85-1.55 37.7 8.1.15.1.3.05m7.7-15.75q-.05-.15-.2-.2Q85-4.7 59.3 5.55q-.15 0-.2.15-.1.15 0 .3 0 .1.15.2.15.05.3 0Q84.9-3.9 104.9 4.7q.15.05.3 0 .1-.05.2-.2.05-.15 0-.25m-1.3 9.5q.1-.05.2-.15.1-.15.05-.25-.05-.15-.15-.25Q86.5 1.95 60.75 8.25q-.15.05-.25.2-.1.1-.05.25.05.1.2.2.1.1.25.05 25.4-6.2 42.95 4.75.1.1.25.05z"
                    id="BeaAni002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaAni002__Symbol_2_0_Layer0_0_FILL"
                transform="translate(139.8 205.3)"
                id="BeaAni002__col1n"
            />
        </g></g>
    )
}

export default BeaAni002
