import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M89.95 53.2q7.95 1.3 8.85 1.55.35.05.7.15 50.2 5.25 68.05-54.55-21.45 58.05-75.4 47.4-9.35-2.7-19.55 0Q18.65 58.4 0 0q15.05 60.15 65.25 54.9.35-.1 1-.25t8.2-1.45q7.55-1.35 15.5 0z"
                    id="BeaMus017__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus017__Symbol_34_0_Layer0_0_FILL"
                transform="translate(108.75 157.9)"
                id="BeaMus017__col1n"
            />
        </g></g>
    )
}

export default BeaMus017
