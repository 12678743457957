import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#FF98D0"
}
function FacMou093({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M22.45.1l.6-.1q-1.1.05-2.15.15Q11.3.8 1.6 2.1L0 2.5q.25 1.65.6 3.25l15.05 4.85L22.45.1m-8.1 9.35q0 .1.05.2l-.15-.15q.05-.05.1-.05m62.55-3.7q.35-1.6.6-3.25l-1.6-.4Q66.2.8 56.6.15 55.55.05 54.45 0l.5.1 6.85 12.65 15.1-7M63.25 9.5l-.15.15q.05-.1.05-.2.05 0 .1.05z"
                    id="FacMou093__Symbol_142_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M74.4 4q-1.2-.55-2.15-.55-1.2 0-2.55.85-.55.35-.8.35-.3-.55-1.65-1.65-1.35-1.1-3.75-1.25l-1.4.1q-1.75.3-2.85 1.05-.1.05-.2.15-.1-.25-.65-.7-1.25-1.05-2.15-1.3-1.1-.35-2.4-.55Q52.9.45 51.9.4 48.3.2 44.7.15q-2.9-.1-5.8-.1-.25-.05-.4 0-.1-.05-.2 0-.3-.05-.55 0-.05-.05-.1 0-3 0-6.05.1Q28 .2 24.4.4q-1 .05-1.95.1-1.3.2-2.4.55-.9.25-2.15 1.3-.55.45-.65.7-.1-.1-.2-.15-1.1-.75-2.85-1.05l-1.4-.1Q10.4 1.9 9.05 3T7.4 4.65q-.25 0-.8-.35-1.35-.85-2.55-.85-.95 0-2.15.55-.4.15-.7.4-.55.45-.85 1-.25.4-.35.85 0 .15.05.3 0 .05.05.1.05.35.15.7l.3 1.2v.1q0 .05.05.15 1 3.65 2.75 7.1.2.5.5 1l.2.4.5.9q0 .05.05.1v.05q.35.55.65 1 .05.1.1.25l.4.5q1.6 2.5 3.7 4.8l.25.25q.3.35.6.75 1.25 1.2 2.45 2.3.75.7 1.55 1.4.05.05.15.05 3.4 2.25 7.1 3.75 16.8 6.5 33.2 0 3.7-1.5 7.1-3.75.1 0 .15-.05.8-.7 1.55-1.4 1.2-1.1 2.45-2.3.3-.4.6-.75l.25-.25q2.1-2.3 3.7-4.8l.4-.5q.05-.15.1-.25.3-.45.65-1v-.05q.05-.05.05-.1l.5-.9.2-.4q.3-.5.5-1 1.75-3.45 2.75-7.1.05-.1.05-.15v-.1l.3-1.2q.1-.35.15-.7.05-.05.05-.1.05-.15.05-.3-.1-.45-.35-.85-.3-.55-.85-1-.3-.25-.7-.4m-11.75 6l-.15.15q.05-.1.05-.2.05 0 .1.05m-48.85.15l-.15-.15q.05-.05.1-.05 0 .1.05.2z"
                    id="FacMou093__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M62.6 7.65q-.15.65-.3 1.15-.55.85-2.2.85-1.3-.1-2.5-.1-.9.05-1.55-.05-.05-.05-.1 0-.05-.05-.1 0h-.05q-1.5-.15-1.55-1.35l.4-5.05-1.2 4.9q-.4 1.35-2.15 1.35h-.4q-.15-.05-.3-.05l-3.8-.1h-.2q-1.45 0-1.55-1.35l.1-3.35-.8 3.85q-.35.65-1.15.8-.35-.05-.7-.05v.05q-.1-.05-.25 0-3.2-.1-6.4-.1h-.05q-.65 0-1-.3L34.15 6l-.05-.05-.15.55v-.1l-.55 2.35q-.35.3-1 .3h-.05q-3.2 0-6.4.1-.15-.05-.25 0V9.1q-.35 0-.7.05-.8-.15-1.15-.8l-.8-3.85.1 3.35q-.1 1.35-1.55 1.35h-.2l-3.8.1q-.15 0-.3.05h-.4q-1.75 0-2.15-1.35l-1.2-4.9.4 5.05q-.05 1.2-1.55 1.35h-.05q-.05-.05-.1 0-.05-.05-.1 0-.65.1-1.55.05-1.2 0-2.5.1-1.65 0-2.2-.85-.15-.5-.3-1.15Q4.9 5 3.6 0q.1 2.85 1.1 8.2v.2q0 1.2-1.35 1.45-1.65.1-3.35.2l.5.9q0 .05.05.1 1.5-.1 3-.15.4-.05.85-.05.6.1.85.8l1.15 6.2-.05-5.45q.05-1.45 1.1-1.7h.75l4.2-.2h.2q1.3 0 1.55.95l.45 4.8.4-4.85q.2-.95 1.45-1h.1q.1-.05.2 0 1-.1 1.9-.1 1.45-.05 2.9-.05h.25q.15-.05.35 0 .8 0 1.05.7l.35 3.1.35-3.15q.4-.75 1.65-.75H25.85q3.3-.05 6.65-.05h.5q.35.05.55.3l.4 1.85v-.15l.15.5-.05.5.6-2.7q.2-.25.55-.3h.5q3.35 0 6.65.05H42.65q1.25 0 1.65.75l.35 3.15.35-3.1q.25-.7 1.05-.7.2-.05.35 0h.25q1.45 0 2.9.05.9 0 1.9.1.1-.05.2 0h.1q1.25.05 1.45 1l.4 4.85.45-4.8q.25-.95 1.55-.95h.2l4.2.2h.75q1.05.25 1.1 1.7l-.05 5.45 1.15-6.2q.25-.7.85-.8.45 0 .85.05 1.5.05 3 .15.05-.05.05-.1l.5-.9q-1.7-.1-3.35-.2Q63.5 9.6 63.5 8.4v-.2q1-5.35 1.1-8.2-1.3 5-2 7.65z"
                    id="FacMou093__Symbol_140_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou093__Symbol_142_0_Layer0_0_FILL"
                transform="translate(153.8 207)"
                id="FacMou093__col2n"
            />
            <use
                xlinkHref="#FacMou093__Symbol_141_0_Layer0_0_FILL"
                transform="translate(154.4 206.5)"
                id="FacMou093__col1n"
            />
            <use
                xlinkHref="#FacMou093__Symbol_140_0_Layer0_0_FILL"
                transform="translate(158.45 213.75)"
                id="FacMou093__col1s"
            />
        </g></g>
    )
}
export default FacMou093
