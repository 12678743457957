import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00006D"
}

function AccMor057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 32.1q3.35-.05 27.05-8.9 51.35-16.35 102.65 0 22.1 7.45 32.8 8.9Q77.2-32.1 0 32.1z"
                    id="AccMor057__Symbol_154_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor057__Symbol_154_0_Layer0_0_FILL"
                transform="translate(111.25 72.55)"
                id="AccMor057__col1n"
            />
        </g></g>
    )
}

export default AccMor057
