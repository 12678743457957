import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF9AFF",
    "col1n": "#FFC6FF",
    "col1s": "#EE51EE"
}

function ShiLon020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 48.75q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.4-1.3-.75-2.6-.4-1.35-.85-2.7-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-.1-.15-.15-.3-.9-2.15-1.85-4.2l-1-2.1q-.05-.15-.15-.3-.2-.45-.4-.85-.1-.25-.2-.45h1.55q3.65-1.55 3.7-6.3 0-5.2-2.75-9.3-4.35-6.15-10.2-1.95-.4.05-3.15 2.95-.15.1-.25.25v.15l-.3.2q-1.35 1.05-2.8 2.1l-1.55 1.15q-5.95 4.1-12.3 7.2-14.4 6.9-30.95 8.6l-10.85 12-11-12.1q-2.2-.25-4.4-.55-10.35-1.6-19.85-5.3-9.65-3.85-18.35-9.85L27.1 7.35q-1.45-1.05-2.8-2.1l-.1-.05v.1q-3.1-3.15-4.05-3.9-.25-.2-.45-.4-5.4-2.85-9.3 2.65-2.75 4.1-2.75 9.3.05 5.1 4.15 6.7h.95q-.15.25-.25.5-.2.4-.4.85-.1.1-.1.25-.45.85-.85 1.7-.8 1.75-1.55 3.55-1.2 2.75-2.25 5.7l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05L143 61.3q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V60.05q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon020__Symbol_294_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M250.25 245.6q-3.6-.95-5.25-1-1.65-.05-4.3 0-2.7.05-5.75 1.2-3.1 1.15-7.15 3.1-2.45 1.05-3.55 1.3-1.1.25-1.65.35-.6.1-2.05.4-1.45.3-1.6.25-5.55.7-14.6 5-.075.037-.15.05-1.193.61-2.3 1.15 5.103-2.309 8.35-3.1 4.65-1.15 11.95-1 6.75.1 10.3-2.6.758-.588 1.6-1.1 3.161-1.31 4.5-1.6.773-.193 3.6-.2 0-.55 3.7.45 2.2.6 8.1 2.35 2.05.55 6.85.95 2.426.218 4.85.3-.9-2.097-1.85-4.15-.563-1.225-1.15-2.45-.027.06-.05.1-.19.051-.4.1-2.726.713-5.3.9-3.15.15-6.7-.75m4.25-18.85q-.213-.144-.1-.3-.39.066-3.15 2.9-.134.163-.25.25v.15l-.3.25q-1.35 1.05-2.8 2.1l-1.55 1.15q-5.944 4.108-12.35 7.15 1.483-.064 3.8-.2 6.55-.65 9.3-1.3 2.194-.533 3.9-1.25 2.455-.993 3.9-2.35 2.1-2.05 2.1-5.05 0-1.8-2.5-3.5m-130.2 20.7q-.797-.336-2.35-1.5-.4.879-.8 1.75-.801 1.731-1.55 3.5 1.208.771 3.1 1.8 1.1.65 4.95 1.1 2.9.3 4.9.3 2.647 0 4.15-.45 1.509-.4 5.1-1.2 3.591-.8 4.6-.85 1.053 0 5.7-.4 9.7.107 21.5 2.4 7.256 1.42 11.4 3.05-.362-.25-.75-.5-2.352-1.555-4.45-2.3-.533-.168-1.05-.3-6.55-2.2-10.75-3.8-4.25-1.6-6.05-2.2-8.95-2.1-18.15-1.85-1 .1-1.95.25-4.4.65-8.35 1.8-3.95 1.15-5.6.7-1.7-.45-2.2-.65-.5-.25-1.4-.65m14.35-14.2l-1.55-1.15q-1.45-1.05-2.8-2.1l-.1-.1v.15q-3.093-3.162-4.05-3.95-.437.324-.8 1.05-.65 1.2-.65 2.6 0 2.95 4.75 5.8.363.22.75.45 4.626 2.593 12.7 4.9 4.408 1.269 10.1 2.15-9.635-3.777-18.35-9.8z"
                    id="ShiLon020__Symbol_263_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M15.35 20.55q-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35m110.3 0q-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15M137.7 1.5q-1.85.35-3.95.3-2.15-.1-5.65-.85-1.3-.3-2.35-.5-4.85-.85-10 0-1.5.3-3.2.95-3.4 1.25-6.25 2.6-2.9 1.3-4 1.55-1.1.2-3.45.75-2.4.55-5.6 1-2.85.4-11.05 4.35-1 .45-2.1 1-4.3 2.1-9.8 5.15-3.5-2.6-6.45-5-2.8-2.3-6.05-3.15-.15-.05-.35-.1-7.2-2.55-11.4-4.1-4.2-1.6-5.85-2.1-9.1-2.15-18.45-1.9h-.05q-1 .1-2 .25-4.45.65-8.1 1.75-3.65 1.1-5.65.65Q4.2 3.65.75 1.55q-.15-.1-.25-.2-.2.4-.4.85-.1.1-.1.25 1.5 1.1 2.3 1.45.9.4 1.4.65.5.2 2.2.65 1.65.45 5.6-.7 3.95-1.15 8.35-1.8.95-.15 1.95-.25Q31 2.2 39.95 4.3q1.8.6 6.05 2.2 4.2 1.6 10.75 3.8.5.15 1.05.35 2.1.7 4.45 2.25l.8.5q2.45 1.7 4.6 3.5 2.45 2 2.8 1.95.35-.1 3.6-1.9 1.65-.9 3.8-2 1-.55 2.1-1.1 1.05-.55 2.25-1.1.05-.05.15-.1 9.05-4.3 14.6-5 .15.05 1.6-.25 1.45-.3 2.05-.4.55-.1 1.65-.35 1.1-.25 3.55-1.3 4.05-1.95 7.15-3.1 3.05-1.15 5.75-1.2 2.65-.05 4.3 0t5.25 1q3.55.9 6.7.75 2.55-.2 5.35-.9.15-.05.35-.1 0-.05.05-.05-.2-.45-.4-.85-.1 0-.15.05-.65.2-2.45.55z"
                    id="ShiLon020__Symbol_231_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M247.65 264.1q-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15m-111-.15q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1z"
                    id="ShiLon020__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon020__Symbol_294_0_Layer0_0_FILL"
                transform="translate(110 224.75)"
                id="ShiLon020__col1n"
            />
            <use
                xlinkHref="#ShiLon020__Symbol_263_0_Layer0_0_FILL"
                id="ShiLon020__col1d"
            />
            <use
                xlinkHref="#ShiLon020__Symbol_231_0_Layer0_0_FILL"
                transform="translate(122 243.55)"
                id="ShiLon020__col1s"
            />
            <use xlinkHref="#ShiLon020__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon020
