import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E92424",
    "col1n": "#FF5D5D"
}

function FacMou064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.7 11.75V0h-7l-.05.05q-.05 0-.1.05-.05 0-.05-.05L10.45 0H0v9.9q.15 3.7 1.3 5.85l.2.3q1.4 2 3.85 2.45l.8.1q.2 0 .45.05h.15q2.2.15 4.4 0h.2q.2-.05.4-.05t.4-.05q.15 0 .35-.05l.5-.1q.45-.1.9-.3 1.7-.75 2.8-2.4.8-1.55 1-3.95z"
                    id="FacMou064__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.7.05L2.75 0H.7l.05.25V13.5q.05.85.3 1.25.8.75 1.5 0 .1-.25.1-.55V.25.1q0-.05.05-.05z"
                    id="FacMou064__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    d="M206.2 226.15q.25-.3.25-.7 0-.4-.25-.65-.3-.3-.7-.3h-26q-.4 0-.65.3-.3.25-.3.65t.3.7q.25.25.65.25h26q.4 0 .7-.25z"
                    id="FacMou064__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou064__Symbol_89_0_Layer0_0_FILL"
                transform="translate(183.45 226.1)"
                id="FacMou064__col1n"
            />
            <use
                xlinkHref="#FacMou064__Symbol_12_0_Layer0_0_FILL"
                transform="translate(190.7 226.2)"
                id="FacMou064__col1d"
            />
            <use xlinkHref="#FacMou064__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou064
