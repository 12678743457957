import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#2B3C4A",
    "col1s": "#07090B",
    "col2n": "#006AC9",
    "col2s": "#00284D"
}

function ShiLon037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M220.2 245.8q.03-.036 0-.1-8.937 2.647-18.65 3.45-.524.06-1.05.1l-1.1.05q-2.921.174-5.95.2-.315-.1-.8-.1-.502 0-.85.1-3.146-.018-6.2-.2l-1.1-.05q-3.884-.29-7.65-.9-5.082-.747-9.95-2.05-.721-.167-1.45-.4.458 1.165 1.05 2.3.593 1.581 1.4 3.3l.05.05q.5 1.2 1.25 2.5.4.6.7 1.2 5.2 9.85 12.7 18.85.6.85 1.4 1.6.2.35.5.75.474.474.95 1-.15.283-.15.85 0 .55 1.15 1.9.6.75 1.6 1.85.3.35 1.3 2.1 1.15 1.55 2.45 1.55 2.3 0 7.65-7.35-.013-.279-.1-.5l.35-.35q.8-.9 1.55-1.8.6-.75 1.35-1.6 2.55-3.1 4.9-6.3 4.4-6.1 7.8-12.55.4-.6.7-1.2.75-1.3 1.3-2.5.212-.424.4-.85.599-1.213 1.15-2.5.5-1.2 1-2.3l.35-.1m-22.3 11.5q-.048 0-.05.2-.005-.203-.15-.3l.2.1m2.9-3.45l.3.2h-.4l.1-.2m-13.25 3.35q-.098.098-.1.3-.002-.2-.15-.2l.25-.1m-3.35-3.2v.05h-.05l.05-.05z"
                    id="ShiLon037__Layer20_0_FILL"
                />
                <path
                    fill="#E0E0E0"
                    d="M166.95 246.5q-.047-.093-.05-.2-.721-.167-1.45-.4.463 1.16.75 6.25.133 1.819.35 2.25.391 1.346 1.4 4.15 2.744 7.442 9 11.9 6.251 4.505 7.05 5.25.2.35.5.75.75.75 1.5 1.6v-.15q-.35-.5-.7-.9-.4-.5-.8-.95l-.1-.3q0-.05-.05-.15-.1-.15-.2-.25-6.15-8.85-8.85-17.7 4-.25 8.85-2.25l.9-.45h.15l1.5-.75-2.5-.15-1.9.9q-.35.15-.5.2-.15.1-.25.1-2.5 1.15-5.9 1.3-.45-.1-.85 0-3.15-.15-4.3-2.65v-.1q-.646-1.09-1.2-2.2-.844-1.55-1.5-3.1-.55-1-.85-2m.95 5.05q.016-.011 0-.05l.05.05q-.022.013-.05 0m51.95-5.65q-.8.2-1.5.4-.35 1.15-.85 2.2-.572 1.239-1.25 2.5-.712 1.387-1.55 2.8v.1q-.9 2.3-4.3 2.65-.4-.1-.85 0-3.4-.15-5.9-1.3-.05 0-.2-.1-.15-.05-.55-.2l-1.9-.9h-.3l-2.2.3 1.75.6q.45.2.75.3 5.25 2.25 9.4 2.4h.05q-2.95 8.9-9.35 17.8l-1 1.35-.9 1.1v.15q.25-.3.5-.55.8-.9 1.55-1.8.6-.75 1.35-1.6 2.556-3.097 7-6.55 4.477-3.414 6.85-7.95 1.797-3.34 1.3-4.4.146-.657.3-1.45 1.303-6.747 1.8-7.85m-2.7 8.8h-.05.05z"
                    id="ShiLon037__Layer20_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-12.15 8.4-26.1 12.5v.05l-.4.1q-.5 1.1-1 2.3-.55 1.3-1.1 2.5-.25.45-.45.85-.55 1.2-1.3 2.5-.3.6-.7 1.2-3.4 6.45-7.8 12.55-2.35 3.2-4.9 6.3-.75.85-1.35 1.6-.75.9-1.55 1.8-.25.25-.5.55l-.1.15q-.2.3-.6.6-.05.15-.3.2-.05.25-.35.55-.15.15-.4.3-1.35 1.5-2.9 3.25-.05 0-.05.1l-1.75 1.7h-.15q-.15 0-.25.05-.2-.2-.35-.25-2.2-2.6-4.25-4.85-.2-.15-.3-.3-.35-.3-.45-.55-.2-.05-.3-.2l-.1-.1q-.35-.25-.45-.5l-.15-.15q-.75-.85-1.5-1.6-.3-.4-.5-.75-.8-.75-1.4-1.6-7.5-9-12.7-18.85-.3-.6-.7-1.2-.75-1.3-1.25-2.5v-.05q-.85-1.7-1.45-3.3-.6-1.15-1.05-2.25Q42.2 13.6 30.6 5.95q-.8-.5-1.55-1.05-.2-.1-.4-.25L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon037__Symbol_308_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M21.65 63.45V55.2l-19 2.4-2 8.5 21-2.65m-.85-7.2v6.45L1.95 64.95l1.4-6.55 4.2-.5q0 .05-.3.1l13.55-1.75M5.15 30.75q-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L0 52.35v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35M120.1 66.1l-2-8.5-19-2.4v8.25l21 2.65m-6.6-8.1q-.3-.052-.3-.1l4.2.5 1.4 6.55-18.85-2.25v-6.45L113.5 58m2.9-27.1q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-28.85 7.8v6.9l21.1-4.15v-6.6l-21.1 3.85m.75 5.9l-.15-5.15 19.9-3.45v4.9L88.3 44.6m24.45-41.1q.25-.4.3-.85.5-1.2.95-2.65l-1.6 1.1q-.3 1.3-.6 2.35-.4 1.05-.8 1.85-.6 1.9-1.05 2.8l-15.5 5.4 9.7 4.7q-1.55 1.9-3.1 3.7-4.55 4.9-9.8 9.45-1.9 1.6-3.7 3.1-4.55 3.65-8.4 6.3-.3.3-.7.5-5.6 4.05-11.35 7.2-.3.1-.65.3-.1 0-.3.15-.3.15-.6.4-1.85 1.05-3.9 2.05-.35.2-.6.3l.1-.25q-.15.1-.3.15H60.7q-.1 0-.2.05 0-.05-.05-.05h-.15q-.15 0-.25.05h-.1q-.3-.1-.5-.2l.15.25q-.3-.25-.65-.3-2-1.05-3.8-2.05-.35-.25-.65-.4-.25-.15-.35-.15-.3-.2-.65-.3-.6-.45-1.3-.75-5-2.85-10-6.45-.45-.2-.75-.5-5.45-3.85-12.1-9.4-5.25-4.4-9.6-9.45-1.75-1.8-3.25-3.7l9.65-4.7-15.5-5.4q-.45-.9-1.05-2.8-.45-.8-.7-1.85-.4-1.05-.6-2.25Q7.5.7 6.75.15q.3 1.35.8 2.45.05.5.3.9 1 2.8 1.5 4.1l.6 1.3 1.1.45L23.9 13.5l-8.7 4.45q.15.15.2.25.25.3.45.65 0 .1.1.15.25.4 2.35 2.9.6.7 1.2 1.5 3.1 3.85 9.85 9.3 5.2 4.35 10.3 8.05 1.3 1.05 2.65 2.1 3.95 2.95 9.9 6.35.4.25.75.4.25.15 1.55.9.3.1 1.55.85.15.05.25.05l.2.15q.25.1.55.3l.4.2 2.15.75.1 31.35h1.2V62.6h-.1v-9.65l.7-.25v-.05q.4-.1.85-.25l.1-.05q.35-.15.75-.3l.5-.2q0-.05.1-.05.05-.15.3-.25l.2-.15q.1 0 .25-.05 1.3-.75 1.6-.85l1.5-.9q6.45-3.65 10.65-6.75 1.45-1.05 2.8-2.1 3.95-2.95 8.05-6.3 1.05-.8 2.1-1.75 6.75-5.45 9.85-9.3.7-.8 1.3-1.5 2.05-2.5 2.25-2.9.1-.05.1-.15.2-.35.45-.65.05-.1.2-.25l-8.7-4.45 12.9-4.15 1.05-.45.6-1.3q.55-1.3 1.5-4.1z"
                    id="ShiLon037__Symbol_243_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.3 8q.002-.202.1-.3l-.25.1q.148 0 .15.2m10.25-.3q.145.097.15.3.002-.2.05-.2l-.2-.1m3-3.15h.4L8.9 0H8L0 4.55h.05l2.5.15.6.15.45.9q.25.65 1.25 1.1.15.05.35.1l.25.75q0 .25.05.5V9L3.6 30.35q2.05 2.25 4.25 4.85.15.05.35.25.1-.05.25-.05h.15l1.75-1.7q0-.1.05-.1 1.55-1.75 2.9-3.25L11.4 9q0-.4.05-.8 0-.25.05-.5.15-.45.2-.75 1.3-.45 1.6-1.2.25-.4.45-.9h.6l2.2-.3z"
                    id="ShiLon037__Symbol_207_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M2.55 1.7l.6.15V1.7q0-.1-.05-.3Q2.85.65 2.85 0L0 1.55h.05l2.5.15m8.95 3q.15-.45.2-.75-3.25.45-6.5 0l.25.75q0 .25.05.5 1.85.55 5.95 0l-2.15.05.2-.05h1.95q0-.25.05-.5m5.05-3.15h.4L14.05 0q-.05.8-.2 1.55-.1.05-.1.15v.15h.6l2.2-.3z"
                    id="ShiLon037__Symbol_166_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFC400"
                    d="M186.8 274.25v1.35h6.7v-1.35h-6.7z"
                    id="ShiLon037__Layer11_1_FILL"
                />
                <g id="ShiLon037__Layer11_0_FILL">
                    <path
                        fill="#FFC400"
                        d="M196.15 303.75q.7.6 1.55.6.8 0 1.4-.6.6-.6.6-1.4 0-.85-.6-1.55-.6-.5-1.4-.5-.85 0-1.55.5-.5.7-.5 1.55 0 .8.5 1.4m2.95-14.15q-.6-.5-1.4-.5-.85 0-1.55.5-.5.7-.5 1.55 0 .8.5 1.35.7.65 1.55.65.8 0 1.4-.65.6-.55.6-1.35 0-.85-.6-1.55z"
                    />
                    <path
                        fill="#FFF"
                        d="M223.7 268.55v2.9l15.6-2.9v-3.25l-15.6 3.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiLon037__Layer20_0_FILL" />
            <use xlinkHref="#ShiLon037__Layer20_1_FILL" />
            <use
                xlinkHref="#ShiLon037__Symbol_308_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon037__col1n"
            />
            <use
                xlinkHref="#ShiLon037__Symbol_243_0_Layer0_0_FILL"
                transform="translate(132.3 233.35)"
                id="ShiLon037__col1s"
            />
            <use
                xlinkHref="#ShiLon037__Symbol_207_0_Layer0_0_FILL"
                transform="translate(184.15 249.5)"
                id="ShiLon037__col2n"
            />
            <use
                xlinkHref="#ShiLon037__Symbol_166_0_Layer0_0_FILL"
                transform="translate(184.15 252.5)"
                id="ShiLon037__col2s"
            />
            <use xlinkHref="#ShiLon037__Layer11_0_FILL" />
            <use xlinkHref="#ShiLon037__Layer11_1_FILL" />
        </g></g>
    )
}

export default ShiLon037
