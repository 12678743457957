import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou055({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.2 17.4q.45-5.05-1.8-8.7Q36.7 1.95 27.6.45 24.95 0 21.95 0h-.6q-3 0-5.65.45Q6.6 1.95 1.9 8.7-.35 12.35.1 17.4q.25 3.1 2.9 5.05.25.1.45.2 6.2 3.55 12.6 1.25.75-.55 1.55-1l.1-.1q.1 0 1.35-.6.95-.45 2.3-.55h.6q1.35.1 2.3.55 1.25.6 1.35.6l.1.1q.8.45 1.55 1 6.4 2.3 12.6-1.25.2-.1.45-.2 2.65-1.95 2.9-5.05z"
                    id="FacMou055__Symbol_80_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou055__Symbol_80_0_Layer0_0_FILL"
                transform="translate(170.65 212.25)"
                id="FacMou055__col1n"
            />
        </g></g>
    )
}

export default FacMou055
