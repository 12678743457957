import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400"
}

function AccMor028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.65 1.3q0-.45-.3-.8-.45-.5-1.3-.5h-.1l-6.7 1.7q-.5.05-.75.1l-.5.15q-1.1.25-2.25.5-.6.1-1.25.25-2.75.6-5.5 1.15-4.85.9-9.75 1.65-1.35.2-2.75.4-2 .25-3.95.5-.25.05-.45.05-1.85.2-3.75.4-.15 0-.25.05-1.65.15-3.35.3h-.1q-2.35.2-4.75.4-.45.05-.9.05-5.2.35-10.4.45-1.55.05-3.1.05l-3.2-.05h-.75q-1.1 0-2.15.05-7.8 0-15.55-.5-.45 0-.9-.05-2.45-.2-4.85-.4l-1.2-.1q-.6-.05-1.15-.1l-1-.1q-.1-.05-.25-.05-1.65-.15-3.25-.35H37q-.15-.05-.25-.05-2.2-.25-4.4-.55-1.4-.2-2.75-.4-3.9-.6-7.8-1.3-.95-.15-1.95-.35-2.75-.55-5.5-1.15-.65-.15-1.25-.25-1.15-.25-2.25-.5l-.5-.15q-.3-.05-.6-.15H9.7v.05L2.9 0h-.1q-.85 0-1.3.5-.25.3-.3.7v.1q.1 1.45 1.25 1.55l-.8 5H1.6q-.25-.05-.55.05-.2.05-.35.15-.5.3-.65.9-.05.15-.05.4 0 .05.05.1.25 1.1 1.15 1.2h.1l1.2.45h-.05l.1.1q.1 0 .15.05.05 0 .15.05.1 0 .25.05 1.7.45 3.5.9.35.1.8.2.35.05.7.15.65.15 1.3.35h.05q.1 0 .2.05 1.25.25 2.5.6.15 0 .3.05.3.05.55.1.85.15 1.7.35 1.65.35 3.35.75.8.15 1.65.3 2.45.45 4.85.9.1 0 .25.05l1.4.2q.7.1 1.4.25 1.2.2 2.5.4l.9.1q.4.05.85.15.2 0 .45.05.55.05 1.15.15l2.3.3q.2 0 .4.05.55.05 1.1.15 2.25.2 6.85 1.3l-1.2-.2q23.1 5.85 47.75 0l-.25.1q4.3-.9 8.3-1.35.2-.05.4-.05l2.3-.3q.6-.1 1.15-.15.25-.05.45-.05.45-.1.85-.15l.9-.1q1.3-.2 2.5-.4.7-.15 1.4-.25l1.4-.2q.15-.05.25-.05 2.4-.45 4.85-.9.85-.15 1.65-.3 1.7-.4 3.35-.75.85-.2 1.7-.35.25-.05.55-.1.1 0 .2-.05.5-.1 1-.25.8-.2 1.6-.35.1-.05.2-.05h.05q.65-.2 1.3-.35.75-.2 1.5-.35 1.05-.25 2.05-.5.75-.2 1.45-.4.1-.05.15-.05l-.2.1q.15-.05.25-.1h.05q.1-.05.15-.05.05-.05.15-.05l.05-.05q1.25-.5 1.3-.5.85-.1 1.15-1.1.05-.1.05-.2v-.3q-.15-.85-1.05-1.15h-.05q-.1-.05-.2-.05-.2-.05-.35 0l-.8-4.95v-.05h.05q1-.1 1.2-1.35v-.2z"
                    id="AccMor028__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M133.6.3V.1l-7.55 1.85.35-.1-2.35.5q-.35.1-.85.15-1.75.35-2.5.55-1.75.35-3.55.75-1.1.2-2.2.45-1.4.25-2.8.55-4.8.85-9.7 1.5-2.1.3-4.25.55-1.95.25-3.9.45h-.25q-1.65.15-3.35.3h-.1q-2.25.2-4.5.35-.6.05-1.15.1-3.15.2-6.35.3-3.55.1-7.15.15h-6.1q-4.6 0-9.2-.15-3.2-.1-6.35-.3-.55-.05-1.15-.1-2.3-.15-4.6-.35l-1.2-.1q-.6-.05-1.15-.1l-1-.1h-.25q-1.6-.15-3.25-.35-.3-.05-.65-.1-2.15-.25-4.25-.55-4.9-.65-9.7-1.5-.6-.15-1.2-.25Q10.3 2.35 1.15 0v.1q.1 1.45 1.25 1.55 11.15 2.85 18.8 4l-.05.05 1.1.2q5 .9 10.05 1.6 2.05.25 4.15.5.35.05.75.1 1.1.15 2.2.25.5.05 1.05.15h.25l1 .1q.55.05 1.15.15l1.2.1q2.2.2 4.4.35.65.05 1.35.1 7.75.45 15.55.45h6.1q6.75-.05 13.5-.45.7-.05 1.35-.1 2.15-.15 4.3-.35h.1q1.7-.2 3.35-.35h.25q.55-.1 1.05-.15 1.5-.15 2.95-.35 2.1-.25 4.15-.5 5.05-.7 10.05-1.6 1.25-.2 2.45-.45 1.3-.25 2.55-.5l2-.4 1.4-.3 11.45-2.55v-.05h.05q1-.1 1.2-1.35m1.2 7.55q-.15-.85-1.05-1.15h-.05q-.1-.05-.2-.05-4.6 1.5-11.1 3V9.6l-.55.15q-.1 0-.25.05-.1 0-.25.05-.15 0-.35.05-.85.2-1.75.4-1.6.3-3.25.65h-.05l-1 .2-.7.1q-2.55.5-5.1.95-1.3.2-2.65.4-2 .3-4.05.6-.2 0-.4.05-.2 0-.45.05-.75.15-1.5.25-.65.05-1.25.15-2.3.25-4.55.5-1.85.2-4.6.85l.9-.25q-24.65 5.85-47.75 0l-6.95-1.1h-.15l-.45-.1h-.1q-.25-.05-.55-.05-.75-.1-1.5-.25-.25-.05-.45-.05-.2-.05-.4-.05-2.05-.3-4.05-.6-1.35-.2-2.65-.4l-4.5-.8q-.15-.05-.25-.05-11.3-2.25-19.3-4.7Q1.3 6.6 1 6.7q-.2.05-.35.15-.5.3-.65.9 8.8 2.7 20.4 5.1l-.05.05q2.4.4 4.75.85.6.1 1.25.2.8.1 1.55.25 1.9.3 3.85.55l.7.1.8.1q1.1.15 2.25.3.1 0 .3.05.7.05 1.4.15.5.05.95.1 1.8.2 3.55.4.6.05 3.05.6l-1.9-.25q23.1 5.85 47.75 0l-1.25.1q4.25-.5 7.25-.85 1.15-.15 2.35-.25.2-.05.3-.05 1.15-.15 2.25-.3l.8-.1.7-.1q1.95-.25 3.85-.55.75-.15 1.55-.25.65-.1 1.25-.2 2.55-.5 5.1-.95.85-.2 1.65-.35h.05q1.65-.35 3.25-.65h.05l1.7-.4q.35-.1.65-.15h.2q.15-.05.35-.1l.4-.1-.55.2q7.2-1.7 12.3-3.4z"
                    id="AccMor028__Symbol_77_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor028__Symbol_126_0_Layer0_0_FILL"
                transform="translate(125.1 298.65)"
                id="AccMor028__col1n"
            />
            <use
                xlinkHref="#AccMor028__Symbol_77_0_Layer0_0_FILL"
                transform="translate(125.15 299.85)"
                id="AccMor028__col1d"
            />
        </g></g>
    )
}

export default AccMor028
