import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor074({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor074__Layer10_0_FILL">
                    <path
                        fill="#FF0"
                        d="M236.45 270.3q-8.85-1.862-17.7 0 .006 1.153.25 2.2 8.798.89 17.15 0 .294-1.047.3-2.2z"
                    />
                    <path
                        fill="#0040D4"
                        d="M221.3 276.5q.274.263.55.5 5.92.976 11.45 0 .287-.237.55-.5.822-.822 1.35-1.75-7.612-1.387-15.25 0 .543.928 1.35 1.75z"
                    />
                    <path
                        fill="red"
                        d="M236.2 268.05q-.307-1.194-.95-2.25-7.65-.882-15.35 0-.6 1.057-.9 2.25 8.699 1.97 17.2 0z"
                    />
                    <path d="M228.65 261.45h-.45l-.15-.05H228q-.086.002-.2 0h-.45q-.24.006-.5 0v.05h-.3q-2.628.294-4.75 2.1 6.08 1.654 11.55 0-2.025-1.795-4.7-2.1z" />
                </g>
                <g id="AccMor074__Layer10_1_FILL">
                    <path
                        fill="#841D9A"
                        d="M227.6 279.1h.2l.05-.05q.248.025.45 0 2.851-.186 5-2.05h-11.45q2.22 1.854 5 2.05.26.044.5.05h.25z"
                    />
                    <path
                        fill="#008E29"
                        d="M219 272.5q.317 1.193.95 2.25h15.25q.674-1.057.95-2.25H219z"
                    />
                    <path
                        fill="#F90"
                        d="M236.45 270.3v-.05q0-1.132-.25-2.15v-.05H219q-.25 1.048-.25 2.2v.05h17.7z"
                    />
                    <path
                        fill="#82491E"
                        d="M235.25 265.8q-.556-.956-1.4-1.8-.236-.236-.5-.45H221.8q-.255.214-.5.45-.828.845-1.4 1.8h15.35z"
                    />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor074__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor074__Layer10_0_FILL" />
            <use xlinkHref="#AccMor074__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor074__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor074__col1l"
            />
        </g></g>
    )
}

export default AccMor074
