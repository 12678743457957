import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A19CCD",
    "col1s": "#7973B7"
}

function SkiWou009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M51.55 3.8l-.1.1q-8.45 5.3-19.3 6-2.5.2-5 .15-.65-.1-1.3-.15Q9.35 8.25 3 1.05 1.5-.5.4.45q-1.1 2.4.95 4.5l.6 2.85-.05-.85.3 1q1.35 4.75 4.1 8.15.65.8 1.35 1.55 2.15 2.2 4.7 3.7 5.85 3.45 13.6 2.85 10.25-.7 18.25-8.1.6-.55 1.2-1.15 2-2.05 3.9-4.8.3-.6.5-.95.05-.15.1-.2.05-.05.15-.1.15-.1.35-.15.7-.4.9-.5 2.45-.95 2.8-2.5.45-1.25-.1-1.95-1-1.05-2.45 0m50.9 12.2q2.75-3.4 4.1-8.15l.3-1-.05.85.6-2.85q2.05-2.1.95-4.5-1.1-.95-2.6.6Q99.4 8.15 82.9 9.8q-.65.05-1.3.15-2.5.05-5-.15-10.85-.7-19.3-6l-.1-.1q-1.45-1.05-2.45 0-.55.7-.1 1.95.35 1.55 2.8 2.5.2.1.9.5.2.05.35.15.1.05.15.1.05.05.1.2.2.35.5.95 1.9 2.75 3.9 4.8.6.6 1.2 1.15 8 7.4 18.25 8.1 7.75.6 13.6-2.85 2.55-1.5 4.7-3.7.7-.75 1.35-1.55z"
                    id="SkiWou009__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M0 .1l.6 2.85-.05-.85q8.7 7.35 22.35 6.65Q9.15 7.55 1.35 1.2.65.65.05.1H0m31.2 14.4q.2-.05.3-.25.1-.15 0-.35-.1-.2-.3-.35-.15-.05-.35.05-7.55 3-17.75-.9-.15-.05-.35.05-.2.1-.3.25-.05.2.05.4t.25.25q10.6 4.05 18.45.85M48.5 4.1l-.1.1q-7 2.9-14.9 4.25Q42 8.9 47.95 5.3q.3-.6.5-.95.05-.15.1-.2.05-.15-.05-.05m9.05-.1q-.1-.1-.05.05.05.05.1.2.2.35.5.95 5.95 3.6 14.45 3.15Q64.65 7 57.65 4.1l-.1-.1m35.75 9.55q.15-.05.25-.25t.05-.4q-.1-.15-.3-.25-.2-.1-.35-.05-10.2 3.9-17.75.9-.2-.1-.35-.05-.2.15-.3.35-.1.2 0 .35.1.2.3.25 7.85 3.2 18.45-.85M106.05 0H106q-.6.55-1.3 1.1-7.8 6.35-21.55 7.55Q96.8 9.35 105.5 2l-.05.85.6-2.85z"
                    id="SkiWou009__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou009__Symbol_15_0_Layer0_0_FILL"
                transform="translate(138.15 169.8)"
                id="SkiWou009__col1n"
            />
            <use
                xlinkHref="#SkiWou009__Symbol_16_0_Layer0_0_FILL"
                transform="translate(139.5 174.65)"
                id="SkiWou009__col1s"
            />
        </g></g>
    )
}

export default SkiWou009
