import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF0000",
    "col1n": "#C40000",
    "col2n": "#FFFF00"
}

function ObjMor084({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.1 11.15q0-1.05-.65-1.8-.05-.05-.1-.05-.35-.4-.8-.55-.1-.05-.25-.05l-.4-.15L.95 20.1q-.15.1-.3.25-.65.75-.65 1.8 0 1 .65 1.75.7.75 1.65.75h.1l25.75-11.4.15-.2q.1-.05.15-.1.3-.35.45-.75.2-.45.2-1.05m-.75-6.75l.1-.1q.65-.75.65-1.8v-.35l-.1-.5q-.2-.5-.55-.95-.65-.7-1.6-.7L2.2 10.05q-.7.3-.9.35-.25.05-.3.15-.1.05-.15.1-.1.1-.2.15-.65.75-.65 1.8t.65 1.8q.7.75 1.65.75h.2L28.35 4.4z"
                    id="ObjMor084__Symbol_173_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M4.6 11.8q0-1.05-.65-1.8-.7-.75-1.65-.75-.15 0-.35.05l-1 .45q-.15.1-.3.25-.65.75-.65 1.8 0 1 .65 1.75.7.75 1.65.75h.1l1.45-.6q.05-.1.1-.15.65-.75.65-1.75m0-9.55q0-.5-.15-.95Q3.6-.55 1 .2.9.25.85.3q-.1.1-.2.15Q0 1.2 0 2.25t.65 1.8q.7.75 1.65.75h.2l1.2-.5q.1-.15.25-.25.65-.75.65-1.8z"
                    id="ObjMor084__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M18.15 8.85L33.9 10.5l8.7-2.95-15.4-1.6-9.05 2.9M6 20.05l1.9-.65-4.6-.5L6 20.05m8.7 3.65h.1l10.4 1.2 8.3-2.8L10 19.6l-2.75.95 7.45 3.15M9.1 11.8L0 14.7l16.55 1.75 8.65-2.95-16.1-1.7M57.65 13l7.9-3.05-20.8-2.15-8.9 2.95L57.65 13M60 1.65L45.6 0l-9.4 3.05L51.25 4.6 60 1.65M41.65 19.1l6.9-2.55.05-.1.15.05 1.05-.45-22.55-2.35-8.65 2.95 23.05 2.45m32.9-14.9l.05-.05-3.5-1.25-9.3-1.05-8.65 2.95L73.4 6.95l4.3-1.7-3.15-1.05z"
                    id="ObjMor084__Symbol_49_0_Layer0_0_FILL"
                />
                <g id="ObjMor084__Layer13_0_FILL">
                    <path d="M45.1 328.15l-2.2 2.8-23.35 7.55q-1.59 1.256-3.6.65l-1.25-.55-5.25 1.5h-.1l1.05 4.3q1.855 14.871 0 29l.8.55v1.85q13.439 6.587 25.45 16.6l20-10.7q23.475-12.354 46.95-22.4v-1.6l-1.8-1 11.75-5.7v-26.3q-3.74-.192-2.65-.7l-.5.2v-1.4l-14.65-5.15v-7.9l17.05-4.6q-.2.05-.85.2-.65.15 1.1-.35 1.75-.45 1.9-.65.85-.8.85-1.95t-.85-1.95q-.6-.65-1.5-.8-.2-.05-.45-.05l-25.5 6.6q-.15 0-.25.05-.05 0-.1.05-.55.2-.95.6-.5.5-.65 1.1h-.05v.15q-.05.3-.05.6t.05.55v6.15l-.8-.25q-18.992 8.413-39.6 12.95m-6.95 18.1l.1.05v.05h-.05l-.15-.1h.1m28.2 5.05q2.045 8.478 0 17.7 0 .15-.05.3-.1.1-.2.15-11.839 7.758-24.8 11.4-.15.1-.3.05-.2-.05-.3-.2-.1-.1-.1-.3-1.44-9.75 0-19.5 0-.15.1-.3.1-.1.2-.15 11.422-8.908 24.8-9.6.15-.05.3 0t.25.2q.1.1.1.25m-2.25 16.9q-1.028-7.67.1-14.8-10.209 5.093-21.75 8.4.732 8.262 0 16.55 10.605-5.714 21.65-10.15m-9.85-3l-5.2 5.15-2-2 5.15-5.2 2.05 2.05m-4.35-39.3l-.15-.05-.1.15.25-.1z" />
                    <path
                        fill="#666"
                        d="M66.35 369q2.045-9.222 0-17.7 0-.15-.1-.25-.1-.15-.25-.2-.15-.05-.3 0-13.378.692-24.8 9.6-.1.05-.2.15-.1.15-.1.3-1.44 9.75 0 19.5 0 .2.1.3.1.15.3.2.15.05.3-.05 12.961-3.642 24.8-11.4.1-.05.2-.15.05-.15.05-.3m-2.15-15.6q-1.128 7.13-.1 14.8-11.045 4.436-21.65 10.15.732-8.288 0-16.55 11.541-3.307 21.75-8.4m-15.15 16.95l5.2-5.15-2.05-2.05-5.15 5.2 2 2z"
                    />
                </g>
                <g id="ObjMor084__Layer13_1_FILL">
                    <path
                        fill="#333"
                        d="M35.8 345.3l-4.55.15 4.3 1.75h4.9l-2.2-.85h-.05l-.15-.1-2.25-.95m-21.1-6.7l3.35 1.45 5.05-.05-3.55-1.5-4.85.1z"
                    />
                    <path
                        fill="#232323"
                        d="M97.9 326.75l3.15 1.05 5.15-1.95-10.65-3.75-5.15 1.9 7.55 2.7-.05.05m-12.4-8.15l-2.25-.8-5.05 1.75 7.3 2.65v-3.6z"
                    />
                    <path
                        fill="#383838"
                        d="M74.6 340.25l.1-.15 38.85-15.2v-.2l-2.35-.8-5 1.95-5.15 1.95-28.95 11.25-.15-.05-.05.1-.05.05 2.75 1.1m-63.4 33.7v1.85l25.45 16.6v-2l-2.1-1.35-23.35-15.1m16.8-25.2l-3.2-1.35v7.2l3.2 1.45v-7.3M9.35 340.1l1.05 4.3 2.25 9.35 10.25 5.3-1.75-13.6h.2l-11.8-5.35h-.2m63.6 3.3l-3-1.25h-.05l-39.2 13.9 3.85 1.75 22.1-7.95 16.3-6.45M45.1 328.15l2.95 1.15 30.15-9.75 5.05-1.75-.25-.1 2.1-.65.4.15v-1.75l-.8-.25-39.6 12.95z"
                    />
                    <path
                        fill="#202020"
                        d="M36.65 390.4v2l20-10.7 46.95-22.4v-1.6l-66.95 32.7z"
                    />
                    <path
                        fill="#666"
                        d="M28 364.45l-5.1-5.4-10.25-5.3 2.6 3.55L28 364.45m6.55-6.65l-3.85-1.75v28.2L12.65 372.9v-19.15l-2.25-9.35v29l.8.55 23.35 15.1V357.8z"
                    />
                    <path fill="#585858" d="M30.7 356.05v-7.3H28v7.3h2.7z" />
                </g>
                <g id="ObjMor084__Layer13_2_FILL">
                    <path
                        fill="#666"
                        d="M85.45 308.75q0 .3.05.55v12.9l4.9 1.8v-13q.2-1.75.2-2.25 0-1.1-.75-1.85t-1.85-.75q-.25 0-.5.05-.15 0-.25.05-.05 0-.1.05-.55.2-.95.6-.5.5-.65 1.1h-.05v.15q-.05.3-.05.6m-54.75 40l4.85-1.55-4.3-1.75-6.45 1.95 3.2 1.35h2.7m-9.35-3.3h9.9l4.55-.15-12.7-5.3-13.55.1 11.8 5.35m7.25-.8h-5.2l-7.55-3.95 4.75.15 8 3.8m41.35-2.5l3 1.25v-.05l1.65-3.1-2.75-1.1-1.95 3h.05m-24.85-14l-2.2 2.8 5.15-1.65-2.95-1.15z"
                    />
                    <path
                        fill="#E7E7E7"
                        d="M30.7 356.05H28v8.4l-12.75-7.15-2.6-3.55v19.15l18.05 11.35v-28.2m-5.6 14.35q.9 0 1.5.65.6.6.6 1.45 0 .9-.6 1.5t-1.5.6q-.85 0-1.45-.6-.65-.6-.65-1.5 0-.85.65-1.45.6-.65 1.45-.65m-6.2-3.9q.6.6.6 1.45 0 .3-.05.6-.15.5-.55.9-.6.6-1.5.6-.85 0-1.45-.6-.4-.4-.55-.9-.05-.3-.05-.6 0-.85.6-1.45.6-.65 1.45-.65.9 0 1.5.65m54.05-23.15v.05l-16.3 6.45-22.1 7.95v31.25l22.1-10.95 48.05-22.8v-24.5l-31.75 12.55m-7.25 7.5q.15-.05.3 0t.25.2q.1.1.1.25V369q0 .15-.05.3-.1.1-.2.15l-24.8 11.4q-.15.1-.3.05-.2-.05-.3-.2-.1-.1-.1-.3v-19.5q0-.15.1-.3.1-.1.2-.15l24.8-9.6m-.3 17.85V352l-23.85 9.2v18.45L65.4 368.7m-20.8 1.6q-1.15-2.15-.5-4.6.65-2.5 2.7-3.8 2.05-1.3 4.3-.7.25.05.45.1 1.95.7 3 2.65.2.35.35.7.45 1.2.4 2.45-.05.7-.25 1.5-.65 2.45-2.7 3.75-2.05 1.35-4.35.75-.7-.2-1.25-.55-1.35-.75-2.15-2.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor084__Layer13_0_FILL" />
            <use xlinkHref="#ObjMor084__Layer13_1_FILL" />
            <use xlinkHref="#ObjMor084__Layer13_2_FILL" />
            <use
                xlinkHref="#ObjMor084__Symbol_173_0_Layer0_0_FILL"
                transform="translate(70.4 339.75)"
                id="ObjMor084__col1n"
            />
            <use
                xlinkHref="#ObjMor084__Symbol_114_0_Layer0_0_FILL"
                transform="translate(70.4 350.1)"
                id="ObjMor084__col1l"
            />
            <use
                xlinkHref="#ObjMor084__Symbol_49_0_Layer0_0_FILL"
                transform="translate(23.35 322.55)"
                id="ObjMor084__col2n"
            />
        </g></g>
    )
}

export default ObjMor084
