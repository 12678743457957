import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF0000"
}

function ObjMus019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#CCC"
                    d="M143.75 288.95q-3.255 2.45-3.35 4.95-.05.2 0 .4v.3l.4 4.6q5.645 11.915 2.05 24.6l.2 2.4q.05.2.1.45.8 3.25 7.2 5.5.45.1.85.25.7.2 1.45.45.7.15 1.45.35l.9.2q1.85.4 4 .8 6 1.05 12.85 1.4 1.4.05 2.85.15h1.3q.8.05 1.65.05h.6q8.3.05 17.65-.75 5.65-.45 10.75-1.15 3.7-.5 7.15-1.15 1.55-.3 3.15-.55.05-.05.05 0 1.6-.4 3.15-.75.2 0 .4-.1.15-.05.35-.05 6-1.4 11.2-3.25 3.55-1.25 6.3-2.55 1.7-.8 3-1.6.75-.45 1.4-.95 2.85-2.05 3.5-4.05.15-.5.15-.95v-.75l-.1-.6-.25-3.4q0-.3-.1-.6-3.991-10.358-1.85-22.25l-.35-4v-.65-.1q-.05-.75-.45-1.35-.177-.354-.45-.7-15.46-8.58-46.3-6.45-28.592 1.393-52.85 11.85z"
                    id="ObjMus019__Layer13_0_FILL"
                />
                <path
                    fill="#A6A6A6"
                    d="M177.85 330.6h-3.6l.15 1.9q.4-.05.8-.05.5.05.9.05.5 0 1 .05h.9l-.15-1.95m-2-23.4q-.95-.05-1.9-.05h-.3q-.25 0-.5-.05H172.3l.15 1.6 3.55.1-.15-1.6m40.8 21.7h.05l-.15-2-3.15.6.15 1.9v.1q1.6-.3 3.1-.6m-2.05-25.3q0-.05-.05 0-1.6.25-3.15.55l.15 1.7 3.2-.5-.15-1.75m26.25 14.3l-3.05 2 .2 2.35q.85-.4 1.55-.85.85-.5 1.5-1.05l-.2-2.45m-1.9-22.6q-1.35.8-3.05 1.65l.2 2.2 3.05-1.55-.2-2.3m-88.85 33.4q1.55.65 3.7 1.25l-.25-2.3-3.65-1.25.2 2.3m1.75-22.35l-.15-1.75q-1.25-.3-2.3-.6-.75-.2-1.45-.45l.15 1.8 3.75 1z"
                    id="ObjMus019__Layer13_1_FILL"
                />
                <path
                    fill="#A6A6A6"
                    d="M140.4 294.6q.05.2.1.45.3 1.25 1.4 2.3.85.85 2.15 1.65 1.05.55 2.4 1.1.6.25 1.25.5 1.65.55 3.75 1 2.2.5 4.9 1 7.35 1.3 16.1 1.55.35-.05.75 0 9.25.2 20.05-.7 4.8-.4 9.2-.95 5.6-.75 10.6-1.7 7.55-1.45 13.85-3.5 1.25-.4 2.55-.85 3.5-1.25 6.2-2.45 1.7-.85 3.05-1.65.25-.2.55-.3 1-.7 1.8-1.3 1.2-1.05 1.9-2.05.244-.38.4-.75.186-.33.3-.65.15-.5.15-.95v-.65-.1q-.05-.75-.45-1.35-2.2-4.4-15.5-6.7l-1.3-.2q-3.704-2.743-10.5-1.1-11.3-.7-25.05.45-11.4.95-20.9 2.9-5.495-2.558-9.65 2.3-3 .85-5.7 1.8-14.15 4.95-14.35 10.2-.05.2 0 .4v.3m2.7-.3q-.4-4.8 13.65-9.4 2.2-.7 4.55-1.35 4.55-1.25 9.65-2.25 9.15-1.8 20.2-2.7 12.9-1.1 23.5-.55 5.65.25 10.65.95.35.05.75.1 14.65 2.15 15.05 6.95.05.45 0 .9-.154.615-.55 1.2l-96.95 7.55q-.117-.161-.2-.35-.25-.5-.3-1.05z"
                    id="ObjMus019__Layer13_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M35 47.2q-6.45-8.05-10.85-14.85l-.2-.3-.05-.05Q13.35 15.2 12.2 8.7q-.5-.3-1.1-.65Q8 6.2 0 0q9 28.7 28.85 54.1 4.55-1.3 9.65-2.3-1.85-2.35-3.5-4.6M120.65.8q-4.8 4-11.1 7.8-.05.05-.05.1-8.55 20.6-23.3 37.05-1.8 2.3-1.75 2.7 5.5.3 10.5 1.1Q112.15 26.1 120.65.8z"
                    id="ObjMus019__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.8 10.4Q1.25 9.95.4 9.2q-.2-.15-.4-.35l2.05 24.6.4.5q1.45 1.65 4.4 3.1 1 .65 2.45 1.3L7.15 13.2q-2.95-1.05-4.7-2.3-.2-.1-.65-.5m29.7 6.35q-1.45-.05-2.85-.15-6.85-.3-12.85-1.35-.65-.1-1.35-.25-1.15-.2-2.3-.45l-.4-.1q-.45-.1-.85-.2L13 39.6q.25.05.55.15.15 0 .35.05.1 0 .25.05.45.1.95.2.75.2 1.5.35.95.15 2 .35l1.2.2q5.1.75 11 1.05l2.2.1q.3 0 .6.05l-2.1-25.4m32.3-1.85q-.85.1-1.75.2-.7.1-1.45.2-2 .25-4.1.5l-3.8.3-3.6.3q-6.65.4-12.7.4-.7.05-1.35.05L37.2 42.2q.65 0 1.35-.05 2.55 0 5.3-.05 5.2-.2 10.9-.7 4.15-.35 8-.8 1.35-.15 2.7-.35.25 0 .5-.05.15-.05.3-.05 3.25-.5 6.4-.95.05-.05.1-.05v-.1L70.6 13.8q-3.15.55-6.5 1.05-.15 0-.3.05m31.75-2.95L95.1 6.6q-2.7 1.25-6.2 2.5l-3.15 1.05q-.15 0-.3.1-1.1.35-2.35.7-2.95.85-6.15 1.55-.85.2-1.7.45-.7.15-1.45.3l2.1 25.3q1.65-.35 3.2-.7.6-.15 1.3-.3 1-.25 1.95-.45l2.9-.8q1.35-.4 2.65-.8.65-.2 1.2-.4 4.9-1.6 8.1-3.2l-1.7-20.4.05.45M103.35 0q-.1.15-.15.25-.7.95-1.15 1.6-.55.55-.7.75-.15.1-.3.3-.75.6-1.75 1.3-.5.35-1.15.75l2.1 25.05q.3-.25.6-.45l.55-.45q.9-.7 1.75-1.45 1.15-1.2 2.15-2.6l.1-.3-.1-1.9q0-.3-.1-.6L103.35 0z"
                    id="ObjMus019__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M143.7 295q-.15.15-.3.35.55 1.25 2.2 2.3.85.55 1.95 1.05 1.55.65 3.75 1.25 2.9.75 6.85 1.25 6.2.95 13.55 1.2 9.75.3 21.4-.7 4.75-.4 9.15-.9 14.25-1.85 25-5.35.1 0 .2-.05 4.9-1.55 8.05-3.15 2.45-1.3 3.8-2.6 1.45-1.3 1.8-2.7-.25-.1-.4-.2-.8-.55-1.75-1.1-3.9-2-15.2-4.15-.15-.05-.35-.05-4.45-.85-10.4-1.05-9.2-.35-22.05.75-10.85.95-18.85 2.45h-.05q-7.8 1.45-12.95 3.4-10.35 3.95-13.85 6.65-.85.6-1.55 1.35z"
                    id="ObjMus019__Layer4_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMus019__Layer13_0_FILL" />
            <use xlinkHref="#ObjMus019__Layer13_1_FILL" />
            <use xlinkHref="#ObjMus019__Layer13_2_FILL" />
            <use
                xlinkHref="#ObjMus019__Symbol_104_0_Layer0_0_FILL"
                transform="translate(131.6 227.8)"
                id="ObjMus019__col1n"
            />
            <use
                xlinkHref="#ObjMus019__Symbol_32_0_Layer0_0_FILL"
                transform="translate(140.8 290.35)"
                id="ObjMus019__col2n"
            />
            <use xlinkHref="#ObjMus019__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjMus019
