import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#FF0000"
}

function ShiSpe120({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7-.05-.15-.1-.25-.05-.2-.1-.35-.05-.25-.1-.4l-.3-1.2-.4-1.4q-.6-2-1.2-3.9-.2-.55-.35-1.1-.15-.45-.25-.85-.3-.8-.55-1.6-.1-.2-.15-.35-.2-.65-.45-1.25l-.4-1.2q-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.85-1.2-1.7-2.3l-.05-.05-1.85 1.4q-.15.1-.25.25l-1 .7q-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75l-1.7.9q-.75.4-1.55.8-3.5 1.75-7.1 3.25-5.25 2.05-10.75 3.5-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95-5.8-1.5-11.25-3.7-3.4-1.4-6.7-3.05-.8-.4-1.55-.8l-.8-.4q-4.35-2.4-8.5-5.25L27.1 3.5q-1.25-.9-2.4-1.75-.2-.2-.4-.35L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.35.95-.65 2-.35 1.05-.7 2.2-.45 1.45-.85 3-.35 1.2-.7 2.45-.05.15-.1.35-.05.15-.05.3l-.1.3-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q1.95 1.05 3.5 2.7.15.1.25.25l-2.8 11.75q-.55 2.1-1.05 4.2v.15q-.15.35-.2.75-.4 1.35-.7 2.7l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 25.25 5.7 50.35 6.8h.7l-.15-.05h9.4V89h.15v-.25h9.3l-.4.15q1.75-.1 3.55-.15 24.95-1.05 49.8-6.75 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45q.2-.25.45-.45 1.5-1.55 3.35-2.5.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe120__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M129.4.75l-.2-.75Q96.45 7.45 63.75 7.35 31.95 7.25.2 0 .05.35 0 .75q31.85 7.3 63.75 7.45 4.55 0 9.1-.15Q101.1 7.2 129.4.75z"
                    id="ShiSpe120__Symbol_152_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.05 45.7l5.55-5.3-2.55-6.85L8 39.05l3.05 6.65m5.55-5.3l2.05 8.4 2.4-1.7q1.35-3.3 1.35-6.75l-1.1-3.3-4.7 3.35M.85 38.8Q.5 40 .15 41.25q-.05.15-.1.35-.05.15-.05.3l1.7 4.85 6.2-7.6L5.4 33 .85 38.8M9 17.15q-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.35.95-.65 2 1.5-.45 3-.6 4.45-.65 8.65.55 3.7 1.05 7.25 3.5.75.55 1.5 1.15.75-2.1 1-2.95h.05q0-.2.05-.35v-.1l.1-.5q.4-2.85.6-5.6l-.05-10.9q-1-9.6-5.2-17.8-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55m134.9 56.4l-2.15-8.6L108 46.6 79.35 74.1l-29.2-26.9-33.1 17.45-1.1 4.55q-.55 2.1-1.05 4.2v.15q31.75 7.25 63.55 7.35 32.7.1 65.45-7.35m14.95-31.6q-.05-.2-.1-.35-.05-.25-.1-.4l-.3-1.2-.4-1.4-4.5-5.6-2.5 6.15 6.2 7.6 1.7-4.8m-16.6-1.55l5.55 5.3 3.05-6.65-6.05-5.5-2.55 6.85m-4.5 6.65l2.45 1.75 2.05-8.4-4.7-3.35-1.1 3.4q-.05 3.3 1.3 6.6m9.15-35.55q-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.85-1.2-1.7-2.3l-.05-.05-1.85 1.4q-.15.1-.25.25l-1 .7q-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75l-1.7.9q-.75.4-1.55.8-3.5 1.75-7.1 3.25-17.3 8.9-34.5 9.25l-.1-.05q-.6 0-1.3.05-18.25.05-36.3-9.45-3.4-1.4-6.7-3.05-.8-.4-1.55-.8l-.8-.4q-4.35-2.4-8.5-5.25L24 3.5q-1.25-.9-2.4-1.75-.2-.2-.4-.35L19.35 0l30.8 47.2 29.5-22.7L108 46.6 139.4.1q-3.8 7.5-4.95 16.2l-.4 8.5q.05 4.85.9 10.1 0 .15.05.35h.1q.25.95.9 2.95h.05q.75-.6 1.5-1.15 3.55-2.45 7.25-3.5 4.2-1.2 8.65-.55 1.45.15 2.95.6-.15-.45-.25-.85-.3-.8-.55-1.6-.1-.2-.15-.35-.2-.65-.45-1.25l-.4-1.2q-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6z"
                    id="ShiSpe120__Symbol_154_0_Layer0_0_FILL"
                />
                <path
                    d="M182.5 317.5q2 .05 4 .05l4.9.05v-7.25q-4.55 0-9.1-.15l-4.4 1.1v4.8l4.6 1.4m22.55-1.4v-4.8l-4.4-1.1q-4.55.15-9.1.15v7.25h.95q4 0 7.95-.1l4.6-1.4z"
                    id="ShiSpe120__Layer12_0_MEMBER_0_FILL"
                />
                <path
                    fill="#A0A1A5"
                    d="M197.65 307.4q-.3-.05-.55-.1-5.85-1-11.55 0-.15 0-.3.05-.05 0-.1.05l-3.25 2.95v7l3.05 2.85q.05 0 .15.05 6.45 1 12.45 0 .05-.05.1-.05l3.25-2.85v-7l-3.25-2.95m-8.25.65q.95-.35 2.05-.35 1.05 0 1.95.35 1.25.4 2.3 1.4.6.65 1 1.35.05.05.05.1.4.7.6 1.55.1.6.1 1.25 0 2.5-1.75 4.25-1.4 1.4-3.15 1.7-.55.1-1.1.1-.25 0-.55-.05-2.15-.2-3.7-1.75-1.75-1.75-1.75-4.25 0-.9.25-1.75.2-.55.45-1.05l.1-.2q.4-.65.95-1.25.95-.95 2.1-1.35.05-.05.1-.05z"
                    id="ShiSpe120__Layer12_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.083-.083-.2-.1-.026-.007-.1 0-.013-.01-.05 0-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.3v.1q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.6-.4q-.037-.01-.1 0H136.75l-.1.1q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2v.05q.037.044.05.1.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25z"
                    id="ShiSpe120__Layer12_1_FILL"
                />
                <g id="ShiSpe120__Layer12_0_MEMBER_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL">
                    <path
                        fill="#A0A1A5"
                        d="M196 309.15q-1.1-1.1-2.45-1.55-1-.3-2.1-.3-2.65 0-4.55 1.85-.6.6-1 1.3-.1.1-.15.3-.3.5-.45 1.05v.05q-.3.9-.3 1.85 0 2.7 1.9 4.6 1.7 1.65 3.95 1.85H191.45q.6 0 1.15-.05 1.95-.35 3.4-1.8v-.05q1.9-1.9 1.9-4.55 0-.7-.1-1.35-.2-.85-.6-1.6-.05-.1-.1-.15v-.05q-.45-.75-1.1-1.4m-9.55 1.9l.1-.1v-.05q.35-.65.9-1.2 1.65-1.65 4-1.65 1 0 1.9.3 1.15.4 2.15 1.35.55.6.95 1.25v.05l.05.05q.35.7.55 1.45.1.6.1 1.25 0 2.3-1.65 4-1.3 1.3-3 1.6-.5.05-1.05.05h-.5q-2-.15-3.5-1.65-1.65-1.7-1.65-4 0-.9.2-1.7.15-.5.45-1z"
                    />
                    <path
                        fill="red"
                        d="M186.55 310.95l-.1.1q-.3.5-.45 1-.2.8-.2 1.7 0 2.3 1.65 4 1.5 1.5 3.5 1.65h.5q.55 0 1.05-.05 1.7-.3 3-1.6 1.65-1.7 1.65-4 0-.65-.1-1.25-.2-.75-.55-1.45l-.05-.05v-.05q-.4-.65-.95-1.25-1-.95-2.15-1.35-.9-.3-1.9-.3-2.35 0-4 1.65-.55.55-.9 1.2v.05z"
                    />
                </g>
                <g id="ShiSpe120__Layer12_0_MEMBER_0_MEMBER_0_MEMBER_0_MEMBER_1_FILL">
                    <path d="M196.75 318.6q.2-.15.35-.25l-.4 1.5v.05l.4-.4q.25-.3.35-.4.3-.6.55-1 .35-.8.5-1.05.15-.25.3-.6.1-.3.15-.65l-1.6-3.35q.1.6.1 1.25 0 2.5-1.75 4.25-1.4 1.4-3.15 1.7.6.2 1.25.2h.05q.7.1 2.05-.65.45-.25.85-.6m-11.4-4.9q0-.65.1-1.25l-1.6 3.35q.1.35.2.65.1.35.3.6.1.25.45 1.05.25.4.55 1 .1.1.35.4l.4.4v-.05l-.4-1.5q.15.1.35.25.4.35.9.6 1.35.75 2.05.65.65 0 1.3-.2-1.8-.3-3.2-1.7-1.75-1.75-1.75-4.25m6.1-4.05q-1.7 0-2.9 1.2t-1.2 2.9q0 1.7 1.2 2.9t2.9 1.2q1.7 0 2.9-1.2t1.2-2.9q0-1.7-1.2-2.9t-2.9-1.2m-3.25 4.05q0-1.35.95-2.3.95-.95 2.3-.95 1.35 0 2.3.95.95.95.95 2.3 0 1.4-.95 2.35-.95.95-2.3.95-1.35 0-2.3-.95-.95-.95-.95-2.35m1.2-5.65l-.3-.4q-1.95.3-3.6 1.8l.4-1.6q-2.8 2.8-2.3 6.45 0 .15.05.6.05 0 .05-.05l2.35-3.95q0-.05.05-.1.4-.7 1-1.35 1-.95 2.2-1.35.05-.05.1-.05m4.3-.4l-.3.4q1.25.4 2.3 1.4.6.65 1 1.35.05.05.05.1l2.35 3.95q0 .05.05.05.05-.45.05-.6.5-3.65-2.3-6.45l.4 1.6q-1.65-1.5-3.6-1.8z" />
                    <path
                        fill="#FC0"
                        d="M189.15 311.4q-.95.95-.95 2.3 0 1.4.95 2.35.95.95 2.3.95 1.35 0 2.3-.95.95-.95.95-2.35 0-1.35-.95-2.3-.95-.95-2.3-.95-1.35 0-2.3.95z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe120__Symbol_151_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe120__col1n"
            />
            <use
                xlinkHref="#ShiSpe120__Symbol_152_0_Layer0_0_FILL"
                transform="translate(127.8 302.15)"
                id="ShiSpe120__col1s"
            />
            <use
                xlinkHref="#ShiSpe120__Symbol_154_0_Layer0_0_FILL"
                transform="translate(113.1 228.6)"
                id="ShiSpe120__col2n"
            />
            <use
                xlinkHref="#ShiSpe120__Layer12_0_MEMBER_0_FILL"
                transform="matrix(1.03304 0 0 1.03572 -5.3 -11.2)"
            />
            <use
                xlinkHref="#ShiSpe120__Layer12_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL"
                transform="matrix(1.03297 0 0 1.03564 -5.3 -11.2)"
            />
            <use
                xlinkHref="#ShiSpe120__Layer12_0_MEMBER_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL"
                transform="matrix(1.03294 0 0 1.03561 -5.3 -11.2)"
            />
            <use
                xlinkHref="#ShiSpe120__Layer12_0_MEMBER_0_MEMBER_0_MEMBER_0_MEMBER_1_FILL"
                transform="matrix(1.03294 0 0 1.03561 -5.3 -11.2)"
            />
            <use xlinkHref="#ShiSpe120__Layer12_1_FILL" />
        </g></g>
    )
}

export default ShiSpe120
