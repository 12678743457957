import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou053({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M14 12.25q0-5.1-2.1-8.75Q9.9 0 7 0 4.05 0 2 3.5q-2 3.65-2 8.75 0 5.05 2 8.65 2.05 3.65 5 3.65 2.9 0 4.9-3.65 2.1-3.6 2.1-8.65z"
                    id="FacMou053__Symbol_78_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou053__Symbol_78_0_Layer0_0_FILL"
                transform="translate(185.45 210.25)"
                id="FacMou053__col1n"
            />
        </g></g>
    )
}

export default FacMou053
