import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#252525",
    "col2n": "#000000"
}

function AccHat068({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M185.15 44.1l1.75-14.15q-.15-9-13.15-15.85Q159 6.45 127.7 1.7l-1.5-.2-5.85-.8Q87.5-2.75 60.2 10.25q-9.6 5.4-17.7 10.45-13.45 8.5-22.65 16Q9.95 44.8 5 51.8q-7.45 10.45-3.8 18.5 0 .05.05.1l7.3 13.05v.05l.1-.05L185 44.15l.1.05.05-.1M57.2 54.65h-.05v-.1l.05.1z"
                    id="AccHat068__Symbol_286_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M179.85 35.4q-2.9 0-4.5.25-10.75-.25-16.9 3.6l.05.3q7.95 4.45 26.25 4.5l.35.15.05-.1q2.4-5.45 1.95-14.15-.45-8.7-14.25-16.1Q159 6.45 127.7 1.7l-1.5-.2-5.85-.8Q87.5-2.75 60.2 10.25q-9.6 5.4-17.7 10.45-13.45 8.5-22.65 16Q9.95 44.8 5 51.8q-7.45 10.45-3.8 18.5 0 .05.05.1 4.35 9.7 7.3 13.05v.05q16.4-7.25 23.95-17.05-9 .05-18.7 5.25-4.3 2.3-7.25 1.95-4.75-2.45-3-8.05 1.25-4.05 5.9-9.75Q15 48.7 24.5 40.75q2.35-2 5-4.05 11.7-9.15 28.2-21.3Q81.85-2.55 113.5 4.95q25.75 4.3 42.55 9.65 32.2 11.1 27.65 20.4-1.3.4-3.85.4m-19.5 4.1h2.1l-2.7.05q.25-.05.5-.05h.1M29.2 68.05q1.2-.6 2.45-1.15l-2.4 1.2-.05-.05z"
                    id="AccHat068__Symbol_190_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M177.1 20.25q1.1-.65 1.9-1.15 0-.25.1-.45v-.15q0-.05.05-.1v-.05q.05-.1.05-.15l-3-13.55Q175.95 1.3 160.9 0h-2.3q-30.7-.05-50.05 2.45-45.25 5.75-84.6 24.6-1.4.7-6.4 3.35-1.8.85-3.15 1.85-1.4 1-7.05 5.2Q1.65 41.6 0 44.1v.05l1.2 5.35.15-.1q-.05.05-.1.15l1.8 7.7h.05q.4 0 .75.1.3.1.65.25h-.25q.05.002.1.1.2 0 .45.05h.35q6.5 1.8 11.7 2l-.6-.1q2.15.25 4.35.5 2.3.25 4.6.45 9.1.8 18.2.85h3.95q23-.35 45.7-5.45.3-.2 1.35-.3.85-.05 6.45-1.6.65-.25 1.45-.45l3.3-.9q1.25-.35 2.55-.75 29.972-8.912 59.45-26.25l.6-.3q0-.05.05-.05l.05-.05q6.75-3.8 8.8-5.1z"
                    id="AccHat068__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M29.5 15.1L26.25 0q-3.1.35-6.1.85V.8q-8 1.3-15.8 2.9v.1q-.55.1-1.05.25v-.1h-.05v.1Q1.6 4.4 0 4.85L3.8 20.4q.75-.2 1.5-.35 4.95-1.15 9.95-2.15 7.1-1.5 14.25-2.8z"
                    id="AccHat068__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M246.05 89H246q-20.25 2.35-40.25 6h-.05q-7.35 1.35-14.65 2.9-5 1-9.95 2.15-.75.15-1.5.35-2.85.65-5.65 1.3-4.75 1.15-9.5 2.35-16.6 4.15-33 9.25-1.2.35-2.35.75-7.5 2.3-14.95 4.9-.75.25-1.45.5-.4.1-.75.25-3.9 1.35-7.8 2.75l-4.75 1.65q-2.4.85-4.8 1.75-.05.05-.1.15l1.8 7.7h.05q.4 0 .75.1.65-.25 1.4-.5 3.75-1.4 7.5-2.75 1.8-.7 3.65-1.3 3.85-1.4 7.8-2.7 1.85-.65 3.7-1.25 3.05-1 6.15-1.95 1.5-.5 3-.95 23.9-7.5 48.25-13.1 1.95-.45 4-.9 35.95-8.05 72.85-12.05 8.4-.9 16.85-1.55H272.4q.05-.1.05-.15l-1.75-8q-1.9.15-3.7.3l-1.8.2-5.75.45q-.9.05-1.75.15-5.85.55-11.65 1.25z"
                    id="AccHat068__Layer8_0_FILL"
                />
                <path
                    fill="#D2AD00"
                    d="M249.85 92.05q-1.5.15-3.05.3-20.25 2.4-40.3 6.05-37.8 6.9-74.8 18.45-1.45.45-2.85.9-6.3 2-12.6 4.1-1.45.5-2.85 1.05-1.6.5-3.15 1.05-2.65.9-5.3 1.85l-4.75 1.7-4.9 1.9.3 1.55q.45-.2.95-.4 1.95-.8 3.95-1.6h.1l4.7-1.65 4.4-1.6 5-1.7q1.5-.55 3.05-1.05 5.45-1.85 10.9-3.55 1.45-.5 2.9-.9 22.95-7.2 46.2-12.6 14.45-3.35 29.05-6.05 20.05-3.65 40.35-6l4.4-.5q4.5-.5 9.05-.9l5.75-.45q2.036-.15 4.1-.3l1.35-.1-.3-1.4q-.2-.05-.35 0-.35.02-.7.05-2.244.12-4.45.25l-5.75.45q-.9.1-1.75.25-4.35.35-8.65.85z"
                    id="AccHat068__Layer8_1_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.4}
                    d="M272.35 95.1v-.15q-.05 0-.05.05-6.3 2.6-12.65 5.1-9.95 3.8-20 7.2-5.3 1.8-10.55 3.55-1.65.5-3.25 1l-14.3-7.65q-4.4.65-27.85 5.55l9.35 10.65-7.95 2.05q-.355.164-1.55.4l-.1.05q-.318.11-.65.2-2.6.55-5.1 1.05-.3 0-.5.1-18.35 3.6-36.3 5.95l-1 .1q-.8.15-1.6.25-8 1-15.9 1.75-2.4.2-4.8.45-.1-.05-.2 0-3.95.3-7.85.6-5.7.35-11.35.6-.25.05-.45.1h-.25q.05.002.1.1.2 0 .45.05h.35q5.55.3 11.15.45 2.95.05 5.95.05h4.8q9.4-.15 18.8-.8 1-.1 2.05-.15 13.25-1.05 26.45-3.2 10.3-1.7 20.55-4.05.9-.25 1.9-.45 5.25-1.25 10.55-2.65l2.8-.8q8.65-2.45 17.3-5.35 20.1-6.85 39.9-16.3 5.85-2.85 11.75-5.85z"
                    id="AccHat068__Layer8_2_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat068__Symbol_286_0_Layer0_0_FILL"
                transform="translate(84.7 37.05)"
                id="AccHat068__col1n"
            />
            <use
                xlinkHref="#AccHat068__Symbol_190_0_Layer0_0_FILL"
                transform="translate(84.7 37.05)"
                id="AccHat068__col1d"
            />
            <use
                xlinkHref="#AccHat068__Symbol_96_0_Layer0_0_FILL"
                transform="translate(93.25 76.45)"
                id="AccHat068__col2n"
            />
            <use
                xlinkHref="#AccHat068__Symbol_32_0_Layer0_0_FILL"
                transform="translate(175.8 80)"
                id="AccHat068__col2d"
            />
            <use xlinkHref="#AccHat068__Layer8_0_FILL" />
            <use xlinkHref="#AccHat068__Layer8_1_FILL" />
            <use xlinkHref="#AccHat068__Layer8_2_FILL" />
        </g></g>
    )
}

export default AccHat068
