import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFE69A"
}

function ObjMus015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M39.75 86.55q-.05.3-.05.55.85 1.7 1.7 3.45.1.25.25.55 0 .1.1.2.75 1.5 1.95 2.25 1.3.85 2.4.4 1.05-.5 1.3-2.05.2-1.4-.4-2.85-.1-.2-.15-.35-.1-.2-2.65-6.15-.95.2-1.95.25-1 0-1.85-.2-.3-.1-.55-.15-.15-.05-.2-.1.1 1 .1 2.15v2.05m-29.6-76.6q.75-1.4 1.05-2.15.8-2.2-.2-4.4T7.8.4Q5.55-.45 3.4.55q-2.2 1-3 3.25-.85 2.2.15 4.35 1 2.2 4.1 3.25 1.1.35 1.5.55 1.15 2.1 2.25 4.3l.5 1q4.15 8.65 7.8 17.55 1.1 2.65 2.15 5.35.75 1.9 1.6 3.75 4.75 11.7 9.95 23.25 1.8.8 3.65 2.45.9.8 1.55 1.55.5-.8 1.2-1.5.6-.6 1.25-1.05l-1-2.1Q32.2 56.2 25 41.85q-.9-1.85-1.8-3.65-1.35-2.6-2.6-5.15-4.3-8.55-8.15-17.4-.25-.5-.45-1-1-2.35-1.85-4.7M165.7 86.6v-2.05q0-1.15.15-2.15l-.2.1q-.3.05-.55.15-.9.2-1.85.2-1.05-.05-2-.25-2.55 5.95-2.65 6.15-.05.15-.1.35-.65 1.45-.4 2.85.2 1.55 1.3 2.05 1.05.45 2.35-.4 1.2-.75 2-2.25l.1-.2q.1-.3.2-.55.85-1.75 1.75-3.45-.05-.25-.1-.55m39.2-78.4q1-2.15.2-4.35-.85-2.25-3.05-3.25-2.15-1-4.35-.15-2.25.8-3.25 3t-.15 4.4q.25.75 1.05 2.15-.9 2.35-1.85 4.7-.25.5-.45 1-3.9 8.85-8.15 17.4-1.3 2.55-2.6 5.15-.95 1.8-1.8 3.65-7.25 14.35-12.05 24.65-.55 1.05-1 2.1.6.45 1.2 1.05.7.7 1.2 1.5.65-.75 1.55-1.55 1.85-1.65 3.7-2.45 5.15-11.55 9.95-23.25.8-1.85 1.6-3.75 1-2.7 2.15-5.35 3.6-8.9 7.75-17.55l.5-1q1.1-2.2 2.3-4.3.35-.2 1.5-.55 3.05-1.05 4.05-3.25z"
                    id="ObjMus015__Symbol_101_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus015__Symbol_101_0_Layer0_0_FILL"
                transform="translate(89.8 216.5)"
                id="ObjMus015__col1n"
            />
        </g></g>
    )
}

export default ObjMus015
