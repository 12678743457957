import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function LegSho008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M157.25 19.3q-.6-9.95-12.9-15.45Q132.1-1.6 115.4.7 98.7 2.9 87.35 11.5q-4.85 3.8-8.7 9.5-3.85-5.7-8.7-9.5Q58.6 2.9 41.9.7 25.2-1.6 12.95 3.85.65 9.35.05 19.3q-.75 10 10.6 18.6 11.3 8.65 27.95 10.95 16.75 2.2 29-3.25 8-3.55 11.05-9.05 3.05 5.5 11.05 9.05 12.25 5.45 29 3.25 16.65-2.3 27.95-10.95 11.35-8.6 10.6-18.6z"
                    id="LegSho008__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho008__Symbol_13_0_Layer0_0_FILL"
                transform="translate(113.85 301.7)"
                id="LegSho008__col1n"
            />
        </g></g>
    )
}

export default LegSho008
