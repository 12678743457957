import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M42.65 6.45q.1-1.4-1.95-2.75-.55-.35-1.15-.65-3.9-1.65-8.15-2.4Q23.25-.55 15.1.4 5.75 1.55.6 5.9-.6 7.35.6 8.05q1.3.55 4.5-.3 1.3-.45 6.15-1.45 7.25-1.55 15.5-.8 4.1.35 6.7.85 3.1.5 5.1 1.25 2.4 1 3.65 0 .15-.15.3-.4.15-.35.15-.75m43.3-.15q4.85 1 6.15 1.45 3.2.85 4.5.3 1.2-.7 0-2.15Q91.45 1.55 82.1.4q-8.15-.95-16.3.25-4.25.75-8.15 2.4-.6.3-1.15.65-2.05 1.35-1.95 2.75 0 .4.15.75.15.25.3.4 1.25 1 3.65 0 2-.75 5.1-1.25 2.6-.5 6.7-.85 8.25-.75 15.5.8z"
                    id="FacBro022__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro022__Symbol_22_0_Layer0_0_FILL"
                transform="translate(143.9 121.95)"
                id="FacBro022__col1n"
            />
        </g></g>
    )
}

export default FacBro022
