import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.35 8.4q24.6-9.15 36.4-3.65Q25.35-5.45.35 8.3q-.15.1-.35.2.2-.05.35-.1m92.9-.3q-.45-.25-.85-.45-24.65-13.4-35.9-3.3Q68.15-1.1 92.4 7.8q.4.15.85.3z"
                    id="FacBro007__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro007__Symbol_7_0_Layer0_0_FILL"
                transform="translate(145.9 124.45)"
                id="FacBro007__col1n"
            />
        </g></g>
    )
}

export default FacBro007
