import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.9 11.85q.048-.048.15-.05-.102-.002-.15-.05v.1M28.45 0h-1.2L22.9.6q-.25 0-3.35.9-.4 0-1.45-1-.65.25-1.2.5-1.35.65-4.2 1.75-2.95 1.1-3.35 1.1-.35 0-.5-.2-.4-.25-.8-1.05-.6.3-3.05 1.45Q2.8 5.1 2.55 5.1q-.4 0-.6-.25Q1.4 5.7 1 6.5l-.1.3q-1.4 3.7-.65 6.8.95-.55 1.3-.65.65-.3 2.7-1.15 2-.85 2.15-.85 1.25.6 1.75.65 1.05-.2 2.1-.35 1.25-.35 3.15-.95 4.05-1.35 4.1-1.35l1.2.45q1.2.45 2.55.45.65 0 1.7-.3.95-.35 1-.35.75-.2 1.15-.3.7-.15 2.55-.25h1.55V.05Q28.8 0 28.45 0m18.1 11.75q-.048.048-.15.05.102.002.15.05v-.1m17-4.95l-.1-.3q-.4-.8-.95-1.65-.2.25-.6.25-.25 0-2.45-1.05Q57 2.9 56.4 2.6q-.4.8-.8 1.05-.15.2-.5.2-.4 0-3.35-1.1-2.85-1.1-4.2-1.75-.55-.25-1.2-.5-1.05 1-1.45 1-3.1-.9-3.35-.9L37.2 0H36q-.35 0-.75.05v8.6h1.55q1.85.1 2.55.25.4.1 1.15.3.05 0 1 .35 1.05.3 1.7.3 1.35 0 2.55-.45l1.2-.45q.05 0 4.1 1.35 1.9.6 3.15.95 1.05.15 2.1.35.5-.05 1.75-.65.15 0 2.15.85 2.05.85 2.7 1.15.35.1 1.3.65.75-3.1-.65-6.8z"
                    id="BeaMus008__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus008__Symbol_25_0_Layer0_0_FILL"
                transform="translate(160.3 199.45)"
                id="BeaMus008__col1n"
            />
        </g></g>
    )
}

export default BeaMus008
