import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiLon001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M181.25 44.05q-4.4-3.45-12.35-10.25-3.65-3.1-5.8-5-.6-.4-1.2-.85-8.65-4.3-15.55-11.2-1.05-1-1.95-1.8-4.7-4.1-9.6-7.55l-.2-.1q-9.35-.75-18.75-3.15-4.35-1.15-8.7-2.5-2.95-.9-5.9-1.65-2.8-.05-5.6.1-2.85.45-5.85 1.25-8.25 2.1-16.15 4.95-1 .8-2.65 2.15Q58.7 16 46.3 23.6q-9.4 5.75-19.7 9.7-.3.1-.6.25-1.1 1.1-2.15 2.2-.8.85-1.4 1.8-3.3 5.9-6.95 11.6l-.6 1.1q-11 23.95-13.85 51.9l-.3 3.8Q.2 114.25 0 122.6q1.65 13.9 2.2 28.05.55 14.85-.35 29.55-.2 3.65-.45 7.25-.05.7-.1 1.45.2 3 .4 6.05.45 6.7.75 13.45l.65.25q1.75-4.75 2.7-9.75.3-1.1 1-3.8.7-2.75 1.2-4.15 1.85-26.45 0-54.5l-.4-3.3q-1.95-14.65-.15-29.3.5-4.3 1.05-8.55 1.35-4.85 2.55-9.75 1.7-6.9 4.45-13.45.95-1.9 2.25-4.3 3-5.35 4.05-8.2.65-1.5.95-2.05.65-1.2 1.55-2t3.8-2q3.6-1.45 4-1.7 2.05-1.1 5.15-3.75 3.55-3.05 5.05-4.05 2.7-1.75 8.55-7.8 1.8-1.4 3.55-2.8 6.2-4.95 13.3-8.3 6.8-3.15 13.5-6.4 2.5-1.25 4.7-2.95 4.75-3.8 10.4-5.35.05 0 1.9 1.75 1.8 1.75 14.75 4.85 1.5 1.05 4.35 2.35 2.8 1.25 6.8 2.15 15.45 4.85 28.75 15.1.1.1.25.2.3.35.6.75.049.076.1.15l.3.3q.15.2.3.45l-.6-.75q4.84 7.525 8.75 10Q169.5 52.1 176 57q.25.5.55 1.05 3.5 9.3 6.95 18.6 1.95 5.25 4.6 10.2.4 1.9.85 3.8.1 4.45.1 8.9v1.7q-1.25 53.7 3.45 100.7.25 3.3.7 6.55l.7 2.8q.55-3.75.75-4.75.35-2 .65-4 1.65-22.15 0-52.7-.5-4.7.85-8.7v-2.4q0-5.65.45-11.25 1.05-13.5.55-27.1-.7-21.5-9.35-44.05-.05-.1-.05-.2l-.8-3.1q-1-2.9-2.35-5.65-1.55-1.9-3.35-3.35z"
                    id="HaiLon001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiLon001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(92.5 48.8)"
                id="HaiLon001__col1n"
            />
        </g></g>
    )
}

export default HaiLon001
