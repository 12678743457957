import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#666666",
    "col1s": "#252525"
}

function ObjToo023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g
                    id="ObjToo023__Symbol_124_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M21.3 67.05h1l5.45-15.3-2.4-1.75q1.3-2.1 2.95-2-2.8-1.55-4.15 2.4l2.05 1.4-4.9 15.25z" />
                    <path d="M24.15 46.25q-2.9 2.65-8.15-.4l2.85 4.35L17 51.95l4.05 11.4 3.6-11.2-2.65-1.4q.9-2.65 2.15-4.5m1.95-1.35l3.75 2v2.8l10.65 4.9L36 42.35h-2.85l-1-3.9q-.5 6.65-6.05 6.45m6.65-9.15l3.7-1.8 2.2 1.8 9.1-4.8-9.6-4.6-1.7 1.05-3.7-1.1q.55.5.95 1 2.55 3.2 0 7.15-.4.65-.95 1.3M8.3 42l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.85.3-1.65.55L.65 53.65l10.65-4.9V45.9l3.75-2Q9.5 44.1 9 37.45l-.35 1.45-.1 1.6q-.1.75-.25 1.5m-.6-8.45q.1.35.25.75l.5.25q-.45-.5-.75-1m-1.8-3.3q.15.15.3.35.15.2.25.4-.4-1.4-.05-2.7.25-1.15 1.1-2.2.3-.35.7-.7.1-.15.25-.3l-3.7 1.1-1.7-1.05L0 26.65q.9.2 1.9.5 2.25 1 3.95 3 0 .05.05.1M5.75 18.5l2.95-.8.4 4.35q.55-4.15 3-5.3.15-.1.25-.15 2.25-1.05 2.85-1.05L11.1 14v-3.35L1.8 6.5l3.95 12m24.3-3.5l-6 1.25q8.25 2 8 6.8l.4-4.35 2.95.8 3.95-12.05-9.3 4.2V15M22 10.95l2.35-1.8L20.3 0l-4.25 9.45 2.2 1.15L17 14.9q3.2-2.55 6.85.15L22 10.95z" />
                </g>
                <g
                    id="ObjToo023__Symbol_105_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M2.85 59.55l14-6.1v-2.9h2.3q-1.5-.8-2.95 0l-.05.05v2.2l-14.1 6.25.8.5M40 39.3l-.05-.1q-.25.05-.5.15-1.95.7-2.15 2.95-.1 1.05.2 2.5h2.8l5.5 15.25.85-.55-5.55-15.3h-2.85q-.1-1 .05-1.9.35-1.75 1.65-2.95l.05-.05m-17.9-8.9q-.2.1-.45.25-.45.3-.9.8-1.25 1.3-1.3 3.2.05 1.9 1.3 3.25l.1.1q1.15 1.2 2.75 1.2.5 0 .95-.1-1.95-.4-3.05-1.5-.9-1-1.05-2.6.25-3.75 2.55-4.45 1-.3 2.45 0-.85-.45-1.85-.45-.7-.05-1.3.2-.05.05-.1.05v.05h-.1M0 29.8q.75 0 1.55.1l5.4-2.7-.8-.45L0 29.8M3.95 7.75l-.7.25 12.7 6v3.15q.05 0 .1.05.2.15.45.3.4.2.95.3h.1q-.85-.5-1.1-1.85V13.7L3.95 7.75m26.85 5.3L24.9 0h-1l5.75 12.8L26.6 15q3.4 4.8 5.3 3.15-1.3.95-3.8-3.05l2.7-2.05m8.5 11.3l-1.7-.65q-1.05 2.4-.3 4 .45 1.05 1.7 1.75.15.1.35.2l.6-.45q-.85-.8-1.3-1.5-1.4-2.1.65-3.35M42.7 41l12.2-6.6-13.15-6.45-.8.45 12.6 6.2L41.4 41h1.3m2.85-31.8L44.6 9l-4.85 15.5h1.05l4.8-15.3h-.05zM11.45 38.15l.3.15q-.05-.05-.1-.15h-.2z" />
                </g>
                <path
                    fill={colors.col1n}
                    d="M42.7 41l12.2-6.6-13.15-6.45-.9.5-.9.75q-.85-.8-1.3-1.5-1.4-2.1.65-3.35l.45.15h1.05l4.8-15.3h-.05L44.6 9l-12.7 6v3.15q-1.3.95-3.8-3.05l2.7-2.05L24.9 0h-1l-6.4 13.55 2.75.95q.55 1.85-.6 2.7-.35.25-.95.45h-.05q-.6.15-1.1.15-.85-.5-1.1-1.85V13.7L3.95 7.75l-.7.25L8.1 23.5l2.15-.8q1 2.25.4 3.8h-.05q-.45 1.05-1.7 1.75-.15.1-.35.2l-1.5-1.2-.9-.5L0 29.8q2.45 0 5.25.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1H7.1q-1.6.6-3.05.75l-2 5.9.8.5 14-6.1v-2.9h2.3l1.5 3.6-1.9 1.4 2.6 6.05v.45l3.3 8.55h1l5.45-15.3-2.4-1.75q1.3-2.1 2.95-2 .05 0 .05.05v2.2l14.1 6.25.85-.55-5.55-15.3h-2.85q-.1-1 .05-1.9.35-1.75 1.65-2.95l.05-.05.05.05L41.4 41h1.3M22.3 30.3q-.05.05-.1.05v.05h-.1l.1-.05v-.05h.1m4.2 1.15q1.2 1.3 1.2 3.2 0 1.9-1.2 3.25-.85.9-1.95 1.2-1.95-.4-3.05-1.5-.9-1-1.05-2.6.25-3.75 2.55-4.45 1-.3 2.45 0 .55.35 1.05.9z"
                    id="ObjToo023__Symbol_183_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo023__Symbol_183_0_Layer0_0_FILL"
                transform="translate(113.5 256.5)"
                id="ObjToo023__col1n"
            />
            <use
                xlinkHref="#ObjToo023__Symbol_124_0_Layer0_0_FILL"
                transform="translate(116.85 260.05)"
                id="ObjToo023__col1d"
            />
            <use
                xlinkHref="#ObjToo023__Symbol_105_0_Layer0_0_FILL"
                transform="translate(113.5 256.5)"
                id="ObjToo023__col1s"
            />
        </g></g>
    )
}

export default ObjToo023
