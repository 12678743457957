import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFD446"
}

function AccJew040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M14.05 7.05q0-2.9-2.05-5Q9.95 0 7.05 0q-2.9 0-5 2.05Q0 4.15 0 7.05T2.05 12q2.1 2.1 5 2.1T12 12q2.05-2.05 2.05-4.95m-3.55-3.5Q11.95 5 11.95 7.1q0 2.1-1.45 3.55-1.45 1.45-3.55 1.45-2.1 0-3.55-1.45Q1.95 9.2 1.95 7.1q0-2.1 1.45-3.55Q4.85 2.1 6.95 2.1q2.1 0 3.55 1.45z"
                    id="AccJew040__Symbol_108_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew040__Symbol_108_0_Layer0_0_FILL"
                transform="translate(278.5 177.85)"
                id="AccJew040__col1n"
            />
        </g></g>
    )
}

export default AccJew040
