import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1n": "#E4B670"
}
function SkiMor037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.5 20.7q-.15-.25-.25-.45t-.15-.4q-.15-.25-.25-.45-1.45-3.05-2.7-5.45-.15-.25-.25-.45l-.2-.4q-.15-.25-.25-.45Q59.4 8.9 58 7.05q-.2-.25-.35-.45-.2-.25-.35-.4l-.15-.15-.3-.3q-2.8-2.9-6.3-3.95-1.35-.45-2.8-.75-7.35-1.45-15-.9-3.4.25-6.9.9-1.45.3-2.8.75-3.5 1.05-6.3 3.95l-.3.3q-.05.1-.15.15-.1.15-.25.3-.15.2-.35.45-1.45 1.9-3.5 5.75-.15.2-.25.45-.1.1-.15.25-.1.2-.25.45-1.3 2.45-2.8 5.6-.1.2-.2.45-.1.1-.1.25-.15.2-.25.45L7.05 23 3.4 30.9q-.6 1.35-1.1 2.55-.1.2-.2.45-.05.1-.1.25-.1.2-.15.45-.8 2.05-1.2 3.65-.25 1.1-.4 2.35-.05.25-.05.5-.05.1-.05.25-.05.25-.05.5Q0 43 0 44.35q0 1.9.25 3.75 0 .35.1.75V49l.1.4q0 .15.05.35l.1.4q.55 2.8 1.75 5.45.05.1.15.25 0 .05.05.15.1.2.2.35v.1q.05.1.15.25.05.05.1.2h.05q.05.1.1.25.1.1.2.25t.2.35q.05.1.15.2 1.2 1.75 3 3.1 2.4 1.75 5.95 2.9 7.3 2.4 23.7 2.25h.9q4 .05 7.45-.05 10.75-.4 16.25-2.2 3.55-1.15 5.95-2.9 1.35-1 2.35-2.25.1-.15.25-.25.15-.3.35-.5.05-.1.1-.15 0-.05.05-.05l.3-.5q.05-.1.15-.2.05-.15.1-.25h.05q0-.1.05-.15.1-.15.15-.25.15-.25.25-.5.05-.1.1-.15.05-.15.1-.25Q72.4 53 73 50.2l.1-.4q0-.2.05-.4l.1-.4v-.1q.05-.4.15-.8.2-1.85.2-3.75 0-1.3-.1-2.5 0-.25-.05-.5v-.25q-.05-.25-.05-.45-.15-1.3-.45-2.4-.4-1.6-1.15-3.6-.1-.25-.15-.45-.1-.15-.15-.3-.1-.25-.2-.45-.5-1.25-1.1-2.55L66.55 23q-.55-1.2-1.05-2.3z"
                    id="SkiMor037__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3 50.2q-.05-.05-.1-.05h-.05q-.25-.05-.5-.05 0 .05.05.15.1.2.2.35v.1q.05.1.15.25.3 0 .65.05h.15q20.15 3.3 39.8 2.75 11.7-.4 23.25-2.15.7-.15 1.4-.25.85-.15 1.7-.25h.15q.35-.05.65-.1.1-.15.15-.25.15-.25.25-.5.05-.1.1-.15-.3 0-.55.05h-.05q-.05 0-.1.05-.85.05-1.6.2l-1.3.2q-12.1 1.8-24.2 2.2-20.15.65-40.2-2.6m63.6-5.95q.7-.15 1.4-.25.85-.15 1.7-.25 1.6-.3 3.15-.6h.25q.05-.4.15-.8H73q-1.3.25-2.55.45-.1 0-.15.05-.85.05-1.6.2l-1.3.2q-12.8 1.9-25.55 2.25Q22.4 46 3 42.85q-.05-.05-.15-.05-1.3-.25-2.55-.45H.1q0 .35.1.75h.25q1.55.3 3.1.55 19.5 3.2 38.5 2.75 12.35-.3 24.55-2.15m4.75-16.1q-.1-.25-.2-.45-.35 0-.7.1-.1 0-.15.05-.85.05-1.6.2l-1.3.2q-14.15 2.1-28.25 2.3-18.1.2-36.15-2.7-.05-.05-.15-.05-.35-.1-.7-.1-.1.2-.2.45-.05.1-.1.25.85.1 1.7.25 18.1 3 35.8 2.8 13.75-.15 27.25-2.2.7-.15 1.4-.25.85-.15 1.7-.25.9-.2 1.8-.3-.1-.15-.15-.3m1.95 7.45v-.25q-.05-.25-.05-.45-1.45.2-2.8.45-.1 0-.15.05-.85.05-1.6.2l-1.3.2q-13.45 2-26.85 2.3-18.8.35-37.55-2.7-.05-.05-.15-.05-1.4-.3-2.75-.5-.05.25-.05.5-.05.1-.05.25.2 0 .45.05 1.55.3 3.1.55 18.8 3.1 37.15 2.8 13.05-.25 25.9-2.2.7-.15 1.4-.25.85-.15 1.7-.25 1.85-.35 3.6-.7M57.5.85q-.2-.25-.35-.4L57 .3l-.3-.3Q45.25 1.1 33.8.95 25.2.85 16.6 0l-.3.3q-.05.1-.15.15-.1.15-.25.3 9.1.95 18.05 1.1 11.85.2 23.55-1m9.1 20.8q.7-.15 1.4-.25.15-.05.3-.05l-.35-.75q-.3 0-.55.05-14.85 2.2-29.6 2.3-16.25.1-32.45-2.3l-.3.65q16.6 2.6 32.9 2.55 14.45-.05 28.65-2.2m-4.85-13.9l-.2-.4q-.15-.25-.25-.45-13.1 1.55-26.15 1.45-11.6-.05-23.1-1.4-.15.2-.25.45-.1.1-.15.25 11.9 1.45 23.65 1.6 13.3.1 26.45-1.5m3.35 6.75q-.1-.2-.15-.4-.15-.25-.25-.45-14.15 1.9-28.25 1.9-13.95 0-27.85-1.85-.1.2-.2.45-.1.1-.1.25 14.25 2 28.3 2.1 14.4 0 28.5-2z"
                    id="SkiMor037__Symbol_47_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor037__Symbol_46_0_Layer0_0_FILL"
                transform="matrix(1.24591 0 0 1 146.65 251.05)"
                id="SkiMor037__col1n"
            />
            <use
                xlinkHref="#SkiMor037__Symbol_47_0_Layer0_0_FILL"
                transform="matrix(1.24591 0 0 1 146.85 256.8)"
                id="SkiMor037__col1d"
            />
        </g></g>
    )
}
export default SkiMor037
