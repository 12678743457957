import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00AFAF"
}

function ShiMot024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M37.75 11.35V0H26.4v3.35h5.45l-5.9 5.95q-1.55-1.1-3.3-1.65-1.85-.6-3.8-.6-1.9 0-3.75.6-.51.156-1 .35-4.502 2.26-6.65 6.7-.095.266-.2.55-.7 2-.7 4.1 0 2.3.8 4.4.75 2 2.2 3.65 1.4 1.65 3.3 2.7 1.247.704 2.6 1.1 3.33.667 6.85 0 1.335-.396 2.55-1.1 1.9-1.05 3.3-2.7 1.45-1.65 2.2-3.65.85-2.15.85-4.4 0-2.1-.75-4.05-.65-1.95-1.9-3.55L34.4 5.9v5.45h3.35m-10.1 7.95q0 1.8-.65 3.45-.7 1.6-1.9 2.8-1.25 1.2-2.8 1.9-1.65.65-3.45.65-1.8 0-3.4-.65-1.6-.7-2.8-1.9-1.25-1.25-1.9-2.8-.7-1.65-.7-3.45 0-1.8.7-3.4.65-1.6 1.9-2.8 1.2-1.25 2.8-1.9 1.6-.7 3.4-.7 1.8 0 3.45.7 1.6.65 2.8 1.9 1.2 1.2 1.9 2.8.65 1.6.65 3.4z"
                    id="ShiMot024__Symbol_52_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot024__Symbol_52_0_Layer0_0_FILL"
                transform="translate(173.65 266.8)"
                id="ShiMot024__col1n"
            />
        </g></g>
    )
}

export default ShiMot024
