import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.6 2.6q.45-.45.45-1.05 0-.65-.45-1.1Q2.1 0 1.5 0 .9 0 .45.45 0 .9 0 1.55q0 .6.45 1.05.45.45 1.05.45.6 0 1.1-.45z"
                    id="AccGla001__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M105.35 24.4q-1.2-4.7-3.8-8.85-1.7-2.75-4.05-5.3-.15-.2-.3-.35Q88.85.9 77.85.1 66.8-.75 59.55 7q-5.35 5.75-6.3 13.7-1-7.95-6.35-13.7Q39.65-.75 28.65.1 17.6.9 9.25 9.9q-.3.3-.55.65l-.3.3q-5 5.65-7 12.4Q.35 26.8.1 30.65q-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9 3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.2-3.2-1-6.2z"
                    id="AccGla001__Symbol_2_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192.9 14.5q-.05-.45-.45-.65-.35-.2-.75-.1l-1.1.25h-.05l-41.3 10.9q-.2-.5-.4-1.2-2.3-7.25-7.85-13.25v-.05Q132.4.95 120.9.1q-11.45-.9-19.1 7.4-3.6 3.85-5.3 8.85-1.75-5-5.35-8.85Q83.5-.8 72.05.1q-11.5.85-20.1 10.3v.05q-5.55 6-7.85 13.25-.2.7-.4 1.2L2.4 14h-.05l-1.1-.25q-.4-.1-.75.1-.4.2-.45.65-.1.4.1.75t.65.45l42.4 11.25q-.6 2.6-.7 5.2v.05q-.85 12.5 6.75 20.7 7.55 8.25 19.1 7.35 11.4-.85 20.1-10.25 5.7-6.3 8.05-13.85 2.3 7.55 8 13.85 8.7 9.4 20.1 10.25 11.55.9 19.1-7.35 7.6-8.2 6.75-20.7v-.05q-.1-2.6-.7-5.2l42.4-11.25q.45-.1.65-.45t.1-.75m-53.35-2.7q8.15 8.8 8.9 20.5v.05q.8 11.55-6.2 19.2-6.9 7.5-17.5 6.7-10.65-.85-18.8-9.6h.05q-5.2-5.65-7.4-12.5-1.7-6.3-1.2-12.6v-.15h.05q.05-7.6 5.6-14.3l.05-.05.2-.2h-.05q7-7.55 17.5-6.75 10.75.85 18.8 9.7m-49.9-2.95l.2.2.05.05q5.55 6.7 5.6 14.3h.05v.15q.5 6.3-1.2 12.6-2.2 6.85-7.4 12.5H87q-8.15 8.75-18.8 9.6-10.6.8-17.5-6.7-7-7.65-6.2-19.2v-.05q.75-11.7 8.9-20.5 8.05-8.85 18.8-9.7 10.5-.8 17.5 6.75h-.05z"
                    id="AccGla001__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <g id="AccGla001__col2n" transform="translate(191 167.6)">
                <use
                    xlinkHref="#AccGla001__Symbol_4_0_Layer0_0_FILL"
                    id="AccGla001__col2n_FL"
                />
            </g>
            <use
                xlinkHref="#AccGla001__Symbol_2_0_Layer0_0_FILL"
                transform="translate(139.3 136.2)"
                id="AccGla001__col2l"
            />
            <g id="AccGla001__col1n" transform="translate(96.05 134.55)">
                <use
                    xlinkHref="#AccGla001__Symbol_3_0_Layer0_0_FILL"
                    id="AccGla001__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default AccGla001
