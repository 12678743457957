import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#C0A594",
    "col1n": "#AC8268",
    "col2n": "#252525"
}

function ObjMus007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M16.4 12.7Q3.2 17.35.2 38.8-.75 47 3 53.8q14.2 25.5 38.75 18.1l-1.25-4.6-.2.05q-.1-25.9-14-51.8l.15-.05-1.1-4.1q-1.4-.05-2.7.05-3.4-5.5-6.25 1.25m225.15 5.5q-5.15-11.6-16.1-14.75-1.4-1.5-3.2-3-1.85-1.5-4.15 1.85h-.05q-1.65-.1-3.35 0l-.35 3.6q-8.95 26.75-5.1 53.45l-.35 3.75q17.65 4.4 30.7-13.15 10.35-14.2 2.35-30.85l-.4-.9z"
                    id="ObjMus007__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M202.05 94.2h.05q2.3.05 4.5.5 1.45.25 2.85.65-.05-23.6-11.25-43.25-2.1-4-4.65-7.85-18.4-25.6-45.1-36.45-37.35-13.55-75.7-3.6-.75.2-1.55.45-34.7 10.5-55.75 39.2Q-2.4 72.9.4 104.6q2.85-1.05 6.25-1.25-1.45-68.2 68.6-92.05 48.7-11.95 87.5 11.05 34.95 24.7 39.3 71.85z"
                    id="ObjMus007__Symbol_62_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.3 8.4L0 10.5l14 51.8 8.35-2.3L7.3 8.4M188.05.85l-7.6-.85-6.1 53.4 8.6.9 5.1-53.45z"
                    id="ObjMus007__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus007__Symbol_93_0_Layer0_0_FILL"
                transform="translate(71.25 136.55)"
                id="ObjMus007__col1n"
            />
            <use
                xlinkHref="#ObjMus007__Symbol_62_0_Layer0_0_FILL"
                transform="translate(87.25 44.65)"
                id="ObjMus007__col1l"
            />
            <use
                xlinkHref="#ObjMus007__Symbol_24_0_Layer0_0_FILL"
                transform="translate(97.55 141.6)"
                id="ObjMus007__col2n"
            />
        </g></g>
    )
}

export default ObjMus007
