import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#0000FF"
}

function AccGla018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    fillOpacity={0.8}
                    d="M72.15 12.7l2.85-6-2.75-.9q-.3-.05-.55-.15Q37.85-3.15 0 5.1l.3.7q.05.2.15.4l1 2.05q.05.15.15.3v.05q4.1 7.5 12.25 12.5 9.1 5.15 23.3 5.7 26.2-.1 35-14.1m85.3-7.9Q143.8 1.1 130.9 0q-21.7.35-43.4 5l-6.45 1.4 2.65 5.55q9.25 15.6 36 14.55.25 0 .5.05 29.5-2.15 36.25-19.3l1-2.45z"
                    id="AccGla018__Symbol_108_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.302}
                    d="M52.2 4.15q-4.6-.75-9.25-.8L33 3.15q-5.3-.05-10.25-1.1-4.7-1.05-9.7-1Q8.2 1 4.1 3.65 1.75 5.2 0 6.75 2.4 10.3 5.75 13q2.9-2.1 6.4-3.3 3-1.05 5.8-1.15.8-.05 1.65 0 1.85.2 2.05 1.5v.75q0 5.6 4.8 4.5.85-.2 2.2-1.15 4-2.2 6.2-6.55.7-1.6 1.45-2 1.35-.9 2.95-.85 4.65-.35 7.3 3.5.25.35.65.65.65 1.2 2.45 1.25.9.25 7.35.5 5.15.15 5.6-2.65.1-1.45-2.15-2.1-4.35-1.15-8.25-1.75m71.7 4.6q1.3.1 2.5.55 2.75 1.1 5.25 2.35 3.05 1.6 6.3 3.3h.1q6.9 3.45 11.55-8-4-.35-6.2-.65-5.35-.7-9.05-3.25-4-2.75-9.5-3-3.2-.2-5.8.4.7 2.1 2.45 4.1 1.45 1.6-1.85 2.4-5.1 1.1-10.3.05-2-.35-3.65-1.7-4.85-3.85-10.65-2.45-.95.25-1.2 1.55-.3 1.45-2.7 1.4-5.4-.25-9.5-3.6h-.1q-1.65.45-3.3.95.65 1.6 1.5 3.1 4.9.15 10.05 1.1 4.55.8 9.15 1.25 4.45.45 8.9 1.2 5 .9 9.7 2.65 2.6.65 1.35-1.2-1.3-1.9-1.35-2.1 1.25-.75 3.45-.6 1.5.1 2.9.2z"
                    id="AccGla018__Symbol_56_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192.15 7.2q.05-1-.5-1.25-.65-.45-2.8 1.35l-13.75 11q-38.15-7.95-72.95.45-2.95.7-5.95.75h-.25Q93 19.45 90 18.75q-34.8-8.4-72.95-.45L3.3 7.3Q1.15 5.5.5 5.95-.05 6.2 0 7.2q.15.9 2.2 2.5l12.95 10.75q4.6 11.4 15.65 17.5 9.7 5 24 5.55 17.65-.15 28.2-6.1 8.45-5.7 12-14.05.45-.65.95-.7.6-.1 1.2.7 3.55 8.35 12 14.05 10.55 5.95 28.2 6.1 14.3-.55 24-5.55 11.05-6.1 15.65-17.5L189.95 9.7q2.05-1.6 2.2-2.5M172.7 20.9q.2 0 .4.05-1.15 2.7-2.7 5.05-2.4 3.55-5.75 6.25-9.7 7.85-27.7 8.5-30.65-.15-36.8-18.5 34.2-9.1 72.55-1.35m-153.65.05q.2-.05.4-.05Q57.8 13.15 92 22.25q-6.15 18.35-36.8 18.5-18-.65-27.7-8.5-3.35-2.7-5.75-6.25-1.55-2.35-2.7-5.05z"
                    id="AccGla018__Symbol_41_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla018__Symbol_108_0_Layer0_0_FILL"
                transform="translate(114.4 169.7)"
                id="AccGla018__col2n"
            />
            <use
                xlinkHref="#AccGla018__Symbol_56_0_Layer0_0_FILL"
                transform="translate(118.15 173.9)"
            />
            <use
                xlinkHref="#AccGla018__Symbol_41_0_Layer0_0_FILL"
                transform="translate(96.45 154.65)"
                id="AccGla018__col1n"
            />
        </g></g>
    )
}

export default AccGla018
