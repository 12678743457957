import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E4B670",
    "col2d": "#E92424",
    "col2n": "#FF5D5D"
}
function FacMou095({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M20.55 22.65q2.916-1.363 3.7-4.15L32.1-.45H-6.4L1.15 17.9q.025.132.05.25.417 1.817 1.65 3.05.85.85 2.1 1.45 2.25.8 4.5 1.2.05-.05.05 0 .15 0 .25.05 2.4.3 4.7.15 1.1-.1 2.15-.25.25-.05.45-.1.3-.1.55-.15 1.5-.35 2.95-.9z"
                    id="FacMou095__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M2 0H0v16.2q.05.85.3 1.25.8.75 1.5 0 .1-.25.1-.55V.55v-.3q0-.15.05-.15L2 0z"
                    id="FacMou095__Symbol_150_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M87.35 27.7q1.85-3.3 1.85-8.35 0-4.25-1.6-6.4-.75-1-1.85-1.7-2.25-2.15-7.85-2.9h-.3q-.75-.05-1.4 0l-6.6-.05q-4.5-.45-10.5-3.6l-.3-.2q-2.1-1.35-3.7-2.25Q49.9-.25 44.65 0h-.1Q39.3-.25 34.1 2.25q-1.6.9-3.7 2.25l-.3.2q-6 3.15-10.5 3.6l-6.6.05q-.65-.05-1.4 0h-.3q-5.6.75-7.85 2.9-1.1.7-1.85 1.7Q0 15.1 0 19.35q0 5.05 1.85 8.35 2.15 3.7 8.5 8.9 1.25 1 3.3 2.25 1.15.65 2.55 1.4 3.85 2.05 5.7 3.65 1.8 1.5 5.75 4.95 1 .9 2.65 1.8 1.4.75 2.85 1.35-.25-.95-.3-2-.05-.5-.05-1 0-.75.2-2.45.05-.65.05-1.15v-.75q-.05-1.75-.3-3.6h.05q-1.2-3.6-.85-5.9 3.45-.35 11.05-1.7h3.25q8.1 1.5 10.95 1.6.35 2.4-.5 5.1-.5 2.3-.55 4.5v.75q0 .5.05 1.15.25 1.7.25 2.45 0 .5-.05 1-.05 1.05-.3 1.95 1.4-.6 2.8-1.3 1.65-.9 2.65-1.8 3.95-3.45 5.75-4.95 1.85-1.6 5.7-3.65 1.4-.75 2.55-1.4 2.05-1.25 3.3-2.25 6.35-5.2 8.5-8.9z"
                    id="FacMou095__Symbol_152_0_Layer0_0_FILL"
                />
                <path
                    d="M203.35 209.4q4.3-3.75 4.75-6.65 0-2.65-3.05-3.9-12.05-4-25.05 0-.85.4-1.5.85-1.6 1.15-1.6 3.05.5 3.05 5.3 6.95 2.55 2.15 4.8 3.4.8.45 1.6.8 3.55 1.7 7.9 0 .7-.3 1.55-.8.8-.45 1.75-1.05 1.45-1 3.1-2.35.25-.15.45-.3z"
                    id="FacMou095__Layer1_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.902}
                    d="M183.85 203.1q0 .85 2.45 1.45 5.85 1.55 12.45 0 2.4-.6 2.4-1.45 0-.4-.55-.55-.75-.1-3.65-.15h-8.9q-2.9.05-3.65.15-.55.15-.55.55z"
                    id="FacMou095__Layer1_1_FILL"
                />
                <g id="FacMou095__Layer1_2_FILL">
                    <path
                        fill="#7E0101"
                        d="M191.3 221.7q.175-.044.4-.05h-.4v.05z"
                    />
                    <path d="M161.65 216.65l-4.15-5.2q.029.165.05.3l-.05-.05q.5 2.85 2.55 5.35.65.95 1.6 1.85 1.3 1.3 2.15 2l.9.8q1.25.95 2.15 1.5 5.6 3.1 10.3 3.1 2.9 0 5.5-.9 4.4-1.1 8.75-1.25.45-.05.85-.05h.55q.4 0 .85.05 4.35.15 8.75 1.25 2.6.9 5.5.9 4.7 0 10.3-3.1.9-.55 2.15-1.5l.9-.8q.85-.7 2.15-2 .75-.75 1.35-1.45l-.2.25q2.45-2.85 3-6l-.1.05q.071-.135.1-.3l-4.15 5.2q-1.3 1.3-2.15 2l-.9.8q-1.25.95-2.15 1.5-5.6 3.1-10.3 3.1-2.9 0-5.5-.9-4.4-1.1-8.75-1.25-.45-.05-.85-.05h-.55q-.4 0-.85.05-4.35.15-8.75 1.25-2.6.9-5.5.9-4.7 0-10.3-3.1-.9-.55-2.15-1.5l-.9-.8q-.85-.7-2.15-2z" />
                </g>
            </defs>
            <use
                xlinkHref="#FacMou095__Symbol_151_0_Layer0_0_FILL"
                transform="translate(179.75 223.35)"
                id="FacMou095__col2n"
            />
            <use
                xlinkHref="#FacMou095__Symbol_150_0_Layer0_0_FILL"
                transform="translate(191.45 222.9)"
                id="FacMou095__col2d"
            />
            <use
                xlinkHref="#FacMou095__Symbol_152_0_Layer0_0_FILL"
                transform="translate(147.9 189.9)"
                id="FacMou095__col1n"
            />
            <use xlinkHref="#FacMou095__Layer1_0_FILL" />
            <use xlinkHref="#FacMou095__Layer1_1_FILL" />
            <use xlinkHref="#FacMou095__Layer1_2_FILL" />
        </g></g>
    )
}
export default FacMou095
