import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function PlaFor022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M159.55 39.55V0H0v39.55h159.55z"
                    id="PlaFor022__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor022__Symbol_28_0_Layer0_0_FILL"
                transform="translate(112.75 145.75)"
                id="PlaFor022__col1n"
            />
        </g></g>
    )
}

export default PlaFor022
