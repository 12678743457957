import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiSpe024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M416.1 71.05q-.15-.4-.25-.8-6.4-23.5-24.95-42.05Q362.7 0 322.7 0q-39.95 0-68.2 28.2-13 13-20 28.5-3.1 6.8-5 14.1l-34.3 85.05q-.45 1.2.05 2.35.5 1.2 1.7 1.7 1.2.45 2.35-.05 1.2-.5 1.65-1.7l29.15-72.3-28.4 79.5q-.4 1.2.15 2.4.55 1.15 1.75 1.6 1.2.4 2.35-.15 1.2-.55 1.6-1.75l32.9-88.9-31 95.5q-.4 1.2.2 2.35.6 1.15 1.8 1.55 1.2.4 2.35-.2 1.15-.6 1.55-1.8l25.8-79.4-22.75 83.35q-.35 1.2.25 2.35.65 1.1 1.85 1.5 1.25.35 2.4-.25 1.1-.65 1.45-1.85l22.45-77.25-16.7 79.5q-.25 1.2.45 2.3.7 1.05 1.95 1.35 1.25.25 2.35-.45 1.05-.7 1.3-1.95l16.5-78.6-8.85 77.6q-.1 1.25.65 2.3.85 1 2.1 1.15 1.25.1 2.25-.65 1.05-.8 1.15-2.1l10.05-88q1-6.25 4.5-9.8 1.75-1.35 3.45-2.65 30.5-23.65 54.8-56.55 22.15 31.8 55.05 56.8 1.7 1.3 3.45 2.6 3.4 3.35 4.1 8.55l10.15 89.05q.1 1.3 1.15 2.1 1 .75 2.25.65 1.25-.15 2.1-1.15.75-1.05.65-2.3l-8.85-77.6 16.5 78.6q.25 1.25 1.3 1.95 1.1.7 2.35.45 1.25-.3 1.95-1.35.7-1.1.45-2.3l-16.7-79.5 22.45 77.25q.35 1.2 1.45 1.85 1.15.6 2.4.25 1.2-.4 1.85-1.5.6-1.15.25-2.35L404.3 96.55l25.8 79.4q.4 1.2 1.55 1.8 1.15.6 2.35.2 1.2-.4 1.8-1.55.6-1.15.2-2.35l-31-95.5 32.9 88.9q.4 1.2 1.6 1.75 1.15.55 2.35.15 1.2-.45 1.75-1.6.55-1.2.15-2.4l-28.4-79.5 29.15 72.3q.45 1.2 1.65 1.7 1.15.5 2.35.05 1.2-.5 1.7-1.7.5-1.15.05-2.35l-34.15-84.8z"
                    id="HaiSpe024__Symbol_156_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe024__Symbol_156_0_Layer0_0_FILL"
                transform="translate(-130.2 57.5)"
                id="HaiSpe024__col1n"
            />
        </g></g>
    )
}

export default HaiSpe024
