import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#C40000",
    "col1n": "#830000"
}

function ObjMor066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#DEDEDE"
                    fillOpacity={0.702}
                    d="M270.4 261.7q0-.85-.6-1.4-.35-.35-.8-.55h-12.4q-.35-.05-.7-.05-.85 0-1.4.6-.65.55-.65 1.4 0 .85.65 1.45.55.55 1.35.55v19.6h12.6v-19.6q.8 0 1.35-.55.25-.25.35-.45.25-.45.25-1z"
                    id="ObjMor066__Layer14_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M12.6 0H0v30.8h.05Q0 31 0 31.15q0 2.6 1.8 4.45 1.9 1.85 4.5 1.85t4.45-1.85q1.85-1.85 1.85-4.45v-2.5q-1.4-.2-2.6-.9-1.4-.8-2.6-2.2-.6-.7-1.05-1.45-1.65-2.7-1.65-6.2t1.65-6.25q.45-.75 1.05-1.45Q8.6 8.8 10 8.05q1.2-.7 2.6-.9V0z"
                    id="ObjMor066__Symbol_159_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M3.65 27.75q-1.4-.8-2.6-2.2-.6-.7-1.05-1.45v6.2q.4 1.05 1.55 1.2.1 0 .25.05 1.5 0 1.85-1.25v-2.55M3.65 0H0v11.65q.45-.75 1.05-1.45 1.2-1.4 2.6-2.15V0z"
                    id="ObjMor066__Symbol_110_0_Layer0_0_FILL"
                />
                <g id="ObjMor066__Layer14_1_FILL">
                    <path
                        fill="#FFF"
                        fillOpacity={0.549}
                        d="M262.2 284.4h3.65v-19h-3.65v19z"
                    />
                    <path
                        fill="#9A9A9A"
                        fillOpacity={0.651}
                        d="M265.15 264.15v.05l-9.3-.05v1.25h12.6v-1.25h-3.3z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor066__Layer14_0_FILL" />
            <use xlinkHref="#ObjMor066__Layer14_1_FILL" />
            <use
                xlinkHref="#ObjMor066__Symbol_159_0_Layer0_0_FILL"
                transform="translate(255.85 281.15)"
                id="ObjMor066__col1n"
            />
            <use
                xlinkHref="#ObjMor066__Symbol_110_0_Layer0_0_FILL"
                transform="translate(262.2 281.15)"
                id="ObjMor066__col1l"
            />
        </g></g>
    )
}

export default ObjMor066
