import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2n": "#6486A6"
}
function FacEar005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.75 13.4q-.05-.25-.15-.5-7.8-6.1-14.65-8.75-6.7-2.6-14.9-4Q1.65-.1 0 .05q.2.1.45.2Q2.9 1.7 4.65 5.5 6.05 8.6 7 13.85q.3 1.7.35 3.35.3 2.95.25 5.5l-.5 4.8-.6 2.3q-1 2.9-2.75 5.8l.1.05q-.2 0 .35.55.6.6 1.3.95.6.35 1.5.65 5.25 5.55 6.35 14.25l.15.9q0 .35.05.75l.1 1.55v1.55q0 1.05-.1 2.1l-.3 2.05q0 .15-.05.25-.05.25-.05.4l-1.2 3.8q-1.35 3.15-2.05 4.35-.05.05-.05.15.2-.05.3-.05.7.1 2.1.1 1.1 0 2.2-.05 7.6-.6 17.45-10 .45-.4 2-1.7.1-.3.15-.5-3.1-11.9-3.1-25.1 0-9.95 1.8-19.2M253.1.25q.25-.1.45-.2-1.7-.15-3.05.1-8.2 1.4-14.9 4-6.95 2.7-14.9 8.9 0 .05-.05.15 1.8 9.3 1.8 19.4 0 13.3-3.15 25.25.1.1.15.2 1.7 1.4 2.15 1.85 9.9 9.4 17.45 10 1.15.05 2.2.05 1.45 0 2.15-.1.1 0 .3.05 0-.1-.05-.15-.7-1.2-2.05-4.35l-1.2-3.8q0-.15-.1-.4v-.25l-.35-2.05q-.1-1.05-.1-2.1v-1.55l.1-1.55q.1-.4.1-.75l.15-.9q1.05-8.7 6.35-14.25.9-.3 1.5-.65.7-.35 1.25-.95.55-.55.4-.55.05-.05.1-.05-1.75-2.9-2.75-5.8l-.6-2.3-.5-4.8q-.05-2.55.2-5.5.1-1.65.4-3.35.95-5.25 2.35-8.35 1.75-3.8 4.2-5.25z"
                    id="FacEar005__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M8.05 37.95l-.3-.1q.25.1.6.25-1.65-.55-1.65-.5-.05-.1-.15-.1 5.25 5.6 6.35 14.3l.15.9q0 .35.05.75l.1 1.55v1.55q0 1.05-.1 2.1l-.3 2.05q0 .15-.05.25-.05.25-.05.4l-1.2 3.8q-1.35 3.15-2.05 4.35-.05.05-.05.15.2-.05.3-.05 3.15-.95 5.9-2.15 9.7-5.3 13.3-14.4Q32 39 26.1 39.1q-.5 0-1.1.15-9.4 1.05-16.95-1.3M2.5.9Q1.65.5.6.15.25.1 0 0q2.45 1.45 4.2 5.25 1.4 3.1 2.35 8.35.25 1.7.35 3.35.3 2.95.25 5.5l-.5 4.8-.6 2.3q-1 2.9-2.75 5.8l.1.05q.25 0 .35.1 10.15 1.75 18.7-1.6 1.65-.55 2.85-1.3 6.95-4.15 1.15-14.6Q19 7.3 2.5.9m243.6 36.6q-.1 0-.15.1.1-.1-1.75.55.4-.2.75-.3-.2.05-.35.1-7.55 2.35-16.95 1.3-.6-.15-1.15-.15-5.85-.1-2.75 13.95 3.55 9.1 13.3 14.4 2.7 1.2 5.85 2.15.15 0 .35.05 0-.1-.05-.15-.7-1.2-2.05-4.35l-1.2-3.8q0-.15-.1-.4v-.25l-.35-2.05q-.1-1.05-.1-2.1V55l.1-1.55q.1-.4.1-.75l.15-.9q1.05-8.7 6.35-14.3M252.05.15Q251 .5 250.1.9 233.65 7.3 226.15 18q-5.75 10.45 1.15 14.6 1.25.75 2.9 1.3 8.5 3.35 18.65 1.6.15-.1.4-.1.05-.05.1-.05-1.75-2.9-2.75-5.8l-.6-2.3-.5-4.8q-.05-2.55.2-5.5.1-1.65.4-3.35.95-5.25 2.35-8.35 1.75-3.8 4.2-5.25-.25.1-.6.15z"
                    id="FacEar005__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar005__Symbol_6_0_Layer0_0_FILL"
                transform="translate(65.75 121.15)"
                id="FacEar005__col1n"
            />
            <use
                xlinkHref="#FacEar005__Symbol_7_0_Layer0_0_FILL"
                transform="translate(66.2 121.4)"
                id="FacEar005__col2n"
            />
        </g></g>
    )
}
export default FacEar005
