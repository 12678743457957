import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFF95",
    "col1n": "#FFFF00",
    "col1s": "#CDBA18",
    "col2n": "#FF0000"
}

function AccGla023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M266 177v-27.25H112.25V177H266z"
                    id="AccGla023__Symbol_111_0_Layer0_0_FILL"
                />
                <path
                    fill="url(#AccGla023__Gradient_1)"
                    d="M267 178.5V148H110.75v30.5H267z"
                    id="AccGla023__Layer8_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M212.2 25q0-7.15-3.6-12.2-2.9-3.95-6.7-4.8h-.05q-.55-.15-1.15-.2-.85-1.2-1.8-2.25-4-4.2-10.1-5.2-3.2-.55-7-.2l-2.4.3q-7.3.9-15.1 1.6-26.2 2.4-58.15 3h-.1Q64.55 4.3 32.8.45l-2.4-.3q-3.8-.35-7 .2-6.1 1-10.1 5.2-.95 1.05-1.8 2.25-.6.05-1.15.2h-.05q-3.8.85-6.7 4.8Q0 17.85 0 25q0 7 3.5 12.05.05.05.1.15 2.45 3.3 5.55 4.45.7.25 1.45.45 2.35 4.5 6.25 8.25 2.3 2.15 5.05 4.1.45.3.95.65 6.45 4.3 15.5 7.85 3.8 1.3 8 2.35 5.15 1.2 10.8 1.85 5.3.4 10.6.7 7.85.45 15.75.8 2.05.05 4.2.1 4.55-.25 7.5-2.65.85-1.65 2.35-3 .2-.2.4-.35 1.25-1 2.6-1.6v.15q1.8-.85 3.8-1.05.65-.1 1.25-.1h1q.6 0 1.25.1 2 .2 3.8 1.05v-.15q1.35.6 2.6 1.6.2.15.4.35 1.5 1.35 2.35 3 2.95 2.4 7.5 2.65 2.15-.05 4.2-.1 7.9-.35 15.75-.8 5.3-.3 10.6-.7 4.8-.55 9.25-1.5.8-.15 1.55-.35 4.2-1.05 8-2.35 9.05-3.55 15.5-7.85.5-.35.95-.65 2.75-1.95 5.05-4.1 3.9-3.75 6.25-8.25.75-.2 1.45-.45 3.1-1.15 5.55-4.45.05-.1.1-.15Q212.2 32 212.2 25m-38.1-1.1q2.25-.05 4 .2 3.65.5 4 6l.05 5.9q0 .1-.05.2v.15q-.45 3.8-4.45 5.25-1.25.45-2.8.7h-.05l-.3.05q-4.95.6-10.2 1.1-25.65 2.4-58.15 2.9h-.1q-39.15-.6-68.35-4l-.3-.05h-.05q-1.55-.25-2.8-.7-4-1.45-4.45-5.25v-.15q-.05-.1-.05-.2l.05-5.9q.35-5.5 4-6 1.75-.25 4-.2.95 0 2 .1 1.35.1 2.85.3 28.35 3.15 57.6 3.6v.05h11.1v-.05q26.7-.4 52.65-3.05 2.5-.3 4.95-.55 1.5-.2 2.85-.3 1.05-.1 2-.1z"
                    id="AccGla023__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M189.65 174.9h-.1q-39.15-.6-68.35-4l-.3-.05h-.05q-1.55-.25-2.8-.7-4-1.45-4.45-5.25l-.05 1.9q0 .1.05.2l-.05 1.8q.25 3 2.5 4.6 1.05.75 2.65 1.25.95.3 2.15.45l.35.05q31.8 3.65 62.85 4.05v.05h11.1v-.05q26.05-.35 52.65-2.95 5.1-.5 10.2-1.1l.35-.05q1.2-.15 2.15-.45 1.6-.5 2.65-1.25 2.25-1.6 2.5-4.6l-.05-1.8q.05-.1.05-.2l-.05-1.9q-.45 3.8-4.45 5.25-1.25.45-2.8.7h-.05l-.3.05q-4.95.6-10.2 1.1-25.65 2.4-58.15 2.9m-.1-37.5h5.6v-.05q26.05-.3 52.65-2.9 7.55-.75 15.1-1.7l2.4-.3h.05q2.35-.2 4.45-.1 6.65.4 11.2 4.2 1.95 1.65 3.55 4 .15.3.25.6 3.1 7 3.7 14 .05.4.05.8.45-9.3-3.75-18.55-.1-.3-.25-.6-.2-.25-.35-.45-.85-1.2-1.8-2.25-4-4.2-10.1-5.2-3.2-.55-7-.2l-2.4.3q-7.3.9-15.1 1.6-26.2 2.4-58.15 3h-.1q-41.5-.75-73.25-4.6l-2.4-.3q-3.8-.35-7 .2-6.1 1-10.1 5.2-.95 1.05-1.8 2.25-.15.2-.35.45-.15.3-.25.6-4.2 9.25-3.75 18.55 0-.4.05-.8.6-7 3.7-14 .1-.3.25-.6 1.6-2.35 3.55-4 4.55-3.8 11.2-4.2 2.1-.1 4.45.1h.05l2.4.3q34.3 4.2 67.75 4.6v.05h5.5z"
                    id="AccGla023__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.6.2h-.05Q0 7.6 0 18.1q0 4.35.8 8.25.8 4.05 2.6 7.5.7.25 1.45.45h.05q-.1-.2-.2-.3-3.4-6.8-3.25-15.2-.05.4-.05.8-.45-9.3 3.75-18.55.1-.3.25-.6.2-.25.35-.45-.6.05-1.15.2m190.7.25q.15.3.25.6 4.2 9.25 3.75 18.55 0-.4-.05-.8.15 8.4-3.25 15.2-.1.1-.2.3h.05q.75-.2 1.45-.45 1.8-3.45 2.6-7.5.8-3.9.8-8.25 0-10.5-4.55-17.9h-.05q-.55-.15-1.15-.2.15.2.35.45z"
                    id="AccGla023__Symbol_6_0_Layer0_0_FILL"
                />
                <linearGradient
                    id="AccGla023__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={262.275}
                    y1={163.25}
                    x2={118.025}
                    y2={163.25}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopOpacity={0.6} />
                    <stop offset="28.235%" stopOpacity={0} />
                    <stop offset="69.804%" stopOpacity={0} />
                    <stop offset="100%" stopOpacity={0.6} />
                </linearGradient>
            </defs>
            <use
                xlinkHref="#AccGla023__Symbol_111_0_Layer0_0_FILL"
                id="AccGla023__col2n"
            />
            <use xlinkHref="#AccGla023__Layer8_0_FILL" />
            <use
                xlinkHref="#AccGla023__Symbol_46_0_Layer0_0_FILL"
                transform="translate(83.5 128.55)"
                id="AccGla023__col1n"
            />
            <use
                xlinkHref="#AccGla023__Symbol_11_0_Layer0_0_FILL"
                id="AccGla023__col1l"
            />
            <use
                xlinkHref="#AccGla023__Symbol_6_0_Layer0_0_FILL"
                transform="translate(89.25 136.35)"
                id="AccGla023__col1s"
            />
        </g></g>
    )
}

export default AccGla023
