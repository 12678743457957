import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#F7DD00",
    "col1n": "#FFFF00",
    "col1s": "#D2AD00"
}

function AccHat025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M215.7 68.8q-6.55-37.8-42-58-53.8-22.6-106.65 2Q33.3 32.5 27.5 80.35q-.45 3.7-.75 7.6-.2 2.95-.3 6-.05.4-.05.8l-.1 3.45L.95 140.8q-1.9 3.25 0 3.8H2.4v.1h.1l.1.05q18.5 4.6 34.65-.1-1.6-5.4-3-8.9-1.65-4.05-1.65-7.55v-3.5q-.05-.05-.05-.1 2.8-3.9 7.5-11.85l.05-.1q1-1.65 1.95-3.3 1.05-1.85 2.05-3.8 2.6-5.15 4.65-10.8l.3-.8q.35-.95.65-1.9 4.2-2.15 8.4-4.1 8.8-4.15 17.5-7.6Q148.35 51.4 215.7 68.8z"
                    id="AccHat025__Symbol_243_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M13.55 20.9q-.05-.2-.25-.3-.2-.15-.35-.05-.2 0-.3.2Q-2.7 46.8.55 76.55q.05.2.2.35.15.15.35.1.2-.05.35-.2.15-.15.1-.35Q-1.7 47 13.5 21.25q.1-.2.05-.35M47 3.2q-.15-.15-.35-.2-.2-.05-.35.1-.15.15-.2.35-.05.2.1.35 14.7 18.4 14.45 51.7 0 .2.15.35.15.15.35.15.2 0 .35-.15.15-.15.15-.35Q61.9 21.8 47 3.2m103.75 44.45q.05.2.25.3.15.1.35.05.2-.1.3-.25.1-.2.05-.4Q140.3 13.2 102.85.05q-.25-.1-.4 0-.2.1-.25.3-.1.2 0 .4.1.15.3.25 37 13 48.25 46.65z"
                    id="AccHat025__Symbol_119_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat025__Symbol_155_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M30.1.1h.1q-.05-.05-.05-.1-.05.05-.05.1z" />
                    <path d="M30.05 0l-.3.4Q16.5 18.3 2.6 19.95q-.3 0-.6.05l-1.9.1H0l4 2.05q.5.2 1.05.45.5.25 1 .45 13.7 6.4 26.55 0 3.45-1.5 4.6-3.75l-2.1-4q-.25-.6-.45-1.2Q31.8 6.65 30.2.1h-.1q-.05-.05-.05-.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat025__Symbol_243_0_Layer0_0_FILL"
                transform="translate(68.15 55.25)"
                id="AccHat025__col1n"
            />
            <use
                xlinkHref="#AccHat025__Symbol_155_0_Layer0_0_FILL"
                transform="translate(70.65 179.85)"
                id="AccHat025__col1d"
            />
            <use
                xlinkHref="#AccHat025__Symbol_119_0_Layer0_0_FILL"
                transform="translate(110.65 61)"
                id="AccHat025__col1s"
            />
        </g></g>
    )
}

export default AccHat025
