import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#002A00",
    "col1l": "#008300",
    "col1n": "#005100"
}

function AccMor055({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M171.6 2.9q-2.3-1.2-6.1-1.15-2.4-.05-20.35 1.6-6.3.55-13.55.95-51 2.75-98.35.05-10-.75-17.5-2.85Q3.5-1.7.2 2 0 2.55 0 3.45q0 1 .8 2.25.3.55.8 1.15.4.5.9 1 1.25 1.35 2.7 2.6 1.55 2.4 2.3 4.1 1.25 2.85 1.1 5.3-.15 2.65-.2 3.9v1q.25 2.3 1.65 3 .1.05.25.1.4.15.9.2 3.9.3 6.05 2.9.8 1 1.35 2.3l.2.5q.55 1.65 2.9 8.8 1.4 4.25 3.8 6.55l.4.4q2.95 1.3 7 1.95 20.25 2.65 36.75 0h.2l1.35 3.95q3.6 6.1 4.75 12.25l1.05 38.8q.7-.15.75-.05l6.15-1.95v-.05q0-7.25-.1-12.95-.1-3.1-.2-5.7-.1 0-.2.05v-.15q2.7-.45 6.75-1.4.25 2.75.4 6.05 0 1.05.05 2.2.15 6.65.3 9.7l6.1-1.9v-.35q.15-6.5.15-8.95v-1.6q-.05-4.35-.3-6.65-.1 0-.15.05-.05-.1-.05-.15 2.6-.65 5.55-1.45v.6q0 2.8.5 6.65.1.95.35 2.85l.4 3.7q.15 1.8.65 3.2l5.95-2q-.55-7.15-.7-8.65-.15-1.4-.35-3.8-.15-2.35-.2-4.15-.1 0-.2.05v-.15q3-.9 5.6-1.65.55 2.75 1.6 8.6 1.15 5.4 1.35 7.55l6.85-2.2v-.35l-1.45-36.15q0-1.6.15-2.7 1.25-4.45 7.95-7.6 1.5-.7 3.6-1.6 7.8-1.4 17.25-3.3 8.35-1.45 11.25-6.4 1.7-2.9 1.7-8.35.25-2.2-.45-4.3-.15-.45-.3-.8.65-.5 1.2-1.05 4.6-.65 7.55-4.85 2.5-3.65 2.5-6 0-2.1-.95-3.45-.9-1.25-2.4-2M29.55 50.1l.05-.05q.1.05.2.15-.15-.05-.25-.1M77.1 82.25l.05.05q-.047.047-.05.15v-.2z"
                    id="AccMor055__Symbol_152_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M73.3 84q-.1 0-.2.05-1.2.2-2.15.3l.8 18.95 1.85-.6v-.05q0-7.25-.1-12.95-.1-3.1-.2-5.7M6.95 29.2q9.15-1.25 13.45-1.6 6.2-.5 17.9-.9-2 0-6.3-.2-5.6-.3-8.1-.4H0q.4.15.9.2 3.9.3 6.05 2.9M86.55 81q-.1 0-.15.05-1.25.3-2.3.6l.6 17.55 2-.65v-.35q.15-6.5.15-8.95v-1.6q-.05-4.35-.3-6.65m11.75-3.1q-1.35.35-2.8.75l.9 16.85 3.35-1.05q-.55-7.15-.7-8.65-.15-1.4-.35-3.8-.15-2.35-.2-4.15-.1 0-.2.05M83.7 45.7h-.2q-7.25 1.4-21.2 3.6-1.35.2-2.75.4L60.7 53q2.15-.8 5.15-1.8 0-.05.05-.05 2.2-.75 4.85-1.6 7.1-2.25 12.95-3.85m78.45-33.1q2.5-3.65 2.5-6 0-2.1-.95-3.45-.9-1.25-2.4-2Q159-.05 155.2 0q-2.4-.05-20.35 1.6-6.3.55-13.55.95-51 2.75-98.35.05H22q19.9 2.65 68.25 4.35h.25q.55 0 1.05.05h.95q1.7.05 2.9.1.55.05 1 .05.8-.05 1.7-.05 1.6-.05 3.5-.1h4.05q23.7-.05 32.75 1.75 7 1.35 7 4.4 0 .25-.05.45-2.05 1.6-14.45 6.4l-2.4.95q-15.3 4.75-42 5.3-17.7.25-28.25.1-8.9-.15-11.5.35 2.15.2 7.45.85 43.45 2.95 72.7-1 7.1-1.9 11.25-3.95 1.8.7 2.2 3.1.05.5.05 1.15 0 4.75-18.25 10.35-22.05 5.9-29.9 6.25 6.3.35 10.35 1.45 5.15 1.4 6.35 4.1 1.45 3.35 1.45 11.45 0 2.3-.05 4.5l1 25.85 2.35-.75v-.35l-1.45-36.15q0-1.6.15-2.7 1.25-4.45 7.95-7.6 1.5-.7 3.6-1.6 7.8-1.4 17.25-3.3 8.35-1.45 11.25-6.4 1.7-2.9 1.7-8.35.25-2.2-.45-4.3-.15-.45-.3-.8.65-.5 1.2-1.05 4.6-.65 7.55-4.85z"
                    id="AccMor055__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M57.25 27.85l-.35-.05-8.4-.25-.4-.05-7.7-.25-.3-.05-4.55-.05q-4.05-.35-5.4-.35H23q-.45.1-.95.2-2.55-.1-4.85-.1.55 1.65 2.9 8.8 1.4 4.25 3.8 6.55 3.35.25 10.6.25 2.9 0 4.9-.25.25-.15.6-.2 1.8-.3 3.15-1.15.15-.25.3-.55-1.45-1-4.4-2.2-1.4-.6-1.4-2.35t1.55-2.55q1.5-.8 8.9-1.4 7.4-.6 21.65.8 2.9.05 4.6 0 1.6-.05 2.65-.5 1.45-.6 1.45-1.35 0-1.55-4.45-2-7.55-.4-13.8-.9l-2.95-.05m35.7 16.85q-1.7 0-6.2 1-4.45 1-5.1 1.1l-5.6.8q-1.5.25-2.5.5-1.95.25-3.95.45 3.6 6.1 4.75 12.25l.35 12.75 9.35-2.7q2.6-.85 5.85-1.7.1-.05.25-.05-1.6-4.65-2.1-6.25-1.65-5.4-1.65-7.65 0-3.1 2.1-4.7.05-.05.15-.05l.1-.05q1.2-.55 3.15-1.45 3.05-1.35 3.05-2.65.05-.4-.15-.8-.45-.8-1.85-.8M81.9 9.25q1.25-.75 1.25-1.7 0-.85-2.3-1.4-2.3-.35-6.25-.4-8.4-.15-9.1-.4-.3-.1-7.75-.5-.1-.1-.2-.15-2.6-.1-5-.2Q35.1 2.45 17.6 1.15 8.8.5 0 0q.4.5.9 1 1.25 1.35 2.7 2.6Q5.15 6 5.9 7.7 7.15 10.55 7 13q-.15 2.65-.2 3.9l.8-.15h.15q.2.1.65.1l.65-.1h.05l19.7.9.3.1h3.1q.85-.15 1.05-.65.1-.2.1-.8 0-1.85-4-3.5-2.75-1.15-3.6-2.5-.1-.35-.1-.7 0-1.45.95-1.95 1.35-.8 5.4-.75 5.75-.05 24.5 1.25 1.35 0 6.85.65 1.2.05 2.5.15 4.15.1 6.2.3 3.85.05 8.3.1.8-.05 1.55-.1z"
                    id="AccMor055__Symbol_58_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor055__Symbol_152_0_Layer0_0_FILL"
                transform="translate(103.4 197.75)"
                id="AccMor055__col1n"
            />
            <use
                xlinkHref="#AccMor055__Symbol_94_0_Layer0_0_FILL"
                transform="translate(113.7 199.5)"
                id="AccMor055__col1d"
            />
            <use
                xlinkHref="#AccMor055__Symbol_58_0_Layer0_0_FILL"
                transform="translate(105 204.6)"
                id="AccMor055__col1l"
            />
        </g></g>
    )
}

export default AccMor055
