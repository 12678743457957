import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFCDE8",
    "col2n": "#FFFFFF"
}

function LegSki015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.7 12.85q-.25-.65-.55-1.25l-.4-.8q-1.35-2.5-3.15-4.7-1.75-1.6-3.6-3-3.5-2.3-7.9-3.1l-2.5.6q-.85.15-1.65.35-1 .25-2 .45-1.95.45-3.85.85l-4.5.9q-2.25.4-4.45.8-4.45.75-8.85 1.35l-3.5.5q-4.45.55-8.9 1-1.05.05-2.1.2-11.6.95-23.15.95h-.3q-10.4 0-20.8-.8-.85-.1-1.7-.15-6-.55-12-1.3-1.7-.25-3.45-.45-2.9-.45-5.75-.9-2.4-.4-4.7-.8-2.2-.4-4.4-.85-2.2-.4-4.3-.85-1.5-.35-3-.65-.5-.15-1-.25-.3-.05-.55-.1Q19.1.5 17.55.1q-.1 0-.2-.05-.25 0-.5.05-3.9.45-7.35 2.4-2.35 1.3-4.45 3.3l-.3.3-.1.1Q2.3 8.7.75 11.6q-.15.25-.25.5l-.4.8-.1.2q.2 0 .45.05.4.05.75.05 3.25.25 6.45.45h.25q1.05.05 2.1.15l2.75.2q71.5 5.5 141.8-.3.9-.05 1.75-.1h.5q3.5-.25 6.95-.5h.1q-.1-.1-.1-.2l-.05-.05M80.05 10.2h.15v.05l-.15-.05z"
                    id="LegSki015__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M217.2 322.9q-.35 0-.7.05l-6.05.1h-.7l-6.55.1h-.2q-3.45 0-6.8.05h-7.5q-3.25 0-6.5-.05h-.5q-3.45 0-6.9-.05.1.45.4 1.6.95 1.8 3.25 1.8 1.8 0 2.85-1.4.35-.45.6-.9.1.45.3 1.25.95 1.8 3.25 1.8 1.8 0 2.85-1.4.45-.55.7-1.2.1.35.2.8.95 1.8 3.25 1.8 1.8 0 2.85-1.4.45-.55.7-1.2.1.35.2.8.95 1.8 3.25 1.8 1.8 0 2.85-1.4.75-.9.9-1.95.1.45.25 1.05.95 1.8 3.25 1.8 1.8 0 2.85-1.4.35-.4.55-.8.05.15.1.4.95 1.8 3.25 1.8 1.8 0 2.85-1.4.35-.45.6-.9 0 .1.05.25.95 1.8 3.25 1.8 1.8 0 2.85-1.4.4-.5.65-1v.1q.3.6.75 1 .95.8 2.5.8 1.8 0 2.85-1.4.45-.55.65-1.05.1.35.25.9.95 1.8 3.2 1.8 1.8 0 2.85-1.4.75-.95.9-1.95.1.4.25 1.05.95 1.8 3.25 1.8 1.8 0 2.85-1.4.65-.8.85-1.65.1.5.3 1.25.95 1.8 3.35 1.8 1.9 0 3-1.4.7-.85.85-1.8.1.45.3 1.15.95 1.8 3.25 1.8 1.8 0 2.85-1.4.65-.8.85-1.65 0 .1.05.25.95 1.8 3.25 1.8 1.8 0 2.85-1.4.45-.55.7-1.2.1.35.2.8.95 1.8 3.25 1.8 1.8 0 2.85-1.4.9-1.1.9-2.4 0-.25-.05-.5-3.45.25-6.95.5h-.5q-3.3.2-6.55.4-.2 0-.35.05-3.45.25-6.85.45-.15-.05-.25 0-3.65.15-7.25.35h-.25l-7 .3h-.15q-3.45.15-6.8.25-.25 0-.5.05-2.9.05-5.75.15-.25 0-.5.05h-.6q-3.1.1-6.15.15m-92.2-1.7h-.45q-3.45-.25-6.8-.45h-.25l-6.45-.45-.75-.05q-.25-.05-.45-.05-.073 1.817 1.2 3.25.992 1.3 2.95 1.3 1.8 0 2.85-1.4.5-.6.75-1.25.4 2.9 3.65 2.9 2 0 3-1.65.25-.4.45-.8.1.45.3 1.15.95 1.8 3.25 1.8 1.8 0 2.85-1.4.35-.4.55-.8.05.15.1.4.95 1.8 3.25 1.8 1.8 0 2.85-1.4.6-.7.8-1.5.1.5.35 1.35.95 1.8 3.25 1.8 1.8 0 2.85-1.4.35-.4.55-.8l.1.4q.95 1.8 3.35 1.8 1.85 0 2.95-1.4.6-.75.8-1.5.1.45.35 1.35.95 1.8 3.25 1.8 1.8 0 2.85-1.4.55-.7.75-1.4.05.2.15.55.1.4.25.95.95 1.8 3.25 1.8 1.8 0 2.85-1.4.65-.8.85-1.65.1.5.3 1.25.95 1.8 3.25 1.8 1.8 0 2.85-1.4.8-.95.9-2-3.65-.1-7.25-.15h-.2q-3.4-.1-6.8-.15h-.3q-.1-.05-.2 0-3.5-.15-7.05-.25h-.1q-3.4-.1-6.8-.25h-.65l-6.6-.3q-.1-.05-.25 0-3.25-.2-6.5-.35-.35 0-.75-.05-3.15-.15-6.25-.4z"
                    id="LegSki015__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki015__Symbol_81_0_Layer0_0_FILL"
                transform="translate(109.85 307.1)"
                id="LegSki015__col1n"
            />
            <use
                xlinkHref="#LegSki015__Symbol_23_0_Layer0_0_FILL"
                id="LegSki015__col2n"
            />
        </g></g>
    )
}

export default LegSki015
