import { AssetSvgProps } from "../../../shared/assets"

function PlaInd011({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#808CAA"
                    d="M385 385V0H0v385h385z"
                    id="PlaInd011__Layer8_0_FILL"
                />
                <path
                    fill="#E1E1E1"
                    d="M37.55 69.55L14 72.65v29.9l23.55-3.65V69.55M130.65 0h-35.4L37.6 5.65h-.05L62.8 26.4l7.55-.9L51.1 8l79.55-8m181.2 32l-14.05-6.55.1.35q-3.884-1.367-22.3-4.45l-8.7 1.1-5.75 10.05-190.8 26v7.8L311.85 32m21.65 158.05V121.8l-22.85-13.35L280.3 94l-90.05 16.95v.05l-57.75 10.2v-.05l-20.8 3.75v.05l-9.95 1.75h-.05l-31.35 5.55v33.35l143.15-29.35q1.35-.3 2.75-.6.1 0 .2-.05.6-.1 1.2-.25 4.1-.8 7.9-1.25 14.2-1.75 25.65.75 8.85 1.9 16.05 6.45l66.25 48.75M385 16.7v-3.35L343.75 0h-9.6L385 16.7m0 23.25V35.4l-51.5 6.7 7.9 3.7 43.6-5.85z"
                    id="PlaInd011__Layer6_1_FILL"
                />
                <path
                    fill="#666"
                    d="M311.85 36.1l-25.8 3.65v7.05h6.1l19.7-10.7z"
                    id="PlaInd011__Layer6_2_FILL"
                />
                <path
                    fill="#FFF"
                    d="M25.25 90.6l12.3 1.5v-3.5l-12.3-3.1v5.1M78 22.3l-9-8.1-8.45.9 8.85 8.15 8.6-.95m7.2-9.85l8.8 8.1 9.8-1.1-8.1-8.15-10.5 1.15m-3.95 9.5l9.1-1-8.85-8.1-8.95.95 8.7 8.15M108.1 19l10.9-1.25-7.75-8.15-11.45 1.3 8.3 8.1m46.95-14.1L143.7 6.15l10.15 7.75 12-1.3-10.8-7.7m-32.4 12.45l11.05-1.2-8.9-8-10.35 1.1 8.2 8.1m17-10.8l-10.95 1.2 9.2 7.9 12-1.3-10.25-7.8m47.9-5.15l14.65 7.1 9.4-1.05-13.5-7.2-10.55 1.15M170.3 12.1L180 11l-11.8-7.5-9.75 1.05 11.85 7.55m27.15-3.05l-14.9-7.15-9.35 1.05L186 10.3l11.45-1.25m43-4.75L231.6 0h-12.2l10.7 5.45 10.35-1.15M214.25 0h-11.2l13.8 6.9 8.75-1L214.25 0m39.25 2.85h.15L247 0h-11.2l8.1 3.9 9.6-1.05M251.4 0l5.5 2.4 7.2-.75L259.95 0h-8.55z"
                    id="PlaInd011__Layer5_2_FILL"
                />
                <path
                    fill="#CCC"
                    d="M107.2 178.85h-.75l-.05.35q0 .4.15.65 0 .6-1.45.6-1.5 0-1.5.75 0 .7.9 1.2l.2.1q2.3-1.1 3.95-2.35-.35-.4-.45-.65-.5-.55-1-.65m-11.85 5.4q-.05.05-.1.05-.8.6-.75 1.4 1.25-.25 2.55-.6.05 0 .2-.05 2.3-.5 4.45-1.3-.65-.7-1.5-.7-.75 0-1.85.35-.35.15-.65.3-.5.1-.75.1-.9 0-1.6.45m-3.3-2.35q.1 0 .45.45.45.45.65.45.85 0 .85-1.4 0-1.65-.75-1.65l-.85.1q-.45 0-.65-.25-.3-.4-.55-.4-.5 0-1.3.5-.3.15-.5.45-.1.15-.1.4-.1.2-.1.6 0 .3.1.5.1 1.3.95 1.3.65 0 1-.45.1-.05.15-.1.35-.5.65-.5m8.1-8.05q-1.8.25-1.8 1.55 0 .05.1.25.05.4.25 1.2.35-.9 1.45-.9.3 0 .6.9.05.05.05.1.4 1 1.65 1 .95 0 1.1-1.05.1-.6.15-1 .05-.45.25-.65-.35.3-.65.3-1 0-1-2.05 0-.4.1-.75-.5.75-2.25 1.1m-15.6-1.45q-.15-.1-.2-.15.15.3.15.55 0 .85-.65 1.85-.05.15-.25.4-.65.75-1.4.75-.15 0-.35-.15-.15-.1-.35-.45-.1-.15-.1-.3l-.1-.05v3.3q.1 1.2.85 1.2.65 0 1.3-.25.35-.1.55-.25.4-.25.7-.55v-.4q0-.55-.15-.85-.2-.4-.2-.6 0-.15.2-.35.05-.2.3-.35.15-.1.25-.15.75-.55.75-.8 0-.2-1.15-2.05-.1-.15-.15-.35m1.35-5.65q-.25 0-.45.2.75.2 1.1 1.25.35 1.2 1.15 1.2.75 0 2.2-1.15.8-.6 1.55-.95h-.05q-.5 0-1.3.5-.8.45-1.4.45-.6 0-1.3-.75-.75-.75-1.5-.75M75.4 182q-.35 0-.85.45-.35.35-.4.75-.4-.15-.75-.4-.5-.4-.55-.4l-.7.25q-.3.05-.35.1v.3q0 1 1 1.4.1.05.45.1.55.15 1.55.15.55-.1 1.1 1.15 1.75.25 3.65.45.5-.5.5-1.1 0-.6-.5-1.2-.6-.75-1.25-.75-.9.35-1.5.35-.55 0-.7-.8-.1-.55-.4-.75-.15-.05-.3-.05m-1.75-4.05q0-.15-.1-.4l-.4.1q-.8.45-1.6.45-.75 0-1.2-.2v2.55q.1-.05.15-.05l1.65-1.1q1.5-.95 1.5-1.35z"
                    id="PlaInd011__Layer5_3_FILL"
                />
                <g id="PlaInd011__Layer7_0_FILL">
                    <path
                        fill="#8B8B8B"
                        d="M32 156.95v-5.45q-.66 2.903 0 5.45m278.65-107.4l-.65-.6q-21.33 3.447-98.45 11.25L70.35 81v51.25q31.142-3.051 46.4-4.8 9.533-1.046 12.85-1.6 3.245-.537.5-.6.776-.917 1.45-2.1l6-.75h.05q1.817.414 3.6.7 5.646.428 14.6 1.25 20.556-.29 34.45-13.35v9.95q51.998-6.62 90.05-26.95 5.529 17.748 30.35 14.45v-58.9m-198.1 75.85q.522.307 1 .55-5.145.326-11.8.75l10.8-1.3M286.05 3.25V0H14v72.65q14.617 11.713 23.55-3.1v35.6q1.236 1.212 2.2 3.95 1.75 5 1.8 13.65 1.15 10.4 1.4 16.9.05 2.1.05 14.9 0 3.3-.65 14.7-.55 10.15-.35 13.8.1 2.6-1.6 7.5-1.8 5.2-1.9 6.75-.35 4.5 0 13.7 0 19.3 8.25 38.85Q48.5 254 52.4 261.9q.926 1.835 1.5 3.15l8.9 14.85q-21.713-127.655 0-253.5l7.55-.9v44.95l116.2-16.55q.25-.05.5-.05l10.55-1.55h.1l20.6-2.9q.2-.05.4-.05l67.25-9.55.1-.05 25.8-3.65V32l-14.05-6.55-1-3.2q-3.137-.19-10.75-19M182.9 10.7v-.15l.15.15h-.15m-47.15 5.2v-.1l.1.1h-.1m249.25.8V0h-50.85Q362.198 3.99 385 16.7z"
                    />
                    <path
                        fill="#B7B7B7"
                        d="M37.55 69.55Q28.617 84.363 14 72.65v33.75l23.55-3.6V69.55m76 56.4q-.478-.243-1-.55l-10.8 1.3q6.655-.424 11.8-.75m219.95 176.7q8.522-89.573 0-180.85-32.18 5.959-22.85-13.35-24.821 3.298-30.35-14.45-38.052 20.33-90.05 26.95V111q-13.894 13.06-34.45 13.35-8.954-.822-14.6-1.25-1.783-.286-3.6-.7h-.05l-6 .75q-.674 1.183-1.45 2.1 2.745.063-.5.6-3.317.554-12.85 1.6-15.258 1.749-46.4 4.8v37.25q18.014 9.096 28.3-5.85 59.837.434 113.3-23.35 2.3-.5 4.5-1 .602-.104-.5 2.9-1.106 3.062 49.8 3.1l39.4 29.65V276.1l1.5 1.45q10.392 12.55 26.85 25.1z"
                    />
                    <path fill="#CCC" d="M7.1 0H0v175.1l7.1 10.65V0z" />
                    <path
                        fill="#A6A6A6"
                        d="M14 0H7.1v185.75H14v-16.4l.1-.2q8.282-11.555.25-23.15l-.1-.15q-.13-.178-.25-.35V0m56.35 25.5l-7.55.9q-21.713 125.845 0 253.5l7.55-1.95v-11.6q20.776-47.04 0-96.85v-144M385 35.4l-51.5 6.7v79.7q8.522 91.277 0 180.85v3.3l7.9 7.4 43.6-14.2V35.4z"
                    />
                    <path
                        fill="#FFF"
                        d="M135.75 15.8v.1h.1l-.1-.1m47.15-5.25v.15h.15l-.15-.15z"
                    />
                    <path
                        fill="#7B7B7B"
                        d="M385 35.4V16.7Q362.198 3.99 334.15 0h-48.1v3.25q7.613 18.81 10.75 19l1 3.2L311.85 32v4.1l-25.8 3.65-.1.05-67.25 9.55q-.2 0-.4.05l-20.6 2.9h-.1l-10.55 1.55q-.25 0-.5.05L70.35 70.45V81l141.2-20.8q77.12-7.803 98.45-11.25l.65.6v58.9q-9.33 19.309 22.85 13.35V42.1l51.5-6.7z"
                    />
                </g>
                <g id="PlaInd011__Layer7_1_FILL">
                    <path
                        fill="#CCC"
                        d="M62.8 26.4Q56.705 15.092 37.55 5.65V141.4q-.723.969-1.45 1.85-9.548 13.04 1.45 21.15v73.35L62.8 279.9V26.4m278.6 19.4l-7.9-3.7v263.85l7.9 7.4V45.8z"
                    />
                    <path
                        fill="#B7B7B7"
                        d="M311.85 32l-14.05-6.55.1.35q-3.884-1.367-22.3-4.45l-8.7 1.1-5.75 10.05-190.8 26v11.95l116.2-16.55q.25-.05.5-.05l10.55-1.55h.1l20.6-2.9q.2-.05.4-.05l67.25-9.55.1-.05 25.8-3.65V32z"
                    />
                </g>
                <g id="PlaInd011__Layer6_0_FILL">
                    <path
                        fill="#555"
                        d="M14 145.5q.12.172.25.35l.1.15q9.299 12.588 21.75-2.75.727-.881 1.45-1.85v-38.6L14 106.4v39.1m203.65-6.4l-1.2.2q-2.2.5-4.5 1l-113.3 23.35q-1.078 5.529 3.45 8.25 4.584 2.766 7.15 2.35 2.605-.355 2.6 0 0 .95-.25 1.85-27.393 15.837-3.2 42.85 91.22-5.898 177.55-35.45V179.65q-.017-1.08 0-2.25v-16.9l-20.2-15.2q-18.6-11.6-48.1-6.2z"
                    />
                    <path
                        fill="#404040"
                        d="M285.95 179.65v.95q.761 24.142 19.2 10.55v-16.2l-19.2-14.45v16.9q-.017 1.17 0 2.25z"
                    />
                </g>
                <g id="PlaInd011__Layer5_0_FILL">
                    <path
                        fill="#666"
                        d="M319.1 272.2h-.15q-1.25 0-2.2.85-.05.05-.1.15-.75.85-.75 2 0 1.25.85 2.15.95.9 2.2.9 1.25 0 2.1-.9.9-.9.9-2.15t-.9-2.15q-.8-.8-1.95-.85m-2.35-35.55q-.85.9-.85 2.15t.85 2.1q.95.9 2.2.9 1.25 0 2.1-.9.9-.85.9-2.1t-.9-2.15q-.85-.9-2.1-.9t-2.2.9m4.6-34.35l-.3-.3q-.85-.85-2.1-.85t-2.2.85q-.4.45-.6 1-.25.5-.25 1.2 0 1.25.85 2.1.95.9 2.2.9 1.25 0 2.1-.9.9-.85.9-2.1 0-1.1-.6-1.9m-29.6-192.2l-.7-.15-7.6 4.3-2.35 7.6 2.4.55 6.15-5.55 2.1-6.75m41.75 188.4v-2.05l-28.35-21.5v2.45l28.35 21.1m0 73.7v-2.45l-28.35-25.7v2.25l28.35 25.9m0-38.4v-2.2l-28.35-23v2.2l28.35 23z"
                    />
                    <path
                        fill="#D0D0D0"
                        d="M82 141.3q-.25.95-.25 1.5 0 1.25 1.5 4.8.5 1.4 2.9 3.45 1 .85 3.15 2.75.55.45 1.8 1.75 1.15 1.05 2.1 1.35-7.05-6.7-11.2-15.6m30.05-14.75q-.2-.8-.35-1.6l-.55.1q-.2 1.15-.2 2.3 0 3.6 1.75 6.1.5.75 1.4 2.2.05.05.1.15.9 1.35 1.85 2-.1-.2-.25-.4-.05-.1-.1-.25-.05-.05-.05-.1-.05-.05-.05-.1l-1.9-4.55q-.85-2.4-1.45-4.85l-.15-.75q-.05-.15-.05-.25z"
                    />
                    <path
                        fill="#FFF"
                        d="M100.1 131.4q-1-1.85-1.55-3.75-7.15 6.75-18.3 9.5.8 2.1 1.75 4.15 4.15 8.9 11.2 15.6l.05.05h.05q4.2-1.1 7.8-2.85.2-.15.45-.2 7.4-3.7 11.9-9.95-9.7-5.4-13.35-12.55m29.95 5.5q5.15-1.3 9.4-4.15-3.6-4.2-5.6-8.25-.8-1.65-1.35-3.25v-.05q0-.05-.05-.1-1.6-4.45-1.25-8.85-9.05 3.9-20.45 2.55 0 5.1.95 10v.15q.15.8.35 1.6 0 .1.05.25l.15.75.2.7q.35 1.3.75 2.55l.5 1.6 1.9 4.55q0 .05.05.1 0 .05.05.1.05.15.1.25.15.2.25.4.3.05.6.05 7.25.65 13.4-.95M80.2 168.8q0-.15.25-.65.3-.45.3-.75l-4.95 1q-1 1.15-1 2.05 0 .75 1.15 1.8 1.1 1.05 1.05 1.55-1.35.35-2.55.85-2.3.9-2.3 1.4 0 .35.75.9.45.3.65.6l-.4.1q-.8.45-1.6.45-.75 0-1.2-.2v6.85q1.1.3 2.2.55 1.55.4 3.35.55 1.75.25 3.65.45 2.3.2 4.95.2 5.3 0 10-.8 1.25-.25 2.55-.6.05 0 .2-.05 2.3-.5 4.45-1.3 1.1-.45 2.15-.85.45-.2.85-.4 2.3-1.1 3.95-2.35 1.3-1.15 2.1-2.25-.15-.65-.5-1.3-.7-1.25-1.45-1.4-.75-.1-1.35-1.6-.5-1.35-1.5-1.35-.9 0-1.45 1.65-.25.85-.5 1.25-.05.05-.05.1-.35.3-.65.3-1 0-1-2.05 0-.4.1-.75 0-.2.15-.4.3-.35.3-.9 0-.7-.55-1.7-.65-.8-1.1-1-.45.2-.8.95-.4 1.05-1.65 1.05-1.15 0-1.8-1.75-.7-1.8-1.5-1.8-.25 0-.55.2-.4.85-.4 1.35l.25 1.45q0 2.85-2.3 2.85-.8 0-1.35-.3-.1-.15-.1-.25 0-.25 1.15-1.2 1-.85 1-1.6 0-1.2-1.2-2.25-.35-.15-.5-.15h-.05q-.5 0-1.3.5-.8.45-1.4.45-.6 0-1.3-.75-.75-.75-1.5-.75-.25 0-.45.2-.2.05-.4.25-.35.25-.5.3-1.5 1.35-1.5 2.5 0 .75.8 1.45.3.45.5.8.15.3.15.55 0 .85-.65 1.85-.05.15-.25.4-.65.75-1.4.75-.15 0-.35-.15-.15-.1-.35-.45-.1-.15-.1-.3l-.1-.05q-.05-.25-.05-.5 0-.4.3-.85.2-.35.45-.6.7-.85.7-1.5 0-.85-1.25-1.6-1.25-.6-1.25-1z"
                    />
                    <path
                        fill="#667699"
                        d="M37.55 141.4L14 145.5v23.85l23.55-4.95v-23M333.5 305.95v-3.3l-26.85-25.1q-1.15.5-2.25 1l29.1 27.4m-28.35-114.8l-19.4-13.9-177.35 41.7-.95 11.9q0 4.3-6.8 7.4-6.85 3.1-16.65 3.1-7.7 0-13.65-1.9v26.9L270.4 216l34.75 30.95v-55.8z"
                    />
                    <path
                        fill="#333"
                        d="M23.85 91.7h-.1v-6.55l13.8 3.45V75.05l-14.5 9.45v8.05l14.5 1.3v-.75l-13.7-1.4m289.5 31.4q-.25-.322-.25-.45h-.15q-.648.065-.3.6-1.204.411-1.6 1.3-.217-.198-.4-.35l-1 2.1q-.05.05-.05.15.15 1.05 3.25 4.05t6.15 3q1.5 0 2.4-1.9.85-2-.1-4.05-.9-1.9-3.2-3.25.95.65-.6-.3-.15-.05-.25-.1v.15q-.02.132-.05.25-.347-1.425-3.1-1.25-.402.025-.75.05m-96.2-73.55l.6-.1-6.2.85v63.4h30.5q1.45 1.05 3.75 2 1.35.55 2.85 1l24.55.35q2.1-.6 4-1.35 2.25-.95 3.75-2h6q0-.05.85-.05 0 .05.05.05h22.8V49.55h-93.5m-96.7-30.65l-.05 34.6 15.35-2.05V17.1l-15.3 1.8m31.65-3.6v-1.2l-11.35-8.2-1.75.15.65.5-10.95 1.2-.6-.55-2.35.25 10 8.35.1.1h-.1v1.2l16.35-1.8m30.8-4.6v-.15l-13.3-7.7-2.1.2.7.45-9.75 1.05-.7-.45-1.85.15 12.15 8.25v1l14.85-1.65V10.7z"
                    />
                    <path d="M23.75 91.7h.1l13.7 1.4v-4.5l-13.8-3.45v6.55m267.15 25.35q0-.15-.05-.25-.6-.7-1.7-1.7-.75-.6-1.2-1.2l-.1-.2q-.05 0-.05-.05-.85 0-.85.05 0 .1.05.2.4.6.95 1.2.3.35.7.65.8.7 1.35 1.3 0 .05.45 0h.45m15.55 2.8v-.05l.35 1.4q.35 1.25.45 1.5.25.8.95 1.65l.5.55.35.35q.1.1.3.25l.35.3q0 .05.1.1-.1.15-.15.4l1-2.1-.3.5q-.1.1-.15.25-.05.05-.05.15-.05 0-.05.05l-.05-.05q-1.6-1.4-2.05-2.65v-.05q-.1-.2-.4-1.2-.15-.65-.4-1.6-.55-1.75-1.2-2.55-.65-.85-5-2.55-.85-.35-1.55-.8h-1.3q.2.15.4.35V114q.9.75 2.15 1.25 4.05 1.55 4.7 2.3.55.7 1.05 2.3M91.85 22.1v-1.6L82.8 12l-2 .2.7.65-8.95.95-.65-.65-3.6.4.7.65-8.45.9-.7-.65-1.55.15 12.05 10.9v34.8l21.5-2.95V22.1m28.6-3.2v-1.6l-8.6-8.4-1.15.15.55.55-11.45 1.3-.65-.65-2.4.25 9.15 8.45v36.5l14.5-1.95.05-34.6m2.2-1.55l11.05-1.2-8.9-8-10.35 1.1 8.2 8.1m45.4-3.85v-1L155.9 4.25l-1.5.2.65.45-11.35 1.25-.7-.5-2.25.25 11.35 8.2v35.1l15.95-2.1V13.5m74-9.5l-8.35-4h-17l10.95 5.6v33.45L242 37.1l.05-31.9V4m24.85-2.7L263.55 0H249.5l5.6 2.45v32.9l11.8-1.6V1.3m27.4 7.5l-10.75-2.45-5.1 17 10.6 2.4L294.3 8.8m-9.15-.15l5.9 1.3.7.15-2.1 6.75-2 6.45-4.15-.9-2.4-.55 2.35-7.6 1.7-5.6z" />
                    <path
                        fill="#555"
                        d="M223.95 116.7l-7.5 14.05 90.9.9-5.75-14.6H290.45q-.45.05-.45 0h-16.8l-24.55-.35h-24.7z"
                    />
                    <path
                        fill="#36F"
                        d="M105.9 20.5l-14.05 1.6v35.25l14.05-1.9V20.5z"
                    />
                    <path
                        fill="#0248EC"
                        d="M105.9 20.5v-1.55l-9.15-8.45-1.6.25.55.55-10.5 1.15-.7-.6-1.7.15 9.05 8.5v1.6l14.05-1.6z"
                    />
                    <path
                        fill="#4D4D4D"
                        d="M152.1 15.3l-16.35 1.8v34.35l16.35-2.25V15.3m30.8 29.8V11.85l-14.85 1.65v33.6l14.85-2z"
                    />
                    <path
                        fill="#1E1E1E"
                        d="M125.75 7.45l-1.55.2.6.5 8.9 8-11.05 1.2-8.2-8.1-.55-.55-2.05.2 8.6 8.4v1.6l15.3-1.8v-1.3l-10-8.35z"
                    />
                    <path fill="#600" d="M198.9 10l-16 1.85V45.1l16-2.15V10z" />
                    <path
                        fill="#3C0000"
                        d="M198.9 10V8.75L183.85 1.3l-2.15.2.85.4-9.35 1.05-.75-.45-2.85.35 13.3 7.7.15.15h-.15v1.15l16-1.85z"
                    />
                    <path
                        fill="#0F5724"
                        d="M213.85 40.9V8.35L198.9 10v32.95l14.95-2.05z"
                    />
                    <path
                        fill="#172660"
                        d="M213.85 8.35V40.9l13.8-1.85V6.8l-13.8 1.55z"
                    />
                    <path
                        fill="#0C411C"
                        d="M213.85 8.35v-1.1l.15-.05L199.6 0h-2l.5.25-10.55 1.15-.8-.4-2.9.3 15.05 7.45V10l14.95-1.65z"
                    />
                    <path
                        fill="#0E1636"
                        d="M227.65 6.8V5.6L216.7 0h-17.1L214 7.2l-.15.05v1.1l13.8-1.55z"
                    />
                    <path
                        fill="#733F0D"
                        d="M255.1 35.35V3.75L242.05 5.2 242 37.1l13.1-1.75z"
                    />
                    <path
                        fill="#4D2909"
                        d="M255.1 3.75v-1.3L249.5 0h-15.8l8.35 4v1.2l13.05-1.45z"
                    />
                    <path
                        fill="#5F3418"
                        d="M291.55 30L298 6.5l-16.9-3.65-6.85 23.1 17.3 4.05m-8-23.65L294.3 8.8l-5.25 16.95-10.6-2.4 5.1-17z"
                    />
                    <path
                        fill="#404040"
                        d="M287.65 23.3l2-6.45-6.15 5.55 4.15.9m3.4-13.35l-5.9-1.3-1.7 5.6 7.6-4.3z"
                    />
                    <path
                        fill="#28160B"
                        d="M293.5 25.45l4.6 1-2.55-8.45-2.05 7.45z"
                    />
                    <path
                        fill="#874A23"
                        d="M295.55 18l3.3-12-16.65-3.65-1.1.5L298 6.5 291.55 30l.75-.5 1.2-4.05 2.05-7.45z"
                    />
                    <path
                        fill="#222"
                        d="M79.55 186.3q-1.9-.2-3.65-.45-1.8-.15-3.35-.55-1.1-.25-2.2-.55v54.7q5.95 1.9 13.65 1.9 9.8 0 16.65-3.1 6.8-3.1 6.8-7.4l.95-11.9 3.2-42.85q-.3.95-.85 1.8-.8 1.1-2.1 2.25-1.65 1.25-3.95 2.35-.4.2-.85.4-1.05.4-2.15.85-2.15.8-4.45 1.3-.15.05-.2.05-1.3.35-2.55.6-4.7.8-10 .8-2.65 0-4.95-.2z"
                    />
                    <path
                        fill="#8B8B8B"
                        d="M74.8 170.45q0-.9 1-2.05l-5.45 1.1v8.4q.45.2 1.2.2.8 0 1.6-.45l.4-.1q-.2-.3-.65-.6-.75-.55-.75-.9 0-.5 2.3-1.4 1.2-.5 2.55-.85.05-.5-1.05-1.55-1.15-1.05-1.15-1.8m5.65-2.3q-.25.5-.25.65 0 .4 1.25 1 1.25.75 1.25 1.6 0 .65-.7 1.5-.25.25-.45.6-.3.45-.3.85 0 .25.05.5l.1.05q0 .15.1.3.2.35.35.45.2.15.35.15.75 0 1.4-.75.2-.25.25-.4.65-1 .65-1.85 0-.25-.15-.55-.2-.35-.5-.8-.8-.7-.8-1.45 0-1.15 1.5-2.5.15-.05.5-.3.2-.2.4-.25.2-.2.45-.2.75 0 1.5.75.7.75 1.3.75.6 0 1.4-.45.8-.5 1.3-.5h.05q.15 0 .5.15 1.2 1.05 1.2 2.25 0 .75-1 1.6-1.15.95-1.15 1.2 0 .1.1.25.55.3 1.35.3 2.3 0 2.3-2.85l-.25-1.45q0-.5.4-1.35.3-.2.55-.2.8 0 1.5 1.8.65 1.75 1.8 1.75 1.25 0 1.65-1.05.35-.75.8-.95.45.2 1.1 1 .55 1 .55 1.7 0 .55-.3.9-.15.2-.15.4-.1.35-.1.75 0 2.05 1 2.05.3 0 .65-.3 0-.05.05-.1.25-.4.5-1.25.55-1.65 1.45-1.65 1 0 1.5 1.35.6 1.5 1.35 1.6.75.15 1.45 1.4.35.65.5 1.3.55-.85.85-1.8.25-.9.25-1.85v-.65q-.5-4.7-8-8.1-2.45-1.05-5.2-1.85l-17.9 3.75q0 .3-.3.75z"
                    />
                </g>
                <g id="PlaInd011__Layer5_1_FILL">
                    <path d="M215 107.85h91.6V52.3H215v55.55z" />
                    <path
                        fill="#555"
                        d="M247.8 114.3q5.55 1.85 13.35 1.85 7.75 0 13.3-1.85.9-.3 1.7-.6h-30q.75.3 1.65.6m63.6 10.6q.35.35.8.9 1.15 1.25.55.55 1.35.55 2.5-.35-.35-.45-1.4-1.45l-.5-.5q-1.4-1.3-.4-1.4-1.25 0-2.3 1.55.3.25.75.7m1.7-2.25q0 .2.6.95.4.45.75.85.7.7 1.35 1.2 1.25-.3 1.45-1.6v-.15q.1.05.25.1 1.55.95.6.3l-.3-.2-.2-.1q-.1-.05-.25-.1-2.05-1.1-3.9-1.2-.15-.05-.3-.05h-.05m-2.45-73.1l-3-2.75h-71.1l-17.85 2.55q-.2 0-.4.05l-1.15.15h93.5z"
                    />
                    <path
                        fill="#333"
                        d="M300.6 121.2l-16.25-.05 1.15 8.2 18.1.15-3-8.3m-16.6-2.6l.25 1.85 16.1.05-.7-1.85-15.65-.05m-58.1-.3l-1.05 1.95 57.35.2-.2-1.85-56.1-.3m-1.4 2.65l-4.15 7.75 62.7.6-.8-8.15-57.75-.2m-8.05 9.8v2l90.9.95v-2.05l-90.9-.9z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaInd011__Layer8_0_FILL" />
            <use xlinkHref="#PlaInd011__Layer7_0_FILL" />
            <use xlinkHref="#PlaInd011__Layer7_1_FILL" />
            <use xlinkHref="#PlaInd011__Layer6_0_FILL" />
            <use xlinkHref="#PlaInd011__Layer6_1_FILL" />
            <use xlinkHref="#PlaInd011__Layer6_2_FILL" />
            <use xlinkHref="#PlaInd011__Layer5_0_FILL" />
            <use xlinkHref="#PlaInd011__Layer5_1_FILL" />
            <use xlinkHref="#PlaInd011__Layer5_2_FILL" />
            <use xlinkHref="#PlaInd011__Layer5_3_FILL" />
        </g></g>
    )
}

export default PlaInd011
