import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525",
    "col1s": "#000000",
    "col2n": "#999999"
}

function ShiLon052({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M53.1 22.3q-3.25-4.5-8.8-8.95-8.15-3.55-15.65-8.7L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15l-.1.2q-3 3.95-5.6 8.35-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55l-.2.4-.4.8q-.2.35-.35.75-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 19.15 4.35 38.2 6h.2q.55.05 1.15.1l-.1-.1q.5 0 1.05.1V48.25q-.35-13.7-6.95-23.75l-1.1-1.55q-.2-.3-.45-.6v-.05m109.6 22.6q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-.15-.25-.25-.55-.2-.4-.35-.8-.3-.6-.55-1.15-1.3-2.6-2.7-5.1-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.9-2.9-3.9-5.6l-.3-.4-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-7.15 4.95-14.95 8.4-2.3 1.75-4.15 3.55-3.2 2.95-5.35 5.95v.05q-.25.3-.45.6l-1.1 1.55q-6.6 10.05-6.95 23.75v39.7q.75-.05 1.45-.1.45-.05.85-.1 19.1-1.65 38.1-6 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45.05-.05.6-.6q4.45-4.4 12-4.05l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon052__Symbol_323_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M26.7 75.75q2.35 1.7 4.65.8L41 74.4l-.3-6.35-19.95-3.25-2.4 5.9 8.35 5.05m-4.9-9.8q.8.1 1.05.2L38.7 68.7v.05h.5q.15 0 .25.05l.15 4.5-.1.1-8.25 1.9q-1.8.7-3.6-.65L20 70.25l1.7-4.3h.1m-2.85-30.6q-.2.1-.25.3L16 49.5q-.4-.3-1-.65-.65-.45-1.7-1.05-2.35-1.2-3.45-1.55-3.1-1.05-5.9-1.1Q1.2 45.1.6 45.4q-.6.25-.6.85l2.7-.1q1 .05 1.55.1 4.05.15 7.65 1.95 2.3 1.4 3.05 1.8H15q.5.15.85.25L14.5 57.1v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1m32.6 7q.05.25.05.5V88h.2q.3 0 .65.05v-45.2q-.05-1.95-.75-3.9-3.95-10.15-19.1-9.6-1.1 0-2.3.1l4.3-13.7q-.5-.2-.95-.35-.75-.3-1.45-.55-8.95-3.05-17.35-2.05l-.45.05q1.35-4.1 1.3-8.4-.05-2-.35-4l-.6-.45q-.1.05-.15.15l-.1.2q.1 1.65.1 3.35-.1 5.4-1.95 10.2-1.35 3.2-3.35 6.25l-5.1-2.6-.4.8 6 2.85q2.6-3.75 4.25-7.5 10.2-.95 19.1 2.75l-4.2 13.75h4.8q.9-.05 1.7-.05 14.1.9 16.1 12.2m57.05 32.3l9.65 2.15q2.3.9 4.65-.8l8.35-5.05-2.4-5.9-19.95 3.25-.3 6.35m1.4-1.1l.15-4.5q.1-.05.25-.05h.5v-.05l15.85-2.55q.25-.1 1.05-.2h.1l1.7 4.3-7.65 4.4q-1.8 1.35-3.6.65l-8.25-1.9-.1-.1m19.9-37.7v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-1.25-6.4.9-.5q.8-.45 2.95-1.75 3.6-1.8 7.65-1.95.55-.05 1.55-.1l2.7.1q0-.6-.6-.85-.6-.3-3.35-.25-2.8.05-5.9 1.1-1.1.35-3.45 1.55-1.15.65-1.8 1.1v.15h-.05l-.05-.25-.75.6-2.75-14q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35m16-17.3q-.2-.4-.35-.8l-5.25 2.65q-2-3.05-3.35-6.25-2.1-5.45-1.9-11.6 0-1.1.1-2.15l-.3-.4-.5.4q-.2 1.05-.3 2.1-.55 5.45 1.15 10.6l-.45-.05q-7.95-.95-16.35 1.75l-1 .3q-.7.25-1.45.55-.2.05-.4.15-.25.1-.55.2l4.3 13.7q-1.2-.1-2.3-.1-15.15-.55-19.1 9.6-.7 1.95-.75 3.9v45q.45-.05.85-.1V43.1q0-.25.05-.5 2-11.3 16.1-12.2.8 0 1.7.05h4.8l-4.2-13.75q8.9-3.7 19.1-2.75 1.65 3.75 4.25 7.5l6.1-2.9z"
                    id="ShiLon052__Symbol_248_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22 58.95q0 .7.5 1.15.45.5 1.15.5t1.2-.5q.45-.45.45-1.15t-.45-1.2q-.5-.45-1.2-.45t-1.15.45q-.5.5-.5 1.2m-3.15-31.1l-.9.75L34.2 44.2l.95-.75-16.3-15.6M3.3 1.65q0-.7-.45-1.2Q2.35 0 1.65 0 1.1 0 .7.3.6.35.5.45q-.5.5-.5 1.2v.05q0 .65.5 1.1.45.5 1.15.5t1.2-.5q.45-.45.45-1.15m27.4 10.9q0-.7-.45-1.2-.5-.45-1.2-.45t-1.15.45q-.5.5-.5 1.2t.5 1.15q.45.5 1.15.5t1.2-.5q.45-.45.45-1.15m80.5 46.65q0 .7.45 1.15.5.5 1.2.5t1.15-.5q.5-.45.5-1.15T114 58q-.45-.45-1.15-.45t-1.2.45q-.45.5-.45 1.2m7.35-30.35l-.9-.75-16.3 15.6.95.75 16.25-15.6M108.6 11.6q-.45-.45-1.15-.45t-1.2.45q-.45.5-.45 1.2t.45 1.15q.5.5 1.2.5t1.15-.5q.5-.45.5-1.15t-.5-1.2M133.65.7q-.45.5-.45 1.2t.45 1.15q.5.5 1.2.5t1.15-.5q.5-.45.5-1.15T136 .7q-.45-.45-1.15-.45t-1.2.45z"
                    id="ShiLon052__Symbol_218_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon052__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon052__Symbol_323_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon052__col1n"
            />
            <use
                xlinkHref="#ShiLon052__Symbol_248_0_Layer0_0_FILL"
                transform="translate(117.7 228.6)"
                id="ShiLon052__col1s"
            />
            <use
                xlinkHref="#ShiLon052__Symbol_218_0_Layer0_0_FILL"
                transform="translate(124.25 241.9)"
                id="ShiLon052__col2n"
            />
            <use xlinkHref="#ShiLon052__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon052
