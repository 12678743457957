import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFBB",
    "col1n": "#FFFF62",
    "col2n": "#6BFFFF"
}

function ShiLon011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.85 48.45q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6L140 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-13.8 9.55-29.95 13.5-1.95.5-3.9.9L82 38.45 61.7 19l-3.2-.7Q42 14.35 27.95 4.65L26.4 3.5q-1.45-1.05-2.8-2.1L21.75 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.05.1-.05.2-.05.1-.05.2 2.45-.8 6.4-.85v-.05h.4q.4-.05.7-.05v-.05H11q2.55.05 6.35 2.35Q21.1 56 21.1 57.3v.1l.05.05-.45 2.05q.3.3.3 1 0 1.05-.85 1.2l-1.8 7.5q-.55 2.1-1.05 4.2l-.9 3.6-.55 2q65.5 13.75 131.8 0l-4.4-17.7q-.85-.1-.85-1.05 0-.6.35-.85l-.45-1.95.5-.5q.1-.15.2-.2v-.1q0-.55.6-1.25.6-.8 2-1.75 2.8-1.95 5-1.6V52h5.8q2.45.45 6.3.75.6.05 1.05.2v-.15q-.05-.1-.05-.15-.05-.15-.05-.25-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5z"
                    id="ShiLon011__Symbol_285_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M121.3 8l-.2-.2h-.05q-.05.05-.05.1-.05 0-.05.05h-.05l-.05.05q-.05.05-.1.05v.05l.15.55-1.05-3Q70.15 14.5 18.65 8.3 10.2 7.25 1.75 5.8q-.25-.05-.5-.05L.75 8.2.5 7.85 0 9.95q.75.05 1.8.2.15.05.35.05 8.3 1.35 16.5 2.35 51.85 6.15 99.1-2.05.3-.1.65-.15l3.3-.55-.4-1.8m-2.05-5.4l-.05-.2v-.1l-.45-2.25V0Q67.5 9.15 18.65 2.75q-8.2-1.1-16.3-2.6L1.8 2.8q8.45 1.55 16.85 2.65 51.55 6.9 100.6-2.85z"
                    id="ShiLon011__Symbol_253_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M103.3 66h-.05q-1.7.1-3.35.2-14.8.85-29.45.4-8-.3-15.9-.95-9.1-.75-18.05-2-9.2-1.3-18.3-3.1-.75-.15-1.5-.25l-.55 2.15v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-.6-2.4-1.15.15h.3q-.3.05-.55.1-.45.05-.85.15-.3.05-.65.15-21.1 3.95-41.95 5.4M82.7 23.65L106.4 0q-3.75.95-7.65 1.55L82.7 17.5 66.85 1.65q-3.9-.6-7.65-1.5l23.5 23.5m57.75 7.65l-1.9-9.3q-53.7 6.85-98.2 2.1-7.35-.8-14.45-1.9h-.05l-1.8 9.25q8.1 1.5 16.3 2.6 48.85 6.4 100.1-2.75m-.35 10.35q-.35.05-.65.15-47.25 8.2-99.1 2.05-8.2-1-16.5-2.35-.2 0-.35-.05-1.05-.15-2.05-.3l-.7 2.75 2.2.25q.25.05.45.1 8.45 1.45 16.95 2.45 49.4 5.95 100.25-2.75h.1l3.3-.55-.4-1.65h-.05l-.15-.65-3.3.55m24.35-7q-.05-.1-.05-.15-.15-.15-.35-.25l-.2-.1q-.3-.25-.2-.2-.05-.05-.1-.05-.05-.05-.1-.05-.9-.5-1.8-.9-8.35-3.95-16.2.5-.15.05-.25.15-1.25.7-2.45 1.6l-1.4.65-.4-1.95q-49.05 9.75-100.6 2.85-8.4-1.1-16.85-2.65l-.5 2.7-1.65-1.2q-.45-.4-.7-.6-.65-.5-1.3-.9-7-4.45-14.45-1.95-.65.2-1.3.5-.7.25-1.35.6-.05.05-.1.05-.7.35-1.4.75-.2.8-.3 1.6-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-.05.25.65-.05.5-2.45q.25 0 .5.05 8.45 1.45 16.9 2.5 51.5 6.2 101.2-2.65l1.05 3-.15-.55v-.05q.05 0 .1-.05l.05-.05q.05 0 .05.05l-.1.1.15-.1h.05l-.05.05.3-.05q.45-.55.5-.5.85-.85 1.8-1.55 4.1-2.9 10.35-2.65l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V38.05q0-.15-.05-.35-.3-1.55-.55-3.05z"
                    id="ShiLon011__Symbol_191_0_Layer0_0_FILL"
                />
                <path
                    d="M247.6 264.45v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35m-110.95-.5q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1z"
                    id="ShiLon011__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon011__Symbol_285_0_Layer0_0_FILL"
                transform="translate(110.7 228.6)"
                id="ShiLon011__col1n"
            />
            <use
                xlinkHref="#ShiLon011__Symbol_253_0_Layer0_0_FILL"
                transform="translate(131.7 278.05)"
                id="ShiLon011__col1l"
            />
            <use
                xlinkHref="#ShiLon011__Symbol_191_0_Layer0_0_FILL"
                transform="translate(110 246.75)"
                id="ShiLon011__col2n"
            />
            <use xlinkHref="#ShiLon011__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon011
