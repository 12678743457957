import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1l": "#434343",
    "col1n": "#252525",
    "col2d": "#747474",
    "col2n": "#999999"
}

function AccMor072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.35 7.6V0q-3.9.45-9.8 1.2-7.3.9-15.6 1.9-3.6.05-3.4-2.05V.9l-.2.25q-2.05 1.15-5.2 2.8-7.75.7-15.45.9-.15 0-.25.05h-.55q-.9.05-1.8.05h-.3V6.3H58.5v-.7Q52 5.2 36.6 4q-1.2-.1-2.25-.2-7.75-.6-7.1-1.35-1.05-.1-2.15-.25-4.1-.55-7.55-1.1t-5.3-.85l-6.1.3H6.1q-.05 0-.1.05L3.65.7q-2 .6-3.65 1.1 1.5 2.4 2.05 4.15 2.2.1 5.5.45l4.6.9v.05q2.6.45 5.1.9 3.65.6 7.2 1.15 4.85 1.55 8.7 2.8 3.6-.3 8.85-.6 7.4.6 14.75.9.4.05.8.05h.75l17.85-.1q10.25-.4 20.65-1.55.2-.05.4-.05H97.5q1.8-.25 3.6-.45 2.3-1.65 3.6-1.4 1.65-.2 4.65.15h.15q2.8-.45 5.7-.95 3.35-.6 6.75-1.2 1.85.3 3.4.6z"
                    id="AccMor072__Symbol_180_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M.7 2L0 1.85v10.8q.1 1.45 2.1 2.5.15.1.3.15.25.1.55.15 1.5.15 1.7.25 1.35-.05 1.95-.85.35-.5.4-1.25l-.1-3.1h.05L6.7 3l-6-1.1V2m26.4 10.2q-.8-.7-.95-1.35v4.7q.05 2.8 2.75 3.4.45.15.95.25 1.05.25 8.6 1.55v.05q.25 0 .55.05H40.35q-1.25-2.8-.15-5.65.05-.2.15-.45-.75.05-1.4.05v-.05q-.25-.05-.5-.05-7.55-1.2-8.6-1.4-1.4-.25-2.45-.85-.2-.15-.3-.25m15.45-6.95V5.2L40 4.7l-.4-.1q-.4-.1-.75-.1-.2-.05-.4-.05Q32 3.2 31.8 3.2q-1.1-.15-1.95 0-2.15.2-3.05 1.65-.35.55-.5 1.35-.05.1-.05.2-.05.55-.1 1.15V9.8l.05.05v.1q.05.1.1.15v.05q.35.6 1.45 1.45.8.6 2.1.95.3.05 1.1.2 2 .4 7.5 1.3.85 0 1.8-.1h.1v-.05l-.2-.6q-1.1-3.6-.65-5.55 0-.2.05-.35.15-.6.55-1 .05-.1.1-.15.8-.9 2.35-1m65.4 11.2q2.95-1.65 3.1-4.65l.05-.05-12.85-8.2-3.55 4.3 13.25 8.6M134.85 7q.1-.05.1-.15v-.1l.05-.05V4.45q-.05-.6-.1-1.15-.5-2.9-3.6-3.2-.85-.15-1.95 0-.4 0-.85.1-.35 0-.75.1l-.4.1-2.55.5v.05q1.55.1 2.35 1 .45.45.65 1.15.6 2-.6 5.9-.1.3-.2.65h.1q2.55.2 4.2-.2 1.3-.35 2.1-.95 1.1-.85 1.45-1.45V7m.15.75q-.15.8-1.25 1.6-1.05.6-2.45.85-1.85.35-4.3.25 1.35 3.05 0 6.1 2.45 0 4.3-.45.5-.1.95-.25 2.7-.6 2.75-3.4v-4.7z"
                    id="AccMor072__FL_0_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.3 8.8V0H0v8.8h14.3m-2.2-6.7v4.65H2.6v-1.6h4.05V3.6H2.6V2.1h9.5z"
                    id="AccMor072__Symbol_178_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.8 1.55V0H0v1.55h.8z"
                    id="AccMor072__Symbol_179_0_Layer0_0_FILL"
                />
                <g
                    id="AccMor072__Symbol_181_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M60.2 12.25H59.25q-.4 0-.8-.05-7.35-.2-14.7-.8-.1-1.1-1.2-1.5v-.05L40 9.35q-.2-.05-2.55.25-.25 0-.55.05-1.95.2-4.15.55-1.15.1-2.3.3-3.15.4-1.5-.75-1.05-.1-2.15-.25-4.1-.55-7.55-1.1t-5.3-.85h-.1v.05l-4.4-.85V3.2Q9.5 1.4 8.05.8L2.4 0q-.2 0-.35.1-.8.35-1.2 1.45l-.1 4.3L0 6.5q4.6 6.55 4 9.15.35 1.5.65 4.7Q6.1 20.5 6.9 20l1.05-.85q1.15-1.1 1.3-2.65v-2.8l-1.45-.25v-5.6h.05l6 1.1V8.9q.45.05.9.15 2 .35 4.2.7 3.45.55 7.35 1.1-.05.1-.05.2-.05.55-.1 1.15v4.5q5.65 4.75 7.65 6.8 2.05.8 4.65 1.9v.05q.25 0 .55.05H40.35l1.35-.5q1.7-.8 2-2.5v-3.9l-2.05-.4v-5.55q.95.15 3.7.5v-.25q6.55.5 13.1.7.15 0 .3.05H60.2v-1.4m-18.55.35v-.2q.85.15.85.2-.05.05-.85 0M26.3 14.75v.05q-.1-.1-.1-.2.05.1.1.15m68.4-2.25l3.55-4.3-1.85-1.25-3.55 4.3q-7.75.7-15.45.9-.15 0-.25.05h-.55q-.9.05-1.8.05h-.3v1.4q1.05 0 2.1-.05h.2q.3-.05.6-.05h.45q8.4-.25 16.85-1.05m40.3-1.15L131.65 6q-1.15-.3-1.75-.45h-5.1v.05q-1.15.4-1.2 1.6-3.15.55-6.7 1.15-6.95 1.05-15.25 2.05l1.8 1.2q7.4-.9 13.45-1.9 3.6-.6 6.75-1.2 1.05-.2 2.05-.4v5.8l-2.05.4v3.9q.3 1.7 2 2.5l1.35.5q3.1-1.95 4.65-3.25 1.5-1.35 2.4.3.05.05.1.15.25-2.2.85-6v-1.05m-.15.35v-.05q.081-.04.1-.15-.018.118-.1.2z" />
                    <path d="M26.3 14.8v-.05q-.05-.05-.1-.15 0 .1.1.2m15.35-2.4v.2q.8.05.85 0 0-.05-.85-.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccMor072__Symbol_180_0_Layer0_0_FILL"
                transform="translate(126.85 305.15)"
                id="AccMor072__col1n"
            />
            <use
                xlinkHref="#AccMor072__Symbol_181_0_Layer0_0_FILL"
                transform="translate(125.15 297.85)"
                id="AccMor072__col1d"
            />
            <use
                xlinkHref="#AccMor072__FL_0_0_Layer0_0_FILL"
                transform="translate(125.15 302.5)"
                id="AccMor072__col1l"
            />
            <use
                xlinkHref="#AccMor072__Symbol_178_0_Layer0_0_FILL"
                transform="translate(185.35 309.6)"
                id="AccMor072__col2n"
            />
            <use
                xlinkHref="#AccMor072__Symbol_179_0_Layer0_0_FILL"
                transform="translate(187.95 313.2)"
                id="AccMor072__col2d"
            />
        </g></g>
    )
}

export default AccMor072
