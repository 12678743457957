import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000"
}

function AccMas020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M179.55 47.6q-3.55-5.95-8.05-11.5-1.8-2.15-3.7-4.25-.9-1-1.85-1.95-.8-.85-1.65-1.7-.9-.9-1.85-1.8-1.75-1.65-3.5-3.2-2.8-2.45-5.65-4.6Q128.6 0 96.25 0 63.9 0 39.2 18.6q-5.7 4.3-11 9.6Q7.35 49.05 1.95 76.25 0 85.85 0 96.25q0 21.4 8.15 39.45.4.85.8 1.75.75 1.55 1.55 3.1 1.15 2.2 2.45 4.35H13q.6.1 1.2.15l3.6.45q21.25 2.6 35.8 2.95 5.9.15 10.65-.1 12.3-.65 17.3-3.75 5.4-3.35 10.7-4.05 8.4-1.2 16.65 4.3 13.15 8.8 66 .65l2.1-.3q.3-.1.6-.1.3-.1.6-.1.55-.1 1-.25l-.35.15.85-.2q1.1-1.9 2.15-3.8 10.65-20.1 10.65-44.65 0-27-12.95-48.65M89.45 86q3.1 3.35 4.75 7.55.7 1.9 1.8 6.35.05.3.15.65l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75h.05q4.65.35 8.8 2.15l2.7 1.4 3.9 2.7q1.9 1.5 3.65 3.4 3.35 3.55 5.4 7.6 2.3 4.2 3.05 8.4.4 2.15.55 4.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-7.3-7.8-8.8-17.85l-.75-2.5q0 .1-.05.2-.2.8-.35 1.55-1.05 4.35-1.7 6.35-1.4 4.15-3.9 7.85-1.55 2.3-3.55 4.45-7.25 7.75-16.55 9.3-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-6.65-1.2-11.5-6.45-7.2-7.6-6.35-19.2.75-11.65 9.05-20.45 8.15-8.85 19.05-9.65 3.15-.25 6 .25 6.85 1.1 11.85 6.5z"
                    id="AccMas020__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M80.5 113.9q-.95 1.15 0 2.25.4.45 2 0 4.7-3.3 6.95-4.5 2.9-1.55 6.8-1.9 3.9.35 6.8 1.9 2.25 1.2 6.95 4.5 1.6.45 2 0 .95-1.1 0-2.25-3.75-4.15-7.05-7.2-3.25-3.05-8.7-4.3-5.45 1.25-8.7 4.3-3.3 3.05-7.05 7.2M24.7 8.65q-1.9 2.1-3.7 4.25-4.5 5.55-8.05 11.5Q0 46.05 0 73.05 0 97.6 10.65 117.7q-.05-2.85.95-3.35.4-.2 3.9-.2h.1l18.3 2q1.6 0 1.6-1.4 0-2.6-11.75-4.75Q12 107.9 12 105.4q0-2.1 5.85-4.05 5.9-1.9 5.9-3.45 0-1.8-2.25-2.9-1.05-.5-5-1.65-7.25-2.05-7.25-6.2 0-2 1.65-3.3.85-.7 3.6-1.9 5.25-2.35 5.25-6.2 0-2.75-2.1-4.9-1.3-1.25-4.65-3.8Q7.25 62.2 6.4 53.5q.45-3 2.2-5.05 1.2-1.4 5.3-4.2 3.85-2.6 5.6-5.1 2.75-3.8 3.25-9.75.25-5.25.5-8 0-.3.05-.55.35-3.65.8-6.45Q26.25 6.8 33.55 0q-1.75 1.55-3.5 3.2-.95.9-1.85 1.8-.85.85-1.65 1.7-.95.95-1.85 1.95m76.05 90.1l.1.1.2.2q1 1.25 2.25 2.5.95 1.2 2 2.35 7.3 8.05 16.8 9.65 1.25.2 2.55.3 3.35.25 6.4-.3 6.8-1.25 11.75-6.7 7.3-7.85 6.4-19.9-.15-2.75-.7-5.3.4 2.15.55 4.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-1.55-1.65-2.8-3.4-4.8-6.55-6-14.45l-.75-2.5q0 .1-.05.2-.05-.1-.05-.2l-.75 2.5q-1.5 10.05-8.8 17.85-7.2 7.75-16.55 9.3-1.2.2-2.5.3-3.3.25-6.3-.3-6.65-1.2-11.55-6.45-7.15-7.6-6.3-19.2.15-2.3.55-4.45-.55 2.55-.7 5.3-.9 12.05 6.4 19.9 4.95 5.45 11.75 6.7 3.05.55 6.4.3 1.3-.1 2.55-.3 9.5-1.6 16.8-9.65 1.05-1.15 2-2.35 1.25-1.25 2.3-2.5 5.1-5 9.25-.3M171.5 12.9q-1.8-2.15-3.7-4.25-.9-1-1.85-1.95-.8-.85-1.65-1.7-.9-.9-1.85-1.8-1.75-1.65-3.5-3.2 7.3 6.8 9.45 14.4.45 2.8.8 6.45.05.25.05.55.25 2.75.5 8 .5 5.95 3.25 9.75 1.75 2.5 5.6 5.1 4.1 2.8 5.3 4.2 1.75 2.05 2.2 5.05-.85 8.7-6.6 13.55-3.35 2.55-4.65 3.8-2.1 2.15-2.1 4.9 0 3.85 5.25 6.2 2.75 1.2 3.6 1.9 1.65 1.3 1.65 3.3 0 4.15-7.25 6.2-3.95 1.15-5 1.65-2.25 1.1-2.25 2.9 0 1.55 5.9 3.45 5.85 1.95 5.85 4.05 0 2.5-11.75 4.6-11.75 2.15-11.75 4.75 0 1.4 1.6 1.4l18.3-2h.1q3.5 0 3.9.2 1 .5.95 3.35 10.65-20.1 10.65-44.65 0-27-12.95-48.65-3.55-5.95-8.05-11.5z"
                    id="AccMas020__Symbol_62_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas020__Symbol_103_0_Layer0_0_FILL"
                transform="translate(96.25 57.4)"
                id="AccMas020__col1n"
            />
            <use
                xlinkHref="#AccMas020__Symbol_62_0_Layer0_0_FILL"
                transform="translate(96.25 80.6)"
                id="AccMas020__col1d"
            />
        </g></g>
    )
}

export default AccMas020
