import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D"
}

function FacMou063({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.7 13.75L14.25 7.8l-.2-.85q-.7-3.1-2.8-4.8-5.65-4.25-10.2 0-1.6 1.9-.75 6.3.25 1.45.4 3.95v1.35z"
                    id="FacMou063__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    d="M207.1 222.55q.395.001.65-.3.302-.257.3-.65.002-.395-.3-.7-.257-.252-.65-.25-18.877.1-32.5 7.9-.319.222-.45.6-.075.38.1.7.222.37.6.45.38.124.7-.1 13.227-7.551 31.55-7.65z"
                    id="FacMou063__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou063__Symbol_88_0_Layer0_0_FILL"
                transform="translate(174.6 215.65)"
                id="FacMou063__col1n"
            />
            <use xlinkHref="#FacMou063__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou063
