import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#B46BFF"
}

function ShiMot085({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.4 11.35h3.35V0H26.4v3.35h5.45l-5.9 5.95q-1.55-1.1-3.3-1.65-1.85-.6-3.8-.6-1.9 0-3.75.6-1.8.55-3.3 1.65l-.9-.9 2.5-2.5-2.5-2.55L8.4 5.9 5.85 3.35H8.4V0H0v8.4h3.35V5.9l2.5 2.5-2.5 2.5 2.5 2.55L8.4 10.9l.8.85q-1.25 1.6-1.95 3.5-.7 2-.7 4.1 0 2.3.8 4.4.75 2 2.2 3.65 1.4 1.65 3.3 2.7 1.95 1.1 4.15 1.45v4.3h-4.85v3.8H17v4.4h3.75v-4.4h4.85v-3.8h-4.85v-4.3q2.2-.35 4.1-1.45 1.9-1.05 3.3-2.7 1.45-1.65 2.2-3.65.85-2.15.85-4.4 0-2.1-.75-4.05-.65-1.95-1.9-3.55L34.4 5.9v5.45m-6.75 7.95q0 1.8-.65 3.45-.7 1.6-1.9 2.8-1.25 1.2-2.8 1.9-1.65.65-3.45.65-1.8 0-3.4-.65-1.6-.7-2.8-1.9-1.25-1.25-1.9-2.8-.7-1.65-.7-3.45 0-1.8.7-3.4.65-1.6 1.9-2.8 1.2-1.25 2.8-1.9 1.6-.7 3.4-.7 1.8 0 3.45.7 1.6.65 2.8 1.9 1.2 1.2 1.9 2.8.65 1.6.65 3.4z"
                    id="ShiMot085__Symbol_48_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot085__Symbol_48_0_Layer0_0_FILL"
                transform="translate(173.65 263.5)"
                id="ShiMot085__col1n"
            />
        </g></g>
    )
}

export default ShiMot085
