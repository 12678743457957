import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF9AFF",
    "col2n": "#9C3AFF"
}

function LegSki005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M145.25 11.65q-1.05-1.9-2.15-3.5-3.15-4.7-6.75-7.4-.3-.25-.6-.45-.05-.1-.1-.3l-.1.1v.05q-.05 0-.1.05h-.1q-.1 0-.15.05-.35.05-.75.15-3.2.6-6.4 1.15l-6.2 1q-.45.1-.9.15-4.25.65-8.45 1.2-.35 0-.7.05l-7.9.9q-4 .35-7.9.65-.15 0-.3.05l-5.15.35L88 6q-.25 0-.55.05h-.4l-4.65.2h-.6l-2.3.05q-.35 0-.75.05l-2.7.05h-2.2q-18.45.1-36.7-1.95-2.65-.3-5.3-.65l-3.35-.45-4-.6-6.7-1.1q-.2-.05-.4-.05L11.6.5q-.45-.1-.9-.15-.25-.1-.5-.1-.1.05-.15.15V.2q-.3.3-.6.65Q7 2.55 4.8 4.95 2.25 7.8 0 11.7q4.85.3 6.6.45 1.1.1 2.2.15 2.95.2 5.95.4 1.75.1 3.55.25 2.4.1 4.9.25 4.35.25 8.75.45 4.45.2 8.95.35l5.25.2q1.35 0 2.8.05l3.85.1 3.35.1h1.8l6.85.1h2.5q2.95.05 5.85.05h5.6q1.55-.05 3.05-.05 2.85-.05 5.65-.05H88q1.3-.05 2.55-.05 3-.1 6-.15 4-.1 8.05-.25h1.1q3.9-.15 7.85-.35h.3q4.25-.2 8.45-.4l1.7-.1q3.1-.2 6.2-.35.25-.05.55-.05 4.05-.25 8.15-.55 1.75-.1 6.35-.6z"
                    id="LegSki005__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.2 2.25q.5-.95.6-1.9l.1-.2q-.25-.1-.5-.1-.1.05-.15.15V0q-.3.3-.6.65Q2.2 2.35 0 4.75q.4.05.85.05 2.6 0 4-2l.2-.3q.05-.15.15-.25m8.65 8.55q0-1.25-.75-2.65Q11.8 5.8 8.85 5.8q-2.6 0-4 2-1 1.45-1 3 .05.6.15 1.1v.2q2.95.2 5.95.4 1.75.1 3.55.25.05-.2.1-.35.25-.8.25-1.6m2.55-4.7q0-.55-.4-.95-.35-.35-.9-.35t-.95.35q-.35.4-.35.95 0 .55.35.9.4.4.95.4.55 0 .9-.4.4-.35.4-.9m15.75-1.7l.2-.15q-2.65-.3-5.3-.65l-3.35-.45.05.1q.05.2.15.4Q25 6.3 28.35 6.3q2.25 0 3.6-1.5.15-.2.3-.35-.05-.05-.1-.05m2.45 7.45q.9-.9.9-1.95 0-.8-.5-1.7-.85-1.5-2.65-1.5-1.3 0-2.25 1-.85.9-.85 2.05-.15 3.1 3.1 3.1 1.3 0 2.25-1M46.35 9.3q-2.6 0-4 2-.65 1-.9 2l-.1.7q1.35 0 2.8.05l3.85.1 3.35.1v-.05l-.1-.8q-.65-4.1-4.9-4.1m9.9 0q.55 0 .9-.4.4-.35.4-.9t-.4-.95q-.35-.35-.9-.35t-.95.35q-.35.4-.35.95 0 .55.35.9.4.4.95.4m8.95 1.45q-1.1 0-1.85.75t-.75 1.85q0 .55.2 1 2.4 0 4.8.05.2-.5.2-1.05 0-1.1-.75-1.85t-1.85-.75M77.85 7.8q.35-.45.55-.9l.45-.9-1.25.05H77l-2.3.05q-.35 0-.75.05l-2.7.05h-2.2l.15.5v.15l.05.1.1.3q1.2 2.55 4.5 2.55 2.65 0 4-2m4.75-.9q-.9.95-.9 2.25v.25q.1 1.15.9 1.95.95.95 2.25.95t2.2-.95q.95-.9.95-2.2 0-.75-.3-1.35-.25-.5-.65-.9-.9-.9-2.2-.9-1.3 0-2.25.9m27.25 3.9q-.4-5-5-5-2.6 0-4 2-.45.65-.65 1.3-.6 2.95.85 4.75 3.85-.2 7.7-.35h.3l.2-.3q.6-1.15.6-2.4m4.95-5q-.55 0-.95.35-.35.4-.35.95 0 .55.35.9.4.4.95.4.55 0 .9-.4.4-.35.4-.9t-.4-.95q-.35-.35-.9-.35m10.6 6.5v-.05q-.3-3.05-3.05-3.05-1.2 0-2.25 1-.95.95-.95 1.95 0 .3.05.6V13q3.1-.2 6.2-.35v-.35m1.3-8.6q.55 0 .9-.4.4-.35.4-.9t-.4-.95q-.35-.35-.9-.35t-.95.35q-.35.4-.35.95 0 .55.35.9.4.4.95.4m8.65 5.1q1.75 0 2.95-.85-3.15-4.7-6.75-7.4-.1.1-.2.25-1 1.5-1 3 0 1.25.75 2.65 1.3 2.35 4.25 2.35z"
                    id="LegSki005__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki005__Symbol_71_0_Layer0_0_FILL"
                transform="translate(119.8 310.1)"
                id="LegSki005__col1n"
            />
            <use
                xlinkHref="#LegSki005__Symbol_20_0_Layer0_0_FILL"
                transform="translate(124.6 310.3)"
                id="LegSki005__col2n"
            />
        </g></g>
    )
}

export default LegSki005
