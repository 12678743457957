import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}
function ObjFoo061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.95 18.6q.1 0 .25.05 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.5.2-1 .35l.25 5.45q.8.4 1.95.75 4.55 1.6 11 1.6 6.5 0 11.1-1.6 1.1-.35 1.95-.75L30.6 0H0l.95 18.6z"
                    id="ObjFoo061__Symbol_135_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.9 48.75q12.55 3.6 22.75 0-11.2 2.15-22.75 0m26.6-42.8v-1q-.35-1.35-2.2-2.35-.95-.55-2.25-1.05Q21.55 0 15.2 0 8.9 0 4.45 1.55q-1.4.5-2.25 1.05Q.15 3.7 0 5.15v.55q0 .15.05.3.45 1.8 4.4 3.2 3.75 1.3 8.85 1.55H15.2q6.35 0 10.85-1.55 3.9-1.4 4.4-3.2l.05-.05z"
                    id="ObjFoo061__Symbol_136_0_Layer0_0_FILL"
                />
                <g id="ObjFoo061__Layer1_0_FILL" fill="#C1D2E2">
                    <path
                        fillOpacity={0.702}
                        d="M117.2 262.35q.01-.031 0-.1l.2 6.4.15-.45v.2q0 .15.05.3.117.468.45.9 1.178 1.157 3.85 2.2.583.227 1.15.4 3.396 1.034 7.8 1.25H132.75q6.35 0 10.85-1.55 3.9-1.4 4.4-3.2v-.1l.15.15.25-6.3q-.097.421-.55 1.05-.563.688-1.7 1.3-.29.168-.6.3-.746.387-1.65.7-4.6 1.6-11.1 1.6-6.45 0-11.05-1.6-.958-.32-1.7-.7-.288-.14-.55-.3-.861-.472-1.4-1-.51-.562-.75-1l-.15-.45z"
                    />
                    <path
                        fillOpacity={0.349}
                        d="M117.2 261.65v.6q.01.069 0 .1l.15.45q.24.438.75 1 .539.528 1.4 1 .262.16.55.3.792-.437 1.95-.85 4.45-1.55 10.75-1.55 6.35 0 10.85 1.55 1.093.42 1.95.85.31-.132.6-.3 1.137-.612 1.7-1.3.453-.629.55-1.05v-1q-.45-2-4.5-3.4-4.6-1.6-11.1-1.6-6.45 0-11.05 1.6-4.3 1.5-4.55 3.6z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo061__Symbol_135_0_Layer0_0_FILL"
                transform="translate(117.55 268.55)"
                id="ObjFoo061__col1n"
            />
            <use
                xlinkHref="#ObjFoo061__Symbol_136_0_Layer0_0_FILL"
                transform="translate(117.55 262.7)"
                id="ObjFoo061__col1d"
            />
            <use xlinkHref="#ObjFoo061__Layer1_0_FILL" />
        </g></g>
    )
}
export default ObjFoo061
