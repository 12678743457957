import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E5B000",
    "col2n": "#FFD446"
}
function AccJew059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.7.3Q1.4 0 1 0 .6 0 .3.3 0 .6 0 1v9.2q0 .4.3.7.3.3.7.3.4 0 .7-.3.3-.3.3-.7V1q0-.4-.3-.7z"
                    id="AccJew059__Symbol_149_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M19.85 1.7Q18.15 0 15.7 0t-4.2 1.7q-.4.4-.7.85-.35-.45-.75-.85Q8.35 0 5.9 0 3.5 0 1.75 1.7 0 3.45 0 5.9q0 2.4 1.75 4.15l8.3 8.35q.3.3.7.3.4 0 .7-.3l7.9-7.85q.15-.15.3-.25.35-.15.5-.55 1.45-1.6 1.45-3.85 0-2.45-1.75-4.2m-1.4 1.4q1.15 1.15 1.15 2.8 0 1.6-1.1 2.7l-.05.05-.5.5-7.2 7.15-7.6-7.65Q2 7.5 2 5.9q0-1.65 1.15-2.8v.05Q4.3 2 5.9 2q1.65 0 2.75 1.1.5.5.85 1.1.3.55.45 1.15.1.3.4.55.25.2.65.15.3-.05.6-.25.25-.25.3-.6.05-.55.3-1.05l.05-.05q.2-.55.65-1v.05Q14.05 2 15.7 2t2.75 1.1z"
                    id="AccJew059__Symbol_151_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew059__Symbol_149_0_Layer0_0_FILL"
                transform="translate(98.7 177.6)"
                id="AccJew059__col1n"
            />
            <use
                xlinkHref="#AccJew059__Symbol_151_0_Layer0_0_FILL"
                transform="translate(88.9 184.1)"
                id="AccJew059__col2n"
            />
        </g></g>
    )
}
export default AccJew059
