import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#0000FF"
}

function ObjToy072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M392.9 340.45h-.05q-7.7-14-21.15-16.65-.8-.2-1.65-.3v1.5q.7.1 1.35.25 12.85 2.6 20.15 15.9.7 1.55 4.3 3.3 4.45 2.2 10.1 2.35 3 .05 12.25 1.45 10.75 1.55 13.7 3 2.95 1.45 6.05 6.1 2.65 3.95 3.3 6.65 1.25 5.85 9.2 10.6 7.25 4.35 16.65 5.7 9.05 1.3 17.4 5.15 8.75 4.05 11.05 8.45 4.1 8.4 10.55 12.35h.05q9.55 5.8 27.9 5.8 4.1 0 7.55-.35 1.65-.2 3.2-.4.3-.05.5-.3t.15-.55q-.05-.3-.3-.5t-.55-.15q-1.55.2-3.2.4-3.35.35-7.35.35-17.85 0-27.15-5.6-6.15-3.75-10-11.7-2.35-4.85-11.75-9.15-8.6-4-17.85-5.3-9.1-1.35-16.1-5.5-7.3-4.35-8.5-9.65-.7-2.95-3.5-7.15-3.35-5.1-6.65-6.6-3-1.5-14.15-3.15-9.4-1.4-12.4-1.45-5.3-.15-9.5-2.2-2.95-1.5-3.6-2.65z"
                    id="ObjToy072__Layer11_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M63.1 12.8q-.1-1.1-1.6-1.55L21.7.2q-.3-.15-.55-.15-.1-.05-.25 0h-5.05q-.4 0-.6.35L13.4.05H8q-.4 0-.6.25L6.35 0 .95.05Q0 .1 0 1.1v89.55q0 1.15 1.1 1.3h20.65L61.5 90.7q1.6-.1 1.6-1.3V12.8z"
                    id="ObjToy072__Symbol_136_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.9 0Q.5 0 .3.25.25.25.2.3q-.2.25-.2.75v69q0 .6.25 1.05.75.35 1.05.55V1.05q0-.4.2-.65.2-.35.8-.4H.9z"
                    id="ObjToy072__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill="url(#ObjToy072__Gradient_1)"
                    d="M327.85 240.45q-.7.05-.75 1.15v88.65q-.2 2 1.6 2.1l39.75-1.25q1.6-.1 1.6-1.3v-76.6q-.1-1.1-1.6-1.55l-39.8-11.05q-.3-.15-.55-.15-.1-.05-.25 0z"
                    id="ObjToy072__Layer2_0_FILL"
                />
                <path
                    d="M322.2 240.8l-1.85-.35q-.95.05-.95 1.05v89.55q0 1.15 1.1 1.3h2.6q-1-.15-1.1-1.3V242.1q-.05-.85.2-1.3m-8.9-.4q-.7.25-.7 1.1v89.55q.05 1.15 1.05 1.3h2.75q-1-.15-1.05-1.3V312.1q-.3-.2-.5-.45l-.75-.9q-.05.25-.05-.25v-69q0-.5.2-.75.05-.05.1-.05l-1.05-.3z"
                    id="ObjToy072__Layer2_1_FILL"
                />
                <linearGradient
                    id="ObjToy072__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={367.975}
                    y1={286.35}
                    x2={327.725}
                    y2={286.35}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopOpacity={0} />
                    <stop offset="100%" stopOpacity={0.278} />
                </linearGradient>
                <g id="ObjToy072__Layer2_2_FILL">
                    <path
                        fill="#272727"
                        d="M348.35 291.95h-.05l-.75.8q.45.1 1.1.1v-1.1q-.2.1-.3.2m2.35-1.4q-.2.05-.35.25v1.25q.15-.15.4-.25l.5-.3q.45-.25.65-.25.35 0 .35.25t-.25.35q-.2.1-.3.25-.1.1-.2.1l-1.15.7v1.35l2.35-1.45q.05-.05.2-.1l.05-.1q.45-.25.45-.7 0-.5-.4-.95-.85-.85-2.3-.4m-1.75.85v2.1l1.1.75v-4l-1.1 1.15m2.8-2.85l-1.05 1.05v.2q.4.15.65 0 .1-.05.15-.25.2-.45.25-1z"
                    />
                    <path
                        fill="#FFF"
                        d="M346.85 290.55q-.1.05-.15.1-.45.3-.45.85 0 .7.8 1.05.25.15.5.2l.75-.8q-.25.25-.55.25-.2 0-.3-.15-.1-.15-.1-.25 0-.2.3-.35.05-.05.1-.15l.9-.5v-1.3l-1.75 1.05h-.05m3.5-6l-1.4-1v7.85l1.1-1.15v-4.3q0-.05.05-.15.15-.2.3-.25.2-.05.25.25.05.25.05.75v3.05l1.05-1.05v-.25q.05-.65 0-1.25-.05-.2-.05-.45l-.05-.1v-.05q-.25-1.2-1.3-1.9z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy072__Layer11_0_FILL" />
            <use
                xlinkHref="#ObjToy072__Symbol_136_0_Layer0_0_FILL"
                transform="translate(306.95 240.4)"
                id="ObjToy072__col1n"
            />
            <use
                xlinkHref="#ObjToy072__Symbol_48_0_Layer0_0_FILL"
                transform="translate(314.05 240.45)"
                id="ObjToy072__col2n"
            />
            <use xlinkHref="#ObjToy072__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy072__Layer2_1_FILL" />
            <use xlinkHref="#ObjToy072__Layer2_2_FILL" />
        </g></g>
    )
}

export default ObjToy072
