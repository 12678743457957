import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function AccJew005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M123.15.65q-.6.5-1.15.95-5.6 4.35-11.5 7.75l-2 1.1-.3.2q-2.25 1.15-4.5 2.2-7.8 3.65-16.25 5.7-.25.05-.5.15-2.3.5-4.65.95-4.85.9-9.85 1.3-2.8.2-5.6.3-.4 0-.85.05h-3.8q-.45-.05-.85-.05-2.8-.1-5.6-.3-5-.4-9.85-1.3-2.35-.45-4.65-.95-.25-.1-.5-.15-8.45-2.05-16.25-5.7-2.25-1.05-4.5-2.2l-.3-.2-2-1.1q-6.1-3.5-11.8-8-.4-.35-.85-.7H5Q4-.15 2.75 0 1.5.1.7 1.1q-.8 1-.7 2.3.05.3.15.6.25.8.9 1.4.05 0 .1.05.4.3.8.65 2.65 2.1 5.4 4.05 4.65 3.2 9.5 5.85.2.05.35.15 2.4 1.25 4.8 2.4 8.3 3.85 17.25 6.05.25 0 .45.1 2.5.55 5 1.05 8.05 1.45 16.65 1.7.4 0 .85.05H66q.45-.05.85-.05 8.6-.25 16.65-1.7 2.5-.5 5-1.05.2-.1.45-.1 8.95-2.2 17.25-6.05 2.4-1.15 4.8-2.4.15-.1.35-.15 4.85-2.65 9.5-5.85 2.6-1.85 5.15-3.85.55-.45 1.05-.85.05-.05.1-.05.65-.6.9-1.4.1-.3.15-.6.1-1.3-.7-2.3-.8-1-2.05-1.1-1.25-.15-2.25.65h-.05z"
                    id="AccJew005__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.15 4q.25.8.9 1.4.05 0 .1.05.4.3.8.65.65-2.75 3.95-4.75-.4-.35-.85-.7H5Q4-.15 2.75 0 1.5.1.7 1.1q-.8 1-.7 2.3.05.3.15.6M22 18.55q.05-3.85 2.5-5.7-2.25-1.05-4.5-2.2l-.3-.2q-2.6 2.5-2.85 5.55.2.05.35.15 2.4 1.25 4.8 2.4m19.25.15q-.25-.1-.5-.15-1.7 2.75-1.5 6.05.25 0 .45.1 2.5.55 5 1.05.1-3.05 1.2-6.1-2.35-.45-4.65-.95M66 21.3h-3.8q-.45-.05-.85-.05v6.2q.4 0 .85.05H66q.45-.05.85-.05v-6.2q-.4 0-.85.05m22.5 3.4q.2-.1.45-.1-.1-3.05-1.5-6.05-.25.05-.5.15-2.3.5-4.65.95 1.05 3.05 1.2 6.1 2.5-.5 5-1.05m22.85-8.7q-.75-3.4-2.85-5.55l-.3.2q-2.25 1.15-4.5 2.2 2.2 2.65 2.5 5.7 2.4-1.15 4.8-2.4.15-.1.35-.15m15.7-10.55q.05-.05.1-.05.65-.6.9-1.4.1-.3.15-.6.1-1.3-.7-2.3-.8-1-2.05-1.1-1.25-.15-2.25.65h-.05q-.6.5-1.15.95 2.95 1.4 4 4.7.55-.45 1.05-.85z"
                    id="AccJew005__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew005__Symbol_76_0_Layer0_0_FILL"
                transform="translate(128.5 228.2)"
                id="AccJew005__col1n"
            />
            <use
                xlinkHref="#AccJew005__Symbol_25_0_Layer0_0_FILL"
                transform="translate(128.5 228.2)"
                id="AccJew005__col2n"
            />
        </g></g>
    )
}

export default AccJew005
