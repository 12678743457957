import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#830000",
    "col2n": "#FF0000"
}

function SkiWou003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.6 3.55q6.9 1.05 12.85-2.5Q51.7 2.85 41.6 0q2.75 5.3.8 7.4-3.05 1.45-11.35 3.2l-.1-.2q1.55 8.75-7.4 10.05-1.8.2-4 .2-1.25 6.2-11 9.95Q4.9 32 0 33.05q6.25-.35 10.75-1.4 6.45-1.5 9.05-4.55 1.25-1.45 1.6-3.25h.1q.5-.05 1-.15 9.7-1.55 12.7-6.75l.2-.4q-.1.15-.15.25.7-1.85.25-4.15l.85-.25q2.3-.6 4.35-1.2 3.65-1.15 5.15-2.2 1.75-1.45 1.2-3.45t-.45-2z"
                    id="SkiWou003__Symbol_8_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.95 34.2q-.25-1.65-.6-3.25-.4-1.6-.55-2.15l-.1.05q-.2.7-1.15 3.7-.9 2.9-.9 3.95 0 1.2.55 1.9.5.7 1.2.7 1.2 0 1.5-1.6.25-1.65.05-3.3m-2.2-16.9q.15-.4.3-.75L0 21.1q1.6.3 1.8.95.15.6.8 2.8.6 2.15 2.35 2 1.7-.15 2.1-2.85.4-2.7.4-2.75.6-2.05 1.3-3.95m19.75.95q-.2-.8-.3-1.4-.25 1.1-.4 1.55-.4 1-.55 2.25-.05.5 0 .85.95 2.1 1.75.2 0-.1.05-.15.1-1.1-.55-3.3M24.65 6q-.1.2-2.4 1.2-2.3.95-10.5 5.95 1.7.7 2.25 2.15.35.85.35 2.8 0 2.9.05 3.45.1.65.3 1.2.85 1.7 1.7 0 .25-.65.3-1.5-.1-2.25 0-3.25.15-1.7.9-3.15 1.05-2 2.7-2 1 0 .8 3.1-.15 3.05.95 3.05 1.35 0 1.65-1.05.2-1.55.05-2.45-.15-.9-.05-2.25.05-1.35.95-7.25m5.3-5.35q-2.05-1.7-4.35 1.2.5.05.8.45.3.35.55 2.35l-.25 2.5q0 .95.15 1.2t.75.25q.65 0 1-.4.65-1.2 0-2.95-.2-.9-.15-1.8.4-1.8 1.5-2.8z"
                    id="SkiWou003__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou003__Symbol_8_0_Layer0_0_FILL"
                transform="translate(110.4 107.3)"
                id="SkiWou003__col1n"
            />
            <use
                xlinkHref="#SkiWou003__Symbol_9_0_Layer0_0_FILL"
                transform="translate(121.15 117.85)"
                id="SkiWou003__col2n"
            />
        </g></g>
    )
}

export default SkiWou003
