import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M187.5 25.35q3.3 14.35 3.4 19-.2 5.1-.9 7.35-.9 6.7-3.45 19.75-3.15.9-4.7 1.15l.2.05q1.9 2.15 1.9 6.7-.55 4.15-3.05 6.6-.65.6-1.7 1.1-.8.6-.8 2.4 0 3.15-.15 5.05-.1.2-.15.35-.1.2-.2.5-1.1 2.3-4.65 7.35-.45-2.95-.5-4.5-2.95 7-4.7 9.45-3.2 4.65-7.85 5.5-.25-.3-.7-1.7-1.05.55-2.5 1.25-4.6 2.25-7.2 3.55-8.7 4.6-15.45 3.75l-3.5 5.85-2.15-3.9-3.1 11q-.1.4-.2.85-.3 1.05-.65 2-2.3 6.2-7.5 8.7-.15 0-.25.05-2.85.95-6.25.25-2.5-.35-5.1-2.35-3.6-1.6-7.2-1.7h-.5q-3.6.1-7.2 1.7-2.6 2-5.1 2.35-3.4.7-6.25-.25-.1-.05-.25-.05-5.25-2.5-7.5-8.7-.35-.95-.65-2-.1-.45-.2-.85l-3.1-11-2.15 3.9-3.5-5.85q-6.75.85-15.45-3.75-2.6-1.3-7.2-3.55-1.45-.7-2.5-1.25-.45 1.4-.7 1.7-4.65-.85-7.85-5.5-1.75-2.45-4.7-9.45-.05 1.55-.5 4.5-3.55-5.05-4.65-7.35-.1-.3-.2-.5-.05-.15-.15-.35-.15-1.9-.15-5.05 0-1.8-.8-2.4-1.05-.5-1.7-1.1-2.5-2.45-3.05-6.6 0-4.55 1.9-6.7l.2-.05q-1.55-.25-4.7-1.15Q7.3 58.4 6.4 51.7q-.7-2.25-.9-7.35.1-4.65 3.3-19-2.1 4.4-5.7 16.2-.45 1.5-1.9 4.9Q0 50 0 57.8v2.15q1.25-1 2-1.75v13.95q-.35 2.6-.75 3.7Q3.15 83.6 6 91.95q8.55 24.95 32.4 43.85.2.15.35.3 13.65 12.6 35.9 19.4l.35.15q5 1.6 9.95 2.5 13.25 2.45 26.55 0 5.25-1 10.6-2.7h-.4q20.363-5.333 34.3-17.9l.05-.05q2.002-1.75 2.1-1.8 23.75-18.85 32.25-43.75 2.85-8.35 4.75-16.1-.4-1.1-.75-3.7V58.2q.75.75 2 1.75V57.8q0-7.8-1.2-11.35-1.45-3.4-1.9-4.9-3.6-11.8-5.8-16.2M47.95 119.3v.1h-.05l.05-.1m100.5 0l.05.1h-.05v-.1z"
                    id="BeaChi020__Symbol_59_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M191.9 10.6q-.25 12.5-11.7 36.75-2.45 5.65-4.7 5.65-.75 0-1.45-.5-.65-.55-.55-1-1.7 3.6-4.8 8.65-4.55 7.35-6.45 7.35-.35 0-1.25-.25-.95-.25-1.25-.25-.15 0-.6.45-3.75 6.75-9.4 10.15-2.45 1.5-5.3 2.35-1.85.7-3.55.8h-.15q-.05.1-.05.15-1.05 2-1.4 2.6-.95 1.5-1.45 1.5-.15 0-1.25-.5-1.15-.5-1.85-.5-.55 4.15-2.05 5-11.95 4.55-23.15 6.3-2.65-.25-4.6-.8-3.6-1-6.35-1-.65 0-1.4.05-.1 0-.25.05-.15-.05-.25-.05-.75-.05-1.4-.05-2.75 0-6.35 1-1.95.55-4.6.8-11.2-1.75-23.15-6.3-1.5-.85-2.05-5-.7 0-1.85.5-1.1.5-1.25.5-.5 0-1.45-1.5-.35-.6-1.4-2.6 0-.05-.05-.15H53q-1.7-.1-3.55-.8-2.85-.85-5.3-2.35-5.65-3.4-9.4-10.15-.45-.45-.6-.45-.3 0-1.25.25-.9.25-1.25.25-1.9 0-6.45-7.35-3.1-5.05-4.8-8.65.1.45-.55 1-.7.5-1.45.5-2.25 0-4.7-5.65Q2.25 23.1 2 10.6 1.2 5.45.75 0v13.95q-.35 2.6-.75 3.7 1.9 7.75 4.75 16.1Q13.3 58.7 37.15 77.6q.2.15.35.3 13.65 12.6 35.9 19.4l.35.15q5 1.6 9.95 2.5 13.25 2.45 26.55 0 5.25-1 10.6-2.7h-.4q20.363-5.333 34.3-17.9l.05-.05q2.002-1.75 2.1-1.8 23.75-18.85 32.25-43.75 2.85-8.35 4.75-16.1-.4-1.1-.75-3.7V0q-.45 5.45-1.25 10.6z"
                    id="BeaChi020__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi020__Symbol_59_0_Layer0_0_FILL"
                transform="translate(94.3 93.05)"
                id="BeaChi020__col1n"
            />
            <use
                xlinkHref="#BeaChi020__Symbol_18_0_Layer0_0_FILL"
                transform="translate(95.55 151.25)"
                id="BeaChi020__col1d"
            />
        </g></g>
    )
}

export default BeaChi020
