import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D19532",
    "col2n": "#027502"
}

function PlaFor018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M118.6 0L0 143.95h695.9L525.55 0H118.6z"
                    id="PlaFor018__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M44.25 0L0 71.45l357.35.5L292.9 0H44.25m255.4 46.1l-.35-.05q0-.7.4-.45.83.44 1.3.45-.71 0-1.35.05z"
                    id="PlaFor018__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M328.75 335.8q-.1-.2-.25-.4.15.45.35.9l-8.3-10.55-10.45-18.45q-1.5-.4-4.45-.45h-.75q-5.95-.1-9.4-.4-3.45-.35-6 1.85-4.75 4.75 0 13.3l1.25 2.25q.75 1.55 1.65 3.4 6.45 13.05 7.4 15.3.35.8 1.9 5.15 1.8 4.75 3.75 8.4 4.3 8.15 9.45 11.15 10.763 2.944 24.6.45 4.385-.827 4.75-4.7-.815-4.975-4.95-11.6-4.65-7.45-6.95-10.45-1.05-1.4-1.9-2.6-1-1.4-1.7-2.55m2.05 13.65q.101.169 0 1.75.044-1.162-.05-1.1 0-.35.05-.65z"
                    id="PlaFor018__Symbol_12_0_Layer0_1_FILL"
                />
                <path
                    fill="#FFF"
                    d="M152.9 314q-9.25 1.6-17.5 4-20.65 6.4-21.35 15.35-.05.3-.05.4v6.45q3.95 6.45 20.3 12.1 22.55 7.9 53.4 7.7 30.8-.2 53.1-7.35 16.2-5.25 19.95-13.1v-4.1q.05-.3.05-.65.2-.3.2-.75 0-.8-.25-1.5-1.35-6.95-15-12.2-2.8-1.2-6.3-2.35-13.15-3.85-29.4-5.5-10.65-1-22.5-1-12.15 0-22.7 1-6.25.6-11.95 1.5z"
                    id="PlaFor018__Layer5_1_FILL"
                />
                <path
                    fill="#CCC"
                    d="M114 334.8v5.4q3.95 6.45 20.3 12.1 22.55 7.9 53.4 7.7 30.8-.2 53.1-7.35 16.2-5.25 19.95-13.1v-4.1q-1.5 8.4-21.3 14.6-21.5 6.55-51.9 6.55-30.45 0-52.15-6.55-20.45-6.4-21.4-15.25m36.4-12.15q-15.7 4.8-15.7 11.4 0 6.75 15.7 11.35 15.7 4.85 37.85 4.85 22.15 0 37.8-4.85 15.7-4.6 15.7-11.35 0-6.6-15.7-11.4-3.3-1.05-7.1-1.85-13.4-2.9-30.7-2.9-17.45 0-30.75 2.9-3.75.8-7.1 1.85m-.25 22.2q-15.1-4.4-15.1-10.5 0-6.2 15.1-10.5 6.7-1.95 14.35-3.05 10-1.35 22.15-1.35 12.3 0 22.4 1.35 7.7 1.1 14.35 3.05 15.15 4.3 15.15 10.5 0 6.1-15.15 10.5-15.15 4.35-36.75 4.35-21.35 0-36.5-4.35z"
                    id="PlaFor018__Layer5_1_MEMBER_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M441.6 358.6l.95 2q3.1-1.05 5.25-2.25l-1.3-2.4-4.9 2.65m-8.75-6.15l-4.75-.1-.55 11.35-.45.55q.2-.05.4-.05 2.3-.4 4.55-.8 2.35-.5 4.6-1.05l9.95-11.6-5.5-3.4-8.6 10.25.35-5.15m-35.35 7.25l-6-3.35-3.5 4.85 8.1 4.55q3.3.1 6.85.1 2.75 0 5.45-.05l-3.75-2.1 5.95-1.65 3.35 3.5q4.55-.25 8.8-.75l-4.6-4.85 8.45-2.35-2.45-5.35-10.5 2.9-5.65-5.95-5.65 4.25 3.7 3.85-8.55 2.4m-12.7-.3l7.95-5.8-3.9-5.25-14.3 10.6-6.15-9.8-5.05 2.45 6.35 10.85q3.8.95 8.05 1.6l.5.1q3.6.55 7.5.95l-.95-5.7m-22.3-5.4l-4.9 2.1 1.85 2.95q2.6 1.2 6.35 2.35l-3.3-7.4z"
                    id="PlaFor018__Layer5_5_MEMBER_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.4}
                    d="M342.35 367q1.05-.7 1.8-2.45-3.05 1.7-11.6 2.1-8.55.4-13.7-.45-5.15-.9-3.35-3.45 2.32-3.216 25.9.3l.3.3-31.6-56.05q-1.5-.4-4.45-.45h-.75q-5.95-.1-9.4-.4-3.45-.35-6 1.85-4.75 4.75 0 13.3l1.25 2.25q.75 1.55 1.65 3.4 6.45 13.05 7.4 15.3.35.8 1.9 5.15 1.8 4.75 3.75 8.4 4.3 8.15 9.45 11.15 11.7 3.2 27.05 0 .2-.1.4-.25m-11.6-16.9v-.05q.335-.181.4-.1-.25.05-.4.15z"
                    id="PlaFor018__Layer5_7_FILL"
                />
                <g id="PlaFor018__Layer5_0_FILL">
                    <path
                        fill="#FC7409"
                        d="M411.2 323.3l3.15 1.4q2.05-.4 4.05-.85l-6.15-2.65-1.05 2.1m9.5-10.6l-.5-2.85-2.3.25.2 1.6.4 2.85q1.4.2 2.65.85l-.45-2.7m9.05-1.85l-1.4 2.1-3.95 5.25q1.05 1.4 1.1 2.5l2-2.8q.05 0 .1.1l2.55-3.7 1.45-2.1-1.85-1.35m-79.85 7.1l.2 3.35.7.3q.75.3 1.6.55v-4.2h-2.5m-1.75-2.55q-.05-.2-.05-.4l-.95.25-3.85 1.15q1 1.35 2.6 2.55.05-1.4 1.2-1.8.65-.25.95-.75.2-.4.1-1m31.6 8.55l-2.35-1.6-3.4 3.85q.6 0 1.3.1.55 0 1.2.05.45.05 1 .1l2.25-2.5m-9.05-14.8l-.4-.2-5.15-2.65-1 1.2-.25.45 3.45 1.85q1.35-.55 2.9-.6.2-.05.45-.05m11.4-1.95l-1.8-1.35-3.4 3.6-1.05 1.1q.9.55 1.75 1.4l.1.1 2.15-2.4 2.25-2.45z"
                    />
                    <path
                        fill="#14CB14"
                        d="M415.85 315.5q.8-1.1 1.05-1.1.8 0 1.6.15l-.4-2.85q-2.4-.6-4.85-.1 1.55 2.2 1.95 4.9.3-.55.65-1m9.65 5.2v.05q0 .45-.3 1.25 2.05-.7 3.75-1.45 2.5-1.15 3.95-2.4v-.25q0-2.35-1.95-3.2l-.8-.4-2.55 3.7q-.05-.1-.1-.1l-2 2.8m2.85-7.75q-1.25-1.15-2.2-2.8 0 .25-.15.25l-2 4.25v.25q-.9-.75-1.75-1.3-.8-.55-1.55-.9l.45 2.7q.45.2.95.55.75.5 1.4 1.2.5.55.9 1.05l3.95-5.25m-69.7-4.7q-.7.85-.75.9-.4.4-1 .75-.25.05-1.4.25-1.25.25-1.45.25-1.5 0-3.35-1.1-1.05-.65-1.85-1.45-.8-.8-1.35-1.7-1.4 1.6-1.95 3.35-.3 1-.3 2.05 0 1.25.9 2.4.3.4 1 1.3l.95-.25q-.2-1.75.8-2.15.7-.3 2.85-.7h.75q-.05 1.75.75 3.05.5-.7 1.15-1.35 2.35-2.35 5.35-3.15-.4-1.1-1.1-2.45m41.6 5.15q-1 4.3-1 7.2 0 1.85 1 4.75.15.45.35 1l2.6-.2q.65-2.2 1.2-3.6 1.1-2.65 1.7-2.65 1.7 0 2.5.3.6.25 1.65.3v-2.25q0-.35-.4-1.55-.45-1.15-.45-1.55-1.05.45-2.7 1.4-.65.35-2 .35-1.75 0-4.2-3.4l-.25-.1M387 319q-.45-.05-.85-.05-1.6-.2-2.7-.4-1.95-.35-2.3-.9-.9-1.4-1.3-3.1-.2-1.05-.7-1.05-.1 0-.25.05-.95.1-1.9 1.3-.1.1-.2.25-1.05 1.45-1.65 3.6-.15.45-.25.95-.15.65-.65 1.15 2.15.7 2.55.7 1.95 0 3.15-.75 1.25-.75 1.95-.75.4 1 .4 2.35.3.9 2.35.8 2.35-.15 3.4 1.2.7.9.7 2.1v.3q2.05 0 4.1-.05.1-.15.3-.25.25-.25.55-.5.05 0 .05-.05.75-.7.75-1.75v-.3q-.05-.4-.1-.7-.25-1.05-.95-1.85-1.05-1.15-3.05-1.7-1.45-.45-3.4-.6z"
                    />
                    <path
                        fill="#28E928"
                        d="M420.15 306.25q-.2 0-2.65.65h-.75q-.55-1.4-1-2.3-.1-.15-.15-.3-.7-1.2-1.95-2.8-.6 1.05-1.7 2.15l-.8.7q-1.8 1.55-2.75 1.55-.85 0-1.75-.55-.75-.6-.9-.6-.15 0-.75.85-.2.3-.35.6 4.05.5 7.05 3.5.9.9 1.55 1.9 2.45-.5 4.85.1l-.2-1.6 2.3-.25.5 2.85q.75.35 1.55.9v-.55q0-2.65-.3-3.55-.25-.7-1.8-3.25m-67.65 5.9h-.75q-2.15.4-2.85.7-1 .4-.8 2.15 0 .2.05.4.1.6-.1 1-.3.5-.95.75-1.15.4-1.2 1.8 1.75 1.3 4.2 2.35l-.2-3.35h2.5v4.2q.5.2 1.05.35.55.2 1.15.35.45.1.95.25 2.8.75 5.85 1.35v-.5q0-1.9-1.3-3.9-1.35-2.1-2.95-2.3-.5-.1-.9-.2-.55-.2-1.05-.5-.45-.3-.9-.65-.65-.55-1.05-1.2-.8-1.3-.75-3.05m51.9 10.4q-.55 1.4-1.2 3.6 2.8-.25 5.5-.6l2.1-.3q1.5-.2 2.95-.45.3-.1.6-.1l-3.15-1.4 1.05-2.1 6.15 2.65q.1-.05.2-.05.95-.25 1.85-.45.45-.15.9-.25 1.6-.45 3.05-.85.4-.15.8-.25.3-.8.3-1.25v-.05q-.05-1.1-1.1-2.5-.4-.5-.9-1.05-.65-.7-1.4-1.2-.5-.35-.95-.55-1.25-.65-2.65-.85-.8-.15-1.6-.15-.25 0-1.05 1.1-.35.45-.65 1-.3.45-.55.9-.9 2.05-1.25 2.5-.1.15-.25.25-.45.2-1.25.3-.45.05-1.1.05h-.55q-1.05-.05-1.65-.3-.8-.3-2.5-.3-.6 0-1.7 2.65m-36.75-4.15h-.5v3.75q.1.2.95 3.4 1.7.2 3.5.4.15 0 .3.05l1.1.1q.45 0 1 .1l3.4-3.85 2.35 1.6-2.25 2.5q5.2.3 10.95.3h.3v-.3q0-1.2-.7-2.1-1.05-1.35-3.4-1.2-2.05.1-2.35-.8 0-1.35-.4-2.35-.7 0-1.95.75-1.2.75-3.15.75-.4 0-2.55-.7-.45-.15-1-.3-.25-.1-.55-.2-.3-.1-.55-.15-3.4-1.15-4.5-1.75m2.65-9.45l.4.2h.2q2.75 0 4.95 1.4l1.05-1.1q-.9-.15-1.7-.4-1.4-.4-4.2-1.8-.3.4-.7 1.7m26.25-1.2q0-.05.05-.05-2.95-3.95-3.15-3.95-.3 0-2.6 1.4-2.3 1.35-2.4 1.35-.25 0-1.2-1l-1.25-1.25q-.1.2-3.1 3.25-1.15 1.7-1.7 2-.25.15-1.35.15l-2.15 2.4q.7.7 1.2 1.5.15-.05.25-.05.5 0 .7 1.05.4 1.7 1.3 3.1.35.55 2.3.9-.2-1-.2-2.1 0-3.95 2.75-6.7 2.8-2.8 6.75-2.8 2.05 0 3.8.8z"
                    />
                    <path
                        fill="#CCC"
                        d="M434.95 314.4q0-1.7-1.4-3.2-3-3.1-12.2-5.6-2.8-.75-5.75-1.3.05.15.15.3.45.9 1 2.3h.75q2.45-.65 2.65-.65 1.55 2.55 1.8 3.25.3.9.3 3.55v.55q.85.55 1.75 1.3v-.25l2-4.25q.15 0 .15-.25.95 1.65 2.2 2.8l1.4-2.1 1.85 1.35-1.45 2.1.8.4q1.95.85 1.95 3.2v.25q1.5-1.25 1.9-2.65.15-.55.15-1.1m-89.7-2.85q0-1.05.3-2.05-1.35.8-2.2 1.7-1.05 1.1-1.35 2.3.35 1.55 1.3 2.9l3.85-1.15q-.7-.9-1-1.3-.9-1.15-.9-2.4m48.1 15.15q-.1-.15-.2-.25-.2.1-.3.25h.5m3.25-19q2.8-1.65 6.25-1.65.95 0 1.8.15.15-.3.35-.6.6-.85.75-.85.15 0 .9.6.9.55 1.75.55.95 0 2.75-1.55l.8-.7q-10.5-1.65-23.5-1.65-19.3 0-32.9 3.6-3.95 1.05-6.7 2.25.8.8 1.85 1.45 1.85 1.1 3.35 1.1.2 0 1.45-.25 1.15-.2 1.4-.25.6-.35 1-.75.05-.05.75-.9.7 1.35 1.1 2.45 1.65-.5 3.5-.5 1.45 0 2.75.3.65-.4 1.35-.7l-3.45-1.85.25-.45 1-1.2 5.15 2.65q.4-1.3.7-1.7 2.8 1.4 4.2 1.8.8.25 1.7.4l3.4-3.6 1.8 1.35-2.25 2.45q1.1 0 1.35-.15.55-.3 1.7-2 3-3.05 3.1-3.25l1.25 1.25q.95 1 1.2 1 .1 0 2.4-1.35 2.3-1.4 2.6-1.4.2 0 3.15 3.95z"
                    />
                    <path
                        fill="red"
                        d="M396.9 326.6q-.45-.4-.85-.8-.95-.95-1.55-1.95v.3q0 1.05-.75 1.75 0 .05-.05.05-.3.25-.55.5.1.1.2.25l3.55-.1m-27.85-15q-1.45-.8-3.05-1.1-1.3-.3-2.75-.3-1.85 0-3.5.5-3 .8-5.35 3.15-.65.65-1.15 1.35.4.65 1.05 1.2.45.35.9.65.5-.75 1.15-1.4 2.95-2.9 7.1-2.9 4.2 0 7.1 2.9 2.1 2.1 2.7 4.85.55.15 1 .3.5-.5.65-1.15.1-.5.25-.95-.9-2.7-3.05-4.85-1.4-1.4-3.05-2.25m33.8-5.55q-3.45 0-6.25 1.65-.05 0-.05.05-1.15.65-2.15 1.6-.2.15-.4.35-3.65 3.65-3.65 8.85 0 .5.05 1.05 2 .55 3.05 1.7-.3-1.2-.3-2.55 0-4.15 2.9-7.1.5-.5 1.1-.9 2.6-2 6-2 4.2 0 7.1 2.9 2.95 2.95 2.95 7.1 0 .7-.05 1.4.15-.1.25-.25.35-.45 1.25-2.5.25-.45.55-.9-.4-2.7-1.95-4.9-.65-1-1.55-1.9-3-3-7.05-3.5-.85-.15-1.8-.15z"
                    />
                    <path
                        fill="#FFF"
                        d="M377.5 326.45q-.55-.05-1-.1-.65-.05-1.2-.05-.7-.1-1.3-.1-.55-.1-1-.1l-1.1-.1q-.15-.05-.3-.05-1.8-.2-3.5-.4-2.3-.3-4.45-.65-1.15-.25-2.25-.45-3.05-.6-5.85-1.35-.5-.15-.95-.25-.6-.15-1.15-.35-.55-.15-1.05-.35-.85-.25-1.6-.55l-.7-.3q-2.45-1.05-4.2-2.35-1.6-1.2-2.6-2.55-.95-1.35-1.3-2.9-.05 2.1-.1 3.3v1.05q0 10.75 13.65 18.25 13.6 7.6 32.9 7.6 19.25 0 32.9-7.6 13.6-7.5 13.6-18.25 0-1.2-.15-2.35-.4 1.4-1.9 2.65-1.45 1.25-3.95 2.4-1.7.75-3.75 1.45-.4.1-.8.25-1.45.4-3.05.85-.45.1-.9.25-.9.2-1.85.45-.1 0-.2.05-2 .45-4.05.85-.3 0-.6.1-1.45.25-2.95.45l-2.1.3q-2.7.35-5.5.6l-2.6.2q-.85.05-1.65.1-1.05.05-2.05.15l-3.55.1h-.5q-2.05.05-4.1.05h-.3q-5.75 0-10.95-.3z"
                    />
                    <path
                        fill="#FF7E7E"
                        d="M367.15 318.4h.5q1.1.6 4.5 1.75.25.05.55.15.3.1.55.2-.6-2.75-2.7-4.85-2.9-2.9-7.1-2.9-4.15 0-7.1 2.9-.65.65-1.15 1.4.5.3 1.05.5.4.1.9.2 1.6.2 2.95 2.3 1.3 2 1.3 3.9v.5q1.1.2 2.25.45 2.15.35 4.45.65-.85-3.2-.95-3.4v-3.75m26 .35q0 1.35.3 2.55.7.8.95 1.85.05.3.1.7.6 1 1.55 1.95.4.4.85.8 1-.1 2.05-.15.8-.05 1.65-.1-.2-.55-.35-1-1-2.9-1-4.75 0-2.9 1-7.2l.25.1q2.45 3.4 4.2 3.4 1.35 0 2-.35 1.65-.95 2.7-1.4 0 .4.45 1.55.4 1.2.4 1.55v2.25h.55q.65 0 1.1-.05.8-.1 1.25-.3.05-.7.05-1.4 0-4.15-2.95-7.1-2.9-2.9-7.1-2.9-3.4 0-6 2-.6.4-1.1.9-2.9 2.95-2.9 7.1z"
                    />
                    <path
                        fill="#85EE76"
                        d="M370.9 311.35q-.35 0-.65.05-.65.05-1.2.2 1.65.85 3.05 2.25 2.15 2.15 3.05 4.85.6-2.15 1.65-3.6.1-.15.2-.25-.35-.65-.85-1.15-.1-.15-.25-.3-2.1-2.05-5-2.05m23.5-2q-.8-.2-1.65-.2-2.95 0-5.05 2.05-2.05 2.1-2.05 5.05 0 1.45.5 2.7.4 0 .85.05 1.95.15 3.4.6-.05-.55-.05-1.05 0-5.2 3.65-8.85.2-.2.4-.35z"
                    />
                    <path
                        fill="#289119"
                        d="M370.7 309.15q-.25 0-.45.05-1.55.05-2.9.6-.7.3-1.35.7 1.6.3 3.05 1.1.55-.15 1.2-.2.3-.05.65-.05 2.9 0 5 2.05.15.15.25.3.5.5.85 1.15.95-1.2 1.9-1.3-.5-.8-1.2-1.5l-.1-.1q-.85-.85-1.75-1.4-2.2-1.4-4.95-1.4h-.2m22.05 0q.85 0 1.65.2 1-.95 2.15-1.6-1.75-.8-3.8-.8-3.95 0-6.75 2.8-2.75 2.75-2.75 6.7 0 1.1.2 2.1 1.1.2 2.7.4-.5-1.25-.5-2.7 0-2.95 2.05-5.05 2.1-2.05 5.05-2.05z"
                    />
                </g>
                <g id="PlaFor018__Layer5_2_FILL">
                    <path
                        fill="#A9BFCD"
                        fillOpacity={0.702}
                        d="M76.85 365v-1.3q-.25 1.7-4.95 3-5.05 1.3-12.1 1.35h-.2q-6.95 0-12-1.35-4.8-1.3-5.1-3v1.3q.3 1.7 3.3 2.85.5.05 1.1.25 5.35 1.3 12.7 1.4h.2q7.5-.1 12.8-1.4.6-.2 1.05-.25 2.9-1.15 3.2-2.85m-17-37.05h-.25q-1.4 0-2.75-.15-1.45-.2-2.75-.6-.7-.1-1.35-.35 5.5 2.8 5.85 14.6l-.1 14.25q.1 1.75-.55 3.25-.2.5-.45.95l-.35.35-.5 1q-.7 1.5 1.15 2.75.85.3 1.8.3h.2q1.05-.05 1.9-.3 1.8-1.25 1.2-2.75l-.65-1q-.1-.15-.2-.35-.3-.45-.55-.95-.6-1.5-.45-3.25v-12.8h-.1v-1.45q.3-11.8 5.8-14.65-.65.3-1.3.4-1.45.4-2.85.6-1.3.15-2.75.15m-11.15-42.4q4.6.45 11.05.45 6.5 0 11.1-.45 4.2-.4 4.55-.85v-.2q-.55-.45-4.55-.85-3.8-.3-8.85-.4h-4.45q-5.1.1-8.85.4-4.15.4-4.55.85-.05.05-.05.15.2.5 4.6.9z"
                    />
                    <path
                        fill="#B9C9D7"
                        fillOpacity={0.353}
                        d="M62.9 361.25q.6 1.5-1.2 2.75-.85.25-1.9.3h-.2q-.95 0-1.8-.3-1.85-1.25-1.15-2.75l.5-1 .35-.35q.25-.45.45-.95-5.95.2-10.35 1.3-3.8 1-4.8 2.4-.25.3-.3.6v.45q.3 1.7 5.1 3 5.05 1.35 12 1.35h.2q7.05-.05 12.1-1.35 4.7-1.3 4.95-3v-.45q-.05-.3-.2-.6-.9-1.4-4.75-2.4-4.4-1.1-10.4-1.3.25.5.55.95.1.2.2.35l.65 1M59.75 286q-6.45 0-11.05-.45-4.4-.4-4.6-.9-3.05 12.15-2.45 25.7l.15-.15q.7-.55 5-1.1 5.3-.55 12.8-.55h.35q7.4 0 12.7.55 4.3.55 5.1 1.1.1.15.15.2l.05-2.1q.15-12.15-2.5-23.55l-.05-.05q-.35.45-4.55.85-4.6.45-11.1.45z"
                    />
                    <path
                        fill="#CDF0FF"
                        fillOpacity={0.8}
                        d="M77.9 310.55q-.75.9-5.25 1.3-5.3.65-12.7.65h-.35q-7.5 0-12.8-.65-4.45-.4-5.2-1.3.05.85.05 1.4 0 6.65 5.25 11.3 2.55 2.35 5.8 3.5h.05v.1q.65.25 1.35.35 1.3.4 2.75.6 1.35.15 2.75.15h.25q1.45 0 2.75-.15 1.4-.2 2.85-.6.65-.1 1.3-.4l.05-.05q3.1-1.15 5.8-3.5 5.25-4.65 5.25-11.3 0-.55.05-1.4z"
                    />
                    <path
                        fill="#8CDCFF"
                        fillOpacity={0.8}
                        d="M77.75 310.2q-.8-.55-5.1-1.1-5.3-.55-12.7-.55h-.35q-7.5 0-12.8.55-4.3.55-5 1.1l-.15.15q-.05 0-.05.05-.1 0-.1.1 0 .15.1.05.75.9 5.2 1.3 5.3.65 12.8.65h.35q7.4 0 12.7-.65 4.5-.4 5.25-1.3.05.1.05-.05 0-.1-.05-.1-.05-.05-.15-.2z"
                    />
                </g>
                <g id="PlaFor018__Layer5_3_FILL">
                    <path
                        fill="#CCC"
                        d="M109.3 308.55q-.55-.9-2.9-1.05-3.65.3-4.25 2.2-.2.55-.05 1.35-.85 7.1-4.7 15.9-.45.7-1.05 2.6-.65 1.95-1.85 4.1L82.55 366q-.05 0-.05.1-.25 1.9 1.75 1.75 2.7.2 8.3-6.35 10.35-13.2 8.75-24.9-.05-.55-.15-1.1-.05-.5.15-3.2.2-1.2.6-2.95.25-.95.45-1.95.25-.8.45-1.6 1.65-6.05 4.9-12.75.4-.85.9-1.8 1.2-1.75.7-2.7m-1.9 2.7q-.45.9-.85 1.8-2.7 5.55-4.4 11.1l-.05.05-1.7 8.5q-.55 1.25-3.8 1.35 2.9-.45 3.2-1.5.25-1.1.25-1.2-.05-.05.1-.75.05-.65.25-1.5l.15-.9q.05-.4.2-.8.15-.8.35-1.65 1.6-6.3 4.7-12.7l1.2-2.4q.75-1.45-.2-2.3 2.05.8.6 2.9z"
                    />
                    <path
                        fill="#AAA"
                        d="M106.55 313.05q.4-.9.85-1.8 1.45-2.1-.6-2.9.95.85.2 2.3l-1.2 2.4q-3.1 6.4-4.7 12.7-.2.85-.35 1.65-.15.4-.2.8l-.15.9q-.2.85-.25 1.5-.15.7-.1.75 0 .1-.25 1.2-.3 1.05-3.2 1.5 3.25-.1 3.8-1.35l1.7-8.5.05-.05q1.7-5.55 4.4-11.1z"
                    />
                </g>
                <g id="PlaFor018__Layer5_4_FILL">
                    <path
                        fill="#CCC"
                        d="M288.55 344q-1.05-1.05-2.6-1.55-3-1.35-3.65-3.3v-.1q-.5-1.6-1.9-5.8l-.05.05q-.9-2.8-1.6-5.65-.2-.8-.4-1.65-1.55-6.15-2.5-12.7-.15-.95-.2-1.85.1-1.75-1-2.7-1.15-.9-3.55-.95-4.5.35-2.45 3.2 4 8.15 6.4 15 .25.85.5 1.65.55 1.55 1.05 2.95l2.3 8.55q.45 1.95-1.75 3.3-1.25.5-1.75 1.55-.45 1.05-.15 2.65v.15q.65 2.1.8 2.3l6.15 18.55q.15.2.45.2.1 0 .35-.15l-4.4-18.55q-.05-.6.75-.55.6-.05.95.55l6.45 18.5q.15.2.45.2.1 0 .35-.15L282.8 349q-.05 0-.05-.05-.1-.4.5-.4.55-.1.85.65v-.05l6.9 18.5q.15.2.45.2.1 0 .4-.15l-5.2-18.7q-.1 0 0-.05h-.05q0-.5.55-.4.85-.1 1.15.6h-.1l7.35 18.5q.2.2.5.2.1 0 .35-.15l-5.6-18.55h.1q-.2-.8-.75-2.35l.1.15q-.05-.2-.1-.3-.65-1.6-1.6-2.65m-14.85-32.55q-.25-1.95-1.7-3 2.45.75 2.45 3 .1.9.25 1.85.7 5.55 2.5 11.1 0 .05.05.1l2.85 9.15q1.6 6.05.7 3.45-.85-2.6-1.15-3.35l-1.95-6.45q-.2-.65-.45-1.3-2.4-6.3-3.25-12.7-.15-.95-.3-1.85z"
                    />
                    <path
                        fill="#AAA"
                        d="M272 308.45q1.45 1.05 1.7 3 .15.9.3 1.85.85 6.4 3.25 12.7.25.65.45 1.3l1.95 6.45q.3.75 1.15 3.35.9 2.6-.7-3.45l-2.85-9.15q-.05-.05-.05-.1-1.8-5.55-2.5-11.1-.15-.95-.25-1.85 0-2.25-2.45-3z"
                    />
                </g>
                <g id="PlaFor018__Layer5_5_FILL">
                    <path
                        fill="#FFF"
                        d="M353.3 352.3q.15 2.8.15 4.1 0 11.4 14.5 19.4 14.5 8.1 35 8.1t35-8.1q8.45-4.7 12-10.5 2.5-4.15 2.5-8.9 0-1.3-.15-2.5-.45 1.5-2.05 2.85-.5.4-1.1.8-.65.4-1.35.8-2.15 1.2-5.25 2.25-2.1.75-4.6 1.4-.65.15-1.3.35-2.25.55-4.6 1.05-2.25.4-4.55.8-.2 0-.4.05-2.15.3-4.35.55-4.25.5-8.8.75-2.7.15-5.55.25-2.7.05-5.45.05-3.55 0-6.85-.1-5.4-.2-10.35-.65-3.9-.4-7.5-.95l-.5-.1q-4.25-.65-8.05-1.6-.9-.2-1.75-.45-1.15-.3-2.15-.6-3.75-1.15-6.35-2.35-4.7-2.25-6-6.25-.1-.25-.15-.5z"
                    />
                    <path
                        fill="#FFD200"
                        d="M389 342.7l-6.5 2.5 2.05 5.15.3 1-.2.15-7.25.2 1.95-4.85-5.75-1.5-2.7 7.85-2.5-4.05-2.8 1.35-3-2.8-5.25 3.9 2.85 3.4-2.6 1.1 1.85 2.95q2.6 1.2 6.35 2.35 1 .3 2.15.6.85.25 1.75.45 3.8.95 8.05 1.6l.5.1q3.6.55 7.5.95 4.95.45 10.35.65 3.3.1 6.85.1 2.75 0 5.45-.05 2.85-.1 5.55-.25 4.55-.25 8.8-.75 2.2-.25 4.35-.55.2-.05.4-.05 2.3-.4 4.55-.8 2.35-.5 4.6-1.05.65-.2 1.3-.35 2.5-.65 4.6-1.4 3.1-1.05 5.25-2.25.7-.4 1.35-.8.6-.4 1.1-.8l-.45-2.2-7.85 1.55 4.65-5.35-5.5-3.4-2.65 2.8-3.45-2.7-1.85 2.2-4.4-7.8-5.15 2.1 1.6 3.35-9.2 3.15.25-5.5-5.15-.25-1.15 6.6-1.95-2.1-3.8 2.9-2-2.35 3.55-2.8-4.05-4.5-9.75 6.85-2.95-6.6z"
                    />
                    <path
                        fill="#E5E5E5"
                        d="M377 341.45q-4.8.8-9.05 1.9-9.75 2.6-12.95 6-1.55 1.6-1.55 3.35v.1q1.3 4 6 6.25l-1.85-2.95 2.6-1.1-2.85-3.4 5.25-3.9 3 2.8 2.8-1.35 2.5 4.05 2.7-7.85 5.75 1.5-1.95 4.85 7.25-.2.2-.15-.3-1-2.05-5.15 6.5-2.5 2.95 6.6 9.75-6.85 4.05 4.5-3.55 2.8 2 2.35 3.8-2.9 1.95 2.1 1.15-6.6 5.15.25-.25 5.5 9.2-3.15-1.6-3.35 5.15-2.1 4.4 7.8 1.85-2.2 3.45 2.7 2.65-2.8 5.5 3.4-4.65 5.35 7.85-1.55.45 2.2q1.6-1.35 2.05-2.85.15-.6.15-1.2 0-1.75-1.5-3.35-3.15-3.35-13-6-5.65-1.5-12.15-2.35-9.45-1.35-20.7-1.45h-2.15q-14.5 0-25.95 1.9m71.85 12.45l-.1-.05h.05l.05.05z"
                    />
                    <path
                        fill="#ECB500"
                        d="M448.75 353.85l.1.05-.05-.05h-.05z"
                    />
                </g>
                <g id="PlaFor018__Layer5_6_FILL">
                    <path
                        fill="#FFF"
                        d="M93.85 378.3q-1.25-1.55-1.65-3.3-.2 3-.2 4.35 0 .85.1 1.7 0 .4.1.8 1.1 7.95 9.55 14.2 2.25 1.65 5.05 3.25.1 0 .15.05 14.95 8.3 36.05 8.3 20.45 0 35.15-7.75.45-.3.95-.55 2.4-1.35 4.45-2.75 9.55-6.75 10.4-15.6.05-.5.05-1v-.65q0-1.35-.15-2.6-.25.85-.9 1.7l-.6.7q-3.4 3.3-13.25 5.95-14.95 4-36.1 4-21.1 0-36.05-4-8.9-2.4-12.5-6.1-.35-.35-.6-.7z"
                    />
                    <path
                        fill="#833712"
                        d="M111.15 370.4q-.15.1-.25.2-.4.4-.7.85-1.9.55-3.7 1.15-8.3 2.85-12.05 6.4 3.6 3.7 12.5 6.1 14.95 4 36.05 4 21.15 0 36.1-4 9.85-2.65 13.25-5.95-3.85-3.6-12.3-6.55-4.3-1.5-9.05-2.55-2.5-2.7-6.6-3.95-3.55-1.05-8.2-1.05-3.55 0-5.75 2.1-.15.1-.25.2-.05.05-.1.15-2.7-.15-5.45-.15-3.55-1.05-8.2-1.05-3 0-5 1.5-.3 0-.55.05h-.25l-7.3 1.05q-2.7-.55-5.95-.6h-.5q-3.55 0-5.75 2.1m2.35-.1q-.002-.096-.15-.1h.15v.1m39.3-3.25q-.002-.096-.15-.1h.15v.1z"
                    />
                    <path
                        fill="#CCC"
                        d="M152.65 366.95q.148.004.15.1v-.1h-.15m-39.3 3.25q.148.004.15.1v-.1h-.15m-2.45.4q.1-.1.25-.2 2.2-2.1 5.75-2.1h.5q3.25.05 5.95.6l7.3-1.05h.25q.25-.05.55-.05 2-1.5 5-1.5 4.65 0 8.2 1.05 2.75 0 5.45.15.05-.1.1-.15.1-.1.25-.2 2.2-2.1 5.75-2.1 4.65 0 8.2 1.05 4.1 1.25 6.6 3.95 4.75 1.05 9.05 2.55 8.45 2.95 12.3 6.55l.6-.7q.65-.85.9-1.7.15-.6.15-1.2 0-1.85-1.55-3.45-3.25-3.5-13.35-6.2-14.95-3.9-36.1-3.9-21.1 0-36.05 3.9-10.05 2.65-13.35 6.2-1.6 1.6-1.6 3.45 0 .6.2-.55.4 1.75 1.65 3.3.25.35.6.7 3.75-3.55 12.05-6.4 1.8-.6 3.7-1.15.3-.45.7-.85z"
                    />
                </g>
                <g id="PlaFor018__Layer5_6_MEMBER_0_FILL">
                    <path
                        fill="#A35733"
                        d="M130.45 368.6q-.45.45-.8.95-.25.35-.5.75-.2.35-.35.65 1.8 1.1 3.15 2.65 1.4 1.65 2.3 3.95l12 6.25q-.4-6.05-3.95-10.6-3-3.85-6.95-4.7-1.1-.3-2.3-.3v.1q-.002-.096-.15-.1h.15q0-.1-.25-.1t-.65.1q-.15 0-.3.05-.6.1-1.4.35m19.75-1.25q-.05.05-.1.15-.4.35-.7.8-.25.35-.5.75l-.1.2q1.55 1 2.7 2.35 1.6 1.9 2.6 4.75l11.9 6.2q-.4-6.05-3.95-10.6-2.15-2.8-4.8-4-1.05-.45-2.15-.7-1.1-.3-2.3-.3v.1q-.002-.096-.15-.1h.15q0-.1-.25-.1t-.65.1q-.65.1-1.7.4m-36.85 2.85h.15q0-.1-.25-.1t-.65.1q-.65.1-1.7.4-.4.4-.7.85l-.1.1q-.25.35-.5.75-1.15 1.9-1.4 3.8l18.5 9.7q-.15-2.6-.9-4.9-1-3.1-3.05-5.7-3-3.85-6.95-4.7l-.4-.1q-.35-.1-.75-.1-.55-.1-1.15-.1v.1q-.002-.096-.15-.1z"
                    />
                    <path
                        fill="#56240C"
                        d="M148.8 369.25q-1.8-1.2-4.15-1.9-3.55-1.05-8.2-1.05-3 0-5 1.5l-.3.2q-.25.2-.45.4-.15.1-.25.2.8-.25 1.4-.35.15-.05.3-.05.4-.1.65-.1t.25.1q1.2 0 2.3.3 3.95.85 6.95 4.7 3.55 4.55 3.95 10.6l.4.2h8.9q-.3-4.45-1.45-7.65-1-2.85-2.6-4.75-1.15-1.35-2.7-2.35m1.65-2.1q-.15.1-.25.2 1.05-.3 1.7-.4.4-.1.65-.1t.25.1q1.2 0 2.3.3 1.1.25 2.15.7 2.65 1.2 4.8 4 3.55 4.55 3.95 10.6l.4.2h8.9q-.55-8.25-4.05-12.4-.15-.15-.25-.3-2.5-2.7-6.6-3.95-3.55-1.05-8.2-1.05-3.55 0-5.75 2.1m-39.3 3.25q-.15.1-.25.2 1.05-.3 1.7-.4.4-.1.65-.1t.25.1q.6 0 1.15.1.4 0 .75.1l.4.1q3.95.85 6.95 4.7 2.05 2.6 3.05 5.7.75 2.3.9 4.9l.4.2h8.9l-.1-1.5q-.45-4.05-1.65-6.95-.9-2.3-2.3-3.95-1.35-1.55-3.15-2.65-1.65-1-3.7-1.6-.85-.25-1.75-.45-2.7-.55-5.95-.6h-.5q-3.55 0-5.75 2.1z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#PlaFor018__Symbol_24_0_Layer0_0_FILL"
                transform="translate(-84.35 295.5)"
                id="PlaFor018__col1n"
            />
            <g id="PlaFor018__col2n">
                <use
                    xlinkHref="#PlaFor018__Symbol_12_0_Layer0_0_FILL"
                    transform="translate(20.5 302)"
                />
                <use xlinkHref="#PlaFor018__Symbol_12_0_Layer0_1_FILL" />
            </g>
            <use xlinkHref="#PlaFor018__Layer5_0_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_1_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_1_MEMBER_0_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_2_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_3_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_4_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_5_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_5_MEMBER_0_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_6_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_6_MEMBER_0_FILL" />
            <use xlinkHref="#PlaFor018__Layer5_7_FILL" />
        </g></g>
    )
}

export default PlaFor018
