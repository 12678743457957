import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1l": "#FFFFFF",
    "col1n": "#EEEEEE",
    "col2n": "#252525"
}

function ObjToy082({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M386.75 328.4q8.05-1.7 14.55-1.7 10.55 0 20.05 4.45 5.15 2.35 7.55 3.15 4.6 1.4 10.1 1.4 7 0 25 .15 17.95.15 84.75-3.75.3 0 .5-.25t.2-.55q0-.3-.25-.5t-.55-.2q-66.7 3.9-84.65 3.75-18-.15-25-.15-5.3 0-9.65-1.35-2.35-.75-7.35-3.05-9.85-4.6-20.7-4.6-6.6 0-14.85 1.75-8.05 1.65-11.15 1.65-11.95 0-19.5-3.85-.75-.35-1.35-.75-5.2-3-5.7-3.25-1.3-.6-2.65-.9-2.35-.65-5.1-.65h-.65q-.35 0-.75.05v1.5q.4-.05.75-.05h.65q2.55 0 4.7.6h.05q1.2.25 2.35.8.45.2 5.6 3.2h-.05q.7.4 1.5.8h-.05q7.85 4 20.2 4 3.2 0 11.45-1.7z"
                    id="ObjToy082__Layer11_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M0 74.95l31.15 9.75q5.2-39.5 0-77.95Q16.25-.3 0 0v74.95z"
                    id="ObjToy082__Symbol_150_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 2.85L31.15 9.6q9.7 0 12.9-3L13.35 0 0 2.8v.05z"
                    id="ObjToy082__Symbol_153_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.9 0L0 3v77.95l12.9-3.9V0z"
                    id="ObjToy082__Symbol_155_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M38.9 64.25l-1.75.45v5.65l1.75-.75v-5.35m-1.6-3.85q-.25.3-.25.7 0 .4.25.65.3.35.7.35.4 0 .7-.35.3-.25.3-.65t-.3-.7q-.3-.25-.7-.25-.4 0-.7.25M13.45 39q5.55 1.4 9.5-3.2 3.95-4.7 3.95-12.75 0-7.95-3.95-14.65T13.45.3q-5.6-1.4-9.6 3.3Q0 8.3 0 16.3q0 8 3.85 14.7 4 6.6 9.6 8z"
                    id="ObjToy082__Symbol_156_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M338.65 274.55q.05.7.45 1.35.25-1.6.95-2.5-.5-.3-1-.5-.45.65-.4 1.65m.7 1.55q.55.6 1.3.55.85-.1 1.35-.75.05-.05.1-.2h-.05q-.7-1.2-1.55-1.95-.75.85-1.35 2.35.15-.1.2 0m1.1-3.2q.4-.4 1-.65-.45-.4-1.1-.2-.5.05-.95.45.55.1 1.05.4m2 1.2q0-.95-.7-1.55-.45.3-.9.75.75.75 1.25 2.25l.05.05q.35-.8.3-1.5z"
                    id="ObjToy082__Layer2_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy082__Layer11_0_FILL"
                transform="translate(-18.4 12.15)"
            />
            <use
                xlinkHref="#ObjToy082__Symbol_150_0_Layer0_0_FILL"
                transform="translate(305.85 262.5)"
                id="ObjToy082__col1n"
            />
            <use
                xlinkHref="#ObjToy082__Symbol_153_0_Layer0_0_FILL"
                transform="translate(305.85 259.65)"
                id="ObjToy082__col1l"
            />
            <use
                xlinkHref="#ObjToy082__Symbol_155_0_Layer0_0_FILL"
                transform="translate(337 266.25)"
                id="ObjToy082__col1d"
            />
            <use
                xlinkHref="#ObjToy082__Symbol_156_0_Layer0_0_FILL"
                transform="translate(307.9 270.85)"
                id="ObjToy082__col2n"
            />
            <use xlinkHref="#ObjToy082__Layer2_0_FILL" />
        </g></g>
    )
}

export default ObjToy082
