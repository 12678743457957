import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF"
}

function ObjToo002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.15 82.15q-.05.15-.05.3-.95 3.2-2.55 4.8-.75.7-1.6 1.1l-.2.1h-.05q-1.05.4-2.05.6l.1.75q.35 5.4 5.7 5.05.35-.1.65-.25.25-.1.45-.2 1.85-1 2.25-2.75.5-2.3-1.25-6-.7-1.85-1.35-3.7l-.05.2M12.25.1q-.5-.25-.7.25l4.3 23.3h.05q.1.9-.7 1.05-.5.2-.8-.4h.1q-.15-.1-.15-.15L8.3 1.1q-.55-.3-.7.25l4.3 23.3q.15.9-.45.95-.55.1-.65-.4l-.1-.1L4.65 2q-.5-.3-.7.25L8.3 25.6q.05.75-.55.85-.75.3-1.05-.5L.65 3Q.2 2.7 0 3.25l4.3 23.4.65 3.2q.05.1.05.2l-.05.05q.7 2.15 1.75 3.5 1.05 1.3 2.65 1.75 2.95 1.4 3.75 4.3v.05l.05-.05L16.4 51.9h.05q.6 2.35 1 4.1.4 1.7.7 3.05.5 2.25.95 4.6.2 1.15.45 2.35h.05q1.55.2 3.25.75.95.4 1.8 1-.65-2.1-1.25-4.1-.85-2.7-1.65-5.35-.45-1.35-.8-2.55-1.25-4.35-2.4-8.45h.05q-.6-2.35-1.05-4.1h.05q-.65-2.85-1-4.2v-.15l-.05-.05q-.65-2.95 1.4-5.65 1.2-1.15 1.5-2.7.35-1.75-.2-3.95l-.1-.3.05.1q-.55-2.15-.85-3.3l-.05.05L12.25.1z"
                    id="ObjToo002__Symbol_162_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.55 51.15q-.3-1.05-.4-1.65-.2.05-.35.15l-.2.1h-.05q-.2.05-.35.15.5 3.7 3.25 4.7-1.45-1.6-1.9-3.45M4.3 17.3L1.65 6.4Q1.4 5.2.35 1.2-.7-2.85 1.3 6.65L5.2 22.4q0 .1.05.15.55 2.35 1.1 4.9.4.05.8.15-1-4.2-2.15-8.1-.35-1.15-.7-2.2z"
                    id="ObjToo002__Symbol_116_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo002__Symbol_162_0_Layer0_0_FILL"
                transform="translate(95.9 221.25)"
                id="ObjToo002__col1n"
            />
            <use
                xlinkHref="#ObjToo002__Symbol_116_0_Layer0_0_FILL"
                transform="translate(110.05 259.95)"
                id="ObjToo002__col1d"
            />
        </g></g>
    )
}

export default ObjToo002
