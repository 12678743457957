import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacEye021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M53.35 12Q49.15 9.55 49 .25 41.05 8.45 28.95 9q-9.05-.05-14.7-4.9Q12.7 2.75 10.7.3 9 4.55 0 10.05q8.1.3 12.3-2.75-.5 3.15-4.35 8.85 6.9-1.5 9.3-5.15.9 4.3.4 8.3 3.65-2.1 5.9-5.95l2.2 7.65 4.15-8.6q1.9 3.85 7.35 7.3-1.85-5.2-.35-9.35 2.65 4.25 8.85 6.55-3.05-4.15-1.3-9.4 1.75 3.75 8.9 4.5m49.45-5.2q3.7 3.05 11.55 2.85-5.45-3.6-9.55-9.65Q98.85 9 86 9.1 73.9 8.55 66.35.35q.35 8.35-3.95 11.3 7.25.4 8.1-4.05 1.55 4.25-1 9.7 5.8-2.4 8.55-6.85 1.8 4.4.25 9.15 5-3.3 6.75-7.1l4.1 8.6 2.25-7.65q1.5 3.15 5.9 5.95-.9-4.65.4-8.3 3.3 3.6 9.2 4.85-4.15-5.75-4.1-9.15z"
                    id="FacEye021__Symbol_65_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye021__Symbol_65_0_Layer0_0_FILL"
                transform="translate(135.05 179.25)"
                id="FacEye021__col1n"
            />
        </g></g>
    )
}

export default FacEye021
