import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1l": "#B4B4B4",
    "col1n": "#999999",
    "col1s": "#434343"
}

function ObjToo059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M38.55 49.05q-.05.05-.05.1v.1l.05-.2m5.9-17.55l.05-.4-.05.2v.2m32-24.95q-.3-1.25-.9-4.1-.3-1.6-.45-2.45h-.05q-.25.05-.55.2L14.85 18.75l-1.95 3.6q-.35.5-1.35 1.2-.95.95-1.3 1.65-.4.65-.55 2.35-.1.7-.25 1.05v.05q-2.95 4.7-3.5 11.05.25.5.55 1.25.45.8 2.9 1.95 1.7.75 2.65 1.35.8.4 1.55 1.1.4.35.75.7.3.25.5.45 1 1.1 1.4 3.25.3.8.45 1.45.3 1.35.3 3.55 0 2.35-1.7 5.15-.85 1.5-1.95 2.4-1.65 1.6-4.05 1.95H6.7q-1.55.2-4.9-1.85-.3-.2-.6-.35L.05 67.5q-.65 4.35 4.4 5l15.3-3.1q1.4-.75 2.3-1.65.7-.6 1.3-1.55 0-.7.55-2.45.6-2 1.2-2.2 0-.05.05-.1 1.55-.95 8.5-4.6l1.35-1q.15-.45.55-1.15.8-1.55.85-1.7.1-.5 1.05-3.7 1.25-5.1 1.25-7.8l-.1-1.95q0-.55.2-.75-.05-.1-.05-.2l2.05-.55q.15-.25.4-.65.75-1.15 1.1-1.8.4-.95.9-3 .15-.8.55-3.3.35-2.55.35-2.95-.3-1.9-.3-2.5 0-.45.15-.7l.05-.05q-.05-.1-.05-.15v-.1l32.85-9.5q.4-.05.85-.2-.15-.5-.3-1.35-.75-4.6-.9-5.25M17.3 49.05q0-3.15 2.25-5.4 2.25-2.25 5.4-2.25 1.9 0 3.45.8.55-.55.9-.7l.05-.05 2.5-.75q.6 3.7.55 6.45.2.9.2 1.9 0 3.15-2.25 5.4-2.25 2.25-5.4 2.25-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4z"
                    id="ObjToo059__Symbol_218_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M1.05 66.25Q.7 67.1 0 67.85q1.95-1.65 3.1-6.3l-.05.05-1.75.95v.05q.4 2.05-.25 3.65m17.55-28l3-.85q3.25-2.65.3-14.35l-3.3.95q2.15 8.6 0 14.25m-1.9.45l-3.25 1.05q1.1 7.3-.45 14.95-.15.75-.35 1.5l3.25-3.1q2.25-7.35.8-14.4M3.35 20.65q-.7.75-1.25 1.55Q.6 24.5.6 27.4q0 3.95 2.75 6.7 2.7 2.7 6.45 2.8h.4q1.25 0 2.5-.3l1.5-.25-4.4-18.6h-.15L8.8 18q-1.85.4-2.55.75-.65.25-1.3.6-.85.55-1.6 1.3M59.65 6.7q0-2.15-1-3.8-.25-.5-.65-.95Q56.4 0 54.15 0 53.6 0 53 .1q-.25.05-.55.2-1.2.45-2.25 1.65-.3.45-.6.95-.95 1.65-.95 3.8 0 2.8 1.55 4.8 1.7 1.95 3.95 1.95h.6q.65-.05 1.3-.4 1.05-.5 1.95-1.55 1.65-2 1.65-4.8z"
                    id="ObjToo059__Symbol_159_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.05 4.35q.05 0 .1.05l-.05.05q-.1.1-.1.4 0 .5.3.75.35.4 1.5.6.35.05 1.9.55Q5.05 8.1 5.65 10L9.1 3.7q-4.95-.65-9.05.65m20.3-.9q.55-.8 1.25-1.55.75-.75 1.6-1.3.65-.35 1.3-.6L19 1.6q-.15 0-.25.1-.35.2-.65.4-.85.55-1.6 1.3-.7.75-1.3 1.55-1.5 2.3-1.5 5.2 0 3.95 2.8 6.7 2.8 2.8 6.75 2.8h.25q.4-.05.8-.15.35 0 .7-.05l6.75-1.7-.8.1q-1.25.3-2.5.3h-.4q-3.75-.1-6.45-2.8-2.75-2.75-2.75-6.7 0-2.9 1.5-5.2z"
                    id="ObjToo059__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M8.2 11.2q-.6-.6-1.45-.6-.9 0-1.5.6-.35.4-.45.85-.15.3-.15.65 0 .85.6 1.45t1.5.6q.85 0 1.45-.6t.6-1.45q0-.9-.6-1.5M3.6 7.15q-.05-.05-.05-.1-.6-.6-1.5-.6-.85 0-1.45.6T0 8.55Q0 9.4.6 10t1.45.6q.9 0 1.5-.6.35-.35.5-.85.1-.3.1-.6 0-.85-.55-1.4m2.65-5.1q0-.85-.6-1.45T4.15 0Q3.3 0 2.7.6q-.35.3-.45.75-.15.3-.15.7 0 .85.6 1.45l.1.1q.55.55 1.35.55.9 0 1.5-.65.6-.6.6-1.45z"
                    id="ObjToo059__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    d="M185.8 245.35q-1.1-1.1-2.6-1.1t-2.6 1.1q-.2.15-.35.3-1.15 1.45-1.15 3.45 0 2.05 1.15 3.45 1.25 1.45 2.95 1.45 1.7 0 2.9-1.45 1.2-1.4 1.2-3.45 0-2-1.2-3.45l-.3-.3z"
                    id="ObjToo059__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo059__Symbol_218_0_Layer0_0_FILL"
                transform="translate(107 242.55)"
                id="ObjToo059__col1n"
            />
            <use
                xlinkHref="#ObjToo059__Symbol_159_0_Layer0_0_FILL"
                transform="translate(129.05 242.45)"
                id="ObjToo059__col1l"
            />
            <use
                xlinkHref="#ObjToo059__Symbol_140_0_Layer0_0_FILL"
                transform="translate(110.8 261.2)"
                id="ObjToo059__col1d"
            />
            <use
                xlinkHref="#ObjToo059__Symbol_112_0_Layer0_0_FILL"
                transform="translate(131.15 262.95)"
                id="ObjToo059__col1s"
            />
            <use xlinkHref="#ObjToo059__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo059
