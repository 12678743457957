import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo041({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjFoo041__Layer7_0_FILL">
                    <path d="M158.65 229.45q-.5.25-.95.7-.85.8-1.2 1.5-1.25 2.55 0 5.05.05.15.15.35.15.25.35.5 2.1 1.7 5.2 1.85h.1q.55 0 1.05-.05 1.373-.291 2.25-1.2.058.064.15.05.475.064 1.95-2.5 1.528-2.508.8-3.85-.67-1.348-1.45-1.8-.783-.467-2.5-.95-1.665-.485-2.55-.55-.895-.055-1.5.15-1 .15-1.85.75m4.45 7.25l-.1.05v-.05h.1z" />
                    <path fill="#A60000" d="M163 236.75l.1-.05h-.1v.05z" />
                </g>
                <g id="ObjFoo041__Layer7_1_FILL">
                    <path
                        fill="#C40000"
                        d="M160.1 230.7q-.1-.15-.1-.3l-.15-.15q-.25.1-.4.15-.15.35-.7.75-.95.6-1.15 1.4-.05.1-.05.7.05.55-.25.8-.15.15-.25.8 0 .1-.05.2.15 1 .8 1.8 1.05-.1 1.2.2.5.55.6.6.05 0 1.3.25.3.05.45.2.45-.2.85-.4.15-.2.4-.4 0-.1-.05-.15-.2-.45-.2-.6 0-.4.75-.5.2-.05.4-.05.35-.85.35-1.75 0-.85-.3-1.55-.8 0-.85-.5.1-.55.05-.7-.2-.35-.35-.5-.4-.4-.8-.55l-1.1.55q-.3 0-.4-.3z"
                    />
                    <path
                        fill="#773900"
                        d="M160.5 228.15q-.15.1-.4.2-1 .6-1.45 1.1.85-.6 1.85-.75.6-.2 1.25-.1t1.95.65q1.25.55 1.75 1.35.45.75.6 1.1.75 1.55.75 3.3 0 1.45-.75 2.65l-.1.1v.05q-.95 1.2-2.6 1.55h.2q1.5 0 2.9-.55 1.35-.55 6.1-2.9 4.7-2.4 6.65-3.75 1.9-1.45 2.35-2 .1-.1.15-.2.3-.35.5-.6 2-3.1 0-4.85-.1-.1-.2-.15-2.3-1.2-6.1-1.05-3.85.1-7.05 1.25-3.15 1.1-6.6 2.75-1.1.5-1.75.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo041__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo041__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo041
