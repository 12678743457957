import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400",
    "col2n": "#7E00FF"
}

function ShiSho066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M108.1 18.9q-4.05-9.05-4.2-18.9-2.7 1.4-5.45 2.6.25 15.15-5.85 20.85l-3.2 1.65q-16.75 5.05-28.95 19-.5.55-.95 1.1-.6-.75-1.2-1.45-11.7-13.65-28-18.65l-3.4-1.65Q20.8 17.7 21.1 2.8 18.3 1.55 15.6.15q-.15 9.05-3.65 17.5-.7 1.45-.8 1.55-2.3 2.65-4.1 5.35-3.8 5.15-5.6 10.55L.7 39 0 42.4q3.5.85 7.05 1.6 5.15 1.2 10.4 2.15 43.55 9.8 85.55 0 8.05-1.5 16.15-3.55-.2-.95-.35-1.8l-1.95-7.9q-2.65-7-8.5-13.7-.1-.15-.25-.25v-.05z"
                    id="ShiSho066__Symbol_196_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M121.1 4.85l-.2-1.35q-.05-.15-.05-.3l-.1-.45q-.3-1.3-.55-2.55-30.95 7.9-61.15 7.4l-7.5-.2q-2.25-.1-4.5-.25Q26.9 5.95 8.1 1.6 4.55.85 1.05 0q-.2 1-.4 2.05L0 4.8q4.05.8 8.1 1.5 63.65 12 113-1.45z"
                    id="ShiSho066__Symbol_195_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho066__Symbol_196_0_Layer0_0_FILL"
                transform="translate(132.9 239.05)"
                id="ShiSho066__col1n"
            />
            <use
                xlinkHref="#ShiSho066__Symbol_195_0_Layer0_0_FILL"
                transform="translate(131.85 281.45)"
                id="ShiSho066__col2n"
            />
        </g></g>
    )
}

export default ShiSho066
