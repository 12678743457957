import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#BFBFBF",
    "col1n": "#D9D9D9"
}

function BodTai010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M88.65 21.8l-.6-.8q-.6-.7-1.1-1.4-1.05-1.3-2.15-2.5-5.35-6.15-11.6-10.95L71.4 4.8q-2.3-1.7-4.7-3.2l-1.65-1Q64.5.3 64 0q.05.1.1.25.5.9 1 1.95l.3.6q.45.8.9 1.75v.05q.15.3.35.65l.4.9q3.2 6.8 3.25 10.15v.2q-.3-.3-.55-.6-1.6-1.65-2.7-1.9-.45-.15-.8-.05 1.05 1.15 1.75 3.7 0-.05.15.2.45 2.45.75 4.75 2.75 16.6-5.95 26.55-8.6 8.55-10.3 18.8-2.8-5.55-.55-8.1-.4.25-.85.6-.05 0-.05.05-.25.15-.45.3l-.4.3-.7.6q-.1.1-.2.15-.15.1-.25.25-.45.35-.85.75-.6.5-1.15 1.1l-.1.1q-.15.15-.3.35-.25.2-.45.45-4.65 4.85-10.5 13.35-.55.7-1.95 2.2-1.45 1.4-1.15 1.05l-6.6 5.4q-6.45 4.85-10.9 8.55-5.35 4.35-7.9 7.1-2.45 2.6-4.8 6.95-4.35 8.3-1.3 12.85.55.75 1.3 1.45l.1.1q.2.15.5.3.55.35 1.15.55 1.1.4 2.35.35 3.8-.2 8.85-4.7 1.95-1.5 4.4-3.5l5.65-4.1q3.2-1.65 7.05-2.95 2.75-.95 5.8-1.75 3.85-.95 7.1-1.9 4.7-1.4 8.2-2.75Q68 97.95 86.25 86.25q-3.1.65-5.25.9-.75.1-1.35.15h-.7q-.55.05-1 0-.25 0-.5-.05-.45-.1-.65-.15.8-.1 1.6-.25l.5-.1 1.8-.5q.9-.3 1.75-.6 3.05-1.3 5.6-3.5 4.65-4.05 10.1-14.35 6.85-14.7 2.95-28.85-1-3.55-2.65-7-.15-.35-.3-.65-1.9-3.85-4.35-7.2-3.05-4.15-4.75-6.1.3 1.1.7 2.55.35 1.25.75 2.8.05.2.1.45.1.55.25 1.1 0 .1.05.2l-1.65-2.5-.6-.8z"
                    id="BodTai010__Symbol_34_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M46.4 97.4l-1 .3q-3.15 1.05-7 2.1-2.15.6-4.5 1.2-7.35 1.9-12.85 4.7l-4.65 3.4q3.95-4.55 8.75-7t10.05-4.65q5.25-2.2 9.9-4.05 4.6-1.85 7.5-3.9-19.1 5.8-23.65 7.7-4.55 1.9-9.05 5.7-4.5 3.8-10.3 11.05-.75.9-1.4 1.6-2.6 1.8-4.8 2.3-1.85.35-3.4-.1.55.75 1.3 1.45l.1.1q.2.15.5.3.55.35 1.15.55 1.1.4 2.35.35 3.8-.2 8.85-4.7 1.95-1.5 4.4-3.5l5.65-4.1q3.2-1.65 7.05-2.95 2.75-.95 5.8-1.75 3.85-.95 7.1-1.9 4.7-1.4 8.2-2.75Q66.75 93.4 85 81.7q-13.5 7.85-38.6 15.7m15.15-26.1q1.65-1.4 3.55-1.15 1.85.2 4.45-1.3 2.55-1.5 6.3-7.95-4.7 5.25-7.1 6.15-2.8 1.05-5.95 1.75-2 .45-2.95 2.35L53.6 78.7q4.65-4.25 4.95-4.55.25-.3.75-.85.6-.65 2.25-2m-32.9 14.9Q23.4 89 23.2 89.15l-10.4 7.4q-2.45 1.7-4.45 5.05-.35.55-2 3.85 6.2-7.75 10.6-9.8 2.95-1.4 8.1-3.3 5.45-2.05 7.75-2.55l13-3q3.8-.9 8.05-2.95 4.25-2.1 6.2-5.55Q53.5 83.1 49.9 84q-3.5.8-16.45 3.35 12.15-4.4 15-7.7 1.2-1.25 2.1-2.45 2.4-3.3 2.65-6.75l-.15-2.9-.1.1Q52 71.15 50 73.2q-1.95 2-3 2.7 1.3-3.4-.2-6.25-.75 3.8-3.3 6.45-2.6 2.65-6.7 5.45-2.55 1.75-8.15 4.65M85.8 58.65V57.3q-.9 2.15-3.4 5.9-2.5 3.75-4.25 5.05-1.75 1.25-3.45 2.65-1.75 1.35-5.55 3.75 8.55-2.2 12.8-6.7t3.85-9.3M82.7 40.8v-.25q-.3 3-2.7 4.95-.65.5-3.3 1.9h-.25q2-3.75 2.6-6.35.65-2.55.65-6.95 0-2.45-1.85-6.6-1.55-3.5-3.25-5.8.8 2.7 1.2 4.2 1.3 4.95 1.4 7.65.1 1 .1 2.15 0 .7-.15 1.5-.4 2.3-2.1 5.65-2.15 4.25-4.5 6.55-2.55 2.4-3.15 3.4-.2.25-1.2 2.75-.4.95-.75 3.85l-.15 2.65-.1.6v.9q1.35-3.5 1.7-4.15 1.5-2.6 4.55-4.75 4.7-3.4 5.75-4.4 4.65-4.45 5.5-9.45m14.5-13.4q-.15-.35-.3-.65.15.4.75 2.35.55 1.95 1.9 9.75 1.3 7.75-3.5 18.4-.35 1.1-.75 2.15 1.6-12.35-3.35-23 1.45 7.8 1.7 14.15.5 10.6-7.5 23.1-2.1 2.5-3.25 3.55-1.15 1-1.9 1.5-.8.5-2.6 1.55-1.05.55-2.15 1.05-.8.3-1.6.55-1.95.65-4.15.95 1.45.5 5.65-.1h.05q-.45-.1-.65-.15.8-.1 1.6-.25l.5-.1 1.8-.5q.9-.3 1.75-.6 3.05-1.3 5.6-3.5 4.65-4.05 10.1-14.35 6.85-14.7 2.95-28.85-1-3.55-2.65-7m-9.8-10.15l-.6-.8q-.6-.7-1.1-1.4-1.05-1.3-2.15-2.5Q78.2 6.4 71.95 1.6q10.25 8.45 18.1 23l-.4-4.05-1.65-2.5-.6-.8z"
                    id="BodTai010__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai010__Symbol_34_0_Layer0_0_FILL"
                transform="translate(237.3 177.5)"
                id="BodTai010__col1n"
            />
            <use
                xlinkHref="#BodTai010__Symbol_26_0_Layer0_0_FILL"
                transform="translate(238.55 182.05)"
                id="BodTai010__col1d"
            />
        </g></g>
    )
}

export default BodTai010
