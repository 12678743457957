import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col1s": "#121212"
}

function ObjToo054({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M291.15 11.55q.5-1.75-.75-3.5l-23.25-5.9q-.55-.35-1.1-.25L218.95 9q-.3.05-.6.35-.2.2-.45.5V10l-5.3 6.65-4.2-2.45-.45-3.3-8.4 1.35.5 3.2-1.4.25v.05l-25 3.75-6.15-5 3.15-3.1-1.45-9.65L165.3 0l-30.35 4.6-6.3 3.55-3.15-2.05q-.5.6-.8 1.9-.05.2-.05.4-.4 2.05.05 4.8.4 3 1.5 4.9.7 1.15 1.3 1.55.45.25.9.25 1.15-.1 1.65-2.4.15-.3.2-.55l6.95 2.15 12.6-1.85 5.05 5.1-40.4 6.15q-.1-.1-.15-.2-1.95-3.05-4-2.75-.3.05-.5.1L68.95 31.9q-2.3.65-3.5 3.75l-.1.3L21.9 42.5q-.35-.4-.8-.9-2.8-3.45-6.05-2.9-.3.05-.55.1l-7.4 1.15q-.5.05-.9.1-3.3.5-5.1 4.6-1.5 3.7-.95 8.4-.05.3 0 .6v.2q.05.15.05.2.05.45.15.85.05.3.15.65.95 4.2 3.35 7.05 2.9 3.45 6.25 2.9.4-.05.85-.2l7.35-1.05q.3 0 .6-.05 3.3-.55 5-4.6.1-.15.15-.35-.05-.05-.05-.15v-.6l.6-.1q-.05-.25-.05-.5 0-2.95 3.1-5 3.1-2.1 7.5-2.1t7.5 2.1q1.65 1.1 2.4 2.45l22.7-3.5q.05.15.2.25 2.1 2.6 4.45 2.6l16.25-2.45q.3.7.85 1.65.15.3 1.35.9 1.1.6 1.2 1.2.3 1.9 1.8 2.7 1.05.55 1.45 1.15.1.4.1.7-.05.4 0 .75v.05q.05.5.35.8.3.4 1.15.65.75.25.75.6l-.1 1.05q0 .05.1.8.25.75.95.75.2 0 .35-.1.2 0 .4-.05 2.1-.25 3.8-.45 5.75-.75 4.35-4.4l-4.4-11.05 10.15-1.45q.25.05.5-.05 2.05-.35 3.05-3.75 0-.15.05-.3l1.8-.25 1.05 7.2 4.1-.65 2.75 2.2 9.35-1.4 1.7-2.9 8.1-1.25.05.05q.3-.1.75-.05 1.35.05 2.1 1.15.4 1.65.5 2.25.05.4.8 4.65.2 1.3 1.4 4.6.35 1.05 1 1.85 1 .15 2.4.15 3.2 0 7.15-1.5 1.6-.65 2.7-1.25.45-1.65.45-4.3.05-.8 0-1.15 0-.2-.05-.4-1.5-.55-2.75-1.8-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4 2.25-2.25 5.4-2.25 3.15 0 5.4 2.25 1.15 1.15 1.75 2.6 1-.25 2.6-.6 4.4-.95 6.8-.95.55 0 2.1.5 1 .8 1.9 1.9.7.95 2.05 2.85.3.4.95 1.4.15.15.3.4.45.65 1.45.65 2.05 0 3.85-5.5 1.75-4.5 0-9.55l-.8-2.1 6.5-1 .5 3.2 8.35-1.25-.45-3.25 3.3-3.55 7 4.9q.3.25.7.35.35.15.6.1l47.1-7.15q.5-.1 1-.6l20.6-12.6z"
                    id="ObjToo054__Symbol_213_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M51.55 38.25q.15-1.15.65-2.3L4.3 43.3q-.5-.25-1.05-.15-1.8.25-2.75 2.8V46q-.75 2.2-.35 5.1 0 .3.05.65V52q0 .1.05.2.05.3.1.65.05.4.15.65.6 2.4 1.85 4.2 1.55 2.05 3.4 1.8.55-.1 1-.4l4.7-.7q-.05-.25-.05-.5 0-2.95 3.1-5 3.1-2.1 7.5-2.1t7.5 2.1q1.65 1.1 2.4 2.45l22.7-3.5q-2-2.5-2.7-6.15-.1-.3-.15-.6l-.15-.5v-.35l-.05-.45q-.05-.3-.05-.55-.15-1.05-.15-2.05-.1-1.5.2-2.95m79.5-11.2v-.6L98.1 31.4q-.1.35-.2.65-.1.8-.1 1.7-.55 6.1 3.3 10.4.85.7 1.75.6.5-.05.85-.35l29.4-4.45q-1-3.15-1.45-6.35-.55-3.25-.6-6.55m25.3-23.3l-.3-2-3.9-1.75.35 2.1 3.85 1.65M155.2 23.4l-.15-.65L135 25.8q-.05.4-.05.7-.25 3.3.3 6.6.5 3.25 1.75 6.25l20.15-3.05-1.95-12.9m31.7-7.9v-.05l-1.4.25v.05l-24.05 3.65-2.75 2.8 2.05 13.6 28.55-4.4v-.05l-2.4-15.85m12.55 1.15l-4.2-2.45 2.45 15.9 3.3-3.55-1.55-9.9z"
                    id="ObjToo054__Symbol_157_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.2 59.2q-2.4-2.95-3.35-7.15L0 53.45q.95 4.2 3.35 7.05 2.9 3.45 6.25 2.9.4-.05.85-.2l7.35-1.05q-2.2.15-4.2-1.5-.7-.65-1.4-1.45m94.25-24.45q-.2-1.1-.2-2.05L64 39.1q0 1 .15 2.05 0 .25.05.55l.05.45v.35l.15.5q.05.3.15.6.7 3.65 2.7 6.15.05.15.2.25 2.1 2.6 4.45 2.6l40.85-6.2q-1.8-.1-3.4-2.75-.15-.15-.2-.25-1.65-2.55-2.3-6.2-.1-.3-.15-.65-.05-.2-.1-.5l-.05-.35q0-.2-.05-.45l-.05-.5m64.9-14.65l2.75-2.8-60.15 9.1v.05q-.45-.2-.95-.15-1.45.2-2.2 2.75 0 .15-.05.25l60.6-9.2M167 12.4L165.15 0 134.8 4.65l-.35-2.15-6.3 3.55.35 2.2-4.35-1.95q-.4 2.05.05 4.8.4 3 1.5 4.9.7 1.15 1.3 1.55.45.25.9.25 1.15-.1 1.65-2.4.15-.3.2-.55L136.7 17l30.3-4.6m43.8 18.85l-1.4-9.65-8.4 1.25.95 6.4v.05l.5 3.2 8.35-1.25m60.15-20.35l-.15-1.1q-.4-1.95-.85-3.7l-53.7 8.2v-.2q.05-1.05.1-1.85.05-.9.1-1.45 0-.1.05-.3.25-1.75.9-2.6l-5.3 6.65 1.55 9.9 7 4.9q.3.25.7.35.35.15.6.1l47.1-7.15q.5-.1 1-.6.7-.85 1.1-3-.05-.15 0-.3.15-.9.2-1.85.1-2.1-.2-4.65l-.1-.7-.1-.45v-.2z"
                    id="ObjToo054__Symbol_136_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M6.3 2.9L0 6.5.95 13l7.25 2.25L6.3 2.9m81.45 9.45q.05-1.05.1-1.85.05-.9.1-1.45 0-.1.05-.3.25-1.75.9-2.6l-5.3 6.65 1.55 9.9 7 4.9q-.85-.65-1.65-2.45-.1-.05-.1-.2-.5-.95-.9-2.05-.75-2.15-1.15-4.85l-.15-.85q0-.2-.1-.45l-.05-.5q-.25-1.95-.3-3.7v-.2m102.1-8.85q2.15-2.1 0-3.5l-28.3 4.25-.15-.05.75 3.5h.1l27.6-4.2z"
                    id="ObjToo054__Symbol_109_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.102}
                    d="M195.85 270.95q-.3.05-.5.1-.952.375-1.65 1.55-.462.86-.8 2.15-.1.15-.15.25-.65 2.35-.45 5.2l4.3-2.75q.1-.3.2-.65.05-.1.05-.25.75-2.55 2.2-2.75.5-.05.95.15v-.05q-.1-.1-.15-.2-.396-.619-.8-1.1-1.566-1.89-3.2-1.65M100.6 284.1q-.3.05-.55.1-2.2.6-3.55 2.7-.55.8-1 1.8-1.55 3.75-1 8.45 0 .25.05.6l.05.4q.05.35.15.75.1.3.15.65l4.05-1.95q-.05-.1-.05-.2v-.25q-.05-.35-.05-.65-.4-2.9.35-5.1v-.05q.95-2.55 2.75-2.8.55-.1 1.05.15l4.45-.8q-.35-.4-.8-.9-2.8-3.45-6.05-2.9m254.2-33.8q.7 1.5 1.2 3.3l20.4 1.65-.3-1.75-23.4-5.95q1 .6 2 2.6.05.05.1.15z"
                    id="ObjToo054__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo054__Symbol_213_0_Layer0_0_FILL"
                transform="translate(85.55 245.4)"
                id="ObjToo054__col1n"
            />
            <use
                xlinkHref="#ObjToo054__Symbol_157_0_Layer0_0_FILL"
                transform="translate(98.7 245.4)"
                id="ObjToo054__col1l"
            />
            <use
                xlinkHref="#ObjToo054__Symbol_136_0_Layer0_0_FILL"
                transform="translate(86.05 247.5)"
                id="ObjToo054__col1d"
            />
            <use
                xlinkHref="#ObjToo054__Symbol_109_0_Layer0_0_FILL"
                transform="translate(214.55 249.25)"
                id="ObjToo054__col1s"
            />
            <use xlinkHref="#ObjToo054__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo054
