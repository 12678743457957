import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M0 6.75q5.7-1.85 11.55-2.9 4.4-.8 8.85-1.15 17.1-1.35 35.05 3.95-27.9-13.3-55.45.1z"
                    id="SkiMor009__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor009__Symbol_13_0_Layer0_0_FILL"
                transform="translate(164.8 288.55)"
                id="SkiMor009__col1n"
            />
        </g></g>
    )
}

export default SkiMor009
