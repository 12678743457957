import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FFFFFF"
}

function HaiSpe002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M81.8 25.5q-.35.05-.7.15-.95.2-1.55.45.25-1.1.25-2.4 0-8.3-8-8.3-.15 0-.3.05v-.8q0-3.75-2.8-6.5Q65.95 5.5 62 5.5q-2.7 0-4.85 1.25-.65-2.2-2.45-4Q51.95 0 48 0t-6.7 2.75q-.9.9-1.5 1.9-.35-.45-.75-.85-2.75-2.75-6.65-2.75-2.1 0-3.85.8-1.55.7-2.8 1.95Q23 6.55 23 10.45q0 .4.05.85-.35-.05-.65-.05-3.8 0-6.5 2.5-2.65 2.55-2.65 6.15 0 3.6 2.65 6.1l.1.1q-2.4.6-4.3 2.3-2.95 2.7-2.95 6.45 0 2.25 1.05 4.1l-2.1.45Q2.5 40.75.95 43.95q-1.85 3.55 0 6.4l.4.4Q4.9 53.9 17.8 53.9q11.75 0 15.45-2.45 2-1.5 1.55-4.35-.05-.2-.05-.3-.15-1.2-.6-2.5-1.4-3.05-5.85-4.5l-.6-.2q.25-.45.5-1 .85-1.7.85-3.75 0-.7-.1-1.35-.45-2.9-2.9-5.1-.2-.2-.45-.35.45-.2.85-.35 1.3-.65 2.4-1.7 2.7-2.5 2.7-6.1v-.05h.85q3.9 0 6.65-2.75.85-.85 1.5-1.85.3.45.75.85 2.75 2.85 6.7 2.85 2.8 0 4.95-1.4.7 1.95 2.35 3.55 2.75 2.7 6.7 2.7.95 0 1.8-.15 0 .95.05 1.25.15 1.4.75 2.5 1.7 3.2 6.7 3.5-2.45 1.65-2.45 4.4 0 1.4.15 2 .25.85 1.15 2 .8.6 2.05 1.1 1.25.45 2.1.85.8.4 1.65.85.8.4 1.05.6.75.6 1.6 2.1.25.5.5 1.1.1.35.25.75.15.6.25 1.1.05.1.1.25.5 2.6.2 5.15 0 1.95 1.75 4.1 1.146 1.375 3.05 2.15 1.057.408 2.3.65 1.55.3 2.75.25 1.478.034 2.1-.9.325-.425.45-1.05.35-1.3.35-3.25t-.4-3.25q-.45-1.5-1.75-3.15-1.95-2.15-2.45-3.05-.1-.15-.15-.35-.75-.7-2-2.85-1.2-2.05-1.2-3.3 0-.75.4-1.65.4-.9.7-1.55.25-.65.4-1.6.15-1 .15-2.35v-.25q-.1-6.5-4.55-6.25z"
                    id="HaiSpe002__Symbol_5_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M61.4 18.25h-.55q-5.8 0-7.35-.5-2.5-.8-2.5-3.8 0-1.9.6-2.75.2-.35 2.05-1.95h-.15q-.35.3-2.15.75-2.1.5-4.1.5-3.6 0-5.35-1-1.55-.9-1.7-1.25Q40 7.9 40 5.7l.25-4.15q0 .1-.25.1-.5 1.4-2.45 2.9-2.3 1.7-4.6 1.7-3.8 0-6.1-2.05Q25.5 3 23.9 0v.15q.55 3-1.15 4.8Q21 6.7 18.8 7.2q-2.2.45-4.2 0-2.05-.45-3.6-2.05.15.25 1.85 2.65Q13.9 9.2 13.9 11q0 3.1-1.9 5-1.8 1.75-4.55 1.75-2.05 0-2.45-.25H2.25q3.4.45 5.75 2.85 2.3 2.4 1.8 5.7Q9.25 29.3 6.15 30 2 30.25 0 30.4q5.35.05 9.8 2.05 4.45 1.95 5.05 4.45.6 2.55-.2 4.2l-1.9 2.65q4.15-.9 5.5-1.8 2-1.5 1.55-4.35-.05-.2-.05-.3-.15-1.2-.6-2.5-1.4-3.05-5.85-4.5l-.6-.2q.25-.45.5-1 .85-1.7.85-3.75 0-.7-.1-1.35-.45-2.9-2.9-5.1-.2-.2-.45-.35.45-.2.85-.35 1.3-.65 2.4-1.7 2.7-2.5 2.7-6.1v-.05h.85q3.9 0 6.65-2.75.85-.85 1.5-1.85.3.45.75.85 2.75 2.85 6.7 2.85 2.8 0 4.95-1.4.7 1.95 2.35 3.55 2.75 2.7 6.7 2.7.95 0 1.8-.15 0 .95.05 1.25.15 1.4.75 2.5 1.7 3.2 6.7 3.5-2.45 1.65-2.45 4.4 0 1.4.15 2 .25.85 1.15 2 .8.6 2.05 1.1 1.25.45 2.1.85.8.4 1.65.85.8.4 1.05.6.75.6 1.6 2.1.25.5.5 1.1.1.35.25.75.15.6.25 1.1.05.1.1.25.5 2.6.2 5.15 0 1.95 1.75 4.1 1.15 1.4 3.1 2.15 1 .4 2.25.65 1.55.3 2.75.25 1.2.05 1.9-.55-3.3-.7-5.05-2.15-1.8-1.45-3.2-5.95-1.9-5.95-1.5-14.5-3.95-.05-6.05-.35Q57 26.8 57 24.55q0-2.1 1.1-3.6 1-1.35 3.3-2.7z"
                    id="HaiSpe002__Symbol_9_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M34.2 9.6q1.6-2.9.4-6.4-1.5-4.65-7.15-2.55-.65.2-1.25.5-.5.25-1.05.55-1.7.95-2.95 2l-.5.5q-.15-.1-.2-.15-1.95-1.3-4.55-1.3-2.95 0-5.05 1.65l-.1.1q-1.05.9-1.5 2-.25.4-.35 1.45v.45H9.9q-3.85-2.65-7.25 0-.55.4-1 .9Q0 11.2 0 14.25q0 1.2.75 2.95.65 1.55 1.3 2.2.2.2.5.4 4.9 3.15 8.85 0 1.85-1.65 2.35-3 .5-1.4.8-2 .3-.6.4-.85.2 0 .4.05.75.15 1.6.15 2.95 0 5-1.7.2-.15.35-.25.1-.15.25-.25l.3-.3q6.1 1.35 9.6-.6 1.2-.85 1.75-1.45m-3.1-5.1q.75.6.75 1.55 0 1.55-1.75 2.25-2.1.45-3.05.55-1 .1-2.5-.2l-.5-.1v-.1q0-.65-.15-1.25-.15-.45-.3-.8v-.05l.2-.1q2.85-2.35 5.45-2.35 1.1 0 1.85.6m-20.8 6.35q.25.4 1.3 1.35.15.15.3.25.2.15.45.35-.8.85-1.65 1.85-.85.95-2.1 1.7-1.3.7-2.15.7-1.05 0-1.95-.8-.95-.9-.95-2 0-.35.1-.65Q5 9.05 10.3 10.85M3.95 12.3q-.05.05-.35-.05l.25.05h.1z"
                    id="HaiSpe002__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe002__Symbol_5_0_Layer0_0_FILL"
                transform="translate(174.6 12.6)"
                id="HaiSpe002__col1n"
            />
            <g id="HaiSpe002__col1d" transform="translate(189.6 22.1)">
                <use
                    xlinkHref="#HaiSpe002__Symbol_9_0_Layer0_0_FILL"
                    id="HaiSpe002__col1d_FL"
                />
            </g>
            <g id="HaiSpe002__col2n" transform="translate(239.35 46.6)">
                <use
                    xlinkHref="#HaiSpe002__Symbol_6_0_Layer0_0_FILL"
                    id="HaiSpe002__col2n_FL"
                />
            </g>
        </g></g>
    )
}

export default HaiSpe002
