import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#621F00"
}

function AccMor013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M131.3 7.05q-2.1-3.3-5.2-7.05-9.55 13.95-23.6 25.95-1.45 1.25-2.95 2.45-8.25 6.7-17.95 12.7l-4.25 2.55q-2.4 1.4-4.85 2.75-.15.05-.25.15L70.3 47.6q-3.35 1.75-6.7 3.35-.9.4-1.75.8-13.15 6-27.3 9.65-.3.1-.6.15-15.2 3.7-31.45 5L0 75.8h.9q2.9-.1 5.35-.45 59.8-5 105.65-46.9 2.45-2.3 4.95-4.7l3.75-3.8q5.8-6.25 10.7-12.9z"
                    id="AccMor013__Symbol_111_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor013__Symbol_111_0_Layer0_0_FILL"
                transform="translate(126.4 228.4)"
                id="AccMor013__col1n"
            />
        </g></g>
    )
}

export default AccMor013
