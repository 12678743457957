import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#77BFFF",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function ShiSho036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.05 12.2l-.3-.5v-.05l-.1-.15q-.95-1.75-1.95-3.4-.1-.1-.15-.2l-.2-.3q-.5-.8-.95-1.6l-.2-.3q-.15-.15-.25-.3-.4-.65-.8-1.25-.2-.3-.4-.55Q136.7 2 135.6.5l-.4-.5-2.1 1.65q-.2.1-.35.25-1.05.8-2.2 1.6L129 4.65q-.15.1-.25.2-.15.05-.25.15l-2.7 1.8q-.25.15-.55.35-2.05 1.25-4.15 2.45l-3.3 1.7q-.45.2-.85.45-.2.1-.45.2l-.2.1q-.15.05-.25.1-.15.05-.3.15-.2.05-.35.15l-.2.1q-.15.05-.3.15-.1 0-.2.1-.15.05-.25.1l-.6.3q-.2.05-.3.1-.25.1-.4.2-.2.05-.4.15-.2.1-.4.15l-.4.2q-.2.05-.4.15-.2.05-.4.15-.2.1-.35.15-.2.1-.4.15-.2.05-.35.15-.2.05-.35.1-.2.05-.35.15-.35.15-.7.25-.4.15-.75.25l-.3.1q-7.05 2.65-19.2 4.55-.3 0-.55.05-.25.05-.45.05l-3.15.35q-.3 0-.6.05l-1.6.05-1.4.1-3.15.1H73q-2.05-.05-4.1-.15-.3-.05-.65-.05l-1.1-.05q-.95-.05-1.85-.15-1.95-.2-3.8-.4-.95-.15-1.85-.25-21-3.2-38.35-15.2L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.3.5-.6 1.05-.55.9-1.05 1.85-2 3.65-3.8 7.5-1.4 3-2.65 6.15-.4 1.05-.8 2.15-.2.45-.35.95l18.95 7.05v.05l-2.7 14q-.55 2.7-1.1 5.45L11.7 69.2q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 26.5 6 52.85 6.85h.85q.35 0 .75.05h.05q2.4 0 4.8.05v-.05h4.2v.05h1.95q1.4-.05 2.85-.05.4-.05.8-.05 1-.05 2-.05 3-.15 6.05-.3 1.4-.1 2.75-.2.3-.05.6-.05l5.65-.45h.25l1.8-.2q.75-.05 1.45-.15 1.75-.2 3.55-.4 2-.25 4.05-.5 2.4-.3 4.8-.65.9-.15 1.85-.25l3.95-.65q3.45-.6 6.85-1.25l.95-.2q.6-.15 1.2-.25l.45-.1 1.5-.3q1.4-.35 2.8-.65.55-.1 1.1-.25 2.1-.45 4.15-.95h.15q.25-.05.5-.15h.2L134.7 53q-.25-1.05-.45-2.05-.05-.4-.15-.75l-2.85-14.75 19.1-7.1-.15-.45q-.1-.1-.1-.25-.05-.15-.1-.25l-.65-1.6-.8-2-.3-.8-.2-.5-1.1-2.5q-.25-.45-.45-.9-.75-1.65-1.55-3.25l-1.9-3.65m-6.85 49.2q0 .049.05.05h-.05q-.2.05-.4.05l.4-.1z"
                    id="ShiSho036__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M43.65 24.7q-.15-.25-.35-.5-.15-.25-.35-.45-.05-.05-.05-.1-.15-.15-.25-.3-.15-.2-.25-.35-.1-.15-.2-.2-.15-.2-.3-.3-2.65-2.3-8.1-.5-3.55 1.2-7.2 3.8-.95.65-1.75 1.15-.05-.15-.05-.25-.1-.55-.05.05-.2-1.45-.2-1.55 0-2.35.65-3.95l.1-.2-.2.3.25-.65-.05.05q.15-.6.25-1.1.2-1.15.25-1.65v-.2-.4q-.05-1.35-.25-1.75l-.1-.3q-.05-.25-.05-.45-.55-.95-.75-1.25-.2-.25-.35-.4l-.3-.4q.3-.7.7-1.7.1-.25.2-.55 1.35-3.5 1.35-4.05Q26.35 6 26 6q-.7 0-2.5 1.05l-.1.1q-.9.35-2.2 1.1-.65.4-1.1.7-.65.4-1 .7-.05 0-.05.05v.05l-.1.05-.1.1q-.9-.2-1.9-.2-.45 0-.9.05h-.1q0-.05.05-.1L10.65.45Q10-.5 9.85.5v.25l1.1 9.05Q10 9 9.85 8.9q-.35-.3-1.4-1.3-.35-.3-.5-.4-.2-.05-.25 0v.05q-.05.05-.05.15v.05q-.55 4.2.1 6.1.2.6.6 1l.05.05q-.6 1.45-.55 2.8-3.4 1.05-5.55 2.9Q-.15 22 0 25.2q-.25 3.15 2.5 4.4 1.5.45 3.7.45 3.35 0 6.1-.45.2-.05.45-.05-.5.75-.8 1.55-.35.85-.5 1.8-.15.65-.15 1.35l-.1.1q-.15.05-.25.1-3.3 1.85-4.7 3.95-.95 1.45-1.75 3.8-.6 1.85-.75 3.3.1 1.4.65 2.15.85 1.15 2.8 1.15 1.85 0 2.15-1 .3-1 .4-1.3.05-.3.6-1.3.55-1 1.05-1.7.2-.15.45-.25.45-.2.85-.35.1-.05.25-.1-.1.4-.2.9-.25 1.5-.25 3.05v.05q0 .1.05.5.05 1.05.25 1.9.2.8 1.05 1.85.4.45 1.6 1.1 1.45.7 2.45.7 1.25 0 2.5-.85.55-.4.9-.75.4-.55.4-1.15 0-1.3-1.25-2-1.3-.75-1.4-1.2-.1-.45.05-1.5l.6-1.35q3.85.75 8.4-.2.4-.1.8-.15.05-.05.2-.05-1.1 2.5.2 5.65.8 1.75 3 1.75 1.95 0 3-1.6.7-1.05.7-1.9 0-.85-.65-1.35-.65-.45-.65-1.05 0-2 2.35-5.1.2-.25.4-.45.15-.15.3-.25v-.05q.1-.05.15-.1 0 2.4 1.95 4.7 1.35 1.55 3.15 2.25 1.35.5 3 .5 2.3 0 2.3-3.35v-.45q-.3-1.9-2.8-2.3-2.8-.45-2.8-2.4 0-.7.9-3.1.2-.55 1-1.9.8-1.4.6-3.9 0-2-1.15-3.9-.2-.35-.4-.65z"
                    id="ShiSho036__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M26.9 42.9q-1.1 2.5.2 5.65.8 1.75 3 1.75 1.95 0 3-1.6.7-1.05.7-1.9 0-.85-.65-1.35-.65-.45-.65-1.05 0-2 2.35-5.1.2-.25.4-.45.15-.15.3-.25-.15 0-.25.05-.1.1-.25.2-2.8 2.4-6.9 3.7-.65.15-1.25.35m-9.45.4q-.85-.15-1.65-.4-1.95-.6-3.15-1.5-.15-.1-.25-.2-.4.2-1 .45-.45.15-.65.4-.1.4-.2.9-.25 1.5-.25 3.05v.05q0 .1.05.5.05 1.05.25 1.9.2.8 1.05 1.85.4.45 1.6 1.1 1.45.7 2.45.7 1.25 0 2.5-.85.55-.4.9-.75.4-.55.4-1.15 0-1.3-1.25-2-1.3-.75-1.4-1.2-.1-.45.05-1.5l.6-1.35h-.05M13.55 27q-1.35 1.45-3 1.8-.5.75-.8 1.55 4.25-1.3 3.8-3.35M11 33.15q1.45 0 2.35.4h.05q-.95-1.45-3.75-1.45-.1 0-.4.05-.15.65-.15 1.35l-.1.1q1.4-.45 2-.45M3.7 24.2q.2-.25.2-.6t-.2-.6q-.2-.25-.45-.25T2.8 23q-.2.25-.2.6t.2.6q.2.25.45.25t.45-.25M.2 21.95q-.2.25-.2.6t.2.6q.2.25.45.25t.45-.25q.2-.25.2-.6t-.2-.6q-.2-.25-.45-.25t-.45.25M5.55 7.4l-.1-.7q-.55 4.2.1 6.1.2.6.6 1l.05.05h.05q.1-.35.3-.65.1-.25.2-.45v-.05q.15-.25.35-.5-.2-.15-.65-1.1-.15-.35-.35-.85-.1-.3-.25-.7 0-.05-.25-1.9-.05-.15-.05-.25m4.95-2.35q-.2-.15-.25-.15-.3 0-.65.25-.35.25-.6.25-.15 0-.25-.25-.1-.2-.1-.3 0-.35.4-.7.45-.4.7-.25l.05-.45q0-.1-.15-.25-.15-.1-.25-.1-.25 0-.5.15l-.35-.05v-.35q.1-.25.45-.3.3-.1.3-.5 0-.25-.45-.25-.45-.05-.45-.35t.25-.5q0-.25-.5-.6-.4-.3-.5-.35l1.1 9.05q.2 1.25.45 1.75.5.85 1.65.85.5 0 1.35-.4.95-.4.95-.85 0-.25-.25-.25l-1.8.65q-.9 0-1.3-.4v-.25q.85 0 1.8-.5 1.1-.55.85-1.2-.5.2-.85.55-.2.2-.7.2-.5 0-.85-.25-.35-.2-.35-.55 0-.5.4-.5l.35.1q1.25-.1 1.6-.7v-.25q-.3-.3-.35-.3-.4 0-1.75.45-.25 0-.5-.2t-.25-.5q0-.8 1.05-.8.1 0 .3-.15.2-.15.2-.25 0-.05-.25-.25M22.55 7.7l.05-.05-.05-.4q-.4.65-1.55 1.4-1.15.7-1.85.9-.2.1-.4.25-2.5 1.6-2.5 3.25 0 1.3 1.1 1.45.55.1 1.6-.5 1-.6 1.4-1.4.1-.25.15-.4.4-.6.85-1.5 1.2-2.35 1.25-2.85-.05-.05-.05-.15z"
                    id="ShiSho036__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M.55 3q-.1-.3-.25-.7Q.3 2.25.05.4 0 .95.1 2.35T.35 4.5q0 .1.05.25-.15.5-.4.8.2.6.6 1l.05.05H.7q.1-.35.3-.65.1-.25.2-.45v-.05q.15-.25.35-.5-.2-.15-.65-1.1Q.75 3.5.55 3M17 .45l.05-.05L17 0q-.4.65-1.55 1.4-1.15.7-1.85.9-.2.1-.4.25-2.5 1.6-2.5 3.25 0 1.3 1.1 1.45.55.1 1.6-.5 1-.6 1.4-1.4-.6.2-.65.25-.1.05-.75.15-.65.1-.65-1t1.85-2.4q1.8-1.3 2.4-1.9z"
                    id="ShiSho036__Symbol_104_0_Layer0_0_FILL"
                />
                <g id="ShiSho036__Layer3_0_FILL">
                    <path d="M178.55 267.15q-.75 0-1.3.7-.5.7-.5 1.65t.5 1.65q.55.7 1.3.7t1.25-.7q.55-.7.55-1.65t-.55-1.65q-.5-.7-1.25-.7m0 1.15q.2.25.2.55 0 .3-.2.5-.2.25-.45.25t-.45-.25q-.2-.2-.2-.5t.2-.55q.2-.2.45-.2t.45.2m-2.5-.3q0-.85-.5-1.5-.45-.6-1.1-.6-.7 0-1.2.6-.45.65-.45 1.5t.45 1.5q.5.6 1.2.6.65 0 1.1-.6.5-.65.5-1.5m-1.6-1.1q.15.25.15.5 0 .3-.15.45-.2.25-.4.25-.25 0-.4-.25-.2-.15-.2-.45 0-.25.2-.5.15-.15.4-.15.2 0 .4.15z" />
                    <path
                        fill="#FFF"
                        d="M178.75 268.85q0-.3-.2-.55-.2-.2-.45-.2t-.45.2q-.2.25-.2.55 0 .3.2.5.2.25.45.25t.45-.25q.2-.2.2-.5m-4.15-1.45q0-.25-.15-.5-.2-.15-.4-.15-.25 0-.4.15-.2.25-.2.5 0 .3.2.45.15.25.4.25.2 0 .4-.25.15-.15.15-.45z"
                    />
                    <path
                        fill="red"
                        d="M182.7 250.9q-.042-.92-.05-1.8-1.929-.18-3.85-.45.05.596.05 1.2v1.05q0 3.4-.45 6.8l2.1 3.6q2.3-4.65 2.2-10.4m68.15 28.75q-.083.043-.2.05-.548.142-1.1.3-.35.05-.7.15-9.55 2.3-18.05 5.65-6.05 2.3-11.6 5.15-3.3 1.65-6.4 3.55v.45q0 3.35-2.3 3.35-1.65 0-3-.5-12.35 8.3-21.85 19.6l-.15.1q.067-.004.1 0 2.4 0 4.8.05v-.05h1.2q12.07-14.362 29.35-23.3 5.85-3.05 12.25-5.45 7.75-2.95 16.45-4.95.499-.143 1-.25.235-.021.45-.05.3-.1.6-.15l.4-.1q.141-.02.25-.05l-.3-1.6q-.233-1.04-.45-2.1l-.6.15q-.074.015-.15 0m.3.2q-.037.035-.1.05l.1-.05m3.55-47.45q.204-.117.4-.25-1.062-1.544-2.2-3.05-.164-.253-.35-.5l-2.15 1.6q-.14.146-.3.25-1.07.831-2.2 1.65l-1.55 1.15q-.13.09-.25.15-.129.113-.25.2-1.34.912-2.7 1.75-.274.198-.55.35-2.065 1.304-4.2 2.45-2.077 1.515-4.05 3.1-3 2.35-5.75 4.85-13.85 12.35-22.2 28.05.15.1.3.3 10.7-16.5 29.35-30.15 8.5-6.25 18.65-11.9z"
                    />
                    <path
                        fill="#FF9A00"
                        d="M184.5 249.25q-.927-.07-1.85-.15.008.88.05 1.8.1 5.75-2.2 10.4-.05.05-.05.1h.1q.45-.05.9-.05 1 0 1.9.2l.1-.1.05-.1q1.17-2.004 1.9-4.05.7-2.1.9-4.3.05-1.05 0-2.1-.042-.8-.05-1.6-.325.02-.65 0l-1.1-.05m67.85 33.95q-.109.03-.25.05l-.4.1q-.3.05-.6.15-.215.029-.45.05-.501.107-1 .25-8.7 2-16.45 4.95-6.4 2.4-12.25 5.45-17.28 8.938-29.35 23.3H194.6v.05q.962-.017 1.9-.05 11.183-12.044 26.3-19.95 6.15-3.25 12.9-5.75 7-2.6 14.7-4.5.55-.15 1.1-.25.151-.038.3-.1.27-.038.55-.1.25-.05.5-.05.118-.031.2-.05l-.15-.9v-.05l-.55-2.6m4.35-48.6q-.074-.154-.2-.3-.083-.168-.2-.35-.4-.603-.85-1.2-.155-.296-.35-.6-.196.133-.4.25-10.15 5.65-18.65 11.9-18.65 13.65-29.35 30.15.1.05.2.2 12.75-14.8 31.05-26.95 9.151-6.1 19.75-11.55-.48-.772-1-1.55z"
                    />
                    <path
                        fill="#FF0"
                        d="M190.35 250.9v-1.45q-2.068-.005-4.1-.15.008.8.05 1.6.05 1.05 0 2.1-.2 2.2-.9 4.3-.73 2.046-1.9 4.05l.1-.05q.35-.3 1-.7.45-.3 1.1-.7 1.3-.75 2.2-1.1l.1-.1q1.6-2.75 2.1-5.5.2-1.3.25-2.3m62.7 35.85q-.082.019-.2.05-.25 0-.5.05-.28.062-.55.1-.149.062-.3.1-.55.1-1.1.25-7.7 1.9-14.7 4.5-6.75 2.5-12.9 5.75-15.117 7.906-26.3 19.95 1.462-.006 2.9-.05.4.007.8 0 .988-.035 1.95-.1 9.814-9.711 22.45-16.5 6.4-3.45 13.5-6.15 6.25-2.35 13-4.05 1-.3 2.05-.55l.4-.1q.01.027 0 .05.19-.029.35-.05l-.85-3.25m7.05-46.45q-.012-.026-.05-.05l-.05-.15q-.963-1.732-2-3.4-.038-.1-.1-.2-.097-.162-.2-.35-10.599 5.45-19.75 11.55-18.3 12.15-31.05 26.95.1.15.25.35 16.15-13.35 32.85-23.6 9.909-6.132 20.1-11.1z"
                    />
                    <path
                        fill="#10E200"
                        d="M194.1 250.9v-1.4h-2.2q-.778-.004-1.55-.05v1.45q-.05 1-.25 2.3-.5 2.75-2.1 5.5 1.8-1.05 2.5-1.05.35 0 .25.55 0 .55-1.35 4.05-.1.3-.2.55 2.9-3.2 4-6.85.8-2.7.9-5.05m59.8 39.1q-.16.021-.35.05-.2.05-.4.05-1.05.25-2.05.55-6.75 1.7-13 4.05-7.1 2.7-13.5 6.15-12.636 6.789-22.45 16.5 3.064-.099 6.1-.3 8.205-7.334 18.25-12.75 6.65-3.65 14.2-6.45 5.4-2 11.2-3.55.802-.236 1.65-.45.05.011.1 0 .2-.1.45-.15.15 0 .3-.05.197-.052.4-.1l-.9-3.55m8.4-45.55l-1.95-3.65q-.108-.246-.25-.5-10.191 4.968-20.1 11.1-16.7 10.25-32.85 23.6.1.15.25.3 0 .05.05.1 18.05-11.15 34.6-20.25 10.432-5.752 20.25-10.7z"
                    />
                    <path
                        fill="#0FF"
                        d="M197.9 250.9q.042-.763.05-1.55l-3.15.1q-.333.044-.7.05v1.4q-.1 2.35-.9 5.05-1.1 3.65-4 6.85-.4 1-.7 1.7l.3.4q.15.15.35.4.2.3.75 1.25.05.3 2.15-2.15 2.05-2.5 3.95-6.9 1.45-3.4 1.9-6.6m56.9 42.65q-.203.048-.4.1-.15.05-.3.05-.25.05-.45.15-.05.011-.1 0-.848.214-1.65.45-5.8 1.55-11.2 3.55-7.55 2.8-14.2 6.45-10.045 5.416-18.25 12.75 1.376-.058 2.75-.15.3-.029.6-.05l4.15-.35q6.011-4.565 12.9-8.3 7.05-3.85 15.05-6.7 4.45-1.65 9.15-2.95l1.6-.4.35-.1.35-.1q.1-.05.2-.05.26-.044.5-.1l-1.05-4.25m9.45-44.95q-.19-.447-.4-.9-.756-1.646-1.55-3.25-9.818 4.948-20.25 10.7-16.55 9.1-34.6 20.25.2.2.35.45 16.6-7.9 36.1-17.35 9.802-4.78 20.35-9.9z"
                    />
                    <path
                        fill="#C833FF"
                        d="M201.55 249.15q-.007.9-.05 1.75-.6 4.85-2.8 9.3-2.7 5.3-8.9 12.5l-.1.2q-.65 1.6-.65 3.95 0 .1.2 1.55-.05-.6.05-.05 9.75-10.35 12.65-17.65 2.2-5.6 3-9.8.13-1.087.2-2.1-.198.028-.45.05l-3.15.3m29.05 62.6q-3.294 1.862-6.35 3.9 2.068-.233 4.1-.5 2.38-.3 4.75-.65 7.188-4.139 15.4-7.2 2.85-1.1 5.8-2.05l2-.6q.25-.05.55-.15l-.05-.2h.1l.1.35q.246-.067.55-.1l-.85-3.45q-.27.083-.55.15H256l-1.1.3-1.3.4q-3.8 1.15-7.4 2.5-8.35 3.1-15.6 7.3M267.3 256l-.6-1.65q-.392-.963-.8-1.95-10.6 4.859-20.35 9.1-20.7 8.95-37.4 14.85.2.3.4.65 17.183-5.101 33.6-10.6l25.5-9.45-.15-.45q-.03-.122-.1-.25-.023-.126-.1-.25z"
                    />
                    <path
                        fill="#0041FF"
                        d="M201.5 250.9q.043-.85.05-1.75-.274.044-.6.05l-1.55.1q-.705.042-1.45.05-.008.787-.05 1.55-.45 3.2-1.9 6.6-1.9 4.4-3.95 6.9-2.1 2.45-2.15 2.15 0 .2.05.45l.1.3q.2.4.25 1.75V269.65q-.05.5-.25 1.65-.1.5-.25 1.1l.05-.05-.25.65.2-.3q6.2-7.2 8.9-12.5 2.2-4.45 2.8-9.3m22.75 64.75q3.056-2.038 6.35-3.9 7.25-4.2 15.6-7.3 3.6-1.35 7.4-2.5l1.3-.4 1.1-.3h.15q.28-.067.55-.15l-.85-3.3q-.24.056-.5.1-.1 0-.2.05l-.35.1-.35.1-1.6.4q-4.7 1.3-9.15 2.95-8 2.85-15.05 6.7-6.889 3.735-12.9 8.3l1.5-.1h.25l1.8-.2q.75-.05 1.45-.15 1.768-.186 3.5-.4m41.1-64.55q-.519-1.262-1.1-2.5-10.548 5.12-20.35 9.9-19.5 9.45-36.1 17.35.2.25.35.5 16.7-5.9 37.4-14.85 9.75-4.241 20.35-9.1-.166-.408-.35-.85l-.2-.45z"
                    />
                    <path
                        fill="#4FB6FF"
                        d="M251.05 279.9q.063-.015.1-.05l-.1.05z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSho036__Symbol_41_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho036__col1n"
            />
            <use
                xlinkHref="#ShiSho036__Symbol_89_0_Layer0_0_FILL"
                transform="translate(164.5 251.65)"
                id="ShiSho036__col2n"
            />
            <use
                xlinkHref="#ShiSho036__Symbol_98_0_Layer0_0_FILL"
                transform="translate(166.7 252.4)"
                id="ShiSho036__col2d"
            />
            <use
                xlinkHref="#ShiSho036__Symbol_104_0_Layer0_0_FILL"
                transform="translate(172.25 259.65)"
                id="ShiSho036__col2s"
            />
            <use xlinkHref="#ShiSho036__Layer3_0_FILL" />
        </g></g>
    )
}

export default ShiSho036
