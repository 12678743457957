import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000"
}

function LegPan021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.8 1.2V.15l-.3.05Q61.8 12.55 0 0v1.5l63 12.25L125.8 1.2z"
                    id="LegPan021__Symbol_49_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan021__Symbol_49_0_Layer0_0_FILL"
                transform="translate(129.6 310.3)"
                id="LegPan021__col1n"
            />
        </g></g>
    )
}

export default LegPan021
