import { AssetSvgProps } from "../../../shared/assets"

function ShiMot048({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="red"
                    d="M159.2 257.85v35.75h66.5v-35.75h-66.5z"
                    id="ShiMot048__Layer5_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M173.8 257.85v35.75h36.85v-35.75H173.8m15.05 8.05q1.1.5 1.35-.45l2.2-4.3 2.15 4.3q.3.95 1.4.45l1.8-1-1.55 7.6q-.1 1.05.9.55l3.25-3.85.65 2.25 4.25-.9-1.3 5 1.7.85-6.7 5.6.75 2.75-6.85-.8.2 7.5h-1.3l.2-7.5-6.85.8.75-2.75-6.7-5.6 1.7-.85-1.3-5 4.25.9.65-2.25 3.25 3.85q.95.5.9-.55l-1.55-7.6 1.8 1z"
                    id="ShiMot048__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot048__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot048__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot048
