import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#77BFFF",
    "col1n": "#43A6FF",
    "col2d": "#830000",
    "col2n": "#C40000"
}

function ShiSho075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.8 31.95l7.85-27.75q-1.35-1.95-3-3.95l-.2-.25-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15-3.1 2.1L110 31.5l-.3.7q-7.45-.25-14.85-.4-20.65-.45-41.25-.25-7.8.1-15.6.25l-8.45.2-6.4.2-.3-.7-7.1-24.7q-1.6-1.05-3.2-2.15L11 3.5Q9.55 2.45 8.2 1.4L6.45.15 6.4.1Q4.55 2.25 3.2 4.2l7.75 27.4L7.5 49.45q-.55 2.7-1.1 5.45L2.95 69.2Q1.95 73.1 1 77l-.95 3.6v.25L0 80.9h.2q.25.1.5.15H.85q2.05.5 4.15.95 6.85 1.55 13.7 2.75 49.25 8.7 98-.45 5.5-1.05 11-2.3 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25-4.85-24.4z"
                    id="ShiSho075__Symbol_211_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M73.6 23.2V0H0v23.2l36.8 8.3 36.8-8.3m-1-22v3.5H1.1q-.05 0-.1.05V1.2h71.6m0 4.7v16.3l-35.8 8.1L1 22.2V5.9h71.6z"
                    id="ShiSho075__Symbol_212_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 5.45q0 2 1.3 3.55l.3.3q1.6 1.6 3.85 1.6 2.2 0 3.8-1.6 1.6-1.65 1.6-3.85 0-2.25-1.6-3.9Q7.65 0 5.45 0 3.2 0 1.6 1.55q-.15.15-.3.35Q0 3.4 0 5.45m100.95 0q0-2.25-1.6-3.9Q97.7 0 95.5 0q-2.25 0-3.85 1.55-1.55 1.65-1.55 3.9 0 2.2 1.55 3.85 1.6 1.6 3.85 1.6 2.2 0 3.85-1.6 1.6-1.65 1.6-3.85z"
                    id="ShiSho075__Symbol_214_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.05 5.5q.45 0 .75-.35.3-.3.3-.7 0-.45-.3-.75t-.75-.3q-.4 0-.75.3-.3.3-.3.75 0 .4.3.7.35.35.75.35m.75-3.75q.3-.3.3-.7Q2.1.6 1.8.3T1.05 0Q.65 0 .3.3q-.3.3-.3.75 0 .4.3.7.35.35.75.35.45 0 .75-.35m3.65-.7q0-.45-.3-.75T4.4 0Q4 0 3.65.3q-.3.3-.3.75 0 .4.3.7.35.35.75.35.45 0 .75-.35.3-.3.3-.7m-.3 4.1q.3-.3.3-.7 0-.45-.3-.75t-.75-.3q-.4 0-.75.3-.3.3-.3.75 0 .4.3.7.35.35.75.35.45 0 .75-.35m89.3.35q.45 0 .75-.35.3-.3.3-.7 0-.45-.3-.75t-.75-.3q-.4 0-.75.3-.3.3-.3.75 0 .4.3.7.35.35.75.35m-2.3-1.05q0-.45-.3-.75t-.75-.3q-.4 0-.75.3-.3.3-.3.75 0 .4.3.7.35.35.75.35.45 0 .75-.35.3-.3.3-.7m0-3.4q0-.45-.3-.75T91.1 0q-.4 0-.75.3-.3.3-.3.75 0 .4.3.7.35.35.75.35.45 0 .75-.35.3-.3.3-.7m1.55.7q.35.35.75.35.45 0 .75-.35.3-.3.3-.7 0-.45-.3-.75t-.75-.3q-.4 0-.75.3-.3.3-.3.75 0 .4.3.7z"
                    id="ShiSho075__Symbol_213_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho075__Symbol_211_0_Layer0_0_FILL"
                transform="translate(126.1 228.6)"
                id="ShiSho075__col1n"
            />
            <use
                xlinkHref="#ShiSho075__Symbol_212_0_Layer0_0_FILL"
                transform="translate(155.7 280.15)"
                id="ShiSho075__col1l"
            />
            <use
                xlinkHref="#ShiSho075__Symbol_214_0_Layer0_0_FILL"
                transform="translate(142.05 264.1)"
                id="ShiSho075__col2n"
            />
            <use
                xlinkHref="#ShiSho075__Symbol_213_0_Layer0_0_FILL"
                transform="translate(144.75 267)"
                id="ShiSho075__col2d"
            />
        </g></g>
    )
}

export default ShiSho075
