import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacEye026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.95 3.4Q11.55 0 8.1 0 4.75 0 2.35 3.4 0 6.75 0 11.55q0 4.75 2.35 8.1 2.4 3.4 5.75 3.4 3.45 0 5.85-3.4 2.4-3.35 2.4-8.1 0-4.8-2.4-8.15m30.65 8.15q0 4.75 2.3 8.1 2.3 3.4 5.55 3.4 3.35 0 5.65-3.4 2.35-3.35 2.35-8.1 0-4.8-2.35-8.15Q55.8 0 52.45 0 49.2 0 46.9 3.4q-2.3 3.35-2.3 8.15z"
                    id="FacEye026__Symbol_70_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye026__Symbol_70_0_Layer0_0_FILL"
                transform="translate(162.3 149.7)"
                id="FacEye026__col1n"
            />
        </g></g>
    )
}

export default FacEye026
