import { AssetSvgProps } from "../../../shared/assets"

function PlaFor015({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="PlaFor015__Layer5_0_FILL">
                    <path
                        fill="#FF0"
                        d="M264.1 357.25q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55m-160.75 8.1q-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7m-94.75-59q-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7m76.85-73.85q1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7m45.4-161.25q1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55m128.1 70.85q-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55M378.4 256.5q0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7m-8.95-192.65q-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55M101.9-3.25q-2.15 0-3.7 1.55Q96.65-.15 96.65 2q0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55z"
                    />
                    <path
                        fill="#3CF"
                        d="M184.65 348.75q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55M306.95 239q1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7M56.6 268.7q1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55m22.95-138.9Q78 131.35 78 133.5q0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55M25.9 76.25q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55m123.5 15q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55m30.75-68q2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55m188.7 85.55q-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7M332.9 12.5q0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7z"
                    />
                    <path
                        fill="#6901A9"
                        d="M221.9 292.75q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 1.45 0 2.6-.7.6-.35 1.1-.85 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-.5-.5-1.1-.85-1.15-.7-2.6-.7m-186.7 52q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55M137.9 204q0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7m-124.2-8.25q2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55M44.9 25.2q1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55m159.8 18.85q-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55m74.45-30.95q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55m45.7 296.1q1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55m2.7-156q1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55z"
                    />
                    <path
                        fill="#0C0"
                        d="M165.95 284.7q1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7M34.45 247q0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7m16.5-143.2q0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7M16.2 28.85q0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7m79.75 8.35q1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7m160.7 3.05q2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55m80.3 322q1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7m21-181.05q1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7m-4.6-136.9q-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7z"
                    />
                    <path
                        fill="red"
                        d="M249.85 245.8q-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7M76.3 305.7q1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7m-3.55-150.95q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55Q78 162.15 78 160q0-2.15-1.55-3.7-1.55-1.55-3.7-1.55m-1.3-95.3Q73 61 75.15 61q2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7m211.5 33.35q-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55m85.9 235.5q-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7m-33.2-119.05q-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55 1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55m34.7-182.55q1.55-1.55 1.55-3.7 0-2.15-1.55-3.7-1.55-1.55-3.7-1.55-2.15 0-3.7 1.55-1.55 1.55-1.55 3.7 0 2.15 1.55 3.7 1.55 1.55 3.7 1.55 2.15 0 3.7-1.55z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaFor015__Layer5_0_FILL" />
        </g></g>
    )
}

export default PlaFor015
