import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccMor033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2.5 17.2L23 10.45Q21.45 4.7 19.1 0L0 6.4l2.5 10.8z"
                    id="AccMor033__Symbol_131_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor033__Symbol_131_0_Layer0_0_FILL"
                transform="translate(246.9 251.9)"
                id="AccMor033__col1n"
            />
        </g></g>
    )
}

export default AccMor033
