import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2.55 1.25Q2.55.7 2.2.4 1.85 0 1.3 0 .8 0 .4.4q-.4.3-.4.85t.4.9q.4.4.9.4.55 0 .9-.4.35-.35.35-.9z"
                    id="SkiMor014__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor014__Symbol_26_0_Layer0_0_FILL"
                transform="translate(126.75 192.25)"
                id="SkiMor014__col1n"
            />
        </g></g>
    )
}

export default SkiMor014
