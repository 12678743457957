import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#5F00C2",
    "col1l": "#9C3AFF",
    "col1n": "#7E00FF"
}

function FacEye036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M36.25 17.45q0-3.95-.95-7.35Q27.55 5 7.15 0H6.9q-.85.8-1.6 1.7Q0 8.25 0 17.45q0 5.15 1.7 9.45 1.3 3.35 3.6 6.15 5.35 6.45 12.85 6.45t12.75-6.45q3-3.55 4.3-7.85 1.05-3.65 1.05-7.75m60.15-.15Q96.4 8.4 91 2q-.75-.9-1.6-1.65Q71.7 4.1 60.55 10.2q-1 3.3-1 7.1 0 4.05 1.1 7.6 1.3 4.2 4.35 7.65 5.35 6.25 12.95 6.25T91 32.55q2.35-2.75 3.7-6 1.7-4.2 1.7-9.25z"
                    id="FacEye036__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M20.8 22.2q.75-2.25 1.15-4.4-.95-2.45-2.25 1.1-4.1 6.65-9 5.95-8.8-2-5.9-24.6l.1-.2L4.75 0q-.5.5-1 1.15-.25.3-.45.6l-.2.3Q0 6.7 0 13.2q0 6.65 3.3 11.4 3.35 4.75 8.05 4.75 4.75 0 8.05-4.75.8-1.15 1.4-2.4m41.2 0q.6 1.25 1.4 2.4 3.3 4.75 8.05 4.75 4.7 0 8.05-4.75 2.25-3.2 2.95-7.25Q81.4 15 79.5 18.9q-4.6 9.25-9.25 6.4-7.9-5.25-7.45-19.85v-.1h-.05q-.8 1.55-1.3 3.15-1.95 6.4.55 13.7z"
                    id="FacEye036__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M17.2 18.95L10.15 2.6Q7.2-.35 2.6.05 0 4.75 0 11.4q0 6.65 2.65 11.4 2.7 4.75 6.5 4.75 3.85 0 6.5-4.75.6-1 1-2.1.4-.9.75-1.8-.1 0-.2.05m60-.05L70.6 2q.05-.1.15-.15-5.8-.8-10.25 3.5 0 .1-.05.2-.2.75-.3 1.5-.35 1.45-.5 2.9-.45 5.4 1.9 10.75.4 1.1 1 2.1 2.65 4.75 6.5 4.75 3.8 0 6.5-4.75 1-1.8 1.65-3.9z"
                    id="FacEye036__Symbol_49_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M224.5 150.35q-.25.2-.45.5-2.25 2.9-2.25 6.95 0 4.05 2.25 6.9 2.25 2.85 5.45 2.85.75 0 1.45-.15 1.6-.35 2.95-1.55.55-.5 1.05-1.15 2.25-2.85 2.25-6.9 0-4.05-2.25-6.95-1.75-2.25-4.05-2.7-3.5-.497-6.4 2.2m13.75.05q2.538 3.503 2.95 12.05.454 8.543-4.8 14.75-5.199 6.207-12.7 6.55-7.506.391-13.1-5.8-5.59-6.184-6-12-.409-5.816.6-10l-7.3 10.3q.45 9.5 7.6 16.35.102.096.2.2 6.227 6.339 12.85 6.55 1.945.098 4.55 0 5.654-.214 10.3-1.25 4.651-.99 5.6-1.4.996-.355.95-.45.002-.049.05-.1l.2-.2q3.2-2 6-5.2 3.8-4.4 5.65-9.75.3-.85.55-1.65-.35.85-.7 1.75 1.864-5.116 2.45-13.25.586-8.134-5.65-16.5L235.3 146q.464.902 2.95 4.4m-61 7.4q0-1.1-.15-2.15-6.072-4.043-13.2-4.5-2.05 2.8-2.05 6.65 0 4.05 2.25 6.9 2.25 2.85 5.45 2.85.8 0 1.6-.15 1.2-.35 2.25-1.1.85-.65 1.6-1.6 2.25-2.85 2.25-6.9m4.95-.4l-.85-.65q.605 3.25.2 8.75-.405 5.497-5.55 11.85-5.097 6.407-12.85 6.6-7.707.242-13.2-6-5.44-6.199-5.45-14.9-.013-8.645 2.75-12.35 2.807-3.7 3.45-4.3l-12.5-5.05q-6.452 8.168-6 16.4.45 8.244 2.2 13.35 1.85 5.35 5.65 9.75 2.8 3.2 6 5.2l.2.2q.05.045.15.15.15.114 1 .65.855.537 5.55 1.65 4.69 1.103 10.25.55 5.613-.498 8.25-1.05.783-.163 1.4-.3.227-.035.4-.1 4.287-1.413 8.55-6.15 6.134-6.524 6.55-15.3-2.232-4.43-6.15-8.95z"
                    id="FacEye036__Layer5_0_FILL"
                />
                <path
                    d="M213.05 187.7l-1.8-.3q-3.55-.45-.1.95 3.45 1.35 4.6 1.7 7.75 2.05 17.85 0 8.1-1.3 6.45-3.9-.18.09-.35.15-.14.073-.25.1-1.9.45-3.8.85-6.95 1.3-13.75 1.2-4.45-.05-8.85-.75m26.5-1.15l-.05.05v-.05h.05m-69.05 3.6q1.15-.3 4.6-1.7 3.45-1.4-.1-.95l-1.8.3q-4.4.7-8.85.75-6.8.1-13.75-1.2-1.9-.4-3.8-.85-.2-.05-.6-.25-1.65 2.6 6.45 3.9 10.1 2.05 17.85 0m-23.75-3.5v.05l-.05-.05h.05m37.45-33.45l-.1-.05-35.8-14.9-.25-.05q-.1-.1-.2-.1-.35-.15-.75-.35l-1-.4q-.2-.1-.5-.2-1-.5-2.15-1.05-2.45-1.3-5.55-3.15-.25-.1-.45-.25.7 3.3 2.55 3.95-1.3-.1-2.45-.25-4.65-1.25-7.2-2.9 1.6 3 3.4 3.4-2.45 1.15-7.75-.65 4.95 4.1 8.05 4.7.15.05.2.1.15-.05.3 0 .1 0 .2.05-8.35 9.55-.35 30-1.75-5.1-1.75-11 0-10.2 5.3-17.9l12.1 4.1v.05q-.35.3-.7.7-5.65 6.45-5.65 15.55 0 9.05 5.65 15.45 5.65 6.45 13.6 6.45 7.95 0 13.6-6.45 5.65-6.4 5.65-15.45 0-2.45-.4-4.7v-.05l10.05 13.05-7.65-17.7m-7.55 2.3l2.15.75q.9 3.05.9 6.55 0 3.7-1 6.95-1.2 3.85-3.95 7-4.85 5.75-11.8 5.75-6.9 0-11.85-5.75-2.15-2.5-3.35-5.5-1.55-3.85-1.55-8.45 0-8.2 4.9-14.05.7-.8 1.45-1.5l11.35 3.85v.05l12.75 4.3v.05m63.85-18.35q-.3.1-.5.2l-1 .4q-.4.2-.75.35-.1 0-.2.1l-.25.05-35.8 14.9-.1.05-7.65 17.7 10.2-13.2v.1q-.45 2.3-.45 4.8 0 9.05 5.65 15.45 5.65 6.45 13.6 6.45 7.95 0 13.6-6.45 5.65-6.4 5.65-15.45 0-9.1-5.65-15.55-.35-.4-.7-.75l12-4.1q5.3 7.7 5.3 17.9 0 5.9-1.75 11 .35-.9.7-1.75 6.95-19.1-1.05-28.25.1-.05.2-.05.15-.05.3 0 .05-.05.2-.1 3.1-.6 6.95-3.9-4.2 1-6.65-.15 1.8-.4 3.4-3.4-2.55 1.65-7.2 2.9-1.15.15-2.45.25 1.85-.65 2.55-3.95-.2.15-.45.25-3.1 1.85-5.55 3.15-1.15.55-2.15 1.05m-.6 25.65q0 4.6-1.55 8.45-1.2 3-3.35 5.5-4.95 5.75-11.85 5.75-6.95 0-11.8-5.75-2.75-3.15-3.95-7-1-3.25-1-6.95 0-3.5.9-6.55l2.15-.75v-.05l4.75-1.6h.05l19.3-6.6q.75.7 1.45 1.5 4.9 5.85 4.9 14.05z"
                    id="FacEye036__Layer5_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye036__Symbol_79_0_Layer0_0_FILL"
                transform="translate(145.05 145.25)"
                id="FacEye036__col1n"
            />
            <use
                xlinkHref="#FacEye036__Symbol_53_0_Layer0_0_FILL"
                transform="translate(151.45 148.5)"
                id="FacEye036__col1l"
            />
            <use
                xlinkHref="#FacEye036__Symbol_49_0_Layer0_0_FILL"
                transform="translate(153.75 148.5)"
                id="FacEye036__col1d"
            />
            <use xlinkHref="#FacEye036__Layer5_0_FILL" />
            <use xlinkHref="#FacEye036__Layer5_1_FILL" />
        </g></g>
    )
}

export default FacEye036
