import { AssetSvgProps } from "../../../shared/assets"

function ObjToy060({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjToy060__Layer11_0_FILL">
                    <path
                        fill="#333"
                        d="M100.7 322.3q.033.398.05.8 2.995 28.45 48.55 80.35.25.25.55.25.3 0 .55-.2.25-.2.25-.5t-.2-.55q-45.204-51.506-48.15-79.35-.018-.402-.05-.8-.97-11.373 5.3-18.7-.4-.6-.85-1.35-7.047 7.799-6 20.05z"
                    />
                    <path
                        fill="#B8B6AA"
                        d="M124.8 304.6l-.3-.25v.05l.3.2m-7.55-47.35q-5.6-3.85-12.3-2.6-6.65 1.25-10.5 6.9-.3.4-.5.85-.35.4-.65.85-3.854 5.6-1.8 12.4 2.112 6.85 5.7 10.1 6.662 2.775 9.7 4.3.073-.042.15-.1l.05-.05q1.496-1.136 4-1.4 1.846-.313 3.95.15 2.745.598 5.9 2.5 5.3 3.325 4.55 9.2-.071.658-.3 2 5.615 4.869 7.75 7.8 3.594 1.748 5.3 2.6.355.177.75.35 2.787 1.213 9 .6 6.204-.604 10.05-6.2.3-.45.55-.9l.6-.8q3.15-4.6 2.9-9.95-.1-1.15-.35-2.4-1.25-6.65-6.85-10.45l-.6-.4q-4.65-2.9-10-2.4l-20.55-14.05q-1.6-5.55-6.5-8.9m-18.85 26.8l1.1.7q-.4-.2-.75-.45-.2-.15-.35-.25m37.4 25.5l1.35.95q-.35-.2-.6-.35-.05-.05-.15-.1-.35-.25-.6-.5z"
                    />
                </g>
                <g id="ObjToy060__Layer2_0_FILL">
                    <path
                        fill="#D7D6CE"
                        d="M158.05 307.5q.3-.45.55-.9-3.8 4.85-9.9 6-6 1.15-11.2-1.9-.2-.05-.35-.2-.35-.2-.6-.35-.05-.05-.15-.1-.35-.25-.6-.5l-10.6-7.2q0 .2-.05.45-.25.9-.65 1.55v.05l11.45 7.8q.15.15.4.25 5.15 3 11.15 1.85 6.7-1.2 10.55-6.8m-64.1-45.1q-.35.4-.65.85-3.85 5.6-2.6 12.25 1.25 6.5 6.5 10.25l8.45 5.8q.545-.953 1.4-1.6l-7.55-5.2q-.4-.2-.75-.45-.2-.15-.35-.25-5.3-3.8-6.5-10.25-1.15-6.15 2.05-11.4z"
                    />
                    <path
                        fill="#8A8983"
                        d="M134.05 288q-1.9 2.75-2.35 5.8-.35 2.4.15 4.95 1.05 5.8 5.95 9.15 4.9 3.35 10.75 2.25 5.85-1.05 9.2-5.95 3.35-4.9 2.2-10.8-1-5.6-5.65-8.95l-.3-.2q-4.9-3.35-10.75-2.25t-9.2 6m4.3.45q1.25-.6 2.55 0 1.2.45 1.8 1.5.05.05.05.2l4.7 11.2q.05.1.15.25.55 1.3-.05 2.55-.5 1.35-1.8 1.85-1.25.6-2.6 0-1.25-.55-1.85-1.8-.05-.25-.15-.4l-4.5-10.65q-.05-.15-.15-.3-.5-1.3 0-2.6.55-1.3 1.85-1.8m12.65-.75q.05.05.1.25l4.7 11.15q.05.15.1.25.55 1.3 0 2.55-.55 1.35-1.8 1.9-1.3.55-2.6-.05-1.3-.5-1.85-1.8-.05-.25-.15-.4l-4.55-10.65q-.05-.15-.1-.25-.5-1.35-.05-2.6.55-1.3 1.9-1.85 1.25-.55 2.5 0 1.25.45 1.8 1.5z"
                    />
                    <path
                        fill="red"
                        d="M153.15 303q1.1-.25 1.7-1.15.6-.9.4-1.95-.15-1.05-1.05-1.65-.9-.6-2-.45-1.05.2-1.65 1.1-.6.9-.45 2 .2 1.05 1.1 1.65.9.6 1.95.45z"
                    />
                    <path
                        fill="#FC0"
                        d="M150.15 290.8q.6-.9.4-2-.2-1-1.05-1.65-.9-.6-2-.45-1.1.25-1.7 1.15-.6.9-.4 1.95.25 1.05 1.15 1.65.85.65 1.9.45 1.1-.2 1.7-1.1z"
                    />
                    <path
                        fill="#00F"
                        d="M145.9 300.45q-.9-.65-1.95-.4-1.1.15-1.7 1.05-.6.9-.4 2 .2 1 1.1 1.65.9.6 1.95.4 1.1-.2 1.7-1.1.6-.9.4-1.95-.2-1.05-1.1-1.65z"
                    />
                    <path
                        fill="#090"
                        d="M139.2 288.9q-1.05.25-1.65 1.15-.6.9-.45 1.95.2 1.05 1.1 1.65.1.1.25.15.8.45 1.75.3.45-.1.85-.3.45-.3.8-.8.6-.9.45-2-.2-1.05-1.1-1.65-.9-.6-2-.45z"
                    />
                    <path
                        fill="#666"
                        d="M129.55 278.25q-.7-.25-1.3.05-.65.3-.95 1-.2.65.1 1.3.05.05.05.15l2.6 5.35.1.2q.3.65 1 .9t1.3-.1q.65-.3.9-1 .25-.65-.05-1.3-.05-.05-.1-.15l-2.65-5.55q-.05-.1-.1-.15-.3-.5-.9-.7m-9.65-4q-.2.65.1 1.3.05.05.05.15l2.6 5.3q.05.1.1.25.3.65 1 .85.4.15.75.15.3-.05.55-.2.65-.3.9-1t-.05-1.3q-.05-.05-.1-.15l-2.65-5.6-.1-.1q-.3-.5-.9-.7-.7-.25-1.3.05-.65.3-.95 1M110 263l-2.4 3.55-3.5-2.4-3.05 4.45 3.5 2.4-2.4 3.55 4.5 3.1 2.4-3.55 3.5 2.4 3.05-4.45-3.5-2.4 2.4-3.55-4.5-3.1z"
                    />
                    <path
                        fill="#8E8D89"
                        d="M118.75 268.5q-.85-4.25-4.4-6.7-3.55-2.4-7.75-1.65-4.3.85-6.7 4.4-2.45 3.55-1.6 7.8.75 4.3 4.3 6.7 3.55 2.45 7.85 1.65 4.2-.85 6.65-4.4 2.4-3.55 1.65-7.8m-4.9-5.95q3.25 2.25 4.05 6.1.65 3.9-1.55 7.15-2.25 3.25-6.05 3.95-3.95.8-7.2-1.45-3.25-2.2-3.95-6.15-.75-3.85 1.5-7.1 2.2-3.25 6.1-4 3.85-.7 7.1 1.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy060__Layer11_0_FILL" />
            <use xlinkHref="#ObjToy060__Layer2_0_FILL" />
        </g></g>
    )
}

export default ObjToy060
