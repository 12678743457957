import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#56A689",
    "col1n": "#8AD7BB",
    "col2n": "#006AC9"
}
function ObjMor100({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#553F35"
                    d="M304.9 262h-19.15v99.6h19.5l-.35-99.6M80.25 361.6H99.8v-95.25H80.25v95.25z"
                    id="ObjMor100__Layer10_0_MEMBER_0_FILL"
                />
                <path
                    fill="#D7CABD"
                    d="M310.2 244.35h-26.3q-2.7.4-4.3 1.8-.6.45-1.05 1.05-1.25 1.45-1.85 3.75v18.9q.6 2.25 1.4 3.75.65.95 1.5 1.5 1.25.8 3.1.7h27.7q5.2-1 5.95-5.95V250.3q-1.2-5.3-6.15-5.95m-204 30.85q1.65-.7 2.2-2.65 0-.45.2-.65v-5.55H69.5q-.35 0-.75-.25v6.2q.55 2.7 4.25 3.25h31.5q1-.05 1.7-.35z"
                    id="ObjMor100__Layer10_0_MEMBER_1_FILL"
                />
                <path
                    d="M114.55 243.7q-1.65-2.2-3.85-2.85H69.15q-2.7 1-3.15 2.85v19.75q.85 2.1 2.75 2.65.4.25.75.25h41.35q2.95-.8 3.7-3.1V243.7z"
                    id="ObjMor100__Layer10_0_MEMBER_3_FILL"
                />
                <path
                    fill="#CCC"
                    d="M72.25 462.8q-4.1 2.65-6.45 6.15-6 15.3 0 27.15 2.45 4.15 6.45 6.4v-39.7m20.55 4.55l-3.95-4.75v38.25l3.95-5.95q4.186-14.473 0-27.55m203.4-4.75l-3.9 4.75q-5.581 13.775 0 27.55l3.9 5.95V462.6m23.05 6.35q-2.3-3.5-6.35-6.15v39.7q3.9-2.25 6.35-6.4 6.05-11.85 0-27.15z"
                    id="ObjMor100__Layer10_0_MEMBER_4_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M4.75 6.6L0 13.75V80h4.75V6.6m202.6 86.35v-25.1q-34.9-4.15-70-5.05 35.2-1.1 70-6.1V0H26.3v56.7q35.8 5 71.15 6.1-35.45.9-71.15 5.05v25.1h181.05m26.75-82.7l-4.35-4.7V80h4.35V10.25z"
                    id="ObjMor100__Symbol_249_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M199.5 76.15v-27.1H0v27.1h199.5m-9.45-65V0H9v11.15h181.05z"
                    id="ObjMor100__Symbol_250_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M185.95 186.75q-1.45-12.4-17.15-12.9H18.25Q.5 175.35 0 192.15h185.95v-5.4M178.9 13.4q-2.5-9.25-10.6-12.15Q164.9.1 160.65 0H25.6q-3.35.35-6 1.25-9.05 3.05-12 12.15-.85 2.6-1.2 5.75v75.5h4.5q2.2.65 3.85 2.85v19.55q-.75 2.3-3.7 3.1H8.8v5.55q-.2.2-.2.65-.55 1.95-2.2 2.65v11.9q1.15 12.15 13.3 13.3h145.85q13.75-3.3 14.25-14.25V128.9q-.85-.55-1.5-1.5-.8-1.5-1.4-3.75v-18.9q.6-2.3 1.85-3.75.45-.6 1.05-1.05v-80.8q-.25-3.15-.9-5.75z"
                    id="ObjMor100__Symbol_248_0_Layer0_0_FILL"
                />
                <g id="ObjMor100__Layer10_0_FILL">
                    <path
                        fill="#999"
                        d="M101.8 361.6H80.25v80h3q5.85-.25 5.6 5.6v15.4l3.95 4.75q5.22 7.92 9 0V441.6h181.05v25.75q5.46 8.078 9.45 0l3.9-4.75v-15.4-.95q.25-4.45 4.75-4.65h4.3v-80h-22.4q-89.059 14.663-181.05 0z"
                    />
                    <path
                        fill="#CCC"
                        d="M52.05 363.45L45.7 381q-6.05 43.3 0 76.9l6.35 18V363.45M339.4 381l-6.35-17.55V475.9l6.35-18q6-33.6 0-76.9z"
                    />
                    <path d="M56.9 323.85q-4 2.15-4.85 6.65v174.9q-.1 8.95 9.4 8.4h12q.05 5.1 4.75 4.7h5.65q4.65.25 5-4.95V447.2q.25-5.85-5.6-5.6H75.5V329.95q-.75-4.1-4.15-6.1-6.8-3-14.45 0m276.15 6.65q-.9-4.5-4.8-6.65-7.7-3-14.55 0-3.35 2-4.1 6.1V441.6h-8.65q-4.5.2-4.75 4.65V513.55q.35 5.2 5.05 4.95h5.65q4.65.4 4.75-4.7h12q9.5.55 9.4-8.4V330.5z" />
                    <path
                        fill="#382016"
                        d="M304.9 137.6q-1.7-13.85-15.4-15.5H99.6q-16.45 1.25-17.75 17.75v.45H82q-.1.45-.15 1.05l-.05 125h18q-17.977 48.957 0 95.25h2q91.991 14.663 181.05 0h2.9q21.546-50.57 0-99.6h19.15V137.6z"
                    />
                </g>
                <g id="ObjMor100__Layer10_0_MEMBER_2_FILL">
                    <path d="M91.6 232.85q.15-1.45.35-2.9 0-1.9.2-3.5-1 .2-1.95.25-1.35-.05-2.15-.25 0 1.05-.2 2.25-.2 2 0 4.1h3.5q.15 0 .25.05z" />
                    <path
                        fill="#A59689"
                        d="M91.9 232.85h-.3q-.1-.05-.25-.05h-5.2q-1.35.35-1.6 1-.2.3-.2.9 0 .55.2.95-.1 0-.25.05-1.15.3-1.15 1.4 0 .65.35 1-.7.3-1 .6-.15.35-.15.85v.25q-.2.35.1 1.05H97.5q.2-.9-.15-1.65-.45-.7-.85-.75.4-.35.4-1.15 0-.5-.2-1-.3-.7-1-.75v-.25-.3q0-1.55-1.7-2.1-2.7-.15-2.1-.05z"
                    />
                    <path
                        fill="red"
                        d="M85.35 214.95q-2.05 1.85-2.05 4.7 0 2.9 2.05 5.1 1.05 1.05 2.7 1.7.8.2 2.15.25.95-.05 1.95-.25 1.7-.5 2.9-1.7 2.05-2.2 2.05-5.1 0-2.85-2.05-4.7-2.1-2.1-4.95-2.1t-4.75 2.1z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor100__Layer10_0_FILL" />
            <use xlinkHref="#ObjMor100__Layer10_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor100__Layer10_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor100__Layer10_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjMor100__Layer10_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjMor100__Layer10_0_MEMBER_4_FILL" />
            <use
                xlinkHref="#ObjMor100__Symbol_249_0_Layer0_0_FILL"
                transform="translate(75.5 361.6)"
                id="ObjMor100__col1n"
            />
            <use
                xlinkHref="#ObjMor100__Symbol_250_0_Layer0_0_FILL"
                transform="translate(92.8 418.3)"
                id="ObjMor100__col1d"
            />
            <use
                xlinkHref="#ObjMor100__Symbol_248_0_Layer0_0_FILL"
                transform="translate(99.8 146.2)"
                id="ObjMor100__col2n"
            />
        </g></g>
    )
}
export default ObjMor100
