import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.25 6.95q-.3-5.95-6.5-6.7-2.65-.4-6.55-.1H6.5Q.95.45.05 6.9 0 8.2.05 9.6q.55 9.5 1.7 14 .15.4.35.8 1.15 1.25 2.05 0 1.35-2.35 3-12.9.05-.1.05-.15v-.05q1-3.05 5.7-3.1h.95q1.25 0 2.5.05h15.9q.05-1.1 0-1.3M71.5 9.6q.05-1.4 0-2.7Q70.6.45 65.05.15h-12.7q-3.9-.3-6.55.1-6.2.75-6.5 6.7-.05.2-.1 1.3h16.15q1.15-.05 2.35-.05 1.15-.05 2.1.05 3.7.4 4.55 3.05v.05q0 .05.05.15 1.65 10.55 3 12.9.9 1.25 2.05 0 .2-.4.35-.8 1.15-4.5 1.7-14z"
                    id="BeaMus027__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus027__Symbol_44_0_Layer0_0_FILL"
                transform="translate(156.75 201.4)"
                id="BeaMus027__col1n"
            />
        </g></g>
    )
}

export default BeaMus027
