import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M72.55 8.95q.75-.55 3.4-.95 2.7-.45 3.35-.45h.9q4.25.2 9.3 1.05-9.15-8.75-21-8.6Q46.9.6 28 17.1 9 34.2 2.95 61.45.5 73.7.05 88.1q0 1.4-.05 2.8V197.55q.55 5.95.75 13.5.25 7.5 1.15.55 1.75-11.4 2.15-17.65.4-6.15 4.7-37.3 2.65 15.85 3.95 40.6l3.45 18.1q.7-13.2.95-17.55.25-4.2.4-37.65v-.25q-.35-36.25 5.75-89.5.65-3 1.6-5.8Q28.8 51.05 39.5 43.35q4.15-3.05 9.2-5.6 25.7-13.1 37.35-9.6-7.1-8.7-17.55-7.25 5.9-4.05 18-2.2-5.3-8.35-14.8-9.2.1-.05.85-.55z"
                    id="HaiHig007__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig007__Symbol_11_0_Layer0_0_FILL"
                transform="translate(96 65.45)"
                id="HaiHig007__col1n"
            />
        </g></g>
    )
}

export default HaiHig007
