import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M189.1 19.6q-.05.1-.1.15l-8.3 10.3V27.6q0-.3.05-.6V4.85L173.2 25l-7-18.15-.05.2v-.1l-6.8 15.35q-.05-.25-.1-.45l.2-.4q-.1.1-.3.45v.05l-5.55-15.6-5 15.6-14.55-20.1L134 15.5q.05.4.1.75L122.4.2V.1l-.05.05V0L101.8 15.35l-2-14.5-6.7 14.45-6.75-13.45-17.9 18.25-.2-.4-.05-.05q-.2-.4-.1-.3l-3.8-11-7.1 14.9q-1.333-3.102-5.95-11.85l-3.3 3.75q-.4.45-.8 1l-.4.5q-.1.1-.15.2L33.3 32.2q-.1-.15-.2-.35l.05-.05-5.5-16.9-8.7 18.7v1.15q-4.2-9.3-4.2-9.35l-3.2-9.3q-2.15 9.95-6.3 19.4L5.1 35 0 23.25v87.1L21.65 65.1v10.55L58.3 63.1l5.5 6.05 30.6-8.05 24.1 9.05 13.55-14.05 30.15 18.05 8.5-9.55 20.95 50.4V16.6l-2.55 3z"
                    id="HaiSho043__Symbol_164_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.9 37.1Q3.6 43.45 8.35 48q1.6 1.55 2.4 2.55v-1L6.35 16.1 0 34.8q.4 1.15.9 2.3m41.85-21.95q-.6.7-1.2 1.5-.1.1-.15.2l-13.5 15q3.6 7.5 5.2 12 .9 2.5 1.3 3.55l.25.7q.1-1.15.25-2.25Q36.45 27.7 46.05 11.4l-3.3 3.75m36.95-4.3q-.1-.85-.1-1.8-.05-1.6.5-3.8.4-1.55 1.05-3.4l-18 17.8-.1.05q4.35 9.4 11 18.4.4.55.9 1.1.5-8.55 1.4-17.05.1-1.05.6-2.05h.1q.05.25 1.1 1.75 1 1.5 2.75 2.9 1.7 1.4 3.5 2.8-3.7-7.25-4.45-13.35l-.1-.9q-.1-1.2-.15-2.45m49.1 4.65v.1L117.25.05 117.2 0v.1q-3 14.15.45 28.25.7-2.9 3.4-8.35.35-.75.65-1.4.65 1 1.35 2 1.7 2.3 3.6 4.45 1.3 1.45 2.75 2.85.35.35.7.6-.5-4.5-1.3-13m31.3-2.2q.35-2.9.85-6.25V6.8l-6.9 15.05q.35 1.6 1.05 3.55.55 1.35 1.25 3.05.7 1.65 1.85 4 1.1 2.3 1.85 3.6-.5-3.15-.75-9.1-.25-5.3.8-13.65m17.2 24.95q2.75-12.2 6.6-18.65l-8.4 9.85v6.95q.15 6.9.45 8.4.3-2.05 1.35-6.55z"
                    id="HaiSho043__Symbol_86_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho043__Symbol_164_0_Layer0_0_FILL"
                transform="translate(96.6 42.15)"
                id="HaiSho043__col1n"
            />
            <use
                xlinkHref="#HaiSho043__Symbol_86_0_Layer0_0_FILL"
                transform="translate(101.8 42.15)"
                id="HaiSho043__col1d"
            />
        </g></g>
    )
}

export default HaiSho043
