import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#695000",
    "col1n": "#8A6900",
    "col2n": "#FFFF00"
}

function AccMor022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.65 8.6l-.35-2.3v-.1l-.85-5.45-5.25.5-1 .1q-20.65 2.2-41.3 2.8l-4.7.1q-1.35.05-2.6.05h-.7q-1.5.05-3 .05-1.25 0-2.45.05H68.6l-9.55-.1q-.45.45-.8.95L54 4.2v-.05h-.05Q31.75 3.5 9.7 1.05L7.9.85 1.35 0 0 8.4v.25q.05.05.1.15 3.9.75 6.65 1.3.85.15 1.6.3.65.1 1.25.2.4.05.75.15.4.05.75.1.75.15 1.5.25l3.4.6q6.9 1.1 13.7 1.9 2.3.3 4.6.55 2.4.2 4.75.45 2.05.15 4.05.35 5.3.4 10.65.65 1.4-.5 2.65-.95l2.75 1.1q.35 0 .7.05 1.95.05 3.9.05H79.05q3.15-.2 6.35-.4 2.55-.2 5.1-.35 1.65-.15 3.35-.3 2.15-.15 4.25-.35 1.55-.15 3.05-.3 7.1-.75 14.2-1.85 1.5-.2 2.95-.45 1.4-.2 2.75-.45 1.75-.3 3.65-.6.25-.05.55-.1 3.8-.65 8.3-1.4.05-.1.1-.15V8.6M52.7 10.7h-.3v-.05q.15 0 .3.05z"
                    id="AccMor022__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M7.25 1Q6.6.4 5.85 0l-4.7.1q2.35.25 4.1 1.65Q7.4 3.4 7.45 5.8q-.05 2.35-2.2 4.05-2.15 1.7-5.25 1.7v.15q3.15-.2 6.35-.4l.9-.7q2.15-2 2.2-4.8Q9.4 3 7.25 1z"
                    id="AccMor022__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M21.45 17.35v-.1h-2.6l-.05.05q-2.45 2.45-5.95 2.45-3.45 0-5.9-2.45-.1-.05-.15-.15-2.35-2.4-2.35-5.75T6.7 5.7l.05-.05.2-.2Q9.4 3 12.85 3q3.5 0 5.95 2.45.15.15.3.35 1.2-.05 2.45-.05-.8-1.3-1.9-2.4Q16.25-.05 11.5 0 6.75-.05 3.4 3.35 2.35 4.4 1.65 5.55v.05q-.05 0-.05.05-1.25 2-1.5 4.4-.1.7-.1 1.45 0 .25.05.55v.05q.1 2.65 1.35 4.9h.1v.15q.75 1.3 1.9 2.5.9.95 1.95 1.65 2.7 1.7 6.15 1.75 3.95-.05 7-2.35.6-.45 1.15-1.05 1.05-1.1 1.8-2.3z"
                    id="AccMor022__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor022__Symbol_120_0_Layer0_0_FILL"
                transform="translate(125.7 302.55)"
                id="AccMor022__col1n"
            />
            <use
                xlinkHref="#AccMor022__Symbol_71_0_Layer0_0_FILL"
                transform="translate(204.75 306.7)"
                id="AccMor022__col1d"
            />
            <use
                xlinkHref="#AccMor022__Symbol_25_0_Layer0_0_FILL"
                transform="translate(178.05 301.15)"
                id="AccMor022__col2n"
            />
        </g></g>
    )
}

export default AccMor022
