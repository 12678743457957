import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999",
    "col1s": "#434343",
    "col2n": "#006AC9"
}

function ObjToo070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M92.95 9.25L88.1 0 0 51.55l3.65 5.9h.05l.2.4q.9 1.4 1.75 2.8 13.05 20.65 26.3 34.3 29.65 28.55 65.5 23.6 20.4-26.85 13.45-63.35-2.25-9.9-6.4-20.75-4.8-12.4-11.95-26.1l.4.9m-17.9 49.3v-.05q.15-.05.35-.15h.05l-.7.4.3-.2m13.5 55.55v.05q-.9.05-1.7.05l1.7-.1z"
                    id="ObjToo070__Symbol_228_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M98.5 8.8l.45.95L93.8 0 0 54.35l3.9 6.25h.05l.2.4Q5.1 62.5 6 63.95q13.9 21.8 28 36.15 31.6 30.1 69.75 24.9 21.7-28.3 14.3-66.8-2.4-10.4-6.8-21.85-5.1-13.1-12.75-27.55m-7.4 1.1q8.95 15.85 13.8 30.1 4.45 12.85 6.15 24.25 4.15 28.25-8.15 47.6-.05.05-.05.1l-1.35 2.3-47.8-82.5L91.1 9.9M10.2 57l36.6-21.25 47.65 82.1H92.5v.05q-.9.05-1.7.05-41.65.7-80.6-60.95z"
                    id="ObjToo070__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M47.15 16.2l-3.7-6.15-17.1 9.8L14.85 0l-8 4.55 11.6 20L0 35.2l3.55 6.2 18.4-10.65L38.1 58.6l7.7-4.5-16.05-27.85 17.4-10.05z"
                    id="ObjToo070__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill="#F3F3F3"
                    d="M269.35 267.65l-4.55 2.65-3.25-5.6q-6.64-9.902-4.8-16.6v-.05l-17.15 9.55q7.159 2.761 11.3 13l3.3 5.8-15.2 8.85h-1.3q-1.35-.05-2.7-.5l8.15 14.1q-.2-1.9.35-3.6.067-.224.15-.45.134-.296.3-.6.1-.2.3-.45l14.75-8.6 13.3 22.9q.3 1.7-.3 3.8-.55 2-1.95 4.25 3.7-2.1 7.3-4.25.1-.15.2-.2 3.1-1.9 6.3-3.7.2-.2.45-.35 1.8-1.15 3.6-2.25 1.45-.9 2.8-1.7-2.35 0-4-.3-2.6-.5-4.3-1.95l-12.95-22.25 13.1-7.75q4.512-1.27 6.25-.2-.15-.15-.25-.35l-4.3-7.25 2.1 3.7q.038.08.05.15l-5.2-9.05q-.65 1.4-1.7 2.7-.5.55-1.05 1.1l-.6.6q-1.145.916-4.9 2.95-1.491 1.012-3.5 1.9h-.1m-23.6-10.35l6.8-3.95 11.4 19.7 16.85-9.7 3.1 5.3-17.05 9.85 15.75 27.25-6.6 3.8-15.8-27.2-17.75 10.25-2.95-5.4 17.7-10.15-11.45-19.75z"
                    id="ObjToo070__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo070__Symbol_228_0_Layer0_0_FILL"
                transform="translate(193.9 209.3)"
                id="ObjToo070__col1n"
            />
            <use
                xlinkHref="#ObjToo070__Symbol_114_0_Layer0_0_FILL"
                transform="translate(189.95 205.55)"
                id="ObjToo070__col1s"
            />
            <use
                xlinkHref="#ObjToo070__Symbol_100_0_Layer0_0_FILL"
                transform="translate(238.05 252.2)"
                id="ObjToo070__col2n"
            />
            <use xlinkHref="#ObjToo070__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo070
