import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho074({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M131.55 50h-.05q-.225.112-.45.2.236-.087.5-.2m73.35-9.95v.05q-.002-.052-.05-.1-2.8-6.15-5.9-11.9-1.4-2.6-2.85-5.1L147.2 2.65l-4.35 6.75-17.3-9.4-.05.05V0l-9.45 7H116L107.65.15 90.7 9.25 84.95 1.3 40 17q-6.55 11.75-11.9 24.75-5.4 13.2-9.55 27.75-5.7 19.75-10.05 41.95-1.05 5.4-2.05 11Q2.65 143.9 0 167.5l18.65-59.75q-4.4 47.9-5.65 96.75 14.35-42.4 18.5-51.3.05 5.3 10.5 43.3 4.55-39.45 11.95-73.8.1-.35.15-.65l-.1.7q3.9-18.3 8.65-35.15Q66.2 74.7 70.6 61l-.1.15q.3-.95.6-1.85l5.15 4.1 1.7-7.7 4.4 2.5 1.8-6.5 6.5 3.3 2.6-5.4 4.7 4.2 2.9-7.3 4.9 5.1 1.6-5.2 5.45 3.25-.15-.3.15.25v.05l4.8-.75 2.95.6 8.4-4.3-.5 4.5 5.7-2.8.55 5v.05q.35-.35.85-.8l5.4-4.1.6 5.45 7.4-3.6v6.5l4.4-3.1v7.6l5.4-3.8.6 6.15v.85l4.4-3.55q4 12.7 7.5 25.4 5.55 20.2 10 42.1 6.4 31.95 10.35 67.45 10.6-35.95 10.9-41.55 4.05 8.55 14.6 51.05 2.75-48.35-1.65-96.25l19.15 54.75q-6.65-50.15-18.45-91.35V71q-.15-.45-.25-.85-4.7-16.15-11-30.1z"
                    id="HaiSho074__Symbol_195_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M16 12.5L3.9 45.45 0 59.3l5.15 4.1 1.7-7.7 4.4 2.5 1.8-6.5 6.5 3.3 2.6-5.4 4.7 4.2 2.9-7.3 4.9 5.1 1.6-5.2 5.45 3.25-3.4-5.4L16 12.5M44.95 7h-.05L36.55.15l9.6 48.25L54.4.05V0l-9.45 7M92.6 59.5l.1-.05-.85-3.75-15.15-43-27.25 36.8 8.4-4.3-.5 4.5 5.7-2.8.55 5q.4-.35.85-.75l5.4-4.1.6 5.45 7.4-3.6v6.5l4.4-3.1v7.6l5.4-3.8.6 6.15q0 .35.25.25-.15.25-.25.6l4.4-3.55-.05-.05z"
                    id="HaiSho074__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M169.65 41.15q-2.5-2.8-4.75-6.9-3.45-6.35-3.75-6.8l-1-1.9v16.5q-9.7-15-10-17.6 0 3.85.8 13.5l-.05.5q-.25-.65-1.65-2.95-1.45-2.35-3.4-6.15-2-3.8-3.3-6l1.8 19.35q-6.3-20.85-15.65-41.15L98.75 47.3 72.15 7.6 66.5 0 53.6 39.7l.2-22q-5.7 11.2-7 14.6-1.3 3.35-1.3-.95-.05-4.3-.95-8.9-.85.6-3.65 5.85-2.85 5.2-4.85 9.65v.1q-.2-1.95-1-5.2-.9-3.6-1.5-4.4-1.8 1.8-5 7.7-2.9 5.3-3.5 7.3h-.5l-3.2-13.3-9.9 14.2-1.8-3.65Q4.25 53.9.1 68.45.55 72 .55 74.2L.5 79.6Q.25 85.05 0 89.95h.05q6.9-15.5 10.5-21.5v2.5q0 .1-.05 2.65v.35l-.95 17.5q.9-2.1 5-14.1 2.3-6.7 3.75-10.7-.05 4.3.25 12.8v21q11.65-26.25 13.5-29v23.3q7.3-18.55 10.4-24.4L35.6 120.9v.05l-.1.7q3.9-18.3 8.65-35.15 3.55-12.9 7.95-26.6l-.1.15q.3-.95.6-1.85Q60 35.55 68.65 12.05L94.3 48.5v.05l4.35-.7 3.4.55 27-36.1.1.05q8.5 22.15 15.5 44.2l.6 1.9q4.05 12.75 7.55 25.45 5.55 20.2 10 42.1l-8.75-58.05 9.55 23.55q-2.95-23.8-2.95-26.05.8 2.45 5.55 11.45 5.1 9.6 8.1 19.15l-1.65-34.1q.65 2.25 5.55 10.25 8.05 13.15 8.45 17.1V63.45q10.9 23.25 11 25.95V69.9q-.15-.45-.25-.85-4.7-16.15-11-30.1v3.25l-7.5-12.15q.65 2.95.25 7.9v1.5q-8.95-9.45-9.5-11v12.7m-149.6 26.8q-.05-.2-.05-.35.05.05.05.1v.25z"
                    id="HaiSho074__Symbol_55_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho074__Symbol_195_0_Layer0_0_FILL"
                transform="translate(76.15 50)"
                id="HaiSho074__col1n"
            />
            <use
                xlinkHref="#HaiSho074__Symbol_104_0_Layer0_0_FILL"
                transform="translate(147.25 50)"
                id="HaiSho074__col1d"
            />
            <use
                xlinkHref="#HaiSho074__Symbol_55_0_Layer0_0_FILL"
                transform="translate(94.65 51.1)"
                id="HaiSho074__col1l"
            />
        </g></g>
    )
}

export default HaiSho074
