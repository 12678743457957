import { AssetSvgProps } from "../../../shared/assets"

function ShiMot105({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFD900"
                    d="M159.2 270v12.05h66.5V270h-66.5z"
                    id="ShiMot105__Layer1_0_MEMBER_0_FILL"
                />
                <g id="ShiMot105__Layer1_0_FILL">
                    <path
                        fill="#31A8EC"
                        d="M159.2 282.05v12.05h66.5v-12.05q-32.37-9.685-66.5 0z"
                    />
                    <path
                        fill="#F50F80"
                        d="M159.2 258v12q32.336 7.315 66.5 0v-12h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot105__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot105__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot105
