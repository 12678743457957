import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.05 6.8Q0 13.9 0 23.9q0 10 7.05 17.05 7.1 7.1 17.1 7.1h1.75q.2-.05.45-.05-1.65-4.05-2.85-8.3-3.75-12.95-3.75-27.4 0-1.85.05-3.65.15-4.4.65-8.65-7.65 1.1-13.4 6.8M231 23.9q0-10-7.05-17.1-5.8-5.75-13.5-6.8.5 4.2.65 8.6.05 1.85.05 3.7 0 14.45-3.7 27.4-1.25 4.25-2.85 8.3.25 0 .5.05h1.75q10 0 17.1-7.1Q231 33.9 231 23.9z"
                    id="FacEar003__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.4 18.9q.95 4.35 4.35 7.75 4.45 4.4 10.85 4.45-1.7-5.95-2.65-12.2-1.1-7.4-1.1-15.2 0-1.85.05-3.65-.1 0-.2.05-4 1-7.15 4.2Q-.05 9 0 15.5q0 1.75.4 3.4m210.05 7.75q3.4-3.4 4.4-7.75.35-1.65.35-3.4.05-6.5-4.55-11.2-3.15-3.2-7.15-4.2l-.3-.1q.05 1.85.05 3.7 0 7.8-1.05 15.2-.95 6.25-2.65 12.2 6.4-.05 10.9-4.45z"
                    id="FacEar003__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.85 3.7q0-1.85.05-3.65-.1 0-.2.05-4 1-7.15 4.2Q-.05 9 0 15.5.05 22 4.75 26.65q4.45 4.4 10.85 4.45-3.75-12.95-3.75-27.4m-1.2-.55q-.05.8-.05 1.65l.35 9.65q.5 6.9 2.2 13.85-4.6.05-7.9-3.7-3.3-3.65-3.35-8.9-.05-5.25 3.2-9.1 2.3-2.65 5.3-3.4.1 0 .25-.05m200 1.15Q207.5 1.1 203.5.1l-.3-.1q.05 1.85.05 3.7 0 14.45-3.7 27.4 6.4-.05 10.9-4.45 4.7-4.65 4.75-11.15.05-6.5-4.55-11.2m-6.1-1.15q.15.05.25.05 3 .75 5.3 3.4 3.25 3.85 3.2 9.1-.05 5.25-3.35 8.9-3.3 3.75-7.9 3.7 1.7-6.95 2.2-13.85l.35-9.65q0-.85-.05-1.65z"
                    id="FacEar003__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar003__Symbol_1_0_Layer0_0_FILL"
                transform="translate(77 141.5)"
                id="FacEar003__col1n"
            />
            <use
                xlinkHref="#FacEar003__Symbol_43_0_Layer0_0_FILL"
                transform="translate(84.9 150.1)"
                id="FacEar003__col2n"
            />
            <use
                xlinkHref="#FacEar003__Symbol_2_0_Layer0_0_FILL"
                transform="translate(84.9 150.1)"
                id="FacEar003__col2d"
            />
        </g></g>
    )
}
export default FacEar003
