import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000"
}

function AccMas036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M167.4 62.25q-1.6-1.75-3.3-3.45-28.15-28.15-67.95-28.2-39.8.05-67.95 28.2-1.7 1.7-3.3 3.45-.8.85-1.55 1.75L0 0v127.4q0 1.45.05 2.85 1.1 36.15 26 62.45.1.05.15.15l4.9 4.6.9.8q1.45 1.25 2.95 2.4l.15.15q.1.05.15.1l1.1.9q.65.5 1.35 1l1.35 1q-1.4-5.3-2.25-9.9-1.3-6.75-1.5-12-.05-1.5 0-2.85.15-5.3 1.65-8.75.6-1.4 1.45-2.5 3.3-2.95 13.65-2.5l8.8.8q4.05.55 8.9 1.35 4.85.8 8.6 2.2l11.5 4.75v.05l6.3 2.6 6.3-2.6v-.05l11.5-4.75q3.75-1.4 8.6-2.2 4.85-.8 8.9-1.35l8.8-.8q10.35-.45 13.65 2.5.85 1.1 1.45 2.5 1.5 3.45 1.65 8.75.05 1.35 0 2.85-.2 5.25-1.5 12-.85 4.6-2.25 9.9l1.35-1q.7-.5 1.35-1l1.1-.9q.05-.05.15-.1l.15-.15q1.5-1.15 2.95-2.4l.9-.8 4.9-4.6q.05-.1.15-.15 24.9-26.3 26-62.45.05-1.4.05-2.85V0l-23.35 64q-.75-.9-1.55-1.75m-122.8 74.3q-7.3-17.05-1.55-20.6 2-1.2 3.85-.9l43.6 8.2q3.9.95 3.2 7.2l-.05.35q-1.95 14.1-6.5 21.15-.45.7-.95 1.35l-1.05 1.25q-3.2 3.5-7.35 4.2-1.65.25-3.45.1-3.25-.3-7-1.95-13.15-4.85-20.85-17.15-1-1.6-1.9-3.2m100.8-21.5q1.85-.3 3.85.9 5.75 3.55-1.55 20.6-.9 1.6-1.9 3.2-7.7 12.3-20.85 17.15-3.75 1.65-7 1.95-1.8.15-3.45-.1-4.15-.7-7.35-4.2l-1.05-1.25q-.5-.65-.95-1.35-4.55-7.05-6.5-21.15l-.05-.35q-.7-6.25 3.2-7.2l43.6-8.2z"
                    id="AccMas036__Symbol_116_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M87.75 109.4q6.15 3.4 6.4 6.8 1.85-7.85-4.75-11.8Q59 95.3 38.55 99q33.55 2.6 49.2 10.4m10.4 6.8q.25-3.4 6.4-6.8 15.65-7.8 49.2-10.4-20.45-3.7-50.85 5.4-6.6 3.95-4.75 11.8m56.25.65q-.95-2.7-2.95-2.85-.95.1-1.7.25-.9.15-1.55.4l.6-.25-3.4.65q1.85-.3 3.85.9 5.75 3.55-1.55 20.6-.9 1.6-1.9 3.2-7.7 12.3-20.85 17.15-3.75 1.65-7 1.95-1.8.15-3.45-.1 1.8.4 3.9.65 18.55-.5 29.6-14.25.95-1.25 1.7-2.45 9-13.15 4.7-25.85m-109.8 19.7q-7.3-17.05-1.55-20.6 2-1.2 3.85-.9l-3.4-.65.6.25q-.65-.25-1.55-.4-.75-.15-1.7-.25-2 .15-2.95 2.85-4.3 12.7 4.7 25.85.75 1.2 1.7 2.45 11.05 13.75 29.6 14.25 2.1-.25 3.9-.65-1.65.25-3.45.1-3.25-.3-7-1.95-13.15-4.85-20.85-17.15-1-1.6-1.9-3.2M15.1 72.8l8.25-8.8L0 0l15.1 72.8M168.95 64l8.25 8.8L192.3 0l-23.35 64z"
                    id="AccMas036__Symbol_82_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas036__Symbol_116_0_Layer0_0_FILL"
                transform="translate(96.35 26.8)"
                id="AccMas036__col1n"
            />
            <use
                xlinkHref="#AccMas036__Symbol_82_0_Layer0_0_FILL"
                transform="translate(96.35 26.8)"
                id="AccMas036__col1l"
            />
        </g></g>
    )
}

export default AccMas036
