import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.2 12.85q-.15.05-.35.1-4.15-3.6-9.75-3.55-5.75-.05-9.9 3.65-.05.05-.1.15-.2.1-.35.25-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3Q0 18.8 0 24.4 0 30.6 4.45 35q4.4 4.4 10.65 4.4 2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.25-3.25 4.1-7.45.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45t-2.25-5.45q-1.65-1.65-3.8-2.1-.3-.05-.55-.1-.25-.05-.5-.05-.15-.05-.35 0-.15-.05-.35 0h-.4q-.7.05-1.25.15m158.05.3q0-1.2-.55-2.15-.3-.55-.75-1-.25-.25-.5-.4-1.05-.85-2.45-.9h-.2q-1.5 0-2.65.9-.95.5-2.5 1.85-1.55 1.3-2.45 1.7-.9.4-1.2.35-1.2-.25-.95-1.7L170.1 7q.15-.95.25-1.75l.1-.7v-.2q0-1.8-1.25-3.05-.45-.45-.95-.7-.9-.55-1.95-.6h-.2q-1.8 0-3.05 1.3-.45.45-.75.95-.05.05-.05.1l-.05.05q-.2.45-.35.9l-.3.85q-.8 6.05-3.15 5.75-.6-.2-1.15-1.15-.5-.95-1-2.9-.4-1.7-.75-2.55-.25-.95-.95-1.7l-.1-.1q-.6-.6-1.35-.9-.55-.2-1.2-.25h-.2q-1 0-1.8.45-.5.25-.95.7l-.35.35q-1.6 2.5-.75 6.45l.2 1q.7 2.8.6 3.35.15 2.7-3.2 2.15-.7-.15-2.6-.85l-1.6-.8q-.75-.4-1.65-.45h-.2q-1.35 0-2.4.8-.5.35-.9.9-.75 1.05-.75 2.35v.35q.1 1.45 1.2 2.55l.35.35q1.05.9 3 2.45 2.15 1.7 3.45 3.2.15.15.6 1.25.55 3.3 2.65 6.3 1.1 1.55 2.45 2.75l.1.1q.05 0 .1.1.4.25.75.55.35.2.65.45.1.05.2.15.1 0 .15.1.1 0 .15.05 2.35 1.35 5.2 1.85 4.75.8 8.8-1.15 3.65-1.7 6.55-5.3.65-.85 1.3-1.45.95-.95 2-1.35 1.05-.4 2.35-.5 1.35-.15 1.9-.15 1.55-.1 1.8-.1 1.6 0 2.75-1.15t1.15-2.75q0-1.6-1.15-2.75-1.1-1.1-2.6-1.15l-3.6-.5q-1.45-.3-1.15-.75.35-.45 3.25-2.1 2.35-1.35 3.05-3 .5-.9.5-2z"
                    id="BodHan010__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.5 7.75q0-3.2-2.25-5.45Q11.6.65 9.45.2 9.15.15 8.9.1 8.65.05 8.4.05q-.15-.05-.35 0Q7.9 0 7.7.05h-.4Q6.6.1 6.05.2 5.9.25 5.7.3q-1.95.45-3.45 2Q0 4.55 0 7.75t2.25 5.45q1.7 1.7 3.95 2.1l1.5.15h.1l.75-.05q1.15-.1 2.15-.5.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45M12.45 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-.85.9-1.85 1.4-1 .45-2.05.55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-2-1.95-1.95-4.7Q1.05 4.95 3.05 3q1.45-1.45 3.3-1.8.5-.1 1-.15H7.8q.4 0 .85.1 2.15.25 3.8 1.85z"
                    id="BodHan010__col1d_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan010__Symbol_23_0_Layer0_0_FILL"
                transform="translate(105.05 271.2)"
                id="BodHan010__col1n"
            />
            <use
                xlinkHref="#BodHan010__col1d_0_Layer0_0_FILL"
                transform="translate(124.2 283.85)"
                id="BodHan010__col1d"
            />
        </g></g>
    )
}

export default BodHan010
