import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMak001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M61.75 15.35q-.2 1.3-.25 1.55-.75 3.1-1.85 5.85-.45 1.2-1 2.3-1.404 2.712-3.35 5.2-1.115 1.411-2.4 2.75-1.75 1.8-3.55 3.25l-.1.1q-7 5.4-15.65 6-10.9.85-18.1-6.6-.3-.3-.55-.6-2.064-2.267-3.45-4.9-.615-1.258-1.1-2.6-.3-.75-.5-1.55-.55-2.05-.75-4.2-.25-2.35-.05-4.95 0-.25.05-.5l.05-.55q.35-2.75 1.1-5.35.75-2.45 1.85-4.75v-.05q.05-.2.15-.35.6-1.25 1.3-2.55.1-.15 1-1.7t-.5.35q-1.45 1.9-1.9 2.55-.5.6-.6.8-.75 1.15-1.5 2.4-1.9 3.25-6.9 2.2.4.4.9.7 1.6 1.1 3.55 1.35-2.25 2.9-6.3 2.65.7 1.2 5.2 1.7-2.3 1.7-6.55 1.1.4.25 1.1.85 1.6 1.35 5.85 3.5-.25.05-.45.2-1.95 1-6.45-.2 3.15 1.15 5.35 3.4 2.2 2.2 2.7 2.75-1-.35-2.45-.7-1.55-.4-2.1-.25.3.15.75.35 1.449.724 3.7 3.4 1.021 1.203 2.2 2.8 2.05 3.35 5.3 6.45 1.95 1.7 4.3 2.95.95.5 2 .95l1.05.4q5.25 2 10.45 1.75 12-1.1 19.1-8.8.15-.2.35-.4 2.486-2.728 4.45-6.1.877-1.51 1.65-3.15.85-2.1 1.55-4.35.85-3 1.3-6.3l.05-1.1m60.6 2.45q.7-.6 1.1-.85-4.25.6-6.55-1.1 4.5-.5 5.2-1.8-4.05.35-6.3-2.55 1.95-.25 3.55-1.35.5-.3.9-.7-5 1.05-6.9-2.2-.75-1.25-1.5-2.4-.1-.2-.6-.85-.5-.7-2.2-2.85-1.75-2.15-.5-.3 1.25 1.85 1.3 2 .7 1.3 1.3 2.55.1.15.15.35v.05q1.1 2.3 1.85 4.75.75 2.6 1.1 5.35l.05.55q.05.25.05.5.2 2.6-.05 4.95-.2 2.15-.75 4.2-.2.8-.5 1.55-.485 1.342-1.15 2.6-1.336 2.633-3.4 4.9-.25.3-.55.6-7.2 7.45-18.1 6.6-8.65-.6-15.65-6l-.1-.1Q72.3 34.8 70.55 33q-1.285-1.339-2.4-2.75-1.946-2.488-3.35-5.2-.55-1.1-1-2.3-1.1-2.75-1.85-5.85-.05-.25-.2-1.55v1.1q.5 3.3 1.3 6.3.7 2.25 1.55 4.35.773 1.64 1.65 3.15 1.964 3.372 4.45 6.1.2.2.35.4 7.1 7.7 19.1 8.8 5.2.25 10.45-1.75l1.05-.4q1.05-.45 2-.95 2.35-1.25 4.3-2.95 3.25-3.1 5.3-6.45 1.179-1.597 2.2-2.8 2.251-2.676 3.7-3.4.45-.2.75-.35-.55-.15-2.1.25-1.45.35-2.45.7.5-.55 2.7-2.75 2.2-2.25 5.35-3.4-4.5 1.2-6.45.2-.2-.15-.45-.2 4.25-2.15 5.85-3.5z"
                    id="SkiMak001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <g id="SkiMak001__col1n" transform="translate(130.8 148.95)">
                <use
                    xlinkHref="#SkiMak001__Symbol_1_0_Layer0_0_FILL"
                    id="SkiMak001__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default SkiMak001
