import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M198.05 201q-2.1 2.3-4.3 4.55l22.9 6.75-7.8-26.2q-4.55 7.8-10.8 14.9M45.4 190.75q-1.5-2.25-2.85-4.55l-7.8 26.1 22.85-6.7q-7-7.1-12.2-14.85m135-140.3h-.05l-2.2-36-31.4 23.35L125.7 0l-21.05 37.8-31.4-23.35-2.2 36-35.5-10.05 7.6 32.2-32.75 4.95 17.9 27.3L0 124.25l24.85 15.8-16 31.35 28.45 4.4h.05q-3.15-13-3.9-29.7-.05-1.05-.05-2.05l22.9-15.95-3.55-30.55 31.4 12.15 17.3-24.05 24.25 24.2L150 85.2l17.25 24.5 31.4-12.1-3.55 30.5 22.95 16.5q-.1 1.5-.15 3-.05.65-.1 1.35-.85 14.9-3.7 26.75-.05.05-.05.1l28.5-4.4-16.45-31.05 25.3-16.1-28.3-19.4 17.9-27.3-32.75-4.95 7.6-32.2-35.45 10.05z"
                    id="HaiSpe017__Symbol_149_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M28.3 104.85L0 124.25 31.55 109l-5.75-8 2.5 3.85m-.85 36.85l-2.6-1.65-16 31.35 18.6-29.7m5.9 1.6q0 .35.05.75l22.9-15.95-23 13.85q0 .7.05 1.35m37.7-92.85L35.55 40.4 70.8 55.6l.35-6.2-.1 1.05M44 77.55l-1-4.9-32.6 4.9H44M125.7 0l-21.05 37.8-31.4-23.35L105.2 42.9 125.7 0m21.05 37.8L125.7 0l20 42.9 32.45-28.45-31.4 23.35m79.35 102.55l-2.15 1.35 18.6 29.7-16.45-31.05m-8.05 4.25q0-1.3.05-2.65l-23-13.85 22.95 16.5m5.05-39.75l-2.45 3.95 30.75 15.45-28.3-19.4m-16-27.35l33.9.05-32.75-4.95-1.15 4.9M52.45 94.95l.3 2.6 31.4 12.15-31.7-14.75m45.65-8.3q-2.2 3-3.95 6.05t-1.85 3.2q-1.35 2.15-2.75 4.5-2.65 4.4-5.4 9.3l17.3-24.05 24.25 24.2-23.8-29.15q-1.65 2.9-3.8 5.95m66.75 18.85l-.8-1.4q-.2-.35-.4-.65l-13.15-23.5-24.8 29.9L150 85.2l17.25 24.5-2.4-4.2m33.8-7.9l.3-2.65-31.7 14.75 31.4-12.1M180.4 50.45l.4 5.6 35.05-15.65-35.45 10.05z"
                    id="HaiSpe017__Symbol_87_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe017__Symbol_149_0_Layer0_0_FILL"
                transform="translate(66.8 15.35)"
                id="HaiSpe017__col1n"
            />
            <use
                xlinkHref="#HaiSpe017__Symbol_87_0_Layer0_0_FILL"
                transform="translate(66.8 15.35)"
                id="HaiSpe017__col1d"
            />
        </g></g>
    )
}

export default HaiSpe017
