import { AssetSvgProps } from "../../../shared/assets"

function ShiMot042({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="red"
                    d="M159.2 269.8v12.15q34.248 4.59 66.5 0V269.8q-33.25-2.867-66.5 0z"
                    id="ShiMot042__Layer5_0_FILL"
                />
                <g id="ShiMot042__Layer5_1_FILL">
                    <path
                        fill="#FFCE00"
                        d="M159.2 281.95v11.85h66.5v-11.85h-66.5z"
                    />
                    <path d="M159.2 258v11.8h66.5V258h-66.5z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot042__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot042__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot042
