import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF0000"
}

function ObjFoo054({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M10.1 1.55L5.1.1H4.95Q4.8.05 4.65.05 3.25-.2 2 .55q-1.4.8-1.85 2.4-.4 1.6.4 3.05.7 1.25 2.05 1.7l.3.1 5.15 1.4 2.05-7.65z"
                    id="ObjFoo054__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.2 6.45q.45-1.6-.4-3Q10 2.1 8.6 1.6h-.05l.1.05L2.3 0Q-.7 3.3.25 7.65L6.4 9.3h.15q1.45.35 2.8-.4 1.45-.85 1.85-2.45z"
                    id="ObjFoo054__Symbol_113_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo054__Symbol_112_0_Layer0_0_FILL"
                transform="translate(262.55 283.8)"
                id="ObjFoo054__col1n"
            />
            <use
                xlinkHref="#ObjFoo054__Symbol_113_0_Layer0_0_FILL"
                transform="translate(270.35 285.35)"
                id="ObjFoo054__col2n"
            />
        </g></g>
    )
}

export default ObjFoo054
