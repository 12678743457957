import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A64F00",
    "col2n": "#BFBFBF"
}

function ObjToo011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.5 34.45q.55 2.4.3 5.1-.1.75-.25 1.5l-.1.55q-.85 3.3-2.45 5l1.15 2.3h-.05l3.4 6.7 6.85-3.55-8.85-17.6m-17.05-33h-5.1Q-.05-2.6 0 4.7l10.35 20.65q3.15-.35 6.8.85 1.85.85 3.35 2.35L6.45 1.45z"
                    id="ObjToo011__Symbol_171_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 14.45l5 9.7L15.4 19v-.05l1.8-.85.8-.4q2.25-1.15 4.55-2.3l8.05-4L41.2 0 25.65 1.65 0 14.45z"
                    id="ObjToo011__Symbol_55_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo011__Symbol_171_0_Layer0_0_FILL"
                transform="translate(101.6 261)"
                id="ObjToo011__col1n"
            />
            <use
                xlinkHref="#ObjToo011__Symbol_55_0_Layer0_0_FILL"
                transform="translate(83.6 248)"
                id="ObjToo011__col2n"
            />
        </g></g>
    )
}

export default ObjToo011
