import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999",
    "col1s": "#434343",
    "col2n": "#FF98D0"
}

function PetPet009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M62.9 21.05v-.1q-.35-.4-.4-.4l-.55-.65-4.45-8.25-1.6-1.2q-.1-.55-.25-1.2-1.2-6.35-5.8-8.85-.8-.4-1.7-.4-3.5 0-3.5 3.15 0 1.1.1 1.65.05.4.15.7-.55-.9-1.5-1.95Q40.75.7 37.6.7q-2.65 0-3.9 1.05-.95.75-.95 1.9 0 1.6 1.05 3.05.45.65 1.3 1.45.3.3.6.55-1.75-.6-3.5-1-.05-.05-.15-.05-3.95-1.1-7.95-1.7Q5.95 4.1.8 13.95q-.45 1.45-.65 3-.2 1.15-.15 2.25 0 2 .7 3.8v.1q0 .1.05.15.6 3.35 2.75 5.8v.35q-.1.45-.1 1.05 0 .5.05.9.2 2.65 5.65 4.7 1.65.75 2.95.95.55.1 5.65.1h2.1q3.65 0 4.35-2.15v-.35q0-.8-.35-1.05v-.1h.1q6.05.1 13.2-1.4.1 0 .25-.05.2-.05.35-.2.05-.1.2.1l.05.1v.05q.25.6.5.95.75 1.6 1.55 3 1.2 2.15 3.4 2.1H44q1.85-.75 1.8-3.6-.05-.25-.05-.5v-.3q-.05-.1 0-.2-.05-.2 0-.35v-.05q.1.3.3.6.05.05.1.15.05 0 .05.05.35.55 1.05 1.9 1.05 1.35 2.5 1.15.163-.016.3-.05 1.45-.65 1.35-3.1-.05-1.75-.45-3.4-.15-.65-.4-1.25h.1q.6.1 1.2.25.15 0 .35.05H52q.6.1 1.1.2.15 0 .5.1.35.05 3.55.3 3.15.25 8.85-.3l-.2-.2q.1-.4.15-1.7.1-2.7.4-2.85l-3.4-3.95h-.05M40.6 3.75v.05l-.25-.2q.097.073.25.15M62.85 20.8l-.2-.2.2.25v-.05z"
                    id="PetPet009__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M18.4 32.05q.1 0 .25-.05.2-.05.35-.2.05-.1.2.1l-1.85-3.95q-2.25.4-2.8.5-3.2.55-7.1.9-4.1.35-6.2.2Q.7 30.4 0 31.3q1.6 0 3.65.85.4.15.7.25.35.45.85 1.05 6.05.1 13.2-1.4m15.1-23.8q.3.15 1.95.95.8.45 1.2.8l.55.45q-.1-.55-.25-1.2-1.2-6.35-5.8-8.85-.8-.4-1.7-.4-3.5 0-3.5 3.15 0 1.1.1 1.65.05.4.15.7.1.1.15.25.1.1.15.25.25.5.35.9.15-.05.4-.05h1.8q1.65.25 2.45.5.8.25 2 .9m12.85 19.9q-2.35-.4-5.5-.9-5.4-.85-7.45-1.15H33q-.3 0-.3.05-.8 0-1.5.05-2.85.2-3.6 1.15-.95 1.15-.55 5.55v.2q.1.3.3.6.05.05.1.15.05 0 .05.05.35.55 1.05 1.9 1.05 1.35 2.5 1.15.162-.016.3-.05 1.45-.65 1.35-3.1-.05-1.75-.45-3.4-.15-.65-.4-1.25h.1q.6.1 1.2.25.15 0 .35.05h-.2q.6.1 1.1.2.15 0 .5.1.35.05 3.55.3 3.15.25 8.85-.3l-.2-.2q-.25-.25-.45-.55-.25-.4-.3-.85z"
                    id="PetPet009__Symbol_59_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M7.45 7.65q-.55-4.6-5.2-6.6Q.85.4 0 0q0 .1.15.2Q5.9 3.6 6.5 8.05q.45 4.4-4.15 9.85-.25.35 1.05.3l1.35.1 1.65.35q.1 0 .2.05.6.15 1.1.3.4.15.75.25.35.15.55.25.05-.05.15 0h.1q-.5-.6-.85-1.05-.3-.1-.7-.25-2.05-.85-3.65-.85.7-.9 1.25-1.75 2.6-4.2 2.15-7.95z"
                    id="PetPet009__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M42.25 4.85q1.25-.6 1.75-.9.15-.1.25-.15-.7-1.8-.7-3.8-.65 1.1-2.15 2-1 .5-1.95 1.1-1.15.6-3.05 1.65-2.05 1.05-4 1.7-1.95.6-4.15 1.3-1.65.45-4.6.8-3 .3-2 .15t-.2 0q-1.2.1-4.55.25-3.4.1-8.2-.05v-.05l-1.5-.2Q3.6 8.55 0 8.4l.2.2.15.15q2.2 1.05 4.5 1.4 1.95.55 7.55.55.25 0 .4.05.1 0 1.65-.05 1.55-.05 7.75-.35Q28.35 10 36.85 7.1q.25-.15.55-.25 3.15-1.1 3.4-1.25.2-.2 1.45-.75m71.25 5.5q.9-.85.9-2.05 0-1.2-.9-2.1-.85-.85-2.05-.85-.85 0-1.55.45-.3.15-.55.4-.85.9-.85 2.1 0 .35.1.65.05.45.3.85.2.3.45.55l.2.2q.2.2.45.35.65.35 1.45.35 1.2 0 2.05-.9z"
                    id="PetPet009__Symbol_33_0_Layer0_0_FILL"
                />
                <g id="PetPet009__Layer3_0_FILL">
                    <path
                        fill="#FFF"
                        d="M116.85 348q-.013-.006-.05 0h-.1q-.054.01-.1 0-.204.06-.4.1-.043.01-.1 0-.175.072-.4.15-.136.049-.3.1-1.02.449-1.85 1.35-.4-.55-.9-1.05-1.8-1.75-4.35-1.75-2.6 0-4.35 1.75-1 .95-1.45 2.1-.4 1-.4 2.2 0 2.45 1.85 4.25 1.75 1.75 4.35 1.75 2.55 0 4.35-1.75 0-.05.05-.05.3.55.8 1.1 1.6 1.75 3.9 1.75 2.25 0 3.85-1.8v.05q.3-.35.5-.65.05-.05.05-.1.05-.05.1-.05.05-.15.15-.25.25-.5.45-.95.35-1.05.35-2.25 0-2.5-1.6-4.25-1.215-1.367-2.8-1.7-.203-.027-.4-.05-.313-.05-.65-.05h-.25l-.3.05m3.15 5.3q.55 0 .95.4.4.4.4.95 0 .55-.4.95-.4.4-.95.4-.55 0-.95-.4-.4-.4-.4-.95 0-.55.4-.95.4-.4.95-.4m-10.6-.95q.4-.4.95-.4.55 0 .95.4.4.4.4.95 0 .55-.4.95-.4.4-.95.4-.55 0-.95-.4-.4-.4-.4-.95 0-.55.4-.95z"
                    />
                    <path d="M110.35 351.95q-.55 0-.95.4-.4.4-.4.95 0 .55.4.95.4.4.95.4.55 0 .95-.4.4-.4.4-.95 0-.55-.4-.95-.4-.4-.95-.4m10.6 1.75q-.4-.4-.95-.4-.55 0-.95.4-.4.4-.4.95 0 .55.4.95.4.4.95.4.55 0 .95-.4.4-.4.4-.95 0-.55-.4-.95z" />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet009__Symbol_81_0_Layer0_0_FILL"
                transform="translate(60.55 336.35)"
                id="PetPet009__col1n"
            />
            <use
                xlinkHref="#PetPet009__Symbol_59_0_Layer0_0_FILL"
                transform="translate(79.25 336.35)"
                id="PetPet009__col1d"
            />
            <use
                xlinkHref="#PetPet009__Symbol_50_0_Layer0_0_FILL"
                transform="translate(75.2 350.3)"
                id="PetPet009__col1s"
            />
            <use
                xlinkHref="#PetPet009__Symbol_33_0_Layer0_0_FILL"
                transform="translate(17 355.55)"
                id="PetPet009__col2n"
            />
            <use xlinkHref="#PetPet009__Layer3_0_FILL" />
        </g></g>
    )
}

export default PetPet009
