import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#252525",
    "col2n": "#000000",
    "col2s": "#4F4F4F"
}
function SkiSki014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.5 95.8q0-39.7-28.05-67.75-16.2-16.2-36.3-23.05-14.7-5-31.4-5t-31.4 5q-20.1 6.85-36.3 23.05Q0 56.1 0 95.8q0 32.25 18.5 56.85l.05.05q.6.75 1.2 1.55l1 1.2L28 163.5q0 .05.05.05l.7.7q3.5 3.4 7.15 6.4v.05l-.05-.05q-5.81 7.718-10.6 17.75-.078.183-.2.35l-.3.7q-.06.17-.15.35-.117.253-.25.5l-2.6 6.15q-1.717 4.388-3.2 9.05-3.094 9.855-5.1 21.05-.05.2-.05.4-.15 2.45.15 4.85Q15.7 245.9 31 249.35q.2.05.45.1l.05-.05q.15.05.25.05.2.6.4 1.15.4.95 1.1 1.75 0 .05.05.1v19.45q-3.9.7-7.8 2.5-.6.267-1.2.55-.94.67 0 .8h143.25q1 0 0-.6-.775-.405-1.55-.75-3.9-1.8-7.8-2.5v-19.45q.05-.05.05-.1.7-.8 1.1-1.75.2-.55.4-1.15.1 0 .25-.05l.05.05q.25-.05.45-.1 15.3-3.45 17.45-17.55.3-2.4.15-4.85 0-.2-.05-.4-3.7-20.65-11.15-36.75-4.8-10.45-11.15-18.95h-.05q0-.1-.1-.2 3.65-3 7.15-6.4l.7-.7q.05 0 .05-.05l7.25-8.05 1-1.2q.6-.8 1.2-1.55l.05-.05q18.5-24.6 18.5-56.85z"
                    id="SkiSki014__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M164.6 61.05q.3-2.4.15-4.85 0-.2-.05-.4-3.7-20.65-11.15-36.75Q148.75 8.6 142.4.1h-.05V0q-.1.05-.15.1-13.05 10.65-28.4 15.9-14.7 5-31.4 5T51 16Q35.6 10.75 22.5.05l-.05.05q-5.8 7.6-10.55 17.55-.1.2-.15.4l-.3.65q-.1.15-.2.35l-.2.5-2.65 6.2q-1.7 4.35-3.15 9Q2.1 44.6.1 55.8q-.05.2-.05.4-.15 2.45.15 4.85 1.85 12.25 13.7 16.5 1.3.45 2.7.8.5.15 1.05.25.2.05.45.1l.05-.05q.15.05.25.05.2.6.4 1.15.4.95 1.1 1.75 0 .05.05.1v19.45q-3.9.7-7.8 2.5-.6.25-1.2.6-.95.6 0 .75H154.2q1 0 0-.6-.75-.4-1.55-.75-3.9-1.8-7.8-2.5V81.7q.05-.05.05-.1.7-.8 1.1-1.75.2-.55.4-1.15.1 0 .25-.05l.05.05q.25-.05.45-.1l1-.2q14.35-3.7 16.45-17.35m-33.35-24.8q4.35 2.05 7.8 4.3l6.35 31.8q-.45.25-.9.55-.05.05-.1.05-.1.05-.2.15-1.15.75-2.45 1.45-3 1.7-6.6 3.25-21.8 9.6-52.75 9.6-30.85 0-52.75-9.6-3.4-1.5-6.25-3.05-1.6-.85-3.05-1.75l-.1-.1q-.45-.3-.85-.55l6.3-31.7q3.45-2.3 7.9-4.4 7.05-3.3 15.1-5.5 15.1-4.05 33.75-4.05 19.9 0 35.7 4.6 6.95 2.05 13.1 4.95z"
                    id="SkiSki014__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M59.4 5.15q-1.7-.6-3.45-1.05-.55-.15-1.05-.3-.15-.05-.25-.05-10.8-3-22-3.6-.4-.05-.8-.05Q31.3.05 30.7.05L24.2 0H24l-3.9.15-1.3.1Q10.25.85 5.6 1.9.9 2.9.25 3.2q-.15.05-.25.1Q8 2 16.15 1.5q.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q15.4-.65 30.05 2l4.15.7q1.15.2 2.3.45 1.75.35 3.45.75l.3.1q.1 0 .2.05.15 0 .3.05-.4-.15-.8-.25m65.1-1.85q-.1-.05-.25-.1-.65-.3-5.35-1.3Q114.25.85 105.7.25l-1.3-.1-3.9-.15h-.2l-6.5.05q-.6 0-1.15.05-.4 0-.8.05-11.2.6-22 3.6-.1 0-.25.05-.5.15-1.05.3-1.75.45-3.45 1.05-.4.1-.8.25.15-.05.3-.05.1-.05.2-.05l.3-.1q1.7-.4 3.45-.75 1.15-.25 2.3-.45L75 3.3q14.65-2.65 30.05-2h.3q.15 0 .35.05l2 .1q.35 0 .65.05 8.15.5 16.15 1.8z"
                    id="SkiSki014__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M8.25 35.05q-.05.05-.05.1L3.9 57q-.45 2.1-.85 4.05l-.1.6q-.2.8-.35 1.6Q1.1 70.6.3 75.4q-.75 4.4.45-.6.4-2.5 1.45-6.25.1-.6.25-1.25.7-3.15 1.3-5.65.65-3.05 1.15-5.1h.05l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85l3.25-16.8.05-.05q0-.25-.1-.4-.1-.2-.3-.2-.2-.05-.3 0-.05 0-.05.05-.05 0-.1.05t-.1.2M99.2 86.4q-.75.05-1.5.15-13.1 1.2-26.3 1.45-4.5.1-9 .1-17-.1-34.1-1.8-.65-.1-1.2-.15 5.8 1.2 11.9 2Q50.25 89.7 62.4 90h.55v.1l1.4 12.2v-.4l.05.4 1.4-12.2V90h.05q.15 0 .35.05l5.2-.2q9.75-.45 18.75-1.7 5.8-.8 11.25-1.95-.15 0-.3.05-.95.05-1.9.15m21.25-51.1v-.1q-.1-.2-.2-.25l-.05-.05q-.15-.05-.35 0-.2 0-.3.2-.1.15-.05.4l3.25 16.85q.1.2 1.05 4.3v.05q.45 2 1.1 4.95.55 2.4 1.2 5.4l.1.4q.25 1.2.5 2.25 1 3.35 1.35 5.75 1.05 4.3.3.1-.9-5.3-2.65-13.9-.4-2.15-.9-4.5l-4.35-21.85m3.2-35.3l-1.15.85q-1.35 1.05-2.8 2.1l-1.55 1.15q-20.4 14.1-45.85 16l-1.1.05q-3.35.2-6.8.2h-.2q-3.45 0-6.8-.2l-1.1-.05q-25.45-1.9-45.85-16L8.9 2.95Q7.45 1.9 6.1.85L4.95 0Q15 8.75 26.45 14.05 44.4 21.9 64.2 21.9h.2q19.8 0 37.75-7.85 11.5-5.3 21.5-14.05z"
                    id="SkiSki014__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiSki014__Symbol_10_0_Layer0_0_FILL"
                transform="translate(96.75 58)"
                id="SkiSki014__col1n"
            />
            <use
                xlinkHref="#SkiSki014__Symbol_15_0_Layer0_0_FILL"
                transform="translate(110.1 228.75)"
                id="SkiSki014__col2n"
            />
            <use
                xlinkHref="#SkiSki014__Symbol_14_0_Layer0_0_FILL"
                transform="translate(130.25 326.5)"
                id="SkiSki014__col2d"
            />
            <use
                xlinkHref="#SkiSki014__Symbol_12_0_Layer0_0_FILL"
                transform="translate(128.2 229.15)"
                id="SkiSki014__col2s"
            />
        </g></g>
    )
}
export default SkiSki014
