import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.65 11q0-3.2-2.25-5.45-1.65-1.65-3.8-2.1-.3-.05-.55-.1-.25-.05-.5-.05-.15-.05-.35 0-.15-.05-.35 0h-.4q-.7.05-1.25.15-.15.05-.35.1Q20.7-.05 15.1 0 9.35-.05 5.2 3.65q-.05.05-.1.15-.2.1-.35.25-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3Q0 9.4 0 15q0 6.2 4.45 10.6Q8.85 30 15.1 30q2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.25-3.25 4.1-7.45.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45z"
                    id="BodHan016__Symbol_30_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.5 7.75q0-3.2-2.25-5.45Q11.6.65 9.45.2 9.15.15 8.9.1 8.65.05 8.4.05q-.15-.05-.35 0Q7.9 0 7.7.05h-.4Q6.6.1 6.05.2 5.9.25 5.7.3q-1.95.45-3.45 2Q0 4.55 0 7.75t2.25 5.45q1.7 1.7 3.95 2.1l1.5.15h.1l.75-.05q1.15-.1 2.15-.5.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45M12.45 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-.85.9-1.85 1.4-1 .45-2.05.55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-2-1.95-1.95-4.7Q1.05 4.95 3.05 3q1.45-1.45 3.3-1.8.5-.1 1-.15H7.8q.4 0 .85.1 2.15.25 3.8 1.85z"
                    id="BodHan016__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill="#432523"
                    d="M259.75 274.55q-.25 0-.5.05H259q-4.5.55-8.15 3.75-1.75 1.5-2.95 3.3-5 9.2-2.9 29.35 2.05 20.05 1.9 20.3-.15.25 1.2 5.4 2 5.05 5.4 5.05.6 0 1.1-.15.45 1 1.1 2.05 3.35 5.5 9.15 5.5 1.45 0 2.85-.95.3-.2.55-.4.35.9.75 1.95 1.85 3.1 5.85 3.1 5.1 0 6.6-3 1 1.8 2.15 2.85 2.95 3.55 6.8 3.6 4.75-.05 6.2-5 .1-.35.2-.75.05-.25.1-.45v-.05q.05.15-.05.55 2.65-7.75 2.4-23.05v-.2-1.75q-1.4-11.75-5.65-21.65-6.1-16.5-14.9-20.9l-2-5.8q-.25-.25-.35-.95-.05-.1-.05-.2-.05-.35-.25-.85l-.4-.5-.45-.25h-12.5q-.95.05-1.05 0h-1.15q-.4 0-.75.05z"
                    id="BodHan016__Layer1_0_FILL"
                />
                <g id="BodHan016__Layer1_1_FILL">
                    <path
                        fill="#8C4F48"
                        d="M259.25 274.6H259v.05q-.05.3-5.2 3.2-2.25 1.25-3.35 3.75-.75 1.55-.75 7.5 0 3.1.15 4.25.05.4.8 7.35.1 1.05.15 1.7.1 10.15 1.4 19.1.9 5.95 3.1 10.95.25.65.55 1.3.15.25.3.55.3.6.65 1.15.75 1.3 1.6 2.35.6.7 1.2 1.3l.5.4q.75.65 1.6 1.1 1.9 1 4.05 1.05h.1q.45-.05.95-.05 1.2-.05 2.25-.25.3-.05.65-.15l.4-.1q.6-.2 1.15-.45.8-.35 1.3-.75.05 1.2.3 2.3.05.35.15.65 1.05 4.2 4.1 5.3l.8.2q.7.15 1.5.15.7 0 1.3-.15.1 0 .2-.05.6-.15 1.15-.4 0-.05.05-.05.65-.4 1.25-.95.45-.5.85-1.05 0-.05.05-.1.05-.1.1-.15.35-.55.6-1 .2.95.55 2.15v.25l.05.2q2.6 6.35 7.8 5.45.45-.1 1-.25 1.8-.7 2.35-1.65l-.1.55q.1-.35.2-.75.05-.25.1-.45v-.05q.05.15-.05.55 2.65-7.75 2.4-23.05v-.2-1.75q-1.4-11.75-5.65-21.65-6.1-16.5-14.9-20.9-2.15-1.1-4.45-1.45-.2-.05-.35-.05-.3-.05-.55-.1-1.65-.25-3.3-.05-.55.05-1.05.15-.05 0-.15.05-1.5-1.5 0-3 .1-.05.15 0 1.45.05 2.6.05.25-.05.55-.05 1.5-.1 2.45-.4 1.4-.5 1.75-1.9-.05-.1-.05-.2-.05-.35-.25-.85l-.4-.5-.45-.25h-12.5q-.95.05-1.05 0h-1.15q-.4 0-.75.05-.25 0-.5.05m17.65 12.5h.25l.15.15q-.3-.05-.4-.15m14.7 27.7l.7 2.5q1.35 5.35 1.8 9.95.05 1 .1 1.95.2 3.8-.35 7-.2 1.15-.45 2.25-.9 3.2-2.6 5.75l-1.45-.15q-.45-.1-.8-.15-10.05-2.55-20.1-7.95-1.2-1.15-1.65-2.8.4-2.75.6-5.5.1-1 .15-1.95.25-4.1.15-8.3 0-1.15-.05-2.25-.25-4.25-.8-8.6-.15-1.05-.3-2.05l-.6-3.6q.15-1.75 2.35-1.75 10.1 0 17.15 5.4.2.2.45.4 2.3 1.85 3.85 4 1.05 3 1.85 5.85z"
                    />
                    <path
                        fill="#653434"
                        d="M294.1 327.25q-.45-4.6-1.8-9.95l-25.45-10.75q.55 4.35.8 8.6l26.45 12.1m-.25 8.95q.55-3.2.35-7l-26.5-11.8q.1 4.2-.15 8.3l26.3 10.5m-4.5 7.85l1.45.15q1.7-2.55 2.6-5.75l-26-10.8q-.2 2.75-.6 5.5.45 1.65 1.65 2.8 10.05 5.4 20.1 7.95.35.05.8.15m-3.45-39.1q-.25-.2-.45-.4-7.05-5.4-17.15-5.4-2.2 0-2.35 1.75l.6 3.6 25.05 10.3q-.8-2.85-1.85-5.85-1.55-2.15-3.85-4z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#BodHan016__Symbol_30_0_Layer0_0_FILL"
                transform="translate(105.05 280.6)"
                id="BodHan016__col1n"
            />
            <use
                xlinkHref="#BodHan016__Symbol_36_0_Layer0_0_FILL"
                transform="translate(124.2 283.85)"
                id="BodHan016__col1d"
            />
            <use xlinkHref="#BodHan016__Layer1_0_FILL" />
            <use xlinkHref="#BodHan016__Layer1_1_FILL" />
        </g></g>
    )
}

export default BodHan016
