import { Item } from "./assets"
// @ts-expect-error
import { unzipurl } from "zipurl"

export const saveItems = (items: Item[]) => {
    localStorage.setItem("sp-items", JSON.stringify(items))
}

export const getDefaultCharacterItems = () => {
    const defaultCharacter = `[{"slug":"ski_ski001","options":["move","rota","leve","col1"],"label":{"en":"standard body","de":"Standardkörper"},"zIndex":30,"layerId":1,"props":{"colors":{"col1d":"#6486A6","col1n":"#8FAFCD","col1s":"#49657E"}}},{"slug":"leg_pan001","options":["move","rota","leve","col1"],"label":{"en":"standard pants","de":"Standardhose"},"zIndex":45,"layerId":25,"props":{"colors":{"col1n":"#C1D2E2","col1l":"#E1E8EE","col1d":"#8FAFCD","col1s":"#6486A6"}}},{"slug":"leg_sho001","options":["move","rota","leve","col1"],"label":{"en":"standard shoes","de":"Standardschuhe"},"zIndex":46,"layerId":26,"props":{"colors":{"col1n":"#000000"}}},{"slug":"shi_sho020","options":["move","rota","leve","col1"],"label":{"en":"standard t-shirt","de":"Standard-T-Shirt"},"zIndex":50,"layerId":23,"props":{"colors":{"col1n":"#4C657B","col1l":"#6486A6","col1d":"#2A3843","col1s":"#1B262E"}}},{"slug":"bod_han001","options":["move","rota","leve","col1"],"label":{"en":"standard hands","de":"Standardhände"},"zIndex":70,"layerId":11,"props":{"colors":{"col1d":"#6486A6","col1n":"#8FAFCD"}}},{"slug":"fac_mou001","options":["move","rota","leve","col1"],"label":{"en":"mouth (smiling)","de":"Mund (lächelnd)"},"zIndex":77,"layerId":6,"props":{"colors":{"col1n":"#000000"}}},{"slug":"fac_eye001","options":["move","rota","leve","col1","col2"],"label":{"en":"standard eyes","de":"Standardaugen"},"zIndex":79,"layerId":7,"props":{"colors":{"col1n":"#FFFFFF","col2n":"#000000"}}}]`
    return JSON.parse(defaultCharacter) as Item[]
}

export const getSavedItems = () => {
    if (window.location.hash) {
        const configString = window.location.hash.substring(1)
        let hash
        try {
            hash = unzipurl(configString)
        } catch (ex) {
            hash = decodeURIComponent(configString)
        }
        return JSON.parse(hash) as Item[]
    }

    const fromStorage = localStorage.getItem("sp-items")

    if (fromStorage) {
        return JSON.parse(fromStorage) as Item[]
    }

    try {
        return getDefaultCharacterItems()
    } catch (ex) {}

    return null
}

export const clearItems = () => {
    localStorage.removeItem("sp-items")
}

export const defaultItems = []
