import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1l": "#999999",
    "col1n": "#666666",
    "col2n": "#252525"
}

function ObjMor040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.65 55.2l-.55-2.95q-7 .7-10.95 1.2-3.5-.6-8.25-1.2l-.55 2.95-.05.05v.4q0 1.8 3.05 3.05 2.8 1.2 6.65 1.3H55.95q3.9-.1 6.7-1.3 3.05-1.25 3.05-3.05 0-.2-.05-.4v-.05m-45.2.05l-.05-.05-.55-2.95q-.75.85-5.1.8-3-.1-4.55.1-3.55-.45-9.55-.9l-.6 2.95v.05q-.05.2-.05.4 0 1.8 3.1 3.05 2.8 1.2 6.7 1.3h.9q3.9-.1 6.7-1.3 3.05-1.25 3.05-3.05v-.4M1.65 37.7v.05q-.2 1.85.75 2.35.35.2.8.35h.05q4.1 1.65 12.6 1.65.25-.05.4-.05.75-.05 1.5-.2 3.7-.4 6.4-1.75 1.05-.65 1.6-1.35H24.4q-1.1.15-8 1.35-1.3.2-2.2.35-5.4-.95-8.75-1.35-3.65-.5-3.8-1.4m24.6-22.05V14.2q-5.45.9-8.05 1-2.65.05-3.05.05-6.45 0-8.65-.3t-2.3-.75l-.15 1.1q.021.016.05 0l-.05.35q.75.55 1.75.85.7.25 1.6.4 1.65.25 3.7.4.9.05 1.95.05 1.05.05 2.15.05 4.55 0 7.8-.5 3.05-.5 3.25-1.25m1.1-15.6q-4.2.4-6.45.7-3.15-.3-6.8-.75v7.7q0 .05.05.1.2.25 1.35.45.25.05.55.1.2.05.35.05l.05.05q.15.1.85.55.1.05.65.5.7.4 1.35.4l2.35-.1q1.05-.3 2.7-.65.9-.25 1.15-.8.15 0 .3-.05 1.4-.25 1.55-.55V.05m26.85 40.4q-.35.05-.65.15-.4-.1-.85-.15-7.2-1.3-11.4-1.7h-1.35q.55.7 1.65 1.35 2.7 1.35 6.35 1.75.75.15 1.55.2.15 0 .35.05 8.55 0 12.65-1.65h.05q.4-.15.8-.35.95-.5.7-2.35-5.6 1.8-9.85 2.7M51.65 7.7V0q-4.05.5-6.4.8-3.75-.35-6.9-.75v7.7l.05.05v.05q.3.25 1.55.45.15.05.3.05.25.05.55.1.2.1.45.2.65.3 1.95.75h1.65q3.15-.25 4.2-.55.6-.2.9-.55.15 0 .3-.05 1.15-.2 1.35-.45.05-.05.05-.1m10 7.95l-.05-.35h.05l-.1-1.1q-.1.45-4.85.75-4.1.25-6.05.45-1.4-.15-4.3-.25-6.05-.25-6.85-.95v1.45q.15.75 3.25 1.25 3.2.5 7.8.5 1.1 0 2.15-.05 1 0 1.95-.05 2-.15 3.65-.4.95-.15 1.6-.4 1.05-.3 1.75-.85z"
                    id="ObjMor040__Symbol_210_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M21.3 14.75l-.6-.1q-.184-.198-.7-.35-.05 0-1.15-.15-1.8-.55-2.35-.65-2.15-.45-2.75-.6l-2.3-1.25q-.3 0-.85.75-.234.298-.4.5-.45.086-.45.5v.05h-.9q-1.05.05-1.95.15-2.05.1-3.7.35-.9.15-1.5.3-1.55.5-1.65.9L0 15.3q.1.45 1.6.85.7.25 1.6.4 1.65.25 3.7.4.9.05 1.95.05 1.05.05 2.15.05 4.55 0 7.8-.5 3.05-.5 3.25-1.25v-.05q0-.1-.05-.2-.1-.1-.35-.25l-.3-.2q-.05.1-.05.15M23.15.9q-.2-.35-1.9-.6-1.95-.3-4.7-.3t-4.7.3q-1.8.25-1.95.65v.15q.15.4 1.95.65 1.95.3 4.7.3t4.7-.3q1.7-.25 1.9-.6V.9M46.5 11.5q-1.6 0-3.9.5-1.95.4-1.95.5-1.75.65-2.75 1.1-1.604.688-1.75 1.25-.1-.1-.15-.25-.15.1-.25.15-.3.2-.4.3-.05.1-.05.2v.05q.15.75 3.25 1.25 3.2.5 7.8.5 1.1 0 2.15-.05 1 0 1.95-.05 2-.15 3.65-.4.95-.15 1.6-.4 1.55-.4 1.65-.85l-.05-.15q-.15-.4-1.65-.9-.65-.15-1.55-.3-1.65-.25-3.65-.35-.95-.1-1.95-.15h-.85q.05-.142.05-.35 0-.3-.1-.5v-.05q-.3-1.05-1.1-1.05M45.55.3q-2-.3-4.75-.3-2.8 0-4.75.3-1.7.25-1.9.6v.25q.2.35 1.9.6 1.95.3 4.75.3 2.75 0 4.75-.3 1.7-.25 1.9-.65V.95q-.2-.4-1.9-.65z"
                    id="ObjMor040__Symbol_211_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.6 33.8q-.15 0-.4.05-8.5 0-12.6-1.65h-.05L0 44q.75.85 2.45 1.6 2.8 1.15 6.7 1.25h.9q3.9-.1 6.7-1.25 1.7-.75 2.45-1.6l-2.1-10.4q-.75.15-1.5.2m10-26.75l-.1.2q-.5.6-3.15 1.05-3.25.5-7.8.5-1.1 0-2.15-.05-1.05 0-1.95-.05-2.05-.15-3.7-.4-.9-.15-1.6-.4-1-.3-1.75-.85L1 29.45q.15.9.75 1.2.4.2.85.4 4.1 1.65 12.6 1.6.25-.05.4-.05 4.7-.35 7.9-1.95l.2-.1q.6-.2 1.8-1L25.6 13h-.05q0-5.05.05-5.05v-.9m-.7-1.8q.15-.25.2-.65 0-.05.05-.05V.05Q25 .1 24.8.1q-1.95.3-4.7.3T15.4.1q-.3-.05-.55-.1v1.9q-.2.1-.4.35-.25.3-.25.6 0 .25.2.6.2.3.45.45v.65q-.05 1.2 1.3 1.45h.1q1.5.25 3.6.25t3-.1q.85-.1 1.45-.3.4-.1.6-.6m24.3 28.6q-.2-.05-.35-.05-.8-.05-1.55-.2L45.25 44q.75.85 2.45 1.6 2.8 1.15 6.65 1.25H55.3q3.9-.1 6.7-1.25 1.65-.75 2.45-1.6L61.9 32.2h-.05q-4.1 1.65-12.65 1.65m.4-32.1V0l-.5.1q-2 .3-4.75.3-2.8 0-4.75-.3-.15 0-.3-.05v4.6q.1.35.25.6.25.5.55.6.6.2 1.5.3.9.1 2.95.1 2.1 0 3.6-.25h.15q1.35-.25 1.3-1.45v-.5q.6-.15.6-1.2t-.6-1.1m9.65 6.15q-.65.25-1.6.4-1.65.25-3.65.4-.95.05-1.95.05-1.05.05-2.15.05-4.6 0-7.8-.5-2.7-.45-3.15-1.05-.097-.097-.1-.2v.9l.05.05v5h-.05v12.15q.05 0 .05.05v4.6l1.8.7h-.05q.15.1.3.15.05 0 .1.05 3.15 1.55 7.8 1.9.15 0 .35.05 8.55.05 12.65-1.6.4-.2.85-.4.6-.3.7-1.15v-.05L61 7.05q-.7.55-1.75.85z"
                    id="ObjMor040__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 .25l28.3 36.1H30V33.9L5.25 3.15Q2.6 1.8 0 .25M94.95 0Q92.4 1.45 89.9 2.75L64.75 33.9v2.45H66.45L94.95 0z"
                    id="ObjMor040__Symbol_212_0_Layer0_0_FILL"
                />
                <path
                    fill="#252525"
                    d="M185.85 261.05q0-.15-1.6-.35-1.65-.1-3.85-.1-2.3 0-3.9.1-1.6.2-1.6.35 0 .2 1.6.4 1.6.1 3.9.1 2.2 0 3.85-.1 1.6-.2 1.6-.4m14.95-.35q-1.6.2-1.6.35 0 .2 1.6.4 1.6.1 3.85.1 2.3 0 3.9-.1 1.6-.2 1.6-.4 0-.15-1.6-.35-1.6-.1-3.9-.1-2.25 0-3.85.1z"
                    id="ObjMor040__Layer1_0_MEMBER_0_FILL"
                />
                <g id="ObjMor040__Layer1_0_FILL">
                    <path
                        fill="#1E1E1E"
                        d="M195.05 300.55q.1-.05.15-.1l-.7.3q.3-.1.55-.2z"
                    />
                    <path
                        fill="#252525"
                        d="M199.6 270.3h-2q-1.6-.05-2.5-.4-1.2-.75-2.45-.8-1.3-.05-2.65.8-.85.35-2.5.4h-2.05v3.1h3.45q.85-.05 1.25.3v.95q-.2 0-.4.05-.4.05-.75.1-.45.05-.75.15-.2.05-.3.15-.45.15-.45.3v2h-1.6q-2.6 1.25-6.25 1.45h.1v3.6H187q1.6.05 3.15.3v11.85h-4.25q-2.4 1.3-6.25 1.65v3.7h5.75l.1-.05q3.1.1 4.2.6.05 0 .1.05.2.05.45.15.9.4 2.2.4h.2q1-.05 1.75-.3.05 0 .1-.05l.7-.3q1.1-.4 2.35-.5h7.85v-3.7q-3.9-.4-6.25-1.65h-4.3v-11.85q1.15-.25 2.7-.3h7.75v-3.6q-4-.25-6.15-1.45h-1.6v-1.9-.1q-.05-.2-.4-.3-.15-.1-.3-.15-.3-.1-.75-.15-.6-.1-1.25-.15v-.85q.35-.35 1.05-.4h3.7v-3.1z"
                    />
                </g>
                <g id="ObjMor040__Layer1_0_MEMBER_1_FILL">
                    <path
                        fill="#121212"
                        d="M190.15 295.95v-1.35h-4.25q-2.4 1.3-6.25 1.65h7.85q1.3.2 2.25.5.4.1.7.25.8.25 1.75.25h.45q.7-.05 1.25-.25.25-.1.55-.2 1.35-.35 3.1-.5v-.05h7.85q-3.9-.4-6.25-1.65h-4.3v1.35q-1.1.5-2.2.55-1.25 0-2.5-.55m-2.65-18.55h-1.6q-2.6 1.25-6.25 1.45h7.85v-1.45m10.05-1.9v-.1q-.05-.2-.4-.3-.15-.1-.3-.15-.3-.1-.75-.15-.6-.1-1.25-.15v.75q-1 .1-2.2.1h-.15q-1.3 0-2.35-.1v-.75q-.2 0-.4.05-.4.05-.75.1-.45.05-.75.15-.2.05-.3.15-.45.15-.45.3.005.153.15.25.35.25 1.35.4.5.1 1.15.15 1.05.1 2.4.1h.1q1.2 0 2.2-.1.65-.05 1.25-.15 1-.15 1.3-.4.1-.05.15-.15m1.6 1.9h-1.6v1.45h7.75q-4-.25-6.15-1.45m-13.7-7.1v.75h2.05q1.05-.05 1.85-.55 1.65-.75 3.3-.75 1.55 0 3.1.75.8.5 1.85.55h2v-.75h-2q-1.6-.05-2.5-.4-1.2-.75-2.45-.8-1.3-.05-2.65.8-.85.35-2.5.4h-2.05z"
                    />
                    <path
                        fill="#434343"
                        d="M197.55 277.4v-1.9q-.05.1-.15.15-.3.25-1.3.4-.6.1-1.25.15-1 .1-2.2.1h-.1q-1.35 0-2.4-.1-.65-.05-1.15-.15-1-.15-1.35-.4-.145-.097-.15-.25v3.45q1.3.35 3.2.55 1 .1 1.95.1.85 0 1.7-.1 1.85-.2 3.2-.55v-1.45z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor040__Symbol_210_0_Layer0_0_FILL"
                transform="translate(159.65 261.2)"
                id="ObjMor040__col1n"
            />
            <use
                xlinkHref="#ObjMor040__Symbol_211_0_Layer0_0_FILL"
                transform="translate(163.85 260.1)"
                id="ObjMor040__col1l"
            />
            <use
                xlinkHref="#ObjMor040__Symbol_216_0_Layer0_0_FILL"
                transform="translate(160.3 269.45)"
                id="ObjMor040__col1d"
            />
            <use
                xlinkHref="#ObjMor040__Symbol_212_0_Layer0_0_FILL"
                transform="translate(145.15 237.2)"
                id="ObjMor040__col2n"
            />
            <use xlinkHref="#ObjMor040__Layer1_0_FILL" />
            <use xlinkHref="#ObjMor040__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor040__Layer1_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ObjMor040
