import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M97.35 12.3q0-3.35-3.3-4.95Q91.8 6.15 89.6 2q-2 .75-5.95 2.45V2.5l-8.9 3L78 0q-.95.6-4.2 2.5-1.4.8-3.15 1.9-1 .55-1.7 1.05v-3.2h-.5q-3.05 1.6-7.4 4.55-.048 0-.05.05v-3.1q-3.3 2.05-5 6.55V2.5q-.1 1.4-2.3 4-2 2.4-1.7 4.3h-.25V2.5q-.15.4-3.5 9.55H48q-.95-4.4-1-6.3-1.15 1.9-2.75 6.3.45-2.55-.45-4.8Q42.15 3.1 42 2.5v7.9q-1.3-3.95-5.6-6.65v3.1q-.05-.05-.1-.05-4.35-2.95-7.4-4.55h-.5v3.2q-.7-.5-1.65-1.05-1.8-1.1-3.2-1.9Q20.3.6 19.35 0l3.25 5.75-8.9-3.25v1.95Q9.75 2.75 7.75 2q-2.2 4.15-4.4 5.35Q0 8.95.05 12.3q-.05.55 0 1 .8-.55 5.1-3.25Q1.6 13.7 1.5 14.3v2.25q5.05-2 7-3.25 0 1.75.25 2.25 1.9-.75 5.1-2.65 1.8-1.1 4.05-1.1.1 0 2.1 2 3.9-2 6.75-2 1.7 0 2.9 1.4 1.15 1.35 3 1.35 1.35 0 3.85-.5v2.5h.5q.85-.5 1.75-.75l4.4 1.3q.5-.05 1-.05h.65q2.2-1 2.2-2.5 1 .5 1.75.5.95 0 1.75-1.25h.5q0 .75.25 2.25.5-.3 2.1-1.5.1 0 1.1 1.5.4.6.85 1 1.9-.35 2.35-.7.95-.8 1.85-.8 2.25 0 3.75.75h1.25v-2q1 0 1.5.25h3.5V12.8q1.75.45 6.5 2.5h1.25q-.1-1.9 0-2.4.15-.6 1.75-.6 2 0 4.5 3.25h1.25v-2.5h2q.75.35 2.8 1.85 1.5 1.1 2.45 1.15 0-1.25.25-1.5 1.05-.1 2 .8 1 .95 1.75.95.6 0 1.25-.25l-4.75-6.1q4.55 2.9 4.85 3.7V12.3z"
                    id="FacBro028__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro028__Symbol_28_0_Layer0_0_FILL"
                transform="translate(143.85 120.25)"
                id="FacBro028__col1n"
            />
        </g></g>
    )
}

export default FacBro028
