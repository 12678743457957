import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFD446"
}

function AccJew051({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.35 3.4q-.9-.9-1.85-1.55Q19 .8 17.35.4q-1.1-.35-2.3-.4h-.3q-6.1 0-10.4 7Q0 13.95 0 23.8q0 9.85 4.35 16.85 4.3 6.95 10.4 6.95 6.1 0 10.4-6.95l.5-.9q.15-.15.25-.35.05-.15.15-.25Q29.5 32.6 29.5 23.8q0-9.85-4.35-16.8-1.15-1.9-2.5-3.3l-.3-.3m.9 5.15q3.6 6.25 3.6 15.1t-3.6 15.1q-3.6 6.3-8.7 6.3-3.75 0-6.75-3.4-.4-.6-.85-1.15-.55-.85-1.05-1.75-3.6-6.25-3.6-15.1 0-4.1.8-7.7.85-4.05 2.8-7.4 3.6-6.3 8.65-6.3 5.1 0 8.7 6.3z"
                    id="AccJew051__Symbol_119_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew051__Symbol_119_0_Layer0_0_FILL"
                transform="translate(270.15 177.55)"
                id="AccJew051__col1n"
            />
        </g></g>
    )
}

export default AccJew051
