import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}

function ObjMor083({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M42.35 21.15v-.4q-.15-8.5-6.2-14.55-6.2-6.2-15-6.2Q12.4 0 6.2 6.2.25 12.15 0 20.5v1q.3-.1.6-.3l2.05.95 23.7-4.3 13.7 4.55 1.7-1.35q.25.1.6.3v-.2z"
                    id="ObjMor083__Symbol_172_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M41.55 7.5q-.3-.2-.65-.35l-1.25-2.6L25.95 0 2.25 4.3.7 7.35H.65q-.35.15-.65.3l.1.4q1.25 5.8 5.7 10.25 6.2 6.2 14.95 6.2 8.8 0 15-6.2 4.5-4.55 5.75-10.45 0-.2.05-.35z"
                    id="ObjMor083__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill="#313131"
                    d="M259.35 282.6q.5 1.95 2 3.5 2.3 2.25 5.5 2.25t5.45-2.25q1.05-1.05 1.6-2.25 4.25 1 7.9 2.8l.45-3.95q-3.6-1.8-7.7-2.9-.25-2.65-2.25-4.65-2.25-2.25-5.45-2.25t-5.5 2.25q-1.45 1.45-1.9 3.3-10.6.4-18.35 4.4l.45 4q7.6-3.85 17.8-4.25z"
                    id="ObjMor083__Layer4_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M262.25 280.6q0 1.05.4 1.95.35.8 1 1.45 1.4 1.4 3.45 1.4 1.95 0 3.35-1.4.35-.35.6-.75.8-1.15.8-2.65 0-.75-.2-1.4-.3-1.1-1.2-2-1.4-1.35-3.35-1.35-2.05 0-3.45 1.35-.5.55-.85 1.2-.55.95-.55 2.2z"
                    id="ObjMor083__Layer4_1_FILL"
                />
                <path
                    fill="#CCC"
                    d="M267.05 277.25q-1.4 0-2.35.95-1 1-1 2.35 0 1.4 1 2.4.95.95 2.35.95 1.4 0 2.4-.95.95-1 .95-2.4 0-1.35-.95-2.35-1-.95-2.4-.95m-1.85 3.3q0-.8.55-1.35t1.35-.55q.8 0 1.35.55t.55 1.35q0 .8-.55 1.4-.55.55-1.35.55t-1.35-.55q-.55-.6-.55-1.4z"
                    id="ObjMor083__Layer4_2_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor083__Symbol_172_0_Layer0_0_FILL"
                transform="translate(240.5 261.65)"
                id="ObjMor083__col1n"
            />
            <use
                xlinkHref="#ObjMor083__Symbol_48_0_Layer0_0_FILL"
                transform="translate(240.9 279.5)"
                id="ObjMor083__col2n"
            />
            <use xlinkHref="#ObjMor083__Layer4_0_FILL" />
            <use xlinkHref="#ObjMor083__Layer4_1_FILL" />
            <use xlinkHref="#ObjMor083__Layer4_2_FILL" />
        </g></g>
    )
}

export default ObjMor083
