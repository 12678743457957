import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M182.65 81.1q-5.4 9.5-14.35 15.05-14.85 8.85-45.15 19v17.55l-3.95 1.55q-5.45 1.85-10.95 2.9-4.45.85-9 1.1-2.05.15-4.05.15-2.05 0-4.1-.15-4.5-.25-8.95-1.1-5.55-1.05-11-2.9l-3.9-1.55v-17.55q-30.35-10.15-45.2-19Q13.1 90.6 7.7 81.1 2.6 72.25 0 60q.75 9.8 2.4 16.4.5 1.95 1.1 3.65 11.4 33.65 50.55 56.3Q67.2 144 83.6 146.7q5.8.9 11.6.9t11.55-.9q16.45-2.7 29.6-10.35 39.1-22.65 50.55-56.3.6-1.7 1.1-3.65 1.6-6.6 2.35-16.4-2.55 12.25-7.7 21.1z"
                    id="BeaChi019__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M184.5 3.65q.6-1.7 1.1-3.65-6.85 20.85-23.9 37.65-5.05 5-10.55 9.05-10.95 8.25-23.5 13-16.1 5.95-34.75 5.95h-.2q-18.7 0-34.75-5.95-12.6-4.75-23.55-13-5.45-4.05-10.5-9.05Q6.85 20.85 0 0q.5 1.95 1.1 3.65 11.4 33.65 50.55 56.3Q64.8 67.6 81.2 70.3q5.8.9 11.6.9t11.55-.9q16.45-2.7 29.6-10.35 39.1-22.65 50.55-56.3z"
                    id="BeaChi019__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi019__Symbol_58_0_Layer0_0_FILL"
                transform="translate(97.35 106.85)"
                id="BeaChi019__col1n"
            />
            <use
                xlinkHref="#BeaChi019__Symbol_17_0_Layer0_0_FILL"
                transform="translate(99.75 183.25)"
                id="BeaChi019__col1d"
            />
        </g></g>
    )
}

export default BeaChi019
