import { AssetSvgProps } from "../../../shared/assets"

function ShiMot056({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M180.5 258.05q-9.909 17.9 0 35.8h22.6q4.794-17.558 0-35.8h-22.6z"
                    id="ShiMot056__Layer5_0_FILL"
                />
                <g id="ShiMot056__Layer5_1_FILL">
                    <path fill="red" d="M225.7 293.85v-35.8h-22.6v35.8h22.6z" />
                    <path
                        fill="#008C45"
                        d="M159.2 258.05v35.8h21.3v-35.8h-21.3z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot056__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot056__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot056
