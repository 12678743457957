import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00B600"
}

function ShiMot090({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.1 26.55q1.1-3.95.6-8-.45-4.2-2.55-7.9-2.8-4.95-7.7-7.8Q26.6 0 20.9 0q-4.25 0-8.1 1.65-3.8 1.6-6.65 4.5Q3.25 9 1.65 12.8 0 16.65 0 20.9q0 5.7 2.85 10.55 2.8 4.8 7.55 7.55l6.85-11.15q-.8-.5-1.45-1.15-.45-.45-.85-.95 0-.1-.05-.15-1.2-1.75-1.2-3.95 0-2.7 1.85-4.8l.25-.3q.65-.65 1.4-1.1l.7-.35q.4-.2.9-.35h.2l.2-.1q.4-.1.9-.15l.8-.05h.6l1.45.3q.75.2 1.5.65.15.05.25.15.1.05.15.1.6.4 1.1.9 2.15 2.15 2.15 5.1 0 2.95-2.15 5.05-.65.65-1.4 1.1l6.6 11.4.05-.05q3.7-2.1 6.3-5.45 2.5-3.2 3.6-7.15z"
                    id="ShiMot090__Symbol_57_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot090__Symbol_57_0_Layer0_0_FILL"
                transform="translate(171.6 262.85)"
                id="ShiMot090__col1n"
            />
        </g></g>
    )
}

export default ShiMot090
