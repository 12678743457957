import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M54.9 28.85q0-11.95-8.1-20.4-3.25-3.45-7.1-5.5Q34.2 0 27.45 0q-6.7 0-12.2 2.95-3.9 2.05-7.2 5.5Q0 16.9 0 28.85q0 12 8.05 20.4 2.1 2.2 4.4 3.8 6.55 4.65 15 4.65 8.5 0 15.05-4.65 2.25-1.6 4.3-3.8 8.1-8.4 8.1-20.4z"
                    id="FacEye029__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9-.25 0-.5.05Q1.8.1 1 .9q-1 1-1 2.35 0 1.4 1 2.35.8.8 1.9 1 .25.05.5.05Q4.8 6.6 5.8 5.6z"
                    id="FacEye029__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye029__Symbol_72_0_Layer0_0_FILL"
                transform="translate(165.05 135.65)"
                id="FacEye029__col1n"
            />
            <use
                xlinkHref="#FacEye029__Symbol_44_0_Layer0_0_FILL"
                transform="translate(188.9 163.45)"
                id="FacEye029__col2n"
            />
        </g></g>
    )
}

export default FacEye029
