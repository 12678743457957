import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#00DE00",
    "col1n": "#00B600",
    "col2n": "#FF0000"
}

function ObjToy052({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.6 89.4q-3.75-3.65-7.5.05-.5.85-1.45 1.7L14.2 99l24.4-4.75-1.55-7.85-15.45 3m2.3-68q0-.05.05-.1L7 0 .25 25.95q.05 0 .1.05h-.1l-.25.45 1.5 7.45q.35.35.65.7 2.45 2.05 8.5-2.5l15.2-2.95-1.45-7.5-.4-.3-.1.05z"
                    id="ObjToy052__Symbol_174_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M23.7 21.3L6.75 0 0 25.95l23.7-4.65z"
                    id="ObjToy052__Symbol_175_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M31.55 71.75q-2.6-1.25-.5-2.55l-.55-2.95-13.35 2.6.6 2.95q3.2 1.3.5 2.55l4.5 23.2q3.85 1.3.5 2.55l.65 3.25 13.35-2.6-.65-3.25q-3.25-1.25-.5-2.55l-4.55-23.2M24.1 1.95L23.7 0 8.5 2.95Q2.45 7.5 0 5.45q2.05 2.6.5 5.25l2.95 15.25q6.1 6.25 2.8 14.1L9 54.25q4.6 3.05 2.95 6.05 3.75-3.7 7.5-.05l15.45-3-2.4-12.2q-6.05-7-2.85-14.75l-2.8-14.25Q21.2 9.8 24.1 1.95z"
                    id="ObjToy052__Symbol_176_0_Layer0_0_FILL"
                />
                <path
                    fill="#C69C5D"
                    d="M108.75 219.55q-3.435-9.12-4.15.8l12.95 66.05q3.25 1.25 4.9 6.8 1.05 3.65 1.15 7.65 1 6.3-1.75 7.75l5.3 27 4.1-.8-22.5-115.25z"
                    id="ObjToy052__Layer2_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M105.2 190.8l-8.2 1.65 5.5 28.25 8.25-1.65-5.55-28.25m-11.75-3.7l.5 2.55 13.35-2.6-.5-2.55-13.35 2.6m8.8-25.8l-.5-2.55-13.3 2.6.5 2.55 13.3-2.6m.95-26.7l-2.85-14.75L79.7 143.8l1.5 7.75 8.95-1.75 13.05-15.2m-29.05-19.1l2.8 14.1 20.6-24-2.75-14.1-20.65 24m5.05-23l-9.15 1.8 1.15 5.95 8-7.75z"
                    id="ObjToy052__Layer2_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy052__Symbol_174_0_Layer0_0_FILL"
                transform="translate(68.55 60.4)"
                id="ObjToy052__col1n"
            />
            <use
                xlinkHref="#ObjToy052__Symbol_175_0_Layer0_0_FILL"
                transform="translate(68.8 60.4)"
                id="ObjToy052__col1l"
            />
            <use
                xlinkHref="#ObjToy052__Symbol_176_0_Layer0_0_FILL"
                transform="translate(70.7 89.55)"
                id="ObjToy052__col2n"
            />
            <use xlinkHref="#ObjToy052__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy052__Layer2_1_FILL" />
        </g></g>
    )
}

export default ObjToy052
