import { AssetSvgProps } from "../../../shared/assets"

function ShiMot103({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot103__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#A54396"
                        d="M159.2 272.45v7.1q34.216 7.343 66.5 0v-7.1q-33.25-3.87-66.5 0z"
                    />
                    <path
                        fill="#40489D"
                        d="M159.2 286.85v7.25h66.5v-7.25q-33.64-3.706-66.5 0z"
                    />
                    <path
                        fill="#F87297"
                        d="M159.2 258v7.35q34.206 7.454 66.5 0V258h-66.5z"
                    />
                </g>
                <g id="ShiMot103__Layer1_0_MEMBER_1_FILL">
                    <path fill="#FFF" d="M159.2 265.35v7.1h66.5v-7.1h-66.5z" />
                    <path d="M159.2 279.55v7.3h66.5v-7.3h-66.5z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot103__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot103__Layer1_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ShiMot103
