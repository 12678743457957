import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1l": "#FF5D5D",
    "col1n": "#FF0000"
}

function FacEye032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M3.05 17.85q3.05 3.05 7.4 3.05 4.35 0 7.4-3.05 3.05-3.05 3.05-7.4 0-4.35-3.05-7.4Q14.8 0 10.45 0 6.1 0 3.05 3.05 0 6.1 0 10.45q0 4.35 3.05 7.4m66.5 0q3.05-3.05 3.05-7.4 0-4.35-3.05-7.4Q66.5 0 62.15 0q-4.35 0-7.4 3.05-3.05 3.05-3.05 7.4 0 4.35 3.05 7.4 3.05 3.05 7.4 3.05 4.35 0 7.4-3.05z"
                    id="FacEye032__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M7.55.9Q8.3.4 9.15.2q-.8-.15-1.6-.2H7.3Q4.85 0 2.95 1.45q-.45.25-.85.65-.7.7-1.2 1.55Q0 5.25 0 7.2q0 1.4.45 2.6.55 1.35 1.65 2.45.65.6 1.4 1.05 1.7 1.05 3.8 1.05h.25q1.2-.05 2.35-.4-1.25-.2-2.35-.85-.85-.5-1.55-1.15-.6-.6-1-1.2Q4.2 9.45 4 8q-.05-.5-.05-1 0-.8.15-1.5t.45-1.25Q5.05 3 6 2.05 6.7 1.4 7.55.9m50.1 1.15q.7-.65 1.55-1.15.75-.5 1.6-.7-.8-.15-1.6-.2h-.25Q56.5 0 54.6 1.45q-.45.25-.85.65-.7.7-1.2 1.55-.95 1.6-.95 3.55 0 1.4.5 2.6.55 1.35 1.65 2.45.65.6 1.4 1.05 1.7 1.05 3.8 1.05h.25q1.2-.05 2.35-.4-1.25-.2-2.35-.85-.85-.5-1.55-1.15-.6-.6-1-1.2-.8-1.3-1-2.75-.05-.5-.05-1 0-.8.15-1.5t.45-1.25q.5-1.25 1.45-2.2z"
                    id="FacEye032__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M25.4 6.65q-.85-1.5-2.15-2.75-1.05-1.05-2.2-1.75-.7-.5-1.5-.85Q16.85 0 13.6 0q-3.2 0-5.9 1.3-2 .9-3.65 2.6-1.35 1.25-2.2 2.75Q0 9.6 0 13.35q0 3.2 1.35 5.8.95 1.95 2.7 3.7 1.55 1.5 3.3 2.45 2.85 1.4 6.25 1.4 4 0 7.15-1.95 1.3-.8 2.5-1.9 1.95-1.95 2.9-4.3 1.1-2.35 1.1-5.2 0-3.75-1.85-6.7m-5.1.05q.95.9 1.55 1.95 1.15 2 1.15 4.5 0 1.85-.65 3.45t-2.05 3.05q-.85.8-1.7 1.3-2.15 1.3-4.85 1.3h-.2q-2.75 0-4.9-1.3-.85-.5-1.65-1.3-1.4-1.45-2.05-3.05-.65-1.6-.65-3.45 0-2.5 1.15-4.5Q6.05 7.6 7 6.7q.55-.6 1.2-1.05 2.3-1.6 5.35-1.6h.2q3.05 0 5.3 1.6.65.45 1.25 1.05m52.35-4.55q-.7-.5-1.45-.85Q68.5 0 65.25 0q-3.2 0-5.9 1.3-2.05.9-3.7 2.6-1.35 1.25-2.2 2.75-1.85 2.95-1.85 6.7 0 3.2 1.35 5.8.95 1.95 2.7 3.7 1.55 1.5 3.35 2.45 2.85 1.4 6.25 1.4 4 0 7.1-1.95 1.3-.8 2.5-1.9 1.95-1.95 2.9-4.3 1.1-2.35 1.1-5.2 0-3.75-1.85-6.7-.85-1.5-2.15-2.75-1.05-1.05-2.2-1.75M71.9 6.7q.95.9 1.55 1.95 1.15 2 1.15 4.5 0 1.85-.65 3.45t-2.05 3.05q-.85.8-1.7 1.3-2.1 1.3-4.8 1.3h-.2q-2.75 0-4.9-1.3-.85-.5-1.65-1.3-1.45-1.45-2.1-3.05-.65-1.6-.65-3.45 0-2.5 1.15-4.5.6-1.05 1.6-1.95.55-.6 1.2-1.05 2.3-1.6 5.35-1.6h.2q3.05 0 5.25 1.6.65.45 1.25 1.05z"
                    id="FacEye032__Symbol_48_0_Layer0_0_FILL"
                />
                <g id="FacEye032__Layer5_0_FILL">
                    <path
                        fill="#333"
                        d="M205.55 140.1v-.05q-3.25 1.75-5.95 4.5-2.6 2.6-4.35 5.6-.2.35-.35.65-1.55 2.85-2.35 6.05 0-.1-.05-.2-.4-1.65-1-3.2-.6-1.55-1.45-3.05-.1-.15-.15-.25-1.8-2.95-4.4-5.6-2.35-2.35-4.85-3.85-6.25-3.9-13.9-3.9-7.05 0-12.8 3.3v-.05q-3.25 1.75-6 4.5-2.7 2.65-4.4 5.75l-.3.5q-3.1 5.6-3.1 12.55 0 6.3 2.6 11.55.05.15.15.3 1.8 3.8 5.05 6.95 2.85 2.85 6.2 4.75.1.05.25.15 5.55 2.95 12.35 2.95 7.1 0 12.95-3.2l.05-.05q3-1.9 5.75-4.6 1.55-1.6 2.85-3.25.05-.1.15-.2.9-1.2 1.6-2.4.05-.05.05-.1.25-.55.5-1 .05-.15.1-.25.4-.85.75-1.7.6-1.55 1-3.15.05-.1.05-.2.65 2.6 1.85 5 .05.15.15.3 1.8 3.8 5.05 6.95 2.8 2.85 6.15 4.75.1.05.25.15 5.6 2.95 12.4 2.95 7.05 0 12.9-3.2l.05-.05q3.05-1.9 5.8-4.6 1.55-1.6 2.85-3.25.05-.1.15-.2.9-1.2 1.6-2.4.05-.05.05-.1.25-.55.5-1 .05-.15.1-.25.4-.85.75-1.7 1.75-4.6 1.75-9.9 0-5.35-1.75-9.9-.6-1.55-1.45-3.05-.1-.15-.15-.25-1.8-2.95-4.4-5.6-2.35-2.35-4.85-3.85-6.3-3.9-13.9-3.9-7.05 0-12.85 3.3m-28.75 13.5q4.15 4.2 4.15 10.1 0 5.85-4.15 10-4.15 4.2-10.05 4.2-5.85 0-10.05-4.2-4.15-4.15-4.15-10 0-5.9 4.15-10.1 4.2-4.1 10.05-4.1 5.9 0 10.05 4.1m5.8 30.6h.1l-.1.1v-.1m45.8-30.6q4.15 4.2 4.15 10.1 0 5.85-4.15 10-4.15 4.2-10.05 4.2-5.85 0-10.05-4.2-4.15-4.15-4.15-10 0-5.9 4.15-10.1 4.2-4.1 10.05-4.1 5.9 0 10.05 4.1m5.85 30.6h.1l-.1.1v-.1z"
                    />
                    <path d="M234.35 184.2h-.1v.1l.1-.1m-51.65 0h-.1v.1l.1-.1z" />
                    <path
                        fill="#FFF"
                        fillOpacity={0.302}
                        d="M218.4 157.2q.75-.5 1.6-.7-.8-.15-1.6-.2h-.25q-2.45 0-4.35 1.45-.45.25-.85.65-.7.7-1.2 1.55-.95 1.6-.95 3.55 0 1.4.5 2.6.55 1.35 1.65 2.45.65.6 1.4 1.05 1.7 1.05 3.8 1.05h.25q1.2-.05 2.35-.4-1.25-.2-2.35-.85-.85-.5-1.55-1.15-.6-.6-1-1.2-.8-1.3-1-2.75-.05-.5-.05-1 0-.8.15-1.5t.45-1.25q.5-1.25 1.45-2.2.7-.65 1.55-1.15m-51.65-.9h-.25q-2.45 0-4.35 1.45-.45.25-.85.65-.7.7-1.2 1.55-.9 1.6-.9 3.55 0 1.4.45 2.6.55 1.35 1.65 2.45.65.6 1.4 1.05 1.7 1.05 3.8 1.05h.25q1.2-.05 2.35-.4-1.25-.2-2.35-.85-.85-.5-1.55-1.15-.6-.6-1-1.2-.8-1.3-1-2.75-.05-.5-.05-1 0-.8.15-1.5t.45-1.25q.5-1.25 1.45-2.2.7-.65 1.55-1.15.75-.5 1.6-.7-.8-.15-1.6-.2z"
                    />
                </g>
                <g id="FacEye032__Layer5_1_FILL">
                    <path
                        fill="#1A1A1A"
                        d="M142.95 166h2.9q-.1-1.15-.1-2.4t.1-2.4h-2.9q-.15.95-.15 1.95v.55q0 1.2.15 2.3m75.7 18.5q-.85 0-1.65-.05v2.9h1.55q1.2 0 2.4-.1v-2.85q-1.15.1-2.3.1m23.9-21.35q0-1-.1-1.95h-2.75q.15 1.15.15 2.4t-.15 2.4h2.75q.1-1.1.1-2.3v-.55M167 184.5q-.85 0-1.65-.05v2.9h1.55q1.2 0 2.4-.1v-2.85q-1.15.1-2.3.1m27.6-23.3q-.1.6-.1 1.2l-.05.75v.55l.05.9q0 .75.1 1.4h2.9q-.1-1.15-.1-2.4t.1-2.4h-2.9m-3.8 0h-2.75q.15 1.15.15 2.4t-.15 2.4h2.75q.1-1.1.1-2.3v-.55q0-1-.1-1.95m-23.9-21.15q-.85 0-1.55.05v2.65q.8-.05 1.65-.05 1.15 0 2.3.1v-2.65q-1.2-.1-2.4-.1m54.05.1q-1.2-.1-2.4-.1-.85 0-1.55.05v2.65q.8-.05 1.65-.05 1.15 0 2.3.1v-2.65z"
                    />
                    <path d="M143.55 150.3l-.3.5q-3.1 5.6-3.1 12.55 0 6.3 2.6 11.55.05.15.15.3l2.25-1.2q-1.85-3.65-2.2-8-.15-1.1-.15-2.3v-.55q0-1 .15-1.95.5-4.65 2.65-8.65.25-.45.55-.85l-2.6-1.4m77.4 36.95q-1.2.1-2.4.1H217q-5.5-.3-10.1-2.85l-.9 2.55q5.6 2.95 12.4 2.95 7.05 0 12.9-3.2l-.8-2.55q-4.35 2.55-9.55 3m20.75-36.85l-2.4 1.3q2.65 4.3 3.15 9.5.1.95.1 1.95v.55q0 1.2-.1 2.3-.4 4.3-2.2 7.95l2.05 1.25q.05-.15.1-.25.4-.85.75-1.7 1.75-4.6 1.75-9.9 0-5.35-1.75-9.9-.6-1.55-1.45-3.05m-74.8 36.95h-1.55q-5.5-.3-10.05-2.85l-.9 2.55q5.55 2.95 12.35 2.95 7.1 0 12.95-3.2l-.8-2.55q-4.35 2.55-9.6 3-1.2.1-2.4.1m30.95-35.8l-2.6-1.4q-.2.35-.35.65-1.55 2.85-2.35 6.05 0-.1-.05-.2-.4-1.65-1-3.2-.6-1.55-1.45-3.05l-2.4 1.3q2.65 4.3 3.15 9.5.1.95.1 1.95v.55q0 1.2-.1 2.3-.4 4.3-2.2 7.95l2.05 1.25q.05-.15.1-.25.4-.85.75-1.7.6-1.55 1-3.15.05-.1.05-.2.65 2.6 1.85 5 .05.15.15.3l2.25-1.2q-.85-1.65-1.35-3.45l-.45-1.75q-.3-1.35-.4-2.8-.1-.65-.1-1.4l-.05-.9v-.55l.05-.75q0-.6.1-1.2.5-4.65 2.65-8.65l.6-1m-32.5-11.45q.7-.05 1.55-.05 1.2 0 2.4.1 5.45.55 9.9 3.2l1.45-2.65q-6.25-3.9-13.9-3.9-7.05 0-12.8 3.3l1.85 2.55q4.35-2.25 9.55-2.55m40.2 0l1.85 2.55q4.4-2.25 9.6-2.55.7-.05 1.55-.05 1.2 0 2.4.1 5.4.55 9.85 3.2l1.5-2.65q-6.3-3.9-13.9-3.9-7.05 0-12.85 3.3z" />
                    <path
                        fill="#666"
                        d="M236.5 166q-.3 2.75-1.4 5.2-1.35 2.8-3.75 5.25-1.7 1.6-3.65 2.8-3.05 1.85-6.75 2.35v2.8q4.45-.45 8.2-2.6 2.45-1.4 4.5-3.45 2.7-2.65 4.2-5.75 1.5-3.05 1.85-6.6h-3.2m-71.15 18.45v-2.8q-3.9-.25-7.15-2.05-2.25-1.25-4.15-3.15-2.35-2.3-3.65-4.95-1.25-2.55-1.6-5.5h-2.95q.4 3.6 1.95 6.7 1.55 3.05 4.2 5.65 2.25 2.2 4.8 3.65 3.85 2.15 8.55 2.45m35.1-18.45h-2.95q.25 2.1.85 4 .45 1.4 1.1 2.7 1.55 3.05 4.15 5.65 2.25 2.2 4.8 3.65 3.9 2.15 8.6 2.45v-2.8q-3.9-.25-7.2-2.05-2.25-1.25-4.15-3.15-2.35-2.3-3.65-4.95-1.2-2.55-1.55-5.5m-12.4 0h-3.2q-.3 2.75-1.4 5.2-1.35 2.8-3.7 5.25-1.7 1.6-3.65 2.8-3.05 1.85-6.8 2.35v2.8q4.5-.45 8.25-2.6 2.45-1.4 4.45-3.45 2.7-2.65 4.2-5.75 1.5-3.05 1.85-6.6m-18.75-23.2v3.35q3.85.45 7.1 2.4 1.75 1.05 3.35 2.6l.55.55q1.25 1.4 2.15 3 1.85 3 2.35 6.5h3.25q-.45-4.5-2.7-8.2-.4-.65-.85-1.3-1.1-1.5-2.5-2.9-1.85-1.85-4.05-3.15-3.9-2.4-8.65-2.85m-3.95 3.2v-3.25q-4.5.3-8.2 2.3-2.75 1.4-5.15 3.75-1.4 1.4-2.5 2.9-.75 1.05-1.35 2.15-1.85 3.4-2.3 7.35h3q.45-3.3 2.05-6.05 1.05-1.85 2.6-3.45.25-.3.55-.55 2.1-2.1 4.6-3.35 3.05-1.55 6.7-1.8m55.6-3.2v3.35q3.8.45 7.05 2.4 1.75 1.05 3.35 2.6.3.25.55.55 1.3 1.4 2.2 3 1.85 3 2.35 6.5h3.25q-.45-4.5-2.7-8.2-.4-.65-.85-1.3-1.1-1.5-2.5-2.9-1.9-1.85-4.1-3.15-3.9-2.4-8.6-2.85M217 146v-3.25q-4.5.3-8.25 2.3-2.75 1.4-5.15 3.75-1.4 1.4-2.45 2.9-.75 1.05-1.35 2.15-.9 1.6-1.45 3.4-.6 1.9-.85 3.95h3q.45-3.3 2-6.05 1.05-1.85 2.6-3.45.25-.3.55-.55 2.1-2.1 4.6-3.35 3.1-1.55 6.75-1.8z"
                    />
                    <path
                        fill="#484848"
                        d="M242.45 166h-2.75q-.35 3.55-1.85 6.6-1.5 3.1-4.2 5.75-2.05 2.05-4.5 3.45-3.75 2.15-8.2 2.6v2.85q5.2-.45 9.55-3 2.7-1.6 5.05-3.85 3.05-3 4.7-6.45 1.8-3.65 2.2-7.95m-77.1 21.35v-2.9q-4.7-.3-8.55-2.45-2.55-1.45-4.8-3.65-2.65-2.6-4.2-5.65-1.55-3.1-1.95-6.7h-2.9q.35 4.35 2.2 8 1.75 3.45 4.75 6.4 2.5 2.5 5.4 4.1 4.55 2.55 10.05 2.85M195 168.8l.45 1.75q.5 1.8 1.35 3.45 1.75 3.45 4.75 6.4 2.45 2.5 5.35 4.1 4.6 2.55 10.1 2.85v-2.9q-4.7-.3-8.6-2.45-2.55-1.45-4.8-3.65-2.6-2.6-4.15-5.65-.65-1.3-1.1-2.7-.6-1.9-.85-4h-2.9q.1 1.45.4 2.8m-4.2-2.8h-2.75q-.35 3.55-1.85 6.6-1.5 3.1-4.2 5.75-2 2.05-4.45 3.45-3.75 2.15-8.25 2.6v2.85q5.25-.45 9.6-3 2.65-1.6 5-3.85 3.05-3 4.7-6.45 1.8-3.65 2.2-7.95m-25.45-23.25v-2.65q-5.2.3-9.55 2.55-3.2 1.7-5.9 4.3-2.25 2.25-3.75 4.75-.3.4-.55.85-2.15 4-2.65 8.65h2.9q.45-3.95 2.3-7.35.6-1.1 1.35-2.15 1.1-1.5 2.5-2.9 2.4-2.35 5.15-3.75 3.7-2 8.2-2.3m3.95-2.6v2.65q4.75.45 8.65 2.85 2.2 1.3 4.05 3.15 1.4 1.4 2.5 2.9.45.65.85 1.3 2.25 3.7 2.7 8.2h2.75q-.5-5.2-3.15-9.5-1.05-1.8-2.5-3.4l-1.2-1.3q-.05 0-.05-.05l-.45-.45-1.55-1.3q-1.3-1-2.7-1.85-4.45-2.65-9.9-3.2m47.7 2.6v-2.65q-5.2.3-9.6 2.55-3.2 1.7-5.85 4.3-1.85 1.85-3.2 3.85-.25.35-.5.75l-.6 1q-2.15 4-2.65 8.65h2.9q.25-2.05.85-3.95.55-1.8 1.45-3.4.6-1.1 1.35-2.15 1.05-1.5 2.45-2.9 2.4-2.35 5.15-3.75 3.75-2 8.25-2.3m3.95-2.6v2.65q4.7.45 8.6 2.85 2.2 1.3 4.1 3.15 1.4 1.4 2.5 2.9.45.65.85 1.3 2.25 3.7 2.7 8.2h2.75q-.5-5.2-3.15-9.5-1.05-1.8-2.5-3.4l-1.2-1.3q-.05 0-.05-.05l-.45-.45-1.55-1.3q-1.3-1-2.75-1.85-4.45-2.65-9.85-3.2z"
                    />
                    <path
                        fill="#BBB"
                        d="M166.7 148.35q-.65 0-1.25.05-3.05.2-5.65 1.5-1.5.7-2.85 1.8-.7.5-1.3 1.1-1.55 1.6-2.65 3.3-1.45 2.5-1.85 5.45-.15 1.1-.15 2.3 0 .95.1 1.95.3 2.5 1.4 4.75 1.2 2.2 3.15 4.15 1.75 1.8 3.7 2.85 2.75 1.5 6.1 1.75h1.25q1.15 0 2.2-.05 3.3-.45 6-2.15 1.65-1 3-2.4 2.2-2.15 3.3-4.65.9-2 1.15-4.25.1-1 .1-1.95 0-1.2-.2-2.3-.4-3.05-1.9-5.65-1-1.6-2.45-3.1-.6-.6-1.25-1.1-.75-.55-1.5-1-2.8-1.75-6.25-2.2-1.05-.15-2.2-.15m7.45 4.2q1.15.7 2.2 1.75 1.3 1.25 2.15 2.75 1.85 2.95 1.85 6.7 0 2.85-1.1 5.2-.95 2.35-2.9 4.3-1.2 1.1-2.5 1.9-3.15 1.95-7.15 1.95-3.4 0-6.25-1.4-1.75-.95-3.3-2.45-1.75-1.75-2.7-3.7-1.35-2.6-1.35-5.8 0-3.75 1.85-6.7.85-1.5 2.2-2.75 1.65-1.7 3.65-2.6 2.7-1.3 5.9-1.3 3.25 0 5.95 1.3.8.35 1.5.85m44.2-4.2q-.65 0-1.25.05-3.05.2-5.65 1.5-1.55.7-2.9 1.8-.7.5-1.3 1.1-1.55 1.6-2.65 3.3-1.45 2.5-1.85 5.45-.15 1.1-.15 2.3 0 .95.1 1.95.3 2.5 1.4 4.75 1.2 2.2 3.15 4.15 1.75 1.8 3.7 2.85 2.8 1.5 6.15 1.75h1.25q1.15 0 2.2-.05 3.25-.45 5.95-2.15 1.65-1 3-2.4 2.2-2.15 3.35-4.65.9-2 1.15-4.25.1-1 .1-1.95 0-1.2-.2-2.3-.4-3.05-1.95-5.65-1-1.6-2.45-3.1-.6-.6-1.25-1.1-.75-.55-1.5-1-2.8-1.75-6.2-2.2-1.05-.15-2.2-.15m9.6 5.95q1.3 1.25 2.15 2.75 1.85 2.95 1.85 6.7 0 2.85-1.1 5.2-.95 2.35-2.9 4.3-1.2 1.1-2.5 1.9-3.1 1.95-7.1 1.95-3.4 0-6.25-1.4-1.8-.95-3.35-2.45-1.75-1.75-2.7-3.7-1.35-2.6-1.35-5.8 0-3.75 1.85-6.7.85-1.5 2.2-2.75 1.65-1.7 3.7-2.6 2.7-1.3 5.9-1.3 3.25 0 5.95 1.3.75.35 1.45.85 1.15.7 2.2 1.75z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#FacEye032__Symbol_75_0_Layer0_0_FILL"
                transform="translate(156.1 153.3)"
                id="FacEye032__col1n"
            />
            <use
                xlinkHref="#FacEye032__Symbol_51_0_Layer0_0_FILL"
                transform="translate(159.2 156.3)"
                id="FacEye032__col1l"
            />
            <use
                xlinkHref="#FacEye032__Symbol_48_0_Layer0_0_FILL"
                transform="translate(153.1 150.4)"
                id="FacEye032__col1d"
            />
            <use xlinkHref="#FacEye032__Layer5_0_FILL" />
            <use xlinkHref="#FacEye032__Layer5_1_FILL" />
        </g></g>
    )
}

export default FacEye032
