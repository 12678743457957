import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function ShiLon034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.55 48.45q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-.05-.05-.05-.1-1-1.8-2.05-3.5-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-5.55 3.8-11.4 6.75l-2.5 1.2-.75.35q-.1 0-.15.05-3.55 1.55-7.15 2.8-.2.05-.4.15l-1.3.45-29.6 46.7q-.15.1-.3.15-.3.1-.6 0-.15-.05-.25-.15l-29.6-46.7q-1-.35-1.95-.65-3.55-1.25-6.9-2.75-.5-.2-.95-.4-1.25-.6-2.45-1.2-5.9-2.95-11.45-6.75L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.8 1.35-1.55 2.7l-.1.2q-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.7-.3 12.2 4.2.15 0 .35.05.2.15.35.3l.05.05-.65 2.7q-.2.3-.4.65l-2.05 8.5q-.55 2.1-1.05 4.2l-.9 3.6-.85 3.25.3.2-.1.4q.4.1.75.2h.15l.4.1q.15.1.3.15 1.5.35 3.05.7 30.5 6.9 60.8 7h1.2q30.45-.05 60.7-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5z"
                    id="ShiLon034__Symbol_305_0_Layer0_0_FILL"
                />
                <g
                    id="ShiLon034__Symbol_240_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M5.2 23.25q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L0 44.5v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05m110.2 0v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35M30.15 14.2q.1-.2.05-.4-.1-.2-.25-.3-.2-.1-.4 0l-13.35 4L21.3.4q-.5-.2-.95-.4L15 18.1q-.1.2 0 .35.1.15.25.25t.35.05l13.15-4-5.5 11.3q-.05.1-.05.2v.1q0 .1.1.2.05.1.2.15l.05.05 36.15 25.1V76.4h1.2V50.5q-.15.1-.3.15-.3.1-.6 0-.15-.05-.25-.15l-.05-.05v.05L24.35 26.1l5.8-11.9z" />
                    <path d="M105.35 18.7q.15-.1.25-.25t0-.35L100.25 0l-.75.35q-.1 0-.15.05l5.05 17.1-13.35-4q-.2-.1-.4 0-.15.1-.25.3-.05.2.05.4l5.8 11.9L60.9 50.5v1.35l36.15-25.1.05-.05q.15-.05.2-.15.1-.1.1-.2v-.1q0-.1-.05-.2l-5.5-11.3 13.15 4q.2.05.35-.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon034__Symbol_305_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon034__col1n"
            />
            <use
                xlinkHref="#ShiLon034__Symbol_240_0_Layer0_0_FILL"
                transform="translate(132.2 241.2)"
                id="ShiLon034__col1s"
            />
        </g></g>
    )
}

export default ShiLon034
