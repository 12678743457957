import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#FFFFFF",
    "col2n": "#DDDDDD"
}

function AccJew004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M130.15 5.45L126.4.25l-.95.7V.9l-.1.1-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L9.9 4.5Q8.45 3.45 7.1 2.4L3.8 0 0 4.9q16.25 17.25 43.05 23.7 21.75 4.95 43.9 0 26.65-6.5 43.2-23.15M66.35 24.7l-.1-.1v-.05q.05.05.1.15z"
                    id="AccJew004__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.25 1.1L0 .5l1.75 3.9 2.5-3.3m14.25 9.2q-.5-.1-.55-.1l-6.2-.5-.3-.05 2.7 4.6q.05.05.1.15l.7.7q1.9 1.15 3.25 0 .35-.2.6-.5 1.1-1.25.5-3.75-.05-.1-.25-.2-.15-.25-.55-.35m22.4 10.95q-.15-.35-.3-.6-.5-.8-1.35-.85-.3-.1-.35-.1l-4.45-.55.05.1 1.8 4.65q.05.05.1.15l.5.8q1.8 1.35 3.55 0 .15-.15.3-.35.05-.1.1-.15.4-.65.4-1.45 0-.8-.35-1.65m25.45 1.7q-1.6 0-2.7 1.1-1.05 1.05-1.05 2.65 0 1.6 1.05 2.65 1.1 1.1 2.7 1.1 1.55 0 2.65-1.1 1.1-1.05 1.1-2.65 0-1.6-1.1-2.65-1.1-1.1-2.65-1.1m25.55-1.7q-.35.85-.35 1.65 0 .45.15.85.1.4.35.75.65.9 1.95 1h.8q.05-.05.1-.05.95-.25 1.5-1.2v-.1l1.95-4.95v-.05l-4.45.55q-.05 0-.35.1-.85.05-1.35.85-.15.25-.3.6m22.95-10.95q-.05 0-.55.1-.4.1-.55.35-.2.1-.25.2-.1.4-.15.8-.2 1.3.2 2.25.3.7 1 1.15 1.65 1.15 3.35 0l.65-.65q.05-.1.1-.15l2.65-4.45.05-.15-6.5.55M132.25 0l-4.3 1.25 2.4 3.35 1.9-4.6z"
                    id="AccJew004__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M7.3.95Q7.15.7 6.75.6 6.25.5 6.2.5L0 0l7.05 4.9q1.1-1.25.5-3.75-.05-.1-.25-.2m21.8 13.7q.4-.65.4-1.45 0-.8-.35-1.65-.15-.35-.3-.6-.5-.8-1.35-.85-.3-.1-.35-.1l-4.45-.55.05.1 6.35 5.1m28.35-.1l-.2-.2q-.75-.75-1.75-1-.45-.1-.9-.1-.4 0-.7.1-.9.15-1.6.7l-.4.3v.05l2.65 3.05 2.9-2.9m29.15-5.1l-4.45.55q-.05 0-.35.1-.85.05-1.35.85-.15.25-.3.6-.35.85-.35 1.65 0 .45.15.85L86.6 9.5v-.05m23-9.4l-6.5.55q-.05 0-.55.1-.4.1-.55.35-.2.1-.25.2-.45 1.85.05 3.05l7.75-4.1.05-.15z"
                    id="AccJew004__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew004__Symbol_75_0_Layer0_0_FILL"
                transform="translate(127.2 227.4)"
                id="AccJew004__col1n"
            />
            <use
                xlinkHref="#AccJew004__Symbol_24_0_Layer0_0_FILL"
                transform="translate(126.1 227.05)"
                id="AccJew004__col2n"
            />
            <use
                xlinkHref="#AccJew004__Symbol_13_0_Layer0_0_FILL"
                transform="translate(137.85 236.75)"
                id="AccJew004__col2l"
            />
        </g></g>
    )
}

export default AccJew004
