import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#B46BFF",
    "col2n": "#FDC6F3",
    "col2s": "#E25DC9"
}

function ShiSho046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.5 19.1q-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65q-1.75 1.2-3.5 2.35-2.9 1.75-5.85 3.35-3.55 6.15-10.9 11.25-14.1 9.8-34 9.8t-34-9.8q-7.7-5.35-11.2-11.85-2.4-1.3-4.7-2.75-1.8-1.15-3.55-2.35L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.3.5-.6 1.05-.55.9-1.05 1.85-2 3.65-3.8 7.5-1.4 3-2.65 6.15-.4 1.05-.8 2.15-.2.45-.35.95 12.05 6.6 18.45 9.8l-2.2 11.3q-.55 2.7-1.1 5.45L11.7 69.2q-1 3.9-1.95 7.8l-.95 3.6v.05l-1.2 4.5q33.4 11.9 67.55 11.8 34.15.1 67.55-11.8l-1.4-5 .1.45-6-24.25q-.65-3.1-1.3-6.15l-2.5-12.75q5.55-2.4 18.75-9.1-.2-.5-.35-.95-.45-1.1-.85-2.15-1.25-3.15-2.65-6.15m-5.1 61.6v-.05l.05.25-.05-.2z"
                    id="ShiSho046__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M12.55 1.7q.6.1-.95-.55Q9.6.3 6.25.05 5.1-.05 4 .1 1.9.3 0 1.3q.25.35.65.5.05.05.15.05.1-.05.2-.05 1.15-.55 3-.65 2.9-.3 7.55.4.5.05 1 .15m147.55.1q.1 0 .2.05.1 0 .15-.05.4-.15.65-.5-1.9-1-4-1.2-1.1-.15-2.25-.05-3.35.25-5.35 1.1-1.55.65-.95.55.5-.1 1-.15 4.65-.7 7.55-.4 1.85.1 3 .65z"
                    id="ShiSho046__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M183.6 274.15v11.45l5.4-4.85v20.2h-5.4v8.35h19.1v-8.35h-5.3V269.7h-8.45l-5.35 4.45z"
                    id="ShiSho046__Layer1_0_FILL"
                />
                <g
                    id="ShiSho046__Symbol_120_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M4.45 21.95l-.4.1q-.95.2-1.7.85-1.1 1-1.1 2.45 0 1.4 1.1 2.4.1.1.2.15l.1.1q-.85.25-1.55.85-1.1 1-1.1 2.45 0 1.4.65 2.15t2.25.85q.3 0 .7-.05.35 0 .8-.05.65-.1 1.55-.25 4.25-.6 9.65 0 6.15.6 9.25 2.2h.05q.8-4.2 1.75-8.35.35-1.65-3.4-3.3-5.6-2.75-12.25-3.05-2.15-.1-4.55.15-.15 0-.3.05l-1.7.3m159.5 3.4q0-1.45-1.1-2.45-.75-.65-1.7-.85l-.4-.1-1.7-.3q-.15-.05-.3-.05-2.4-.25-4.55-.15-6.65.3-12.25 3.05-4 1.35-3.3 3.8l1.45 7.85h.25q3.1-1.6 9.25-2.2 5.4-.6 9.65 0 .9.15 1.55.25.45.05.8.05.4.05.7.05 1.6-.1 2.25-.85.65-.75.65-2.15 0-1.45-1.1-2.45-.7-.6-1.55-.85l.1-.1q.1-.05.2-.15 1.1-1 1.1-2.4zM132.95 0q-4.15 2.55-8.45 4.65-1.6 1.65-3.35 3.2-2.15 1.9-4.4 3.55-10.1 7.3-22.6 9.5-5.4.95-11.25.95h-.6q-5.85 0-11.25-.95-12.5-2.2-22.6-9.5-2.25-1.65-4.4-3.55-1.75-1.55-3.3-3.2Q36.4 2.55 32.3 0q3.95 8.25 11.75 15.1 2.15 1.9 4.4 3.55 1.65 1.2 3.35 2.25 13.3 8.2 30.5 8.2h.6q17.2 0 30.5-8.2 1.7-1.05 3.35-2.25 2.25-1.65 4.4-3.55 7.8-6.85 11.8-15.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSho046__Symbol_119_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho046__col1n"
            />
            <use
                xlinkHref="#ShiSho046__Symbol_120_0_Layer0_0_FILL"
                transform="translate(109.9 235.6)"
                id="ShiSho046__col2n"
            />
            <use
                xlinkHref="#ShiSho046__Symbol_121_0_Layer0_0_FILL"
                transform="translate(111.95 261.7)"
                id="ShiSho046__col2s"
            />
            <use xlinkHref="#ShiSho046__Layer1_0_FILL" />
        </g></g>
    )
}

export default ShiSho046
