import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFF5D7",
    "col1n": "#FFE69A",
    "col2l": "#0087FF",
    "col2n": "#006AC9"
}

function AccHat035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M244.5 69.15q6.5-1.8 0-6.55-2.55-1.15-4.7-1.9-1.15-.45-2.3-.6-4.15-.45-7.3-.8l-24.3-.4-4.25-16.5-9.15-.1-7.25-40.75L59.85 0l-11.3 40.05-5.65-.1-3.4 16.4-17.95-.3q-1.95.15-4.3.3Q4.5 57.25.9 60.7q-1.85 2.75.05 4.25l243.55 4.2M57.45 40.2q.25-.25.5-.4l-.05.4h-.45z"
                    id="AccHat035__Symbol_253_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M85.55 41.55L81.3.9 7.9 0 0 40.25l85.55 1.3z"
                    id="AccHat035__Symbol_211_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M166.4 18.95l-4.25-16.5L3.4 0 0 16.4l166.4 2.55z"
                    id="AccHat035__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M99.2 17.95l-1.45-16.5L2.65 0 0 16.4l99.2 1.55z"
                    id="AccHat035__Symbol_41_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat035__Symbol_253_0_Layer0_0_FILL"
                transform="translate(68.65 42.05)"
                id="AccHat035__col1n"
            />
            <use
                xlinkHref="#AccHat035__Symbol_211_0_Layer0_0_FILL"
                transform="translate(150 42.4)"
                id="AccHat035__col1l"
            />
            <use
                xlinkHref="#AccHat035__Symbol_76_0_Layer0_0_FILL"
                transform="translate(108.15 82)"
                id="AccHat035__col2n"
            />
            <use
                xlinkHref="#AccHat035__Symbol_41_0_Layer0_0_FILL"
                transform="translate(143.3 82.55)"
                id="AccHat035__col2l"
            />
        </g></g>
    )
}

export default AccHat035
