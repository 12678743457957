import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FF008C"
}
function HaiHig026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M33.6 149.25l.45.1q-.1-.05-.25-.1h-.2z"
                    id="HaiHig026__Layer8_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M74.3 9.65l-.7-.5q-1.65-1.5-3.5-2.75-5.5-3.65-11.45-5.1Q29.55-5 9.4 19l-.2.2q-3.9 4.45-6.7 10-1.05 2.1-1.85 4.35-.1.1-.15.35-.3.6-.5 1.25 4.6-3.4 6.35-4.5 1.25-.7 2.5-1.15 1.4-.55 2.8-.85-1.25 2.8-2.65 4.8-1 1.25-1.8 2.5-5.85 9.45-4.6 17.4.45 2.7 1.5 5.55 1.15 3.25 3.3 6.05 2.65-9.45 10.15-15.4 1.5-1.3 3.3-2.35 8.15-5.15 16.2-6.45l1.3-.1q1.9-.3 3.75-.4 1.25-.1 11.3-1 9.55-.9 12.5-6.5.45-.65 1-1.35l.6-.9q.2-1.1 2.1-2.8 1.9-1.75 5.75-5.8 3.8-4.1 2.75-3.8l-.75.2q.3-.1.7-2.15.35-2.05.95-2.35-.3-.55.2-.15-2.85-2.4-4.9-4z"
                    id="HaiHig026__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M75.85 6.95l-.5-.4q-.1-.1-.25-.15Q57.4-4.45 37.2 3.1q-1 .25-1.95.5Q46.2 5.95 51.8 8.15q5.4 2.05 6.65 3.85 1.2 1.8 1.5 1.95-1.9.5-4.2.75-3.35.9-7.45 1.55-9.65 1.55-17.9-.3-6.05-1.4-10.45-1.3-1.55 0-2.85.2-3.95.6-7.45 2.3-2.05.95-4 2.3-1.9 1.3-3.15 2.6-1.05 2.1-1.85 4.35-.1.1-.15.35-.3.6-.5 1.25 4.6-3.4 6.35-4.5 1.25-.7 2.5-1.15 1.4-.55 2.8-.85Q10.4 24.3 9 26.3q-1 1.25-1.8 2.5-5.85 9.45-4.6 17.4.45 2.7 1.5 5.55Q5.25 55 7.4 57.8q2.65-9.45 10.15-15.4 1.5-1.3 3.3-2.35Q29 34.9 37.05 33.6l1.3-.1q1.9-.3 3.75-.4 1.25-.1 11.3-1 9.55-.9 12.5-6.5.45-.65 1-1.35l.6-.9q.2-1.1.75-2.55.6-1.7 1.7-3.9 2.85-5.55 6.45-8.5.35-.3.7-.5-.1-.2-.2-.3-.1.05-.25.15l-.8-.8z"
                    id="HaiHig026__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.8 2.25Q14.45 1.1 15.15 0q-1.2 0-2.35.4-.25.05-.5.15-.15.05-.35.15h-.1q-.1.05-.2.15-.6.3-1.3.8-.3.2-.6.45-.35.2-.7.5-3.6 2.95-6.45 8.5Q1.5 13.3.9 15q-.55 1.45-.75 2.55-.05.15-.05.3-.15.9-.1 1.55 0 .15.05.35Q.2 21 1 22.15q.25.2.4.45.05 0 .1.05l-.05.05q.2.15.35.25.45.55 1.1 1.05.3.2 1.25.8l-.2-.2H4q.15.1.15.2.25-.7.45-1.4.2-.9.5-1.7 2.25-7.2 5.75-14.25 1.4-2.7 2.95-5.2z"
                    id="HaiHig026__Symbol_80_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#HaiHig026__Layer8_0_FILL" />
            <use
                xlinkHref="#HaiHig026__Symbol_81_0_Layer0_0_FILL"
                transform="translate(29.75 88.1)"
                id="HaiHig026__col1n"
            />
            <use
                xlinkHref="#HaiHig026__Symbol_82_0_Layer0_0_FILL"
                transform="translate(29.75 95.25)"
                id="HaiHig026__col1d"
            />
            <use
                xlinkHref="#HaiHig026__Symbol_80_0_Layer0_0_FILL"
                transform="translate(97.1 101.05)"
                id="HaiHig026__col2n"
            />
        </g></g>
    )
}
export default HaiHig026
