import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col2d": "#830000",
    "col2n": "#C40000"
}
function BodHan006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.8 1.55q.2-.3.2-.6Q2-.15 1 0 0 .1 0 .95q0 1 1 1 .5 0 .8-.4z"
                    id="BodHan006__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1 1.95q.5 0 .8-.4.2-.3.2-.6Q2-.15 1 0 0 .1 0 .95q0 1 1 1z"
                    id="BodHan006__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M46.7 23.45q-1.25-1.6-2.9-3-1.85-1.6-5.05-3.5.05 0-1.15-.75-.1-3-2.25-5.15-1.4-1.4-3.15-1.9.2-.25.35-.5h-.05q1-1.55 0-3.1-.05-.1-.1-.15-.25-.3-.5-.55-2.5-1.95-5.3-3.1-7.4-3.2-15.4 0-.95.45-1.95 1Q7.9 3.6 7.2 4.8 5.8 8.45 5.55 9.75q-.05.3-.05.45 0 .15.1.35-.05 0-.1.05Q0 16.35 0 24.5t5.5 13.9l.6.6q1.1 1.65 2.7 3.15 6.65 5.6 13 6.85 3.45 1.15 7.5 1.15 3.5 0 6.6-.85 4.65-1.15 8.3-4.4 1.85-1.65 3.1-3.45 4.2-8.65 0-17.3-.3-.4-.6-.7M171 2.75q-1-.55-1.95-1-8.8-3.45-16.25 0-3.25 1.55-5.4 4.5-.45 1.2.35 2.4h-.05q.15.25.35.5-1.75.5-3.15 1.9-1.25 1.25-1.8 2.8-.1.3-.2.7-.15.45-.2.95-.05.3-.05.6v.05q-.1 0-.1.05-1.25.75-1.45.95-2.95 1.7-4.8 3.3-1.65 1.4-2.9 3-.3.3-.6.7-4.2 8.65 0 17.3 1.25 1.8 3.1 3.45 3.65 3.25 8.3 4.4 3.1.85 6.6.85 4.05 0 7.5-1.15 6.3-1.25 13-6.85 1.6-1.5 2.7-3.15l.6-.6q5.5-5.75 5.5-13.9t-5.5-13.9l-.15-.15q.1.05.2.15.1-.2.1-.4 0-1-1.7-5.4-.7-1.2-2.05-2.05z"
                    id="BodHan006__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M26.9 5.4q-.25-.3-.5-.55-2.5-1.95-5.3-3.1-7.4-3.2-15.4 0-.95.45-1.95 1Q2.4 3.6 1.7 4.8.3 8.45.05 9.75q-.05.3-.05.45 0 .15.1.35v.05q.65-.5 1.4-1.1 5.65-3.75 11.55-3.6 2.4.2 4.65.75 1.6.35 3.1.85 2 .65 3.4 1.3-.8 0-1.45.15-.15.05-.35.1-1.95.45-3.45 2-2.25 2.25-2.25 5.45t2.25 5.45q2.25 2.25 5.45 2.25 1.6-.05 3-.6l.3-.1q.15-.05.3-.15-.1.15-.2.35-.3.4-2.15 2.75-1.45 1.85-1.45 2 0 .05.1.5.1.2.25.35.15-.15.35-.25h.05q.3-.5.9-1.2 1.5-1.7 1.7-2 .45-.85 1.6-2.25.15-.15 1.25-2.05l-.15.1q.1-.15.55-.7.1 1.6.3 3.45.25 2.6.25 3.1 0 .05.05.75.2.7.9.7.05 0 .05-.7-.05-.75-.15-1.65-.1-.9-.05-2.6v-3.5l-.25-2.7q0-1 .2-1.6-.1-3-2.25-5.15-1.4-1.4-3.15-1.9.2-.25.35-.5H27q1-1.55 0-3.1-.05-.1-.1-.15m-3.95 4.55q.7-.15 1.45-.15.45 0 .95.1 2.15.25 3.8 1.85 1.2 1.2 1.7 2.8.15.5.2 1.05.05.45.05.9v.4q0 .05-.05.4-.05.35-.25 1.4-.2 1.05-1.65 2.5-.85.9-1.85 1.4-1.35.6-2.9.6-2.8-.05-4.75-2-2-1.95-1.95-4.7-.05-2.8 1.95-4.75 1.45-1.45 3.3-1.8m142.55-7.2q-1-.55-1.95-1-8.8-3.45-16.25 0-3.25 1.55-5.4 4.5-.45 1.2.35 2.4h-.05q.15.25.35.5-1.75.5-3.15 1.9-1.25 1.25-1.8 2.8-.1.3-.2.7-.15.45-.2.95-.05.3-.05.6v.05q-.05.25-.05.6-.05.4-.1 1-.35 5.55-.35 7.45-.05 1.85.2 3.75h.1q.7 0 .9-.7.05-.7.05-.75v-1.8q0-1.3.2-3.45l.1-1.65q-.1.55-.05-.2v-.05q.1.2.05.2l.85 1.4q.85 1.4 1.05 1.65 1.15 1.4 1.6 2.25.2.3 1.7 2 .6.7.9 1.2h.05q.2.1.35.25.15-.15.25-.35.1-.45.1-.5 0-.15-1.45-2-1.85-2.35-2.15-2.75-.1-.2-.2-.35.15.1.3.15l.3.1q1.4.55 3 .6 3.2 0 5.45-2.25t2.25-5.45q0-3.2-2.25-5.45-1.5-1.55-3.45-2-.2-.05-.35-.1-.65-.15-1.45-.15 1.4-.65 3.4-1.3 1.2-.4 2.5-.7 10.6-1.95 18 3.65.1.05.2.15.1-.2.1-.4 0-1-1.7-5.4-.7-1.2-2.05-2.05M144.85 9.8q.75 0 1.45.15 1.85.35 3.3 1.8 2 1.95 1.95 4.75.05 2.75-1.95 4.7-1.95 1.95-4.75 2-1.55 0-2.9-.6-1-.5-1.85-1.4-1.45-1.45-1.85-3.35.05-.1.05-.15 0-.35-.15-.6v-.6q0-2.8 1.95-4.75 1.65-1.6 3.8-1.85.5-.1.95-.1z"
                    id="BodHan006__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan006__Symbol_45_0_Layer0_0_FILL"
                transform="translate(130 290.65)"
                id="BodHan006__col1n"
            />
            <use
                xlinkHref="#BodHan006__Symbol_46_0_Layer0_0_FILL"
                transform="translate(134.8 290.65)"
                id="BodHan006__col1d"
            />
            <use
                xlinkHref="#BodHan006__Symbol_13_0_Layer0_0_FILL"
                transform="translate(102.45 274.1)"
                id="BodHan006__col2n"
            />
            <use
                xlinkHref="#BodHan006__Symbol_12_0_Layer0_0_FILL"
                transform="translate(107.95 274.1)"
                id="BodHan006__col2d"
            />
        </g></g>
    )
}
export default BodHan006
