import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function LegSho001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.9 7.7q-9-5.25-23.3-6.95-13.1-1.45-27.4 0-7 .8-14.2 2.45l-8.3 2.05-8.3-2.05Q58.2 1.55 51.2.75 36.9-.7 23.8.75 9.1 2.5 0 8h147.4l-.5-.3z"
                    id="LegSho001__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho001__Symbol_2_0_Layer0_0_FILL"
                transform="translate(118.8 326)"
                id="LegSho001__col1n"
            />
        </g></g>
    )
}

export default LegSho001
