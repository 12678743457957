import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col2n": "#D9D9D9"
}

function ObjMor007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.8.95q-.05 0-.1-.05Q13.4-2 11.3 4.25q-.45 1.1-.85 2.3-1 2.55-1.9 5.3-3.5 10.45-5.95 22V34q-.15.45-.15.9-1 4.5-1.75 9.2-.05.35-.1.75L.25 47.2Q-.85 50.5 2.7 52q3.05 1.3 5.75 2 .05.05.1.05 2.7.8 5.95 1.35 3.85.6 4.65-2.75l.15-.25q-.5-.15-1-.35h-.05l-.2-.1q-.1-.05-.15-.05-.75-.4-1.45-1.05-.8-.8-1.45-2-.7-1.35-1.15-3.1l-.1-.55q-.15-.75-.25-1.5v-.35q-.1-1.3 0-2.5.05-.75.15-1.4.25-1.45.75-2.8.25-.65.55-1.25.4-.85.9-1.6.15-.2.3-.35.05-.05.05-.1 1.35-1.6 3.1-2.55.4-.25.85-.45 2.05-.65 3.95-.85 1.05-.1 2.05-.05.8.05 1.6.15 1.75-5.75 3.1-11.35.65-2.8 1.2-5.45.3-1.3.5-2.45 1.55-6.4-8.75-9.4z"
                    id="ObjMor007__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.5 48.85q-.25 0-.45.05-.9.2-1.05.8-.2.65.45 1.3.55.65 1.6.95.35.1.7.15h.05q.6.05 1.2-.1l.4-.1q-.75-.4-1.45-1.05-.8-.8-1.45-2m-6.7-.5q-.85.2-1.05.85-.15.6.45 1.2l.05.05q.6.6 1.5.9.05.05.1.05.9.2 1.7 0l.05-.05q.9-.15 1.05-.75.2-.65-.45-1.3l-.05-.05q-.55-.55-1.45-.85-.05 0-.1-.05-.9-.2-1.75-.05l-.05.05m3-3.85q-.05 0-.1-.05-.9-.25-1.7-.1l-.05.05q-.9.25-1.05.85-.2.65.45 1.25l.05.05q.55.55 1.45.85.05.05.1.05.9.2 1.75.05l.05-.05q.85-.15 1.05-.8.15-.6-.45-1.3l-.05-.05q-.6-.55-1.5-.8m-7.7.85q-.55-.05-1 .1-.9.25-1.05.85-.2.65.45 1.25.4.45 1 .7v.05l.6.2q1.05.3 1.95.05.85-.15 1.05-.8.15-.6-.45-1.3-.65-.6-1.7-.9-.45-.15-.85-.2m-.25-3.95q-.85.3-1.05.9-.2.65.45 1.25.4.45 1.05.8.25.05.6.15 1.05.3 1.9.05.85-.15 1.05-.8.2-.6-.45-1.35-.55-.6-1.6-.9-.45-.1-.9-.1-.5-.05-1.05 0m7.3-1.1H9.1q-.85.25-1.05.9-.15.6.45 1.2l.05.05q.6.6 1.5.85.05.05.1.05.9.25 1.7.05l.05-.05h.1q-.1-1.3 0-2.5-.45-.3-1-.45-.05 0-.1-.05-.9-.25-1.75-.05m-2.45-.7q.2-.65-.4-1.3-.65-.6-1.7-.9-.4-.15-.8-.2-.6-.05-1.05.05-.9.25-1.1.9-.15.6.45 1.25.45.4 1.05.75l.6.2q1.05.3 1.9.1.9-.25 1.05-.85M4.65 33q-.55-.05-1.05.1-.85.2-1.05.85-.2.6.45 1.25.4.45 1.05.75.3.1.65.2 1.05.3 1.85.05.85-.2 1.05-.8.2-.65-.45-1.3-.55-.6-1.6-.9-.45-.15-.9-.2m8.25 3.65q-.35-.2-.75-.35-.05 0-.1-.05-.9-.2-1.7-.05h-.05q-.9.3-1.05.9-.15.5.2.95.05.15.25.3v.05q.6.6 1.5.85.05.05.1.05.45.1.85.15.25-1.45.75-2.8M22.3.95q-.05 0-.1-.05Q11.9-2 9.8 4.25q-.45 1.1-.85 2.3-3 9.25-3.7 16.55l-.05.2q-.8 5.7 2.35 9.1 2.55 2.35 5.9 3 .4-.85.9-1.6.15-.2.3-.35.05-.05.05-.1 1.35-1.6 3.1-2.55-1.75-.1-3.9-.6-.05 0-.1-.05-6.85-2.3-7.15-6.1Q7.6 13.6 10.2 6.4q2.6-7.2 11.7-4.3.05.05.1.05 9.2 2.35 7.6 9.8-1.6 7.5-6.35 16.85-.3.4-.65.7 1.05-.1 2.05-.05.15-.25.25-.5l.05-.2q3.3-6.55 5.6-15.95.3-1.3.5-2.45 1.55-6.4-8.75-9.4z"
                    id="ObjMor007__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill="#85CD6F"
                    d="M257.6 269.45l-3.3 11.6 16.6 4.75 3.3-11.6-16.6-4.75z"
                    id="ObjMor007__Layer4_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor007__Symbol_120_0_Layer0_0_FILL"
                transform="translate(246.15 257)"
                id="ObjMor007__col1n"
            />
            <use
                xlinkHref="#ObjMor007__Symbol_24_0_Layer0_0_FILL"
                transform="translate(247.65 257)"
                id="ObjMor007__col2n"
            />
            <use xlinkHref="#ObjMor007__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjMor007
