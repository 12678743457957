import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#14BA01",
    "col1n": "#00DE00",
    "col1s": "#1F9900",
    "col2d": "#830000",
    "col2n": "#C40000"
}

function AccHat044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M94.05 1.1q-5-2.15-10.5 0-5.4 2.7-10.6 5.75Q57 16.15 43 28.8q-4.25 3.45-8.85 6.55-3.35 2.15-7.25 3.9-.3.15-.55.25-.7.3-1.4.55-4.6 1.75-9.4 1.95H14.1q-4.15-.05-8.45-1.2-1.3-.35-2.5-.55h-1.2l-.1.1-.4.2q-8.9 22.35 37.65 61.4 6.15 4.25 37.05 0Q115.9 97 151.35 76.9q2.45-1.4 4.9-2.85.25-.2.55-.35.35-.2.75-.4-.1-.05-.15-.1l-.05-.05q-.55-.4-.85-.55-.05-.05-.15-.1-5.65-2.65-11.3-5.45-.25-.15-.45-.25-5.2-2.4-8.35-5.55-.6-.65-1.15-1.25-.65-.8-1.2-1.6-.35-.55-.6-1.05-8.75-17.35-20.8-33.7-2.2-3.3-4.25-6.6-4.65-7.4-10.75-13.9-.85-.9-2.05-1.45-.7-.35-1.4-.6z"
                    id="AccHat044__Symbol_262_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M44.3 43.8Q59.4 36.95 71 29.7q2.3-1.5 4.5-2.95Q94.35 14 102.7 0 87.75 1.3 75.5 3.8q-2.3.4-4.5.95-27 6.15-39.85 18.4-2.5 2.4-4.45 5-1.65 2.2-2.9 4.55-5.7 9.15-20.1 26.2Q1.9 61 0 63.25q4.2-2 8.45-4.05 4.65-2.25 9.95-4.8 1.95-1 4-1.65.35-.15.75-.25 7.6-2.9 14.55-5.85 3.4-1.4 6.6-2.85z"
                    id="AccHat044__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M44.3 43.8Q59.4 36.95 71 29.7q2.3-1.5 4.5-2.95Q94.35 14 102.7 0 88.1 1.85 73.25 8.1q-1.85.75-3.65 1.55Q53.5 17.3 49.65 31.3l-8.55 5.15q.25-1.9.6-3.4Q43.15 25.8 45.85 22q-1.95 1.6-6.8 8.3-4.95 6.8-6.15 11.1L4.7 58.35q-2.35 2.4-4.7 4.9 4.2-2 8.45-4.05 4.65-2.25 9.95-4.8 1.95-1 4-1.65.35-.15.75-.25 7.6-2.9 14.55-5.85 3.4-1.4 6.6-2.85z"
                    id="AccHat044__Symbol_25_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat044__Symbol_170_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M154.05 72.3l-.35.65q.2.15.55.3.2.1.7.45.35-.2.75-.4-.1-.05-.15-.1l-.05-.05q-.55-.4-.85-.55-.05-.05-.15-.1-.25-.1-.45-.2z" />
                    <path d="M110.65 23.65q-2.2-3.3-4.25-6.6-4.65-7.4-10.75-13.9-.85-.9-2.05-1.45-.7-.35-1.4-.6-5-2.15-10.5 0-5.4 2.7-10.6 5.75Q80.25 4 86.3 13.2q1.65 2.5 3.2 4.95 3 5.25 5.35 10 14.35 32-9.45 47.9-1.05.6-2.1 1.3l-1 .6q-4.25 2.5-8.65 4.6-4.85 2.2-9.55 4.05-4.3 1.6-11.45 3.2-6.8 1.5-8.9-.2-2.95-3.15-4.35-7.95-1.6-4.9-1.75-9.95-.2-3.6-.4-7.1l-.2-4q-.15-2.95-.35-6.05-.1-1.6-.15-3.15-1.05-3.75-6.15-5.9-6.2-3.6-16.7-3.5h-1.45q-4.15-.05-8.45-1.2-1.3-.35-2.5-.55H.1l-.1.1 2.55 1.5q1.5.7 2.75 1.15h.05q5.5 1.9 10.6 3.55 1.6.45 3.2 1l.05.05q3.25 1.05 6.5 2.3.65.25 1.4.5h.05q5.85 2.15 6.1 8.2.3 5.6.6 10.8l.2 4q.2 3.5.4 7.1.15 5.05 1.75 9.95 1.4 4.8 4.35 7.95 2.1 1.7 8.9.2Q56.6 97 60.9 95.4q4.7-1.85 9.55-4.05 4.9-2.35 9.65-5.2 4.75-3.05 9.05-5.1.7-.35 1.4-.65 4.2-2 8.45-4.05 4.6-2.25 9.95-4.8 1.7-.85 3.5-1.45.35-.15.6-.2l-.05.05q4-1.2 7.15-1.4 6.35-.4 9.75.2 3.35.6 5.9 1.1 2.5.45 5.55.95 3.05.45 6.75 1.15 3.7.65 5.6 1l.35-.65q-5.45-2.6-10.85-5.25-.25-.15-.45-.25-5.2-2.4-8.35-5.55-.6-.65-1.15-1.25-.65-.8-1.2-1.6-.35-.55-.6-1.05-8.75-17.35-20.8-33.7z" />
                </g>
                <g
                    id="AccHat044__Symbol_126_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M113.55 28.85v.05l.05-.05h-.05z" />
                    <path d="M130.25 28.7q3.35.6 6 1.05 2.7.45 5.65 1 3 .5 7 1.2t6.6 1.15q.15.05.35-.1l.05-.05q-.8-.6-1.1-.75-.35-.15-.55-.3-1.9-.35-5.6-1-3.7-.7-6.75-1.15-3.05-.5-5.55-.95-2.55-.5-5.9-1.1-3.4-.6-9.75-.2-3.15.2-7.15 1.4v-.05q-.25.05-.55.2-1.8.6-3.5 1.45-5.35 2.55-9.95 4.8-4.25 2.05-8.45 4.05-.7.3-1.4.65-4.3 2.05-9.05 5.1-4.75 2.85-9.65 5.2-4.85 2.2-9.55 4.05-4.3 1.6-11.45 3.2-6.8 1.5-8.9-.2-2.95-3.15-4.35-7.95-1.6-4.9-1.75-9.95-.2-3.6-.4-7.1l-.2-4q-.3-5.2-.6-10.8-.25-6.05-6.1-8.2h-.05q-.75-.25-1.4-.5-3.25-1.25-6.5-2.3l-.05-.05q-1.6-.55-3.2-1-5.1-1.65-10.6-3.55h-.05Q4 1.3 1.55 0H.45Q.2.15 0 .3q3.2 1.75 5.55 2.6Q11.1 4.85 16.2 6.45q1.6.5 3.2 1.05h.15q3.15 1.1 6.35 2.3.7.25 1.45.5 5.2 1.9 5.45 7.3.3 5.6.6 10.8l.2 4q.2 3.5.4 7.1.15 5.2 1.8 10.2 1.45 5.05 4.6 8.35l.05.05q2.2 2.1 9.75.45 7.25-1.6 11.6-3.25 4.75-1.9 9.6-4.1h.05q4.95-2.35 9.7-5.25h.05q4.7-3 8.95-5.05.65-.35 1.35-.65h.05q4.2-2 8.45-4.05 4.65-2.25 9.95-4.8 1.65-.85 3.35-1.4.25-.1.5-.15h.05q3.9-1.15 6.9-1.35 6.2-.4 9.5.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat044__Symbol_262_0_Layer0_0_FILL"
                transform="translate(128.15 12.9)"
                id="AccHat044__col1n"
            />
            <use
                xlinkHref="#AccHat044__Symbol_170_0_Layer0_0_FILL"
                transform="translate(130 12.9)"
                id="AccHat044__col1d"
            />
            <use
                xlinkHref="#AccHat044__Symbol_126_0_Layer0_0_FILL"
                transform="translate(129.65 53.15)"
                id="AccHat044__col1s"
            />
            <use
                xlinkHref="#AccHat044__Symbol_84_0_Layer0_0_FILL"
                transform="translate(220.95 29.7)"
                id="AccHat044__col2n"
            />
            <use
                xlinkHref="#AccHat044__Symbol_25_0_Layer0_0_FILL"
                transform="translate(220.95 29.7)"
                id="AccHat044__col2d"
            />
        </g></g>
    )
}

export default AccHat044
