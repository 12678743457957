import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2n": "#005100"
}

function AccHat069({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M185.2 61.1l.25.2.05-.05 2.15-6.1q4.05-12.85-21.9-29.55l-4.3-2.6q-4-2.8-13.4-8.1-13.8-7.85-22.65-10.65-28.8-7.8-55.95-1.2-2.15.65-4.2 1.35h-.05q-15.6 6.85-27.55 12.85-3 1.5-5.75 2.95Q-2.35 39.55.2 54.15L3.4 64.4h.05v.05H3.4l.05 5.45h.05q92.778-19.06 181.7-3.1l-.05-5.7h.05m-66-14.6l.35.15q-.2.05-.4.15l.05-.2v-.1m-61.95-.55l.05-.1v.1h-.05m6.85 1.65q-.1.05-.15.05 0-.05.05-.05l.1-.1v.1z"
                    id="AccHat069__Symbol_287_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M170.3 36.65q4.6 3.75 7.8 7.7 6.45 7.95 7.1 16.75l.25.2.05-.05 2.15-6.1q4.05-12.85-21.9-29.55l-4.3-2.6q-4-2.8-13.4-8.1-13.8-7.85-22.65-10.65-28.8-7.8-55.95-1.2-2.15.65-4.2 1.35h-.05q-15.6 6.85-27.55 12.85-3 1.5-5.75 2.95Q-2.35 39.55.2 54.15L3.4 64.4h.05Q4 52.1 15.25 39.8q.95-1.05 2-2.1.45-.5.95-.95 11.9-11.8 24.25-19.5 51.1-32 100.35 0 14.25 7.35 27.5 19.4z"
                    id="AccHat069__Symbol_191_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M168.8 21.6q.85.05 1.75.2l5.8.7q.85.05 1.75.15 1.85.2 3.7.45l-.05-5.7q-7.2-2.35-14.3-4.35-73.25-26.1-147.45 0Q9.9 16.4.05 20.75H0l.05 5.45h.2q2.5-.4 5.1-.75l5-.7q4.15-.6 8.25-1.1.4-.05.75-.1.8-.1 1.55-.2 7.9-1 15.75-1.8 1.2-.15 2.45-.25 17.25-1.7 34.45-2.5 4.95-.2 9.9-.35h.7l.65-.05q1.7-.05 3.3-.05H89.25q10.15-.2 11.8-.2 7.5-.05 15 .1l.05.05q20.45.4 40.9 2.15h.05q5.85.5 11.75 1.15M115.75 3.1l.05-.2v-.1l.35.15q-.2.05-.4.15m-55.15.8l.1-.1v.1q-.1.05-.15.05 0-.05.05-.05z"
                    id="AccHat069__Symbol_97_0_Layer0_0_FILL"
                />
                <g id="AccHat069__Layer10_0_FILL">
                    <path
                        fill="#D2AD00"
                        d="M186.15 105.7q14.9-.4 29.85-.05 20.5.4 40.95 2.15 2.25.2 4.45.4 4.55.45 9.1.95l5.8.7q2.625.34 5.25.65v-1.4q-.116-.05-.2-.05-2.55-.4-5.1-.7l-5.75-.7q-.9-.1-1.8-.15-4.3-.5-8.65-.9l-3.1-.3q-20.45-1.75-40.95-2.15-38.7-.8-77.55 3.1-1.45.1-2.95.3-6.65.65-13.25 1.5-1.55.15-3.05.4-1.7.2-3.35.45-2.75.35-5.55.75l-5.1.7q-2.578.396-5.45.95-.025-.005-.05 0v1.55q.025-.006.05 0 .815-.203 1.3-.3l4.2-.7h.05l5-.7q2.35-.35 4.7-.65 2.6-.4 5.25-.7 1.65-.2 3.25-.4 5.7-.75 11.4-1.3 1.55-.2 3.1-.3 24.1-2.45 48.15-3.1z"
                    />
                    <path d="M190.35 109.15q-2.1 0-4.15.05-25.15.6-50.25 3.15-1.55.15-3.1.35-3.25.3-6.5.7-1.95.2-3.9.45-4.15.5-8.2 1.1-1.95.25-3.85.5-4.05.55-8 1.15-.713.09-2.15.2.003.015-.3.05-.027-.003-.1 0v.05h.05q87.35 35.5 181.65-2.15v-.95l-.05-.05q-8.45-1-16.9-1.8-37.2-3.5-74.25-2.8M216.4 84.6q-13.1-1.25-26.35-1.15-37.05.2-75.2 10.65l-15 9.4v5.15q.171-.01.25-.05 2.5-.4 5.1-.75l5-.7q4.15-.6 8.25-1.1.4-.05.75-.1.8-.1 1.55-.2 7.9-1 15.75-1.8 1.2-.15 2.45-.25 17.25-1.7 34.45-2.5 4.95-.2 9.9-.35 2.85-.1 5.75-.1l1.6-.1q5.1-.1 10.25-.1 7.5-.05 15 .1l.05.05q20.45.4 40.9 2.15h.05q5.85.5 11.75 1.15.85.05 1.75.2l5.8.7q.85.05 1.75.15 1.8.195 3.6.4v-5.25l-11.2-6.45Q241.75 87 216.4 84.6z" />
                    <path
                        fill="#FF0"
                        d="M186.2 109.2q2.05-.05 4.15-.05 37.05-.7 74.25 2.8 8.45.8 16.9 1.8l.05.05v-3.3q-2.625-.31-5.25-.65l-5.8-.7q-4.55-.5-9.1-.95-2.2-.2-4.45-.4-20.45-1.75-40.95-2.15-14.95-.35-29.85.05-24.05.65-48.15 3.1-1.55.1-3.1.3-5.7.55-11.4 1.3-1.6.2-3.25.4-2.65.3-5.25.7-2.35.3-4.7.65l-5 .7h-.05l-4.2.7q-.485.097-1.3.3v3q.054-.002.1 0 .073-.003.1 0 .303-.035.3-.05 1.438-.11 2.15-.2 3.95-.6 8-1.15 1.9-.25 3.85-.5 4.05-.6 8.2-1.1 1.95-.25 3.9-.45 3.25-.4 6.5-.7 1.55-.2 3.1-.35 25.1-2.55 50.25-3.15m29.75-8.5l-.05-.05q-7.5-.15-15-.1-5.15 0-10.25.1l-1.6.1q-2.9 0-5.75.1-4.95.15-9.9.35-17.2.8-34.45 2.5-1.25.1-2.45.25-7.85.8-15.75 1.8-.75.1-1.55.2-.35.05-.75.1-4.1.5-8.25 1.1l-5 .7q-2.6.35-5.1.75-.079.04-.25.05-.046.04-.1.05v3.6q2.872-.554 5.45-.95l5.1-.7q2.8-.4 5.55-.75 1.65-.25 3.35-.45 1.5-.25 3.05-.4 6.6-.85 13.25-1.5 1.5-.2 2.95-.3 38.85-3.9 77.55-3.1 20.5.4 40.95 2.15l3.1.3q4.35.4 8.65.9.9.05 1.8.15l5.75.7q2.55.3 5.1.7.084 0 .2.05v-3.65q-1.8-.205-3.6-.4-.9-.1-1.75-.15l-5.8-.7q-.9-.15-1.75-.2-5.9-.65-11.75-1.15h-.05q-20.45-1.75-40.9-2.15z"
                    />
                </g>
                <g id="AccHat069__Layer1_0_FILL">
                    <path
                        fill="#FFF"
                        fillOpacity={0.4}
                        d="M231 121.5l-12.4-10.35h-26.8l6.25 12.25q-49.6 1.35-98.15-6.5 89.35 23.85 181.65-2.15-25.4 4.55-50.55 6.75z"
                    />
                    <path
                        fill="#B5A628"
                        d="M198.85 56.2q.75-1.35 1.35-2.6 2.25-4.6 1.4-5.2-.9-.55-4.35 3.2-1.55 1.65-3.15 3.7-.2-2.6-.55-4.8-.7-5.1-1.75-5.1t-1.8 5.1q-.3 1.8-.45 3.95-1.25-1.75-2.45-3.25-3.2-4-4.1-3.45-.95.5 1 5.2.55 1.35 1.25 2.85-1.45-.8-2.75-1.4-4.65-2.2-5.25-1.35-.6.8 3.25 4.25 1.7 1.5 3.8 3.1-2.65.15-4.9.5-5.15.7-5.15 1.75 0 1 5.15 1.75 1.8.25 3.95.45-1.75 1.15-3.25 2.3-4.05 3.15-3.55 4.05.5.9 5.35-1 1.35-.55 2.9-1.2-.8 1.4-1.4 2.7-2.3 4.55-1.45 5.15.85.55 4.35-3.2 1.55-1.65 3.2-3.7.15 2.6.5 4.85.75 5 1.8 5.05 1.05-.05 1.75-5.05.3-1.85.5-3.95 1.2 1.7 2.35 3.15 3.25 4 4.15 3.5.9-.55-1.05-5.25-.55-1.35-1.2-2.75 1.45.75 2.75 1.4 4.65 2.15 5.25 1.35.6-.85-3.25-4.25-1.7-1.5-3.75-3.1 2.65-.2 4.9-.5 5.15-.75 5.15-1.75 0-1.05-5.15-1.75-1.9-.3-4.1-.45 1.8-1.25 3.3-2.4 4.1-3.15 3.55-4.05-.5-.9-5.35 1-1.35.55-2.75 1.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccHat069__Layer10_0_FILL" />
            <use
                xlinkHref="#AccHat069__Symbol_287_0_Layer0_0_FILL"
                transform="translate(96.45 38.7)"
                id="AccHat069__col1n"
            />
            <use
                xlinkHref="#AccHat069__Symbol_191_0_Layer0_0_FILL"
                transform="translate(96.45 38.7)"
                id="AccHat069__col1d"
            />
            <use
                xlinkHref="#AccHat069__Symbol_97_0_Layer0_0_FILL"
                transform="translate(99.85 82.4)"
                id="AccHat069__col2n"
            />
            <use xlinkHref="#AccHat069__Layer1_0_FILL" />
        </g></g>
    )
}

export default AccHat069
