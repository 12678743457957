import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 3.25L36.2 7.3l.2-3.25L.35 0 0 3.25m95.4 0L95.05 0 59 4.05l.2 3.25 36.2-4.05z"
                    id="FacBro003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro003__Symbol_3_0_Layer0_0_FILL"
                transform="translate(144.8 126.25)"
                id="FacBro003__col1n"
            />
        </g></g>
    )
}

export default FacBro003
