import { AssetSvgProps } from "../../../shared/assets"

function PlaInd023({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="PlaInd023__Layer7_1_FILL">
                    <path
                        fill="#919191"
                        d="M53.15 0H0v341.4l38.15-63.3h.4l14.6-26.9V0m331.8 0H347.2v251.2l37.75 106.1V0z"
                    />
                    <path
                        fill="#CCC"
                        d="M384.95 385v-27.7L347.2 251.2H53.15l-14.6 26.9h-.4L0 341.4V385h384.95z"
                    />
                </g>
                <g id="PlaInd023__Layer6_0_FILL">
                    <path
                        fill="#FFF"
                        d="M74.4 278.1H0V385h30.2q26.312-47.554 44.2-106.9m-38.15 18.4l.3.2q-.15.1-.3.25v-.45m-9.75 57.45h2.45q-.082.074-.2.15-1.25.8-2 1.8l-.25.3v-2.25z"
                    />
                    <path
                        fill="#B3B979"
                        d="M36.55 296.7l-.3-.2v.45q.15-.15.3-.25z"
                    />
                    <path
                        fill="#94D1EB"
                        d="M169.75 100.75v-87.9H75.7q9.681 43.95 0 87.9 48.86-8.256 94.05 0m-24.55-84.7q-.05-.091-.1-.2h.1v.2m0 76.95v.25h-.1q.05-.108.1-.25z"
                    />
                    <path
                        fill="#626262"
                        d="M327.95 197.65q-1.25-.5-2.5-.9-16.938-37.975 0-75.95-12.173-4.825-4.8-9.65-5.962-5.9 0-11.8-27.99 10.504-59.8 0v99.1q-11.6 5.1-11.6 12.2 0 .35.05.75.15.95.4 2 3.25 14.35 10.8 25.2 5.15 7.4 11.05 12.2.251.203.5.4 4.674 3.663 9.75 5.75.95 3.35 3.35 6.1 3.9 4.35 9.45 4.35 5.6 0 9.45-4.35 2.25-2.5 3.2-5.5 6.023-2.055 11.45-6.35.251-.197.5-.4 5.9-4.8 11.05-12.2 7.5-10.85 10.8-25.05.2-1.1.4-2.15l.05-.75q0-7.65-13.55-13z"
                    />
                </g>
                <g id="PlaInd023__Layer5_0_FILL">
                    <path
                        fill="#B3B3B3"
                        d="M73.5 344.15H57.7Q43.254 364.575 41.8 385H59l14.5-40.85z"
                    />
                    <path
                        fill="#DDD"
                        d="M74.4 289.7v-11.6L30.2 385h6.05q21.592-41.778 38.15-95.3z"
                    />
                    <path
                        fill="#B3B979"
                        d="M4.1 295.75q-1.494 0-4.1-.55V385h13.65q1.251-4.013 3.3-6.7.55-.75 3.9-4.25.7-.7 1.3-1.5.458-.634.85-1.35 2.393-4.291 2.35-11.25 0-1.95.95-3.4l.2-.35.25-.3q.75-1 2-1.8.118-.076.2-.15 4.824-3.082 5.8-4.4 2.55-3.45 3.65-6.25 1.35-3.35 1.35-7.7 0-1.62-.2-3.1-.35-3.23-1.55-5.8-1.75-3.85-1.75-6.9 0-1.53 1.05-3.8.636-1.366 1.65-3 2.2-3.6 2.95-4.9.3-.55.35-.7h-5.6q.55-.25-.05-.25h-.35v-.75q.85-.85 3.55-4 .5-.6 1.05-1.15.35-.65.4-1.1v-.2q0-.3-.75-1.45l-.1-.2q-2.8-.3-3.9-1.15-.15-.1-.25-.2.15-.15.3-.25 1.85-1.9 3.1-3.8.45-.7.8-1.3-.6-.4-1.25-.8-1.15-.75-2.3-1.3H26.35q-.81.218-1.7.5-2.836.884-6.55 2.4-3.4 1.4-5.7 2.35-.1 0-.15.05-5.7.95-8.15.95z"
                    />
                    <path
                        fill="#919191"
                        d="M81.9 93.25v-80.4h-6.2v87.9l6.2-7.5z"
                    />
                    <path
                        fill="#C4C4C4"
                        d="M169.75 100.75v-7.5H81.9l-6.2 7.5h94.05z"
                    />
                    <path
                        fill="#7B7B7B"
                        d="M320.65 111.15v-11.8h-59.8v11.8h59.8m7.3 86.5q-1.25-.5-2.5-.9V120.8H264.1v76.15q-1 .55-1.4.7-.05.021-.1.05-.897.371-1.75.75-11.6 5.1-11.6 12.2 0 .35.05.75.15.95.4 2 .776 2.243 2.85 4.25 2.899 2.86 8.3 5.3.9.35 1.85.75 13.6 5.35 32.65 5.35 19.1 0 32.6-5.35 5.46-2.169 8.7-4.7 3.322-2.542 4.4-5.45.2-1.1.4-2.15l.05-.75q0-7.65-13.55-13m.2 12.2v.15q0 .888-.4 1.7-19.546-1.303-43-1.55-24.953-.204-24.95-.3.05-1.4 1.05-2.65 2.2-2.85 8.9-5.05 10.05-3.15 24.2-3.15 14.25 0 24.15 3.15 9.85 3.25 10.05 7.7z"
                    />
                </g>
                <g id="PlaInd023__Layer5_1_FILL">
                    <path
                        fill="#484848"
                        d="M282.2 257.1q-.204-.073-.4-.15.95 3.35 3.35 6.1 3.9 4.35 9.45 4.35 5.6 0 9.45-4.35 2.25-2.5 3.2-5.5-.789.26-1.6.45-4.993 1.45-10.3 1.45-6.931 0-13.15-2.35m35.9-39.25q.8-.3 1.6-.55-28.9-7.45-51.45 0 .7.25 1.5.55 10.05 3.2 24.2 3.2 14.25 0 24.15-3.2z"
                    />
                    <path
                        fill="#919191"
                        d="M328.15 210v-.15q-35.75-11.7-68.35 0v.15q0 1.5 1.05 2.9 1.9 2.4 7.4 4.4 22.55-7.45 51.45 0 8.45-3.05 8.45-7.3z"
                    />
                </g>
                <g id="PlaInd023__Layer4_0_FILL">
                    <path
                        fill="#626262"
                        d="M41.8 385l32.6-84.05V289.7L36.25 385h5.55z"
                    />
                    <path
                        fill="#9BA354"
                        d="M1.3 385h12.35q1.251-4.013 3.3-6.7.55-.75 3.9-4.25.7-.7 1.3-1.5-1.7.55-8.1 1.5-5.75.9-7.6 2.65-2.25 2.15-4.5 6.9-.338.713-.65 1.4m40.6-76.9l-.4-.2q-1.05 1.45-6.85 7.9-.083.1-.2.2-5.15 6.356-5.15 9.9 0 2.5 3.15 6.15.19.225.35.45 2.7 3.387 2.7 6.95 0 5.7-3.45 9.7-2.567 2.907-4 4.8-.861 1.168-1.3 1.95.75-1 2-1.8.118-.076.2-.15 4.824-3.082 5.8-4.4 2.55-3.45 3.65-6.25 1.35-3.35 1.35-7.7 0-1.62-.2-3.1-.35-3.23-1.55-5.8-1.75-3.85-1.75-6.9 0-1.53 1.05-3.8.636-1.366 1.65-3 2.2-3.6 2.95-4.9m-1.05-6.85q.35-.65.4-1.1-2.4 1.15-7.25 2.95-2.35.85-4.05 1.75l-2.25 1.6q-1.1 1-1.1 1.8 0 .85 4.3-.1 2.35-.55 3.8-.8.45-.15.85-.15.4-.2.7-.3v-.5q.85-.85 3.55-4 .5-.6 1.05-1.15m-4.6-4.3v-.45l.3.2q1.85-1.9 3.1-3.8.45-.7.8-1.3-.6-.4-1.25-.8-4.05 2.15-7.2 4.55-.15 1.35 4.25 1.6m-26.1-1.3q.45-.2 1-.4l1.25-.5q-.1 0-.15.05-5.7.95-8.15.95-1.494 0-4.1-.55v3.15q4.564-.66 9.95-2.65.1-.05.2-.05z"
                    />
                    <path
                        fill="#B3B979"
                        d="M36.25 296.5v.45q.15-.15.3-.25l-.3-.2z"
                    />
                    <path
                        fill="#666"
                        d="M94.05 12.85h-6v80.4l.05.05v2.95q2.75 2.85 6 0v-80.4h-.05v-3m17.2 0h-6.3v80.4l.05.05v2.95q3.2 3.5 6.3 0v-80.4h-.05v-3m16.9 0h-5.95v80.4l.05.05v2.95q3.25 3.35 5.95 0v-80.4h-.05v-3m16.9 0h-5.95v80.4l.05.05v2.95q3.25 3.65 5.95 0v-80.4h-.05v-3m17.35 3v-3h-5.8v80.4l.05.05v2.95q3.25 3.65 5.95 0v-3h-.05v-77.4h-.15z"
                    />
                    <path
                        fill="#919191"
                        d="M260.85 111.15l3.25 9.65h61.35l-4.8-9.65h-59.8z"
                    />
                </g>
                <path
                    fill="#AEAEAE"
                    d="M347.2 251.2q23.647-125.6 0-251.2H53.15q-28.877 127.525 0 251.2 149.386 30.687 294.05 0z"
                    id="PlaInd023__Layer7_0_FILL"
                />
                <path
                    d="M265.95 103.9q-.25-.3-.6-.4-.3-.1-.55-.1-1.3-.1-1.35 1.1v.1q0 .45.15.75.35.65 1.2.65.7 0 1.1-.5.25-.4.25-.7v-.15q0-.45-.2-.75m50.65-1.05q-.45.15-.65.55-.15.25-.15.7l.2.65q.3.45.75.55.2.05.4.05t.4-.05q.35-.1.6-.45.25-.3.3-.6v-.2-.3q-.2-1.1-1.3-1-.35 0-.55.1z"
                    id="PlaInd023__Layer4_1_FILL"
                />
            </defs>
            <use xlinkHref="#PlaInd023__Layer7_0_FILL" />
            <use xlinkHref="#PlaInd023__Layer7_1_FILL" />
            <use xlinkHref="#PlaInd023__Layer6_0_FILL" />
            <use xlinkHref="#PlaInd023__Layer5_0_FILL" />
            <use xlinkHref="#PlaInd023__Layer5_1_FILL" />
            <use xlinkHref="#PlaInd023__Layer4_0_FILL" />
            <use xlinkHref="#PlaInd023__Layer4_1_FILL" />
        </g></g>
    )
}

export default PlaInd023
