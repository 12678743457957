import { AssetSvgProps } from "../../../shared/assets"

function ShiMot107({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#8B3F99"
                    d="M159.2 272.5v7.2h66.5v-7.2h-66.5z"
                    id="ShiMot107__Layer1_0_MEMBER_0_FILL"
                />
                <g id="ShiMot107__Layer1_0_FILL">
                    <path
                        fill="#DF1970"
                        d="M159.2 258.1v14.4q11.815 3.113 23.5 4.05 10.32-.668 20.35-.05 11.38-.94 22.65-4v-14.4h-66.5z"
                    />
                    <path
                        fill="#24429A"
                        d="M159.2 279.7v14.4h66.5v-14.4q-11.145-2.455-22.65-3.2-10.03-.618-20.35.05-11.568.748-23.5 3.15z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot107__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot107__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot107
