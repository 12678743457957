import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.3 0q0 .4-.45 11.05-.45 10.6-1.05 17.8-4.2 21.4-22.85 41.55l-.1.1q-4.7 4.05-10.9 6.8-1.65.7-6.25 2.25-1.3.5-4.45.55-4.9-.3-7.3 2-.7.75-1.75 2.35t-2.1 4.85l-.25.6q-3.15 9.55-8.4 12.55-3.75 2.1-8.7 2.1-4 0-6.9-1-2.6-.95-4.3-2.75-1-1.05-1.55-2.25-3.2 2.1-3.65 2.1-.05 0-.05-.05-.8-.5-1.35-.9l-1.4-.95h-2.2l-1.4.95q-.55.4-1.35.9 0 .05-.05.05-.45 0-3.65-2.1-.55 1.2-1.55 2.25-1.7 1.8-4.3 2.75-2.9 1-6.9 1-4.95 0-8.7-2.1-5.25-3-8.4-12.55l-.25-.6q-1.05-3.25-2.1-4.85-1.05-1.6-1.75-2.35-2.4-2.3-7.3-2-3.15-.05-4.45-.55-4.6-1.55-6.25-2.25-6.2-2.75-10.9-6.8l-.1-.1Q7.3 50.25 3.1 28.85q-.6-7.2-1.05-17.8Q1.6.4 1.6 0 .3 4.9.3 10.3q-.1.9-.15 1.85-.25 4.95 0 10.3 0 13.8 2 19.55.1.5.15 1 .65 3.25 1.75 4.8 1.45 2.1 4.95 8 .15.35.35.75 1.9 3.4 2.3 4 .4.6 1.05 2.05.65 1.5 2.8 6.15 1.25 2.7 3.45 5.6 1.55 2 3.5 4.05 3.3 3.05 6.35 9.3 4.35 6.7 11.65 11.1l1.4.9q11.05 7.25 14.15 9.6.35.3 7.65 9.6 2.55 3.25 6 6.6 0-2.45 2.25-2.45 1.05 0 1.9.15 2.55 4.35 7.35 6.4.05 0 .1.05.25-1.1 1.25-2.1 1.6-1.75 4.05-2h1.85l2.2.4q1.8.55 3.2 1.95.15.1 1.4 1.55.95 1.15 1.25 1.65.3-.5 1.25-1.65 1.25-1.45 1.4-1.55 1.4-1.4 3.2-1.95l2.2-.4h1.85q2.5.25 4.05 2 1 1 1.25 2.1.05-.05.1-.05 4.8-2.05 7.35-6.4.85-.15 1.9-.15 2.25 0 2.25 2.45 3.45-3.35 6-6.6 7.3-9.3 7.65-9.6 3.1-2.35 14.15-9.6l1.4-.9q7.3-4.4 11.65-11.1 3.05-6.25 6.35-9.3 1.95-2.05 3.5-4.05 2.2-2.9 3.45-5.6 2.15-4.65 2.85-6.15.65-1.45 1-2.05.4-.6 2.3-4 .2-.4.35-.75 3.5-5.9 4.95-8 1.1-1.55 1.75-4.8.05-.5.15-1 2-5.75 2-19.55.25-5.35 0-10.3-.05-.95-.15-1.85 0-5.4-1.3-10.3z"
                    id="BeaChi021__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M186.55 4.8q1.1-1.55 1.75-4.8-1.1 3.1-2.5 5.3-2.7 3-3.15 3.75-.5.85-1.85 2.3-1.15 1.2-1.75 2.5-1.2 2.7-3.2 7-1.75 3.45-3.25 5.7t-4.35 5.3q-2.9 3.05-3.8 3.95-.95.9-1.55 2.25-.8 1.9-1.2 2.7-.4.75-1.6 2.5-.95 1.4-1.35 2.2-.1.1-.15.2-3.9 3.85-7.6 6.55-2.7 2-7.4 4.75-.5.3-1 .65-.4.3-.85.55-3.55 2.1-4.45 2.65-1.5.85-3.5 2.8-1.7 1.7-2.45 2.85-.75 1.15-2.1 2.6-1.45 1.6-2 2.3-.8.95-2.2 2.3-1.65 1.55-2.1 1.55-2.9-1.65-5.45-1.65-1.5 0-1.7.4-.15.35-.85 2.3-.85 1.5-1.95 2.45-1.85 1.25-2.45 1.25-2.2-1.7-2.8-1.95-1.05-.5-2.05-.7-.8-.1-2.3-.1-3.25 0-5.25.8-2.05.85-4 2.95-1.95-2.1-4-2.95-2-.8-5.25-.8-1.5 0-2.25.1-1 .2-2.1.7-.6.25-2.8 1.95-.6 0-2.45-1.25-1.1-.95-1.95-2.45-.7-1.95-.85-2.3-.2-.4-1.7-.4-2.55 0-5.45 1.65-.45 0-2.1-1.55-1.4-1.35-2.2-2.3-.55-.7-2-2.3-1.3-1.45-2.1-2.6-.75-1.15-2.45-2.85-2-1.95-3.5-2.8-.9-.55-4.45-2.65-.45-.25-.85-.55-.5-.35-1-.65-4.7-2.75-7.4-4.75-3.65-2.7-7.6-6.55-.05-.1-.15-.2-.4-.8-1.35-2.2-1.2-1.75-1.6-2.5-.4-.8-1.2-2.7-.6-1.35-1.55-2.25-.9-.9-3.8-3.95-2.85-3.05-4.35-5.3-1.5-2.25-3.25-5.7-1.95-4.3-3.2-7-.6-1.3-1.75-2.5-1.35-1.45-1.85-2.3Q5.2 8.3 2.5 5.3 1.1 3.1 0 0q.65 3.25 1.75 4.8 1.45 2.1 4.95 8 .15.35.35.75 1.9 3.4 2.3 4 .4.6 1.05 2.05.65 1.5 2.8 6.15 1.25 2.7 3.45 5.6 1.55 2 3.5 4.05 3.3 3.05 6.35 9.3 4.35 6.7 11.65 11.1l1.4.9q11.05 7.25 14.15 9.6.35.3 7.65 9.6 2.55 3.25 6 6.6 0-2.45 2.25-2.45 1.05 0 1.9.15 2.55 4.35 7.35 6.4.05 0 .1.05.25-1.1 1.25-2.1 1.6-1.75 4.05-2h1.85l2.2.4q1.8.55 3.2 1.95.15.1 1.4 1.55.95 1.15 1.25 1.65.3-.5 1.25-1.65 1.25-1.45 1.4-1.55 1.4-1.4 3.2-1.95l2.2-.4h1.85q2.5.25 4.05 2 1 1 1.25 2.1.05-.05.1-.05 4.8-2.05 7.35-6.4.85-.15 1.9-.15 2.25 0 2.25 2.45 3.45-3.35 6-6.6 7.3-9.3 7.65-9.6 3.1-2.35 14.15-9.6l1.4-.9q7.3-4.4 11.65-11.1 3.05-6.25 6.35-9.3 1.95-2.05 3.5-4.05 2.2-2.9 3.45-5.6 2.15-4.65 2.85-6.15.65-1.45 1-2.05.4-.6 2.3-4 .2-.4.35-.75 3.5-5.9 4.95-8z"
                    id="BeaChi021__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi021__Symbol_60_0_Layer0_0_FILL"
                transform="translate(96.1 138.95)"
                id="BeaChi021__col1n"
            />
            <use
                xlinkHref="#BeaChi021__Symbol_19_0_Layer0_0_FILL"
                transform="translate(98.4 181.95)"
                id="BeaChi021__col1d"
            />
        </g></g>
    )
}

export default BeaChi021
