import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#9AFF00",
    "col2d": "#007070",
    "col2n": "#00AFAF"
}

function ObjToo052({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#3A3A3A"
                    d="M107.85 261.6l-.7-.6q-1.592 1.198-6.85 3.55-7.8 3.4-8.2 3.6-3.45 1.95-8.9 4.6-7.4 3.65-8.75 3.65-.18 0-.35-.05.096.258.25.5-1.7.05-3.1-.3-3.55-1.5-7.1.6L32.3 292.6l-12.75 6.15-5.1 2.5h-.05l-2.45 1.2-.05.05-.5.2-.35.15q-.1.05-.15.15l-.1.05q-.332.796-1.8 3.5-1.9 3.55-1.9 7.55 0 5.5 2.9 11 3.95 7.4 11.3 8.8h1.45q1.872-.393 3.8-1.3 1.8-.85 4.05-2.3l5.2-2.6q4.035-1.695 4.8-.55l5.45-2.65v.05L63.6 316q1.4-1.43 6.65-4.55 5.5-3.25 7.7-4.3 2.15-1.15 3.95-1.45 2.95-.55 3.7 1.55.55 2.4 2.55 5.2 1.1 1.55 3.5 5.1 2.3 3.4 3.65 5.25 4.6 6.3 6.85 7.9 1.8 1.3 5.05 1.3 1.85 0 10.3-4.45 3.05-1.6 14.15-7.75 1.75-1 5.35-2.8 3.95-2 5.65-2.95 2.05-1.15 2.75-3.95.168-.699.3-1.2-.284-1.212-.95-2.5l-1.05-1.35q-.35 1.85-3.5 3.6L116.75 320q-.4.2-.85.35l-4.2 1.65q-2.55 1.2-4.15-1.1l-14.1-18.8.35-.2q-.1-.187-.1-.5 0-.9 4.3-2.8 4.5-2 6.1-.7l3.2 3.15q.6.6 2.55 1.35.93.384 1.25.85.754-.152 1.35-.45l.25-.1.05-.05q.9-.4 1.45-.95.7-.6.5-1.1-.05 0-2.25-2.5-.65-.9-1.15-1.6-.15-.35-.55-1.15-.2-.45-.45-1.2l2.05-1q-.6-1.15-.1-2.85l3.45-1.65q8.5-3.65 9.65-6.25l-.9-1.1q.352-.23.6-.5-.65-.193-.65-1.25 0-1.38 1.05-1.65-.055-.096-.15-.2 2.9-1.9 1.15-4.75-.2-.35-.35-.6l-13.3-11.25q-2.2-.7-3.8-.05-.2.05-.4.2l-.75.35z"
                    id="ObjToo052__Layer14_0_FILL"
                />
                <path
                    fill="#666"
                    d="M22 304.7q-.55-.35-1.1-.75l-.2-.1q-.3-.15-.6-.35-.9-.5-1.8-.85-1.25-.4-2.4-.6-1.2-.15-2.35 0 0 .05-.05 0l-.3.1q-.6.1-1.3.35l-.5.2-.35.15q-.1.05-.15.15-4 2.1-4.75 7.95-.05.05-.05.2-.7 5.95 2.4 12.3 3.15 6.5 8.4 9.65.2.1.45.2.85.5 1.7.85 3.9 1.55 7.2 0l.75-.35q.75-.4 1.4-1.05l.15-.15q.9-.85 1.45-1.95.65-1 1-2.3.2-.85.4-1.85 0-.25.05-.5v-.15q.05-.3.1-.55.05-.9.05-1.8.05-.2 0-.35l-.1-1.8q0 1.05-.15 2.1v.4q-.1.6-.25 1.2v.1q-.05.05-.05.15v.1q-.05 0-.05.1-.05 0-.05.05v.1q-1.05 1.85-2.9 2.75-3.1 1.5-6.95-.85-3.9-2.4-6.25-7.25-2.2-4.6-1.9-8.85 0-.25.05-.55.05-.7.2-1.3.8-3.45 3.45-4.75 1.8-.85 3.85-.45.45.1 1 .25.15.15.4.25.15.05.35.15.05.05.15.1.6.4 1.15.8-.5-.45-1-.85-.25-.2-.55-.4m55.7-24.65l-1-1.3-38 18.5q.078.065.15.1-6.922 3.189-11.35 5.3L25 303.9l1.25 1.1 2.5-1.2h-.05q6.455-3.297 11-5.65l.15.15q.05 0 .1.05l37.75-18.3m9.5 12.6l-1.95-2.6-38.95 18.9-4.85 3.25v8.25h.05l-.05 1.15 21.5-10.45q.55-3.6-.7-6.4l24.95-12.1m4 2q1.35 2.25 4.5.6l28.75-13.95q2.6-1.7.85-3.6l-18.2-16.75q-1.8-1.4-3.85-.15l-28.1 13.65q-1.75.9-.8 2.4 1.55-.05 3.4-.4l13.45 18.2z"
                    id="ObjToo052__Layer14_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M9.15 25l.85 1.4 2.7-1.3q-.4-.75-.85-1.4L9.15 25M4.6 22.95q-.5-.05-1 0-.55.1-1.1.35Q.55 24.25.1 27q-.2 1.2 0 2.4.2 1.6 1 3.2 1.4 2.9 3.75 4.25 2.45 1.35 4.4.4.75-.35 1.3-1l.3-.4.3-.5q-.25-1.05-.55-2.15l-.6-1.6q-.4-1.1-.95-2.2-.5-1.05-1.1-2-.4-.75-.85-1.4-1.15-1.7-2.5-3.05M61.65 1.3l-1-1.3-37.6 18.25q.45.7.85 1.4L61.65 1.3z"
                    id="ObjToo052__Symbol_211_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22 19.1q.75.55 1.5 1.15 0 .05.05.1.6.5 1.15 1.05h.05q1.7 1.75 3.15 3.95.45.65.85 1.4.4.7.75 1.45l11.2-5.4q-.35-.75-.75-1.5-.4-.7-.85-1.4-1.45-2.2-3.15-3.95-.05-.05-.1-.05-.55-.55-1.15-1.05-.75-.65-1.5-1.2L22 19.1m20.05 23.05q.8-1.8 1.2-4.05v-.05l-11.1 5.4q0 .05-.05.15-.35 2.2-1.15 3.95l-8.7 4.2q-3.3 1.55-7.2 0-.85-.35-1.7-.85-.25-.1-.45-.2-5.25-3.15-8.4-9.65-3.1-6.35-2.4-12.3 0-.15.05-.2.75-5.85 4.75-7.95.05-.1.15-.15l.35-.15.5-.2.05-.05 2.45-1.2h.05l5.6-2.7h.1q1.65.5 3.3 1.45.1 0 .2.05l11.25-5.5q-.15-.05-.25-.1-1.75-.9-3.35-1.35-4.4-1.3-8.3.35l-12.35 6 .05.05Q1.65 19.95.35 26.85q-1.3 7.3 2.4 14.9Q6.4 49.3 12.85 52.8q.1 0 .15.05 5.8 3.05 10.85 1.35L36.6 48l.2-.1q3.5-1.95 5.25-5.75m1.55-7.05q.15-2.65-.45-5.4-.3-1.55-.85-3.15-.15-.5-.35-1.05L30.8 30.95q1.85 4.85 1.65 9.55l11.15-5.4m80.35-33.65Q123.2.7 122 .15q-.4-.15-.65-.15-1.15 2.6-9.65 6.25l-3.45 1.65q-.35 1.15-.15 2 .85-.05 1.9.05 4.1.35 8.05 3.35 3.9 3 5.3 6.9 1.45 3.8-.45 6.25-1.05 1.35-2.75 1.85 0 .05.05.1.4.7 1.4 1.75.3.3.55.5.95.85 1.95 1 .3.1.6.15l11.5-5.55q3.15-1.75 3.5-3.6.3-1.35-.85-2.8l-14.6-18q-.15-.15-.3-.4z"
                    id="ObjToo052__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M16.05 10.4q-1.25-.35-2.45-.45-3.3-.35-6.3 1.1l-.6.3Q1.65 14.2.35 21.1q-1.3 7.3 2.4 14.9 3.65 7.55 10.1 11.05.1 0 .15.05 5.8 3.05 10.85 1.35l2.65-1.3q2.15-1.4 3.55-3.7.5-.7.85-1.5 0-.1.05-.15l-8.7 4.2q-3.3 1.55-7.2 0-.85-.35-1.7-.85-.25-.1-.45-.2-5.25-3.15-8.4-9.65Q1.4 28.95 2.1 23q0-.15.05-.2.75-5.85 4.75-7.95.05-.1.15-.15l.35-.15.5-.2.05-.05 2.45-1.2h.05l5.6-2.7M111.1 3q0-.25.05-.4-.05-.4 0-.75 0-.1.05-.25.1-.45.5-1.1l-3.45 1.65q-.6 1.2.1 2.85.6-.2 1.55-.35.9-.2 1.65-.2-.45-.65-.45-1.45m12.8 15.6q-.25 1.1-1.15 1.9-.9.75-1.95 1.1-1.1.3-.7.9.4.6 1.1 1.4.7.8.95 1 .95.85 1.95 1 .3.1.6.15l3.05-1.5q-.6-.05-1.35-.5-.2-.2-.4-.35-1.05-.8-1.65-2.1-.15-.25-.35-1.85-.1-.7-.1-1.15m2.2-1.85q.5.35 1.9-.35 1.35-.6 3.15-1.6 1.75-.95 2.45-1.65.7-.8-.25-1.25-.8 1.35-7.25 4.85m-2.9-6.25q1.35-.6 3.15-1.55 1.75-1 2.45-1.75.7-.75-.25-1.2-.8 1.3-7.2 4.85.05 0 .1.05.55.2 1.75-.4m-.6-7.65q1.75-.95 2.4-1.7.7-.75-.25-1.15-.8 1.3-7.2 4.8.5.3 1.85-.35 1.35-.6 3.2-1.6z"
                    id="ObjToo052__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    id="ObjToo052__Layer14_0_1_STROKES"
                    stroke="#000"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M19.55 298.775L32.3 292.6"
                />
                <g id="ObjToo052__Layer14_2_FILL">
                    <path
                        fill="#121212"
                        d="M62.95 311.15l-.25 5.3 20.6-10 15.75 23.15q4.1 5.05 11.4 2.35l31.65-15.35q5.2-3.15 3.7-7.4-.3-1.35-1.05-2.8 1 2.85-.6 4.4-1.7 1.5-1.75 1.45l-30.8 14.95q-4.45 1.95-8.1-.15-.75-.5-1.35-1.2-.1-.15-.2-.25l-14.2-20.3-.15.05-1.05-1.75-.1-.1h.1l-1.8-2.95-21.8 10.6m-21.9 15.8l.4-5.35-10.05 4.9q-.2 1-.4 1.85-.35 1.3-1 2.3-.55 1.1-1.45 1.95l-.15.15q-.65.65-1.4 1.05l14.05-6.85m71.2-36.65l-2.75 1.35q.45 1.5.8 2.5l2.05-1q-.6-1.15-.1-2.85m-11.1 5.4q-.25.1-.45.2-2.75 1.1-4 1.95l-4.7 2.3 1.3 2 .15-.05 4.95-2.45q.55-.3 1.2-.6.55-.25 1-.45 1.3-.45 2.05-.05.8.35 2.95 2.85.35.4 1.35 1.05 1.35.85 2.4 1 .65 0 1.5-.15.9-.15 1.6-.5l.25-.1q-.4.15-.75.1-.35-.05-1.05-.6-1.25-.8-1.45-.95-.65-.45-1.2-1.15-.45-.6-1.4-2-.85-1.25-1.25-1.65-.95-.95-2.45-1-.95-.05-2 .25z"
                    />
                    <path
                        fill="#9F9F9F"
                        d="M20.9 303.95l-.2-.1q-.3-.15-.6-.35-.9-.5-1.8-.85-1.25-.4-2.4-.6-1.2-.15-2.35 0 0 .05-.05 0l-.3.1q-.6.1-1.3.35l-.5.2-.35.15q-.1.05-.15.15-4 2.1-4.75 7.95-.05.05-.05.2-.7 5.95 2.4 12.3 3.15 6.5 8.4 9.65.2.1.45.2.85.5 1.7.85 3.9 1.55 7.2 0l.75-.35q.75-.4 1.4-1.05l.15-.15q.9-.85 1.45-1.95.65-1 1-2.3.2-.85.4-1.85 0-.25.05-.5v-.15q.05-.3.1-.55.05-.9.05-1.8.05-.2 0-.35l-.1-1.8q0 1.05-.15 2.1v.4q-.1.6-.25 1.2v.1q-.05.05-.05.15v.1q-.05 0-.05.1-.05 0-.05.05v.1q-.1.25-.2.55-.1.4-.25.7-.6 1.45-1.6 2.45-.25.25-.55.5-.25.25-.6.45-.4.3-.9.55-1.15.55-2.4.65-1.4.15-2.9-.25-1.4-.35-2.95-1.2-4.55-2.65-7.2-8.15-2.15-4.45-2.05-8.7 0-.85.1-1.75 0-.1.05-.2.15-1.05.4-1.95 1.1-3.55 4.05-5 .5-.25.95-.4.35-.1.6-.2 1.85-.45 3.9.15.1.05.15 0 .45.15.9.35.5.2 1.05.45.15.15.4.25.15.05.35.15.05.05.15.1.6.4 1.15.8-.5-.45-1-.85-.25-.2-.55-.4-.55-.35-1.1-.75z"
                    />
                    <path
                        fill="#1E1E1E"
                        d="M23.9 306.15q-.2-.1-.35-.2-.55-.4-1.15-.8-.1-.05-.15-.1-.2-.1-.35-.15-.3.05-.6.15-.3.1-.65.3-1.35.6-2.25 1.7-.75 1-1.2 2.35-.2.6-.3 1.2-.8 3.85 1.15 7.8 1.9 4 5.4 5.75 3.45 1.75 6.45.3.15-.1.4-.2l.6-.4q.25-.15.45-.4.15-1.05.15-2.1-.05-1.1-.15-2.25-.1.15-.15.3l-.3.5-.3.4q-.55.65-1.3 1-1.95.95-4.4-.4-2.35-1.35-3.75-4.25-.8-1.6-1-3.2-.2-1.2 0-2.4.45-2.75 2.4-3.7.55-.25 1.1-.35.5-.05 1 0h.25q-.5-.45-1-.85z"
                    />
                    <path d="M106.4 276.6l3.3-1.65-7.3-8.65-3.15 1.55 7.15 8.75m-10.1-7.35l-3.1 1.5 7.15 8.75 3.3-1.6-7.35-8.65z" />
                    <path
                        fill="#FFF"
                        d="M87.3 273.65l7.15 8.75 3.3-1.65-7.35-8.65-3.1 1.55z"
                    />
                </g>
            </defs>
            <g>
                <use xlinkHref="#ObjToo052__Layer14_0_FILL" />
                <use xlinkHref="#ObjToo052__Layer14_0_1_STROKES" />
            </g>
            <use xlinkHref="#ObjToo052__Layer14_1_FILL" />
            <use xlinkHref="#ObjToo052__Layer14_2_FILL" />
            <use
                xlinkHref="#ObjToo052__Symbol_211_0_Layer0_0_FILL"
                transform="translate(20.05 284.05)"
                id="ObjToo052__col1n"
            />
            <use
                xlinkHref="#ObjToo052__Symbol_88_0_Layer0_0_FILL"
                transform="translate(4 282.4)"
                id="ObjToo052__col2n"
            />
            <use
                xlinkHref="#ObjToo052__Symbol_19_0_Layer0_0_FILL"
                transform="translate(4 288.15)"
                id="ObjToo052__col2d"
            />
        </g></g>
    )
}

export default ObjToo052
