import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#006AC9",
    "col1l": "#43A6FF",
    "col1n": "#0087FF"
}

function ObjMor089({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1l}
                    d="M186.9 18.5q-8.8-3.35-18.8-6.5-16.95-5.35-33.5-8.1Q67.5-10.05 27.65 26 3.6 49 .4 83.45q-.4 5.6-.4 11.6 0 23.65 5.75 43.55-.1-.55-.2-2.75-3.65-30.05 2-52.75 1.45-6.5 3.9-12.95.75-1.95 1.55-3.9Q20 49.7 31.05 38.9q4-3.95 8.55-7.1Q83.8-.8 155.3 25.1q21.1 5.8 38.4 15.1 45.25 22.2 76.5 65.9 3.45 3.95 6.45 7.8l9 12.6q10.35 16.9 12.7 32.35.3 8.95-1.25 16.5-3.8 18.2-18.45 28.2-3.2 2-6.6 3.55-10.2 5.35-22.8 5.35-12.8 0-23.1-5.55 5.15 4.2 15.7 7.35 13.35 3.9 25.95 2.35-.6 1.4-4.6 3.9-4.3 2.45-5.15 3.1h2.75q15.3-1.65 29.25-13.3 10.95-9.2 15.75-18.95v2.25q-1.3 11.9-14.05 26.45-13.1 14.95-31.45 24.8 1.9 0 9.5-3.5 6.9-3.25 12-6.25h.25q-4.45 10.75-16.65 20-16.05 12.15-41.1 17.25 11.9-.6 23.9-3.4 13.65-3.25 18.6-7.6h.25q-4.65 10.4-24.25 19.35-20.65 9.4-42 9.4-1.25.1-2.2.2 5.7.8 11.4.75 35.5-2.3 62.05-21.6 13.9-8.75 25.7-20.95 5.3-5.7 9.5-12.05 3.1-4.7 5.65-9.7 9.6-18.9 9.6-40.2 0-11.6-2.05-22.95-1.6-8.9-4.45-17.65-4.65-14.25-11.4-24.9-5.35-9.7-11.55-18.75Q255.6 48.05 186.9 18.5m43.15 36.85h.25q.1.1.2.15-.25-.1-.45-.15z"
                    id="ObjMor089__Symbol_219_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M54.5 76.6q1.45.8 3.7 1.4 2.25.55 8.15.35 5.9-.25 11.1-3.9h-.35q-6 1-7.75 1.25-3.05.35-6.9.35-4.4 0-7.3-.55-1.45-.25-3.75-1 1.05.8 1.3 1.05.3.25 1.8 1.05m12-73.05q-3.55-.35-4.3-.45-.75-.05-3.7-.05-4.95 0-7.1.45 1.15.1 2.8.25 3.5.35 5.45.55 1.9.15 2.9.45 1 .3 5.7 1.25 2.95.75 8.35 2.9 1.95.75 5.35 2.05-3.05-2.45-6.35-4.3-2.45-1.4-5-2.5-3.2-.55-4.1-.6M89.65 56.4q5.05-1.7 8.65-4.25v-.1q-2.9 1.05-7.9 1.65-5.05.6-10.95.6-4.5 0-10.8-.85-5.15-.65-8.6-1.45l-5.7-1.8-.5-.2q4.5 4.2 11 6.2 6.45 2 13.1 1.95 6.65-.05 11.7-1.75M84.8 45.6q5.2-1.25 21.5-6-18.7.85-29.4-1.2-10.15-2-17.85-7.55-4.3-3.1-13.6-5.25-8-1.8-14.65-2 1.9-3.65 8.85-6.3 4.25-2.2 13.15-2.2 6.5 0 10.7 1.25 2.65.8 9.4 3.35-.5-.45-.95-.8l-.5-.4Q58.95 5.4 39.9 7.9q-3.65.3-8.05 2.25-3.8 1.7-4.75 2.7 1.65-3.1 7.9-6.55 1.75-1 3.8-2 1.65-.8 3.2-1.45h-.15q.3-.1.6-.15Q47.15.75 51.3.05 50.45 0 49.6 0q-15.8 0-28 7.85-3.7 2.35-7.05 5.45-7.15 6.55-10.8 14.4-1.15 2.45-1.95 5.1Q0 38.75 0 45.4q0 9.55 3.75 17.7 3.65 7.85 10.8 14.4 2.4 2.2 5 4.1Q8.7 68.35 4.3 54.35h.25Q8.5 67.4 21.7 77.2q12.95 9.65 25.85 9.65 8.3 0 12.5-.7 5-.9 11.75-3.7l-3.5.25q-2.4.15-3 .15-12.6 0-18.85-3.45-5.2-2.9-10.65-11.55 4.75 1.7 9.2 2.55 2.55.5 3.75.45.3 0 .5-.05.95.1 3.35.1 5.9 0 15.4-2.35t17.3-6.95q-4 1.1-5.3 1.35-1.8.4-4.95.4-13.95 0-20.7-5.7-2.5-2.05-4.6-5.35-1.35-2.35-2.25-4.75-.4-1.1-.7-2.15 5.7 1.15 7.6 1.4 4.25.55 10.9.55 12.1 0 19.5-1.75m6.85-18.7q-1.95.35-6.05.15-4.15-.25-8.1-1.4-4.55-1.7-7.5-3t-5.55-2.25q-2.65-.9-5.85-1.1-3.25-.25-5.2-.05h-.1q.45-.15-1.45.1-3.35.45-5 .75-.2 0-.3.05-.7.2-1.2.3-1 .25-1.15.3-2 .3-2.6.5-.2.05-.35.1 1.05-.25 3.1-.3.6-.05 3.8-.05 8.4 0 13.6 1.3 3.05.75 8.2 3.95 5.15 3.2 9.55 3.65 4.4.4 6.4.1 1.95-.3 3.15-.7 1.2-.35 2.15-.95.9-.65 4.1-2.45h-.05q-1.65.65-3.6 1z"
                    id="ObjMor089__Symbol_218_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.251}
                    d="M360.4 134.25q-4.8-13.05-10.2-23.9-.964-1.945-2-3.9-37.385-54.265-106.1-83.8-8.8-3.35-18.8-6.5-16.972-5.363-33.55-8.1Q122.686-5.896 82.85 30.1q-24.053 23.074-27.3 57.5-.35 5.602-.35 11.6 0 23.65 5.75 43.55-.1-.55-.2-2.75-3.626-30.037 2-52.8 1.461-6.449 3.9-12.95.73-1.91 1.55-3.85 6.978-16.565 18-27.4 4.025-3.89 8.55-7.05 44.228-32.594 115.7-6.75 21.154 5.869 38.4 15.1 45.313 22.266 76.55 65.95 3.45 3.95 6.45 7.8l9 12.6q10.368 16.912 12.7 32.35.246 1.558.4 3.1-.065-1.722-.25-3.5-2.013-21.503-19.15-48.2-18.593-28.862-48.9-54.75-.188-.079-.4-.15h.25q.093.08.15.15 5.17 2.015 18.4 11.75 13.23 9.785 33.55 32.85 19.145 21.738 25.8 38.1-1.243-3.305-3-8.1z"
                    id="ObjMor089__Layer1_0_FILL"
                />
                <g id="ObjMor089__col1n_0_Layer0_0_FILL" fill={colors.col1n}>
                    <path d="M145.35 28.7Q158.1 14.15 159.4 2.25V0q-4.8 9.75-15.75 18.95-13.95 11.65-29.25 13.3h-2.75q.85-.65 5.15-3.1 4-2.5 4.6-3.9-12.6 1.55-25.95-2.35-10.55-3.15-15.7-7.35-.5-.25-.95-.5-.15-.1-.25-.2-3.05-2.45-6.35-4.3-2.45-1.4-5-2.5-8.85-3.85-19.3-4.1-.85-.05-1.7-.05-4.3 0-8.35.6v.45Q-10.1 29.25 2.7 71.35 6 76.7 11.15 81.4q2.4 2.2 5 4.1 3.2 2.25 6.65 3.95 14.8 7.45 29.4 9.5.95-.1 2.2-.2 21.35 0 42-9.4Q116 80.4 120.65 70h-.25q-4.95 4.35-18.6 7.6-12 2.8-23.9 3.4 25.05-5.1 41.1-17.25 12.2-9.25 16.65-20h-.25q-5.1 3-12 6.25-7.6 3.5-9.5 3.5 18.35-9.85 31.45-24.8M47.65 7.5q.1-.05.2-.05l2.75.35q-1.55-.15-2.95-.3z" />
                    <path d="M47.85 7.45q-.1 0-.2.05 1.4.15 2.95.3l-2.75-.35z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor089__col1n_0_Layer0_0_FILL"
                transform="translate(201.6 195.5)"
                id="ObjMor089__col1n"
            />
            <use
                xlinkHref="#ObjMor089__Symbol_219_0_Layer0_0_FILL"
                transform="translate(55.2 4.15)"
                id="ObjMor089__col1l"
            />
            <use
                xlinkHref="#ObjMor089__Symbol_218_0_Layer0_0_FILL"
                transform="translate(198.2 199.4)"
                id="ObjMor089__col1d"
            />
            <use xlinkHref="#ObjMor089__Layer1_0_FILL" />
        </g></g>
    )
}

export default ObjMor089
