import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2d": "#D9D9D9",
    "col2n": "#EEEEEE"
}

function AccHat043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M314.1 10.15q.5.1 1.05.2-.45-.3-.85-.55-1.45-.8-2.9-1.35-6.5-1.7-12.55.75-.1.05-.15.1-.35.1-.7.3-1.35.5-2.6 1.15-7.1 4.4-10.2 10.75-.05.05-.1.15l-.1.2q-.1.15-.15.35-.05.05-.1.15-.1.15-.15.35-.15.25-.25.5-.2.35-.35.7-.25.45-.5.95v.05q-.6.05-1.5.6-.6.35-1.3.9-.6.45-1.1.9-2.3 1.95-3.85 3.95-1.1 1.5-1.65 2.15-1 1.1-2.4 1.9-2.35 1.3-6.2 4.1-3.7 2.85-5.45 4.15-3 2.25-6.45 4.05-4.2 2.45-6.95 3.9-2.7 1.1-3.75 1.55-.8.4-1.25.8l-1-1.7-.05-.1q.15-.05.35-.05l-10.3-22.45q.95-.2.95-1.55 0-1.9-1.3-4.4-.2-.35-2.7-4.4-.5-.75-1.65-2.25-1.2-1.6-1.55-2.15-.4-.65-.95-1.1l-4.15-9.1Q151.4-8.2 94.45 14.7L79.5 64.45v.45h-.4q-10.35.1-20.65.05l5-.25q-4.8.1-9.5 0-5.65-.15-11.2-.65h-.3Q3.75 63.7 0 92q6.35 16 75.05-15.75l1.4.1q87.6 6.5 168.95-14.3l2-.6q25.65 4.1 41.85-6 35.4-16.55 24.85-45.3z"
                    id="AccHat043__Symbol_261_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.3 85Q3 99 19.55 96.65 40.6 91.1 72.65 76.4q1.35-5.6 4.4-9.85-16.9-1.25-33.95-3.5V63h-.05v.15q-1.1-.2-2.2-.35l-.3-.05Q15.2 59 .6 82.2q-.3.45-.6.95.05.95.3 1.85m331.4-37.45q4.25-16.25-3.05-30.55-6-11.9-12.9-16.45-16.6-3.3-24.4 12.85-.05.2-.15.45-.85 2.75-1.75 5.35-9.3 21.95-29.95 29-.7.15-1.35.35-1.75.5-3.15.95l-4 1.25q-2.55.8-3.5 1.05-.95.25-1.2.25-.25-.05.05.2 2.25 2.8 3.8 6.6l.8.2q2.95.65 5.85 1.25l.6.1q1.1 2.25 9.85 4.65 6.8 1.8 11.85 2.45h8.5q2.55.15 8.5-1.35 3.5-.9 5.15-1.75.35 0 .75-.05 2.4-.2 4.6-.55 1.6-.2 3.1-.5 17.45-3.45 22-15.75z"
                    id="AccHat043__Symbol_169_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M64.1 18.2q-1.3-.75-2.25-1.25-6.7-3.45-14.25 0-.85.3-1.8.8-.95.5-1.6.9.15-5.2 3.25-7.35 3.05-2.15 4.7-2.5.45-.1.85-.2.95-.25 1.35-.4-.5-.4-1.6-.4h-.7q-5.1.05-8.1.95-4 .95-6.9 2.85-2.9 1.85-4.45 3.6.05-2.45.5-3.65.2-.5 2.95-5.4 1.15-2 5.5-5.35l.5-.4q.05-.05.15-.1-.05-.05-.1-.05-.15-.1-.35-.15-.35-.1-.8-.1Q35.7 0 29.9 5.5q-5.7 5.45-6.25 11.85-.85-1.8-1.45-4.7-.3-1.3-.3-7.65-.1-2 0-3.7-2.45 1.15-4.25 8.45-1.1 4.35-1.6 8.9v1.05q-1.85-1.55-4.6-5.3-2-2.55-1.7-4.05-1 1.6-1.2 2.7-.2 1.05.25 3.65.4 2.55.35 3.75-.2-.25-.85-1.55-.65-1.3-3.5-2.85T.5 15.2H0q.15.1.35.2 2.85 2 4.8 7.05 2.4 4.1 2.4 8.35l3.2 7h.05l-.05.05L25.1 69.2l-.3.15.05.05 1.05 1.8q.45-.05.95-.1 12.95-3.4 22.55-9.55 2.05-1.35 3.95-2.8 1.1-.9 2.15-1.75 1.35-1.2 2.65-2.45.3-.3.65-.6v-.05l.7-.7q.7-.75 1.4-1.55.15-.15.3-.35.65-.75 1.3-1.55l.3-.4q.7-.9 1.35-1.8.1-.15.25-.3 0-.05.05-.1l1.4-2.1q.4-.6.75-1.2.55-.9 1.05-1.8-.75-.85-1.5-1.6-1.35-1.4-2.8-2.45-1.55-1.15-3.2-1.95 6.6-1.1 10 .85-1.55-2.3-5.85-5.05-3.35-2.15-5.45-2.75l-2.7-.75q-2.6-.5-1.85-.6.7-.15.45-.15.6-.2 1.65-.3h-.25q8.4-1.6 14.45-.1-4.65-6.6-18-6.25 2.2-3.2 11.5-2.75M41.05 48.75h.2q-.109.004-.2.05v-.05m5.65-5.05q-.15.2-.65.5l-.45-.5h1.1m38.9 51.2q0-2.05-.05-3.55-3.15.25-6.6.3H76.7q-2.75-.05-5.6-.2-1.3-.05-2.6-.15-5.3-.35-11.1-1.15h-.1l-1.6-.2q-.1.75-.25 1.8-.55 4.65-.55 9 0 2.25.2 6.45.2 4.3.4 5.6.5 3.75 1.25 6.05l.75 2.95q.1.45.65 1.65.05.15.15.4v-.45q2.35 4.9 4.55 7.35 3.25 3.6 10.3 5.95 1.006.613 2.1 1.25-1.126-.696-2.05-1.4-3.7-2.75-3.85-5.25v-.4q3.7 3.3 11 6.5 6.25 2.2 10.75 0v-.25q-6.7.25-11.25-2.1-1.95-1-3.25-2.4 1.95.75 4.5.75 2 0 2.5-.25 4.3-.1 6-2.75l-4.05.9q-.9.1-2.7.1-3.95 0-7.45-3.1-.65-.6-1.2-1.2-.1-.65-.1-1.35v-1.05q.95.8 1.3 1 2.8 1.75 11.05 1.5-9.3-1.75-11.6-7.3l.2-4.15q.7-3.55 1.3-7.35 2.6 6.4 9.75 10.5-5.4-4.75-5.25-10.45-.15-6.7 0-10.05 2.85 5.55 8 8-3.25-7.2-3.25-11.5m-14.95 25.55q0 .05-.05.15-.1-.1-.25-.2.15 0 .3.05z"
                    id="AccHat043__Symbol_83_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M25.25 52.6q-.85 1.1-1.3 2.35-1.05 3.55 0 7.25.35.9.5 0 .45-1.3 5.3-4.25-1.5 3.2-1.75 4.5-.2 1.05-.25 3.35v3.85q.35-1.8 2-3.45.75-.75 3.05-2.3-1.4 3.35-.9 8.25.45 4.85 1.05 6.2.6 1.35 2.8 6.6-.55-6.75 2.5-8.5-1.05 5.4.95 10.6 1.2 2.95 4.05 8.65l-.05-6.4v-.6q.05-2.35.4-4.15.15-.85.4-1.6.1 1.4.35 1.6 2.5 6.55 7.8 7.95-1.75-1.55-3-5.7-.45-2.2-.75-3.65-.05-.7-.15-1.25 3.05 2.95 5.9 3.1-.95-.85-2-2.7-1.85-3.9-2.35-4.95-1.4-3.05-1.85-4.2-.5-1.2 1.55 1.9 2.05 3.05 5.65 3.75-.95-.75-2-2.25-1.65-2.4-2.45-4.8-.7-2.3-.9-5.65.15.2.25.4 3.6 5.8 6.45 6.15-1.6-1.15-2.7-5.15-1-5.65-1-5.7 0-.4.05-.75.75 1.15 1.9 2.3 2.5 2.5 4.65 3.1-2.2-3.85-3.1-8 1.7 1.8 5.15 2.85-.55-2.4.15-4.35-2.75-.05-5.6-.2-1.3-.05-2.6-.15-5.3-.35-11.1-1.15h-.1q-3.9-.55-8.05-1.2-2.5-.45-5.05-.9-1.9-.4-3.85-.75m17.8-32.75q.3-.3.65-.6v-.05q-.1-.1-.2-.15-2.25-1.45-3.9-1.65-1.9-.2-3.65.6-3.3 1.5-3.3 1.45 0-2.45 3.35-4.35.85-.5 2.1-1.5-.3-.05-.55-.1-.65-.05-1.25-.1-.2-.1-.45-.1-.15 0-.25.05-.2-.1-.4-.1-.1 0-.2.05-3-.2-4.9.05-2.95.35-4.1.7h.15q-.109.004-.2.05v-.05H26q.2-.3-.55.2 1.05-2.9 3.45-3.8.9-.35 1.5-.65.25-.1.45-.2l.1-.1-.45-.5q-1.3-.15-2.45-.1-5.75.15-7.6 4.55l-.4.3Q20.6 7.7 24.45 4q-3.9.7-7.8 4.15-4.05 3.65-4.95 7.6v-.5q.6-2.25.6-6.15 0-4.35-1.35-9.1-.2 3.75-.7 5.3-.55 1.55-.85 2.9-.3 1.35-.35 1.35l-1.8 3.85q-.35.9-.9 2.25.35-4.55-2.05-7.4L.05 4H0q.75 1.55.75 5.45l.1 5L10 34.5l-.3.15.05.05 1.05 1.8q.45-.05.95-.1Q24.7 33 34.3 26.85q2.05-1.35 3.95-2.8 1.1-.9 2.15-1.75 1.35-1.2 2.65-2.45z"
                    id="AccHat043__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    d="M268.95 95.4l.8-.1-.6-.95q-.345-1.05-.2-1.05l-10.2-22.35-144.15 11.3-7.1 23.5v.45q15.05-.15 30.1-.6l-.05-.55q.2-.05.45.45v.1l5-.2q8-.25 16-.6l6-.3h.05q51.9-2.65 103.9-9.1z"
                    id="AccHat043__Layer8_0_FILL"
                />
                <path
                    fill="#B8B8B8"
                    d="M165 104.5v-.75h.05l-1-25.4-26.75 2.1.7 25.15 5-.2q8-.25 16-.6l6-.3m-5.6-20.75l.6 15.15-18.1 1.15-.45-15.1 17.95-1.2z"
                    id="AccHat043__Layer8_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat043__Symbol_261_0_Layer0_0_FILL"
                transform="translate(28 41.3)"
                id="AccHat043__col1n"
            />
            <use
                xlinkHref="#AccHat043__Symbol_169_0_Layer0_0_FILL"
                transform="translate(27.4 51.1)"
                id="AccHat043__col1d"
            />
            <use
                xlinkHref="#AccHat043__Symbol_83_0_Layer0_0_FILL"
                transform="translate(243.85 24.1)"
                id="AccHat043__col2n"
            />
            <use
                xlinkHref="#AccHat043__Symbol_24_0_Layer0_0_FILL"
                transform="translate(258.95 58.8)"
                id="AccHat043__col2d"
            />
            <use xlinkHref="#AccHat043__Layer8_0_FILL" />
            <use xlinkHref="#AccHat043__Layer8_1_FILL" />
        </g></g>
    )
}

export default AccHat043
