import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D"
}

function FacMou050({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M9.1 1Q8.4.35 7.75.15q-1-.4-2 .15-.45.2-1.05.75-.6-.5-1.2-.6Q1.7.4.8 2-.8 5.55.8 7.95q.65 1.15 1.75 1.6-.1.1-.3.4-.6.8-.75 1.75-.1.35-.1.75 0 1.75 1.3 2.95l.15.15q1.25 1.1 3 1.1.9 0 1.65-.25 2.4-.85 2.75-3.8 0-.85-.25-1.7-.05-.1-.05-.15-.35-.7-.95-1.3-.35-.3-.7-.55-.15-.05-.25-.1 2.4-1.8 2.15-4.85-.1-.35-.15-.75Q9.8 1.8 9.1 1z"
                    id="FacMou050__Symbol_75_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou050__Symbol_75_0_Layer0_0_FILL"
                transform="translate(187.25 216.15)"
                id="FacMou050__col1n"
            />
        </g></g>
    )
}

export default FacMou050
