import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M88 53.5l-1.55-2.25Q75.5 21.6 36.5 4.65 16.6-2.4.05 1.25q-.4.8 2.15 1.45 2.55.65 7.4 2.15 4.8 1.5 8.05 3.6 3.25 2.15 3.2 2.5-.1.3-5.45-.15-5.35-.5-14 2.85 16.35 5.4 22.25 9.45-10.9-2.3-21.5 2.35Q50.6 36.6 78.1 63.2q-3.7-11.15-12.4-17.4 7.4 1.7 17.6 11.35-3.95-10.2-14-20.05 8.05 2.95 18.7 16.4z"
                    id="HaiHig003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <g id="HaiHig003__col1n" transform="translate(108.05 62.75)">
                <use
                    xlinkHref="#HaiHig003__Symbol_3_0_Layer0_0_FILL"
                    transform="matrix(-1 0 0 1 88 0)"
                    id="HaiHig003__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default HaiHig003
