import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#480000"
}

function ShiMot088({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.95 41.95q7.25-7.2 7.25-17.35 0-10.15-7.25-17.4Q34.75 0 24.6 0 14.4 0 7.2 7.2 0 14.45 0 24.6q0 10.15 7.2 17.35 1.4 1.4 2.9 2.55l.4.3.05.05Q16.7 49.2 24.6 49.2q7.75 0 13.85-4.2 0-.05.05-.05.4-.35.9-.65 0-.05.05-.05 1.25-1.05 2.5-2.3M40.55 8.6q3.75 3.75 5.4 8.35h-15.4L25.7 2.05q8.65.35 14.85 6.55m6.65 16q0 9.35-6.65 15.95-.95.95-1.9 1.75L34.1 27.95 46.55 19q.65 2.65.65 5.6m-26.8-5.65h8.7l2.65 8.25L25 32.1q-.15.1-.25.2l-7.05-5.15q.05-.1.1-.25l2.6-7.95m8.05-2h-7.4l3.7-11.45 3.7 11.45m-22 2H18.3l-2.25 7-9.6-7M2 24.6q0-3.1.75-5.85l12.35 9q.15.1.35.15-.15.15-.2.3L10.6 42.35q-1-.85-2-1.8Q2 33.95 2 24.6m1.25-7.65Q4.9 12.35 8.6 8.6 14.95 2.3 23.8 2l-4.85 14.95H3.25M13.4 40.4l3.65-11.25 5.95 4.3-9.6 6.95m11.3-5.75q.05.15.2.25l11.95 8.75q-5.4 3.55-12.25 3.55t-12.25-3.55l12.1-8.75.25-.25M36 40.5l-9.6-7 6-4.3L36 40.5M33.45 26l-2.25-7.05h12L33.45 26z"
                    id="ShiMot088__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot088__Symbol_56_0_Layer0_0_FILL"
                transform="translate(167.9 258.75)"
                id="ShiMot088__col1n"
            />
        </g></g>
    )
}

export default ShiMot088
