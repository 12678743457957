import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999"
}

function BodMor003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.7 7.7q-1.95-2.2-2.95-4.8-.65 2.15-1.25 4.15-5.1 18.3-.7 22.5.95-9.95 4.5-20.6.2-.65.4-1.25M6.95 4.8Q5 2.6 4 0q-6.85 22-1.95 26.65 1-10.5 4.9-21.85m14.4 4.5l.2-.5Q19.6 6.6 18.6 4q-.9 2.95-1.65 5.65-4.55 17-.3 21 1-10.25 4.7-21.35M176.7 0q-1 2.6-2.95 4.8 3.9 11.35 4.9 21.85Q183.55 22 176.7 0m-5.5 7.05q-.6-2-1.25-4.15-1 2.6-2.95 4.8.2.6.4 1.25 3.55 10.65 4.5 20.6 4.4-4.2-.7-22.5m-7.45 2.6Q163 6.95 162.1 4q-1 2.6-2.95 4.8l.2.5q3.7 11.1 4.7 21.35 4.25-4-.3-21z"
                    id="BodMor003__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodMor003__Symbol_6_0_Layer0_0_FILL"
                transform="translate(102.15 300.45)"
                id="BodMor003__col1n"
            />
        </g></g>
    )
}

export default BodMor003
