import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00AFAF",
    "col1n": "#00DEDE",
    "col2n": "#FFFF62"
}

function LegPan024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M127.75 4.55q2-1.65 2.25-3.7.1-.4.1-.75V0q-.85.2-2.95.65-.3.05-.65.1-.15.05-.25.05-.3.05-.65.15-1.65.3-3.95.75-.35.1-.7.15l-.4.1q-.5.1-1.05.2-1.65.25-3.7.55-.85.15-1.8.25-.6.1-1.15.15-1.15.15-2.5.35-1.5.15-3.15.35l-.8.1-1.9.2q-.2 0-.45.05-1.25.1-2.55.25-.35.05-.75.1h-.15q-1.55.15-3.2.3-1.25.1-2.55.25l-.9.1q-1.05.05-2.1.15l-9.2.7h-.1q-.3 0-.6.05l-3.95.2q-1.3.05-2.6.15h-.95l-2.8.1h-2q-.8 0-1.55.05H62q-1.5-.05-3-.05h-.55q-1.05-.05-2.05-.05-.4-.05-.75-.05-1.75-.05-3.55-.15l-3.95-.2q-.3-.05-.6-.05h-.1l-9.2-.7q-.85-.1-1.7-.1-.2-.05-.4-.05-1.8-.2-3.45-.35-1.5-.15-2.9-.25-.15-.05-.3-.05l-.9-.1q-1.3-.15-2.55-.25-.25-.05-.45-.05-.85-.1-1.75-.15-.05-.05-.15-.05-2.1-.25-3.95-.45-1.35-.2-2.5-.35h-.1q-1.55-.2-2.85-.4-2.05-.3-3.7-.55-.15-.05-.25-.05-.65-.15-1.2-.25-.35-.05-.7-.15-2.3-.45-3.95-.75-.3-.1-.6-.15h-.05q-.5-.1-.9-.15Q.85.2 0 0v.1q0 .25.05.6Q.1.75.1.85q.15 1.05.75 2 .55.9 1.5 1.7.1 0 .2.05h.3q.35.05.65.1l.75.1v.05q.55.05 1.1.15.35 0 .7.1l.5.05.6.1q.3.05.65.1l1.15.15q.6.05 1.2.15.25.05.55.1h.15l1.1.2q.45.05.95.1.1.05.2.05l.6.1q2.05.3 4.15.65.15 0 .35.05.5.05 1 .15l.4.1q1.1.15 2.2.35 2 .35 3.95.75h.2q.3 0 .6.1 3.9.7 7.7 1.45h.15q.1 0 .25.05.7.15 1.45.3.2.05.45.1 3.75.7 7.45 1.55.1 0 .2.05 1.05.15 2.1.45l2.8.6q1 .25 2.05.5.2.05.45.1.8.15 1.65.35 2.15.5 4.3 1.05.05 0 .15.05.3.05.6.15.9.2 1.85.45 1.5.35 3 .75.4.15.85.25.5.15 1.05.3.95-.25 1.9-.55 1.5-.4 3-.75.4-.1.75-.15.85-.25 1.7-.45.1-.05.15-.05 2.15-.55 4.3-1.05.1-.05.25-.05.9-.2 1.85-.4 1.05-.25 2.05-.5.85-.2 1.7-.35.55-.15 1.1-.25 1.15-.3 2.3-.5l2.7-.6q2.35-.5 4.75-.95.65-.15 1.3-.25.4-.1.85-.2h.15q.95-.2 1.95-.35.7-.15 1.45-.3.1-.05.25-.05 2-.4 4-.75V8.3q.002.05.05.05.3-.1.6-.1 2.3-.45 4.65-.8l1.7-.3q.7-.15 1.4-.25 1.5-.25 3-.45.75-.15 1.5-.25l.6-.1q.1 0 .2-.05 1.05-.15 2.05-.3l.7-.1q.55-.05 1.1-.15.95-.1 1.9-.25 1.55-.25 3.1-.45l.7-.1q.5-.1 1-.15z"
                    id="LegPan024__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.7 2.25q.05-.75.2-1.45h-.05q-.5-.1-.9-.15Q.85.2 0 0v.1q0 .25.05.6Q.1.75.1.85q.15 1.05.75 2 .55.9 1.5 1.7.1 0 .2.05h.3q.35.05.65.1l.75.1Q4 4.55 3.8 4.2q-.1-.55-.1-1.1v-.35-.25-.25M25 7.05q-.1-.15-.15-.25-.2-.35-.35-.7-.5-1-.65-2.15-.05-.05-.15-.05-2.1-.25-3.95-.45-1.35-.2-2.5-.35h-.1q0 .35.05.75.1.7.3 1.3.15.45.35 1.7.15 0 .35.05.5.05 1 .15l.4.1q1.1.15 2.2.35 2 .35 3.95.75-.5-.85-.75-1.2m18.75-1q-.1-.15-.15-.35l-5.35-.4q-.85-.1-1.7-.1.2.65.55 1.25.15.3.35.6.2.25.45.55 1.15 1.3 2.55 1.3h.1q1.55 0 2.75 1.45.1.15.25.35.35.55.65 1.15 1.05.15 2.1.45l2.8.6q1 .25 2.05.5.2.05.45.1-.4-1.6-1.2-2.8-.9-1.3-2-1.65-.5-.15-1-.15h-.1q-1 0-1.8-.6-.6-.45-1.2-1.25-.35-.5-.55-1m19.6 1q-.15-.25-.25-.5H62q-1.5-.05-3-.05h-.55q-1.05-.05-2.05-.05.15.3.35.6 1.3 1.85 3 1.85h.1q.55 0 1.05.15 1.1.4 1.95 1.65.85 1.25 1.15 2.9.1.7.1 1.5 0 .75-.1 1.45.5.15 1.05.3.95-.25 1.9-.55 1.5-.4 3-.75.4-.1.75-.15v-.3q0-.8-.1-1.5-.05-.2-.05-.35-.3-1.3-.95-2.35-.05-.05-.05-.1l-.1-.1q-.75-1.05-1.6-1.45-.2-.05-.3-.15-.1-.05-.15-.05-.5-.15-.95-.15h-.1q-1.7 0-3-1.85M84.4 8.4q-1.25-.3-2.2-1.7-.2-.3-.4-.65l-3.8.2q-1.3.05-2.6.15l.2.3q1.25 1.85 3 1.85h.1q.5 0 1 .15 1.1.35 2 1.65.65.95 1 2.2.55-.15 1.1-.25 1.15-.3 2.3-.5l2.7-.6q-.25-.45-.5-.85-1.3-1.8-3-1.8h-.1q-.45 0-.8-.15m19.1-.05V8.3q-.95-.45-1.7-1.55l-.05-.05q-.7-1-1-2.2h-.15q-1.55.15-3.2.3-1.25.1-2.55.25.25.9.8 1.65.25.4.55.7.55.6 1.6 2.05.7-.15 1.45-.3.1-.05.25-.05 2-.4 4-.75M117 6.1q.1 0 .2-.05 1.05-.15 2.05-.3l.7-.1q.55-.05 1.1-.15-.35-1.35-.4-1.65-.1-.7-.1-1.45v-.45q-.5.1-1.05.2-1.65.25-3.7.55-.85.15-1.8.25.05 1 .3 1.85.1.4.6 1.65.75-.15 1.5-.25l.6-.1z"
                    id="LegPan024__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.6 2q0-.35.05-.65Q6 1.2 5.45 1.1q-.35-.05-.7-.15Q2.45.5.8.2.5.1.2.05q-.15.8-.2 1.7v.6q0 .55.1 1.1.25.35.45.65 1.45.15 2.9.4l3 .4q.25.05.55.1-.25-1.35-.3-1.8-.05-.15-.05-.4-.05-.4-.05-.8m24.1 7.05q.1 0 .25.05.7.15 1.45.3-.75-.95-1.3-1.15-.4-.1-.85-.1h-.1q-1.7 0-3-1.85-.05-.15-.1-.2-.05-.1-.1-.15-.6-1-.85-2.15-.15-.05-.3-.05l-.9-.1q-1.3-.15-2.55-.25-.25-.05-.45-.05-.85-.1-1.75-.15.15 1.15.65 2.15.15.35.35.7.05.1.15.25.25.35.75 1.2h.2q.3 0 .6.1 3.9.7 7.7 1.45h.15M44.45 5.3q-.3-.05-.6-.05h-.1l-3.85-.3q.05.2.15.35.2.5.55 1 .6.8 1.2 1.25.8.6 1.8.6h.1q.5 0 1 .15 1.1.35 2 1.65.8 1.2 1.2 2.8.8.15 1.65.35 2.15.5 4.3 1.05.05 0 .15.05.3.05.6.15 0-.8-.1-1.5-.35-1.65-1.2-2.9-1.25-1.8-3-1.8h-.1q-.35 0-.7-.05-.65-.2-1.25-.65T47.2 6.3q-.3-.4-.5-.85l-2.25-.15m21.6.65l-.1-.2q-.8 0-1.55.05h-5q.1.25.25.5 1.3 1.85 3 1.85h.1q.45 0 .95.15.05 0 .15.05.1.1.3.15.8.2 1.4 1.1.15.2.3.45 0 .05.05.1.65 1.05.95 2.35 0 .15.05.35.1.7.1 1.5v.3q.85-.25 1.7-.45.1-.05.15-.05 2.15-.55 4.3-1.05.1-.05.25-.05-.05-.1-.05-.2-.05-.2-.05-.35-.3-1.65-1.15-2.9-.65-.95-1.45-1.4-.75-.4-1.55-.4h-.1q-1.75 0-3-1.85M84.6 9.6q.25.4.5.85 2.35-.5 4.75-.95.65-.15 1.3-.25-1.2-1.45-2.7-1.45h-.1q-.95 0-1.8-.6-.65-.45-1.2-1.25-.4-.55-.6-1.15l-5.8.45h-.1q-.3 0-.6.05h-.15q.2.35.4.65.95 1.4 2.2 1.7.35.15.8.15h.1q1.7 0 3 1.8m19.6-4.5q-.5-.9-.7-2.05l-.8.1-1.9.2q-.2 0-.45.05-1.25.1-2.55.25-.35.05-.75.1.3 1.2 1 2.2l.05.05q.75 1.1 1.7 1.55 0 .05.05.05.3-.1.6-.1 2.3-.45 4.65-.8-.35-.65-.45-.75-.25-.4-.45-.85M122.55.05q-.3.05-.65.15-1.65.3-3.95.75-.35.1-.7.15l-.4.1v.45q0 .75.1 1.45.05.3.4 1.65.95-.1 1.9-.25 1.55-.25 3.1-.45l.7-.1q-.4-1.7-.4-2.05v-.25q0-.9.15-1.65-.15.05-.25.05z"
                    id="LegPan024__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan024__Symbol_54_0_Layer0_0_FILL"
                transform="translate(127.45 307.25)"
                id="LegPan024__col1n"
            />
            <use
                xlinkHref="#LegPan024__Symbol_55_0_Layer0_0_FILL"
                transform="translate(127.45 307.25)"
                id="LegPan024__col1d"
            />
            <use
                xlinkHref="#LegPan024__Symbol_56_0_Layer0_0_FILL"
                transform="translate(131.15 308)"
                id="LegPan024__col2n"
            />
        </g></g>
    )
}

export default LegPan024
