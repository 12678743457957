import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M12.15 4.3q.15-.1.25-.2t.1-.25q0-.15-.05-.3-.1-.15-.25-.2Q6.5.7.55 0 .35-.05.2.1.05.25 0 .45q-.05.2.1.35.15.15.35.2 5.15.6 10.2 2.75l-7.7 2.6q-.2.05-.3.25-.1.15 0 .35.05.2.25.3.15.1.35.05l8.9-3M46.6 34.2q-.15-.2-.25-.25-.2-.1-.35.05-5.3 3.6-12.1 3.45-8.25-.25-13.9-5.9l.05-.05q-.7-.6-1.25-1.25-.15-.15-.3-.15-.2-.05-.35.2-.1.15-.1.35 0 .2.15.3.6.7 1.25 1.4 5.8 5.9 14.35 6.1 7.1.2 12.55-3.55.2-.15.25-.3.05-.2 0-.4m51.1 3.25q-6.8.15-12.1-3.45-.15-.15-.35-.05-.1.05-.25.25-.05.2 0 .4.05.15.25.3 5.45 3.75 12.55 3.55 8.55-.2 14.35-6.1.65-.7 1.25-1.4.15-.1.15-.3 0-.2-.1-.35-.15-.25-.35-.2-.15 0-.3.15-.55.65-1.25 1.25l.05.05q-5.65 5.65-13.9 5.9M128.15 7.3q.2.05.4-.05.15-.1.25-.3.05-.2-.05-.35-.1-.2-.3-.25l-7.65-2.6q5-2.15 10.15-2.75.2-.05.35-.2.15-.15.1-.35-.05-.2-.2-.35-.15-.15-.35-.1-5.95.7-11.65 3.35-.15.05-.2.2-.1.15-.1.3t.1.25q.1.1.25.2l8.9 3z"
                    id="SkiMor004__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor004__Symbol_8_0_Layer0_0_FILL"
                transform="translate(126.75 162)"
                id="SkiMor004__col1n"
            />
        </g></g>
    )
}

export default SkiMor004
