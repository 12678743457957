import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiHig021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M69.5 23.95Q67.45 16.1 60.85 9.8 58.2 7.3 55.2 5.35 51.05 2.9 46.3 1.5q-.95-.3-1.9-.45-3.55-.85-7.3-1Q23.45-.6 13.95 7.5q-.5.35-1 .8-.4.5-.75.85-.85.85-1.4 1.9-1.15 2.1-1.45 2.85-.75 1.7-.75 3 0 1 .05 1.35.05.25.2.65 0 .05.05.2h.05q-.2 1-1.35 1.15-.6.1-1.05.2l-.95-.35q-1.4.65-2.5 1.7-.7.55-1.25 1.25Q.25 25.15 0 27.8v.6l.05 1.3q9.1 5.85 18.7 14.35 4.85 4.4 9.15 9.35 1.15 1.35 2.25 2.7 1.05 1.3 2.05 2.6l4.55 6.4q1.55 2.35 2.9 4.8.4.15.65.3 5 1.8 12.3-2.45 1.5-.9 3.05-2 .7-.65 1.25-1.25.35-.45.65-.85 1.2-1.85 1.2-3.8 0-.45-.15-1.25v-.2q-.05-1.05-.5-2.15-.05-.15-.1-.25h-.05q0-.05-.05-.1-.05-.25-.15-.45 1.55-3.15 3-5.9 3-5.7 4.85-7.55.65-.7 3-1.95l.4-.3q.05.2.05.4l.3-.9q.2-.55.35-1.05.6-2.2.75-4.5.15-1.75 0-3.4-.1-3.35-.95-6.35z"
                    id="HaiHig021__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M28.3 8.9q1.2 0 2.45.05-12.35-1.9-21.35-.9-1.75-.05-4 .2-.05.05-.15.1-1.65.15-2.2.3Q5.8 6.2 7.75 4.6q.55-.35.95-.7Q12.85.8 16.05.05q-1.8-.1-3.5.05-1.95.2-3.6.65-1.1.3-3.6 1.1-.5.35-1 .8-.4.5-.75.85-.85.85-1.4 1.9Q1.05 7.5.75 8.25q-.75 1.7-.75 3 0 1 .05 1.35.05.25.2.65 0 .05.05.2 1-.45 2.1-.85.5-.25 1.1-.4 1.35-.45 2.85-.8 1.85-.45 7.6-1.45 1.15-.2 2.35-.35 6.4-.95 12-.7m32.15 25.6q1.2-3.1 1.4-6.45-3.75-8.35-12.8-13.4-2.75-1.55-6-2.8-.45-.1-.85-.2 2.4 1.15 4.6 2.65 2.2 1.5 4.15 3.35 7.65 7.2 9.25 15.05.15.9.25 1.8z"
                    id="HaiHig021__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M9.6 24.4q-1-.65-2.05-1.15L3.1 20.8q-.7.55-1.25 1.25Q.25 24.15 0 26.8q9.15 4.65 15 9.95 2.95 1.95 7.15 5.65.65.65 1.4 1.3.14.093.25.2l-.1-.1q.15.1.25.2 4.3 4 10.65 11.15.65.65 1.2 1.35-.4-.7-4.2-6.7-3.8-6.05-9.55-11.75-.85-.9-1.55-1.55Q26 40.6 31.2 45q5.65 4.8 9.9 9.45 4.2 4.65 6 6.25-.95-1.45-3-4.45-4.1-5.85-6.9-9-2.8-3.15-8.9-9.2-6.05-6.05-12.1-9.7-6.05-3.7-6.6-3.95m36.35 24.05Q43.4 46 42.45 45.3q-.9-.8-1.6-1.2-.65-.4-2-1.5 3.2 3.45 4.5 5.05l1.1 1.4.05.05q1.7 1.95 4.75 6.15.4.55.7 1.05 2.9 4.55 2.65 10.45 1.5-.9 3.05-2 .7-.65 1.25-1.25-.95-5.35-5.3-9.7-1-1.05-1.9-1.9-1.15-1.1-3.75-3.45m23.55-25.5Q67.45 15.1 60.85 8.8 58.2 6.3 55.2 4.35 51.05 1.9 46.3.5q-.95-.3-1.9-.45H44q-.4.05-.35.1-6.75-.35-9.95.1-2.25.3-5.3 1.65 9.75-.45 14.85 2.7-7.55-.95-15 1.45Q23.7 7.4 19.8 9.9l5.6-.9q16.05-1.8 31.1 4.35 8.3 3.7 13 9.6z"
                    id="HaiHig021__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M53.95 8.8Q52 6.95 49.8 5.45 41.9.55 31.3.05q-5.6-.25-12 .7-1.2.15-2.35.35-5.75 1-7.6 1.45-1.5.35-2.85.8-.6.15-1.1.4-1.1.4-2.1.85-.05-.15-.05-.2Q1.2 5.05 0 5.6l5.2 1.7q2.25.75 6.45 2.7l2.25 1.15h-.05q.4.3.5.4.35.2.75.45.25.1.5.3 1.1.55 2.4 1.35 1.85 1.05 3.75 2.35 1.65.95 3.3 1.95Q37 25.6 45.45 33.4q.5.4.95.85 1.8 1.5 2.95 2.65Q50.5 38 51 38.8h-.05q.25.4.4.7.4.7.7 1.35.15.25.25.55 1.6-.2 2.65-.4 1-.2 1.65-.45.65-.25 1.2-.5.6-.3 1.1-.6.55-.3 1-.8l.1-.1q1.05-.75 1.85-2.05.3-.6.6-1.25.4-1 .7-1.9.6-2.1.55-3.8l-.05-2.6q-.05-.7-.2-1.3-.1-.9-.25-1.8Q61.6 16 53.95 8.8m-4.4 7.9h.1q.048.145.1.25-.053-.102-.15-.15l-.05-.1z"
                    id="HaiHig021__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M48.7 11.35q-2.65-3.1-7.45-5.85Q33.9 1.25 27.7.35 15-1 2.1 2.8q-1.05.3-2.1.65 2.25.75 6.45 2.7Q10.2 4.8 17.8 5.1q13.6 1.1 22.35 8.85 8.75 7.7 6.7 16.2.2-.35.8-1.6.65-1.3.85-5.65.2-4.35-.95-6.95.75.55 1.75 1.65 1.7 1.7 2.75 6.1 1.1 4.35.7 8.75 2.2-3.95 1.9-8.9-.3-4.95-5.95-12.2m-4.15 1.75q-.053-.102-.15-.15l-.05-.1h.1q.048.145.1.25z"
                    id="HaiHig021__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M36.55 22.2q0-5.35-5.6-10.95Q27 7.25 17.1 3.2q-.55-.25-1.15-.5-.55-.25-1.1-.4l-.8-.2q-.7-.25-1.4-.45Q9.15.6 5.5.25 4.2.05 2.95.05 2.2 0 .35.05H0q.4.3.5.4.35.2.75.45.25.1.5.3 1.1.55 2.4 1.35Q6 3.6 7.9 4.9q1.65.95 3.3 1.95Q23.15 14.5 31.6 22.3q.5.4.95.85 1.8 1.5 2.95 2.65.25-.35.5-1 .55-1.25.55-2.6z"
                    id="HaiHig021__Symbol_116_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig021__Symbol_119_0_Layer0_0_FILL"
                transform="translate(237.5 39.65)"
                id="HaiHig021__col1n"
            />
            <use
                xlinkHref="#HaiHig021__Symbol_117_0_Layer0_0_FILL"
                transform="translate(246.1 45.3)"
                id="HaiHig021__col1d"
            />
            <use
                xlinkHref="#HaiHig021__Symbol_118_0_Layer0_0_FILL"
                transform="translate(237.5 40.65)"
                id="HaiHig021__col1l"
            />
            <use
                xlinkHref="#HaiHig021__Symbol_115_0_Layer0_0_FILL"
                transform="translate(243.1 54.15)"
                id="HaiHig021__col2n"
            />
            <use
                xlinkHref="#HaiHig021__Symbol_114_0_Layer0_0_FILL"
                transform="translate(248.3 58)"
                id="HaiHig021__col2l"
            />
            <use
                xlinkHref="#HaiHig021__Symbol_116_0_Layer0_0_FILL"
                transform="translate(256.95 65.25)"
                id="HaiHig021__col2d"
            />
        </g></g>
    )
}
export default HaiHig021
