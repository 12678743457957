import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M330.7 81.05l-.05-.3-.2.05v-.05l-2.55.7q-.75.2-1.5.45-31.75 8.4-65.05 10.75-7.15.5-14.35.7 20.35-30.15 51.35-56.95l.3-.3-.95.75q-16.45 11.65-32.25 20.1-4.1 2.25-8.2 4.25l-3.55 1.65q-14.55 6.3-29.15 10.3.2-.6.45-1.4.1-.35.2-.75l.2-.6q.25-.7.55-1.4 12.65-30.75 29.65-58.75l-.3.4q-.229.305-.45.65-.026.037-.05.05l-.1.15-1.1 1.4Q227 47.05 200.5 63l-.05.05h-.1q0-.15-.05-.3-3.8-28.2 3.1-62.5v-.2l-.75 2.35Q191.5 35.9 173.7 57.05q-1.7-7.35-3-14.5l-.1-.5q-2.65-15.05-3.9-29.2l-.4-12.8v.2q-.045.133-.05.3v-.3l-.05.65q-.05 1.3-.15 2.7v-.3l-.65 9.55q-1.25 14.15-3.85 29.2-.1.25-.1.5l-.1.6q-1.6 7.05-3.1 13.75Q139.95 35.1 128.8.25q-.05-.1-.1-.25 0 .15.05.25.1.55.2 1.05l.25 1.1.2 1.2q5.8 32.3 2.2 59.15-.05.1-.05.2-27.5-16.55-55-52.7l.6 1 .45.75q16.9 28.6 29.35 60.1l.3.75q.2.45.4.95-14.4-4.55-29.25-10.95l-3.55-1.65q-4.1-2-8.15-4.25-16.5-8.8-33.65-21.05 1.1.95 2.2 1.95l.1.05Q65.2 64.15 84.9 93.35q-7.1-.2-14.15-.7-34.2-2.4-67.7-11.15l-1.7-.4q43.05 18.1 57.4 35.6l-58.6 4.55-.15.15q33.9 5.95 63.9 18.7l.05-.05q.05 0 .15.05-16 19.25-44.3 37.6-3.6 2.15-6.5 3.95.25-.1.5-.15-.15.05-.3.15 2.45-.85 4.75-1.7l41.45-11.3q4.1-.6 8.05.5 4.3 1.7-.3 9.45-10.45 15.9-36.3 43.5l.2-.2 2-1.95q27.5-27 50.2-31.1l-2.5-9.7q-2.65-15.8-.75-30.6 3-23.1 17.05-43.75 1.1-1 2.25-2 19.8-9.55 41.1 2.4 8.85 3.75 17.65 5.05 7.7 1.1 15.4 0 8.8-1.3 17.65-5.05 21.3-11.95 41.15-2.4l2.2 2q14.05 20.65 17.05 43.75 0 .05.05.1 1.65 14.7-1 30.5l-2.5 9.7q23.1 4.25 50.9 31.65h.05q-24.7-26.5-34.85-41.9-4.2-7.1-.95-9.1 2.7-1.4 6.35-1.25.9.05 1.8.25h.2l41.8 11.45q2.3.85 4.75 1.7-.15-.1-.25-.15.2.05.45.15-2.9-1.8-5.7-3.6-28.75-18.5-44.85-37.95 2.2-.95 4.35-1.8l1.45-.6 42.6-13.2 12.4-2.45-55.65-5.4q14.25-17.45 57.5-35.6m-105.55-10.1l-.15.55-.25.4q.15-.3.25-.6.1-.2.15-.35z"
                    id="HaiSpe016__Symbol_148_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M13.5 181.65q2.45-.85 4.75-1.7l34.35-9.4q12.8-3.25 16.2-.8 2.6 2.2-1.35 8.85-10.45 15.9-36.3 43.5 1.35-1.35 2.65-2.6 27.15-26.55 49.55-30.65l-2.5-9.7q-2.6-15.45-.8-29.95L13.8 181.5q-.15.05-.3.15m50.4-41.55h.2l5.6-8.25q-28.15-6.5-65.65-10.15-.5-.05-1.05-.1l-3-.3v.1q2.25.45 4.05.8 31.6 5.9 59.85 17.9M3.05 81.5l-1.75-.4q43.1 18.1 57.45 35.6l20.4-2.25Q43.5 93.4 3.05 81.5m128.5-18.45l14.95 9.3L128.7 0q6.7 34.5 2.9 62.75-.05.1-.05.2v.1m26.7-5.95q-4.25 18.5-10.7 38.3l6.55 7.75 7.35-60.7q-1.5 7.2-3.15 14.45 0 .1-.05.2M85.1 93.35q11.35.25 16.85-3.55L33.25 36q31.3 27.2 51.65 57.35h.2m21.85-21.25l.3.75q.2.45.4.95v.05q.4 1.15.85 2.35l2.95 12.55q7-5.2 19.05 0l-53.95-78.5q17.55 29.4 30.4 61.85m152.75 80.65l-7.85-4.1-.25-.1q1.9 14.8-.75 30.6l-2.5 9.7q21.6 3.95 47.65 28.8.15.1.25.25.3.25.6.55 1.5 1.45 2.45 2.2-.05-.05 0-.05l2.1 1.85-2.55-2.8q0-.05-.05-.05-.3-.45-2.25-2.6-3.05-3.35-9.95-10.8-11.8-12.75-22.35-27.6-3.65-6.2-1.65-8.5.355-.443.9-.75 2.6-2.1 16.5 1.4l33.65 9.2q2.3.85 4.75 1.7-.15-.1-.25-.15-2.8-1.75-5.9-3.45l-52.55-25.3m3.3-21.2l4.85 8.55q1.543-.652 3.1-1.25l-.35.15q22.85-7.55 45.5-13.95 6.45-2.05 12.45-2.9-38.9 3.3-65.55 9.4M178.85 77.3q-2.9-10.3-5.2-20.2-1.7-7.35-3-14.5l7.2 60.55 6.5-7.75q-3-9.2-5.5-18.1M202.5 4.15q.05-.5.15-.95L185.2 72.35h.05l-.1.45 15.35-9.75-.1-1q-2.8-23.5.8-48.85.6-4.5 1.3-9.05m44.5 89.2q19.95-29.85 51.25-56.85l-68.5 53.3q5.55 3.85 17.25 3.55m7.7-81.85l-.2.4-53.25 76.85q12-5.2 19 0 2.3-10.1 5.9-20.25 4.45-10.8 9.45-21.25 9.1-19.05 20-37-.45.6-.9 1.25m73.2 69.95q-.75.2-1.5.45-38.85 11.9-73.85 32.55l20.65 2.2q14.05-17.45 57.3-35.6l-.05-.25v-.05l-2.55.7z"
                    id="HaiSpe016__Symbol_86_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe016__Symbol_148_0_Layer0_0_FILL"
                transform="translate(26.6 .25)"
                id="HaiSpe016__col1n"
            />
            <use
                xlinkHref="#HaiSpe016__Symbol_86_0_Layer0_0_FILL"
                transform="translate(26.6 .25)"
                id="HaiSpe016__col1d"
            />
        </g></g>
    )
}

export default HaiSpe016
