import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function ObjMus016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M58.8 71.3h-.5l5.15 11.65 2.3-1-4.85-11q-.8.35-2.1.35m13.5 1.8l1.5-2-8.65-6.35q-.1 1.35-.7 2.6l7.85 5.75M1.45 18.25l-1.45 2L43.15 51.8l.6-.1q1.05-.55 2.5-.75l-44.8-32.7M29.55 0L27.3 1.05l22 49.9q1.65.1 3 .6L29.55 0z"
                    id="ObjMus016__Symbol_102_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus016__Symbol_102_0_Layer0_0_FILL"
                transform="translate(64.15 238.7)"
                id="ObjMus016__col1n"
            />
        </g></g>
    )
}

export default ObjMus016
