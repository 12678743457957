import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.25 76.85q-.15.9-.25 1.85.1-.7.2-1.35l.7-3.7q-.35 1.6-.65 3.2m190.7 19.5l-.3.65.6-1.05-.3.4m-11.55-51.4l2.85-2.6Q159.05 17.7 134 7.4 106.6-3.85 77 2 40.7 9.15 1 42.1l8.05 5.2v.1l18.45 11v.05q2 .9 2.45 1.1Q52.25 67.9 77 55.4q1.7-.9 3.45-1.85Q105.3 66.4 134 58.15q7.25-2.05 14.75-5.5l17.75 3.55.15-.05v-.1l12.7-11.15.05.05z"
                    id="HaiSho032__Symbol_153_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M28.6 12.6L10.85 1.9Q2.7 19.95.55 40L0 52.4q0 .95.05 1.9l9.25 5.45q-2.5-15 .95-27.75l4.7.55 13.65-19.9v-.05m162 19.9q-3.2-17-11.3-32.5l-11.55 10.25v.1l-.05.05 7.15 21.95h3.05q.3.5.55 1.05 2.95 10.3 2.2 21v2.95l11.7-7.7q-.05-.4-.05-.75-.55-8.7-1.7-16.4z"
                    id="HaiSho032__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho032__Symbol_153_0_Layer0_0_FILL"
                transform="translate(97.45 56.6)"
                id="HaiSho032__col1n"
            />
            <use
                xlinkHref="#HaiSho032__Symbol_26_0_Layer0_0_FILL"
                transform="translate(96.35 102.4)"
                id="HaiSho032__col1l"
            />
        </g></g>
    )
}

export default HaiSho032
