import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D19532",
    "col2n": "#954A3C"
}

function ObjToy018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.2 6.65q2.95-2.8 5.05-6.55Q16.1-1.15 0 12.65q2.9 1.1 5.85 1.55 1.15.25 2.25.3h.1q8 .8 10.85 9.55l.75 2.9q.9.45 1.7 1.05.9.65 1.75 1.5 3.55 3.7 3.55 8.9 0 3.05-1.2 5.6-.45.9-1.05 1.7l.65 2.6q2.7.6 7.4-1.3 4.05-1.45 5.5-3.35L27.6 21q-3.1-8.1 1.95-13.7.3-.35.65-.65z"
                    id="ObjToy018__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M61.2 24.1Q56.4 10.55 44.35 4q-12-6.6-24.2-2.3-12.2 4.35-17.4 17.05-2.3 5.5-2.65 11.2-.15 2.3 0 4.6.35 5.15 2.2 10.4 4.85 13.6 16.9 20.1 2.1 1.2 4.25 2.05L58.7 54.55q1.2-1.95 2.1-4.25 5.25-12.6.4-26.2z"
                    id="ObjToy018__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy018__Symbol_112_0_Layer0_0_FILL"
                transform="translate(98.2 262.05)"
                id="ObjToy018__col1n"
            />
            <use
                xlinkHref="#ObjToy018__Symbol_27_0_Layer0_0_FILL"
                transform="translate(74.75 207.6)"
                id="ObjToy018__col2n"
            />
        </g></g>
    )
}

export default ObjToy018
