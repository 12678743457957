import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function AccHat061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M152.1 40.55q-15.55-18.65-24.55-25.1Q105.95 0 75.45 0t-52.1 15.45Q14.25 21.95 0 39.3q4.95-1.2 9.9-2.25Q74.75 23.2 141.75 38.1q5.15 1.15 10.35 2.45z"
                    id="AccHat061__Symbol_279_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M152.1 40.55q-15.55-18.65-24.55-25.1Q105.95 0 75.45 0t-52.1 15.45Q14.25 21.95 0 39.3q4.95-1.2 9.9-2.25 2.85-4 6.15-7.8Q31.5 11.5 51.1 5q.5-.15.95-.3.05-.05.1-.05l.3-.1q1.75-.6 3.6-1.05 3.95-1 8.1-1.6.45-.1.95-.15h.2q4.95-.65 10.15-.65 8.65 0 16.7 1.75.3.05.6.15.7.15 1.45.35l.4.1q.3.05.6.15h.05q.4.1.85.25 21.8 6 38.75 25.4 3.75 4.3 6.9 8.85 5.15 1.15 10.35 2.45z"
                    id="AccHat061__Symbol_185_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat061__Symbol_279_0_Layer0_0_FILL"
                transform="translate(117.05 54.9)"
                id="AccHat061__col1n"
            />
            <use
                xlinkHref="#AccHat061__Symbol_185_0_Layer0_0_FILL"
                transform="translate(117.05 54.9)"
                id="AccHat061__col1d"
            />
        </g></g>
    )
}

export default AccHat061
