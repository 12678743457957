import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1n": "#D19532"
}

function ObjMor063({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.1 20.8q1-7.05-1.05-12.4-1.6-4.55-4.55-5.6.05-.25.05-.5 0-2.3-2.45-2.3-1 0-1.95.3-.8.25-1.2.65Q11 .3 7.55 3.5q-3.8 3.6-5.95 9.7Q-.55 19.35.15 24.45q.55 3.85 2.45 5.5.4.3.85.55 1.5 4.15 4.65 4.9 1.35.3 2.75-.05 1.25 1.1 2.9 1.35 3.75.5 7-4.15 3.4-4.65 4.35-11.75M21.05 7.55q.5.95.9 2.25 1.25 4.5.45 10.5-.85 6-3.25 10.05-2.55 4-5.15 3.6-.2-.05-.4-.05.95-.8 1.95-1.9 3.8-4.3 5.45-11.25 1.65-6.95.25-12.45-.05-.15-.05-.25-.1-.3-.15-.5m-3.65-2q-.05-.1-.05-.2.4.55.75 1.3.3.7.6 1.65.1.55.25 1.2.3 1.65.35 3.5 0 3.2-.9 6.95-1.4 5.9-4.25 9.65-1.4 1.8-2.75 2.6-.25-.3-.45-.7-.45-.7-.75-1.55 1.35-.8 2.55-2.3 1.35-1.75 2.4-4.45.05-.25.15-.45 2.25-6.2 2.25-13.9 0-1.8-.15-3.3M13.8 3.9q-1.3 1-2.55 2.75-3.3 4.65-4.3 11.7-.8 5.9.45 10.55-.5.1-1 .1-.6 0-.95-.05-.25-.8-.45-1.8-.85-4.6.55-10.5t4.25-9.6q2.1-2.7 4-3.15m-1.25 4.35q1.4-2.15 2.85-3 .05 1 .05 2.8 0 6.5-1.9 12.7-1.6 5.15-4 7.1-.95-4.25-.25-9.6.85-5.95 3.25-10M7.9 30.7q.05.05.05.1l.1.2q.35.95.8 1.75-.1-.05-.2-.05-1.25-.3-2.1-1.45-.1-.1-.15-.25-.1-.1-.15-.25H7.6q.15-.05.3-.05z"
                    id="ObjMor063__Symbol_156_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.7.55q-.15-.25-.85-.4.05-.05.1-.05Q11-.55 7.55 2.65q-3.8 3.6-5.95 9.7Q-.55 18.5.15 23.6q.55 3.85 2.45 5.5.4.3.85.55 1.5 4.15 4.65 4.9 1.35.3 2.75-.05-1.15-1-2-2.6-.1-.05-.2-.05-1.25-.3-2.1-1.45-.1-.1-.15-.25-.1-.1-.15-.25H7.6q.15-.05.3-.05v-.1q-.3-.85-.5-1.7-.5.1-1 .1-.6 0-.95-.05-.25-.8-.45-1.8-.85-4.6.55-10.5T9.8 6.2q2.1-2.7 4-3.15.15-.15.35-.25l.3-.2q.35-.5.35-1.05 0-.85-.1-1m6.1 5.5q-.1-.45-.3-1-.25-.6-.65-1.4-.4-.8-.6-1.25-.3.4-1.05.7-.8.25-2.05-.05-.55-.15-.85-.2.4.25 2.05 1.6-1.4-.45-1.95-.05.05 1 .05 2.8 0 6.5-1.9 12.7-1.6 5.15-4 7.1.1.45.25.95.1.25.15.55.1.25.2.55l.05.05q1.35-.8 2.55-2.3 1.35-1.75 2.4-4.45.05-.25.15-.45 2.25-6.2 2.25-13.9 0-1.8-.15-3.3-.05-.1-.05-.2.4.55.75 1.3.3.7.6 1.65.1.55.25 1.2.3 1.65.35 3.5 0 3.2-.9 6.95-1.4 5.9-4.25 9.65-1.4 1.8-2.75 2.6.9 1.35 2.2 1.7.95-.8 1.95-1.9 3.8-4.3 5.45-11.25 1.65-6.95.25-12.45-.05-.15-.05-.25-.1-.3-.15-.5-.15-.25-.25-.65z"
                    id="ObjMor063__Symbol_89_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor063__Symbol_156_0_Layer0_0_FILL"
                transform="translate(109.55 295.9)"
                id="ObjMor063__col1n"
            />
            <use
                xlinkHref="#ObjMor063__Symbol_89_0_Layer0_0_FILL"
                transform="translate(109.55 296.75)"
                id="ObjMor063__col1d"
            />
        </g></g>
    )
}

export default ObjMor063
