import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF008C",
    "col1n": "#FF4DAD"
}

function ShiSho007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.35 44.1l-2.85-14.75v-.05h-.05v-.15h-4.55v-29l-1.8 1.1q-.2.1-.4.25-2.05 1.25-4.15 2.4v25.25h-90.5V3.8q-2.2-1.2-4.35-2.5-.15-.1-.25-.2-.1-.05-.15-.05Q15.5.5 14.7 0v29.15l-4.45.05q-.05.05-.05.1l.15.05v.05q-.1-.05-.15-.05l-2.7 14q-.55 2.7-1.1 5.45L3.05 62.85q.25-.95.5-1.8l-.6 2.2-.05.05L1 70.9l-.95 3.6v.25L0 74.8h.2q.25.1.5.15H.85q.85.2 1.75.4 1.2.3 2.4.55 61.75 14 122.7 0l3.2-.7q.45-.15.95-.25H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15z"
                    id="ShiSho007__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.3 47.5q-2.35-3.25-3.55-8.8-.95-4.3-.8-8L7.5 43.35q-.55 2.7-1.1 5.45L3.05 62.85q.25-.95.5-1.8l-.6 2.2-.05.05L1 70.9l-.95 3.6v.25L0 74.8h.2q.25.1.5.15H.85q.85.2 1.75.4.75-.35 1.55-.6 2.6-.85 6.95-.85 5 0 9.55 1.4 4.55 1.4 5.45 1.4 1.4 0 2-.2.8-.25.8-1 0-1.3-9-3.1-9-1.75-9.1-3.8-.1-2.1 6.35-1.7 6.4.35 7.55-.7 1.15-1-3.85-2.3-5-1.3-4.75-3.3.2-2.05.2-2.7 0-3.9-.3-5.85-.45-2.75-1.7-4.55m2.15-46.4q-.1-.05-.15-.05Q15.5.5 14.7 0v29.15h2V1.3q-.15-.1-.25-.2M117.9.15l-1.8 1.1q-.2.1-.4.25v27.65h2.2v-29m7.45 43.95l-2.85-14.75v-.05h-.05v-.15h-.05q.05.4.05.85.15 3.7-.8 8-1.2 5.55-3.55 8.8-1.25 1.8-1.7 4.55-.3 1.95-.3 5.85 0 .65.2 2.7.25 2-4.75 3.3-5 1.3-3.85 2.3 1.15 1.05 7.55.7 6.45-.4 6.35 1.7-.1 2.05-9.1 3.8-9 1.8-9 3.1 0 .75.8 1 .6.2 2 .2.9 0 5.45-1.4t9.55-1.4q4.35 0 6.95.85.8.25 1.55.6.55.25 1.1.55.45-.15.95-.25H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15z"
                    id="ShiSho007__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho007__Symbol_15_0_Layer0_0_FILL"
                transform="translate(126.1 234.7)"
                id="ShiSho007__col1n"
            />
            <use
                xlinkHref="#ShiSho007__Symbol_53_0_Layer0_0_FILL"
                transform="translate(126.1 234.7)"
                id="ShiSho007__col1d"
            />
        </g></g>
    )
}

export default ShiSho007
