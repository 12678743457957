import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.1 17.7q.7-9.7-3.6-17.7-2.7 6.15-8.25 11-5.95 5.15-13 6.9-.25.05-.55.1-.6.1-1.25.2l.2.05q-3.05.6-6.45.6-7.05 0-11.9-3.1-11.8-7.45-17.7.35-5.9-7.8-17.25-.6-5.3 3.35-12.35 3.35-3.4 0-6.45-.6l.2-.05q-.65-.1-1.25-.2-.3-.05-.55-.1-3.7-.9-7.05-2.75-2-1.1-3.9-2.5-1.05-.8-2.05-1.65Q6.4 6.15 3.7 0-.6 8 .1 17.7q.55 8.05 4.4 14.5 2.65 4.5 7 8.25 10.45 9.15 24.2 8.15 3.85-.25 7.35-1.25 2.3-.7 6.55-2.7 4.1-2 12.75-10.75.1-.15.25-.3.1.15.25.3 8.65 8.75 12.75 10.75 4.25 2 6.55 2.7 3.5 1 7.35 1.25 13.75 1 24.2-8.15 4.35-3.75 7-8.25 3.85-6.45 4.4-14.5z"
                    id="BeaMus020__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M251.55 176.9q1.5 4.75 1.5 10.2 0 13.65-9.7 23.3-9.65 9.7-23.3 9.7t-23.35-9.7q-2.3-2.3-4.1-4.85-1.75 2.55-4.1 4.85-9.65 9.7-23.3 9.7t-23.35-9.7q-9.65-9.65-9.65-23.3 0-5.4 1.55-10.2-.1-.1-.15-.25-4.3 8-3.6 17.7.55 8.05 4.4 14.5 2.65 4.5 7 8.25 10.45 9.15 24.2 8.15 3.85-.25 7.35-1.25 2.3-.7 6.55-2.7 4.1-2 12.75-10.75.1-.15.25-.3.1.15.25.3 8.65 8.75 12.75 10.75 4.25 2 6.55 2.7 3.5 1 7.35 1.25 13.75 1 24.2-8.15 4.35-3.75 7-8.25 3.85-6.45 4.4-14.5.7-9.55-3.45-17.45z"
                    id="BeaMus020__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus020__Symbol_37_0_Layer0_0_FILL"
                transform="translate(129.9 176.65)"
                id="BeaMus020__col1n"
            />
            <use
                xlinkHref="#BeaMus020__Symbol_7_0_Layer0_0_FILL"
                id="BeaMus020__col1d"
            />
        </g></g>
    )
}

export default BeaMus020
