import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function ObjMor045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="url(#ObjMor045__Gradient_1)"
                    d="M157.7 237.5q0-14.9-10.6-25.5-10.55-10.55-25.45-10.55-14.9 0-25.5 10.55-10.55 10.6-10.55 25.5T96.15 263q10.6 10.55 25.5 10.55T147.1 263q10.6-10.6 10.6-25.5z"
                    id="ObjMor045__Layer14_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M16.05 1.65q-.85-.45-1.75-.8Q9.9-.8 5.45.85q-.1.05-.25.1-.75.3-1.55.7-.9.45-1.55.95H2q-.85 0-1.25.65-.1.1-.15.25H.5v.25q-.05.1-.05.25-.15.3-.2.75-.1.45-.1 1.85-.2 1.65-.1 4.2v.4L0 15.05q0 2.1.15 2.75.1.45.3.9-.05.05-.05.15l.1.45v14l.3.1q1.5-.05 3.75 1.45 5.6 3.85 5.6 7.8 0 3.1-.75 5.6-.2.75-.45 1.4-.05.05-.05.15-1.25 4.2-4.3 4.35-3.1.15-4.3-.85L.5 59q9.4 4.55 18.25 0V44.1q-2.45-.35-4.3-2.2-2.15-2.15-2.15-5.25t2.15-5.3q1.85-1.8 4.3-2.3v-18q.35-1.15.35-1.55v-.2q.45-.75.55-1.15.05-.25.05-1.5 0-2.45-1.15-3.55-.75-.7-2.5-1.45z"
                    id="ObjMor045__Symbol_145_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.65 7.6l-.05.25v5.2l.05.05v2.6l.05.05v2.05q0 2.25.35 5 .35 2.7 1.6 3.2t2.9-5q.35-3.2.4-3.7v-.55q-.05-.1.3-2.95t.65-3.95q.3-1.15.45-1.55.35-1.15.35-1.55v-.2q-.2.25-.4.55-.3.4-1.05 1.65-.15.5-.5 1.55-.3 2.2-.5 3.75v-.4q-.002.106-.05.25v.5q-.25 1.95-.3 2.7-.1.75-.15 1.65-.2 2.7-.4 3.2-.1.35-.2.65l-.1.4q-.6 1.75-1.3 1.75-.9 0-1.3-3.3-.25-1.9-.25-3.75v-1.95l-.05-.05v-2.6l-.05-.05V11q-.05-.15-.05-.35 0-.65-.05-.75V7.35q-.05-3-1.4-3.9h-.85q-.7.35-1.3.95-.15.25-.25.45-.45.85-1 2.75-.05.15-.1.45-.2.65-.55 1.95-.05.25-.15 1.4-.15 1.1-.75 1.1-.5 0-1.05-1.8-.35-1.25-.7-1.65-.65-.25-.95.85-.35 1.1-.65 2.6-.25 1-.35 2.6-.2 1.45-.3 2.15-.25.55-.4.55-.05 0-.15-.05h-.05q-.35 0-.8-1.1-.15-.35-.2-.7Q0 16 0 16.1l.25 1.1q.35 1.15 1.25 1.5.9.35 1.45-1.6.5-1.95.65-3.7.1-1.45.65-2.35.55 1.75 1.55 2.55 1 .8 1.7-2.05.7-2.9 1.35-4.75.6-1.85 1.8-1.85.5 0 1 2.65M17.2.3q-.1 0-.15-.05-.4-.1-1.1-.1-.1.05-.9.1l-.45.2q-.1 0-.35.1-1.15.9-2 .95h-.1q-.7 0-1.45-.25-.75-.3-2.05-.3-.15 0-1.15.4-1 .35-1.5.35-.55 0-1.6-.8-.55-.4-.8-.65h-.35Q3.2.2 2.85.2h-.2Q2.4.25 1.9 0q.7.4 1.35.8.25.15.55.35.85.6 1.7 1.35h.45q1.25 0 2.85-.85 1.3 0 2.05.55.7.55 1.4.55h.05q.55 0 1.15-.25.2-.25.65-.75.1-.1.2-.25.05 0 .05-.05h.05q.1-.1.25-.2.25-.15.55-.3 1.1-.6 2-.65z"
                    id="ObjMor045__Symbol_82_0_Layer0_0_FILL"
                />
                <radialGradient
                    id="ObjMor045__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={36.813}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(121.65 237.5)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FF0" stopOpacity={0.722} />
                    <stop
                        offset="46.275%"
                        stopColor="#FEFE70"
                        stopOpacity={0.463}
                    />
                    <stop
                        offset="100%"
                        stopColor="#FFF5A2"
                        stopOpacity={0.071}
                    />
                </radialGradient>
                <g id="ObjMor045__Layer5_0_FILL">
                    <path d="M120.9 254.45v2.95h1.4V246.8h-1.4v7.65z" />
                    <path
                        fill="#FF0"
                        d="M123.05 237.45q-.15.45-.75 1.3l-1.45 2.05q-.25.3-.45.7-.55.9-.95 1.9-.2.5-.35 1.1-1 2.4-1.2 3.8-.25 1.4.9 3.15 1.1 1.7 2.1 2.3v-6.95h1.4v6.9q1.45-.55 2.35-2 1-1.6 1-3.8 0-1.3-1.25-4.4-.2-.45-.3-.8-.35-1.35-.55-2.25-.35-1.5-.5-3z"
                    />
                    <path
                        fill="#FFE600"
                        d="M123.65 226.25q-.4-.8-1.1.1.25 4.9-2.75 9.55-1.35 2.15-3.65 4.8-1.6 2.2-1.6 4.55 0 2.65 1.1 5.05 1.55 3.25 4.55 4.15h.7v-.7q-1-.6-2.1-2.3-1.15-1.75-.9-3.15.2-1.4 1.2-3.8.15-.6.35-1.1.4-1 .95-1.9.2-.4.45-.7l1.45-2.05q.6-.85.75-1.3.15 1.5.5 3 .2.9.55 2.25.1.35.3.8 1.25 3.1 1.25 4.4 0 2.2-1 3.8-.9 1.45-2.35 2v.75h.3q3.55-.7 4.7-4.45.6-1.9.6-5.65 0-3.2-.2-4.5-.1-.75-.4-1.8-.35-1.4-1-3.35l-2.35-7.15q-.2-1.1-.3-1.3z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor045__Layer14_0_FILL" />
            <use
                xlinkHref="#ObjMor045__Symbol_145_0_Layer0_0_FILL"
                transform="translate(111.9 255)"
                id="ObjMor045__col1n"
            />
            <use
                xlinkHref="#ObjMor045__Symbol_82_0_Layer0_0_FILL"
                transform="translate(112.3 257.75)"
                id="ObjMor045__col1d"
            />
            <use xlinkHref="#ObjMor045__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjMor045
