import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#004D1E"
}

function ShiMot027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.5 16.25l-6.25.15 4.95 3.8-1.75 6 5.15-3.55 5.2 3.55-1.8-6 4.95-3.8-6.25-.15-2.1-5.95-2.1 5.95M23.8 3q4.6 0 8.25 2.3Q26.6-.05 18.9 0 11.05-.05 5.55 5.5 0 11 0 18.85q0 7.8 5.55 13.35 5.5 5.55 13.35 5.55 7.8 0 13.35-5.55l-.15-.2q-3.7 2.35-8.3 2.4-6.5 0-11.1-4.6-4.65-4.65-4.6-11.1-.05-6.5 4.6-11.1Q17.3 2.95 23.8 3z"
                    id="ShiMot027__Symbol_33_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot027__Symbol_33_0_Layer0_0_FILL"
                transform="translate(173.55 264.4)"
                id="ShiMot027__col1n"
            />
        </g></g>
    )
}

export default ShiMot027
