import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#6486A6"
}

function LegPan001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7 0q-.2.05-.35.1Q62.1 12.35.85.2.6.1.35.1.2.05.1.05.05 0 0 0q0 .05.05.1v19.5q.35-.1.7-.15h.1q32.4-5.15 62 2.9 29.7-8.1 62.3-2.85.15 0 .3.05.15 0 .25.05V.05l.05-.05h-.05z"
                    id="LegPan001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan001__col1n"
            />
        </g></g>
    )
}

export default LegPan001
