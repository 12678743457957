import { AssetSvgProps } from "../../../shared/assets"

function ShiMot101({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot101__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#FFF600"
                        d="M159.2 258v9q33.82 7.027 66.5 0v-9h-66.5z"
                    />
                    <path
                        fill="#8E58A6"
                        d="M159.2 276v9q33.25 4.205 66.5 0v-9q-33.25-5.136-66.5 0z"
                    />
                </g>
                <g id="ShiMot101__Layer1_0_MEMBER_1_FILL">
                    <path d="M159.2 285v9.1h66.5V285h-66.5z" />
                    <path fill="#FFF" d="M159.2 267v9h66.5v-9h-66.5z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot101__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot101__Layer1_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ShiMot101
