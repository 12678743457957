import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ShiSho012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M124 1.9q-1.05.8-2.2 1.6l-1.55 1.15q-3.25 2.25-6.6 4.2-.1.15-.15.3 0 .1-.05.15-1.05 2.5-2.55 4.8-1.5 2.25-3.4 4.35-2.3 2.45-5.2 4.75-.6.45-1.15.85-3 2.25-6.3 4-5.2 2.8-11 4.4-.3.1-.55.15-7.9 2.1-16.9 2.1-20.35 0-34.75-10.65-9.1-6.7-12.45-15.2-.3-.2-.6-.35-3.1-1.8-6.05-3.85L11 3.5l-1.1-.8L6.35 0q-.1.05-.15.15Q4.8 2 3.5 4q8.2 5.75 8.35 18.65l-.2 5.2q-.248 2.584-.8 5.45l.05-.05-3.4 16.2q-.55 2.7-1.1 5.45L2.95 69.2Q1.95 73.1 1 77l-.95 3.6v.25L0 80.9h.2q.25.1.5.15H.85q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-3.65-18.3q-.35-2.05-.5-3.9-.3-2.75-.2-5.25.15-12.9 8.35-18.65h.05Q127.95 2 126.45 0l-1.85 1.4-.6.5z"
                    id="ShiSho012__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho012__Symbol_20_0_Layer0_0_FILL"
                transform="translate(126.1 228.6)"
                id="ShiSho012__col1n"
            />
        </g></g>
    )
}

export default ShiSho012
