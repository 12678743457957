import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiHig017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.5 16.8q-.7-4.2-1.25-6.5Q22.8 8 20.05 0q-2.3 2.6-4.8 5.1-1.6 1.6-3.25 3.1-.45.4-1.1 1-.45.35-1.05.85-.45.4-1.8 1.45l-.7.55.05.15q-.7 15.55-2.8 31.1Q3.4 52.35 0 69.85 6.9 60.8 11.1 48.9q-.15 4-.25 4.5Q9.7 61 9.2 61.5L7 69.15q6.25-6.25 9.45-12.5 3.2-6.3 5.5-17.35 2.75-16.4 2.55-19.85.25-1.25 0-2.65m-13.2 8.05h.15v.05l-.15-.05z"
                    id="HaiHig017__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M21.15 9.75v-.1q-.4-3.5-1.1-9.65-2.3 2.6-4.8 5.1-1.6 1.6-3.25 3.1-.45.4-1.1 1-.45.35-1.05.85-.45.4-1.8 1.45l-.7.55.05.15q-.7 15.55-2.8 31.1Q3.4 52.35 0 69.85q10.2-24.65 13.65-52.6.95 5.35 1.55 8.45.55 2.9-.05 12.85 2-6.5 3.3-11.15 1.3-4.7 2.7-17.65m-9.7 15.15l-.15-.05h.15v.05z"
                    id="HaiHig017__Symbol_57_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M26.25 14.8q-1-4.95-3.15-14.8l.6 5.3q.4 4.55.4 9.3 0 2.1-.1 4.2-.5 14.05-4.7 26.6-2.75 8.3-7.1 15.95v.05q-.45.8-.95 1.6-4.4 7.45-10.4 14.25.7 6.15 1.1 9.65v.1q.8 10.8.55 14.35l.25 22.7q0 1.25-.25 4.1-.3 3.15-.6 4.6-.6 3.7-1.9 5.3 2.85-2.4 7.1-8.9 4.25-6.55 4.15-22.6 2.15 7.75 3.5 16.65.95-4 2.95-13.25 2-9.3 1.8-20.65v-.5q.7 1.95 2.65 6.8.25.7 1.25 6.15 4.6-31.5 1.35-38.85.85 1.65 2.4 4.7 1.6 3.15 2.1 4.45h.25q.5-6.65.25-22.8t-1.4-22.8q-1.15-6.75-2.1-11.65z"
                    id="HaiHig017__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M18.9.15L18.45 0q-2.75 8.3-7.1 15.95.05.05.05.1l-.05-.05q-.45.8-.95 1.6Q6 25.05 0 31.85.7 38 1.1 41.5v.1l.15 1.45q4.8 7.15 5.55 11.8l.2-1.7q.55-5.2.55-9.8 0-2.15-.15-8.1-.15-4.45-.25-6l-.05-.75v-.15q4.25 5.35 6.5 8.95l.05.4-.25-2.65q-.15-2.85-.25-6.15-.1-3.3-.3-5-.15-1.7-.65-4.6-.5-2.75-.75-3.25 4.7 7.8 5.45 10.1 0-3.05.75-13.55Q18.5 1.65 18.9.15z"
                    id="HaiHig017__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig017__Symbol_58_0_Layer0_0_FILL"
                transform="translate(244.6 216.45)"
                id="HaiHig017__col1n"
            />
            <use
                xlinkHref="#HaiHig017__Symbol_57_0_Layer0_0_FILL"
                transform="translate(244.6 216.45)"
                id="HaiHig017__col1d"
            />
            <use
                xlinkHref="#HaiHig017__Symbol_55_0_Layer0_0_FILL"
                transform="translate(263.8 139.2)"
                id="HaiHig017__col2n"
            />
            <use
                xlinkHref="#HaiHig017__Symbol_56_0_Layer0_0_FILL"
                transform="translate(264.65 184.6)"
                id="HaiHig017__col2d"
            />
        </g></g>
    )
}
export default HaiHig017
