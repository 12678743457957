import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function ObjToy028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M81.45 43.45q2.25 2.25 2.25 5.45t-2.25 5.45q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.85 4.2-4.1 7.45-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-2.5.95-5.4.95-6.25 0-10.65-4.4-4.45-4.4-4.45-10.6 0-5.6 3.6-9.75l.3-.3.05-.05q0-.05.05-.05.2-.25.45-.5l.2-.2.05-.05q0-.05.05-.05.15-.15.35-.25.05-.05.05-.1l.1-.9q.05-.3.15-.6 0-.2.05-.4l.1-.4q.15-.85.35-1.9v-.1q.05-.3.15-.6v-.05q.05-.45.15-.85 0-.1.1-.4.05-.45.25-1.35v-.1q.05-.2.1-.35.05-.35.15-.9v-.1q.4-1.8.95-3.6 0-.2.1-.35 0-.15.05-.3.45-1.7.95-3.45l.8-2.6.7-2.1q.3-1 .7-2 .35-1.1.75-2.2-1.1-.7-2.15-1.3-2.4-1.4-4.7-2.6l-1.2-.6Q35.4 1.4 15.85 0h-.75q-1 0-1.7.35-2.65 1.2-2.1 6.5 0 .3.1.65.1.65.2 1.4 0 .15.05.3v.05q-5.15.35-6.1.3-.5-.05-2.25.15-.95.05-1.5.4.7-.55-.4.05-.35.15-.6.45-.65.8-.75 2.45Q-.2 15.35.6 18q.8 2.65.85 2.9.1.25.25.55 4.7 9.9 15.25 19.95Q30.25 54.05 53.4 67l.3.2q2.5 1.4 5.1 2.8l.45.4q2.35 1.25 4.8 2.45 3.25 1.55 6.75 3 22.05 9.45 47 7.75 3.2 0 4.35-1.5.2-.25.35-.55l.1-.2q.15-.4.2-.9v-.3q-.002.05-.05.05-2.8-9.2-7.8-17.55-6.2-9.55-18.3-21.25-8.95-7.25-17.35-13.35l-2.55 13.1v.05h.15l.5.05q-.05 0-.05.05.15 0 .3.05 2.15.45 3.8 2.1z"
                    id="ObjToy028__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M43.6 25.45q.05-.35.15-.9-3-1.85-5.35-3.1l1.5.75q-5.2-3.2-6.4-3.85Q12.35 6.2.5 0q0 .05.05.1.1.6-.25.85Q.15 1 0 1q12.55 6.7 33.5 18.95l.5.3-.35-.25q4.95 2.9 9.85 5.9v-.1q.05-.2.1-.35m65.65 47.85q.25-.15.5-.35.1-.1.25-.15-18.3-15.75-38.05-29.7-.2.6-.5 1.15Q91 57.95 109.25 73.3z"
                    id="ObjToy028__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M11.65 8.85q.3 0 .45-.05.15 0 .3-.05.35-.25.25-.85-.05-.05-.05-.1-.05-.25-.05-.45Q11.9 1.6 13.4 0q-2.65 1.2-2.1 6.5 0 .3.1.65.1.65.2 1.4 0 .15.05.3m109.7 72.25q-1.25.65-3.05.65Q93.35 83.45 71 74q-6.2-2.6-11.8-5.65Q17.25 45.5 4.45 23.25.8 18.15 1.2 11.65q.15-.85.5-1.85h.05l.05-.05q.7-.55-.4.05-.35.15-.6.45-.65.8-.75 2.45-.25 2.3.55 4.95.8 2.65.85 2.9.1.25.25.55Q6.4 31 16.95 41.05q13.3 12.65 36.45 25.6l.3.2q2.5 1.4 5.1 2.8l.45.4q2.35 1.25 4.8 2.45 3.25 1.55 6.75 3 22.05 9.45 47 7.75 3.2 0 4.35-1.5.2-.25.35-.55l.1-.2q.15-.4.2-.9v-.3q-.002.05-.05.05-.05.1-.1.15-.25.3-.55.6-.15.05-.25.15-.25.2-.5.35z"
                    id="ObjToy028__Symbol_55_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy028__Symbol_117_0_Layer0_0_FILL"
                transform="translate(56 242.7)"
                id="ObjToy028__col1n"
            />
            <use
                xlinkHref="#ObjToy028__Symbol_71_0_Layer0_0_FILL"
                transform="translate(68.1 250.85)"
                id="ObjToy028__col1d"
            />
            <use
                xlinkHref="#ObjToy028__Symbol_55_0_Layer0_0_FILL"
                transform="translate(56 243.05)"
                id="ObjToy028__col1s"
            />
        </g></g>
    )
}

export default ObjToy028
