import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col1s": "#BD7B00",
    "col2d": "#C40000",
    "col2n": "#FF0000",
    "col2s": "#770707"
}

function ShiDre034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.6 16.9l.4-.55L135.05.05l-1.35 1.3-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15L127.5 6q-1.8 1.25-3.65 2.4-4.85 3-9.9 5.4l-.25.25.2-.25q-.35.15-.65.3-10.05 4.4-21.15 6.6L73.65 39.5l-18.9-18.9Q43.2 18.4 32.8 13.5q-5.4-2.55-10.45-5.8-1.3-.8-2.55-1.7l-1.55-1.15q-1.45-1.05-2.8-2.1L14.4 2h.1l-2.55-2q-.45.7-.9 1.45L0 16.55 17.85 32.7q-.15 1.6-.3 3.25v.2q0 .7-.05 1.45l-4.3 20.85-9.65 38.5.7.1q.45.05.85.1h.25l3.6.3q2.15.15 4.25.3 1.4.05 2.75.2 2 .1 4 .25.9 0 1.75.1 1.4.05 2.75.15 1.45.1 2.9.15 3.4.2 6.7.35h.1q.2 0 .4.05 1.85.05 3.8.15h.5q.75 0 1.55.05l4.6.2h.8l2.6.1 4.5.1 8.7.2q2.9 0 5.85.05.1-.05.2 0H79.25q.1-.05.25 0 1-.05 2.05-.05h3.75q.65-.05 1.25-.05l3.75-.05 3.7-.1 2.1-.05q1-.05 2.05-.05h.9l.55-.05q.75 0 1.55-.05h.8q.45 0 .95-.05h.3l2.5-.1q.4-.05.8-.05.8-.05 1.55-.05h.55q1.9-.05 3.8-.15 4.85-.2 9.7-.5.15-.05.35-.05 1.4-.1 2.75-.15.9-.1 1.75-.1 2-.15 4-.25 1-.1 1.95-.15v.05l5.7-.4q1.9-.15 3.85-.3l1.3-.1-9.85-39.35q-.2-.8-.35-1.55-.15-.7-.25-1.4l-.7-3.2-2.85-14.75q-.05-.45-.1-.85-.2-1.75-.3-3.45l17.25-15.6m-40.1 67.8v-.05l.05.05h-.05m0 10.05l.05.05h-.05v-.05z"
                    id="ShiDre034__Symbol_231_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.25 97.05q.45.05.85.1h.25l3.6.3V75.6l-5.4 21.35.7.1M16 45.1v-.55l-2.8 13.9v39.3q1.4.05 2.75.2v-52.6l.05-.25m122.6 52.3v.05q1.9-.15 3.85-.3l1.3-.1-5.8-22.8v23.2q.3-.05.65-.05m-5.05-41.25q-.15-.7-.25-1.4l-.7-3.2-1.6-8.2-.05 54.6q1-.1 1.95-.15.4-.05.8-.05l-.15-41.6m.15-54.8l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15L127.5 6q-1.8 1.25-3.65 2.4-4.85 3-9.9 5.4l-.25.25.2-.25q-.35.15-.65.3-10.05 4.4-21.15 6.6L73.65 39.5l-18.9-18.9Q43.2 18.4 32.8 13.5q-5.4-2.55-10.45-5.8-1.3-.8-2.55-1.7l-1.55-1.15q-1.45-1.05-2.8-2.1L14.4 2h.1l-2.55-2q-.45.7-.9 1.45l-5.85 8 7.75 7.3 4.8-5.4 9.65 9.55-4.95 5.6 10.25 10 4.95-5.75 10.6 10.55-4.95 5.35 8.85 8.6 5.3-4.75L67.6 60.65v.05L63.65 65l10 9.15 9.6-9.15-4.15-4.6 9.85-9.35 5.25 4.55 9.45-9-4.75-5 10.85-10.3 4.5 5.2 10.2-10-4.75-5.4 9.7-9.55 4.55 5.2 7.15-7.7.3-.25-6.35-8.75-1.35 1.3m12.9 15.55l.4-.55-2.9-3.9-.3.35h-.05l-70.25 66L2.55 13.05 0 16.55l71.4 64.9v18.4h4.1v-18.7l71.1-64.25z"
                    id="ShiDre034__Symbol_196_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M65.1 62.65l3.5 3.5-5.4 5.5 5.05 5.05-5.05 5 5.05 5.1-5.3 5.3q.65-.05 1.25-.05l3.75-.05 3.7-.1V56.75l-6.55 5.9m-27.95-.05l-6.6-5.95V91.9l8.7.2L34 86.8l5-5.1-5-5 5-5.05-5.35-5.5 3.5-3.55m-25.4-22.95l-4.9 5 4.95 5v.7L7.15 55.1l5.25 5.1-.6.4-5.3 5.1 5.3 5.5-4.95 5.05 4.95 5-4.95 5.05 4.85 4.95h.1q.2 0 .4.05 1.85.05 3.8.15l-4.35-4.35 5-5.1-5-5 5-5.05-5.35-5.5 5.65-5.6-5-5 5.05-5.1-5.35-5.35 3.1-3.05-3-2.7m83.65 5l-4.9-4.95-2.95 2.65 3.05 3.05-5.4 5.35 5.1 5.1-5.05 5 5.7 5.6-5.4 5.5L90.6 77l-5.05 5 5.05 5.1-4.35 4.35q1.9-.05 3.8-.15.2-.05.35-.05h.1l4.9-4.95-5-5.05 5-5-5-5.05 5.35-5.5-5.35-5.1-.5-.7 5.2-4.8-4.7-4.75v-.7l5-5M101.5.7q-4.85 3-9.9 5.4l-40.3 40L10.45 5.8Q5.05 3.25 0 0l51.3 51.2L101.5.7z"
                    id="ShiDre034__Symbol_180_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M12.05 8.35l.3-.3L3.45 0 .35 3.05 5.7 8.4.65 13.5l5 5L0 24.1l5.35 5.5-5 5.05 5 5-5 5.1L4.7 49.1h.5q.75 0 1.55.05l4.6.2-4.6-4.6v-.05l5-5.05-5-5v-.05l5-5-5.35-5.5.35-.35 5.65-4.9-5.65-5.45 5.35-5-.05-.05m67.25-5.3L76.25 0 67.2 8.2l5.65 5.2-5.65 5.45 6.05 5.25-.4.35-5 5.15 5.05 5.05h-.05l-5 5 5.05 5.1h-.05l-4.55 4.6q.45 0 .95-.05h.3l2.5-.1q.4-.05.8-.05.8-.05 1.55-.05h.55l4.35-4.35-5.05-5.1 5.05-5-5.05-5.05 5.4-5.5-5.7-5.6 5.05-5-5.1-5.1 5.4-5.35z"
                    id="ShiDre034__Symbol_157_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.25 22.1h.35V0L7.85 3.4v18.7h3.4M3.75 3.7L0 .2v21.9h3.75V3.7z"
                    id="ShiDre034__Symbol_142_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M2.75 0L.35 2.45l5.3 5.3-5.3 5.3L5.7 18.4.65 23.5l5 5L0 34.1l5.35 5.5-5 5.05 5 5-5 5.05 4.55 4.6q3.4.2 6.7.35L6.75 54.7l4.95-5.05-4.95-5 4.95-5.05-5.3-5.5 5.3-5.1.6-.4-5.25-5.1 4.65-4.75v-.7l-4.95-5 4.9-5L2.75 0M90.4 8.1l4.9 4.95-5 5v.7L95 23.5l-5.2 4.8.5.7 5.35 5.1-5.35 5.5 5 5.05-5 5 5 5.05-4.9 4.95h-.1q-.15 0-.35.05 4.85-.2 9.7-.5l-2.55.1 4.6-4.6-5.05-5.05 5.05-5-5.05-5.05 5.4-5.5-5.7-5.6 5.05-5-5.1-5.1 5.4-5.35-5.3-5.3 5.3-5.3-2.4-2.4-8.9 8.05z"
                    id="ShiDre034__Symbol_133_0_Layer0_0_FILL"
                />
                <path
                    d="M181.6 300.6l-.75.75 5.35 5.5-5 5.05 5 5-5 5.1 5.1 5.1q.1-.05.2 0v-21.95l-4.9-4.55m22.2.75l-.8-.8-4.9 4.45v22.1q.1-.05.25 0l5.1-5.1-5.05-5.1 5.05-5-5.05-5.05 5.4-5.5m15.9-5.2l5.65-5.45-5.65-5.25-6.85 6.2v35.2l2.1-.05q1-.05 2.05-.05h.9l.55-.05q.75 0 1.55-.05h.8l4.55-4.6V322l-5-5.05 5-5v-.05l-5-5 5-5.15.4-.35-6.05-5.25M159.25 322v.05l4.6 4.6h.8l2.6.1 4.5.1v-35.3l-6.9-6.2-.3.3.05.05-5.35 5 5.65 5.45-5.65 4.9-.35.35 5.35 5.5-5 5v.05l5 5-5 5.05z"
                    id="ShiDre034__Layer10_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre034__Symbol_231_0_Layer0_0_FILL"
                transform="translate(118.85 227.25)"
                id="ShiDre034__col1n"
            />
            <use
                xlinkHref="#ShiDre034__Symbol_196_0_Layer0_0_FILL"
                transform="translate(118.85 227.25)"
                id="ShiDre034__col1d"
            />
            <use
                xlinkHref="#ShiDre034__Symbol_180_0_Layer0_0_FILL"
                transform="translate(141.2 234.95)"
                id="ShiDre034__col1s"
            />
            <use
                xlinkHref="#ShiDre034__Symbol_157_0_Layer0_0_FILL"
                transform="translate(152.5 277.3)"
                id="ShiDre034__col2n"
            />
            <use
                xlinkHref="#ShiDre034__Symbol_142_0_Layer0_0_FILL"
                transform="translate(186.5 305)"
                id="ShiDre034__col2d"
            />
            <use
                xlinkHref="#ShiDre034__Symbol_133_0_Layer0_0_FILL"
                transform="translate(141.3 266.55)"
                id="ShiDre034__col2s"
            />
            <use xlinkHref="#ShiDre034__Layer10_0_FILL" />
        </g></g>
    )
}

export default ShiDre034
