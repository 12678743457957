import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#DE5500",
    "col1n": "#FF7B00",
    "col1s": "#B15A03",
    "col2n": "#000000"
}

function AccHat016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M156.65 17.65q.95-3.7.2-6.95-4.35-10.2-19.4-5.8-1.8.4-5.2 1.55-.1 0-.2.05-13 3.75-33 1.95-.95-.1-1.8-.2-2.1-.25-4.2-.45-.75-.1-1.5-.15-1.2-.15-2.35-.3-13.9-1.45-25.65-2.4Q51.8 4 47.95 3.6q-.9-.1-1.45-.15-.25-.05-.45-.05l-.95-.15H45.25l.8.05q-2.5-.3-5-.65Q29.55.2 21.65.1 9.8-.75 3.9 6.25q-.95 1.2-1.45 2.6-.55.85-.8 1.9Q.8 14 .4 17.35l-.2 1.9q-.4 5 .05 8.35.05.25.05.5.35 2.85.5 3.2.1.35 1.1 4.65 5.1 17.1 7.3 34.2.15 1.25.3 2.45 0 .05.05.1v.1q.15.9.3 1.75H150.8q.1-.5.25-1.05.05-.25.1-.55.05-.25.15-.5l.2-.8q.15-.75.2-1.5.1-1.5-.15-3.15-.75-4.9-.9-9.7v-.05-1.45q-.05-1.85.15-3.7.65-7.8 2-15.45.2-1.2.45-2.4.2-.9.4-1.7l.1-.55q.25-1.1.4-2.05l.1-.55q0-.15.05-.3.55-3.45 1.35-6.9 0-.15.05-.3.25-1.3.6-2.65.15-.8.35-1.6z"
                    id="AccHat016__Symbol_234_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M159.15 69.9l.6-.3q3.9-1.6 1.15-3.35-9.45-3.75-26.35-4.4Q76.8 57.25 16.8 63.8q-2.15.35-4.3.75l-.4.1q-1.6.25-3.15.6-3.7.8-7.3 1.95-3.25 1.5 0 3.05 1.75.5 3.55.95 3.15.55 6.35 1 72.85 5.9 138.35-.4 5.45-.55 9.25-1.9M86.75 3.7q0-1.55-2.55-2.65Q81.65 0 78.05 0q-3.65 0-6.2 1.05-2.5 1.1-2.5 2.65 0 1.5 2.5 2.55 2.55 1.15 6.2 1.15 3.6 0 6.15-1.15 2.55-1.05 2.55-2.55z"
                    id="AccHat016__Symbol_59_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat016__Symbol_147_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M61.2 17.15q1.3-1.45 3.05-4.05 2.4-2.4 0-1.25l.8-.45q-9.15 3.5-17.75 5.95-8.65 2.45-16.55 3.5l-.2.5q1.4.7 3.3 1.55 1.9.85 6.5 1.05 4.6.15 8.1-.7 3.45-.85 7.45-2.75 3.95-1.9 5.3-3.35m38.35 30.7Q97 46.8 88.85 44.9q-8.2-1.9-14.2-1.85-6.05.05-12.65 2.1-5.6 1.7-6.9 3.85 3.9-1.15 9.35-1.9 5.3-.75 10.4-.75 3 0 14.65 1 10.75.95 12.45 1.55 0-.05.05-.05l-2.45-1M81.05 7.2q7.6-1.75 10.2-3-1.2-.15-2.35-.3Q75 2.45 63.25 1.5 51.5.55 47.65.15l-.3.6q1.1.6 2.9 1.6l.05.05q.9.35 5.7 2.95-1.6-.95-1.25-.95.35-.05 4.55 1.45T73 7.35h.35q-.249.005-.1.8.15.75 7.8-.95m28.8 14.9q2.9 0 4.65-.45 1.75-.5 1-1.05v-.5q-9.55-3.5-13.9-5.05-6.8-2.45-13.1-4.3-.05 4.65 7.05 8 7.1 3.35 14.3 3.35m45.5-3.35l-.05-.05q-.55 1.1-1.15 2.25-1.4 3-1.8 3.85-2 4-2.8 6.05-1.9 4.75-1.85 7.95 0 7.6-.75 12.35-.25 1.45-.5 2.7-2.9 8.8-19.9 5.35-3-.9-5.3-1.35-8.7-1.85-24.6-3.05-16.05-1.2-32.3-1.2-20.95 0-35.35 3.25-2.65.6-4.95 1.3-.45.15-.85.3-10.9 3.15-13.75-4.9-.15-.3-.2-.55-.7-2.25-1-5.5-.5-5.5-.8-6.8-.6-2.6-2.45-6.15-.9-1.8-3.05-5.35-.45-.75-.85-1.6l-.6.25q.1.35 1.1 4.65 2.9 9.75 4.9 19.55 1.45 7.3 2.4 14.65.15 1.25.3 2.45 0 .05.05.1v.1q.15.9.3 1.75h8.15q.75-1.3 1.3-2.6 4.4-1.05 8.45-2.25 1.05-.3 2.5-.8l8.2-2.75q2.65-.95 5.85-2.1 5.25-1.9 19.2-3.45 1-.1 2.05-.2.75-.15 1.35-.25.3-.05 1.65-.1 10.2-1 18.1-1 8.7 0 15.75 1.6 3.1.7 10.05 2.35 2.7.45 5.05 1 1 .2 2.2.6 2.4.55 5.6 1.85 5.8 2.3 6.75 2.6 1.5.45 2.8 1.1.05.1.1.15.4.6 3.5 4.25h12.35q.1-.5.25-1.05.05-.25.1-.55.05-.25.15-.5l.2-.8q.15-.75.2-1.5.1-1.5-.15-3.15-.75-4.9-.9-9.7v-.05-1.45q-.05-1.85.15-3.7.65-7.8 2-15.45.2-1.2.45-2.4.2-.9.4-1.7l.1-.55q.25-1.1.4-2.05l.1-.55q0-.15.05-.3.55-3.45 1.35-6.9z" />
                    <path d="M47.2.65q.05.05.15.1l.3-.6q-.9-.1-1.45-.15.4.3 1 .65M.5 27.85l.6-.25Q.45 26.2 0 24.65q.35 2.85.5 3.2m154.85-9.1q0-.15.05-.3-.05.1-.1.25l.05.05z" />
                </g>
                <g
                    id="AccHat016__Symbol_114_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M26.2.25l.05-.2q-.1 0-.2-.05-.2.05.15.25M39.9 5.6l-.05.15v.1h.05q-.05-.05 0-.1V5.6M12 21.6h.25l-.15-.3q-.05.1-.05.25-.05 0-.05.05m31.95-9.2l.15.45.7-.3q1.05-.5 1.95-.8.2-.1.4-.15-.25-.05-.5-.05 0 .1-.05.1-.95.25-1.9.55-.4.1-.75.2M73.8 4.55l-.05.15h.45v-.1q-.2 0-.4-.05M84.55 49.5h.1q0-.05-.05-.05l-.05.05M72.9 12.2l.15-.45q-.55-.1-1.1-.25-.7-.2-1.25-.35-.45-.1-.75-.2 0 .05.1.15.1.05.25.1.2.05.45.15l1.1.4q.55.2 1.05.45z" />
                    <path d="M39.85 5.85v-.1l-4.75-2.4V3.3q-.15-.1-1.8-.85-1.7-.8-2.35-1.35-.7-.6-1.5-.65Q28.85.4 27.2.2l-.95-.15-.05.2q.3.2 3.6 2.05 3.25 1.8 10.05 3.55m4.25 7l-.15-.45q-6.7 1.95-13.45 3.65-6.15 1.5-12.35 2.85-1.55.4-3.05.8-.65.15-1.25.3-2.4.45-1.75 1.3l.15.3Q24 19.3 30.9 17.25q2-.65 3.9-1.2 4.2-1.25 9.3-3.2M73.75 4.7l.05-.15q-1.75-.2-3.45-.4-2.45.75-4.1 2.35 5.8-1.7 7.5-1.8m10.8 44.8l.05-.05q-34.05-14.1-49 .5 17.25-11.8 48.7-.4-.15-.1.25-.05m27.15 16.4l1.6.65q9.7 4.65 3.35 0-1.05-.55-2.3-1.15-9.6-4.95-17.65-6.3-8.1-1.4-8.3-1.45-.25-.05-.45-.05-16-2.95-33-2.25Q40.5 56.4 30.1 59.1q-10.4 2.65-12.3 3.3-1.95.6-2.25.7-.35.1-2.75.9-2.45.8-6.85 2.65l-.7.3q-12.3 8.2 4.3 0 4.5-1.65 9.65-3.35 5.4-1.8 12.55-3.45 21.95-4.95 44.2-2.55 3.9.4 7.85 1.05 6.1.85 11.9 2.2 8.4 2 16 5.05m-15.95-5.3l-.05.1q-.05-.2-.05-.35l.05.05v.15l.05.05m1.6-39.1q.9-.75-1.1-1.5l-.7-.2-1.6-.7q-2.7-1.1-5.35-2.15l-2.3-.9-3-1.1q-4.95-1.85-10.25-3.2l-.15.45 24.45 9.3z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat016__Symbol_234_0_Layer0_0_FILL"
                transform="translate(111.85 16.45)"
                id="AccHat016__col1n"
            />
            <use
                xlinkHref="#AccHat016__Symbol_147_0_Layer0_0_FILL"
                transform="translate(112.15 19.9)"
                id="AccHat016__col1d"
            />
            <use
                xlinkHref="#AccHat016__Symbol_114_0_Layer0_0_FILL"
                transform="translate(130.7 19.65)"
                id="AccHat016__col1s"
            />
            <use
                xlinkHref="#AccHat016__Symbol_59_0_Layer0_0_FILL"
                transform="translate(109.25 24.4)"
                id="AccHat016__col2n"
            />
        </g></g>
    )
}

export default AccHat016
