import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E2FFFF"
}

function BodWin006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.651}
                    d="M10.25 151.9q3.35 7.2 7.85 13.95 5 7.5 11.45 14.5-.95.4-1.9.85-2.2.95-4.25 2.25-1.6 1.05-3.05 2.2-9.1 7.3-11.15 18.8-2.45 13.35 5.95 24.65 8.4 11.25 22.75 13.85 14.25 2.6 26.05-4.95 11.85-7.55 15.7-50.35-4.8-23.2-11.95-45.4Q52.85 74.85 27.8 14.3 13.95-19.1 6.2 25.5q-12.4 65.3.05 116.75 1.75 4.9 4 9.65m65.1 62.15l.1-.55h.3q-.2.3-.4.55m159.3-33.7q1.35-1.5 2.65-2.95 3.55-4.15 6.65-8.45 4.1-5.7 7.4-11.7 3.9-7.3 6.6-15Q270.4 90.8 258 25.5q-7.75-44.6-21.6-11.2-25.05 60.55-39.9 127.95-7.15 22.2-11.95 45.4 3.85 42.8 15.7 50.35 11.8 7.55 26.1 4.95 14.3-2.6 22.7-13.85 8.4-11.3 5.95-24.65-2.25-12.55-12.8-20.05-.7-.5-1.4-.95-3-1.85-6.15-3.1z"
                    id="BodWin006__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodWin006__Symbol_14_0_Layer0_0_FILL"
                transform="translate(60.4 49.15)"
                id="BodWin006__col1n"
            />
        </g></g>
    )
}

export default BodWin006
