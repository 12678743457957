import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.8 9.95l-.15.15q-.2.3-.45.6-3.05 2.8-9.9 2-.2-.05-.45-.1h-.2q-2.55-.4-4.05-.8-3.45-.95-5.3-3.7-.7-1.55-.5-3.05.65-2.35 2.6-3.35.2-.1.35-.2.85-.4 1.85-.8 1-.35 2.15-.7-1.05.15-2.15.25-3.1.35-6.2.4-3.1-.05-6.2-.4-1.1-.1-2.15-.25 1.15.35 2.15.7 1 .4 1.85.8.15.1.35.2 1.95 1 2.6 3.35.2 1.5-.5 3.05-1.85 2.75-5.3 3.7-1.5.4-4.05.8h-.2q-.25.05-.45.1-6.85.8-9.9-2-.25-.3-.45-.6L0 9.95q1.65 3.1 4.35 4.95 1.6 1.2 4.9 1.85Q16 17.95 22.4 18q6.4-.05 13.15-1.25 3.3-.65 4.9-1.85 2.7-1.85 4.35-4.95z"
                    id="BeaChi005__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <g id="BeaChi005__col1n" transform="translate(170.1 233.9)">
                <use
                    xlinkHref="#BeaChi005__Symbol_5_0_Layer0_0_FILL"
                    id="BeaChi005__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default BeaChi005
