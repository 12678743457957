import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#004D1E",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M1.25 0Q.75 0 .4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0z"
                    id="AccGla007__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M60.5 34.35q5.35-8.85 5.15-19L63 7.3 28.05 0l-28 1.35v14H0v.25q.05.65.1 1.35v.7Q.3 20.8.8 24q.4 3.75 1.55 6.6 3.05 10 6.2 11.6 3.15 1.55 8.85 2.75 5.65 1.2 14.5 2.3 18.4 1.15 28.6-12.9m64.4 8.25q3-1.05 6.2-12 1.15-2.85 1.55-6.6.5-3.2.7-6.35 0-.35.05-.65 0-.7.05-1.4 0-.1.05-.2l-1.9-13.05L102.4 0 70.2 7.3l-2.4 8.1q-.05.05-.1.05l-.05-.1q0-.2-.05-.4.1 11.2 6.5 20.7Q84.15 47.9 100.05 47.4q9.35-.9 15.6-2.35 6.25-1.45 9.25-2.45z"
                    id="AccGla007__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M191.7 8.45V2.7L161.6.9q-9.15-1.15-25.25-.85-16.1.3-26.6 3.5-3.7 1.1-6.55 1.8-3.1 1.15-6.35 1.35h-.8q-3.65-.05-7.4-1.35-2.85-.7-6.55-1.8Q71.6.35 55.5.05 39.4-.25 30.25.9L0 2.8v5.65q16.05.5 23.35 1.9 4.8 1.2 5.75 5.85h1.5q2.1-1.3 2.5-4.65.3-2.55.25-1.6 1-8.2 18.8-7.85 16.7.3 30.75 4.5 1.75.5 2.45.8.65.2 2.55 1.2 1.8.95 2.85 4.05.7 2.15 4.05 3.55h.05q.35-5.95 1.2-4.7.4 1.9.7 4.3.05.2.05.4l.05.1q3.55-1.5 4.25-3.65 1.05-3.1 2.85-4.05 1.9-1 2.55-1.2.7-.3 2.45-.8Q123 2.4 139.7 2.1q17.95-.35 19.35 9.6 0 .1.05.15 0 .25.05.5v.3q1.4 4.3 3.5 3.6.05-.05.1-.05.95-4.65 5.75-5.85 7.25-1.4 23.2-1.9z"
                    id="AccGla007__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla007__Symbol_98_0_Layer0_0_FILL"
                transform="translate(191.3 155.55)"
                id="AccGla007__col2n"
            />
            <use
                xlinkHref="#AccGla007__Symbol_77_0_Layer0_0_FILL"
                transform="translate(125.75 146.25)"
                id="AccGla007__col2l"
            />
            <use
                xlinkHref="#AccGla007__Symbol_30_0_Layer0_0_FILL"
                transform="translate(96.6 145.4)"
                id="AccGla007__col1n"
            />
        </g></g>
    )
}

export default AccGla007
