import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00"
}
function ShiDre001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.85.25q.05-.15.1-.25l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-5.55 3.8q-.7.4-1.4.85v.3q-.2 1.75-.55 3.4-.9 3.8-2.85 7-5.5 8.2-15.95 11.95-26 8.55-53.8.95Q36.65 28 30.55 20.7q-4.3-5.35-5.05-12.55-.8-.45-1.55-.95-1.65-1-3.2-2.05l-.7-.5L18.5 3.5q-1.45-1.05-2.8-2.1L13.9.05l.05.1V.2q5.1 10.35 4.4 27.2-.15 3.45-.55 7.2-.05.4-.1.85l-2.7 14q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8l-.95 3.6v.25H7.5Q4.75 88.55 0 93.9h.4q73.75 6 147.5 0-4.6-4.9-7.7-13.2h-.05v-.1l-6-24.25q-.65-3.1-1.3-6.15L130 35.45q-.05-.45-.1-.85-.4-3.75-.55-7.2-.7-16.9 4.5-27.15z"
                    id="ShiDre001__Symbol_86_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre001__Symbol_86_0_Layer0_0_FILL"
                transform="translate(118.6 228.6)"
                id="ShiDre001__col1n"
            />
        </g></g>
    )
}
export default ShiDre001
