import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#D96900"
}

function ShiSpe071({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.75 34.95q-.2-.8-.4-1.65l-.1-.4q-.1-.25-.15-.55-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-1.6-3.6-3.35-7.05-.15-.2-.25-.4-.05-.1-.05-.2-.95-1.8-1.95-3.5-1.75-3.1-12-1.4Q130 1.9 120.5 4.15q-1.95.85-3.9 1.6-4.4 1.6-8.95 2.8-.15.05-.25.05-2.15.55-4.3 1-4.15.85-8.4 1.35-.95.1-1.85.25l-1.3.1h-.25q-.15 0-.3.05l-1.6.05q-1.7.1-3.4.15-.5 0-1 .05h-4.55q-.8-.05-1.55-.05H77.95q-1.2-.1-2.35-.15l-1.1-.05q-.4-.05-.75-.05-1.4-.1-2.8-.25-2.05-.25-4.1-.55-2.85-.45-5.6-1.05-1.9-.4-3.8-.9-4.5-1.15-8.8-2.75-1.4-.5-2.8-1.1-1.25-.2-2.85-.45Q25.95 1.2 15.5 1.5q-1.15 1.95-2.2 4-.05.05-.05.1v.05q-.2.4.1-.1-2.2 4.1-3.7 7.55-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35-.1.2-.1.4-.1.3-.15.6-.25.8-.4 1.6-.55 2.15-.95 4.35l-.2 1q-.05.1-.05.25Q.5 43.4.1 46.4q-.05.3-.1.65.05-.1.15-.15.1-.05.15-.1l5.8-2.95q.4-.1.75-.15H6.8q1.4-.3 2.9-.35h1.55q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15 3.05 1.1 5.8 3.55v.05q.1.1.2 1l.2-.65.05.05q28.1 7.3 48.2 13.05h30.45q17.1-5.55 42.7-12.9l-.05-.1.05-.05q.2-.2.4-.35v-.05q2.75-2.45 5.8-3.55.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05h1.55q.6.05 1.25.1 4.45.4 7.75 3l.2.2.5.4q-.05-.35-.1-.65-.4-3.05-.95-5.95v-.1q-.05-.1-.05-.2-.1-.5-.15-.95-.3-1.3-.55-2.55-.25-.9-.45-1.75z"
                    id="ShiSpe071__Symbol_350_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M0 29.4q11.3-6.7 21.85 1.25.05.05.15.1l.2-1q-10.7-8.2-22-1.35l-.2 1m65.55-15.7L50.25 1l.8 1.8L65.4 15.05l2.5-1.3-.15-.15q-.25-.25-.45-.8l-1.75.9m31.2.05l-1.3-.65q-.15.3-.35.5l-.15.15q0 .05-.15.2l2.1 1.1 15-12.8L113.05 0l-16.3 13.75m66.05 15.6q-.1-.5-.15-.95-11.35-6.85-22.05 1.4l.2 1q.1-.05.2-.15 10.55-7.9 21.8-1.3z"
                    id="ShiSpe071__Symbol_351_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M130.8 5.2l-.7-1q-1.4-2.15-2.9-4.15l-1.8 1.3q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75-5.9 3.25-12.15 5.65-.1.2-.15.5L86.2 45.65q-3.5 4.5-10.5 9.85-1.45 1.05-3 2.2-.8.5-1.8 1.25-1.5 1-3.1 2.05l-.4.3q-.35.6-.9 1.4l-.5-.5q-2.6-1.9-4.9-3.7-10.3-8-14.25-13.2l-13.5-30.25q-5.9-2.2-11.5-5.2-4.35-2.4-8.5-5.25L11.8 3.45Q10.35 2.4 9 1.35L7.2 0Q5.7 2.05 4.3 4.15l-.7 1Q1.8 7.9.2 10.75q-.95 3.9 2.5 7.05 9.4 7.15 8.25 17.4-.05.05-.05.1l.05-.05q.05.1.05.2-.05.05-.05.2L6.45 57.2l.1.1-5.7 23.5q.1.05.25.05.25.1.5.15H1.75q1.3.3 2.65.6v.05l1.4.3q24.85 5.65 49.6 6.75l5.7.2q2.35.05 4.7.05.4-.7.7 0h.7q2.95 0 5.85-.05 3.85-.1 7.65-.25 23.95-1.25 47.8-6.7 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05-5.6-23.2h-.05l-.05-.1.1-.05-4.45-21.6v-.05q-.05-.2-.05-.4l-.05-.05q-1.45-11.45 8.25-17.7.15-.15.35-.25 4.1-2.7 2.5-6-1.75-3.1-3.65-6.05z"
                    id="ShiSpe071__Symbol_352_0_Layer0_0_FILL"
                />
                <g id="ShiSpe071__Layer1_0_FILL">
                    <path d="M186.5 308.65q-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65m4.9-9.25l-.15-.15q-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55.15.15.35.3.55.35 1.25.35.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05q-.15-.5-.45-.9m.75-39.65h-.25l-.1 31.6-.1-.1 6.5 7.15-7.2 19.15.05.05h.75q.182-.019.4-.05l.3-1.05v-.05l7-18.55-6.45-6.65.05-31.45h-.95v-.05z" />
                    <path
                        fill="#FC0"
                        d="M239.4 259.8l-4.9-2.25h-3.45l-4.9 2.25v18.15q.7.4 1.45.65 2.25.85 4.4 1 .35.05.75.05h.05q.35 0 .7-.05 2.15-.15 4.4-1 .75-.25 1.5-.65V259.8z"
                    />
                    <path
                        fill="#FFF"
                        d="M195 249.45q-.555.037-1.1.05h.7q.2-.012.4-.05z"
                    />
                    <path
                        fill="#830000"
                        d="M198.15 251.05q-.2-.65-.7-1.15-.4-.4-.9-.45H195.95q-.458.015-.95 0-.2.038-.4.05h-2.7q-.746-.003-1.5-.05-.756.023-1.55 0-.087.003-.2 0-.076-.003-.15 0-.521.044-.9.4-.504.537-.75 1.2-.158-.188-.35-.35-.267-.245-.55-.45-4.337-1.82-8.1-1.15-1.25.464-1.7 1.6-1.678 4.776 0 9.7.425 1.479 1.1 2.9.038.052.05.05v.05q.22.371.5.55 1.362.897 4.8-1.65 1.134-.696 2.75-2.05.647-.613 1.15-1.1.17-.153.3-.3.35-.31.6-.55.186.294.4.5.123.123.25.2 1.206.916 3.85.75h.25v.05h1q2.65.1 3.8-.75.2-.15.3-.25.2-.2.4-.5l.55.55q.145.134.3.25v.05q.5.5 1.15 1.1 1.6 1.35 2.7 2.1 3.5 2.5 4.8 1.65.35-.25.5-.6h.05v-.05q.7-1.45 1.15-2.9 1.7-4.95 0-9.7-.45-1.2-1.7-1.65-3.75-.65-8.1 1.15-.3.2-.55.45v.05q-.172.14-.35.3z"
                    />
                </g>
                <g id="ShiSpe071__Layer1_1_FILL">
                    <path
                        fill="#E50000"
                        d="M232.75 260.1l-1.45.9v3.7h-1.65v-2.95l-2.05 1.05v13.65h10.3V262.8l-2.05-1.05v2.95h-1.65V261l-1.4-.9v-.05l-.05.05z"
                    />
                    <path
                        fill="#480000"
                        d="M186.85 251.05q-.426-.462-.95-.85-.4 1.17-.4 1.25-.05.15-.05 1.55 0 2.787.65 4.5.195.53.45.95.132.216.25.4.35-.31.6-.55l-.15-.25q-.193-.396-.35-1-.35-1.32-.35-3.45 0-1.513.3-2.55m12.25-.8q-.45.35-.95.8.35 1.05.35 2.55 0 2.15-.35 3.5-.037.137-.1.25-.113.412-.3.75l-.1.2.55.55q.068-.09.1-.2.118-.09.2-.2.182-.292.35-.7.046-.101.1-.25.65-1.7.65-4.5 0-1.4-.05-1.55 0-.1-.45-1.2z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe071__Symbol_350_0_Layer0_0_FILL"
                transform="translate(110 237.9)"
                id="ShiSpe071__col1n"
            />
            <use
                xlinkHref="#ShiSpe071__Symbol_351_0_Layer0_0_FILL"
                transform="translate(111.1 248.75)"
                id="ShiSpe071__col1s"
            />
            <use
                xlinkHref="#ShiSpe071__Symbol_352_0_Layer0_0_FILL"
                transform="translate(125.3 228.65)"
                id="ShiSpe071__col2n"
            />
            <use xlinkHref="#ShiSpe071__Layer1_0_FILL" />
            <use xlinkHref="#ShiSpe071__Layer1_1_FILL" />
        </g></g>
    )
}

export default ShiSpe071
