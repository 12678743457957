import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFD446"
}

function AccJew050({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.5 23.8q0-9.85-4.35-16.8-4.3-7-10.4-7h-.3q-1.2.05-2.3.4Q10.5.8 9 1.85q-.95.65-1.85 1.55l-.3.3Q5.5 5.1 4.35 7 0 13.95 0 23.8q0 8.8 3.45 15.35.1.1.15.25.1.2.25.35l.5.9q4.3 6.95 10.4 6.95 6.1 0 10.4-6.95 4.35-7 4.35-16.85M23.6 8.55q1.95 3.35 2.8 7.4.8 3.6.8 7.7 0 8.85-3.6 15.1-.5.9-1.05 1.75-.45.55-.85 1.15-3 3.4-6.75 3.4-5.1 0-8.7-6.3-3.6-6.25-3.6-15.1t3.6-15.1q3.6-6.3 8.7-6.3 5.05 0 8.65 6.3z"
                    id="AccJew050__Symbol_118_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew050__Symbol_118_0_Layer0_0_FILL"
                transform="translate(85.35 177.55)"
                id="AccJew050__col1n"
            />
        </g></g>
    )
}

export default AccJew050
