import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M193.7 24.05q-.05-1.85-.05-3.65v-.95q-.05-5.8-.05-11.65l-13.05 18.55-7.7-19.05-9.75 20.1L148.9 0l-18.35 19.05-7.6-14.65-15.8 15.15L93.35 0 80 14.1l-.3.05-8.25-11.7-12.15 17.1L38.6 5.4l-3.25 14.7L19.5 7.15 17 19.85l-17-15V147.9q.65 8.7 1.4 12.3.55 2.95 1.45 5.5h.4q9.9-26.85 18.95-44.65 6.9-13.65 17.4-19.4 3.5-1.95 7.45-3 6.5-1.75 11.85-2.2 6.65-.65 11.5.7.5.1 1.05.3 5.7 1.95 13 3.05 6.1 1.25 12.95 1.15 2.2-.05 4.5-.2 4.45-.35 8.5-.95 7.25-1.1 12.95-3.05.55-.2 1.1-.3 4.8-1.35 11.5-.7 5.3.45 11.8 2.2 3.95 1.05 7.45 3 10.5 5.75 17.4 19.4 9.15 18 18.3 42.55l.2.5.55.8.1-.4q2.45-11.25 2.75-22.85v-11.4q-.05-.4-.05-.8l-.25-4.3q-.1-1.75-.25-3.55-.05-41.1-.2-82.25V33.2q-.05-1.1-.05-2.25v-6.9z"
                    id="HaiSpe013__Symbol_145_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M193.65 18.95q-.05-5.8-.05-11.65l-13.05 18.55-7.7-19.05-9.75 20.1L148.9-.5l-18.35 19.05-7.6-14.65-15.8 15.15L93.35-.5 80 13.6l-.3.05-8.25-11.7-12.15 17.1L38.6 4.9l-3.25 14.7L19.5 6.65 17 19.35l-17-15v143.3q.65 8.7 1.4 12.3.55 2.95 1.45 5.5.2-.6.25-1.2-1.05-18.95.8-59.65L4 92.35h.1l.55-69.65Q4 13.1.45 5.25 6 13.6 12 23.05q2.3 3.65 4.3 6.8 2.15 3.5 3.95 6.35 1.9-2.7 6.1-6.8 1-1 2.15-2.1.75.2 5.2 4.1.2.15.4.35 3.95 3.45 1.95 1.5 1.7-1.95 4.15-4.6 1.7-1.9 3.8-4.1.5.55.95 1.05 4.3 4.8 5.65 7.15l4.25-8.5L67.9 33.7q1.25-1.4 3.15-4.3 1.45-2.35 2.3-3.8.2-.45.4-.8 2.25 2.05 6.35 6.5 1.65 1.8 3.65 4 1.8-1.35 4.55-4.9.9-1.2 1.95-2.7 2.1-2.9 4-5.9h.5q1.25 2.45 2.65 4.6 2.75 4.3 5.85 7.4.45-.2 3.3-3.4.1-.15.25-.3 3-3.3 3.45-3.3.75 0 4.45 4.5l.8 1q.6.6 6-6.7l.1-.2q1.05-1.5 2.2-4.4 3.65 4.4 7.3 7.85.6.55 1.1 1.05l.4.4 12.25-12.8 8 12.85 3.65-3.5 7.35 8.5q1.15-.25 3.7-4.45.45-.8.95-1.5 2.6-4.3 4.8-7.2 2.85 4.5 5.1 7.45.05.05.15.2 2.15 2.75 2.6 4.9 2.75-5.3 10.7-23.7-2.55 7.4-3.85 18.65l1 56.65h.05l.05 4.75h-.05l.45 24.7q.4 5.9.75 12.05.8 17.05.65 35.5l.2.5.55.8.1-.4q2.45-11.25 2.75-22.85V130q-.05-.4-.05-.8l-.25-4.3q-.1-1.75-.25-3.55-.018-14.497-.05-29V91.1v-1.75-3q-.059-23.75-.15-47.5V32.7q-.05-1.1-.05-2.25v-6.9q-.05-1.85-.05-3.65v-.95z"
                    id="HaiSpe013__Symbol_83_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe013__Symbol_145_0_Layer0_0_FILL"
                transform="translate(95.25 5.9)"
                id="HaiSpe013__col1n"
            />
            <use
                xlinkHref="#HaiSpe013__Symbol_83_0_Layer0_0_FILL"
                transform="translate(95.25 5.9)"
                id="HaiSpe013__col1d"
            />
        </g></g>
    )
}

export default HaiSpe013
