import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}

function AccHat021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M185.4 70.25q4.2 13.6 4 27.25V99q-.05 4-.45 8-.05.1-.05.2.15 0 .3.05 13.95 4.5 29 0 .25-.05.55-.15 14.45-5.75 15.4-13.8.35-6.7-4.25-12-1.15-1.4-2.75-3.1l-.25-.25q-8.75-8.65-20.75-13.65-6.45-2.4-13.1-4.8-5.15-1.8-10.55-3.55-1.9-3.7-2.1-3.4Q149.45-.85 91.7 0 20.4 4.8 0 71.8q16.95-7.5 31.35-13.6Q34 97.3 96.55 78.15q60.1-18.45 87.95-10.75.5 1.4.9 2.85z"
                    id="AccHat021__Symbol_239_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M161.4 22.15q-11.5-3.75-31.35-4.9-21.3-1.25-35.4 1.25-14.1 2.5-21.55 5.75-3.25 1.4-5.75 2.4l-2 .8q-2.85 1.1-4.4 1.5-.05 0-.1-.05-.85.25-1.75.5-9.45 2.7-18.05 2.65-18.65-.35-26.2-10.35-.2-.25-.35-.5.1.05-.4-.6-1.05-1.45-1.9-2.3-1.3-1.35-2.25-1.35h-.3q-1.4.6 2.5 5.2Q13.8 24 16.2 26.8q.3.35.95.8 8.6 6.55 21.9 6.6 3.458-.05 5.45-.2-.063.013-.1.05 7.25-.05 12.05-1.5Q61.2 31 69.75 27.9q3.6-1.3 9.3-3.4 5.7-2.1 10.55-2.8 19.9-3.3 27.5-2.4 24.95-.45 44.3 2.85m-10.3-6.4q-1.9-3.7-2.1-3.4Q58.45-14.9 0 18q70.45-29.8 151.1-2.25m40.75 45.45q16.15-7.55 1.4-19.95-13.7-8.85-40.15-14.05.5 1.4.9 2.85 4.2 13.6 4 27.25v1.5q-.05 4-.45 8 23.9-.15 34.3-5.6z"
                    id="AccHat021__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.45 3q.55.5.7 1.7l2.5-.15q-.1-2.2-1.3-3.35Q7.15 0 4.95 0 3.5 0 2.55.5 1.55 1 1 2T.45 4.2q0 1.05.4 2 .45.9 1.2 1.45.7.55 2.3 1.05 1.8.55 2.25.8.45.2.7.55.2.4.2.9 0 .85-.65 1.45T5.1 13q-2.3 0-2.7-2.9l-2.4.3q.5 5.15 5 5.15 2.6 0 3.8-1.35 1.2-1.3 1.2-3.25 0-1.35-.45-2.3Q9.1 7.7 8.3 7.1q-.85-.6-2.75-1.15Q3.8 5.45 3.3 5q-.5-.4-.5-1 0-.65.55-1.05.55-.45 1.55-.45t1.55.5m11.1 6q-.65-.25-2.9-.25h-4V23.8h2.5v-5.65h1.6q2.25 0 3.2-.45.9-.45 1.55-1.55.6-1.1.6-2.75 0-1.8-.7-2.95-.75-1.1-1.85-1.45m-3.2 2.3q1.55 0 2.05.2.5.15.8.65.35.55.35 1.3t-.35 1.25q-.3.5-.8.7-.5.2-1.9.2h-1.35v-4.3h1.2z"
                    id="AccHat021__Symbol_64_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat021__Symbol_239_0_Layer0_0_FILL"
                transform="translate(98.75 56.8)"
                id="AccHat021__col1n"
            />
            <use
                xlinkHref="#AccHat021__Symbol_151_0_Layer0_0_FILL"
                transform="translate(130.15 97)"
                id="AccHat021__col1d"
            />
            <use
                xlinkHref="#AccHat021__Symbol_64_0_Layer0_0_FILL"
                transform="translate(186.2 67.5)"
                id="AccHat021__col2n"
            />
        </g></g>
    )
}

export default AccHat021
