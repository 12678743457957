import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}
function FacMou089({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M46.7 1.2q.2-.25.2-.5 0-.3-.25-.5-.15-.2-.45-.2H.7Q.4 0 .25.2 0 .4 0 .7q0 .25.2.5 6.05 7 12.9 12.8 4.1 3.4 8.7 6.8 1.55 1.5 3.3 0 4.6-3.4 8.7-6.8 6.85-5.8 12.9-12.8z"
                    id="FacMou089__Symbol_171_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M0 3.15l.1.1H.05l2.8 3h.05l.05.05q2.775 2.931 4.3 4.2l.1.05q1.236.936 1.5-.35V0H-.2q-1.447.161-.6 1.9.299.59.8 1.2v.05M18.15 6.3V0h-8.5v6.3h8.5m9.25 0V0h-8.5v6.3h8.5m10.45-4.4q.85-1.75-.6-1.9H28.2v10.25q.25 1.25 1.5.3l.05-.05q1.55-1.25 4.35-4.2l.05-.05h.05l2.8-3h-.05l.1-.1q.5-.65.8-1.25z"
                    id="FacMou089__Kopie_von_Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou089__Symbol_171_0_Layer0_0_FILL"
                transform="translate(169.05 215.6)"
                id="FacMou089__col2n"
            />
            <use
                xlinkHref="#FacMou089__Kopie_von_Symbol_54_0_Layer0_0_FILL"
                transform="translate(174 217)"
                id="FacMou089__col1n"
            />
        </g></g>
    )
}
export default FacMou089
