import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor085({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor085__Layer10_0_FILL">
                    <path
                        fill="#EE74BE"
                        d="M219.6 274.1q8 1.21 16 0 .59-1.216.75-2.6-9.038-1.056-17.55 0 .206 1.384.8 2.6z"
                    />
                    <path
                        fill="#A60062"
                        d="M227.6 279.1q3.507 0 6.05-2.4-6.063-2.094-12.15 0 2.641 2.4 6.1 2.4z"
                    />
                    <path
                        fill="#E52B00"
                        d="M233.7 263.85q-2.557-2.45-6.1-2.45-3.495 0-6.15 2.45 6.405 2.145 12.25 0z"
                    />
                    <path
                        fill="#FFA05D"
                        d="M236.35 268.95q-.17-1.356-.75-2.55-8-1.304-16 0-.587 1.194-.8 2.55 8.896 2.016 17.55 0z"
                    />
                </g>
                <g id="AccMor085__Layer10_1_FILL">
                    <path
                        fill="#C4569A"
                        d="M219.6 274.1q.616 1.295 1.7 2.4.106.102.2.2h12.15l.2-.2q1.105-1.105 1.75-2.4h-16z"
                    />
                    <path
                        fill="#F5771D"
                        d="M235.6 266.4q-.645-1.295-1.75-2.4l-.15-.15h-12.25q-.07.074-.15.15-1.084 1.105-1.7 2.4h16z"
                    />
                    <path
                        fill="#FFF"
                        d="M236.35 271.5q.1-.609.1-1.25 0-.668-.1-1.3H218.8q-.05.632-.05 1.3 0 .641.05 1.25h17.55z"
                    />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor085__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor085__Layer10_0_FILL" />
            <use xlinkHref="#AccMor085__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor085__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor085__col1l"
            />
        </g></g>
    )
}

export default AccMor085
