import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#EEEEEE"
}

function BodWin007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.651}
                    d="M108.5 45.2Q96.3 27.6 85.6 18.35q-5.4-4.65-12.1-8.5Q63.05 3.8 52.55 1.5 50.3 1 48.05.65q-1.5-.2-3-.35-3.25-.35-6.5-.25-.25-.05-.5 0-2.5.05-5 .3-6.7.75-12.1 2.85-8 2.55-13.3 9.1-1.25 1.6-2.3 3.3-.5.75-1 1.6Q-1.5 27.3.55 39.3q.95 5.4 3.5 11.2 3.05 6.9 7.8 12.85 1.55 2 3.35 3.9 7.15 7.65 17.35 13.55 6.25 3.6 12.5 5.9l1.8.6q1.5.5 3.05.95.2.05.35.1 11.3 3.1 32.15 3.6 12.25.25 20.4-4.65 2.8-1.7 5.1-4 .85-.85 1.6-1.75l.5-.6q1.15-1.45 2.15-3.05 8.4-13.75-2.15-30.45-.25-.4-.5-.75-.4-.6-.75-1.15-.15-.2-.25-.35M45.2 33h-.15q-1.15-1.6 0-.15l.15.15m193-20.7q-5.3-6.55-13.3-9.1-5.4-2.1-12.1-2.85-7.5-.8-15 .3-2.25.35-4.5.85-10.5 2.3-20.95 8.35-6.7 3.85-12.1 8.5-10.8 9.3-23.15 27.2-12.3 17.85-3.4 32.35t29.75 14.05q20.85-.5 32.15-3.6.15-.05.35-.1 8.65-2.45 17.35-7.45 10.2-5.9 17.35-13.55 1.8-1.9 3.35-3.9 4.75-5.95 7.8-12.85 2.55-5.8 3.5-11.2 2.05-12-3.8-22.1-.5-.85-1-1.6-1.05-1.7-2.3-3.3z"
                    id="BodWin007__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.851}
                    d="M96.7 195.8h.05q.85-.9 1.65-1.65.8-.8 1.25-1.65.05-.15.1-.2-.15 0-.3.05-.4.05-.7.1-5.8 1.25-10.4 5.25-.8.65-1.55 1.4l-.4.4q-7.4 7.55-7.7 18.3-.2 6.15 3.75 12.6l.1.2q1.15 1.85 3.25 3.7 1 .9 2.25 1.8 1.95 1.55 7.05 3.25-.65-.75-1.25-1.55-6.95-8.55-6.95-20.9 0-7.85 4-14.25 2.25-3.7 5.8-6.85m191.6 0h.05q3.55 3.15 5.8 6.85 4 6.4 4 14.25 0 12.35-6.95 20.9-.6.8-1.25 1.55 5.1-1.7 7.05-3.25 1.25-.9 2.25-1.8 2.1-1.85 3.25-3.7l.1-.2q3.95-6.45 3.75-12.6-.3-10.75-7.7-18.3l-.4-.4q-.75-.75-1.55-1.4-4.6-4-10.4-5.25-.3-.05-.7-.1-.15-.05-.3-.05.05.05.1.2.45.85 1.25 1.65.8.75 1.65 1.65z"
                    id="BodWin007__Layer2_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodWin007__Symbol_15_0_Layer0_0_FILL"
                transform="translate(69.6 180.2)"
                id="BodWin007__col1n"
            />
            <use xlinkHref="#BodWin007__Layer2_0_FILL" />
        </g></g>
    )
}

export default BodWin007
