import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF"
}

function LegPan033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.1 1.5q-.2-.65-.45-1.3 0-.05-.05-.15h-.05V0H1.85v.1Q1.7.05 1.5 0 1.2.75.95 1.55-.15 5 0 8.75q0 .3.05.65 1.05-.05 2-.05.2-.05.45-.05-.05-.3-.05-.55l2.9.5h.4q13.9-.25 37.9 2.1 8.4.8 18 1.95.2.05.4.05h2q.2 0 .4-.05 9.6-1.15 18-1.95 24.35-2.35 38.3-2.1.55-.35.95-.55l1.9.6v.05q.25 0 .45.05.95 0 2 .05V9.4q.05-.55.05-1.05.1-3.55-1-6.85z"
                    id="LegPan033__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 0l.4 4.15h2L2.8 0H0z"
                    id="LegPan033__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M121.8 7.75l.5 1.4q1.1 3.4 1.05 6.8V16q1.4 0 2.7.05h.15V16q.2-3.85-.95-7.4-.2-.65-.45-1.25-.05-.05-.05-.1.3-.1.5-.1 1-.2 1.95-.4.05.1.05.15.25.65.45 1.3 1.25 3.8.95 7.9v.05q.35 0 .65.05.95.05 1.95.1.25-4.35-.85-8.6-.2-.75-.4-1.45.35-3.15-.5-6.25-27.1 5.05-54.35 5.8l-3.65.05q-5.6.1-11.2 0l-3.65-.05Q29.35 5.05 1.9.05.95 3 1.35 6.15 1.1 6.95.9 7.7q-1.1 4.25-.85 8.55 1-.05 1.95-.1.3-.05.65-.05-.3-4.05.9-7.85.25-.8.55-1.55.95.2 1.95.4.2 0 .5.1-.35.75-.55 1.5-1.1 3.55-.9 7.3h.15q1.3-.05 2.7-.05-.05-3.35 1-6.65.2-.75.5-1.5l.05-.1q.15.05.35.1 55.45 9.95 111.95-.05z"
                    id="LegPan033__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M3.2.55Q3 .5 2.8.5 1.65.25.45 0 .2.8 0 1.55l2.65.55q.25-.8.55-1.55m4.85 2.6q.2-.75.5-1.5l.05-.1q-1.5-.25-2.95-.5-.35.75-.55 1.5l2.95.6m116.3-.7q-.2-.65-.45-1.25-.05-.05-.05-.1-1.45.25-2.95.5l.5 1.4 2.95-.55m4.7-2.4q-1.2.25-2.35.5-.2 0-.4.05.05.1.05.15.25.65.45 1.3l2.7-.5q-.2-.75-.4-1.45l-.05-.05z"
                    id="LegPan033__Symbol_75_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan033__Symbol_76_0_Layer0_0_FILL"
                transform="translate(129.45 310.45)"
                id="LegPan033__col1n"
            />
            <use
                xlinkHref="#LegPan033__Symbol_77_0_Layer0_0_FILL"
                transform="translate(191.1 319.65)"
                id="LegPan033__col1d"
            />
            <use
                xlinkHref="#LegPan033__Symbol_74_0_Layer0_0_FILL"
                transform="translate(126.85 303.75)"
                id="LegPan033__col2n"
            />
            <use
                xlinkHref="#LegPan033__Symbol_75_0_Layer0_0_FILL"
                transform="translate(127.75 309.9)"
                id="LegPan033__col2d"
            />
        </g></g>
    )
}

export default LegPan033
