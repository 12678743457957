import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col1s": "#770707",
    "col2n": "#FFFF00"
}

function AccMor021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M130.4 0q-3.25.7-6.5 1.3-23.2 4.45-47.2 5.15l-2.1.45v-.3H57.85v.55L55.8 6.5v-.1h-.25q-23-.9-46.7-5.1Q6.05.75 3.25.1v.1Q2.95.15 2.6.1L0 10.25q7.55 1.85 15.05 3.25 20.6 3.6 40.75 4.35l2.05-.5v.6h.9q7.95.2 15.85 0v-.55l2.05.5q5.643-.2 11.25-.65l-.05.05q.8-.1 1.6-.15l1-.1H90.75q13.2-1.15 26.25-3.55 7.8-1.5 15.65-3.45L130.4 0z"
                    id="AccMor021__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.6.45Q3.35.2 3.05 0 1.95.05.85.15l.1.1q.05 0 .1.05.6.35 1.1.85.15.15.3.35Q4 3.2 4 5.6q0 1.1-.3 2.05-.05.05-.05.1-.45 1.3-1.5 2.35-.75.75-1.65 1.25-.2.05-.35.15H.1q-.05 0-.1.05.8-.1 1.6-.15l1-.1h.05q.1-.1.3-.15.55-.35 1.05-.8v-.05q.15-.15.25-.3.3-.35.6-.85l.2-.4q.1-.15.15-.3.05-.2.15-.4.05-.35.1-.8.1-.75.1-1.8 0-2.3-.9-3.7-.05-.1-.1-.15Q4.1.95 3.6.45z"
                    id="AccMor021__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M2.2 1.1q0-.45-.35-.8-.3-.3-.75-.3T.35.3H.3q-.3.35-.3.8 0 .45.3.75l.05.05q.3.3.75.3t.75-.35q.35-.3.35-.75m18.5 1.4q.15-.15.25-.3.1-.2.1-.45t-.1-.45q-.1-.2-.25-.35-.3-.3-.75-.3-.35 0-.65.2-.1.05-.15.1-.3.35-.3.8 0 .45.3.75l.15.15q.3.2.65.2.45 0 .75-.35m14.4-.3q.35.35.8.35.45 0 .75-.35.35-.3.35-.75t-.35-.8q-.3-.3-.75-.3t-.8.3q-.3.35-.3.8 0 .45.3.75z"
                    id="AccMor021__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M20.85 2.15q0-.1.05-.25.25-1.75-1.65-1.9v.05H1.05Q.6.05.3.35t-.3.7V14.4q0 .4.3.7.3.3.75.3H19.3q1.7-.1 1.55-1.5V2.15M4.9 6.75q-.2-.1-.45-.1h-2.4v-4.6H18.8V13.4q-7.9.2-15.85 0h-.9V8.65h2.4q.25 0 .45-.1.15-.1.25-.2.3-.3.3-.7 0-.4-.3-.7-.1-.1-.25-.2z"
                    id="AccMor021__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor021__Symbol_119_0_Layer0_0_FILL"
                transform="translate(126.2 300.15)"
                id="AccMor021__col1n"
            />
            <use
                xlinkHref="#AccMor021__Symbol_70_0_Layer0_0_FILL"
                transform="translate(214.05 305.9)"
                id="AccMor021__col1d"
            />
            <use
                xlinkHref="#AccMor021__Symbol_42_0_Layer0_0_FILL"
                transform="translate(173.2 310.6)"
                id="AccMor021__col1s"
            />
            <use
                xlinkHref="#AccMor021__Symbol_24_0_Layer0_0_FILL"
                transform="translate(182 304.7)"
                id="AccMor021__col2n"
            />
        </g></g>
    )
}

export default AccMor021
