import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2d": "#19172A",
    "col2n": "#332F54"
}

function AccGla031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.5 1.25q0-.5-.35-.9-.4-.35-.9-.35T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4l.05-.05q.3-.35.3-.8z"
                    id="AccGla031__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.8}
                    d="M60.6 4.75q-.7-.95-1.55-1.8-4.65-4.6-11.15-4.6-5.85 0-10.15 3.75l-7.2 6.95-7.2-6.95q-4.3-3.75-10.15-3.75-6.5 0-11.15 4.6Q1.2 3.8.5 4.75q-7.4 12.85 6.4 28Q17.1 45.4 30.35 52.8l.2-.1.2.1q13.3-7.4 23.45-20.05 13.8-15.15 6.4-28m73.9 0q-.7-.95-1.55-1.8-4.65-4.6-11.15-4.6-5.85 0-10.15 3.75l-7.2 6.95-7.2-6.95q-4.3-3.75-10.15-3.75-6.5 0-11.15 4.6-.85.85-1.55 1.8-7.4 12.85 6.4 28Q91 45.4 104.25 52.8l.2-.1.2.1q13.3-7.4 23.45-20.05 13.8-15.15 6.4-28z"
                    id="AccGla031__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M193.8 11.25q-.25-.8-1.05-1.1-.75-.35-1.5-.05l-19.95 7.3q-.05-.3-.05-.6-.8-6.35-5.5-11.05Q160.1.1 152.15.1q-7.95 0-13.6 5.65-2.1 2.1-3.45 4.55-.6.95-1.35 2.2-.66-1.267-1.25-2.2-1.35-2.45-3.45-4.55Q123.4.1 115.45.1q-7.95 0-13.6 5.65-3.55 3.55-4.85 8-1.3-4.5-4.9-8.1Q86.45 0 78.5 0q-7.95 0-13.6 5.65-2.1 2.1-3.45 4.55-.6.95-1.35 2.2-.65-1.25-1.25-2.2-1.35-2.45-3.45-4.55Q49.75 0 41.8 0q-7.95 0-13.6 5.65-4.7 4.7-5.5 11.05 0 .3-.05.6L2.7 10q-.75-.3-1.5.05-.8.3-1.05 1.1-.3.8.05 1.55t1.1 1.05l21.35 7.8v.5q.25 4.05 2.05 7.6l.5 1q2.3 4.7 5.4 9.05 9.75 12.95 29.55 21.1 19.8-8.15 29.55-21.1 3.1-4.35 5.4-9.05l.5-1q.85-1.65 1.35-3.45.55 1.8 1.4 3.55l.5 1q2.3 4.7 5.4 9.05Q114 52.75 133.8 60.9q19.8-8.15 29.55-21.1 3.1-4.35 5.4-9.05l.5-1q1.8-3.55 2.05-7.6v-.5l21.35-7.8q.75-.3 1.1-1.05.35-.75.05-1.55M150.1 6.6q6.1 0 10.45 4.3.8.8 1.45 1.7 6.95 12.05-6 26.3-9.55 11.85-22 18.8l-.2-.1-.2.1q-12.45-6.95-22-18.8-12.95-14.25-6-26.3.65-.9 1.45-1.7 4.35-4.3 10.45-4.3 5.5 0 9.55 3.5l6.75 6.55 6.75-6.55q4.05-3.5 9.55-3.5m-63.2 4.2q.8.8 1.45 1.7 6.95 12.05-6 26.3-9.55 11.85-22 18.8l-.2-.1-.2.1q-12.45-6.95-22-18.8-12.95-14.25-6-26.3.65-.9 1.45-1.7 4.35-4.3 10.45-4.3 5.5 0 9.55 3.5l6.75 6.55L66.9 10q4.05-3.5 9.55-3.5 6.1 0 10.45 4.3z"
                    id="AccGla031__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.8 4.9V.65L0 0v4.25l1.8.65m150.45-.55V.1l-1.8.65V5l1.8-.65z"
                    id="AccGla031__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla031__Symbol_119_0_Layer0_0_FILL"
                transform="translate(191.2 156.95)"
                id="AccGla031__col2n"
            />
            <use
                xlinkHref="#AccGla031__Symbol_70_0_Layer0_0_FILL"
                transform="translate(125.05 143.1)"
                id="AccGla031__col2d"
            />
            <use
                xlinkHref="#AccGla031__Symbol_53_0_Layer0_0_FILL"
                transform="translate(95.55 136.6)"
                id="AccGla031__col1n"
            />
            <use
                xlinkHref="#AccGla031__Symbol_21_0_Layer0_0_FILL"
                transform="translate(116.4 153.25)"
                id="AccGla031__col1d"
            />
        </g></g>
    )
}

export default AccGla031
