import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#1082E7",
    "col2n": "#43A6FF",
    "col2s": "#00539F"
}

function ShiDre059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163 45.85q-.15-.7-.3-1.35-.35-1.35-.65-2.7-.1-.2-.1-.35-.05-.15-.1-.25l-.4-1.6q-.1-.3-.15-.55-.7-2.4-1.45-4.75-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.75-1-1.45-1.95l-7.3 9.5-2.1-3.3-.2.1q-2.2 1.4-4.5 2.7-.7.35-1.4.75l-2.5 1.3q-5.8 2.85-11.9 4.85-2.7 3.85-7.05 7.25-7.3 5.7-20.6 11.7-1.3.6-2.7 1.2V36q-.05 0-.1.05-.05-.05-.1-.05v.05q-1.4-.6-2.7-1.2-13.3-6-20.6-11.7-4.35-3.45-7-7.25-6.15-2-11.95-4.85l-2.5-1.3q-.25-.15-.5-.25-2.7-1.5-5.35-3.15l-.05-.05-3.85 5.4L22.15 0Q19.2 3.95 16.6 8.3q-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.55 1.8-1.05 3.65-.3 1.05-.6 2.15-.05.15-.05.25-.05.15-.1.35l-.7 2.7-.1.5h.05l-.2.8Q2 45.9 2 46q-.1.3-.15.6-.05.2-.05.35-.05.05-.05.1-.1.45-.2.95-.4 1.7-.75 3.4v.2q-.1.25-.1.55-.05.15-.1.35-.1.45-.15.9-.1.45-.2.95l-.2 1.1q-.05.2-.05.4v.1q1.05-.9 2.25-1.6.5-.3 1.05-.55 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q.25.1.45.25 1.55.9 2.8 2.2.1.05.15.15.05.05.15.1l.15.15V57q.05 0 .05.05v.1l4.8 9.9-8.6 6.4H18l-.7 3.2.1.15q-.1.15-.3.4L9.9 96.5H155l-7.7-19.75.4-.1-.7-3.2h-.05v-.05l-7.55-7 3.6-9.3v-.05q.3-.35.6-.6 1.25-1.25 2.7-2.1.25-.15.5-.25.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6.4.2.85.45 1.15.7 2.2 1.6V55.8q0-.15-.05-.35l-.2-1.1q-.2-1-.35-1.95-.05-.1-.05-.25-.05-.05-.05-.15-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85-.05-.2-.1-.45v-.05l-.2-.8q-.05-.1-.05-.15v-.05q-.05-.25-.1-.45-.05-.3-.15-.6 0-.1-.05-.15z"
                    id="ShiDre059__Symbol_256_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 38.25q11.35-6.7 22.15 1.8l.2-.95Q11.6 30.55.25 37.2q-.05.05-.05.1-.1.45-.2.95m66.8-14.3q5.5 2.3 11.85 4.2-5.2-1.85-9.85-3.9Q48.55 15.2 38.6 1.3L36.1 0q.05.05.1.15 9.9 15.05 30.6 23.8M81.85 75q10.4-.1 20.95-.75 19.15-1.45 37.15-5.2 2.75-.55 5.45-1.2l-.2-.75h-.05q-.5.1-1 .25-.15 0-.4.05-1.75.35-3.55.75-2.4.45-4.85.9-26.95 4.8-53.5 5H80.05q-26.55-.2-53.5-5-2.45-.45-4.85-.9-1.8-.4-3.55-.75-.25-.05-.4-.05-.5-.15-1-.25h-.05l-.2.75q2.7.65 5.45 1.2 18 3.75 37.15 5.2 10.55.65 20.95.75H81.85M125.8 0l-2.5 1.3q-9.95 13.9-30.2 22.95-4.65 2.05-9.85 3.9 6.35-1.9 11.85-4.2 20.7-8.75 30.6-23.8.05-.1.1-.15M162 38.35v-.05l-.2-.8q-.05-.1-.05-.15v-.05q-.1-.05-.15-.05l-.05-.05q-.05-.05-.1-.05-.55-.3-1.05-.6-.1-.05-.15-.05-.4-.25-.8-.4l-.3-.1q-6.35-2.9-12.55-.85-3.55 1.15-7.05 3.9l.2.95q1.1-.9 2.2-1.55 2.5-1.6 5-2.4 7.3-2.25 14.85 2.2l.2.1z"
                    id="ShiDre059__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M142.8 77.05l-.75.15v.1q-.05-.05-.1-.05h-.1l.2.75q.2.7.35 1.2.2.55.35.85 1.4 3.05 2.3 6.35.85 3.05 1.25 6.35-22.85-2.85-45.3.3-11.3 1.9-22.5 2H76.7q-11.2-.1-22.5-2-22.45-3.15-45.3-.3.4-3.3 1.25-6.35.9-3.3 2.3-6.35.15-.3.35-.85.15-.5.35-1.2l.2-.75h.05l-1-.2.1.15q-.2.25-.85 1.15-.35.45-.65.85-3.05 3.95-4.95 7.2-1.15 1.95-2 3.7h.1Q1.85 94.6 0 99.4q.2 1.15 1.25 1.5l.9 1.3v-.1q.3.4 5-.1 3.1-.7 1.35-.25 5.95-.95 12.3-1.4 18-1.25 30.25-.5 5.15.3 8.1.75 18.65 3.4 36.5.2 3.35-.65 8.5-.95 12.25-.75 30.25.5 6.35.45 13.65 1.65 4.7.5 5 .1v.1l.9-1.3q1.05-.35 1.25-1.5-1.65-4.25-3.65-8.3-1.25-2.75-2.4-4.7-1.9-3.25-4.95-7.2-.3-.4-1.3-1.8l.05.15q-.1-.2-.25-.35l.1-.15m-5.1-77l-.05-.05-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-.45.35-.95.65-1.05.7-2.15 1.4-.05.25-.15.5 0 .1-.05.2l-.2.7q-.95 3.9-1.7 7.8-1.85 10.85-1.25 21.75-12.05 2.3-24 3.6-11.3 1.1-22.5 1.2H76.7q-11.2-.1-22.5-1.2-11.95-1.3-24-3.6.6-10.9-1.25-21.75-.75-3.9-1.7-7.8l-.2-.7Q27 7.3 27 7.2q-.1-.25-.15-.5-1.55-1-3.1-2.05L22.2 3.5q-1.45-1.05-2.8-2.1L17.55 0q-.1.05-.15.15-.1.1-.15.25.15.55.35 1.15v.1q.55 2.1 1.1 4.35l.5 2.1q.15.75.3 1.55 3.15 14.95 1.75 28.5l-4.4 19.8.05-.15-.65 2.65q-.4 1.45-.7 2.95l-2.4 10.45q.2.1.6.25.45.1 1.1.2.9.15 1.6.25h-.05q2.05.4 4.15.75.7.1 1.4.25.9.15 1.85.3.45.1.95.15 12.6 2.05 24.9 3.2 13.7 1.25 27.05 1.4H78.5q13.35-.15 27.05-1.4 12.3-1.15 24.9-3.2.5-.05.95-.15.95-.15 1.85-.3.7-.15 1.4-.25 2.1-.35 4.15-.75h-.05q.7-.1 1.6-.25.65-.1 1.1-.2.4-.15.6-.25L138.7 59.9h-.05l-.55-2.4v.8l-4.5-21.8q-1.95-10.4.1-18.7 2.15-8.95 2.3-9.7l.5-2.1q.55-2.25 1.1-4.35v-.1q.2-.6.35-1.15-.15-.2-.25-.35z"
                    id="ShiDre059__Symbol_177_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M4.4 47.3q.1-.35.2-.65-.2.4-.35.85Q3.6 49.05 3 50.6q-.15.4-.3.85-1.4 3.4-2.7 7l.9 1.3v-.1q1.5-4.25 2.3-7.4.05-.4.15-.75.45-2.35 1.05-4.2m72.5-9.15V0h-1.1v38.15h1.1m71.55 9.35q-.15-.45-.35-.85.1.3.2.65.6 1.85 1.05 4.2.1.35.15.75.8 3.15 2.3 7.4v.1l.9-1.3q-1.3-3.6-2.7-7-.15-.45-.3-.85-.6-1.55-1.25-3.1z"
                    id="ShiDre059__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M4.45.1q-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1M115.4.6v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35z"
                    id="ShiDre059__Symbol_138_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre059__Symbol_256_0_Layer0_0_FILL"
                transform="translate(110 229)"
                id="ShiDre059__col1n"
            />
            <use
                xlinkHref="#ShiDre059__Symbol_216_0_Layer0_0_FILL"
                transform="translate(111.55 238.75)"
                id="ShiDre059__col1d"
            />
            <use
                xlinkHref="#ShiDre059__Symbol_177_0_Layer0_0_FILL"
                transform="translate(114.9 228.6)"
                id="ShiDre059__col2n"
            />
            <use
                xlinkHref="#ShiDre059__Symbol_151_0_Layer0_0_FILL"
                transform="translate(116.15 271.05)"
                id="ShiDre059__col2d"
            />
            <use
                xlinkHref="#ShiDre059__Symbol_138_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiDre059__col2s"
            />
        </g></g>
    )
}

export default ShiDre059
