import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2d": "#005100",
    "col2n": "#008300",
    "col2s": "#002A00"
}

function ShiLon072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M61.4 31.9L43.5 11.75q-2.55-1.25-5.05-2.65-3.6-2.05-7.1-4.45L29.8 3.5Q28.35 2.45 27 1.4L25.15 0q-.1.05-.15.15-1.3 1.8-2.55 3.65l-.35-.2h-.15q-.15-.05-.25 0-.2.05-.25.25l-1.6 2.95.2-.4-4.2-2.3q-4.5-2.3-8.45 1.95 3.75 1 10.3 4.6l-1.35 2.45q-.15.2-.05.4l.05.05q0 .1.15.2-.9 1.7-1.7 3.4-.2.35-.35.7l-.15-.1h-.15q-.15 0-.25.05-.2.05-.25.25l-1.25 3.1.15-.4L8.15 19Q3.35 17.25 0 21.95q3.8.5 10.75 3.3l-1.05 2.6q-.1.2 0 .4l.05.05q.05.15.25.2-.45 1.1-.85 2.25-.15.4-.3.85-.7 2.05-1.35 4.2-.8 2.6-1.5 5.35L26.25 56.3l-3.4 8.3-1.1 4.6q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H19.65q16.35 6.5 35.7 9.05v-2.75l6.05-2.9V31.9m103.05 9.3l-.3-1.2q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45l-.05-.05.05-.05q.1-.2 0-.4l-1.05-2.6q6.95-2.8 10.75-3.3-3.35-4.7-8.15-2.95l-4.4 1.75.15.4-1.25-3.1q-.05-.2-.25-.25-.1-.05-.25-.05-1.05-2.15-2.15-4.25.1-.2-.05-.4l-1.35-2.45q6.55-3.6 10.3-4.6-3.95-4.25-8.45-1.95l-4.2 2.3.2.4-1.6-2.95q-.05-.2-.25-.25-.1-.05-.25 0H148l-.1.1q-.45-.7-.9-1.35-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-6.35 4.384-13.2 7.6.565-.24 1.1-.5l-15.65 13.7v59.4l3.8 2.5v2.75q21.35-3.1 35.55-9.05H150.8q.25-.05.5-.15h.2l-.05-.2v-.1l-4.05-16.3-4.65-7.9 21.7-15.2z"
                    id="ShiLon072__Symbol_512_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M41.3 66.3q-.2 0-.35.2-.15.15-.15.3v11.55q0 .2.15.4.15.1.35.1h4.4v-1h-3.9V67.3h3.9v-1h-4.4M20.05 31.7q-.2-.05-.35.1-.2.1-.25.3l-2.4 12.2 1 .35 2.4-12.3v-.05q.05-.2-.05-.35-.15-.2-.35-.25M9.9 16.5q-.1-.2-.25-.25L4.8 14.3l-.15-.1H4.5q-.15 0-.25.05-.2.05-.25.25L.05 24.3q-.1.2 0 .4l.05.05q.05.15.25.2l5 2.05q.15.05.35-.05.2-.05.3-.25l.65-1.7 4 1.6q.15.1.35.05.25-.1.3-.3l1.8-4.5q.05-.05.05-.1l.3-.8q.1-.15 0-.35-.1-.2-.25-.3l-3.95-1.6.35-.9q0-.05.05-.1l.3-.8q.05-.2-.05-.4m-2.85 7.6l1.8-4.5 3.5 1.45-1.8 4.5-3.5-1.45m-2.3-8.75L8.8 17l-3.55 8.9-4.05-1.65 3.55-8.9m15.65-7.5q.1-.15.05-.35-.1-.25-.2-.35l-3.7-2.05.45-.85q0-.05.05-.1l.4-.75V3q-.1-.2-.25-.3L12.45.05h-.15q-.15-.05-.25 0-.2.05-.25.25L6.7 9.55q-.15.2-.05.4l.05.05q0 .1.15.2 0 .05.05.05l4.7 2.6q.15.05.35 0 .25-.05.35-.2l.85-1.6 3.75 2.1q.15.1.35.1.25-.1.35-.3l2.35-4.25q.05-.05.05-.1l.4-.75m-1.1-.05l-2.35 4.3-3.3-1.9L16 5.95l3.3 1.85m-6.85-6.6l3.85 2.15-4.65 8.45-3.8-2.15 4.6-8.45M45.7 47.1h-4.4q-.2 0-.35.2-.15.15-.15.3v11.55q0 .2.15.4.15.1.35.1h4.4v-1h-3.9V48.1h3.9v-1m85.15-15.3q-.1.05-.15.15-.1.15-.05.35v.05l2.45 12.3.95-.3-2.4-12.25q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1m15.95-17.3q-.05-.2-.25-.25-.1-.05-.25-.05h-.15l-.15.1-4.85 1.95q-.15.05-.25.25t-.05.4l.3.8q.05.05.05.1l.35.9-3.95 1.6q-.15.1-.25.3-.1.2 0 .35l.3.8q0 .05.05.1l1.8 4.5q.05.2.3.3.2.05.35-.05l4-1.6.65 1.7q.1.2.3.25.2.1.35.05l5-2.05q.2-.05.25-.2l.05-.05q.1-.2 0-.4l-3.95-9.8M142 17l4.05-1.65 3.55 8.9-4.05 1.65L142 17m-.05 2.6l1.8 4.5-3.5 1.45-1.8-4.5 3.5-1.45m1.95-9.35q.05 0 .05-.05.15-.1.15-.2l.05-.05q.1-.2-.05-.4L139 .3q-.05-.2-.25-.25-.1-.05-.25 0h-.15L133.6 2.7q-.15.1-.25.3v.4l.4.75q.05.05.05.1l.45.85-3.7 2.05q-.1.1-.2.35-.05.2.05.35l.4.75q0 .05.05.1l2.35 4.25q.1.2.35.3.2 0 .35-.1l3.75-2.1.85 1.6q.1.15.35.2.2.05.35 0l4.7-2.6m-9.1-4.3l2.35 4.25-3.3 1.9-2.35-4.3 3.3-1.85m-.3-2.6l3.85-2.15 4.6 8.45-3.8 2.15-4.65-8.45z"
                    id="ShiLon072__Symbol_453_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M39.5 39.4L0 26.65 5.4 44.5q2.95-2.5 6.9-3.25.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-1.7 7.15L39.5 39.4m100.5-20L129.5 0q-5.75 2.8-11.85 4.75l-.7.2-1.1.3-.05.05q-.05 0-.1.05-.1 0-.15.05l-27.7 33.25h-.1L60 5.4l-.3-.15-1.8-.5Q51.8 2.8 46.05 0l-10.5 19.4 22.25-5.8q.05-.05.1-.05V39.9H60v10.55h-2.1v8.65H60v10.55h-2.1v5.95l3.1.3q13.1 1.3 26.15 1.35h1.2q12.95-.05 25.9-1.3 1.7-.15 3.4-.35V13.55q.05 0 .1.05L140 19.4m30.3 24.95l5.35-17.7-39.5 12.75 13.8 13.15-1.1-4.4h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V44.45v-.1z"
                    id="ShiLon072__Symbol_419_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.15.05Q.05 0 0 0v2.65l26.65 22.4v-.55L.35.15q-.1-.1-.2-.1m58.2 1.05h-.1l-25.3 23.4v.55L59.6 2.65V.05q-.1 0-.2.1l-1.05.95z"
                    id="ShiLon072__Symbol_400_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M0 0v38.6h1.2V0H0z"
                    id="ShiLon072__Symbol_377_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon072__Symbol_512_0_Layer0_0_FILL"
                transform="translate(107.3 228.6)"
                id="ShiLon072__col1n"
            />
            <use
                xlinkHref="#ShiLon072__Symbol_453_0_Layer0_0_FILL"
                transform="translate(116.95 232.15)"
                id="ShiLon072__col1s"
            />
            <use
                xlinkHref="#ShiLon072__Symbol_419_0_Layer0_0_FILL"
                transform="translate(104.75 240.35)"
                id="ShiLon072__col2n"
            />
            <use
                xlinkHref="#ShiLon072__Symbol_400_0_Layer0_0_FILL"
                transform="translate(162.65 253.9)"
                id="ShiLon072__col2d"
            />
            <use
                xlinkHref="#ShiLon072__Symbol_377_0_Layer0_0_FILL"
                transform="translate(191.9 279)"
                id="ShiLon072__col2s"
            />
        </g></g>
    )
}

export default ShiLon072
