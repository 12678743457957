import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M56.55 10.5q-.25-2.25-.5-2.65-.3-.65-1.05-1.3-.9-.8-2.4-1.55-.3-.15-.65-.3-.5-.25-1.1-.45-1.15-.5-2.55-.9-.6-.2-1.25-.35-1.8-.5-3.9-1-2.25-.5-4.85-.95-2.95-.75-6-.95-2.85-.2-5.7 0-2.7.2-5.5.7-.5.15-1.05.25-1.95.35-3.7.7-3.15.7-5.65 1.4l-.7.2q-1 .3-1.8.65-.2.05-.3.1-1.6.6-2.8 1.25-1.05.6-1.75 1.2Q2.15 7.6 2 8.7l-.55 7.1h.05l-.7 9-.05-.1q-.35 3.85-.4 7-.3 3.15-.35 5.1.15 1.85 1.2 3.6 2.45-5.65 3.3-14.45v-.1l1.15-13.4q1.15-5.3 23.3-4.95h.4q22.15-.35 23.3 4.95L53 15.4h-.05l.15 2h-.05l.8 8.45v.1q.8 8.45 3.1 14l.1.15.1.3q1-1.8 1.15-3.6-.05-1.8-.3-4.65-.05-3.1-.35-6.9 0-.25-.05-.55l-1.05-14.2z"
                    id="BeaMus031__Symbol_48_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus031__Symbol_48_0_Layer0_0_FILL"
                transform="translate(163.35 205.35)"
                id="BeaMus031__col1n"
            />
        </g></g>
    )
}

export default BeaMus031
