import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC6FF",
    "col1s": "#EE51EE",
    "col2n": "#FFFFFF"
}

function ShiSho047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1s}
                    d="M.25 21.65q-.15.3-.25.6L17.65 29q1.4.25.95 1.85l-1.2 6.35.2.15q.65.25 1.1-1.25l1.15-5.9q.1-1.25-.85-1.7L.3 21.4v.1q-.05.05-.05.15M127.5 0l-.9.6q-1 .65-2 1.25-.1.1-.25.15L107.7 23.25l-.1.15-1.2-.8-1.2-.8q-2.6-1.55-5.4-2.3-5.05-1.2-10.25-.2-4.95.9-9.9 4.2-1.35.95-4.1 3.2-.25.2-.55.45l-.1.1-.1-.1q-3.15-2.6-4.65-3.65-4.95-3.3-9.9-4.2-5.2-1-10.25.2-2.8.75-5.4 2.3l-1.2.8-1.2.8-.1-.15-16.8-21.4Q23.9 1 22.6.1l19.35 24.85q2-1.55 4.05-2.7 7.65-4.5 17.8-.95.45.15.95.35 4.6 1.9 9.55 6.3 0 .05.05.05v19.65h1.2V27.9q4.9-4.35 9.5-6.25.5-.2.95-.35 10.15-3.55 17.8.95 2.05 1.15 4.05 2.7L127.5 0m22.25 21.65v-.1l-18.7 7.1q-.95.45-.85 1.7l1.15 5.9q.45 1.5 1.1 1.25l.2-.15-1.2-6.35q-.45-1.6.95-1.85l17.65-6.75q-.1-.3-.25-.6 0-.1-.05-.15z"
                    id="ShiSho047__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.5 10.95q-.55-.4-1.25-.4-.85 0-1.45.55 0 .05-.05.05l-.1.1q-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.2-.2-.35-.3m1-8.7v-.3q-.1-.75-.65-1.3Q3.2 0 2.25 0 1.3 0 .65.65q-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6z"
                    id="ShiSho047__Symbol_124_0_Layer0_0_FILL"
                />
                <g
                    id="ShiSho047__Symbol_122_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M143.2 8.1q-.1-.1-.15-.2-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4-.6.5q-1.05.8-2.2 1.6l-1.55 1.15q-.55.4-1.1.75-.1.05-.2.15l-.9.6q-1 .65-2 1.25-.1.1-.25.15-.35.25-.7.45-16.55 9.85-36.15 12.2L78.25 32.25q-.25.2-.55.45l-.1.1-12.55-12.6Q44.9 17.75 28 7.4q-1.4-.85-2.7-1.75-.2-.15-.4-.25-.55-.4-1.1-.75L22.25 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L17.6 0q-.1.05-.15.15-3.05 4.1-5.7 8.55l-.35.65q-.15.2-.25.4-.2.4-.45.75l-.4.8-.2.3q-2 3.65-3.8 7.5-.1.2-.2.45-.1.15-.15.4l-2.1 4.9-.2.4-.4 1.1q-.15.3-.25.6-.1.25-.15.45-.15.3-.25.6-.05.2-.1.35-1.35 3.6-2.5 7.4l20 7.3-1.25 6.4q-.55 2.7-1.1 5.45L14.2 69.2q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H12.1q2.05.5 4.15.95 28.15 6.35 56.1 6.95 5.5.1 11 0 27.9-.6 55.6-6.95 2.1-.45 4.15-.95H143.25q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-.25-1.5.55 2.8-1.55-8.5 20-7.3q-1.15-3.8-2.5-7.4-.05-.15-.1-.35-.1-.3-.25-.6 0-.1-.05-.15v-.1l-.1-.2q-.1-.3-.25-.6l-.4-1.1-.2-.4-.35-.85Q150.1 21.5 149 19.1q-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4M97.95 29.25h-.05l.2-.05-.15.05z" />
                    <path d="M97.9 29.25h.05l.15-.05-.2.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSho047__Symbol_122_0_Layer0_0_FILL"
                transform="translate(114.85 228.6)"
                id="ShiSho047__col1n"
            />
            <use
                xlinkHref="#ShiSho047__Symbol_123_0_Layer0_0_FILL"
                transform="translate(117.55 234.15)"
                id="ShiSho047__col1s"
            />
            <use
                xlinkHref="#ShiSho047__Symbol_124_0_Layer0_0_FILL"
                transform="translate(183.6 263.55)"
                id="ShiSho047__col2n"
            />
        </g></g>
    )
}

export default ShiSho047
