import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF00FF",
    "col2n": "#00FF00"
}

function ShiSho032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M132.4 228.55q-.04.11-.1.2-2.025 2.724-3.9 5.6.005.056-.05.05l1.05 7.2-7.65 4.8q-.025.047-.05.1l-.55 1.2q-2.06 4.453-3.85 9.25h.05l20.25 6.65-2.4 6.1-1.6 8.3q-.192.951-.4 1.85-.005.13-.05.2-.01.193-.05.35l.6 6.1-3.15 4.95-1.5 6.35q-.637 2.526-1.3 5.05.012.053 0 .1l12.3 9.6q.166.025.3.05.964.163 1.9.35.65.1 1.35.25.15 0 .3.05 6.4 1.1 12.75 1.9 3.8.5 7.6.9.7.1 1.45.15l1.8.2h.25l5.65.45q.3 0 .6.05 5.4.35 10.8.55.4 0 .8.05 2.4 0 4.8.05v-.05h4.2v.05q1.974-.035 3.95-.1.424.03.85 0 .4.007.8 0 5.342-.188 10.65-.55.092.004.15 0 .3-.029.6-.05l5.65-.45h.25l1.8-.2q.75-.05 1.45-.15.064-.007.1-.05 3.764-.358 7.5-.85.383-.048.75-.1.278-.033.5-.1 4.552-.557 9.1-1.3 1.203-.194 2.4-.4.15-.05.3-.05.58-.124 1.1-.25.14.017.25 0 1.515-.278 3.05-.6v-.05q.946-.16 1.9-.35.341-.075.65-.15 1.742-.377 3.45-.75.452-.102.9-.2 2.465-.558 4.85-1.15.219-.008.4-.05h.15q.25-.05.5-.15.109 0 .2-.05l-.2-.55h.05l-5.75-23.05v-.05l-4.35-21.75L264.5 258l.05-.05 3-1.25q-.326-.951-.7-1.9-1.403-3.65-3-7.1-.679-1.478-1.4-2.95-1.174-2.36-2.45-4.65-1.022-1.837-2.1-3.6-.58-.965-1.2-1.9-1.971-3.101-4.15-6l-1.85 1.4q-.046.036-.1.05-1.093.875-2.25 1.7-.227.189-.45.35l-1.55 1.15q-1.04.719-2.1 1.4-3.66 2.39-7.5 4.4-3.793 2.004-7.8 3.6-4.877 2.03-10.05 3.45-7.697 2.123-15.95 2.95-1.223.108-2.45.2l-1.1.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-3.366-.251-6.65-.75-5.157-.694-10.1-1.95-10.112-2.588-19.3-7.4-5.034-2.606-9.8-5.9l-1.55-1.15q-1.45-1.05-2.8-2.1l-1.85-1.4-.05-.05z"
                    id="ShiSho032__Layer10_0_FILL"
                />
                <path
                    fill="#626262"
                    d="M258.6 308.9h.05l-1.05-4.05q-7.037-3.146-14.2-6.05-1.9 2.25-3 4.8 8.594 2.894 17.15 6.1.219-.008.4-.05h.15q.25-.05.5-.15.109 0 .2-.05l-.2-.55m-49.9-1.95q-.3-2.6-.3-5.3 0-.8.05-1.6-6.85-1.35-13.55-2.55 0 3.95.55 7.7 6.55.7 13.25 1.75m1.4 6.6h-.05q-6.6-.5-12.85-.8l1.35 4.7q.424.03.85 0 .4.007.8 0 5.342-.188 10.65-.55l-.75-3.35m29-6.15q-.45 2.2-.45 4.6v.2q1.946.378 3.85.75.14.017.25 0 1.515-.278 3.05-.6v-.05q.946-.16 1.9-.35.341-.075.65-.15 1.742-.377 3.45-.75-6.398-1.942-12.7-3.65m-10 7.65q-1.353-.116-2.65-.2-3.05-.25-5.95-.5l.35 1.65q3.764-.358 7.5-.85.383-.048.75-.1m0-4.75q-.05-.55-.05-1.1 0-2.25.35-4.35-4.8-1.2-9.55-2.3-.25 2-.25 4.1 0 1 .05 1.95 4.7.8 9.45 1.7m7.35-19.6q.15-.1.25-.25 2.5-2.5 5.45-4.25-5.1-2.65-10.2-5.15-1.5 1.15-2.8 2.5-1.4 1.35-2.5 2.85 4.95 2.05 9.8 4.3m-3.25 4.1q-4.9-1.85-9.85-3.6-1.55 2.9-2.5 6.15 4.8 1.45 9.65 2.95.95-2.9 2.7-5.5m-19.35-13.55q2-3.3 4.75-6.3-7.2-3.15-14.6-6-2.55 3.45-4.4 7.15 7.1 2.4 14.25 5.15m10.75-11.8q3.45-2.7 7.4-4.45-7.3-4.45-14.8-8.55-3.75 2.45-7.2 5.7 7.3 3.45 14.6 7.3m-27.65 13.4q-1.1 3.7-1.6 7.7 6.8 1.6 13.7 3.45.65-3.6 1.95-6.95-6.95-2.25-14.05-4.2m55.3 0h.05l-1.05-5.2q-2.31-1.674-4.6-3.2-4.4.7-8.35 2.55 5.3 3.1 10.65 6.4 1.65-.4 3.3-.55m2.45 10.3q-.08-.048-.15-.1-.7-.45-1.4-.8-3.45 1.05-6.3 3.25 4.786 2.27 9.6 4.7l-1.75-7.05m-6.35-31.55q.85 0 1.75.05-3.9-3.7-7.7-7.2-2.85-2.6-5.8-5.15-5.2.65-9.95 2.45 6 4.55 12.05 9.45.75.6 1.45 1.2 3.9-.8 8.2-.8m16.2-3.65q-.1-.1-.2-.25-1.4-1.7-2.75-3.4-1.25-1.5-2.4-2.9-5.95-2-12.55-2.4 4.8 5 9.55 10.15.55.6 1.15 1.25.05.05.1.15l.15.15 6.9-2.7.05-.05z"
                    id="ShiSho032__Layer10_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M98.35 78.5q-5.5-.9-10.95-1.65-6.7-1.05-13.25-1.75-8.75-1-17.15-1.6-9.85-.8-19.3-1-5.55-.1-11-.05-3.5.05-6.95.15-2.15.05-4.3.15-3.25.1-6.45.25l2.25-.15-4.8.1Q6.1 74.2 5.8 75.5l-.95 3.6v.2q.2.1.25.1.25.1.5.15h.15q1.15.25 2.35.55 1.45.3 2.9.65 2.15.5 4.35.95.3.1.65.15l1.9.4v.05q.6.1 1.15.2 2.1-.15 4.3-.3 1.65-.1 3.35-.15 6.45-.3 13.9-.35 8.75-.1 18.8.25 7.85.2 16.5.7 6.25.3 12.85.8h.05q5.05.3 10.35.8h.05q2.9.25 5.95.5 1.3.1 2.7.2.25-.05.5-.05 4.5-.6 9.05-1.35l2.4-.4q.15-.05.3-.05.6-.1 1.15-.2l-3.9-.8q-4.8-1.05-9.55-1.9-4.75-.9-9.45-1.7m3.7-17.4q4.95 1.75 9.85 3.6 5.1 1.9 10.2 4 7.15 2.9 14.25 6.05l-1.15-4.6q-4.85-2.5-9.65-4.75-5.2-2.45-10.4-4.8-4.85-2.25-9.8-4.3-6.45-2.7-12.8-5.15Q85.4 48.4 78.3 46q-9.55-3.25-19.1-5.95-10.5-3-20.9-5.3-10.3-2.35-20.6-4-5.2-.85-10.4-1.5-2.45-.3-4.9-.65h-.25q-.15-.05-.25-.05-.25-.05-.45-.05l.05.05-1.5-.2 15 5.55v.05l-1.1 5.75.1.05q.75.05 1.45.15.55.05 1.05.15 5.15.75 10.2 1.65 4.9.85 9.75 1.75 10.35 2 20.55 4.45 9.45 2.25 18.65 4.85 7.1 1.95 14.05 4.2 6.2 2 12.35 4.15m26.55-20.6q-.25-.2-.45-.35l-.5-.4q-4.4-3.85-8.8-7.45-.7-.6-1.45-1.2-6.05-4.9-12.05-9.45-.75-.55-1.45-1.1-2.65-1.95-5.25-3.85-.55-.35-1-.7-7.75 2.1-16 2.95 7.25 3.55 14.25 7.4 7.5 4.1 14.8 8.55 7.45 4.6 14.65 9.45 2.3 1.55 4.65 3.2l-1.4-7.05m16.95-15.8q-1.4-3.65-3-7.1-.7-1.5-1.4-2.9-2.3-3-4.6-5.85-2.5-3.1-5-6.15-.9-1.05-1.75-2.1-.15-.15-.2-.25l-.2-.2-.05-.05-.05-.1q-1.1.85-2.25 1.7-.25.15-.45.3l-1.55 1.15-2.1 1.4.05.05q3.95 4.2 7.75 8.5 3.65 4.05 7.15 8.2 1.15 1.4 2.4 2.9 1.35 1.7 2.75 3.4.1.15.2.25l3.05-1.25q-.4-.95-.75-1.9z"
                    id="ShiSho032__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M99.15 62.95q-5.9-1.75-11.8-3.35-6.9-1.85-13.7-3.45-9.15-2.2-18.15-4-10.15-2.05-20.2-3.55-4.5-.75-9-1.35-5.2-.7-10.45-1.25-.35-.05-.8-.05-.95-.1-1.85-.15-.8-.1-1.6-.15v.05h-.1q-.4 1.7-.7 3.4l-1.95 8.05H9l.05-.05q1.55 0 3.15.05 1.4 0 2.85.05.9 0 1.8.05 4.7.15 9.45.45 4.65.25 9.35.6 9.8.8 19.85 2.1 8.75 1.15 17.7 2.7 6.7 1.2 13.55 2.55 5.65 1.2 11.4 2.5 4.75 1.1 9.55 2.3 4.8 1.25 9.7 2.55 6.3 1.7 12.7 3.65l.9-.2 4.9-1.1q-8.6-3.25-17.2-6.15-5-1.75-9.9-3.3-4.85-1.5-9.65-2.95M82.3 34.55q7.4 2.85 14.6 6 6.7 2.95 13.35 6.1 5.1 2.5 10.2 5.15 4.9 2.6 9.75 5.35.3.2.6.35.35.15.65.35.7.35 1.4.8.05.05.15.1l-1.8-7.25q-2-1.25-3.95-2.5-5.35-3.3-10.65-6.4-6.85-3.95-13.7-7.55-7.3-3.85-14.6-7.3Q78.4 23 68.4 18.9q-3.05-1.25-6.2-2.45-3-1.15-6.05-2.3-5.15-.75-10.1-2-10.1-2.6-19.3-7.35-.9-.25-1.75-.5-.8-.2-1.55-.4Q15.6 1.85 7.8.2 7.25.1 6.75 0 5.8 1.4 4.9 2.9q-.85 1.45-1.65 2.9Q1.55 8.85 0 12.1q10.15 1.7 20 3.9 10.75 2.35 21.2 5.15 10.8 2.9 21.3 6.4 10.05 3.25 19.8 7m53.45-8.4q-.05-.1-.1-.15-.6-.65-1.15-1.25-4.75-5.15-9.55-10.15l-.15-.15q-4.7-4.9-9.45-9.55l-.2-.2-.05-.05q-3.85 2-7.8 3.65 3.8 3.25 7.6 6.6 2.95 2.55 5.8 5.15 3.8 3.5 7.7 7.2l.95.95.4.4 6.15-2.3-.15-.15z"
                    id="ShiSho032__Symbol_86_0_Layer0_0_FILL"
                />
                <g id="ShiSho032__Layer1_0_FILL">
                    <path
                        fill="#626262"
                        d="M258.65 308.9l-1.05-4.05q-7.037-3.146-14.2-6.05-1.9 2.25-3 4.8 8.594 2.894 17.15 6.1.219-.008.4-.05h.15q.25-.05.5-.15.109 0 .2-.05l-.2-.55h.05m-47.8 8l-.75-3.35h-.05q-6.6-.5-12.85-.8l1.35 4.7q.424.03.85 0 .4.007.8 0 5.342-.188 10.65-.55m-2.45-15.25q0-.8.05-1.6-6.85-1.35-13.55-2.55 0 3.95.55 7.7 6.55.7 13.25 1.75-.3-2.6-.3-5.3m34.1 11.3q.14.017.25 0 1.515-.278 3.05-.6v-.05q.946-.16 1.9-.35.341-.075.65-.15 1.742-.377 3.45-.75-6.398-1.942-12.7-3.65-.45 2.2-.45 4.6v.2q1.946.378 3.85.75m-14.15 2.2q.383-.048.75-.1-1.353-.116-2.65-.2-3.05-.25-5.95-.5l.35 1.65q3.764-.358 7.5-.85m.7-5.95q0-2.25.35-4.35-4.8-1.2-9.55-2.3-.25 2-.25 4.1 0 1 .05 1.95 4.7.8 9.45 1.7-.05-.55-.05-1.1m4.15-14.4q-4.9-1.85-9.85-3.6-1.55 2.9-2.5 6.15 4.8 1.45 9.65 2.95.95-2.9 2.7-5.5m-1.25-13.75q-1.5 1.15-2.8 2.5-1.4 1.35-2.5 2.85 4.95 2.05 9.8 4.3.15-.1.25-.25 2.5-2.5 5.45-4.25-5.1-2.65-10.2-5.15m-18.1.2q2-3.3 4.75-6.3-7.2-3.15-14.6-6-2.55 3.45-4.4 7.15 7.1 2.4 14.25 5.15m10.75-11.8q3.45-2.7 7.4-4.45-7.3-4.45-14.8-8.55-3.75 2.45-7.2 5.7 7.3 3.45 14.6 7.3m-27.65 13.4q-1.1 3.7-1.6 7.7 6.8 1.6 13.7 3.45.65-3.6 1.95-6.95-6.95-2.25-14.05-4.2m57.75 10.3q-.08-.048-.15-.1-.7-.45-1.4-.8-3.45 1.05-6.3 3.25 4.786 2.27 9.6 4.7l-1.75-7.05m-2.45-10.3h.05l-1.05-5.2q-2.31-1.674-4.6-3.2-4.4.7-8.35 2.55 5.3 3.1 10.65 6.4 1.65-.4 3.3-.55m-3.9-21.25q.85 0 1.75.05-3.9-3.7-7.7-7.2-2.85-2.6-5.8-5.15-5.2.65-9.95 2.45 6 4.55 12.05 9.45.75.6 1.45 1.2 3.9-.8 8.2-.8m16.2-3.65q-.1-.1-.2-.25-1.4-1.7-2.75-3.4-1.25-1.5-2.4-2.9-5.95-2-12.55-2.4 4.8 5 9.55 10.15.55.6 1.15 1.25.05.05.1.15l.15.15 6.9-2.7.05-.05z"
                    />
                    <path
                        fillOpacity={0.749}
                        d="M197.2 312.75q-1.2-3.65-1.75-7.55-8.75-3.11-17.15-1.6.9 4.3 2.4 8.45 8.137 2.968 16.5.7m-.25-29.9q1.1-3.5 2.65-6.75-8.483-5.296-19.1-5.95-1.35 3.75-2.2 7.85 8.91 4.23 18.65 4.85m-19.75 3.7q-.15 2.1-.15 4.25 0 2 .15 4 8.563 3.402 17.7 2.7v-.1q0-3.55.45-6.85-8.773-3.895-18.15-4m12.9-33.25q-3.4 4.1-5.9 8.65 9.42 4.69 19.8 7 2.2-3.05 5-5.85.5-.5 1-.95-8.247-6.403-19.9-8.85m31.95-6.1q-2.645-.754-3.15-1.1-7.697 2.123-15.95 2.95 4.31 6.46 14.25 7.4 4.45-2.9 9.45-4.7-.287-.71-1.15-2.25-.81-1.542-3.45-2.3m21.3 1.7q1.7 0 3.3.1-.094-.095-.95-3.15-.868-3.066-4.8-4.9-3.872-1.777-4-1.85-.086-.026-.15-.05-3.793 2.004-7.8 3.6 2.28 3.908 7.65 6.65 3.3-.4 6.75-.4m3-15.65q-1.04.719-2.1 1.4.029.034 1.7 2.5 1.72 2.453 5.5 6.5 3.83 4.103 7.75 7.75 3.994 1.331 7.65 3.4-1.403-3.65-3-7.1-.679-1.478-1.4-2.95-2.32-3.597-4.75-7-2.422-3.342-4.85-4.95-2-.55-4-.9-.187-.047-.35-.1v.05l-.05-.05v.05l-.05-.05q-.026-.013-.05-.05-.227.189-.45.35l-1.55 1.15z"
                    />
                    <path
                        fillOpacity={0.502}
                        d="M210.05 313.55h.05q5.05.3 10.35.8-.65-2.75-.8-5.75-5.5-.9-10.95-1.65.4 3.4 1.35 6.6m9.8-11q.25-2.7 1-5.2-5.9-1.75-11.8-3.35-.5 2.95-.6 6.05 5.65 1.2 11.4 2.5m.2-29.2q-.75.75-1.45 1.6 6.7 2.95 13.35 6.1 2.95-2.45 6.35-4.05-6.85-3.95-13.7-7.55-2.35 1.7-4.55 3.9m-6.2 7.9q-1.7 2.8-2.85 5.8 6.2 2 12.35 4.15 1.4-2.5 3.3-4.8-6.45-2.7-12.8-5.15m35.6-11l-.5-.4q-4.4-3.85-8.8-7.45-4.35.8-8.15 2.6 7.45 4.6 14.65 9.45 1.91-.334 3.9-.4l-.7-3.45q-.187-.177-.4-.35z"
                    />
                    <path d="M210.1 313.55l.75 3.35q.092.004.15 0 .3-.029.6-.05l5.65-.45h.25l1.8-.2q.75-.05 1.45-.15.064-.007.1-.05l-.35-1.65h-.05q-5.3-.5-10.35-.8m9.55-4.95q-.05-.95-.05-1.95 0-2.1.25-4.1-5.75-1.3-11.4-2.5-.05.8-.05 1.6 0 2.7.3 5.3 5.45.75 10.95 1.65m10.85-8.3q4.9 1.55 9.9 3.3 1.1-2.55 3-4.8-5.1-2.1-10.2-4-1.75 2.6-2.7 5.5m-1.1 4.55q-.35 2.1-.35 4.35 0 .55.05 1.1 4.75.85 9.55 1.9v-.2q0-2.4.45-4.6-4.9-1.3-9.7-2.55m-10.8-29.9q-2.75 3-4.75 6.3 6.35 2.45 12.8 5.15 1.1-1.5 2.5-2.85 1.3-1.35 2.8-2.5-6.65-3.15-13.35-6.1m-7.6 12.1q-1.3 3.35-1.95 6.95 5.9 1.6 11.8 3.35.95-3.25 2.5-6.15-6.15-2.15-12.35-4.15M232 265q-3.95 1.75-7.4 4.45 6.85 3.6 13.7 7.55 3.95-1.85 8.35-2.55-7.2-4.85-14.65-9.45m20.5 26.9q-.3-.15-.6-.35-4.85-2.75-9.75-5.35-2.95 1.75-5.45 4.25-.1.15-.25.25 5.2 2.35 10.4 4.8 2.85-2.2 6.3-3.25-.3-.2-.65-.35m-.2-9.05h-.05q-1.65.15-3.3.55 1.962 1.226 3.95 2.45v-.05l-.6-2.95m-.85-19.9q-.21-.16-.4-.35l-.95-.95q-.9-.05-1.75-.05-4.3 0-8.2.8 4.4 3.6 8.8 7.45l.5.4q.213.173.4.35l-1.3-6.55 2.9-1.1z" />
                    <path
                        fillOpacity={0.251}
                        d="M229.55 314.45q.04.284.05.5 4.552-.557 9.1-1.3-.05-.732-.05-1.45-4.8-1.05-9.55-1.9.05 2.1.45 4.15m9.55-7.05q.5-2 1.3-3.8-5-1.75-9.9-3.3-.8 2.15-1.1 4.55 4.8 1.25 9.7 2.55m-2.65-16.7q-1.85 1.9-3.25 4.1 5.1 1.9 10.2 4 .6-.75 1.3-1.4 1.05-1.05 2.15-1.9-5.2-2.45-10.4-4.8m15.45.85q.3.2.6.35.35.15.65.35.599-.214 1.2-.35l-1.45-6.05q-1.988-1.224-3.95-2.45-3.6.85-6.8 2.8 4.9 2.6 9.75 5.35z"
                    />
                    <path
                        fillOpacity={0.451}
                        d="M167.75 246.55q-.21.32-.4.65-.25.35-.45.7-.05.05-.1.15-2.2 3.65-3.9 7.5 10.8 2.9 21.3 6.4 2.5-4.55 5.9-8.65-3.05-1.25-6.2-2.45-3.02-1.175-6.05-2.35-5.157-.694-10.1-1.95m82.95-16.35l-.05-.1q-1.1.85-2.25 1.7h.05l.05.05v-.05h.05q.15.05.35.1 2 .35 4 .9-.9-1.05-1.75-2.1-.15-.15-.2-.25l-.2-.2-.05-.05M159 302.6q1.1 4.7 2.9 9.2 8.75-.1 18.8.25-1.5-4.15-2.4-8.45-9.85-.8-19.3-1m.6-37.75q-1.2 4.2-1.85 8.7 10.35 2 20.55 4.45.85-4.1 2.2-7.85-10.5-3-20.9-5.3M157 283q-.05.9-.05 1.8 0 4.05.4 7.9 9.8.8 19.85 2.1-.15-2-.15-4 0-2.15.15-4.25-10.15-2.05-20.2-3.55z"
                    />
                    <path
                        fill="#008500"
                        d="M251.05 262.6q.19.19.4.35l2.65-.95q-2.023-.3-4-.35l.95.95z"
                    />
                    <path
                        fillOpacity={0.6}
                        d="M141.05 302.7q-2.15.05-4.3.15-3.243.098-6.45.25l2.25-.15-4.85.1q-.3 1.271-.65 2.55l-.9 3.6v.1l-.05.1q.265.095.3.1.258.08.5.15h.15q1.157.267 2.3.5 1.479.367 2.95.7 2.159.475 4.3.95.359.075.7.15.954.19 1.9.35v.1q.583.094 1.15.2 2.086-.152 4.3-.3-2.1-4.7-3.6-9.6m-3.5-22.3q-.35-.05-.8-.05-.95-.1-1.85-.15-.82-.098-1.6-.15h-.15q-.326 1.734-.7 3.45l-1.9 8.05h.15v-.05q1.606.007 3.2 0 1.42.06 2.85.1.9 0 1.8.05-.85-5.5-1-11.25m-13.85-21.7h-.25q-.15-.05-.25-.05-.25-.05-.45-.05l.05.05-1.5-.25 15 5.6v.05l-1.15 5.75h.15q.73.106 1.45.2.55.05 1.05.15.25-3.55.8-6.9l.4-2.4q-5.2-.85-10.4-1.5-2.45-.3-4.9-.65m1.25-18.5q-1.707 3.064-3.25 6.3 10.146 1.716 20 3.9 1.9-5.75 4.75-11.15.1-.3.25-.55-.8-.2-1.55-.4-7.85-2.05-15.65-3.7-.551-.114-1.1-.25-.914 1.465-1.8 2.95-.84 1.43-1.65 2.9z"
                    />
                    <path
                        fillOpacity={0.902}
                        d="M161.9 311.8q-1.8-4.5-2.9-9.2-5.954-1.717-10.35-1.25-4.346.467-7.6 1.35 1.5 4.9 3.6 9.6 1.697.275 5.85.9 4.203.675 11.4-1.4m-24.35-31.4q.15 5.75 1 11.25 4.799 1.635 9.5 1.9 4.701.315 9.3-.85-.4-3.85-.4-7.9 0-.9.05-1.8-3.766-4.153-8.8-3.95-5.034.203-10.65 1.35m1.05-17.15q-.55 3.35-.8 6.9 5.15 1.642 10.1 2.85 5 1.258 9.85.55.65-4.5 1.85-8.7-8.567-6.547-20.6-4l-.4 2.4m9.85-24.1q-1.19-.604-1.75-.45-.15.25-.25.55-2.85 5.4-4.75 11.15 9.875 5.56 21.2 5.15 1.7-3.85 3.9-7.5.05-.1.1-.15.2-.35.45-.7.19-.33.4-.65-10.112-2.588-19.3-7.4z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiSho032__Layer10_0_FILL" />
            <use xlinkHref="#ShiSho032__Layer10_1_FILL" />
            <use
                xlinkHref="#ShiSho032__Symbol_39_0_Layer0_0_FILL"
                transform="translate(121.3 230.1)"
                id="ShiSho032__col1n"
            />
            <use
                xlinkHref="#ShiSho032__Symbol_86_0_Layer0_0_FILL"
                transform="translate(121.7 234.4)"
                id="ShiSho032__col2n"
            />
            <use xlinkHref="#ShiSho032__Layer1_0_FILL" />
        </g></g>
    )
}

export default ShiSho032
