import { AssetSvgProps } from "../../../shared/assets"

function ShiMot091({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 257.8v35.6h66.25v-35.6H159.2z"
                    id="ShiMot091__Layer1_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M159.2 257.75v35.7h66.25v-35.7H159.2z"
                    id="ShiMot091__Layer1_1_FILL"
                />
                <path
                    fill="red"
                    d="M159.2 272.05v7.2h29.7v14.2h7.2v-14.2h29.35v-7.2H196.1v-14.3h-7.2v14.3q-.074.013-.15 0H159.2m29.7 5h-.15q.076-.021.15-.05v.05z"
                    id="ShiMot091__Layer1_2_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot091__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot091__Layer1_1_FILL" />
            <use xlinkHref="#ShiMot091__Layer1_2_FILL" />
        </g></g>
    )
}

export default ShiMot091
