import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000"
}

function ShiMot006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.1 16.3L32.6 4.8 27.8 0 16.3 11.5 4.8 0 0 4.8l11.5 11.5L0 27.8l4.8 4.8 11.5-11.5 11.5 11.5 4.8-4.8-11.5-11.5z"
                    id="ShiMot006__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot006__Symbol_12_0_Layer0_0_FILL"
                transform="translate(176.15 264.7)"
                id="ShiMot006__col1n"
            />
        </g></g>
    )
}

export default ShiMot006
