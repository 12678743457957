import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#984969",
    "col1n": "#C67D9A"
}
function AccMor093({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#6486A6"
                    d="M255.1 329.8q.15 0 .25.05v-.1h-.55q.16.003.3.05m-125-.05h-.4v.1q.201-.057.4-.1z"
                    id="AccMor093__Layer9_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M129.15 2.1q0-1.45-1.25-1.85-.45-.25-2.05-.25H125.25q-.55 0-1.4.1-17.75.4-26 1.1-8.2.65-10.05.9-.75.1-2.15.25-.15.05-4.85.65-4.7.65-10.55 1.5-1.45.2-2.9.3-.4.05-.8.05-.25 0-.55.05H63.4q-.3-.05-.55-.05-.4 0-.8-.05-1.45-.1-2.9-.3Q53.3 3.65 48.6 3q-4.7-.6-4.85-.65-1.4-.15-2.15-.25-1.85-.25-10.05-.9Q23.3.5 5.55.1 4.7 0 4.15 0h-.6Q1.95 0 1.5.25.25.65.25 2.1v.2q.05.8.65 1.5.65.75.65 1.35 0 .55-.4 1.3l-.2.3q-.1.15-.2.25l-.2.3q-.2.25-.3.55-.2.45-.25.95 0 .8.55 1.65.25.2.65.3.2.05.45.15h.8q.15-.05.35-.05h.05q.1-.05.2-.05 32.2-5.05 61.65 2.95 29.4-8 61.65-2.95.1 0 .2.05h.05q.1 0 .2.05h.75q.35-.05.65-.15h.1q.35-.1.55-.3.55-.85.55-1.65-.05-.5-.25-.95-.1-.3-.3-.55l-.2-.3q-.1-.1-.2-.25l-.2-.3q-.4-.75-.4-1.3 0-.6.65-1.35.6-.7.65-1.5v-.2z"
                    id="AccMor093__Symbol_194_0_Layer0_0_FILL"
                />
                <g
                    id="AccMor093__Symbol_193_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M15.2 1.4q1.5-.75-4.7-.4-3.15.1-5.05.1-3.1-.05-4.35-.45Q.35.4 0 0q.05.8.65 1.5.65.75.65 1.35 0 .55-.4 1.3 1.7-1.3 7.25-1.65 5.55-.4 7.05-1.1m25.3 1.2h.55q.15-.05.15-.3 0-.3-.65-.45-.4-.1-1.85-.3-.45-.1-3.45-.4-3-.35-5.35-.45-2.3-.15-3.7-.1-1.4 0-1.8.15-.35.1.05.55.6.2 3.35.3 2.8.05 5.25.3t4.95.5l2.5.2m61.4-2h-.35q-1.1 0-2.55.1-2.35.1-5.35.45-3 .3-3.45.4-1.45.2-1.85.3-.65.15-.65.45 0 .2.15.3h.55l2.5-.2q2.5-.25 4.95-.5t5.25-.3q2.75-.1 3.35-.3.4-.45.05-.55-.4-.15-1.8-.15h-.8m24.5 7.95h.05l-2.6-.85q-.1-.05-.2-.05-3.2-1-6.55-1.65-23.9-3.5-45.25 2.4-.9.2-1.8.5l-.3.1q.16-.056.3-.15-1 .35-1.6.4h-.25q-.65 0-.95-.05 0-.05-.3-.15-.2-.1-.5-.35-.15-.15-.15-.25-.15-.15-.15-.4 0-.2.1-.3V7.6q.15-.75.9-1.2.75-.5 4.5-1.3 3.8-.8 3.8-1.35 0-.35-.25-.4-.15-.05-.55 0-.75 0-2.45.45-1.65.45-3.9.55-.1-.05-.2 0h-.25q-.4.05-1.2-.1-.7-.2-.6-.7.15-.6 1.05-1.05-.4.05-.8.05-.25 0-.55.05H63.15q-.3-.05-.55-.05-.4 0-.8-.05.9.45 1.05 1.05.1.5-.6.7-.8.15-1.2.1h-.25q-.1-.05-.2 0-2.25-.1-3.9-.55-1.7-.45-2.45-.45-.4-.05-.55 0-.25.05-.25.4 0 .55 3.8 1.35 3.75.8 4.5 1.3.75.45.9 1.2.05.25.05.4v.2q0 .1-.05.2-.05.15-.2.3-.3.25-.5.35-.3.1-.3.15-.3.05-.9.05h-.2q-.45 0-1.3-.25-1.05-.35-2.1-.6Q35.8 2.5 11.9 6q-3.35.65-6.55 1.65-.1 0-.2.05l-2.6.85h.05q.1-.05.2-.05 28.25-4.45 54.35 1.2 1.8.35 3.6.8 1.85.45 3.7.95 1.9-.5 3.8-.95 1.8-.45 3.6-.8 26.1-5.65 54.35-1.2.1 0 .2.05m1.6-4.4q-.4-.75-.4-1.3 0-.6.65-1.35.6-.7.65-1.5-.35.4-1.1.65-1.25.4-4.35.45-1.9 0-5.05-.1-6.2-.35-4.7.4 1.5.7 7.05 1.1 5.55.35 7.25 1.65z" />
                    <path d="M27.85 4q0-.1-.05-.05-.048.048-.1.05h.15m73.35 0q-.052-.002-.1-.05-.05-.05-.05.05h.15z" />
                </g>
            </defs>
            <use xlinkHref="#AccMor093__Layer9_0_FILL" />
            <use
                xlinkHref="#AccMor093__Symbol_194_0_Layer0_0_FILL"
                transform="translate(127.8 318.85)"
                id="AccMor093__col1n"
            />
            <use
                xlinkHref="#AccMor093__Symbol_193_0_Layer0_0_FILL"
                transform="translate(128.05 321.15)"
                id="AccMor093__col1d"
            />
        </g></g>
    )
}
export default AccMor093
