import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFCDE8",
    "col1s": "#FF5DB5"
}

function BodWin003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M57.3 24.6q-7.9-7.1-16.45-12.45-.5-.3-.95-.6-3.65-2.9-8.15-5.75-5.4-3.5-8.45-4.7-3.1-1.25-5.75-1-2.65.35-3.7 2.65-1.1 2.2 2.8 5.75 3.9 3.5 15.55 16-5.35-4.15-11.7-6.35-5.25-1.9-9-2.65-1.5-.4-2.7-.55-3.65-.4-6.15.9Q.1 17.2 0 19.45q-.2 2.25 5.2 6.7 1.4 1.15 6.3 4.5 7.2 4 20.55 11.1-.65 0-1.4.05-9.75.45-16.3 3.15-6.55 2.75-7.3 5.5t4.25 4.75q5 1.9 12.6 2.7 7.6.75 17.35.95-1.55.45-1.9.6-3.45 1.65-4.1 5.75-.05.1-.05.25-.6 3.8 2.55 5.35 4.9 1.9 10 2 3.3.05 6.35-.45h.15q.15-.05.35-.05 14.05-2.45 21.85-15.8 5.35-11.8-14.9-27.2-.05-.05-.1-.15-2.85-3.65-4.15-4.55m189.65-5.2q-.1-2.25-2.65-3.6-2.5-1.3-6.15-.9-1.2.15-2.7.55-3.75.75-9 2.65-6.35 2.2-11.7 6.35 11.65-12.5 15.55-16 3.9-3.55 2.8-5.75-1.05-2.3-3.7-2.65-2.65-.25-5.75 1-3.05 1.2-8.45 4.7-4.5 2.85-8.15 5.75-.45.3-.95.6-8.55 5.35-16.45 12.45-1.3.9-4.15 4.55-.05.1-.1.15-20.25 15.4-14.9 27.2 7.8 13.35 21.85 15.8.2 0 .35.05h.15q3.05.5 6.35.45 5.1-.1 10-2 3.15-1.55 2.55-5.35 0-.15-.05-.25-.65-4.1-4.1-5.75-.35-.15-1.9-.6 9.75-.2 17.35-.95 7.6-.8 12.6-2.7 5-2 4.25-4.75t-7.3-5.5q-6.55-2.7-16.3-3.15-.75-.05-1.4-.05 13.35-7.1 20.55-11.1 4.9-3.35 6.3-4.5 5.4-4.45 5.2-6.7z"
                    id="BodWin003__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M26.25 10.85l-.7-.55q.2.15.5.3-3.4-3.95-4.7-4.9Q20 4.75 15.6 1.85q-4.4-2.9-7.8-1.1-3.45 1.65-3.85 6.6-.3 4.85 2.35 10.05.05.15.2.35-3.25-1.8-5.05-.35Q-.7 18.8.3 23.05q.85 3.85 3.85 8.1v.05q2.95 4 8.05 8.5 1.2 1 2.25 1.8-1.1.3-2 .9-2.75 1.65-2.95 4.35-.25 2.6 2.15 4.75.65.55 1.35.95l.6.3q0 .05 1.85.6-.6 0 .75.25.15.05.3.05.2.05.4.05.2.05.45.05.7.05 1.35.05h.15q.15-.05.35-.05 4.15-1.05 1.65-1.2-2.65-.15-3.35-.2-.55-.05-1.05-.15-2.3-.4-3.9-1.75h.05q-1.85-1.6-1.7-3.6.2-2 2.25-3.25h.05q1.35-.85 3-1.05h1.3q1 0 .6-.4t-1.4-.8l-.05-.05-.1-.1q-1.55-.9-3.45-2.55h.05Q8.2 34.3 5.3 30.35q-2.85-3.95-3.65-7.6-.75-3.15.6-4.2h.05q1.4-.95 4.15.8l.05.05q.95.5 4.05 3.05.2.15 1.15.85.9.65 1.35.7.4 0-.45-1.2-.9-1.2-1.1-1.4h-.05v-.05q-.1-.05-.15-.1-2.95-2.95-3.75-4.5-2.5-4.85-2.2-9.3.3-4.05 3.1-5.45H8.4q2.9-1.35 8.9 2.6 4.4 2.85 8.95 6.25m141.45-8.9q2.8 1.4 3.1 5.45.3 4.45-2.2 9.3-.8 1.55-3.75 4.5-.05.05-.15.1v.05h-.05q-.2.2-1.1 1.4-.85 1.2-.45 1.2.45-.05 1.35-.7.95-.7 1.15-.85 3.1-2.55 4.05-3.05l.05-.05q2.75-1.75 4.15-.8h.05q1.35 1.05.6 4.2-.8 3.65-3.65 7.6-2.9 3.95-7.85 8.3h.05q-1.9 1.65-3.45 2.55l-.1.1-.05.05q-1 .4-1.4.8-.4.4.6.4h1.3q1.65.2 3 1.05h.05q2.05 1.25 2.25 3.25.15 2-1.7 3.6h.05q-1.6 1.35-3.9 1.75-.5.1-1.05.15-.7.05-3.35.2-2.5.15 1.65 1.2.2 0 .35.05h.15q.65 0 1.35-.05.25 0 .45-.05.2 0 .4-.05.15 0 .3-.05 1.35-.25.75-.25 1.85-.55 1.85-.6l.6-.3q.7-.4 1.35-.95 2.4-2.15 2.15-4.75-.2-2.7-2.95-4.35-.9-.6-2-.9 1.05-.8 2.25-1.8 5.1-4.5 8.05-8.5v-.05q3-4.25 3.85-8.1 1-4.25-1.15-5.65-1.8-1.45-5.05.35.15-.2.2-.35 2.65-5.2 2.35-10.05-.4-4.95-3.85-6.6-3.4-1.8-7.8 1.1-4.4 2.9-5.75 3.85-1.3.95-4.7 4.9.3-.15.5-.3l-.7.55q4.55-3.4 8.95-6.25 6-3.95 8.9-2.6h-.05z"
                    id="BodWin003__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodWin003__Symbol_11_0_Layer0_0_FILL"
                transform="translate(69.05 210.2)"
                id="BodWin003__col1n"
            />
            <use
                xlinkHref="#BodWin003__Symbol_2_0_Layer0_0_FILL"
                transform="translate(104.45 228.75)"
                id="BodWin003__col1s"
            />
        </g></g>
    )
}

export default BodWin003
