import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#773900",
    "col2n": "#FFC400"
}

function ShiSho022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M147.35 21.15q-1-2.45-2.1-4.8-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2-.6-.95-1.15-1.9-.85-1.3-1.7-2.55-.15-.15-.2-.3-.05-.1-.1-.15l-24.5 11.3q-2.15 1.05-4.3 2-31.55 13.7-63.8.95-4.9-1.95-9.8-4.5L11.85 0q-.15.15-.25.3 0 .05-.05.1l-.2.3Q9.6 3.25 8 5.95q-.3.5-.6 1.05-.55.9-1.05 1.85-2 3.65-3.8 7.5-1.1 2.4-2.1 4.9-.15.25-.25.55-.1.15-.15.35-.05.05-.05.15 4.95 2.5 17.7 10.35v.05l-2.7 14q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8l-.05.15-.9 3.45v.25l-.05.05h.2q.25.1.5.15H8.35q2.05.5 4.15.95 61.75 14 122.7 0 .35-.05.65-.15l3.5-.8H139.5q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15L130 32.7l17.85-10.4q-.05-.05-.1-.15-.15-.35-.25-.7l-.1-.2q-.05-.05-.05-.1z"
                    id="ShiSho022__Symbol_30_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M147.25 24.25q-.1-.15-.15-.35-1-2.25-2.1-4.5l-1-2q-1.9-3.8-4-7.5-1.6-2.8-2.85-4.75-.45-.75-.7-1.2.45-.4.9-.7.15.1.35.2-.15-.15-.2-.3-.2-.25-.35-.45-.05-.1-.05-.15-.95-1.3-1.9-2.55l-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-.75 1-1.45 2.05l-.1.1q-.05.05-.05.1-.25.3-.5.65 0 .05-.05.1.2.15.45.3.1.1.25.2-3.35 5.75-5.95 10.7-2.35 4.45-4.4 9.15-.05.05-.05.1l-.65 1.75-1.15-.45q-.1.15-.15.35-.4 1.05-.8 2.15-.2.45-.35.95l18.95 7.05v.05l-.05.4q.05-.05.1-.05l.95-4L3.9 25.9l.45-1.25q.15-.3.3-.65.15-.4.3-.75l.9-2.1q3.55-8 9.05-16.4 4.75 3.6 9.8 6.55 16.05 9.4 34.8 12.1 7.6 1.1 15.65 1.1 23.55 0 43.3-9.4 6.85-3.3 13.3-7.7.9-.6 1.8-1.25.8-.6 1.6-1.25 6.4 9.85 10.3 19.2.1.25.2.55.05.15.15.35l.3 1-15.85 5.9 1 3.55 19.1-7.1q-.2-.5-.35-.95-.45-1.1-.85-2.15-.05-.15-.15-.35l-1.3.5-.45-1.15z"
                    id="ShiSho022__Symbol_78_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho022__Symbol_30_0_Layer0_0_FILL"
                transform="translate(118.6 231.35)"
                id="ShiSho022__col1n"
            />
            <use
                xlinkHref="#ShiSho022__Symbol_78_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho022__col2n"
            />
        </g></g>
    )
}

export default ShiSho022
