import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D19532",
    "col2n": "#666666"
}

function ObjToo028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M5.2 2Q1.1 4.75.1 9.65q-.5 2.6 1.4 7.8 1.35 3.75 3.95 8.85Q17.1 49.4 32.4 64.75q12.95 11.8 23.9 25.9l2 2.6 1.2 1.6q1.65 2.3 3.3 4.65l16.8 25.15q2.8.8 5.55 2.9 3.9 3 5.3 6.9 1.4 3.65-.3 6.05l4.2 6.3q.1.5.7 2.3.6 1.4.65 2.35.05 1.05-.3 3.1-.15 1.35.4 2.2.7 1.05 2.95 1.55 3.6.75 7.95-2.15.6-.4 1.15-.85.05 0 .1-.05.45-.25 1.05-.65 4.3-2.85 5-6.5.45-2.25-.25-3.3-.55-.85-1.9-1.2-2-.5-2.95-.95-.9-.4-2-1.45-1.35-1.3-1.75-1.65l-6.3-9.45q-1.7 1-3.8 1-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4.35-.35.75-.65L73.6 92.25l-1-1.6q-12-19-19.45-39.75-6.95-16.85-19.9-33.45-2.5-3.15-5.15-6.25l-.05.05Q18.65 1.1 14.2.25q-4.9-1-9 1.75z"
                    id="ObjToo028__Symbol_188_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M55.85 83.7q-1-.7-2.75-2.85l-.85 13.8 3.6-10.95m3.05-19.8l-6.35-8.05q-2.75.3-2.95 3.3l9.3 4.75m.1 3.8q-2.7-.6-3.75 2.45l7.8 8.25L59 67.7m-13 0q-1.3-2.5-4.25-1.4L36.8 82.8 46 67.7m23.1-1.05l1.6 4.15 14.1-.25-15.7-3.9m-7.15-9.85q-1.35 2.5 1.25 4.3l16.35-5.55-17.6 1.25M13.8 53.45l17.65.55q1.55-2.3-.85-4.4l-16.8 3.85m20.4 6.65l-10.7 8.75L36.3 63q-.6-.7-2.1-2.9M27 31.35l6.4 9.45q2.8-.05 3.1-3.25l-9.5-6.2m-2.75 5.2q.4-2.75-2.65-3.65L8.05 43.6l16.2-7.05m-9.55-11.3l-14.7 3 16.15 1.2q-.95-2.1-1.45-4.2M7.15 5.8l10.4 14.1q2.8-.1 3.1-3.25L7.15 5.8M32.7 24.6l-3.75-12.35-.65 13.25q2.35 1.55 4.4-.9M23.3 9.8l2.4-.15L23.95 0l-.65 9.8m9.1 2.4l2.35 1.9 5.75-9.9-8.1 8m38.8 28.85L54.85 48q-.45 2.75 2.55 3.7l13.8-10.65m-28.1 7.9q0-1.2-.75-2.05-.7-.85-1.7-.85t-1.75.85q-.7.85-.7 2.05 0 1.2.7 2.05.75.85 1.75.85t1.7-.85q.75-.85.75-2.05m0-10.05q-.25 2.85 2.8 3.5l12.45-11.75L43.1 38.9M42 25.25q.1 2.8 3.25 3.1l11.55-12.5-14.8 9.4M55.85 39.6q1.3 1.9 1.85 3.1l10.8-9-12.65 5.9z"
                    id="ObjToo028__Symbol_69_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo028__Symbol_188_0_Layer0_0_FILL"
                transform="translate(36.9 168.15)"
                id="ObjToo028__col1n"
            />
            <use
                xlinkHref="#ObjToo028__Symbol_69_0_Layer0_0_FILL"
                transform="translate(23.2 158.55)"
                id="ObjToo028__col2n"
            />
        </g></g>
    )
}

export default ObjToo028
