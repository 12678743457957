import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#EEEEEE",
    "col1s": "#AFAFAF"
}

function ObjToy029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M247.5 9.7q0-4.7-3.5-8.05-3.5-3.4-12.15.15-3.6 1.55-10.9 4.85Q213.7 10 208.4 10.3q-3.65.25-5.35.15h-.25q-.2-.05-.35-.05l-.2-.05q-.85-.05-1.7-.15-.2 0-.4-.05l-2.9-.15q-3.45-.3-6.85-.55-68.5-7.85-137 .7l-3.1.3q-2.3.15-4.6.35-1.3.35-6.55.05-5.3-.35-12.55-3.65-7.3-3.35-10.95-4.85Q7-1.2 3.55 2.15.05 5.5 0 10.2q8.75 19.05 41.65 17.95.9-.1 1.75-.15 3.45-.35 6.9-.65 1.55-.15 38.05-2.7 36.5-2.6 102.05 1.4 9.75.75 19.55 1.65.5-.05.95-.05 28.55-.4 36.6-17.95z"
                    id="ObjToy029__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M210.95 22.35q16.4.35 28.9-7.75 8.25-5.15 7.7-14.6-8.05 17.55-36.6 17.95-.45 0-.95.05-9.8-.9-19.55-1.65-68.4-7.8-137 1.05-1.55.1-3.1.25-3.45.3-6.9.65-.85.05-1.75.15Q8.8 19.55.05.5q-.5 9.45 7.7 14.6 14.55 9.45 34.35 7.4.65-.1 1.35-.1 3.45-.35 6.9-.6 1.55-.15 3.1-.25 68.5-8.6 137-.75 3.4.25 6.85.55 3.25.2 6.5.5h.4q.25.05.5.05l.5.05q2.9.15 5.75.4z"
                    id="ObjToy029__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy029__Symbol_118_0_Layer0_0_FILL"
                transform="translate(68.75 317.65)"
                id="ObjToy029__col1n"
            />
            <use
                xlinkHref="#ObjToy029__Symbol_56_0_Layer0_0_FILL"
                transform="translate(68.7 326.85)"
                id="ObjToy029__col1s"
            />
        </g></g>
    )
}

export default ObjToy029
