import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00002C",
    "col1n": "#00006D",
    "col2d": "#2121E7",
    "col2n": "#4F4FFF"
}

function AccHat027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M181.95 48.2q-1.2-2-2.5-3.9l-2.65-3.8q-.15-.25-.3-.45l-.35-.4q-.05-.05-.05-.1-2.3-3.1-4.8-5.9l-2.45-2.7-9.45-8.75q-13.45-11-30.75-16.6-3.2-1.1-6.35-1.95-22.75-7.3-47.35 0-2.3.65-4.5 1.4l-2.3.95q-4.3 1.55-8.6 3.5l-.8.4-1.3.6q-.3.15-.55.3-.15 0-.25.1-.55.25-1.1.55l-3.7 1.85q-.9.4-1.7.9l-.05-.05q-9.5 5.45-17.4 13l-.5.5Q21.05 38 13.65 51.4l-.15.5q-1.65 2.7-3 5.55-.45.85-.8 1.75-1.75 3.45-2.6 7.3v.05q-.05 0-.05.05-.65 1.5-1.25 3.05l-.3.9q-1.85 5.35-3.05 10.8Q1.5 85.55 1 89.8q-.35 2.35-.55 4.75l-.05.05q-.1 1.15-.15 2.35L.2 98.9q-.1 1.35-.1 2.7H.05q-.1 2.05-.05 4.05v2.2q.3 4.95 4.1 4.2 1.15-.45 2.35-.85 4.05-1.5 8.1-2.85 1.55-.55 3.1-1.05 17.2-5.7 34.4-9.75l.7-.2 7.05-1.6q.2-.05.45-.1.05-.1.05-.15.15-.45 6.65-3.6 6.5-3.2-1.35-11.4 5.9-12.35 15.15-18.35 15.8-9.9 39 .5 7.1 4.1 11.45 11.25-13.25 6.3 4.9 11.95l.05.3.2.8v.1l8.4.05h.8q18.95.35 37.75 2.7 3.25.4 6.5.9.95.1 1.95.25 5.1.4 4.7-3.85l-.2-1.2q-1.7-9.95-5.45-19.6-.2-.55-.45-1.1l-1.1-2.7q-.1-.15-.15-.25-.15-.4-.25-.7l-.1-.1q-.95-2.25-2-4.4-1.3-2.55-2.7-5.05-.2-.4-.4-.75l-.5-1.1-.05-.05-1-1.85-.15-.05z"
                    id="AccHat027__Symbol_245_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M181.95 48.2q-1.2-2-2.5-3.9l-2.65-3.8q-.15-.25-.3-.45l-.35-.4q-.05-.05-.05-.1-2.3-3.1-4.8-5.9l-2.45-2.7-9.45-8.75q-13.45-11-30.75-16.6-3.2-1.1-6.35-1.95-22.75-7.3-47.35 0-2.3.65-4.5 1.4l-2.3.95q-4.3 1.55-8.6 3.5l-.8.4-1.3.6q-.3.15-.55.3-.15 0-.25.1-.55.25-1.1.55l-3.7 1.85q-.9.4-1.7.9l-.05-.05q-9.5 5.45-17.4 13l-.5.5Q21.05 38 13.65 51.4l-.15.5q-1.65 2.7-3 5.55-.45.85-.8 1.75-1.75 3.45-2.6 7.3v.05q-.05 0-.05.05-.65 1.5-1.25 3.05l-.3.9q-1.85 5.35-3.05 10.8Q1.5 85.55 1 89.8q-.35 2.35-.55 4.75l-.05.05q-.1 1.15-.15 2.35L.2 98.9q-.1 1.35-.1 2.7H.05q-.1 2.05-.05 4.05v2.2q.3 4.95 4.1 4.2 1.15-.45 2.35-.85 4.05-1.5 8.1-2.85-.35-4.45-.35-9.05 0-39.7 24.8-67.8 24.8-28 59.9-28 35.1 0 59.9 28 21.8 24.7 24.5 58.3 3.25.4 6.5.9.95.1 1.95.25 5.1.4 4.7-3.85l-.2-1.2q-1.7-9.95-5.45-19.6-.2-.55-.45-1.1l-1.1-2.7q-.1-.15-.15-.25-.15-.4-.25-.7l-.1-.1q-.95-2.25-2-4.4-1.3-2.55-2.7-5.05-.2-.4-.4-.75l-.5-1.1-.05-.05-1-1.85-.15-.05z"
                    id="AccHat027__Symbol_157_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M75.8 11.95q-1.3-5.1-3.4-9.3-.15-.2-.2-.4-.1-.1-.1-.2Q71.5 1 70.9 0 38.05.8 5.3 6.6q-.6 1.25-1.1 2.55-.1.1-.1.2-.1.2-.2.45-2.05 5-3.65 10.9-.1.45-.25.9 38.25-8.3 76-8.6-.05-.35-.15-.75l-.05-.3z"
                    id="AccHat027__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M67.9 2.05Q67.3 1 66.7 0 33.85.8 1.1 6.6.5 7.85 0 9.15q35.05-7.3 67.9-7.1z"
                    id="AccHat027__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat027__Symbol_245_0_Layer0_0_FILL"
                transform="translate(93.2 51.7)"
                id="AccHat027__col1n"
            />
            <use
                xlinkHref="#AccHat027__Symbol_157_0_Layer0_0_FILL"
                transform="translate(93.2 51.7)"
                id="AccHat027__col1d"
            />
            <use
                xlinkHref="#AccHat027__Symbol_69_0_Layer0_0_FILL"
                transform="translate(153.5 125.6)"
                id="AccHat027__col2n"
            />
            <use
                xlinkHref="#AccHat027__Symbol_18_0_Layer0_0_FILL"
                transform="translate(157.7 125.6)"
                id="AccHat027__col2d"
            />
        </g></g>
    )
}

export default AccHat027
