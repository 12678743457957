import { AssetSvgProps } from "../../../shared/assets"

function ShiMot095({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#006431"
                    d="M175.95 258q-5.637 17.801 0 35.8h49.75V258h-49.75z"
                    id="ShiMot095__Layer1_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M204.7 266.6l.05 3.15-2.75 1.05 2.95.8.15 3.1 1.75-2.6 3 .9-2-2.5 1.65-2.6-2.85 1.1-1.95-2.4m-9.45-1.15q.68-.659 1.4-1.2-2.899.849-5.2 3.15-3.55 3.6-3.55 8.65 0 5.05 3.55 8.65 3.6 3.6 8.7 3.6 5.05 0 8.6-3.6 1.778-1.753 2.7-3.85-.2.202-.4.4-3.25 3.3-7.85 3.3-4.65 0-7.95-3.3-3.2-3.25-3.2-7.85 0-4.65 3.2-7.95M159.2 258v35.8h16.75V258H159.2z"
                    id="ShiMot095__Layer1_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot095__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot095__Layer1_1_FILL" />
        </g></g>
    )
}

export default ShiMot095
