import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#EEEEEE",
    "col2n": "#999999"
}

function LegSho006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.9 7.7q-9-5.25-23.3-6.95-13.1-1.45-27.4 0-7 .8-14.2 2.45l-8.3 2.05-8.3-2.05Q58.2 1.55 51.2.75 36.9-.7 23.8.75 9.1 2.5 0 8h147.4l-.5-.3z"
                    id="LegSho006__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 5.2q0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45Q3 5.8 3 5.2V0H0v5.2m74.95 0q0 .6.45 1.05.45.45 1.05.45.6 0 1.05-.45.45-.45.45-1.05V0h-3v5.2z"
                    id="LegSho006__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho006__Symbol_10_0_Layer0_0_FILL"
                transform="translate(118.8 326)"
                id="LegSho006__col1n"
            />
            <use
                xlinkHref="#LegSho006__Symbol_11_0_Layer0_0_FILL"
                transform="translate(153.55 334)"
                id="LegSho006__col2n"
            />
        </g></g>
    )
}

export default LegSho006
