import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M63.35 22.8q-.35-5.3-2-9.4-3.05-7.1-10.6-10.5Q47.9 1.5 44.7.8 32.35-.75 18.75.8q-3.25.7-6.1 2.1Q5.1 6.3 2.05 13.4q-1.7 4.1-2 9.4-.1 1.55-.05 3.25v1.35q.3 7.85 3.8 13.1 1.35 1.45 3 1.45 3.35 0 3.35-4.55 0-.8-.1-1.15-.1-.4-.1-.5-.05-.15-.25-.95-.2-.8-.6-1.9Q8 29.95 8 25.95q0-1.6.4-3.15.1-.6.3-1.2.5-1.55 1.3-2.85l.25-.3q1.85-3.05 4.7-5.05 5.85-3.85 15.7-3.4Q42.1 8.85 48.6 13.4q2.7 2 4.55 5.05l.25.3q.8 1.3 1.3 2.85.2.6.35 1.2.35 1.55.35 3.15 0 4-1.1 6.95-.4 1.1-.65 1.95l-.3 1.3q-.1.45-.1 1.25 0 4.55 3.35 4.55.9 0 1.7-.45 4.05-3.05 5.1-14.65.05-2.1-.05-4.05z"
                    id="BeaMus029__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus029__Symbol_46_0_Layer0_0_FILL"
                transform="translate(160.8 198.4)"
                id="BeaMus029__col1n"
            />
        </g></g>
    )
}

export default BeaMus029
