import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#773900",
    "col2n": "#D19532"
}

function ObjToy046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M83.05 23.25q2.3-.55 4.1-2.1 2.7-2.35 2.7-5.65 0-3.3-2.7-5.65-2.7-2.35-6.55-2.35-3.85 0-6.55 2.35-.55.45-1 1Q70.6 9.55 67.8 9q-.25-3.55-2.85-6.15Q62 0 57.85 0 53.7 0 50.8 2.85q-2.95 2.85-2.95 6.9v.45H48q4.35.7 4.35 6.35l.1 1q.55 8.75-2.4 12-2.6 2.1-5.2 2.45h-.3q-2.75.2-5.45-1.65-.55-.15-1.7-1.25l-1.6-1.6q-.05-.1-.15-.2-.05-.05-.05-.1l-.2-.3q-.9-1.75-1.05-3.9-.05-.15-.05-.25-.95-6-4.45-9.15-1.2-.85-2.45-1.15-1.95-.5-3.45-.2-2 .4-3.25 1.55-1.3 1.55-1.85 3.05-1 6.1 4.75 8.45l.8.3q-.4.25-.8.55-3.95 2.85-5.95 6.35-.45-.25-1-.55-5.4-2.6-13.7 0-.1 0-.15.05-.5.2-.95.4-.5.15.15 1.45.6 1.3.35 5.1t-1.6 4q-1.4.15-.15.75.05.05.15.1 1.4.65 4.25.7 2.85.05 10.4-.3-6.9 1.6-12.3 3.9-.5.2-.95.5-.65.4-1.1.95Q4.55 51.2 4 54.4q-.55 3.15.7 4.2.05 0 .15.05.55.1 1.15.2 2.25.3 4.5.5 12.45.25 14.85-3.35.25-.6.45-1.2Q32.95 59 42.55 59q11.1 0 18.95-5.65.85-.6 1.6-1.25 3.1 6.4 9.15 6.85 4.6.55 7.3-3.25 2.35-5.05-2.4-8.9-3-2.4-6.7-3.45 0-.05-.05-.05 3.8-1.35 6.95-4.1 6-5.25 6-12.7 0-1.7-.3-3.25z"
                    id="ObjToy046__Symbol_131_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M8.2 47.1Q6 44.2 3.1 46.05q-1.4 1.65-.65 4.45.95 3.55 4.3 4.6l.2-.1q2.7-1.2 2.6-5.3-.3-1.4-1.35-2.6M3.9 28.9q-.5.2-.95.4-.9.4-1.45 1.35-2.75 4.55-.45 8.5.35.4 1.6 1.05 2.1.7 3.95-2.65 1.15-2.45.9-4.9l-.15-.85q-.75-2.9-3.45-2.9m43-.4h-.3q-2.75.2-5.45-1.65-.55-.15-1.7-1.25l-1.6-1.6q.5.55.8.95l-.5.2h-.05v-.05q-.1.05-.2.05-.2.1-.45.15l-.3.1q-.45.15-.95.35-2.15.85-4 2.05-.1.05-.15.1-5.75 3.75-5.75 9.1v.75q.25 2.6 1.9 4.85 1.45 1.95 4 3.6Q38.05 50 46.35 50t14.2-3.8q2.2-1.45 3.6-3.1.7-.9 1.2-1.8l-.45-.3h-.2q-4.2 0-7.75-1.35-8.85-4.7-10.05-11.15M65.2 4.9q-.25-1.25-.95-2.25-.25-.4-.6-.75Q61.9 0 59.4 0t-4.25 1.9Q53.4 3.8 53.4 6.5q0 .75.15 1.45h.05q.4-.25.8-.45.5-.3.95-.5 1.75-.8 3.6-1.3.4-.1.8-.15 1.05-.25 2.1-.35.2-.05.4-.05Q63.55 5 64.9 5l.3-.1m22.7 8.35q0-1.75-1.6-3T82.4 9q-1.8 0-3.2.8l-.2.1q.2.15.4.35 3.55 3.1 4.95 7 1.1-.35 1.95-1 1.6-1.25 1.6-3z"
                    id="ObjToy046__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    d="M131.85 306.35q.3 1.4 1.4 2.15.087.06.15.1-.069.413-.2.85-.15.45-.3.95-.1.3 0 .4-2.698.274-5.35.4l.15 1.4h.6q2.8-.05 4.75-.3.78.387 1.55 1.05 1.52 1.325 1.9 1.8l1.05-.7q-1.081-1.098-1.8-1.8-1.65-1.698-1.75-1.7.05-.2.1-.35.25-.75.45-1.5h.15q.51.08 1.05 0 1.4-.1 2.55-1.25 1.05-1.1 1-2.2-.1-1.15-2.2-1.8-2.2-.55-3.25-.8-1.1-.35-1.7.7-.55 1.2-.3 2.6z"
                    id="ObjToy046__Layer5_0_FILL"
                />
                <path
                    d="M142.45 297.15q-.85.9-.85 2.1 0 1.2.85 2.05.9.9 2.1.9 1.2 0 2.05-.9.9-.85.9-2.05 0-.9-.5-1.6-.15-.25-.4-.5-.85-.85-2.05-.85-1.2 0-2.1.85z"
                    id="ObjToy046__Layer5_1_FILL"
                />
                <path
                    fill="#FFF"
                    d="M146.45 299.45q0-.6-.45-1.05-.9 1.75-2.7 1.75.1.2.35.4.5.55 1.15.55.7 0 1.1-.55.55-.4.55-1.1z"
                    id="ObjToy046__Layer5_1_MEMBER_0_FILL"
                />
                <path
                    d="M134.45 293.05q-.15-.25-.4-.5-.85-.85-2.05-.85-1.2 0-2.1.85-.85.9-.85 2.1 0 1.2.85 2.05.9.9 2.1.9 1.2 0 2.05-.9.9-.85.9-2.05 0-.9-.5-1.6z"
                    id="ObjToy046__Layer5_2_FILL"
                />
                <path
                    fill="#FFF"
                    d="M132.25 296.5q.7 0 1.1-.55.55-.4.55-1.1 0-.6-.45-1.05-.9 1.75-2.7 1.75.1.2.35.4.5.55 1.15.55z"
                    id="ObjToy046__Layer5_2_MEMBER_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy046__Symbol_131_0_Layer0_0_FILL"
                transform="translate(71.6 275.6)"
                id="ObjToy046__col1n"
            />
            <use
                xlinkHref="#ObjToy046__Symbol_43_0_Layer0_0_FILL"
                transform="translate(69.55 279.1)"
                id="ObjToy046__col2n"
            />
            <use xlinkHref="#ObjToy046__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy046__Layer5_1_FILL" />
            <use xlinkHref="#ObjToy046__Layer5_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy046__Layer5_2_FILL" />
            <use xlinkHref="#ObjToy046__Layer5_2_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjToy046
