import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2d": "#FF008C",
    "col2l": "#FF98D0",
    "col2n": "#FF4DAD"
}

function ShiLon068({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.65 21.95l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.4-1.2-.85-2.4L104.2 0q-22.5 6.6-45 0L7.35 1.35l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.95-.3 12.45 4.5l.2.2-.05.25.2-.1-1.95 24.3-.9-.2q-.65-.14-1.65-.3.05.033.1.05l.2.1v4.6q3.15.65 6.25 1.25.4.05.75.15.25 0 .5.05.4.05.75.15.4.05.75.1.75.15 1.5.25.25.05.45.1Q39.4 63 50 64.15q.2 0 .4.05 2 .15 3.95.35.2.05.4.05 3.4.3 6.75.55 10.05.75 20.05.8.2-.05.4 0 1.85.3 3.6.95 2.35.85 4.5 2.25 5.15 3.3 7.1-.15.25-.45-.45-1.05-.55-.5-1.2-1.05-.65-.45-1.3-.95l-.3-.2h.05q.8-.05 1.6-.05l7.7-.4 6.3-.5q2-.15 3.95-.3.15-.05.3-.05 9.75-.95 19.5-2.5l.7-.1 2-.3q.4-.1.75-.15.25-.05.5-.05l2.7-.5q3.8-.7 7.55-1.45v-4.9q-1.3.3-1.55.35l-1.95.35-1.4-25.15q.1.25.2.4.05.1.1.1h.05v-.05h.05v-.05q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V29.2q0-.15-.05-.35v-.1q-.1-.05-.15-.1-.05-.1-.1-.15l.25.2q-.3-1.45-.55-2.9l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85z"
                    id="ShiLon068__Symbol_508_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M12.35 2.7q-.75-.15-1.5-.25-.35-.05-.75-.1-.35-.1-.75-.15-.25-.05-.5-.05v4.8q.25 0 .5.05.4.05.75.15.4.05.75.1.75.15 1.5.25.25.05.45.1V2.8q-.2-.05-.45-.1m22.1 7.9q2 .15 3.95.35v-5.1q-1.95-.2-3.95-.35v5.1m42.9-.1l-2.7-1.35q-1.55-.8-2.45-1.2-.75-.35-2.8-1.15l-3.1.05q4.8 1.65 6.95 2.7 1.7.8 4.75 2.6.8-.05 1.6-.05-.35-.3-1-.8l-1.25-.8M93.6 6.1v5.1q2-.15 3.95-.3V5.8q-1.95.15-3.95.3M124 7.25v-4.9q-1.35.2-2.7.45-.25 0-.5.05-.35.05-.75.15v4.95q.4-.1.75-.15.25-.05.5-.05l2.7-.5z"
                    id="ShiLon068__Symbol_450_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2 4q.85 0 1.4-.6Q4 2.85 4 2q0-.85-.6-1.45Q2.85 0 2 0 1.15 0 .55.55 0 1.15 0 2t.55 1.4Q1.15 4 2 4z"
                    id="ShiLon068__Symbol_415_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M146.5 19.1q-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.75-1-1.45-1.95l-.3-.4-1.85 1.4q-.2.15-.35.3-.45.3-.9.65-.75.6-1.55 1.15L129 4.65q-.45.3-.85.6-3.5 2.35-7.1 4.35-4.9 2.7-10.1 4.75-2.4 2.55-5.65 4.85l-.6.4q-4 2.7-8.55 4.65-.2.05-.45.15v2.05h.2V78.8H54.15V25.05q-.35-.15-.65-.25-10.35-4.3-15.95-11.2l-1.7-.7q-4.4-2-8.6-4.45-2.3-1.35-4.55-2.85-.7-.5-1.4-.95L19.75 3.5l-1.2-.9-1.6-1.2L15.3.15l.05.15-.15-.15q0-.05.05-.05L15.1 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l19.55 7.4-4.3 20.65v.05l-.2.8-.6.45-2.75 11.5q-.55 2.1-1.05 4.2l-.9 3.6-1.1 3.9q-.1.1-.1.25 0 .05.25.05-.095-.048-.2-.05h.35q-.103.003-.15.05l1.1.15q.05 0 .1.05 1.1.15 1.8.3 3.15.6 4.9.9.4.05.75.15.25 0 .5.05.4.05.75.15.4.05.75.1.75.1 1.5.25.25.05.45.1 10.7 1.6 21.25 2.65.2 0 .4.05 2 .15 3.95.35h.4l4.1.35q.65 0 1.35.05l1.3.1q11.6.75 23.05.75h2.3q.5-.05 1 0l13.95-.5h.4l.6-.05h.45q.1-.05.25-.05.75-.05 1.55-.05l4.5-.35q2-.15 3.95-.3.15-.05.3-.05 9.8-.85 19.5-2.35l.7-.1q1-.2 2-.3.4-.1.75-.15.25-.05.5-.05 1.35-.25 2.7-.45l6-1.1q.25-.05 1.55-.35l.2-.1q.1 0 .65-.15.55-.2.45-.35l-.05-.2v-.1l-.85-3.4v-.05l-4.3-17.25h-.05l-.55-2.4h-.05v.05h-.05q-.05 0-.1-.1-.15-.2-.3-.75l.05.4-4.3-21.5.25-.1v.05l.65-.3 18.55-6.85v-.05q-.9-2.4-1.85-4.75-1-2.3-2-4.5m-5.9 61.95h.05l-.1.1.05-.1M32.05 20.8l-.2.4v-.05l.2-.35z"
                    id="ShiLon068__Symbol_397_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M71.95 40L53.2 67.25h36.5L71.95 40M17.6 2.95L16.05 1.8l-1.2-.9-.4.5-.1.1Q4.6 13.25.15 27.65q-.1.15-.15.35l.05.05v.05l5.75 2.15v-.05h.05q.1-.4.25-.8Q9.85 15.65 18.6 4.35l.3-.4q.1.05.15.1V4l-.1-.1H19q-.7-.5-1.4-.95M91.15 26.8q.45-.2.85-.35.1-.05.2-.05l-.2-3.7q-.4.15-.85.35-9.35 3.45-20.8 3.45-10.9 0-19.9-3.15v3.75q9 3.15 19.9 3.15 11.45 0 20.8-3.45m-18.8 1.7h-.05v-.05q.001.049.05.05m-2.75-.15h-.15q.05-.05.15-.05v.05M129.3 0q-.45.3-.9.65-.75.6-1.55 1.15l-1.55 1.15q-.45.3-.85.6 0 .05.05.1.65.9 1.3 1.85 7.55 11 10.9 23.45.15.65.3 1.25h.05l6.6-2.45-.05-.15q-.15-.45-.3-.95-4.6-13.05-13.05-25.25-.3-.45-.6-.85-.15-.2-.25-.4-.05-.1-.1-.15z"
                    id="ShiLon068__Symbol_392_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon068__Layer9_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon068__Symbol_508_0_Layer0_0_FILL"
                transform="translate(110 255.6)"
                id="ShiLon068__col1n"
            />
            <use
                xlinkHref="#ShiLon068__Symbol_450_0_Layer0_0_FILL"
                transform="translate(125.95 309.2)"
                id="ShiLon068__col1s"
            />
            <use
                xlinkHref="#ShiLon068__Symbol_415_0_Layer0_0_FILL"
                transform="translate(191.6 309.2)"
                id="ShiLon068__col2n"
            />
            <use
                xlinkHref="#ShiLon068__Symbol_397_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiLon068__col2d"
            />
            <use
                xlinkHref="#ShiLon068__Symbol_392_0_Layer0_0_FILL"
                transform="translate(121.05 230.3)"
                id="ShiLon068__col2l"
            />
            <use xlinkHref="#ShiLon068__Layer9_0_FILL" />
        </g></g>
    )
}

export default ShiLon068
