import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#DE5500",
    "col1n": "#FF7B00",
    "col1s": "#9F3D00"
}

function AccHat075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M179.7 85.85q5.5.45 11 .95l5.1.6V74.3l-4-.5-.15-.55Q184.6 51.8 167.4 34.6q-6.8-6.8-14.25-12-1.85-1.25-3.7-2.45-.1-.05-.15-.1-.35-.2-.4-.05V9.3h-12v4.2q-2.35-1-4.25-1.7-10.1-3.8-22.2-5.4-3.5-.45-7.2-.75h-.35V0H90.85v5.95h-.6q-1.05.05-2.1.15-13.6 1.15-25.7 5.7-2.65 1-5.75 2.45l-.1.2.1-4.45H44.65v10.75q-.25.3-.5.4-.1.05-.15.05-1.6 1.05-3.2 2.2l-.5.4Q38.65 25 37 26.3q-4.85 3.8-9.4 8.3Q10.45 51.8 3.45 73.25l-.15.25q-.05.15-.05.35L0 74.2v13l4.1-.4q5.75-.5 11.55-.95Q28 84.9 40.3 84.2h.5q1.7-.1 3.35-.2h.5q52.6-2.7 104.25-.15.2 0 .4.05 1.95.05 3.85.2 13.3.7 26.55 1.75z"
                    id="AccHat075__Symbol_292_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M33.35 20.65q-4.85 3.8-9.4 8.3Q7 45.95 0 67.1q7.9-.75 15.85-1.3Q22 48.25 34.6 33.8q1-1.15 2.05-2.3v33.45l3.85-.25V15.5q-.1.05-.15.05-1.6 1.05-3.2 2.2l-.5.4v.1L33.7 20.4q-.2.1-.35.25M86.3.35q-.45 0-.9.05-.45 0-.9.05V.5Q70.9 1.6 58.8 6.15q-2.55.95-5 2.05.05.05.1.15.05.1.1.25v8.35q7.45-4.7 15.65-7.4.5-.15.95-.3.05-.05.1-.05l.3-.1q1.75-.6 3.6-1.05 3.95-1 8.1-1.6.45-.1.95-.15h.2q.3-.05.65-.05V63.5h2.1V.3q-.15 0-.3.05m46.2 7.2q-1.75-.75-3.5-1.4-8.05-3.05-16.8-4.55l-5.4-.8V.75Q103.3.3 99.6 0v2.15h.15V63.3h2.1V6.05q4.5.4 8.85 1.35.3.05.6.15.7.15 1.45.35l.4.1q.3.05.6.15h.05q.4.1.85.25 9.25 2.55 17.65 7.55V7.9q0-.15.05-.25l.1-.1h.05m39.7 58.25q7.8.6 15.65 1.35-7.1-21.2-24.1-38.2-6.8-6.8-14.25-12-1.65-1.15-3.3-2.2-.2-.1-.4-.25-.1-.05-.15-.1v50.25l3.85.25V29.6q2 2 3.9 4.2 12.6 14.45 18.8 32z"
                    id="AccHat075__Symbol_196_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M188.4 67.5q-.1-.25-.15-.45-20.75-1.95-41.65-3v-49.4q-.2-.1-.4-.25-.5-.35-.55-.15v-.5q-.15.15-.1.3V64q-5.9-.3-11.8-.5V7.8q0-.2-.1-.3v.25l-.5-.2q-.15-.05-.25-.1h-.05l-.1.1q-.05.1-.05.25v55.65q-16.05-.6-32.15-.65V.15q-.2-.05-.4-.05V0h-.65V62.8q-5.85-.05-11.75 0V.25q-.1 0-.15-.05h-.5q-.2 0-.4.05V62.8q-16.1.1-32.3.75V8.5q-.05-.15-.1-.25t-.1-.15q-.15.1-.3.15 0-.05-.05-.05-.15.1-.35.25-.1.05-.15.1V63.6q-5.9.2-11.8.5V15q0-.2-.15-.35V15q-.35.4-.65.45l-.05.05q-.1.05-.2.15v48.5Q20.5 65.2.4 67l-.2.5-.15.25q-.05.15-.05.3 20.3-1.85 40.5-2.9.55 0 1.05-.05 5.9-.3 11.8-.5.55-.05 1.05-.05 16.2-.65 32.3-.75.55-.05 1.05 0 5.9-.05 11.75-.05h1.05q16.1.1 32.15.7.55 0 1.05.05 5.9.2 11.8.5.55.05 1.05.05 21 1.05 41.85 3h.1l-.15-.55z"
                    id="AccHat075__Symbol_137_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat075__Symbol_292_0_Layer0_0_FILL"
                transform="translate(94.85 44.7)"
                id="AccHat075__col1n"
            />
            <use
                xlinkHref="#AccHat075__Symbol_196_0_Layer0_0_FILL"
                transform="translate(98.5 50.35)"
                id="AccHat075__col1d"
            />
            <use
                xlinkHref="#AccHat075__Symbol_137_0_Layer0_0_FILL"
                transform="translate(98.1 50.45)"
                id="AccHat075__col1s"
            />
        </g></g>
    )
}

export default AccHat075
