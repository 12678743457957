import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00006D",
    "col1n": "#0000BB",
    "col2d": "#830000",
    "col2n": "#C40000",
    "col2s": "#540101"
}

function AccHat007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M172.05 37.4q-3.5-4.35-7.55-8.4-17.85-17.9-40.25-24.7-.75-.2-1.45-.4-29.15-7.8-55.55 0-.7.2-1.35.4-7.55 2.3-14.55 5.8-13.9 7-25.75 18.9-4.1 4.05-7.55 8.4-8.6 10.65-13.7 22.75Q1.75 66.2.1 72.65q-.05.15-.1.25.4-.15.85-.25 90.1-23.2 189.2 0l-.2-.7q-1.65-6.1-4.05-11.8-5.1-12.1-13.75-22.75z"
                    id="AccHat007__Symbol_226_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M172.55 37.4q-3.5-4.35-7.55-8.4-18.8-18.85-42.7-25.4-2.15-.55-4.3-1.05v.05q-24.95-5.5-47.85.65l-2.4.65q-8.3 2.35-15.9 6.2-13.9 7-25.75 18.9-4.1 4.05-7.55 8.4Q7.3 51.25 2.05 67.7q-.8 2.35-1.4 4.75-.1.25-.65 2.85h.5q.1-.05.15-.05Q6.3 73.5 12 71.95q1.8-.5 3.65-.95h.1q27.8-7.25 55.65-9.45 25.15-2.05 50.35.1 27 2.25 54 9.3h.1q1.85.45 3.7.95 5.45 1.45 10.9 3.15.05 0 .1.05h.65q-.7-2.65-.75-2.85-.65-2.45-1.4-4.8-5.3-16.3-16.5-30.05m-57-32.7q.4.1.85.25 21.8 6 38.75 25.4 13 14.9 19.15 33.05-78.55-19.9-157.1.1 6.1-18.2 19.15-33.15Q51.8 12.6 71.4 6.1q.5-.15.95-.3.05-.05.1-.05l.3-.1q1.75-.6 3.6-1.05 1.5-.4 3.05-.7 16.4-3.4 32.85 0 .1 0 .2.05.3.05.6.15.7.15 1.45.35l.4.1q.3.05.6.15h.05z"
                    id="AccHat007__Symbol_507_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.35 26.8q7.25-2.3 14.55-4.3 1.8-.5 3.65-.95V.1Q10.95 2 3.4 4.35l-3.2 1-.2.25v20.9l.35.3M197.3 5.35q-1.9-.65-3.85-1.25-7.35-2.25-14.7-4.1v21.5q1.85.45 3.7.95 7.05 1.9 14.15 4.15l.6.2h.1q.1-.05.15-.1.05-.1.05-.2V5.6l-.2-.25z"
                    id="AccHat007__Symbol_508_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat007__Symbol_53_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M122.8 24.4q0-.95-.7-1.6-.65-.65-1.65-.65h-10l9-3.5q.2-.05.35-.15.9-.35 1.3-1.15.35-.95 0-1.85-.35-.9-1.2-1.25t-1.75 0q-.1.05-.15.1l-8.05 3.1-.2.1-2.1.85 7.7-7.75.25-.25q.7-.7.75-1.55-.05-1.05-.7-1.7-.7-.7-1.65-.65-.9 0-1.6.7l-6.25 6.25-.1.1-.75.75 3.9-8.9q.15-.15.15-.35.35-.85.1-1.7-.4-.9-1.3-1.3T106.4 2q-.85.35-1.25 1.25v.2l-3.55 7.85v.2l-.95 2.15v-11-.3q0-1-.55-1.65-.1-.1-.25-.2-.7-.5-1.45-.5-.85 0-1.4.5l-.2.2q-.65.65-.7 1.6l.05 8.85q0 .1-.05.15v.75L92.7 3.3q-.05-.2-.15-.35-.35-.9-1.15-1.3-.95-.35-1.85 0-.9.35-1.25 1.2t0 1.75q.05.1.1.15l3.1 8.05.1.2.85 2.15L84.7 7.4l-.25-.25q-.7-.7-1.55-.75-1.05.05-1.7.7-.7.7-.65 1.65 0 .9.7 1.6l6.25 6.25.1.1.75.75-8.9-3.9q-.15-.15-.35-.15-.85-.35-1.7-.1-.9.4-1.3 1.3t-.05 1.75q.35.85 1.25 1.25l10.55 4.55H76.4q-1 0-1.7.65-.65.65-.65 1.6 0 .95.65 1.7.7.6 1.7.6h9.85l-8.9 3.45q-.15.05-.25.1-.95.35-1.35 1.25-.4.8-.05 1.7.35.9 1.25 1.4.85.25 1.8-.1.05 0 .1-.05l10.35-4-7.75 7.7-.2.2q-.7.7-.75 1.65 0 .9.7 1.6.65.65 1.65.75.9-.1 1.6-.8l.1-.1 6.9-6.85-3.9 8.75q-.05.15-.1.2-.45.95-.05 1.85.25.85 1.15 1.25.9.4 1.9.1.75-.45 1.2-1.3.05-.05.05-.15l4.4-10.05V46.1q.05.15.05.25 0 1 .6 1.7.65.65 1.65.65.9 0 1.7-.6.55-.75.55-1.75V36.3l3.55 9.1q.05.15.1.25.35.95 1.25 1.35.8.4 1.7.05.9-.35 1.4-1.25.25-.85-.1-1.8 0-.05-.05-.1l-4-10.3 7.7 7.7.2.2q.7.7 1.65.75.9 0 1.6-.7.65-.65.75-1.65-.1-.9-.8-1.6l-.1-.1-6.85-6.85 8.75 3.85q.15.05.2.1.95.45 1.85.05.85-.25 1.25-1.15.4-.9.1-1.9-.45-.75-1.3-1.2-.05-.05-.15-.05l-9.9-4.35h11q1 0 1.65-.6.7-.75.7-1.7m74.5 71.3q-1.9-.65-3.85-1.25-7.35-2.25-14.7-4.1-80.1-20.7-160.2.1-7.6 1.9-15.15 4.25l-3.2 1-.2.25v20.9l.35.3q7.25-2.3 14.55-4.3 1.8-.5 3.65-.95 27.85-7.25 55.75-9.45 25.15-2.05 50.35.1 27.05 2.25 54.1 9.3 1.85.45 3.7.95 7.05 1.9 14.15 4.15l.6.2h.1q.1-.05.15-.1.05-.1.05-.2v-20.9l-.2-.25z" />
                    <path d="M.35 117.15l-.35-.3q0 .1.05.2l.3.1M0 95.95l.2-.25q-.1 0-.15.1-.05.05-.05.15m197.45-.15q-.05-.1-.15-.1l.2.25q0-.1-.05-.15z" />
                </g>
                <g id="AccHat007__Symbol_2_0_Layer0_0_FILL" fill={colors.col2s}>
                    <path d="M10.75 25.5L11 25l-.25-.1v.6m4.9 1.65v-.5h-.35q.1.15.35.5m.5-.35q0-.3-.1-.45l-.1.05.15.5q.017-.067.05-.1m3.45-3.7l-.1-.1v.05l.45.45q-.25-.3-.35-.4m-8.05 1.6h-.45v1.15l.3-.5q.15-.3.15-.45v-.2M6 24.5l.25-.25-.15-.15q-.05.15-.1.4m-2.35-4.4l.25-.1-.05-.15q-.05.1-.2.25M.8 16.15h.4V16q-.1.1-.4.15m1.25-4.95q-.3-.1-.65-.15l1.45.55v-.05l-.8-.35m21.55 9.5l.05.1.35.15q-.3-.2-.4-.25M2.8 6.95q.2.2.35.3l.05.05.15-.4-1.15-.5q.45.35.6.55m-.3-.9l.1.1.75.75.15-.35q-.1-.1-1-.5m4.9-1.6l.35-.1q-.1-.15-.65-.7l.3.8m-.2.45l.25-.3-.85-.85q.15.3.25.6.2.4.35.55m3.9-3.4l-.35-.95q-.05.15-.05.3-.05.65.05.8l.35-.15m.15-1.2q-.05-.2-.1-.3v.6q0 .1-.05.15v.75l.4-.15q-.1-.15-.25-1.05m4.75 2l-.25.65.3.15q0-.1-.05-.8m-.6.3q-.1.25-.15.45v.05h.4V1.65q-.15.6-.25.95m9.35 14.2q.15.05.55.05.3 0 1.35.25l-2.2-.95-.15.45.45.2m.35-.75v.1h.3l-.3-.1m.35-4.45l1.25-.45q-.15-.05-.3 0-.95 0-1.1.1l.15.35m.15 0v.4q.15-.1.5-.25.1-.1.2-.15h-.7m-2.15-4.1l-.65.3.15.35q.1-.15.4-.5.05-.1.1-.15m.35-.85q-.1.05-.3.2-.9.55-1.1.7v.05l.25.25 1.15-1.2m-3-4.05q-.4.45-.55.65-.2.15-.25.25-.05.05-.05.1l.35.15.5-1.15m-.35 1.05l.25.25q.05-.15.3-.85l-.55.6z" />
                    <path d="M24.7 21.85l-1.05-1.05-.05-.1-11.4-5q.15.1.2.2l-.1-.1q-.1-.1-.25-.1h-.15q-.05-.1-.2-.2h-.2l-.1-.1q-.05-.05-.15-.1l-.65-.3q-.05 0-.1-.05h-.1l-.85-.4H9.5l-1.9-.85q-.05 0-.1-.05l-4.65-2.1v.05H1.4q.1.1.1.15.1.15.2.2L6.3 14l-5.1 2v.15h.05l-1.25.5q1.35.2 1.5.1l5.85-2.3 1.35.6-4.85 4.8.05.15.3-.1-.95.95q.25-.05.6-.15.45-.15.55-.3l5.05-5 .35.15-3.7 8.55.15.15.2-.2-.55 1.15q.1-.05.35-.2.55-.45.6-.6l3.7-8.55.2.1v8.95l.25.1.1-.3h.45V17l3.75 9.65h.35v-.9l.3.65.1-.05L12.4 17l6.65 6.65q.15.15.9.6l-.45-1.2V23l-5.7-5.7 9.5 4.15q.15.1 1.4.4m-4-17.95l-.25-.25-.15.1-.35-.15-3.7 8.55-.2-.1V3.1l-.3-.15-.1.15h-.4V11L11.5 1.35l-.4.15-.35.15L14.4 11 7.75 4.35l-.35.1.05.15-.25.3 5.8 5.8-9.5-4.15-.15.35-.15.4 11.4 5q-.15-.1-.2-.2l.1.1q.05.05.15.1h.25q.05.1.2.2h.2l.1.1q.05.05.15.1l.65.3q.05 0 .1.05h.1l.85.4h.05l1.9.85q.05 0 .1.05l5 2.25.15-.45h.65v-.1L20.5 14l5.1-2v-.4h-.15l-.15-.35-5.85 2.3-1.35-.6 4.85-4.8-.15-.35-.15.05-.25-.25-5.05 5-.35-.15 3.7-8.55z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat007__Symbol_226_0_Layer0_0_FILL"
                transform="translate(97.25 53.8)"
                id="AccHat007__col1n"
            />
            <use
                xlinkHref="#AccHat007__Symbol_507_0_Layer0_0_FILL"
                transform="translate(96.75 53.8)"
                id="AccHat007__col1d"
            />
            <use
                xlinkHref="#AccHat007__Symbol_53_0_Layer0_0_FILL"
                transform="translate(93.85 32)"
                id="AccHat007__col2n"
            />
            <use
                xlinkHref="#AccHat007__Symbol_508_0_Layer0_0_FILL"
                transform="translate(93.85 122.35)"
                id="AccHat007__col2d"
            />
            <use
                xlinkHref="#AccHat007__Symbol_2_0_Layer0_0_FILL"
                transform="translate(178.85 42.55)"
                id="AccHat007__col2s"
            />
        </g></g>
    )
}

export default AccHat007
