import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#77BFFF",
    "col2s": "#1074CB"
}

function LegSki004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M140 4.15q-1.35-1.55-2.75-2.7-.75-.65-1.5-1.15-.05-.1-.1-.3l-.1.1v.05q-.05 0-.1.05H135.3l-.05.05h-.05l-3.2.6q-.7.1-1.35.25l-1.95.35q-.35.05-.65.1-.4.05-.8.15l-.75.1q-2 .35-4 .65l-.95.15-.6.1q-.05 0-.15.05l-5.9.8q-.3.05-.65.1-.9.1-1.75.25-.6.05-1.15.1l-1.85.25-5.6.6h-.4q-3 .3-6 .55l-1.5.1-2.9.2-1.6.1q-.5.05-.95.1-.65 0-1.25.05L88 6q-.25 0-.5.05h-.8l-1.2.1q-.95 0-1.85.05l-1.85.05-3.05.1h-2.1l-1.35.05h-3.8l-4.2-.05H65.75q-.4-.05-.75-.05h-.2q-3.4-.05-6.8-.2h-.05q-.25-.05-.5-.05-.6-.05-1.2-.05h-.4l-3.05-.2-3.3-.2h-.05Q47 5.4 44.5 5.2l-2.9-.3q-.35-.05-.65-.05-1.95-.2-3.95-.4l-3.3-.4-2.2-.3-1.5-.2q-.85-.1-1.75-.25-1.9-.25-3.75-.55l-2.5-.4Q19.7 2 17.4 1.6l-.4-.1-5.5-1q-.275-.069-.55-.15-.125.016-.25 0-.25-.1-.5-.1-.1.05-.15.15V.2q-.3.3-.6.65-.125.087-.25.15-2.612 1.88-4.95 4.6-1.05 1.2-2 2.6Q1.1 9.8 0 11.7q1.2.1 2.25.15 1.1.05 2 .15 1.5.1 2.35.15 2.45.2 4.9.35 1.6.1 3.25.2 1.1.05 2.25.15l5 .3q.6 0 1.2.05 2.5.15 5.05.3.9 0 1.75.05 1 .05 1.95.1l5.05.2q1.95.1 3.9.15 1.8.05 3.6.15l4.45.1q.25 0 .55.05 1.65 0 3.3.05l3.45.1H58l6.8.1h2.5q2.1 0 4.2.05h7.25q1.55-.05 3.05-.05 1.85 0 3.7-.05H88q1.3-.05 2.55-.05 3-.1 6-.15h.95l6-.2q.55-.05 1.1-.05 2.45-.1 4.9-.15 2-.1 4.05-.2h.7q.9-.05 1.75-.1l6.3-.3q.1-.05.2 0 2-.15 4-.25 2.1-.1 4.25-.25.6-.05 1.25-.05 1.6-.15 3.25-.25l2-.1q.8-.1 1.65-.15.45-.05 1.1-.05 1.85-.2 5.25-.55-2.5-4.45-5.25-7.5z"
                    id="LegSki004__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2 5.6Q.95 6.8 0 8.2v3.65q1.1.05 2 .15V5.6m13.15-4l-.4-.1-5.5-1v12q1.6.1 3.25.2 1.1.05 2.25.15.8.05 1.55.1V1.8q-.55-.1-1.15-.2m12.6 11.95v-10q-.85-.1-1.75-.25v10.2q.9 0 1.75.05m15.8-8.25l-1.3-.1-2.9-.3q-.35-.05-.65-.05-1.95-.2-3.95-.4v9.4q1.95.1 3.9.15 1.8.05 3.6.15.65 0 1.3.05V5.3m12.15.8q-.25-.05-.5-.05Q54.6 6 54 6v8.45h1.75V6.1h-.05m8.05.25h-.25q-.4-.05-.75-.05v8.25h2.3q2.1 0 4.2.05h1.3V6.4h-1.3l-4.2-.05H63.75m19.5-.2v8.35h2V6.05h-.8l-1.2.1M102.8 4.7q-.6.05-1.15.15h-.4q-3 .3-6 .55v8.9l6-.2q.55-.05 1.1-.05h.45V4.7m9.85-1.15q-.3.05-.65.1V13.7q.9-.05 1.75-.1V3.4l-1.1.15M125 1.7l-.75.1q-2 .35-4 .65V13.3q2-.15 4-.25.75-.05 1.45-.05V1.6q-.35 0-.7.1m12.75 2.45v8.05q1.85-.2 5.25-.55-2.5-4.45-5.25-7.5M133.3.1v.05q-.05 0-.1.05H133.05l-.05.05V12.5l2-.1V1.45Q134.25.8 133.5.3q-.05-.1-.1-.3l-.1.1z"
                    id="LegSki004__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M.4.75L0 .65V12q.8.05 1.55.1 1.7.1 3.45.2V1.5Q3.25 1.25 1.55.95 1 .85.4.75M31.95 13.4q.25 0 .55.05v-8.7h-.05q-1.8-.15-3.65-.3l-1.3-.1v8.95q.65 0 1.3.05 1.55 0 3.15.05M54.5 5.55v8.2H61V5.5h-1.35l-1.35.05h-3.8m33.55-1.7q-.6.05-1.15.15h-.4v9.25q.55-.05 1.1-.05h.45q2.25-.1 4.45-.15V3.4q-2.2.25-4.45.45M115 11.9V0q-.7.1-1.35.25L111.7.6q-.35.05-.65.1-.05 0-.1.05-.35 0-.7.1l-.75.1V12.2q.75-.05 1.45-.05l2.8-.2q.6-.05 1.25-.05z"
                    id="LegSki004__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki004__Symbol_70_0_Layer0_0_FILL"
                transform="translate(119.8 310.1)"
                id="LegSki004__col1n"
            />
            <use
                xlinkHref="#LegSki004__Symbol_19_0_Layer0_0_FILL"
                transform="translate(122.05 310.1)"
                id="LegSki004__col2n"
            />
            <use
                xlinkHref="#LegSki004__Symbol_5_0_Layer0_0_FILL"
                transform="translate(136.8 310.95)"
                id="LegSki004__col2s"
            />
        </g></g>
    )
}

export default LegSki004
