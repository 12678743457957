import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2d": "#5F00C2",
    "col2l": "#9C3AFF",
    "col2n": "#7E00FF"
}

function LegSki031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.55 0q-.1.05-.15.1h.15V0M133.2 1.3l-.9.2.9-.1v-.1m33.05 23q-.3-1.35-.6-2.55L114.9 4.05l-5.6.6q-1.05.05-2.1.2-.55 0-1.1.1-2.5.15-5 .35l-4.2.2q-.45.05-.95.05-1.7.05-3.45.15-1.2 0-2.35.05h-1.2q-2.45.05-4.9.05h-.3q-2.6 0-5.2-.05-1.05 0-2.05-.05-3.3-.1-6.55-.25l-1.9-.1q-2.55-.2-5.1-.35-.65-.1-1.25-.1-.25-.05-.45-.05-.3-.05-.55-.05-4.6-.4-9.25-.95L.8 21.25Q.3 22.8 0 24.5h8.65q3.85.25 8.65-1.9 5.45-1.15 10.9-1.85 5.2-.7 10.4-1 3.55-.2 7.05-.2 4.5 0 8.95.35 4.7.3 9.35 1 2 .3 4.05.7l.9.15q1.4.25 2.75.5 9.75 1.95 19.5.45 1.7-.4 3.5-.75l.5-.1 1.1-.2q.5-.1 1-.15 2.6-.5 5.25-.8 4.55-.6 9.15-.8 4.55-.25 9.15-.15 3.7.05 7.4.35 5.2.4 10.5 1.2 5.35.8 10.8 2.05l-.1-.1q.65.3 1.8.65 1.15.35 6.55.85l8.5-.45z"
                    id="LegSki031__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M17.3 17.7l.95-.9q.15-.05.35-.1l6.6-5.85Q16.35 9.6 0 19.6h8.65q3.85.25 8.65-1.9M36.4 6.95q-4.65 3.25-7.05 7.6l-1.15 1.3q5.2-.7 10.4-1l1.15-1.3h-.1q4-6.5 11.85-11.95-3.45-2.35-15.1 5.35M68.05.45Q65.5.25 62.95.1 62.3 0 61.7 0h-1.15L57 2.85Q49.85 7.5 46.8 13.4h.05l-1.2 1.25q4.5 0 8.95.35l1.25-1.25h.05Q58.3 7.7 65.6 3.5l1.1-.6h-.15l3.4-2.35-1.9-.1m22.1.4h-1.2Q86.5.9 84.05.9h-.3q-2.6 0-5.2-.05Q77.5.85 76.5.8l-5.85 6.4q-.1.05-.2.2-3.55 3.1-5.5 7.3.05 0-1 1.3 2 .3 4.05.7l.9.15q1.4.25 2.75.5 1.95-4.5 6.1-9.25l5.9-4.6L91 9.6q2.05 2 2.95 3.5 1.9 3.15 1.2 3.85l1.1-.2q.5-.1 1-.15 2.6-.5 5.25-.8-1.85-4.6-5.2-7.65L90.15.85m19.05 2L106.65.5q-2.7-.2-5.55-.1l-4.2.2 3.4 2.45h-.55q9.1 5.15 11.9 11.95 4.55-.25 9.15-.15l-1.45-1.5h.1q-3.25-5.6-10.25-10.5m19 12.35q5.2.4 10.5 1.2-2.95-5.1-8.85-9.5-8.8-6.55-14.6-5.15h-.3q1.3.8 2.5 1.7 6.95 5.1 10.75 11.75m12.85-4.4q5.8 3.45 8.35 7.55.65.3 1.8.65 1.15.35 6.55.85l8.1-.45q-14.15-8.7-24.8-8.6z"
                    id="LegSki031__Symbol_57_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M61.7 7.05Q61.45 7 61.25 7q-.3-.05-.55-.05-4.6-.4-9.25-.95l-2.2-.3q-.25-.05-.45-.05l-3-.4-.6-.1q-2.6-.4-5.15-.8-2.4-.4-4.7-.8-2.2-.4-4.4-.85-1.4-.25-2.75-.55-.8-.15-1.55-.3-.5-.1-.9-.2-1.05-.25-2.1-.45-.5-.15-1-.25h-.1q-.25-.1-.45-.1-1.7-.4-3.35-.8-.4.05-.75.1l-.45 1.55v.4h.05l-.05.05v.1h-.15Q6.1 9.05 1.9 20q-.4.95-.7 1.95V22q-.25.65-.4 1.4-.5 1.55-.8 3.25l51.5-18q4.5-1.35 9.05-1.6h1.15M165.05 22q-.45-1.05-.85-2.05-2.4-5.35-5.3-9.35-3.5-4.15-8.55-8.2L149.8.05q-.15 0-.3-.05-.25.05-.45.15-1.05.2-2.05.45-.85.15-1.65.35-.05 0-.05.05-.95.2-1.95.4l-.9.2q-1.5.3-2.95.65l-3 .6-1.5.3-1.8.3v.1l-.9.1q-.9.15-1.75.3-4.25.7-8.45 1.3-.2 0-.4.05-1.6.25-3.2.45-.15 0-.3.05l-3.3.4-5.6.6q-1.05.05-2.1.2-.55 0-1.1.1-2.5.15-5 .35 2.85-.1 5.55.1 4.5.3 8.6 1.25l50.6 17.65h.4q-.3-1.35-.6-2.55-.05-.15-.05-.2-.2-.7-.4-1.35-.05-.2-.15-.35z"
                    id="LegSki031__Symbol_30_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M48.45.55Q48.2.5 48 .5l-3-.4-.6-.1L1.1 14.85q-.4.95-.7 1.95v.05q-.25.65-.4 1.4L50.65.85l-2.2-.3m115.8 16.3q-.45-1.05-.85-2.05L121.3.1q-.2 0-.4.05-1.6.25-3.2.45-.15 0-.3.05l-3.3.4 50.75 17.7q-.05-.15-.05-.2-.2-.7-.4-1.35-.05-.2-.15-.35z"
                    id="LegSki031__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M60.7.95Q56.1.55 51.45 0L.8 17.4q-.5 1.55-.8 3.25l51.5-18q4.5-1.35 9.05-1.6h1.15Q61.45 1 61.25 1q-.3-.05-.55-.05m105.55 19.5q-.3-1.35-.6-2.55L114.9.2l-5.6.6q-1.05.05-2.1.2-.55 0-1.1.1-2.5.15-5 .35 2.85-.1 5.55.1 4.5.3 8.6 1.25l50.6 17.65h.4z"
                    id="LegSki031__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki031__Symbol_97_0_Layer0_0_FILL"
                transform="translate(108.45 309.25)"
                id="LegSki031__col1n"
            />
            <use
                xlinkHref="#LegSki031__Symbol_57_0_Layer0_0_FILL"
                transform="translate(108.45 314.15)"
                id="LegSki031__col1d"
            />
            <use
                xlinkHref="#LegSki031__Symbol_30_0_Layer0_0_FILL"
                transform="translate(108.45 307.1)"
                id="LegSki031__col2n"
            />
            <use
                xlinkHref="#LegSki031__Symbol_16_0_Layer0_0_FILL"
                transform="translate(109.25 312.25)"
                id="LegSki031__col2l"
            />
            <use
                xlinkHref="#LegSki031__Symbol_13_0_Layer0_0_FILL"
                transform="translate(108.45 313.1)"
                id="LegSki031__col2d"
            />
        </g></g>
    )
}

export default LegSki031
