import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou081({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M166.3 1.7q.3-.3.3-.7 0-.4-.3-.7-.3-.3-.7-.3h-18.95q-.4 0-.7.3-.3.3-.3.7 0 .4.3.7.3.3.7.3h10.45q-2.75 25.55-21.1 44.4-21.65 22.35-52.2 22.35-30.65 0-52.2-22.35Q13.95 28.25 10.7 4h9.25q.4 0 .7-.3.3-.3.3-.7 0-.4-.3-.7-.3-.3-.7-.3H1q-.4 0-.7.3-.3.3-.3.7 0 .4.3.7.3.3.7.3h7.65q3.3 25.1 21.5 43.8 22.2 22.95 53.65 22.95 31.35 0 53.65-22.95Q156.35 28.4 159.1 2h6.5q.4 0 .7-.3z"
                    id="FacMou081__Symbol_106_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou081__Symbol_106_0_Layer0_0_FILL"
                transform="translate(109.15 163.1)"
                id="FacMou081__col1n"
            />
        </g></g>
    )
}

export default FacMou081
