import { AssetSvgProps } from "../../../shared/assets"

function ShiMot055({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot055__Layer5_0_FILL"
                />
                <path
                    fill="#0000A4"
                    d="M159.2 271v10.05h16.1v12.75h9.8v-12.75h40.6V271h-40.6v-13h-9.8v13h-16.1z"
                    id="ShiMot055__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot055__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot055__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot055
