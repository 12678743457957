import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col2d": "#00006D",
    "col2n": "#0000BB"
}

function AccGla032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.5 1.25q0-.5-.35-.9-.4-.35-.9-.35T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4l.05-.05q.3-.35.3-.8z"
                    id="AccGla032__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.8}
                    d="M123.75 44.95l17.4-18.3-24.8-4.45L104.4 0 92.45 22.25 71 26.2l-22.25-4L36.8 0 24.85 22.25 0 26.8l17.5 18.15-3.35 25 22.75-11L59.55 69.9l-3.4-24.95 14.55-15.3v.4l14.4 14.9-3.35 25 22.75-11 22.65 10.95-3.4-24.95z"
                    id="AccGla032__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M128.9 48.35l16.55-17.4q.65-.7.7-1.65.05-.95-.55-1.65-.6-.75-1.5-.9l-23.6-4.2-11.4-21.2q-.45-.8-1.3-1.15-.9-.35-1.75 0-.9.35-1.35 1.15L93.3 22.6l-19.8 3.65-20.6-3.7-11.4-21.2Q41.05.55 40.2.2q-.9-.35-1.75 0-.9.35-1.35 1.15L25.7 22.6 2.05 26.9q-.9.15-1.5.9T0 29.5q.05.9.7 1.6l16.7 17.3-3.2 23.8q-.15.9.4 1.7.5.75 1.4 1 .9.25 1.75-.15l21.7-10.45L61 74.7q.85.4 1.75.15.85-.25 1.4-1 .5-.8.4-1.7L61.3 48.4l11.75-12.35L85 48.35 81.8 72.2q-.15.9.4 1.7.5.75 1.4 1 .9.25 1.75-.15l21.7-10.45 21.55 10.4q.85.4 1.75.15.85-.25 1.4-1 .5-.8.4-1.7l-3.25-23.8m-5-1.6q-.2.5-.1 1.1l2.75 20.35-18.45-8.95q-.55-.25-1.1-.25t-1.1.25l-18.55 9 2.75-20.4q.05-.6-.1-1.1-.2-.55-.6-.95L75.1 31l20.3-3.75q.55-.1 1.05-.4.45-.35.7-.85l9.75-18.15 9.75 18.1q.25.5.75.85.45.3 1 .4l20.25 3.65-14.2 14.95q-.4.4-.55.95m-74.85-20.8q.25.5.75.85.45.3 1 .4l20.25 3.65-14.2 14.95q-.4.4-.55.95-.2.5-.1 1.1l2.75 20.35-18.45-8.95q-.55-.25-1.1-.25t-1.1.25l-18.55 9 2.75-20.4q.05-.6-.1-1.1-.2-.6-.6-1L7.55 30.95l20.25-3.7q.55-.1 1.05-.4.45-.35.7-.85L39.3 7.85l9.75 18.1z"
                    id="AccGla032__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M26.45 4.5l.8-.15L2.95.05Q1.9-.15 1.1.45q-.85.6-1.05 1.6-.2 1 .4 1.9.6.8 1.65 1L25.5 9.1l-.4-.4q-.65-.7-.7-1.6-.05-.95.55-1.7.6-.75 1.5-.9M195 .7q-.85-.6-1.85-.45l-25.6 3.8q-.2 0-.4.05l1.35.25q.9.15 1.5.9.6.7.55 1.65-.05.95-.7 1.65l-.2.25 24.25-3.6q1-.15 1.6-1 .6-.85.5-1.85-.15-1.05-1-1.65z"
                    id="AccGla032__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla032__Symbol_120_0_Layer0_0_FILL"
                transform="translate(191.2 154.35)"
                id="AccGla032__col2n"
            />
            <use
                xlinkHref="#AccGla032__Symbol_71_0_Layer0_0_FILL"
                transform="translate(121.6 125.85)"
                id="AccGla032__col2d"
            />
            <use
                xlinkHref="#AccGla032__Symbol_54_0_Layer0_0_FILL"
                transform="translate(119.1 123.3)"
                id="AccGla032__col1n"
            />
            <use
                xlinkHref="#AccGla032__Symbol_22_0_Layer0_0_FILL"
                transform="translate(94.7 145.7)"
                id="AccGla032__col1d"
            />
        </g></g>
    )
}

export default AccGla032
