import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFC400",
    "col1l": "#FFEAAD",
    "col1n": "#FFD446"
}

function AccJew028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.25 6.25Q23.2 5 21.6 3.7q-.3-.2-.55-.4l-.5-.4q-1.35-.9-2.8-1.55-.1-.05-.15-.05-.2-.1-.35-.15h-.1q-.2-.1-.4-.15-1.4-.5-2.85-.75-.25-.05-.45-.05l-.1-.05h-.05Q13.05.1 12.85.1q-1-.1-2.05-.1-.55 0-1.1.05h-.4q-.25 0-.5.05Q7 .3 5.4.9q-.05 0-.1.05-.2.05-.4.15l-.3.1q-1.7.7-3.05 1.7-.25.4-.25.65-.05.05-.05.1-.05.2-.05.4-.05.05-.05.1V4.3Q.75 5.85.5 7.4q-.1.55-.15 1.1Q.1 10.1 0 11.6q.1-.05.2-.05.1-.1.2-.15.05-.1.1-.15 1.25-1.1 2.7-1.9l.3-.15q.2-.1.45-.2.05-.05.1-.05 1.6-.8 3.45-1.1.2-.05.45-.05.05-.05.15-.05.1 0 .3-.05.95-.1 2-.1.55 0 1.1.05h.5q.05 0 .1.05.25 0 .5.05 1.5.15 2.9.65.15.05.4.15l.1.05q.2.05.4.2h.1q1.4.65 2.75 1.6L23 13.5l1.4-7.15V6.1l-.05.25-.1-.1z"
                    id="AccJew028__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M23.1 6.25Q22.05 5 20.45 3.7q-.3-.2-.55-.4l-.5-.4q-1.35-.9-2.8-1.55-.1-.05-.15-.05-.2-.1-.35-.15H16q-.2-.1-.4-.15-1.4-.5-2.85-.75Q12.5.2 12.3.2l-.1-.05h-.05Q11.9.1 11.7.1q-1-.1-2.05-.1-.55 0-1.1.05h-.4q-.25 0-.5.05-1.8.2-3.4.8-.05 0-.1.05-.2.05-.4.15l-.3.1Q1.75 1.9.4 2.9q-.25.4-.25.65-.05.05-.05.1-.05.2-.05.4-.05.05-.05.1v.1q.35-.3.75-.55 1.2-.85 2.5-1.4.15-.1.3-.15.2-.1.45-.15 0-.05.1-.05 1.6-.6 3.4-.8.2-.05.5-.05.05-.05.15-.05h.2Q9 1 9.65 1q.95 0 1.85.1.25 0 .45.05H12.1l.5.1q1.45.2 2.85.65.2.05.4.15.05 0 .1.05.15.05.35.1.05.05.1.05 0 .05.05.05 3.7 1.7 6.65 5.1l.15-1.05V6.1l-.05.25-.1-.1z"
                    id="AccJew028__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M8.6 1.05Q8.4.95 8.2.9 6.8.45 5.35.25l-.5-.1H4.7Q4.5.1 4.25.1 3.35 0 2.4 0q-.65 0-1.3.05L0 6.7q.95-.1 2-.1.55 0 1.1.05h.5q.05 0 .1.05.25 0 .5.05 1.5.15 2.9.65.15.05.4.15l.1.05q.2.05.4.2l1.05-6.6q-.2-.05-.35-.1-.05-.05-.1-.05z"
                    id="AccJew028__Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew028__Symbol_97_0_Layer0_0_FILL"
                transform="translate(110.1 272.95)"
                id="AccJew028__col1n"
            />
            <use
                xlinkHref="#AccJew028__Symbol_66_0_Layer0_0_FILL"
                transform="translate(111.25 272.95)"
                id="AccJew028__col1d"
            />
            <use
                xlinkHref="#AccJew028__Symbol_54_0_Layer0_0_FILL"
                transform="translate(118.5 273.95)"
                id="AccJew028__col1l"
            />
        </g></g>
    )
}

export default AccJew028
