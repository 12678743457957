import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00AFAF",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function ShiLon073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M51.95 2.25l-.4.8q-1.7 3.4-4.4 6.35-7.05 7.6-17 7.6T13.2 9.4q-3.05-3.25-4.75-7.15Q4.15 1.3 0 0l13.15 72.25q7.25.4 14.5.5.4 0 .85.05.3-.1.6-.05.25-.05.55 0h.3q7.15 0 14.35-.35h.35l2-.15L59.75.15q-.2.05-.35.15-3.7 1.1-7.45 1.95m-2 10.65l.2-.1-.15.1h-.05z"
                    id="ShiLon073__Symbol_513_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M62.65 37.95L49.5 16.4q-6.9-2.25-13.35-5.55-6.8-3.75-10.35-6.2L24.25 3.5q-1.45-1.05-2.8-2.1L19.6 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-1.7 3.65-3.2 7.6-.45-.3-.95 1.55Q3.6 29.6 3.1 31l-.85 2.45q-.25.8-.5 1.55-.2.55-.35 1.15Q.9 37.65.55 39q-.1.2-.15.4-.25.75-.4 1.5l20.75 8.75h.05L16.2 69.2q-.55 2.1-1.05 4.2l-.9 3.6-.9 3.5 49.3 14.95v-57.5m14.5-8.6H77l.15.05v-.05m22.3-.05h.05l.15-.1-.2.1m47.7-17.8q-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-11.35 7.85-24.25 11.9l-13.1 21.2v57.5L145.9 80.6l-5.15-20.7h-.05l-2.4-11.85 20.65-8.25h.1q.15-.1.25-.2-.1-.3-.15-.6-.1-.35-.2-.65-1.1-3.6-2.3-7.1l-.2-.6q-.1-.35-.2-.65-.25-.65-.45-1.25l-.25-.65q-.65-1.4-1.15-.9l-1.4-3.6q-1-2.3-2-4.5-1.8-3.95-3.85-7.6z"
                    id="ShiLon073__Symbol_420_0_Layer0_0_FILL"
                />
                <path
                    d="M238.9 237.85q-.537.346-1.1.65l6.8 11.35h-17.9L237.35 261l-28.4 28.4v2.1l29.9-30.55-9.75-10.1h17.45l-7.65-13m-91.7.65q-.563-.304-1.15-.65l-7.6 13h17.45l-9.75 10.1 29.6 30.2v-1.6L147.65 261l10.65-11.15h-17.9l6.8-11.35z"
                    id="ShiLon073__Layer9_0_FILL"
                />
                <g
                    id="ShiLon073__Symbol_378_0_Layer0_0_FILL"
                    fill={colors.col2s}
                >
                    <path d="M24.65 8.25Q24.5 8 24.3 7.9l-.45-.25-.05-.05L5.1.05q-.45-.3-.95 1.55Q3.6 2.95 3.1 4.35L2.25 6.8q-.25.8-.5 1.55-.2.55-.35 1.15-.5 1.5-.85 2.85-.1.2-.15.4-.25.75-.4 1.5L20.75 23h.05l.3.1q.05.05.15.05h.3q.2-.05.4-.2.15-.2.2-.4L24.7 8.7q.05-.25-.05-.45m-.95.5L21 21.9q-9.95-4.5-19.85-8.2l4.1-12.4L23.7 8.75m23.2 18.9q.15-.15.15-.35 0-.2-.15-.35l-7.1-7.1q-.15-.15-.35-.15-.2 0-.35.15L23.95 35q-.15.15-.15.35 0 .2.15.35l7.1 7.1q.15.15.35.15.2 0 .35-.15L46.9 27.65m-15.5 14.1l-6.4-6.4L39.45 20.9l6.4 6.4L31.4 41.75m96.55 1.15q.2 0 .35-.15l7.1-7.1q.15-.15.15-.35 0-.2-.15-.35L120.25 19.8q-.15-.15-.35-.15-.2 0-.35.15l-7.1 7.1q-.15.15-.15.35 0 .2.15.35l15.15 15.15q.15.15.35.15m6.4-7.6l-6.4 6.4-14.45-14.45 6.4-6.4 14.45 14.45M156.45 4q-.1-.35-.2-.65-.25-.65-.45-1.25l-.25-.65q-.65-1.4-1.15-.9l-19.45 7.5q-.25.1-.7.5-.15.15.7 3.8 0 .15.05.3.65 2.85 1.15 4.9.15.75.3 1.45.6 2.4.65 2.45.15.15.35.2.1 0 .7-.2l20.8-8.3h.1q.15-.1.25-.2-.1-.3-.15-.6-.1-.35-.2-.65-1.1-3.6-2.3-7.1l-.2-.6m-1.85-2.45q1.75 5.3 3.5 10.8v.1l-20.05 7.9H138q-.3-1.15-.55-2.25-.05-.3-.1-.55-.7-2.6-1.35-5.2-.45-1.65-.9-3.25l19.5-7.55zM77 2.7l.15.05V2.7H77zM99.65 2.55l-.2.1h.05l.15-.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon073__Symbol_513_0_Layer0_0_FILL"
                transform="translate(162.35 245)"
                id="ShiLon073__col1n"
            />
            <use
                xlinkHref="#ShiLon073__Symbol_420_0_Layer0_0_FILL"
                transform="translate(112.85 228.6)"
                id="ShiLon073__col2n"
            />
            <use
                xlinkHref="#ShiLon073__Symbol_378_0_Layer0_0_FILL"
                transform="translate(112.85 255.25)"
                id="ShiLon073__col2s"
            />
            <use xlinkHref="#ShiLon073__Layer9_0_FILL" />
        </g></g>
    )
}

export default ShiLon073
