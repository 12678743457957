import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#A37831"
}

function ObjMus039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#313131"
                    d="M230.9 241.65l-.3.6q-6.1 12.35-23 25.25 15.867-.218 22.75-3.6 21.15-19.45 22.05-35.4-10.9 8.95-21.5 13.15m-13.15 24.15h.2l-.2.05v-.05z"
                    id="ObjMus039__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M89.75 8.4Q94.2 4.5 86 .65 79.4-2.2 62.75 7.3q-14.6 7.25-24.1-1.65-12.05-9.25-23.5-1.1-6.9 4.4-11.3 10.55-2.55 9.95 5.1 25h.45q.2 0 .45.05h.05l.5.1q2.15.45 3.8 2.1 2.25 2.25 2.25 5.45t-2.25 5.45q-.55.55-1.15 1-.2.05-.35.2v.05l-.05.05q-.25.1-.6.25l-.3.1q-.05.3-.15.65-.8 1.05-.85 2.45-1.1 2.35-3.1 4.4-1.35 1.35-2.9 2.3l-.1.1-.1.05-.8.4q-.4.2-.75.35l-.2.1-.2.1h-.05l-.1.05q-1.2.45-2.45.7.9 3.15 2 6.35.55 1.6 1.15 3.3Q8 89.5 23.3 92.85q17.2 1.95 25.2-8.55 8-10.55 24-8.4 15 4.15 20.75-4.75 2.65-4-2.15-5.8-10.2-1.5-12.05-7.35-2.25-7.15 6.75-15.3v-.3h.05q2.9-9.75-1.6-18.65-4.9-8.65 5.5-15.35z"
                    id="ObjMus039__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M141.25 25.15L144.5 5.5 109.95 0l-5.2 5.6-.1.05q-1.75 2.75-5.1.8l-.8.1q-3.4 3.6-7.65 1.2l-.75.1q-2.45 3.9-6.6 1.05l-.8.1q-3.8 4.1-7.6 1.2l-3.7.55q-1.4 2.6-4.3.7l-.05-.05-39.55 6.15q-2.05 4.75-4.15.65l-.8.1q-1 6.1-3.9.6l-.8.15q-1.8 5.25-3.65.55l-.8.15-2.05 2.9q-.85-2.45-1.2-2.45h-.2v.1l-2.8 3.2-.4-2.7-6.95 1.1H0l1.6 18.65L9.95 39H10l100.7-18.25 4.85 4.4h25.7z"
                    id="ObjMus039__Symbol_49_0_Layer0_0_FILL"
                />
                <path
                    fill="#BFBFBF"
                    d="M168.9 287.4l.55 3.1 19.45-3.05-.5-3.1-19.5 3.05z"
                    id="ObjMus039__Layer4_1_FILL"
                />
                <path
                    fill="#919191"
                    d="M154.55 304.7q.7.9 1.75 2.05.85 1 1.95 2.15 1.2 1.25 2.65 2.75 4.3 4.65 4.45 4.8 3.7 3.2 7.55 3.3h.35q1-.05 1-.95v-.1q-.05-.15-.5-.3-.5-.2-3.55-1.45t-4.65-3.15q-1.65-1.95-3.55-4.05-1.95-2.1-3.75-4.05-.05-.1-.1-.15-.95-1.05-1.65-1.85l-.05-.05q-.85-.95-1.4-1.55v-2.15h-6.6v4.75h6.1z"
                    id="ObjMus039__Layer4_2_FILL"
                />
                <g id="ObjMus039__Layer4_0_FILL">
                    <path d="M209.65 267.15l-.8.15 2.6 17.35q0 .14.25 1l.35-.05q.059-.444.1-.65.1-.25.1-.4l-2.6-17.4m2.7-.4l-.8.1 2.75 18.35.75-.15-2.7-18.3m3.65 3.85l-.65-4.35-.8.15 2.65 17.9q0 .15.15.25.063.031.15.05l.25-.05q.086-.036.15-.1.1-.15.1-.3l-2-13.55m2.55-4.85q-.25.05-.6.05h-.2v.1l.65 4.3 2 13.6q0 .143.1.25h.05l.55-.1q.1-.1.1-.25l-2.05-13.65-.6-4.3m3.45-.55l-.8.15.65 4.25 2 13.7v.1l.75-.1q.05-.073.05-.15l-2.05-13.65-.6-4.3m14.05-2.15l-.75.1.6 4.1 2 13.6.8-.15-2.65-17.65m-9.6 1.45l-.8.15.6 4.2 2.05 13.75.8-.15-2.05-13.7-.6-4.25m3.9-.6l.6 4.15 2 13.7.8-.15-2-13.65-.6-4.15-.8.1m90.55-16.15q-1.9 0-1.9 1.85 0 .3.1.55.05.25.15.45.45.9 1.65.9 1.85 0 1.85-1.9-.05-1.85-1.85-1.85m-77.85 32.15l.8-.15-2.6-17.5-.8.1 2.6 17.55m3.65-18.5l-.8.1 2.6 17.4.75-.1-2.55-17.4m6.4-1l-.8.1 2.6 17.25.75-.1-2.55-17.25m6.9-1.1l-.75.15 2.5 17.1.8-.15-2.55-17.1m6.6-.95h.05v-.1l-.5.1v.05l-.35.05 2.5 16.9.8-.15-2.5-16.85m23.9-3.75l.45 3.3 1.95 13 .8-.15-1.95-12.95-.45-3.3-.8.1m7.4-1.15l.45 3.25 1.95 12.9.75-.15-1.9-12.85-.5-3.25-.75.1m8.4-1.3l.45 3.2 1.9 12.75.75-.15-1.85-12.75-.45-3.15-.8.1m-22.9 7l-.5-3.35-.8.1.5 3.35 1.95 13.2.75-.15-1.9-13.15m-8.55-2.15l-.8.15-.1.05.05.1 2.45 16.55.75-.15-1.95-13.25-.5-3.3v-.1l.1-.05m51.05-7.9q-.8.45-.8 1.7 0 .55.25 1.05.45.9 1.65.9 1.85 0 1.85-1.95-.05-1.95-1.85-1.95-.25 0-.45.05-.4.05-.65.2m5.6 2.85q0 .05.25 1 .45.9 1.65.9 1.85 0 1.85-1.85-.05-1.9-1.85-1.9-1.9 0-1.9 1.85m8.75-.85q-1.9 0-1.9 1.85 0 .05.25 1 .45.9 1.65.9 1.85 0 1.85-1.85-.05-1.9-1.85-1.9m6.65 1.4q-1.9 0-1.9 1.9 0 .55.25 1.05.45.9 1.65.9 1.85 0 1.85-1.95-.05-1.9-1.85-1.9z" />
                    <path
                        fill="#F60"
                        d="M215.35 266.25l.65 4.35 2.4-.4-.65-4.3-2.4.35m59.5-9.25l-.1.05v.1l.5 3.3.1-.05 7.25-1.15-.5-3.35-2.9.45-4.35.65z"
                    />
                    <path
                        fill="#03F"
                        d="M221.2 265.35l-2.65.4.6 4.3 2.7-.45-.65-4.25m61.7-9.55l.5 3.35 7.55-1.25-.45-3.3-7.6 1.2z"
                    />
                    <path
                        fill="#FF0"
                        d="M226.25 268.85l-.6-4.2-3.65.55.6 4.3 3.65-.65m72.1-12.15l-.45-3.25-6.6 1.05.45 3.3 6.6-1.1z"
                    />
                    <path
                        fill="#03C102"
                        d="M231.15 263.8l.6 4.15 4.15-.7-.6-4.1-4.15.65m75.95-11.75l.45 3.15 5.15-.8-.5-3.15-5.1.8z"
                    />
                    <path
                        fill="red"
                        d="M230.95 268.05l-.6-4.15-3.9.6.6 4.25 3.9-.7m75.8-12.7l-.45-3.2-7.65 1.2.5 3.25 7.6-1.25z"
                    />
                    <path
                        fill="#A17937"
                        d="M274.85 257l4.35-.65v-.05l-4.35.7z"
                    />
                    <path
                        fill="#FFF"
                        d="M131.9 319.1q1.35 1.35 3.25 1.35t3.25-1.35q1.35-1.35 1.35-3.25t-1.35-3.25q-1.35-1.35-3.25-1.35t-3.25 1.35q-1.35 1.35-1.35 3.25t1.35 3.25m11.35-17.15q0-1.9-1.35-3.25t-3.25-1.35q-1.3 0-2.3.65-.2.05-.35.2v.05h-.05q-.269.169-.55.45-.25.25-.5.6-.8 1.05-.85 2.45v.2q0 1.9 1.35 3.25t3.25 1.35q1.9 0 3.25-1.35t1.35-3.25m10.35-14.65q0 5.2 1.6 9.35 1 2.65 1.25 6.65 0 .15.05.35h-.05l.05.05q-.109 1.342-.2 3.05 0 .25.05.55-.15 2.1-.15 4.35v2q.05 3.35-1.45 7.15-.85 2.05-2.5 4.85-1.15 2.3-1.15 4.35 0 .15.3 1.25.35 1.35 3.6 1.35 3.05 0 5.45-.95 2.35-1 4.9-2.8 2.5-1.85 4.35-4 1.8-2.15 3.7-4.05 1.85-1.95 3.05-2.6 1.2-.65 1.7-.85 4.4-1.8 9.7-1.8 4.5 0 9.1 1.2t9.15 1q4.5-.2 6.15-1.8 1.6-1.65 1.6-2.65 0-.9-.6-1-.55-.5-2.75-1.2-2.25-.7-4.85-1.8-2.65-1.15-4.55-3.8-1.9-2.7-1.7-7.35.2-4.65 2.1-7.45 1.85-2.8 1.2-1.6 2.9-4.55 3.05-7.7.2-4.4-.15-6.5-.25-1.45-.5-2.45-.3-1-.6-1.65-1.25-3.35-6.75-1-5.55 2.3-16.35 3.2-10.75.85-17.2.6-6.45-.25-9.55 6-1.05 2.05-1.05 7.7m13.6 5.65q-6.9-3.8-1.55-10.4l24.65-3.65q5.75 3.6 1.9 10.1l-25 3.95m1.15-8.65l.55 3.1q10.924 2.68 19.5-3.05l-.5-3.05-19.55 3z"
                    />
                    <path fill="#FFFAEA" d="M125.9 309.55l.2-.1-.25.1h.05z" />
                </g>
            </defs>
            <use xlinkHref="#ObjMus039__Layer13_0_FILL" />
            <use
                xlinkHref="#ObjMus039__Symbol_123_0_Layer0_0_FILL"
                transform="translate(123.3 243.75)"
                id="ObjMus039__col1n"
            />
            <use
                xlinkHref="#ObjMus039__Symbol_49_0_Layer0_0_FILL"
                transform="translate(207.55 245.6)"
                id="ObjMus039__col2n"
            />
            <use xlinkHref="#ObjMus039__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus039__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus039__Layer4_2_FILL" />
        </g></g>
    )
}

export default ObjMus039
