import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2n": "#00DE00",
    "col2s": "#0D6B0D"
}

function ObjToo051({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M12.65.4q-1-.6-2.3-.25-1.15.25-2.5 1.2-2.1 1.5-3.9 4.05l.25.15Q3.05 7.1 1.3 10.1.25 12.6.05 14.9q-.25 2.35.5 3.65.35.6 1 1 1.85 1.05 4.8-1 1.8-1.3 3.35-3.3 1-1.25 1.85-2.7.75-1.3 1.25-2.55 1.1-2.6 1.35-5 .35-3.55-1.5-4.6m14.1 6.45q-1.85-.2-4.35 1.5Q20 10 18.05 12.7q-1.8 3.55-2.8 5.95l-.45-.3q-1.25 2.95-1.45 5.7-.3 3.8 1.5 5.2.45.75 1.2 1.2 2.25 1.3 5.8-1.2 2.2-1.55 4.1-4.05h.1q1.1-1.5 2.15-3.25.9-1.6 1.5-3.1 1.35-3.15 1.65-6.05.4-4.3-1.85-5.6-1.2-.7-2.75-.35m78.1 32.65q-8.8-7.55-19.55-11.05-1.2-.4-2.4-.7-4-3.9-9.7-7.05-5.8-3.2-13.35-5.65-8.7-2.1-19.1-1.15h-.1q-.5 0-.9.05-1.6.2-3.6 1.45-1.35.9-2.6 2.1-.9.85-2.05 2.15v.05l-.05.05q-2.4 4.15-3.5 6.65l-.15-.1q-.55 1.35-.85 2.6-.45 1.7-.5 3.35-.05 1.4.1 2.55.4 1.85 1.5 2.9 2.7 5.95 6.15 10.75 2.05 2.8 4.3 5.2 2.1 1.95 4.15 3.65 2.55 2.1 5 3.7.35 1.35 1.05 3.15 1.85-.3 4-.05 2.85 1.5 6.25 2.6.6.55 1.15 1.2 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1H58q-3.55 1.35-6.45.45h-.15q-.25-.1-.5-.25-2.15-1.05-4-2.9-.4-.4-.8-.85-.1.35-.1.7 0 1.25 1.2 2.55l3.35 3.25q.1.15.25.25.9.85 6.15 3.9.05 0 .1.05l.2.1q11.95 6.05 13.25.9.1-.4.85-4.25 1.2-6.05 3.8-11 .45-.8.95-1.65 1.3-2.25 2.45-3.8 1.65-2.25 2.8-2.95 1.8-1.1 4-1.6.65-.15 1.3-.25 1.35-.15 2.6-.35.05.05.15 0 1-.15 1.95-.35 7-1.35 9.8-4.4 3.05-3.35-.8-12.15.4-1.1 1.05-2 2.1-3.25 7-4.45 1.15-.45.5-1.8-.75-1.55-4.05-4.25m-70.8-9.75q.75-.3 1.4-.6-.15.1-.35.2-.55.35-1.05.4z"
                    id="ObjToo051__Symbol_210_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M50.5 86.45q-1.95-1.05-3.6-2.7-.4-.4-.8-.85-.1.35-.1.7 0 1.25 1.2 2.55l3.35 3.25q-1-1.1-.05-2.95m-39.9-74.4q.8-1.35 1.35-2.65.95-2.35 1.1-4.6.25-3.4-1.6-4.45-.5-.3-1.1-.35-1.15.25-2.5 1.2-2.1 1.5-3.9 4.05l.25.15Q3.05 6.95 1.3 9.95q-1.05 2.5-1.25 4.8-.25 2.35.5 3.65.2.15.35.25 1.85 1.05 4.65-.9 1.85-1.2 3.4-3.15.85-1.2 1.65-2.55m17.5-4.9q-.65-.35-1.35-.45-1.85-.2-4.35 1.5-2.4 1.65-4.35 4.35-1.8 3.55-2.8 5.95l-.45-.3q-1.25 2.95-1.45 5.7-.3 3.8 1.5 5.2.2.15.4.25 2.25 1.3 5.7-1.05 2.2-1.45 4-3.8.05-.05.1-.05 1.05-1.4 2.05-3.05.9-1.6 1.55-3.25h.05q1.15-2.85 1.3-5.55.35-4.15-1.9-5.45M43.6 26.3q1.2-3.1 1.35-6 .15-4.5-2.4-6-.75-.4-1.5-.55h-.4q-.5 0-.9.05-1.6.2-3.6 1.45-1.35.9-2.6 2.1-.9.85-2.05 2.15v.05l-.05.05q-2.4 4.15-3.5 6.65l-.15-.1q-.55 1.35-.85 2.6-.45 1.7-.5 3.35-.05 1.4.1 2.55.4 1.85 1.5 2.9.15.15.3.25.2.15.45.3 2.55 1.5 6.45-.95.75-.5 1.45-1.1 1.55-1.2 2.95-2.95v-.05q1.2-1.5 2.25-3.25 1-1.75 1.65-3.45 0-.05.05-.05m-9.55 3.3q.75-.3 1.4-.6-.15.1-.35.2-.55.35-1.05.4m47.3 41.35q1.8-1.1 4-1.6-2.25.25-4.8.1-6.45-.25-11-.75-3.35-.3-5.6-.8-.4-.05-.75-.15-2.05-.4-4.1-1.05.5.5 1 1.05 0 .05.05.1.15.15.3.35.9 1.4 1.45 2.9.55-.8 1.15-1.65 4.95 1.1 10.3 1.45 3.95.25 8 .05m14.55-27.3q1.2 1.7 2.1 3.4l.65 1.3q.95 1.85 1.7 3.5.4-1.1 1.05-2-4.5-9.35-12.15-17.6Q101.7 35.6 108.9 43.6q-.75-1.55-4.05-4.25-8.8-7.55-19.55-11.05-1.2-.4-2.4-.7l1.4 1.4q1.55 1.55 3.45 3.85Q91.2 37 95.9 43.65z"
                    id="ObjToo051__Symbol_134_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M116.75 289.65q0-.1.1-.25.4-1.1.8-1.85-3.65-1.65-7.4-4.25.35 1.35 1.05 3.2.15.35.25.75 1.7 4.55 2.9 5.25.55.3.95-.1 0-.05.45-.8.35-.6.9-1.95z"
                    id="ObjToo051__Symbol_134_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M12.55 5.55q0-1-.55-1.3L3.9 0v.1L3.8.05H1.75Q.95.1.45.7 0 1.15 0 1.75q0 .5.35 1.5Q.8 4.5 1.4 4.5l7.95 4.85q.15 0 .35.05.35-.05.8-.3.8-.55 1.4-1.6.6-1 .65-1.95m11.9 13q.6.3 1.45-.3.25-.2.55-.35.5-.65.95-1.25l.2-.3q.7-1.2.8-2.35.05-1.05-.5-1.45L16.5 6.6q-.5 1.25-1.25 2.55-.85 1.45-1.85 2.7l11.05 6.7M40.8 23.9q.8-1.45.8-2.8 0-1.35-.9-1.85l-.5-.2-6.8-3.6q-.6 1.5-1.5 3.1-1.05 1.75-2.15 3.25l7.2 4.45q.8.3 1.85-.3 1.2-.65 2-2.05z"
                    id="ObjToo051__Symbol_87_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M11.1 11.55l.4-.4q1.05-1.1 1.55-2.8.05-.2.05-.3V8l.1-.5q.35-2.2-.85-4.2Q11 .9 8.35.25 5.7-.5 3.3.85.9 2.25.25 4.95-.5 7.55.9 9.9q1.35 2.4 4 3.15 2.65.65 5.05-.7.3-.2.65-.4.25-.25.5-.4z"
                    id="ObjToo051__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M152.95 281.1q.65-1.15.3-2.45-.35-1.25-1.5-1.9-1.15-.7-2.4-.35-1.35.35-2 1.5-.65 1.2-.3 2.45.35 1.25 1.5 1.95 1.15.65 2.4.3 1.35-.3 2-1.5m-14.65-8.45q-.65 1.2-.35 2.45.35 1.3 1.55 1.95 1.15.65 2.4.35 1.3-.35 1.95-1.55.65-1.15.35-2.4-.35-1.3-1.5-1.95-1.2-.65-2.45-.35-1.3.35-1.95 1.5z"
                    id="ObjToo051__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo051__Symbol_210_0_Layer0_0_FILL"
                transform="translate(62.6 222.3)"
                id="ObjToo051__col1n"
            />
            <g id="ObjToo051__col1d">
                <use
                    xlinkHref="#ObjToo051__Symbol_134_0_Layer0_0_FILL"
                    transform="translate(62.6 222.45)"
                />
                <use xlinkHref="#ObjToo051__Symbol_134_0_Layer0_1_FILL" />
            </g>
            <use
                xlinkHref="#ObjToo051__Symbol_87_0_Layer0_0_FILL"
                transform="translate(58.9 225.7)"
                id="ObjToo051__col2n"
            />
            <use
                xlinkHref="#ObjToo051__Symbol_4_0_Layer0_0_FILL"
                transform="translate(49.6 218.2)"
                id="ObjToo051__col2s"
            />
            <use xlinkHref="#ObjToo051__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo051
