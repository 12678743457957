import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000",
    "col2n": "#000000"
}

function AccMas040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M193.1 114.55q.25-4.6.1-9.25-1.85-36.6-28.2-62.95-10.55-10.55-22.65-17.15-2.45-1.3-4.9-2.45 13.15-.5 13.15-4.65 0-3.1-5.1-4.75-7.6-1.8-12.7-3.55-9.65-3.5-15.95-5.7Q105.1 0 102.8 0 95.15 0 89 3.15 82.35 7.4 78.8 9.6q-4.75 2.95-13.25 7.6-10.2 5.6-11.9 6.65-13.7 6.85-25.35 18.5Q.95 69.7.05 108 0 109.35 0 110.7q0 1.65.05 3.25.05.95.15 2 .7 16.35 7.2 31.45 7 17.45 21.45 31.9 28.2 28.2 68.05 28.2 39.85 0 68.05-28.2 27.05-27.05 28.15-64.75M124 91.35h.05q11.9.9 20.9 10.6 9.05 9.65 9.9 22.45.9 12.75-6.95 21.1-5.35 5.75-12.65 7.05-3.3.6-6.9.35-1.45-.1-2.75-.35-10.3-1.7-17.25-8.9-7-7.25-8.75-12.35-1.8-5.15-2.25-8.55h-.75q-.65 3.8-1.2 5.5-.6 1.7-1.3 3.25-.7 1.45-1.35 2.6-.7 1.15-2.1 3.25-1.45 2.1-4.2 5.05-7.95 8.5-18.15 10.2-1.4.25-2.75.35h-.05q-3.65.25-6.95-.35-7.3-1.3-12.6-7.05-7.9-8.35-7-21.1.85-12.8 9.95-22.45 8.95-9.7 20.9-10.6 11.8-.9 19.6 7.4 2.15 2.3 3.65 5 .9 1.6 1.55 3.3.35 1 .85 2.6.4 1.4.85 3.3.7 3.35 1.25 0l.15-.45q1.8-8.45 6.75-13.8 7.75-8.3 19.55-7.4z"
                    id="AccMas040__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M21.9 16.65q2.05 0 3.25.1 1.05.1 5.95-.9.05-.05.15-.05.35-.05.75-.15h2.55q-2.45-1.3-4.9-2.45-.9.05-1.85.05H25q-7.15-.2-12.7-3.45-.85-.5-1.6-.95-.2-.1-.35-.2Q9.15 7.9 8.1 7.2h-.05Q6.2 5.95 5 4.95 3.75 4 .05 0q-.3 1.55 2.6 5.4.4.5.85 1.05Q4 7 4.55 7.6q0 .05.1.1 1.1 1.15 2.45 2.35 7.6 6.6 14.8 6.6z"
                    id="AccMas040__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M112.7 84.25q4.6-2.45 8.25-6.75 5.55-6.6 8.9-17.55 1.25-4.1 1.75-8.05 1.65-13.85-5.95-26.65-9.8-16.55-23.9-23.2Q87.7-4.6 78.4 10.15 71.95 20.4 68.45 29.3q-1.4 3.9-2.15 7.55-.3 1.25-.5 2.65-.25-1.45-.55-2.75-.75-3.65-2.15-7.55-3.2-8.85-9.6-19.05-9.3-14.75-23.35-8.1-14.1 6.65-23.9 23.2-9.8 16.5-4.2 34.7Q7.6 78.1 19.2 84.25q11.55 6.15 22.55.4Q52.7 78.85 58.6 68.2q2.5-4.6 4.15-8.75 2.2-5.55 2.9-10.4.05-.4.15-.8l.1.9q.7 4.85 2.9 10.4 2.05 4.1 4.5 8.65 5.9 10.65 16.85 16.45 11 5.75 22.55-.4M90.45 19.6q10.85.8 19.05 9.65 8.25 8.8 9 20.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-3.75-4-5.95-8.55l-1.2-2.85q-.15-.4-.25-.75-.45-1.4-1.1-4l-.3-1.7-.45-1.55v.15q-.05-.15-.05-.25v-.05l-.1-.3q-.05-.2-.1-.35l-.05-.15q-.15.55-.25 1.05-1.15 4.9-1.85 7.05-1 2.95-2.5 5.65-2 3.5-4.95 6.65-7.25 7.75-16.55 9.3-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-6.65-1.2-11.5-6.45-7.2-7.6-6.35-19.2Q13.7 38.1 22 29.3q8.15-8.85 19.05-9.65Q51.8 18.8 58.9 26.4q1.25 1.35 2.25 2.8 1.5 2.2 2.5 4.75.75 2 2.05 7.65l.35-.85q1.1-4.7 1.85-6.7 1-2.55 2.5-4.95l-.1.2q1.05-1.6 2.3-2.95 7.05-7.6 17.8-6.75h.05z"
                    id="AccMas040__Symbol_38_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas040__Symbol_120_0_Layer0_0_FILL"
                transform="translate(95.6 42.4)"
                id="AccMas040__col1n"
            />
            <use
                xlinkHref="#AccMas040__Symbol_75_0_Layer0_0_FILL"
                transform="translate(203.4 51.95)"
                id="AccMas040__col1d"
            />
            <use
                xlinkHref="#AccMas040__Symbol_38_0_Layer0_0_FILL"
                transform="translate(126.8 117)"
                id="AccMas040__col2n"
            />
        </g></g>
    )
}

export default AccMas040
