import { AssetSvgProps } from "../../../shared/assets"

function ShiMot058({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot058__Layer5_2_FILL">
                    <path
                        fill="#FF0"
                        d="M186 266.3h-.05q-1.25 0-2.35.3l-2.05.8-.55.35q-.9.5-1.7 1.3-.9.9-1.5 1.95-.05.05-.05.1-.35.65-.6 1.4l-.2.6v.1q-.1.3-.15.7-.05 0-.05.1l-.05.3q-.05.4-.05.8l-.05.45v.35q0 .6.1 1.15.15 1.45.8 2.75.7 1.4 1.85 2.55 2.65 2.65 6.45 2.75H186.45q3.6-.15 6.2-2.75l.4-.4h.05q2.3-2.65 2.3-6.25 0-3.9-2.75-6.65-.55-.6-1.15-1-2.4-1.75-5.5-1.75m-.6 1.1v1.75h1v-1.75q2.7.1 4.7 1.75.1 0 .15.05.05.05.05.15.25.2.45.4.35.35.7.75-.6-.05-1.15-.05v1q.35 0 .7.05-.35.15-.7.35v1.1q1.05-.5 2-1.1.8 1.45.9 3.3v.85l-.35-.1q-1.1-.75-2.55-1.25v1.05q.95.35 1.7.8l-1.7.4v1q1.4-.2 2.75-.6-.25 1.25-.85 2.45-1.05-.4-2.15-.7-.15.45-.4.9.95.3 1.95.65-1.55.45-3.05.7-.9.8-1.95 1.2 2.05-.15 4.2-.65-2.3 2.1-5.4 2.25V282.75h-1v1.2q-3.05-.15-5.25-2.3 1.55.4 3.25.65-.95-.45-1.7-1.2l-.15-.15q-.95-.2-1.85-.45-.2-.1-.4.05-.05 0-.05.05-.1-.1-.1-.15l-.05-.05q.8-.3 1.65-.5-.25-.45-.4-.95-.9.25-1.75.6h-.05q-.65-1.2-.85-2.6h.1q1.1.3 2.25.55v-1.05q-1.15-.2-2.25-.5-.05-.05-.1-.05h-.1q.05-.05.1-.05 1.15-.3 2.35-.55v-1q-1.15.2-2.3.55h-.1q.1-1.4.65-2.5v-.05q.05-.1.1-.25l.05.05q.65.5 1.6.95v-1.15q-.35-.2-.65-.35.35-.05.65-.05v-1.05l-.7.1q.3-.4.7-.8v-.4q0-.1.05-.15.1-.05.2-.05h.4q.35-.3.75-.5l1.3-.7q1.25-.5 2.65-.55m2.7 12.55l.7.7.65-.65-.7-.7-.65.65m-5.45.7l.7-.7-.65-.65-.7.7.65.65m6.9-5h1v-.95h-1v.95m-7.8-.95h-1v.95h1v-.95m-1-3.65h1v-.95h-1v.95m8.8-.95v.95h1v-.95h-1m-3.25.95v-.95h-1v.95h1z"
                    />
                    <path
                        fill="#DC231E"
                        d="M189.7 281.1q-.1.1-.15.2 1.5-.25 3.05-.7-1-.35-1.95-.65-.4.6-.95 1.15m-3.3 2.85v.15q3.1-.15 5.4-2.25-2.15.5-4.2.65-.6.15-1.2.25v1.2m4.9-8.25v1.2l1.7-.4q-.75-.45-1.7-.8m1.9 4.05q.6-1.2.85-2.45-1.35.4-2.75.6v.05q-.05.55-.25 1.1 1.1.3 2.15.7m1-4.1v-.5q-.1-1.85-.9-3.3-.95.6-2 1.1v1.7q1.45.5 2.55 1.25l.35.1v-.15-.2m-2.45-5.9q-.2-.2-.45-.4v1.1q.55 0 1.15.05-.35-.4-.7-.75m.25 1.75q-.35-.05-.7-.05v.4q.35-.2.7-.35m-5.6-4.1v1.75h4.7q-2-1.65-4.7-1.75m-5.9 9.85q0 .15.05.3 0 .65.25 1.3.15.45.4.9.35.55.8 1.05.15.15.35.3 1.4 1.25 3.35 1.25 1.7 0 3.05-.95.35-.3.65-.6.45-.45.8-.95.25-.45.45-.9.15-.5.25-.95v-8.45h-10.4v7.7m5.8-7.15v.95h-1v-.95h1m3.25.95v-.95h1v.95h-1m-7.8 0h-1v-.95h1v.95m-1 3.65h1v.95h-1v-.95m8.5 2.5q-.05.5-.25.95-.1.2-.2.35-.25.4-.6.7-.1.1-.15.2-.7.6-1.65.8-.35.05-.75.05h-.25q-1.2-.1-2.05-.9l-.15-.15q-.35-.35-.6-.75-.2-.3-.3-.6-.15-.5-.15-1.05v-5.3h7.1v5.7m1.3-1.55h-1v-.95h1v.95m-7.2 4.3l-.7.7-.65-.65.7-.7.65.65m5.45.7l-.7-.7.65-.65.7.7-.65.65z"
                    />
                    <path
                        fill="#006132"
                        d="M185.4 283.95v-1.2q-1.1-.05-2-.45-1.7-.25-3.25-.65 2.2 2.15 5.25 2.3m-3.85-3q-.5-.5-.8-1.05-.85.2-1.65.5l.05.05q0 .05.1.15 0-.05.05-.05.2-.15.4-.05.9.25 1.85.45m-3.75-4h-.1q.2 1.4.85 2.6h.05q.85-.35 1.75-.6-.25-.7-.3-1.45-1.15-.25-2.25-.55m-.1-1.1q-.05 0-.1.05h.1q.05 0 .1.05 1.1.3 2.25.5v-1.15q-1.2.25-2.35.55m2.35-3.95v-.4q-.3 0-.65.05.3.15.65.35m0-1.45v-.7q-.4.4-.7.8l.7-.1m-1.6 1.65l-.05-.05q-.05.15-.1.25v.05q-.55 1.1-.65 2.5h.1q1.15-.35 2.3-.55v-1.25q-.95-.45-1.6-.95m6.95-2.95v-1.75q-1.4.05-2.65.55l-1.3.7q-.4.2-.75.5h4.7z"
                    />
                    <path
                        fill="#FFF"
                        d="M186.4 269.15H180.3q-.1 0-.2.05-.05.05-.05.15V277.5q.05.75.3 1.45.15.5.4.95.3.55.8 1.05l.15.15q.75.75 1.7 1.2.9.4 2 .45h1q.6-.1 1.2-.25 1.05-.4 1.95-1.2.05-.1.15-.2.55-.55.95-1.15.25-.45.4-.9.2-.55.25-1.1v-8.6q0-.1-.05-.15-.05-.05-.15-.05h-4.7m-5.85 8.4q-.05-.15-.05-.3v-7.7h10.4V278q-.1.45-.25.95-.2.45-.45.9-.35.5-.8.95-.3.3-.65.6-1.35.95-3.05.95-1.95 0-3.35-1.25-.2-.15-.35-.3-.45-.5-.8-1.05-.25-.45-.4-.9-.25-.65-.25-1.3m8.45.6q.2-.45.25-.95v-5.7h-7.1v5.3q0 .55.15 1.05.1.3.3.6.25.4.6.75l.15.15q.85.8 2.05.9h.25q.4 0 .75-.05.95-.2 1.65-.8.05-.1.15-.2.35-.3.6-.7.1-.15.2-.35z"
                    />
                </g>
                <g id="ShiMot058__Layer5_2_MEMBER_0_FILL">
                    <path
                        fill="#002F81"
                        d="M186.65 274.7h-1.85v1.4q0 .15.05.25l.1.2q.05.1.15.15 0 .05.05.05.2.2.5.25h.25q.25-.05.45-.2 0-.05.05-.1.1-.05.15-.15 0-.05.05-.1.05-.1.05-.25v-1.5m-1.2.35q.05.1.05.2t-.05.15q-.1.1-.2.1t-.15-.1q-.1-.05-.1-.15 0-.1.1-.2.05-.1.15-.1.1 0 .2.1m.5.2q0-.1.05-.2.1-.1.2-.1t.2.1q.05.1.05.2t-.05.15q-.1.1-.2.1t-.2-.1q-.05-.05-.05-.15m.25.8q.1 0 .2.05.05.1.05.2t-.05.2q-.1.05-.2.05t-.2-.05q-.05-.1-.05-.2t.05-.2q.1-.05.2-.05m-.3-.5q.1.1.1.2t-.1.2q-.05.05-.15.05-.1 0-.2-.05-.05-.1-.05-.2t.05-.2q.1-.05.2-.05t.15.05m-.8.55q.05-.05.15-.05.1 0 .2.05.05.1.05.2t-.05.2q-.1.05-.2.05t-.15-.05q-.1-.1-.1-.2t.1-.2z"
                    />
                    <path
                        fill="#FFF"
                        d="M185.25 276.05q-.1 0-.15.05-.1.1-.1.2t.1.2q.05.05.15.05.1 0 .2-.05.05-.1.05-.2t-.05-.2q-.1-.05-.2-.05m.75-.3q0-.1-.1-.2-.05-.05-.15-.05-.1 0-.2.05-.05.1-.05.2t.05.2q.1.05.2.05t.15-.05q.1-.1.1-.2m.4.35q-.1-.05-.2-.05t-.2.05q-.05.1-.05.2t.05.2q.1.05.2.05t.2-.05q.05-.1.05-.2t-.05-.2m-.4-1.05q-.05.1-.05.2t.05.15q.1.1.2.1t.2-.1q.05-.05.05-.15 0-.1-.05-.2-.1-.1-.2-.1t-.2.1m-.5.2q0-.1-.05-.2-.1-.1-.2-.1t-.15.1q-.1.1-.1.2t.1.15q.05.1.15.1.1 0 .2-.1.05-.05.05-.15z"
                    />
                </g>
                <g id="ShiMot058__Layer5_2_MEMBER_1_FILL">
                    <path
                        fill="#002F81"
                        d="M186.65 272h-1.85v1.4q0 .15.05.25l.1.2q.05.1.15.15 0 .05.05.05.2.2.5.25h.25q.25-.05.45-.2 0-.05.05-.1.1-.05.15-.15 0-.05.05-.1.05-.1.05-.25V272m-.45 1.35q.1 0 .2.05.05.1.05.2t-.05.2q-.1.05-.2.05t-.2-.05q-.05-.1-.05-.2t.05-.2q.1-.05.2-.05m.2-.65q-.1.1-.2.1t-.2-.1q-.05-.05-.05-.15 0-.1.05-.2.1-.1.2-.1t.2.1q.05.1.05.2t-.05.15m-.85.15q.1-.05.2-.05t.15.05q.1.1.1.2t-.1.2q-.05.05-.15.05-.1 0-.2-.05-.05-.1-.05-.2t.05-.2m-.05-.3q0 .1-.05.15-.1.1-.2.1t-.15-.1q-.1-.05-.1-.15 0-.1.1-.2.05-.1.15-.1.1 0 .2.1.05.1.05.2m-.4.85q.05-.05.15-.05.1 0 .2.05.05.1.05.2t-.05.2q-.1.05-.2.05t-.15-.05q-.1-.1-.1-.2t.1-.2z"
                    />
                    <path
                        fill="#FFF"
                        d="M185.25 273.35q-.1 0-.15.05-.1.1-.1.2t.1.2q.05.05.15.05.1 0 .2-.05.05-.1.05-.2t-.05-.2q-.1-.05-.2-.05m.2-.65q.05-.05.05-.15 0-.1-.05-.2-.1-.1-.2-.1t-.15.1q-.1.1-.1.2t.1.15q.05.1.15.1.1 0 .2-.1m.3.1q-.1 0-.2.05-.05.1-.05.2t.05.2q.1.05.2.05t.15-.05q.1-.1.1-.2t-.1-.2q-.05-.05-.15-.05m.45 0q.1 0 .2-.1.05-.05.05-.15 0-.1-.05-.2-.1-.1-.2-.1t-.2.1q-.05.1-.05.2t.05.15q.1.1.2.1m.2.6q-.1-.05-.2-.05t-.2.05q-.05.1-.05.2t.05.2q.1.05.2.05t.2-.05q.05-.1.05-.2t-.05-.2z"
                    />
                </g>
                <g id="ShiMot058__Layer5_2_MEMBER_2_FILL">
                    <path
                        fill="#002F81"
                        d="M186.65 277.4h-1.85v1.4q0 .15.05.25l.1.2q.05.1.15.15 0 .05.05.05.2.2.5.25h.25q.25-.05.45-.2 0-.05.05-.1.1-.05.15-.15 0-.05.05-.1.05-.1.05-.25v-1.5m-1.4.8q-.1 0-.15-.1-.1-.05-.1-.15 0-.1.1-.2.05-.1.15-.1.1 0 .2.1.05.1.05.2t-.05.15q-.1.1-.2.1m.5 0q.1 0 .15.05.1.1.1.2t-.1.2q-.05.05-.15.05-.1 0-.2-.05-.05-.1-.05-.2t.05-.2q.1-.05.2-.05m.2-.25q0-.1.05-.2.1-.1.2-.1t.2.1q.05.1.05.2t-.05.15q-.1.1-.2.1t-.2-.1q-.05-.05-.05-.15m.25.8q.1 0 .2.05.05.1.05.2t-.05.2q-.1.05-.2.05t-.2-.05q-.05-.1-.05-.2t.05-.2q.1-.05.2-.05m-.75.05q.05.1.05.2t-.05.2q-.1.05-.2.05t-.15-.05q-.1-.1-.1-.2t.1-.2q.05-.05.15-.05.1 0 .2.05z"
                    />
                    <path
                        fill="#FFF"
                        d="M185.5 279q0-.1-.05-.2-.1-.05-.2-.05t-.15.05q-.1.1-.1.2t.1.2q.05.05.15.05.1 0 .2-.05.05-.1.05-.2m.9-.2q-.1-.05-.2-.05t-.2.05q-.05.1-.05.2t.05.2q.1.05.2.05t.2-.05q.05-.1.05-.2t-.05-.2m-.4-1.05q-.05.1-.05.2t.05.15q.1.1.2.1t.2-.1q.05-.05.05-.15 0-.1-.05-.2-.1-.1-.2-.1t-.2.1m-.1.5q-.05-.05-.15-.05-.1 0-.2.05-.05.1-.05.2t.05.2q.1.05.2.05t.15-.05q.1-.1.1-.2t-.1-.2m-.8-.15q.05.1.15.1.1 0 .2-.1.05-.05.05-.15 0-.1-.05-.2-.1-.1-.2-.1t-.15.1q-.1.1-.1.2t.1.15z"
                    />
                </g>
                <g id="ShiMot058__Layer5_2_MEMBER_3_FILL">
                    <path
                        fill="#002F81"
                        d="M188.6 276.7q.1-.05.15-.15 0-.05.05-.1.05-.1.05-.25v-1.5H187v1.4q0 .15.05.25l.1.2q.05.1.15.15 0 .05.05.05.2.2.5.25h.25q.25-.05.45-.2 0-.05.05-.1m0-1.65q.05.1.05.2t-.05.15q-.1.1-.2.1t-.2-.1q-.05-.05-.05-.15 0-.1.05-.2.1-.1.2-.1t.2.1m-1.15-.1q.1 0 .2.1.05.1.05.2t-.05.15q-.1.1-.2.1t-.15-.1q-.1-.05-.1-.15 0-.1.1-.2.05-.1.15-.1m1.2 1.35q0 .1-.05.2-.1.05-.2.05t-.2-.05q-.05-.1-.05-.2t.05-.2q.1-.05.2-.05t.2.05q.05.1.05.2m-.7-.8q.1 0 .15.05.1.1.1.2t-.1.2q-.05.05-.15.05-.1 0-.2-.05-.05-.1-.05-.2t.05-.2q.1-.05.2-.05m-.5.55q.1 0 .2.05.05.1.05.2t-.05.2q-.1.05-.2.05t-.15-.05q-.1-.1-.1-.2t.1-.2q.05-.05.15-.05z"
                    />
                    <path
                        fill="#FFF"
                        d="M187.65 276.1q-.1-.05-.2-.05t-.15.05q-.1.1-.1.2t.1.2q.05.05.15.05.1 0 .2-.05.05-.1.05-.2t-.05-.2m.45-.55q-.05-.05-.15-.05-.1 0-.2.05-.05.1-.05.2t.05.2q.1.05.2.05t.15-.05q.1-.1.1-.2t-.1-.2m.5.95q.05-.1.05-.2t-.05-.2q-.1-.05-.2-.05t-.2.05q-.05.1-.05.2t.05.2q.1.05.2.05t.2-.05m-.95-1.45q-.1-.1-.2-.1t-.15.1q-.1.1-.1.2t.1.15q.05.1.15.1.1 0 .2-.1.05-.05.05-.15 0-.1-.05-.2m1 .2q0-.1-.05-.2-.1-.1-.2-.1t-.2.1q-.05.1-.05.2t.05.15q.1.1.2.1t.2-.1q.05-.05.05-.15z"
                    />
                </g>
                <g id="ShiMot058__Layer5_2_MEMBER_4_FILL">
                    <path
                        fill="#002F81"
                        d="M184.4 274.7h-1.85v1.4q0 .15.05.25l.1.2q.05.1.15.15 0 .05.05.05.2.2.5.25h.25q.25-.05.45-.2 0-.05.05-.1.1-.05.15-.15 0-.05.05-.1.05-.1.05-.25v-1.5m-1.65.55q0-.1.1-.2.05-.1.15-.1.1 0 .2.1.05.1.05.2t-.05.15q-.1.1-.2.1t-.15-.1q-.1-.05-.1-.15m.95 0q0-.1.05-.2.1-.1.2-.1t.2.1q.05.1.05.2t-.05.15q-.1.1-.2.1t-.2-.1q-.05-.05-.05-.15m.05.85q.1-.05.2-.05t.2.05q.05.1.05.2t-.05.2q-.1.05-.2.05t-.2-.05q-.05-.1-.05-.2t.05-.2m0-.35q0 .1-.1.2-.05.05-.15.05-.1 0-.2-.05-.05-.1-.05-.2t.05-.2q.1-.05.2-.05t.15.05q.1.1.1.2m-.75.3q.1 0 .2.05.05.1.05.2t-.05.2q-.1.05-.2.05t-.15-.05q-.1-.1-.1-.2t.1-.2q.05-.05.15-.05z"
                    />
                    <path
                        fill="#FFF"
                        d="M183.2 276.1q-.1-.05-.2-.05t-.15.05q-.1.1-.1.2t.1.2q.05.05.15.05.1 0 .2-.05.05-.1.05-.2t-.05-.2m.45-.15q.1-.1.1-.2t-.1-.2q-.05-.05-.15-.05-.1 0-.2.05-.05.1-.05.2t.05.2q.1.05.2.05t.15-.05m.3.1q-.1 0-.2.05-.05.1-.05.2t.05.2q.1.05.2.05t.2-.05q.05-.1.05-.2t-.05-.2q-.1-.05-.2-.05m-.2-1q-.05.1-.05.2t.05.15q.1.1.2.1t.2-.1q.05-.05.05-.15 0-.1-.05-.2-.1-.1-.2-.1t-.2.1m-.9 0q-.1.1-.1.2t.1.15q.05.1.15.1.1 0 .2-.1.05-.05.05-.15 0-.1-.05-.2-.1-.1-.2-.1t-.15.1z"
                    />
                </g>
                <path
                    fill="#DC231E"
                    d="M185.95 258.05q-9.838 18.954 0 35.8h39.75v-35.8h-39.75z"
                    id="ShiMot058__Layer5_0_FILL"
                />
                <path
                    fill="#006132"
                    d="M159.2 258.05v35.8h26.75v-35.8H159.2z"
                    id="ShiMot058__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot058__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot058__Layer5_1_FILL" />
            <use xlinkHref="#ShiMot058__Layer5_2_FILL" />
            <use xlinkHref="#ShiMot058__Layer5_2_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot058__Layer5_2_MEMBER_1_FILL" />
            <use xlinkHref="#ShiMot058__Layer5_2_MEMBER_2_FILL" />
            <use xlinkHref="#ShiMot058__Layer5_2_MEMBER_3_FILL" />
            <use xlinkHref="#ShiMot058__Layer5_2_MEMBER_4_FILL" />
        </g></g>
    )
}

export default ShiMot058
