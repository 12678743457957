import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col1s": "#770707",
    "col2n": "#AC8268"
}

function ShiLon059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M192.8 260.4q-5.95 0-10.45-3.3-.6-.4-1.2-.95-.1-.15-.25-.2l-.65-.65q-.3-.3-.45-.6-.2-.2-.4-.3-.15-.15-.3-.35l-.7.95q-.25.25-.35.5-.25.3-.35.5-.1.15-.15.2-.05.05-.05.2-.1.05-.2.15-.1.25-.15.5-3.85 5.7-8.05 9l4.95 4.3-.2-.2q.282.25.65.45.2.15.45.3v.1l13.75 10.05.7.5q.6.35 1.2.85.15.05.3.15.4.25.75.55.6.4 1.15.9.5-.5 1.1-.9.4-.3.75-.55.15-.1.3-.15.45-.4.95-.7l17-12.5 3.55-3.15q-4.2-3.3-8.05-9-.05-.25-.15-.5-.15-.15-.25-.35-.2-.35-.5-.7-.05-.2-.15-.3l-.2-.2q0-.05.05-.05l-.65-.95-.45.45q-.2.15-.3.25-.15.1-.2.25-.6.75-1.45 1.35l-.25.25q-4.65 3.85-11.1 3.85z"
                    id="ShiLon059__Layer12_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-2.05 1.4-4.15 2.75-3.95 2.4-8 4.35v.05l.1.3q.1.2.15.4l.1.3q.05.1.05.2.2.7.3 1.4l.1.3q.55 2.65.55 5.25.05 1.3-.05 2.55 0 .35-.05.7l-.1 1.1v.2q-.5 3.5-1.9 6.95-.75 2.3-2.15 4.35 0 .05-.05.1l-.1.1q-.1.2-.3.45-.1.1-.25.3-.6.8-1.35 1.55-1 1.05-2.25 2l-.6.4h-.05q-.65.35-3.05.8-1.45.25-3.2.1-3.05-.5-5.9-2.15L83 55.9 60.2 39.75q-2.55 1.4-5.3 1.85-1.75.15-3.2-.1-2.4-.45-3.05-.8h-.05l-.6-.4q-1.25-.95-2.25-2-.75-.75-1.35-1.55-.15-.2-.25-.3-.2-.25-.3-.45l-.1-.1q-.05-.05-.05-.1-1.4-2.05-2.15-4.35-1.65-4.05-2-8.25-.05-.35-.05-.7-.1-1.25-.05-2.55v-.35q.05-2.45.55-4.9l.1-.3q.1-.7.3-1.4 0-.1.05-.2l.1-.3q.05-.2.15-.4 0-.1.05-.15l-.25-.35q-3.95-1.95-7.65-4.2-2.15-1.35-4.2-2.75L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95l-.2.5Q9 23.8 7.35 28.35l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5m-61.1 12.15l.25.45q-.15-.1-.25-.25v-.05q.033-.1 0-.15M83 71.45h.1q-.8.5-.1 0m67.55-19.25q-1.35.35-2.6.95v-.05q1.25-.6 2.6-.9m-133.5.9v.05q-1.25-.6-2.6-.95 1.35.3 2.6.9m46.1 4.4l.2-.45.05.15v.05q-.1.15-.25.25z"
                    id="ShiLon059__Symbol_351_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M101.35 14.3q0 .4-.05.8h15.3l-.2-.4-.1-.2q-.1-.15-.2-.35-.5-1-1.15-1.95L108 0q-3.95 2.4-8 4.35v.05L78.05 30.7l-2.95 2.65L60.9 43.8q-.4.25-.8.55-.1.05-.25.15-.3.2-.6.45-.5.35-.95.75-.45-.4-.95-.75-.3-.25-.6-.45-.15-.1-.25-.15-.5-.4-1-.7l-.6-.4-11.5-8.4v-.1q-.2-.1-.35-.25-.421-.234-.7-.5l.3.3-4.1-3.6L16.3 4.2Q12.35 2.25 8.65 0l-7 12.2Q1 13.15.5 14.15q-.1.2-.2.35l-.1.2-.2.4h15l.1.95-9.15 8.55.05.05 33.1 25.1.1-.2v.05q-.05.05 0 .15v.05L51 59.45v.05h.15v.1q.15.05.35.15.5.3 4 2.6.6.4 1.05.75.15.05.2.1l.05.05q.1.05.25.15l.15.1.3.2v-.1q.8.75 1.6 0v.1q.2-.15.45-.3l.3-.2-.85.8 2.1-1.45q3.5-2.5 4-2.8.2-.1.35-.15v-.1h.1q.05 0 .05-.05l11.8-9.65q.05-.148 0-.2l.05.05.05.1 33.1-25.1.05-.05-9.4-8.8q-.007.058-.05.1.082-.745.15-1.6m-.4-1.75h.05q0 .1-.05.2v-.2M43.7 36.2q.1 0 .25.05v.05l-.25-.1m28.95.1v-.05q.15-.05.25-.05l-.25.1m6.9 8.3q0-.05.05-.05h.05q-.052.002-.1.05m-63.9-31.85q-.05-.1-.05-.2h.05v.2M37 44.55q.05 0 .05.05-.048-.048-.1-.05H37z"
                    id="ShiLon059__Symbol_352_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M85.55 2.95l-.1-.3q-.1-.7-.3-1.4 0-.1-.05-.2l-.1-.3q-.05-.2-.15-.4-.05-.15-.1-.35-12.35 6-27.9 8.35h-.15q-.2.05-.35.05-2.1.25-4.25.45-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-1.85-.15-3.6-.35h.15q-1.2-.15-2.05-.25h-.1q-1.65-.2-2.05-.25Q13.6 5.95 1.4 0q-.1.2-.15.35-.1.2-.15.4l-.1.3q-.05.1-.05.2-.2.7-.3 1.4l-.1.3Q0 5.6 0 8.2q-.05 1.3.05 2.55 0 .35.05.7.35 4.2 2 8.25.75 2.3 2.15 4.35 0 .05.05.1l.1.1q.1.2.3.45.1.1.25.3.6.8 1.35 1.55 1 1.05 2.25 2l.6.4h.05q.65.35 3.05.8 1.45.25 3.2.1 2.75-.45 5.3-1.85 1.3-.7 2.55-1.65 3.5-2.75 6.7-7.5.05-.2.15-.4.05-.1.15-.15 0-.1.05-.15.05-.05.1-.15.1-.2.3-.45.1-.2.3-.4l.6-.8q.1.15.25.3.15.1.3.25.15.25.4.5l.55.55q.1.05.2.15.5.45 1 .8 3.75 2.75 8.7 2.75 5.4 0 9.3-3.2.1-.15.2-.2.7-.5 1.2-1.15.05-.1.15-.2.1-.1.25-.2l.4-.4.55.8q-.05 0-.05.05l.15.15q.1.1.15.25.25.3.4.6l.2.3q.1.2.15.4 3.2 4.75 6.7 7.5.95.75 1.95 1.35 2.85 1.65 5.9 2.15 1.75.15 3.2-.1 2.4-.45 3.05-.8h.05l.6-.4q1.25-.95 2.25-2 .75-.75 1.35-1.55.15-.2.25-.3.2-.25.3-.45l.1-.1q.05-.05.05-.1Q83.25 22 84 19.7q1.65-4.05 2-8.25.05-.35.05-.7.1-1.25.05-2.55 0-2.6-.55-5.25m.2 5.25q0 .1-.05.2v-.2h.05m-85.4 0H.4v.2q-.05-.1-.05-.2z"
                    id="ShiLon059__Symbol_353_0_Layer0_0_FILL"
                />
                <path
                    d="M192.2 317.6H193.1v-17.9h-1.2v17.9h.3m-7.95-58.35q-.15-.1-.3-.15-1.45-.65-2.3-1.85-1.5-1.3-2.2-3.3-.492-1.328-.4-2.8v-.05q0-.3.05-.6v-.55q.05-.4.05-.8 0-.233.05-.45-.567-.069-1.15-.15-.065.465-.1.95-.05.5-.05 1v1.3q.05 1.55.75 2.85v.05q.05.3.15.55.15.5.45.95.45.7 1.2 1.25.05 0 .05.05.15.05.25.1l-7.25 29.9.05.05h-.05v-.05l-1.95 8h13.5q.05 0 .15-.05 0 .05.05.05l-.05-14.6-.05-.1v-4.2q.1.05.2.15V260q-.1-.1-.2-.15l-.9-.6m-6.1 13v.05l-.25-.1q.1 0 .25.05m22.6-13l-.9.6q-.1.05-.2.15v16.75q.1-.1.2-.15v4.2l-.05.1-.05 14.6q.05 0 .05-.05.1.05.15.05h13.5l-1.95-8v.05h-.05l.05-.05-7.25-29.9q.1-.05.25-.1 0-.05.05-.05.75-.55 1.2-1.25.3-.45.45-.95.1-.25.15-.55v-.05q.7-1.3.75-2.85v-.7-.6q0-.468-.05-.95.003-.018 0-.05-.034-.477-.15-.95-.536.103-1.1.15.05.225.05.45v.4l.05.4v.55q.05.3.05.6.1 1.5-.4 2.85-.7 2-2.2 3.3-.85 1.2-2.3 1.85-.15.05-.3.15m6.1 13.05v-.05q.15-.05.25-.05l-.25.1m41.75-8.05q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon059__Layer3_0_FILL"
                />
            </defs>
            <use xlinkHref="#ShiLon059__Layer12_0_FILL" />
            <use
                xlinkHref="#ShiLon059__Symbol_351_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon059__col1n"
            />
            <use
                xlinkHref="#ShiLon059__Symbol_352_0_Layer0_0_FILL"
                transform="translate(134.2 236)"
                id="ShiLon059__col1s"
            />
            <use
                xlinkHref="#ShiLon059__Symbol_353_0_Layer0_0_FILL"
                transform="translate(149.45 240.35)"
                id="ShiLon059__col2n"
            />
            <use xlinkHref="#ShiLon059__Layer3_0_FILL" />
        </g></g>
    )
}

export default ShiLon059
