import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#8080FF",
    "col1n": "#ADADFF"
}

function ShiSho072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M103.5 19.1v-.05h-.2q-.5-.75-1.2-1.55v-.05q.9-4.1-1.4-7.15-.4-.5-.85-.95-2.15-2.3-6.35-3.95h-.05q-.35-1.15-.9-2.05-1.35-2.2-4.1-3-4-1.2-8.35 1.6-1.4.7-2.75 1.4-5.15 2.75-9.35 6-5 3.85-8.65 8.4-.75.9-1.45 1.85-.7-.95-1.45-1.85-3.65-4.55-8.65-8.4-4.2-3.25-9.35-6-1.35-.7-2.75-1.4-4.35-2.8-8.35-1.6-2.75.8-4.1 3-.55.9-.9 2.05h-.05q-4.2 1.65-6.35 3.95-.45.45-.85.95-2.3 3.05-1.4 7.15v.05q-.7.8-1.2 1.55h-.2v.05H.45L0 21.6h.05q.25-.05.45-.05h11.05v.05h.2q0 .9.3 1.75.5 1.4 1.9 2.8l.4.4q1.15 1.1 2.75 2.15-.6 1.25-.4 2.3.15 2.95 8.75 3.65 13.9 1 22.55-2.75 4.5-2 7.6-5.15l.2-.2q.2-.2.35-.4.2-.2.4-.45.2-.2.35-.45l.1-.1q.5-.6.9-1.2.4.6.9 1.2l.1.1q.15.25.35.45.2.25.4.45.15.2.35.4l.2.2q3.1 3.15 7.6 5.15 8.65 3.75 22.55 2.75 8.6-.7 8.75-3.65.2-1.05-.4-2.3 1.6-1.05 2.75-2.15l.4-.4q1.4-1.4 1.9-2.8.3-.85.3-1.75h.2v-.05h11.05q.2 0 .4.05l-.45-2.5H103.5z"
                    id="ShiSho072__Symbol_189_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.1 16.1h1.3v.05h.2q.05-1.25.75-2.55h-.2v.05H1.1Q-.3 15 .1 16.1m88.35-2.5q1.7-.7 3.5-1.55-18.6 7.35-40.9 4.5l-1.1-.1q-.15-.05-.25-.05l-.75.05v-.05h-.2q-.15-.15-.15-.35 0-.35.35-.35.05 0 .1.05 0-.05.05-.05 1.05-.15 2.1-.4l1.2-.3q.75-.15 1.5-.35.5-.15 1-.25 12.55-3.3 23-10.4.05-.05.15-.1 2.65-1.85 5.2-3.9-2.95 2.05-6 3.9-11.55 6.85-24.9 10.35-.65.15-1.25.3-.8.2-1.5.35-.35.2-.65.25h-.4q-.05 0-.1-.05-.15-.2-.25-.2-.05 0-.05-.4v-.05q0-.35 1.05-2.15-.75.9-1.45 1.85-.7-.95-1.45-1.85 1.05 1.8 1.05 2.15v.05q0 .4-.05.4-.1 0-.25.2-.05.05-.1.05h-.4q-.3-.05-.65-.25-.7-.15-1.5-.35-.6-.15-1.25-.3-13.35-3.5-24.9-10.35-3.05-1.85-6-3.9 2.55 2.05 5.2 3.9.1.05.15.1 10.45 7.1 23 10.4.5.1 1 .25.75.2 1.5.35l1.2.3q1.05.25 2.1.4.05 0 .05.05.05-.05.1-.05.35 0 .35.35 0 .2-.15.35h-.2v.05l-.75-.05q-.1 0-.25.05l-1.1.1q-22.3 2.85-40.9-4.5 1.8.85 3.5 1.55 3.8 1.5 7.6 2.55 4.4 1.2 8.8 1.75 7.1.9 14.4.25 1.15-.1 2.25-.25 2.5-.3 4.95-.75l1.65-.45q.35 0 .35.3t-.35.5q-.25.1-.65.2-.2.05-.45.1-.1.05-.25.1-3.15 1.6-6.4 2.8-.6.2-1.15.4l-.3.1q-13.9 4.7-29.75 2.2 17.1 3.8 32.35-2.15.2-.1.35-.15.5-.2.95-.4 2.75-1.15 5.45-2.6.1.05.15.25.1.35.1.6t-.25.75q.5-.6.9-1.2.4.6.9 1.2-.25-.5-.25-.75t.1-.6q.05-.2.15-.25 2.7 1.45 5.45 2.6.45.2.95.4.15.05.35.15 15.25 5.95 32.35 2.15Q71.9 25.9 58 21.2l-.3-.1q-.55-.2-1.15-.4-3.25-1.2-6.4-2.8-.15-.05-.25-.1-.25-.05-.45-.1-.4-.1-.65-.2-.35-.2-.35-.5t.35-.3l1.65.45q2.45.45 4.95.75 1.1.15 2.25.25 7.3.65 14.4-.25 4.4-.55 8.8-1.75 3.8-1.05 7.6-2.55m6.95 2.5q.4-1.1-1-2.45h-1.05v-.05h-.2q.7 1.3.75 2.55h.2v-.05h1.3z"
                    id="ShiSho072__Symbol_190_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho072__Symbol_189_0_Layer0_0_FILL"
                transform="translate(134.6 251.35)"
                id="ShiSho072__col1n"
            />
            <use
                xlinkHref="#ShiSho072__Symbol_190_0_Layer0_0_FILL"
                transform="translate(144.75 256.8)"
                id="ShiSho072__col1d"
            />
        </g></g>
    )
}

export default ShiSho072
