import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#252525",
    "col2n": "#000000"
}
function BodHan009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M26.55 3q.032-.078.05-.15-.023.081-.05.15l-.75-.5Q15.3-1.2 4.1.55q-2.344 11.19-2.85 16H23.2Q24.538 11.062 26.55 3M171.3 16.55q.03-4.721-2.3-16-11.2-1.75-21.9 2.1 0 .05-.05.1-.3.15-.4.25-.1.05-.3.3 2.196 8.016 3.5 13.25h21.45z"
                    id="BodHan009__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.8 2q.054.03.1.05V2h-.1m8.65.55q-.037-.075-.05-.15-5.224 4.606-8 5.35.45-1.375 0-2.75 0-.106-.05-.25-1.426 1.063-6.3 2.35-1.236-1.301-1.8-2.55-.446-.95-.45-1.65-.575.282-1.2.6-2.75 1.35-3.7 1.75V4.9q-.579-1.893-1.1-3.55L.2 18.2q-.9 3.8 2.5 4.9l.05.05q9.7 3.8 19.15-.3 1.4-.75 1.65-2l3-18.25v-.15q-.063.173-.1.1M166.55 8q-.685-1.006-4.55-4.1h-.1q.545 2.442.05 3.05-2.25-.91-7.5-2l-.05.05q0 .8-.2 2-.25 1.7-.65 2.1-2.05-1.3-4.55-4.4-1.3-1.8-1.9-2.6 0 .05-.05.1-.3.15-.4.25-.1.05-.3.3l3.2 18.1q.25 1.25 1.65 2 9.45 4.1 19.15.3l.05-.05q3.4-1.1 2.5-4.9l-3.65-16.9q-1.062 2.725-2.7 6.7z"
                    id="BodHan009__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M3.9 0L0 20.9q1.4-.75 1.65-2L4.7.35Q4.6.7 4.55.6q-.1-.2-.4-.35Q4.1.2 4.1.15L3.9 0m125.4 20.9L125.2.15q0 .05-.05.1-.3.15-.4.25-.1.05-.3.3l3.2 18.1q.25 1.25 1.65 2z"
                    id="BodHan009__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan009__Symbol_19_0_Layer0_0_FILL"
                transform="translate(105.95 284.55)"
                id="BodHan009__col1n"
            />
            <use
                xlinkHref="#BodHan009__Symbol_52_0_Layer0_0_FILL"
                transform="translate(105.95 285.1)"
                id="BodHan009__col2n"
            />
            <use
                xlinkHref="#BodHan009__Symbol_20_0_Layer0_0_FILL"
                transform="translate(127.85 287.05)"
                id="BodHan009__col2d"
            />
        </g></g>
    )
}
export default BodHan009
