import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00B600"
}

function ShiMot019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.4 4.2q-.3-.7-.55-1.4-.35.7-.6 1.4-4.1 10.85-3 24.9.65 5.1 2.65 10.5.1.3.2.65l.1.45q-.45-.65-1.2-1.65-.5-.9-.85-1.55-4.7-15.6-17.25-27.35.55 10.9 10.2 22.75l.25.15q2.35 3.2 6.85 6.55l.45.45q.3.45.6.8h-.15q-.4-.4-1.05-.85-1.85-1.35-2.05-1.55-1.65-1.55-2.85-2.3-2.45-2.05-5.5-3.65Q9.2 29.8 0 27.75q5.85 9.35 22.55 13.2l2.05.6-5.35-.25Q14 40.25 5.95 46.65q8.3.2 18.35-4.4l1.3-.15q-.1.05-.15.1-.65.4-1.7 1.05-4.9 2-6.3 6.25 6.5-2.3 8.95-6.95l.2-.2v3.3h.5v-3.3q.05.1.2.2 2.4 4.65 8.95 6.95-1.45-4.25-6.35-6.25-1.05-.65-1.7-1.05l-.1-.1 1.25.15q10.05 4.6 18.35 4.4-8-6.4-13.25-5.35l-5.4.25 2.05-.6q16.65-3.85 22.55-13.2Q44.45 29.8 40 32.5q-3.05 1.6-5.45 3.65-1.25.75-2.9 2.3-.15.2-2 1.55-.7.45-1.1.85h-.1q.25-.35.6-.8.2-.25.45-.45 4.45-3.35 6.8-6.55l.25-.15Q46.2 21.05 46.8 10.15 34.15 21.9 29.5 37.5q-.35.65-.8 1.55-.8 1-1.2 1.65l.05-.45q.1-.35.25-.65 1.95-5.4 2.65-10.5Q31.5 15.05 27.4 4.2z"
                    id="ShiMot019__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot019__Symbol_25_0_Layer0_0_FILL"
                transform="translate(165.65 255)"
                id="ShiMot019__col1n"
            />
        </g></g>
    )
}

export default ShiMot019
