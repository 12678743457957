import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiSpe131({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M15.25.2V.15l-.05-.1-.05-.05q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25L19 35.4l.1-.8q.25-2.3.4-4.45 0-.2.05-.35l.1-2.4q.7-16.85-4.4-27.2m131.3 18.9q-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35-.05.1-.1.25-4.8 9.55-4.55 24.75 0 1.2.05 2.4.05 1.35.15 2.75.15 2.15.4 4.45.05.4.1.85l19.1-7.1q-.9-2.4-1.85-4.75-1-2.3-2-4.5z"
                    id="ShiSpe131__Symbol_312_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe131__Symbol_312_0_Layer0_0_FILL"
                transform="translate(117.3 228.6)"
                id="ShiSpe131__col1n"
            />
        </g></g>
    )
}

export default ShiSpe131
