import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#621F00",
    "col1n": "#9A3200"
}

function ObjFoo008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.85 50.9q.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.25.2-.45.4 5.85 5.9 13.4 12.05 4.5 3 8.1 3 1.2 0 2.2-.35l.1.1q.45.75 1.6 1.8 0 .05.05.05 1.05.9 2.15 1.4 1.2.55 2.4.55t1.75-1.15q.25-.3.4-.75.05-.15.1-.35.15-2.25.4-3.1.4-1.25 1.35-2.8.65-1.05.35-2.1-.3-1.1-.7-1.55-.45-.55-2-1.05-1.55-.55-4.1.95l.35-.3q-.2.05-.35.1-.1-.8-.4-1.4-.6-1.75-3.25-3.5-1.6-1.05-2.8-1.85-5.15-4.45-9.35-8.8-.9.2-1.8.2-3.15 0-5.4-2.25-1.2-1.2-1.75-2.6M13 0h-1.1q-.85-.05-1.75.8Q9.7 1.25 9.1 2q-.45.45-1.5 1.05-1.35.8-2.15.8-.45 0-.9-.15-.05 0-.55-.2t-1.25-.2Q.95 3.3.3 4.95q-.35.8-.3 1.65 0 1.05.95 2.05 1 1 2.7 1.35h.1q.8.3 1.95.45-.3.3-.55.65-1.15 1.55-1.6 3.3-.35 1.25-.35 2.65 0 5.2 5.75 17.6 1.1 2.3 1.95 4.2.85 2.2 2 4.45.45.9.95 1.8.65-.85 1.75-1.4 4.45-1.85 10.2 0 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-1.6.6-2.9-5.1-6.95-7.6-13.6-1.6-4.4-3.45-10.85-2.8-9.65-6.2-12-.2-.2-.45-.3-.05-.05-.1-.05t-.05-.05l.3-1V7.3q.15-.5.3-1.15.5-2.6.5-2.65 0-1.15-.55-2.15Q14.05.25 13 0m1.4 14.05h-.05L14.3 14q.05 0 .1.05z"
                    id="ObjFoo008__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M49.35 74.1l.1.1q.15.1.45.2.5.1 1.3.1H51.8q.1-.05.2-.05h.05q.1-.05.2-.05.35-.05.55-.2.35-.15.55-.35l.05-.05q.65-.65.9-2.3.25-1.3.75-1.8.45-.4.45-1.25 0-.7-.55-1.25-.55-.6-1.75-1.1.05.2.05.45.05.35.05.7 0 .25-.05.45.05.05 0 .1-.05 1.75-.7 3.2-.3.65-.65 1.15-.25.35-.5.6-.1.15-.25.25l-.1.1q-.15.15-.3.25l-.6.4-.4.2q-.2.05-.35.15h-.05M10.95 1.9q-.25-.7-.5-1.15Q9.3-1.05 5 1.5q-.1.05-.15.1-.9.55-1.15.65-.85.35-2.25.4l-.15.05Q-.55 3.35.25 4.75q.05.05.1.05.8.3 1.95.45.55-.55 1.25-1Q4 4 4.45 3.8q.75-.35 1.65-.6.3-.1.6-.15.9-.2 1.95-.3h.8q.05 0 .1.05.55.05 1.05.3.05.05.1.05l.3-1V2.1q0-.1-.05-.2z"
                    id="ObjFoo008__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo008__Symbol_100_0_Layer0_0_FILL"
                transform="translate(92.95 243.5)"
                id="ObjFoo008__col1n"
            />
            <use
                xlinkHref="#ObjFoo008__Symbol_36_0_Layer0_0_FILL"
                transform="translate(96.35 248.7)"
                id="ObjFoo008__col1d"
            />
        </g></g>
    )
}

export default ObjFoo008
