import { AssetSvgProps } from "../../../shared/assets"

function ShiMot102({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 270v12.05h66.5V270h-66.5z"
                    id="ShiMot102__Layer1_0_MEMBER_1_FILL"
                />
                <g id="ShiMot102__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#528C40"
                        d="M159.2 282.05v12.05h66.5v-12.05q-33.44-7.762-66.5 0z"
                    />
                    <path
                        fill="#AF8CBF"
                        d="M159.2 258v12q34.573 6.427 66.5 0v-12h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot102__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot102__Layer1_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ShiMot102
