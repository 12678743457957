import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#008300",
    "col1l": "#00DE00",
    "col1n": "#00B600",
    "col1s": "#3D584C"
}

function ShiSpe049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M149.15 21.75V7.65q-1.05-3.1-8.7-6.05-1.55-.6-3.4-1.2l-1.4-.4-.2.15-.75.75-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-7.6 5.25-15.95 8.85l-2.9 1.2Q97.4 20.3 83.7 21.5q-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-10.45-1.6-19.95-5.35l-2.6-1.1q-8.15-3.5-15.65-8.7L19.25 4.4q-1.45-1.05-2.8-2.1L13.4 0l-1.3.4Q1.25 3.95 0 7.65v14.1l19.3 7.6.1.05q-.45 3.4-.95 6.8 0 .2-.05.4l-3.85 18.9.35.1-.45-.1.35.2-.4-.2.7 2.65 14.25 3.95h28.9l1.45-1.25q1.15-.75 4.3-.9h21.15q3.15.15 4.3.9l1.45 1.25h28.9l14.25-3.95.7-2.65-.4.2.35-.2-.45.1.35-.1-3.85-18.9q-.05-.2-.05-.4-.5-3.4-.95-6.8l.1-.05 19.3-7.6z"
                    id="ShiSpe049__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M11.75 16.65q1.2-3.75 1.2-7.6 0-2.65-.85-5.4Q11 0 9.1 0 7.6 0 0 4.15h.45q7.5 1.25 8.9 6.25.1.3 1.95 5 .25.65.45 1.25m31.85 12.6q9.7-.4 11.95 1-.15-.45-.35-1-.35-1.15-.95-1.8-.35-.45-1.1-.75-.35-.1-.7-.15-6.9-.8-15.6-.15-3.6.25-7.5.75-1.25.4-1.8.5-1 .45-1.65 1.55-.65 1.1-.85 2.35-.25 1.2 2.65 13.1-.15-6.3 3-10.65 3.15-4.4 12.9-4.75m44.9-2.7q-.35.05-.7.15-.75.3-1.1.75-.6.65-.95 1.8-.2.55-.35 1 2.25-1.4 11.95-1 9.75.35 12.9 4.75 3.15 4.35 3 10.65 2.9-11.9 2.65-13.1-.2-1.25-.85-2.35-.65-1.1-1.65-1.55-.55-.1-1.8-.5-3.9-.5-7.5-.75-8.7-.65-15.6.15M128 9.05q0 3.85 1.2 7.6.2-.6.45-1.25 1.85-4.7 1.95-5 1.4-5 8.9-6.25h.45Q133.35 0 131.85 0q-1.9 0-3 3.65-.85 2.75-.85 5.4z"
                    id="ShiSpe049__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 7.5l19.25 7.6.1.05q0-.1.05-.2l.15-1.25-.05-.05L0 5.9v1.6m32.7-4.8q-1.35 1.1-1.85 2.4l25.3 8.25Q35.25 6 32.7 2.7m85.5 2.4q-.5-1.3-1.85-2.4Q113.8 6 92.9 13.35l25.3-8.25m-9.35-1.75q.85-.65 1.65-1.35 1-.9 2-1.85l-2.9 1.2q-12.25 4.7-25.95 5.9-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2H74q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85Q48.5 4.85 39 1.1L36.4 0q1.05 1.05 2.15 2 .8.7 1.65 1.35Q50.5 10.3 64.75 12q4.8.55 9.6.6h.35q4.8-.05 9.6-.6 14.25-1.7 24.55-8.65m25.45 38.1l.35-.2-15.05 4.35H90.9l-1.5-1.3q-1.15-.75-4.3-.9H63.95q-3.15.15-4.3.9l-1.5 1.3h-28.7L14.4 41.25l.35.2-.4-.2.7 2.65 14.25 3.95h28.9l1.45-1.25q1.15-.75 4.3-.9H85.1q3.15.15 4.3.9l1.45 1.25h28.9L134 43.9l.7-2.65-.4.2M126.4 15q-.2.75-.35 1.5-5.4 24.05-8 22.7h-4.5l-.1.05-.1-.05h-8.95q-6.05-.15-11.2-.4-2.45-.2-4.55-.4-8.6-1.05-11.05-2.75l-.1-.1-.15-.15q-1.65-2.3-2.25-6.1-.1-.5-.15-1.05v-.1q-.05-.6-.1-1.25v-.2q0-.15-.05-.3h.05l-.15-4.5v1.5l-.2-6-.15 6v-1.5l-.15 4.5h.05q-.05.15-.05.3v.2q-.05.65-.1 1.25v.1q-.05.55-.15 1.05-.6 3.8-2.25 6.1l-.15.15-.1.1Q69 37.35 60.4 38.4q-2.1.2-4.55.4-5.15.25-11.2.4H35.7l-.1.05-.1-.05H31q-2.6 1.35-8-22.7-.15-.75-.35-1.5-.05-.35-.15-.7.05.3.1.65.05.75.15 1.45 2.95 25.3 5.95 24.8 1.6.05 3.25.05 11.9.15 23.9-.25 5.05-.4 10.15-1.15 1.1-.15 2.2-.35h.2q.15-.05.3-.05l.55-.1q2.35-.4 5.1-.35h.35q2.75-.05 5.1.35l.55.1q.15 0 .3.05h.2q1.1.2 2.2.35 5.1.75 10.15 1.15 12 .4 23.9.25 1.65 0 3.25-.05 3 .5 5.95-24.8.1-.7.15-1.45.05-.35.1-.65-.1.35-.15.7m22.65-7.5V5.9l-19.5 7.75-.05.05.15 1.25q.05.1.05.2l.1-.05 19.25-7.6z"
                    id="ShiSpe049__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M55.6 5.2l5.8-1.4q.4-.15.8-.25 1.5-.45 2.95-.95 3-1.05 5.45-2.35-12.25 4.7-25.95 5.9-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2H35q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85Q9.5 3.75 0 0q2.6 1.45 5.9 2.6 1.45.5 2.95.95.4.1.8.25l5.8 1.4q9.95 2.2 19.9 2.25h.35q9.95-.05 19.9-2.25z"
                    id="ShiSpe049__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe049__Symbol_54_0_Layer0_0_FILL"
                transform="translate(117.85 227.7)"
                id="ShiSpe049__col1n"
            />
            <use
                xlinkHref="#ShiSpe049__Symbol_51_0_Layer0_0_FILL"
                transform="translate(121.95 230.55)"
                id="ShiSpe049__col1l"
            />
            <use
                xlinkHref="#ShiSpe049__Symbol_52_0_Layer0_0_FILL"
                transform="translate(117.9 241.95)"
                id="ShiSpe049__col1d"
            />
            <use
                xlinkHref="#ShiSpe049__Symbol_53_0_Layer0_0_FILL"
                transform="translate(156.9 243.05)"
                id="ShiSpe049__col1s"
            />
        </g></g>
    )
}

export default ShiSpe049
