import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1n": "#E4B670"
}

function ObjMor025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M97.6 6.4q-4.85-4.65-9.5-3.95l-62.25 27.9q-1.2.85-2.05 2.05-2 2.85-2 7.35.2 1.8.55 3.35L19 45.85q-3.05 2.1-8.35 3.1-8.9 1.5-10.6 7.15-.55 3.8 3.25 5.25 6.4.95 9.6-2.85 3.05-4 8.15-5.95l5.15-1.6q1.35 1.5 3.1 2.7 3 1.9 6.1 1.6 1.7-.15 3.45-.95l12.7-6.6q.2-.3.5-.6 1.55-1.85 2.8-6 .7-2.3 2.05-3.85 2.5-2.85 6.85-2.85 2.25 0 4 1.5 1 .85 1.45 1.75.15.4.3.7L97.85 23.6q4.55-2.8 3.2-10.4.65-.2 1.35-.35 1.55-.45 3.2-.5 4.85-.5 7.75-6.3.85-4-2.55-5.7-5.65-1.7-11.85 4.8l-.3.3q-.5.5-1.05.95z"
                    id="ObjMor025__Symbol_133_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14 34.2q-4.65-9-12-7.25-2 2.85-2 7.35.2 1.8.55 3.35L3.75 35q5-.2 5.75 4.05.7 3.9-.85 5.05L4.4 45.5q1.35 1.5 3.1 2.7 3 1.9 6.1 1.6 5.5-4.85.4-15.6M75.8.95q2.75 3.25 3.45 6.8.65-.2 1.35-.35Q80.1 3 76.85 0q-.5.5-1.05.95z"
                    id="ObjMor025__Symbol_74_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor025__Symbol_133_0_Layer0_0_FILL"
                transform="translate(209.75 256.75)"
                id="ObjMor025__col1n"
            />
            <use
                xlinkHref="#ObjMor025__Symbol_74_0_Layer0_0_FILL"
                transform="translate(231.55 262.2)"
                id="ObjMor025__col1d"
            />
        </g></g>
    )
}

export default ObjMor025
