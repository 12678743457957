import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#D9D9D9",
    "col2s": "#999999"
}

function ShiLon028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.6 58.6h5v-7.8H2.2v4q2.2-1.3 4.85-1.8.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-.25 1.15m141.2-3.85V50.8H140v7.8h3.25l-.25-1.15q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.65.45 4.8 1.75z"
                    id="ShiLon028__Symbol_330_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M163.55 48.45q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.4-1.65-.85-3.3l-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-20.9-3.2-38.2-15.15L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.45 1.65-.85 3.3l-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l2.15-.25h.55q.8-.05 1.65 0l.9.05H12.75q.4.05.75.1.8.1 1.55.3 3.9.9 6.9 3.95l-2.9 12.1q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.1l-5.8-23.3v-.1q.95-1 2.05-1.75 2.3-1.7 5-2.3.75-.2 1.55-.3.4-.05.85-.1 1.35-.1 2.6-.05h.55l2.15.25q.15 0 .3.05 4.05.75 7.05 3.35V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5z"
                    id="ShiLon028__Symbol_156_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.9 3.15q-1.2-.9-2.3-1.75l-.25-.2-13.6 51.5H8l.9.05H9.05q.4.05.75.1h.05L22.9 3.15m-10 5.55l-1.6 2.8Q4.35 32.8 0 54.05q1.5-.7 3.25-1 5.5-26.1 14.9-52.3-2.8 3.8-5.25 7.95m20.3 1.05q-1.4-.8-2.75-1.6L15.95 81.7q.7.15 1.45.3t1.45.35L33.2 9.75m5.1 2.55q-1.45-.65-2.85-1.35-5.3 36.5-11.9 72.4 1.45.25 2.95.55Q33 48.45 38.3 12.3m-9.95-5.4Q27 6.05 25.7 5.15L13.45 53.8q1.4.6 2.7 1.5l12.2-48.4m20.85 9.65q-1.5-.5-2.95-1l-8.65 70.3q1.45.2 2.95.45l8.65-69.75m10.55 2.65q-1.5-.3-2.95-.6L51 87.55l3 .3 5.75-68.65m15.45 1.65l-3-.1v68.2q1.5 0 3 .05V20.85M63.15 19.8q-.5-.1-.95-.15l-3.8 68.6 3 .2 3.8-68.35q-1.05-.15-2.05-.3m7.15.8q-1.5-.1-3-.25l-1.95 68.3q1.5.1 3 .15l1.95-68.2m-15.55-2.5q-1.5-.35-2.95-.8l-7.7 69.45 3 .4 7.65-69.05M43.4 14.5q-1.45-.55-2.85-1.15l-9.8 71.35q1.45.25 2.95.5l9.7-70.7M130.75 84q1.45-.3 2.95-.6-6.6-35.8-11.9-72.25-1.4.7-2.8 1.35 5.25 36.05 11.75 71.5M89.9 20.4q-1 .1-2.05.2-.45 0-.95.05l1.95 68.15 3-.1-1.95-68.3m10.55-1.7l-3 .6 5.75 68.6 3-.3-5.75-68.9m-5.4 1q-1.5.25-3 .45l3.75 68.3 3-.2-3.75-68.55M85 20.75l-3 .1V89q1.5-.05 3-.05v-68.2m20.45-3.3q-1.45.4-2.95.8l7.6 68.95 3-.4-7.65-69.35m11.25-3.95q-1.45.6-2.85 1.15l9.7 70.65 2.9-.5-9.75-71.3m-5.7 2.2q-1.5.5-2.95 1l8.6 69.65q1.5-.25 3-.45L111 15.7m20.6-10.3q-1.3.85-2.65 1.7l12.2 48.45q.05-.05.15-.1 1.2-.9 2.55-1.5L131.6 5.4m-4.75 3q-1.4.8-2.75 1.55l14.25 72.45q.9-.2 1.75-.4.6-.15 1.2-.25L126.85 8.4m12-8.4l-.05.05q9.55 26.5 15.15 52.9h.05q.15 0 .3.05 1.5.25 2.9.8-4.55-21.95-11.85-44-.55-.95-1.15-1.9-.6-.95-1.15-1.9-2-3.1-4.2-6m-1.9 1.45q-1.25.95-2.6 1.95l13.05 49.5q.2 0 .45-.05.4-.05.85-.1.9-.05 1.75-.05l-13.5-51.25z"
                    id="ShiLon028__Symbol_157_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M.15 40.05q-.1.35-.15.65h.05q11.3-6.9 22 1.6l-1.25 6.45v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-2.7 13.9q-10.75-8.25-22-1.8-.1.3-.15.65M90.4 65.7q-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5V68q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65m0-19.75q-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65m1.6-15.9q.65-.65.65-1.6 0-.95-.65-1.6-.65-.65-1.6-.65-.95 0-1.6.65-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65M129.25 0h-.1q-4.75 2.8-9.75 4.95L93.15 21.4q-5.2-7.1-11.35-8.85h-1.3q-6.2 1.75-11.4 8.85L42.95 5.05q-5.1-2.2-9.9-5.05l35.9 22.5q.15.1.35.05.2-.05.3-.2 5.05-6.95 10.9-8.75v67.05h1.2v-67.1q5.9 1.8 10.95 8.8.1.15.3.2.2.05.35-.05L129.25 0m32.9 40.7q.05 0 .15.05-.05-.1-.05-.15l-.1-.5q-.1-.35-.15-.65-11.35-6.5-22.05 1.75l-2.75-13.9q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-1.25-6.45q10.7-8.5 22-1.6z"
                    id="ShiLon028__Symbol_158_0_Layer0_0_FILL"
                />
            </defs>
            <g id="ShiLon028__col1n" transform="translate(110 228.6)">
                <use xlinkHref="#ShiLon028__Symbol_330_0_Layer0_0_FILL" />
                <use
                    xlinkHref="#ShiLon028__Symbol_156_0_Layer0_0_FILL"
                    id="ShiLon028__col1n_FL"
                />
            </g>
            <g id="ShiLon028__col2n" transform="translate(113.7 228.6)">
                <use
                    xlinkHref="#ShiLon028__Symbol_157_0_Layer0_0_FILL"
                    id="ShiLon028__col2n_FL"
                />
            </g>
            <g id="ShiLon028__col2s" transform="translate(111.4 236.95)">
                <use
                    xlinkHref="#ShiLon028__Symbol_158_0_Layer0_0_FILL"
                    id="ShiLon028__col2s_FL"
                />
            </g>
        </g></g>
    )
}

export default ShiLon028
