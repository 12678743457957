import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999"
}

function ObjToy062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M58.2 15.15q-1.9-4-6.9-7.1-.95-.6-2.05-1.2l-.2-.1q-.05 0-.05.05-2.45-1.4-2.35-1.5Q41 1.9 36.35 2.1 34.8 4.55 33.2 3.55L24.85.4Q22.8-.15 20.9.1q-3.05.35-5.85 2.8l-.75.7q-.15.1-.2.25-1.75 1.8-2.6 4-.5.55-.95 1-7.8 8.75-10.1 18.7-1.2 3.65.9 5.7 2.25 2.2 6-1.85l8.9-8.25q1 .8 2.2 1.6 5.85 3.7 4.3 7.65l-.95 2.8q1.2.95 3.05 4.45.35 1.25.35 2.8 0 2.5-.8 4.95l-.4 1q-1.5 3.75-4.5 4.8-1.15.4-2.5.4h-.75q-.15 1.9.3 3.2.95 2 3.05 1.85 2.85-.45 6.85-4.55L38.1 41.55q2.75-3.25 8.8.1 1.25.65 2.5 1.15l-.3.9-2.8 10.85q-1.75 5.25 1.3 6.2 2.8.9 5.4-1.95 2.8-2.5 5.15-5.6 2-2.7 3.65-5.8 1.55-3 2.8-6.4.25-.65.45-1.3 1.55-1.8 2.3-4.2.05-.15.1-.35l.25-1q.8-3.55-.35-6.4-.7-1.85-2.15-3.35l-6.75-5.8q-1.5-1.55-.25-3.45z"
                    id="ObjToy062__Symbol_193_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M38.85 31.6q-.1 0-.15.05-.15.05-.3.05l.25 1.25q.2-.05.45-.15v.05l.2-.1-.35-1.15q0-.05-.1 0m-38-19.5q-.35-3.5.8-6.35-.5.55-.95 1-.9 2.7-.65 5.85.9 4.95 5.65 9.05l.7-.6Q1.75 17.2.85 12.1m17.1-2.8q-.75-2.9-3.3-4.4-2.55-1.5-5.4-.75-2.9.7-4.4 3.25-1.5 2.55-.8 5.45.75 2.85 3.3 4.35 2.55 1.5 5.4.8 2.9-.75 4.4-3.3 1.5-2.55.8-5.4M26.5 0l-3.15 1.45-3.2 12.4L26.5 0m28.25 38.9q.25-.65.45-1.3-1.95 2.35-5.2 3.75-4.9 1.6-10.45-.65l-.3.9q5.95 2.2 10.65.65 2.95-1.25 4.85-3.35m-10.8-8.65l-.1-.1q-.1.75.1 1.55t.6 1.4l.1-.1q.1-.2.25-.3-.3-.45-.45-1.1-.15-.6-.15-1.2-.15-.05-.35-.15m1.95-3.35q.25.1.45.3.4-.25 1-.4.6-.15 1.15-.15.05-.25.2-.5-.75-.1-1.55.1-.7.25-1.25.65m4.95 7.4h-.05l-.4-.3q-.45.35-1.1.5-.6.15-1.15.15-.05.15-.15.35-.05.05-.05.1.65 0 1.45-.15.8-.2 1.45-.65m1.45-3.55q.2.05.4.2.1-.7-.1-1.45-.15-.75-.55-1.3-.15.2-.35.35.3.5.5 1.15.1.55.1 1.05M48.6 16.5l-.25-3.45L39.2 25.2l9.4-8.7z"
                    id="ObjToy062__Symbol_192_0_Layer0_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M126.8 293.75q.35 1.45 1.6 2.25 1.3.75 2.75.35 1.45-.35 2.15-1.65.75-1.25.4-2.7-.35-1.4-1.6-2.15-1.3-.8-2.75-.45-1.45.4-2.2 1.65-.75 1.25-.35 2.7z"
                    id="ObjToy062__Layer2_1_FILL"
                />
                <path
                    d="M123 267.75l-.05-2.5-2.25 1.1 2.3 1.4m-.8 4.35l2.5-.05-1.15-2.3-1.35 2.35m-4.35-.9l.05 2.5 2.25-1.1-2.3-1.4m-1.65-4.25l1.2 2.3 1.35-2.35-2.55.05z"
                    id="ObjToy062__Layer2_2_FILL"
                />
                <g id="ObjToy062__Layer2_0_FILL">
                    <path
                        fill="#FFD200"
                        d="M156.65 290.75q-.65-.4-1.45-.25-.55.2-.95.6-.15.1-.25.3l-.1.1q-.3.7-.1 1.35.2.8.85 1.2.7.4 1.5.2.7-.2 1.15-.75 0-.05.05-.1.1-.2.15-.35.2-.55.05-1.15-.2-.75-.9-1.15m-1.4-5.45h-.05q-.65-.4-1.4-.2-.75.2-1.15.9-.45.65-.2 1.45.15.7.75 1.1l.1.1q.2.1.35.15.55.15 1.15.05.7-.25 1.15-.9.4-.7.2-1.45-.15-.5-.45-.9-.2-.2-.45-.3m6.4 3.85q-.5-.1-1.05 0-.75.2-1.15.9-.45.65-.2 1.45.1.55.5.9l.4.3h.05q.65.4 1.4.2.75-.25 1.2-.9.4-.7.15-1.45-.2-.8-.85-1.2h-.05q-.2-.15-.4-.2m-.95-5.25q-.65-.4-1.45-.2-.8.2-1.2.85-.15.25-.2.5-.1.45 0 1 .2.8.85 1.2.7.4 1.5.2.5-.15.85-.5.2-.15.35-.35v-.05q.4-.65.2-1.45-.2-.8-.9-1.2z"
                    />
                    <path
                        fill="#0800DE"
                        d="M151.6 293.1q-.3-1.05-1.25-1.6-.8-.5-1.7-.35l-.2.1v-.05q-.25.1-.45.15-.7.35-1.15 1.05-.55.95-.3 1.95.3.9 1 1.45.1.1.2.15.65.35 1.25.35.35 0 .7-.05 1.05-.3 1.6-1.25.55-.9.3-1.9z"
                    />
                    <path
                        fill="#008C33"
                        d="M148.3 290q.85-.3 1.3-1.1.55-.95.3-2-.25-1.05-1.15-1.6-.95-.55-2-.25-1.05.25-1.6 1.2-.5.9-.25 1.95.25 1 1.2 1.55.75.45 1.65.35.15 0 .3-.05.05-.05.15-.05.1-.05.1 0z"
                    />
                    <path
                        fill="#333"
                        d="M157.8 272.65l.15 2.25 6.75 5.8q1.45 1.5 2.15 3.35l.95-1.35q-.65-1.9-2.2-3.5l-7.8-6.55m-33.15 16.95q-1.4 2.35-.75 5 .05.2.1.35.1.35.25.7.8 1.85 2.65 2.95 2.4 1.4 5.05.75 2.65-.65 4.05-3t.75-5.05q-.7-2.65-3.1-4.05-2.35-1.4-4.95-.7-2.65.7-4.05 3.05m-5.35-14.5l1.8-3.05 3.05 1.85 1.9-3.2-3.05-1.85 1.85-3.1-3.25-1.9-1.85 3.1-3.05-1.8-1.9 3.2 3.05 1.8-1.8 3.05 3.25 1.9m1.75-20.15l-.65 1.45q1.9-.25 3.95.3l8.35 3.15 2.05-.95-9.5-3.7q-2.15-.55-4.2-.25z"
                    />
                    <path
                        fill="red"
                        d="M135.45 283.9q.05.1.15.15.95.55 1.95.3 1.05-.25 1.6-1.2.55-.9.3-1.95-.3-1.05-1.25-1.6-.1-.05-.15-.1-.8-.4-1.75-.15-1.05.25-1.6 1.15-.55.95-.3 2 .25.9 1.05 1.4z"
                    />
                    <path
                        fill="#2F2F2F"
                        d="M330.85 363.75q6.606 7.366 17.2 14.2 5.968 3.835 16.95 9.8 2.84 1.551 11.3 6.3 8.404 4.665 13.55 7.2 1.767.877 25.8 13 18.19 9.196 21.4 10.55.288.12.55 0 .32-.115.45-.4.12-.287 0-.6-.116-.27-.4-.4-3.19-1.346-21.3-10.5-24.067-12.128-25.85-13-5.104-2.515-13.45-7.15-8.49-4.751-11.35-6.3-10.918-5.935-16.85-9.75-10.406-6.716-16.9-13.95-6.562-7.32-7.95-8.45-1.324-1.124-3.2-2.65-1.889-1.505-9.65-6.9-7.793-5.361-14.25-8.25-15.296-6.752-41.65-13.4-6.591-1.32-12.45-2.65-8.07-1.85-14.75-3.7-21.695-5.875-32.65-13.65-7.57-5.434-13.85-14.95-6.22-9.526-8.3-14.9-2.085-5.373-6.9-9.35-4.822-3.972-10.05-3.95-3.373-.002-5.55.6-1.498.432-3.3 1.35-.025.012-.05 0-.262.14-.55.25-.337.19 1.15.75.28.096.45.15.067.016.1 0 1.402-.69 2.6-1.05 2.022-.548 5.15-.55 4.775.02 9.25 3.6 4.524 3.632 6.55 8.9 2.03 5.267 8.35 15 6.317 9.787 14.1 15.35 11.095 7.925 33.1 13.9 6.847 1.89 15.15 3.75 5.717 1.32 12.1 2.6 26.196 6.601 41.4 13.3 6.343 2.861 14 8.15 7.688 5.355 9.55 6.85v-.05q1.874 1.524 3.2 2.65 1.361 1.12 7.8 8.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy062__Symbol_193_0_Layer0_0_FILL"
                transform="translate(99.5 256.3)"
                id="ObjToy062__col1n"
            />
            <use
                xlinkHref="#ObjToy062__Symbol_192_0_Layer0_0_FILL"
                transform="translate(109.35 258.4)"
                id="ObjToy062__col1d"
            />
            <use xlinkHref="#ObjToy062__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy062__Layer2_1_FILL" />
            <use xlinkHref="#ObjToy062__Layer2_2_FILL" />
        </g></g>
    )
}

export default ObjToy062
