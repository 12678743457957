import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000"
}

function SkiWou013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M8.45 15.3q-.05-.35-.2-.65L4.3 0 .35 14.65q-.1.3-.2.65-.15.5-.15 1.15 0 1.8 1.3 3.1 1.25 1.25 3 1.3 1.75-.05 3.05-1.3 1.25-1.3 1.25-3.1 0-.65-.15-1.15z"
                    id="SkiWou013__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou013__Symbol_21_0_Layer0_0_FILL"
                transform="translate(193.25 193.55)"
                id="SkiWou013__col1n"
            />
        </g></g>
    )
}

export default SkiWou013
