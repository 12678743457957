import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo027({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#3737FF"
                    d="M120.3 299.5q.5-.65.85-1.5l-7.2-1.95q.329.42 1.5 2.8-.048-.087-.1-.2-.248-.399-.25-.35.117.219.2.4.932 1.93 2.2 2.1.071.033.1.05.509.052 1.05-.15.024.036.05 0 .027-.015.05-.05.23-.064.45-.2.178-.057.35-.2.194-.121.35-.3.22-.2.4-.45m13.95-31.3l-.05.45 6.2.8q-.2-.55-.5-1.2-.863-2-1.5-2.8-1.007-1.277-2.8 0-.672.67-1.2 2-.05.35-.15.75m-.05 3.3l.05.1.35 2.25q0 .65-.6.65-.65-.6-1.15-1-2.95-1.8-6.2-.55-.45.25-.8.4l20.3 3.25q.009-2.17-2.3-3.6l-.2-.1-9.45-1.4m-15.8 13.9q0 .25-.05.4v.05l14.7 3.5h.05q.35-.4.5-.65.3-.45.6-.7.75-1.15 1.4-2.3l-16.6-3.65q-.6 1.7-.6 3.35m-7.8 3.55q-.4.05-.7.3-.55.25-.95.6-.2.35-.2.65 0 .3.1.55l12.75 3q-.117-.819-.35-1.75-.15-.4-.25-.8.95.65 1.75 1.1.3.05.45.2.15 0 .2.05 1.35.35 2.75.25.7-.1 1.6-.2.05-.05.1-.05l.05.05q.05-.05.1-.05.65-.209 1.25-.55l-16.1-3.8q-1.5.05-2.55.45m10.2-10.9q.15.2 0 .2-.15.25-.25.4-.087.134-.2.25l16.6 3.25.1.05v-.05q.196-1.222 0-2.35 0-.2-.05-.45 0-.3-.05-.55l-14.4-2.8q-.6.55-1.1 1.25-.1.1-.2.35-.15.15-.25.4l-.2.05z"
                    id="ObjFoo027__Layer7_1_FILL"
                />
                <path
                    fill="red"
                    d="M138.8 278.7q-.253.158-.5.3l4.3 4.7q.123-.433.25-.9.7-2.8.6-3.6.05-.25-.2-.5l-.1-.1q-1.1.1-2.6.05-.75-.1-1.4-.15-.15.05-.35.2m-1.75 3.7q-.35 1.45-1.4 3.25l5.1 5.75h.05q.4-.2.85-.35.8-.2 1.5-.25-.05-.75-.15-1.5l-.05-.05-5.9-6.85m-30.25 8.95q.05-.15.05-.3.095-.238.15-.45.05-.217.05-.4l-1.7-2.5q0 .8-.3 2.7 0 1.25.8 3.3.3-.6.5-1.15.3-.4.45-1.2m-1.45-11.75q-.4.15-.55.4l3 4.6q.1-.2.35-.35.25-.2.6-.35 1.9.4 4.05 3.3.6.65 1.1 1.25l.05.05q.2-.05.3 0h.05l-4.95-7.25q-1.55-1.25-2.95-1.55-.55-.1-1.05-.1m13.8 2l-1.15-1.55q-1.55.9-2.6 2.2l2.9 4.05q.045-.18.05-.45v-.05q.05-.15.05-.4 0-1.65.6-3.35.1-.2.15-.45z"
                    id="ObjFoo027__Layer7_3_FILL"
                />
                <g id="ObjFoo027__Layer7_0_FILL">
                    <path
                        fill="#F0F"
                        d="M117.5 300.8l.15.15-.05-.1q-.029-.017-.1-.05z"
                    />
                    <path
                        fill="#FF0"
                        d="M137.35 279.65q-.15.05-.3.15.196 1.128 0 2.35v.25q-.35 1.45-1.4 3.25 0 .05-.05.05-.65 1.15-1.4 2.3-.3.25-.6.7 0 .1.05.15.1 0 .15.05.25-.45.45-.9h.05q.45 1.1 2.45 3.05.8.85 1.5 1.45.55-.35 1.15-.65.25-.1.55-.15.45-.1.8-.3h.05q.4-.2.85-.35.8-.2 1.5-.25-.05-.75-.15-1.5l-.05-.05-.6-1.75q-.4-1.35.25-3.65.015-.075 0-.15.123-.433.25-.9.7-2.8.6-3.6.05-.25-.2-.5l-.1-.1q-1.1.1-2.6.05-.75-.1-1.4-.15-.15.05-.35.2-.253.158-.5.3-.121.108-.25.2-.4.1-.7.45m-21.95 2.6q-.2.15-.3.25-1.1 1.1-1.55 2.15-.05.3-.1.4-.1-.25-.35-.4-.4-.5-.95-1.05-.5-.6-1.05-.95-.3-.25-.45-.5-.25-.05-.4-.25-.1-.1-.25-.15h-.05q-.35-.3-.6-.5-1.55-1.25-2.95-1.55-.55-.1-1.05-.1-.4.15-.55.4-.35.3-.35.85-.1.65.1 1.6l.55 2.85q.05.1.25 1.65 0 .05-.1.1.1.3.1.55v.1q0 .8-.3 2.7 0 1.25.8 3.3.3-.6.5-1.15.3-.4.45-1.2.05-.15.05-.3.095-.238.15-.45.05-.217.05-.4.15-.6.2-.75l.15-2.8q.1-1.15.2-1.7 0-.15.2-.35.1-.2.35-.35.25-.2.6-.35 1.9.4 4.05 3.3.6.65 1.1 1.25l.05.05q.2-.05.3 0h.05q.15 0 .4-.05.35.05.55.05.75-.05 1.55 0 .8.1 1.4.3.2-.2.45-.2-.35-1-.35-2.3.045-.18.05-.45v-.05q.05-.15.05-.4 0-1.65.6-3.35.1-.2.15-.45.409-1.362 1.2-2.7.113-.116.2-.25-.232.108-.45.2-1.14.574-2.1 1.2-1.55.9-2.6 2.2z"
                    />
                    <path
                        fill="#FFF"
                        d="M121 291.5q.1.4.25.8.233.931.35 1.75.028.265.05.5v.1q-.05 1.05-.2 1.9-.05.4-.1.75-.04.158-.1.3-.04.218-.1.4-.35.85-.85 1.5-.18.25-.4.45-.156.179-.35.3-.172.143-.35.2-.22.136-.45.2-.023.035-.05.05-.026.036-.05 0-.541.202-1.05.15l.05.1q.25.1.3.25 1.45 1 3.55 1.5.45.107.9.15.6.121 1.2.15.45.1.95.1h.85q.683.057 1.35 0 .505-.014 1-.1 2.15-.15 3.9-1 1.8-.95 3-2.3.05-.05.05-.1.1-.4.25-.7v-.1q0-.35.15-.6.1-.1.25-.1.2-.2.45.1.38.266.75 1.05.13.249.25.55l.75 2q.1.3.2.35 1 1.3 1.2 1.5.677.638 1.45.9.197.06.4.1.31.104.65.1 1.405-.182 1.65-1.55.04-.855.05-1l-.05-1.65q0-.622.15-1.3.211-1.012.75-2.15.3-.6.65-1 .475-.899.8-1.6 1.155-1.905 0-3.15-.767-.686-2.3-.55-.7.05-1.5.25-.45.15-.85.35h-.05q-.35.2-.8.3-.3.05-.55.15-.6.3-1.15.65-.2.05-.35.15-.85.45-1.65.95l-.1.05v-.05q-.15-2.95-2.35-4.7-.05-.05-.15-.05-.05-.05-.05-.15-.15.25-.5.65l-.05.05q-.75.75-1.55 1.4-.1.1-.3.2-.2.25-.6.45-.642.509-1.35.85-.6.341-1.25.55-.05 0-.1.05l-.05-.05q-.05 0-.1.05-.9.1-1.6.2-1.4.1-2.75-.25-.05-.05-.2-.05-.15-.15-.45-.2-.8-.45-1.75-1.1m-2.35-2.9q-.25 0-.45.2.35 0 .55.1-.05-.2-.1-.3m-9.9-4.7q-.35.15-.6.35-.25.15-.35.35-.2.2-.2.35-.1.55-.2 1.7l-.15 2.8q-.05.15-.2.75 0 .183-.05.4-.055.212-.15.45 0 .15-.05.3-.15.8-.45 1.2-.2.55-.5 1.15.056.099 0 .25-.018.204-.1.65-.076.445-.1 1.15 0 .7.55 1.55.65 1.04 1.75 1.15.17.05.35.05.8 0 1.45-.05.2-.05.45-.15.25-.15.6-.4.404-.121.75-.3.574-.263 1.05-.65.5-.35 1.05-1.05h.15q.02-.2-.5-.8-.52-.6-.85-.85l-1.2-.75-1.05-.95q-.75-.7-1.2-1.2-.1-.14-.15-.3-.1-.25-.1-.55 0-.3.2-.65.4-.35.95-.6.3-.25.7-.3 1.05-.4 2.55-.45.35 0 .75-.05-.5-.6-1.1-1.25-2.15-2.9-4.05-3.3z"
                    />
                    <path
                        fill="#6FC"
                        d="M121.45 277.25q-.1.1-.2.35-.15.15-.25.4l-.2.05q.15.2 0 .2-.15.25-.25.4-.087.134-.2.25-.791 1.338-1.2 2.7-.05.25-.15.45-.6 1.7-.6 3.35 0 .25-.05.4v.05q-.005.27-.05.45 0 1.3.35 2.3.05.1.1.3-.2-.1-.55-.1-.6-.2-1.4-.3-.8-.05-1.55 0-.2 0-.55-.05-.25.05-.4.05h-.05q-.1-.05-.3 0l-.05-.05q-.4.05-.75.05-1.5.05-2.55.45-.4.05-.7.3-.55.25-.95.6-.2.35-.2.65 0 .3.1.55.05.16.15.3.45.5 1.2 1.2l1.05.95 1.2.75q.33.25.85.85t.5.8q.05 0 .15.15.329.42 1.5 2.8-.048-.087-.1-.2-.248-.399-.25-.35.117.219.2.4.932 1.93 2.2 2.1.071.033.1.05.509.052 1.05-.15.024.036.05 0 .027-.015.05-.05.23-.064.45-.2.178-.057.35-.2.194-.121.35-.3.22-.2.4-.45.5-.65.85-1.5.06-.182.1-.4.06-.142.1-.3.05-.35.1-.75.15-.85.2-1.9v-.1q-.022-.235-.05-.5-.117-.819-.35-1.75-.15-.4-.25-.8.95.65 1.75 1.1.3.05.45.2.15 0 .2.05 1.35.35 2.75.25.7-.1 1.6-.2.05-.05.1-.05l.05.05q.05-.05.1-.05.65-.209 1.25-.55.708-.341 1.35-.85.4-.2.6-.45.2-.1.3-.2.8-.65 1.55-1.4l.05-.05q.35-.4.5-.65.3-.45.6-.7.75-1.15 1.4-2.3.05 0 .05-.05 1.05-1.8 1.4-3.25v-.25q.196-1.222 0-2.35 0-.2-.05-.45 0-.3-.05-.55-.15-.5-.3-.95 0-.1-.05-.15.4.15 1 .4.7.15 1.55.4.65.05 1.4.15 1.5.05 2.6-.05l.5-.1q1.4-.35 2.15-1.25.15-.25.35-.65.009-2.17-2.3-3.6l-1.2-.75q-1.15-.8-2.25-2.8-.2-.55-.5-1.2-.863-2-1.5-2.8-1.007-1.277-2.8 0-.672.67-1.2 2-.05.35-.15.75l-.2 2.3.15 1 .05.1.35 2.25q0 .65-.6.65-.65-.6-1.15-1-2.95-1.8-6.2-.55-.45.25-.8.4-1.8.85-3.3 2.65-.6.55-1.1 1.25z"
                    />
                </g>
                <g id="ObjFoo027__Layer7_2_FILL">
                    <path
                        fill="#F6F"
                        d="M141.65 291.05q-.45.15-.85.35l3.2 5.7q.3-.6.65-1 .475-.899.8-1.6 1.155-1.905 0-3.15-.767-.686-2.3-.55-.7.05-1.5.25m-8-2.2q-.05-.05-.05-.15-.15.25-.5.65l-.05.05 7.75 15.25q.31.104.65.1 1.405-.182 1.65-1.55.04-.855.05-1l-.05-1.65-5.2-7.9q-.85.45-1.65.95l-.1.05v-.05q-.15-2.95-2.35-4.7-.05-.05-.15-.05m-5.8 4v.05l3.8 9.1q1.8-.95 3-2.3l-4.05-8.25q-.642.509-1.35.85-.6.341-1.25.55-.05 0-.1.05l-.05-.05M121 291.5q.1.4.25.8.233.931.35 1.75.028.265.05.5v.1q-.05 1.05-.2 1.9-.05.4-.1.75l2.25 5.7q.45.1.95.1h.85q.683.057 1.35 0 .505-.014 1-.1l-4.35-10.15q-.05-.05-.2-.05-.15-.15-.45-.2-.8-.45-1.75-1.1m-14.2-.15q-.15.8-.45 1.2-.2.55-.5 1.15.056.099 0 .25-.018.204-.1.65-.076.445-.1 1.15 0 .7.55 1.55.65 1.04 1.75 1.15.17.05.35.05.8 0 1.45-.05l-2.95-7.1m1.95-7.45q-.35.15-.6.35l1.75 5q.3-.25.7-.3 1.05-.4 2.55-.45.35 0 .75-.05-.5-.6-1.1-1.25-2.15-2.9-4.05-3.3z"
                    />
                    <path
                        fill="#F0F"
                        d="M113.8 295.9q.02-.2-.5-.8-.52-.6-.85-.85l-1.2-.75 1.35 3.45q.5-.35 1.05-1.05h.15z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo027__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo027__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo027__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo027__Layer7_3_FILL" />
        </g></g>
    )
}

export default ObjFoo027
