import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1n": "#E4B670",
    "col2n": "#FF5D5D",
    "col2s": "#A80F0F"
}
function FacMou101({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#5B0202"
                    d="M192.45 229.2v-.05h-.1q.05.05.1.05z"
                    id="FacMou101__Layer8_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M23.95 16.5q1.3-.45 1.6-.45L28 0H0l1.2 15.55q1.1.45 1.9.75.2.1.55.2h20.3M13.25 3.95l.15.05h-.05q-.05 0-.1-.05z"
                    id="FacMou101__Symbol_180_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M36.05 12.5q1.65-4.75 1.1-8.95L16.5 0 .25 4.7Q-.6 7.85 1.2 11q-.05.65.95 3.25.95 2.6-.25 9.2Q.65 30.05.75 30.2q.2.35 2.25.35 3.15.05 3.25.1.15-.9.2-1.7v-.75-4.5q0-.4.1-.75-.05-2.75 1.6-4.25 1.1-1.1 2.95-1.4.1-.05.25-.05.15-.05.3-.05.45-.05.95-.05 2.1 0 3.95.85 1.3.6 1.9.9.55-.25 1.85-.85 1.95-.9 4.05-.9.5 0 .95.05.15 0 .3.05.15 0 .25.05 1.85.3 2.95 1.4 1.65 1.5 1.6 4.25.1.35.1.75v4.4q-.05-.05-.05-.15-.05.15-.05 1.1 0 1.3.2 2.1 5.8.3 5.1-.2-2.4-11.6.35-18.45z"
                    id="FacMou101__Symbol_179_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M61.15 18.05q.05-1.65-.05-3.75-.35-7.5-10.6-10.5Q40.3.75 39.55.7 38.7.55 37.8.45 36.45.3 35.05.2 32.95.05 30.9 0h-.65Q28.2.05 26.1.2q-.7.05-1.4.15-1.55.1-3.1.35-.75.05-10.95 3.1Q.4 6.8.05 14.3q-.1 2.1-.05 3.75.05.8.15 1.4.1.7.2 1.05v.05q.1.35.25.75.5 2.3 2.45 4.75 1.5 1.9 4.55 4.75 1.2 1.05 3.45 4 .5.6 1.05 1.35 1.45 1.9 1.45 5.35 0 1.85-1.1 6.35 0 .1-.05.2-.75 3.05-1 5.65-.75 6.9 3.9 10.6.6.5 1.35.95.15.1.35.2v-.05q.85.35 1.75.65l1.6.4q9.95 2.75 20.45 0l1.6-.4q.9-.3 1.75-.65v.05q.2-.1.35-.2.75-.45 1.35-.95 4.65-3.7 3.9-10.6-.25-2.6-1-5.65-.05-.1-.05-.2-1.1-4.5-1.1-6.35 0-3.45 1.45-5.35.55-.75 1.05-1.35 2.25-2.95 3.45-4 3.05-2.85 4.55-4.75 1.95-2.45 2.45-4.75.15-.4.25-.75v-.05q.1-.35.2-1.05.1-.6.15-1.4m-15.05 9.4q2.15 1.5 2.15 2.75 0 .9-1.9 4.6-1.95 3.65-1.95 5.05 0 1.65 1.05 4.25 1 2.6 1 3.95 0 1.9-1.2 3.3-.6.7-1.2 1.5-.4.3-1.35.45h-.9q-4.85-.3-7.15-.3l-12.9.25h-.1q-2.2 0-3.3-.45l-.2-.1q-.25-.1-1.7-1.25-.6-.45-1.2-2.15-.5-1.4-.5-2 0-1.45.95-3.8.9-2.3.9-3.95 0-1.35-.15-1.8-.2-.65-1.25-2.15-.55-.8-1.15-2.75-.55-1.9-.55-2.8 0-1.55 1.45-2.85.55-.6 3.6-1.4.1-.05.25-.05 3.9-1.1 4.8-1.25 1.1-.2 4.7-1 1.65.2 5.6.7 1.85.25 3.4.5 2.55.4 4.4.8.1 0 .25.05 2.1.5 4.15 1.9z"
                    id="FacMou101__Symbol_178_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M13.35 10.6q0-1.55 1.45-2.85.55-.6 3.6-1.4.036-.018.05-.05l-3.55.45q.075-.075.15-.1-2.62.35-4.7.35Q2.35 7 0 0q.1.7.2 1.05v.05q.1.35.25.75.5 2.3 2.45 4.75 1.5 1.9 4.55 4.75 1.2 1.05 3.45 4 .5.6 1.05 1.35 1.45 1.9 1.45 5.35 0 1.85-1.1 6.35 0 .1-.05.2-.75 3.05-1 5.65-.75 6.9 3.9 10.6.6.5 1.35.95-1.35-1.45-1.8-2.9-.3-1-.3-2.65 0-3.1 3.7-4.25.55-.15 1.3-.35.15-.05.3-.05l7.4-1.9-5.5.1h-.1q-2.2 0-3.3-.45l-.2-.1q-.25-.1-1.7-1.25-.6-.45-1.2-2.15-.5-1.4-.5-2 0-1.45.95-3.8.9-2.3.9-3.95 0-1.35-.15-1.8-.2-.65-1.25-2.15-.55-.8-1.15-2.75-.55-1.9-.55-2.8m40.05.75q3.05-2.85 4.55-4.75 1.95-2.45 2.45-4.75.15-.4.25-.75v-.05q.1-.35.2-1.05Q58.5 7 50.5 7q-1.95 0-4.35-.3-.2 0-.4-.05-.1-.05-.15-.05l-3.9-.55q.048.033.1.05 2.1.5 4.15 1.9 2.15 1.5 2.15 2.75 0 .9-1.9 4.6-1.95 3.65-1.95 5.05 0 1.65 1.05 4.25 1 2.6 1 3.95 0 1.9-1.2 3.3-.6.7-1.2 1.5-.4.3-1.35.45h-.9q-4.85-.3-7.15-.3l-.95.05 7.2 2h.35q.1 0 .25.05.8.15 1.4.35 3.7 1.15 3.7 4.25 0 1.65-.3 2.65-.45 1.45-1.8 2.9.75-.45 1.35-.95 4.65-3.7 3.9-10.6-.25-2.6-1-5.65-.05-.1-.05-.2-1.1-4.5-1.1-6.35 0-3.45 1.45-5.35.55-.75 1.05-1.35 2.25-2.95 3.45-4z"
                    id="FacMou101__Symbol_177_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M195.5 239.9q-.15-.6-.35-1.2-.5-1.45-.85-1.45-.4 0-1.25 2.65 1.69 1.207 2.45 0m2.65-2.35q-.2-.25-.4-.2-.1.05-.55.7-.45.6-.7 1.85l1.05.5-.1.25q.136-.088.25-.2l.15.1q-.025-.064-.05-.15.88-.492 1.3-.45-.25-.65-.55-1.55-.2-.6-.4-.85m-8.25 1.15q-.2.6-.35 1.2 1.225.76 2.45 0-.85-2.65-1.25-2.65-.35 0-.85 1.45m-1.35 1.2q-.254-1.24-.7-1.9-.428-.618-.6-.7-.141-.017-.35.25-.202.255-.4.85-.3.9-.55 1.55.463-.046 1 .3-.08.065-.2.15l1.8-.5m-7.05 1.25q.402.398.35.15-.056-.195.95-.5 1.005-.256 1.4-.5.398-.198.5-.2-.3-1.25-.45-2.75-.234-2.973-.4-4.3-.2-.13-.35-.25h-.05q-.148 0-.25.05-.104.172-.2.3-.092.148-.2.25-1.002 1.598-1.75 3.45-.3.65-.55 1.35-.3.8-.55 1.7.25.15.7.5.45.4.85.75m13.7-26.35q.3-.9.5-1.85-.044-.004 0-.4.032-.388-1.35-.2-1.382.188-1.45.3-.006.154-.05.15 1.05 3.45 1.5 3.45.35 0 .85-1.45m3.95-1.45q-.24-.09-.75-.7-.51-.56-1.7.4 1.35 3.85 1.55 3.35.5-.3.8-1.2.35-.95.6-1.6 0-.1.05-.2-.25-.05-.5-.05h-.05m-14.6.2v-.05q-.2 0-.25.05h-.1q-1.732-1.208-4.85.95.1.05.15 0 .05.4.1.65.1.3.2.7 1.012 3.037 2.6 5.4l.15.15q.25.25.5.25.138 0 .35-.7.156-1.28.35-3.75.2-2.05.8-3.65m1.35-.2h-.05q-.25 0-.5.05.05.1.05.2.25.65.6 1.6.3.9.8 1.2.212.487 1.55-3.35-.9-.654-1.55-.3-.65.404-.9.6m3.55.05q.102.505.4 1.4.5 1.45.85 1.45.45 0 1.5-3.45h-.1l-1.25-.65q-1.257-.632-1.35.05-.101.686-.05 1.2m15.65 26.5q-.25-.9-.55-1.7-.25-.7-.55-1.35-.75-1.85-1.8-3.4-.05-.15-.15-.25-.1-.2-.2-.3-.1-.1-.25-.1h-.05q-.15.1-.35.25l-.05.05q-.1 1.3-.35 4.25-.15 1.5-.45 2.75h.2q.302.05 1.25.7.941.701 1.1.3-.158.46.9-.3 1.052-.753 1.3-.9m-2.6-18.5q.05-.05.15-.1 1.6-2.4 2.6-5.45.1-.4.2-.7.05-.25.1-.65.045.045.1 0-2.145-1.409-4.8-.95h-.1q-.05-.05-.25-.05v.05q.6 1.6.8 3.65.2 2.45.3 3.8.25.65.4.65.25 0 .5-.25z"
                    id="FacMou101__Layer1_0_FILL"
                />
                <path
                    d="M210.6 219.05q.2-.55.05-2.1-.05-1.75-2.8-2.9-1.5-.65-3.8-1.15-6.45-1.4-7.8-1.75-2.8-.85-2.95-2.4 0-.1-.1-1.35-.05-.7-.05-.8v-.2q-.1-2.1-.1-3.85l-.1-1.95-.45-9.95q-.446 10.853-.5 11.9 0 1.75-.1 3.85v.2q0 .1-.05.8-.1 1.25-.1 1.35-.15 1.55-2.95 2.4-1.35.35-7.8 1.75-2.294.476-3.8 1.1-2.735 1.203-2.8 2.95-.15 1.55.05 2.1.4 1.45 2.05 4.05 1.65 2.55 1.65 4.8 0 2.05-1.6 5.6-.55 1.35-.55 2.65 0 1.2.15 1.85.15.6.3.9.8 1.5 1.5 2.3.65.8 1.9 1.35.887.39 1.95.4.47.044.95 0 1.6-.2 3.95-.6l-1.15.25q2.696-.435 6.8-.35.174-.004.35 0 4.113-.082 6.8.35l-1.15-.25q2.35.4 3.95.6.5.05.9.05 1.1-.05 2-.45 1.25-.55 1.9-1.35.7-.8 1.5-2.3.15-.3.3-.9.15-.65.15-1.85 0-1.3-.55-2.65-1.6-3.55-1.6-5.6 0-2.25 1.65-4.8 1.65-2.6 2.05-4.05m-4.95-4.55q.66.207 1.1.5 2.4 1.25 2.4 3.4 0 .75-.2 1.25-.15.3-.5 1.15l-.05.1q0 .05-.05.1-.2.45-.7 1.15-.2.5-.5 1-1.3 2.4-1.3 3.45 0 1.85.95 4.45.85 2.35.95 3.9-.15 1.1-.4 2.15-.15.65-1.25 1.95-.05 0-.05.05-.45.4-.55.5l-.4.3q-.25.15-.55.3-.6.25-1.3.3h-.2q-.4 0-1.9-.3l-.6-.1H200.25q-.15-.05-.3-.05-.35-.05-.65-.1h-.2q-.5-.05-1.05-.05H187q-.55 0-1.05.05h-.2q-.3.05-.65.1-.15 0-.3.05H184.5l-.6.1q-1.5.3-1.9.3h-.2q-.7-.05-1.3-.3-.3-.15-.55-.3l-.4-.3q-.1-.1-.55-.5 0-.05-.05-.05-1.1-1.3-1.25-1.95-.25-1.05-.4-2.15.1-1.55.95-3.9.95-2.6.95-4.45 0-1.05-1.3-3.45-.3-.5-.5-1-.5-.7-.7-1.15-.05-.05-.05-.1l-.05-.1q-.35-.85-.5-1.15-.2-.5-.2-1.25 0-2.128 2.35-3.45.521-.24 1.1-.5.055.016.05 0h.05l-.1.05 4.85-.95h.1q.05-.05.25-.05h.1q.3-.05.65-.1h.05q.25-.05.5-.05h.05q.25-.05.55-.1 1-.1 1.9-.2h.15q.4-.05.85-.1.032-.004.05 0-.004-.102 2.7-.2.01.025 0 .05h.25v.05h.1v-.05h.5q0-.05-.05-.05 2.75.1 2.7.2h.1q.45.05.85.1h.15q.9.1 1.9.2.3.05.55.1h.05q.25 0 .5.05h.05q.35.05.65.1h.1q.2 0 .25.05h.1l4.8.95z"
                    id="FacMou101__Layer1_1_FILL"
                />
                <path
                    d="M202.9 209.7q.25-.15.45-.3 4.3-3.75 4.75-6.65 0-2.65-3.05-3.9-12.05-4-25.05 0-.85.4-1.5.85-1.6 1.15-1.6 3.05.5 3.05 5.3 6.95 2.55 2.15 4.8 3.4.8.45 1.6.8 3.55 1.7 7.9 0 .7-.3 1.55-.8.8-.45 1.75-1.05 1.45-1 3.1-2.35z"
                    id="FacMou101__Layer1_2_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M191.05 219.2q0-.35-.45-.75-.6-.5-1.6-.5-.95 0-1.5.3l-.1.05q-1.056.351-1.9 1.5-1.2 1.705-1.2 3.75 0 2.608.85 3.8.123.016.25 0 .15-.05.3-.05.45-.05.95-.05 2.1 0 3.5.8.6.4 1.6.85.338.085.45.15.174.062.3 0 .115-.054.95-.35.845-.25 1.45-.65 1.4-.8 3.5-.8.5 0 .95.05.15 0 .3.05.15 0 .25.05.85-1.25.85-3.85 0-2.05-1.25-3.75-.8-1.15-1.85-1.5-.05 0-.1-.05-.55-.3-1.5-.3-1 0-1.6.5-.45.4-.45.75 0 .55.55 1.05.05.05.05.1l.1.2q.15.3.15.55 0 1.35-1.8 1.35h-.5q-.098-.005-.2-.05l-.35.05q-1.8 0-1.8-1.35 0-.25.15-.55.04-.116.05-.25.062-.015.1-.05.55-.5.55-1.05z"
                    id="FacMou101__Layer1_3_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.902}
                    d="M184.4 191.15q-.55.15-.55.55 0 .85 2.45 1.45 5.85 1.55 12.45 0 2.4-.6 2.4-1.45 0-.4-.55-.55-.75-.1-3.65-.15h-8.9q-2.9.05-3.65.15z"
                    id="FacMou101__Layer1_4_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou101__Layer8_0_FILL" />
            <use
                xlinkHref="#FacMou101__Symbol_180_0_Layer0_0_FILL"
                transform="translate(179.1 225.2)"
                id="FacMou101__col2n"
            />
            <use
                xlinkHref="#FacMou101__Symbol_179_0_Layer0_0_FILL"
                transform="translate(174.05 210.1)"
                id="FacMou101__col2s"
            />
            <use
                xlinkHref="#FacMou101__Symbol_178_0_Layer0_0_FILL"
                transform="translate(161.95 187.95)"
                id="FacMou101__col1n"
            />
            <use
                xlinkHref="#FacMou101__Symbol_177_0_Layer0_0_FILL"
                transform="translate(162.1 207.4)"
                id="FacMou101__col1d"
            />
            <use xlinkHref="#FacMou101__Layer1_0_FILL" />
            <use xlinkHref="#FacMou101__Layer1_1_FILL" />
            <use
                xlinkHref="#FacMou101__Layer1_2_FILL"
                transform="translate(0 -11.4)"
            />
            <use xlinkHref="#FacMou101__Layer1_3_FILL" />
            <use xlinkHref="#FacMou101__Layer1_4_FILL" />
        </g></g>
    )
}
export default FacMou101
