import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#FFFF00"
}

function AccHat128({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.05 28.4L11.1 0h-1L0 77.25q37.1 3.9 61.5-17.5l-6.85-8.15q-14.5-8.45-19.6-23.2z"
                    id="AccHat128__Symbol_473_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.9 34.5q-2.65 2.2-2.85 5.55-.25 3.3 2.05 5.8 2.4 2.55 5.95 2.8 3.55.2 6.2-2.05 2.7-2.2 2.95-5.5.25-3.35-2.1-5.85-2.4-2.5-5.95-2.7Q7.6 32.3 4.9 34.5M1.55 9.9q1.75 1.85 4.4 2.05 2.65.15 4.6-1.5 2-1.6 2.2-4.1.15-2.5-1.6-4.35Q9.45.15 6.8 0 4.15-.15 2.15 1.45q-2 1.6-2.15 4.1-.2 2.5 1.55 4.35M24.9 7.3q-.5.3-.95.7Q19 12.05 18.6 18.15q-.45 6.15 3.85 10.75 4.4 4.6 10.95 5 6.3.4 11.1-3.4L24.9 7.3z"
                    id="AccHat128__Symbol_357_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat128__Symbol_473_0_Layer0_0_FILL"
                transform="translate(129.65 11.65)"
                id="AccHat128__col1n"
            />
            <use
                xlinkHref="#AccHat128__Symbol_357_0_Layer0_0_FILL"
                transform="translate(139.8 32.75)"
                id="AccHat128__col2n"
            />
        </g></g>
    )
}

export default AccHat128
