import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C67D9A"
}

function SkiWou012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0q5.65 10.3 7.4 14.05.7 1.5 1.3 2.95l.3.7q.1.3.3.65.1.2.2.45.05.2.15.35 2.7 5.35 3.75 9 .25.7.45 1.55 11.95 32.75 14.3 74.4.25 2.3.25 3.9v1.8q-.15 1.85-.3 2.85v1.5q1.6-10.05 1.65-10.75.6-5.25.6-12.55 0-9.2-.15-12.6-.3-6.55-1.4-13.1-2.5-16.05-3.25-18.35-5.1-15-5.35-16.3-1.45-5.1-7.35-14.25Q9 8.85 0 0z"
                    id="SkiWou012__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou012__Symbol_20_0_Layer0_0_FILL"
                transform="translate(195.95 109.05)"
                id="SkiWou012__col1n"
            />
        </g></g>
    )
}

export default SkiWou012
