import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D7A5B9",
    "col1l": "#FAECF2",
    "col1n": "#ECCDDA",
    "col1s": "#A87388",
    "col2d": "#332F54",
    "col2l": "#7973B7",
    "col2n": "#56518C"
}

function LegSki032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M166.95 23.6l-4.2-4.9-8.75-8.65h-.05l-42.65-4.1h-.05q-1.15.1-2.3.25-.05-.05-.1 0-1.05.05-2.1.2-.3 0-.55.05-1.15.05-2.3.15l-4.2.3q-4.7.25-9.35.35-1.05.05-2.15.05-.45 0-.95.05H83.3q-4.35 0-8.7-.15L70 7q-.55-.05-1.1-.05-.6-.05-1.25-.05-2.6-.15-5.15-.35-.2-.05-.4-.05l-1.3-.1q-1.3-.15-2.6-.25l-1-.1q-.9-.1-1.75-.15l-2.5-.3-41.15 6-10 10.6q-.2.3-.55.75Q.15 24.45 0 24.7q.45.55 1.15 1.1.65.55 1.5 1.1.3.25.65.4.05 0 .05.05.2.15.45.3.45.3.85.55 1.4.75 2.45.6h3.45q1.35-.25 3.2-.75.55-.15 1.05-.3 1.75-.5 2.05-.6h2.25q.15.05.35.1 1.45.5 2.4.95.25.1.45.2.2.15.4.25 2.55 1.45 3.6 1.7h3.15q1.5-.3 2.9-.75l1.4-.45q.05-.05.2-.05-1.05 1.2-1.05 1.9 0 .8 1.05 1.5 1.15.75 3.05.8.1-.05.2-.05.3 0 .65-.05 1.85-.1 3.85-.3 1.75-.15 3-.45l.05-.1q.7-.15 2-.55 2.25-.8 2.65-.9l3.2-.05q.15 0 .4.15.5.2 1.45.85.15.05.3.15.1.1.2.15-.1.2-.15.45-.1.35-.25 1-.1.7-.35 1.85.65.85 2.45 1.8.1 0 .2.05h.6q.4-.2 1.1-.3 3.4-.25 4.9-.75h.1q2.4-1 3.7-1.45 1.45-.9 2.75-1.5 1.75-.8 3.35-1.15.5-.2 1-.25h.9q.7-.15 1.4-.25h2.9q1.5.3 4.4 1.45l.7.2.8.3q.8.15 2 .4.8.2 1.45.35 1.75.4 2.55.45h3.35q2.3-.15 4.75-1.2.75-.3 1.5-.7 1.1-.6 2.05-1 .9-.45 1.65-.75.2-.15.3-.2h.15q.65-.25 1.15-.35.15-.1.3-.1h3.25q.85.2 3.75 1.25 1.9.6 3.35.85.25.05.5.05l3.75.1.25.05h3.05l3.8-.55q.5-.3 2.15-.95l.9-.6-2.05-2.15q.65-1.6 2.85-2.2.85-.2 2.3-.3.1.05.2.05h1.5q1.05.35 3.05 1.1.35.15.75.3t.85.3l3.85 1.15 5.65.25h.2q1.25-.05 2.7-.35 0-.05.05-.05 1.85-.5 4.05-1.6 1-.65 1.75-1.05.35-.2.65-.35l.2-.1q.3-.15 1.5-.6.4-.15.65-.3 2.15-.75 4.85-1.2z"
                    id="LegSki032__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M9.5 10.3q-1.25 0-2.3.9-.45.4-2.95 3.1-3.1 3.3-4.25 5.2h1.5q1.35-.25 3.2-.75.55-.15 1.05-.3 1.75-.5 2.05-.6h2.25q.15.05.35.1.3-.6.5-1.05 1.25-2.55 1.25-4.2 0-1-.8-1.7t-1.85-.7m12.65 1.9q-.2-.3-1.1-.3-3 0-7.8 7.2.2.15.4.25 2.55 1.45 3.6 1.7h.95q.8-1.15 1.6-2.5 2.45-4 2.45-5.25 0-.95-.1-1.1M45 0q-.1 0-.25.05l-7.9 7.55-5.5 5.95q-.4.3-.85.7-.1.1-1.8 1.7-.5.45-1.15 1.1h-.05q-.65.65-1.15 1.25-.15.05-.25.2-.1.05-.15.1-.05.05-.1.15-.1.1-.2.15-.1.1-.2.25-.35.35-.55.65-1.05 1.2-1.05 1.9 0 .8 1.05 1.5 1.15.75 3.05.8.1-.05.2-.05.3 0 .65-.05 1.25-1.4 3.4-3.7l-.6.7q1-1.1 2.1-2.4h.05q.05-.1.1-.1L40.9 7.75l4.15-5.45q.4-1 .4-1.45V.3Q45.4 0 45 0m15.05 1.9q-.05-.35-.1-.5-.2-.5-.9-.5-.4 0-.65.25-.1.1-.2.25l-3 6.75-7.6 19.8q.1 0 .2.05h.6q.4-.2 1.1-.3 3.4-.25 4.9-.75h.1L60.05 1.9M90.5 24.3L81.35 2.65q-1-1.35-1.6-1.35-.7 0-.7.65V2l5.05 23.5h1.65q2.3-.15 4.75-1.2m12.85-21.65q-.8-.45-1.65-1.25Q100.25 0 99.3 0q-.45 0-.55.1-.2.15-.2.65 0 .75.45 1.35.05.1.3.4l5.9 6.9 10.2 14.1h.1l3.8-.55q.5-.3 2.15-.95l.9-.6-11.4-12-7.6-6.75m27.5 9.85q-1.7 0-1.7 1.7 0 1.9 1.05 3.7.35.15.75.3t.85.3l3.85 1.15 5.65.25h.2q-2-1.6-3.9-3.1-5.5-4.3-6.75-4.3m14.45-3.15h-.15q-.55-.45-1.25-.45-1.95 0-1.95 2 0 2.85 2.25 8.65 0-.05.05-.05 1.85-.5 4.05-1.6 1-.65 1.75-1.05l-.05-.1.05-.05q-.2-.35-.4-.75-.55-1.05-1.3-2.3-.15-.3-.35-.6-.45-.7-.8-1.25-.15-.25-.25-.4-1.1-1.7-1.5-2-.1-.05-.15-.05z"
                    id="LegSki032__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M162.1 18.9q2.15-.75 4.85-1.2l-4.2-4.9-7.35-7.3v.05q-1.65 2.25-3.55 3.7-.85.6-1.75 1.05-3.9 2-11.4 2-6.25 0-11.85-1.6-4.05-1.15-7.75-3.1-5.7-2.9-10.15-7.3-.05-.05-.1 0-1.05.05-2.1.2-.3 0-.55.05-1.15.05-2.3.15l-4.2.3q-4.7.25-9.35.35-1.05.05-2.15.05-.45 0-.95.05H83.3q-4.35 0-8.7-.15L70 1.1q-.55-.05-1.1-.05Q68.3 1 67.65 1 65.05.85 62.5.65 62.3.6 62.1.6L60.8.5Q59.5.35 58.2.25l-1-.1q-.9-.1-1.75-.15-2.4 3.1-6.55 5.9-4.8 3.25-10.05 4.8Q34.2 12 29.2 12q-11.45 0-15.9-2.85-1.7-1.1-2.4-2.55l-9.1 9.7q-.2.3-.55.75Q.15 18.55 0 18.8q.45.55 1.15 1.1.65.55 1.5 1.1.3.25.65.4.05 0 .05.05.2.15.45.3.45.3.85.55l1.6-1.55q.5-.65.95-1.15 1.45-1.8 2.35-2.85 3.1-3.5 6.8-3.95.5-.05 1-.05 1.5 0 2.55.05 1 0 2.05.65 1 .65-.25 6.35l1.45-1.85q.7-.35 3.6-2.85 2.2-1.85 4-1.85 1.4 0 2 .45.8.6.8 2.25 0 2.1-2.35 5-.75.95-1.85 2.25-.1.1-.2.25l-.85 1h1.15q1.5-.3 2.9-.75l1.4-.45q.05-.05.2-.05.2-.3.55-.65.1-.15.2-.25.1-.05.2-.15.05-.1.1-.15.05-.05.15-.1.1-.15.25-.2.5-.6 1.15-1.25h.05q.65-.65 1.15-1.1 1.7-1.6 1.8-1.7.45-.4.85-.7L45.9 11l8.55-8.2q.4-.1.85-.1 1.35 0 1.8 1 .3.65.3 2 0 1.2-1.25 4.5-.15.4-1.6 2.6L47.3 24.7q-.8 1-2.55 1.75.7-.15 2-.55 2.25-.8 2.65-.9h1.75l6.95-13L53 25.1q.5.2 1.45.85.15.05.3.15.1.1.2.15 0-.15.05-.25v-.1l1.45-4.15 4.4-8.95 5.25-9.1q.25-.15.55-.25 1.2-.45 2.6-.45 2.75 0 4.9 4l.1.2q.45.9.85 1.9l-.9-1.95q1.8 4.4-.85 19.1.5-.2 1-.25h.9q.7-.15 1.4-.25h.45l1.6-14.15.35 14.15h.5q1.5.3 4.4 1.45l-1-11.8q.2-6.35 1.15-8.7 1.35-3.2 5.5-3.2 1.1 0 2.6 1.15l4.6 8.15 6.3 13.2q.9-.45 1.65-.75.2-.15.3-.2h.15q.65-.25 1.15-.35L101.9 9.8l7.15 14.8h.85q.85.2 3.75 1.25 1.9.6 3.35.85l-6.05-8.55q-2.2-4.35-3.2-6.2-1.85-3.6-1.85-6.2 0-1.5.5-2.15.5-.7 1.75-.7.4 0 .8.1L120 12.8l9.35 9.85q.65-1.6 2.85-2.2.85-.2 2.3-.3v-.85-.75q0-3.9 3.7-3.9 2.45 0 6.9 2.35 2.2 1.25 5.4 3.6-.75-3.9-.75-4.9 0-1.65.6-2.9.25-.45.6-.85 1.15-1.2 2.8-1.2 1.55 0 3.7 2.05 1.4 1.6 3.1 4.2.4.55.75 1.1.25.45.55.85.1-.05.25-.05z"
                    id="LegSki032__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M22.65 23.6q6.25-5.7 12.15-12.3L19.1 24.7l3.55-1.1M44.55.3l-2.5-.3L.9 6l-.9.9q.7 1.45 2.4 2.55 4.45 2.85 15.9 2.85 5 0 9.65-1.3Q33.2 9.45 38 6.2q4.15-2.8 6.55-5.9M42.1 25.4l5.1-13.1-6.95 13 1.45-.05q.15 0 .4.15m24.1.65h1.95L67.8 11.9l-1.6 14.15M95.45 25q.15-.1.3-.1h2.4L91 10.1 95.45 25m23.05-2.05q.45-1 .95-1.55l-15-12.5q9.05 9 14.05 14.05m24.6-18.5h-.05L100.4.35h-.05q-1.15.1-2.3.25Q102.5 5 108.2 7.9q3.7 1.95 7.75 3.1 5.6 1.6 11.85 1.6 7.5 0 11.4-2 .9-.45 1.75-1.05 1.9-1.45 3.55-3.7V5.8l-1.4-1.35z"
                    id="LegSki032__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M41.3 8.35q.45-.4.9-.75.75-.55 1.45-1.1h-.2Q41.5 6.25 39.5 6q-.15 0-.3-.05-.55-.05-1.1-.15-1-.15-2.05-.25-.9-.15-1.85-.3l-3.9-.6q-2.4-.4-4.7-.8-.2-.05-.4-.05-1.45-.3-2.95-.55Q21.7 3.1 21.2 3q-.55-.1-1.05-.2-1.65-.35-3.25-.65-1.5-.35-3-.65-.5-.15-1-.25-.3-.05-.55-.1Q10.75.8 9.25.4 8.9.3 8.6.25 7.7 0 6.6 0 4 0 2.1 1.8 0 3.8 0 6.95q0 3 2.4 5.45l.1.1q1.55 1.5 3.85 2.55.1.1.25.15 3.3 1.6 10.85 1.6 6.35 0 10-1.05.85-.25 6.65-2.75 3.2-1.4 7.2-4.65m104.6-2.2q0-3.7-1.7-4.95-1.1-.8-3.65-.8h-.25q-.3-.05-.55-.1l-2.5.6-.8.2-.85.15q-1 .25-2 .45l-.3.1q-.7.1-1.35.3-1.15.2-2.2.45-.75.15-1.45.3-1.55.3-3.05.6-.35.05-.65.1-.35.05-.7.15-1.55.25-3.1.55-.85.1-1.65.25-3.6.6-7.2 1.1l-.5.1-3 .4q-.75.05-1.45.15l-.7.1q-2.15.25-4.25.5-.05-.05-.1 0 .2.1.45.25.6.45 2.1 2l2.6 1.9q2.05 1.5 2.65 1.8 1.45.85 3.85 1.5 9.6 2.6 18.95 2.6 5.3 0 9.15-2.8 1.9-1.4 3-3.15 1.2-2.15 1.2-4.8z"
                    id="LegSki032__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M15.4 12.3h-.1q-3 0-6.4-.7-5.5-1.1-5.5-3.45 0-.95 3-1.3 3.6-.4 4.3-.85-2-.6-3.2-1-2.2-.75-2.2-1.15 0-.65 1.15-1.3Q7.55 1.9 7.7 1.4H6.2q-2.65.75-3.15.95-.05.05-.1.05.05.05.05.1-.75.25-1.35.5-.4.15-.65.35-.6.3-.6.55 0 .3 1.55.9 1.55.6 1.55 1 0 .35-1.75 1.15Q0 7.75 0 8.6q0 2.1 4.7 3.3 3.25.8 6.05.8 3.8 0 4.65-.4M133.9 0h-.8q1.6.35 1.6 1.55 0 .15-.05.3-.2.55-1.2 1.05-3.9 1.8-5.1 2.45.55.5 3.05.6 3.2.1 3.2 2.05 0 2.5-5.7 3.65-3.35.65-6.2.65.55.4 2.55.7 1.6.2 2.8.2 3.55 0 6.75-1.9 3.3-1.95 3.3-4.3 0-.8-2.2-1.5-2.2-.65-2.2-1.1 0-.5 1.9-1.5t1.9-1.65q0-1.05-1.8-1.2-1.8 0-1.8-.05z"
                    id="LegSki032__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M22.95 4.45q1.1-.75 2.35-1.75-.55-.05-1.1-.15-1-.15-2.05-.25-.9-.15-1.85-.3l-3.9-.6Q14 1 11.7.6q-.2-.05-.4-.05Q9.85.25 8.35 0 6.6.9 4.65 1.4q-1.6.4-4.65.75.1.9 2 1.15.6.05 2.9.05 1.65 0 3.8-.35t3.6-.35q1.5 0 1.5 1.4 0 1.9-5.4 3-5.8.75-6.5 1 .5.7 1.95.95 1.4.2 2.15.15h.1q1.3.1 6.5-.8 4.15-.8 5.7-1.5 3.2-1.5 4.65-2.4m89.2 5.2q3.15 0 4.2-.25.55-.15 2.55-1.25v-.2q-4.85.15-9.85-.55l-.8-.1q-1.75-.25-3.15-.6-1.7-.6-1.7-2.1 0-.95 1.65-1.25 1.7.1 4 .4 3.7.5 4.3.5 2.55 0 4.1-.4 1.5-.4.65-2-2.3 0-3.55-.2-1.2-.2-3.85-1.35-.35.05-.7.15-1.55.25-3.1.55-.85.1-1.65.25-3.6.6-7.2 1.1l-.5.1-3 .4q-.75.05-1.45.15l-.7.1q2.7 2.75 6.9 4.4 5.5 2.15 12.85 2.15z"
                    id="LegSki032__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki032__Symbol_98_0_Layer0_0_FILL"
                transform="translate(108.9 307.7)"
                id="LegSki032__col1n"
            />
            <use
                xlinkHref="#LegSki032__Symbol_65_0_Layer0_0_FILL"
                transform="translate(117.95 317)"
                id="LegSki032__col1l"
            />
            <use
                xlinkHref="#LegSki032__Symbol_58_0_Layer0_0_FILL"
                transform="translate(108.9 313.6)"
                id="LegSki032__col1d"
            />
            <use
                xlinkHref="#LegSki032__Symbol_37_0_Layer0_0_FILL"
                transform="translate(119.8 313.3)"
                id="LegSki032__col1s"
            />
            <use
                xlinkHref="#LegSki032__Symbol_31_0_Layer0_0_FILL"
                transform="translate(118.2 306.8)"
                id="LegSki032__col2n"
            />
            <use
                xlinkHref="#LegSki032__Symbol_17_0_Layer0_0_FILL"
                transform="translate(123.5 308.2)"
                id="LegSki032__col2l"
            />
            <use
                xlinkHref="#LegSki032__Symbol_14_0_Layer0_0_FILL"
                transform="translate(132.1 310.05)"
                id="LegSki032__col2d"
            />
        </g></g>
    )
}

export default LegSki032
