import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#EEEEEE",
    "col2n": "#000000"
}

function ObjToy003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M51.7 47.05q5.15-6.3 6.1-14.3.05-.65.1-1.35.05-.5.05-1 .05-.7.05-1.4 0-6.6-2.55-12.1-2.1-4.55-5.95-8.4-4.15-4.15-9.15-6.3-.25-.1-.45-.15Q34.85 0 29 0q-5.4 0-10.1 1.7-5.75 2.1-10.4 6.8-2.95 2.9-4.85 6.3Q0 21.15 0 29q0 3.4.7 6.55.05.2.1.45 1.8 7.6 7.7 13.5 1.8 1.8 3.75 3.2 6.85 4.95 15.65 5.25.55.05 1.1.05 10.95 0 19-7.1l1.5-1.4q1.2-1.2 2.2-2.45z"
                    id="ObjToy003__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M54.75 15.2l-1 1.05q-2.05-1.75-6-3.1-2.5-4.45-8.1-9V.5Q39.4.4 39.2.35V4q-6.35.6-10.85 2.8-4.05-1.75-9.8-2.8.35-2-.35-4-5.75 2.1-10.4 6.8-2.95 2.9-4.85 6.3 2.2-.85 5.15-.05l1.25 10.7Q5.3 28.85 5.45 34q-2.1.35-5.45-.15.05.2.1.45 3.3.5 5.45.1 2.8 5.2 8 9.15-.25 4.85.65 7.45h-2.65q6.85 4.95 15.65 5.25v-.6q6.3-1 10.2-4.3 6.2-.4 9.9-2.15l1.5-1.4q1.2-1.2 2.2-2.45 1.2-2.95 1.25-7.3 2.4-2.1 4.85-7 .05-.65.1-1.35-2.6 5.7-5.2 7.95l-7.3-3.4q-.05-6.2-2.45-12.4l5.45-8.25q3.95 1.3 5.85 3.1 2.75 6.5 3.7 12 .05-.7.05-1.4 0-6.6-2.55-12.1M41.8 22q2.45 6.15 2.45 12.3-3.1 4.3-8.6 8.15-6.95-.75-11.85-3.9-2.1-5.85-3.2-12l9.35-8.7q7.1 1.7 11.85 4.15M28.15 7.2l1.5 10.35-9.35 8.7L9.75 23.6 8.5 12.85v-.1q3.4-3.8 9.95-8.35 5.7 1.05 9.7 2.8M23.6 38.95q4.95 3.2 11.95 3.95L37.1 51q-3.9 3.25-10.1 4.25l-12.3-4.2q-.95-2.55-.7-7.45l9.6-4.65z"
                    id="ObjToy003__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy003__Symbol_97_0_Layer0_0_FILL"
                transform="translate(270 312.05)"
                id="ObjToy003__col1n"
            />
            <use
                xlinkHref="#ObjToy003__Symbol_18_0_Layer0_0_FILL"
                transform="translate(270.7 313.75)"
                id="ObjToy003__col2n"
            />
        </g></g>
    )
}

export default ObjToy003
