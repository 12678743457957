import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#3C1B14",
    "col1n": "#5D342C"
}
function BodHor005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M18.85 72.2q-.3-4.7-8.15-13.65-7.8 8.7-8.25 13.75-.4 5.1.3 5.5 4.4.8 7.95 3.5 3.65-2.7 8-3.5.5-.8.15-5.6M10.65 0Q.75 11.1.2 17.55-.35 24 .55 24.6q5.65 1 10.1 4.4 4.7-3.4 10.25-4.4.65-1.1.2-7.2-.4-6-10.45-17.4m9.4 46.2q-.35-5.4-9.3-15.6-8.9 9.95-9.45 15.7-.45 5.8.35 6.3 5.05.9 9.1 3.95 4.15-3.05 9.1-3.95.55-.95.2-6.4z"
                    id="BodHor005__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.7 58.55q-7.8 8.7-8.25 13.75-.4 5.1.3 5.5 4.4.8 7.95 3.5-.75-3.5-.8-7.8-.15-6.7.8-14.95M10.65 0Q.75 11.1.2 17.55-.35 24 .55 24.6q5.65 1 10.1 4.4-.9-4.4-1-9.9-.15-8.55 1-19.1m.1 30.6q-8.9 9.95-9.45 15.7-.45 5.8.35 6.3 5.05.9 9.1 3.95-.9-3.95-.95-8.9-.15-7.6.95-17.05z"
                    id="BodHor005__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHor005__Symbol_12_0_Layer0_0_FILL"
                transform="translate(181.85 34.9)"
                id="BodHor005__col1n"
            />
            <use
                xlinkHref="#BodHor005__Symbol_13_0_Layer0_0_FILL"
                transform="translate(181.85 34.9)"
                id="BodHor005__col1d"
            />
        </g></g>
    )
}
export default BodHor005
