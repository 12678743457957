import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2d": "#FF0000",
    "col2n": "#FF5D5D"
}

function FacMou072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M207.1 220.8q-3.777-1.12-4.45-1.55-.656-.395-.8-.45-9.25-2.75-18.3-.2-.599.196-1.6.65-1.003.465-5.2 2.2-4.155 1.725-6.3-1.35-2.132-3.023-2.1-3.05-.6-.7-.8-1.35-2.4-.65-5.7-1.95l5.65 6.55q5.54 3.984 10.05 9l1.1.9q12.446 6.255 28.05-.1.088-.05.5-.45l.2-.1q4.495-4.984 9.95-9.2l5.2-6.1q-2.8.9-5.05 1.55-.4.6-1.1 1.35-.052.052-2.8 2.45-2.69 2.388-6.5 1.2z"
                    id="FacMou072__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.75 4.15q-.1-.15.35-.6.45-.5-1.5-1.7Q25.4.6 22.55.4q-2.1-.1-4.65 1.05l-.5.2q-.55.2-1.1.5L14.3 1l.3.2Q11.75-.3 9.1.1 4.9.75.15 5.15q-.1.05-.15.05.2.1 1.55 1.25Q2.55 7.3 3.5 8l-.8-.55q11 8.4 23.85-.6l2.5-2.1-.1-.2-.1-.2-.1-.2z"
                    id="FacMou072__Symbol_130_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.1 7q1.7-3.8 6.15-5.85L4.25 0l.3.2Q-.7 2.6.1 7z"
                    id="FacMou072__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M25 19.5q4.85-7.7 7-16.9h-.1q-.05.05-.15.05-.35.2-.65.3Q26.05 4.85 19.5.1q-.05-.05-.05-.1-.05.05-.05.1-.3 1.1-.6 2.4-.1.35-.2.75-.05.05-.05.15-2.55 8.65-9.5 22.5-2.25 4.45-4.95 9.4-.7 1.25-1.2 2.3-.75 1.3-1.2 2.2-.55 1.4-1.15 2.75v.05q-2.15 4.45 4.5 0 8.3-6.15 15.75-16.7 2.1-3 4.2-6.4m56.95 23.1q6.65 4.45 4.5 0v-.05q-.6-1.35-1.15-2.75-.45-.9-1.2-2.2-.5-1.05-1.2-2.3-2.7-4.95-4.95-9.4-6.8-13.6-9.4-22.15-.05-.25-.1-.45-.15-.4-.25-.8-.3-1.3-.75-2.3 0-.05.05-.1-7.45 4.4-11.45 2.85h-.1q-.45-.05-1-.25 2.15 8.8 7.1 16.85 2.05 3.35 4.15 6.35 7.45 10.55 15.75 16.7z"
                    id="FacMou072__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    id="FacMou072__Layer13_0_1_STROKES"
                    stroke="#00F"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M207.125 220.825q3.784 1.163 6.5-1.225m-43.175.525q2.145 3.05 6.325 1.325"
                />
            </defs>
            <g>
                <use xlinkHref="#FacMou072__Layer13_0_FILL" />
                <use xlinkHref="#FacMou072__Layer13_0_1_STROKES" />
            </g>
            <use
                xlinkHref="#FacMou072__Symbol_130_0_Layer0_0_FILL"
                transform="translate(178.35 224.8)"
                id="FacMou072__col2n"
            />
            <use
                xlinkHref="#FacMou072__Symbol_112_0_Layer0_0_FILL"
                transform="translate(188.4 225.8)"
                id="FacMou072__col2d"
            />
            <use
                xlinkHref="#FacMou072__Symbol_97_0_Layer0_0_FILL"
                transform="translate(148.9 217.05)"
                id="FacMou072__col1n"
            />
        </g></g>
    )
}

export default FacMou072
