import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#D70076",
    "col2s": "#640037"
}

function FacMou084({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fillOpacity={0.4}
                    d="M198.45 233.2q-.45-.15-.95-.25-5.2-1.1-10.45 0-.5.1-.95.25-3.05.85-3.65 1.5.35-.05.7-.05 9.25-1.1 18.45 0 .25 0 .5.05l-.05-.05q-.65-.65-3.6-1.45z"
                    id="FacMou084__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M62 12q-.05-.05-.1-.05-1.5-2.75-3.55-5.25L37.9 0H24.95L4.05 6.55q-.85 1-1.6 2-1.15 1.65-2.1 3.4-.2.1-.35.2l2.8 3.7Q4 17 5 17.75q.1.05.25.1 3.4 1.55 7.75 0l.5-.1q16-3.2 31.35-.65 1.7.25 3.45.65l.5.1q5.3 1.8 7.75 0l.2-.1q1.1-.75 2.25-1.9l3-3.8V12z"
                    id="FacMou084__Symbol_135_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M60.4 9.65q-5.1-1.6-9.35-4.4Q36.1-5.2 21.2 5.25l-1.4.9-2.75 1.5Q9.4 11.2.95 11.35H0l5.15 6.75q6.55-4.45 12.15-6.3 8.6-2.95 19.05-2.95 9.65 0 17.75 2.6 8.05 2.95 12.9 6.4.05.05.15.1l4.9-6.6H69.65q-4.6-.2-9.25-1.7z"
                    id="FacMou084__Symbol_111_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M7.4 14.1q.1.05.25.1 3.4 1.55 7.75 0l.5-.1-4.8-10.2-3.7 10.2M0 1.65L5.55 9.1 8.9.15V0Q5.2 1.25 0 1.65m61.05 7.5l5.55-7.4q-4.8-.45-8.85-1.7l3.3 9.1m-2.1 5.05l.2-.1L55.5 3.9l-4.8 10.2.5.1q5.3 1.8 7.75 0z"
                    id="FacMou084__Symbol_109_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou084__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou084__Symbol_135_0_Layer0_0_FILL"
                transform="translate(161.25 210.95)"
                id="FacMou084__col2n"
            />
            <use
                xlinkHref="#FacMou084__Symbol_111_0_Layer0_0_FILL"
                transform="translate(156.1 205)"
                id="FacMou084__col2s"
            />
            <use
                xlinkHref="#FacMou084__Symbol_109_0_Layer0_0_FILL"
                transform="translate(158.85 214.6)"
                id="FacMou084__col1n"
            />
        </g></g>
    )
}

export default FacMou084
