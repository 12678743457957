import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFE69A",
    "col2n": "#FF9AFF"
}

function ObjFoo022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.5 42.6q6.75-2.35 11-8.45 4.2-6.2 3.3-15.85v1L35.15 0 3.7 6.95l-.1.15Q.5 12.85.05 19.8q-.1 1.75.2 3.45 3.25-.45 7.05.8 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.05.35q4.1 2.15 9.3 3.5 11.25 3.1 20.55-.15M21.4 16.55q.3-.1.5-.2 3.45-.8 8 .5 4.4 1.05 6.95 3.5.2.25.4.35l1.55 1.5-10.65 1.5L20 17.9l1.4-1.35z"
                    id="ObjFoo022__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M43.25 37.75Q49.9 35.5 52.8 31q1.25-1.85 1.75-4 .55-2.05.45-4.15-.15-5.45-4.9-10.95-6.45-7.4-17.65-10.45-11.25-3-20.6.3-8.4 2.95-10.9 9.4-.05.1 0 .2l-.15.3q-.15.4-.25.85-.65 2.2-.55 4.3.3 5.45 4.95 10.8Q11.45 35 22.6 38q11.3 3 20.65-.25M21.2 13.45q3.5-.9 7.85.3 4.45 1.2 7.05 3.6 2.65 2.6 2 4.85-.6 2.25-3.65 3.05-.3.1-.4.15-3.55.85-7.95-.25-4.45-1.15-7.1-3.7-.2-.25-.4-.35-2.2-2.35-1.65-4.5.65-2.4 4.25-3.15z"
                    id="ObjFoo022__Symbol_10_0_Layer0_0_FILL"
                />
                <g id="ObjFoo022__Layer7_0_FILL">
                    <path
                        fill="#FCC5AD"
                        d="M154.95 291.6q-.05.1 0 .5.1.4.7.6.25.05 3.1.25.8-.05 1.9 0 .75-.05.85-.6.35-1.25-3-1.4-3.3-.15-3.55.65M143 288.1q-.15.8-.05 1.1-.1.2-.25 1.3-.05.9.85 1.25.4.1.75-.2.35-.2.35-.4.05-2.7.05-3.15.25-1.45-.55-1.9-.8-.6-1.1.6-.05.3-.05 1.4m18.9-4.45q-.05.35.05.55.1.4.6.55.5.2 2.65-.9 2-1 2.05-1.25.05-.3 0-.45-.05-.55-.55-.75-.55-.05-2.65 1.1-2.05 1-2.15 1.15m-28.7-17.45q-.1.35 1.4 2.1 1.45 1.8 2.2 2 .35.05.65-.15.4-.15.45-.5.2-.6-2.05-2.7-2.3-2.2-2.65-.75m25.75 2.75q0 .2.55 2.6.45 2.6.95 2.6.4.15.75-.15.15-.25.25-.45-.4-4.95-1.2-5.2-1-.2-1.3.6m-37.8 14.55q-.55-.75-.75-1.15-.9-2.1-1.55-2.2-.55-.2-.85-.05-.25.25-.25.45-.05.1.9 2.25 1.2 2.3 1.7 2.5.45 0 .8-.3.35-.15.25-.45.2-.35-.25-1.05m0-19.25v.55q.15.45.85.6.2.1 2.85-.2 1-.15 2.05-.15.6-.15.7-.6.4-1.3-2.85-1.1-3.35.1-3.6.9z"
                    />
                    <path
                        fill="#896FF7"
                        d="M156.55 285.85q.25-.75-.3-1.05-.8-.35-1.65.85l-.3.35q-1.55 1.05-1.95 2.3l.1.5q.1.45.55.55.7.2 1.2-.45.2-.3.35-.55.3-.35.8-.8 1.1-1.3 1.2-1.7m-26.55 7.2q.1-.2.25-.45-1.05-4.75-1.6-5-.95-.3-1.15.6l.5 2.45q.65 2.45 1.2 2.55.4.1.8-.15m14.75-24.75q-.55-1.05-.55-1.3-.15-1.05-.95-1.3-.9-.4-1.2.55-.1.15.7 1.95.85 1.95 1.25 2.1.4.15.8-.15.2-.1.3-.45.05-.3-.35-1.4m-23.85 2.55q0 .25.1.5.05.45.6.65.45.1 2.1-1.4 1.6-1.45 1.7-1.8.15-.75-.5-1.1-.75-.2-1.8 1.05-.15.25-1.2 1.05-.8.7-1 1.05z"
                    />
                    <path
                        fill="#FDED68"
                        d="M149.55 290.05q-1.1-.25-1.2.55-.25.7.2.95.25.25 1.3 1.15.1.05.85 1.25 1.1 1.05 1.5 1.2.4.1.8-.15.25-.25.3-.45.15-.5-.3-1.05-.7-.65-1.2-1.25-1.5-2.05-2.25-2.2m11.6-14.2q-1.7.05-2.15.1-.6-.05-.6.45-.15.25-.05.5.15.4.8.7.6.05 2.55 0 .9.15 1.25 0 .35-.2.45-.6.1-.65-.35-.95-.55-.45-1.9-.2m-35.1 6.35q-.15.65-.3 1.35l-.35 1.25q-.15 1.35.5 1.45.65.3 1.25-.85.25-1.35.3-1.4.25-.7.35-1.25.25-1.3-.55-1.55-.65-.15-1.2 1m16.8-18.55q.45-.2.65-.7.35-1.25-2.6-1-2.9.4-3 1.05-.1.3-.1.55.2.5.7.65.4-.05 1.5-.25.5-.05 1.3-.2.7-.1 1.55-.1z"
                    />
                    <path
                        fill="#6DEAF8"
                        d="M159.1 284.5q-.15.05-.2.1l.95 1.55q.05.05 0 .15.15.2.2.8.45 1 .95 1.2.35.05.75-.2.25-.25.3-.55.1-.4-.2-1.5-.55-1.15-.75-1.5-.3-1.15-1.2-1.65-.9-.55-1.2.5-.1.4.3 1.05l.1.05m-25.75 5.35q-.1.55.35.8.4.35 2.2 1.05.45 0 3.9 1.5 1.05.25 1.25-.5.15-.65-.55-1.1-1.7-.55-3.1-1.2-2.35-1.05-3.15-1.1-.7-.05-.9.55m-2.8-27.7q-.85 0-1 .9-.2.5-.15 1.95.05 2 .7 2.15.75.2 1.05-.7 0-.2.05-2.7 0-1.5-.65-1.6m19.45 9.3q.25.5.65.6.25.05 2.2-.8 1.95-.85 2.1-1.1.4-1.6-2.25-.8-2.4.8-2.7 1.7v.4m-32 5.65q1.05 0 1.45.1 2.15.5 2.3-.5.4-1-2.45-1.4-2.8-.2-3.1.75v.35q.1.55.85.75.1-.05.95-.05z"
                    />
                    <path
                        fill="#97F9C7"
                        d="M155.65 276.9q-.05-.55-.15-.8-.1-.45-.25-.75-.6-.5-1.05-.3-.4.2-.5.6-.15.25.4 2.45.65 2.45 1.15 2.45.5.15.85-.15.15-.25.25-.45.1-.4-.7-3.05m-28.1-4.45q-.15.45-.2 1.15 0 .85-.05 1.2-.2.7-.25 1.2.1.85.8 1.2.5 0 .75-.3l.25-.5q.2-.4.2-1.55 0-1.25.15-1.55.1-1.75-.8-1.75-.6 0-.85.9m10.1 11.6q-1.05-.3-3.35.3-2.35.45-2.35.9-.3.95.55 1.15.55.15 3.1-.45.9-.15 1.9-.3.65-.1.75-.5.1-.95-.6-1.1m12.8-20.25l-2.55-.3q-.6.05-.7.6v.45q.2.45 1.2.7.6.2 1.45.25.7.05 1.25.1 1.95.55 2.15-.35.2-.6-.25-.85-.6-.5-2.55-.6z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo022__Symbol_91_0_Layer0_0_FILL"
                transform="translate(111.45 263.15)"
                id="ObjFoo022__col1n"
            />
            <use
                xlinkHref="#ObjFoo022__Symbol_10_0_Layer0_0_FILL"
                transform="translate(114.25 258.6)"
                id="ObjFoo022__col2n"
            />
            <use xlinkHref="#ObjFoo022__Layer7_0_FILL" />
        </g></g>
    )
}

export default ObjFoo022
