import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#86DE00",
    "col1n": "#9AFF00",
    "col1s": "#5F980C",
    "col2d": "#C40000",
    "col2n": "#FF0000",
    "col2s": "#770707"
}

function ObjMor016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.35 40.5l-7.6 4.4v2H23.8l1.85 3.25 9.95-5.75-2.25-3.9M0 5.75l2.25 3.9L12.2 3.9 9.95 0Q4.25.25 0 5.75z"
                    id="ObjMor016__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.6 8.65L0 0l1.7 14.35-.05.1L11.6 8.7v-.05z"
                    id="ObjMor016__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M23.5 41l7.6-4.4-6.75-11.7q-.4-.1-.8-.25Q19 32.85 23.5 41M12.05 3.6L9.95 0 0 5.75l4.15 7.15h.1q4.45 0 7.8 2.85 3.55-1.75 0-12.15z"
                    id="ObjMor016__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.5 48.55q-5.35-6.4-11.5.05v4.5h11.5v-4.55M.05 6.3q6.5 5.4 11.45 0V1.8Q5.8-1.8.05 1.85V6.3z"
                    id="ObjMor016__Symbol_30_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M5.75 0L0 13.4l11.45-.05.05-.05L5.75 0z"
                    id="ObjMor016__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M.05 19.55q.5.45 1 .95 3.65 3.85 3.65 9.25t-3.65 9.2q-.5.55-1.05 1v2.35l11.5-.05.05-13.8q-1.45-.55-2.6-1.7-2.1-2.1-2.1-5.1 0-3 2.1-5.15 1.15-1.15 2.6-1.65L11.5 0H.05v19.55z"
                    id="ObjMor016__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor016__Symbol_126_0_Layer0_0_FILL"
                transform="translate(103.15 269.85)"
                id="ObjMor016__col1n"
            />
            <use
                xlinkHref="#ObjMor016__Symbol_69_0_Layer0_0_FILL"
                transform="translate(101.5 261.15)"
                id="ObjMor016__col1d"
            />
            <use
                xlinkHref="#ObjMor016__Symbol_54_0_Layer0_0_FILL"
                transform="translate(105.4 273.75)"
                id="ObjMor016__col1s"
            />
            <use
                xlinkHref="#ObjMor016__Symbol_30_0_Layer0_0_FILL"
                transform="translate(117.4 263.65)"
                id="ObjMor016__col2n"
            />
            <use
                xlinkHref="#ObjMor016__Symbol_6_0_Layer0_0_FILL"
                transform="translate(117.45 252.1)"
                id="ObjMor016__col2d"
            />
            <use
                xlinkHref="#ObjMor016__Symbol_3_0_Layer0_0_FILL"
                transform="translate(117.4 269.95)"
                id="ObjMor016__col2s"
            />
        </g></g>
    )
}

export default ObjMor016
