import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.7 6.5q-1.05-.7-1.7-1.25-7.6-6.35-15.25-5-.2 0-.5.1-.1 0-.3.1-1.65.5-2.95 1.2-.6.3-.85.5-.3-.2-.85-.5-1.3-.7-2.95-1.2-.2-.1-.3-.1-.3-.1-.5-.1-7.7-1.35-15.3 5-.6.5-1.35 1.05l-.9.65L2.55 8.6l19.35 2.75 19.3-2.9 3.1-1.5-.6-.45z"
                    id="FacMou040__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M44.4 0q-.1.05-2.75.55t-5.85.95q-.3 0-.6.05-13.1 1.6-25.85.05-.3-.05-.65-.1-3.2-.45-5.85-.95Q.2.05.1 0L0 .1q2.85 3.15 5.1 4.65Q7.3 6.2 9 6.95 15.5 8.6 22.15 8.7h.2Q29 8.6 35.5 6.95q1.7-.75 3.9-2.2 2.25-1.5 5.1-4.65l-.1-.1z"
                    id="FacMou040__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou040__Symbol_65_0_Layer0_0_FILL"
                transform="translate(170.55 214.55)"
                id="FacMou040__col1n"
            />
            <use
                xlinkHref="#FacMou040__Symbol_18_0_Layer0_0_FILL"
                transform="translate(170.45 221.5)"
                id="FacMou040__col1l"
            />
        </g></g>
    )
}

export default FacMou040
