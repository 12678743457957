import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col2n": "#8FAFCD"
}
function BeaAni004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M241.25 1.6l-.1-.05q-3.906-.9-22.45-4.85-18.493-3.897-30 7.5-2.581 2.586-3.75 4.5-4.104 4.009-13.35 10.25.863 1.913-3.65 2.35-4.51.49-12.55.95-7.997.453-9.85.05-.298-.103-11.4-4.75-11.105-4.64-24.4 0-13.295 4.64-14.35 4.9-1.056.258-6.65-.8-5.536-1.02-12.2-.85-6.668.224-7.9-4.1Q51.77 1.106 50.15 1.4 40.132-7.328 22.9-3.5 4.505.646.6 1.55l-.1.05H.35q4.85 1.05 7.6 2 2.85.95 6 2.75Q7.5 6.9 0 11.9l3.85.4q7.35.55 12.3 5.65 4.9 5.1 8.85 9.7 2.45 2.9 4.55 4.1.2.1.45.25.25.1 2.2 1.35.05 0 .1.05-2.55.5-7.25 1.75 2.85.9 3.7 1.05.8.15.9.2.1 0 .4.1 2.8.9 8.05 2.65 5.25 1.7 11.75 5.15t8.6 5.05q2.1 1.55 2.2 1.65l.2.1v.05l.75.5q.65.4 1.5.95l.4.3.85.65q.3.25.65.5l.8.6-.3-.25 1.85 1.35q.1.05.15.1.2.15.45.25.15.15.35.25l.1.1q.8.5 1.6 1.05 8.1 5.1 16.9 8.4 12.4 4.6 26.25 5.65l1.1.05q2.15.15 4.3.2h4.65q2.15-.05 4.3-.2l1.1-.05q13.85-1.05 26.25-5.65 8.8-3.3 16.9-8.4.8-.55 1.6-1.05l.1-.1q.2-.1.35-.25.25-.1.45-.25.05-.05.15-.1l1.85-1.35-.3.25.8-.6q.35-.25.65-.5l.85-.65.4-.3q.85-.55 1.5-.95l.75-.5v-.05l.2-.1q.1-.1 2.2-1.65 2.1-1.6 8.6-5.05 6.5-3.45 11.75-5.15 5.25-1.75 8.05-2.65.3-.1.55-.2l.4-.1q.4-.1 4.05-1.05-4.75-1.25-7.3-1.75-.35-.1-.6-.15.2-.1.45-.2.8-.35 1.45-.65 2.7-.8 6.05-4.75 3.95-4.6 8.85-9.7 4.95-5.1 12.3-5.65l3.85-.4q-7.5-5-13.95-5.55 3.15-1.8 6-2.75 2.75-.95 7.6-2h-.15M47.85 14.75v.05h-.15q.078-.01.15-.05z"
                    id="BeaAni004__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M230.7 23.7q-.05-.05-.15-.1-3.5-2.45-6.15-5.4-1.25-1.45-2.35-3-.85-1.3-1.6-2.7-2.2-4.2-3.4-10.15-.15-.65-.3-1.35V.95q0-.2-.05-.95-1.95 7.7-7.4 12.35-3.2 2.75-6.7 4.15-.4.25-1.25.45-.1-1.45-.6-5.6-.55-4.5-1-4.3L200 5.7q-3.25 9.1-9.05 15.3-5.7 6.2-7.2 7.3.55-.85-1.6-5.7-4.6 10.65-10.95 16.75-3.9 3.8-9.7 5.4-3.55.95-7.75 1.1h-2q-1.8-.15-3.9-.45-5.8-.85-8.9-2.2-3.75-1.65-7.35-3.35-7.15-2.75-10.75-2.55h-.15q-3.6-.2-10.75 2.55-3.6 1.7-7.35 3.35-3.1 1.35-8.9 2.2-2.1.3-3.9.45h-2q-2.2-.1-4.2-.35-8.2-1.25-13.25-6.15Q64 33.25 59.4 22.6q-2.15 4.85-1.6 5.7-1.5-1.1-7.2-7.3-5.8-6.2-9.05-15.3l.25 1.35q-.45-.2-1 4.3-.5 4.15-.6 5.6-.85-.2-1.25-.45-3.5-1.4-6.7-4.15Q26.8 7.7 24.85 0q-.05.85-.05 1-1.25 6.85-3.7 11.5-.75 1.4-1.6 2.7-1.1 1.55-2.35 3-2.65 2.95-6.15 5.4-.1.05-.15.1-2.75 1.85-6.6 3.65-2 .85-4.25 1.75h.25l-.1.05H.4l.1-.05q3.9-.9 6-1.1 20.7-2.75 37.4 11.4 1.8 1.4 3.7 2.95h.15q.5-2.25-2.3-5.7l-.1-.2q.1.05.15.1Q57.8 38 68.3 48q.8 1.2 5.15 3.15 4.35 1.9 12.6.5 8.2-1.4 10.05-1.8.25-.1.55-.15 24.8-6.25 48.2 0 .3.05.6.15 1.85.4 10.05 1.8 8.25 1.4 12.6-.5 4.35-1.95 5.15-3.15 10.5-10 22.8-11.45.05-.05.15-.1l-.1.2q-2.8 3.45-2.3 5.7h.15q1.9-1.55 3.7-2.95 16.7-14.15 37.4-11.4 2.1.2 6 1.1l.1.05h.25l-.1-.05h.25q-2.25-.9-4.25-1.75-3.85-1.8-6.6-3.65M121.3 43.4q.05 0 .05.05h-.05v-.05m-.6.1v-.05q0-.05.1-.05.05 0 .05.05v.05h-.15m-.45-.1v.05h-.05q0-.05.05-.05z"
                    id="BeaAni004__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaAni004__Symbol_4_0_Layer0_0_FILL"
                transform="translate(71.65 177.7)"
                id="BeaAni004__col1n"
            />
            <use
                xlinkHref="#BeaAni004__Symbol_5_0_Layer0_0_FILL"
                transform="translate(71.75 150.15)"
                id="BeaAni004__col2n"
            />
        </g></g>
    )
}
export default BeaAni004
