import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiLon003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M169.7 72.7q7.15-2.25 14.35-5.6 6-2.85 12.25-6.05 3.65-1.9 6.85-4.45.05-.1.2-.15.45.8.95 1.65 1.9 2.55 3.55 5.2 9.15 17.65 9.75 35.95.1 2.45.05 4.95-.05.15-.05.35-.05 1.3-.05 2.55l-.15 2.25q-1.1 16.6-7.2 30.65l-2.6 5.25-.8 1.4-6.25 9.65q-.15.15-.3.35L192.3 166q-.9.95-1.8 1.85-1.6 1.6-3.2 3.1-2.25 2.05-4.5 3.95 3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5l1.1 2q.75 1.45 1.5 3l.65 1.25.3.7q.05.1.1.25.05.1.15.25.5 1.1 1 2.25l.8 1.9q.15.45.45 1.45v.05l.1.2q.9 2.2 1.75 4.4.15.5.35 1l.1.2.4 1.2q0 .05.05.1l.4-.1v.05q10.3-1.35 20.8-6.4 12.9-6.95 16-17.1-19.9 2.45-13.45-25.9 15.8-45.05 4.55-87.85-5-20-16.35-35.85-18-23.05-45.1-30.4-27.95-7.25-61.65 0Q94.3 5.6 85.2 8.75 38.65 24.7 20.35 71q-.15.35-.25.7-1.75 3.75-3 8.2-6.15 22.1.3 60.6.1.6.15 1.2 3.75 29.85-14.7 30.4-1.35 0-2.85-.1 1.35 2.05 2.85 4.05 8.65 11.45 21.8 19.5 4.8 2.95 10.2 5.45 5.35 2.45 11.25 4.5l.55.2q.05-.05.05-.1h.25l.3-.9q.85-2.2 1.75-4.4l.1-.2v-.05q.3-1 .45-1.45l.8-1.9q.5-1.15 1.05-2.25.05-.15.1-.25.05-.15.1-.25l.3-.7.65-1.25q.75-1.55 1.5-3l1.1-2q1.3-2.3 2.7-4.5.15-.25.3-.45 1.4-2.2 4.7-7.1-2.35-1.9-4.6-4-1.65-1.5-3.25-3.1-19.45-19.45-25.4-44.5-4-18.4 1-39.2l1.7-5.25q.6-1.7 1.35-3.35 7.7-.05 15.25-.95 9.8-1.2 19-3.55 1.4-.35 2.85-.45-6.85 5.7-15.8 8.6v.1q4.8-.3 9.45-.7 5.55-.45 11-1.2 7.35-1.05 14.5-2.6 6.25-1.35 12.3-3 1.7-.45 3.35-.95 8.25-2.4 15.5-6.5-4.35 8.25-14 9.7-2.4.35-4.85.7-.85.15-1.75.25.85.15 1.75.3 3.9.55 8.75.35 12.35-.6 24.05-3.5 6.55-1.65 13.1-3 4.9-1 8.9-2.7-2.65 7.3-9.6 9.95v.1q10.3 0 22.3-3.8z"
                    id="HaiLon003__Symbol_7_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M27.65 51.05q-2.2 22.7-2.05 38 .1 11.35 1.5 18.65 3.15 16.2-24.45 12.35-1.3-.2-2.65-.4 1.2 2.1 2.65 4.05 6.6 9.15 17.8 16.35 7.35 4.7 14.2 8.1 5.8 2.8 11.25 4.7l.55.2q.05-.05.05-.1h.25l.3-.9q.85-2.2 1.75-4.4l.1-.2v-.05q.3-1 .45-1.45l.8-1.9q.5-1.15 1.05-2.25.05-.15.1-.25.05-.15.1-.25l.3-.7.65-1.25q.75-1.55 1.5-3l1.1-2q1.3-2.3 2.7-4.5.15-.25.3-.45 1.4-2.2 4.7-7.1-2.35-1.9-4.6-4-1.65-1.5-3.25-3.1-19.45-19.45-25.4-44.5-2.05-9.5-1.75-19.65m21.05-29.1q9.8-1.2 19-3.55 1.4-.35 2.85-.45 2.8-2.1 5.55-5.6-20.7 7.6-43.15 10.45l.5.1q7.7-.05 15.25-.95m41 .2q6.25-1.35 12.3-3 1.7-.45 3.35-.95 8.25-2.4 15.45-6.45l2.65-5q-30.35 15-59.25 19.2 5.55-.45 11-1.2 7.35-1.05 14.5-2.6m58.05-5.1q4.95-1.55 8.95-3.2.05 0 .1-.05l1.75-4.45q-21.6 9.7-43.2 13.35 10.1-.75 18.75-2.45 8.6-1.7 13.65-3.2M210 87.35l-2.6 5.25-.8 1.4-6.25 9.65q-.15.15-.3.35l-7.95 9.35q-.9.95-1.8 1.85-1.6 1.6-3.2 3.1-2.25 2.05-4.5 3.95 3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5l1.1 2q.75 1.45 1.5 3l.65 1.25.3.7q.05.1.1.25.05.1.15.25.5 1.1 1 2.25l.8 1.9q.15.45.45 1.45v.05l.1.2q.9 2.2 1.75 4.4.15.5.35 1l.1.2.4 1.2q0 .05.05.1l.4-.1v.05q10.3-1.35 20.8-6.4 13.2-6.8 16-17.1-25.85 7.6-22-20.6 3-18.7 3.1-55.95l-.15 2.25q-1.1 16.6-7.2 30.65m-7.05-83.4h.05l-.05-.05L200.9 0Q189 14.55 156 23.2q6.45-.9 13.5-3.15 7.15-2.25 14.35-5.6 6-2.85 12.25-6.05 3.65-1.9 6.85-4.45z"
                    id="HaiLon003__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M15 157.5q0-.2-.15-.35-.15-.15-.35-.15-.2 0-.35.15-.65.65-.7 1.35Q11.65 172 .4 175.05q-.2 0-.3.2-.15.2-.05.4 0 .15.2.3.2.1.4.05 11.9-3.2 13.8-17.35v-.05q.05-.4.4-.75.15-.15.15-.35M81.4 14.7q-.15-.2-.35-.2-.2 0-.35.15-2.2 1.85-4.45 3.6-23 17.9-54.4 24.75-.2.05-.3.25-.15.15-.1.35.05.2.25.35.15.1.35.05 32.3-7.05 55.8-25.75 1.75-1.4 3.5-2.85.15-.15.15-.35 0-.2-.1-.35m27.5-6.65q-.2 0-.3.2-.15.2-.05.4 1.15 4.75-.1 9.6-1.4 5.3-5.65 10.7-8.25 10.4-27.05 21.35l-.5.3q-.15.1-.2.3-.1.15.05.35.1.2.3.25.15.05.35-.05 19.4-11.2 27.85-21.9 4.5-5.7 5.9-11.3 1.2-5 0-9.85-.05-.2-.25-.3-.2-.15-.35-.05m40.85 4.4q-.65-2.45-1.9-4.4L147.8 8q-4.2-6.75-14.85-7.85h-.1q-.9-.1-1.8-.15-.25 0-.35.15-.2.1-.2.35 0 .2.15.35.1.15.35.15 2.95.15 5.4.7h.05q5.7 1.3 9 4.8v.05q2.3 2.5 3.35 6.15 3 11.1-5.7 32.6-.1.2 0 .4.05.2.25.3.2.05.4-.05.2-.05.3-.25 8.9-21.9 5.7-33.25m30.55 150q-.05-.25-.2-.35-.15-.15-.35-.1-.1 0-.2.05t-.15.15q-.15.15-.1.4.1.6.25 1.25 1.95 8.8 10.15 15.8.15.1.35.1.2 0 .35-.15.1-.2.1-.4t-.15-.3q-8.45-7.25-10.05-16.45z"
                    id="HaiLon003__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiLon003__Symbol_7_0_Layer0_0_FILL"
                transform="translate(69.7 53.7)"
                id="HaiLon003__col1n"
            />
            <use
                xlinkHref="#HaiLon003__Symbol_6_0_Layer0_0_FILL"
                transform="translate(69.9 106.35)"
                id="HaiLon003__col1d"
            />
            <use
                xlinkHref="#HaiLon003__Symbol_5_0_Layer0_0_FILL"
                transform="translate(96.1 64.75)"
                id="HaiLon003__col1s"
            />
        </g></g>
    )
}

export default HaiLon003
