import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00",
    "col2d": "#D23900",
    "col2l": "#FF8220",
    "col2n": "#FF5400"
}
function AccHat165({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M202.9 9.85l-.05-.05-14.2-9.8H24.9L10.7 9.8l-.05.05-.95.6v.05l-6.75 4.65-.05.05-.25.15q-.15.15-.25.3-.1.05-.2.15l-.1.1Q.8 17.3.25 18.55q-2.4 6.3 17.65 10.85 7 1.4 14.05 2.55 36.25 5.95 72.1 5.55 1.15 0 2.25.05h.95q1.1-.05 2.25-.05 35.85.4 72.1-5.55 7.05-1.15 14.05-2.55 20.05-4.55 17.65-10.85-.55-1.25-1.85-2.65l-.1-.1q-.1-.1-.2-.15-.1-.15-.25-.3l-.35-.2-6.75-4.65v-.05l-.9-.6z"
                    id="AccHat165__Symbol_545_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M191.4 3.4q-.25.2-.55.35Q186.5 6.5 176.3 8.8q-7 1.35-14.05 2.55-3.65.55-7.35 1.2-25.8 3.3-49.85 3.65H104.1q-24.15-.35-49.9-3.65-3.65-.65-7.3-1.2-7.05-1.2-14.1-2.55-10.15-2.3-14.55-5.05-.3-.15-.5-.35l-1 .7q.25.2.55.35 4.3 2.85 14.75 5.2 7.05 1.4 14.05 2.55 29.15 4.8 58 5.05h.95q28.9-.25 58-5.05 7-1.15 14.05-2.55 10.45-2.35 14.75-5.2.3-.15.55-.35l-1-.7M187.45.75l-1-.75Q182 2.45 172.7 4.55q-7.05 1.35-24.25 3.65-16.85 2.2-43.4 2.85h-.95Q77.55 10.4 60.65 8.2 43.5 5.9 36.45 4.55 27.15 2.45 22.7 0l-1 .75q4.45 2.5 13.95 4.65Q42.7 6.8 60.1 9.25q17.1 2.3 44 2.9h.95q26.9-.6 44-2.9 17.4-2.45 24.45-3.85Q183 3.25 187.45.75M197 7.3l-1-.65q-3.8 3.4-16.1 6.2-7.05 1.35-14.1 2.55-30.45 4.95-60.75 5.55h-.95v-.05q-30.25-.5-60.75-5.5-7.05-1.2-14.1-2.55-12.3-2.8-16.1-6.2l-1 .65q3.7 3.55 16.35 6.4 7 1.4 14.05 2.55 30.9 5.1 61.55 5.6H105.05q30.6-.5 61.55-5.6 7.05-1.15 14.05-2.55 12.6-2.85 16.35-6.4m4.6 3.2v-.05l-.9-.6q-3.05 4-17 7.15-7.05 1.35-14.1 2.55-32.4 5.3-64.55 5.6h-.95q-32.15-.3-64.6-5.6-7-1.2-14.05-2.55-14-3.15-17-7.15l-.95.6v.05q2.85 4.1 17.15 7.4 7.05 1.35 14.1 2.5 32.8 5.45 65.35 5.65h.95q32.5-.25 65.35-5.65 7.05-1.15 14.05-2.5 14.35-3.3 17.15-7.4m4.1 2.8l-.85-.6q-1.85 4.65-17.65 8.2-7 1.35-14.05 2.55-34.2 5.6-68.1 5.6h-.95q-33.85 0-68.1-5.6-7.05-1.2-14.05-2.55-15.8-3.55-17.65-8.2l-.85.6q1.55 4.8 17.7 8.5 7.05 1.35 14.05 2.55 34.65 5.7 68.9 5.6h.95q34.3.05 68.85-5.6 7.05-1.2 14.1-2.55 16.15-3.7 17.7-8.5m3 2.05l-.35-.2q0 .15-.05.35-.05.75-.45 1.5-2.55 4.3-17.35 7.65-7.05 1.4-14.1 2.55-35.85 5.9-71.35 5.6h-.95q-35.5.3-71.35-5.6-7.05-1.15-14.1-2.55Q3.85 21.3 1.3 17q-.45-.75-.5-1.5-.05-.2-.05-.35l-.05.05-.25.15q-.15.15-.25.3-.1.05-.2.15v.55q.05.5.25 1 1.9 4.65 17.6 8.2 7.05 1.35 14.1 2.55 36.25 5.95 72.1 5.55h1.05q35.85.4 72.1-5.55 7.05-1.2 14.05-2.55Q207 22 208.9 17.35q.2-.5.25-1v-.55q-.1-.1-.2-.15-.1-.15-.25-.3z"
                    id="AccHat165__Symbol_546_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M163.75 50.9l-.3-.2-7.85-15.5-.05-.15-7.7-15.25-.05-.05q-3.8-7-11-11.35-8.1-4.9-20.45-6.55-34-3.7-68.95 0-11.6 1.55-19.45 6-7.95 4.45-12 11.9l-.05.05-7.75 15.25v.1L.3 50.7l-.3.2q4.45 2.45 13.75 4.55 7.05 1.35 24.2 3.65 16.9 2.2 43.45 2.85h.95q26.55-.65 43.4-2.85 17.2-2.3 24.25-3.65 9.3-2.1 13.75-4.55z"
                    id="AccHat165__Symbol_547_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M163.75 20l-.3-.2-7.85-15.5-.05-.15-2-3.95Q148.8 4.6 131 7.7q-49.05 8-98.05 0-18.2-3.15-22.8-7.7l-2 4.15v.1L.3 19.8 0 20q4.45 2.45 13.75 4.55 7.05 1.35 24.2 3.65 16.9 2.2 43.45 2.85h.95q26.55-.65 43.4-2.85 17.2-2.3 24.25-3.65 9.3-2.1 13.75-4.55z"
                    id="AccHat165__Symbol_548_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M105 0q-1.05 1.35-13.7 3.9-16 3.25-38.65 3.25T14 3.9Q2.8 1.65 0 .25-.45 3.4 14 6.3q16 3.25 38.65 3.25T91.3 6.3q15.1-3 13.7-6.3z"
                    id="AccHat165__Symbol_549_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat165__Symbol_545_0_Layer0_0_FILL"
                transform="translate(85.9 96.05)"
                id="AccHat165__col1n"
            />
            <use
                xlinkHref="#AccHat165__Symbol_546_0_Layer0_0_FILL"
                transform="translate(88.1 96.05)"
                id="AccHat165__col1d"
            />
            <use
                xlinkHref="#AccHat165__Symbol_547_0_Layer0_0_FILL"
                transform="translate(110.8 45.15)"
                id="AccHat165__col2n"
            />
            <use
                xlinkHref="#AccHat165__Symbol_548_0_Layer0_0_FILL"
                transform="translate(110.8 76.05)"
                id="AccHat165__col2l"
            />
            <use
                xlinkHref="#AccHat165__Symbol_549_0_Layer0_0_FILL"
                transform="translate(140.4 58.2)"
                id="AccHat165__col2d"
            />
        </g></g>
    )
}
export default AccHat165
