import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 9.5h31.5V0H0v9.5z"
                    id="BeaMus007__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus007__Symbol_24_0_Layer0_0_FILL"
                transform="translate(176.75 201.1)"
                id="BeaMus007__col1n"
            />
        </g></g>
    )
}

export default BeaMus007
