import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#2D4A00",
    "col1l": "#61A300",
    "col1n": "#4C7E00"
}

function AccHat144({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M177.15 47q-3-5-6.35-9.45-18.65-24.6-48.4-31.8-4.6-1.1-11.65-3.3l-.15-.05q-.05-.05-.15-.05-16.15-5.4-35.9 1.6l-1.9.6q-1.6.35-3.15.75-.85.2-1.65.4-24.75 6.4-41 18.55-3.35 2.45-6.3 5.2-.75.7-1.45 1.4-5.4 7.35-12.65 17.7Q1 56.25.25 61.25l-.1 1q-1.45 10.6 13.3 13.5 4.9 1.4 10.1 1.4 2.55 0 6.6-.45.3-.05.65-.1 4.85-.55 11.75-1.8 13.25-2.4 34.6-2.4 23.25 0 40.35 2.95 14.25 2.5 29.05 8.05 5.6 2.05 12.95 6.35 6.6 3.85 10.3 6.9 1.85 1.55 9 4.85 2 .95 3.75 1.65-.05.55-.05 1.2v2.05q.05 1.1.4 2.25.15.45.45 1 .5 2 1.75 2.75.25.25.55.45.05.05.35.25.25.1.7.4-.3 1.8-1.45 8-.95 4.9-.95 9.25 0 6.8 5.1 20.5-.05.4-.05.8.15-.25.15-.55.2.55.4 1.15 6.25 16.4 11.5 16.4 2.6 0 3-13.6-.05-5.55-.05-9.15l-.1-2.4q-.1-.9-.15-1.75-.55-6.6-1.1-12.75.05-.05.1-.05 2.6 3.45 5.25 7.15l7.1 10.2q4.1 5.75 11.15 8.05 2.9-5.55-.55-12.15-2.9-5.65-6.1-11.3-3.05-5.6-6.5-11.05-3.4-5.25-5.2-7.7-1.85-2.45-3.75-4.95-1.95-2.55-3.9-5.3-.95-1.35-2.2-2.45.15-.9.15-1.85 0-2.55-3.8-8.4-1.8-2.7-3.2-4.5-.4-.5-.75-.95-2-15.2-8.5-28.25-2.3-4.6-5.15-8.9z"
                    id="AccHat144__Symbol_486_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M103.75 6.45q9.8 2.7 10.4.05.5-2.7-3.4-4.05l-.15-.05q-.05-.05-.15-.05-16.15-5.4-35.9 1.6l-1.9.6q-1.6.35-3.15.75-.85.2-1.65.4-24.75 6.4-41 18.55-3.35 2.45-6.3 5.2-.75.7-1.45 1.4-5.4 7.35-12.65 17.7Q1 56.25.25 61.25l-.1 1q-1.45 10.6 13.3 13.5 4.9 1.4 10.1 1.4 2.55 0 6.6-.45.1-.1.2-.15-3.85-.65-4.8-1.2-1.85-1.15-1.85-4.8 0-6.1 12.8-9 9-2 21-2 1 0 4.55.4H81.5q5.6.35 10.25.8 5.2.55 12.3 1.1 1.05-.65 1.05-1.75 0-.15-1.05-.9-1.95-.4-4.85-.8-1.1-.15-2.05-.3h-4.1q-7.05-.95-11.6-1.55-8.4-1.1-14.05-1.55-17.35-1.5-18-1.6-3.9-.65-3.9-3.3 0-3 14.3-3.75 7.45-.4 9.85-.75 4.45-.65 4.45-2.25 0-2.65-5.6-3.3l-5.95-.7q-.3-.1-.6-.15-6.25-1.3-7.85-1.65-5.3-1.15-8-2.45-3.4-1.7-3.4-3.85 0-3.3 11.2-6.7 3.15-1.05 3.65-5.05.6-4.65 1.35-5.35Q63.85 9.4 74.15 7.2q7.6-1.65 15.95-1.65 2.45 0 4.8.25 4.65-.5 8.85.65m91.9 91.5q-1.85-1.05-4.05-1.7h-3.1q-4.05 1.3-5.3 3.75-.2.4-.35.95-.25.9-.3 2.2-.05.55-.05 1.2v2.05q.05 1.1.4 2.25.15.45.45 1 .5 2 1.75 2.75.25.25.55.45.05.05.35.25.25.1.7.4.05.05.15.1 0 .05.05.15.85.6 2.45 1.6 1.15.7 2.05 1.2.25.25.55.5 4.85 4.35 8.55 9.1 1.25 1.6 2.5 3.25.05-.05.1-.05 2.6 3.45 5.25 7.15l7.1 10.2q4.1 5.75 11.15 8.05 2.9-5.55-.55-12.15-2.9-5.65-6.1-11.3-3.05-5.6-6.5-11.05-3.4-5.25-5.2-7.7-1.85-2.45-3.75-4.95-1.95-2.55-3.9-5.3-.95-1.35-2.2-2.45-1.25-1.1-2.75-1.9z"
                    id="AccHat144__Symbol_440_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22.8 76.2q-.05-.1-.05-.15-.1-.05-.15-.1-.3 1.8-1.45 8-.95 4.9-.95 9.25 0 6.8 5.1 20.5-.05.4-.05.8.15-.25.15-.55-.05-.75-.1-1.6 0-2.7-.1-5.35 0-6.85 1-10.5 1.35-4.8 4.5-4.8 3.25 0 5.9 4.85 2.05 3.7 3.55 9.8-.1-.9-.15-1.75-.55-6.6-1.1-12.75-1.25-1.65-2.5-3.25-3.7-4.75-8.55-9.1-.3-.25-.55-.5-.9-.5-2.05-1.2-1.6-1-2.45-1.6m-4.6-57.85q-2.3-4.6-5.15-8.9-3-5-6.35-9.45 2.5 4.75 2.5 8.6 0 3.75-1.7 5.2-2 1.7-3.25 3.4H4.2q-1.6.5-1.6 2.3 0 3.1 4.6 6.9 4.6 3.8 4.6 5 0 2.2-5 2.1-5.6-.45-6.8-.3.15 1.3 5.35 3.2 8.95 3.4 9.85 3.8 5.05 2.35 7.55 3.85 2.1 1.35 4.7 3.5-.4-.5-.75-.95-2-15.2-8.5-28.25z"
                    id="AccHat144__Symbol_417_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat144__Symbol_486_0_Layer0_0_FILL"
                transform="translate(98.7 52.85)"
                id="AccHat144__col1n"
            />
            <use
                xlinkHref="#AccHat144__Symbol_440_0_Layer0_0_FILL"
                transform="translate(98.7 52.85)"
                id="AccHat144__col1l"
            />
            <use
                xlinkHref="#AccHat144__Symbol_417_0_Layer0_0_FILL"
                transform="translate(262.8 90.4)"
                id="AccHat144__col1d"
            />
        </g></g>
    )
}

export default AccHat144
