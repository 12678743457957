import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00006D",
    "col1n": "#0000BB",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}

function ShiDre032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2.1 29.85q-.2.45-.35.9-.15.4-.3.85-.05.15-.05.25l20.05 5.05.35-1.8-19.7-5.25M.75 33.8q-.35 1-.65 2-.05.15-.1.35l20.5 5.05h.05l.45-2.15L.75 33.8m96.2 52.1l-2.8.2q-5.65.4-11.25.55-2.25.05-4.5.05h-1.2q-2.25 0-4.5-.05-5.6-.15-11.25-.55l-2.8-.2q-2-.15-3.95-.35-4.1-.4-8.15-.9-.95-.1-1.9-.25l-4.2-.6q-4.7-.7-9.45-1.6l-4.5-.9-3.1-.7q-1.2-.25-2.4-.55l-6.55-1.75v.05l-9.8 8.2 2.2 2.4q.05.3.35.4 5.15 1.2 10.35 2.3 1.55.3 2.1 2.9 9-.7 17.05.45 1.1.15 2 .6.95.45 1.7 1.25 9.7-2.55 11.5-1.1 2.3 1.2 3.9 2.95 3 .05 6.85-1.5 3.8-1.6 7.55.6.7.55 1.45.9 6.45 4 12.35 0 .7-.35 1.4-.9 3.75-2.2 7.55-.6 3.85 1.55 6.85 1.5 1.6-1.75 3.9-2.95 1.8-1.45 11.5 1.1.75-.8 1.7-1.25.9-.45 2-.6 8.05-1.15 17.05-.45.55-2.6 2.1-2.9 5.2-1.1 10.35-2.3.3-.1.35-.4l2.2-2.4-9.75-8.2-.05-.05-6.55 1.75q-1.2.3-2.4.55l-3.1.7-4.5.9q-4.75.9-9.45 1.6l-4.2.6q-.95.15-1.9.25-4.05.5-8.15.9-1.95.2-3.95.35M77.8 89h.1l-.1.4-.1-.4h.1m56.7-49.95l.5 2.2 20-5q0-.05.6-.2-.25-.7-.45-1.35-.15-.4-.25-.8-.3.1-.6.15l-19.8 5m-.9-3.9l.45 1.8 19.55-4.9q.3-.1.65-.2-.15-.35-.25-.7-.1-.2-.15-.35l-.3-.9-19.95 5.25m6.85-31.1l.1-.05.1-.1q-.55-.8-1.05-1.55-.15-.15-.25-.3L77.8 50.8 16.3 2.1q-.05.05-.1.05-.45.6-.9 1.25l.1.15q-.15.2-.3.45l62.6 50.3.55-.25 62.2-50m-2.55-4l-.05-.05L136 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-.45.35-.95.65-3.25 2.2-6.65 4.1-10 5.55-21.1 8.45-1.95.5-3.9.95L77.8 43.9 56.55 18.75q-1.95-.4-3.8-.9Q42.1 15.1 32.45 9.9q-2.7-1.5-5.35-3.15-1.6-1-3.15-2.1L22.4 3.5q-1.45-1.05-2.8-2.1L17.75 0q-.1.05-.15.15-.15.2-.3.45l60.5 48.2L138.3.6q-.2-.3-.4-.55z"
                    id="ShiDre032__Symbol_229_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9.8 0v.05L0 8.25l2.2 2.4L12.25.7 9.8 0m3.1 13.35q1.55.3 2.1 2.9L21.85 3l-3.1-.7-5.85 11.05m22.85 5.2L40 6.1l-4.2-.6-3.75 11.2q1.1.15 2 .6.95.45 1.7 1.25M54 7.6q-2-.15-3.95-.35l-2.8 10.2q2.3 1.2 3.9 2.95L54 7.6m24.25.75q-2.25.05-4.5.05h-1.2q-2.25 0-4.5-.05l-2.5 11.15q.7.55 1.45.9 6.45 4 12.35 0 .7-.35 1.4-.9l-2.5-11.15m-5.1 2.35h.1l-.1.4-.1-.4h.1m23.1-3.45q-1.95.2-3.95.35l2.85 12.8q1.6-1.75 3.9-2.95l-2.8-10.2M110.5 5.5l-4.2.6 4.25 12.45q.75-.8 1.7-1.25.9-.45 2-.6L110.5 5.5m17.05-3.2l-3.1.7 6.85 13.25q.55-2.6 2.1-2.9L127.55 2.3m16.55 8.35l2.2-2.4-9.75-8.2-.05-.05-2.45.7 10.05 9.95z"
                    id="ShiDre032__Symbol_194_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.15 13.85q-.4 4.35.4 5.5 3.8 7.1 22.85 10.75.45.05.9.15 2.2.3 4.6.6 1.35.1 2.8.25L0 0q.05 1.15.5 2.55.5 3.1 2 5.95 1.95 3.65 4.65 5.35m37.7 22.7q-1-.35-2-.6-.15-.05-.25-.05-.85-.2-1.75-.25-1.55-.15-3.15 0-2.1.15-4.3.85.1 1.3.1 2.6 0 7.2-3.85 13.35-.65 1-1.35 2-.3.4-.6.75l-1.3 1.5q-.8.9-1.7 1.75-.35.25 0 .5 4.2.35 8.25-.25v3.75l1-.3q8.25-2.5 9.8-3.65 1-.7 1.7-1.8.45-.7.8-1.5.1-.35.25-.75.3-.9.45-2 .25-1.55.25-3.4 0-4.15-.95-7.8-.35-1.15-.65-2.2-.45-1.35-.75-2.5M75.8 8.5q1.5-2.85 2-5.95.45-1.4.5-2.55L39.6 31.1q1.45-.15 2.8-.25 2.4-.3 4.6-.6.45-.1.9-.15 19.05-3.65 22.85-10.75.8-1.15.4-5.5 2.7-1.7 4.65-5.35z"
                    id="ShiDre032__Symbol_155_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 0q.05 1.15.5 2.55l34.05 27.2q-1.55-.4-3.25-.95l-.9-.3q-.65-.25-1.35-.5-1.25-.45-5.55-2.9-3.1-1.75-8.75-6.45-.55 0-.55.4 0 2.05 8.5 6.8 4.45 2.45 7.7 3.65.45.2.9.35 2.5.8 4.2.8h.15l.25.2q1.35.1 2.8.25L0 0m40.85 44.9q1.3-1.85.85-4.85-.5-3-.85-4.4-1.55-.15-3.15 0-2.95 6.45 0 12.8 1.45 1.6 1.5 0-2.2-5.15-1.1-8.9 1.05-3.75 1.6-.1.55 1.95 0 4.25.25 2.45 1.15 1.2m4-8.35q-1-.35-2-.6.45 1.8 1.1 3.55.5 1.6.6 6.5.05 3.65-1.05 6.45-.4.9-.9 1.75-.1.15-.15.25-.3.4-.6.75-.85.85-2.1 1.5-2.5 1.35-6.8 2v3.75l1-.3q8.25-2.5 9.8-3.65 1-.7 1.7-1.8.45-.7.8-1.5.1-.35.25-.75.3-.9.45-2 .25-1.55.25-3.4 0-4.15-.95-7.8-.35-1.15-.65-2.2-.45-1.35-.75-2.5m32.95-34q.45-1.4.5-2.55L39.6 31.1q1.45-.15 2.8-.25l.25-.2h.15q1.7 0 4.2-.8.45-.15.9-.35 3.25-1.2 7.7-3.65 8.5-4.75 8.5-6.8 0-.4-.55-.4-5.65 4.7-8.75 6.45-4.3 2.45-5.55 2.9-.7.25-1.35.5l-.9.3q-1.7.55-3.25.95L77.8 2.55z"
                    id="ShiDre032__Symbol_140_0_Layer0_0_FILL"
                />
                <g id="ShiDre032__Layer19_0_FILL">
                    <path
                        fill="#FFF"
                        d="M131 230.5l.05.05v.05q-.05.05-.05.1 5.956 5.12 12 10.15-13.303-8.262-13.4-8.3-1.553 2.324-3 4.75-.84 1.43-1.65 2.9-1.508 2.707-2.9 5.55-.449.967-.9 1.95-2.06 4.453-3.85 9.25-.254.75-.55 1.5 3.312 1.345 6.6 2.35-3.399-.43-7.3-.35-.309.972-.65 1.95 10.054 3.958 20.25 5.2l-.45 2.2-1.6 8.2q-.568 2.765-1.15 5.5l-2.15 9.1h-.05l-1.2 5.2q-.529 2.099-1.05 4.2-.467 1.8-.95 3.6l-.2.75 2.25.55h.05l.65.15 1.35 2.8 60.3 8.1L255 308.8l1.15-2 1.85-.55-4.45-17.8v.05l-.85-3.55q-.61-3.092-1.25-6.2l-1.7-8.5q-.176-.19-.4-.35l.35-.1-.5-2.15q9.9-.641 19.8-5v-.05q.298-.05.6-.15-.191-.535-.4-1.1-.124-.436-.25-.85-.327.068-.65.15-2.857.46-5.7 1.05 2.652-1.306 5.65-3.2-.29-.774-.6-1.55-.864-2.418-1.85-4.75-.928-2.292-1.95-4.5-1.804-3.927-3.85-7.6-1.022-1.837-2.1-3.6-.58-.965-1.2-1.9-.653-1.053-1.35-2.1-.067.038-.15.05l-.05.1q-3.798 2.813-6.8 5 5.67-7.046 5.65-7.1l-.05.05v-.1q-.462-.643-.95-1.3-34.88 23.73-60.5 46.9-28.696-24.563-60.55-46.95-.453.695-.95 1.35m7.25 33.7v-.1l.2.05q-.1 0-.2.05m122.45-21.55v-.55q.147.428.25.85.037.185.05.4-.14-.332-.3-.7m-14.15 21.5l.2-.05v.1q-.1-.05-.2-.05m6.15 25.75q-.014-.061-.05-.15l.1.2-.05-.05m-60.2-12.5l.35-.25v.1l-.35.15z"
                    />
                    <path
                        fill="#FE7878"
                        d="M192.85 277.15l-.35.25.35-.15v-.1z"
                    />
                    <path
                        fill="#B3B3B3"
                        d="M252.65 289.75q.036.089.05.15l.05.05-.1-.2z"
                    />
                    <path
                        fill="#BFBFBF"
                        d="M246.75 264.1l-.2.05q.1 0 .2.05v-.1m-108.5 0v.1q.1-.05.2-.05l-.2-.05z"
                    />
                </g>
                <g id="ShiDre032__Layer19_1_FILL">
                    <path
                        fill="#B8B8B8"
                        d="M201.25 281.65q-.366-3.314-.9.15v6.95q-1.05-.4-2.1-.7-4.4.25-5.75.65-1.35-.4-5.75-.65-1.05.3-2.1.7v-6.95q-.366-3.219-.9-.15v6.75q1.2 2 3.1 2.25 4.3-.65 5.65-1.15 1.35.5 5.65 1.15 1.9-.25 3.1-2.25v-6.75z"
                    />
                    <path
                        fill="#E0E0E0"
                        d="M244 271.45q.2.15.35.25.75.45 1.1.85.65.95 1.2 2.9.3 1.05.9 3.1.3.95 2.1 6 .707 1.646 1.8 3.35 1.088 1.695 2.15 2.1.175 0 .35-.1l-.4-1.45v.05l-.85-3.55q-.61-3.092-1.25-6.2l-1.7-8.5q-.176-.19-.4-.35l.35-.1-1.4-6.05-4.55-1.35q-1.25-.35-1.45-.35-.8 0-.8.35 0 .5 1.65 2.05 1.7 1.55 1.7 2.3 0 .55-.45.6-.55.2-2.65-.1-1.75-.25-.9 1.45.3.45.7.95.3.45 2.45 1.8m2.75-7.25q-.1-.05-.2-.05l.2-.05v.1m-103.3 5.45q.4-.5.7-.95.85-1.7-.9-1.45-2.1.3-2.65.1-.45-.05-.45-.6 0-.75 1.7-2.3 1.65-1.55 1.65-2.05 0-.35-.8-.35-.2 0-1.45.35l-4.55 1.5-.2-.25-1.3 6.15-1.6 8.2q-.568 2.765-1.15 5.5l-1.6 6.7h.05q.354-.035 1.6-.8.18-.214.35-.45 1.46-1.975 2.5-4.4 1.8-5.05 2.1-6 .6-2.05.9-3.1.55-1.95 1.2-2.9.35-.4 1.1-.85.15-.1.35-.25 2.15-1.35 2.45-1.8m-5.2-5.45v-.1l.2.05q-.1 0-.2.05z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiDre032__Layer19_0_FILL" />
            <use xlinkHref="#ShiDre032__Layer19_1_FILL" />
            <use
                xlinkHref="#ShiDre032__Symbol_229_0_Layer0_0_FILL"
                transform="translate(114.7 228.6)"
                id="ShiDre032__col1n"
            />
            <use
                xlinkHref="#ShiDre032__Symbol_194_0_Layer0_0_FILL"
                transform="translate(119.35 306.9)"
                id="ShiDre032__col1d"
            />
            <use
                xlinkHref="#ShiDre032__Symbol_155_0_Layer0_0_FILL"
                transform="translate(153.35 251.55)"
                id="ShiDre032__col2n"
            />
            <use
                xlinkHref="#ShiDre032__Symbol_140_0_Layer0_0_FILL"
                transform="translate(153.35 251.55)"
                id="ShiDre032__col2d"
            />
        </g></g>
    )
}

export default ShiDre032
