import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#984969"
}

function SkiMak011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M106.6 20.4q-2.3-5.7-6.8-10.5Q91.45.9 80.4.1 69.4-.75 62.15 7q-4.7 5.05-6.5 13.35-1.8-8.3-6.5-13.35Q41.9-.75 30.9.1 19.85.9 11.5 9.9 7 14.7 4.7 20.4 2.2 25.05.9 30.15q-2.85 11.1 3.1 21.4 5.95 10.2 21.2 9.55 3.4-.15 6.45-.8 11.6-3.15 16.6-11.55 5.95-8.75 7.4-19.3 1.45 10.55 7.4 19.3 5 8.4 16.6 11.55 3.05.65 6.45.8 15.25.65 21.2-9.55 5.95-10.3 3.1-21.4-1.3-5.1-3.8-9.75z"
                    id="SkiMak011__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak011__Symbol_17_0_Layer0_0_FILL"
                transform="translate(136.85 136.35)"
                id="SkiMak011__col1n"
            />
        </g></g>
    )
}

export default SkiMak011
