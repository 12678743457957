import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48.6 14.15q9.25-.2 4.7-5.85-4.95-5.8-11.65-7.1-13.55-2.4-28.45 0Q6.45 2.5 1.5 8.3q-4.55 5.65 4.7 5.85 22.1-1.7 42.4 0z"
                    id="BeaMus010__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus010__Symbol_27_0_Layer0_0_FILL"
                transform="translate(165.1 198.3)"
                id="BeaMus010__col1n"
            />
        </g></g>
    )
}

export default BeaMus010
