import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}

function ObjMus040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#313131"
                    d="M252.45 228.35q-3.45 3.3-6.95 5.25l3.2 20.25q4.55 2.5 8.2-2.5l-4.45-23m-111.3 6.45l-8.85-6.5 9.55 58.65q6.8 3.15 9.05-2.75l-9.75-49.4z"
                    id="ObjMus040__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M204.45 16.15Q211.4 12.8 216.2 6.1l-2-3.85q-1.25-3-4.25-2L56.2 47.15q.2.3.95 1.4l-.1.05q.65 1.45.65 3.15 0 2.7-1.6 4.7-.2.25-.4.55-.15.1-.25.2-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.3 1.5-.9 2.85-.65 1.45-1.6 2.8-.75.95-1.6 1.8l-.2.2q-.15.15-.3.25-1.15 1.05-2.4 1.85l-.1.05-.05.05q-.05.05-.1.05l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-.7.25-1.45.5-.3.05-.55.1-1.65.35-3.4.35h-.7q-.85-.05-1.65-.15-1.15-.2-2.2-.5-1.75-.55-3.3-1.55-.15-.05-.25-.15-.7-.4-1.3-.9-.45-.4-.85-.75l-.4-.4q-3.25-3.2-4.1-7.35-.05-.05-.05-.1l-.15.05q-.05-1.35-.05-1.7L10.75 61 1.3 65.15q-3.65 1.75 2.9 8.35 6.1 7.45 6 16.6-.25 7 5.3 6.65.2-.05.5-.05h.15q.2-.05.4-.05Q95 84.6 169.15 61.95q-.7-1.5-1-3.1l-.3-.1q-1.2-.55-2.15-1.55-2.1-2.05-2.25-4.95-4.9.15-7.8-1.65-5.5-3.55-6.1-8-1-2.85.7-5.05 2.05-2.6 7.95-4.3l7.1-2.65 12.1-8.65 14.35-1.05 12.7-4.75z"
                    id="ObjMus040__Symbol_124_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M59.15 66.25q-.1-.6-.65-.95t-1.2-.2q-.65.15-1 .75-.3.55-.15 1.2.15.6.7.9.55.4 1.2.25.65-.2 1-.8.3-.5.1-1.15m-9.2 1.1q-.55-.3-1.2-.15-.65.15-1.05.7-.3.6-.15 1.2.15.65.7 1 .6.35 1.25.15.65-.1.95-.7.3-.55.15-1.2-.1-.6-.65-1m15.45-1.5q.55.3 1.25.2.6-.15.95-.8.3-.5.2-1.1-.2-.65-.75-1t-1.15-.2q-.7.15-1 .75-.35.5-.2 1.15.2.65.7 1M1.2 79.3q-.65.15-1 .75-.3.5-.1 1.15.1.6.6 1 .65.3 1.25.2.65-.2 1-.8.35-.5.15-1.1-.1-.65-.7-1-.55-.35-1.2-.2m10.5-.95q-.15-.65-.7-1-.55-.35-1.2-.2-.65.2-1 .75t-.2 1.2q.15.6.7.95.6.35 1.25.2.65-.2.95-.8.35-.5.2-1.1m7.9-3.1q-.6-.35-1.25-.2-.65.2-.95.7-.35.6-.2 1.25.15.65.7.95.55.4 1.25.2.6-.15.95-.75.3-.5.2-1.2-.2-.6-.7-.95m19.35-4q.2.65.7.95.6.4 1.25.2.65-.15.95-.7.4-.55.2-1.2-.15-.6-.7-1-.55-.3-1.2-.2-.65.2-1 .75-.3.55-.2 1.2m-12 1.7q-.65.1-1 .65-.3.6-.2 1.2.2.65.7 1 .6.35 1.25.2.65-.15.95-.75.35-.5.2-1.15-.2-.65-.75-1-.5-.35-1.15-.15M81.2 61.9q.4-.55.25-1.15-.2-.65-.75-1.05-.5-.3-1.2-.1-.6.1-.95.7-.35.55-.2 1.2.15.6.7.95.6.35 1.25.2.65-.15.9-.75m5.85-3.75q-.3.55-.15 1.25.15.6.75.9.55.35 1.2.25.65-.2.95-.8.35-.5.15-1.15-.1-.65-.7-1-.5-.35-1.15-.15-.65.1-1.05.7m8.6-2.1q-.3.6-.15 1.2.15.65.7 1 .6.35 1.25.15.65-.1.95-.7.3-.6.15-1.2-.15-.65-.65-1-.55-.3-1.2-.2-.65.25-1.05.75m9.6-2.85q-.7.15-1 .7-.35.6-.15 1.2.15.65.7 1 .55.35 1.2.15.6-.1.95-.7.35-.55.2-1.2-.15-.6-.7-1-.6-.3-1.2-.15m10.65-33.25q-.3.6-.1 1.2.25.65.85.95.6.3 1.2 0 .65-.2.95-.8.25-.6 0-1.25-.2-.55-.8-.85-.6-.3-1.25-.1-.6.25-.85.85m-5.55 3.5q.2.65.8.95.6.25 1.2 0 .6-.2.9-.8.3-.6.05-1.2-.25-.65-.85-.9-.6-.3-1.15-.05-.6.2-.9.8-.3.6-.05 1.2m-2.45 1.15q-.2-.6-.8-.9-.65-.25-1.25-.05-.6.25-.85.85-.3.6-.1 1.2.2.6.85.85.6.3 1.2.1.6-.2.9-.8.25-.6.05-1.25m50.3-19q-.2-.6-.8-.9-.6-.25-1.25-.1-.6.25-.9.85-.3.6-.05 1.15.25.7.85.95.6.3 1.2.05.55-.2.85-.8.3-.6.1-1.2m5.3-2.3q-.2-.55-.8-.85-.6-.3-1.25-.1-.6.3-.9.9-.25.6-.05 1.15.25.6.85.9.6.3 1.2.05.6-.2.85-.8.3-.6.1-1.25m5.65-2.25q-.2-.6-.8-.9-.6-.25-1.2-.05t-.85.8q-.3.6-.1 1.2.2.65.8.9.6.3 1.2.05.65-.2.95-.8.25-.6 0-1.2z"
                    id="ObjMus040__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    d="M208.25 279.25l-3.25 1 6.25 18.85 3.3-.85-6.3-19m11.95 17.45l-6.3-19.15-3.35 1 6.3 18.95 3.35-.8m-28.9-12.4l-3.25 1.05 6.05 18.25-2.3.6-6.05-18.2-3.25 1 6.05 18.1 8.9-2.35-6.15-18.45m-22.6 6.85l-1.2.35-.5.2-1.55.45 5.85 17.45 3.25-.85-5.85-17.6m5.55-1.7L171 290.5l5.9 17.6 3.3-.85-5.95-17.8m22.8-6.8l-3.2.95 6.15 18.45 3.25-.85-6.2-18.55m-84.75 25.8q-.15-.05-.25-.15l-3.1.9 5.1 15.4 4.6-1.2-1.25.3-5.1-15.25m-5.6 1.45l-3.3 1 5.1 15.15 3.3-.85-3.9-11.6v-.1l-1.2-3.6m12.75.75q-.85-.05-1.65-.15-1.15-.2-2.2-.5l4.3 13.05 3.4-.85-3.85-11.55m8.35-2.05l-.05.05L131.5 320l3.25-.85-4.1-12.55-.35.1q-1.15 1.05-2.4 1.85l-.1.05m7.15-7.2l-.95.25q-.65 1.45-1.6 2.8l4.7 14.1 3.3-.9-5.45-16.25m13.5-4.1l5.65 16.8-.65.15 3.95-1-5.65-17-3.3 1.05m-5.55 1.65l5.6 16.65 3.35-.9-5.6-16.75-3.35 1m14.65-4.4l-3.25.95 5.65 17.05 3.35-.85-5.75-17.15z"
                    id="ObjMus040__Layer4_0_MEMBER_0_MEMBER_0_FILL"
                />
                <path
                    fill="#333"
                    d="M205 280.25l1.2 3.6 3.25-.95-1.2-3.65-3.25 1m10.05.9l-1.15-3.6-3.35 1 1.25 3.6 3.25-1m-29.3 4.85l-3.25 1 1.2 3.65 3.3-1.05-1.25-3.6m2.3-.65l1.2 3.6 3.3-1-1.25-3.65-3.25 1.05m9-2.7l-3.2.95 1.15 3.6 3.3-.95-1.25-3.6m-21.55 10.4l-1.25-3.6-3.25 1.05 1.2 3.6 3.3-1.05m-8-1.55l-.5.2-1.55.45 1.25 3.6 3.25-1-1.25-3.6-1.2.35m-51.55 19.55l1.85-.55q-1.15-.2-2.2-.5l.35 1.05m-9.25-1.15l-3.3 1 1.25 3.6 3.25-1-1.2-3.6m5.6-1.45q-.15-.05-.25-.15l-3.1.9 1.2 3.6 3.3-.95-1.15-3.4m20.1-4l.45 1.5 3.35-1-1.25-3.55-.95.25q-.65 1.45-1.6 2.8m16.05-7.15l1.25 3.6 3.25-1-1.2-3.65-3.3 1.05m-2.2.65l-3.35 1 1.25 3.65 3.3-1.05-1.2-3.6m11.3-3.4l-3.25.95 1.15 3.6 3.3-.95-1.2-3.6z"
                    id="ObjMus040__Layer4_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL"
                />
                <path
                    fill="#666"
                    d="M246.05 267.65l1.25 2.85 26.45-9.7-1.25-2.7-26.45 9.55z"
                    id="ObjMus040__Layer4_0_MEMBER_1_FILL"
                />
                <g id="ObjMus040__Layer4_0_FILL">
                    <path
                        fill="#999"
                        d="M267.2 254.45l-21.9 7.45q-1.45.8-.75 2.4l1.5 3.35q13.71-1.868 26.45-9.55l-1.3-2.65q-1.4-1.95-4-1z"
                    />
                    <path
                        fill="#CCC"
                        d="M104.2 326.95l.1.2.6-.15-.05-.05h-.65z"
                    />
                    <path
                        fill="#FFF"
                        d="M139.15 288.45l-.1.05q.65 1.45.65 3.15 0 2.7-1.6 4.7-.2.25-.4.55-.15.1-.25.2-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.3 1.5-.9 2.85-.65 1.45-1.6 2.8-.75.95-1.6 1.8l-.2.2q-.15.15-.3.25-1.15 1.05-2.4 1.85l-.1.05-.05.05q-.05.05-.1.05l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-.7.25-1.45.5-.3.05-.55.1-1.65.35-3.4.35h-.7q-.85-.05-1.65-.15-1.15-.2-2.2-.5-1.75-.55-3.3-1.55-.15-.05-.25-.15-.7-.4-1.3-.9-.45-.4-.85-.75l-.4-.4q-3.25-3.2-4.1-7.35-.05-.05-.05-.1l-14.45 4.45 6.9 20.85q.454 1.967 1.55 2.85 1.424 1.16 3.95.45l1-.25-.1-.2h.65l.05.05 27-7.05-.4.05 3.25-.85.05.05 2.3-.65 3.3-.9v.05l11.45-3 32.55-8.55.3-.05 12.75-3.35-.35.1 2.9-.75v-.05l19.2-5-2.3.55-.05-.1 3.35-.8.05.1 5.25-1.4q4.65-1.15 3.15-5.4l-8.75-26.3-80.75 24.75z"
                    />
                </g>
                <g id="ObjMus040__Layer4_0_MEMBER_0_FILL">
                    <path
                        fill="#CCC"
                        d="M209.05 267.25l-.5.2 3.5 10.65.55-.15-3.55-10.7m5.65-1.65l-.55.15 10 30 .55-.2-10-29.95m-11.25 3.35l-.6.25 3.55 10.6.6-.15-3.55-10.7m-16.9 5.15l-.6.15 3.55 10.65.55-.15-3.5-10.65m8.6 9.1l.55-.2-3.55-10.6-.5.15 3.5 10.65m-25.6-4l-.5.15L172.6 290l.5-.15-3.55-10.65m-5.6 1.7l-.5.15L167 291.7l.5-.2-3.55-10.6m16.9-5.15l-.5.2 3.55 10.7.5-.2-3.55-10.7m-5.6 1.75l-.6.2 9.5 28.5.55-.1-9.45-28.6m22.5-6.8l-.5.15L207 300.2l.55-.1-9.8-29.4M96.4 301.55l-.55.2 8.45 25.4.6-.15-8.5-25.45m14.35 5.85q-.45-.4-.85-.75l.7 2.1.55-.2-.4-1.15m-5.25 2.85l-3.45-10.4-.55.15 3.4 10.45.6-.2m18.05.05l3.6 10.9.55-.15-3.6-10.85q-.3.05-.55.1m23.5-24.25l-.6.15 3.55 10.6.6-.15-3.55-10.6m11.3-3.45l-.6.15 9.3 27.95.55-.15-9.25-27.95m-5.7 1.7l-.5.2 3.5 10.6.55-.15-3.55-10.65m-11.2 3.45l-.55.2 3.5 10.55.55-.15-3.5-10.6m-3.35 8.6q-.2.25-.4.55l6.55 19.8.55-.15-6.7-20.2z"
                    />
                    <path
                        fill="#333"
                        d="M130.65 306.6l-.05-.15q-.15.15-.3.25l.35-.1z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMus040__Layer13_0_FILL" />
            <use
                xlinkHref="#ObjMus040__Symbol_124_0_Layer0_0_FILL"
                transform="translate(82 239.9)"
                id="ObjMus040__col1n"
            />
            <use
                xlinkHref="#ObjMus040__Symbol_50_0_Layer0_0_FILL"
                transform="translate(121.15 246.45)"
                id="ObjMus040__col2n"
            />
            <use xlinkHref="#ObjMus040__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus040__Layer4_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMus040__Layer4_0_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMus040__Layer4_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMus040__Layer4_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ObjMus040
