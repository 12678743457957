import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400",
    "col1s": "#932800",
    "col2n": "#C40000"
}

function ShiLon012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.85 46.55l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-.5-1.1-.95-2.2l-.1-.4q0 .05-8 4.9-8 4.8-20.9 9.35-36.7 1.7-51.2-3.45-14.55-5.2-16.55-5.6-2-.4-13.4-6.85-11.45-6.4-24.1-16.9L19.8 0q0 .05.05.1-2.15 3-4.05 6.2-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6L.75 46q-.4 1.7-.75 3.4v.2q.4-.3 7.45-1.35 7-1.1 13.95 2.15l1.35.9-.65 3.35-1.05.45-2.8 11.7q-.55 2.1-1.05 4.2l-.9 3.6-.1.4q65.85 19.2 130.95 0l-4.35-17.5h-.05l-.55-2.45-.85-.45-.6-3.2.55-.45q.25.15 5.45-1.75t10.75-.85q5.55 1 6 1.25-.35-1.55-.65-3.05z"
                    id="ShiLon012__Symbol_286_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65M116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4z"
                    id="ShiLon012__Symbol_223_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M23.55 53.6l-1.35-.9q-9.65-7.5-20.55-1.3-.45.2-.85.5-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7v.05l1.05-.45.65-3.35m124.4 23.7l-.8.2Q84.8 92.55 17.8 77.5l-.8-.2-.85 3.2v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.1l-.8-3.2m-22.2-41.4q13.05-2.85 29.05-14.8-.45-1.05-.95-2.1-.15-.3-.25-.55-11.15 10.4-27.85 14.05l-4 .7q-33.7 5.2-61.85-7.75l-3.9-1.9-3.25-1.8q-.65-.35-1.25-.7l-3.25-1.95Q31.75 9.45 22.35 0q-.05 0-.05.05-.85 1.15-1.65 2.3Q31.25 13.1 50.9 24.1q3.4 2 6.95 3.7.25.15.55.3 28.75 13.85 63.35 8.5l4-.7m39.3 20.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.45-.25-.8-.45-11.15-6.35-20.95 1.45-.25.15-.45.35l-.55.45.6 3.2.85.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.1z"
                    id="ShiLon012__Symbol_192_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M247.6 264.45v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35m-110.95-.5q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1z"
                    id="ShiLon012__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon012__Symbol_286_0_Layer0_0_FILL"
                transform="translate(110.8 231)"
                id="ShiLon012__col1n"
            />
            <use
                xlinkHref="#ShiLon012__Symbol_223_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiLon012__col1s"
            />
            <use
                xlinkHref="#ShiLon012__Symbol_192_0_Layer0_0_FILL"
                transform="translate(110 228.7)"
                id="ShiLon012__col2n"
            />
            <use xlinkHref="#ShiLon012__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon012
