import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFF00",
    "col1n": "#FFE000",
    "col1s": "#D2AD00"
}

function AccMor040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.9 29.7V15.3l-.05-.05q-.7-.35-1.4-.65l-.8-.4q-1.25-.6-2.4-1.2-.4-.2-.75-.4-.45-.2-.9-.45Q25.9 6.05 19.15 0L8.85 1.9q-2.2.45-4.3.95-.35.1-.65.15Q.25 4.4 0 6.95L.75 27.2q.7 3.3 3.2 5 1.15.75 3.35 1.8 2.75.95 5.9 1.4 1.8.2 3.7.3h.6q.8 0 1.6-.05 1.85-.05 3.75-.25 1.3-.15 2.65-.35 2.55-.4 5.25-1.05 2.85-.7 5.85-1.65 2.3-.75 4.75-1.65.55-.25 1.1-.45.65-.25 1.35-.5.05-.05.1-.05M156.8 3q-.3-.05-.65-.15-2.1-.5-4.3-.95L141.55 0q-6.75 6.05-18.45 12.15-.45.25-.9.45-.35.2-.75.4-1.15.6-2.4 1.2l-.8.4q-.7.3-1.4.65l-.05.05v14.4q.05 0 .1.05.7.25 1.35.5.55.2 1.1.45 2.45.9 4.75 1.65 3 .95 5.85 1.65 2.7.65 5.25 1.05 1.35.2 2.65.35 1.9.2 3.75.25.8.05 1.6.05h.6q1.9-.1 3.7-.3 3.15-.45 5.9-1.4 2.2-1.05 3.35-1.8 2.5-1.7 3.2-5l.75-20.25q-.25-2.55-3.9-3.95z"
                    id="AccMor040__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M9.25 21.15q1.8.2 3.7.3V4.3q-1.95.05-3.7-.1v16.95M3.35 3.1q-1.9-.65-3.3-1.6L0 17.95q1.15.75 3.35 1.8V3.1m15.55.8q-1.95.2-3.75.3v17.2q1.85-.05 3.75-.25V3.9m7.9-1.3q-2.75.55-5.25.95V20.8q2.55-.4 5.25-1.05V2.6M37.4 0q-.25.05-.5.15-2.2.6-4.25 1.1V18.1q2.3-.75 4.75-1.65V0m78 0v16.45q2.45.9 4.75 1.65V1.25q-2.05-.5-4.25-1.1-.25-.1-.5-.15m15.85 3.55q-2.5-.4-5.25-.95v17.15q2.7.65 5.25 1.05V3.55m6.4.65q-1.8-.1-3.75-.3v17.25q1.9.2 3.75.25V4.2m5.9 16.95V4.2q-1.75.15-3.7.1v17.15q1.9-.1 3.7-.3m5.9-18.05v16.65q2.2-1.05 3.35-1.8l-.05-16.45q-1.4.95-3.3 1.6z"
                    id="AccMor040__Symbol_56_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M41.35 6.45v.05l.3-.1q-1.25-.6-2.4-1.2-.4-.2-.75-.4l-7.55 1.7q-.9.15-1.7.35l-.8.2q-.4.05-.8.15Q5.05 11.5.3.75.15.4 0 0v2.45q1 3.45 4 5.5 1.4.95 3.3 1.6 2.55.85 5.9 1.1 1.75.15 3.7.1h.6l1.6-.1q1.8-.1 3.75-.3 1.3-.2 2.65-.35 2.5-.4 5.25-.95 2.8-.6 5.85-1.35 2.05-.5 4.25-1.1.25-.1.5-.15m101.85 4.3h.6q1.95.05 3.7-.1 3.35-.25 5.9-1.1 1.9-.65 3.3-1.6 3-2.05 4-5.5V0q-.15.4-.3.75-4.75 10.75-27.35 6.45-.4-.1-.8-.15l-.8-.2q-.8-.2-1.7-.35l-7.55-1.7q-.35.2-.75.4-1.15.6-2.4 1.2l.3.1v-.05q.25.05.5.15 2.2.6 4.25 1.1 3.05.75 5.85 1.35 2.75.55 5.25.95 1.35.15 2.65.35 1.95.2 3.75.3l1.6.1z"
                    id="AccMor040__Symbol_47_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor040__Symbol_138_0_Layer0_0_FILL"
                transform="translate(112.15 227.35)"
                id="AccMor040__col1n"
            />
            <use
                xlinkHref="#AccMor040__Symbol_56_0_Layer0_0_FILL"
                transform="translate(116.1 241.6)"
                id="AccMor040__col1l"
            />
            <use
                xlinkHref="#AccMor040__Symbol_47_0_Layer0_0_FILL"
                transform="translate(112.15 235.15)"
                id="AccMor040__col1s"
            />
        </g></g>
    )
}

export default AccMor040
