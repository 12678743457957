import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A10058",
    "col1l": "#FF008C",
    "col1n": "#D70076"
}

function ObjFoo024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M58.85 14.05L58.75 0q-11.8 17.25-55.4 16.4L0 7.7q.15 18 2.75 21.9v.05q22.65 1.6 42.05-6.55 8.6-3.7 14.05-9.05z"
                    id="ObjFoo024__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.25 0L0 13.35q.5 6.7 3 8.55V9.5q-.75-2.2-1.65-5.75Q.45.2.25 0z"
                    id="ObjFoo024__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M56.9 24.3q.6-.8 1.25-2.15.1-.2.15-.4.35-.95.5-2.15 0-.2.05-.45.1-3.3-1.35-5.65-3.25-5.3-9.15-6.8-4.05-1.2-8.45-.2-.25.05-.45.1-.25.05-.4.15l-.1-.4q-.5-1.55-1.75-2.65-1.4-1.3-2.75-1.85-.2-.1-.35-.15-.4-.2-.75-.35-7.8-2.7-16.7 0-5 1.5-9.05 4.9Q1.95 12.3.65 20.7q-.25 1.1-.45 3-.35 3.1-.15 3.9v-.2q.05.2.1.35.95 7.05 2.65 9.15 17.5 1.75 33.3-2.55.25-.05.5-.15.2-.05.45-.1 2.2-.6 4.95-1.7 1.05-.4 2.8-1.05.8-.3 1.85-.7.4-.15.85-.35 1.65-.65 3.1-1.35 4.45-2.2 6.3-4.65z"
                    id="ObjFoo024__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill="#F9F"
                    d="M112.5 274.1q-.15.05-.25.1h.05l.2-.1z"
                    id="ObjFoo024__Layer7_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M115.8 271.25q-1.771.414-3 .8-.367.164-.7.3-1.779.703-2.3.9l-.1.05-.05.05q-.15.05-.2.1-2.714 1.347-5.1 3.35-1.935 2.072-3.35 4.4-.031.085-.05.15-.27.486-.55 1-.112.292-.25.6-2.97 8.35-2.15 16.7.099.628.2 1.2.035.045.05.1 1.572-.153 5.7.7 10.306 2.118 10.35 2.1 1.408-.068 2.8-.2.365-.034.7-.1 6.666-2.504 10.4-2.7 3.768-.195 4-.15.296.098.55.05.2-.055.4-.15.043.013.05 0 2.04-.566 4.45-1.55h.05q.122-.046.25-.1.052-.009.1-.05.197-.052.4-.15.147-.024.7-.3.596-.28 3.8-2.6 2.208-1.607 1.55-1.05 1.149-.506 1.95-.7.907-.228 1.45-.35.36-.164.85-.4 2.53-1.061 2.85-1.7.14-.258.25-.55.018-.042 0-.1 1.427-3.385 0-7.35-.036-.08-.1-.15-.096-.244-.25-.5-3.393-4.54-8.25-5.6l-.1-.05q-.041.015-.1 0-.323-.052-.65-.1-.15 0-.3-.05-1.55-.3-2.25-.4h-2.15q-.1.05-.15.05h-.35q-.842.115-1.5.2-1.006.234-1.5.45-.25.1-.45.25-.05 0-.05-.1-.4-1.5-1.55-3.05l-.05-.05q-.083-.097-.2-.2-1.4-1.3-2.75-1.85-.2-.1-.35-.15-.4-.2-.75-.35-.192-.064-.4-.15-4.666-1.59-9.9-.85h-.05q-.633.114-1.35.25-.265.046-.55.1m-3.3 2.85l-.2.1h-.05q.1-.05.25-.1z"
                    id="ObjFoo024__Layer7_1_FILL"
                />
                <g id="ObjFoo024__Layer7_2_FILL">
                    <path
                        fill="#702B23"
                        d="M135.8 294.35q0 .95.3 1.75.3.85.8 1.6.2.3.45.6.274.281.45.5.096.074.25.1.72-.298 1.7-.7 1.264-.494 1.85-.75l1.3-.55q7.197-3.447 8.8-5.35-.733-1.085-1.65-1.9-.98-.813-1.9-1.25-.347-.178-.75-.35-.299-.1-.65-.2-1.25-.35-2.65-.35h-.4q-.85.05-1.55.2-2.2.4-3.9 1.8-1.079.88-1.7 1.9-.161.34-.3.65-.45 1.061-.45 2.25v.05m-29.35.65H106q-.82.054-1.5.2-.902.164-1.75.5-1.147.474-2.15 1.3-1.4 1.15-2 2.6-.266.591-.4 1.25.307 1.282.85 2.3l.15.35q7.665.732 15 .25.055.022.1 0 .286-.73.35-1.55v-.4q0-.5-.05-.95v-.15q-.25-.85-.65-1.65 0-.05-.05-.1-.6-1.05-1.7-1.95-.205-.167-.45-.35-.58-.415-1.25-.75-.65-.35-1.4-.55-1.25-.35-2.65-.35m13.65-12.4q-.2.15-.45.4-1.25 1.25-1.75 2.75-.25.65-.3 1.3-.1.5-.1 1v.05q0 1 .25 1.85.3.9.7 1.65l.2.4q.1.1.2.25l.35.35h.1q0 .05.05.05.1-.05.15-.05 4.45-3 11.05-.35.4-.6.7-1.2.2-.45.3-.9.25-.75.3-1.55v-.15-.4q0-.5-.05-1v-.15q-.2-.9-.55-1.75 0-.05-.05-.1-.3-.7-.8-1.3-.2-.25-.4-.45-.05-.1-.15-.2l-.1-.1q-.45-.4-.95-.75-.3-.2-.55-.4-.55-.35-1.25-.55-1.1-.35-2.25-.35h-.45q-.45.05-.85.1-.1 0-.2.05-.15 0-.25.05-1.6.35-2.9 1.45m-8.6-.8q1.45.25 2.8.85.75.35 1.45.85.25-.55 1-1.3.55-.55 1-.9v-.3q0-2.6-2.15-4.45-2.1-1.85-5.15-1.85-3.05 0-5.25 1.85-2.1 1.85-2.1 4.45 0 1 .35 1.95 2.45-1.35 5.6-1.35 1.35 0 2.45.2z"
                    />
                    <path
                        fill="#BD5927"
                        d="M140.1 278h-.25q-.75.05-1.45.2-2.1.4-3.7 1.85-1.35 1.25-1.9 2.75-.25.65-.35 1.3-.1.5-.1 1v.05q0 1 .3 1.85.3.9.75 1.65.1.2.25.4.1.1.2.25l.55.55q.15.2.3.3.1.1.3.25.041.041.1.05.136-.295.3-.6.131-.253.3-.5 4.16-4.83 10.5-3.55.154.056.3.1.742.143 1.55.35.082-.641.05-.75.006-.202 0-.4 0-.5-.05-1v-.15q-.2-.9-.6-1.75 0-.05-.05-.1-.473-.947-1.35-1.8-.281-.24-.65-.5-.05 0-.05-.05-.296-.257-.6-.5-.3-.173-.6-.35-.6-.35-1.35-.55-1.2-.35-2.5-.35h-.2m-18.9 16.05q-1.07.484-2 1.25-2.45 2-2.45 4.8v.05q.01.927.3 1.75.28.838.75 1.55 7.177-.693 14-2.55.25-.05.5-.15.2-.05.45-.1.2-.055.4-.15.017-.284.05-.45v-.2-.45q-.012-.2-.05-.4-.187-.916-.65-1.75-.6-1.05-1.7-1.95-1.062-.904-2.35-1.4-.36-.141-.75-.25-1.233-.35-2.65-.35h-.4q-.85.05-1.55.2-.996.181-1.9.55m-.75-20.25q-1.826 1.69-2 4v.45q0 1.3.5 2.4 3.48-2.793 7.7-1.95 2.353.526 4.6 2.5.646-1.056.75-2.35.057-.007.1-.05-.032-.095-.05-.2v-.35q0-2.6-2-4.45-.381-.343-.8-.65-1.734-1.15-4-1.15-2.8 0-4.8 1.8m-20.1 14.55q-.25.6-.35 1.25-.1.45-.1.95v.05q0 .95.3 1.75.3.85.8 1.6l.2.3q2.45-1.25 5.55-1.25 1.35 0 2.45.2 1.45.25 2.8.85.75.35 1.45.85 0 .05.05.05.05.1.2.15l.25.2q1.555-1.213 2.1-2.75.3-.8.35-1.6v-.4q0-.5-.05-.95v-.15q-.25-.85-.65-1.65 0-.05-.05-.1-.6-1.05-1.7-1.95-.8-.65-1.7-1.1-.65-.35-1.4-.55-1.25-.35-2.65-.35h-.45q-.8.05-1.5.2-2.2.4-3.9 1.8-1.4 1.15-2 2.6z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo024__Symbol_103_0_Layer0_0_FILL"
                transform="translate(95.75 288.6)"
                id="ObjFoo024__col1n"
            />
            <use
                xlinkHref="#ObjFoo024__Symbol_41_0_Layer0_0_FILL"
                transform="translate(95.5 296.3)"
                id="ObjFoo024__col1d"
            />
            <use
                xlinkHref="#ObjFoo024__Symbol_24_0_Layer0_0_FILL"
                transform="translate(95.7 268.9)"
                id="ObjFoo024__col1l"
            />
            <use xlinkHref="#ObjFoo024__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo024__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo024__Layer7_2_FILL" />
        </g></g>
    )
}

export default ObjFoo024
