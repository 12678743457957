import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#C6C56C"
}

function SkiMor002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M79.75 94.45q-.25-2.4-2.25-2.4-.95 0-1.7.75-.7.75-.7 1.55 0 .65.35 1.25.55 1.1 2.05 1.1 2.25 0 2.25-2.25M59.05 52.5q-.25-2.45-2.25-2.45-.95 0-1.7.75-.7.8-.7 1.6 0 .65.35 1.25.55 1.1 2.05 1.1 2.25 0 2.25-2.25M20.9 71.35q2.3 0 2.3-2.3-.25-2.45-2.3-2.45-.95 0-1.7.8-.75.75-.75 1.55 0 .65.35 1.3.6 1.1 2.1 1.1M3.2 46.6q-1.25 0-2.25 1-.95 1-.95 2.05 0 .9.45 1.75.8 1.5 2.75 1.5 3.05 0 3.05-3.1-.3-3.2-3.05-3.2M9.75 2.3q0 .65.35 1.25.55 1.1 2.05 1.1 2.25 0 2.25-2.25Q14.15 0 12.15 0q-.95 0-1.7.75-.7.75-.7 1.55m124.8 60.05q.4-.95.4-2.25-.45-4.75-4.5-4.75-1.85 0-3.35 1.5-1.4 1.45-1.4 3 0 1.3.65 2.5l.05.05q1.15 2.2 4.05 2.2 3.15 0 4.1-2.25M102.9 61q.55 1.1 2.05 1.1 2.25 0 2.25-2.25-.25-2.4-2.25-2.4-.95 0-1.7.75-.7.75-.7 1.55 0 .65.35 1.25m47.25-22.45q3.05 0 3.05-3.05-.3-3.2-3.05-3.2-1.25 0-2.25 1-.95 1-.95 2.05 0 .9.45 1.7.8 1.5 2.75 1.5z"
                    id="SkiMor002__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M56.85 51.55q0-1.45-1.3-1.45-1.5 0-1.45 1.55-.05 1.25 1.45 1.25 1.3 0 1.3-1.35M74.8 93.6q-.05 1.25 1.45 1.25 1.3 0 1.3-1.35 0-1.45-1.3-1.45-1.5 0-1.45 1.55M19.65 66.65q-1.5 0-1.5 1.55 0 1.3 1.5 1.3 1.35 0 1.35-1.35 0-1.5-1.35-1.5m-17.7-19.6Q0 47.05 0 49.1q0 1.75 1.95 1.75 1.8 0 1.8-1.85 0-1.95-1.8-1.95M12.2 1.45Q12.2 0 10.9 0 9.4 0 9.45 1.55 9.4 2.8 10.9 2.8q1.3 0 1.3-1.35m117 55.2q-2.9-.05-2.9 3 0 .85.35 1.4.6 1.1 2.55 1.15 1.7-.05 2.35-1.15.3-.6.3-1.55 0-2.9-2.65-2.85M102.25 59q-.05 1.25 1.45 1.25 1.3 0 1.3-1.35 0-1.45-1.3-1.45-1.5 0-1.45 1.55m46.65-22.5q1.8 0 1.8-1.8 0-1.95-1.8-1.95-1.95 0-1.95 2.05 0 1.7 1.95 1.7z"
                    id="SkiMor002__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor002__Symbol_6_0_Layer0_0_FILL"
                transform="translate(119.55 150.7)"
                id="SkiMor002__col1n"
            />
            <use
                xlinkHref="#SkiMor002__Symbol_5_0_Layer0_0_FILL"
                transform="translate(120.8 152)"
                id="SkiMor002__col2n"
            />
        </g></g>
    )
}

export default SkiMor002
