import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#C1D2E2",
    "col1n": "#8FAFCD",
    "col2n": "#6486A6"
}
function FacEar012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M100.15 47.4q-1.1-18.55-14.55-32-9-9-20.25-12.45-7.1-2.2-15.1-2.2-20.7 0-35.4 14.65Q.25 30.05.25 50.75q0 20.7 14.6 35.35l.2.2q14.6 14.45 35.2 14.45 9.8 0 18.2-3.25 6-24.9 25.35-44.25 3.1-3.1 6.35-5.85M272 .25q-8 0-15.1 2.2-11.25 3.45-20.25 12.45-13.5 13.5-14.55 32.1 3.15 2.7 6.2 5.75 19.25 19.25 25.3 44 7.9 3.5 18.4 3.5 20.6 0 35.15-14.45l.2-.2Q322 70.95 322 50.25q0-20.7-14.65-35.35Q292.7.25 272 .25z"
                    id="FacEar012__Symbol_30_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M69.3 42.55q0 1.788-.15 3.5 1.733-5.85 1.55-12.45-.5-16.25-12.25-27.35Q46.7-4.9 30.45-4.4 14.25-3.9 3.1 7.9q-4.236 4.48-6.8 9.55 1.578-1.92 3.4-3.75Q11.65 1.8 28.55 1.8q16.9 0 28.8 11.9Q69.3 25.65 69.3 42.55M286.1 13.7q1.8 1.85 3.35 3.8-2.5-5.1-6.75-9.6-11.15-11.8-27.35-12.3-16.25-.5-28 10.65-11.75 11.1-12.25 27.35-.2 6.6 1.5 12.5-.1-1.75-.1-3.55 0-16.9 11.95-28.85 11.9-11.9 28.8-11.9t28.85 11.9z"
                    id="FacEar012__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M71 35.5q0-14.7-10.4-25.1Q50.2 0 35.5 0 20.8 0 10.4 10.4 0 20.8 0 35.5q0 14.7 10.4 25.1Q20.8 71 35.5 71q14.7 0 25.1-10.4Q71 50.2 71 35.5m228.6 0q0-14.7-10.4-25.1Q278.8 0 264.1 0 249.4 0 239 10.4q-10.4 10.4-10.4 25.1 0 14.7 10.4 25.1Q249.4 71 264.1 71q14.7 0 25.1-10.4 10.4-10.4 10.4-25.1z"
                    id="FacEar012__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar012__Symbol_30_0_Layer0_0_FILL"
                transform="translate(31.4 33.55)"
                id="FacEar012__col1n"
            />
            <use
                xlinkHref="#FacEar012__Symbol_18_0_Layer0_0_FILL"
                transform="translate(49.6 45.35)"
                id="FacEar012__col1l"
            />
            <use
                xlinkHref="#FacEar012__Symbol_36_0_Layer0_0_FILL"
                transform="translate(42.7 53.4)"
                id="FacEar012__col2n"
            />
        </g></g>
    )
}
export default FacEar012
