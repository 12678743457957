import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.55 0l-6.3 3.75L8.2 40.95q-.2 1-.55 1.9-.15.85-.4 1.75L0 90.6l13 32.35q.05.15.2.25.1.1.25.1H38.5l-1.45-1.45q-2.45-2.45-4.75-5-13.75-15.7-19.4-34.75V40.25L25.05.75q.25-.4.5-.75M201.3 40.95l-11.05-37.2-6.3-3.75q.25.35.5.75l12.15 39.5V82.1q-4.193 14.138-12.85 26.4-3.05 4.113-6 7.4-2.901 3.29-4.5 5-1.55 1.703-2.25 2.4h25.05q.15 0 .25-.1.15-.1.2-.25l13-32.35-7.25-46q-.25-.9-.4-1.75-.35-.9-.55-1.9z"
                    id="HaiSho004__Symbol_125_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 40.6l.1.25q.05.15.2.25.1.1.25.1l3.3-21.25L5.1 41.2h1.85l3.4-11.5 3.1 11.5h.75l1.9-5.25.95 5.25h8.55l-1.45-1.45q-2.45-2.45-4.75-5Q5.65 19.05 0 0v40.6m170.4-13.55q-2.934 3.787-5.75 6.9-2.83 3.11-4.35 4.8-1.506 1.747-2.2 2.45h7.6l3.4-6 1.45 6 2.8-10 2.8 10 3.2-21 3.8 21q.15 0 .25-.1.15-.1.2-.25l.1-.25V0q-4.3 14.5-13.3 27.05z"
                    id="HaiSho004__Symbol_63_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho004__Symbol_125_0_Layer0_0_FILL"
                transform="translate(87.75 99.7)"
                id="HaiSho004__col1n"
            />
            <use
                xlinkHref="#HaiSho004__Symbol_63_0_Layer0_0_FILL"
                transform="translate(100.65 181.8)"
                id="HaiSho004__col1d"
            />
        </g></g>
    )
}

export default HaiSho004
