import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#D19532",
    "col1n": "#A37831"
}

function AccMor061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.55 4.55q-3.6 5.85-6.65 11.85-2.45 4.6-4.6 9.3-1.95 3.95-3.7 8-.35.45-.55.95l-3 7.4q-1.8 4.4-3.4 8.9V51q-.2.55-.45 1.25-.95 2.8-1.85 5.7-.9 2.65-1.65 5.4-.25.65-.45 1.35L5.1 68.6Q4 72.65 3.05 76.8L1.5 83.55q-.55 2.55-1.1 5Q.2 89.6 0 90.6l.7-.2q.2-.15.35-.35l5.05-5.3q.4-.45.8-.8h-.1q1.95-2.2 3.65-4.45.95-1.15 1.85-2.2l.05.05q.35-.7.85-1.25l4.6-7.05q1.75-2.7 3.35-5.3.15-.05.25-.1 1.9-3.2 3.65-6.65l-.05.05q1.75-3.3 3.15-6.4L15.65 54h-.2l13.9-8.25L31.1 32.9v-.05l1-8-.05.05L35.35 0q-1.4 2.25-2.8 4.55z"
                    id="AccMor061__Symbol_158_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 82.55q.55-.35.95-.65 1.95-2.2 3.65-4.45.95-1.15 1.85-2.2-1.1.6-2.05 1.2-1.6 1-3.05 1.8l-.5 1.7q-.45 1.4-.85 2.6m3.9-12.3L11.95 67q1.75-2.7 3.35-5.3Q9 64 5.55 65.1q-.85 2.7-1.65 5.15m5.9-18.3h-.2l-.2.1q-1 4.1-1.9 6.8 4.35-1.4 11.65-3.85 1.75-3.3 3.15-6.4L9.8 51.95m5.6-15.35q-1.95 5.2-3.25 8.6 1.5-.85 3.1-1.65 4.9-2.3 8.95-4.95l1.05-7.75q-5.6 3.5-9.85 5.75m12.55-26.4L29.25 0l-.1.6Q27.85 4.2 25 11.8q.55-.3 1.1-.55.3-.1 1.85-1.05m-.85 6.15q-1.85 1.1-5.1 3.15-1.45 3.95-2.8 7.5 2.4-1.55 5.05-3.05 1.05-.65 1.95-1.1l.9-6.5z"
                    id="AccMor061__Symbol_59_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor061__Symbol_158_0_Layer0_0_FILL"
                transform="translate(259.55 13.8)"
                id="AccMor061__col1n"
            />
            <use
                xlinkHref="#AccMor061__Symbol_59_0_Layer0_0_FILL"
                transform="translate(265.4 15.85)"
                id="AccMor061__col1l"
            />
        </g></g>
    )
}

export default AccMor061
