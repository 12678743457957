import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000"
}

function ObjFoo044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#EAEAEA"
                    d="M113.9 273.9l3.4 9.95.2.85 1.65 4.6 3.2-1.05 1.75 5.45-3.6 1.1 1.1 3.95 5.05-1.65-.1-.1q-2.25-2.25-2.25-5.4 0-2.516 1.4-4.45l-.05-.25.35-.15.55-.55q.983-.983 2.1-1.55l-4.7-14.15-10.05 3.4z"
                    id="ObjFoo044__Layer16_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M10 0L0 3.35 1.05 6.5 4.4 5.4l.3.9-3.35 1.15 1.6 4.8 3.4-1.05.3.9-3.3 1.1 1.8 5.3 3.25-1.1.25.85-3.3 1.05 1.55 4.6 10-3.4L10 0z"
                    id="ObjFoo044__Symbol_72_0_Layer0_0_FILL"
                />
                <g id="ObjFoo044__Layer16_0_FILL">
                    <path d="M116.65 251.15q-.39-1.226-.55-2.1l-9 3q.102.418.15.95 0 .447-.15.65l.35 1.05q.173.1.4.3.55.5.95 1.05.4 1.1.4 2.15 0 .83-.2 1.15l.4 1.2q.55.15.8.7l.5 1.35q.05.25.65 2.65 0 .376-.2.5l.25.8q.274.095.75.7.45.65.6 1 .35.85.35 1.75v.85q-.035.207-.15.35l1.15 3.25 3.8-1.3q1.518-.096 3.1-1.15l.1.1 2.95-1.2-1.05-3.2q-3.947-7.42-5.65-15-.568-1.022-.7-1.55z" />
                    <path
                        fill="#CCC"
                        d="M101.9 224.55l7.25 21.7-4.05 1.5 1.05 3.05q.68.035.95 1.25l9-3q-.15-.63-.15-1.05 0-.383.15-.65l-1-3-4.2 1.35-13.05-36.5 4.05 15.35z"
                    />
                </g>
                <g id="ObjFoo044__Layer16_2_FILL">
                    <path d="M121.25 283.55l-.25-.85-3.7 1.15.2.85 3.75-1.15m-1.55-5.1l-.3-.95-3.85 1.35.3.85 3.85-1.25z" />
                    <path
                        fill="#CCC"
                        d="M117.9 272.55l1.5 4.95.3.95 1.3 4.25.25.85 1.4 4.55 2.2-.75q.05 0 .15-.1 0-.1.45-.1l-4.85-15.5-2.7.9z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo044__Layer16_0_FILL" />
            <use xlinkHref="#ObjFoo044__Layer16_1_FILL" />
            <use xlinkHref="#ObjFoo044__Layer16_2_FILL" />
            <use
                xlinkHref="#ObjFoo044__Symbol_72_0_Layer0_0_FILL"
                transform="translate(106.1 247.3)"
                id="ObjFoo044__col1n"
            />
        </g></g>
    )
}

export default ObjFoo044
