import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C40000"
}

function ShiSho060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M36.55 23.1q-2.85-5.7-7.85-9.55-8.45-3.6-16.15-8.9L11 3.5Q9.55 2.45 8.2 1.4L6.35 0q-.1.05-.15.15-1.5 2-2.9 4.1l.45.45L6 7.6Q8.8 12 9.95 18.65q.65 3.85.75 8.5v1.25q-.05.7-.05 1.4-.05.35-.05.7-.1 1.6-.25 3.3l-.2 1.6q-.1.4-.15.8l-.2 1.4-2.3 11.85q-.55 2.7-1.1 5.45L2.15 72.35q-.1.5-.25 1.05-.05.3-.15.55L.05 80.6v.25L0 80.9h.25l.3.1.15.05H.85q2.05.5 4.15.95 3 .7 6.05 1.3 1 .25 2.05.4 6.95 1.2 13.1-.4 9.55-2.85 12.45-13.25 2.4-20.2 0-40.8l-.1-.4q-.45-1.8-1.05-3.4-.45-1.25-.95-2.35M126.8 7.6l2.25-2.9q.2-.2.45-.4-1.5-2.2-3.05-4.3l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-7.7 5.35-16.1 8.95-5.05 3.8-7.9 9.5-.5 1.1-.95 2.35-.6 1.6-1.05 3.4l-.1.4q-2.4 20.6 0 40.8 2.9 10.4 12.45 13.25 6.25 1.55 12.75.5 1.2-.2 2.4-.5 2.95-.6 5.95-1.3 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45.1-.1-1.25-5.2q-.2-1-.4-1.95l-1.8-9.25q-.35-2.15-.7-4.35v-.15l-.05-.25q-.2-1.2-.35-2.4-.15-1.7-.25-3.3l-.1-2.1v-1.25q0-1.1.1-2.15.15-3.4.65-6.35Q124 12 126.8 7.6z"
                    id="ShiSho060__Symbol_170_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho060__Symbol_170_0_Layer0_0_FILL"
                transform="translate(126.1 228.6)"
                id="ShiSho060__col1n"
            />
        </g></g>
    )
}

export default ShiSho060
