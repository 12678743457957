import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1l": "#434343",
    "col1n": "#252525",
    "col1s": "#000000",
    "col2n": "#FFFFFF"
}

function ObjToy074({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M382.15 329.8v-4.85q-38.22 3.91-76.1 10.55-2.15 2.585 0 5.5l76.1-11.2z"
                    id="ObjToy074__Layer14_0_FILL"
                />
                <path
                    fill="#252525"
                    d="M388.1 316.25h.05q1.2.25 2.35.8.45.2 5.6 3.2h-.05q.7.4 1.5.8h-.05q7.85 4 20.2 4 3.2 0 11.45-1.7 8.05-1.7 14.55-1.7 10.55 0 20.05 4.45 5.15 2.35 7.55 3.15 4.6 1.4 10.1 1.4 7 0 25 .15 17.95.15 84.75-3.75.3 0 .5-.25t.2-.55q0-.3-.25-.5t-.55-.2q-66.7 3.9-84.65 3.75-18-.15-25-.15-5.3 0-9.65-1.35-2.35-.75-7.35-3.05-9.85-4.6-20.7-4.6-6.6 0-14.85 1.75-8.05 1.65-11.15 1.65-11.95 0-19.5-3.85-.75-.35-1.35-.75-5.2-3-5.7-3.25-1.3-.6-2.65-.9-2.35-.65-5.1-.65h-.65q-.35 0-.75.05v1.5q.4-.05.75-.05h.65q2.55 0 4.7.6z"
                    id="ObjToy074__Layer14_1_FILL"
                />
                <path
                    fill="#121212"
                    d="M306.05 341v-5.5q-14.211-6.654-31.9-12.15v4.9l31.9 12.75z"
                    id="ObjToy074__Layer14_2_FILL"
                />
                <path
                    d="M319.65 336.2q0-.65-.4-1.15-.5-.4-1.15-.4h-.35l-2.65.4v.05h-.15q-.5.05-.9.45-.45.45-.45 1.1 0 .65.45 1.1.4.4.95.45h.1q.35 0 .6-.1l2.4-.35H318.25q.55-.05 1-.5.4-.45.4-1.05m-9.8 1q0 .4.3.7.35.3.7.3.45 0 .75-.3.25-.3.25-.7 0-.45-.25-.75-.3-.25-.75-.25-.35 0-.7.25-.3.3-.3.75m62.3-9.4q-.35.35-.35.7 0 .45.35.75.3.3.65.3.45 0 .75-.3t.3-.75q0-.35-.3-.7-.3-.3-.75-.3-.35 0-.65.3m10-2.85l-3.9-1.05-102.65-.75-1.45.2 31.9 12.15 76.1-10.55m-2.75 3.45v-2.1l-3.8.6v2.05l3.8-.55z"
                    id="ObjToy074__Layer14_3_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M112.15 20.3V9.7Q79 9.05 75.1 0 42.3 11.05 0 8.35v.05q1.15 5.05 0 10.05h.05Q16.8 23.7 32.8 30.95l79.35-10.65z"
                    id="ObjToy074__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M112.15 9.8v-.1L75.1 0 0 8.35v.05l32.8 11.35 79.35-9.95z"
                    id="ObjToy074__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M32.8 22.55v-11.2L0 0v10.05h.05l32.75 12.5M31.75 12v9.2L.95 9.55v-8.4L31.75 12z"
                    id="ObjToy074__Symbol_85_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M30.8 20.05v-9.2L0 0v8.4l30.8 11.65m-.8-7.1L29.95 14 .65 3.25v-.9L30 12.95m0 3.45v1L.65 6.5v-.9L30 16.4m0 1.65V19L.65 7.95v-.8L30 18.05m0-2.25q-.003-.09-.05.05l-29.3-11V3.9L30 14.75v1.05m0-4.5v1L.65 1.75v-.9L30 11.3z"
                    id="ObjToy074__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.95 1.15Q.75.95.5.95.45.9.35.9 0 1.5 0 2.25 0 3.2.65 3.8q0-.3.25-1.1.2-.65.3-.9.2-.3.25-.4-.25-.2-.5-.25M3.25 3q-.2-.4-.55-.7-.4-.45-.55-.55-.75 1-1.1 1.85v-.05q-.1.2-.15.25 0 .15-.05.25.35.2.8.35.2.05.5.05t.6-.05q.55-.15.9-.45 0-.1-.05-.3-.1-.3-.35-.65M2.7.45q.15 0 .55-.15h.05Q2.85 0 2.15 0q-.9 0-1.5.65.6-.05 1 0 .2.15.35.15.3-.25.4-.25.2-.05.3-.1m0 .7v.05q.25.15.35.3.2.2.45.55.15.3.25.85.1.6.1.85.6-.6.6-1.5T3.8.65Q3.75.6 3.65.55q-.1.05-.3.1-.2.15-.65.5z"
                    id="ObjToy074__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjToy074__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy074__Layer14_1_FILL" />
            <use xlinkHref="#ObjToy074__Layer14_2_FILL" />
            <use xlinkHref="#ObjToy074__Layer14_3_FILL" />
            <use
                xlinkHref="#ObjToy074__Symbol_138_0_Layer0_0_FILL"
                transform="translate(271.05 303)"
                id="ObjToy074__col1n"
            />
            <use
                xlinkHref="#ObjToy074__Symbol_93_0_Layer0_0_FILL"
                transform="translate(271.05 303)"
                id="ObjToy074__col1l"
            />
            <use
                xlinkHref="#ObjToy074__Symbol_85_0_Layer0_0_FILL"
                transform="translate(271.05 311.4)"
                id="ObjToy074__col1d"
            />
            <use
                xlinkHref="#ObjToy074__Symbol_63_0_Layer0_0_FILL"
                transform="translate(272 312.55)"
                id="ObjToy074__col1s"
            />
            <use
                xlinkHref="#ObjToy074__Symbol_50_0_Layer0_0_FILL"
                transform="translate(375.7 316.65)"
                id="ObjToy074__col2n"
            />
        </g></g>
    )
}

export default ObjToy074
