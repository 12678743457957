import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M89.45 88.1q-.45-14.4-2.9-26.65Q80.5 34.2 61.5 17.1 42.6.6 21 0 9.15-.15 0 8.6q5.05-.85 9.3-1.05h.9q.65 0 3.35.45 2.65.4 3.4.95.75.5.85.55-9.5.85-14.8 9.2 12.1-1.85 18 2.2-10.45-1.45-17.55 7.25 11.65-3.5 37.35 9.6 5.05 2.55 9.2 5.6 10.7 7.7 14.65 21.25.95 2.8 1.6 5.8 6.1 53.25 5.75 89.5v.25q.15 33.45.4 37.65.25 4.35.95 17.55l3.45-18.1q1.3-24.75 3.95-40.6 4.3 31.15 4.7 37.3.4 6.25 2.15 17.65.9 6.95 1.15-.55.2-7.55.75-13.5V94.2v-3.3q-.05-1.4-.05-2.8z"
                    id="HaiHig008__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig008__Symbol_12_0_Layer0_0_FILL"
                transform="translate(199.5 65.45)"
                id="HaiHig008__col1n"
            />
        </g></g>
    )
}

export default HaiHig008
