import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#621F00",
    "col1s": "#000000",
    "col2n": "#D19532"
}

function ObjToy023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.85 94.2q-6.3-3.1-10.3 2.1l3.05 14.95v.15q5.85 2.3 10.85-2.5l-3.6-14.7M0 1.75l7.5 36.3q5.5 3.7 8.65-2L7.6 0 0 1.75z"
                    id="ObjToy023__Symbol_173_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M22.35 58.15l-.85-3.65q-5.367-4.067-10.2 2.3l.75 3.7 10.3-2.35M.6 4.95q5.241 1.754 8.75-1.9L8.65 0-.1 1.65l.7 3.3z"
                    id="ObjToy023__Symbol_172_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M8.8 0L0 1.75 3.55 18.6v.1l1.25 6.55v.15l.15.2h.55q7.85 1.2 8.8 20-.35 1.2-.95 2.35-.7 1.55-1.85 2.25-.6.35-1.4.7l.35 1.4v.1l.7 3.15 10.25-2.2-2.65-11v-.25L18 39.15q-1.35-.6-2.45-1.7Q13.3 35.2 13.3 32q0-2.882 1.8-5v-.2L8.8 0z"
                    id="ObjToy023__Symbol_171_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M77.1 79.45q-2.326-1.039-3.5.8l3.4 17.3q3.559 4.393 4.3-1.05l-4.2-17.05z"
                    id="ObjToy023__Layer2_0_FILL"
                />
                <path
                    fill="#432101"
                    d="M77.1 79.45l-.6-2.25-3.35.7.45 2.35 3.5-.8z"
                    id="ObjToy023__Layer2_1_FILL"
                />
                <path
                    fill="#F1DDAF"
                    d="M82.5 101.9q-.15-.6-.25-1.05l-.95-4.35-4.3 1.05 25.95 125.3 7.6-1.75L82.5 101.9z"
                    id="ObjToy023__Layer2_2_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy023__Symbol_173_0_Layer0_0_FILL"
                transform="translate(102.95 221.1)"
                id="ObjToy023__col1n"
            />
            <use
                xlinkHref="#ObjToy023__Symbol_172_0_Layer0_0_FILL"
                transform="translate(110.45 257.15)"
                id="ObjToy023__col1s"
            />
            <use
                xlinkHref="#ObjToy023__Symbol_171_0_Layer0_0_FILL"
                transform="translate(110.9 259.6)"
                id="ObjToy023__col2n"
            />
            <use xlinkHref="#ObjToy023__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy023__Layer2_1_FILL" />
            <use xlinkHref="#ObjToy023__Layer2_2_FILL" />
        </g></g>
    )
}

export default ObjToy023
