import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFEAAD",
    "col1n": "#FFD446",
    "col2l": "#FF0000",
    "col2n": "#C40000"
}
function AccHat164({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M264.4 68.7q-1.1-1.1-2.7-1.15l-21.8.1q-.35-.1-.7-.1l-21.5.1v-.15h-7.3l.05.15h-1.35q-5.5-7.3-2.1-15.35h.15l-2.5-8.75q-2.85-9.15-9.2-16.95-.3-.4-.7-.85-.1-.1-.15-.2l-.35-.35h-.05q-.15-.25-.4-.5-3.3-3.85-7.45-7.35-17-14.2-39.85-16.75-5-.6-10.3-.6-1.15 0-2.25.05h-.8Q131.9 0 130.6 0q-5.3 0-10.3.6-22.85 2.55-39.85 16.75-4.15 3.5-7.45 7.35-.25.25-.4.5h-.05l-.35.35-.2.2q-.35.45-.65.85-6.35 7.8-9.2 16.95l-2.5 8.75h.05q3.1 7.7-2.1 15.35h-2.75l2.25-.15q-.55 0-.95.05-1.1 0-1.45.05h-.15q0-.05.05-.1l-28.25-.1q-.35 0-.7.1l-21.8-.1q-1.6.05-2.7 1.15Q0 69.7 0 71.35t1.15 2.8q1.1 1.1 2.7 1.15H26.6l184.25-.05v.2h50.85q1.6-.05 2.7-1.15 1.15-1.15 1.15-2.8t-1.15-2.8z"
                    id="AccHat164__Symbol_543_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M80.5 34.9Q77.45 9.45 63.25 4.55 56.4 1.25 48.4.3q-3-.3-6.15-.3H38.9q-3.1 0-6.1.3Q26.85 1 21.6 3 3.8 12.3 1.3 31.9L0 40.65q41.2 5.6 81.65 0L80.5 34.9z"
                    id="AccHat164__Symbol_541_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M152.65 15.35L149.9 0H2.6L0 15.35h152.65z"
                    id="AccHat164__Symbol_544_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M85.45 15.35L83.5 0H2.05L0 15.35h85.45z"
                    id="AccHat164__Symbol_542_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat164__Symbol_543_0_Layer0_0_FILL"
                transform="translate(51.85 23.15) scale(1.05931)"
                id="AccHat164__col1n"
            />
            <use
                xlinkHref="#AccHat164__Symbol_541_0_Layer0_0_FILL"
                transform="translate(149.25 35.5) scale(1.05931)"
                id="AccHat164__col1l"
            />
            <use
                xlinkHref="#AccHat164__Symbol_544_0_Layer0_0_FILL"
                transform="translate(112.4 78.55) scale(1.05931)"
                id="AccHat164__col2n"
            />
            <use
                xlinkHref="#AccHat164__Symbol_542_0_Layer0_0_FILL"
                transform="translate(147.25 78.55) scale(1.05931)"
                id="AccHat164__col2l"
            />
        </g></g>
    )
}
export default AccHat164
