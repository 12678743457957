import { AssetSvgProps } from "../../../shared/assets"

function ShiMot066({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 269.6v12.15q34.817 10.189 66.5 0V269.6q-33.25-7.515-66.5 0z"
                    id="ShiMot066__Layer5_0_FILL"
                />
                <g id="ShiMot066__Layer5_1_FILL">
                    <path
                        fill="#008C45"
                        d="M159.2 281.75v11.85h66.5v-11.85h-66.5z"
                    />
                    <path fill="red" d="M159.2 257.8v11.8h66.5v-11.8h-66.5z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot066__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot066__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot066
