import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M188.8 73.05h.5q1.9 1.45 2.9 4.7.65 2.1.85 5.05.042.169.05.35-.158-9.634-2.1-18.1-8.95-26.85-30.6-44-13.9-11.4-26-16.45-.05-.05-.1-.05-2.85-1.2-5.6-2.05l-1.3-.4q-1.85-.55-3.6-.9h-.15q-8-2.2-16.75 0-1.5.35-2.95.9-4 1.5-7.4 4.45-3.4-2.95-7.4-4.45Q78.3-2.1 66 2.1h-.3l-1.3.4q-2.75.85-5.6 2.05-.05 0-.1.05-12.1 5.05-26 16.45-21.65 17.15-30.6 44Q.155 73.429 0 83.1q.006-.169.05-.3.2-2.95.85-5.05 1-3.25 2.9-4.7h.5q-3 9.6-3.35 24.2l4-1.65q.2-3.3 1.4-8.2.35 4.55 1.25 7.05l14.9-6.35q-.85-7.85-.55-14.75.85-7 1.55-9 .75-2.05 1.8-3.9-3.35 13.8-.35 26.6.05.2.1.35l.1-.4q1.9-8.55 2.45-10 .55-1.55 1.9-5.2 1.35-3.65 2.35-5.45 1-1.85 2.05-2.85l.05-.05q-.2.5-.4.95l-.85 2.3q-.15.35-.25.65-.3.85-.65 1.7l-.05.25q-.45 1.25-.85 2.45Q29 78.9 29 85.35l7-2.95q-.05-1 .55-4.9.7-4.9 3.4-11.3 2.75-6.4 5.7-11.5-4.2 14.15-2.45 24.65l14.15-5.95 3.4-2.5q.5-6.15 1.05-8.05.55-1.9.65-2.35-.75 4.5-.1 9.1l4.35-3.05q.4-3.85 1.35-7.55 3.2-12.7 11.05-12.7 6.7-.05 15.6 6.9.3.2.6.45.5.4.95.8.1.05.2.15v.15q.05-.05.1-.05t.1.05v-.15q.1-.1.2-.15.45-.4.95-.8.3-.25.6-.45 8.9-6.95 15.6-6.9 7.85 0 11.05 12.7 1.05 3.9 1.45 8.05l3.85 2.65q.435-4.35 0-8.55.079.592.35 1.6.5 1.9 1.25 8.05l3.7 2.55.1.05 14.15 5.95q1.75-10.5-2.45-24.65 2.95 5.1 5.7 11.5 2.7 6.4 3.4 11.3.6 3.9.55 4.9l7 2.95q0-6.45-1.9-13.6-.4-1.2-.85-2.45l-.05-.25q-.35-.85-.65-1.7-.1-.3-.25-.65l-.85-2.3q-.2-.45-.4-.95l.05.05q1.05 1 2.05 2.85 1 1.8 2.35 5.45 1.35 3.65 1.9 5.2.55 1.45 2.45 10l.1.4q.05-.15.1-.35 3-12.8-.35-26.6 1.05 1.85 1.8 3.9.7 2 1.55 9 .3 6.9-.55 14.75l14 5.95q.05-.1.05-.15 0-.15.05-.3.9-2.5 1.25-7.05 1.2 4.9 1.4 8.2 0 .15.05.3v.2l4.75 2q-.35-14.6-3.35-24.2z"
                    id="HaiSho070__Symbol_191_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.9 73.05h.5l1.55-5.15Q2.4 68.5 0 77.75q1-3.25 2.9-4.7m28-4l-.05.25q-.45 1.25-.85 2.45-1.9 7.15-1.9 13.6l2.55-1.05q.55-14.2 8.15-33.7 1.4-3.9 3.1-8.35Q36.4 54.7 33 63.5l.05-.05q-.2.5-.4.95l-.85 2.3q-.15.35-.25.65-.3.85-.65 1.7m-9.85 4.3q.85-7 1.55-9 .75-2.05 1.8-3.9-7.15 9.2-5.7 28.9l2.9-1.25q-.85-7.85-.55-14.75M30.4 33.3l-.15.15h.1l-.1.1h.15v-.25M75.2 40q-3.85-1.5-6.8 0-5.95 2.6-9.2 13.4-2.75 9-2.75 17.25v2.75l3.4-2.5q.5-6.15 1.05-8.05.55-1.9.65-2.35-.75 4.5-.1 9.1l4.35-3.05q.4-3.85 1.35-7.55 3.2-12.7 11.05-12.7 6.7-.05 15.6 6.9-9.6-9.35-18.6-13.2m50.4 27.05l3.85 2.65q.435-4.35 0-8.55.079.592.35 1.6.5 1.9 1.25 8.05l3.7 2.55.1.05v-2.75q0-8.25-2.75-17.25-3.25-10.8-9.2-13.4-2.95-1.5-6.8 0-9 3.85-18.6 13.2 8.9-6.95 15.6-6.9 7.85 0 11.05 12.7 1.05 3.9 1.45 8.05m32.65-3.6l.05.05q-3.4-8.8-8.9-21.25 1.7 4.45 3.1 8.35 7.6 19.5 8.15 33.7l2.55 1.05q0-6.45-1.9-13.6-.4-1.2-.85-2.45l-.05-.25q-.35-.85-.65-1.7-.1-.3-.25-.65l-.85-2.3q-.2-.45-.4-.95m-62.5-56q0-.4.05-.8-.05-.05-.15-.1-3.4-2.95-7.4-4.45-10.85-4.2-23.15 0-3.5.95-7.15 2.5Q63 3 67.25 2.9q4.3-.1 6.6.45 2.3.5 5.05 1.55-7.35-1.45-14.65.7l-.9.3q.85-.05 1.65-.05 15.95 0 25.95 8.65l1.3 1.75q-5.05-2.9-10.85-2.5-2.75-.1-5.1.35-2.3.45-5.1 1.7 5.95-.2 9.55 1.7-6.8-1.05-13.9 1.8 13.75-2.5 21.4 7.9l.15.15.2.3q.7.95.85 1.8-4.7-4.75-15.7-4.45-10.95.3-20 6.6Q70 24 82.8 31.35q3 1.5 5.4 3.95 1.2 1.15 2.2 2.6 3.85 5.75 5.15 14 .05.3.1.65l.1.7V7.45m.35-1.15v.05h.1q.048-.048.1-.05h-.2m64.8 27.25h.15l-.1-.1h.1l-.15-.15v.25m7.8 30.8q.7 2 1.55 9 .3 6.9-.55 14.75l2.9 1.25q1.45-19.7-5.7-28.9 1.05 1.85 1.8 3.9m17.65 3.55l1.55 5.15h.5q1.9 1.45 2.9 4.7-2.4-9.25-4.95-9.85z"
                    id="HaiSho070__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M41.9 31.5l-.6-.4q-8.85-5.9-19.4-6.2-11-.3-15.7 4.45.15-.85.85-1.8l.2-.3.15-.15q7.65-10.4 21.4-7.9-7.1-2.85-13.9-1.8 3.6-1.9 9.55-1.7-2.8-1.25-5.1-1.7-2.35-.45-5.1-.35-5.8-.4-10.85 2.5l1.3-1.75q10-8.65 25.95-8.65.8 0 1.65.05l-.9-.3q-7.3-2.15-14.65-.7 2.75-1.05 5.05-1.55 2.3-.55 6.6-.45 4.25.1 9.3 1.7-3.65-1.55-7.15-2.5-.25-.1-.55-.15-1.5-.45-2.9-.75-8-2.2-16.75 0-1.5.35-2.95.9-4 1.5-7.4 4.45.1.05.15.1-.05.4-.05.8V51.8q1.35-8.25 5.15-14 1.15-1.6 2.55-2.95 2.1-2.05 4.7-3.4 12.1-7 28.25-.3.55.15 1.15.35M.65 6.2q-.052.002-.1.05h-.1V6.2h.2z"
                    id="HaiSho070__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho070__Symbol_191_0_Layer0_0_FILL"
                transform="translate(95.95 51.3)"
                id="HaiSho070__col1n"
            />
            <use
                xlinkHref="#HaiSho070__Symbol_102_0_Layer0_0_FILL"
                transform="translate(96.85 51.3)"
                id="HaiSho070__col1d"
            />
            <use
                xlinkHref="#HaiSho070__Symbol_51_0_Layer0_0_FILL"
                transform="translate(192.5 51.4)"
                id="HaiSho070__col1l"
            />
        </g></g>
    )
}

export default HaiSho070
