import { AssetSvgProps } from "../../../shared/assets"

function PlaInd008({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#74696B"
                    d="M385 385V0H0v385h385z"
                    id="PlaInd008__Layer4_0_FILL"
                />
                <path
                    fill="#1A1A18"
                    d="M324.8 120.95V64.6H7.3v56.35h317.5m0-65.05V23.4H7.3v32.5h317.5m0-41.2V0H7.3v14.7h317.5z"
                    id="PlaInd008__Layer2_1_FILL"
                />
                <path
                    fill="#48372F"
                    d="M324.8 55.9V48H7.3v7.9h317.5z"
                    id="PlaInd008__Layer2_2_FILL"
                />
                <path
                    fill="#48372F"
                    d="M324.8 55.9V48H7.3v7.9h317.5z"
                    id="PlaInd008__Layer2_3_FILL"
                />
                <path
                    fill="#48372F"
                    d="M324.8 55.9V48H7.3v7.9h317.5z"
                    id="PlaInd008__Layer2_4_FILL"
                />
                <path
                    fill="#919191"
                    d="M22.1 7.25h-.25q-3.55 0-6.05.85-2.2.75-2.5 1.8-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.05-.9q1.3-.45 1.95-.95l.6-1.2q-.05-.25-.15-.4-.4-.9-2.4-1.6-2.25-.75-5.25-.85h-.55m-.35.6h.45q3.05.05 5.25.65 2.35.7 2.35 1.7 0 .15-.05.3-.1.25-.45.55-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.4-.65-2.4-1.65 0-1 2.4-1.7 2.35-.65 5.7-.65m33.7 1.85q-.4-.9-2.4-1.6-2.25-.75-5.25-.85H47q-3.55 0-6.05.85-2.2.75-2.5 1.8-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9 3.5 0 6.05-.9 1.3-.45 1.95-.95l.6-1.2q-.05-.25-.15-.4M52.6 8.5q2.35.7 2.35 1.7 0 .15-.05.3-.1.25-.45.55-.6.45-1.85.8-2.35.7-5.7.7-3.4 0-5.75-.7-2.35-.65-2.35-1.65 0-1 2.35-1.7 2.35-.65 5.75-.65h.4q3.1.05 5.3.65m23.9 3.75q1.25-.45 1.9-.95l.6-1.2q-.05-.25-.15-.4-.4-.9-2.35-1.6-2.25-.75-5.3-.85h-.8q-3.55 0-6.05.85-2.2.75-2.5 1.8-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.1-.9m1.35-1.2q-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.4-.65-2.4-1.65 0-1 2.4-1.7 2.35-.65 5.7-.65h.45q3.05.05 5.25.65 2.35.7 2.35 1.7 0 .15-.05.3-.1.25-.45.55M92 7.25h-.8q-3.55 0-6.05.85-2.2.75-2.45 1.8-.05.15-.05.3-.05 1.2 2.5 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2q-.05-.25-.15-.4-.4-.9-2.35-1.6-2.25-.75-5.3-.85m-.85.6h.4q3.05.05 5.25.65 2.35.7 2.4 1.7 0 .15-.05.3-.15.25-.5.55-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.35-.65-2.35-1.65 0-1 2.35-1.7 2.35-.65 5.7-.65h.05M124.8 9.7q-.4-.9-2.35-1.6-2.25-.75-5.3-.85h-.8q-3.55 0-6.05.85-2.2.75-2.5 1.8-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2q-.05-.25-.15-.4m-.5.5q0 .15-.05.3-.1.25-.45.55-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.4-.65-2.4-1.65 0-1 2.4-1.7 2.35-.65 5.7-.65h.45q3.05.05 5.25.65 2.35.7 2.35 1.7m24.05-.1q-.05-.25-.15-.4-.4-.9-2.35-1.6-2.25-.75-5.3-.85h-.8q-3.55 0-6.05.85-2.2.75-2.45 1.8-.05.15-.05.3-.05 1.2 2.5 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2m-.65.4q-.15.25-.5.55-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.35-.65-2.35-1.65 0-1 2.35-1.7 2.35-.65 5.7-.65h.45q3.05.05 5.25.65 2.35.7 2.4 1.7 0 .15-.05.3z"
                    id="PlaInd008__Layer1_1_FILL"
                />
                <g id="PlaInd008__Layer3_0_FILL">
                    <path fill="#2B3C4A" d="M385 385v-89H0v89h385z" />
                    <path fill="#3F3B3C" d="M385 195.4v-1.5H0v1.5h385z" />
                    <path fill="#81838F" d="M385 163.4V0H0v163.4h385z" />
                    <path fill="#595153" d="M385 185.65V163.4H0v22.25h385z" />
                </g>
                <g id="PlaInd008__Layer2_0_FILL">
                    <path
                        fill="#4E5464"
                        d="M52.3 234.15h-6.5v69.1H31.15v4.9H66.9v-4.9H52.3v-69.1m133.8 0h-6.5v69.1H165v4.9h35.75v-4.9H186.1v-69.1m144.7 74v-4.9h-14.65v-69.1h-6.5v69.1h-14.6v4.9h35.75z"
                    />
                    <path
                        fill="#663722"
                        d="M81 234.15q5.65-8.35 0-15.7H17.05q-5.65 7.35 0 15.7H81m69.9-15.7q-5.65 7.35 0 15.7h63.95q5.65-8.35 0-15.7H150.9m194 15.7q5.65-8.35 0-15.7h-63.95q-5.65 7.35 0 15.7h63.95z"
                    />
                    <path
                        fill="#FC0"
                        d="M286.15 146.2v-6.4h-7.8v6.4l-5.85 5.85v20.05H292v-20.05l-5.85-5.85z"
                    />
                    <path
                        fill="#4A3426"
                        d="M316.4 139.8h-12.7v31.65h12.7V139.8z"
                    />
                    <path fill="#9B5031" d="M340.5 129.6V0H0v129.6h340.5z" />
                    <path
                        fill="#333"
                        d="M382.6 170q-.25-.05-.4 0h-.05q-3.35-.05-5.7.65-2.4.65-2.4 1.65 0 1 2.4 1.65 2.35.7 5.7.7 1.513 0 2.85-.15v-4.4q-1.133-.1-2.4-.1m-24.25.65q-2.35.65-2.35 1.65 0 1 2.35 1.65 2.35.7 5.7.7t5.7-.7q1.25-.35 1.85-.8.35-.25.45-.55.05-.15.05-.3 0-1-2.35-1.65-2.2-.65-5.25-.65-.25-.05-.4 0h-.05q-3.35-.05-5.7.65z"
                    />
                    <path
                        fill="#666"
                        d="M385 170.1v-18.85h-.3q-1-.15-2.05-.15-2.65 0-4.55.65-1.85.65-1.85 1.55L373.7 172q-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9 1.462-.042 2.75-.2v-.55q-1.337.15-2.85.15-3.35 0-5.7-.7-2.4-.65-2.4-1.65 0-1 2.4-1.65 2.35-.7 5.7-.65h.05q.15-.05.4 0 1.267 0 2.4.1m-25-18.35q-1.85.65-1.85 1.55L355.6 172q-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2q-.05-.2-.15-.4l-1.65-18.45v-.05q-.05-.9-1.9-1.55-1.1-.4-2.45-.5-1-.15-2.05-.15-2.65 0-4.55.65m-4 20.55q0-1 2.35-1.65 2.35-.7 5.7-.65h.05q.15-.05.4 0 3.05 0 5.25.65 2.35.65 2.35 1.65 0 .15-.05.3-.1.3-.45.55-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.35-.65-2.35-1.65z"
                    />
                    <path
                        fill="#959595"
                        d="M342.2 153.3v.05q.35 0 .7.05.2-2.3-4.85-2.7-.15.25-.15.55 1.3.1 2.4.5 1.9.65 1.9 1.55z"
                    />
                    <path
                        fill="#AEAEAE"
                        d="M342.95 153.4h-.05q-.35-.05-.7-.05l1.3 13.95h.75l-1.3-13.85v-.05m1.8 19.2l-.45-4.7h-.75l.35 3.9q.1.25.15.5 0 .15-.05.3h.75z"
                    />
                    <path
                        fill="#EAEAEA"
                        d="M350.65 151.25q0-.9-1.9-1.55-1.85-.65-4.5-.65t-4.5.65q-1.3.45-1.7 1 5.05.4 4.85 2.7h.05v.05l1.3 13.85h-.75l-1.3-13.95v-.05q0-.9-1.9-1.55-1.1-.4-2.4-.5-1-.15-2.1-.15-2.65 0-4.5.65-1.9.65-1.9 1.55l-2.5 18.7q-.05.15-.05.3 0 1.2 2.5 2.05 2.55.9 6.1.9 3.5 0 6.05-.9 1.55-.5 2.2-1.15h1.1q2.95-.15 5.15-.9 2.5-.85 2.55-2.05-.05-.25-.15-.5l-1.7-18.5m-1.15 17.3q.5.15.9.3 1.45.6 1.45 1.4 0 1-2.35 1.65-2 .6-4.75.7H344q.05-.15.05-.3-.05-.25-.15-.5l-.35-3.9h.75q3 .05 5.2.65m-22.25 3.75q0-1 2.35-1.65 2.35-.7 5.75-.65.2-.05.4 0 3.1 0 5.3.65 2.35.65 2.35 1.65 0 .15-.05.3-.15.3-.45.55-.6.45-1.85.8-2.35.7-5.7.7-3.4 0-5.75-.7-2.35-.65-2.35-1.65z"
                    />
                    <path
                        fill="#CCC"
                        d="M329.6 170.65q-2.35.65-2.35 1.65 0 1 2.35 1.65 2.35.7 5.75.7 3.35 0 5.7-.7 1.25-.35 1.85-.8.3-.25.45-.55.05-.15.05-.3 0-1-2.35-1.65-2.2-.65-5.3-.65-.2-.05-.4 0-3.4-.05-5.75.65m14.7-2.75l.45 4.7q2.75-.1 4.75-.7 2.35-.65 2.35-1.65 0-.8-1.45-1.4-.4-.15-.9-.3-2.2-.6-5.2-.65z"
                    />
                </g>
                <g id="PlaInd008__Layer1_0_FILL">
                    <path
                        fill="#025A4A"
                        d="M52.05 70.1H47.3v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m15.5 0H62.8v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1M16.3 78.55l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1H16.3v8.45m20.25-8.45H31.8v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m103.75 8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1h-4.75v8.45m-10.75-8.45h-4.75v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m-42.8 19.95l-3.7-11.5V70.1H78.3v8.45l-3.7 11.5v28.4h12.15v-28.4m11.8-19.95H93.8v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m15.5 0h-4.75v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m50.2 48.35v-28.4l-3.7-11.5V70.1h-4.75v8.45l-3.7 11.5v28.4h12.15m58.3-48.35h-4.8v8.45l-3.65 11.5v28.4h12.1v-28.4l-3.65-11.5V70.1m15.5 0h-4.8v8.45l-3.65 11.5v28.4h12.1v-28.4l-3.65-11.5V70.1m-35.8 8.45l-3.65 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1h-4.8v8.45m-26.2-8.45h-4.75v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m15.5 0h-4.8v8.45l-3.65 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m108.5 0h-4.8v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.65-11.5V70.1m15.45 0h-4.75v8.45l-3.7 11.5v28.4h12.15v-28.4l-3.7-11.5V70.1m-35.75 8.45l-3.65 11.5v28.4h12.1v-28.4l-3.65-11.5V70.1h-4.8v8.45m-26.2-8.45h-4.8v8.45l-3.65 11.5v28.4h12.1v-28.4l-3.65-11.5V70.1m15.5 0h-4.8v8.45l-3.65 11.5v28.4h12.1v-28.4l-3.65-11.5V70.1z"
                    />
                    <path
                        fill="#057A11"
                        d="M38.5 29.85l-2.1-4.6-2.55 1.2 2.1 4.6.85 7.1 7.15 15.4 6.55-3.05-7.1-15.35-4.9-5.3z"
                    />
                    <path
                        fill="#FC0"
                        d="M30.55 32.25V27h-6.4v5.25l-4.8 4.8v16.5h16v-16.5l-4.8-4.8M95 32.3v-5.25h-6.45v5.25l-4.8 4.8v16.5H99.8V37.1L95 32.3m178.4-.05l-4.8 4.8v16.5h16.05v-16.5l-4.85-4.8V27h-6.4v5.25z"
                    />
                    <path
                        fill="#CCC"
                        d="M27.9 12.25q1.3-.45 1.95-.95l.6-1.2q-.05-.25-.15-.4L29.4 0H14.65L13.3 9.9q-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.05-.9m25.15 0q1.3-.45 1.95-.95l.6-1.2q-.05-.25-.15-.4l-.9-9.7H39.8l-1.35 9.9q-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9 3.5 0 6.05-.9M79 10.1q-.05-.25-.15-.4l-.9-9.7H63.2l-1.35 9.9q-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2m20.8 0q-.05-.25-.15-.4l-.9-9.7H84l-1.3 9.9q-.05.15-.05.3-.05 1.2 2.5 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2m24.55 1.2l.6-1.2q-.05-.25-.15-.4l-.9-9.7h-14.75l-1.35 9.9q-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95m24-1.2q-.05-.25-.15-.4l-.9-9.7h-14.75l-1.3 9.9q-.05.15-.05.3-.05 1.2 2.5 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2m58.85-2q1.95.7 2.35 1.6l-.9-9.7H193.9l-1.35 9.9q.3-1.05 2.5-1.8 2.5-.85 6.05-.85h.8q3.05.1 5.3.85m1.35 2.95q.35-.3.45-.55.05-.15.05-.3 0-1-2.35-1.7-2.2-.6-5.25-.65H201q-3.35 0-5.7.65-2.4.7-2.4 1.7t2.4 1.65q2.35.7 5.7.7t5.7-.7q1.25-.35 1.85-.8m15.4-3.2h-.4q-3.4 0-5.75.65-2.35.7-2.35 1.7t2.35 1.65q2.35.7 5.75.7 3.35 0 5.7-.7 1.25-.35 1.85-.8.35-.3.45-.55.05-.15.05-.3 0-1-2.35-1.7-2.2-.6-5.3-.65m-.3-.6h.8q3 .1 5.25.85 2 .7 2.4 1.6l-.9-9.7h-14.75l-1.35 9.9q.3-1.05 2.5-1.8 2.5-.85 6.05-.85z"
                    />
                    <path
                        fill="#919191"
                        d="M209.1 11.3l.6-1.2q-.05-.25-.15-.4-.4-.9-2.35-1.6-2.25-.75-5.3-.85h-.8q-3.55 0-6.05.85-2.2.75-2.5 1.8-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95m-.1-.8q-.1.25-.45.55-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.4-.65-2.4-1.65 0-1 2.4-1.7 2.35-.65 5.7-.65h.45q3.05.05 5.25.65 2.35.7 2.35 1.7 0 .15-.05.3m14.9-3.25h-.25q-3.55 0-6.05.85-2.2.75-2.5 1.8-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9 3.5 0 6.05-.9 1.3-.45 1.95-.95l.6-1.2q-.05-.25-.15-.4-.4-.9-2.4-1.6-2.25-.75-5.25-.85h-.55m-.35.6h.4q3.1.05 5.3.65 2.35.7 2.35 1.7 0 .15-.05.3-.1.25-.45.55-.6.45-1.85.8-2.35.7-5.7.7-3.4 0-5.75-.7-2.35-.65-2.35-1.65 0-1 2.35-1.7 2.35-.65 5.75-.65M385 170.1v-.6q-.929-.1-1.95-.1h-.8q-3.55-.05-6.05.8-2.2.75-2.5 1.8-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9 1.462-.042 2.75-.2v-.55q-1.337.15-2.85.15-3.35 0-5.7-.7-2.4-.65-2.4-1.65 0-1 2.4-1.65 2.35-.7 5.7-.65h.05q.15-.05.4 0 1.267 0 2.4.1m0-14.75v-4.1h-.3q-1-.15-2.05-.15-2.65 0-4.55.65-1.85.65-1.85 1.55t1.85 1.55q1.9.65 4.55.65.75 0 1.5-.05.425-.03.85-.1M355.6 172q-.05.15-.05.3 0 1.2 2.55 2.05 2.5.9 6.05.9t6.1-.9q1.25-.45 1.9-.95l.6-1.2q-.05-.2-.15-.4-.4-.9-2.35-1.6-2.25-.75-5.3-.8h-.8q-3.55-.05-6.05.8-2.2.75-2.5 1.8m.4.3q0-1 2.35-1.65 2.35-.7 5.7-.65h.05q.15-.05.4 0 3.05 0 5.25.65 2.35.65 2.35 1.65 0 .15-.05.3-.1.3-.45.55-.6.45-1.85.8-2.35.7-5.7.7t-5.7-.7q-2.35-.65-2.35-1.65m4-20.55q-1.85.65-1.85 1.55t1.85 1.55q1.9.65 4.55.65.75 0 1.5-.05 1.65-.15 3-.6 1.85-.6 1.9-1.5v-.05q-.05-.9-1.9-1.55-1.1-.4-2.45-.5-1-.15-2.05-.15-2.65 0-4.55.65z"
                    />
                    <path
                        fill="#FFF"
                        d="M342.2 153.35v-.05q0-.9-1.9-1.55-1.1-.4-2.4-.5-1-.15-2.1-.15-2.65 0-4.5.65-1.9.65-1.9 1.55t1.9 1.55q1.85.65 4.5.65.8 0 1.5-.05 1.7-.15 3-.6 1.85-.6 1.9-1.5m9.65 16.9q0 1-2.35 1.65-2 .6-4.75.7H344q.05-.15.05-.3-.05-.25-.15-.5-.45-.9-2.4-1.6-2.25-.75-5.3-.8h-.75q-3.55-.05-6.1.8-2.2.75-2.45 1.8-.05.15-.05.3 0 1.2 2.5 2.05 2.55.9 6.1.9 3.5 0 6.05-.9 1.55-.5 2.2-1.15h1.1q2.95-.15 5.15-.9 2.5-.85 2.55-2.05-.05-.25-.15-.5-.25-.5-.85-.9-.6-.35-1.55-.7-2.4-.8-5.7-.85h-.75l.05.6h.75q3 .05 5.2.65.5.15.9.3 1.45.6 1.45 1.4m-24.6 2.05q0-1 2.35-1.65 2.35-.7 5.75-.65.2-.05.4 0 3.1 0 5.3.65 2.35.65 2.35 1.65 0 .15-.05.3-.15.3-.45.55-.6.45-1.85.8-2.35.7-5.7.7-3.4 0-5.75-.7-2.35-.65-2.35-1.65m21.5-19.5q1.9-.65 1.9-1.55t-1.9-1.55q-1.85-.65-4.5-.65t-4.5.65q-1.3.45-1.7 1 5.05.4 4.85 2.7h.05q.6.05 1.3.05 2.65 0 4.5-.65z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaInd008__Layer4_0_FILL" />
            <use xlinkHref="#PlaInd008__Layer3_0_FILL" />
            <use xlinkHref="#PlaInd008__Layer2_0_FILL" />
            <use xlinkHref="#PlaInd008__Layer2_1_FILL" />
            <use
                xlinkHref="#PlaInd008__Layer2_2_FILL"
                transform="matrix(1 0 0 .93767 0 3.5)"
            />
            <use
                xlinkHref="#PlaInd008__Layer2_3_FILL"
                transform="translate(0 -41.2)"
            />
            <use
                xlinkHref="#PlaInd008__Layer2_4_FILL"
                transform="matrix(1 0 0 1.31645 0 47.35)"
            />
            <use xlinkHref="#PlaInd008__Layer1_0_FILL" />
            <use xlinkHref="#PlaInd008__Layer1_1_FILL" />
        </g></g>
    )
}

export default PlaInd008
