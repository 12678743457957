import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col2n": "#000000"
}

function AccMor065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 9.65h34.2V0H0v9.65z"
                    id="AccMor065__Symbol_166_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 9.65h34.2V0H0v9.65m32.8-8.3V8.2H1.35V1.35H32.8z"
                    id="AccMor065__Symbol_167_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M21.5 2.9q1.5-1.1 2.45-1.35l.1.5q0 .25-.65 2l.05.15q.1.15.2.15.4 0 .9-2.3.05-1-.65-1-.95 0-2.4 1.1-.75.55-1.55 1.25h-.65q-.35-.35-.3-1 .05-.7 0-1 0-.75-.1-1.1-.3-.6-1.35-.05-.6.3-1.1.7-.3.25-.9.8-.45.35-.9.8-.45.35-1.4.65-.4-1.05-1.1-1.05-.45 0-1.05.3-.6.35-.85.45v-.05l.05-.1V.85Q10.25.6 10.2.5q-.15-.2-.55-.2Q9.05.3 5.3 3h-.4q.05-.8.05-1.3 0-.85-.7-.85-1.05 0-2.7 1.55Q0 3.8 0 4.25l.05.15q.1.1.2.1.05 0 1.45-1.55T4.15 1.4q.2 0 .15 1.05 0 1.05.75 1.05.6 0 2.3-1.15 1.8-1.3 2.15-1.5h.25q.05.35-.1 1.5 0 1.05.6 1.05.35 0 .95-.35.6-.4 1.05-.4l.3.55q.3.5.8.5.75 0 2.35-1.4Q17.45.75 18.3.5q.2.55.2 1.7-.1 1.7 1 1.7.6 0 2-1z"
                    id="AccMor065__Symbol_168_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor065__Symbol_166_0_Layer0_0_FILL"
                transform="translate(203.2 264.3)"
                id="AccMor065__col1n"
            />
            <use
                xlinkHref="#AccMor065__Symbol_167_0_Layer0_0_FILL"
                transform="translate(203.2 264.3)"
                id="AccMor065__col1d"
            />
            <use
                xlinkHref="#AccMor065__Symbol_168_0_Layer0_0_FILL"
                transform="translate(207.4 266.95)"
                id="AccMor065__col2n"
            />
        </g></g>
    )
}

export default AccMor065
