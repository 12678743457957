import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.1.15q-.75.75-1.4 1.6Q0 8.95 0 19.15 0 27.6 3.95 34q.1.2.3.25.15.05.35-.05.2-.15.25-.3.05-.2-.05-.4Q1 27.3 1 19.15q0-9.8 5.5-16.8v.05q.65-.8 1.35-1.55.1-.15.1-.35 0-.25-.15-.35Q7.65 0 7.45 0 7.2 0 7.1.15M123.8 34q3.9-6.4 3.9-14.85 0-10.2-5.7-17.4-.65-.85-1.3-1.55-.15-.2-.35-.2-.2 0-.35.15-.2.1-.2.35 0 .15.15.35.6.75 1.25 1.55v-.05q5.5 7 5.5 16.8 0 8.15-3.75 14.35-.15.2-.05.4 0 .15.2.3.2.1.4.05.15-.05.3-.25z"
                    id="SkiMor003__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor003__Symbol_7_0_Layer0_0_FILL"
                transform="translate(128.65 148.35)"
                id="SkiMor003__col1n"
            />
        </g></g>
    )
}

export default SkiMor003
