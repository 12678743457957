import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#B70505",
    "col1n": "#FF0000",
    "col2l": "#FFFFA6",
    "col2n": "#FFFF00"
}

function ObjToy035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.65 5.15q-.9-1.95-1.85-2.4-2.7-1.5-3.55-1.75Q25.6.5 20.2 0h-3l-3.25.35q-.8.2-1.5.6-1.2.3-2.35.85-.5.25-.95.5-5.7 2.95-8 9.15-2.5 6.7.45 13.25 2.1 4.6 6.2 7.25 1.15.7 2.45 1.3.95.4 2 .8l1.4.5q.3.05.85.2.95.3 1.4.35h4.5q8.15-.65 12.8-7.4 3.55-5.2 3.55-10.4 0-2.75-.65-4.65Q35 10 34 7.6q-.95-1.55-1.35-2.45z"
                    id="ObjToy035__Symbol_124_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M28.6 10.65q-.55-1.15-1.2-2.2-.3-.5-.65-.95-2.85-3.95-7.6-5.9-.05 0-.15-.05l-.35-.15Q17.3 1 16.1.7q-.75-.2-1.5-.35-1.4-.25-2.7-.3h-.3q-1.4-.1-2.75 0l-.8.1q-.15.05-.4.1-.15.05-.3.05-1.75.2-3.4.8-.9.25-1.75.7.55-.15 1.1-.35h.15q.75-.2 1.45-.25.3-.05.5-.05h.2q.85-.1 1.7-.05h.45q.85.05 1.65.1.1.05.2.05l.15.05q.1.05.15 0h.05q.25.05.45.15 1.3.25 2.65.75 4.95 1.85 7.9 5.9.6.8 1.1 1.75.35.6.7 1.3 2.95 6.5.45 13.2-.85 2.3-2.2 4.2-.6.85-1.3 1.6-2.25 2.4-5.45 3.9-.2.05-.4.15-1.3.5-2.55.85-.35.15-2.1.45-.05 0-.2.05-.25-.05-.4 0-.05 0-.05.05-.65.05-1.25.05H7q-.35-.05-.7-.05-.05.05-.15 0l-.95-.05-1.1-.2q-1.55-.3-3.1-.85l-1-.4q.95.4 2 .8 2.25.85 4.5 1.3.9.15 1.8.2 1.1.15 2.2.1h1.2q.65-.15 1.2-.2 1.1-.15 2.25-.35 1-.3 2-.7 1.4-.45 2.7-1.1.05-.05.1-.05.4-.2.8-.45l.05-.05q.4-.15.7-.3 3.35-1.9 5.45-4.95.65-.9 1.15-1.9l-.1.05q.3-.55.65-1.15l.4-1.1q2.55-6.9-.45-13.6z"
                    id="ObjToy035__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M12.75 8.9l3.85-5.2-6.2 1.55L6.6 0l-.4 6.45L0 8.35l5.95 2.4-.05 6.5 4.1-5 6.15 2.1-3.4-5.45z"
                    id="ObjToy035__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M24.25 16.9q1.8-4.9-.3-9.55-2.2-4.7-7.05-6.5-4.85-1.8-9.55.3Q2.6 3.3.8 8.2.3 9.5.15 10.8q-.4 2.45.3 4.9.05.35.25.8.15.6.45 1.2 2.05 4.5 6.5 6.35l.5.2q4.85 1.8 9.6-.35 4.7-2.2 6.5-7M22.15 8q1.8 4 .25 8.2-1.5 4.05-5.5 5.9-4 1.8-8.15.25t-5.95-5.5Q1 12.8 2.5 8.75q1.55-4.2 5.55-6 4-1.9 8.15-.35 4.15 1.55 5.95 5.6z"
                    id="ObjToy035__Symbol_15_0_Layer0_0_FILL"
                />
                <g id="ObjToy035__Layer4_0_FILL">
                    <path
                        fill="#646464"
                        d="M133.15 302.45q.6-1.05.95-1.9.35-.9-.05-1.75-.85.1-1.1.15.1.5.1 1.2 0 .4-.9 2.3-.95 1.95-.95 3.4-.05 1.45 1 2.5 1 1 3 .6t2.65-1.2q.6-.85.85-1.7-.55 0-.9-.35-.1.5-.55 1.1-.5.55-1.1.85-.6.3-1.65.3-1.55 0-1.95-.75-.25-.45-.15-2.05.1-1.65.75-2.7m-6.8-6.2l-.65-.7-38.4 17.3-1.35.55v.05q-.4.6-.75 1.2l1.45-.65 39.7-17.75z"
                    />
                    <path d="M77.35 320.85q-.15.05-.35.15-1.45.5-2.85.9-.35.1-2.35.35-.05 0-.1.05-.35 0-.55.05-.05-.05-.05 0-.7.1-1.35 0h-1.6q.9.15 1.8.2 1.1.15 2.2.1h.15q.1-.05.2-.05 1.95-.3 2.35-.35 1.45-.4 2.85-.9.25-.1.45-.2 5.6-2.4 8.5-7.15l-1.45.65q-2.8 4.05-7.85 6.2m8.3-25.7q.4.7.7 1.5 3.5 6.95.85 14.05-.55 1.45-1.25 2.7l1.35-.55q.45-.95.85-2 2.7-7.25-.8-14.35-.4-.85-.8-1.45-.3-.5-.55-.95-.4-.5-.75-.95-3.35-4.5-9-6.65-1.4-.25-2.7-.3h-.3q1.05.2 2.2.65 5.55 2.05 8.9 6.45.25.3.45.6.45.6.85 1.25z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy035__Symbol_124_0_Layer0_0_FILL"
                transform="translate(51.4 287)"
                id="ObjToy035__col1n"
            />
            <use
                xlinkHref="#ObjToy035__Symbol_76_0_Layer0_0_FILL"
                transform="translate(61.65 286.15)"
                id="ObjToy035__col1d"
            />
            <use
                xlinkHref="#ObjToy035__Symbol_37_0_Layer0_0_FILL"
                transform="translate(59.9 295.55)"
                id="ObjToy035__col2n"
            />
            <use
                xlinkHref="#ObjToy035__Symbol_15_0_Layer0_0_FILL"
                transform="translate(56.25 292.15)"
                id="ObjToy035__col2l"
            />
            <use xlinkHref="#ObjToy035__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjToy035
