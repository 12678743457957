import { Category, CategoryItem } from "./categories"

export interface AssetSvgProps extends React.SVGProps<SVGSVGElement> {
    className?: string
    colors?: { [key: string]: string }
    onClick?: () => void
    outerTransform?: string
    transform?: string
    disableShadows?: boolean
    startDrag?: () => void
    textContent?: string
}

export interface ItemText {
    value: string
    fontFamily?: string
    fontSize?: string
    fontStyle?: string
    fontWeight?: string
    textDecoration?: string
}

export interface Item extends CategoryItem {
    component?: any
    flipped?: boolean
    layerId?: number
    locked?: boolean
    props?: AssetSvgProps
    rotate?: number
    translate?: {
        x: number
        y: number
    }
    disableShadows?: boolean
    text?: ItemText
}

export interface AssetIndex {
    categories: Category[]
    categorySlugs: string[]
    itemSlugs: string[]
}

function copy(source: any, deep: boolean = false) {
    var o, prop, type

    if (typeof source != "object" || source === null) {
        // What do to with functions, throw an error?
        o = source
        return o
    }

    o = new source.constructor()

    for (prop in source) {
        if (source.hasOwnProperty(prop)) {
            type = typeof source[prop]

            if (deep && type === "object" && source[prop] !== null) {
                o[prop] = copy(source[prop])
            } else {
                o[prop] = source[prop]
            }
        }
    }
    return o
}

export const prepareItem = (item: Item) => {
    const module = require(`../components/assets/svg/${item.slug}`)
    item.component = module.default

    item.props = { ...item.props }

    if (module.defaultColors !== undefined) {
        item.props.colors = Object.assign(
            copy(module.defaultColors),
            item.props?.colors
        )
    }

    if (module.defaultTranslate !== undefined) {
        item.translate = Object.assign(
            copy(module.defaultTranslate),
            item.translate
        )
    }

    if (module.defaultText !== undefined) {
        item.text = Object.assign(copy(module.defaultText), item.text)
    }

    return item
}

export const prepareItems = (items: Item[]) => {
    let newItems = items.map((item: Item) => copy(item, true)) as Item[]

    newItems.forEach((item: Item, key: number) => {
        newItems[key] = prepareItem(item)
    })

    newItems = sortItems(newItems)

    return newItems
}

export const sortItems = (items: Item[]) => {
    items = items.sort((a: Item, b: Item) => {
        if (a.zIndex! > b.zIndex!) {
            return 1
        }
        if (a.zIndex! < b.zIndex!) {
            return -1
        }
        return 0
    })
    return items
}

export const addCategoryItem = (items: Item[], catItem: CategoryItem) => {
    const sameLayerItems = items.filter(
        (i: Item) => i.layerId === catItem.layerId
    )

    const preparedItem = prepareItem({
        ...catItem
    })

    // Items with these sub slugs should keep color options
    const categorySlugParts = [
        "fac_ear",
        "ski_ski",
        "bod_han",
        "fac_bro",
        "hai_",
        "bea_"
    ]
    let keepColors =
        categorySlugParts.filter((p: string) => preparedItem.slug.startsWith(p))
            .length > 0

    // Take color props from existing item on stage and put it to new item
    if (
        keepColors === true &&
        sameLayerItems.length > 0 &&
        preparedItem.props !== undefined &&
        preparedItem.props.colors !== undefined
    ) {
        const sameLayerItem = sameLayerItems[0]
        if (
            sameLayerItem.props !== undefined &&
            sameLayerItem.props.colors !== undefined
        ) {
            preparedItem.props.colors = {
                ...preparedItem.props.colors,
                ...sameLayerItem.props.colors
            }
        }
    }

    let filteredItems = items.filter(
        (i: Item) =>
            !catItem.layerId || !(!i.locked && i.layerId === catItem.layerId)
    )
    filteredItems.push(preparedItem)
    return filteredItems
}
