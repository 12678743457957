import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000"
}

function ObjToo049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.169}
                    d="M66.35 107.6q-3.95-2.15-7.9-.8-4 1.3-6.05 5.45-1.8 4.2-.35 8.5.4 1.95 1.1 3.8l51.3 152.35 19.8-6.6L72.7 117.15q-.35-1.15-.65-2.05-.15-.4-.35-.95-1.4-4.3-5.35-6.55z"
                    id="ObjToo049__Symbol_209_0_Layer0_1_MEMBER_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.251}
                    d="M65.65 110.1q-3.2-1.8-6.45-.75-3.25 1.1-4.95 4.55-1.55 3.4-.3 6.95.4 1.6.9 3.15l51.35 152.35 16.3-5.3-51.6-153.1q-.3-1-.5-1.7-.15-.4-.25-.8-1.25-3.6-4.5-5.35z"
                    id="ObjToo049__Symbol_209_0_Layer0_1_MEMBER_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M56.25 116q-1.2 2.75-.25 5.6.25 1.25.65 2.45L107.8 275.8l13.05-4.25-51.35-152.3q-.2-.75-.35-1.45-.15-.2-.3-.6-.95-2.8-3.55-4.35-2.55-1.35-5.1-.55-2.6.95-3.95 3.7z"
                    id="ObjToo049__Symbol_209_0_Layer0_1_MEMBER_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M58.35 117.6q-.8 1.9-.1 3.85.2.85.5 1.75l51.35 152.4 8.9-2.85-51.55-152.9q-.1-.5-.25-.95-.1-.15-.1-.4-.7-2-2.5-2.95-1.8-1-3.5-.35-1.8.55-2.75 2.4z"
                    id="ObjToo049__Symbol_209_0_Layer0_1_MEMBER_3_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.169}
                    d="M130.8 289.3l30.3-10.25q1.2-.3 2.1-.6.4-.2.9-.35 4.35-1.4 6.55-5.35 2.15-3.95.85-7.9-1.3-4-5.45-6-4.2-1.8-8.55-.35-1.9.4-3.8 1.05l-29.35 9.9 6.45 19.85z"
                    id="ObjToo049__Symbol_209_0_Layer0_2_MEMBER_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.251}
                    d="M130.15 287.45l30.15-10.2q1.05-.25 1.8-.5.35-.1.75-.25 3.55-1.2 5.35-4.45 1.8-3.2.75-6.45-1.1-3.2-4.55-4.95-3.4-1.5-7-.3-1.5.4-3.1.9l-29.4 9.95 5.25 16.25z"
                    id="ObjToo049__Symbol_209_0_Layer0_2_MEMBER_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M154.25 263.05l-28.85 9.75 4.25 13 29.4-9.9q.75-.2 1.4-.35.3-.1.65-.25 2.8-.95 4.3-3.55 1.4-2.55.55-5.15-.9-2.65-3.65-3.95-2.75-1.2-5.6-.3-1.2.3-2.45.7z"
                    id="ObjToo049__Symbol_209_0_Layer0_2_MEMBER_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M158.45 273.9q.55-.1.95-.25.15-.1.4-.15 2-.7 2.95-2.55.95-1.75.35-3.45-.6-1.8-2.45-2.7-1.85-.85-3.8-.1-.85.1-1.7.45l-29.05 9.8 2.9 8.85 29.45-9.9z"
                    id="ObjToo049__Symbol_209_0_Layer0_2_MEMBER_3_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.169}
                    d="M110.75 296l-6.45-19.8-29.7 9.25q-1.9.55-3.7 1.35-4.3 1.35-6.65 5.3-2.15 4.1-.85 8.1 1.2 3.95 5.25 5.85 4.15 1.9 8.45.45.55-.15 1-.25.9-.3 2-.7l30.65-9.55z"
                    id="ObjToo049__Symbol_209_0_Layer0_3_MEMBER_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.251}
                    d="M110.1 294.15l-5.25-16.25-29.65 9.25q-1.55.5-3.1 1.1-3.6 1.1-5.45 4.35-1.75 3.4-.75 6.7 1.05 3.2 4.4 4.75 3.3 1.55 7 .45.35-.15.75-.25.7-.25 1.65-.6l30.4-9.5z"
                    id="ObjToo049__Symbol_209_0_Layer0_3_MEMBER_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M109.6 292.55l-4.25-13.05-29.05 9.1q-1.25.4-2.4.9-2.85.9-4.4 3.5-1.4 2.65-.6 5.35.85 2.55 3.45 3.8 2.75 1.2 5.55.35.4-.1.7-.15.6-.25 1.35-.55l29.65-9.25z"
                    id="ObjToo049__Symbol_209_0_Layer0_3_MEMBER_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M108.95 290.55l-2.9-8.9-29.25 9.1q-.85.25-1.6.65-2.05.55-3.15 2.35-.9 1.85-.3 3.65.5 1.7 2.3 2.6 1.9.9 3.9.3.2-.15.35-.15.45-.15.95-.35l29.7-9.25z"
                    id="ObjToo049__Symbol_209_0_Layer0_3_MEMBER_3_FILL"
                />
                <path
                    fill="#FFF"
                    d="M65.15 119.25v.05q-.25-.75-1-1.1-.8-.35-1.55-.05-.8.25-1.2 1-.35.65-.15 1.45l51.85 153.95 4-1.3-51.95-154z"
                    id="ObjToo049__Layer5_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M159.95 270.1q.35-.8.05-1.6-.25-.8-1-1.1-.65-.45-1.45-.25l-30.5 10.3 1.3 3.95 30.55-10.3-.1-.05q.8-.25 1.15-.95z"
                    id="ObjToo049__Layer5_1_FILL"
                />
                <path
                    fill="#FFF"
                    d="M108.6 287.85l-1.3-3.95-30.75 9.7q-.75.25-1 1-.4.65-.15 1.45.2.9 1 1.25.65.4 1.4.15v.05l30.8-9.65z"
                    id="ObjToo049__Layer5_2_FILL"
                />
                <path
                    d="M129.05 298.7q-1.36-.56-2.5-1.7-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.597.227-1.2.4l.6 1.8-.05.05 2.2 6.85q6.95.1 11.9-3.85l-5-15.45m-22.6-15.75l1.45 4.6q.307-.185.65-.35 4.45-1.85 10.2 0 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-2.283 1.15-4.1l-.9-2.95 4.3-1.4-2.25-6.95-2.15.7-.4-.95-3.15 1.05-1.65-5.1-9.6 3.1 1.6 5.1-3.05 1 .35 1.15-2.1.7z"
                    id="ObjToo049__Layer5_3_FILL"
                />
                <path
                    fill="url(#ObjToo049__Gradient_3)"
                    d="M122.5 308.5q-.06-.392-.1-.75-.733.666-1.55 1.05l-.2.1h-.05l-.4.15q.434.796.7 1.35l2.95 5.45-1.35-7.35m2.65-6.45l-.1.55q-.549 2.072-1.4 3.5l.15.3 4.15 8.65-1.5-10.7q-.8-4.85 5.8 9.1l-2.05-11.1-1.15-3.65q-1.363-.563-2.5-1.7-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5m-14.95-21.6l.7 2.05q.25-.05.5-.05.85-.05 1.4.5.5.6.6 1.35 0 .85-.5 1.45-.35.35-.75.55 3.057-.24 6.6.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.336.531.6 1.05l.65-.2q.02-2.207 1.15-4l-1.55-4.8q-.25.15-.6.15-.75 0-1.4-.55-.45-.55-.55-1.4 0-.8.5-1.45.4-.3.85-.45l.05.05-.8-2.4-11.75 3.8z"
                    id="ObjToo049__Layer5_3_MEMBER_0_FILL"
                />
                <linearGradient
                    id="ObjToo049__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={156.375}
                    y1={114.125}
                    x2={177.625}
                    y2={106.775}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#68FE07" stopOpacity={0} />
                    <stop offset="33.333%" stopColor="#60FF00" />
                    <stop offset="42.353%" stopColor="#D8FFBF" />
                    <stop offset="54.118%" stopColor="#D3FFB9" />
                    <stop offset="63.137%" stopColor="#60FF00" />
                    <stop offset="100%" stopColor="#69FE10" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ObjToo049__Gradient_3"
                    gradientUnits="userSpaceOnUse"
                    x1={117.2}
                    y1={299.487}
                    x2={128.2}
                    y2={295.813}
                    spreadMethod="pad"
                >
                    <stop offset="1.176%" stopColor="#333" />
                    <stop offset="57.647%" stopColor="#8E8E8E" />
                    <stop offset="100%" stopColor="#333" />
                </linearGradient>
                <radialGradient
                    id="ObjToo049__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={11.763}
                    fx={0}
                    fy={0}
                    gradientTransform="rotate(179.939 55.057 80.78)"
                    spreadMethod="pad"
                >
                    <stop offset="11.373%" stopColor="#F7DADC" />
                    <stop offset="28.627%" stopColor="red" />
                    <stop
                        offset="93.333%"
                        stopColor="#FE0A0A"
                        stopOpacity={0}
                    />
                </radialGradient>
                <g
                    id="ObjToo049__Symbol_209_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M113 172.6l.05-.05H113v.05zM149.3 31.1q-.05-.1-.1-.15-.05-.05-.1-.05l.2.2z" />
                </g>
            </defs>
            <g id="ObjToo049__col1n">
                <use
                    xlinkHref="#ObjToo049__Symbol_209_0_Layer0_0_FILL"
                    transform="translate(51.4 106.3)"
                />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_1_MEMBER_0_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_1_MEMBER_1_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_1_MEMBER_2_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_1_MEMBER_3_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_2_MEMBER_0_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_2_MEMBER_1_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_2_MEMBER_2_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_2_MEMBER_3_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_3_MEMBER_0_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_3_MEMBER_1_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_3_MEMBER_2_FILL" />
                <use xlinkHref="#ObjToo049__Symbol_209_0_Layer0_3_MEMBER_3_FILL" />
            </g>
            <use xlinkHref="#ObjToo049__Layer5_0_FILL" />
            <use xlinkHref="#ObjToo049__Layer5_1_FILL" />
            <use xlinkHref="#ObjToo049__Layer5_2_FILL" />
            <use xlinkHref="#ObjToo049__Layer5_3_FILL" />
            <use xlinkHref="#ObjToo049__Layer5_3_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjToo049
