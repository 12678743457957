import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1l": "#D9D9D9",
    "col1n": "#BFBFBF",
    "col1s": "#747474",
    "col2d": "#00284D",
    "col2n": "#00498A"
}

function ObjMor039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M67.75 12.25q-.2-.2-.65-.25-.6-.1-.9-.3-.35-.25-1.45-1.4-.85-.85-1.85-1.25-1.9-.75-3.05-.55-.55-.3-1.1-.55L48.5 6.5q-.95-.15-1.9-.35l-.25-.05Q37.4 4.2 30.85.35q1.05.7 2.1 1.6.3.2.6.45-1.35-.5-2.2-.9-.9-.4-1.35-.65h-.95Q28.7.7 28.5.55q-4.2-1-12.3-.1h.2l-.9.1q-.15 0-.3.05H15v.05q-.6.1-1.2.25-7.75 2.05-11.35 11-.35.8-.6 1.6-3.55 10.35-.25 22.75 1.05 4 2.65 7.45 3.4 8.3 9.6 13.7 1.55 1.3 3.1 2.3 3.7 2.5 7.45 3.05 3 .45 6.1-.4 2.65-.7 10.2-5.45l.4-.2q.1-.1.2-.15 2.55-1.35 4-2.45l22.1-13.3q.1-.25.2-.45 5.2-4.05 5.9-6.05v-5.65q0-4.9-.05-5.4-.3-2.15-2-6.05-.5-1.05-2.05-2.8-.9-1.05-1.05-1.4-.5-.6-.6-.75m15.6 46.55l-1.3-3.5q-3.7-9.45-2.9-15.5-.05 0-.1.05-.25.15-.55.4v-.1q0-1-.8-1.25-1-.3-2.85.9-.55.35-2.45 1.3-1.7.9-2.45 1.4-2.95 2.05-3.6 2.95-.6.85-.6 2.95 0 1.15.15 1.45.15.35 1 2.15.15.4.6 2.05.5 1.1 1.4 1.35 0 .15.05.25 2.65.2 4.55 2.1 2.2 2.2 2.2 5.25 0 1.85-.75 3.35.05.05.1.15 10.8.4 8.3-7.7z"
                    id="ObjMor039__Symbol_204_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 11.55q15.7-2.25 26.3 8.2 4.2-1.4 6.55-3.25Q27.75 7.25 15.2 0q-1.95.05-3.85.55Q3.6 2.6 0 11.55z"
                    id="ObjMor039__Symbol_205_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M25.45 11.9q1.25-3.85 1.65-8Q21.65 1.75 16.8 2q-2 .1-3.9.45 9.05 6.15 3.35 14.85Q11.8 24 0 21.85q6.7 4.5 13.55 2.65 8.35-2.25 11.9-12.6m21.65-1.7q1.2-2.4 2.15-4.55 1.65-3.7 2.2-5.45.2-.65-3.25.75Q44.6 2.3 37.05 4.7q-3.8 1.45-7 5.2-.3 1.5-1.25 4.2-.3.85-.6 1.65-1.6 3.85-3.8 6.65 3.65-3.7 6.9-6.1 3.5-2.6 6.5-3.6l9.3-2.5z"
                    id="ObjMor039__Symbol_206_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M35.05 6.15Q33.6 4.9 32.15 3.9q-1-.7-2.1-1.2Q23-1.3 15.9.6 6.25 3.15 2.15 14.95-2 26.75 1.8 40.85 5.55 54.95 15 63.1q9.5 8.1 19.15 5.55 4.05-1.1 7.2-3.75 1.15-1.1 2.15-2.3 1.95-2.5 3.45-5.85.2-.4.35-.8.3-.8.6-1.65.95-2.7 1.25-4.2 2.1-10.75-.8-21.7-3.8-14.1-13.3-22.25M20.45 4.2q6.45 0 12.75 5.5 4.2 3.75 7 8.65-.65 1.25-2.1 2.35-2.35 1.85-6.55 3.25-.15.05-.25.05-4.3 1.45-8.4 2.2-.45.05-1 .15-2.8.75-4.15 3.9-1.3 3.1-.35 6.75 1 3.65 3.65 5.65 2.7 2.1 5.5 1.35 2.65-.95 5.45-1.35 1.9-.35 3.9-.45 4.85-.25 10.3 1.9-.4 4.15-1.65 8-2.4 7.05-7.05 10.35-2.2 1.55-4.85 2.25-6.85 1.85-13.55-2.65-1.55-1-3.1-2.3-8.25-7.2-11.6-19.65-3.3-12.4.25-22.75.25-.8.6-1.6 3.6-8.95 11.35-11 1.9-.5 3.85-.55z"
                    id="ObjMor039__Symbol_207_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M24.8 14.15q-.3-1-.6-1.95-1.7-4.8-5.1-7.75-2.6-2.3-5.3-2.6-.15-.05-.3-.05L0 0q10.4 5.05 11.95 18.1.5 4.8-1.45 10.8-.3 1.05-1 2.75-.5 1.15-1.2 2.7-.95 2.15-2.15 4.55l-3.2 4.7q-.55 3.65.25 6.8.45-.15.85-.3.1-.15.25-.3.8-.8 1.75-1.25 1.55-.9 3.5-.9.35 0 .65.05-1.6-4.55-2-9.35 5.15-1.4 9.9-4.85 1.4-1.05 2.2-1.6.05-.05.1-.05.85-.6 1.9-1.65 1.15-1.25 2.35-4.9 1.75-5.05.15-11.15z"
                    id="ObjMor039__Symbol_208_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M3.4 26.7L.2 31.4q-.55 3.65.25 6.8.45-.15.85-.3.1-.15.25-.3.8-.8 1.75-1.25.2-.4.35-.8-1.1-4.8-.25-8.85M22.05 1.95q-.3-1-.6-1.95 1.8 15.55-15.9 22.15Q4.6 24.3 3.4 26.7l2.05-.55q5.15-1.4 9.9-4.85 1.4-1.05 2.2-1.6.05-.05.1-.05.85-.6 1.9-1.65 1.15-1.25 2.35-4.9 1.75-5.05.15-11.15z"
                    id="ObjMor039__Symbol_209_0_Layer0_0_FILL"
                />
                <path
                    d="M119.35 277.85q-3 1-6.5 3.6 6.7 2.35 11.9-.35l3.9-5.75-9.3 2.5M88.6 254q-1.95-1.85-3.95-1.3-2 .5-2.8 3.1-.85 2.45 0 5.5.8 3.1 2.8 4.9 1.95 1.7 3.95 1.2 2-.55 2.8-3 .85-2.55.05-5.65-.85-3.05-2.85-4.75m19 2q-.15-.7-.35-1.4l-12.45 3.35.4 1.4 12.4-3.35z"
                    id="ObjMor039__Layer1_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M94.6 228.85q-1-.7-2.1-1.2-7.05-4-14.15-2.1-9.65 2.55-13.75 14.35-4.15 11.8-.35 25.9 3.75 14.1 13.2 22.25 9.5 8.1 19.15 5.55 4.05-1.1 7.2-3.75 1.15-1.1 2.15-2.3 2.2-2.8 3.8-6.65.3-.8.6-1.65.95-2.7 1.25-4.2 2.1-10.75-.8-21.7-3.8-14.1-13.3-22.25-1.45-1.25-2.9-2.25m1.05 5.8q4.2 3.75 7 8.65 2.9 5 4.5 10.95.05.15.1.35.2.7.35 1.4 1 4.55 1.1 10.2v2.4q-.05.05-.05.1l.05.3-.05.05q-.4 4.15-1.65 8-3.55 10.35-11.9 12.6-6.85 1.85-13.55-2.65-1.55-1-3.1-2.3-8.25-7.2-11.6-19.65-3.3-12.4.25-22.75.25-.8.6-1.6 3.6-8.95 11.35-11 1.9-.5 3.85-.55 6.45 0 12.75 5.5z"
                    id="ObjMor039__Layer1_0_MEMBER_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor039__Symbol_204_0_Layer0_0_FILL"
                transform="translate(63.75 228.5)"
                id="ObjMor039__col1n"
            />
            <use
                xlinkHref="#ObjMor039__Symbol_205_0_Layer0_0_FILL"
                transform="translate(67.7 229.15)"
                id="ObjMor039__col1l"
            />
            <use
                xlinkHref="#ObjMor039__Symbol_206_0_Layer0_0_FILL"
                transform="translate(81.55 265.15)"
                id="ObjMor039__col1d"
            />
            <use
                xlinkHref="#ObjMor039__Symbol_207_0_Layer0_0_FILL"
                transform="translate(62.45 224.95)"
                id="ObjMor039__col1s"
            />
            <use
                xlinkHref="#ObjMor039__Symbol_208_0_Layer0_0_FILL"
                transform="translate(122.5 236.45)"
                id="ObjMor039__col2n"
            />
            <use
                xlinkHref="#ObjMor039__Symbol_209_0_Layer0_0_FILL"
                transform="translate(125.25 248.65)"
                id="ObjMor039__col2d"
            />
            <use xlinkHref="#ObjMor039__Layer1_0_FILL" />
            <use xlinkHref="#ObjMor039__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMor039
