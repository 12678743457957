import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#0087FF"
}

function ObjToo068({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M88.3 41.95q-.05-.9-.1-1.85-.5-5.6-2.2-10.7-.75-2.15-1.7-4.15-2.75-5.65-7.2-10.55-.85-.95-1.65-1.75-.8-.85-1.6-1.55-5-4.5-10.7-7.25-2-.95-4-1.7-4.2-1.45-8.7-2Q48.5.2 46.5.05 45.35 0 44.2 0t-2.25.05Q37.25.3 32.9 1.4q-1.85.45-3.55 1.05-2.1.75-4.15 1.7-5.65 2.75-10.6 7.25-.9.7-1.65 1.55l-.1.1q-.8.75-1.55 1.65-4.5 4.9-7.15 10.55-1 2-1.7 4.15Q.3 35.3.05 41.95 0 43.05 0 44.2v.2q0 1.1.05 2.25Q.1 48.2.3 49.7q.6 4.95 2.15 9.45.75 2.1 1.75 4.1.7 1.6 1.65 3.2 2.25 3.8 5.5 7.4.7.75 1.55 1.55l.1.15q.75.8 1.65 1.55 4.95 4.45 10.55 7.2 2.1.95 4.25 1.65.25.1.55.2 5.65 1.9 11.9 2.15 1.1.05 2.25.05h.05q1.15 0 2.35-.05Q53.1 88 59 86q2.1-.75 4.15-1.7 5.65-2.75 10.6-7.2.75-.7 1.6-1.5.05-.05.1-.15.8-.8 1.65-1.6 4.4-4.9 7.15-10.6.95-2 1.7-4.1 2.05-5.9 2.35-12.5.05-1.25.05-2.45 0-1.15-.05-2.25z"
                    id="ObjToo068__Symbol_226_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 22.35q0 9.3 6.5 15.8 6.6 6.55 15.85 6.55 9.2 0 15.75-6.55 6.55-6.5 6.55-15.8 0-9.25-6.55-15.8Q31.55 0 22.35 0 13.1 0 6.5 6.55 0 13.1 0 22.35z"
                    id="ObjToo068__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M279.7 260.2q-15 0-25.65 10.6-10.6 10.7-10.6 25.65 0 15.05 10.6 25.6 10.65 10.7 25.65 10.7 15 0 25.6-10.7 10.7-10.55 10.7-25.6 0-14.95-10.7-25.65-10.6-10.6-25.6-10.6m-20.45 15.45q8.65-8.5 20.8-8.5 12.1 0 20.7 8.5 8.6 8.65 8.6 20.75 0 12.15-8.6 20.75t-20.7 8.6q-12.15 0-20.8-8.6-8.5-8.6-8.5-20.75 0-12.1 8.5-20.75m15.55 12.95l-15.6.25 12.35 9.6-4.6 14.75 12.9-8.6 12.9 8.9-4.35-14.9 12.5-9.35-15.65-.6-5.1-14.65-5.35 14.6z"
                    id="ObjToo068__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo068__Symbol_226_0_Layer0_0_FILL"
                transform="translate(235.7 252.45)"
                id="ObjToo068__col1n"
            />
            <use
                xlinkHref="#ObjToo068__Symbol_98_0_Layer0_0_FILL"
                transform="translate(257.6 273.95)"
                id="ObjToo068__col2n"
            />
            <use xlinkHref="#ObjToo068__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo068
