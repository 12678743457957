import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#91F000",
    "col1l": "#E9FFC9",
    "col1n": "#C2FF66"
}

function LegSki008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M145.15 4.2q-1.75-.9-2.65-1.5-.9-.6-1.8-2.6-1.2.25-2.85.65-.45.05-.85.15-.35.1-.75.2-14.1 3.15-28.25 4.85-5.65.7-11.3 1.15-2.6.15-5.15.35-4.3.25-8.6.35-3.6.1-7.25.1h-1.3q-4.45 0-8.8-.2-3.05-.1-6.1-.3-2-.1-4-.25-1.9-.15-3.75-.3-3.85-.35-7.7-.8-3.8-.45-7.6-1-3.75-.55-7.5-1.2-1.75-.3-3.5-.65-4.1-.75-8.2-1.65-1.5-.3-2.95-.65-1.45-.3-2.9-.65Q10.85.1 10.35 0 9.9 1.65 9 2.95q-.45.6-1.1 1.3-.15.1-.25.25-.8.7-1.5 1.15-.75.4-2.7 1.45Q1.5 8.15 0 10.95l.4.5q.9.85 1.95 1.35.05.05.1.05 1.4.65 3.1.65.8 0 1.75-.15h.1q.15-.05.35-.1.45-.1.95-.2 1.75-.5 3.75-.5 1.75 0 3.35.5.1 0 .2.05 1 .3 1.95.8.25.15.5.35.35.2.7.45.45.3.85.6 1.55.95 2.85 1.3.15.05.35.1.15.05.35.05.3.05.65.05h.25q.45 0 1.1-.05.1-.05.25-.05 1.15-.1 2.8-.4.8-.15 1.6-.25.65-.1 1.25-.15 1.25-.15 2.25-.15.25 0 .6.05.75.05 1.7.25h.15q.1 0 .2.05.5.1 1.1.25 1.8.45 4.15 1.2.4.15.85.3l.05.05q.05 0 .1.05 1.25.6 2.5 1.05.95.35 1.95.55 1.05.25 2.15.35.45.05.95.05H50.6q1.1.05 2.25-.1 1-.15 2.05-.4l3.2-.55q1.05-.1 2.2-.1.55-.05 1.15-.05 1.8 0 4.3.65.15 0 .3.05.75.25 1.5.45 4 1.1 7.75.8.05-.05.15-.05.9-.1 1.8-.25.3-.05.65-.1.5-.25 1.05-.4 3.2-1.15 6.1-1.6 1.15-.15 2.3-.2.85-.05 1.65-.05h.3q2.2.05 4.2.6.15.05.35.05.25.05.55.1 2.4.4 4.05.6.15 0 .3.05.4.05.7.05.6.05 1.05.05 2.55 0 3.35-.25 0-.05.05-.05.5-.25 1.7-1l.5-.3q.25-.15.55-.3.6-.3 1.45-.55.7-.2 1.6-.35.75-.15 1.7-.25h.25q.05-.05.1 0 .35-.05.65-.1h.25l3.95-.25q.15-.05.25 0 .9.05 2.25.2.5.05 1.1.15.75.1 1.4.2.4.1.85.2.2 0 .4.05.25.05.55.1.25 0 .45.05h1.5q1.2-.05 2.4-.4h.05q.2-.05.45-.15 1.15-.4 2.3-1.05.05-.05.15-.1.65-.4 1.35-.9 0-.05.05-.05l.8-.7h.05q1.5-.95 2.85-1.5.25-.15.5-.25.2-.1.45-.15.1-.05.25-.1 2-.7 4.35-.7.15 0 .45.05.55 0 1.65.2 1.4.2 1.9.2h.05q.2 0 .5-.05.45-.1 1-.3.4-.15.8-.35h.05q.95-.5 2.15-1.3l-1.35-2.05q-1.5-2.3-2.6-2.85-.45-.2-.8-.4z"
                    id="LegSki008__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M12.95 0q-.65.95-1.4 1.6-1.15.95-2.7 1.35-1.6.3-2.3.45-2.7.7-4.2 1.75Q.25 6.7 0 9.25q1 .3 2 .8.25.15.5.35.35.2.7.45.45.3.85.6 1.55.95 2.85 1.3V10.7q1.8-5.85 12.4-9.15.6-.15 1.15-.35Q16.7.65 12.95 0m59.5 8.5q1.7-1.9 2.75-4.15.15-.35.35-.75-4.3.25-8.6.35-3.6.1-7.25.1h-.45q-1.35 7.45-6.6 10.35-1.25.5-2.65 1.05-.05 0-.1.05-.1 0-.15.05.15 0 .3.05.75.25 1.5.45 4 1.1 7.75.8.05-.05.15-.05 3.05-1.5 5.05-2.5 2.2-1.15 3.15-1.75 2.8-1.8 4.8-4.05m42.25 3.05q.65-.4 1.35-.9 0-.05.05-.05l.8-.7h.05q-1.05-1.3-2.5-2.45-3.85-3.1-8.05-3.1-3.55 0-6.15 2.95-2.1 2.35-2.65 5.2l3-.2q.15-.05.25 0 .9.05 2.25.2.5.05 1.1.15.75.1 1.4.2.4.1.85.2.2 0 .4.05.25.05.55.1.25 0 .45.05h1.5q1.2-.05 2.4-.4h.05q.2-.05.45-.15 1.15-.4 2.3-1.05.05-.05.15-.1z"
                    id="LegSki008__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.85 7.4q3.15-2.35 4.4-5.85-1.5-.3-2.95-.65-1.45-.3-2.9-.65Q10.85.1 10.35 0 9.9 1.65 9 2.95q-.45.6-1.1 1.3-.15.1-.25.25-.8.7-1.5 1.15-.75.4-2.7 1.45Q1.5 8.15 0 10.95l.4.5q.9.85 1.95 1.35.05.05.1.05 1.4.65 3.1.65.8 0 1.75-.15h.1q.15-.05.35-.1 0-.1.05-.2.3-1.7 1.5-2.95.4-.4 3.55-2.7m48.25 4q0-1.5-.35-2.2-.25-.65-1.25-1.8-2-.1-4-.25-1.9-.15-3.75-.3-13.25.7-15.6 9.2.1 0 .2.05.5.1 1.1.25 1.8.45 4.15 1.2.4.15.85.3l.05.05q.05 0 .1.05 1.25.6 2.5 1.05.95.35 1.95.55 1.05.25 2.15.35.45.05.95.05 2.6-.85 4.85-2 2.35-1.2 4.3-2.7.45-.5.8-.95 1-1.35 1-2.9m84.85-6.8q-.45-.2-.8-.4-1.75-.9-2.65-1.5-.9-.6-1.8-2.6-1.2.25-2.85.65-.45.05-.85.15-.35.1-.75.2-14.1 3.15-28.25 4.85-4.35 4.5-4.35 10.65 0 .6.05 1.15 0 .95.15 1.55 0-.05.05-.05.5-.25 1.7-1l.5-.3q.25-.15.55-.3.6-.3 1.45-.55.7-.2 1.6-.35.75-.15 1.7-.25h.25q.05-.05.1 0v-.45q0-4.85 5.05-8.25 5.1-3.35 12.35-3.35 7.3 0 12.4 3.35 2.5 1.7 3.8 3.7h.05q.2 0 .5-.05.45-.1 1-.3.4-.15.8-.35h.05q.95-.5 2.15-1.3l-1.35-2.05q-1.5-2.3-2.6-2.85z"
                    id="LegSki008__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki008__Symbol_74_0_Layer0_0_FILL"
                transform="translate(116.8 307.15)"
                id="LegSki008__col1n"
            />
            <use
                xlinkHref="#LegSki008__Symbol_61_0_Layer0_0_FILL"
                transform="translate(132.8 311)"
                id="LegSki008__col1l"
            />
            <use
                xlinkHref="#LegSki008__Symbol_39_0_Layer0_0_FILL"
                transform="translate(116.8 307.15)"
                id="LegSki008__col1d"
            />
        </g></g>
    )
}

export default LegSki008
