import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FEFDAB",
    "col2n": "#000000"
}

function FacEye028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fillOpacity={0.251}
                    d="M142.75 189.65q10.3 14.6 31.2 6.25-18.95 4.05-31.2-6.25m100 0q-12.25 10.3-31.2 6.25 20.9 8.35 31.2-6.25m-58.6-3.4l.15-.15q-29.9 9.8-44.45-10.05.052 0 3.85-6.4 3.793-6.398 5-23.75-2.4-.75-4.85-1.45-2.3-.4-7-1.7-4.75-1.35-12.8-3.05 11.35 6.45 13.7 16.85.05.5.1.95.8 8.9-4.1 12.85 20.95 32.2 50.4 15.9m11.3-16.9q-.944-1.82-2.95-3.35l.1-.4q-3.127 3.23-4 4.1-.824.866-3 4.6-2.126 3.788-2.6 10.6 2.9-.55 3.45-.7.55-.2.9-1.05.35-.9 5.2-14.05l4.65 14.4.3.25q1.7.9 4.55 1.15-1.75-6.298-3.8-10.4-1.251-2.541-1.55-3.25-.256-.084-1.25-1.9m-5.25-27.85q.15.15.35.3 2.05 2.35 2 19.8v.05q0 .05.05.1.6-15.7 2.55-20.15 15.55-16.5 42.9-24.4-22.9 3.7-38.85 17.4-1.5 1.15-2.5 1.5-2.45.9-1.45-3.05l.05-.25q1.55-5.8 3.75-12.2-3.95 4.15-6.5 14.55-2.55-10.35-6.5-14.55 2.2 6.4 3.75 12.2l.05.25q1 3.95-1.45 3.05-1-.35-2.5-1.5-15.95-13.7-38.85-17.4 27.75 8.05 43.15 24.3m51 2.95q-2.45.7-4.85 1.45-1.018 15.855 3.9 23 4.906 7.148 4.95 7.15-14.55 19.85-44.45 10.05l.15.15q29.45 16.3 51-14.9-5.5-4.95-4.7-13.85.05-.45.1-.95 2.35-10.4 13.7-16.85-8.05 1.7-12.8 3.05-4.7 1.3-7 1.7z"
                    id="FacEye028__Layer11_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M105.7 30.2q2.85-17.95-8.75-30.2-1.15.4-2.35.8-17.95 6.25-33.65 16.4-4.15 2.25-6.3.7-.95-.65-1.45-2.05-.05-.05-.05-.1v-.05q-.6 1.5-1.55 2.2-2.15 1.55-6.3-.7Q29.6 7.05 11.65.8 10.45.4 9.3 0-2.3 12.25.55 30.2q21.6 13.45 42.95 8.85l.1-.05q4.95-5.6 7.5-12.35.6-1.6 1.1-3.25.05-.25.15-.5v-.2l.8-3.2.8 3.3-.05.1.1.2q0 .15.05.3.5 1.65 1.1 3.25Q57.7 33.4 62.65 39h.05l.05.05.9.2q20.9 4.1 42.05-9.05z"
                    id="FacEye028__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 3.25q0 1.4 1 2.35.95 1 2.4 1.05Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35m32 0q0 1.4 1 2.35 1 1 2.45 1.05 1.4-.05 2.35-1.05 1-.95 1-2.35 0-1.35-1-2.35-.95-.95-2.35-.9Q34-.05 33 .9q-1 1-1 2.35z"
                    id="FacEye028__Symbol_43_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacEye028__Layer11_0_FILL" />
            <use
                xlinkHref="#FacEye028__Symbol_71_0_Layer0_0_FILL"
                transform="translate(139.4 145.9)"
                id="FacEye028__col1n"
            />
            <use
                xlinkHref="#FacEye028__Symbol_43_0_Layer0_0_FILL"
                transform="translate(173 163.45)"
                id="FacEye028__col2n"
            />
        </g></g>
    )
}

export default FacEye028
