import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#621F00",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.25 1.4q.2.3.6.3.35 0 .55-.3.3-.2.3-.55Q1.7.5 1.4.2 1.2 0 .85 0q-.4 0-.6.2Q0 .5 0 .85q0 .35.25.55z"
                    id="AccGla012__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M52.8 0H0v52.4h52.8V0m58.45 52.4V0h-52.8v52.4h52.8z"
                    id="AccGla012__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M191.8 11q-.1-.45-.45-.65l-.2-.1q-.3-.1-.6 0l-37.8 13.8-.05-.1V.6q-.05-.2-.2-.3-.3-.3-.7-.3h-53q-.4 0-.7.3-.3.3-.3.7v24.1h-3.75V1q0-.4-.3-.7-.3-.3-.7-.3h-53q-.4 0-.7.3-.15.1-.2.3v23.35l-.05.1-37.8-13.8q-.3-.1-.6 0l-.2.1q-.35.2-.45.65-.1.4.15.75.2.35.6.45l38.35 14.25V53.8q.05.15.2.25.3.3.7.3h53q.4 0 .7-.3.3-.3.3-.7V27.1h3.75v26.25q0 .4.3.7.3.3.7.3h53q.4 0 .7-.3.15-.1.2-.25V26.45l38.35-14.25q.4-.1.6-.45.25-.35.15-.75M92.05 2v50.35h-51V2h51m7.75 0h51v50.35h-51V2z"
                    id="AccGla012__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla012__Symbol_102_0_Layer0_0_FILL"
                transform="translate(188.9 162.85)"
                id="AccGla012__col2n"
            />
            <use
                xlinkHref="#AccGla012__Symbol_82_0_Layer0_0_FILL"
                transform="translate(136.9 138.7)"
                id="AccGla012__col2l"
            />
            <use
                xlinkHref="#AccGla012__Symbol_35_0_Layer0_0_FILL"
                transform="translate(96.6 137.65)"
                id="AccGla012__col1n"
            />
        </g></g>
    )
}

export default AccGla012
