import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M99.9 39.95q7.2-7.75 6.45-19.6Q105.55 8.8 97.5 0L53.1 11.8 8.7.3Q.8 9 .1 20.4q-.85 11.8 6.45 19.55 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9z"
                    id="FacEye012__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.4 6.65Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35 0 1.4 1 2.35.95 1 2.4 1.05m32.05 0q1.4-.05 2.35-1.05 1-.95 1-2.35 0-1.35-1-2.35-.95-.95-2.35-.9Q34-.05 33 .9q-1 1-1 2.35 0 1.4 1 2.35 1 1 2.45 1.05z"
                    id="FacEye012__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacEye012__col1n" transform="translate(139.3 146.45)">
                <use
                    xlinkHref="#FacEye012__Symbol_15_0_Layer0_0_FILL"
                    id="FacEye012__col1n_FL"
                />
            </g>
            <g id="FacEye012__col2n" transform="translate(173 163.45)">
                <use
                    xlinkHref="#FacEye012__Symbol_4_0_Layer0_0_FILL"
                    id="FacEye012__col2n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacEye012
