import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiLon031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M204.05 96.15q0-7.15-.9-13.95Q199 51.25 175.9 28.15q-12.2-12.2-26.6-19.1Q130.45 0 107.85 0 70.7 0 43.7 24.5q-1.95 1.75-3.85 3.65-4.4 4.4-8.05 9.05-.5.55-.9 1.15-1.05 1.3-1.95 2.65-.4.5-.75 1.05-.15.15-.25.35l-.5.7q-.35.5-.7 1.05Q15.8 60.3 12.8 81.1l-.2 1.1v.2l-.1.6-.7 9.35q-.05.2-.05.45-.05 1.25-.05 2.55v.8q-.75 20.55-.45 24.75l.6 10.75q.05 13.4-.35 25.2-1.05 29.3-4.8 48.6-2.65 13.6-6.7 22.25.25.25.55.45 7.15 6.05 16 8.6l2.3.6q.1 0 .2.05.2.05.45.1l.5.1h.15q.05.05.15.05.1 0 .15.05h.05l4.15.85q.75-1.95 1.45-3.9 1.1-3.25 2-6.5l.4-1.5q-.05-.15.4-2.2.75-3.6 1.25-4.65.1-1.4 1.4-7.2 1.5-6.95 3.2-17.65.05-.3.1-.65 0-.05.05-.1.05-.2.1-.3.2-.55.45-1.1.5-1.15 1.05-2.25.05-.15.1-.25.05-.15.1-.25l.3-.7.65-1.25q.75-1.55 1.5-3l1.1-2q1.3-2.3 2.7-4.5.15-.25.3-.45 1.4-2.2 4.7-7.1-2.35-1.9-4.6-4-1.65-1.5-3.25-3.1-2.7-2.7-5.1-5.45-.05-.1-.1-.15 0-.2.1-.35V78.95h145.05v80.3q-.65.7-1.3 1.45-1.55 1.65-3.15 3.25-1.6 1.6-3.2 3.1-2.25 2.05-4.5 3.95 3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5l1.1 2q.75 1.45 1.5 3l.65 1.25q.1.2.15.35.05.15.15.35.05.1.1.25.05.1.15.25.4.85.8 1.75 1.8 18.05 5.6 29.75.6 1.85.95 3.2 1.05 4.8 3.05 9.35.8 1.9 1.75 3.75l4.6-.95q.05-.05.1-.05l1.75-.45q9.55-2.6 17.1-9.2-4.05-8.65-6.7-22.25-3.75-19.3-4.75-48.6-.2-5.75-.25-11.6l.8-44.1q-.1.6-.1-5m-4.25-18.9l-.1.05v-.05h.1m-184.55 0h.05v.05l-.05-.05z"
                    id="HaiLon031__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M8.05 20.1q.15-.25.3-.45 1.4-2.2 4.7-7.1-2.35-1.9-4.6-4-1.65-1.5-3.25-3.1Q2.5 2.75.1 0v34.35q.038-.15.1-.3-.062.16-.1.35-.05.05-.05.15v.8Q0 35.5 0 35.7q.05-.2.1-.3.2-.55.45-1.1.5-1.15 1.05-2.25.05-.15.1-.25.05-.15.1-.25l.3-.7.65-1.25q.75-1.55 1.5-3l1.1-2q1.3-2.3 2.7-4.5m-5.1 8.5q-.3.5-.7 1.15.3-.65.65-1.3l.05.15M145.15.75q-.65.7-1.3 1.45-1.55 1.65-3.15 3.25-1.6 1.6-3.2 3.1-2.25 2.05-4.5 3.95 3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5l1.1 2q.75 1.45 1.5 3l.65 1.25q.1.2.15.35.05.15.15.35.05.1.1.25.05.1.15.25.4.85.8 1.75-.05-.35-.1-.65V.75z"
                    id="HaiLon031__Symbol_57_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M23.4 71.2q-.75 7.75-2.2 25.75-.05 8.7-.1 16.75-.15 10.65-.3 20.3-.05.3-.05.6v.05q-1.25 57-5.3 75.55-1.05 5-2.4 7.2-.25.45-.55.8l-2.75 3.5q2.75-1.35 4.95-4.3 2.1-2.85 3.7-7.2 3.65-10.05 4.5-28.15-.05-12.15.5-22.95.3-5.95.35-19.85v-.05-.6q0-10.35-.15-24.9v-2.35q-.1-6.85.5-40.15 0-2.15.05-4.45.05-4.4-.75 4.45m-9.85-5.9q-1 6.75-.95 20.55 0 18.15-.1 30v1.1q-.1 8.6-.2 13.7 1.25 26.95-1.5 47.8-3 22.8-10.8 38.25 6.8-4.5 10.8-18.45 5.7-19.85 5.8-58.8L13.55 65.3m153.3-24.95q5.65 9.8 7.4 18.3 3.2-23.4-25.9-48 37.75 22 42.05 47.1Q185.1 22 136.5 1.5q-1.05-.75-2.15-1.5L141 9.2q6.55 8.55 10.55 21.25-13.65-14-19.75-20.15L124 3.2l11.25 30.5Q118.6 12 116.5 5.7v30.5Q107 9.95 107.85 6.35 102.2 16.9 96.5 35.2v-27q-3.2 3.9-5.25 6.1-.5.55-1 1.15-.25.3-.5.65L77.5 33.45 86 2.95l-19.45 16.2h.05q-2.35 2.6-8.35 9.6-2.05 2.35-3 3.6-.25.15-.55.35 5.95-14.95 12.5-23.5L73.85 0q-1.1.75-2.15 1.5Q23.15 22.9 16.3 58q5.8-25.35 43.6-47.35-29.15 24.6-25.95 48 1.75-8.5 7.4-18.3Q47 30.5 52.9 23.7q-5 13.9-4.25 30.25 5.15-17 27.15-34.65L69 54.45l22.5-31q.35 12.8 3 30.25l12.15-32.05q4.55 14.1 7 19.15 2.25 4.65 6 12.45 1.05-2.3 1.2-3.9.7-6.95.8-26.6Q131.5 39.6 142.5 52.7l-7-32.75q14.2 15.55 24.05 34-1.35-16-1.75-19.75 0-3-.25-4v-2.75q3.65 3.05 9.3 12.9m18 56.6q-1.45-18-2.15-25.75-.85-8.85-.8-4.45.05 2.3.1 4.45.55 33.3.5 40.15-.05 1.2-.05 2.35-.15 15.85-.1 26.7-.05.3 0 .65 0 12.5.3 18.05.55 10.8.5 22.95 1.6 34 13.15 39.65l-2.75-3.5q-6.55-7.75-8.15-81.6v-.05q-.05-.35-.05-.65-.2-10.45-.35-22.2-.1-8.05-.15-16.75m8.65-11.1q0-13.8-1-20.55l-3.05 74.15q.15 66.4 16.6 77.25-14.9-29.6-12.3-86.05-.1-4.05-.15-10.2v-.05-1.1q-.15-12.55-.1-33.45z"
                    id="HaiLon031__Symbol_29_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiLon031__Symbol_90_0_Layer0_0_FILL"
                transform="translate(84.6 57.6)"
                id="HaiLon031__col1n"
            />
            <use
                xlinkHref="#HaiLon031__Symbol_57_0_Layer0_0_FILL"
                transform="translate(119.5 216.1)"
                id="HaiLon031__col1d"
            />
            <use
                xlinkHref="#HaiLon031__Symbol_29_0_Layer0_0_FILL"
                transform="translate(88.8 68.35)"
                id="HaiLon031__col1l"
            />
        </g></g>
    )
}

export default HaiLon031
