import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M54.4 14.6q-1.85-3.2-4.65-6.05Q41.15 0 29.15 0 17.1 0 8.6 8.55 0 17.1 0 29.15q0 12 8.6 20.55 4 4 8.8 6.15 1.6-3.35 3.45-6.6l1.1-1.9Q28 37.5 36.7 28.8q3.55-3.55 7.3-6.65l1.3-1.1q4.45-3.55 9.1-6.45m117.55 14.2q8.7 8.7 14.75 18.55l1.1 1.9q1.85 3.25 3.45 6.6 4.8-2.15 8.8-6.15 8.6-8.55 8.6-20.55 0-12.05-8.6-20.6Q191.55 0 179.5 0q-12 0-20.6 8.55-2.8 2.85-4.65 6.05 4.65 2.9 9.1 6.45l1.3 1.1q3.75 3.1 7.3 6.65z"
                    id="FacEar017__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M27.1 18.05q3.55-3.55 7.3-6.65l1.3-1.1q-1.3-2.55-3.5-4.75Q26.65 0 18.9 0 11.1 0 5.6 5.55q-3.35 3.3-4.7 7.4-.9 2.8-.9 5.95 0 7.8 5.6 13.3 3.05 3.05 6.75 4.4 6.05-9.85 14.75-18.55M183.85 32.2q5.6-5.5 5.6-13.3 0-3.15-.9-5.95-1.35-4.1-4.7-7.4Q178.35 0 170.55 0q-7.75 0-13.3 5.55-2.2 2.2-3.5 4.75l1.3 1.1q3.75 3.1 7.3 6.65 8.7 8.7 14.75 18.55 3.7-1.35 6.75-4.4z"
                    id="FacEar017__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M33.5 11.4l1.3-1.1q-1.3-2.55-3.5-4.75Q25.75 0 18 0 10.2 0 4.7 5.55q-3.35 3.3-4.7 7.4Q1.4 10 3.9 7.55 9.4 2 17.2 2q7.75 0 13.3 5.55 1.8 1.8 3 3.85m150.25-3.85q2.5 2.45 3.9 5.4-1.35-4.1-4.7-7.4Q177.45 0 169.65 0q-7.75 0-13.3 5.55-2.2 2.2-3.5 4.75l1.3 1.1q1.2-2.05 3-3.85Q162.7 2 170.45 2q7.8 0 13.3 5.55z"
                    id="FacEar017__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar017__Symbol_46_0_Layer0_0_FILL"
                transform="translate(88.2 57.3)"
                id="FacEar017__col1n"
            />
            <use
                xlinkHref="#FacEar017__Symbol_45_0_Layer0_0_FILL"
                transform="translate(97.8 68.05)"
                id="FacEar017__col2n"
            />
            <use
                xlinkHref="#FacEar017__Symbol_44_0_Layer0_0_FILL"
                transform="translate(98.7 68.05)"
                id="FacEar017__col2d"
            />
        </g></g>
    )
}
export default FacEar017
