import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#666666",
    "col1n": "#999999"
}
function SkiMor038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M96.3 37.5q-1-1.05-2.15-1.95 6.1-7.85 5.65-16.65.15-3.7-1-8.15-.4-1.45-.9-3-1-2.85-3.2-4.55l-1.55-1.05Q82-2.15 68.4 2.15q-4.45 1.65-6.45 3.1-3.55 2.45-8.65 3-1.5.15-3.15.15h-.4q-1.65 0-3.15-.15-1.25-.15-2.45-.4l-3.5-1.1-1.7-.85q-.55-.35-1-.65-.35-.25-.75-.5l-4.9-2.3-.8-.3q-13.6-4.3-24.75 0-3.5 1.8-4.8 5.6-.5 1.55-.9 3-1.15 4.45-1 8.15-.45 8.8 5.7 16.65-1.15.9-2.2 1.95Q.15 41.8.9 51.8q.7 8.9 7.4 13.2.75.45 1.55.9l1.65.8q.05 0 .1.05l8.5 2.85q1.65.45 3.45.8 3.25.7 7 1.3 6.2 1.05 11.35.8 1.1-.05 2.15-.1.75-.1 1.5-.15.25-.1.5-.1 4.8-.45 9.4 0l.85.15q1.3.1 2.6.15 6.35.25 13-1.15 2.4-.45 4.6-.95 6.95-1.35 11.8-3.6 1.8-.85 3.3-1.75 6.65-4.3 7.35-13.2.75-10-2.65-14.3z"
                    id="SkiMor038__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.7 59.1q6.8 2.4 11.55 3.1 4.7.65 11.55 1.35 6.8.7 11-1.15-3.95 1.45-19.6-.75-16.85-2.45-20.8-8.6-4.15-5.8-3.7-16.4-1.5 17.7 8.55 21.85l1.45.6M.2 9.4Q0 11.55 0 13.5q-.1 14.1 8.75 17.75l1.45.6Q17 34.25 26 34.6q2.5.15 4.9.15 6.85 0 13.05-1.6-11.15 1.15-21.55.2-14.55-1.4-18.5-7.55Q.6 21.2.2 13.5q-.1-1.95 0-4.1m46.15 39.95q.4-8.05 1.3-15.5l-1.5-.8-1.5.8q.9 7.45 1.3 15.5.1 1.6.15 9.25.1-5.2.1-5.65.1-1.85.15-3.6M61.75 3.5q3.3-1.95 4.65-3.5-2.85 2.1-8.35 4.05-2.45.85-5.1 1.3-3.15.55-6.6.6h-.4q-3.4-.05-6.55-.6-2.65-.45-5.1-1.3Q28.8 2.1 25.95 0q1.35 1.55 4.65 3.5 1.85 1.1 3.75 1.7 1.85.55 3.55 1.05 1.75.45 4.45.65 2.7.15 2.8 4.35l1.05 18.8.95-18.9q0-4 2.85-4.25 2.75-.25 3.5-.35.75-.2 1.75-.4t2.6-.8q2.05-.75 3.9-1.85m19.9 55.6l1.45-.6q10.05-4.15 8.55-21.85.45 10.6-3.7 16.4-3.95 6.15-20.8 8.6-15.65 2.2-19.65.75 4.25 1.85 11.05 1.15 6.85-.7 11.55-1.35 4.75-.7 11.55-3.1m10.5-45.6q-.4 7.7-3.7 12.3-3.95 6.15-18.5 7.55-10.4.95-21.55-.2 6.2 1.6 13.05 1.6 2.4 0 4.9-.15 9-.35 15.8-2.75l1.45-.6q8.85-3.65 8.75-17.75 0-1.95-.2-4.1.1 2.15 0 4.1z"
                    id="SkiMor038__Symbol_49_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor038__Symbol_48_0_Layer0_0_FILL"
                transform="translate(142.6 248)"
                id="SkiMor038__col1n"
            />
            <use
                xlinkHref="#SkiMor038__Symbol_49_0_Layer0_0_FILL"
                transform="translate(146.8 253.45)"
                id="SkiMor038__col1d"
            />
        </g></g>
    )
}
export default SkiMor038
