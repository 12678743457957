import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M63.7 16.45q4.6 0 4.6-3.9 0-4.85-7.35-5.3-.3-.5-.5-1-.85-2.2-3.25-1.7-.35.05-.65.1h-.35q-1.5-.1-2.6-1.3-1.25-1.4-2.1-1.65-.95-.3-1.55-.45Q48.8.9 47.9 1.2q-.95.3-1.65.75-.25.2-.4.4h-.05q-.3-.75-.9-1.25-3.15-2.15-6.85 0-.3.2-.5.4-.2.15-1.2.8l-.05.05h-.6q-.6-.3-1.2-.8-.45-.4-.85-.65-2.4-1.35-5.35 0-.3.2-.65.45-1.1.85-1.45 1.35-.5.15-1.15.15-2.75-.75-2.85-.75-2 0-3.15 1-1.2 1-2.35 1-1.8-.5-2.45-.5-2.15 0-4.4 2.15l-.3.3q-.8-.6-2.1-.6-2.95 0-3.1 2.8-.1 0-.2.05h-.6Q.25 8.9 0 12.15v.1q0 1.6.4 2.3.7 1.3 3 1.9h1.1q.8-.05 1.65-.25.25.25.7.5.2.15.35.3.85.2 1.9.2 2.7 0 3.9-1.8v.05q2.65 3.55 7.1 2.75 1.1-.2 2-.55.85-.25 2.1-1.5.7-.65 1.15-1.2 1.4 3.25 5 3.25 2.7 0 4.35-3.6.1-.3.25-.55 0 .05.05.15 2 3.25 4.85 3.25 2.75 0 4.65-2.85 1.7 2.85 5.25 2.85 1.8 0 3.3-1.55 1.5 1.8 4.95 1.8 2.4 0 3.4-1.5.9.25 2.3.25z"
                    id="BeaMus014__Symbol_31_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus014__Symbol_31_0_Layer0_0_FILL"
                transform="translate(158.35 195.35)"
                id="BeaMus014__col1n"
            />
        </g></g>
    )
}

export default BeaMus014
