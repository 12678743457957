import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D2E1B",
    "col1n": "#794D32",
    "col1s": "#2A180D",
    "col2d": "#6D6D3B",
    "col2n": "#9C986A"
}

function AccHat026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g
                    id="AccHat026__Symbol_120_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M39 52.6q.2 0 .35-.1.15-.15.15-.35 0-.25-.1-.4-.15-.15-.35-.15-19.85-.75-37.9 7.2-.45.15-.85.35-.2.1-.25.3-.1.15 0 .35.1.2.3.3.15.05.35-.05.25-.1.45-.2 18.05-8 37.85-7.25M64.6 1V.3q0-.05-.05-.05-.2-.15-.4-.1-13.4 2.5-19.05 12.7-.15.2-.05.4.05.15.2.3.2.1.4.05.15-.1.3-.25 5.5-9.8 18.4-12.2.15-.05.25-.15m50.6 12.35q.1.2.3.25.15.05.35-.05.2-.15.25-.3.05-.2-.05-.4-7-11.65-26.2-12.85-.25 0-.35.15-.2.1-.2.35 0 .2.15.35.1.15.35.15 18.55 1.2 25.4 12.35m9.85 35.75q-.2 0-.3.2-.15.15-.15.35v.05q0 .15.2.3.15.1.35.1 19.6-1.5 35.3 4.95.2.1.4 0 .2-.05.25-.25.1-.2 0-.4-.05-.2-.25-.25-15.9-6.55-35.8-5.05zM64.75.55Q64.7.4 64.6.3V1q.05 0 .05-.05.15-.2.1-.4z" />
                </g>
                <g
                    id="AccHat026__Symbol_68_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M48.9 27.05L49 27h-.05l-.05.05m1.25-14.75l.3.5q0-.4.1-.75l-.4.25z" />
                    <path d="M50.45 12.8l-.3-.5L7.35 37q-9.5 5.15-6.7 10.7.9 2 5.05 2.45l.15-.05 32.4-5.65 10.65-17.4 1.55-14.25m129.35-2.1l-1.05-9.4-.3-1.3-61.35 1.95q-.1.55-.2 1.15l-.7 7.8v.05q0 1.35.1 2.65l63.5-2.9z" />
                </g>
                <g
                    id="AccHat026__Symbol_17_0_Layer0_0_FILL"
                    fill={colors.col2d}
                >
                    <path d="M46.7.1l-.05-.1-.05.05q-1.7.55-3.4 1.2L0 24.25h.05q5.35.9 11.45.65 20.1-1.05 32.45-5.4Q44 7.75 46.7.1z" />
                    <path d="M46.7 0h-.05l.05.1V0z" />
                </g>
                <path
                    fill={colors.col1n}
                    d="M190.95 72.4Q187 43.35 173.4 25.95q-12-15.3-31.45-21.5-15.8-5.1-36.5-4.2-.55 0-1.1.05l-.7.05q-9.55-.9-19.9.55l-.55.05q-.4.05-.75.1l-.5.1q-11 1.6-20.5 4.4-24.4 7.1-39 21.9-2.1 2.1-3.95 4.35-2.15 2.55-4 5.35-8.85 13-12 30.5-.038.075-.05.15-3.8 7.85-1.65 15-.15.25.65.75.8.45 1.3-.35.45-.8 1.05-1.2.55-.4.6-.4v.1q5.05-1.75 10.1-3.3 2-.6 4-1.15 21.15-5.9 42.95-7.35 2.85-.2 5.7-.3l1.05-.2v-.05q2.05-6.6.8-11.65 0-.05.05-.1 2-9.3 8.35-16.6 2.4-2.7 5.05-4.75 7.7-5.85 17.45-5.85 13.15 0 22.45 10.6 5.75 6.55 8 14.75-1.75 6 1.35 10.7v.05q5.15-.15 10.25-.1 24.6.35 49 6z"
                    id="AccHat026__Symbol_244_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M19.95 5.9q-.3-2.3-2.9-4Q14.15 0 10 0T2.9 1.9Q0 3.8 0 6.5q0 .45.1.9 0 .05.05.1.4 2.05 2.75 3.6Q5.85 13 10 13q4.15 0 7.05-1.9 2.7-1.8 2.95-4.2v-.05-.35-.25q0-.2-.05-.35z"
                    id="AccHat026__Symbol_156_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat026__Symbol_244_0_Layer0_0_FILL"
                transform="translate(95 53.55)"
                id="AccHat026__col1n"
            />
            <use
                xlinkHref="#AccHat026__Symbol_156_0_Layer0_0_FILL"
                transform="translate(178.65 46.95)"
                id="AccHat026__col1d"
            />
            <use
                xlinkHref="#AccHat026__Symbol_120_0_Layer0_0_FILL"
                transform="translate(112.35 58.8)"
                id="AccHat026__col1s"
            />
            <use
                xlinkHref="#AccHat026__Symbol_68_0_Layer0_0_FILL"
                transform="translate(46.9 109.3)"
                id="AccHat026__col2n"
            />
            <use
                xlinkHref="#AccHat026__Symbol_17_0_Layer0_0_FILL"
                transform="translate(52.7 135.15)"
                id="AccHat026__col2d"
            />
        </g></g>
    )
}

export default AccHat026
