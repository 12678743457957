import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#8A96C4",
    "col1n": "#A5B3E9",
    "col2d": "#FF9D43",
    "col2n": "#FEBE81",
    "col2s": "#E26D00"
}

function ShiLon078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M94.3 30.9L81.4 0q-1.5.65-2.95 1.2l-1 .4q-22.6 8.15-45.85-.3Q29.95.7 28.3 0L14.8 30.9 28.3 58q1.25.1 2.5.25.25.05.5.05 11.3 1.2 22.5 1.3h2q11 0 21.9-1 .6-.1 1.2-.15.25 0 .5-.05l2-.2 12.9-27.3m-53.7-12q.75-.15 1.7.05h-1.95l.25-.05m20.7-.95q-.2.05-.3.1h-.75q.5-.05 1.05-.1z"
                    id="ShiLon078__Symbol_518_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M57.2 8V0q-1.5.65-2.95 1.2l-1 .4-.15 4.85v.9q-7.85 2.75-16.2 3.25-2.3.3-4.55.45h-.4q-.35 0-.7.05-12.35.4-24.35-4.45.4-3.15.4-4.05h.05l.05-1.3Q5.75.7 4.1 0v7.55q-.2 2.65-.15 3.25 0 .55-.35 2.15-.2.95-.75 2.8-.7 2.55-1.95 5.8-.9 2.65-.9 5.4 0 3.6 2.25 11.25 1.3 4.6 1.85 7V58q1.25.1 2.5.25.25.05.5.05V43.25q-1.15-4.45-1.55-5.8-1.1-3.2-1.8-5.35-1.25-3.95-1.25-5.65 0-1.95 1.35-4.95 1.9-4.25 2.1-4.95.25-.9.45-2.45.25-2 .35-2.5.15-.85.35-1.4V8.4q23.2 8.1 46.1.4.2 2.75.95 4.7 1 2.7 1 4.9 0 1.1-.1 1.95-.2.6-.35 1.2l-.2.6q-2.3 6.5.2 13l.2.6q1.35 3.95 1.35 6.2 0 1.7-.65 5.45-.05.1-.05.25l-.05.2-.6 5.05-1.4 5.7q.6-.1 1.2-.15.25 0 .5-.05l2-.2q-.1-2.15.2-3.8.1-.85.4-1.55.3-1.4 1-3.8 1-3.5 1-5.85 0-1.85-.3-3-.35-1.2-.6-2.4-.05 0-.05-.05-1.55-4.15-1.55-7.35 0-3.25 1.15-5.9 1.15-2.65 1.2-3.3.05-.65 0-2-.1-1.35-.25-2.35-.15-1.05-1.05-3.35-.9-2.35-1-3-.15-.65-.1-1.45 0-.85-.05-1.05z"
                    id="ShiLon078__Symbol_485_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M53.7 45.6q.55-1.85.75-2.8.35-1.6.35-2.15-.05-.6.15-3.25V23.25Q34.25 19.85 26.7 3.2l-.05-.05q-1.2-.85-2.35-1.75L22.45 0q-.1.05-.15.15-.05.1-.1.15-.3.4-.6.85-2.65 3.6-5 7.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.1.25-.1.5-.15.65-.25 1.3-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.45-.3 11.9 3.95.3.25.55.55.1.05.15.15.05 0 .05.05v.1l-2.8 11.65q-.55 2.1-1.05 4.2l-.9 3.6-.85 3.35-.05 6.75q.05 0 .05.05h.1q19.65 4.9 38.6 6v-18.1q-.55-2.4-1.85-7-2.25-7.65-2.25-11.25 0-2.75.9-5.4 1.25-3.25 1.95-5.8m-27.05-8.1v-.05h.05l-.05.05m136.05 7.4q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.7-.9-1.35-1.8-.2-.3-.4-.55l-1.85 1.4q-.6.5-1.25.95-.65.5-1.35 1.05-2.7 4.6-6.75 8.65-9.9 10.05-23.25 11.85v2.25h-.05v11.7q.05.2.05 1.05-.05.8.1 1.45.1.65 1 3 .9 2.3 1.05 3.35.15 1 .25 2.35.05 1.35 0 2-.05.65-1.2 3.3t-1.15 5.9q0 3.2 1.55 7.35 0 .05.05.05.25 1.2.6 2.4.3 1.15.3 3 0 2.35-1 5.85-.7 2.4-1 3.8-.3.7-.4 1.55-.3 1.65-.2 3.8v5.55q20.2-1.3 38.85-4.95l1.55-.25v-8.95L143.6 59.9h-.05l-.55-2.45h.05q.05-.1.1-.1 4.5-4.95 12.5-4.6l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.25-1.45-.55-2.9v-.15l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5m-20.15 12.55q-.05.05-.1.05-.1.1-.3.2l-.1-.6q.2.25.4.35h.1z"
                    id="ShiLon078__Symbol_425_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M16.35 83.75h-.1l-.05 3.35q.05 0 .05.05h.1q19.65 4.9 38.6 6v-3.4q-18.95-1.1-38.6-6M22.8 55l.15-1.65Q12.55 44.8.8 52q-.1.25-.1.5-.15.65-.25 1.3-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.45-.3 11.9 3.95.3.25.55.55.1.05.15.15.05 0 .05.05v.1l.35-.05.55-2.4.15-.1h-.1m32.15-28v-3.75Q34.25 19.85 26.7 3.2l-.05-.05q-1.2-.85-2.35-1.75L22.45 0q-.1.05-.15.15-.05.1-.1.15-.3.4-.6.85 3 7.6 9.25 13.9Q41.1 25.4 54.95 27m53.1 62.95v3.65q20.2-1.3 38.85-4.95l1.55-.25v-3.8l-.5.1v-.05l-1.4.3q-18 3.85-38.5 5M138.1 3.4q-2.7 4.6-6.75 8.65-9.9 10.05-23.25 11.85v2.25h-.05v.95q14.7-1.2 25.4-12.05 6.5-6.55 9.5-14.5-.2-.3-.4-.55l-1.85 1.4q-.6.5-1.25.95-.65.5-1.35 1.05M162 54.3q1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.25-1.45-.55-2.9v-.15l-.1-.4q-.05-.2-.05-.4-11.8-7.2-22.15 1.35l.3 3.85.6.25q.05-.1.1-.1 4.5-4.95 12.5-4.6l2.05.2q.15 0 .3.05 2.15.4 4 1.3z"
                    id="ShiLon078__Symbol_403_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M24.2 44q-.2.2-.3.4-.3.45-.3 1v.05q0 .8.6 1.35.6.6 1.4.6.8 0 1.4-.6.6-.55.6-1.35v-.05q0-.55-.25-1-.15-.2-.35-.4-.6-.6-1.4-.6-.8 0-1.4.6m-1.6-14.3q-.8 0-1.4.6-.15.1-.2.3-.4.45-.4 1.1 0 .8.6 1.4.45.45 1 .55.2.05.4.05.8 0 1.4-.6.25-.25.45-.55v-.05q.15-.35.15-.8 0-.65-.35-1.1-.1-.2-.25-.3-.1-.1-.2-.15-.55-.45-1.2-.45M5.2 3q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L0 24.25v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4V3M25 14.6q-.15-.3-.4-.5-.15-.15-.35-.25-.45-.35-1.05-.35-.8 0-1.4.6-.6.55-.6 1.4 0 .6.4 1.15.05.1.2.25.5.5 1.15.6h.25q.35 0 .65-.1.4-.15.75-.5.15-.15.2-.25.4-.55.4-1.15 0-.5-.2-.9m2-14q-.6-.6-1.4-.6-.4 0-.75.15T24.2.6q-.6.6-.6 1.4 0 .8.6 1.4.6.6 1.4.6.8 0 1.4-.6.45-.45.55-.95.05-.25.05-.45 0-.8-.6-1.4m88.6 1.9q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4 2.8q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1z"
                    id="ShiLon078__Symbol_380_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon078__Symbol_518_0_Layer0_0_FILL"
                transform="translate(136.65 258.45)"
                id="ShiLon078__col1n"
            />
            <use
                xlinkHref="#ShiLon078__Symbol_485_0_Layer0_0_FILL"
                transform="translate(160.85 258.45)"
                id="ShiLon078__col1d"
            />
            <use
                xlinkHref="#ShiLon078__Symbol_425_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon078__col2n"
            />
            <use
                xlinkHref="#ShiLon078__Symbol_403_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon078__col2d"
            />
            <use
                xlinkHref="#ShiLon078__Symbol_380_0_Layer0_0_FILL"
                transform="translate(132.2 261.45)"
                id="ShiLon078__col2s"
            />
        </g></g>
    )
}

export default ShiLon078
