import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF"
}

function ObjToo010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M38.9 73.75q-.35.35-.75.65l4.1 8.65q2.9 4.85 8.65 2.4 4.7-2.8 2.25-8.9L47.5 65.5q-.05-.05-.15-.05l-.1-.2q-2.45-.4-4.25-2.2-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1m5.55 5.85q.1-1.4 1.2-2.25 1-.95 2.4-.75 1.35.05 2.3 1.1.85 1.05.75 2.45-.1 1.4-1.15 2.25-1.05.95-2.4.85-1.4-.15-2.3-1.2-.9-1.05-.8-2.45M15.7 0l1.7 14.8-1.1 1.35-4.4.55-4.1-2.25L6.25 1.2Q.25 6.05 0 14.65q-.3 11 11.2 14.35 3 .3 4.9 1.55 1.4.9 2.25 2.35l9.35 19.6q3.4-.55 7.5.75 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-1.8.75-3.3L28 28.8 25.05 8.3Q22.25.25 15.7 0z"
                    id="ObjToo010__Symbol_170_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToo010__Symbol_120_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M25.8 68.65q-.5 1.85-1.2 3.2l1.6 3.3v-.05l.75 1.6q1.7-3.5 5.85-2.8l-5.65-11q-1.1-1.15-1.6-2.45.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55M9.85 30.55q-.95-.35-2.25 0-2.4 1.15-.95 4l8.6 17.85q2 .15 4.25.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.1-.35-.15-.75l-13.8-26.8q-.55-1.3-1.55-1.7m.5-16.75l-8.65 1-1.1 1.35 9.9-1.2-.15-1.15zM10.35 13.8l-1-5.5Q6.55.25 0 0l1.7 14.8 8.65-1z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo010__Symbol_170_0_Layer0_0_FILL"
                transform="translate(83.55 233.95)"
                id="ObjToo010__col1n"
            />
            <use
                xlinkHref="#ObjToo010__Symbol_120_0_Layer0_0_FILL"
                transform="translate(99.25 233.95)"
                id="ObjToo010__col1d"
            />
        </g></g>
    )
}

export default ObjToo010
