import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000"
}

function BeaStu003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.2}
                    d="M178.3 45.35q9.25-14.55 12.15-34.2.5-3.5.85-7.1.15-2 .25-4.05h-.8q-.45 1.75-5 10.6-1 1.75-2.4 4.15-5.2 8.95-9.05 9.7-.15.1-.3.25-11.25 26.4-42.65 30.55l-6.15-14q-31-5.95-62 0l-7.25 13.9q-31.2-8.6-40.35-29.5-1.1-.65-2.45-1.9H13q-.1-.15-.15-.3-.85-.75-1.75-1.7-2.7-2.95-3.5-5.1-3.25-7.1-6.05-12.2Q-.55.55.45 0H0q.05 6.75.95 13.7.4 3.1.95 6.25l.1.3q1.5 5.35 3.7 10.6.3.7.65 1.45l1.75 4.05q.45.95.95 1.9v-.05q.1.25.25.5 1.15 2.3 2.4 4.55 1.15 2.05 2.45 4 1.35 2.15 7.1 9.4 5.7 7.25 14.55 14.65l1.75 1.3q1.35 1.05 2.8 2.1l8.2 5.35q17.8 10.2 39.2 11.8l1.1.05q3.35.2 6.8.2h.2q3.45 0 6.8-.2l1.1-.05q25.45-1.9 45.85-16l1.55-1.15q1.45-1.05 2.8-2.1l1-.7.65-.65q13.75-11.35 22.7-25.9m-71.95 30.5Q94.8 69.35 84 76.1q-2.899 3.237-8.9 1.65-9.9-5.65-12-14.2.15-10.25 6-14.55 25.05-5.95 50.45.5 4.05 4.9 5.75 16.4-.05 6.35-8.4 11.85-7.7 1.7-10.55-1.9m-71.7-22.3l-.1-.1q0-.05.05-.05.05.05.05.15z"
                    id="BeaStu003__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.251}
                    d="M178.4 44.1q9.25-14.55 12.15-34.2l-.05.05q-4.8 26.55-26.8 46.65-28.1 25.75-67.85 25.75-39.75 0-67.9-25.75Q1.95 32.8 0 0q.15 9.05 1.9 18.45l.1.3.5 1.7 1.45 4.3Q4.8 27.2 5.8 29.6q.3.7.65 1.45L8.2 35.1q.45.95.95 1.9v-.05q.1.25.25.5 1.15 2.3 2.4 4.55 1.15 2.05 2.45 4 1.35 2.15 7.1 9.4 5.7 7.25 14.55 14.65l1.75 1.3q1.35 1.05 2.8 2.1l8.2 5.35q17.8 10.2 39.2 11.8l1.1.05q3.35.2 6.8.2h.2q3.45 0 6.8-.2l1.1-.05q25.45-1.9 45.85-16l1.55-1.15q1.45-1.05 2.8-2.1l1-.7.65-.65q13.75-11.35 22.7-25.9z"
                    id="BeaStu003__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaStu003__Symbol_4_0_Layer0_0_FILL"
                transform="translate(96.75 157.4)"
                id="BeaStu003__col1n"
            />
            <use
                xlinkHref="#BeaStu003__Symbol_8_0_Layer0_0_FILL"
                transform="translate(96.65 158.65)"
                id="BeaStu003__col1d"
            />
        </g></g>
    )
}

export default BeaStu003
