import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666"
}
function SkiMor035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#8AAAC9"
                    d="M190.8 257.8h-.05q.102.079.25.2-.09-.088-.2-.2m3.4 0h-.05q-.05.1-.15.25.15-.15.2-.25z"
                    id="SkiMor035__Layer4_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M102.9 15.3q0-.25-.05-.55Q101.2 7.2 93.2 2.4 88.95 0 83.65 0q-.95 0-1.9.1-.4.05-.8.15-2.05.45-3.95.9-.25.05-.55.15Q69.5 3 64.5 5q-8.15 3.35-12.55 10.45-.05.15-.05.25l-.05-.25-.05.3q-.05-.2-.05-.3Q47.35 8.35 39.2 5q-5-2-11.95-3.7-.3-.1-.55-.15-1.9-.45-3.95-.9-.4-.1-.8-.15Q21 0 20.05 0q-5.3 0-9.55 2.4-8 4.8-9.65 12.35-.05.3-.05.55l-.3 2.25q0 .2-.05.5-.1.75-.15 1.6-.65 5.5.2 8.95 0 .15.1.3.6 1.9 2.25 3.4l.3.3h.05l.1.1q.1.05.2.15-.1.75-.15 1.45l-.1.7q-.05.7-.05 1.45-.05.5-.05 1 0 13.1 10.95 22.35 6.3 5.35 14.15 7.65 2.8.75 5.8 1.2 3.4.55 7.05.75 5.25.3 10.55.3h.4q5.3 0 10.55-.3 3.65-.2 7.05-.75 3-.45 5.8-1.2 7.85-2.3 14.15-7.65 10.95-9.25 10.95-22.35 0-.5-.05-1 0-.75-.05-1.45l-.1-.7q-.05-.7-.15-1.45.1-.1.2-.15l.1-.1h.05l.3-.3q1.65-1.5 2.25-3.4.1-.15.1-.3.85-3.45.2-8.95-.05-.85-.15-1.6-.05-.3-.05-.5l-.3-2.25z"
                    id="SkiMor035__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M97 11q-.05-.7-.15-1.45-1.8 1.35-4.55 2.25-1 .35-2.2.6-.05.05-.15.05-.05 0-.15.05-1.2.25-2.6.45-1.9.25-4 .25H69q-1.6.1-3.1.05-3.65-.1-6.75-.95h-.05q0-.05-.05-.05H59q-1.65-.5-3.15-1.2Q53.9 10 52.45 8.6q-1.15-1.35-2-2.7Q49 3.45 48.6.9V.05q-.006.231-.05.45 0 .2-.05.4l-.1.6V.9q-.35 2.55-1.85 5-.85 1.35-2 2.7-1.45 1.4-3.4 2.45-1.5.7-3.15 1.2h-.05q-.05 0-.05.05h-.05q-3.1.85-6.75.95-1.5.05-3.1-.05H13.8q-2.1 0-4-.25-1.4-.2-2.6-.45-.1-.05-.15-.05-.1 0-.15-.05-1.2-.25-2.2-.6-2.75-.9-4.55-2.25Q.05 10.3 0 11q1.95 1.35 4.65 2.25 3.05 1.05 7.1 1.5 1.85.2 3.85.25l16.35.05q3.3-.15 5.05-.35.3-.05.55-.1 1.2-.15 1.5-.25.3-.1 2.6-.55 2.25-.5 4.55-.4 1.45 0 2 .05h.15l-3.35-.2q4.35.15 5.8.15 2.3-.1 4.55.4 2.3.45 2.6.55.3.1 1.5.25.25.05.55.1 1.75.2 5.05.35L81.4 15q2-.05 3.85-.25 4.05-.45 7.1-1.5 2.7-.9 4.65-2.25z"
                    id="SkiMor035__Symbol_41_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#SkiMor035__Layer4_0_FILL" />
            <use
                xlinkHref="#SkiMor035__Symbol_43_0_Layer0_0_FILL"
                transform="translate(140.65 246.6)"
                id="SkiMor035__col1n"
            />
            <use
                xlinkHref="#SkiMor035__Symbol_41_0_Layer0_0_FILL"
                transform="translate(144 269.9)"
                id="SkiMor035__col1d"
            />
        </g></g>
    )
}
export default SkiMor035
