import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1l": "#BFBFBF",
    "col1n": "#999999",
    "col2d": "#71BA01",
    "col2l": "#97FA00",
    "col2n": "#86DE00"
}

function AccHat076({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#BFBFBF"
                    d="M151.45 212.8l-.6-.1q-11.3-1.9-22.65-4.85-.286-.071-.55-.1h-.2q-4.177 3.05 0 6.1l.6.15q3.354.836 6.65 1.6 6.458 1.468 12.85 2.65v10.45q-4.113-.904-8.25-2-.376-.079-.8-.2-4.563-1.189-9.15-2.6-.906-.272-1.75 0-.066.017-.15.05-3.734 2.825 0 5.65.05 0 .15.05 1.994.61 4 1.15.545.194 1.1.35 5.217 1.475 10.45 2.7 3.005.72 6 1.35.366.081.7.15l.2.05q19.221 3.887 38.45 4.2l4 .1q.7.004 1.4 0l2.35-.05q10.946-.186 21.85-1.5 6.837-.834 13.65-2.1l.05-.05q.15 0 .25-.05 1.539-.284 3.05-.6.399-.074.75-.15 9.02-1.821 18-4.45.544-.159 1.1-.35 1.268-.351 2.55-.75 3.007-4.028 0-5.8-.85-.25-1.7.05-4.693 1.462-9.4 2.7-.4.097-.8.2-4.51 1.169-9.05 2.1-1.14.27-2.3.5V219q7.977-1.364 15.85-3.25 1.68-.373 3.3-.8 1.744-.422 3.45-.9.328-.064.65-.15 3.586-3.075 0-6.15h-.15q-.32.02-.6.1-12.9 3.5-25.9 5.6h-.15q-38.9 6.1-79.25-.65m2.1 17.15v-10.7q38 5.9 74.7.65v10.65q-37.35 6.55-74.7-.6z"
                    id="AccHat076__Layer17_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M165 176.1q2.85-2.5 5.65-5.3 29.3-29.3 29.35-70.75-.05-41.4-29.35-70.75Q156 14.65 138.3 7.35q-1.7-.7-3.4-1.35-10.6-3.9-22.25-5.25-3.05-.35-6.2-.55-3.2-.2-6.45-.2-8.25 0-16 1.15-7.9 1.2-15.3 3.55-1.8.6-3.6 1.25-1.7.6-3.4 1.35-17.75 7.3-32.45 22Q0 58.65 0 100.05q0 11.55 2.3 22.15 5.85 27.45 26.95 48.6 2.8 2.8 5.7 5.3V90.05q24.25-7.5 49.05-9.2 14.25-1 28.65-.1 25.85 1.6 52.35 9.3v86.05z"
                    id="AccHat076__Symbol_293_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M26.75 1.9v31.15L0 66.05v4.3q24.25-7.5 49.05-9.2V57.6q-21.8 1.45-43.45 6.95l-1 .3 25.55-31.2V.5q-1.55.6-3.1 1.25-.15.05-.3.15m76.6 31.15V1.35q-1.7-.7-3.4-1.35v33.65l25.55 31.2q-24-6.1-47.8-7.4v3.6q25.85 1.6 52.35 9.3V66.1h.05v-.05l-26.75-33z"
                    id="AccHat076__Symbol_218_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M61.7 21.6V7.3q-17.75 7.3-32.45 22Q0 58.65 0 100.05q0 11.55 2.3 22.15 5.85 27.45 26.95 48.6 2.8 2.8 5.7 5.3v-4.3l-1.45-1.45Q16.25 153.1 9.75 131.4q-3.65-12.05-4-25.45V104q.7-36.9 27.75-63.25 12.85-12.5 28.2-19.15m73.2-1.9V6Q124.3 2.1 112.65.75 109.6.4 106.45.2 103.25 0 100 0q-8.25 0-16 1.15-7.9 1.2-15.3 3.55-1.8.6-3.6 1.25V20.2q1.25-.5 2.55-.95Q75.5 16.45 84 15.1q8-1.35 16.55-1.35 6.2 0 12.1.7.65.05 1.25.15 3.15.4 6.2.95 7.65 1.5 14.8 4.15m60.45 84.3v1.95q-.85 33.95-22.9 59.2-1.95 2.2-4.05 4.35l-.85.85q-1.25 1.25-2.55 2.5v3.25q2.85-2.5 5.65-5.3 29.3-29.3 29.35-70.75-.05-41.4-29.35-70.75Q156 14.65 138.3 7.35v13.7q15.95 6.75 29.25 19.7 27.1 26.35 27.8 63.25z"
                    id="AccHat076__Symbol_197_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.65.75Q25.6.4 22.45.2 19.25 0 16 0 7.75 0 0 1.15v79.7q14.25-1 28.65-.1v-80z"
                    id="AccHat076__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M0 4.05q14.25-1 28.65-.1V.35Q14.3-.45 0 .5v3.55z"
                    id="AccHat076__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M16.55 13.75q6.2 0 12.1.7V.75Q25.6.4 22.45.2 19.25 0 16 0 7.75 0 0 1.15V15.1q8-1.35 16.55-1.35z"
                    id="AccHat076__Symbol_33_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat076__Layer17_0_FILL" />
            <use
                xlinkHref="#AccHat076__Symbol_293_0_Layer0_0_FILL"
                transform="translate(92.5 54.65)"
                id="AccHat076__col1n"
            />
            <use
                xlinkHref="#AccHat076__Symbol_218_0_Layer0_0_FILL"
                transform="translate(127.45 74.35)"
                id="AccHat076__col1l"
            />
            <use
                xlinkHref="#AccHat076__Symbol_197_0_Layer0_0_FILL"
                transform="translate(92.5 54.65)"
                id="AccHat076__col1d"
            />
            <use
                xlinkHref="#AccHat076__Symbol_99_0_Layer0_0_FILL"
                transform="translate(176.5 54.65)"
                id="AccHat076__col2n"
            />
            <use
                xlinkHref="#AccHat076__Symbol_47_0_Layer0_0_FILL"
                transform="translate(176.5 131.45)"
                id="AccHat076__col2l"
            />
            <use
                xlinkHref="#AccHat076__Symbol_33_0_Layer0_0_FILL"
                transform="translate(176.5 54.65)"
                id="AccHat076__col2d"
            />
        </g></g>
    )
}

export default AccHat076
