import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col1s": "#CDBA18",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.15.35Q1.75 0 1.25 0T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4q.35-.35.35-.85t-.35-.9z"
                    id="AccGla024__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M107.8 50.4V0H0v50.4h107.8z"
                    id="AccGla024__Symbol_87_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M133.45 23.5q-.95-4.45-2.45-8.3Q123.6 1.45 108 0H27.05q-15.6 1.45-23 15.2-1.5 3.85-2.45 8.3h-.05v.05h.05Q.05 30.95.05 40.05H0l1.2 5.7Q3.55 53.6 9.7 60.7 33.45 77.9 54 67.5q.35-.2.75-.4Q61 63.75 67 57.8l.05-.05q.15-.15.3-.35.05.1.15.15l.05.05q.05.05 1.95 1.6 5.3 4.95 10.8 7.9.4.2.75.4 20.55 10.4 44.3-6.8 6.15-7.1 8.5-14.95l1.2-5.7H135q0-9.1-1.55-16.5h.05v-.05h-.05M92.6 11.65q12.75 1.05 18.1 8.85 6.9 13.75 0 29.75-3.65 5.7-10.1 8-3.05.8-6.5.8-8.15 0-14.35-4.5-1.85-1.9-3.7-3.25-2.05-1.4-4-2.2-4.25-1.7-9.05 0-2 .8-4 2.2-1.85 1.35-3.7 3.25-6.2 4.5-14.35 4.5-3.45 0-6.5-.8-6.45-2.3-10.1-8-6.9-16 0-29.75 5.35-7.8 18.1-8.85 2.55-.2 5.4-.15H87.2q2.85-.05 5.4.15z"
                    id="AccGla024__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M2.35 12.55q-.25.55-.45 1.1H2q-.15.25-.25.5-3.75 10.7.6 21.05Q4 38.05 6.6 40.55q.8.8 1.65 1.5 4.05 3.3 9 4.55-6.45-2.3-10.1-8-6.9-16 0-29.75Q12.5 1.05 25.25 0 8.15 0 2.35 12.55m96.55 1.6q-.1-.25-.25-.5h.1q-.2-.55-.45-1.1Q92.5 0 75.4 0q12.75 1.05 18.1 8.85 6.9 13.75 0 29.75-3.65 5.7-10.1 8 4.95-1.25 9-4.55.85-.7 1.65-1.5 2.6-2.5 4.25-5.35 4.35-10.35.6-21.05z"
                    id="AccGla024__Symbol_8_0_Layer0_0_FILL"
                />
                <g id="AccGla024__Symbol_60_0_Layer0_0_FILL">
                    <path
                        fill="#434343"
                        d="M8.55 16.5V0H0l.15 1.1v15.4h8.4M26.65 0v16.5h8.7V0h-8.7m-4.1 16.5V0h-9.9v16.5h9.9m149.75 0V0h-10.95v16.5h10.95M190.4 0v16.5h8.4V1.1l.15-1.1h-8.55m-4.1 16.5V0h-9.9v16.5h9.9z"
                    />
                    <path d="M8.55 16.5h4.1V0h-4.1v16.5M26.65 0h-4.1v16.5h4.1V0M86.7 43.45q-.4.25-.75.5 6.5-1.25 13-1.3H100q6.5.05 13 1.3-.35-.25-.75-.5-5.5-3.9-10.8-10.4-1.9-2.05-1.95-2.1l-.05-.1q-.1-.05-.15-.2-.15.3-.3.5l-.05.05q-6 7.85-12.25 12.25M176.4 0h-4.1v16.5h4.1V0m14 0h-4.1v16.5h4.1V0z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccGla024__Symbol_112_0_Layer0_0_FILL"
                transform="translate(191.25 136.35)"
                id="AccGla024__col2n"
            />
            <use
                xlinkHref="#AccGla024__Symbol_87_0_Layer0_0_FILL"
                transform="translate(138.8 139.6)"
                id="AccGla024__col2l"
            />
            <use
                xlinkHref="#AccGla024__Symbol_60_0_Layer0_0_FILL"
                transform="translate(93.05 153.05)"
            />
            <use
                xlinkHref="#AccGla024__Symbol_47_0_Layer0_0_FILL"
                transform="translate(125 129.5)"
                id="AccGla024__col1n"
            />
            <use
                xlinkHref="#AccGla024__Symbol_8_0_Layer0_0_FILL"
                transform="translate(142.2 141.15)"
                id="AccGla024__col1s"
            />
        </g></g>
    )
}

export default AccGla024
