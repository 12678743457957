import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M153.2 0q-17.55 14.9-31.25 21.65-25.6 12.95-37.55 1.1-.3-.3-.55-.65h-.35q-.25.3-.65.7-1.05 1.05-1.75 1.05-1.5 0-3.15-1.15-.45-.3-.75-.6h-.5q-.5.3-1.4.75-2 1-2.8 1-2.15 0-3.3-1.75h-.75q-.35.35-.7.65-12.6 11.75-39.3-1.4Q15.4 14.65 0 0q2.2 3.15 4.6 6.15.3.35 1 1.15 2.35 2.8 9.45 10.95 2.4 2.75 8.4 7.55 2.2 1.75 4.9 3.8 11.4 8.65 14.95 8.65 1.25 0 3.75-1 .25 0 2.95 1.75t4.55 1.75q2.35 0 4.65 1.75 2.3 1.75 2.85 1.75 1.25 0 3-1 1.25 0 3.25 1 1.75 0 4.2-1.3v.8q2-.05 4.1-.35 2.05.3 4.05.35v-.8q2.45 1.3 4.2 1.3 2-1 3.25-1 1.75 1 3 1 .55 0 2.85-1.75t4.65-1.75q1.85 0 4.55-1.75 2.7-1.75 2.95-1.75 2.5 1 3.75 1 3.55 0 14.95-8.65 1.55-1.15 2.9-2.25 7.6-5.95 10.4-9.1 9.15-10.5 10.45-12.1 2.4-3 4.65-6.15z"
                    id="BeaChi014__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M127.65 6.55q3.4-3.9 5.7-6.55Q109.6 21.75 98.8 22.2q-1.15-.05-1.9.05-.2-.1-.4-.15-1.45-.45-3.1 1-.25.25-1.55 1.35-.35.25-.65.45l-.05.05v-.05q-1.05-.4-2.45-.95-1.4-.55-3.6-.55-.15 0-.9 1.45-.75 1.4-1.35 1.4-1.1 0-3.3-1.45-2.05-1.45-2.15-1.55h-.6v2.2q-.2 1.45-1.1 1.45-.9 0-2.75-.85-1.85-.9-2.5-.9-.6 0-1.05.9-.5.85-.95.85-1.3 0-2.2-1.45-1-1.65-1.6-1.8-.4.85-1.1 2.3-.6 1.2-1.5 1.95-2.1-.1-3.7-1.5-1.75-1.5-1.9-1.5-.45 0-1.55.65-1.1.6-1.6.6-1.3 0-2.5-4.25-.35.2-2.4 1.9-1.35 1.15-2.75 1.2h.7q-1.05.1-1.25 0-.15-.1-.25-.15-.35-.2-.75-.4-.75-.45-1.7-1.05-2.65-1.35-5.25-1.2-2 .2-3.3.35-1.7-.35-3.75-.8Q20.85 19.7 0 1.25q1.95 2.3 4.6 5.3Q7 9.3 13 14.1q2.2 1.75 4.9 3.8 11.4 8.65 14.95 8.65 1.25 0 3.75-1 .25 0 2.95 1.75t4.55 1.75q2.35 0 4.65 1.75 2.3 1.75 2.85 1.75 1.25 0 3-1 1.25 0 3.25 1 1.75 0 4.2-1.3v.8q2-.05 4.1-.35 2.05.3 4.05.35v-.8q2.45 1.3 4.2 1.3 2-1 3.25-1 1.75 1 3 1 .55 0 2.85-1.75t4.65-1.75q1.85 0 4.55-1.75 2.7-1.75 2.95-1.75 2.5 1 3.75 1 3.55 0 14.95-8.65 1.55-1.15 2.9-2.25 7.6-5.95 10.4-9.1z"
                    id="BeaChi014__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi014__Symbol_53_0_Layer0_0_FILL"
                transform="translate(115.9 211.25)"
                id="BeaChi014__col1n"
            />
            <use
                xlinkHref="#BeaChi014__Symbol_15_0_Layer0_0_FILL"
                transform="translate(126.35 222.95)"
                id="BeaChi014__col1d"
            />
        </g></g>
    )
}

export default BeaChi014
