import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#914435",
    "col2n": "#5D342C"
}

function AccGla005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M3.7 3.65q.6-.6.6-1.5T3.7.65Q3.05 0 2.15 0 1.3 0 .65.65 0 1.25 0 2.15t.65 1.5q.65.65 1.5.65.9 0 1.55-.65z"
                    id="AccGla005__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M131.2 9.7q-.05-1.25-.15-.35-.05-7-11.2-8.65h-.25Q96.85-1.9 73.75 5.35h-.1q-.85.3-1.85.6-1.15.3-2.8 1.1-.05 0-.1.05-3.85 1.2-6.5 0-.05-.05-.1-.05-1.65-.8-2.8-1.1-1-.3-1.85-.6h-.1Q34.45-1.9 11.7.7h-.2Q.3 2.35.25 9.35.2 8.45.1 9.7H.05v.45Q0 12 0 13.75q0 24.9 14.95 33.85 20.35 9.2 32.85 2.05 9.45-5.75 14.5-20.95.7-2.15 1.35-4.5 2-2.7 4 0 .65 2.35 1.35 4.5 5.05 15.2 14.5 20.95Q96 56.8 116.35 47.6q14.95-8.95 14.95-33.85 0-1.75-.05-3.6v-.05-.4h-.05z"
                    id="AccGla005__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M166.7 12q.2-4.8 1.7-5.1l23.7 4.05q.65-1.9-.25-3.85l-10.4-1.8q-11.9-2.2-21.55-3.4-3.4-.4-6.5-.7-14.35-2.35-29.8 0-2.1.3-4.25.7-6.7 1.25-13.45 3.4l-.05.05q-1 .25-1.55.45l-2.55.85q-2.15.75-5.2.95h-.75q-3.05-.2-5.2-.95l-2.55-.85q-.55-.2-1.55-.45l-.05-.05Q79.7 3.15 73 1.9q-2.15-.4-4.25-.7-15.45-2.35-29.8 0-3.1.3-6.5.7Q22.8 3.1 10.9 5.3L.5 7.1q-.9 1.95-.25 3.85l23.7-4.05q1.5.3 1.7 5.1v6.25l3.65 1.7q.85 22.5 15.35 31 .05.05.15.1 20.8 9.6 33.6 1.7 9.9-5.9 14.95-22.35 1.25-2.55 2.5-2.5h.65q1.25-.05 2.5 2.5 5.05 16.45 14.95 22.35 12.8 7.9 33.6-1.7.1-.05.15-.1 14.5-8.5 15.35-31l3.65-1.7V12M149 4.1h.2q10.3 1.55 10.35 8.15.05-.85.1.35h.05V13q.05 1.75.05 3.4 0 23.5-13.75 31.95-18.75 8.6-30.25 1.85-8.7-5.45-13.35-19.8-1.55-4.8-2.65-10.65 0-.1-.05-.25v.1q-.5-4.15.3-6.5.85-2.4 2.4-3.1 1.55-.75 2.6-1.05.9-.3 1.7-.55h.1q21.25-6.8 42.2-4.3M85.65 8.4q.8.25 1.7.55 1.05.3 2.6 1.05 1.55.7 2.4 3.1.8 2.35.3 6.5v-.1q-.05.15-.05.25-1.1 5.85-2.65 10.65Q85.3 44.75 76.6 50.2q-11.5 6.75-30.25-1.85Q32.6 39.9 32.6 16.4q0-1.65.05-3.4v-.05-.35h.05q.05-1.2.1-.35.05-6.6 10.35-8.15h.2q20.95-2.5 42.2 4.3h.1z"
                    id="AccGla005__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla005__Symbol_96_0_Layer0_0_FILL"
                transform="translate(190.35 155.6)"
                id="AccGla005__col2n"
            />
            <use
                xlinkHref="#AccGla005__Symbol_75_0_Layer0_0_FILL"
                transform="translate(126.85 139)"
                id="AccGla005__col2l"
            />
            <use
                xlinkHref="#AccGla005__Symbol_27_0_Layer0_0_FILL"
                transform="translate(96.35 137.1)"
                id="AccGla005__col1n"
            />
        </g></g>
    )
}

export default AccGla005
