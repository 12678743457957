import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#D9D9D9",
    "col1n": "#BFBFBF"
}
function AccMor100({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M19.7 3.8q0-3.8-3.8-3.8H3.85q-.5 0-.95.1Q0 .5 0 3.8v6.05q0 2.95 2.3 3.6.7.2 1.55.2H16.4q3.3-.25 3.3-3.8V3.8z"
                    id="AccMor100__Symbol_206_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 0v11.85h7.65V0H0z"
                    id="AccMor100__Symbol_205_0_Layer0_0_FILL"
                />
                <g id="AccMor100__Layer10_0_FILL">
                    <path
                        fill="#FF0"
                        d="M127.5 302.85q-.072-.014-.15-.05l-.35 1.8q.053.041.1.05 2.294.547 17.7 4.15 15.424 3.604 29.5 3.7 7.656.05 11.2.05h.1q.49.003.9 0 2.013.016 2.05 0 .199-.036.35-.05.475.02.95 0 3.126.059 6.25 0h.15q.055.002 1.25 0 1.174-.011 3.45-.05 1.158.016 2.6 0 2.98-.019 7.15-.05 14.305-.104 29.7-3.5 15.404-3.406 16.5-3.85 1.094-.39 1.1-.45l-.35-1.85q-.038.037-1.15.1-1.107.113-16.15 2.95-15.045 2.886-29.5 3.85-4.27.288-7.3.5-1.444.094-2.6.15-2.287.173-3.45.25-1.16.073-1.2.05-.13.028-.2 0-3.349.066-6.7 0-.25.022-.5 0-.155.02-.2-.2-.013-.055-2.2-.25-.411-.013-.9-.05-.05-.004-.1 0-3.427-.272-10.55-.7-13.684-.814-29.55-3.45-15.854-2.647-17.9-3.1z"
                    />
                    <path
                        fill="red"
                        d="M128.1 299.35l-.35 1.65q.088.031.85.35.753.379 16.1 3.8 15.356 3.417 29.55 3.5 7.68.08 11.25.1h.1q.49.002.9 0 2.04.01 2.1 0 .156-.028.3-.05.037.024.05 0 3.59.074 7.15 0 .075.022.15.1.021.023 1.25.05 1.174.023 3.45.05 1.158.006 2.6 0 2.909.03 6.95.05 14.238.077 29.5-3.5 15.307-3.514 16.3-4 .994-.445 1.05-.5l-.35-1.7q-1.784.467-3.2.75-25.058 5.756-50.25 6.75-1.3.085-2.6.1-1.724.08-3.45.1-.425.019-.85 0-.2.028-.4 0-.075.026-.15 0-3.599.08-7.2 0-.15.027-.3 0h-.05q-.313.02-.65 0-.7.012-1.4 0-.45-.014-.9-.05-.05.024-.1 0-27.123-.716-54.4-6.9-1.307-.28-3-.65z"
                    />
                    <path
                        fill="#0040D4"
                        d="M126.7 306.45h-.05l-.35 1.85q3.145.842 18.4 4.3 15.25 3.464 29.4 3.6 7.794.072 11.4.1h.1q.49.005.9 0 1.972.027 2.1 0 .15.027.3 0 .9.043 1.8.05 2.701.019 5.4-.05.074.03.2.05h1.2q1.156-.024 3.45-.05 1.156-.02 2.6-.05 3.046-.039 7.35-.1 14.49-.2 29.7-3.45 15.207-3.188 16.6-3.75 1.44-.57 1.45-.7l-.3-1.8q-3.141.392-18.7 3-15.513 2.599-29.35 3.55-3.919.306-6.75.5-1.445.132-2.6.2-2.3.218-3.45.35-1.104.115-1.15.15-.172.231-.25.2-2.911.075-5.85.05-.662-.006-1.35-.05-.149.024-.3-.2-.058-.085-2.1-.25-.41-.038-.9-.1-.05.021-.1 0-3.493-.223-10.95-.65-13.86-.733-28.75-3.7-14.873-2.959-19.1-3.05z"
                    />
                </g>
                <g id="AccMor100__Layer10_1_FILL">
                    <path
                        fill="#841D9A"
                        d="M131.1 309.35q-2-.45-4.8-1.05l-.3 1.5q3 .7 5.1 1.15 10.496 2.38 20.95 3.95 17.968 2.692 35.85 3 .35.009.7 0 .15.021.3 0 1.088.043 2.15.05 2.538.014 5.05-.05.075.026.15 0 .2.028.4 0 18.232-.251 36.4-3 10.39-1.57 20.75-3.95 2-.4 4.8-1.1.15-.05.35-.1l-.3-1.5h-.05q-2.8.7-4.8 1.1-28.483 6.542-57.15 6.95-.2.028-.4 0-.075.026-.15 0-2.699.069-5.4.05-.9-.007-1.8-.05-.15.027-.3 0-.35.029-.7 0-28.309-.49-56.8-6.95z"
                    />
                    <path
                        fill="#008E29"
                        d="M127 304.6l-.35 1.85h.05q2.55.6 4.4 1 28.481 6.457 56.8 6.95.35.03.7 0 .15.027.3 0 .688.044 1.35.05 2.939.025 5.85-.05.075.026.15 0 .2.028.4 0 28.659-.406 57.15-6.95 1.9-.4 4.55-1l-.35-1.85q-.017.04-.05.05-2.4.55-4.15.9-28.492 6.545-57.15 6.95-.2.004-.4 0h-.15q-3.124.059-6.25 0-.475.02-.95 0-.15.02-.3 0-.35.005-.7 0-28.32-.493-56.8-6.95-1.7-.35-4-.9-.047-.009-.1-.05z"
                    />
                    <path
                        fill="#F90"
                        d="M127.9 301.05q-.066-.015-.15-.05l-.1.55h-.05l-.25 1.25q.078.036.15.05 2.05.45 3.6.8 28.484 6.458 56.8 6.95.35.007.7 0 .15.02.3 0 .25.022.5 0 3.351.066 6.7 0 .075.026.15 0 .2.027.4 0 28.662-.406 57.15-6.95 1.6-.3 3.75-.85.066-.013.1-.05l-.3-1.8q-.056.04-.1.05-1.95.45-3.45.75-28.49 6.544-57.15 6.95-.2.028-.4 0-.075.026-.15 0-3.56.074-7.15 0-.012.024-.05 0-.15.022-.3 0-.35.012-.7 0-28.319-.493-56.8-6.95-1.4-.3-3.2-.7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccMor100__Layer10_0_FILL" />
            <use xlinkHref="#AccMor100__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor100__Symbol_206_0_Layer0_0_FILL"
                transform="translate(182.65 305.6)"
                id="AccMor100__col1n"
            />
            <use
                xlinkHref="#AccMor100__Symbol_205_0_Layer0_0_FILL"
                transform="translate(188.6 306.45)"
                id="AccMor100__col1l"
            />
        </g></g>
    )
}
export default AccMor100
