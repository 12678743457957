import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.2 18.3q-.15-.05-.35 0h-.4q-.7.05-1.25.15-.15.05-.35.1-4.15-3.6-9.75-3.55-5.75-.05-9.9 3.65-.05.05-.1.15-.2.1-.35.25-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3Q0 24.4 0 30q0 6.2 4.45 10.6Q8.85 45 15.1 45q2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.25-3.25 4.1-7.45.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45t-2.25-5.45q-1.65-1.65-3.8-2.1-.3-.05-.55-.1-.25-.05-.5-.05-.15-.05-.35 0M172.75 7.75V5.8q-.2-1.7-1.25-3.4Q170 0 167.8 0h-1.25l-.4.1q-.45.05-.7.15-.3 0-1.15.5-.6.3-.9.75-.2.3-.45.8-.8 1.1-1.3 3.15-.55 2.15-.65 6.35V14q.05.2-.05 1.05-.6-.05-1.15 0-2.6-.05-4.85.75h-.3q-.55.05-1.6.45-1.6.65-1.85.7-1.5.45-2.4 1.4h-.05q-.05.05-.05.15-.15.1-.25.25-.8.95-.85 2.05v.2q0 .7.5 2.4.25.8.5 1.35-.7.3-1.25.7-1.75 1.15-1.75 2.95 0 .45.25 1.2.1.85.6 1.65.55.95.55 1.2v.1q-.35.25-.55.75-.25.65-.25 1.85 0 1.9 1.2 3.25.1.15.2.35l.25.4q.6.75 1.3 1.5 1.35 1.35 2.9 2.3.05 0 .1.05t.15.1q.35.2.75.4.35.2.75.35l.2.1h.05q.05.05.15.1.1 0 .2.05 2.5.95 5.4.95 6.25 0 10.65-4.4t4.45-10.6q-.05-3.55-1.45-6.5-.15-.75-.1-1.65v-3.15q-.05-3-.35-4.55v-3.3q-.1-.55-.15-1.6-.05-1.15-.1-1.55z"
                    id="BodHan013__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.5 7.75q0-3.2-2.25-5.45Q11.6.65 9.45.2 9.15.15 8.9.1 8.65.05 8.4.05q-.15-.05-.35 0Q7.9 0 7.7.05h-.4Q6.6.1 6.05.2 5.9.25 5.7.3q-1.95.45-3.45 2Q0 4.55 0 7.75t2.25 5.45q1.7 1.7 3.95 2.1l1.5.15h.1l.75-.05q1.15-.1 2.15-.5.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45M12.45 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-.85.9-1.85 1.4-1 .45-2.05.55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-2-1.95-1.95-4.7Q1.05 4.95 3.05 3q1.45-1.45 3.3-1.8.5-.1 1-.15H7.8q.4 0 .85.1 2.15.25 3.8 1.85z"
                    id="BodHan013__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan013__Symbol_27_0_Layer0_0_FILL"
                transform="translate(105.05 265.6)"
                id="BodHan013__col1n"
            />
            <use
                xlinkHref="#BodHan013__Symbol_36_0_Layer0_0_FILL"
                transform="translate(124.2 283.85)"
                id="BodHan013__col1d"
            />
        </g></g>
    )
}

export default BodHan013
