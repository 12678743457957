import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#640037",
    "col1l": "#D70076",
    "col1n": "#A10058",
    "col2d": "#9A3200",
    "col2l": "#FF5400",
    "col2n": "#D24400",
    "col2s": "#621F00"
}

function AccHat163({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M211.5 39.95q0-7.9-3.25-11.75-1.6-1.9-4.05-2.85-8-2.6-17.45 1.9v-.1q5.1-4.15 4.35-9.8l-.1-.55q-1.15-6.1-6.45-9.15-4.3-2.7-10.35-2.4-2.7.1-5.75.85-1.6.35-3.2.9-.35.1-.7.25-1.7-.75-3.95-1.65Q146.75-.15 101.1.1q-7.9-.6-16.3 1.7-33.65 1.65-61.7 21.75-.2.1-.3.2-.2.1-.4.25-4.9 5.5-8.65 11.9-.6.95-1.1 1.95 4.25-4.05 9.1-7.4 8.15-5.65 17.95-9.4 3.35-1.45 6.75-2.6 3.9-1.35 8.15-2.55 2.2-.6 4.4-1.15.1-.05.2-.05 1.9-.5 3.8-.95 34.45-8 70.25-2.95 11 1.55 22.15 4.35-.45 1.45-.45 3.1 0 1.75.35 3.3l.15.6q.1.45.3.9-9.95-2.85-21.2-4.8Q98.1 12.85 63 21q-1.9.45-3.8.95-.1 0-.2.05-2.2.55-4.4 1.15Q24.5 31.6 8.35 50.1l-.5.5-.15.2q-.7.8-1.35 1.6l-.05.1q-2 6.1-3.25 12.8-.55 2.25-1 4.55-.2 1.1-.4 2.25-.75 4.6-1.1 9.35-.15 1.2-.3 2.35l-.2 1.7v.9Q0 87 0 87.6v.6q0 2.5.15 4.8 1 14.5 8.8 22.05 6.4 5.7 6.9 1.3-3-12.6-2-23.35.2-2.45.65-4.8l.1-.6q3.25-16.95 17.3-28.65 1.6-1.35 3.35-2.6l.6-.45q.9-.65 1.9-1.3h-.1q17.8-12.25 41.55-15.45l.05.05q43.55-6.4 73.05 6.95.35.15.75.3 2 5.5 10.05 9.35 7.5 3.55 15.85 4.15 1.55.1 3.1.1h.05q13.7 7.45 20.85 20.85 2.55 5.1 5.1 0 .2-2 .25-4.1 0-6.2-1.75-12.9-.55-1.9-1.15-3.8-.85-2.55-1.95-5.15-.95-2-2-4 1.1.1 2.2.1 5.55 0 7.1-3.55.75-1.75.75-7.55M11.4 55.8q-.4.45-.8.95.393-.54.8-1.05v.1m-4.9 6.5q-.15.25-.35.55l-.2.3q.25-.45.55-.85z"
                    id="AccHat163__Symbol_538_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M155.5 13q1.5-.95 3.45-1.85h.05q-.2-.1-.45-.15-41.5-18.35-88.95-6.05-.7.15-1.45.3-.1 0-.15.05-34.15 1.1-56.65 28.9-2.9 3.3-5.25 7.05-2 3.1-3.6 6.45-.5 1.4-.9 2.8-.75 4.6-1.1 9.35-.15 1.2-.3 2.35L0 63.9v.9q.6-1.5 1.25-2.9 38.3-67.55 148.5-45.7l2.2.7q.1-.6 1.05-1.8.9-1.2 2.5-2.1m51 29.3q-.55-1.9-1.15-3.8-4.15-7.5-10.45-9.85l-1.5-1.05-1.2-.8-2.4-1.5q.1.25.15.5.45 1.7 1.25 4 .55 1.85.55 3.2 0 1.95-.35 2.7-.1.2-.25.45-.1.75 6.05 6.4 6.15 5.65 11.05 12.65 0-6.2-1.75-12.9z"
                    id="AccHat163__Symbol_533_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M159.55 9.9q.3-.2.6-.35-.5-.2-1.15-.5-27.15-11-57.9-8.6-7.9-.6-16.3 1.7Q51.15 3.8 23.1 23.9q-.2.1-.3.2-.2.1-.4.25-4.9 5.5-8.65 11.9-.6.95-1.1 1.95 4.25-4.05 9.1-7.4 8.15-5.65 17.95-9.4 3.35-1.45 6.75-2.6 3.9-1.35 8.15-2.55 2.2-.6 4.4-1.15.1-.05.2-.05 1.9-.5 3.8-.95 34.45-8 70.25-2.95 10.5 1.5 21.15 4.1.5.15 1 .25.95-3.3 4.15-5.6m32.05 38.05q-4.1-2.6-5.65-6.2-.05 0-.05.05.85 2.2 2.5 6 1.7 4.3 1.7 7.2v.05q-.05 2.85-1.9 4.15-1.85 1.2-6.1 1.2.85.45 1.65.95 12.45 7.35 19.2 19.9 2.55 5.1 5.1 0 .2-2 .25-4.1-2.9-9.7-16.5-18.6-.3-.2-.6-.45.15-.25.25-.45.35-.75.35-2.7 0-1.35-.55-3.2-.8-2.3-1.25-4-.05-.25-.15-.5.05 0 .1.05 1.05.65 2.15 1.35.05.05.15.1l1.2.8 1.5 1.05q6 4.35 10.45 9.85-.85-2.55-1.95-5.15-.65-1.35-1.35-2.7-.2-.4-.35-.75-.15-.3-.3-.55-.9-.1-1.75-.25-3.4-.65-6.75-2.35-.7-.4-1.35-.75m-4.8-20.35l-.05-.05v.05h.05m-20.2 5.15l2.75-.15q-.05-.1-.05-.2-11.05-1.7-13.55-9l-1-.3q-9.5-2.65-20.2-4.5Q98.1 13.2 63 21.35q-1.9.45-3.8.95-.1 0-.2.05-2.2.55-4.4 1.15-30.1 8.45-46.25 26.95l-.5.5-.15.2q-.7.8-1.35 1.6l-.05.1q-2 6.1-3.25 12.8-.55 2.25-1 4.55-.2 1.1-.4 2.25.4-1.4.9-2.8 7-14.7 20.9-24.25.1-.1.25-.2Q37.1 35.15 59 29.4q.1-.05.2-.05 1.85-.5 3.8-.95 6.8-1.65 14.35-2.9l6.9-.8q1.05-.1 2.15-.15 22.35-1.8 45.85 1.8 14.7 2.6 26.8 6.75-.2.05-.35.15-1.75.8-3.15 1.7-1.6.9-2.5 2.1-.95 1.2-1.05 1.8-42.05-13.45-88.1-3.75-.45.1-.9.25-1.9.45-3.8 1.05-.1 0-.2.05-3.15.9-6.2 2.05Q19.7 49.45 4.3 77.8 1.95 82.05.05 86.75q-.05.6-.05 1.2v.6q0 2.5.15 4.8 1 14.5 8.8 22.05 6.4 5.7 6.9 1.3-3-12.6-2-23.35.2-2.45.65-4.8l.1-.6Q20.3 58.35 59 44.6q.1-.05.2-.05l2.6-.9 1.2-.3q37.9-9.75 73.25-1.9 7.9 1.9 15.4 4.8.7.25 1.4.55-.1-.35-.2-.65-.45-1.55-.45-3.25 0-1.8.6-3.2 2.2-6 13.6-6.95z"
                    id="AccHat163__Symbol_534_0_Layer0_0_FILL"
                />
                <path
                    fill="#2A0017"
                    d="M285.95 113.95q-.15-.3-.3-.55-.9-.1-1.75-.25-3.4-.65-6.75-2.35-.7-.4-1.35-.75-4.1-2.6-5.65-6.2-.05 0-.05.05.85 2.2 2.5 6 1.7 4.3 1.7 7.2v.05q-.05 2.85-1.9 4.15-1.85 1.2-6.1 1.2.85.45 1.65.95 5.5.6 7.45-3.25.15-.25.25-.45.35-.75.35-2.7 0-1.35-.55-3.2-.8-2.3-1.25-4l.25.75q-.35-1.25-.3-1.2 1.05.65 2.15 1.35.05.05.15.1 4.1 2.6 9.85 3.85-.2-.4-.35-.75m-32.4-19.25q-.05-.1-.05-.2-10.5-1.65-13.3-8.35l-.1-.2q-.1-.25-.15-.45l-1-.3q1.85 7.85 9.5 9.05-2.45-.2-5.55 1.1-1.75.8-3.15 1.7-1.6.9-2.5 2.1-.95 1.2-1.05 1.8-1.15 3.65-.35 7.4.7.25 1.4.55-.1-.35-.2-.65-.45-1.55-.45-3.25 0-1.8.6-3.2 2.2-6 13.6-6.95l2.75-.15m-9.2-23.05q-.5-.2-1.15-.5l-.8.5q-2.75 2.4-3.8 5.7.5.15 1 .25l.1-.3q1-3.1 4.05-5.3.3-.2.6-.35z"
                    id="AccHat163__Layer6_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M135.7 26.35q3.8-.9 6.3-2.85Q120.35 8.75 87.9 6.6q-3.7-2.05-9.2-4.25Q72.7 0 67.2 0T54.55 2.2q-7.2 2.2-7.2 7.75 0 1.4.1 1.85l.3 1.3q-2.1.35-7.15 2.05h-.1q-1.75.55-3.7 1.35l-.2.1q-1.4.55-2.9 1.25Q15.15 25.55 2.85 38q-.7.7-1.35 1.45-.75.75-1.5 1.6 2.8-1.95 5.85-3.7 7.75-4.55 17.15-8 31.7-10.9 61.5-11 16-.05 32.35 3.1 7.75 1.5 15.55 3.75 1.6.5 3.3 1.15z"
                    id="AccHat163__Symbol_540_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M21 13.9q1.8-2.8.2-6.4-1.3-2.6-3.95-4.45l-.3-.2q-.75-.55-1.65-1Q11.15-.35 7 .05 2.75.6.95 3.4q-1.3 2-.8 4.35.05.1.05.2.2.9.65 1.8 1.65 3.55 5.8 5.75 4.1 2.2 8.35 1.7.8-.1 1.5-.25h.1q2.95-.75 4.4-3.05z"
                    id="AccHat163__Symbol_537_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.6 4.2Q9.75 1.8 21.65.1 11.85-.55 0 3l.3 1.3q.1-.05.2-.05.05-.05.1-.05z"
                    id="AccHat163__Symbol_536_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M6.3 0Q2.75.9 0 2.85q.55.2 1.15.45Q2.7 2.4 4 1.85q1.25-.6 3.15-1.3-.4-.3-.85-.55z"
                    id="AccHat163__Symbol_539_0_Layer0_0_FILL"
                />
                <g id="AccHat163__Layer10_0_FILL">
                    <path
                        fill="#FDFDFD"
                        d="M166.2 71.95q-2.2.65-2 .35-32.55 1-60.05 21.9l-1.8 1.35q-1.05.8-2.1 1.7-1.75 1.45-3.4 3.05-3.6 6.4-6.05 13.65l-.3 1 .05-.1q.45-.55.95-1.1 33.4-33.25 99.9-34.2 25.843.195 47.75 6.3-1.596-4.725-.25-9.45-1.168-.323-2.6-.6-.25-.505-2.2-.85-.465-.082-.7-.15-38.826-8.93-65.7-3.1.7-.4-1.5.25z"
                    />
                    <path
                        fill="#C6C6C6"
                        d="M245.3 87.7v-9.65q-3.096-.93-6.4-1.65-1.346 4.725.25 9.45 3.115.871 6.15 1.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccHat163__Layer10_0_FILL" />
            <use
                xlinkHref="#AccHat163__Symbol_538_0_Layer0_0_FILL"
                transform="translate(84.2 62.45)"
                id="AccHat163__col1n"
            />
            <use
                xlinkHref="#AccHat163__Symbol_533_0_Layer0_0_FILL"
                transform="translate(84.25 84.05)"
                id="AccHat163__col1l"
            />
            <use
                xlinkHref="#AccHat163__Symbol_534_0_Layer0_0_FILL"
                transform="translate(84.2 62.1)"
                id="AccHat163__col1d"
            />
            <use xlinkHref="#AccHat163__Layer6_0_FILL" />
            <use
                xlinkHref="#AccHat163__Symbol_540_0_Layer0_0_FILL"
                transform="translate(106.6 45.4)"
                id="AccHat163__col2n"
            />
            <use
                xlinkHref="#AccHat163__Symbol_537_0_Layer0_0_FILL"
                transform="translate(253.5 86.75)"
                id="AccHat163__col2l"
            />
            <use
                xlinkHref="#AccHat163__Symbol_536_0_Layer0_0_FILL"
                transform="translate(154.05 54.2)"
                id="AccHat163__col2d"
            />
            <use
                xlinkHref="#AccHat163__Symbol_539_0_Layer0_0_FILL"
                transform="translate(242.3 68.9)"
                id="AccHat163__col2s"
            />
        </g></g>
    )
}

export default AccHat163
