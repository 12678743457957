import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF8E8E"
}
function FacMou085({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M204.45 235.3q2.319.027 3.45-2.25 2.906-5.975.25-12.85-2.433-4.072-7.1-5.4l-17.5-3.95q-6.15-1.45-8.45 4.7-.1.4-.2.9-.3 1.2-.55 3.15-.05.4-.15 1v.35q-.05.45-.1.95-.05.25-.05.45-.05.1-.05.15-.057.45-.1.9-.048.422-.1.85-.112 1.801.05 3.7.05 1.2.6 2.2 3.15 6.1 9.8 4.4 2.9-.7 5.75-1.85.7-.25 1.3-.45.6-.2 1.1-.2.45 0 1.7.35t1.65.5q1.1.4 1.95.8 3.2 1.6 6.75 1.6z"
                    id="FacMou085__Layer9_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M25.6 8.2q.15-.15.25-.5.2-.6.3-1.2.05-4.15-5.8-5.55Q12.9-.95 4.6.95q-1.15.4-2.05.95Q1.7 2.4 1.1 3 .05 4.35 0 5.8q.1.8.4 1.5 1.25 2.35 4.3 2.2 1.3-.15 3.15-.95 2.4-1.05 2.85-1.15.2-.05.4-.05 2.2-.45 4.6.4 1.05.35 2.2.95 2.65 1.55 4.65 1.35 1.75-.2 3.05-1.85z"
                    id="FacMou085__Symbol_136_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou085__Layer9_0_FILL" />
            <use
                xlinkHref="#FacMou085__Symbol_136_0_Layer0_0_FILL"
                transform="translate(179.4 223.3)"
                id="FacMou085__col1n"
            />
        </g></g>
    )
}
export default FacMou085
