import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6D6D3B",
    "col1n": "#9A9956"
}

function AccMor004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.4 47.8q-1.15-1.3-2.35-2-1.85-.85-4.15-.35 4.15 15.35-.5 23.3l-.6.95q-1.05 1.4-2.35 2.75-4.35 4.35-11.9 6.35l-5.7-28.9h-.15q-.25.05-.55.1-4.55.9-9.7 2v-.05q-15.05 3.1-23.6 6.5h-.1l-.45-.15q-1.9-.65-3.75-1.25l-2.9-1Q36.5 41.55 4.55 3.45L0 0q.25.35.55.7v5.15Q31.4 43.9 87.15 62.2l5.55 40.05q4.05.65 12.9-.95 4.8-.9 13.85-2.9 3.65-.8 8-1.8 19.2-4.4 23.8-8.25.2-4.4.15-7.5-.05-.6-.05-1.15v-1q1.55-.7 3.05-1.35-.6.35-1.05.65.5-.3 1.15-.6l.45-.2-2.35-14.35q-2.45-10.8-6.2-15.05m-37.95 39.85q.094.047.2.05h-.2v-.05z"
                    id="AccMor004__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M85.25 66.75l.5.1q-1.7 27.75.6 31.95l10.75 4.5v-3.2q-.05-.8 0-1.05.05-3.85 0-8.1h-.05l.05-.2h-.05q.25-19.75-2.6-28.1 3.5.75 4.5 7.65-.15-.2.05.6l2.55 23.35q8.85-.3 23.25-3.15t30.9-10.4l.1-.1q.15-.6 1.95-1.55.45-.3 1.05-.65-26.75 11.85-55.35 14-.25-1.65-.45-3.1l-.05-.05q-.25-1-.5-2.95-.45-2.55-.9-7.3-1.35-14.3-4.85-17.1-.2-.2-.35-.4-1.55-1.65-4.4-1.65-1.15 0-1.95.35-.6.2-1.45.9l-.15-.1Q37.25 44.4 4.4 1.05L3 0 .35 4.15Q-.4 5.3.5 6.45q21.75 25.1 45.7 41 12.9 8.6 26.5 14.5 6.2 2.7 12.55 4.8z"
                    id="AccMor004__Symbol_62_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor004__Symbol_102_0_Layer0_0_FILL"
                transform="translate(132.6 228.55)"
                id="AccMor004__col1n"
            />
            <use
                xlinkHref="#AccMor004__Symbol_62_0_Layer0_0_FILL"
                transform="translate(128.2 227.5)"
                id="AccMor004__col1d"
            />
        </g></g>
    )
}

export default AccMor004
