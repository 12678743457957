import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1l": "#BFBFBF",
    "col1n": "#999999"
}

function AccHat084({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M200 83.85l-3.2-8.75q-.05-.15-.15-.25-.7-1.5-1.45-2.95 0-.05-.05-.1-18.15-36.75-41.9-50.35Q127.15 6.5 107.65 0q-19.5 6.5-45.6 21.45-23.75 13.6-41.9 50.35-.05.05-.05.1-.75 1.45-1.45 2.95-.1.1-.15.25l-3.2 8.75h-4.9v.4h-.05V99.5L0 174.95h7.8q8.9-5.65 18.55-4.1-10.15-21.4-12.25-49.5l2.6-22.05h85.85v66.6h10.2V99.3h85.85l2.6 22.05q-2.1 28.1-12.25 49.5 9.65-1.55 18.55 4.1h7.8L204.95 99.5V84.25h-.05v-.4H200z"
                    id="AccHat084__Symbol_301_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 59.45h72.15V0Q22.2 15.9 0 59.45m82.95 0h72.15Q132.9 15.9 82.95 0v59.45z"
                    id="AccHat084__Symbol_220_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 93.2q8.9-5.65 18.55-4.1Q8.4 67.7 6.3 39.6L0 93.2m181.15-4.1q9.65-1.55 18.55 4.1l-6.3-53.6q-2.1 28.1-12.25 49.5m11.05-87l-.8-2.1H8.3l-.8 2.1h184.7z"
                    id="AccHat084__Symbol_200_0_Layer0_0_FILL"
                />
                <g id="AccHat084__Layer17_0_FILL">
                    <path
                        fill="#747474"
                        d="M273.55 204.7q9.65-1.55 18.55 4.1l-6.3-53.6q-2.1 28.1-12.25 49.5M92.4 208.8q8.89-5.66 18.55-4.1-10.15-21.4-12.25-49.5l-6.3 53.6z"
                    />
                    <path
                        fill="#999"
                        d="M289.55 133.35V118.1h-.05v-.4H95v.4h-.05v15.25L84.6 208.8h7.8l6.3-53.6 2.6-22.05h85.85v66.6h10.2v-66.6h85.85l2.6 22.05 6.3 53.6h7.8l-10.35-75.45m-51.7-78.05q-26.1-14.95-45.6-21.45-19.5 6.5-45.6 21.45-23.75 13.6-41.9 50.35-.05.05-.05.1-.75 1.45-1.45 2.95-.1.1-.15.25l-2.55 6.95.15-.3h183.1l.1.3-2.5-6.95q-.05-.15-.15-.25-.7-1.5-1.45-2.95 0-.05-.05-.1-18.15-36.75-41.9-50.35m31.95 50.45h-72.15V46.3q49.95 15.9 72.15 59.45M186.85 46.3v59.45H114.7q22.2-43.55 72.15-59.45z"
                    />
                    <path
                        fill="#BFBFBF"
                        d="M186.85 105.75V46.3q-49.95 15.9-72.15 59.45h72.15m10.8 0h72.15Q247.6 62.2 197.65 46.3v59.45z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccHat084__Layer17_0_FILL" />
            <use
                xlinkHref="#AccHat084__Symbol_301_0_Layer0_0_FILL"
                transform="translate(84.6 33.85)"
                id="AccHat084__col1n"
            />
            <use
                xlinkHref="#AccHat084__Symbol_220_0_Layer0_0_FILL"
                transform="translate(114.7 46.3)"
                id="AccHat084__col1l"
            />
            <use
                xlinkHref="#AccHat084__Symbol_200_0_Layer0_0_FILL"
                transform="translate(92.4 115.6)"
                id="AccHat084__col1d"
            />
        </g></g>
    )
}

export default AccHat084
