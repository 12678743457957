import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#480000",
    "col1n": "#830000",
    "col2n": "#FF5D5D"
}

function AccHat029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M173.6 22.2q.25-.6.5-1.15.2-.5.45-.95-.15-.35-.35-.65-2.6-4.15-17.15-8.65-18.4-5.7-45-8.7Q85.4-.95 66.05.45 51 1.6 47.2 4.9q-.75.65-1 1.4-.3-1.6.35 2.75.6 4.3 1.2 10.8.55 6.45.65 8.9v10.1q-.05 3.65-.3 7.2-.25 3.45-.6 6.85v.25q-.05.1-.05.25l-.15 1.4L0 71.9v6.3q.5 4.7 5.75 7.8Q12.9 90.4 28 95.2q29.75 9.45 72.8 14.75 42.3 5.25 73.1 3.55.55-.05 1.05-.1 16.35-1.1 23.8-4.1l.6-.3q4.95-2.25 5.4-6.6v-6.3l-42.9-24.6q1.9-17.95 6.3-34.75 2.35-7.5 5.45-14.55z"
                    id="AccHat029__Symbol_247_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M204.65 96.9l-.15-.3q-.75-4.45-7.15-9.4-11.4-8.8-35.45-14.75l-2.95 19.35-113.7-15.25L47.3 56.3q-.05.2-.05.4Q17.7 55.55 4.4 65.95q-.6.4-1.1.85-.2.15-.35.35-.1.1-.2.25-2.2 2.15-2.6 5.1l-.05.25-.1.6q.1 2.45 2.95 5.05Q9.2 84 28.75 90.2q25.2 8 59.85 13.05 4.5.6 9.15 1.25 1.9.2 3.8.45 41.75 5.2 72.3 3.55l1.8-.1q19.05-1.3 26.05-5.2.5-.3.95-.6 1.1-1 1.65-2.45.65-1.4.35-3.25m-30.6-73.85q.25-.7.6-1.3v-.2q.15-1.05-.5-2.1-2.6-4.15-17.15-8.65-18.4-5.7-45-8.7Q85.35-.95 66 .45 50.95 1.6 47.15 4.9q-1.05.95-1.2 2.05v.35q.05.45.15.95l.2.5q.5 1.05 1.85 2.2 4.1 3.25 15.5 6.8 18.4 5.7 45.05 8.75 26.6 3.05 45.95 1.6 14.05-1.05 18.25-4 .7-.45 1.1-1 0-.05.05-.05z"
                    id="AccHat029__Symbol_158_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M96.25 39.45q17.05-.9 17.7-5.05l2.9-19.5q-.75 4.05-17.75 4.95-1.45.1-3 .15-8.1-.05-16.3-.5-.35-.05-.7-.05-20.35-1.25-41.45-5.35l-1.3-.3q-5.8-1.15-11-2.45l-.2-.05q-.2-.05-.35-.1-3.6-.95-6.9-1.95-10.05-3.15-13.8-6-1.95-1.5-2-2.95L0 20.25v.3Q.05 22 2 23.5q3.75 2.85 13.8 6 16.35 5 39.9 7.9l1.6.2 5.2.6q.7.05 1.4.15 18.4 1.85 32.35 1.1z"
                    id="AccHat029__Symbol_71_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat029__Symbol_247_0_Layer0_0_FILL"
                transform="translate(96.1 6.95)"
                id="AccHat029__col1n"
            />
            <use
                xlinkHref="#AccHat029__Symbol_158_0_Layer0_0_FILL"
                transform="translate(96.15 4.95)"
                id="AccHat029__col1d"
            />
            <use
                xlinkHref="#AccHat029__Symbol_71_0_Layer0_0_FILL"
                transform="translate(141.3 62.05)"
                id="AccHat029__col2n"
            />
        </g></g>
    )
}

export default AccHat029
