import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1n": "#EEEEEE",
    "col2n": "#000000"
}
function ShiSpe137({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M181.85 9.5q-9.35 6.45-19.7 10.35-4.55 1.7-9.25 2.9-8.15 2.1-16.9 2.75l-1.1.05q-2.2.15-4.4.2h-3.1q-3.2 0-6.3-.2l-1.1-.05q-13.8-1.05-26.1-5.65-10.4-3.9-19.75-10.35L72.5 8.25q-.2-.15-.4-.25-1.2-.9-2.3-1.75l-1.85-1.4L63.1 0q-9.65 5.45-18.35 12.8Q10.9 41.55 10.9 82.15q0 30.95 19.75 55.05-.1.25-.25.6-.3.8-.8 1.85-.05.15-.15.35l-.1.2q-.75 1.5-2.35 3.8-3.3 4.4-10.45 8.5-1.9 1.1-7.05 3.75-4.55 2.45-6.45 4.15l-.4.4q-3.75 3.55-2.15 6.1 2.15 3.4 9.15 2.5 7-.95 20.3-.2 13.3.7 20.8 3.75 1.45.6 2.8 1.15 2.8 1.65 8 4.3l3.6 1.8 2.7 1.2q.45.25 1 .45 8.1 3.35 11.8 3.1h5.8q2.7-.35 12.15-2.35 5.95-1.3 19.6-2.1l4.4-.05.3-.05 18.95.7q1.35.15 6.85.7 3.7.35 6.45.85 5.75 1.05 6.6 1.2 4 .6 7.15.6 5.35 0 10.75-2.6 3-1.5 8.45-4.75 7.5-3.75 9.05-4.65 1.2-.6 2.25-1.1 1.2-.5 2.25-.85 1.2-.65 4.2-1.4l.9-.2q2.05-1 10.15-1.85h1.35l.15-.05 3.45.05q.8.05 1 .15h1.4q.2 0 .45.05 2.25.15 9.1.6h2.1q4.15-.3 6.35-.8 2.1-.5 4.3-1.75 2.2-1.25 2.2-3.35-.1-2.1-.6-2.95-.7-1.15-3.2-2.65-1.55-.9-4.55-1.7-4.55-1.3-5.05-1.45-5.25-1.75-10.35-7.05-.2-.15-.3-.35-.95-1.35-1.8-2.75-.4-.65-.7-1.05-.1-.25-.2-.45-.05-.15-.1-.25-.1-.2-.2-.35-.55-1.1-.8-1.8 21.4-24.75 21.4-57 0-40.6-33.9-69.35-7.9-6.65-16.5-11.75l-3.85 3.8-2.1 1.65-.1.05q-.15.1-.25.2-.25.2-.55.4l-3.2 2.35M196.1 167q.136-.086.25-.2h.15q-.206.082-.4.2m19.5 0l.6.1-.95-.1h.35M15.8 156.9v.05l-.05.05v-.25q0-.2.05-.35v.5m52.9 22.6l-.1-.05h.1v.05m91.9-.25h.5l.05.05-.55-.05m-8.55-1.35q0 .05.05.1h-.6q.25-.05.55-.1z"
                    id="ShiSpe137__Symbol_385_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.95 132.4q-.3.8-.8 1.85-.05.15-.15.35 24.85 27.6 54.5 33.6l.1-.05Q33 161 15.3 146q-8-6.8-14.1-14.2-.1.25-.25.6M136.4 14.05Q147.9 8.75 157.9 0l-1.5 1.15q-.15.1-.25.2-.25.2-.55.4l-3.2 2.35q-9.35 6.45-19.7 10.35-4.55 1.7-9.25 2.9-8.15 2.1-16.9 2.75l-1.1.05q-2.2.15-4.4.2h-3.1q-3.2 0-6.3-.2l-1.1-.05q-13.8-1.05-26.1-5.65-10.4-3.9-19.75-10.35l-1.65-1.25q-.2-.15-.4-.25-1.2-.9-2.3-1.75L39.2 0q10.05 8.75 21.5 14.05.45.2.95.4 17.5 7.45 36.8 7.45h.2q19.3 0 36.85-7.45l.9-.4m55.05 119.7q-5.55 6.35-12.5 12.25-17.7 15-39.25 22.15 4.9-1 9.65-2.6 11.35-3.8 22-10.95 10.95-7.45 21.15-18.4l.05-.05q-.05-.15-.1-.25-.1-.2-.2-.35-.55-1.1-.8-1.8z"
                    id="ShiSpe137__Symbol_386_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.95 96.3q-2.75 0-4.8 1.9-.1.05-.15.15-2 2-2 4.95 0 2.9 2 4.95 2 2.05 4.95 2.05 2.9 0 4.9-2.05 1.9-2 2.1-4.55v-.4q0-2.95-2.1-4.95-2-2.05-4.9-2.05m9.3-30.15q-2-2-4.9-2-2.85 0-4.8 1.85l-.15.15q-2 2.1-2 5 0 2.85 2 4.85 1.95 2.15 4.95 2.15 2.9 0 4.9-2.15 1.85-1.9 2.1-4.45v-.4q0-2.9-2.1-5m0-32.05q-2-1.95-4.9-1.95-2.85 0-4.8 1.9-.1 0-.15.05-2 2.05-2 5.05 0 2.85 2 4.8Q8.35 46 11.35 46q2.9 0 4.9-2.05 1.85-1.8 2.1-4.55v-.25q0-3-2.1-5.05m-4.4-22.15q1.9-1.9 2.1-4.55v-.35q0-2.95-2.1-4.95-2-2.1-4.9-2.1-2.75 0-4.8 1.95-.1.05-.15.15-2 2-2 4.95 0 2.85 2 4.9Q4 14 6.95 14q2.9 0 4.9-2.05z"
                    id="ShiSpe137__Symbol_387_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe137__Symbol_385_0_Layer0_0_FILL"
                transform="translate(64.5 223.75)"
                id="ShiSpe137__col1n"
            />
            <use
                xlinkHref="#ShiSpe137__Symbol_386_0_Layer0_0_FILL"
                transform="translate(93.95 229.15)"
                id="ShiSpe137__col1d"
            />
            <use
                xlinkHref="#ShiSpe137__Symbol_387_0_Layer0_0_FILL"
                transform="translate(192.15 265.05)"
                id="ShiSpe137__col2n"
            />
        </g></g>
    )
}
export default ShiSpe137
