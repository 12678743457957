import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M129.35 92.95h.15v-.1l-.15.1m61.6-52.1q-6.85-7.85-17.5-5.6-4.7 1.1-10.3 4.15-1.1-4.15-3.3-8.4-.4-.85-.85-1.65l-2.5-4.1-.7-1q-.1-.1-.15-.2-6.8-9.3-18.25-14.9-6.75-3.3-14.2-5.4Q106.3-1 85.75.3 81.7.7 77.5 1.4q13.3-.2 21.05 2.3-.65 0-1.25.05-33.75 1.5-60 20.1 5.15-1.4 10.25 0-11.4 2.8-19.35 9.05l-1.25.95Q12.45 45.35 5 67.25q-.5 1.4-.95 2.9-6.75 19.85-2.2 40.4 1.35 4.75 3.15 9 4.95 11.7 13.1 19.2 6.55 6.1 15.25 9.45 2.1.65 4.45 1.3l4.8.9q-.2-.25-.45-.45-2.2-2.5-4.1-4.65-2.2-3.1-3.55-6.55-.4-.95-.7-1.95-.9-2.75-1.05-4.3-.4-3.4-.35-5.45 4.65 3.9 10.9 6.65-7.65-17.7-5.9-30.5 0-.05.05-.1.15-.85.3-1.75.6-3.25 1.6-6.8.05.8.2 1.6.65 4.55 3.45 8.95 2.55 3.95 6.9 7.7-3.05-14.85 3.4-29.7 2.4-5.7 6.25-11.35l4.95 2.35q-1.05 4.7-1.6 9-2.25 17.85 4.55 28.65-.3-3-.45-5.85-.1-1.8-.15-3.55l.05-3.75q.3-7.55 2.3-15.5.75-3 1.75-6.05H71l2.15 1Q89.5 85 108.4 85.2h2.75q27.6-.7 58.95-16.7l.2.1q7.6 4.35 11.35 14 .9 2.55 1.65 5.25 2.25 8.25 2.85 17.8.3 4.3.05 12.5 9.3 3.55 14.25 20.6.7 2.45 1.3 5.2 1.9-2.25 2.9-5.2.4-1.4.65-3 .65-4.85.5-6.45-.15-1.65-.3-3.2-.15-1.55-.25-2.7-.15-1.2.2-2.05.35-.9.55-1.15.2-.3.45-.9.05-.15.15-.25 1.15-1.5 2-3 6.1-10.65 0-22.8-.85-1.8-2-3.6-.7-.85-.55-1.65.15-.85.95-3.2l.4-1.1q2.95-8.1 0-16.2-3.55-9.05-9.55-14.15-7.35-7.25-24.25-7.9 7.3-6.25 17.85-3.4l.3.1.05.05v-.05q-.25-.6-.6-1-.1-.15-.25-.3z"
                    id="HaiSho056__Symbol_177_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M48.4 64.85l4.95 2.35q4.2-12.45 11.95-20.05-10.3 7-16.9 17.7m-21.8 29.6q.6-3.25 1.6-6.8l.1-.4.05-.05q-3.4-29.95 16.15-44.25-11.05 7.6-16.6 16.4-8.95 16.4-1.3 35.1m-13.4-40.8q-1.2 1.4-2.3 2.85Q1.35 69.4.05 85.2q-.75 20.65 16.7 36.75 2.25 1.8 4.85 3.65-.4-3.4-.35-5.45Q12.6 113.3 8.5 102.5q-.8-1.75-1.35-3.55Q5 92.2 5.25 84.7q.05-1.1.15-2.2-.05-16.25 7.7-28.3.2-.5.45-1-.2.2-.35.45m149.45-15.2q7.3-5.6 17.95-3.2-1.8-1.2-4.5-2.1-10.15-3.95-22.3 5.05l-1.75-5.5q0-.1-.05-.2-1.1-4.15-3.3-8.4-.4-.85-.85-1.65l-2.5-4.1-.7-1Q117.45-9.1 76.4 4.75q16.35-2.4 34.85 2.6 1.5.4 3.05.9l1 .3.6.2q25.85 8.4 34.85 37.05l-.4.15q-19.4-34.7-68.05-28.3 43.7 1.85 58.75 29.8-28.9-25.2-57.75-13.75 32.1-2.35 52.9 17.9-46.3-21.25-70.8 9.35-3.4 3.8-5.6 9.05v.05l-.05.05.05.05 4.25 1.9q40.75 17.35 94.9-10.15 12.95 6.2 15.55 34.15l.7 7.4q6.85-2.7 12.25 2.35l.05-.05q.65-14.2-12.3-20.25 8.4-7.6 14-1.2-4.45-12.55-14.8-13.2 6.1-6.75 14.8-3.25-6.25-18.2-26.1-10.45 3.05-5.6-2.25-12.4 0-.05-.05-.05-2.45-2.8.35-5.25l-.05-.05.05-.05q.45-.4 1.25-1 .05 0 .05-.05l.2-.1z"
                    id="HaiSho056__Symbol_93_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho056__Symbol_177_0_Layer0_0_FILL"
                transform="translate(85 25.75)"
                id="HaiSho056__col1n"
            />
            <use
                xlinkHref="#HaiSho056__Symbol_93_0_Layer0_0_FILL"
                transform="translate(96.15 32.65)"
                id="HaiSho056__col1d"
            />
        </g></g>
    )
}

export default HaiSho056
