import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400"
}

function ShiMot018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.6 33.75q3.35-3.35 3.4-8.15-.05-4.8-3.4-8.2-3.4-3.35-8.15-3.35-.6 0-2.55.15.25-1.25.25-2.65 0-4.75-3.4-8.15Q30.4.05 25.6 0q-4.8.05-8.2 3.4-3.35 3.4-3.35 8.15 0 .85.1 1.65.05.5.1.95-2.25-.1-2.7-.1-4.75 0-8.15 3.35Q.05 20.8 0 25.6q.05 4.8 3.4 8.15 3.4 3.4 8.15 3.4.45 0 2.75-.25-.25 2.25-.25 2.55 0 4.75 3.35 8.15 3.4 3.35 8.2 3.4 4.8-.05 8.15-3.4 3.4-3.4 3.4-8.15 0-1.35-.25-2.55 1.2.25 2.55.25 4.75 0 8.15-3.4M21.35 17.5q.7-.35 1.4-.55 1.3-.4 2.75-.4 3.3 0 5.75 2.05l.55.55q1.25 1.2 1.95 2.7.65 1.5.75 3.3-.05.15 0 .35-.05 1.85-.65 3.4-.65 1.45-1.85 2.75-.1.05-.2.2l-.1.1q-1.9 1.85-4.35 2.35-.9.2-1.85.2-3.7 0-6.35-2.6l-.05-.05q-.95-.95-1.5-2.05-1.1-1.95-1.05-4.3-.05-3.7 2.55-6.35l.05-.05q1-1 2.2-1.6z"
                    id="ShiMot018__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot018__Symbol_50_0_Layer0_0_FILL"
                transform="translate(166.7 257.65)"
                id="ShiMot018__col1n"
            />
        </g></g>
    )
}

export default ShiMot018
