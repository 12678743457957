import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M19.15 9.7l3.6-8.85-7.7-.85 4.1 9.7M7.8 0L0 .85 3.75 9.7 7.8 0z"
                    id="FacMou066__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    d="M206.5 226.7q-.15-.35-.5-.5-12.85-6-26.9-.05-.4.2-.5.55-.2.35 0 .7.15.4.5.5.35.2.75 0 13.2-5.6 25.35 0 .35.2.75.05.35-.15.5-.5.2-.35.05-.75z"
                    id="FacMou066__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou066__Symbol_91_0_Layer0_0_FILL"
                transform="translate(181.05 225.05)"
                id="FacMou066__col1n"
            />
            <use xlinkHref="#FacMou066__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou066
