import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E4B670",
    "col2l": "#FF5D5D",
    "col2n": "#FF0000"
}

function ObjMor026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M99.85 8.75L95.05 0l-94 52.75q-3.5 8.65 4.9 8.7l93.9-52.7z"
                    id="ObjMor026__Symbol_134_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M40.55 48.75q2.95-4.15 4.4-9.5 2.3-8.75-.4-16.9l5.5-3.1q-.35-.55-.8-1.45-.3-.55-.65-1.2l-5.2-9.15-5.6 3.15q-.3-.25-.5-.6-5.35-5.5-13.2-7.8Q18.75.55 13.65.95q-.25.05-.6.05-.85.05-1.7.15-1.25.3-2.55.6-.7.15-1.55.35l-.05.05-.05-.05q-3-3.55-5.95-1.05Q-.15 3.4 0 5.65q.05.5.15 1.05 0 .25.05.45 1.45 15.4 8.5 27.55 6.7 12.75 19.35 22.05.9.6 1.75 1.05.85.35 1.55.6 1.5.5 2.95.45 2.35-1.05 2.1-3.6-.05-.7-.3-1.5l-.05-.05q2.6-2.2 4.5-4.95z"
                    id="ObjMor026__Symbol_35_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M6.4 0q-.25.05-.6.05Q4.95.1 4.1.2 2.85.5 1.55.8.85.95 0 1.15v.8Q1.75 34.5 28.85 52.8l-.05-.05q2.6-2.2 4.5-4.95Q7.8 29.45 6.4 0m36.4 18.3q-.35-.55-.8-1.45-.3-.55-.65-1.2l-5.2-9.15-9.7 5.4q0 .85.15 1.6 0 .3.05.6.9 5.35 5 8.65.45.4 1.05.8l.4.2 9.7-5.45z"
                    id="ObjMor026__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor026__Symbol_134_0_Layer0_0_FILL"
                transform="translate(277.45 33.35)"
                id="ObjMor026__col1n"
            />
            <use
                xlinkHref="#ObjMor026__Symbol_35_0_Layer0_0_FILL"
                transform="translate(234.15 77)"
                id="ObjMor026__col2n"
            />
            <use
                xlinkHref="#ObjMor026__Symbol_17_0_Layer0_0_FILL"
                transform="translate(241.4 77.95)"
                id="ObjMor026__col2l"
            />
        </g></g>
    )
}

export default ObjMor026
