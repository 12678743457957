import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function AccMor037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.65 4.05q.6-1 0-2-29.55-4-60.5-.25-.2 0-.4.05H61.3q-.2-.05-.4-.05-31-3.75-60.5.25-.8 1 0 2 29.75 1.6 61.15.05.7-.05 1.4-.05h1.1q.75 0 1.45.05 31.35 1.55 61.15-.05z"
                    id="AccMor037__Symbol_135_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor037__Symbol_135_0_Layer0_0_FILL"
                transform="translate(129.05 320.5)"
                id="AccMor037__col1n"
            />
        </g></g>
    )
}

export default AccMor037
