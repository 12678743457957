import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function AccMas003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M56.95 79.85q.25.05.55.1 4.65.7 9.6.7h.8q14.6-3.9-2.2-2.8-4.2-.05-8.2-.7-.3-.05-.55-.05-4.3-.75-8.35-2.1-9.35-2.95-17.1-8.35 3.65 5 10.55 8.55 8.1 3.35 14.9 4.65m6.4-33.9q-4.6-.25-5.25-.25-24.3 0-41.55-15.75-3.55-3.25-6.35-6.8l-.8-1Q8.1 20.4 7 18.65 1.3 9.65.25 0q-.6 4.35.3 9Q1.8 16.05 7 23.1q1.2 1.65 2.6 3.3l.4.4q2.2 2.6 4.8 5 18.05 16.6 43.6 16.6h.05q8.55-.5 4.9-2.45m70.95 31.2q-4 .65-8.2.7-15.5 1-2.2 2.8h.8q4.95 0 9.6-.7.3-.05.55-.1 6.8-1.3 14.9-4.65 6.9-3.55 10.55-8.55-7.75 5.4-17.1 8.35-4.05 1.35-8.35 2.1-.25 0-.55.05M191.25 9q.9-4.65.3-9-1.05 9.65-6.75 18.65-1.1 1.75-2.4 3.5l-.8 1q-2.8 3.55-6.35 6.8Q158 45.7 133.7 45.7h-1.25q-18 1.25.9 2.7h.05q25.55 0 43.6-16.6 2.6-2.4 4.8-5l.4-.4q1.4-1.65 2.6-3.3 5.2-7.05 6.45-14.1z"
                    id="AccMas003__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M74.6 20.15q-2.25-5-7.7-7.5l-.7-.4-.4-.2q-.45-.1-.9-.25-1.3-.4-2.75-.95-5.55-2.25-14-7.35-.2-.15-.45-.3-1.3-.9-2.55-1.5-2.65-1.4-5-1.7h-4.2q-2.35.3-5 1.7-1.25.6-2.55 1.5-.25.15-.45.3-8.45 5.1-14 7.35-1.45.55-2.75.95-.45.15-.9.25l-.4.2-.7.4q-5.45 2.5-7.7 7.5-.55 1.25-.9 2.7-1.85 7.6 2.15 19.7.8 2.4 2.05 4.85 1.45 2.6 3.05 4.9 1.05 1.45 2.2 2.8 8.05 9.4 20.2 11.3 3.6.5 7.2.55h1.2q3.6-.05 7.2-.55Q58 64.5 66.05 55.1q1.15-1.35 2.2-2.8 1.6-2.3 3.05-4.9 1.25-2.45 2.05-4.85 4-12.1 2.15-19.7-.35-1.45-.9-2.7z"
                    id="AccMas003__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M61.55 11.65l.05.05q-.3-.15-.65-.3-.35-.2-.8-.35-.9-.25-1.9-.65-1.4-.6-3-1.4-4.2-1.95-10.2-5.55-1.3-.9-2.4-1.55Q41 .95 39.8.6q-.5-.15-.9-.2-1.85-.3-2.35-.35-.2-.05-.95-.05h-1.3q-.75 0-.95.05-.5.05-2.35.35-.4.05-.9.2-1.2.35-2.85 1.3-1.1.65-2.4 1.55-6 3.6-10.2 5.55-1.6.8-3 1.4-1 .4-1.9.65-.45.15-.8.35-.35.15-.65.3l.05-.05q-12.85 5.9-5.9 26.9.75 2.15 1.8 4.4 5.3 9.6 13.5 13.9 7.3 3.8 16.55 4.2h1.3q9.25-.4 16.55-4.2 8.2-4.3 13.5-13.9 1.05-2.25 1.8-4.4 6.95-21-5.9-26.9m-3.65-.25q1.05.4 1.95.7h-.05q.5.15.85.3.25.1.4.2 12.05 5.65 5.35 25.65-.65 2.05-1.65 4.2-5.1 9.3-13.05 13.5-7.25 3.65-16.1 4h-1.3q-8.85-.35-16.1-4-7.95-4.2-13.05-13.5-1-2.15-1.65-4.2-6.7-20 5.35-25.65.15-.1.4-.2.35-.15.85-.3h-.05q.9-.3 1.95-.7 2.15-.9 4.9-2.4l1.9-.95q3-1.6 6.65-3.75v-.05q1.4-.95 2.55-1.6 1.8-1.05 2.95-1.3.15-.05.25-.05 1.9-.25 2.3-.3h.05q.2-.05.8-.05h1.2q.6 0 .8.05h.05q.4.05 2.3.3.1 0 .25.05 1.15.25 2.95 1.3 1.15.65 2.55 1.6v.05q3.65 2.15 6.65 3.75L53 9q2.75 1.5 4.9 2.4m-2.5 31.35q-1.7 1.4-4.25 2.65-6.75 3.25-16.2 3.3-9.45-.05-16.2-3.3-2.55-1.25-4.25-2.65l-.05-.05q1.35 3.4 5.65 6.1 6.15 3.9 14.85 3.9t14.85-3.9q4.3-2.7 5.65-6.1l-.05.05m3.5-14.5q-.75.25-1.55.55Q54 30 50.1 30.75q-2.2.4-4.55.7-2.5.3-5.2.45h-10.8q-2.7-.15-5.2-.45-2.35-.3-4.55-.7-3.9-.75-7.25-1.95-.8-.3-1.55-.55-2.6-1-4.4-2.15 0 .05.05.1 1.9 3.35 7.75 6 4.3 2 9.5 2.95 2.45.45 5.05.7 1.05.1 2.1.15 1.9.1 3.9.1t3.9-.1q1.05-.05 2.1-.15 2.6-.25 5.05-.7 5.2-.95 9.5-2.95 5.85-2.65 7.75-6 .05-.05.05-.1-1.8 1.15-4.4 2.15m1.8 6.1q-2.1 1.4-5.35 2.65-6.5 2.5-15.05 3.1-2.55.2-5.25.2h-.2q-2.7 0-5.25-.2-8.55-.6-15.05-3.1-3.25-1.25-5.35-2.65l-.05-.05q1.7 3.4 7.1 6.1 7.75 3.9 18.7 3.9t18.7-3.9q5.4-2.7 7.1-6.1l-.05.05M57.5 19.7q-1 .45-2.15.9-1 .4-2.05.75-7.85 2.5-18.35 2.55-10.5-.05-18.35-2.55-1.05-.35-2.05-.75-1.15-.45-2.15-.9-1.9-.85-3.25-1.8 1.35 2.65 5 4.95l.5.3q.75.45 1.6.85 1.45.75 3.05 1.35 6.8 2.55 15.65 2.55 8.85 0 15.65-2.55 1.6-.6 3.05-1.35.85-.4 1.6-.85l.5-.3q3.65-2.3 5-4.95-1.35.95-3.25 1.8z"
                    id="AccMas003__Symbol_9_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M23.5 3.35V3.3Q20.1.95 18.15.45l-.4-.1q-1.9-.25-2.3-.3h-.05Q15.2 0 14.6 0h-1.4q-.6 0-.8.05h-.05q-.4.05-2.3.3l-.4.1Q7.7.95 4.3 3.3v.05Q2.05 4.7 0 5.8l2.2 2.15h.2l1.9-1.1V6.8q3.8-2.6 5.75-2.95 1.9-.25 2.3-.3h.05q.2-.05.8-.05h1.4q.6 0 .8.05h.05q.4.05 2.3.3 1.95.35 5.75 2.95v.05l1.9 1.1h.2l2.2-2.15q-2.05-1.1-4.3-2.45z"
                    id="AccMas003__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas003__Symbol_88_0_Layer0_0_FILL"
                transform="translate(96.6 158.05)"
                id="AccMas003__col1n"
            />
            <use
                xlinkHref="#AccMas003__Symbol_21_0_Layer0_0_FILL"
                transform="translate(154.45 183.6)"
                id="AccMas003__col2n"
            />
            <use
                xlinkHref="#AccMas003__Symbol_9_0_Layer0_0_FILL"
                transform="translate(157.55 186.85)"
                id="AccMas003__col2d"
            />
            <use
                xlinkHref="#AccMas003__Symbol_1_0_Layer0_0_FILL"
                transform="translate(178.6 191.4)"
                id="AccMas003__col2s"
            />
        </g></g>
    )
}

export default AccMas003
