import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col2l": "#999999",
    "col2n": "#666666"
}

function ObjToo057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.2 1.9q-.1.05-.2.15L.45 3.1Q15.9 41.55 13 65.85q-.8.3-1.4.95-.95.9-1.35 1.95-1.05 2.35.4 5.85l3.25 7.25 1.05-.45v-.05l.45-.2.35.85q0 .05.1.1l-.05.05 1.55 4 .05-.05v.4q.15 1 .5 2.1.3 1.2.55 2.5.5 2.65.6 5.5v.05q-.7.5-1.35 1-.15.05-.25.15-.55.4-1.05.85-4.1 3.45-3.95 8.65 0 .8.25 1.45.05.4.15.85 1.05-.95 3.75-.6-.95-1.65-.95-3.35 0-1.25 1.55-2.65.65-.5 1.15-.7.6-.25.95-.5t.45-.3l.3-.2q-.1.2-.1.4-.1.75-.3 1.45-.05.15-.05.3-.15.6-.1 1.1h.05q-.25 1.45-.7 4.45 0 .2-.05.35 3.25.75 5 2.3.35.15.7.35 2.45 3.3 2.85 4.05 2.4 4.55 2.4 8.1 0 1.1-.35 1.95-.1.25-.2.45-.25 1.15-.75 2.1-1.6 3.2-5.6 3.2-1.75 0-2.95-.2 1.15 4.5 2.25 6.7 2.3 4.65 5.6 6.1 1.3.55 2.75.6l12.5-6q5.45-2.45 6.3-6.2.8-4.15-1.45-8.15l-.5-.6q-1.55-1.2-2.75-2.8l-1.55-2.6-.35.35q-2.25 2.25-5.4 2.25-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4 1.65-1.65 3.8-2.05l-.1-.15q-4.2-7.25-1.15-15.2 1.55-4.1.35-7.85-1.2-3.75-2.35-6.3-.65-1.4-.85-1.75l3.65-1.8L28.2 58.4l.45.8L3.65 0q-.8.4-1.55.85-.9.45-1.8 1-.05.05-.1.05z"
                    id="ObjToo057__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.35 58.15Q10.7 54.4 10.25 53q-.2-.55-.35-1.1-1.1.35-2.45.35-1.75 0-2.95-.2 1.15 4.5 2.25 6.7 2.3 4.65 5.6 6.1 1.3.55 2.75.6l4.7-2.25q-.5.05-.85.05-3.35 0-4.65-1.3-.5-.55-1.95-3.8m-8.1-34.6q-.05.15-.05.3-.15.6-.1 1.1h.05q-.25 1.45-.7 4.45 0 .2-.05.35 2.95.7 4.65 2 .2-.95.45-1.8.15-.4.35-.8 2-4.9 1.4-10.3v-.05q-.45.05-.85.15-.85.45-1.95 1.05l-.2.1q-.2 0-1.15.55-.65.45-1.45 1.05-.1.2-.1.4-.1.75-.3 1.45M3.3.1L3.25 0 0 1.55l.35.85q0 .05.1.1l-.05.05 1.55 4L2 6.5v.4q.15 1 .5 2.1.3 1.2.55 2.5.5 2.65.6 5.5l.15-.05q1.4-.95 2.9-1.5.85-4.45-.55-8.35-.25-.65-.55-1.3L3.3.1z"
                    id="ObjToo057__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 4.2l16.3 34.6-9.85 4.25 40.7 89q.4-1.05 1.35-1.95.65-.7 1.55-1.05.1-.05 1.15-.4 1.05-.4 2.8-1 .15-.05.25-.1L62.2 124 36.9 65.35q1.05-.65 2.1-1.2.75-.45 1.55-.85L13.9 0 0 4.2z"
                    id="ObjToo057__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M9.2 38.5l-2.75 1.2 40.7 89q.4-1.05 1.35-1.95.65-.7 1.55-1.05L9.2 38.5m21.3 26.8l-2.95 1.15 27.4 57.45 2.9-1.25L30.5 65.3M2.8 0L0 .85 30.5 65.3l3.35-1.25-.1-.15L2.8 0z"
                    id="ObjToo057__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo057__Symbol_216_0_Layer0_0_FILL"
                transform="translate(94.65 177.95)"
                id="ObjToo057__col1n"
            />
            <use
                xlinkHref="#ObjToo057__Symbol_138_0_Layer0_0_FILL"
                transform="translate(110.05 257.55)"
                id="ObjToo057__col1d"
            />
            <use
                xlinkHref="#ObjToo057__Symbol_91_0_Layer0_0_FILL"
                transform="translate(57.75 114.65)"
                id="ObjToo057__col2n"
            />
            <use
                xlinkHref="#ObjToo057__Symbol_46_0_Layer0_0_FILL"
                transform="translate(57.75 118)"
                id="ObjToo057__col2l"
            />
        </g></g>
    )
}

export default ObjToo057
