import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccMas013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M177.7 48.4l13.95-15.35q.1-1.25.2-2.3.45-5.05 0-10.1-.05-1-.45-2.6-11.3 1.3-20.35-2.65-1.3-.6-2.75-1.3-1.75-.9-3.7-2-4.15-2.75-8.3-4.85-2.3-1.15-4.6-2.15-4.7-2-9.4-3.2-11.5-3-23.6-1.1-9 1.75-16.1 5.05-.2.05-.4.15-3.45 1.1-6.95 1.05-.35 0-.7-.05-2.85-.3-5.65-1.35-4.45-2.15-9.3-3.55-3.1-.9-6.25-1.35-11.8-1.95-23.6 1.15-4.7 1.2-9.4 3.2-2.3 1-4.6 2.15-4.15 2.1-8.3 4.85-1.95 1.1-3.7 2-1.45.7-2.75 1.3-9.05 3.95-20.45 2.7-.25 1.3-.2 1.45 0 .1-.1 1.35-.45 4.8 0 9.6.05 1.2.15 2.55L14.35 48.4q3.9 4.25 9.25 9.2 5.85 5.4 13.05 8.95 1.8.9 3.7 1.7 2 .8 4.1 1.5 2.8 1 5.5 1.7 3.15.75 6.2 1.15 2 .2 4 .3 8.65.2 16.4-3.15l.45-.2 1.3-.6q.75-.35 1.5-.75.15-.1.35-.2l.35-.2.2-.1 10.95-6.3q.35-.2.75-.35 1.1-.5 2.15-.7 1-.25 1.95-.25 1.65.15 3.35.9.35.15.75.35l10.95 6.3q.05.05.15.1l.35.2q.2.1.35.2.75.4 1.55.75.25.15.5.25 1.35.7 6.35 2.35 5 1.6 11.1 1.4h.2q1.9-.1 3.8-.3 3.05-.4 6.2-1.15 2.7-.7 5.5-1.7 2.1-.7 4.1-1.5 1.9-.8 3.7-1.7 7.2-3.55 13.05-8.95 5.35-4.95 9.25-9.2m-37.85-29q8.25 8.8 9 20.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-7.3-7.8-8.8-17.85l-.75-2.5q-.9 3.85-1.55 6.2-.3 1.1-.55 1.9-2.25 6.7-7.45 12.3Q79.3 64 70 65.55q-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-6.65-1.2-11.5-6.45-7.2-7.6-6.35-19.2.75-11.65 9.05-20.45Q60.5 10.6 71.4 9.8q10.75-.85 17.85 6.75 3.1 3.35 4.75 7.55.25.7.55 1.7.6 2 1.4 5.3l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75h.05q10.85.8 19.05 9.65z"
                    id="AccMas013__Symbol_97_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas013__Symbol_97_0_Layer0_0_FILL"
                transform="translate(96.45 126.85)"
                id="AccMas013__col1n"
            />
        </g></g>
    )
}

export default AccMas013
