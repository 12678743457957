import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000"
}

function AccHat089({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M216.1 15.35q-1.6-2.05-3.5-3.95Q201.2 0 185.1 0q-4.75 0-9.05 1-10.4 2.35-18.45 10.4-11.4 11.4-11.4 27.5 0 10.45 4.8 19.05-1.7-.75-3.4-1.4-16.45-6.45-35.6-6.45-21.15 0-39 7.9 4.8-8.6 4.8-19.1 0-16.1-11.4-27.5Q58.35 3.35 47.95 1q-4.3-1-9.05-1-16.1 0-27.5 11.4-1.9 1.9-3.5 3.95Q0 25.5 0 38.9q0 16.15 11.4 27.55 11.4 11.4 27.5 11.4 2.85 0 5.55-.35l-.2.2-.8.8q-12.4 12.4-19.4 27-2.05 4.25-3.6 8.65l-.7 2.1q-.4 2.45 2.75 2 .85-.2 1.7-.35 4.65-1.05 9.3-2 82.25-16.45 165.05 1.7l.7.1q.2.05.45.1.85.15 1.7.35 3.15.45 2.75-2-.25-.7-.45-1.4-.1-.35-.2-.75-.15-.5-.35-1-7.05-19-22.55-34.5l-1-1q2.7.35 5.5.35 16.1 0 27.5-11.4Q224 55.05 224 38.9q0-13.4-7.9-23.55m-7.55 43.95l.05.05q-.188.14-.3.3.11-.163.25-.35m-193.15.05l.05-.05q.136.181.3.35-.169-.164-.35-.3z"
                    id="AccHat089__Symbol_306_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M51.35 44.65q8.85-9.5 8.85-22.9Q60.2 9.1 52.3 0q.2.4.4.9 2.8 6.6 2.8 14.6 0 13.65-8.15 23.35-8.1 9.7-19.6 9.7-9.65 0-16.9-6.8-.8-.7-1.5-1.5-.65-.65-1.25-1.4-1.6-1.9-2.85-3.95Q.8 27.75.15 18.75 0 20.2 0 21.75q0 2 .2 3.9.65 6.3 3.4 11.7.85 1.6 1.85 3.1 1.35 1.95 3 3.8.15.2.35.4l.1.1q8.8 9.35 21.2 9.35 12.45 0 21.25-9.45m158.5-25.9q-.65 9-5.1 16.15-1.25 2.05-2.85 3.95-.6.75-1.25 1.4-.7.8-1.5 1.5-7.25 6.8-16.9 6.8-11.5 0-19.6-9.7-8.15-9.7-8.15-23.35 0-8 2.8-14.6.2-.5.4-.9-7.9 9.1-7.9 21.75 0 13.4 8.85 22.9 8.8 9.45 21.25 9.45 12.4 0 21.2-9.35l.1-.1h.1q.1-.2.25-.4 1.65-1.85 3-3.8 1-1.5 1.85-3.1 2.75-5.4 3.4-11.7.2-1.9.2-3.9 0-1.55-.15-3z"
                    id="AccHat089__Symbol_204_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat089__Symbol_306_0_Layer0_0_FILL"
                transform="translate(80.5 6.7)"
                id="AccHat089__col1n"
            />
            <use
                xlinkHref="#AccHat089__Symbol_204_0_Layer0_0_FILL"
                transform="translate(87.5 21.75)"
                id="AccHat089__col1d"
            />
        </g></g>
    )
}

export default AccHat089
