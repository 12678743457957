import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M55.5 5.65q-.75 3.45-1.6 6.15Q50.1 22 40 21.3q-2.55-.35-5.15-1.55v-.05h-.1l-.1-.1v.05q-1-.45-1.75-1.05-1.6-1.2-2.15-3.05-.25-.7-.3-1.5v-.4q0-.25.05-.4 0-.2.05-.45.25-1.1.8-1.9.2-.35.5-.6.45-.5 1.05-.85-1.95.35-3.85.35-1.9 0-3.85-.35.6.35 1.05.85.3.25.5.6.55.8.8 1.9.05.25.05.45.05.15.05.4v.4q-.05.8-.3 1.5-.55 1.85-2.15 3.05-.75.6-1.75 1.05v-.05l-.1.1h-.1v.05q-2.6 1.2-5.15 1.55Q8 22 4.2 11.8q-.85-2.7-1.6-6.15Q2 3.05 1.4 0l-.3 5.2q.05-1.5-.1.85Q.7 9.65.4 13q-.35 3.35-.4 5.4.2 2.15 1.35 3.4.5.5 1.15.85.2.05.35.1.45.2.85.35 9.3 3.7 19.75 4.35.45.05.9.05 2.35.15 4.7.05 2.35.1 4.7-.05.45 0 .9-.05Q45.1 26.8 54.4 23.1q.4-.15.85-.35.15-.05.35-.1.65-.35 1.15-.85 1.15-1.25 1.35-3.4-.05-2.05-.4-5.4-.3-3.35-.6-6.95-.15-2.35-.1-.85L56.7 0q-.6 3.05-1.2 5.65z"
                    id="BeaChi011__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi011__Symbol_50_0_Layer0_0_FILL"
                transform="translate(163.45 224.25)"
                id="BeaChi011__col1n"
            />
        </g></g>
    )
}

export default BeaChi011
