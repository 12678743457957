import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#BFBFBF",
    "col1s": "#747474",
    "col2n": "#EEEEEE"
}
function ShiSho081({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.2 8.1q-.1-.1-.15-.2-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4-.6.5q-1.05.8-2.2 1.6l-1 .75-.05-.05q-.35.3-.95.8l-.2.1q-.25.15-.45.3-.1.05-.2.15l-.9.6-.6.4q-.25 4.4-4.55 7.9-16.4 7.85-32.8 11.6-2.5.55-5 1.05-.7.1-2.7.25v4.05q-2.3 5.05-8.45 7.8-.05 0-.1.05l-.3.1-.4.15q-.05.05-.05.1h-.05q-3.3-2.05-4.55-5.8v-6.4q-1.15-.25-2.25-.45-1.6-.35-3.15-.7-17.7-4-33.6-11.75-.75-.7-1.4-1.35-3.3-3.55-3.4-7.05-.35-.25-.65-.45-.2-.15-.4-.25-.55-.4-1.1-.75l-.1-.05v-.05q-.5-.4-.95-.7l-.5-.35q-.25-.2-.5-.35-1.2-.9-2.3-1.75L17.6 0q-.1.05-.15.15-3.05 4.1-5.7 8.55l-.35.65q-.15.2-.25.4-.2.4-.45.75l-.4.8-.2.3q-2 3.65-3.8 7.5-.1.2-.2.45-.1.15-.15.4l-2.1 4.9-.2.4-.4 1.1q-.15.3-.25.6-.1.25-.15.45-.15.3-.25.6-.05.2-.1.35-.75 2-1.4 4.05l-.25.95h-.1q-.4 1.2-.75 2.4l20 7.3-1.25 6.4q-.5 2.35-.95 4.75-.1.35-.15.7L14.2 69.2q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H12.1q2.05.5 4.15.95 24.05 5.45 48 6.65 2.7.15 5.35.25 1.4 0 2.75.05 5.5.1 11 0 3.15-.05 6.25-.2l2.4-.1q23.55-1.3 46.95-6.65 2.1-.45 4.15-.95H143.25q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-.25-1.5.6 2.8-1.6-8.5.15-.05V43l.85-.3v-.05l19-6.95q-1.15-3.8-2.5-7.4-.05-.15-.1-.35-.1-.3-.25-.6 0-.1-.05-.15v-.1l-.1-.2q-.1-.3-.25-.6l-.4-1.1-.2-.4-.35-.85Q150.1 21.5 149 19.1q-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4z"
                    id="ShiSho081__Symbol_235_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.05 11.6q-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.25-.25.45-.55.2-.5.2-1.05v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65-.9 0-1.55.6l-.05.05q-.55.55-.6 1.3M3.85.7Q3.65.5 3.5.4 2.95 0 2.25 0 1.4 0 .8.55.8.6.75.6l-.1.1Q.1 1.25.05 2 0 2.15 0 2.3q0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6V2q-.1-.75-.65-1.3z"
                    id="ShiSho081__Symbol_233_0_Layer0_0_FILL"
                />
                <g
                    id="ShiSho081__Symbol_234_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M.1 29.5H0l19.05 7.1-.8 2.25.85.3L20.2 36 .35 28.55l-.25.95m135.5 9.35v-.05l-.8-2.2 19.05-7.1h-.1l-.25-.95L133.65 36l1.1 3.1v.05l.85-.3M131.25.35q-.35.3-.95.8-.1.45-.2.95-1.65 7.4-4.95 10.15-17.25 8.25-35.85 12.3-.2.05-.3.2-.1.1-.1.3l-.05 7.5q-4 5.3-8.05 7.05-.15.05-.2.2-.1.1-.1.25l.1 7.1h-6.1V36q0-.1-.05-.2v-.45q-.05 0-.1.05l-.3.1-.4.15q-.05.05-.05.1l-.05.05q-.05.1-.05.2v10.8q-8.25-4.9-11.85-12.65v-.05q.05-.1.05-.25v-9.5q0-.15-.1-.3t-.25-.15q-.4-.1-.75-.2-17-4-32.25-11.45Q26 10.1 24.85 8q-.8-1.45-1.9-7.25V.7Q22.45.3 22 0l-.05.05q-.15.15-.1.35 1.2 6.5 2.1 8.1 1.25 2.25 3.8 4.55l.1.05q15.35 7.5 32.5 11.55.15.05.35.1v9.1q0 .15.1.25-.05.05-.05.1-.1.15 0 .35 3.85 8.4 13 13.55.2.1.4.05.05-.05.1-.05.1.05.25.05h6.6q.2 0 .35-.15.15-.15.15-.35l-.1-7.25q4.15-1.95 8.25-7.4.1-.15.1-.3l.05-7.25q18.55-4.1 35.8-12.35.05 0 .1-.05 2.65-2.15 4.3-7.1.75-2.3 1.3-5.15.05-.2-.05-.35l-.1-.1zM2.25 23.2v-.1q-.1.25-.15.45l.1-.2q0-.1.05-.15z" />
                    <path d="M97.2 25.4l.15-.05-.2.05h.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSho081__Symbol_235_0_Layer0_0_FILL"
                transform="translate(114.85 228.6)"
                id="ShiSho081__col1n"
            />
            <use
                xlinkHref="#ShiSho081__Symbol_234_0_Layer0_0_FILL"
                transform="translate(115.6 232.45)"
                id="ShiSho081__col1s"
            />
            <use
                xlinkHref="#ShiSho081__Symbol_233_0_Layer0_0_FILL"
                transform="translate(178.65 257.55)"
                id="ShiSho081__col2n"
            />
        </g></g>
    )
}
export default ShiSho081
