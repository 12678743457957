import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#0000BB"
}

function AccMor067({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M124.6 2.5l-2-2.5-56.1 43.7v.05L62.3 47 2 0 0 2.5l60.4 47.2q.15.1.35.25v2.2h3.2v-2.2q.2-.1.4-.25l.2-.2 60.05-47z"
                    id="AccMor067__Symbol_164_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M15.65 12.9V.7Q8.1-.7 0 .7v12.2q8.1 1.25 15.65 0z"
                    id="AccMor067__Symbol_165_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M200.5 301.6h-15.65v8.85h15.65v-8.85m-15.65-12.2h15.65v-1.9h-15.65v1.9z"
                    id="AccMor067__Layer1_0_FILL"
                />
                <path
                    d="M194.15 307.15q-.15 0-.25.1h-.6q.05-.15 0-.25-.05-.2-.45-.2t-.7.4q-.35.45-.4.45.2-1.15-.25-1.5h-.35q-.8.5-1.45 1.25-.05-.05-.25-.55-.2-.4-.45-.4-.3 0-.7.6-.35.5-.35.7v.15q0 .15.2.15.3-.75.8-1.2.05.05.25.5.25.5.5.5.2 0 .7-.55.55-.65.95-.75.1.25-.05.85-.05.45.75.65.15-.1.35-.5.2-.3.5-.35-.05.2.35.45h.8l.1-.1q.05.05.05.15 0 .1.2.1t.2-.25q0-.4-.45-.4m1.85-2.55q0 .1.2.25h.45q.9-.2.9-.5l-.05-.15-.2-.05-.85.3v-.05q.3-1.2-.35-1.2-.15 0-.7.4-.55.35-.75.35 0-.65-.35-.65-.1 0-.4.4l-.35.4q.05-.25.05-.4-.05-.25-.3-.25-.15 0-.4.4-.25.45-.35.5.05-.8.05-1.2 0-.7-.45-.5-.4.15-1.5.9-1 .7-1.1.7l-.1-.05q0-1.2-.55-1.2-1.2 0-1.2 1.3v.15q0 .15.2.15.1-.25.25-.55.3-.6.75-.65.1.15.1.75.05.5.5.5.3 0 2.7-1.6v.45l-.1.9q0 .35.4.35.35 0 .65-.45-.1.35.3.35.1 0 .4-.35.3-.35.4-.4-.05.15 0 .35.1.2.3.2.1 0 1.55-.85v.75q-.1.1-.1.25z"
                    id="AccMor067__Layer1_1_FILL"
                />
                <g id="AccMor067__Layer1_1_MEMBER_0_FILL">
                    <path
                        fill="#BFBFBF"
                        d="M194.9 288.5v-8.05h-4.65v8.05h4.65m-5.95 7.45q0-1.55 1.15-2.7 1.1-1.15 2.7-1.15t2.7 1.15q1.15 1.15 1.15 2.7 0 1.6-1.15 2.75-.166.166-.35.3.178.192.35.45 0 .15.05.25l.1.2q.1.2.2.5v.15q0 .1.05.3h2.05V290.2H187.3v10.65h2.25q.05-.2.1-.3.05-.35.2-.65 0-.1.05-.2t.15-.25q.15-.3.35-.5-.16-.11-.3-.25-1.15-1.15-1.15-2.75z"
                    />
                    <path
                        fill="#666"
                        d="M190.1 293.25q-1.15 1.15-1.15 2.7 0 1.6 1.15 2.75.14.14.3.25-.2.2-.35.5-.1.15-.15.25-.05.1-.05.2-.15.3-.2.65-.05.1-.1.3h6.35q-.05-.2-.05-.3v-.15q-.1-.3-.2-.5l-.1-.2q-.05-.1-.05-.25-.172-.258-.35-.45.184-.134.35-.3 1.15-1.15 1.15-2.75 0-1.55-1.15-2.7-1.1-1.15-2.7-1.15t-2.7 1.15z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccMor067__Symbol_164_0_Layer0_0_FILL"
                transform="translate(130.25 228.3)"
                id="AccMor067__col1n"
            />
            <use
                xlinkHref="#AccMor067__Symbol_165_0_Layer0_0_FILL"
                transform="translate(184.85 288.7)"
                id="AccMor067__col2n"
            />
            <use xlinkHref="#AccMor067__Layer1_0_FILL" />
            <use xlinkHref="#AccMor067__Layer1_1_FILL" />
            <use xlinkHref="#AccMor067__Layer1_1_MEMBER_0_FILL" />
        </g></g>
    )
}

export default AccMor067
