import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function AccJew035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.05 2.55l-.7-.55Q18.95.6 15.6.15h-.15Q6.7-.95.1 5.65l-.1.1 1.65 7.4q1.5-1.45 3.1-2.55h.05q.2-.2.45-.35 3.6-2.4 7.55-2.85 4-.55 8.45 1.15l.4.2q1.45.8 2.75 1.95.05.05.1.15-.55-4.55-1.45-8.3z"
                    id="AccJew035__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.25 7.5q1.3 1.3 3.15 1.3 1.8 0 3.05-1.3Q8.8 6.25 8.8 4.4q0-1.8-1.35-3.1Q6.2 0 4.4 0 2.55 0 1.25 1.3 0 2.6 0 4.4q0 1.85 1.25 3.1z"
                    id="AccJew035__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M8.8 4.4q0-1.8-1.35-3.1Q6.2 0 4.4 0 2.55 0 1.25 1.3 0 2.6 0 4.4q0 1.85 1.25 3.1 1.3 1.3 3.15 1.3 1.8 0 3.05-1.3Q8.8 6.25 8.8 4.4M6.9 1.85Q8 2.9 8 4.4q0 1.5-1.1 2.5Q5.9 8 4.4 8T1.85 6.9Q.8 5.9.8 4.4t1.05-2.55Q2.9.8 4.4.8q1.5 0 2.5 1.05M5.45 1.3H5.4L3.6 4.25q-.05.15-.05.3t.15.25q.1.1.25.15l2.55.65q.15.05.35-.05.2-.15.25-.3.05-.2-.05-.4-.15-.2-.3-.2l-2-.5.8-2.8q-.05-.05-.1-.05z"
                    id="AccJew035__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew035__Symbol_103_0_Layer0_0_FILL"
                transform="translate(250.4 273.55)"
                id="AccJew035__col1n"
            />
            <use
                xlinkHref="#AccJew035__Symbol_43_0_Layer0_0_FILL"
                transform="translate(264.1 272.95)"
                id="AccJew035__col2n"
            />
            <use
                xlinkHref="#AccJew035__Symbol_4_0_Layer0_0_FILL"
                transform="translate(264.1 272.95)"
                id="AccJew035__col2s"
            />
        </g></g>
    )
}

export default AccJew035
