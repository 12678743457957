import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00006D",
    "col2n": "#FFFFFF"
}

function LegPan011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7.05l.05-.05q-4 .8-8 1.45-.7.15-1.45.25-4.8.8-9.55 1.45l-.7.1q-44.1 6-87.2 0-.15-.05-.25-.05-2.7-.4-5.35-.8l-3.8-.6q-.55-.15-1.1-.2l-.4-.1Q3.95.8 0 0q0 .05.05.1v19.5q3.95-.7 7.9-1.1.2-.05.4-.05.55-.1 1.1-.15 1.9-.2 3.8-.35 2.7-.2 5.35-.35h.25q22.75-1.05 44 4.75 20.9-5.7 43.2-4.8.35 0 .7.05 4.75.2 9.55.7.7.1 1.45.15 3.95.45 7.95 1.15V.05z"
                    id="LegPan011__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.9 1.8q-.15-.05-.25-.05-2.7-.4-5.35-.8L1.5.35Q.95.2.4.15L0 .05v17Q.2 17 .4 17q.55-.1 1.1-.15 1.9-.2 3.8-.35 2.7-.2 5.35-.35h.25V1.8m87.9 14.35q4.75.2 9.55.7.7.1 1.45.15V0q-.7.15-1.45.25-4.8.8-9.55 1.45v14.45z"
                    id="LegPan011__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan011__Symbol_16_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan011__col1n"
            />
            <use
                xlinkHref="#LegPan011__Symbol_17_0_Layer0_0_FILL"
                transform="translate(137.6 311.7)"
                id="LegPan011__col2n"
            />
        </g></g>
    )
}

export default LegPan011
