import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function AccHat057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M220.2 37.7q0-6.6-4.75-13.25-5.25-7.4-15.05-12.85-5.6-3.1-10.85-5.2-32.8-12.75-84.2 0-3.35.8-7 1.75-5.2 1.4-9.7 2.7-14.8 4.35-21.75 8.05-13.2 7.05-23.8 15.85-9.65 7.95-15.4 15.75-3.2 4.35-7.95 13.45-3.5 6.65-7.8 15.9-6.3 13.4-9.25 22.45-1.75 5.2-2.35 8.95-.15.95-.25 1.8-.05.55-.05 1.05-.05.45-.05.9 0 .35.5 5.7 1.45 5.2 6.4 5.2 1.1 0 1.95-.45 2.5-.5 10.9-5.05 2.25-1.25 5.05-2.75.3-.2.65-.4l1.95-1.05q42.55-22.6 88.8-18.3 31.2 2.4 59.45 14.3 4 1.95 8.85 4.35 5.25 2.6 8.75 4.2 5.25 2.35 6.45 2.35 2.45 0 4.25-1.05 2-3.05 2.85-6.1 2.1-7.55-2.85-15.05-1.15-1.95-2.35-3.6-.15-.2-7-5.85-1.95-1.6-3.4-3.25-.05-.1-.3-.5l-.2-.1q-.15-.2-.3-.35l-.8-1.6-.1-.1q-.15-.35-.3-.6l.05-.05q.1-.1-.2-.35l-.1-.1-.1-.15q-.05-.2-.3-.8-.35-.9-1.15-2.65-5.6-13.9-3.95-20.95.9-3.95 1.4-5.35.2-.55.35-.55.05-.5.05-.55.1-.05.25-.1.1-.05.3-.1.65.05 1.35.1h.1q7.9.55 14.65.55 12.05 0 16.2-6.6 2.1-3.3 2.1-9.6z"
                    id="AccHat057__Symbol_275_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M83.6 1.8q1.9-.75 4.95-1.8-14.8 4.35-21.75 8.05Q53.6 15.1 43 23.9q-9.65 7.95-15.4 15.75Q24.4 44 19.65 53.1q-3.5 6.65-7.8 15.9Q7.3 78.65 4.5 86.05l-.95 2.6q4.5-6.9 7.5-10.8 1.75-2.25 5.3-4.85 3.45-2.55 5.5-5.35.7-.95 3.65-7.7 3.2-7.15 5.15-10.3 16.9-27.3 33.65-38.25 7.75-5.05 19.3-9.6m123.1 103.3l-.25.25q-1.4 1.3-4.9 1.3-5.85 0-19.5-6.45-17.05-8.15-19-8.75-7.15-2.3-13.15-3.95l-8.35-2.25q-1.3-.3-2.5-.55-1.3-.3-2.55-.55l-17.8-2.8-10.1-.7q-3.05-.1-6.1-.1-.95.15-6.75.1-12.9.5-25.95 3-4.2 1.1-9.3 2.95-5.85 2.05-11.05 4.45-14.6 7.15-21.55 10.4-5.4 2.55-9.35 4-10.6 5.15-18.45 0-.05 0-.1-.05v.05q.1 1.4.4 4.4 1.45 5.2 6.4 5.2 1.1 0 1.95-.45 2.5-.5 10.9-5.05 2.25-1.25 5.05-2.75.3-.2.65-.4l1.95-1.05q42.55-22.6 88.8-18.3 31.2 2.4 59.45 14.3 4 1.95 8.85 4.35 5.25 2.6 8.75 4.2 5.25 2.35 6.45 2.35 2.45 0 4.25-1.05 2-3.05 2.85-6.1m-23.05-57.85q.4-2.3 1.65-4.75-7.75.15-21.05-3.6-13.35-3.75-16.7-4.35-2.4-.45-6.55-3.95-12.85-4.85-19.15-10.9h-.35q-33.7 1.7-47.95 21.65-.5.85-.9 1.75-.8 1.8-1.4 3.6-.8 2.35-1.2 4.75-.2 8.35 15.9 9.3 2.85-.15 5.3-.2 2.4 0 6.45.05 9.9.1 29.55.25 23.5 2.75 34.8 6.3 11.25 3.5 28.9 9.75-5.5-8-7.55-25.75-.2-1.6.25-3.9z"
                    id="AccHat057__Symbol_183_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M135.05 57.5l.3.1q-.2-.1-.35-.15-11.55-4.4-16.7-6.55Q67.75 29.85 0 44.75q13.45-1.8 26.35-2.5 56.2-3.3 108.7 15.25m-5.45-34.15q-.05 0-.05-.05Q96.2 19.4 65.75 0 89.8 23.85 129.6 23.35z"
                    id="AccHat057__Symbol_132_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat057__Symbol_275_0_Layer0_0_FILL"
                transform="translate(91.05 25.1)"
                id="AccHat057__col1n"
            />
            <use
                xlinkHref="#AccHat057__Symbol_183_0_Layer0_0_FILL"
                transform="translate(91.15 35.95)"
                id="AccHat057__col1d"
            />
            <use
                xlinkHref="#AccHat057__Symbol_132_0_Layer0_0_FILL"
                transform="translate(146.75 55.25)"
                id="AccHat057__col1s"
            />
        </g></g>
    )
}

export default AccHat057
