import { AssetSvgProps } from "../../../shared/assets"

function ObjToy078({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#333"
                    d="M207.95 56.45q-15.693-5.792-32 0L171 109.1l-49.05 11.4-3.9 3.2q-7.21 3.551-9.55 7.8-2.288 4.295-4.2 9.05-.5 1.2-.95 2.5-.8 6.25-1.15 12.6-.35 5.1-.4 10.3-.05 5.6.15 11.3.15 3.55.45 7.1.3 4.65.85 9.4.65 5.35 5.8 11.45h-.1q6.55 6.65 19.35 8.7 11.35 1.55 22.55 2.55 2.3.2 4.65.4 19.05 1.9 38.2 1.85h.15q17.9-.05 35.8-1.85 2.3-.2 4.55-.4 4.15-.35 8.35-.8 14.05-1.15 27.05-6.6 9.95-4.55 12.5-17.85.35-3.45.6-6.85.25-3.55.45-7.1.2-5.6.15-11.1-.05-5.3-.4-10.5-.35-6.35-1.2-12.6-.404-1.301-.9-4.25-.44-2.942-1.7-5.1-1.214-2.168-1.4-2.1-3.72-4.762-9.9-9.75-10.96-6.453-54.15-12.65l-5.7-52.75m-25.9 34.9h21.45v12.25h-21.45V91.35z"
                    id="ObjToy078__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    fill="#191919"
                    d="M173.7 81.65l-.3 3.3H211l-.35-3.3H173.7m20.1-25.75q1.264.103 2.75.2 5.75.45 11.6 2l-.4-3.8q-3.648-1.466-8.55-2.4-7.492-2.005-15.2 0-1.748.322-3 .7-2.45.7-4.85 1.7l-.35 3.8q5.75-1.65 11.65-2.05 1.581-.05 2.8-.2v.05h.4q-.006-.038 2.3-.05.265.027.5 0v.05h.35z"
                    id="ObjToy078__Layer5_0_MEMBER_1_FILL"
                />
                <path
                    fill="#191919"
                    d="M214.6 117.25h.5q6.35.35 12.75.9 4.4.4 8.8.85 2.4.2 4.75.45 1.35.1 2.75.25 28.75 1.2 36.65 20.85.5 1.2.9 2.5.85 6.25 1.2 12.6.35 5.2.4 10.5.05-2 0-4l-.05-5.95v-.55q-.25-8.25-1.05-16.3-1.3-9.2-7.7-16.7-2.4-2.55-5.65-4.5-3.45-2.05-7.85-3.5-6.65-2.15-15.45-2.9-16.05-1.85-31.9-2.55-21.5-1.05-42.65-.1-16.3.75-32.4 2.65-7.65.8-13.8 2.9-4.25 1.45-7.75 3.5-4.85 2.85-8.4 6.85-5 6.6-5.75 14.1-.9 8.2-1.05 16.55v.5l-.05 6.3q-.05 1.75 0 3.5.05-5.2.4-10.3.35-6.35 1.15-12.6.45-1.3.95-2.5 7.9-19.65 36.6-20.85 1.4-.15 2.8-.25 2.35-.25 4.6-.45 4.5-.45 9.1-.85 6-.5 12.05-.85h.5q5.55-.3 11.05-.45 5.5-.15 11-.15h.65q.2-.05.45 0h.05q5.65 0 11.25.15 5.05.1 10.2.4z"
                    id="ObjToy078__Layer5_0_MEMBER_3_FILL"
                />
                <path
                    fill="#3F3F3F"
                    d="M231.95 129.75q-.2 0-.4-.05-.25 0-.55-.05-1.85-.2-3.7-.35-11.4-1.2-22.9-1.6-4.05-.15-8.05-.2h-5.75q-16.35.05-32.9 1.8-1.85.15-3.7.35-.4.05-.85.1-9.45 1.3-18.5 3.95-8.25 4.15-11.65 12.6-.4.95-.7 1.95-.5 3.7-.8 7.4-.3 3.7-.4 7.45-.05.4-.05.8.05 0 .05-.05l.15 13.15q.05 1.9.2 3.8.35 4.05.85 8.15.05 2.55 3.85 7.75 2.85 3.95 10.1 6.15 7.4 2 19.2 2.9v-.05l.9.1q1.65.2 3.3.3 1.85.2 3.65.3 14.7 1.5 29.35 1.5h.5q.25-.05.55 0 .1-.05.15 0 13.95-.1 27.95-1.5 1.75-.1 3.55-.3 2.2-.15 4.3-.4 12.4-1.15 18.95-3.25 8.4-3.1 11.15-7.05 3.45-5 3.05-6.45.5-4.1.8-8.15.2-1.9.25-3.8l.15-13.1q-.1-4.15-.45-8.25-.25-3.7-.75-7.4-.3-1-.7-1.95-3.45-8.45-11.7-12.55-5.85-2.45-18.45-3.9v-.1z"
                    id="ObjToy078__Layer5_0_MEMBER_4_FILL"
                />
                <g id="ObjToy078__Layer5_0_MEMBER_2_FILL">
                    <path d="M193.7 67.8h-2.75q.25 6.95.85 13.85l.3 3.3q.3 3.2.7 6.4.7 6.15 1.65 12.25h2.85q-.05-.2-.1-.35-.9-5.95-1.6-11.9-.4-3.2-.7-6.4-.2-1.65-.3-3.3-.6-6.9-.9-13.85m3.15 0h-2.75q.35 7.05 1.25 13.85.2 1.7.45 3.3.5 3.25 1.1 6.4 1.15 6.2 2.75 12.25h2.9q-.1-.25-.15-.5-1.55-5.8-2.7-11.75-.6-3.15-1.05-6.4-.25-1.6-.5-3.3-.9-6.8-1.3-13.85m-6.7 0h-2.75q-.15 6.9-.55 13.85l-.2 3.3q-.2 3.2-.45 6.4-.45 5.95-1.1 11.95v.3h2.75q.65-6.15 1.15-12.25.25-3.2.45-6.4l.2-3.3q.35-6.95.5-13.85m0-16.7v-.05q0-.55-.4-.95-.45-.4-1-.4-.6 0-.95.45-.4.4-.4 1v.15q.05 2.35.05 4.75v4.8h2.8V55.9l-.1-4.8m6.5.25v-.15q0-.55-.35-.95-.4-.45-1-.45-.55-.05-1 .35-.4.4-.4.95v.05q-.1 2.4-.1 4.75 0 2.5.05 4.95h2.75q-.05-2.4-.05-4.75l.1-4.75m-3.6-2.05q-.4-.4-1-.4-.55 0-.95.4-.4.4-.4 1v.75q-.05 2.4-.05 4.85 0 2.45.05 4.95h2.8q-.05-2.45-.05-4.95-.05-2.4 0-4.85v-.4-.35q0-.6-.4-1z" />
                    <path
                        fill="#191919"
                        d="M196.6 60.85h-10.9v6.95h12.6v-6.95h-1.7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy078__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy078__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy078__Layer5_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy078__Layer5_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjToy078__Layer5_0_MEMBER_4_FILL" />
        </g></g>
    )
}

export default ObjToy078
