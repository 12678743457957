import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFF9C"
}

function ShiDre035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.6}
                    d="M27.85 38.65q-.65.3-1.35.75-1.7 1.05-3.65 2.8l-.4.45L.7 57.95l.05.1q1.1 5.3 2.15 5.95L.65 66.4l-.5.85q0 .15.05.3v.7q0 1.1.35 1.8.45.85 1.65 1.9 1.5 1.2 2.35 1.7 1.6.9 3.4 1.15h1.8l.6.05q3.15 0 5.85-2.95 1.2-1.5 1.8-1.95 1.3-1 3.4-1.35h2q2.25.9 2.8.9 1.6 0 1.6-2.4 0-.95-.95-3.7-1.15-3.1-1.15-3.15-1.25-4.8-1.25-7.15 0-1.85.35-3.15.2-.7 1.35-3.5.4-1.05 1.1-3.65.7-2.65.7-3.05v-.3l.6-1.1-.2.1-.5.2m73.95-25.8q2.25-3.35 8.9-11.5V.5Q92.1-.55 73.2.7q-.5 0-1 .05Q83.95 16.1 91.65 23.3q7.85-7.15 10.15-10.45m86.55 56.95v-.1l-3.1-3.35q.3-1.1.7-2.6 1.4-1.9.1-4.4L164.3 43.8q-1.2-1.35-3.8-2.95-2.65-1.65-3.35-2.05-.7-.4-.75-.4l-.55-.55q1 1.7 2 3.35.35.9 1.1 2.6l1.05 3.65q.45 4.65.45 7.45 0 4.2-.75 7.4-.75 3.25-.75 4.4 0 1.6 1.1 1.6.45 0 .75-.2.4-.25.85-.35.3-.05.65-.3.45-.15 1.35-.2h2.05q1.65.55 2.6 2 1.2 1.8 2.05 2.4.1.05.15.1 1 .95 2.15 1.65.35.25.7.55 1.75 1.1 4.1 1.4 1.35.45 2.05.45 3.55 0 6.4-2.25 2.45-1.95 2.45-3.5v-.25z"
                    id="ShiDre035__Symbol_232_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.4}
                    d="M263.2 316.3q-.3 1.2-.55 2.7-.35 1.85-.75 3.8-.55 2.8-1.25 5.75l-.1.4q.7-.25 1.45-.5 3.05-1.6 5.5.05 1.85 1.25 1.85 2.25v.05q.05-.05.15-.05.65.95 1.35 1.75l-6.7-20.05q-.4.95-.95 3.85m25.8 14.5v-.05l-24.05-25.9q-1.2-1.35-3.8-2.95-2.65-1.65-3.35-2.05-.7-.4-.75-.4l-.55-.55q9.598 16.538 20.1 28.25 2.798 5.791 5.75 8.35h.05q4.85-1.2 6.6-4.7m-160-31.3q-.245.082-.5.2-.659.277-1.35.75-1.7 1.05-3.65 2.8l-22.2 24.2-.5.85q.05.3.15.6.75 3 4.8 5.35 3.284-4.032 6.35-11 9.572-10.422 17.1-23.85l-.2.1m-4.4 18.1q-.55-2.3-.85-3.65-.3-1.25-.55-2.4l-4.6 18.9q.1-.05.25-.1.95-.55 1.45-.8.25-.1.55-.2.15-.1.35-.15 1.25-.45 2.4-.45.45 0 .95.1h.05q.95-.2 2.45.8.35.3.7.55-.4-.7-.45-.95-.1-.35-.15-.65-.55-2.5-1-4.2-.15-.65-.35-1.45-.5-2.15-1.2-5.35z"
                    id="ShiDre035__Symbol_232_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M286.85 320.55l-.15-.15q-.1.4.15 2.35v.15q-.55 2.4-2.25 2.8-.15 0-.25.05l1.8 1.9q0-.05.05-.1l.05-.05q.3-.35.55-.8.154-.27.25-.55l.1-.15q1.438-2.835.7-3.85-.9-1.4-1-1.6m-185.7-1q-.25.4 0 2.1.25 1.6.6 2.25.55.95 1.7 1.2l1.5-1.6q-2.05.15-3.55-4.4l-.25.45m38.7-51.9q-1.3.7-2.5 1.55-.15.1-.45.25-.3.15-.8.6l-.6.4q-.266.221-.55.4L133.6 278q-.568 2.765-1.15 5.5l-3.35 14.05q-.135.567-.55 1.85-.019.175-.05.3.255-.118.5-.2l.2-.1q-7.528 13.428-17.1 23.85-3.066 6.968-6.35 11-4.05-2.35-4.8-5.35-.1-.3-.15-.6l.3-.5q-.45.05-.45 1.4 0 .8.3 1.65.3 1.2 1.25 2.35.35.4.75.75 2.9 3.05 8 3.05 1.25 0 2.65-.5.8-.2 1.65-.65 1.15-.25 2.25-1.15.65-.55 1.35-1.35 2.25-2.65 4.3-2.65.6 0 1.3.15.8.6 2.55 1.35 3.8 1.65 7.85 1.2h1.45q26.75 3.25 53.8 3.45h3.5q30.85-.05 62.7-4.2h.15q.55 0 1.3-.15 1.15-.25 2.65-.85l4.1-1.65h.9q.55.3 1.3 1t2.8 2.6q1.1.9 2.25 1.6 3.6 2.15 7.8 2.15 4.85 0 7.65-2.2 2.05-1.7 2.05-3.3 0-.7-.25-1.05-1.75 3.5-6.6 4.7h-.05q-2.952-2.559-5.75-8.35-10.502-11.712-20.1-28.25-.15-.15-.3-.25-.028-.303-.4-1.4l.15.8-3.25-13.1q-.61-3.092-1.25-6.2l-1.85-9.4q-4.976-3.081-10.15-4.95-10.904-1.62-23.75-2.85-.042-.225-4.35 0l.4-.45-7.8 8.25q-.261.276-.55.5-5.43 5.37-9.75 11.85l-1.35 1.4q-7.8-11.85-19.45-21.3l-.05-.05-3.15.25q-11.45.9-23 2.45-3.55 1.25-6.8 3.2m-16.1 46.3q.3 1.35.85 3.65.7 3.2 1.2 5.35.2.8.35 1.45.45 1.7 1 4.2.05.3.15.65.05.25.45.95-.35-.25-.7-.55-1.5-1-2.45-.8h-.05q-.5-.1-.95-.1-1.15 0-2.4.45-.2.05-.35.15-.3.1-.55.2-.5.25-1.45.8-.15.05-.25.1-.25.15-.55.35H118l.05.05-.25.25q-.45.5-.9.95-.7.6-1.4 1.1-1.55 1.1-3.15 1.6-.4.1-.75.25-1.8.35-3.65.15.196-.397.35-.85.213-.408 3.1-7.15 2.933-6.69 11.8-15.6.25 1.15.55 2.4m138.9 5.05q.25-1.5.55-2.7.55-2.9.95-3.85 11.484 13.492 13.55 18.3 2.065 4.805 2.05 5.2-5.5.1-8.9-3.45-.7-.8-1.35-1.75-.1 0-.15.05v-.05q0-1-1.85-2.25-2.45-1.65-5.5-.05-.75.25-1.45.5l.1-.4q.7-2.95 1.25-5.75.4-1.95.75-3.8z"
                    id="ShiDre035__Symbol_232_0_Layer0_2_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M20.6 68.35l2.85-7.2L6.35 85 0 96q1.5.7 2.2.9.2-.4.45-.8.15-.4.35-.75 6.25-11.9 14.45-22.05.25 1.15.55 2.4.3 1.35.85 3.65.7 3.2 1.2 5.35.2.8.35 1.45.45 1.7 1 4.2.05.3.15.65.05.25.45.95.4.25.8.35.35.1 1.15.25l-4.2-22.15q.05-.15.15-.3l.7-1.75-.2.4.15-.4h.05M131.75.4q-3.5 1.9-7.15 3.45l-26.4 27.3-.5.5q-5.5 5.3-9.8 11.8l-1.35 1.4Q78.75 33 67.1 23.55l-10.9-11.9-8-8.1Q44.9 2.1 41.75.4q-.2-.1-.35-.15-.25-.15-.45-.25L54.6 13.9l2.65 2.75q2.5 2.6 4.95 5.25l1.7 1.85q12.85 10.35 21 23.9 2.15 7.3 1.95 12.15-.1 1.75-.5 3.2-1.5 5.35-1.55 5.5-.05.05-.05.2l-.4 3.25q-1 4.35-1.25 8.55-.2 3.3-.05 6.55.1 4.65.85 9.1l.3-.1h3q-1-4.4-1.3-9-.2-3.2-.1-6.55.1-3.5.9-7.15.15-.7.3-1.35.4-1.8.75-3.6v-.05q.2-.95 1-3.05.65-1.85.8-5.5.05-.65.05-1.35.05-4.75-1.75-10.95 6-9.2 14-16.4L131.75.4m39.1 92.1q2.95 4.8 3.15 5.2.75 0 2.6-.45l-5-8.35-19.7-26.65q1.1 1.55-1.45-1.85 4.35 9.05 5.55 11.15l-3.5 20 .2-.1q1-.4 2.1-.75l.1-.4q.7-2.95 1.25-5.75.4-1.95.75-3.8.25-1.5.55-2.7.55-2.9.95-3.85 9.5 13.5 12.45 18.3z"
                    id="ShiDre035__Symbol_158_0_Layer0_0_FILL"
                />
            </defs>
            <g id="ShiDre035__col1n">
                <use
                    xlinkHref="#ShiDre035__Symbol_232_0_Layer0_0_FILL"
                    transform="translate(100.65 261.05)"
                />
                <use xlinkHref="#ShiDre035__Symbol_232_0_Layer0_1_FILL" />
                <use xlinkHref="#ShiDre035__Symbol_232_0_Layer0_2_FILL" />
            </g>
            <use
                xlinkHref="#ShiDre035__Symbol_158_0_Layer0_0_FILL"
                transform="translate(105.75 238.25)"
                id="ShiDre035__col2n"
            />
        </g></g>
    )
}

export default ShiDre035
