import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1l": "#E7D4B6",
    "col1n": "#E4B670",
    "col1s": "#957032",
    "col2d": "#4D2E1B",
    "col2n": "#794D32"
}

function ObjMus026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M96 10.05q-1.1-.2-2.35-.2-2.25 0-3.85.65-1.5.6-1.6 1.5V9q-.45-.9-1.6-1.35-.75-.3-1.6-.45-1.1-.2-2.4-.2-2.25 0-3.85.65-1.45.6-1.55 1.45V6.05q-.45-.95-1.6-1.4-.75-.25-1.6-.45-1.1-.2-2.4-.2-2.25 0-3.85.65-1.45.65-1.55 1.5v-2.3q-.5-.95-1.65-1.35-.7-.3-1.55-.45-1.1-.25-2.4-.25-2.25 0-3.85.7-1.5.6-1.6 1.45V2q-.1-.15-.15-.3-.5-.7-1.45-1.05-.75-.3-1.6-.45-1-.2-2.2-.2h-.15q-2.25 0-3.85.65-1.15.45-1.45 1.05-.15.2-.15.4v.35h-.1q-.3-.45-.8-.75-.3-.2-.7-.35-.75-.3-1.6-.45Q39.9.7 38.7.7h-.15q-2.25 0-3.85.65-.4.15-.65.35-.55.3-.75.65-.05.05-.05.1h-.1V4.9q-.5-.9-1.6-1.3-.75-.3-1.6-.45-1.1-.25-2.4-.25-2.15 0-3.75.65-.05 0-.1.05-1.35.55-1.55 1.3v3.5q-.5-.95-1.65-1.35-.7-.3-1.55-.45-1.1-.25-2.4-.25-.4 0-.8.05-1 .05-1.9.25-.6.15-1.15.4-1.5.6-1.6 1.45v2.8q-.45-.9-1.6-1.35-.75-.25-1.6-.45-.6-.1-1.25-.15-.55-.05-1.1-.05-2.25 0-3.85.65-1.5.65-1.6 1.5L0 61.85q.25.05.55.1 2.15.45 3.8 2.1Q6.6 66.3 6.6 69.5t-2.25 5.45q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.8.3-1.65.45l.2 8.05h-.2L.2 88.6H.1v11.35q0 1.05 1.6 1.75 1.65.75 3.9.75 2.3 0 3.9-.75 1.65-.7 1.65-1.75 0-.1-.05-.15v-8.5q.15.95 1.6 1.6 1.65.75 3.95.75 2.25 0 3.85-.75 1.65-.7 1.65-1.75v-5.2-3.25q.1.95 1.6 1.6 1.6.75 3.9.75 2.25 0 3.9-.75.8-.35 1.2-.8l.4-.95v-.05V77.35v-3.1q.05.25.15.45.35.65 1.45 1.15 1.6.75 3.9.75t3.9-.75q.8-.35 1.2-.8l.4-.95v-.05-4.95-3.15q.15.95 1.6 1.6 1.65.75 3.9.75 2.3 0 3.9-.75 1.65-.7 1.65-1.75 0-.05-.05-.15V61.5q.05-.1.05-.15 0-.1-.05-.15v-2.95q.15.9 1.6 1.6 1.65.7 3.95.7 2.25 0 3.85-.7 1.65-.75 1.65-1.8V53.75v-3.1q.1.95 1.6 1.65 1.6.7 3.9.7 2.25 0 3.9-.7.8-.4 1.2-.8l.4-1v-.05-4.05-3.1q.1.9 1.6 1.6.8.35 1.85.55l2.05.2h.05l2.1-.2q1-.2 1.8-.55 1.65-.75 1.65-1.75V35.9q.1.95 1.6 1.6 1.6.75 3.9.75 2.25 0 3.9-.75 1.6-.7 1.6-1.75V30.2 27.5h-.1V11.85q-.45-.9-1.6-1.35-.75-.3-1.6-.45z"
                    id="ObjMus026__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.6 9.75Q.85 9.5 0 9.3v88.15q.9.2 1.6.55 1.5.65 1.6 1.6v-8.65V11.1q-.45-.9-1.6-1.35M14.25 8.2q-.5-.95-1.65-1.35-.7-.3-1.55-.45v82.3q.85.15 1.55.5 1.55.65 1.65 1.6v-5.05-3.25q-.05-.1-.05-.15 0-.1.05-.15v-74m9.4-4.8q-.75-.3-1.6-.45v77.1q.85.2 1.6.5 1.5.7 1.6 1.65v-5.05-3.1-.2V20v-.55V10 4.7q-.5-.9-1.6-1.3m12.5-1.15q-.3-.45-.8-.75-.3-.2-.7-.35-.75-.3-1.6-.45l.05 55.85v.2l-.05 14.85q.85.2 1.6.5 1.5.7 1.6 1.6v-4.8-3.3V2.25h-.1m20.5.05q-.7-.3-1.55-.45V55.6q.85.2 1.55.5 1.55.7 1.65 1.6v-4.15-3.1q-.05-.05-.05-.15 0-.05.05-.1V3.65q-.5-.95-1.65-1.35m-9.55-.8Q46.6.8 45.65.45q-.75-.3-1.6-.45v63.35q.9.15 1.6.5 1.5.65 1.6 1.6v-7.4-.2V1.8q-.1-.15-.15-.3m33.2 7.3q-.45-.9-1.6-1.35-.75-.3-1.6-.45v20.3h.1v13.35q.85.2 1.55.5 1.3.6 1.55 1.35l.1.3v-7.1q-.05-.1-.05-.15 0-.05.05-.1V27.3h-.1V8.8m-11-2.95q-.45-.95-1.6-1.4-.75-.25-1.6-.45v44.05q.85.2 1.6.5 1.5.7 1.6 1.6V46.2v-3.15-.1-37.1m22 5.8q-.45-.9-1.6-1.35-.75-.3-1.6-.45V27.3h.1v5.95q.85.2 1.6.55 1.5.65 1.6 1.6V30 27.3h-.1V11.65z"
                    id="ObjMus026__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M7.8 64.4q-1.05-.2-2.3-.2-2.25 0-3.9.75-1.5.65-1.6 1.6V66.7q0 1.05 1.6 1.75 1.65.75 3.9.75 2.3 0 3.9-.75 1.65-.7 1.65-1.75 0-.1-.05-.15-.1-.95-1.6-1.6-.7-.35-1.6-.55m12.6-4.75q1.65-.7 1.65-1.75v-.15q-.1-.95-1.65-1.6-.7-.35-1.55-.5-1.05-.25-2.3-.25-2.3 0-3.95.75-1.45.65-1.6 1.6V58.05q.15.95 1.6 1.6 1.65.75 3.95.75 2.25 0 3.85-.75M29.85 47q-1.05-.2-2.3-.2-2.3 0-3.9.7-1.5.7-1.6 1.65-.05.05-.05.15 0 .05.05.15.1.95 1.6 1.6 1.6.75 3.9.75 2.25 0 3.9-.75.8-.35 1.2-.8l.4-.95v-.05-.1q-.1-.95-1.6-1.65-.75-.3-1.6-.5m13.8-5.2l.4-.95v-.05-.15q-.1-.9-1.6-1.6-.75-.3-1.6-.5-1.05-.2-2.3-.2-2.3 0-3.9.7-1.5.7-1.6 1.6V41q.1.9 1.6 1.6 1.6.75 3.9.75t3.9-.75q.8-.35 1.2-.8m9.8-7.5q1.65-.7 1.65-1.75 0-.05-.05-.15-.1-.95-1.6-1.6-.7-.35-1.6-.5-1.05-.25-2.3-.25-2.25 0-3.9.75-1.5.65-1.6 1.6V32.7q.15.95 1.6 1.6 1.65.75 3.9.75 2.3 0 3.9-.75M67.7 15.5q-1.5.7-1.6 1.6v.05q-.05.05-.05.1 0 .1.05.15.1.95 1.6 1.65 1.6.7 3.9.7 2.25 0 3.9-.7.8-.4 1.2-.8l.4-1v-.05-.1q-.1-.9-1.6-1.6-.75-.3-1.6-.5-1.05-.2-2.3-.2-2.3 0-3.9.7m-3.25 7.55q-.7-.3-1.55-.5-1.05-.25-2.3-.25-2.3 0-3.95.75-1.45.7-1.6 1.6V25q.15.9 1.6 1.6 1.65.7 3.95.7 2.25 0 3.85-.7 1.65-.75 1.65-1.8v-.15q-.1-.9-1.65-1.6M85 7.6h-.1q-.35-.1-.8-.1l-1.5-.1-2.05.15q-1 .2-1.8.55-1.45.7-1.6 1.65v-.3q-.05.15-.05.25V10.05q.1.9 1.6 1.6.8.35 1.85.55l2.05.2h.05l2.1-.2q1-.2 1.8-.55 1.65-.75 1.65-1.75v-.15l-.1-.3q-.25-.75-1.55-1.35-.7-.3-1.55-.5M96 .2Q94.95 0 93.7 0q-2.3 0-3.9.75-1.5.65-1.6 1.6v.05q-.05.05-.05.1t.05.15q.1.95 1.6 1.6 1.6.75 3.9.75 2.25 0 3.9-.75 1.6-.7 1.6-1.75v-.15q-.1-.95-1.6-1.6Q96.85.4 96 .2z"
                    id="ObjMus026__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M7.3 65.4q0-.5-1.05-.85-.1-.05-.25-.05-1-.3-2.35-.3-1.5 0-2.6.35Q0 64.9 0 65.4t1.05.85q1.1.4 2.6.4t2.6-.4q1.05-.35 1.05-.85m3.7-8.8q0 .55 1.05.9 1.1.35 2.6.35 1.55 0 2.6-.35t1.05-.9q0-.5-1.05-.85-.1-.05-.2-.05-1-.3-2.4-.3-1.5 0-2.6.35-1.05.35-1.05.85m28.25-16.2q1.1-.35 1.1-.85t-1.1-.9q-.1 0-.2-.05-1-.3-2.35-.3-1.5 0-2.6.35-1.05.4-1.05.9t1.05.85q1.1.35 2.6.35t2.55-.35M23.1 47.15Q22 47.5 22 48q.05.5 1.1.85t2.6.35q1.5 0 2.55-.35 1.05-.35 1.1-.85 0-.5-1.1-.85l-.2-.1q-1-.25-2.35-.25-1.55 0-2.6.35M47.7 32.5q1.5 0 2.6-.35 1.05-.35 1.05-.9 0-.5-1.05-.85-.1-.05-.25-.05-1-.3-2.35-.3-1.5 0-2.6.35-1.05.35-1.05.85 0 .55 1.05.9 1.1.35 2.6.35m18.35-16.55V16q0 .5 1.1.85 1.05.35 2.6.35 1.5 0 2.55-.35 1.1-.35 1.1-.85v-.05q-.05-.5-1.1-.85-.1 0-.2-.05-1-.3-2.35-.3-1.55 0-2.6.35t-1.1.85m-4.75 6.7q-.1 0-.2-.05-1-.3-2.4-.3-1.5 0-2.6.35-1.05.4-1.05.9t1.05.85q1.1.35 2.6.35 1.55 0 2.6-.35t1.05-.85q0-.5-1.05-.9m22.1-14.9l-.2-.1q-.55-.15-1.15-.2h-2.7q-.6.1-1.15.3-1.05.35-1.05.85t1.05.85q.55.2 1.15.3h2.85q.65-.1 1.2-.3 1.05-.35 1.05-.85t-1.05-.85m11-7.4Q94.3.3 94.2.3q-1-.3-2.4-.3-1.5 0-2.55.35-1.1.35-1.1.85t1.1.85q1.05.4 2.55.4 1.55 0 2.6-.4 1.1-.35 1.1-.85T94.4.35z"
                    id="ObjMus026__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M101.55 19.65v-8.5l-.1-.85q-32.65-14-66.85-9Q17.5 3.8 0 11.05v23L1.5 36q18.2-5.65 20.1-9.1 5.25-6.6 13-10.1 22.5-10.3 66.05 5.05l.9-2.2z"
                    id="ObjMus026__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M101.55 16.55v-.25Q42.1-4.5 21.6 21.2 19.7 24.6 0 30.8v.15l1.5 1.95q18.2-5.65 20.1-9.1 20.55-25.7 79.05-5.05l.9-2.2m0-5.4V9.7Q48.25-10.5 0 11.35v1.4q48.2-21.9 101.55-1.6z"
                    id="ObjMus026__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus026__Symbol_110_0_Layer0_0_FILL"
                transform="translate(133.1 222.1)"
                id="ObjMus026__col1n"
            />
            <use
                xlinkHref="#ObjMus026__Symbol_84_0_Layer0_0_FILL"
                transform="translate(141 222.3)"
                id="ObjMus026__col1d"
            />
            <use
                xlinkHref="#ObjMus026__Symbol_69_0_Layer0_0_FILL"
                transform="translate(133.2 255.35)"
                id="ObjMus026__col1l"
            />
            <use
                xlinkHref="#ObjMus026__Symbol_58_0_Layer0_0_FILL"
                transform="translate(135 256.6)"
                id="ObjMus026__col1s"
            />
            <use
                xlinkHref="#ObjMus026__Symbol_36_0_Layer0_0_FILL"
                transform="translate(131.8 232.5)"
                id="ObjMus026__col2n"
            />
            <use
                xlinkHref="#ObjMus026__Symbol_16_0_Layer0_0_FILL"
                transform="translate(131.8 235.6)"
                id="ObjMus026__col2d"
            />
        </g></g>
    )
}

export default ObjMus026
