import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF98D0"
}

function FacMou070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M202.4 195.8h-17.8l-16.9 4-11.9 4.7-6.65 2q-1.051.48-2.15.9-1.37.49-2.75.8-.02.043-.05.05-.1.05-.25.1-6.1 1.6-12.65 1.05-.85-.05-1.35-.1v.4q1.6 1.85 4.1 2.45 2.45.55 6.95 2.15 4.5 1.65 7.8 5.55 3.25 3.95 3.45 4.2l.1.05q.618.792 1.25 1.55l8.4 6.75 14.95 6.8 15.7 2.25 19.3-3.95 8.45-3.9 9.9-6.75q.42-.51 2.5-2.75l.1-.05q.2-.25.3-.4 6.45-7.75 10.95-9.35 4.5-1.6 7-2.15 2.45-.55 4.05-2.45v-.4q-.5.05-1.35.1-6.6.55-12.65-1.05-.15-.05-.25-.1l-.6-.15-3.25-.2-12.9-5.8-21.8-6.3z"
                    id="FacMou070__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M81.15 33.35l-.1.2-.1-.1q-2.1 2.15-6.7 3.75l-.1.25-.1-.15q-2.8 3.25-7.15 3.35v.05l-.05-.05q-2.5 3-7.3 2.2-2.55 2.35-6.35 1.8l-.05-.05h-.05v.15l-.1.1.05-.1q-3.6 1.45-7.35 1.65h-.05q-.1-.05-.15-.05l-.05.25v-.2l-7.35-1.85.1-.05h-.25l.05-.05h-.05q-3.05.3-6.35-1.6-3.25.1-7.3-2.2l-.05.05v-.05q-3.95-.85-7.15-3.35l-.1.15-.1-.25q-3.95-1.35-6.7-3.75l-.1.1-.1-.2-1.15.15q10.65 10.45 29.5 14.25 7.15 1.25 14.45 0 18.85-3.8 29.5-14.25-.1-.35-1.15-.15m4.8-21l.1.25.05-.2q2.45.65 5.05.5-2.75-1.05-5.4-2.5-16.2-7.75-32.3-9.9-7.7-1-15.8 0-16.1 2.15-32.25 9.9-2.65 1.45-5.4 2.5l5.05-.5.05.2.1-.25q4.25-2.9 9.05-4.2h.05Q18.25 5.8 23 5.7q2.55-1.45 7.85-2.15l.05-.2.1.15q3.35-.75 7.35-.95l.05-.2.1.2 6.95-1.1v.15h.05v-.2l.05.15h.05l1.6-.2q1.6-.2 5.45 1.2l.1-.2.05.2q3.45-.2 7.35.95l.1-.15.05.2q2.75 1.75 7.85 2.15 4.65.6 8.65 2.45h.05l9.1 4.2z"
                    id="FacMou070__Symbol_129_0_Layer0_0_FILL"
                />
                <g
                    id="FacMou070__Symbol_95_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M27.05 40L24.5 29.8l-4.6 6.85q2.05 3.75 7.15 3.35M13 32.8q1.7 3.15 6.7 3.75l-3.4-9.3L13 32.8m-.2-.1l-3.2-6.75-1.55 2.95.1.05q1.6 2.05 3.5 3.9l1.15-.15m18.65-17.2l2.2-12.6q-4.3-1.55-7.85 2.15l5.65 10.45m-8.85 1.8l3.2-12.25q-4.85-1.3-8.7 2.45l-.1.05.05-.05q-6.1-.1-9.05 4.2l-.1.25-.05-.2q-2.45-.2-5.05.5-1.4.5-2.8.85l4.05 7.45 3.9-8.65 5.45 6.95L17.1 7.8l5.5 9.5M33.8 2.85L38.55 14l2.6-12.1q-3.8-2.35-7.35.95m46.6 24.4l-3.35 9.3q4.95-.6 6.7-3.75l-3.35-5.55m-8.15 2.55L69.7 40q5.1.4 7.15-3.35l-4.6-6.85m-23.8 15.95h.05q4.2 1 7.35-1.65l-.05.1.1-.1v-.15h.05L56 44l-.05-.1.05-.05V44q3.15 1.95 6.35-1.8 4 1.85 7.3-2.2L65 32.8l-2.35 9.4-4-8.5-2.75 10.1-.2-.55-4.2-8.5-3.2 10.95q.05 0 .15.05m7.45-1.9v.05l-.05-.05h.05m-14.85-.5l-.15.25-2.85-9.9-3.95 8.5-2.35-9.4L27.1 40q3.3 4.05 7.3 2.2 3.3 3.85 6.35 1.6h.05l-.05.05H41l-.1.05-.15.05.05.05q.1.05.2.15v.1l.05-.05q3.1 2.55 7.2 1.55v-.7l-3-10.3-4.2 8.5v.1M55.6 1.9L58.15 14l4.8-11.15q-3.6-3.3-7.35-.95M48.3.95h-.05V.8Q44.3-1.25 41.3 1.9l4.15 10.6 2.35-8.6q.1-.8.5-2.95m.2-.15l-.1.1h-.05l2.95 11.6 4.15-10.6q-3-3.15-6.95-1.1m36.6 32.05q1.9-1.85 3.5-3.9l.1-.05-1.55-2.95-3.2 6.75 1.15.15M65.3 15.5l5.65-10.45Q67.4 1.35 63.1 2.9l2.2 12.6m18.05 3.35l5.45-6.95 3.9 8.65 4.05-7.45q-1.4-.35-2.8-.85-2.6-.7-5.05-.5l-.05.2-.1-.25q-2.95-4.3-9.1-4.2l.05.05-.1-.05q-3.8-3.75-8.65-2.45l3.2 12.25 5.45-9.5 3.75 11.05z" />
                    <path d="M17.1 7.5h-.05l-.05.05.1-.05M56 44v-.15l-.05.05.05.1m-.1-.1v-.05h-.05l.05.05M79.65 7.5h-.05l.1.05-.05-.05z" />
                </g>
            </defs>
            <use xlinkHref="#FacMou070__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou070__Symbol_129_0_Layer0_0_FILL"
                transform="translate(147 194.5)"
                id="FacMou070__col2n"
            />
            <use
                xlinkHref="#FacMou070__Symbol_95_0_Layer0_0_FILL"
                transform="translate(144.2 195.15)"
                id="FacMou070__col1n"
            />
        </g></g>
    )
}

export default FacMou070
