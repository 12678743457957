import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#434343"
}

function LegPan020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M10.15 4.9q0 .05.1 2.45.15 1.2.5 1.8h1.2q-.8-3.15-.8-4.25-.2-1.65.15-2.05L12.05 1q-.15-1.4-1.5-.85-.4.15-.7.4-.45-.05-1 0-1.75.1-5.25 1.4Q0 3.25 0 6.1q0 .6.45 1.2.8 1.05 2.45 1.05 1.1 0 2.25-1.2Q6.3 5.9 7.1 5.2q.7-.7 2.35-2.1l-1.2 1.8Q7.15 6.55 7.2 8.4q0 1.85.05 2.5.05.65.2.85h1q-.1-1.45-.15-2.1-.05-1.15.35-2.45.25-1 1.4-2.85.05-.1.1-.15v.7M7.9 2.75q-.4.85-2.5 2.8-2.1 1.9-2.8 1.75-.75-.15-1.15-.55-.45-.45-.4-1.1Q1.05 5 2.3 4.3q1.45-.8 5.6-1.55m137.2 5.6q1.65 0 2.45-1.05.45-.6.45-1.2 0-2.85-3.6-4.15-3.5-1.3-5.25-1.4-.55-.05-1 0-.3-.25-.7-.4-1.35-.55-1.5.85l.75 1.85q.35.4.15 2.05 0 1.1-.8 4.25h1.2q.35-.6.5-1.8.1-2.4.1-2.45v-.7q.05.05.1.15 1.15 1.85 1.4 2.85.4 1.3.35 2.45-.05.65-.15 2.1h1q.15-.2.2-.85.05-.65.05-2.5.05-1.85-1.05-3.5l-1.2-1.8q1.65 1.4 2.35 2.1.8.7 1.95 1.95 1.15 1.2 2.25 1.2m.6-4.05q1.25.7 1.25 1.35.05.65-.4 1.1-.4.4-1.15.55-.7.15-2.8-1.75-2.1-1.95-2.5-2.8 4.15.75 5.6 1.55z"
                    id="LegPan020__Symbol_48_0_Layer0_0_FILL"
                />
                <g
                    id="LegPan020__Symbol_47_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M125.8.2h-.3Q61.8 12.55 0 0v1.5l63 12.25L125.8 1.2v-1z" />
                    <path d="M125.8.15l-.3.05h.3V.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#LegPan020__Symbol_47_0_Layer0_0_FILL"
                transform="translate(129.6 310.3)"
                id="LegPan020__col1n"
            />
            <use
                xlinkHref="#LegPan020__Symbol_48_0_Layer0_0_FILL"
                transform="translate(118.5 308.85)"
                id="LegPan020__col2n"
            />
        </g></g>
    )
}

export default LegPan020
