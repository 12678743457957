import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ShiMot033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.85 8.45q-.05-1.35-.1-2.8 0-.6-.05-1.25-.6.65-1.15 1.25-1.35 1.5-2.45 2.8-3.85 4.6-5 7.3-2.6 6.15-2.1 13.6Q15.1 13.5 0 6.2q.85 9.65 8.55 17.05 5.6 5.05 11.75 6.05l-4.35.95q-4.5.85-7.9 5.1-3.75 4.55 0 9.9 3.75 3.55 7.5 3.35l-.9 3.1q-.577 1.974.9 2.75 2.936.303 5.7-.6 3.2-.9 5.9-2.25 5-2.2 4.6-9.05 0-8.1-3.85-10.95 7-6.95 6.95-23.15M13.4 35q1 0 1.65.7.65.65.7 1.6-.05 1-.7 1.65-.65.65-1.65.7-.95-.05-1.6-.7-.7-.65-.7-1.65 0-.95.7-1.6.65-.7 1.6-.7z"
                    id="ShiMot033__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot033__Symbol_39_0_Layer0_0_FILL"
                transform="translate(173.85 253.7)"
                id="ShiMot033__col1n"
            />
        </g></g>
    )
}

export default ShiMot033
