import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF8E8E"
}

function FacMou086({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M208.95 216.25q-.961-1.545-2.6-1.65h-26.3q-3.25.1-4.6 2.8-.35.75-.55 1.4-.3.75-.55 1.85-.05.25-.15.6v.05q-.2 1.05-.3 2.1-.25 2.2-.05 4.55.05 1.2.6 2.2 3.15 6.1 9.8 4.4 2.9-.7 5.75-1.85.7-.25 1.3-.45.6-.2 1.1-.2.45 0 1.7.35t1.65.5q1.1.4 1.95.8 3.2 1.6 6.75 1.6 4.35.05 5.4-4.85.233-1.077.35-2.2.526-5.88-1-11.6-.108-.205-.25-.4z"
                    id="FacMou086__Layer9_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.8 2.8q-.5-1.05-1-1.95-.25-.4-.5-.85H.75Q.6.3.5.7q-.15.45-.25 1-.15.6-.2 1.35v.2q-.2 2.9.55 4.65 1.25 2.35 4.3 2.2 1.3-.15 3.15-.95Q10.45 8.1 10.9 8q.2-.05.4-.05 2.2-.45 4.6.4 1.05.35 2.2.95 2.65 1.55 4.65 1.35 1.75-.2 3.05-1.85.15-.15.25-.5.85-2.75-.25-5.5z"
                    id="FacMou086__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M6.25 0H0v5.1h6.25V0m7.6 5.1V0h-6.8v5.1h6.8m7.6 0V0h-6.8v5.1h6.8m.8-5.1v5.1h6.9V0h-6.9z"
                    id="FacMou086__Symbol_50_0_Layer0_0_FILL"
                />
                <g id="FacMou086__Layer1_0_FILL">
                    <path
                        fill="#666"
                        d="M194.85 218.05q-.01.05-.05.1h2.95q.01-.05 0-.1-.089-.535-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.311.415-.4.95m1.45 2.5q.6 0 1.05-.6.31-.379.4-.9h-2.95q.14.521.45.9.45.6 1.05.6m-8.55-.6q.45.6 1.05.6.6 0 1.05-.6.31-.379.4-.9h-2.95q.14.521.45.9m2.5-1.8q.01-.05 0-.1-.089-.535-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.311.415-.4.95-.01.05-.05.1h2.95m-7.35-.1q-.089-.535-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.311.415-.4.95-.01.05-.05.1h2.95q.01-.05 0-.1m-.4 1.9q.31-.379.4-.9h-2.95q.14.521.45.9.45.6 1.05.6.6 0 1.05-.6m20.65 0q.45.6 1.05.6.6 0 1.05-.6.31-.379.4-.9h-2.95q.14.521.45.9m2.5-1.9q-.089-.535-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.311.415-.4.95-.01.05-.05.1h2.95q.01-.05 0-.1z"
                    />
                    <path fill="#999" d="M207.4 218.15h-29.15v.9h29.15v-.9z" />
                </g>
            </defs>
            <use xlinkHref="#FacMou086__Layer9_0_FILL" />
            <use
                xlinkHref="#FacMou086__Symbol_114_0_Layer0_0_FILL"
                transform="translate(179.2 222.7)"
                id="FacMou086__col2n"
            />
            <use
                xlinkHref="#FacMou086__Symbol_50_0_Layer0_0_FILL"
                transform="translate(178.25 216.2)"
                id="FacMou086__col1n"
            />
            <use xlinkHref="#FacMou086__Layer1_0_FILL" />
        </g></g>
    )
}

export default FacMou086
