import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E5B000",
    "col2n": "#FFD446"
}
function AccJew062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.7.3Q1.4 0 1 0 .6 0 .3.3 0 .6 0 1v9.2q0 .4.3.7.3.3.7.3.4 0 .7-.3.3-.3.3-.7V1q0-.4-.3-.7z"
                    id="AccJew062__Symbol_153_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M31.7 11.7q-.1-.4-.4-.6-.3-.25-.7-.25l-10.15-.3L17.15.8Q17.1.65 17 .55q-.15-.2-.3-.3-.3-.25-.65-.25H16q-.35 0-.65.25-.15.1-.25.2-.15.15-.2.35l-3.5 9.6-10.2.1q-.4 0-.7.25-.3.2-.4.6-.15.35-.05.7.1.35.45.6l7.95 6.25-3 9.85q-.1.35 0 .75.1.35.45.6.3.2.7.2.35 0 .7-.2l8.45-5.7 8.3 5.85q.3.2.7.2.4 0 .7-.2.3-.25.45-.6.1-.35 0-.7l-2.7-9.8L31.3 13q.3-.25.4-.6.1-.35 0-.7m-4.55 1.45l-6.05 4.6q-.3.2-.45.6-.1.35 0 .7l2.05 7.3-6.25-4.4q-.3-.25-.7-.25-.35 0-.65.2l-6.35 4.3L11 18.8q.1-.35 0-.7t-.4-.6l-5.9-4.6 7.55-.1q.4 0 .7-.2.3-.25.45-.6L16 4.8l2.5 7.3q.1.35.4.55.3.25.65.25l7.6.25z"
                    id="AccJew062__Symbol_154_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew062__Symbol_153_0_Layer0_0_FILL"
                transform="translate(284.3 177.6)"
                id="AccJew062__col1n"
            />
            <use
                xlinkHref="#AccJew062__Symbol_154_0_Layer0_0_FILL"
                transform="translate(269.35 187.6)"
                id="AccJew062__col2n"
            />
        </g></g>
    )
}
export default AccJew062
