import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.9 9.5l-.45-1.25-3.3 1.15v-.05l-.2-.5-.35-1Q8.85 7.25 9.45 6q.7-1.4.3-2.85-.05-.2-.1-.35-.05-.05-.05-.1Q9 1.15 7.55.45 6-.3 4.4.25 2.8.8 2.05 2.35 1.35 3.8 1.8 5.3q0 .05.05.1l.05.2q.55 1.55 2.05 2.3 1.1.5 2.3.35l.55 1.6-2.95 1.05.4 1.2 3-1 1.6 4.7q.05.25.15.55v.1q.35 1-.1 1.95-.5.95-1.5 1.35-1 .3-1.95-.15-1-.45-1.3-1.45-.05-.05-.05-.1l2.6-.9.05-.1-4.95-3.3L0 19.4l.1.05 2.6-.9q.55 1.6 2.05 2.35 1.15.55 2.3.4l1.6-.5q1.05-.65 1.6-1.8.15-.3.25-.65.25.2.6.35 1.2.6 2.5.35l1.45-.5q1-.65 1.55-1.75.75-1.55.2-3.1l2.6-.9v-.1l-4.9-3.3-1.85 5.6.1.1 2.6-.9.05.05q.35 1-.1 1.95-.5.95-1.5 1.3-1.05.35-1.95-.1-1-.5-1.35-1.5v-.05h-.05q-.1-.3-.2-.5l-1.65-4.7 3.3-1.15m-5-7.55q.95.45 1.3 1.5.3 1-.15 1.95-.45.95-1.45 1.3-1.05.35-2-.1-.95-.5-1.3-1.5-.35-1 .15-1.95.45-1 1.45-1.3 1-.4 2 .1z"
                    id="SkiTat001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(244.75 238.75)"
                id="SkiTat001__col1n"
            />
        </g></g>
    )
}

export default SkiTat001
