import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400",
    "col2n": "#0000BB"
}

function ObjMor067({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.4 99.8l-.25.25q-1.65 1-.9 3.55l.8 1.3q1.25 1.75 3.3 1.35l1-.6q.95-4-3.8-5.9l-.15.05m52.25-31l-2.8-.15-7.85 5.2q-.05 0 .2 1.8.25 1.75 3.25 3.55v.05l8.5-5.6-.7.4q2.15-5.1-.6-5.25m8.5.1L70 63.7q2.65-5.85-.3-5.65-2.95.15-3 .15l-8 5.3q.05 0 .2 1.45.1 1.5 3.25 3.95M32.4 85q0 2.5-1.35 4.4l7.55-5q1.05-5.05-1.15-5.2l-2.2-.25-3.75 2.45q.9 1.6.9 3.6M15.85 97.7q-.6.35-1.2.65-.7.6.3 1.2l8.3-5.2-.4.2q.9-1.8-.8-2.3-2.15 3.1-6.2 5.45m74.8-52.9q.75 1.85 2.95 3.35l8.7-5.75q.55-4.55-3.85-5.2l-8.5 5.6q-.05.15.7 2M82.4 48q.1-.15.15-.3l-8.15 5.4q.1 0-.4 2.3-.5 2.25 3.9 3.1l7.85-5.2q1-4.1-3.35-5.3m27.5-24.9v.2q0 .05-.05.15-.7 2.1-1.8 4.2-.15.25-.3.55-.05.05-.05.1-1.05 1.35-2.25 2.65l.1.05q.05-.05.05-.1.5.15.9.25.05 0 .1.35t.35 1.35q.25.95 1.9 1 1.6.05 1.95.6.05.15.1.2l.05.05q.55-.6 1.05-1.25.8-1.1 1.55-2.25 1.75-2.85 2.7-5.95.1-.2.15-.4-3.05-4.05-6.5-1.75m5.75-15.85q-.9.95-1.65 2.05-.75.95-1.4 2.1-.25.6-.45 1.3-.15.35-.25.75-.3 1.1-.6 2.4 3 2.45 4.6 2.1 1.6-.4 1.85-.25.35-1.85 1.3-3.5.6-.9 1.4-1.75-.9-4.25-4.8-5.2m31.6 19.85q-.7 2.15-2.1 3.95-.05 1.7.75 3.4.75 1.65 3.9 1.25 2.55-3.15 3.6-7.15-1.95-.85-3.5-1.15-1.6-.35-2.65-.3m-20 22.25q.15-.2.4-.3-1.25-2.4-4.5-5.35l-1.1 1.05q-1.3 1.35-.3 3.7.45.7 1.05 1.25 1.5 1.05 3.2.4.25-.15.55-.25l.25-.25q.25-.1.45-.25m9-12.35q-1.2.25-.25-.05-4.25 1.2-4.95 1.4-.6.25-.35.2-2.8 5.85 3.85 5.75.8-.45 1.5-.75 1.55-.6 5.15-1.5.75-.2 1.4-.5-1.05-2.15-3.3-5.65-1.5.75-3.05 1.1M132.6 3.85q.95-1.65-1.85-3.85-4.7.3-8.75 2.4l2.95 6.1q.5-.3 1.1-.6 2.25-1.05 4.75-1.35.8-1.1 1.8-2.7m19.1 7.9q-1.95-.55-3.55-.3-1.6.25-1.8 3.3 1.6 2.9 1.75 6.2 1 .75 2.5 1.3t3.65-2q-.3-4.55-2.55-8.5m-9.3-1.85q2.15-.1 3.1-1.3.95-1.2.8-3.5-3.45-2.95-7.9-4.2-1.85 1.35-2.2 2.9-.35 1.5.6 3.2 3.15.85 5.6 2.9z"
                    id="ObjMor067__Symbol_199_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.95 105.65l9.6-6.05q-.15-.65-.3-1.25-3.95 1.95-7.7 2.1-3.05.05-4.65-1.2-.5.3-.9.55l.15-.05q2.9 2.2 3.8 5.9m45.5-36.95q2.95 1.65 3.4 5.35l7.9-5.15q-.35-4.05-3.35-5.4l-7.95 5.2m23.7-15.55l-7.9 5.15q2.7 1.6 3.35 5.4l7.9-5.2q-.4-3.15-3.35-5.35m-52.5 39.1q.5 1.05.8 2.3l3.35-2.1q-.65.1-1.35.1-1.45 0-2.7-.45-.05.05-.1.15M33.85 79q2.6 1.45 3.35 5.4l7.85-5.2q-.65-3.75-3.4-5.35L33.85 79M92.2 48.15q-.2-2.55-3.35-5.35L81 48q2.9 1.95 3.35 5.3l7.85-5.15m4.85-10.95q2.9 1.6 3.85 5.2l3.1-2.05q1.1-.85 2.35-2.1 1.5-1.5 3.2-3.55l-.05-.05q-.05-.05-.1-.2-.35-.55-.75-1-1-1.1-2.3-1.75-.55-.3-1.1-.5-.1-.05-.15-.05-.4-.1-.9-.25 0 .05-.05.1l-.1-.05q-.1.15-.2.25-.6.65-1.25 1.25l-1 1q-.6.55-1.2 1.05-1.55 1.35-3.35 2.7m11.85-15.4q-.15.7-.4 1.3 3.15-.05 6.5 1.75l.45-1.85q.2-.9.35-1.65.05-.1.05-.2.15-.8.25-1.55.25-1.65.25-1.9l-.5-.3q-2.85-1.7-5.95-1.55-.35 1.65-.7 3.7-.05.3-.1.65l-.2 1.6m17.35 27.25q.45-.35.95-.7.95-.7 2.2-1.6.8-.55 2.55-1.7.65-.45 1.2-.75-3.35-1.45-3.85-5.75-3.6 1.55-6.9 4.55-.3.25-.65.55v.05q.95 4.45 4.5 5.35m14.8-15.15q-1.3 1-2.6 1.75-.3.15-.55.25.15 4.45 3.3 5.65 1.4-.55 2.6-1.3.45-.4 1-.75.55-.5 1.05-.95.2-.15.3-.3 1.3-1.2 2.25-2.55-1.7-.55-3.25-2.55-.7-.85-1.4-2.1-.95 1.3-2.15 2.35-.3.3-.55.5M121.5 10q.95-.85 2.05-1.5-.75-4.4-2.95-6.1-2 1.05-3.95 2.65-1.3 1.1-2.4 2.2 3.1 1.5 4.8 5.2 1.05-1.35 2.45-2.45m11.4-3.4q1.3.1 2.5.4.9-1.15 1.35-2.45.55-1.65.25-3.65-1.65-.45-3.5-.75-.25 0-.6-.05-1.85-.15-3.55-.1.95 2.3.7 4.3-.1 1.15-.65 2.25 1.65-.15 3.4 0 .05 0 .1.05m15.75 14.75q-.95-.05-1.95-.4.05 1.2-.1 2.5-.15 1.95-.75 3.65.95.65 1.85 1.05 2.15.85 4.3.4.6-1.95.85-4.05.2-2.2 0-4.25-2.15 1.25-4.2 1.1m1.65-9.6q-.95-1.8-2.35-3.55-1.45-1.7-3.05-3.1-.4 1.5-1.2 2.5-1 1.5-2.7 2.3 1.2 1 2.2 2.25 1.05 1.25 1.75 2.6 1.6-.2 2.9-.95 1.4-.75 2.45-2.05z"
                    id="ObjMor067__Symbol_200_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor067__Symbol_199_0_Layer0_0_FILL"
                transform="translate(107.4 206.35)"
                id="ObjMor067__col1n"
            />
            <use
                xlinkHref="#ObjMor067__Symbol_200_0_Layer0_0_FILL"
                transform="translate(108.8 206.35)"
                id="ObjMor067__col2n"
            />
        </g></g>
    )
}

export default ObjMor067
