import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFC400",
    "col1n": "#E5B000"
}

function AccJew037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.45 1.3Q6.75.65 6 .35 5.2 0 4.3 0h-.05Q4.1 0 4 .05 2.45.1 1.3 1.3 0 2.6 0 4.35q0 1.8 1.3 3.1 1.25 1.25 3 1.25 1.7 0 2.85-1 .15-.15.3-.25 1.25-1.3 1.25-3.1 0-1.75-1.25-3.05z"
                    id="AccJew037__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M.55 3.05q.55.55 1.25.55.75 0 1.25-.55.55-.55.55-1.25 0-.75-.55-1.25Q2.55 0 1.8 0 1.1 0 .55.55 0 1.05 0 1.8q0 .7.55 1.25z"
                    id="AccJew037__Symbol_58_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew037__Symbol_105_0_Layer0_0_FILL"
                transform="translate(94.55 177.75)"
                id="AccJew037__col1n"
            />
            <use
                xlinkHref="#AccJew037__Symbol_58_0_Layer0_0_FILL"
                transform="translate(97.05 179.1)"
                id="AccJew037__col1l"
            />
        </g></g>
    )
}

export default AccJew037
