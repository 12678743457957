import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#E0F0FF",
    "col1n": "#B4DBFF",
    "col2n": "#0087FF"
}

function ObjFoo032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1l}
                    d="M29.6 71.15q-.9.5-1.8.65-.1-.05-.25.05-11.95 3.05-22.6-3.1-1.55-.75-2.1-1.9-.55-.95-.15-1.9.3-.65 1.25-.8-.4-.1-.8-.05-1.3.05-2 .6-2.3 1.8 0 5.65 2.05 2.8 6.7 4.25.5.1 1.05.2 1.6.25 3 .1 1.65-.2 3.15-.95.2-.15.35-.25.25.15.35.3 1.2 1.15 2.8 1.8 1.3.55 2.85.8 1.25.2 2.45.15 4.25-.4 7.5-3.4 1.4-3.1 0-4.6-.4-.4-.9-.6.2.35.3.8.1.7-.15 1.25-.2.45-1 .95m.3-10.5q-.05.1-.05.2l-17.3-2.5q-.85 2.1-2.05 3.3-.75.7-1.6 1.1l-.2.1h-.05l-.2.1 22 3.05q.45.05 1-.15.25-.1.35-.2.1-.1.15-.2 0-.1.1-.2.25-.25.3-.65.1-.45-.45-1.15-.15-.25-.3-.3-.1-.1-1.7-2.5m.75-11.7l-18.45-2.3q1.25 2.7 1.35 5.8l18.5 2.6q.55.05.95-.25.55-.3.6-.85.1-.25 0-.5 0-.85-.6-1.65-.2-.35-.5-.65-.8-1.4-1.85-2.2M5.7 40.85q.55.1 1.1.3.2.1.35.2L33.5 44.9q.6.05 1-.45.2-.25.3-.35.3-.45.4-1.1 0-.15.05-.3-.95-3.35-3.7-5.45l-20.75-3q-3.85 1-5.25 3.75-.2.4-.3.9-.15.85.2 1.6.1.2.25.35m5.1-18.6q-1.25.55-2.7 2.3l.1-.05q-.6.55-.8 1.3-.35.65-.55 1.7-.05.6-.05 1.15Q6.4 30.4 9.4 31l23.7 3.45q2.9.2 3.45-2l.1-.6q.1-.35.1-.65.1-.35.15-.8.25-1.25-.4-2.25-.05-.15-.1-.25h.15q-.75-1.35-1.05-2.1l-24.7-3.55m29.25-5.9q-.2-1.5-.65-2.95Q27.25-.9 10.65 8.65q-3.4 2.2-3.4 6.3-.05.5-.05 1.1-.05.7.5 2.15.7 1.65 1.45 1.95.05 0 .1.05.05 0 .05.05l27.15 3.9q.2 0 .45-.1 1.2-.2 2.2-2.45 1.3-3.3 1-4.6.05-.3-.05-.55v-.1m-9-12.6l.35-2.2L20.85 0l-.3 2.15 10.5 1.6z"
                    id="ObjFoo032__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M133.9 238.6l-1.1 7.45 10.55 1.55 1.05-7.4-10.5-1.6z"
                    id="ObjFoo032__Symbol_14_0_Layer0_0_FILL"
                />
                <g
                    id="ObjFoo032__Symbol_96_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M40.7 31.85q.15-1.05.1-2-.1-3-2.1-4.4-.55-.6-.95-.95l2.05.25q.1.05.2 0 2.45-1.7 1.7-9.05-.8-4.1-3.2-7.8-2.25-3-6.15-5.65-4.1-3.5-10.5-1.6-4.1 1.35-6.95 3.4-4.75 3.25-6.95 8.55-2.1 5.85 0 7.5l.2.1 2.8.4q-.5.2-1.2.55-3 1.15-4 4.35-.15.6-.25 1.25-.05.4-.05.65-.2 1.65.05 2.85.3 1.45.95 1.7l3.1.4q-.6.25-1.35.6-2.8.7-3.75 3.2-.45 1.1-.7 2.45v.2q2.05.1 4.35.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05Q6.4 62.7 3.5 61.8h-.15q-.25-.1-.5-.25-1.05-.5-2-1.2-.7 1.7-.75 3.9-.8 5.35 4.15 8.85 2.15 1.4 5.2 1.85 1.65.25 3.3.15 1.8-.2 3.4-.85.25-.15.4-.25.2.2.45.3 1.25 1.15 3 1.85 1.5.55 3.15.75 2.4.4 4.5.05 6.55-2.05 7.6-8.2.75-5.4-1.6-7.95-.65-.75-1-1.15l1.95.3q.1-.05.15 0 .1.1.1 0 .25.1.8-.4-.1.05.45-1.05.65-1.35 1-3.4.1-.5.15-1 0-2.9-2.5-5.45-.5-.5-.95-.9l2.3.35q.1.05.15.05.2 0 .2.05.6-.05 1.25-1.25l.1-.2q0-.1.35-1.35.55-1.9.7-3.15.25-3.1-1.85-5.05l-.05-.05q-.5-.55-.9-.95l1.95.3q.05-.05.1 0 .1.05.25 0 .6-.05 1.2-1.25.8-1.35 1.15-3.4m.75-16.9h-.1v-.1h.05q0 .05.05.1m-26.3-3.75l.05-.05.45.1q-.25-.05-.5-.05m22.7 15.2l-.05.25q-.05-.15-.1-.25h.15z" />
                    <path d="M37.8 26.65l.05-.25h-.15q.05.1.1.25zM15.2 11.15l-.05.05q.25 0 .5.05l-.45-.1zM41.35 14.95h.1q-.05-.05-.05-.1h-.05v.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo032__Symbol_96_0_Layer0_0_FILL"
                transform="translate(110.65 247.55)"
                id="ObjFoo032__col1n"
            />
            <use
                xlinkHref="#ObjFoo032__Symbol_95_0_Layer0_0_FILL"
                transform="translate(111.95 246.05)"
                id="ObjFoo032__col1l"
            />
            <use
                xlinkHref="#ObjFoo032__Symbol_14_0_Layer0_0_FILL"
                id="ObjFoo032__col2n"
            />
        </g></g>
    )
}

export default ObjFoo032
