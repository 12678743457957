import { AssetSvgProps } from "../../../shared/assets"

function PlaAbs008({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="PlaAbs008__Layer2_0_FILL">
                    <path fill="#FF0" d="M385 203.4v-87.5H0v87.5h385z" />
                    <path fill="#00F" d="M385 335.5v-93.05H0v93.05h385z" />
                    <path fill="red" d="M385 74.85V0H0v74.85h385z" />
                </g>
                <g id="PlaAbs008__Layer2_1_FILL">
                    <path fill="#14BA01" d="M385 257.45V192.9H0v64.55h385z" />
                    <path fill="#C902CA" d="M385 385v-63H0v63h385z" />
                    <path fill="#FD8A17" d="M385 128.4V63.85H0v64.55h385z" />
                </g>
            </defs>
            <use xlinkHref="#PlaAbs008__Layer2_0_FILL" />
            <use xlinkHref="#PlaAbs008__Layer2_1_FILL" />
        </g></g>
    )
}

export default PlaAbs008
