import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col2n": "#FFFFFF"
}

function FacMou035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M44.75 6.65q.45-.05-.05-1-.55-1-6.5-3.3Q32.2 0 28.8 0q-1.45 0-3.7.6-2.15.5-2.5.5h-.1q-.35 0-2.55-.5-2.2-.6-3.7-.6-3.4 0-8.65 2.1T1 5.4Q-.4 6.55.2 6.65q.6.05.3.25 22.55-3.85 44.05 0-.3-.2.2-.25z"
                    id="FacMou035__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M47.3 13.2q-1-.75-3.2-2.6-4.45-3.75-6.35-5.45l-.6-.6q-.55-.5-.8-.75-.05-.1-.15-.2Q32.65.2 28.3 0q-2.35.05-4.55 1.5Q21.6.05 19.2 0q-4.3.2-7.9 3.6l-.1.2q-.25.25-.8.75l-.6.6q-1.95 1.7-6.35 5.45Q1.2 12.45.2 13.2l-.2.2q2.1.5 6 2.45.2.15.45.25 4.8 2.75 10.85 3.45 3.25.45 6.45.5 3.2-.05 6.5-.5 6.05-.7 10.85-3.45.25-.1.45-.25 3.9-1.95 5.95-2.45l-.2-.2m-2.1-.4l.6.3q-21.5-2.95-44.05 0l.6-.3q.3-.15.55-.3 3.2-1.75 6.5-2.95 4.7-1.75 8.1-1.75 1.5 0 3.7.45 2.2.4 2.55.4h.1q.35 0 2.5-.4 2.25-.45 3.7-.45 3.4 0 8.1 1.75 3.3 1.2 6.55 2.95.2.15.5.3z"
                    id="FacMou035__Symbol_60_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou035__Symbol_120_0_Layer0_0_FILL"
                transform="translate(170 217.2)"
                id="FacMou035__col2n"
            />
            <use
                xlinkHref="#FacMou035__Symbol_60_0_Layer0_0_FILL"
                transform="translate(168.75 210.4)"
                id="FacMou035__col1n"
            />
        </g></g>
    )
}

export default FacMou035
