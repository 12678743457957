import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col2n": "#000000"
}

function FacMou051({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M8.25 19.4q3.35 0 5.65-2.9 2.55-2.8 2.55-6.75 0-4-2.55-6.9Q11.6 0 8.25 0 4.8 0 2.3 2.85 0 5.75 0 9.75q0 3.95 2.3 6.75 2.5 2.9 5.95 2.9z"
                    id="FacMou051__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M21.3 12.75q.3-1.6.1-3.4-.25-1.8-.9-3.15-.7-1.35-1.1-1.95-.4-.6-2.95-3-.15-.15-.25-.2-2.25-2.05-4.95 0-.3.35-.5.75-.15-.4-.5-.75-2.1-2.05-5.1 0l-.05.05Q2.75 3.25 2.3 3.9q-.5.65-1.1 1.95-.65 1.4-1 3.35-.4 2-.05 3.55.3 1.5 1.15 3.05.8 1.5.8 2.6 0 1.05-.25 2.15-.25 1-.25 1.25-.3.5-.3 1.35 0 1.3.55 2.45.7 1.75 2.15 3.1 2.75 2.7 6.55 2.7h.4q3.8 0 6.5-2.7 1.5-1.35 2.15-3.1h.05q.5-1.15.5-2.45 0-.85-.25-1.35-.05-.25-.3-1.25-.3-1.1-.25-2.15 0-1.1.8-2.6.8-1.55 1.15-3.05m-10.5-5q3.1 0 5.25 2.55 2.35 2.6 2.35 6.2 0 3.55-2.35 6.05-2.15 2.6-5.25 2.6-3.2 0-5.5-2.6-2.15-2.5-2.15-6.05 0-3.6 2.15-6.2 2.3-2.55 5.5-2.55z"
                    id="FacMou051__Symbol_76_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou051__Symbol_123_0_Layer0_0_FILL"
                transform="translate(184.3 214.2)"
                id="FacMou051__col2n"
            />
            <use
                xlinkHref="#FacMou051__Symbol_76_0_Layer0_0_FILL"
                transform="translate(181.75 207.25)"
                id="FacMou051__col1n"
            />
        </g></g>
    )
}

export default FacMou051
