import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#479EEC",
    "col1n": "#77BFFF"
}

function LegPan006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7.05l.05-.05Q112.2 2.65 98.8 4.2l-.9.1q-1.65.15-3.3.35l-1.1.1Q78.2 6.2 63 6.25h-1.5Q47.4 6.2 33.4 4.9l-1.3-.1-2.9-.3q-.6-.1-1.2-.15Q13.95 2.8 0 0q0 .05.05.1v19.5q32.85-5.4 62.8 2.75Q92.8 14.2 125.7 19.6V.05z"
                    id="LegPan006__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.1 4.4q0 .45.35.8l.15.15q.3.15.65.15.3 0 .55-.1.1-.1.25-.2l.05-.05q.25-.35.25-.75 0-.45-.3-.8-.2-.2-.4-.25-.2-.1-.4-.1-.15 0-.25.05-.1 0-.15.05-.2.05-.4.25-.35.35-.35.8m64.5-.7q-.1-.25-.3-.5-.2-.2-.4-.25-.2-.1-.4-.1t-.4.1q-.2.05-.4.25v.05q-.05 0-.1.05-.25.3-.25.7 0 .45.35.8.35.3.8.3.4 0 .7-.2l.1-.1q.3-.35.3-.8v-.3m56.65-.1q-.2-.2-.4-.25-.2-.1-.4-.1t-.4.1q-.2.05-.4.25-.35.35-.35.8 0 .45.35.8.35.3.8.3.45 0 .8-.3.2-.25.3-.5v-.3q0-.3-.15-.6-.05-.1-.15-.2m2.4-2.25V.15q-.7.15-1.45.25-.3.05-.55.1-5.95 1.05-11.45 1.75-2.4.3-4.75.65-4.25.5-8.45.95h-.25V.6.45l-.9.1V5.7l-3.3.3V.9l-1.1.1q-.05.2-.1.2v3.1q-3.5.3-6.95.5-6.3.4-12.6.6l-4.85.1h-6V2.6v-.1h-1.5v3H59.9q-13.2-.15-26.55-1.25v-3-.1l-1.3-.1V6l-2.9-.25v-5q-.6-.1-1.2-.15v3.2q-1.05-.1-2.05-.2-3.7-.35-7.4-.8-1.2-.15-2.3-.3-1.35-.2-2.65-.35V2.1Q8.7 1.45 3.85.65 3.65.6 3.45.6 2.2.35 1.05.15.55.1 0 0v1.35q.5.05.95.15 1.15.2 2.3.35l8.1 1.2q-.05.7-.3 1.25-.25.5-.65.9-2.05 2-8.3 2.3-.65.05-1.3.05H0V8.7H1.8Q6.3 8.55 9 7.6q.55-.2 1-.45 1.55-.75 2.4-1.9.4-.65.6-1.4.05-.3.1-.55.55.05 1.05.15 1.05.1 2.1.25 3.2.4 6.45.75 2.6.3 5.25.55v1.55l5.4.5v-1.6q4.7.4 9.4.65 3.6.2 7.2.35h.15q5.5.2 10.95.25h.4v2.6h1.5V6.7q9.8.05 19.55-.45 1.2-.1 2.4-.15 3.45-.2 6.95-.45.75-.1 1.5-.15v1.6l5.4-.5V5.05l7.3-.8q3.1-.4 6.15-.8h.1q.15 0 .35-.05.05.4.2.75.25.8.8 1.5.25.35.65.7 2.55 2.2 9.7 2.45h1.65V7.7q-.85 0-1.6-.05H124q-4.45-.15-6.75-1.1-1-.45-1.6-.95-.8-.7-1.05-1.5-.15-.35-.15-.7-.05-.1-.05-.25 4.3-.65 8.95-1.35.55-.1 1.05-.2.65-.15 1.25-.25z"
                    id="LegPan006__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan006__Symbol_6_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan006__col1n"
            />
            <use
                xlinkHref="#LegPan006__Symbol_7_0_Layer0_0_FILL"
                transform="translate(129.7 314)"
                id="LegPan006__col1d"
            />
        </g></g>
    )
}

export default LegPan006
