import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#B46BFF",
    "col1n": "#CB9AFF",
    "col2d": "#4848F5",
    "col2l": "#ADADFF",
    "col2n": "#8080FF"
}

function AccHat048({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M172.25 10.6q-1.15-.45-2.2-.9-11.45-5-23.4-7.3Q138.9.6 131.15.15q-5.15-.3-10.3 0-7.8.45-15.55 2.25-9.5 1.75-19.4 5Q50.75 20 36.85 55.45 35.15 58.5 34 61.3h-.05q-1.05 2.45-1.7 4.6-3.8 12.7-3.8 12.8h.05l-1.1 4.25.1-.1-7.8 35.6q.05-.1.15-.2l-6.2 28.45q-.05.15-.1.35Q10.9 161.1 2.05 170v.05q-2.3 2.45-2.05 4.2.8 4.6 12.35 0 9.3-3.3 12.55-10.1l.3-.75q.8-1.75 2.6-5t3.5-5.95q1.65-2.7 4.95-9.2 3.25-6.55 11.75-15.8 2.2-2.45 4.55-4.7 8.15-9.25 13.35-12.8 5.15-3.6 7-4.55 1.75-1 2.25-1.25 5.35-2.8 10.75-4.95v-.05h.15q5.8-2.15 11.75-3.6l-.65.1q14.1-2.6 27.05-2.8 10.05-.2 24.95 1.9l-3.5-.65q3.5.55 7.05 1.45 5.95 1.4 11.8 3.6h.1v.05q5.45 2.15 10.7 4.95.45.25.95.55 3.4 1.85 6.6 4.15 8.05 5.7 15.15 13.9 2.25 2.25 4.5 4.7h.05q8.45 9.25 15.55 21.75.5.9 1.6 3.5 1.15 2.6 2.75 5.8 1.6 3.15 2.8 4.9l.4.75q2.6 5.4 9.65 8.6 20.25 7.7 16.1 0-.5-.95-2-1.9v-.35q-9.05-8.85-12-21.6-.2-.6-.3-1.2l-6.15-27.95q.05.1.1.25l-7.5-34.15v.05l-1.9-7.55.1.1q0-.1-.05-.2.15.15.3.25l.05.05q-.05-.053-.05-.1-.05-.15-.05-.3l-.25.05q-2.85-12.9-7.15-22.95-15.35-32.45-42.3-44.65z"
                    id="AccHat048__Symbol_266_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M251.4 87.75q-.5-.95-2-1.9-14.35.15-23.1-17.55-9-18.05-15.25-25.85-1.9-2.4-3.95-4.7-.35-.45-.65-.85-8.35-10.3-16.95-17.2-3.3-2.7-6.65-4.85-2.4-1.6-4.9-2.9-5.25-2.8-10.7-4.95-2.95-1.15-6-2.05l-.4-.1q-.1-.05-.2-.05-.25-.1-.6-.15l-.15-.05q-7.9-2.2-15.85-3.3Q134.2-.15 124.2 0q-9.2.1-18.55 1.5Q97.5 2.7 89.4 4.95q-3.2.9-6.15 2.05-5.45 2.15-10.75 4.95-2.45 1.3-4.9 2.9Q64.2 17 61 19.7q-8.65 6.9-17 17.2-.3.4-.65.85-2.05 2.3-3.9 4.7-6.25 7.8-10.9 17.4Q17 83.2 2.05 85.05-.25 87.5 0 89.25q.8 4.6 12.35 0 9.3-3.3 12.55-10.1l.3-.75q.8-1.75 2.6-5t3.5-5.95q1.65-2.7 4.95-9.2Q39.5 51.7 48 42.45q2.2-2.45 4.55-4.7 8.15-9.25 13.35-12.8 5.15-3.6 7-4.55 1.75-1 2.25-1.25 5.35-2.8 10.75-4.95v-.05h.15q5.8-2.15 11.75-3.6l-.65.1q14.1-2.6 27.05-2.8 10.05-.2 24.95 1.9l-3.5-.65q3.5.55 7.05 1.45 5.95 1.4 11.8 3.6h.1v.05q5.45 2.15 10.7 4.95.45.25.95.55 3.4 1.85 6.6 4.15 8.05 5.7 15.15 13.9 2.25 2.25 4.5 4.7h.05Q211 51.7 218.1 64.2q.5.9 1.6 3.5 1.15 2.6 2.75 5.8 1.6 3.15 2.8 4.9l.4.75q2.6 5.4 9.65 8.6 20.25 7.7 16.1 0z"
                    id="AccHat048__Symbol_174_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M202.35 44.45q-.05-.053-.05-.1-.05-.15-.05-.3l-.25.05h-.05l.05.05q.15.15.3.25l.05.05M173.2 22.9q-4.25-2.4-8.35-4.25Q133.95 4.8 104.5 4.9q-16.45 0-32.45 4.35-.75.15-1.4.4v-.1q0-.15-.05-.3v.4h-.1q.05.05.1.05l-.05.1-.15-.05q-1.05-.3-4.6.95h-.55q-.35 0-1.05.2h-.1v.05H64q-2.05.05-4.95 1.2.55-1 1.15-2.6 1.5-2.4 1.5-4.2 0-1.55-1-2.95Q59 0 55.2 0q-7.5 0-11.3 6.3-2.45 4.05-2.45 7.6 0 .05.15 2.35.05.35.15.6-.1.15-.15.35-.4-1.5-.9-3-2.25-6.7-5.3-6.7-5.55 0-8.4 4.7-1.9 3.1-1.9 5.7 0 .05.5 3.85.4 1.55 1 2.5-.25-.1-.4-.15-1.05-.75-4-.75-2.9 0-4.25.9-2.05 1.4-3.05 3.2-1.05 1.8-1.05 4.05 0 1.3.3 1.95.35.8 1.75 2.05.95.85 3.2 2-1.05.95-1.7 1.7-.8.65-1.55 1.35-4.25 3.85-8 8.05L0 84.35q.05-.1.15-.2 7.35-14 24.9-27.05l3.85-2.8q-.05.35-.05.7 0 1.8.8 3.7.2 1.25.75 2.4 1.75 3.65 6.15 3.65 6.3 0 8.75-5.05 1.3-2.7 1.7-8.35.05-1.35.15-2.5.9.5 1.65 1 6.3 4.4 10.3 4.4 4.35 0 6.65-2.9 1.65-2.05 1.65-3.95 0-2.2-1.65-4.45-.1-.2-.2-.35-.3-.4-.7-.75-.5-.55-1.1-1.1L63.6 40q4-.65 7.05-2.5 1.25-.75 1.25-1l2.9-.65q2.9-.45 3.9-.65 8.75-1.8 18.35-2.95 3.7-.25 7.45-.25 16.2-.2 32.15 2.7 24.15 6.2 44.4 18.95 21.85 15.55 30.2 32 .05.1.1.25l-7.5-34.15v.05l-.1-.35q-9.8-15.45-27.5-26.65l.05-.15q-1.55-.9-3.1-1.75z"
                    id="AccHat048__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M16.15 36.05q-.4-1.35-1.5-2.95-1.35-2.05-2.95-2.9-1.15-.35-1.9-.55-1.45-.8-4.05 0-2.15.65-4 2.15Q0 33.2 0 34.15l.1.1q.6-.65 1.7-1.4 2.55-1.7 4.2-1.65.1-.1.3-.15.95-.35 2.85.3 1.55.7 2.55 2.15 1.5 2.15 1.5 4.25 0 .35-.6 2.45-.55 2.15-.55 2.9 0 .6.25 1.1.4.75 1.25.75h.3q1.3-.6 2.1-2.75.5-1.65.5-3.25 0-1.75-.3-2.9M.1 23.1q.25.6 1.25 1.2 1.7 1.1 4.4 1.1 4.95 0 6.6-3.2.8-1.4.8-3.7 0-2.35-2.55-5.9l-.2.1q.3.7.75 2.3.35 1.75.35 2.65 0 1.45-.55 2.55-.8 1.45-2.25 1.9-1.2.75-3.05.75-1.15 0-2.6-.75-.7-.4-1.15-.6-.4-.2-.55-.2Q0 21.3 0 22.65q0 .2.1.45m26.75-2.3q-2.1.6-2.9 1.2-4.1 1.5-4.1 5 0 .55 2.05 2.35.7.55 1.3 1l.5-.95q-1.15-1.5-1.15-2.55 0-.7.45-1.25.1-.05.2-.05.65-.95 2.8-1.75 2.6-1 5.2-1.1.4-.15 1.05-.15h2.95q4.55.6 4.55-1.65 0-.6-.8-1.25-.1-.1-.3-.2-.8-.05-1.8-.1-2.95-.1-5.3.5-.3.1-.65.15-1.55.1-4.05.8m3.95-8.75l.1-.3q-1.25.5-2.9.5-2.35 0-3.45-1.3-.7-.8-.7-1.55 0-1.25.7-1.85.5-1.1 1.75-2.95.1-.2.25-.4.55-.85.95-1.55.25-.7.4-1.2-.05 0-.05-.05-.2-1.2-.4-1.4H26.2q-.8.3-1.4.65-2.3 2.7-3.25 5.3l-.1.3q-.2.5-.3 1-.05.2-.05.35 0 1.65.8 3.25 1.45 2.9 4.9 2.9 1.55 0 2.9-1 .65-.45 1.1-.7z"
                    id="AccHat048__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.3 46.6q-.55.4-1.05.8l1.2-.9q-.053.003-.1.05l-.05.05M6.5 28.85q-.65-.3-1.55-.75-.35-.15-.65-.3-.897.812-1.5 1.45l.25-.2q.8.5 2 1.1Q0 34.25 0 38.55q0 1.6 1.85 4.35 2.65 3.9 6.9 3.9.95 0 2.05-.25l.5-.1q.05-.05.15-.05.05 0 .15-.05l-.1.1 2.6-1.85q0-.3.05-.6.05-.25.1-.45v-.15q-.6.2-1.15.4-1.65.6-3.05.95H7.1q-4.85-1.9-4.85-6.7 0-1.6.5-2.4Q3.85 34.3 5 32.8q1.4-1.9 3.1-3.15-.75-.3-1.6-.8m56.5-6.7q0-2.3-1.1-4.15-1.65-2.5-4.95-3.05.8-.6 1.65-1.5.55-.55 1-1.1.85-1 1.2-2.1.4-1.15.4-2.2 0-2.2-.35-3.2-.5-1.4-2.35-3.35-.45-.5-2.75-1.35L55.7.1q-.05-.05-.1-.05-1.05-.3-4.6.95 1.95.05 3.35.45.85.35 1.6.9 2.85 2 3.05 5.35v.2q0 .6-.2 1.25-.5 1.6-2.6 3.4-.45.4-.85.7-.8.45-1.65.85-2.7 1.2-5.15 1.55l.05.15q4.95.3 8 2.05 3.65 1.25 3.65 4.2 0 .55-.2 1.1-.3 1.6-2.95 3.65l2.9-.65q3-2.55 3-4z"
                    id="AccHat048__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat048__Symbol_266_0_Layer0_0_FILL"
                transform="translate(66.45 16.35)"
                id="AccHat048__col1n"
            />
            <use
                xlinkHref="#AccHat048__Symbol_174_0_Layer0_0_FILL"
                transform="translate(66.45 101.35)"
                id="AccHat048__col1d"
            />
            <use
                xlinkHref="#AccHat048__Symbol_88_0_Layer0_0_FILL"
                transform="translate(86.15 50.45)"
                id="AccHat048__col2n"
            />
            <use
                xlinkHref="#AccHat048__Symbol_45_0_Layer0_0_FILL"
                transform="translate(111.35 59.35)"
                id="AccHat048__col2l"
            />
            <use
                xlinkHref="#AccHat048__Symbol_27_0_Layer0_0_FILL"
                transform="translate(100.95 60.15)"
                id="AccHat048__col2d"
            />
        </g></g>
    )
}

export default AccHat048
