import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#0000BB",
    "col1n": "#0000FF",
    "col1s": "#040454",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}

function AccHat051({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M164.9 6.3q-.45-.25-.95-.45-24.5-11.65-58.8 0-.7.2-1.35.45-1.65.45-3.45.95-4.05 1.15-7.05 2-1.55.4-2.8.75-.25.05-.45.15-2.15.55-3.45.9Q79 8.65 75 7.7q-9.5-2.55-20.55-2.55h-.15q-5.7 0-10.9.65-15.6 1.95-27.5 9.7Q0 25.8 0 40.35q0 14.2 14.8 25.2 2.15 2.05 6.1 5.1.2 1.05.5 2.05.5 1.7 1.15 3.4.15 0 .3-.05 5.6-.75 11.2-1.45 2.3-.3 4.6-.55 15-1.7 29.95-2.6 6.65-.45 13.3-.7Q136.4 68.4 190.05 76q.45.05.85.1h.25q.5-1.3.95-2.6 0-.15.1-.35l.2-.6q.3-1.3.55-2.65.05-.3.15-.6.1-.1.25-.15 2.95-1.2 8-8.85 1.85-2.8 3.1-5.35 5.95-11.4 0-20.25-1.85-2.35-4.8-4.5l-.05-.05-.1-.05-.05-.05q-1.1-.8-2.3-1.55l-.3-.2q-.1-.05-.2-.15-1.45-1.05-3.7-2.5v.05q-14.55-9.8-14.9-10-1.4-1.25-2.75-2.3-5.75-4.45-10.4-7.1z"
                    id="AccHat051__Symbol_269_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M19.25 27.7q0-7.8 11-15.5 6.3-4.4 13.15-6.4-15.6 1.95-27.5 9.7Q0 25.8 0 40.35q0 14.2 14.8 25.2 2.15 2.05 6.1 5.1.2 1.05.5 2.05.55-1.3-.15-3.05-.7-1.8-.6-6.6.1-4.8.1-4.85 2.65-.45 12.25-3 8.6-2.35 11.25-2.5v-1.5q-1.15-1.15-5.65-3.8-6-3.55-8.4-5.2-10.95-7.45-10.95-14.5M175.3 13.4q-5.75-4.45-10.4-7.1-.45-.25-.95-.45-24.5-11.65-58.8 0-.7.2-1.35.45-1.65.45-3.45.95-4.05 1.15-7.05 2-1.55.4-2.8.75 2.2 1.2 5.05 2.15 1.05.35 2.1.65 2.75.4 5.4.35 2.35-.1 5.65.2 2 .15 2.9.35.95.15 7.8-.95 6.85-1.1 12.75-3 5.85-1.9 9.1-2.05 3.25-.15 3.45.2 2.95-.25 3.5-.1.1-.05.1.1 5.05.1 8 2.75.25.2.45.45l1 .6q7 4.65 7 15.5 0 6.05-3.25 10.35-3.25 4.25-3.25 8.65 0 1.55.1 2.5l34.35 9.2.95 6.8-2 6.4q.3.7.75 1.45.3-1.3.55-2.65.05-.3.15-.6.1-.1.25-.15 2.95-1.2 8-8.85 1.85-2.8 3.1-5.35 5.95-11.4 0-20.25-1.85-2.35-4.8-4.5l-.05-.05-.1-.05-.05-.05q-1.1-.8-2.3-1.55l-.3-.2q-.1-.05-.2-.15-1.45-1.05-3.7-2.5v.05q-14.55-9.8-14.9-10-1.4-1.25-2.75-2.3z"
                    id="AccHat051__Symbol_177_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M16.8 18.1q-.1-4.65.8-8.95.9-4.3 1.7-7.9V0h-.25Q12.7 5.75 8.55 17q-3.1 8.4-3.7 16.6-.25 3.35-.3 7.85Q3.4 39.1 3.1 36.7q-.25-1.9-.3-2.9-1.4 1.75-2.25 9.85Q-.35 51.7.2 54.1.75 56.5 1.55 65q.5-.1 1.1-.1.55 0 1.1.1.5.05 1 .25 1.25.4 2.4 1.4 6.75-7.4 10.2-18.45Q18.4 45.15 19 43q.55-2.25.55-3.6 0-1.3-1.3-9-1.35-7.65-1.45-12.3z"
                    id="AccHat051__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M8.3 0Q7.95 21.75 0 39.25q.5.05 1 .25 4.75-6.3 6.35-17.2Q8.95 11.35 8.9 7.7 8.85 3.95 8.3 0z"
                    id="AccHat051__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    fill="#FC0"
                    d="M255.7 85.45l-.1.1q-.65.95-1.1 1.95-1.45 3.1-1.45 7.05 0 5.25 2.55 8.95 2.55 3.75 6.15 3.75 3.6 0 6.15-3.75 2.55-3.7 2.55-8.95 0-1.75-.25-3.3-.25-1.2-.6-2.3-.65-1.85-1.7-3.4-.15-.25-.3-.45-.65-.85-1.35-1.5-1.15-1-2.4-1.4-.5-.2-1-.25-.55-.1-1.1-.1-.6 0-1.1.1-2.85.5-4.95 3.5z"
                    id="AccHat051__Layer7_0_FILL"
                />
                <g
                    id="AccHat051__Symbol_129_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M73.4 0q-4.5 1.2-6.7 1.8.75.15 1.5.35l1.65.45q1.75.4 8.4 1.75 6.65 1.4 19.4.1Q83.8 3.6 73.4 0m77.15 42l.05-.1q-23.95-5.45-48.3-6.6-3.45-.2-6.9-.2-3.5-.15-6.95-.15h-3q-3.45 0-6.95.15-3.45 0-6.9.2Q35.75 37 .8 47.9l-.6.2q-.35 4-.05 7.75 0 .1.05.2.1 1.25.8 5.35.6 2.8 1.65 5.45.15 0 .3-.05-.45-2.85-.65-5.45-.15-1-.2-1.95-.5-5.8 0-9.9 42.35-12.05 83.85-12.35h2.8q5.2 0 10.45.25 24.95 1.15 50.25 6.55.45-1 1.1-1.95m14 3.4q.35 1.1.6 2.3 3.3.85 6.7 1.8.45 4.1-.05 9.9-.05.95-.2 1.95-.2 2.6-.65 5.45l.3.05q.5-1.3.95-2.6 0-.15.1-.35.45-1.6.75-3.25.05-.3.15-.6l.2-1.5q.2-1.25.3-2.5.05-.1.05-.2.3-3.75-.05-7.75l-.6-.2q-.1-.05-.15-.05-4.2-1.3-8.4-2.45z" />
                    <path d="M150.65 41.9h-.05l-.05.1.1-.1M171 66.85h.25l-.3-.05.05.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat051__Symbol_269_0_Layer0_0_FILL"
                transform="translate(85.15 34.3)"
                id="AccHat051__col1n"
            />
            <use
                xlinkHref="#AccHat051__Symbol_177_0_Layer0_0_FILL"
                transform="translate(85.15 34.3)"
                id="AccHat051__col1d"
            />
            <use
                xlinkHref="#AccHat051__Symbol_129_0_Layer0_0_FILL"
                transform="translate(105.05 43.55)"
                id="AccHat051__col1s"
            />
            <use
                xlinkHref="#AccHat051__Symbol_89_0_Layer0_0_FILL"
                transform="translate(259.1 16.95)"
                id="AccHat051__col2n"
            />
            <use
                xlinkHref="#AccHat051__Symbol_28_0_Layer0_0_FILL"
                transform="translate(262.85 42.7)"
                id="AccHat051__col2d"
            />
            <use xlinkHref="#AccHat051__Layer7_0_FILL" />
        </g></g>
    )
}

export default AccHat051
