import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00AFAF",
    "col1n": "#02E3E3"
}

function AccHat059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g
                    id="AccHat059__Symbol_277_0_Layer0_0_FILL"
                    fill={colors.col1n}
                    fillOpacity={0.502}
                >
                    <path d="M72.05 19.75l.35-.05v-.1q-.2.1-.35.15z" />
                    <path d="M208.65 49.4l-.6-.3q-2.3-1.35-4.8-2.6l-.05-.05v.05q-.2-.15-.4-.25-1.3-.75-2.7-1.65v.1q-.05-.2-.05-.35-2.25-28.15-38.6-22.55l-2.9.75q-.05-.15-.1-.25-1.55-3.15-4.3-6.7-4.35-5.65-14.85-10-5.25-2.65-10.75-4-11.8-3.2-23.75 0-5.95 1.35-12.15 4-4.3 1.65-8.8 3.85-8 4.7-11.45 10.25l-.35.05q-.65-.1-1.25-.2h-.15q-.4-.1-.75-.1-.15-.05-.25-.05-.15-.05-.3-.05-1-.15-1.95-.25-36.7-4.3-43.95 27.5 0 .15-.05.3-1.4.95-2.8 1.8l-.6.3.1.1q-.098 0-.1.05V49l-.5.4-.05-.05q-4.2 2.35-5.65 3.05l-1.3 1Q.05 61.45.15 74.45-1.5 90.2 13.8 95.3q1.7.35 3.55.65-.05 0-.1.05-.4.35-.8.75-.3.55-.4 1.05.9 2.85 3.55.9.2-.2.45-.4 21.45-17.15 55.1-27.9 40-10.5 78.15 0 29.35 10.55 49.65 28 3.25 2.55 3.45 0 .05-.5-.1-1.05-.2-.8-.7-1.7 1.55-.5 3.05-.95.6-.3 1.25-.6 11.15-5.6 13.25-14.8 1.65-16.85-13.25-28.9-.05 0-.05-.05-.65-.45-1.2-.95z" />
                </g>
                <g
                    id="AccHat059__Symbol_184_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path
                        fillOpacity={0.6}
                        d="M7.2 31.15q-.05-1.8.25-3.75-.05.05-.1.05-1.4.95-2.8 1.8-.95 8.45 6.35 13.5.9.65 2 1.25l.4.2q-.2-.15-.35-.3-.9-.8-1.65-1.65-2-2.3-3-5.05l-.75-2.6-.15-.85q-.2-1.25-.2-2.6M54.7 6q.5-2.8 1.15-4.65.2-.55.4-1l.1-.2q-.2.1-.35.15-.65-.1-1.25-.2h-.15q-.4-.1-.75-.1-2.15 5.9-1.55 12.4l.1.75q.1.85.25 1.7.05.35.1.65l.6 2.65q.1.55.25 1.15 0-.6.05-1.15.25-7.4 1.05-12.15m88.25-1.5q0 .1.05.2.1.45.2.95.35 2.25.3 5.75-.05.4-.05.85-.05.65-.05 1.4-.05.3-.05.6-.05.9-.15 1.9-.1 1.15-.2 2.4-.05.35-.1.75-.05.15-.05.35.4-.45.7-1.05 1.85-4.55 1.85-9.95l.1-5.15q-.05-.6-.1-1.15l-2.9.75h-.05l.2.4q.15.45.3 1m43.8 22.3q-1.3-.75-2.7-1.65l.1 2.35v1.4q.1.9.1 1.75v.5q-.05 9.05-6 12.55 9.3-2.55 8.5-16.9z"
                    />
                    <path
                        fillOpacity={0.8}
                        d="M186.9 78.95q3.25 2.55 3.45 0 .05-.5-.1-1.05-.2-.8-.7-1.7-.05-.1-.1-.15-24.7-22-52.2-30.15-14.5-4.45-29.05-5.6l-9.75-.3-9.8.3q-14.7 1.15-29.55 5.6Q20 58.35 1.4 76.35q-.05.05-.1.15-.05 0-.1.05-.4.35-.8.75-.3.55-.4 1.05.9 2.85 3.55.9.2-.2.45-.4 21.45-17.15 55.1-27.9 40-10.5 78.15 0 29.35 10.55 49.65 28z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat059__Symbol_277_0_Layer0_0_FILL"
                transform="translate(80.85 23.95)"
                id="AccHat059__col1n"
            />
            <use
                xlinkHref="#AccHat059__Symbol_184_0_Layer0_0_FILL"
                transform="translate(96.9 43.4)"
                id="AccHat059__col1d"
            />
        </g></g>
    )
}

export default AccHat059
