import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#435125",
    "col1n": "#617433"
}

function LegPan009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M155.75 11.6q-.85-1.1-3.05-2.4-1.5-.9-3.25-1.45.3-.55.35-.85 0-.05.05-.1.55-1 .55-2 0-1.8-1.55-2.9-1.2-.85-3.45-1.45-1.35-.4-2.75-.4-1.45 0-2.95.4Q110.95 5.6 83 5.3l-2.8 3h-2.15L75.7 5.5q-27.15 0-57-4.9-.75-.2-1.5-.3-.7-.15-1.4-.2-.35-.05-.7-.05-5.75-.4-9 2.25-.15.15-.3.25-1.05.9-1.05 2 0 .9 1 2.15l.05.05q-.053-.05-.1-.05-.15.55-.75.6-.1 0-.2.05Q0 8.3 0 11.2q0 1.3.25 1.8 2.3 4.35 8.8 2.4 5-1.5 6.3-1.95Q19.6 12.4 25.5 11.9q.75-.1 1.5-.15 28.45-2.7 52.95 3.95 29.7-8.1 62.25-2.85 4.75 1.05 8.85 2.95l.6.15q2.4-.05 4.1-1.65.9-1.25 0-2.7z"
                    id="LegPan009__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M8.1.25Q7.5 0 7.15 0 5.7 0 5 1.55T2.05 3.1q-.7 0-1-.1-.05-.05-.1-.05-.15.55-.75.6-.1 0-.2.05h.05q1.3.45 5.35.95 4.1.5-.25-.95Q6 3.15 6 2 5.95.8 6.8.55q.9-.2 1.3-.3m129.8 6.3q1.75.3 3.5.7-1.75-.65-3.5-1.2-6.85-2.1-14.15-2.55 6.35-.25 12.1-2.2-7.85 1-15.55 1.55-4.15.3-8.3.55-18.2 1.1-36.55 1.15H73.3Q50.45 4.5 27.35 2.9q-8.15-.6-16.3-1.35Q16.4 2.9 25 3.4q-7.7.65-13.95 2.65-1.75.55-3.4 1.2 1.75-.4 3.4-.7Q25 3.7 31.05 4.2q9.7.9 19.8 1.45 23.4 1 46.05 0 7.75-.45 15.45-1.15 8.3-.75 18.4.85 3.6.5 7.15 1.2m5.2-3.75q-.3.05-1.15-1.15Q141 .3 139.5.3q-1 .2-1.25.2h-.05q-.05.05-.05.1.35.1.8.25.25.1 1.05.35.7.2.9.65.2.4-.15 1.25t1.15.9h2.8q.3-.55.35-.85v-.1q-.35.45-1.95-.25z"
                    id="LegPan009__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan009__Symbol_12_0_Layer0_0_FILL"
                transform="translate(112.55 316.9)"
                id="LegPan009__col1n"
            />
            <use
                xlinkHref="#LegPan009__Symbol_13_0_Layer0_0_FILL"
                transform="translate(117.3 320.65)"
                id="LegPan009__col1d"
            />
        </g></g>
    )
}

export default LegPan009
