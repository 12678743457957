import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#794D32",
    "col2n": "#E7D4B6",
    "col2s": "#B68637"
}

function ObjToo047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M116.35 124.6q-.75 1.5-.7 4.05l66.3 70.15 6.65-6.2-63.95-67.6q-1.75 1-3.85 1-2.5 0-4.45-1.4M6.65 0L0 6.25 65.6 75.6q7.4 1.05 6.55-6.2L6.65 0z"
                    id="ObjToo047__Symbol_207_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M54.45 57.35q-1.95-.5-3.45-2-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.05.25.2.2q0 .05-.05.1l.4.35 4.35-3.95M43.2 45.55q2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.1 2.15-5.3l-.2-.2v-.05L8.8 0 0 8.3l34.65 36.65q-.05.05-.05.1 3.85-1 8.6.5z"
                    id="ObjToo047__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M35.7 30.3L30 39q-.55.9-1 1.75-.05.05-.05.1 1.2-.35 2.5-.4l5.5-8.8-1.25-1.35M19.6 13.25l-1.85-1.95-.05 17.5 2.1 2.25-.2-17.8M7.1 0L0 10.05l1.45 1.55 7.15-10L7.1 0m34.75 44.8q.45.7.85 1.5l1.85-6.6-.2-.25-1.05-1.1-1.7 6.15q.1.15.25.3z"
                    id="ObjToo047__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo047__Symbol_207_0_Layer0_0_FILL"
                transform="translate(11.15 173.25)"
                id="ObjToo047__col1n"
            />
            <use
                xlinkHref="#ObjToo047__Symbol_86_0_Layer0_0_FILL"
                transform="translate(75.55 241.65)"
                id="ObjToo047__col2n"
            />
            <use
                xlinkHref="#ObjToo047__Symbol_3_0_Layer0_0_FILL"
                transform="translate(81.2 245.85)"
                id="ObjToo047__col2s"
            />
        </g></g>
    )
}

export default ObjToo047
