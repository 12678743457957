import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.05 7.75l-.8-.4q-1.85-1.1-7.8-4.1Q30.15 0 28.7 0q-2.5 0-6.2 2.45-.95-.6-1.8-1.05Q18.05 0 16.3 0q-.95 0-4 1.4-1.65.7-3.9 1.85-4.5 2.05-7.05 3.7-.85.5-1.35.8L2.9 8.8q4.7.7 9.4 1.05 4.2.3 8.4.4 10.8.1 21.7-1.45l2.65-1.05z"
                    id="FacMou046__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M45.15 2h-.7q-4.05 0-6.85-.2-2.8-.25-4.5-.6Q29.15.45 22.75 0h-.6q-.75.05-1.45.1-5.1.4-8.4 1.05-.2 0-.4.05-2 .45-5.4.7Q3.8 2 .3 2H0q4.8 1.95 9 3.4 2.35.8 4.05 1.35 11.7 2.45 18.8 0Q33.55 6.2 36 5.4q4.05-1.45 9.15-3.4z"
                    id="FacMou046__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou046__Symbol_71_0_Layer0_0_FILL"
                transform="translate(170.3 216.35)"
                id="FacMou046__col1n"
            />
            <use
                xlinkHref="#FacMou046__Symbol_24_0_Layer0_0_FILL"
                transform="translate(170.3 222.1)"
                id="FacMou046__col1l"
            />
        </g></g>
    )
}

export default FacMou046
