// en, de, es, nl, it, pt, sl
const translations = {
    Back: [
        "Back",
        "Zurück",
        "Atrás",
        "Terug",
        "Indietro",
        "Para trás",
        "Nazaj"
    ],
    Delete: [
        "Delete",
        "Löschen",
        "Eliminar",
        "Verwijderen",
        "Cancella",
        "Cancelar ação",
        "Izbriši"
    ],
    Lock: [
        "Lock",
        "Sperren",
        "Bloquear",
        "Vergrendel",
        "Blocca",
        "Bloquear",
        "Prikleni"
    ],
    Locked: [
        "Locked",
        "Gesperrt",
        "Bloqueado",
        "Vergrendeld",
        "Bloccato",
        "Bloqueado",
        "Priklenjeno"
    ],
    Move: [
        "Move",
        "Bewegen",
        "Mover",
        "Verplaats",
        "Sposta",
        "Mover",
        "Premakni"
    ],
    Rotate: [
        "Rotate",
        "Drehen",
        "Rotar",
        "Draai",
        "Ruota-Gira",
        "Rodar",
        "Obrni"
    ],
    Flip: [
        "Flip",
        "Spiegeln",
        "Voltear",
        "Spiegel",
        "Rovescia",
        "Derrubar",
        "Zrcali"
    ],
    Shading: [
        "Shading",
        "Schattierung",
        "Sombreado",
        "Schaduw",
        "Ombreggiate",
        "Sombras",
        "Senčenje"
    ],
    col1: [
        "Color 1",
        "Farbe 1",
        "Color 1",
        "Kleur 1",
        "Colore 1",
        "Cor 1",
        "Barva 1"
    ],
    col2: [
        "Color 2",
        "Farbe 2",
        "Color 2",
        "Kleur 2",
        "Colore 2",
        "Cor 2",
        "Barva 2"
    ],
    Move_info_text: [
        "You can also use drag and drop or arrow keys to move the item.",
        "Du kannst auch Drag-and-Drop oder Pfeiltasten nutzen, um das Item zu bewegen.",
        "También puedes arrastrar o usar las flechas para mover el objeto.",
        "U kunt ook slepen en loslaten of pijltoetsen gebruiken om het item te verplaatsen.",
        "Tu puoi anche utilizzare il trascinamento della selezione o i tasti freccia per spostare l'elemento.",
        "Você também pode usar arrastar e soltar ou as teclas de seta para mover o item.",
        "Za premik lahko poleg danih puščic na element z miško kliknete in ga povlečete, ali pa uporabite tipke s puščicami na tipkovnici."
    ],
    "Or use drag and drop": [
        "Or use drag and drop",
        "Oder nutze Drag-and-Drop",
        "Oppure usa il trascinamento della selezione",
        "Ou use arrastar e soltar",
        "Ali pa za premik na element z miško kliknete in ga povlečete"
    ],
    Info_headline: [
        "Welcome to the SP-Studio!",
        "Willkommen im SP-Studio!",
        "¡Bienvenido al SP-Studio!",
        "Welkom bij de SP-Studio!",
        "Benvenuto nel SP-Studio!",
        "Benvenuto nello SP-Studio!",
        "Dobrodošli na SP-studio!"
    ],
    Info_text: [
        `The SP-Studio is the biggest create-a-character tool online, and even 20 years
        after its launch there are updates with new content. <span class="mobilehide">Create a cute cartoon-
        look-a-like of yourself, friends or celebrities and dress it up! It's easy to use,
        but offers endless options for customization.</span>`,
        `Das SP-Studio ist der webweit umfangreichste Charakter-Baukasten, und nach 20 Jahren 
		gibt es immer noch regelmäßige Updates. <span class="mobilehide">Bastle niedliche Cartoon-Versionen von dir, 
		deinen Freunden oder Berühmtheiten und kleide sie ein! Leicht zu bedienen,
		aber mit unzähligen Möglichkeiten, kreativ zu sein.</span>`,
        `SP-Studio es la herramienta de crear-un-personaje más grande online; y hasta 20 años después 
		de su lanzamiento siguen apareciendo actualizaciones con nuevo contenido. 
		<span class="mobilehide">¡Haz un bonito personaje que se parezca a ti, amigos, o celebridades y dale ropa! 
		Es facil de usar, pero ofrece opciones infinitas para la personalización.</span>`,
        `De SP-Studio is de grootste online tool om een ​​karakter te creëren, en zelfs 20 jaar na de lancering zijn er updates met nieuwe content. 
		<span class="mobilehide">Creëer een schattige cartoon-look-a-like van jezelf, vrienden of beroemdheden en kleed het aan! 
		Het is gemakkelijk te gebruiken, maar biedt eindeloze mogelijkheden voor aanpassing.</span>`,
        `Il SP-Studio è il più grande "crea OC" online ed anche dopo 20 anni dell suo lancio, ci sono aggiornamenti con nuovi contenuti. 
        <span class="mobilehide">Crea un simpatico "OC" simile a te, ai tuoi amici o alle tue celebrità preferite. È facile da usare, 
        ma offre infinite possibilità di personalizzazione.</span>`,
        `O SP-Studio é o maior "OC maker" online e mesmo após 20 anos de seu lançamento, há atualizações com novos conteúdos. 
        <span class="mobilehide">Crie um "OC" fofo parecido com você, seus amigos ou celebridades favoritas. É fácil de usar, mas oferece inúmeras opções de personalização.</span>`,
        `SP-Studio je največje orodje za ustvarjanje likov na spletu. Celo 20 let po začetku še vedno prihaja do posodobitev z novo vsebino. 
        <span class="mobilehide"> Ustvarite ljubko podobo sebe, prijateljev ali zvezdnikov in jo oblecite! SP-Studio je enostaven za uporabo, 
        hkrati pa ponuja neskončno možnosti za ustvarjanje.</span>`
    ],
    "Let's start…": [
        "Let's start…",
        "Los geht's…",
        "Vamos a empezar...",
        "Laten wij beginnen...",
        "Iniziamo..",
        "Vamos começar..",
        "Pa začnimo..."
    ],
    Info_point_1_lead: [
        `Click on the skin of the character to change its color.`,
        `Klicke den Körper der Figur an, um die Hautfarbe zu ändern.`,
        `Haz clic en la piel del personaje para cambiar el color.`,
        `Klik op de huid van het personage om de kleur te veranderen.`,
        `Fare clic sulla pelle del personaggio per cambiarne il colore.`,
        `Clique na pele do personagem para mudar sua cor.`,
        `Kliknite na kožo vašega lika, da ji zamenjate barvo.`
    ],
    Info_point_1_text_a: [
        `The item options let you customize your picture in many ways. 
        You can also use`,
        `Die Item-Optionen lassen dich dein Bild freier gestalten. 
        Verwende`,
        `Las opciones de los objetos te dejan personalizar tu imagen de muchas maneras. También puedes usar`,
        `Met de itemopties kunt u uw afbeelding op veel manieren aanpassen. U kunt`,
        `Le "opzioni oggetto" ti consentono di personalizzare al meglio, la tua immagine in molti modi. 
        Puoi anche utilizzare`,
        `As 'opções de itens' permitem que você personalize melhor sua imagem de várias maneiras. 
        Você também pode usar`,
        `Možnosti elementov vam omogočajo prilagajanje slike na več načinov. XXX`
    ],
    Info_point_1_text_b: [
        `to access everything currently in use.`,
        `, um alles aufzulisten, was bisher eingefügt wurde.`,
        `para acceder a todo actualmente en uso.`,
        `ook gebruiken om toegang te krijgen tot alles wat momenteel in gebruik is.`,
        `per accedere a tutto ciò che è attualmente in uso.`,
        `para acessar tudo o que está em uso no momento.`,
        `lahko uporabite tudi za dostop do vsega, kar je trenutno na sliki.`
    ],
    Info_point_2_lead: [
        `Click on the category icons to add more items to the picture.`,
        `Klicke auf die Kategorie-Symbole, um neue Items hinzuzufügen.`,
        `Haz clic en los iconos de las categorías para añadir más objetos a la imagen.`,
        `Klik op de categoriepictogrammen om meer items aan de afbeelding toe te voegen.`,
        `Clicca sulle "icone di categoria", per aggiungere altri elementi all'immagine.`,
        `Clique nos "ícones da categoria", para adicionar outros elementos à imagem.`,
        `S klikom na ikone se vam pokažejo kategorije z mnogimi elementi, ki jih lahko dodate.`
    ],
    Info_point_2_text: [
        `There are many categories for clothes, hairstyles, backgrounds
        and more. In the mobile version you can find them here:`,
        `Es gibt viele verschiedene Kategorien für Kleidung, Frisuren,
		Hintergründe und mehr. In der mobilen Version findet du sie hier:`,
        `Hay muchas categorías para ropa, cortes de pelo, fondos y más. 
		En la versión de móvil las puedes encontrar aquí: `,
        `Er zijn veel categorieën voor kleding, kapsels, achtergronden en meer. 
		In de mobiele versie vind je ze hier:`,
        `Ci sono molte categorie per vestiti, acconciature, luoghi e altro ancora. 
        Nella versione mobile si trovano qui:`,
        `Existem muitas categorias para: roupas, penteados, papéis de parede 
        e etc. Na versão "mobile", encontre-os aqui:`,
        `Za oblačila, frizure, ozadja in podobno obstaja več kategorij. 
        V mobilni različici jih lahko najdete tu: `
    ],
    Info_point_3_lead: [
        `Have fun!`,
        `Viel Spaß!`,
        `¡A divertirse!`,
        `Veel plezier!`,
        `Divertiti!`,
        `Divirta-se!`,
        `Zabavajte se!`
    ],
    Info_point_3_text: [
        `The <a href="https://www.sp-studio.de/w/faq/" target="_blank" rel="noopener noreferrer">F.A.Q.</a> will help you with any questions. <span class="mobilehide">They also explain how to combine any items with the awesome "lock" button.</span>`,
        `Die <a href="https://www.sp-studio.de/w/faq/" target="_blank" rel="noopener noreferrer">F.A.Q.</a> helfen dir bei Fragen weiter. <span class="mobilehide">Dort wird auch erklärt, wie du mit der praktischen "Sperren" Funktion beliebige Items kombinierst.</span>`,
        `El <a href="https://www.sp-studio.de/w/faq/" target="_blank" rel="noopener noreferrer">F.A.Q.</a> te ayudará con cualquier pregunta. <span class="mobilehide">También explican como combinar objetos con el increíble botón de "bloquear".</span>`,
        `De <a href="https://www.sp-studio.de/w/faq/" target="_blank" rel="noopener noreferrer">F.A.Q.</a> zal u helpen met eventuele vragen. <span class="mobilehide">Ze leggen ook uit hoe u items kunt combineren met de geweldige "vergrendel"-knop.</span>`,
        `La sezione <a href="https://www.sp-studio.de/w/faq/" target="_blank" rel="noopener noreferrer">F.A.Q.</a>, ti aiuterà con qualsiasi domanda. Spiegano purè, come combinare qualsiasi elemento con il fantastico Tasto "blocca".`,
        `A seção <a href="https://www.sp-studio.de/w/faq/" target="_blank" rel="noopener noreferrer">F.A.Q.</a> irá ajudá-lo com qualquer dúvida. Eles também explicam como combinar qualquer item com o novo e legal botão "bloquear".`,
        `Zavihek s pogostimi vprašanji (<a href="https://www.sp-studio.de/w/faq/" target="_blank" rel="noopener noreferrer">F.A.Q.</a>) vam lahko pomaga pri marsikaterem vprašanju. Tam prav tako najdete razlago za uporabo vrhunske funkcije za „priklenitev“.`
    ],
    "Clear stage": [
        "Clear stage",
        "Bühne leeren",
        "Vaciar escenario",
        "Podium wissen",
        "Ripulisci stage",
        "Limpar estágio",
        "Izbriši vse"
    ],
    "No items yet": [
        "No items yet.",
        "Noch keine Items.",
        "Aún no hay objetos.",
        "Geen items nog.",
        "Nessun Item selezionato.",
        "Nenhum item selecionado.",
        "Brez elementov."
    ],
    "Undo delete": [
        "Undo delete",
        "Rückgängig machen",
        "Deshacer eliminación",
        "Verwijderen ongedaan maken",
        "Ripristinare",
        "Recuperar",
        ""
    ],
    "Default character": [
        "Default character",
        "Standard-Charakter",
        "Personaje por defecto",
        "Standaard karakter",
        "Personaggio generico",
        "Personagem Genérico",
        "Privzeti lik"
    ],
    Copyright_headline: [
        "Copyright",
        "Copyright",
        "Copyright",
        "Copyright",
        "Diritto d'autore",
        "Direito autoral",
        "Avtorske pravice"
    ],
    Copyright_text: [
        `Every drawing in this tool was created by Janina Himmen and is protected by copyright. 
        This is a non-commercial fan project inspired by the art style of the cartoon South Park. 
        Please always mention sp-studio.de as a source when you share the images or parts of them. Then you may hide the watermark.
        If you want to use the pictures for your own projects, have a look at the <a href="https://www.sp-studio.de/w/faq/#terms" target="_blank">terms of use</a> first.`,
        `Alle Zeichnungen im SP-Studio wurden von Janina Himmen erstellt und sind urheberrechtlich geschützt. 
        Es handelt sich hierbei um ein nicht-kommerzielles Fanprojekt, inspiriert durch den Stil der Serie South Park.
        Bitte gib immer SP-Studio.de als Quelle an, wenn du die Bilder (oder Teile davon) teilst. Dann darfst du das Wasserzeichen ausblenden.
        Bei Fragen zur Verwendung der Bilder findest du hier die <a href="https://www.sp-studio.de/w/faq/#terms" target="_blank">Nutzungsbedingungen</a>.`,
        `Todos los dibujos en esta herramienta fueron creados por Janina Himmen y están protegidos por copyright. Este es un proyecto fan no comercial 
		inspirado en el estilo de arte de South Park. Por favor, menciona siempre a sp-studio.de como una fuente cuando compartas imágenes o partes de ella. 
		Así podrás esconder la marca de agua. Si quieres usar las imágenes para tus propios proyectos, date un paseo por los <a href="https://www.sp-studio.de/w/faq/#terms" target="_blank">términos de uso</a> primero.`,
        `Elke tekening in deze tool is gemaakt door Janina Himmen en is auteursrechtelijk beschermd. Dit is een niet-commercieel fanproject geïnspireerd 
		op de tekenstijl van de cartoon South Park. Gelieve altijd sp-studio.de als bron te vermelden wanneer u de afbeeldingen of delen ervan deelt. 
		Dan kunt u het watermerk verbergen. Als u de foto's voor uw eigen projecten wilt gebruiken, bekijk dan eerst de <a href="https://www.sp-studio.de/w/faq/#terms" target="_blank">gebruiksvoorwaarden</a>.`,
        `Ogni disegno presente in questo sito è stato creato da Janina Himmen ed è protetto da copyright. 
        Questo è un progetto di fan non commerciale ispirato allo stile artistico del cartone animato: South Park. 
        Si prega di citare sempre sp-studio.de come fonte quando si condividono le immagini. In questo modo è possibile nascondere la filigrana. 
        Se volete utilizzare le immagini per i vostri progetti, <a href="https://www.sp-studio.de/w/faq/#terms" target="_blank">leggete prima le condizioni di utilizzo</a>.`,
        `Todos os desenhos deste site foram criados por Janina Himmen e são protegidos por direitos autorais. 
        Este é um projeto de fã não comercial inspirado no estilo de arte do desenho animado: South park. 
        Sempre mencione o sp-studio.de como fonte quando compartilhar as imagens. Assim, você terá permissão para ocultar a marca d'água. 
        Se quiser usar as imagens em seus próprios projetos, leia primeiro os <a href="https://www.sp-studio.de/w/faq/#terms" target="_blank">termos de uso</a>.`,
        `Vse slike na tej spletni strani so delo Janine Himmen in so zaščitene z avtorskimi pravicami. To je nekomercialni ljubiteljski projekt, 
        ki ga navdihuje umetniški slog risanke South Park. Ko delite slike ali njene dele ne pozabite navesti sp-studio.de kot vir. V tem primeru lahko odstranite vodni žig. 
        Če želite slike uporabiti za svoje projekte, <a href="https://www.sp-studio.de/w/faq/#terms">si najprej oglejte pogoje uporabe</a>.`
    ],
    "Download as SVG": [
        "Download as SVG.",
        "Als SVG herunterladen.",
        "Descargar como SVG.",
        "Download als SVG.",
        "Salva come .SVG.",
        "Salvar como .SVG.",
        "Prenesi kot SVG datoteko."
    ],
    "I agree to the terms, please remove the watermark.": [
        "I agree to the terms, please remove the watermark.",
        "Ich stimme der Vereinbarung zu, bitte entferne das Wasserzeichen.",
        "Acepto los términos. Quitad la marca de agua, por favor.",
        "Ik ga akkoord met de voorwaarden, verwijder het watermerk alstublieft.",
        "Accetto i termini, rimuovi il watermark.",
        "Aceito os termos, retire a watermark",
        "Soglašam s pogoji in prosim, da odstranite vodni žig."
    ],
    "Download as image": [
        "Download as image",
        "Als Bild herunterladen",
        "Descargar como imagen",
        "Download als afbeelding",
        "Scarica come immagine",
        "Baixar como imagem",
        "Shrani kot sliko"
    ],
    "Save your character": [
        "Save your character",
        "Sichere deinen Charakter",
        "Guardar tu personaje",
        "Sla je karakter op",
        "Salva la tua figura",
        "Salve sua figura",
        "Shrani svoj lik"
    ],
    "Grab this link to save your character for later or to share it with others.":
        [
            "Grab this link to save your character for later or to share it with others.",
            "Schnapp dir diesen Link, um deine Figur für später zu speichern oder mit anderen zu teilen.",
            "Coge este enlace para guardar tu personaje para después o compartirlo con otros.",
            "Pak deze link om uw personage op te slaan voor later of om het met anderen te delen.",
            "Prendi questo link, per salvare il vostro personaggio per dopo, possibilmente condividerlo con altri.",
            "Pegue este link, para salvar sua figura para mais tarde, possivelmente compartilhe com outras pessoas.",
            "Uporabite to povezavo, da svoj lik shranite ali ga delite z drugimi."
        ],
    "Copy the link": [
        "Copy the link",
        "Link kopieren",
        "Copiar enlace",
        "Kopieer de link",
        "Copie o link",
        "Kopiraj povezavo",
        "Copiar enlace"
    ],
    "Copied link!": [
        "Copied link!",
        "Link kopiert!",
        "¡Enlace copiado!",
        "Link gekopieerd!",
        "Link copiado!",
        "Povezava kopirana!",
        "¡Enlace copiado!"
    ],
    Imprint: [
        "Imprint",
        "Impressum",
        "Imprimir",
        "Imprint",
        "Impronta",
        "Imprensa",
        "Natisni"
    ],
    "Privacy policy": [
        "Privacy policy",
        "Datenschutz",
        "Política de privacidad",
        "Privacybeleid",
        "Privacy ed riservatezza",
        "Privacidade e confidencialidade",
        "Pravilnik o zasebnosti"
    ],
    Loading: [
        "Loading…",
        "Lädt…",
        "Cargando...",
        "Laden...",
        "Caricamento in corso...",
        "Carregando...",
        "Nalaganje..."
    ],
    "System language": [
        "System language",
        "System-Sprache",
        "Idioma del sistema",
        "Systeemtaal",
        "Lingua di sistema",
        "Idioma do sistema",
        "Sistemski jezik"
    ],
    English: [
        "English",
        "Englisch",
        "Inglés",
        "Engels",
        "Inglese",
        "Inglês",
        "Angleščina"
    ],
    German: [
        "German",
        "Deutsch",
        "Alemán",
        "Duits",
        "Tedesco",
        "Alemão",
        "Nemščina"
    ],
    Spanish: [
        "Spanish",
        "Spanisch",
        "Español",
        "Spaans",
        "Spagnolo",
        "Espanhol",
        "Španščina"
    ],
    Dutch: [
        "Dutch",
        "Niederländisch",
        "Holandés",
        "Nederlands",
        "Olandese",
        "Holandês",
        "Nizozemščina"
    ],
    Italian: [
        "Italian",
        "Italienisch",
        "Italiano",
        "Italiaans",
        "Italiano",
        "Italiano",
        "Italijanščina"
    ],
    Portuguese: [
        "Portuguese",
        "Portugiesisch",
        "Portugués",
        "Portugees",
        "Portoghese",
        "Português",
        "Portugalščina"
    ],
    Slovenian: [
        "Slovenian",
        "Slowenisch",
        "Esloveno",
        "Sloveens",
        "Sloveno",
        "Esloveno",
        "Slovenščina"
    ],
    "All options": [
        "All options",
        "Alle Optionen",
        "Todas las opciones",
        "Alle opties"
    ],
    "Having loading problems?": [
        "Please wait. Having loading problems?",
        "Bitte warte. Probleme beim Laden?",
        "Por favor, espere. ¿Problemas de carga?",
        "Even geduld.. Problemen met laden?",
        "Attendere. Problemi di caricamento?",
        "Por favor, aguarde. Carregando problemas?",
        "Prosimo, počakajte. Se pri nalaganju pojavljajo težave?"
    ],
    "Click here to get help": [
        "Click here to get help.",
        "Hier findest du Hilfe.",
        "Haga clic aquí para obtener ayuda.",
        "Klik hier om hulp te vragen.",
        "Fai clic qui, per richiedere aiuto.",
        "Clique aqui, para solicitar ajuda.",
        "Kliknite tu za pomoč."
    ],
    Search: ["Search", "Suchen"]
} as { [key: string]: string[] }

export function getCulture(countryCode?: string): string {
    let systemCulture = "en-US"
    let culture = systemCulture

    if (
        typeof window !== "undefined" &&
        typeof window.navigator !== "undefined"
    ) {
        systemCulture = window.navigator.language
    }

    if (typeof localStorage !== "undefined") {
        culture = (
            localStorage.getItem("culture")
                ? localStorage.getItem("culture")
                : systemCulture
        ) as string
    }

    countryCode = countryCode || culture
    return countryCode
}

export type Language = "en" | "de"

export function getLanguage(countryCode?: string): Language {
    countryCode = getCulture(countryCode)
    const lang = countryCode.substr(0, 2)
    if (lang === "en" || lang === "de") {
        return lang
    } else {
        return "en"
    }
}

export function getPhrase(keyword: string, countryCode?: string): string {
    countryCode = getCulture(countryCode)

    if (translations[keyword]) {
        if (countryCode.indexOf("de") > -1) {
            return translations[keyword][1]
        } else if (countryCode.indexOf("es") > -1 && translations[keyword][2]) {
            return translations[keyword][2]
        } else if (countryCode.indexOf("nl") > -1 && translations[keyword][3]) {
            return translations[keyword][3]
        } else if (countryCode.indexOf("it") > -1 && translations[keyword][4]) {
            return translations[keyword][4]
        } else if (countryCode.indexOf("pt") > -1 && translations[keyword][5]) {
            return translations[keyword][5]
        } else if (countryCode.indexOf("sl") > -1 && translations[keyword][6]) {
            return translations[keyword][6]
        } else {
            return translations[keyword][0]
        }
    } else {
        return keyword
    }
}
