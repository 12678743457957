import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A64F00",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function ObjToo015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.65 16.65q-.15-.2-.25-.4-2.1-2.85-5.45-7.75L9.75 0Q2.85 11.35 3 21.8L.9 25.7l-.2.25-.15.05-.55.25 13.9 13.8L16.2 42q2.55.4 5.55-1.9 3.7-2.85 8.1-10v-.05q.05-.15.05-.25-2.2-.45-3.9-2.15-2.25-2.25-2.25-5.4 0-3.05 2.15-5.3-.15-.15-.25-.3m-3.95-3q1.15 1.6 1.1 7.9 0 5.15-.65 7.3-1.6 5.2-6.35 6.05L5.1 24.75l7.05-8.8q.2-.25.4-.4 5.3-5.65 7.5-3.65 1 .8 1.65 1.75z"
                    id="ObjToo015__Symbol_175_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M83.35 87.6l.15-.05h-.1l2.4-2.15 6.75-23.8L8.4 0 2.9 3.6q-.3.05-.35.3l-.9.7v.05L0 11.15 5.65 9.5v.05l-1.8 5.55 5.6-1.6.05.05-1.8 5.55 5.55-1.6.05.05-1.75 5.5 5.55-1.6L15.3 27l5.55-1.6.1.05-1.8 5.5 5.5-1.6.1.15L23 34.95l5.5-1.6.1.15-1.75 5.4 5.45-1.55.15.15-1.8 5.4 5.5-1.55.15.15-1.8 5.4 5.25-1.5.1-.3.55-.15-1.8 5.6 5.6-1.6h.05l-1.85 5.6 5.65-1.6-1.8 5.6 5.6-1.65.1.05-1.85 5.55 5.6-1.6.05.05-1.8 5.55 5.55-1.65v.05l-1.8 5.5 5.55-1.6.1.1-1.8 5.45 5.55-1.6.1.15-1.8 5.45 5.5-1.6.15.15-1.8 5.4 5.5-1.55.1.15-1.75 5.4 5.45-1.55.15.15-1.75 5.4 6.4-2.7M7.3 8.15v.05h-.05l.05-.05M40.05 44.5l.05-.1 2.5 2.6-2.55-2.5z"
                    id="ObjToo015__Symbol_59_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M83.35 82.95l.15-.05h-.1l.3-.25L1.65 0 0 6.5l5.65-1.65v.05l-1.8 5.55 5.6-1.6.05.05-1.8 5.55 5.55-1.6.05.05-1.75 5.5 5.55-1.6-1.8 5.55 5.55-1.6.1.05-1.8 5.5 5.5-1.6.1.15L23 30.3l5.5-1.6.1.15-1.75 5.4 5.45-1.55.15.15-1.8 5.4 5.5-1.55.15.15-1.8 5.4 5.25-1.5.1-.3.55-.15-1.8 5.6 5.6-1.6h.05l-1.85 5.6 5.65-1.6-1.8 5.6 5.6-1.65.1.05-1.85 5.55 5.6-1.6.05.05-1.8 5.55 5.55-1.65v.05l-1.8 5.5 5.55-1.6.1.1-1.8 5.45 5.55-1.6.1.15-1.8 5.45 5.5-1.6.15.15-1.8 5.4 5.5-1.55.1.15-1.75 5.4 5.45-1.55.15.15-1.75 5.4 6.4-2.7m-43.3-43.1l.05-.1 2.5 2.6-2.55-2.5z"
                    id="ObjToo015__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo015__Symbol_175_0_Layer0_0_FILL"
                transform="translate(100.55 269.35)"
                id="ObjToo015__col1n"
            />
            <use
                xlinkHref="#ObjToo015__Symbol_59_0_Layer0_0_FILL"
                transform="translate(17.75 207.75)"
                id="ObjToo015__col2n"
            />
            <use
                xlinkHref="#ObjToo015__Symbol_11_0_Layer0_0_FILL"
                transform="translate(17.75 212.4)"
                id="ObjToo015__col2d"
            />
        </g></g>
    )
}

export default ObjToo015
