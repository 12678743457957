import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000"
}

function FacEye034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M70.8 61.6q64.7.6 66.6-61.55-64.55-2.2-66.6 61.55M0 .05Q1.9 62.2 66.6 61.6 64.55-2.15 0 .05z"
                    id="FacEye034__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 0q10.8 7.6 32 11.3Q20-.55 0 0m86.1 11.3q21.2-3.7 32-11.3-20-.55-32 11.3z"
                    id="FacEye034__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.251}
                    d="M165.45 151.1q-12-11.85-32-11.3 10.8 7.6 32 11.3m86.1-11.3q-20-.55-32 11.3 21.2-3.7 32-11.3z"
                    id="FacEye034__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEye034__Symbol_77_0_Layer0_0_FILL"
                transform="translate(123.8 134.6)"
                id="FacEye034__col1n"
            />
            <use
                xlinkHref="#FacEye034__Symbol_81_0_Layer0_0_FILL"
                transform="translate(133.45 139.8)"
                id="FacEye034__col1l"
            />
            <use xlinkHref="#FacEye034__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacEye034
