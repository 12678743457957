import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#92AA59"
}

function LegSki012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M139 0q-2.05.5-4.15.95-30.55 7-61.3 7-30.6 0-61.4-7Q10.05.5 8 0 1 2 0 8.6q0 1.05.3 2.15 2.05-2.9 6.95-5 .4 3.5 3.3 7.5 2.7-3.7 8.3-6.3 1.3 4.1 6.6 7.6 3.9-3.05 8.35-4.65 2.8 3.55 7.6 6.2 4.55-3 10.65-4.05 2.5 3.5 5.75 6.05l7.3-5 8 5.4 6.45-5.5 7.55 4.35q3.95-1.85 7-5.2 4.4 1.45 8.6 4.35 4.25-2.6 7.2-6.85 5.5 1.2 9.9 4.85 4.85-2.25 7.25-7.1 6 .15 9.25 4.75l1.05-1.2q1-1.2 1.85-4.9 5.15.8 8 3.25.15-.05.25-.05Q144.8.1 139 0z"
                    id="LegSki012__Symbol_78_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki012__Symbol_78_0_Layer0_0_FILL"
                transform="translate(118.95 307.1)"
                id="LegSki012__col1n"
            />
        </g></g>
    )
}

export default LegSki012
