import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ObjMor088({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.149}
                    d="M116.7-17.05q-21.5 0-40.4 5.95Q59.2-6.4 44.05 2.65q-1.1.6-2.25 1.3-12.05 7.4-22.85 17.6-40.2 37.9-40.2 91.4 0 13.7 2.6 26.3 2.65 12.6 7.8 24.2 2.8 6.25 9.25 26.8.9 7.35.9 9.35 0 17.6-4.6 42.6-2.25 12.5-4.6 23.4 0 8.55 2.05 14.3Q-6 285.05.4 295.6l8.9 14.9q3.4 5.7 5.15 7.7 5.5 6.55 11.2 6.55.5 0 .9-.05 1 .3 2.05.3 1.1 0 1.85-.35.45 0 .9.1v.25H202.3v-.25q.45-.1.85-.1.8.35 1.85.35 1.1 0 2.05-.3.45.05.95.05 5.65 0 11.2-6.55 1.7-2 5.1-7.7l8.95-14.9q6.4-10.55 8.2-15.7 2.05-5.75 2.05-14.3-2.3-10.9-4.55-23.4-4.6-25-4.6-42.6 0-2.25 1.15-11.25.05-.362.1-.75l-.6 5.2q5.4-23.95 7.8-29.35 2.3-5.15 4.1-10.4 6.3-18.85 6.3-40.1 0-53.5-40.2-91.4Q199.75 9.15 184.75.8l-1.2-.65q-3.25-1.8-6.5-3.3-3.8-1.8-7.65-3.35-24.15-10.55-52.7-10.55m-86.5 243.5q-.3.55-1.1 2.35.45-1.25.9-2.45.05.05.2.1m-23 44.15q-.3-.6-.6-1.25.15-.2.4-.4.05.85.2 1.65m196.25-44.15q.15-.05.2-.1.45 1.2.85 2.45-.8-1.8-1.05-2.35m23.6 42.9q-.3.65-.6 1.25.15-.8.2-1.65l.4.4z"
                    id="ObjMor088__Symbol_217_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M117.75-10.05q-19.838 0-37.35 5.7Q64.623.167 50.6 8.8q-1 .6-2.05 1.25Q37.4 17.15 27.4 26.9-9.75 63.15-9.75 114.35q0 13.1 2.4 25.15 2.45 12.05 7.2 23.15 2.616 6.006 8.55 25.65.85 7.03.85 8.95Q9.25 214.1 5 238 2.9 249.95.75 260.4q0 8.2 1.9 13.7 1.7 4.9 7.6 15l8.25 14.25q3.15 5.45 4.75 7.4Q28.35 317 33.6 317q.45 0 .85-.05.9.3 1.9.3 1 0 1.7-.35.4 0 .8.1v.25H196.9V317q.4-.1.8-.1.7.35 1.7.35t1.9-.3q.4.05.85.05 5.25 0 10.35-6.25 1.6-1.95 4.75-7.4l8.25-14.25q5.9-10.1 7.6-15 1.9-5.5 1.9-13.7-2.15-10.45-4.25-22.4-4.25-23.9-4.25-40.75 0-2.16 1.05-10.8.352-2.738.8-6.15.54-4.118-1.25 10.45 4.982-22.91 7.25-28.1 2.1-4.9 3.75-9.95 5.85-18.05 5.85-38.35 0-51.2-37.15-87.45Q194.55 15 180.65 7.05l-1.1-.65q-3-1.7-6-3.15-3.487-1.713-7.1-3.2-22.293-10.1-48.7-10.1M36.8 225.2q.4-1.2.8-2.35.05.05.2.1-.25.5-1 2.25M16 264q.15-.2.35-.4.05.8.2 1.6-.3-.6-.55-1.2m182.95-38.8q-.75-1.75-1-2.25.15-.05.2-.1.4 1.15.8 2.35m20.45 38.4q.2.2.35.4-.25.6-.55 1.2.15-.8.2-1.6z"
                    id="ObjMor088__Symbol_217_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M232.1 143.5q2.4-12.15 2.4-25.35 0-49.55-34.35-84.65-34.3-35.05-82.8-35.05-36.25 0-64.55 19.6-9.6 6.6-18.25 15.45Q.25 68.6.25 118.15q0 13.2 2.45 25.35h-.05q3.796 18.605 13.4 34.75.188.413.35.8 2.5 6.05 6.95 16.25 1.45 3.8 2.25 7.2-.5 14.25-8 31.2-5.3 12-5.5 12.5-2.5 6.4-2.5 10.9 0 7.55 3.45 12.2 1.1 1.6 4.25 5.6 3.15 4.3 6.05 9.6 1.65 3 2.55 6.75.85 3.65.7 6.9-.25 4.05 1.25 7.6 2.4 5.75 8.5 5.75 1 0 1.7-.2.4 0 .8.05v.15h157.8v-.15q.4-.05.8-.05.7.2 1.7.2 6.1 0 8.5-5.75 1.5-3.55 1.25-7.6-.15-3.25.7-6.9.9-3.75 2.55-6.75 2.9-5.3 6.05-9.6 3.15-4 4.25-5.6 3.45-4.65 3.45-12.2 0-4.5-2.5-10.9-.2-.5-5.5-12.5-6.45-14.6-7.7-27.2.05-.45.05-.95-.2-2.4-.35-3.9v-1q0-2.6.35-5.45.75-3.55 2.7-8 .147-.304.3-.6 14.075-19.614 18.8-43.1h.05M42.5 200.95q.05-.7.1-1.3V201q-.05-.05-.1-.05m-4.7 22q-.25.5-1 2.25.4-1.2.8-2.35.05.05.2.1M16 264q.15-.2.35-.4.05.8.2 1.6-.3-.6-.55-1.2m181.9-41.15q.4 1.15.8 2.35-.75-1.75-1-2.25.15-.05.2-.1m-5-22.3v-.9q.05.6.1 1.3-.05 0-.1.05v-.45m26.25 63.05q.2.2.35.4-.25.6-.55 1.2.15-.8.2-1.6z"
                    id="ObjMor088__Symbol_217_0_Layer0_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M204.55 190.95q24.9-30.47 24.9-71.45 0-46.95-32.65-80.2Q164.2 6.15 118 6.15q-46.2 0-78.9 33.15Q6.55 72.55 6.55 119.5q0 39.712 23.25 69.55 3.45 8.81 3.45 17.2 0 4.2-1 10.7-1 6.5-1 6.6 0 .05.05.5-1.35 3.9-3.05 8.3-2.25 5.85-6.2 16.05-2.8 7.8-2.8 10.75 0 3.85.9 7.6 1.15 4.9 3.5 7.25 4.6 4.65 10.65 8 .8.8 2.1 1.75 3.6 2.75 3.6 5.8 0 1.85-1.85 3.9-1.2 1.25-1.65 2.2-2.25 1.2-2.25 6.65 0 2.2.9 3.1 1 .95 3.95 1.8.1 0 .2.05.9.25 1.95.5h14q1.2-.05 2.05-.5l.2-.1h122l.2.1q.85.45 2.05.5h14q1.05-.25 1.95-.5.1-.05.2-.05 2.95-.85 3.95-1.8.9-.9.9-3.1 0-5.6-2.4-6.75-.7-1-1.8-2l-.05-.05q-2-3.6-2-5.75 0-1.75 3.9-4.55 7.45-3.65 12.95-9.2 2.35-2.35 3.5-7.25.9-3.75.9-7.6 0-2.95-2.8-10.75-3.95-10.2-6.2-16.05-1.7-4.5-3.1-8.5-.5-2.55-1-5.4-1.4-7.75-1.4-13.8 0-7.213 1.3-13.7m-162.3 9.6v.45q-.05-.05-.1-.05.05-.7.1-1.3v.9m-5 22.3q.05.05.2.1-.25.5-1 2.25.4-1.2.8-2.35M194.75 201v-.45-.9q.05.6.1 1.3-.05 0-.1.05m-3.5 86l.3-.1q-.05.4-.05.8l.1.45q-.35-.6-.35-1.15z"
                    id="ObjMor088__Symbol_217_0_Layer0_3_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M196.5 188.85q.8-1.65 4.15-7.2 20.4-26.65 20.4-61.75 0-42.75-30.2-72.95-30.2-30.2-72.95-30.2h-.3q-42.75 0-72.95 30.2-30.2 30.2-30.2 72.95 0 35.1 20.4 61.75 3.35 5.55 4.15 7.2 3.25 6.5 3.25 11.7 0 4.4-1.6 11.9-.8 3.5-1.55 6.6-.8 1.8-1.6 3.7-.15.35-7.35 16.85Q23 256.75 23 260.95 23 273.3 36.25 279q3.3 1.45 9.1 3.6 4.15 1.95 4.15 4.5 0 5.65-4.6 7.65-2.6 1.1-3.2 1.6-1.45 1.2-1.45 3.55 0 .05.25 2.2.4.9 1.3 1.4H112q1.4.05 2.6 0 1.8-.05 3.15-.25 1.3.2 3.15.25 1.2.05 2.6 0h70.2q.9-.5 1.3-1.4.25-2.15.25-2.2 0-2.35-1.45-3.55-.6-.5-3.2-1.6-4.6-2-4.6-7.65 0-2.55 4.15-4.5 5.8-2.15 9.1-3.6 13.25-5.7 13.25-18.05 0-4.2-7.15-21.35-7.2-16.5-7.35-16.85-.8-1.9-1.6-3.7-.75-3.1-1.55-6.6-1.6-7.5-1.6-11.9 0-5.2 3.25-11.7z"
                    id="ObjMor088__Symbol_217_0_Layer0_4_FILL"
                />
            </defs>
            <g id="ObjMor088__col1n">
                <use
                    xlinkHref="#ObjMor088__Symbol_217_0_Layer0_0_FILL"
                    transform="matrix(1 0 0 1.00804 74.75 30.4)"
                />
                <use
                    xlinkHref="#ObjMor088__Symbol_217_0_Layer0_1_FILL"
                    transform="translate(74.75 33.25)"
                />
                <use
                    xlinkHref="#ObjMor088__Symbol_217_0_Layer0_2_FILL"
                    transform="translate(74.75 33.25)"
                />
                <use
                    xlinkHref="#ObjMor088__Symbol_217_0_Layer0_3_FILL"
                    transform="translate(74.75 33.25)"
                />
                <use
                    xlinkHref="#ObjMor088__Symbol_217_0_Layer0_4_FILL"
                    transform="translate(74.75 33.25)"
                />
            </g>
        </g></g>
    )
}

export default ObjMor088
