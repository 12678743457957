import { AssetSvgProps } from "../../../shared/assets"

function PlaInd024({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <linearGradient
                    id="PlaInd024__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={195.425}
                    y1={297.55}
                    x2={196.375}
                    y2={274.15}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={288.7}
                    y1={426.925}
                    x2={293.1}
                    y2={383.475}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_3"
                    gradientUnits="userSpaceOnUse"
                    x1={223.488}
                    y1={395.05}
                    x2={227.313}
                    y2={337.65}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_4"
                    gradientUnits="userSpaceOnUse"
                    x1={244.925}
                    y1={325.738}
                    x2={248.375}
                    y2={293.262}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_5"
                    gradientUnits="userSpaceOnUse"
                    x1={215.813}
                    y1={263.575}
                    x2={218.288}
                    y2={240.125}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_6"
                    gradientUnits="userSpaceOnUse"
                    x1={172.987}
                    y1={246.625}
                    x2={191.412}
                    y2={220.875}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_7"
                    gradientUnits="userSpaceOnUse"
                    x1={204.25}
                    y1={241.013}
                    x2={218.55}
                    y2={216.287}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_8"
                    gradientUnits="userSpaceOnUse"
                    x1={261.2}
                    y1={282.863}
                    x2={261.7}
                    y2={260.338}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_9"
                    gradientUnits="userSpaceOnUse"
                    x1={230.4}
                    y1={238.375}
                    x2={243.5}
                    y2={215.725}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_10"
                    gradientUnits="userSpaceOnUse"
                    x1={269.825}
                    y1={254.375}
                    x2={271.275}
                    y2={239.425}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_11"
                    gradientUnits="userSpaceOnUse"
                    x1={255.613}
                    y1={230.85}
                    x2={273.088}
                    y2={211.85}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_12"
                    gradientUnits="userSpaceOnUse"
                    x1={290.15}
                    y1={237.412}
                    x2={279.45}
                    y2={210.987}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_13"
                    gradientUnits="userSpaceOnUse"
                    x1={312.9}
                    y1={244.713}
                    x2={299.9}
                    y2={217.487}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_14"
                    gradientUnits="userSpaceOnUse"
                    x1={157.45}
                    y1={349.875}
                    x2={167.85}
                    y2={314.425}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_15"
                    gradientUnits="userSpaceOnUse"
                    x1={117.35}
                    y1={314.5}
                    x2={123.85}
                    y2={288.5}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_16"
                    gradientUnits="userSpaceOnUse"
                    x1={-56.525}
                    y1={397.088}
                    x2={-41.675}
                    y2={347.113}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_17"
                    gradientUnits="userSpaceOnUse"
                    x1={103.1}
                    y1={406.738}
                    x2={117}
                    y2={365.262}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_18"
                    gradientUnits="userSpaceOnUse"
                    x1={-26.225}
                    y1={437.988}
                    x2={-12.775}
                    y2={386.012}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_19"
                    gradientUnits="userSpaceOnUse"
                    x1={54.75}
                    y1={380.375}
                    x2={69.15}
                    y2={337.425}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_20"
                    gradientUnits="userSpaceOnUse"
                    x1={48.263}
                    y1={263.438}
                    x2={63.638}
                    y2={236.863}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_21"
                    gradientUnits="userSpaceOnUse"
                    x1={27.25}
                    y1={334.238}
                    x2={40.15}
                    y2={295.762}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_22"
                    gradientUnits="userSpaceOnUse"
                    x1={7.075}
                    y1={300.662}
                    x2={16.525}
                    y2={272.137}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_23"
                    gradientUnits="userSpaceOnUse"
                    x1={10.787}
                    y1={269.15}
                    x2={27.012}
                    y2={240.85}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_24"
                    gradientUnits="userSpaceOnUse"
                    x1={-33.925}
                    y1={275.275}
                    x2={-6.975}
                    y2={241.825}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_25"
                    gradientUnits="userSpaceOnUse"
                    x1={88.6}
                    y1={286.712}
                    x2={91.1}
                    y2={256.688}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_26"
                    gradientUnits="userSpaceOnUse"
                    x1={156.45}
                    y1={271.088}
                    x2={156.45}
                    y2={251.113}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_27"
                    gradientUnits="userSpaceOnUse"
                    x1={78.313}
                    y1={265.9}
                    x2={103.488}
                    y2={222.4}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_28"
                    gradientUnits="userSpaceOnUse"
                    x1={146.25}
                    y1={249.013}
                    x2={160.55}
                    y2={224.287}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_29"
                    gradientUnits="userSpaceOnUse"
                    x1={115.438}
                    y1={253.488}
                    x2={130.863}
                    y2={226.813}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="91.765%" stopColor="#E6E6E6" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_30"
                    gradientUnits="userSpaceOnUse"
                    x1={145.65}
                    y1={216.925}
                    x2={161.45}
                    y2={189.375}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_31"
                    gradientUnits="userSpaceOnUse"
                    x1={83.013}
                    y1={222.912}
                    x2={96.087}
                    y2={200.088}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_32"
                    gradientUnits="userSpaceOnUse"
                    x1={82.838}
                    y1={114.063}
                    x2={98.063}
                    y2={87.738}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_33"
                    gradientUnits="userSpaceOnUse"
                    x1={115.888}
                    y1={218.675}
                    x2={131.012}
                    y2={192.525}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_34"
                    gradientUnits="userSpaceOnUse"
                    x1={114.962}
                    y1={185.1}
                    x2={130.637}
                    y2={158}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_35"
                    gradientUnits="userSpaceOnUse"
                    x1={115.5}
                    y1={148.7}
                    x2={131.9}
                    y2={120.1}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_36"
                    gradientUnits="userSpaceOnUse"
                    x1={48.6}
                    y1={226.588}
                    x2={62.7}
                    y2={202.213}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_37"
                    gradientUnits="userSpaceOnUse"
                    x1={79.95}
                    y1={186.8}
                    x2={102.45}
                    y2={158.1}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_38"
                    gradientUnits="userSpaceOnUse"
                    x1={47.45}
                    y1={188.713}
                    x2={67.05}
                    y2={159.987}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_39"
                    gradientUnits="userSpaceOnUse"
                    x1={12.012}
                    y1={229.688}
                    x2={27.887}
                    y2={202.012}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_40"
                    gradientUnits="userSpaceOnUse"
                    x1={11.613}
                    y1={191.65}
                    x2={25.387}
                    y2={167.85}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_41"
                    gradientUnits="userSpaceOnUse"
                    x1={-29.8}
                    y1={235.988}
                    x2={-9.9}
                    y2={203.012}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_42"
                    gradientUnits="userSpaceOnUse"
                    x1={-34.088}
                    y1={197.5}
                    x2={-7.113}
                    y2={163.1}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_43"
                    gradientUnits="userSpaceOnUse"
                    x1={-32.212}
                    y1={154.925}
                    x2={-7.787}
                    y2={123.475}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_44"
                    gradientUnits="userSpaceOnUse"
                    x1={12}
                    y1={151.913}
                    x2={26.3}
                    y2={127.188}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_45"
                    gradientUnits="userSpaceOnUse"
                    x1={82.888}
                    y1={150.137}
                    x2={99.013}
                    y2={122.262}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_46"
                    gradientUnits="userSpaceOnUse"
                    x1={50}
                    y1={149.025}
                    x2={64.6}
                    y2={123.775}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_47"
                    gradientUnits="userSpaceOnUse"
                    x1={48.862}
                    y1={113.563}
                    x2={65.237}
                    y2={85.238}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_48"
                    gradientUnits="userSpaceOnUse"
                    x1={47.65}
                    y1={78.238}
                    x2={63.65}
                    y2={50.563}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_49"
                    gradientUnits="userSpaceOnUse"
                    x1={10.388}
                    y1={116.088}
                    x2={27.313}
                    y2={86.813}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_50"
                    gradientUnits="userSpaceOnUse"
                    x1={-31.325}
                    y1={113.975}
                    x2={-8.375}
                    y2={83.925}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_51"
                    gradientUnits="userSpaceOnUse"
                    x1={10.463}
                    y1={77.488}
                    x2={28.138}
                    y2={46.913}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_52"
                    gradientUnits="userSpaceOnUse"
                    x1={-33.788}
                    y1={75.213}
                    x2={-6.813}
                    y2={44.288}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_53"
                    gradientUnits="userSpaceOnUse"
                    x1={7.8}
                    y1={39.125}
                    x2={28.8}
                    y2={8.375}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_54"
                    gradientUnits="userSpaceOnUse"
                    x1={-32.775}
                    y1={35.3}
                    x2={-7.825}
                    y2={2.9}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_55"
                    gradientUnits="userSpaceOnUse"
                    x1={14.375}
                    y1={0.575}
                    x2={30.925}
                    y2={-15.975}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_56"
                    gradientUnits="userSpaceOnUse"
                    x1={47.662}
                    y1={39.913}
                    x2={66.138}
                    y2={7.987}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_57"
                    gradientUnits="userSpaceOnUse"
                    x1={51.3}
                    y1={2.05}
                    x2={70.3}
                    y2={-16.95}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_58"
                    gradientUnits="userSpaceOnUse"
                    x1={114.3}
                    y1={115.7}
                    x2={131}
                    y2={86.6}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_59"
                    gradientUnits="userSpaceOnUse"
                    x1={80.15}
                    y1={79.112}
                    x2={99.25}
                    y2={51.388}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_60"
                    gradientUnits="userSpaceOnUse"
                    x1={114.038}
                    y1={80.8}
                    x2={130.263}
                    y2={52.5}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_61"
                    gradientUnits="userSpaceOnUse"
                    x1={88.75}
                    y1={1.763}
                    x2={97.85}
                    y2={-14.063}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_62"
                    gradientUnits="userSpaceOnUse"
                    x1={80.625}
                    y1={41.163}
                    x2={100.375}
                    y2={12.237}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_63"
                    gradientUnits="userSpaceOnUse"
                    x1={116.263}
                    y1={40.388}
                    x2={130.137}
                    y2={16.412}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_64"
                    gradientUnits="userSpaceOnUse"
                    x1={117.438}
                    y1={9.075}
                    x2={129.563}
                    y2={-11.875}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_65"
                    gradientUnits="userSpaceOnUse"
                    x1={145.725}
                    y1={113.975}
                    x2={159.575}
                    y2={90.025}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_66"
                    gradientUnits="userSpaceOnUse"
                    x1={229.387}
                    y1={206.625}
                    x2={245.613}
                    y2={184.975}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_67"
                    gradientUnits="userSpaceOnUse"
                    x1={230.263}
                    y1={114.088}
                    x2={241.838}
                    y2={93.912}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_68"
                    gradientUnits="userSpaceOnUse"
                    x1={291.175}
                    y1={206.775}
                    x2={279.025}
                    y2={182.725}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_69"
                    gradientUnits="userSpaceOnUse"
                    x1={310.05}
                    y1={210.25}
                    x2={302.35}
                    y2={187.95}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_70"
                    gradientUnits="userSpaceOnUse"
                    x1={291.275}
                    y1={177.05}
                    x2={279.525}
                    y2={152.45}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_71"
                    gradientUnits="userSpaceOnUse"
                    x1={311.688}
                    y1={181.088}
                    x2={301.013}
                    y2={154.013}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_72"
                    gradientUnits="userSpaceOnUse"
                    x1={254.912}
                    y1={201.8}
                    x2={272.387}
                    y2={183.3}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_73"
                    gradientUnits="userSpaceOnUse"
                    x1={255.637}
                    y1={175.287}
                    x2={269.262}
                    y2={151.513}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_74"
                    gradientUnits="userSpaceOnUse"
                    x1={291.15}
                    y1={145.412}
                    x2={279.75}
                    y2={121.588}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_75"
                    gradientUnits="userSpaceOnUse"
                    x1={255.45}
                    y1={144.6}
                    x2={268.35}
                    y2={122.3}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_76"
                    gradientUnits="userSpaceOnUse"
                    x1={312.337}
                    y1={148.537}
                    x2={301.563}
                    y2={123.463}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_77"
                    gradientUnits="userSpaceOnUse"
                    x1={230.35}
                    y1={145.212}
                    x2={244.25}
                    y2={121.187}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_78"
                    gradientUnits="userSpaceOnUse"
                    x1={205.013}
                    y1={208.35}
                    x2={218.787}
                    y2={184.55}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_79"
                    gradientUnits="userSpaceOnUse"
                    x1={200.625}
                    y1={146.888}
                    x2={218.075}
                    y2={124.213}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_80"
                    gradientUnits="userSpaceOnUse"
                    x1={229.1}
                    y1={175.863}
                    x2={246.5}
                    y2={152.637}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_81"
                    gradientUnits="userSpaceOnUse"
                    x1={144.088}
                    y1={181.95}
                    x2={163.013}
                    y2={156.45}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_82"
                    gradientUnits="userSpaceOnUse"
                    x1={202.575}
                    y1={178.25}
                    x2={220.425}
                    y2={152.35}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_83"
                    gradientUnits="userSpaceOnUse"
                    x1={175.725}
                    y1={213.012}
                    x2={189.675}
                    y2={188.688}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_84"
                    gradientUnits="userSpaceOnUse"
                    x1={172.487}
                    y1={181.225}
                    x2={193.412}
                    y2={153.775}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_85"
                    gradientUnits="userSpaceOnUse"
                    x1={144.063}
                    y1={148.475}
                    x2={161.938}
                    y2={122.525}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_86"
                    gradientUnits="userSpaceOnUse"
                    x1={174.612}
                    y1={148.238}
                    x2={189.287}
                    y2={122.863}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_87"
                    gradientUnits="userSpaceOnUse"
                    x1={203.925}
                    y1={113.3}
                    x2={215.475}
                    y2={93.3}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_88"
                    gradientUnits="userSpaceOnUse"
                    x1={144.112}
                    y1={42.388}
                    x2={163.787}
                    y2={17.712}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_89"
                    gradientUnits="userSpaceOnUse"
                    x1={203.575}
                    y1={47.563}
                    x2={217.825}
                    y2={22.938}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_90"
                    gradientUnits="userSpaceOnUse"
                    x1={201.7}
                    y1={80.763}
                    x2={217.9}
                    y2={59.138}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_91"
                    gradientUnits="userSpaceOnUse"
                    x1={175.763}
                    y1={113.213}
                    x2={188.838}
                    y2={90.387}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_92"
                    gradientUnits="userSpaceOnUse"
                    x1={143.4}
                    y1={80.138}
                    x2={161.8}
                    y2={54.663}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="91.765%" stopColor="#E6E6E6" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_93"
                    gradientUnits="userSpaceOnUse"
                    x1={174.863}
                    y1={81.013}
                    x2={189.738}
                    y2={55.288}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_94"
                    gradientUnits="userSpaceOnUse"
                    x1={174.938}
                    y1={46.425}
                    x2={189.363}
                    y2={21.475}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_95"
                    gradientUnits="userSpaceOnUse"
                    x1={148.537}
                    y1={8.963}
                    x2={162.662}
                    y2={-15.463}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_96"
                    gradientUnits="userSpaceOnUse"
                    x1={177.625}
                    y1={10.625}
                    x2={189.575}
                    y2={-10.025}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_97"
                    gradientUnits="userSpaceOnUse"
                    x1={204.3}
                    y1={14.262}
                    x2={214.8}
                    y2={-3.862}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_98"
                    gradientUnits="userSpaceOnUse"
                    x1={291.037}
                    y1={114.45}
                    x2={279.863}
                    y2={91.35}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_99"
                    gradientUnits="userSpaceOnUse"
                    x1={277.512}
                    y1={49.363}
                    x2={292.488}
                    y2={28.837}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_100"
                    gradientUnits="userSpaceOnUse"
                    x1={231.175}
                    y1={47.775}
                    x2={244.225}
                    y2={25.225}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_101"
                    gradientUnits="userSpaceOnUse"
                    x1={312.875}
                    y1={114.438}
                    x2={299.525}
                    y2={89.363}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_102"
                    gradientUnits="userSpaceOnUse"
                    x1={310.837}
                    y1={81.575}
                    x2={301.563}
                    y2={56.525}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_103"
                    gradientUnits="userSpaceOnUse"
                    x1={230.662}
                    y1={81.225}
                    x2={243.138}
                    y2={59.675}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_104"
                    gradientUnits="userSpaceOnUse"
                    x1={255.05}
                    y1={114.813}
                    x2={267.85}
                    y2={92.688}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_105"
                    gradientUnits="userSpaceOnUse"
                    x1={291.025}
                    y1={83.525}
                    x2={280.075}
                    y2={59.275}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_106"
                    gradientUnits="userSpaceOnUse"
                    x1={255.225}
                    y1={83.363}
                    x2={269.875}
                    y2={58.038}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_107"
                    gradientUnits="userSpaceOnUse"
                    x1={254.025}
                    y1={50.95}
                    x2={270.675}
                    y2={27.25}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_108"
                    gradientUnits="userSpaceOnUse"
                    x1={231.2}
                    y1={14.925}
                    x2={244}
                    y2={-7.425}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_109"
                    gradientUnits="userSpaceOnUse"
                    x1={269.5}
                    y1={17.388}
                    x2={256.6}
                    y2={-5.588}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_110"
                    gradientUnits="userSpaceOnUse"
                    x1={303.462}
                    y1={48.4}
                    x2={308.438}
                    y2={22.9}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_111"
                    gradientUnits="userSpaceOnUse"
                    x1={277}
                    y1={15.338}
                    x2={290.5}
                    y2={-4.638}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_112"
                    gradientUnits="userSpaceOnUse"
                    x1={302.712}
                    y1={13.913}
                    x2={307.688}
                    y2={-5.613}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_113"
                    gradientUnits="userSpaceOnUse"
                    x1={333.912}
                    y1={216.175}
                    x2={321.988}
                    y2={189.125}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_114"
                    gradientUnits="userSpaceOnUse"
                    x1={316.162}
                    y1={307.038}
                    x2={318.637}
                    y2={281.563}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_115"
                    gradientUnits="userSpaceOnUse"
                    x1={310.012}
                    y1={369.212}
                    x2={319.387}
                    y2={321.288}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_116"
                    gradientUnits="userSpaceOnUse"
                    x1={391.038}
                    y1={398.875}
                    x2={391.962}
                    y2={363.925}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_117"
                    gradientUnits="userSpaceOnUse"
                    x1={388.712}
                    y1={343.375}
                    x2={389.188}
                    y2={307.425}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_118"
                    gradientUnits="userSpaceOnUse"
                    x1={425.675}
                    y1={318.788}
                    x2={429.625}
                    y2={287.813}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_119"
                    gradientUnits="userSpaceOnUse"
                    x1={316.888}
                    y1={274.662}
                    x2={319.313}
                    y2={255.737}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_120"
                    gradientUnits="userSpaceOnUse"
                    x1={376.912}
                    y1={295.387}
                    x2={377.387}
                    y2={273.912}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_121"
                    gradientUnits="userSpaceOnUse"
                    x1={333.938}
                    y1={247.862}
                    x2={321.962}
                    y2={223.838}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_122"
                    gradientUnits="userSpaceOnUse"
                    x1={363.838}
                    y1={263.75}
                    x2={351.363}
                    y2={231.75}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_123"
                    gradientUnits="userSpaceOnUse"
                    x1={396.112}
                    y1={270.1}
                    x2={380.688}
                    y2={235.6}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_124"
                    gradientUnits="userSpaceOnUse"
                    x1={337.188}
                    y1={112.475}
                    x2={322.212}
                    y2={93.025}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_125"
                    gradientUnits="userSpaceOnUse"
                    x1={336.238}
                    y1={149.463}
                    x2={322.262}
                    y2={124.037}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_126"
                    gradientUnits="userSpaceOnUse"
                    x1={363.962}
                    y1={223.6}
                    x2={350.538}
                    y2={197.6}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_127"
                    gradientUnits="userSpaceOnUse"
                    x1={397.875}
                    y1={230.862}
                    x2={379.425}
                    y2={199.338}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_128"
                    gradientUnits="userSpaceOnUse"
                    x1={399.512}
                    y1={194.313}
                    x2={379.088}
                    y2={161.788}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_129"
                    gradientUnits="userSpaceOnUse"
                    x1={334.137}
                    y1={181.288}
                    x2={320.662}
                    y2={154.313}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_130"
                    gradientUnits="userSpaceOnUse"
                    x1={364.563}
                    y1={187.55}
                    x2={349.538}
                    y2={161.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_131"
                    gradientUnits="userSpaceOnUse"
                    x1={364.075}
                    y1={150.825}
                    x2={350.125}
                    y2={124.875}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_132"
                    gradientUnits="userSpaceOnUse"
                    x1={399.575}
                    y1={154.15}
                    x2={379.125}
                    y2={123.65}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_133"
                    gradientUnits="userSpaceOnUse"
                    x1={366.412}
                    y1={116.85}
                    x2={349.988}
                    y2={89.85}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_134"
                    gradientUnits="userSpaceOnUse"
                    x1={336.975}
                    y1={79.95}
                    x2={320.525}
                    y2={54.45}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_135"
                    gradientUnits="userSpaceOnUse"
                    x1={365.725}
                    y1={77.163}
                    x2={347.775}
                    y2={51.638}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_136"
                    gradientUnits="userSpaceOnUse"
                    x1={398.15}
                    y1={113.3}
                    x2={377.15}
                    y2={82.8}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_137"
                    gradientUnits="userSpaceOnUse"
                    x1={334.588}
                    y1={8.65}
                    x2={320.613}
                    y2={-7.35}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_138"
                    gradientUnits="userSpaceOnUse"
                    x1={335.813}
                    y1={43.525}
                    x2={322.888}
                    y2={22.075}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_139"
                    gradientUnits="userSpaceOnUse"
                    x1={399.113}
                    y1={75.45}
                    x2={379.088}
                    y2={47.45}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_140"
                    gradientUnits="userSpaceOnUse"
                    x1={365.612}
                    y1={42.763}
                    x2={350.688}
                    y2={16.737}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_141"
                    gradientUnits="userSpaceOnUse"
                    x1={399.1}
                    y1={35.438}
                    x2={378.7}
                    y2={7.462}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_142"
                    gradientUnits="userSpaceOnUse"
                    x1={361.375}
                    y1={6.088}
                    x2={350.425}
                    y2={-8.388}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_143"
                    gradientUnits="userSpaceOnUse"
                    x1={391.788}
                    y1={-0.037}
                    x2={372.813}
                    y2={-22.563}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#B7B7B7" />
                    <stop offset="46.275%" stopColor="#E5E5E5" />
                    <stop offset="81.961%" stopColor="#EEE" />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_144"
                    gradientUnits="userSpaceOnUse"
                    x1={266.988}
                    y1={124.912}
                    x2={123.712}
                    y2={130.588}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopOpacity={0.231} />
                    <stop offset="100%" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_145"
                    gradientUnits="userSpaceOnUse"
                    x1={207.488}
                    y1={242.125}
                    x2={200.813}
                    y2={326.575}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopOpacity={0.231} />
                    <stop offset="100%" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaInd024__Gradient_146"
                    gradientUnits="userSpaceOnUse"
                    x1={283.012}
                    y1={130.162}
                    x2={364.887}
                    y2={134.838}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopOpacity={0.243} />
                    <stop offset="100%" stopOpacity={0} />
                </linearGradient>
                <g id="PlaInd024__Layer6_0_FILL">
                    <path
                        fill="url(#PlaInd024__Gradient_1)"
                        d="M252.95 287.6q-22.55-11.3-48.05-20.65-34.05 2.95-65.65 9.95 22.55 11.2 41.25 23.35 33.4-8.2 72.45-12.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_2)"
                        d="M266.7 384.5q-.85.25-1.65.5h65.05l-18.55-10.6q-22.95 3.4-44.85 10.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_3)"
                        d="M179.95 384.5q.3.25.55.5h84.55q.8-.25 1.65-.5 21.9-6.7 44.85-10.1-35.45-23.6-76.7-43.4-47.55 4.7-95.2 21.05 21.85 15.7 40.3 32.45z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_4)"
                        d="M180.5 300.25q7.4 3.25 14.45 6.8 21.35 10.7 39.9 23.95 34.9-13.95 78.65-17.3-6.85-3.4-13.85-6.65-22.25-10.3-46.7-19.45-39.05 4.45-72.45 12.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_5)"
                        d="M196.95 246.4q-14.25 1.85-28.35 4.75l36.3 15.8q30.1-7.8 60.05-9.15-18.75-7.95-40.2-14.05-14 .8-27.8 2.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_6)"
                        d="M168.6 218.75q.45 2 .8 3.95 2.25 13.2 0 24.95-.35 1.75-.8 3.5 14.1-2.9 28.35-4.75 2.7-12.15 1.1-23.7-.5-3.5-1.35-6.9l-28.1 2.95z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_7)"
                        d="M224.85 213.2q-.05-.15-.1-.35l-28.05 2.95q.85 3.4 1.35 6.9 1.6 11.55-1.1 23.7 13.8-1.85 27.8-2.65 3.8-15.25.1-30.55z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_8)"
                        d="M252.95 287.6q31.55-7.6 65.1-11.3l-53.1-18.5q-29.95 1.35-60.05 9.15 25.5 9.35 48.05 20.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_9)"
                        d="M249.95 239.6q3.65-13.55.55-26.4-.35-1.5-.8-2.95l-24.95 2.6q.05.2.1.35 3.7 15.3-.1 30.55 12.5-2.5 25.2-4.15z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_10)"
                        d="M274.75 237.2q-1.65.1-3.3.25-10.8.75-21.5 2.15-12.7 1.65-25.2 4.15 21.45 6.1 40.2 14.05 25.75-5.45 51.5-7.9-10.3-4.05-21.15-7.3-10.05-3.05-20.55-5.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_11)"
                        d="M271.45 237.45q1.15-14.7-.05-29.45l-21.7 2.25q.45 1.45.8 2.95 3.1 12.85-.55 26.4 10.7-1.4 21.5-2.15z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_12)"
                        d="M295.3 242.6q-4.3-16 .25-30.25l-20.8-4.7q-2.95 14.1 0 29.55 10.5 2.35 20.55 5.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_13)"
                        d="M295.55 212.35q-4.55 14.25-.25 30.25 10.85 3.25 21.15 7.3-2.35-8.4-2.3-16.7 0-8.1 2.3-16.15l-20.9-4.7z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_14)"
                        d="M95.85 315q24.3 17.55 43.8 37.05 47.65-16.35 95.2-21.05-18.55-13.25-39.9-23.95-7.05-3.55-14.45-6.8-24.45 2.85-46.6 6.8-19.95 3.55-38.05 7.95z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_15)"
                        d="M87.1 307.05q4.45 3.95 8.75 7.95 18.1-4.4 38.05-7.95 22.15-3.95 46.6-6.8-18.7-12.15-41.25-23.35-37.4 3.8-74.9 11.45 11.9 9.1 22.75 18.7z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_16)"
                        d="M0 382.85l23.95-5.3Q18.8 367.5 12.8 357.8q-6-9.75-12.8-19.15v44.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_17)"
                        d="M23.95 377.55L28.7 385h151.8q-.25-.25-.55-.5-18.45-16.75-40.3-32.45-58.65 9-115.7 25.5z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_18)"
                        d="M0 385h28.7l-4.75-7.45L0 382.85V385z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_19)"
                        d="M12.8 328.6q-6.45 1.35-12.8 2.85v7.2q6.8 9.4 12.8 19.15 6 9.7 11.15 19.75 57.05-16.5 115.7-25.5-19.5-19.5-43.8-37.05-42.15 4.8-83.05 13.6z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_20)"
                        d="M74.5 261.9q1.4-7.1 1.55-14.25.15-9.5-1.9-19L40.3 232.2q1.65 7.5 1.85 15.45.25 9.9-1.85 20.35 17.15-3.9 34.2-6.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_21)"
                        d="M12.8 294.2q-6.45 1.05-12.8 2.3v34.95q6.35-1.5 12.8-2.85 40.9-8.8 83.05-13.6-4.3-4-8.75-7.95-10.85-9.6-22.75-18.7Q38 290 12.8 294.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_22)"
                        d="M12.8 269.65Q6.75 270.2.75 271q-.4.05-.75.1v25.4q6.35-1.25 12.8-2.3 25.2-4.2 51.55-5.85L40.3 268q-13.8.3-27.5 1.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_23)"
                        d="M40.3 232.2L.75 236.35V271q6-.8 12.05-1.35 13.7-1.35 27.5-1.65 2.1-10.45 1.85-20.35-.2-7.95-1.85-15.45z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_24)"
                        d="M.75 236.35l-.75.1v34.65q.35-.05.75-.1v-34.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_25)"
                        d="M139.25 276.9L108 259.15q-16.7.55-33.5 2.75-17.05 2.2-34.2 6.1l24.05 20.35q37.5-7.65 74.9-11.45z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_26)"
                        d="M138.6 253.85q-15.5 2-30.6 5.3l31.25 17.75q31.6-7 65.65-9.95l-36.3-15.8q-15.2.75-30 2.7z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_27)"
                        d="M108 259.15q1.3-5.8 1.75-11.5.9-11.5-1.75-22.55l-33.85 3.55q2.05 9.5 1.9 19-.15 7.15-1.55 14.25 16.8-2.2 33.5-2.75z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_28)"
                        d="M138.35 221.95q.1.4.15.75 2.45 12.45 1.05 24.95-.35 3.1-.95 6.2 14.8-1.95 30-2.7.45-1.75.8-3.5 2.25-11.75 0-24.95-.35-1.95-.8-3.95l-30.25 3.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_29)"
                        d="M138.5 222.7q-.05-.35-.15-.75L108 225.1q2.65 11.05 1.75 22.55-.45 5.7-1.75 11.5 15.1-3.3 30.6-5.3.6-3.1.95-6.2 1.4-12.5-1.05-24.95z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_30)"
                        d="M140 213.2q-.55 4.3-1.65 8.75l30.25-3.2q.5-2.8.8-5.55 1.6-13.95-.8-27.15l-30.25 2.05q3.3 12 1.65 25.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_31)"
                        d="M108 190.15l-33.85 2.25q2.5 10.4 2.15 20.8-.2 4.75-.95 9.5-.5 3-1.2 5.95L108 225.1l.6-2.4q1-4.7 1.35-9.5.85-11.15-1.95-23.05z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_32)"
                        d="M74.15 81.25q4.1 18.9 0 36.8H108q3.45-17.35 0-35.7l-33.85-1.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_33)"
                        d="M138.35 188.1L108 190.15q2.8 11.9 1.95 23.05-.35 4.8-1.35 9.5l-.6 2.4 30.35-3.15q1.1-4.45 1.65-8.75 1.65-13.1-1.65-25.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_34)"
                        d="M108 153.8q4.4 18.2 0 36.35l30.35-2.05q4.15-18.6 0-35.4L108 153.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_35)"
                        d="M140.05 140.8q.65-10.85-1.7-22.75H108q2.95 11.35 2.15 22.75-.45 6.5-2.15 13l30.35-1.1q1.3-5.75 1.7-11.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_36)"
                        d="M74.15 192.4l-33.85 2.2q2.5 8.95 2.45 18.6-.05 4.65-.65 9.5-.65 4.7-1.8 9.5l33.85-3.55q.7-2.95 1.2-5.95.75-4.75.95-9.5.35-10.4-2.15-20.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_37)"
                        d="M74.15 155.1q4.5 19.15 0 37.3l33.85-2.25q4.4-18.15 0-36.35l-33.85 1.3z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_38)"
                        d="M74.15 192.4q4.5-18.15 0-37.3l-33.85 1.25q5.8 18.65 0 38.25l33.85-2.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_39)"
                        d="M40.3 194.6L.75 197.3v39.05l39.55-4.15q1.15-4.8 1.8-9.5.6-4.85.65-9.5.05-9.65-2.45-18.6z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_40)"
                        d="M40.3 156.35l-39.55 1.5v39.45l39.55-2.7q5.8-19.6 0-38.25z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_41)"
                        d="M.75 197.3l-.75.05v39.1l.75-.1V197.3z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_42)"
                        d="M.75 157.85l-.75.05v39.45l.75-.05v-39.45z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_43)"
                        d="M.75 118.05H0v39.85l.75-.05v-39.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_44)"
                        d="M40.3 118.05H.75v39.8l39.55-1.5q2.3-7.6 2.65-15.55.5-11.05-2.65-22.75z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_45)"
                        d="M74.15 118.05q3.5 11.55 2.75 22.75-.5 7.25-2.75 14.3l33.85-1.3q1.7-6.5 2.15-13 .8-11.4-2.15-22.75H74.15z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_46)"
                        d="M76.9 140.8q.75-11.2-2.75-22.75H40.3q3.15 11.7 2.65 22.75-.35 7.95-2.65 15.55l33.85-1.25q2.25-7.05 2.75-14.3z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_47)"
                        d="M74.15 81.25L40.3 80.2q5.05 18.95 0 37.85h33.85q4.1-17.9 0-36.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_48)"
                        d="M74.15 81.25q2.1-12.35 1.55-23.85-.35-6.7-1.55-13.1L40.3 42.25q1.6 7.6 1.95 15.15.45 11.4-1.95 22.8l33.85 1.05z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_49)"
                        d="M40.3 80.2L.75 79v39.05H40.3q5.05-18.9 0-37.85z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_50)"
                        d="M.75 79H0v39.05h.75V79z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_51)"
                        d="M40.3 42.25L.75 39.9V79l39.55 1.2q2.4-11.4 1.95-22.8-.35-7.55-1.95-15.15z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_52)"
                        d="M.75 39.9L0 39.85V79h.75V39.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_53)"
                        d="M14.4 0H.75v39.9l39.55 2.35q4.4-19.45 0-39.85L14.4 0z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_54)"
                        d="M.75 0H0v39.85l.75.05V0z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_55)"
                        d="M40.3 2.4V0H14.4l25.9 2.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_56)"
                        d="M74.15 44.3q4.85-19.35 0-38.7L40.3 2.4q4.4 20.4 0 39.85l33.85 2.05z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_57)"
                        d="M74.15 5.6V0H40.3v2.4l33.85 3.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_58)"
                        d="M138.35 83.35l-30.35-1q3.45 18.35 0 35.7h30.35q3.85-16.35 0-34.7z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_59)"
                        d="M108 82.35q2.4-12.7 1.5-24.95-.4-5.55-1.5-11.05L74.15 44.3q1.2 6.4 1.55 13.1.55 11.5-1.55 23.85l33.85 1.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_60)"
                        d="M109.5 57.4q.9 12.25-1.5 24.95l30.35 1q3.65-13 1.95-25.95-.65-4.6-1.95-9.25L108 46.35q1.1 5.5 1.5 11.05z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_61)"
                        d="M108 8.75V0H74.15v5.6L108 8.75z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_62)"
                        d="M108 46.35q4.8-19.3 0-37.6L74.15 5.6q4.85 19.35 0 38.7L108 46.35z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_63)"
                        d="M138.35 48.15q4.3-17.3 0-36.5L108 8.75q4.8 18.3 0 37.6l30.35 1.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_64)"
                        d="M138.35 11.65V0H108v8.75l30.35 2.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_65)"
                        d="M168.6 118.05q4.5-16.85 0-33.8l-30.25-.9q3.85 18.35 0 34.7h30.25z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_66)"
                        d="M249.7 180.65l-24.95 1.65q3.7 13.85 0 30.55l24.95-2.6q4.2-13.9 0-29.6z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_67)"
                        d="M249.7 118.05q4.95-15.6 0-31.25l-24.95-.8q4.9 15.05 0 32.05h24.95z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_68)"
                        d="M295.55 182.1l-20.8-3.15q-2 14.35 0 28.7l20.8 4.7q-3.5-15.6 0-30.25z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_69)"
                        d="M316.45 185.25l-20.9-3.15q-3.5 14.65 0 30.25l20.9 4.7q-5.3-17.35 0-31.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_70)"
                        d="M295.55 182.1q-3.9-15.4 0-32.8l-20.8-1.65q-1.3 15.9 0 31.3l20.8 3.15z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_71)"
                        d="M316.45 151.1l-20.9-1.8q-3.9 17.4 0 32.8l20.9 3.15q-3.9-17.05 0-34.15z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_72)"
                        d="M271.4 179.2l-21.7 1.45q4.2 15.7 0 29.6l21.7-2.25q1.25-14.45 0-28.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_73)"
                        d="M249.7 148.6q4.25 14.6 0 32.05l21.7-1.45q.5-15.45 0-31.4l-21.7.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_74)"
                        d="M274.75 118.05q-1.95 15.5 0 29.6l20.8 1.65q-3.6-15.6 0-31.25h-20.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_75)"
                        d="M271.4 118.05h-21.7q3.45 14.4 0 30.55l21.7-.8q.7-14.7 0-29.75z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_76)"
                        d="M316.45 118.05h-20.9q-3.6 15.65 0 31.25l20.9 1.8q-5.5-16.95 0-33.05z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_77)"
                        d="M224.75 118.05q3.95 16.7 0 31.4l24.95-.85q3.45-16.15 0-30.55h-24.95z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_78)"
                        d="M224.75 182.3l-28.05 1.9q3.7 14.1.6 29l-.6 2.6 28.05-2.95q3.7-16.7 0-30.55z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_79)"
                        d="M224.75 118.05H196.7q4.25 16.7 0 32.5l28.05-1.1q3.95-14.7 0-31.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_80)"
                        d="M224.75 149.45q3.5 15.95 0 32.85l24.95-1.65q4.25-17.45 0-32.05l-24.95.85z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_81)"
                        d="M168.6 186.05q3.65-16.75 0-34.45l-30.25 1.1q4.15 16.8 0 35.4l30.25-2.05z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_82)"
                        d="M196.7 150.55q4.25 18.25 0 33.65l28.05-1.9q3.5-16.9 0-32.85l-28.05 1.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_83)"
                        d="M196.7 184.2l-28.1 1.85q2.4 13.2.8 27.15-.3 2.75-.8 5.55l28.1-2.95.6-2.6q3.1-14.9-.6-29z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_84)"
                        d="M196.7 150.55l-28.1 1.05q3.65 17.7 0 34.45l28.1-1.85q4.25-15.4 0-33.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_85)"
                        d="M170.1 140.8q.8-11.15-1.5-22.75h-30.25q2.35 11.9 1.7 22.75-.4 6.15-1.7 11.9l30.25-1.1q1.1-5.3 1.5-10.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_86)"
                        d="M196.7 118.05h-28.1q2.3 11.6 1.5 22.75-.4 5.5-1.5 10.8l28.1-1.05q4.25-15.8 0-32.5z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_87)"
                        d="M224.75 86l-28.05-.85q4.35 16.5 0 32.9h28.05q4.9-17 0-32.05z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_88)"
                        d="M168.6 49.95q3.5-18.25 0-35.45l-30.25-2.85q4.3 19.2 0 36.5l30.25 1.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_89)"
                        d="M224.75 19.85L196.7 17.2q4 16.7 0 34.4l28.05 1.75q4.2-16.3 0-33.5z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_90)"
                        d="M197.8 57.4q2.05 13.85-1.1 27.75l28.05.85q3.45-16.35 0-32.65L196.7 51.6q.65 2.9 1.1 5.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_91)"
                        d="M196.7 85.15l-28.1-.9q4.5 16.95 0 33.8h28.1q4.35-16.4 0-32.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_92)"
                        d="M169.9 57.4q-.5-3.7-1.3-7.45l-30.25-1.8q1.3 4.65 1.95 9.25 1.7 12.95-1.95 25.95l30.25.9q2.95-13.45 1.3-26.85z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_93)"
                        d="M196.7 51.6l-28.1-1.65q.8 3.75 1.3 7.45 1.65 13.4-1.3 26.85l28.1.9q3.15-13.9 1.1-27.75-.45-2.9-1.1-5.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_94)"
                        d="M196.7 17.2l-28.1-2.7q3.5 17.2 0 35.45l28.1 1.65q4-17.7 0-34.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_95)"
                        d="M168.6 14.5q2-7.25 1.95-14.5h-32.2v11.65l30.25 2.85z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_96)"
                        d="M198.95 0h-28.4q.05 7.25-1.95 14.5l28.1 2.7q2.6-8.25 2.25-17.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_97)"
                        d="M224.75 19.85Q227.4 9.9 226.9 0h-27.95q.35 8.95-2.25 17.2l28.05 2.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_98)"
                        d="M295.55 86.25l-20.8 1.4q-1.85 14.6 0 30.4h20.8q-4.4-14.9 0-31.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_99)"
                        d="M274.75 24.5v31.8l20.8-2.85q-3.7-17.6 0-33.3l-20.8 4.35z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_100)"
                        d="M249.7 54.8q4.75-15.35 0-32.65l-24.95-2.3q4.2 17.2 0 33.5l24.95 1.45z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_101)"
                        d="M316.45 84.85l-20.9 1.4q-4.4 16.9 0 31.8h20.9q-4.15-15.2 0-33.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_102)"
                        d="M295.55 53.45q-3.75 16.85 0 32.8l20.9-1.4q-5.5-13 0-34.2l-20.9 2.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_103)"
                        d="M249.7 86.8q4.25-16.95 0-32l-24.95-1.45q3.45 16.3 0 32.65l24.95.8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_104)"
                        d="M271.4 87.55l-21.7-.75q4.95 15.65 0 31.25h21.7q.9-15.4 0-30.5z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_105)"
                        d="M274.75 56.3q-1.45 14.95 0 31.35l20.8-1.4q-3.75-15.95 0-32.8l-20.8 2.85z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_106)"
                        d="M271.4 56.1l-21.7-1.3q4.25 15.05 0 32l21.7.75V56.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_107)"
                        d="M271.4 24.2l-21.7-2.05q4.75 17.3 0 32.65l21.7 1.3q.45-15.8 0-31.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_108)"
                        d="M249.7 22.15Q251.8 11.1 251.3 0h-24.4q.5 9.9-2.15 19.85l24.95 2.3z"
                    />
                    <path
                        fill="#959595"
                        d="M274.75 0h-2.6q.25 12.1-.75 24.2.45 16.1 0 31.9v31.45q.9 15.1 0 30.5.7 15.05 0 29.75.5 15.95 0 31.4 1.25 14.35 0 28.8 1.2 14.75.05 29.45 1.65-.15 3.3-.25-2.95-15.45 0-29.55-2-14.35 0-28.7-1.3-15.4 0-31.3-1.95-14.1 0-29.6-1.85-15.8 0-30.4-1.45-16.4 0-31.35V0z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_109)"
                        d="M272.15 0H251.3q.5 11.1-1.6 22.15l21.7 2.05q1-12.1.75-24.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_110)"
                        d="M316.45 15.8l-20.9 4.35q-3.7 15.7 0 33.3l20.9-2.8q-4.7-17.9 0-34.85z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_111)"
                        d="M295.55 20.15q-2.5-9.5-1.85-20.15h-18.95v24.5l20.8-4.35z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_112)"
                        d="M314.7 0h-21q-.65 10.65 1.85 20.15l20.9-4.35Q314.5 7.9 314.7 0z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_113)"
                        d="M339.8 222.3q-4.3-16.75 0-33.5l-23.35-3.55q-5.3 14.45 0 31.8l23.35 5.25z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_114)"
                        d="M332.3 307.05q22.5-6.55 49.7-8.45-30.1-12.6-63.95-22.3-33.55 3.7-65.1 11.3 24.45 9.15 46.7 19.45 7 3.25 13.85 6.65 8.9-3.75 18.8-6.65z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_115)"
                        d="M385 351v-7.25q-34.7-16.8-71.5-30.05-43.75 3.35-78.65 17.3 41.25 19.8 76.7 43.4 29.7-16.6 73.45-23.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_116)"
                        d="M385 385v-34q-43.75 6.8-73.45 23.4L330.1 385H385z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_117)"
                        d="M385 299.4l-3-.8q-27.2 1.9-49.7 8.45-9.9 2.9-18.8 6.65 36.8 13.25 71.5 30.05V299.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_118)"
                        d="M385 299.4v-1.45l-3 .65 3 .8z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_119)"
                        d="M371.9 266.9q-15.55-5.95-32.25-10.8-11.4-3.35-23.2-6.2-25.75 2.45-51.5 7.9l53.1 18.5q24.3-6.45 53.85-9.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_120)"
                        d="M385 269.75q-6.45-1.55-13.1-2.85-29.55 2.95-53.85 9.4Q351.9 286 382 298.6l3-.65v-28.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_121)"
                        d="M338.55 233.2q.35-5.5 1.25-10.9l-23.35-5.25q-2.3 8.05-2.3 16.15-.05 8.3 2.3 16.7 11.8 2.85 23.2 6.2-1.8-11.7-1.1-22.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_122)"
                        d="M370.9 234.65q.1-.75.25-1.45.35-1.8.75-3.6l-32.1-7.3q-.9 5.4-1.25 10.9-.7 11.2 1.1 22.9 16.7 4.85 32.25 10.8-3.4-17.2-1-32.25z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_123)"
                        d="M385 232.55l-13.1-2.95q-.4 1.8-.75 3.6-.15.7-.25 1.45-2.4 15.05 1 32.25 6.65 1.3 13.1 2.85v-37.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_124)"
                        d="M339.8 118.05q-3.2-16.9 0-34.8l-23.35 1.6q-4.15 18 0 33.2h23.35z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_125)"
                        d="M339.8 153.05q-3.95-18.45 0-35h-23.35q-5.5 16.1 0 33.05l23.35 1.95z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_126)"
                        d="M371.9 193.6l-32.1-4.8q-4.3 16.75 0 33.5l32.1 7.3q-.6-2.95-1-5.8-1.9-13.3 0-25.1.4-2.6 1-5.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_127)"
                        d="M385 195.6l-13.1-2q-.6 2.5-1 5.1-1.9 11.8 0 25.1.4 2.85 1 5.8l13.1 2.95V195.6z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_128)"
                        d="M385 156.75l-13.1-1.1q-.6 2.95-1 5.9-1.95 13.85 0 26.7.4 2.7 1 5.35l13.1 2v-38.85z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_129)"
                        d="M339.8 188.8q-4.45-16.9 0-35.75l-23.35-1.95q-3.9 17.1 0 34.15l23.35 3.55z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_130)"
                        d="M339.8 153.05q-4.45 18.85 0 35.75l32.1 4.8q-.6-2.65-1-5.35-1.95-12.85 0-26.7.4-2.95 1-5.9l-32.1-2.6z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_131)"
                        d="M370.9 122.35q.45-2.15 1-4.3h-32.1q-3.95 16.55 0 35l32.1 2.6q-.55-2.35-1-4.7-2.75-14.8 0-28.6z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_132)"
                        d="M385 118.05h-13.1q-.55 2.15-1 4.3-2.75 13.8 0 28.6.45 2.35 1 4.7l13.1 1.1v-38.7z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_133)"
                        d="M371.9 81.05l-32.1 2.2q-3.2 17.9 0 34.8h32.1q-.15-1.15-.3-2.25-2.4-18.2.3-34.75z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_134)"
                        d="M339.8 83.25q-3.45-17.85 0-35.7l-23.35 3.1q-5.5 21.2 0 34.2l23.35-1.6z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_135)"
                        d="M339.8 47.55q-3.45 17.85 0 35.7l32.1-2.2q-3.9-18.9 0-37.9l-32.1 4.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_136)"
                        d="M385 80.2l-13.1.85q-2.7 16.55-.3 34.75.15 1.1.3 2.25H385V80.2z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_137)"
                        d="M339.8 10.9q-1.7-5.7-1.95-10.9H314.7q-.2 7.9 1.75 15.8l23.35-4.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_138)"
                        d="M339.8 47.55q-3.75-18.8 0-36.65l-23.35 4.9q-4.7 16.95 0 34.85l23.35-3.1z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_139)"
                        d="M385 41.4l-13.1 1.75q-3.9 19 0 37.9l13.1-.85V41.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_140)"
                        d="M371.9 43.15q-4.2-19.5 0-38.95l-32.1 6.7q-3.75 17.85 0 36.65l32.1-4.4z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_141)"
                        d="M385 1.45L371.9 4.2q-4.2 19.45 0 38.95L385 41.4V1.45z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_142)"
                        d="M371.9 4.2q-.7-2.15-1.1-4.2h-32.95q.25 5.2 1.95 10.9l32.1-6.7z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_143)"
                        d="M385 1.45V0h-14.2q.4 2.05 1.1 4.2L385 1.45z"
                    />
                </g>
                <g id="PlaInd024__Layer6_0_MEMBER_0_FILL">
                    <path
                        fill="url(#PlaInd024__Gradient_144)"
                        d="M271.4 24.2q1-12.1.75-24.2H0v247.65h.75V271q6-.8 12.05-1.35 13.7-1.35 27.5-1.65 17.15-3.9 34.2-6.1 16.8-2.2 33.5-2.75 15.1-3.3 30.6-5.3 14.8-1.95 30-2.7 14.1-2.9 28.35-4.75 13.8-1.85 27.8-2.65 12.5-2.5 25.2-4.15 10.7-1.4 21.5-2.15 1.15-14.7-.05-29.45 1.25-14.45 0-28.8.5-15.45 0-31.4.7-14.7 0-29.75.9-15.4 0-30.5V56.1q.45-15.8 0-31.9z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_145)"
                        d="M385 269.75q-6.45-1.55-13.1-2.85-15.55-5.95-32.25-10.8-11.4-3.35-23.2-6.2-10.3-4.05-21.15-7.3-10.05-3.05-20.55-5.4-1.65.1-3.3.25-10.8.75-21.5 2.15-12.7 1.65-25.2 4.15-14 .8-27.8 2.65-14.25 1.85-28.35 4.75-15.2.75-30 2.7-15.5 2-30.6 5.3-16.7.55-33.5 2.75-17.05 2.2-34.2 6.1-13.8.3-27.5 1.65Q6.75 270.2.75 271q-.4.05-.75.1v113.4h385V269.75z"
                    />
                    <path
                        fill="url(#PlaInd024__Gradient_146)"
                        d="M371.9 266.9q6.65 1.3 13.1 2.85V0H274.75v56.3q-1.45 14.95 0 31.35-1.85 14.6 0 30.4-1.95 15.5 0 29.6-1.3 15.9 0 31.3-2 14.35 0 28.7-2.95 14.1 0 29.55 10.5 2.35 20.55 5.4 10.85 3.25 21.15 7.3 11.8 2.85 23.2 6.2 16.7 4.85 32.25 10.8z"
                    />
                </g>
                <path
                    fill="#A7A7A7"
                    d="M385 385V0H0v385h385z"
                    id="PlaInd024__Layer7_0_FILL"
                />
            </defs>
            <use xlinkHref="#PlaInd024__Layer7_0_FILL" />
            <use xlinkHref="#PlaInd024__Layer6_0_FILL" />
            <use xlinkHref="#PlaInd024__Layer6_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default PlaInd024
