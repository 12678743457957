import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00",
    "col2n": "#43A6FF"
}

function AccHat120({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M169.45 48.55V39.6h.2l5.55-12.65q-6.05 6.95-15.65 8.55-8.45-5.4-10.95-12.8-1.05 7.3-10.3 10-9.2-3.6-11.6-13-2.95 7.1-11.9 8.25-6.05-5.85-6.25-11.75-.3.5-.65 1-4.2 5.85-11.45 6.9Q89.5 12.8 87.7 0h-.15q-1.7 12.65-8.4 24.7-8.1-1.8-11.5-8.5-1.05 7.95-8 12-7.65-2.05-10.4-9-1.9 8.05-11.35 12.75-8.75-.95-11.05-9.25-1.15 7.7-9.95 12.55Q8 34.05 0 26.7l6.55 15.05L7 48.3q52.1 5.35 100.9 4.3 31.45-.7 61.55-4.05M95.75 29.3v.05h-.15l.15-.05m0 .85V31.05q-.05-.45-.15-.8v-.05l.15-.05m8.8 8.3l.05-.1.4.1h-.45m0-4.95q.05 0 .15.05h-.15v-.05m-54.35.3q.35-.1.85-.05h.05l-.05.2q-.4-.1-.8-.15h-.05m.8 4.75l.05.15q-.3 0-.55-.05l.5-.1m20.9-4.65v.1q-.05-.05-.15-.05.05-.05.15-.05m2.4.1v-.25h.05l-.05.25m6-2.95v.4q-.05-.25-.05-.5h.05v.1z"
                    id="AccHat120__Symbol_466_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M90 6.45V6.4q-.5-2.35-2.15-4.05Q85.6 0 82.4 0t-5.5 2.35q-.85.9-1.35 1.9-.55 1.05-.75 2.2-.1.3-.1.7v.5q-.05.2-.05.4 0 1.2.3 2.3.5 1.9 1.95 3.4 2.3 2.35 5.5 2.35 1.05 0 2-.25 1.95-.5 3.45-2.1 2.3-2.35 2.3-5.7v-.8q-.05-.45-.15-.8m73.85 11.5V15.8Q134 18.35 102.3 18.95q-49 .8-102.3-3.2l.95 2.2Q53.1 22.1 102.3 21.2q31.35-.6 61.55-3.25z"
                    id="AccHat120__Symbol_405_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.35 3.9Q1 5.05 2.65 5.05q1.4 0 2.1-1 .5-.7.5-1.5Q5.25 0 2.65 0 0 0 0 2.55 0 3.3.35 3.9m26.8-.85q0-1.75-1.45-2.25-.4-.1-.8-.15h-.05q-.1-.05-.2-.05-2.5-.2-2.5 2.45 0 .7.35 1.35.65 1.15 2.15 1.15.25 0 .5-.05l.5-.1q.6-.3 1-.85.5-.75.5-1.5m20.75 0q0-1.65-1.35-2.2Q46.5.8 46.4.8q-.3-.1-.7-.15-.15-.05-.3-.05-2.5-.2-2.5 2.45 0 .7.35 1.35.65 1.15 2.15 1.15.3 0 .6-.05.25-.05.5-.15.05 0 .1-.05.5-.3.8-.75.5-.75.5-1.5M81 5.2q.7-.25 1.1-.9.35-.5.45-1 .05-.25.05-.5 0-.3-.05-.6Q82.3.8 80.75.45q-.3-.05-.65-.1-.4-.05-.75.05h-.15q-1.6.25-1.6 2.4 0 .7.35 1.35.45.8 1.3 1.05l.4.1h.45q.5 0 .9-.1m22.75-.9q.5-.75.5-1.5 0-2.55-2.5-2.55t-2.5 2.55q0 .65.4 1.35.7 1.15 2.1 1.15 1.3 0 2-1m22-1.5q0-2.55-2.5-2.55t-2.5 2.55q0 .65.4 1.35.7 1.15 2.1 1.15 1.3 0 2-1 .5-.75.5-1.5z"
                    id="AccHat120__Symbol_354_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M197.95 82.75q-.1-2.25-1.55-3.9-1.5-1.75-3.65-1.75-.8 0-1.45.25-1.25.4-2.25 1.5-1.4 1.65-1.5 3.9v.3q0 2.45 1.5 4.2.65.7 1.35 1.15 1.05.6 2.35.6 2.15 0 3.65-1.75.2-.2.35-.45 1.2-1.6 1.2-3.75v-.3z"
                    id="AccHat120__Layer7_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat120__Symbol_466_0_Layer0_0_FILL"
                transform="translate(104.9 51.3)"
                id="AccHat120__col1n"
            />
            <use
                xlinkHref="#AccHat120__Symbol_405_0_Layer0_0_FILL"
                transform="translate(110.5 75.1)"
                id="AccHat120__col1d"
            />
            <use
                xlinkHref="#AccHat120__Symbol_354_0_Layer0_0_FILL"
                transform="translate(130.25 84.45)"
                id="AccHat120__col2n"
            />
            <use xlinkHref="#AccHat120__Layer7_0_FILL" />
        </g></g>
    )
}

export default AccHat120
