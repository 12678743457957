import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#775C31",
    "col1n": "#A37831",
    "col1s": "#43341A",
    "col2d": "#EEAA00",
    "col2n": "#FFC400"
}

function AccMor016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.35 8.35v-4Q18.4 3.7 14.45 3 8.3 1.9 2.15.5 1.1.25 0 0v4q1.1.25 2.15.5Q8.3 5.9 14.45 7q3.95.75 7.9 1.35m79.6-3.55l-.7.1-6.4.8-1 .1q-2.4.3-4.8.5l-3.5.3q-.15 0-.2.05h-.25q-.4 0-.8.05-.2 0-.35.05H83.55q-.1 0-.2.05h-.55q-.3.05-.55.05l-1 .1q-.1-.05-.15 0-2.2.1-4.35.25-.5 0-1 .05v-.7H73.3v.8h-.85l-3.6.1q-.95 0-1.9.05h-5.1q-2.85-.05-3.4-.05-6.8-.15-13.6-.6-5.8-.4-11.6-1.05-2.2-.25-4.4-.55l-3.7-.5v4l3.7.5q2.2.3 4.4.55 5.8.65 11.6 1.05 6.8.45 13.6.6.6 0 3.25.05h7.15l3.6-.1q.45-.05.85-.05v.45h2.45v-.55q.5 0 1-.05 2.25-.1 4.5-.25h.35q.55-.05 1.15-.1h.45q.05-.05.15-.05h.45q.05-.05.15-.05h.1l1.1-.1h.5l3.4-.3 4.8-.5q.5-.1 1-.15l6.4-.8.7-.1v-4m22.9-.3l1.9-.4v-4l-1.9.4q-6.8 1.55-13.6 2.8-3.25.55-6.5 1.05v4q3.25-.5 6.5-1.05 6.8-1.25 13.6-2.8z"
                    id="AccMor016__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 .75V4.8q.45-.05.85-.05v-4H0m3.3-.1v4q.5 0 1-.05v-4q-.5 0-1 .05m10.85 1.4q0-1-.7-1.75-.2-.15-.35-.3-.15 0-.2.05h-.25q-.4 0-.8.05-.2 0-.35.05H11.1q-.1 0-.2.05h.3q.35.05.7.25.2.1.4.3.6.6.6 1.45t-.6 1.4q-.25.3-.6.45-.05 0-.05.05h-.05v.05l1.1-.1h.5q.15-.15.25-.3.7-.65.7-1.7z"
                    id="AccMor016__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1.25 1.25q.25-.2.25-.5T1.25.2q-.2-.2-.5-.2T.2.2Q0 .45 0 .75q0 .3.2.5.25.25.55.25.3 0 .5-.25m7.25 0q0 .3.2.5.25.25.55.25.3 0 .5-.25.25-.2.25-.5T9.75.7q-.2-.2-.5-.2T8.7.7q-.2.25-.2.55M24 .5q-.3 0-.55.2-.2.25-.2.55 0 .3.2.5.25.25.55.25.3 0 .5-.25.25-.2.25-.5T24.5.7q-.2-.2-.5-.2m10.25.9q.25-.2.25-.5t-.25-.55q-.2-.2-.5-.2t-.55.2Q33 .6 33 .9q0 .3.2.5.25.25.55.25.3 0 .5-.25z"
                    id="AccMor016__Symbol_40_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.75 1.4q0-.15-.05-.25Q7.7.3 6.7 0H2.8Q1.65 0 .8.95.8 1 .75 1q-.15.2-.25.4-.5.85-.5 1.95 0 1.2.6 2.05.1.1.2.25.8.95 2 1l3.95.05q.85-.15.95-.9v-.05q.1-.15.15-.35H2.5q-.25-.15-.45-.4-.3-.35-.4-.8h2.7q.6-.8 0-1.65H1.7q.1-.35.35-.65.15-.2.3-.3l.3-.2H7.75z"
                    id="AccMor016__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.15 0Q.1.1.1.25 0 .55 0 .9q0 .25.05.5 0 .15.05.25h.85Q.6.85.95 0h-.8z"
                    id="AccMor016__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor016__Symbol_114_0_Layer0_0_FILL"
                transform="translate(128.95 310.1)"
                id="AccMor016__col1n"
            />
            <use
                xlinkHref="#AccMor016__Symbol_67_0_Layer0_0_FILL"
                transform="translate(201.4 316.7)"
                id="AccMor016__col1d"
            />
            <use
                xlinkHref="#AccMor016__Symbol_40_0_Layer0_0_FILL"
                transform="translate(174.9 318.25)"
                id="AccMor016__col1s"
            />
            <use
                xlinkHref="#AccMor016__Symbol_20_0_Layer0_0_FILL"
                transform="translate(188.15 316.2)"
                id="AccMor016__col2n"
            />
            <use
                xlinkHref="#AccMor016__Symbol_4_0_Layer0_0_FILL"
                transform="translate(189.7 318.75)"
                id="AccMor016__col2d"
            />
        </g></g>
    )
}

export default AccMor016
