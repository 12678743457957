import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1l": "#E7D4B6",
    "col1n": "#E4B670"
}

function LegSki014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.6 2.5q-.45-1.3-.75-2.45l-3.6.8Q110.05 5 91.8 6.7 79.4 7.85 66.95 7.85 54 7.85 41 6.6 23.3 4.9 5.55.85 3.65.45 1.8 0q-.15.65-.6 2.5L0 7.35l.9.4Q3.95 9.1 7.1 10.3q.25.1.55.25h.1l.05.05q.6.2 1.25.45.35.1.75.25 4 1.45 8.2 2.7l2.35-3.35-.65 3.8q4.7 1.3 9.65 2.4h.1q.25.05.55.15.45.05.9.15.3.05.6.15l1.6.3q.35.05.7.15.1 0 .2.05.4.05.85.15.9.15 1.75.3.35.05.75.1 3.9.65 8 1.15l1.85-3.8v4.05q5.5.6 11.25.95h.65q.1 0 .2.05h.6q.5.05 2.2.25h1.5l5-.1v.05l1.9-.1 1.9-.05q1.65-.05 3.3-.15.4-.05.8-.05 1-.05.9-.15l.95-3.95 1.2 3.9q2.7-.25 5.35-.5.35-.05.75-.1.3-.05.6-.05l.8-.1q1-.15 1.95-.25.25-.05.65-.1 4.5-.55 9-1.35.25-.05.55-.1 1.65-.3 3.25-.6.15-.05.25-.05l-.45-3.9 1.55 3.65q1.85-.4 3.75-.8.25-.1.55-.15.25-.05.55-.15.35-.1.7-.15l1.9-.5q.3-.1.65-.15l.5-.1q.25-.1.55-.15 1.9-.55 3.85-1.15.3-.15.65-.2 2.9-.9 5.7-1.85l-.85-3.75 2.4 3.2q.2-.1.4-.15.35-.15.7-.25.35-.15.7-.25.3-.15.6-.25 1.15-.45 2.35-.95.3-.15.65-.25 0-.05.05-.05l1.8-.8h.05l.4-.2q.05 0 .1-.05.2-.1.45-.2l1.1-.5-1.45-4.65z"
                    id="LegSki014__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M129.9.1l.25-.1-1.25.4 1-.3M9.8 4h-.05q-.05-.05-.1-.05-.3-.15-.6-.2Q4.9 2.2.9.45L0 4.05l.9.4Q3.95 5.8 7.1 7q.25.1.55.25h.1l.05.05q.6.2 1.25.45.35.1.75.25 4 1.45 8.2 2.7l2.35-3.35Q14.95 5.85 9.8 4m35.55 12.2l1.85-3.8q-5-.55-9.85-1.35-.4-.05-.75-.1l-2.8-.5q-.35-.1-.7-.15-.9-.15-1.8-.35-.3-.05-.55-.1-.05-.05-.1-.05-5.25-1.1-10.3-2.45l-.65 3.8q4.7 1.3 9.65 2.4h.1q.25.05.55.15.45.05.9.15.3.05.6.15l1.6.3q.35.05.7.15.1 0 .2.05.4.05.85.15.9.15 1.75.3.35.05.75.1 3.9.65 8 1.15m23.25 1.45l1.9-.1 1.9-.05q1.65-.05 3.3-.15.4-.05.8-.05 1-.05.9-.15l.95-3.95q-.9.05-1.85.1-.4 0-.8.05-4.2.2-8.55.3H65.5l-.05.05-3.8-.15q-.9-.1-1.05-.1-.45 0-.9-.05h-.65q-6.05-.35-11.85-1v4.05q5.5.6 11.25.95h.65q.1 0 .2.05h.6q.5.05 2.2.25h1.5l5-.1v.05m33.85-3.7q.15-.05.25-.05l-.45-3.9q-1.5.25-3.05.55-.3.05-.55.1-4.8.85-9.65 1.5-.4 0-.65.05l-2.7.3q-.4.05-.75.1-3.25.35-6.55.6l1.2 3.9q2.7-.25 5.35-.5.35-.05.75-.1.3-.05.6-.05l.8-.1q1-.15 1.95-.25.25-.05.65-.1 4.5-.55 9-1.35.25-.05.55-.1 1.65-.3 3.25-.6m20.7-5.65l-.85-3.75q-2.4.85-4.85 1.6-.35.05-.65.2-2.45.75-4.9 1.4-.35.05-.65.15-1.6.4-3.15.8-.3.05-.55.15-2.25.5-4.5.95-.3.05-.6.15-.1 0-.2.05l1.55 3.65q1.85-.4 3.75-.8.25-.1.55-.15.25-.05.55-.15.35-.1.7-.15l1.9-.5q.3-.1.65-.15l.5-.1q.25-.1.55-.15 1.9-.55 3.85-1.15.3-.15.65-.2 2.9-.9 5.7-1.85m9.35-3.75q.2-.1.45-.2l1.1-.5-1.1-3.5q-.75.35-1.45.65-.05.05-.1.05l-.4.2h-.05q-1.25.5-2.5 1.05h-.05q-.35.15-.6.25l-2 .8q-.35.1-.7.25-.25.05-.5.15l-2.3.8 2.4 3.2q.2-.1.4-.15.35-.15.7-.25.35-.15.7-.25.3-.15.6-.25 1.15-.45 2.35-.95.3-.15.65-.25 0-.05.05-.05l1.8-.8h.05l.4-.2q.05 0 .1-.05z"
                    id="LegSki014__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 7q.25.1.55.25l2-3.3q-.3-.15-.6-.2Q1.2 4.9.4 6.35q-.2.3-.4.65m24.2 2.95q-.3-.05-.55-.1l-1.3 3.7q.25.05.55.15l1.3-3.75M55 17.7h.65l.3-4.1h-.6L55 17.7m-3-.3l.6-4H52l-.6 4h.6M82.55 16l-.65-3.85q-.4 0-.65.05l.65 3.9q.25-.05.65-.1m22-6.75l.2.7q.3.9.55 1.7.25-.1.55-.15-.25-.9-.5-1.7-.3-1.1-.55-2.05-.35.05-.65.15.2.65.4 1.35m-3.05 1.3q0-.1-.05-.2-.2-.75-.45-1.65-.3.05-.55.15.2.8.5 1.8.25.9.6 1.9.35-.1.7-.15-.35-.6-.75-1.85M122.8.1l.25-.1-1.25.4 1-.3m1.1 1.15h-.05l1 3.55h.05l.4-.2-1-3.55-.4.2z"
                    id="LegSki014__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki014__Symbol_80_0_Layer0_0_FILL"
                transform="translate(125.55 307.2)"
                id="LegSki014__col1n"
            />
            <use
                xlinkHref="#LegSki014__Symbol_63_0_Layer0_0_FILL"
                transform="translate(125.55 310.5)"
                id="LegSki014__col1l"
            />
            <use
                xlinkHref="#LegSki014__Symbol_44_0_Layer0_0_FILL"
                transform="translate(132.65 310.5)"
                id="LegSki014__col1d"
            />
        </g></g>
    )
}

export default LegSki014
