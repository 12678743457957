import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.75 2.8q-4.3.05-7.65-.6-3.4-.7-6.15-1.15l-1.3-.2Q15.9 0 13 0q-.75 0-1.1.1-.5.1-1.15.2-.7.05-3.75.6-3.05.5-4.8 1-1.75.45-2.2.9l.45.1q4.95-.55 7.75-.45 2.75.1 2.85.1h8q1.1 0 5.4.7 4.25.65 7.45.25 3.2-.45 3.85-.7z"
                    id="FacMou009__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou009__Symbol_34_0_Layer0_0_FILL"
                transform="translate(175.35 222.6)"
                id="FacMou009__col1n"
            />
        </g></g>
    )
}

export default FacMou009
