import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function ObjToy022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4.85 64l5.85-11.85q-.3.4-.6.75-3.4 3.9-7.6 3.65L0 61.7q-.2 5.55 4.85 2.3m21-42.5h-.05q4.9-9.55 10.25-18.85-1-3.5-4.8-2.35-9.2 17.8-18.35 35.65 1.2 3.2.95 7.15-.15 2.1-.65 4l1.7-3.45q0-.1-.05-.2-.25-1-.25-2.1 0-1.95.85-3.5.5-1.05 1.4-1.95 1.25-1.3 2.8-1.8l6.2-12.6z"
                    id="ObjToy022__Symbol_168_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M41.15 43.7l9.8-20.9L61.1 2.35 56.15 0 47.1 18.55l.25-.6L35.4 41.3l-13.7-6.5q-.25-.1-.45-.15-.45-.2-.9-.35l-.9-.35q-.8-.3-1.65-.6-7.6-2.65-11-3h-.55q-4-.1-5.7 5.2-.25.75-.35 1.7-.8 3.9 1.35 7.3l.6.9-.6-.75q.9 1.85 3 3.15.9.55 2 1.05 2 .8 4.7 1.35 10.45 1.55 21.8-.95.9-.25 1.75-.45.1 0 .15-.05 1.2-.3 2.15-.8 1-.5 1.75-1.15.25-.2.45-.4.15-.2.3-.35.55-.6.95-1.25.35-.55.6-1.15z"
                    id="ObjToy022__Symbol_169_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M37.7 16.45q-1.85.35-3.8.6-10.4 1.25-20.8-1.85-1.1-.1-3.1-1.5-3.05-1.15-3.45-6.85-.1-1.8 0-3.95.1-1.4.25-2.9h-.55q-4-.1-5.7 5.2Q.3 5.95.2 6.9q-.8 3.9 1.35 7.3l.6.9-.6-.75q.9 1.85 3 3.15.9.55 2 1.05 2 .8 4.7 1.35 10.45 1.55 21.8-.95.9-.25 1.75-.45.1 0 .15-.05 1.2-.3 2.15-.8 1-.5 1.75-1.15.25-.2.45-.4h-.05q-.75.2-1.55.35z"
                    id="ObjToy022__Symbol_170_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy022__Symbol_168_0_Layer0_0_FILL"
                transform="translate(109.6 250.25)"
                id="ObjToy022__col1n"
            />
            <use
                xlinkHref="#ObjToy022__Symbol_169_0_Layer0_0_FILL"
                transform="translate(53.45 311.95)"
                id="ObjToy022__col2n"
            />
            <use
                xlinkHref="#ObjToy022__Symbol_170_0_Layer0_0_FILL"
                transform="translate(53.45 342.3)"
                id="ObjToy022__col2d"
            />
        </g></g>
    )
}

export default ObjToy022
