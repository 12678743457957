import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C2FF66",
    "col1s": "#69AA07"
}

function ShiLon060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 45.75q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-.85-1.3-1.75-2.6-.35-.55-.7-1.05-.35-.4-.6-.8-.3-.45-.6-.8-.15-.2-.25-.35-.1-.15-.2-.25l-.2-.2-1.75 1.45q-1.1.85-2.25 1.7l-.4.3q-.1.05-.15.1l-1.55 1.15q-4.05 2.8-8.35 5.15-4.85 2.65-10 4.7l-.7.3q-.1 0-.25.1-.15 0-.25.1L82.35 40.8l-35-25.3q-.15-.1-.3-.15-.1-.05-.25-.1-6.65-2.65-12.85-6.3-2.7-1.65-5.3-3.45L27.1 4.35q-.05-.05-.15-.1-1.35-1-2.65-2L22.45.85q-.1.05-.15.15-.05.05-.05.1l-.05.05v.05l-.05.05q-.05 0-.05.05-.05 0-.05.05L22 1.4q-.15.2-.25.35-.25.35-.5.75-.65.85-1.25 1.75-1.75 2.55-3.4 5.3-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4-.5 2.2v.15q-.05.2-.1.45l-.15.6v.2q-.2.8-.35 1.6H.5q-.05.1-.05.2-.05.15-.05.3-.15.65-.25 1.3l-.1.45q-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.15-.3 11.55 3.6l.2.2q.45.4.9.9l-2.8 11.75q-.55 2.1-1.05 4.2-.25 1-.5 2.05-.05.2-.1.45-.1.25-.15.5-.05.2-.1.45-.05.1-.05.15l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 12.85 2.9 25.7 4.65 3.15.4 6.25.75 14.75 1.6 29.45 1.6 14.85 0 29.65-1.6 2.9-.3 5.85-.7 12.9-1.75 25.8-4.7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05L143 58.3q.45-.5.95-.95l.2-.2q4.4-3.85 11.5-3.55l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V57.05q0-.15-.05-.35l-.1-.5q-.05-.15-.05-.25l-.1-.7q-.05-.1-.05-.15-.15-.75-.25-1.45l-.1-.4q-.05-.2-.05-.4-.15-.6-.25-1.15-.1-.55-.2-1.05-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon060__Symbol_355_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M21.8 53.95q2.25 1.05.25-.95-.95-.75-1.85-1.3-9.45-6.05-19.35-.55l-.6.3v.15q-.05.2-.1.45l-.15.6v.15q.4-.25.85-.5 10.4-5.85 19.65.65l.4.3q.45.3.9.7M81.7 86.1q17.8 0 35.5-2.3 12.9-1.75 25.8-4.7 1.95-.4 3.9-.9h.15q.05-.05.1-.05l-.25-.9h-.1q-.05.05-.1.05l-3.7.8q-12.9 2.95-25.8 4.7-17.7 2.3-35.5 2.3T46 82.75Q33.15 81 20.3 78.1l-3.6-.8q-.05 0-.1-.05h-.15q-.05.2-.1.45-.05.1-.05.15l-.05.35h.1q.05.05.1.05 1.9.45 3.85.85Q33.15 82 46 83.75q17.9 2.35 35.7 2.35M142.05 3l.8-.6h.05q-.3-.45-.6-.8h-.05l-.5.4q-5.7 4.2-12.8 9.45l-47.2 33.7-49.9-35.6q-6.3-4.5-10.9-7.8-.25.35-.5.75l.4.3q4.4 3.3 10.3 7.7l50.6 36.1 48-34.2q6.8-5.25 12.3-9.4m21 48.6q-.15-.1-.3-.15-2.95-1.7-5.8-2.4h-.05q-7.2-1.7-14.15 2.9-.85.6-1.7 1.25l.25 1q.55-.45 1.1-.8v-.05q9.65-7.2 20.55-.8.25.15.55.3-.15-.6-.25-1.15l-.2-.1z"
                    id="ShiLon060__Symbol_354_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon060__Layer3_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon060__Symbol_355_0_Layer0_0_FILL"
                transform="translate(110 227.75)"
                id="ShiLon060__col1n"
            />
            <use
                xlinkHref="#ShiLon060__Symbol_354_0_Layer0_0_FILL"
                transform="translate(110.8 227.75)"
                id="ShiLon060__col1s"
            />
            <use xlinkHref="#ShiLon060__Layer3_0_FILL" />
        </g></g>
    )
}

export default ShiLon060
