import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400",
    "col2n": "#008300"
}

function ShiLon050({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-20.9-3.2-38.2-15.15L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon050__Symbol_321_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M103.85 15.25q6.35-4.2 10.45-8.5 3.05-3.2 5.3-6.75l-1.7 1.35q-1.35 1.05-2.8 2.1l-1.55 1.15q-11.4 7.85-24.35 11.95-8.95 2.8-18.6 3.8-.9.1-1.85.2-.5 0-1.05.05l-1.1.05q-1.3.1-2.65.15-.75 0-1.45.05h-5.15q-.3-.05-.6-.05h-.15l-2.75-.1q-.5 0-1.05-.05l-1.1-.05q-1.4-.1-2.8-.25l-.8-.1-3.6-.45q-.15-.05-.3-.05h-.15q-5.9-.8-14.25-3.35l-1.6-.5Q16.35 11.85 5.85 4.6L4.3 3.45Q2.85 2.4 1.5 1.35L0 .25q2.15 3.4 5.1 6.5 4.1 4.3 10.45 8.5 3.85 2.55 13.5 6.5 1.55.65 3.05 1.25 10.8 4.05 17.4 4.05h.15q2 0 3.7-.15 1.8-.2 3.25-.55 2.45-.65 3.05-2.05 0-.1.05-.2.45 1.55 3.1 2.25 1.05.25 2.3.45 2 .25 4.5.25h.15q6.6 0 17.35-4.05 1.55-.55 3.25-1.25 9.65-3.95 13.5-6.5z"
                    id="ShiLon050__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    d="M233.65 264.45q0-.2-.15-.35-.15-.15-.35-.15H203.9q-.205.005-.35.15-.15.15-.15.35v34.4q0 .2.15.35.15.15.35.15h29.25q.2 0 .35-.15.15-.15.15-.35v-34.4m-15.35 15.5h.25q.1-.05.2-.15l13.9-14.1v32.65H204.4V265.7l13.65 14.1.1.1q.05 0 .15.05m.1-1.2l-13.3-13.8H232l-13.6 13.8m-25.3-24.9q-.6-2.34-1.2 0v63.75h1.2v-63.75m55.5 10.4q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4m14.1-.4q-.15.1-.15.35v34.4q0 .2.15.35.15.15.35.15h29.25q.2 0 .35-.15.15-.15.15-.35v-34.4q0-.2-.15-.35-.15-.15-.35-.15h-29.25q-.205.005-.35.15m.85 34.25v-32.6l13.15 14.05.1.1q.05 0 .1.05h.3q.1-.05.2-.15l14.4-14.15v32.7h-28.25m27.55-33.4l-14 13.8-12.85-13.8h26.85z"
                    id="ShiLon050__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon050__Symbol_321_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon050__col1n"
            />
            <use
                xlinkHref="#ShiLon050__Symbol_216_0_Layer0_0_FILL"
                transform="translate(132.8 228.65)"
                id="ShiLon050__col2n"
            />
            <use xlinkHref="#ShiLon050__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon050
