import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M209.5 220q-1.25-1.3-6.05-2.55-.65-.15-1.35-.25-4.7-.65-9.6-.6v-.05q-4.921-.02-9.65.65-.64.115-1.3.25-4.8 1.25-6.05 2.55-.5.55-1.3 1.3-1.7 1.7-2.1 2.2-.5.6-.5 1.55 0 .382.3.9.065.05.1.1.358.532 1.05 1.2.85.85 1.85 1.65.45.4 1 .8 0 .05.1.1l.1.05.05.05q.05.05.15.1l.05.05q2.55 1.6 5.6 1.5.2-.05.45-.05 5.169-.35 10.1-.35 4.95 0 10.1.35.25 0 .45.05 3.05.1 5.6-1.5l.05-.05q.1-.05.15-.1l.05-.05.1-.05q.1-.05.1-.1.55-.4 1-.8 1-.8 1.85-1.65.7-.65 1.05-1.2.034-.05.05-.1.35-.534.35-.9 0-.95-.5-1.55-.4-.5-2.1-2.2-.8-.75-1.3-1.3z"
                    id="FacMou024__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M5.25 7.95v4h4.3v-4.1h-.4l-3.9.1m-2.85 4h2.05v-4q-2.2.05-4.4.15L0 10.4q.1 1.2 2.4 1.55m-2-7.6q2.05-.1 4.05-.2V1Q2.4 1.4.4 2.2v2.15M5.25.95v3.2q1.95-.1 3.9-.1.2-.05.4-.05V.3q-.2 0-.4.05-1.95.2-3.9.5v.1m9.8 6.85q-2.35 0-4.7.05v4.1l4.7-.05V7.8M10.35.2V4q2.35-.05 4.7-.05V0q-2.35 0-4.7.2m10.2 11.75v-4.1q-2.35-.05-4.7-.05v4.1l4.7.05m1.2-4.1h-.4v4.1h4.3v-4l-3.9-.1M20.55 4V.2Q18.2 0 15.85 0v3.95q2.35 0 4.7.05m5.1-3.05v-.1q-1.95-.3-3.9-.5-.2-.05-.4-.05V4q.2 0 .4.05 1.95 0 3.9.1V.95m5.25 9.45l-.05-2.3q-2.2-.1-4.4-.15v4h2.05q2.3-.35 2.4-1.55m-.4-6.05V2.2q-2-.8-4.05-1.2v3.15q2 .1 4.05.2z"
                    id="FacMou024__Symbol_49_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou024__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou024__Symbol_49_0_Layer0_0_FILL"
                transform="translate(177.05 217.95)"
                id="FacMou024__col1n"
            />
        </g></g>
    )
}

export default FacMou024
