import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M19.3 9.45L23.2 0l-7.25 1.5 3.35 7.95m-15.4 0L7.25 1.5 0 0l3.9 9.45z"
                    id="FacMou065__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    d="M205.9 224.85q-.342-.126-.7.05-12.13 5.61-25.4-.05-.337-.126-.7 0-.367.168-.55.5-.126.387 0 .75.168.366.5.5 14.08 5.99 26.95 0 .36-.162.5-.55.126-.343-.05-.7-.162-.36-.55-.5z"
                    id="FacMou065__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou065__Symbol_90_0_Layer0_0_FILL"
                transform="translate(180.9 226.65)"
                id="FacMou065__col1n"
            />
            <use xlinkHref="#FacMou065__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou065
