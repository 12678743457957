import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#411F00",
    "col1n": "#773900",
    "col2d": "#A37831",
    "col2n": "#D19532"
}

function ObjToo030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.4 0Q7 0 4.05 1.3 0 3.05 0 6.85q0 2.1.45 2.45.4 1.2.65 1.85L13.85 37.6q.6 0 1.2.05 4.1.35 8.05 3.35 3.9 3 5.3 6.9 1.45 3.8-.7 5.8-2.2 1.95-4.85 2.1l2.9 6.4q1.4 3.3 2.85 5.9.95 1.75 1.95 3.15.65.6 1.7 1.35.8.55 2.6.95 1.6.35 2.25.95.6.55 1.1.9 1.15.4 2.4.4 2.4 0 3.5-1.55.75-1.75.95-2.05 1.45-2.2 1.5-2.35.3-.6.3-1.85 0-2.95-2.3-6.15l-2.95-4.9-6.15-12.75q-1.15.35-2.45.35-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-3.15 2.25-5.4.6-.6 1.3-1.05L14.6 1.1q-.05.2-.05 0 0-.15-1.05-.6-1.1-.5-2.1-.5z"
                    id="ObjToo030__Symbol_190_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.85 3.5L14.6 1.1q-.05.2-.05 0 0-.15-1.05-.6-1.1-.5-2.1-.5Q7 0 4.05 1.3 0 3.05 0 6.85q0 2.1.45 2.45.4 1.2.65 1.85l.5 1.1q2.8-.55 4.2-1.75 1.5-1.65 2.4-2.65 2.3-2.5 7.65-4.35z"
                    id="ObjToo030__Symbol_128_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.5 17.6q-.2-.05-.4-.05l-.05-.05L16.8.9Q4.4-2.9 1.15 9.05q-3.9 10.4 5.55 12 4.6 11.35 6.4 13.25 1.75 1.9 1.45 1.25v-.05q.8 1.4 1.7 3.15 1.5 7.4 3.55 7.2 2.45 5.85 2.8 7.85.3 2 .35 2.05.2.55.45 1.1 0 .05.05.1.85 1.35 1.95 1.35h.1q.95-.05 2.9-1.9 1.95-1.95 2.15-2.1 1.5-1.25 3.2-2.1.8-.4 1.35-.5.45-.3 1.2-.4 1.65-.55 3.5-1.1h.1q.5-.2.55-.25.65-.3.75-.4.05-.05.05-.8 0-1.4-1-3.2-1.15-1.8-1.5-2.55l-.8-1.6-.95-1.9q-.55-1.25-1-2.7v-.35l-6.45-9.95q3.65-8-4.05-8.9M7.4 12.1q-.35-.1-.6-.15-.45-.15-.9-.4.7.25 1.5.55m23.1 32.2q-.05.05-.05.1-.1-.05-.25-.05.25-.05.3-.05z"
                    id="ObjToo030__Symbol_71_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M37 30.45q-.55-1.25-1-2.7v-.35q-.05 0-.1.05-12.9 1.7-16.1 9.3.45-1 1.45-1.5.45-.5 1.45-1.1 1.45-.9 3.1-1.65 1.65-.8 3.2-1.15 1.5-.4 4.25-.75v-.05h2.5v-.1H37M25.05 8.5q-.1-.05-.25-.1L11.75 6.1q-.8-.15-1.5-.25-2.2-.45-3.95-1-2.45-.75-3.8-2.3Q1.45 1.4 1.4.15 1.25.05 1.15 0q-3.9 10.4 5.55 12 .45.05.95.1 8.85-2.75 19.1-.35 2.7 3 2.8 5.7 3.65-8-4.05-8.9-.2-.05-.4-.05h-.05m-3.2 12q2.05-.4 4.95.95l.6.3q3.7 1.35 8.45 5.5.05.1.15.15l-6.45-9.95q-4.65-1.8-8.1-1.25-2.6.8-4.4 2.45-2.7 2.55-2.7 6.1 0 .65.2 1.7.8 1.4 1.7 3.15 0-.1.05-.15-.25-.35-.4-1-.05-1.5-.05-1.55 0-2.05 1.15-3.65 1.55-2.3 4.85-2.75z"
                    id="ObjToo030__Symbol_16_0_Layer0_0_FILL"
                />
                <g id="ObjToo030__Layer14_0_FILL">
                    <path
                        fill="#999"
                        d="M24.05 219.7q.122-.917.15-1.3-.193.2-.4.4v.05q.122.432.25.85m113.45-33.25v.05q.293.537.65 1.2-.038-.288-.1-.6-.262-.335-.55-.65m-17.7-12.85q-7.8-5.75-7.8-6.7v-.35q-.118-.072-.25-.15-2.8-1.65-5.25-4.1-2.8-2.95-5-6.2-4.45-2.35-9.25-4.55 16.5 24.1 0 52.4-.75 1.25-1.6 2.55-5.612 7.389-15.65 8.15-2.6.25-5.3.25-22.7-.3-52.05-23.85.65 3.8 1.35 7.5 1.2.8 1.7 2.55.9 2.85-.25 4.5l.3 1.1q.601.704 1.15 1.4.449.33.9.7 5.4 4.55 5.4 9.5 0 .5-.4 3.3 0 1.15 2.7 8.1 1.9 4.9 3.3 8.1 4.35 10.2 9.85 16.05 2.4 2.6 6.45 8.35 4.4 4.8 11.95 6.4 5.75 1.2 11.25 6.6 5.1 5 5.1 8.25 0 .176-.05.35.418.07.8.15 5.6 1.05 11.6 1.15-6.45-14.25.6-29.35.96-2.065 2.2-4.15 7.024-8.515 16.3-9.55.75-.1 1.45-.15 11.75-.85 32.05 12 1.95 1.95 3.95 4.15.2-.65.35-1.3.75-2.45 1.3-4.85-.85-.65-1.65-1.8-1.4-2.05-1.8-4.55-.3-2.25.05-4.05.171-.882.5-1.65-.614-1.842-1.05-5.4-1.3-10.65-1.45-11.3-.8-3.1-1.45-9.5-.65-6.2-1.55-9.4-2.75-9.7-8.55-18.9-3.3-5.15-12.2-11.75z"
                    />
                    <path
                        fill="#A37831"
                        d="M104.3 250q.017-.053.05-.1-.06 0-.3.05.127.02.25.05z"
                    />
                </g>
                <g id="ObjToo030__Layer14_1_FILL">
                    <path
                        fill="#BFBFBF"
                        d="M23.8 218.85q1.75 6.2 3.8 11.8.6 1.55 1.15 3.1 5.15 3.35 5.85 13.1h.05q3.55.45 2.35 4.3 8.35 5.6 10.55 14.6 3.95.1 7.3 2.35 3.35 2.2 5.05 4.75 10.55.85 12 5.9.15-.05.2-.05 3.3-.05 4.6 3.15.4.9.55 1.7l1.9.4-7.25-18.5q-31.3-11.5-42.55-54.8l-8.6-3.95q2.65 3.1 4.8 6.75 1.35 2.35-1.75 5.35v.05m88.4-45.6q.403.355.8.7 32.387 28.984 24.45 57.55-.285 1.1-.65 2.2l8.75 7.8q.65-3.35 3.7-5.15-.95-1.25-1.6-3.15-.302-.887-.5-1.7-.63-3.357 1.1-5.45-1-4.25-.75-9.25.2-3.5-4.15-5.75.85-6.75 2.95-11l-1.1-.7q-6.8-4.6-7.2-13.35-9.8-4.5-10.65-12.15-2.4-2.25-5.1-4.4-5.04-.26-9.25-2.4-.634-.29-1.25-.65l.45 6.85z"
                    />
                    <path
                        fill="#747474"
                        d="M92.25 203.95q-.75 1.25-1.6 2.55L75 214.65q-2.6.25-5.3.25-.55 1.95-.75 4.15-.5 5.9 3.2 7.7 8.7 4.2 11.65 13.95 2.25 7.4 7.55 15.05.96-2.065 2.2-4.15l16.3-9.55q.75-.1 1.45-.15-6.3-7.4-1.2-14.9-6-5.7-10.6-10.9-4.8-5.45-7.25-12.15z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToo030__Layer14_0_FILL" />
            <use xlinkHref="#ObjToo030__Layer14_1_FILL" />
            <use
                xlinkHref="#ObjToo030__Symbol_190_0_Layer0_0_FILL"
                transform="translate(98.95 254.7)"
                id="ObjToo030__col1n"
            />
            <use
                xlinkHref="#ObjToo030__Symbol_128_0_Layer0_0_FILL"
                transform="translate(98.95 254.7)"
                id="ObjToo030__col1d"
            />
            <use
                xlinkHref="#ObjToo030__Symbol_71_0_Layer0_0_FILL"
                transform="translate(73.85 205.6)"
                id="ObjToo030__col2n"
            />
            <use
                xlinkHref="#ObjToo030__Symbol_16_0_Layer0_0_FILL"
                transform="translate(73.85 214.65)"
                id="ObjToo030__col2d"
            />
        </g></g>
    )
}

export default ObjToo030
