import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccMor010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.25 3.95Q5.85 2.45 0 0v72.95l11.25 1.3V3.95M75.7 0q-5.85 2.45-11.25 3.95V74.3q8-.75 11.25-1.35V0z"
                    id="AccMor010__Symbol_108_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor010__Symbol_108_0_Layer0_0_FILL"
                transform="translate(154.65 242.1)"
                id="AccMor010__col1n"
            />
        </g></g>
    )
}

export default AccMor010
