import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000"
}

function AccMor056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M167.55 66q-.1-1.4-.3-2.8-1.55-10.45-8.8-19.9-8.15-9.65-20.75-16.6-.65-.35-1.2-.55-7.95-4.8-16.9-7.7-.25-.05-.5-.15h-.2q-.65-.2-1.2-.45Q119.75-1.4 105.4.1q-9.75 1.65-10.75 13.75h-.55q-1.6-.65-3.25-1.1-7.3-2.05-14.65.25-1.3.3-2.65.85h-.65Q71.95 1.75 62.15.1 47.8-1.4 49.9 17.85q-1.2.35-2.3.8-6.3 2.05-12.15 5.1-2.2 1.1-4.35 2.4-.55.2-1.2.55-12.6 6.95-20.75 16.6Q.9 54.05 0 66q.25-.4.45-.85 1.3-2.7 2.8-5.1Q8.8 51 17.3 46.15q14.45-9.6 33.15-12.95 1.05-.2 2.15-.45 1.2-.2 2.4-.3 1.6-.25 3.25-.45 9.75 2.35 14.8-1.3h.65q.75-.1 3.55 0 2.75.1 6.95.15 4.15.05 7.3-.35h.1q1.1.1 2.1.1h.3q.2 0 .55.1 6.3 4.25 14.75 1.3 1.65.2 3.3.45h.1q1.1.1 2.25.3 20 3.25 35.35 13.4 6.05 3.45 10.75 9.2 3.45 4.25 6.1 9.8.2.45.4.85z"
                    id="AccMor056__Symbol_153_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.3 9.45q-1.2.35-2.3.8.45 8.9 7.4 13.8 1.6-.25 3.25-.45-6.4-3.05-8.35-14.15M27.7 7.1q.35-.9.75-1.65v-.1h-.1l.25-.75q-1.3.3-2.65.85h-.65Q16.2-2.5 8.7 1.1q8.6-.45 14 5.9.75.75 1.45 1.7-1.2 5.1 0 9.6.4 1.95 1.3 4 .4-.1.85-.1 1.1 0 2.15-.1-.85-2-1.3-3.8-1.5-5.8.55-11.2m16.65-.8q.2.45.3.9 2.1 5.3.55 11.1-.45 1.8-1.3 3.8h.1q1.1.1 2.1.1h.3q.2 0 .55.1.85-2.05 1.3-4 1.15-4.5 0-9.6l.1-.1q.6-.85 1.25-1.75.55-.55 1.1-1.05Q55.9.65 63.65 1.1q-7.5-3.6-16.6 4.35h-.55q-1.6-.65-3.25-1.1l1.1 1.95m27.95 3.95q-.2-.1-.35-.2-.25-.05-.5-.15h-.2q-.65-.2-1.2-.45-1.95 11-8.25 14.15h-.15q1.65.2 3.3.45h.1q6.85-4.9 7.25-13.8z"
                    id="AccMor056__Symbol_95_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor056__Symbol_153_0_Layer0_0_FILL"
                transform="translate(108.75 50.1)"
                id="AccMor056__col1n"
            />
            <use
                xlinkHref="#AccMor056__Symbol_95_0_Layer0_0_FILL"
                transform="translate(156.35 58.55)"
                id="AccMor056__col1d"
            />
        </g></g>
    )
}

export default AccMor056
