import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}

function AccGla016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.4 2.1q.35.4.85.4t.9-.4q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35q-.4.4-.4.9t.4.85z"
                    id="AccGla016__Symbol_106_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.8}
                    d="M127.75 36.7q.1-.75.15-1.5.05-.5.05-.95.15-3.25.05-6.15 0-1.2-.1-2.4-.15-1.3-.35-2.45-1.45-9.1-6.15-17.1-.75-1.15-1.6-2.1-3.15-3.2-10.55-3.9L76.1.1h-1.4q-8.45.4-10.85 9.25Q61.5.4 53.05 0h-1.4L18.7.05q-7.35.7-10.5 3.9-.85.9-1.6 2.1Q1.9 13.95.5 23q-.25 1.2-.35 2.45-.1 1.2-.1 2.4-.1 2.9.05 6.15 0 .4.05.9l.1 1.5q.35 3.15 1.2 5.85.35 1.2.85 2.4 1.25 3.1 3.35 5.6Q6.7 51.5 8 52.65q1.55 1.35 3.35 2.5 7.35 3.4 16.1 2.35 9.8-1.2 17.85-5.2 10.25-5.7 16.65-21.45.95-2.45 1.85-5.05.95 2.7 1.95 5.3 6.45 15.85 16.7 21.6 8.15 4 18 5.2 8.8 1.05 16.2-2.35 1.8-1.15 3.35-2.55 1.3-1.1 2.35-2.4 2.1-2.5 3.4-5.6.45-1.2.85-2.45.85-2.7 1.15-5.85z"
                    id="AccGla016__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M193.8 9.2q-.45-1.75-3.75 0l-21.5 13.4H162q-.65-5.75-3.3-11.1-.35-.95-.8-1.85l-.3-.6Q153.25.4 142.4 0H51.5Q40.65.4 36.3 9.05l-.3.6q-.45.9-.8 1.85-2.65 5.35-3.3 11.1h-6.55L3.85 9.2Q.55 7.45.1 9.2q-.4 1.4.9 3.5l23.95 19.8h6.25v1.35q.05 2 .3 4.05-.05.05 0 .05v.25l.1.8q.3 2.15 1 4.4.3 1.1.75 2.25.95 2.55 2.45 5.25.45.85.95 1.7.2.25.45.55 2.5 3.05 5.95 5.2 3.45 2 7.6 2.7.25 0 .5.05.6.05 1.2.15 1.85.15 3.75.2h1.25q11.15-.45 20.9-5l.4-.2.3-.1q9.65-4.7 15.85-18.55.2-.4.35-.8.85-2 1.65-4.1l.05-.05.05.05q.8 2.1 1.65 4.1.15.4.35.8 6.2 13.85 15.85 18.55l.3.1.4.2q9.75 4.55 20.9 5h1.25q1.9-.05 3.75-.2.6-.1 1.2-.15.25-.05.5-.05 4.15-.7 7.6-2.7 3.45-2.15 5.95-5.2.25-.3.45-.55.5-.85.95-1.7 1.5-2.7 2.45-5.25.45-1.15.75-2.25.7-2.25 1-4.4l.1-.8v-.25q.05 0 0-.05.25-2.05.3-4.05V32.5h6.25l23.95-19.8q1.3-2.1.9-3.5m-42.7-1.75q.8.85 1.5 1.95 4.4 7.45 5.75 15.95.2 1.1.3 2.3.1 1.1.1 2.25.1 2.7-.05 5.75 0 .4-.05.85l-.1 1.4q-.3 2.95-1.1 5.5-.35 1.15-.8 2.25-1.2 2.9-3.15 5.25-1 1.2-2.2 2.25-1.45 1.3-3.15 2.35-6.9 3.2-15.1 2.2-9.2-1.1-16.8-4.85-9.6-5.35-15.6-20.15-1.05-2.65-2-5.5-.5-2.55-.65-4.9v-1.05q-.35-17 11-17.5h1.3l30.95.05q6.9.65 9.85 3.65m10.6 25.45v-.4h.05l-.05.4M83.6 3.75h1.3q11.35.5 11 17.5v1.05q-.15 2.35-.65 4.9-.95 2.85-2 5.5-6 14.8-15.6 20.15-7.6 3.75-16.8 4.85-8.2 1-15.1-2.2-1.7-1.05-3.15-2.35-1.2-1.05-2.2-2.25-1.95-2.35-3.15-5.25-.45-1.1-.8-2.25-.8-2.55-1.1-5.5l-.1-1.4q-.05-.45-.05-.85-.15-3.05-.05-5.75 0-1.15.1-2.25.1-1.2.3-2.3 1.35-8.5 5.75-15.95.7-1.1 1.5-1.95 2.95-3 9.85-3.65l30.95-.05M32.15 32.5h.05v.4l-.05-.4z"
                    id="AccGla016__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla016__Symbol_106_0_Layer0_0_FILL"
                transform="translate(191.3 163.95)"
                id="AccGla016__col2n"
            />
            <use
                xlinkHref="#AccGla016__Symbol_65_0_Layer0_0_FILL"
                transform="translate(128.5 137.3)"
                id="AccGla016__col2d"
            />
            <use
                xlinkHref="#AccGla016__Symbol_39_0_Layer0_0_FILL"
                transform="translate(95.55 135.5)"
                id="AccGla016__col1n"
            />
        </g></g>
    )
}

export default AccGla016
