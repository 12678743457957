import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#000000"
}

function FacMou073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M28.95 6.7q1.05.3.65-.55-.6-.6-3.15-1.65-2.5-1.1-4.6-2-2.15-.9-3.9-1.55-.55-.25-1-.4Q15.8 0 14.85 0q-.95 0-2.1.55-.45.15-1 .4Q10 1.6 7.85 2.5q-2.1.9-4.6 2Q.7 5.55.1 6.15q-.4.85.65.55 4.65-2.35 9.8-2.85 2.15-.25 4.3-.2 2.15-.05 4.3.2 5.15.5 9.8 2.85z"
                    id="FacMou073__Symbol_131_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M26.55 4.5h-.05l-.45-.2q-.05-.05-.1-.05h-.05q-.05-.05-.1-.05l-.05-.05q-.3-.1-.65-.25-2.15-.9-3.2-1.4-1.9-.9-3.35-1.45Q18.2.9 17.9.8q-.85-.35-1.65-.55-.8-.2-1.55-.25h-1.35q-1.5.15-2.9.8-.3.1-.65.25Q9 1.4 8 1.8q-.75.35-1.55.7-1.3.6-2.35 1-.5.2-.95.45-.15.05-.3.15v-.05l-.4.2q-.1 0-.15.05h-.05l-.4.2Q.1 7.65.05 11.85 0 12.4 0 13.05q-.05 1.65.1 3.9 0 1.25.1 3.5.05 1.05.1 2.3.05.6.05 1.2.25 3 3.05 3.1h21.55q2.8-.1 3.05-3.1 0-.6.05-1.2.05-1.25.1-2.3.1-2.25.1-3.5.15-2.25.1-3.9 0-.65-.05-1.2-.05-4.15-1.75-7.3l.3.1-.3-.15z"
                    id="FacMou073__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M26.55.55l-.45-.2h-.05Q26 .3 25.9.3l-.4-.2v.05q-.15-.1-.3-.15 1.3 3.05 1.8 6.85.1 1.8.1 3.7v5.95q-.05 1.55-.25 2.8-.15 2.2-2.65 2.45H4.15q-2.5-.25-2.65-2.45-.2-1.25-.25-2.8v-.75-5.2q0-1.9.1-3.7.5-3.8 1.8-6.85-.15.05-.3.15V.1l-.4.2q-.1 0-.15.05h-.05l-.4.15Q.1 3.7.05 7.9 0 8.45 0 9.1q-.05 1.65.1 3.9 0 1.25.1 3.5.05 1.05.1 2.3.05.6.05 1.2.25 3 3.05 3.1h21.55Q27.75 23 28 20q0-.6.05-1.2.05-1.25.1-2.3.1-2.25.1-3.5.15-2.25.1-3.9 0-.65-.05-1.2-.05-4.2-1.75-7.35z"
                    id="FacMou073__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1 27.05V0H0v27.05h1z"
                    id="FacMou073__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou073__Symbol_131_0_Layer0_0_FILL"
                transform="translate(177.65 212.85)"
                id="FacMou073__col2n"
            />
            <use
                xlinkHref="#FacMou073__Symbol_98_0_Layer0_0_FILL"
                transform="translate(178.35 214.25)"
                id="FacMou073__col1n"
            />
            <use
                xlinkHref="#FacMou073__Symbol_13_0_Layer0_0_FILL"
                transform="translate(178.35 218.2)"
                id="FacMou073__col1d"
            />
            <use
                xlinkHref="#FacMou073__Symbol_8_0_Layer0_0_FILL"
                transform="translate(192.05 214.25)"
                id="FacMou073__col1s"
            />
        </g></g>
    )
}

export default FacMou073
