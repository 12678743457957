import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M53 19.25Q51.75 12.2 46.9 7q-2.5-2.65-5.4-4.25Q35.9-.45 28.65.1 17.6.9 9.25 9.9.85 18.85.1 30.65q-.85 11.8 6.45 19.55 7.25 7.75 18.25 6.9 9.3-.65 16.7-7.15 1.4-1.25 2.7-2.6 4.35-4.7 6.7-10.15 3.4-8.65 2.15-17.65 0-.15-.05-.3z"
                    id="FacEye010__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1 5.6q.95 1 2.4 1.05Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35 0 1.4 1 2.35z"
                    id="FacEye010__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    d="M192.05 164.45q0 .15.2.3 6.95 4.6 15.65 6.45 8.65 1.85 17.75 2.5 6.15.45 11.1-.4.2-.05.3-.2.15-.2.1-.35-.05-.25-.2-.35-.2-.15-.35-.1-4.9.85-10.9.4-9.05-.65-17.6-2.5-8.05-1.7-14.55-5.8 8.4-3.75 17.2-4.2 8.6-.5 18.4-.45 5.25 0 10.95.5.2 0 .4-.1.15-.15.15-.35 0-.2-.1-.35-.15-.2-.35-.2-5.8-.5-11.05-.5-9.8-.05-18.45.45-9.35.5-18.4 4.65h-.05v.05q-.15.05-.2.2-.1.15 0 .35z"
                    id="FacEye010__Layer6_0_FILL"
                />
            </defs>
            <g id="FacEye010__col1n" transform="translate(139.25 136.2)">
                <use
                    xlinkHref="#FacEye010__Symbol_13_0_Layer0_0_FILL"
                    id="FacEye010__col1n_FL"
                />
            </g>
            <g id="FacEye010__col2n" transform="translate(173 163.45)">
                <use
                    xlinkHref="#FacEye010__Symbol_12_0_Layer0_0_FILL"
                    id="FacEye010__col2n_FL"
                />
            </g>
            <use xlinkHref="#FacEye010__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye010
