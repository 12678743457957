import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.85 14.8q2.2-2.8 2.4-5.15.1-1.55-.6-2.85-1.15-2.1-4.3-3.85L2.95.05Q2 2 1.4 4.1.35 7.65.1 11.5q-.2 2.7.05 5.15.1 1.45.4 2.85l29-2.85-.65.05 18.95-1.9m57.5-9.55q-.7-2.7-1.85-5.25L61.1 2.95Q57.95 4.7 56.85 6.8q-.75 1.3-.6 2.85.15 2.35 2.35 5.15l5.8.6-1.25-.1 42.7 4.3q.25-1.35.4-2.75.3-2.6.1-5.4-.2-3.2-1-6.2z"
                    id="FacEye011__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.4 6.65Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35 0 1.4 1 2.35.95 1 2.4 1.05m32.05 0q1.4-.05 2.35-1.05 1-.95 1-2.35 0-1.35-1-2.35-.95-.95-2.35-.9Q34-.05 33 .9q-1 1-1 2.35 0 1.4 1 2.35 1 1 2.45 1.05z"
                    id="FacEye011__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacEye011__col1n" transform="translate(139.3 155.35)">
                <use
                    xlinkHref="#FacEye011__Symbol_14_0_Layer0_0_FILL"
                    id="FacEye011__col1n_FL"
                />
            </g>
            <g id="FacEye011__col2n" transform="translate(173 162.65)">
                <use
                    xlinkHref="#FacEye011__Symbol_4_0_Layer0_0_FILL"
                    id="FacEye011__col2n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacEye011
