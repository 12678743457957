import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}

function PetPet016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.65 36.5q-.1-.7-.2-1.35-1.85-18.5 21.7-24.8-15-14.6-46.55-5.65-11.25 4.55-23.3 8.5-4.8 1.1-5.2 1.2Q86.7 15.75 79.75 0l-3.3 6.3-2.3 3.05L64.2 3.9q2 14.2-.75 17.75-1.35 1.6-4.85 2.8-12.15 3.25-23.7 5.25Q6.7 36.85 0 58q23.55-5.2 30.6 8.7.75 1.45 1.3 3.3 13.9-12.45 25.3-9 5.2 1.55 9.9 6.45.35.35.65.7.25-.5.45-.95 2.4-4.65 5.45-7.45 2.3-2.35 5-3.35 1.9-.55 3.9.6 0 .05.05.05.05.05.05.1 5 3.05 10.25 17.15.1.35.25.75-.1-.4-.15-.75-3.15-13.9-1.7-19.1.4-1.4 1.2-2.15.35-.3.75-.6 3.55-2.2 10.1.15 3.55.95 7.95 2.8.5-17.45 23.5-18.9 5.15-.35 11.4.1.2 0 .4.1.05-.15.05-.2z"
                    id="PetPet016__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 53.05q23.55-5.2 30.6 8.7-5.95-15.5 12.05-33.05Q10.3 33.2 0 53.05m67.75 9.05l.45.15q2.4-4.65 5.45-7.45 2.3-2.35 5-3.35 1.9-.55 3.9.6-5.45-3.3-10.35-9.95-.25 5.4-1.1 9.2-.85 3.75-3.35 10.8m-10.55-28q-2.35-2.35-5.25-4.2-19.9 13.6-20.15 31.75-.05 1.65.1 3.4 13.9-12.45 25.3-9 5.2 1.55 9.9 6.45l.65-.4q-.85-18.35-10.55-28m51.7-25q32.7 2.4 37.55 21.1-1.85-18.5 21.7-24.8Q149.7-7.05 108.9 9.1M102.35 44q-3.45-3.1-6.4-7.3-.65 5.55-3.1 10.6l.4.2q3.55-2.2 10.1.15 3.55.95 7.95 2.8-5.05-3.5-8.95-6.45m32.45-12.45q5.15-.35 11.4.1-23.65-21.6-43.65-17.3-.2 6.25.6 12.2 1.75 12.7 8.15 23.9.5-17.45 23.5-18.9z"
                    id="PetPet016__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 2.15l3.95 6.9.65-7.95L0 2.15M5.1.95L9.1 8l.35-8L5.1.95z"
                    id="PetPet016__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M94.8 30q-2.15.65-3.15 2.8-1.034 2.166-.5 4.5-.63-2.372-2.55-3.8-1.9-1.3-4.1-.85-2 .45-3.1 2.75-.95 2.15-.5 4.75.49 1.819 1.65 3.05.484.53 1.1.95 1.95 1.4 4.05.9 1.67-.427 2.65-1.85.316-.409.55-.9 1-2.05.5-4.55.6 2.45 2.45 3.75 1.95 1.55 4.05 1 2.1-.5 3.25-2.8.9-2.15.3-4.75-.65-2.5-2.6-4-2-1.35-4.05-.95z"
                    id="PetPet016__Layer4_0_MEMBER_0_FILL"
                />
                <path
                    d="M86 39.75q0 .8.55 1.35.6.6 1.4.6.8 0 1.35-.6.6-.55.6-1.35t-.6-1.4q-.55-.55-1.35-.55t-1.4.55q-.55.6-.55 1.4m9.7-4.1q-.8 0-1.4.55-.55.6-.55 1.4 0 .8.55 1.35.6.6 1.4.6.8 0 1.35-.6.6-.55.6-1.35t-.6-1.4q-.55-.55-1.35-.55z"
                    id="PetPet016__Layer4_0_MEMBER_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#PetPet016__Symbol_88_0_Layer0_0_FILL"
                transform="translate(14 18.1)"
                id="PetPet016__col1n"
            />
            <use
                xlinkHref="#PetPet016__Symbol_72_0_Layer0_0_FILL"
                transform="translate(14 23.05)"
                id="PetPet016__col1l"
            />
            <use
                xlinkHref="#PetPet016__Symbol_38_0_Layer0_0_FILL"
                transform="translate(90.15 46.8)"
                id="PetPet016__col2n"
            />
            <use xlinkHref="#PetPet016__Layer4_0_MEMBER_0_FILL" />
            <use xlinkHref="#PetPet016__Layer4_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default PetPet016
