import { AssetSvgProps } from "../../../shared/assets"

function ShiMot044({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="red"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot044__Layer5_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M195.8 264.05h-7v8.25h-8.25v7h8.25v8.2h7v-8.2h8.2v-7h-8.2v-8.25z"
                    id="ShiMot044__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot044__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot044__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot044
