import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#984969",
    "col1n": "#C67D9A"
}

function BodTai003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M83.45 55.4q.25-.2.45-.35.95-.95 1.55-1.9 2.95-4.7-1.55-9.35-5.85-4.85-21.45-6.9-2.95-.35-6.2-.6-1.9-.15-3.6-.35-24.45-3.9-36.4-26.5-1-1.9-1.75-3.8l-.3-.7-.4-1.2Q13.25 2.8 12.35 2 10.1 0 6.9 0q-.85 0-1.65.15Q3.1.55 1.4 2-.85 4.05.55 9.1q.3 1.1.7 2.2 9.85 23.05 36.6 30.3 9.85 2.5 24.7 3.65 11.15 1.15 14.25 3 .85.5 1.1 1.1v.1l.05.05q.1.55-.3 1.1-.1.1-.15.2-1.25 1.9-6.5 2.35-7.05.65-11.55 1.2-.3 0-.6.05-7.35.95-11.65 3.35-4.35 2.5-6 6.75-2.35 5.9 2.2 11.85 5.75 5.9 15.25 6.8-7-1.2-10.5-6.7-1.5-2.3-2.15-4.65l-.1-.3v-.05q-.05-1.2.2-2.25 1.65-7.9 18.3-8.9 14.95-1.05 19.05-4.9z"
                    id="BodTai003__Symbol_5_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M53.95 42q-4.15-.6-6.45-1.1-.2-.05-.35-.05-4.45-.95-7.75-2.05-15.2-4.45-21.9-10.9-.3-.25-.55-.5-5.3-5.95-8.2-11.05-.05-.05-.1-.15-.05-.05-.05-.1l-.9-1.6Q1.55 2.95 5.25.05V0Q3.1.4 1.4 1.85-.85 3.9.55 8.95q.3 1.1.7 2.2 9.85 23.05 36.6 30.3 9.85 2.5 24.7 3.65 11.15 1.15 14.25 3 .85.5 1.1 1.1-.4-2-2.2-3.15Q73 44.15 53.95 42m29.5 13.25q.25-.2.45-.35.95-.95 1.55-1.9-2.55 3.35-15.3 4.25l-.95.05q-2 .1-2.05.1-14.4 1.3-18.4 4.8-2.1 1.8-2.65 6.85 1.65-7.9 18.3-8.9 14.95-1.05 19.05-4.9z"
                    id="BodTai003__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai003__Symbol_5_0_Layer0_0_FILL"
                transform="translate(230.6 283.2)"
                id="BodTai003__col1n"
            />
            <g id="BodTai003__col1d" transform="translate(230.6 283.35)">
                <use
                    xlinkHref="#BodTai003__Symbol_6_0_Layer0_0_FILL"
                    id="BodTai003__col1d_FL"
                />
            </g>
        </g></g>
    )
}

export default BodTai003
