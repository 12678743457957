import { AssetSvgProps } from "../../../shared/assets"

function ShiMot071({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="red"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot071__Layer5_0_FILL"
                />
                <path
                    fill="#FBEA0E"
                    d="M178.75 275.45l.8 1.25-.9 1.15 1.4-.4.8 1.25.1-1.5 1.4-.35-1.35-.55.1-1.45-.95 1.15-1.4-.55m1.4-14.7l-1.35-.6.8 1.3-.95 1.1 1.45-.35.75 1.25.1-1.5 1.45-.35-1.4-.5.15-1.5-1 1.15m-12.1 5.3h-4.5l3.65 2.7-1.4 4.25 3.6-2.65 3.65 2.65-1.35-4.25 3.55-2.7h-4.45l-1.4-4.2-1.35 4.2m18.5 5.7h-1.5l-.4-1.4-.45 1.4h-1.5l1.2.9-.45 1.4 1.2-.9 1.15.9-.45-1.4 1.2-.9m-2.75-7.8l.2 1.5-1.3.65 1.45.25.2 1.45.7-1.3 1.45.25-1.05-1.05.7-1.35-1.35.65-1-1.05z"
                    id="ShiMot071__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot071__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot071__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot071
