import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M214.4 219.45q-.177-.155-.45-.15H171.2q-.254.004-.45.15-.203.194-.25.45-.037.254.1.45 2.32 3.832 5.75 6.4.45.327.85.6.05.013.05 0l.05.05q.046.041.1.05 3.04 2.087 6.85 3.25.313.116.6.25.064.014.1 0 3.8 1.204 7.6 1.2 3.54-.047 7.05-1.15.105-.023.15-.05.116-.015.2-.05.224-.09.45-.2.988-.286 1.9-.7 2.84-1.181 5.4-2.95.425-.3.8-.6 3.294-2.501 6-6.05.17-.185.15-.45-.024-.28-.25-.5z"
                    id="FacMou022__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M0 0q1.95 2.85 4.65 4.9l.1-.05L4.8 0H0m13.1 9q3.25 1 6.55 1.05V5.5h-6.5L13.1 9m-.95-.3h.15l.05-3.2h-6.8q.1.05.25.2 2.8 1.9 6.35 3m.2-4V0H5.6l-.05 4.7h6.8m7.3 0V0h-6.5v4.7h6.5m7.1 4.25q0-.05.05-.05V5.5h-6.35v4.55q3.15-.1 6.3-1.1m.85-.3q.85-.3 1.7-.65 2.45-1 4.7-2.5h-6.4v3.15M35.15 0l.05 4.65q2.6-2 4.8-4.65h-4.85m-.75 4.7L34.35 0h-6.8l.05 4.7h6.8z"
                    id="FacMou022__Symbol_47_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou022__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou022__Symbol_47_0_Layer0_0_FILL"
                transform="translate(172.5 220.7)"
                id="FacMou022__col1n"
            />
        </g></g>
    )
}

export default FacMou022
