import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D004D",
    "col1n": "#870087"
}

function ShiDre011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.6 5.8q0-.2-.05-.35-.1-.45-.15-.9l-.1-.05h-.05l-.5-.2-.45-.15-.75-.25-.2-.1h-.1q-.1-.05-.2-.05-1.35-.5-2.75-.9Q114.75-1.7 99.3 1q-5.45.95-11.1 2.8h-.1l-.5.2q-2 .75-3.8 1.55L76.5 8.5l-5.35-2.25q-1.65-.75-3.4-1.45-1.2-.5-2.5-1h-.1q-2.75-.9-5.45-1.55-1.7-.45-3.4-.8Q52.5.65 48.8.3L42.05 0q-.5 0-1.05.05h-.05q-7.35.2-14.25 2.3-.85.25-1.65.5-.35.1-.7.25-.15 0-.3.1-1 .25-1.95.6H22q-.7.25-1.35.6l-.4.15-2.2 11.35q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8l-.95 3.6v.25h-.2l-.05.1L0 63.95H.15q.05 0 .1.05.35 0 .75.05 2.1.2 4.25.4 34.95 3.05 69.85 3.2h4.7q1.9-.05 3.8-.05 3.65-.05 7.3-.05.2-.05.4 0 31.3-.55 62.6-3.5l-2.1-3 .1.1-8.5-12.75q-.1-.25-.15-.3-.45-2.25-.65-3.45l-5.4-21.85q-.65-3.1-1.3-6.15l-2.15-11q-.1.05-.15.15z"
                    id="ShiDre011__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M39.35.05Q32 .25 25.1 2.35q-.85.25-1.65.5-.35.1-.7.25-.15 0-.3.1-1 .25-1.95.6h-.1q-.7.25-1.35.6l-.4.15-2.2 11.35q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8L9 47.05v.25h-.2l-.05.1L0 61.4q.75.95 14.7 2.25Q39.65 65.2 62.8 65q.75-.05 1.5-.05-1-.05-1.95-.05l-27.2-1.5Q2.65 60.85 3.8 58.6L15.5 47q1.55-.55 6.1-1.75v-.2q-4.2.1-4.2-2.3 0-1.9 1.8-1.9 4.85 0 10-.8v-.4q-3.9-.2-6.5-.85-3.7-.85-3.7-2.35 0-.85.6-1.4.6-.6 1.5-.6l4.75.9q4.7.9 5.85.9 2.25 0 2.7-.05 1.15-.2 2.8-1.15-6.4-.5-9.5-3.05-2.5-2-3.7-6.15 21.95 6.85 40.6-4.25-14.35 4.35-25.3 4.3Q14.6 23.3 21.15 9q1.2-1.65 3.15-3.1 4-2.95 15.05-5.85M132 5.8q0-.2-.05-.35-.1-.45-.15-.9l-.1-.05h-.05l-.5-.2-.45-.15-.75-.25-.2-.1h-.1q-.1-.05-.2-.05-1.35-.5-2.75-.9Q113.15-1.7 97.7 1q-5.45.95-11.1 2.8h-.1L86 4q-2 .75-3.8 1.55L74.9 8.5l-5.35-2.25q-1.65-.75-3.4-1.45-1.2-.5-2.5-1h-.1q-2.75-.9-5.45-1.55 6.35 2 11.1 5.15 3.3 2.15 5.8 4.85.15-.15.35-.3 2.8-2.55 6.3-4.65 6-3.55 14.05-5.75 8.05-1.75 15.6-1.5 11.1 2.9 15.1 5.85 1.95 1.45 3.15 3.1 6.55 14.3-18.15 16.9-10.95.05-25.3-4.3 18.65 11.1 40.6 4.25Q125.5 30 123 32q-3.1 2.55-9.5 3.05 1.65.95 2.8 1.15.45.05 2.7.05 1.15 0 5.85-.9l4.75-.9q.9 0 1.5.6.6.55.6 1.4 0 1.5-3.7 2.35-2.6.65-6.5.85v.4q5.15.8 10 .8 1.8 0 1.8 1.9 0 2.4-4.2 2.3v.2q4.55 1.2 6.1 1.75l11.7 11.6q.2 3.5-59.6 6.35 26.4.3 55.1-1.75l2.6-.2q5.75-.55 5.3-1.8l-.1-.15.1.1-8.5-12.75q-.1-.25-.15-.3-.45-2.25-.65-3.45l-5.4-21.85q-.65-3.1-1.3-6.15l-2.15-11q-.1.05-.15.15z"
                    id="ShiDre011__Symbol_58_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre011__Symbol_96_0_Layer0_0_FILL"
                transform="translate(115.55 262.15)"
                id="ShiDre011__col1n"
            />
            <use
                xlinkHref="#ShiDre011__Symbol_58_0_Layer0_0_FILL"
                transform="translate(117.15 262.15)"
                id="ShiDre011__col1d"
            />
        </g></g>
    )
}

export default ShiDre011
