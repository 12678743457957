import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo042({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjFoo042__Layer7_0_FILL">
                    <path
                        fill="red"
                        d="M222 233.8H221.55q-.15.05-.2.1h-.05q-.179.06-.4.1-1.473.512-3.6 2.6-1.02.98-1.65 1.65-.9 1.08-.9 1.5 0 .773.35 1.1l.05.05.2.2h.25l.1.1q.5.3 1 .65.25.05.5.15.25.05.6.2 2.65-.9 5.25-2 .75-1.55 1.35-3.2.1-.4.35-.85-1.2-1.4-1.85-2.8-.1.05-.15.05-.45.2-.75.4m-4.15 5.75q.032.131.1.25h-.4l.05-.05q.122-.096.25-.2z"
                    />
                    <path
                        fill="#FFF"
                        d="M214.5 230.15q.188-.036.35-.1-3-1.2-5.95-2.5-2.8-1.25-5.55-2.55l-.4.1-.3.1q-.85.4-1.65.8-.4.2-.75.35-.3.45-.5.95-.15.3-.2.7.45.7.95 1.4.8.5 1.55 1.1 1.95 1.75 3.95 3.45-.05-.1-.05-.15h.45q.167.184.35.25-.064.068-.15.1h-.3q3.85 3.15 7.95 5.9.123-.553.25-1.15.584-.283 1.15-.65.63-.67 1.65-1.65 2.127-2.088 3.6-2.6.1-.2.1-.4v-.15q.689-.31 1.4-.6-3.25-1.1-6.45-2.35 0 .047.05.1l-.05-.05-.1-.1q-.507 0-1.05-.05-.025-.044-.1-.1-.085-.065-.2-.15m-5.9-1.5q-.036.027-.1 0h.1m1.95 3.4q.06.022.1.05-.169.128-.35.25.144-.155.25-.3z"
                    />
                </g>
                <g id="ObjFoo042__Layer7_1_FILL">
                    <path
                        fill="#AAA"
                        d="M208.85 228.8q-.1.05-.1 0-.1-.15-.25-.15.05.7-.6 1.5t-.9 1.8q-.15 1-.55 1.85h-.5q0 .05.05.15.05.05.1.2h.5q.4-.15.85-.35.1-.2.1-.45-.1-2.3 1.45-3.85-.1-.35-.15-.7m7.1 1.7q-.05-.3-.1-.65-.4-.1-.8.1l-.2.1q-2.15.85-4.15 1.85-.9 1-1.35 2.25 0 1.2.75 1.35.3-2.15 2.1-3.6 1.1-.9 2.25-1.5.7.05 1.4.05l.1.1.05.05q-.05-.053-.05-.1z"
                    />
                    <path d="M221.35 233.9q.05-.05.2-.1H222q.3-.2.75-.4.05 0 .15-.05-.15-.3-.25-.6-.15.05-.25.1-3.8 1.55-7.25 3.5-.5 1.9-.9 3.7v.15q.05.15.1.4l.7.3h.1q-.35-.55-.1-1.25v-.2h.05q.25.1.5.35h2.8q1.35-.25 2.15-1.2.65-.7.75-1.65.05-.6.25-1.05.4-1.3-.55-1.7.2-.15.35-.3z" />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo042__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo042__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo042
