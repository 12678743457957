import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2n": "#FFFF00"
}

function AccMor058({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M56.05 19.8q2.95-3.55 1.85-5.5-1.15-1.95-5.7-1.2-4.6.8-9.85 3.85-1.1.65-2.1 1.3.4-1.2.75-2.45 1.55-5.9 1.15-10.5Q41.7.7 39.5.1q-2.2-.6-4.85 3.2-2.7 3.75-4.25 9.65-.45 1.6-.7 3.15-.95-2.3-2.35-4.75-3.1-5.3-6.65-8.25-3.55-2.95-5.5-1.8-2 1.1-1.2 5.65.75 4.55 3.85 9.9 1.45 2.5 3 4.5-2.3-1-5.05-1.75Q9.9 18 5.3 18.45.7 18.9.1 21.1q-.6 2.15 3.2 4.85Q7.05 28.6 13 30.2q2.5.7 4.8 1-1.2.55-2.4 1.3-5.3 3.05-8.25 6.6t-1.8 5.55q1.1 1.95 5.7 1.2 4.55-.8 9.85-3.85 1.1-.65 2.15-1.35-.4 1.15-.7 2.35-1.6 5.9-1.15 10.5.4 4.6 2.6 5.2 2.2.6 4.9-3.15 2.65-3.8 4.25-9.7.3-1.25.55-2.4.85 1.95 2.05 4.05 3.05 5.3 6.6 8.25t5.55 1.8q1.95-1.15 1.2-5.65-.8-4.6-3.85-9.85-1.45-2.45-2.95-4.4 2.3.95 5.1 1.75 5.9 1.55 10.5 1.15 4.6-.45 5.2-2.65.6-2.2-3.2-4.85-3.75-2.7-9.65-4.25-2.55-.7-4.8-1 1.25-.6 2.55-1.35 5.3-3.1 8.25-6.65z"
                    id="AccMor058__Symbol_155_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.15 21.5q-1.2-2.15-3.15-3.95-.95-.85-2.35-1.95-1.35-.9-2.45-1.65 1.25-.05 2.8-.1.75-.05 1.1-.1 3.5-.4 7.25-2.15-2.9-.5-5.5-.4-1.3.05-3.1.25-1.8.3-3.2.6V12q.95-.85 2.3-2.35.45-.6.7-.85 2.1-2.7 3.8-6.65-2.7 1.7-4.45 3.6-.85 1-2 2.45-1.2 2.1-1.85 3.35l-.1-.3q0-1.3-.1-3.3-.05-.75-.05-1.1-.5-3.4-2-6.85-.85 2.6-.65 5.15.05 1.3.3 3.15.55 2 1 3.3l-.05-.05q-.85-.95-2.35-2.3-.5-.55-.75-.8Q6.55 6.4 2.7 5q1.6 2.4 3.5 4.1.95.9 2.45 2.05 1.85 1.05 3.05 1.7-.1.05-.2.05-1.3-.05-3.3 0-.75.05-1.1.05-3.4.4-7.1 2.1 2.8.6 5.35.5 1.3-.05 3.15-.25 1.95-.45 3.2-.8-.95 1-2.1 2.25-.45.55-.65.85-2.15 2.8-3.75 6.8 2.6-1.85 4.35-3.75.9-1 2-2.45.8-1.2 1.5-2.2.05 1.25.15 2.75.05.75.1 1.1.5 3.5 2.25 7.4.5-3.1.35-5.7-.1-1.3-.3-3.1-.35-1.65-.65-2.95.95.9 2.15 1.95.6.5.85.75 2.8 2.15 6.2 3.3z"
                    id="AccMor058__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.6 9.3q1.6 1.6 3.85 1.6 2.2 0 3.8-1.6 1.6-1.6 1.65-3.85-.05-2.25-1.65-3.85Q7.65 0 5.45 0 3.2 0 1.6 1.6-.05 3.2 0 5.45-.05 7.7 1.6 9.3z"
                    id="AccMor058__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor058__Symbol_155_0_Layer0_0_FILL"
                transform="translate(212.2 61.15)"
                id="AccMor058__col1n"
            />
            <use
                xlinkHref="#AccMor058__Symbol_96_0_Layer0_0_FILL"
                transform="translate(230.1 77.3)"
                id="AccMor058__col1d"
            />
            <use
                xlinkHref="#AccMor058__Symbol_32_0_Layer0_0_FILL"
                transform="translate(239 85.05)"
                id="AccMor058__col2n"
            />
        </g></g>
    )
}

export default AccMor058
