import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M62.3 17.75q.25-1.15.3-2.2 0-2.55-1.25-4.8-.25-.5-.45-.95-.05-.15-.1-.25-.25-.3-.4-.5-1.6-1-1.9-1.25-.5-.4-.7-.75-.5-.1-1.1-.05-.35.2-.55.55l-.05.15q-1.35-3.35-4.65-3-.2.1-.35.25-.2-.5-.55-.85-1.4-1.5-3.7-1.15-.25-.4-.6-.55-2.55-.25-3.05-.35-.95-.15-1.75-.55-2-.25-4.05-.3-.15-.1-.25-.2-.3-.25-.6-.4H33.8q-.95-.55-1.25-.6h-1.7q-.5-.05-1.15.3-.25.15-.35.3Q27.15.55 25 .55q-.2.15-.35.45-.05.05-.1.2-.9.25-1.65.6-.55.25-1.05.6-.2.15-.4.35-.1-.05-.2-.15-1.8-.85-3.2.35-2.4-.45-4.15 1.15Q12 4 10.8 5.25q-.6-.05-1.2.05-1.45.9-2 .9L7 6.1h-.2q-1.1.65-1.5 2-.4-.35-1.6-.35h-.4q-.25.6-.95 1.2-1.3 1.15-1.8 1.7Q.5 10.8.5 11q-.15.8-.25 1.75-.3 1.15-.25 2.35 0 1.05.25 2.15.4 1.55 1.4 2 .4.2.9.25.2-.95.9-1.65 1.05-1.3 2.35-2.2l1.05-.45q2.5-.4 2.75-.55.25-.2.35-.5.1-.45.2-.55.25-.4 2.15-.7.65-.15 1.3-.95.2-.1 1.05-.2.75-.05 1.1-.35.3-.3.45-.55 3.1.55 5.75-.55.55-.1 1.05-.25 1.7-.55 2.25-.6l2-.05q.25-.15.45-.25h.05q.05-.05.15-.1.05-.05.1-.05.2-.05.35-.1 1.05 1.15 3.35.3.45-.65.8-1.35Q33.6 9 35.3 9.1q.65.7 1.55.7 1.4.1 2.85.25.5.05.95.1.5.15.95.25.7.2 1.55.5.85.25 2.35.35h1.85q.15 0 .25.05.3.8 1.1 1.25.55.35 1.2.75 1.25.6 2.55.05.5 1.35 2.05 1.7h.55q.05-.05.15-.05h.15q.5-.15.95-.45l.1-.1.05.05.1.2q.1.25.15.5 2 .55 3.2 2.55.35.4.65.85l.4.6q1.05-.25 1.35-1.45z"
                    id="BeaMus011__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus011__Symbol_28_0_Layer0_0_FILL"
                transform="translate(161.2 195.35)"
                id="BeaMus011__col1n"
            />
        </g></g>
    )
}

export default BeaMus011
