import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF99FF",
    "col2n": "#000000"
}

function SkiMak008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M50.45 18.6q-1.4-6.65-6-11.6Q37.2-.75 26.2.1 15.15.9 6.8 9.9q-.3.3-.55.65l-.3.3Q2.1 15.2 0 20.3q22.65-8.9 50.45-1.7m51.05 1.6q-1-2.4-2.4-4.65-1.7-2.75-4.05-5.3-.15-.2-.3-.35Q86.4.9 75.4.1 64.35-.75 57.1 7q-4.65 5-5.95 11.65Q76.9 12 101.5 20.2z"
                    id="SkiMak008__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M190.5 154.65q.85.1 1.7.25h.1q-.05-.05-.1-.05-16.95-8.4-36.4-3.25-.35.05-1 .25-.65.1-1.15.2-.55.05-.75.15l-6.8 1.4h-.05q-1.6.25-3.1.25h-.05q-4.4-.15-7.85-2.75 1.8 4.55 6.3 6.05 7-2.9 15.6-3.75.2-.05.45-.05 16.55-1 33.1 1.3m2.35.25q.85-.15 1.7-.25 16.55-2.3 33.1-1.3.25 0 .45.05 8.6.85 15.6 3.75 4.5-1.5 6.3-6.05-3.45 2.6-7.85 2.75h-.05q-1.5 0-3.1-.25h-.05l-6.8-1.4q-.2-.1-.75-.15-.5-.1-1.15-.2-.65-.2-1-.25-19.45-5.15-36.4 3.25-.05 0-.1.05h.1z"
                    id="SkiMak008__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak008__Symbol_14_0_Layer0_0_FILL"
                transform="translate(141.75 136.2)"
                id="SkiMak008__col1n"
            />
            <use
                xlinkHref="#SkiMak008__Symbol_9_0_Layer0_0_FILL"
                id="SkiMak008__col2n"
            />
        </g></g>
    )
}

export default SkiMak008
