import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#BD9100"
}

function ShiLon026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M164.8 44.9q-.4-1.65-.85-3.3l-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-14.3 9.9-31.05 13.8Q89.1 23.7 84.8 41q-.05-.2-.1-.35-5.3-16.25-22.35-22.1-17.05-3.85-31.6-13.9L29.2 3.5q-1.45-1.05-2.8-2.1L24.55 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.2.7-.35 1.4l-.3 1.1q-.1.3-.15.6 0 .1-.05.2l-.1.5h.05q-.15.5-.25 1-.1.3-.15.6-.1.6-.25 1.15 0 .15-.05.25-.1.35-.15.7L0 50.95q1.95 2.4 2.1 5.35v.05q1.05-.9 2.2-1.55 2.2-1.3 4.85-1.8.15-.05.3-.05l2-.2q8-.3 12.5 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.3l-.4 1.4q.2.05.4.15 25.9 9.4 57.6 10.2h4.95l3.75-3.6 3.85 3.7h4.9q31.95-.8 58-10.35l-.45-1.7v-.1l-5.75-23.15q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35v-.05q.3-3.25 2.15-5.35l-3.5-1.85q-.05-.05-.05-.15l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon026__Symbol_299_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M28.85 46.65l-.9-.3q-3.75-2.85-3.1-14.45L21.2 48.05q-.4.8-1 1l-2.75 11.4q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.3l-.4 1.4q.2.05.4.15 1-.4 1.7-.7.35-.15 3.85-.95 3.4-.9 5.6-1 2.2-.15 3.75-.45 1.5-.3 1-1.85-.55-1.6-2.4-2.75-1.85-1.2-1.85-1.85 0-2.25 5.75-2.2 5.75 0 5.75-1.05 0-1.1-4.5-3.65t-4.5-5.4q0-1.7 1.5-2.1 1.5-.45 1.5-1.15t-1.45-1.4q-.6-.3-1.4-.55M.75 35.95q0 .1-.05.2l-.1.5h.05q-.15.5-.25 1-.1.3-.15.6-.1.6-.25 1.15 11-6.35 22.15 1.85v-.6q-8.9-10.7-21.4-4.7m80.2-4.1q-12.75-10.8-24.05-3.6Q43.8 5.9 31.95 0 46.35 13.3 55 33.25q14.85-7.5 25.9-1.4h.05M129.9 0q-11.85 5.9-24.95 28.25-11.3-7.2-24 3.6 11.05-6.1 25.9 1.4Q115.5 13.3 129.9 0m9.8 40.65v.6q11.15-8.2 22.15-1.85-.15-.55-.25-1.15-.05-.3-.15-.6-.1-.5-.25-1h.05l-.1-.5q-.05-.1-.05-.2-12.5-6-21.4 4.7m-8.35 6.55q-1.45.7-1.45 1.4t1.5 1.15q1.5.4 1.5 2.1 0 2.85-4.5 5.4-4.5 2.55-4.5 3.65 0 .7 2.4.9l3.35.15h.25l2.4.15q3.1.4 3.1 2.05 0 .65-1.85 1.85-1.85 1.15-2.4 2.75-.5 1.55 1 1.85 1.55.3 3.75.45 2.2.1 5.6 1 3.5.8 3.85.95.7.3 1.7.7.05-.05.15-.05.05 0 .1.05.2-.1.4-.15l-.4-1.4v-.3l-.95-3.6-.9-3.6q-.5-2.1-1.05-4.2l-2.75-11.4q-.7-.2-1.1-1.25-.3-1.35-.4-1.75-.1-.95-.5-2-.4-1.2-.5-1.65l-2.4-10.5q.65 11.6-3.1 14.45l-.9.3q-.8.25-1.4.55z"
                    id="ShiLon026__Symbol_268_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.3 47.65q-.1.3-.15.65-.1.35-.15.65h.05q11.3-6.9 22 1.6L20.8 57v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L26 35.8v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-2.7 13.9q-10.75-8.25-22-1.8M134.8 4.8L141 0l-13.1 8.85q-10.25 8.45-17 18.05-1.85 2.6-3.4 5.25-1.1 1.9-2.1 3.8-8.25-4.3-15.8-1.3-2.65 1.05-5.2 3-1.65 1.2-3.25 2.9Q79.7 39.1 78.2 38q-2.55-1.95-5.2-3-7.55-3-15.8 1.3-1-1.9-2.1-3.8-1.55-2.65-3.4-5.25-6.75-9.6-17-18.05L21.6.35l6.2 4.8Q42.9 16.05 51.7 29q1.2 1.75 2.25 3.5 1.4 2.35 2.6 4.75.05.1.2.2.1.05.25.05.1 0 .25-.05Q65.5 32.95 73 36.1q2.65 1.1 5.2 3.15 1.05.85 2.1 1.85l.2.2v47.25q.45.95 1.2 0V41.4q1.75-1.7 2.7-2.5 2.55-2.05 5.2-3.15 7.5-3.15 15.75 1.35.15.05.25.05.15 0 .25-.05.15-.1.2-.2 1.2-2.4 2.6-4.75 1.05-1.75 2.25-3.5 8.8-12.95 23.9-23.85m27.35 44.15q.05 0 .15.05-.05-.1-.05-.15l-.1-.5q-.1-.35-.15-.65-11.35-6.5-22.05 1.75l-2.75-13.9q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15V57l-1.25-6.45q10.7-8.5 22-1.6z"
                    id="ShiLon026__Symbol_237_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.5 39.5q-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65m0-19.75q-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65m1.35-15.9q.65-.65.65-1.6 0-.95-.65-1.6Q3.2 0 2.25 0 1.3 0 .65.65 0 1.3 0 2.25q0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65z"
                    id="ShiLon026__Symbol_198_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon026__Symbol_299_0_Layer0_0_FILL"
                transform="translate(107.9 228.6)"
                id="ShiLon026__col1n"
            />
            <use
                xlinkHref="#ShiLon026__Symbol_268_0_Layer0_0_FILL"
                transform="translate(111.6 237.35)"
                id="ShiLon026__col1d"
            />
            <use
                xlinkHref="#ShiLon026__Symbol_237_0_Layer0_0_FILL"
                transform="translate(111.4 228.7)"
                id="ShiLon026__col1s"
            />
            <use
                xlinkHref="#ShiLon026__Symbol_198_0_Layer0_0_FILL"
                transform="translate(199.55 266.95)"
                id="ShiLon026__col2n"
            />
        </g></g>
    )
}

export default ShiLon026
