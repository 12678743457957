import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.7.75q.05-.1.05-.25T29.7.25l-.1-.1Q29.55.1 29.5.1q-.1-.1-.25-.1h-5q-.2 0-.35.15-.15.15-.15.35 0 .2.15.35.15.15.35.15h3.2l-5.7 3.45q0-.6-.15-1.2-.45-1.65-1.9-2.5-1.55-.85-3.15-.4-1.65.45-2.5 1.9-.8 1.45-.4 3.05-.45-1.6-1.85-2.4-1.5-.9-3.1-.45-1.65.45-2.5 1.9-.85 1.45-.4 3.1.25 1 .9 1.7L10 11.5l-1.6.95h-.05l-.05.05-2.75-.65q-.25-.1-.4.05L.25 15q-.2.1-.25.3-.05.2.1.4.1.15.3.2l2.6.6-.55 2.55q-.05.2.1.4.1.15.3.2.2.05.4-.05l4.95-3.1q.15-.15.2-.3l.6-2.95 1.85-1.15 5.55 3.95L21.45 5.8v-.05l6.5-3.85-1.6 2.85q-.15.2-.05.4.05.15.2.3.2.1.4.05.15-.1.3-.25l2.45-4.4V.8l.05-.05M7.85 14.15l-.35 1.6-3.8 2.35.35-1.55 3.8-2.4m-2.35-1.3l1.65.35-3.8 2.4-1.55-.4 3.7-2.35z"
                    id="SkiTat002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat002__Symbol_2_0_Layer0_0_FILL"
                transform="translate(203.8 249.75)"
                id="SkiTat002__col1n"
            />
        </g></g>
    )
}

export default SkiTat002
