import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M18.35 9.4V0Q8.6.05 1.85 7.4.9 8.35 0 9.4h18.35m29.85 0q-.9-1.05-1.85-2Q39.6.05 29.85 0v9.4H48.2z"
                    id="BeaMus005__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus005__Symbol_22_0_Layer0_0_FILL"
                transform="translate(168.4 201.2)"
                id="BeaMus005__col1n"
            />
        </g></g>
    )
}

export default BeaMus005
