import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#3D584C",
    "col1s": "#000000",
    "col2n": "#2E0E00"
}

function ShiSpe025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.75 5.9v-.05q-.4-.25-.8-.55.1.1.25.2.25.2.55.4m133 38.35q-.3-1.3-.65-2.6-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-4.05-9.2-9.25-17.2l-.7-1Q144 2.1 142.45.05l.05-.05h-.05L114.4 22.95l-13-3.7q-5.35 1-10.95 1.4l-5.85.2H82.45l-7.9-.25q-5.75-.45-11.25-1.5-.2-.05-.4-.05-.05-.05-.1-.05-.2-.05-.4-.05v.1l-21.7-.45L22.4 1.25l-.2-.8-.1.1q-.1.15-.15.25l-.05.05Q20.7 2.5 19.6 4.2l-.7 1q-5.2 8-9.25 17.2-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35-.35 1.3-.65 2.6Q.85 49.8.1 55.7q-.05.3-.1.65.4-.4.85-.7 2.7-2 6.2-2.65.15-.05.3-.05l1.85-.2q5.2-.2 8.95 1.8.85.45 1.65 1.05h.05q.3.25.65.55.1.05.2.15.55.5 1.05 1.05.05 0 .1.05v-.05l.15-.1v.15q.25.4.5.4l39.05 32.6q-.4-.05-.75-.05.65.25 1.3.55 3.15 1.3 6.45 2.85 4.55 2.05 7.55.15 2.55-1.75 4.9-4.45 1.6 2.25 2.8 5.05.4.9 1 1.95 4.1-1.9 9.45-2.8 2.35-.4 4.7-.9 4.65-1.1 9.2-2.6 3-1 5.95-2.1l-1.35.15 24.05-8.6 6.35-22.1h.05l-.05-.05v-.05l.1-.1.3-.3.1-.05q.15-.2.35-.35.05 0 .05-.05.45-.4.95-.75 2.1-1.6 4.4-2.4.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05H155q6 .1 10 3.7-.05-.35-.1-.65-.75-5.9-2.15-11.5M83 30.3q.001.049.05.05H83v-.05z"
                    id="ShiSpe025__Symbol_116_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.85.05q-.2-.05-.3 0-.05 0-.05.05-.05 0-.1.05t-.1.2q-.05.05-.05.1L0 22.15q.4.7.85.15l.1-.3v-.15H1l-.05-.05v-.05q.1-.45.2-.85.1-.35.15-.65v-.05q.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85L5.2.7l.05-.05q0-.25-.1-.4-.1-.2-.3-.2m111.65.1q0-.05-.05-.05-.1-.05-.3 0-.2 0-.3.2-.1.15-.1.4l.05.05 3.25 16.8q.05.1.2.85.05.1.05.25v.05q.2.55.4 1.4v.15q0 .05.05.05.1.65.3 1.5v.05l-.05.05h.05v.15l.1.3q.35.45.75.05 0-.05.1-.15v-.1L116.75.5V.45L116.7.4q-.05-.15-.1-.2-.05-.05-.1-.05z"
                    id="ShiSpe025__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M48.75 46.7q1.35-8.7-.85-25.25l-.3-1.8q-.05-.35-.05-.65-18.3-3.7-33.65-14.35L7.7 0h-.05l.05.05h-.05q-.15.2-.3.45l9.35 32.65q2.7 6.3 3.2 16.25t-7.75 9.2q-1.45-.5-2.35-1.2-.1-.05-.2-.15L8.3 56.2q-.1.4-.2.85v.05l.05.05H8.1v.15l-.1.3q-.45.55-.85-.15v-.15l-.15.1v.15l-7 29.3h.35v.05l45.6 3.5q2.25.05 4.45.05.55 0 .9-.1.5-.1.6-.35l.4-1.2q.4-1.25.75-2.45.4-1.65.7-3.3 1.9-12.4-2.7-19.45-4.05-6.1-2.3-16.9M14.35 5.55q-.15-.1-.25-.2.4.3.8.55v.05q-.3-.2-.55-.4m72.2 13.9v1.8q0 2.8.05 5.6.15 7.25.2 14.55-.05 6.45-2.05 12.3-2.8 7.6-1.65 16.75 1 8 .85 15.95v.8l-.1 1.6q0 .4-.05.8l51.1-4.95-.85-3.65-.05-.1-5.65-23.35h-.2q-.1.1-.1.15-.4.4-.75-.05l-.1-.3v-.15h-.05l.05-.05v-.05q-.2-.85-.3-1.5-2.15 1.25-5.15 1.65-3.7.2-6.05-3.65-.1-.15-.2-.35l-.1-.2q-.95-1.75-.65-4 1.5-11.6 4.3-22.95.25-1.1.55-2.2L127.3.3l-5.9 4.4q-15.85 11-34.85 14.6v.15m1.25 10.7h-.05l.2-.05-.15.05m5.4 37.05q1-.25 1.9-.7 1.55-.8 2.9-1.8 1.5 1.4 3.1 2.7 7.4 6.1-2.4 5.3 1.9-1.65 0-3-2.95-2.1-5.5-2.5z"
                    id="ShiSpe025__Symbol_115_0_Layer0_0_FILL"
                />
                <g id="ShiSpe025__Layer11_0_FILL">
                    <path
                        fill="#8A6900"
                        d="M227.6 275.75q2.45-.75 3.2-3.35 1.3-4.6 3.6-5.35 3.25-1.05.3-4.05-.95-1-2.65-.95-4 .15-6.95-1-.85 1.9.7 5.9.5 1.3-.9 3.2-6.15 8.4 2.7 5.6z"
                    />
                    <path
                        fill="#830000"
                        d="M152.55 284.25l-.4.15q3.5 8.85-4.05 13.65-.75.45-.75 1.7.15 7.3 8.7 7 .4-8.85 5.2-11.55 3.45-1.9-.1-6.45-1.25-1.55 1.45-2.35-4.85-1.95-10.05-2.15z"
                    />
                    <path d="M124.55 254.7q-.2.3-.45.55h1q.75 0 1.5.1 1 .15 2 .4-4.3 6.75-8.5 11 3.75-1.2 3.6 2.5-.1 2-.1 4 3.95-3.8 7.5-8-5.5 1.1-3.8-2.9 2.35-5.5 6.8-8.6-3.2-.4-5.6-1.2-.85-.3-1.65-.65-1.2 1.35-2.3 2.8z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe025__Symbol_116_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe025__col1n"
            />
            <use
                xlinkHref="#ShiSpe025__Symbol_117_0_Layer0_0_FILL"
                transform="translate(132 263.85)"
                id="ShiSpe025__col1s"
            />
            <use
                xlinkHref="#ShiSpe025__Symbol_115_0_Layer0_0_FILL"
                transform="translate(124.85 228.55)"
                id="ShiSpe025__col2n"
            />
            <use xlinkHref="#ShiSpe025__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe025
