import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaMus026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M76 18.9q-1.45-3.75-3.25-5.8-1.8-2-5.85-4.45.1 0 .25-.15 1.85-.4 3.1-.35-9.6-5.4-14.75-4.65v-.1l5.25-1.65h-.35q-1.15 0-3.3-.25t-3.2-.25q-3.95 0-8.45 1.3-4 1.15-4.7 2.2-.55-.25-2.05-1.15-1.25-.85-2.8-1.35-3-1.05-5.3-1.6-3-1.05-8-.4 1.15.55 2.3.95 1.15.4 1.75 1.2-2.1-.4-5.7.3-2.05.4-5.8 1.3l-.15.15q2.05-.05 3.15.35.95.5 1.35.65-3.2.55-6.55 2.25Q9.85 9 8 10.9q-.3.25-.5.5-.25.3-.35.6l.25-.1q2.65-1.05 4.85-1.5v.1q-.9.7-1.75 1.5-2.9 1.7-4.25 3.25-1.7 2.1-2.9 4.15Q2.1 21.55.5 25.15h.15Q2.4 23.5 3 23.25v.15Q.9 30.2.6 31.3 0 33.9 0 37.25q0 1.9.25 2.9.1-1.25.95-2.85.45-.75.85-1.35Q1.9 37 1.9 38.7q0 .75.05 1.5.45 2 1.4 4.55 1.65 4.3 3.15 5.4v-.5q.2-2.3.5-4.9.8 2 5.15 6.4-.5-1.15-.95-2.85-.55-1.9-.55-2.95v-.3Q12.7 47.5 16.7 48q-3.55-6.4-1.45-14.55.1.05.2.15.3.15.5.5.15.35.55.65.3.3 1.3 1.4-2.3-12.2 5.05-18 .25-.15.5-.35.05-.098.15-.2-.048.052 0 .1-.05 1.4-.05 3.6l.3-.4q.3-.2 2.9-2.05 2.1-1.5 3.1-1.95h.15l-.9 1.2q-.75 1.05-1.25 1.15l-.1.15q1.95-.4 4-1.45 2.3-1.25 3-1.45v1.15l-.65 1.1v.15q1.85-.35 3.9-1.7 1.9-1.45 2.25-1.7.35 1 2.65 2.15 1.95 1 2.85 1.1h.1q-.65-1.05-.75-2.1v-.15q.9 1.7 4 2.6 1.75.45 4.75 1.05-1.2-2.85-1.35-3.75.15.75.85 1.4.4.4 1.4 1.1 1.75 1.8 3.8 3.15.25-1.1.85-1.7.1.2.35.45.1.15.25.3l.05.1q.05.05.15.2.2.2.35.45Q64.7 27.8 63.6 37l-.2 1.2q-.05.25-.1.55 1.3-1 2.05-1.65-1.25 6.4-3.7 10.4 1.7-.35 2.9-.8l2.1-1.05v.5q-.05.1-1 3.05-.9 2.85-1.15 3.2h.15q.6-.75 1.95-1.65 1.7-1.1 2.55-1.85 1.3-1.25 2.1-2.25.15-.15 1.25-1.6l-.65 4.85q.05-.05.1-.05l.6-.4q6.3-4.95 4.85-16.05h.1q.3.3 1.25.85.9.5 1.15.9-.05-.1-.1-.5-1.25-9.95-6.65-16 1.05.1 2.35.25h.5z"
                    id="BeaMus026__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M180.25 214.1l.2-.2q-.1.05-.15.05l-.1.15h.05m-14.6 2.9q-2 2.95-2.45 4.05v.2q3.5-2.45 3.5-4.95 0-.25-.1-.65-.4.6-.95 1.35m5.05-13.9q-1.75.25-2.55.45-.4.1-.85.25-1.55.4-3.55 1.3v.05h.3q1.7-.15 2.75-.25h.4q.2-.05.4-.05-.45.15-.9.35 1.1-.25 2.2-.6.9-.3 1.8-.75 1.35-.65 2.15-1-.55.05-2.15.25m11.85 7.85q-.25-.85-.25-1.25-.8.8-2.4 2.65-.3.3-.6.65-.9 1-1.7 1.7.65-.5 1.6-1.15 1.05-.75 1.85-1.25.6-.4 1.05-.6l.45-.75m2.8-7.75h-.4q-3.05.35-4.95 1.6-1.05.65-3.05 2.05v.1q6.65-2.1 6.8-2.15 1.05-.5 1.6-1.6m2.1 5.9l-.25.1q-1.45 1.85-2.85 3.05-.55.45-1.05.85.45-.2.9-.45.4-.2.75-.4 1.4-.75 2.05-.95.05-.15.05-.25.15-.55.4-1.95m5.5.25q-.15.55-.2.95 0 .1.1.2.5.75 2 1.6.25.1.5.25.65.35 1.2.6-.5-.45-.9-.85l-.1-.1-2.6-2.65m4.9 0q-.15.1-.3.1-.1.75 0 1.9v-.15q.25.45.65.85 1.05 1.1 3.35 1.75 1.35.35 3.4.8-3.1-1.25-4-1.9-.4-.3-.8-.7-.7-.7-1.2-1.65-.6-1-1.1-1m30.5 16.2q-.05-.05-.1-.15-1.05-1.3-1.5-1.8.2.4.5.9.25.5.4.95.2.6.2 1.2.15.05.3.2.55.3 1.4.95.25.25.45.4v-.15q-.1-.5-.7-1.25-.65-.85-.95-1.25m-6.65 2.35q.05 1 .1 2.35l2.05 4.45.1-.25q.4-2.85-.8-5.1-.2-.35-1-1.35-.2-.3-.45-.6v.5m-1.35-15.95q-.1-.05-.15-.1h-.15l.3.1m-9.55 4.2q-1.95-1.65-2.5-2.3-.3-.35-1.2-1.95l-.05-.05q-.85-1.55-1.85-2.5l-.1 2.15q.05.2.2.45.2.25.5.55.4.4 1.4 1.1 1.75 1.8 3.8 3.15l.1-.3-.3-.3m3-8.8l-.25-.15q-2.85-1.55-3.75-2.05-.4-.25-.8-.4l.6-.5q1.35-1.15 1.45-1.15h-.1q-.3 0-2.1.6l-1.5.5h-1.25q.2.2.5.4t.65.35q.45.2 1 .45.6.25 1.3.55 1.25.75 4.25 1.4m2.4-5.75h-2v.15q2.35 1.15 4.6 1.6.75-.2 1.45-.35-3.75-1.35-4.05-1.4m7.6 11.95q1-.25 2.2 0-.1-.05-.15-.1-1.85-1.35-4.55-2.2-.35-.15-.65-.2.15.05.35.2.6.45 1.6 1.35.65.65 1.2.95m-62 16.55q-.45 0-.65.4-.1.15-.15.35-.05.2-.05.6 0 1.7 1 2.9.6.65 1.75 1.6v-2q-.25-1.1-.6-2.05-.6-1.8-1.3-1.8m-5.65-.5q-.15.55-.2 1.1l-.15.6q.5-1.55 2.05-4.6-1.55 1.15-2.4 2.35-.4.6-1.15 1.55-.35.6-.5 1.35v.05q.001-.05.05-.05 1.75-2.05 2.3-2.35m.65-12.6q-.9.55-2.1 1.3-.05.15-.05.25.95-.65 2.7-.9l.6-.1v.05q.2-.05.45-.1l.1-.1q2.15-1.7 3.6-2.9-2.25.65-4.2 1.85-.5.3-1.1.65z"
                    id="BeaMus026__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus026__Symbol_43_0_Layer0_0_FILL"
                transform="translate(152.55 194.7)"
                id="BeaMus026__col1n"
            />
            <use
                xlinkHref="#BeaMus026__Symbol_13_0_Layer0_0_FILL"
                id="BeaMus026__col1d"
            />
        </g></g>
    )
}

export default BeaMus026
