import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#6486A6",
    "col2n": "#8FAFCD"
}
function LegSpe003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.1-2.6H73.2l-2.55 6.55-.1.1-.05.15-1.15 2.65q-.55 1.5.35 2.2.55.4 1.6.5.15 0 .25.05h62.85q2.85-.3 1.95-2.7l-3.25-9.5m-69.9 0h-59l-4 9.45q-.9 2.4 1.95 2.7.25.05.55.05h62.1q.25 0 .5-.05 2.85-.3 1.95-2.7l-1.05-2.8q-.05 0-.05.05l-.1-.4-.05-.1q-.123-.07-.25-.15L63.2-2.6M51.1 6.35h-.05l-.1-.3.15.3z"
                    id="LegSpe003__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M143.45 15.1v-.15q-3.65-1.4-4.9-2.9-.7-.8-1-2v-10l.05-.05h-.05q-.2.05-.35.1Q73.95 12.35 12.7.2q-.25-.1-.5-.1-.15-.05-.25-.05-.05-.05-.1-.05 0 .05.05.1v10.7q-.35 1.95-1.8 3.4-1.3 1.3-5.65 4.3-.3.25-.65.45h.1q-.15.05-.3.15-.5.3-.65.5.45-.05 1.95.3l-.2.1h.4q-.5.75-1.75 1.6Q1.8 22.7 0 23.15q.35.4.75.7 3.5 2.1 7.2 0 .9-.4 1.85-.95.7-.5 1.7-1.45.7-.65 1.05-1.1h.05q-.2.85-.75 2.5v.1l1.7.05q1.25-.05 2.5-.3 1.3-.5 2.85-1.6 1.3-.95 2.45-1.45h.1q.6-.05 1.45-.25h1.8q3.1.45 6.5 2.5 1.05.6 2.85 1.5 1.75.85 2.55 1.1-.2-.15-.3-.45-.25-.55-.25-1.1 0-1.25.85-1.6v-.05q.55-.2 1.85-.4h1.6q.2.05.5.1h.25q.75.15 2.05.6.35.1.85.25 2.2.7 3.35.85h1.55q.6-.1 1.45-.45.2-.05.45-.15.55-.1 1.2-.2.9-.15 1.7-.2.55-.05 1.05-.05 2.35 0 4.9.45 1.85.3 3.8.85.4.1 1.25.25.8.15 2 .55.6.2 1.85 1.05-.45-1.6-2.25-3.2h1.4q1.1.2 2.15.65H70q.05.05.1.05.2.05.4.15 1 .4 2 .9 1.8.95 2.05 1.8.2-.25.7-.55.7-.45 1.35-.8.35-.2.55-.25.35.4.65.7.55.5 1.7 1.6l.3.3q.2-.5.9-1.6 1-1.5 1.05-1.6.15-.35 1.1-1.3 1.25 1.8 2.15 2.85.65-.45 1.7-.95 1.95-.9 3.55-1.35 1-.3 2.1-.55.8-.1 3.15-.6 3.15-.45 4.35-.5h2.3q1.2.2 4.55 1 .5.1 2.45.75 1.6.5 2 .55.05 0 .1.05.35.15.65.3-.55-.85-1.45-3.35.4.4 1.05.95 1.45.95 3.6 1.25h2.05q.05 0 4.85-1.2 3.15-.75 6.1-1.05h2.6q1.6.3 3.65 1.25l3.85 2.4q.55.2 1.15.5l.75.25q.15.05.25.1.15.05.35.15.2.1.4.15h.1q.4.1.8.25h1.8l-.05.05.35-.05h.7q.65.05 2.15-.5.25-.1.5-.15.8-.25 1.15-.5.95-.3 1.95-.75-1.95.1-4.4-1.55-.1-.1-.2-.15-.35-.25-.75-.55-3-2.35-4.5-5.5 1.85 0 2.75-.1z"
                    id="LegSpe003__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M70.6.4Q57.5 1.6 44.3 1.85q-4.5.1-9 .1-17-.1-34.1-1.8Q.55.05 0 0q5.8 1.2 11.9 2 11.25 1.55 23.4 1.85h.55v.1H36l1.25 11.2v-.4l.05.4 1.3-11.3h.15q.15 0 .35.05l5.2-.2q9.75-.45 18.75-1.7 5.8-.8 11.25-1.95-.15 0-.3.05-.95.05-1.9.15-.75.05-1.5.15z"
                    id="LegSpe003__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSpe003__Symbol_37_0_Layer0_0_FILL"
                transform="translate(124.3 329.95)"
                id="LegSpe003__col1n"
            />
            <use
                xlinkHref="#LegSpe003__Symbol_11_0_Layer0_0_FILL"
                transform="translate(117.8 310.25)"
                id="LegSpe003__col2n"
            />
            <use
                xlinkHref="#LegSpe003__Symbol_12_0_Layer0_0_FILL"
                transform="translate(155.3 316.3)"
                id="LegSpe003__col2d"
            />
        </g></g>
    )
}
export default LegSpe003
