import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#2E0E00",
    "col1n": "#621F00"
}

function AccMor009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.4 15q-.25-3.4-1.75-5.75T7.1 5.5l-.9-.6-1.55-1.15Q3.2 2.7 1.85 1.65L0 .2q8.35 10.4 6.5 23.75-.1.55-.15 1.15-.45 2.5-1.25 5.1l-1.2 5.75q.25-.1.45-.25 4.45-3.85 6.15-9.3.2-.6.35-1.15Q12 20.65 11.4 15M120.3 0l-2.05 1.65q-1.35 1.05-2.8 2.1L113.9 4.9q-.45.3-.85.6-1.1 1.4-2.6 3.75T108.7 15q-.6 5.65.55 10.25.15.55.35 1.15 1.7 5.45 6.15 9.3.2.15.45.25L115 30.2q-.8-2.6-1.25-5.1-.05-.6-.15-1.15Q111.7 10.5 120.3 0z"
                    id="AccMor009__Symbol_107_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.5 1.3L.15 0Q.05.55 0 1.15l4.15 1.3q.2-.6.35-1.15M107.25 0l-4.35 1.3q.15.55.35 1.15l4.15-1.3q-.05-.6-.15-1.15z"
                    id="AccMor009__Symbol_64_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor009__Symbol_107_0_Layer0_0_FILL"
                transform="translate(132.45 228.35)"
                id="AccMor009__col1n"
            />
            <use
                xlinkHref="#AccMor009__Symbol_64_0_Layer0_0_FILL"
                transform="translate(138.8 252.3)"
                id="AccMor009__col1d"
            />
        </g></g>
    )
}

export default AccMor009
