import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1l": "#D9D9D9",
    "col1n": "#B5B5B5",
    "col1s": "#747474",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}

function AccHat110({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1l}
                    d="M11.45 2.95V2.9Q10.6 1.8 9.7 1.2 5.45-1.85 0 2.9L4.7 111h.15l6.6-108.05m91.2 95.5l-.3-1.9q-.1-.35-.15-.7-.15-.85-.35-1.7l-.2-1q-5.95-27.2-26.85-48.1Q61.65 31.9 46 24.65l-.2-.1q-.65-.3-1.25-.55-1.2-.5-2.35-1l-.3-.1q-.4-.2-.8-.3-.7-.3-1.4-.55-1.2-.45-2.4-.85l-.05-.05q-1.25-.35-2.4-.55-.4-.05-.75-.1-2.9-.45-5.3-.15-1.05.1-2 .35-6.55 1.7-9.35 9.45l-.55 3.65 6.8 3.1q41 20.65 44.55 77.1l2.95 11.3q1.35 7.05-.95 14.1 9.6-5 19.3-16.25 3.35-3.9 6.7-8.55.35-.55.75-1.1 2.8-4 5.65-8.5v-3.25l-.1-.1h.1V99.5l.05-.05.15.1q-.05-.1-.05-.2-.05-.45-.15-.9m-13.3 17.6l-.4.5v-.1q.75-.9.4-.4z"
                    id="AccHat110__Symbol_435_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M115.35 1.8q-.8-.9-1.55-1.75l-6.75 111.05 12.75-83.85q2.8-7.75 9.35-9.45l-13.7-15.85-.1-.15M204 90.25l.2 1q.2.85.35 1.7.05.35.15.7l.3 1.9q.1.45.15.9 0 .1.05.2l-.15-.1-.05.05v2.15l.5-.05q0 .2.05.45h-.15l-.4-.3v3.25q-2.85 4.5-5.65 8.5-.4.55-.75 1.1-3.35 4.65-6.7 8.55-9.7 11.25-19.3 16.25 2.3-7.05.95-14.1l-2.95-11.3h-63.55L102.35 0q-3 3.8-6.45 6.85l-.1.1-.8.7-8.85 9.25q-3.4-.7-8.45.35l-.5.1q-.5.1-1 .25l-.2.05q-.55.1-1.05.25l-.4.1q-3.1 1.05-6.1 2.3-1.15.45-2.25.95-.4.15-.75.35-2.1.95-4.1 2l-.2.1q-.6.25-1.15.6-12.75 6.85-23.8 17.85-19.8 19.8-26.1 45.3-.6.45-1.15.95-3.1 1.9-5.5 4.65Q0 96.95 0 100.55q0 .1.05 1.4.15 1 .5 1.9.15 3 2.05 5.35 1.7 2.1 4.45 3.05v.45q0 13.9 3.3 26.4 2.75 10.45 7.8 19.95l88.05 53.2q.25.05.55.1.15 0 .3.05 47.65-10.5 85.5-48.6 13.8-22.7 13.8-51.1 2.45-1.4 3.85-4.9 1.35-3.5 1.35-7.35 0-4.2-2.9-7.2-1.95-2.05-4.65-3m-38.75 25.35q4.8 11.25 0 23.5H49.05q-4.8-12.25 0-23.5h116.2M96.2 32.25l1 .2-1 2.15q8.8 35.85 10.45 76.5h-58q-.1-6.05.35-11.65 1.7-12.1 5.45-22.3 6.85-18.9 20.5-31.25 7.65-7 17.4-11.9l3.85-1.75M7.9 98.95h.25q-.15.1-.25.2v-.2z"
                    id="AccHat110__Symbol_400_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M95 7.65l-8.85 9.25q6.45 1.2 8.9 9.85l11.6 84.35h.4L102.35 0q-3 3.8-6.45 6.85l-.1.1-.8.7M7.9 98.95q.05-.4.1-.75.1-.9.25-1.75.2-1.4.5-2.8.05-.4.15-.8.45-2.55 1.1-5.05.05-.2.1-.35-.6.45-1.15.95-3.1 1.9-5.5 4.65Q0 96.95 0 100.55q0 .1.05 1.4.15 1 .5 1.9.15 3 2.05 5.35 1.7 2.1 4.45 3.05v.45q0 13.9 3.3 26.4 3.15 12.05 9.35 22.8 38.45 39.6 87.05 50.45.15 0 .3.05v-65.7q-.6.1-1.25.25.05-.05.1-.05-36.5 2.15-56.75-6.55l-.5-.2q-.9-.4-1.8-.85-2.25-.65-4.5-1.65-1-.45-2-.95-9.6-5-19.3-16.25-3.35-3.9-6.7-8.55-.35-.55-.75-1.1-2.7-3.85-5.45-8.2-.3-.55-.55-1.05.1-1.2.3-2.4v-.2m195.35 13.1q1.05-4.25 1.75-8.85.1-.75.2-1.45-.1.15-.2.35-2.85 4.5-5.65 8.5-.4.55-.75 1.1-3.35 4.65-6.7 8.55-9.7 11.25-19.3 16.25-1 .5-2 .95-2.25 1-4.5 1.65-20.4 9.9-59.05 7.6 17.35 7.4 35.95 6.85 33.5.65 47.5-16.05 8.45-10 12.35-23.95l.4-1.5z"
                    id="AccHat110__Symbol_378_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M75.2 9.65q-4.25-2.15-5.85-2.4l-1.2-.2q-5.2-1.1-10.15 0-2.2.35-5.05 1.6-.6.25-1.1.55 0-3.95 6.95-6.05.65-.2 1.25-.35-.5-.2-1.1-.35l-2.85-.1q-1.95 0-3.5.25-.8.25-1.75.6-1.15.4-1.9.7-2.75 1.7-3.6 2.9-.3-.35-.55-.9-.6-1.2-.6-1.7 0-2.5 1.15-4.2-.45.1-.9.35-1.3.85-4.2 3.6l-.75.75-.2.2v-.05q-.4.3-.65.3-1.2 0-1.7-2.1Q36.7.9 36.7.8q0-.35.05-.65-.35.1-.75.35-4.3 2-6.45 7.05-.2-.1-.35-.25-2.1-3.5-7.5-4.2-2.95 0-5.45.7-2.05.55-2.85 1.3.55-.1 2.1-.05 1.5.05 2.8.55 1.3.45 3.1 1.8 1.75 1.3 2.15 1.95.35.6.55 1.15-.1-.05-.15-.1-1.9-1.25-4.3-1.75-5.6-.7-10.8.15-3.05.45-4.45 1.1-2 .9-3.7 2.65-.3.3-.6.65l-.1.25v.2q7.3-1.25 13.5 4.9 1.05 1.5 5.55 5.5 8.3 7.5 7.8 7.85 0 .15.05.3l.1-.1q3.45-3.05 6.45-6.85 5.45-4.75 9.7-1.7.9.6 1.75 1.7v.05q.75.85 1.55 1.75l.15.1-.05.05 2.8 3.25v-.05q-.4-1.4 6.6-8.4 2.5-2.5 4.2-4.35 4.3-2.15 7.25-3.5 10.25-2.75 11-2.45-2.95-1.9-3.2-2.05m-45.35-2h-.05l.1-.1-.05.1z"
                    id="AccHat110__Symbol_349_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M36.65 14.65q-.1-.3-.25-.55-2-4.1-2.45-4.7-2.95-3.9-4-4.75-2.8-2.25-6.6-3.1Q16.3.1 11.5 3.45q.05 0 .1.05h.2q3.85 0 6.95 4.15-3.1-.4-4.95-.4-2.45 0-4.6.9-.35.1-.6.25-.25.1-.5.25-2 1.1-2.2 1.95-.3.25-.4.6 7.85-2.55 12.9 2 .05 0 .1.05h-.1q-9.25.05-15.05 6.25-.1.15-.1.3.2-.15.9-.4.65-.3 3.7-1 3.4-.55 5.2 0 2.4.15 3.05 1.1-6.6.05-11.3 3.65-1.95 1.35-1.95 2.5 0 .2.05.4 1.1-.75 4.05-.75 2.55 0 4.35 1.2.4.25.7.55Q3.7 28.2.1 34.85q-.1.3-.1.55 0 .35.2.7.05.1.1.15.6-1.45 4.95-3 3.25-1.2 5.55-1.5-.6.35-1.2.75-.1.1-.2.15-5.95 3.3-6.8 6.7.55-.35 1.15-.6l.2-.1q2-1.05 4.1-2 .35-.2.75-.35 1.1-.5 2.25-.95 3-1.25 6.1-2.3.05-.05.15-.05.25-.1.55-.15l-.7.2.4-.1q.5-.15 1.05-.25l.2-.05q.5-.15 1-.25l.5-.1q5.05-1.05 8.45-.35l8.85-9.25.8-.7q-.05-.15-.05-.3.5-.35.5-1.5 0-1.35-2.2-5.55m56.05-2.9q-.1-.25-.35-1.25-.25-1.05-3.1-3.05-2.85-2-6.45-2.35.5-1.2 2.8-1.8 1.05-.25 2.5-.4 1.55-.2 3.65-.25Q90.7 2 89.9 1.5 88.3.9 86.85.45q-.4-.15-.7-.2Q85.8.2 84.85.1q-1-.15-1.5-.1-.5.05-.55.05-.55 0-1.1.1-.3 0-.55.05-1.3.2-2.85.7-.05 0-.1.05-8.1 1.9-12.95 7.35-1.5 1.6-2.7 3.65-2.1 3.6-2.1 6.45 0 .4.4 1.8v.05l10.9 12.6q.95-.25 2-.35 2.4-.3 5.3.15.35.05.75.1 1.15.2 2.4.55l.05.05q1.2.4 2.4.85.7.25 1.4.55.4.1.8.3l.3.1q1.15.5 2.35 1 .6.25 1.25.55l.2.1v-.1q0-.8-.35-1.65-1-1.85-3.05-4.15-1.55-1.75-3.2-2.75 2.9.75 5.65 2.6 4.25 2.85 4.25 4.95 0 .35-.05.6.25-.15.4-.45.25-.35.25-.75 0-.6-.1-1.15-1.35-6-5.7-8.8 1.5.2 3.5 1.05 2 .85 3.5 2.25.25-.55.25-.8v-.05-.1q0-1.75-4.8-5.9-5.05-4.3-9.75-4.75 1.35-.25 3.2-.25 3.75 0 6.1 2.85.45.5.75 1 .3-.35.3-1.05 0-.65-.15-1.25-3-5.9-9.6-7-.65-.15-1.4-.3 2.2-.6 6.05-.9 2.25-.05 4.35.95.85.4 1.35.85z"
                    id="AccHat110__Symbol_326_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.4}
                    d="M191.15 185.25q.054-.03.1-.05-38.65 2.3-59.05-7.6-2.25-.65-4.5-1.65-1-.45-2-.95-9.6-5-19.3-16.25-3.35-3.9-6.7-8.55-.35-.55-.75-1.1-2.71-3.87-5.5-8.2-.235-.527-.5-1.05.167.94.35 1.85.023.138.05.25.682 4.481 1.7 8.6l.4 1.5q.68 2.435 1.5 4.75 2.847 9.56 5.6 13.45 3.498 4.935 5.4 6.6 1.905 1.67 4 3.35 4.114 3.546 9.35 6 18.26 9.573 43.95 6.7 13.15-1.1 27.15-7.9-.621.123-1.25.25z"
                    id="AccHat110__Layer7_0_MEMBER_0_FILL"
                />
                <path
                    fill="#171717"
                    fillOpacity={0.851}
                    d="M183.15 222.2v-21.75h-6v21.75h6m23.75 0v-21.75h-6v21.75h6m15.75-11h-6v21.75h6V211.2m11.75-10.75v21.75h6v-21.75h-6m-74.5 10.75v21.75h6V211.2h-6m-10.25-10.75h-6v21.75h6v-21.75m-17.1-8.6h-6v21.75h6v-21.75m123.5 0h-6v21.75h6v-21.75z"
                    id="AccHat110__Layer7_0_MEMBER_1_FILL"
                />
                <g
                    id="AccHat110__Symbol_456_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M0 116.85q.1-.1.25-.2H0v.2m197.1-.3l.4.3h.15q-.05-.25-.05-.45l-.5.05h-.1l.1.1zM67.35 35.5q-.3.05-.55.15-.1 0-.15.05l.7-.2zM90.9 0l-.1.1h.05l.05-.1m16.7 19.6l-.15-.1.1.15.05-.05zM98.8 230.1h.35q-.15-.05-.3-.05l-.05.05z" />
                    <path d="M196.1 107.95q0 .1.05.2l-74.9-72.65-13.7-15.85-.1-.15q-.8-.9-1.55-1.75v-.05q0-.05-.05-.05H94.5l-.05.05-9.95 25.2-61.5 50v91.6l-12.75-7.75q.75 1.45 1.55 2.85 6.55 11.35 16.5 21.3 29.1 29.1 70.3 29.2h.2l.05-.05q.15 0 .3.05 41.05-.1 70.1-29.2 9.15-9.1 15.4-19.4 1.7-2.85 3.25-5.75l8.9-45.35 2.6-16.3q-1.2-2.05-3.15-5.85-.05-.15-.15-.3m1.4 8.9l-.4-.3-.1-.1h.1l.5-.05q0 .2.05.45h-.15m-14.1 14.4q.75-.9.4-.4l-.4.5v-.1M85.75 64.2q-.1-.4-.25-.75.1-.05.15-.05l.1.8m-45 92.6q-6.2-11.75 0-23.5H158.2q4.8 11.25 0 23.5H40.75z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat110__Symbol_456_0_Layer0_0_FILL"
                transform="translate(93.25 20.6)"
                id="AccHat110__col1n"
            />
            <use
                xlinkHref="#AccHat110__Symbol_435_0_Layer0_0_FILL"
                transform="translate(187.7 35.4)"
                id="AccHat110__col1l"
            />
            <use
                xlinkHref="#AccHat110__Symbol_400_0_Layer0_0_FILL"
                transform="translate(85.35 38.3)"
                id="AccHat110__col1d"
            />
            <use
                xlinkHref="#AccHat110__Symbol_378_0_Layer0_0_FILL"
                transform="translate(85.35 38.3)"
                id="AccHat110__col1s"
            />
            <use
                xlinkHref="#AccHat110__Symbol_349_0_Layer0_0_FILL"
                transform="translate(154.25 13.05)"
                id="AccHat110__col2n"
            />
            <use
                xlinkHref="#AccHat110__Symbol_326_0_Layer0_0_FILL"
                transform="translate(142.75 23.25)"
                id="AccHat110__col2d"
            />
            <use xlinkHref="#AccHat110__Layer7_0_MEMBER_0_FILL" />
            <use xlinkHref="#AccHat110__Layer7_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default AccHat110
