import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FE5FFE",
    "col1n": "#FF9AFF",
    "col2n": "#5F00C2"
}

function AccHat005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M188.3 40.4l-8.15-10.7q-12.7-16.6-40-23.25Q112.85-.2 101.95 0q-7 .1-13.9 1.65h.25q-.17.006-.45.1V1.7q-.7.1-1.35.3-.8.15-1.5.4l-.3.05q-8.45 2.25-16.75 6.6Q31.3 22.4 27.1 27.75q-15.3 19.4-24.05 40.2-6.5 11.6.9 24.95.1.2.25.4 3.05 5 7.3.6 3.8-4.2 9.6-6.2 5.8-2.75 8.55-7.35 3.45-4.85 7.25-8.2 6.85-6.1 14.75-7.5 12.1-.7 34.4-13.6 9.1-3.4 26.85-2.15 21.35 1.55 55.2 9.8 6.75 1.6 13.95 3.5 7.25 2.25 9-1.1l3.8-9.5q.1-.35.15-.75.4-3.75-6.7-10.45z"
                    id="AccHat005__Symbol_224_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6.5 71.6q0-8.2 3.4-16.95.95-2.45 2.1-4.7-5.1 8.85-8.95 18-6.5 11.6.9 24.95l.25.4q3.05 5 7.3.6 3.8-4.2 9.6-6.2 3.3-1.55 5.6-3.7-.05 0-8.7 1.95-8.7 1.9-11.3-8-.2-1.9-.2-6.35m168.15-44.15q3.55 3.25 7.4 10.1 4.05 7.25 4.05 11.1 0 4.8-2.15 7.6-2.45 3.65-8.45 3.65h-1.1q-.3 0-.5-.05-.7 0-1.3-.05 4.6 1.15 9.45 2.4 7.25 2.25 9-1.1l3.8-9.5q.1-.35.15-.75.4-3.75-6.7-10.45l-8.15-10.7q-12.7-16.6-40-23.25Q112.85-.2 101.95 0q-7 .1-13.9 1.65h.25q-.17.006-.45.1V1.7q-.7.1-1.35.3-.8.15-1.5.4l-.3.05q-8.45 2.25-16.75 6.6-11.55 4.2-19.85 7.65l-.75.3q-.8.3-1.55.65l-.35.15q-15.7 6.55-18.35 9.95-6.45 8.2-11.75 16.65 2.4-3.4 5.35-6.15 4.3-4.05 10.25-7.9 8.8-4.65 17.05-7.8 1.55-.45 3-.85 1.5-.55 3.7-1.25 3.75-1.2 5.95-1.85 1.5-.45 3.7-1.05 6.95-2 12.25-2 2.35 0 7.3 1.6 5.5 1.7 10.85 2.15h4.9q.05 0 .4-.1h1.3q4.4-.45 7.7-1.9 1.65-.7 5.4-3 .3-.2.7-.45.45-.15 1.05-.4 2.8-1.15 3.4-1.3 3.45-1.05 10.5-1.5h3.35q6.25.1 11.2 1.85 4.95 1.65 6.75 2.15 4.7 1.9 9.8 4.55 9.75 4.85 13.45 8.25m-25.1-16.6q.3.1.6.25-.3-.1-.55-.2l-.05-.05m-74.8 2.7q.2 0 .45.05-1.4.05-2.55.15 1.05-.2 2.1-.2z"
                    id="AccHat005__Symbol_509_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.25 8.45q2.3-1.3 2.3-2.95 0-2.35-4.5-3.95Q21.6 0 15.3 0h-1.1q-.45 0-.8.05-5.15.2-8.95 1.5-4 1.5-4.4 3.55v.05Q0 5.3 0 5.5q0 1.8 2.65 3.15.75.4 1.8.8.25.05.55.15 4.3 1.45 10.3 1.45 3.15 0 5.9-.4h.15q2.25-.4 4.15-1 .3-.15.55-.2.8-.3 1.45-.6.4-.2.75-.4z"
                    id="AccHat005__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat005__Symbol_224_0_Layer0_0_FILL"
                transform="translate(88.7 46.9)"
                id="AccHat005__col1n"
            />
            <use
                xlinkHref="#AccHat005__Symbol_509_0_Layer0_0_FILL"
                transform="translate(88.7 46.9)"
                id="AccHat005__col1d"
            />
            <use
                xlinkHref="#AccHat005__Symbol_51_0_Layer0_0_FILL"
                transform="translate(170.5 50.05)"
                id="AccHat005__col2n"
            />
        </g></g>
    )
}

export default AccHat005
