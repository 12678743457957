import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#479EEC",
    "col1n": "#77BFFF",
    "col2l": "#E0F0FF",
    "col2n": "#B4DBFF"
}

function ObjMor001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M54.05 24.4q2.6-3.35 3.8-7.2 1.15-3.75 1.1-7.95-.05-.7-.05-1.4-.3-3.2-2.1-5.05-1.75-1.55-4.2-2.3Q50.9.05 49 0q-4.05-.1-7.85 1.15-.7.2-1.35.4-.25.05-.45.15h-.1q-9.95 3.55-19.65 0-.85-.25-1.8-.55Q14-.1 9.95 0q-1.9.05-3.6.5-2.45.75-4.2 2.3Q.35 4.65.05 7.85q0 .7-.05 1.4-.05 4.2 1.1 7.95 1.35 4.25 4.35 7.9 4.5 4.85 10.95 7.2 4.65 1.55 9.95 2.5.45 0 .9.05 1 .05 1.95.05h.55q1.9 0 3.85-.2 4.8-.7 9.3-2.3 7.05-2.8 11.15-8z"
                    id="ObjMor001__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M57.75 6.4q.2.75.35 1.6.9 4.6-.25 9.2 1.15-3.75 1.1-7.95-.05-.7-.05-1.4-.3-3.2-2.1-5.05-1.75-1.55-4.2-2.3Q50.9.05 49 0q-4.05-.1-7.85 1.15-.7.2-1.35.4-.25.05-.45.15h-.1q-9.95 3.55-19.65 0-.85-.25-1.8-.55Q14-.1 9.95 0q-1.9.05-3.6.5-2.45.75-4.2 2.3Q.35 4.65.05 7.85q0 .7-.05 1.4-.05 4.2 1.1 7.95Q-.05 12.6.85 8q.15-.85.35-1.6.8-2.3 2.85-3.55.15-.05.25-.15 2.65-1.45 5.6-1.3 3.9.35 7.75 1.4.55.15 1 .3l1.7.5 2.75.8q6.45 1.45 12.75 0l2.75-.8 1.7-.5q.45-.15 1-.3 3.85-1.05 7.75-1.4 2.95-.15 5.6 1.3.1.1.25.15 2.05 1.25 2.85 3.55z"
                    id="ObjMor001__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M19.25 3.75q-.9-1.2-2.15-1.9Q14.35.1 10.65 0H10.1Q6.45.1 3.65 1.85q-1.25.7-2.15 1.9Q-.55 6.4.2 9.85q.3-.3.6-.55.15-.15.3-.25t.25-.2q1.25-.95 2.3-.55.35.15.55.45.05.05.05.15.35 1.1-1 2.35l-.15.15q-.6.55-1.05 1.1.25.25.55.5.15.1.35.25 3 2.15 7.15 2.25H10.65q4.15-.1 7.15-2.25.2-.15.35-.25.3-.25.55-.5-.45-.55-1.05-1.1l-.15-.15q-1.35-1.25-1-2.35 0-.1.05-.15.2-.3.55-.45 1.05-.4 2.3.55.1.1.25.2t.3.25q.3.25.6.55.75-3.45-1.3-6.1z"
                    id="ObjMor001__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M24.1 2.1q-.2-.25-.4-.45-.3-.3-.6-.55-.15-.15-.3-.25t-.25-.2Q21.3-.3 20.25.1q-.35.15-.55.45-.05.05-.05.15-.35 1.1 1 2.35l.15.15q.6.55 1.05 1.1.15.2.3.45.3.4.55.8 1.05 1.9 1.05 4.25 0 2.95-1.7 5.2-.5.6-1.05 1.2-.1.05-.2.15-.15.1-.25.25-2.85 2.45-6.75 2.5H13.25q-3.9-.05-6.75-2.5-.1-.15-.25-.25-.1-.1-.2-.15Q5.5 15.6 5 15q-1.7-2.25-1.7-5.2 0-2.35 1.05-4.25.25-.4.55-.8.15-.25.3-.45.45-.55 1.05-1.1l.15-.15Q7.75 1.8 7.4.7q0-.1-.05-.15-.2-.3-.55-.45-1.05-.4-2.3.55-.1.1-.25.2t-.3.25q-.3.25-.6.55-.2.2-.4.45Q0 5.35 0 9.75q0 2.2.75 4.15.75 1.8 2.1 3.4.5.55 1.1 1.05 3.85 3.5 9.3 3.6h.55q5.45-.1 9.3-3.6.6-.5 1.1-1.05 1.35-1.6 2.1-3.4.75-1.95.75-4.15 0-4.4-2.95-7.65z"
                    id="ObjMor001__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor001__Symbol_115_0_Layer0_0_FILL"
                transform="translate(163.05 203.1)"
                id="ObjMor001__col1n"
            />
            <use
                xlinkHref="#ObjMor001__Symbol_63_0_Layer0_0_FILL"
                transform="translate(163.05 203.1)"
                id="ObjMor001__col1d"
            />
            <use
                xlinkHref="#ObjMor001__Symbol_21_0_Layer0_0_FILL"
                transform="translate(182.15 213.9)"
                id="ObjMor001__col2n"
            />
            <use
                xlinkHref="#ObjMor001__Symbol_15_0_Layer0_0_FILL"
                transform="translate(179 222.1)"
                id="ObjMor001__col2l"
            />
        </g></g>
    )
}

export default ObjMor001
