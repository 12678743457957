import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1l": "#FF5D5D",
    "col1n": "#FF0000"
}

function ObjFoo016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M48.4 6.1v-.3-.15-.2l-.1-4.15q-.1-2.5-7.25-.1L30.3 5.9 4.7 7.7q.057-.007.1-.05-.3.05-.65.1h-.2q-1 .15-2.3.4-1.95.3-1.6 1.45L2 16.4q.05.15.15.35.45 1.45.45 2.9 0 2.3.4 3.85.3 1.8 1.5 3.95.8 1.6 3.15 3.9.35.35.7.75 2.05.15 4.3.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.6.25-1.15.4 0 .25.05.55.2 1.35 0 2.9-.3 1.35-.45 1.8-.3 1.2-.7 2.6-.45 1.4-.6 2.25-.3 1.05-.3 1.7-.05 1.2 1.85.95 1.95-.15 4.65-.7 2.5-.65 4.25-.5 1.75.1 8.3.55 6.5.4 8.6-.8 2.1-1.2 3.9-2.3 1.8-1.2 8.85-4.1 7.05-3 7.05-5.45-2.5-7.8-2.6-10.6L56 33.5q.4-4.45.2-5.15 0-.5-2.15-4.05-2.1-3.55-2.55-5.1-.6-1.6-1.8-5.3-.1-.05-.1-.2-1.15-3.65-1.2-7.6z"
                    id="ObjFoo016__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M17.6 39q-.15-.75-.35-1.1-.45-.7-.95-.6-.3.05-.55.3-.3.15-.55.55-1.1 1.7-1.1 2.75.1 3.15 4.45 3.15 2.25 0 2.5-1.45-.1-.45-.25-.45-.45 0-1.4-.4-.95-.6-1.1-.95-.2-.3-.7-1.8M.8 5.9q.95 1.45 2.15 1.7-.25-.25-.35-.7l-.25-1.5q0-1.3 1.6-2.4.5-.25.95-.55Q4.9 0 2.4.1-.1.2 0 3.85q0 .6.8 2.05m19.5-2.65h-.05Q16.5 6.5 16.5 8.3q0 .05.35 2.3.65 2.05 2.1 2.3.8-.4 1.95-.85.15-.2.3-.45V11q0-.7-.4-1.5t-.4-1.85q0-1.6 1.35-3.1 1.3-1.55 1.3-1.9 0-.1-.15-.2-.2-.15-.35-.15-.45 0-2.25.95M41.85 29.8q0-1.35-.65-2.4-.9-1.55-5.75-2.25.5.45 1.35 1.95.3.35.45 1.05.35.85.35 2.25-.1 2.5-.6 4.8l-.05.7q0 .2.25.45l.6.05q.9 0 1.85-1.45.95-1.7 1.2-2.15l.8-1.5q.2-.8.2-1.5M44 7.7q-.15-.3-1.75-2.4-1.1-1.5-1.4-2.45-.55-1.7-.9-2.85-.8.6-1.9 1.3-2 1.3-4 2.45-3.9 2.05-7.25 6.05-1.6 1.7-4.4 2.8-3.5 1.2-5.1 2.35-.8.55-4.25 3.25-1.15.9-1.95 1.5 1.6 3.55 1.2 7.8-.1.75-.25 1.5l-.1.55q-.85 3.3-2.5 5 .1.7.2 1.35.2 1.15 1.3 3.85.2.45.35.8.2-.35.5-.8 1.25-2 1.85-2.55 1.25-1.35 3.4-2 1.2-.4 4.2-.9.85-.15 1.6-.3.45-.2 1-.45 2.85-1.2 4.6-3.3.35-.45 3.85-5 .5-.7 1.45-1.3.45-.45 1.15-1 2.1-1.55 4.5-2.2 2.6-.8 4.8-2.05l1.85-1.05q-.25-.65-.25-2.05-.3-2.1-.3-2.8 0-2.55-1.5-5.1z"
                    id="ObjFoo016__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M23.55 66.35q.1-.05.25-.1l-.45-16.7L10.4 60.2q.2.15.55.35.95.4 1.4.4.15 0 .25.45-.25 1.45-2.5 1.45-1.5 0-2.5-.35l-5.8 4.75q.3-.1.6-.15 2.5-.65 4.25-.5 1.75.1 8.3.55 6.5.4 8.6-.8M27.6 19.4q-.2.15-.45.3l-.05.05q0 .05-.05.05-2.4 1.75-3.35 2.6-.3.25-2.75 1.9l-1.2 1.2q-2.2 2.1-3.75 3.4-.7.6-1.85 1.8-1.15 1-2.8 1.3-1 .15-1.8.45-.45.25-.7.45-.2.1-.35.2-.7.45-1.65 1.3l-.8.55q-.75.8-2.5 1.9-.4.25-1.25.9l.6 1.3q.05-.05.15-.1l4.2-2.7q2.65-1.65 4-2.7.75-.65 2.05-1.15.75-.3 2.45-.95 1.8-.75 3.5-2.95 1.8-2.4 3.3-3.15 4.55-2.4 4.9-2.65 2.3-1.35 3.8-2.95 0 2.85 2.5 5.85 1.5 1.8 2.1 3.55.25.15.65 2.75.45 2.7.45 3.6 0 1.15-4.1 2.8-4.2 1.5-4.4 1.55-2.3.7-4.1 2.85-1.05 1.3-3.1 4.05-4.6 5-10.8 5.8-3.4.45-5.2 2.4-1 1.15-2.7 3.5-.6-1.75-.95-2.75-.4-1.3-.5-2.45l-.2.2q-.45.4-.9.75.25 1.05.8 2.1l1.75 4.4q.2-.2.5-.4 1-.7 1.55-1.85.95-2.25 1.95-2.85h.05q.3-.55 1.6-1.05 1.4-.55 2.3-.6 2.8-.3 5-1.15 2.35-1 4.05-2.4.8-.9 1.45-1.55l.55-.55q.35-.55.8-1.05 1-1.55 1.6-2.35 1-1.35 2.95-2.2.8-.4 4.05-1.4 2.6-.85 3.85-1.75 1.3-1.05 2.25-1.8l.2-.15.1-.1.7-.6q-.2-.8-.3-1.95-.4-5.4-1.7-8.8-.15-.55-1.25-1.65-1.05-1.1-1.2-1.5-1.05-2.05-1.05-3.15-.05-.35-.9-3.15-.1-.4-.2-.75V16q-.95 1.15-3.85 3.4m5.35-13.75v-.2l-.1-4.15q-.1-2.5-7.25-.1 3.1-.5 3.85-.5 2.7 0 2.7.8l-.05.2q0 .2-.05.45t-.2.6q-1.6 3.5-2.95 4.85-1.8 1.65-6.5 3.6-.95.45-2 .85l-.75 10.3 14.1-10.1q-.75-3-.8-6.15v-.3-.15z"
                    id="ObjFoo016__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill="#FF0"
                    d="M117.45 259.25q-.042.058-.1.1-1.905 2.44-1.65 5.65h-.6q-2.1 0-3.6 1.9-.5.8-.9 1.7.05.1.15.2.192.256.45.45.253.233.55.4.12.049.2.1.437.176.95.25.047.04.1.05.714.15 1.6.15.4 0 3.05-.3.95-.1 2.15-.25h.45q.65 0 1.75.1.06-.147.1-.3v.3q1.7.05 2.2.25.1 0 .2.1.6.4 2.15.85 2.5.55 5.25-.75 3.25-1.6 4.85-2.05.45-.2 1.2-.45.7-.3 1.3-.45.7-.2 1.3-.5 1.85-.6 3.4-1.3 4.7-1.95 6.5-3.6-.35-.5-.7-.85-1.85-2.05-4.55-2.05-1 0-1.95.35-.818-2.147-3.65-3.3-.935-.35-2-.35-2.2 0-5.1 3.15-.1.1-.15.25-.3.5-.5 1.1-.3.45-.45 2.35v-.2q-.1.5-.1.95v.15q-.4-.1-.65-.1-1.15 0-2.05.4-.06-.482-.05-.6-.3-1.05-.4-1.25-.4-.95-.85-1.7-.25-.5-.65-.9l-.1-.2q-2.4-2.05-4.45-2.05-2.65 0-4.65 2.25z"
                    id="ObjFoo016__Layer7_1_FILL"
                />
                <path
                    fill="#FC0"
                    d="M143.25 259.3q-.28-.57-.25-.6.018-.039-1.35.55-1.36.596-2.85 2.35-1.432 1.805-1.45 3.85-2.978-2.686-6.05-2.6v.55q2.25.2 3.85 2.15 1.05 1.2 1.6 2.6.45-.2 1.2-.45.7-.3 1.3-.45-.15-.6-.15-1.35 0-2.85 1.85-4.9.95-1.2 2.3-1.7M115.7 265q1.85.2 3.15 1.9.95 1.25 1.4 2.75l1.85.05v.05q1.7.05 2.2.25.2-2.6 1.8-4.45 1.1-1.4 2.5-1.85-.06-.482-.05-.6-3.532.64-5 2.45-1.07 1.322-1.35 2.95-.132-1.61-2.45-3.2-1.663-1.175-4.15-.95.1.097.1.25v.4z"
                    id="ObjFoo016__Layer7_2_FILL"
                />
                <g id="ObjFoo016__Layer7_0_FILL">
                    <path
                        fill="#FFF"
                        d="M128.9 298.5q-.8-.8-.9-.85-.7-.4-1.9-.55-.2-.05-.35-.05-.193.03-.4.05.169 1.308.1 2.65.385-.3 1.05-.3.2 0 .45.1.45.05.6.45l1.55-1.3-.2-.2m-.4 3.1q.35.6.25 1.15-.1.45-.55.9l-.4.2q-.3.1-.7.1-1.15 0-1.75-1.3-.127-.27-.25-.55l-.05.5q-.428 1.616-1.05 2.85.714.7 1.7.85.15.1.45.1 1.65.15 3.25-1.05 1.55-1.1 1.75-2.45.4-1.35-.6-2.85l-2.05 1.55m23.8-17.65q1-.45 1.35-.1.2.2 0 .6-.1.25-.5.55-.5.35-.8.35-.4 0-.75-.35l-2.1 1.65q1 1.05 2.2 1.05 1.2 0 2.65-1.05 1.45-1.1 1.7-2.35.4-1.1-.5-2.3-.4-.45-.95-.7-.5-.25-1.1-.25-.6.15-1.35.5-.72.32-.5.25l-.6.2q-1.082.361-1.4.05-.256-.254-.15-.4.05-.25.35-.6.5-.25.85-.35.15 0 .55.35l2.05-1.65q-.85-.9-2.05-.9-1.1 0-2.55.9-1.25 1.15-1.6 2.25-.4 1.2.4 2.2.45.6.95.8.45.15 1.05.15.25 0 .9-.1.55-.15 1.5-.65.15 0 .4-.1m-21.7 10l-2.4 1.35 5.35 7.1 2.45-1.2-2.25-3.05 2.35-1.35 2.15 3.15 2.1-1.65L135 291l-2.05 1.7 1.45 2.2-2.1 1.45-1.7-2.4m10.9-8.2l-1.75 1.75 5.15 6.85 2-2.1-1.65-2.05 1.25-1.05q1.3-1 1.5-2.1.2-1.15-.65-2.25-.45-.75-1.1-1-.55-.3-1.35-.2-.5 0-1.2.25-.5.3-2.2 1.9m2.15.75q.552-.598.9-.6.35 0 .75.35.3.4.1.85-.1.3-1.25 1.5l-1.05-1.35.55-.75m-5 2.25l-2.45 1.4 5.5 7.35 1.8-2.25-4.85-6.5z"
                    />
                    <path
                        fill="#B4B4B4"
                        d="M110.9 261.9q-1.3.35-2 .6-.8.3-1.25.6-.7.2-.7.7-.05.55.65 1.4 1.85 2.5 3 3.4l.05.1 39.65-6.75q.092-.047.15-.1 1.35-1.35 2.95-4.85.15-.35.2-.6.05-.25.05-.45l.05-.2q0-.8-2.7-.8-.75 0-3.85.5-.75.25-1.55.55-2.25.9-4.4.85-3.804.423-8.25 1.8-4.397 1.377-7.45.9l-2.2.3q-.987.16-1.9.3-8.491 1.393-10.5 1.75z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo016__Symbol_88_0_Layer0_0_FILL"
                transform="translate(106.1 254.25)"
                id="ObjFoo016__col1n"
            />
            <use
                xlinkHref="#ObjFoo016__Symbol_89_0_Layer0_0_FILL"
                transform="translate(113.1 273.05)"
                id="ObjFoo016__col1d"
            />
            <use
                xlinkHref="#ObjFoo016__Symbol_90_0_Layer0_0_FILL"
                transform="translate(121.55 254.25)"
                id="ObjFoo016__col1l"
            />
            <use xlinkHref="#ObjFoo016__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo016__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo016__Layer7_2_FILL" />
        </g></g>
    )
}

export default ObjFoo016
