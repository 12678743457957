import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#D24400",
    "col1n": "#9A3200"
}

function ObjFoo039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.8 14.75Q48.7 2.5 63.4 2.05 63.3.3 60.35 0 43.2-.3 37.25 14.75q-.1.25-.2.55l-.2.55q-1.75 5-3.3 9v.05q-1.15 2.95-2.15 5.4-5.5 13.05-8.2 10.75-3.9-4.75-9.35-6-.65-.15-1.25-.2-3.1.9-6.95 3.3Q1.8 40.5.15 43.9 0 44.9 0 46.05q-.1 5.8 3.9 10.85 1.15 1.5 2.5 2.65.2.2.4.35.7.85 1.85 1.65.7.5 1.5.85 2.1-1.9 5-1.9 3.15 0 5.4 2.25.15.15.25.3 2.25-1 4.3-3.5 1.9-2.35 3.65-6.1Q35.5 42.3 39.95 30.3q1-2.7 1.9-5.4h-.05q.05-.001.05-.05 1.5-4.4 2.65-8.95 0-.1.05-.2.1-.5.25-.95z"
                    id="ObjFoo039__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M3.25 12.25q4.7.85 8.55-2.35 3.25-2.25 3.1-6.95l-.1-.5Q14.4.5 12.45.15 7.6-.55 4 2.3.7 4.9 0 9.2q.15 1.7 2.35 2.7.4.15.9.35z"
                    id="ObjFoo039__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    d="M119.05 264.65q-1 2.05-.05 3.45.9 1.4 3.2 1.3 2.3-.1 4.65-1.65.45-.3.8-.55 1.75-1.35 2.55-3.05 1-2.1.1-3.5t-3.25-1.25q-2.3.05-4.6 1.6-2.4 1.55-3.4 3.65z"
                    id="ObjFoo039__Layer7_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo039__Symbol_77_0_Layer0_0_FILL"
                transform="translate(116.8 223.45)"
                id="ObjFoo039__col1n"
            />
            <use
                xlinkHref="#ObjFoo039__Symbol_31_0_Layer0_0_FILL"
                transform="translate(116.95 258.15)"
                id="ObjFoo039__col1l"
            />
            <use xlinkHref="#ObjFoo039__Layer7_0_FILL" />
        </g></g>
    )
}

export default ObjFoo039
