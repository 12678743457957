import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#830000"
}

function ObjToo018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M85.55 36.7h-.05q-3.15 0-5.4-2.25-2.25-2.25-2.25-5.4 0-2.3 1.25-4.15l-5.2-9.45q-1.05-1.8-3.6-.9h-.05q-.55-.1-1.05-.05-.25-1.5-1.8-3.3-.4-.45-.65-.7-.75-.9-1.8-1.8-.25-.25-.5-.45-.1-.1-.25-.2-1.15-.95-2.35-1.85Q54.5.75 45 .2 29.75-1.25 17.3 8.3q-1.75 1.3-3.25 2.9-10.25 9.1-13 23.7-3.1 15.7 2.75 30.5 3.1 5.15 7.85 7.25 5.95 2.55 14.25 0 10.8-3.5 20.05-3.9 9.1.15 17.7 6.6.1.1.25.2 5.25 4.05 11.95 4.05 4.6 0 9.2-.65 4.35-.65 8.75-1.55 13.9-3.7 22.85-.4.65.25 1.35.55 1.7.75 3.45 1.35.1-.05 1.4.2 1.3.25 3.1-.05l1-.4q-2.95-.7-6.25-2.3-1.7-.85-3.2-1.45-.25-.1-.45-.2-.4-.2-.85-.35-8.85-3.15-19.35 0-.85.15-1.6.3-14.05 2.75-23.7 1.4-1.6-.45-3.1-1.15l-.9-.45q-.8-.5-1.6-1.05-7.95-6.75-20.2-7.25-5.75-.35-12.15 1-14.8 4.55-19.45 2.25-3-1.25-5.25-4-.35-.4-.6-.85-.6-1.45-1.05-2.75Q3 49.1 5.2 35.45q.65-3.45 2-6.75 1.9-4.7 5.3-8.4Q22.8 6.9 38.95 5.8q4.4 0 8.75.65 6.4.8 11.35 4.8.8.7 2.15 2.05 1.1 1.15 1.3 1.3.7.75 1.2 1.45.45.5 1.05.85h-.05q-.35.5-.4.9-.2.1-.35.25-1.6 1.7-1.1 3.35l4.6 8.4h.1q4.1.35 8.05 3.35 2.95 2.3 4.5 5.1 2.05 4.9-.25 7.6-.5.5-1.05.9-.75.5-1.55.85l4.7 8.6q.1.15.25.25l10.95-5.65q-.1-.35-.3-.75l-7.3-13.35z"
                    id="ObjToo018__Symbol_178_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M16.65 39.8l1.1 1.9q1.1 1.3 3.35.85 3.7-1.1 6.4-3.4 2.15-1.7 1.15-3.6l-1-1.8q-4.65 4.45-11 6.05M7.25.8q-.5-.55-1.2-.75Q5.5-.05 5 0q.05.3.05.6 0 .75-.5 1.35-.75.85-2.25.85-.95 0-1.75-.4H.5q-.35.5-.4.9-.25.75.15 1.5.45.75 1.25.55 3.4-.95 5.7-3.2.55-.65.05-1.35z"
                    id="ObjToo018__Symbol_62_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo018__Symbol_178_0_Layer0_0_FILL"
                transform="translate(46.45 262.55)"
                id="ObjToo018__col1n"
            />
            <use
                xlinkHref="#ObjToo018__Symbol_62_0_Layer0_0_FILL"
                transform="translate(110.65 277.05)"
                id="ObjToo018__col2n"
            />
        </g></g>
    )
}

export default ObjToo018
