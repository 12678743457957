import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF9D43",
    "col1n": "#FEBE81",
    "col1s": "#E26D00",
    "col2n": "#FFFFFF"
}

function ShiLon104({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M16.2 87.1q.05 0 .05.05h.1q19.65 4.9 38.6 6v-3.4q-18.95-1.1-38.6-6h-.1l-.05 3.35m6.75-33.65v-.1Q12.55 44.8.8 52q-.1.25-.1.5-.15.65-.25 1.3-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.45-.3 11.9 3.95.3.25.55.55.1.05.15.15.05 0 .05.05v.1l.35-.05.05-.15-.05-.05v-.2l.75-3.65m32-26.45v-3.75Q34.25 19.85 26.7 3.2l-.05-.05q-1.2-.85-2.35-1.75L22.45 0q-.1.05-.15.15-.05.1-.1.15-.3.4-.6.85 3 7.6 9.25 13.9Q41.1 25.4 54.95 27m53.1 62.95v3.65q20.2-1.3 38.85-4.95l1.55-.25v-3.8l-.5.1v-.05l-1.4.3q-18 3.85-38.5 5M138.1 3.4q-2.7 4.6-6.75 8.65-9.9 10.05-23.25 11.85v2.25h-.05v.95q14.7-1.2 25.4-12.05 6.5-6.55 9.5-14.5-.2-.3-.4-.55l-1.85 1.4q-.6.5-1.25.95-.65.5-1.35 1.05m26.95 52.8q0-.15-.05-.35-.25-1.45-.55-2.9v-.15l-.1-.4q-.05-.2-.05-.4-11.8-7.2-22.15 1.35l.05.7.6 3.05v.25l.25.1q.05-.1.1-.1 4.5-4.95 12.5-4.6l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2z"
                    id="ShiLon104__Symbol_505_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.45.1q-.2.1-.25.3L0 21.85v.2l.05.05q0 .05.05.1v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1M116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.1.05.15.05.15.05.25 0 .2-.05.3-.2v-.05q.1-.05.1-.1v-.05-.2L116.4.4z"
                    id="ShiLon104__Symbol_471_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3 45.45q0 .8.6 1.35.6.6 1.4.6.8 0 1.4-.6.6-.55.6-1.35v-.05q0-.55-.25-1-.15-.2-.35-.4-.6-.6-1.4-.6-.8 0-1.4.6-.2.2-.3.4-.3.45-.3 1v.05M2 29.7q-.8 0-1.4.6-.15.1-.2.3-.4.45-.4 1.1 0 .8.6 1.4.45.45 1 .55.2.05.4.05.8 0 1.4-.6.25-.25.45-.55v-.05q.15-.35.15-.8 0-.65-.35-1.1-.1-.2-.25-.3-.1-.1-.2-.15-.55-.45-1.2-.45m2.4-15.1q-.15-.3-.4-.5-.15-.15-.35-.25-.45-.35-1.05-.35-.8 0-1.4.6-.6.55-.6 1.4 0 .6.4 1.15.05.1.2.25.5.5 1.15.6h.25q.35 0 .65-.1.4-.15.75-.5.15-.15.2-.25.4-.55.4-1.15 0-.5-.2-.9m2-11.2q.45-.45.55-.95Q7 2.2 7 2q0-.8-.6-1.4Q5.8 0 5 0q-.4 0-.75.15T3.6.6Q3 1.2 3 2q0 .8.6 1.4.6.6 1.4.6.8 0 1.4-.6z"
                    id="ShiLon104__Symbol_448_0_Layer0_0_FILL"
                />
                <g
                    id="ShiLon104__Symbol_544_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M53.7 45.6q.55-1.85.75-2.8.35-1.6.35-2.15-.05-.6.15-3.25V23.25Q34.25 19.85 26.7 3.2l-.05-.05q-1.2-.85-2.35-1.75L22.45 0q-.1.05-.15.15-.05.1-.1.15-.3.4-.6.85-2.65 3.6-5 7.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.1.25-.1.5-.15.65-.25 1.3-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.45-.3 11.9 3.95.3.25.55.55.1.05.15.15.05 0 .05.05v.1l-2.8 11.65q-.55 2.1-1.05 4.2l-.9 3.6-.85 3.35-.05 6.75q.05 0 .05.05h.1q19.65 4.9 38.6 6v-18.1q-.55-2.4-1.85-7-2.25-7.65-2.25-11.25 0-2.75.9-5.4 1.25-3.25 1.95-5.8m109-.7q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.7-.9-1.35-1.8-.2-.3-.4-.55l-1.85 1.4q-.6.5-1.25.95-.65.5-1.35 1.05-2.7 4.6-6.75 8.65-9.9 10.05-23.25 11.85v2.25h-.05v11.7q.05.2.05 1.05-.05.8.1 1.45.1.65 1 3 .9 2.3 1.05 3.35.15 1 .25 2.35.05 1.35 0 2-.05.65-1.2 3.3t-1.15 5.9q0 3.2 1.55 7.35 0 .05.05.05.25 1.2.6 2.4.3 1.15.3 3 0 2.35-1 5.85-.7 2.4-1 3.8-.3.7-.4 1.55-.3 1.65-.2 3.8v5.55q20.2-1.3 38.85-4.95l1.55-.25v-8.95L143.6 59.9h-.05l-.55-2.45h.05q.05-.1.1-.1 4.5-4.95 12.5-4.6l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.25-1.45-.55-2.9v-.15l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5zM67.25 48.75l-.25.05h1.95q-.95-.2-1.7-.05zM86.9 47.9h.75q.1-.05.3-.1-.55.05-1.05.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon104__Symbol_544_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon104__col1n"
            />
            <use
                xlinkHref="#ShiLon104__Symbol_505_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon104__col1d"
            />
            <use
                xlinkHref="#ShiLon104__Symbol_471_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiLon104__col1s"
            />
            <use
                xlinkHref="#ShiLon104__Symbol_448_0_Layer0_0_FILL"
                transform="translate(152.8 261.45)"
                id="ShiLon104__col2n"
            />
        </g></g>
    )
}

export default ShiLon104
