import { AssetSvgProps } from "../../../shared/assets"

function ObjMus018({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#444"
                    d="M256.5 349.3l11.25 5.1 31.35-.05v-5.05h-42.6m17.8-8.8v-4.4l-24.6 5.6 14.95 1.5 9.65-2.7m-112.7 9.65q-.245 6.513-.9 9.35h73.2l52.8 15.3q2.95-3.638 0-5.9l-52.8-18.8h-72.3v.05M99.1 368.9q-2.517 3.179 0 5.9l52.8-15.3h6.75q.048-.113.05-.25.653-2.66.9-9.15h-7.7l-52.8 18.8m-12.4-14.55l31.35.05 11.25-5.1H86.7v5.05m24.8-13.85l9.7 2.7 14.9-1.5-24.6-5.6v4.4z"
                    id="ObjMus018__Layer13_1_FILL"
                />
                <path
                    fill="#999"
                    d="M281.75 253.3q-9.55 0-17.2 2.7-.2 2.15-1.05 4.15 8.05-3.1 18.25-3.1 14.15 0 24.15 5.95 9.85 5.9 10 14.2v-3.75q-.15-8.3-10-14.2-10-5.95-24.15-5.95m-33.2 19.25q-.45.15-.85.35v2.9q.2-1.7.85-3.25m16.1-28.7q-.2-8.7-10.55-14.9-10.5-6.25-25.35-6.25-14.9 0-25.4 6.25-9.5 5.65-10.4 13.45v3.95q.1-1.25.5-2.4 1.9-6.3 9.9-11.05 10.5-6.3 25.4-6.25 14.85-.05 25.35 6.25 10.35 6.2 10.55 14.9v-3.95m-128.55 28.4q1 2.35 1.05 4.95v-3.75-.8q-.55-.2-1.05-.4m-15.2-16q-7.9-2.95-17.9-2.95-14.15 0-24.15 5.95-9 5.4-9.9 12.8v3.75q.9-7.4 9.9-12.8 10-5.95 24.15-5.95 10.75 0 19.1 3.45-.95-2.05-1.2-4.25m10.45-23.35q10.5-6.3 25.4-6.25 14.85-.05 25.35 6.25 8.65 5.15 10.2 12.1.3 1.35.35 2.8v-3.95q-.05-.2-.05-.35v-.45q-.05-.3-.05-.5-.9-7.9-10.45-13.6-10.5-6.25-25.35-6.25-14.9 0-25.4 6.25-9.5 5.65-10.4 13.45v3.95q.9-7.8 10.4-13.45z"
                    id="ObjMus018__Layer13_2_FILL"
                />
                <path
                    fill="#444"
                    d="M167.6 280.1q-.15.65-.15 1.3 0 .35.05.7.5 4.1 7.3 7.05.15.1.35.15 7.25 3.05 17.4 3.05 10.15 0 17.4-3.05.2-.05.35-.15 6.8-2.95 7.3-7.05.05-.35.05-.7 0-.65-.15-1.3-.9-3.7-7.2-6.45-7.35-3.2-17.75-3.2t-17.75 3.2q-6.3 2.75-7.2 6.45m88.35-46.85q-5.45-3.15-9.05-4.6-6.3-2.6-16.6-2.95h-11.1q-10.95 1.5-18.65 7.7-6.531 5.244-7.05 10.4-.028.076-.05.15-.4 1.15-.5 2.4l-.05 9.7q0 .35.1.7 1.1 7.55 10.35 13.05 10.5 6.3 25.4 6.3h.35q3.061 3.306 6.25-.35 6.7-.7 12.35-2.85l-.05 12.1q.75 7.6 9.95 13.1 10 6 24.15 6 14.15 0 24.15-6 9.9-5.9 10-14.2v-6.7q-.03-1.711-.5-3.35.068-.054.1-.15.1-.3.1-1.1 0-2-2.85-5.9-2.7-3.8-4.95-5.2-5.15-3.25-9.65-4.75-5.9-1.9-14.35-2.3h-5.2q-1.65.35-4.7.65-2.85.25-3.05.3-4 1-4.95 1l-1.3-.2h-.1q.04-.1.05-.2.05-.55.1-1.15v-7.05q-.07-3.08-1.45-5.85.019-.048 0-.1-.4-.95-1.45-2.95-1.05-1.95-1.8-2.8-1.5-1.75-4-2.85M160 226.2h-2.9l-.6.1-5.9.05q-13.05 1-21.55 6.75-7.55 5.15-7.55 10.65-.398 1.28-.55 2.6l-.05 9.7v.2l.05.45q-1.479-.347-2.55-.55-3-.55-10.35-.95H96.5q-6.65.8-11.6 3.05-3.4 1.55-7.55 4.7-1.6 1.2-4.65 4.8-3.45 4.2-3.45 5.7 0 .036.1.35-.276.98-.4 2l-.05 9.2q.75 7.6 9.95 13.1 10 6 24.15 6 14.15 0 24.15-6 9.9-5.9 10-14.2v-10.45-.8q6.5 2.6 14.45 3.25.046.057.1.1v.05q1.972 2.412 3.6 1.3 1.73-1.146 2.05-1.25 14.45-.15 24.75-6.3 9.25-5.5 10.4-13.05.1-.95.15-1.9v-7.05q-.05-1.45-.35-2.8-.31-1.39-.9-2.7-.31-.94-.75-1.85-1.35-2.8-4.45-6.3-3.15-3.65-9.35-5.4-5.3-1.45-16.85-2.55z"
                    id="ObjMus018__Layer13_3_FILL"
                />
                <path
                    fill="#999"
                    d="M281.75 253.3q-9.55 0-17.2 2.7-.2 2.15-1.05 4.15 8.05-3.1 18.25-3.1 14.15 0 24.15 5.95 9.85 5.9 10 14.2v-3.75q-.15-8.3-10-14.2-10-5.95-24.15-5.95m-33.2 19.25q-.45.15-.85.35v2.9q.2-1.7.85-3.25m16.1-28.7q-.2-8.7-10.55-14.9-10.5-6.25-25.35-6.25-14.9 0-25.4 6.25-9.5 5.65-10.4 13.45v3.95q.1-1.25.5-2.4 1.9-6.3 9.9-11.05 10.5-6.3 25.4-6.25 14.85-.05 25.35 6.25 10.35 6.2 10.55 14.9v-3.95m-128.55 28.4q1 2.35 1.05 4.95v-3.75-.8q-.55-.2-1.05-.4m-15.2-16q-7.9-2.95-17.9-2.95-14.15 0-24.15 5.95-9 5.4-9.9 12.8v3.75q.9-7.4 9.9-12.8 10-5.95 24.15-5.95 10.75 0 19.1 3.45-.95-2.05-1.2-4.25m10.45-23.35q10.5-6.3 25.4-6.25 14.85-.05 25.35 6.25 8.65 5.15 10.2 12.1.3 1.35.35 2.8v-3.95q-.05-.2-.05-.35v-.45q-.05-.3-.05-.5-.9-7.9-10.45-13.6-10.5-6.25-25.35-6.25-14.9 0-25.4 6.25-9.5 5.65-10.4 13.45v3.95q.9-7.8 10.4-13.45z"
                    id="ObjMus018__Layer13_4_FILL"
                />
                <path
                    fill="#262626"
                    d="M169.95 282.1v.15q0 .45.1.85.65 3.15 6.4 5.45 1 .4 2.1.75 5.85 1.9 13.65 1.9 7.8 0 13.65-1.9 1.05-.35 2.05-.75 5.8-2.3 6.45-5.45.1-.4.1-.85v-.15q-.15-3.6-6.55-6.2-6.5-2.6-15.7-2.6t-15.75 2.6q-6.35 2.6-6.5 6.2m81.55-13.45q9.35-5.4 9.35-13.1 0-7.65-9.35-13.15-9.4-5.4-22.65-5.4-13.2 0-22.6 5.4-5.8 3.4-8 7.65-1.4 2.6-1.35 5.5-.05 7.7 9.35 13.1 9.4 5.5 22.6 5.5h.25q3.25 0 6.25-.35 9.15-1.05 16.15-5.15m-16.15-18.6q2.7.4 4.8 1.6 3.05 1.75 3.05 4.25t-3.05 4.25q-2.1 1.2-4.8 1.6-1.2.2-2.55.2-2 0-3.7-.4-2-.45-3.6-1.4-3.05-1.75-3.05-4.25t3.05-4.25q1.6-.95 3.6-1.4.55-.1 1.15-.2 1.2-.15 2.55-.15 1.35 0 2.55.15m20 32.05q-.5 1.45-.5 3 0 6.3 7.7 10.75 7.7 4.5 18.55 4.5 10.85 0 18.55-4.5 7.7-4.45 7.7-10.75t-7.7-10.8q-7.7-4.45-18.55-4.45-10.85 0-18.55 4.45-5.8 3.4-7.2 7.8m-134.45-7.8q-7.7-4.45-18.55-4.45-10.85 0-18.55 4.45-7.7 4.5-7.7 10.8 0 6.3 7.7 10.75 7.7 4.5 18.55 4.5 10.85 0 18.55-4.5 7.7-4.45 7.7-10.75t-7.7-10.8m35.2-37.3q-13.2 0-22.6 5.4-9.4 5.5-9.35 13.15-.05 7.7 9.35 13.1 7.75 4.55 18.1 5.35 2.2.15 4.5.15h1.25q12.45-.25 21.4-5.5 9.35-5.4 9.35-13.1 0-2.9-1.35-5.5-2.2-4.25-8-7.65-9.4-5.4-22.65-5.4m-2.05 12.9q1.35 0 2.55.15.4.05.75.15 2.25.4 4.05 1.45 3.05 1.75 3.05 4.25t-3.05 4.25q-1.8 1.05-4.05 1.5-1.55.3-3.3.3-1.3 0-2.45-.15-2.75-.4-4.85-1.65-3.05-1.75-3.05-4.25t3.05-4.25q2.1-1.25 4.85-1.6 1.15-.15 2.45-.15z"
                    id="ObjMus018__Layer4_2_FILL"
                />
                <path
                    fill="#B3B3B3"
                    d="M229.1 276.1v13.2h6.25v-13.55q-3 .35-6.25.35m-71.75 13.2v-13.2h-.6q-2.65 0-5.15-.2v13.4h5.75z"
                    id="ObjMus018__Layer4_3_FILL"
                />
                <path
                    fill="#B3B3B3"
                    d="M159.3 313.95v-7.75h-9.8l-.1 19.3h9.9v-4h68.3v4h9.3l.1-19.3h-9.4v7.7h-67.95q-.2 0-.35.05m71.35 5h-.1v-.1l.1.1z"
                    id="ObjMus018__Layer4_4_FILL"
                />
                <path
                    fill="#8C8C8C"
                    d="M227.6 306.2v7.7q1.25.05 2.15 1.1.9 1.1.95 2.6v-11.4h-3.1m-71.45 19.3h3.15v-4h68.3v4h3.1v-6.55h-74.55v6.55m3.15-11.55v-7.75h-3.15v10.6h.05q.2-1.05.95-1.85.95-.9 2.15-1z"
                    id="ObjMus018__Layer4_4_MEMBER_0_FILL"
                />
                <g id="ObjMus018__Layer13_0_FILL">
                    <path
                        fill="#262626"
                        d="M155.85 341.3l-32.95-5.2h-11.4q5.722 4.811 10.35 7l-27.35 2.8-7.8 3.4q21.658 4.796 32.3 4.65L91.1 366.5q2.745 4.387 8 2.4 37.852-4.56 52.8-18.8h.15q.166.243.35.5 6.295 8.783 6.25 8.9-1.66 6.842-5.9 13.85-1.946 3.212-15.15 21.05-11.25 15.196-18.5 29.1-.011.075-.05.15-4.868 9.423-15.8 45.55-8.183 26.865-14.15 74h2q5.942-46.736 14.05-73.4 10.868-35.873 15.7-45.25.271-.524.55-1.05 7.034-13.35 17.8-27.9 13.296-17.962 15.25-21.2 4.49-7.426 6.2-14.7.051-.084.05-.2 12.798-4.294 19.55-7.1 26.325 2.708 51.75 1.95 25.608 8.842 54.7 14.55 4.496.784 8-2.4l-28.1-12.6q10.834-.431 32.5-4.6l-7.8-3.4-27.2-2.75q4.462-2.507 10.2-7.05h-11.4l-32.2 5.05.6-.25-3.85.4H193.7q-11.61-2.938-24.2 0h-13.65m5.9 8.8q-.07.037-.15.05v-.05q.08.012.15 0z"
                    />
                    <path
                        fill="#444"
                        d="M161.6 350.15q.08-.013.15-.05-.07.012-.15 0v.05z"
                    />
                    <path d="M161.8 247.85q-4.4 1.2-12.7 4.75-10.65 4.55-10.65 6.6 0 .7-3.05 3.25-3.05 3.45-3.05 8.95 0 3.95.35 5.25 1.3 5.2 4 5.2.244 0 .45-.05v.3h14.45q2.445-3.023.1-6.05V276q-.054-.043-.1-.1h-.05q.12-.193.3-.5.15-.3 1.6-.45 2.2-.15 2.55-.25h.95q.1.05 1.05 1.35.015.02 0 .05-.308-.004-.65 0-2.295 3.255 0 6h10.15q-.05-.35-.05-.7 0-.65.15-1.3.9-3.7 7.2-6.45 7.35-3.2 17.75-3.2t17.75 3.2q6.3 2.75 7.2 6.45.15.65.15 1.3 0 .35-.05.7h11.5q1.833-3 0-6h-1.2q.076-.197.1-.45.2-1.3 1.95-1.9l4.25-.05q1.7.7 2.25 1.35.18.269.4.5-.728.12-1.5.2-2.349 3.61 0 6.35h12.35v-.75h.05q.2 0 2.45-1.35 2.2-1.35 2.65-1.7.9-.8 1.5-1.85.25-.45.8-1.7.25-.65.6-3.25t.35-3.85q0-2.85-.85-4.65-1.1-2.35-4.25-4.95-1.75-1.5-7.65-3.5-1.2-.45-10.55-3.4-11.65-3.7-17.1-4.95-6.85-1.6-20-3.35l-2.75-.05v-.25q-.1-.05-.25-.05-.1 0-.15.05v.25h-1.1l-.65-.1h-3.6q-.6.15-11.3 2.1-9.15 1.65-14.1 3.05z" />
                </g>
                <g id="ObjMus018__Layer4_0_FILL">
                    <path d="M286.7 374.8v-5.9l-1.15-.4v6l1.15.3m-53.9-15.3h1.1v-9.4h-1.1v9.4m-63.3-25.2v7h24.2v-7h-24.2m67.35 9.8l.05-18.6q-4.65-5.955-9.3 0v18.6q4.6 3.95 9.25 0m-1.5-54.8q-3.125-2.532-6.25 0h-1.5v16.9q3.463 4.782 9.4 0v-16.9h-1.65m-83.45 60.8v9.4h1.2v-9.4h-1.2m-52.8 24.7l.9-.25v-5.95l-.9.3v5.9m60.2-30.7v-18.6q-3.841-3.974-9.9 0v18.6q4.55 3.6 9.9 0m-1.95-54.8q-2.602-2.271-5.75 0h-2.1v16.9q5.36 2.02 9.8 0v-16.9h-1.95z" />
                    <path
                        fill="#484848"
                        d="M286.7 368.9v5.9l8-2.55v-5.75l-8 2.4m-187.6 0l-8-2.4v5.75l8 2.55v-5.9z"
                    />
                </g>
                <g id="ObjMus018__Layer4_1_FILL">
                    <path
                        fill="#FF0"
                        d="M264.65 254.35v-6.55q-.2-8.7-10.55-14.9-10.5-6.3-25.35-6.25-14.9-.05-25.4 6.25-8 4.75-9.9 11.05-.4 1.15-.5 2.4v6.55q.1-1.05.4-2.05.1-.4.25-.8 2.05-6 9.75-10.6 10.5-6.25 25.4-6.25 14.85 0 25.35 6.25 10.35 6.2 10.55 14.9z"
                    />
                    <path
                        fill="#0202DB"
                        d="M247.7 275.8v6.25q.9-7.4 9.9-12.8 10-5.95 24.15-5.95 14.15 0 24.15 5.95 9.85 5.9 10 14.2v-6.25q-.15-8.3-10-14.2-10-5.95-24.15-5.95-10.2 0-18.25 3.1-2.3 5.4-9.4 9.65-2.65 1.55-5.55 2.75-.65 1.55-.85 3.25z"
                    />
                    <path
                        fill="#01B101"
                        d="M136.1 272.25q-2.5-1.1-4.75-2.45-6.9-4.1-9.25-9.3-8.35-3.45-19.1-3.45-14.15 0-24.15 5.95-9 5.4-9.9 12.8v6.25q.9-7.4 9.9-12.8 10-5.95 24.15-5.95 14.15 0 24.15 5.95 9.05 5.45 9.9 12.85.088.486.1 1.05v-5.95q-.05-2.6-1.05-4.95z"
                    />
                    <path
                        fill="red"
                        d="M156.75 226.65q-14.9-.05-25.4 6.25-9.5 5.65-10.4 13.45v6.55q.9-7.8 10.4-13.45 10.5-6.25 25.4-6.25 14.85 0 25.35 6.25 7.7 4.6 9.8 10.6.2.65.35 1.3.35 1.45.4 3v-6.55q-.05-1.45-.35-2.8-1.55-6.95-10.2-12.1-10.5-6.3-25.35-6.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMus018__Layer13_0_FILL" />
            <use xlinkHref="#ObjMus018__Layer13_1_FILL" />
            <use xlinkHref="#ObjMus018__Layer13_2_FILL" />
            <use xlinkHref="#ObjMus018__Layer13_3_FILL" />
            <use xlinkHref="#ObjMus018__Layer13_4_FILL" />
            <use xlinkHref="#ObjMus018__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus018__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus018__Layer4_2_FILL" />
            <use xlinkHref="#ObjMus018__Layer4_3_FILL" />
            <use xlinkHref="#ObjMus018__Layer4_4_FILL" />
            <use xlinkHref="#ObjMus018__Layer4_4_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMus018
