import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacNos003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4.6 2.95q-.2-.1-.35-.15-1.95-.9-2.3-1.15-.05 0-.05-.05-.35-.25-.7-.85Q.85.05.85 0 .7.05.55.15-.5 1.75.55 2.9q.65.8 2.1.8.75 0 1.2-.1.6-.2.75-.65M21.95 0q0 .05-.35.75-.35.6-.7.85 0 .05-.05.05-.35.25-2.3 1.15-.15.05-.35.15.15.45.75.65.45.1 1.2.1 1.45 0 2.1-.8 1.05-1.15 0-2.75-.15-.1-.3-.15z"
                    id="FacNos003__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.4}
                    d="M192.6 188.85q-8.35-.3-16.05 6.6 7.85-5.05 15.85-5 8.1-.1 16.05 5-7.6-6.8-15.85-6.6z"
                    id="FacNos003__Layer2_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos003__Symbol_12_0_Layer0_0_FILL"
                transform="translate(181.15 199.2)"
                id="FacNos003__col1n"
            />
            <use xlinkHref="#FacNos003__Layer2_0_FILL" />
        </g></g>
    )
}

export default FacNos003
