import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor088({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor088__Layer10_0_FILL">
                    <path
                        fill="#FFF"
                        d="M229.8 276.25q-2.234-1.452-4.3 0-1.752-.151-1.5 2.1 1.684.75 3.6.75 1.987 0 3.65-.8 1.195-3.796-1.45-2.05z"
                    />
                    <path d="M218.75 270.25q1.616 1.302 2.65 0 2.833-.78 2.1-3-1.399-1.873-4.25 0-.5 1.399-.5 3m15.1 0q1.313.826 2.6 0 0-1.601-.5-3-2.714-1.701-4.2 0-1.39 1.774 2.1 3m-6.2-8.85l-.05.05v-.05h-.1q-.375.005-.75.05-3.075.263-5.45 2.55-.146.148-.3.3 2.235 1.856 4.6 0 1.646-.59 2-1.55.453.923 2.1 1.55 2.591 1.832 4.45 0l-.3-.3q-2.515-2.515-6-2.6h-.2z" />
                </g>
                <g id="AccMor088__Layer10_1_FILL">
                    <path d="M229.8 276.25q2.502-1.797-2.15-3h-.05q-2.753-.055-2.1 3h4.3z" />
                    <path
                        fill="#FFF"
                        d="M221.4 270.25h-2.65q0 1.601.5 3h.1v-.05q2.513-.246 2.05-2.95m15.05 0h-2.6q-1.428 1.752 2.1 2.95.5-1.377.5-2.95m-.5-3q-.57-1.608-1.8-2.95h-4.45q-1.579 2.153 2.05 2.95h4.2m-12.45 0q2.723-.34 2.1-2.95H221q-1.194 1.342-1.75 2.95h4.25z"
                    />
                </g>
                <g id="AccMor088__Layer10_2_FILL">
                    <path
                        fill="#0040D4"
                        d="M234.1 276.25q1.011-1.086 1.55-2.35.167-.287.25-.6.038-.017.05-.05-4.6-2.123-8.3 0l2.15 3q2.476.814 4.3 0m-14.85-2.95l.05.05q.012.048 0 .05.59 1.568 1.75 2.85 2.343.84 4.45 0l2.1-3q-4.162-2.507-8.35 0 .01.026 0 .05z"
                    />
                    <path
                        fill="red"
                        d="M227.6 261.4l-2 2.9q2.343 2.927 4.1 0l-2.05-2.9h-.05z"
                    />
                    <path
                        fill="#FF0"
                        d="M231.75 267.25q-4.1-1.186-8.25 0l-2.1 3q6.544 1.666 12.45 0l-2.1-3z"
                    />
                </g>
                <g id="AccMor088__Layer10_2_MEMBER_0_FILL">
                    <path
                        fill="#841D9A"
                        d="M234.1 276.25h-4.3l1.45 2.05q1.415-.615 2.6-1.8l.25-.25m-13.05 0q.129.126.25.25 1.263 1.217 2.7 1.85l1.5-2.1h-4.45z"
                    />
                    <path
                        fill="#008E29"
                        d="M219.35 273.2v.05h16.6q.009-.025 0-.05l-2.1-2.95H221.4l-2.05 2.95z"
                    />
                    <path
                        fill="#F90"
                        d="M229.7 264.3h-4.1l-2.1 2.95h8.25l-2.05-2.95z"
                    />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor088__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor088__Layer10_0_FILL" />
            <use xlinkHref="#AccMor088__Layer10_1_FILL" />
            <use xlinkHref="#AccMor088__Layer10_2_FILL" />
            <use xlinkHref="#AccMor088__Layer10_2_MEMBER_0_FILL" />
            <use
                xlinkHref="#AccMor088__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor088__col1l"
            />
        </g></g>
    )
}

export default AccMor088
