import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1n": "#EEEEEE",
    "col1s": "#AFAFAF"
}

function ObjToy065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#D9D9D9"
                    d="M172.85 283.9l.6-2.65q-3.4-4.7-9.7-4.45l-2.6 2.35q8.268.493 11.7 4.75m-48.15-13.6l-.6 2.6q7.946-1.92 12.65.7l-.9-2.95q-5.95-2.85-11.15-.35z"
                    id="ObjToy065__Layer11_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M63.95 12.75Q61.1 6.65 52.25 8q-14 1.6-24.4-5.55-5.55-4.5-12.65-.7-.7.35-1.4.75-.05.05-1.35 1.3-2.25 2.9-4.1 6.05h.15q2.65 0 4.3 1.5 1.1 1 1.8 2.7.15.25.3.55.05.15.15.4l.1.3q.35 6.05-.65 10.35-.1.3-.15.6l-.15.55q-2.4 7.5-12.4 7.5l-.9-.05H.6q-.3-.05-.6-.05.15 5.7 3.45 6.7l2.8.1q1.4-.3 3.05-1 .5-.2 1-.45.05 0 1.05-.6.6-.35 1.6-.9.65-.4.95-.6 2.1-1.4 5.45-2.15 3.3-.75 9.35-.3 6.05.4 9.2 1.75 3.1 1.3 4.1 2 .95.65 3.35 2.75 2.4 2.1 3.95 4.35 1.55 2.25 2.55 3.45 1.75 2.2 3.4 3.25 5.55 3.65 9.15-6.35 4.85-18.9.25-31.55-.3-1.05-.7-1.9z"
                    id="ObjToy065__Symbol_201_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.9 18.55q-.7 3 .95 5.6 1.65 2.6 4.65 3.3 3 .7 5.6-.95 2-1.3 2.9-3.35v-.1q.25-.6.4-1.2.65-3-1-5.6-1.65-2.6-4.65-3.3-3-.7-5.55.95-2.65 1.65-3.3 4.65m4.35-6.2q2.35-1.5 2.95-4.2.6-2.7-.85-5Q10.85.8 8.2.2q-2.75-.65-5.05.85Q.8 2.5.2 5.25-.15 6.6.1 7.9q1.1 1 1.8 2.7.15.25.3.55.05.15.15.4l.1.3q1.15.95 2.8 1.35 2.65.6 5-.85m18.15 6.5q-2.5 1.55-3.15 4.4-.65 2.85.9 5.3 1.55 2.45 4.4 3.1 2.85.65 5.3-.9 2.45-1.55 3.1-4.4.65-2.8-.9-5.25-1.55-2.5-4.4-3.1-2.8-.7-5.25.85z"
                    id="ObjToy065__Symbol_202_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M2.65 3.2q.6-.4.75-1.1.15-.7-.25-1.25Q2.8.2 2.1.05 1.4-.1.8.3.2.65.05 1.35q-.15.7.2 1.3.4.6 1.1.75.7.15 1.3-.2m14.25.75q-.4-.6-1.1-.75-.7-.2-1.3.2-.6.35-.75 1.05-.2.7.2 1.3.4.6 1.1.75.65.15 1.3-.2.55-.4.75-1.05.15-.7-.2-1.3z"
                    id="ObjToy065__Symbol_203_0_Layer0_0_FILL"
                />
                <path
                    fill="#9AFF00"
                    d="M150.55 284.15q-.9-1.45-2.55-1.85-1.7-.35-3.15.55t-1.8 2.6q-.4 1.65.5 3.1.9 1.45 2.6 1.85 1.65.35 3.1-.55 1.45-.95 1.85-2.6.35-1.65-.55-3.1m-5.3-.45l.05-.05q.7.5 1.3 1.2-1.35.8-2.5 2.65-.4-.9-.15-1.9.3-1.25 1.3-1.9m.65-.35q.85-.35 1.8-.1.9.2 1.55.85-1-.05-1.85.3-.65-.7-1.5-1.05m3.8 1.3q.65 1.05.35 2.3-.25 1.05-1 1.7l-.05-.05q-.2-2.15-1.05-3.5.85-.35 1.75-.45m-.9 4.1v.05q-.05.05-.1.15-1.1.65-2.35.4-1.3-.3-2-1.4-.05-.15-.1-.2 1.4-1.45 2.9-2.25.95 1.35 1.65 3.25z"
                    id="ObjToy065__Layer2_0_MEMBER_1_FILL"
                />
                <g id="ObjToy065__Layer2_0_FILL">
                    <path
                        fill="#9AFF00"
                        d="M162.3 294.65q-.25 1.05.35 2 .55.9 1.65 1.15 1.05.25 2-.3.95-.6 1.2-1.7.2-1.05-.35-2-.6-.9-1.65-1.15-1.1-.25-2 .35-.95.55-1.2 1.65z"
                    />
                    <path
                        fill="#D50000"
                        d="M171.6 288.75q-1.05-.25-2 .35t-1.15 1.7q-.25 1.05.35 2 .55.9 1.65 1.15 1.05.25 2-.35.9-.6 1.2-1.65.2-1.05-.35-2-.6-.95-1.7-1.2z"
                    />
                    <path
                        fill="#F7CB00"
                        d="M167.75 282.65q-1.1-.25-2 .35-.95.55-1.15 1.65-.3 1.05.3 2 .6.9 1.7 1.15 1.05.25 2-.35.9-.6 1.15-1.65.25-1.1-.35-2-.6-.95-1.65-1.15z"
                    />
                    <path
                        fill="#666"
                        d="M149.95 309.2h-.1q-6.35-3.5-13.25-3.8-5.1-.15-9 1-2.7.8-4.8 2.2-.3.2-.95.6-1 .55-1.6.9-1 .6-1.05.6-.5.25-1 .45-1.65.7-3.05 1 2.35-.4 2.8-.5.4-.1.8-.25t.9-.35q.5-.2.95-.45 5-2.85 8.9-3.75 12.05-.75 22.65 5.1 4.1 3 8.1 8.85.3.5.7.85 1.5 1.45 3.2 2.05-1.65-1.05-3.4-3.25-1-1.2-2-2.7-1.7-3-4.05-5.2-2.15-2-4.75-3.35z"
                    />
                    <path
                        fill="#434343"
                        d="M154.45 295.05q-1.8-.4-3.3.6-1.6.95-2 2.75-.4 1.75.6 3.35.95 1.5 2.75 1.9 1.8.4 3.35-.55 1.55-.95 1.95-2.75.4-1.8-.6-3.35-.95-1.55-2.75-1.95m-21.8-12.85q.4-1.8-.55-3.35-1-1.6-2.8-2-1.8-.4-3.35.6-1.55.95-1.95 2.75-.4 1.8.6 3.35.95 1.55 2.75 1.95 1.75.4 3.35-.55 1.5-1 1.95-2.75z"
                    />
                    <path
                        fill="#0027C4"
                        d="M158.8 290.5q.55.9 1.65 1.2 1.05.2 2-.35.9-.6 1.15-1.65.25-1.1-.35-2-.6-.95-1.65-1.2-1.05-.25-2 .35-.95.55-1.15 1.65-.25 1.05.35 2z"
                    />
                    <path
                        fill="#FFF"
                        fillOpacity={0.8}
                        d="M150.55 284.15q-.9-1.45-2.55-1.85-1.7-.35-3.15.55t-1.8 2.6q-.4 1.65.5 3.1.9 1.45 2.6 1.85 1.65.35 3.1-.55 1.45-.95 1.85-2.6.35-1.65-.55-3.1z"
                    />
                    <path
                        fill="#252525"
                        d="M137.85 290.25q-.25-.2-.55-.35-.65-.35-1.45-.55-.65-.15-1.25-.15-.3 0-.6.05-1.2.1-2.35.8-1.95 1.25-2.45 3.5-.5 2.2.75 4.15.85 1.4 2.25 2.05.55.25 1.2.4 1.1.25 2.2.1 1-.2 1.95-.8 1.1-.7 1.75-1.65.1-.25.25-.5.05-.05.05-.1.25-.55.4-1.2.2-.75.2-1.5l-.1-.6q-.2-1.1-.8-2.1-.6-.95-1.45-1.55z"
                    />
                </g>
                <g id="ObjToy065__Layer2_0_MEMBER_0_FILL">
                    <path
                        fill="#434343"
                        d="M138.45 297.55l-2.15-.45-.5 2.2q.75-.2 1.45-.6.7-.5 1.2-1.15m-6-1.35l-2.35-.5q.2.75.65 1.5.5.75 1.15 1.3l.55-2.3m6.25-4q-.45-.7-1.05-1.15l-.45 2.2 2.15.5q-.2-.85-.65-1.55m-4.85-2.15q-.85.15-1.65.65-.75.5-1.25 1.15l2.35.5.55-2.3z"
                    />
                    <path
                        fill="#666"
                        d="M130 295v.1l3.05.7-.65 3q.55.3 1.25.45.8.2 1.55.15l.65-2.95 2.9.65.1-.1q.3-.6.45-1.25.15-.75.1-1.4l-2.9-.65.65-3.05q-.65-.35-1.4-.55-.7-.15-1.35-.1l-.7 3.05-3.05-.7q-.05.05-.05.1-.35.55-.45 1.2-.2.7-.15 1.35z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy065__Layer11_0_FILL" />
            <use
                xlinkHref="#ObjToy065__Symbol_201_0_Layer0_0_FILL"
                transform="translate(108.9 271.15)"
                id="ObjToy065__col1n"
            />
            <use
                xlinkHref="#ObjToy065__Symbol_202_0_Layer0_0_FILL"
                transform="translate(121.6 274.6)"
                id="ObjToy065__col1d"
            />
            <use
                xlinkHref="#ObjToy065__Symbol_203_0_Layer0_0_FILL"
                transform="translate(138.5 283)"
                id="ObjToy065__col1s"
            />
            <use xlinkHref="#ObjToy065__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy065__Layer2_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy065__Layer2_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ObjToy065
