import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F"
}

function ShiLon018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 49.45q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-.9-1.6-1.8-3.15-.15-.25-.3-.45-.1-.15-.15-.3h-.05q-.15-.35-.35-1.25.05-.1.15-.2 1.3-1.55 1.3-3.05 0-1.15-.65-1.95l-.1-.1q-.5-.8-.5-1.15 0-.3.35-.65.15-.15.3-.25.65-.45.7-1v-.1q0-1.15-1-2.4L146.75 0l-3.3 1.6q-.65.3-1.25.6l-4.55 2.05q-2.5 1-5 1.9-2.1.65-4.2 1.25-8.65 2.9-17.2 4.75-30 6.45-59.2 0-7.15-1.6-14.3-3.95-3.1-.95-6.1-2.05-2.15-.85-4.2-1.7l-2.4-1.05-1.1-.5-2.8-1.3-3.3-1.6-1.15.05q-1 1.25-1 2.4v.1q.05.55.7 1 .15.1.3.25.35.35.35.65 0 .35-.5 1.15l-.1.1q-.65.8-.65 1.95 0 1.5 1.3 3.05.35 1.1.15 1.45h.05q-.3.45-.6.95-.05.05-.1.15-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.1.35-.2.65-.75 2.55-1.45 5.15-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q2.05-1.8 4.6-2.7 1.15-.4 2.45-.65.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.35 1.3-.65 2.6l-.4 1.6-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05L143 62q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V60.75q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon018__Symbol_292_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M56.05 22.1q23 6.25 45.35 0-22.2 3.15-45.35 0M0 50.2q6.75-1.55 13.5.9-6.2-4.85-13.5-.9m22.3-13.55v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4M12.2 8.35q-.3.45-.6.95 7.1 5.5 28.25 9.9-20.3-6.6-26.2-10.85Q12.6 7.6 12 6.9q.35 1.1.15 1.45h.05M11.6 0q.35.35.35.65 0 .35-.5 1.15 6.1 4.65 12.3 5.05Q15.45 3.1 11.6 0m128.9 51.1q6.75-2.45 13.5-.9-7.3-3.95-13.5.9m-7-14.7q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m9.15-28.05h-.05q-.15-.35-.35-1.25-.55.6-1.4 1.25-5.45 4.2-23.3 10.5Q136.2 14.7 143.1 9.1q-.15-.25-.3-.45-.1-.15-.15-.3m.3-6.55q-.5-.8-.5-1.15 0-.3.35-.65-4.15 3.3-11.75 6.75 6.7-1.8 11.9-4.95z"
                    id="ShiLon018__Symbol_229_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon018__Symbol_292_0_Layer0_0_FILL"
                transform="translate(110 224.05)"
                id="ShiLon018__col1n"
            />
            <use
                xlinkHref="#ShiLon018__Symbol_229_0_Layer0_0_FILL"
                transform="translate(115.1 227.85)"
                id="ShiLon018__col1s"
            />
        </g></g>
    )
}

export default ShiLon018
