import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999",
    "col2l": "#EEEEEE",
    "col2n": "#D9D9D9"
}

function ObjToo072({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M54.3 35.75q-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-1.3.5-2.55.7l.2.7h-.05l1.55 6.4q2.6.4 3.75 3l1.8 7.35q-.85 4.2-3 6.65l17-4.1q-3.1-1.2-5.7-4.45l-1.8-7.45q-.25-2.8 2-4.4l-3.4-14.2q-2.2-.45-3.85-2.1m37.9-19.4L88.25 0q-7.65 5.2-19.6 9.6-2.35.85-5.05 1.7-5.05 1.8-11.05 3.3l-.55-.4-8.05-12.55-5.4 16.2-.05.1q-.05.05-.15.05-4.1.95-7.8 1.55Q12.15 22.9 0 21.25L3.9 37.6q2.35-4.85 28.3-11.3 2.9-.8 6.35-1.55l1.5-.3.15.6q2.9-.2 6.3.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.3-.3.6-.5l-.85-3.4 1.5-.4q5.35-1.3 9.8-2.25 2.7-.65 5.2-1.1 18.05-3.45 21.65-.95z"
                    id="ObjToo072__Symbol_230_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.9 19.5l5.5-1.3-.3-1.4q-2.2-.45-3.85-2.1-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.6-.2 1.25m5.95.5l-6.45 1.6q-.9 2.45-2.25 3.8-.75.7-1.6 1.1l-.2.1H8.3q-1.3.5-2.55.7L7 32.45l14.05-3.4-2.2-9.05m-6.7-9.3H12q.15.35.3.75-.1-.4-.15-.75m2.1-6.8q.25-.25.55-.5L14 0 0 3.4l.15.6q2.9-.2 6.3.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.15.25.35.55l.95-.2q.2-2.8 2.2-4.8z"
                    id="ObjToo072__Symbol_148_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.05 15.75l-.6.15L2.9 0 0 19.15 47.5 216l4.9-10.65 9.15 7.3-47.5-196.9z"
                    id="ObjToo072__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M47.95 198.45l1.65 1.4L3.85 10.3q-6-20.9-2.6.6L47 200.45l.95-2z"
                    id="ObjToo072__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo072__Symbol_230_0_Layer0_0_FILL"
                transform="translate(72.25 261.25)"
                id="ObjToo072__col1n"
            />
            <use
                xlinkHref="#ObjToo072__Symbol_148_0_Layer0_0_FILL"
                transform="translate(112.3 282.3)"
                id="ObjToo072__col1d"
            />
            <use
                xlinkHref="#ObjToo072__Symbol_102_0_Layer0_0_FILL"
                transform="translate(63.25 63.2)"
                id="ObjToo072__col2n"
            />
            <use
                xlinkHref="#ObjToo072__Symbol_50_0_Layer0_0_FILL"
                transform="translate(67.7 70.1)"
                id="ObjToo072__col2l"
            />
        </g></g>
    )
}

export default ObjToo072
