import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ObjMor091({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.6}
                    d="M77.95 14.3q0-5.95-11.45-10.15Q55.1 0 38.95 0T11.4 4.15Q0 8.35 0 14.3q0 .15.05.3.05 1.65 1.05 3.2 2.45 3.7 10.3 6.6 11.4 4.15 27.55 4.15T66.5 24.4q8.7-3.2 10.8-7.4.55-1.15.65-2.4v-.3z"
                    id="ObjMor091__Symbol_220_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M68.2 323.7q-16.15 0-27.55 4.15-8.65 3.2-10.75 7.4 2.45 3.7 10.3 6.6Q51.6 346 67.75 346t27.55-4.15q8.7-3.2 10.8-7.4-2.25-3.35-8.95-6.05-6.95-2.3-13.7-3.6-7-1.1-15.25-1.1z"
                    id="ObjMor091__Symbol_220_0_Layer0_1_FILL"
                />
            </defs>
            <g id="ObjMor091__col1n">
                <use
                    xlinkHref="#ObjMor091__Symbol_220_0_Layer0_0_FILL"
                    transform="translate(28.8 317.45)"
                />
                <use xlinkHref="#ObjMor091__Symbol_220_0_Layer0_1_FILL" />
            </g>
        </g></g>
    )
}

export default ObjMor091
