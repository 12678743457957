import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF",
    "col1n": "#EEEEEE",
    "col2l": "#E1E8EE",
    "col2n": "#C1D2E2"
}

function AccJew011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.3 30.15q-.95-.95-2.35-.95-1.35 0-2.35.95-.95 1-.95 2.35 0 1.4.95 2.35 1 1 2.35 1 1.4 0 2.35-1 1-.95 1-2.35 0-1.35-1-2.35m-14.65-8.7q-.9.95-.9 2.3 0 1.35.9 2.3 1 .95 2.3.95 1.35 0 2.3-.95 1-.95 1-2.3 0-1.35-1-2.3-.95-.95-2.3-.95-1.3 0-2.3.95M9.55 10.1q-1.25 0-2.2.9-.8.9-.8 2.15t.8 2.15q.95.9 2.2.9 1.25 0 2.15-.9.95-.9.95-2.15T11.7 11q-.9-.9-2.15-.9M.85 4.9q.85.8 2 .8 1.2 0 2-.8.85-.85.85-2.05 0-1.15-.85-2-.8-.85-2-.85-1.15 0-2 .85-.85.85-.85 2 0 1.2.85 2.05M67.6 43.55q0 1.65 1.2 2.9 1.2 1.2 2.9 1.2t2.9-1.2q1.2-1.25 1.2-2.9 0-1.7-1.2-2.9t-2.9-1.2q-1.7 0-2.9 1.2t-1.2 2.9m-9.7-2.9q-1.2-1.2-2.9-1.2t-2.9 1.2q-1.2 1.2-1.2 2.9 0 1.65 1.2 2.9 1.2 1.2 2.9 1.2t2.9-1.2q1.2-1.25 1.2-2.9 0-1.7-1.2-2.9M42.3 42q1.1-1.1 1.1-2.6t-1.1-2.55q-1.1-1.1-2.55-1.1-1.5 0-2.6 1.1-1.05 1.05-1.05 2.55 0 1.5 1.05 2.6 1.1 1.05 2.6 1.05 1.45 0 2.55-1.05m44.65-6.25q-1.45 0-2.55 1.1-1.1 1.05-1.1 2.55 0 1.5 1.1 2.6 1.1 1.05 2.55 1.05 1.5 0 2.6-1.05 1.05-1.1 1.05-2.6t-1.05-2.55q-1.1-1.1-2.6-1.1m10.45-.9q.95 1 2.35 1 1.35 0 2.35-1 .95-.95.95-2.35 0-1.35-.95-2.35-1-.95-2.35-.95-1.4 0-2.35.95-1 1-1 2.35 0 1.4 1 2.35m12.35-14.35q-1.35 0-2.3.95-1 .95-1 2.3 0 1.35 1 2.3.95.95 2.3.95 1.3 0 2.3-.95.9-.95.9-2.3 0-1.35-.9-2.3-1-.95-2.3-.95m7.4-10.4q-1.25 0-2.15.9-.95.9-.95 2.15t.95 2.15q.9.9 2.15.9t2.2-.9q.8-.9.8-2.15t-.8-2.15q-.95-.9-2.2-.9m6.7-4.4q1.15 0 2-.8.85-.85.85-2.05 0-1.15-.85-2-.85-.85-2-.85-1.2 0-2 .85-.85.85-.85 2 0 1.2.85 2.05.8.8 2 .8z"
                    id="AccJew011__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M23.9 30.8q0 .65.4 1.05.5.45 1.15.45.6 0 1.05-.45.4-.4.4-1.05t-.4-1.1q-.45-.45-1.05-.45-.65 0-1.15.45-.4.45-.4 1.1m15.5 5.65q-.55-.55-1.2-.55-.7 0-1.2.55-.45.45-.45 1.15t.45 1.15q.5.55 1.2.55.65 0 1.2-.55.45-.45.45-1.15t-.45-1.15M16.45 21q-.4-.45-1-.45-.65 0-1.15.45-.35.4-.35 1.05t.35 1.05q.5.4 1.15.4.6 0 1-.4t.4-1.05q0-.65-.4-1.05m-9.5-10.45q-.35.35-.35.95t.35 1q.45.35 1.05.35.6 0 .95-.35.4-.4.4-1t-.4-.95Q8.6 10.1 8 10.1t-1.05.45M2.2 2.2q.4-.35.4-.9T2.2.4Q1.8 0 1.3 0 .75 0 .35.4 0 .75 0 1.3t.35.9q.4.4.95.4.5 0 .9-.4m66.25 40.7q.6.55 1.35.55.75 0 1.35-.55.55-.55.55-1.3 0-.8-.55-1.35-.6-.55-1.35-.55-.75 0-1.35.55-.5.55-.5 1.35 0 .75.5 1.3m-14.95.55q.75 0 1.35-.55.5-.55.5-1.3 0-.8-.5-1.35-.6-.55-1.35-.55-.75 0-1.35.55-.55.55-.55 1.35 0 .75.55 1.3.6.55 1.35.55m32.8-7q-.5-.55-1.2-.55-.65 0-1.2.55-.45.45-.45 1.15t.45 1.15q.55.55 1.2.55.7 0 1.2-.55.45-.45.45-1.15t-.45-1.15m12.7-4.6q.4-.4.4-1.05t-.4-1.1q-.5-.45-1.15-.45-.6 0-1.05.45-.4.45-.4 1.1 0 .65.4 1.05.45.45 1.05.45.65 0 1.15-.45m7.85-8.75q.4.4 1 .4.65 0 1.15-.4.35-.4.35-1.05T109 21q-.5-.45-1.15-.45-.6 0-1 .45-.4.4-.4 1.05t.4 1.05m9.5-12.55q-.45-.45-1.05-.45-.6 0-.95.45-.4.35-.4.95t.4 1q.35.35.95.35t1.05-.35q.35-.4.35-1t-.35-.95M122 2.6q.55 0 .95-.4.35-.35.35-.9t-.35-.9q-.4-.4-.95-.4-.5 0-.9.4-.4.35-.4.9t.4.9q.4.4.9.4z"
                    id="AccJew011__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M32.5 33.8q1.05-1.05 1.05-2.55 0-1.5-1.05-2.6-1.1-1.05-2.6-1.05t-2.55 1.05q-1.1 1.1-1.1 2.6t1.1 2.55q1.05 1.1 2.55 1.1 1.5 0 2.6-1.1M10.1 10.1q-1.3 0-2.3.95-.9.95-.9 2.3 0 1.35.9 2.3 1 .95 2.3.95 1.35 0 2.3-.95 1-.95 1-2.3 0-1.35-1-2.3-.95-.95-2.3-.95M5.15 5.2q.95-.9.95-2.15T5.15.9Q4.25 0 3 0T.8.9Q0 1.8 0 3.05T.8 5.2q.95.9 2.2.9 1.25 0 2.15-.9m16.95 18q0-1.35-1-2.3-.95-.95-2.3-.95-1.3 0-2.3.95-.9.95-.9 2.3 0 1.35.9 2.3 1 .95 2.3.95 1.35 0 2.3-.95 1-.95 1-2.3m51.7 11q-1.15 1.15-1.15 2.75t1.15 2.75q1.15 1.15 2.75 1.15t2.75-1.15q1.15-1.15 1.15-2.75T79.3 34.2q-1.15-1.15-2.75-1.15T73.8 34.2m-25.85 2.75q0-1.6-1.15-2.75t-2.75-1.15q-1.6 0-2.75 1.15t-1.15 2.75q0 1.6 1.15 2.75t2.75 1.15q1.6 0 2.75-1.15t1.15-2.75m12.35-1.5q-1.75 0-3 1.25t-1.25 3q0 1.7 1.25 2.95 1.25 1.25 3 1.25t3-1.25q1.2-1.25 1.2-2.95 0-1.75-1.2-3-1.25-1.25-3-1.25m30.4-7.85q-1.5 0-2.6 1.05-1.05 1.1-1.05 2.6t1.05 2.55q1.1 1.1 2.6 1.1t2.55-1.1q1.1-1.05 1.1-2.55 0-1.5-1.1-2.6-1.05-1.05-2.55-1.05m14.3-4.4q0-1.35-.9-2.3-1-.95-2.3-.95-1.35 0-2.3.95-1 .95-1 2.3 0 1.35 1 2.3.95.95 2.3.95 1.3 0 2.3-.95.9-.95.9-2.3m5.5-13.1q-1.35 0-2.3.95-1 .95-1 2.3 0 1.35 1 2.3.95.95 2.3.95 1.3 0 2.3-.95.9-.95.9-2.3 0-1.35-.9-2.3-1-.95-2.3-.95m10.1-7.05q0-1.25-.8-2.15-.95-.9-2.2-.9-1.25 0-2.15.9-.95.9-.95 2.15t.95 2.15q.9.9 2.15.9t2.2-.9q.8-.9.8-2.15z"
                    id="AccJew011__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M27.1 28.25q-.45.45-.45 1.15t.45 1.2q.55.5 1.25.5.65 0 1.15-.5.5-.5.5-1.2t-.5-1.15q-.5-.55-1.15-.55-.7 0-1.25.55m-11-7.8q-.35.4-.35 1.05t.35 1.05q.5.4 1.15.4.6 0 1-.4t.4-1.05q0-.65-.4-1.05-.4-.45-1-.45-.65 0-1.15.45M7.4 10.6q-.35.4-.35 1.05t.35 1.05q.5.4 1.15.4.6 0 1-.4t.4-1.05q0-.65-.4-1.05-.4-.45-1-.45-.65 0-1.15.45M.35.45Q0 .8 0 1.4t.35 1q.45.35 1.05.35.6 0 .95-.35.4-.4.4-1t-.4-.95Q2 0 1.4 0T.35.45m73 33.35q-.5.5-.5 1.25t.5 1.25q.55.55 1.25.55.75 0 1.3-.55.5-.5.5-1.25t-.5-1.25q-.55-.55-1.3-.55-.7 0-1.25.55M40.7 35.05q0 .75.5 1.25.55.55 1.3.55.7 0 1.25-.55.5-.5.5-1.25t-.5-1.25q-.55-.55-1.25-.55-.75 0-1.3.55-.5.5-.5 1.25m19.05 1.25q-.6-.55-1.4-.55-.8 0-1.35.55t-.55 1.4q0 .8.55 1.35.55.6 1.35.6t1.4-.6q.55-.55.55-1.35 0-.85-.55-1.4m27.85-5.7q.5.5 1.15.5.7 0 1.25-.5.45-.5.45-1.2T90 28.25q-.55-.55-1.25-.55-.65 0-1.15.55-.5.45-.5 1.15t.5 1.2m11.25-8.05q.4.4 1 .4.65 0 1.15-.4.35-.4.35-1.05t-.35-1.05q-.5-.45-1.15-.45-.6 0-1 .45-.4.4-.4 1.05t.4 1.05m9.7-12.4q-.6 0-1 .45-.4.4-.4 1.05t.4 1.05q.4.4 1 .4.65 0 1.15-.4.35-.4.35-1.05t-.35-1.05q-.5-.45-1.15-.45m7.15-7.4q.6 0 1.05-.35.35-.4.35-1t-.35-.95Q116.3 0 115.7 0q-.6 0-.95.45-.4.35-.4.95t.4 1q.35.35.95.35z"
                    id="AccJew011__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew011__Symbol_82_0_Layer0_0_FILL"
                transform="translate(128.8 227.6)"
                id="AccJew011__col1n"
            />
            <use
                xlinkHref="#AccJew011__Symbol_50_0_Layer0_0_FILL"
                transform="translate(130.5 228.3)"
                id="AccJew011__col1l"
            />
            <use
                xlinkHref="#AccJew011__Symbol_29_0_Layer0_0_FILL"
                transform="translate(131.85 232.6)"
                id="AccJew011__col2n"
            />
            <use
                xlinkHref="#AccJew011__Symbol_16_0_Layer0_0_FILL"
                transform="translate(133.6 233.3)"
                id="AccJew011__col2l"
            />
        </g></g>
    )
}

export default AccJew011
