import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1n": "#D19532",
    "col2d": "#43341A",
    "col2n": "#644D27"
}

function BodTai008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M113.25 7.3q1.35-.5 3.35-.2Q109.15.45 100.05 0h-.1q-1 0-2 .1-8.7.7-15.35 4-2.45 1.2-4.55 2.7-3.75 3-6.5 6.8l-.65-2.75q-4.45 8.2-7.5 16.65-2.45 7.6-4.15 12.15-.35.95-.7 1.75l-.2.5Q54.6 55.15 47.1 62.45l-1.05.95-.1.1q-.1.1-.25.2l-.2.2q-9.65 8.15-27.1 6.35-5.75-.7-12.3-2.55-.55-.1-6.1-1.95Q.3 73.4 4 81v.1q.1.1.2.35.35.75.5 1.35.1.25.2.55Q9.4 92.55 17.2 97q3.25 1.85 7.1 2.85 7.1 1.4 16.2.55-1.55-1.65-2.25-3.5 3 1.15 10.15 1.35 5.8-.25 9.7-1.7 6.55-2.8 11.85-7.3.25-.2.45-.4 4.35-4.7 7.25-9.45-3.65 1.3-4.5 0 5.45-4.95 7.15-8.4 1.7-3.45 2.65-5.65.9-2.25 1.6-3.9.55-1.45 1.1-2.75 1.45-4.45 3-8.35 1.35-3.45 2.1-7.15-2.4.85-4.4.75l1.2-.9q3.3-2.7 5.5-5.7l3.7-5.25q1.55-2.2 2.85-3.6 1.95-2.2 4.25-3.65 9.05-6.95 21.9-2.4-3.55-8.3-12.5-15.15z"
                    id="BodTai008__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M41.05 72.85q1.2 2.3 3.4 5.15 5.75-.25 9.65-1.7 6.55-2.8 11.85-7.3.2-.2.45-.35l-1-.7q-4.1-2.8-7.9-7.2-1.65.75-3.65 1.55-7.3 2.9-15.3 4.2 1.05 3.45 2.5 6.35M0 60.75v.1q.1.1.2.35.35.75.5 1.35.1.25.2.55 4.5 9.2 12.3 13.65 3.25 1.85 7.15 2.9v-.15q-.05-.1-.05-.15-1.4-3.3-1.7-7.05-.1-1.35-.05-4-4.15-1.2-8.75-3.15-7-2.9-9.8-4.4M84.65 30.1l-.1-.05-.7-.35q-.7-.35-1.15-.6-2-1.05-3.35-1.85-.1.55-1.2 2.8-1.1 2.25-2.8 6-1.75 3.7-4.05 8.2 2.05.95 4.95 2.15.35.1.75.3l1 .4q.7-1.15 1.2-2.4 1.25-3.45 2.45-6.25 1.45-4.45 3-8.35m15.2-25.5l-.5-.7q-1.1-1.7-2-3.9-3.5 2.1-6.75 5.65-3.75 4.4-6 6.9 2.05 1.8 4.45 3.1l.85.45q1.45-2.1 3.25-4.75 2.85-4.3 6.7-6.75z"
                    id="BodTai008__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.85 67.4q-6.55 3.3-15.4 3.3v.1q-.9 2.95-1.7 6.6-.25 1.5-.6 7.05v.1q-.1 2.3-.15 4.1-.05 2.65.05 4 .3 3.75 1.7 7.05 0 .05.05.15 6.6 1.75 15 1.1 1-.05 2-.15-.55-.9-1-1.55-.45-.65-.85-1.1-.05 0-.05-.05 4.9.35 8.95.2-1.95-2.45-3.35-5.1-1.45-2.9-2.5-6.35-.75-2.45-1.25-5.1-.75-3.65-1.05-7.8-.3-3.6.15-6.45v-.1m19.2-18.5q-2.4-3.95-4.15-8.05-.1 12.4-6.15 19.25 1.9 6.2 6.75 13.65 1.15 1.75 2.3 3.4 1.55 2.1 3.15 3.95 4.25 4.9 8.85 7.8 4.95-4.3 8.2-9.6l1.35-1.5-1.75.85q-.5.15-1.3.25-1.6.3-2.15.25-.05-.05-.1-.05Q56.3 75 59.6 67.45h-.05q-4.1-1.6-6.8-2.85-1.6-.8-2.7-1.45L50 63.1q-3.15-1.9-6.55-5.65-.7-.75-1.3-1.45l-.25-.25q-2.5-2.9-3.85-5.05-.5-.85-1-1.8M49.5 12.65l-.1-.1q-.2-1-.4-3.2V9.3h-.15q-4.1 5.45-7.7 17.05l-.1.4q-.15.4-.25.75.05.05.05.1.45.7.85 1.45Q45.45 35.6 50 39.7q2.2 2.1 4.7 3.8.55.35 4.5 3.05.1.05.25.15.55.4 1.35.9 1.35.8 3.35 1.85.65.35 1.85.95.3-.7.65-1.5 1.5-3.75 3.25-7 0-.1.05-.15-.15.05-.3.15-.95.55-3.7 1.05h-.1q1.5-1.15 5.4-6.55-2.85-1.45-5.2-3.5-3.2-2.8-5.5-6.65-4.15-6.8-4.9-15.95-.15-1.95-.15-3.4v-.15q-3.5 2.5-6 5.9m11.95 27.5h.25l.5-.25v.05l-.75.35v-.15m42.45-16.4q-.05-.25-.1-.45-1.65-6.95-7.6-12.5l-.45-.45-.15-.15q-.1-.1-.2-.15-1.35-1.2-2.75-2.2-.1-.05-.15-.1.35-.1.65-.1l1.4.2.7-.05q-.1-.15-.2-.25l-.1-.1q-.1-.1-.15-.2-2.4-2.7-5.6-4.45-1.15-.6-2.35-1.1Q82.5 0 77.5 0v.1q-.5 1.4-1.1 3.85-.4 1.5-.4 2.65-.05.55.15 2.9.4 5.45 2.3 10 .15.45.35.85 1.05 2.6 2.45 4.5 7.5-4.85 18.8-2.4 1.75.35 3.45 1.1l.4.2z"
                    id="BodTai008__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M57.35 60.4l-1.75.85q-.5.15-1.3.25-1.6.3-2.15.25-.05-.05-.1-.05 4.25-4.1 7.55-11.65h-.05q-4.1-1.6-6.8-2.85-.45.85-4.2 5.5-3.55 4.3-7.5 6.55 2 .65 4.4 0-.2 1.45-6.5 4.45 4.25 4.9 8.85 7.8 4.95-4.3 8.2-9.6l1.35-1.5M14.45 73h-1.5Q7.2 73.3 0 71.25q-.05 2.65.05 4 .3 3.75 1.7 7.05 0 .05.05.15 6.6 1.75 15 1.1 1-.05 2-.15-.55-.9-1-1.55-.45-.65-.85-1.1-.05 0-.05-.05 4.9.35 8.95.2-1.95-2.45-3.35-5.1-1.45-2.9-2.5-6.35-3.45.55-9.05.8 1.4 1.5 2.45 2.05 1 .55 1.05.7m56.8-54q-2.85-1.45-5.2-3.5-.3.35-.6.65-4.1 4.45-7.25 7.35 1.6.3 5.15-.6-1.35 3.7-2.55 7.3 1.35.8 3.35 1.85.65.35 1.85.95.3-.7.65-1.5 1.5-3.75 3.25-7 0-.1.05-.15-.15.05-.3.15-.95.55-3.7 1.05h-.1q1.5-1.15 5.4-6.55M103.5 6.15l.4.2q-.05-.25-.1-.45l-.4-.3Q95.85 0 89.05 0 83.7 0 78.8 2.95q1.05 2.6 2.45 4.5 7.5-4.85 18.8-2.4 1.75.35 3.45 1.1z"
                    id="BodTai008__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai008__Symbol_32_0_Layer0_0_FILL"
                transform="translate(235.75 218.45)"
                id="BodTai008__col1n"
            />
            <use
                xlinkHref="#BodTai008__Symbol_24_0_Layer0_0_FILL"
                transform="translate(239.75 238.7)"
                id="BodTai008__col1d"
            />
            <use
                xlinkHref="#BodTai008__Symbol_19_0_Layer0_0_FILL"
                transform="translate(258.3 218.35)"
                id="BodTai008__col2n"
            />
            <use
                xlinkHref="#BodTai008__Symbol_14_0_Layer0_0_FILL"
                transform="translate(258.3 235.75)"
                id="BodTai008__col2d"
            />
        </g></g>
    )
}

export default BodTai008
