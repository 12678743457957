import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.2.05H27Q21.25 0 17.1 1 7.7 3.9 2.15 11.65.05 14.6.05 17.9q-.05.45-.05.95-.05 1.55.7 1.8.1-.95 1.05-2.1.9-1.2 1.7-1.75Q3 22.6 9.7 27q-1.05-2.45-1.5-4.65Q5.7 9.85 23.75 6.9q.8-.2 1.8-.25 2-.2 4.65 0 7.7-6.3 3.35-6.4-4.4-.15-5.3-.15-1-.05-1.05-.05m45.95 17.2q.05-.1.05-.2.55-3.45-2.05-6.55Q65.6 3.65 55.7.85q-4.4-1-10.15-.8-2.15 0-7 .2-4.9.15 3.55 6.4 3.1-.1 5.4.05 2.25.15 3.3.25 17.5 3.25 13.65 17.5-.2.75-.45 1.55l-.2.9q4.1-1.95 5.4-4.9 1.15-2.55 1.35-4.85.05-.5.25-1.35 1.05.85 1.9 2.25l.25.35q.1-.8.2-1.15z"
                    id="BeaMus028__Symbol_45_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus028__Symbol_45_0_Layer0_0_FILL"
                transform="translate(155.85 203.3)"
                id="BeaMus028__col1n"
            />
        </g></g>
    )
}

export default BeaMus028
