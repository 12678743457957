import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999"
}

function ObjToo066({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M103.55 47.05q-1.5-18.4-15-31.85Q73.3 0 51.85 0q-6.95 0-13.2 1.6-13.2 3.35-23.45 13.6Q0 30.4 0 51.85q0 14.45 6.9 26.1 3.3 5.6 8.3 10.6 8.95 8.95 20.05 12.6 7.8 2.55 16.6 2.55 21.45 0 36.7-15.15 15.2-15.2 15.2-36.7 0-2.45-.2-4.8z"
                    id="ObjToo066__Symbol_224_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M94.25 42.2q-.1-.45-.1-.85-1.8-15.8-13.55-27.55Q66.8 0 47.2 0q-5.75 0-11 1.15-12.6 2.9-22.4 12.65Q0 27.65 0 47.2q0 13 6.15 23.5 3.05 5.25 7.65 9.85 7.65 7.6 17 11.05 7.6 2.8 16.4 2.8 19.6 0 33.4-13.85 13.85-13.8 13.85-33.35 0-2.55-.2-5m-2.7 0q.25 2.4.25 4.95 0 18.5-13.15 31.5Q65.6 91.8 47.1 91.8q-8.75 0-16.3-2.9-8.45-3.35-15.35-10.25Q11.1 74.3 8.2 69.4q-5.8-9.95-5.8-22.25 0-18.55 13.05-31.65 9.5-9.45 21.85-12 4.7-1 9.8-1 18.5 0 31.55 13Q90 26.8 91.55 42.2z"
                    id="ObjToo066__Symbol_146_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo066__Symbol_224_0_Layer0_0_FILL"
                transform="translate(224.05 227.45)"
                id="ObjToo066__col1n"
            />
            <use
                xlinkHref="#ObjToo066__Symbol_146_0_Layer0_0_FILL"
                transform="translate(228.55 232.3)"
                id="ObjToo066__col1d"
            />
        </g></g>
    )
}

export default ObjToo066
