import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2d": "#000000"
}

function ObjToy008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.75 4.45H1.7l-.3.5Q.55 6.35.25 8.05 0 8.75 0 9.5q0 .2.05.45v.2q0 2.4 1 4.4.25.55.55 1.05.4.55.85 1.1.25.3.55.6 2.95 2.95 7.2 3 4.2-.05 7.15-3 3-2.95 3-7.15 0-4.25-3-7.25Q14.4-.05 10.2 0 7.25-.05 4.9 1.4 3.9 2 3 2.9q-.75.75-1.25 1.55z"
                    id="ObjToy008__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M15.85 13q-.95 0-.95 1.05 0 .85.95.85t.95-.9q-.05-1-.95-1M.9 13.1q.25.55.55 1.05l.2-.15q-.45-.45-.75-.9m1.6 0q0 .85.95.85t.95-.9q0-.25-.05-.4-.15-.6-.9-.6-.5 0-.75.35-.2.25-.2.7m5.15 2.15q0 .85.95.85t.95-.9V15q-.15-.8-.95-.8-.85 0-.9.8-.05.1-.05.25M10.1 7q-.95 0-.95 1.05 0 .85.95.85t.95-.9Q11 7 10.1 7M0 8v.5q.2.45.9.45.95 0 .95-.95 0-1-.95-1Q0 7 0 8m1.55-5l-.3.5q.2-.25.4-.45L1.6 3h-.05M5.9 4.05q0 .85.95.85T7.8 4q-.05-1-.95-1-.95 0-.95 1.05M12.55 1q-.05-1-.95-1-.95 0-.95 1.05 0 .45.25.65t.7.2q.95 0 .95-.9m5.3 3.75q-.95 0-.95 1.05 0 .85.95.85t.95-.9q0-1-.95-1z"
                    id="ObjToy008__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.102}
                    d="M351.8 366.8q0-4.25-3-7.25-2.95-2.95-7.15-2.9-2.95-.05-5.3 1.4-1 .6-1.9 1.5-.75.75-1.25 1.55l.05.05q2.3-2.25 5.5-2.25.85 0 1.6.15 1.15.2 2.15.8.9.5 1.7 1.3 2.3 2.3 2.3 5.5t-2.3 5.45q-1.35 1.4-3.05 1.95-1.15.35-2.4.35-.45 0-.85-.05-.85-.1-1.65-.35-1.65-.55-3-1.9l-.2.15q.4.55.85 1.1.25.3.55.6 2.95 2.95 7.2 3 4.2-.05 7.15-3 3-2.95 3-7.15z"
                    id="ObjToy008__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy008__Symbol_102_0_Layer0_0_FILL"
                transform="translate(331.45 356.65)"
                id="ObjToy008__col1n"
            />
            <use
                xlinkHref="#ObjToy008__Symbol_54_0_Layer0_0_FILL"
                transform="translate(331.6 358.1)"
                id="ObjToy008__col1s"
            />
            <use
                xlinkHref="#ObjToy008__Symbol_2_0_Layer0_0_FILL"
                id="ObjToy008__col2d"
            />
        </g></g>
    )
}

export default ObjToy008
