import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#2E0E00"
}
function ObjMor099({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.35 34.45L44.9 34l-8.15 7.65-1.25-1.3 7.6-8.1-.45-.45-8.1 7.6-1.3-1.3L40.9 30l-.45-.45-8.15 7.6-1.25-1.25 7.6-8.1-.45-.45-8.1 7.6-1.25-1.3 7.6-8.1-.45-.45-8.1 7.6-1.3-1.25 7.6-8.1-.45-.45-8.1 7.6-1.25-1.3 7.6-8.1-.45-.45-8.1 7.6-1.25-1.3 7.55-8.05-.45-.45L21.25 26l-1.3-1.25 7.6-8.1-.45-.45-8.1 7.6-1.25-1.3 7.55-8.05-.45-.45-8.05 7.55-1.25-1.25 7.55-8.1-.45-.45-8.1 7.6-1.25-1.3L20.85 10l-.45-.45-8.05 7.55-1.25-1.25 7.55-8.1-.45-.45-8.05 7.6-1.3-1.3 7.6-8.05-.5-.45-8.05 7.55-1.25-1.3L14.2 3.3l-.45-.45L5.7 10.4 4.5 9.2l7.45-7.45q.25-.55-.15-1.1-.5-.5-1.2-.6Q9-.15 7.9 1L2.7 6.25Q-.9 9.55.3 13.4q.6 1.85 2.4 3.9Q9.95 26 17.65 34.05h1.5q3.45.5 6.4 2.75.3.25 4.25 3.95 3 2.85 3.65 3.95.7 1.2.7 3.65-.05.9-.2 1.7 3.8 3.45 7.7 6.75 6.15 6 11.75 0l5.45-5.45q.8-.85 1-2v-.05q.1-1.1-.65-1.85-.7-.5-1.35 0l-7.65 7.7-1.4-1.4 7.65-8.15-.45-.5-8.15 7.7-1.25-1.3 7.65-8.15-.45-.45-8.15 7.65-1.3-1.25L52 41.15l-.45-.5-8.15 7.65-1.25-1.25 7.65-8.15-.45-.45-8.15 7.65-1.3-1.3 7.65-8.1-.45-.5-8.1 7.65-1.3-1.25 7.65-8.15z"
                    id="ObjMor099__Symbol_247_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor099__Symbol_247_0_Layer0_0_FILL"
                transform="translate(96.65 256.65)"
                id="ObjMor099__col1n"
            />
        </g></g>
    )
}
export default ObjMor099
