import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400",
    "col1s": "#BD7B00"
}
function FacMou078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M134.8 10.5q-1.65-1.55-3.85-2.6-7.4-3.45-15.9-.65-12.65 3.25-22.3-.1Q89 6 85.6 4.3 78.15.5 69.6 0h-1q-8.55.35-16.05 4.3-3.35 1.7-7.1 2.85-9.65 3.35-22 .3-8.85-3-16.2.45-2.2 1.05-3.85 2.6-.9.9-1.5 1.75-3.8 5.35 0 11.45.15.15.35.3 2.55 2.4 8.3.55 8.45-2.45 15.25-.7l-.05.05h.2l1 .25-2.9 2.5q-.1.05-.2.15-.05 0-.15.05-.2.15-.35.3-3.8 3.15-3.8 6.1 0 4.95 6.55 5.5.05 0 .15.05.3.55.35.75h.05q.1 4.1 1.45 5.75.35.25.6.5 6.2 4.15 17.95.05 23.9-7.2 44.9 0 11.75 4.1 17.95-.05.25-.25.55-.5 1.35-1.6 1.5-5.4v-.35q.05-.65 0-.85l.6.05q6.5-.6 6.5-5.5 0-3.1-4.15-6.4l-.3-.2-.1-.1-2.95-2.4 1.3-.25q-.05-.05-.05-.1h.05l.05.1h.05q.15-.05.25-.05 8.15-1.6 15.8 1 .75.2 1.45.35 3.9.65 5.9-1.2.15-.15.35-.3 3.8-6.1 0-11.45-.65-.85-1.5-1.75z"
                    id="FacMou078__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M17.45 7.2q0 1.7-1.4 3.5l-.05.05h.2l1 .25q1.25-1.9 1.25-3.8.1-4.95-7.9-4.95-3.1 0-9.5 1.3.15-1.1.95-1.95.65-.95.65-1.3 0-.05.1-.15l-.1.05q0-.05.05-.1V.05q-.05-.1-.3-.05T1.25.95V.9Q0 2.25 0 4.1t1.25 3.15q1.1.85 1.55.75l.05-.05q-.05-.05-.05-.1Q2.4 7.3 2 6.6l-.05-.05q-.8-.85-.9-2h.1Q7.5 3.2 10.55 3.2q6.8 0 6.9 4m83.6 18.2h-.6q-.4-.05-.9-.1-4-.65-15.75-3.6-14.7-3.75-23.9-3.65h-.05q-12.1 0-25.8 3.65-13.75 3.7-16.4 3.7h-.75q-.2 0-.35.15-.05.05-.05.1.3.55.35.75h.8q2.65 0 16.4-3.65 13.7-3.65 25.8-3.75 9.45 0 24.05 3.7 14.5 3.7 17.15 3.7h.75q.05-.65 0-.85v-.05q-.1-.05-.3-.05-.4-.05-.45-.05M84.7 10.65q4.15 1.25 8.35 3h.35q-.25-.1-2.2-1.3-1.95-1.25-6.5-2.75-12.75-3.95-24.85-3.95h-1q-11.75 0-24.05 3.7-4.9 1.6-8.1 3.4-.9.5-1.45.85.2.05.4 0 4.6-1.9 9.15-3.25 12.3-3.7 24.05-3.7h1q12.1 0 24.85 4m33.6-6.7q-.05-1.75-1.2-3.05v.05q-.8-.75-1.3-.75-.55 0-.15.45.35.5.7.95.75.8.9 1.85-6.2-1.2-9.1-1.2-7.8 0-7.9 4.7v.25q0 .85.25 1.7.25 1.05.9 2.1l1.3-.25q-.05-.05-.05-.1-.35-.4-.55-.8-.6-.95-.75-1.85-.1-.4-.1-.8.1-4 6.9-4 2.85 0 9.15 1.3-.1 1.15-.95 2.05v.05q-1 1.15-.8 1.4h.1q1-.3 1.4-.75h.05q1.2-1.3 1.2-3.1v-.2z"
                    id="FacMou078__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou078__Symbol_103_0_Layer0_0_FILL"
                transform="translate(123.4 191.65)"
                id="FacMou078__col1n"
            />
            <use
                xlinkHref="#FacMou078__Symbol_11_0_Layer0_0_FILL"
                transform="translate(133.35 204.8)"
                id="FacMou078__col1s"
            />
        </g></g>
    )
}
export default FacMou078
