import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#0000DB",
    "col1n": "#00284D",
    "col2n": "#FFFF00",
    "col2s": "#D2AD00"
}
function ShiSho079({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.5 19.1q-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2l-.2-.3q-.5-.8-.95-1.6-2-3.1-4.2-6l-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.3.5-.6 1.05-.55.9-1.05 1.85-1.9 3.4-3.55 7.05-.15.2-.25.45-1.4 3-2.65 6.15-.4 1.05-.8 2.15-.2.45-.35.95l18.95 7.05v.05l-2.7 14q-.55 2.7-1.1 5.45L11.7 69.2q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 1.65.4 3.35.75Q77.15 95.6 136.45 82q.4-.1.85-.15l3.3-.8H140.75q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75 19.1-7.1q-.2-.5-.35-.95-.45-1.1-.85-2.15-1.25-3.15-2.65-6.15z"
                    id="ShiSho079__Symbol_226_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M24.85 8.3h-2.1V4.15H20.7V2.1h-4.15V0H6.2v2.1H2.05v2.05H0V8.3h4.15v2.05H8.3v2.05h4.1v2.1H8.3v2.05H4.15v2.1H0v4.1h2.05v2.1H6.2v2.05h10.35v-2.05h4.15v-2.1h2.05v-4.1h2.1V8.3z"
                    id="ShiSho079__Symbol_225_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M4.1 0H0v4.15h4.1V0m8.3 4.15h4.15V0H12.4v4.15m12.45 0H29V0h-4.15v4.15m16.55 0V0h-4.15v4.15h4.15m8.25 0h4.15V0h-4.15v4.15M62.1 0v4.15h4.1V0h-4.1z"
                    id="ShiSho079__Symbol_224_0_Layer0_0_FILL"
                />
                <g
                    id="ShiSho079__Symbol_223_0_Layer0_0_FILL"
                    fill={colors.col1l}
                >
                    <path
                        fillOpacity={0.6}
                        d="M12.55 53.15v2.3H58.6v-2.3H12.55m46.1-44.4v-2.3H12.6v2.3h46.05z"
                    />
                    <path
                        fillOpacity={0.302}
                        d="M0 59.6v2.3h46.05v-2.3H0M46.1 2.3V0H.05v2.3H46.1z"
                    />
                    <path d="M94.6 48.85l-.5-2.3H24.85v2.3H94.6m-7.35-33.7l-.4-2.25h-62v2.3H87.1q.05 0 .15-.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSho079__Symbol_226_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho079__col1n"
            />
            <use
                xlinkHref="#ShiSho079__Symbol_223_0_Layer0_0_FILL"
                transform="translate(161.9 251.6)"
                id="ShiSho079__col1l"
            />
            <use
                xlinkHref="#ShiSho079__Symbol_225_0_Layer0_0_FILL"
                transform="translate(211.55 268.95)"
                id="ShiSho079__col2n"
            />
            <use
                xlinkHref="#ShiSho079__Symbol_224_0_Layer0_0_FILL"
                transform="translate(141.2 279.3)"
                id="ShiSho079__col2s"
            />
        </g></g>
    )
}
export default ShiSho079
