import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.65 1.5q0-.6-.9-1.05-.3-.2-1.2-.4Q23.4 0 20.35 0H10.4Q7.3 0 7.15.05q-.9.2-1.2.4-.9.45-.9 1.05 0 .95 1.35 1.55.5.15 3 .8 4.35 1.1 4.35 3.75 0 .85-.45 1.45-.75 1.35-2.5 1.95-.65.25-1.5.35-1.5.25-3.5.4-5 .7-5.7 3.35H0v.2q-.3 3.1 5.8 8.8.5.3 1.15.7 3.4 2.15 6.85 2.55 1.35.15 2.7.05 3.65-.35 7.3-2.6.6-.4 1.1-.7 5.7-5.35 5.9-8.4v-.2-.15q-.05-.1-.05-.15v-.1h-.1q-.75-2.65-5.75-3.35-2-.15-3.5-.4-.85-.1-1.5-.35-1.75-.6-2.45-1.95h-.05q-.45-.6-.45-1.45 0-2.65 4.35-3.75 2.5-.65 3-.8 1.35-.6 1.35-1.55z"
                    id="BeaChi038__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.9 8.8q5.7-5.35 5.9-8.4V.3q-.05 0-.05.05V.4Q26.4 9.1 15.1 8.5 4.1 8.9 0 0q-.3 3.1 5.8 8.8.5.3 1.15.7 3.4 2.15 6.85 2.55 1.35.15 2.7.05 3.65-.35 7.3-2.6.6-.4 1.1-.7z"
                    id="BeaChi038__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi038__Symbol_77_0_Layer0_0_FILL"
                transform="translate(177.15 234.2)"
                id="BeaChi038__col1n"
            />
            <use
                xlinkHref="#BeaChi038__Symbol_36_0_Layer0_0_FILL"
                transform="translate(177.15 249.5)"
                id="BeaChi038__col1d"
            />
        </g></g>
    )
}

export default BeaChi038
