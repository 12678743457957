import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiSpe126({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M53.5 0q-.8.25-1.6.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85Q6.3 1.65 1.7.45.85.25 0 0v70.25q13.4 1.35 26.75 1.35 13.4 0 26.75-1.3V0z"
                    id="ShiSpe126__Symbol_307_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe126__Symbol_307_0_Layer0_0_FILL"
                transform="translate(165.75 246)"
                id="ShiSpe126__col1n"
            />
        </g></g>
    )
}

export default ShiSpe126
