import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#00006D"
}

function LegPan022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M130.1 0q-.85.2-2.95.65-.4.05-.9.15-.3.05-.65.15-1.65.3-3.95.75-.35.1-.7.15-.7.15-1.45.3-1.65.25-3.7.55-1.35.2-2.95.4-1.15.15-2.5.35-1.85.2-3.95.45l-1.9.2q-.2 0-.45.05-1.25.1-2.55.25l-.9.1q-1.55.15-3.2.3-1.65.15-3.45.35-1.05.05-2.1.15l-9.2.7h-.1q-.3 0-.6.05l-3.95.2q-1.8.1-3.55.15l-2.8.1h-.55q-1.5 0-3 .05H62q-1.5-.05-3-.05h-.55l-2.8-.1q-1.75-.05-3.55-.15l-3.95-.2q-.3-.05-.6-.05h-.1l-9.2-.7q-1.05-.1-2.1-.15-1.8-.2-3.45-.35-1.65-.15-3.2-.3l-.9-.1q-1.3-.15-2.55-.25-.25-.05-.45-.05l-1.9-.2q-2.1-.25-3.95-.45-1.35-.2-2.5-.35-1.6-.2-2.95-.4-2.05-.3-3.7-.55-.75-.15-1.45-.3-.35-.05-.7-.15-2.3-.45-3.95-.75-.35-.1-.65-.15-.5-.1-.9-.15Q.85.2 0 0v.1q0 .35.1.75.1.85.55 1.65v.05q.55 1.05 1.7 2 .85.1 1.7.25 1.55.2 3.1.45l3 .4.7.1q1 .15 2.05.3.1.05.2.05l.6.1 4.5.7q.7.1 1.4.25 3.2.5 6.35 1.1.3 0 .6.1 1.7.3 3.4.65l4.3.8h.15q1.05.2 2.15.45 3.75.7 7.45 1.55 1.15.2 2.3.5.75.15 1.55.35.6.1 1.25.25 1 .25 2.05.5 1.05.2 2.1.45 2.15.5 4.3 1.05.05 0 .15.05.65.15 1.3.35.55.1 1.15.25 1.5.35 3 .75.95.3 1.9.55.95-.25 1.9-.55 1.5-.4 3-.75.55-.15 1.15-.25.65-.2 1.3-.35.1-.05.15-.05 2.15-.55 4.3-1.05 1.05-.25 2.1-.45 1.05-.25 2.05-.5.6-.15 1.2-.25.8-.2 1.6-.35 1.15-.3 2.3-.5 3.7-.85 7.45-1.55 1.1-.25 2.15-.45h.15q1.95-.4 3.95-.75 1.85-.35 3.75-.7.3-.1.6-.1 3.15-.6 6.35-1.1.7-.15 1.4-.25l4.5-.7.6-.1q.1 0 .2-.05 1.05-.15 2.05-.3l.7-.1 3-.4q1.55-.25 3.1-.45.85-.15 1.7-.25 1.15-.95 1.75-2.05 0-.05.05-.1.35-.75.45-1.55.1-.4.1-.75V0z"
                    id="LegPan022__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M78.3 10.9q1.05-.25 2.05-.5.6-.15 1.2-.25-17.2.85-34.35 0 .6.1 1.25.25 1 .25 2.05.5 1.05.2 2.1.45 2.15.5 4.3 1.05.05 0 .15.05.65.15 1.3.35 6 .1 12.1 0 .65-.2 1.3-.35.1-.05.15-.05 2.15-.55 4.3-1.05 1.05-.25 2.1-.45m44-8.15q1.55-.25 3.1-.45.85-.15 1.7-.25 1.15-.95 1.75-2.05Q64.6 12.1.35.05.15.05 0 0v.05q.55 1.05 1.7 2 .85.1 1.7.25 1.55.2 3.1.45l3 .4.7.1q1 .15 2.05.3.1.05.2.05l.6.1 4.5.7q.7.1 1.4.25 3.2.5 6.35 1.1.3 0 .6.1 1.7.3 3.4.65 34.9 3.65 69.85.05 1.85-.35 3.75-.7.3-.1.6-.1 3.15-.6 6.35-1.1.7-.15 1.4-.25l4.5-.7.6-.1q.1 0 .2-.05 1.05-.15 2.05-.3l.7-.1 3-.4z"
                    id="LegPan022__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan022__Symbol_51_0_Layer0_0_FILL"
                transform="translate(127.45 307.25)"
                id="LegPan022__col1n"
            />
            <use
                xlinkHref="#LegPan022__Symbol_50_0_Layer0_0_FILL"
                transform="translate(128.1 309.75)"
                id="LegPan022__col2n"
            />
        </g></g>
    )
}

export default LegPan022
