import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000",
    "col2d": "#252525",
    "col2n": "#000000"
}

function ObjToo037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#D9D9D9"
                    d="M74.2 82.7l.1-10.1q-.888.886-1.8 2.45.007-.009 0-.05-.136.215-.3.45-1.668 2.81-3.1 6.9-.736 2.087-1.4 4.5 12.532 93.35 39.35 188.05l10.75-2.95q-20.022-60.108-43.6-187.7-.143-.76-.3-1.55h.3z"
                    id="ObjToo037__Layer14_0_FILL"
                />
                <path
                    fill="#EEE"
                    d="M74.3 72.6q-2.234 4.614-.05 24 2.188 19.44 8.45 48.3 6.306 28.852 11.15 48.45 1.3 5.25 2.7 10.65.3 1.35.65 2.7.25 1.05.55 2.15 3.65 14.35 7.95 29.9.05.05.1.15.95 3.55 1.95 7.2.8 2.8 1.6 5.7.3.95.55 1.95.55 1.85 1.05 3.7.15.4.3.9.55 2.1 1.2 4.2l.7 2.5q.45 1.65.95 3.35.25.85.55 1.75l.65 2.45 4.4-1.2Q88.863 171.963 74.45 72.5l-.15.1z"
                    id="ObjToo037__Layer14_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M40.85 184.7q-.1.05-.15.1v.05l.15-.15m.75-18.6v-.1l-.05.1h.05m-2.05 24.7q-2.65-4.3-3.65-6.95-.3 3.85-1.7 6.75-1.75 3.65-1.75 3.8 0 1.45 1.05 4 1.05 2.6 1.05 4.15 0 .95-.4 1.35l1.4 3.7h.1q7.8 1.05 12.05-5.25-.5-1.15-1.2-2.85l-.3-.7-.15.05q-.35-.5-.65-1-2.4-1.3-5.85-7.05m-7.95-31.15q-.5-1.55-.85-2.6-.25-.8-.45-1.4h-.05v-.05q-.1-.35-.2-.6l-12.65 3.45.3.9q0 .1.05.25l.4 1.2q0 .1.05.2.1.25.15.5.25.8.55 1.6l.1.3-9.65 2.75 1.1 3.95 9.8-2.8.05.05.65 1.2q.6-.2 1.7-.1 1.55.15 5.5 1 1.55.35 3.15 1.7.1.1.25.2-.35-1.75-.35-3.3 0-3.15 1.55-3.65-.05-.05-.05-.1v-.65l9.4-2.7-1.15-3.9-9.35 2.7v-.1M0 15.25h.1q-.05-.05-.1-.05v.05M4.55 0q-.1.15-.15.4l.2-.1-.05-.3z"
                    id="ObjToo037__Symbol_197_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.3 48.85q-.05 0-.05.05l1.4 3.7h.1q7.8 1.05 12.05-5.25-.5-1.15-1.2-2.85l-.3-.7-.15.05-11.85 5M20.4.65h-.05V.6q-.1-.35-.2-.6L7.5 3.45l.3.9q0 .1.05.25l.4 1.2q0 .1.05.2.1.25.15.5.25.8.55 1.6l.1.3 12.6-3.65v-.1q-.5-1.55-.85-2.6-.25-.8-.45-1.4m11.8 5.3l-.55-1.85L0 13.2l.55 1.9L32.2 5.95z"
                    id="ObjToo037__Symbol_130_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.45 5.25Q14.4 5.1 14.4 5q-.55-1.5-1.05-3-.1-.2-.15-.4-.25-.8-.55-1.6L0 3.7l.05.05q.35 1.1.75 2.2.05.25.15.5 3.6-.7 7.9.7 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-2.05 1-3.75-.5-1.3-.95-2.55m-1.2-2.6l.25-.05v.05h-.25m8.1 21q-.2-.5-.45-1.1-.75-1.9-1.45-3.8-1.55-.55-2.8-1.8-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.7.25-1.3.45l.6 1.6.9 2.2.3.6q.05.05.05.1.7 1.8 1.35 3.5.05.15.15.3.35.95.75 1.9l.3.6q0 .05.05.15l12.1-5.05-.2-.4-.05-.15.15.25q-.2-.4-.25-.6-.45-1.15-1.65-4.25h-.05q-.3-.6-.55-1.25l-.2-.4-.05-.15.15.25q-.2-.4-.25-.6-.45-1.15-1.65-4.25m-5.4 13.8l-.3.15q.05-.1.15-.15h.15z"
                    id="ObjToo037__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M14.5 13l-.1.1q.15.3.25.6-.1-.35-.15-.7m.85 3.4l.85.1q-.9-1-1.35-2.15.3 1 .5 2.05m-1-11.15l-.45.15-3 3q1 .75 1.85 1.75 0 .05.05.1.1.1.25.3l1.35.15q.15-1.6.95-2.9-.5-1.3-.95-2.55h-.05m-6.85-.7L0 3.75q.35 1.1.75 2.2.05.25.15.5 2.15-.4 4.55-.05L7.5 4.55M13.3 2q-.1-.2-.15-.4Q12.9.8 12.6 0l-.2.05-4.9 4.5 6.55.65.3-.2q-.55-1.5-1.05-3m.15.6v.05h-.25l.25-.05M15 29.35l-.65.7 1.7-1.95-3.55-.4q-.75.65-1.6 1.05l-.2.1h-.05q-.7.25-1.3.45l.6 1.6.9 2.2.3.6q.05.05.05.1.7 1.8 1.35 3.5.05.15.15.3.35.95.75 1.9l.3.6 4.9-5.6-7.45-.85 3.8-4.3m.9 8.1l-.3.15q.05-.1.15-.15h.15m5.4-13.8q-.2-.5-.45-1.1l-4.8 5.55 7 .8-4.4 5.6 7 .8.25-.1-.2-.4-.05-.15.15.25q-.2-.4-.25-.6-.45-1.15-1.65-4.25h-.05q-.3-.6-.55-1.25l-.2-.4-.05-.15.15.25q-.2-.4-.25-.6-.45-1.15-1.65-4.25m-.5-1.1h.05q-.75-1.9-1.45-3.8-.95-.35-1.75-.9L15.5 20.1q-.05.2-.05.4-.1.75-.25 1.5v.1l5.6.45z"
                    id="ObjToo037__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjToo037__Layer14_0_FILL" />
            <use xlinkHref="#ObjToo037__Layer14_1_FILL" />
            <use
                xlinkHref="#ObjToo037__Symbol_197_0_Layer0_0_FILL"
                transform="translate(89.65 116.45)"
                id="ObjToo037__col1n"
            />
            <use
                xlinkHref="#ObjToo037__Symbol_130_0_Layer0_0_FILL"
                transform="translate(99.55 271.45)"
                id="ObjToo037__col1d"
            />
            <use
                xlinkHref="#ObjToo037__Symbol_77_0_Layer0_0_FILL"
                transform="translate(109.9 280.05)"
                id="ObjToo037__col2n"
            />
            <use
                xlinkHref="#ObjToo037__Symbol_18_0_Layer0_0_FILL"
                transform="translate(109.95 280.05)"
                id="ObjToo037__col2d"
            />
        </g></g>
    )
}

export default ObjToo037
