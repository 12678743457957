import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212",
    "col2n": "#FFFFFF"
}

function HaiSpe047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.8 28.75q-6.8-12.7-17.9-20.8l-.4-.25-.5-.4q-.35-.25-.7-.45-2.25-1.4-4.6-2.6-3.85-2-8-3.05-5.2-1.35-10.95-1.2-1.5.25-3.75.35-2.05.05-3.85.25-5.85.6-9 2.45-2 1.15-6.75 2.8-4.35 1.7-5.2 1.95-.85.3-2.95.3-1.2 0-1.85-.2-.05.1-.05.2-8.95-1.6-18.5-1.6-40.5 0-69.15 28.6-10.05 10.05-16.6 21.6-2.6 4.65-4.65 9.55Q1.65 77.55 0 90.2q19.5-2.5 39.05-9.95 1.35-.55 4.45-2.15 3.05-1.6 3.6-1.65 3.25-1.45 4.6-1.95-.15-2.6 1.05-5.15l1.8-.1q.35.05.75.05 2.9.2 6.05.2 9.35-.05 20.95-1.85 3.05-19.1 5.7-21.85 3.4-4.85 5.65-10.05l-2.6 32.35q3 .85 6.15.9 2.1 0 4.3-.35.75-.05 1.7-.1 4.95-.3 15.8 1.45 2.4.4 4.65 1.25 4.5 1.7 9.15 3.05 4.35 1.2 8.45 1.75 4.1.5 8.5.75 9.2.95 13.15-.2l.2-.1h.05q.9.8 2.9 2.25 3.35 2.5 5.8 4.4 1.15.85 2.1 1.6 3 2.35 3.3 2.35l15.05-.1q.1.15.15.3 1.7 3.65 2.25 11.25v.35q.15 2.5.2 5.4 0 .25.05.5 0 1.35.05 2.75-.05 2.75-.25 5.65-.25 3.6-.8 7.4-4.4 25.25-21.85 45.15-2.3 2.55-4.75 5-1.6 1.6-3.2 3.1-2.1 1.9-4.15 3.7l-.6.4q1.05 1.4 2.05 2.8 1.45 2.05 2.85 4.05l1.4 1.5q2.5 2.8 5 0v-.05h.05q.05-.1.15-.2.05-.1.15-.15l.05-.05q1.55-1.95 3.45-3.6l.1-.1q3.7-3.55 5.15-3.75h.1q-.05.35-.05.7-.55 7.25 1 10.3 1.35 2.9 5.3 0 .8-.6 1.65-1.3.75-1.1 3.85-3.6.65-.6 1.1-.9 2.7-2.9 9.45 5.7 3.1 3.5 5.85 0 .3-.4.5-.85 3.25-8.3 1.4-17.7-.4-2.4-1.15-4.65-.35-1-.75-1.8-4.95-10.15-5.45-21.35-.4-8.3-.5-16.65-.1-6.2-.1-12.45-.1-11.2-.6-22.25-.1-2.35-.25-4.6 0-.55-.05-1.1-.3-5.6-.75-11.1-.15-1.45-.25-2.85 0-1.5-.75-13.95-.8-12.45-5.2-24.35-1.1-2.5-2.35-4.9z"
                    id="HaiSpe047__Symbol_179_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M142.5 125.9l-.7-18.35q-.05 2.75-.25 5.65-.25 3.6-.8 7.4-4.4 25.25-21.85 45.15-2.3 2.55-4.75 5-1.6 1.6-3.2 3.1-2.1 1.9-4.15 3.7l-.6.4q1.05 1.4 2.05 2.8.65-.55 1.35-1.2 5.15-4.35 6.85-4.35 4.05 0 4.75 2.65 0 .1.15 4.4l.1-.1q3.7-3.55 5.15-3.75l.55-20.5q.2-.65.4-1.2.45-.6 1.3-1.1 1.3-.8 2.4-.8 2.5 0 2.85 4.35.2 2.35.05 7 .35 6.05.95 8.05.5 1.7 1.3 2.65.65.7 1.7.2.45-.25 1.05-.8 2.65-2.45 2.95-4.65.3-2.2.25-3.85-.1-1.7-.1-2.55 0-.4-.05-.85l.1-37.55q.05-.45.2-.9M2.85 53.35q-.4 1.15-.9 3.35Q.9 59.75.5 63.25q-.2 1.95-.5 6.1l1.35-.1q.35.05.75.05 2.9.2 6.05.2v-.3q0-.9-.45-4.2-.45-3.3-.45-5.45 0-4.3.5-7.25.65-3.7 2.3-6.4-3.35 2.75-4.8 4-1.7 1.4-2.4 3.45m38.9-10.95q-.15-3.8-.15-4.15-.3-1.95-1.15-2.5l-2.6 32.35q3 .85 6.15.9-.3-2.45-.85-6.35-.65-4.6-.8-7.55-.45-7.3-.6-12.7m72.55 18.4q-1.9-1.5-8.15-4.2v.1q1.25 1.5 3 3 1.1 1.25 1.1 3.7 0 2.05-.35 4.75-.35 2.75-.35 5.2v2.15q.05.6.15 1.1l.2-.1h.05q.9.8 2.9 2.25 3.35 2.5 5.8 4.4-.7-3.4-.7-4.55 0-1.85.35-4.4.35-2.55.35-4.6 0-5.45-4.35-8.8m9.25-23.55q1.5.7 5.1 2.25 1.3.6 2.7 4.8.55 1.8 1.85 5.8 1.1 3.75 1.75 6.9 2.25 11.2 2.65 13.4 1.65 9.25 1.65 13.3 0 .95-.15 1.7-.1.6-.15 1.45.1-.05.25-.15 0 .25.05.6 1.7 3.65 2.25 11.25v.35q.15 2.5.2 5.4 0 .25.05.5.05-.05.2-.05l.9-23.3q.05-1.05.1-2.5V77.25v-.7l-.05-3.75q-1.5-25.35-7.45-35-.35-.4-.75-.8-1.15-1.5-2.5-2.75-3.9-3.65-8.35-3.65-1.6 0-2.9.3-2.2.5-2.2 1.65 0 2.25 3.35 3.8.55.45 1.45.9m-2.05-21.5q.05-.4.05-1 0-3.6-3.4-6.45-.95-.9-2.25-1.85-3.7-2.75-9.4-5.25Q101.3-.15 95.55 0q-1.5.25-3.75.35-2.05.05-3.85.25-.2.65-.2 1.2 0 1.25.4 2.25.35 1 1.4 2.75.45.8 1.3 2.45.5.9.9 1.25.5.4 5.3 2.8 2.45 1.2 4.05 2.25.35.65.7 1.2-.15.5-.15 1.25 0 4.35 3.9 7.3 3.2 2.4 6.15 2.4 1.35 0 2.05-.2.55-.15.95-.2.95-.65 1.8-.9.65-.15 2.55-.4 3.35-.5 5.65-.5 3.05 0 5.25 2.3-.25-2.85-2.2-5.4-2.2-2.85-4.8-3-1.3-.1-3-.55-1.4-.75-3.8-2.45-2.55-1.9-3.3-2.4-2.15-1.55-4.7-1.7h-.4q-2.15-.6-2.8-1-1.1-.7-1.1-2.2 0-1.25.2-1.65.2-.5.95-.75-.15.25-.25.4 5.65-.25 9.8 2.95 2.25 1.75 6.95 5.7z"
                    id="HaiSpe047__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M127.55 153.7q-.05-1-.05-1.95v-1q-.4 2.8-.4 3.05-.1 2.75-.1 5.55-.05 3.9 0 7.75l.1 3.5v1.05q0 .2.05.3l.1.1q.1.1.2.1t.2-.05q.1-.1.2-.55.05-.4.05-.95v-.1q.05-2 .05-3.95 0-3.15-.05-6.3-.05-3.4-.15-4.1-.15-1.25-.2-2.45m15.95-11.95q.05-11.2-.25-22.25-.15-.05-.3-.05l-.2-.1q-.5.95-.45 2.3.15 11.25.1 22.5v.05q-.05 5.05.2 12.95 0 .8.05 1.6v.1q.05.75.05 1.5.1 2.85.1 4.25.3-1.9.65-7.3-.05-6.6 0-13.15 0-1.2.05-2.4M9.6 39.8q-2.5 1.75-4.45 3.3-1.15.85-1.7 1.9-1.05 1.85-1.75 5.25Q1 53.55.55 57q-.45 3.45-.5 4.55-.1.65-.05 1.3v.05l1.1-.05q.1-1.1.4-3.75.5-5.7 1.4-9 .85-3.25 1.8-4.65.9-1.4 3.95-3.95l-.45.55Q12.05 38 9.6 39.8m98.25 12.4q-.05-.05-.2-.1v.05q.2.05 1.3 1.45 1.05 1.35 1.1 2.8h.05q-.05.05-.05.2-.05.65-.15 1.25-1.1 6.85 0 12.25h.45q.05 0 .1.05.1-.05.2-.05l-.1-.55q-.15-4.35-.05-6.25.1-1.9.3-3.1.2-1.2.35-2.7.1-1.5 0-1.55-.1-.05.05-.05.15 0-.5-1.7-.65-1.75-4.4-4.25h-.2q.1.15.25.3l-.1-.15.3.45q.2.3.5.65.05.05.1.15l.7.85m7.95-32.9q-.1 0-.15-.05l-.05.1.25-.05h-.05M131 34.8q-1.25-2.5-2.8-3.2l-.05.05q-.05.2 0 .45.05.1.1.3.1.3.25.65l-.3-.35q3.1 4.25 4.1 8.8.35 1.85 1.6 6.3 1.25 4.4 2.3 10.15 1.05 5.7 2.1 11.2 1.05 5.5 1.25 11.4 1.2-5.4.45-8.75-.75-3.4-1.2-6.7-.5-3.35-1.55-8.3-1.1-4.95-2.75-12-1.3-5.6-3.5-10m-6-15.85h-.7q-3.3 0-6.35.3-2.05.15-2.4.15l-.05.05q.1.05.2.15 0 .1.05.15l.1.2q.15.05.3.05.3.3.6.55l.2.2q-.1-.65 3.1-.85 3.15-.25 4.05-.2 2.05.05 4.2.3.65.3.95.6.25.25.35.45l.05.05q.55.65.8.9l.05.25v-.15q.2.2 0-.15l-.1-.7q-.1-.25-.2-.55-.35-.95-1.45-1.55l-3.75-.2m5.3 2.75h.05l.05.15q-.05-.1-.1-.15m-8.35-12.05q-.05 0-.15-.35-.2-.5-.55-1-.4-.55-.65-.8-3.85-3.9-7.35-6.35l-.95-.3Q109.7-.3 106.6.1l.3.05q-1.15.15-1.85.3l.05.15q2.8.2 4.5.6 1.7.35 2.3.6l.45.15 1.55.95q1.7 1.05 3.8 3.3 2.05 2.2 4.15 3.75.1-.2.1-.3M110.6.7h.1l.65.1q-.4-.05-.75-.1z"
                    id="HaiSpe047__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M53.55 30.05q-.55-.5-.65-.55-6.05-5.4-11.65-11.2-.85-.9-1.45-1.9-.55-.9-.9-1.95-.05-.1-.1-.25-.55-1.35-1.2-2.45-.2-.75-.6-1.5-1.25-2.2-2.6-4.25-.9-1.3-1.85-2.25Q28.4-.4 22.35.05q-6.75.9-13.1 2.9-1.9.6-3.6 1.7Q1.4 7.3 0 11.8q5.45 1 10.65 2.6.8.2 1.65.5 8.95 2.9 17.2 7.6 2.55 1.45 5 3.1 8.5 5.55 16.1 13.2 4.05 4.05 7.5 8.3l.7.9q.85 1 1.65 2.1 2.3 3.1 4.35 6.35.15-.25.25-.45.25-.65.45-1.25.45-1.4.75-2.8.55-3.05.15-6.3-.1-.55-.15-1.1-.15-.75-.35-1.45-.15-.35-.25-.65-.05-.2-.1-.3-.5-1.3-1.2-2.45-1.45-2.25-3.8-3.9-1.8-1.3-3.45-2.7-1.35-1.1-2.6-2.15l-.1-.1-.25-.25q-.3-.25-.6-.55z"
                    id="HaiSpe047__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe047__Symbol_179_0_Layer0_0_FILL"
                transform="translate(92.75 50.6)"
                id="HaiSpe047__col1n"
            />
            <use
                xlinkHref="#HaiSpe047__Symbol_110_0_Layer0_0_FILL"
                transform="translate(145.95 50.6)"
                id="HaiSpe047__col1d"
            />
            <use
                xlinkHref="#HaiSpe047__Symbol_48_0_Layer0_0_FILL"
                transform="translate(145.5 57.05)"
                id="HaiSpe047__col1s"
            />
            <use
                xlinkHref="#HaiSpe047__Symbol_36_0_Layer0_0_FILL"
                transform="translate(208.05 46.95)"
                id="HaiSpe047__col2n"
            />
        </g></g>
    )
}

export default HaiSpe047
