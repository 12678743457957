import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999",
    "col2n": "#BFBFBF"
}

function ObjToo076({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17 1.1q.15-.2.15-.45 0-.3-.2-.45-.2-.2-.45-.2-.3 0-.45.2L12.4 4.1q-.05 0-.05.05-1-.65-2.25-.85-.95-.15-1.9.1-1.05.25-1.95.95-.05.1-.15.1-.05.05-.05.1L6 4.5 1.1.35Q.85.2.6.2T.15.45Q0 .65 0 .9t.25.45l4.9 4.15q-.1.1-.2.25-.65.9-.8 2.1l-.05.05q-.05.4-.05.8v.1q.05.5.15 1 .2 1.05 1 1.95l-4.35 4.6q-.2.2-.2.5 0 .25.2.45.2.15.5.15.25 0 .45-.2l4.3-4.5q.2.2.45.35.95.6 2.1.75h.15q.5.1 1 .1.55-.05 1.2-.25.1 0 .35-.15.6-.2 1.1-.65.15 0 .2-.05.2-.1.35-.3.1-.05.15-.1h.05l5.3 4.5q.2.15.45.15t.45-.2q.15-.25.15-.5t-.2-.45l-5.3-4.5H14l.1-.2q.45-.85.6-1.9v-.3q.1-.45.1-.9V8.1q-.05-.5-.15-.95-.35-.95-.95-1.75-.2-.15-.25-.3l-.1-.1L17 1.1m-5.75 7.25v.5q-.05.1-.05.2-.1.3-.3.6 0 .1-.05.1-.15.1-.3.25-.1.05-.1.1-.15.05-.35.15-.35.15-.9.05h-.05q-.3-.05-.55-.15-.4-.2-.6-.45v-.05l-.05-.1q-.05 0 0-.1-.15-.1-.2-.3-.1-.35-.1-.6v-.1q0-.05.05-.1 0-.2.05-.4 0-.1.05-.2.2-.25.45-.5V7.2l.1-.1q.05-.05.3-.15.05-.05.2-.05.35-.2.8-.15.2.05.35.1.25.15.45.2.15.2.3.3.05.1.15.1.05.15.2.25l-.05.1q.15.3.2.55z"
                    id="ObjToo076__Symbol_243_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.65 10.9l-3.2.5-.3-.8q-.4-1.05-1.15-2.1-.2-.25-.35-.55-.1-.05-.15-.2l2.3-2.45Q24.2 3.25 22 2.15l-2 2.7q-.2-.15-.45-.25-.6-.25-1.35-.5-.45-.1-.95-.25-.45-.15-1.05-.25h-.15L16 .1q-2.6-.35-5.15.3l.75 3.5q-.5.1-.95.3-1.1.35-2.1 1.05-.35.2-.6.5l-2.5-2.5Q3.25 4.85 2.05 7L4.9 9.1q-.2.4-.4.95-.55.95-.8 2.2-.05.2-.1.55-.05.1-.05.2l-3.4-.05q-.35 2.85.25 5l3.35-.4q.1.55.3 1.1.45 1.05 1 2.05.25.3.45.65L3.3 23.5q1.6 2.35 3.65 3.45L9 24.5q.3.2.7.35 1.1.55 2.25.85.45.05.95.15v3.1l.15.05q2.65.35 5.1-.35l-.45-3q.65-.2 1.25-.45 1-.4 2.05-1.1.25-.15.4-.35l2.25 2.05q2.2-1.65 3.35-3.95l-2.3-1.75q.15-.4.25-.8.5-1.05.7-2.25.05-.3.1-.55 0-.15.05-.2v-.2L29 16q.35-2.65-.35-5.1m-8.4-2.5q.55.5 1.1 1.25 1.5 2 1.75 4.35v.1q0 .85-.1 1.8-.35 2.45-1.85 4.2-.05.15-.1.2-.55.65-1.35 1.15-.05.1-.2.1-.85.65-1.85 1.1-1.2.4-2.5.55-.8.05-1.75-.1-2.85-.4-4.75-2.4-.45-.45-.75-.9-1.65-2.15-1.75-4.7 0-.05.05-.1-.05-.7.05-1.4.35-2.65 2.1-4.5.55-.65 1.2-1.1 2.8-2.15 6.2-1.65 2.6.35 4.5 2.05z"
                    id="ObjToo076__Symbol_242_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo076__Symbol_243_0_Layer0_0_FILL"
                transform="translate(106.25 282.35)"
                id="ObjToo076__col1n"
            />
            <use
                xlinkHref="#ObjToo076__Symbol_242_0_Layer0_0_FILL"
                transform="translate(101.05 276.2)"
                id="ObjToo076__col2n"
            />
        </g></g>
    )
}

export default ObjToo076
