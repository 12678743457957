import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function ShiLon084({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.5-.65-.95-1.3-.1-.1-.15-.2-.25-.35-.5-.65-.05-.1-.15-.2l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-16.2 11.2-35.6 14.7-.55.1-1.05.2h-.05l-.05.05L79.25 32 60.5 18.6h-.05l-.3-.1h-.1q-.55-.15-1.05-.25-1.85-.45-3.6-.95-7.45-2.1-14.4-5.45-6.4-3.1-12.35-7.2L27.1 3.5q-1.15-.8-2.2-1.65-.3-.2-.6-.45L22.45 0q-.1.05-.15.15-.05.05-.05.1-3 4-5.65 8.45-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-1.85 4-3.45 8.3-.2.45-.35.95l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2l-.6 2q-.55 1.9-1.05 3.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q1.9-1.65 4.2-2.55 1.35-.5 2.85-.8.15-.05.3-.05l1.85-.2q7.4-.3 11.8 3.85.1.1.2.15 1.55-.95 2.1-.85-.25.15-.45.35-.85.6-.85.5-.05-.15-.1.05-.1.15-.5.15.2.25.45.5l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95.65.15 1.35.3 5.65 1.25 11.35 2.3 3.6.65 7.2 1.2 4.8.75 9.6 1.3 2.4.3 4.8.55 1.25.1 2.55.25.5 0 1.1.1v-.1l21.1-9.9 24.55 10.1q14.7-1.2 29.3-4 4.25-.85 8.55-1.8.6-.15 1.25-.3 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q.15-.15.3-.25 4.5-4.8 12.35-4.45l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5m-20.05 13.45v-.15l.05.15h-.05z"
                    id="ShiLon084__Symbol_524_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M14.85 44.9v-.05h-1V59.9q0 .15.1.3l.05.05q.1.15.35.15H31.4q.2 0 .35-.15.15-.15.15-.35V44.85h-1v.05H14.85m0 1H30.9v4.95H14.85V45.9m16.05 5.95v7.55H14.85v-7.55H30.9m-18-18.7q-.25-.1-.4 0-.2.1-.25.3-1.35 3.95-2.3 8.5-1.9 8.8-8.8 13.45-.2.1-.9.8-.25.25-.25.45-.05.2.55.45.65.25 1.45-.7.8-.95 1.4-1.45 5.8-4.75 7.55-12.8l.3-1.4q.65-2.75 1.45-5.3.2-.85.5-1.7.05-.2 0-.35-.1-.2-.3-.25m25-14.9l-.1.1q-.15.15-.3.35l-.2.3q-.2.2-.4.45-.7.8-1.4 1.4-1 .9-2.05 1.45-4.7 2.5-10.75-1.05-7.3-4.3-11.75-10.35-1.1-1.5-2.35-3.1-2.2-2.85-4.8-5.95-.3-.2-.6-.45L1.35 0q-.1.05-.15.15-.05.05-.05.1.45.5.85 1Q3.65 3.2 5.1 5q2.85 3.45 5.05 6.5 4.6 6.15 12.05 10.6 7.6 4.4 13.25.25.2-.2.45-.4.85-.75 1.65-1.65.2-.25.45-.55.4-.5.8-1.1 0-.05.05-.1t.1-.05q-.55-.15-1.05-.25m71.95 15.2q-.05-.2-.25-.3-.1-.05-.2-.05t-.2.05q-.2.05-.3.25-.05.15 0 .35.4 1.2.8 2.5.8 2.85 1.45 5.9 1.75 8.05 7.55 12.8.8.65 1.65 1.3.85.6.85.5.05-.15.1.05.1.15.5.15.05 0 .05-.05.2-.15.35-.3-.1-.15-.35-.4-.7-.7-.9-.8-6.9-4.65-8.8-13.45-.95-4.55-2.3-8.5m-17.9 11.4h-1V59.9q0 .2.15.35.1.15.35.15h17.05q.2 0 .35-.15.15-.15.15-.35V44.85h-1v.05H91.95v-.05m0 1.05H108v4.95H91.95V45.9M108 51.85v7.55H91.95v-7.55H108M121.65 1.8q.25-.35.5-.65l.1-.1q-.1-.1-.15-.2-.25-.35-.5-.65 0 .05-.05.05-.25.3-.5.65-.05.05-.05.1-.25.25-.45.55-.65.8-1.35 1.7-1.45 1.7-3.1 3.5-.4.4-.8.85l-.8.9-2.45 2.45q-4.05 4-8.2 7.45-2.05 1.7-4.1 3.25-.7.55-1.45.95-2.35 1.35-4.85 1.6-5.2.5-9.35-1.6-.8-.4-1.55-.9-.15-.1-.35-.2-.25-.2-.55-.4l-.1-.1q-.25-.2-.5-.35-.35-.35-.7-.65-.35-.3-.7-.65-.55.1-1.05.2.25.6.85 1.05.1.1.25.2.35.25.4.3.05.05.2.15.15.15.35.25.25.25.55.45.45.35.95.65 4.9 3.2 11.4 2.6 3.45-.35 6.6-2.6.1-.05.2-.15 2.55-1.9 5.1-4.05 3.7-3.15 7.3-6.75 2.8-2.75 5.15-5.35 1.8-2.05 3.35-3.95.2-.3.4-.55z"
                    id="ShiLon084__Symbol_460_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M40.6.95h-.05q-1.85.3-3.7.55-.45.05-.85.1-1.75.25-3.5.4-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-.35-.1-.7-.1Q4.2.6 1.4 0l1.55 4.05q.1.2.2.45.25.6.5 1.25.05.2.15.4.15.4.35.85 0 .05.05.1.3.95.5 1.65v.05q0 .15.1.35.1.4.25.95.45 2 .1 4.25-.25 1.05-.45 2.15-1.6 7.75-1.5 15.85.15 9.45 3.05 18.4.85 2-1 6.75-.15.3-.25.65l-.1.2-.2.4q-.9 2.15-1.8 4.1L0 69.3v.1l4 .3q9.75.7 19.45.7 6.95 0 13.85-.35.9-.05 1.85-.1 1.1-.05 2.25-.15 2.15-.15 4.25-.3-3.7-3.3-5.1-6-1.8-3.55-1.8-9.8 0-2.7.85-8 .85-5.35.85-9.8 0-7.45-2.2-14t-2.2-10.05q0-1.35.45-2.65-.05-.25.3-.9l.1-.1q.25-.5.5-.95 1.05-2 1.75-3.45.85-1.7 1.25-2.55.05-.1.1-.15 0-.1.1-.15m1.3 68.7v.05h-.45q.2-.05.45-.05z"
                    id="ShiLon084__Symbol_429_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M7.65 3.85Q7.4 2.5 7.1 1.1 4.2.6 1.4 0l1.55 4.05q.1.2.2.45.25.6.5 1.25.05.2.15.4l.6 1q-.1-.05-.2-.05.3.95.5 1.65v.05q0 .15.1.35.1.4.25.95.45 2 .1 4.25-.25 1.05-.45 2.15-1.6 7.75-1.5 15.85.15 9.45 3.05 18.4.85 2-1 6.75-.15.3-.25.65l-.1.2-.2.4q-.9 2.15-1.8 4.1L0 69.3v.1l4 .3 1.4-3.25q.15-.35.3-.65l.55-1.25q.25-.65.55-1.3.5-1.2.95-2.3 3.4-8.15 3.4-12.05 0-.75-2-7.85-2-7.15-2-10.35 0-3.65 1.3-7.55 1.3-3.85 1.3-7.25 0-2.75-.9-6.55-.05-.25-.1-.55-.15-.5-.25-.95-.25-.05-.45-.1.05-.9.25-.85-.35-1.45-.65-3.05M40.6.95h-.05q-1.85.3-3.7.55-.05.1-.1.15-1.95 2.85-2.95 5.9-.1.45-.15.9h-.15q-.85 2.85-.85 5.85 0 4 1.7 9.4t1.7 9.75l-.2 6.15v2.5q-.05 3.8-.45 8.05-.45 4.75-.45 6.65 0 3.65.25 5.2.35 2.45 1.55 4.4.65 1 1.5 2.55.4.6.9 1 1.1-.05 2.25-.15 2.15-.15 4.25-.3-3.7-3.3-5.1-6-1.8-3.55-1.8-9.8 0-2.7.85-8 .85-5.35.85-9.8 0-7.45-2.2-14t-2.2-10.05q0-1.35.45-2.65-.05-.25.3-.9l.1-.1q-.3 0-.55.05.55-.65 1.05-1 1.05-2 1.75-3.45.85-1.7 1.25-2.55.05-.1.1-.15 0-.1.1-.15m1.3 68.7v.05h-.45q.2-.05.45-.05z"
                    id="ShiLon084__Symbol_407_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M33.1 2.05q.25-.5.5-.95-1.05.05-1 0l-2.6.3q-.75.05-1.45.1-.25 0-.45.05-.5 0-1 .05-3.65.25-7.35.25h-1.7q-3.15-.05-6.2-.25-.15-.05-.3-.05Q8 1.3 4.5.75 4.15.7 3.85.65 1.9.35 0 0q.15.4.35.85 0 .05.05.1 1.7.35 3.45.6.4.05.85.15 3.4.5 6.85.75.15 0 .3.05 1.4.1 2.8.15 1.65.05 3.4.1h1.7q2.35 0 4.65-.1 1.35-.05 2.7-.15h.4q.3-.05.65-.05.5-.05 1.55-.15 1.3-.1 3.4-.25z"
                    id="ShiLon084__Symbol_383_0_Layer0_0_FILL"
                />
                <path
                    fill="#333"
                    d="M165.2 314.2q0 .447.2.7.247.3.8.3.5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-.553.055-.8.35-.2.22-.2.6m3.2-2.4q0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6m1.2-4.1q.5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1m-.4 1.5q0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6m2-4.6q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m.7-2.8q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m.5-3q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m-1-5.4q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m-1 1.9q0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95m.5-5.4q0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6m-.3-2.7q0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6m-1.4-3.55q-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95m-1-2.05q0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95m1.8-1.9q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m-1.6-3.3q0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95m1.8-2q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m-.7-4.05q-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95m1.1-1.85q0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6m1.1-4.8q.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4m-1.6 1.25q-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95m.3-4.45q0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95m-.1-3.1q0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95m.3-1.8q.5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95 0 1 1 1m.3-3.9q0-1.1-1-.95-1 .1-1 .95 0 1 1 1 .5 0 .8-.4.2-.3.2-.6z"
                    id="ShiLon084__Layer9_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon084__Symbol_524_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon084__col1n"
            />
            <use
                xlinkHref="#ShiLon084__Symbol_460_0_Layer0_0_FILL"
                transform="translate(131.1 228.6)"
                id="ShiLon084__col1s"
            />
            <use
                xlinkHref="#ShiLon084__Symbol_429_0_Layer0_0_FILL"
                transform="translate(169.05 247.2)"
                id="ShiLon084__col2n"
            />
            <use
                xlinkHref="#ShiLon084__Symbol_407_0_Layer0_0_FILL"
                transform="translate(169.05 247.2)"
                id="ShiLon084__col2d"
            />
            <use
                xlinkHref="#ShiLon084__Symbol_383_0_Layer0_0_FILL"
                transform="translate(172.85 253.35)"
                id="ShiLon084__col2s"
            />
            <use xlinkHref="#ShiLon084__Layer9_0_FILL" />
        </g></g>
    )
}

export default ShiLon084
