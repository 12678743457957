import { AssetSvgProps } from "../../../shared/assets"

function ShiMot061({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0280D2"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot061__Layer5_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M159.2 258v5l24.8 13.15-24.8 13.15v4.5h8.2l24.95-13.25 24.95 13.25h8.4v-4.4l-24.95-13.25 24.95-13.25V258h-7.45l-25.9 13.7-25.9-13.7h-7.25z"
                    id="ShiMot061__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot061__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot061__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot061
