import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666",
    "col2d": "#999999",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function ObjToy025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M35.05 3.15L27.2.55q-.15-.05-.25-.1Q20.6-1.4 13.55 4.8q-7.3 6.45-11.1 17.7Q-1.3 33.75.65 43.3 2.25 51.2 7 54l7.9 2.65q-.85-.45-1.55-1.15-3.5-3.05-4.85-9.55-1.9-9.55 1.85-20.8 3.8-11.25 11.05-17.7 5.05-4.4 9.65-4.75 2-.15 4 .45m-10.8 9.95q4.95-5.05 9-3.7 4.05 1.35 4.9 8.4.45 4.25-.35 9.05l2.25.75q1.1-5.95.2-11.15Q39 8.5 34 6.8q-5-1.65-10.85 3.9-5.8 5.55-9 15.1-.45 1.4-.8 2.75-2.25 8-1.1 14.75 1.25 7.95 6.25 9.6 5 1.7 10.85-3.85 3.15-3 5.5-7.15l-2.15-.75q-2 3.4-4.4 6-4.95 5.05-9 3.7-4.05-1.35-4.9-8.4-.7-5.95 1.3-13.1.35-1.25.75-2.5 2.9-8.6 7.8-13.75m93.55 29.35q-1.25-7.95-6.25-9.6-5-1.7-10.9 3.85-5.2 5.05-8.35 13.3-.05.1-.05.2-.35.75-.6 1.6-.3.85-.5 1.7-2.6 8.55-1.4 15.8 1.3 7.95 6.3 9.65 5 1.65 10.85-3.9 3.1-3 5.5-7.15 1.6-2.9 2.95-6.3.25-.8.55-1.65.75-2.3 1.25-4.45 1.65-7 .65-13.05m-7.05-7.05q4.1 1.4 4.9 8.4.6 5.1-.75 10.95-.05.15 0 .3-.55 2.1-1.3 4.4-.25.7-.45 1.4-1.25 3.4-2.9 6.25 0 .05-.05.05-1.95 3.45-4.4 6-4.9 5.1-9 3.7-4.05-1.35-4.9-8.35-.75-6.45 1.6-14.2.2-.75.45-1.45.3-1 .7-1.85 0-.1.05-.2 2.75-7.15 7.05-11.65 4.95-5.1 9-3.75m-28.25 6q-.55 1-1.05 2.1-.5 1.2-1.05 2.5-.05.05-.05.15-.4 1.1-.8 2.25l-.3 1v.1q-.2.5-.35 1.05-.85 2.9-1.3 5.7-1.1 6.85.15 12.95 1.65 7.85 6.35 10.7l7.9 2.65q-.85-.45-1.55-1.15-3.5-3.05-4.8-9.55-1.3-6.1-.15-12.95.5-3.3 1.6-6.75.15-.55.35-1.1.35-1.1.8-2.2 0-.1.05-.2.5-1.3 1.05-2.5.5-1.15 1.1-2.2 3.25-6.3 8.1-10.6 5-4.4 9.6-4.75 2-.15 4 .45l-7.85-2.65q-.1-.05-.25-.05-6.35-1.85-13.4 4.35Q85.8 35 82.5 41.4z"
                    id="ObjToy025__Symbol_69_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M58.55 18.6q.5-1.1 1.05-2.1L8.4 0Q6.75 0 5.9.35q-.8.3-1.4.7-.65.4-1.3.9-.35.35-.65.7-1.1 1.2-1.8 3-.1.25-.15.45-.15.45-.25.85Q-.2 9.1.15 11q.5 2.25 2.05 2.75.15.05.3.05l17.1 5.8v-.05q0-1.3 1.35-2.9 1.05-1.3 2.4-1.95.5-.3 1-.45h4.95q.8.2 1.5.45 3.9.8 5.85 3.1.6.75 1.05 1.7.2.45.35.9.45 1.35 2.15 4.6.6 1.1.95 1.8l13.55 4.55q.45-2.8 1.3-5.7.15-.55.35-1.05v-.1l.3-1q.4-1.15.8-2.25 0-.1.05-.15.55-1.3 1.05-2.5m30.3 16.5q.55-1.55.65-2.95.05-.7 0-1.35-.05-.5-.1-1.05-.35-1.75-1.45-2.4-.7-.55-1.65-.7l-3.6-1.25q-1.25.05-2.65 1.25-.85.75-1.5 1.8-.85 1.2-1.35 2.75-.05.2-.1.35-.8 2.7-.35 5 .45 2.15 1.9 2.85l4.25 1.4q1.5.35 3.2-1.15.1-.1.25-.2 1.55-1.5 2.4-4 .05-.2.1-.35z"
                    id="ObjToy025__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M55.9 20.85q.4-1.15.8-2.25 0-.1.05-.15L1.8 0Q.7 1.2 0 3l25.4 8.6h3.15q.8.2 1.5.45 3.9.8 5.85 3.1l19.7 6.7.3-1m21.9 4.95q-.85 1.2-1.35 2.75-.05.2-.1.35L80 30.15l.1-.4q.5-1.55 1.3-2.75l-3.6-1.2z"
                    id="ObjToy025__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M7.75.7Q7.05.15 6.1 0q-.55.05-1.15.25-.35.15-.7.4-.4.25-.75.55Q2.65 2 1.95 3q-.8 1.2-1.3 2.75l-.1.4q-.8 2.65-.4 4.95.2.95.6 1.7.45.7 1.1 1.05l.2.1.65.2q1.5.35 3.2-1.15.1-.1.25-.2 1.55-1.5 2.4-4 .05-.2.1-.35.55-1.55.65-2.95.05-.7 0-1.35-.05-.5-.1-1.05Q8.85 1.35 7.75.7z"
                    id="ObjToy025__Symbol_4_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToy025__Symbol_114_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M43.75 28.8q0-.1.05-.15.15-.55.15-.9v-.1q1.05-6.65-.2-12.7-1.9-9.35-8.2-11.6L27.2.55q-.15-.05-.25-.1Q20.6-1.4 13.55 4.8q-7.3 6.45-11.1 17.7Q-1.3 33.75.65 43.3 2.25 51.2 7 54l12.25 4.15q5.55.6 11.6-4.7 4.65-4.1 7.9-10.25h-.05q.45-.6.45-1.2 0-3.85 1.4-7.05 1.1-1.9 1.85-3.35.95-1.75 1.35-2.8m60.3-2.45q-6.35-1.85-13.4 4.35Q85.8 35 82.5 41.4q-.55 1-1.05 2.1-.5 1.2-1.05 2.5-.05.05-.05.15-.4 1.1-.8 2.25l-.3 1v.1q-.2.5-.35 1.05-.85 2.9-1.3 5.7-1.1 6.85.15 12.95 1.65 7.85 6.35 10.7L96.4 84q5.5.65 11.55-4.7 4.65-4.1 7.95-10.25 1.55-2.95 2.8-6.35.15-.55.35-1.1.85-2.55 1.4-5 1.95-8.4.4-15.8-1.85-9.3-8.2-11.6l-8.35-2.8q-.1-.05-.25-.05m-1.85 38.2q-.1-.05-.15-.1l.4.15q-.15 0-.25-.05z" />
                    <path d="M102.05 64.45q.05.05.15.1.1.05.25.05l-.4-.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy025__Symbol_114_0_Layer0_0_FILL"
                transform="translate(63 250.8)"
                id="ObjToy025__col1n"
            />
            <use
                xlinkHref="#ObjToy025__Symbol_69_0_Layer0_0_FILL"
                transform="translate(63 250.8)"
                id="ObjToy025__col1d"
            />
            <use
                xlinkHref="#ObjToy025__Symbol_29_0_Layer0_0_FILL"
                transform="translate(85.9 275.7)"
                id="ObjToy025__col2n"
            />
            <use
                xlinkHref="#ObjToy025__Symbol_13_0_Layer0_0_FILL"
                transform="translate(86.65 278.35)"
                id="ObjToy025__col2l"
            />
            <use
                xlinkHref="#ObjToy025__Symbol_4_0_Layer0_0_FILL"
                transform="translate(166.1 302.35)"
                id="ObjToy025__col2d"
            />
        </g></g>
    )
}

export default ObjToy025
