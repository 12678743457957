import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#D9D9D9"
}

function LegSki003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M4.1.15q-.25-.1-.5-.1-.1.05-.15.15V0Q1.35 3.8.4 8.15.15 10 0 11.95q4.05.3 8.15.55v-.35q.2-2.1.4-4 .9-4.5 2.25-6.75Q7.45.8 4.1.15m21.4 8q.2-2.2.85-4.4-4.25-.55-8.45-1.2-.9 2.8-1.2 5.6-.1 2-.1 4-.05.2 0 .35-.05.25 0 .5 4.35.25 8.75.45v-.95-.35q0-2 .15-4m16.9 0q.1-1.35.45-2.75-3.95-.3-7.85-.7-.45 1.7-.6 3.45-.1 2-.1 4-.05.2 0 .35-.05.65 0 1.3 4 .15 8.05.25-.05-.75-.05-1.55v-.35q-.05-1.95.1-4m17.7-2h-.95l-.95-.05q-3.45-.05-6.85-.2v8.35l6.85.1h2.5v-8.2h-.6m15.1 8.2q2.85-.05 5.65-.05V5.85l-5.65.2-3.05.1h-.55v8.25h.55q1.55-.05 3.05-.05m22.65-6.2q-.15-1.75-.55-3.5-4 .35-7.9.65.3 1.45.5 2.85.1 2.05.1 4-.05.2 0 .35-.05.8-.05 1.6 4-.1 8.05-.25V12.5v-.35q-.05-2-.15-4m17.75 0q-.35-2.85-1.25-5.65-4.25.65-8.45 1.2.65 2.2.9 4.45.1 2 .15 4-.05.2 0 .35v1q4.35-.2 8.75-.4v-.6-.35q0-2-.1-4m16.7 3.9q-.2-1.95-.5-3.9-.95-3.95-3-8.1l-.15.1.1-.15q-.1 0-.15.05-.35.05-.75.15-3.2.6-6.4 1.15 1.35 2.25 2.25 6.8.2 1.9.4 4 0 .15.05.35v.1q4.05-.25 8.15-.55z"
                    id="LegSki003__Symbol_18_0_Layer0_0_FILL"
                />
                <g
                    id="LegSki003__Symbol_69_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M6.6 12.15q.15-1.95.4-3.8Q7.95 4 10.05.2q-.3.3-.6.65Q4.15 4.5 0 11.7q4.85.3 6.6.45m16.7-3.8q.3-2.8 1.2-5.6-3.55-.55-7.1-1.15-1.35 2.25-2.25 6.75-.2 1.9-.4 4v.35q4.2.25 8.45.5-.05-.25 0-.5-.05-.15 0-.35 0-2 .1-4m17.7 0q.15-1.75.6-3.45-.35-.05-.7-.05-4-.4-7.95-.9-.65 2.2-.85 4.4-.15 2-.15 4V13.65q4.45.2 8.95.35-.05-.65 0-1.3-.05-.15 0-.35 0-2 .1-4m16.45-2.3q-2.3-.1-4.65-.25-1.65-.1-3.35-.2Q49.1 7 49 8.35q-.15 2.05-.1 4v.35q0 .8.05 1.55l3.85.1 4.65.1h.5V6.1q-.25-.05-.5-.05m20.75.3q-3.35.05-6.7.05-2.1 0-4.2-.05v8.2q5.45.05 10.9.05V6.35m18.3 2q-.2-1.4-.5-2.85l-4.1.3q-.65.05-1.35.1-.65 0-1.25.05L88 6q-.25 0-.55.05v8.45H88q1.3-.05 2.55-.05 3-.1 6-.15 0-.8.05-1.6-.05-.15 0-.35 0-1.95-.1-4m16-4.45q-.35 0-.7.05l-7.9.9q.4 1.75.55 3.5.1 2 .15 4V14.05q4.45-.15 8.95-.35v-1q-.05-.15 0-.35-.05-2-.15-4-.25-2.25-.9-4.45m9.7 4.45q.1 2 .1 4v.95q4.2-.25 8.45-.5v-.1q-.05-.2-.05-.35-.2-2.1-.4-4-.9-4.55-2.25-6.8l-6.2 1q-.45.1-.9.15.9 2.8 1.25 5.65M135.75.3q.05.25-.35-.05 2.05 4.15 3 8.1.3 1.95.5 3.9 1.75-.1 6.35-.6-4.35-7.7-9.5-11.35m-.4-.1l-.1.15.15-.1.05-.05h-.1z" />
                    <path
                        fillOpacity={0.961}
                        d="M15.15 8.35q.9-4.5 2.25-6.75Q14.05 1 10.7.35q-.25-.1-.5-.1-.1.05-.15.15V.2Q7.95 4 7 8.35q-.25 1.85-.4 3.8 4.05.3 8.15.55v-.35q.2-2.1.4-4m16.8 4q0-2 .15-4 .2-2.2.85-4.4-4.25-.55-8.45-1.2-.9 2.8-1.2 5.6-.1 2-.1 4-.05.2 0 .35-.05.25 0 .5 4.35.25 8.75.45v-.95-.35m16.95 0q-.05-1.95.1-4 .1-1.35.45-2.75-3.95-.3-7.85-.7-.45 1.7-.6 3.45-.1 2-.1 4-.05.2 0 .35-.05.65 0 1.3 4 .15 8.05.25-.05-.75-.05-1.55v-.35m16.85-6l-.95-.05q-3.45-.05-6.85-.2v8.35l6.85.1h2.5v-8.2H65.75m21.7-.3l-5.65.2-3.05.1h-.55v8.25h.55q1.55-.05 3.05-.05 2.85-.05 5.65-.05V6.05m17.15 6.3q-.05-2-.15-4-.15-1.75-.55-3.5-4 .35-7.9.65.3 1.45.5 2.85.1 2.05.1 4-.05.2 0 .35-.05.8-.05 1.6 4-.1 8.05-.25V12.7v-.35m17.7 0q0-2-.1-4-.35-2.85-1.25-5.65-4.25.65-8.45 1.2.65 2.2.9 4.45.1 2 .15 4-.05.2 0 .35v1q4.35-.2 8.75-.4v-.6-.35m12.95-12l.1-.15q-.1 0-.15.05-.35.05-.75.15-3.2.6-6.4 1.15 1.35 2.25 2.25 6.8.2 1.9.4 4 0 .15.05.35v.1q4.05-.25 8.15-.55-.2-1.95-.5-3.9-.95-3.95-3-8.1l-.15.1z"
                    />
                    <path d="M135.55.1v.05l.1-.15-.1.1z" />
                    <path d="M135.55.15q-.05 0-.1.05l-.05.05q.4.3.35.05-.05-.1-.1-.3l-.1.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#LegSki003__Symbol_69_0_Layer0_0_FILL"
                transform="translate(119.8 310.1)"
                id="LegSki003__col1n"
            />
            <use
                xlinkHref="#LegSki003__Symbol_18_0_Layer0_0_FILL"
                transform="translate(126.4 310.3)"
                id="LegSki003__col2n"
            />
        </g></g>
    )
}

export default LegSki003
