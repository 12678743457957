import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00284D"
}

function AccMor071({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.5 1.9q-.1-.1-.25-.2L9.2 0q-.55.4-1 .85L8.15.9q-.5 2.05-3.5 3.45v.05Q1.9 7.55 0 11.4v4.45l2.25-3.7Q5.4 7 11.45 1.9l.05.05V1.9m127.35 9.5q-2.05-3.9-4.6-7-2.85-1.7-3.45-3.45V.9l-1.1-.9-2.05 1.7q-.1.1-.25.2v.05l.05-.05q6.05 5.1 9.2 10.25l2.2 3.55v-4.3z"
                    id="AccMor071__Symbol_177_0_Layer0_0_FILL"
                />
                <path
                    fill="#BFBFBF"
                    d="M2.9 2.9q.8-.75.8-1.85 0-.6-.2-1.05Q1.6 1.6 0 3.45v.05q.45.2 1.05.2 1.1 0 1.85-.8m125.6.8q.55 0 1.05-.2h.05q-1.65-1.9-3.45-3.45V0q-.05 0-.05.05-.2.45-.2 1 0 1.1.75 1.85.75.8 1.85.8z"
                    id="AccMor071__col2n_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor071__Symbol_177_0_Layer0_0_FILL"
                transform="translate(123.05 228.3)"
                id="AccMor071__col1n"
            />
            <use
                xlinkHref="#AccMor071__col2n_0_Layer0_0_FILL"
                transform="translate(127.7 229.2)"
            />
        </g></g>
    )
}

export default AccMor071
