import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.9 11.3l1.5-.2q-2.6-7.6-7.65-9.8l.45 3.05q-.05-.05-.1-.05Q30.4 2 27.15.9l-.5.05.45 3.15q-.75-.4-1.8-.8-1.95-.8-3.4-1.4Q18.4.45 17.4 0l.35 2.5-5.6.8.3 1.9Q8.25 4.1 6.2 3.65 4.6 8.15 2.6 9.6-.45 11.65.05 14.95q.1.8.15 1.35.45-2.15 3.3-.4 1.05-1 4.65-3.15 4.95-3 10.05-3.45l2.65-.4q8.3-1.2 10.85.2.35.15.65.55.05.05.2.15 3.75-1.9 8.35 1.5m1.5-.2h.05q.05-.5-.3-1 .15.45.25 1m50.95-1.5q-2.05-1.45-3.6-5.95-2.1.45-6.25 1.55l.25-1.9-5.55-.8.35-2.5q-1.05.45-4.5 1.9-1.5.6-3.45 1.4-1.05.4-1.8.8l.45-3.15-.5-.05Q65.5 2 60.8 4.3q-.05 0-.1.05l.45-3.05q-5.05 2.2-7.65 9.8l1.5.2q4.6-3.4 8.4-1.5.1-.1.2-.15.25-.4.6-.55 2.55-1.4 10.9-.2l2.65.4q5.05.45 10.05 3.45 3.55 2.15 4.65 3.15 2.8-1.75 3.25.4.1-.55.2-1.35.45-3.3-2.55-5.35z"
                    id="FacBro027__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro027__Symbol_27_0_Layer0_0_FILL"
                transform="translate(144.55 122.25)"
                id="FacBro027__col1n"
            />
        </g></g>
    )
}

export default FacBro027
