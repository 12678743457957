import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F"
}

function ShiSho062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M115.4 2.4q-10.8 6.5-22.95 9.8Q80.3 37.6 67.6 39.25q.45.15-.6.1v-.05h-.65q-.55-.05-1.3-.15-12.5-1.55-24.6-26.9-12.2-3.3-23-9.8-.4-.25-.7-.45L10.3 29.25q-.05.2-.05.4-.05.2-.05.4l-2.7 14q-.55 2.7-1.1 5.45L2.95 63.8q-1 3.9-1.95 7.8l-.95 3.6v.25L0 75.5h.2q.25.1.5.15H.85q2.05.5 4.15.95 6.85 1.55 13.7 2.75 5.5.95 11 1.75 1.35-1.8 4.1-3.35 2.3-1.4 5.7-2.6 10.95-3.9 26.3-4h1.25q14.7.1 25.35 3.7l.9.3q.6.2 1.15.45 4 1.5 6.4 3.3 1.35 1 2.2 2.05l.05-.05.1.2q7.35-1.05 13.5-2.2 5.5-1.05 11-2.3 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-8.8-43.1q-.6.35-1.15.7z"
                    id="ShiSho062__Symbol_173_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M19.3 1.2q-.15-.15-.35-.15H.5q-.2 0-.35.15-.15.15-.15.35 0 .2.15.35.15.15.35.15h17.95v4.5q0 .2.15.35.15.15.35.15.2 0 .35-.15.15-.15.15-.35v-5q0-.2-.15-.35M44.15 0h-1.2v31.85h1.2V0M87 1.2q-.15-.15-.35-.15H68.2q-.2 0-.35.15-.15.15-.15.35v5q0 .2.15.35.15.15.35.15.2 0 .35-.15.15-.15.15-.35v-4.5h17.95q.2 0 .35-.15.15-.15.15-.35 0-.2-.15-.35z"
                    id="ShiSho062__Symbol_174_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho062__Symbol_173_0_Layer0_0_FILL"
                transform="translate(126.1 234)"
                id="ShiSho062__col1n"
            />
            <use
                xlinkHref="#ShiSho062__Symbol_174_0_Layer0_0_FILL"
                transform="translate(148.95 273.3)"
                id="ShiSho062__col1s"
            />
        </g></g>
    )
}

export default ShiSho062
