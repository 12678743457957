import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00AFAF",
    "col1n": "#00DEDE",
    "col2d": "#D9D9D9",
    "col2n": "#EEEEEE"
}

function AccHat135({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M145.8 17.1q-6.4-2.7-16.4-3.95-4.95-.65-10.7-.9-1.7-.1-3.5-.1-1.55-.45-3.55-.9-9.6-1.7-10.6-1.9Q97.8 8.7 92.5 7.1q-1.15-.95-1.9-2.4-.8-1.5-1.45-4.7-1.8.2-3.45.35-.1 0-.2.05-13.15 1.45-18.95 2.5-10.6 1.9-18.5 5.1-2.25 1-4.35 2.05 1.15 6.6-.5 11.2-.3.2-.6.5-1.25 1.2-3 2.1.4-.35.8-.65-.85.45-1.95 1.05-1.35.05-1.8.95-2.45 1.3-5.75 3-8.8 4.65-14.15 8.5Q0 48.7 0 64.15q0 2.35.7 4.4.3 1.15.8 2.25.05.15.15.3.95 2.25 3.8 5 2.35 2.3 4.85 3.65 9.8 4.75 21.4 0 1.2-.45 2.5-.95 1.5-.6 2.75-1.15Q40.8 76 45.4 73.8q9.75-4.65 11.9-5.6 5.85-2.55 8.7-2.55 1.7 0 4.65.6 3 .6 6.55.6 3.45 0 8.5-2.25t7.15-3.95q2.2-1.7 9-3.9t8.8-2.2q3.8 0 8 1.05 1.65.4 7.65 2.3 5.15 1.6 10.05 2.35 5.2.9 10.05.9 6 0 9.7-2.05 1.9-1.1 3.2-2.75 3.35-4.2 3.35-13.4 0-18.15-15.75-25.4-.55-.2-1.1-.45z"
                    id="AccHat135__Symbol_477_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M125.7 3.85q4.6 2.95 6.65 5.2 3.2 3.6 3.2 8.2 0 1.85-.65 3.1-.65.85-1.05 1.5 3.4-.5 5.15-2.25 2.05-2.05 2.05-6.1 0-3.6-5.5-6.65-6.35-2.8-8-3.6-2.75-1.35-8-3.2-.1-.05-.15-.05 3.15 1.85 6.3 3.85m24.6.8q5.85 7.5 5.85 13.35 0 8.25-2.75 12.1-3.3 4.5-11.3 4.5-14.4 0-25.95-9.05-7.65-6-19.4-17.5 1.2 1.55 3.65 4.65 1.9 2.85 1.9 5.9 0 7.05-3.75 9.45-3.8 2.35-5.8 3.25-2.1.85-2.1.95 0 .05-12.3 3.25-11.65 3-14.25 3-8.05 0-12.95-4.55Q46.3 29.4 52 19.1l-2 2.85Q47.7 23.7 46.2 28q-1.35 4-1.35 8.1 0 4.15 1.4 6.75 1.6 2.9 4.7 3.75-.15 0-.25.15l-.05.15q-5.2 1.3-12.6 4.55-2.7 1.5-5.2 2.8-2.4 1.25-3.95 1.9-.65.8-1.9.8l-.5-.1q-.2 0-.4-.05l-12.5-2.5q-5.6-1-7.85-1.55-.2-.05-.35-.1-.65-.15-1.15-.35l-.05.05-.8-.1q-1.5-.25-2.95-1-.2-.1-.45-.25.3 1.15.8 2.25.05.15.15.3.95 2.25 3.8 5 2.35 2.3 4.85 3.65 9.8 4.75 21.4 0 1.2-.45 2.5-.95 1.5-.6 2.75-1.15 3.85-1.65 8.45-3.85 9.75-4.65 11.9-5.6 5.85-2.55 8.7-2.55 1.7 0 4.65.6 3 .6 6.55.6 3.45 0 8.5-2.25t7.15-3.95q2.2-1.7 9-3.9t8.8-2.2q3.8 0 8 1.05 1.65.4 7.65 2.3 5.15 1.6 10.05 2.35 5.2.9 10.05.9 6 0 9.7-2.05 1.9-1.1 3.2-2.75 3.35-4.2 3.35-13.4 0-18.15-15.75-25.4 2 1.9 4.1 4.65z"
                    id="AccHat135__Symbol_412_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M153.2 12.1V9.95q-1.4 2.75-5.05 4.1-2.3.9-7.35 1.65-5.35.85-7.45 1.5-3.4 1.1-5.05 3.25-1.6-.7-4.1-.8.1-.35.2-.85.2-.9.2-1.95 0-1.9-1.55-3.3-.75-.65-2.6-1.65-2.05-1.05-2.6-1.5-3-2.5-4.7-6.65Q113 3.4 112.6 2q-.35-1-.7-1.6-.5-.4-.85-.25V0q-.002.054-.05.2-.1.15-.25.45-.7 1.2-2.7 4.75-1.85 3.2-1.85 6.55 0 2.2 1.15 4.1.25.4.55.8.6.8 2.85 2.75 2.85 2.55 3.2 2.9.55.45 1.05.85-.35 0-.85.2-1.9 1-2.45 1.15-.45.1-.8.15-1.25.1-2.55.25-4.65.5-9.65 1.75-11.7 2.9-20.45 4.2-8.8 1.3-12.3 2.45-3.45 1.1-9.85 4.55v.25q-.3.15-.85.5-.7.4-1.85 1-2.25 1.7-7.05 5.75-6.5 5.55-18.9 25-5 7.8-9.55 15.95-1 1.65-1.9 3.4-5.25 9.75-9.3 20.1-.45.85-.85 1.7l-.1.4q-.45 1.4-1.15 3.35l-.5 1.35q-.15.4-.3.85-.05.05-.05.15-1.3 3.2-2.15 6.6-2 7.9-1.5 16.65.45 2.4 1.1 4.75.85 3.05 1.35 4.95.3-2.95 1.05-5.8.95-3.85 3.65-10.95 1.45-3.75 3-6.7 3.8-8.95 9.4-13.95 8.4-7.5 13.3-14.85H33q.15-.25.35-.55 2.25-4.05 4.6-8.05 2.7-4.55 5.5-9 5.9-9.2 17.85-27.5 1.35-2.15 3.95-6.8 2.45-4 7.15-8.85 4.6-4.85 17-4.6 12.35.25 15.55.9 3.2.65 7-1.95 3.75-2.6 9.55-3.45h1.1q4.75 0 9.1.2 5.75.25 10.7.9.95-.95 2.25-1.95 3.2-2.45 4.3-4.25.8-1.4 2.35-5.15 1.9-4.75 1.9-6.3m-37.45 11.85h-.05v-.05l.05.05m65.9 67.55q-5.7-10-12.55-15.8-3.7 2.05-9.7 2.05-4.85 0-10.05-.9 3.8 10.55 9.1 19.15 4.65 7.45 15.95 17.85h.05q3.55 2.65 6.65 6.2 1.85 2.05 5.1 6.2 2.25 2.7 5.3 11.7 1.75 5.3 2.5 8.85 1.25-6.35 1.25-12.65 0-9.45-3.75-20.6-1.8-5.15-2.7-7.95-3-6.8-7.15-14.1z"
                    id="AccHat135__Symbol_359_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 81.65q13.15-31.45 36.9-57.5Q10.1 45.2 0 81.65m154.4-4.15q-3.3-7.85-11.15-11.9 1.25 1.65 2.4 3.25 6.15 8.7 10.25 17.45 2.3 4.85 3.95 9.75-2.2-10.7-5.45-18.55M106.75 2.2Q106 1.35 104.8.85q-1.6-.7-4.1-.8-.55-.05-1.15-.05h-.3q-.2 0-.45.05-3.65.05-5.1.85-1.55.8-2.1 2.85v.05q.1-.05.2 0 .15-.05.3-.05 7.75.35 7.75 2.9 0 1.2-.75 2.4 4.75 0 9.1.2-.15-5.3-1.45-7.05z"
                    id="AccHat135__Symbol_330_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M173.7 32.55q5.8-1.05 18.95-2.5l-.9-4.7q-15.07-4.465-23.1-1.4-7.98 3.065-14.7 9l1.25 4.7q7.9-3.2 18.5-5.1z"
                    id="AccHat135__Layer7_0_FILL"
                />
                <path
                    fill="#888"
                    d="M196.65 25.45l-2.6-9.5q-23.4-1-46.05 8.85l2.4 9.8q2.45-1.25 3.55-1.65 8.25-3.5 15.35-5.15 10.25-2.35 22.45-2.45h.95q1.4 0 2.9.05.55.05 1.05.05z"
                    id="AccHat135__Layer7_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat135__Symbol_477_0_Layer0_0_FILL"
                transform="translate(107.15 29.65)"
                id="AccHat135__col1n"
            />
            <use
                xlinkHref="#AccHat135__Symbol_412_0_Layer0_0_FILL"
                transform="translate(107.85 47.2)"
                id="AccHat135__col1d"
            />
            <use
                xlinkHref="#AccHat135__Symbol_359_0_Layer0_0_FILL"
                transform="translate(94.15 13.05)"
                id="AccHat135__col2n"
            />
            <use
                xlinkHref="#AccHat135__Symbol_330_0_Layer0_0_FILL"
                transform="translate(117.65 32.65)"
                id="AccHat135__col2d"
            />
            <use xlinkHref="#AccHat135__Layer7_0_FILL" />
            <use xlinkHref="#AccHat135__Layer7_1_FILL" />
        </g></g>
    )
}

export default AccHat135
