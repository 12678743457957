import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2n": "#FFC4C4"
}
function BodHan025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.95 11.8q-.45-2.65-2.1-5.2-3.4-5.1-9.2-6.25Q14.85-.9 9.9 2.5 7.65 3.95 6.05 6 3.7 8.45 1.2 12.9q-.15.2-.3.3-.6.9-.8 2-.4 2.1.85 3.95 1.1 1.65 3.25 2.1 1 .2 1.85.1-.4 2.05.8 3.9 1.25 1.8 3.3 2.2 2.05.45 3.9-.7 1-.7 1.5-1.6.2 1.05.75 2.05 1.25 1.8 3.3 2.25 2.15.4 4-.75 1.75-1.25 2.15-3.3.85.45 1.85.65 2.05.4 3.9-.7 1.8-1.2 2.25-3.25.2-1.1-.05-2.2-.05-.15-.1-.4-.55-4.5-1.65-7.7m142.1-5.6q-1.6-2.05-3.85-3.5-4.95-3.4-10.75-2.15-5.8 1.15-9.2 6.25-1.65 2.55-2.1 5.2-1.1 3.2-1.65 7.7-.05.25-.1.4-.25 1.1-.05 2.2.45 2.05 2.25 3.25 1.85 1.1 3.9.7 1-.2 1.85-.65.4 2.05 2.15 3.3 1.85 1.15 4 .75 2.05-.45 3.3-2.25.55-1 .75-2.05.5.9 1.5 1.6 1.85 1.15 3.9.7 2.05-.4 3.3-2.2 1.2-1.85.8-3.9.85.1 1.85-.1 2.15-.45 3.25-2.1 1.25-1.85.85-3.95-.2-1.1-.8-2-.15-.1-.3-.3-2.5-4.45-4.85-6.9z"
                    id="BodHan025__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.6 19.15q.1.2.35.4.6.6 1.45.8 1.25.2 2.2-.4 1-.65 1.2-1.75.25-1.2-.45-2.25-.6-.95-1.85-1.2-1.15-.25-2.1.4-1 .65-1.25 1.9-.2 1.1.45 2.1M5.3 9.4q-.6-.95-1.8-1.25-1.2-.25-2.1.45-1.05.6-1.35 1.85-.2 1.1.55 2.15.1.15.3.35.65.6 1.5.75 1.2.25 2.1-.3 1-.7 1.2-1.8.3-1.2-.4-2.2m15.9 10.7q.25-1.2-.45-2.2-.6-.95-1.85-1.25-1.15-.25-2.1.4-1 .65-1.25 1.9-.2 1.1.45 2.1.1.2.35.4.6.65 1.45.8 1.25.3 2.2-.35 1-.7 1.2-1.8m6.75-1.95q1-.65 1.2-1.75.3-1.2-.4-2.25-.65-.95-1.85-1.2-1.15-.25-2.1.4-1 .7-1.3 1.9-.2 1.1.5 2.1.1.2.3.45.65.5 1.5.7 1.2.25 2.15-.35M26.05 5.6Q24.75 2 20.5 2.15q-.35.05-.7.15-3.5.6-5.65-1.3-.4-.35-1-.75-4.8-1-6.55 2.35-.35.8-.55 1.45Q5.35 6.1 7.5 7.7q2 1.5 3.1 3.75v.05q3.6 5.25 8.6 1.75.1.05.1 0 1.8-1.65 4.25-2.3 2.55-.55 2.7-2.75.2-1.25-.2-2.6M156.7 16.85q-1.25.3-1.85 1.25-.7 1-.45 2.2.2 1.1 1.2 1.8.95.65 2.2.35.85-.15 1.45-.8.25-.2.35-.4.65-1 .45-2.1-.25-1.25-1.25-1.9-.95-.65-2.1-.4m-8-3.7q-1.2.25-1.85 1.2-.7 1.05-.4 2.25.2 1.1 1.2 1.75.95.6 2.15.35.85-.2 1.5-.7.2-.25.3-.45.7-1 .5-2.1-.3-1.2-1.3-1.9-.95-.65-2.1-.4m20.75 4.1q-.25-1.25-1.25-1.9-.95-.65-2.1-.4-1.25.25-1.85 1.2-.7 1.05-.45 2.25.2 1.1 1.2 1.75.95.6 2.2.4.85-.2 1.45-.8.25-.2.35-.4.65-1 .45-2.1M169 2.8q-1.75-3.35-6.55-2.35-.6.4-1 .75-2.15 1.9-5.65 1.3-.35-.1-.7-.15-4.25-.15-5.55 3.45-.4 1.35-.2 2.6.15 2.2 2.7 2.75 2.45.65 4.25 2.3 0 .05.1 0 5 3.5 8.6-1.75v-.05q1.1-2.25 3.1-3.75 2.15-1.6 1.45-3.65-.2-.65-.55-1.45m6.55 7.85q-.3-1.25-1.35-1.85-.9-.7-2.1-.45-1.2.3-1.8 1.25-.7 1-.4 2.2.2 1.1 1.2 1.8.9.55 2.1.3.85-.15 1.5-.75.2-.2.3-.35.75-1.05.55-2.15z"
                    id="BodHan025__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill="#434343"
                    d="M280.7 289.95q1.1 1 1.8 2.45 2.85-4.15 3.85-8.3l.05-.1q-4.6 4.65-6.85 5.15.55.3 1 .7.1 0 .15.1m-7.9-7.2q1.45.35 2.65 1.55 1.1-4.7.15-9.25-2.05 6.55-4.85 7.45 1.1-.05 2.05.25m-13.35-1.8q1.6-.7 3.05-.45 1 .05 2 .5-2.25-1.85-1.65-8.7-2.65 3.9-3.4 8.65m-5.6 2.95q-1.9-1.35-4.4-7.2-.65 4.2.45 9.1 1.2-1.1 2.6-1.6.05-.05.15 0 .55-.25 1.2-.3z"
                    id="BodHan025__Layer1_0_FILL"
                />
                <path
                    fill="#434343"
                    d="M135.2 285.8q1.1-4.9.45-9.1-2.5 5.85-4.4 7.2.65.05 1.2.3.1-.05.15 0 1.4.5 2.6 1.6m-12.6-5.3q1.45-.25 3.05.45-.75-4.75-3.4-8.65.6 6.85-1.65 8.7 1-.45 2-.5m-10.3 2.25q.95-.3 2.05-.25-2.8-.9-4.85-7.45-.95 4.55.15 9.25 1.2-1.2 2.65-1.55M98.7 284l.05.1q1 4.15 3.85 8.3.7-1.45 1.8-2.45.05-.1.15-.1.45-.4 1-.7-2.25-.5-6.85-5.15z"
                    id="BodHan025__Layer1_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan025__Symbol_50_0_Layer0_0_FILL"
                transform="rotate(180 141.275 154.95)"
                id="BodHan025__col1n"
            />
            <use
                xlinkHref="#BodHan025__Symbol_51_0_Layer0_0_FILL"
                transform="rotate(180 140.15 152.425)"
                id="BodHan025__col2n"
            />
            <use xlinkHref="#BodHan025__Layer1_0_FILL" />
            <use xlinkHref="#BodHan025__Layer1_1_FILL" />
        </g></g>
    )
}
export default BodHan025
