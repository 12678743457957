import { AssetSvgProps } from "../../../shared/assets"

function ObjMor010({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjMor010__Layer5_1_FILL">
                    <path
                        fill="#4D4D4D"
                        d="M219.4 291.3q1.3 2.75 3.5 5.2 2.3 2.6 5.6 4.85.35.25.75.5 3.8 2.5 6.6 1.45l12.2-6.9-15.45-15.55-12.65 5.05q-2.2 1-.6 5.35.05 0 .05.05m50.8-22.95q-2.3-1.05-4.05-.85l-6.4 2.55 9.25 7.85.2.2.65.45 3.85 3.35 6.1-3.35q.5-1.5-1-3.45-.1-.15-.25-.35-1.2-1.5-2.75-2.8-1.2-1-2.6-1.9-.8-.55-1.55-.95-.75-.45-1.45-.75z"
                    />
                    <path d="M237.9 316.1l-3.1 7.85 22.9-15.8q-.2-.2-.4-.45-.45-.55-.8-1.5-.15-.35-.2-.65-.25-.7-.3-1.3l-18.1 11.85m39.6-24.6q1.2.3 1.95.9.25.15.45.4l5.6-3.85 4-6.7-13.65 9q.5 0 .95.1.35.05.7.15z" />
                    <path
                        fill="#333"
                        d="M211.35 286.45l-5.55-8.65-1.65.55-1.25 7.75 31.9 37.85 3.1-7.85-26.55-29.65z"
                    />
                    <path
                        fill="#FFF"
                        d="M250.4 295.1l21.55-12.2-14.1-12.1-23 9.15 15.55 15.15z"
                    />
                </g>
                <g id="ObjMor010__Layer5_2_FILL">
                    <path
                        fill="#FFF"
                        d="M257.3 307.7q-.45-.55-.8-1.5-.15-.35-.2-.65L243 314.3l-1.35 4.25 15.65-10.85m20.2-16.2q1.2.3 1.95.9l5-3.5 1.65-3.1-8.6 5.7z"
                    />
                    <path
                        fill="#CCC"
                        d="M238.15 286.4H238q-1.95.05-2.85 1-.95 1.05-.65 2.55.1.55.35 1 .45.85 1.35 1.55 1.4 1.1 3.05 1.1 1.7.05 2.65-1 .45-.45.75-1.5.05-.05.05-.1l-4.55-4.6m30.85-8.55q.1-.3.1-.55v-.1q.05-1-.75-1.85-.05-.1-.15-.15l-.5-.4q-.8-.55-1.85-.65-.65-.1-1.15 0h-.1l4.4 3.75v-.05m.1-3.7q-.05 0-.05-.05l-.2-.2q-1.35-1.1-3.1-1.3-1.45-.15-2.6.35l1 .85h.1q.75-.2 1.55-.1.7.1 1.25.35.55.2 1.05.6.15.1.3.25 1.05 1 1.05 2.25 0 .4-.2.9 0 .05-.05.05l.65.45q.5-.7.5-1.6.05-1.55-1.25-2.8m-25.35 17.9l-.75-.75q-.4 1.15-.9 1.65-1.05 1.15-2.9 1.1-1.85 0-3.4-1.2-.95-.75-1.45-1.65-.3-.55-.45-1.2-.3-1.65.75-2.8 1-1.05 3.15-1.1l-.9-.9q-2.25 0-3.6 1.4-1.35 1.45-.95 3.55.2.9.65 1.7.6 1.05 1.75 1.95 1.95 1.5 4.35 1.55.55 0 1.1-.05 1.55-.3 2.6-1.35v-.05q.75-.8.95-1.85z"
                    />
                    <path d="M249.45 291.45q.05.1.15.1.1.05.15 0l17.9-9.65q.1-.05.1-.15.05-.1 0-.15-.05-.1-.15-.1-.05-.05-.15 0l-17.9 9.65q-.1.05-.1.15-.05.05 0 .15m-2.45-2.6q-.05.1 0 .15.05.1.15.1.05.05.15 0l18.25-9.25q.1 0 .1-.1.05-.1 0-.15-.05-.1-.15-.15-.05 0-.15.05l-18.25 9.25q-.1 0-.1.1m-2.5-2.3l.1.1h.2l18.5-8.95q.05 0 .1-.1t0-.15q-.05-.1-.15-.1-.05-.05-.15 0l-18.5 8.9q-.1.05-.1.1-.05.1 0 .15v.05m14.6-13.2q-.1-.05-.15 0l-19.15 8.15-.1.1q-.05.1 0 .2l.1.1h.2l19.1-8.15q.1-.05.1-.15.05-.05 0-.15 0-.1-.1-.1m2.05 2.1q.05-.1 0-.15-.05-.1-.15-.1-.05-.05-.15 0l-18.75 8.55q-.1.05-.1.15-.05.05 0 .15.05.1.1.1.1.05.2 0l18.75-8.55q.05-.05.1-.15m5.95-10.35l.95-.35-5.95-4.85-57.95 18.45 6.35 7.1-.4 3.15-5.75-.8.95 1.15 5.55.9.5-3.4 1.6-.65-3.9-4.7 54.7-18.75 3.35 2.75z" />
                </g>
                <path
                    fill="#171717"
                    d="M259.75 296.45q3.4-2.85 8.55-4.3 4.25-1.25 7.55-.9.5 0 .95.1.35.05.7.15 1.2.3 1.95.9.25.15.45.4l5.6-3.85 4-6.7-27.4-22.35-57.95 18.45-1.25 7.75 31.9 37.85 22.9-15.8q-.2-.2-.4-.45-.45-.55-.8-1.5-.15-.35-.2-.65-.25-.7-.3-1.3-.1-.4-.1-.8 0-3.8 3.85-7z"
                    id="ObjMor010__Layer5_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor010__Layer5_0_FILL" />
            <use xlinkHref="#ObjMor010__Layer5_1_FILL" />
            <use xlinkHref="#ObjMor010__Layer5_2_FILL" />
        </g></g>
    )
}

export default ObjMor010
