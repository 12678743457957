import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou069({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M207.6 205.3q-1.05-.1-2.1-.15-6.05-.35-12.4-.4H192q-6.35.05-12.4.4-1.05.05-2.1.15-4.8.4-8.1-.75-.4-.15-.75-.3-1-.3-1.95-.6-5.75-1.95-9.95-4.75-.7-.45-1.35-.95.15.5.25.95 5.45 18.85 10.1 29.5.4.85 1.85 4.9.8 2.2 1.65 3.7.75 1.25 1.5 2.05 1.65 1.75 3.3 2.55 1.65.85 8.8-.65 2.823-.614 9.3-.55l.15.05h.65q6.5-.1 9.3.5 7.15 1.5 8.8.65 1.65-.8 3.3-2.55.75-.8 1.5-2.05.85-1.5 1.65-3.7 1.45-4.05 1.85-4.9 4.65-10.65 10.1-29.5.1-.45.25-.95-.65.5-1.35.95-4.2 2.8-9.95 4.75-.95.3-1.95.6-.35.15-.75.3-3.3 1.15-8.1.75z"
                    id="FacMou069__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M13 4.9q2.05 10 7.55 19.95-.6-10.15 1.9-20.15-6.15.7-9.45.2m11.55 30.8v.05q3.45-.8 6.5-.3-2.25-4.7-2.7-9.45-3.2 4.85-3.8 9.7m-15.9-9.45q.5 1.9 1.1 3.55 1.25 3.2 3.1 5.5Q11.2 27.65 11.4 20q-2.35 2.95-2.75 6.25m6.1 10.2q.85.5 4-.15 1.5-.2 3.6-.35Q18 29.2 16.95 21.5q-2.75 6.6-2.2 14.95M0 0q3 8.05 8.6 13.8-.2-4.95 2.15-9.2-.5-.15-1-.25Q4.05 2.7 0 0m31.45 4.9v-.05l.15-.35q-3.45-1-7.65 0 1.2 5.2 4.7 9.6.85-5.15 2.8-9.2m2.9 0q1.95 4.05 2.8 9.2 3.5-4.4 4.7-9.6-4.2-1-7.65 0l.15.35v.05m9.1 31.05q2.1.15 3.6.35 3.15.65 4 .15.55-8.35-2.2-14.95-1.05 7.7-5.4 14.45m-2.2-.2v-.05q-.6-4.85-3.8-9.7-.45 4.75-2.7 9.45 3.05-.5 6.5.3m11.7-.45q1.85-2.3 3.1-5.5.6-1.65 1.1-3.55-.4-3.3-2.75-6.25.2 7.65-1.45 15.3M52.8 4.9q-3.3.5-9.45-.2 2.5 10 1.9 20.15 5.5-9.95 7.55-19.95m2.25-.3q2.35 4.25 2.15 9.2Q62.8 8.05 65.8 0q-4.05 2.7-9.75 4.35-.5.1-1 .25z"
                    id="FacMou069__Symbol_94_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou069__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou069__Symbol_94_0_Layer0_0_FILL"
                transform="translate(159.65 203.1)"
                id="FacMou069__col1n"
            />
        </g></g>
    )
}

export default FacMou069
