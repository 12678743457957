import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#794D32"
}

function ObjMor029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.35 3.2q-.9-.65-1.85-1.15-3.2-1.7-7-2.05L2.7 3.95q-1.4.1-2.7.4L18.35 19.3l4.35 14.15q.15.25.2.6V64.2l32.4-5.35v-35.9q.55-8.8-3.75-15.8-.3-.35-.55-.65-.45-.55-1-1.05-1.2-1.25-2.65-2.25z"
                    id="ObjMor029__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M70 26.05V24.9l-32.4 4.2.05-10.95q-.8-6.2-5.4-10.75-1.4-1.45-3-2.5l32.8-3.75Q61.15.5 60.2 0l-33 3.75q-3.8-1.9-8.3-1.9-.8 0-1.5.05-6.85.45-11.9 5.5Q0 12.9 0 20.7q0 7.8 5.5 13.35 5.35 5.35 12.8 5.55-.15-.2-.25-.55-.05-.2-.05-1.15 0-3.1 2.2-5.8 3.25-4 10.1-4 .5 0 1 .05.25-.05.5-.05h.65q1.75 0 3.3 1.25l.05.05q.95.8 1.2 1.45.581.533.6 1.3V30.5L70 26.05z"
                    id="ObjMor029__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.05 6q.95.8 1.2 1.45.581.533.6 1.3V0Q1.45 3.2 0 5.95L.05 6z"
                    id="ObjMor029__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M12.9 12.85q2.2-2.15 2.2-5.3 0-3.1-2.2-5.35Q10.7 0 7.55 0 4.4 0 2.2 2.2 0 4.45 0 7.55q0 3.15 2.2 5.3 2.2 2.25 5.35 2.25 3.15 0 5.35-2.25z"
                    id="ObjMor029__Symbol_38_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor029__Symbol_137_0_Layer0_0_FILL"
                transform="translate(257 262.3)"
                id="ObjMor029__col1n"
            />
            <use
                xlinkHref="#ObjMor029__Symbol_76_0_Layer0_0_FILL"
                transform="translate(242.3 264.35)"
                id="ObjMor029__col1d"
            />
            <use
                xlinkHref="#ObjMor029__Symbol_58_0_Layer0_0_FILL"
                transform="translate(278.05 287.75)"
                id="ObjMor029__col1s"
            />
            <use
                xlinkHref="#ObjMor029__Symbol_38_0_Layer0_0_FILL"
                transform="translate(253.35 277.65)"
                id="ObjMor029__col2n"
            />
        </g></g>
    )
}

export default ObjMor029
