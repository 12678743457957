import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF98D0"
}

function FacNos007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.75 2.85q-3.4 4.4 8.85 13.7 11.85-8.3 8.65-13.7-4.5-5.5-8.9 0-3.95-5.7-8.6 0z"
                    id="FacNos007__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos007__Symbol_16_0_Layer0_0_FILL"
                transform="translate(183.05 194.85)"
                id="FacNos007__col1n"
            />
        </g></g>
    )
}

export default FacNos007
