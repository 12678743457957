import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#333333",
    "col1n": "#000000",
    "col1s": "#4F4F4F"
}

function AccHat031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M127.65 34.35l-.2-.8q-2.2-8.95-10.7-17.05Q104.3 4.7 85.15 1.15q-13.45-2.3-27.45 0-7.05 1.5-13.4 4.7-8.15 4.1-12.85 9.8-1.4 1.65-2.45 3.45-2.3 3.75-3.25 8.15l-.1.4q-6 .2-11.15 1.95-9.45 3.2-12.75 10.05-.7 1.45-1.15 3.1-1.25 4.65.2 9.35Q12.75 80 63.3 90.95q1.1.2 2.25.4 2.25.4 4.45.75 9 1.4 17.4 1.6 41.25-1.35 54-18.3 3.75-5.45 2.7-11.2-.6-3.3-2.7-6.7-1.2-1.95-2.9-3.85-3.85-4.35-10.3-7.85h-.05l-.1-.1q-.1-.05-.2-.15h-.2q-.05 0-.05-.05.2-1 .25-1.1v-.25q.8-5.05-.2-9.8z"
                    id="AccHat031__Symbol_249_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M141.4 35.75q3.75-5.45 2.7-11.2-2.9 14.4-25.15 21.2-22.7 6.8-52.2 1.6Q37.25 42.2 18.25 28 0 14.3 1.75 0 1.05 1.45.6 3.1q-1.25 4.65.2 9.35 11.95 27.9 62.5 38.85 1.1.2 2.25.4 2.25.4 4.45.75 9 1.4 17.4 1.6 41.25-1.35 54-18.3z"
                    id="AccHat031__Symbol_210_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M126.05 34.35l-.2-.8q-2.2-8.95-10.7-17.05Q102.7 4.7 83.55 1.15q-13.45-2.3-27.45 0-7.05 1.5-13.4 4.7-8.15 4.1-12.85 9.8-1.4 1.65-2.45 3.45-2.3 3.75-3.25 8.15l-.1.4q-6 .2-11.15 1.95Q3.45 32.8.15 39.65q-.55 4.5.9 9Q16.9 41 30.5 50.15q-2.95-8.85-.8-18.3.4-1.7.9-3.25Q34.9 15.1 49 7.75q15.7-8.2 34.35-4 18.6 4.2 29.25 18.35 7 9.3 7.9 19.25.5 5.3-.75 10.8-1.3 5.7-4.15 10.45 13.1.55 19.9 11.05.4.8.8 1.7 4.95-4.85 6.2-11.15-.6-3.3-2.7-6.7-1.2-1.95-2.9-3.85-3.85-4.35-10.3-7.85h-.05l-.1-.1q-.1-.05-.2-.15h-.2q-.05 0-.05-.05.2-1 .25-1.1v-.25q.8-5.05-.2-9.8z"
                    id="AccHat031__Symbol_160_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M103.5 18.5q.05-.05.15 0-.1.25-.15.5v-.5l-1 3.9q-2.7 7.2-9.25 11.5-3.05 2-6.95 3.4-2.95 1.05-6.1 1.8l-6.5 1.1q-8.35.95-18.2.1-.65-.1-1.25-.15l-2.55-.3q-.35-.05-.6-.05h-.05q-.45-.1-.9-.15-4.75-.7-9.2-1.7Q25.55 34.45 14 26.7 1.6 18.4 1.15 6.65q-.05-1.35.05-2.7 0-.4.05-.8L1.75 0l-.1.4-1.3 3.85V4.1q-2.4 14.95 12.8 25.8 10.8 7.75 25 11.4 4.75 1.2 9.85 2l2.3.3q4.1.5 8 .75 7.95.4 14.85-.5 7.75-1 14.25-3.65l2.2-1q4.2-2.1 7.25-5.05 5.75-5.6 7.3-14.25.1-.45.15-.85 0-.05.05-.1l-.15-.4h-.05l-.1-.1q-.1-.05-.2-.15h-.2q-.05 0-.05-.05-.05.1-.05.2-.05 0-.05.05z"
                    id="AccHat031__Symbol_121_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat031__Symbol_249_0_Layer0_0_FILL"
                transform="translate(132.35 23.65)"
                id="AccHat031__col1n"
            />
            <use
                xlinkHref="#AccHat031__Symbol_210_0_Layer0_0_FILL"
                transform="translate(132.35 63.3)"
                id="AccHat031__col1l"
            />
            <use
                xlinkHref="#AccHat031__Symbol_160_0_Layer0_0_FILL"
                transform="translate(133.95 23.65)"
                id="AccHat031__col1d"
            />
            <use
                xlinkHref="#AccHat031__Symbol_121_0_Layer0_0_FILL"
                transform="translate(156.35 50.9)"
                id="AccHat031__col1s"
            />
        </g></g>
    )
}

export default AccHat031
