import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666"
}
function SkiMor034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M153 28.8q-.1-.5-.2-1.05-.2-1-.45-2-5.65-30.25-35.3-24.4-2.3.65-4.75 1.45-21.85 7.95-30.35 8.95-1.2.15-4.65.15H77q-3.45 0-4.65-.15-8.5-1-30.3-8.95-2.4-1-4.6-1.75-27.15-6.3-35.5 24.7-.05.3-.15.6v.15q-.15.6-.3 1.25Q-.75 38.6.4 48.7q1.95 29.6 26.4 42.65.25.05.5.15 3.3 1.2 6.95 1.75-2.7 4.95-3.65 9.65-.65 3.1-.5 6.05-.25 18.95 17.6 26.5 2.3.95 4.55 1.75 7.1 2.45 13.65 3.3 11.25 1.3 22.5 0 6.55-.85 13.65-3.3 2.25-.8 4.55-1.75 17.85-7.55 17.6-26.5.15-2.95-.5-6.05-.95-4.7-3.65-9.65 1.95-.3 3.75-.75 23.5-9.95 29.9-41.8 1.5-10.55-.7-21.9z"
                    id="SkiMor034__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M122.75 48.1q8.75-21.9-.9-38.55-1.25-2.15-2.8-4.2-3.42-3.143-7.55-4.45-4.34-1.338-9.5-.7l-1.05.2q-.985.16-2 .4l-.85.25h-.05L69.85 8.4q-6.15 1.45-12.3 0l-28.7-7.35h-.05L27.95.8q-1.232-.291-1.9-.4-.393-.059-.6-.05-.127.025-.1.1Q22.216.005 21.7 0q-3.275-.011-6.2.85-.539.2-1.1.4-.068.043-.15.05-3.465 1.353-6.4 4.05-13.6 18.05-3.7 42.75 4.4 8.6 13.5 13.15 11.6 5.75 24 1.45 12.3-4.25 18.2-16.1 2.15-4.35 3-8.75.2-.95.3-1.9.1-.8.15-1.6v-1.4h.2q.1 1.15.1 1.3.05.85.15 1.7.1.95.3 1.9.85 4.4 3 8.75 5.9 11.85 18.2 16.1 12.4 4.3 24-1.45 8.2-4.1 12.6-11.5.45-.8.9-1.65z"
                    id="SkiMor034__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor034__Symbol_39_0_Layer0_0_FILL"
                transform="translate(115.35 108.45)"
                id="SkiMor034__col1n"
            />
            <use
                xlinkHref="#SkiMor034__Symbol_40_0_Layer0_0_FILL"
                transform="translate(129.05 131.05)"
                id="SkiMor034__col1d"
            />
        </g></g>
    )
}
export default SkiMor034
