import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#C66450",
    "col1n": "#954A3C",
    "col2n": "#FFFFFF"
}

function ObjToy005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M82.55 38q1.15-1.4 1.8-2.9.6-1.5.7-3.1H85q0-.1-.3-1.2-3.55-9.3-11.55-16.6v-.05q-1.6-1.55-3.25-2.95Q51.95-1.75 31.9.3 15 1.9 4.65 12.75 2.35 15.45.5 18.7q-.25.65-.45 1.15H0q0 .05.05.1V20q2.8 11.95 14.05 20.95 3.65 2.6 7.85 4.4Q28 48 35 48.95q3.05.4 6.3.5 21.75 1.15 34.5-6.3h.05l-.1-.1q.95-.5 1.85-1.05 3.15-1.85 4.95-4z"
                    id="ObjToy005__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M84.95 32q0-.1-.3-1.2-3.55-9.3-11.55-16.6v-.05q-1.6-1.55-3.25-2.95Q51.9-1.75 31.85.3 14.95 1.9 4.6 12.75 2.3 15.45.45 18.7q-.25.65-.45 1.15 4.75-1.7 9.6-2.75 28.35-5.15 55.9 5.4 9.6 3.8 19.45 9.5z"
                    id="ObjToy005__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M45.2 4.55q-.45-.25-.9-.15t-.7.5q-1.45 2.2-2.05 4.75-.05.05-.05.1l-.4-.1q-1.8-.45-3.55-.85.4-2.45 1.75-4.5.25-.45.2-.9-.15-.45-.5-.7-.45-.3-.9-.15-.45.05-.75.5-1.6 2.45-2.1 5.25-1.75-.35-3.45-.6l-.5-.1v-.05q.4-2.4 1.8-4.5.3-.45.2-.9-.1-.45-.5-.75-.4-.25-.85-.15-.5.1-.75.5-1.65 2.55-2.15 5.4-.05.1-.05.15l-1.6-.2q-1.15-.15-2.3-.25.45-2.5 1.85-4.6.25-.4.2-.85-.1-.5-.5-.75-.45-.25-.9-.15-.45.05-.7.5-1.75 2.6-2.25 5.65l-3.8-.2q.4-2.4 1.8-4.45.25-.45.15-.9t-.5-.7q-.4-.3-.85-.15-.45.05-.75.5-1.75 2.6-2.2 5.7H15.5q-1.35 0-2.75.05.45-2.55 1.85-4.65.3-.45.2-.9-.1-.45-.5-.7-.4-.3-.9-.2-.45.1-.7.5-1.8 2.75-2.25 6.1Q9 6.7 7.6 6.8l-1.3.1q.45-2.35 1.75-4.3.3-.45.2-.9-.1-.45-.5-.7-.4-.3-.9-.2-.45.1-.7.5-1.75 2.65-2.2 5.9-1.35.1-2.65.3-.2 0-.35.05-.15 0-.3.1-.3.05-.45.3-.25.4-.2.9Q0 8.9.05 9q.05.35.4.6.35.25.85.2 1.4-.2 2.8-.35 0 2.3.7 4.75.1.5.55.7.4.25.85.1.5-.1.7-.5.25-.45.1-.9-.65-2.3-.6-4.35 1.9-.2 3.9-.3 0 2.3.65 4.9.15.5.55.7.45.25.9.15.45-.15.7-.55.2-.45.1-.85-.6-2.4-.6-4.5 1.95-.05 3.85-.05-.05 2.5.7 5.25.1.5.55.7.4.25.85.15.45-.15.7-.55.25-.45.1-.85-.65-2.45-.6-4.7 1.9.1 3.85.2 0 2.55.7 5.4.1.45.55.65.4.25.9.15.45-.15.65-.55.25-.4.15-.85-.65-2.45-.65-4.6 1.95.2 3.9.45-.05 2.6.7 5.5.1.45.5.7.45.2.9.1t.7-.55q.2-.4.1-.85-.6-2.4-.6-4.55 1.95.3 3.9.65-.15 2.7.65 5.75.1.45.55.7.4.25.85.1.5-.1.7-.5.25-.45.1-.85-.6-2.5-.55-4.7 1.95.45 3.9.95-.2 2.9.65 6.2.1.45.55.7.4.2.9.1.45-.1.65-.55.25-.4.15-.85-.7-2.65-.65-4.95.35.1.65.2h.05q1.1.3 2.15.7.45.2.85 0 .4-.25.6-.65l.1-.2q.05-.35-.1-.7-.2-.4-.65-.6-1.15-.4-2.25-.7l-1.1-.4q.45-2.25 1.75-4.2.25-.4.2-.85-.1-.45-.5-.75z"
                    id="ObjToy005__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy005__Symbol_99_0_Layer0_0_FILL"
                transform="translate(53.4 327.65)"
                id="ObjToy005__col1n"
            />
            <use
                xlinkHref="#ObjToy005__Symbol_86_0_Layer0_0_FILL"
                transform="translate(53.45 327.65)"
                id="ObjToy005__col1l"
            />
            <use
                xlinkHref="#ObjToy005__Symbol_20_0_Layer0_0_FILL"
                transform="translate(65.25 335.25)"
                id="ObjToy005__col2n"
            />
        </g></g>
    )
}

export default ObjToy005
