import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF98D0"
}

function SkiMak012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M108 34.45q1.15-10.35-2.65-22.5Q101.55-.25 83.9 0 66.2.2 60.05 7.45q-5.6 6.55-5.8 16.05-.1.7-.2 1.45-.05-.4-.1-.75 0-9.95-5.8-16.75Q42 .2 24.35 0 6.65-.25 2.95 11.95-.75 24.1.2 34.75q.2 2.75.5 5.2 1 8.5 6.65 14.5 7.25 7.8 18.25 6.95 11.05-.8 19.4-9.8 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 9 19.4 9.8 11 .85 18.25-6.95 5.7-6.15 6.7-14.7l.6-5.3z"
                    id="SkiMak012__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak012__Symbol_18_0_Layer0_0_FILL"
                transform="translate(138.4 131.45)"
                id="SkiMak012__col1n"
            />
        </g></g>
    )
}

export default SkiMak012
