import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#BE0101",
    "col1n": "#FF0000",
    "col2d": "#FFC4C4",
    "col2n": "#FFE9E9"
}

function AccHat132({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M175.7 45.75q-.2.05-.4.05h-.25q-31.3 2.4-62.6 2.9V6.75h.1l-2.6-6.6V.4h-24V0q-.05 0-.1.05l-1.4 6.2V48.7q-32.7-.45-65.4-3.05l-5.1-.3-.3.25-8.5 21.85Q.95 75.7 0 82.75-.3 89.2 13.5 78q.05-.1.15-.15.95-1.1 1.9-2.05 6.55-4.95 29.95-4 7.05.55 14.05.95 39.9 2.25 79.8-.15 2.6-.15 5.25-.35 25.5-1.25 33.75 6.1l.15-.2q.25.35.55.75 13 7.95 14.3 6.4 1.35-1.55 1.1-5.6-.25-3.6-3.65-15.75.65 2.85.55 3.3l-.45-2.55q-.05-.1-.05-.2l.05.05-.5-2.8-7.35-16.6h-.1q-.1 0-.15.05l-6.95.6h-.15v-.05z"
                    id="AccHat132__Symbol_475_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M52.2 10.85q8.9-3.2 18.3-4.6l1.4-6.2q-.3 0-.55.05l-1.9.3Q19.8 8.4.3 44.8l-.1.1v.35H.05l-.05.1 5.1.3q.2-.3.45-.6 8.9-11.5 20.35-19.95 3.3-2.5 6.85-4.7 6.65-4.15 14.1-7.4l5.35-2.15M161.75 45.8h.15l6.95-.6Q146.45 10.25 98.1.4 97.05.2 96.05 0L96 .15l2.6 6.6q43.3 8.75 63.15 39v.05z"
                    id="AccHat132__Symbol_410_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M36.05 13.6q-2.15-4.65-5-7.8Q25.5-.4 16.1.55 15.75.6 14.75.5q-1-.05-3.65-.45-2.7-.4-5.45 1.7-2.7 2.05-4.3 4.75-.15.25-.25.5-.1.05-.1.2H.95q-.05.15-.15.35-.1.2-.2.45-1.05 2.5-.2 9.55.75 7.05 5.2 8.95 4.4 1.85 5.25 7.45.7 4.7-.45 14.35-.1.55-.15 1.15v.1Q10.1 50.6 10 51.7q-.05.25-.05.45v9.1q.65 3.4 4.5 5.25 6.65 2.85 13.35 0 .4-.1.8-.25 4.05-1.6 5.15-4.4l.2-9.7H34q-.15-.4 0-2.7.05-.5.1-1.15.1-1.2.25-2.75.55-5.65-1.95-18.9-.542-.05-1-.05.21-.003.5-.1.2-.05.45-.1-.05-.2-.05-.35Q40.4 23 36.05 13.6M9.5 11.8l-.1.05q-.05-.1-.05-.15.05.05.15.1z"
                    id="AccHat132__Symbol_358_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M22.75 14.3q.05-.1.1-.15 0-.2-.05-.35Q14 12.05 8.65 7.65 4.2 4 .1 0L0 .05q1.6 5.55 5.4 8.6.75.85 1.75 1.55l.4.3q5.8 3.95 14 4.3H22q.2 0 .5-.1.2-.05.45-.1-.05-.2-.05-.35-.1 0-.15.05z"
                    id="AccHat132__Symbol_329_0_Layer0_0_FILL"
                />
                <path
                    fill="#BFBFBF"
                    d="M112.85 112.35q0-3.25-2.3-5.55-.55-.55-1.1-.9-1.1-.3-2.25-.25l-5.35 13.75q1.45.7 3.25.7 3.2 0 5.45-2.3 2.3-2.25 2.3-5.45m159.65 6.7q2 2 4.65 2.25h.95q.15 0 1.35-.05 2.4-.15 5.35-1.75l-6-13.55q-4-.15-6.3 2.1-2.25 2.35-2.25 5.55 0 3.2 2.25 5.45m-79.15-9.95q-3.55 0-6.05 2.5-2.45 2.5-2.45 6.05v.35q.15 3.25 2.45 5.6 2.25 2.25 5.3 2.5.35 0 .75.05 3.5-.05 6-2.55 2.5-2.45 2.5-5.95 0-3.55-2.5-6.05t-6-2.5z"
                    id="AccHat132__Layer7_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat132__Symbol_475_0_Layer0_0_FILL"
                transform="translate(94.4 57.75)"
                id="AccHat132__col1n"
            />
            <use
                xlinkHref="#AccHat132__Symbol_410_0_Layer0_0_FILL"
                transform="translate(108.35 57.75)"
                id="AccHat132__col1d"
            />
            <use
                xlinkHref="#AccHat132__Symbol_358_0_Layer0_0_FILL"
                transform="translate(170.4 5.6)"
                id="AccHat132__col2n"
            />
            <use
                xlinkHref="#AccHat132__Symbol_329_0_Layer0_0_FILL"
                transform="translate(179.8 17.4)"
                id="AccHat132__col2d"
            />
            <use xlinkHref="#AccHat132__Layer7_0_FILL" />
        </g></g>
    )
}

export default AccHat132
