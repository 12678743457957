import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M166.35 30.4q-1.1-1.15-2.2-2.25l-.75-.75-1.9-1.8q-15.071-14.082-33.4-20.45-1.11-.387-2.25-.75-.226-.059-.5-.15-5.346-1.403-5.45-1.5-6.15-1.5-12.6-2.15L97.05 0H95.15l-10.2.6q-.75.05-1.45.15-2.45.55-5.4 1.1-3.55.65-5.95 1.1Q71.5 3 71 3.1 48.6 8.9 30.75 25.6q-.35.3-.65.6l-.9.9q-.2.15-.35.3v.05q-.4.35-.75.7-1.1 1.1-2.2 2.25Q15.7 41.2 9.5 53.7q-.55 1.1-1.1 2.25Q4.8 63.6 2.75 72.5q-.55 2.5-1 5.1l-.7 4.3q-.2 1.5-.35 3-.4 3.4-.55 6.75Q0 94.6 0 97.55l.8 10.85q.3 2.5.75 5l.1.5.5 2.5q.35.15.8.65 7.35 8.65 9.75 16.15 2.45 7.8 2.9 9.1.2.4.65.95.55.55.75.8.65.6 1.6 1.35.1.05.15.1.05.05.15.1.15.1.35.25l-.5-.3 2.05 1.85v-.1l.1.1q-3.4-16.9-4.65-34-.25-3.75-.4-7.5-.5-10.9-.15-21.9.2-2.2.5-4.3.85-6.3 2.45-11.45.7-2.35 1.6-4.45.8-1.95 1.75-3.7 2.7-5 6.2-9 1.75-2.05 3.7-3.8 16.45-15.85 30.9-18.2 3.3-.55 6.5-.4 7.1.35 13.75 4.2 6.55 2.4 13.25 14 6.3-11.6 12.85-14 6.65-3.85 13.8-4.2 3.05-.15 6.2.35Q143.7 31.3 160.3 47.3q1.95 1.75 3.7 3.8 3.5 4 6.2 9 1.25 2.3 2.3 5 2.4 6.3 3.55 14.6.25 2.1.45 4.3.4 11-.1 21.9-.2 3.75-.45 7.5-1.25 17.1-4.65 34l2.05-1.85-.5.3q.25-.2.5-.35.05-.05.15-.1.95-.75 1.6-1.35.2-.25.75-.8.45-.55.65-.95.45-1.3 2.9-9.1 2.4-7.5 9.75-16.15.45-.5.8-.65l.6-3q.6-3.15 1-6.3l.65-10.9q.05-3.3-.15-6.6-.2-2.35-.5-4.7-.15-1.05-.25-2.1l-.9-5.55q-.35-1.75-.75-3.5-1-4.55-2.3-8.8l-3.75-9.55q-6.35-13.4-17.2-24.95l-.05-.05m-146.7 8.9q-.6.75-.95 1.45-.75 1-1.45 2 1.15-2.3 1.95-3.85.35 0 .75-.05-.15.25-.3.45m10.6-11.6q-.25.2-.45.45h-.25q.35-.2.7-.45z"
                    id="HaiSho017__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.75 31.05q2.7-5 6.2-9 1.75-2.05 3.7-3.8Q28.1 2.4 42.55.05 12.45 2.8 0 34.75q.8-1.95 1.75-3.7m148.2 0q1.25 2.3 2.3 5Q141.15 3.55 108.9 0q14.55 2.25 31.15 18.25 1.95 1.75 3.7 3.8 3.5 4 6.2 9z"
                    id="HaiSho017__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M164.75 32.2q-.55-.6-1.05-1.15-1.35-1.45-2.8-2.9Q132.7 0 92.85 0 53 0 24.85 28.15q-1.55 1.55-3 3.15-6.05 6.5-10.6 13.6l-.7 1.1q-.5.8-.95 1.6l-.1.15q-.15.15-.2.35l-.2.3-.45.8q-.3.45-.55 1l-.1.15-.05.15q-.45.75-.85 1.55-.45.8-.85 1.65l-.15.35q-.15.2-.2.4l-.05.05Q2.95 60.3 1 66.9l-.05.05q-.05.15-.1.35Q.4 68.85 0 70.45q.5-1.6 1.05-3.15 6.85-18.95 23.4-34.25 3.85-3.55 7.9-6.6Q58.25 6.9 92.9 6.9q39.3 0 67.3 25.15l1.1 1q5.15 4.75 9.4 9.85 9.35 11.35 14.1 24.45l-.7-2.4-3.75-9.55q-.2-.4-.4-.75-.15-.3-.25-.6l-.65-1.3q-.15-.2-.25-.4l-.05-.2q-.1-.15-.15-.25-.1-.2-.2-.35-.1-.2-.15-.35-.1-.2-.2-.35-.1-.15-.15-.3-5.2-9.6-13.15-18.35z"
                    id="HaiSho017__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho017__Symbol_138_0_Layer0_0_FILL"
                transform="translate(96.35 57.6)"
                id="HaiSho017__col1n"
            />
            <use
                xlinkHref="#HaiSho017__Symbol_67_0_Layer0_0_FILL"
                transform="translate(116.6 86.65)"
                id="HaiSho017__col1d"
            />
            <use
                xlinkHref="#HaiSho017__Symbol_19_0_Layer0_0_FILL"
                transform="translate(99.6 57.6)"
                id="HaiSho017__col1l"
            />
        </g></g>
    )
}

export default HaiSho017
