import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#794D32",
    "col1n": "#AC8268",
    "col1s": "#4D2E1B",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function AccMor023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.65.75l-.4-.1q-.4-.1-.75-.1-.45-.1-.85-.1Q4.55.3 3.7.45.6.75.1 3.65.05 4.2 0 4.8v2.25q.05.15.15.3-.1.35-.15.75v6.6q1.45 1.2 3.7 1.75 1.85.45 4.3.45v-6.1q-.15-.4-.25-.75.1-.05.25-.05-.9-8.7 2.4-8.7L7.65.75M20.6.65q.05-.1.15-.15-4.1-.95-7.6.05-1.4.2-2.3.85-.3.2-.5.45-.2.15-.35.4-.4.55-.65 1.3Q9 4.6 9.05 6v1.75q.05.05.15.1-.1.35-.15.8V22.1q3.75 2.35 8.2.1V11.6q-.05.05-.05.1l-.05-3.05q-.05-.15-.05-.3.5-.4.7-1.55.15-1.9.2-2.25.1-1.2.7-1.95.1-.15 1.05-.85.9-.6.9-.85 0-.15-.05-.25M32.65 2.7q0-.25-.1-.35l.4-.3q-4.1-.95-7.6.05-1.65.2-2.65 1.15-1.5 1.35-1.45 4.3v4.95q.1.2.25.35-.1.35-.25.75v8.15q4.1 2.1 8.2 0v-8.6q0-.3-.05-.6.5-.55.65-2.2v-2.7q.15-2.5 1.4-3.7 1.2-1.15 1.2-1.25m79.1 4.8q0-.95-.1-1.7-.15-.8-.45-1.35-.35-.75-.9-1.25-1-.95-2.65-1.15-3.5-1-7.6-.05 2 2.75 2.7 3.45l.8 7.65v8.6q4.1 2.1 8.2 0v-8.15q-.05.1-.1.25l-.1-1q.1-.2.2-.35V7.5M123 2.2q-.15-.25-.35-.4-.2-.25-.5-.45-.9-.65-2.3-.85-3.5-1-7.6-.05.15.1.3.25-.05.1-.05.3 0 .45.6 1.05.35.3 1 .9.15.15.6 1.1.05.1.1.15l1.05 4.55-.05 2.9q0-.05-.05-.1v10.6q4.45 2.25 8.2-.1V8.6l-.05.05q.05-.4.05-.95V5.95q.05-1.35-.25-2.4l-.05-.05q-.25-.7-.6-1.25-.05 0-.05-.05m9.9 6.25v-1.2q.05-.15.1-.25V4.75q-.05-.6-.1-1.15-.65-3.7-5.55-3.2-.4 0-.85.1-.35 0-.75.1l-.4.1-2.75.55q1 0 1.6.8.4.45.65 1.25.55 1.9.3 5.5l-.1.8q-.05.2-.05.35h.05l-.05 6.9q5.4 0 8-2.2v-6.6l-.1.4z"
                    id="AccMor023__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3 1.8q.15-.25.35-.4.2-.25.5-.45L3.4.85Q-1.85 1 1 9.55q-.15 0-.25.05v.75H1q-1.35 3.05 0 6.1.55.1 1.05.2V8.2q.15.05.25.15v-.9q-.15-.1-.25-.15V5.55q-.05-1.4.3-2.45.25-.75.65-1.3M13.75.05Q12.05 2.1 11 3.35v3.5h-.35q.15.25.4.5l-.75.45-.25.45h.05l-.9 2.3q-.95 2.6 1.05 11.2l4-3.15v-5.45q.1.15.2.35v-1.15l-.2-.3V7.1q-.05-2.95 1.45-4.3Q15.35.75 14.65.5l-.9-.45m82.8 6.9V5.6q-.05-.25-.1-1.15-.1-.95-3.4-2.9l-.9.45q-.95.35-1.2 3.6-.05.1-.05.15h-.1l-.7 1-27.55 1.5-32.9-1.2L28.1 5.8q-.3-3.4-1.25-3.75l-.9-.45q-1.8 1.35-2.7 2.55v3.6h-.6v3.45h-.4v.4h.05v1.3q.1-.1.15-.2-2 4.9 0 8.6l5.7-5.1v-2.9q.4.05.85.1h.1q10.85 1.2 21.5 1.6.8.05 1.55.05h18q9.55-.35 19-1.35l1.7-.2v2.65l5.7 5.1q2.5-4.55 0-8.6l.1.1q.4.5 1 .85v-1.35q-1.2-.85-1.1-5.3m11.9-2.25q-.05-.65-.05-1.4v-.95q-.05-.9-3.15-2.35l-.9.45q-.7.25-1.05 2.3.55.5.9 1.25.3.55.45 1.35.1.75.1 1.7V12q0 .05-.05.1v1.1q.048-.048.05-.1v5.45l4 3.15q2-5.2 1.7-8-.35-2.85-1.6-5.4v-.15q.048 0 .05.05V7q-.4-.7-.45-2.3m3.05 4.45v-.2h-.7l.7.2m5.15-6.1l.05.05q.3 1.05.25 2.4v1.75q-.2.15-.45.25v.9q.25-.1.45-.25v8.45q.5-.1 1.05-.2 1.15-3.15 0-6.1.15 0 .3.05v-.8q-.15-.05-.3-.05 0-.15.45-2.7.4-2.55-.15-3.75-.6-1.2-1.15-1.7Q116.6.8 115.6.8l-.45.1q.3.2.5.45.2.15.35.4 0 .05.05.05.35.55.6 1.25z"
                    id="AccMor023__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1.25 9.25q1.05.6 2.45.85 1.85.35 4.3.25h.05l1-.55v-.85l-1 .6H8q-2.6.2-4.3-.2Q2.4 9 1.6 8.4.2 7.3 0 6.6v1.05q.15.8 1.25 1.6M21.65.5l-.9-.45q-2.4 1.75-3.15 3.2.1 4.2-1.25 4.35-2.4.9-4.4.7-.7-.1-1.3-.25-.85-.3-1.6-.75v.9l.7.4q.45.2.9.35 2.55.9 5.45-.25.5-.2 1.05-.5 1-.5 1.25-1.15.15-.7.05-2.2V4.8q-.1-.7.2-1.35.4-1.05 1.7-2.05.15-.15 1.3-.9m12.2 1.55l-.9-.45q-2.65 1.95-3.3 3.55-.15.25-.2.5V7q.1 4.45-1.1 5.3-.05.05-.15.1-1.3.75-4.4.75-.45 0-.85-.05-1.25-.2-1.7-1.05v1.1q.35.75 1.4 1 .15.05.3.05 1 .15 3 .1 2.3-.1 3.5-1.6l.1-.1q.35-.6.75-2.45.4-1.9.4-3.5-.05-.15-.05-.3-.05-.55.1-1.05.35-1.2 1.8-2.35.15-.15 1.3-.9m64.05 3.7q-.4.05-1.4.2-.55.05-1.05.1-9.85 1-19.7 1.35-.9.05-1.8.05h-15q-.65 0-1.35-.05Q47.25 7.1 36.85 6q-.4-.05-.75-.1l-1-.1q0 .6.05 1.35v.05q.5.05.95.15.4 0 .75.05 10.4 1.1 20.75 1.4.3 0 .6.05h15.45q1.05 0 2.1-.05 9.85-.35 19.7-1.35.75-.1 1.45-.15l-.35.05q.4-.05.45-.05.4-.1.85-.15V7.1q.05-.75.05-1.3v-.05m4.4.9q0 1.55.4 3.45.4 1.85.75 2.45l.1.1.1.1q1.15 1.4 3.4 1.5 2 .05 3-.1.15 0 .3-.05 1.05-.25 1.4-1V12q-.45.85-1.7 1.05-.4.05-.85.05-2.7 0-4-.55l-.4-.2q-.1-.05-.15-.1-1.2-.85-1.1-5.3V5.6q-.05-.25-.2-.5-.65-1.6-3.3-3.55l-.9.45q1.15.75 1.3.9 1.45 1.15 1.8 2.35.15.5.1 1.05 0 .15-.05.3v.05m21.65.6q-.75.45-1.6.75-.6.15-1.3.25-2 .2-4.4-.7-1.1-.1-1.2-2.85-.05-.65-.05-1.4v-.1q-.75-1.45-3.15-3.2l-.9.45q1.15.75 1.3.9 1.3 1 1.7 2.05l.05.05q.25.6.15 1.3v.1q-.1 1.45.05 2.15.25.65 1.25 1.15.55.3 1.05.5 2.9 1.15 5.45.25.45-.15.9-.35l.7-.4v-.9m9.05.35V6.55q-.1.3-.35.65-.15.15-.3.35-.4.35-.95.8-1.9 1.5-6.4 1.15h-.05l-1-.6v.85l1 .55h.05q4.3.25 6.75-1.1 1.1-.8 1.25-1.6z"
                    id="AccMor023__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.3 8.8V0H0v8.8h14.3m-2.2-6.7v4.65H2.6v-1.6h4.05V3.6H2.6V2.1h9.5z"
                    id="AccMor023__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.8 1.55V0H0v1.55h.8z"
                    id="AccMor023__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor023__Symbol_121_0_Layer0_0_FILL"
                transform="translate(126 301.8)"
                id="AccMor023__col1n"
            />
            <use
                xlinkHref="#AccMor023__Symbol_72_0_Layer0_0_FILL"
                transform="translate(133 302.25)"
                id="AccMor023__col1d"
            />
            <use
                xlinkHref="#AccMor023__Symbol_43_0_Layer0_0_FILL"
                transform="translate(126 302.25)"
                id="AccMor023__col1s"
            />
            <use
                xlinkHref="#AccMor023__Symbol_26_0_Layer0_0_FILL"
                transform="translate(185.35 309.2)"
                id="AccMor023__col2n"
            />
            <use
                xlinkHref="#AccMor023__Symbol_6_0_Layer0_0_FILL"
                transform="translate(187.95 312.8)"
                id="AccMor023__col2d"
            />
        </g></g>
    )
}

export default AccMor023
