import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#999999",
    "col2n": "#666666"
}

function AccMor017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.35 8.35v-4q-3-.5-5.95-1L14.45 3Q8.3 1.9 2.15.5 1.1.25 0 0v4q1.1.25 2.15.5Q8.3 5.9 14.45 7q.1 0 .25.05l1.95.35q2.85.5 5.7.95m35.9-.9Q51 7.3 43.7 6.75l-5.05-.4q-2.7-.25-5.4-.55-2.2-.25-4.4-.55l-3.7-.5v4l3.7.5q2.2.3 4.4.55 5.8.65 11.6 1.05 6.7.45 13.4.6l5.4-2-5.4-1.95v-.05m43 1.45l.7-.1v-4l-.7.1-6.4.8-1 .1q-2 .25-4 .45-.4 0-.8.05l-.8.1-2.7.2q-.15 0-.2.05h-.25q-.4 0-.8.05-.2 0-.35.05H83.55q-.1 0-.2.05h-.55q-.3.05-.55.05-.3 0-.6.05-.2 0-.4.05-6.1.35-12.2.5l-5.4 2 5.45 1.95-.05.05q6.1-.15 12.2-.5H81.65l1.1-.1h.45q.05-.05.15-.05h.45q.05-.05.15-.05h.1l1.1-.1h.5l3.4-.3.8-.1 4-.4q.5-.1 1-.15l6.4-.8m25.5-8.8l-1.9.4q-6.8 1.55-13.6 2.8-3.25.55-6.5 1.05v4q3.25-.5 6.5-1.05 6.8-1.25 13.6-2.8l1.9-.4v-4z"
                    id="AccMor017__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.65 5.7q.25-.25.4-.5.25-.5.25-1.05V2.2q0-.55-.25-1-.15-.3-.4-.55Q10 0 9.1 0H2.15Q1.3 0 .65.65.4.9.25 1.2 0 1.65 0 2.2v1.95Q0 4.7.25 5.2q.15.25.4.5.65.65 1.5.65H9.1q.9 0 1.55-.65z"
                    id="AccMor017__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M7.85.55Q7.3 0 6.5 0H1.9Q1.1 0 .55.55T0 1.9v.05q0 .8.3 1.4.3.55.6.6.3 0 .3-1.2-.05-1.2.55-1.55.6-.35 1.35-.3h4.35q.4 0 .65-.05 0-.05-.25-.3z"
                    id="AccMor017__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor017__Symbol_115_0_Layer0_0_FILL"
                transform="translate(128.95 310.1)"
                id="AccMor017__col1n"
            />
            <use
                xlinkHref="#AccMor017__Symbol_21_0_Layer0_0_FILL"
                transform="translate(186.95 316.35)"
                id="AccMor017__col2n"
            />
            <use
                xlinkHref="#AccMor017__Symbol_12_0_Layer0_0_FILL"
                transform="translate(188.25 317.4)"
                id="AccMor017__col2l"
            />
        </g></g>
    )
}

export default AccMor017
