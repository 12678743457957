import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00"
}

function ShiMot021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M37.75 26.15h-.2q-1.85-.1-3.8.4.05.6.05 1.2 0 1.5-.35 2.8-.7 2.35-2.55 4.25l-.4.4q-1.15 1-2.45 1.6.3 1.6 1.1 3 0 .05.1.15.15.25.35.55 2.25-1 4.15-2.9 4.05-4.1 4.1-9.95-.05-.8-.1-1.5m-23.1 4.4q-.4-1.3-.4-2.8 0-.6.1-1.15-2-.55-3.8-.45h-.25q-.05.75-.05 1.55 0 5.8 4.1 9.95 1.9 1.85 4.15 2.9.15-.3.35-.6.05-.1.1-.15.8-1.4 1.1-2.95-1.35-.65-2.45-1.65-.25-.2-.4-.4-1.9-1.85-2.55-4.25M24 17.8q1.4 0 2.65.4 1.7.45 3.15 1.55l.6-.6q.85-.85 1.45-1.8.05-.1.1-.15.05-.1.1-.15.15-.25.3-.55-3.6-2.8-8.35-2.85-4.7.1-8.25 2.85.1.3.3.55.05.1.1.15 0 .1.05.15.6.95 1.5 1.8.25.3.6.6 1.45-1.1 3.15-1.55 1.2-.35 2.55-.35v-.05m-.65 6.8v-1.65q-2.65-.15-4.85-1.45-1.05-.7-1.95-1.6-.45-.4-.75-.8-.3-.35-.5-.7-.65-.95-1.1-1.95-.2-.5-.35-.95-.3-1-.35-2.05-.05-.5-.05-1 0-1.35.3-2.6.65-2.75 2.8-4.9l.1-.1q1.75-1.7 3.9-2.4-4.4.9-7.75 4.25-4.6 4.6-4.6 11.15 0 .6.05 1.25-1.95 1.05-3.6 2.7Q.3 26.15.05 32.15q-.05.4 0 .8Q0 35.9 1 38.5.55 37 .55 35.4q0-1.35.3-2.55.65-2.8 2.8-4.9 3.1-3.1 7.45-3.1t7.45 3.1q.75.75 1.35 1.6l.05.05 1.1-.55q-.25-.6-.25-1.25 0-1.35.95-2.3.7-.7 1.6-.9m21.1 18.25q-.95.95-2 1.6-2.45 1.5-5.45 1.5-4.4 0-7.45-3.1-3.15-3.1-3.15-7.45 0-2.35.9-4.3.05-.2.2-.4l-1.15-.65v.05q-1 .95-2.35.95v.05q-1.3-.05-2.25-1l-1.15.6.2.4q.85 1.95.85 4.3 0 4.35-3.1 7.45-3.1 3.1-7.45 3.1-3.05 0-5.45-1.5-1.1-.65-2-1.6-.4-.4-.75-.8.75 1.05 1.75 2.05.2.2.5.45 4.45 4.15 10.6 4.15 4.6 0 8.25-2.25 3.65 2.2 8.3 2.25 6.2-.05 10.65-4.15l.5-.5q.95-.95 1.75-2-.35.4-.75.8m2.8-10.05q.25 1.25.3 2.6 0 1.6-.45 3.05.95-2.55.95-5.5v-.8q-.25-6.05-4.6-10.35-1.65-1.7-3.6-2.75.05-.6.05-1.25 0-6.5-4.6-11.1-3.4-3.4-7.75-4.3 2.15.75 3.9 2.4.05.1.1.15 2.15 2.15 2.8 4.9.3 1.2.3 2.55 0 .5-.05 1.05-.1 1.05-.35 2.05-.15.45-.35.9-.45 1.05-1.1 2-.25.35-.5.65-.35.4-.75.85-.95.9-2 1.55-2.15 1.35-4.8 1.5v1.65q.9.15 1.6.85.95.95.95 2.35 0 .65-.25 1.2l1.1.6v-.05q.6-.85 1.4-1.6 3.05-3.1 7.45-3.1 4.35 0 7.45 3.1 2.1 2.1 2.8 4.85z"
                    id="ShiMot021__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot021__Symbol_27_0_Layer0_0_FILL"
                transform="translate(168.4 256.1)"
                id="ShiMot021__col1n"
            />
        </g></g>
    )
}

export default ShiMot021
