import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000",
    "col2d": "#A37831",
    "col2n": "#D19532"
}

function AccHat099({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#BFF2FF"
                    fillOpacity={0.749}
                    d="M267.5 185.25v-53H120v53h147.5z"
                    id="AccHat099__Layer16_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M168.5 31.4q-1.4-1.5-2.9-3Q137.2 0 97 0 56.85 0 28.45 28.4q-1.9 1.9-3.65 3.85Q0 59.45 0 97q0 40.15 28.45 68.55 18.75 18.8 42.65 25.2Q83.35 194 97 194q13.65 0 25.95-3.25 23.9-6.4 42.65-25.2Q194 137.15 194 97q0-38.1-25.5-65.6m-2.35 6.95v.05l4.3 18.15q-.1.35-.25.7-5.45 15.15-7.85 22.5l10.35 20.35q8.1 16.95 5.65 25.35-3.05 6.15-12.2 13.4-.75.5-1.5 1.1-.9.75-1.75 1.5-2.35 1.85-3.45 3.1-7.05 7.5-10.35 14.35-1.35 2.75-2.1 5.4-.25.95-.45 1.85-.1.5-.15.85l-1.35 8.4-13.75 7.5-8.35-3.4-51.85-.2v.2l-8.95 3.4-13.75-7.5-.9-6.3q-.1-.4-.15-1.4v.35l-.1-.7q-.1-.6-.45-2.45-1.8-8.8-12.65-20.35-1.1-1.25-2.25-2.4-1.2-1.15-2.5-2.2-.75-.6-1.5-1.1-4.65-3.65-7.7-7.05-.75-.85-1.4-1.65-.6-.75-1.1-1.45-.6-.9-1.1-1.7-3.45-7.45 2.2-20.9.75-1.75 1.65-3.6l11.4-22.45q-2.4-7.35-7.95-22.8-.1-.35-.25-.7l4.25-18.2q3.25-4.1 7.1-7.9 8.35-8.4 17.9-14.05 3.85-2.3 7.95-4.15 3.6-1.1 7.3-1.85l.05.1v-.05l10.75 41.4h36.2l10.75-41.4v.05l.05-.1q3.7.75 7.25 1.85 4.1 1.85 8 4.15 9.5 5.65 17.9 14.05 3.8 3.8 7.05 7.9m17.45 77.55l-.2.75q.05-.35.1-.65l.1-.1m-173 .1q.05.3.1.65l-.2-.75.1.1z"
                    id="AccHat099__Symbol_446_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M23.65 29.05l4.25-18.2-2.55-5.55-.15-.45v.05l-.2.2-.2-.35Q0 31.95 0 69.5q0 40.15 28.45 68.55 18.75 18.8 42.65 25.2v-8.35q.05-.15 0-1.35V152l-8.95 3.4-13.75-7.5-.9-6.3q-.1-.4-.15-1.4v.35l-.1-.7q-.1-.6-.45-2.45-1.8-8.8-12.65-20.35-1.1-1.25-2.25-2.4-1.2-1.15-2.5-2.2-.75-.6-1.5-1.1-4.65-3.65-7.7-7.05-.75-.85-1.4-1.65-.6-.75-1.1-1.45-.6-.9-1.1-1.7-3.45-7.45 2.2-20.9.75-1.75 1.65-3.6l11.4-22.45q-2.4-7.35-7.95-22.8-.1-.35-.25-.7M10.5 88.4l.1.1q.05.3.1.65l-.2-.75M96.55 1.95q11.6-.05 23.55 3.1l.55-2Q109.35-.05 97.55 0 85.85 0 73.4 3.05l.55 2Q84.9 2 96.55 1.95m69.6 8.95l4.3 18.15q-.1.35-.25.7-5.45 15.15-7.85 22.5L172.7 72.6q8.1 16.95 5.65 25.35-3.05 6.15-12.2 13.4-.75.5-1.5 1.1-.9.75-1.75 1.5-2.35 1.85-3.45 3.1-7.05 7.5-10.35 14.35-1.35 2.75-2.1 5.4-.25.95-.45 1.85-.1.5-.15.85l-1.35 8.4-13.75 7.5-8.35-3.4v11.25q23.9-6.4 42.65-25.2Q194 109.65 194 69.5q0-38.1-25.5-65.6l-2.35 7m17.45 77.5l-.2.75q.05-.35.1-.65l.1-.1z"
                    id="AccHat099__Symbol_392_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M155.15 46.15L150.85 28v-.05q-3.25-4.1-7.05-7.9-8.4-8.4-17.9-14.05-3.9-2.3-8-4.15-3.55-1.1-7.25-1.85l-.05.1V.05l-10.75 41.4h-36.2L52.9.05V.1l-.05-.1q-3.7.75-7.3 1.85Q41.45 3.7 37.6 6q-9.55 5.65-17.9 14.05-3.85 3.8-7.1 7.9l-4.25 18.2q.15.35.25.7 5.55 15.45 7.95 22.8L5.15 92.1q-.9 1.85-1.65 3.6-5.65 13.45-2.2 20.9.5.8 1.1 1.7.5.7 1.1 1.45.65.8 1.4 1.65 3.05 3.4 7.7 7.05.75.5 1.5 1.1 1.3 1.05 2.5 2.2 1.15 1.15 2.25 2.4Q29.7 145.7 31.5 154.5q.35 1.85.45 2.45l.1.7v-.35q.05 1 .15 1.4l.9 6.3 13.75 7.5 8.95-3.4v-.2l51.85.2 8.35 3.4 13.75-7.5 1.35-8.4q.05-.35.15-.85.2-.9.45-1.85.75-2.65 2.1-5.4 3.3-6.85 10.35-14.35 1.1-1.25 3.45-3.1.85-.75 1.75-1.5.75-.6 1.5-1.1 9.15-7.25 12.2-13.4 2.45-8.4-5.65-25.35l-10.35-20.35q2.4-7.35 7.85-22.5.15-.35.25-.7m-20 29.9L146.3 96.4h-.05q-23.9 12.5-40.55 13.8-19.5.8-22.4-22.45 32.95-1.2 51.85-11.7m-118 20.35L28.3 76.05q18.9 10.5 51.9 11.7Q77.25 111 57.75 110.2 41.1 108.9 17.2 96.4h-.05z"
                    id="AccHat099__Symbol_344_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M155.15 18.2L150.85.05V0l-15.7 48.1 11.15 20.35h-.05q-23.9 12.5-40.55 13.8-19.5.8-22.4-22.45-.75 0-1.55.05-.8-.05-1.55-.05-2.95 23.25-22.45 22.45-16.65-1.3-40.55-13.8h-.05L28.3 48.1 12.6 0 8.35 18.2q.15.35.25.7 5.55 15.45 7.95 22.8L5.15 64.15q-.9 1.85-1.65 3.6-5.65 13.45-2.2 20.9.5.8 1.1 1.7.5.7 1.1 1.45.65.8 1.4 1.65 3.05 3.4 7.7 7.05.75.5 1.5 1.1 1.3 1.05 2.5 2.2 1.15 1.15 2.25 2.4 10.85 11.55 12.65 20.35l1.7.95q-.6.7-1.2 1.45l.05.4q.05 1 .15 1.4l.9 6.3 13.75 7.5 8.95-3.4q-.1-1.1-.25-2.2-.65-4.35-2.05-8.95l-.6.4q-.1-1-.3-2.05h.35q-.1-.35-.25-.7l-.2-.6-.05.1-.75-2.35q-.55-1.3-1.15-2.65-.35-.8-.65-1.6-7.55-17.35-19.95-26.6Q14.7 83.5 16.85 74q11.7 4.15 23.75 8.55 12.8 4.65 19.15 3.95 10.65-2.2 22-2.2 11.3 0 21.95 2.2 6.4.7 19.15-3.95 12.05-4.4 23.75-8.55.7 11.45-13.1 19.95-12.4 9.25-19.85 26.6l-.7 1.6q-.45 1.05-.85 2.05-.2.4-.35.8l-1.35 3.35h.15q-.25.9-.45 1.7l-.05-.05q-1.45 4.6-2.15 8.95l-.2 1.8q-.05.2-.05.4l8.35 3.4 13.75-7.5 1.35-8.4q-.5-.9-1-1.7l1.6-1q.75-2.65 2.1-5.4 3.3-6.85 10.35-14.35 1.1-1.25 3.45-3.1.85-.75 1.75-1.5.75-.6 1.5-1.1 9.15-7.25 12.2-13.4 2.45-8.4-5.65-25.35L147.05 41.4q2.4-7.35 7.85-22.5.15-.35.25-.7z"
                    id="AccHat099__Symbol_321_0_Layer0_0_FILL"
                />
                <path
                    d="M142.8 224.1l12.45 6.7 9.05-5.65h56.55l8.95 5.65 12.1-7q.05-.35.15-.85.2-.9.45-1.85l-12.45 7.7.05.15-8.75-5.45H163.4l-9 5.25v-.1l-12.1-6.95q.35 1.85.45 2.45l.1.7v-.35l-.05-.4z"
                    id="AccHat099__Layer7_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat099__Layer16_0_FILL" />
            <use
                xlinkHref="#AccHat099__Symbol_446_0_Layer0_0_FILL"
                transform="translate(95.5 56.8)"
                id="AccHat099__col1n"
            />
            <use
                xlinkHref="#AccHat099__Symbol_392_0_Layer0_0_FILL"
                transform="translate(95.5 84.3)"
                id="AccHat099__col1d"
            />
            <use
                xlinkHref="#AccHat099__Symbol_344_0_Layer0_0_FILL"
                transform="translate(110.8 67.2)"
                id="AccHat099__col2n"
            />
            <use
                xlinkHref="#AccHat099__Symbol_321_0_Layer0_0_FILL"
                transform="translate(110.8 95.15)"
                id="AccHat099__col2d"
            />
            <use xlinkHref="#AccHat099__Layer7_0_FILL" />
        </g></g>
    )
}

export default AccHat099
