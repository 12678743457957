import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#006AC9",
    "col1n": "#0087FF"
}

function LegSki009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M134.15 1.05q-5.6 1.25-11.2 2.3-2.05.4-4.05.75-5.15.85-10.3 1.55l-4.8.6q-8.7 1-17.4 1.45-2.2.1-4.35.15Q77.5 8 73 8h-2.05q-2.2 0-4.35-.05-7.1-.2-14.15-.7-1.9-.2-3.85-.35-5.1-.45-10.15-1.1-2.05-.3-4.1-.55Q27.3 4.2 20.3 2.85l-3.1-.6q-2.8-.6-5.6-1.25-.55-.1-1.1-.25Q9.2.45 7.25.1 4.6 5.75 0 9.25q-.25 1.1 2.35 2h.05q4.45 1 8.95 1.95 1.55.3 2.1 2.9 9-.7 17.05.45 1.1.15 2 .6.95.45 1.7 1.25 9.7-2.55 11.5-1.1 2.3 1.2 3.9 2.95 3 .05 6.85-1.5 3.8-1.6 7.55.6 4.75 3.5 9.5.1 3.95-1.8 11.1 1.5.7-1.3 1.5-1.7.8-.45 2.75-1.25 5.3-1.8 18.7.75 1.95-1.8 4.15-2.1 6.45-.9 13.4-.35 1.4-2.15 3.25-2.5 7.1-1.4 13.1-2.95 1.15-.2 1.2-.2 1.75-.4 1.85-1.7-5.2-5.25-5.8-8.95-2.7.65-4.4 1-.1 0-.15.05z"
                    id="LegSki009__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.4 11.25Q7.7 6.6 10.5.75 9.2.45 7.25.1 4.6 5.75 0 9.25q-.25 1.1 2.35 2h.05m8.95 1.95q1.55.3 2.1 2.9Q18.1 9.75 20.3 2.85l-3.1-.6q-1.6 5.8-5.85 10.95m23-7.95l-.1.1q-.9 5.8-3.75 11.2 1.1.15 2 .6.95.45 1.7 1.25 3.05-6.15 4.25-12.45V5.8q-2.05-.3-4.1-.55M48.6 6.9l-.1.2-2.8 10.2q2.3 1.2 3.9 2.95l2.85-12.8v-.2q-1.9-.2-3.85-.35M71 8.25q-.05-.15-.05-.25-2.2 0-4.35-.05-.05.1-.1.25L64 19.35q4.75 3.5 9.5.1L71 8.25m15.1 11q.8-.45 2.75-1.25l-2.4-10.2q-.05-.05-.05-.1-2.2.1-4.35.15-.05.1-.05.2l2.6 12.9q.7-1.3 1.5-1.7m25.6-2.6q-3.15-5.05-3.1-11l-4.8.6q.4 6.45 3.75 12.5 1.95-1.8 4.15-2.1m16.65-2.85q-4.6-4.65-5.4-10.45-2.05.4-4.05.75 1.15 7.25 6.2 12.2 1.4-2.15 3.25-2.5M134.3 1q-.1 0-.15.05 2.55 5.6 7.3 9.8 1.15-.2 1.2-.2 1.75-.4 1.85-1.7-5.2-5.25-5.8-8.95-2.7.65-4.4 1z"
                    id="LegSki009__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki009__Symbol_75_0_Layer0_0_FILL"
                transform="translate(119.5 307.05)"
                id="LegSki009__col1n"
            />
            <use
                xlinkHref="#LegSki009__Symbol_40_0_Layer0_0_FILL"
                transform="translate(119.5 307.05)"
                id="LegSki009__col1d"
            />
        </g></g>
    )
}

export default LegSki009
