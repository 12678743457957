import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.7 4.95l-.1-.1q-.25-.25-.45-.5-.15-.1-.25-.2V4.1l-.05-.05q-.2-.15-.35-.25-.05-.1-.1-.15Q25.2-.05 19.5 0q-5.65-.05-9.7 3.55-.2-.05-.35-.1-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05H6.6q-.25.05-.45.1-2.2.45-3.85 2.1Q0 7.8 0 11t2.3 5.45q.95 1 2.15 1.55l.3.1q.85 4.25 4.1 7.5 1.35 1.35 2.9 2.3.05 0 .1.05t.15.1q.35.2.75.4.35.2.75.35l.2.1h.05q.05.05.15.1.1 0 .2.05 2.5.95 5.4.95 6.25 0 10.65-4.4T34.6 15q-.05-5.6-3.65-9.75-.15-.15-.25-.3z"
                    id="BodHan018__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.55 7.75q-.05-3.2-2.3-5.45-1.5-1.55-3.4-2H9.8Q9.6.25 9.45.2q-.4-.1-.8-.1l-.8-.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05H6.6q-.25.05-.45.1-2.2.45-3.85 2.1Q0 4.55 0 7.75t2.3 5.45q.95 1 2.15 1.55l.3.1q1 .4 2.25.55l.75.05h.1l1.5-.15q2.2-.4 3.9-2.1 2.25-2.25 2.3-5.45M12.5 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-1.45 1.45-3.35 1.85l-1.3.15h-.1L7 14.4q-1.1-.1-2-.55-1.05-.5-1.95-1.4-1.95-1.95-1.9-4.7Q1.1 4.95 3.05 3q1.65-1.6 3.8-1.85h.05q.4-.1.85-.1H8.2q.5.05 1 .15 1.85.35 3.3 1.8z"
                    id="BodHan018__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill="#999"
                    d="M121.8 306.35q.1-.25.1-.35.1-.25.15-.5.05-.4-.05-.75-.15-.5-.45-.8-.6-1.05-1.4-1.8l-.05-.05q-5.05-5-9.4.25-.25.55-.35 1.3-.065.422-.1.85v.55l.1.6q0 .95.25 2.3.1.95.35 2.15.15 1-.05 2.25-.05.6-.2 1l-.1.2q-.75 1.5-2.05 2.15-.2.05-.4.2-.05.05-.15.1-1.2.35-2.75.05-1.7-.35-3.15-2-.4-.55-.7-1-.7-1.55-.35-3.4.05-.3.05-.5.05-.35.15-.9-.5.6-1.15 1.6-.05.1 0 .2-.05 0-.1.05-.25.45-.5 1.75-.1.5-.1 1.05-.7 6.35 5.9 8.9 1 .3 2.1.55 1.3.05 2.45 0 7.4-.95 8.25-8.9.5-2.55 1.15-3.7.2-.4.55-.8.3-.2.6-.5l.2-.2.5-.5q.023-.034.05-.1l.1-.1q.4-.45.55-1.2z"
                    id="BodHan018__Layer1_0_FILL"
                />
                <path
                    d="M111.25 277.95q-3.35 7.7-4.8 12.3-3.7 11 3.8 14.3.05-.5.1-.9.1-.75.35-1.3 4.35-5.25 9.4-.25l.05.05q.8.75 1.4 1.8.3.3.45.8.1.35.05.75-.05.25-.15.5 0 .1-.1.35-.15.75-.55 1.2-.05.05-.1.15 8.95 1.1 10.65-11 .8-4.95 1.5-12.55l-22.05-6.2z"
                    id="BodHan018__Layer1_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan018__Symbol_32_0_Layer0_0_FILL"
                transform="translate(245.4 280.6)"
                id="BodHan018__col1n"
            />
            <use
                xlinkHref="#BodHan018__Symbol_37_0_Layer0_0_FILL"
                transform="translate(245.4 283.85)"
                id="BodHan018__col1d"
            />
            <use xlinkHref="#BodHan018__Layer1_0_FILL" />
            <use xlinkHref="#BodHan018__Layer1_1_FILL" />
        </g></g>
    )
}

export default BodHan018
