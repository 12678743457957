import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#7973B7",
    "col1n": "#56518C",
    "col1s": "#201E35",
    "col2n": "#FFFF9C"
}

function ShiSpe059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-.8-1.2-1.55-2.35-.5-.65-.9-1.3-.35-.5-.7-.95-.25-.35-.5-.65-.25-.4-.55-.75l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95Q46.8 15.1 37.15 9.9q-4.35-2.4-8.5-5.25L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-.15.2-.3.45-.25.3-.5.65-.8 1.1-1.55 2.25-1.75 2.55-3.35 5.2-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 17.3 3.9 34.55 5.65.15 0 .35.05.6 0 1.3.1.3 0 .6.05.4.05.75.1 11.65 1 23.25 1.05h1.2q11.75 0 23.45-1.05.2-.05.4-.05.45-.05.95-.1.85-.1 1.65-.15Q126.7 85.9 143.8 82q2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe059__Symbol_284_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M117.45 2.35q-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95Q24.8 15.1 15.15 9.9q-4.35-2.4-8.5-5.25L5.1 3.5Q3.65 2.45 2.3 1.4L.45 0Q.35.05.3.15.15.35 0 .6q1.05 1.35 2.3 2.7 3.95 4.2 9.3 7.95l1.3.9 9.45 6.6q.05.05.15.1l36.55 28.2q.45.35.85.6.7-.15 1.2-.5v.6q.45-.25 1.05-.7l36.55-28.2 8.25-5.8.2-.1q1.25-.85 2.45-1.7 5.35-3.75 9.3-7.95 1.2-1.3 2.2-2.55-.25-.4-.55-.75l-1.85 1.4q-.6.5-1.25.95z"
                    id="ShiSpe059__Symbol_285_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.15 34.75q-.2.1-.25.3L.7 56.5v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1m110.95.5v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35m6-34.45q-.25-.35-.5-.65-1 1.25-2.2 2.55-3.95 4.2-9.3 7.95-2.75 1.95-5.7 3.65-17.9 10.25-41.75 10.55-.55-.05-1.05-.05v-4.5h-1.2v4.5q-.4 0-.85.05-23.85-.3-41.75-10.55-2.95-1.7-5.7-3.65Q6.75 6.9 2.8 2.7 1.55 1.35.5 0 .25.3 0 .65q1.3 1.7 2.8 3.3 2.25 2.4 4.95 4.65 1.35 1.1 2.8 2.2.75.55 1.55 1.1 2.4 1.7 4.95 3.2.15.05.35.2l2.6 1.5q1.45.75 3 1.45 16.1 7.6 36.55 7.85h.85v62.3h1.2V26.1h1.05q20.45-.25 36.55-7.85 1.55-.7 3-1.45l2.6-1.5q.2-.15.35-.2 2.55-1.5 4.95-3.2.8-.55 1.55-1.1 1.45-1.1 2.8-2.2 2.7-2.25 4.95-4.65 1.45-1.5 2.7-3.15z"
                    id="ShiSpe059__Symbol_287_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.8 48.85Q12.05 40.5.75 47.15q-.4 1.7-.75 3.4v.2q11.75-7.2 22.15 1.35l.65-3.25M46.15 30.5l1.9 4.55q6.65 17.45 6.95 41 .05 5.05-.15 10.35.15 0 .35.05.6 0 1.3.1.3 0 .6.05.4.05.75.1.2-5.5.15-10.65-.35-30.8-10.35-49.65-.2-.35-.35-.65-1.55-2.85-3.3-5.35-2.45-3-5.9-5.75-.2-.15-.35-.2-2.55-1.5-4.95-3.2-.8-.55-1.55-1.1-1.45-1.1-2.8-2.2-2.7-2.25-4.95-4.65Q22 1.7 20.7 0q-.8 1.1-1.55 2.25 5 6.15 13.45 11.85.5.35 1.05.75 7.45 5.2 12.5 15.65M144.4 2.4q-.5-.65-.9-1.3-.35-.5-.7-.95-1.25 1.65-2.7 3.15-2.25 2.4-4.95 4.65-1.35 1.1-2.8 2.2-.75.55-1.55 1.1-2.4 1.7-4.95 3.2-.15.05-.35.2-3.45 2.75-5.9 5.75-1.75 2.5-3.3 5.35-.15.3-.35.65-10 18.85-10.35 49.65-.05 5.15.15 10.65.2-.05.4-.05.45-.05.95-.1.85-.1 1.65-.15-.2-5.3-.15-10.35.3-23.55 6.95-41l1.9-4.55q5.05-10.45 12.5-15.65.55-.4 1.05-.75 8.35-5.65 13.4-11.7m18.65 46.15q-.1-.45-.2-.85l-.1-.5q-11.4-6.75-22.1 1.7l.6 3.2h.05q10.35-8.6 22.2-1.35-.25-1.1-.45-2.2z"
                    id="ShiSpe059__Symbol_288_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe059__Symbol_284_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe059__col1n"
            />
            <use
                xlinkHref="#ShiSpe059__Symbol_285_0_Layer0_0_FILL"
                transform="translate(132 228.6)"
                id="ShiSpe059__col1l"
            />
            <use
                xlinkHref="#ShiSpe059__Symbol_287_0_Layer0_0_FILL"
                transform="translate(131.5 229.2)"
                id="ShiSpe059__col1s"
            />
            <use
                xlinkHref="#ShiSpe059__Symbol_288_0_Layer0_0_FILL"
                transform="translate(110.8 229.85)"
                id="ShiSpe059__col2n"
            />
        </g></g>
    )
}

export default ShiSpe059
