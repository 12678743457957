import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#9A3200",
    "col1s": "#2E0E00",
    "col2n": "#621F00"
}

function ObjToo020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.75 104.7q-1.5.55-2.9.75-.1.95-.15 1.9l-.6 4.7q-.55 4-1.35 8-.6 1.1-.95 1.7-.05.05-.05.15-.15.2-.25.4-.2.2-.35.4l-.1.1q-1.35 1.7-1.75 2.55-1.05 2.2-.8 3.95 0 1.3.3 2.45.1.35.25.7 0 .05.05.2.5 1.95 1.25 2.4.1-.15.25-.3l.25-1q.2-2.45.65-3.2.3-.55.8-1.15.45-.65 1.4-1.55.6-.6 1-1.05.05-.05.15-.05.5-.5 1.15-1.05.35-.45.65-.7.4-.7.65-1.4.2-.55.9-2.95.1-.3.2-.5 1.3-8.85 1.85-17.35-.3.35-.65.7-.75.7-1.6 1.1l-.2.1h-.05M14.55 8.9l-.4-.3q-1.8-1.75-2.25-2.15-.6-.5-1.25-.9-.4-.1-.95-.25-.8-.2-1.5-.35h-.15Q7.5 4.8 6.7 4.6q-1.3-.35-2.05-.65-.75-.3-1.25-.6Q2.65 2.9 1.25.9L.55.15Q.35.05.2 0q-.45.7.1 2.7 0 .1.05.2 0 .35.15.75.25 1.15.95 2.25.65 1.65 2.65 3.05.75.5 2.8 1.3.05 0 .1.05.25.05.5.15.2.1.45.25.05.05.15.1.55.3 1.65 1Q24.1 28.3 32 46.4q6.65 15.3 8.7 31.75l.4 4.6q.4.1.8.25 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.15.2.25.4-.15-5.1-.65-10.1Q44.3 60.7 37.15 45q-8.2-18.1-22.6-36.1M191.9 19.1l-1.2-2.3-15.3 7.8q1.5 1.8 3.05 4.05.15.3.35.55-.75 1.65-.75 2.6 0 .95.6 2.1 1 1.9 3.1 1.9.35 0 .8-.05.05.1.1.25 5 9.05 7.2 14.7l14.55-7.45-1.95-3.9-.05.05-.1-.2q.05-.1.15-.2.2-.3.2-.6 0-1.1-1-.95-.15 0-.25.05l-2.55-4.95q.05-.1.1-.15.2-.3.2-.6 0-1.1-1-.95-.1 0-.15.05l-.05-.1.05-.05-2.05-3.95h-.05l-.3-.6q0-.05.05-.1.2-.3.2-.6 0-1.1-1-.95-.05 0-.1.05l-.05-.1h.05l-2-3.8.1-.1q.2-.3.2-.6 0-1.1-1-.95-.1 0-.15.05m1.45 8.2h.05l-.05.05v-.05z"
                    id="ObjToo020__Symbol_180_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M15.5 26.65L2.1 0 0 1.05l13.4 26.1h-.05l.25.45 1.9-.95z"
                    id="ObjToo020__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M125.9 11.85l3.7-3.5 1.25-1q-1.5-2.95-4.2-7.35-41.4 42.8-118.1 64.25-.8.65-1.6 1.1l-.4.2h.05l-.55.35Q4.75 71.85 0 74.95l.2-.05q23.3-5.3 46.45-15.6 49.9-20.5 79.25-47.45z"
                    id="ObjToo020__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M318.45 221.45l-20.25.85q.132-.136.25-.3.502-.603 2.45-3.45l8.55-12.4-23.55 13.6-5.2 11.1-.1.8q.616.232 1.25.35-.25.747-.5 1.8l13.45 1.35 23.65-13.7z"
                    id="ObjToo020__Layer5_1_FILL"
                />
                <path
                    fill="#652801"
                    d="M271.6 228.7h-.05l.95 1.85 10.5-4.5 1.2-2.65-12.6 5.3m32.3-7.9l-1.15-1.95-27.9 16.1-.05.05 1.05 2 28.05-16.2m-9.1 14.35l-2.3-.25-14.3 6.7 1.05 2 .05-.05 28.75-13.45-.95-2.05-12.3 7.1m5-27.45l-1.15-1.9-30 17.35 1.05 2 30.1-17.45z"
                    id="ObjToo020__Layer5_2_FILL"
                />
                <g id="ObjToo020__Layer5_0_FILL">
                    <path fill="#563A29" d="M270.2 231.5v.05l.05-.05h-.05z" />
                    <path
                        fill="#999"
                        d="M283.55 239.1l1.25-.6q-.265 1.221-.3 2.65l13.3 2.45 24.7-11.55-17.9-.3q-1.687-.246-3.25-.4l2.35-1.35 11.2-14-14.05 6.55L286 231.1l.3 3.2-2.75 4.8m16.25-19.75l-.75-2.5 4.75-2.45-.1.2 10.65-6.2-17.85 1.2q-1.64.127-3.05.25.937-1.044 1.9-2.2l10.05-14.6-23.6 13.65-5.65 12.1q.826 1.11 1.95 1.45l-.8.5 7.8.8.65 7.1 13-7.5q-.35-.242-.35-.85 0-.85 1-.95.25-.037.4 0z"
                    />
                    <path d="M113.9 327l.1-.1-.1.05v.05M83.75 214.45v.05h.1q-.05-.05-.1-.05z" />
                    <path
                        fill="#252525"
                        d="M83.85 214.5h-.1l30.15 112.45.1-.05q.15-.2.35-.4.1-.2.25-.4 0-.1.05-.15L84.95 215q-.1-.05-.15-.1-.25-.15-.45-.25-.25-.1-.5-.15z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo020__Symbol_180_0_Layer0_0_FILL"
                transform="translate(76.85 204.2)"
                id="ObjToo020__col1n"
            />
            <use
                xlinkHref="#ObjToo020__Symbol_104_0_Layer0_0_FILL"
                transform="translate(260.55 223.5)"
                id="ObjToo020__col1s"
            />
            <use
                xlinkHref="#ObjToo020__Symbol_64_0_Layer0_0_FILL"
                transform="translate(128.65 232.85)"
                id="ObjToo020__col2n"
            />
            <use xlinkHref="#ObjToo020__Layer5_0_FILL" />
            <use xlinkHref="#ObjToo020__Layer5_1_FILL" />
            <use xlinkHref="#ObjToo020__Layer5_2_FILL" />
        </g></g>
    )
}

export default ObjToo020
