import { ReactNode, createContext, useContext, useState } from "react"
import { Item, prepareItems } from "../shared/assets"
import { defaultItems, getSavedItems } from "../shared/storage"
import { Tab } from "../shared/app"

const AppContext = createContext<{
    showWatermark: boolean
    setShowWatermark: (showWatermark: boolean) => void
    items: Item[]
    setItems: (items: Item[]) => void
    activeTab: Tab
    setActiveTab: (activeTab: Tab) => void
    activeItemKey: number | null
    setActiveItemKey: (activeItemKey: number | null) => void
}>({
    showWatermark: true,
    setShowWatermark: () => {},
    items: [],
    setItems: () => {},
    activeTab: "info",
    setActiveTab: () => {},
    activeItemKey: null,
    setActiveItemKey: () => {}
})

export interface AppProviderProps {
    children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
    const [showWatermark, setShowWatermark] = useState(true)
    const [items, setItems] = useState<Item[]>(
        prepareItems(getSavedItems() || defaultItems)
    )
    const [activeTab, setActiveTab] = useState<Tab>("info")
    const [activeItemKey, setActiveItemKey] = useState<number | null>(null)

    return (
        <AppContext.Provider
            value={{
                showWatermark,
                setShowWatermark,
                items,
                setItems,
                activeTab,
                setActiveTab,
                activeItemKey,
                setActiveItemKey
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export const useApp = () => {
    const context = useContext(AppContext)
    if (context === undefined) {
        throw new Error("useApp must be used within a AppProvider")
    }
    return context
}
