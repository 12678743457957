import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#794D32"
}

function ObjMor070({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M57.8 107.55q.4 1.45.45 3.05.2.35.3.75.3.95.2 1.9 0 1.2-.3 2.5v.2q-.05.2-.15.45-.45 1.7-1.35 3.65Q60.3 129 62.7 138.4q3.9.8 6.7-.6.15-.05.2-.1 2.85-1.4 4.5-4.95-9.65-12.2-16.3-25.2M0 1.6l22.35 45.2.1-.05 14.5 29.4q3.2 5.5 9.15 20.75l.1.2q1.3.2 2.7.75 1.8.7 3.3 1.75 1.75 1.25 3.1 2.95.1.1.2.25-1.75-3.75-3.25-7.55-9.55-14.9-12-20.7L25.65 45l-.05.05L3.3 0 0 1.6z"
                    id="ObjMor070__Symbol_161_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor070__Symbol_161_0_Layer0_0_FILL"
                transform="translate(68.15 189.6)"
                id="ObjMor070__col1n"
            />
        </g></g>
    )
}

export default ObjMor070
