import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.35 8.1l-1.4-3.65q-6.45-1.85-12.1-2.9-12.15-3.05-19.4 0Q5.5 3.45 0 9.1q14.4-10.35 43.35-1m56.9 1q-5.5-5.65-10.45-7.55-8.25-2.8-19.4 0-5.65 1.05-12.1 2.9L56.9 8.1q28.95-9.35 43.35 1z"
                    id="FacBro012__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro012__Symbol_12_0_Layer0_0_FILL"
                transform="translate(142.4 122.95)"
                id="FacBro012__col1n"
            />
        </g></g>
    )
}

export default FacBro012
