import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}

function ObjFoo025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M59.5 29.75q0-12.3-8.75-21.05-8.7-8.7-21-8.7T8.7 8.7Q0 17.45 0 29.75t8.7 21q8.75 8.75 21.05 8.75 5.3 0 9.9-1.6.4-.1 1.45-.6 1.45-.7 1.6-.8l-.1.2q4.4-2.2 8.15-5.95 8.75-8.7 8.75-21z"
                    id="ObjFoo025__Symbol_57_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M40.55 40.55q6.95-6.95 6.95-16.8 0-9.85-6.95-16.8Q33.6 0 23.75 0 13.95 0 7 6.95q-7 6.95-7 16.8 0 9.85 7 16.8 6.95 6.95 16.75 6.95 9.85 0 16.8-6.95M24.5 6.25q7.25 0 12.4 5.15Q42 16.5 42 23.75t-5.1 12.4q-5.15 5.1-12.4 5.1t-12.35-5.1Q7 31 7 23.75t5.15-12.35q5.1-5.15 12.35-5.15M36.55 24q0-4.85-3.45-8.3-3.45-3.45-8.3-3.45-4.9 0-8.3 3.45-3.45 3.45-3.45 8.3 0 4.85 3.45 8.3 3.4 3.45 8.3 3.45 4.85 0 8.3-3.45 3.45-3.45 3.45-8.3m-7.2-4.15q1.8 1.8 1.85 4.4-.05 2.6-1.85 4.4-1.85 1.85-4.4 1.85-2.6 0-4.45-1.85-1.85-1.8-1.8-4.4-.05-2.6 1.8-4.4Q22.35 18 24.95 18q2.55 0 4.4 1.85z"
                    id="ObjFoo025__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M120.3 258.45q-.3 0-.55.05l.1-.2q-.15.1-1.6.8-1.05.5-1.45.6.05 0 .05.1l10.75 23.6h.05l.65 1.45q1.366-.747 3-.9l-.7-1.5q0-.1-.05-.2l-10.65-23.7.4-.1z"
                    id="ObjFoo025__Layer7_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo025__Symbol_57_0_Layer0_0_FILL"
                transform="translate(77.15 201.8)"
                id="ObjFoo025__col1n"
            />
            <use
                xlinkHref="#ObjFoo025__Symbol_11_0_Layer0_0_FILL"
                transform="translate(83.65 207.8)"
                id="ObjFoo025__col2n"
            />
            <use xlinkHref="#ObjFoo025__Layer7_0_FILL" />
        </g></g>
    )
}

export default ObjFoo025
