import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#621F00",
    "col1n": "#9A3200",
    "col2d": "#747474",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjToo029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M34.35 61.25q-1.3.5-2.75.5-3.15 0-5.4-2.25-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-1 .4-1.9.6l6 20.7q.05.15.05.35.35 1 1.3 1.7.6.35 1.5.65.35.1.8.2 1.6.35 3.45.2h.2q1.4-.15 3-.65.35-.1.65-.2 3.25-1 5.2-2.95.6-.6 1-1.15 1.05-1.55.6-2.95 0-.05-.05-.1 0-.1-.05-.2l-7.65-26.35M30.1 46.6L16.95 1.4Q6.3-3.5 0 9.05l11.6 39.8q3.15-.35 6.8.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4 1.7-1.7 3.9-2.1z"
                    id="ObjToo029__Symbol_189_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M39.3 72.15l-1.45-5.05v.1L27.2 89.3q.35.1.8.2 1.6.35 3.45.2l7.85-17.55M30.4 55.6q-1.8-.3-3.25-1.35L19.7 70.7l1.8 5.65 8.9-20.75m-12-12q.95.4 1.8 1.05L27 29.9l-1.75-6.1-9.2 19.2q1.15.2 2.35.6M6.65 25.8l1.9 6.5L20.9 8.85l-1.7-5.7L6.65 25.8m-5-17.2L6.55 0 0 2.95 1.65 8.6z"
                    id="ObjToo029__Symbol_127_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M98.3 22.15L88.85 4.4 62.8 0 24 17.2 0 40.25 5.95 62.3l6.7 9.5.3.25L27.05 79l25.8-11.75-.05-.15 16.95-7.65.05.15 30.95-14.15-2.45-23.3z"
                    id="ObjToo029__Symbol_70_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M21.55 42.9L9.05 0 0 10.2l7.45 25.75 14.1 6.95z"
                    id="ObjToo029__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M48.55 10.45q-2.9.45-6.05 1.85-3.1 1.35-5.3 3.1-3.05 1.25-5.6 2.2-.5.15-.95.35h-.05L3.05 29.7 0 43.4l7.65 26.3 10.15 8.05L10.35 52l9.05-10.2 74.3-31.7L72.55 0 53.8 8.05q-2.7 1.25-5.25 2.4m9.3 4.7q1.05 2.4-1.65 5.55-2.75 3.2-7.65 5.35-4.85 2.15-9.1 1.9-4.1-.15-5.25-2.55-.85-1.7-.85-2.25l-.05-.2q.6 1.15.75 1.3 1.35 1.2 4.4 1.35 4.2.2 9.05-1.9 4.9-2.15 7.5-4.85 2.55-2.75 1.7-6.35.1.15.15.3.1.15.1 0 .05.15.15.35.1.1.15.2.4 1.3.6 1.8M108.1 37.6l-7.2-25.2-7.2-2.3 11.9 41.05 2.5-13.55z"
                    id="ObjToo029__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.149}
                    d="M62.1 215.55l-16.35-12.1-3.05 13.7 10.35 8.6 9.05-10.2m17.8-26.4q-1.25 1-2.3 2.1-2.7 3.15-1.6 5.55 0 .05.05.1l-.05-.2q.6 1.15.75 1.3 1.35 1.2 4.4 1.35 4.2.2 9.05-1.9 4.9-2.15 7.5-4.85 2.55-2.75 1.7-6.35-1.15-2.1-5.1-2.25-1.5 0-3.05.2-2.9.45-6.05 1.85-3.1 1.35-5.3 3.1z"
                    id="ObjToo029__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo029__Symbol_189_0_Layer0_0_FILL"
                transform="translate(100.35 237.5)"
                id="ObjToo029__col1n"
            />
            <use
                xlinkHref="#ObjToo029__Symbol_127_0_Layer0_0_FILL"
                transform="translate(100.35 243.6)"
                id="ObjToo029__col1d"
            />
            <use
                xlinkHref="#ObjToo029__Symbol_70_0_Layer0_0_FILL"
                transform="translate(47.55 179.45)"
                id="ObjToo029__col2n"
            />
            <use
                xlinkHref="#ObjToo029__Symbol_33_0_Layer0_0_FILL"
                transform="translate(53.05 215.55)"
                id="ObjToo029__col2l"
            />
            <use
                xlinkHref="#ObjToo029__Symbol_15_0_Layer0_0_FILL"
                transform="translate(42.7 173.75)"
                id="ObjToo029__col2d"
            />
            <use xlinkHref="#ObjToo029__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo029
