import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF"
}

function AccGla028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    fillOpacity={0.502}
                    d="M23.9 23.85q4.05-4 4.05-9.85T23.9 4.1Q19.8 0 13.95 0 8.15 0 4 4.1 0 8.15 0 14q0 5.85 4 9.85 3.2 3.2 7.45 3.9l2.5.2 2.55-.2q4.2-.7 7.4-3.9m27.2 3.9q4.2-.7 7.4-3.9 4.05-4 4.05-9.85T58.5 4.1Q54.4 0 48.55 0q-5.8 0-9.95 4.1-4 4.05-4 9.9 0 5.85 4 9.85 3.2 3.2 7.45 3.9l2.5.2 2.55-.2z"
                    id="AccGla028__Symbol_116_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.502}
                    d="M23 6.4q3.6 3.6 3.6 8.9 0 5.3-3.6 8.95-2.75 2.8-6.5 3.5 4.2-.7 7.4-3.9 4.05-4 4.05-9.85T23.9 4.1Q19.8 0 13.95 0 8.15 0 4 4.1 0 8.15 0 14q0 5.85 4 9.85 3.2 3.2 7.45 3.9-3.65-.7-6.45-3.5-3.65-3.65-3.65-8.95Q1.35 10 5 6.4q3.75-3.75 8.95-3.75 5.3 0 9.05 3.75m34.6 17.85q-2.75 2.8-6.5 3.5 4.2-.7 7.4-3.9 4.05-4 4.05-9.85T58.5 4.1Q54.4 0 48.55 0q-5.8 0-9.95 4.1-4 4.05-4 9.9 0 5.85 4 9.85 3.2 3.2 7.45 3.9-3.65-.7-6.45-3.5-3.65-3.65-3.65-8.95 0-5.3 3.65-8.9 3.75-3.75 8.95-3.75 5.3 0 9.05 3.75 3.6 3.6 3.6 8.9 0 5.3-3.6 8.95z"
                    id="AccGla028__Symbol_69_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla028__Symbol_116_0_Layer0_0_FILL"
                transform="translate(161.25 172.5)"
                id="AccGla028__col2n"
            />
            <use
                xlinkHref="#AccGla028__Symbol_69_0_Layer0_0_FILL"
                transform="translate(161.25 172.5)"
                id="AccGla028__col2d"
            />
        </g></g>
    )
}

export default AccGla028
