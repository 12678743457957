import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#43A6FF",
    "col2n": "#666666"
}

function SkiMak009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.4 12.1q-3.95-2.25-6.6-4.25-21.65-15.9-42.65.35h-.05v.15l-.2.8v.05l-4 16.1-4-16.1v-.05q-.1-.4-.15-.8-.05-.1-.05-.15h-.2Q53.55-8.05 31.9 7.85q-2.65 2-6.6 4.25-12.9 7.35-13.8 7.95-.9.55-1.1.7-.65.35-1.3.75-3.15 1.75-6.35 3.5L0 28.35l24.7 12.6q3.3-8.5 9.25-15.5l13.4-8.25q.35-.15.65-.3 8.4-4 18.3 1.35 5.45 2.2 9.35 6.2l3.25 6.8 3.55-7.2q3.75-3.75 8.95-5.8 9.9-5.35 18.3-1.35.3.15.65.3l13.4 8.25q5.95 7 9.25 15.5l24.7-12.6-2.75-3.35q-3.2-1.75-6.35-3.5-.65-.4-1.3-.75-.2-.15-1.1-.7-.9-.6-13.8-7.95M78.9 25.9l.05.1q-.048 0-.05.05v-.15m.1-.1v-.1l.3-.75q-.15.4-.3.85m-.2-.1v.1q-.15-.45-.3-.85l.3.75z"
                    id="SkiMak009__Symbol_15_0_Layer0_0_FILL"
                />
                <g
                    id="SkiMak009__Symbol_10_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M86.95 38.5q-.15.2-.2.45.1-.25.25-.45h-.05z" />
                    <path d="M82.6 10.85h.05v-.05Q82 7.85 81.4 4.85q-2.95-.6-5.9-1.8-3.05-1.25-6-1.95-3.05-.7-6.05-.9-8-1.35-19.05 5.45l-2.75 1.8L24.1 19.3q-3.6 2.6-5.35 3.85.05 0 .15-.05 1.55-.65 12-6.1 15.35-7.95 22.15-9.9 9.35-3.4 18.05-.25 2.15.85 4.15 1.6 2.05.7 2.5.9 2.1.9 4.65 1.45.1.05.2.05M142.8 17q10.45 5.45 12 6.1.1.05.15.05-1.75-1.25-5.35-3.85L132.05 7.45l-2.75-1.8Q118.25-1.15 110.25.2q-3 .2-6.05.9-2.95.7-6 1.95-2.95 1.2-5.9 1.8-.6 3-1.2 5.95-.05 0-.05.05h.05q.1 0 .25-.05 2.5-.55 4.6-1.45.45-.2 2.5-.9 2-.75 4.15-1.6 8.7-3.15 18.05.25 6.8 1.95 22.15 9.9m25.05 13.25q-2.5-1.3-4.95-2.6-1.4 4.3-10.25 9-2.75 1.35-5.65 2.2-.45.1-.9.25-4.35.9-7.25-4.4l-.05-.15q-.05-.15-.15-.3-.15-.3-.35-.6-2.8-5.1-5.85-8.5-1.35-1.55-2.95-2.8-.3-.3-.6-.55-1.05-.9-2.2-1.65-.25-.15-.55-.25-5.25-3.4-11.05-4.2-.45-.1-.95-.15-13.75-1.5-22.65 7.35-2.85 3.3-4.55 8.4-.05.1-.1.3l-.1.3v-.65q-.25-.7-.5-1.35-1.55-4.15-4.05-7-8.9-8.85-22.65-7.35-.5.05-.95.15-5.8.8-11.05 4.2-.3.1-.55.25-1.15.75-2.2 1.65-.3.25-.6.55-1.6 1.25-2.95 2.8-3.05 3.4-5.85 8.5-.2.3-.35.6-.1.15-.15.3l-.05.15q-2.9 5.3-7.25 4.4-.45-.15-.9-.25-2.9-.85-5.65-2.2-8.85-4.7-10.25-9-2.45 1.3-4.95 2.6L0 32.95q19.45 3.5 29.95 14.2l2.1 2.25q.95 1.1 1.8 2.3-.75-14.9 10.5-23.6 1.3-1.05 2.85-2 .7-.5 1.45-.95l.7-.3q0-.05.05-.05t.1-.05q13.55-7.55 26.25-1.5 1.5.65 2.9 1.55 5.05 3.3 7.75 10.1v.1q.05.1.5 2.8l.3-2.6.1-.2v-.1q2.7-6.8 7.75-10.1 1.4-.9 2.9-1.55 12.7-6.05 26.25 1.5.05.05.1.05t.05.05l.7.3q.75.45 1.45.95 1.55.95 2.85 2 11.25 8.7 10.5 23.6.85-1.2 1.8-2.3l2.1-2.25q10.5-10.7 29.95-14.2l-5.85-2.7z" />
                </g>
            </defs>
            <use
                xlinkHref="#SkiMak009__Symbol_15_0_Layer0_0_FILL"
                transform="translate(113.6 121.65)"
                id="SkiMak009__col1n"
            />
            <use
                xlinkHref="#SkiMak009__Symbol_10_0_Layer0_0_FILL"
                transform="translate(105.65 119)"
                id="SkiMak009__col2n"
            />
        </g></g>
    )
}

export default SkiMak009
