import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#666666"
}

function LegSoc003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.25 11.1q-1.3-.75-2.6-1.35-3.05-1.4-6.05-2.1-.65-.15-1.2-.25v-.05q-.18-.036-.35-.1V0q-.15 0-.25.05H10.55v.1Q10.5.1 10.4.05V7.3q-3.8.65-7.6 2.45-1.35.6-2.65 1.35H.1q-.05 0-.05.05-.05.05 0 .1l.05.05q0 .05.05.05h146.1q.05 0 .1-.05 0-.05.05-.05v-.1q-.05-.05-.1-.05h-.05z"
                    id="LegSoc003__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M54.85 7.7q-.15-.05-.25-.05-10.8-3-22-3.6-.4-.05-.8-.05-.55-.05-1.15-.05l-6.5-.05h-.2l-3.9.15-1.3.1q-8.55.6-13.2 1.65Q.85 6.8.2 7.1l-.2.1q7.95-1.3 16.1-1.8.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q15.4-.65 30.05 2l4.15.7q1.15.2 2.3.45 1.75.35 3.45.75.15.05.25.1h.05q.1 0 .2.05.15 0 .3.05-.25-.1-.55-.15-.1-.05-.25-.1-1.7-.6-3.45-1.05-.55-.15-1.05-.3M63.3 0h-2.05l1 8.8v-.4l.05.4 1-8.8m61 7.2v-.05q-.05-.05-.1-.05-.65-.3-5.35-1.3-4.65-1.05-13.2-1.65l-1.3-.1-3.9-.15h-.2l-6.5.05q-.6 0-1.15.05-.4 0-.8.05-11.2.6-22 3.6-.1 0-.25.05-.5.15-1.05.3l-1.4.4q-.2.05-.35.1-.85.25-1.7.55-.05 0-.1.05-.35.05-.7.2.15-.05.3-.05.1-.05.2-.05h.05q.1-.05.15-.05.05-.05.1-.05.85-.2 1.7-.35.25-.1.5-.1.6-.15 1.25-.3 1.15-.25 2.3-.45l4.15-.7Q89.6 4.55 105 5.2h.3q.15 0 .35.05l2 .1q.35 0 .65.05 8.05.5 16 1.8z"
                    id="LegSoc003__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSoc003__Symbol_4_0_Layer0_0_FILL"
                transform="translate(119.3 322.55)"
                id="LegSoc003__col1n"
            />
            <use
                xlinkHref="#LegSoc003__Symbol_5_0_Layer0_0_FILL"
                transform="translate(130.3 322.6)"
                id="LegSoc003__col1s"
            />
        </g></g>
    )
}

export default LegSoc003
