import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function AccJew043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24 17.05q2.3-3.6 1.35-6.75Q24.95 9 24 7.8H13.85q.05-.4.05-.85V6.8q-.05-1.8-.85-3.25-.5-.85-1.2-1.55-2-2-4.9-2Q4.05 0 2 2 .05 4 0 6.75v.2Q0 8.6.65 10q.5 1 1.35 1.85.45.45 1 .85-.55.35-1 .8-2 2.05-2 4.95 0 2.9 2 4.9 2.05 2.05 4.95 2.05 2.9 0 4.9-2.05 2.05-2 2.05-4.9 0-.75-.1-1.4H24m41.25-13.5Q64.8 2.7 64.1 2q-2.05-2-4.95-2-2.9 0-4.9 2-.7.7-1.2 1.55-.8 1.45-.85 3.25v.15q0 .45.05.85H42.1q-.95 1.2-1.35 2.5-.95 3.15 1.35 6.75h10.2q-.1.65-.1 1.4 0 2.9 2.05 4.9 2 2.05 4.9 2.05 2.9 0 4.95-2.05 2-2 2-4.9 0-2.9-2-4.95-.45-.45-1-.8.55-.4 1-.85.7-.7 1.15-1.5.85-1.5.85-3.4V6.8q-.05-1.8-.85-3.25z"
                    id="AccJew043__Symbol_111_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M25.35 10.3Q24.95 9 24 7.8H13.85q.05-.4.05-.85V6.8q-.05-1.8-.85-3.25-.5-.85-1.2-1.55-2-2-4.9-2Q4.05 0 2 2 .05 4 0 6.75v.2Q0 8.6.65 10q-.85-3.15.75-5.1.4-.5.9-.85.35-.3.7-.5 3.6-1.85 7.45 0 .5.3 1 .7 1.9 2 1.6 6.05h12.3M63.4 3.55q.45.25.85.6 2.5 2.6 1 6.2.85-1.5.85-3.4V6.8q-.05-1.8-.85-3.25Q64.8 2.7 64.1 2q-2.05-2-4.95-2-2.9 0-4.9 2-.7.7-1.2 1.55-.8 1.45-.85 3.25v.15q0 .45.05.85H42.1q-.95 1.2-1.35 2.5h12.3q-.05-.1-.1-.25-.2-4 1.9-5.9.4-.35.85-.6 3.85-2.05 7.7 0z"
                    id="AccJew043__Symbol_71_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew043__Symbol_111_0_Layer0_0_FILL"
                transform="translate(159.45 188.35)"
                id="AccJew043__col1n"
            />
            <use
                xlinkHref="#AccJew043__Symbol_71_0_Layer0_0_FILL"
                transform="translate(159.45 188.35)"
                id="AccJew043__col1d"
            />
        </g></g>
    )
}

export default AccJew043
