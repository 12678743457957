import { AssetSvgProps } from "../../../shared/assets"

function ShiMot040({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot040__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#0040D4"
                        d="M159.2 282.05v6h66.5v-6q-33.522-4.084-66.5 0z"
                    />
                    <path
                        fill="red"
                        d="M159.2 258v6q34.098 3.22 66.5 0v-6h-66.5z"
                    />
                    <path
                        fill="#FF0"
                        d="M159.2 270v6.05q33.7 3.145 66.5 0V270q-33.506-2.565-66.5 0z"
                    />
                </g>
                <g id="ShiMot040__Layer1_0_MEMBER_1_FILL">
                    <path fill="#009C2D" d="M159.2 276.05v6h66.5v-6h-66.5z" />
                    <path fill="#F90" d="M159.2 264v6h66.5v-6h-66.5z" />
                </g>
                <path
                    fill="#9B00C2"
                    d="M159.2 284.8v9.3h66.5V258h-55.45v26.8H159.2z"
                    id="ShiMot040__Layer1_0_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot040__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot040__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot040__Layer1_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ShiMot040
