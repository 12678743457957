import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.2 13.8q-.15.05-.35.1-4.15-3.6-9.75-3.55Q9.35 10.3 5.2 14q-.05.05-.1.15-.2.1-.35.25-.05 0-.05.05l-.05.05-.2.2q-.25.25-.45.5-.05 0-.05.05l-.05.05-.3.3Q0 19.75 0 25.35q0 6.2 4.45 10.6 4.4 4.4 10.65 4.4 2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.25-3.25 4.1-7.45.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45T32.4 15.9q-1.65-1.65-3.8-2.1-.3-.05-.55-.1-.25-.05-.5-.05-.15-.05-.35 0-.15-.05-.35 0h-.4q-.7.05-1.25.15M170.05 0q-2.15 0-3.65 1.5-.65.7-1 1.5l-.1.2q-.15.35-.25.8l.05 7.8q-5.25-2.25-10.4 0l-.1-7.55q-.05-.4-.2-.8-.1-.25-.15-.45-.4-.8-1.05-1.5-1.5-1.5-3.65-1.5t-3.65 1.5q-.65.7-1 1.5-.35.75-.45 1.6v8.6h.05l.2 11.15h.05q-.1 1.95.4 3.95l.1.5q.75 2.8 2.35 4.95.05.05.1.15.05.05.1.15l.05.05q.05.05.1.15.25.3.55.6l.6.7q.05.05.15.1l.05.05q1.3 1.25 3 2.2.65.4 1.4.75l.05.2q1.4.65 2.85 1 3.5.8 7.35-.2 2.3-.6 4.2-1.8 2.6-1.65 4.35-4.3.05-.05.1-.15 0-.05.9-2.2.65-1.45 1-2.9.25-1.15.35-2.25l.2-22.05q-.1-.55-.3-1-.4-.8-1.05-1.5-1.5-1.5-3.65-1.5z"
                    id="BodHan012__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.5 7.75q0-3.2-2.25-5.45Q11.6.65 9.45.2 9.15.15 8.9.1 8.65.05 8.4.05q-.15-.05-.35 0Q7.9 0 7.7.05h-.4Q6.6.1 6.05.2 5.9.25 5.7.3q-1.95.45-3.45 2Q0 4.55 0 7.75t2.25 5.45q1.7 1.7 3.95 2.1l1.5.15h.1l.75-.05q1.15-.1 2.15-.5.05-.05.1-.05l.3-.1q1.15-.55 2.15-1.55 2.25-2.25 2.25-5.45M12.45 3q1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-.85.9-1.85 1.4-1 .45-2.05.55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-2-1.95-1.95-4.7Q1.05 4.95 3.05 3q1.45-1.45 3.3-1.8.5-.1 1-.15H7.8q.4 0 .85.1 2.15.25 3.8 1.85z"
                    id="BodHan012__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan012__Symbol_26_0_Layer0_0_FILL"
                transform="translate(105.05 270.25)"
                id="BodHan012__col1n"
            />
            <use
                xlinkHref="#BodHan012__Symbol_36_0_Layer0_0_FILL"
                transform="translate(124.2 283.85)"
                id="BodHan012__col1d"
            />
        </g></g>
    )
}

export default BodHan012
