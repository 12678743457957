import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF5D5D",
    "col1n": "#FF0000",
    "col2n": "#FFFFFF"
}

function ObjMor064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M55.8 30.6L51.1 2.85q-.3-1.1-1.7-1.9Q47.6 0 45.05 0h-.7q-2.55 0-4.4.95-1.35.8-1.7 1.9l-4.6 27.75q7.2 9.85-3.35 19.65L27.35 67.9v.05l-.35 2q10.95 9.15-3.1 18.25v.05l-1.5 7.25-.2.05q-.4.1-.5.15-.2.1-.35.15-1.85.65-3.35 1.4-4.05 2.05-5.45 4.5l-7.6 2.4q-4.65 1.3-4.85 2.9-.3 1.55.2 2.7 0 .1.05.2.3.8 1.65 1.3l40.5 13.45q2.25.6 4.55-.05l40.35-13.4q1.3-.5 1.6-1.3.1-.1.1-.2.5-1.15.2-2.7-.2-1.6-4.85-2.9l-7.6-2.4q-1.35-2.45-5.5-4.5-1.45-.75-3.25-1.4.2.1.45.2l-1.5-.55-1.55-7.25v-.05q-21.85-7.15-3.35-18.1.15-.1.25-.15l-.3-1.85-.05-.15v-.05L59.1 50.25Q45.95 43.1 55.8 30.6z"
                    id="ObjMor064__Symbol_157_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M73.75 5.9q0-.4-.05-.7v-.1q-.45-1.9-5.25-4.55L66.95 0l1.4 6.9h.05q.15.25.15.5v.2q0 .05-.1.1-.4 1.65-5.75 2.95-.05.05-.2.05-.15 0-.35.1-.2 0-.4.05-.25.1-.45.15-4 .8-8.9 1.15l-6.2.3H43.05l-6.25-.3q-4.95-.35-8.9-1.15-.25-.05-.5-.15-.15-.05-.35-.05-.2-.1-.35-.1-.15 0-.25-.05-5.25-1.3-5.7-2.95-.05-.05-.05-.1v-.05-.15q0-.25.1-.5L22.3 0l-.2.05q-.4.1-.5.15-.2.1-.35.15Q16 3.1 15.55 5.1v.1q-.1.3-.1.7 0 4.15 8.8 7.15 7.9 2.7 18.8 3v.05h1.55q.8 0 1.6-.05 10.8-.3 18.7-3 8.85-3 8.85-7.15m15.45 5.65q-.2-1.6-4.85-2.9l-7.6-2.4q.75 1.3.75 2.65 0 5.1-9.9 8.75-9 3.25-21.4 3.6H43.05v-.05q-12.45-.3-21.45-3.55Q11.75 14 11.75 8.9q0-1.35.7-2.65l-7.6 2.4Q.2 9.95 0 11.55l40.85 13.5q3.9 1.15 7.65 0l40.7-13.5z"
                    id="ObjMor064__Symbol_108_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.85 40.75H7.5q-.15-.05-.25-.05-.2-.05-.3-.05-.1-.05-.15-.05-2.65-.55-3.7-1.25L0 57.6v.05q0 .05.05.15.35 1.35 5 2.4l.2.1h.3q.2 0 .35.05l.4.1q5.5 1.05 12.95 1.2h3.15q7.4-.15 12.9-1.2.15-.05.35-.1.25-.05.35-.05h.35l.2-.1q4.6-1.05 5-2.4.05-.1.05-.15v-.05l-3.1-18.25q-1.1.7-3.7 1.25-.05 0-.15.05-.2 0-.3.05-.15 0-.3.05h-.3q-4.85.9-11.3 1.1H19.1q-6.45-.2-11.25-1.1M29.4.5q-3 .5-7 .55h-3.15Q15.2 1 12.15.5h-.55q-.05-.05-.15-.05-1.1-.2-1.7-.45L6.4 19.65q.85.5 2.9.95h.4q.05 0 .25.05.1.05.25.05 3.85.65 9.05.8h3.15q5.2-.15 9-.8.15 0 .25-.05.15-.05.3-.05h.3q2.1-.45 2.95-.95L31.9 0q-.65.25-1.8.45-.05 0-.1.05H29.4z"
                    id="ObjMor064__Symbol_45_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor064__Symbol_157_0_Layer0_0_FILL"
                transform="translate(284.7 239.15)"
                id="ObjMor064__col1n"
            />
            <use
                xlinkHref="#ObjMor064__Symbol_108_0_Layer0_0_FILL"
                transform="translate(284.8 334.65)"
                id="ObjMor064__col1l"
            />
            <use
                xlinkHref="#ObjMor064__Symbol_45_0_Layer0_0_FILL"
                transform="translate(308.6 269.75)"
                id="ObjMor064__col2n"
            />
        </g></g>
    )
}

export default ObjMor064
