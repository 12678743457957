import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF9D43",
    "col1n": "#FF7B00",
    "col2l": "#FFFFFF",
    "col2n": "#EEEEEE"
}

function ObjFoo056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.6}
                    d="M.1 25q-.05.15-.1.35v.15q0 1.55 3.25 2.65 3.35 1.15 8.1 1.15 4.7 0 8-1.15 3.35-1.1 3.35-2.65v-.25q-.05-.15-.1-.25V0H.1v25z"
                    id="ObjFoo056__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M266.5 294.2q-4.75 0-8.1 1.1-2.7.95-3.15 2.2-.05.15-.1.35v.15q0 1.55 3.25 2.65 3.35 1.15 8.1 1.15 4.7 0 8-1.15 3.35-1.1 3.35-2.65v-.25q-.05-.15-.1-.25-.5-1.25-3.25-2.2-3.3-1.1-8-1.1z"
                    id="ObjFoo056__Symbol_118_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M19.75 3.8q.05 0 .1-.05.45-.9.15-1.85-.25-.95-1.25-1.55-.85-.55-1.9-.25-1.05.25-1.5 1.05l-.05.1q-.05.15-.05.3l-2.9 5q.25-.95-.3-1.8-.55-.8-1.3-1.15-.05-1.1-.8-1.75-.8-.8-1.85-.75-1 .05-1.7.6l-.05.1q-.15.1-.2.25l-.25.25-2.35-.65q-.1-.05-.3-.1H3q-.9-.15-1.65.3-1 .55-1.3 1.6-.2 1 .3 1.95.45.8 1.3 1.1l-.4.4q-.05.15-.25.2v.05q-.15.05-.2.1-.5.7-.45 1.65Q.3 10 .9 10.6q-.25.45-.2 1.05 0 .05-.1.15l.95 7.55q0 .05.05.15.15.95.95 1.6.25.2.5.3-.45.3-.7.7-.5.9-.2 2.05.35 1 1.2 1.55.8.45 1.7.35h.25l7.3-2.1q.05 0 .15-.05.1-.05.25-.05l2.95 1.65q.05.05.2.05.8.5 1.8.2 1.05-.25 1.5-1.35.6-.8.3-1.85-.3-.85-1.1-1.45-.05-.05-.05 0-.15-.1-.4-.1l-5.6-3.35 5.6-.05q.05.05.2 0 .95-.05 1.7-.8.65-.7.6-1.85.15-1.05-.65-1.75-.5-.5-1.2-.65.7-.3 1.05-1.05.5-.95.2-2-.2-1.05-1.1-1.55-.75-.35-1.45-.3l2.2-3.85m-8.7 4.45q.15-.05.25-.1-.2.55-.1 1.2l-1.1.25q-.15 0-.25.05H9.8q-.2 0-.3.05-.85.4-1.3 1.1-.55.9-.2 2.05.15.55.45.95-.3.55-.3 1.3 0 1.05.75 1.85.25.2.55.4-.6.3-.95.95-.35.8-.3 1.6l-1.85.45q.4-.7.4-1.5 0-.05-.05-.1 0-.2-.1-.3l-.75-6.8q.05-.15 0-.3v-.05q0-.15.05-.25-.2-.55-.55-1l2.05-2 1.75.45h.15q.95.3 1.75-.25z"
                    id="ObjFoo056__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M24.2 4.1v-.3-.4H1.25q-.05.2-.05.35V7.4q-.63.69-.9 1.1-.27.41-.3.6v5.3q0 .15.1.3v.05q.2.85 1.15 1.45.9.55 2.5 1.05 3.7 1.25 8.8 1.25 5.3 0 9-1.25 1.75-.5 2.65-1.15.8-.6.95-1.35l.05-.05v-.3q0-.25-.05-.4V9.45l.05-.1v-.2q-.004-.255-.15-.5L24.2 7.5V4.1z"
                    id="ObjFoo056__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M24.2 8.65v.3q-.35 1.4-3.3 2.3-3.45 1.15-8.2 1.15-4.7 0-8.15-1.15-3-.9-3.3-2.3V8.9q-.05-.15-.05-.25V7.4Q.3 8 .1 8.7q-.1.2-.1.4v.05q0 .1.1.2v.1q.2.8 1.15 1.5.7.45 1.75.8l.75.25q3.7 1.3 8.8 1.3 5.3 0 9-1.3.4-.15.7-.25 1.2-.45 1.95-.9.8-.65.95-1.4l.05-.1v-.2q0-.25-.05-.45-.15-.65-.95-1.2v1.15m0-5.25q-.35-1.3-3.3-2.3Q17.45 0 12.7 0 8 0 4.55 1.1q-3 1-3.3 2.3-.05.2-.05.35v.05q0 .1.05.3v.05Q1.5 5.4 3.8 6.2h.05q.35.15.7.25Q8 7.6 12.7 7.6q4.75 0 8.2-1.15.35-.1.7-.25 2.3-.8 2.6-2.05V4.1v-.3-.4z"
                    id="ObjFoo056__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.302}
                    d="M265.8 291.45q-1.05.25-1.6 1.25-.45.95-.25 1.95.25.9.95 1.45l.2.1 6.55 3.7q.05.05.2.05.8.5 1.8.2 1.05-.25 1.5-1.35.6-.8.3-1.85-.3-.85-1.1-1.45-.05-.05-.05 0-.15-.1-.4-.1l-5.9-3.55q-.1-.1-.2-.1l-.05-.05q-.15-.1-.2-.15-.9-.4-1.75-.1m10-7.55q-.2-1.05-1.1-1.55-.95-.45-1.85-.3-.05 0-.15.05-.15.05-.25.2l-6.65 1.7q-.15 0-.25.05h-.05q-.2 0-.3.05-.85.4-1.3 1.1-.55.9-.2 2.05.25 1 1.15 1.55.85.5 1.7.3h.2l7.3-2q.1 0 .15-.05.95-.25 1.4-1.15.5-.95.2-2m-15.2 0q-.85-.6-1.95-.45-1.1.1-1.7 1.05-.65.6-.55 1.55 0 .05-.1.15l.95 7.55q0 .05.05.15.15.95.95 1.6.9.65 1.9.5 1.05-.05 1.75-1 .55-.8.55-1.75 0-.05-.05-.1 0-.2-.1-.3l-.75-6.8q.05-.15 0-.3v-.05q0-.15.05-.25-.3-.85-1-1.55m-1.7-7.95h-.2q-.9-.15-1.65.3-1 .55-1.3 1.6-.2 1 .3 1.95.45.8 1.3 1.1l.2.1 7.3 1.9h.15q.95.3 1.75-.25 1-.5 1.25-1.6.35-1-.25-1.9-.6-.85-1.45-1.25H265.85l-6.6-1.85q-.1-.05-.3-.1h-.05z"
                    id="ObjFoo056__Layer1_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.2}
                    d="M261.6 276.7l-2.35-.65q-.1-.05-.3-.1h-.25q-.9-.15-1.65.3-1 .55-1.3 1.6-.2 1 .3 1.95.45.8 1.3 1.1l-.4.4q-.05.15-.25.2v.05q-.15.05-.2.1-.5.7-.45 1.65-.045 1.076.5 1.7-.212.463-.15 1.05 0 .05-.1.15l.95 7.55q0 .05.05.15.15.95.95 1.6.25.181.5.3-.433.315-.7.7-.5.9-.2 2.05.35 1 1.2 1.55.8.45 1.7.35h.25l7.3-2.1q.05 0 .15-.05.122-.029.25-.1l2.95 1.7q.05.05.2.05.8.5 1.8.2 1.05-.25 1.5-1.35.6-.8.3-1.85-.3-.85-1.1-1.45-.05-.05-.05 0-.15-.1-.4-.1l-5.6-3.4h5.6q.05.05.2 0 .95-.05 1.7-.8.65-.7.6-1.85.15-1.05-.65-1.75-.5-.5-1.2-.7.686-.271 1.05-1 .5-.95.2-2-.2-1.05-1.1-1.55-.746-.354-1.45-.35l2.2-3.8q.05 0 .1-.05.45-.9.15-1.85-.25-.95-1.25-1.55-.85-.55-1.9-.25-1.05.25-1.5 1.05l-.05.1q-.05.15-.05.3l-2.95 5q.32-.944-.25-1.8-.547-.775-1.3-1.2-.044-1.039-.8-1.7-.8-.8-1.85-.75-1 .05-1.7.6l-.05.1q-.15.1-.2.25l-.25.25m-.55 7.7l2-2 1.8.5h.15q.95.3 1.75-.25.127-.063.25-.15-.201.613-.15 1.2l-1.05.3q-.15 0-.25.05h-.05q-.2 0-.3.05-.85.4-1.3 1.1-.55.9-.2 2.05.133.53.45.9-.3.593-.3 1.35 0 1.05.75 1.85.24.222.5.35-.544.352-.9 1-.371.784-.3 1.6l-1.85.45q.4-.7.4-1.5 0-.05-.05-.1 0-.2-.1-.3l-.75-6.8q.05-.15 0-.3v-.05q0-.15.05-.25-.193-.548-.55-1.05z"
                    id="ObjFoo056__Layer1_1_FILL"
                />
            </defs>
            <g id="ObjFoo056__col1n">
                <use
                    xlinkHref="#ObjFoo056__Symbol_118_0_Layer0_0_FILL"
                    transform="translate(255.15 272.5)"
                />
                <use xlinkHref="#ObjFoo056__Symbol_118_0_Layer0_1_FILL" />
            </g>
            <use
                xlinkHref="#ObjFoo056__Symbol_119_0_Layer0_0_FILL"
                transform="translate(255.7 274.4)"
                id="ObjFoo056__col1l"
            />
            <use
                xlinkHref="#ObjFoo056__Symbol_120_0_Layer0_0_FILL"
                transform="translate(253.95 256.25)"
                id="ObjFoo056__col2n"
            />
            <use
                xlinkHref="#ObjFoo056__Symbol_121_0_Layer0_0_FILL"
                transform="translate(253.95 256.25)"
                id="ObjFoo056__col2l"
            />
            <use xlinkHref="#ObjFoo056__Layer1_0_FILL" />
            <use xlinkHref="#ObjFoo056__Layer1_1_FILL" />
        </g></g>
    )
}

export default ObjFoo056
