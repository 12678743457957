import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400",
    "col2n": "#BFBFBF"
}

function ObjToo075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 5.95L1.45 7.7q2.65.55 2.7 3.3l5.75 7.05q.65-.1 1.25-.05 1.85 0 3.65.65 0 .05.1.1.2 0 .45.15.55.25 1.05.6 3.85 2.3 5.9 6.75.2.5.45 1.05l.1.4.2.55q.8 2.9.45 5-.05.35-.15.7-.1.25-.15.45l.55.7q1.2 1.25 3.1 1.4 1.85.25 3.35-.95 1.5-1.25 1.75-3.2.15-1.8-.9-3.2l-1.85-2.3q-.1-.15-.2-.25L11.45 5.05q-2.65-.25-2.7-3.3L7.3 0 0 5.95z"
                    id="ObjToo075__Symbol_240_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M21.4 25.95l.2.55q.8 2.9.45 5 .45.5 1.15.55.55.1 1.05-.3.55-.45.7-1.1-.05-.45-.25-.85l-3.4-4.25.1.4M8.15 9.35q-.45-.55-1.1-.65-.6-.05-1.1.35-.55.45-.65 1.05 0 .5.2.85l2.95 3.75.1.1 1.15 1.45q1.85 0 3.65.65 0 .05.1.1.2 0 .45.15.55.25 1.05.6l-6.8-8.4M10 3.3Q7.35 3.05 7.3 0L0 5.95q2.65.55 2.7 3.3L10 3.3m1.5 3.3q-.45-.55-1.15-.65-.55 0-1.1.45-.5.4-.65 1 .05.5.25.85l2.95 3.7.1.1L25.4 28.8q.45.5 1.1.55.6.1 1.1-.35.55-.45.65-1.1 0-.4-.2-.8L11.5 6.6z"
                    id="ObjToo075__Symbol_239_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.1 10l-2-5.3L2.95 0 0 2.4l3.35 7 4.8 3 25.6 31.4 2.95-2.4L11.1 10z"
                    id="ObjToo075__Symbol_238_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo075__Symbol_240_0_Layer0_0_FILL"
                transform="translate(102.35 275.2)"
                id="ObjToo075__col1n"
            />
            <use
                xlinkHref="#ObjToo075__Symbol_239_0_Layer0_0_FILL"
                transform="translate(103.8 276.95)"
                id="ObjToo075__col1d"
            />
            <use
                xlinkHref="#ObjToo075__Symbol_238_0_Layer0_0_FILL"
                transform="translate(70.8 235.55)"
                id="ObjToo075__col2n"
            />
        </g></g>
    )
}

export default ObjToo075
