import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#00006D",
    "col2n": "#0000BB"
}

function AccHat063({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M155.55 26.1q.7-1.9 1.2-3.6l.1-.5q.15-.6.25-1.15Q161.2 2.5 115.75 5.1H114q-5.55 1.25-14.55 2.05-7.9.7-13.5.7-9.1 0-17.7-1.7T56.3 4.1q-3.4-.35-6.05-1.1-2.7-.75-3.15-.65h-.25q-18.4-4.3-31.3-.8Q2.6 4.95.75 11.15-1.05 17.3 1.4 24q.3 1.4 1 3.15 1.2 3.25 3.7 7.6 6.45 22.65 45.7 26.4 7.25 1.2 17.65 2.2 8.7.85 6.8.8 55.25 3.1 70.45-24.2 3.5-3.8 6.25-8.35 1.55-2.9 2.6-5.5z"
                    id="AccHat063__Symbol_281_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M131.35 8q-5.3-2.3-6.15-2.65-.85-.35-7.2-.9-6.35-.55-20.45 3.1-14.1 3.6-27.45 4.65-13.4 1.05-23.7-2.55Q29.2 2.45 17.9.8 6.55-.9 0 1.05h2.5q11.1.9 16.65 2.1.15.05 3.7.55 3.55.45 14.05 4.85 4.85 2.05 8.85 3.35 4.6 1.5 8.05 1.95 6.35.85 14.65.6 8.25-.3 19.75-2.5 2.15-.4 4.05-.75 8.25-1.6 11.65-2.45 4.15-1 10.7-1.85 4.6-.65 10.95.15 2.75.35 5.8.95z"
                    id="AccHat063__Symbol_187_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M203.4 15.85l-.05-10.65Q192 17.5 172.9 22q-14.75 4.25-33.15 4.85-2.7.05-5.5.05-28.65 0-48.9-8.5-4.45-1.85-7.9-3.85-4.95-2.9-7.85-6.1-2.95-2.75-5.05-5.6-.65-.9-1.15-1.75-.35-.55-.65-1.1l-2.1 2q-.95.9-1.9 1.75Q30.45 29.5 0 40.15q5.45 1.8 13.75 1.75L5.5 52.95Q28.2 44 42.6 31.55q3.85-3.4 7.15-7-12.2 21.2-37.6 46.35 8.45-3 15.35-5.7l-3 14.85q21.8-25.65 36.15-54.8 1.45-2.9 2.8-5.85 2.3 10.15 20.2 17.65 20.55 8.6 49.6 8.6t49.6-8.6q20.2-8.45 20.55-20.4v-.4-.4z"
                    id="AccHat063__Symbol_92_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M20.85 19.4q-.35-1.55-.35-3.15v-.1q0-1.4.3-2.75l1.15-10.55q-.65-.9-1.15-1.75-.35-.55-.65-1.1l-2.1 2q-.95.9-1.9 1.75Q9.35 17.65 0 31.55q3.85-3.4 7.15-7 6.6-10.65 10.9-18.4-1.95 7.45 0 19.1 1.45-2.9 2.8-5.85z"
                    id="AccHat063__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat063__Symbol_281_0_Layer0_0_FILL"
                transform="translate(117.45 34.2)"
                id="AccHat063__col1n"
            />
            <use
                xlinkHref="#AccHat063__Symbol_187_0_Layer0_0_FILL"
                transform="translate(129.1 55.6)"
                id="AccHat063__col1d"
            />
            <use
                xlinkHref="#AccHat063__Symbol_92_0_Layer0_0_FILL"
                transform="translate(60.8 68.95)"
                id="AccHat063__col2n"
            />
            <use
                xlinkHref="#AccHat063__Symbol_30_0_Layer0_0_FILL"
                transform="translate(103.4 68.95)"
                id="AccHat063__col2d"
            />
        </g></g>
    )
}

export default AccHat063
