import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiSho007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M88.1 8.9l1.45 6.4L40.7 0l2.95 11.2L17.5 5.25l11.55 14.4-29.05.9 23.7 20.8-4.75-8.4L57 46.45l-15.8-15.5 33.35 5.15-10.35-8.6 60.4 6.45L88.1 8.9z"
                    id="HaiSho007__Symbol_128_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho007__Symbol_128_0_Layer0_0_FILL"
                transform="translate(125.75 43.3)"
                id="HaiSho007__col1n"
            />
        </g></g>
    )
}

export default HaiSho007
