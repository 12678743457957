import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFD95B",
    "col1n": "#FFC400"
}
function FacMou076({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.1 2.9Q24.1.05 18.5 0 12.65-.05 7.25 2.9q-.65.4-1.2.8-.85.6-1.5 1.25-9.05 12.65 0 25.15l14.1 19.55 14.1-19.55q9.05-12.5 0-25.15-.65-.65-1.5-1.25-.55-.4-1.15-.8z"
                    id="FacMou076__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M7.05 7.95Q6.3 3 4.35.7 3.8.3 3.3.1 3.25.05 3.15.05 3 0 2.75 0t-.4.05Q2.3.05 2.2.1q-.45.2-1.05.6Q-.8 3-1.5 7.95q-.2 2.25-.1 5.25.15 2 .4 4.5v.4q.3 2.5.45 4.4 0 .2.1.4.1 1.5.4 3.05.15 1 .4 2.35.15.85.3 1.85.5 2.45 2.3 5.2 1.85-2.75 2.3-5.2.15-1 .3-1.85.25-1.35.4-2.35.35-1.55.5-3.05v-.4q.15-1.9.45-4.4v-.4q.25-2.5.4-4.5.1-3-.05-5.25z"
                    id="FacMou076__Symbol_172_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou076__Symbol_101_0_Layer0_0_FILL"
                transform="translate(173.9 195.2)"
                id="FacMou076__col1n"
            />
            <use
                xlinkHref="#FacMou076__Symbol_172_0_Layer0_0_FILL"
                transform="translate(189.75 196.8)"
                id="FacMou076__col1l"
            />
        </g></g>
    )
}
export default FacMou076
