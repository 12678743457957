import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C0A594",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}
function PlaFor039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0v578h578V0H0m421.5 137.05V416H154V137.05h267.5z"
                    id="PlaFor039__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M27.8 18.75q1.07.028 2.1-.1 4.85-.55 6.05-3.6Q37.1 12 33.9 8.3q-3.2-3.65-8.85-5.8Q19.4.3 14.6.85q-4.85.6-6 3.65-1.2 3.05 2 6.7.217.255.45.5-.2 3.85-2.1 7.7-1.6 0-2.5.1-3.95.45-5.5 2.6-.35.45-.6 1.05-1.25 3.1 1.9 6.9 1.8 2.25 4.55 4 .75.45 1.55.85 1.2.65 2.55 1.2.05.05.15.05l2.15.8q.25.05.55.1.75.25 1.55.4v.05q3.35.7 6.35.35 4.9-.5 6.15-3.6.2-.6.3-1.2.3-2.65-2.25-5.75-.5-.65-1.95-1.35 1.647-4.359 3.9-7.2z"
                    id="PlaFor039__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.95 19.4q-.35.45-.6 1.05-1.25 3.1 1.9 6.9 1.8 2.25 4.55 4 .75.45 1.55.85 1.2.65 2.55 1.2.05.05.15.05l2.15.8q.25.05.55.1.75.25 1.55.4v.05q3.35.7 6.35.35 4.9-.5 6.15-3.6.2-.6.3-1.2-1.55 2.1-5.55 2.5-3.7.45-7.85-.75-1.3-.4-2.7-.95l-.2-.1q-1.35-.5-2.55-1.2-.9-.45-1.65-.95-2.65-1.7-4.45-3.85-2.5-3.1-2.2-5.65m34.95-7.05q.25-.6.35-1.2-1.6 2.05-5.35 2.55h-.3q-3.8.45-8-.8-1.4-.4-2.75-.95l-.2-.1q-1.45-.5-2.65-1.2-2.068-1.083-2.55-1.35-2.122-1.568-3.2-3.25-.1-.1-.2-.25Q9 2.6 9.6.1q-.45.45-.75 1.05-1.6 3 1.2 7.15.45.65.9 1.35 0 .1-.1.85l.1-.55q-.366 3.4-2.2 6.85l-1.2 3.1q1.1 3.2 3.65 4.8 1.2.8 2.7 1.2l.2.1q1.4.75 2.85 1.05 2.95.65 5.95-.95l1.3-3.05q1.29-3.96 3.35-6.7l-.1.3q.45-.35.55-.45.8-.05 1.6-.15 5-.55 6.3-3.7z"
                    id="PlaFor039__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.251}
                    d="M337.15 40.55h-10.9V54.2l-3.7-.1q18.296 229.69-8.8 292H56.4v5.65l-12.8-.35-.4 13 280.65 8.45.35-11.4h12.95V40.55m-12.1 305.55h-.4q.316-.746.4 0z"
                    id="PlaFor039__Layer1_0_FILL"
                />
                <path
                    fill="#DEDEDE"
                    d="M59.5 41.1l-13.1-.35-8.4 320.8 280.6 7.35 1.55-59.9h-12.6l-.15 7L52 309.3l6.65-255.45h.85V41.1z"
                    id="PlaFor039__Layer1_1_FILL"
                />
                <path
                    fill="#FFF"
                    d="M332.9 357.65V36.75H52.15v320.9H332.9M64.75 49.55H320.3V305.1H64.75V49.55z"
                    id="PlaFor039__Layer1_2_FILL"
                />
                <path
                    fillOpacity={0.251}
                    d="M246.45 25.2q-.2-.85-.35-1.7-2.85-8.8-10.4-14.35-.7-.5-1.55-.9-3.6-1.75-6-.75-.65.2-1.1.6-2.75 1.95-2.05 6.8 0 .75.15 1.35-2.2 3.15-6.45 5.1-.8-.45-1.6-.85-1.265-.642-2.3-.95 3.096 4.157 2 6.1.172-.126.3-.3-.1.6-.3 1.2-1.25 3.1-6.15 3.6-1.002.117-2.05.1.431 1.366 1.1 2.75l.8 1.5-21.8 16.55q.1 2.55 2.55 3.5l22.7-15.4q.5.65 1.1 1.2 2.45 2.4 5.15 3.7 4.45 2.15 7.15.25.5-.4.9-.9 1.65-2.1 1.15-6.05-.1-.85-.3-1.6 3.2-3.45 6.7-4.6.7.35 1.45.7 4.4 2.2 7.05.2.6-.3 1-.8 1.65-2.1 1.15-6.05z"
                    id="PlaFor039__Layer1_3_FILL"
                />
                <path
                    fill="#D9D9D9"
                    d="M202.2 29.25l-2.15-.8q-.1 0-.15-.05-1.35-.55-2.55-1.2l-9.65 25.65q1.45 2.1 4 1.6l11.05-25.1q-.3-.05-.55-.1z"
                    id="PlaFor039__Layer1_4_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor039__Symbol_79_0_Layer0_0_FILL"
                transform="translate(-96.5 -96.5)"
                id="PlaFor039__col1n"
            />
            <use
                xlinkHref="#PlaFor039__Symbol_80_0_Layer0_0_FILL"
                transform="translate(189 -7.7)"
                id="PlaFor039__col2n"
            />
            <use
                xlinkHref="#PlaFor039__Symbol_81_0_Layer0_0_FILL"
                transform="translate(189 -5)"
                id="PlaFor039__col2d"
            />
            <use xlinkHref="#PlaFor039__Layer1_0_FILL" />
            <use xlinkHref="#PlaFor039__Layer1_1_FILL" />
            <use xlinkHref="#PlaFor039__Layer1_2_FILL" />
            <use xlinkHref="#PlaFor039__Layer1_3_FILL" />
            <use xlinkHref="#PlaFor039__Layer1_4_FILL" />
        </g></g>
    )
}
export default PlaFor039
