import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#830000",
    "col2n": "#FBDD0F"
}

function AccHat147({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M200.8 107.25l-.1-3.55Q189.45 50.35 168.1 0q-4.2 1.55-8.4 2.9l-1.05.35q-58.05 19.2-112.2.05-.55-.2-1.05-.4h-.1Q41.1 1.55 36.9 0 14.4 53.05 3.15 109.4q-.15 2.4-.25 4.85v.6l.15 17.05q.25 6.95 1 13.85.4 3.65.95 7.3.3 3.25.45 6.25 0 .15.05.3 0 .6.05 1.25v.5q0 .85.05 1.65.05 6.55-.9 11.65-.3 1.45-.65 2.85L0 203.35q15.1-2.4 27.6 4.75 11.5 7.6 32.8 14.3 40.2 6.7 76.6 0 18.5-4.95 32.3-13.8 16.15-6.55 32.4-4.35l-5.75-31.75q-.35-2-.25-5.35v-1q0-.25.05-.55 0-.35.05-.7 0-.65.05-1.3 0-.3.05-.65.25-2.95.7-6.6 2.15-11.05 3.1-22.65l1.1-25.55v-.2-.7m-99.55-47.5q32.4 0 55.3 20.45 22.95 20.45 22.95 49.35 0 28.95-22.95 49.35-22.9 20.45-55.3 20.45T45.9 178.9Q23 158.5 23 129.55q0-28.9 22.9-49.35 22.95-20.45 55.35-20.45z"
                    id="AccHat147__Symbol_489_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M201.6 1.5q-16.05-2.1-32.05 4.35-13.8 8.8-32.3 13.8-36.4 6.7-76.6 0-21.3-6.75-32.8-14.3Q15.45-1.7.5.55L0 3.85Q15.25 1.4 27.85 8.6q11.5 7.6 32.8 14.3 40.2 6.7 76.6 0 18.5-4.95 32.3-13.8 16.3-6.6 32.65-4.3l-.6-3.3z"
                    id="AccHat147__Symbol_366_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat147__Symbol_489_0_Layer0_0_FILL"
                transform="translate(91.65 43.5)"
                id="AccHat147__col1n"
            />
            <use
                xlinkHref="#AccHat147__Symbol_366_0_Layer0_0_FILL"
                transform="translate(91.4 244.5)"
                id="AccHat147__col2n"
            />
        </g></g>
    )
}

export default AccHat147
