import { AssetSvgProps } from "../../../shared/assets"

function ShiMot082({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 257.8v35.8h66.5v-35.8h-66.5z"
                    id="ShiMot082__Layer5_0_FILL"
                />
                <g id="ShiMot082__Layer5_1_FILL">
                    <path
                        fill="red"
                        d="M159.2 275.75v17.85h66.5v-17.85h-66.5z"
                    />
                    <path
                        fill="#0000A4"
                        d="M159.2 257.8v17.95h18.6V257.8h-18.6m8.95 3.65l1.05 3.3h3.35l-2.7 1.95 1 3.25-2.7-2-2.8 1.95 1.05-3.2-2.7-2h3.3l1.15-3.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot082__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot082__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot082
