import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M105.35 24.4q-1.2-4.7-3.8-8.85-1.7-2.75-4.05-5.3-.15-.2-.3-.35Q88.85.9 77.85.1 66.8-.75 59.55 7q-5.35 5.75-6.3 13.7-1-7.95-6.35-13.7Q39.65-.75 28.65.1 17.6.9 9.25 9.9q-.3.3-.55.65l-.3.3q-5 5.65-7 12.4Q.35 26.8.1 30.65q-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9 3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.2-3.2-1-6.2z"
                    id="FacEye003__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1 5.6q.95 1 2.4 1.05Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35 0 1.4 1 2.35m59.35-2.35q0-1.35-1-2.35-.9-.95-2.3-.9-1.45-.05-2.45.9-1 1-1 2.35 0 1.4 1 2.35 1 1 2.45 1.05 1.4-.05 2.3-1.05 1-.95 1-2.35z"
                    id="FacEye003__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacEye003__col1n" transform="translate(139.3 136.2)">
                <use
                    xlinkHref="#FacEye003__Symbol_1_0_Layer0_0_FILL"
                    id="FacEye003__col1n_FL"
                />
            </g>
            <g id="FacEye003__col2n" transform="translate(142.85 163.45)">
                <use
                    xlinkHref="#FacEye003__Symbol_6_0_Layer0_0_FILL"
                    id="FacEye003__col2n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacEye003
