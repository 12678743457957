import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#EEAA00",
    "col2n": "#FFC400"
}

function LegSki017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.65 0q-.05.25-.1.45h.1V0m18.1 2.6q-.05.1-.05.15h.05V2.6m16.8 3.25h.05l.05-.4-.1.4m15.15.25h-.1q0 .15.05.4l.05-.4m7.85.2h-.05v.15q.003-.103.05-.15m32.3-3.5l-.05-.05q-.05 0-.1.05h.15m21.45-1.15l-2.5.6q-.85.15-1.65.35-.65.15-1.25.3-.4.05-.75.15-1.95.45-3.85.85l-4.5.9q-2.25.4-4.45.8-4.45.75-8.85 1.35l-3.5.5q-1.45.2-2.2.25-3.35.4-6.7.75-1.05.05-2.1.2-11.6.95-23.15.95h-.3q-10.4 0-20.8-.8-.85-.1-1.7-.15-6-.55-12-1.3-1.7-.25-3.45-.45-2.9-.45-5.75-.9l-3.1-.5-1.6-.3q-2.2-.4-4.4-.85-2.2-.4-4.3-.85-1.5-.35-3-.65-.15-.05-.25-.1-.4-.05-.75-.15-.3-.05-.55-.1-1.7-.4-3.35-.8-.1 0-.15.05L.55 16.1H.5L0 18.35q3.5-.9 7.2-1.45.15 0 .35-.05.5-.1 1-.15 1.8-.25 3.65-.45 5.15-.5 10.55-.4 1 0 2.05.05.4 0 .8.05 2.7.1 5.5.4 1.05.1 2.15.25l34 6.25q2.75.35 5.45.55 1.1.1 2.15.15 14.6.8 27.65-2.7 2.85-.8 5.7-1.8.25-.1.55-.2 2.55-.4 5.05-.65 8.65-.85 16.85.55.1.05.2.05 1.6.3 3.2.65 3.3.75 6.55 1.9l-5.3-19.7z"
                    id="LegSki017__Symbol_83_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.75.05q-.1 0-.15.05L.75 14.45H.7L0 17.6q1.15-.35 2.8-.6 1.75-.25 5.05-.6 0-.05.05-.05.5-1.6.8-3.4 0-.25.05-.5.2-1.45.3-3.05 0-.95.15-3.45.2-2.55.15-3.55 0-.65.05-1.3-.4-.05-.75-.15-.3-.05-.55-.1-1.7-.4-3.35-.8m26.3 12.25v-1.05q-.1-.35-.2-.65-.85-2.3-2.5-2.3-1.55 0-2 1.25-.15.3-.2.75-.05.75-.05 1.6v.05q.05.05.05.25v.2q-.1.95-.25 3.3h5.05q.15 0 .3.05v-.15q-.1-2.2-.15-3-.05-.2-.05-.3m38.9 4.4q-1.3-3.3-3.55-3.3-.55 0-1.15 1-.3.45-.4.95v.05l-2.15 6.3q2.1.25 5.35 1.05 2.2.4 4.55.7-.85-2.15-1.5-3.95h-.3q-.3-1.45-.85-2.8m37.45-1.05q-.1.05-.15.05-.15-1.25-.7-2.95-1-2.95-2.4-2.95-1.7 0-1.7 4v.7q.05.55.1 1.25V17.15h-.1q.05 1.3.15 3v.1q1.6-.9 4.55-1.55l.9-.2q-.15-.6-.3-1.25 0-.1-.05-.15-.15-.8-.3-1.45M135.5 0l-2.5.6q-.85.15-1.65.35-.65.15-1.25.3.95 4.6.95 6.9 0 2.2-.35 4.2-.25 1.45-.3 2.65-.05.5-.05.95v1.3q0 .25.05.45h.05q3.65.3 7.8 2.25.5.2 2.8 1L135.5 0z"
                    id="LegSki017__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M141.7 10.2l-1-4.85q-.2-.05-.35-.1-2.85-.95-5.7-1.6-1.55-.4-3.1-.65-.4-.05-.75-.1-1.4-.25-2.8-.4-6-.75-12.25-.25-.65.05-1.25.1-2.55.2-5.15.65l-1.4.5q-.1 0-.15.05-.1.05-.15.05-2.2.75-4.35 1.35-.1 0-.15.05-.1 0-.2.05h-.1l-.8.2q-1.15.3-2.3.55-10.45 2.35-21.9 2-.7 0-1.4-.05-2.45-.1-4.9-.35h-.35q-1.7-.2-3.35-.4l-2.65-.5h-.15L33.85.75Q32.8.6 31.75.5h-.25Q29 .2 26.55.1h-.05q-.6-.05-1.2-.05h-.75q-.6-.05-1.2-.05-5.65-.1-11 .45-1.65.15-3.2.4H9.1q-.45.05-.85.1-3.65.55-7.1 1.4H1.1L0 7.15q3.8-1 7.8-1.6.15 0 .35-.05.5-.1 1-.15 1.8-.25 3.65-.45 5.15-.5 10.55-.4 1 0 2.05.05.4 0 .8.05 2.7.1 5.5.4 1.05.1 2.15.25l34 6.25q2.75.35 5.45.55 1.1.1 2.15.15 14.6.8 27.65-2.7 2.85-.8 5.7-1.8.25-.1.55-.2 2.55-.4 5.05-.65 8.65-.85 16.85.55.1.05.2.05 1.6.3 3.2.65 3.55.8 7.05 2.1z"
                    id="LegSki017__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.1 2.25L0 7.05q3.8-1 7.8-1.6l.5-1.3q.5-1.6.8-3.4-.45.05-.85.1-3.65.55-7.1 1.4H1.1M31.65 3.4q-.1-2.2-.15-3Q29 .1 26.55 0v.2q-.1 1.05-.35 4.05v.25q2.7.1 5.5.4v-.6q-.05-.45-.05-.9m41.65 8.55q-1-2.55-1.8-4.65h-.3q-1.7-.2-3.35-.4l-2.65-.5h-.15l-.85-.15-1.4 4.2 5.05.95q2.75.35 5.45.55m34.5-8.5q-.1.05-.15.05-2.2.75-4.35 1.35-.1 0-.15.05-.1 0-.2.05h-.1q.05 1.3.15 3 .05.75.1 1.45 2.85-.8 5.7-1.8-.35-1.15-.65-2.55 0-.1-.05-.15-.15-.8-.3-1.45m33.9 6.65l-1-4.85q-.2-.05-.35-.1-2.85-.95-5.7-1.6-1.55-.4-3.1-.65-.4-.05-.75-.1-.05.5-.05.95v1.3q0 .7.05.85.1.55.45 1.4.1.05.2.05 1.6.3 3.2.65 3.55.8 7.05 2.1z"
                    id="LegSki017__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki017__Symbol_83_0_Layer0_0_FILL"
                transform="translate(122.65 305.45)"
                id="LegSki017__col1n"
            />
            <use
                xlinkHref="#LegSki017__Symbol_46_0_Layer0_0_FILL"
                transform="translate(122.45 307.1)"
                id="LegSki017__col1d"
            />
            <use
                xlinkHref="#LegSki017__Symbol_24_0_Layer0_0_FILL"
                transform="translate(122.05 319.2)"
                id="LegSki017__col2n"
            />
            <use
                xlinkHref="#LegSki017__Symbol_9_0_Layer0_0_FILL"
                transform="translate(122.05 319.3)"
                id="LegSki017__col2d"
            />
        </g></g>
    )
}

export default LegSki017
