import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F"
}

function AccHat058({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M217.45 112.7q-.35 3.8-.2 6.45.55 10.85 8.7 2.9l.35-.35-8.85-81.1v72.1m0-72.1L232.2 37 133.6 0 0 25l39.2 27.75-1.4 2.1-.2.25-9 16.2q1.55.75 3.15 1.5l22.75 8.8q67.55 20.65 136.2-10.85l-9-21.45 35.75-8.7z"
                    id="AccHat058__Symbol_276_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M144.65 1.7L143.9 0 34.25 26.65 1.4 3.45 0 5.55 34.2 29 144.65 1.7z"
                    id="AccHat058__Symbol_133_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat058__Symbol_276_0_Layer0_0_FILL"
                transform="translate(82.55 29.6)"
                id="AccHat058__col1n"
            />
            <use
                xlinkHref="#AccHat058__Symbol_133_0_Layer0_0_FILL"
                transform="translate(120.35 78.9)"
                id="AccHat058__col1s"
            />
        </g></g>
    )
}

export default AccHat058
