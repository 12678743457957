import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiMed005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M183.9 21.85q-1.15-1.05-2.25-2.05l-2.25-1.9Q162.55 3.95 146.1 1.55 139.3-.2 132.8.05q-11.5-.25-23.25 3.8Q97.4-.2 85.9 0 62.8.4 41.6 16.85q-26.15 20-37.3 51-4.4 13.9-4.3 27 .05 11.75 3.8 22.8.2.65.45 1.35.45 1.35.9 2.65 2.1 5.95 4.6 11.2.95 2 1.95 3.85 9.65 17.9 24.8 26.4 1.65.9 2.55 1.2l-5.25-7h8.05q-6.85-12.1-10.8-24.45-1.85-5.6-3.05-11.2-3.95-18.25-1.6-36.95l.1-1v-.05l2.25-11.95v12h8.1l4.5-16.5.45 16.5h11.6l2.5-7.5 2 7.5 4.5-18 .4 18h45.8l1.8-19.5 1.95 19.5h2.85l3.7-14 .95 14h38.55V72.2l4.65 11.5h21.6l2.25-10.5.5 10.5h2.65l.65-14.15q.456 5.412 1.6 15.15 2.35 18.7-1.6 36.95-1.2 5.6-3 11.2-4 12.35-10.85 24.45h8.05l-5.4 7.35 2-.7q15.85-7.65 25.5-27.25.9-1.9 1.8-3.85 1.7-3.95 3.2-8.35.65-1.4 1.25-2.85 1.35-3.35 2.35-6.7l.1-.3q1.7-5.8 2.4-11.65l.5-5.45q.1-1.35.15-2.65.2-7.7-1.05-15.1-6-30.75-30.3-54.75-1.7-1.55-3.5-3.2z"
                    id="HaiMed005__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M164.65 45.1q-1.1-4.35-1.5-5.2-1.3-3.35-4.2-8.35-4.55-8.8-13.9-15.65-5.9-4.55-12.85-8.95-4-2.15-12.55-6.3-.8-.35-1.4-.65 7.3 6.35 11.35 12.3.5 1.15 2.9 5.65-12.95-7.2-16.05-8.9-3.15-1.75-9-4.8 2.85 4.35 3.65 7.15.4 1.1 1.8 6.4l.2.5h-.25Q109 16.15 108 15.35q-1-.8-2.95-2.15-2-1.35-10.95-8.9 4.75 11 5.25 18.5-.1 0-.15-.05-4.95-6.75-9.7-12.9l-.2.15q-.1-.1-.15-.1h-.05l4.6 15.9-.4-1-11.55-13.7L68.6 25.55q1.5-5.75 1.5-6 0-1.4 4.9-10 .45-.8 2.45-3.55 1-1.45 2.4-3.4l-18.3 13.55q-.55.25-1.2.5l6.8-11.35Q59.45 13 56.2 16.05q-3.1 2.95-4.5 4l.8-14.65L37 17.3Q39.75 7.5 49.6.9 12 17.1 0 47.35q2.75-3.05 4.3-4.75 14.25-16.8 22.15-19.7-7.15 7.55-9.7 15.85 0 .35.1.35.2 0 .4.05 4.6-4.05 13.6-10.1-6.4 11.8-6.5 29.55 4.75-19.7 8.5-21.25 0 .5.5.5-.1 1.5.95 4.5 1.05 3.05 1.05 4h.5Q37 35.9 44.35 27.8q-1.9 6.85-.85 11.15.4 6.5 1.75 15.65.2-9.15 3.85-15.3 3-5.15 4.8-6.5.05 1 .1 2.35.3 2.75.9 4.15.55-.8 1.5-1.7 2.45-2.35 6.45-6.55 2.55 8.6 4.6 13.8.4.7.9 3.35 2.15-4.9 5.95-8.65 3.75-3.75 6.95-6.3.75 1.15 1.15 2 .45.45 3.3 5.1.35.5.7 1.05 1.9 2.8 4.45 6.45l1.35-12.4q11.45 9.4 15.4 23.25 1.5-11.85-1.25-26.25 2.05 1.5 4 3.65 4.3 4.75 6.25 8-1.85-7.3-2.2-8.25-.35-.95-4.4-9.85 9.35 11.2 15.65 21.7l-1.75-13.1q3.95 6.1 3.65 6.05 1.6 2.15 3.6 6.1.35.3 3.45 7.85-1.1-16.3-11-30.8 15.25 9.8 25.6 34.65-.1-7.35-.1-7.45-.8-7.75-2.4-15.5-1.15-5.45-2.35-8.95 4.25 2.85 10.5 7.75 1.25.95 5.5 5.75 2.7 3 3.95 4.85.2.1.35.2m-73.3-10.35h-.05q0-.25.05-.45v.45m-3.25-.35q-.002-.052-.05-.1h.05v.1z"
                    id="HaiMed005__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed005__Symbol_61_0_Layer0_0_FILL"
                transform="translate(82.95 54.15)"
                id="HaiMed005__col1n"
            />
            <use
                xlinkHref="#HaiMed005__Symbol_14_0_Layer0_0_FILL"
                transform="translate(106.4 64.55)"
                id="HaiMed005__col1l"
            />
        </g></g>
    )
}

export default HaiMed005
