import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#005100",
    "col2l": "#00B600",
    "col2n": "#008300"
}

function ObjMor085({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.9 10.3q.35-1.85-.7-3.35Q44.25 5.4 42.4 5L17.6.1q-1.8-.35-3.4.7-1.6.95-1.95 2.8L.1 65q-.35 1.75.75 3.35 1.05 1.55 2.85 1.9l24.8 4.9q1.85.4 3.35-.65 1.55-1.05 1.9-2.8L35 65.45l-.5-.2h-.05l-.2-.1q-.3-.15-.55-.3-.55-.35-1.05-.8-1.7-1.7-2.6-5.1l-.1-.55q-.15-.75-.25-1.5-.5-5.5 2.35-9.9.15-.2.3-.35.05-.05.05-.1 1.7-2 3.95-3 .85-.25 1.65-.45.75-.2 1.5-.3l6.4-32.5z"
                    id="ObjMor085__Symbol_174_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M42.65 6.25q-.75-1.05-2-1.3L15.85.05q-1.3-.25-2.3.45l-.05.05q-1.05.65-1.3 1.9L.05 63.85q-.25 1.25.5 2.3.65 1 1.95 1.25l24.8 4.9q1.25.25 2.35-.4 1.05-.7 1.3-1.95l1.3-6.55q-.55-.35-1.05-.8-1.7-1.7-2.6-5.1l-.1-.55q-.15-.75-.25-1.5-.5-5.5 2.35-9.9.15-.2.3-.35.05-.05.05-.1 1.7-2 3.95-3 .85-.25 1.65-.45l6.55-33.1q.25-1.25-.45-2.3z"
                    id="ObjMor085__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M9.8 41.65q3.1-.35 4.55-1.5 1.4-.95 1.8-3.05.8-3.95-2.75-6.8-2.8-2.25-7.25-3.15-1.7-.3-2.1-.35h-.5L0 44.65q5.6-2.55 9.8-3M29.65 17.9q3.05.6 6.9.35h.8l1.95-9.9q.25-1.25-.45-2.3-.75-1.05-2-1.3L12.8 0q-.05.2-.1.5-.25 1-.25 1.1-.45 2.3.75 4.9 1.25 2.6 3.75 4.9 2.5 2.35 5.75 4.05 3.35 1.75 6.95 2.45z"
                    id="ObjMor085__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M31.55 18.9q-3.35-4.75-7.9-7.75Q20.45 9 17.5 7.7q-3.35-1.45-4.7-3.65-.5-.8-.85-1.4-.6-1.1-1-2.65L8 15q1.7.15 2.9.35 3.65.75 5.8 1.65 2.45 1.1 4.9 2.6 2.4 1.65 5.15 5.65.55.75 1.2 1.95.6 1.2.7 2.8.05.65.05 1.2-.1.85-.2 1.45-.4 2.1-1.4 3.5-1.1 1.4-2.85 2.25-3.35 1.55-10.95 1.75-5.8.2-9.8 1.75-.5.2-.9.4L.05 55.1q-.25 1.25.5 2.3.65 1 1.95 1.25l24.8 4.9q1.25.25 2.35-.4 1.05-.7 1.3-1.95l1.3-6.55q-.55-.35-1.05-.8-1.7-1.7-2.6-5.1l-.1-.55q-.15-.75-.25-1.5-.5-5.5 2.35-9.9.15-.2.3-.35.05-.05.05-.1 1.7-2 3.95-3 .85-.25 1.65-.45l.4-2.1q-.05-.25-.1-.45-1.8-6.5-5.3-11.45z"
                    id="ObjMor085__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M267.5 265.95l-22.2-4.35q-1-.2-1.85.3-.85.5-1 1.4-.2.95.4 1.75.55.8 1.55 1l22.2 4.35q1 .2 1.85-.3.8-.5 1-1.45.15-.9-.4-1.7-.55-.8-1.55-1z"
                    id="ObjMor085__Layer4_0_FILL"
                />
                <path
                    d="M260.55 249.8q-.4-.55-1.1-.7-.3-.05-.65 0-.3.05-.65.25-.55.4-.7 1.1-.15.7.2 1.3.4.6 1.1.7.7.15 1.3-.25.3-.25.55-.55.15-.25.2-.55.15-.7-.25-1.3z"
                    id="ObjMor085__Layer4_2_FILL"
                />
                <path
                    fill="#FFF"
                    d="M255.9 310.4q.05 0 .1-.05v-.1l-.1-.1q-.05-.05-.1 0l-1.8.9-.05.05v.1q.05 0 .05.05l1.3 1.5q.05.05.1.05t.1-.05v-.1q.05-.05 0-.05l-1.2-1.4 1.6-.8m-8.9-1.9q-.75-.15-.9.6l-.25 1.2q-.15.75.6.9l1.2.2q.75.15.9-.6l.25-1.2q.15-.75-.6-.9l-1.2-.2m-.05.25l1.2.2q.45.1.35.6l-.25 1.2q-.1.45-.55.35l-1.2-.2q-.45-.1-.35-.55l.25-1.2q.1-.5.55-.4m-6.55-1.45q-.05.05-.05.1l-.45 2.35v.1q.05.05.1.05h.1q.1-.05.1-.1l.45-2.35q0-.05-.05-.1 0-.1-.05-.1t-.15.05m-.45-.15h-.15q-.05.05-.05.1l-.45 2.35v.1q.05.1.1.1t.1-.05q.1-.05.1-.1l.45-2.35q0-.05-.05-.1 0-.05-.05-.05m-.6-.05q.05-.1-.05-.1-.05 0-.1.05t-.05.1l-.45 2.35v.1q0 .05.05.05h.1q.1-.05.1-.1l.45-2.35q0-.05-.05-.1z"
                    id="ObjMor085__Layer4_4_FILL"
                />
                <g id="ObjMor085__Layer4_1_FILL">
                    <path
                        fill="#0392F9"
                        d="M254.8 301.3q-.55-.75-1.45-.9l-.9-.2q-.9-.15-1.65.3-.75.55-.9 1.45l-.2.9q-.15.9.3 1.65.55.75 1.45.9l.9.2q.9.15 1.65-.3.75-.55.9-1.45l.2-.9q.15-.9-.3-1.65z"
                    />
                    <path
                        fill="red"
                        d="M260 302.75l-.1-.55q-.054-.267-.1-.55h-.1q-.85-.2-1.6.3-.8.5-.95 1.4l-.2.9q-.15.9.35 1.7.55.7 1.4.9l.95.15q.9.2 1.6-.3.154-.096.25-.2-.907-1.51-1.5-3.75z"
                    />
                    <path
                        fill="#01C7FF"
                        d="M242.4 301.4q-.15.9.35 1.65.5.75 1.4.9l.95.2q.85.2 1.6-.3.75-.55.9-1.45l.2-.9q.15-.9-.3-1.65-.55-.7-1.4-.9l-.95-.2q-.9-.15-1.6.3-.8.55-.95 1.45l-.2.9z"
                    />
                    <path
                        fill="#0F0"
                        d="M240.2 298.4q-.55-.7-1.4-.9l-.95-.15q-.85-.2-1.6.3-.8.5-.95 1.4l-.2.9q-.15.9.35 1.7.55.7 1.4.9l.95.15q.85.2 1.6-.3.8-.5.95-1.4l.2-.9q.15-.9-.35-1.7z"
                    />
                </g>
                <g id="ObjMor085__Layer4_3_FILL">
                    <path
                        fill="#FFF"
                        d="M244.65 299.7q-.5-.1-.6.05-.25.1-.45 1.1-.15.7-.05.9 0 .25.35.35l.4.1-.2.85 1-.7q.15 0 .3.05.2.05.35.05.25.05.45.1.35 0 .45-.15.1-.2.25-.9.2-1 .05-1.2-.1-.15-.6-.25l-1.7-.35m-.15 1h.05l.1.1q.1.1.05.25 0 .1-.1.2-.1.05-.2 0h-.05q-.15 0-.2-.1-.05-.1-.05-.2.05-.15.1-.25h.3m.85.2q.1 0 .15.05.1.15.05.3 0 .1-.1.15-.15.05-.25.05-.15-.05-.15-.1-.1-.15-.1-.25.05-.15.15-.2.1-.05.25 0m.65.15h.25q.15.05.15.1.1.1.05.25 0 .1-.1.2-.1.05-.25 0-.15 0-.15-.1-.1-.1-.1-.2.05-.15.15-.25m12.6 4.15l1.85.4q.349.058.5-.15-.452-.97-.85-2.25l-1.05-.2q-.6-.1-.75.45l-.2 1.05q-.1.6.5.7m1.5-1.15q.2.2.15.45-.05.2-.3.35-.15.15-.35.1-.2-.05-.4-.25-.1-.2-.05-.4.05-.25.2-.4.25-.1.45-.05t.3.2m-6.7.2q-.1-.05-.2-.05-.6-.15-1.2-.65-.6-.55-.85-1.15-.05-.05-.05-.2-.2.4-.2.85.1.65.55 1.05.45.45 1.15.4.45-.05.8-.25m.7-1.4q-.1-.65-.6-1.1-.5-.4-1.1-.35-.2 0-.4.05-.15.05-.2.1-.05 0-.15.05-.05 0-.05.05-.2.2 0 .65.2.5.7.9.45.4.9.55.45.1.65-.05.053-.056.1-.15 0-.05.05-.1.05-.1.05-.2v-.05q.05-.2.05-.35m-15.75-3.35q.15.1.3 0 .1-.05.15-.35.1-.35 0-.45-.1-.1-.5-.2-.6-.1-.85.25-.2.25-.35.85 0 .15-.05.3-.05.1-.05.25-.1.6-.05.95.15.45.75.55.4.1.5 0 .15-.05.2-.35.05-.35-.05-.5-.05-.05-.2-.1-.4 0-.3-.5v-.05-.2q.1-.5.5-.45z"
                    />
                    <path
                        fill="#014ED6"
                        d="M246.25 301.05H246q-.1.1-.15.25 0 .1.1.2 0 .1.15.1.15.05.25 0 .1-.1.1-.2.05-.15-.05-.25 0-.05-.15-.1m-.75-.1q-.05-.05-.15-.05-.15-.05-.25 0t-.15.2q0 .1.1.25 0 .05.15.1.1 0 .25-.05.1-.05.1-.15.05-.15-.05-.3m-.95-.25h-.35q-.05.1-.1.25 0 .1.05.2t.2.1h.05q.1.05.2 0 .1-.1.1-.2.05-.15-.05-.25l-.1-.1m9.6 3.05q.05-.2-.1-.5 0 .1-.05.2 0 .05-.05.15l-.1.1q-.2.15-.65.05-.45-.15-.9-.55-.5-.4-.7-.9-.2-.45 0-.65 0-.05.05-.05.1-.05.15-.05.05-.05.2-.1h-.05q-.55-.15-.8.1-.2.3-.05.65 0 .15.05.2.25.6.85 1.15.6.5 1.2.65.1 0 .2.05.45.1.65-.15.1-.15.1-.35z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor085__Symbol_174_0_Layer0_0_FILL"
                transform="translate(229.95 243.8)"
                id="ObjMor085__col1n"
            />
            <use
                xlinkHref="#ObjMor085__Symbol_50_0_Layer0_0_FILL"
                transform="translate(231.4 245.25)"
                id="ObjMor085__col2n"
            />
            <use
                xlinkHref="#ObjMor085__Symbol_20_0_Layer0_0_FILL"
                transform="translate(235.2 245.45)"
                id="ObjMor085__col2l"
            />
            <use
                xlinkHref="#ObjMor085__Symbol_14_0_Layer0_0_FILL"
                transform="translate(231.4 254)"
                id="ObjMor085__col2d"
            />
            <use xlinkHref="#ObjMor085__Layer4_0_FILL" />
            <use xlinkHref="#ObjMor085__Layer4_1_FILL" />
            <use xlinkHref="#ObjMor085__Layer4_2_FILL" />
            <use xlinkHref="#ObjMor085__Layer4_3_FILL" />
            <use xlinkHref="#ObjMor085__Layer4_4_FILL" />
        </g></g>
    )
}

export default ObjMor085
