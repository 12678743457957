import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiHig024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.4 40.9q.05-.75.1-1.55-.25-6.45-2.65-11.45-1-2.05-2.35-3.9-2.45-3.4-5.75-5.5-.05-.05-.15-.1l-.1-.05q-.35-.25-.75-.45-.2-.15-.45-.3-.65-.5-1.35-.9-5.45-3.65-9.55-6.9Q16 4.65 12.9.3q-.1-.2-.2-.3v.05q.1.65.25 1.4v.05q-.55.45-.65 1.85-.2 1.35-.85 3.55-.7 2.2-1.8 7.95-.95 4.8-2.65 15-.3 1.45-3.45 13.7Q.55 55.9.1 57.95q-.5 2.05 1.85 5.9 1.85 3.75 4.25 3.8.95 1.15 1.95 2.05 6.45 5.95 7.25 10.55.4-1.15.7-3.4.35-2.3-.15-6.55.9 1.6 1.45 3.5 1.2 4.3 1.2 7.7.05 3.4-6.05 6.9 7.1.3 9.35-1.6 2.25-1.95 3.25-4.95 1.05-3.05.9-7.25v-.05q-1.15-6.8-2.45-8.3 0-.15-.05-.25-.05-.25-.2-.4-.05-.2-.2-.45 3.75 5.25 9.3 8.25 3.8 2.35 8.1 3 2.25.4 4.65.25 1.45-.05 2.75-.2.85-.15 1.65-.35.1-.05.25-.05v-.1q-1-.3-1.9-.6-5.25-1.9-7.3-4.9-1.25-1.75-1.8-4.45.5-.2.95-.45.5-.2.8-.4 3-1.5 3-2.95-2.4.55-4.4.2-.15 0-.35-.05h-.15q-.15 0-.3-.05-.05-2.4.2-5.3.05-.2 0 1.2.1-1.75.15-1.65.85-2.55 1.5-4.55.4 3.95 2.65 5.85 2.3 1.85 3.95 2.45-1.3-1.65-2.05-3.55-.7-2.05-.75-4.05 0-1.3.25-3.85.1-1.45.5-3.95.45-2.6.6-4z"
                    id="HaiHig024__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22.4 66.8q-.35.05-.65.05-6.25.9-9.9-1.45-1.55-1.05-1.45-.4.05.05.05.15 3.75 5.25 9.3 8.25 3.8 2.35 8.1 3 2.25.4 4.65.25 1.45-.05 2.75-.2.85-.15 1.65-.35.1-.05.25-.05v-.1q-1-.3-1.9-.6-5.25-1.9-7.3-4.9-1.25-1.75-1.8-4.45-.5.2-1.1.35-1.35.4-2.65.45M27.8 24q-2.45-3.4-5.75-5.5-.05-.05-.15-.1l-.1-.05q-.35-.25-.75-.45-.2-.15-.45-.3-.65-.5-1.35-.9-5.45-3.65-9.55-6.9Q3.3 4.65.2.3.1.1 0 0v.05q.1.65.25 1.4Q.25.95.2.5q.25.5.45.95l.7 1.4Q2.1 4.25 2.9 5.5q1.35 2.15 2.25 3.6 1.35 2.15 1.6 2.6 1.4 2.55 5.15 5.3.2.05.4.15 3.05 1.5 5.15 3.25.15.05.25.15 2.75 2.35 3.75 5.1h.05q.45.8.85 1.65 1.1 2.55 1.55 5.55.3 6.3-1.85 12.5-2.05 3.3-1.9 7.3.15 2.2.6 3.9.7 2.7 2.2 4.25.3.1.45.25h.05q1.05.8 2.25 1.25-.05-2.4.2-5.3.05-.2 0 1.2.1-1.75.15-1.65.85-2.55 1.5-4.55.4 3.95 2.65 5.85 2.3 1.85 3.95 2.45-1.3-1.65-2.05-3.55-.7-2.05-.75-4.05 0-1.3.25-3.85.1-1.45.5-3.95.45-2.6.6-4 .05-.75.1-1.55-.25-6.45-2.65-11.45-1-2.05-2.35-3.9z"
                    id="HaiHig024__Symbol_87_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M26.5 24.5q-.5-1.2-1.1-2.3-1.6-2.55-4.8-5.55l-.7-.7q-.1-.2-.3-.35l-.05-.05q-.25-.35-.6-.65l-.05.05q-2.35-2.8-3.05-4.95-.75-2.15-.95-2.85v-.1q-.25-1.15-1.1-4-.15-.55-.35-1.2-.3-.95-.5-1.85-.55.45-.65 1.85-.2 1.35-.85 3.55-.7 2.2-1.8 7.95-.95 4.8-2.65 15-.3 1.45-3.45 13.7Q.55 54.4.1 56.45q-.5 2.05 1.85 5.9 1.85 3.75 4.25 3.8.95 1.15 1.95 2.05 6.45 5.95 7.25 10.55.4-1.15.7-3.4.35-2.3-.15-6.55.9 1.6 1.45 3.5 1.2 4.3 1.2 7.7.05 3.4-6.05 6.9 7.1.3 9.35-1.6 2.25-1.95 3.25-4.95 1.05-3.05.9-7.25v-.05q-1.15-6.8-2.45-8.3-.05-.35-.25-.65-.05-.2-.2-.45 0-.1-.05-.15-.55-1.35-1.65-3.35-1.55-2.9-1.65-6.8-.1-3.9 4.35-9.7 2.65-3.6 3.65-7.05.65-2.1.55-4.2-.2-4.35-1.85-7.9z"
                    id="HaiHig024__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.5 11.05q0-1.15-.15-3.05-.15-1.95.15-6.55-.15-.75-.25-1.4V0Q27.4 4.4 24 7.7q-3.5 3.35-9.7 5.55-.35.1-.75.25-8.2 3-11.05 10.5-.6 1.7-.8 4.2-.2 2.5.7 3.95.65 1.1 1.5 2.4 0-.25.05-2.9.1-2.65 3.1-6.9h.1q-.15.5-.65 2.05-.5 1.65-.65 3.45-.4 4.15 1.75 8.65Q9.8 43.4 9.25 49q-.2 2.25-.3 2.6 0 .1-.05.15-.45.1-1.05.2.05-.1-1.1.05-1.1.15-2 .05-.9-.1-1.25-.15-.3-.05-3.5-.55 3.1 4.6 5.5 6.2.55.35 1.2.7.2.1.35.15.35.25.65.45 1.2 10.5 4.35 14.8 3.15 4.2 5.65 6.05 2.55 1.85 9.2 4.4-3.25-3.45-3.4-4.2l.05-.05q-1.9-3.45-2.4-7.55-.25-2.65.45-4.85-.95-1.2-1.9-2.65-1.1-1.75-1.7-3.3.8.05 1.55 0h.1l.25-.05q.5-.05 1.05-.15 4.25-.9 7.15-4.95 2.9-4.1 3.35-8.6 0-.4-.25-.65.1-.55.15-1.1.2-2.3.25-2.45-.05-.15-.45-3.35-.2-2.3-.4-2.95-.047-.094-.1-.15 1.052 1.055 1.6 2.8 1.05 3.15.95 5.55-.05 2.35-.35 3.85l.1-.3q.05-.15.15-.3 1.05-.5 2.4-3.2 1.4-2.75 1.9-7.25t-1.8-8.8q-2.3-3.95-2.7-4.7-.7-1.35-1.2-2.55-.7-1.65-1.1-2.15-.35-.5-.55-1.3-.25-.8-.9-3.7-.65-2.9-.65-4z"
                    id="HaiHig024__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M16.35 61.5l-1-.1-.4-.05q-4.1.15-8.9-2.5 1.2 10.5 4.35 14.8 3.15 4.2 5.65 6.05 2.55 1.85 9.2 4.4-3.25-3.45-3.4-4.2l.05-.05Q20 76.4 19.5 72.3q-.25-2.65.45-4.85-.95-1.2-1.9-2.65-1.1-1.75-1.7-3.3M26 2.8q.35-1.05.6-2.8-.85 4.4-4.25 7.7-3.5 3.35-9.7 5.55-.35.1-.75.25Q3.7 16.5.85 24q-.6 1.7-.8 4.2-.2 2.5.7 3.95.65 1.1 1.5 2.4 0-.25.05-2.9.1-2.65 3.1-6.9h.1q-.15.5-.65 2.05-.5 1.65-.65 3.45-.4 4.15 1.75 8.65Q8.15 43.4 7.6 49q-.2 2.25-.3 2.6 0 .1-.05.15.65-.1 1.25-.2.7-.15 1.35-.35.15-.05.25-.1 1.2-.45 1.95-1.05.55-.45 1-1.05.7-.8 1.2-1.8 1.85-3.5 1.85-7.35 0-2.7-1.25-5.65t-1.25-6q0-5.2 3.8-8.85 4.25-4.05 6.3-8.85l.05-.05q-.05 0-.15.05.2-.3.35-.6.05-.15.1-.25.25-.5.4-1.15.1-.25.15-.45.2-.8.4-1.8.05-.3 1-3.45z"
                    id="HaiHig024__Symbol_83_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig024__Symbol_88_0_Layer0_0_FILL"
                transform="translate(88.2 183.2)"
                id="HaiHig024__col1n"
            />
            <use
                xlinkHref="#HaiHig024__Symbol_87_0_Layer0_0_FILL"
                transform="translate(100.9 183.2)"
                id="HaiHig024__col1d"
            />
            <use
                xlinkHref="#HaiHig024__Symbol_86_0_Layer0_0_FILL"
                transform="translate(88.2 184.7)"
                id="HaiHig024__col1l"
            />
            <use
                xlinkHref="#HaiHig024__Symbol_84_0_Layer0_0_FILL"
                transform="translate(72.65 183.2)"
                id="HaiHig024__col2n"
            />
            <use
                xlinkHref="#HaiHig024__Symbol_83_0_Layer0_0_FILL"
                transform="translate(74.3 183.2)"
                id="HaiHig024__col2d"
            />
        </g></g>
    )
}
export default HaiHig024
