import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}

function ShiSpe109({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M181.1 32.45q-1.8 1.25-3.65 2.4-1.95 1.2-3.95 2.35-7.1 3.95-14.75 6.55-.05 0-.1.05-.2.05-.35.1-.8.25-1.65.55-2.1.65-4.25 1.2-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95-1.6-.4-3.15-.85-8.95-2.7-17.15-7.1-2.45-1.35-4.85-2.85-1.85-1.15-3.65-2.4l-1.55-1.15q-1.45-1.05-2.8-2.1l-1.85-1.4q-1.15-1-2.2-2Q45.2 11.2 23.7 0l34.55 34.85q-3.2 1.95-5.2 5.25-.55.85-1 1.8l-.35.85Q42.5 65.1 30.5 84.7q-1.8 2.5-3.05 4.35-12.2 17.5-27.45 21.3.5.05 1.05.25.55 0 2-.2l61.95-.8q.4.1.85.2 30.8 7 61.4 7 30.75 0 61.3-7l1-.2v-.15l.35-.05 58.1 2.75 1.95 1.1-14.8-9.7q-6.1-4.25-11.1-13.55l-1.1-2.1q-2.6-5.3-4.25-10.8-.05-.15-.05-.25-.2-.6-.35-1.15-8.8-28.8-16.4-35.5-.15-.1-.65-.5t-1.8-1.5q-1.3-1.2-4.1-2.7.3-.35 1.95-1.9L230.6 0q-20.25 10.65-40.55 25.35-1.35 1.25-2.75 2.45l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15z"
                    id="ShiSpe109__Symbol_264_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M23.65 19.75q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L18.45 41v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05M43.3 4.5q5.3-1.2 7.25-3.6l-1.3.5Q43.6 3.35 33.4 3.05q-1.65-.05-3.05-.1-1.45-.05-4.2 0l-.9.05Q9.65 4.15 2.55 15.15q-.75 1.1-1.4 2.35L0 23.3q1.2-3.15 2.05-4.35.9-1.35 4.05-5.2 3.1-3.9 8.55-6.55 4.15-2.05 11.4-2.1h.8q1.9 0 4 .15 1.8.15 3.45.2 5.15-.1 9-.95m90.6 14.9q-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15V41l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15m19.5-4.95q-.15-.25-.35-.5-2.85-3.95-6.4-6-3.8-2.25-9.05-2.25-5.7.3-8.7.3-3.6 0-7.8-.85-4.2-.85-5.85-1.75-1.2-.7-3.95-2.65-.5-.35-1-.75.4 1.05 1.35 2.1.5.55 2.05 2 1.25 1.15 2.75 1.9l.4.2q3.65 1.65 7.05 1.9 5.3.35 13.4.1 8.1-.3 11.3 3.95 3.15 4.2 6.5 9.2 3.3 4.95 3.15 5.1.1.05.05-.35-.1-.5-.4-1.6-.6-2.1-1.75-6.05 0-.05-.15-.25-.55-.8-2.6-3.75z"
                    id="ShiSpe109__Symbol_267_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M36.85 84.5Q34.2 88.4 29 93.4q-5.2 4.95-7.95 6.9L8.8 108.35Q5.65 110 2.5 110.3q-1.85.3-2.5.3 1.45.45 2.8 1.4 1.75 1.25 4.85 3.8 2.9 2.1 6.05 3.1 4.4 1.45 9.4.8 8.5-1.05 11.35-1.85 1.45-.4 3.55-.6 2.05-.2 4.75-.2 1.2 0 2.35.3 1.55.35 3 1.2.6.35 4.15 3.1 4 3.05 8.65 2.75.45-.05.95-.1l1.95-.4.2-14.25-4.15-1.15v-.1l.95-3.6.9-3.6q.1-.3.15-.6l.9-3.6 2.8-11.75q-1.65-1.8-3.75-2.95l-.2-.1q-3.65-1.85-8.7-1.65l-1.85.2q-.15 0-.3.05-.2.05-.35.1-.1 0-.2.05-1.7.35-3.2 1.05-1.8.85-3.3 2.15v-.1q0-.2.05-.4.2-1.05.4-2.05.05-.45.15-.9l.2-.9v-.2q.35-1.7.75-3.4l.3-1.4q.05-.3.15-.6.1-.5.25-1h-.05l.1-.5.7-2.7q.05-.3.15-.6l.6-2.2 1.15-5.8-.4.8q-.25.45-.45.95-.2.55-.45 1.15-.25.55-.55 1.15-.8 1.6-1.9 3.4-.3.5-2.25 5.8.35-.4-1.7 3.65l-.2.4q-.5.85-.85 1.4-.4.5-3 4.4m27.1-58.7q-7.2-6.6-13.35-14.05-1.3-1.55-2.55-3.15L44.9 4.45Q32.35 4.5 22.65 0l41.3 25.8m135.4 54.75q-4.8-.2-8.35 1.5-.25.1-.5.25-2.15 1.1-3.8 2.95l.55 2.45h.05l5.15 20.7v.1l.05.2h-.2q-.25.1-.5.15l-3.3.6v15.9q3.1-.1 4.9-1 2-1 4.35-1.9 1.05-.4 2.3-.6.2-.05.45-.1 1.3-.15 2.8-.15 3.45 0 7.6.75 1.35.2 2.75.5 4.85.2 6.7-1.4.65-.6 1.55-1.5 1.45-1.5 3.5-3.8 2.2-2.5 3.95-3.25.75-.35 1.45-.3 5-.7 18.1.65l-8.55-4.8q-6-3.95-6.65-4.35-6.95-4.05-11.8-9.45-3.3-3.75-5.6-8.1-1.3-2.4-1.6-2.4-2.8-4.2-6.1-11.05-1.7-3.6-2.95-6.3l-.5-1.1v-.05q-.95-1.95-1.55-3.35v.05q1.15 3.95 1.75 6.05.3 1.1.4 1.6.05.4-.05.35l.2.3q.25 1 .5 2.05.2.75.35 1.5.1.3.15.6.15.7.35 1.45l.1.5q.1.4.2.85.2 1.1.45 2.2 0 .2.05.4l.1.4q.25 1.5.55 3.05.05.2.05.35V84.15q-1.4-1.25-3.05-2.05-.7-.35-1.45-.6 0-.05-.05-.05-1.2-.45-2.5-.65-.15-.05-.3-.05l-2.05-.2m22.55-77.8q-6.75 1.75-14.6 1.7l-2.95 4.15q-1.55 2-3.15 3.9-5.9 7-12.2 12.85L229.55 0q-3.65 1.7-7.65 2.75z"
                    id="ShiSpe109__Symbol_265_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M46.15 98.95l.95-3.6.9-3.6q.1-.3.15-.6-2.5.7-3.75 1.75-1.15.9-2.4 3.1-1.55 2.7-2.8 4.15L35.05 105q-1.95 1.95-3.65 2.9 1.55.35 3 1.2.6.35 4.15 3.1 4 3.05 8.65 2.75.45-.05.95-.1l1.95-.4.2-14.25-4.15-1.15v-.1M26.2 96.1q3.8-6.65 3.8-9.65v-1.3q-.05-.2-.9-.2-2.35 0-8.9 6.8-3.45 3.6-8.4 9-3 2.8-8.55 6.55-1.75 1.2-3.25 2.15 4.4 1.45 9.4.8 8.5-1.05 11.35-1.85 1.45-.4 3.55-.6-.35-.15-.6-.35-1.3-.95-1.3-2.8 0-1.85 3.8-8.55M24.55.05q-.2 0-.35-.05h-.1l26.15 16.35Q43.05 9.75 36.9 2.3q-4.75-.1-6.8-.45-3-1-5.55-1.8M176.25 89l2.5 9.95v.1l.05.2h-.2q-.25.1-.5.15l-3.3.6v15.9q3.1-.1 4.9-1 2-1 4.35-1.9 1.05-.4 2.3-.6-1.15-1.05-1.75-2.85-.6-1.6-1.4-7.95-.7-5.5-2.2-8.25-1.65-2.95-4.75-4.35m16.55.25q0 1.45 2.8 6.75 2.8 5.35 2.8 11.25 0 3.4-.65 4.75-.25.5-.55.9 1.35.2 2.75.5 4.85.2 6.7-1.4.65-.6 1.55-1.5 1.45-1.5 3.5-3.8 2.2-2.5 3.95-3.25-2.25-.15-6.1-2.1-4.05-2.1-5.95-4.4-2.05-2.6-4.2-5-4.3-4.8-5.7-4.8-.45 0-.7.8-.2.65-.2 1.3m2.55-87.5q-3.05.8-7.85 1.3-5.9 7-12.2 12.85L200.25.35h-.3q-2.5.9-4.6 1.4z"
                    id="ShiSpe109__Symbol_266_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe109__Symbol_264_0_Layer0_0_FILL"
                transform="translate(65.25 200.8)"
                id="ShiSpe109__col1n"
            />
            <use
                xlinkHref="#ShiSpe109__Symbol_267_0_Layer0_0_FILL"
                transform="translate(113.75 244.7)"
                id="ShiSpe109__col1s"
            />
            <use
                xlinkHref="#ShiSpe109__Symbol_265_0_Layer0_0_FILL"
                transform="translate(66.3 200.8)"
                id="ShiSpe109__col2n"
            />
            <use
                xlinkHref="#ShiSpe109__Symbol_266_0_Layer0_0_FILL"
                transform="translate(80 210.25)"
                id="ShiSpe109__col2d"
            />
        </g></g>
    )
}

export default ShiSpe109
