import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#002A00"
}

function ShiLon003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M142.75 10.2q-.25-1.25-.25-3.55v-.2q-.1-.15-.15-.3-.05 0-.05-.05-2-3.05-4.15-6.1l-1.85 1.55q-1.35 1.05-2.8 2.1l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-20.9-3.2-38.2-15.15L22.7 3.65q-1.45-1.05-2.8-2.1L18.05.15q-.1.05-.15.15-2.9 3.85-5.4 8.1-.15.15-.25.35.1.2.15.4.35.95.45 1.6.05.35.05 1.5 0 1.25.05 2.2-.1.6-1.1 2.8-.6 1.25-3.15 3.05-2.05 1.4-3.15 1.7-.1.1-.15.25-1.3 3.05-2.45 6.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.15.4-.25.85-.1.25-.15.55 1.25.95 3.25 3.25 1.35 1.5 2.35 2.7 2 1.65 3.4 3.25.15.15 4.3 4.95 4.1 4.95 4.1 5.9v.15l.05.05v.05l-2.4 10q.5.35.9 1 .55 1 .55 2.8 0 2.25-2.05 4.5-1 1.15-1.65.9l-1.05 3.9V81l-.05.05h.2q.25.1.5.15H12.55q2.05.5 4.15.95 15.45 3.5 30.85 5.25h.35q.4.05.85.1.4 0 .75.05.7.1 1.45.15l1.55.2.9.1q12.35 1.15 24.7 1.15 30.75 0 61.3-7 2.1-.45 4.15-.95H143.7q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-.55-2.15q.02.05.05.05v-.05l-.4-1.65q-1.95-.45-3-3.4-.75-3.55-.75-3.7-.1-1.05-.1-1.55.1-.85.95-1.5l.3-.2-1.65-6.55h-.05l-.4-1.9q-.25-.25-.25-.75 0-2.05 4.5-5.9 5.9-5.1 6.25-5.5 3.35-3.95 4.65-5.7 1.6-1.9 2-2.85.1-.3.2-.5-.05-.2-.1-.35-.3-.9-.55-1.75-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-.45-.95-.85-1.9-.1-.2-.15-.4-1.55 0-3.65-2.8-1.6-2.15-2.65-4.55-1.1-2.5-1.4-3.9m.65 68.4l-.2.35h-.1l-1.65 1.85q1.8-2.1 1.95-2.2M78.75 30.45h-.05v-.05q.001.049.05.05z"
                    id="ShiLon003__Symbol_277_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M145.25 60.7q-.3.05-.6.15h-.3q-.5.05-.9.15h-.05q-62 9.15-122.6.65-.2-.05-.35-.05h-.05q0-.05-.05-.05-.3-.05-.55-.05-.15-.05-.3-.05l-.45 1.8q-.55 2.1-1.05 4.2l-.9 3.6-.05.2q66.2 12.2 130.85 0l-2.65-10.55M150 5.55q-1-1.85-2.1-3.6-.05-.1-.15-.2-.5-.9-1-1.7-.05 0-.05-.05-17.9 13.35-43.5 18.5-18.85 2.85-39.25.4-28.8-4.2-47.05-16.6-.15.2-.3.45l-.8 1.4q-.45.75-.85 1.5-1.5 2.7-2.85 5.55-.5.95-.95 1.95-.7 1.5-1.35 3 .3.15.65.3.1 0 .25.1.35.15.75.3 68.1 28.45 141.2-.15.75-.3 1.45-.6.05-.05.1-.05l.8-.3q-.55-1.35-1.15-2.6-1.8-3.95-3.85-7.6m-66.85 18.8h-.05v-.05q.001.049.05.05m80.4 18.15q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.5-1.8-1.05-3.55-.35.05-.7.2-.2.05-.35.15-.05 0-.15.05-9.7 2.9-19.15 5.15l-1 .1.05.15h-.4v.1q-2.1.5-4.25.95-7.95 1.7-15.85 2.9-47.05 7.15-92.55-3.55l-1-.25H25v-.2q-.5-.05-1-.05-.7-.15-1.4-.35-7.95-2-15.8-4.45-.5-.15-.95-.3-.6-.25-1.2-.4h-.1q-.75 2.4-1.4 4.95-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.8-.3 12.3 4.35.15.1.3.3l.05.05v.05q.05 0 .6-.25.55-.25.2-.35-.4-.1.3-.55.7-.45-.1-.15-.7.2.05-.2h.05l.8.1q.45.05 1 .15 56.9 8.65 115.7.25.55-.1 1.15-.15.05-.05.1-.05l.1.3.3.05q.35 0 .6.7-.05 0-.05.05h-.05l-.05.05h.45q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V50.25q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5z"
                    id="ShiLon003__Symbol_183_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon003__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon003__Symbol_277_0_Layer0_0_FILL"
                transform="translate(114.4 228.45)"
                id="ShiLon003__col1n"
            />
            <use
                xlinkHref="#ShiLon003__Symbol_183_0_Layer0_0_FILL"
                transform="translate(110 234.55)"
                id="ShiLon003__col2n"
            />
            <use xlinkHref="#ShiLon003__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon003
