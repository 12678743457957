import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#ADFFFF",
    "col1n": "#E2FFFF"
}

function AccJew041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M8.4 1.5q0-.6-.45-1.05Q7.5 0 6.9 0q-.6 0-1.05.45Q5.4.9 5.4 1.5q0 .6.45 1.05.3.3.65.4-.8.1-1.4.7-.75.75-.75 1.8t.75 1.8Q5.85 8 6.85 8L0 26.15 6.9 43.4 3.4 26.15 6.9 7.9l3.5 18.25L6.9 43.4l.05-.1 6.85-17.15L6.95 8q1 0 1.75-.75t.75-1.8q0-1.05-.75-1.8-.6-.6-1.35-.7.3-.1.6-.4.45-.45.45-1.05z"
                    id="AccJew041__Symbol_69_0_Layer0_0_FILL"
                />
                <g
                    id="AccJew041__Symbol_109_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M4.7 0L0 18.25 4.7 35.5l4.25-17.25L4.7 0zM4.85 35.6v-.05l-.05-.3-.05.15.1.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccJew041__Symbol_109_0_Layer0_0_FILL"
                transform="translate(96 186.1)"
                id="AccJew041__col1n"
            />
            <use
                xlinkHref="#AccJew041__Symbol_69_0_Layer0_0_FILL"
                transform="translate(93.8 178.2)"
                id="AccJew041__col1d"
            />
        </g></g>
    )
}

export default AccJew041
