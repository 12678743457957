import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function FacEar004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.5 19.45Q15.7 8.35 2.75.25q-3.6-1.2-2.5 2.9 2.5 5.85 4.5 11.5.85 2.4 1.6 4.8 1.15 3.8 2 7.5 2.5 11.8 2.85 23v.6q0 .9.1 1.75.1 1.95.55 3.8 1.4 5.85 6 10.45 6.6 6.65 16.05 6.65h1.65l.9-.25q-7.2-17.15-7.2-37.25 0-3.7.05-5.7-.6-1.55-1.3-3.05-2-3.9-4.5-7.5m221.5-4.8q2-5.65 4.5-11.5 1.1-4.1-2.5-2.9-12.95 8.05-20.8 19.2-2.55 3.6-4.55 7.5-.75 1.6-1.4 3.2.25 1.85.25 5.55 0 20.1-7.2 37.25l.9.25h1.65q9.45 0 16.05-6.65 4.6-4.6 6-10.45.45-1.85.55-3.8.1-.85.1-1.75v-.6q.35-11.2 2.85-23 .9-3.7 2.05-7.5.7-2.4 1.55-4.8z"
                    id="FacEar004__Symbol_5_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9.35 14.95q2.85 3.45 4.7 6.5 2 3.15 2.7 7.7.7 4.65-3 8.25-2.6 2.4-4.15 3.85.55-.15 1.45-.15.9-.1 2.05-.65 1.2-.65 1.95-1.2.5-.55 1.1-1.1 3.6-3.55 3.2-9.75-.4-6.15-3.55-9.7Q12.65 15.1 0 0q4.2 7.5 9.35 14.95m214.45-6.3q2.65-3.75 5-7.5.1-.05.15-.2-.1.15-.2.2-4 4.3-7.05 7.5-6.45 7.65-8.55 10.05-3.15 3.55-3.55 9.7-.4 6.2 3.2 9.75.6.55 1.1 1.1.75.55 1.95 1.2 1.15.55 2.05.65.9 0 1.45.15-1.55-1.45-4.15-3.85-3.7-3.6-3-8.25.7-4.55 2.7-7.7 1.85-3.05 4.7-6.5 2.15-3.15 4.2-6.3z"
                    id="FacEar004__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar004__Symbol_5_0_Layer0_0_FILL"
                transform="translate(67.65 118.1)"
                id="FacEar004__col1n"
            />
            <use
                xlinkHref="#FacEar004__Symbol_8_0_Layer0_0_FILL"
                transform="translate(78.05 136.4)"
                id="FacEar004__col1d"
            />
        </g></g>
    )
}

export default FacEar004
