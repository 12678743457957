import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col1s": "#3E576D"
}

function BodHan020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M75.9 3.1q-.1-.2-.4-.35-.05-.05-.05-.1l-.2-.15Q64.75-1.2 53.55.55l-3 14q-2.05 3.55-4.8 5.6-2 1.5-6.5 3.55-5.1 2.35-7.75 4-.8.5-6.85 2.85-6.5 2.5-10.6 4.7Q0 42.8 0 52.95 0 61.6 4.25 66.2q4.45 4.75 13.25 4.75 9.05 0 17.35-3.2.8-.3 1.55-.6-11.35-1.4-13.2-2.05-7.7-2.5-7.7-9.8 0-7.8 11.55-11.05 3.5-1 9.25-1.7 3.55-.65 8.95-1.45 7.45-1.3 11.5-3.4 5.65-2.95 9.1-5.8 6.2-5.85 7.6-13.1l2.6-15.95q-.1.35-.15.25m200.85 34.85q-6.6-7-17.75-7-5 0-11.6 2.15-6.65 2.1-11.65 2.1-7.4 0-11.35-9.8-1-3.4-1.95-6.3-.05-.2-.1-.35l-3.9-18.2q-11.2-1.75-21.9 2.1 0 .05-.05.1-.3.15-.4.25-.1.05-.3.3l2.6 14.55q3.05 10.4 10.45 21.95l.25.25q7.95 10.15 22.25 10.15 6.05 0 12.75-3.5t15.25-3.5q11 0 17.3 7.55 4.15 5 5.6 12.2.55-1.15.7-3.55.05-1 .05-4.35 0-10.45-6.25-17.1z"
                    id="BodHan020__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M2.85 0L0 19.7q-.1.65 1.35-4.35 1.4-5 2.3-15-.1.35-.15.25-.1-.2-.4-.35-.05-.05-.05-.1L2.85 0m124.3 19.1l-3-18.95q0 .05-.05.1-.3.15-.4.25-.1.05-.3.3 1.6 11 3.75 18.3z"
                    id="BodHan020__Symbol_22_0_Layer0_0_FILL"
                />
            </defs>
            <g id="BodHan020__col1n" transform="translate(56.5 284.55)">
                <use
                    xlinkHref="#BodHan020__Symbol_21_0_Layer0_0_FILL"
                    id="BodHan020__col1n_FL"
                />
            </g>
            <use
                xlinkHref="#BodHan020__Symbol_22_0_Layer0_0_FILL"
                transform="translate(128.9 287.05)"
                id="BodHan020__col1s"
            />
        </g></g>
    )
}

export default BodHan020
