import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1n": "#D19532"
}

function AccMas015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M192.8 45.45l-.55-8.85q-.6-4.4-1.6-9.35-1.6-.85-6.55-3.4-2.3-1.2-5.15-2.55-3.35-1.65-7.55-3.55l-12.35-5.4Q140.9 4.8 124.25 2.1 112 .1 99.75.05q-1.15-.05-2.3 0-.15-.05-.3 0-32.75.3-70.9 16.1-2.4.95-4.8 2.05l-14.5 6.55q-.85.35-1.7.65-.9.3-2.5.85l-.85 5Q.9 35.5.4 39.9L0 48.8l.8 10.5q.8 5.3 2.25 10.8 6.9 6.6 16 12.55 8 5.25 17.75 10 18.7 10.35 37.4 0 1.6-.95 3.3-2.05 4.8 2.95 8.7 10.05 1.1 2.05 2.15 4.4 3.1 7.1 5.5 17.35.9 3.8 1.7 8 .45 2.55.9 5.25.1.6.2 1.25l.1.6q.1-.95.2-1.85.25-2.7.6-5.25.5-4.2 1.15-8 4.05-24.85 12.75-32.05 2.05 1.85 4.15 3.3 13.4 9.55 36.25 0 11.45-5.2 20.85-11 3.25-2.05 6.25-4.15 5.85-4.1 10.75-8.5 1.6-6.4 2.4-12.5l.7-12.05m-102.95-9.4q3.1 3.35 4.75 7.55.75 2.05 1.95 7l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75h.05q10.85.8 19.05 9.65 8.25 8.8 9 20.45.85 11.6-6.3 19.2-2.35 2.5-5.1 4.1-3 1.7-6.45 2.35-3 .55-6.3.3-1.3-.1-2.5-.3-3.9-.65-7.35-2.35-5-2.4-9.2-6.95-7.3-7.8-8.8-17.85l-.75-2.5q0 .1-.05.2-1.3 5.55-2.05 7.9-2.25 6.7-7.45 12.3-4.2 4.5-9.1 6.9-3.55 1.75-7.45 2.4-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-1.55-.3-2.95-.75-1.9-.65-3.55-1.65-2.7-1.55-5-4.05-7.2-7.6-6.35-19.2.7-10.75 7.85-19.1.55-.7 1.2-1.35Q61.1 30.1 72 29.3q10.75-.85 17.85 6.75z"
                    id="AccMas015__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M187.9 28q-5.9-3.45-11.7-6.45Q147 6.25 121.5 2.1 109.25.1 97 .05q-1.15-.05-2.3 0-.15-.05-.3 0-32.75.3-70.9 16.1Q12 20.85 0 27q1.2 1.2 11.75-3.15h.1l.05-.05q13.5-5.65 24.45-9.35 5-2 11.15-3.45 16.25-3.75 40.15-3.2 1.1.05 2.1.15l1.05.1q4.45.55 5.55 2.15.2.4.3.85.3 1.4-.7 3.25-1.05 1.6-6.5 1.7-1.95-.05-4.05-.1l-3.05-.1q-21.2-1.65-41.1 5.7-3.1 1.05-6.5 2.3 16.9-3.75 37.3-3.65.75.05 1.45.1l12.8.1q.95.1 2.3.15 6.35-.5 8 2.15.15.6-.15 1.3-1.75 2-7.2 1.45-12.4-.9-24 .8-4.8.65-8.25 3.3-3.35 2.5-8 11.55.55-.7 1.2-1.35 8.15-8.85 19.05-9.65Q80 29.2 87.1 36.8q3.1 3.35 4.75 7.55.75 2.05 1.95 7l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75h.05q10.85.8 19.05 9.65 8.25 8.8 9 20.45.85 11.6-6.3 19.2-2.35 2.5-5.1 4.1-3 1.7-6.45 2.35-3 .55-6.3.3-1.3-.1-2.5-.3-3.9-.65-7.35-2.35-5-2.4-9.2-6.95-7.3-7.8-8.8-17.85l-.75-2.5q0 .1-.05.2v81.35l.1.6q.1-.95.2-1.85.25-2.7.6-5.25 3.9-31.75 13.9-40.05 2.05 1.85 4.15 3.3 13.4 9.55 36.25 0 11.45-5.2 20.85-11 3.25-2.05 6.25-4.15 5.85-4.1 10.75-8.5 5.7-23 .95-42.75z"
                    id="AccMas015__Symbol_61_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas015__Symbol_99_0_Layer0_0_FILL"
                transform="translate(95.85 107.35)"
                id="AccMas015__col1n"
            />
            <use
                xlinkHref="#AccMas015__Symbol_61_0_Layer0_0_FILL"
                transform="translate(98.6 106.6)"
                id="AccMas015__col1d"
            />
        </g></g>
    )
}

export default AccMas015
