import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFF00"
}

function PetPet028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M5.2 4.45q.35.5.4 1.4.15.75.1 1.7 0 .5-.1 1.05.05 2.75.85-.45.2-.8.25-1.65v-.6q-.15-1.85-1.1-3.15-1.2-1.55-2.9-1.4-.8 0-1.55.45-.35.15-.7.5-.45.45-.45 1-.05.65.9.55.45 0 1.2-.3.8-.45 1.6-.25.35.05.75.35.45.15.75.8m7.55.7q0-1-.2-1.8-.3-1.1-.9-1.95Q10.45-.15 8.7 0q-.75 0-1.5.45-.35.15-.7.45-.45.5-.45 1.05-.05.65.9.55.4 0 1.15-.3.85-.45 1.65-.25.35.05.75.35.4.1.75.8.05.05.1.15.25.5.3 1.25.1.65.05 1.4v.4q-.05.85-.85 3.05.8-1.15 1-1 .5-.9.75-1.9.1-.5.15-1v-.3m13.9 5.25q-1.7.6-2.5-.45T22.9 8.8h-.1q-.9-.15-1.85-.15h-.3q-.45 0-.9.05h-.05q-.85.1-1.1.3-.3.15.7.55 1 .35-.4.55-1.45.15-1.8-.15-.4-.35-.5-.3-.85.35-1.65.95-.15.1-.25.2l-.05.05q.6 1.25.3 3.05-.2 1.25-.3 1.9-1.3 2.3-1.4 2.4.4.7 1.1 1.35.25.3.6.55.15.15.35.25.25.15.45.35.1.05.3 0 .15 0 1.15-.4 1-.4.95.5-.1.9 0 .9.25.05.55.15.95.2 2.05.2h.6q.45 0 1.65-1t1.5-.35q.3.65.65.5l.6-.3q.65-.35 1.2-.8 1.9-1.5 2.35-3.45l3.35-1.1-3.3-1.25q-.35-1.8-1.85-3.2-.25-.25-.55-.5l-.3-.2z"
                    id="PetPet028__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M12.9 6q0-1.6-.75-2.85l-.1-.2q-.1-.15-.2-.25-.35-.5-.85-.95-.5-.45-1-.75-.15-.1-.25-.15-.2-.15-.4-.2-.2-.15-.45-.2Q8.1.1 7.2.05 6.85 0 6.45 0q-1.1 0-2.1.3-.2.05-.4.15-.45.2-.85.45-.2.05-.35.2-.45.25-.85.65Q0 3.5 0 6t1.9 4.25q.6.55 1.25.95 1.5.8 3.3.8h.2q1.4-.05 2.65-.55 0-.05.1-.05.7-.35 1.35-.9l.25-.25.65-.65q0-.05.05-.05Q12.9 8 12.9 6m4.3-5q-.85.1-1.65.35-.1.05-.15.05-.4.1-.8.3-.2.05-.35.15-.05.05-.15.1.65.95.95 2 .3 1.15.3 2.5 0 1.5-.4 2.85-.5 1.5-1.6 2.75-.2.25-.35.45-.05 0-.05.05-.1.05-.15.1.25.15.45.35l.2.1q.1.05.25.15.85.4 1.75.7.1 0 .2.05.2-.25.45-.5.3-.35.55-.7 1.1-1.4 1.6-3.1.4-1.55.4-3.25 0-1.5-.3-2.85-.3-1.15-.95-2.25-.1-.15-.2-.35m7.1 2.05q-.05-.2-.15-.35-1.3-.95-2.85-1.35-.45-.15-.9-.25 0 .05.05.1 0 .05.05.1.05.15.15.25.3.45.5 1 .1.15.15.35.2.45.3.9.3 1.35.3 2.85 0 1.7-.4 3.25-.5 1.7-1.6 3.1-.15.2-.25.35-.15.2-.3.35-.15.15-.25.3l-.2.2q-.1.1-.2.15h.15q.45 0 .95-.05 1.1-.15 2.15-.5l.7-.3q.1-.15.25-.35 1.1-1.4 1.6-3.1.4-1.55.4-3.25 0-1.5-.3-2.85-.1-.45-.3-.9z"
                    id="PetPet028__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M312.45 165.6q-.95-1.1-2.4-1.1-1.4 0-2.4 1.1-1 1.15-1 2.7 0 1.6 1 2.7 1 1.15 2.4 1.15 1.45 0 2.4-1.15.4-.4.65-.9.2.5.55.9 1 1.15 2.45 1.15 1.4 0 2.4-1.15.95-1.1.95-2.7 0-1.55-.95-2.7-1-1.1-2.4-1.1-1.45 0-2.45 1.1-.35.4-.55.9-.25-.5-.65-.9z"
                    id="PetPet028__Layer1_0_FILL"
                />
                <path
                    d="M313.5 168.2q0 .35.25.55.25.3.6.3t.6-.3q.25-.2.25-.55 0-.4-.25-.65-.25-.2-.6-.2t-.6.2q-.25.25-.25.65m-5.6.8q.35 0 .6-.3.25-.2.25-.55 0-.4-.25-.65-.25-.2-.6-.2t-.6.2q-.25.25-.25.65 0 .35.25.55.25.3.6.3z"
                    id="PetPet028__Layer1_0_MEMBER_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M336.6 157.15q0-2.3-1.5-3.65t-4.2-1.35q-6.7 0-6.7 10 0 1.9.45 4.85.2 1.25.3 2.15.6-.35 1.1-.65.25-.15 3.5-2.35 2.3-1.65 4.15-3.55 2.9-3.15 2.9-5.45z"
                    id="PetPet028__Layer1_1_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M329.45 159.3q.5-4.25-.85-6.1-1.4-1.85-3.45-2.05-1.95-.25-4.15 1.35-5.4 4 .55 12.05.25.3.5.65 3-1.25 6-.35.05.05.15-.25.9-2.65 1.25-5.3z"
                    id="PetPet028__Layer1_2_FILL"
                />
            </defs>
            <use
                xlinkHref="#PetPet028__Symbol_99_0_Layer0_0_FILL"
                transform="translate(304.65 155.9)"
                id="PetPet028__col1n"
            />
            <use
                xlinkHref="#PetPet028__Symbol_100_0_Layer0_0_FILL"
                transform="translate(307.15 163.6)"
                id="PetPet028__col2n"
            />
            <use xlinkHref="#PetPet028__Layer1_0_FILL" />
            <use xlinkHref="#PetPet028__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#PetPet028__Layer1_1_FILL" />
            <use xlinkHref="#PetPet028__Layer1_2_FILL" />
        </g></g>
    )
}

export default PetPet028
