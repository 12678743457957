import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col1s": "#BD7B00"
}
function FacMou077({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M98.1 28.3q0-1.2-2.4-3.15-2.85-2.35-12.9-6.45-11.3-4.8-18.3-6-.05-6.1-4.75-9.7-4.1-3.2-10.3-3h-.8q-6.2-.2-10.3 3-4.7 3.6-4.75 9.7-7 1.2-18.3 6-10.05 4.1-12.9 6.45Q0 27.1 0 28.3q0 4.1 4.9 5.05 1 .2 2 .2h1.35q6.85 0 15.6-3 2.45-.85 4.9-1.45.35.65.95 1.4.1.05.15.15l.5.5q1.5 1.3 4.15 2.6 5.75 2.75 14.15 3.15h.8q8.4-.4 14.15-3.15 2.65-1.3 4.15-2.6l.5-.5q.05-.1.15-.15.6-.75.95-1.4 2.45.6 4.9 1.45 8.75 3 15.6 3h1.35q1 0 2-.2 4.9-.95 4.9-5.05z"
                    id="FacMou077__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M39.5 2.8q.05-.1.15-.15.6-.75.95-1.4-3.7-.7-6.15-.95-2.5-.25-4.25-.3H10.4Q8.65.05 6.15.3 3.7.55 0 1.25q.35.65.95 1.4.1.05.15.15 8.4-1.3 13.75-1.2l5.45.05 5.45-.05q5.35-.1 13.75 1.2z"
                    id="FacMou077__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1.35 2.7q.55 0 .95-.4.4-.4.4-.95Q2.7.8 2.3.4 1.9 0 1.35 0 .8 0 .4.4q-.4.4-.4.95 0 .55.4.95.4.4.95.4m10.3 0q.55 0 .95-.4.4-.4.4-.95 0-.55-.4-.95-.4-.4-.95-.4-.55 0-.95.4-.4.4-.4.95 0 .55.4.95.4.4.95.4z"
                    id="FacMou077__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou077__Symbol_102_0_Layer0_0_FILL"
                transform="translate(143.45 195.15)"
                id="FacMou077__col1n"
            />
            <use
                xlinkHref="#FacMou077__Symbol_14_0_Layer0_0_FILL"
                transform="translate(172.2 223)"
                id="FacMou077__col1d"
            />
            <use
                xlinkHref="#FacMou077__Symbol_10_0_Layer0_0_FILL"
                transform="translate(186 204.05)"
                id="FacMou077__col1s"
            />
        </g></g>
    )
}
export default FacMou077
