import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999"
}

function BodMor002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.3 10.05q-1.55-1.6-2.95-3.35l-.1-.1H8V0H0v21.4h8v-6.8h21.3q-.8-.6-1.5-1.25-.2-.15-.35-.3-1.55-1.35-3-2.85l-.15-.15M184.25 0h-8v6.6H163l-.1.1q-1.4 1.75-2.95 3.35l-.15.15q-1.45 1.5-3 2.85-.15.15-.35.3-.7.65-1.5 1.25h21.3v6.8h8V0z"
                    id="BodMor002__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.8 0H0v8h2.8V0m165.45 0h-2.8v8h2.8V0z"
                    id="BodMor002__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodMor002__Symbol_4_0_Layer0_0_FILL"
                transform="translate(100.4 211.5)"
                id="BodMor002__col1n"
            />
            <use
                xlinkHref="#BodMor002__Symbol_5_0_Layer0_0_FILL"
                transform="translate(108.4 218.1)"
                id="BodMor002__col1d"
            />
        </g></g>
    )
}

export default BodMor002
