import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#794D32",
    "col1s": "#2A180D",
    "col2d": "#121212",
    "col2l": "#434343",
    "col2s": "#000000"
}

function ObjToo056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M204.45 14.9l11.5-1.1 2.25-9 5.85-4.8-52.65 4.9-89.05 9.2q.45.35.85.75l.05.05q1.55 1.55 2.05 3.5.2.9.2 1.9 0 3.15-2.25 5.4-2.25 2.25-5.4 2.25h-.75l-3.9-1.6-.5-.4q-.05 5.95-8.7 5.95-6.7 0-9.75-3.4-1.3-1.45-1.75-3.1-.6-1.4-.6-3.1 0-.65.1-1.2-.05-.1-.05-.2l-1.9-.45q-7-1.8-14.1-1.05L.1 21.6q-.7 18.45 3.95 36.55Q26.2 49 48.3 45.1q6-.9 11.75 1.4l-1.2 2.55q5.75 3.35 11.85 1.2.75-14.05 7.8-19.95.1-.1.2-.15.25.05.55.05 1.15 0 1.15-.9 0-.2-.1-.35l.1-.1q2.45-1.5 5.5-2.25.15-.05.3-.05.15.05.4.05.35 0 .6-.2l5.45-.55q.9.65 2.4.65 1.55 0 3.5-1 .25-.15.5-.25l93.25-9.15h.1l.1-.1q.3-.05.65-.05.3-.4.7-.8 2.25-2.25 5.4-2.25 1.9 0 3.45.8.9.45 1.7 1.2h.05m-47.55-3.35v.15h-1.65l1.65-.15z"
                    id="ObjToo056__Symbol_215_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M34.15 8.45l1.05-.1-.7-6.95 3.25-.3.65 6.9 1.05-.1-.7-7.45q-.05-.2-.2-.35-.2-.15-.4-.1L16.4 2.15v.15h-1.65L.45 3.7q-.2 0-.35.2-.15.15-.1.35l.7 7.45 1.05-.1-.65-6.9 3.1-.3.7 6.9 1.05-.1-.7-6.9 3.15-.35.65 6.95 1.05-.1-.65-6.95 3.1-.3.7 6.95 1.05-.15-.7-6.9 3.15-.3.65 6.9 1.05-.1-.65-6.9 3.1-.3.7 6.9 1.05-.1-.65-6.9 3.1-.3.7 6.9 1.05-.1-.7-6.85v-.05l3.15-.35.65 6.95 1.05-.1-.65-6.95 3.1-.3.7 6.95z"
                    id="ObjToo056__Symbol_111_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M62.95 72.45l12.7 1.15q0-.95.05-1.85l-11.85-1.2-.9 1.9M5.1 43.1l-5.1.5 3.7 37.65 5.35-1.6L5.1 43.1M94 13.15l-6.4-2.05-13.5 1.35 1.45 14.75 13.5-1.35 5.9-3.2-.95-9.5m45.65 5.15l-.05-.05-.9-9.45-12.55 1.2v-.1l-12.1 1.25v.05l-13.2 1.3.9 9.45 8.65-.8 4-.4 25.25-2.45m43.35.3L181.15 0 157.1 2.35l.25 2.45-12.2 3.35.9 9.5 12.65.85.25 2.5L183 18.6M319.2 17l-.75-7.4-235.2 23.05q-.15-.15-.3-.2-.05-.05-.1-.05-.4-.25-.95-.3-1.05-.6-1.95-.5-1.9.2-2.45.95-.1-.35-.5.25-.05.05-.1.15-.5.8-.3 3.35.05.2.05.45.1 1.25 1.05 2.9.9 1.55 2.05 1.95.8-.65 1.65-1.2.9-.65 1.9-1.15.1-.1.25-.15 2.35 3.6 5.55 4.55 0-.05.05-.05.45.25.85.2.2.15.3.15h.2q.35.05.8.05.55 1.8 2.7 1.6 1.25-.15 1.9-1.2.55-.85.5-1.7-.45-2.65-2.9-2.4-1.35.15-1.95 1.15-.15.2-.2.4-.25-.1-.45-.1-.3-.05-.55-.1-.35-.15-1-.2v-.05q-1.9-.5-2.5-1.3-.5-.75-1.55-1.9 1.55-.7 3.3-1.25.05 0 .15-.05 3.3-1.05 7.25-1.5 1.45-.15 3.05-.25L224 22.95l-.85 3.45L319.2 17z"
                    id="ObjToo056__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M0 .3v.05l.3 3 3.3-.3L3.3 0 0 .3z"
                    id="ObjToo056__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M8.95 46.7q0-1.3-.05-2.05l-.8.1q-.4.05-.7.15-.05.55-.15 1.2-.15.6.55 2.6 1.25 1.85 2.8 2 1.55.05 1.5-.5-.05-.45-1.5-1.25-1.5-.85-1.65-2.25m-6.4 2.5q-.1-.2-.65-2.05-1 .65-1.9 1.45 2.95 6.55 9.25 5.85 5.9-.7 8.3-5.95l.3-.7q1.65-3.95 3.45-4.35l-5.15.5-.05.05v-.05l-2.5.25q1.85.9 1.55 2.6v.05q-1.15 5.4-6.05 5.5-4.9.1-6.55-3.15m14.8-39.9v-.05l-.2-1.6-6.8.7 1.1 11.1 2.2 1.85-1.5 3.8 6.85-.6-2.25-3.5 1.5-2.2v-.05l-.9-9.45m25.3-2.6l-.15-1.5-4.8.5v-.05l-.3-3.05 1.45-.15L38.6 0l-6.15.6.25 2.45 1.4-.1.3 3 .05.05-4.1.4.2 1.55 12.1-1.25m1.1 11.5l-.2-1.9-12.1 1.2.2 1.85 12.1-1.15M61.6 4.95l-.15-1.6-6.45.6.2 1.5v.15l.9 9.45.05.05 2.2 1.75-1.35 3.9 6.3-.7-1.9-3.5 1.15-2.1-.9-9.5h-.05z"
                    id="ObjToo056__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M46.4.9l-.05.1L8.4 4.7v-.05l-6.85.6-.05.1L0 5.5l.3 3 56.4-5.55L56.45 0l-3.7.35L52.7.2l-6.3.7z"
                    id="ObjToo056__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    id="ObjToo056__Symbol_90_0_Layer0_0_1_STROKES"
                    stroke="#000"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M110.4 21.15l4-.4"
                />
            </defs>
            <use
                xlinkHref="#ObjToo056__Symbol_215_0_Layer0_0_FILL"
                transform="translate(54.1 271.3)"
                id="ObjToo056__col1n"
            />
            <use
                xlinkHref="#ObjToo056__Symbol_111_0_Layer0_0_FILL"
                transform="translate(194.6 280.7)"
                id="ObjToo056__col1s"
            />
            <g transform="translate(49.1 249.8)" id="ObjToo056__col2n">
                <use xlinkHref="#ObjToo056__Symbol_90_0_Layer0_0_FILL" />
                <use xlinkHref="#ObjToo056__Symbol_90_0_Layer0_0_1_STROKES" />
            </g>
            <use
                xlinkHref="#ObjToo056__Symbol_45_0_Layer0_0_FILL"
                transform="translate(166.7 255.6)"
                id="ObjToo056__col2l"
            />
            <use
                xlinkHref="#ObjToo056__Symbol_20_0_Layer0_0_FILL"
                transform="translate(132.6 253)"
                id="ObjToo056__col2d"
            />
            <use
                xlinkHref="#ObjToo056__Symbol_6_0_Layer0_0_FILL"
                transform="translate(143.2 272.85)"
                id="ObjToo056__col2s"
            />
        </g></g>
    )
}

export default ObjToo056
