import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FB0D0D",
    "col2d": "#006AC9",
    "col2n": "#0087FF"
}

function ObjToy050({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#999"
                    d="M125.1 289.45q.032-.16-.4.3.056.022.1 0 .2-.1.3-.3m-62.3-120.8q-.1-.2-.3-.25-.25-.1-.45 0t-.25.35q-.1.2 0 .4l62.25 120.35q.1.2.35.3l.5-1.1-12.85-140.8q0-.25-.2-.35-.15-.15-.4-.15t-.35.2q-.15.15-.15.4l12.6 138.1L62.8 168.65z"
                    id="ObjToy050__Layer14_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    fillOpacity={0.922}
                    d="M96.45 37.35q-3.55-11.65-11.8-21-6.8-7.7-14.95-11.75-18.2-9.15-40.35 0-8.2 4.05-15 11.75-8.25 9.35-11.8 21Q-.05 45.9 0 55.7q-.05 6.9 1.3 13.25h.05q3.05 18.6 13 33.1 3.1 4.5 6.5 8.1 7.8 8.2 17.15 11.25 2.45.75 5 1.2 1.8.4 6.9 2.05-.15.05-.3.15h3.3q3.15-1.25 8.1-3.4 9.35-3.05 17.15-11.25 3.4-3.6 6.5-8.1 9.95-14.5 13-33.1h.05Q99.05 62.6 99 55.7q.05-9.8-2.55-18.35z"
                    id="ObjToy050__Symbol_134_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M17.35 6.5q.3-.5.55-.95.25-.55.4-1.1.6-2 .3-4.15V0q-4 1.25-8.25 1.6-.6 0-1.25.05-.8.05-1.65.05h-.7q-.85 0-1.65-.05-.65-.05-1.25-.05Q2.2 1.45.6 1.2 0 3.5 0 3.55q0 .45.05.9.1 1.15.4 2.05 1.6 3.9 7.85 3.9.85 0 1.6-.05 5.3-.45 7.45-3.85z"
                    id="ObjToy050__Symbol_83_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    fillOpacity={0.922}
                    d="M95.8 37.3q-.9-2.9-2.05-5.65-4.6-12.35-15.3-20.8-.3-.3-.65-.6-5.45-4.5-11.7-7l-.2-.1Q58.15 0 49.2 0q-6.95 0-13.15 1.85-12.15 3.7-21.6 14.45-5.5 6.15-8.9 13.35 0 .05-.05.1Q0 41.35 0 55.65q0 6.9 1.35 13.25h.05q.15.75.3 1.6 1.9 10.45 6.1 19.65h.1q.1.2.15.35 2.1 4.35 4.65 8.45.7 1.2 1.5 2.3.15.2.25.35 1.3 1.95 2.7 3.7 2.05 2.6 4.15 4.8.7.7 1.45 1.4 10.85 9.8 23.6 10.55.05 0 1.25.3t2.9.7q1.7.35.85 0-.9-.35 2.35.4t4.85-.6q1.6-1.35 1.8-1.4l1.2-.4q0-.05.05-.05 8.6-3.25 15.9-10.9 2.55-2.7 4.95-5.95.8-1.05 1.55-2.15.25-.4.5-.75 2.5-3.75 4.55-7.75l.4-.8q-.1-.1-.2-.15l.25.1q5.35-10.9 7.5-23.75h.05q1.35-6.35 1.3-13.25.05-9.8-2.55-18.35z"
                    id="ObjToy050__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M17.6.3l-.1-.1-.15-.2-1.2.4q-.2.05-.45.1-2.35.45-8.8.35h-.85q-.75 0-1.5.05-1.25 0-2.3.1h-.1Q.9 3 0 6.35q0 5.8 9.05 5.8Q15 12.15 17.4 8q.25-.4.4-.85.1-.15.1-.25.4-.85.6-1.85 0-.15.05-.25.1-.5.1-1v-.05-.15q0-1.9-1.05-3.3z"
                    id="ObjToy050__Symbol_12_0_Layer0_0_FILL"
                />
                <g id="ObjToy050__Layer5_0_FILL" fill="#FFF">
                    <path
                        fillOpacity={0.8}
                        d="M27.25 74.35l-7.5-2.55q-5.7 8.35-7.4 16.7-.314 1.665-.5 3.3l8.65 4.05q.266-2.165 1.4-6.7 1.899-7.247 3.5-10.85 1.65-3.553 1.85-3.95m64-20.05q1.6-3.6 1.8-4l-7.5-2.55q-1.323 1.938-2.45 3.85 3.772 3.4 6.5 6.95.859-2.547 1.65-4.25z"
                    />
                    <path
                        fillOpacity={0.2}
                        d="M86.35 71.8q.2-2.15 1.4-6.65.963-3.798 1.85-6.6-2.728-3.55-6.5-6.95-3.645 6.438-4.95 12.85-.3 1.65-.5 3.35l8.7 4z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy050__Layer14_0_FILL" />
            <use
                xlinkHref="#ObjToy050__Symbol_134_0_Layer0_0_FILL"
                transform="translate(59.8 16.2)"
                id="ObjToy050__col1n"
            />
            <use
                xlinkHref="#ObjToy050__Symbol_83_0_Layer0_0_FILL"
                transform="translate(102.2 137.6)"
                id="ObjToy050__col1d"
            />
            <use
                xlinkHref="#ObjToy050__Symbol_46_0_Layer0_0_FILL"
                transform="translate(3.1 39.65)"
                id="ObjToy050__col2n"
            />
            <use
                xlinkHref="#ObjToy050__Symbol_12_0_Layer0_0_FILL"
                transform="translate(47.3 160.7)"
                id="ObjToy050__col2d"
            />
            <use xlinkHref="#ObjToy050__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToy050
