import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C40000"
}

function ShiMot029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M8.45-.7q-.25.2-.35.5-.1.3 0 .65l4.75 14.5q.05.15.2.3-.2.05-.35.15l-12.35 9q-.25.2-.35.5-.1.3 0 .6t.4.5q.25.2.55.2h15.6l4.85 14.95q.1.3.35.5.25.2.6.2.3 0 .6-.2.25-.2.35-.5l4.85-14.95h15.7q.35 0 .6-.2t.35-.5q.1-.3 0-.6t-.35-.5L31.7 15.2 36.4.3q.1-.3 0-.6t-.35-.5q-.3-.2-.6-.2-.35 0-.6.2L22.5 8.25q-.15.1-.2.25l-.25-.25L9.65-.7q-.3-.2-.6-.2-.35 0-.6.2m13.9 11.55q.1.1.25.2l6.75 4.9-2.65 8.25H18l-2.6-7.95q-.05-.15-.1-.25l7.05-5.15M28.8 24.2l2.25-7.05 9.75 7.05h-12m4.8-21.55L30 13.95l-6-4.3 9.6-7M13.65 17.2l2.25 7H4.05l9.6-7m1-3.2L11 2.75l9.6 6.95-5.95 4.3m11.4 12.2l-3.7 11.45-3.7-11.45h7.4z"
                    id="ShiMot029__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot029__Symbol_35_0_Layer0_0_FILL"
                transform="translate(170.15 261.1)"
                id="ShiMot029__col1n"
            />
        </g></g>
    )
}

export default ShiMot029
