import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E5B000",
    "col2n": "#FFD446"
}

function AccJew021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.75 1.1q.15-.2.15-.45 0-.3-.2-.45-.2-.2-.45-.2-.3 0-.45.2-23 24.1-57.3 39.7-.15.05-.25.1-.25.1-2.2 1.2-.1 0-.15.05-.6-.35-1.1-.65-.9-.5-1.1-.6l-.3-.1Q24.1 24.3 1.15.2.95 0 .7 0 .4 0 .2.2 0 .35 0 .65q0 .25.2.45 23.2 24.4 57.95 40.1.1.05.25.05.1 0 1.8.4-.95.6-.3.55.6-.1 1.4-.3.25-.05.55-.15.1-.05.25-.05 1.3-.45 1.4-.45.15 0 .3-.05 34.7-15.7 57.95-40.1z"
                    id="AccJew021__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M15 10.15v-5.1h-4.95V1.6q-.1 0-.2-.05-.3-.1-.4-.3-.1-.25 0-.5.1-.3.35-.4.1-.05.25-.1V0h-5.1v.25l.3.1q.2.1.3.4.1.25 0 .5-.1.2-.35.3-.15.05-.25.05v3.45H0v5.1h4.95v9.75h5.1v-9.75H15z"
                    id="AccJew021__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew021__Symbol_91_0_Layer0_0_FILL"
                transform="translate(131.55 228.55)"
                id="AccJew021__col1n"
            />
            <use
                xlinkHref="#AccJew021__Symbol_34_0_Layer0_0_FILL"
                transform="translate(185 268.2)"
                id="AccJew021__col2n"
            />
        </g></g>
    )
}

export default AccJew021
