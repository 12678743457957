import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function ShiMot025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.75.7q-1.7-.5-3.5-.65Q23 .5 24.35 1.2 23 .55 21.3.1q-.95-.1-1.95-.1-8 0-13.7 5.7Q0 11.35 0 19.35t5.65 13.7Q9.7 37.1 15 38.25l-.8-.5q.35.2.75.45.1 0 .25.05.9.2 1.9.3l13.95-15.2V5.95L24.75.7z"
                    id="ShiMot025__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.8 11.35q.75-.75.75-1.85 0-1.05-.75-1.8-.75-.8-1.8-.75-1.1-.05-1.85.75-.8.75-.75 1.8-.05 1.1.75 1.85.75.75 1.85.75 1.05 0 1.8-.75m17.35 7.9q0-8-5.7-13.65Q18.5.6 11.75 0q1.75.45 3.1 1.15l1.25.9q3.25 3.1 3.4 7.2-.05 2-.7 4.45-1.2 2.7-4.2 4.4-.65.3-1.35.6-.95.35-1.9.5-.5 0-.9.05-.3 0-.55.05-3.1.35-4.45 1.15-4.2 2.4-4.95 5.8-.6 2-.45 3.8.2 5.45 5.65 8.15 1.95.4 4.1.4 8 0 13.65-5.65 5.7-5.7 5.7-13.7m-17.35 8q.75.75.75 1.85 0 1.05-.75 1.8t-1.8.75q-1.1 0-1.85-.75-.8-.75-.75-1.8-.05-1.1.75-1.85.75-.8 1.85-.75 1.05-.05 1.8.75z"
                    id="ShiMot025__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot025__Symbol_31_0_Layer0_0_FILL"
                transform="translate(173.15 264.5)"
                id="ShiMot025__col1n"
            />
            <use
                xlinkHref="#ShiMot025__Symbol_6_0_Layer0_0_FILL"
                transform="translate(182.65 264.55)"
                id="ShiMot025__col2n"
            />
        </g></g>
    )
}

export default ShiMot025
