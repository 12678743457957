import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FFCC00"
}

function FacMou023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M205.3 217.2q-.333-.093-.75-.15-1.07-.287-2.35-.65-.754-.189-1.45-.35-4.037-.851-8.2-.85-4.089.014-8.3.85-.646.161-1.45.35-1.23.363-2.3.65-.417.057-.8.15h.1q-2.438.595-3.6.65h-.2q-4.299-.003-6.95-.4h.2q-.265-.025-.65-.1-.18.172-.25.4-.028.234.05.45.922 1.86 2.2 4.15.69 1.26 1.5 2.65.02.05.05.1.671 1.162 2.5 3l.9.9q1.198 1.09 2.25 1.85h.05q1.086.85 2.4 1.5 2.38 1.203 5.5 2.1.225.075.4.1.376.117.7.2 2.887.83 5.7.8 2.837.037 5.6-.8.376-.083.7-.2.225-.025.4-.1h.05q3.12-.897 5.45-2.1 1.364-.65 2.45-1.5 1.102-.76 2.25-1.85h.05l.9-.9q1.829-1.838 2.5-3 .03-.05.05-.1.81-1.39 1.5-2.65 1.277-2.29 2.15-4.15.128-.216.05-.45-.02-.228-.2-.4l-.2.05q-2.238.447-7.2.45h-.2q-1.136-.05-3.6-.65h.05z"
                    id="FacMou023__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.7 5.6V.05H4.05Q3.5-.05.5.25l-.5.1v4.9q3.35.55 5.7.35M12.15.35Q11.9.3 11.7.25q-3-.3-3.5-.2H6.5V5.6q3.05.25 5.65-.35V.35z"
                    id="FacMou023__Symbol_113_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M4.4 13.95q2 1 4.6 1.8l.05-4q-3.05.2-4.65.65v1.55M9.8 16q.05 0 .15.05 3.15.8 5.45.9h.1l.05.05v-5.25l-5.7-.1L9.8 16M3.6 1.4h-.1q-2.25.5-3.5.65v1.1l3.6.65V1.4m5.45 3.25V0Q8 .2 6.6.6q-1.2.3-2.2.6v2.75l4.65.7m12.9 11.4Q22 16 22.1 16l-.1-4.35-5.65.1V17h.1q2.3-.15 5.5-.95m5.5-3.8q-2.5-.6-4.6-.55l.05 4.05q2.55-.8 4.55-1.8v-1.7m0-11.05q-1-.3-2.15-.6-1.45-.4-2.5-.6v4.65l4.65-.7V1.2m4.4 1.9V2.05q-1.25-.15-3.5-.65h-.1v2.4l3.6-.7z"
                    id="FacMou023__Symbol_48_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou023__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou023__Symbol_113_0_Layer0_0_FILL"
                transform="translate(186.45 216.75)"
                id="FacMou023__col2n"
            />
            <use
                xlinkHref="#FacMou023__Symbol_48_0_Layer0_0_FILL"
                transform="translate(176.6 217.2)"
                id="FacMou023__col1n"
            />
        </g></g>
    )
}

export default FacMou023
