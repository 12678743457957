import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1n": "#D19532",
    "col1s": "#644D27"
}

function ShiLon024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M56.2 33.5Q55.05 23.15 50.45 19 38.9 15 28.65 7.9L27.1 6.75l-1.2-.9q-.85-.6-1.6-1.2l-1.55-1.2.15.1q-7.6-6.3-11.35-1.35Q10.2 4 9.3 5.85q-1.5 3.2-1.5 6.5.05 4.95 4.8 7v.05q-.3.5-.5 1-.05.05-.05.1-.45.9-.9 1.85-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05L9.2 56q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 4.8 1.1 9.6 2 11.85 2.3 23.7 3.55v-.1l.5.05q.7.05 1.4.15V35.5q0-1.05-.1-2m106.5 14.65q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-.45-.95-.9-1.85l-.5-1.1q4.7-2.3 4.75-7.05 0-3.5-1.25-6.5-.6-1.45-1.5-2.8-4.15-5.75-12.25.45v.05l-1.5 1.1q-.75.6-1.55 1.2-.65.45-1.25.9l-1.55 1.15Q126.1 15 114.55 19q-4.6 4.15-5.75 14.5-.1.95-.1 2v55.4q.7-.1 1.4-.15l.5-.05h.5q-.05.002-.05.05 11.65-1.25 23.3-3.5 4.75-.9 9.45-2 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05L143 60.7q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V59.45q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiLon024__Symbol_298_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22 .45h-.05q-1 .1-2 .25-4.45.65-8.1 1.75-3.65 1.1-5.65.65Q4.45 2.65 1 .55.7.3.55.2q-.3.5-.5 1-.05.05-.05.1 1.7 1.25 2.55 1.6.9.4 1.4.65.5.2 2.2.65 1.65.45 5.6-.7 3.95-1.15 6.7-1.55 2.7-.45 7.6-.95 4.3-.45 9.1 4.95 3.2 4.55 6.05 12.6-.95-6.8-4.85-12.6-5-7.6-14.35-5.5m118.35-.2q-.2.1-.45.3-3.45 2.1-5.2 2.55-2 .45-5.65-.65-3.65-1.1-8.1-1.75-1-.15-2-.25h-.05q-9.35-2.1-14.35 5.5-3.9 5.8-4.85 12.6 2.85-8.05 6.05-12.6 4.8-5.4 9.1-4.95 4.9.5 7.6.95 2.75.4 6.7 1.55t5.6.7q1.7-.45 2.2-.65.5-.25 1.4-.65.85-.35 2.55-1.6l-.5-1.1-.05.05z"
                    id="ShiLon024__Symbol_267_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65M116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4z"
                    id="ShiLon024__Symbol_235_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M247.6 264.45v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35m-110.95-.5q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1z"
                    id="ShiLon024__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon024__Symbol_298_0_Layer0_0_FILL"
                transform="translate(110 225.35)"
                id="ShiLon024__col1n"
            />
            <use
                xlinkHref="#ShiLon024__Symbol_267_0_Layer0_0_FILL"
                transform="translate(122.05 244.55)"
                id="ShiLon024__col1d"
            />
            <use
                xlinkHref="#ShiLon024__Symbol_235_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiLon024__col1s"
            />
            <use xlinkHref="#ShiLon024__Layer1_0_FILL" />
        </g></g>
    )
}

export default ShiLon024
