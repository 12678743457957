import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C67D9A"
}

function SkiWou011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.6 6.45q3.193-1.802 8.65-5.1 5.502-3.3-5.75 1.7Q35.25 8 33.15 8.8 27 11.1 26.9 11.15q-3.85 1.35-5.75 1.75.1.5.9 1.5.9 1.25.9 2.35 0 3.4-8 6.5-8.6 2.65-9.85 3.35-8.75 3.55-2.55 1.85Q8.7 26.7 9.1 26.6q1.75-.45 4.4-.95 1.945-.595 6.4-1.7 4.516-1.116 5.9-2.3 1.378-1.179 1.4-2.45.002-.353.15-1.5.186-1.09.05-3.85.905-.246 2.05-.65 6.9-2.45 14.15-6.75z"
                    id="SkiWou011__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou011__Symbol_19_0_Layer0_0_FILL"
                transform="translate(172 104.05)"
                id="SkiWou011__col1n"
            />
        </g></g>
    )
}

export default SkiWou011
