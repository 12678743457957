import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00498A"
}

function SkiWou007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M51.6 16.25q-2.4-3.2-5.15-6.3Q40.5 3.3 32.7 1.05q-8.35-2.1-15.25 0-9.7 3-13.85 10.2l-.65 1.2q-5.65 10.7-.5 27.1.4 1.75 1.15 3.45.45 1 1 2 6.7 12.1 19.55 16.35 17.95 4.1 27.6-6.75 7.65-9.85 6.55-22.4 0-.2-.05-.3-.1-.8-.25-1.55-1.5-7.6-6.35-14.05 0-.05-.05-.05z"
                    id="SkiWou007__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou007__Symbol_14_0_Layer0_0_FILL"
                transform="translate(192.7 135.95)"
                id="SkiWou007__col1n"
            />
        </g></g>
    )
}

export default SkiWou007
