import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M204.55 221.8q-.113-.024-.3-.05-10.884-1.725-21.95 0-.337.051-.7.1h.05q-8.315 1.215-8.15 3.85-.132.973 1.9 2.15 1.738 1.012 3.2 1 1.025 0 2.05-.4.609-.253 1.15-.3.061-.008.1-.05.143.016.35.05.063.003.1 0 .321-.006.5 0 .182.098.75.55h.05q.172.144.35.25 2.691 1.828 5.55 0 .453-.249.9-.6.024.013.05 0 1.11-.905 2.1-1.05.075-.01.1-.05.171.01.65.05h.05q.492.042 1.9.85 1.888 1.038 2.8 1 .088-.002.15-.05.205-.012.45-.05l.05-.05q.797-.128 1.85-.8 1.176-.669 1.75-.7.72.01 1.7.45H204q.215.115.45.25 1.703.862 3.05.85 1.48-.005 2.55-.4h.05q1.683-.657 1.6-1.9.081-1.324-1.85-2.9h-.05q-2.044-1.66-5.25-2.05z"
                    id="FacMou014__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M6.45 4.4V.95Q.15 1.9 0 3.3q.1.3 1.2.95 1.35.8 2.5.8.75 0 1.55-.3.6-.25 1.2-.35m4.85 1.5h.35V.3q-1.8.15-3.7.4l-.7.1v3.55h.3q.4 0 .7.1.3 0 1.3.75.7.45 1.7.7h.05m5.95-2.35L17.2.05 17.15 0q-2.3 0-4.7.25v5.6q.2-.05.45-.05.9-.3 1.85-.95h-.05q1.3-1.05 2.55-1.3m5.9 1.8q.05 0 .15-.05L23.2.2q-3.85-.3-5.05-.15h-.05q-.05 0-.05.05H18l.05 3.4h.5q.7 0 2.55 1.05 1.35.75 2.05.8m4.3-1.65q.7 0 1.65.35l.05-3.3Q27.85.55 24 .3l.1 4.8q.4-.2.9-.5 1.6-.95 2.45-.9m5.15 1.55q1.2 0 2.15-.3h-.05q.6-.25.7-.6-.1-.75-1.35-1.8h.05q-1.7-1.3-4.15-1.7l-.1 3.55q.2.05.4.2 1.3.65 2.35.65z"
                    id="FacMou014__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou014__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou014__Symbol_39_0_Layer0_0_FILL"
                transform="translate(174.9 222.4)"
                id="FacMou014__col1n"
            />
        </g></g>
    )
}

export default FacMou014
