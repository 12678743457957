import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#77BFFF",
    "col1n": "#43A6FF",
    "col2l": "#D4D6E5",
    "col2n": "#A5A8BF"
}

function AccJew007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M122.75 9.05q1.25-4.75.55-7.5-.35-3.1-2.55 0-.6 1.8-1.5 3.35-.1.25-.3.5l-.1.2q-2.45 3.25-5.1 5.75-4.7 5.45-10.45 7.2l-.8.2q-2.3.4-5.3.95l-1.75.2h-.1l-7.6.75q-1.1.05-2.15.2-.85.05-1.7.15-10.95 1.35-18.8 6.15-.15.1-.35.2 0 .05-.1.15-.55.5-1.05 1.15-.1.15-.25.35-.05.1-.05.15l-.05.05q-.05-.05-.1-.05-.9-.35-2.7 0-.1 0-.15.05v-.05q-.05-.05-.1-.15-.15-.2-.25-.35-.5-.65-1.05-1.15-.1-.1-.1-.15-.2-.1-.35-.2-7.85-4.8-18.8-6.15-.85-.1-1.7-.15-1.05-.15-2.15-.2l-7.6-.75q-7-1.1-7.95-1.35-5.75-1.75-10.45-7.2-2.65-2.5-5.1-5.75l-.1-.2q-.2-.25-.3-.5-.9-1.55-1.5-3.35-2.2-3.1-2.55 0-.7 2.75.55 7.5 3.5 10.6 15.95 14.5.4.1.75.2.9.3 2.5.55.05 0 .2.05.9.2 3.7.65l-.05.05 4.1.35q18.65 1.3 28.5 6.9.45.2.65.35.2.15 2.6 1.35 2.35 1.2 4.45-.05 2.05-1.3 2.05-1.25.1-.05.25-.1.3-.15.65-.3 9.85-5.6 28.5-6.9l4.1-.35q.8-.1 3.45-.55v.05q.75-.15 2.9-.8.35-.1.75-.2 12.45-3.9 15.95-14.5z"
                    id="AccJew007__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M8.85 2.65Q8.6 1.5 7.3.75q-1.5-1-2.95-.7-1.55.4-2.55 1.6Q.4 3.3.05 6.4v.2Q0 6.75 0 6.95v.2q.1.7.6.9.5.2 1.55.3.9.05 1.3-.65l.05-.05v-.1q.15-1.35.95-2.85.05-.1.1-.25.5-.95 1.4-2.1l1.75.2h.1l1.05.1M43.2 11.3q.5-.65 1.05-1.15.1-.1.1-.15.2-.1.35-.2-.95-.55-3.25-.6-2.4.05-3.4.6.15.1.35.2 0 .05.1.15.55.5 1.05 1.15.1.15.25.35.05.1.1.15v.05q.05-.05.15-.05 1.8-.35 2.7 0 .05 0 .1.05l.05-.05q0-.05.05-.15.15-.2.25-.35m39.5-4.7v-.2q-.35-3.1-1.75-4.75-1-1.2-2.55-1.6-1.45-.3-2.95.7-1.3.75-1.55 1.9l1.05-.1h.1l1.75-.2q.9 1.15 1.4 2.1.05.15.1.25.8 1.5.95 2.85 0 .05.05.1v.05q.4.7 1.3.65 1.05-.1 1.55-.3.5-.2.6-.9v-.2q0-.2-.05-.35z"
                    id="AccJew007__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.75.5q-.65.75-.7 2.65-.2 3.35.4 4.8.9 3.05 2.05 3.1h.4q1.15-.05 2.05-3.1.55-1.45.4-4.8Q5.3 1.25 4.6.5 2.7-.45.75.5z"
                    id="AccJew007__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M7.5.8Q6.9.45 5.95.25 5.9.2 5.85.2q-.9-.35-2.7 0-.1 0-.15.05-.95.2-1.55.55-.6.35-.95.8-.45.65-.45 1.95v.2q-.05.3.05.55-.1.75-.1 1.55Q0 7.8.05 8.2q.15 1.25.7 2.4.4.9 1.35 2.3.35.6.85 1.15.7.8 1.2 1 .05.05.25.1H4.55q.2-.05.25-.1.5-.2 1.2-1 .5-.55.85-1.15.95-1.4 1.35-2.3.55-1.15.7-2.4.05-.4.05-2.35 0-.8-.1-1.55.1-.25.05-.55v-.1-.1q0-1.3-.45-1.95-.35-.45-.95-.8m-1.35 2q.6.65.65 2.3.15 2.9-.35 4.2-.8 2.65-1.8 2.7H4.3q-1-.05-1.8-2.7Q2 8 2.15 5.1q.05-1.65.65-2.3 1.7-.85 3.35 0z"
                    id="AccJew007__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew007__Symbol_78_0_Layer0_0_FILL"
                transform="translate(130.55 228.15)"
                id="AccJew007__col1n"
            />
            <use
                xlinkHref="#AccJew007__Symbol_47_0_Layer0_0_FILL"
                transform="translate(150.95 245.5)"
                id="AccJew007__col1l"
            />
            <use
                xlinkHref="#AccJew007__Symbol_27_0_Layer0_0_FILL"
                transform="translate(189.6 258.85)"
                id="AccJew007__col2n"
            />
            <use
                xlinkHref="#AccJew007__Symbol_15_0_Layer0_0_FILL"
                transform="translate(187.85 257.1)"
                id="AccJew007__col2l"
            />
        </g></g>
    )
}

export default AccJew007
