import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#00284D",
    "col1s": "#000000",
    "col2n": "#000000"
}

function ShiSpe133({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95Q46.8 15.1 37.15 9.9q-4.35-2.4-8.5-5.25L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe133__Symbol_362_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M15.15 43.85q2.3 1.1 4.55 1.85 4 1.25 7.9 1.25 4 .05 7.9-1.15 2.2-.7 4.4-1.8v-8.8H15.15v8.65m1.3-.55l-.3-.1v-7H38.8v7.1q-10.9 5.4-22.35 0M5.2 27.55v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L0 48.75v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4M115.6 27q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1M93.2 46.95q4 .05 7.9-1.15 2.2-.7 4.4-1.8v-8.8H80.75v8.65q2.3 1.1 4.55 1.85 4 1.25 7.9 1.25M81.75 43.2v-7h22.65v7.1q-10.9 5.4-22.35 0l-.3-.1M108.45 0h-.1q-4.75 2.8-9.75 4.95L72.35 21.4q-5.2-7.1-11.35-8.85h-1.3q-6.2 1.75-11.4 8.85L22.15 5.05q-5.1-2.2-9.9-5.05l35.9 22.5q.15.1.35.05.2-.05.3-.2 5.05-6.95 10.9-8.75v67.1h1.2V13.55q5.9 1.8 10.95 8.8.1.15.3.2.2.05.35-.05L108.45 0z"
                    id="ShiSpe133__Symbol_361_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.3 14.9q0-.9-.65-1.55l-.15-.15q-.5-.4-1.2-.45h-.15q-.8 0-1.35.45-.1.05-.2.15Q0 14 0 14.9q0 .45.2.85.1.35.4.65.65.65 1.55.65h.15q.8-.05 1.35-.65.2-.2.35-.4.3-.5.3-1.1m42.45 26.3q-.15-.6-.6-1.05-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05m-2.2-21.45q-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65M42.95.65q-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6 0-.95-.65-1.6Q45.5 0 44.55 0q-.95 0-1.6.65m26.3 12.7l-.15-.15q-.5-.4-1.2-.45h-.15q-.55 0-1 .25-.2.05-.35.2-.1.05-.2.15-.6.65-.6 1.55 0 .45.2.85.1.35.4.65.25.25.55.4.45.25 1 .25h.15q.8-.05 1.35-.65.2-.2.35-.4.3-.5.3-1.1 0-.9-.65-1.55z"
                    id="ShiSpe133__Symbol_360_0_Layer0_0_FILL"
                />
                <g id="ShiSpe133__Layer2_0_FILL">
                    <path
                        fill="#FFFF62"
                        d="M262.05 243.95l-5.25 1.95 2.9 6.9v.05q.14.235.3.45l.25.65.05.05q.34.578.8.95 1.667 1.415 4.95.5.466-.129.95-.35l-.3-.75q-.05-.11-.1-.25-3.943 1.248-5.3-1.2l-2.6-6.4 3.9-1.45q-.184-.369-.4-.75-.06-.171-.15-.35m1.45 3.05v-.05l-2.25.8 1.05 2.55 2.35-.8q-.074-.198-.2-.45-.218-.535-.5-1.1-.04-.12-.1-.25v-.05q-.175-.33-.35-.65m-143 2.05q-.1.2-.2.45l2.4.75 1-2.5-2.2-.85-.05.05q-.15.3-.3.7-.05.15-.15.25-.25.55-.5 1.15m1.9-4.05l3.85 1.45-2.55 6.4q-1.35 2.45-5.3 1.2l-.1.3-.3.75q.5.15.95.35 3.3.85 4.95-.5.45-.4.8-1v-.05l.3-.65q.15-.2.3-.45v-.05l2.9-6.9-5.25-1.95q-.1.2-.2.35-.15.4-.35.75z"
                    />
                    <path
                        fill="#00498A"
                        d="M262.8 245.45l-.2-.4-3.9 1.45 2.6 6.4q1.357 2.448 5.3 1.2-.029-.05-.05-.1-.02-.071-.05-.15-.083-.208-.2-.45l-.5-1.2q-.215-.577-.5-1.15-.105-.325-.25-.75l-.4-.8-2.35.8-1.05-2.55 2.25-.8-.7-1.5M122.4 245l-.2.4-.7 1.5 2.2.85-1 2.5-2.4-.75q-.15.4-.35.75-.15.45-.3.75-.25.55-.5 1.15l-.5 1.25q-.05.2-.15.4-.05.1-.05.15 0 .05-.05.1 3.95 1.25 5.3-1.2l2.55-6.4-3.85-1.45z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe133__Symbol_362_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe133__col1n"
            />
            <use
                xlinkHref="#ShiSpe133__Symbol_361_0_Layer0_0_FILL"
                transform="translate(132.2 236.95)"
                id="ShiSpe133__col1s"
            />
            <use
                xlinkHref="#ShiSpe133__Symbol_360_0_Layer0_0_FILL"
                transform="translate(157.5 263.15)"
                id="ShiSpe133__col2n"
            />
            <use xlinkHref="#ShiSpe133__Layer2_0_FILL" />
        </g></g>
    )
}

export default ShiSpe133
