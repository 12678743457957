import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000",
    "col2l": "#A1FFA1",
    "col2n": "#00FF00"
}

function ObjFoo050({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.45 3.85l-1.8-2.25V.15q-12.25 1-24.55 0-.05 0-.05-.05L2 0v1.6L0 3.95.05 20q1.65.2 3.5.8 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1H5.4q-2.9 1.1-5.35.7v11.7q.1 2 1.45 2.95.4.3.95.6.75.35 1.7.7.6.2 1.15.35.35.15.75.25.15 0 .3.05 3.45.8 7.9.8 2.6 0 4.8-.25 2.9-.4 5.25-1.2 1.4-.5 2.3-1.05.25-.1.4-.25 1.25-.9 1.45-2.7l.15-.65-.15-50.65z"
                    id="ObjFoo050__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M6.35 45.15V40.5q-.35.25-.7.4l-.2.1H5.4q-1.55.6-2.95.75v15.2q.75.35 1.7.7.6.2 1.15.35.35.15.75.25.15 0 .3.05V45.15m22.1-42.8L26.65.1q-.15.25-1.05.55-.9.25-2.45.5-3.7.45-8.8.45-5.1 0-8.75-.45Q4.05.9 3.1.65q-.85-.3-1.05-.6L2 0v.1L0 2.45q.6.6 2.45 1V19q.55.1 1.1.3 1.55.7 2.8 1.85V4q3.45.3 7.9.3 4.8 0 8.5-.4.8-.1 1.55-.2 3.75-.5 4.15-1.35z"
                    id="ObjFoo050__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.25 37.6q-.3.05-.7.15.75 2.1 1.6 4.55v2.95q.25-.1.65-.15v-2.95q.8-2.9 1.6-5.2-.35 0-.65.05-.65 2-1.25 4.1-.6-1.8-1.25-3.5m-1.8 2.55q.3-.05.65-.15-.05-1.1-.45-1.65-.5-.6-1.2-.45-1 .1-1.5 1.1-.45 1.05-.4 3.35-.1 2.35.4 3.2.4 1 1.35.9.45-.15.8-.55.3-.45.5-1.1 0 .5.05 1.15.25-.1.5-.15-.05-2.05 0-4.25-1 .25-1.9.4v.9q.65-.1 1.3-.25-.05 0 0 .15V43q0 1.1-.35 1.7-.2.65-.8.8-.6.15-.95-.6-.25-.65-.25-2.35-.05-2.1.25-3 .25-.8 1-.9.5-.1.7.3.25.3.3 1.2M0 37.55v.5l.05 2.25q.05 3 .1 5.75.3.1.6.15Q.7 42.85.6 38.75q1 4.25 2.1 7.8.35 0 .85.1-.1-4.05-.15-8.4-.35-.05-.65-.1.05 3.8.15 7.15-1.05-3.5-2.1-7.55l-.8-.2m7.35 1.85v-.8q-1.45-.05-2.9-.2l.1 8.4q1.4.1 2.85.15v-1q-1.1 0-2.25-.1V42.9q1 .1 2.05.1-.05-.4 0-.85-1.05 0-2.05-.1-.1-1.4-.05-2.75 1.1.05 2.25.1m2.45-.8q-.8 0-1.55.05v8.3h.6V43.2h.8q.65-.1.95.25.2.3.3 1.15v.75q0 1.1.15 1.45l.8-.1q-.3-.4-.35-1.7-.05-.45-.05-.75 0-.8-.25-1.15-.25-.45-.7-.4.5-.25.8-.8.25-.55.25-1.4 0-1.15-.45-1.5-.4-.5-1.3-.4m-.95.75q.45-.05.9 0 .6-.05.85.25.3.3.3 1.1t-.3 1.2q-.35.45-1 .45-.4.1-.75.05v-3.05m.95-27.9L15.5 0 9.65.65l-3.6 13.4 5.45-.85-6.4 12.4 5.7-.9-4.65 10.85 8.8-13.6-5.85.85 6.85-12.25-6.15.9z"
                    id="ObjFoo050__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M120.7 312.35q-1.4-.4-2.55-.75v8.5q1.3.45 2.7.75V320q-1.1-.25-2.15-.55-.05-1.05 0-2.95.95.25 1.95.5 0-.45-.05-.9-.95-.15-1.9-.45-.05-1.5-.05-3 1 .25 2.05.65v-.95z"
                    id="ObjFoo050__Symbol_5_0_Layer0_0_FILL"
                />
                <path
                    fill="#B6B6B6"
                    d="M117.3 265.2q0 .05-.05.05-.047.047-.05.15v.7l.05.1q.2.3 1.05.55.95.25 2.5.45 3.65.55 8.75.55t8.8-.55q1.55-.2 2.45-.45.9-.25 1.05-.5.035-.035.05-.1v-.3l.05-.05v-.1q0-.05-.05-.05v-.35q-.002-.05-.1-.05-.2-.25-.95-.5-.9-.3-2.5-.5-3.7-.55-8.8-.55-5.1 0-8.75.55-1.65.2-2.5.5-.7.2-1 .45z"
                    id="ObjFoo050__Layer7_0_FILL"
                />
                <g id="ObjFoo050__Layer7_1_FILL">
                    <path d="M137.95 265.65q-.1 0-.15-.05-.8-.2-1.8-.2-1.2 0-2 .25-.45.15-.65.25.05.05.15.1.15.05.5.15.8.25 2 .25 1.1 0 1.95-.25.4-.1.6-.25-.2-.1-.6-.25z" />
                    <path
                        fill="#7E7E7E"
                        d="M138.65 265.5q-.1-.2-.7-.35-.85-.2-1.95-.2-1.2 0-2 .2-.85.2-.85.5 0 .15.2.25.2-.1.65-.25.8-.25 2-.25 1 0 1.8.2.05.05.15.05.4.15.6.25.15-.1.15-.25 0-.1-.05-.15m-21.45-.35q.002.053.05.15.05.05.15.1.1.1.25.15.25-.15.65-.25.85-.3 2.5-.5 3.65-.5 8.75-.5t8.8.5q1.6.2 2.5.5.4.1.65.25.15-.05.2-.15.15-.1.2-.2.05 0 .05-.05V265h-.05q0-.05-.1-.05-.2-.25-.95-.5-.9-.3-2.5-.5-3.7-.55-8.8-.55-5.1 0-8.75.55-1.65.2-2.5.5-.7.2-1 .45 0 .05-.05.05-.05.05-.05.15v.05z"
                    />
                    <path
                        fill="#E9E9E9"
                        d="M117.25 265.3q-.048-.097-.05-.15v1.25l.05.1q.2.3 1.05.55.95.25 2.5.45 3.65.55 8.75.55t8.8-.55q1.55-.2 2.45-.45.9-.25 1.05-.5.05-.05.05-.15h.05v-.1l-.05-.05v-.4l.05-.05v-.1q0-.05-.05-.05v-.45q-.05.1-.2.2-.05.1-.2.15-.25.15-.7.3-.9.25-2.45.5-3.7.5-8.8.5-5.1 0-8.75-.5-1.55-.25-2.5-.5-.35-.15-.65-.3-.15-.05-.25-.15-.1-.05-.15-.1z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo050__Symbol_81_0_Layer0_0_FILL"
                transform="translate(115.2 266.4)"
                id="ObjFoo050__col1n"
            />
            <use
                xlinkHref="#ObjFoo050__Symbol_82_0_Layer0_0_FILL"
                transform="translate(115.2 267.9)"
                id="ObjFoo050__col1l"
            />
            <use
                xlinkHref="#ObjFoo050__Symbol_80_0_Layer0_0_FILL"
                transform="translate(121.55 275)"
                id="ObjFoo050__col2n"
            />
            <use
                xlinkHref="#ObjFoo050__Symbol_5_0_Layer0_0_FILL"
                id="ObjFoo050__col2l"
            />
            <use xlinkHref="#ObjFoo050__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo050__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo050
