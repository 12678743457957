import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2n": "#FFF5D7"
}

function ObjToy034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.65 3.3Q.4 5.6 0 8.65q.15-.05.3-.05.45-.05.85-.05 3.95 0 6.7 3.4 2.8 3.45 2.8 8.3 0 4.85-2.8 8.3-2.75 3.4-6.7 3.4H.6q-.05.55-.05.75 0 .05.25 2.4.15.35.35.7.2.35.45.6 1.25.65 2.7.9v-.05q0-.1.4-.2.4-.15 1.55-.05h.35v.3q1.7-.2 3.55-.9.15-.15.3-.35.25-.4.45-.95.25-2.35.25-2.4 0-.6-.25-3.45t-.25-4.95q0-2.2.6-5.55.6-3.3.6-7.35 0-4.8-1.8-8.1Q8.4.25 6.1.15h-.5Q3.3.25 1.65 3.3M155.5 0h-.5q-2.3.1-3.95 3.15-1.8 3.3-1.8 8.1 0 4.05.6 7.35.6 3.35.6 5.55 0 2.1-.25 4.95t-.25 3.45q0 .05.15 1.5.05.45.1 1.05.15.35.3.65.2.35.5.65 1.15.6 2.4.85 0-.1.05-.15 1 .15 2.25.25 1.8-.15 3.85-.95.15-.15.3-.35.25-.4.45-.95.05-.6.1-1.05.15-1.45.15-1.5 0-.2-.05-.75H159.95q-3.95 0-6.7-3.4-2.8-3.45-2.8-8.3 0-4.85 2.8-8.3 2.75-3.4 6.7-3.4.4 0 .85.05.15 0 .3.05-.4-3.05-1.65-5.35Q157.8.1 155.5 0z"
                    id="ObjToy034__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9 1.45q.15-.15.3-.35Q8.95.75 8 .5 6.55.05 4.45.05 2.4.05.9.5.35.65 0 .85q.2.35.45.6 1.25.65 2.7.9V2.3q0-.1.4-.2.4-.15 1.55-.05h.35v.3q1.7-.2 3.55-.9m145.55.95q1.8-.15 3.85-.95.15-.15.3-.35-.05 0-.1-.05-.35-.35-1.3-.6-1.45-.45-3.55-.45-1.15 0-2.1.15-.45.1-.85.2-.95.25-1.3.6l-.1-.2-.05.05q.2.35.5.65 1.15.6 2.4.85 0-.1.05-.15 1 .15 2.25.25z"
                    id="ObjToy034__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M185.65 18.15q.1-2.45-3.05-5.8-2.45-2.55-2.45-5.6 0-1 .4-2.55.3-1.45.55-2.25 0-.1.05-.2v-.3q0-.3-.15-.6-.25-.4-.7-.5-.5-.15-.9.15-.4.2-.5.7-.05.05-.05.15-.05.2-.15.55-.15.7-.4 1.75-.45 1.9-.45 3.1 0 3.95 3.1 7.2 2.3 2.45 2.4 4.2-.1 3.65-4.8 5.75-4.35 1.9-10.15 1.9-3.65 0-6.8-.55-3.35-.65-8-.65-8 0-23.75 4.05-15.4 3.95-20.3 3.95-.1 0-.25.05-13.65 2.85-28.4-1.25h.05Q65.7 27.1 58.4 26.6q-7.5-.5-16.05 1.4-8.45 1.85-10.1 2.35-1.65.45-15 2.5-6.3.9-8.6-.05-.65-.3-.8-.85-.05-.8.6-2 1.9-3.65 1.9-5.8-.05-2.8-2.5-3.65-.1-.05-.2-.05-2.85-.55-4-.9-1.35-.4-1.35-1.7 0-1.75 1.3-2.7 1.25-.95 3.9-1.45 7.95-1.75 9.7-2.25 7.6-2.3 10.15-3.8 1.7-1.05 2.8-2.1V5.5Q31.7 3.85 32 2q.05-.25.05-.45V1.4v-.25q0-.5-.3-.8Q31.4 0 30.9 0t-.8.35q-.35.3-.35.8v.25q-.05.25-.1.55Q29.35 3 28.5 3.9q-.95.9-2.35 1.8l.05-.05q-2.4 1.4-9.65 3.6-1.7.5-9.5 2.2-3.3.65-4.85 1.85h.05Q0 14.9 0 17.85q0 3 3 3.9 1.2.35 4.15.9.9.35.9 1.5-.1 1.75-1.65 4.7-1.15 2.05-.8 3.5.2 1.65 2.1 2.5 2.65 1.3 9.9.25 13.6-2.1 15.25-2.55h.05q1.6-.5 9.95-2.3 8.25-1.85 15.4-1.35t22.05 4.7q15.25 4.25 29.4 1.3 5.05-.05 20.7-4 15.4-4 23.2-4 4.45 0 7.6.6 3.35.6 7.2.6 6.3 0 11.1-2.1 6.25-2.8 6.15-7.85z"
                    id="ObjToy034__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy034__Symbol_123_0_Layer0_0_FILL"
                transform="translate(112.1 276.6)"
                id="ObjToy034__col1n"
            />
            <use
                xlinkHref="#ObjToy034__Symbol_75_0_Layer0_0_FILL"
                transform="translate(113.25 311.55)"
                id="ObjToy034__col1d"
            />
            <use
                xlinkHref="#ObjToy034__Symbol_36_0_Layer0_0_FILL"
                transform="translate(86.65 312.5)"
                id="ObjToy034__col2n"
            />
        </g></g>
    )
}

export default ObjToy034
