import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.95 28.1q5.8-1.75 14.8-1.75 2.5 0 5.45.9h.05q3.05.85 8.75.85 2.05 0 5.65-1 .15-.1.3-.25.1-.2.05-.35-.1-.2-.25-.3-.2-.15-.35-.05-3.45.95-5.4.95-5.5 0-8.5-.8-3.15-.95-5.75-.95-9.2 0-15.1 1.8-5.8 1.7-9.8 1.7-6.65 0-11.55-1.8-5.15-1.95-7.35-1.95-1.3 0-6.2.55-4 .5-5.3.7-.25.05-.35.2-.15.15-.1.4.05.2.2.3.15.15.4.1 1.3-.2 5.25-.7 4.8-.55 6.1-.55 2.1 0 7 1.9 5.05 1.85 11.9 1.85 4.1 0 10.1-1.75M58.8 1.2q-.15-.15-.35-.2-.8-.1-3.8-.75-1.2-.25-3.4-.25-4.4 0-8.2 1.35-3.6 1.25-6.1 1.25-3.8 0-5.95-.75h-.05Q27.4.6 24.35.1h-.95q-.2 0-.35.15-.15.15-.15.35 0 .2.15.35.15.15.35.15h.8q3 .5 6.45 1.7 2.3.8 6.3.8 2.6 0 6.45-1.3Q47.05 1 51.25 1q2.1 0 3.2.25 3.1.65 3.9.75.2.05.35-.1.15-.15.2-.35.05-.2-.1-.35M69 14.05q.15-.1.25-.3.05-.2-.05-.35-.1-.2-.3-.25-2.3-.8-3.3-.8-3.55 0-9.6 1.95-5.85 1.8-9.5 1.8-4 0-9.35-1.85-5.55-2-9-2-4.4 0-8.85 1.3h-.05q-1.45.45-3.9 1.2-2 .5-4.05.5-1.55 0-2-.1-.2-.1-.35 0-.2.1-.25.3-.1.2 0 .4.1.15.3.25.55.15 2.3.15 2.2 0 4.35-.55 2.45-.8 3.9-1.2 4.3-1.25 8.6-1.25 3.35 0 8.65 1.95 5.5 1.9 9.7 1.9 3.75 0 9.8-1.85 5.85-1.9 9.3-1.9.9 0 3 .75.2.05.4-.05z"
                    id="SkiMor006__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor006__Symbol_10_0_Layer0_0_FILL"
                transform="translate(154.65 90.2)"
                id="SkiMor006__col1n"
            />
        </g></g>
    )
}

export default SkiMor006
