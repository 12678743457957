import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}
function FacMou090({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M37.15 6.9q0-.3-.05-.65-.45-3.85-4.4-5.05-1.1-.4-2.45-.6l.05.05Q27.8.1 24.25.05q-.5-.05-1-.05H13.9q-.5 0-1 .05-3.55.05-6.05.6L6.9.6q-1.35.2-2.45.6Q.5 2.4.05 6.25 0 6.6 0 6.9q-.1 3.45 4.5 4.1h.05q1.2.15 2.35.3h23.35q1.15-.15 2.35-.3h.05q4.6-.65 4.5-4.1z"
                    id="FacMou090__Symbol_163_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M3.25 8.4q1 .1 1.9.25v-3.9Q2.55 5.1 0 5.8q.15 2.15 3.25 2.6m1.9-4.5V1.2Q.552 2.115.05 4.75q-.034.147-.05.3.577-.159 1.1-.3 2.244-.567 4.05-.85m5.9 4.8V6.8q-.588 1.13-1.25 1.1-.612-.03-2.35-1.9l-1-1.25q-.157.093-.5.1V8.7h5.1m0-7.3v-1q-3.545.33-4.5.55-.392.085-.8.15-.037.458.05.75.209.538.3.8.188.483.5 1.05.184.362.4.75L8.25 5.8q1.23 1.389 1.7 1.35.51-.045.75-1.2.217-.966.25-1.2L11 4.7q.022-.256 0-.4-.011-.156 0-1.05-.004-.837.05-1.85M16.9 4V.3h-5.05V4H12q2.5-.05 4.9 0m0 .85q-.447-.01-.9-.05h-1.75q-1.106.027-2.25.05h-.15V8.7h5.05V4.85M22.5 4h.15V.3H17.6V4h.05q.3-.006.6 0 2.088-.043 4.25 0m.15.85H18.25q-.3-.006-.6 0h-.05V8.7h5.05V4.85m5.9 0q-.35 0-.55-.1L27.05 6q-1.75 1.85-2.4 1.9-.6.05-1.2-1.05V8.7h5.1V4.85m-.15-2.2q.1-.25.25-.75.15-.35.05-.75-.35-.1-.75-.2-.95-.2-4.5-.55v1q.05 1 .05 1.9v1.45q.1.25.3 1.25.25 1.1.7 1.15.5.05 1.75-1.3l1.2-1.4q.25-.4.45-.75.3-.55.5-1.05m2.85 5.75q3.1-.45 3.25-2.6-2.55-.7-5.15-1.05v3.9q.9-.15 1.9-.25m3.25-3.35q0-.15-.05-.3-.5-2.65-5.1-3.55v2.7q1.8.3 4 .85.55.15 1.15.3z"
                    id="FacMou090__Kopie_von_Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou090__Symbol_163_0_Layer0_0_FILL"
                transform="translate(173.85 216.7)"
                id="FacMou090__col2n"
            />
            <use
                xlinkHref="#FacMou090__Kopie_von_Symbol_42_0_Layer0_0_FILL"
                transform="translate(175.3 217.9)"
                id="FacMou090__col1n"
            />
        </g></g>
    )
}
export default FacMou090
