import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M91.95 14.5l17.5 6.55L88.45 0l-33.7 34.2L21.5 0 0 21.05l17.5-6.55 37 28.8.1.25.15-.1.1.1.1-.25 37-28.8z"
                    id="FacBro029__Symbol_29_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro029__Symbol_29_0_Layer0_0_FILL"
                transform="translate(137.8 109.8)"
                id="FacBro029__col1n"
            />
        </g></g>
    )
}

export default FacBro029
