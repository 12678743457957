import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF9AFF",
    "col2n": "#FFFFFF"
}

function LegPan013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7.05l.05-.05q-4.25.85-8.5 1.55-.6.1-1.25.2-4 .7-8 1.25-.6.1-1.25.2-4.65.6-9.25 1.15-.6.05-1.25.1-4 .4-8 .75-.6.05-1.25.1-4.5.35-9 .6-.6 0-1.25.05-4 .15-8 .25H67.5q-4.65.1-9.25.05-.6-.05-1.25-.05-4-.1-8-.25-.6 0-1.25-.05-4.65-.2-9.25-.55-.6-.05-1.25-.1-4-.35-8-.75-.6-.05-1.25-.1-4.65-.55-9.25-1.2-.6-.1-1.25-.15-4-.6-8-1.25-.6-.15-1.25-.25Q4.1.85 0 0q0 .05.05.1v19.5q4.1-.7 8.2-1.15.65-.1 1.25-.15 4-.45 8-.65.65 0 1.25-.05 4.65-.2 9.25-.15.65 0 1.25.05 4 .05 8 .4.65.05 1.25.1 4.65.4 9.25 1.15.65.05 1.25.2 4.05.65 8 1.55.65.15 1.25.3 2.3.55 4.6 1.15 2.3-.65 4.65-1.15.6-.2 1.25-.3 3.95-.9 8-1.55.6-.15 1.25-.2 4.45-.7 9-1.15.6-.05 1.25-.1 4-.3 8-.4.6-.05 1.25-.05 4.6-.05 9.25.15.6 0 1.25.05 4 .2 8 .65.6.05 1.25.1 4.2.5 8.45 1.2V.05z"
                    id="LegPan013__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M9.25 16.1q.65-.05 1.25-.05V1.65q-.65-.1-1.25-.2V16.1M1.25.2Q.6.1 0 0v16.85q.65-.05 1.25-.1V.2M21 15.95V2.9q-.65-.05-1.25-.1v13.1q.65 0 1.25.05m8 .4q.65.05 1.25.1V3.75q-.65-.05-1.25-.1v12.7m11.5 1.45V4.4q-.65-.05-1.25-.05V17.6q.65.05 1.25.2m9.25-13.15H48.5v14.7q.65.1 1.25.3v-15m10.5 0q-.65 0-1.25.05v14.95q.6-.15 1.25-.3V4.65m18.5-.85v12.65q.6-.05 1.25-.1V3.7q-.65.05-1.25.1m-10.5.6v13.4q.6-.15 1.25-.2V4.35q-.65.05-1.25.05m21 11.5V2.85q-.65.05-1.25.1v13q.6-.05 1.25-.05M98.5 1.65v14.4q.6.05 1.25.05V1.5q-.65.05-1.25.15m9.25-1.4v16.5q.6.05 1.25.15V0q-.65.1-1.25.25z"
                    id="LegPan013__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan013__Symbol_20_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan013__col1n"
            />
            <use
                xlinkHref="#LegPan013__Symbol_21_0_Layer0_0_FILL"
                transform="translate(137.9 311.8)"
                id="LegPan013__col2n"
            />
        </g></g>
    )
}

export default LegPan013
