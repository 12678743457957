import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M26.95 6.2q.4-.15.6-.45.15-.35.1-.75-.15-.4-.45-.55Q14.5-2.8.65 1.8q-.35.1-.55.5-.2.35-.05.7.1.35.5.55.35.2.7.05 13.05-4.3 25 2.5.3.15.7.1z"
                    id="FacMou002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacMou002__col1n" transform="translate(178.65 223.35)">
                <use
                    xlinkHref="#FacMou002__Symbol_2_0_Layer0_0_FILL"
                    id="FacMou002__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacMou002
