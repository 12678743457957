import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1l": "#D9D9D9",
    "col1n": "#BFBFBF"
}

function AccHat073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fillOpacity={0.302}
                    d="M210 125.45q16.9 2.8 28.35 6.4 2.35.75 4.45 1.5l1.5.6q8.05 3.45 12.15 8.65 1.35 2.05 2.5 4.1 4.55 8.5 4.5 17.45l-.1 1.2 24.05 1.9-21.8-3.9q1.15-10.4-4.5-19.45-4.15-7.8-13.9-11.95-26.65-11.45-52.75-11.5v-.05q-31.6-.35-59.25 11.55-9.75 4.15-13.9 11.95-5.65 9.05-4.5 19.45l-21.8 3.9 24.05-1.9-.1-1.2q-.05-8.95 4.5-17.45 1.15-2.05 2.5-4.1 4.1-5.2 12.15-8.65l1.5-.6q2.1-.75 4.45-1.5 11.45-3.6 28.35-6.4 8.2-.9 20.1-.85h1.9q7.7 0 15.6.85z"
                    id="AccHat073__Layer15_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M190.15 34.35q-3.874-4.585-8.4-9.3-19.8-20.65-51.9-24.3-.25-.05-.45-.05-3.15-.35-6.35-.55h-.55q-.55-.05-1.1-.05-2.9-.15-5.9-.1H113.3q-.8 0-1.55.05-.2-.05-.35 0-2.25.05-4.45.2-1.2.05-2.35.15Q82.25 2.3 65 12.35q-4.15 2.4-8 5.25-2.75 2.05-5.35 4.35-2.2 1.9-4.3 4-4.177 4.17-8.15 8.2l-.2.2q-2.272 2.343-3.95 4.5-6.8 8.6-11.35 16.75l-.45.9-.15.25q-.054.115-.1.2-.133.248-.3.5l-2.2 4.5q-2.175 4.847-3.5 9.55-3.15 11.05-4.6 16.7-.3 1.05-.5 1.9-.9 3.45-.9 3.95L.95 118.3l12.4-2.25q-6.75 1.55-3.15.75 3.65-.8 5.6-1.15l24.8-4.45q-1.55-13.75 8.9-25.1.65-.7 1.35-1.35 1.3-1.3 2.75-2.55Q81 69.3 107.85 68.35h.1q.534-.027 6.25-.05h1.2q.162-.003.3 0 1.625-.03 3.25 0 1 .013 2 .05h.05q26.88.944 54.3 13.85 1.45 1.25 2.75 2.55.7.65 1.35 1.35 10.45 11.35 8.9 25.1l24.8 4.45q1.95.35 5.6 1.15 3.6.8-3.15-.75l12.4 2.25-10.05-24.25q0-.5-.9-3.95-.2-.85-.5-1.9-1.45-5.65-4.6-16.7-1.178-4.133-3.05-8.4-1.293-3.04-2.95-6.15-.358-.673-.75-1.35-4.483-8.142-11.3-16.75-1.765-2.234-3.7-4.5m-66.6-27.9l1.8.2q.3.05.65.1l.6.1q-.65-.05-1.2-.1-.95-.1-1.85-.3m5.35.75q.15 0 .35.05-.15.1-.15.25l-.2-.3m-54.5 7.2l.05.05h-.05v-.05z"
                    id="AccHat073__Symbol_290_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M117.75 87.4Q109.4 85 100.1 81q-1.5-.75-2.95-1.45-3.85-1.75-7.65-3.35Q78.55 35.25 40.1 6.8 35.35 3.3 30.2 0q1.15 2.5 2.4 5.55.3.05.65.1l.6.1q-.65-.05-1.2-.1.4 1 .8 2.1 7.35 19.3 16.5 56.7-4.1-.75-8.2-1.2-1.731-.215-3.45-.4-13.536-1.353-26.75-.2-3.425.277-6.85.75V68q2.54-.382 11.6-.75 9.06-.32 22.95.85 21.478 2.729 43.3 13 1.45 1.25 2.75 2.55.7.65 1.35 1.35 10.45 11.35 8.9 25.1l24.8 4.45q1.95.35 5.6 1.15.1 0 .15.05v-.05l-3.3-.75 3.25.6-8.3-28.15M36.5 6.15q-.15.1-.15.25l-.2-.3q.15 0 .35.05z"
                    id="AccHat073__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.8}
                    d="M111.4.05q-2.25.05-4.45.2-1.2.05-2.35.15Q82.25 2.3 65 12.35q-4.15 2.4-8 5.25-2.75 2.05-5.35 4.35-2.2 1.9-4.3 4-.843.842-1.7 1.65-25.46 22.189-33.5 61.8-.099.52-.2.95-.9 3.45-1.15 3.8l-10 24.4 15.95-2.95 10.6-29.35 4.5-2.65q14.804-7.599 29.8-12.45 17.447-55.092 50.5-70.9.55-.15 1.15-.25-.8 0-1.55.05-.2-.05-.35 0m-37 14.4v-.05l.05.05h-.05z"
                    id="AccHat073__Symbol_195_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M270.15 134.5q-1.5-.75-2.95-1.45l5.55 31.8 14.5 2.6-8.1-29.35-9-3.6m-48.3-57.85q-8.1-8.85-9.85-9.5 8.75 21.45 16.05 51.3l.95 1.6q11.2 2.5 22.65 6.7-14.8-33.6-29.8-50.1z"
                    id="AccHat073__Layer6_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat073__Layer15_0_FILL" />
            <use
                xlinkHref="#AccHat073__Symbol_290_0_Layer0_0_FILL"
                transform="translate(77.3 52.4)"
                id="AccHat073__col1n"
            />
            <use
                xlinkHref="#AccHat073__Symbol_216_0_Layer0_0_FILL"
                transform="translate(170.05 53.5)"
                id="AccHat073__col1l"
            />
            <use
                xlinkHref="#AccHat073__Symbol_195_0_Layer0_0_FILL"
                transform="translate(77.3 52.4)"
                id="AccHat073__col1d"
            />
            <use xlinkHref="#AccHat073__Layer6_0_FILL" />
        </g></g>
    )
}

export default AccHat073
