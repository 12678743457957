import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M92.9 4.35q-.45.05-.7.05-2 0-3.45-1.4l-.5-.5q-.55.45-2.5 2.15-2.65 2.2-3.65 2.8-3.75 1.45-4.15-1.85-1.5 1.85-1.65 1.95-4.25 3.05-6.25 3.05-1.1 0-1.55-.75-1.2-.65-2.45-2.55-.6.5-1.3 1.05-3.7 3-5.2 3-1.1 0-1.75-2.15-2.35.95-3.65.95-1.55 0-3.95-2.65-1.55.6-3.95 2.25-1.4.9-2.3 1.7h-.05q-1.35-1.25-5.8-5-.35 1.4-2.15 1.4-2.1 0-4.95-.9l-.2-.05q-.25-.05-.45-.15-.55-.15-1.05-.35-.65-.25-1.2-.45.05.35-2.1 1.65-1.4.8-1.7 1.25-.45-1.2-3.75-7.1-.35.35-.8.75-1.25 1.1-1.15.95-1 1.05-3.95.4-1.85-.7-5.1-3.35Q9.25.3 9 .1q-.65 1.25-2.95 2.25Q3.7 3.35 0 0q2.9 5 7.6 7.8 6.6 3.65 12.8 5.95 1.3.5 2.85 1 29 10.6 58.4 0 1.1-.5 2.35-1.15 4.2-2.35 8.35-5.6 3.4-2.7 7.25-5.65-3.9 2-6.55 2h-.15M27.05 12.8h.05q0 .047.05.1l-.1-.1z"
                    id="BeaChi013__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M92.75 4.35q1-.95 2.2-2.1-20.25 11.9-44.8 11.9Q22.15 14.15 0 0q2.5 3.35 6.1 5.5 4.9 2.7 9.65 4.7 1.6.65 3.15 1.25 1.3.5 2.85 1 24.2 8.85 48.7 2.95 14.6-3.3 22.3-11.05z"
                    id="BeaChi013__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi013__Symbol_52_0_Layer0_0_FILL"
                transform="translate(142.85 233.95)"
                id="BeaChi013__col1n"
            />
            <use
                xlinkHref="#BeaChi013__Symbol_14_0_Layer0_0_FILL"
                transform="translate(144.35 236.25)"
                id="BeaChi013__col1d"
            />
        </g></g>
    )
}

export default BeaChi013
