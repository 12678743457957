import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#CBB4A6",
    "col1n": "#E0CEC3"
}

function ObjFoo028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M30 41.4q-.5.1-.95.1Q31.1 57.55 46.5 65.65q.6-.35 1.05-.7.25-.2.6-.45-1.5-.9-2.9-1.95l-.05-.05q0-.05-.15-.05-1.1-.7-2.1-1.45-.3-.2-.65-.45-.75-.6-1.45-1.2-8.35-7.6-10.1-18.15-.35.1-.75.2M2.1 28.65q-.1.05-.2 0-.85.2-1.35 1.15-.15.25-.25.55-.05.05-.05.1-.3 1.05-.2 2.4 0 .15.05.35l.2 1.1.5 1.2q.55 1.45 1.2 2.65.25.45.45.8 1.75 2.8 3.4 2.4.15-.05.25-.15.15-.1.25-.15.75-.7.6-3-.05-2-.4-3.35-.15-.65-.35-1.2-1.3-4-3.4-4.75-.35-.15-.6-.1h-.1M67.05 0q-.8.1-1.55.25l-.4.9-.8 1.1q-1.15 1.2-2.9 1.7-1.65.45-3.25-.05-.65-.2-1.3-.55-.35-.25-.75-.5-.65.25-1.35.55l.25.25q2.1 2.1 5.05 2.1 2.95 0 5-2.1 1.6-1.55 2-3.65m30.4 64.85q6.05-4.1 5.8-7.45-.05-.35-.15-.7-.2-.75-.7-1.35-.7-.85-1.9-1.5-.7.85-1.4 1.55-8 8.35-20.3 11.65Q70.1 69.4 62 68.5q-4.55-.25-8.55-1.6l-.05.05q-.5.85-.65 1.7-.1.75.1 1.5.1.3.25.65 1.45 3.05 8.8 3.6 8 .7 18.45-2.1t17.1-7.45m12.8-28.5l-1.3.3-.3 1.45q-.65 2.95-1.85 5.95-.3.5-.55.95.05 0 .5-.15.4-.15.95-.25l.3-.1q2.35-3.45 2.25-8.15m-2.5 5.1h.15l.05.05-.2-.05M93.45 5q-.95-.55-1.9-1-.1.05-.15.1-1.55 1.5-3.8 3.1-8.4 5.9-21.45 9.4-13.1 3.5-23.3 2.6-3.6-.3-6.05-1.1-.05 0-.15-.05 0 .05-.05.1-.5.75-.95 1.55 2.85 1.35 7.9 1.8 10.2.9 23.3-2.6Q79.9 15.4 88.3 9.5q3.3-2.35 5.15-4.5m8.8 7.25q.5-.55 1.15-1.25-1.95-2.95-5.05-4.85-.65.65-1.2 1.25.65.55 1.35 1.1 1.25 1.05 2.45 2.2.65.8 1.3 1.55z"
                    id="ObjFoo028__Symbol_42_0_Layer0_0_FILL"
                />
                <g
                    id="ObjFoo028__Symbol_76_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M126.2 24.7q-1.1-4.25-2.4-7-1.45-3-4.45-7.05-3.45-3.6-7.3-4.15-1.6-.25-3.2.1-2.8 1.2-5.45 4-.7.8-1.65 1.9l-.05.05q-.15.25-.25.3 0 .05-.05.05h-.05q-.7.8-1.05 1.1-.65.65-1.2 1.25-1.75-1.3-3.7-2.4-1.15-.65-2.35-1.25-.15-.05-.3-.15Q89.1 9.7 85.3 8.7q-1.8-.45-3.65-.8Q76 7 70.05 7.7q-1.3.15-2.6.4.55-1.45.25-3.05-.05-.25-.1-.55l-.1-.3q-.2-.6-.45-1.1-.1-.25-.3-.55-.2-.25-.45-.55-.7-.7-1.45-1.15-.65-.4-1.3-.6-1.55-.5-3.3 0-1.8.5-2.95 1.8-.35.45-.7 1-.5.95-.7 1.95-.1.35-.1.7v.4q.05.65.2 1.3 0 .1.05.2.05.3.2.65.5 1.45 1.8 2.45-1.1.4-2.25.9-5.45 2.5-9.85 6.2-.6.45-1.15 1v.1l-.25.05q-.45.45-.85.9-2.6 2.55-4.75 5.6-.2.25-.4.55-.5.75-.95 1.55-3.95 6.35-4.8 12.85-.8 4.3-.1 8.65-.35.1-.75.2-.65.15-1.3.05h-.05q-.1 0-.15.05-.2 0-.35-.05-1.95-.1-3.5-2.15-1.3-1.2-2.5-2.7-.85-1.05-1.55-1.95-5.05-7.45-10.8-8.75-4.45-.7-6.7-.05-2.15.55-3.45 1.95Q.45 36.8.1 38.6q-.75 5.45 4 11.3.7.65 1.55 1.25 1.5.55 3.15.15.6 0 1.55-.25.5-.1 2.2-.75 1.65-.4 2.45.1.6.35 1.4 1.25 1.15 1.55 1.7 2.8.9 1.85 2.4 5.95 1.45 3.05 3.25 5.1 1.5 2.7 3.7 5 2.25 2.25 4.95 3.55 7.85 3.8 15.05 0l.05-.05q.15-.1.35-.15 1-.55 1.65-1.05.25-.2.6-.45 2.5 1.45 5.3 2.4l-.05.05q32.1 14.45 47.1-13.1.1-.2.25-.35.35-.35.7-.75.95-1.3 1.85-2.6 1.75-2.55 2.95-5.15.05 0 .1.05.25-.35 1.3-.5.55-.1 1.4-.35.6-.2 1.3-.45.75-.25.95-.3 1.25-.35 2.4-.75 2.9-1.05 5.1-2.65-1.25-.6-2.3-1.65-1.6-1.6-2.05-3.6h-.05q-.3.15-.55.3-.5.2-1.9.7-.15.05-.35.1-.35.1-.75.25l-.15.05q-.05.05-.1.05h-.1l-1.55.4q.2-1.15.15-1.55h.05q.65-5.55-.9-11.2-.2-.75-.45-1.45-1.8-5.75-5.55-10.2.65-.7 1.55-1.8l.2-.3q1.4-1.8 2.5-3.05l.1-.1.4-.4q2.8-2.6 5.85.4 1.2 1.45 2.45 3.85 1.95 3.55 3.15 7.55.55 2.1.65 4.7v1.35q-.05.75-.1 1.45 1.35-.55 2.9-.55 2 0 3.65.9-.05-.2-.05-.45.05-4.15-1.25-8.95m-16.5 24.6h.15l.05.05-.2-.05M53.55 69.65h.05v.05q-.05 0-.05-.05z" />
                    <path d="M109.85 49.3h-.15l.2.05-.05-.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo028__Symbol_76_0_Layer0_0_FILL"
                transform="translate(8.1 250.75)"
                id="ObjFoo028__col1n"
            />
            <use
                xlinkHref="#ObjFoo028__Symbol_42_0_Layer0_0_FILL"
                transform="translate(10.05 258.6)"
                id="ObjFoo028__col1d"
            />
        </g></g>
    )
}

export default ObjFoo028
