import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiMed023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M55.7 57.2l-.3-.15.3.2v-.05m.3-.15l-.3-.15.3.2v-.05m126.65-32q-7.55-5.25-17.75-7.9-3.05-.85-5.4-1.55-1.9-.65-3.3-1.2-2.7-1.1-3.85-1.95-1.75-1.5-3.6-2.8-1.9-1.35-3.8-2.45-5.85-3.35-12.45-4.75-26.35-4.9-47.55 0-3.45.85-7.4 2.35-4.35 1.55-9.3 3.8-3.85 1.75-8.15 4-4.55 2.35-9.7 6.05-3.75 2.7-7.8 6.1-10.6 8.8-23.45 22.5l-1.2 1.2q-2.35 2.5-4.7 5.1-17.6 38.25-11.8 69.65 7.75 27.3 23.35 56.1.4.8.8 1.65.3.6.6 1.25l13.1 9.5 14.75 8q-.35-.45-.6-.9-5.7-7.65-9.8-14.7-17.35-29.85-7-49.65-1.75-.05-3.75-.65-12.7-4.2-14.05-13.85-.15-.7-.25-1.4-.1-.8-.1-1.7 0-.6.05-1.15.3-4.05 3-7.05 3.05-3.35 7.4-3.35 4.35 0 7.8 3.7 1.95 2.1 2.9 3.2-1.35-4.8-1.9-9.8-.9-8.1.35-16.6 1-9.8 4.5-19Q48.55 56.6 60 52.45l1.25-.35.05 5.3q-.15 5-.2 5.15-.75 10.15-2.9 19.6Q61.5 75.2 66 68.4q2.45-3.55 3.65-5.2 1.15-1.65 2-2.55.8-.9 1.3-1.55 6.9-8.4 15.45-10.65 3-.8 6.3-.8 3.6 0 6.65.8 2.8.8 5 2.35.9 1 1.35 2.25.9 2.45 1.35 3.05 1.7 2.25 5.3 2.25 2.05 0 5.3-2.05 4.9-3.1 8.3-4.55 1.75-.75 4.9-1.5 4.4-1.1 11.5-2.2 4.15 0 7.3.4 1.25.15 2.35.35 21.3 6.8 25.7 29.7.75 2.9 1 5.6.15 1.55.15 3 0 7.9-.95 14.2-.9 5.05-1.4 9.6.65-1.2 1.6-2.25 3.05-3.35 7.4-3.35 4.35 0 7.4 3.35 3.05 3.4 3.05 8.2 0 1.65-.35 3.1-.1.85-.3 1.65-1.35 5.35-6.5 8.8-3 2-7.25 3.4-.05 0-.1.05h-.1q-1.25.25-2.6.4.05.05.05.15 7.05 20-1.5 41.8-2.7 6.75-6.9 13.75-.45.7-.9 1.45-2.45 3.9-5.4 7.9-.05.05-.1.15v.25q6.75-2.8 14.05-8.15 2.2-1.75 4.95-3.85l19.85-26.1q.3-1.35.7-2.75 7.45-33.5 5.95-82.2-5.95-20.85-13.55-33.7-.65-1.1-1.3-2.15-3.75-5.85-7.35-9.85-3.35-3.7-6.65-5.9z"
                    id="HaiMed023__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.1 91.25q.65 2.3.65 4.75 0 .55-.2 3 .05.35.1.75.8 6.45 2.1 13.45 1.55 7.05 3.95 12.7 4.1 9.8 12.65 19.7-17.35-29.85-7-49.65-1.75-.05-3.75-.65-5.3-1.75-8.6-4.45l.1.4M36.25 0q-2.9 4.1-10.1 10.55-7.25 6.5-9.2 11.6-2 5.05-4.55 14.1Q10 44.7 10 49.75q0 4.1 3.45 14.15-.9-8.1.35-16.6 1-9.8 4.5-19 5.95-10.2 17.4-14.35l1.05-.25-.2-5.85q-.2-5.15-.3-7.85M147.5 12.5q-6.95-6.75-17.5-6.75-8.35 0-21.45 6 4.4-1.1 11.5-2.2 4.15 0 7.3.4 1.25.15 2.35.35Q151 17.1 155.4 40q.75 2.9 1 5.6.1-2.8.1-5.75 0-18.55-9-27.35m21.2 77.7q-1 .9-2.2 1.7-3 2-7.25 3.4-.05 0-.1.05h-.1q-1.25.25-2.6.4.05.05.05.15 7.05 20-1.5 41.8-2.7 6.75-6.9 13.75 5-6.15 7.15-9.25 4.6-6.65 8.25-14.95 3.45-7.95 4.6-17.25.4-6.35.6-9 0-.5.05-.9-.25-5.65-.25-6.25 0-2 .2-3.65z"
                    id="HaiMed023__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.3 38.1l.3.2v-.05l-.3-.15m-.3.15l.3.2v-.05l-.3-.15m42.75-12.5q.05-.05.15-.1-1.25-.5-3.15-2.15-2.05-1.75-2.45-2.35-.85-.55-1.75-1.15v-.15Q29.5 15.7 28.4 13.8v-.15q-.85-1.4-.95-3.8-.05-.15-.1-.25-.4-2.3-.5-2.75-.15-.7-.5-2.7-.45-.15-.7.25-.4.3-.3.7v2.05q-.4 5.1 1.25 7.6 1.25 2.35 7.75 6.75 4.95 3.45 7.55 4.25.45.15.85 0m10.75-15q.005-.145.15-.15h-.15v.15m12.5 6.2q-.3-.85-.45-1.9Q63.2 8.2 58.4 0q.15.65.6 2.25.45 1.7.85 2.75.8 2.15 1.4 5.05 4.45 9.85 4.35 17.1-.1.35.2.65.05-.7.4-2.1.35-1.7.35-4.1 0-3-.55-4.65m20.3.85q-.6 2.85-3.1 5.5-1.25 1.3-2.15 1.9.15.2.4.3.45 0 .85-.15 6.05-3.6 7.8-13.3-.05-.3-.05-.6.15-2.8 0-3.6-.35-1.3-.6-2.35-.35 0-.55.3-.25.1-.25.65-.35 6.6-2.25 10.9-.05.2-.1.45z"
                    id="HaiMed023__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed023__Symbol_79_0_Layer0_0_FILL"
                transform="translate(84.2 51.5)"
                id="HaiMed023__col1n"
            />
            <use
                xlinkHref="#HaiMed023__Symbol_48_0_Layer0_0_FILL"
                transform="translate(108.5 90)"
                id="HaiMed023__col1d"
            />
            <use
                xlinkHref="#HaiMed023__Symbol_13_0_Layer0_0_FILL"
                transform="translate(139.6 70.3)"
                id="HaiMed023__col1s"
            />
        </g></g>
    )
}

export default HaiMed023
