import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 66.7q0 .35.25.55.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6m7.45-24.9q0 .35.25.55.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6m12.35-10q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2M73.3 43q0 .35.25.55.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6M55 28.95q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2m3.75-15.05q0 .35.25.55.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6m-5.9-8.2q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2M73.95 22q.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25M72.7.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2m7.25 4.5q-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6m33.25 31.5q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2m6.1-13.5q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2M102 1.95q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2m55.5 35.5q-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6M139 16.7q0 .35.25.55.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6m-15.45-4.9q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2m51.2 68.8q0-.35-.2-.6-.25-.2-.6-.2t-.55.2q-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55m-1.35-22.5q-.35 0-.55.2-.25.25-.25.6t.25.55q.2.25.55.25.35 0 .6-.25.2-.2.2-.55 0-.35-.2-.6-.25-.2-.6-.2z"
                    id="HaiSho001__Symbol_1n_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.251}
                    d="M191.6 95.8q0-39.7-28.05-67.75Q135.5 0 95.8 0T28.05 28.05Q0 56.1 0 95.8q0 3.75.25 7.4l.1.05q.1-.1.25-.2v.65h.1q15.4-6.2 19.25-33.6 8.45-36.35 28.5-26.45 24.15 9.9 47.4 10.3 23.25-.4 47.4-10.3 20.05-9.9 28.5 26.45 3.85 27.25 19.1 33.55.05 0 .15.05.15.05.3.05.3-3.9.3-7.95z"
                    id="HaiSho001__Symbol_1d_0_Layer0_0_FILL"
                />
            </defs>
            <g id="HaiSho001__col1n" transform="translate(106.45 63.2)">
                <use
                    xlinkHref="#HaiSho001__Symbol_1n_0_Layer0_0_FILL"
                    id="HaiSho001__col1n_FL"
                />
            </g>
            <g id="HaiSho001__col1d" transform="translate(96.65 58)">
                <use
                    xlinkHref="#HaiSho001__Symbol_1d_0_Layer0_0_FILL"
                    id="HaiSho001__col1d_FL"
                />
            </g>
        </g></g>
    )
}

export default HaiSho001
