import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#A8FFCA",
    "col2n": "#DBFFE9"
}

function AccMas002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M186.05 5l1.35-5q-17.15 7.75-34.15 12.7-.7.2-1.45.45-1.8.5-3.65 1-3.65 1-7.35 1.85l-1.2.3q-22.8 5.15-45.4 5.3h-1q-22.6-.15-45.4-5.3l-1.2-.3q-3.7-.85-7.35-1.85-1.85-.5-3.65-1-.75-.25-1.45-.45Q17.15 7.75 0 0l1.35 5q12.15 6.2 33.3 12.35.7.2 1.45.45 2.15 17.8 8.05 35.35-.85-.3-1.6-.6-8.7-3.45-17.35-8.05 3.4 3.8 8.15 7.4l1.7.85Q39.5 55 44 56.85q.8.35 1.65.7 2.25.95 4.55 1.8 2.3.85 4.6 1.6l1.5.5q.45.15.95.3 18.1 5.7 35.95 5.95h1q17.85-.25 35.95-5.95.5-.15.95-.3l1.5-.5q2.3-.75 4.6-1.6 2.3-.85 4.55-1.8.85-.35 1.65-.7 4.5-1.85 8.95-4.1l1.7-.85q4.75-3.6 8.15-7.4-8.65 4.6-17.35 8.05-.75.3-1.6.6 5.9-17.55 8.05-35.35.75-.25 1.45-.45Q173.9 11.2 186.05 5z"
                    id="AccMas002__Symbol_87_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.5 44.85q-.8-2.2-1.5-4.4-.85-.3-1.6-.6.7 2.15 1.45 4.3.8.35 1.65.7M0 0q.2 2.35.5 4.65.7.2 1.45.45-.3-2.35-.5-4.65Q.7.2 0 0m110.7 39.85q-.75.3-1.6.6-.7 2.2-1.5 4.4.85-.35 1.65-.7.75-2.15 1.45-4.3m7.9-35.2q.3-2.3.5-4.65-.7.2-1.45.45-.2 2.3-.5 4.65.75-.25 1.45-.45z"
                    id="AccMas002__Symbol_59_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M98.8 35.8q5.35-17.75 7.8-35.8-21.65 5.3-42.25 7.15-5.3.5-10.55.55h-1q-5.25-.05-10.55-.55Q21.65 5.3 0 0q2.45 18.05 7.8 35.8 9.3 4.5 24.15 7.15 4.3.75 9.1 1.4l1.5.15q5.15.5 10.25.55h1q5.1-.05 10.25-.55l1.5-.15q4.8-.65 9.1-1.4Q89.5 40.3 98.8 35.8z"
                    id="AccMas002__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M92.75 1.9L93.1 0Q70.7 5.7 47.05 6.45h-1Q22.4 5.7 0 0l.35 1.9q22.25 5.65 45.7 6.4h1q23.45-.75 45.7-6.4M91 10.85l.4-2.05Q69.8 15.3 47.05 16h-1Q23.3 15.3 1.7 8.8l.4 2.05q21.4 6.3 43.95 7h1q22.55-.7 43.95-7m-1.65 8.45l.4-1.95Q69 24.3 47.05 25.05h-1q-21.95-.75-42.7-7.7l.4 1.95q20.45 6.85 42.3 7.6h1q21.85-.75 42.3-7.6z"
                    id="AccMas002__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas002__Symbol_87_0_Layer0_0_FILL"
                transform="translate(98.8 176.35)"
                id="AccMas002__col1n"
            />
            <use
                xlinkHref="#AccMas002__Symbol_59_0_Layer0_0_FILL"
                transform="translate(132.95 189.05)"
                id="AccMas002__col1d"
            />
            <use
                xlinkHref="#AccMas002__Symbol_20_0_Layer0_0_FILL"
                transform="translate(139.2 195.15)"
                id="AccMas002__col2n"
            />
            <use
                xlinkHref="#AccMas002__Symbol_8_0_Layer0_0_FILL"
                transform="translate(145.95 208)"
                id="AccMas002__col2d"
            />
        </g></g>
    )
}

export default AccMas002
