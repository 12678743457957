import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#DDDDDD",
    "col2l": "#FFFFFF",
    "col2n": "#EEEEEE",
    "col2s": "#CCCCCC"
}

function AccJew033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M26.45 4.5l.1-.45q-3.5-2.1-7.1-3.1L17.2.45Q10.05-.8 4 1.75 1.8 9.85.15 17.95q-.05.25-.1.6-.05.1-.05.2 12.75-6.85 22.5 2.6.05.2.15.35l.1.2q0-.1.05-.2 0-.15.05-.3.1-.8.35-1.7.1-.5.25-1 1.65-6.6 3-14.2M161.2 1.75Q155.8-.55 149.6.2q-5.85 1.05-10.7 3.75-.1.05-.15.1l.3 1.35v.05l3.45 16.1q0-.05.05-.05 9.4-9.65 22.55-2.85-1.45-8.45-3.9-16.9z"
                    id="AccJew033__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.95 4.7q.95-2.05.45-3.7-.8-1.25-2.1-.9L1.35 2.35l5.6 2.35q3.3 1.6 3.85.3.05-.15.15-.3m-.8 7.05q0-.1.05-.1 1.25-2.2.6-4.4-.1-.3-.2-.5Q9.75 5.6 7 6.8L0 9.85l.85.3h.2v.05l5.3 1.7q3.3.95 3.8-.15M155.4.1q-1.3-.35-2.1.9-.5 1.65.45 3.7.1.15.15.3.55 1.3 3.85-.3l5.6-2.35L155.4.1m8.45 10.05l.85-.3-7-3.05q-2.75-1.2-3.6-.05-.1.2-.2.5-.65 2.2.6 4.4.05 0 .05.1.5 1.1 3.8.15l5.2-1.65v-.05h.1l.1-.05h.1z"
                    id="AccJew033__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M4.85 7.5q-2-1.4-3.75-.4Q0 8 0 10.25q0 .4.35 1.5 1 1.8 3.85 1.2l4.45-1.45q-.15.1-.15.15 0 .45.5.45.55 0 .9-.35.25-.3.25-.6 0-.5-.6-.75-.35-.1-.5.05L4.85 7.5M6.1 5.4l3.7-1.9L4.85.7Q4.35.45 4.2.35 3.2 0 2.25 0q-.3.1-.6.15Q1.3.35 1 .6-.5 2.75.6 5.1q.25.5.7.85 1.75 1 4.8-.55m131.35 6.35q.35-1.1.35-1.5 0-2.25-1.1-3.15-1.75-1-3.75.4l-4.2 3q-.2-.2-.6-.15-.75.05-.75.7 0 .9 1.2.9.65 0 .65-.45l4.35 1.45q2.85.6 3.85-1.2m-.25-6.65q1.1-2.35-.4-4.5-.3-.25-.65-.45-.3-.05-.6-.15-.95 0-1.95.35-.15.1-.65.35L128 3.5l3.7 1.9q3.05 1.55 4.8.55.45-.35.7-.85z"
                    id="AccJew033__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.65 8.1l-1.4.45q-.4 1.05-.2 2.15 0 .1.05.25.35 1.5 1.65 1.45L11.8 11 2.75 6.5q-1-.3-1.95 1l.85.6M3.3 6h.05l10-1.4L3.95.05q-.7-.2-1.2.6Q2.3 1.3 2.1 2q-.3.8-.35 1.65Q1.7 5.95 3.05 6h.25m117.9 4.95q.05-.15.05-.25.2-1.1-.2-2.15l-1.4-.45.85-.6q-.95-1.3-1.95-1L109.5 11l10.05 1.4q1.3.05 1.65-1.45m-2-8.95q-.2-.7-.65-1.35-.5-.8-1.2-.6l-9.4 4.55 10 1.4h.3q1.35-.05 1.3-2.35-.05-.85-.35-1.65z"
                    id="AccJew033__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M6.85 8.15q.15-.6.3-1.15v-.05h-.1L0 9.25l6.15 2.45q.25-1.05.45-2 .1-.8.25-1.55M7.7 4.9L8.85 0l-7.4 1.7L7.7 4.9M168 0l1.25 4.9 6.15-3.2L168 0m8.7 9.35l.1-.05h-.1L169.75 7h-.1v.05q.15.55.3 1.15.15.75.25 1.55.2.95.4 1.95l6.1-2.35z"
                    id="AccJew033__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew033__Symbol_101_0_Layer0_0_FILL"
                transform="translate(109.85 265.9)"
                id="AccJew033__col1n"
            />
            <use
                xlinkHref="#AccJew033__Symbol_41_0_Layer0_0_FILL"
                transform="translate(110.15 268.05)"
                id="AccJew033__col2n"
            />
            <use
                xlinkHref="#AccJew033__Symbol_20_0_Layer0_0_FILL"
                transform="translate(123.6 268.15)"
                id="AccJew033__col2l"
            />
            <use
                xlinkHref="#AccJew033__Symbol_10_0_Layer0_0_FILL"
                transform="translate(131.85 271.1)"
                id="AccJew033__col2d"
            />
            <use
                xlinkHref="#AccJew033__Symbol_3_0_Layer0_0_FILL"
                transform="translate(104.05 271.25)"
                id="AccJew033__col2s"
            />
        </g></g>
    )
}

export default AccJew033
