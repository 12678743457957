import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#773900"
}

function AccMor015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.75.1l-1.9.4Q63.9 14.5 2.15.5 1.1.25 0 0v4q1.1.25 2.15.5 61.75 14 122.7 0l1.9-.4v-4z"
                    id="AccMor015__Symbol_113_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor015__Symbol_113_0_Layer0_0_FILL"
                transform="translate(128.95 310.1)"
                id="AccMor015__col1n"
            />
        </g></g>
    )
}

export default AccMor015
