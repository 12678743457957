import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M129.05 35.55q14.75 1.7 30.55 7.25-.15-.15-.3-.25-.05 0-.05-.05-.85-.7-1.7-1.3-19.05-18.55-39.4-24.1-2.3-.55-4.6-.9 4.8-5.6 10.05-10.8.6-.65 1.15-1.25.7-.75 4.05-4.15-.55.1-1.05.2v.05Q96.8 4 74.8 16.2l-6.65-3.3q.3.2.55.4Q46.75.85 28.2 1.45V1.3q-.25-.05-.55-.05.1.1.2.15l-.75.15q.6.25 1.15.6Q33.15 4.8 37 10.1l-.2-.3.4.55q-.1-.1-.2-.25 4.05 6.95 10.85 11.85-.8.1-1.6.3h.05Q35.9 24.45 27.55 30q-6.1 3.55-11.15 9.05-2.55 2.35-5 4.8-3 2.95-6.1 5.6Q3 51.4 0 53.6h1.1q6.2-.35 14.7-2.25 12.9-2.9 22.65-3.85 9.7-1 9.8-1.15h.05v.05h.2q-.05.002-.05.1l.1-.1-.05.15-.05.05q-.55 1.2-.9 2.05-.8 1.8-1.55 3.7-.25.5-.4 1-1.25 2.8-2.65 5.6-.1.15-.15.35l-.25.5q-6.3 12.45-13.1 19.15h-.1q-.15.1-.25.2h.1l1.2-.1.3-.05q.45-.05.95-.15 13.15-2.3 31-15.6.1-.1.25-.15 7.05-4.25 13.6-9.2 3.2-2.4 6.45-4.65l1.8-1.35q.7-.55 1.3-.95.9.2 1.85.45l.55.15q35.15 9.6 60.4 29.95l4.2 4.2q.05 0 .15.05-1.05-5.8-2.6-11-1.8-5.95-4.15-11.2-1.35-2.95-2.9-5.7-5.6-10-14.5-18.3z"
                    id="HaiSho008__Symbol_129_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M47.5 46.4v-.05q.15-.1.25-.25.85-2.15 3.1-7.55-10.5.15-19.3 3.1-8.85 2.9-18.7 6.8Q5.4 51.4.6 53.35q-.3.15-.6.25 6.2-.35 14.7-2.25 12.9-2.9 22.65-3.85 9.7-1 9.8-1.15h.05v.05h.1q.05-.05.1-.05v.05q-.05 0-.05.1.05-.05.15-.1m-2.35-24.15h.25q.1-.05.15-.05 3.1-.4 5.2-.85 2.6-.65 5.15-1.1-6.6-3.1-12.25-8.3-5.3-4.9-7.45-6.3-4-2.7-9.05-3.5 4.9 2.65 8.75 7.95l-.2-.3.4.55q-.1-.1-.2-.25 4.05 6.95 10.85 11.85-.8.1-1.6.3M86.8 47.4l.55.15q35.15 9.6 60.4 29.95l4.2 4.2q-18.2-29.35-67.5-43.1-1.2 1.25-2.3 2.45Q75 48.45 70.2 52q-1.1.85-3.2 2.55-1.4 1.15-3.3 2.75-4 3.4-6.7 5.2-5.2 3.55-13.75 9.45-6.9 4.25-14.9 7h-.1q-.15.1-.25.2h.1l1.2-.1.3-.05q.4-.05.85-.15 13.15-2.3 31.05-15.6.1-.1.25-.15 6.55-3.95 12.75-8.55.4-.3.85-.65 3.2-2.4 6.45-4.65.2-.15.35-.25.8-.6 1.5-1.1.7-.55 1.3-.95 0-.05.05-.05l1.8.5m49.15-14.5q-5.55-1.75-13.55-2.55 2.6 2.45 5.9 5.55l-.35-.35q14.75 1.7 30.55 7.25-.15-.15-.3-.25-.05 0-.05-.05-.25-.1-4.4-2.3-4.2-2.2-8.25-3.85-4.05-1.7-9.55-3.45M126.65.2v.05q-4.45 2.55-10.3 5.4-5.85 2.8-11.25 9.5l7.35 1.05q4.8-5.6 10.05-10.8.6-.65 1.15-1.25.7-.75 4.05-4.15-.55.1-1.05.2z"
                    id="HaiSho008__Symbol_65_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho008__Symbol_129_0_Layer0_0_FILL"
                transform="translate(108.95 35.4)"
                id="HaiSho008__col1n"
            />
            <use
                xlinkHref="#HaiSho008__Symbol_65_0_Layer0_0_FILL"
                transform="translate(110.05 35.4)"
                id="HaiSho008__col1d"
            />
        </g></g>
    )
}

export default HaiSho008
