import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#794D32",
    "col2n": "#BFBFBF"
}

function ObjToo013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M26.6 36.2q-8.15-7.15-10.65 4l18.2 47.15h.15l23.45 61.05q2.45 0 5.2.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.25.25-.5.45l5.1 13.25 10.55-4-8.5-22.05.05-.05L45 83.95h-.15L42.6 78.2l-.05.05-15.5-40.5-.45-1.55M3.9 14.55q14.4 14.75 14.55-5.4L15.8 0 0 6.15l3.9 8.4z"
                    id="ObjToo013__Symbol_173_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M99.65 39.05L95.6 23q37.95-19.85 78-20.9-39.15-6.8-78.9 8.6l-26.1 9.65-.4.2q-4.25 1.5-8.4 3.25Q25.35 39.25 0 72.85q34.45-27 59.8-37.2 6.3-2.4 12.1-3.9l8.25 14.15 19.5-6.85z"
                    id="ObjToo013__Symbol_57_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo013__Symbol_173_0_Layer0_0_FILL"
                transform="translate(55.8 137.9)"
                id="ObjToo013__col1n"
            />
            <use
                xlinkHref="#ObjToo013__Symbol_57_0_Layer0_0_FILL"
                transform="translate(-12.7 133.5)"
                id="ObjToo013__col2n"
            />
        </g></g>
    )
}

export default ObjToo013
