import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccJew001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M123.15 3.8l1.5-2.15L123.8.5l-.1-.1-.25-.35-.05-.05-1.85 3.25-3.95 1.1L115 8l-4.7 1-2.65 3.05-4.8.75-3.15 2.65-4.45.4-3.25 2.4-4.3-.15-3.2 2.25-4.05-.75-3.9 1.95-4.6-.9-4.3 2-4.35-1.6-4.35 1.6-4.3-2-4.6.9-3.9-1.95-4.05.75-3.2-2.25-4.3.15-3.25-2.4-4.45-.4-3.15-2.65-4.8-.75L16.3 9l-4.7-1L9 4.35l-3.95-1.1L3.2 0l-.05.05L2.9.4l-.1.1-.85 1.15 1.5 2.15L.95 3 0 4.25l3.7 1.3L5.75 8.9l4.7 1.25 2.95 3.45v.05h.05l4.75.75 2.8 2.9.2.1 4.75.35 3.25 3.1 4.8-.2 3.1 2.95h-.05q.052.002.1.05v-.05l4.55-.95 3.2 2.8 5-1.1 4.3 2.2 4.8-1.4 4.3 2.05v.05l4.3-2.1 4.8 1.4 4.3-2.2 5 1.1 3.2-2.8 4.55.95v.05q.048-.048.1-.05h-.05l3.1-2.95 4.8.2 3.25-3.1 4.75-.35.2-.1 2.8-2.9 4.75-.75h.05v-.05l2.95-3.45 4.7-1.25 2.05-3.35 3.7-1.3-.95-1.25-2.5.8m-6.95 4.85l2.3-3 3.15-.85-1.7 2.85-3.75 1m-12.7 5.3l3.9-.45-2.6 2.8-4.45.25 3.15-2.6m7.55-3.75l3.8-1-2.4 3.25-3.7.5 2.3-2.75M84 21.75l-2.7 2-3.6-.85 2.7-1.9 3.6.75m1.8-.35l2.3-1.95 3.25.25-2.4 2.25-3.15-.55m10.95-2h-3.6l2.65-2.2 3.35-.2-2.4 2.4M63.3 22.75v.05l3.55 1.2-3.55 1.5-3.55-1.5 3.55-1.25m8.8-.35l3.8.55-3.6 2-3.45-1.05 3.25-1.5m-29.5-.65l3.6-.75 2.7 1.9-3.6.85-2.7-2m11.9.65l3.25 1.5-3.45 1.05-3.6-2 3.8-.55m-28.25-5.85l-4.45-.25-2.6-2.8 3.9.45 3.15 2.6m3.6 2.85l-2.4-2.4 3.35.2 2.65 2.2h-3.6m7.8 2.55l-2.4-2.25 3.25-.25 2.3 1.95-3.15.55M11.75 9.2l3.8 1 2.3 2.75-3.7-.5-2.4-3.25m-5.1-1.55L4.95 4.8l3.15.85 2.3 3-3.75-1z"
                    id="AccJew001__Symbol_72_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew001__Symbol_72_0_Layer0_0_FILL"
                transform="translate(129.2 228.35)"
                id="AccJew001__col1n"
            />
        </g></g>
    )
}

export default AccJew001
