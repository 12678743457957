import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#007E31",
    "col1n": "#00B145"
}

function LegSho009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M160.8 8.3q0-3.45-2.45-5.9-2.5-2.4-6-2.4-3.4 0-5.8 2.25-.35.4-.65.85l-.7 1.1q.1-.05.2-.15.45-.4.95-.65 1-.5 2.2-.5 1.85 0 3.15 1.15 1.3 1.15 1.3 2.8 0 1.3-.85 2.35l-.45.45-.1.1q-1.9 1.4-7.7 1.35h-.45q-1.95-.1-4.7-.4-.8-.05-2.1-.25t-3.35-.35q-2.1-.2-2.5-.25-.4-.1-.8-.15-.5-.05-1.05-.1-14.25-1.7-31.05.45-1.65.2-3.35.45l-1.4.2q-4.65.7-9.5 1.7-1.55.3-3.2.7-1.7-.4-3.3-.7-4.85-1-9.5-1.7l-1.4-.2q-1.7-.25-3.35-.45Q46.1 7.9 31.85 9.6q-.55.05-1.05.1-.4.05-.9.1-.55.05-2.25.3-1.7.2-3.25.4-1.5.15-2.3.2h-.05q-2.7.3-4.65.4h-.5l-6.45-.7q-.75-.3-1.2-.65l-.1-.1-.45-.45q-.85-1.05-.85-2.35 0-1.65 1.3-2.8.65-.55 1.45-.85l1.65-.3.65.05q.8.1 1.55.45.5.25.95.65.1.1.2.15l-.7-1.1q-.3-.45-.65-.85Q11.85 0 8.45 0q-3.5 0-6 2.4Q0 4.85 0 8.3q0 2.5 1.3 4.5.1.15.25.3.4.55.9 1.05l.3.3Q4 15.7 6.2 16.65q.7.3 1.6.55 2.05.65 4.8 1.05.35.05.75.15l36.25.95.9-.05h.05q.1-.05.2-.05h.05q13.85-.8 29.4-1.35h.3q15.65.55 29.55 1.35.15 0 .25.05l.95.05q21.45 1.45 36.95-1.1 2.75-.4 4.8-1.05 1-.3 1.85-.65 2-.9 3.2-2.1l.3-.3q.5-.5.9-1.05.15-.15.25-.3 1.3-2 1.3-4.5z"
                    id="LegSho009__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M20.5 8.25h-.05q-2.7.3-4.65.4h-.5l-6.45-.7q-.75-.3-1.2-.65l-.1-.1-.45-.45Q6.25 5.7 6.25 4.4q0-1.65 1.3-2.8Q8.2 1.05 9 .75l1.65-.3.65.05q.85.05 1.6.45Q11.55 0 9.8 0 7.65 0 6.15 1.4q-1.5 1.45-1.5 3.45 0 2.05 1.5 3.5l.5.4q3.1 1.75 9.35.5l4.5-1M146.95.45q1.85 0 3.15 1.15 1.3 1.15 1.3 2.8 0 1.3-.85 2.35l-.45.45-.1.1q-1.9 1.4-7.7 1.35h-.45q-1.95-.1-4.7-.4l4.5 1q6.25 1.25 9.35-.5l.5-.4q1.5-1.45 1.5-3.5 0-2-1.5-3.45Q150 0 147.85 0q-1.75 0-3.1.95 1-.5 2.2-.5m9.5 11.55l.3-.3q.5-.5.9-1.05-1.65 1.9-5.1 3-1.3.45-2.9.75l-.8.2q-.4.1-.8.15-.7.15-1.45.25-15.6 2.6-49-.25-8.75-.75-18.7-1.85h-.2q-.15 0-.3.05-9.7 1.1-18.2 1.8-33.55 2.85-49.15.25-.75-.1-1.45-.25-.4-.05-.8-.15l-.8-.2q-1.45-.3-2.7-.65-3.6-1.15-5.3-3.1.4.55.9 1.05l.3.3q1.25 1.25 3.45 2.2.7.3 1.6.55 2.05.65 4.8 1.05.35.05.7.15L48 16.9l.9-.05h.05q.1-.05.2-.05h.05q13.85-.8 29.4-1.35h.3q15.65.55 29.55 1.35.15 0 .25.05l.95.05q21.45 1.45 36.95-1.1 2.75-.4 4.8-1.05 1-.3 1.85-.65 2-.9 3.2-2.1z"
                    id="LegSho009__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho009__Symbol_14_0_Layer0_0_FILL"
                transform="translate(112.1 317.15)"
                id="LegSho009__col1n"
            />
            <use
                xlinkHref="#LegSho009__Symbol_15_0_Layer0_0_FILL"
                transform="translate(113.7 319.6)"
                id="LegSho009__col1d"
            />
        </g></g>
    )
}

export default LegSho009
