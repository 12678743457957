import { AssetSvgProps } from "../../../shared/assets"

function ShiMot054({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#0079CA"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot054__Layer5_0_FILL"
                />
                <path
                    fill="#FFCE00"
                    d="M159.2 272.25v7.55h17.85v14h7.65v-14h41v-7.55h-41V258h-7.65v14.25H159.2z"
                    id="ShiMot054__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot054__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot054__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot054
