import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#444444",
    "col1n": "#434343"
}

function ObjMus012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 3.4l35.3 29.7q3.05-5.7.2-11.45l.05-.5-13.2-8.5L.05 0 0 3.4m95.15 11.75L89.7 20.6l.3.1q4.3 0 9.5-2.7l6.2-6.1q-5.2-.1-10.4 3.15l-.15.1m-8.05 6.4l-5.45-2.35-.15-.05q-4.85-.7-6.4 1.95H75l-1.5.45.15.05 5.3 2.3q4.65.2 8.15-2.35z"
                    id="ObjMus012__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M39.3 76.15q-.05-.05-.45-.2-.45-.2-.9-.25-.5-.1-1.1-.05-.65 0-1.35.35-.95.45-.95 1.35 0 .65.3 1.25.1.2.2.35L61.15 101q.1.1.3.2.2.15.55.25.75.25 1.7.05.1-.05.25-.1.75-.2 1.25-.65 1.15-1.4 0-2.75L39.3 76.15m81.05-16.35l-.05-.1-9-6q-3.4-2.15-5.8-1.4-.75.25-1.05.95-.05.1-.1.3l.35 2.7 14.95 9.1 14.15 9.25q2.2 1.3 3.55 0 1.75-1.8.25-3.3l-11.55-7.7-.05-.05v.05l-2.65-1.8.25.1q-.65-.4-1.6-1.05l-1.3-.8V60h-.05l-.3-.2M151.8 0l-.75.25L0 24.55 22.3 37.2l13.2 8.5-.05.5 33.9-7.85q49.1-11.85 96.25-29.8L151.8 0z"
                    id="ObjMus012__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill="#F2F2F2"
                    d="M265.9 295.95l3.8-1.05q-.483-3.969 6.05-1.7l13.75-4q1.72-3.833 6.35-1.85l3.35-.95q.693-2.739 6.95-2l14.85-4.25-22.15-13.7-6.7 1.9-.05.05-122.9 34.5-.05 1.75q12.78 6.979 22.35 15.5v-2.8l8.75-2.5q1.109-2.41 6.6-1.9l7.65-2.2h.05l6.4-1.9q1.658-3.313 6.5-1.85l4-1.1q1.626-2.44 5.25-1.55l14-4.05q1.05-2.7 5.85-1.65l3.6-1.05q-.268-3.969 5.75-1.65z"
                    id="ObjMus012__Layer4_0_FILL"
                />
                <path
                    d="M262.25 302.35l-2-1.9-9.15 2.55 2.4 1.9 8.75-2.55m16.3-2.55l-5.3 1.6 1.95 2 5.7-1.6-2.35-2m-12.45 6.15l-1.95-1.8-8.45 2.5 2.1 1.65 8.3-2.35m21.05-5.9l5.3-1.5-2.05-2.25-5.2 1.55 1.95 2.2m-81.55 18.5l1.9 1.35 40.65-11.6-1.75-1.25-40.8 11.5m-2.6-1.85l1.7 1.2 40.55-11.65-1.65-1.15-40.6 11.6m5.65 4l2.35 1.65 40.35-11.85-2-1.4-40.7 11.6m110.25-30.05l2.75-.8-8.3-5.55-2.6.75 8.15 5.6M304 286.9l8 5.65 2.8-.75-8.15-5.65-2.65.75m-6.1 7.15l2.9 2.15 5.65-1.6-2.85-2.25-5.7 1.7m26.4-4.9l2.9-.8-8.75-5.45-2.7.75 8.55 5.5z"
                    id="ObjMus012__Layer4_2_FILL"
                />
                <g
                    id="ObjMus012__Symbol_63_0_Layer0_0_FILL"
                    fill={colors.col1l}
                >
                    <path d="M76.1 72.35q.05.05.2.1l-.15-.15-.05.05zM84.9 56.9l3.8 1.55q1.35.45 1.9-.15.15-.4.3-.7l.05-.05q.15-.55.2-1.05 0-.75-.3-1.3l-.05-.05v-.05q-.55-.8-1.7-1.4l-12.15-5.2q-.5-.25-1.5-.7L57.9 40.25l6.3-6.2-9.5 2.7-.3-.1-1.5 1.45-.05-.05-1.05-.45-8.15 2.35 5.15 2.25-34.45 34.1q-1.1 3.95 3.65 3.1l36.3-35.15 30.6 12.6v.05m45.5-46.85V0l-.05.05-96.2 27.8h-.05L24.05 30.8H24L.2 37.7 0 49.15l36.05-10.8h.1l2.2-.7-.15-.05 1.5-.45h.1l6.4-1.95.15.05 13.5-4.05.15-.1 10.4-3.15-.15.15 60.15-18.05z" />
                </g>
                <g id="ObjMus012__Layer4_1_FILL">
                    <path
                        fill="#999"
                        d="M189.25 305.4l2.05-.6-9-5.6-2.05.6 9 5.6m13.4-11.9l-2.05.55 9.05 5.65 2.05-.6-9.05-5.6m28-7.85l8.8 5.45 2-.55-8.75-5.5-2.05.6m-8.1 2.25l-2 .55 22.55 14.05 2-.55-22.55-14.05m-1.4 8.35l-8.85-5.45-2 .5 8.9 5.6 1.95-.65m-28.6.1l-2.1.6 22.8 14.15 1.25-.35h.05l.7-.25-22.7-14.15m99.55-27.95l-2 .55 6.45 4 .05.05 15.6 9.7 2.05-.6-22.1-13.75-.05.05m-22.35 6.25l8.4 5.25 2.05-.6-.2-.05-8.2-5.15-2.05.55m-21.5 13.8l2.05-.6-8.5-5.3-2.1.55 8.55 5.35m3.7-8.8l-2.05.6 8.6 5.3.05.05 2-.55-8.6-5.4m10.2-2.85l-2.1.6 22.35 13.85 2-.6-22.25-13.85m19.85-5.6l-2.05.6 8.4 5.2 2.05-.55-8.4-5.25z"
                    />
                    <path d="M189.25 305.4l-2.2.6 13.2 8.85 6.6-1.9-13.9-8.6-1.65.45-2.05.6m22.45-6.3l-2.05.6-1.9.55 13.2 8.6 6.5-1.85-13.75-8.45-2 .55m27.75-8l-2.3.7 13.55 8.5 5.85-1.65-13.7-8.55-1.4.45-2 .55m-18.3 5.15l-1.95.65-1.45.4 13.7 8.6 5.25-1.55-13.6-8.7-1.95.6m61.15-17.5l-2.1.55-2.05.6-2.1.6 13.45 8.7 6.35-1.85-13.55-8.6m-34.05 9.7l-1.65.5 13.55 8.65 5.75-1.65-14-8.55-1.6.45-2.05.6m10.3-2.85l-2.6.75 13.75 8.55 6.05-1.7-13.9-8.6-1.3.45-2 .55m31.85-9.15l-2.05.55-2.4.7 13.25 8.7 6.95-2-13.95-8.45-1.8.5z" />
                    <path
                        fill="#C6C6C6"
                        d="M191.5 320.15v-2.8l-22.3-14.45-.05 1.75 22.35 15.5z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus012__Symbol_98_0_Layer0_0_FILL"
                transform="translate(169.15 304.7)"
                id="ObjMus012__col1n"
            />
            <use
                xlinkHref="#ObjMus012__Symbol_78_0_Layer0_0_FILL"
                transform="translate(169.2 280.15)"
                id="ObjMus012__col1d"
            />
            <use
                xlinkHref="#ObjMus012__Symbol_63_0_Layer0_0_FILL"
                transform="translate(204.45 288.65)"
                id="ObjMus012__col1l"
            />
            <use xlinkHref="#ObjMus012__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus012__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus012__Layer4_2_FILL" />
        </g></g>
    )
}

export default ObjMus012
