import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2n": "#C40000"
}

function AccMor038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M271.55 97.6q-5.45-8.45-12.7-17.35-9.8-12.6-14.55-25.45l-1.4-2.8q-.1-.1-.15-.2 0-.05-.05-.1-.1-.15-.2-.25l-.2-.3q-.25-.35-.45-.6l-.1-.1q-.1-.05-.15-.1l-.15-.15q-.1-.05-.15-.1L230.05 38q-.75-1.45-1.25-2.85-.55-1.25-1.15-2.4-7.35-15.9-20.75-26.45-2.15-1.55-4.8-3.05-.45-.3-.95-.55l.2.15q-2.65-1.3-5.05-2.1-.9-.3-2.15-.75l-5.7 4.55q-.35.25-.65.5-.15.05-.25.15L186 6.35q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L76.75 5.2q-.9-.65-1.75-1.3-.25-.2-.45-.35-.3-.25-.6-.45L70.8.65h-.05Q65.4 3.1 60.5 7.85q-4.45 3.7-9.65 8.9-9.1 8.6-11.4 12.35-2.55 4.15-6.55 21.35-.25 8.7-23.95 35.15-.65 1-1.85 2.25-2.2 2.25-4.75 4.85l.3-.45Q1.55 93.6.9 94.95q-2.5 5.15 2.1 9.75 3.45 2.75 8.6.25 7.75-3.65 13.15-4.25 5.65-.65 8.5.8.15.1.3.15 5.65 3.25 13.15 0 1.25-.45 3.1-1.35 1.8-.9 4.15-2.35Q56.3 96.45 61 96q4.65-.5 10.05 2.1 2.15.75 5.6 2.05.3.05.8.25.8.2 2.85.7 11.8 3.6 19.35 0 .05-.05.1-.05 1.6-.65 4.1-2.75 2.45-2.15 5.8-2.65l1-.05 9.75.3q10.05 0 11.1.1 1.65.15 8.65 1.85 1.45.35 2.65.65 9.45 2.6 20.55 0 1.95-.5 4.95-1.5 3.85-1.25 5.85-3.05.85-.85 1.8-1.35 5.35-3.4 10.55-.1.05.05.15.1l.05.05q.7.25 1.8 1.15.85.75 3.15 2.7 5.1 3.95 10.3 5.5 5.2 1.5 8.5-1t10.1-3.6q4.65-.8 7.55-.05 3.75.4 8.15 2.6.3.15 6.4 3.8.4.25 4.45 2.05 8.3 2.85 16.65 0 2.5-.85 4.6-2.2 1.65-1.05 2.6-2 .55-.65.85-1.2.6-1.3-.05-2.5v-.1l-.2-.2M188.1 37.5q.05 0 .15.1.05.1.1.25l-.25-.35m-.75.45q0-.15.1-.3l-.1.4v-.1m28 20.55l.05.15q-.1-.05-.15-.1l.1-.05M77.15 38.05L76.9 38l.1-.4.05.05q0 .05.05.15.05.1.05.25m-.6 1.7v-.05h.05l-.05.05M49.3 58.5l.05-.05.05.05h-.1z"
                    id="AccMor038__Symbol_136_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M265.1 90.1q-3.95-1.4-8.85-1.4-6.2 0-9.8 2.4-1.4.95-2.1 2-1.45-1.6-2.15-3.3-1.1-2.75-2.6-6.8t-1.85-19.65q-.4-15.55-6.2-18.55-5.8-3.05-5.95-4.8l-.1.1q-.9-2.85-1.65-5.15Q213.8 7.95 190 1.4q-1.1-.2-2.2-.25-.15.05-.25.15L186 2.45q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L76.75 1.3Q75.85.65 75 0 58.55 9.7 45.85 26.45q-2.35 3.35-4.4 6.9-2.9 7.25-1.05 16.7 1.9 9.8-2.85 18.45-4.7 8.7-5.15 22.5-3.6-3.35-6.2-4.4-2.6-1.1-6.05-1.65-2.6-.4-6.5-.2-1.4.05-2.95.2-5.6.95-9.8 6.1-2.5 5.15 2.1 9.75 3.45 2.75 8.6.25 7.75-3.65 13.15-4.25 5.65-.65 8.5.8.15.1.3.15 5.65 3.25 13.15 0 1.25-.45 3.1-1.35 1.8-.9 4.15-2.35 2.35-1.5 7.05-1.95 4.65-.5 10.05 2.1 2.15.75 5.6 2.05.3.05.8.25.8.2 2.85.7 11.8 3.6 19.35 0 .05-.05.1-.05 1.6-.65 4.1-2.75 2.45-2.15 5.8-2.65l1-.05 9.75.3q10.05 0 11.1.1 1.65.15 8.65 1.85 1.45.35 2.65.65 9.45 2.6 20.55 0 1.95-.5 4.95-1.5 3.85-1.25 5.85-3.05.85-.85 1.8-1.35 5.35-3.4 10.55-.1.05.05.15.1l.05.05q.7.25 1.8 1.15.85.75 3.15 2.7 5.1 3.95 10.3 5.5 5.2 1.5 8.5-1t10.1-3.6q4.65-.8 7.55-.05 3.75.4 8.15 2.6.3.15 6.4 3.8.4.25 4.45 2.05 8.3 2.85 16.65 0 2.5-.85 4.6-2.2 1.65-1.05 2.6-2 2.2-4.6-5.85-7.6m-77.75-55.95v-.1q0-.15.1-.3l-.1.4m1-.2l-.25-.35q.05 0 .15.1.05.1.1.25m27 20.65l.05.15q-.1-.05-.15-.1l.1-.05M77.05 33.75q0 .05.05.15.05.1.05.25l-.25-.05.1-.4.05.05m-.5 2.1v-.05h.05l-.05.05M49.3 54.6l.05-.05.05.05h-.1z"
                    id="AccMor038__Symbol_80_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor038__Symbol_136_0_Layer0_0_FILL"
                transform="translate(60.35 226.9)"
                id="AccMor038__col1n"
            />
            <use
                xlinkHref="#AccMor038__Symbol_80_0_Layer0_0_FILL"
                transform="translate(60.35 230.8)"
                id="AccMor038__col2n"
            />
        </g></g>
    )
}

export default AccMor038
