import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#215140",
    "col1n": "#34725C",
    "col2d": "#6486A6",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccMor069({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.45 4.5q.55-1.1.5-2.4-.15-.15-.15-.4 0-.2.05-.3-.05-.05-.1-.15-.2-.5-.25-.6-.2-.25-.9-.35h-.25q-.3 0-.4-.15h-.6Q17 .25 15.7.3q-.15.15-.5.3-.8.35-1.65.35Q10.8.95 10.3.4H8.75q-.8-.05-1.6-.05H6.8q-.1.2-.3.3-.45.1-.75.15H4.2Q2.7.6 2.1.5q-.15 0-.3-.05Q1.4.4 1 .2.85.1.75.05.6 0 .45 0q-.2.55-.3 1.05-.25 1.1-.1 2.05.15.15.35.4.75.95 2.35 3.25Q3.7 8 5.05 9q.7.55 1.55 1.15.5.45.5 1.15 0 .4-.6 1.5-.65 1.15-1.15 1.25l-.9 2.15q.2-.05.3.1.05.1.05.6-.6 3.85-1.3 4l-.15 3.4q.6.3.6 2.55-.05 2.85-.65 2.85-.15 0-.25-.05L2.9 32.5q.2.15.35.55.2.6.2 1.4-.1 3.55-.85 3.6l-.2 3.35q.35.5.3 2.05-.15 3.1-.6 3.4l-.2 3.1q.15.1.3.35.2.9.2 1.3-.15 3.15-.75 3.15L1.5 57.1l8.95 8.9 1.35-1.35q-.05-.1-.05-.25 0-.25.8-1.15.85-1.05 1.45-1.05.15 0 .25.05l1.8-1.75q-.05-.15-.05-.25 0-.25 1-1.3 1.05-1.15 1.65-1.15h.05l.7-.7-.15-2.55q-.25-.3-.35-1-.05-.25-.05-1.45l-.45-2.8q0-.55.4-.65h.15l-.2-3.9q-.3-.55-.5-2Q18 41 18 40.05q0-.55.4-.6h.05l-.15-2.8v.3l-.1-2.2q-.3-.3-.4-1-.05-1.2-.05-1.25 0-.35-.25-1.4-.25-1.05-.25-1.15 0-.5.55-.5h.1l-.25-4.2q-.45-1.1-.8-4.5 0-.45.55-.5l-.15-2.15-.55-1.25v-.05l-.75-1.8q-.55-.4-1.3-1.95-.9-1.85-.9-2.3 0-.95 1.75-.95.4 0 .65.1 1.1-.75 2.05-1.75-.05-.15-.05-.3 0-1.05 1.25-2.45.7-.8 1-.75 0-.05.05-.15z"
                    id="AccMor069__Symbol_172_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M7.9 0q-.05 0-.15.05-.7.1-1.35.2-.55.05-1.05.1-.1 0-.25.05H3.15Q2.8.35 2.4.3L.8.1Q.7.05.6.05.5 0 .4 0L0 1.1q.3.1.55.2 1 .25 2.05.35.7.1 1.45.1h.1q.55 0 1.1-.05.4 0 .8-.05 1.1-.2 2.25-.55L7.9 0z"
                    id="AccMor069__Symbol_173_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.4 62.15L1.95 49.7l-.3 5.1 10 10 2.75-2.65m4.4-16.6l-.35-6.4-15.1-15.1-.3 5.75L18.8 45.55m-16.4-4.3L2.1 47l13.7 13.7 3.05-3L2.4 41.25m.35-6.4q-.05.25-.05.65v.2l-.1 2.55 16.7 16.7-.35-6.4L2.9 32.5l-.15 2.35M4.55 16l-.35.8v.05l-.55 1.25L3.5 21l14.75 14.75-.35-6.4L4.55 16M0 2.55q0 .5.15 1 .1.5.35.95.35.65.75 1.35.15.25.35.6l.1.2q.1.2.25.35.35.55.8 1.05Q3.7 9.2 4.95 10q.25.15.55.3.55.3 1.2.55L5.4 14l12.3 12.3-.35-6.4L0 2.55m14.2 8.3q.05 0 .1-.05.6-.2 1.2-.5.45-.25.95-.55L7.05.35H6.6Q5.15.25 3.75.25 3.4.2 3.05.2q-.1-.05-.2-.05Q1.9.1 1 .05H.7L16.15 15.5l-1.95-4.65M15.6.3h-.2q-.4 0-.8.05-.75 0-1.45.05h-3.2l8 8q.2-.2.4-.45.45-.6.9-1.3l.1-.2.05-.05q.4-.7.75-1.35l.2-.3L15.9.3h-.3m5.1.35Q20.6.3 20.5 0q-.1 0-.2.05h-.15l-1.2.1 2 2q-.05-.7-.25-1.5z"
                    id="AccMor069__Symbol_174_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.95 54.8l10 10 1.25-1.2L1.1 52.5l-.15 2.3M2 35.5v.2l-.1 2.55 16.7 16.7-.15-3L2 35.5m.35-5.7L18.1 45.55l-.2-3.4-15.4-15.4-.15 3.05m-.8 14.15L1.4 47l13.7 13.7 1.6-1.6L1.55 43.95m15.3-21l-11.2-11.2L4.7 14 17 26.3l-.15-3.35M2.95 18.3L2.8 21l14.75 14.75-.2-3.05-14.4-14.4m10.55-7.45q.05 0 .1-.05L3.05.25Q2.7.2 2.35.2q-.1-.05-.2-.05Q1.2.1.3.05H0L15.45 15.5l-1.95-4.65M11.7.4H9.25l8 8q.2-.2.4-.45.45-.6.9-1.3l.05-.1L12.45.4h-.75m7.9-.35h-.15l-1.2.1 2 2q-.05-.7-.25-1.5-.1-.35-.2-.65-.1 0-.2.05z"
                    id="AccMor069__Symbol_175_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M3.75 1.75L2.3.3.8.1Q.7.05.6.05.5 0 .4 0L0 1.1q.3.1.55.2 1 .25 2.05.35.55.1 1.15.1M8.3 1.1L7.9 0q-.05 0-.15.05-.7.1-1.35.2-.45.05-.85.1l1.2 1.2q.75-.2 1.55-.45z"
                    id="AccMor069__Symbol_176_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor069__Symbol_172_0_Layer0_0_FILL"
                transform="translate(182.05 248.95)"
                id="AccMor069__col1n"
            />
            <use
                xlinkHref="#AccMor069__Symbol_173_0_Layer0_0_FILL"
                transform="translate(188.35 259.8)"
                id="AccMor069__col1d"
            />
            <use
                xlinkHref="#AccMor069__Symbol_174_0_Layer0_0_FILL"
                transform="translate(182.05 248.95)"
                id="AccMor069__col2n"
            />
            <use
                xlinkHref="#AccMor069__Symbol_175_0_Layer0_0_FILL"
                transform="translate(182.75 248.95)"
                id="AccMor069__col2l"
            />
            <use
                xlinkHref="#AccMor069__Symbol_176_0_Layer0_0_FILL"
                transform="translate(188.35 259.8)"
                id="AccMor069__col2d"
            />
        </g></g>
    )
}

export default AccMor069
