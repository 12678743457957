import { AppBottomPlaceholder } from "./AppBottomPlaceholder"

export function AppPlaceholder() {
    return (
        <div className="app">
            <section className="stage-section">
                <div className="outer-stage">
                    <div className="stage" />
                </div>
            </section>

            <AppBottomPlaceholder />
        </div>
    )
}