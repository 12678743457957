import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2n": "#FF0000"
}

function AccHat162({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M161.05 25.9q-5.65-5.2-13.6-10-1.1-.65-2.2-1.25Q120.45.75 86.55 0H78.5Q46.55.7 22.7 13.05q-2.4 1.25-4.75 2.65-.2.1-.45.2Q6.5 22.5 0 30.2q2.25 11.65 15.75 24l.1.2 2.55 10.5q32.5-8.1 64.45-8.15 32.25 0 64.95 8.15l2.45-10.5q13-11.95 15.7-23.15-1.85-2.35-4.15-4.55-.4-.45-.75-.8z"
                    id="AccHat162__Symbol_530_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M134.4 8.85q-1.2-.35-2.45-.65Q99.4.1 67.4 0H67Q35.05.1 2.55 8.2 1.3 8.5 0 8.85l.35 1.2q33.3-7.3 66.65-8.2V1.8h.4q33.35.95 66.75 8.25l.25-1.2z"
                    id="AccHat162__Symbol_531_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M26.9 17.2V9.85h-9.85V0H9.8v9.85H0v7.35h9.8V28h7.25V17.2h9.85z"
                    id="AccHat162__Symbol_529_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat162__Symbol_530_0_Layer0_0_FILL"
                transform="translate(109.55 19.5)"
                id="AccHat162__col1n"
            />
            <use
                xlinkHref="#AccHat162__Symbol_531_0_Layer0_0_FILL"
                transform="translate(125.3 65.05)"
                id="AccHat162__col1d"
            />
            <use
                xlinkHref="#AccHat162__Symbol_529_0_Layer0_0_FILL"
                transform="translate(179.05 29.45)"
                id="AccHat162__col2n"
            />
        </g></g>
    )
}

export default AccHat162
