import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSho050({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M182.65 37.45q-6.75-4.9-14.55-8.7-2.5-1.25-5.15-2.35-3.3-6.7-9.4-11.5-6.55-5.4-15.2-1.1-4-1.9-8.1-3.35-.6-.25-1.2-.45-.05-.05-.15-.05l-2-.7q-.1-.1-.2-.15l-.45-.45q-.95-.9-2-2.1-7.45-8.15-18.9-3.05-1.4.9-2.7 1.4Q99 6.1 95.5 4.25l-.9-.5q-.2-.15-.35-.25-.45-.3-.85-.65l-.3-.2Q89.9.75 87.3.45q-5.6-1.8-20.1 3.45l.6-.1q-5.65 2-17.35 11.35-1.85 1.5-3.85 3.15h-.35q-1.25.05-2.5.2-14.45 1.15-22.05 7.6-1.75 1.45-3.1 3.2-3.15 4.1-6.9 6.75-3.55 2.5-5.95 8.2-3.5 8.2-4.7 22.95-.35 4.1-.5 8.75Q-.7 98.1 1 124.6q6.25 6 10.55 13 2.65-19.8 4.7-38.8 1-9.5 3.05-18.6 1.45-6.6 3.5-13 1.1-3.4 2.35-6.75 1.4-3.85 4.35-6.55.2-.2.45-.4v-.05q1.1-1 2.4-1.8 3.65-2.3 6.7-5.8v-.15q0-.2-.05-.35 2.55 1.05 4.75 1.7 6.3 1.7 9.65-.4Q54.45 46 55.2 45q.5-.7.9-1.6.05 0 .1.05 3.05 1.95 6 3.5 1.75.9 3.45 1.7.8.35 1.65.7l.05.05q3.3 1.5 7.15 2.4 4.1.95 8.85 1.2 3.55.4 6.95.1.4-.05.8-.05 3.25-.3 6.7-.65 3.85 1.35 7.35 2.25 1.95.5 3.8.85 4.95 1 9.1 1 2.25 0 4.25-.3 1.55-.2 2.95-.55.75-.2 1.45-.45.45-.2.85-.35 10.75-4.35 12.5-4.6.45 0 .95-.15.2-.05.45-.1 1.05-.15 2.15-.25 3-.25 5.65-.15 5.8.2 10.05 2.15 9.25 4.2 10.95 16.55 0 .1.7 1.65 1.5 5.05 2.7 10.25l.8 3.9q.2.9.35 1.8 1.2 6.35 1.9 12.9.25 2.65.55 5.35 1.5 13.5 3.35 27.4.35 3 .8 6.05 1.9-3.15 4.2-6.05 2.6-3.35 5.75-6.35 0-.05.1 0 .1 0 .7-7.7.5-7.8.65-31.05.15-23.3-3.4-39.85-1.3-3.65-3.65-6.3-1.35-1.6-3.1-2.85M53.3 15.85l.05.05h-.05v-.05m2.8 27.2h.05q-.048.144-.05.25v-.25z"
                    id="HaiSho050__Symbol_171_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.85 49.1l-.1.1q1.1-1 2.4-1.8 3.65-2.3 6.7-5.8v-.15q0-.2-.05-.35Q9.45 31.15 0 26.2q9.35 14.25 2.35 22.4l-.5.5M29.9 23.45q-1.45-1.3-3.1-2.4.05.1.1.25l.05.2q4 11.9 1.05 17.7 3.05 1.95 6 3.5 1.75-10.7-4.1-19.25m1.25-6.5Q28.8 13 22.25 10.9q-1.85 1.5-3.85 3.15 6.4 0 12.75 2.9m15.15 30.6q4.1.95 8.85 1.2 3.55.4 6.95.1.4-.05.8-.05 3.25-.3 6.7-.65.05-.05.1-.05.05-.5.2-1.3 1.4-8.55-2.95-15.7-.65-1.1-1.45-2.15-4.75-6.55-10.05-11.85 6.2 7.55 7.75 17.1.55 3.7.45 7.7-1.8 8.85-17.35 5.65M67.3 0q8.55 5.1 9.45 6.75l-2.3-6.1Q70.8 1.85 67.3 0m21.5 17.3q2.3 3.1 3.75 6.5 1.9 6.95 2.25 12.45.85 7.75-1.7 11.6-3.35 4.95-12.35 3.4 4.95 1 9.1 1 2.25 0 4.25-.3 1.55-.2 2.95-.55.45-.3.9-.65.3-.65.55-1.25 3.6-8.85.5-18.55-.25-.6-.5-1.15-.8-1.85-1.65-3.65-2.85-5.65-7.2-9.75-1.65-1.5-3.5-2.8 1.35 1.9 2.65 3.7m14.35-6.95l-1.1-4.15q-.6-.25-1.2-.45-.05-.05-.15-.05l-2-.7q-.1-.1-.2-.15l-.45-.45 5.1 5.95m12.7 11.8q-1.65-1.75-2.7-2.6-1.1-.85-4.8-3.55-2.05-1.45-4.1-3.05.2.15 4 3.65t6.5 9.3q3.25 12.45.75 16.45-1.9 3-2.9 3.55.1-.05.2-.05.2-.05.45-.1 1.05-.15 2.15-.25 3-.25 5.65-.15 3.8-13.05-5.2-23.2m20.9 13.4q4.1-3.95 3.15-11.05-2.5-1.25-5.15-2.35 3.65 6.7 2 13.4z"
                    id="HaiSho050__Symbol_90_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho050__Symbol_171_0_Layer0_0_FILL"
                transform="translate(96.25 41.8)"
                id="HaiSho050__col1n"
            />
            <use
                xlinkHref="#HaiSho050__Symbol_90_0_Layer0_0_FILL"
                transform="translate(124.45 46.05)"
                id="HaiSho050__col1d"
            />
        </g></g>
    )
}

export default HaiSho050
