import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M81.25 9.55q1.55-2 4.9-4.5Q87.85 3.6 88.8 0q-1.95 1.7-3.95 3.25Q79.1 7.45 72.8 10q-4 1.65-8.15 2.65-5.5 1.25-11.25 1.4h-3.1q-.95 0-1.9-.05.45-1.95.9-3.5.05-.15.1-.25.35-1.3.85-2.6t1.4-2.35v-.05q-7.25 1.45-14.5 0l.05.05q.85 1.05 1.4 2.35.45 1.3.85 2.6 0 .1.05.25.45 1.55.9 3.5-.95.05-1.9.05h-3.05q-5.8-.15-11.25-1.4-4.2-1-8.2-2.65Q9.7 7.45 4 3.25 1.95 1.7 0 0q.95 3.6 2.65 5.05 3.35 2.5 4.9 4.5.4.45 1.15 1.9l.1.3q.35.5.75 1 .75 1 1.8 1.95 1.5 1.3 3.5 2.35 1.95 1.05 4.4 1.8 2.55.9 5.7 1.45 4.35.55 8.7.75h.6q3.55.3 5.65 2.15.15.05.25.15l.35.35q1.75 3.25 2.45 8.7.7 3.05 1 6.1.05.35.05.65.3 1.2.45 5 .4-5.45.4-5.65.3-3.05 1.05-6.1.65-5.45 2.4-8.7.2-.2.4-.35.05-.1.2-.15 2.1-1.85 5.65-2.15h.65q4.3-.2 8.65-.75 3.15-.55 5.75-1.45 2.4-.75 4.4-1.8 1.95-1.05 3.5-2.35 1-.95 1.8-1.95l.7-1q.05-.15.15-.3.7-1.45 1.1-1.9z"
                    id="BeaChi012__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaChi012__Symbol_51_0_Layer0_0_FILL"
                transform="translate(148.1 231.35)"
                id="BeaChi012__col1n"
            />
        </g></g>
    )
}

export default BeaChi012
