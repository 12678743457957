import { AssetSvgProps } from "../../../shared/assets"

function AccHat127({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#174A0B"
                    d="M238.75 134.2q1.15.3 2.2.55 6.05 1.2 10.7 0 4.3-1.35 7.55-4.25 4.95-4.35 6.05-9.25 1.1-4.85 1.1-8.5l-2.6-1.65q1.1 12.6-9.15 19.75l-1.45.9q-2.4.9-7 1.4-4.55.5-15.9-2.85-6.25-1.7-12.55-2.95-46.7-9.45-99.55 3.25-7.1 1.75-14.6 3.95-16.35 3-15.25-4.7-.35.65-.65 1.25-2.1 9.7 15.5 6.15 6.4-2.05 12.6-3.75 45.25-11.8 93.7-5.65 13.45 2 26.9 5.7l1.1.3q.65.15 1.3.35z"
                    id="AccHat127__Layer16_1_FILL"
                />
                <path
                    fill="#FF0"
                    d="M196.55 56.7q-.7-.2-1.4-.35-4.15.65-7.9-.1-.25.05-.45.1-9.75 2.1-17.3 7.05-17.85 11.65-24 39.05-.15.55-.25 1.15 48.75-6.35 92.55 1.35.6 0 1.25.05-.2-.75-.35-1.45-7.35-28.15-26.35-40.15-7.1-4.5-15.8-6.7z"
                    id="AccHat127__Layer16_2_MEMBER_0_FILL"
                />
                <path
                    fill="#B7B7B7"
                    d="M189.55 45.4q0 1-2.6 1.85-.3.1-.65.2-.3.9-.4 1.95 5.85 1.3 11.75 0-.15-1.05-.45-2-.2-.05-.35-.1-3.3-.95-3.3-2.05 0-.4.15-1.7-.15.5-.15.1 0-.25.15-.45.05-.1.2-.2v-.05q-1-.55-2.15-.55-1.35 0-2.5.75.25.25.3.5v.15q0 .4.05-.1-.05 1.3-.05 1.7z"
                    id="AccHat127__Layer16_3_FILL"
                />
                <path
                    fill="#999"
                    d="M181.75 40.85q-.985-.19-2.15-.35h-52.95q-1.075.15-2.05.3-7.85 1.3-7.85 3 0 .4.15-.2 2.05 2.5 10.5 3.6 10.65 1.4 25.75 1.4t25.75-1.4q5.35-.7 8.05-1.55 1.25-.4 2.25-1.4.15-.2.35-.45v-.15q-.05-.25-.3-.5-.2-.2-.5-.4-1.7-1.05-7-1.9m84.6 2.8q0-1.7-7.7-2.95-.564-.104-1.2-.2H202.4l-.95.15h-.05q-4.8.8-6.65 1.75-.55.3-.85.55V43q-.15.1-.2.2-.15.2-.15.45 0 .4.15-.1.05.1.15.2 1.1 1.4 3 1.95 2.6.75 7.35 1.35 10.65 1.4 25.75 1.4t25.75-1.4q8.45-1.1 10.6-3.4.05.4.05 0z"
                    id="AccHat127__Layer16_4_FILL"
                />
                <path
                    fill="#B7B7B7"
                    d="M177.2 41.8q2.25-.45 4.55-.95-1.3-.25-2.85-.45-10.65-1.45-25.75-1.45T127.4 40.4q-1.5.2-2.8.4 2.25.55 4.5 1 23.3 4.65 48.1 0m81.35-1.1h.1q-1.35-.25-2.95-.45-10.65-1.45-25.75-1.45t-25.75 1.45q-1.5.2-2.75.4 2.25.55 4.5 1 23.25 4.65 48.05 0 2.25-.45 4.55-.95z"
                    id="AccHat127__Layer16_5_FILL"
                />
                <g id="AccHat127__Layer16_0_FILL">
                    <path
                        fill="#298712"
                        d="M230.75 101.45q-.816-.22-1.65-.45-34.557-14.44-63.35-7.25-28.737 7.19-41.65 11.25-12.868 4.06-17.05 5.65v.05q-4.9 3.05-8.95 6.6-3 2.6-5.5 5.5-2.7 3.6-4.15 6.3-.1.4-.15.75-.35.65-.65 1.25-2.1 9.7 15.5 6.15 6.4-2.05 12.6-3.75 45.25-11.8 93.7-5.65 13.45 2 26.9 5.7l1.1.3q.65.15 1.3.35 1.15.3 2.2.55 6.05 1.2 10.7 0 4.3-1.35 7.55-4.25 4.95-4.35 6.05-9.25 1.1-4.85 1.1-8.5l-2.6-1.65q-.85-.3-1.65-.55-1.45-.5-4.1-1.2-2.645-.706-27.25-7.9m8.3 3.55q-.65-.05-1.25-.05-.912-.45-1.85-.9l3.1.95z"
                    />
                    <path
                        fill="#666"
                        d="M266.3 43.65q-.47.504-1.25.95h-70.4q-.428-.376-.8-.85-.1-.1-.15-.2-.051.443-.1.75-.597-.157-1.45-.2-1.86-.093-2.6.05.04-.202.05-.45-.05.5-.05.1-.2.25-.35.45-.185.185-.4.35h-70.75q-.714-.468-1.15-1-.063.588-.1 1-.05.568-.05.8 0 2 10.65 3.4t25.75 1.4q15.1 0 25.75-1.4 4.644-.587 7.25-1.35.576.63 1.55 1-.892.388-1.8.95h-.95v6.4q.146-.052 1.35 5.7 1.263 5.756 4.3 11.4 3.037 5.694 4.55-5.6 1.504-11.248 3.1-11.65h.3V49.4h-.9q-1.8-1.172-3.65-1.6.164-.1.3-.2.568-.467.75-.95.728.341 1.8.65.15.05.35.1 2.55.7 7 1.25 10.65 1.4 25.75 1.4t25.75-1.4q10.65-1.4 10.65-3.4 0-.212-.05-.65.023-.387 0-.95z"
                    />
                </g>
                <g id="AccHat127__Layer16_2_FILL">
                    <path
                        fill="#001AFF"
                        d="M185.2 55.75q-.1 0-.25.05-11.45.85-23.2 4.4-4 1.55-7.7 3.2-37.35 16.7-46.85 46.8-.1.2-.15.45 2.15-.65 3.6-.9 17.6-3.9 34.6-6.15.636-.45 23.25-9 22.61-8.5 15.55-22.35-7.004-13.8 2.75-15.9.2-.05.45-.1-1.05-.2-2.05-.5z"
                    />
                    <path
                        fill="red"
                        d="M196.55 56.7q8.7 2.2-1.85 14.05-10.55 11.85 16.8 22.65 27.35 10.85 27.55 11.6.65.2 1.35.4 2.8.75 5 1.1 2.2.3 6.1 1.25 3.85.9 6.5 1.6 2.65.7 4.1 1.2.8.25 1.65.55l2.6 1.65 4.5 1q10.85 3.05 8.9-.5-10.9-27.5-34.8-42.65-6.95-4.2-14-7.2-15.85-6.75-32.4-7.75h-.3q-1.6.4-3.1.7.7.15 1.4.35z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccHat127__Layer16_0_FILL" />
            <use xlinkHref="#AccHat127__Layer16_1_FILL" />
            <use xlinkHref="#AccHat127__Layer16_2_FILL" />
            <use xlinkHref="#AccHat127__Layer16_2_MEMBER_0_FILL" />
            <use xlinkHref="#AccHat127__Layer16_3_FILL" />
            <use xlinkHref="#AccHat127__Layer16_4_FILL" />
            <use xlinkHref="#AccHat127__Layer16_5_FILL" />
        </g></g>
    )
}

export default AccHat127
