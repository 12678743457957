import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF0000",
    "col1n": "#C40000"
}

function LegSki006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.45 11.6l-.2-.3L134 0q-.1 0-.15.05-1.6.3-3.15.6-2.05.35-4 .7-2.45.4-4.85.8-1.15.2-2.25.35-4.25.65-8.45 1.2-4.35.5-8.6.95-2.75.25-5.45.45-1.25.1-2.45.2-2.75.2-5.45.4-.6 0-1.2.05-.7 0-1.35.05-.25 0-.55.05l-5.65.2-3.05.1h-.55q-1.45 0-2.95.05h-3.15q-2.4 0-4.8-.05h-.6q-.95 0-1.9-.05-1.6-.05-3.15-.05-1.85-.1-3.7-.15-.25-.05-.5-.05-2.3-.1-4.65-.25-.7-.05-1.35-.1-.6-.05-1.2-.05l-.4-.05h-.4q-3.95-.3-7.85-.7-4.35-.4-8.65-.95-3.8-.5-7.6-1.05-.45-.1-.85-.15Q19.6 2 16.05 1.4q-.3-.05-.55-.1Q12.4.75 9.35.15q-.25-.1-.5-.1-.1.05-.15.15V0q-.05.05-.05.1L0 11.6l5.25.35q1.1.1 2.25.15l5.9.4q2.15.15 4.35.3 2 .1 4.1.2 3.3.2 6.65.35l2.1.1q.75.05 1.45.05l4 .2 3.5.1q4 .15 8 .25h.05l3.85.1 4.65.1h.5q1.1 0 2.2.05 2.35 0 4.65.05h2.5q2.5 0 5 .05h6.45q1.55-.05 3.05-.05 2.85-.05 5.65-.05h.55q1.05-.05 2.15-.05h.4q3-.1 6-.15l3.85-.1q2.1-.05 4.2-.15 3.6-.1 7.2-.25.85-.05 1.75-.1.6-.05 1.25-.05 1.5-.05 3.05-.15 2.25-.1 4.45-.2 3-.2 6-.35 1.2-.1 2.45-.15 4.05-.25 8.15-.55.85-.05 2.35-.2 1.15-.1 3.55-.25z"
                    id="LegSki006__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.75 5.4L0 7.7q3.6.35 7.25.7L9 6.05Q5.35 5.7 1.75 5.4m9.15-2.15h.15l1.5-1.95Q9.45.75 6.4.15q-.25-.1-.5-.1-.1.05-.15.15V0Q5.7.05 5.7.1L3.8 2.6q1.7.15 3.45.3 1.85.15 3.65.35m19.7 1.4q1.15.05 2.3.15h.1l1.4.1q1.05.05 2.15.1h.1q4.085.23 8.15.35-3.763-.267-7.5-.65-4.35-.4-8.65-.95-3.8-.5-7.6-1.05l-.8 1.3q3.45.2 6.9.45 1.25.05 2.5.15h.1q.4 0 .85.05m14.95 6.25l.75-2.45-10.9-.5-3.7-.2q-1.7-.1-3.35-.2l-9.9-.7-1.5 2.4 10.2.7q1.7.1 3.45.2 1.9.1 3.8.25 5.6.3 11.15.5m40 .35l-.25-2.5q-5.5.1-11 .15h-3.35q-1.55-.05-3.1 0-5.5-.05-11-.15l-.45 2.5q5.75.1 11.5.15H74.35q5.6-.05 11.2-.15M112.5 4.6q3.65-.2 7.35-.5l-.95-1.95q-1.15.2-2.25.35-4.25.65-8.45 1.2-4.35.5-8.6.95-2.75.25-5.45.45l.05.4h.05q.55-.05 1.15-.05 4.6-.15 9.2-.4h.05l1.8-.1q.6-.05 1.25-.05h.05l1.7-.1q.65-.05 1.3-.05h.05q.85-.1 1.7-.15m8.75 2.4q-4.8.35-9.55.65-1.55.1-3.05.2-1.55.05-3.05.15-5.35.3-10.75.5l.55 2.45q5.55-.2 11.05-.5 1.5-.1 3-.15 1.55-.1 3.05-.2 4.95-.3 9.9-.7L121.25 7M130.9.05q-1.6.3-3.15.6l2.2 2.65h.1q1-.1 3.4-.35L131.05 0q-.1 0-.15.05M137.6 8l-1.85-2.25q-2.3.2-3.55.3l1.9 2.35q1.35-.15 3.5-.4z"
                    id="LegSki006__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill="#770707"
                    d="M246.5 314.2q-1.25.1-2.45.2h-.1l1.4 2.9q5.45-.45 10.95-.95l-2.25-2.75q-3.75.3-7.55.6m11.7 4.5q-5.85.55-11.7 1l1.55 3.35q1.246-.075 2.45-.15 4.099-.253 8.15-.55.878-.057 2.4-.2l-2.85-3.45m-77.7 2.85q-5.45-.15-10.85-.35l-1 3.15h.05q1.963.052 3.9.1 2.325.053 4.65.1.25.004.5 0 1.105.02 2.2 0l.55-3m-2.75-5.4q-.25.012-.5 0-2.325-.11-4.65-.25-.684-.043-1.4-.1l-.8 2.95 10.55.3.45-2.75q-1.82-.042-3.65-.15m20.7 5.55H192l.05 2.95q1.513.032 3 0 1.476.032 2.95 0 .225.026.45 0v-2.95m-6.55-5.2l.05 2.7q1.55-.05 3.1 0h3.35v-2.8q-.2.03-.4 0-1.475.056-2.95.05-1.575.045-3.15.05m27.6 4.75q-4.9.2-9.85.3l.3 2.95q.195.03.4 0 2.988-.027 5.95-.1 1.947-.049 3.85-.1l-.65-3.05m-1.3-5.85q-1.205.11-2.45.2-2.689.207-5.4.35-.612.055-1.25.05l.3 3.05q4.8-.1 9.55-.25l-.75-3.4m15.35-.3l-1.7.1h-.05l.95 2.95q1.5-.1 3.05-.2l-.95-2.9q-.65 0-1.3.05m3.05 5.3q-1.5.1-3.05.2l1 3.15q1.568-.074 3.1-.15l-1.05-3.2m-95.55-.85q-4.85-.4-9.7-.85l-2.75 3.7q2.977.213 5.9.4 2.194.138 4.35.25l2.2-3.5m19.7-4.25h-.1q-1.1-.05-2.15-.1l-1.4-.1-1.3 2.95 3.7.2 1.25-2.95m-2.25 5.4q-1.9-.15-3.8-.25l-1.5 3.35q1.995.096 3.95.15l1.35-3.25m-15.95-3.55l1.8-2.85h-.1q-1.6-.15-3.2-.25l-5.9-.5-2.05 2.8q4.75.4 9.45.8z"
                    id="LegSki006__Layer5_0_FILL"
                />
                <g id="LegSki006__Layer5_1_FILL">
                    <path
                        fillOpacity={0.251}
                        d="M246.5 314.2q-1.25.1-2.45.2h-.1l1.4 2.9q5.45-.45 10.95-.95l-2.25-2.75q-3.75.3-7.55.6m11.7 4.5q-5.85.55-11.7 1l1.55 3.35q1.246-.075 2.45-.15 4.099-.253 8.15-.55.878-.057 2.4-.2l-2.85-3.45m-77.7 2.85q-5.45-.15-10.85-.35l-1 3.15h.05q1.963.052 3.9.1 2.325.053 4.65.1.25.004.5 0 1.105.02 2.2 0l.55-3m-2.75-5.4q-.25.012-.5 0-2.325-.11-4.65-.25-.684-.043-1.4-.1l-.8 2.95 10.55.3.45-2.75q-1.82-.042-3.65-.15m20.7 5.55h-3.4v2.95q1.476.032 2.95 0 .225.026.45 0v-2.95m-.45-5.3q-1.475.056-2.95.05v2.75h3.35v-2.8q-.2.03-.4 0m21.5 4.85q-4.9.2-9.85.3l.3 2.95q.195.03.4 0 2.988-.027 5.95-.1 1.947-.049 3.85-.1l-.65-3.05m-1.3-5.85q-1.205.11-2.45.2-2.689.207-5.4.35-.612.055-1.25.05l.3 3.05q4.8-.1 9.55-.25l-.75-3.4m18.4 5q-1.5.1-3.05.2l1 3.15q1.568-.074 3.1-.15l-1.05-3.2m-4.8-5.2l.95 2.95q1.5-.1 3.05-.2l-.95-2.9q-.65 0-1.3.05l-1.7.1h-.05m-90.75 4.35q-4.85-.4-9.7-.85l-2.75 3.7q2.977.213 5.9.4 2.194.138 4.35.25l2.2-3.5m17.45 1.15q-1.9-.15-3.8-.25l-1.5 3.35q1.995.096 3.95.15l1.35-3.25m2.15-5.4q-1.1-.05-2.15-.1l-1.4-.1-1.3 2.95 3.7.2 1.25-2.95h-.1m-16.3-1h-.1q-1.6-.15-3.2-.25l-5.9-.5-2.05 2.8q4.75.4 9.45.8l1.8-2.85z"
                    />
                    <path
                        fill="#FFF"
                        fillOpacity={0.749}
                        d="M195.05 321.7H192l.05 2.95q1.513.032 3 0v-2.95m-3.15-5.2l.05 2.7q1.55-.05 3.1 0v-2.75q-1.575.045-3.15.05m41.65 4.1q-1.5.05-3 .15l1.05 3.1q.859-.01 1.7-.05l1.25-.05-1-3.15m-1.75-5.4q-.65 0-1.25.05l-1.8.1h-.05l1 2.95q1.5-.1 3.05-.15l-.95-2.95m-77.1 5.25q-1.75-.1-3.45-.2l-1.65 3.4 2.15.1q.73.031 1.45.05l1.5-3.35m2.4-5.35h-.1q-1.15-.1-2.3-.15-.45-.05-.85-.05l-1.4 2.95q1.65.1 3.35.2l1.3-2.95z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#LegSki006__Symbol_72_0_Layer0_0_FILL"
                transform="translate(121.15 310.3)"
                id="LegSki006__col1n"
            />
            <use
                xlinkHref="#LegSki006__Symbol_38_0_Layer0_0_FILL"
                transform="translate(124.1 310.3)"
                id="LegSki006__col1d"
            />
            <use xlinkHref="#LegSki006__Layer5_0_FILL" />
            <use xlinkHref="#LegSki006__Layer5_1_FILL" />
        </g></g>
    )
}

export default LegSki006
