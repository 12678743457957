import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#6486A6"
}

function ShiMot012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.1 8.1q.15-.05.25-.1V5.9q-1.95 1-3.35 1-1.7 0-3.5-1.05-1.75-1.05-3.55-1.05-2.75 0-6.45 2.05-1.1.35-1.85.6-.45.15-.8.25-.2.05-.4.15V5.1q.45-.05.9-.15.05-.05.15-.05l.1-.05q.7-.25 1.75-.65.55-.25 1.15-.5 3.95-1.65 5.15-1.65 1.1 0 2.75 1.05 1.7 1 4.25 1 1.45 0 3.15-.8.15-.05.25-.1V1.1q-1.95 1-3.35 1-1.7 0-3.5-1.05Q23.45 0 21.65 0 18.9 0 15.2 2.05q-.25.1-1.2.5-.65.25-1.3.45l-2.15.45V29.5q-1.8-1.75-4.3-1.7-2.6-.05-4.45 1.8T0 34.05q-.05 2.55 1.8 4.4 1.85 1.85 4.45 1.85 2.55 0 4.4-1.85 1.15-1.15 1.6-2.6.15-.55.2-1.15V10.1q.3-.05 1.2-.35l2-.75q.55-.25 1.15-.5 3.95-1.65 5.15-1.65 1.1 0 2.75 1.05 1.7 1 4.25 1 1.45 0 3.15-.8z"
                    id="ShiMot012__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot012__Symbol_18_0_Layer0_0_FILL"
                transform="translate(176.45 260.95)"
                id="ShiMot012__col1n"
            />
        </g></g>
    )
}

export default ShiMot012
