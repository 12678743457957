import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#7B5D30",
    "col1n": "#A37831",
    "col2d": "#43341A",
    "col2n": "#644D27"
}

function AccHat034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M269.6 68q3.45-2.5 0-5.7-.7-.4-1.75-.5-2.15-.2-4.35-.35-.75-.1-1.5-.1-3 .6-5 .7-.6.05-1.1.05-5.2 0-11.25-2.1-.45-.15-4.05-1.7l-.7-.3q-5.25-1.45-10.4-3.05l-.1.1q-.65-.3-1.25-.55-5.8-2.5-12.55-6.1-.2-.15-.35-.2h.35l-1.8-19.65h-.2l-1.6-11-4.35-5.4q-2.4-1.85-5.3-2.35-.05-.05-.05-.1l-.8-.2q-9.4-2.25-18.9-4.25-5.65-1.2-11.3-2.3-5.65-1.15-11.25-1.9Q154.3.3 148.6.15q-16.75-.2-33.4-.15v.05q-2.8.3-5.45.85-7.25 1.4-14.6 2.3-2.35.25-4.65.65-5.45 1-10.65 2.85-1.6.55-2.7 1.7-3.45 3.5-6.6 7-.1.1-.25.15-1.85 2.55-4.55 3.55-2.25.35-3.65.6-.2.05-.35.1-1.85.35-2.2.55-1 .5-1.95 1-17.15 7.25-21.9 21.6l-3.35.7L37.2 65.8q-.25.1-.5.15l-8 2.5q-2.8.9-5.6 1.6-1.25.3-2.45.55-6.2 1.45-12.5 2.45-.65.1-1.3.25-1.95.35-3.85.8-.45.1-.9.25Q0 76 0 76.9q0 6.45 7.6 7.95.85.2 2.05.4 7.2-.45 11.4-1.45 5.15-1.2 9.3-2.35l1.5-.5q1.95-1.2 3.95-2.35 1.35-.85 2.9-.7 3.2.15 6.4.2l6.6.2q.3-.05.65 0 5.9 0 11.75-.25 5.3-.3 10.6-.75 4.85-.45 9.7-1.05.1-.05.25-.05l1.4-.2q.1-.05.2-.05 1.25-.15 18.2-5.7h2.05q.4-.15.8-.15 1.25-.1 3.6.1t8.7.25q6.35 0 10.75-.85 4.4-.8 7.9-1.3t9.7-1.05q8.1-.8 16.2-1.9l3-.1q.35 0 .7-.05 2.35-.05 4.75-.05 1.25-.05 2.55-.05 11.45-.15 23 .3 2.65.1 5.3.25 1.4.05 2.8.15 3.8.25 7.6.55.25 0 .5.05.85.1 1.75.25 6 .85 12.3 2.4 2.85.7 5.2 1.25 12.55 2.05 22.95 0 2.65-.3 6.05-.7l.9-.1q4.2-.55 6.1-1.5z"
                    id="AccHat034__Symbol_252_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M184.35 20.85l-1.7-20.7q-8.8-.45-23.5.3l-19.9.9Q99.5 2.65 59.9 6.4 45.25 7.8 30.6 9.15q-1.05.1-2.05.2L0 15.5l4.85 22.15Q6.45 37.1 8 36.3q.25-.15.55-.3.2-.15.45-.25 37.1-6.45 76.45-8.9 17.05-1.55 34.1-3.05 13.45-1.15 26.9-2.05 19.55-1.6 37.9-.9z"
                    id="AccHat034__Symbol_75_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M9 35.8q8.95-1.55 18.1-2.85L24.35 10.3 0 15.55 4.85 37.7q1.6-.55 3.15-1.35.25-.15.55-.3.2-.15.45-.25M169.05.15l1.55 20.55q6.95-.05 13.75.2L182.65.2q-5.4-.3-12.95-.1-.35 0-.65.05z"
                    id="AccHat034__Symbol_19_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat034__Symbol_163_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M0 66.2q2.9-.6 5.8-1.1l-.2-.5q-2.8.9-5.6 1.6M159.5 1.4q.7.6 1.45 1.2l.2-.85q-.85-.2-1.65-.35m58 53.05l-.7-.3q-5.1-1.4-10.1-2.95l-.3-.1-.1.1q-.65-.3-1.25-.55l-.2.9q.65.1 1.35.25.15 0 .3.05.95.15 10.2 2.4.35.1.8.2m-38.25-48.5q-.05-.05-.05-.1l-.8-.2.85.3z" />
                    <path d="M67.4 0q-5.45 1-10.65 2.85-1.6.55-2.7 1.7-3.45 3.5-6.6 7-.1.1-.25.15-1.85 2.55-4.55 3.55-2.25.35-3.65.6-.2.05-.35.1-1.85.35-2.2.55-1 .5-1.95 1-17.15 7.25-21.9 21.6l-3.35.7 1.95 8.9q6.35-.8 12.9-1.45 7.45-.75 14.2-1.5v-.9q0-3-1.2-6.3-1.2-3.35-1.2-6 0-6 2.7-8.9 4.35-4.75 16.9-5.8 2.8-.25 3.5-3.1 1.4-5.9 1.9-6.9 2.25-4.7 6.5-7.85m109.25 36.35q-.55-1.3-.9-3.15-18.05-1.15-23.75-1.15-11.3 0-27.65 1.35-15.65 1.3-24.85 2.85-24.3 4.3-34.7 5.95-11.4 1.8-26.5 3.55-.1 7.4-6.6 7.55-6.55.1-17.85 7.55l.25 1.1q-.25.1-.5.15l-8 2.5.2.5q28.25-5.05 51.3-6.65 6.5-.45 32.05-2.1Q114.65 54.7 126 56q11.35 1.3 29.6-2.65 18.2-4 21.9-3.7 10.75 0 15.7.35 5.45.35 11.65 1.55l.2-.9q-5.8-2.5-12.55-6.1-.2-.15-.35-.2h.35v-.2q-.25.05-.3.05-.15.05-2.3.05-10.05 0-13.25-7.9M190.7 24.7h-.2l-1.6-11-4.35-5.4q-2.4-1.85-5.3-2.35l-.85-.3q-8.6-2.05-17.25-3.9l-.2.85q3.7 2.9 8.35 5.65 2.35 2.05 3.8 5.15 1.55 3.35 1.6 7.25.1 8 1.05 12.55 2.8.15 5.9.35 5.6.35 9.95.65l-.9-9.5z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat034__Symbol_252_0_Layer0_0_FILL"
                transform="translate(61 49.7)"
                id="AccHat034__col1n"
            />
            <use
                xlinkHref="#AccHat034__Symbol_163_0_Layer0_0_FILL"
                transform="translate(84.1 53.55)"
                id="AccHat034__col1d"
            />
            <use
                xlinkHref="#AccHat034__Symbol_75_0_Layer0_0_FILL"
                transform="translate(93.35 77.85)"
                id="AccHat034__col2n"
            />
            <use
                xlinkHref="#AccHat034__Symbol_19_0_Layer0_0_FILL"
                transform="translate(93.35 77.8)"
                id="AccHat034__col2d"
            />
        </g></g>
    )
}

export default AccHat034
