import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#DE5500",
    "col1l": "#FF9D43",
    "col1n": "#FF7B00",
    "col2l": "#FFFFA6",
    "col2n": "#FFFF00"
}
function AccJew056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M5.4 3.7l.05-.05L5 2.05v-.1Q4.5.4 2.9.9l-1.6.45Q-.3 1.8.1 3.4l.5 1.65q.4 1.6 1.9 1.2l1.75-.5q1.1-.25 1.25-1.1.05-.3 0-.7-.05-.1-.1-.25m9.85 0l.05-.25.15-1.5q.1-1.65-1.5-1.8L12.1 0q-1.55-.15-1.8 1.45l-.15 1.7v.1q-.1.25 0 .5.1 1.1 1.5 1.3l1.75.1q1.35.15 1.7-.8.15-.3.15-.65m9.35-.4L23 2.65q-1.55-.7-2.2.8l-.7 1.6q0 .05-.05.1v.05l-.1.2q-.4 1.25 1 1.85l1.6.7q1.5.7 2.2-.8l.65-1.6q.7-1.55-.8-2.25z"
                    id="AccJew056__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.75.85L3.15.2Q1.6-.5.95 1l-.7 1.6q0 .05-.05.1v.05l-.1.2q-.4 1.25 1 1.85l1.6.7q1.5.7 2.2-.8l.65-1.6q.7-1.55-.8-2.25z"
                    id="AccJew056__Symbol_143_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M5.4 2.9l.05-.05L5 1.25v-.1Q4.5-.4 2.9.1L1.3.55Q-.3 1 .1 2.6l.5 1.65q.4 1.6 1.9 1.2l1.75-.5q1.1-.25 1.25-1.1.05-.3 0-.7-.05-.1-.1-.25z"
                    id="AccJew056__Symbol_144_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.2 3.35L5.1 1.5Q4.95-.1 3.3 0L1.5.15Q-.1.35 0 1.9l.05.15v.1l.45 1.6-.05.05q.05.15.1.25.05.4 0 .7.45.5 1.35.45l1.8-.15q1.3-.1 1.5-1.2-.1-.25 0-.5m10 1.7l.45-1.55q.45-1.6-1.15-2.15L12.8.9Q11.2.4 10.75 2l-.45 1.8q0 .35-.15.65.1.85 1.15 1.2l1.7.55q1.4.4 2-.7l.1-.2v-.05q.05-.05.05-.1l.05-.1z"
                    id="AccJew056__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M4.35.55L2.65.1Q1.05-.4.6 1.2L.15 3q0 .35-.15.65.1.85 1.15 1.2l1.7.55q1.4.4 2-.7l.1-.2v-.05Q5 4.4 5 4.35l.05-.1.45-1.55Q5.95 1.1 4.35.55z"
                    id="AccJew056__Symbol_142_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew056__Symbol_140_0_Layer0_0_FILL"
                transform="translate(109.25 274.55)"
                id="AccJew056__col1n"
            />
            <use
                xlinkHref="#AccJew056__Symbol_143_0_Layer0_0_FILL"
                transform="translate(129.1 277)"
                id="AccJew056__col1d"
            />
            <use
                xlinkHref="#AccJew056__Symbol_144_0_Layer0_0_FILL"
                transform="translate(109.25 275.35)"
                id="AccJew056__col1l"
            />
            <use
                xlinkHref="#AccJew056__Symbol_141_0_Layer0_0_FILL"
                transform="translate(114.2 274.45)"
                id="AccJew056__col2n"
            />
            <use
                xlinkHref="#AccJew056__Symbol_142_0_Layer0_0_FILL"
                transform="translate(124.35 275.25)"
                id="AccJew056__col2l"
            />
        </g></g>
    )
}
export default AccJew056
