import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#1E1E1E",
    "col1n": "#434343"
}

function BeaMus024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M61.4 6.95q.149-.05.3-.05-.05-.001-.05-.05Q59.2 4.2 50.15 1.3 44.5-.8 39.8 1.3q-1.85.5-3.9-.4-.55-.15-.95-.25Q30.65-.6 25.4.6q-.2 0-.35.05-.35.05-.7.15-6.5.6-10.65 4.25-.15.1-.25.2l-.7.7q-.05.05-.1.05v.05H13q.45-.05.85-.05h1.3q-1.85.7-3.5 1.6-2.55 1.05-4.4 2.65-2.4 1.9-3.95 4.55l-.35.75q-.3.7-.3 1.2 1.2-.75 2.4-.4-3.8 3.4-4.65 6.7-.45 1.4-.4 2.8v1.3q.15-.2.3-.35v.05q.001-.05.05-.05.7-.85 2.2-1.35v.05l-.15.6q-.15.6-.5 1.65-.5 1.65-.8 3.65-.55 4.2.55 7.2 1.1-3.35 3.95-4.55.3 2.75 3.95 5.5.1.05.2.2-.05-.4-.1-.7.25-4.4 1.7-8.4.45.45 1 1.1l.1-.5q.15-.7.2-1.65.45-4.05.65-6.95l.1-.8q0-.65.1-1.1v-.15q.2.2.35.45.8.85 1.35 1h.1q0 .05.05.1l.05-.1q.25-3.45 3.6-6.95 1.5-1.5 2.15-2.35 1.05-1.15 1.45-2 .35.35.5 1.5.15 1.25.2 1.95V14.9q.85-.5 1.05-.65 1.45-.65 3.05-1.55 1.5-.95 1.6-1 .1-.05.55-.35.25-.15.5-.35l.1-.05q.4-.4 1.1-.85l2.65 2.1q2.05-.3 5.25-2.95Q43 12.3 46.2 12.7l.55.05v-.05l1-3q3.15 5.15 7.85 8.95.25.2.5.5h.05q-.3-1.35.3-2.6 0-.1.05-.15.25.25.5.6 1.5 2 2.25 5.5.65 3.3.65 8.4l-.2 4.95.15-.15q.75-.7 2.25-2.5.05-.1.15-.15 1.6 1.75 2.05 4.35.25 1.4.45 2.4l.2.85q.05.2.1.45.05.15.05.25 2.6-5.15 2.85-8.1 1.3 1.2 2.3 3.7l.1-.25q0-.15.05-.3l.2-.9q1.35-4.4 0-8.75-.05-.35-.15-.6-.35-1.05-.6-1.7 1.15.15 2.7 1.6-.05-.1-.05-.2-.3-2.6-2-6.7-.2-.45-.35-.85l.15.45q-.8-1.9-2.55-4.1h.05q1.05-.25 2.3 0 .65.15 1.45.45-4.5-5.1-8.2-6.5l-3.6-1.25h.1q.8-.2 1.55-.4M22.7 10.6v.1l-.05.05q0-.05.05-.15z"
                    id="BeaMus024__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.65 27.05q.05-.25.25-.75-2.1.7-3.4 3.25-1.35 2.55-1.25 3.75.1 1.2.1 1.25Q2.45 31.2 5.3 30l-.1-.2q.1-1.6.45-2.75m5.4-.45q-.05-.15-.15-.2 0-.25-.65-.85-.4-.35-.85-.4-.55-.1-.6.35-.05.5 0 1.85.05 1.3.35 5.3-.1.1.2 2.35.25-4.4 1.7-8.4m.45-11.4v1.2q-.35 1.8-.1 4.9.2 3.05.75 5.9.15-.7.2-1.65.45-4.05.65-6.95l.1-.8q0-.65.1-1.1-.95-.95-1.7-1.5M0 22.8q.001-.05.05-.05.7-.85 2.2-1.35v.05l-.15.6q.1-.3.25-.7.6-1.6 2.65-5.75-3.15 2.85-3.7 3.8-.55.95-.8 1.8-.35.75-.5 1.55v.05M17.2.15Q15.75.5 15.15.8q-.65.2-2.7 1.1-.05.05-.1.05V2h.35q.45-.05.85-.05h1.3q2.6-1 5.4-1.7Q18.7-.2 17.2.15m-6.75 7.8q.05-.1.15-.25-3 1.2-4.55 1.95-1.6.7-2.45 1.6-.9.95-1.05 1.15-.2.15-.2.3 1.2-.75 2.4-.4Q6.9 10.4 9.9 8.4q.3-.25.55-.45M24.95 5l-.2-2.9-.7 1.55q-.9 1.25-1.3 2.15-.45.85-.35.75v.1l-.05.05h-.05q.35.35.5 1.5.15 1.25.2 1.95V10.85l.2-.45q1.5-2.5 1.75-5.4m8.65 3.15q2.05-.3 5.25-2.95l-.65-.55q.5.3.1-.05-.55-.5-2.2-1.75-.3 3.7-2.5 5.3m30.25 18.2Q62.7 27.9 61.95 29q1.6 1.75 2.05 4.35.25 1.4.45 2.4l.2.85q.05.2.1.45.6-2.6.75-4.55.15-1.95-.4-3.5-.65-1.55-1.25-2.65m3.85-1.05v.5q.05.85 0 2.65 0 .35-.05.75 1.3 1.2 2.3 3.7l.1-.25q0-.15.05-.3.3-2.8-.9-5-.2-.4-1-1.45l-.5-.6m-11-12.35q1.5 2 2.25 5.5.65 3.3.65 8.4l-.2 4.95.15-.15q.55-1.35 1-3.5.55-2.75.55-5.5 0-3.9-2.5-7.55-.75-1.1-1.9-2.15m-9.25-7.3q-1.15-1.35-2.1-3.7-.75 1.35-.6 3.6.2 2.55 1.7 3.1l1-3m6.95 4.2q-.15.55-.15 1.05-.05.85.2 1.65.35 1.45.85 2.05.25.2.5.5h.05q-.3-1.35.3-2.6 0-.1.05-.15v-.05q-.05-.15-.3-.45-.4-.5-.9-1.25-.5-.7-.6-1.2v.45m5.1-6.55h.05q.8-.2 1.55-.4-2.7-1.4-4.25-1.45h-2.1v.15q2.4 1.2 4.75 1.7m10 17.05l.05.05q1.15.15 2.7 1.6-.05-.1-.05-.2-1.4-3.15-4.3-6l1.6 4.55M63.9 7.9l.3.1q-.1-.05-.15-.1h-.15m.3.1q.15.05.4.2.6.45 1.65 1.4.65.7 1.25 1 1.05-.25 2.3 0-.1-.05-.15-.1-1.95-1.4-4.75-2.3-.35-.15-.7-.2z"
                    id="BeaMus024__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus024__Symbol_41_0_Layer0_0_FILL"
                transform="translate(155.6 199.15)"
                id="BeaMus024__col1n"
            />
            <use
                xlinkHref="#BeaMus024__Symbol_11_0_Layer0_0_FILL"
                transform="translate(155.9 203.2)"
                id="BeaMus024__col1d"
            />
        </g></g>
    )
}

export default BeaMus024
