import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M187 47.3q-2.4-4.15-5.3-8.05-3.1-4.35-6.75-8.35l-4.55-4.6q-.2-.15-.35-.3l-.15-.15-.05-.05q-7.05-6.45-14.9-11.2Q131.55.05 101.65 0h-.4Q71.35.05 48 14.6 40.3 19.55 33.25 26q-6.5 5.95-11.45 12.5-3.3 4.25-5.9 8.8-1.55 2.4-2.9 4.9-4.5 8.65-7.45 20.7-1.35 5.55-2.4 11.85-1.75 15.5-2.4 30.3-.3 4.85-.5 9.75-.5 13.55.05 27.1.05 1.35.1 2.8.3 7.65.9 15.15h38.55q-3.45-3.3-6.5-6.6-1.35-1.4-2.65-2.85-31.35-37.8-20.15-87.5h35v-4.1q.85-12.9 5.7-25.8 1.65-4.45 3.85-8.95-.6 2.25-1.1 4.6-1.35 6.15-1.9 12.65-.85 10.35.3 21.6h103.2q-1.3-11.05-4.75-21.5-4.65-13.9-12.95-26.85 6.1 6.7 11.05 14.15 1.4 2.1 2.7 4.25 8.25 13.8 12.45 29.95h28.95q9 51.2-18.7 85.9-.25.2-.45.5-2.3 2.55-4.75 5-1.6 1.6-3.2 3.1-1.4 1.25-2.75 2.45h38.4q.7-8.4 1-17.05l.1-2.8q.4-12.6-.05-25.25-.2-4.9-.5-9.75-.7-14.8-2.4-30.25-1.05-6.3-2.4-11.85-2.95-12.05-7.45-20.7-1.35-2.5-2.9-4.9M13.2 134.7l-.1-.2v-.05q.05.1.1.25z"
                    id="HaiMed008__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.25 124.1q.4 10 1.4 20-.15.75-.15 1.2h10.25v-18.15q2.5 6.4 5.95 7.8v10.35h16.2q-3.45-3.3-6.5-6.6-1.35-1.4-2.65-2.85-21.55-25.95-23-57.5-2.35 22.45-1.5 44.9v.85m7-13.95l-.1-.2v-.05q.05.1.1.25m22-91.7Q17.05 33.65 15 48.35h7.1q-.65-14.7 7.15-29.9m16.05 0Q46.95 14 49.15 9.5q-.6 2.25-1.1 4.6-.1.4-.15.75L51 2.45Q34.8 19.3 31.45 48.35h8.15v-4.1q.85-12.9 5.7-25.8m57.45 29.9h7.55q-.95-9.55-4.8-19.15.95 9.6-2.75 19.15m35.4-31.25q4 11.55 4.75 31.25h8.5Q148.7 25.7 137.65 6.8 135 2.75 131.95 0l1.35 4.75q.75.8 4.85 12.35m-16.4 31.25h7.5q-1.45-16.45-8.75-38.4 2.75 23.45 1.25 38.4m62 96.95h6.15l.7-14.5.1-1.8q1.5-25.4-1.5-51.9-1.25 32.65-20.8 57.15-.25.2-.45.5-2.3 2.55-4.75 5-1.6 1.6-3.2 3.1-1.4 1.25-2.75 2.45h21.5v-14.1q3-1.75 5-7.5v21.6m3.35-96.95q-5.05-17.75-13.85-25.75 7.7 13.95 6.75 25.75h7.1z"
                    id="HaiMed008__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed008__Symbol_64_0_Layer0_0_FILL"
                transform="translate(91.05 57.25)"
                id="HaiMed008__col1n"
            />
            <use
                xlinkHref="#HaiMed008__Symbol_35_0_Layer0_0_FILL"
                transform="translate(97 81.8)"
                id="HaiMed008__col1d"
            />
        </g></g>
    )
}

export default HaiMed008
