import { AssetSvgProps } from "../../../shared/assets"

function AccJew012({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccJew012__Layer5_0_FILL">
                    <path
                        fill="#F9F906"
                        d="M243.15 247.05q0-.65-.15-1.2-1.95-.2-2.3-1.85-.25-.05-.5-.05-2.15 0-3.35 1.65-.85 1.1-.85 2.15 0 2.9 3.35 2.9 1.95 0 3-1.45.8-1.05.8-2.15M223.4 266.7q0-.55-.1-1-1.4-.4-1.7-1.85-.55-.15-1.15-.15-3 0-3 3.05 0 .4.05.7.45.55.6 1.4.75.8 2.35.8 1.4 0 2.25-1.05.7-.9.7-1.9m-26.45 10.2q.45.7.5 1.95 0 .55-.2 1.15.7 1.1 2.6 1.1 3.1 0 3.1-2.95v-.25q-.2-.5-.25-1.15-.6-1.6-2.85-1.6-2.35 0-2.9 1.75m-22.55-6.2v.3q0 1-.7 1.9-.35.4-.75.65.05 2.75 3 2.75 1.4 0 2.25-1l.1-.1q.1-.85.55-1.35.05-.25.05-.5-.1-2.95-2.95-2.95-.95 0-1.55.3m-19.05-12.3l-.55.55q-.15.5-.15 1.15 0 2.9 3 2.9 1.35 0 2.25-1.05l.1-.2q.6-.8.6-1.7-.1-3-2.95-3-1.05 0-1.7.4-.2.45-.6.95m-21.5-27.05q.3 0 .55.05-.1-2.9-2.95-2.9-3 0-3 3.05 0 2.55 2.4 2.9v-.05q0-3.05 3-3.05z"
                    />
                    <path
                        fill="#0BDDF4"
                        d="M245.65 240.6q-.95-.15-1.45-.65-.35-.05-.65-.05-3 0-3 3.05 0 .6.15 1.05.35 1.65 2.3 1.85h.55q1.35 0 2.2-1.05.75-.9.75-1.9-.05-1.6-.85-2.3m-37.7 37.75q.7-.9.7-1.9 0-.25-.05-.55-1-.6-1.1-1.95-.7-.5-1.8-.5-3 0-3 3.05v.25q.05.65.25 1.15.6 1.5 2.75 1.5 1.4 0 2.25-1.05m16.9-18.45h-.35q-3 0-3 3.05 0 .5.1.9.3 1.45 1.7 1.85.5.15 1.2.15 1.4 0 2.25-1.05.7-.9.7-1.9v-.15q-.15-2.65-2.6-2.85m-64.95 2.05q-.5.7-.5 1.95 0 2.9 3 2.9 1.3 0 2.15-.9.2-.8.7-1.3.1-.35.1-.75-.1-3-2.95-3-1.6 0-2.4.9l-.1.2m-19.15-17.35q-.4.65-.4 1.7 0 2.5 2.25 2.85.35.05.75.05 1.4 0 2.25-1.05.7-.9.7-1.9 0-.3-.05-.5-.3-2.5-2.9-2.5-.5 0-.95.1-.45.95-1.65 1.25z"
                    />
                    <path
                        fill="red"
                        d="M246.75 234.75q-.15-.05-.35-.05-3 0-3 3.05 0 1.5.8 2.2.5.5 1.45.65.3.05.75.05 1.35 0 2.2-1.05.75-.9.75-1.9-.05-.95-.35-1.6-.6-1.25-2.25-1.35m-34.3 36.2q-.75-.55-2-.55-3 0-3 3 0 .25.05.55.1 1.35 1.1 1.95.7.4 1.85.4 1.4 0 2.25-1 .7-.95.7-1.95-.05-.8-.25-1.4-.25-.6-.7-1m20.95-14.45q-.05-.9-.3-1.55-.4-.95-1.3-1.25-.6-.2-1.35-.2-3 0-3 3.05v.3q.15 2.6 3 2.6h.35q1.1-.15 1.9-1.05.7-.9.7-1.9m-35.95 22.35q-.05-1.25-.5-1.95-.7-1.05-2.45-1.05-1.4 0-2.1.65-.9.8-.9 2.4 0 .8.25 1.4.65 1.5 2.75 1.5 1.4 0 2.25-1.05.3-.4.5-.75.2-.6.2-1.15m-19.15-3.65q-.1.35-.1.85 0 2.9 3 2.9.65 0 1.25-.25.55-.3 1-.8.7-.9.7-1.9-.05-.8-.25-1.4-.6-1.6-2.7-1.6-1.55 0-2.35.85-.45.5-.55 1.35m-13.05-10.6q-.5.5-.7 1.3-.15.5-.15 1.1 0 .1.4 1.6.7 1.55 2.6 1.55.65 0 1.2-.15 1.25-.4 1.65-1.8.1-.55.1-1.15-.1-3.1-2.95-3.1-1.45 0-2.15.65m-9.3-7.15q.1-.5.1-.95-.05-3-2.95-3-.65 0-1.1.15-1.05.25-1.5 1.1-.4.75-.4 1.8 0 2.9 3 2.9.95 0 1.7-.5l.55-.55q.4-.5.6-.95m-16.3-18.7h-.1q-2.5.05-2.9 2.1-.1.45-.1.95 0 2.9 3.1 2.9.6 0 1.1-.1 1.2-.3 1.65-1.25.35-.65.35-1.6-.1-3-3.1-3m-2.85-4.4q-.1-2.65-2.4-2.95-.25-.05-.55-.05-3 0-3 3.05v.05q0 2.8 2.9 2.85h.1q1.4 0 2.25-1.05.5-.6.65-1.3.05-.3.05-.6z"
                    />
                    <path
                        fill="#80F906"
                        d="M251.7 235.05q.15-.15.3-.35.45-.75.45-1.55-.1-3-2.95-3-.3 0-.6.05-2.4.25-2.4 3 0 .85.25 1.55 1.65.1 2.25 1.35h.5q1.35 0 2.2-1.05m-39.5 34.55q0 .8.25 1.35.45.4.7 1 .75.55 2.05.55 1.4 0 2.25-1.05.7-.9.7-1.9 0-.35-.05-.7-.15-.85-.6-1.4-.7-.9-2.3-.9-3 0-3 3.05m20.9-14.65q.6.2 1.4.2 1.35 0 2.25-1.05.7-.9.7-1.9-.1-3-2.95-3-3 0-3 3.05 0 .85.3 1.45.9.3 1.3 1.25m-43.35 20.2q-1.25 0-1.95.5-1.05.75-1.05 2.55 0 .9.35 1.55.6 1.35 2.65 1.35 1.2 0 2-.8-.25-.6-.25-1.4 0-1.6.9-2.4-.7-1.35-2.65-1.35m-19.5-6.95q-.4 1.4-1.65 1.8-.15.45-.15 1.05 0 2.9 3 2.9.85 0 1.5-.4.4-.25.75-.65.7-.9.7-1.9v-.3q-.25-2.7-2.95-2.7-.65 0-1.2.2M152 253.65q.5-.85.5-1.7-.1-3-2.95-3-.6 0-1.1.15-1.75.45-1.9 2.6v.3q0 2.9 3 2.9.55 0 .95-.15.45-.85 1.5-1.1m-12.45-14.9q.1-.4.1-.85-.1-2.95-2.9-2.95-.15.7-.65 1.3-.85 1.05-2.25 1.05h-.1q-.05.35-.05.65 0 2.9 2.95 2.9.4-2.05 2.9-2.1z"
                    />
                    <path
                        fill="#7137C8"
                        d="M253.85 233.65q.7-.9.7-1.9-.05-3-2.95-3-2.05 0-2.7 1.45.3-.05.6-.05 2.85 0 2.95 3 0 .8-.45 1.55 1.15-.15 1.85-1.05m-26.4 23.2q-2.6.15-2.6 3v.05q2.45.2 2.6 2.85h.4q1.35 0 2.2-1.05.75-.9.75-1.9v-.35h-.35q-2.85 0-3-2.6m-42.2 17.55q-.75 0-1.35.2.2.6.25 1.4 0 1-.7 1.9-.45.5-1 .8.5 1.65 2.8 1.65 1.1 0 1.85-.6-.35-.65-.35-1.55 0-1.8 1.05-2.55-.7-1.25-2.55-1.25m-39-28.65q.05.2.05.5 0 1-.7 1.9-.85 1.05-2.25 1.05-.4 0-.75-.05.1.4.3 1.15.7 1.5 2.6 1.5.6 0 1.05-.1.15-2.15 1.9-2.6v-.4q-.1-2.65-2.2-2.95z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccJew012__Layer5_0_FILL" />
        </g></g>
    )
}

export default AccJew012
