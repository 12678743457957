import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000",
    "col2n": "#000000"
}

function ObjMor005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M16.9 49.45q-3.2-1.05-15.2-4.6l-1.6 6.5q-.3-.15.4 1.35t2.65 2.05L24 60.55q1.4.35 2.4-.45.95-.8 1.35-1.7-5.55-1.7-4.9-7.6-4.2-1.25-5.95-1.35m24-37q.55-1.5.55-3.85 0-2.4-3.35-3.5Q28.85 2.45 18.25.15q-1.75-.5-3.2.5-1.45.95-2.6 4.15l-.95 2.1 28.7 7.6.7-2.05z"
                    id="ObjMor005__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M18.65 55.3q.35-1.3-.3-2.45-.65-1.1-1.85-1.45l-.15-.05q-1.25-.3-2.4.3-1.15.7-1.5 2-.35 1.3.35 2.4.65 1.15 1.95 1.5 1.3.35 2.45-.3 1.1-.65 1.45-1.95M43.4 12.55q.6-2.3-.55-4.3-1.2-2.1-3.5-2.7L19.55.2q-2.3-.6-4.4.6Q13.1 2 12.5 4.3L.25 50.1q-.65 2.3.55 4.35Q2 56.5 4.3 57.1l19.8 5.35q2.3.6 4.4-.55 1-.65 1.7-1.5.1-.2.25-.4-.2-.05-.4-.15-.75-.15-1.4-.35-.45.5-1.05.85-1.45.85-3.05.45l-19.8-5.35q-1.6-.4-2.45-1.85-.85-1.45-.4-3.05l1-3.7q.4.1 1.15.3L23.7 52.4q.05-.5.2-1l2.35-14.2 8.7.35.8-.1q.55-.05 1.05-.05l6.6-24.85m-2.05-3.5q.85 1.45.4 3.05L41 15 14.65 7.9q-.75-.2-1.25-.3l.75-2.85q.45-1.6 1.9-2.45 1.45-.8 3.05-.4l19.8 5.35q1.6.4 2.45 1.8M31.8 8.7q-.2-.25-.55-.35L25.1 6.7q-.4-.1-.7 0-.3.15-.4.45-.05.3.15.55.15.3.55.4l6.15 1.65q.35.1.7-.05.35-.1.4-.4.1-.3-.15-.6z"
                    id="ObjMor005__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.3 38.2v-.4h21.75q.15-.35.3-.65.05-.2.15-.3.25-.65.5-1.05.65-1.15 1.2-2 .9-1.45 1.3-1.9 1.95-2 5.3-2.4l5.95-22.15L10.15 0 0 37.85l1.3.35z"
                    id="ObjMor005__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M257.45 273.55q-.05 0-.1.05-.2.05-.6.3l-.65.35-.65-1.4q-.05 0-.25.2-.2.3-.2.4-.05.05 0 .1-.2.2-.4.5-.1.15-.3.55v-.05q-.1-1.35-.15-2 0-.35-.3-.3l-.55.25q-.65.4-.9.6l-.15.1q0 .1.1.15.15-.1.8-.5.45-.3.75-.4.05.4.1 1.2.1.75.3 1.25.1-.1.2-.3.15-.2.2-.35 0-.1.2-.35l.25-.25V273.5q0-.1.1-.15.05-.15.15-.15.1.2.25.9.25.6.35.65.1 0 .1-.05v-.05q.25-.3.65-.55.25-.15.55-.3.1-.05.25-.05 0-.05-.05-.1l-.05-.1z"
                    id="ObjMor005__Layer4_3_MEMBER_0_FILL"
                />
                <g id="ObjMor005__Layer4_0_FILL">
                    <path
                        fill="#FFF"
                        d="M251.5 303.65l-3.25-.9-.9 3.3 3.25.9.9-3.3m-3.65 2.1l.65-2.55 2.5.7-.65 2.55-2.5-.7m10.45-36.1l.85-3.2-4.75-1.25-.85 3.2 4.75 1.25z"
                    />
                    <path
                        fill="#0CF"
                        d="M263.65 278.75l4.75 1.3 1.25-4.75-4.75-1.3-1.25 4.75m-15.6 10.9l4.75 1.25 1.3-4.7-4.75-1.25-1.3 4.7m9.7-17.55l-4.75-1.3-.6 2.3-.15.1q0 .1.1.15l-.6 2.2 4.75 1.3.8-3.05q.1-.05.25-.05 0-.05-.05-.1l-.05-.1q-.05 0-.1.05l.4-1.5m6.7-.8l1.25-4.75-4.8-1.25-1.25 4.75 2.6.65q-2.1-1-2-1.3.1-.4.4-.25.7.4.75.45.05 0 .15.1.2.1.25.1.15.05.35.1.35.05.85.05h.1q.306 0 .7.05.2.05.25.25v.15q-.05.15-.8.25-.6.15-.85.1l2.05.55m-1-3.4q.2.25.1.55-.05.3-.4.45-.25.15-.55.1-.3-.1-.45-.4-.1-.25-.05-.55.1-.3.35-.45.25-.15.55-.05.3.05.45.35z"
                    />
                    <path
                        fill="#9D9D9D"
                        d="M263 281.05l-1.25 4.75 4.75 1.3 1.25-4.75-4.75-1.3z"
                    />
                    <path
                        fill="#C4C4C4"
                        d="M260.35 295.75q.05-.2.15-.3l-20.55-5.5-1.8 6.75 19.8 5.3q.05-.3.3-.9.25-.85.85-2.35.5-1.25.9-2.2.15-.45.35-.8z"
                    />
                    <path
                        fill="#666"
                        d="M249.95 277.55l-4.75-1.25-1.25 4.75 4.75 1.25 1.25-4.75m-2.6-.1q.15-.3.2-.1.05.15 0 .3l-.25 1.25q0 .05-.1.1l.05.05q.1.1.05.3-.05.1-.15.2-.15.1-.3.05-.2-.05-.25-.2-.05-.1 0-.15.05-.25.2-.3-.2-.2-.45-.4-.55-.5-.5-.7.1-.3.35.05.15.15.35.4.05 0 .4.4.1 0 .1.05.15-.65.15-.75.1-.4.15-.55m7.25 6.45l.8-3q0-.05.05-.05l.05-.2h-.05l.4-1.5-4.75-1.3-1.25 4.75 4.75 1.3m5.2-7.65v-.05q.05-.05.05-.1-.25-.05-.3-.05-.05 0-.1.05v.05q-.05.2-.15.35-.1.2-.1.25-.05.15.05.45 0 .25.05.35v.1q.05-.05.1-.05l.35-1.3.05-.05z"
                    />
                    <path d="M247.55 277.35q-.05-.2-.2.1-.05.15-.15.55 0 .1-.15.75 0-.05-.1-.05-.35-.4-.4-.4-.2-.25-.35-.4-.25-.35-.35-.05-.05.2.5.7.25.2.45.4-.15.05-.2.3-.05.05 0 .15.05.15.25.2.15.05.3-.05.1-.1.15-.2.05-.2-.05-.3l-.05-.05q.1-.05.1-.1l.25-1.25q.05-.15 0-.3z" />
                    <path
                        fill="#C6F"
                        d="M248.1 284.6l-4.75-1.25-1.3 4.7 4.75 1.25 1.3-4.7z"
                    />
                    <path
                        fill="#FFD680"
                        d="M256.8 280.9l-.9 3.35 4.75 1.25.9-3.35-4.75-1.25z"
                    />
                    <path
                        fill="#9F6A1C"
                        d="M257.15 279.5l-.35 1.4 4.75 1.25.35-1.4-4.75-1.25m-5.3-9l-4.75-1.25-1.25 4.75 4.75 1.25 1.25-4.75m-3.9-.25l2.8.75-.65 2.5-2.8-.75.65-2.5z"
                    />
                    <path
                        fill="#FF0"
                        d="M259.45 276.15q-.15-.1-.3-.3l-.4 1.6.55.15v-.05q-.05-.1-.05-.35-.1-.3-.05-.45 0-.05.1-.25.1-.15.15-.35m1.1-3.3l-.55-.15-.3 1.15-.15.55-.15.5q.25-.05.45-.05.05 0 .15.05l.1-.35.15-.55.3-1.15z"
                    />
                    <path
                        fill="#F90"
                        d="M259.55 274.4l.15-.55-.95-.25-.15.55.95.25m3.65.4q-.05 0-.15.05.05.05.15.2-.05.1-.1.25v.05l.25.05.15-.55-.3-.05m-2.4-.55h.05l-.05-.05v-.05l-.55-.15-.15.55.55.15v-.25q.05-.15.15-.2z"
                    />
                    <path
                        fill="#CCC"
                        d="M260.65 274.7l-.55-.15-.1.35q.5.2.3.9-.05.2-.15.3-.15.15-.35.1v.05l-.05.05-.35 1.3 3.15.85.8-3.05-.25-.05q-.1.35-.25.5-.4.5-1.15.3-.75-.2-.95-.95-.1-.3-.1-.5m-1.6.55q.05-.2.3-.3 0-.05.05-.05l.15-.5-.95-.25-.8 3.05.95.25.4-1.6q0-.1-.05-.2-.1-.25-.05-.4m0-2.8l-.3 1.15.95.25.3-1.15-.95-.25m1.8 1.2q.1-.3.4-.2.3.1.2.4l.35.05.05-.05q.1-.35.35-.2.3.1.25.4l.25.05h.05q.1-.35.4-.25.3.1.25.4-.05.1-.15.15v.25q-.05.1-.05.15l.3.05.3-1.15-3.25-.85-.3 1.15.55.15q-.05-.1 0-.2v-.05q.05-.2.05-.25m5.95-6.8l-1.25 4.75 4.75 1.3 1.25-4.75-4.75-1.3z"
                    />
                    <path
                        fill="red"
                        d="M259.35 274.95q-.25.1-.3.3-.05.15.05.4.05.1.05.2.15.2.3.3v-.05q.05-.05.1-.05t.3.05q0 .05-.05.1.2.05.35-.1.1-.1.15-.3.2-.7-.3-.9-.1-.05-.15-.05-.2 0-.45.05-.05 0-.05.05m1.9-1.5q-.3-.1-.4.2 0 .05-.05.25v.05q-.05.1 0 .2v.05l.05.05q.05-.05.15 0 .05-.05.1-.05.3.05.9.2.5.15.8.3h.1q.1.05.15.15.1-.05.15-.05 0-.05.05-.15v-.25q.1-.05.15-.15.05-.3-.25-.4-.3-.1-.4.25h-.05l-.25-.05q.05-.3-.25-.4-.25-.15-.35.2l-.05.05-.35-.05q.1-.3-.2-.4m-6.45-9.8l-.4 1.55 4.75 1.25.4-1.55-4.75-1.25z"
                    />
                    <path
                        fill="#03F"
                        d="M260.85 274.25h-.05q-.1.05-.15.2v.25q0 .2.1.5.2.75.95.95.75.2 1.15-.3.15-.15.25-.5v-.05q.05-.15.1-.25-.1-.15-.15-.2-.05-.1-.15-.15h-.1q-.3-.15-.8-.3-.6-.15-.9-.2-.05 0-.1.05-.1-.05-.15 0z"
                    />
                    <path
                        fill="#8C9882"
                        d="M250.75 271l-2.8-.75-.65 2.5 2.8.75.65-2.5z"
                    />
                    <path
                        fill="#0C0"
                        d="M247.8 266.85l4.75 1.25 1.25-4.75-4.75-1.25-1.25 4.75z"
                    />
                    <path
                        fill="#060"
                        d="M263.25 270.65q.75-.1.8-.25v-.15q-.05-.2-.25-.25-.394-.05-.7-.05h-.1q-.5 0-.85-.05-.2-.05-.35-.1-.05 0-.25-.1-.1-.1-.15-.1-.05-.05-.75-.45-.3-.15-.4.25-.1.3 2 1.3l.05.05h.1q.25.05.85-.1z"
                    />
                    <path
                        fill="#660"
                        d="M263.55 268.45q.1-.3-.1-.55-.15-.3-.45-.35-.3-.1-.55.05-.25.15-.35.45-.05.3.05.55.15.3.45.4.3.05.55-.1.35-.15.4-.45z"
                    />
                </g>
                <g id="ObjMor005__Layer4_1_FILL">
                    <path
                        fill="#0C0"
                        d="M241.15 291.5l-1.25 4.7 4.75 1.25 1.25-4.7-4.75-1.25z"
                    />
                    <path
                        fill="#09F"
                        d="M257.95 296l-4.75-1.3-1.25 4.7 4.75 1.3 1.25-4.7m-6.05-1.65l-4.75-1.25-1.25 4.7 4.75 1.25 1.25-4.7z"
                    />
                    <path
                        fill="#F90"
                        d="M260 296.55l-.75-.2-1.25 4.7.25.05q.25-.85.85-2.35.5-1.25.9-2.2z"
                    />
                </g>
                <g id="ObjMor005__Layer4_2_FILL">
                    <path
                        fill="red"
                        d="M254.3 297.35l.6.8q1.2-.6 2.1-1.7-1.6.15-2.7.9z"
                    />
                    <path
                        fill="#FFF"
                        d="M254.3 297.35q-.155.116-.3.25-.606.534-1.05 1.3.545-.104 1.05-.3.467-.186.9-.45l-.6-.8M244 295q-.25-.1-.45.2-.15.1-.25.3-.4-.35-.5-.5-.1-.25-.25-.5-.15-.2-.5-.65-.3-.4-.3-.45.05-.1.3-.35.3-.25.35-.35.05-.3-.1-.4-.15-.1-.45-.05-.7.25-.9.95-.2.65.45 1.85.65 1.2 1.6 1.45.25.05.75-.3.4-.3.45-.5.05-.2.05-.35-.05-.3-.25-.35m6.85.5l-3.35-.9-.55 2.15 3.35.9.55-2.15z"
                    />
                </g>
                <g id="ObjMor005__Layer4_3_FILL">
                    <path
                        fill="#CCC"
                        d="M264.4 286.15l.1-.2q.05-.3-.25-.4-.3-.15-.4.2l-.05.25q.1.15.25.2.2.05.35-.05m-1.8-1.9l.05.2q0 .05.05.1-.25.2-.35.5-.2 0-.25.2v.25q.05.05.05.1.05.15 0 .3l1 .3q0-.25.05-.45.1-.4.45-.6.35-.25.75-.15.4.15.65.5.2.4.1.8-.05.2-.2.35l.9.25q.15-.15.2-.4.15.05.3-.1.1-.1.1-.2.1-.3-.2-.35l-.05-.05q0-.35-.2-.7.15 0 .25-.1t.1-.2q.1-.3-.2-.4-.3-.05-.45.15-.15-.2-.3-.35l.1-.2q.05-.3-.25-.35-.3-.15-.4.2v.1q-.1 0-.15-.05-.25-.05-.45-.1 0-.2-.25-.3-.3-.1-.4.3-.05.05 0 .15-.25.05-.45.15 0-.05-.1-.1-.35-.1-.45.25z"
                    />
                    <path
                        fill="#FF0"
                        d="M265.8 275.7q-.55.35-.75.95-.15.6.2 1.1.3.55.9.7.6.15 1.15-.15.5-.3.65-.9.2-.6-.1-1.15-.35-.55-.95-.7-.6-.15-1.1.15m-1.4-6.9q.15-.7-.2-1.3-.4-.65-1.1-.85-.7-.15-1.3.2-.65.3-.8 1-.2.65.2 1.25.3.55.95.8.35.05.85.05h.1q.207-.005.5-.2.6-.3.8-.95m-.95-.9q.2.25.1.55-.05.3-.4.45-.25.15-.55.1-.3-.1-.45-.4-.1-.25-.05-.55.1-.3.35-.45.25-.15.55-.05.3.05.45.35z"
                    />
                    <path
                        fill="#FFF"
                        d="M247.05 286.8q.25-.8-.15-1.5-.35-.75-1.1-.95-.75-.2-1.45.3-.65.4-.9 1.2-.2.8.15 1.5.4.7 1.15.9.75.2 1.4-.25.7-.4.9-1.2m-.85-1.65q.1.1.15.25.3.55.15 1.2-.2.6-.7.9-.45.3-.95.15-.4-.1-.7-.45 0-.05-.1-.2-.25-.45-.05-1.05.15-.65.6-1.05.55-.3 1.05-.15.35.1.55.4m-.2.8q.1-.15.1-.25 0-.3-.25-.3-.35 0-.7.05-.5.1-.5.25-.05.05.05.05-.05.1-.05.3-.05.2-.1.25-.05-.05-.05-.1-.05 0-.15.05-.05.05-.05.1v.15q.1.1.3.15.15.05.2-.2.05-.3.1-.3v-.3q.3 0 .5-.05.15 0 .4-.1v.05l-.15.45q-.05.1-.1.35-.05-.05-.05-.1-.05-.1-.15-.1-.1-.05-.15.1l.05.2q.05.1.25.15.2.05.5-.85m6.8.95q-.35-.75-1.1-.95-.75-.2-1.45.25-.65.4-.9 1.2-.2.8.15 1.5.4.75 1.15.95.75.2 1.4-.25.7-.4.9-1.2.25-.8-.15-1.5m-.7-.2q.15.15.2.25.25.55.1 1.2-.2.6-.65.9-.5.3-1 .2-.4-.1-.7-.45 0-.05-.1-.2-.25-.5-.05-1.1.15-.65.6-1 .55-.3 1.05-.15.35.1.55.35m-1.4.4q-.05-.05-.1-.05-.45-.05-.5.25-.05.1.15.3l.05.05q-.05.1-.15.2-.15.2-.15.3-.05.15.1.15.1.05.35-.25.05-.1.15-.2.1 0 .3.1l.4.1q-.05.1 0 .2.05.5.25.55.15.05.2-.1-.1-.3-.05-.5.35.1.4-.15.05-.15-.05-.25-.05-.05-.35-.3l-.15-.05q0-.3-.15-.45-.1-.6-.4-.25-.15.2-.3.35m.2.15q.25-.2.25-.25.05.15.1.45-.05 0-.05-.05-.2-.15-.3-.15m-3.45-10.1q-.85-.2-1.6.2-.7.45-.95 1.3-.2.85.15 1.55.45.8 1.3 1 .85.25 1.6-.2.8-.4 1-1.25.25-.85-.2-1.6-.45-.75-1.3-1m.1.2q.05.15 0 .3l-.25 1.25q0 .05-.1.1l.05.05q.1.1.05.3-.05.1-.15.2-.15.1-.3.05-.2-.05-.25-.2-.05-.1 0-.15.05-.25.2-.3-.2-.2-.45-.4-.55-.5-.5-.7.1-.3.35.05.15.15.35.4.05 0 .4.4.1 0 .1.05.15-.65.15-.75.1-.4.15-.55.15-.3.2-.1m5.1-11.55q.1-.4-.45-.85-.45-.4-1.2-.65-.85-.2-1.5-.1-.6.15-.7.55-.1.4.35.8l.05.05q-.1.15-.1.2-.05.15-.15.3-.05.15-.1.25l-.05.1q.05.05.15.05.05.05.75-.25.15-.1.35-.15.2.05.45.1.75.25 1.35.1.7-.1.8-.5z"
                    />
                    <path
                        fill="#9B8886"
                        d="M251.05 280.6l-.45 1.6 1.6.4.45-1.6-1.6-.4m.05-.3l1.6.4.45-1.65-1.6-.4-.45 1.65m3.95-.75l-1.65-.4-.45 1.65 1.65.4.45-1.65z"
                    />
                    <path
                        fill="#F90"
                        d="M254.1 283.1l.45-1.6-1.65-.4-.45 1.6 1.65.4z"
                    />
                    <path
                        fill="#FFD680"
                        d="M251.3 270.75l-3.8-1.05-1.05 4.05 3.8 1.05 1.05-4.05m-3.35-.5l2.8.75-.65 2.5-2.8-.75.65-2.5m1.65 4q-.2-.05-.25-.25v-.15q.1-.3.4-.15.25.05.2.3 0 .1-.15.2-.05.1-.2.05m-2.45-.8l.05-.15q.1-.3.35-.2.25.1.2.35 0 .1-.1.15-.1.1-.25.05t-.25-.2z"
                    />
                    <path
                        fill="#666"
                        d="M267.15 270.6q.3.55.9.7.6.15 1.15-.15.5-.3.7-.9.15-.6-.15-1.15-.35-.55-.95-.7-.6-.15-1.1.15-.55.35-.7.95-.2.6.15 1.1m1 .1q-.35-.1-.5-.45-.2-.4-.1-.8.15-.4.45-.6.35-.25.7-.15t.5.45q.2.35.05.75-.1.4-.4.65-.35.25-.7.15z"
                    />
                    <path
                        fill="#39F"
                        d="M267.65 270.25q.15.35.5.45.35.1.7-.15.3-.25.4-.65.15-.4-.05-.75-.15-.35-.5-.45-.35-.1-.7.15-.3.2-.45.6-.1.4.1.8z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor005__Symbol_118_0_Layer0_0_FILL"
                transform="translate(234.75 251.65)"
                id="ObjMor005__col1n"
            />
            <use
                xlinkHref="#ObjMor005__Symbol_99_0_Layer0_0_FILL"
                transform="translate(233.85 250.55)"
                id="ObjMor005__col1l"
            />
            <use
                xlinkHref="#ObjMor005__Symbol_23_0_Layer0_0_FILL"
                transform="translate(237 258.6)"
                id="ObjMor005__col2n"
            />
            <use xlinkHref="#ObjMor005__Layer4_0_FILL" />
            <use xlinkHref="#ObjMor005__Layer4_1_FILL" />
            <use xlinkHref="#ObjMor005__Layer4_2_FILL" />
            <use xlinkHref="#ObjMor005__Layer4_3_FILL" />
            <use xlinkHref="#ObjMor005__Layer4_3_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMor005
