import { AssetSvgProps, ItemText } from "../../../shared/assets"

export const defaultColors = {
    col1n: "#830000"
}

export const defaultTranslate = { x: 0, y: 70 }

export const defaultText: ItemText = {
    value: "Your text",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "bold"
}

function Text({
    colors = defaultColors,
    className,
    onClick,
    outerTransform,
    transform,
    startDrag,
    style,
    textContent
}: AssetSvgProps) {
    return (
        <g transform={outerTransform}>
            <g
                className={className}
                onClick={onClick}
                transform={transform}
                onMouseDown={startDrag}
                onTouchStart={startDrag}
            >
                <text
                    fill={colors.col1n}
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    style={style}
                >
                    {textContent
                        ?.split("\n")
                        .map((line: string, index: number) => (
                            <tspan x="50%" dy="1em">
                                {line}
                            </tspan>
                        ))}
                </text>
            </g>
        </g>
    )
}

export default Text
