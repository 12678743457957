import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000",
    "col2n": "#FFC400"
}

function ObjToo064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M67.6 27.7q1-3.1.4-5.65-.45-1.8-1.45-2.65L6.85 0Q3.4-.25.75 5.45q-.1.2-.15.45l-.1.3q-.85 2.95-.3 5.45t2.3 3.15l.25.1 58.4 19.05v-.05q1.65.2 3.4-1.3 2.05-1.8 3.05-4.9z"
                    id="ObjToo064__Symbol_222_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.1 3.2Q9.7 1.4 8.7.6L6.85 0Q3.4-.25.75 5.45q-.1.2-.15.45l-.1.3q-.85 2.95-.3 5.45t2.3 3.15l.25.1q1.9.55 3.9-1.15Q8.7 12 9.7 8.9q1-3.1.4-5.7z"
                    id="ObjToo064__Symbol_144_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M15.6 18.55q-.2-.6-.25-.7-.35-.9-.65-1.1-.45-.35-.8-.25-.35.1-.35.5 0 .05.1.35-.05.3-.05.75 0 1 1.2 3.65.05.1.05.15v.05q.85 2.2 1.2 2.85.2.55.45.55h.2l.1.1v-3.5q-.25-.85-.65-1.8-.15-.4-.25-.7-.1-.45-.3-.9m-6.5-2.1q-.35.3-.8.8-.2.2-.8.6-.55.4-.6.8-.2.05-.5.2-.25.45-1.95 2.75-.05.1-.25.45.8.15 2.35-.85 1.1-.75 1.8-1.5 1.1-1.1 1.5-1.4.2-.1.3-.4.1-.35.15-.45.5-.5.55-.75.1-.2.05-.35-.05-.2-.5-.3-.55-.15-1.3.4m-6.75-5.5q1.15.6 1.8.95.7.35 2.35.9 1.7.55 1.95.55t.4-.1q.25.1.35.1.35 0 .4-.25h.1q.15 0 .25-.05.05-.25.15-.45-.1-.45-1.05-.85-.95-.45-1.85-.85-.95-.4-1.4-.4-3.25-1.2-4.45-1.2-.05 0-.15.05-.2-.1-.4-.05-.55.1-.8.3.1.05.25.15.95.55 1.4.95.5.05.7.25m9.5-5.25q0-.55-.5-2.1-.25-.8-.5-1.25 0-.1-.05-.2 0-.05-.05-.05-.1-.5-.25-.75-.1-.45-.2-.75-.15-.55-.5-.6-.65-.15-.5 1.4 0 .3.15 1.8.15 1.85.3 2.55.05.15.45 1 .3.7.35 1.15.15 1.8.85 2.05.15.05.25.05.15 0 .2-.1.25-.1.25-.4.1-.05.15-.2l.05-.25q-.1-.55-.25-1.05l.05-.3q-.25-1.15-.25-2m9.3 11.45q.85.3 1.5.25 1.15.3 2.6.15-.1.1.5.1.55 0 .65-.3-.25-.15-.6-.3-.55-.2-1.35-.5-.25-.1-.55-.4-.7-.2-2.45-.8-2.8-.95-3.3-.95-.4 0-.9.35-.45.3-.45.55 0 .1.1.2.05.4.95.65.05 0 .1.05.9.4 1.3.4.9.55 1.9.55m-1.5-1.4q-.05-.05-.25-.15.45.1.85.2-.55-.05-.6-.05m1.7-11.05q-.2.15-.85 1.05-.25.3-.45.55-.35.2-.65.45-.7.65-.75.7-.4.3-1 1-.1.1-.25.2Q16 9.8 16 10q0 .05.15.2-.1.1-.1.3 0 .45.55.45.25 0 .45-.2h.1q1.8 0 3.65-2.95l1.65-2.6v.1q.9-1.5.9-1.35 0-.35-.2-.5-.3-.2-.65.3-.5.35-1.15.95m-1.1 2.85v.05l-.15.1h-.05l.2-.15z"
                    id="ObjToo064__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    d="M83.5 278.05q3.35 3.25 3.6 3.25l.7-.1q.05.05.05-.6 0-1.55-3.45-4.2-.45-.3-.9-.65-4.55-3.3-7.3-2.55-.75.25-1.7.8-2.45 1.4-6.25 4.8-.45.15-1.05.45-.55.35-1.4.7-.45.2-.8.35h-.25q-.5.3-1.75.6-1.9.4-4.55.45-.25.25-.25.4 0 .7.55 1.05h.7q2.05 0 4.45-.75.2-.05.35-.1.25-.05.5-.05.55-.2 1.1-.45.4-.3.85-.35.4-.2.75-.4 1.4-1 2.15-1.45l.15-.25q.75-.7 1.4-1.3 1.8-1.65 3.35-2.45 1.55-.8 2.8-.8 2.55 0 6.05 3.45l.15.15z"
                    id="ObjToo064__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo064__Symbol_222_0_Layer0_0_FILL"
                transform="translate(82.4 273.45)"
                id="ObjToo064__col1n"
            />
            <use
                xlinkHref="#ObjToo064__Symbol_144_0_Layer0_0_FILL"
                transform="translate(82.4 273.45)"
                id="ObjToo064__col1d"
            />
            <use
                xlinkHref="#ObjToo064__Symbol_95_0_Layer0_0_FILL"
                transform="translate(45.75 268.45)"
                id="ObjToo064__col2n"
            />
            <use xlinkHref="#ObjToo064__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo064
