import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ObjMus042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.75 1q-1.4 0-2.5.7Q-2.1 6.8.8 20.7q1 3.45 3.15 8.2 1 2.2 4.95 9.7 2.75.25 4.5-2.2-1.8-3.25-2.6-5.15-1.4-3.6-1.4-7.05 0-.65.1-1.15.1-.45.3-.85.45-.5 1.4-.9.25-.1.5-.25 2.75-2.7 2.4-7.7v-.1q0-5.4-1.5-8.65Q10.9 1 7.75 1M208.3 19.7q2.9-13.9-4.45-19-1.1-.7-2.5-.7-3.15 0-4.85 3.6-1.5 3.25-1.5 8.65v.1q-.35 5 2.4 7.7.25.15.5.25.95.4 1.4.9.2.4.3.85.1.5.1 1.15 0 3.45-1.4 7.05-.8 1.9-2.6 5.15 1.75 2.45 4.5 2.2 3.95-7.5 4.95-9.7 2.15-4.75 3.15-8.2z"
                    id="ObjMus042__Symbol_126_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus042__Symbol_126_0_Layer0_0_FILL"
                transform="translate(87.95 155)"
                id="ObjMus042__col1n"
            />
        </g></g>
    )
}

export default ObjMus042
