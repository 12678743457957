import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function AccMas031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M123.25 49.45q10.45-21.7 5.45-33.3-1.35-3.05-3.7-5.35l-.7-1q-4.95-5.3-12.95-7.75-15.4-4.05-30.8 0-.2.05-.35.1Q51.5 9.5 26.85 37q-2.4 2.75-4.65 5.65-1.5 1.7-2.95 3.45l-2.7 3.5q-2.25 3-4.2 6-4.3 6.75-7 13.55Q2.4 75.6 1.75 81.5 1.2 83.8.8 86.15l-.3 2v1.55q-2.8 14.55 10.55 38 0 .05.1.1 3.45 6.2 8.2 12.45 2.65 3.5 5.75 7.05 22.6 26.65 30.5 16.75 7.85-9.95 10.45-13.95 2.6-4 6.2-6.7t6.45-4l3.45-1.35q8.85-2.95 17.75-.75V86.75q-.3-13.85 13.25-23.85 2-2.55 4.25-4.95 1.75-1.9 3.35-4.3.45-.75.95-1.55.8-1.35 1.55-2.65M81.45 69.2q7.95 1.7 11.65 7.35 1.15 2.4 1.65 5.5 1.9 11.8-2.3 24.15-4.3 12.35-13.75 18.5-6.4 4.25-13.45 4.6-12.6.05-17.75-8.65-.2-.3-.35-.65l-.25-.45q-5.85-10.85-2.65-24.4.7-3.85 2.25-7.3 2.95-6.7 8.8-12.15.6-.65 1.3-1.25.95-.7 1.95-1.35 10.4-6.55 22.9-3.9z"
                    id="AccMas031__Symbol_113_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M92.3 76.55q1.15 2.4 1.65 5.5 1.9 11.8-2.3 24.15-4.3 12.35-13.75 18.5-6.4 4.25-13.45 4.6-12.6.05-17.75-8.65-.2-.3-.35-.65l-.25-.45q-5.85-10.85-2.65-24.4.7-3.85 2.25-7.3 2.95-6.7 8.8-12.15.6-.65 1.3-1.25-.65.45-1.25.95-6.8 5.4-10.7 14.15-4.5 10.45-3.25 25.5 2.55 13.25 13.3 16.4 12.75 3.35 23.95-3 9.95-6.5 14.6-19 4.7-12.9 2.7-25.25-.75-4.5-2.85-7.65m31.9-65.75l-.7-1q-4.95-5.3-12.95-7.75-15.4-4.05-30.8 0-.2.05-.35.1Q50.7 9.5 26.05 37q-2.4 2.75-4.65 5.65-1.5 1.7-2.95 3.45l-2.7 3.5q-2.25 3-4.2 6-4.3 6.75-7 13.55Q1.6 75.6.95 81.5.4 83.8 0 86.15q.4-1.35.85-2.75 1.75-5.5 4.5-11.45 2.7-6.8 7-13.55 1.95-3 4.2-6l2.7-3.5q1.45-1.75 2.95-3.45 2.25-2.9 4.65-5.65Q53.45 10.1 84.8 3.9q14.8-2.3 29.85 1.4 6 1.95 9.55 5.5z"
                    id="AccMas031__Symbol_70_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas031__Symbol_113_0_Layer0_0_FILL"
                transform="translate(95.8 62.45)"
                id="AccMas031__col1n"
            />
            <use
                xlinkHref="#AccMas031__Symbol_70_0_Layer0_0_FILL"
                transform="translate(96.6 62.45)"
                id="AccMas031__col1d"
            />
        </g></g>
    )
}

export default AccMas031
