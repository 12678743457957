import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiSpe129({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M83.1 14.35q2.9-2.3 5.2-4.75 1.9-2.1 3.4-4.35 1.5-2.3 2.55-4.8.05-.05.05-.15.05-.15.15-.3-.5.25-1 .55-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95Q11.5 6.25 1.85 1.05.9.5 0 0q3.35 8.5 12.45 15.2 14.4 10.65 34.75 10.65 9 0 16.9-2.1.25-.05.55-.15 5.8-1.6 11-4.4 3.3-1.75 6.3-4 .55-.4 1.15-.85z"
                    id="ShiSpe129__Symbol_310_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe129__Symbol_310_0_Layer0_0_FILL"
                transform="translate(145.3 237.45)"
                id="ShiSpe129__col1n"
            />
        </g></g>
    )
}

export default ShiSpe129
