import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#9A9956"
}

function LegPan003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7 16.15V.05l.05-.05Q61.85 12.5 0 0q0 .05.05.1v12.5q.05.15.1.35L4.2 9.8l3.95 5.3q1-1.4 4.05-4.35 1.75 4.35 2.4 6.5 8.95-7.35 9.8-7.5.05 0 .05.05v.1l1.65 3.55q2.2-1.1 3.1-1.95l3.15 3.55q.05-.05.1-.05.95-.55 6-3 .25.5 1.75 3.75 1.5-.75 5.1-3 1.05 0 3.4 4.75 3.35-1.45 5.5-3.5 1.05 1.3 3.6 5.8 1-1.4 4.9-6.2l3.1 6.1 10.25-5.6q.45.45 2.4 3.15l3.6-3.95h.2l17.15 2.75 3.8-6.35 2.75 4.2 2.25-2.65 2.15 4.55 2.05-3 3.25 3.75 3.55-5.75q2.15 5.5 3.45 7.65l-.1.3q.35-.8.7-1.75.45-1.2.75-2.2l-.05.25.2-.5q1.1 1.2 1.55 1.6z"
                    id="LegPan003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan003__Symbol_3_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan003__col1n"
            />
        </g></g>
    )
}

export default LegPan003
