import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#775C31",
    "col1l": "#D19532",
    "col1n": "#A37831"
}

function AccHat106({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M193.8 90.45q-.4-2.2-.9-4.2-15.75-62.6-63.95-80.1-3.6-1.45-7.1-2.6-2-.65-4-1.2-10.7-2.65-21.4-2.3-1.8.05-3.6.2V.2l-.1.05q-6.5.5-13 2.1l-.6.2q-2 .45-4 1Q20.05 18.45 3 86.25q-.5 2-.9 4.2Q1.4 94.2 1 98.7q-2.95 28.85 2.75 57.7 2.4 6.15 7.7 9.85 6.45 4.5 14.5 10 23.7 18.05 52 29.2 3.7 1.5 7.5 2.85.65.25 1.3.45l.8.3q2.6.85 5.25 1.65v-51l5.15 1.9 5.15-1.9v51q2.65-.8 5.25-1.65l.8-.3q.65-.2 1.3-.45 3.8-1.35 7.5-2.85 28.3-11.15 52-29.2 8.05-5.5 14.5-10 5.3-3.7 7.7-9.85 5.7-28.85 2.75-57.7-.4-4.5-1.1-8.25m-155.35 29.5l-2.1-2.1q18.9-16.95 34.8-20.55 9.55-1.75 17.7 4.25l2 1.65q6.7 6.1 5.95 15.15-.1 1.25-.3 2.5l-.1.6-.2.9v.15q-.3 1.4-.65 2.8-.05.1-.05.2-4.4 12.55-18.15 15.45-16.4 2.25-37.1-19.1-.95-.95-1.8-1.9m121.1-2.1l-2.1 2.1q-.85.95-1.8 1.9-20.7 21.35-37.1 19.1-13.75-2.9-18.15-15.45 0-.1-.05-.2-.35-1.4-.65-2.8v-.15l-.2-.9-.1-.6q-.2-1.25-.3-2.5-.75-9.05 5.95-15.15l2-1.65q8.15-6 17.7-4.25 15.9 3.6 34.8 20.55z"
                    id="AccHat106__Symbol_452_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M63.7 157.35q-.45.25-.95.5l-4.3 1.65v51q.45-1.05.85-1.95 2.15-4.55 4.4-6.2v-45m-19.85.5q-.5-.25-.95-.5v45q2.25 1.65 4.4 6.2.4.9.85 1.95v-51l-4.3-1.65M48.2.05V0l-.1.05Q10.7 15.95 1 66.8l-1 2.4 38.1-18.9q2.6-1.15 5.2-1.9Q32.85 27.35 48.2.05z"
                    id="AccHat106__Symbol_431_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M53.3 100.4q-3.65 3.1-5.35 4.6-1.25 1-1.5 1.25-1.05.95-5.65 4.35-4.6 3.35-13.45 4.7l-.85 4.6q9.55 1.65 17.55 11.95.6.85 1.3 1.7.7.9 1.5 1.85 3.55 4.15 7.15 7.1 2.65 1.5 5.2 2.65 15.1 6.2 30.95-4.65l2.4-2.1 2.35-10.2q.45-1.55.75-2.85.4-1.6.55-2.85-.3 1.4-.65 2.8-.05.1-.05.2-4.4 12.55-18.15 15.45-16.4 2.25-37.1-19.1-.95-.95-1.8-1.9l-2.1-2.1q18.9-16.95 34.8-20.55 9.55-1.75 17.7 4.25l2 1.65q6.7 6.1 5.95 15.15.45-4.4-.1-8.75v-.1q-2.1-10.45-11.6-15.4-14.95-6.75-31.8 6.3m116.1 19.5l-.85-4.6q-8.85-1.35-13.45-4.7-4.6-3.4-5.65-4.35-.25-.25-1.5-1.25-1.7-1.5-5.35-4.6-16.85-13.05-31.8-6.3-9.5 4.95-11.6 15.4v.1q-.55 4.35-.1 8.75-.75-9.05 5.95-15.15l2-1.65q8.15-6 17.7-4.25 15.9 3.6 34.8 20.55l-2.1 2.1q-.85.95-1.8 1.9-20.7 21.35-37.1 19.1-13.75-2.9-18.15-15.45 0-.1-.05-.2-.35-1.4-.65-2.8.15 1.25.55 2.85.3 1.3.75 2.85l2.35 10.2 2.4 2.1q15.85 10.85 30.95 4.65 2.55-1.15 5.2-2.65 3.6-2.95 7.15-7.1.8-.95 1.5-1.85.7-.85 1.3-1.7 8-10.3 17.55-11.95m25.5-21.2q-.4-4.5-1.1-8.25-.4-2.2-.9-4.2-15.75-62.6-63.95-80.1-3.6-1.45-7.1-2.6-2-.65-4-1.2-10.7-2.65-21.4-2.3 22.55 4.3 38.1 18.55 21.3 19.7 27.2 56l-48.6-24.1q-2.6-1.15-5.2-1.9-3.1-.95-6.2-1.25-1.9-.2-3.8-.2t-3.8.2q-3.1.3-6.2 1.25-2.6.75-5.2 1.9L3.25 89.9q0-.1.05-.2l-1.2.75Q1.4 94.2 1 98.7q-2.95 28.85 2.75 57.7 2.4 6.15 7.7 9.85 6.45 4.5 14.5 10 23.7 18.05 52 29.2 3.7 1.5 7.5 2.85.65.25 1.3.45l.8.3q2.6.85 5.25 1.65-.45-1.05-.85-1.95-2.15-4.55-4.4-6.2-1.05-.75-2.1-.9-5.85-.9-12.55-2.9-13.65-4.15-25.75-11.6Q33.95 179 26.35 169q-8.55-11.2-8.55-23.05 1-22.8 1-24.05 0-12.8-1.45-19.2-2.1-8.95-8.2-15.2l77.4-31.8q3.75-1.55 7.6-1.95 1.9-.2 3.8-.15 1.9-.05 3.8.15 3.85.4 7.6 1.95l77.4 31.8q-6.1 6.25-8.2 15.2-1.45 6.4-1.45 19.2 0 1.25 1 24.05 0 11.85-8.55 23.05-7.6 10-20.8 18.15-12.1 7.45-25.75 11.6-6.7 2-12.55 2.9-1.05.15-2.1.9-2.25 1.65-4.4 6.2-.4.9-.85 1.95 2.65-.8 5.25-1.65l.8-.3q.65-.2 1.3-.45 3.8-1.35 7.5-2.85 28.3-11.15 52-29.2 8.05-5.5 14.5-10 5.3-3.7 7.7-9.85 5.7-28.85 2.75-57.7z"
                    id="AccHat106__Symbol_396_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat106__Symbol_452_0_Layer0_0_FILL"
                transform="translate(94.55 46.45)"
                id="AccHat106__col1n"
            />
            <use
                xlinkHref="#AccHat106__Symbol_431_0_Layer0_0_FILL"
                transform="translate(139.2 46.65)"
                id="AccHat106__col1l"
            />
            <use
                xlinkHref="#AccHat106__Symbol_396_0_Layer0_0_FILL"
                transform="translate(94.55 46.45)"
                id="AccHat106__col1d"
            />
        </g></g>
    )
}

export default AccHat106
