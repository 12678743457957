import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400",
    "col2n": "#0000BB"
}

function ObjMor068({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.2 5.85q0 .05-.05.1l1.4 54.3q.2-.05.4-.05 5.9-.25 11.1-2.2L15.45 5.65l4.2 2.8L23 3.05 18.35 0q-1.1.6-5.7 3.85Q9.5 6 10.5 6.15L0 58l.8.3q1.35.45 2.75.8 3.8.95 7.95 1.15l1.6-54.3q-.05 0 .1-.1m25.55 7.55l-7.8-5.15q-4.15 1.05-3.4 5.35l7.8 5.15 3.4-5.35M58.9 34.35l-.65-.4 8.7 5.75q2.5-2.55 3.35-5.35l-8.15-5.55q-3.6 1.1-3.25 5.55m-12.35-15.8q-3.8.65-3.35 5.4l7.9 5.2q.1-.45.25-.85.05-.3.55-.5.5-.25 1.15-1.15.65-.9.9-1.85.25-.95.6-1.2l-8-5.05m36.1 31.5Q84.9 48 86 44.7l-7.85-5.15q-5.3.65-3.35 5.3l7.85 5.2m75.95 41.6q-5.25 1.15-3.5 6l.5.35q2.1.95 3.9-1.55l.8-1.3q.75-1.8-.85-3l-.85-.5m-19.9-7.75q-.05-.1-.1-.15l-.05.05q-1.7.6-.8 2.3l7.8 5.4q1.95.15.4-1.55-.55-.3-1.05-.6-4.05-2.35-6.2-5.45m-11.3-12.05l-2.1-1.3q-5.55-.4-3.35 5.4l2.1 1.35q2.8 1.9 5.65 3.75-1.35-1.9-1.35-4.4 0-2 .9-3.6-.95-.6-1.85-1.2m-29-11.4l3.35-5.4-7.85-5.2q-4.45.8-3.35 5.4l7.85 5.2m19.1 4.95l-7.8-5.15q-5.05.1-3.4 5.35l7.8 5.15 3.4-5.35z"
                    id="ObjMor068__Symbol_202_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.25 5.6v-.3q-.1-2.1-1.65-3.65-.25-.25-.5-.45-.2-.1-.35-.25Q7.35 0 5.65 0 3.6 0 2.2 1.2q-.2.15-.3.25-.2.1-.2.2Q0 3.3 0 5.6q0 2.4 1.7 4 0 .15.2.2l.05.05q.65.65 1.55 1 .8.35 1.8.4h.75q.85-.05 1.65-.4l.3-.1q.15-.1.3-.15.2-.15.45-.3.25-.2.5-.35l.35-.35q1.65-1.6 1.65-4m9.2 13.3q.45-3.7 3.4-5.35l-7.95-5.2q-3 1.35-3.35 5.4l7.9 5.15m11.2-.2q-2.75 1.6-3.4 5.35l7.85 5.2q.75-3.95 3.35-5.4l-7.8-5.15m39.4 26.15l-7.85-5.2q-3.15 2.8-3.35 5.35l7.85 5.15q.45-3.35 3.35-5.3M46.8 29.4q-.7.55-1.3 1.35-.7 1-1.1 2.3-.1.25-.15.55-.15.4-.25.85l7.8 5.2q.85-3.65 3.25-5.55l-7.6-5.2q-.35.25-.65.5M139 95.3q-.1-.05-.15-.05-.25.7-.4 1.55l7.65 5.05q1.1.7 1.9 1.1.75-3.7 3.5-6l-.65-.4q-1.55.9-4.15.85-3.75-.15-7.7-2.1m-10.2-5.8q-.7 0-1.35-.1l3.2 2q.3-1.3.8-2.3-1.25.4-2.65.4m-13.95-8.25q.75-3.95 3.35-5.4l-7.8-5.15q-2.75 1.6-3.4 5.35l7.85 5.2m-31.4-20.7q.65-3.8 3.35-5.4L78.9 50q-2.95 2.2-3.35 5.35l7.9 5.2m11.2-.2q-3 1.35-3.35 5.4l7.9 5.15q.45-3.7 3.4-5.35l-7.95-5.2z"
                    id="ObjMor068__Symbol_201_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor068__Symbol_202_0_Layer0_0_FILL"
                transform="translate(116.9 215.05)"
                id="ObjMor068__col1n"
            />
            <use
                xlinkHref="#ObjMor068__Symbol_201_0_Layer0_0_FILL"
                transform="translate(124 209.75)"
                id="ObjMor068__col2n"
            />
        </g></g>
    )
}

export default ObjMor068
