import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.7 20q.25.15.55.15l.05-.05q.45-.3.45-.75 0-.05-.25-.65L2.35 0l-.1.1Q1.8 1.35 0 2.65q.1.25.4.45L29.7 20M74.5 3.1q.3-.2.4-.45-1.8-1.3-2.25-2.55l-.1-.1L44.4 18.7q-.25.6-.25.65 0 .45.45.75l.05.05q.3 0 .55-.15L74.5 3.1z"
                    id="FacBro004__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro004__Symbol_4_0_Layer0_0_FILL"
                transform="translate(155 121.05)"
                id="FacBro004__col1n"
            />
        </g></g>
    )
}

export default FacBro004
