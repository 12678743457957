import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400",
    "col1s": "#932800",
    "col2l": "#333333",
    "col2n": "#000000"
}

function ShiSpe134({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M149.65 0q-.2.2-.35.45-2.2 2.5-4.4 4.9l-.05-.05q-4.55 4.85-9.5 9.25l-1.2.9q-1.35 1.05-2.8 2.1l-1.55 1.15q-6.25 4.3-12.95 7.5-.15.05-.3.15-4.85 2.25-9.95 3.95-.3.05-.6.2l-.65.2q-2.5.85-5.15 1.4l-.6.45q-1.35.55-2.7 1.2-1.8.8-3.45 1.8-3.55 2.1-6.45 4.85-.45.45-.9.95-.15.1-.25.2-.3.35-.65.7-4.5 5.1-6.75 12h.1q-1.2 2.9-2 6.05-.6.95-1.2 0-.55-3.2-1.5-6.05-2.25-6.9-6.75-12-.35-.35-.65-.7l-.15-.15q-.5-.55-1-1-2.9-2.75-6.45-4.85-1.65-1-3.45-1.8-1.35-.65-2.7-1.2-.8-.3-1.55-.55-2.95-.8-5.8-1.7-2.3-.75-4.5-1.65-1.3-.5-2.6-1.05-1.55-.65-3.05-1.35-.1-.05-.15-.05-.5-.25-.95-.5l-.7-.3q-1.15-.6-2.25-1.15-2.6-1.4-5.15-3-2-1.2-3.9-2.55l-1.55-1.15q-1.45-1.05-2.8-2.1l-.6-.45q-5.35-4.65-10.2-9.85-1.2-1.25-2.3-2.5Q3.6 1.45 2.6.25L2.35 0Q-3.5 11.05 5.2 22.15q6.15 6.55 15.05 10.3l1.25.45q1.15.45 2.3.85l.45.2q.5.15.95.3.25.1.45.15l.3.1v.05l.2.9q.45 2.25.7 4.4.1 1 .15 2 0 .2.05.4.2 4.7-.75 8.9-.4 1.7-.9 3.1-2.15 5.65-6.6 6.85-.65.2-1.3.25l-.45 2.15q-.05.3-.15.7-.5 2.35-.95 4.75l-1.2 5q2.25 4.25-.95 4l-1.3 5.3q-.05.1-.05.25 2.45 2.35-1 4-.3 1.1-.55 2.2-.1.35-.2.75-1 5.2 7.3 6.35 5.15 1.1 10.25 2l2.9.5q1.15.2 2.25.4h.2q3.9.6 7.75 1.1 14.75 1.9 29.4 2.2 4.3-.45 4.6-3.5v-1.1h1.2v1.45q.4 3.05 4.6 3.15 17.75-.35 35.4-3 4.85-.75 9.7-1.65 4.25-.8 8.5-1.7 6.9-.65 6.4-6.2l-.7-2.9q-6.45-.15-1-4.05l-1.4-5.5q-3.8-1.55-1-4.05l-.85-3.55q-.65-3.1-1.3-6.15l-.55-2.9h-.15q-.65-.05-1.3-.25-4.45-1.2-6-6.85-.5-1.4-.9-3.1-.95-4.2-.75-8.9.05-.2.05-.4.05-1 .15-2 .25-2.15.7-4.4l.2-.9v-.05l.3-.1q.2-.05.45-.15.45-.15.95-.3l.45-.2q1.15-.4 2.3-.85l.9-.35h-.2l.25-.1q8.9-3.75 15.05-10.3 8.7-11.1 2.85-22.15z"
                    id="ShiSpe134__Symbol_368_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M26.9 20.25q-1.15-.6-2.25-1.15-2.6-1.4-5.15-3-2-1.2-3.9-2.55l-1.55-1.15q-1.45-1.05-2.8-2.1l-.6-.45Q5.3 5.2.45 0q-1.8 3.75 3.75 9.75 2.75 2.9 10.3 6.15 9.4 4.05 10.4 4.25.95.2 3.8.95-.1-.05-.15-.05-.5-.25-.95-.5l-.7-.3m31.9 15.9q-6.7-3.65-16.3-2.9-3.4.4-8.45-.15-1.1-.15-2.3-.3-1.7-.3-3.6-.6-3.9-.75-8.5-1.9.45 2.25.7 4.4.1 1 .15 2 0 .2.05.4.2 4.7-.75 8.9-.4 1.7-.9 3.1-2.15 5.65-6.6 6.85-.65.2-1.3.25l-.45 2.15q-.05.3-.15.7 3.4-.55 5.2-1.7 1.85-1.2 4.25-3.6 2.35-2.45 3.75-8.25 1.35-5.85 7.3-7.8 5.9-2 13-2.35 7.05-.35 15.35.9l-.45-.1m38-2.9q-9.25-.7-15.8 2.65-.15.05-.2.1 7.3-.95 13.6-.65 7.1.35 13 2.35 5.95 1.95 7.85 7.8 1.85 5.8 3.05 8.25 1.15 2.4 3.7 3.6 2.5 1.15 5.9 1.7.25.05.5.05l-.55-2.9h-.15q-.65-.05-1.3-.25-4.45-1.2-6-6.85-.5-1.4-.9-3.1-.95-4.2-.75-8.9.05-.2.05-.4.05-1 .15-2 .25-2.15.7-4.4-.3.1-.65.15-4.25 1.05-7.85 1.75-1.9.3-3.6.6-1.2.15-2.3.3-5.05.55-8.45.15M138.35.15Q133.8 5 128.85 9.4l-.7.6-.6.45q-1.35 1.05-2.8 2.1l-1.55 1.15q-1.9 1.35-3.9 2.55-2.55 1.6-5.15 3-1.1.55-2.25 1.15l-.7.3q-.45.25-.95.5-.05 0-.15.05 2.85-.75 3.8-.95 1-.2 10.4-4.25 7.55-3.25 10.3-6.15 5.5-5.95 3.8-9.7l-.05-.05z"
                    id="ShiSpe134__Symbol_369_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M128 13.75q.45-.1 1-.15l-1-4.05q-11.9 2.25-23.65 3.7l-.25-1.05q-3.75.45-7.55.8l.2 1.1Q82.6 15.5 68.6 15.75v-1.4h-8.3v1.4q-14.1-.2-28.05-1.55l.2-1.2q-3.8-.35-7.55-.8l-.2 1.15h-.05Q12.8 11.9 1 9.55l-1 4q11.85 2.4 23.85 3.9l-.25 1.2q3.8.45 7.65.85l.25-1.15q3.15.3 6.35.55 11.15.9 22.45 1.05v1h8.3v-1q10.65-.2 21.45-1.05 3.7-.3 7.5-.65l.2 1.25q3.85-.4 7.65-.85l-.25-1.3q11.4-1.4 22.85-3.6m-1.4-9.7l-1-4.05q-11.65 2.15-23.2 3.5l-.25-1.1q-3.65.45-7.35.8l.2 1.1Q81.75 5.5 68.6 5.75v-1.4h-8.3v1.4Q47.1 5.6 34 4.35l.2-1.15q-3.7-.35-7.35-.8l-.2 1.2h-.05Q14.9 2.2 3.3 0l-.95 4Q14 6.3 25.8 7.7l-.25 1.15q3.75.45 7.5.8l.2-1.15q13.5 1.25 27.05 1.45v1h8.3v-1Q82.1 9.7 95.75 8.4l.2 1.25q3.75-.35 7.5-.8l-.25-1.25q11.65-1.4 23.4-3.55z"
                    id="ShiSpe134__Symbol_371_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M1.3 9.8L0 16.25q3.8.45 7.65.85l1.2-6.5q-3.8-.35-7.55-.8m.65-3.35q3.75.45 7.5.8L10.6.8Q6.9.45 3.25 0l-1.3 6.45M45 8.55v-6.6h-8.3v6.6H45m34.85-2.1L78.55 0Q74.9.45 71.2.8l1.15 6.45q3.75-.35 7.5-.8M45 18.55v-6.6h-8.3v6.6H45m27.95-7.95l1.2 6.5q3.85-.4 7.65-.85L80.5 9.8q-3.75.45-7.55.8z"
                    id="ShiSpe134__Symbol_372_0_Layer0_0_FILL"
                />
                <g
                    id="ShiSpe134__Symbol_370_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M40.35 6.9q-.5-.55-1-1-5.8-3-11.65-3.2-2.1-.05-4.15.2-4.45.55-11.25-.4-3-.45-6.4-1.15Q3.55.85 1 .25.5.15 0 0v.05l.2.9q4.6 1.15 8.5 1.9 1.9.3 3.6.6 1.2.15 2.3.3 5.05.55 8.45.15 9.6-.75 16.3 2.9l1.15.25-.15-.15M50.6 60.4h-1.2v3.5h1.2v-3.5m0-6.6v-3.4h-1.2v3.4h1.2m-1.2-10h1.2v-18q-.6.95-1.2 0v18M100.2.95l.2-.9V0q-.3.1-.65.2-.15 0-.35.05-2.55.6-4.9 1.1-3.4.7-6.4 1.15-6.8.95-11.25.4-2.05-.25-4.15-.2-4.6.15-9.1 2.05-1.3.5-2.55 1.15-.45.45-.9.95-.15.1-.25.2l1.15-.25q.15-.05.3-.15.05-.05.2-.1Q68.1 3.2 77.35 3.9q3.4.4 8.45-.15 1.1-.15 2.3-.3 1.7-.3 3.6-.6 3.6-.7 7.85-1.75.35-.05.65-.15zM54.1 60.4v-6.6h-8.3v6.6h8.3m-3.5-16.6h-4.8v6.6h8.3v-6.6h-3.5z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe134__Symbol_368_0_Layer0_0_FILL"
                transform="translate(116.55 214.55)"
                id="ShiSpe134__col1n"
            />
            <use
                xlinkHref="#ShiSpe134__Symbol_369_0_Layer0_0_FILL"
                transform="translate(123.05 219.7)"
                id="ShiSpe134__col1d"
            />
            <use
                xlinkHref="#ShiSpe134__Symbol_370_0_Layer0_0_FILL"
                transform="translate(142.5 249.05)"
                id="ShiSpe134__col1s"
            />
            <use
                xlinkHref="#ShiSpe134__Symbol_371_0_Layer0_0_FILL"
                transform="translate(128 288.5)"
                id="ShiSpe134__col2n"
            />
            <use
                xlinkHref="#ShiSpe134__Symbol_372_0_Layer0_0_FILL"
                transform="translate(151.6 290.9)"
                id="ShiSpe134__col2l"
            />
        </g></g>
    )
}

export default ShiSpe134
