import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#D23900",
    "col2n": "#FF5400"
}

function AccGla017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M1.5 3q.65 0 1.1-.45.4-.4.4-1.05Q3 .9 2.6.45 2.15 0 1.5 0 .9 0 .45.45 0 .9 0 1.5q0 .65.45 1.05Q.9 3 1.5 3z"
                    id="AccGla017__Symbol_107_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.702}
                    d="M172.15 20.05V20v-.75q-.35-5.2-4.3-8.75-5.35-6-13-8.5-1.1-.35-2.45-.6-6.35-1.35-19-1.4-3.45-.05-7.55.1h-.05q-.8 0-1.65.05-1.7.05-3.5.15-8.5.35-14.35 3.9-5.35 3.35-8.9 8.85l-.1.15q-.75 1.2-1.15 2.65-.4-1.45-1.1-2.65l-.1-.15q-3.6-5.5-8.9-8.85Q80.2.65 71.85.3 70 .2 68.25.15 67.45.1 66.7.1h-.05q-4.1-.15-7.45-.1-12.65.05-18.9 1.4-1.4.25-2.45.6-7.6 2.5-12.9 8.5-4 3.55-4.35 8.75v.8q.2 4.8 2.6 12.25.05.1.05.2 2.55 8.15 6.9 15.7.3.45.65.9 7.5 11.3 19.6 14.8.4.1.85.2 5.05 1.05 9.9 1.25 7.8.7 14.15-2.75 1.6-.95 3.1-2.05.4-.25.7-.55 1.8-1.45 3.6-3.35 9.05-9.8 12.8-18.45.15-.3.25-.6.2-.5.4-1.05.15.55.35 1.05l.3.6q3.8 8.65 12.9 18.45 1.75 1.9 3.65 3.35.3.3.65.55 1.55 1.1 3.1 2.05 6.45 3.45 14.3 2.75 4.9-.2 9.95-1.25l.9-.2q12.15-3.5 19.7-14.8.3-.45.65-.9 4.3-7.55 6.9-15.7 0-.1.05-.2 2.4-7.45 2.6-12.25z"
                    id="AccGla017__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill="url(#AccGla017__Gradient_1)"
                    d="M149.5 13.65l-.05-.05q-6.05-8.9-15.8-12.05-1.1-.35-2.4-.6-2.8-.6-6.9-.95H96.6q-1.4.1-2.85.2-4.45 1.25-8.15 3.5-5.8 3.6-9.55 9.8l-.1.1q0 .05-.05.1l-.5 1-.5-1q-.05-.05-.05-.1l-.1-.1Q71 7.3 65.2 3.7 61.5 1.45 57.05.2 55.6.1 54.25 0h-27.8q-4.1.35-6.9.95-1.3.25-2.4.6Q7.4 4.7 1.35 13.6l-.05.05q-.2.3-.3.7-.05.05-.05.15-.1.1-.2.25-.4.85-.6 2Q0 17.8 0 19.2v.05Q.2 24 2.6 31.3q.6 1.95 1.45 4.2v.1l.25.75h.05q0 .05.05.1 8.75 23.5 29.55 26.85 2.15.3 4.5.4h.05q3.75.4 7.25.15h.05q6.45-.85 11.75-4.8l-.05-.15q.35-.15.75-.35 1.85-1.45 3.6-3.3 9-9.6 12.75-18.1.45-1.1.75-2.05 0-.1.05-.15.05.05.05.15.3.95.75 2.05 3.8 8.5 12.75 18.1 1.75 1.85 3.6 3.3.4.2.75.35l-.05.15q5.3 3.95 11.75 4.8h.05q3.5.25 7.25-.15h.05q2.35-.1 4.5-.4 20.8-3.35 29.55-26.85.05-.05.05-.1h.05l.25-.75v-.1q.85-2.25 1.45-4.2 2.4-7.3 2.6-12.05v-.05q0-1.4-.15-2.45-.2-1.15-.6-2-.1-.15-.2-.25 0-.1-.05-.15-.1-.4-.3-.7z"
                    id="AccGla017__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M191.65 14.65q.35-1.7-.25-2.95l-19.05 7.5q-1-5.05-5-8.85-5.45-5.85-13.05-8.3-1.35-.45-3.15-.8-.45-.1-.9-.15-6.1-1.05-17.2-1.1-3.3 0-7.25.1h-.1q-4.35.1-9.55.6-8.25 1.05-14.6 7.15-2.7 2.5-4.9 5.75-.3.5-.7.55-.4-.05-.7-.55-2.2-3.25-4.9-5.75Q84 1.75 75.75.7 70.55.2 66.2.1h-.1Q62.15 0 58.85 0q-11.1.05-17.2 1.1-.45.05-.9.15-1.8.35-3.15.8-7.6 2.45-13.05 8.3-4 3.8-5 8.85l-19-7.3h-.2q-.6 1.1-.2 2.75l19.45 7.7q.3 4.35 2.25 10.65.4 1.3.9 2.65 1.9 6.7 5.8 12.85 9.1 15.05 25.7 17.7.8.1 1.65.2 6.65 1 12.3 0 3.3-.65 6.4-2.1 4.6-2.35 8.5-6.55 2.4-2.55 4.4-5.05 4.95-6.1 7.65-11.7.15-.3.3-.55.25-.5.5-.65.25.15.5.65.15.25.3.55 2.7 5.6 7.65 11.7 2 2.5 4.4 5.05 3.9 4.2 8.5 6.55 3.1 1.45 6.4 2.1 5.65 1 12.3 0 .85-.1 1.65-.2 16.6-2.65 25.7-17.7 3.9-6.15 5.8-12.85.5-1.35.9-2.65 1.95-6.3 2.25-10.65l19.35-7.7M166.1 12.2q3.8 3.4 4.15 8.35v.75q-.2 4.6-2.5 11.7-.05.1-.05.2-2.45 7.75-6.65 14.95l-.6.9q-7.25 10.75-18.9 14.1-.4.1-.85.2-4.85 1-9.55 1.2-7.5.65-13.65-2.65-1.55-.9-3-1.95-.35-.25-.65-.5-1.8-1.4-3.5-3.2-8.7-9.35-12.35-17.6-.15-.3-.25-.6-.9-2.1-.85-4.6V20.1q.2-3.15 1.55-5.35l.1-.15q3.45-5.25 8.55-8.4 5.65-3.4 13.75-3.75 1.75-.1 3.4-.15.8-.05 1.55-.05h.05q3.95-.15 7.2-.1 12.2.05 18.25 1.35 1.3.25 2.35.6 7.3 2.35 12.45 8.1m-72.65 2.55q1.35 2.2 1.55 5.35v13.35q.05 2.5-.85 4.6-.1.3-.25.6-3.65 8.25-12.35 17.6-1.7 1.8-3.5 3.2-.3.25-.65.5-1.45 1.05-3 1.95-6.15 3.3-13.65 2.65-4.7-.2-9.55-1.2-.45-.1-.85-.2-11.65-3.35-18.9-14.1l-.6-.9q-4.2-7.2-6.65-14.95 0-.1-.05-.2-2.3-7.1-2.5-11.7v-.05-.7Q22 15.6 25.8 12.2q5.15-5.75 12.45-8.1 1.05-.35 2.35-.6 6.05-1.3 18.25-1.35 3.25-.05 7.2.1h.05q.75 0 1.55.05 1.65.05 3.4.15 8.1.35 13.75 3.75 5.1 3.15 8.55 8.4l.1.15z"
                    id="AccGla017__Symbol_40_0_Layer0_0_FILL"
                />
                <linearGradient
                    id="AccGla017__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={37.9}
                    y1={1.675}
                    x2={37.9}
                    y2={41.525}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="100%" stopOpacity={0} />
                </linearGradient>
            </defs>
            <use
                xlinkHref="#AccGla017__Symbol_107_0_Layer0_0_FILL"
                transform="translate(191.05 147.7)"
                id="AccGla017__col2n"
            />
            <use
                xlinkHref="#AccGla017__Symbol_66_0_Layer0_0_FILL"
                transform="translate(96 134.25)"
                id="AccGla017__col2d"
            />
            <use
                xlinkHref="#AccGla017__Symbol_55_0_Layer0_0_FILL"
                transform="translate(117.15 135.3)"
            />
            <use
                xlinkHref="#AccGla017__Symbol_40_0_Layer0_0_FILL"
                transform="translate(96.55 133.6)"
                id="AccGla017__col1n"
            />
        </g></g>
    )
}

export default AccGla017
