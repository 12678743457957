import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1l": "#999999",
    "col1n": "#666666",
    "col1s": "#252525",
    "col2d": "#545429",
    "col2l": "#9A9956",
    "col2n": "#6D6D3B"
}

function AccGla025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M139.5 24.2q-3.1-8.3-10.15-14.85Q119.25 0 104.95 0q-3.4 0-6.55.55Q91 1 84.9 4.8q-1.4.9-2.75 1.95-.95.75-1.9 1.65-9.35 5.7-19-.4-.75-.65-1.5-1.25Q58.4 5.7 57 4.8 50.9 1 43.5.55 40.35 0 36.95 0q-14.3 0-24.4 9.35-2.25 2.1-4 4.4-3.2 4.05-4.2 6.75-.25.6-.4 1.25-.35 1.05-.6 2.3-.45.2-1.05.8Q0 27.1 0 30.35q0 .05.2 2.2.45 1.6 1.75 2-.05.1-.05.2-.1.75.05 1.5.1.7.2 3.5Q2.2 42.6 4.7 48q3.25 7.65 10.6 11.85 7.35 4.2 21.65 4.2 13.35 0 23-8.15 1.85-1.45 3.45-2.5 7.45-5.25 15.15 0 1.55 1.05 3.4 2.5 9.65 8.15 25 7.95 15.35-.25 25.9-9.65 7.95-7.15 8.45-16.55 0-.7-.05-1.4-.05-.95-.15-1.8.95-.2 1.2-2.3V29.7q0-.45-.1-1.7-.15-1.4-.3-1.75-.9-.95-2.1-1.6-.1-.05-.15-.05-.05-.2-.15-.4M100.7 8.5q9.55 0 16.3 6.75t6.75 16.3q0 9.55-6.75 16.3t-16.3 6.75q-9.55 0-16.3-6.75t-6.75-16.3q0-9.55 6.75-16.3t16.3-6.75m-42.95 6.75Q64.5 22 64.5 31.55t-6.75 16.3Q51 54.6 41.45 54.6t-16.3-6.75q-6.75-6.75-6.75-16.3t6.75-16.3Q31.9 8.5 41.45 8.5t16.3 6.75z"
                    id="AccGla025__Symbol_113_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M111.15 8.85Q105.3 3 97.85 1.05 94 0 89.7 0q-1.15 0-2.25.1-7.4.45-13.5 4.25-1.4.9-2.75 1.95-.95.75-1.9 1.65-.5.45-.95.9-1.15 1.15-2.2 2.4-.2.3-.4.55-5.95 3.2-11.5 0-.2-.25-.4-.55-1.05-1.25-2.2-2.4-.65-.65-1.35-1.3-.75-.65-1.5-1.25-1.35-1.05-2.75-1.95Q39.95.55 32.55.1 31.45 0 30.3 0 26 0 22.15 1.05 14.7 3 8.85 8.85 0 17.75 0 30.25q0 12.6 8.85 21.45 8.9 8.9 21.45 8.9 5.45 0 10.2-1.7 1.3-.45 2.55-1 3.9-1.9 7.3-4.95.65-.6 1.3-1.25 1.4-1.4 2.6-2.9 5.1-5.3 11.5 0 1.2 1.5 2.6 2.9.65.65 1.35 1.25 3.35 3.05 7.25 4.95 1.25.55 2.55 1 4.75 1.7 10.2 1.7 12.55 0 21.45-8.9Q120 42.85 120 30.25q0-12.5-8.85-21.4m-1.95 2.45q8.05 8.1 8.05 19.45 0 11.45-8.05 19.5T89.75 58.3q-9.25 0-16.3-5.35-1.6-1.2-3.1-2.7-.75-.75-1.4-1.5-.8-.9-1.45-1.8-.25-.3-.45-.6-2.6-3.65-3.75-7.95-3.25-1.85-6.55 0-1.2 4.3-3.8 7.95l-.4.6q-.65.85-1.4 1.7-.7.8-1.5 1.6-8.05 8.05-19.4 8.05-11.4 0-19.45-8.05-8.05-8.05-8.05-19.5 0-11.35 8.05-19.45 8.05-8.05 19.45-8.05 11.35 0 19.4 8.05l.3.3q3.8 3.9 5.75 8.55 4.75 1.9 8.6 0 1.9-4.55 5.55-8.35l.2-.2.3-.3q.05 0 .05-.05 8.05-8 19.35-8 11.4 0 19.45 8.05z"
                    id="AccGla025__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M75.25 11.7q1.05-1.25 2.2-2.4.45-.45.95-.9-9.35 5.7-19-.4.7.65 1.35 1.3 1.15 1.15 2.2 2.4.2.3.4.55 5.55 3.2 11.5 0 .2-.25.4-.55m62.4 12.5q-3.1-8.3-10.15-14.85Q117.4 0 103.1 0q-3.4 0-6.55.55 1.1-.1 2.25-.1 4.3 0 8.15 1.05 7.45 1.95 13.3 7.8 8.85 8.9 8.85 21.4 0 12.6-8.85 21.45-8.9 8.9-21.45 8.9-5.45 0-10.2-1.7-1.3-.45-2.55-1-3.9-1.9-7.25-4.95-.7-.6-1.35-1.25-1.4-1.4-2.6-2.9-6.4-5.3-11.5 0-1.2 1.5-2.6 2.9-.65.65-1.3 1.25-3.4 3.05-7.3 4.95-1.25.55-2.55 1-4.75 1.7-10.2 1.7-12.55 0-21.45-8.9Q9.1 43.3 9.1 30.7q0-12.5 8.85-21.4 5.85-5.85 13.3-7.8Q35.1.45 39.4.45q1.15 0 2.25.1Q38.5 0 35.1 0 20.8 0 10.7 9.35q-2.25 2.1-4 4.4-3.2 4.05-4.2 6.75-.25.6-.4 1.25-.35 1.1-.65 2.35-.3 1-.5 2.15-.1.35-.1.7-.05-.05-.05-.1-.4 3.65-.75 7.9-.1.75.05 1.5.1.7.2 3.5Q.35 42.6 2.85 48q3.25 7.65 10.6 11.85 7.35 4.2 21.65 4.2 13.35 0 23-8.15 1.85-1.45 3.45-2.5 7.45-5.25 15.15 0 1.55 1.05 3.4 2.5 9.65 8.15 25 7.95 15.35-.25 25.9-9.65 7.95-7.15 8.45-16.55 0-.7-.05-1.4-.05-.95-.15-1.8-.1-2.55-.35-4.3-.3-1.8-.4-2.45-.05-.7.25 1.3-.3-2.35-.35-2.65-.05-.05-.05-.1-.3-1.05-.7-2.05m-21.5 34.45q17.15-10.9 16.5-34.9 5.7 24.6-16.5 34.9m-94.05 0Q-.1 48.35 5.6 23.75q-.65 24 16.5 34.9z"
                    id="AccGla025__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M52.95 16.9Q51 12.25 47.2 8.35l-.3-.3Q38.85 0 27.5 0 16.1 0 8.05 8.05 0 16.15 0 27.5 0 38.95 8.05 47t19.45 8.05q11.35 0 19.4-8.05.8-.8 1.5-1.6.75-.85 1.4-1.7l.4-.6q2.6-3.65 3.8-7.95.8-2.9.95-6.1.05-.75.05-1.55 0-2.3-.3-4.45-.5-3.2-1.75-6.15m-9.35-3.15q2.4 2.4 3.9 5.15.75 2 1.1 4.15.25 1.65.25 3.45 0 1.3-.1 2.55-.4 3.25-1.65 6.1-1.55 3.5-4.45 6.35-6.15 6.2-14.9 6.2-8.8 0-15.05-6.2-6.15-6.2-6.15-15 0-2.65.55-5.05 1.6-4.2 5.1-7.7Q18.7 7.2 27.9 7.2q9.15 0 15.7 6.55M106.45 47q8.05-8.05 8.05-19.5 0-11.35-8.05-19.45Q98.4 0 87 0 75.7 0 67.65 8q0 .05-.05.05l-.3.3-.2.2q-3.65 3.8-5.55 8.35-1.25 2.95-1.7 6.15-.35 2.15-.35 4.45 0 .8.05 1.55.15 3.2 1 6.1 1.15 4.3 3.75 7.95.2.3.45.6.65.9 1.45 1.8.65.75 1.4 1.5 1.5 1.5 3.1 2.7 7.05 5.35 16.3 5.35 11.4 0 19.45-8.05m.05-28.1l.4.7q1.05 3.25 1.05 6.9 0 8.8-6.15 15-6.25 6.2-15.05 6.2-7.55 0-13.15-4.6-.9-.75-1.75-1.6-5.3-5.3-6.05-12.45-.15-1.25-.15-2.55 0-3.05.75-5.8.85-2 2.15-3.8 1.15-1.65 2.65-3.15Q77.7 7.2 86.9 7.2q9.15 0 15.7 6.55 2.4 2.4 3.9 5.15z"
                    id="AccGla025__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M52.95 16.9Q51 12.25 47.2 8.35l-.3-.3Q38.85 0 27.5 0 16.1 0 8.05 8.05 0 16.15 0 27.5 0 38.95 8.05 47t19.45 8.05q11.35 0 19.4-8.05.8-.8 1.5-1.6.75-.85 1.4-1.7l.4-.6q2.6-3.65 3.8-7.95.8-2.9.95-6.1.05-.75.05-1.55 0-2.3-.3-4.45-.5-3.2-1.75-6.15M45.6 8.05q4 4 5.9 8.85 1.1 2.9 1.5 6.15.2 1.65.2 3.4v.65q0 1-.1 1.95-.3 3.2-1.3 6.1-1.5 4.35-4.6 7.95l-.5.6-1.1 1.1q-7.6 7.6-18.35 7.6-10.75 0-18.35-7.6-7.4-7.35-7.6-17.7v-.65q0-10.8 7.6-18.4Q16.5.45 27.25.45 38 .45 45.6 8.05M106.45 47q8.05-8.05 8.05-19.5 0-11.35-8.05-19.45Q98.4 0 87 0 75.7 0 67.65 8q0 .05-.05.05l-.3.3-.2.2q-3.65 3.8-5.55 8.35-1.25 2.95-1.7 6.15-.35 2.15-.35 4.45 0 .8.05 1.55.15 3.2 1 6.1 1.15 4.3 3.75 7.95.2.3.45.6.65.9 1.45 1.8.65.75 1.4 1.5 1.5 1.5 3.1 2.7 7.05 5.35 16.3 5.35 11.4 0 19.45-8.05m6.75-19.9q-.2 10.35-7.6 17.7-7.6 7.6-18.35 7.6-10.75 0-18.35-7.6-.55-.55-1.05-1.1l-.5-.6q-3.15-3.6-4.65-7.95-1-2.9-1.3-6.1-.1-.95-.1-1.95v-.65q0-1.75.2-3.4.4-3.25 1.55-6.15 1.75-4.6 5.35-8.35l.2-.2.3-.3q.05 0 .05-.05Q76.55.45 87.25.45q10.75 0 18.35 7.6 7.6 7.6 7.6 18.4v.65z"
                    id="AccGla025__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M11.05 0q-.2 0-.3.1-1.15.8-2.2 1.75-.35.1-.55.45-.4.25-.8.65-2.45 2.3-4.05 5.15-.55.9-.95 1.8Q.45 13.4.1 17.45q-.1.9-.1 1.85 0 9.05 6.4 15.75.35.45.8.8 3.55 3.25 7.65 4.95-.1-.15-.15-.25-3.3-1.65-6.05-4.4Q3.9 31 2.4 24.75q-.6-2.55-.6-5.35v-.35-1.7-.45q.1-1.2.4-2.4.1-.25.1-.5.4-2.05 1.15-3.95.65-1.55 1.5-3Q5.4 6.2 6 5.25q1.1-1.5 2.55-2.95 0-.1.1-.25Q9.8.9 11.05 0M45.4 8.2q.15.4.35.8.2.65.65 1.25.4 1.3.85 2.7.15.55.25 1.15.6 2.6.6 5.45v.1q0 1.95-.35 3.85-.4 3.25-1.8 6.4l-1 2.5q-.05.3-.15.7 4.75-6 4.75-13.8v-.15q0-1.2-.1-2.15-.1-1.45-.45-2.75-.8-3.5-2.75-6.55l-1.7-2.3q.75 2.1.85 2.8m19.55-2.8l-1.7 2.3q-1.95 3.05-2.75 6.55-.35 1.3-.45 2.75-.1.95-.1 2.15v.15q0 7.8 4.75 13.8-.1-.4-.15-.7l-1-2.5q-1.4-3.15-1.8-6.4-.35-1.9-.35-3.85v-.1q0-2.85.6-5.45.1-.6.25-1.15.45-1.4.85-2.7.45-.6.65-1.25.2-.4.35-.8.1-.7.85-2.8m41.4 2.7q-1.6-2.85-4.05-5.15-.4-.4-.8-.65-.2-.35-.55-.45Q99.9.9 98.75.1q-.1-.1-.3-.1 1.25.9 2.4 2.05.1.15.1.25 1.45 1.45 2.55 2.95.6.95 1.05 1.8.85 1.45 1.5 3 .75 1.9 1.15 3.95 0 .25.1.5.3 1.2.4 2.4v2.5q0 2.8-.6 5.35-1.5 6.25-6.25 11.4-2.75 2.75-6.05 4.4-.05.1-.15.25 4.1-1.7 7.65-4.95.45-.35.8-.8 6.4-6.7 6.4-15.75 0-.95-.1-1.85-.35-4.05-2.1-7.55-.4-.9-.95-1.8z"
                    id="AccGla025__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M36.5 8.4q2.4 2.4 3.9 5.15-.4-1-.85-2-1.5-2.9-4-5.4Q29.4 0 20.65 0 11.85 0 5.6 6.15 1.3 10.5 0 16.1q1.6-4.2 5.1-7.7 6.5-6.55 15.7-6.55 9.15 0 15.7 6.55m63.3 5.85q-1.55-4.45-5.1-8.1Q88.45 0 79.65 0 73.9 0 69.3 2.65q-2.45 1.4-4.55 3.5-2.5 2.5-3.95 5.4-.95 1.8-1.5 3.8.85-2 2.15-3.8Q62.6 9.9 64.1 8.4q6.5-6.55 15.7-6.55 9.15 0 15.7 6.55 2.4 2.4 3.9 5.15l.4.7z"
                    id="AccGla025__Symbol_9_0_Layer0_0_FILL"
                />
                <g id="AccGla025__Symbol_61_0_Layer0_0_FILL">
                    <path d="M3.4 21.8q11.35 3.8 24.3 4.45.35-4.25.75-7.9 0 .05.05.1 0-.35.1-.7.2-1.15.5-2.15-13.65-.15-26.3-5.25-2.35-1.15-2.8.9v6.7q-.3 2.8 3.4 3.85M193.45 13q-.15-2.95-2.5-2.1-12.75 5.05-25.65 4.8.4 1 .7 2.05 0 .05.05.1.05.3.35 2.65-.3-2-.25-1.3.1.65.4 2.45.25 1.75.35 4.3 2.3-.25 6.3-.55 7.05-.5 16.65-3.3 3.2-1.05 3.6-3.75V13z" />
                    <path
                        fillOpacity={0.651}
                        d="M90.3 23.05q0-9.55-6.75-16.3T67.25 0q-9.55 0-16.3 6.75t-6.75 16.3q0 9.55 6.75 16.3t16.3 6.75q9.55 0 16.3-6.75t6.75-16.3m59.25 0q0-9.55-6.75-16.3T126.5 0q-9.55 0-16.3 6.75t-6.75 16.3q0 9.55 6.75 16.3t16.3 6.75q9.55 0 16.3-6.75t6.75-16.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccGla025__Symbol_113_0_Layer0_0_FILL"
                transform="translate(121.6 131.55)"
                id="AccGla025__col2n"
            />
            <use
                xlinkHref="#AccGla025__Symbol_88_0_Layer0_0_FILL"
                transform="translate(132.55 132)"
                id="AccGla025__col2l"
            />
            <use
                xlinkHref="#AccGla025__Symbol_67_0_Layer0_0_FILL"
                transform="translate(123.45 131.55)"
                id="AccGla025__col2d"
            />
            <use
                xlinkHref="#AccGla025__Symbol_61_0_Layer0_0_FILL"
                transform="translate(95.8 140.05)"
            />
            <use
                xlinkHref="#AccGla025__Symbol_48_0_Layer0_0_FILL"
                transform="translate(135.3 135.25)"
                id="AccGla025__col1n"
            />
            <use
                xlinkHref="#AccGla025__Symbol_17_0_Layer0_0_FILL"
                transform="translate(135.3 135.25)"
                id="AccGla025__col1d"
            />
            <use
                xlinkHref="#AccGla025__Symbol_14_0_Layer0_0_FILL"
                transform="translate(137.75 142.2)"
                id="AccGla025__col1l"
            />
            <use
                xlinkHref="#AccGla025__Symbol_9_0_Layer0_0_FILL"
                transform="translate(142.4 140.6)"
                id="AccGla025__col1s"
            />
        </g></g>
    )
}

export default AccGla025
