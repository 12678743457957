import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF8E8E",
    "col1n": "#FFC4C4",
    "col2n": "#FFFFFF"
}

function ShiSho017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M124.4.2V.1q-.05.05-.15.1l-.6.4-1.6 1.15q-21.1 14.6-47.4 16.55l-1.1.05q-3.2.2-6.55.2h-1.2q-3.35 0-6.55-.2l-1.1-.05q-26.3-1.95-47.4-16.55L9.15.6q-.4-.35-.8-.6 2.05 6.2 2.5 14.3v.05q.2 3.9 0 8.25-.15 3.45-.55 7.2-.05.4-.1.85l-2.7 14q-.55 2.7-1.1 5.45L2.95 64.4q-1 3.9-1.95 7.8l-.95 3.6v.25L0 76.1h.2q.25.1.5.15H.85q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75q-.05-.45-.1-.85-.4-3.75-.55-7.2-.2-4.3.05-8.15.4-8.1 2.5-14.25z"
                    id="ShiSho017__Symbol_25_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M116.05.2V.1q-.05.05-.15.1l-.6.4-1.6 1.15Q92.6 16.35 66.3 18.3l-1.1.05q-3.2.2-6.55.2h-1.2q-3.35 0-6.55-.2l-1.1-.05Q23.5 16.35 2.4 1.75L.8.6Q.4.25 0 0q2.05 6.2 2.5 14.3v.05q1.6.5 3.5.5 3.3 0 6-1.6.4-.2.8-.5.05 0 .05-.05.7-.55 1.45-1.25l.05-.05q-.15.85-.3 1.8v.75l.15 1.5q.25 2.35 1.35 4.3.2.25.35.55.05.05.1.15.65.9 1.5 1.8 1.7 1.7 3.75 2.5 2.05.9 4.5.9 3.3 0 6-1.55 1.2-.8 2.25-1.85.25-.25.45-.5l.3-.3q.6-.7 1.05-1.45.2-.3.35-.6.2 2.45 1.45 4.6.75 1.3 1.9 2.45.85.85 1.7 1.45l.1.1q1 .6 1.95 1 2.1.9 4.5.9 3.3 0 6-1.6 1.2-.75 2.3-1.85.8-.8 1.45-1.75.15-.15.25-.3.05-.1.1-.15.1-.2.25-.4l.2.4q.05.05.1.15.1.15.25.3.65.95 1.45 1.75 1.1 1.1 2.3 1.85 2.7 1.6 6 1.6 2.4 0 4.5-.9.95-.4 1.95-1l.1-.1q.9-.6 1.7-1.45 1.15-1.15 1.9-2.45 1.25-2.15 1.45-4.6.15.3.35.6.45.75 1.05 1.45l.3.3q.2.25.45.5 1.05 1.05 2.25 1.85 2.7 1.55 6 1.55 2.45 0 4.5-.9 2.05-.8 3.75-2.5.85-.9 1.5-1.8.05-.1.1-.15.15-.3.35-.55 1.1-1.95 1.35-4.3l.15-1.5v-.2-.55q-.15-.95-.3-1.8l.05.05q.75.7 1.45 1.25 0 .05.05.05.4.3.8.5 2.7 1.6 6 1.6 1.7 0 3.25-.4h.15q.4-8.1 2.5-14.25z"
                    id="ShiSho017__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M123.35 5.95q0-3.3-1.65-5.9l-.05-.05-1.8 1.35q-1.35 1.05-2.8 2.1L115.5 4.6q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05Q28.2 18.7 7.8 4.6L6.25 3.45Q4.8 2.4 3.45 1.35L1.7.05V.1Q0 2.7 0 5.95q0 4.7 3.35 8 1.2 1.2 2.6 2 .35.2.75.4h.05q.05 0 .1.05 2.05.85 4.45.85 3.2 0 5.8-1.55.4-.2.75-.45.05 0 .05-.05.7-.55 1.4-1.25l.05-.05q-.15.85-.25 1.75v.75l.1 1.45q.25 2.25 1.35 4.15.15.25.3.55.05.05.1.15.65.85 1.45 1.7 1.65 1.65 3.65 2.45 2 .85 4.35.85 3.2 0 5.8-1.5 1.15-.75 2.2-1.8.2-.2.4-.45l.3-.3q.6-.7 1-1.4.2-.3.35-.6.2 2.4 1.4 4.45.75 1.25 1.85 2.4.8.8 1.65 1.4l.1.1q.95.55 1.9.95 2 .85 4.35.85 3.2 0 5.8-1.55 1.15-.7 2.2-1.75.8-.8 1.4-1.7.15-.15.25-.3.05-.1.1-.15.1-.2.25-.4l.2.4q.05.05.1.15.1.15.25.3.6.9 1.4 1.7 1.05 1.05 2.2 1.75 2.6 1.55 5.8 1.55 2.35 0 4.35-.85.95-.4 1.9-.95l.1-.1q.85-.6 1.65-1.4 1.1-1.15 1.85-2.4 1.2-2.05 1.4-4.45.15.3.35.6.4.7 1 1.4l.3.3q.2.25.4.45 1.05 1.05 2.2 1.8 2.6 1.5 5.8 1.5 2.35 0 4.35-.85 2-.8 3.65-2.45.8-.85 1.45-1.7.05-.1.1-.15.15-.3.3-.55 1.1-1.9 1.35-4.15l.1-1.45v-.2-.55q-.1-.9-.25-1.75l.05.05q.7.7 1.4 1.25 0 .05.05.05.35.25.75.45 2.6 1.55 5.8 1.55 2.4 0 4.45-.85.05-.05.1-.05h.05q.35-.15.7-.35 1.4-.8 2.65-2.05 3.35-3.3 3.35-8z"
                    id="ShiSho017__Symbol_76_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho017__Symbol_25_0_Layer0_0_FILL"
                transform="translate(126.1 233.4)"
                id="ShiSho017__col1n"
            />
            <use
                xlinkHref="#ShiSho017__Symbol_54_0_Layer0_0_FILL"
                transform="translate(134.45 233.4)"
                id="ShiSho017__col1d"
            />
            <use
                xlinkHref="#ShiSho017__Symbol_76_0_Layer0_0_FILL"
                transform="translate(130.85 228.65)"
                id="ShiSho017__col2n"
            />
        </g></g>
    )
}

export default ShiSho017
