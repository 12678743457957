import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#747474",
    "col2n": "#999999"
}

function ShiSpe056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M131.45 6h-.05L121.25.15q-3.1-.45-6.05.55-1.05.35-2.1.9-1.65.9-3.3 2.25-.85.75-1.7 1.65h-.05l-.4.5q-6.15 7.5-14.7 11.5-.8.4-1.7.75-3.4 1.45-7.15 2.35-8.85 2.35-17.7 2.25h-1q-.4-.05-.85-.05v.05q-7.75-.2-15.45-2.25-3.75-.9-7.15-2.35-.9-.35-1.7-.75-8.55-4-14.7-11.5l-.4-.5h-.05q-.85-.9-1.7-1.65-1.65-1.35-3.3-2.25-1.05-.55-2.1-.9-2.95-1-6.05-.55L1.85 6h-.1Q2.9 7.95 3.9 9.9l.3.6q.3.8.55 1.65.5 1.65.95 3.35Q8.85 28.05 7 42.55L5.95 49v.1l-.05.15q.15 0 .3.05l3 4-5.45 4.4L3 60.6l2.75 4L1 68.65l-1 3.8q8.25 1.95 16.1 3.45.8.15 1.65.3.55.1 1.05.2 2.45.4 4.95.75 1.1.15 2.25.35.25 0 .5.05 2 .3 4.05.55 17.2 2.25 34 2.45H66.4q17.9-.05 36.25-2.45 2.05-.25 4.05-.55 1.4-.2 2.75-.4 2.5-.35 4.95-.75.5-.1 1.05-.2.85-.15 1.65-.3 7.85-1.5 16.1-3.45l-1-3.8-5.65-3.55 3.65-4.5-.75-2.9-5.15-3.45 2.8-4.95q.1-.05.2-.05l-.05-.15V49l-1.05-6.45q-1.85-14.5 1.3-27.05.45-1.7.95-3.35.25-.85.55-1.65l.3-.6q1-1.95 2.15-3.9z"
                    id="ShiSpe056__Symbol_353_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M95.6 7.65Q95.15 1.2 87.95 0H7.65Q.45 1.2 0 7.65L.35 28.4q-.15-.05-.35-.05v3.45l.5.1.4 8.8h.15q-.1.5-.1.95-.05.4 0 .8.35 2.55 4 3.45 1.1.15 2.25.35.25 0 .5.05 2 .3 4.05.55 17.2 2.25 34 2.45H47.6q17.9-.05 36.25-2.45 2.05-.25 4.05-.55 1.4-.2 2.75-.4 4.8-1.15 3.9-5.2l.45-8.8.6-.1v-3.45q-.2 0-.4.05l.4-20.75z"
                    id="ShiSpe056__Symbol_354_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 6.45l.05.05q1.15 1.45 2.3 3 1.9 2.55 3.45 5.15l14.9 21q1.95 2.05 4.45.7l5.25-3.6q2.45-1.55 1.15-4.1L9 0 0 6.45M113.8 69.2l4.9-.5q4.7-.5 9.35-1.1l3.1-.4 2.6-.4h.1l2.25-.4-2.2-8.7-2.35.35v.15l.05.15q-1.15.15-2.35.35-1.35.15-2.75.35-3.9.5-7.8.9l-4.9.5-3 .3q-20 1.75-40.1 1.75h-1.85Q49.9 62.45 31 60.75l-3-.3-4.9-.5q-3.9-.4-7.8-.9-1.4-.2-2.75-.35-1.2-.2-2.35-.35l.05-.15v-.15L7.9 57.7l-2.2 8.7 2.25.4h.1l2.6.4 3.1.4q4.65.6 9.35 1.1l4.9.5q.85.1 1.75.2 19.5 1.8 39.1 1.85h1.85q20.7 0 41.35-1.85.9-.1 1.75-.2m0 12.4q1.9-.25 3.85-.55.5-.05 1.05-.15 5.75-.85 11.45-1.95 1.75-.3 3.45-.65 1.45-.3 2.9-.55h.05q0-.05.05-.05l2.25-.35-2.05-8-2.25.35h-.05q-1.4.2-2.7.45-1.6.2-3.2.45l-2.1.3q-3.9.7-7.8 1.25l-4.9.7q-.6.05-1.25.15-20.85 2.7-41.85 2.65h-1.85q-19.9-.1-39.6-2.65-.65-.1-1.25-.15l-4.9-.7q-3.9-.55-7.8-1.25l-2.1-.3q-1.6-.25-3.2-.45-1.3-.25-2.7-.45h-.05L5 69.35l-2.05 8 2.25.35q.05 0 .05.05h.05q1.45.25 2.9.55 1.7.35 3.45.65 5.7 1.1 11.45 1.95.55.1 1.05.15 1.95.3 3.85.55 20.35 2.7 40.85 2.8h1.85q21.65.1 43.1-2.8m25.65-72.1q1.15-1.55 2.3-3l.05-.05-9-6.45-22.55 28.65q-1.3 2.55 1.15 4.1l5.25 3.6q2.5 1.35 4.45-.7l14.9-21q1.55-2.6 3.45-5.15z"
                    id="ShiSpe056__Symbol_355_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M2.65 66L.6 74l2.25.35q.05 0 .05.05h.05q1.45.25 2.9.55l1.8-8.15q-1.3-.25-2.7-.45H4.9L2.65 66M7.9 54.85v-.15l-2.35-.35-2.2 8.7 2.25.4h.1l2.6.4 1.9-8.5Q9 55.15 7.85 55l.05-.15m18.25 14.7q-.238-.028-.5-.05-.375-.054-.75-.1-2.075-.304-4.15-.6-3.9-.55-7.8-1.25l-2.1-.3L9.3 75.6q5.7 1.1 11.45 1.95.55.1 1.05.15 1.565.24 3.1.45.213.022.4.05l.85-8.65m-.5-12.45q-.375-.038-.75-.1-2.075-.188-4.15-.4-3.9-.4-7.8-.9l-1.55 8.55q4.65.6 9.35 1.1 2.075.212 4.15.4.375.062.75.1l.85.1.9-8.7q-.88-.063-1.75-.15M9.25 0L0 6.15Q1.9 8.7 3.45 11.3l4.2 5.9 9.8-6.75L9.25 0M19.7 13.25L9.55 19.9l8.8 12.4q1.95 2.05 4.45.7l5.25-3.6q2.45-1.55 1.15-4.1l-9.5-12.05m112.5 53.1h-.05q-1.4.2-2.7.45l1.8 8.15q1.45-.3 2.9-.55h.05q0-.05.05-.05l2.25-.35-2.05-8-2.25.35M129.25 55q-1.15.15-2.35.35l1.9 8.5 2.6-.4h.1l2.25-.4-2.2-8.7-2.35.35v.15l.05.15M112.5 69.35l-1.05.15q-.23.02-.5.05l.95 8.65q.3-.042.6-.1 1.388-.183 2.8-.4.5-.05 1.05-.15 5.75-.85 11.45-1.95l-1.55-8.35-2.1.3q-3.9.7-7.8 1.25l-3.85.55m0-12.35q-.525.046-1.05.1-.888.089-1.8.15l.95 8.7.85-.1q.525-.054 1.05-.1 1.925-.204 3.85-.4 4.7-.5 9.35-1.1l-1.55-8.55q-3.9.5-7.8.9-1.925.196-3.85.4m4.9-43.75l-9.5 12.05q-1.3 2.55 1.15 4.1l5.25 3.6q2.5 1.35 4.45-.7l8.8-12.4-10.15-6.65m12.05 3.95l4.2-5.9q1.55-2.6 3.45-5.15L127.85 0l-8.2 10.45 9.8 6.75z"
                    id="ShiSpe056__Symbol_356_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe056__Symbol_353_0_Layer0_0_FILL"
                transform="translate(125.9 236.95)"
                id="ShiSpe056__col1n"
            />
            <use
                xlinkHref="#ShiSpe056__Symbol_354_0_Layer0_0_FILL"
                transform="translate(144.7 268.2)"
                id="ShiSpe056__col1d"
            />
            <use
                xlinkHref="#ShiSpe056__Symbol_355_0_Layer0_0_FILL"
                transform="translate(121.6 227.85)"
                id="ShiSpe056__col2n"
            />
            <use
                xlinkHref="#ShiSpe056__Symbol_356_0_Layer0_0_FILL"
                transform="translate(123.95 231.2)"
                id="ShiSpe056__col2d"
            />
        </g></g>
    )
}

export default ShiSpe056
