import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#0000BB",
    "col1n": "#0000FF",
    "col2d": "#FFE000",
    "col2n": "#FFFF00"
}

function ShiSpe101({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M127.5 48.65h-.05l-.55-2.45q-1.5-6.15-2.25-10.8-.45-.45-3-2-2.6-1.6-6.8-2.75l-2.5-.7v-.05l-.55-.15L109.15 0q-8.45 4.2-17.6 6.6-3.95 1-8 1.7L81.4 26.95v.2H51.5v-.2l-2.05-18.6q-4.15-.7-8.1-1.75Q32.15 4.25 23.8.05l-2.7 29.7-.55.15v.05l-2.5.7q-3.95 1.1-6.7 2.75-2.8 1.65-3.35 2.1-.75 4.7-2.25 10.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H.85q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7z"
                    id="ShiSpe101__Symbol_260_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M31.1.35V3.45q-.2 9.5-4.4 16.45-4.45 7.3-10.8 7.3-6.35 0-10.75-7.3-3.3-5.35-4.1-12.35-.1-.5-.1-.8Q.7 4.7.7 2.65V2.6l-.55.15v.05Q0 3.6 0 4.3q0 2.2.25 4.35 0 .35.15.8.85 7 4.3 12.35 4.6 7.3 11.2 7.3t11.25-7.3q4.45-6.9 4.65-16.4v-.75-.35q0-1.85-.1-3.7-.05-.2-.05-.45Q31.6.1 31.6 0h-.5v.35m60.3 2.3q0 2.05-.25 4.1 0 .3-.1.8-.8 7-4.1 12.35-4.4 7.3-10.75 7.3t-10.8-7.3Q61.2 12.95 61 3.45v-.7-.3V.6.35v-.2V0h-.5v.15q-.05.25-.1.45-.1 1.85-.1 3.7v1.1q.2 9.5 4.65 16.4 4.65 7.3 11.25 7.3t11.2-7.3q3.45-5.35 4.3-12.35.15-.45.15-.8.25-2.15.25-4.35 0-.7-.15-1.5v-.05l-.55-.15v.05z"
                    id="ShiSpe101__Symbol_259_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M16.6 8.5q-.2-3.4-2.5-5.9Q11.75 0 8.3 0h-.4Q4.75.15 2.4 2.6 0 5.3 0 9.05q0 2.8 1.35 4.95.45.75 1.05 1.4 2.35 2.55 5.5 2.7h.4q3.45 0 5.8-2.7 2.5-2.6 2.5-6.35V8.5m60.95.55q0-3.75-2.4-6.45Q72.8.15 69.65 0h-.4q-3.45 0-5.8 2.6-2.3 2.5-2.5 5.9v.55q0 3.75 2.5 6.35 2.35 2.7 5.8 2.7h.4q3.15-.15 5.5-2.7.6-.65 1.05-1.4 1.35-2.15 1.35-4.95z"
                    id="ShiSpe101__Symbol_257_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M5.8 1.3Q5.55.95 5.35.75q-.6-.5-1-.75Q6 2.1 6 4.9q0 2.85-1.65 4.9-1.6 2-4 2H0q.35.25.8.4.6.2 1.4.2 2.15 0 3.6-1.95 1.5-1.8 1.5-4.55 0-2.7-1.5-4.6m62.45 4.6q0-2.7-1.5-4.6-.25-.35-.45-.55-.6-.5-1-.75 1.65 2.1 1.65 4.9 0 2.85-1.65 4.9-1.6 2-4 2h-.35q.35.25.8.4.6.2 1.4.2 2.15 0 3.6-1.95 1.5-1.8 1.5-4.55z"
                    id="ShiSpe101__Symbol_258_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe101__Symbol_260_0_Layer0_0_FILL"
                transform="translate(126.1 239.85)"
                id="ShiSpe101__col1n"
            />
            <use
                xlinkHref="#ShiSpe101__Symbol_259_0_Layer0_0_FILL"
                transform="translate(146.5 267)"
                id="ShiSpe101__col1d"
            />
            <use
                xlinkHref="#ShiSpe101__Symbol_257_0_Layer0_0_FILL"
                transform="translate(153.7 267.95)"
                id="ShiSpe101__col2n"
            />
            <use
                xlinkHref="#ShiSpe101__Symbol_258_0_Layer0_0_FILL"
                transform="translate(160.2 271.35)"
                id="ShiSpe101__col2d"
            />
        </g></g>
    )
}

export default ShiSpe101
