import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.3 19.25l-3.75-2H41.9l-8.95-4.35-1.7.75L22.6 9.2l-4.3-.45-2.1-2.55-3.8-1.45q-1.25-.1-2.7-.65-2.35-.85-4.15-2.25 0 .1-.05.25L0 0l5.05 3.45q-.05.15-.15.3h2.15v.95q.35.25.65.6h.1l11.05 7.55 1.6-.2.05.15 3.95 2.85 2.3-.5 6.05 3.8 6.85 1.85 2 3.1q4.2.15 7.65 1.55v-6.2M94.5 7.7l9.6-6.6-10.05 2.6-5.25 1.95v1.6h-4.3l-7.15 2.7-.25 1.6-2.85.15-9.15 3.65-3.35-.7-4.55 3.75-1.55-1.05-1.1.45v7.45l2.65-2-.05-.1 4-.45 2.8-3 1.7.4 3.5-2.7h.05l1.6.6 6.35-1.4 2.75-3.75 3.2.4L93 7.4l1.5.3z"
                    id="FacBro017__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro017__Symbol_17_0_Layer0_0_FILL"
                transform="translate(140.45 116)"
                id="FacBro017__col1n"
            />
        </g></g>
    )
}

export default FacBro017
