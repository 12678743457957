import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.9.05q-4.35.4-8.7 1.85Q7.95 3.55 0 11.9q10.25-6.6 15.45-7.2 3.5-.5 4.55-.3 4.65.5 5.7.6 11.2 1.65 15.45 2.95l-2.5-5.15Q25.05-.25 20.9.05m80.6 11.85q-7.9-8.35-12.25-10Q85 .45 80.75.05 76.4-.25 62.8 2.8l-2.4 5.15Q64.75 6.65 75.85 5l5.65-.6q1.1-.2 4.45.3 5.25.6 15.55 7.2z"
                    id="FacBro020__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro020__Symbol_20_0_Layer0_0_FILL"
                transform="translate(141.75 122.15)"
                id="FacBro020__col1n"
            />
        </g></g>
    )
}

export default FacBro020
