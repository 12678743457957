import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1l": "#FFFFFF",
    "col1n": "#EEEEEE"
}

function AccJew049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M9.3 30.45q0-1.95-1.35-3.3-.6-.6-1.3-.9-.15-.1-.3-.15-.3-.1-.6-.15-.2-.05-.45-.1-.3-.05-.65-.05H4.4q-.15 0-.3.05-.3 0-.6.1l-.4.1q-.95.35-1.75 1.1Q0 28.5 0 30.45q0 1.9 1.35 3.3 1.4 1.35 3.3 1.35 1.95 0 3.3-1.35 1.35-1.4 1.35-3.3m-3.55-17.4q-.2-.05-.45-.1-.3-.05-.65-.05H4.4q-.15 0-.3.05-.3 0-.6.1l-.4.1q-.95.35-1.75 1.1Q0 15.6 0 17.55q0 1.9 1.35 3.3 1.35 1.3 3.15 1.35h.35q1.8-.05 3.1-1.35 1.35-1.4 1.35-3.3 0-1.95-1.35-3.3-.6-.6-1.3-.9-.15-.1-.3-.15-.3-.1-.6-.15m.9-12.6Q6.6.4 6.55.4 6.5.35 6.45.35 6.4.3 6.35.3q-.3-.1-.6-.15Q5.25 0 4.65 0q-.2 0-.4.05H3.9Q3.8.1 3.7.1t-.2.05q-1.2.3-2.15 1.2Q0 2.7 0 4.65q0 1.9 1.35 3.3 1.25 1.2 2.9 1.35h1q.15-.05.3-.05Q6.9 9 7.95 7.95q1.35-1.4 1.35-3.3 0-1.95-1.35-3.3-.6-.6-1.3-.9z"
                    id="AccJew049__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.1 30.05q-.15 0-.3.05H.9v1.55q-.05 0-.05.05-.65.15-.8.5-.05.15-.05.85 0 .05.05.8.3-.1.6-.1.15-.05.3-.05l-.05-.65q0-.4.25-.6h.75q.25.05.4.6v.8q.3.05.6.15.15.05.3.15 0-1.2-.15-1.55-.25-.8-.95-1v-1.55M1.2 17.2H.9v1.55q-.05 0-.05.05-.65.15-.8.5-.05.15-.05.85 0 .05.05.8.3-.1.6-.1.15-.05.3-.05l-.05-.65q0-.4.25-.6h.75q.25.05.4.6v.8q.3.05.6.15.15.05.3.15 0-1.2-.15-1.55-.25-.8-.95-1v-1.55q-.15 0-.3.05H1.2M2.1.5Q1.4-.5.9.5v5.35q-.05 0-.05.05-.65.15-.8.5-.05.15-.05.85 0 .05.05.8.1-.05.2-.05t.2-.05H.8q.05-.05.15-.05L.9 7.25q0-.4.25-.6h.75q.25.05.4.6v.8q.3.05.6.15.05 0 .1.05.05 0 .1.05.05 0 .1.05 0-1.2-.15-1.55-.25-.8-.95-1V.5z"
                    id="AccJew049__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M3.65 26.3q-.6-.6-1.5-.6t-1.55.6q-.6.65-.6 1.55t.6 1.5q.65.65 1.55.65t1.5-.65q.65-.6.65-1.5t-.65-1.55m0-9.85q.65-.6.65-1.5t-.65-1.55q-.6-.6-1.5-.6t-1.55.6q-.6.65-.6 1.55t.6 1.5q.65.65 1.55.65t1.5-.65m.65-14.3q0-.9-.65-1.55-.6-.6-1.5-.6T.6.6Q0 1.25 0 2.15t.6 1.5q.45.45 1.05.6.15.05.35.05h.15q.4 0 .7-.1.45-.15.8-.55.65-.6.65-1.5z"
                    id="AccJew049__Symbol_120_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew049__Symbol_122_0_Layer0_0_FILL"
                transform="translate(280.2 184.8)"
                id="AccJew049__col1n"
            />
            <use
                xlinkHref="#AccJew049__Symbol_121_0_Layer0_0_FILL"
                transform="translate(283.65 176.9)"
                id="AccJew049__col1d"
            />
            <use
                xlinkHref="#AccJew049__Symbol_120_0_Layer0_0_FILL"
                transform="translate(282.9 188.8)"
                id="AccJew049__col1l"
            />
        </g></g>
    )
}

export default AccJew049
