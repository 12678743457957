import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FFFFFF"
}

function HaiSpe046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M176.15 64.85q1.2.6 2.45 1.25 20.05 11.05 34.3 36.1 5.25 11.3 9.8 26.1.8 2.6 1.6 5.35 1 3.45 1.55 9.4 7.2-50.05-41.9-87.9-10.55-6.2-23.8-10.6-3.8-12.8-8.65-21.05-7.6-13.4-23.05-18.5Q93.8-6.75 51.95 9.15 18.85 22.2 25.55 44.1q7.15 15.2 5.1 35-.7 7.55-2.1 17.1-6.3 26.7-28.55 44l25-9.5-7 15.1q8.2-4.1 16.4 0 .75-6.1 1.6-11.45l.95-5.25.2-.8q4.5-14.8 9.75-26.1 14.25-25.05 34.35-36.1 1.5-.7 3.1-1.3 12.35-4.75 24.75-2.3h.15q.5.1 1.05.2 2.3.5 4.7 1.3l1 .4q15.05 6.2 29.3 0 3.55-1.3 4.35-1.5.95-.3 1.5-.4h.2q12.4-2.45 24.8 2.35z"
                    id="HaiSpe046__Symbol_178_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M61.95 4.5q-9.4 4.5-12.55 11-3.25 6.8-4.75 19.35Q43 48.55 41.4 53q-7.7 21.5-14.2 33.2-3.35 6.1-4.65 7.9-2.6 3.5-6.6 6.1-5.55 3.7-7.5 4.75-.1.05-3.6 2.35-2.5 1.65-3.8 2.1-.5.45-1.05.85l25-9.5-7 15.1q8.6-3.1 16.4 0v-1.15l-.1.9q-5.8-44.65 28.75-78.7Q79.8 22.85 100.3 15.75 90.25.05 80.8 0q-9.45-.05-18.85 4.5z"
                    id="HaiSpe046__Symbol_109_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M56.8 16.1Q52.1 6.6 44.75 3.05 37.15-.65 26.7.15q-9.4.7-18 4.8Q-.35 9.25 0 18.8q28.9-8.4 56.8-2.7z"
                    id="HaiSpe046__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe046__Symbol_178_0_Layer0_0_FILL"
                transform="translate(62.2 17)"
                id="HaiSpe046__col1n"
            />
            <use
                xlinkHref="#HaiSpe046__Symbol_109_0_Layer0_0_FILL"
                transform="translate(62.2 46.95)"
                id="HaiSpe046__col1d"
            />
            <use
                xlinkHref="#HaiSpe046__Symbol_35_0_Layer0_0_FILL"
                transform="translate(159.3 44.9)"
                id="HaiSpe046__col2n"
            />
        </g></g>
    )
}

export default HaiSpe046
