import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF98D0",
    "col1l": "#FFECF6",
    "col1n": "#FFCDE8",
    "col1s": "#FF5DB5"
}

function LegSho010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M145.25 17.5q-1.05-.25-2.05-.5-4.65-1.05-9.35-1.8-.65-.1-1.35-.2-1.45-.15-2.9-.25.1-.15.25-.25 1.7-1.65 2.95-4 1.8-3.45 1.35-6.5-.45-3-2.85-3.75-2.45-.8-5.4 1.15t-4.75 5.4q-1.85 3.45-1.35 6.5.1.35.15.65l.15.25q-6-.2-11.9.05l.15-.3q.05-.3.15-.65.5-3.05-1.35-6.5-1.8-3.45-4.75-5.4Q99.45-.55 97 .25 94.6 1 94.15 4q-.45 3.05 1.35 6.5 1.25 2.35 2.95 4 .2.15.35.3h-.05l.05.05q-.8.05-1.6.15l-1.3.2q-4.65.75-9.3 1.8-1 .25-2.05.5-3.85.85-6.05 3.25-1.1 1.15-1.8 2.65v.35q-.05-.15-.15-.35-.7-1.5-1.8-2.65-2.2-2.4-6.05-3.25-1.05-.25-2.05-.5-4.65-1.05-9.3-1.8l-1.3-.2q-.8-.1-1.6-.15l.05-.05h-.05q.15-.15.35-.3 1.7-1.65 2.95-4 1.8-3.45 1.35-6.5-.45-3-2.85-3.75-2.45-.8-5.4 1.15T46.1 6.8q-1.85 3.45-1.35 6.5.1.35.15.65l.15.3q-5.9-.25-11.85-.05l.1-.25q.05-.3.15-.65.5-3.05-1.35-6.5-1.8-3.45-4.75-5.4Q24.4-.55 21.95.25 19.55 1 19.1 4q-.45 3.05 1.35 6.5 1.25 2.35 2.95 4 .15.1.3.25-1.5.1-2.95.25-.7.1-1.35.2-3.8.6-7.55 1.4l-1.8.4q-1 .25-2.05.5-3.95.9-6.15 3.35-.75.9-1.3 1.95-.2.3-.35.6l-.1.4q-.45 2.05 1.6 2.4h149.95q1.95-.4 1.5-2.4l-.1-.4q-.15-.3-.35-.6-.55-1.05-1.3-1.95-2.2-2.45-6.15-3.35z"
                    id="LegSho010__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M165.2 315.75q-1.25 2.45-1.3 4.35-.05 1.25.45 1.85.25.35.65.45.45.2 1.1 0 .4-.15.9-.45.35-.25.8-.6 1.7-1.45 2.95-3.9 1.25-2.45 1.25-4.3.05-1.95-1.05-2.35-1.2-.35-2.85 1.1-1.65 1.4-2.9 3.85m69.75 11.35l-.3-.4q-.85-.8-2-.8h-.3q-.05-.1-.2-.25-2.25-.9-4.55 0l-.25.25H227q-1.1 0-1.95.8-.8.85-.8 2 0 1.2.8 2.05l.15.15q.15 1.1 1.4 1.9l.4.2q1.25.55 2.9.55 1.85 0 3.2-.75 1.3-.8 1.4-1.9l.15-.15q.15-.15.3-.35.55-.75.55-1.7 0-.9-.55-1.6m-18-15.2q-1.65-1.45-2.85-1.1-1.1.4-1.05 2.35 0 1.85 1.25 4.3 1.25 2.45 2.95 3.9.45.35.85.6.45.3.9.45.6.2 1.05 0 .4-.1.7-.45.45-.6.4-1.85-.05-1.9-1.3-4.35-1.25-2.45-2.9-3.85m23.3 3.85q-1.25 2.45-1.3 4.35-.05 1.95 1.1 2.3 1.05.4 2.8-1.05 1.7-1.45 2.95-3.9 1.25-2.45 1.25-4.3.05-1.95-1.05-2.35-1.2-.35-2.85 1.1-1.65 1.4-2.9 3.85M160 326.7q-.85-.8-1.95-.8h-.35l-.25-.25q-2.3-.9-4.55 0-.15.15-.2.25h-.3q-1.15 0-2 .8l-.3.4q-.55.7-.55 1.6 0 .95.55 1.7.15.2.3.35l.15.15q.1 1.1 1.4 1.9 1.35.75 3.2.75 1.65 0 2.9-.55l.4-.2q1.25-.8 1.4-1.9l.15-.15q.8-.85.8-2.05 0-1.15-.8-2m-13.9-6.6q-.05-1.9-1.3-4.35-1.25-2.45-2.9-3.85-1.65-1.45-2.85-1.1-1.1.4-1.05 2.35 0 1.85 1.25 4.3 1.25 2.45 2.95 3.9 1.75 1.45 2.8 1.05 1.15-.35 1.1-2.3z"
                    id="LegSho010__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.55 1.15Q5.45.9 5.3.7L5.05.45Q2.75-.45.5.45.35.6.3.7q-.15.2-.25.45-.25.75.7 1.9.9.9 1.95 1.2v-.1h.05v.1h.15q1.1-.3 1.95-1.2.95-1.15.7-1.9m74.65 0Q80.1.9 79.95.7q-.05-.1-.2-.25-2.25-.9-4.55 0l-.25.25q-.15.2-.25.45-.25.75.7 1.9.85.9 1.95 1.2h.15v-.1h.05v.1q1.05-.3 1.95-1.2.95-1.15.7-1.9z"
                    id="LegSho010__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M7.45 2.25H7.4q-1.2 0-2.05-.85Q4.9.95 4.7.4 4.6.25 4.6.15L4.55.1V0H4.5v.4q-.2.55-.65 1-.85.85-2.05.85-1.05 0-1.8-.65l.4.5q.7.7 2.25.8.8.05 1.85.05 1.1 0 2.05-.05.5-.05.9-.1.85-.2 1.35-.7l.4-.5q-.75.65-1.75.65m73.75.65q1.55-.1 2.25-.8l.4-.5q-.75.65-1.8.65-1.2 0-2.05-.85-.45-.45-.65-1V.25.1 0h-.05v.1l-.05.05q0 .1-.1.25-.2.55-.65 1-.85.85-2.05.85h-.05q-1 0-1.75-.65l.4.5q.5.5 1.35.7.4.05.9.1.95.05 2.05.05 1.05 0 1.85-.05z"
                    id="LegSho010__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    d="M164.35 324q-.75 0-1.3.5-.5.55-.5 1.3t.5 1.25q.55.55 1.3.55t1.25-.55q.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5m76 3q.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5t-1.3.5q-.5.55-.5 1.3t.5 1.25q.55.55 1.3.55t1.25-.55m-21.5-1.25q0 .75.5 1.25.55.55 1.3.55t1.25-.55q.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5t-1.3.5q-.5.55-.5 1.3m-74.65.05q0 .75.5 1.25.55.55 1.3.55t1.25-.55q.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5t-1.3.5q-.5.55-.5 1.3z"
                    id="LegSho010__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho010__Symbol_16_0_Layer0_0_FILL"
                transform="translate(115.9 308.1)"
                id="LegSho010__col1n"
            />
            <use
                xlinkHref="#LegSho010__Symbol_17_0_Layer0_0_FILL"
                id="LegSho010__col1l"
            />
            <use
                xlinkHref="#LegSho010__Symbol_18_0_Layer0_0_FILL"
                transform="translate(152.4 325.2)"
                id="LegSho010__col1d"
            />
            <use
                xlinkHref="#LegSho010__Symbol_19_0_Layer0_0_FILL"
                transform="translate(150.6 329.35)"
                id="LegSho010__col1s"
            />
            <use xlinkHref="#LegSho010__Layer1_0_FILL" />
        </g></g>
    )
}

export default LegSho010
