import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D19532",
    "col1s": "#644D27",
    "col2n": "#000000"
}

function AccMor005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M12 5.05Q10 9 8.05 13.5 6.95 16.05 6 18.55l-1.2 3.2Q-2.1 42.35.95 66.8l-.4 13q0 2.45-.1 4.95-.2 3.1-.45 6.3-.1 3.15 3.05 3.05 15.8-.9 31.45 0 2.85.05 3-3 .25-2.55.25-5.1v-1.25q-.05-1.5-.15-2.95-.05-.35-.05-.7l-.45-10q-.25-.95-.85-1.6v-.05q-.4-.45-.95-.7l-.05-.05q-.05-.05-.1-.05-.1-.05-.15-.05-.7-.35-1.6-.45-8.55-1.15-17.05-1.3l-10.5-.05q-.05-.05-.05-.1-.05-.05-.05-.1Q.8 39.1 13.8 9.55 16.1 3.8 18.85 0L17.7.8q-2.65 2.05-5.55 4.05l-.15.2z"
                    id="AccMor005__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M30.4 2.7v-.05q-.4-.45-.95-.7l-.05-.05q-.05-.05-.1-.05-.1-.05-.15-.05-.05 0-.05.05-.15 1.15-.35 2.1-.2.9-.35 1.7-.5 2.5-1 4-.75 1.75-3.05 2.2l-.5.1H6.65l-.5-.1q-2.3-.45-3.05-2.2-.6-2-1-4-.2-.85-.3-1.7Q1.5 2 1.4 0H0q.25 2.4.6 4.3.1.9.25 1.7.5 2.95 1.05 4.55.95 2.6 5.1 2.7h16.55q4.15-.1 5.1-2.7.45-1.35 1.1-4.55.15-.8.35-1.7.15-.75.3-1.6z"
                    id="AccMor005__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.45 2.65q.45.45 1.1.45.65 0 1.1-.45.45-.45.45-1.1 0-.6-.45-1.05Q2.2 0 1.55 0 .9 0 .45.5 0 .95 0 1.55q0 .65.45 1.1z"
                    id="AccMor005__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor005__Symbol_103_0_Layer0_0_FILL"
                transform="translate(233.65 228.6)"
                id="AccMor005__col1n"
            />
            <use
                xlinkHref="#AccMor005__Symbol_36_0_Layer0_0_FILL"
                transform="translate(239.5 295.4)"
                id="AccMor005__col1s"
            />
            <use
                xlinkHref="#AccMor005__Symbol_14_0_Layer0_0_FILL"
                transform="translate(253.05 302.65)"
                id="AccMor005__col2n"
            />
        </g></g>
    )
}

export default AccMor005
