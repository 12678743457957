import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho051({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M174.05 28.5q-2.35-.6-4.85-1-4.1-4.55-8.75-8.35-.15-.15-.35-.25Q138.1.95 104.6 0q-5.75.05-11.15.45-16.7 1.15-30.65 5.4-4.75 1.45-9.15 3.25-6.5 2.6-12.3 6Q17.6 27.95 4.6 59.95q-.85 2.15-1.55 4.45-6.1 20.5 0 41 1.3 5.9 3.5 16.4H12v-.35q0-1.75-.1-4.55 1.85-22.1 9.7-30.5 13.2-9.9 16.55-19.45l.55-1.25q.35-.85.7-1.75l.2-.5q.2-.7.45-1.35.25-.85.5-1.75.9-1.8 1.8-3 .8-1.3 1.7-2.35 4.15-4.6 9.6-4.6h.05q12.4.05 24.75 6.05Q90.8 62.4 103.9 62.4q6.8-.05 13.3-1 8.15-1.15 15.8-3.8 5.55-2.05 24.75-3.4-1.8 8.25 2.35 15.65 2.15 3.8 5.9 7.4 7.55 6.75 8.75 8.5 11.6 13.4 12.3 36.05h6.65q.75-3.4 2.15-8.85 1.35-5.5 3.55-9.9.7-1.4 1.25-2.9 6.45-17.35 0-37.1-8.5-28.9-26.6-34.55z"
                    id="HaiSho051__Symbol_172_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M31.1 60.15l-6.05-7.2 9.55-6.4-15.9 4.15q2.9 4.5 3.75 6.45h-5.1Q15.8 56.9 15 56.8q-1.3-.15-1 0 .2.05 1.15.25v.1l-1.05-.25q-.85-.25-2.25-.55 4.85 1.9 8.8 6.05-6.6.4-10.15.15 2.75 2.45 6 5.6-5.4-.05-11.75-.3l8.35 5.05q.1.05.2.15H13q-10.75 1.7-9.4 2.45 1.35.7 3.05 2.35 1.7 1.55 2.75 1.8Q7.25 80.9 0 86.9l13.55-5.55q-.85-1.6-2.5-3.85l7.45-2.45q-.2-.5-2.8-3.35l8.1-1.15q-3.1-2.75-4.2-3.5-1.15-.7-1.1-1.6 2.9-.05 8-.55-.55-.55-3.6-4.75h8.2M15.15 56.9l-.05.1q-.097-.097-.2-.1h.25m72.7-41h3.4q-10-3.6-13.4-5.3Q93 8.8 105.45 9.35q-8.55-1.6-17.35-2.9 12.85-3.4 38.95 1.65Q114.35 1.3 88.2 0 71.5 1.15 57.55 5.4q16.3.05 18.5.35-4.8-.05-11.2 2.15-5.85 2-9.9 3.95 9.3-.5 14.65 1.35v.2q-.55.15-16.25 3.6 11.25 1 13.3 1.35-9.5 3.4-15.85 4.75 21.45 1.1 29.3 4.4-6.6.55-11.2.55 6.7 4.3 8.15 4.6v.25q-7 1.05-9.8 1.1 3.1.6 9.45 3.65-.25.25-.2.45-7.3 1.6-9.5 1.45 8.15 4.25 8.45 4.5-5 .4-7.75.7.2 0 .2.2 1.85.9 15.35 6.45 12.2 5.05 28.7 1.8 1.65-.4 3.4-.8-1.75.05-3.4 0Q95.5 52.1 84 45.2q1.25-.8 8.95-2.25-5.65-.1-12.3-2.15 11.05-1.45 13.65-2.7v-.15q-7.4-.5-11.5-1 2.35-1 11.8-3.95l-7.65-1.55q4.35-1.65 7.2-3.05-8.4-3.05-13.75-4.2 5.45-.05 16.45-.55-10.25-3.65-14.5-5.5-4.2-1.9-5.85-1.95 7.5-.05 11.35-.3m85.6 45.95v-.1q.4.05 10-1.7l-3.25-1q-6.35-2.45-8.9-2.8 1.75-1.65 8.2-4.1-4.9-1.15-9.3-2.8-4.4-1.7-8.5-2.8-1.55-.35-2.6-.8 3.8 3.35 5 4.3 1.5 1.2 4.7 2.45-4 1.7-5.95 2.05l.2.45q1.8 1.6 5 4.05.35.3.75.65-3.2.8-4.6.7v.1q1.6 1.8 9.1 6.8-2.55.25-3.35.55h-2.25q2.5.9 8.55 5.2-2.6 1.85-4.95 3.1 5.6 5.6 12.2 12.1-3.55-7.8-5.55-9.7 2.15-1.45 7.45-3.4-2.15-.9-4.2-3-2.3-2.1-3.05-2.4.65-.15 2.3-1.35 1.75-1.4 2.8-1.55-7.05-2.55-9.7-5h-.1z"
                    id="HaiSho051__Symbol_38_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho051__Symbol_172_0_Layer0_0_FILL"
                transform="translate(92.35 55.35)"
                id="HaiSho051__col1n"
            />
            <use
                xlinkHref="#HaiSho051__Symbol_38_0_Layer0_0_FILL"
                transform="translate(97.75 55.9)"
                id="HaiSho051__col1l"
            />
        </g></g>
    )
}

export default HaiSho051
