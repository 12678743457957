import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#830000",
    "col2n": "#FFFFFF"
}

function SkiWou004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.55 6.35l.1-.1h.05q.05-.1.1-.1l.05-.05-5.25 1.45 1.25-4.75q-.6.5-1.25 1-1.3-.1-2.55-.2-.15-.05-.2-.05l-.25-.05q-1.5 0-3.1-.6-1.7-.65-2.1-1.35Q6.6.35 6.05.15 5.85.05 5.6 0 3.75.05 3.75 2.05v.15q-.1.5 0 1.15.3 1.9.7 3.75l.2.9q.15 1 .1 1.55h.05q.05.25.05.55l-.15.15-.15.15q-1 .95-2.1 1.8Q.2 13.95 0 16.5q-.1 1.05.95 1.1 1.65.05 3.1.5.3.05.6.2 2.25.75 3.95 2.55l.1.3q.55.05.9.1l-.35 1.45q.2.35.35.75.05.1.1.15l5.15 1.85 9.3-1.55.05-.05.3-5.8q0-.05-.05-.05-1.75-1.05-2.9-2.8l.05-.05h-.1v-.05l-3.45-.95V11.4l-.05-.05h.05q-.35-.45-.35-1.45 0-.65.35-1.1v-.05q.05-.1.15-.15.35-.45 1.25-1.2.25-.25.5-.45.25-.3.5-.5 0-.05.05-.05l.05-.05z"
                    id="SkiWou004__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M15.95 8.7l-.05-.05Q13.55 12.15 3.55 12h-1.1q-.25-.05-.55-.05h-.75q-.3-.05-.65-.05H.35q.7.55 1.45 1.05.25.15.55.3Q5.4 15 8.75 15.1h.85q6.65-.05 9.85-2.6l.05-.05h-.2l.1-.05-.1-.2q-.1-.15-.2-.25-1.9-1.85-3.15-3.25m3.1-5.6q.3-.25.55-.4-1.5-.4-3.95.7h-.15v-.05L14.85 0q-.65.55-1.35 1.1h.05l-.8.5-.8.5q-.1.05-.2.15l-.2.1q-.05 0-.1.05-3.8 2.1-8.9 2.9-.6.1-1.15.15-.7.1-1.4.15v.05q1.2.45 2.6.8 1.1.2 2.3.35.25.05.5.05 1 .1 2.1.15h.6q.1-.05.25 0 .75-.05 1.45-.05 4.8-.4 8.75-3.4h.05q.05-.1.1-.1.1-.1.2-.15l.15-.2m5.7 15.35q-1.7-2.7-1.75-2.8-.35-.15-.65-.35-.2.95-1 1.8-1.45 1.4-4.65 2.45-1.1.35-2.25.65-3.15.7-6.85.7-.3 0-.75.15 0 .05.05.1.05 0 .25.1.3.2 2.25 1.25 1.85 1.05 4.4 1.15l1.2-.75 1 .85q.25-.05.5-.05 2.95-.2 5.4-1.35l.6-3.95q1.3 1.05 3.45 1.15-1-.85-1.2-1.1z"
                    id="SkiWou004__Symbol_10_0_Layer0_0_FILL"
                />
                <g id="SkiWou004__Layer7_0_FILL">
                    <path
                        fill="#FFF"
                        d="M212.5 277.95h-.05.05m5.8 6.55v.05h.05l-.05-.05m-5.55-10.6v-.05q-.094 0-.15-.05.056.053.15.1z"
                    />
                    <path d="M191.15 254.5l-.05.05q2.417 6.835 2.95 14.8-3.988-.08-7.6 2.1 3.3.8 5 3-8.325 3.933-15 11 13.072-2.972 23.1-1.45.535.056.85.05l-.3 1.5-.4 1.05.05-.1q-.017.047-.05.1-2.109 6.008-1.8 12.95l.2-.3q3.905-6.44 8.65-10.05l1.2-.75 6 5 1.05-6.7v-.1l.05.1.3-5.85q-.021-.03-.05-.05-1.76-1.056-2.9-2.85v-.05h-.05l-.05-.05-3.4-.9-.05-2.8q.012-.015 0-.05-.3-.393-.3-1.4 0-.663.3-1.1.032-.027.05-.05.052-.079.1-.2.392-.407 1.3-1.15.248-.248.45-.5.292-.232.5-.45.054-.03.05-.05.05-.023.05-.05.094-.063.15-.15.088-.03.15-.1.028-.002.05-.05l-5.25 1.5 1.25-4.75q.045-.053.1-.1l-2.35-12.1-1.85 11.5q-.23.917-1.15.75-.869-.163-1.45-.75-.54-.591-.7-.8-.114-.168-.2-.2l-3.3-4.95q-2.8-2.936-5.65-4.5m1.8 16.65q.047.03.1.05-.053-.007-.1-.05z" />
                    <path
                        fill="#900"
                        d="M193.05 271.2q-.053-.02-.1-.05.047.043.1.05z"
                    />
                </g>
            </defs>
            <use xlinkHref="#SkiWou004__Layer7_0_FILL" />
            <use
                xlinkHref="#SkiWou004__Symbol_11_0_Layer0_0_FILL"
                transform="translate(190.85 262.85)"
                id="SkiWou004__col1n"
            />
            <use
                xlinkHref="#SkiWou004__Symbol_10_0_Layer0_0_FILL"
                transform="translate(192.95 265.55)"
                id="SkiWou004__col2n"
            />
        </g></g>
    )
}

export default SkiWou004
