import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M198.95 47.75l-11.2-12.3q-19-17.3-47.1-22.35-11.6-2.1-24.7-2.1l-8.7.25q-1.55.05-2.95.2 5.7-6.55 17.95-10.2Q116.7-.85 107.6.6l-1.75.3q-.9.15-1.8.35-3.7.65-7.3 1.55-.75.2-1.45.4l-.5.1h-.05q-.05 0-.1.05h-.05q-.05 0-.15.05-.25.05-.5.15l-1 .25q-4.15 1.1-8.1 2.55Q63.9 13.6 60.2 22.2q-4.15-2.25-8.55-3.5-6.35-1.9-13.2-.75-2.55.4-4.9 1.75-1.1.6-2.15 1.45 10.1.5 17.05 9.4l-1.2.4-12.95 4.8Q1.05 51 .05 85.65 0 86.75 0 87.9v.7q0 .9.05 1.8l.75 7.45q.05.6.15 1.2V99q.8 4.95 2.3 10.4Q5.1 116.2 8 123.7q.9 2.4 1.8 4.75.05.2.15.4.35.95.75 1.95 5.9 16.45 2.3 26.8.6-.45 1.6-.85 3.75-1.7 5.3-7.1 1.55-5.5 1.55-10.8-.05-4.35-1.55-11.15 0-.15-.05-.25-1.8-8.85-1.8-18.05-.05-4.55.4-9.2Q20.4 83 27.5 72.3q4-6.1 6.5-5.65l.25.05q.4.15.75.45.6.5 1.1 1.45.95 1.3 1.2 2.45.65 2.6 1.2 7 .15 1.1.5 3.45.25 2.1.35 5.95.05 2.3.1 3.45l-.1 3.45q1.25-2.35 2.6-5.7 8.3-20.65 10.5-42.1l.1.1q.1.25.85 1.55l.2.4q.15.2.3.45Q62.2 63.55 78.1 75q.35.25.75.55 3.2 2.3 6.7 4.5 1.75 1 3.55 2.05H89q14.25 8.55 31.25 15.15l32.7 9.9q45.7 15.75 73.95 45.1-.65-14.05-3.05-25.9-3.8-18.65-11.95-31.9-1.8-2.45-3.55-4.75-6.4-8.25-12.4-14.45 14.7 4.3 29 19.2-12.2-28.9-25.8-46.45l-.2-.25z"
                    id="HaiSho060__Symbol_181_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M51 34.55q-.95-2.55-2.55-4.9l-1.2.4-12.95 4.8Q1.05 50.1.05 84.75 0 85.85 0 87v.7q0 .9.05 1.8l.75 7.45q.05.6.15 1.2v-.05Q.7 51.4 51 34.55M105.85 0q-.9.15-1.8.35-3.7.65-7.3 1.55-.75.2-1.45.4l-.5.1h-.05q-.05 0-.1.05h-.05q-.05 0-.15.05-.25.05-.5.15l-1 .25Q88.8 4 84.85 5.45 63.9 12.7 60.2 21.3q-4.15-2.25-8.55-3.5-6.35-1.9-13.2-.75-2.55.4-4.9 1.75 7.2-2.4 14.05-.35 3.3 1.15 5.65 2.5.7.4 1.35.8 4.25 2.85 8.5 5.95 1-2.3 2.9-4.7.3-.4.65-.8 1.35-1.65 3.05-3.3 1.7-1.8 3.4-3.4.2-.2.45-.4Q87.6 3.75 105.85 0m93.1 46.85l-11.2-12.3Q160.9 10.1 115.95 10.1l-8.7.25q-1.55.05-2.95.2-2.75 3.4-4.75 7.15 9-.85 17.7-.95l15.25.6q45.55 3.85 67.15 30.45l-.5-.7-.2-.25z"
                    id="HaiSho060__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M112.95 24.95q-8-10.3-20.65-16.05Q75.45 1.1 56.7.3 37.95-.55 29.5.95 21 2.45 0 2.95 10.55 27.9 28.2 35.3q17.6 7.4 33.95 11.5-.75-.65-1.45-1.25Q47.1 34.1 45.5 29.6l7.45 3.9q2 .95 4.9 2.55.6.3 1.2.65 8.8 4.85 15.95 7.45 6.95 2.55 19.6 6.9 9.5 4.25 16.45 11.05 2.3 2.2 5.55 6.05-2.6-5.45-7.25-11.9-7.65-10.5-12.25-16.2-5.95-7.25-14.2-13.5-4.75-3.6-9.75-6.45.65-.05 1.4-.1 17.55-1.6 26.7.9 9.15 2.5 11.7 4.05z"
                    id="HaiSho060__Symbol_43_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho060__Symbol_181_0_Layer0_0_FILL"
                transform="translate(87.3 45.6)"
                id="HaiSho060__col1n"
            />
            <use
                xlinkHref="#HaiSho060__Symbol_96_0_Layer0_0_FILL"
                transform="translate(87.3 46.5)"
                id="HaiSho060__col1d"
            />
            <use
                xlinkHref="#HaiSho060__Symbol_43_0_Layer0_0_FILL"
                transform="translate(161.8 78.6)"
                id="HaiSho060__col1l"
            />
        </g></g>
    )
}

export default HaiSho060
