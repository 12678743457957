import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccMas012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.5 23.3l-.1-1.95-13.15 11.2-.1-1q-.5-4.15-2.3-8.05l-.2-.4-.35-.75q-.75-1.4-1.6-2.7-7.85-12.5-23.25-16.95Q139.05-.4 127.7.05q-12.9.3-25.45 6.15-.2.05-.4.15-3.45 1.1-6.95 1.05-3.2-.2-6.35-1.4Q76.55.2 62 0 51.6-.15 41.2 2.7q-15.5 4.45-23.4 17-.85 1.3-1.55 2.7l-.4.75q-.1.15-.15.35-1.4 3-2 6.15-.2.95-.3 1.95-.15 1-.1.9L.1 21.3 0 23.25 13.2 34.8q.05 7.8 4.6 16.45 8.9 15.6 24.25 20.7 17.25 5.95 32.55 0 .35-.1.65-.25l1.4-.6 1.3-.6q.75-.35 1.5-.75.15-.1.35-.2l.35-.2.2-.1 10.95-6.3q.35-.2.75-.35 2.05-.95 4.1-.95 1.65.15 3.35.9.35.15.75.35l10.95 6.3q.05.05.15.1l.35.2q.2.1.35.2.75.4 1.55.75.25.15.5.25 1.35.7 2.7 1.25 17.2 6.05 32.55 0Q164.8 66.9 173.7 51.2q4.55-8.6 4.6-16.4h.3v-.2l12.9-11.3m-52-2.35q8.25 8.8 9 20.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-7.3-7.8-8.8-17.85l-.75-2.5q-1.35 5.7-2.1 8.1-2.25 6.7-7.45 12.3-7.25 7.75-16.55 9.3-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-6.65-1.2-11.5-6.45-7.2-7.6-6.35-19.2Q43.7 29.8 52 21q8.15-8.85 19.05-9.65Q81.8 10.5 88.9 18.1q3.1 3.35 4.75 7.55.75 2.05 1.95 7l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75h.05q10.85.8 19.05 9.65z"
                    id="AccMas012__Symbol_96_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas012__Symbol_96_0_Layer0_0_FILL"
                transform="translate(96.8 125.3)"
                id="AccMas012__col1n"
            />
        </g></g>
    )
}

export default AccMas012
