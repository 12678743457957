import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00DE00",
    "col1n": "#00FF00",
    "col1s": "#219C21",
    "col2n": "#FF0000"
}

function AccHat139({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M164.45 27.55v-.05q-.05-8.6-2.3-14-3.5-8.35-12.25-9l-9-4.5H26.45L19.9 4.75q-7.2 1.4-10.25 8.75-2.25 5.4-2.25 14v4.05q.2.35.35.7L0 57.4l11.1 15.5 9.1-3.5q1.4.2 2.95.25.2-.05.45 0h124.65q4.2-.05 7.3-1.35.2-.1.4-.15l7.65.8 6.65-22.05-5.8-14.3.45-.85q.1-.2.05-.35v-.05q-.1-.2-.25-.3-.15-.05-.25-.05v-3.3-.15z"
                    id="AccHat139__Symbol_481_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M21.85 56.3q-.15.2-.3.45-2.8 4.35-5.2 8.35-.6.95-1.15 1.9-2.65 4.4-4.8 8.4-.8 1.45-1.55 2.9-1.95 3.75-3.45 7.1-.6 1.25-1.05 2.4-.3.65-.55 1.25-1.2 3-1.95 5.65-.2.7-.4 1.45-.6 2.25-.8 4.2-.1.6-.1 1.2-.2 3.6-.35 6.75-.1 2.4-.15 4.5-.1 4.55 0 7.8 0 .4.05.8 0 .85.15 1.85.4 3.25 1.9 7.8 1.5 4.65 4.15 10.7 6.9-28.8 31.3-47.35-11.35-.2-14.5-10.15-.75-2.3-1.05-5.1-.15 0-.25-.05-.2-.1-.25-.25-.1-.25 0-.4l.35-.75q-.1-1.3-.05-2.7 4.85-9.35 0-18.7m162.85 10q-.55-.9-1.05-1.75-2.2-3.6-4.75-7.2-4.75 8.85 0 17.65 0 1.9-.15 3.65-.3 3.1-1.1 5.6-2.1 6.45-7.65 8.8 17.4 17.95 22 47.3 2.3-4.8 3.8-9.7 1.1-3.55 1.8-7.2.5-2.75.8-5.55.35-3.95.3-8-.1-2.75-.35-5.55-.4-3.8-1.2-7.6-.3-1.45-.65-2.95-.1-.4-.2-.75-.9-3.35-2.1-6.75-.3-.9-.6-1.75-1.4-3.75-3.15-7.5-.7-1.45-1.45-2.9-1.95-3.95-4.3-7.85m-20.35-50.85q0-1.9-.8-3.1-.05-.6-.2-1.2-.05-.5-.15-.95-32.75-19.05-62.65 0v6.4q-.5-.05-1 0l-1 .1v-6.5q-29.45-20.4-61.95 0-.1.25-.15.5-.45.65-.65 1.5-.25.4-.4.9-1.05 1.45-1.05 3.25 0 .3.05.6-.05.15-.05.3-.4 3.3 0 7.2v5.05q1.7-.35 3.7-.35H162.7q.85 0 1.65.1v-3.3q.45-4.4 0-8.4-.05-.5-.1-.95.1-.6.1-1.15z"
                    id="AccHat139__Symbol_414_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.1 106.8q-.1-3.25 0-7.8-.5-.1-1.1-.1-2.1 0-3.2 1.6-.8 1.15-.8 2.4 0 1 .6 2.1 1.05 1.9 3.4 1.9.6 0 1.1-.1m.2 2.65q-1.3.3-2.1 1.45t-.8 2.4q0 1 .6 2.1 1.05 1.9 3.4 1.9.4 0 .8-.05-1.5-4.55-1.9-7.8m.4-22.9q-.25-.05-.5-.05-2.1 0-3.2 1.6-.8 1.15-.8 2.4 0 1 .6 2.1 1.05 1.9 3.4 1.9h.05q.15-3.15.35-6.75 0-.6.1-1.2M8 65.25q-.8 1.15-.8 2.4 0 1 .6 2.1.85 1.55 2.65 1.85 1.5-3.35 3.45-7.1-1-.85-2.7-.85-2.1 0-3.2 1.6M9.4 74q-1.05-1.15-3-1.15-2.1 0-3.2 1.6-.8 1.15-.8 2.4 0 1 .6 2.1.85 1.6 2.55 1.9.1.25.25.55.3.55.7.95.2-.75.4-1.45.75-2.65 1.95-5.65.25-.6.55-1.25m3.8-17.8q0 .05.7 3.15.6 1.45 1.55 2.25 2.15-4 4.8-8.4-1.15-1.15-3.05-1.15-4 0-4 4.15m10-15.35q-4 0-4 5.45 0 1.95.3 2.85.55 1.6 1.9 2.15 2.4-4 5.2-8.35-.95-2.1-3.4-2.1m58.6-12.1q.75 1.5 2.6 1.5 3 0 3-3-.05-3-3-3-3 0-3 3l.4 1.5M103.6 0v6.95h2V0h-2m22.6 27.25q-.05-3-3-3-3 0-3 3l.4 1.5q.8 1.5 2.6 1.5 3 0 3-3M204 112.9q-.1-2.25-1.35-3.25-.7 3.65-1.8 7.2 3.15-.4 3.15-3.95m-.55-8.8h.15q2.1 0 3.2-1.6.8-1.15.8-2.4-.2-3.95-3.85-4 .05 4.05-.3 8m1.35-14.8q.8-1.15.8-2.4-.25-3.7-3.4-3.95.8 3.8 1.2 7.6.85-.4 1.4-1.25m-6.15-18.55q2.95-.55 2.95-3.9-.15-4-4.2-4-1.1 0-1.9.4 1.75 3.75 3.15 7.5m.95 1.7q-.2 0-.35.05 1.2 3.4 2.1 6.75.1.35.2.75.75-.4 1.25-1.15.8-1.15.8-2.4-.3-4-4-4m-5.55-12.1q.55-.6 1.15-1.4 2-2.7 2-4.2 0-4.3-4-4.3-1.6 0-3.45 2.05 2.35 3.9 4.3 7.85m-9.6-18q.05.15-.05.35l-.45.85q2.55 3.6 4.75 7.2 2.9-.6 2.9-4.85.5-5.05-4-5.05-2.2 0-3.15 1.5z"
                    id="AccHat139__Symbol_385_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M55 15.9q0-6.55-8.05-11.25Q38.9 0 27.5 0h-.55Q15.9.1 8.05 4.65 0 9.35 0 15.9q0 2.05.8 3.95h53.4q.8-1.9.8-3.95m55.95-11.25Q103.6.4 93.45.05 92.5 0 91.5 0 80.1 0 72.05 4.65q-5.15 3.05-7 6.8Q64 13.55 64 15.9q0 2.05.8 3.95h53.4q.8-1.9.8-3.95 0-6.55-8.05-11.25z"
                    id="AccHat139__Symbol_362_0_Layer0_0_FILL"
                />
                <g id="AccHat139__Layer7_0_FILL">
                    <path d="M160.4 44.35q-3.6 9.95-3.3 19.85h9.1q-1-9.9-5.8-19.85m60.55 19.85h9.25q.25-9.9-3.3-19.8-4.85 9.9-5.95 19.8z" />
                    <path
                        fill="#FFF"
                        d="M116 113.7h17.85l-7.9-21.1-9.95 21.1m18.95 0h17.7l-8.25-21.1-9.45 21.1m-9.5-22.4H115.3v18.75q-.05 1.4.05 2.7l10.1-21.45m18.4 0h-17.3l7.9 21.1 9.4-21.1m18.05 0h-16.95l8.3 21.05 8.65-21.05m9.95 22.4l-9.4-21.1-8.7 21.1h18.1m57.1 0h14.95l-6.75-20.9-8.2 20.9m25.25-22.4h-16.45l6.75 21.05 9.7-21.05m-9.25 22.4h14.85l-5.2-20.9-9.65 20.9m27.25 0q.15-1.75.15-3.65V92.4l-11.45 21.3h11.3m-78.85 0h16.15l-6.75-21-9.4 21m-20.4 0h19.35l-9.1-21.2-10.25 21.2m37.6 0h17.3l-8.25-21.05-9.05 21.05m26.1-22.4h-16.5l8.25 21.05 8.25-21.05m-17.55 0h-15.75l6.75 21 9-21m-36.45 0h-19.7l9.45 21.25 10.25-21.25m19.6 0h-18.5l9.05 21.2 9.45-21.2m69.55 0h-16.55l5.2 21 11.35-21z"
                    />
                    <path
                        fill="#999"
                        d="M255.25 91.3v-.1q-.05-.15-.15-.25-.15-.15-.3-.15t-.3.1q-.15.05-.2.2l-.1.2-9.7 21.05-6.75-21.05-.05-.15q-.05-.15-.2-.25-.1-.1-.25-.1-.2 0-.3.1-.15.05-.2.25l-.1.15-8.25 21.05-8.25-21.05-.05-.15q-.1-.2-.3-.3-.2-.1-.35 0-.2.05-.3.25l-.05.2-9 21-6.75-21-.05-.15q-.05-.15-.2-.25-.1-.1-.25-.1t-.3.1q-.15.05-.2.2l-.1.2-9.45 21.2-9.05-21.2-.1-.2q-.05-.2-.25-.25-.2-.1-.35 0-.2.05-.3.25l-.1.2-10.25 21.25-9.45-21.25-.1-.2q-.1-.2-.25-.25-.2-.1-.4 0-.2.05-.25.25l-.05.2-8.65 21.05-8.3-21.05-.05-.15q-.1-.2-.3-.3-.2-.1-.35 0-.2.05-.3.25l-.1.2-9.4 21.1-7.9-21.1-.05-.15q-.1-.2-.3-.3-.2-.1-.35 0-.2.05-.3.25l-.1.2-10.1 21.45-.35.75q-.1.15 0 .4.05.15.25.25.1.05.25.05h.15q.15-.1.25-.25l.1-.25 9.95-21.1 7.9 21.1.1.2q.05.15.25.25t.4.05q.15-.1.25-.3l.1-.2 9.45-21.1 8.25 21.1.1.2q.05.1.2.2.1.1.25.1t.3-.05q.1-.1.2-.25l.05-.2 8.7-21.1 9.4 21.1.1.2q.05.2.25.25.2.1.4.05.15-.1.25-.25l.1-.25 10.25-21.2 9.1 21.2.05.2q.05.2.25.25.2.1.4.05.15-.1.25-.3l.1-.2 9.4-21 6.75 21 .05.15q0 .15.15.25.1.1.3.1.15 0 .3-.05.1-.1.15-.25l.1-.2 9.05-21.05 8.25 21.05.1.2q.05.15.2.25.1.05.25.05t.3-.05q.1-.1.2-.25l.05-.2 8.2-20.9 6.75 20.9.05.15q0 .15.15.25.1.1.3.1.1 0 .25-.05.15-.1.2-.25l.1-.2 9.65-20.9 5.2 20.9.05.15q0 .1.15.25.1.1.25.1t.3-.05q.1-.05.2-.2l.15-.25 11.45-21.3.45-.85q.1-.2.05-.35v-.05q-.1-.2-.25-.3-.15-.05-.25-.05-.05 0-.15.05-.2.05-.3.2l-.1.25-11.35 21-5.2-21z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat139__Symbol_481_0_Layer0_0_FILL"
                transform="translate(107.9 59.8)"
                id="AccHat139__col1n"
            />
            <use
                xlinkHref="#AccHat139__Symbol_414_0_Layer0_0_FILL"
                transform="translate(93.45 35.05)"
                id="AccHat139__col1d"
            />
            <use
                xlinkHref="#AccHat139__Symbol_385_0_Layer0_0_FILL"
                transform="translate(88.4 48.85)"
                id="AccHat139__col1s"
            />
            <use
                xlinkHref="#AccHat139__Symbol_362_0_Layer0_0_FILL"
                transform="translate(133.45 44.35)"
                id="AccHat139__col2n"
            />
            <use xlinkHref="#AccHat139__Layer7_0_FILL" />
        </g></g>
    )
}

export default AccHat139
