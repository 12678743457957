import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1n": "#FFC400",
    "col1s": "#BD7B00",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjToo039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.85 38.45q-2.35.1-3.45-2.65l-.7-2.25q-3-.1-5.15-2.25-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-1.35.5-2.6.7-.15.1-.25.2 0 .1-.05.1t-.1.1q-2.6 1.9-.1 4.4 1.35 1.3 3.15 1.7 2.7.55 5.5.25 1.85-.05 3.55-.5 1.75-.6 3.3-1.65 2.5-1.3 4.4-3.25 1.2-1.4 1.6-3.2.65-3.5-2.55-3.65h-.6v.05M55.8 10.8q1.45-1.1 1.75-1.4 2.45-2.7 2.4-5.35-.2-3.4-4.1-4.05-2.9.4-2.75 2.6.05.25.15.5.7 1.8-3.9 3.75l-18.9 6.85-19.15 4.95q-4.5 1.2-5.5-.7-.1-.3-.25-.6-1.15-1.9-3.75-.6Q-1.1 19.5.7 22.4q1.4 2.2 5 3.05.4.05 2.25.15.1 0 .2.1l10.5-2.55v-.05q8.8-2.05 12.75-3.15-.45.6-.65 1.55 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.45-.45.9-.75l-.75-2.4 3.6-1.25q.25-.1.45-.15.35-.15.65-.25 0-.05.15-.2.55-.2 1.3-.45l10.85-4.1q0-.1.1-.15z"
                    id="ObjToo039__Symbol_199_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.65 0l-.4.15q-5 2-10.25 3.35 1.3.2 2.7.65 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.45-.45.9-.75L10.65 0m9.85 22.65q.55-1.35-.05-1.5-.1-.05-.25-.05-.25.05-.45.05l.05-.05q-2.35.1-3.45-2.65l-.7-2.25q-3-.1-5.15-2.25-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-1.35.5-2.6.7-.15.1-.25.2.15-.05.35-.2l.05.05q-.2.1-.35.2-.8.6.6 1.5 1.85 1 4.75 1.1 2.8.05 5.3-.75 2.55-.75 4.85-2.4 2.3-1.7 3.25-3.6z"
                    id="ObjToo039__Symbol_132_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M10.25.15q-5 2-10.25 3.35 1.3.2 2.7.65 1.1.5 2.1 1.25 2.75-.85 5.4-1.9.15-.2.3-.35.4-.4.9-.75L10.65 0l-.4.15z"
                    id="ObjToo039__Symbol_107_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.25 0q-2.6 9.75.25 24l51.25 172.05 8.5-2.2L69.1 191 14.65 19.95Q9.55 8.55 1.25 0z"
                    id="ObjToo039__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M13.4 19.95Q8.3 8.55 0 0l60 193.85 7.85-2.85L13.4 19.95z"
                    id="ObjToo039__Symbol_38_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo039__Symbol_199_0_Layer0_0_FILL"
                transform="translate(88 265.7)"
                id="ObjToo039__col1n"
            />
            <use
                xlinkHref="#ObjToo039__Symbol_132_0_Layer0_0_FILL"
                transform="translate(116.05 283.05)"
                id="ObjToo039__col1d"
            />
            <use
                xlinkHref="#ObjToo039__Symbol_107_0_Layer0_0_FILL"
                transform="translate(116.05 283.05)"
                id="ObjToo039__col1s"
            />
            <use
                xlinkHref="#ObjToo039__Symbol_79_0_Layer0_0_FILL"
                transform="translate(57.2 85.55)"
                id="ObjToo039__col2n"
            />
            <use
                xlinkHref="#ObjToo039__Symbol_38_0_Layer0_0_FILL"
                transform="translate(58.45 85.55)"
                id="ObjToo039__col2l"
            />
        </g></g>
    )
}

export default ObjToo039
