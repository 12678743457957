import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiMed032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.35 111.05h-.1l.35 99.8 3.55-62.35L6.6 208l4.25-37.35v-.2l1.4-12h.3v49.2h8.05q2.05-5.75 3.5-8.8l.7-28.05 2.3 21.95 6.1-10 .9 26.7 2.3-30.7q-.2-.15-.4-.35-2.4-1.85-4.6-3.9-1.65-1.5-3.25-3.1-7.25-7.25-12.6-15.25-1.55-2.3-2.9-4.65-1.3-2.15-2.45-4.4v-.05q-2.55-4.95-4.45-10.15-2.05-5.55-3.35-11.45-1.546-6.98-2.05-14.4m191.1-1.35h-.05l-.35 3.35q-1.815 16.837-8.2 30.5-.144.42-.35.8-.081.23-.2.45-8.597 18.382-28.9 35.55l-.25.2.45 29.45 4-28.5 7.45 11.55 2.95-22.5 2.85 36.25h7.45v-1.6l3.1-45.7 1.75 48.95 3.95-61.2 3.9 64.25.45-101.8M185.7 13v-.1l-.05-.1-5.95 8.85V11.8l-5.9-2.2-4 6.8-.9-8.55-6.35-4.45-3.4 7.5q0 .05-.45.55l-2.9-8.3-4.85-.95-2.7 9.6L140.5.9q-3.55-.7-6.8-.8v3.95q.05.05.05.15v.7L130.2 0q-.3 0-.6.05-4.35.4-7.2 2.9-.1.05-.2.15v.45q-.05-.15 0-.45-2.1 2.05-3.3 4.95-.25-.85-.6-1.75-.85-2.05-2.5-3.35-.8-.6-1.75-1.05h-11.1q.61 3.59 1.05 5.8L100.75 3 93.7 1.7l-.2 5.75q-.054-.308-.15-.65-1.304-4.653-1.55-5.45Q88.25 3.1 85.6.1V.05q-1.4 1.15-2.65 2.2-.45.35-.85.7-1.35 1.1-2.5 2.05-.6.5-1.15.95-1.25-1.5-2.25-3-.15-.25-.3-.45l-6.3 6.4h.05v.05h-.05V8.9l-1.7-5.1-6.1-.45-2.35 11.55-2.3-8.8-3.75.7-.55 3.95-2.05-3.3-5.35 1.35-10.95 8.6-.05-.05-.55-8.5-9.35 2.65-1.45 5.8-1.55-5.6q-4.15 2.65-1.9 12.95l-3.2-9.6q0-.35-.05-.65l-.6-1.75h-5.1v.05h-.05l-4.1 15.05-.4-.3L0 21l.15 67.8-.05.3-.25 12.8q.3-.4.35-.5l.4-.9.05-.05 9.5-20.75q1.4 2.45 3 4.95.95 1.6 2.05 3.2l.35.05q.2-1.6.45-3.25 1.45-9.75 5.15-21 1.1-3.3 2.35-6.55l-.35-.15h14.6l21.95-7.5 5.5 6 12.4-3.25v.1l16.6-4.35 12 4.5h16.35l9.15-9.5 23.95 14.3h9.5l5.15-5.8 4.6 11.05q.15 1 .25 2.05l2.55 19.75h7.2l6.75 18.65.4-15.25-.1-.85.1.3.05.4.6-51.4-.05.1.25-17.7-4.2 4.3-3-9.85M168 35.7q-.05-.05-.05-.1v-.05q.002.052.05.1v.05z"
                    id="HaiMed032__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M.4 111.5H.25l.35 99.35 3.55-62.35L6.6 208l4.25-37.35v-.1L8.4 191.5l1.8-44.45q-2.55-4.95-4.45-10.15-2.05-5.55-3.35-11.45-1.499-6.769-2-13.95m.5-26.85q2.6-15.05 8.25-29.1Q1.4 39.4 0 21.75L.15 88.8l-.05.3v.4q.35-2.45.8-4.85m9.8-71.95h-.05l-4.1 15.05-.1-.05 10.8 12.95q.05.05.05.1h.3l-2-28.1h-4.9v.05m33.25 4.15q.8-5.75 1.5-8.05L34.5 17.4v.55q.2 3.35 2.3 8.4 2.05 5.05 3.45 6.55.85.9 2.45 2.95 0-.3.05-.6.1-1.55.25-7.1.15-5.6.95-11.3M85.8 3.45v-.5Q85.7 1.1 85.6.1V.05q-1.4 1.15-2.65 2.2-.45.35-.85.7-1.35 1.1-2.5 2.05-.6.5-1.15.95-1.25-1.5-2.25-3h-.75L69.6 8.9h.05v.05q4.5 8.7 8 14.1.75 1.1 1.5 2.25.6.85 1.1 1.65.2-3.65.5-7.3.35-4 .75-8 0-.25.1-.5 2.15 4.4 4.6 8.5 6.45 10.9 14.55 19.45-1-1.8-4.95-8.65-3.3-5.65-5.4-10.8-1.3-3.15-2.15-6.1-2.35-7.9-2.45-10.1m25.2-.5q.05-.5.15-1.05h-8.2l8.25 27.15q-.65-4.7-.85-9.4-.35-8.3.65-16.7m22.75 1.25v.7L130.2 0q-.3 0-.6.05-4.35.4-7.2 2.9-.1.05-.2.15v.45q-.8 3.25-1.15 6.55-.3 2.7.85 9.55.25 1.5.55 3.15.1-1.65.25-3.15.5-6.95 1.2-10.2V9.4q.7-1.95 1.2-3.95 1.35 2.35 2.95 4.6 2.8 3.9 6.3 7.45.15.15.35.3-.75-9.95-.95-13.6m57.7 105.1h-.05l-.35 3.75q-1.815 16.837-8.2 30.5-.144.42-.35.8-.081.23-.2.45-3.55 7.59-9.1 14.95l5.1 47.05v-1.6l3.1-45.7 1.75 48.95 3.95-61.2 3.9 64.25.45-102.2m.6-22.15l.05.4v-.05l-.05-.35m.65-51l-.05.1q-2.75 9.45-8.25 13.6 5.15 16.65 7.6 34.8.05.55.15 1.1l.55-49.6m-28.75-16.5q-.3-3.5-.35-4.15-.35-6.05-1.05-12.1l-3.4 7.5q0 .05-.45.55.3 3.3.4 4.75.05.5.05 3.45-.05 4.95-.2 16.8-.1 3.75-.05 6.05l2.45-18.9q0-.3.05-.6 1.05 4.55 5.55 11.25Q164.5 26 164.1 21.5q-.1-1-.15-1.85m21.7-6.1v-.75l-5.95 8.85v13.8q.22-.796.45-1.65 5.148-17.105 5.5-20.25z"
                    id="HaiMed032__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed032__Symbol_88_0_Layer0_0_FILL"
                transform="translate(96.65 49.95)"
                id="HaiMed032__col1n"
            />
            <use
                xlinkHref="#HaiMed032__Symbol_30_0_Layer0_0_FILL"
                transform="translate(96.65 49.95)"
                id="HaiMed032__col1l"
            />
        </g></g>
    )
}

export default HaiMed032
