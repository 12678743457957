import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M193.9 233.2v-.05q1.72.366 3.25 1.2.277.16.55.05.281-.064.4-.35.16-.226.05-.5-.064-.281-.35-.45-1.03-.544-2.15-.9-3.738-1.528-7.5 0-.028.055-.1.1-.134.267-.1.55.083.274.3.4.255.184.5.1.525-.075 1-.2v.05q2.147-.377 4.15 0m18.75-6.85q.083.311.3.45.267.184.55.1.274-.033.4-.3.184-.205.1-.5-.15-1.004-1.2-1.65-1.067-.757-2.55-.7h-.05q-1.315-.004-2.5-.15h-.05q-.984-.24-1.85-.6h.05l-3.65-1.75q-.024-.014-.05-.05-2.31-1.368-4.2-1.35-1.15-.006-2.95.65-1.45.543-2.45.6h-.1q-.931-.053-2.3-.6h-.05q-1.644-.597-2.8-.65h-.1q-1.889-.016-4.25 1.35l-3.7 1.8q-.772.36-1.8.6h-.05q-1.13.146-2.5.15-1.531-.05-2.55.7-1.029.623-1.3 1.6-.048.302.1.55.146.25.4.3.302.098.55-.05.25-.146.3-.45.174-.454.7-.75.038-.023.05-.05.7-.493 1.7-.45h.05q1.36.003 2.5-.15 2.319.84 4.65 1.5 2.562.69 5.15 1.1 2.587.352 5.2.4H192.55q2.763-.047 5.45-.45 2.688-.41 5.35-1.15 2.174-.562 4.35-1.4 1.194.153 2.55.15 1.025-.036 1.75.45.024.039.05.05.504.277.6.7z"
                    id="FacMou013__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M0 3.1q1.35.45 2.7.85l.8.2V1.4L.1 3.05q-.05 0-.1.05M7.9.05Q7.7 0 7.55 0h-.1Q6 0 4.25 1q.05.1.05.2v3.1q1.65.4 3.35.65.15 0 .25.05V.05m4.45 5.3v-4.1Q11.3 1.1 9.9.55h.05Q9.25.3 8.7.2v4.9q1.8.2 3.65.25m4.7-5.2q-.6.15-1.3.4-1.5.55-2.6.7v4.1q1.95-.05 3.9-.3V.15m4.65 1.2q0-.1.05-.15Q19.8 0 18.2 0q-.15 0-.35.05v4.9q.1-.05.2-.05 1.8-.25 3.65-.7-.05-.05-.05-.1l.05-2.75m3.85 1.7h-.05l-3-1.45-.05 2.45q.4-.1.85-.2 1.15-.35 2.35-.75-.05 0-.1-.05z"
                    id="FacMou013__Symbol_38_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou013__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou013__Symbol_38_0_Layer0_0_FILL"
                transform="translate(179.75 221.25)"
                id="FacMou013__col1n"
            />
        </g></g>
    )
}

export default FacMou013
