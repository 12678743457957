import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC6FF",
    "col1s": "#EE51EE"
}

function AccHat140({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M207.35 6.4q.1-.05.25-.1v-.05l-.05-.05q-18.8 4.55-34 12-8.9 4.35-27 16.5-17.65 11.1-26.45 17.65Q105 63.45 97.35 75.3q-.15-.05-.25 0-10.85-.5-21.65.85Q69.4 65.5 57.85 52.5q-14.1-15.15-20.8-23.3-10.4-12.55-18.35-18.95Q9.45 2.8 0 0q2.85 1.95 5.6 4.3l23.85 51.05q-1.2-.6-2.4-1.15 5 3.75 12.3 7.8 1.1.6 2.5 1.35 6 4.45 12.55 6.65 2.05 1.05 4.35 2.25 6.15 3.2 9.55 4.95-28.85 5.1-57.6 22.85 80.85-24.6 161.75 0-29.7-18.3-59.4-23.15 4.2-1 8.75-2.65 12.3-1.4 20.15-6.25 3.65-2.3 6.8-5.65 1.7-1.8 4.1-4.55 1.35-1.35 3.1-2.75 1.2-1.05 2.6-2.1l-.7.3L186.7 26.5q.2-.15.35-.3l.7-.6q1.7-1.8 3.85-4.9 3.4-5.05 5.45-7.5 3.2-3.85 10.3-6.8M96.55 79.75h-.5q.3-.35.85-.7l-.35.7z"
                    id="AccHat140__Symbol_482_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M8.5.75Q7.4.4 6.35.15 6.1.1 5.9.1.95-.65.15 3.5-.1 4.75.05 6.6q0 7.35.3 9.15.9 4.95 4.75 9.25 1.3 1.45 4.4 3.55 1.75 1.2 5.8 3.9 2.55 1.8 4.6 3.75 4.05 3.8 5.9 8 2.1 4.9 9.3 10.4.2.15.45.35 4.45 2.1 9.35 4.75 3.25 1.8 8 3.55 2.3.85 4.95 1.7 8.1 2.6 8.2 2.1-14.7-10.45-32-38.1-.95-1.5-1.9-2.95Q20.95 9.25 8.5.75M27 34.45h.05q0 .047.05.1l-.1-.1M226.85 15.2q.7-3.7.15-5.9-1.1-4.3-6.95-2.95l-3.9.6h-.1l.05.05v.05q-.15.05-.25.1-7.1 2.95-10.3 6.8-2.05 2.45-5.45 7.5-2.55 3.7-4.55 5.5-.45.35-1 .75-2.25 1.4-6.7 2.8-7.3 2.25-8.8 2.95-22.9 10.3-62.65 39.75 3.9-1.9 7.8-3.05 2.75-.85 8.7-1.8.75-.15 1.55-.25 7-1.15 10.15-2.1 5.15-1.65 7.45-4.5 2.7-3.35 8.75-5.55 3.8-1.35 6.25-2.25 5.15-2.9 10.95-5.95 5.8-3.1 7.5-5.55.95-1.2 4-2.55 3.05-1.4 18.25-2.3 8.45-1.7 13.2-6.95 5.1-5.6 5.9-15.2z"
                    id="AccHat140__Symbol_386_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat140__Symbol_482_0_Layer0_0_FILL"
                transform="translate(100.9 3.9)"
                id="AccHat140__col1n"
            />
            <use
                xlinkHref="#AccHat140__Symbol_386_0_Layer0_0_FILL"
                transform="translate(92.4 3.15)"
                id="AccHat140__col1s"
            />
        </g></g>
    )
}

export default AccHat140
