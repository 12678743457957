import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#B46BFF"
}

function SkiMak019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M10.2 0L8.95 9.1 0 11.25l8.3 3.95-.8 9.15 6.4-6.65 8.45 3.55-4.35-8.1 6-6.95-9.05 1.65L10.2 0z"
                    id="SkiMak019__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak019__Symbol_25_0_Layer0_0_FILL"
                transform="translate(241.4 185.65)"
                id="SkiMak019__col1n"
            />
        </g></g>
    )
}

export default SkiMak019
