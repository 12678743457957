import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function AccHat136({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M180.3 41.05q-5.55-12.35-15.25-20.6H165q-.65-1-1.3-1.95-1.6-2.2-3.45-4.25-4.5-4.95-10.7-9.05-52.1-10.35-109.8 0-1.8 1.1-3.4 2.25-4.8 3.35-8.65 7-2.5 2.35-4.6 4.85-.6.7-1.2 1.45h-.05q-11.15 9-15.5 22.8-.05.05-.05.1-.55 1.9-.8 3.95-.45.85-.85 1.75Q3.8 51.2 3.1 53.1q-.25.6-.45 1.25-2.5 7.2-2.65 14.4 27.6-7.4 54.85-12.2 2.2-.4 4.45-.75.15-.05.3-.05 7.25-1.3 14.45-2.3 3.65-.55 7.3-1 54.25 7.25 109.5 25.1.65-14.2-6.4-28.4-.6-.25-1.25-.5-.45-1.35-.9-2.65-.95-2.6-2-4.95z"
                    id="AccHat136__Symbol_478_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.8}
                    d="M56.95 42.7q7.25-1.3 14.45-2.3 3.65-.55 7.3-1-38.2-5.1-75.85-4.85-.45.85-.85 1.75-.85 1.85-1.55 3.75-.25.6-.45 1.25h.2q27.35-.6 52 2.2 2.2-.4 4.45-.75.15-.05.3-.05m122.7-9.75q-.95-2.6-2-4.95-35.4-13.75-73.4-19.75 28.3-2.2 58.1-.85-.65-1-1.3-1.95-1.6-2.2-3.45-4.25-38.95-3.6-76.3 3.7Q54.2.15 25.05 1.4q-2.5 2.35-4.6 4.85-.6.7-1.2 1.45 25-.15 55.3 2.75 2 .2 4.05.45 53.2 6.15 100.65 24.2.5 0 1.25.45 0 .05.05.05-.45-1.35-.9-2.65z"
                    id="AccHat136__Symbol_413_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M69.4 35.25q3.65-.55 7.3-1Q38.5 29.15.85 29.4q-.45.85-.85 1.75 34.5-.2 69.4 4.1m109.15-4.8q-.45-1.35-.9-2.65-44.15-16.65-93.25-23 8.85-1.05 17.85-1.7 28.3-2.2 58.1-.85-.65-1-1.3-1.95-49.9-1.4-81.05 4Q46.85-.55 18.45 1.1q-.6.7-1.2 1.45 25-.15 55.3 2.75 2 .2 4.05.45 53.2 6.15 100.65 24.2.5 0 1.25.45 0 .05.05.05z"
                    id="AccHat136__Symbol_384_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat136__Symbol_478_0_Layer0_0_FILL"
                transform="translate(97.85 67.45)"
                id="AccHat136__col1n"
            />
            <use
                xlinkHref="#AccHat136__Symbol_413_0_Layer0_0_FILL"
                transform="translate(100.5 80.5)"
                id="AccHat136__col1d"
            />
            <use
                xlinkHref="#AccHat136__Symbol_384_0_Layer0_0_FILL"
                transform="translate(102.5 85.65)"
                id="AccHat136__col1s"
            />
        </g></g>
    )
}

export default AccHat136
