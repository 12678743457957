import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col2d": "#121212",
    "col2n": "#252525"
}
function BodHan022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M28.3 13.6l1.2-6.05L8.3.2Q5.05 9.15 4.15 19.15q-.1.05-.15.15-.05 0-.05.05l-.05.05-.3.3Q0 23.85 0 29.45q0 6.2 4.45 10.6 4.4 4.4 10.65 4.4 2.9 0 5.4-.95.1-.05.2-.05.05-.05.15-.1h.05q.05-.05.15-.1.4-.15.75-.35l.8-.4q.1-.05.15-.1l.1-.05q1.5-.95 2.9-2.3 3.15-3.15 4.05-7.15 0-.15.05-.3l.35-1.25q.75-.5 1.4-1.2 1.95-1.95 1.95-4.7 0-1.75-.6-3.25-.65-1.55-1.85-2.4-1.25-.85-2.5-1.15h-1.2q.3-1.65.55-3.1.15-.75.35-1.95m142.2 26.45q4.4-4.4 4.45-10.6-.05-3.95-1.85-7.2-.75-1.35-1.8-2.55-.15-.15-.25-.3l-.1-.1q-.15-.15-.3-.35l.1.1Q169.5 9.4 166.1 0l-20.9 7.2 2.35 11.3q-.25.35-.3.35h-.05q-2.15.25-3.8 1.85-.7.7-1.15 1.55-.8 1.45-.75 3.2-.05 2.75 1.9 4.7.75.75 1.65 1.25l.1 1.2-.05-.05q0 .1.05.2.85 4.1 4.05 7.3 1.35 1.35 2.9 2.3.05 0 .1.05t.15.1q.35.2.75.4.35.2.75.35l.2.1h.05q.05.05.15.1.1 0 .2.05 2.5.95 5.4.95 6.25 0 10.65-4.4z"
                    id="BodHan022__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M163.5-7.05l-.15-.6q1 3.5 1.6 6.7 3.65-3.4 3.4-10.45-2.182 2.561-4.75 3.95 3.242-3.622 3-10.05-2.35 2.75-5.2 4.2l2.1 6.25M169.65-5q-2.25 2.65-4.7 4.05.435 2.465.7 4.9l-.15-.15q-.098-.087-.2-.2-4.283-3.649-10.05-3.6-5.65-.05-9.7 3.45l-.7.7q-.15.1-.2.2h-1.05q-.2-.05-.35 0-.25-.05-.4 0-.25 0-.45.05h-.05q-.25.05-.45.1-2.2.45-3.85 2.1-2.3 2.25-2.3 5.45t2.3 5.45q.95 1 2.15 1.55l.3.1q1 .4 2.25.55l.75.05h.1l1.5-.15q2.2-.4 3.9-2.1 2.25-2.25 2.3-5.45-.05-3.2-2.3-5.45-.95-1-2.05-1.5-.6-.3-1.25-.45.35-.35.5-.45Q150 .95 155.25 1q5.5-.05 9.55 3.5l.1.1v.05l.6.55v-.05l.7.65-.05-.25q3.698-3.407 3.5-10.55M142.6 5.45h.05q.4-.1.85-.1H143.95q.5.05 1 .15 1.85.35 3.3 1.8 1.95 1.95 1.95 4.75 0 2.75-1.95 4.7-1.45 1.45-3.35 1.85l-1.3.15h-.1l-.75-.05q-1.1-.1-2-.55-1.05-.5-1.95-1.4-1.95-1.95-1.9-4.7-.05-2.8 1.9-4.75 1.65-1.6 3.8-1.85m-143.25.3v.15l.1-.05L0 5.3v.1l1-.9Q5.05.95 10.55 1 15.8.95 19.6 4.2q.15.1.5.45-.65.15-1.25.45-1.1.5-2.05 1.5-2.25 2.25-2.3 5.45.05 3.2 2.3 5.45 1.7 1.7 3.9 2.1l1.5.15h.1l.75-.05q1.25-.15 2.25-.55l.3-.1q1.2-.55 2.15-1.55 2.3-2.25 2.3-5.45t-2.3-5.45Q26.1 4.95 23.9 4.5q-.2-.05-.45-.1h-.05q-.2-.05-.45-.05-.15-.05-.4 0-.15-.05-.35 0h-1.05q-.05-.1-.2-.2l-.7-.7Q16.2-.05 10.55 0 4.874-.048.6 3.5q-.135.154-.3.3-.044.044-.15.1.013.037 0 .05L0 4.1l.2-.25-.45.5q.343-2.566.8-5.1-2.45-1.4-4.7-4.05-.2 7.18 3.5 10.55m21.5-.25q.5-.1 1-.15H22.3q.45 0 .85.1h.05q2.15.25 3.8 1.85 1.95 1.95 1.9 4.75.05 2.75-1.9 4.7-.9.9-1.95 1.4-.9.45-2 .55l-.75.05h-.1l-1.3-.15q-1.9-.4-3.35-1.85-1.95-1.95-1.95-4.7 0-2.8 1.95-4.75 1.45-1.45 3.3-1.8M.55-.75q.584-3.195 1.4-6.35-2.516-1.388-4.8-4.1Q-3.1-4.15.55-.75m1.4-6.45q.799-2.996 1.8-6-2.52-1.343-4.85-4.1-.242 6.625 3.05 10.1z"
                    id="BodHan022__Kopie_von_Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan022__Symbol_42_0_Layer0_0_FILL"
                transform="translate(105.05 266.15)"
                id="BodHan022__col2n"
            />
            <use
                xlinkHref="#BodHan022__Kopie_von_Symbol_5_0_Layer0_0_FILL"
                transform="translate(109.65 279.55)"
                id="BodHan022__col2d"
            />
        </g></g>
    )
}
export default BodHan022
