import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#CCCCCC",
    "col1n": "#BFBFBF"
}

function ObjToo008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M52.85 48.65q1.3-3.2-.1-6.4-1.4-3.25-4.65-4.5-3.25-1.3-6.45.1-2.5 1.05-3.8 3.25L28.9 34l.6-17.85Q28.6 6.7 24.75 0L24 17.15l-1.2 12L0 10.7q2.3 7.4 8.6 14.5L22 36.85l-1.15 11.4q-2.35-.4-4.65.6-3.2 1.5-4.5 4.65-1.3 3.25.1 6.5 1.35 3.2 4.65 4.5 3.3 1.3 6.5-.1 3.2-1.4 4.45-4.65.7-1.6.65-3.2l.6-13.9 10 8.8.5.3q1.2 1.1 2.75 1.7 3.3 1.3 6.45-.1 3.25-1.4 4.5-4.7M44 40.2q.25-.05.55-.05.45 0 .85.2-.6-.15-1.25-.15H44m-.5.1q.6 0 1.15.15 1.55.25 2.7 1.4 1.6 1.65 1.6 3.9t-1.6 3.85q-1.6 1.6-3.85 1.6-1.35 0-2.45-.55-.8-.4-1.45-1.05-1.55-1.6-1.55-3.85 0-1.5.75-2.7.3-.65.8-1.2 1.6-1.5 3.75-1.55h.15m-20.95 8.25v.05q5.2-.55 5.2 5.05 0 1.05-.25 1.65-.1 1.25-1.5 2.95-1.95 2.3-4.85 2.3-.75 0-1.3-.3-.65 0-1.3-.25-1.5-.6-2.15-2.05-.65-1.45-.05-2.9.35-.8.95-1.35l-.05-.2q0-.35.1-.65v-.05q0-1.25 1.1-2.3.3-.5.85-.7 1.1-.75 2.35-1.05.3-.1.6-.1l.3-.1M21.5 53q0-.2.05-.3-.25.05-.45.15.1.05.15.05.15.05.25.1z"
                    id="ObjToo008__Symbol_168_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToo008__Symbol_119_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M0 24.85q.1.05.15.05.15.05.25.1 0-.2.05-.3-.25.05-.45.15zM.9 8.85L.7 10.9l6.8 5.5.05-1.75L.9 8.85M4 4.9q-.75 0-1.3.5-.5.55-.5 1.3t.5 1.25q.55.55 1.3.55t1.25-.55q.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5M7.8 6l.05-1.45L1.8 0l-.1 1.15L7.8 6z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo008__Symbol_168_0_Layer0_0_FILL"
                transform="translate(88 246.1)"
                id="ObjToo008__col1n"
            />
            <use
                xlinkHref="#ObjToo008__Symbol_119_0_Layer0_0_FILL"
                transform="translate(109.1 274.1)"
                id="ObjToo008__col1d"
            />
        </g></g>
    )
}

export default ObjToo008
