import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#666666"
}

function PlaFor029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M238.95 15.8q0-15.8-15.8-15.8H15.8Q0 0 0 15.8v64.4Q0 96 15.8 96h152.05q-4.05 16.55-16.75 27 27-3.95 40.6-27h31.45q15.8 0 15.8-15.8V15.8z"
                    id="PlaFor029__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M240.45 16.55Q240.45 0 223.9 0H16.55Q0 0 0 16.55v64.4Q0 97.5 16.55 97.5h151.1q-4.1 15.65-16.25 25.7-.2.15-.25.4-.1.2.05.45.1.25.3.35.2.15.45.1 27.15-4 40.95-27h31q16.55 0 16.55-16.55v-64.4M223.9 1.5q15.05 0 15.05 15.05v64.4Q238.95 96 223.9 96h-31.45q-.2 0-.35.1-.2.1-.3.3-12.75 21.6-37.45 26.2 11.2-10.25 15-25.65.05-.25-.05-.45-.1-.25-.25-.35-.2-.15-.45-.15H16.55Q1.5 96 1.5 80.95v-64.4Q1.5 1.5 16.55 1.5H223.9z"
                    id="PlaFor029__Symbol_43_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor029__Symbol_36_0_Layer0_0_FILL"
                transform="translate(140.5 5.5)"
                id="PlaFor029__col1n"
            />
            <use
                xlinkHref="#PlaFor029__Symbol_43_0_Layer0_0_FILL"
                transform="translate(139.75 4.75)"
                id="PlaFor029__col2n"
            />
        </g></g>
    )
}

export default PlaFor029
