import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}
function FacBro030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M187.1 19.2q6.55-7.3 11-17.85.1-.3.25-.55l.3-.8-.65.5q-1.65 1.25-6.4 3.8-4.7 2.5-14.85 4.1 1.35-.8 2.55-3.9.3-.7 1.45-4.35-6.8 5.05-15.65 8.3-10.2 3.75-20.25 3.65-10.05-.1-14.8.35-4.75.4-11.2 3.4 3.8-5.25 10.65-7.55-6.25-.05-10.65 1.4-13.7 6.8-17.95 26.45-1.05 3.8-1.55 8.2-.55-4.4-1.6-8.2Q93.5 16.5 79.8 9.7q-4.4-1.45-10.65-1.4Q76 10.6 79.8 15.85q-6.45-3-11.2-3.4-4.75-.45-14.8-.35-10.05.1-20.25-3.65Q24.7 5.2 17.9.15q1.15 3.65 1.45 4.35 1.2 3.1 2.55 3.9Q11.75 6.8 7.05 4.3 2.3 1.75.65.5L0 0l.3.8q.15.25.25.55Q5 11.9 11.55 19.2q8 8.95 17.35 11.45 9.2 2.45 19.1 2.8h1q5.95.15 17.9.1.5 0 5.2.15 4.65.1 9.5 1.35t8.55 3.8q3.65 2.55 5.4 3.7 1.7 1.1 2.1 1.3.35.2 1.6 1.15 0-.05.05-.1v-.05l.05.05q.05.05.05.1l.05.05.4-.4q.85-.65 1.15-.8.4-.2 2.1-1.3 1.75-1.15 5.4-3.7 3.7-2.55 8.55-3.8 4.85-1.25 9.5-1.35 4.7-.15 5.2-.15 11.95.05 17.9-.1h1q9.9-.35 19.1-2.8 9.35-2.5 17.35-11.45m.9-8.95q-.05 0-.1.05l.1-.2v.15m-177.35 0v-.15l.1.2q-.05-.05-.1-.05z"
                    id="FacBro030__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M89.9 35.55q-2.55-2.05-6.15-4.05-3.6-2-10.8-3.1-7.25-1.1-19.15-1.15-11.95-.1-16.8-1.3-4.9-1.2-9.9-2.7-5-1.5-10-5.15-5.05-3.7-7.1-6.3Q7.9 9.15 0 0q4.45 10.55 11 17.85 8 8.95 17.35 11.45 9.2 2.45 19.1 2.8h1q5.95.15 17.9.1.5 0 5.2.15 4.65.1 9.5 1.35t8.55 3.8q3.65 2.55 5.4 3.7 1.7 1.1 2.1 1.3.35.2 1.6 1.15 0-.05.05-.1-3.45-3.45-5.05-4.85-1.3-1.15-3.8-3.15M197.55 0q-7.9 9.15-10 11.8-2.05 2.6-7.1 6.3-5 3.65-10 5.15-5 1.5-9.9 2.7-4.85 1.2-16.8 1.3-11.9.05-19.15 1.15-7.2 1.1-10.8 3.1-3.6 2-6.15 4.05-2.5 2-3.8 3.15-1.5 1.3-4.55 4.35l-.5.5q.05.05.05.1l.05.05.4-.4q.85-.65 1.15-.8.4-.2 2.1-1.3 1.75-1.15 5.4-3.7 3.7-2.55 8.55-3.8 4.85-1.25 9.5-1.35 4.7-.15 5.2-.15 11.95.05 17.9-.1h1q9.9-.35 19.1-2.8 9.35-2.5 17.35-11.45 6.55-7.3 11-17.85z"
                    id="FacBro030__Symbol_30_0_Layer0_0_FILL"
                />
                <g id="FacBro030__Layer2_0_FILL">
                    <path
                        fill="#411F00"
                        d="M192.65 131.6l-.05-.05q0-.05-.05-.1l-.05.05v-.05q-.05.05-.05.1l.05.05v.05h.1v-.05h.05z"
                    />
                    <path d="M192.5 131.4v.1l.05-.05-.05-.05z" />
                </g>
            </defs>
            <use xlinkHref="#FacBro030__Layer2_0_FILL" />
            <use
                xlinkHref="#FacBro030__Symbol_31_0_Layer0_0_FILL"
                transform="translate(93.2 86.55)"
                id="FacBro030__col1n"
            />
            <use
                xlinkHref="#FacBro030__Symbol_30_0_Layer0_0_FILL"
                transform="translate(93.75 87.9)"
                id="FacBro030__col1d"
            />
        </g></g>
    )
}
export default FacBro030
