import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiHig041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M136.75 8.9q-7.35-3.5-15.25-5.65-4.85-1.3-9.9-2.05Q103.9 0 95.75 0 56.1 0 28 28 10.2 45.8 3.7 68.3 1.45 76.1.6 84.45 0 89.95 0 95.8q0 2.5.1 5l.45.45q1.75-2.15 2.95-3.4.85-1 1.4-1.4l1.3-1q2.7-2.1 5.15-3.85 4.8-3.45 8.65-5.6.05-.05.15-.05 2.7-1.55 4.95-2.45 11.2-4.45 24.75-7.45 7.85-1.75 23.65-4.7Q81.05 69.8 88.85 67q46.6-12.5 50.65-45.9.25-1.9.35-3.85.15-3.2-.05-6.55v-.2l-.3-.1v-.15q-1.35-.7-2.75-1.35z"
                    id="HaiHig041__Symbol_133_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M119 .25q-.45 2.65-1.1 5.6-.8 3.6-1.8 7.55-1.95 7.35-9.65 15.2t-14.8 12.8Q84.5 46.3 69.5 51.8q-15 5.45-27.75 8.3-12.75 2.8-17.3 4.6-4.55 1.75-12.25 4.95Q4.5 72.8 0 75.7q2.7-1.55 4.95-2.45 11.2-4.45 24.75-7.45 7.85-1.75 23.65-4.7 7.55-1.55 15.35-4.35 46.6-12.5 50.65-45.9.25-1.9.35-3.85.15-3.2-.05-6.55v-.2l-.3-.1q-.15-.1-.3-.15 0 .1-.05.25z"
                    id="HaiHig041__Symbol_132_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M84.3 12.2Q92.45 4.45 94.1 0q-.8.65-14 10.9-13.2 10.2-36.15 18.85Q21 38.4 12.3 42.15q-8.75 3.7-12.15 5.6L0 47.9q6.65-2.25 25.35-6.7 18.7-4.45 33.3-11.5Q73.2 22.65 84.3 12.2z"
                    id="HaiHig041__Symbol_131_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M131.5 5.05q-3.35-.65-7.6-1.65-4.25-1-10.5-1.65-6.3-.7-18.85-1.65Q93.8.05 93.1 0 62.15 1.2 41.35 17.05q-9.15 7.1-19.9 20.35-9.3 11.45-16.1 26.55Q4.1 68 0 81.2q2.35-4.35 5.4-8.75 3-4.45 11-14.5 7.95-10.1 18.45-18.4 10.5-8.35 17.65-12.4Q63.2 21 78.2 16.3q1.65-.5 10.5-2.75-7.8 6.15-15.65 12.25-7.9 6.1-15.6 11.25-.05 0-.1.05-.15.05-.15.2 12.85-3.15 23.5-8.35 3.35-1.65 16.75-9.4 17.1-9.9 19.75-11 2.85-1.2 6.55-2.05 3.45-.85 7.6-1.45h.15z"
                    id="HaiHig041__Symbol_130_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M102.45 3.4q-4.25-1-10.5-1.65-6.3-.7-18.85-1.65-.75-.05-1.45-.1Q40.7 1.2 19.9 17.05 10.75 24.15 0 37.4 39.05-2.4 110.05 5.05q-3.35-.65-7.6-1.65z"
                    id="HaiHig041__Symbol_129_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig041__Symbol_133_0_Layer0_0_FILL"
                transform="translate(96.25 58)"
                id="HaiHig041__col1n"
            />
            <use
                xlinkHref="#HaiHig041__Symbol_132_0_Layer0_0_FILL"
                transform="translate(116.4 68.25)"
                id="HaiHig041__col1d"
            />
            <use
                xlinkHref="#HaiHig041__Symbol_131_0_Layer0_0_FILL"
                transform="translate(126.1 78.75)"
                id="HaiHig041__col1l"
            />
            <use
                xlinkHref="#HaiHig041__Symbol_130_0_Layer0_0_FILL"
                transform="translate(102.55 63.2)"
                id="HaiHig041__col2n"
            />
            <use
                xlinkHref="#HaiHig041__Symbol_129_0_Layer0_0_FILL"
                transform="translate(124 63.2)"
                id="HaiHig041__col2l"
            />
        </g></g>
    )
}
export default HaiHig041
