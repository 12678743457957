import { AssetSvgProps } from "../../../shared/assets"

function ShiMot104({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot104__Layer1_0_FILL">
                    <path
                        fill="#9B00C2"
                        d="M159.2 294.05v.05h66.5v-.05h-66.5z"
                    />
                    <path d="M159.2 288.9v5.15h66.5v-5.15q-33.053-5.134-66.5 0m0-30.9v5.15q34.215 5.402 66.5 0V258h-66.5z" />
                    <path
                        fill="#FFF"
                        d="M159.2 268.3v5.15q9.247 1.118 18.35 1.6-9.19 1.117-18.35 3.55v5.15q33.84 4.33 66.5 0v-5.15q-9.118-2.393-18.2-3.5 9.174-.492 18.2-1.65v-5.15q-33.25-4.71-66.5 0z"
                    />
                </g>
                <g id="ShiMot104__Layer1_1_FILL">
                    <path
                        fill="#C2C2C2"
                        d="M159.2 283.75v5.15h66.5v-5.15h-66.5m0-20.6v5.15h66.5v-5.15h-66.5z"
                    />
                    <path
                        fill="#ADD366"
                        d="M159.2 273.45v5.15h66.5v-5.15h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot104__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot104__Layer1_1_FILL" />
        </g></g>
    )
}

export default ShiMot104
