import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343",
    "col2n": "#FFFFFF",
    "col2s": "#CCCCCC"
}

function HaiSpe044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M188.9 116.4q-.05-.05-.05-.1-6.45-23.15-24.65-41.35Q136 46.8 96.15 46.8q-39.85 0-68 28.15T0 142.95v.65q-.05 3.15.55 6.05 1.9.5 4.7 4.7 2.9 4.4 5.65 11.1-.15-5.35-.15-5.55.3-7.75 2-14.75 3.15-12.75 9.15-14 7.1-2.6 8.3-8.05 1.15-5.4-.85-17.1-.3-1.9-.45-3.6l-.05-.4q-.2-5.3 1.25-9.75.25-.65.6-1.2.35-.65.75-1.2l1.85-2 .4-.3q5.1-5.05 12.65-7.05l.85-.2q1.25-.3 1.75-.35.45-.05 2.25-.4l-1.2.2q.15-.05.25-.05.1-.05.25-.05l.7-.1q7.7-1 15.05 1.7.85.25 1.65.55l.55.2q.05 0 .1.05.1.05.15.05 10.7 3.6 21.2 4.5 2.65.2 5.3.25h1.9q1.2 0 2.4-.05 11.95-.6 24.1-4.7.05 0 .15-.05.05-.05.1-.05l.8-.3q.7-.25 1.4-.45 7.35-2.7 15.05-1.7l.7.1q.15 0 .25.05.1 0 .25.05l-1.2-.2q1.8.35 2.25.4.5.05 1.75.35l.85.2q7.55 2 12.65 7.05l.4.3 1.85 2q.4.55.75 1.2.35.55.6 1.2 1.45 4.45 1.25 9.75l-.05.4q-.15 1.7-.45 3.6-2 11.7-.85 17.1 1.2 5.45 8.3 8.05 6 1.25 9.15 14 1.7 7 2 14.75 0 .2-.15 5.55 2.75-6.7 5.65-11.1 2.8-4.2 4.7-4.7.75-4.35.6-9.25l-.1-2.1q-.15-3.3-.45-6.5-.4-3.55-1-7-.8-4.25-1.9-8.35v-.05M163.35 12.6q.2-.15-.6.3-.8.45-3.9.8-2.1.3-5 .3-18.95 0-26.3-6.2-1.55-1.35-2.8-2.2-.4-.35-.75-.6-8.35-5.3-16.3-5-4.95.05-8.3 1.15-7.7 2.45-11.05 10.1-2.95-4.85 1.1-9.75-5.1 1.7-6.8 3.55-2.4 2.4-2.4 7.55 0 2.5.95 5 .5 1.45 1.35 2.9.65 1.2 1.4 2.1-2.05 1.25-3.4 2.5-3.9 3.4-3.5 6.6 1.9 7.4 10.25 8.4v2.1h17.85v-3.8q6.3-1.45 8.85-2.65 1.6-.8 3.2-1.45-4.9.55-10.4-3.35 1.9-1.25 5.9-.75 2.2 0 7.4 1.7.95.3 2.05.7 5.9 2.1 7.25 3l.1-.5q-2.2-6.7-9.4-9.25-1.9-.65-4.15-1.05 1.8-1.4 4.15-1.8 1.3-.25 2.9-.35 4.4 0 9.65 2.7 5.15 2.7 12 2.7 3.2 0 7.65-1.7 1.7-.65 4.2-1.7h.05q.95-.4 2.05-.85.05 0 .1-.05-5.55.6-9.65-1.75-4.15-3.15-6.9-4.65.55.25 3.65.45 10.5.3 16.25-4.05l.05-.05q.7-.55 1.3-1.1h-.05z"
                    id="HaiSpe044__Symbol_176_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M102.1 55.7q.2 1.8 0 3.7-.65 6.5-6.05 13.6.25-5.55.35-9.15.1-4.25-.1-5.6 0-.15-.05-.3-.1-.4-.25-.75-1.1-2.6-4.85-2.6-3.6 0-4.4 1.7-.2.35-.25.8 0 .3-.05.6-1.3 9.55-8.8 14.75.5-.85.9-1.7Q82.2 63.6 82.9 57.1q0-1.15-.55-1.85-.85-1-2.75-1.15h-6q-2.8.55-4.95 17.2Q67 54.55 64 54.1h-7.2q-2.2.45-2.6 2.8.65 6.6 4.35 13.85.4.85.9 1.7-7.5-5.2-8.8-14.75-.05-.3-.05-.6-.25-2.5-4.65-2.5-3.75 0-4.85 2.6-.15.35-.25.75-.25 1.1-.15 5.9.1 3.6.35 9.15-7.05-9.25-6-17.5l-.3.2q-4.4 2.7-5.8 4.35-3.05 3.3-4.55 11.25-2.25-3.6-2.3-10.9v-.9q-.35.65-.75 1.25-6.9 11.6-12.55 18.7-5.95 7.4-6.35 8.3-.45.9-.5 1.05-.1.2-1.45 3.3-1.35 3.05.8 10.7v-.4l-.05-.4q-.2-5.3 1.25-9.75l.1-.3q.2-.45.45-.9.35-.65.8-1.2l1.85-2 .4-.3q5.1-5.05 12.65-7.05l.85-.2q7.2-2 7.95-5.7l.1-.5q.1-.5.1-1.1.15-3.7.85-6.5 1.15-3.35 4.05-6.95L31.1 62.9q-1.4 4.65-1.4 6.55v.25q.05 3.45 1.85 6.15 2.75 3.7 8.75 5.95l.55.2q.05 0 .1.05.1.05.15.05 10.7 3.6 21.2 4.5 2.65.2 5.3.25h1.9q1.2 0 2.4-.05 11.95-.6 24.1-4.7.05 0 .15-.05.05-.05.1-.05l.8-.3q5.8-2.25 8.5-5.85 1.8-2.7 1.85-6.15v-.25q0-1.9-1.4-6.55l-1.55-3.35q.15.2.3.35 2.65 3.4 3.75 6.6.7 2.8.85 6.5 0 .6.1 1.1l.1.5q.75 3.7 7.95 5.7l.85.2q7.55 2 12.65 7.05l.4.3 1.85 2q.45.55.8 1.2.25.45.45.9l.1.3q1.45 4.45 1.25 9.75l-.05.4v.4q2.15-7.65.8-10.7-1.35-3.1-1.45-3.3-.05-.15-.5-1.05-.4-.9-6.35-8.3-5.25-6.6-11.55-17.05-.85-1.4-1.75-2.9V62q-.3 6.1-2.3 9.3-1.35-7.2-4-10.6-.25-.35-.55-.65-1.4-1.65-5.65-4.25-.1-.05-.15-.1l-.3-.2q0 .1.05.2m29.15-42q-2.15.35-5.1.35-18.95 0-26.3-6.15-1.6-1.35-2.7-2.3-.4-.35-.75-.6-8.35-5.3-16.3-5-4.95.05-8.3 1.15-7.7 2.45-11.05 10.1.8.5 1.8 1.65.95 1.1.85 3.3 2.1-.7 3.2-6.6 3.15 5.75 3.85 8.65.3-1.85 1.7-3.9 3-4.75 3.65-5.2-.85 6.7.4 11.3 1.2 4.55 1.15 5.3l.25.1q.2-8 2.2-11.8 1.95-3.8 6.45-3.8t10.7 5.15q6.15 5.15 15.1 8.8 8.9 3.6 16.85.45h.05q.95-.4 2.05-.85-5.55.6-9.65-1.75-4.3-3.25-7.1-4.75h.25l.05.05q.55.25 3.65.45 10.5.3 16.25-4.05l.05-.05q.7-.55 1.25-1.1.2-.15-.6.3-.8.45-3.9.8z"
                    id="HaiSpe044__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.4.25V.3h-.2q-1.55.65-3.15 1.05-.3.1-.65.2-4.3.9-9.1-.2Q2.2.85 0 0v22.3q1.25-.1 2.6-.15.85-.05 1.7-.05 1.3-.1 2.6-.05.55-.05 1.15 0h.05q.25-.05.55 0h.1q.05-.05.1 0 .1-.05.25 0 .25-.05.6 0h.25q.3-.05.65 0 1.4-.05 2.8.05.1-.05.2 0H14.05q.55 0 1.1.05h.2q.95.05 1.85.1h.1q.25 0 .55.05V0q-.25.1-.45.25z"
                    id="HaiSpe044__Symbol_34_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M17.85 11.6L0 12.25v1.7l17.85-.75v-1.6m-9.1 8.35q.05-.05.1 0 .1-.05.25 0 .25-.05.6 0h.25q.3-.05.65 0 1.4-.05 2.8.05.1-.05.2 0H14.05q.55 0 1.1.05h.2q.95.05 1.85.1h.1q.25 0 .55.05L0 16.35v1.85l8.75 1.75m9.1-18.15V0L0 8.85v1.8L17.85 1.8z"
                    id="HaiSpe044__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe044__Symbol_176_0_Layer0_0_FILL"
                transform="translate(96.3 10.8)"
                id="HaiSpe044__col1n"
            />
            <use
                xlinkHref="#HaiSpe044__Symbol_64_0_Layer0_0_FILL"
                transform="translate(123.9 10.8)"
                id="HaiSpe044__col1l"
            />
            <use
                xlinkHref="#HaiSpe044__Symbol_34_0_Layer0_0_FILL"
                transform="translate(183.6 35.65)"
                id="HaiSpe044__col2n"
            />
            <use
                xlinkHref="#HaiSpe044__Symbol_11_0_Layer0_0_FILL"
                transform="translate(183.6 37.75)"
                id="HaiSpe044__col2s"
            />
        </g></g>
    )
}

export default HaiSpe044
