import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.8.95l-.4.2q-3.7 1.7-9.5 1.7-.2 0-7.05-.75-7.15-.75-8.75-.75-3.2 0-6.7 1.35-7.6 2.2-11.25 9.65-1.3 2.3-2.05 4.8-.45 1.4 1.35.2 1.85-1.2 6.7-4.45 4.9-3.25 11.35-2.55 6.55.7 11.65.8 5.15.1 9.65-1.2t8.35-8.45q1.7-2.7-3.35-.55M96.85 12.9q4.85 3.25 6.7 4.45 1.8 1.2 1.35-.2-.75-2.5-2.05-4.8Q99.2 4.9 91.6 2.7q-3.5-1.35-6.7-1.35-1.6 0-8.75.75-6.85.75-7.05.75-5.8 0-9.5-1.7l-.4-.2q-5.05-2.15-3.35.55 3.85 7.15 8.35 8.45 4.5 1.3 9.65 1.2 5.1-.1 11.65-.8 6.45-.7 11.35 2.55z"
                    id="FacBro024__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro024__Symbol_24_0_Layer0_0_FILL"
                transform="translate(140 120.8)"
                id="FacBro024__col1n"
            />
        </g></g>
    )
}

export default FacBro024
