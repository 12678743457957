import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function ObjToy013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M102.35 16.55q-4.1-12.5-20.1-15.45-16.8-3.1-38 2.45-21.05 5.7-34.1 16.85Q-2.25 31.05.5 43.9l.2.9q2.8 10.15 19.55 13.35 9.35 1.75 19.9.75 8.65-.75 18.05-3.3 9.5-2.5 17.3-6.15 9.7-4.45 16.9-10.6 13-11.15 10.25-21.3-.15-.45-.3-1z"
                    id="ObjToy013__Symbol_107_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M45.5 34.15Q62.5 29.6 73.4 22q11-7.8 9.2-14.15Q80.95 1.4 67.55.25q-13.2-1.2-30.2 3.35T9.5 15.75Q-1.55 23.5.25 29.9q1.65 6.4 15.05 7.55 13.25 1.25 30.2-3.3m27.55-12.7q-10.8 7.65-27.7 12.2-16.9 4.5-30.05 3.35Q2.4 35.8.8 29.85q-1.6-6.1 9-13.6 10.75-7.6 27.65-12.1Q54.35-.4 67.55.75 80.4 2 82.15 8q1.45 5.95-9.1 13.45M78 8.75q-1.4-5.35-13.25-6.1Q53 1.85 37.8 5.9 22.7 9.95 12.95 16.5q-10 6.6-8.45 11.95 1.35 5.35 13.3 6.15 11.7.75 26.9-3.35 15.1-4 24.85-10.5 9.9-6.65 8.45-12M64.65 3.2q11.45.7 12.8 5.7 1.35 4.95-8.2 11.35-9.7 6.55-24.75 10.55-15.05 4.1-26.7 3.25Q6.35 33.3 5 28.35 3.65 23.3 13.15 16.9q9.8-6.4 24.8-10.5Q53 2.45 64.65 3.2M74 9.55Q72.95 5.35 62.4 5q-10.2-.4-23.7 3.25T16.4 17.3q-8.95 5.55-7.75 9.8 1.05 4.25 11.65 4.55 10.2.4 23.75-3.25 13.45-3.65 22.25-9.1 8.9-5.45 7.7-9.75M62.45 5.5q10 .3 11.05 4.25 1.05 3.85-7.45 9.15-8.8 5.45-22.15 9-13.4 3.6-23.6 3.3-10-.3-11.15-4.25-1-3.9 7.5-9.2 8.75-5.4 22.15-8.95 13.35-3.6 23.65-3.3z"
                    id="ObjToy013__Symbol_67_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy013__Symbol_107_0_Layer0_0_FILL"
                transform="translate(274.35 189.95)"
                id="ObjToy013__col1n"
            />
            <use
                xlinkHref="#ObjToy013__Symbol_67_0_Layer0_0_FILL"
                transform="translate(282.35 193.6)"
                id="ObjToy013__col1d"
            />
        </g></g>
    )
}

export default ObjToy013
