import { AssetSvgProps } from "../../../shared/assets"

function ShiSho030({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#00F5AA"
                    d="M141.15 234.9q-1.258-.792-2.5-1.65l-1.55-1.15q-.252-.182-.5-.4-1.184-.832-2.3-1.7l-1.85-1.4-.05-.05q-.04.11-.1.2-2.568 3.454-4.9 7.2-.399.678-.8 1.35l-.45.75q-.077.168-.15.3-.222.381-.45.75l-.45.75q-.063.193-.15.35-1.055 1.894-2.05 3.85-.016.032-.05.05-.866 1.785-1.7 3.6-1.265 2.735-2.45 5.6-.037.148-.1.25-.046.164-.1.3-.416 1.066-.85 2.15-.15.473-.35.95h.05L136.3 264v.05L133.6 278q-.46 2.241-.9 4.45-.119.527-.25 1.05l-3.4 14.3q-.687 2.727-1.4 5.45-.277 1.179-.6 2.35l-.25 1.05v.05l-.65 2.5v.1l-.05.15h.05q.217.052.4.1.824.19 1.5.35.862.2 1.5.35.785.176 1.55.35.601.136 1.2.25 7.562 1.698 15.1 2.95 6.986 1.17 13.95 1.95 5.72.687 11.4 1.1 16.57 1.228 33.05.4 1.11-.03 2.2-.1 13.98-.797 27.9-3.05l.15-.05q1.008-.138 1.95-.3 7.923-1.343 15.8-3.15 2.092-.475 4.15-.95H258.1q.25-.05.5-.15.109 0 .2-.05l-.05-.15v-.1l-6.05-24.25q-.61-3.092-1.25-6.2l-2.9-14.65v-.05h.05l19.05-7.1q-.15-.477-.35-.95-.384-1.084-.8-2.15-.158-.395-.35-.8-.012-.057-.05-.15-.176-.437-.35-.9l-1.6-3.7q-.153-.282-.3-.6-1.525-3.32-3.25-6.45-.284-.583-.6-1.15-.963-1.732-2-3.4-.038-.1-.1-.2-.58-.965-1.2-1.9-.434-.713-.9-1.45-.054-.055-.1-.15-1.525-2.238-3.15-4.4l-1.85 1.4q-.297.231-.6.45-1.07.831-2.2 1.65l-1.55 1.15q-.623.43-1.25.85-.042.028-.1.05-4.518 3.028-9.3 5.45-4.805 2.454-9.85 4.3-.19.058-.4.1-3.36 1.242-6.85 2.2-4.642 1.244-9.5 2-.121.05-.3.05-3.728.633-7.6.95h-.15q-.277.03-.55.05l-1.1.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-4.225-.315-8.3-1-1.49-.21-2.95-.5-7.045-1.334-13.65-3.7-.078-.024-.15-.05-3.224-1.168-6.35-2.6-5.11-2.274-9.95-5.25-.226-.112-.45-.25-.779-.478-1.55-1z"
                    id="ShiSho030__Layer3_0_FILL"
                />
                <g id="ShiSho030__Layer3_1_FILL">
                    <path
                        fill="#62D7FF"
                        d="M185.9 305.3l-10.35-18-21.1 24.45 31.45-6.45m29.75.85l.15.05-10.1-20.65-19.8 19.75 29.75.85m26.8-12.5L224.3 280.4l-8.45 25.65 26.6-12.4m10.25-8.7q-.61-3.092-1.25-6.2l-.4-2.05-8.45-11.7-.05.25v28.4l11.75-2.05-.05-.15q.057.01.1 0l-1.65-6.5M127.65 303.4l.05-.2-.05.05q-.277 1.179-.6 2.35l-.25 1.05.1.05 27.55 5.05-14.9-20.2-11.9 11.85z"
                    />
                    <path
                        fill="#004FFF"
                        d="M205.65 317.2h.05l-19.8-11.9-13.5 11.45.45.05q-.036.028-.1.05 16.57 1.228 33.05.4-.06-.004-.15-.05m-2 .1l-.15.05q-.052-.007-.1-.05.136.03.25 0m4.25-.2q.113-.007.2-.05-.032.065-.1.1 13.98-.797 27.9-3.05l-20.1-7.9-7.9 10.9m7.85-11l.05.1v-.05l-.05-.05m26.8-12.45h-.1l-4.45 20.1q7.923-1.343 15.8-3.15 2.092-.475 4.15-.95H258.1q.25-.05.5-.15.109 0 .2-.05l-.05-.15v-.1l-1.3-5.15h-.05l.05.05-14.9-10.45m-116.4 15.55v.1l-.05.15h.05q.217.052.4.1.824.19 1.5.35.862.2 1.5.35.785.176 1.55.35.601.136 1.2.25l-5.5-4.15-.65 2.5m28.3 2.55l-7.05 2.05q6.986 1.17 13.95 1.95-.058-.012-.15-.05.34.041.65.05l-7.4-4z"
                    />
                    <path
                        fill="#3595FF"
                        d="M203.5 317.35l.15-.05q-.114.03-.25 0 .048.043.1.05m12.3-11.15l-.15-.05-29.75-.85 19.8 11.9h-.05q.09.046.15.05 1.11-.03 2.2-.1.068-.035.1-.1-.087.043-.2.05l7.9-10.9m20.25 7.85q1.008-.138 1.95-.3l4.45-20.1-26.6 12.4-.1.05.05.05v.05l20.1 7.9.15-.05m18.2-22.6l.05.15-11.75 2.05 14.9 10.45-.05-.05h.05l-3.1-12.6q-.043.01-.1 0M161.2 315.7q.092.038.15.05 5.72.687 11.4 1.1.064-.022.1-.05l-.45-.05 13.5-11.45-31.45 6.45 7.4 4q-.31-.009-.65-.05m-13.8-1.9l7.05-2.05-27.55-5.05-.1-.05v.05l5.5 4.15q7.562 1.698 15.1 2.95z"
                    />
                    <path
                        fill="#00B8D7"
                        d="M205.7 285.55l-30.15 1.75 10.35 18 19.8-19.75m18.6-5.15l-18.6 5.15 10.1 20.65-.05-.1.1-.05 8.45-25.65m18.25-15.15v-.35l-18.25 15.5 18.15 13.25h.1v-28.4m6-1.2l.25-.1-6.15.9v.05l-.05.1 8.45 11.7-2.5-12.6v-.05m-109 27.5l14.9 20.2 21.1-24.45-36 4.25m-.05-.05l-6.75-8.75q.015-.013 0-.05-.032-.124-.05-.25-.119.527-.25 1.05l-3.4 14.3q-.687 2.727-1.4 5.45l.05-.05-.05.2 11.9-11.85-.05-.05z"
                    />
                    <path
                        fill="#00BB6D"
                        d="M205.7 285.55l6.7-24.15-23.7 6.7 17 17.45m18.6-5.15l6.2-26.85-18.1 7.85 11.9 19m6.2-26.85l12.05 11.35v-.05h.05l8.95-23.05-21.05 11.75m15.5-6l.25.35-.3-.1.05-.25m-70.45 39.75l13.15-19.2-31.45-2.85 18.3 22.05m85.05-46.05l-9.05.55 12.75 16.4 1.8-5.3q-.176-.437-.35-.9l-1.6-3.7q-.153-.282-.3-.6-1.525-3.32-3.25-6.45M136.3 264v.05l3.2 27.45 17.75-26.25L136.3 264z"
                    />
                    <path
                        fill="#00BB2F"
                        d="M209.1 248.2q-.121.05-.3.05l3.6 13.15 6.2-15.2q-4.642 1.244-9.5 2m-7.9 1h-.15q-.277.03-.55.05l-1.1.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-4.225-.315-8.3-1v.05q-.398-.078-.85-.15l13.35 19.95 12.5-18.9m24.65-5.3q-.19.058-.4.1l5.05 9.55 5.2-13.95q-4.805 2.454-9.85 4.3m29.95-10.75q-.054-.055-.1-.15-1.525-2.238-3.15-4.4l-1.85 1.4q-.297.231-.6.45-1.07.831-2.2 1.65l-1.55 1.15q-.623.43-1.25.85l6.45 7.7 4.25-8.65m-106.3 14.8h-.4l-22.05.25.1.2 9.15 15.6 13.2-16.05m-7.2 4.05l-.1.1h-.05l-.1.05-.25-.6.5.45m-23.6 1.3q-.037.148-.1.25.068-.104.1-.25m40.9-9.25q-.078-.024-.15-.05l-9.95 3.95 7.75 17.3 16.1-17.45q-.049-.014-.15-.05h.05q-7.045-1.334-13.65-3.7m-36.7 0q-.016.032-.05.05-.866 1.785-1.7 3.6-1.265 2.735-2.45 5.6l8.35-5.1-4.15-4.15z"
                    />
                    <path
                        fill="#999"
                        d="M242.65 264.85h-.1V265.25l.05-.25.05-.1v-.05m3.6-16.95l-.25-.35-.05.25.3.1m-73.8 13.4h-.05l-.55.35.6-.35m1.95-3.15l.1-.1-.2-.05.1.15m32.45-5.1l-.2.05.2.05h.05l-.05-.1m-64.65-.95l.1-.1-.5-.45.25.6.1-.05h.05z"
                    />
                    <path
                        fill="#00FF5C"
                        d="M176.2 248.25q-1.49-.21-2.95-.5h-.05q.101.036.15.05l-16.1 17.45 31.45 2.85-13.35-19.95q.452.072.85.15v-.05m-1.7 9.8l-.1.1-.1-.15.2.05m-2.1 3.25h.05l-.6.35.55-.35m28.8-12.1l-12.5 18.9 23.7-6.7-3.6-13.15q-3.728.633-7.6.95m5.45 3.9l.2-.05.05.1h-.05l-.2-.05m18.8-9.1q-3.36 1.242-6.85 2.2l-6.2 15.2 18.1-7.85-5.05-9.55m19.65-9.9q-.042.028-.1.05-4.518 3.028-9.3 5.45l-5.2 13.95 21.05-11.75-6.45-7.7m11.6.5q-.434-.713-.9-1.45l-4.25 8.65 9.05-.55q-.284-.583-.6-1.15-.963-1.732-2-3.4-.038-.1-.1-.2-.58-.965-1.2-1.9m-99.45 30.65l-7.75-17.3L136.3 264l20.95 1.25M118.7 253.3q-.032.146-.1.25-.046.164-.1.3-.416 1.066-.85 2.15-.15.473-.35.95h.05l17.2 6.4q.037-.049.05-.1-.003-.025 0-.05v-.1h.15q.07.186.15.35l1.4.55-9.15-15.6-.1-.2-8.35 5.1z"
                    />
                    <path
                        fill="#00F5AA"
                        d="M134.6 263.1v.1q-.003.025 0 .05-.013.051-.05.1l.35.1q-.08-.164-.15-.35h-.15z"
                    />
                    <path
                        fill="#34DE00"
                        d="M153.1 241.45v-.05q-5.11-2.274-9.95-5.25-.226-.112-.45-.25l6.4 12.05h.4l3.6-6.5m-11.95-6.55q-1.258-.792-2.5-1.65l-1.55-1.15q-.252-.182-.5-.4-1.184-.832-2.3-1.7l-1.85-1.4-.05-.05q-.04.11-.1.2-2.568 3.454-4.9 7.2l-.35 12.25.3-.35 13.8-12.9v-.05z"
                    />
                    <path
                        fill="#56FF65"
                        d="M153.1 241.4v.05l-3.6 6.5 9.95-3.95q-3.224-1.168-6.35-2.6m-25.7-5.45q-.399.678-.8 1.35l-.45.75q-.077.168-.15.3-.222.381-.45.75l-.45.75q-.063.193-.15.35-1.055 1.894-2.05 3.85l4.15 4.15.35-12.25m13.75-1.05v.05l-13.8 12.9-.3.35 22.05-.25-6.4-12.05q-.779-.478-1.55-1z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiSho030__Layer3_0_FILL" />
            <use xlinkHref="#ShiSho030__Layer3_1_FILL" />
        </g></g>
    )
}

export default ShiSho030
