import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}

function BodTai007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M145.95 12.55q.4.65.7 1.4-.85-4-5.05-7.9-2.95-2.75-6.2-4.05-12.45-5.35-20.45 3.55-9 10.4-4.3 23.6.25.9.65 1.85 1.65 5.9 5.2 15.85 4.25 12.15 5.9 18.85 2.7 10.9 2.7 20.95 0 13.95-4.9 25.3-4.75 11-12.6 16.1 2.5.5 5.75.5-1.75 1.85-3.4 3.5-20.15 16-49.85 12.85-1.4-.25-2.8-.6-6.8-2-7.45-2h-.5q.35.45.95 3.05.55 2.5.55 3.45-25-2.15-39.85-15.7-7.6-6.95-11-15.3 5.6 16.55 21.15 28.5 17.3 9.75 44.05 10.25h3.4q-2.4-.15-5.1-1.95-2.4-1.6-3.15-3.05v-.25h1.5q1.15.35 7.25.75 17.15 0 32.75-7.7.1-.1.25-.15 15.95-8 24.6-23.05-.8.3-2.2.6-1.9.45-4.2.85 12.6-13.5 11.8-34.95-.4-5.9-1.55-12.75-.6-3.3-1.3-6.8-.25-1-.45-2-1.3-4.4-5.6-18.85-.5-1.75-1-3.35-.25-.9-.45-1.7-.2-.55-.3-1.05-.15-.4-.25-.8-.1-.3-.15-.6-.55-1.9-.95-3.6-2.5-9.65-2.5-13.6 0-.8.05-1.5-.05-.55-.05-1.1 0-6.25 4.4-10.7 2-2 4.3-3 2.9-1.45 6.4-1.45 4.4 0 7.9 2.2 1.5.95 2.8 2.25 1.5 1.5 2.5 3.2 0 .05.05.1z"
                    id="BodTai007__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M144.05 20.85Q140.85 8.2 127.5 2.2q-.9-.35-1.8-.65Q121.05 0 115.75 0q-12.85 0-22 9.1-3.85 3.9-6.1 8.5-1.05 2.1-1.7 4.35-3.7 10.4-3.85 20.85-.1 7.05 1.4 14.05 1.4 6.45 4.15 12.95.1.45.25.95h-.25q-1.75-.95-3.1-1.6-2.65-1.25-4.15-1.4 5.3 23.55-5.6 45-2.5 5.4-5.9 9.8l-.2.2q-3.4 3.4-7.3 6.05-11.7 7.95-25.25 7.95-12.9 0-21.4-4.15-7.7-3.8-14.75-12.4 4.3 10.35 12.65 18.95Q29.9 157 54.3 157t41.65-17.85q2.15-2.25 4.1-4.6l-5.4 1.45q18.4-13.85 18.55-42.1-.25-5.95-1.5-12.15-1-4.45-2.6-8.6-1.2-4.3-2.8-8.45l-4.25-10.95q-1.9-5.85-1.9-11 0-3.35.5-6.3.4-3.5 1.75-6.6 1.9-4.5 5.6-8.25 7.65-7.6 18.4-7.6 10.15 0 17.5 6.75.05.05.15.1M95.3 70.5l.25.15h-.5q.1-.1.25-.15z"
                    id="BodTai007__Symbol_18_0_Layer0_0_FILL"
                />
                <g
                    id="BodTai007__Symbol_31_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M145.85 11.25l-3.9-3.4Q134.4 2.15 124.5.9q-3.05-.55-6.15-.75l-1.6-.1q-.45-.05-.9-.05-17.55-.5-31.6 11.95l-.1.1Q67.9 28.45 71.95 55.7q.65 4.4 1.85 9.1-.7-.25-1.55-.55-2.65-.95-3.65-1.3-.05 1.9.45 5.35.5 3.45.5 5.75 0 2.35-.2 4.75-.15 11.55-3.55 21.25-2.05 5.75-5.25 10.85-10.95 18.75-29 22.3-13.3 2.6-30.4-3l-.8-.3-.3-.1q-.2 5.15.3 10 .7 6.95 2.85 13.25 5.6 16.55 21.15 28.5 17.3 9.75 44.05 10.25h3.4q-2.4-.15-5.1-1.95-2.4-1.6-3.15-3.05v-.25h1.5q1.15.35 7.25.75 17.15 0 32.75-7.7.1-.1.25-.15 15.95-8 24.6-23.05-.8.3-2.2.6-1.9.45-4.2.85 12.6-13.5 11.8-34.95-.4-5.9-1.55-12.75-.6-3.3-1.3-6.8-.25-1-.45-2-1.3-4.4-5.6-18.85-.5-1.75-1-3.35-.25-.9-.45-1.7-.2-.55-.3-1.05-.15-.4-.25-.8-.1-.3-.15-.6-.55-1.9-.95-3.6-2.5-9.65-2.5-13.6 0-.8.05-1.5-.05-.55-.05-1.1 0-6.25 4.4-10.7 2-2 4.3-3 2.9-1.45 6.4-1.45 4.4 0 7.9 2.2 1.5.95 2.8 2.25 1.5 1.5 2.5 3.2 0 .05.05.1.4.65.7 1.4 1.2 2.75 1.2 6 0 2.5-.7 4.75l-.2.6q2.95-4.1 4.65-8.65l.7-2.1q.9-3.05 1.3-6.3.3-2.5.3-5.1 0-2.4-.25-4.7-.45-4.15-1.75-7.95-1.55-4.55-4.3-8.55l-4.95-5.95m-39.1 32.6l-.3-.2.3.15v.05m-9.3 40.1q.1-.1.25-.15l.25.15h-.5z" />
                    <path d="M106.45 43.65l.3.2v-.05l-.3-.15z" />
                </g>
            </defs>
            <use
                xlinkHref="#BodTai007__Symbol_31_0_Layer0_0_FILL"
                transform="translate(222.45 136.7)"
                id="BodTai007__col1n"
            />
            <use
                xlinkHref="#BodTai007__Symbol_23_0_Layer0_0_FILL"
                transform="translate(225.65 171.95)"
                id="BodTai007__col1d"
            />
            <use
                xlinkHref="#BodTai007__Symbol_18_0_Layer0_0_FILL"
                transform="translate(224.85 150)"
                id="BodTai007__col2n"
            />
        </g></g>
    )
}

export default BodTai007
