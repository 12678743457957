import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999"
}

function AccMor036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.5 14.15l1.1-5.8L2.45 0Q1 2.25.35 5v.15q-.1.2-.1.45-.1.6-.2 1.55Q0 7.4 0 7.7v.05l21.5 6.4M158.45 5.2q-.65-2.65-2.15-4.9l-19.95 8.45 1.15 5.85 21.3-6.4h.05v-.05h.05V8.1q-.05-.3-.05-.55-.05-1.35-.4-2.35z"
                    id="AccMor036__Symbol_134_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor036__Symbol_134_0_Layer0_0_FILL"
                transform="translate(113 260)"
                id="AccMor036__col1n"
            />
        </g></g>
    )
}

export default AccMor036
