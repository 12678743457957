import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6D6D3B",
    "col1n": "#9A9956"
}

function AccHat003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M182.25 41.65q-4.3-5.4-9.5-10.4-16.7-16.35-37.05-23.9-36.4-14.65-72.85 0Q37.35 16.2 21.9 31.8q-9.05 9.05-14.65 20.4l-1 1.9q-.7 1.6-1.3 3.2l-1.2 3.1q-8.55 23.95 2.5 37.5.7.85 1.4 1.65l.9.9v.05l.3.3.65 4.75q.05-.75.15-1.4 0-.2.05-.35-.05.3-.05.6-.1 1.8.2 2.95.7 2.75 3.45 1.65 1.45-.6 3.45-2.2.3-.3.65-.6 1.6-1.6 3.3-3.15 4.25-3.8 9.3-7.4 32-22.8 77.25-22.8 4.25 0 8.35.2 32.25 1.55 57.45 15.35 5.9 3.25 11.45 7.25 3.7 2.65 6.95 5.4 1.05.85 2.05 1.75 1.5 1.35 2.95 2.75 1.3 1.05 2.4 1.7 3.95 2.45 5.2.15.55-1.1.55-3.2-.05-2.1-.65-5.25-.1-1.2-.15-2.3-.6-7.85-2.25-15.2-4.9-21.85-19.3-39.8z"
                    id="AccHat003__Symbol_223_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M40.85 14.95l1.55-1.15Q54.6 4.7 68.7 0 30.8 11.55 5.2 44.05l-.1.2q-1.15 1.6-2.05 3.45Q-.05 54.05 0 62.75L1.55 76.6v.05l.3.3.65 4.75q.05-.75.15-1.4-.1-.65-.15-1.25 16.55-33.85 64.25-52.5-38.8 10-61.8 43.7-2.45 2.2-3.1-2.45.1-12.6 4.95-18.55Q19.05 31 40.85 14.95m151 68.45q-7-8.85-17.7-16.65-30.8-22.3-74.4-22.3-43.6 0-74.45 22.3-11.75 8.55-19 18.4 1.45-.6 3.45-2.2.3-.3.65-.6 1.6-1.6 3.3-3.15 4.25-3.8 9.3-7.4Q55 49 100.25 49q4.25 0 8.35.2 32.25 1.55 57.45 15.35 5.9 3.25 11.45 7.25 3.7 2.65 6.95 5.4 1.05.85 2.05 1.75 1.5 1.35 2.95 2.75 1.3 1.05 2.4 1.7m5.1-8.3q-.1-1.2-.15-2.3-20.9-34-56.05-43.25 31.45 10.7 56.2 45.55z"
                    id="AccHat003__Symbol_141_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat003__Symbol_223_0_Layer0_0_FILL"
                transform="translate(85.05 31.25)"
                id="AccHat003__col1n"
            />
            <use
                xlinkHref="#AccHat003__Symbol_141_0_Layer0_0_FILL"
                transform="translate(92.05 55.1)"
                id="AccHat003__col1d"
            />
        </g></g>
    )
}

export default AccHat003
