import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col1s": "#49657E",
    "col2n": "#6486A6"
}
function FacEar006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.9 3.75Q26.65 1.45 22.85.6q-12.5-3-20.15 11.25-2.8 5.1-2.7 11.4v.6q0 10.1 6.1 17.85 3.8 4.85 9.15 6.7.95.35 2 .6 2.85.7 5.35 1.05 12.4.9 17.8-8.7 3.7-6.8 3.95-14.2.95-16.05-13.45-23.4m1.2 32.45h.05l-.1.1q.05-.053.05-.1m222.1-13.05q.1-6.3-2.7-11.4Q243.85-2.5 231.35.5q-3.8.85-8.05 3.15-14.4 7.35-13.45 23.4.25 7.4 3.95 14.2 5.4 9.6 17.8 8.7 2.5-.35 5.35-1.05 1.05-.25 2-.6 5.35-1.85 9.15-6.7 6.1-7.75 6.1-17.85v-.6z"
                    id="FacEar006__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M23 18q.45-1.3.45-2.95-.75-5.7-4.1-9.1Q14.2 1.05 6.25.4q-.6-.1-1.1-.15Q2.7.1 0 .5 2.85.3 8 2.05 3.15 3.75 1.95 7.3l-.2.8.3-.8Q8 .05 16.3 5.65q.55.4 1.1.95 4.3 4.1 4.25 8.45 0 1.9-.5 3.55l-.4 1.2q-.1.15-.15.3v.05h-.05q-.05 0-.05.1-.4.7-.85 1.3l-.3.3-.05.05-.1.1h.05l-.1.1-.35.3-.4.3h.05q3.7-1.95 4.55-4.7M228.75.3q-2.7-.4-5.15-.25-.5.05-1.1.15-7.95.65-13.1 5.55-3.35 3.4-4.1 9.1 0 1.65.45 2.95.85 2.75 4.55 4.7h.05l-.4-.3-.35-.3-.1-.1h.05l-.1-.1-.05-.05-.3-.3q-.45-.6-.85-1.3 0-.1-.05-.1h-.05v-.05q-.05-.15-.15-.3l-.4-1.2q-.5-1.65-.5-3.55-.05-4.35 4.25-8.45.55-.55 1.1-.95 8.3-5.6 14.25 1.65l.3.8-.2-.8q-1.2-3.55-6.05-5.25 5.15-1.75 8-1.55z"
                    id="FacEar006__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M19.8 12.05q.05-4.35-4.25-8.45-.55-.55-1.1-.95Q6.15-2.95.2 4.3q-1.25 8.85 5.05 13.65 1.45 1.1 2.9 1.85 5.3 2 8.35-.05.048-.048.1-.05h-.05l.4-.3.35-.3q.05-.05.05-.1l.1-.1.05-.05.3-.3q.45-.6.85-1.3 0-.1.05-.1h.05v-.05q.05-.15.15-.3l.4-1.2q.5-1.65.5-3.55M209.5 3.4q-4.3 4.1-4.25 8.45 0 1.9.5 3.55l.4 1.2q.1.15.15.3v.05h.05q.05 0 .05.1.4.7.85 1.3l.3.3.05.05.1.1q0 .05.05.1l.35.3.4.3h-.05q.052.002.1.05 3.05 2.05 8.35.05 1.45-.75 2.9-1.85 6.3-4.8 5.05-13.65-5.95-7.25-14.25-1.65-.55.4-1.1.95z"
                    id="FacEar006__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar006__Symbol_13_0_Layer0_0_FILL"
                transform="translate(65.4 82.3)"
                id="FacEar006__col1n"
            />
            <use
                xlinkHref="#FacEar006__Symbol_11_0_Layer0_0_FILL"
                transform="translate(78.3 96.5)"
                id="FacEar006__col1s"
            />
            <use
                xlinkHref="#FacEar006__Symbol_12_0_Layer0_0_FILL"
                transform="translate(80.15 99.5)"
                id="FacEar006__col2n"
            />
        </g></g>
    )
}
export default FacEar006
