import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col1s": "#E0E0E0"
}

function AccGla022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M190.15 0l-16 6.25H20.05L4.05 0 0 23.7l10.6 4.7 9.45 36.25h74.1v-5.6q.1-.45.1-1.05.05-.4 0-.65l-.1-10.7q2.25-4.1 5.3 0l.05 11.75.1.35q-.05-.3.1.25.15.3.3.65l-.55-1.05q-.05.25 0 .5v5.55h74.7l9.45-36.25 10.6-4.7L190.15 0m-26.5 21.45v28.4q0 10.6-10.6 10.6h-38q-10.6 0-10.6-10.6v-28.4q0-10.6 10.6-10.6h38q10.6 0 10.6 10.6m-74.4 0v28.4q0 10.6-10.6 10.6h-36q-10.6 0-10.6-10.6v-28.4q0-10.6 10.6-10.6h36q10.6 0 10.6 10.6z"
                    id="AccGla022__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.05 0L0 23.7l10.6 4.7 9.45 36.25V6.25L4.05 0m186.1 0l-16 6.25v58.4l9.45-36.25 10.6-4.7L190.15 0z"
                    id="AccGla022__Symbol_5_0_Layer0_0_FILL"
                />
                <g id="AccGla022__Symbol_59_0_Layer0_0_FILL" fillOpacity={0.843}>
                    <path
                        fill="#04F293"
                        d="M172.2 191.2v-53.6h-35.25q-11.85 0-11.85 11.45v30.7q0 11.45 11.85 11.45h35.25m15.05-53.6h-15v53.6h15v-53.6z"
                    />
                    <path
                        fill="red"
                        d="M197.8 137.6v53.6h50.7q11.45 0 11.45-11.45v-30.7q0-11.45-11.45-11.45h-50.7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccGla022__Symbol_59_0_Layer0_0_FILL" />
            <use
                xlinkHref="#AccGla022__Symbol_45_0_Layer0_0_FILL"
                transform="translate(95.4 128.75)"
                id="AccGla022__col1n"
            />
            <use
                xlinkHref="#AccGla022__Symbol_5_0_Layer0_0_FILL"
                transform="translate(95.4 128.75)"
                id="AccGla022__col1s"
            />
        </g></g>
    )
}

export default AccGla022
