import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col2n": "#FFFFFF"
}

function FacMou031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M206.4 222.4l.1-.1q-.3.1-.7.1h-.15q-5.25 1.1-10.55 1.3-1.271.053-2.5.05-1.092-.003-2.15-.05-4.8-.2-9.75-.95-1.05-.2-2.05-.35l.25.25q.65.75 2.3 2.05 1.4 1.4 4.2 2.05 3.641.85 7.2.85 3.771.05 7.45-.85.15-.05.35-.1 3-1 4.25-2.15 1.25-1.35 1.35-1.55.1-.3.3-.55h.1m1.35-5.45q1.7-.3 3.25-.65l3.7-5.2-21.65 4.8q-.215-.045-.45-.1-.805-.156-1.85-.35-5.05-.85-14.8-3-2.65-.6-5.65-1.25l3.8 5.1q.75.15 1.85.45.6.1 1.3.25 2 .45 3.35.7 1.3.25 2.15.45.8.15 2.35.45 1.4.2 2.95.45 2.28.192 4.55.2 2.457.008 4.9-.2 1.65-.25 3.15-.55.75-.1 1.5-.3.65-.15 1.45-.35.75-.2 1.15-.3.55-.15 2.75-.55.1 0 .25-.05z"
                    id="FacMou031__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.4 17.95h24.8L46.1.15l-23.75 3.2L0 0l11.4 17.95z"
                    id="FacMou031__Symbol_116_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M49.75 1.85q-2.05.1-5.9-.55-4.7-.75-7.65-1-6.35-1.25-11.1 2.45l-.05.05q-.05 0-.1.05h-.2V2.8Q19.95-.95 13.55.3q-2.95.25-7.65 1-3.85.65-5.9.55l3.4 5.7q.2.2.2.3.3.5.55 1 .95 1.7 1.8 3.5l.15.25 1 2.1q-.05-.1-.05 0 .1.3 1.95 4.35 1.55 3.25 5.9 5 4.6 2 9.8 2.05H25.1q5.2-.05 9.75-2.05 4.35-1.75 5.9-5Q42.6 15 42.7 14.7q0-.1-.05 0l1-2.1.15-.25q.85-1.8 1.8-3.5.25-.5.55-1 0-.1.2-.3l3.4-5.7m-8.3 2.4Q44.1 3.65 47.1 3l-7.95 10.8q-.2.3-.4.4l-.25.25q-.25.3-.65.7-.65.55-1.65 1.35-1.4 1.4-4.2 2.05-3.5.8-6.9.9H24.95q-3.55-.05-7.2-.9-2.8-.65-4.2-2.05-1.65-1.3-2.3-2.05L2.65 3q3 .65 5.65 1.25 8.2 1.8 13.1 2.7 3.65.85 6.95 0 4.9-.9 13.1-2.7z"
                    id="FacMou031__Symbol_56_0_Layer0_0_FILL"
                />
                <path
                    fill="#830000"
                    d="M204.35 222.75q1.05-.2 2.05-.35.2-.1.4-.4l4.35-5.8q-.95.25-2.05.55-.6.1-1.3.25-2 .45-3.35.7-1.3.25-2.15.45-.8.15-2.35.45-1.4.2-2.95.45-2.25.2-4.45.2t-4.45-.2q-1.55-.25-2.95-.45-1.55-.3-2.35-.45-.85-.2-2.15-.45-1.35-.25-3.35-.7-.7-.15-1.3-.25-1.1-.3-2.05-.55l4.35 5.8q.2.3.4.4 1 .15 2.05.35 4.95.75 9.75.95 1 .05 2.05.05 1.05 0 2.05-.05 4.8-.2 9.75-.95z"
                    id="FacMou031__Layer5_0_FILL"
                />
                <path
                    fill="#D63232"
                    d="M204.35 222.55q-.35-1.2-1.1-2.05-1.15-1.4-3.3-1.9-1.4.2-2.95.45-2.25.2-4.45.2t-4.45-.2q-1.55-.25-2.95-.45-2.15.5-3.3 1.9-.75.85-1.1 2.05v.2q4.95.75 9.75.95 1 .05 2.05.05 1.05 0 2.05-.05 4.8-.2 9.75-.95v-.2z"
                    id="FacMou031__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou031__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou031__Symbol_116_0_Layer0_0_FILL"
                transform="translate(169.55 210.6)"
                id="FacMou031__col2n"
            />
            <use
                xlinkHref="#FacMou031__Symbol_56_0_Layer0_0_FILL"
                transform="translate(167.65 208.2)"
                id="FacMou031__col1n"
            />
            <use xlinkHref="#FacMou031__Layer5_0_FILL" />
            <use xlinkHref="#FacMou031__Layer5_1_FILL" />
        </g></g>
    )
}

export default FacMou031
