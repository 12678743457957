import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2l": "#FF5D5D",
    "col2n": "#FF0000"
}
function ObjMor097({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M15.2 14.7q.65-1.4-.15-2.3l4.85-8.45L13.05 0l-4.8 8.35Q7 7.9 6.2 9.25L.35 19.4q-.95 1.7.65 2.5l5.8 3.35q1.6.85 2.75-.75l5.65-9.8z"
                    id="ObjMor097__Symbol_245_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.4 0L0 .7q.25.15.45.25l5.8 3.35q.3.2.55.45l.45-.8L.4 0z"
                    id="ObjMor097__Symbol_244_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.8 1.05Q11.25-.7 7.2.5 6 1.1 5.05 2.05q-.6.6-1.1 1.35L0 10.25l5.35 3.05 5.8-10.1q.35-.65.45-1.2.15-.55.2-.95z"
                    id="ObjMor097__Symbol_243_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.8 3.25q1.15.65 3.5-.1Q5.8 2.6 6.55 2q.15-.55.2-.95Q6.2-.7 2.15.5.95 1.1 0 2.05l.1.4q.35.55.7.8z"
                    id="ObjMor097__Symbol_242_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor097__Symbol_245_0_Layer0_0_FILL"
                transform="translate(261.9 276.25)"
                id="ObjMor097__col1n"
            />
            <use
                xlinkHref="#ObjMor097__Symbol_244_0_Layer0_0_FILL"
                transform="translate(270.15 283.9)"
                id="ObjMor097__col1s"
            />
            <use
                xlinkHref="#ObjMor097__Symbol_243_0_Layer0_0_FILL"
                transform="translate(275.7 266.45)"
                id="ObjMor097__col2n"
            />
            <use
                xlinkHref="#ObjMor097__Symbol_242_0_Layer0_0_FILL"
                transform="translate(280.75 266.45)"
                id="ObjMor097__col2l"
            />
        </g></g>
    )
}
export default ObjMor097
