import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiDre005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143.4 11.5l-1.1-1.9q-2.2 1.55-3.95 3.45l-4.9-8.8q.35-.75.75-1.45.8-1.4 1.8-2.75l-.05-.05-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-1.15.8-2.25 1.55-3.15 11.5-7.3 17.8-5 7.15-13.65 9.75-.45.15-1.1.25-.75.15-1.75.25-1.75.15-9.6 1.3H94q-2.55.4-4.85 1.4l-.7.3q-4.85 2.2-8.95 7.6-1.8 2.4-3.5 5.4-1.35-3-3.05-5.4-3.7-5.2-8.95-7.6-.5-.2-.95-.4-2.3-1-4.85-1.4h-.1q-7.85-1.15-9.6-1.3-.6-.05-1.15-.15-1-.15-1.7-.35Q37 31.05 32 23.9q-3.75-5.3-5.45-13.15-.4-1.7-.7-3.5v-.1q-1.95-1.2-3.8-2.5L20.5 3.5q-1.45-1.05-2.8-2.1l-.9-.65q1.05 1.4 1.9 3l-5.15 6.4Q12.2 8.6 10.8 7.4L10 8.7l-.9 1.6q1.25 1.05 2.45 2.35 5.85 6.3 8.65 17.65l-.5 3.7q-.25 1.6-.5 3.25h.15L17 49.45q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8l-1.25 4.85L0 97.35h152.2l-9.6-14.85-6.45-26.15q-.65-3.1-1.3-6.15l-2.5-12.95h.05q-.3-1.8-.5-3.25-.05-.4-.1-.75 1.05-3.8 2.55-7.2 2.35-5.35 5.8-9.75 1.65-2.1 3.7-4-.25-.4-.45-.8m-13.1 10.85q-.15-2.65-.05-5.1l2.35 5.35q-.85 2.05-1.5 4.35l-.4 1.3q-.2-2.55-.4-5.9m6.35-7.2q-1.65 2.3-3 5.1l-3.05-6.45q.5-3.8 1.85-7.2l4.2 8.55M92.6 87.7l.45 1.6q-9.6-1.55-18.4 0v-1.6q9-2.65 17.95 0m2.15 1.6l-.45-1.6q10.7-2.65 22.1 0l.5 1.6q-11.45-1.55-22.15 0m1.3 4.65l-.5-1.7q10.4-1.5 22.3 0l.55 1.7H96.05m-2.2-1.7l.45 1.7H74.65V92.3q8.8-2 19.2-.05m26.35 1.7l-.55-1.7q9.05-1.7 17.4 0l.7 1.7H120.2m-1.5-4.65l-.5-1.6q8.5-2.2 17.05 0l.65 1.6q-8.6-1.45-17.2 0m19.1 0l-.7-1.6h5.5l1.4 2.8-6.2-1.2m2.1 4.65l-.75-1.7 7.05 1.05 1.85 1.65-8.15-1M15.1 12.1l4.55-6.55q1.5 3.1 2.4 6.7L18.2 17.2q-1.45-2.85-3.1-5.1m7.55 16.25q-.8-2.55-1.6-4.75-.9-2.25-1.8-4.1l3.35-4.35q.95 6.05.05 13.2M5.6 90.1l2.7-2.4h4.95l-.7 1.6-6.95.8m-2.5 4.85l1.35-1.8 6.75-.85-.7 1.65-7.4 1m9.7-1l.7-1.65q8.05-1.85 16.15 0L29 93.95H12.8m2-4.65l.7-1.6q8.85-2.35 15.9 0l-.6 1.6q-6.9-1.45-16 0m18.45 0l.6-1.6q9.4-2.05 18.85 0l-.2 1.6q-9.65-1.15-19.25 0m-1.7 4.65l.6-1.65q9.95-2.75 19.9 0l-.2 1.65h-20.3m22.7-4.65l.2-1.6q9.1-2.9 18.2 0v1.6q-9.2-1.85-18.4 0m-.5 4.65l.2-1.65q9.7-2.65 18.7 0v1.65h-18.9z"
                    id="ShiDre005__Symbol_90_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre005__Symbol_90_0_Layer0_0_FILL"
                transform="translate(116.6 228.6)"
                id="ShiDre005__col1n"
            />
        </g></g>
    )
}

export default ShiDre005
