import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E0F0FF",
    "col2n": "#FF0000"
}

function ObjMor032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M41.35 21.4l-.4-.25-4.6-2.4h-.05l-.3-.2h-.05l-2.55-1.4h-.05L25.8 13.1h-.05l-5-2.7v.05l-8-4.3q-.1-2.5-1.85-4.25Q9.25.25 7 .05q-.3-.05-.6 0Q4.6 0 3.2.85q-.75.4-1.35 1.05-.25.2-.45.45Q.8 3.1.45 4 0 5.1 0 6.4v.05q0 1.5.65 2.8.45.9 1.2 1.65 1.85 1.85 4.55 1.9 2-.05 3.6-1.15l.5.3q0-.05.05-.05L52.8 34.35h9.75q.45.05.45-.7-.35-.6-.55-1.05l-.45-.25-.05.05L51 26.55l-.05-.05-5-2.65q-.05-.05-.1 0v-.05l-4.5-2.4M6 2.95V2.9h.2q-.109.004-.2.05z"
                    id="ObjMor032__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M56.85 31.05V31l-34.1-18.15h-.05l-.25-.1v-.05l-2.2-1.2-.05.05-.3-.15.05-.05-2.55-1.3V10l-2.2-1.15h-.05L12.7 7.5h-.05L8.7 5.35q.1-.45.1-.95 0-1.8-1.3-3.1T4.4 0Q2.6 0 1.3 1.3T0 4.4q0 1.8 1.3 3.1t3.1 1.3q1.8 0 3.1-1.3l.25-.25q0-.05.05-.05l47.05 25h4.2l-.7-.35q-.05.05-.1.05-.1.05-.15 0-.05-.05-.05-.1-.05-.05 0-.1l-1.2-.65M3.9.75q-.109.004-.2.05V.75h.2z"
                    id="ObjMor032__Symbol_40_0_Layer0_0_FILL"
                />
                <g id="ObjMor032__Layer4_0_FILL">
                    <path d="M189.3 225.2l-.2-.1-1.1 1.75.2.1q-.05.05 0 .1 0 .05.05.1t.15 0q.05 0 .1-.05l.9-1.65v-.15l-.1-.1m-10.95-3.55l.95-1.75-.45-.25-.05-.05h-.1l-.1.1-.85 1.65.6.3M173.6 217l-.9 1.65.65.35.9-1.8-.45-.25q-.05-.05-.1 0-.1 0-.1.05m-9.4-5.15h-.05l-.3-.2h-.05l-.1-.05h-.05q-.1 0-.15.05l-.8 1.6q-.05 0-.05.05l.6.35.95-1.8m3.45 4.1l.6.35.95-1.8-.4-.25h-.05q-.058-.042-.1 0-.05 0-.1.1l-.9 1.6m16.1 6.35q-.1 0-.1.1l-.9 1.6.55.3.9-1.8-.4-.2h-.05m-41.2-19.7l.25.15h.05l.35.2.95-1.85-.5-.25q-.1-.05-.15 0-.05 0-.1.1l-.85 1.65m6.7 1.25l-.65-.35v.05h-.05q-.1 0-.1.05l-.9 1.65v.05l.7.35 1-1.8m9.35 5.05q-.1 0-.1.1l-.9 1.65.6.3.95-1.8-.4-.25q-.1-.05-.15 0m-4.95-2.7h-.1l-.1.1-.85 1.6v.1l.25.1h.05l.35.2.9-1.85-.5-.25z" />
                    <path
                        fill="#FFF"
                        d="M135.5 197.05q-1.2-.15-1.6.65 2.1.45 2.6 2.4.95-.8.6-1.85-.4-1.05-1.6-1.2z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor032__Symbol_140_0_Layer0_0_FILL"
                transform="translate(127.85 193.1)"
                id="ObjMor032__col1n"
            />
            <use
                xlinkHref="#ObjMor032__Symbol_40_0_Layer0_0_FILL"
                transform="translate(130.15 195.25)"
                id="ObjMor032__col2n"
            />
            <use xlinkHref="#ObjMor032__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjMor032
