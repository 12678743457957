import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function LegPan025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M129 2.2l1.05-1.95Q88.7 11.95 37.85 7.7 20.05 4.95 0 0q.25 1.25.65 2.3l.8 2.05h.1v.1l63.75 12.4 62.95-12.6.15-.05.6-2z"
                    id="LegPan025__Symbol_57_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M129 2.2l-6.85 1.65q-21.3 4.75-35.9 6.3l-7.3.55q-1.8.1-3.45.15-2.25.05-4.4.05-13.75-.45-29.35-2.65-1.95-.25-3.9-.55Q20.05 4.95 0 0q.25 1.25.65 2.3L65.3 16.85l62.95-12.6.15-.05.6-2z"
                    id="LegPan025__Symbol_58_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan025__Symbol_57_0_Layer0_0_FILL"
                transform="translate(127.5 306.95)"
                id="LegPan025__col1n"
            />
            <use
                xlinkHref="#LegPan025__Symbol_58_0_Layer0_0_FILL"
                transform="translate(127.5 306.95)"
                id="LegPan025__col2n"
            />
        </g></g>
    )
}

export default LegPan025
