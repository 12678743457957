import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#773900",
    "col2n": "#C69C5D"
}

function ShiSpe112({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.75 44.3q-.2-.8-.4-1.65l-.1-.4q-.1-.25-.15-.55-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-4.05-9.2-9.25-17.2l-.7-1Q144 2.15 142.45.1l.05-.05h-.05l-6.2 4.65q-10.1 7-21.45 11-23.1 14.5-24.85 9.1h-14.6q-.6 7.65-25.25-9.2-11.3-4-21.35-10.95L22.55 0h-.05l.05.05h-.05Q21 2.1 19.6 4.25l-.7 1q-5.2 8-9.25 17.2-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35-.1.2-.1.4-.1.3-.15.6-.25.8-.4 1.6-1.4 5.6-2.15 11.5-.05.3-.1.65 4-3.6 10-3.7h1.25q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15 3.05 1.1 5.8 3.55V57q.2.15.4.35l.05.05-5.7 23.5q.1.05.25.05.25.1.5.15h.15q2.6.6 5.25 1.2l5 1.1q2.5.5 4.95.95.65.1 1.35.25.15 0 .3.05 6.4 1.1 12.75 1.9 3.8.5 7.6.9.7.1 1.45.15l1.8.2h.25l5.65.45q4.5.3 9 .5.6 0 1.2.05.6 0 1.2.05h1l4.6.1H84.6V89l5.6-.1h.9q.65-.05 1.3-.05.5-.05 1-.05 4.1-.2 8.2-.5l5.65-.45h.25l1.8-.2q.75-.05 1.45-.15 3.8-.4 7.6-.9 6.35-.8 12.75-1.9.15-.05.3-.05.7-.15 1.35-.25 2.45-.45 4.95-.95l5-1.1q2.65-.6 5.25-1.2h.15q.25-.05.5-.15.15 0 .25-.05l-5.7-23.5.05-.05q.2-.2.4-.35V57q2.75-2.45 5.8-3.55.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05H155q6 .1 10 3.7-.05-.35-.1-.65-.65-5-1.7-9.75-.25-.9-.45-1.75z"
                    id="ShiSpe112__Symbol_231_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M87 47.05q6.15-8.55 6.15-19.25 0-16-13.65-27.25Q79.25.3 79 .1q-11.55 4.05-24.35 5l-5.85.2H46.65l-7.9-.25Q25.85 4.05 14.3 0q-.35.25-.7.55-1.5 1.2-2.8 2.5l-.7.7L5.65 9.1Q0 17.45 0 27.8q0 1 .05 1.9.8 14.8 13.55 25.4 5.75 4.75 12.55 7.55 3.5 1.35 7.25 2.25 6.2 1.45 13.15 1.45 19.3 0 32.95-11.25 1.2-1 2.3-2l.75-.7L87 47.05z"
                    id="ShiSpe112__Symbol_230_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiSpe112__Layer2_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe112__Symbol_231_0_Layer0_0_FILL"
                transform="translate(110 228.55)"
                id="ShiSpe112__col1n"
            />
            <use
                xlinkHref="#ShiSpe112__Symbol_230_0_Layer0_0_FILL"
                transform="translate(145.8 244.15)"
                id="ShiSpe112__col2n"
            />
            <use xlinkHref="#ShiSpe112__Layer2_0_FILL" />
        </g></g>
    )
}

export default ShiSpe112
