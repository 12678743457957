import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFD446"
}

function AccJew039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.95 12q2.1-2.05 2.1-4.95 0-2.9-2.1-5Q9.9 0 7 0 4.15 0 2.05 2.05 0 4.15 0 7.05T2.05 12q2.1 2.1 4.95 2.1 2.9 0 4.95-2.1m-1.5-8.45Q11.9 5 11.9 7.1q0 2.1-1.45 3.55Q9 12.1 6.9 12.1q-2.05 0-3.5-1.45Q1.95 9.2 1.95 7.1q0-2.1 1.45-3.55Q4.85 2.1 6.9 2.1q2.1 0 3.55 1.45z"
                    id="AccJew039__Symbol_107_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew039__Symbol_107_0_Layer0_0_FILL"
                transform="translate(92.5 177.85)"
                id="AccJew039__col1n"
            />
        </g></g>
    )
}

export default AccJew039
