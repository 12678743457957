import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#1919FF",
    "col1n": "#0000BB",
    "col2n": "#C40000"
}

function ShiDre057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFE991"
                    d="M130.9 296.15v-.05l-.15.05v-.05q-.45-.35-.7-1.05-.15.45-.3.95-.4 1.55-.75 3.85-.15.7-1.15 3.35l-.3.9-.1.3q-.1.3-.15.6-1.35 2.9-2.85 4.6-1.5 1.65-2.05 4.9-.6 3.25-.5 4.45.15 2.35-.45 5.15-.65 2.75-7.4 7.4l14.35-1.8q12.7-.6 20.35-.3 7.65.3 11.2 1.1 23.35 4.25 41.85.4 24.1-3.9 33.25-1.35 9.1 2.5 23.4-.05-.35-.1-.65-.1l-.1-.1 11.7.85q-5.25-3.75-6.65-7.65-.8-2.1-.45-4.2.2-1.45.2-2.8-.1-3.9-2.05-7-.95-1.1-1.7-2.25-.6-.95-1.15-1.85-.3-.5-.55-1-.2-.4-.35-.75l-.85-4.3q.9 7.4-.15-.7v-.05q0-.8-.25-1.1-.2.2-.35.3v.15l-3.75 3.55v.05l-.35.35q-.05.1-.1.15-1.55 1.85-4.6 3.35-1.85.9-4.2 1.65-.85.2-1.7.55-1.95.4-4.1.95l-1.3.2q-.3 0-.55.1-2.2.35-4.35.75-12.35 2.3-25.45 5.6-.75.2-1.5.45-.35.1-.75.2l-1.3.35q-2.15.5-4.2 1.05-.45.1-1.9.35-.7.1-1.05.2h-.1l-.4.1h-.25l-.65.1h-.1l-.65-.1h-.2q-.15-.05-.35-.05-.45-.15-.9-.25l-4.5-1.4q-.7-.2-1.4-.35v-.1q-10.95-2.8-21.5-4.95-.95-.2-2.05-.3-1.5-.35-3-.65-.55-.1-1.2-.25h-.4q-2.25-.4-4.5-.85-6.95-1.4-11.15-3.3-.05 0-.1-.05-1.25-.6-2.15-1.25-1.45-1-2.4-2-1.2-.95-1.25-.9l-3.9-4 .05-.1m-2.4 10.1l-.3.1q.1-.1.2-.1h.1m75.35 8.95q-.319.01-.5.1 0-.1.1-.1h.4m-21.35 0l.1.1q-.332-.095-.6-.1h.5m75.2-8.85l-.25-.1h.1l.15.1z"
                    id="ShiDre057__Layer19_0_FILL"
                />
                <path
                    fill="#FDD124"
                    d="M204.65 314.2q-.75.2-1.5.45 0 5.75 1.55 10.4.4 1.2.9 2.3-.25-1.15-.4-2.3-.7-4.75-.45-9.85l-.1-1m-.8 1q-.319.01-.5.1 0-.1.1-.1h.4m38.25-9.15q-.85.2-1.7.55 3.2 6.85 3.4 13.85 1.5-7.4-1.7-14.4m-5.8 1.5l-1.3.2q-.3 0-.55.1 3.15 7.6 3.15 16.2v.1q.002-.052.05-.1 1.35-8.5-1.35-16.5m-75.4 1.95q.1 6.85-2.35 12.95 3.75-5.65 4.4-12.65-.95-.2-2.05-.3m-20.35-5.1q-1.25-.6-2.15-1.25-.5.6-.95 1.25-5.25 7.1-4.85 17.6 2.9-12.5 8.05-17.55-.05 0-.1-.05m16.15 4.2h-.4q.7 5.45-2.7 15.75-.2.6-.45 1.3l.8-1.3q4.15-7.25 3.95-15.5-.55-.1-1.2-.25z"
                    id="ShiDre057__Layer19_0_MEMBER_0_FILL"
                />
                <path
                    fill="#CF9C27"
                    d="M266.6 254.6l-20 5.1 2.1 5.2q10.3-4.45 19.65-5.35l-1.75-4.95m-148.3 0l-1.8 4.95q9.423.905 19.7 5.35l2.05-5.25-19.95-5.05z"
                    id="ShiDre057__Layer19_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M114.2 1.85l-.1-1.1q-4.85 5.7-5.9 9.55-1.05 3.85-1.2 4.45-.15.6-.35 1.6-1.5 6.95-9.4 10.65-3.45 1.25-6.9 2.25-9.35 2.8-19 3.65l-7.35.3h-1.2q-.6.45-1.1.85-.15-.45-.3-.85h-1.2l-7.35-.3q-9.65-.85-19-3.65-3.45-1-6.9-2.25-7.9-3.7-9.4-10.65-.2-1-.3-2.1 0-.1-.05-.2l-.85-3.55q-1.3-5.1-4.9-9.15-.15-1.6-.5-1.3-1.25 1-2.8 12.05Q6.6 23.15 6.3 23.25v.3q-.1.4-.15.85-.05.7-.15 1.45-.05.4-.1.85v.05L3.2 40.7q-.55 2.7-1.1 5.45l-2 8.25.1.1-.2.75q.2.65.55 1.1.1.9 1.85 3.25 2.1 2.85 4.65 4.9 2.45 1.95 7.1 3.8 4.85 1.95 8.8 2.45 7 .85 11.25 1.65.75.15 9.55 2 .2.05 9 2.4 5.2 1.4 8 1.75.3.45 1.65.6 1.3.15 2.15-.55 5.1-.25 9.75-1.6 3.05-.85 7.25-2.45 3.85-1.1 8.35-2.2 5.5-1.3 8.2-1.65 12.55-1.7 17.45-4.45 1.15-.65 2.65-1.8 2.35-1.8 2.65-2 3.65-3.05 3.65-3.9.05-.35-.05-.55.1-.1.25-.15l-2.35-10.25q-.65-3.1-1.3-6.15l-2.85-14.7v-.05q-.05-.45-.1-.85-.15-1.45-.25-2.85-.15-.05-1.8-10.35-1.35-8.45-1.85-10.8z"
                    id="ShiDre057__Symbol_254_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M28.65 8.3q-1.25-1.7-5.05-4-3.8-2.35-6.3-4.3-6.2.45-7.65 5.85-.1.35-.85 2.05Q8.35 9 7.15 9.85 1.9 11.8.5 15.9q-.75 2.15-.4 4.9-.05 1.4.4 2.7 1.05 2.95 4.85 5.15 2.25 1.6 6.1 1.8h1q.85 0 1.8-.1.35.45.9 1 .2.3.5.5.45.35 1.1.65 1.85.95 4.15 1.15.6.05 1.2.05.55 0 1-.05.75-.05 1.4-.25.3-.1.6-.25.55-.2 1-.4 1.9-1.3 3-3.65 1.2-1.85.85-4.85-.3-3-.95-5.05-.35-1.2-.35-3-.1-1.65.1-3.9l.15-1.6q1-.7-.25-2.4m123.75-.4q-.75-1.7-.85-2.05-1.5-5.4-7.65-5.8-3 2.3-5.9 4.95-1.15 1-2.25 2.1-.55.4-1.1.95-1.2 1.1-2.25 2.25-.1.1-.15.2l.15 1.8q.45 4.65-.2 6.9-.65 2.05-1 5.05v.15q-.1 1.15 0 2.1.2 1.5.9 2.6 1.25 2.8 3.65 3.95l.3.1q.05 0 .1.05 1.25.5 2.9.5h.55q2.65-.1 4.85-1.2.65-.3 1.05-.65.35-.2.55-.5l.55-.55q.15-.25.3-.45.2 0 .4.05.75.05 1.45.05H149.7q.55-.05 1.05-.05 2.45-.3 4.15-1.2.25-.1.45-.25.25-.15.45-.3 1.75-1 2.9-2.15 1-1 1.6-2.1.85-1.65.8-3.6.95-7.95-7.1-10.95-1.2-.85-1.6-1.95z"
                    id="ShiDre057__Symbol_226_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.25 3.05L11.2 0q-.2.15-.45.35l-.2.2q-.2.1-.45.45-.4.2-.95.75-1.4 1.15-3.35 3.2Q2.15 8.5 1.65 11.1 1.2 13.55 0 17.45q1.65-1.4 2.6-1.85v-.1q.1 0 .2-.1l3.15-1.1q2.45-1.05 3.2-4.7l1.1-6.55m7.2-1.2q-.9 1.3-2.15 4.4-.45.95-.9 2.05-1.9 4.95-1.25 7.4.5 2.35.95 6.55 1.05-2.05 1.7-2.8.65-.75 2.6-2.55 1.05-1.2 1.2-2.9.1-1.2-.25-2.6l-1.6-6.45-.3-3.1m115 0l-.35 3.1-1.6 6.45q-.75 3.55 1 5.5.95.9 1.6 1.55.65.65.95 1 .35.4.8 1.1.4.7.95 1.7.1-.9.2-1.7.1-1.15.25-2.1.25-1.6.5-2.75.65-2.45-1.3-7.4-1.95-4.85-3-6.45m15.8 9.25q-.55-2.6-4.2-6.15-1.95-2.05-3.35-3.2-.95-1-1.6-1.4-.2-.2-.4-.35l.95 3.05 1.05 6.55q.75 3.65 3.25 4.7 2.15.75 3.1 1.1.1.1.2.1.9.45 2.6 1.95-1.2-3.9-1.6-6.35z"
                    id="ShiDre057__Symbol_175_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M265.9 216.1v-.7q-6.3 7.4-13.1 12.8l-.05.05q-3.65 2.85-7.45 5.3l-2.15 1.35q-.45.3-.95.6l-.6.4q-2 1.2-4.1 2.5v13q2.2-7.9 7.5-13.7.05-.1.15-.2 1.05-1.15 2.25-2.25.55-.55 1.1-.95 1.1-1.1 2.25-2.1 2.9-2.65 5.9-4.95 4.5-3.45 9.25-6.1 2.45-2.4 0-5.05m-133.55 12.15l-.05-.05q-6.8-5.4-13.1-12.8v.7q-2.45 2.65 0 5.05 4.75 2.65 9.25 6.1 3 2.3 5.9 4.95 1.15 1 2.25 2.1.55.4 1.1.95 1.2 1.1 2.25 2.25.1.1.15.2 5.3 5.8 7.5 13.7v-13q-2.1-1.3-4.1-2.5l-.6-.4q-.5-.3-.95-.6l-2.15-1.35q-3.8-2.45-7.45-5.3z"
                    id="ShiDre057__Layer10_0_MEMBER_0_FILL"
                />
                <path
                    fill="#CF9C27"
                    d="M130.95 293.7q-.35-.45-.55-1.1l-.5 2 .05.35.1.3q.25.7.7 1.05v.05l.15-.05v.05l-.05.1 3.9 4q.05-.05 1.25.9.95 1 2.4 2 .9.65 2.15 1.25.05.05.1.05 4.2 1.9 11.15 3.3 2.25.45 4.5.85h.4q.65.15 1.2.25 1.5.3 3 .65 1.1.1 2.05.3 10.55 2.15 21.5 4.95v.1q.7.15 1.4.35l4.5 1.4q.45.1.9.25.2 0 .35.05h.2l.65.1h.1l.65-.1h.25l.4-.1h.1q.35-.1 1.05-.2 1.45-.25 1.9-.35 2.05-.55 4.2-1.05l1.3-.35q.4-.1.75-.2.75-.25 1.5-.45 13.1-3.3 25.45-5.6 2.15-.4 4.35-.75.25-.1.55-.1l1.3-.2q2.15-.55 4.1-.95.85-.35 1.7-.55 2.35-.75 4.2-1.65 3.05-1.5 4.6-3.35.05-.05.1-.15l.35-.35v-.05l3.75-3.55V297q.15-.1.35-.3l-.45-1.8h-.2l-3.9 4.7-.1-.15v.15q-.25.3-.5.55-.2.15-.35.3-2.7 2.35-8.05 4.05l-.3.1q-.85.3-1.65.45-1.85.55-3.85 1l-1.1.2q-.4.1-.85.2-2 .35-4.05.65-12.45 2.25-25.55 5.6-.85.2-1.75.4l-1 .3q-.9.2-1.8.45l-3.3.9q-.05 0-.1.05-2.85.5-3.15.6h-.25v-44.85h-1.4v44.85h-.2q-.2-.05-.35-.1-.45-.15-.9-.25l.7.25q-14.5-4.3-27.45-6.9-1.2-.2-2.35-.45-1.4-.3-2.8-.55-.85-.2-1.75-.3h-.1q-2.35-.45-4.8-.85-3.65-.75-6.55-1.65-2.2-.7-3.95-1.45-3.75-1.75-5.5-3.9-.2-.3-.4-.55v.1l-4.3-4.65.05-.2q-.15-.1-.2-.25z"
                    id="ShiDre057__Layer10_0_MEMBER_1_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.302}
                    d="M250.2 232.2q-1.15 1-2.25 2.1-.55.4-1.1.95-1.2 1.1-2.25 2.25-.1.1-.15.2l.15 1.8q.45 4.65-.2 6.9-.65 2.05-1 5.05v.15q-.1 1.15 0 2.1.2 1.5.9 2.6 1.25 2.8 3.65 3.95l.3.1q.053.022.1.05 1.269.5 2.9.5h.5q2.719-.11 4.9-1.2.65-.3 1.05-.65.35-.2.55-.5.292-.292.5-.55.21-.24.35-.45.189.02.35 0 .779.1 1.5.1H261.9q.547-.026 1.05-.1 2.456-.24 4.15-1.15.229-.114.4-.25.283-.137.5-.3 1.75-1 2.9-2.15 1-1 1.6-2.1.85-1.65.8-3.6.95-7.95-7.1-10.95-1.2-.85-1.6-1.95-.75-1.7-.85-2.05-1.5-5.4-7.65-5.8-3 2.3-5.9 4.95m6.35.95q.2.15.4.35.65.4 1.6 1.4 1.4 1.15 3.35 3.2 3.65 3.55 4.2 6.15.4 2.45 1.6 6.35-1.7-1.5-2.6-1.95-.1 0-.2-.1-.95-.35-3.1-1.1-2.5-1.05-3.25-4.7l-1.05-6.55-.95-3.05m-8.2 11.4l1.6-6.45.35-3.1q1.05 1.6 3 6.45 1.95 4.95 1.3 7.4-.25 1.15-.5 2.75-.15.95-.25 2.1-.1.8-.2 1.7-.55-1-.95-1.7-.45-.7-.8-1.1-.3-.35-.95-1-.65-.65-1.6-1.55-1.75-1.95-1-5.5M129.5 227.2q-6.2.45-7.65 5.85-.1.35-.85 2.05-.45 1.1-1.65 1.95-5.25 1.95-6.65 6.05-.75 2.15-.4 4.9-.05 1.4.4 2.7 1.05 2.95 4.85 5.15 2.25 1.6 6.1 1.8h1q.85 0 1.8-.1.35.45.9 1 .2.3.5.5.45.35 1.1.65 1.85.95 4.15 1.15.6.05 1.2.05.55 0 1-.05.75-.05 1.4-.25.3-.1.6-.25.55-.2 1-.4 1.9-1.3 3-3.65 1.2-1.85.85-4.85-.3-3-.95-5.05-.35-1.2-.35-3-.1-1.65.1-3.9l.15-1.6q1.013-.7-.25-2.4-1.26-1.7-5.05-4.05-3.797-2.3-6.3-4.25m-1.55 6.95q.25-.35.45-.45l.2-.2q.25-.2.45-.35l-.95 3.05-1.1 6.55q-.75 3.65-3.2 4.7l-3.15 1.1q-.1.1-.2.1v.1q-.95.45-2.6 1.85 1.2-3.9 1.65-6.35.5-2.6 4.15-6.15 1.95-2.05 3.35-3.2.55-.55.95-.75m4.3 7.3q.45-1.1.9-2.05 1.25-3.1 2.15-4.4l.3 3.1 1.6 6.45q.35 1.4.25 2.6-.15 1.7-1.2 2.9-1.95 1.8-2.6 2.55-.65.75-1.7 2.8-.45-4.2-.95-6.55-.65-2.45 1.25-7.4z"
                    id="ShiDre057__Layer10_0_MEMBER_2_FILL"
                />
            </defs>
            <use xlinkHref="#ShiDre057__Layer19_0_FILL" />
            <use xlinkHref="#ShiDre057__Layer19_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiDre057__Layer19_1_FILL" />
            <use
                xlinkHref="#ShiDre057__Symbol_254_0_Layer0_0_FILL"
                transform="translate(130.4 237.35)"
                id="ShiDre057__col1n"
            />
            <use
                xlinkHref="#ShiDre057__Symbol_226_0_Layer0_0_FILL"
                transform="translate(112.2 227.2)"
                id="ShiDre057__col1l"
            />
            <use
                xlinkHref="#ShiDre057__Symbol_175_0_Layer0_0_FILL"
                transform="translate(117.85 233.15)"
                id="ShiDre057__col2n"
            />
            <use xlinkHref="#ShiDre057__Layer10_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiDre057__Layer10_0_MEMBER_1_FILL" />
            <use xlinkHref="#ShiDre057__Layer10_0_MEMBER_2_FILL" />
        </g></g>
    )
}

export default ShiDre057
