import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSho059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M176.6 43.05q-1.4-2-3.2-4-5.85-6.3-15.15-13.65-5.55-4.3-9.2-6.45-.4-.25-.8-.45-8.65-5.85-17.85-9.6-40.95-17.8-81.85 0-5.2 1.95-8.2 3.4-1.7.9-3 1.6Q10.2 27.45 1.25 55.6-1.95 67.7 3 74.4q10.45 11.15 41.65 4.25Q88.8 67.25 132 39.3q.85-.55 1.7-1.05 8.3-4.95 15.35-5.35Q160 32.2 168 42.2q2.35 3.2 8.95 14.8 2.45 4.35 3.85 6.9.2-.3.3-.65.4-.65.4-1.7 0-7.05-1.25-11.15-1.1-3.7-3.65-7.35z"
                    id="HaiSho059__Symbol_180_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M83.35 47.15q.3-.1.4-.35.1-.3 0-.55-.1-.3-.35-.4-.3-.1-.55 0-16 6.2-26.7 6.95-.3.05-.45.25-.2.2-.2.5.05.3.25.5.2.15.5.15 10.9-.75 27.1-7.05M91.85.3q-.2-.25-.45-.25Q20.85-1.5.1 42q-.15.25-.05.55.1.25.35.4.25.1.55 0 .25-.1.4-.35 20.5-42.7 90-41.15.25 0 .5-.2.2-.2.2-.45 0-.3-.2-.5m23.85 15.1q-.3.1-.35.4-6.85 21.85-91.65 30.5-.3.05-.5.25-.2.25-.15.55.05.25.25.45.25.2.55.15 86.05-8.75 92.85-31.5.05-.25-.05-.5-.15-.3-.45-.35-.25-.1-.5.05m-14.2-.25q.05-.3-.1-.55-.2-.25-.45-.25-.3-.1-.55.1-.25.15-.25.45-2.65 12.7-61.6 19-.3.05-.5.25-.2.25-.15.55.05.25.25.45.25.2.55.15 60.3-6.5 62.8-20.15m3.2-8.85q.25.05.5-.15.2-.2.25-.45.05-.3-.15-.5-.2-.25-.45-.3-47.1-5.7-87.7 32.35l-.05.05q-3.3 3.1-6.6 6.5-.2.15-.2.45 0 .3.25.5.15.2.45.2.3 0 .5-.2 3.3-3.4 6.55-6.45l.05-.05q26.4-22.6 53.45-19.45.25.05.5-.15.2-.2.25-.45.05-.3-.15-.5-.2-.25-.45-.3-19.65-2.3-38.9 8.7Q66.55 1.7 104.7 6.3m23.85 11.35q-.3 0-.5.2Q93 48 13.05 52.65q-.3 0-.45.25-.2.2-.2.5t.25.5q.2.15.5.15Q93.7 49.4 128.95 18.9q.2-.2.25-.5 0-.3-.15-.5-.2-.2-.5-.25z"
                    id="HaiSho059__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho059__Symbol_180_0_Layer0_0_FILL"
                transform="translate(101.7 56.7)"
                id="HaiSho059__col1n"
            />
            <use
                xlinkHref="#HaiSho059__Symbol_6_0_Layer0_0_FILL"
                transform="translate(107.75 69.35)"
                id="HaiSho059__col1s"
            />
        </g></g>
    )
}

export default HaiSho059
