import { AssetSvgProps } from "../../../shared/assets"

function ObjToy038({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjToy038__Layer14_0_FILL">
                    <path
                        fill="#744529"
                        d="M286 330.45q.25-.45.2-1.3-.05-.9-.05-1.25 0-.55.9-1.05 1.25-.55 2-.95.85-.4 1.5-.95h-2.7v-1.5q-2.724 3.381-7.1 4.45v3.8q1.555 7.675.05 15.35h3.5q.15-.2.3-.45.35-.6.35-1.2 0-.4-.25-.9-.05-.05-.05-.15-.3-.5-.8-1.15-.05 0-.05-.05-1.1-1.3-1.1-1.9 0-.8.9-2.25.9-1.5.9-2.55 0-.95-.6-1.85-.25-.4-.4-.6-.2-.4-.2-.5v-.15q.15-.45.95-1.05 1.25-1 1.75-1.85z"
                    />
                    <path
                        fill="#00BBBF"
                        d="M349.15 332.7q-1-1.15-1-2.4 0-.75.3-2.2v-.2q.3-1.35.3-2.25.1-.2.3-.45.05-.05.1-.15.05-.05.15-.1h-58.75q-.65.55-1.5.95-.75.4-2 .95-.9.5-.9 1.05 0 .35.05 1.25.05.85-.2 1.3-.5.85-1.75 1.85-.8.6-.95 1.05v.15q0 .1.2.5.15.2.4.6.6.9.6 1.85 0 1.05-.9 2.55-.9 1.45-.9 2.25 0 .6 1.1 1.9 0 .05.05.05.5.65.8 1.15 0 .1.05.15.25.5.25.9 0 .6-.35 1.2-.15.25-.3.45 31.681-1.552 63.75 0-.05-.05-.05-.15 0-.7.9-1.4.55-.45.75-1 0-.05.05-.15.1-.3.1-.6 0-.25-.15-.6-.15-.4-.5-.85v-.05q-.7-1.05-.7-2.4 0-.9.55-1.9.15-.25.3-.55.8-1.25.8-2.7 0-.85-.95-2z"
                    />
                    <path
                        fill="#54301B"
                        d="M371.45 325.2V323q-2.578-5.9 0-11.8-7.59 4.855-12.15 13.75h-10q-.1.05-.15.1-.05.1-.1.15-.2.25-.3.45 0 .9-.3 2.25v.2q-.3 1.45-.3 2.2 0 1.25 1 2.4.95 1.15.95 2 0 1.45-.8 2.7-.15.3-.3.55-.55 1-.55 1.9 0 1.35.7 2.4v.05q.35.45.5.85.15.35.15.6 0 .3-.1.6-.05.1-.05.15-.2.55-.75 1-.9.7-.9 1.4 0 .1.05.15h4.15l2.35-2.7 1.05-1.2.9-1v-.05l14.9-16.9h.05z"
                    />
                    <path
                        fill="#FF9A00"
                        d="M371.45 323v2.2h-.05l-14.9 16.9v.05l-.9 1-1.05 1.2-2.35 2.7h-4.15q-32.069-1.552-63.75 0h-3.5q1.505-7.675-.05-15.35-6.85 9.55-19.4 16.25 4.113 8.838 0 18.05 58.2-3.807 116.4 0 6.836-15.747 18.5-27.7-4.186-7.65 0-15.3-15.261 2.48-24.8 0m3.1 21l-.4.25h-.25l.65-.25m-11.15 3.95h-.15l.1-.05q.074.009.15 0l-.1.05z"
                    />
                    <path fill="#FFF" d="M374.15 344.25l.4-.25-.65.25h.25z" />
                    <path d="M378.55 286.1h-.15q-11.043 7.174-17.5 18.25h-70.7l-2.35 1.5q3.724 8.8 0 17.6v1.5h71.45q4.56-8.895 12.15-13.75 1.061-8.07 7.1-8.1-3.353-8.323 0-17m-55.5 24q.098.028.25.05-.189-.011-.35-.05h.1z" />
                    <path
                        fill="#8C0000"
                        d="M323.3 310.15q-.152-.022-.25-.05h-.1q.161.039.35.05z"
                    />
                    <path
                        fill="#B6E4FF"
                        d="M362.45 277.8q-4.509 1.53-6.45 5.95-17.25-2.483-34.5 0 1.69-7.8 0-15.6-2.64 2.14-4.95 0-11.49 9.18-26.15 17.1h-.2q5.259 9.55 0 19.1h70.7q6.457-11.076 17.5-18.25l1.75-1.8q-6.727-6.69 0-16.15-4.99 1.563-7.2 0-6.133 3.236-10.5 9.65z"
                    />
                    <path fill="#D99D09" d="M362.75 259.55h.3v-.2l-.3.2z" />
                    <path
                        fill="#5E7C98"
                        d="M362.75 259.55l-.3.2v18.05q4.367-6.414 10.5-9.65l.05-.05V252q-15.854 2.208-29.5 0-4.8 4.262-13.2 7.55H362.75l.3-.2v.2h-.3z"
                    />
                    <path
                        fill="#FF6519"
                        d="M362.45 259.75v-.2H330.3q-2.973 3.586-8.8 5.05v3.55q1.69 7.8 0 15.6 17.25-2.483 34.5 0 1.941-4.42 6.45-5.95v-18.05z"
                    />
                    <path fill="#FFC943" d="M362.45 259.55v.2l.3-.2h-.3z" />
                </g>
                <g id="ObjToy038__Layer14_1_FILL">
                    <path
                        fill="#9F6443"
                        d="M284.25 332.3q1.25-1 1.75-1.85.25-.45.2-1.3-.05-.9-.05-1.25h-5.4v19.15h3.55q.15-.2.3-.45.35-.6.35-1.2 0-.4-.25-.9-.05-.05-.05-.15-.3-.5-.8-1.15-.05 0-.05-.05-1.1-1.3-1.1-1.9 0-.8.9-2.25.9-1.5.9-2.55 0-.95-.6-1.85-.25-.4-.4-.6-.2-.4-.2-.5v-.15q.15-.45.95-1.05m63.9-2q0 1.25 1 2.4.95 1.15.95 2 0 1.45-.8 2.7-.15.3-.3.55-.55 1-.55 1.9 0 1.35.7 2.4v.05q.35.45.5.85.15.35.15.6 0 .3-.1.6-.05.1-.05.15-.2.55-.75 1-.9.7-.9 1.4 0 .1.05.15h4.15V327.9h-3.75v.2q-.3 1.45-.3 2.2z"
                    />
                    <path
                        fill="#744529"
                        d="M287.85 324.95v-1.5l-7.1 4.45h5.4q0-.55.9-1.05 1.25-.55 2-.95.85-.4 1.5-.95h-2.7m60.9.7q0 .9-.3 2.25h3.75l2.9-2.95h-5.8q-.1.05-.15.1-.05.1-.1.15-.2.25-.3.45z"
                    />
                    <path
                        fill="#FFC431"
                        d="M363.25 366q2.916-9.182 0-18.05h-101.9V366h101.9z"
                    />
                    <path
                        fill="#00D2D7"
                        d="M349.15 332.7q-1-1.15-1-2.4 0-.75.3-2.2v-.2h-62.3q0 .35.05 1.25.05.85-.2 1.3-.5.85-1.75 1.85-.8.6-.95 1.05v.15q0 .1.2.5.15.2.4.6.6.9.6 1.85 0 1.05-.9 2.55-.9 1.45-.9 2.25 0 .6 1.1 1.9 0 .05.05.05.5.65.8 1.15 0 .1.05.15.25.5.25.9 0 .6-.35 1.2-.15.25-.3.45h63.75q-.05-.05-.05-.15 0-.7.9-1.4.55-.45.75-1 0-.05.05-.15.1-.3.1-.6 0-.25-.15-.6-.15-.4-.5-.85v-.05q-.7-1.05-.7-2.4 0-.9.55-1.9.15-.25.3-.55.8-1.25.8-2.7 0-.85-.95-2m-10-.3q.4.4.4.85-.5.45-1 .95-1 1-1 1.8 0 1.1 1.5 2.6.7.75 1.45 1.5 0 .5-1.15 1-1.55.5-2.4.8l-2.95.05q-.6.2-2.8 1.45-1.5.85-3.35.9h-.15q-.862-.103-2.25-.25l-2.15.05q-.568-.18-1.65-.55-1.7-.6-2.8-.6h-3.8q-1.417.375-4.65 1.45l-1.5.05q-.552.13-.75.45h-2.6q-1.841-.39-3.05-.6 0-.75-4-1.7-4-.9-4-1.85.45-.55.9-1.15.9-1.15.9-2.2 0-.9-.6-1.65-.6-.7-.6-1.2 0-1.1.85-2 1.15-1.15 3.5-1.75h2.2q.144.103.35.2 1.1.5 3.25.5 1.55 0 2.8-.8 1.3-.8 5.8-.8 1.7 0 3.4.65 1.75.65 4.15.65 3.45 0 7.05-.9l4.15.05q.65.3 1.2 1.25 1.1.75 5.35.8m-44.8 5.25q-.155.093-.3.15.104-.144.2-.35.057.111.1.2z"
                    />
                    <path
                        fill="#005E5F"
                        d="M294.05 337.8q.145-.057.3-.15-.043-.089-.1-.2-.096.206-.2.35m45.5-4.55q0-.45-.4-.85-4.25-.05-5.35-.8-.55-.95-1.2-1.25l-4.15-.05q-3.6.9-7.05.9-2.4 0-4.15-.65-1.7-.65-3.4-.65-4.5 0-5.8.8-1.25.8-2.8.8-2.15 0-3.25-.5-.206-.097-.35-.2h-2.2q-2.35.6-3.5 1.75-.85.9-.85 2 0 .5.6 1.2.6.75.6 1.65 0 1.05-.9 2.2-.45.6-.9 1.15 0 .95 4 1.85 4 .95 4 1.7 1.209.21 3.05.6h2.6q.198-.32.75-.45l1.5-.05q3.233-1.075 4.65-1.45h3.8q1.1 0 2.8.6 1.082.37 1.65.55l2.15-.05q1.388.147 2.25.25h.15q1.85-.05 3.35-.9 2.2-1.25 2.8-1.45l2.95-.05q.85-.3 2.4-.8 1.15-.5 1.15-1-.75-.75-1.45-1.5-1.5-1.5-1.5-2.6 0-.8 1-1.8.5-.5 1-.95z"
                    />
                    <path
                        fill="#00BBBF"
                        d="M348.45 327.9q.3-1.35.3-2.25.1-.2.3-.45.05-.05.1-.15.05-.05.15-.1h-58.75q-.65.55-1.5.95-.75.4-2 .95-.9.5-.9 1.05h62.3z"
                    />
                    <path
                        fill="#54301B"
                        d="M371.45 325.2v-14l-12.15 13.75h-4.2l-2.9 2.95v19.15l4.3-4.9v-.05l14.9-16.9h.05m-3.65-3.85q1.1 0 1.4.3.117.117.2.35.617 2.807-5.7 8.75-4 3.35-5.7 4.2-1.15-.05-1.4-.65-.1-.2-.1-1.55 0-2.05 2.95-5.45.4-.45.85-.9.25-.3.55-.6l.6-.6q.1-.05.15-.15 3.8-3.7 6.2-3.7z"
                    />
                    <path
                        fill="#009295"
                        d="M369.2 321.65q-.3-.3-1.4-.3-2.4 0-6.2 3.7-.05.1-.15.15l-.6.6q-.3.3-.55.6-.45.45-.85.9-2.95 3.4-2.95 5.45 0 1.35.1 1.55.25.6 1.4.65 1.7-.85 5.7-4.2 6.317-5.943 5.7-8.75-.083-.233-.2-.35z"
                    />
                    <path
                        fill="#FF3800"
                        d="M382.9 341v.05q-2.76 8.876 0 17.25l13.35-20V323l-13.35 18z"
                    />
                    <path
                        fill="#FF9A00"
                        d="M378.25 345.75q.75-.3 2.8-2.45 1.324-1.384 1.85-2.25V341l13.35-18h-24.8v2.2h-.05l-14.9 16.9v.05l-4.3 4.9h-71.45V331.7l-19.4 16.25h101.9l.1-.05q.855.104 1 .2H369q3.3-.6 5.05-1.05 1.8-.45 4.2-1.3m-3.7-1.75l-.4.25h-.25l.65-.25z"
                    />
                    <path fill="#FFF" d="M374.15 344.25l.4-.25-.65.25h.25z" />
                    <path d="M371.45 311.2l7.1-8.1v-17H319.1l-31.25 19.75v19.1h71.45l12.15-13.75m-48.4-1.1q.098.028.25.05-.189-.011-.35-.05h.1z" />
                    <path
                        fill="#8C0000"
                        d="M323.3 310.15q-.152-.022-.25-.05h-.1q.161.039.35.05z"
                    />
                </g>
                <g id="ObjToy038__Layer14_1_MEMBER_0_FILL">
                    <path
                        fill="#272727"
                        d="M305.7 305.85h-17.85v19.1h17.85v-19.1z"
                    />
                    <path
                        fill="#8C0000"
                        d="M359.3 324.95v-19.1h-53.6v19.1h53.6m-8.5-12.85q1.25.5 1.25 1.6 0 .85-1.35 1.35-1.9.5-3.05.9-.6.2-1.8 1.4-.219.453-.35.65-.35.4-1.15.7-.85.3-1.65.3-.75 0-2.1-.65t-2-.65q-.7 0-2.3.85-1.65.85-3.55.85-.9 0-2.7-.85-1.55-.7-1.8-1.05-.3-.3-1.15-.55h-1.4q-.982.179-2.2.85-1.624 1.204-2.4 1.35h-1.45q-.057-.021-.15-.05h-.5q-.9-.25-2.1-1.3-1.05-.9-2.4-1.05-2.8-.3-3.95-.85-1.1-.55-1.1-1.5 0-1.15 1.05-1.5.35-.1 5-1.1 1.15-.25 2.7-1.3 1.55-1 3.3-1.3h1.65q.25.05 1.25.5 1.1.45 2.05.45 1.15 0 2-.6l2.1-.5h1.65q1.15.3 2.9.95 1.15.45 2.15.45 1.4 0 2.75-.6 1.3-.65 2.05-.65 1.4 0 2.65 1.1 1 .9 2.35 1.1 2.85.35 3.75.7z"
                    />
                    <path d="M352.05 313.7q0-1.1-1.25-1.6-.9-.35-3.75-.7-1.35-.2-2.35-1.1-1.25-1.1-2.65-1.1-.75 0-2.05.65-1.35.6-2.75.6-1 0-2.15-.45-1.75-.65-2.9-.95h-1.65l-2.1.5q-.85.6-2 .6-.95 0-2.05-.45-1-.45-1.25-.5h-1.65q-1.75.3-3.3 1.3-1.55 1.05-2.7 1.3-4.65 1-5 1.1-1.05.35-1.05 1.5 0 .95 1.1 1.5 1.15.55 3.95.85 1.35.15 2.4 1.05 1.2 1.05 2.1 1.3h.5q.093.029.15.05h1.45q.776-.146 2.4-1.35 1.218-.671 2.2-.85h1.4q.85.25 1.15.55.25.35 1.8 1.05 1.8.85 2.7.85 1.9 0 3.55-.85 1.6-.85 2.3-.85.65 0 2 .65t2.1.65q.8 0 1.65-.3.8-.3 1.15-.7.131-.197.35-.65 1.2-1.2 1.8-1.4 1.15-.4 3.05-.9 1.35-.5 1.35-1.35m-29-3.6q.098.028.25.05-.189-.011-.35-.05h.1z" />
                    <path
                        fill="#CB0000"
                        d="M338.65 293q-1.55.75-4.3 2.6-3.2 2.2-5.5 3.45-.75.45-3.05 2.2-2.4 1.7-4.1 2-14.15 2.55-15.85 2.6h53.45l12.65-13q-1.35-.25-2.9-.5-6.3-1.2-8-1.35-1.25-.15-6.7-.15-11.1 0-15.7 2.15z"
                    />
                </g>
                <g id="ObjToy038__Layer14_1_MEMBER_1_MEMBER_0_FILL">
                    <path
                        fill="#D7F0FF"
                        d="M360.9 304.35v-19.1h-70.7v19.1h70.7z"
                    />
                    <path
                        fill="#83D2FF"
                        d="M380.15 268.15h-.1l-19.15 17.1v19.1l19.25-20.05v-16.15z"
                    />
                </g>
                <g id="ObjToy038__Layer14_1_MEMBER_2_FILL">
                    <path
                        fill="#7B5800"
                        d="M368.25 270.65q-.4.45-1.15.55v2.3l2.55-2.3v-1.8q-.65.4-1.4 1.25m-1.05-5.35l-.05.05v.25q0 .15.05.3-.15.25-.3.45h2.75v-2.25q-.3.35-.45.5-.1.05-.9.1-.75.05-1.1.6m.3-6q-.05.15-.1.35 0 .2-.05.45v.2q.1.4.3.95 0-.15.05-.3.25-.6 1.2-1.15.3-.15.75-.35v-5L367 256.4q.4.25.5.35.05.2.05 1.6 0 .1-.05.95z"
                    />
                    <path
                        fill="#A37503"
                        d="M365.95 255.75q.65.4 1.05.65l2.65-1.95h-3.95q-.3.85-.55 1.3h.8m-9.85-.3q.35-.55.6-1h-17.45l-.55.3q.15 0 .3.05.95.2.95 1 0 .1-.05.2.6.2 2.25 1.05 2.1 1.1 2.6 1.1.3 0 2.75-.75 2.5-.75 3.05-.75-.05-.45.35-1.05 2.05-.15 5.2-.15z"
                    />
                    <path
                        fill="#2C2C2C"
                        d="M373 252h-.1l-2.35 1.75v16.65l2.45-2.3V252z"
                    />
                    <path
                        fill="#314F6B"
                        d="M363.05 259.35v17.9l4.05-3.75v-2.3q-.3.1-.65.1-.6 0-1.25-.6-.75-.65-.75-1.35 0-1.65 1.2-2.15.8-.35 1.25-.85.15-.2.3-.45-.05-.15-.05-.3v-.25q-.4.55-.9.55-1.5 0-1.5-1.35 0-.5.35-1.05.25-.35.55-.55.2-.15.4-.25.35-.25.6-.4.6-.35 1-1.05-.2-.55-.3-.95v-.2q.05-.25.05-.45.05-.2.1-.35.05-.85.05-.95 0-1.4-.05-1.6-.1-.1-.5-.35l-3.95 2.95z"
                    />
                    <path
                        fill="#626262"
                        d="M343.5 252l-3.05 1.75h30.1l2.35-1.75h-29.4z"
                    />
                    <path
                        fill="#5E7C98"
                        d="M356.7 254.45q-.25.45-.6 1-3.15 0-5.2.15-.4.6-.35 1.05-.55 0-3.05.75-2.45.75-2.75.75-.5 0-2.6-1.1-1.65-.85-2.25-1.05.05-.1.05-.2 0-.8-.95-1-.15-.05-.3-.05l-9.25 5.3h32.6v18.1l1-.9v-17.9l3.95-2.95q-.4-.25-1.05-.65h-.8q.25-.45.55-1.3h3.95v5q-.45.2-.75.35-.95.55-1.2 1.15-.05.15-.05.3-.4.7-1 1.05-.25.15-.6.4-.2.1-.4.25-.3.2-.55.55-.35.55-.35 1.05 0 1.35 1.5 1.35.5 0 .9-.55l.05-.05q.35-.55 1.1-.6.8-.05.9-.1.15-.15.45-.5v2.25h-2.75q-.45.5-1.25.85-1.2.5-1.2 2.15 0 .7.75 1.35.65.6 1.25.6.35 0 .65-.1.75-.1 1.15-.55.75-.85 1.4-1.25v1.8l.9-.8v-16.65h-30.1l-1.2.7h17.45z"
                    />
                    <path
                        fill="#FF6519"
                        d="M356 264.6q3.537-.229 6.05-4.55h-32.6l-7.95 4.55q17.86 1.986 34.5 0z"
                    />
                </g>
                <g id="ObjToy038__Layer14_1_MEMBER_2_MEMBER_0_FILL">
                    <path
                        fill="#FF872A"
                        d="M356 283.75V264.6h-34.5v19.15H356z"
                    />
                    <path
                        fill="#FF3800"
                        d="M356 264.6v19.15l6.05-5.6v-18.1L356 264.6z"
                    />
                </g>
                <g id="ObjToy038__Layer14_1_MEMBER_2_MEMBER_1_FILL">
                    <path
                        fill="#D99D09"
                        d="M363.05 259.35l-1 .7v18.1l1-.9v-17.9m6.6-4.9v16.75l.9-.8v-16.65l-.9.7z"
                    />
                    <path
                        fill="#FFC943"
                        d="M362.05 260.05l1-.7H330.7l-1.25.7h32.6m-21.6-6.3l-1.2.7h30.4l.9-.7h-30.1z"
                    />
                    <path
                        fill="#37AD4D"
                        d="M336.2 258.15q1.25 0 2.55-.9 1-.75 1.15-1.25.05-.1.05-.2 0-.8-.95-1-.15-.05-.3-.05l-5.1 2.95h.65q.8.35.95.4.15.05 1 .05m30.7 8.2q-.45.5-1.25.85-1.2.5-1.2 2.15 0 .7.75 1.35.65.6 1.25.6.35 0 .65-.1.75-.1 1.15-.55.75-.85 1.4-1.25v-3.05h-2.75z"
                    />
                    <path
                        fill="#382800"
                        d="M369.65 259.45q-.45.2-.75.35-.95.55-1.2 1.15-.05.15-.05.3-.4.7-1 1.05-.25.15-.6.4-.2.1-.4.25-.3.2-.55.55-.35.55-.35 1.05 0 1.35 1.5 1.35.5 0 .9-.55l.05-.05q.35-.55 1.1-.6.8-.05.9-.1.15-.15.45-.5v-4.65z"
                    />
                    <path
                        fill="#D7D7D7"
                        d="M356.7 254.45q-.25.45-.6 1-.2.4-.5.9v1.35h1.8q.75-.75 1.25-1.05.75-.45 1.75-.45.55 0 1.25.3.75.3 1.35.3 1.35 0 2.1-1 0-.05.05-.05.25-.45.55-1.3h-9z"
                    />
                </g>
                <g id="ObjToy038__Layer14_3_FILL">
                    <path
                        fill="#06F"
                        d="M363.25 347.95V366h14.5v-18.05h-14.5z"
                    />
                    <path
                        fill="#00388C"
                        d="M382.9 341h-.05l-5.1 6.95V366l5.15-7.7V341z"
                    />
                </g>
                <g id="ObjToy038__Layer14_4_FILL">
                    <path
                        fill="#FFF"
                        d="M280.75 343.15h-7.3l7.3-6.45v-1.2l-10.6 8.85h10.6v-1.2m90.65-17.95l-.8.95 17.7-.05-12.25 17-20.45.05-1.05 1.2 19.15-.1 2.05-.75-.05.1v.05l.3-.15h.05l1.4-.6.05.1.05.05 12.9-18h-19v.15h-.05z"
                    />
                    <path
                        fill="#0095FF"
                        d="M361.45 338.95q-.6 0-.6-.15h-1.45l-2.9 3.3v.05q1.2.1 1.95.1 1.25 0 1.55-.05.75-.15.85-.85-.2-.15-.7-.3-.45-.15-.95-.15.15-.6.15-.75.2 0 1.7.05.4 0 .4-.7v-.55m5.35.15H363.4q-1.05.15-1.05.85 0 2.5 2.45 2.65 2.45.15 2.45-2 0-1.35-.45-1.5m-1.9 1.05h1.05v.1q.05.05.15.05 0 .55-.7.8-.05 0-.2.1l-.75-.15q-.1-.35.05-.55.1-.1.4-.35m7.9 0q-.1-1.35-3-1.35-.7-.05-1.05 0-.6.1-.6.6 0 1.05.9 2.85h.7q1.5-.05 2.15-.45-.15-.45-.15-.75-.9-.15-1.35-.15v-.6q1.8 0 2.4-.15m1.85-11.15q.2-.05 1.05-.05 1.15 0 1.6.15-.1.2-1.05 1.45-.9 1.3-.9 1.9 0 1 2.3 1 .95 0 1.4-.25.05-.05.15-.1l.05-.05q.5-.85 1.3-1.7 1.25-1.05 2.05-1.8 1.45-1.4 1.55-2.25h-14.6l-5.05 5.75q.4.05.7.1h2.65q-.2-1.1.55-2.2.45-.7 1.85-2h1.35q0 .35-.9 1.25-.9.8-.9 1.65 0 .85.75 1h1.55q.7 0 1.4-.25.25-.1 1.4-.65v-1.5h-1.05v-.75q.1-.55.8-.7z"
                    />
                    <path
                        fill="#FF0"
                        d="M375.7 328.95q-.85 0-1.05.05-.7.15-.8.7v.75h1.05v1.5q-1.15.55-1.4.65-.7.25-1.4.25h-1.55q-.75-.15-.75-1t.9-1.65q.9-.9.9-1.25h-1.35q-1.4 1.3-1.85 2-.75 1.1-.55 2.2h-2.65q-.3-.05-.7-.1l-4.3 4.9h13.25q1.35-1.2 3.2-2.75 1.9-1.6 2.4-2-.45.25-1.4.25-2.3 0-2.3-1 0-.6.9-1.9.95-1.25 1.05-1.45-.45-.15-1.6-.15z"
                    />
                </g>
                <g id="ObjToy038__Layer5_0_FILL">
                    <path
                        fill="#0095FF"
                        d="M287.6 353.25q-.2-.2-.55-.2-.3 0-.55.2-.2.25-.2.55 0 .8.2 5.55 0 .3.2.5.25.25.55.25.65 0 1.9.05.6.05 1.35.05h2.05q1.05.25 1.05-1.65v-1.35q0-.3-.2-.55-.25-.2-.55-.2-.3 0-.55.2-.25.25-.25.55v1.5q-.85 0-1.55-.05h-.65q-1.1-.05-1.85-.1-.15-3.95-.15-4.75 0-.3-.25-.55m10.65 1.3v.7q0 .75-.45 4.15-.05.35.2.6.15.2.5.25.3.05.55-.1.25-.25.3-.55.45-3.6.45-4.35 0-1.2-.25-1.5-.3-.8-1.8-.8l-3.4.1q-.4 0-.55.25-.25.25-.25.55 0 .3.3.55.2.2.55.2l3.35-.1q.35 0 .45.05h.05m5.9-.85q0-.35-.2-.55-.25-.25-.55-.25-.3 0-.55.25-.2.2-.2.55l-.05 4.75q-.1 1.3 1.5 1.65.5.1 1.85.05.3 0 .55-.25.15-.25.15-.55 0-.35-.2-.55-.3-.2-.55-.2-1.1.05-1.55 0-.2-.1-.2-.15v-4.75m3.05 1.35q-.3 0-.55.2-.2.25-.2.55 0 .3.2.55.25.2.55.2l4.5-.05q.35 0 .55-.2.25-.25.25-.55 0-.3-.25-.55-.2-.2-.55-.2l-4.5.05m9.25-1.9q-.2-.25-.55-.25-.3 0-.55.25-.2.2-.2.55 0 2.7.15 5.1h-2.55q-.3 0-.55.2-.2.25-.2.55 0 .3.2.55.25.2.55.2 4.85 0 6.35-.1.3-.05.5-.3.25-.2.2-.55-.05-.3-.3-.55-.2-.2-.5-.15-.75.05-2.2.1-.1-2.4-.1-5.05 0-.35-.25-.55m7.8 1h.05q.15.35.35 2.15.05.4.05 3.1 0 .35.25.55.2.25.55.25.3 0 .5-.25.25-.2.25-.55 0-2.9-.05-3.3-.3-2.35-.6-2.75v.05q-.2-.7-1.8-.7-1.1 0-3.4.25v.05h-.3q-.3.05-.5.3t-.15.55q.05.3.3.55.2.2.55.15.2-.05.45-.05v-.05q2.05-.25 3.05-.25.35 0 .45-.05m8.75-1.65h-3.6q-.8.1-.9.2-.1 0-.1.05-.5.05-.5 1.6 0 .95.25 2.05h-.05q.1.55.35 1.65l-.05-.1q.05.35 0 .6 0 .25.1.45.1.6 1.95.6.75 0 3-.25.3-.05.45-.25.25-.3.2-.55-.05-.35-.25-.55-.3-.2-.6-.15-2.15.2-2.8.2-.3 0-.45.1-.05-.2-.05-.35v-.05l-.3-1.65q-.2-.9-.2-1.75 0-.2-.05-.3h3.6q.3 0 .5-.25.25-.2.25-.55 0-.3-.25-.5-.2-.25-.5-.25m8.5.05q-.25-.25-.55-.25-3.5.15-4.05.2-1.2-.1-1.2 1.9 0 .55.1 1.65.15 1.8.55 3.35.05.35.35.5.25.15.55.1.35-.1.5-.35.15-.3.1-.6-.35-1.5-.5-3.1v-.05q-.15-1.05-.15-1.5 0-.35-.15-.35.5-.05 3.95-.2.35 0 .55-.2.2-.3.2-.55 0-.35-.25-.55z"
                    />
                    <path
                        fill="#ADFDFF"
                        d="M312.75 333.3q-.35 0-.6.25t-.25.65l.05 5.05q0 .3.25.55.25.25.65.25.3 0 .55-.25.25-.25.25-.6l-.05-5.05q0-.35-.25-.6t-.6-.25m6.45.25H317q-1.9-.05-1.9 3.75 0 1.95 1.3 2.5l.05.05q.65.3 3.15.35.3 0 .6-.2.25-.25.25-.6t-.2-.6q-.25-.3-.6-.3-1.95-.05-2.55-.2-.3-.3-.3-1 0-1.9.35-2.05h2.05q.35 0 .6-.25t.25-.6q0-.35-.25-.6t-.6-.25m4.1.1q-.25-.25-.6-.2-.35.05-.6.3t-.2.65l.45 5.15q.05.3.3.55.25.25.65.2.3-.05.55-.3.25-.25.2-.6l-.45-5.15q-.05-.35-.3-.6m-19.65 5.6q-.2.25-.1.6.05.35.35.55.25.2.6.15 1.05-.2 3-.1h.1q2.4-.15 2.55-2.1l.15-2.25v-.15q-.05-.65-.05-.95 0-.2-.05-.25.25-.25.25-.6t-.25-.6q-.25-.25-.6-.25t-.6.25q-.45.3-.45 1.45 0 .35.05 1v.1l-.15 2.05q-.15.55-.9.6h-.05q-2.15-.1-3.3.15-.35.05-.55.35m-.15-5.4h-2.95q-.1 0-.2.05-1.3.2-1.4 2.15v.05q-.05.65.05 1.5l.05 2.3q0 .35.3.6.25.2.6.2t.6-.25q.2-.3.2-.6l-.05-2.35v-.1q-.1-.7-.05-1.2v-.05q0-.45.1-.6h2.75q.35 0 .6-.25t.25-.6q0-.35-.25-.6t-.6-.25m21.9-.35q-.269.225-.3.55-.072.552.55 3.9v-.05q.138 1.172.65 1.75.77 1.127 2.7.75 2.576-.141 2.8-2.25.004-.038 0-.1.006-2.16.25-3.55.056-.353-.15-.65-.204-.276-.55-.35-.354-.044-.65.15-.289.203-.35.55-.25 1.469-.25 3.7-.172.741-1.2.8-.062.008-.15 0-.752.19-1.1-.1l-.05-.05q-.194-.273-.25-.85-.012-.025-.05-.05-.529-2.953-.5-3.45.03-.326-.2-.6-.225-.269-.6-.3-.326-.03-.6.2z"
                    />
                    <path
                        fill="#838383"
                        d="M315.3 314.55q-.35-.1-.6.1-.3.2-.35.55-.1.35.1.65.2.25.55.35 2.5.45 4 .35.05.5.25 1.55v.45l-3.7.05q-.4 0-.6.3-.25.25-.25.6t.3.6q.25.2.6.2l4.55-.1q.3 0 .55-.25.25-.25.25-.6v-1.25-.15q-.2-1.1-.25-1.55v-.1q-.3-1.7-1.85-1.45-1.35.1-3.55-.3m1.7-5.65h-1.55q-.35 0-.6.25t-.25.6q0 .35.25.6t.6.25h1.5q.9.1 1.1.5H318q.1.55-.05 1.55-.05.35.2.65.2.25.55.3.35.05.65-.15.25-.25.3-.6.15-1.3.05-2v-.05q-.2-1.65-2.6-1.9h-.1m9 9.75q-.1-.35-.35-.55-.3-.2-.65-.1-1.6.25-2 0-.1-1.3-.25-2.15-.05-.35-.35-.55-.3-.25-.6-.2-.35.05-.55.35-.25.3-.2.65.15.9.25 2.3 0 .2.1.4.6 1.45 3.9.85.35-.1.55-.35.2-.3.15-.65m1.3-.4v.05q.3.55 1.35 1.25.25.2.6.15.15-.05.35-.15h.3q.3-.1 1.2-.25.05 0 .1-.05 1.5-.45 1.4-1.65 0-1.05-.55-1.8-.85-1.2-2.5-1.2-1 0-1.65.6-.85.65-.85 1.7 0 .9.25 1.35m1.5-.75q-.05-.2-.05-.6 0-.25.2-.35 0-.05.05-.05.2-.2.55-.2.75 0 1.1.5h.05q.2.35.2.8-.04 0-.2.05l.15-.05q-1.05.15-1.4.3-.1 0-.15.05-.35-.3-.5-.5v.05m-2.4-5.85q-.95-.05-1.45-.1h-.05q-.7-.05-1.05-.15.05-.05.05-.2v-.1q0-1.05-.1-1.7-.05-.35-.35-.55-.3-.25-.6-.2-.35.05-.55.35-.25.3-.2.65.1.6.1 1.55v.1q.2 1.4.9 1.6v.05q.55.25 1.7.3.55.05 1.5.1.35 0 .65-.2.25-.25.25-.6t-.2-.6q-.25-.3-.6-.3m7.4 2.65q-.35 0-.6.25t-.25.6q0 .35.25.6t.6.25l3.4-.05q.2 1.65.35 3.15.05.3.3.55.25.25.65.2.3-.05.55-.3.25-.25.2-.6-.15-1.85-.5-4-.05-.3-.3-.5t-.55-.2l-4.1.05m-3.9-5.5q-.25-.25-.6-.25t-.6.25q-.25.25-.25.6 0 3.25 1.05 3.75.25.2.6.15.35-.1.55-.35.2-.3.15-.65-.1-.35-.35-.55-.3-.4-.3-2.35 0-.35-.25-.6m6.3-.35q-.2-.15-.45-.15h-2.9q-.35 0-.6.25t-.25.6q0 .35.25.6t.6.25h2.6q.2.3.25.9.05.7.05 1.75 0 .35.25.6t.6.25q.35 0 .6-.25t.25-.6q0-1.15-.05-1.85-.15-1.8-1.2-2.35m8.25 6.8q.15-.35.1-.65-.1-.35-.4-.5-.35-.2-.65-.1-.35.05-.5.4l-2.65 4.5q-.2.3-.1.65.05.3.4.5.3.15.65.1.3-.1.5-.4l2.65-4.5m.75-2.6q.2-.3.2-.6 0-.4-.25-.6-.3-.25-.6-.25l-3.35.1q-.25 0-.3.05.05-1.35-.2-2.55-.1-.35-.35-.55-.35-.2-.65-.1-.35.05-.55.35-.2.3-.1.65.2 1.1.15 2.3v.15q.1 1.4 2 1.4l3.4-.1q.35 0 .6-.25z"
                    />
                    <path
                        fill="#ECF8FF"
                        d="M317.95 280.1q-.15.05-.75.3t-1.1.35h-9.8q-1.95-.25-1.95.65 0 .8 1.8.95h10.3q2-.65 2.6-1.15.4-.4.4-1.15 0-.95-.65-1.3-1.4-.7-6.05-.9h-4.8q-.45.2-.65.5-.1.15-.1.2 0 .75.75.8l4.55.05q1.75.2 5.45.55v.15m3.5-2.85v-1.5l-7.35-.1q.4-.25.4-.65 0-1.15-1.6-.6-1.65.55-1.65 1.4 0 .6.15.75.3.4 1.8.7h8.25m0-3.2v-1.55h-3.2v.05q-1.65.05-2.2.4-.25.2-.2.4l.1.4q.3.35 1.3.35.4 0 1-.05h3.2m0-2.3v-1.55l-2.15.05q-.4.2-.6.45-.1.15-.15.25 0 .75.75.8h2.15z"
                    />
                    <path
                        fill="#FFF"
                        d="M354.4 296.85q-1-.15-1.85.05-.8.25-.8.65 0 .65 1.9.95 1.95.2 1.95.8 0 .35-.35.6-2.75.05-3.55.4l-.25.4.05.4q.45.35 2.3.35 2.6 0 3.2-.85.1-.2.1-1.25 0-2.2-2.7-2.5m-2.65-2l.15.75q.65.8 2.9.8 1.6 0 2.15-.35.3-.15.3-.6-.1-.55-.75-.7-.85.05-1.6.05-1.45.05-1.8-.4-.8-.65-1.15-.3-.2.25-.2.75m0-3.3q0 .05.3.6.7.45 2.65.05 0 1.2.9 1.2.75 0 .75-1 0-1.2-2.3-1.65-2.3-.35-2.3.8m-.4-3.05q-.3.2-.2.4l.05.4q.35.35 1.75.35 3.6 0 4.5-.3.25-.15.25-.6 0-.35-.45-.55-.9-.45-3.3-.1-2 .05-2.6.4z"
                    />
                    <path
                        fill="#F60"
                        d="M316.55 291.75q.2.25.2.9 0 .4-.3 1.95 0 .05.15 1.15.1.35.25.6l.7-.2q-.1-.5-.1-1.1 0-1.8.9-2.75l-.1-.6q-.3-1.5-1.15-1.7-.8-.2-.8.9 0 .55.25.85m14.4-1.15q.05 0 .1.1 1.05.75 1.45 1.4.15.2.2.45.2-.25.2-.7 0-1.15-.3-2.5-.3-1.4-.75-2.4 0-2.1-1.85.25-.75.9-1.45 2.1h.3q.45.25.85.5.1.05.2.15.55.3 1.05.65z"
                    />
                    <path d="M326 299.25q0 .8-.25 1.25-.134.192-.35.35.397-.091.75-.2 1.1-.35 1.75-.8 1.25-.85 1.25-2.6 0-2.05-1.65-2.85-2.2-.95-6.65.75-1.25.35-2.3.7-.55.15-1 .3l-.7.2q-1.8.55-2.85.95-3.55 1.2-3.55 2.8 0 .5.15.7.3.35 1.4.65h2.65q-.916-.031-1.15-.5v-.6q0-1.31 5.45-2.5.178-.014.35-.05 4.914-.966 5.7-.05.251.076.4.15.6.3.6 1.35m4.85-8.05q-.15-.05-.35-.05-.7 0-.9.5-.05.15-.05.35 0 .3.15.5.2.45.8.45.4 0 .65-.3.15-.2.25-.4v-.2q0-.6-.35-.8-.1-.05-.2-.05z" />
                    <path
                        fill="#FFFC66"
                        d="M325.75 300.5q.25-.45.25-1.25 0-1.05-.6-1.35-.149-.074-.4-.15-.786-.916-5.7.05-.172.036-.35.05-5.45 1.19-5.45 2.5v.6q.234.469 1.15.5h6.3q2.66-.164 4.45-.6.216-.158.35-.35m5.3-9.8q-.05-.1-.1-.1-.5-.35-1.05-.65-.1-.1-.2-.15-.4-.25-.85-.5H323.95q-4.4 1.95-5.4 2.8l-.2.2q-.9.95-.9 2.75 0 .6.1 1.1.45-.15 1-.3 1.05-.35 2.3-.7 4.45-1.7 6.65-.75 1.65.8 1.65 2.85 0 1.75-1.25 2.6-.65.45-1.75.8 4.05-.3 5.7-2.1 1.2-1.3 1.2-3.65 0-1.5-.35-2.35-.05-.25-.2-.45-.4-.65-1.45-1.4m-.55.45q.2 0 .35.05.1 0 .2.05.35.2.35.8v.2q-.1.2-.25.4-.25.3-.65.3-.6 0-.8-.45-.15-.2-.15-.5 0-.2.05-.35.2-.5.9-.5z"
                    />
                    <path
                        fill="#FFC943"
                        d="M340 275.9h-.05q-.75.05-1.65 0-.35 0-.6.25-.3.2-.3.55 0 .35.25.65.2.25.55.25 1 .05 1.85 0 1.65-.05 2.7.05.35.05.6-.2t.3-.55q.05-.4-.2-.65t-.55-.3q-1.1-.1-2.9-.05m2.7-1.6q.35 0 .6-.25t.25-.6q0-.35-.25-.6t-.6-.25l-4.35.05q-.35 0-.6.25t-.25.6q0 .35.25.6t.6.25l4.35-.05m-7.3-3.1q-.35 0-.6.25t-.25.65l.05 4.9q0 .3.25.55.25.25.65.25.3 0 .55-.25.25-.25.25-.6l-.05-4.9q0-.35-.25-.6t-.6-.25m-3.75.35q-.25.25-.3.6-.15 1.65-.1 3v.05q0 .9.3.9l-1.25.1v1.7l1.45-.1q1.2.1 1.2-2.65-.05-1.3.1-2.85.05-.4-.2-.65t-.55-.3q-.4-.05-.65.2m18.65.2q-.05-.35-.3-.6t-.6-.2q-.35.05-.6.3t-.2.65q.15 1.65.1 4.65v.1q-.1 1.15 2.4 1.15h.05q2.15-.05 2.25-.05.35 0 .6-.25t.25-.6q0-.35-.25-.6t-.6-.25q-.1 0-2.3.05-.65 0-.7.3.05-3-.1-4.65m-4.25-.95q-.35 0-.6.25t-.25.65l.05 5.25q0 .3.25.55.25.25.65.25.3 0 .55-.25.25-.25.25-.6l-.05-5.25q0-.35-.25-.6t-.6-.25z"
                    />
                </g>
                <path
                    fill="#B6E4FF"
                    d="M360.9 285.25q12.506-4.886 19.15-17.1h-63.5l-26.15 17.1q35.263 7.791 70.5 0z"
                    id="ObjToy038__Layer14_1_MEMBER_1_FILL"
                />
                <path
                    fill="#9F6443"
                    d="M338.45 330.65h-1.65q-.6-.1-1.9-.05-.75 0-1.3-.05-.6-.5-1.35-.85-1.55-.85-3.15-.85-2.2 0-3.2.5-.3.15-.55.25-.05 0-.15.05-1.7.4-2.45.75h-3.95q-.8-.15-1.5-.3-.5-.2-1.1-.5-1.95-.95-2.55-1.2-2.025-.441-4.05 0-.55.1-2.3 1-1.5.75-3.2.75-1 0-1.75-.1-.2-.05-.6-.05-.25-.1-.45-.15-.8-.4-2.75-.4-3.1 0-4.2 1.5-.55.75-.55 2.15 0 .9.6 1.9.05.05.1.15l.1.2q.3.55.4.85-.05.25-.65 1.15-.7 1-.7 1.95 0 1.7.45 2.45.45.85 1.6 1.4.65.35 1.55.6.95.25 1.9.6.2.05.45.15 1.7.6 3.4 1.3 2.35.85 5.9.85 2.95 0 4.5-1.1l.6-.6q.229-.11.35-.15 1.75-.65 3.5-.65 1.05 0 3.35.85 1.131.418 2.05.6 1.818.6 2.95.6 2.95 0 4.9-1.2.3-.3.6-.5.05-.1.2-.15 1.05-.85 1.8-1.2.05 0 .1-.05.6-.25 4.1-.45.75-.15 1.35-.4 1.95-.8 1.95-2.65 0-.85-.55-1.45-.95-1.2-1.45-2.15 1.2-.75 1.45-1.4.1-.25.1-1.15 0-1.1-.25-1.55-.45-.9-2.05-1.25m-2.7 2.7q.2.1.2.55 0 .55-.45 1.15-.45.65-.45 1.15 0 .8 1.2 1.55 1.15.7 1.15 1.2 0 .75-1.2 1.25-.405.178-.7.35-2.274.237-3.8 1-.45.3-1.95 1.35-.2.15-.35.25-1.35.75-2.55.75-1.2 0-3.55-.75l-.9-.3q-1.854-.671-2.6-.95-.917-.444-1.55-.55h-2.1v.05q-1.2.05-2.15.7-.55.35-1.65 1.15-.811.417-1.45.75-.965.324-2 .35h-.4q-2-.05-3.95-1-.25-.1-.45-.2-2.1-.9-2.7-1.05-3.15-.5-4.45-1.25-1.05-.6-1.05-1.5 0-.2.7-1.2.65-1.05.65-2 0-.85-.6-1.7-.6-.9-.6-1.45-.05-.35.2-.65v-.05q.323-.05.75-.05.589 0 1.1.05.766.427 2.9 1h3.6q1.5-.15 3-.8 1.6-.75 2.95-.95h1.9q.479.032 1.4.2.52.213 1.05.45 1.8.8 3.4.95h4.15q.161-.027.8-.25.47-.172 1.2-.45.133-.027.5-.2.181-.032.3-.1.136.04.25.05.23-.096.45-.2 1.304-.3 2.3-.3.8 0 1.4.35 1.35.75 2 1h2.55q1.3.15 1.55.3z"
                    id="ObjToy038__Layer14_1_MEMBER_3_FILL"
                />
                <path
                    fill="#004FC6"
                    d="M374.15 344.25h-.25l-10.5 3.7h-.15q1.406 9.025 0 18.05 7.25-2.232 14.5 0 .604-5.633 5.15-7.7-1.908-8.364 0-17.3h-.05l-.1.15-5.15 1.8-.05.1-.05-.05-1.8.65v-.05l-1.15.4-.4.25z"
                    id="ObjToy038__Layer14_2_FILL"
                />
                <path
                    fill="#FF0"
                    d="M350.1 361.2q.827-.081 1.5-.2 1.374-.277 2.05-.75 1.05-.7 1.05-2.15 0-1.7-3.25-2.4-.242-.052-.5-.1h-78.2l-.5.1q-3.25.7-3.25 2.4 0 .639.2 1.1.262.658.85 1.05.7.45 2.05.75.65.1 1.5.2 1.891.079 3.7.15.513.029 1 .05h67.05q2.371-.104 4.75-.2z"
                    id="ObjToy038__Layer14_5_FILL"
                />
                <path
                    fill="#FFC943"
                    d="M343.45 263.3q.2-.2.1-.3-.1-.1-.4-.1h-3.35q-.25 0-.55.1-.35.15-.5.3-.2.15-.1.25.05.15.3.15h3.35q.3 0 .65-.15.3-.1.5-.25m-4.25-1.65q.15-.1.05-.25 0-.1-.3-.1-.25 0-.65.1-.25.15-.4.3l-2.6 2.35q-.15.2-.1.3.05.1.3.1.3 0 .65-.1.35-.1.5-.3l2.55-2.4m-7.05.25q.05.15.35.15h1.75q.478 0 .6-.15-1.453 1.404-2.1 2.15-.15.15-.1.25.05.1.4.1.25 0 .6-.15.3-.1.45-.3.75-.75 2.25-2.1l.05-.05q.7-.55-1.25-.55h-1.75q-.3 0-.65.15-.3.1-.5.25-.15.15-.1.25m13.1-.4q-.05-.1-.3-.1h-3.55q-.2-.05-.5.05-.35.1-.5.3-.25.15-.15.25 0 .1.2.15.85.05 2.25 0h1.3q.3 0 .6-.1.35-.1.5-.25.15-.15.15-.3m2.1.25q.15-.2.15-.3-.05-.1-.4-.1-.2 0-.55.1-.35.1-.5.3l-2.4 2.25q-.2.15-.1.25.05.15.3.15t.6-.15q.3-.1.5-.3l2.4-2.2m6.65-.05q-.55.45-.75.7l-.05.05q-.05.15-.45.5-.45.4-.25.4-.05.05-.1.05-.35.05-2 0-.3 0-.6.1-.3.15-.45.3-.2.15-.15.25.05.15.3.15 2 .05 2.55-.1.75 0 2-1.15.5-.45.7-.7l-.05.05q.15-.2.65-.6.15-.15.05-.3 0-.1-.25-.1-.3 0-.7.1-.3.15-.45.3m-1.7-.05q.2-.15.15-.25-.05-.15-.4-.1l-2.05.05q-.85-.05-2.25 1.2-.6.6-1.55 1.35-.15.15-.15.25.1.1.3.15.3 0 .6-.1.35-.15.55-.3.9-.75 1.55-1.35.446-.446.25-.45h1.95q.35-.05.6-.2.35-.1.45-.25z"
                    id="ObjToy038__Layer5_0_MEMBER_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjToy038__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_MEMBER_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_MEMBER_2_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_MEMBER_2_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_1_MEMBER_3_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_2_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_3_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_4_FILL" />
            <use xlinkHref="#ObjToy038__Layer14_5_FILL" />
            <use xlinkHref="#ObjToy038__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy038__Layer5_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjToy038
