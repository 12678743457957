import { AssetSvgProps } from "../../../shared/assets"

function SkiMor018({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <radialGradient
                    id="SkiMor018__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={11.2}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(241.45 207.65)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <path
                    fill="url(#SkiMor018__Gradient_1)"
                    d="M251.45 207.65q0-4.15-2.95-7.1-2.9-2.9-7.05-2.9t-7.1 2.9q-2.9 2.95-2.9 7.1 0 4.15 2.9 7.05 2.95 2.95 7.1 2.95 4.15 0 7.05-2.95 2.95-2.9 2.95-7.05z"
                    id="SkiMor018__Layer3_0_FILL"
                />
            </defs>
            <use xlinkHref="#SkiMor018__Layer3_0_FILL" />
        </g></g>
    )
}

export default SkiMor018
