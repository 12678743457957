import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.95 24.55L31.05 2.5 28.95 0 0 21.95l1.95 2.6m85-22.05l29.1 22.05 1.95-2.6L89.05 0l-2.1 2.5z"
                    id="FacBro001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(133.5 119.75)"
                id="FacBro001__col1n"
            />
        </g></g>
    )
}

export default FacBro001
