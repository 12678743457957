import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.302}
                    d="M112.8 14.6v-.45-.8q0-7.45-6.25-13.35 5.7 8.7 4.45 14.4v.05q-.55 3.7-2.7 7.85-.9 1.8-2.1 3.4-.85 1-1.85 1.95-1.95 1.9-4.15 3.25-4.75 2.9-10.75 3.25-3.5.75-10.2-.2-.45-.1-.55-.2-7.15-1.4-12.45-6.7-5.05-5-8.8-13.15-1.15-5-.95-10.7-.35 5.3-1.25 10.55-3.2 8.35-8 13-5.95 5.75-12.6 7.05-.55.05-1.1.15-6.7.95-10.2.2-6-.35-10.75-3.25-2.2-1.35-4.15-3.25-1-.95-1.85-1.95-1.2-1.6-2.1-3.4-2.15-4.15-2.7-7.85v-.05Q.55 8.7 6.25 0-.45 6.3.05 14.4q.1 1.7.55 3.4.2 1.3.65 2.55 1.8 5.4 6.95 9.7.55.45 1.1.85 7.3 5.45 17.2 5.75h1q3.75 0 7.15-.7 6.8-1.5 12.1-5.9 6-4.95 9.7-14.85 3.65 9.75 9.8 14.85 5.25 4.3 11.9 5.85v.05q1.7.35 3.5.5l3.65.2H87l1.2-.1q.3 0 .65-.05 9.2-.85 15.95-6.45 7.8-6.45 8-15.45z"
                    id="SkiMor010__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor010__Symbol_14_0_Layer0_0_FILL"
                transform="translate(136.1 253.85)"
                id="SkiMor010__col1n"
            />
        </g></g>
    )
}

export default SkiMor010
