import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#333333",
    "col1n": "#000000"
}

function AccMor041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M42.4 3.45l-.05-.15-.2-.35q-.324-.462-.7-.95-.2-.3-.45-.55Q38.35-1.1 35.7 1q-.1 0-.15.1l-5.5 4.5q-.65-1.45-1.85-2.65Q25.65.4 22.05.4q-3.6 0-6.2 2.55-1.2 1.25-1.85 2.8-.05 0-.05.05l-5.7-4.7Q8.2 1 8.15 1q-2.7-2.1-5.35.45-.188.188-.35.4l-.1.15q-.3.35-.55.75-3.6 9.6 0 19.2 2.438 3.085 5.5.7-.026.05-.1.05.1-.05.15-.1.55-.45 1.15-1.05l6.45-7.05q.1.15.3.4l.05.05h-.05l.15.15h.05q.1.1.25.3l.1.1.2.2q2.15 2.05 5.05 2.3.45.05.9.05 1.85 0 3.4-.65l.95-.5.45-.3q.75-.5 1.45-1.2.4-.4.75-.85l6.4 7q1.15 1.25 2.3 1.75.05 0 .1.05h-.1q2.4 1.1 4.4-1.5.2-.45.35-.9 3.15-8.75 0-17.5z"
                    id="AccMor041__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M10.7 6.3v-.1q-1.05-.5-3.3-3.55Q5.15-.35 2.35.9-.5 2.15.1 3.55.7 4.9 4.95 5.6q4.25.75 4.65 1.65 1.9 1.7-1.75 4.2l-5.65 1.8q0 .05-.5.75-.5.65-.7 2.6-.25 1.95 1.1 2.85 1.3.8 2.5.35.1-.05.15-.1.55-.45 1.15-1.05l6.45-7.05q.073.11.2.25v-.15Q10.7 9.409 10.7 6.3m13.5 5.85q1.55-1.8 1.55-3.6t-.9-1.75q-2.25-.65-3.95 2.9-.65 1.4-2.9 1.75-2 .3-2 1.2 0 1.65 2.15 1.85 2.1.2 3.3-.2 1.15-.4 2.75-2.15m10-6.25q5.55.2 4.65-2.15-.9-2.3-1.85-3.35-1-1.1-3.05.7-3.7 2.6-5.95 3.3.2.9.2 1.9 0 3.05-1.85 5.35l6.4 7q1.15 1.25 2.3 1.75.05 0 .1.05.75-.3 1.55-.95 1.55-1.25 1.55-2.6 0-2.15-1.3-3.5-.55-.55-1.2-.95-.95-.1-3-.5-3.85-.75-3.95-3.5-.15-2.8 5.4-2.55z"
                    id="AccMor041__Symbol_57_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor041__Symbol_139_0_Layer0_0_FILL"
                transform="translate(170.6 248.2)"
                id="AccMor041__col1n"
            />
            <use
                xlinkHref="#AccMor041__Symbol_57_0_Layer0_0_FILL"
                transform="translate(173.2 251.1)"
                id="AccMor041__col1l"
            />
        </g></g>
    )
}

export default AccMor041
