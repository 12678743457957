import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#480000",
    "col1n": "#830000"
}

function ObjFoo035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#C1D2E2"
                    fillOpacity={0.349}
                    d="M136.85 314.3q-.85-1.15-4.75-2.25-4.25-1.1-10.25-1.25-.34-1.094-.4-2.35-.29.204-.6.35l-.2.1h-.05q-.89.339-1.75.5-.133.744-.45 1.4-5.85.15-10.2 1.25-3.65 1.1-4.65 2.25-.2.4-.3.75v1.75q.15.65.7 1.2 2.95 2.1 8.95 2.75 3.3.3 7.1.4h.25q4.85-.1 8.8-.6 5.15-.85 7.65-3 .25-.35.3-.75v-1.75q0-.35-.15-.75m-2.2-76.95q-2.7-.4-5.4-.6-8.7-.75-17.8 0-2.85.2-5.7.6-.75.2-.9.35v.05q-.05 0-.05.05-.1.5-.2.95-.7 2.85-1.1 5.75-1.2 6.9-1.2 15.75 0 1.65.05 3.35-.05.05-.05.1 0 .1.05.2v.7q0 2.9 1.05 5.4l.45 1.1 1.55 2.35q.95 1.2 2.1 2.25.7.65 1.4 1.1.7.55 1.5 1 .55.4 1.25.65.8.35 1.65.7 3.657 1.964 5 7.9.234.08.45.15 1.617.719 2.95 1.95 1.049-7.583 5.05-9.75l.8-.55h.05q.025-.004.05 0 .554-.19 1.1-.4.6-.25 1.2-.65.9-.5 1.75-1.15.5-.4 1.1-.95 1.2-1.05 2.1-2.2l1.6-2.4.5-1.1q.9-2.35.95-5.05 0-.5.05-.9v-.4-3.4q-.05-9-1-15.45-.5-3.3-1.25-6.1l-.2-.9q0-.05-.05-.05v-.05q-.15-.15-.85-.35z"
                    id="ObjFoo035__Layer16_0_FILL"
                />
                <path
                    fill="#C1D2E2"
                    fillOpacity={0.349}
                    d="M131.95 238.6l2.7-.35.3-.1q.5-.15.6-.3v-.05q0-.05-.05-.05v-.05q-.15-.15-.85-.35-2.7-.4-5.4-.6-8.7-.75-17.8 0-2.85.2-5.7.6-.75.2-.9.35v.05q-.05 0-.05.05l.05.05v-.05q.1.2.5.35l.35.1 2.7.35q11.65 1.45 23.55 0z"
                    id="ObjFoo035__Layer16_1_FILL"
                />
                <path
                    fill="#C1D2E2"
                    fillOpacity={0.349}
                    d="M137 316.8v-1.35q-.25 1.75-4.9 2.9-4.9 1.35-11.85 1.35H120q-6.8 0-11.8-1.35-4.65-1.15-4.95-2.9v1.35q.15.65.7 1.2 2.95 2.1 8.95 2.75 3.3.3 7.1.4h.25q4.85-.1 8.8-.6 5.15-.85 7.65-3 .25-.35.3-.75m-13.6-3.35q-.8-1.5-.95-1.65-.35-.5-.6-1-.34-1.094-.4-2.35-.29.204-.6.35l-.2.1h-.05l-.9.3q-.421.145-.85.25-.136.7-.45 1.35-.2.5-.45 1-.1.15-1 2.2-.3 1 .9 1.55 1.25.5 2.15.5h.25q1.05-.15 1.85-.3 2.1-.85 1.3-2.3m-5.1-26.4q.234.08.45.15 1.617.719 2.95 1.95 1.049-7.583 5.05-9.75l.8-.55q-8.077 2.72-15.9-.4.8.35 1.65.7 3.657 1.964 5 7.9z"
                    id="ObjFoo035__Layer16_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M34.2 9.6l.5-1.1q.9-2.35.95-5.05 0-.5.05-.9v-.4h-.05Q35.4 1.3 30.4.7 25.2 0 17.8 0 10.45 0 5.2.7.45 1.3.05 2.1 0 2.15 0 2.2q0 .1.05.2v.7Q.05 6 1.1 8.5l.45 1.1 1.55 2.35q.95 1.2 2.1 2.25.7.65 1.4 1.1.7.55 1.5 1 .55.4 1.25.65 7.8 3.1 15.9.45l.05-.05h.05l1.1-.4q.6-.25 1.2-.65.9-.5 1.75-1.15.5-.4 1.1-.95 1.2-1.05 2.1-2.2l1.6-2.4z"
                    id="ObjFoo035__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M35.65 2.15Q35.4 1.3 30.4.7 25.2 0 17.8 0 10.45 0 5.2.7.45 1.3.05 2.1 0 2.15 0 2.2q0 .1.05.2.05.05.2.15.9.7 4.95 1.2 5.25.65 12.6.65 7.4 0 12.6-.65 2.05-.2 3.25-.5 1.35-.3 1.8-.7.2-.15.2-.35v-.05z"
                    id="ObjFoo035__Symbol_45_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjFoo035__Layer16_0_FILL" />
            <use xlinkHref="#ObjFoo035__Layer16_1_FILL" />
            <use xlinkHref="#ObjFoo035__Layer16_2_FILL" />
            <use
                xlinkHref="#ObjFoo035__Symbol_66_0_Layer0_0_FILL"
                transform="translate(102.3 261.5)"
                id="ObjFoo035__col1n"
            />
            <use
                xlinkHref="#ObjFoo035__Symbol_45_0_Layer0_0_FILL"
                transform="translate(102.3 261.5)"
                id="ObjFoo035__col1d"
            />
        </g></g>
    )
}

export default ObjFoo035
