import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor081({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor081__Layer10_0_FILL">
                    <path
                        fill="#FFF"
                        d="M219.6 274.1q8.182 1.364 16 0 .59-1.216.75-2.6-8.942-2.016-17.55 0 .21 1.384.8 2.6m16.75-5.15q-.167-1.356-.75-2.55-8.097-1.07-16 0-.58 1.194-.8 2.55 8.8 2.287 17.55 0z"
                    />
                    <path d="M227.6 279.1q3.507 0 6.05-2.4-6.247-1.476-12.15 0 2.641 2.4 6.1 2.4m6.1-15.25q-2.557-2.45-6.1-2.45-3.495 0-6.15 2.45 6.22.957 12.25 0z" />
                </g>
                <g id="AccMor081__Layer10_1_FILL">
                    <path
                        fill="#C2C2C2"
                        d="M219.6 274.1q.616 1.295 1.7 2.4.106.102.2.2h12.15l.2-.2q1.105-1.105 1.75-2.4h-16m16-7.7q-.645-1.295-1.75-2.4l-.15-.15h-12.25q-.07.074-.15.15-1.084 1.105-1.7 2.4h16z"
                    />
                    <path
                        fill="#ADD366"
                        d="M236.35 271.5q.1-.609.1-1.25 0-.668-.1-1.3H218.8q-.036.514-.05 1.05v.5q.014.505.05 1h17.55z"
                    />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor081__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor081__Layer10_0_FILL" />
            <use xlinkHref="#AccMor081__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor081__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor081__col1l"
            />
        </g></g>
    )
}

export default AccMor081
