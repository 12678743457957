import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#2E0E00",
    "col1l": "#9A3200",
    "col1n": "#621F00"
}

function ObjMor081({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.8 9.9l-.4-.3q-1.1-.85-2.3-1.35-.1 0-.25-.1-1.05-.35-2.15-.4-.55-.1-1.05-.1-1.15 0-2.15.25-.05-.3-.05-.45-.15-.45-1.1-2.6-.25-.8-1.8-2.35-1.75-1.65-3.6-2-1.4-.45-3.1-.5h-2.4q-1.3.2-1.95.35-.95.15-1.05.7-.25.75 1.05 2.3 1 1.25 1 2.45 0 1.85-2.8 4.4-.8.7-1.8 1.4l-.15.15q-.35.2-.6.4-1.35 1.1-2.2 2.45-.65.7-.9 1.5-.2.2-.3.55l-.3.9q-.25-.1-.75-.1-1.1 0-2.05.4-.75.2-1.4.6-.95.6-1.75 1.55-.35.4-.6.8-1.75 2.35-1.75 5.35 0 .35.1.6H6.1q-.75 0-1.35.2-.7.15-1.25.6-.75.45-1.3 1.25-.65.8-1.2 1.9l-.2.7Q0 33.45 0 35.3q0 2.65 1.7 5.1.05.15.2.3 1.1 1.55 2.85 2.95.6.6 1.5 1.25 3.85 2.8 7.45 4.2.35.15 4.15 1.4.2.05.5.1 12.7 2.95 24.05 0 2.75-.8 6.05-2.15.3-.05.55-.2.9-.4 1.7-.75h.05q4.35-2.2 6.7-4.75.25-.2.55-.4v-.05q.9-.9 1.6-1.9 1.25-2.05 1.25-4.55t-1.35-4.6q-.65-.95-1.5-1.75l-.2-.2q-.7-.6-1.35-.95.75-1.6.75-3.45 0-2.65-1.5-4.75-.55-.9-1.4-1.7-.45-.4-.95-.8-2.5-1.8-5.8-1.8h-.15v-.55q0-3.15-2.6-5.4z"
                    id="ObjMor081__Symbol_170_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M38.8 30.2q-.65-.95-1.5-1.75l-.2-.2q2.2 3.5-.1 9.8-2.2 4.85-6.95 8.4 4.35-2.2 6.7-4.75.25-.2.55-.4v-.05q.9-.9 1.6-1.9 1.25-2.05 1.25-4.55t-1.35-4.6M.75 0Q.5.75 1.8 2.3q1 1.25 1 2.45 0 1.85-2.8 4.4.2-.1.45-.3Q2.35 8.1 3 7.7 5.4 6.15 5.4 4 5.4 2.2 3 .9 2.05.5.75 0M21.4 7.2q3.75 1.9 2.6 7.05t-7.95 8.05q8.05-.85 10.65-7.5v-.55q0-3.15-2.6-5.4l-.4-.3q-1.1-.85-2.3-1.35m15.1 16.65q0-2.65-1.5-4.75-.55-.9-1.4-1.7-.45-.4-.95-.8 3.35 3.2 1.1 8.5-2.3 5.25-9 7.85 4.95-.25 11-5.65.75-1.6.75-3.45z"
                    id="ObjMor081__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6.25 19.1H6.1q6.2 9.2 17 8.05-9.25-2.35-12.85-7.45l-.05-.2q-.1-.1-.15-.2l-.6-1q-2-3.95.8-7.5-.95.6-1.75 1.55-.35.4-.6.8-1.75 2.35-1.75 5.35 0 .35.1.6M14.75 9l-.3.9q3.35 6.95 13.6 6.75-7.45-2.15-9.45-6.45-.65-1.4-.75-3 .3-2.25 2.85-4.65-.8.7-1.8 1.4l-.15.15q-.35.2-.6.4-1.35 1.1-2.2 2.45-.65.7-.9 1.5-.2.2-.3.55m22-3.4q3.1-1.9 2.95-5.5-.55-.1-1.05-.1-1.15 0-2.15.25-.2 4.6-4.1 6.55 2.35-.25 4.35-1.2M49 40.6q-.75.15-1.45.25-19 5-33.85-3.2-.9-.45-1.7-1-1.9-1.15-3.5-2.55-3.75-2.5-5.3-6.1-.1-.2-.1-.35-1.85-5.7 1.65-8.35-.7.15-1.25.6-.75.45-1.3 1.25-.65.8-1.2 1.9l-.2.7Q0 25.8 0 27.65q0 2.65 1.7 5.1.05.15.2.3Q3 34.6 4.75 36q.6.6 1.5 1.25 3.85 2.8 7.45 4.2.35.15 4.15 1.4.2.05.5.1 12.7 2.95 24.05 0 2.75-.8 6.05-2.15.3-.05.55-.2z"
                    id="ObjMor081__Symbol_95_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor081__Symbol_170_0_Layer0_0_FILL"
                transform="translate(26.75 301.8)"
                id="ObjMor081__col1n"
            />
            <use
                xlinkHref="#ObjMor081__Symbol_112_0_Layer0_0_FILL"
                transform="translate(47.45 302.85)"
                id="ObjMor081__col1l"
            />
            <use
                xlinkHref="#ObjMor081__Symbol_95_0_Layer0_0_FILL"
                transform="translate(26.75 309.45)"
                id="ObjMor081__col1d"
            />
        </g></g>
    )
}

export default ObjMor081
