import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaSid003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M5.7 13.8L4.3 0Q-5.8 37.55 7.7 65.85q.4.8.9 1.6Q26.25 79.8 35.2 85.5q-4.6-9.6-3.85-19.75-1.05 1-1.7 2.6v-.75q0-.6 1.5-7-1.8 1.65-2.5 2.4-.1.1-.15.2.05-.35.15-.75 1.35-5.55 1.5-6.35-3.7.85-5.5 5.75v-1q0-1.4 1.65-6.6 1.8-5.75 1.85-6.15-.9-.05-3.1 2.45-2.3 2.5-2.65 4.05v-.75q0-4.15 1.65-9.7 1.6-4.85 1.6-5.55v-.75q-8.2 8.2-8.5 8.75v-1q0-3 2.6-11.4.4-1.4.7-2.35-1.95.1-4.9 3.75-3 3.65-3.65 6.25v-.25q.65-5.25 1.5-9v-2.25h-.25q-1.65 1.1-3.65 5.1-.85 1.7-1.3 3.1-.05-.1-.05-.2-2-5.8 0-12 1.35-2.75 2.5-12-1.2 2.5-3.8 6.8l-.1-.2q-.5-1.3-.9-5.7-.1-.7-.15-1.45m178.2 2.8q.8 3.8.95 5.7.05.85.05 4.3 0 2.45-.3 4.25-.3 1.65-.35 4.8-.95-4.45-2.2-10.15-.9-4-1.85-8.65.1 4.15.2 8.4.05 9.25-.1 18.65-1.55-5.35-3.85-12.3 0 .25-.25.25.4 2.35-3.75 17.75.7-12.4-3.25-21.5.7 4.05-.1 14-.8 9.9-1.65 11.75-.5-5-.8-7.4-.5-4.1-1.7-7.1.15 4.8-1.75 19-.2-4.7-.45-7.65-.45-5.4-1.55-7.35v1q0 16.9-1 21-1.05-3.35-1-5-1.45 7.2-2.3 10.35-.55-1.3-1.75-3.45.15 10.4-2.1 18.95 8.85-5.1 31.1-19.5l2.4-6.15q10.6-30.15 2-59.35-1.05 14.85-1.9 18.65-.2-.35-.45-.65-2.15-2.5-2.3-2.75v.15m-27.6 56.05l-.1.2v-.25q.05 0 .1.05z"
                    id="BeaSid003__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaSid003__Symbol_4_0_Layer0_0_FILL"
                transform="translate(96.2 126)"
                id="BeaSid003__col1n"
            />
        </g></g>
    )
}

export default BeaSid003
