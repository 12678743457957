import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FFFFFF",
    "col2s": "#CCCCCC"
}

function HaiSpe042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.05 28.95q.6-.1 1.2-.25l-2 .4q.4-.05.8-.15m60.3-.25q.6.15 1.2.25.4.1.8.15l-2-.4m-53.95-.8l.4-.05h-.1q-.15 0-.3.05m48.5-.05h-.1l.4.05q-.15-.05-.3-.05m65.8 57.1q-5.45-18.8-15.6-31.35-2.3-3.05-5.2-5.75-17.9-15.45-45.1-20l.35-1.45.05-.35-.3 1.3 1.25-7.25q.45-2.4.8-4.75 1.3-7.65-4.35-11.5-1.1-.7-2.5-1.25-1.7-.6-3.5-1-17-3.2-31.6 0-1.85.4-3.5 1-1.4.55-2.5 1.25-5.65 3.85-4.35 11.5.35 2.35.8 4.75l1.35 7.75q-27.2 4.55-45.1 20-2.9 2.7-5.2 5.75Q11.35 66.15 5.9 84.95q-.2.6-.35 1.2l-.1.25Q-.15 108.5 0 122.1h.1l8.85 9.15q7.4-33.35 27.6-61.4Q55.1 46.6 74.8 50.55q4.75 1 9.65 3.6.1 0 .1.1 6.1 3.05 10.45 8.6.4.4.8.95.4-.55.8-.95 4.35-5.55 10.45-8.6 0-.1.1-.1 4.9-2.6 9.65-3.6 19.7-3.95 38.25 19.3 20.2 28.05 27.6 61.4l8.85-9.15h.1q.15-13.6-5.45-35.7l-.1-.25q-.15-.6-.35-1.2z"
                    id="HaiSpe042__Symbol_174_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M71.4 27.9l.4-.05h-.1q-.15 0-.3.05m114.3 57.05q-5.45-18.8-15.6-31.35-2.3-3.05-5.2-5.75-17.9-15.45-45.1-20l.35-1.45v-.15l-.25 1.1 1.25-7.25q.45-2.4.8-4.75 1.3-7.65-4.35-11.5-1.1-.7-2.5-1.25-1.7-.6-3.5-1-17-3.2-31.6 0-1.85.4-3.5 1-1.4.55-2.5 1.25-5.65 3.85-4.35 11.5.35 2.35.8 4.75l1.25 7.25-.1-.45.2.95q-27.2 4.55-45.1 20-2.9 2.7-5.2 5.75Q11.35 66.15 5.9 84.95q-.2.6-.35 1.2l-.1.25Q-.15 108.5 0 122.1h.1q0-.95.1-1.85 1.65-27.3 14.1-48.75 11.5-23.25 41.2-35.1 8.45-3 16.85-4.7.15-.05.25-.05 3.85-.8 7.7-1.35 7.75-1.1 15.5-1.1t15.5 1.1q3.85.55 7.7 1.35.1 0 .25.05 8.4 1.7 16.85 4.7 29.7 11.85 41.2 35.1 12.45 21.45 14.1 48.75.1.9.1 1.85h.1q.15-13.6-5.45-35.7l-.1-.25q-.15-.6-.35-1.2m-73.4-77.9q1.45 3.2 1.45 6.9 0 1.2-.1 2.45l-1.75 10.25q-2.45-.3-4.85-.5-5.3-.45-10.45-.55H95q-5.15.1-10.45.55-2.4.2-4.85.5L77.95 16.4q-.1-1.25-.1-2.45 0-3.7 1.45-6.9.05-.15.15-.3 1-1.9 4.25-2.95 12.1-1.95 24.2 0 3.25 1.05 4.25 2.95.1.15.15.3z"
                    id="HaiSpe042__Symbol_106_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M49.45 9.2l.45-4.8q-5.85-1.75-9.8-2.65-12.45-2.9-26-.95-1.15.2-2.25.5-.65.1-1.2.2-1.45.25-2.85.6-3.95.8-7.8 2.1l.65 4.9v.1Q7.3 8 13.8 7.45 19.1 7 24.25 6.9q3.45 0 6.95.1 1.1 0 2.2.1 1.55 0 2.9.15 2.9.2 5.6.55 2.7.35 7.55 1.4z"
                    id="HaiSpe042__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M31.25 7L3.45 0Q2.3.2 1.2.5.55.6 0 .7l22.75 5.6q1.55 0 2.9.15 2.9.2 5.6.55z"
                    id="HaiSpe042__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe042__Symbol_174_0_Layer0_0_FILL"
                transform="translate(96.7 29)"
                id="HaiSpe042__col1n"
            />
            <use
                xlinkHref="#HaiSpe042__Symbol_106_0_Layer0_0_FILL"
                transform="translate(96.7 29)"
                id="HaiSpe042__col1d"
            />
            <use
                xlinkHref="#HaiSpe042__Symbol_32_0_Layer0_0_FILL"
                transform="translate(167.45 47.7)"
                id="HaiSpe042__col2n"
            />
            <use
                xlinkHref="#HaiSpe042__Symbol_10_0_Layer0_0_FILL"
                transform="translate(178.1 48.5)"
                id="HaiSpe042__col2s"
            />
        </g></g>
    )
}

export default HaiSpe042
