import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#005100",
    "col1n": "#008300",
    "col2d": "#AC8268",
    "col2n": "#C0A594"
}

function ObjFoo055({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#E5F0FA"
                    d="M263.55 266.2q3 .8 5.8 0 1.25-.45 2.25-1 .1-.1.15-.1l.35-.35q-5.4 2.05-11.2 0-.05.15.75.7.95.5 1.9.75z"
                    id="ObjFoo055__Layer9_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M21.95 3.65q0-.2-.05-.35-.3-1.25-3.15-2.2Q15.5 0 10.95 0 6.45 0 3.2 1.1.35 2.05.05 3.3 0 3.45 0 3.65q0 .1.05.25v14.3q-.05.2-.05.45 0 2 3.2 3.45 3.25 1.35 7.75 1.35 4.55 0 7.8-1.35 3.2-1.45 3.2-3.45 0-.25-.05-.45V3.95l.05-.05v-.25z"
                    id="ObjFoo055__Symbol_114_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M21.9 3.95l.05-.05v-.25q0-.2-.05-.35-.3-1.25-3.15-2.2Q15.5 0 10.95 0 6.45 0 3.2 1.1.35 2.05.05 3.3 0 3.45 0 3.65q0 .1.05.25v.05q.3 1.35 3.15 2.2 3.25 1.1 7.75 1.1 4.55 0 7.8-1.1 2.85-.85 3.15-2.2z"
                    id="ObjFoo055__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.2 1.8q.05-.15.05-.25 0-.6-1.65-1.1Q7.95 0 5.65 0q-2.35 0-4 .45Q0 .95 0 1.55q0 .15.1.3l1.5 6.2q0 .45 1.2.75 1.25.35 2.85.35 1.7 0 2.85-.35 1.2-.3 1.2-.75l1.5-6.25z"
                    id="ObjFoo055__Symbol_116_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.2 1.8q.05-.15.05-.25 0-.6-1.65-1.1Q7.95 0 5.65 0q-2.35 0-4 .45Q0 .95 0 1.55q0 .15.1.3.3.45 1.55.8 1.65.45 4 .45 2.3 0 3.95-.45 1.4-.35 1.6-.85z"
                    id="ObjFoo055__Symbol_117_0_Layer0_0_FILL"
                />
                <g id="ObjFoo055__Layer9_0_FILL" fill="#C1D2E2">
                    <path
                        fillOpacity={0.702}
                        d="M272.6 271.2q-.45-1.95-.4-4-.25.1-.55.2-.25 0-.45.2-1.9.65-4.75.65-2.75 0-4.75-.65-.149-.05-.25-.2-.25-.05-.45-.15 0 2.05-.45 3.95-.5 1.4-1.8 2.55-2.4 2.15-3.1 6.2v1.6h21.85v-1.6q-.75-4.05-3.15-6.2-1.25-1.15-1.75-2.55z"
                    />
                    <path d="M273.2 266v-2.6q0-.95-2-1.65-1.9-.65-4.75-.65-2.75 0-4.75.65-1.9.7-2 1.65v2.6q0 .5.65.9.2.2.65.35.2.1.45.15.101.15.25.2 2 .65 4.75.65 2.85 0 4.75-.65.2-.2.45-.2.3-.1.55-.2.8-.45 1-1v-.2z" />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo055__Layer9_0_FILL" />
            <use xlinkHref="#ObjFoo055__Layer9_1_FILL" />
            <use
                xlinkHref="#ObjFoo055__Symbol_114_0_Layer0_0_FILL"
                transform="translate(255.6 278.25)"
                id="ObjFoo055__col1n"
            />
            <use
                xlinkHref="#ObjFoo055__Symbol_115_0_Layer0_0_FILL"
                transform="translate(255.6 278.25)"
                id="ObjFoo055__col1d"
            />
            <use
                xlinkHref="#ObjFoo055__Symbol_116_0_Layer0_0_FILL"
                transform="translate(260.9 255.45)"
                id="ObjFoo055__col2n"
            />
            <use
                xlinkHref="#ObjFoo055__Symbol_117_0_Layer0_0_FILL"
                transform="translate(260.9 255.45)"
                id="ObjFoo055__col2d"
            />
        </g></g>
    )
}

export default ObjFoo055
