import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiSpe064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M193.8 161.5v-.05q-.45.65-1 1.4-12.15 15.2-27.55 24.9l.8 4.3.5 1.5h.05l1.45 4.15q8-3.7 13.2-10.55-3.4 8.5-11.3 16.5-.05.1-.15.25l.25.5q1.1 3.65 2.6 7.05l.05.05h-.05q.3.6.7 1.25h.05q1.15 2.3 2.7 4.55l.05.05h-.05q.25.4.6.8.25.4.5.75h.05q1.65 2.1 3.55 4.05l.05.05h-.05q.2.4.6.65.3.4.55.75h.05q2.45 2.1 5.15 4.2l.05.05h-.05q.3.3.7.55.3.2.75.55h.05q2.35 1.7 5.15 3.3 14.3-19.3 8.8-37-.15-.2-.2-.4l-1-2.1q-.15-.35-.3-.6v-.1q-.5-.85-.8-1.75-.2-.45-.35-.8l-1.7-4.3q-.15-.35-.2-.6-.45-1.1-.7-2.15-.15-.5-.3-.85-.1-.3-.15-.55-.05-.2-.05-.3-.6-1.9-1-3.8-.25-1.2-.4-2.3v-.25q-.1-.35-.2-.6-.25-1.15-.4-2.2-.1-.35-.15-.7l-.15-1.15v-.05q-.3-1.75-.45-3.6-.05-.95-.15-1.95v-.25q-.05-.65-.05-1.35v-.05q-.05-.95-.1-1.8m-8.6-66.8q.3 0 .6.05h.15l1.85.05q.3.05.6.05h.55q13.55-.15 24.25-4.25-1.25-5.3-2.8-10.2-.85-2.75-1.85-5.35-4.05-10.85-9.9-19.95Q164.8 2.25 89.7 0q-7.5.3-14.35 1.8-4.15.9-8.05 2.25-10.1 3.4-18.9 9.65-14.95 10.85-27.65 32.45Q11.55 61.8 5.65 80 .6 95.35.6 103.05q0 .25-.6 9.45.45-.85 2.9-3.55 2.4-2.7 6.95-6.95 7.65-5.9 20.75-14.15 7.15-32.9 20.2-48.3-.75 1.3-1.4 2.8-6.3 14.1-5.9 40 .4-.15.75-.3 10.7-3.9 18.9-5.7.1-.05.25-.05.15-.1.3-.1.3-.05.6-.15h.1v.1h.15l2-.4q.9-.2.95-.35 1.2-.25 2.4-.45 1.75-.3 3.7-.6 2.75-.45 5.5-.65.35-.1.65-.1.55-.05 1.05-.05.45-.1.9-.1v-.05q.05-.05.15-.05v.05q.05-.05.15-.05h.05q.05 0 .15.05h1.15q.6-.05.65-.1 5.45-.35 11.05-.3 1.35 0 2.75.1h.9q-.1-.15-.1-.3h.15q0 .15-.05.3h.15l.45.05h.6q.95 0 2 .1 1.75.15 3.6.3 2 .15 4.05.45 1.1.15 2.3.25 2.2.3 4.45.6 2.9.55 5.85 1.15.5.15 1.05.2.5.1 1.05.25.5.05 1 .2l1.05.2q3.55.75 7.2 1.8.1.1.25.1.2.05.45.15 3.4.65 21 7.7-3.05-1.1.65.45.4.15.75.3.35.2.75.35.05.1.3.15.35.15.65.3 13.9 5.8 26.9 6.55z"
                    id="HaiSpe064__Symbol_196_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M34.35.2l-2.5-.2Q5.25 33.95 0 74.55v.55q0 .15.1.2v.1l2-.4v-.15Q7.35 34.15 34.35.2m16.85 2q-1.15-.2-2.3-.35-27.25 34-31.5 70.7v.1q.05-.05.15-.05h.05q.1 0 .3.05h1q.6-.05.65-.1 4.3-36.4 31.6-70.3l.05-.05m13.25 8.55q-.2.05-.3.2Q53.25 23.6 45.7 38.2q-.7 1.1-1.2 2.4-7.2 14.65-11 31.2-.1.25 0 .55h.75q-.1-.15-.1-.3h.3q0 .15-.05.3l.45.05q.3-.05.6 0 0-.05.05-.15 3.7-16.3 10.9-30.85.55-1.2 1.15-2.25 7.4-14.3 18-26.65.15-.15.4-.35.05-.1.15-.15.05-.15.15-.2 2.3-2.4 4.6-4.35.2-.2.35-.45-1.05-.35-2.15-.65-2.35 1.9-4.3 4v-.05q-.15.15-.3.45M85.3 12.8l.2-.2q0-.05.05-.1-.95-.45-1.85-.85-9.45 8.9-15.15 19.3-6.6 12-9.2 26.55-1.3 7.4-1.5 15.6v2.15q.5.15 1.05.2.5.1 1.05.25v-.25V73.1q.2-7.95 1.45-15.25 2.55-14.15 9-25.9 5.8-10.6 14.9-19.15m9.7 4.5q-.9-.5-1.75-.95-19.8 32.55-1.7 69.95.4.15.75.3.35.2.75.35.05.1.3.15.35.15.65.3 0-.05.1-.05.05-.45-.1-.85-18.35-36.85 1-69.2m12.75 8.5q-.9-.7-1.75-1.3Q95.9 61.85 121.5 94l1.85.05q.35 0 .75.05-25.3-29.75-16.35-68.3z"
                    id="HaiSpe064__Symbol_127_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe064__Symbol_196_0_Layer0_0_FILL"
                transform="translate(75.45 48.35)"
                id="HaiSpe064__col1n"
            />
            <use
                xlinkHref="#HaiSpe064__Symbol_127_0_Layer0_0_FILL"
                transform="translate(139.9 49.1)"
                id="HaiSpe064__col1d"
            />
        </g></g>
    )
}

export default HaiSpe064
