import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#BFBFBF",
    "col1n": "#D9D9D9",
    "col1s": "#999999",
    "col2l": "#333333",
    "col2n": "#000000"
}

function ObjToy021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.05 33.05L62.3 2.4 58.1 0 34.9 38.15q.4 2.1.25 4.5-.15 2.1-.65 4-.1.3-.2.7-.8 2.35-2.3 4.35-.3.4-.6.75-3.2 3.65-7.3 3.7L0 96.3q.05 5.25 5.4 2.3l4.3-8.5L37 44.9q-.45-.65-.65-1.4-1.05-3.1.45-6.25.5-.95 1.35-1.8 1.5-1.55 3.45-2 .2-.05.35-.1.55-.1 1.25-.15h.4q.2-.05.35 0H44q0-.1.05-.15z"
                    id="ObjToy021__Symbol_165_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.9 0L0 9.9l4.8 2.2 5.95-9.7L5.9 0z"
                    id="ObjToy021__Symbol_166_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.75 7.5L8 2.15 3.25 0 0 5.4l4.75 2.1z"
                    id="ObjToy021__Symbol_167_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M45.1 16.9q1.25-1.3 2.4-2.8.95-1.25 1.85-2.7l4.6-9.1-5.4-2.3-.5.8q-1.15 1.95-2.6 3.35-.8.8-1.65 1.4l-.3.2Q41.6 7 39.4 7.55q-.35.1-.75.15-14.25 3.35-28.45 3.7-7.85.1-9.7 4.2-1.15 3.85.3 5.75 3.65 4.75 12.25 4.35 8.55-.4 17.8-1.95 9.2-1.55 14.25-6.85z"
                    id="ObjToy021__Symbol_163_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.3 1.4l-.3.2 4 8.35q.95-1.25 1.85-2.7l2.3-4.5L1.95 0Q1.15.8.3 1.4z"
                    id="ObjToy021__Symbol_164_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy021__Symbol_165_0_Layer0_0_FILL"
                transform="translate(88.3 250.7)"
                id="ObjToy021__col1n"
            />
            <use
                xlinkHref="#ObjToy021__Symbol_166_0_Layer0_0_FILL"
                transform="translate(100.65 316.4)"
                id="ObjToy021__col1d"
            />
            <use
                xlinkHref="#ObjToy021__Symbol_167_0_Layer0_0_FILL"
                transform="translate(93.25 333.3)"
                id="ObjToy021__col1s"
            />
            <use
                xlinkHref="#ObjToy021__Symbol_163_0_Layer0_0_FILL"
                transform="translate(39.75 347)"
                id="ObjToy021__col2n"
            />
            <use
                xlinkHref="#ObjToy021__Symbol_164_0_Layer0_0_FILL"
                transform="translate(83.25 351.15)"
                id="ObjToy021__col2l"
            />
        </g></g>
    )
}

export default ObjToy021
