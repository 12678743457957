import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D24400",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function AccHat080({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.502}
                    d="M176.1 26.8q-.05-.05-.05-.1Q92.9-26.65 9.8 26.7l-.1.1q0 .05-.05.1-4.2 8.9-6.55 18.4-.1.45-.25 1l-.95 4.65q-.1.35-.15.75L0 71.15l.5 11q0 .25.05.55.05.5.1 1.05v.05l8.65 35.9q.2.6.4 1.15h.05q.05 0 .05.1.55.35 1.05.75 81 64.6 164.15 0 .5-.4 1-.75l.05-.1q.75-.95 1.4-1.95.1-.2.15-.45l.15-.45 6.3-29.4v-.05q.05-.55.1-1l.05-.25q0-.4.05-.8.6-6.6.6-13.15L183.3 52.5l-.1-.9-.35-1.65q-.05-.35-.1-.6-1.85-10.4-5.3-20.65-.65-.95-1.35-1.9z"
                    id="AccHat080__Symbol_297_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M200.6 72.95q-6.95-24-25.95-43-29-28.95-69.6-29.9h-.8q-.85-.05-1.7-.05h-.25q-1.05 0-2.05.05h-1.1Q70.1.8 47 16q-9 5.9-17.05 13.95Q0 59.9 0 102.25q0 35.45 21 62.25.15.15.25.3 4 5.05 8.7 9.75 2.45 2.45 4.95 4.7 1.07.94 2.15 1.85.512.428 1 .85l.8.65.8.65q-.564.205-1.15.45-.54.232-1.1.5-3.476.132-2.7 3.4.114.446.3.95.84.582 1.65 1.15 5.452 3.663 10.9 6.75 1.398.794 2.8 1.55 1.631.877 3.25 1.7 3.832 1.921 7.65 3.55 5.128 2.188 10.25 3.85 1.489.471 2.95.9 28.5 9.75 57.05 0 9.528-2.755 18.9-7.15 2.887-1.348 5.75-2.85 1.986-1.038 3.95-2.15 3.45-1.939 6.85-4.1 4.7-2.1 3.75-4.4-.9-1.6-2.95-2.4-2.114-.65-3-1.6 2.355-1.819 4.6-3.8 2.7-2.4 5.3-5 5.7-5.7 10.3-11.8 2.5-3.3 4.65-6.75 15-23.75 15-53.75 0-15.5-4-29.3m-10.45 15.1q.05.25.1.55l.3 1.6q.05.4.1.85l1.55 19.8q0 6.2-.6 12.45-.05.4-.05.8l-.05.2q-.05.45-.1.95v.05l-6.15 27.9-.15.45q-.05.25-.15.5-.65.95-1.35 1.85-.047.047-.1.15.05-.103.05-.15-.5.35-1 .75-81.25 61.3-160.45 0-.5-.4-1-.75v.1q-.002-.052-.05-.1H21q-.2-.55-.4-1.15l-8.45-34.1v-.05l-.1-1q-.05-.25-.05-.5l-.5-10.45 1.7-18.45q.05-.4.15-.75l.95-4.4q.15-.5.25-.95 2.3-9 6.4-17.45.05-.05.05-.1l.1-.1q81.2-50.65 162.5 0 0 .05.05.1l1.3 1.8q3.35 9.75 5.2 19.6z"
                    id="AccHat080__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.8}
                    d="M200.05 71.95q.25.5.55 1-.1-.4-.2-.75-7.05-23.55-25.75-42.25-29-28.95-69.6-29.9h-.8q-.85-.05-1.7-.05h-.25q-1.05 0-2.05.05h-1.1Q70.1.8 47 16q-9 5.9-17.05 13.95Q0 59.9 0 102.25q0 35.45 21 62.25.15.15.25.3 4 5.05 8.7 9.75 2.45 2.45 4.95 4.7 2.35 2.05 4.75 4-1.1.4-2.25.95-3.95.15-2.4 4.35 7.65 5.3 15.35 9.45 12 6.45 24.1 10 28.5 9.75 57.05 0 12.45-3.6 24.65-10 5.45-2.85 10.8-6.25 4.7-2.1 3.75-4.4-.9-1.6-2.85-2.9.55.4-.35.25-12 8.15-23.9 13.3-18.8 8.15-37.45 8.9.05-1.05-.1-2.45v-3.9q-17.7-.2-27.2-2.55-.55-.1-1-.25-12.45-3.35-17.15-11.5-12.05-9.05-23.05-15.95-11-6.95-16.55-14.2 0-.05-.05-.1H21q-.1-.1-.15-.2l-.3-.4-3.25-4.85q-7.6-44.9 5.1-76.05 1.4-3.5 3.05-6.75.85-3.4 1.85-8.45 1.6-10.1 9.3-18.5l9.05-7.75Q65.6 19.6 103.25 18.1h1.8V3.35q10.7.35 24.9 4.2 35.7 11.6 62.75 52.5 3.75 5.65 7.35 11.9z"
                    id="AccHat080__Symbol_35_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M127.3.4l-1.05.9q-11.5 8.75-24.55 13.75-14 5.35-29.75 6.3-.35 0-.7.05l-2.6.1q-1.85.05-3.75.05-19.75 0-36.75-6.5-.95-.35-1.9-.75Q14.55 9.55 4.2 1.8L2 0Q1 1.05 0 1.25q12 8.5 24.1 13.8 21.55 9.5 43.25 8.95 19.35-.5 38.85-8.95 11.9-5.15 23.9-13.3-1.65-.5-2.8-1.35z"
                    id="AccHat080__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    d="M288.7 155.2q0-24.667-14.5-44.15v88.3q14.5-19.483 14.5-44.15m-100-82.6q-5.614.163-11 .8-20.95 2.593-38.3 12.7-.1.05-.15.1-1.902 1.097-3.8 2.25l-10.7 8.05q-.176.149-.35.3l-3.55 3.2q-1.75 1.625-3.35 3.3-3.1 3.19-5.7 6.6v-.15q-.228.296-.45.6Q96.3 130.1 96.3 155.2q0 24.725 14.6 44.25.198.284.4.55v.1l.5.65q1.753 2.238 3.7 4.4 16.855 20.276 48.65 29.5l.85.15q28.335 7.191 55.25 0 16.894-4.237 31.15-13.9.336-.226.65-.45l6.55-4.95q1.015-.798 2-1.65 7.55-6.5 13.1-13.7.05-.103.05-.15-.05.035-.1.05-.45.34-.9.7-47.9 36.138-95.1 29.65-30.127-4.114-60-25.65-2.66-1.918-5.35-4-.194-.155-.4-.3-.038-.046-.1-.1v-90.1q2.014-1.26 4-2.45 78.963-46.49 158 2.75-5.6-7.25-13.2-13.75-26.9-23.1-64.3-24.2h-.05q-1.85-.05-3.75-.05t-3.8.05z"
                    id="AccHat080__Layer6_0_FILL"
                />
                <path
                    fill="url(#AccHat080__Gradient_1)"
                    d="M274.6 112.25q-.04-.23-.1-.45-.167-.159.05-.15.263.046-.45-.65-.672-.635-1.5-1.55-.816-.875-1.1-1.3-80.1-49.263-160.1 2.4V200q.45.9.9 1.25 79.2 62.3 160.45 0 .434-.347 1.85-2.35v-86.65z"
                    id="AccHat080__Layer6_1_FILL"
                />
                <linearGradient
                    id="AccHat080__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={271.4}
                    y1={158.15}
                    x2={116.6}
                    y2={158.15}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="50.98%" stopOpacity={0} />
                    <stop offset="100%" />
                </linearGradient>
            </defs>
            <use
                xlinkHref="#AccHat080__Symbol_297_0_Layer0_0_FILL"
                transform="translate(100.15 81.2)"
                id="AccHat080__col1n"
            />
            <use
                xlinkHref="#AccHat080__Symbol_101_0_Layer0_0_FILL"
                transform="translate(90.2 44)"
                id="AccHat080__col2n"
            />
            <use
                xlinkHref="#AccHat080__Symbol_35_0_Layer0_0_FILL"
                transform="translate(90.2 44)"
                id="AccHat080__col2d"
            />
            <use
                xlinkHref="#AccHat080__Symbol_6_0_Layer0_0_FILL"
                transform="translate(127.6 226.95)"
                id="AccHat080__col2s"
            />
            <use xlinkHref="#AccHat080__Layer6_0_FILL" />
            <use xlinkHref="#AccHat080__Layer6_1_FILL" />
        </g></g>
    )
}

export default AccHat080
