import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#F96106"
}

function SkiMor019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M3.6 10.2q-.15.6.3 1.1 1 .6 1.75-.15.2-.35.25-.75 0-.2-.05-.4 0-.15-.05-.35l-.3-.3q-.35-.2-.7-.3-.3.05-.6.15l-.4.2-.2.6v.2M2.3 1.1Q2.25.75 2.1.35q-.15-.1-.4-.2T1.1 0Q.75.1.4.3L.1.6Q0 1 0 1.35q.05.4.25.75.75.75 1.75.15.45-.5.3-1.15m14.1.8q-.35.05-.6.15-.25.1-.4.2-.15.4-.2.75-.15.65.3 1.15 1 .6 1.75-.15.2-.35.25-.75 0-.35-.1-.75l-.3-.3q-.35-.2-.7-.3m55.3.15q-.25-.1-.6-.15-.35.1-.7.3l-.3.3q-.1.4-.1.75.05.4.25.75.75.75 1.75.15.45-.5.3-1.15-.05-.35-.2-.75-.15-.1-.4-.2M83.3 9.2q-.3-.1-.6-.15-.35.1-.7.3l-.3.3q-.05.2-.05.35-.05.2-.05.4.05.4.25.75.75.75 1.75.15.45-.5.3-1.1V10l-.2-.6-.4-.2m4.2-7.85q0-.35-.1-.75l-.3-.3q-.35-.2-.7-.3-.35.05-.6.15-.25.1-.4.2-.15.4-.2.75-.15.65.3 1.15 1 .6 1.75-.15.2-.35.25-.75z"
                    id="SkiMor019__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor019__Symbol_17_0_Layer0_0_FILL"
                transform="translate(148.75 199.15)"
                id="SkiMor019__col1n"
            />
        </g></g>
    )
}

export default SkiMor019
