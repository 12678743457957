import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M62.75 1.1q-4.85-2.15-9.7 0-3.7 1.75-7.6 6.6-5.6 6.95-12 7.75Q24.2 16.6 20.8 19q-2.7 1.95-3.85 5.75-.1 1.35-.2 3.2-.1 1.25-.35 2.3-.35 1.6-1.2 2.65-.85 2.1-2.45 3.85-3.4 3.7-3.4 6.9 0 2.35 1.25 5.7t1.25 4.35q0 1.7-1.65 3.65-1.6 1.95-1.6 3.75 0 1.95 1.25 5.3 1.25 3.4 1.25 4.85 0 2.45-1.5 4.4-1.5 1.95-1.5 3.05 0 1.75.75 3.2.5.95 2.1 2.95 2.4 2.9 1.8 5.6-.15 2.25-2.15 4.25-.45.5-4.55 3.65-3.15 2.65-4.6 6.4-2.85 6.15 0 12.35.8 1.1 3.55 3.4 4.65 3.5.55 9.3-2.7 5.5.4 10.95.85 1.4 2.15 2.2 4.95 2.95 6.2 4.35 1.25 1.4 1.25 4.05t-1.2 4.1q-.3.35-.5.65-.45.35-.9.55-1.55 1.15-3.65 1.15h-.15q.55 1.75 3.65 1.75 5.05 0 6.85-4.15.85-1.85.9-4.3v-.3-2.45q0-4.65-3.45-7.75-3.8-2.1-3.65-7.15.55-2.8 2.7-5.3 2.9-3.25 2.9-5.9 0-2.5-2.65-5.35-2.05-2.25-2.5-5.85-1.3-6.15 3.6-9.45.85-.6 1.45-1.05 4.55-3.8 3.3-8.45-.45-1.7-1.8-3.9-.45-.8-1.05-1.65-1.2-1.75-1.95-2.95v-.45q0-1.3 1.95-4t1.95-5.45q0-2.2-2-4.9t-2-4.5q0-2.2 3.1-4.75 3.15-2.55 3.15-5.4 0-.75-.9-3.55-.85-2.75-.85-4.45 0-3.65 2.5-6.55t2.5-4.8q0-.85-.4-2.1-.35-1.2-.35-2.25v-.1-.1q0-3 1.9-4.55 1.5-1.2 5.7-2.35 6.15-1.7 10.25-5.5.9-.8 2.95-3.65 2.4-3.4 3.45-4.85 3.7-5.15 7.6-6.4 1.55-.5 6.15-.6v-.4q-.75-.5-1.35-.85z"
                    id="HaiHig011__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig011__Symbol_15_0_Layer0_0_FILL"
                transform="translate(107.45 70.5)"
                id="HaiHig011__col1n"
            />
        </g></g>
    )
}

export default HaiHig011
