import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1n": "#252525"
}

function AccMor054({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M165.85 10.25q3.75-2.25 3.75-6.2 0-2.1-1.3-3.15-1-.8-2.5-.9h-.35q-.85 0-7.1 1-1.15.15-2.4.3h-.2q-1.7.25-3.5.45-.2.05-.5.1-.1 0-.2.05-.1 0-.15.05-7.65.95-15.2 1.75l-3.3.4-50.2 2.3-47.25-2.5q-1.05-.1-2.05-.2-7.65-.8-15.35-1.8-.4-.1-.7-.15-1.8-.2-3.5-.45h-.2q-1.25-.15-2.4-.3-5.7-.9-6.9-1h-.2Q3.4 0 2.8.2q-.85.2-1.5.7Q0 1.95 0 4.05 0 8 3.75 10.25q1.4.85 3.95 1.65 2 .4 4.05.8l1 .2h.2q1 2.8 1.05 3l.3.9q.25.7.6 1.35.35.6.4.65.25.5.55 1l1.75 2.45q.7.9 1.55 1.8 4.1 4.3 8.65 5.8 4.3 1.4 8.75 5.85 4.8 4.85 7.75 6.15 1.4.6 2.85 1.1 1.4.45 1.65.55.25.05 2.7.65t6.6 1.55q4.1.95 6.35 2.7 2.25 1.75 6.2 6.1.1.05 1.35 1.5 1.2 1.4 3.1 4.8 1.05 2.45 2.4 4.9 1.3 2.45 2.05 3.45.75 1 1.25 1.4.45.35 1.6.7.7.2 1.45.2.45 0 1.05-.1.55-.15 1-.3l.4-.2q2.1-.7 7.75-5 7.9-5.95 10.85-11.35.8-1.55 1.9-2.9 5.5-5.5 12-7.95 1.85-.55 5.55-1.95 6.9-2.4 9.8-3.55 2.7-1.55 5.75-3.75 2.7-1.9 3.7-5.15 1-3.25 1.8-4.4.8-1.2 2.7-2.2 1.85-1 5.05-2.55v-.05q5.1-2.9 4.7-7.3.05-.05.3-.1 1.65-.35 3.25-.65l.5-.1q2.55-.8 3.95-1.65z"
                    id="AccMor054__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M45.6 4.95q1.35-.75 0-1.3-1.85-.65-9.1-.7-10.15.15-10.95.1-2.35-.2-4.9-.35-4.9-.4-10.5-.8Q5 1.15 0 .25l.65 2.5Q.75 3 .7 3q0 .05.05.15l.2.5q.4 1.2 1.05 2.4 0 .05.05.1.25.5.55 1L4.35 9.6q.7.9 1.55 1.8 4.1 4.3 8.65 5.8 4.3 1.4 8.75 5.85 4.8 4.85 7.75 6.15 1.4.6 3.65 1.3.3.05.6.2.3.1 2.85 1 2.55.85 6.75 1.5 4.15.6 7.85 3.75.7.55 4.65 4.9.1.05.35.4.85 1 3.55 4.55 1.65 3.45 2.85 5.65 1.4 3.05 3.05 4.65-2.65-4.25-2.65-6.1 0-1.1.95-1.85t.95-1.65q0-1.35-1.7-2.2-2.85-1.5-3.8-2.35-2.45-2.25-3.45-3.7-.95-1.45-.95-2.9 0-.85.45-1.65.45-.8.45-.85 0-1.15-4.25-2.3-5.9-1.4-9.3-2.25-13.55-3.4-13.55-8.35 0-1.2.95-2.3.6-.65.85-1.1.8-1.6-1.7-1.7-2.6.25-4.4 0-5.7-.6-8-1.05-4-.8-4-2.4 0-.4.55-.65 1.4-.8 7-.75 7.55.1 7.55-.8 0-1.3-5.15-1.55-3.9-.15-4.85-.7-.3-.2-.3-.4 0-1.1 8.95-1.35 4.55-.1 9.65-.05l4.1-.25q2.95-.3 4.05-.95M145.4 0q-.65.1-1.3.25-5 .9-10.15 1.65l-2.7.2q-4.1.3-7.8.6l-13.15.85q-4.05.35-7.9.65-3.9.2-7.4.4-3.5.15-5.5.1-2.05 0-2.05 1.25.1.45.85.85 3.3 1.15 12.75.75h.4q4.3 0 12.4-.15 8.1-.15 8.1 1.85 0 .6-.75 1.25-.55.4-1.45.85-2.55.25-8.5.7-8.2.35-12.5.55-2.75.15-4.65.35-3.25.3-4.1.5-.5 1.2.6 1.25 1.1.05 2.6.25t3.55.25q2 .05 3.9.15 1.9.1 7.35.35 4.45.55 4.45 2.35 0 1-3.1 1.6-3.3.45-3.7.5-.9.15-1.4.2-.15.05-.25.05-.5.15-.75.3-.05.05-.1.15 0 1.35 4.3 1.35 4.3-.05 4.3 1.65 0 1.6-11.65 2.1-11.65.55-11.65 1.65 0 .75 3.1 1.3t3.1 2q0 1.4-9.05 1.8-9.05.4-9.05 2.2 0 2.55 4 4.9 4 2.3 4 4.2 0 .95-3.6 2.4-3.95 1.6-5 4.45-.35.95-1.05 3.3l-.15.55q-.1.25-.2.55l-.1.3q-.05.15-.1.4v.1l-.65 2.05q-.05 0-.1.05l-.1.1q.1-.05.2-.05 1.95-.45 8.1-5.1 7.9-5.95 10.85-11.35.8-1.55 1.9-2.9 5.5-5.5 12-7.95 1.85-.55 5.55-1.95 6.9-2.4 9.6-3.35 2.9-1.75 5.95-3.95 2.7-1.9 3.4-4.5 1-3.65 1.6-4.5 1-1.5 3.6-2.95 1.45-.8 4.65-2.35V7.3q5.1-2.9 4.5-7.3z"
                    id="AccMor054__Symbol_93_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor054__Symbol_151_0_Layer0_0_FILL"
                transform="translate(106.8 205.5)"
                id="AccMor054__col1n"
            />
            <use
                xlinkHref="#AccMor054__Symbol_93_0_Layer0_0_FILL"
                transform="translate(119.55 218.15)"
                id="AccMor054__col1d"
            />
        </g></g>
    )
}

export default AccMor054
