import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo001({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M87.1 295.75q-.25-.55-.6-1.1-2.7-4.25-10.5-5.55l-1.05-.2q-1.05-.15-2.1-.25-7.95-.8-17.65.65-.9.1-1.85.25-9.05 1.45-16.05 4.2-2.5 1.05-4.55 2.25-8.05 5.15-9.15 9.65-.1.35-.15.7-.2 1.6.5 3.05 0 .1.05.2l5 8.35q4.45 3.15 13.75 3.7 2.3.15 4.55.15h1q13.2-.2 25.35-5.8 7.75-3.35 11.1-7.65l1.05-3.95 23.2-3.75q0-.05 15.2-2.7 1.501-.27 2.8-.55-.222-.172-.45-.4-2.018-2.018-2.25-4.75-.238.043-.5.1l-.05-.05-36 5.85q-.2-1.3-.65-2.4z"
                    id="ObjFoo001__Layer7_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M60.8 309.85q1.1.1 2.15.35 1.7.35 3.65.9 7.45-2.05 13.15-5.2 1.7-.95 3.05-1.9.3-.95.2-1.9-.3-1.85-3.2-3.5-2.35-1.4-4.25-1.75-3.6-.75-10.8.45-2.5.2-5.45.85-.1.3-.4.8-.6.9-1.2 1-1.2-.6-2.35-.4-1.15.8-2.55 1.05-.75.1-1.4-.35-.3.1-.5.15-.25.15-.5.35-1.75 1.2-3.3 1.45-1.6-.25-2.85 0-3.3.5-5.1 4.15-.05.6.1 1.3.1.75.5 1.35.45.1 1 0 .45-.05 1.05-.65.6-.65 1.35-.75.2-.05 4.55 1.35.25.05 1.3 1.05l.15.15q2.4-.5 4.25-1 1.85-.35 5.05.75.55.15 2.35-.05z"
                    id="ObjFoo001__Layer7_2_FILL"
                />
                <path
                    fill="#FC0"
                    d="M70.95 307.95q2.9-.45 4.75-1.95 1.9-1.55 1.65-3.25-.3-1.7-2.55-2.5-2.25-.85-5.2-.35-2.9.45-4.8 1.95t-1.6 3.2q.25 1.7 2.5 2.55 2.3.85 5.25.35z"
                    id="ObjFoo001__Layer7_3_FILL"
                />
                <g id="ObjFoo001__Layer7_1_FILL">
                    <path
                        fill="#333"
                        d="M77.3 296.3q-5.55-2.55-21-.3-15.35 2.55-20.7 5.9-7.25 4.65-7 10.25 2.05.75 4.9 1.25 10.15 1.95 23.7-.25 5-.85 9.4-2.05 7.45-2.05 13.15-5.2 1.7-.95 3.05-1.9 1.1-.75 1.95-1.5-2.3-4.2-7.45-6.2z"
                    />
                    <path
                        fill="#666"
                        d="M37.3 293.75q-2.5 1.05-4.55 2.25-8.05 5.15-9.15 9.65-.1.35-.15.7.5 3.6 2.85 4.85 2.3 1.25 2.3.95-.25-5.6 7-10.25 5.35-3.35 20.7-5.9 15.45-2.25 21 .3 5.15 2 7.45 6.2.05 0 .1-.05l.3-.3q3.2-3.05 1.35-7.5-2.7-4.25-10.5-5.55l-1.05-.2q-1.05-.15-2.1-.25-7.95-.8-17.65.65-.9.1-1.85.25-9.05 1.45-16.05 4.2z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo001__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo001__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo001__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo001__Layer7_3_FILL" />
        </g></g>
    )
}

export default ObjFoo001
