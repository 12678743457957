import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#252525",
    "col2n": "#000000"
}
function BodTai014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M105.1 5.85q.25-.85.55-1.7 1.65-3.1-.05-4.15-.7-.15-1.95 1.85-4.05 7.8-8.45 14.95-2.35 3.75-4.85 7.35-.65.95-1.25 1.85-2.75 3.85-5.6 7.45Q67.05 54.1 46.2 66.7q-6.6 3.95-13.6 7.1-2.1.95-4.2 1.8-7.1 2.9-14.6 5-3.85 1.1-7.8 1.95-.6.05-1.15.2-.2 0-.3.05h.1q-1.5.5-2.7 1.75-2 2.05-1.95 4.9.1 2.8 2.1 4.8.2.2.4.35 1.95 1.6 4.5 1.6.95-.05 1.9-.3 26.3-7.35 46.2-21.25 4.55-3.25 8.8-6.75 5.2-4.4 9.95-9.3l2.8-3q.35-.45.75-.85 2.4-2.75 4.7-5.65l1.4-1.8q1.65-2.2 3.2-4.4.2-.3.4-.55.4-.65.8-1.25 10.5-15.7 17.2-35.25z"
                    id="BodTai014__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M85.4 35.25Q95.9 19.55 102.6 0q-8.7 20.6-20.35 36.3-4.25 5.75-8.9 10.85-5.1 5.55-10.7 10.35-1.3 1.1-2.65 2.2-4.55 3.7-9.4 7-3.75 2.55-7.75 4.9-.4.5-.9 1-1.75 1.75-4 2.5-1.1.35-2.25.45Q19.4 83.9 0 88.75q1.95 1.6 4.5 1.6.95-.05 1.9-.3Q32.7 82.7 52.6 68.8q4.55-3.25 8.8-6.75 5.2-4.4 9.95-9.3l2.8-3q.35-.45.75-.85 2.4-2.75 4.7-5.65l1.4-1.8q1.65-2.2 3.2-4.4.2-.3.4-.55.4-.65.8-1.25z"
                    id="BodTai014__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M16.95 60.65q2.05-2.6 2.05-6 0-1.8-.55-3.4-.3-.7-.65-1.35-6.6 3.95-13.6 7.1-2.1.95-4.2 1.8.7 1.55 2 2.85 1.5 1.5 3.25 2.25 1.8.7 3.8.7h.75q1.15-.1 2.25-.45 2.25-.75 4-2.5.5-.5.9-1m12.9-13.7q-2.45 0-4.2 1.7-1.7 1.75-1.7 4.2 0 1.6.75 2.9.35.65.95 1.25.5.5 1.05.85 4.55-3.25 8.8-6.75-.4-1.3-1.4-2.35l-.1-.1q-1.7-1.7-4.15-1.7M53.7 32.3l1.4-1.8q1.65-2.2 3.2-4.4.2-.3.4-.55-.95-.2-1.95-.2h-.4q-3.65.15-6.25 2.75-2.75 2.75-2.75 6.65 0 .75.1 1.45.2 1.35.8 2.6.35-.45.75-.85 2.4-2.75 4.7-5.65M67.85 3.35q0-1.9-1.05-3.35-2.35 3.75-4.85 7.35-.65.95-1.25 1.85.55.1 1.2.1 2.45 0 4.2-1.75t1.75-4.2z"
                    id="BodTai014__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M6.25 36.3q.5-.5.9-1-3.5 2.05-7.15 3.95 1.15-.1 2.25-.45 2.25-.75 4-2.5m8.65-5.9q.35.65.95 1.25.5.5 1.05.85 4.55-3.25 8.8-6.75-.4-1.3-1.4-2.35-4.55 3.7-9.4 7m29-23.45l1.4-1.8q1.65-2.2 3.2-4.4.2-.3.4-.55-.95-.2-1.95-.2h-.4q-4.25 5.75-8.9 10.85.2 1.35.8 2.6.35-.45.75-.85 2.4-2.75 4.7-5.65z"
                    id="BodTai014__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai014__Symbol_44_0_Layer0_0_FILL"
                transform="translate(216.1 211.15)"
                id="BodTai014__col1n"
            />
            <use
                xlinkHref="#BodTai014__Symbol_43_0_Layer0_0_FILL"
                transform="translate(218.6 217)"
                id="BodTai014__col1d"
            />
            <use
                xlinkHref="#BodTai014__Symbol_45_0_Layer0_0_FILL"
                transform="translate(244.5 227.95)"
                id="BodTai014__col2n"
            />
            <use
                xlinkHref="#BodTai014__Symbol_46_0_Layer0_0_FILL"
                transform="translate(254.3 253.3)"
                id="BodTai014__col2d"
            />
        </g></g>
    )
}
export default BodTai014
