import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo009({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFDA2C"
                    d="M123.9 257.55q-.123.013-.25 0-24.586 4.073-31.55 18.85v20.75l36.8 4.45q6.023-4.736 9.8 1.15l16.35 2v-5.65q-.45-.25-.85-.6-.75-.75-.75-1.85 0-1.05.75-1.85.4-.35.85-.55v-10l-.75-.65-13.4-7.4-10.05-11.2-4.8-3.55-1.15-3.05-1-.85z"
                    id="ObjFoo009__Layer7_0_FILL"
                />
                <g id="ObjFoo009__Layer7_1_FILL">
                    <path
                        fill="#F7A912"
                        d="M138.7 302.75q-.1-1.95-1.5-3.35-1.4-1.4-3.5-1.4-2.05 0-3.55 1.4-.9 1-1.25 2.2 5.55-3.6 9.8 1.15m10.65-9.4q-.4-.4-1-.4-.55 0-1 .4-.4.4-.4.95 0 .65.4 1.05.45.4 1 .4.6 0 1-.4.45-.4.45-1.05 0-.55-.45-.95m-.45-3.65q0-1.3-.95-2.25-.9-.95-2.25-.95-1.3 0-2.25.95t-.95 2.25q0 1.35.95 2.25.95.95 2.25.95 1.35 0 2.25-.95.95-.9.95-2.25m-22.05-1.1q.5-.55.5-1.3t-.5-1.3q-.55-.5-1.3-.5t-1.3.5q-.55.55-.55 1.3t.55 1.3q.55.55 1.3.55t1.3-.55m-17.75.2q-1.4-1.4-3.35-1.4-2 0-3.35 1.4-1.4 1.4-1.4 3.35 0 2 1.4 3.4 1.35 1.4 3.35 1.4 1.95 0 3.35-1.4 1.4-1.4 1.4-3.4 0-1.95-1.4-3.35m4-12.1q-.25-.15-.6-.35-1.95-1.1-3.95-.65-2 .4-2.85 1.1-.85.75-1.3 1.7v.35q0 1.45 1.2 2.5 1.25 1.1 3 1.1 1.55 0 2.7-.85.15-.1.3-.25 1-.8 1.2-1.9.05-.3.85-1.15.65-.75-.55-1.6m24.4-1.25q0-1.05-1.25-1.8-1.25-.7-3.05-.7-1.8 0-3.05.7-1.25.75-1.25 1.8t1.25 1.75q1.25.75 3.05.75 1.8 0 3.05-.75 1.25-.7 1.25-1.75m-6.4-8.6q0-.35-.5-.65-.5-.25-1.2-.25-.75 0-1.25.25-.5.3-.5.65 0 .35.5.6.5.3 1.25.3.7 0 1.2-.3.5-.25.5-.6m-15.7.65q-1 .45-1 1.15t1 1.15q1.05.45 2.5.45 1.5 0 2.5-.45 1.05-.45 1.05-1.15t-1.05-1.15q-1-.45-2.5-.45-1.45 0-2.5.45m2.25-3.95q1 .45 2.3.45 1.35 0 2.25-.45 1-.5 1-1.15 0-.7-1-1.15-.9-.5-2.25-.5-1.3 0-2.3.5-.9.45-.9 1.15 0 .65.9 1.15m-13.9 7.95q-.75-.35-1.85-.35-1.15 0-1.9.35-.8.4-.8.9t.8.85q.75.4 1.9.4 1.1 0 1.85-.4.8-.35.8-.85t-.8-.9z"
                    />
                    <path
                        fill="#FEFDAB"
                        d="M93.5 276.6q-.2.3-.3.65l11.2 1.25q.45-.95 1.3-1.7.85-.7 2.85-1.1 2-.45 3.95.65.35.2.6.35 1.2.85.55 1.6-.8.85-.85 1.15l42.25 4.8-10.25-8.8h-.4q-2 0-3.5-.95-1.4-1-1.4-2.4 0-.5.25-1l-9.5-8.1q-.25.05-.55.05-.9 0-1.55-.65t-.65-1.5q0-.15.05-.25l-2.65-2.3q-.8.15-1.65.25l-4.65.85q-19.6 4.1-25.1 17.15m8.4-5.45q1.1 0 1.85.35.8.4.8.9t-.8.85q-.75.4-1.85.4-1.15 0-1.9-.4-.8-.35-.8-.85t.8-.9q.75-.35 1.9-.35m18.05-7.15q-1.3 0-2.3-.45-.9-.5-.9-1.15 0-.7.9-1.15 1-.5 2.3-.5 1.35 0 2.25.5 1 .45 1 1.15 0 .65-1 1.15-.9.45-2.25.45m-5.55 4.65q0-.7 1-1.15 1.05-.45 2.5-.45 1.5 0 2.5.45 1.05.45 1.05 1.15t-1.05 1.15q-1 .45-2.5.45-1.45 0-2.5-.45-1-.45-1-1.15m16.2-2.45q.5.3.5.65 0 .35-.5.6-.5.3-1.2.3-.75 0-1.25-.3-.5-.25-.5-.6t.5-.65q.5-.25 1.25-.25.7 0 1.2.25m5.65 7.45q1.25.75 1.25 1.8t-1.25 1.75q-1.25.75-3.05.75-1.8 0-3.05-.75-1.25-.7-1.25-1.75t1.25-1.8q1.25-.7 3.05-.7 1.8 0 3.05.7z"
                    />
                    <path
                        fill="#C25500"
                        d="M93.2 277.25l-1.55-.2v20.05l1.55.2v-20.05z"
                    />
                    <path
                        fill="#FA8310"
                        d="M91.65 277.05l1.55.2q.1-.35.3-.65 5.5-13.05 25.1-17.15l4.65-.85q.85-.1 1.65-.25l-1-.8q-7.85.85-13.9 3.05-13.55 4.9-18.35 16.45z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo009__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo009__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo009
