import { AssetSvgProps } from "../../../shared/assets"
function PlaInd039({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#652BAA"
                    d="M385 385V0H0v385h385m-17.2-111.7l-.05-.1h.3q-.126.046-.25.1z"
                    id="PlaInd039__Layer4_0_FILL"
                />
                <path
                    fill="url(#PlaInd039__Gradient_1)"
                    d="M382.25 162.35q0-33.1-43.3-56.45Q295.6 82.55 234.3 82.55q-61.3 0-104.6 23.35t-43.3 56.45q0 33 43.3 56.35 13.75 7.45 29.35 12.5H309.6q15.6-5.05 29.35-12.5 43.3-23.35 43.3-56.35z"
                    id="PlaInd039__Layer4_2_FILL"
                />
                <path
                    fill="#542A85"
                    d="M43.75 13.5l-.3.1L.05 0H0v303.8l43.75-31V13.5z"
                    id="PlaInd039__Layer4_4_FILL"
                />
                <path
                    fill="#FFF"
                    d="M385 228.5v-14.4l-21.3-6.85H104.25L62.2 228.5H385z"
                    id="PlaInd039__Layer3_1_FILL"
                />
                <path
                    fill="#B68EB8"
                    d="M84.75 237.75V235H77.8v2.75h6.95z"
                    id="PlaInd039__Layer3_2_FILL"
                />
                <path
                    fill="#9F77A1"
                    d="M121.2 235H84.75v87.5l36.45-48.9V235m224.25 0v37.95L385 305.9V235h-39.55z"
                    id="PlaInd039__Layer3_3_FILL"
                />
                <path
                    fill="#434343"
                    d="M333.45 123.25q-.1 0-.15.05-48.95 4.35-98.4 4.2-10.35-.05-20.7-.3-35.6-.8-71.45-3.9h-.2q-.15-.05-.25-.05v80.25q25.15-2.3 50.05-3.4 2.9-.15 5.85-.25 16.644-.704 25.75-.6 8.245.102 11.3 1.05-1.87.314-3.75 1v6.7q1.243.73 2.45 1.2-6.59-.987-29 .75v.05h-.05l.05.05v2.4h66.6v-2.55q-22.288-1.772-29.35-.25 1.791-.53 3.55-1.65v-6.7q-2.103-.767-4.2-1.1 2.528-1.194 4.2-1.15 16.7.1 33.4.8 27.3 1.1 54.3 3.65v-80.25z"
                    id="PlaInd039__Layer2_1_FILL"
                />
                <path
                    d="M245.75 201.3v-2.25q-1.75-.05-3.5-.05h-7.65q-1.55 0-3.1.05v2.25h14.25m21.95 4.55h-21.95V208H231.5v-2.15h-25.15L204.9 210h.05v-.05l66.55-.05-3.8-4.05m63.5-79.55q-.5.05-1.05.1-49.3 4.15-99.25 3.8l-15-.2q-34.9-.75-70.1-3.65-.35 0-.7-.05v74.2q31.45-2.7 62.55-3.6l8.5-.2q1.9-.05 3.75-.05 7.1-.15 14.15-.15.25-.05.5 0h.05q1.85-.05 3.7-.05h4.6q6.8.05 13.55.15 1.7 0 3.45.05l8.65.2q.3 0 .6.05 31.2.85 62.05 3.6v-74.2z"
                    id="PlaInd039__Layer2_1_MEMBER_0_FILL"
                />
                <path
                    d="M74.25 158.35h-.05q2.966 33.25 0 66.5 18.15-2.533 36.3 0l-.1-.1q10.173-10.744 21.5-17.7-3.63-29.5 0-59l-25.7-.2-31.95 10.5z"
                    id="PlaInd039__Layer2_2_FILL"
                />
                <path
                    fill="#0059E0"
                    d="M85.65 198.65q-1.85.1-3.2 2.35-1.35 2.45-1.35 5.75v.35q.1 3.1 1.35 5.35.5.85 1.1 1.4 1 1 2.3 1 1.9 0 3.25-2.4 1.3-2.25 1.4-5.35v-.35q0-3.3-1.4-5.75-1.35-2.35-3.25-2.35h-.2m0 5.15q.1-.05.25-.05.75 0 1.25.85.55.85.55 2.05 0 1.2-.55 2.05-.5.9-1.25.9h-.25q-.6-.15-1.1-.9-.5-.85-.5-2.05 0-1.2.5-2.05.5-.7 1.1-.8m0-25.5q-.9 0-1.7.55-.9.6-1.6 1.8Q81 183.1 81 186.4v.35q.1 2.4.85 4.3.2.55.5 1.05 1.4 2.4 3.4 2.4h.05q1.85-.05 3.2-2.4 1.3-2.25 1.4-5.35v-.35q0-3.3-1.4-5.75-1.35-2.35-3.25-2.35h-.1m.1 5.15h.05q.75.05 1.25.85.5.85.5 2.05 0 1.2-.5 2.05-.5.85-1.25.9H85.65q-.7-.05-1.2-.9t-.5-2.05q0-1.2.5-2.05.5-.8 1.2-.85h.1m4.65-18.3q0-2.1-.6-3.85h-8.4q-.15.55-.25 1.1-.3 1.3-.3 2.75 0 3.45 1.4 5.9.95 1.7 2.2 2.25.55.25 1.15.25h.15q.55-.05 1.05-.25 1.2-.55 2.15-2.25 1.45-2.45 1.45-5.9m-3.5-1.8q.55.85.55 2.05 0 1.2-.55 2.05-.45.85-1.15.9h-.1q-.75 0-1.35-.9-.5-.85-.5-2.05 0-1.2.5-2.05.6-.85 1.35-.85h.1q.7.05 1.15.85z"
                    id="PlaInd039__Layer2_2_MEMBER_0_MEMBER_0_MEMBER_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M62 137.25H43.65L0 149.5v7.05l62-19.3m-.3-34.95l-18-.35-43.7 3.5v2.25l61.7-5.4z"
                    id="PlaInd039__Layer2_3_FILL"
                />
                <path
                    fill="#2E2E2E"
                    d="M331.2 211.5q-.1-.05-.25-.05-.095-.048-.2-.05-2.383-.68-4.45 0h-.25q-1.15.3-1.75 2.05l-.4 2.25v.15q.45 1 4.2 3.1 3.8 2.05 6.7 1.3 1.45-.4 1.85-2.5.25-2.1-1.15-3.85-1.35-1.6-3.95-2.35-.2 0-.35-.05z"
                    id="PlaInd039__Layer1_1_FILL"
                />
                <path
                    fill="#555"
                    d="M327.5 212.9l-.6-.3q-.55-.35-.8-.6-.35-.25-.25-.45.05-.1.2-.15-1.15.3-1.75 2.05.25.05.6.25.15.1.35.2.4.25 1 .65 1.4.95.7.4 1.4.2 2.3-1l-1.75-1.05m2.2.6q1.05-.55 1-1.7v-.2q-.05-.1-.05-.15h.2q1.75.5.7.1-.2 0-.35-.05-.1-.05-.25-.05-.095-.048-.2-.05-2.383-.68-4.45 0l.35.3q.2.15.45.35.55.35 1 .6.8.55 1.6.85z"
                    id="PlaInd039__Layer1_1_MEMBER_0_FILL"
                />
                <path
                    d="M48.1 80q-3.1.1-5.25 2.25-2.25 2.25-2.25 5.45 0 3.25 2.25 5.45.65.65 1.4 1.15l.7.4q-1.55.5-2.15 1.1-1.55 1.65-1.55 2.7 0 1.05.9 1.45.55.25 1.65.2.2.6-.3 1.35-.4.6-.05 1.35h9.3q.35-.75-.05-1.35-.5-.75-.3-1.35 1.1.05 1.65-.2.9-.4.9-1.45 0-1.05-1.55-2.7-.6-.6-2.15-1.1l.7-.4q.75-.5 1.4-1.15 2.25-2.2 2.25-5.45 0-3.2-2.25-5.45Q51.2 80.1 48.1 80z"
                    id="PlaInd039__Layer1_2_FILL"
                />
                <path
                    d="M29 79.7q-3.35.1-5.7 2.4-2.45 2.45-2.45 5.95t2.45 5.9q.7.7 1.5 1.25.35.2.75.4-1.7.55-2.35 1.2-1.65 1.8-1.65 2.9 0 1.15.95 1.6.65.25 1.85.2.15.65-.35 1.45-.45.7-.05 1.5H34q.4-.8-.05-1.5-.5-.8-.35-1.45 1.25.05 1.85-.2.95-.45.95-1.6 0-1.1-1.65-2.9-.65-.65-2.35-1.2.4-.2.75-.4.8-.55 1.5-1.25 2.45-2.4 2.45-5.9 0-3.5-2.45-5.95-2.35-2.3-5.65-2.4z"
                    id="PlaInd039__Layer1_3_FILL"
                />
                <path
                    d="M9.7 79.4q-3.6.1-6.1 2.6Q.95 84.65.95 88.4q0 3.75 2.65 6.35.75.75 1.6 1.35.4.2.8.45-1.8.55-2.5 1.25-1.8 1.95-1.8 3.15 0 1.25 1.05 1.7.65.3 1.95.25.2.7-.35 1.55-.5.75-.05 1.6h10.8q.45-.85-.05-1.6-.55-.85-.35-1.55 1.3.05 1.95-.25 1.05-.45 1.05-1.7 0-1.2-1.8-3.15-.7-.7-2.5-1.25.4-.25.8-.45.85-.6 1.6-1.35 2.65-2.6 2.65-6.35 0-3.75-2.65-6.4-2.5-2.5-6.1-2.6z"
                    id="PlaInd039__Layer1_4_FILL"
                />
                <path
                    d="M257.25 217.7l-.6-1.75h-72.6l-.7 1.75h73.9m.7-1.75l.75 1.75h21.65l-2-1.75h-20.4m1.2 7.45l-1.5-4.55H182.9l-1.8 4.55h78.05m22.55-4.55h-22.5l1.95 4.55H287l-5.3-4.55m11.2 8.25v-1.65H176.6v1.65h116.3z"
                    id="PlaInd039__Layer1_7_FILL"
                />
                <g id="PlaInd039__Layer4_1_FILL">
                    <path
                        fill="#9058D2"
                        d="M368.05 273.2q-.126.046-.25.1l-.05-.1H43.15Q19.694 279.41 0 303.8V385h385V273.2h-16.95z"
                    />
                    <path
                        fill="#C68FF0"
                        d="M385 13.5V0H.05q19.511 13.844 43.4 13.6l.3-.1H385z"
                    />
                </g>
                <g id="PlaInd039__Layer4_3_FILL">
                    <path
                        fill="url(#PlaInd039__Gradient_2)"
                        d="M367.75 273.2h-259.6l-22.75 44H385V306l-17.25-32.8z"
                    />
                    <path
                        fill="#542A85"
                        d="M361.45 273.2L360 272v-38.2H117.2v39.4h244.25z"
                    />
                </g>
                <g id="PlaInd039__Layer3_0_FILL">
                    <path
                        fill="#9F77A1"
                        d="M295.9 278.15l-3.45-4.75v.2l3.45 4.55z"
                    />
                    <path
                        fill="#D5B5D7"
                        d="M77.8 235v87.5h6.95q5.358-44.107 0-87.5H77.8z"
                    />
                    <path
                        fill="#F1D6F3"
                        d="M385 235v-6.5q-161.871-9.423-322.8 0v6.5h22.55q18.225 5.798 36.45 0h224.25q20.534 7.591 39.55 0z"
                    />
                </g>
                <g id="PlaInd039__Layer2_0_FILL">
                    <path
                        fill="#F1D6F3"
                        d="M62 106.5v-4.2h-.3q-31.105.306-61.7 5.4v5.7l62-6.9m0 35.05v-4.3q-32.379 8.088-62 19.3V163l62-21.45z"
                    />
                    <path
                        fill="url(#PlaInd039__Gradient_3)"
                        d="M42.25 13.25h.25L-.75-.6v55.55l43 11.5v-53.2z"
                    />
                    <path
                        fill="url(#PlaInd039__Gradient_4)"
                        d="M384.95 67V13.25H42.25V67h342.7z"
                    />
                </g>
                <g id="PlaInd039__Layer2_2_MEMBER_0_FILL">
                    <path
                        fill="#434343"
                        d="M110.5 158.35H74.2v66.5h36.3l-.1-.1.1-66.4m-3.05 12.7V221.75H77.05V161.3h30.4v9.75z"
                    />
                    <path
                        fill="#00193F"
                        d="M107.45 221.75v-3.95H92.8v3.95h14.65z"
                    />
                    <path
                        fill="#00265F"
                        d="M107.45 217.8v-4.9h-2.6l2.6-1.65v-1.8L92.9 217.8h14.55m22.65-14.45v-1.65h-9.15l-8.05 4.65v1.45l5.3-3.4h8.85l-11.95 8.5h-2.2v3.15l17.2-12.7z"
                    />
                    <path d="M107.45 212.9v-1.65l-2.6 1.65h2.6M92.9 196.1v1H99.55q.05.05.1.15 1.3 1.4 2.65 2.3 1.55 1.05 3.5 1.7.8.25 1.65.45v-1.45q-.75-.2-1.45-.4-.1-.05-.2-.05-1.85-.65-3.3-1.65-.7-.45-1.35-1.05H107.45v-1H92.9m3.8-9.6h10.75v-.95H92.9v.95h3.8m-3.2-21.35q0 .25.05.5.1 3 1.25 5.65 1.4 3.2 3.95 4.7 2.8 1.65 5.8 2 .7.1 1.25.2 1.05.15 1.65.45v-1.75h-.05q-.55-.1-1.25-.2-.2 0-.35-.05-2.8-.4-5.45-1.95-2.55-1.5-3.95-4.7-.9-2.05-1.15-4.35-.05-.25-.05-.5h-1.7m12.3 5.65q-1.45-.35-2.65-1-1.55-.9-3.15-3.65-.65-1.05-1-1.95h-1.3q.15 1.35 1.4 3.45 1.6 2.75 3.15 3.65 1.55.85 3.55 1.2.3 0 .65.05l1 .1v-1.6h-.1q-.8-.1-1.55-.25m12.4 33.6l-5.3 3.4v5.1h2.2l11.95-8.5h-8.85m-5.3-11.5v1l4.05-2.85v-.8l-4.05 2.65m0-9.9v.7l4.05-1.9v-.7l-4.05 1.9m6.4-3.65l-.9-.45q-2.326-.967-5.45-1.55h-.05q-.05.6-.05 1.25l.05.05v.25q3.4.65 5.05 1.4.233.113.45.2 1.327.706 2.1 1.35.9.75 2.4 3.5 1.45 2.75 1.45 6.65 0 3.9-3.7 6.1-1.114.639-2.25 1.1-2.688 1.05-5.5 1.05V202q2.951-.029 5.5-1.1 1.857-.744 3.5-2.05 3.2-2.55 3.9-5.7v-4.65q-.75-5.1-4.35-7.85-.55-.45-1.2-.8-.5-.3-.95-.5m8.2-5.85h-.75v24.7h.75v-24.7m-6.95-14.8l.05 1.35q0 3.925-2.2 6.75-.098.126-.2.25-.35.4-.75.8l-.05.05q-2.05 2.05-4.5 2.75v.95q3.495-1.122 5.5-2.9.319-.255.6-.55.35-.35.6-.7 2.4-3.15 2.75-9.45h-.05q-.65.3-1.75.7z" />
                    <path
                        fill="url(#PlaInd039__Gradient_5)"
                        d="M107.45 207.2v-5.5q-.85-.2-1.65-.45v5.95h1.65m0-10.1h-1.65v1.45q.8.25 1.65.45v-1.9m0-6.85v-3.75h-1.65v3.75h1.65m-13.9-24.6h-1.4q.05 3.3 1.3 6.15 1.4 3.2 3.95 4.7 2.8 1.65 4.75 2.25 1.9.55 2.6.55.65.05 1.05.25v1.55h1.65v-2.45q-.6-.3-1.65-.45-.55-.1-1.25-.2-3-.35-5.8-2-2.55-1.5-3.95-4.7-1.15-2.65-1.25-5.65m13.8 5.4h.1v-9.75h-1.65v9.5q.75.15 1.55.25m-1.55 1.45q-2-.35-3.55-1.2-1.55-.9-3.15-3.65-1.25-2.1-1.4-3.45h-.65q-.3 1.5 1.4 4.35 1.6 2.75 3.15 3.65 1.8 1 4.2 1.25v3.2q.15.05.35.05.7.1 1.25.2h.05v-4.25l-1-.1q-.35-.05-.65-.05m12.6 9.35q-.725-.465-1.45-.75v.7l-4.05 1.9v6.55h4.05v.8l-4.05 2.85v5.05q2.767-.04 5.5-1.05.626-.22 1.25-.5 3.35-1.55 3.35-5.6 0-5.7-3.25-8.8-.675-.651-1.35-1.15m-3.6-1.45q-.5-.15-1.9-.15v.85h4.05q-.1-.05-.15-.05-1.55-.6-2-.65m5.8-20.35l-.05-1.35q1.1-.4 1.75-.7h.05q-.35 6.3-2.75 9.45-.25.35-.6.7-.281.295-.6.55-2.005 1.778-5.5 2.9v1.15q1.75-.55 2.85-1.1 1.346-.673 2.65-1.75.731-.581 1.45-1.3.1-.15.25-.3.15-.2.35-.35.6.4 1.15 1 1.85 2.05 1.85 4.85v.3q-.1 2.65-1.85 4.55-.65.7-1.35 1.2.65.35 1.2.8 3.6 2.75 4.35 7.85v-15h.75v24.7h-.75v-5.05q-.7 3.15-3.9 5.7-1.643 1.306-3.5 2.05-2.549 1.071-5.5 1.1v4.35l8.05-4.65h9.15v-50.05l-17.2 8.55v8.75q1.8-1.95 4.4-1.95.45 0 .9.05.102-.124.2-.25 2.2-2.825 2.2-6.75m6.9 13.45h.75v24.7h-1.5v-24.7h.75z"
                    />
                    <path
                        fill="url(#PlaInd039__Gradient_6)"
                        d="M107.45 209.45v-2.25h-1.65l-26.05 14.55H92.8v-3.95h.1l14.55-8.35z"
                    />
                    <path
                        fill="#001E4D"
                        d="M102.95 197.1h-1.8q.65.6 1.35 1.05 1.45 1 3.3 1.65.1 0 .2.05.7.2 1.45.4V199q-.85-.2-1.65-.45-1.65-.6-2.85-1.45m-.8-10.6v.3q0 1.5.9 2.85.5.75.7 1.55.15.6.15 1.2v.15q0 .95-.1 1.25-.15.4-.7.6h.75q.55-.2.7-.6.1-.3.1-1.25v-.15q0-.8-.25-1.5-.2-.65-.6-1.25-.9-1.35-.9-2.85v-.3h-.75m-3.15.3q0 1.5.9 2.85.85 1.3.85 2.75v.15q0 .95-.1 1.25-.15.4-.7.6h.75q.55-.2.7-.6.1-.3.1-1.25v-.15-.2q-.05-1.35-.85-2.55-.9-1.35-.9-2.85v-.3H99v.3m-2.3 0v-.3h-.75v.3q0 1.5.9 2.85.85 1.3.85 2.75v.15q0 .95-.1 1.25-.05.15-.15.25.3 1.15.95-.4.05-.4.05-1.1v-.15q0-1.45-.85-2.75-.9-1.35-.9-2.85m16.2-6.55q1.4 0 1.9.15.45.05 2 .65.05 0 .15.05.725.285 1.45.75.675.499 1.35 1.15 3.25 3.1 3.25 8.8 0 4.05-3.35 5.6-.624.28-1.25.5-2.733 1.01-5.5 1.05v1.3q2.812 0 5.5-1.05 1.136-.461 2.25-1.1 3.7-2.2 3.7-6.1 0-3.9-1.45-6.65-1.5-2.75-2.4-3.5-.773-.644-2.1-1.35-.217-.087-.45-.2-1.65-.75-5.05-1.4v1.35z"
                    />
                    <path
                        fill="url(#PlaInd039__Gradient_7)"
                        d="M105.8 197.1h-2.85q1.2.85 2.85 1.45v-1.45m-2.9-10.6v.3q0 1.5.9 2.85.4.6.6 1.25l1.4-.65v-3.75h-2.9m2.9-1.05l-.3.1h.3v-.1m-6.05 1.35q0 1.5.9 2.85.8 1.2.85 2.55l2.25-1q-.2-.8-.7-1.55-.9-1.35-.9-2.85v-.3h-2.4v.3m-.75-.3h-2.3v.3q0 1.5.9 2.85.85 1.3.85 2.75v.15q0 .7-.05 1.1l2.35-1.1v-.15q0-1.45-.85-2.75-.9-1.35-.9-2.85v-.3m4.15-16.7q1.2.65 2.65 1v-9.5H77.05v60.45h2.7l26.05-14.55v-5.95q-1.95-.65-3.5-1.7-1.35-.9-2.65-2.3-.05-.1-.1-.15h-.35v11.45l-.95.5V197.1H92.9v-1l4.55-2.05q.1-.1.15-.25.1-.3.1-1.25v-.15q0-1.45-.85-2.75-.9-1.35-.9-2.85v-.3H92.9v-.95l12.9-4.45v-1.55q-.4-.2-1.05-.25-.7 0-2.6-.55-1.95-.6-4.75-2.25-2.55-1.5-3.95-4.7-1.25-2.85-1.3-6.15h1.4q-.05-.25-.05-.5h1.7q0 .25.05.5.25 2.3 1.15 4.35 1.4 3.2 3.95 4.7 2.65 1.55 5.45 1.95v-3.2q-2.4-.25-4.2-1.25-1.55-.9-3.15-3.65-1.7-2.85-1.4-4.35H99q.35.9 1 1.95 1.6 2.75 3.15 3.65z"
                    />
                    <path
                        fill="#00388C"
                        d="M105.5 185.55l.3-.1v.1h1.65v-4.45h-1.65l-12.9 4.45h12.6m-4.8 8.85h-.75q.55-.2.7-.6.1-.3.1-1.25l-2.35 1.1q-.65 1.55-.95.4l-4.55 2.05h14.55v-5.85h-1.65l-1.4.65q.25.7.25 1.5v.15q0 .95-.1 1.25-.15.4-.7.6h-.75q.55-.2.7-.6.1-.3.1-1.25v-.15q0-.6-.15-1.2l-2.25 1v.35q0 .95-.1 1.25-.15.4-.7.6m25.1-5.9v9.7h.75v-24.7h-.75v15m-12.9 1.75v2.65l4.05-2.65h-4.05m0-9.15v1.9l4.05-1.9h-4.05m5.85-8.9q-.16-.19-.35-.35-.462-.3-1.1-.3-.8 0-1.45.65-.55.65-.55 1.55t.55 1.55q.65.7 1.45.7.638 0 1.1-.35.19-.146.35-.35.55-.65.55-1.55t-.55-1.55m-2.1.85q.3-.3.7-.3.4 0 .7.3.3.3.3.7 0 .4-.3.7-.3.3-.7.3-.4 0-.7-.3-.3-.3-.3-.7 0-.4.3-.7m11.6.45h-.75v24.7h.75v-24.7z"
                    />
                    <path
                        fill="#0059E0"
                        d="M99.2 197.1h-.95v11.95l.95-.5V197.1m16.55-25.45q-1.1.55-2.85 1.1v4.55l.05.05q3.124.583 5.45 1.55l.9.45q.95-.45 1.75-1.35 1.6-1.75 1.6-4.2t-1.6-4.2q-.55-.65-1.2-1-.719.719-1.45 1.3-1.304 1.077-2.65 1.75m2.65.2q.19.16.35.35.55.65.55 1.55t-.55 1.55q-.16.204-.35.35-.462.35-1.1.35-.8 0-1.45-.7-.55-.65-.55-1.55t.55-1.55q.65-.65 1.45-.65.638 0 1.1.3m-5.5-1.55v.35q2.45-.7 4.5-2.75l.05-.05h-.2q-2.2 0-3.8 1.75-.3.35-.55.7z"
                    />
                    <path
                        fill="#272727"
                        d="M110.35 158.35h.15l-.1 66.4 21.5-17.7v-59l-21.55 10.3m2.55 12.3v-.35-10.1l17.2-8.55V205.75l-17.2 14v-41.1l-.05-.05q0-.65.05-1.25v-.05-4.55-1.15-.95z"
                    />
                    <path
                        fill="#00112C"
                        d="M130.1 205.75v-2.4l-17.2 12.7v3.7l17.2-14z"
                    />
                    <path
                        fill="#418DFF"
                        d="M112.9 177.35h.05l-.05-.05v.05m7.2-9.05q-.15.15-.25.3.65.35 1.2 1 1.6 1.75 1.6 4.2t-1.6 4.2q-.8.9-1.75 1.35.45.2.95.5.7-.5 1.35-1.2 1.75-1.9 1.85-4.55v-.3q0-2.8-1.85-4.85-.55-.6-1.15-1-.2.15-.35.35m-1.9-1.25q-.45-.05-.9-.05-2.6 0-4.4 1.95v1.35q.25-.35.55-.7 1.6-1.75 3.8-1.75h.2q.4-.4.75-.8z"
                    />
                    <path
                        fill="#001C46"
                        d="M117.35 172.75q-.4 0-.7.3-.3.3-.3.7 0 .4.3.7.3.3.7.3.4 0 .7-.3.3-.3.3-.7 0-.4-.3-.7-.3-.3-.7-.3z"
                    />
                </g>
                <g id="PlaInd039__Layer2_2_MEMBER_0_MEMBER_0_FILL">
                    <path
                        fill="#418DFF"
                        d="M85.65 197.75q-.35 0-.7.1-.25.05-.5.15-1.45.55-2.6 2.35-1.6 2.65-1.6 6.35v.4q.1 3.45 1.6 5.95.3.45.6.8.2.25.4.45 1.35 1.4 3 1.4t2.95-1.4q.5-.5.95-1.25.85-1.35 1.25-2.95.35-1.4.4-3v-.4q0-1.8-.4-3.4-.4-1.6-1.25-2.95-1.1-1.8-2.55-2.35-.25-.1-.45-.15-.45-.1-.9-.1h-.2m0 6.05q.1-.05.25-.05.75 0 1.25.85.55.85.55 2.05 0 1.2-.55 2.05-.5.9-1.25.9h-.25q-.6-.15-1.1-.9-.5-.85-.5-2.05 0-1.2.5-2.05.5-.7 1.1-.8m-5.3-41.4q-.35 1.4-.35 3v.05q0 1.1.15 2.15.35 2.35 1.5 4.2.55.9 1.2 1.5 1.25 1.15 2.75 1.15h.15q1.45-.05 2.6-1.15.6-.6 1.15-1.5 1.7-2.65 1.7-6.35 0-2.25-.65-4.15h-9.9q-.2.55-.3 1.1m6.55.95q.55.85.55 2.05 0 1.2-.55 2.05-.45.85-1.15.9h-.1q-.75 0-1.35-.9-.5-.85-.5-2.05 0-1.2.5-2.05.6-.85 1.35-.85h.1q.7.05 1.15.85m-6.75 23.05v.4q.05 2.35.8 4.25.3.9.8 1.7 1.65 2.65 4 2.65h.05q2.25-.05 3.85-2.65 1.55-2.5 1.65-5.95v-.4q0-3.65-1.65-6.35-1.6-2.6-3.9-2.6h-.1q-1.7.05-2.95 1.4-.5.5-.95 1.2-1.6 2.7-1.6 6.35m5.6-2.95h.05q.75.05 1.25.85.5.85.5 2.05 0 1.2-.5 2.05-.5.85-1.25.9H85.65q-.7-.05-1.2-.9t-.5-2.05q0-1.2.5-2.05.5-.8 1.2-.85h.1z"
                    />
                    <path
                        fill="#00388C"
                        d="M85.9 203.75q-.15 0-.25.05-.6.1-1.1.8-.5.85-.5 2.05 0 1.2.5 2.05.5.75 1.1.9h.25q.75 0 1.25-.9.55-.85.55-2.05 0-1.2-.55-2.05-.5-.85-1.25-.85m-.25 1.45q.1-.05.2-.05.35 0 .6.5.3.45.3 1.1 0 .65-.3 1.15-.25.45-.6.45-.1 0-.2-.05-.25-.1-.45-.4-.25-.5-.25-1.15 0-.65.25-1.1.2-.35.45-.45m1.8-39.8q0-1.2-.55-2.05-.45-.8-1.15-.85h-.1q-.75 0-1.35.85-.5.85-.5 2.05 0 1.2.5 2.05.6.9 1.35.9h.1q.7-.05 1.15-.9.55-.85.55-2.05m-1.15-1.1q.3.45.3 1.1 0 .65-.3 1.15-.25.4-.55.45h-.05q-.35 0-.65-.45-.25-.5-.25-1.15 0-.65.25-1.1.3-.5.65-.5h.05q.3.05.55.5m-.5 19.15H85.65q-.7.05-1.2.85-.5.85-.5 2.05 0 1.2.5 2.05.5.85 1.2.9H85.8q.75-.05 1.25-.9t.5-2.05q0-1.2-.5-2.05-.5-.8-1.25-.85m-.7 1.8q.25-.45.55-.5H85.8q.3.05.55.5.3.45.3 1.1 0 .65-.3 1.15-.25.4-.55.45H85.65q-.3-.05-.55-.45-.25-.5-.25-1.15 0-.65.25-1.1z"
                    />
                    <path
                        fill="#001C46"
                        d="M85.85 205.15q-.1 0-.2.05-.25.1-.45.45-.25.45-.25 1.1 0 .65.25 1.15.2.3.45.4.1.05.2.05.35 0 .6-.45.3-.5.3-1.15 0-.65-.3-1.1-.25-.5-.6-.5m.75-39.75q0-.65-.3-1.1-.25-.45-.55-.5h-.05q-.35 0-.65.5-.25.45-.25 1.1 0 .65.25 1.15.3.45.65.45h.05q.3-.05.55-.45.3-.5.3-1.15m-.95 19.35q-.3.05-.55.5-.25.45-.25 1.1 0 .65.25 1.15.25.4.55.45H85.8q.3-.05.55-.45.3-.5.3-1.15 0-.65-.3-1.1-.25-.45-.55-.5H85.65z"
                    />
                </g>
                <g id="PlaInd039__Layer2_2_MEMBER_0_MEMBER_1_FILL">
                    <path
                        fill="#001C46"
                        d="M112.9 161.75v.95l14.85-7.75V154l-14.85 7.75m-34.05 1.4v1.05h28.6v-1.05h-28.6z"
                    />
                    <path
                        fill="#0059E0"
                        d="M112.9 160.2v1.55l14.85-7.75v-1.15l-14.85 7.35m-34.05 1.1v1.85h28.6v-1.85h-28.6z"
                    />
                    <path
                        fill="#418DFF"
                        d="M112.9 162.7v.95l14.85-7.9v-.8l-14.85 7.75m-34.05 1.5v.95h28.6v-.95h-28.6z"
                    />
                </g>
                <g id="PlaInd039__Layer1_0_FILL">
                    <path
                        fill="#434343"
                        d="M292.9 225.45l-13.35-11.1-97.55.1-5.45 11q58.188 1.943 116.35 0z"
                    />
                    <path fill="#FFF" d="M0 105.45l1.3-.15-1.3.1v.05z" />
                    <path
                        fill="#020093"
                        d="M22.35 147.8V120l-4.55.6v28.6l4.55-1.4m16.1-30l-3.75.5v25.75l3.75-1.1V117.8z"
                    />
                    <path
                        fill="#910000"
                        d="M17.8 120.6l-9.5 1.3v30.15l9.5-2.85v-28.6z"
                    />
                    <path
                        fill="#00E0C1"
                        d="M8.3 121.9l-4.45.6v30.9l4.45-1.35V121.9z"
                    />
                    <path
                        fill="#484848"
                        d="M3.85 122.5L0 123v31.6l3.85-1.2v-30.9m41.3-5.6l-3.45.45v24.6l3.45-1.05v-24z"
                    />
                    <path d="M34.7 118.3l-3.75.5v26.4l3.75-1.15V118.3m20.25-2.75l-9.8 1.35v24l9.8-2.95v-22.4M376 224.75l-25.35-10.4h-60.7l14.65 10.4H376z" />
                    <path
                        fill="#005AD7"
                        d="M30.95 118.8l-8.6 1.2v27.8l8.6-2.6v-26.4z"
                    />
                    <path
                        fill="#E5FF00"
                        d="M41.7 141.95v-24.6l-3.25.45v25.15l3.25-1z"
                    />
                    <path
                        fill="#B6008B"
                        d="M57.25 115.25l-2.3.3v22.4l2.3-.7v-22z"
                    />
                    <path
                        fill="#2A2A2A"
                        d="M57.25 115.25H48.1L0 120.65V123l3.85-.5 4.45-.6 9.5-1.3 4.55-.6 8.6-1.2 3.75-.5 3.75-.5 3.25-.45 3.45-.45 9.8-1.35 2.3-.3z"
                    />
                    <path
                        fill="#F1D6F3"
                        d="M329.85 91.25q.1.05.25.05h16.55q.1 0 .2-.05.15-.1.25-.25l8.3-14.7-8.55-14.8H330.4l-8.15-14.1q-.1-.1-.2-.15-.1-.1-.2-.1h-16.6q-.1 0-.2.1-.1.05-.15.15l-8.15 14.1h-16.3l-8.05-14q-.15-.1-.2-.15-.1-.1-.25-.1h-16.5q-.15 0-.25.1-.1.05-.15.15l-8.15 14.15h-15.95l-8.15-14.2q-.1-.1-.2-.15-.1-.1-.25-.1H205.8q-.1 0-.2.1-.15.05-.2.15l-8.1 14.1h-16l-8.15-14.1q-.1-.1-.2-.15-.1-.1-.2-.1h-16.6q-.1 0-.2.1-.1.05-.15.15l-8.15 14.1h-16.3q-.15 0-.2.1-.15.05-.2.15l-8.3 14.35q-.1.1-.1.3 0 .1.1.2l8.3 14.35q.05.1.2.25.05.05.2.05h16.5q.2 0 .3-.05.1-.15.2-.25l8.15-14.1h15.85l8.25 14.15q.05.15.15.25.1.05.25.05h16.55q.1 0 .2-.05.15-.1.25-.25l8.1-14.15h15.95l8.2 14.2q.05.15.15.25.1.05.2.05h16.6q.15 0 .2-.05.1-.1.2-.25l3.35-5.9.05-.05 4.75-8.2h16.2l8.1 14q.05.1.2.25.05.05.2.05h16.5q.2 0 .3-.05.1-.15.2-.25l8.15-14.1h15.85L329.7 91q.05.15.15.25z"
                    />
                </g>
                <g id="PlaInd039__Layer1_5_FILL" fill="#C394FC">
                    <path
                        fillOpacity={0.4}
                        d="M30.95 118.8L0 123v31.6l30.95-9.4v-26.4m14.2 22.1v-24l-10.45 1.4v25.75l10.45-3.15m12.1-3.65v-22l-2.3.3v22.4l2.3-.7z"
                    />
                    <path
                        fillOpacity={0.141}
                        d="M29 79.7q-3.35.1-5.7 2.4-2.45 2.45-2.45 5.95t2.45 5.9q.7.7 1.5 1.25.35.2.75.4-1.7.55-2.35 1.2-1.65 1.8-1.65 2.9 0 1.15.95 1.6.65.25 1.85.2.15.65-.35 1.45-.45.7-.05 1.5H34q.4-.8-.05-1.5-.5-.8-.35-1.45 1.25.05 1.85-.2.95-.45.95-1.6 0-1.1-1.65-2.9-.65-.65-2.35-1.2.4-.2.75-.4.8-.55 1.5-1.25 2.45-2.4 2.45-5.9 0-3.5-2.45-5.95-2.35-2.3-5.65-2.4z"
                    />
                    <path
                        fillOpacity={0.271}
                        d="M9.7 79.4q-3.6.1-6.1 2.6Q.95 84.65.95 88.4q0 3.75 2.65 6.35.75.75 1.6 1.35.4.2.8.45-1.8.55-2.5 1.25-1.8 1.95-1.8 3.15 0 1.25 1.05 1.7.65.3 1.95.25.2.7-.35 1.55-.5.75-.05 1.6h10.8q.45-.85-.05-1.6-.55-.85-.35-1.55 1.3.05 1.95-.25 1.05-.45 1.05-1.7 0-1.2-1.8-3.15-.7-.7-2.5-1.25.4-.25.8-.45.85-.6 1.6-1.35 2.65-2.6 2.65-6.35 0-3.75-2.65-6.4-2.5-2.5-6.1-2.6z"
                    />
                </g>
                <g id="PlaInd039__Layer1_6_FILL">
                    <path
                        fill="#7238FF"
                        d="M155.6 76.45l-8.05-13.95H131.6l-8 13.95 8 13.85h16.05l7.95-13.85m198.8 0l-8.1-13.95h-15.9l-8.1 13.95 8.1 13.85h16l8-13.85z"
                    />
                    <path
                        fill="#944DFF"
                        d="M172.45 75.95l8-13.9-8-13.9H156.5l-8.1 13.95 8.1 13.85h15.95m149.1-27.85H305.6l-8.1 13.95 8.1 13.85h15.95l8-13.9-8-13.9z"
                    />
                    <path
                        fill="#BD66FF"
                        d="M173.2 76.5l8.1 13.85h16l8-13.85-8.1-13.95h-15.9l-8.1 13.95m99.5-.1l8 13.85h16.05l7.95-13.85-8.05-13.95H280.7l-8 13.95z"
                    />
                    <path
                        fill="#E483FF"
                        d="M222.1 48.15h-16l-8 14 8 13.85h16l8-13.9-8-13.95m49.65.05h-16l-8.05 14 8.05 13.8h16l8-13.85-8-13.95z"
                    />
                    <path
                        fill="#EFB8FF"
                        d="M254.95 76.5l-8.1-13.9h-15.9l-8.05 13.95 8.05 13.85h15.95l8.05-13.9z"
                    />
                </g>
                <linearGradient
                    id="PlaInd039__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={235.2}
                    y1={314}
                    x2={235.2}
                    y2={282.8}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#9058D2" />
                    <stop offset="100%" stopColor="#7137B6" />
                </linearGradient>
                <linearGradient
                    id="PlaInd039__Gradient_3"
                    gradientUnits="userSpaceOnUse"
                    x1={28.1}
                    y1={10.988}
                    x2={12.6}
                    y2={57.213}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FCF" />
                    <stop
                        offset="13.725%"
                        stopColor="#F9F"
                        stopOpacity={0.902}
                    />
                    <stop offset="100%" stopColor="#F9F" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaInd039__Gradient_4"
                    gradientUnits="userSpaceOnUse"
                    x1={213.6}
                    y1={14.725}
                    x2={213.6}
                    y2={66.475}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FCF" />
                    <stop
                        offset="13.725%"
                        stopColor="#F9F"
                        stopOpacity={0.902}
                    />
                    <stop offset="100%" stopColor="#F9F" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaInd039__Gradient_5"
                    gradientUnits="userSpaceOnUse"
                    x1={128.6}
                    y1={179.45}
                    x2={106.9}
                    y2={179.45}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="100%" stopColor="#001E4D" />
                </linearGradient>
                <linearGradient
                    id="PlaInd039__Gradient_6"
                    gradientUnits="userSpaceOnUse"
                    x1={85.65}
                    y1={222.15}
                    x2={107.05}
                    y2={209.75}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="100%" stopColor="#001E4D" />
                </linearGradient>
                <linearGradient
                    id="PlaInd039__Gradient_7"
                    gradientUnits="userSpaceOnUse"
                    x1={104.3}
                    y1={191.55}
                    x2={79.4}
                    y2={191.55}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="100%" stopColor="#001E4D" />
                </linearGradient>
                <radialGradient
                    id="PlaInd039__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={148.825}
                    fx={0}
                    fy={0}
                    gradientTransform="matrix(1 0 0 .5394 234.3 162.35)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#5FE7FF" />
                    <stop offset="100%" stopColor="#652BAA" />
                </radialGradient>
            </defs>
            <use xlinkHref="#PlaInd039__Layer4_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer4_1_FILL" />
            <use xlinkHref="#PlaInd039__Layer4_2_FILL" />
            <use xlinkHref="#PlaInd039__Layer4_3_FILL" />
            <use xlinkHref="#PlaInd039__Layer4_4_FILL" />
            <use xlinkHref="#PlaInd039__Layer3_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer3_1_FILL" />
            <use xlinkHref="#PlaInd039__Layer3_2_FILL" />
            <use xlinkHref="#PlaInd039__Layer3_3_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_1_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_1_MEMBER_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_2_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_2_MEMBER_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_2_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_2_MEMBER_0_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_2_MEMBER_0_MEMBER_1_FILL" />
            <use xlinkHref="#PlaInd039__Layer2_3_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_1_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_1_MEMBER_0_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_2_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_3_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_4_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_5_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_6_FILL" />
            <use xlinkHref="#PlaInd039__Layer1_7_FILL" />
        </g></g>
    )
}
export default PlaInd039
