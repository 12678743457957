import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999"
}

function BodMor004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.6 15.4q-2.4 0-4.45-.65 3.4 8.55 9.5 15.3 1.85 2.1 4 4-1.65-2-3.05-4-5-7.3-6-14.65m-9.2-3.1v-.05Q3.65 11.65 3 11 1.1 9.1 0 6.9q5.3 17.05 14.05 23.15.5.35 1 .65-.3-.3-.6-.65Q6.6 21.3 4.4 12.3M25.15 10q-.45.5-.95 1-1.35 1.35-2.9 2.3l-.05.05q-.1.05-.15.1l-.8.4q-.4.2-.75.35l-.2.1Q23.8 25.9 29.5 30.05q1.3.95 2.65 1.5-.8-.75-1.5-1.5-7.5-8-5.5-20.05m6.8-9.9q-.5.9-1.25 1.65-.95.9-2 1.45l-.25.4q6.35 3.65 8.95 14.8Q38 7.55 31.95.1m126.2 15.2h-.05q-1 7.35-6 14.65-1.4 2-3.05 4 2.15-1.9 4.05-4 6.05-6.75 9.5-15.3-2.1.65-4.45.65M143.3 3.5l-.3-.4q-1.05-.55-1.95-1.45-.8-.75-1.25-1.65-6.1 7.45-5.5 18.3 2.6-11.15 9-14.8m4.2 7.4q-.5-.5-.9-1 1.95 12.05-5.5 20.05-.75.75-1.55 1.5 1.35-.55 2.65-1.5 5.7-4.15 10.15-15.75-.1-.05-.15-.1-.4-.15-.75-.35-.45-.2-.8-.4-.1-.05-.15-.1l-.1-.05q-1.55-.95-2.9-2.3m24.2-4.1q-1.1 2.2-2.95 4.1-.7.65-1.45 1.25v.05q-2.2 9-10.05 17.75-.3.35-.6.65.5-.3 1-.65 8.75-6.1 14.05-23.15z"
                    id="BodMor004__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodMor004__Symbol_7_0_Layer0_0_FILL"
                transform="translate(106.65 294.8)"
                id="BodMor004__col1n"
            />
        </g></g>
    )
}

export default BodMor004
