import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}

function AccGla021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M62.15 10.95q-.05-.35-.05-.55-.85-5.1-4.25-4.8L3.1 6.95q-3.3.1-3.1 3.35l2.65 49.9q-.1 3.35 4.45 2.7l54.3-4.3q2.5-.2 2.5-4.65l-1.75-43m67.65 47.3q3.6-.05 3.15-4.55L131.2 4.65q-.65-5-5.05-4.65L71 4.95q-4.35.2-3.95 4.25l3.1 44.7q.05 5 5.35 4.7l54.3-.35z"
                    id="AccGla021__Symbol_110_0_Layer0_0_FILL"
                />
                <path
                    d="M41.6 33.85q3-2.75 3-7.05 0-4-2.75-7-3-2.75-7.05-3-4.25 0-7 2.75-3.25 2.75-3.25 7 0 4.05 2.75 7.05 3 3 7 3 4.3 0 7.3-2.75M50.1 4.5l-.25-.25-49.6 2Q0 6.5 0 6.75L2.25 48.1h.5l42.1-2.75.25-.25h.25v-.25q0-.25-.25-.25l-.25-.25-41.85 3L1 7l48.6-1.75q.25 0 .25-.5.25 0 .25-.25m42.85 18.55q0-4-2.75-7-2.75-2.75-7-2.75-4.05-.25-7.05 2.5-3 3-3.25 7 0 4.25 3 7.3 2.75 2.75 7.05 2.75 4 .25 7-2.75 3-2.8 3-7.05m19.3-22.8Q112 0 111.75 0L63.4 3.75q-.25 0-.5.25v.5h.5L111.25 1l2 39.85-42.1 2-.25.25q-.25 0-.25.25l.5.5 42.6-2h.5q.25-.25.25-.5L112.25.25z"
                    id="AccGla021__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M191.85 24.1l-25-6.7v-.05l-1.05-.3-.5-14.45q-.75-2.85-4.05-2.6L101.8 5.45q-4.35.65-5.05 3.6 0 .1-.05.3l.5 14.5-2.7.05-.55-14.7Q92.95 6 90.3 6L29.95 7q-.55-.05-1 0-4.75.05-4.15 4.15l.6 14.7L0 35.5l1.45 6.1 24.6-1 1.15 26.6q.1 5.3 5.85 4.2l58.85-4.7q3.6-.35 4.1-3.3l-1.25-28.5 3.7-.05L99.8 62.5q.1.65.35 1.2 1 2.5 3.8 2.4l58.9-1.25.8-.1q3.75-.6 3.7-4.45l-1-28.85 25.4-1.2.1-6.15m-38.7-16.95q4.05-.3 4.65 4.2l1.6 44q.4 4.05-2.9 4.1l-50.05.3q-4.85.25-4.9-4.2L98.7 15.4q-.4-3.6 3.6-3.8l50.85-4.45m-65.65 4.9q3.25-.25 4.05 4.45 0 .2.05.5l1.65 39.65q0 4.1-2.4 4.3L39.5 64.9q-4.3.6-4.2-2.5l-2.5-46q-.2-3 2.9-3.1l51.8-1.25z"
                    id="AccGla021__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla021__Symbol_110_0_Layer0_0_FILL"
                transform="translate(127.4 132.55)"
                id="AccGla021__col2n"
            />
            <use
                xlinkHref="#AccGla021__Symbol_58_0_Layer0_0_FILL"
                transform="translate(135.7 140.05)"
            />
            <use
                xlinkHref="#AccGla021__Symbol_44_0_Layer0_0_FILL"
                transform="translate(96.4 128)"
                id="AccGla021__col1n"
            />
        </g></g>
    )
}

export default AccGla021
