import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000"
}

function AccMor039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M62.55 36q-1.3-2-2.85-3.8l-.6-.7q-.5-.55-1-1.05-2.2-2-8.6-6.1-1.1-.65-2.15-1.3-2.9-1.75-5.6-3.6-12.6-8.6-20.8-18.95-.2-.2-.35-.4-3.55 1.1-5.85 2.7-1.2.8-2.45 2.5-1.75 2.35-2.15 2.8-2.3 2.35-5 3.7-1.9.95-4.45 1.65-.3.1-.45.2-.15 0-.2.05-.4 2.1 4.8 2.75 8.9.6 17.2 3.05 4.4 1.25 8.65 3.05l3.45 1.6q2 1 4 2.05.15.1.35.2 4.85 2.6 9.3 5.65 11.5 7.8 20.3 18.1l.1.3q-1.5-7.95-5.45-14.15l-.2-.3m107.6-16.5q8.3-2.45 17.2-3.05 5.1-.65 4.85-2.65-3.05-.55-6.25-2.5-3.25-1.95-4.65-4.25-1.4-2.35-3.55-3.8-2.15-1.45-6.1-3.25l-.4.5q-8.2 10.35-20.8 18.95-2.7 1.85-5.6 3.6-1.05.65-2.15 1.3-6.4 4.1-8.6 6.1-.5.5-1 1.05l-.6.7q-1.55 1.8-2.85 3.8l-.2.3q-3.95 6.2-5.45 14.15l.1-.3q8.8-10.3 20.3-18.1 4.45-3.05 9.3-5.65.2-.1.35-.2 2-1.05 4-2.05l3.45-1.6q4.25-1.8 8.65-3.05z"
                    id="AccMor039__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M62.5 36.2q-1.3-2-2.85-3.8l-.6-.7q-.5-.55-1-1.05-2.2-2-8.6-6.1-1.1-.65-2.15-1.3-2.9-1.75-5.6-3.6Q29.1 11.05 20.9.7q-.3-.35-.55-.7-6.5 2.05-9.4 6.4l-.1.1q-2.9 3.9-6.5 5.5Q.7 13.6 0 13.9q.4.3 8.9.55 6.6.15 13.1 2.1 1.95.55 3.85 1.3 8.45 3.2 10.7 4.3l2.3 1.1q20.5 10.35 28.7 25.85.25.6.5 1.25l.1.3q-1.5-7.95-5.45-14.15l-.2-.3M187.75 12q-3.6-1.6-6.5-5.5l-.1-.1q-2.9-4.35-9.4-6.4-.25.35-.55.7-8.2 10.35-20.8 18.95-2.7 1.85-5.6 3.6-1.05.65-2.15 1.3-6.4 4.1-8.6 6.1-.5.5-1 1.05l-.6.7q-1.55 1.8-2.85 3.8l-.2.3q-3.95 6.2-5.45 14.15l.1-.3q.25-.65.5-1.25 8.2-15.5 28.7-25.85l2.3-1.1q2.25-1.1 10.7-4.3 1.9-.75 3.85-1.3 6.5-1.95 13.1-2.1 8.5-.25 8.9-.55-.7-.3-4.35-1.9z"
                    id="AccMor039__Symbol_81_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor039__Symbol_137_0_Layer0_0_FILL"
                transform="translate(96.4 212.7)"
                id="AccMor039__col1n"
            />
            <use
                xlinkHref="#AccMor039__Symbol_81_0_Layer0_0_FILL"
                transform="translate(96.45 212.5)"
                id="AccMor039__col1d"
            />
        </g></g>
    )
}

export default AccMor039
