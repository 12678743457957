import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function ShiSpe074({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.7 0h-.05l-4 3.05-.05.05-1.35 1-4.45 31.8H11.6L7.15 4.1l-1.35-1-.05-.05-4-3.05H1.7l4.7 35.4-2.2 12.55-.1 12.25H1.45L0 66.7h4.1v24.8q58.2 6.55 115.2 0V66.7h4.1l-1.45-6.5h-2.65l-.1-12.25L117 35.4 121.7 0z"
                    id="ShiSpe074__Symbol_342_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.1 32v-6.5H0V32h1.1M8.45 0H3.3l.1.7-.05.5H8.6L8.45 0M116.3 25.5V32h1.1v-6.5h-1.1M93.55 45.3V24.8h-69.7v19.8h.1v.7h69.6m-1.1-19.4v18.3h-67.5V25.9h67.5M114.1 1.2l-.1-.5.1-.7H109l-.2 1.2h5.3z"
                    id="ShiSpe074__Symbol_344_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe074__Symbol_342_0_Layer0_0_FILL"
                transform="translate(130.8 228.6)"
                id="ShiSpe074__col1n"
            />
            <use
                xlinkHref="#ShiSpe074__Symbol_344_0_Layer0_0_FILL"
                transform="translate(133.8 263.3)"
                id="ShiSpe074__col1d"
            />
        </g></g>
    )
}

export default ShiSpe074
