import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1l": "#BFBFBF",
    "col1n": "#999999",
    "col2d": "#747474",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjToo026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.2 41.1l-.1.55v.15l3.1 5.5h-.05l1.35 2.4v-.15L26.4 53h-.05l1.9 3.4q.05 0 .1.05l.5.35q6.85.4 8.9-5.1v-.55q0-.05.05-.05v-.05L30.25 37.6q-1.5.7-3.25.7-3.15 0-5.4-2.25-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5M11.65 4.65V4.6L9.55.85 7.95 0 7.6 1.45Q4.55 3.6 1.4 4.9L.25 4.35l-.2 1.7L0 6.2l10.95 19.35q1.35.2 2.85.7 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.55-.55 1.15-.95L13.6 8v.05l-1.95-3.4z"
                    id="ObjToo026__Symbol_186_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M67.3 103.3l-.05.05q-1.1 1.25-2.2 2.25-.3.2-.55.45-1.6 1.2-3.5 1.85-1.55.55-3.25.7v.15q-.5 2.6 1.85 3.3h.1q1.7-.15 3.25-.7 1.9-.65 3.5-1.85.25-.25.55-.45l1.05-1 1.1-1.3.05-.05q-.15-2.9-1.9-3.4M46.35 72.5q-.3.2-.55.45-1.6 1.2-3.5 1.85-1.55.55-3.25.7-.55 2.8 1.95 3.45 1.7-.15 3.25-.7 1.9-.65 3.5-1.85.25-.25.55-.45 1.15-1.05 2.2-2.35-.15-2.9-1.9-3.4-1.1 1.25-2.25 2.3m-11-3.55q-.7 2.8 1.95 3.45 1.75-.15 3.3-.7 1.9-.65 3.5-1.85.25-.25.55-.45 1.15-1.05 2.2-2.35.35-2.5-1.95-3.4-1.05 1.25-2.2 2.3-.3.2-.55.45-1.6 1.2-3.5 1.85-1.55.55-3.3.7M7.8 0q-.65.45-1.25.65T4.7 1.1q-1.3.2-2.35 1.3T.15 4.3L0 4.25l33.5 59v.15l1.15.55q3.15-1.3 6.2-3.45l.35-1.45-.05-.05v.05L7.8 0z"
                    id="ObjToo026__Symbol_150_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M36.95 52.25q-.65.7-1.35 1.35-.3.2-.55.45-1.6 1.2-3.5 1.85-1.2.4-2.45.6h-.75l3.1 1.95q3.6-.6 6.3-3.5V51.2q-.15.2-.35.4-.2.35-.45.65M6.4 1.55q-.3.25-.55.45-1.6 1.3-3.45 1.95-.75.25-1.55.4-.5.05-.6-.1L.05 6.1 0 6.25q1.75-.1 3.3-.65 1.9-.65 3.5-1.85.25-.25.55-.45Q8.5 2.25 9.55.9L7.95.05 7.9 0v.05q-.2.15-.35.3Q7 1 6.4 1.55z"
                    id="ObjToo026__Symbol_126_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M42.85 12.05l-.3-.5Q41.35 9.4 39.9 7.8q-.7-.85-1.4-1.5l-.3 2.9-.45-.05-.65-.65Q34.45 5.95 31.65 4q-1.9-1.35-3.35-2.05l1.55-.8q-.3-.05-.6-.15Q29 .95 28.8.9 25.6 0 22.55 0q-.95 0-2 .1-1.5.2-3.1.5-.25.15-.55.2h-.05q-.7.2-1.4.45-1.15.5-2.1.95-1.25.55-2.45 1.25-1.05.7-2.05 1.4-.8.5-1.55 1.15h-.05q-.2.25-.45.4-.75.7-1.45 1.45Q4.35 9 3.5 10.3 1.8 12.9.8 16.05q-.1.25-.15.4-.1.35-.15.6l2.8-1.7 1.3 3.45q-2.45 4-2.45 8.05 0 .1.05 1.4L0 27.15q.2.95.5 2 .6 2.05 1.7 4.2l.3.5q1.2 2.2 2.7 4.05.05.05.1.15l.7.8.05.05Q7.7 40.65 9.6 42q.85.5 1.75 1 .95.5 2.05.95 1.25.6 2.65.95.2.1.35.15l3.8-1.75 6.4 2.3q.05 0 .2-.05.3-.05.55-.1.6-.1 1.15-.2l2.2 3.9 2.45 3.1q.05.1.15.2-.35 0-.65.05h-.1l2.5 4.5q-.15-.3-.25-.6l.45.8h.05q.4-.05 2.85.1 2.45.15 3.95-1.5t2-2.85v-.05q.45-.6.7-1.05l-2.6-4.65q-1.05-.2-2.65-2.6.35-.4.7-.85l-2.15-3.8q.35-.4.7-.75.3-.2.5-.4l.1-.1.55-2.2q.75-.9 1.4-2 1.25-2.1 1.8-3.8l1.35-.9q.1-.15.15-.35.4-1.35.65-2.75.2-1.2.25-2.25 0-.2.05-.4.05-.8.05-1.55 0-2.4-.6-4.7l-.05-.05q-.15-.55-.25-1.05-.05-.1 0-.2-.7-2.25-1.9-4.45z"
                    id="ObjToo026__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M41.6 52.55l.05.05.85-11.7-7.55-.85q1.25 7.2 6.65 12.5m-19.3 1q-1.1-.45-2.05-.95-1.55.95-1.2 2.7l3.25-1.75m17.3 5.1v.1q-.65 2.65 1.85 3.35h.1q1.75-.15 3.3-.7 1.9-.65 3.5-1.85.25-.25.55-.45 1.15-1.05 2.2-2.3v-.05q.35-2.5-1.95-3.4-1.05 1.25-2.2 2.3-.3.2-.55.45-1.6 1.2-3.5 1.85-1.55.55-3.3.7m-9.5 6.65l.1-.15q.7-6.1-1.1-12.25l-3.8 1.75q.05 0 .2.05l4.6 10.6M15 48.4l-.05.05 9.3.75 2.3-7Q19.4 43.65 15 48.4M2.75 47.25l11.35.25q-1.5-1.85-2.7-4.05l-.3-.5q-4.65 0-8.35 4.3m11.95-15.7l-2.5-6.6L0 32.25q7.45 1.6 14.7-.7M12.4 19.9Q9.5 15.85 4 15.15l5.7 10.5q1-3.15 2.7-5.75m23.3-7.65l4.2 5.65Q45.75 14 48.5 6l-12.8 6.25m-4.6 7.55l-10.3-5.75v.15q1.4 7 6.85 12.35l3.45-6.75m6.6-9.3L32 0q-2.1 4.8-.55 9.6 3.05 0 6.25.9m15.7 29.1q.05-.1 0-.2l-3.5 2.25q3.95 5.3 9.65 7.6h.15l-6.3-9.65m-11.1-5.95l7.15 1.9 4.4-8.15q-6.8 1.55-11.55 6.25m12.15.4q-.05 1.05-.25 2.25l3.25-1.8q-1.3-.7-3-.45m5.15-17.6q-4.85 1.2-8.15 4.7l.3.5q1.2 2.2 1.9 4.45l5.95-9.65z"
                    id="ObjToo026__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M44.2 66.8l1.75 1.4.15.05q1.25-.1 2.45-.45 1.9-.65 3.5-2 .25-.25.55-.5.6-.65 1.15-1.35l-.05-2.5q-.25.45-.7 1.05v.05q-.95.85-1.9 1.6-2.75 2-6 2.5-.5.1-.9.15m-14-1.65l5.45-9.95h-.15l-6.4-2.3 1.1 12.25M19.05 55.3l1.2-2.7q-.9-.5-1.75-1v4l.55-.3m12.7-8.8l.05.05 9.8 6-6.65-12.5h-.1l-3.1 6.45M27.6 26.6l.05-.05L20.8 14.2l-.3 11.5.05.05 7.05.85m-8.4 10.55L15 48.4l11.55-6.2-7.35-5.05m-7.65.9l2.95-6.5-14.5.7 11.55 5.8M2.7 47.2l.05.05 8.35-4.3q-1.1-2.15-1.7-4.2L2.7 47.2m11.55-29.75q.7-.75 1.45-1.45.25-.15.45-.4L4 15.15l8.4 4.75q.85-1.3 1.85-2.45m11.5-7.05h.05q.3-.05.55-.2 1.6-.3 3.1-.5 1.05-.1 2-.1L32 0l-6.25 10.4M49.9 41.65l-1.6 6.65v.1l11.25.85-9.65-7.6m-7.75-16.9l.15 8.9 11.55-6.25-11.7-2.65m12.3 9.3l3 .45.6-.3-3.5-2.1q0 .75-.05 1.55-.05.2-.05.4M47.1 18.8L48.5 6 40 17.75l7.1 1.05m12.55-2.4l-10.85 1q1.45 1.6 2.65 3.75l8.15-4.7.05-.05z"
                    id="ObjToo026__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo026__Symbol_186_0_Layer0_0_FILL"
                transform="translate(104.95 260.95)"
                id="ObjToo026__col1n"
            />
            <use
                xlinkHref="#ObjToo026__Symbol_150_0_Layer0_0_FILL"
                transform="translate(71.7 201.9)"
                id="ObjToo026__col1l"
            />
            <use
                xlinkHref="#ObjToo026__Symbol_126_0_Layer0_0_FILL"
                transform="translate(104.95 260.9)"
                id="ObjToo026__col1d"
            />
            <use
                xlinkHref="#ObjToo026__Symbol_67_0_Layer0_0_FILL"
                transform="translate(34.65 147.55)"
                id="ObjToo026__col2n"
            />
            <use
                xlinkHref="#ObjToo026__Symbol_31_0_Layer0_0_FILL"
                transform="translate(25.75 137.95)"
                id="ObjToo026__col2l"
            />
            <use
                xlinkHref="#ObjToo026__Symbol_14_0_Layer0_0_FILL"
                transform="translate(25.75 137.95)"
                id="ObjToo026__col2d"
            />
        </g></g>
    )
}

export default ObjToo026
