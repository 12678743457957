import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMak003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47 9q-.8-1-1.55-1.85-1.4-1.55-2.8-2.7Q37.3.3 30.35.05q-1.5-.1-2.95 0-2.9.25-5.65 1-7.6 2.15-13.8 8.9-3.15 3.4-5.2 7.2Q1 20.4 0 23.95q3.15-6.45 7.35-11Q15.15 5.5 22.8 3.3L24 3q1.65-.35 3.25-.5.15-.05.3-.05Q38.6 1.55 47 11.5q3.15 3.5 4.55 8.25l-.2-1.1q-.2-.75-.3-1.5-.1-.15-.1-.25-.4-1.4-.9-2.6Q48.85 11.5 47 9m56.6 15q-.95-3.6-2.7-6.85-2.05-3.8-5.2-7.2-6.2-6.75-13.8-8.9-2.75-.75-5.65-1-1.45-.1-2.95 0Q66.35.3 61 4.45q-1.4 1.15-2.8 2.7Q57.45 8 56.65 9q-1.85 2.5-3.05 5.3-.5 1.2-.9 2.6 0 .1-.1.25-.1.75-.3 1.5l-.2 1.1q1.4-4.75 4.55-8.25 8.4-9.95 19.45-9.05 3.55.3 7.35 1.65 9.6 4 16.35 13.25 2.05 3.05 3.8 6.65z"
                    id="SkiMak003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak003__Symbol_3_0_Layer0_0_FILL"
                transform="translate(140.7 135.4)"
                id="SkiMak003__col1n"
            />
        </g></g>
    )
}

export default SkiMak003
