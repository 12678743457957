import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#FFD95B",
    "col2n": "#FFC400"
}

function AccJew002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M122.8 7.15l3.6-2.9L123.5 0l-.1.1-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L7.95 3.6Q6.5 2.55 5.15 1.5L3.2.05 3.15.1 2.9.45l-.1.1L0 4.3q2.3 2.35 6.2 4.95 5.5 4.15 17.85 9.45.65.25 1.35.55l1.4.65q4.4 1.9 9 3.35.35.1.65.2.15.05.25.1 9.9 2.8 19.7 3.5l2.1.1q1.1.05 2.15.1 14.2.45 28.7-3.65.1-.05.25-.1.3-.1.65-.2 4.6-1.45 9-3.35l1.4-.65q.7-.3 1.35-.55 3.65-1.6 7-3.2l1.9-1q6.7-3.5 11.9-7.4m-58.5 16.5q.05.05.1.15l-.1-.1v-.05z"
                    id="AccJew002__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.4 10.65l2.95-2.5L13.7 6.8l.65-3.85L11 4.1 8.7 0 6.55 4.1 3 2.95l.7 3.85L0 8.15l3 2.5-1.9 3.4 3.85.05.7 3.85 3-2.5 3.05 2.5.7-3.85 3.9-.05-1.9-3.4z"
                    id="AccJew002__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.85 8.6Q0 9.8 0 11.3q0 1.95 1.4 3.35 1.4 1.4 3.4 1.4 1.95 0 3.35-1.4 1.4-1.4 1.4-3.35 0-2-1.4-3.4T4.8 6.5q-1.6 0-2.85.95-.3.2-.55.45-.35.35-.55.7m6.3-6.45v-.1Q6.95-.2 4.1 0H4q-.15.1-.35.2L3.9.05Q2.4.55 2.4 2h.05q.05 1.95.05 3.9l.2.05 1.95-3.3 2 3.3.35-.1V4.2q.05-1.05.15-2.05z"
                    id="AccJew002__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew002__Symbol_73_0_Layer0_0_FILL"
                transform="translate(129.15 228.3)"
                id="AccJew002__col1n"
            />
            <use
                xlinkHref="#AccJew002__Symbol_22_0_Layer0_0_FILL"
                transform="translate(183.85 249.35)"
                id="AccJew002__col2n"
            />
            <use
                xlinkHref="#AccJew002__Symbol_11_0_Layer0_0_FILL"
                transform="translate(187.85 247.6)"
                id="AccJew002__col2l"
            />
        </g></g>
    )
}

export default AccJew002
