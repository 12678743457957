import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#C600C6"
}

function ShiSho071({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M84.2.2q-.45.1-.95.25V11.2L63.6 32.15H51.55L31.9 11.2 31.95.3l-1-.3-.05 11.2-19.6 20.95-1.65-.9v-.05l-8.7-4.75q-.1-.05-.75-.4l-.2 1q.4.25.5.3l10.6 5.8q.05.05.2.1h40.1q.15 0 .3-.05h11.8q.1.05.25.05h40.15l.2-.1 10.35-5.8q.15-.1.75-.55.05-.05.45-.25l-.15-.8q-1.4.65-1.5.7l-8.4 4.75v.05l-1.7.9L84.25 11.2 84.2.2z"
                    id="ShiSho071__Symbol_191_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho071__Symbol_191_0_Layer0_0_FILL"
                transform="translate(134.6 245.95)"
                id="ShiSho071__col1n"
            />
        </g></g>
    )
}

export default ShiSho071
