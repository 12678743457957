import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999",
    "col2d": "#E2C700",
    "col2n": "#F3E200"
}

function ObjMus028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.9.25q-.1-.15-.25-.2-.15-.1-.3 0-.15.05-.2.2L.05 54.15q-.1.15 0 .3.05.15.2.25.15.05.3 0 .15-.1.25-.25L22.9.55q.05-.15 0-.3m3.4 1.3q-.15-.1-.3 0-.15.05-.2.2L3.7 55.65q-.1.15 0 .3.05.15.2.25.15.05.3 0 .15-.1.25-.25l22.1-53.9q.05-.15 0-.3-.1-.15-.25-.2M29.55 3q-.15.05-.2.2L7.15 57.3q-.1.15 0 .3.05.15.2.25.15.05.3 0 .15-.1.25-.25L30.1 3.5q.05-.15 0-.3-.1-.15-.25-.2-.15-.1-.3 0m3.6 1.5q-.15.05-.2.2l-22.1 53.9q-.1.15 0 .3.05.15.2.25.15.05.3 0 .15-.1.25-.25L33.7 5q.05-.15 0-.3-.1-.15-.25-.2-.15-.1-.3 0M37.1 6q-.15-.1-.3 0-.15.05-.2.2L14.45 60.3q-.1.15 0 .3.05.15.2.25.15.05.3 0 .15-.1.25-.25L37.35 6.5q.05-.15 0-.3-.1-.15-.25-.2m3.8 2q.05-.15 0-.3-.1-.15-.25-.2-.15-.1-.3 0-.15.05-.2.2l-22.1 53.9q-.1.15 0 .3.05.15.2.25.15.05.3 0 .15-.1.25-.25L40.9 8m3.65 1.4q.05-.15 0-.3-.1-.15-.25-.2-.15-.1-.3 0-.15.05-.2.2L21.7 63q-.1.15 0 .3.05.15.2.25.15.05.3 0 .15-.1.25-.25l22.1-53.9z"
                    id="ObjMus028__Symbol_112_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M76.1 22.8q-.15-.35-.35-.75-3-4.6-8.9-3.6-3.45.8-5.75 4.15L32.05 10.8q.65-4-1.35-7Q27.25-.95 21.85.2q-.35.15-.65.3-2.9 1.2-4.05 4-1.15 2.85.1 5.65 1.25 2.85 4.1 4 .7.25 1.55.45h.05l-.45.95h-.1L6.55 36.05q-3.7 5.3-5.8 10.25-.45 1-.75 1.9 1.3.3 2.1.95 2.3 1.4 3.5 4.35 1.5 3.55 1.75 4.35.7 2.15.7 4.35 0 2.3-1.3 5.4-1.15 2.7-2.85 3.9-1 .7-2.2.85.85 1.45 1.95 2.9 4.7 6.05 11.05 9.55 1.3.7 2.65 1.3l1.8.75q1.4.5 2.85.9 7.05 1.95 14.55.85 16.6-2.55 22.75-20.05 1.65-4.55 2.55-10.1l3-26.55q.05 0 .05.05l.05-.3.1.05H65q.75.45 1.45.75 2.85 1.15 5.7 0 2.85-1.2 4-4.05 1.15-2.8-.05-5.6m-16.85 3.25Q58 29.3 57.8 34.1l-1.6 20.55q-2.65 18.6-17.4 21.4-.3 0-.55.1-.35.05-.75.1-.8.1-1.55.1l-.05.1h-.55q-.25 0-.45.05-.45 0-.9-.05-1 0-1.9-.05v-.05h-.6l-.05.05-.7-.1q-1.1-.15-2.25-.35v-.1q-.25 0-.55-.1l-.05.05-1-.2q-.9-.25-1.8-.55-.05 0-.15-.05l-.45-.15q-.048 0-.05.05v-.1q-.65-.15-1.3-.4-.6-.3-1.2-.55l-.1-.05q-.2-.15-.45-.25h-.1q-.85-.45-1.7-.95-.45-.25-.85-.5l.05-.05q-.25-.1-.5-.35v.1q-1-.65-1.95-1.3l-.5-.4.05-.05q-.25-.25-.5-.45v.05q-.75-.65-1.4-1.3-.35-.35-.75-.7l-.2-.2H13q-.2-.25-.45-.45l.05-.1q-.5-.55-1-1.15-.2-.3-.45-.6-.2-.25-.35-.45-8.65-12.25 2.4-27.5l13.2-15.85q3.25-3.6 4.6-6.85l28.25 11.55z"
                    id="ObjMus028__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M2.2 67.85q-1 .7-2.2.85.85 1.45 1.95 2.9 4.7 6.05 11.05 9.55 1.3.7 2.65 1.3l1.8.75q1.4.5 2.85.9 7.05 1.95 14.55.85Q51.45 82.4 57.6 64.9q-7.85 17.4-24.5 17.65-16.6.2-27.9-11.25-1.65-1.7-3-3.45m22-62.45q-.05-.15-.05-.35l-.1.2-.05.1v.05l-.2.4q-.05.05-.15.2-1.85 1.1-3.45-.05-1.8-1.6-.75-3.55 1.8-2.9 5.65-.7 2.95 2 1.1 6.2h-.05q-1.55 2.65-4.95 3.05h.05l-.45.95q1.95.1 3.25-.65.1-.1.2-.1 2.5-1.7 3.25-4 .7-2.3-.1-4.2-.85-1.9-3.05-2.6-2.2-.75-3.9.05-1.75.8-2.2 2.9-.05.3-.05.55-.1.55.1 1.35.3.8.85 1.35.1.1.25.3l.4.3q.3.2.7.35 1.05.45 2.1.1.15-.05.35-.15 1.35-.8 1.25-2.05m34.55 13.25q-.35.25-.6.55-.4.4-.75 1.2l-.3.5-26.6-10.8.2-.6h.05q.2-.85.25-1.4v-.65l.05-.05-.7-.25q-.15 1-.45 2.05-.05.3-.2.6-.1.25-.2.55l-.2.5L57.55 22.4l.2-.6q.1-.3.25-.5.15-.3.25-.6.55-.95 1.15-1.75l-.65-.3m3.15 3.75l-.05-.05q1.55-4.3 5.05-3.7 4.3 1.1 3.55 4.4-.6 2.15-3 2.05-2-.25-2.45-2.35 0-.15.05-.25l.05-.45V22l.05-.1.1-.2q-.15.15-.3.25-.9.85-.55 2.35.05.15.25.35.45.95 1.5 1.4.4.15.8.2.2 0 .5.05h.35q.85-.05 1.5-.45.75-.4 1.05-.85.15-.2.3-.45 1.15-1.8.5-3.5-.65-1.75-2.65-2.8-2.05-1.05-3.9-.2-1.9.85-2.7 2.3Q60.3 23 60.95 26v.2q.5 1.55 2 2.85v-.1l.25-.95h.05q-2.1-2.6-1.35-5.6z"
                    id="ObjMus028__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus028__Symbol_112_0_Layer0_0_FILL"
                transform="translate(126.1 252.5)"
                id="ObjMus028__col1n"
            />
            <use
                xlinkHref="#ObjMus028__Symbol_38_0_Layer0_0_FILL"
                transform="translate(114 237.35)"
                id="ObjMus028__col2n"
            />
            <use
                xlinkHref="#ObjMus028__Symbol_17_0_Layer0_0_FILL"
                transform="translate(115.7 241)"
                id="ObjMus028__col2d"
            />
        </g></g>
    )
}

export default ObjMus028
