import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999",
    "col2l": "#EEEEEE",
    "col2n": "#D9D9D9"
}

function ObjToo038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M83.6 13.15q3.95-1.3 7.05-.6L79.15 0Q79.6 3.2 78 7.05L67.35 16.8q-2.3 1.5-4.7.35l-28.6 26.3-1-1.05q-3.7-3.95-6.55-7.3-1.85-2-3.4-3.9-11.5-13.7-11-18L0 24.3q8.05 4.1 17.4 12.15 1.85 1.6 3.85 3.5 3.95 3.45 8.05 7.85l-.1.65-6.85 12.75 16.35-3.05h.1l.1.1q2.75 3.05 5 5.9 11.55 14.05 15.85 25.3l12.1-11.1q-5.25.3-23-18.75-2.05-2.15-4.3-4.7l-1-1.1 28.6-26.3q-.85-2.4.8-4.6l10.65-9.75z"
                    id="ObjToo038__Symbol_198_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9.5 36.65l5.75-5.25-9.5-10.35L0 26.3l9.5 10.35M13.4 14l-6.3 5.8 9.5 10.35 6.3-5.8L13.4 14m10.85 9.1l6.7-6.15-9.5-10.35-6.7 6.15 9.5 10.35M38.1 10.35L28.6 0l-5.8 5.35 9.5 10.35 5.8-5.35z"
                    id="ObjToo038__Symbol_131_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M151.5 11.25L153.35 0 9.1 132.4l-.5 1.45L0 150.3l17.7-6.55 145.15-133.4-11.35.9z"
                    id="ObjToo038__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M148.55 1.9l-2.1.15.35-2.05L7.05 128.45q-14.95 14.95 1.75 1.9L148.55 1.9z"
                    id="ObjToo038__Symbol_37_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo038__Symbol_198_0_Layer0_0_FILL"
                transform="translate(264.2 134.4)"
                id="ObjToo038__col1n"
            />
            <use
                xlinkHref="#ObjToo038__Symbol_131_0_Layer0_0_FILL"
                transform="translate(298.25 151.55)"
                id="ObjToo038__col1d"
            />
            <use
                xlinkHref="#ObjToo038__Symbol_78_0_Layer0_0_FILL"
                transform="translate(140.15 182.2)"
                id="ObjToo038__col2n"
            />
            <use
                xlinkHref="#ObjToo038__Symbol_37_0_Layer0_0_FILL"
                transform="translate(145.2 191.4)"
                id="ObjToo038__col2l"
            />
        </g></g>
    )
}

export default ObjToo038
