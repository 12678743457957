import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#FFE000",
    "col2n": "#FFFF00"
}

function PetPet027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M142.35 42.85q0-14.7-8.65-25.85-1.85-2.35-4-4.5Q117.2 0 99.4 0 81.65 0 69.05 12.5q-1.75 1.75-3.25 3.65-.35.45-.7.85-8.55 11.15-8.55 25.85 0 8.45 2.95 15.7 1.7 4.55 4.65 8.7l.15-.05v.05q-.05.05-.05.1L65.4 86.6 24.05 90v15.1h-.2q-.2.05-.45.1h-5.2q-.85-.1-1.4-.2-2.35-.35-3.5-.75t-1.95-.65q-.8-.25-1.9-.6-1.05-.3-1.55-.4-.5-.15-2.45.35-1.95.5-2.75 2.15-.6 1.1-.6 2.45 0 1.1.45 2.45.45 1.4.45 2.3v.35q-.1.2-.2.45-.3 1.05-1.55 4.55-1.25 2.5-1.25 4.4 0 .35.05.75-.05.3-.05.6 0 2.4 1.85 4.6.05.1.15.2.25.2.6.35.55.45 1.25.75 5.15 1.05 9.65 0 .85-.3 1.2-.75.3-.4.2-1.5-.1-1.15-1-2.2-.9-1.1-1-2.35-.1-1.25.65-1.75.7-.5 1.8.15 1.1.6 2.85 1.4.55.3 1.25.6 2.65 1.3 8.25 3.75.35.2 2.7 1.5 4.45 1.6 5 1.7 5.65 2.25 10.4 2.25 5.2 0 10.4-5.1l.2-.2q1.9-2 2.85-4.4.1-.3.2-.55 4.5-3.2 7.5 0 0 .65-.05 1.85-.05.7 0 .85-.1.65-.15 3.25 0 2.55-.35 3.4-.25.5-.45 1-1.45 3.25-.75 6.45.05.1.1.25.55 1.15 1.7 1.35 1.65.15 2.65-1.15 1-1.3 1.35-1.8.3-.5 1.05-1.65 1.5 6.8 4.4 7.3 2.15.25 2.8-4.3.25.2.8.75.5.55.95.75 1.85 1.5 3.1 1.7 1.7.2 2.55-.7.65-.75.7-1.5-.05-1.1-.6-2.35-.1-.45-.25-1.1.9.2 1.85-.2 2.35-.95 2.55-2.45.1-1.05-1.1-1.8-2.2-1.55-3.25-2.75-.2-.15-.3-.35-.65-1.5-1-2.65-1.25-3.35-1.9-6.7-.2-.25-.2-.6.05 0 .1-.05 5.55-5.7 14.2-7.6l.5 2.8q.25 1.4.25 3.05 0 2.55-1 6.75t-1 5.6q0 1.1 1.1 4.35.05.1.15.25l.2.3q.65 1.05 1.7 2.15 1.8 1.75 3.3 1.75 1.55 0 2.15-1.55.6-1.55 1.05-1.55h.05q.55.3 1.25.7 2.55 1.5 3.7 1.75.5.35 1.1.65 1.45.8 2.25 1.2.7.2 1.2.3 3.55-.1 3.55-2.55-.2-1.35-.65-2.25 4.1 0 4.1-2.05 0-1.75-1.35-2.45-.85-.4-1.75-.8 3.8-.8 3.8-2.85 0-1.45-1.85-2.25h-.1q-4.7-.6-5.15-.65-.85-.2-1.45-1.05-1.1-.75-1.3-3.35v-3.8-1.85q.6-8.65-1.1-17.35-.2-1.15-.3-1.85-.1-2-.8-4.1l-.6-1.9-2.5-1.7 2.25-1.9-.15-.25q8.1-2.35 14.1-6.65.3-.25.6-.45 2.6-2 4.95-4.4 3.6-3.5 6.2-7.55 6.45-10.05 6.45-22.7z"
                    id="PetPet027__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M61.65 17.2q-.05 0-.1.05-2.3.5-4.55.85-3.2.4-6.6.4-11.45 0-20.8-5.25Q25.65 10.9 22 7.7q-.5-.4-.95-.85-.15-.1-.25-.2Q17.55 3.5 15.15.2V.15q0-.05.05-.1V0l-1.05.55q-1.1.8-2.35 1.35l-.15-.05q-.15.05-.4.15-.85.4-2.35.8l-1.25.25h-2.8q-.1 0-.2.15-1.1 1.3-2.15 3.45-1.65 3.4-2.05 6.7-.05.2-.05.4v.15q-.7 4.3-.2 8.2.85 5.35 3.45 9.65l.15.25.2.3q.05.05.1.15 0 .05.05.1.1.05.3.3.3.55.7 1.15.55.85 1.4 2 6.25 11.3 3.8 18.65 3.9.5 7.5 0-.05-.8-.15-1.15l-.1-.9q-.2-1.3-.75-3.75-.15-.65-.3-1.2-.15-.55-.3-1-3.7-8.2-4.45-16.7.05-.05.05-.1V29q-.1-.25-.15-.4-.05-.65-.05-1.25Q22 21.7 32.75 25.3v.7q-.15.95-.4 5.3l-.55 6.35V39q-.15.4 0 .75-.15 1.15.35 3.05.2 1.45.65 3.25.5 2.25 1.3 4.9 0 .25.05.5 7.5-2.6 13.05-6.75.55-.45 1.15-.9 5.05-4.15 8.4-9.7.3-.55.6-1.05V33q0-.15.1-.15.15-.25.3-.55.1-.15.15-.3.1-.15.15-.25l.15-.25q.05-.2.15-.35l.4-.8q.1-.2.2-.45l.6-1.6q.75-1.95 1.25-4 .1-.4.2-.75.1-.75.25-1.5v-.1q0-.15.05-.25.05-.25.05-.45.1-1.05.15-2.05v-.4q.05-.75.15-1.6M12.35 43h.2q-.197.05-.25.2.05-.15.05-.2z"
                    id="PetPet027__Symbol_45_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M7.4 2.85H4.85q-.1 0-.2.15-1.1 1.3-2.15 3.45-1.65 3.4-2.05 6.7-.05.2-.05.4v.15q-.7 4.3-.2 8.2.85 5.35 3.45 9.65l.15.25.2.3q.05.05.1.15 0 .05.05.1.1.05.3.3.3.55.7 1.15.55.85 1.4 2 6.25 11.3 3.8 18.65 3.9.5 7.5 0-.05-.8-.15-1.15l-.1-.9q-.2-1.3-.75-3.75-.15-.65-.3-1.2-.15-.55-.3-1-3.7-8.2-4.45-16.7-.5.9-1.15 1.5-1.95 1.7-5.35 1.15-.5-.05-1.05-.2-.4-1.25-.75-2.3-.15-.35-.25-.65Q-.5 13.2 7.65 2.85H7.4m4.95 39.95h.2q-.197.05-.25.2.05-.15.05-.2M57 17.9q-3.2.4-6.6.4-11.45 0-20.8-5.25Q25.65 10.7 22 7.5q-.5-.4-.95-.85-.15-.1-.25-.2Q17.55 3.3 15.15 0 11.1 3 10.8 6.75q3.95-4.55 7.85-2.35l.2.1q.25.3.55.65.6.75 1.25 1.45 8.3 9.4 16.9 12.85 10.5 3.35 18.55-1 .45-.25.9-.55m4.25 3.95v-.1q0-.15.05-.25.05-.25.05-.45.1-1.05.15-2.05l-.5.9-.3.6q-.2.3-.4.65-.15.15-.25.35-8.5 13.1-24.7 9.95 1.25-6.35-1.45-13.2-.2 1.7-.9 3.5-11.15-3.3-21.15 1.25-4.85-6.4 0-13.95-7.65 4.7-3.4 13.55 1.5 3.35 3.2 4.55Q22 21.5 32.75 25.1v.7q-.15.95-.4 5.3l-.55 6.35v1.35q-.15.4 0 .75-.15 1.15.35 3.05.2 1.45.65 3.25.5 2.25 1.3 4.9 0 .25.05.5 7.5-2.6 13.05-6.75.55-.45 1.15-.9 5.05-4.15 8.4-9.7.3-.55.6-1.05v-.05q0-.15.1-.15.15-.25.3-.55.1-.15.15-.3.1-.15.15-.25l.15-.25q.05-.2.15-.35l.4-.8q.1-.2.2-.45l.6-1.6q.75-1.95 1.25-4 .1-.4.2-.75.1-.75.25-1.5z"
                    id="PetPet027__Symbol_28_0_Layer0_0_FILL"
                />
                <g id="PetPet027__Layer4_0_FILL">
                    <path
                        fill="#FFF"
                        d="M297.2 306.75q-.636-.422-1.05-.75-5.723-3.974-11.85 0-.159.103-.3.2-.512.328-.9.8-.252.142-1.2.65-.946.505-2.2.8v.1h-.05q-2.2.2-3.85.55-12.565 2.974-16.8 11.45-4.834 10.76 0 20.75.677 1.07 1.4 1.6h.05q.15-.05.35-.05.2-.05.4 0 .05.05.15.05-.2-2.3-.2-2.4 0-1.5.35-2.9 1-3.55 4.45-5.65 2.153-1.31 4.65-1.8.86-.167 1.7-.25 1.065-.084 2.15-.05.256-.049.5-.1 7.669.065 12.9 5.6.35.2.6.6.239.239.45.5.123.147.2.3-2.545-4.287-3.4-9.7-.488-3.852.15-8.2l.05-.1q.016-.2.05-.4.424-3.298 2-6.7 1.078-2.127 2.2-3.45.1-.15.2-.15h2.8l1.25-.25q1.486-.419 2.3-.8.29-.12.45-.2-.013-.008.05-.05m65.1-29.45q-3.75-6.1-9.85-7.9-6.35-1.6-11.35 2.2-3.75 2.5-5.2 7.05.5-4.7-1.95-8.55-3.25-5.45-9.65-6.05-6.45-.7-12.3 3.45-5.95 4.25-7.7 10.9-1.75 6.85 1.5 12.05 3.2 5.45 9.5 6.1 6.5.7 12.45-3.45 5.45-4 7.35-10.1-.7 6.2 2.85 11.85 3.6 6.4 10 8.2 6.1 1.45 11.1-2.15 5-3.65 6.15-10.75.9-6.7-2.9-12.85z"
                    />
                    <path d="M337.8 310.1q-2.31-.353-4.1-1.4-.578-.324-1.15-.65-3.66-2.394-8.7-2.3h-2q-.953 0-2-.3-.338-.064-.55-.15.02.048 0 .05.053.2.35.4 7.85 6.85 17.7 4.75.25-.15.45-.25v-.15z" />
                </g>
                <g id="PetPet027__Layer4_1_FILL">
                    <path
                        fill="#E0E0E0"
                        d="M283.1 307q-.252.142-1.2.65-.946.505-2.2.8v.1q-4.7 7.45-.75 14.75-.511-2.986-.2-5.7.145-1.004.35-2 1.242-5.78 4-8.6z"
                    />
                    <path d="M324.95 280.4q-.526.126-.95.55-.6.6-.6 1.45t.6 1.45q.337.337.75.45.328.15.7.15.85 0 1.45-.6t.6-1.45q0-.85-.6-1.45t-1.45-.6q-.249 0-.5.05m17.7 3.5q-.85 0-1.45.6t-.6 1.45q0 .85.6 1.45t1.45.6h.1q.788-.038 1.35-.6.6-.6.6-1.45t-.6-1.45q-.254-.254-.55-.4-.41-.2-.9-.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet027__Symbol_98_0_Layer0_0_FILL"
                transform="translate(236.4 237.8)"
                id="PetPet027__col1n"
            />
            <use
                xlinkHref="#PetPet027__Symbol_45_0_Layer0_0_FILL"
                transform="translate(285.5 305)"
                id="PetPet027__col2n"
            />
            <use
                xlinkHref="#PetPet027__Symbol_28_0_Layer0_0_FILL"
                transform="translate(285.5 305.2)"
                id="PetPet027__col2d"
            />
            <use xlinkHref="#PetPet027__Layer4_0_FILL" />
            <use xlinkHref="#PetPet027__Layer4_1_FILL" />
        </g></g>
    )
}

export default PetPet027
