import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#954A3C",
    "col1n": "#C66450"
}

function LegSki022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.95 0l-.4.1-2.1.5q-.85.15-1.65.35-1 .25-2 .45-1.95.45-3.85.85-1.65.3-3.3.65-.6.1-1.2.25-.1 0-.2.05-2.15.35-4.25.75-4.45.75-8.85 1.35l-3.5.5q-4.45.55-8.9 1-1.05.05-2.1.2-.4 0-.75.05l-2.6.2q-.95.05-1.85.1-.6.05-1.15.1-3.2.15-6.35.3l-2.2.1q-4.15.1-8.25.1h-.3q-10.4 0-20.8-.8-.85-.1-1.7-.15-6-.55-12-1.3-1.7-.25-3.45-.45-2.9-.45-5.75-.9-.95-.15-1.85-.3-1.45-.25-2.85-.5-.4-.05-.8-.15l-3.6-.7q-2.2-.4-4.3-.85-1.5-.35-3-.65-.5-.15-1-.25-.3-.05-.55-.1-.5-.15-1-.25Q3.35.35 2.2.05h-.05q-.2 0-.35.05h-.05Q2.9 2.2 0 20.25q1 1.5 3.3 2.75 9.1 4.75 40.7 2.9l4.75-.3q3.25-.3 13.75-1.65 5.05-.65 8.6-1.6 1.7-.45 3.4-1 2.85-.9 4.3-1.7 2.6-1 4.6-3.4l.3-.4q2.6 2.5 7.2 4.3.6.25 1.25.55 1.35.6 2.65 1.1 18.75 7.3 37.4 2.15.9-.2 1.85-.4 0-.05.05-.05v-.45l-.85-4.8V18Q131.8 4.8 133.2.05q-.15-.05-.25-.05z"
                    id="LegSki022__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M5.65 5.65q0-3.05-1.1-5.05Q3.35.35 2.2.05h-.05q-.2 0-.35.05h-.05Q2.9 2.2 0 20.25q.4-.95.55-1.85.6-3.35 2.45-5.9.2-.25.35-.5 2.3-3.8 2.3-6.35M54.3 20.6h.05l-.05-.05v.05m4.75-8.1l-.05-.05v.1q.002-.05.05-.05m16.7-4.65q-.6.45-2.45 1.5t-3.9 1.7l-3 .9q-.3.1-1 .25-.4.15-.9.25-1.85.5-2.55.6-.35.05-1.1.25-.8.25-1.15.4-.2.15-.4.4.25.25 1.35.35 3.25-.05 8.8-2 5.3-1.85 7.1-3.3.55-.4 1.4-1.4l-2.2.1m14.15-.8l-2.6.2q-.1 1-.3 2-.65 2.05-1.85 4.3-.65 1.3-1.45 2.3 2.6 2.5 7.2 4.3.6.25 1.25.55-5.95-3.7-4.9-6.45.5-1.6.85-2.9.2-.7.35-1.45.3 1.4.85 2.9 1.6 4.5 4.65 5.45 1.2.6 2.7.6.9 0 1.15-.2h-.2q-4.55 0-6.45-5.05-1.05-2.7-1.1-5.6-.1-.45-.15-.75v-.2m-5.6.4q-.35.45-.75.95-2.7 3.3-6.1 5.25-2.2 1.35-9.9 3.7-3.65 1.15-9.75 2.05-.55.1-1 .15-.9.3-.9.5 0 .25.25.4Q66.85 20 76.7 15.5q.85-.4 2-1.15 1.4-.9 2.2-1.6.75-.7 1.05-1.05 2.55-2.05 3.5-4.35-.6.05-1.15.1M132.55.1q-.35.9-.8 1.85-.5 1.05-.9 2.15-.4 1.25-.65 2.55-.05.6-.05 1.25 0 2.9 1.35 6.3 1.35 3.5 2.1 7.2.15.2.5 1.65l-.85-4.8V18Q131.8 4.8 133.2.05q-.15-.05-.25-.05l-.4.1z"
                    id="LegSki022__Symbol_49_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSki022__Symbol_88_0_Layer0_0_FILL"
                transform="translate(125 307.1)"
                id="LegSki022__col1n"
            />
            <use
                xlinkHref="#LegSki022__Symbol_49_0_Layer0_0_FILL"
                transform="translate(125 307.1)"
                id="LegSki022__col1d"
            />
        </g></g>
    )
}

export default LegSki022
