import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#479EEC",
    "col1n": "#77BFFF",
    "col1s": "#1073B4"
}

function ShiSpe075({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.45 42.95l-.1-.3-.1-.4q-.1-.25-.15-.55-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-4.05-9.2-9.25-17.2-.35-.5-.65-.95l-.05-.05q-.5-.8-1.05-1.55-.1-2.35-1.9-2.65l.05-.05h-.05l-6.2 4.65Q122 15.3 103.85 19 89.6 28.75 82.5 42.3v.05q-.2.35-.35.75-.05-.05-.05-.1v-.2l-.1.2q-.2-.85-.45-1.65l-.05.15q-.3-.95-.65-1.9-.7-1.9-1.6-3.65-4.6-9.45-15.35-16.85-18.55-3.65-34.15-14.45L23.55 0h-.05l.05.05h-.05q-.4.1-.75.25-1.6.75-1.5 3-.35.45-.65.95-.25.3-.4.6l-.3.4q-5.2 8-9.25 17.2-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35v.05l-.1.3v.05q-.1.3-.15.6-.15.45-.25.85-.05.15-.05.35l-.05.1q-.05.15-.05.3-.1.35-.2.75l-.2.9q-.05.1-.05.25l-.1.5h.05q-2.35.6-2.65 4.7-.2 2.9.05 3.9.3 1.35 1.9.3 1.7-1.3 3.75-2 .25-.1.55-.15 2.15-.65 4.65-.7h1.25q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15 3.05 1.1 5.8 3.55V57q.2.15.4.35l.05.05-4.1 17q-2.4 3-1.5 6.05l-.1.45-.1.15-.25.95q0 .05.05.05h.05l-.8 6.5q-.2 2.4 2.3 3.1.25.05.55.15 4.8 1.05 13.6 1.85 12.65 1.15 23.3.85 10.45-.3 13.05-.35h.1v.9q.25 2.2 3.25 2.3l4.6-.1v2.55q.1 1.9 2.3 2.05h6.8q1.9-.25 2-1.4v.4H88V96.9l1.15-.05q.4-.05.85-.05l.85 2.6q.75 1.95 3.05 1.7l5.8-1.1.05-.05q.1-.05.25-.05.75-.2 1.55-.5l.2-.05q1.35-.6.95-2.3l-.4-.95q28.75-1.7 37.35-2.9 1.35-.2 2.55-.35 1.75-.25 3.15-.5h.15q.7-.15 1.35-.25.45-.1.85-.15 3.25-.75 3.15-2.8l-.7-6.55.1 1.9-.15-2.05h-.05l-.4-1.75q.9-3.2-1.3-6.05l-4.2-17.2.05-.05q.2-.2.4-.35V57q2.75-2.45 5.8-3.55.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05H156q2.75.05 5.1.85v.05q2.05.7 3.75 2 1.6 1.05 1.9-.3.25-1 .05-3.9-.3-3.95-2.45-4.65-.1-.4-.15-.75-.05-.15-.1-.25-.1-.4-.15-.8l-.2-.7q-.05-.25-.1-.45v-.05q-.05-.1-.05-.2-.1-.35-.15-.65m-14.95 34.9l-.1.05v-.05h.1z"
                    id="ShiSpe075__Symbol_272_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.15 43.9q.05-.05.1-.05.45-.25.85-.45 11.1-5.65 21 2.05l.3-1.45q-10.35-8.2-22-1.75H.35l-.2.9q-.05.1-.05.25l-.1.5h.15m47-19.85q19.4 5.5 27.1 23.35l1-1.85q-7.7-17.75-27.1-23.25l-.85-.2q-.1-.05-.25-.1-16.8-5.05-27.15-19.5-.6-.8-1.15-1.65l-.2-.3q-.35.45-.65.95-.25.3-.4.6.6 1 1.3 2 10.15 14.5 27.25 19.65.15.05.25.1l.85.2m92.05 19.9l.25 1.35q9.85-7.55 21.05-1.8h-.1q.4.2.85.45h.05q0 .05.05.05h.1q.1 0 .2.05-.1-.4-.15-.75-.05-.15-.1-.25-.1-.4-.15-.8-.1-.05-.15-.05-11.65-6.35-21.9 1.75m3.8-41.2q.35-.6.75-1.15l-.05-.05q-.5-.8-1.05-1.55-.25.4-.55.85-10.5 15.8-28.35 21.15-.15.05-.25.1l-.85.2Q93.1 27.85 85.1 45 74.65 64.5 74.35 74.2l-4.4-.1-3.65-.1h-.95q-24.2-.85-48.8-4.1h-.1l-.4 1.75h.05q.9.1 1.8.25 28.25 3.7 56.35 4.15v6.3q-29.7-.55-59.7-4.65l-.1.45-.1.15-.25.95q0 .05.05.05h.05q.05 0 .05.05 24.65 3.5 49.15 4.45.65.05 1.3.05l1.6.05 3.4.1 4.55.1-.1 12.95q.1 1.9 2.3 2.05h6.8q1.9-.25 2-1.4v.4h.05v-2.5q-.3 1.6-1.75 1.8h-5q-2.3-.15-2.35-2.8l.05-17.6q0-.65.25-1.25.25-1 1-2 1.85-2.4 5.65-2.4 2.9 0 4.2.4.7.25 1.4.9.35.3.85 1.1.05.1.3.4h-1.95q-.15 0-.25.05h-.35q-1.5.2-1.7 2.25V89.1l2.5 7.55q.75 1.95 3.05 1.7l5.8-1.1.05-.05q.1-.05.25-.05l-5.35-12.9 1.65-.05 5.25 12.4v.05l.2-.05q1.35-.6.95-2.3l-4.15-10.1q.2-.05.35-.05 22.05-.75 45.8-3.8l4.6-.6h.05q.35-.05.7-.05l-.4-1.75-.8.1.3-.15-.7.1q-25.2 3.5-50.75 4.2l-3.85.1q-2.05.05-4.05.05v-4.8q.2-1.7 1.15-1.45l4.4-.1q24.3-.55 48.5-3.5l3.7-.5.8-.1-.45-1.75q-.4 0-.8.05-.1 0-.15.05Q118.2 73.6 92 74.15h-.05v-.1q0-.25-.55-1.4-.6-1.25-.9-1.45-1.3-.95-1.85-1.2-1.1-.5-3.05-.7h-2.35q-2.5.15-3.4.45-.95.3-3.2 2.15Q78.7 59.7 86.9 45.45q8.2-16.15 26.75-21.4l.85-.2q.1-.05.25-.1 17.8-5.45 28.25-21z"
                    id="ShiSpe075__Symbol_274_0_Layer0_0_FILL"
                />
                <g
                    id="ShiSpe075__Symbol_273_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M46 58.8v-7.45l-1.6-.05v7.5H46zM5.2.7l.05-.05q0-.25-.1-.4Q5.1.2 5.1.15q-.1-.1-.25-.1-.2-.05-.3 0-.05 0-.05.05-.05 0-.1.05T4.35.3Q4.3.3 4.3.35q-.05.05-.05.1L0 22.15q.4.7.85.15.05-.1.05-.2t.05-.1v-.15H1l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85L5.2.7m53.3 5.5l-1.1 2.2q-1.05 1.85-2 3.7l-.25.55-.1.15-.1.2-1 1.85.05.1q-5.95 12.4-8.35 26.5h1.45q2.7-14.9 9.15-27.9 1.3-2.6 2.75-5.15l.15-.5v-.1q-.05-.05-.05-.1v-.2l-.1.2q-.2-.85-.45-1.65l-.05.15M116.75.5V.45L116.7.4q-.05-.15-.1-.2-.05-.05-.1-.05 0-.05-.05-.05-.05-.05-.15 0h-.15q-.15 0-.25.15-.05 0-.05.05-.1.15-.1.4l.05.05 3.25 16.8q.05.1.2.85.05.1.05.25v.05q.2.6.4 1.55.15.65.35 1.55v.05l-.05.05h.05v.15l.1.3q.3.35.6.2.1-.1.25-.35L116.75.5z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe075__Symbol_272_0_Layer0_0_FILL"
                transform="translate(109 228.55)"
                id="ShiSpe075__col1n"
            />
            <use
                xlinkHref="#ShiSpe075__Symbol_274_0_Layer0_0_FILL"
                transform="translate(111.7 231.3)"
                id="ShiSpe075__col1d"
            />
            <use
                xlinkHref="#ShiSpe075__Symbol_273_0_Layer0_0_FILL"
                transform="translate(132 263.85)"
                id="ShiSpe075__col1s"
            />
        </g></g>
    )
}

export default ShiSpe075
