import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#666666"
}

function PetPet021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.2 6.1q.15.1.35.05.2-.05.35-.2.1-.2.05-.4t-.2-.3L36.1.1q-.15-.1-.25-.1-.15 0-.3.1-.15.05-.2.2l-5 9.55q-.8-.5-1.65-.85-.6-.25-1.25-.4v.05q-1-.25-2-.35h-1.8q-1.15.05-2.2.3-.65.15-1.25.4-.8.35-1.6.8L13.55.3q-.05-.15-.2-.2-.15-.1-.25-.1-.15 0-.25.1l-7.6 5.15q-.2.1-.25.3-.05.2.1.4.1.15.3.2.2.05.4-.05l7.15-4.85 4.8 9.1h.05q-.7.5-1.35 1.15-1.75 1.75-2.55 3.95l-7-7.5q-.15-.1-.25-.1-.15-.05-.3 0-.15 0-.2.15l-6 6.95Q0 15.1 0 15.3q0 .2.2.35.15.1.35.1.2 0 .35-.15l5.6-6.55 7.05 7.5q-.15.65-.25 1.3-.1.75-.1 1.55 0 1.35.3 2.6l-9.25-2.85q-.15-.05-.25 0-.15 0-.25.1t-.15.25l-1.7 9.05q-.05.2.1.4.1.15.3.2.2.05.4-.1.15-.1.2-.3l1.6-8.5 9.35 2.9q.15.5.4 1Q15 25.8 16.4 27.2L6.2 29.5q-.15 0-.25.1t-.15.25q-.05.15.05.3l3.05 8.7q.05.15.25.25.15.1.4.05.15-.1.25-.25.1-.2.05-.4L7 30.35l9.9-2.25q.15-.05.3-.15.15.1.3.25-.5 1.2-.5 2.6 0 3 2.2 5.15 2.2 2.1 5.4 2.1t5.4-2.1q2.2-2.15 2.25-5.15-.05-1.5-.6-2.8l.1-.1q.1.15.3.2l9.9 2.25-2.8 8.15q-.1.2 0 .4.1.15.3.25.2.05.4-.05.15-.1.25-.25l3-8.7v-.3q-.05-.15-.15-.25-.1-.1-.25-.1l-10.15-2.3q1.35-1.4 2.15-3.05l.4-1 9.3-2.9 1.65 8.5q.05.2.25.3.15.15.35.1.2-.05.3-.2.15-.2.1-.4L45.3 19.5q-.05-.15-.1-.25-.15-.1-.25-.1-.15-.05-.3 0L35.45 22q.25-1.25.25-2.6 0-.8-.1-1.55-.1-.65-.25-1.3l7.05-7.5 5.7 6.55q.1.15.35.15.15 0 .35-.1.15-.15.15-.35 0-.2-.1-.35L42.8 8q-.1-.15-.25-.15-.15-.05-.25 0-.15 0-.25.1l-7 7.5q-.85-2.15-2.6-3.95-.6-.6-1.3-1.1l.05-.05 4.8-9.1 7.2 4.85z"
                    id="PetPet021__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1 0H0v326.75h1.8q-.4-.05-.8-.05V0z"
                    id="PetPet021__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M313.2 145.7q.2.3.45.5.65.55 1.5.6h.1q1 0 1.7-.7l.3-.4q.5-.6.7-1.35l.1-.5.1.5q.2.75.65 1.35.25.35.55.6.65.5 1.5.5h.1q.7-.05 1.3-.45.3-.25.6-.65.4-.5.65-1.1.2-.7.2-1.5 0-1.55-.85-2.65-.75-.95-1.8-1.05h-.2q-1.2 0-2.05 1.05-.6.85-.75 1.95-.15-1.1-.8-1.95-.8-1.05-2-1.05h-.2q-1.05.1-1.85 1.05-.8 1.1-.8 2.65 0 .25.05.5.1 1.25.75 2.1z"
                    id="PetPet021__Layer4_0_FILL"
                />
                <path
                    d="M319.15 144q.25.3.6.3t.6-.3q.25-.2.25-.55 0-.4-.25-.65-.25-.2-.6-.2t-.6.2q-.25.25-.25.65 0 .35.25.55m-2.9-1.4q-.35 0-.6.2-.25.25-.25.65 0 .35.25.55.25.3.6.3t.6-.3q.25-.2.25-.55 0-.4-.25-.65-.25-.2-.6-.2z"
                    id="PetPet021__Layer4_1_FILL"
                />
            </defs>
            <use
                xlinkHref="#PetPet021__Symbol_93_0_Layer0_0_FILL"
                transform="translate(293.45 111.2)"
                id="PetPet021__col1n"
            />
            <use
                xlinkHref="#PetPet021__Symbol_42_0_Layer0_0_FILL"
                transform="translate(317.1 -207.25)"
                id="PetPet021__col2n"
            />
            <use xlinkHref="#PetPet021__Layer4_0_FILL" />
            <use xlinkHref="#PetPet021__Layer4_1_FILL" />
        </g></g>
    )
}

export default PetPet021
