import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#773900",
    "col2n": "#AD5300"
}

function ShiSpe023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M128.25 49.75q-.1.35-.2.75l-60.3-15.2q-9.8-.7-19.9-5.95-8.45-4.4-17.1-11.9-6.15-6-12.3-10.1l-.2-.1q0-.05-.05-.05t-.05-.05q-.6-.4-1.2-.75l-2.9-1.85q-.55-.35-1.1-.75Q6.7-.7 5.1.15q-3.05.6-3.05 3.8 0 1.45.7 3.05.25.55.6 1 .3.5.75.85.1.05.2.15l.1.2q4.5 6 5.55 22.15.15 2.45.2 4.95.1 9-2.2 18.4-.35 1.3-.7 2.65H6.4q.55.05 1.05.05l-4.7 25.95L0 90.55h138.2l-1.2-2.8-1.65-6-3.7-23.8v-.05q.05-.05.2-.15-.55-1.1-1-2.1-1.15-2.45-1.8-3.95-.3-.65-.45-1.1-.25-.55-.35-.85m-81.8-.9q-3.25-2.3.1 0h-.1z"
                    id="ShiSpe023__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.15 11.2q1.2 4.3 2.2 9.25 2.2 10.55 1.95 16.9.05-.05 1.05 3.15 1 3.15 4.1 9.7 3.1 6.5 2.25-3.6-.9-10.2.7-10.45-1.95-8.7-8.95-19.9-1.65-2.65-3.3-5.05m48.6 21.5q11.95 3.85-7.35-8.5-7.3-1.9-13.5-6.2-4.4-3.05-10.2-8.55-.05 0-.05-.05-5.8-5.7-9.55-9.4 1.05 2.3 2.05 4.45 1.7 3.7 4.15 7.15Q33.75 23.25 45 28.45q2.8.4 14.75 4.25m65.45 14.5q-1-1.1-1.95-1.75-.2-.1-.35-.2-.5-.45-1.05-.75 3.2-6 4.8-12.75-.3-.65-.45-1.1-.25-.55-.35-.85-.1.35-.2.75l-2.95-.75Q97.4 79.3 1 59.85L.35 63.4l-.35.9 20.6 6.3h71.65q10.8-2.5 22.8-15.7 1.7.8 3.55 1.7 3.55 1.7 7.4 3.55.4.15.8.35.1.05.25.1v-.05q.45.05 1 .05 1.3 0 1.6-1.15.05-.2.05-1.5 0-2.75-1.95-7.15-.4-.85-2.55-3.6z"
                    id="ShiSpe023__Symbol_25_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M131.3 28.8q1.65-6.55 2.05-10.05.35-3.55.8-5.3.45-1.8 1.15-6.2.3-2.05-.25-3.7-.45-1.4-1.2-2.25-3.45-2.6-7.95 0-.9.4-2.8 2-2.05 1.75-3.65 3.1-.8.65-1.1.95l-.3.2q.1-.1.1-.15l-.1.05-.1.1-.05.05h-.05q-.05 0-.1.05v.05q-4.45 3.9-9.05 5.65-2.2 1.1-4.5 2.05-8.45 3.55-18.9 5.35-2.6 1.55-5.2 3.45-3.15 2.9-6.35 5.6-3.65 3.1-7.4 5.95Q60 40.5 53.4 44.6q-2.8 1.65-5.6 3.25l-1.8 1q-.45.2-.85.45h-.05q-3.95 2.1-8.05 4-.7.2-1.55.5-8.45 2.7-17.15 3.65-1.45.15-1.95.25-.05 0-.1.05v.05q-5.6.3-11.3 0H2.85q-1.05.2-1.7.8Q0 59.6 0 61.45v.5q.3 5.45 3.4 5.4h.05v.1H3.4L1.2 81.6v.8h.05l-.05.05v.35q.2.05.45.15.05 0 .15.05.1.1.25.1h.1q.05 0 .1.05.1.05.25.05.25.1.5.15.05.05.15.05.1.05.25.1h.05q16 5 27.45 7.5 35.2 7.65 58.2 0l2-.7q.7-.25 1.45-.55l-.05-.05H92.65q12.75-5.1 21.4-15.35 3.9-4.6 6.95-10.15 2.05-3.85 3.75-8.1 1.15-2.85 2.1-5.9-.05-.1-.05-.2l.1.05q2.85-9.2 4.3-20.25l.1-1z"
                    id="ShiSpe023__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M112.6 35.3q-4.1 7.2-9.1 13.25-6.4 7.65-14.25 13.4l-1 .7q-10.05 7-22.45 11-19.85 6.3-45.6 4.8 42.95 5.1 68.05-8.05 6.15-3.25 11.25-7.55 7.1-6.05 12.15-14.3 6.8-11.15 9.75-26.3 1.1-5.9 1.65-12.4-1.9 6.5-4.25 12.4-2.75 6.95-6.2 13.05M89.4 22.25l.6-.9q1.1-1.65 2.25-3.4-2.35 2.2-4.65 4.3-.7.6-1.35 1.2Q44.5 60.25.6 59.9H.55L0 63.45q50.8 15.15 89.4-41.2m11.95-14.4q.7 1.4.1 4.2-1.7 5.1-4.1 10.2l-.1.2q.1-.1.25-.2 10.15-9.5 17.75-22.25l-.35.2V.15q-4.45 3.9-9.05 5.65-2.2 1.1-4.5 2.05z"
                    id="ShiSpe023__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe023__Symbol_24_0_Layer0_0_FILL"
                transform="translate(123.8 227.45)"
                id="ShiSpe023__col1n"
            />
            <use
                xlinkHref="#ShiSpe023__Symbol_25_0_Layer0_0_FILL"
                transform="translate(126.2 247.4)"
                id="ShiSpe023__col1d"
            />
            <use
                xlinkHref="#ShiSpe023__Symbol_26_0_Layer0_0_FILL"
                transform="translate(125.2 227)"
                id="ShiSpe023__col2n"
            />
            <use
                xlinkHref="#ShiSpe023__Symbol_27_0_Layer0_0_FILL"
                transform="translate(128.05 234.55)"
                id="ShiSpe023__col2d"
            />
        </g></g>
    )
}

export default ShiSpe023
