import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF98D0",
    "col1l": "#FFECF6",
    "col1n": "#FFCDE8",
    "col1s": "#FF5DB5",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF"
}

function ObjMor030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.15 17.85V0H24.8L14.2 8.95V8.9L0 20.5v22.75h34l15.15-25.4z"
                    id="ObjMor030__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M34 0H0v22.75h34V0z"
                    id="ObjMor030__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 43.25l15.15-25.4V0L0 20.5v22.75z"
                    id="ObjMor030__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M17.7 0L0 15h6.45l6.05-9.05 4.2-3.4-.3.4q-.1.1-.15.2L19.2 0h-1.5z"
                    id="ObjMor030__Symbol_59_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.25 15.7v-.4q.1-.95.15-2 0-1.75-.9-6.8-.7-3-1-4.05Q20 .65 19.6 0q-.4 1.35-2.65 3.75l-.4.45Q15.4 5.55 14.8 6.25q-1.1 1.15-2.15 1.9-.95.65-6.9 2.85-.5.2-.95.4-.9.2-1.85.7-1.15.65-1.45 1.1Q.35 14.8.15 15.7 0 16.2 0 18.05q0 1.6.5 4.9.45 3.2 1.1 4.15.1.25.2.55.4.9 1.1 2.05 1.05 1.8 2.65 3.55l.6.6q1.7 1.65 3.95 2.2h.15l8.5-10q.05-.3 1.15-1.65.05-.1.15-.2.9-1.4 1.3-2.95.05-.15.1-.35.3-1.15.5-2.15.3-1.7.3-3.05m-16.9 5.55v.1l-.1.3.1-.4z"
                    id="ObjMor030__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M8.95 14.25Q1.5 8.2 0 0q-.4 8.8 7.45 16l1.5-1.75z"
                    id="ObjMor030__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor030__Symbol_138_0_Layer0_0_FILL"
                transform="translate(281.3 311.05)"
                id="ObjMor030__col1n"
            />
            <use
                xlinkHref="#ObjMor030__Symbol_103_0_Layer0_0_FILL"
                transform="translate(281.3 331.55)"
                id="ObjMor030__col1l"
            />
            <use
                xlinkHref="#ObjMor030__Symbol_77_0_Layer0_0_FILL"
                transform="translate(315.3 311.05)"
                id="ObjMor030__col1d"
            />
            <use
                xlinkHref="#ObjMor030__Symbol_59_0_Layer0_0_FILL"
                transform="translate(297.35 313.55)"
                id="ObjMor030__col1s"
            />
            <use
                xlinkHref="#ObjMor030__Symbol_39_0_Layer0_0_FILL"
                transform="translate(293.7 292.3)"
                id="ObjMor030__col2n"
            />
            <use
                xlinkHref="#ObjMor030__Symbol_10_0_Layer0_0_FILL"
                transform="translate(296.9 311.9)"
                id="ObjMor030__col2d"
            />
        </g></g>
    )
}

export default ObjMor030
