import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00",
    "col2n": "#FF5400"
}

function ObjToo062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M84.15 33.1l-.3-3.4-5.55.5q-.25-.5-.4-1-5.2-13.8-2.95-26.5.25-1.35.55-2.7L59.9 1.45q-.2.65-.35 1.25-3.7 14.45 2.75 27.85v.1l.4 1-4.7.45.3 3.4-18.4 1.7-1.65-17.7-10.6 1-.15-1.45L13 20.4l.15 1.45L0 23.05l1.5 16.2 4.05-.4v.05l.85 8.4q2 1 4.05 2.6h.3q.75-.15 1.55-.15 3.15 0 5.4 2.25 0 .05.05.05l1.3 1.75q.9 1.6.9 3.6 0 3.15-2.25 5.35v.05q-.55.55-.95.8l6.2-.55-14.8 32.3 24.15-2.2L40 74l34.9-3.25 5.2-13.05 14.4-1.3-2.25-24.05-8.1.75M70.3 66.9l-28.85 2.7L45.1 61l-.25.1.3-.15 28.25-2.6-3.1 8.55m71.4-19.7q1.45-.5 3-.6 3.75-.3 7.85 1.6l-2.15-22.9-10.7 1 .15 1.6-29.4 2.75 2.05 21.9L129.45 51q.95-.75 2.05-1.05.55-.2 1.15-.25.5-.1 1-.1.95 0 1.85.25.5.1.95.3.1-.05.2-.15.95-.8 1.95-1.4.95-.55 2-.9v-.1q.55-.25 1.1-.4z"
                    id="ObjToo062__Symbol_221_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M22.1 2.8l-.15-1.45Q13.9-1.95 7.45 2.7l.15 1.45L22.1 2.8m12.25 16.7l-.15-1.45L0 21.2l.15 1.45 34.2-3.15m45.55 10l-25.85 2.4-1.3-14.1-1.25.1 1.4 15.25 28.2-2.6-1.4-15.25-1.1.1 1.3 14.1m37.15 4.4l-.45-4.5-1.1.1.45 4.5 1.1-.1m-4.75.45l-.45-4.5-1.15.1.45 4.5 1.15-.1m-23.75.05l-.1-1.1-48.3 4.45L38.4 19.1l-1.45.15L38.8 39l49.75-4.6m37.4-2.15q.55-.2 1.15-.25l-.4-3.55-1.15.1.4 3.7m-3.6 1.15l-.45-4.5-1.1.1.45 4.5 1.1-.1m8.3-5.3l-1.1.1.4 3.95q.5.1.95.3.1-.05.2-.15l-.45-4.2m2.55-17.8l1.3 14.1-28.3 2.65.1 1.15 28.3-2.65.45 4.35q.55-.25 1.1-.4l-1.85-19.3-1.1.1z"
                    id="ObjToo062__Symbol_143_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M35.4 56.9l3.75-.35-.6-6.75-.3.15.25-.1-3.1 7.05M26.25 7.4l-.65-7-8.85.8-.1-1.2-8.7.8.1 1.2L0 2.75l.65 7 25.6-2.35M87.9 45.25l4.7-.45-2.4-25.65-4.7.45 2.4 25.65m124.05-35.8l-4.2.4-.15-1.8-4.7.4.9 9.6 4.7-.4-.2-1.8 4.2-.4-.55-6z"
                    id="ObjToo062__Symbol_94_0_Layer0_0_FILL"
                />
                <g id="ObjToo062__Layer14_0_FILL">
                    <path
                        fill="#FFF"
                        d="M229.95 264.95l-13.5 1.25q-4.786 3.633.55 6l13.5-1.25-.55-6m99.75-3.25q4.3-3-.55-6l-59.1 5.5.55 6 59.1-5.5z"
                    />
                    <path fill="#00F" d="M251.1 229.1h-.15v.05h.15v-.05z" />
                    <path
                        fill="#0F0"
                        d="M157.85 254.7l1.25 13.4 23.1-2.15q-.031-.078-.05-.15l15.6-1.4.05.1 38.05-3.55q.8-.05 1.35-.1.15 0 .2-.05.6-.05 1.3-.1 3.05-.3 5.7-1.6 2.7-1.35 4.85-4.05 4.25-4.8 3.7-10.95-.6-6.3-5.7-10.4-4.2-3.35-9.6-3.35L195 234.3q-.022.091-.05.15l-20.95 1.8h-.1q-9.75 1.4-13.65 9.6-1.8 3.75-2.4 8.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToo062__Layer14_0_FILL" />
            <use
                xlinkHref="#ObjToo062__Symbol_221_0_Layer0_0_FILL"
                transform="translate(119.5 234.3)"
                id="ObjToo062__col1n"
            />
            <use
                xlinkHref="#ObjToo062__Symbol_143_0_Layer0_0_FILL"
                transform="translate(125.05 252)"
                id="ObjToo062__col1d"
            />
            <use
                xlinkHref="#ObjToo062__Symbol_94_0_Layer0_0_FILL"
                transform="translate(126.1 245.45)"
                id="ObjToo062__col2n"
            />
        </g></g>
    )
}

export default ObjToo062
