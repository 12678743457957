import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#333333",
    "col1n": "#434343"
}

function BeaMus025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.65 16.6q1.15 0 2.35.1 1.05.05 2.05.2 3.95.8 7.6 3.65l.35.35q1.3 1.35 2.55 2.2v-.65q-.2-1.55-.3-2.55-.05-.25-.05-.4l.2.3 1.5 2.85q1.35 2.25 3.05 5.85l1.95 3.2q2.1 5.15 5.35 6.7-1-2.15-1.1-3.6 0-.35-.05-.6.85 1.05 2.2 1.7 1.2.6 3.65.75l-.25-.25q-1.2-1.25-1.95-2.35l-.95-2.2q1.95 1.5 4.2 1.45 2.15-.5 3.2-1.2-3.2 1.45-6.2-5.5-2-4.6-2.1-4.95Q62.5 3.8 47.3.95 38.85-.9 30.05.95 14.6 4.5 8.2 19.2q-1 1.9-2.25 4.45Q4.1 27.4 0 28.25q1.8.3 2.9.3 1.65 0 2.9-.65-.3.75-.6 1.45L3.4 32.4q-.55.75-.4.9l.05.05h.05q.35.1 1.55-.2 1.15-.35 2.45-1.4.35 2.55 1.9 3.7 1.5 1.1 1.6 1.1v-.4-.5q0-1.15.2-1.95 0-.3.45-2.25.4-2 2.35-5.2.25-.5.7-1 .3-.35.7-.7.2 1.15.6 3.1h.15q.05-.1.1-.15 2.1-.95 3.25-2.6.45-.6.85-1.1.2-.3.4-.55.4-.35 1.15-1.15.75-.85 1.6-1.75.8-.95 3.45-2.6-.6 1.3-.65 2.1v.3q.005-.155.15-.3 7.1-3.2 14.6-3.25M3.45 27.45h.05l-.2.15.15-.15z"
                    id="BeaMus025__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M8.7 6.95q-1.15 2-3.15 4.95-1.5 2.15-2.75 2.8-.25.7-.55 1.3l-1.8 3.05q-.55.75-.4.9L.1 20h.05q4.1-3.3 7.2-8.45.35-.95.85-2.95.2-.9.5-1.65m2.85 2.8q-.5-1.85-.5-2.2h-.15q-1.45 1.7-2.55 5.05-1.05 3.3-1.05 5.85 0 3.2.35 4.35v-.5q0-1.15.2-1.95 0-.3.45-2.25.4-2 2.35-5.2.25-.5.7-1 .25-.3.6-.65-.15-.5-.4-1.5M26.35.1V.05l-3.8 2.25q-1.25 1.05-1.7 1.45-.5.35-.7.6-.25.25-1 .95l.4-.55q-2.1 2-2.65 2.95-.6.9-1.2 2.2-.65 1.25-1.55 2.5-.15.2-.95 1.2-.15.2-.25.5l-.05.05q2.1-.95 3.25-2.6.45-.6.85-1.1.2-.3.4-.55.4-.35 1.15-1.15.75-.85 1.6-1.75.8-.95 3.45-2.6.15-.3.3-.65 0-.05.05-.1.95-1.75 2.4-3.55m25.9 6.05l.2.3 1.5 2.85q1.35 2.25 3.05 5.85l1.6 2.6q-.7-1.4-1.25-3.05T55.8 8.85q-.7-2.8-2.7-5.45-1.55-2.15-3.25-3.4.2.5.5 1.5 0 .047.05.15-.053-.103-.1-.15.95 1.7 2 5.05-.05-.25-.05-.4m.35 3.6V9.7q-.45-1.1-.55-1.25-.25-.4-.55-.8-.6-.8-1.25-1.4-.15-.15-.25-.3-.25-.3-.55-.55-.6-.55-2.85-2.25-2.3-1.7-5.7-2.15-3.4-.45-4.45-.05-3.55.5-6.9 1.9Q27.1 3.9 25 5.25q.6-.25 1.35-.45-2.7.85-3.25 1.7 7.1-3.2 14.6-3.25 1.15 0 2.35.1 1.05.05 2.05.2 3.95.8 7.6 3.65l.35.35q1.3 1.35 2.55 2.2m9.35 3.45q-.55-.95-1.15-2.25-.05.05-.05.1-.2.75-.2 1.1 0 3.85 3.05 7.2 2.4 2.7 5.15 3.7-1.2-1.25-1.95-2.35l-.95-2.2q-2.1-1.55-3.9-5.3z"
                    id="BeaMus025__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus025__Symbol_42_0_Layer0_0_FILL"
                transform="translate(152.8 193.7)"
                id="BeaMus025__col1n"
            />
            <use
                xlinkHref="#BeaMus025__Symbol_12_0_Layer0_0_FILL"
                transform="translate(155.75 207.05)"
                id="BeaMus025__col1d"
            />
        </g></g>
    )
}

export default BeaMus025
