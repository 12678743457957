import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#004D1E",
    "col2d": "#C40000",
    "col2l": "#FF5D5D",
    "col2n": "#FF0000"
}

function ObjMor053({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.4 3.75v-.2q-1.6-.1-3.45-1.05-2.5-1.2-2.95-1.35.5-.8 1.45-1.15-1.8.15-4.55.15Q11.8.05 11 0l-.2.3Q9.65.85 4.1 4.8v.05h.05q-.1.15-.2.25Q2.15 7.25.4 7.95h1.7q-1.3 2.2-2.1 4.4 1.1-.65 2.9-1.9-.5 3.2-.7 6.05 0 2.25.4 3.95l.1.1q1.2-2.05 3.6-6.5l.2-.1q.2.7.4.7l.8 1.1h.05q.55-4 1.05-5.5 1.5.65 1.7 1.2h.1L10.45 7l12.5 22.1 5.4 9.7L30.2 42l-.05.05 7.7 13.9q3.8 2.55-5.1-14.05l-1.35-2.45q-3.4-.65-4.5-4.3-.4-1.4-.45-4.55L11 2.75q.4.1.85.25 4.1 1.15 5.55 1.15 1.8 0 5-.4z"
                    id="ObjMor053__Symbol_175_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M30.5 12.5q-.1-.85-.25-1.65l-.4-1.4q-1-2.9-3.05-9.45-2.15 3.35-5.55 1.6Q17.8-.15 11 2.3 4.15 4.7 5.25 15.05 5.1 15 4.95 15 2.5 14.4 0 13.5q1.7 1.45 2.7 2.4.95.95 1.15 1.35.4.75 1.75 3 1.3 2.2 4.7 5.3.65.6 1.35 1.15.5.4 1.05.8 1.6 1.05 3.45 1.5 2.05.35 3.7.35 2.6-.05 4.25-1.05l.1-.1q.05-.15.15-.2v-.05q.3-.6.65-1.15.25-.4.6-.75l.05-.05.2-.3q.45-.55.9-1.15 2.1-2.75 3.15-5.9.45-1.35.6-2.7.2-1.7 0-3.45z"
                    id="ObjMor053__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M20.7 16.9q0-.3.05-.5.05-3.5-.35-6.6-.3-1.65-.65-3.15-.3-1.05-.6-2-.45-1.35-1-2.6Q16.9-.8 15.6.35q-2.85 2.5-6.15 4-1.95.9-6.45 2.2V6.5q-.25.2-.8.35-.65.05-1 .2l-.55.15q-.3.1-.65.2h.05q.4.05.95.15 5.25.8 11.35 2.15 1.65 5.65 4.55 8.95.95 1.05 2.05 1.9.05-.15.15-.2v-.05q.3-.6.65-1.15.25-.4.6-.75l.05-.05.2-.3q.05-.6.1-1.15z"
                    id="ObjMor053__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.55 10.4Q5.05 9.1 7 8.2 5.65 6.8 5.65 5.15 9.8 4.6 15.95.45 10.75-.4 4.6.45q-6.15.8-4.05 9.9v.05z"
                    id="ObjMor053__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor053__Symbol_175_0_Layer0_0_FILL"
                transform="translate(97.9 259.15)"
                id="ObjMor053__col1n"
            />
            <use
                xlinkHref="#ObjMor053__Symbol_43_0_Layer0_0_FILL"
                transform="translate(82.15 234.6)"
                id="ObjMor053__col2n"
            />
            <use
                xlinkHref="#ObjMor053__Symbol_19_0_Layer0_0_FILL"
                transform="translate(87.4 242.25)"
                id="ObjMor053__col2l"
            />
            <use
                xlinkHref="#ObjMor053__Symbol_13_0_Layer0_0_FILL"
                transform="translate(89.85 238.4)"
                id="ObjMor053__col2d"
            />
        </g></g>
    )
}

export default ObjMor053
