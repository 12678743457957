import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function ObjMor078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M45.8 8.5q0-1.4-.35-1.8-1.2-1.45-5.35-1.15-.4-.4-1.55-2.15-1.1-1.6-2.1-2.35-3.5-1.6-6.65-.8-.75.2-3.3 1.5-1.95 1.05-3.1 2.1-.55.45-1.2 1.2h-1.3q-8.7 0-14.8 5.55Q0 16.3 0 24.2q0 7.95 6.1 13.55 3.55 3.25 8.05 4.7 1.1 1.4 1.75 3.7.95 3.3 3.1 3.3.05 0 2.75-.85.7.05 1.6-.05.8.65 2.05 2.75Q27 53 30.15 53t4.1-2.25q.35-2.45.55-2.8.55-1.15 3.05-2.55 2.8-1.6 3.6-2.45.4-.5.65-1.2.5-.85.45-1.8-.05-1.6-.05-2.3v-.25q.35-.95 3-2.1 1-.45 1.6-1.05 1.05-.85 1.05-2.3 0-.45-.4-1.6-.25-1.6-1.15-3.15-.35-.9-.35-1.2 0-1.45 1.55-2.25 1.55-.85 1.55-2 0-1.35-2.5-2.6-2.5-1.35-2.5-4.3 0-.5.7-2.85.75-2.4.75-3.5z"
                    id="ObjMor078__Symbol_167_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.75 10.85q.95-1.95.95-3.45 0-4.05-5.1-6.6Q5.55-.15 4.1 0q-.95.15-1.3.8.75.1 1.75.55Q8.6 3.25 8.9 6.4V6.85Q8.9 8 7.3 10.5q-.35.5-.65.9-.1.45-.1.95 0 3.55 2.1 5.9 2.15 2.35 2.15 5 0 1.95-1.75 4.85-.85 1.4-2.55 2.6-1.05.95-2.9 2.05Q1.7 33.9 0 34.5h3q5.55-1.8 8.65-4.4 2.7-2.3 2.7-4.2 0-.9-.35-2.3t-.35-2q0-.15.05-.3-1.45-2.35-3.6-6.85 0-2.3 1.65-3.6z"
                    id="ObjMor078__Symbol_94_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor078__Symbol_167_0_Layer0_0_FILL"
                transform="translate(84.25 108.6)"
                id="ObjMor078__col1n"
            />
            <use
                xlinkHref="#ObjMor078__Symbol_94_0_Layer0_0_FILL"
                transform="translate(108.35 113.75)"
                id="ObjMor078__col1d"
            />
        </g></g>
    )
}

export default ObjMor078
