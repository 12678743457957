import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#411F00",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.15 2.1q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4z"
                    id="AccGla004__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M129.15 24.75q.1-1.95.1-4.3.05-2.8-.1-6.15-.2-6.95-.3-5.15-.05-9.5-19.9-9.15-17.65.3-32.5 4.7-1.85.55-2.6.85-.65.2-2.65 1.25-1.25.65-2.2 2.35h-8.8q-.95-1.55-2.15-2.2-2-1.05-2.65-1.25-.75-.3-2.6-.85Q37.95.45 20.3.15.45-.2.4 9.3.3 7.5.1 14.45-.05 17.8 0 20.6q0 2.35.1 4.3 1 10.05 9.7 13.15 2.15.5 4.15 1 25.9 6.05 37.4.2Q61.55 34 62.15 17.2v-1.1-.15h4.95v1.1q.6 16.8 10.8 22.05 11.5 5.85 37.4-.2 2.05-.5 4.15-1 8.7-3.1 9.7-13.15z"
                    id="AccGla004__Symbol_74_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M192.2 7.7l-.05-5.55L161.85.9Q152.7-.25 136.6.05q-16.1.3-26.6 3.5-3.7 1.1-6.55 1.8-3.6 1.35-7.35 1.35-3.75 0-7.35-1.35-2.85-.7-6.55-1.8Q71.7.35 55.6.05 39.5-.25 30.35.9L.05 2.15 0 7.7q16.15 1.25 23.45 2.65 5.15 1.3 5.85 6.5.05 1 .1 2.05.2 3.15.7 6.35 1.25 11.45 9.5 14.55.85.3 1.65.55Q62.8 45.6 75.8 43.5q1.15-.2 2.15-.5 9.35-2.85 12.85-9.3l.25-.45q.1-.3.25-.6 1.75-3.85 3.05-9.3.25-1.1.5-2.25l-.1.3q.25-1.9 1.35-2.1 1.1.2 1.35 2.1l-.1-.3q.25 1.15.5 2.25 1.3 5.45 3.05 9.3.15.3.25.6l.25.45q3.5 6.45 12.85 9.3 1 .3 2.15.5 13 2.1 34.55-3.15.8-.25 1.65-.55 8.25-3.1 9.5-14.55.5-3.2.7-6.35.05-1.05.1-2.05.7-5.2 5.85-6.5 7.3-1.4 23.45-2.65m-42.3 30.55q-2 .5-3.9.95-24.55 5.8-35.45.2-9.65-5-10.2-21.05V17.3q0-1.6 1-4.65 1.05-3.1 2.85-4.05 1.9-1 2.55-1.2.7-.3 2.45-.8 14.05-4.2 30.75-4.5 18.8-.35 18.85 8.75.1-1.75.3 4.9.15 3.2.1 5.85 0 2.25-.1 4.1-.95 9.6-9.2 12.55M83 6.6q1.75.5 2.45.8.65.2 2.55 1.2 1.8.95 2.85 4.05 1 3.05 1 4.65v1.05Q91.3 34.4 81.65 39.4q-10.9 5.6-35.45-.2-1.9-.45-3.9-.95-8.25-2.95-9.2-12.55-.1-1.85-.1-4.1-.05-2.65.1-5.85.2-6.65.3-4.9.05-9.1 18.85-8.75 16.7.3 30.75 4.5z"
                    id="AccGla004__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla004__Symbol_95_0_Layer0_0_FILL"
                transform="translate(191.3 159.75)"
                id="AccGla004__col2n"
            />
            <use
                xlinkHref="#AccGla004__Symbol_74_0_Layer0_0_FILL"
                transform="translate(127.9 146.75)"
                id="AccGla004__col2l"
            />
            <use
                xlinkHref="#AccGla004__Symbol_26_0_Layer0_0_FILL"
                transform="translate(96.4 145.9)"
                id="AccGla004__col1n"
            />
        </g></g>
    )
}

export default AccGla004
