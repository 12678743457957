import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D19532"
}

function ObjToy014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M98.85 130.1q-1.7 1-3.8 1-3.15 0-5.4-2.25.1.2.15.35 1 2.45 1.3 3.45.6 1.95.6 3.6-.1 3.55-1.15 4.25-.1.15-.25.25l4.05 6.05q.1.5.7 2.3.6 1.4.65 2.35.05 1.05-.3 3.1-.15 1.35.4 2.2.7 1.05 2.95 1.55 3.6.75 7.95-2.15.6-.4 1.15-.85.05 0 .1-.05.45-.25 1.05-.65 4.3-2.85 5-6.5.45-2.25-.25-3.3-.55-.85-1.9-1.2-2-.5-2.95-.95-.9-.4-2-1.45-1.35-1.3-1.75-1.65l-6.3-9.45M5.2 2Q1.1 4.75.1 9.65q-.9 4.5 5.35 16.65Q17.1 49.4 32.4 64.75q14.15 12.9 25.9 28.5l1.2 1.6q1.65 2.3 3.3 4.65l12.45 18.7q3.05-.3 6.6.85 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.55.85 1 1.75.15.25.4.6-.15-.7-.15-1.4 0-3.15 2.25-5.4.35-.35.75-.65L73.6 92.25Q60.9 72.5 53.15 50.9 44.9 30.85 28.1 11.2l-.05.05Q18.65 1.1 14.2.25q-4.9-1-9 1.75z"
                    id="ObjToy014__Symbol_108_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy014__Symbol_108_0_Layer0_0_FILL"
                transform="translate(36.9 168.15)"
                id="ObjToy014__col1n"
            />
        </g></g>
    )
}

export default ObjToy014
