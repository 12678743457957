import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#CCCCCC",
    "col2n": "#FFFFFF"
}

function HaiSpe034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.65 88.4q.05 0 .1.05 11.5 4.5 11.65 8.8.45 5.9.1 11.7v.05q-.05.7-.1 1.45l-.9 7.75q-2.25 15.25-9.05 28.7-1.75 13.6 6.7 26.6 1.7 3.05 6.7 7.65 8 7.15 15.65 6.65-2.1-2.4-2.8-4-3.45-4.7-3.7-6.95-.5-1.7-.95-6.15-.8-6.5.35-12.95v-.3q-.1-1.3.5-6.45v-1.1q.1-2.65.55-5.15 4.3-21.9-1.95-40.8-.25-1.2-.6-2.1-.4-.9-.75-2.1-1.85-9.3-7.15-18.2 0-.05.05-.05-.55-1.4-1.05-2.75Q180.75 1.6 129.35 0q-8.6.3-16.9 4.5l-1.65-.45Q101.2.85 91.65 1q-6.6.1-13.15 1.7-29.8 9.9-46 32.7Q12.95 62.35 12.1 97q-.15 12-2.9 20.8-.75 1.85-1.7 3.9-1.6 3.4-2.95 5.8-9.2 16.6.2 36.05 1.75 4.6 3.4 8.1.95 2.5.95 8.4 0 5.35-3.95 14.6-.25.75-.7 1.5 1.5-1.1 3.4-2.6.65-.4 4.25-3.4 3.9-2.7 7.65-8.5 4.5-6.95 6.7-14 1.05-3.8 1.45-15.05v-1.1q-3.9-5.7-8.2-17.5l-.45-1.5q-.2-.6-.35-1.2-.3-1.1-.55-2.15-.3-1.25-.55-2.45-.15-.6-.25-1.2-.1-.55-.2-1.05-1-7.65 1.35-15.4l1.1-3.05q.05-.25.2-.45 1.45-3.6 3.5-6.85 8.9-16.15 26.4-26.15 4-2.45 8.55-4.55 1.7-.8 3.45-1.4 23.6-7.45 37.5-21.5 2.7-2.55 3.4-3.1.25-.25 1.85-2.05 27.6 21.3 63.6 39.5 11.8 5.55 23.4 8.95z"
                    id="HaiSpe034__Symbol_166_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M152.1 4.95Q141.45.4 129.35 0q-8.6.3-16.9 4.5l-1.65-.45Q101.2.85 91.65 1q-6.6.1-13.15 1.7-1.9.65-3.75 1.35 3.75-.3 9.3-.3 8.7 0 9.75.15 2.55.25 7.9 2.35 1.4.55 5.25 3.05 3.35 2.2 4.1 2.2.45 0 3.95-2.65 4.05-3.05 7.7-4.35 3-1.1 15.5-1.75 4.15 0 11.5 1.65 1.2.25 2.4.55m27.45 21.9q6.25 11.5 10.15 21.55 4.75 12.35 4.75 19.2 0 2.75-.5 5.05t-.5 6.35h-.25q-2.5-2.85-4.8-4.6-3.25-2.5-7.95-4.65-6.25-2.9-10.35-3.8-3.4-.8-12.65-1.7-14.3-1.45-32.35-13.55-14.95-9.95-19.65-16.95-6.2 10.75-18 17.75-11.1 6.55-25.5 8.75Q43.8 63 33.5 70.15q-12.1 8.4-12.8 22.6-3.75-9.95-3.75-18.15 0-4.25.1-5.1.35-2.4.9-6.15-5.4 15.8-5.85 33.65-.15 12-2.9 20.8-.75 1.85-1.7 3.9-1.6 3.4-2.95 5.8-4.5 8.15-4.55 17 .7-4.25 2.8-7.55 1.1-1.75 8.15-9.95l.25-.25q0 3-.5 9.1 0 5.9 4 13.45 4 7.55 4 14.45 0 3.6-1.6 9.15-.65 2.2-2.9 8.6-.55 1.5-1.95 4.1-2.3 4.45-2.55 4.9-.05.1-2.8 3.2-.8.9-1.45 1.75 1.1-.85 2.4-1.9.65-.4 4.25-3.4 3.9-2.7 7.65-8.5 4.5-6.95 6.7-14 1.05-3.8 1.45-15.05v-1.1q-3.9-5.7-8.2-17.5l-.45-1.5q-.2-.6-.35-1.2-.3-1.1-.55-2.15-.3-1.25-.55-2.45-.15-.6-.25-1.2-.1-.55-.2-1.05-1-7.65 1.35-15.4l1.1-3.05q.05-.25.2-.45 1.45-3.6 3.5-6.85 8.9-16.15 26.4-26.15 4-2.45 8.55-4.55 1.7-.8 3.45-1.4 23.6-7.45 37.5-21.5 2.7-2.55 3.4-3.1.25-.25 1.85-2.05 27.6 21.3 63.6 39.5 11.8 5.55 23.4 8.95.05 0 .1.05 11.5 4.5 11.65 8.8.45 5.9.1 11.7v.05q-.05.7-.1 1.45l-.9 7.75q-2.25 15.25-9.05 28.7-1.75 13.6 6.7 26.6 1.7 3.05 6.7 7.65 7.7 6.85 15.05 6.7-5.75-4.6-10.85-13.55-6.6-11.45-6.6-22.05 0-3.4 1.45-9.95 1.5-6 1.55-6.3 1.25-6.1 1.75-9.75.3-2.4.3-4.5-.05-1.6-.05-1.65 0-4.3-.05-4.95-.1-.95-.7-3.15h.35q2.75 0 5.45 12.8 1.95 9 2.25 18.55 3.8-21.1-2.25-39.4-.25-1.2-.6-2.1-.4-.9-.75-2.1-1.85-9.3-7.15-18.2 0-.05.05-.05-.55-1.4-1.05-2.75-10.3-32.75-25.45-51.9z"
                    id="HaiSpe034__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.35 17.3q-1.2-.75-2.5-.95h-.1l-.4-.1q-.8-.15-1.6-.15-.9 0-1.65.15-2.35.3-4.1 1.8-.65.45-1.15 1-.05.05-.05.1-.1-.05-.15-.1-.95-.95-2.05-1.6-.1-.05-.15-.1-.05 0-.1-.05t-.1-.05q-.85-.5-1.75-.75-.45-.15-.9-.2-.15-.05-.25-.05-.75-.15-1.6-.15-.9 0-1.65.15-3 .45-5.25 2.8Q0 21.85 0 25.95q0 4.1 2.85 6.9Q5.1 35.1 8.1 35.6q.75.1 1.65.1H10.6q.25-.05.5-.05.15-.05.25-.05 1.6-.2 3-1 1.25-.7 2.3-1.75.05-.05.1-.05l.1.05.1.1q.5.55 1.1.95 1.8 1.35 4.05 1.7.75.1 1.65.1.8 0 1.6-.1 1.6-.2 3-1 1.3-.7 2.3-1.75 2.85-2.8 2.85-6.9 0-4.1-2.85-6.9-1-1.05-2.3-1.75M217.6 2.9q-1-1.05-2.3-1.75-1.4-.8-3-1-.8-.15-1.6-.15-.9 0-1.65.15-1.45.15-2.6.85l-.4.2q-.55.3-1.05.7-.65.45-1.15 1-.1.05-.1.1l-.1-.1q-1.05-1.05-2.3-1.75Q200 .4 198.55.2q-.15-.05-.25-.05-.75-.15-1.6-.15-.9 0-1.65.15-3 .4-5.25 2.75-1.5 1.5-2.2 3.4-.1.15-.15.35-.5 1.45-.5 3.15 0 4.1 2.85 6.95 2.25 2.25 5.25 2.75.75.1 1.65.1.7 0 1.35-.05.15-.05.25-.05 1.6-.2 3.05-1 1.25-.7 2.3-1.75.05-.05.1-.05l.05.05h.05q.55.6 1.2 1.05.15.15.35.25 1.6 1.15 3.65 1.45.75.1 1.65.1.8 0 1.6-.1l.8-.1q.05-.05.1-.05 1.1-.25 2.1-.85 1.3-.7 2.3-1.75 2.85-2.85 2.85-6.95t-2.85-6.9z"
                    id="HaiSpe034__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M2.5 17.25l-.6-.6q-.35-.25-.65-.5-.65.45-1.15 1-.05.05-.05.1H0q1.4 1.4 2.1 3.1.65 1.7.65 3.7 0 1.45-.35 2.75-.4 1.4-1.2 2.65-.3.4-.55.75-.05.05-.1.15L0 30.9l.1.05.1.1q.5.55 1.1.95.3-.25.65-.5l.55-.55q2.9-2.8 2.9-6.85 0-1.3-.3-2.45-.6-2.5-2.6-4.4m188.8-13.8q-.7-1.3-1.8-2.35-.4-.4-.8-.7-.25-.25-.45-.4-.65.45-1.15 1-.1.05-.1.1.15.15.3.35 2.4 2.65 2.4 6.45 0 4-2.65 6.9H187l.05.05h.05q.55.6 1.2 1.05.6-.45 1.2-1.05 2.85-2.85 2.85-6.9 0-1.05-.15-2-.3-1.35-.9-2.5z"
                    id="HaiSpe034__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe034__Symbol_166_0_Layer0_0_FILL"
                transform="translate(83.05 53)"
                id="HaiSpe034__col1n"
            />
            <use
                xlinkHref="#HaiSpe034__Symbol_98_0_Layer0_0_FILL"
                transform="translate(83.05 53)"
                id="HaiSpe034__col1d"
            />
            <use
                xlinkHref="#HaiSpe034__Symbol_26_0_Layer0_0_FILL"
                transform="translate(82.65 133.5)"
                id="HaiSpe034__col2n"
            />
            <use
                xlinkHref="#HaiSpe034__Symbol_15_0_Layer0_0_FILL"
                transform="translate(99.4 135.4)"
                id="HaiSpe034__col2d"
            />
        </g></g>
    )
}

export default HaiSpe034
