import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col2n": "#FF008C"
}
function HaiHig018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M5.8 0q-.1.25-.2.45-.1.25-.15.4-.05.15-.15.35-.5 1.4-.5 2.5l-.1.4q-.25.95-.25 2.75 0 .85.15 2.3.2 1.5.4 2.1.6 1.85 1.4 3.8.2.5.5 1-.1.1-.5.4-.4.25-2.2 2.4-1.55 1.9-.8 6.75.45 3.5 2.4 7.75l.65 1.4H6.4Q4 34.95 3 37.9q-.2.6-.35 1.3v.1Q.85 46.85 3 51.35l.65 1.15v.05q-.35-.05-.35 0h.15Q1.8 52.6.8 54.6 0 56.25 0 58.15q0 2 1.1 5.5 1 3.4 2.15 5.3.95 1.85 1.65 3 .4.65.8 1.15l.2.25q.05.05.05.1.25.15.8 1.5-.25.95-2.7 3.45-.65.85-1.2 1.75-2.8 4.8-1.25 11.95.15.45.3.85.15.3.95 2.05.35.75.85 1.8.9 1.9 1.55 3.45.2.4.35.8 1.4 3.35 2.05 6.6.15.85.8 5.2v.1q1.35-1.55 2.6-2.9 2.35-2.65 3.8-4.95 1.5-2.3 2.35-4 .9-1.7 1.8-4.95.6 3.4.9 5.2v2.2q3.95-5.45 5.35-13.15 1.3-7.05-3.3-12.15-.15-.15-.35-.3-1.45-.95-1.85-2.65 1.05-1.65 1.2-1.85.3-.45.55-.8 7.25-10.2 4.9-14.4-.65-1.15-2.1-1.95-.4-.25-.75-.35.15-.35.5-.95.35-.55.8-1.35 1-1.65 1.3-2.8.7-2.5.9-3.25.25-1.05.25-2.15 0-1.85-1.05-2.55-.05-.05-.15-.1-.2-.2-.5-.4-.088-.087-.25-.1-.25-.15-.4-.15.15 0 .4.15.55-.7 1-1.35.3-.45.5-.85.15-.4.35-.75l.3-.4q0-.05-.1-.1-.3-.35-.65-.75-2.65-2.9-5-5.95l-2.2-3q-.25-.35-.5-.65-.3-.5-.65-.95-3.7-5.5-6.55-11.3-.3-.55-.55-1.15l-.1-.2q-.3-.65-.55-1.25-.1-.15-.15-.3-.35-.75-.65-1.5l-.1-.2q-.3-.75-.65-1.55-.05-.25-.15-.45Q7.25 5.2 5.8 0z"
                    id="HaiHig018__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.45 19H2.8l.65 1.4q.2-.05.4-.05.65 0 1.45.2.1 0 .15.05 1.2.35 2.65 1.1.1.05.15.15.3.1.55.3.05 0 .05.05.05 0 .05.05 1.05.6 2.65 2.65 1 1.2 1.6 3.6.2.65.35 1.15-1 .75-2.1 1.75-4.15 3.75-5.15 7.5-2.6-1.65-5.3-1.8-.1-.05-.3-.05L0 37l.65 1.15.05.05h.25q.8 0 1.55.25l2.45 1.25q.65.25 1.65 1.05.7.4.95.75 1.65 1.7 2 2.5l.9 1.95-.05.45q-.8 1.25-1.65 2.9-.45.6-.9 1.3-.6.75-1.2 2.2-.9 2.35-.6 4.85.2 1.7.95 3.45.1 0 .6 1-.7.25-2.1 1.4-3.5 3.4-3.15 9.45l.3 1.1q.8 2.2 2.45 4.85-.2-.75.55-2.65.6-1.5 1.05-2.2.5 2.15 1 4.1.5 1.95.4 5.3-.1 3.3-.4 5.85 1.65-3.75 3.2-6.25 2.95-4.75 2.95-7.85 0-3.85-1.6-6.45-.05-.1-.25-.35-.1-.4-.3-.75-.6-.9-1.2-1.85l-.5-.9q-1.1-2.65-1.35-3.4-2.5-5.45.5-10.4.2-.3.4-.55.1-.15.2-.35 2.5-4.35 2.85-4.9.75-.9 1.6-1.6.2-.2.45-.35 1.15-.9 2.45-1.5.7-.35 1.5-.6h.65q.55 0 1.3.35.15-.35.5-.95l-.5-.3q-.8-.35-1.4-.35-1 .1-1.25.1h-.2q-.1 0-.2.05l-2.7 1.5Q12.65 43 12 44.05q-.2.35-.5.85-.5-2.55-3.8-5.05v-.05l-.4-.25v-.05q1.1-3.65 5.1-7.25 1.1-1 2.1-1.75.55-.45 1.1-.8.25-.2.4-.3.65-.45 1.45-.75.85-.35 1.75-.5.6-.15 1.25-.2.2 0 .4-.05.25 0 .45-.05.3-.05.55-.05h.35v.1q.05 0 .1.05.6-.7 1.05-1.35-3.2-.9-8.2 1.55l-.5.3q-.3-1.5-1.4-3.1l-.2-.35q-.2-.25-.5-.6l-.75-1.05q0-.05-.05-.05-.55-.45-1.2-1.1l-.1-.2q1.2-2.65 2.45-4.4 1.35-1.9 3.15-3.45-.3-.5-.65-.95-1.45 1.2-2.85 2.9-2.15 2.65-2.95 5-.65-.55-1.7-1.1-1-.5-2.4-.9-.1-.05-.8-.05Q4.1 19 3.45 19M3.4.7q.2.5.5 1 .05.05.05.1.9-.5 1.95-.5h.05q0-.35 2.9.6-.3-.55-.55-1.15l-.1-.2Q5.65-.6 3.4.7z"
                    id="HaiHig018__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.25 4.9q1.3-.45 2.2-1.25 1.75-2.3.1-3.65-.25.3-.55.55-.05.05-.1.15-.55.4-1.25.75-.35.2-.8.35-5.4 2.25-10.2-.35-.6-.3-1.15-.75L1.45.65l-.3-.3-.4-.3Q-.85 1.4.9 3.65q.95.8 2.25 1.25 4.7 1.65 10.1 0z"
                    id="HaiHig018__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    id="HaiHig018__Symbol_26_0_Layer0_0_1_STROKES"
                    stroke="#900"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M25.35 42.3q-.25-.15-.4-.15"
                />
            </defs>
            <g transform="translate(94.25 179.4)" id="HaiHig018__col1n">
                <use xlinkHref="#HaiHig018__Symbol_26_0_Layer0_0_FILL" />
                <use xlinkHref="#HaiHig018__Symbol_26_0_Layer0_0_1_STROKES" />
            </g>
            <use
                xlinkHref="#HaiHig018__Symbol_27_0_Layer0_0_FILL"
                transform="translate(97.25 193.75)"
                id="HaiHig018__col1d"
            />
            <use
                xlinkHref="#HaiHig018__Symbol_28_0_Layer0_0_FILL"
                transform="translate(99.6 252.85)"
                id="HaiHig018__col2n"
            />
        </g></g>
    )
}
export default HaiHig018
