import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFC400",
    "col1n": "#FFD446"
}

function AccMor063({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M13.45 9.25l2.45-4.6h-5.15L8.45 0 5.7 4.65H0l2.7 4.6L.25 14H5.5L8 18.65 10.75 14H16l-2.55-4.75z"
                    id="AccMor063__Symbol_160_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M23.2 7.4v-.05q0-.5-.4-.85-.4-.4-.95-.4-.55 0-.9.4-.15.1-.25.25-.15.3-.15.6v.3q0 .1.05.2l-5.65.05-3-5.25q.3-.1.55-.3.1-.05.15-.1.35-.4.35-.95 0-.55-.35-.95-.4-.35-.95-.35-.55 0-.95.35-.35.4-.35.95 0 .55.35.95.05.05.2.15.2.15.45.25L8.45 7.9 2.7 7.85q.1-.3.1-.55 0-.55-.4-.95Q2 6 1.45 6q-.55 0-.9.35-.4.4-.4.95 0 .55.4.95.35.35.9.35h.35q.15-.05.4-.15l3 5.15-3.1 5.3v.05q-.35-.25-.8-.25h-.1q-.5.05-.85.35-.35.4-.35.95 0 .55.35.95.4.35.95.35.55 0 .95-.35.4-.4.4-.95 0-.2-.1-.4-.05-.1-.05-.2l5.95-.15 2.95 5.35q-.15 0-.25.1-.15.1-.35.25-.35.4-.35.95v.05q0 .5.35.85.4.4.95.4.55 0 .95-.4.35-.35.35-.85v-.05q0-.5-.25-.85l-.1-.1q-.35-.35-.75-.35l3-5.35 6 .15q-.15.1-.2.25-.05.2-.05.4 0 .55.4.95.4.35.95.35.55 0 .9-.35.4-.4.4-.95 0-.55-.4-.95-.35-.35-.9-.35h-.15q-.3.05-.6.2v-.05l-3.1-5.3 2.95-5.05h.05q.15.1.35.2h.55q.35-.1.7-.4l.05-.05q.35-.4.35-.9M11.85 5.7l2.05 3.95h4.15l-2.15 3.9 1.95 3.9H13.7l-2.2 3.9-2-3.9H5.3l2.2-3.95-2.05-3.85h4.2l2.2-3.95z"
                    id="AccMor063__Symbol_98_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor063__Symbol_160_0_Layer0_0_FILL"
                transform="translate(219.3 260.4)"
                id="AccMor063__col1n"
            />
            <use
                xlinkHref="#AccMor063__Symbol_98_0_Layer0_0_FILL"
                transform="translate(215.8 256.15)"
                id="AccMor063__col1d"
            />
        </g></g>
    )
}

export default AccMor063
