import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSpe058({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M180.3 97.3q-6.3-12.05-15.6-21.15l-.5-.5-.45-.45-.35-.35-.95-.85q-.35-.35-.7-.65-3.2-2.85-6.7-5.35l-.65-.65-.1-.1q-.2 0-.1-.4.05-.25.2-.6.05-.15.15-.3l.65-1.3q1.9-2.6 2.45-6.55 2.3-15.3-3.85-28.95-.85-1.9-1.75-3.65-3-5.6-7-10.15-1.15-1.3-2.35-2.5-8.95-8.25-27.5-10.95-1.5-.2-3.1-.45Q95.45-1.3 79 1.25l-1.45.25Q62.05 4.6 52.2 14.7q-.85.85-1.65 1.75-.95 1.05-1.8 2.15-3.95 6.15-5.25 13.95-1.8 11.05.3 21.85.2 1.1.55 2.1l.45 2.9h.05q-24 15.5-36.25 40.85-6.3 12.9-8.6 29.4 8.35-1.2 16.65-3.25 10.45-2.5 19.9-7.55 6.4-3.35 12.15-7.05.5-.05 1-.05 30.9 3.4 69.45-3.45 4.35-.8 8.7-1.65 3.4 3.2 6.55 6.7 5.9 6.6 13.5 10.7.15.1.3.15.65.4 1.4.75l1.75.9q16.2 8 33.85 7.15 2.5-.25 4.85-.4-1.1-13.15-4.65-23.65-2.15-6.3-5.1-11.65z"
                    id="HaiSpe058__Symbol_190_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M152.05 16.85q.3 1.4 1.05 6.75.7 5.25.95 9.45.25 4.2-.05 6.1-.3 1.9-.95 3.85-.65 1.95-1.15 2.8.4-3.45.05-6.8-.4-3.4-.7-7.1-.35-3.75-3.3-10.4.75 9.5.1 14.55-.7 5-2.45 7.05-.25-12.2-1.5-20.35-1.3-8.2-7.15-14.05 1.55 3.6 1.95 8.95.1 1.8.1 9.25 0 6.3-.25 7.8-.2 1.05-1 5.05-.75-12.1-1.9-16.3-2.6-9.25-9.5-20.8v.2q.8 20.1-2.3 32.75-.7-8.5-1.45-13.05-1.6-9.65-5.45-20.85.6 6.15-.6 14.95-1.55 11.1-5.25 15.8-.3-2.45-1.55-9.15-1.4-7.25-3-14.4h-.2v.35q-2 17.55-4.5 22.25-.1-.45-1.5-9.1-1.2-7.6-2.85-13.15v.2q-2.2 15.3-4.15 22.65v-.2q-2.4-3.3-3.45-11.35-1-7.4-.5-14.5-2.55 5.65-4.55 14.75-1.3 5.8-1.95 11.55-1.8-16.7-1.8-22.55 0-1.25.3-4.65.25-3.4.25-5.15-6.55 5.15-11.4 16.9-3.85 9.3-5.25 18.65-.05-.1-.05-.15-1.6-5.25-1.6-11.6 0-4.35.45-7.9.3-2.7 1.25-7.2-3.3 1.7-7 11.05Q54.5 29.1 55 39.55q-2.1-2.5-2.2-7.6-.1-5.1-.4-15.25-3.95 8.75-4.65 13.9-.7 5.1-.3 11.2-.95-3.7-1.15-4.2-.55-1.55-.45-7.95.05-6.45 1.75-12.5Q49.3 11 49.9 9.6q.55-1.4.65-1.8-.95 1.05-1.8 2.15Q44.8 16.1 43.5 23.9q-1.8 11.05.3 21.85.2 1.1.55 2.1l.3 1.75 4.95-2.9v-.15q2.35-1 4.65-1.85 6.4-2.4 12.6-4.1 9.1-2.5 17.8-3.5 19.95-2.35 38.1 3 11.95 3.5 23.2 10.45 1.85 1.1 3.65 2.35v.1l4.8 4.6q.05-.15.15-.3l.65-1.3q1.9-2.6 2.45-6.55 2.3-15.3-3.85-28.95-.85-1.9-1.75-3.65m33.15 107.5q2.5-.25 4.85-.4-41.3-.75-59.6-30.55-46.1 15.55-83.7 4.9Q28.5 115 0 121q8.35-1.2 16.65-3.25 10.45-2.5 19.9-7.55 6.4-3.35 12.15-7.05.5-.05 1-.05 30.9 3.4 69.45-3.45 4.35-.8 8.7-1.65 3.4 3.2 6.55 6.7 5.9 6.6 13.5 10.7.15.1.3.15.65.4 1.4.75l1.75.9q16.2 8 33.85 7.15z"
                    id="HaiSpe058__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M107 21.75l.65.5q.35.25.65.5.3.2.6.45.5.35 1 .8l.1.05v.05l.05.05v-.05l-.1-.25q-.2 0-.1-.4.05-.35.35-.9l.65-1.3-2.9-2.2q-1.05-.8-2.05-1.5Q62.65-14.05 4.45 11.2L0 13.1.45 16H.5l.05-.05H.6l8.4-3.3Q64-9.3 105.3 20.5q.85.6 1.7 1.25z"
                    id="HaiSpe058__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe058__Symbol_190_0_Layer0_0_FILL"
                transform="translate(97.6 9.85)"
                id="HaiSpe058__col1n"
            />
            <use
                xlinkHref="#HaiSpe058__Symbol_121_0_Layer0_0_FILL"
                transform="translate(97.6 18.5)"
                id="HaiSpe058__col1d"
            />
            <use
                xlinkHref="#HaiSpe058__Symbol_51_0_Layer0_0_FILL"
                transform="translate(141.95 53.25)"
                id="HaiSpe058__col1s"
            />
        </g></g>
    )
}

export default HaiSpe058
