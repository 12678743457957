import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#007070",
    "col1n": "#00AFAF",
    "col1s": "#004848",
    "col2d": "#C2C2C2",
    "col2n": "#FFFFFF"
}

function AccHat112({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M250.4 92.85q-1.1-8.65-5.55-17.6l-.5-1q-2.6-5.2-6.4-10.5-8-11.25-20.95-23-8.1-7.3-18.1-14.8l-1.6-1.2q-.95-.75-2-1.5-9.3-6.1-18.6-10.55-16.75-8.05-33.5-11Q135.1.25 126.95.05q-.8-.05-1.55-.05t-1.55.05q-8.15.2-16.25 1.65-16.75 2.95-33.5 11-9.3 4.45-18.6 10.55-1.05.75-2 1.5l-1.6 1.2q-10 7.5-18.1 14.8-12.95 11.75-20.95 23-3.8 5.3-6.4 10.5l-.5 1Q1.5 84.2.4 92.85q-.75 5.85 0 11.55 1.2 8.85 6.05 17.35.15.25.35.55.85 1.05 1.7 2 2.1 2.2 4.35 3.95 2.75 2.15 5.75 3.55 11.7 5.55 26.85 0 7-2.55 14.7-7.5 1.5-.95 3-2l62.25-50.1.05.05 62.2 50.05q1.5 1.05 3 2 7.7 4.95 14.7 7.5 15.15 5.55 26.85 0 3-1.4 5.75-3.55 2.25-1.75 4.35-3.95.85-.95 1.7-2 .2-.3.35-.55 4.85-8.5 6.05-17.35V92.85z"
                    id="AccHat112__Symbol_458_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M72.15 12.05q.05-.95.15-1.8.05-.5.15-.95-.75-.7-1.45-1.25Q59.95-1.4 45.95.25q-.1 0-.2.1.05.05.1.15l6 .75q.55.05 1.1.15 12.15 1.75 19.2 10.65m.8 7.7q-.15-.55-.25-1.1Q37.15 20.2 8 42.75q-1.55 1.2-2.35 2.15.05 0 .15.05.15 0 .3-.1 29.55-22.25 66.45-24.1Q53.1 28.3 17.85 61.3q-8.7 8.15-13.6 13.3-3.2 3.35-4.25 4.7.85-.75 2.2-2 2.95-2.7 7.7-7.25 38.1-36.1 64.2-47.3-.45-.95-.8-1.95-.2-.55-.35-1.05m12.15 10.8h-.65v15.2q-.05.15.05.25l.6-.5.05.05.55.45q.1-.1.05-.25v-15.2h-.65M124.35.5q.05-.1.1-.15-.1-.1-.2-.1-14-1.65-25.05 7.8-.7.55-1.45 1.25.1.45.15.95.1.85.15 1.8 7.05-8.9 19.2-10.65.55-.1 1.1-.15l6-.75m40.05 44.45q.1-.05.15-.05-.8-.95-2.35-2.15-29.15-22.55-64.7-24.1-.1.55-.25 1.1-.15.5-.35 1.05-.35 1-.8 1.95 26.1 11.2 64.2 47.3 4.75 4.55 7.7 7.25 1.35 1.25 2.2 2-1.05-1.35-4.25-4.7-4.9-5.15-13.6-13.3-35.25-33-54.7-40.55 36.9 1.85 66.45 24.1.15.1.3.1z"
                    id="AccHat112__Symbol_401_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M26 17.25q0-.65-.05-1.25-.05-.95-.15-1.8-.05-.5-.15-.95-.7-4.65-3.3-8.2Q18.7 0 13.5 0h-1Q7.3 0 3.65 5.05q-2.6 3.55-3.3 8.2-.1.45-.15.95-.1.85-.15 1.8-.05.6-.05 1.25 0 1.3.15 2.55v.2q0 .2.05.45l.05.25v.2l.05.35q.1.7.3 1.35.1.55.25 1.1.15.5.35 1.05.35 1 .8 1.95.7 1.45 1.65 2.75 3.6 4.95 8.7 5.05H13.65q5.1-.1 8.7-5.05.95-1.3 1.65-2.75.45-.95.8-1.95.2-.55.35-1.05.15-.55.25-1.1.2-.65.3-1.35l.05-.35v-.2l.05-.25q.05-.25.05-.45v-.2q.15-1.25.15-2.55z"
                    id="AccHat112__Symbol_379_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M24.35 44.25l-.05-.05L0 63.75h48.6l-24.25-19.5M34.3 10.3q-.55-3.6-2.6-6.4Q28.9-.05 24.8 0h-1q-4.1-.05-6.9 3.9-2.05 2.8-2.6 6.4-.1.35-.15.75-.15 1.15-.1 2.4 0 .9.2 2.85v.25q.15.95.45 1.9.1.4.25.8.7 2 1.95 3.7 1.3 1.8 2.9 2.8 4.4 2.45 9.05 0 1.55-1 2.85-2.8 1.25-1.7 1.95-3.7.15-.4.25-.8.3-.95.45-1.9v-.25q.2-1.95.2-2.85.05-1.25-.1-2.4-.05-.4-.15-.75z"
                    id="AccHat112__Symbol_350_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.25 0L0 1h18.1l-1.25-1H1.25z"
                    id="AccHat112__Symbol_327_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat112__Symbol_458_0_Layer0_0_FILL"
                transform="translate(67.8 20.8)"
                id="AccHat112__col1n"
            />
            <use
                xlinkHref="#AccHat112__Symbol_401_0_Layer0_0_FILL"
                transform="translate(108.1 47.5)"
                id="AccHat112__col1d"
            />
            <use
                xlinkHref="#AccHat112__Symbol_379_0_Layer0_0_FILL"
                transform="translate(180.2 43.55)"
                id="AccHat112__col1s"
            />
            <use
                xlinkHref="#AccHat112__Symbol_350_0_Layer0_0_FILL"
                transform="translate(168.9 48.8)"
                id="AccHat112__col2n"
            />
            <use
                xlinkHref="#AccHat112__Symbol_327_0_Layer0_0_FILL"
                transform="translate(184.15 99.3)"
                id="AccHat112__col2d"
            />
        </g></g>
    )
}

export default AccHat112
