import { AssetSvgProps } from "../../../shared/assets"

function AccHat100({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#1B1510"
                    fillOpacity={0.302}
                    d="M263.1 144.25v-9l-69.95 9.15-70.05-9.55v8.55l70.1 7.3 69.9-6.45z"
                    id="AccHat100__Layer16_0_FILL"
                />
                <path
                    fill="url(#AccHat100__Pattern_1)"
                    d="M263.8 79.3l-2.6.8q-9.05 2.8-18.85 4.8-9.15 1.9-18.4 2.85-9.65 1-19.5 1.05-9.8.05-19.5-.95-8.55-.85-17-1.7-9.3-.95-18.55-1.65-9.9-.8-18.7-5.1l-1.1-.5q-7.7-3.6-16.05-4.65-13.5-1.8-14.1 6.2 0 .1.05.25l1.75 20.1q-10.5 28.25-5.7 69.55h.25q.25 1.55.55 3.1 2.965 15.636 11.95 28.75 2.722 3.912 5.95 7.6.4.6.8 1.15.95 1.3 1.9 2.55 2.8 3.65 5.9 6.95.6.65 1.25 1.35.75.75 1.5 1.55 1.55 1.5 3.15 2.95l1.3 1.2q.2.15.4.35l.15.15q.3.25.65.55.4.35.8.65l.3.3q2.55 2.1 5.2 4.05 5.55 4.05 11.75 7.35.4.2.85.4.55.3 1.15.6.05.05.2.1l.2.1.2.2h.1q.05.05.15.05 2.25 1.1 4.55 2.1.3.1.55.25.2.15.5.25h.05q-.049.001 0 .05.05-.05.15.05.05 0 .15.05h.15q1.502.636 4.65 1.5.625.08 5.5 1.05l6.95-69.8-2.95-.65v-.15l-46-10.15v-31.2l1.8.25v-.25l65.45 8.9 65.05-8.5.35 35.3-44.05 4.75 6.4 70.45 10.75-4.1-.05-.15q1.85-.65 3.7-1.35 4.95-1.95 9.65-4.6.05-.05.1-.05 2.85-1.65 5.65-3.5 2.65-1.85 5.25-3.85.6-.5 1.2-.95 0-.05.05-.05.5-.45 1.1-.9.45-.45.95-.85.85-.75 1.75-1.55 3.5-3.2 6.9-6.9 1.2-1.35 2.45-2.75 2-2.35 3.95-4.9.25-.3.45-.6 1-1.3 2-2.65.4-.55.85-1.1.35-.55.7-1.05l1.4-2q0-.1.1-.15.133-.2.25-.4.683-1 1.35-2 .35-.6.75-1.2.45-.75.95-1.5l.1-.2q.6-1.05 1.2-2 .1-.2.2-.35.05-.1.1-.15.45-.6.9-1.25.65-1 1.25-2.15 2.3-4.45 3.6-11.35 7.8-28.4.55-64.2-1.35-16.9 2.3-29.9.15-.3.3-.55-.5-3.25-1.4-6.35-2.85-9.55-13.8-5.2-7.45 3-15.1 5.45z"
                    id="AccHat100__Layer7_0_MEMBER_0_FILL"
                />
                <path
                    fill="#900"
                    d="M199.5 117.35q-2.9-2.8-7-2.75-4.15-.05-7.05 2.75-1.3 1.25-2 2.75-.9 1.85-.9 4.15 0 2.2.9 4.1.7 1.4 2 2.65 2.9 2.8 7.05 2.8 4.1 0 7-2.8 2.95-2.8 2.95-6.75 0-4.05-2.95-6.9z"
                    id="AccHat100__Layer7_0_MEMBER_1_FILL"
                />
                <path
                    fill="#FC0"
                    d="M188.4 124.4q0 1.7 1.25 2.9t3.05 1.2q1.75 0 3-1.2t1.3-2.9q-.05-1.75-1.3-3-1.25-1.2-3-1.2-1.8 0-3.05 1.2-1.25 1.25-1.25 3z"
                    id="AccHat100__Layer7_0_MEMBER_2_FILL"
                />
                <pattern
                    id="AccHat100__Pattern_1"
                    patternUnits="userSpaceOnUse"
                    patternTransform="matrix(.3902 0 0 .37658 87.75 3.9)"
                    width={121}
                    height={116}
                >
                    <use xlinkHref="#AccHat100__Prof_Chaos1" />
                </pattern>
                <image
                    id="AccHat100__Prof_Chaos1"
                    x={0}
                    y={0}
                    width={121}
                    height={116}
                    xlinkHref="./Bilder/Prof_Chaos1.jpg"
                />
            </defs>
            <use xlinkHref="#AccHat100__Layer16_0_FILL" />
            <use xlinkHref="#AccHat100__Layer7_0_MEMBER_0_FILL" />
            <use xlinkHref="#AccHat100__Layer7_0_MEMBER_1_FILL" />
            <use xlinkHref="#AccHat100__Layer7_0_MEMBER_2_FILL" />
        </g></g>
    )
}

export default AccHat100
