import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function ShiDre010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.2.7V.45l-.7.25v.05l.2 1.1-.25-1.25q-.15.05-.3.15-.5.15-1 .35-2.55.85-16.4 2.55-13.9 1.65-38.95 1.9-25.1.25-37.65-1.8Q18.6 1.65 15.2.4l-.5-.2-.6-.2-.05.1H14l-.1.6-2.7 14q-.55 2.7-1.1 5.45l-3.45 14.3q-.95 3.7-1.85 7.35 9 2.8 17.3 5.05-9.85 1.35-19.8 3.2L0 56.45q12.3 2.65 24.4 4.45 44.95 6.5 87.2.1 14.3-2.15 28.25-5.8l-2.1-5.35q-8.85-1.75-17.85-3 14.1-4.8 14.2-4.85.15-.1.45-.15.4-.1.8-.1l-5-20.15q-.65-3.1-1.3-6.15L126.2.7z"
                    id="ShiDre010__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M138.9 69.95q-.1 0-.15.05Q76.8 83.35 9.7 69.75q-.05.25-.1.45l-.95 3.6v.25L7.2 78q70.65 14.85 135.45-.2l-2.4-8.1q-.4 0-.8.1-.3.05-.55.15m9.45-53.15q-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.15-.25-.3-.55-.55.5-1.05.95-.55.5-1.15 1.05-3.45 2.85-7.9 5.5-1.15.7-2.4 1.4-4.45 2.45-9.25 4.45-18.6 7.5-42.9 7.5-25.55 0-44.85-8.35-3.8-1.65-7.3-3.6-2.05-1.1-3.85-2.25-4.2-2.65-7.4-5.55-.6-.55-1.15-1.05l-.6-.6-1.2 1.9q-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95Q1.7 16.55 0 21.1l18.95 6.95.05-.1.6.2.5.2q3.4 1.25 7.05 2.35 20.75 6.2 48.3 6.2 27.6 0 48.4-6.2 2.65-.8 5.2-1.65.5-.2 1-.35.15-.1.3-.15l.25 1.25-.2-1.1v-.05l19.45-8q-.75-1.95-1.5-3.85z"
                    id="ShiDre010__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre010__Symbol_95_0_Layer0_0_FILL"
                transform="translate(122.4 263.35)"
                id="ShiDre010__col1n"
            />
            <use
                xlinkHref="#ShiDre010__Symbol_34_0_Layer0_0_FILL"
                transform="translate(117.5 235.4)"
                id="ShiDre010__col2n"
            />
        </g></g>
    )
}

export default ShiDre010
