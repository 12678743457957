import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1l": "#E4B670",
    "col1n": "#D19532"
}

function AccHat121({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M170.35 18.15q-.1-.1-.15-.3-.05-.2-2.45-4.2-2.4-4.05-14.5-6.65-11.2-2.95-22.15-4.75-3.3-.55-6.65-.95-.75-.1-1.45-.2-.5-.05-.9-.1-.6-.05-1.25-.15-.5-.1-1.05-.1-2.4-.25-5.5-.5l-3.85 4.5L88.05 0l-22.4 4.75-3.9-4.5q-3.1.25-5.5.5-.5 0-.95.1-.7.1-1.3.15-.4.05-.85.1l-1.5.2q-3.35.4-6.7.95-.75.15-1.55.25-10.9 1.85-22.05 4.85-9.25 2.2-10.4 3.15-1.15.85-2.65 3.2-1.45 2.35-1.95 3.35-.55 1-.65 1.1-.6 1.1-1.05 1.95-.45.85-.7 1.3-.5.95-.95 1.9-.3.7-.8 1.95l-.1.2q-.85 2-2.1 5.25-.2 2.2 2.1 1.65l.1-.05q24.5-6.85 48.75-9.9l1.75 1.05L80.1 43.1l7.95 5.2 2-1.2 35.05-24.7q24 3 48.3 9.75.45.15.95.3 2.25.4 1.7-2-1.25-3.1-2.1-5l-.1-.2q-.5-1.25-.8-1.95l-.9-1.9q-.3-.45-.75-1.3-.45-.85-1.05-1.95z"
                    id="AccHat121__Symbol_467_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M33.65 27.7l-2.3-.3-1 5.3 3.9-1.55-.6-3.45M.85 9.95q-.05.2-.15.4-.3.95-.7 2.15l13.3 13.3v-.35q.55-1.5 1-2.5l3.1.15.8-3.1L5.85 4.95l-.9 3.8-3.3-.35q-.35.55-.8 1.55m14.05 11.7l.05-.05h.25q-.164.005-.3.05M36.4 7.7l-1.7 14.6-10.6 2.95 10.5 1.55 1.75 14.45.35-1.6 1.45-12.85 10.5-1.55-10.6-2.95L36.4 7.7m-2.1 7.8l.95-7.55L20 11.2 10.1 0Q7 .25 4.6.5q-.5 0-.95.1l15 18 15.65-3.1m-5.7 2.4l-.05.1 2.9 4.35 2.65-1 .15-2.55-5.65-.9m13.85 14.8l-1-5.3-2.4.35-.55 3.5 3.95 1.45m1.8-14.7l-.05-.1-5.7 1 .55 2.4 2.4.85 2.8-4.15M68.15.5q-2.4-.25-5.5-.5L52.8 11.2 37.5 7.95l.95 7.55 15.65 3.1L69.2.6q-.5-.1-1.05-.1m3 7.9l-3.35.35-.85-3.8L54.6 20l.8 3.1 3.1-.15q.45 1 .95 2.5v.35L72.8 12.5q-.4-1.2-.7-2.15-.1-.2-.15-.4-.4-1-.8-1.55m-13.3 13.2l.05.05q-.188-.047-.35-.05h.3z"
                    id="AccHat121__Symbol_437_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M47.65 10.05h-.15q-3.1.45-6.2 1-19.3 3.1-38.75 8.2-.45.85-.7 1.3-.5.95-.95 1.9-.3.7-.8 1.95l-.1.2 1.7-.5 2.15-.45q18.75-4.95 37.45-8 3.85-.65 7.7-1.2l1.25-.25L56 19.95h.05l.3.35-7.5 1.25 1.75 1.05 6.9-1.2 1.3 1.15q-.85-.7-1.25-1L78 42.25l5.9 4-36.25-36.2M120 .15q-.6-.05-1.25-.15l-15.1 18L88 14.9l-.95-7.55 15.3 3.25 6-6.7-1.25-.25-5.1 5.3L85.95 5.5l-16.1 3.45-5-5.3-1.3.25 6 6.7L84.8 7.35l-.95 7.55L68.2 18l-15-18q-.7.1-1.3.15l34.05 41.3L120 .15M88.6 20.7l-.55-2.4 5.7-1 .05.1-2.8 4.15-2.4-.85m-4.8-2.5l-.15 2.55-2.65 1-2.9-4.35.05-.1 5.65.9m3.8 3.5l10.6 2.95-10.5 1.55-1.45 12.85-.35 1.6-1.75-14.45-10.5-1.55 10.6-2.95 1.7-14.6 1.65 14.6m1 5.45l2.4-.35 1 5.3-3.95-1.45.55-3.5m-5.4-.05l.6 3.45-3.9 1.55 1-5.3 2.3.3m86.85-6.55q-.3-.45-.75-1.3-22.45-5.9-44.95-9.2h-.15l-36.25 36.2 5.95-4 13.35-13.6h.05l7-7.1q-.35.3-1.2 1l1.25-1.15 6.95 1.2 1.7-1.05-7.5-1.25.3-.35h.05l5.8-5.75 1.2.25q22.6 3.25 45.15 9.2l2.2.45q.8.25 1.65.5l-.1-.2q-.5-1.25-.8-1.95l-.9-1.9z"
                    id="AccHat121__Symbol_406_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat121__Symbol_467_0_Layer0_0_FILL"
                transform="translate(104.45 84.4)"
                id="AccHat121__col1n"
            />
            <use
                xlinkHref="#AccHat121__Symbol_437_0_Layer0_0_FILL"
                transform="translate(156.1 84.65)"
                id="AccHat121__col1l"
            />
            <use
                xlinkHref="#AccHat121__Symbol_406_0_Layer0_0_FILL"
                transform="translate(106.55 85.25)"
                id="AccHat121__col1d"
            />
        </g></g>
    )
}

export default AccHat121
