import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiLon032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M185.85 46.65q-6.4-9.35-6.85-9.9-6.55-8.25-14.6-14.7Q135.9.55 98 .05h-.3Q60.55-.9 56.1 11.2q-1.25-.55-2.5-.8-12.2-.45-26.25 17.95-8.4 9.9-14.05 22-11.6 23-12 45.95l-.4 96.6-.9 33.7v41.7q1.95-9.85 7.4-15.3 1.2-.85 2.25-1.55v-.1q4.35-2.55 6.7-3.4.25-.1.55-.2l-.05-.05h.35q-2.95-3.85-2.95-8.9 0-5.6 3.6-9.75l.3-.3.05-.05q0-.05.05-.05.2-.25.45-.5l.2-.2.05-.05q0-.05.05-.05.1-.1.25-.15l-.05-.05q.4-2.3.85-4.55l.9-4.2q2.95-12.55 8.25-24.15 1.65-4.5 3.75-8.85l1.1-2q1.3-2.3 2.7-4.5.15-.25.3-.45 1.4-2.2 4.7-7.1-.15-.1-.25-.2-2.25-1.8-4.35-3.8-1.65-1.5-3.25-3.1Q11.85 142.7 7.15 113.5v-4.05q0-.2-.05-.45.05-.1.05-.2l.1-.3.05-.2q.1-.35.2-.65Q11 95.5 22.15 87.5q28.5-19.85 32.2-35.45 1.25 1.65 2.75 3.4 2 2.4 4.5 5 18.3 21 39 38.25 37.9 27.8 59.85 37.65 19.8 8.85 21.85 10.1l.1-.2q-.05-.05-.1-.05-42.85-26.35-60.6-56-.65-1.05-1.25-2.1.7 1 1.4 2.05 22.55 33.5 61.55 48.9.85.3 2.75.8.7.15 1.35.35-6.8 13.25-18.1 24.55-1.6 1.6-3.2 3.1-2.25 2.05-4.5 3.95 3.25 4.95 4.65 7.15.15.2.3.45.45.7.9 1.45.9 1.5 1.8 3.05l1.1 2q.75 1.45 1.5 3l.65 1.25.3.7q.05.1.1.25.05.1.15.25.5 1.1 1 2.25l.8 1.9q.15.45.45 1.45v.05l.1.2q.9 2.2 1.75 4.4.15.5.35 1l.1.2.4 1.2 1.3 3.8q.85 2.65 1.6 5.3.65 2.45 1.25 4.9.65 2.7 1.15 5.35.15 1.05.35 1.9 0 .2.05.4l.1.4q.05.3.1.55.05.5.15 1.05.1.05.3.2l.05.05v.05q.1.1.25.2.2.25.45.5l.1.1q.1.15.25.3 3.6 4.15 3.65 9.75-.05 5.7-3.8 9.9 10.3 4.1 14.4 19.6v-40.95q.048 0 .05.05l-.7-113.85q.55-9.35-.1-18.7-.8-10.65-3.15-21.3-.55-2.15-1.2-4.2-2.7-12.85-7.85-21.15-.5-.8-1-1.55M21.25 149.9l.25.25v.05q-.15-.15-.25-.3z"
                    id="HaiLon032__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 89.9q3.25-17.15 14.95-30.5-4.65 21.3-7.5 44Q14 82.5 20.4 75q6.35-7.5 10.45-11.35 1.65-2.65 3.5-5.2l.2-.3q-2.25-1.8-4.35-3.8-1.65-1.5-3.25-3.1Q4.9 29.2.2 0L0 89.9m14.55-53.25v.05q-.15-.15-.25-.3l.25.25m172.2-5.55q-.7-2.65-5.1-4.1l-.7-.2q-.2-.1-.4-.1-6.8 13.25-18.1 24.55-1.6 1.6-3.2 3.1-2.25 2.05-4.5 3.95 3.25 4.95 4.65 7.15.15.2.3.45.45.7.9 1.45.05 0 .1.05 1.25.75 3.35 2.8 2.05 2 2.5 3 7.9 8.85 12.95 28.25l-5.75-43.5q11.85 13.85 13.5 28.8V36.8q.35-2.45-.5-5.7z"
                    id="HaiLon032__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M34.6 11.85q-1 .3-2.15.7-1.7.6-4.05 2.3-3.15 2.3-3.35 2.45-2.15 1.35-4.65 4.1-.8.8-1.6 1.8-.55.6-1.05 1.25-1.35 1.7-5.95 8.35 2-1.45 10.5-5.25v.25q-6.35 5.05-13.45 16.7-5.3 8.6-8.85 17v.6q3.1-7.25 7.65-12.3 6.15-6.85 17-12.55.1-.1.15-.2h.25l-.4.2q-5.8 8.45-7.4 11.15-3.2 5.6-4.2 10.5 3.05-5.8 7.35-10.55 4.75-5.15 13.8-11.8-.3-2.65-1.1-7.4-.65-4.15-.65-7.35 0-3.15 1.15-6.65.6-1.9.9-2.8.1-.35.2-.55-.05 0-.1.05M76.35 0h-1.1q-.65.05 1.1 0m88.85 48.15q-.5-.8-1-1.55-6.4-9.35-6.85-9.9-6.55-8.25-14.6-14.7Q114.25.5 76.35 0v.05q-17.8 1.3-27 5.4-.45.2-.85.4.4 0 .8.05 14.8.85 23.6 4.15-6.65-.2-13.6.3h-.05q-6.45 0-13.75 1.25 9.05 3.1 18.75 10l-20.5-1.25 18.75 13.5q-12.7.4-21.75-5.25 5.75 9.5 13.8 15.25-12.25-2.8-17.1-6.4 26.9 43.75 68.05 73.15-23.65-27.7-21-50.5 27.2 47.55 80.75 71.75-20.15-18.95-38.55-44 20.8 18.75 46.8 30-24.3-20.65-41.5-39 22.25 13.4 40.5 23-18.3-15.5-33.3-30.1-.8-.65-1.85-1.55l.5.25-.6-.6.8.7q15.6 10.85 33.2 17.05l-29.5-26.75q14.55 8.95 31 15Q162.1 65.3 155.9 54.7q11.35 7.4 15.95 14.1l.8 1.2q.45.8.85 1.6-.2-1.15-.45-2.3-2.7-12.85-7.85-21.15z"
                    id="HaiLon032__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiLon032__Symbol_91_0_Layer0_0_FILL"
                transform="translate(90.8 56.8)"
                id="HaiLon032__col1n"
            />
            <use
                xlinkHref="#HaiLon032__Symbol_58_0_Layer0_0_FILL"
                transform="translate(97.75 170.3)"
                id="HaiLon032__col1d"
            />
            <use
                xlinkHref="#HaiLon032__Symbol_30_0_Layer0_0_FILL"
                transform="translate(112.45 56.85)"
                id="HaiLon032__col1l"
            />
        </g></g>
    )
}

export default HaiLon032
