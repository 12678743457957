import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF008C",
    "col2d": "#6486A6",
    "col2l": "#C1D2E2"
}

function AccGla010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M73.4 6.3v-.05Q73.2 4.7 72.6 4q-1.1-1.3-3.7-1.05h-.05Q39.75 4.45 9.1.05h-.05Q4.5-.45 1.95 3.7q-.2.2-.3.45-.1.05-.15.2h.25L0 24.05q.05.1.05.25t-.05.25q.1.15.15.35v9.5q.15 4.35 3.5 6Q37 46.15 68.1 44q5.35-.8 6.6-5.3V16.4l-.1-1v-.45h-.05V13.5h-.05l-1.2-7.2h.1m81.85-1.9h.2q-.05-.15-.1-.2-.15-.25-.3-.45Q152.45-.4 147.9.1h-.05Q117.2 4.5 88.1 3h-.05q-2.5-.25-3.65 1-.65.7-.9 2.25v.05h.05v.05h.1l-1.2 7.2h-.1l-.05 25.2q1.2 4.5 6.55 5.3 31.1 2.15 64.45-3.6 3.35-1.65 3.55-6v-9.5q0-.2.1-.35-.05-.1-.05-.25t.05-.25l-1.7-19.7z"
                    id="AccGla010__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    fillOpacity={0.502}
                    d="M77.9 17q0-.2-.05-.35h-1.7v.45l.1 1v22.3q-1.25 4.5-6.6 5.3-31.1 2.15-64.45-3.6-3.35-1.65-3.5-6v-9.5q-.05-.2-.15-.35-.1.25-.55.25-.55 0-.95-.05-.05.05-.05.15v9.5q.2 5.55 4.7 7.6l.2.1q33.6 5.75 64.95 3.6h.05q6.6-1.05 7.95-6.75.05-.1.05-.2V17m-2.95-9.05h1.8q-.2-1.4-.55-2.25-1.3-3.15-5.85-2.7h-.05Q41.3 4.45 10.85.05h-.05Q5.2-.6 2.05 4.45q-.35.5-.65 1.15-.15.15-.15.35 0 .05.05.1 1.15 0 1.6.25.05-.15.15-.25.05-.15.15-.2.1-.25.3-.45 2.55-4.15 7.1-3.65h.05q30.65 4.4 59.75 2.9h.05q2.6-.25 3.7 1.05.6.7.8 2.25m84.1 18.6q-.45 0-.55-.25-.1.15-.1.35v9.5q-.2 4.35-3.55 6-33.35 5.75-64.45 3.6-5.35-.8-6.55-5.3l.05-23.8h-1.65q-.1.2-.1.4V40.5q0 .1.05.2 1.35 5.7 7.95 6.75h.1q31.3 2.15 64.9-3.6l.2-.1q4.5-2.05 4.7-7.6v-9.5-.15q-.45.05-1 .05m-.35-20.9q-.35-.65-.7-1.15-3.15-5.05-8.75-4.4h-.05q-30.45 4.4-59.4 2.95h-.05q-4.6-.45-5.85 2.65-.35.85-.45 2.25h1.6q.25-1.55.9-2.25 1.15-1.25 3.65-1h.05q29.1 1.5 59.75-2.9h.05q4.55-.5 7.15 3.65.15.2.3.45.05.05.1.2.1.1.2.25.4-.25 1.6-.25V6q0-.2-.1-.35z"
                    id="AccGla010__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M17.85.85Q17.8.8 17.8.75L.25 0l-.1 8L15 10.3l-.1 6.95-14.85-3.4L0 17l15.85 4q.25.15.75.25.4.05.95.05.45 0 .55-.25.05-.1.05-.25t-.05-.25L19.85.85h-.25q-.1.1-.15.25-.45-.25-1.6-.25M101.6 2.8v-.05H100v.05h-6.7v-.05h-1.8v.05h-.1l1.2 7.2h.05v1.45h7.8v-1.4h.1l1.2-7.2h-.1V2.8h-.05m91.45 5.25l-.1-8-17.6.75v.1q-1.2 0-1.6.25-.1-.15-.2-.25h-.2l1.7 19.7q-.05.1-.05.25t.05.25q.1.25.55.25.55 0 1-.05.45-.1.7-.25l15.85-4-.05-3.15-14.8 3.4-.15-6.95 14.9-2.3z"
                    id="AccGla010__Symbol_33_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla010__Symbol_80_0_Layer0_0_FILL"
                transform="translate(114.05 140.45)"
                id="AccGla010__col2l"
            />
            <use
                xlinkHref="#AccGla010__Symbol_64_0_Layer0_0_FILL"
                transform="translate(112.5 138.75)"
                id="AccGla010__col2d"
            />
            <use
                xlinkHref="#AccGla010__Symbol_33_0_Layer0_0_FILL"
                transform="translate(95.95 143.95)"
                id="AccGla010__col1n"
            />
        </g></g>
    )
}

export default AccGla010
