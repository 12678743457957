import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#0000FF"
}

function ShiMot003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.85 5.8Q29.05 1.05 22.7.2 21.3 0 19.85 0 11.6 0 5.8 5.8 0 11.6 0 19.8t5.8 14q5.8 5.8 14.05 5.85 8.2-.05 14-5.85 5.8-5.8 5.8-14t-5.8-14z"
                    id="ShiMot003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <g id="ShiMot003__col1n" transform="translate(172.65 261.3)">
                <use
                    xlinkHref="#ShiMot003__Symbol_3_0_Layer0_0_FILL"
                    id="ShiMot003__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default ShiMot003
