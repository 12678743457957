import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFD95B",
    "col1n": "#FFC400"
}

function ObjFoo036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25 2.55q-2.7 0-4.6-1.8L20.05.4q-.15-.2-.3-.35-.15.15-.35.3-2.1 1.8-5.05 1.8-2.95 0-5.05-1.8-.2-.2-.4-.35-.45.65-1.95 1.5-2 1.1-3.15 1.15H0V43.4q0 .05.05.15.05.6.25 1.1.45 1.15 1.65 1.65.9.35 2.2.4h25.7q1.3-.05 2.2-.4 1.7-.75 1.9-2.75H34V2.65h-1.05q-.9-.25-2-.85-1-.55-1.65-.8-1.85 1.55-4.3 1.55z"
                    id="ObjFoo036__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M1.8.15Q1.5 0 1.05 0H.8Q.65 0 .55.1q-.1 0-.2.05Q0 .3 0 .5v33q0 .2.35.35l.2.1q.1.05.25.05h.25q.45 0 .75-.15t.3-.35V.5q0-.2-.3-.35m8.2 0Q9.7 0 9.25 0H9.1q-.05 0-.1.05-.25 0-.45.1Q8.2.3 8.2.5v33q0 .2.35.35.2.1.45.15h.25q.45 0 .75-.15t.3-.35V.5q0-.2-.3-.35M17.35 0q-.354.002-.6.1-.15.05-.25.1-.35.15-.35.35v33q0 .2.35.35.3.15.7.15.45 0 .75-.15t.3-.35v-33q0-.2-.3-.35-.3-.15-.75-.15.047-.047.15-.05m8.45 0H25.65q-.4 0-.7.15-.35.15-.35.35v33q0 .2.35.35.3.15.7.15h.1q.4 0 .65-.15.3-.15.3-.35V.5q0-.2-.3-.35-.25-.1-.55-.15h-.05z"
                    id="ObjFoo036__Symbol_28_0_Layer0_0_FILL"
                />
                <g id="ObjFoo036__Layer16_0_FILL">
                    <path
                        fill="#C1D2E2"
                        fillOpacity={0.702}
                        d="M119.05 308.1q-.032.316-.1.6h-33.7q-.068-.284-.1-.6-.048-.097-.05-.15v3.95q.35 2.65 3.3 3.05h27.4l.6-.1q2.45-.45 2.7-2.4v-4.35h-.05m13-8.85h-.1q-2.419 0-4.3-1.35-.095.271-.2.5-.25.55-.6 1-.3.4-.7.75-2.7 2.75-6.6 2.75h-.45q-2.804 2.476 0 4.25 1-.05 1.85-.05 4.85 0 8.25-3.45 2.006-1.963 2.85-4.4M121 270.7h-1.9q-2.88 2.125 0 4.25.25-.05.5-.05 3.9 0 6.6 2.75.4.35.7.75 1.1 1.35 1.25 3.95.05.4.05.8v1.75q1.68-.95 3.75-.95h.75v-1.6q0-4.8-3.45-8.2-3.4-3.45-8.25-3.45z"
                    />
                    <path
                        fill="#FFF"
                        d="M95.7 246q-.9-.8-1.95-1.2-1.05-.4-2.25-.4-2.6 0-4.45 1.85t-1.85 4.45q0 2 1.1 3.6.1.15.25.35h-.05q-.25-.05-.45-.05-2.3 0-4 1.45-.25.2-.45.4-1.85 1.85-1.85 4.45 0 2.65 1.85 4.5 1.508 1.467 3.5 1.75l2.5 2.55h28.9l2.55-2.6q1.915-.305 3.35-1.7 1.85-1.85 1.85-4.5 0-2.6-1.85-4.45-1.15-1.15-2.6-1.6-.9-.25-1.85-.25-.2 0-.4.05h-.1l.4-.5q.95-1.55.95-3.45 0-2.6-1.85-4.45t-4.45-1.85q-1.15 0-2.15.35-1.1.4-2 1.25h-.05v-.2q-.4-1.8-1.75-3.15-1.7-1.7-4-1.8v-.05h-.45q-2.95.15-4.65 1.85-1.2 1.2-1.6 2.7-.1.3-.15.65z"
                    />
                </g>
                <g id="ObjFoo036__Layer16_1_FILL">
                    <path
                        fill="#E0E0E0"
                        d="M88.1 263.1q.2-.8.05-1.6l-.05.1q-.15 1.05-1 1.55-.8.45-1.85.1-.95-.3-1.5-1.25-.55-.9-.4-1.85-.65.5-.95 1.4-.25 1.2.35 2.2.6 1.05 1.75 1.4 1.2.3 2.3-.3 1-.6 1.3-1.75m-2.3-6q1.05 0 2.2-.25 1.4-.3 1.4-.75-1.35-.4-1.9-.6-.65-.3-1-.85-.25-.05-.45-.05-2.3 0-4 1.45.35.2.8.4 1.55.65 2.95.65m19.8.8q.1-.05.1-.1.75-1 1.9-1.05 1.1-.05 1.95.85.8.85.85 2.15.05 1.2-.55 2.15.9-.2 1.65-1 .95-1.1.9-2.5-.1-1.4-1.15-2.35-1.05-1-2.45-.9-1.4.05-2.35 1.15-.65.7-.85 1.6m15 1.1l.05.15q.6.8.35 1.8-.2.85-1.2 1.35-.85.45-1.95.15-1.05-.2-1.6-1-.1.8.35 1.65.65 1.05 1.8 1.3 1.15.35 2.25-.25 1.05-.65 1.35-1.8.3-1.15-.3-2.15-.45-.75-1.1-1.2m-3.05-4.35q-.45.45-.85.7-.35.2-1.3.7.8.75 2 .45.8-.2 1.85-1.15.3-.25.55-.5-.9-.25-1.85-.25-.2 0-.4.05m-12.05-8.2q-.45-.9-1.25-1.3l.05.05q.75 1.05.45 2.15-.3 1.05-1.45 1.6-1.05.55-2.3.2-1.15-.3-1.9-1.2-.1.95.45 1.95.75 1.2 2.1 1.55 1.35.35 2.6-.3 1.25-.75 1.65-2.15.35-1.35-.4-2.55m2.85-.45q.1.4.15.5v.85l-.2 1.35v.1q2.1-1.3 2.05-4.05-1.1.4-2 1.25z"
                    />
                    <path
                        fill="#E1E1E1"
                        d="M94.8 255.5q-1 1.05-1 2.45 0 1.4 1 2.4 1 1.05 2.45 1.05 1.4 0 2.4-1.05.7-.65.95-1.55-.1.05-.1.1-.8.95-1.95.95-1.1 0-1.9-.95-.8-.9-.8-2.2 0-1.2.7-2.1-.95.15-1.75.9m.9-9.5q-.9-.8-1.95-1.2-.1.35-.15.8 0 1.5.3 2 .3.55 1.6 1.1.2-1.7.2-2.7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo036__Layer16_0_FILL" />
            <use xlinkHref="#ObjFoo036__Layer16_1_FILL" />
            <use
                xlinkHref="#ObjFoo036__Symbol_67_0_Layer0_0_FILL"
                transform="translate(85.1 264.55)"
                id="ObjFoo036__col1n"
            />
            <use
                xlinkHref="#ObjFoo036__Symbol_28_0_Layer0_0_FILL"
                transform="translate(88.8 271.65)"
                id="ObjFoo036__col1l"
            />
        </g></g>
    )
}

export default ObjFoo036
