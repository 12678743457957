import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.15 35.4q.65-.3.8-.4.25-.15.25-.45-.05-.1-.05-.2-.4-.2-.9.25-.4.35-.4.5l.3.3m1.55-5.45q-.5 0-1.1.45-.55.45-.55.8 0 .15.1.25l.2.05q1.4-1.05 1.4-1.3l-.05-.25m4.4-4.75l-.25-.3q-1.3.2-3.65 1.2Q.35 27.25.35 28.2q0 .2.4.45.2-.35 1.15-1.05.95-.75 1.55-1 .65-.35 2.25-.9l1.4-.5m-5.45-2.45l.3.05q.35 0 3.1-1.45 1.3-.75 4.5-1.05l.25-.3-.25-.25q-1.35.1-4.45 1.05-3.5 1.05-3.5 1.7 0 .15.05.25m6.25-9.7q-.95-.1-2.5.3-1.65.4-1.65.95l.3.3q3.9-1.15 3.9-1.4l-.05-.15M5.15 18.2q2.3-.7 2.3-.8l-.05-.15q-1.15-.1-4.2 1-3.2 1.2-3.2 2.1l.3.25q1.95-1.5 4.85-2.4m3.5-10.95q-1.7-.05-2.75 1.3.1.1.4.1.2 0 1.15-.45 1.05-.5 1.25-.9l-.05-.05m1.95-5.5l-.05-.25q-.7-.2-1.1.05-.35.2-.35.45 0 .15.1.25l.35.05q.6 0 1.05-.55m148.5 35q-.35.05-.5.3.15.25.45.25.2-.2.2-.35l-.15-.2m.65-2.85q.3 0 .3-.3 0-.45-.65-1.1-.7-.6-.7.1.95 1.3 1.05 1.3m-1.8-4.05q.3 0 .3-.35 0-.4-1.75-1.55-1.8-1.15-1.8-.35 0 .2 1.6 1.05.1.05.75.65.7.55.9.55m-2.1-6.95q0 .25.35.35.6.15 1.05.5.05.05.55.45t.8.4q.3 0 .3-.35 0-.3-1.5-1.2-1.55-.9-1.55-.15m.55-7.7q0-.25-1.75-1.35-1.85-1.15-2.45-1.15-.55 0-.55.3 0 .25.25.3l1 .25 3.15 2q.35 0 .35-.35m-3.15 3.05q-.55 0-.55.3 0 .15 2.6 1.45.15.1 1.05.8.9.65 1.1.65.3 0 .3-.35-3.45-2.85-4.5-2.85M149.85.9q-.1-.9-1.1-.9-.996 0-1 .5.005-.143.3.3l1.2.05q0 .15.3.2l.3-.15m2.35 8.25q.45 0 .45-.4 0-.15-.8-.75-.85-.6-1.15-.6-.5 0-.5.35l.75.7q.8.7 1.25.7z"
                    id="SkiMor022__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor022__Symbol_20_0_Layer0_0_FILL"
                transform="translate(113.2 244.85)"
                id="SkiMor022__col1n"
            />
        </g></g>
    )
}

export default SkiMor022
