import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2l": "#333333",
    "col2n": "#000000"
}

function AccGla030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.5 1.25q0-.5-.35-.9-.4-.35-.9-.35T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4l.05-.05q.3-.35.3-.8z"
                    id="AccGla030__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M52.9 21.4q0-.5.4-.9.35-.35.85-.35Q32.8-2 1.7.2q-4.4 21.5 2.55 37.35 1.5 3.05 4 5.7 7.3 7.5 18.4 6.7 11.1-.85 19.6-9.6h-.05q6.8-7.1 8.9-18.15l-.05.05q-.4.4-.9.4t-.85-.4q-.4-.35-.4-.85m50.45 21.85q2.5-2.65 4-5.7Q114.3 21.7 109.9.2 77.95-2.05 56.35 21.35q2 11.65 9.05 19h-.05q8.5 8.75 19.6 9.6 11.1.8 18.4-6.7z"
                    id="AccGla030__Symbol_91_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M104.65 46.2q2.55-2.85 4.1-6.1 6.45-15.2 3.4-35.3-.1-.8-.25-1.65-.25-1.45-.55-2.95-32.45-2.35-54.5 22.2-.15.15-.25.35-.2-.2-.3-.35Q34.25-2.15 1.8.2q-.3 1.5-.55 2.95Q1.1 4 1 4.8q-3.05 20.1 3.4 35.3 1.55 3.25 4.1 6.1 7.45 8 18.8 7.15 11.4-.95 20.15-10.3h-.05q7-7.7 9.2-19.8 2.15 12.1 9.15 19.8h-.05q8.75 9.35 20.15 10.3 11.35.85 18.8-7.15m4.15-42.35q4.05 19.95-2.35 34.6-1.35 2.8-3.65 5.25-6.65 6.95-16.8 6.2-10.15-.8-17.95-8.9h.05q-6.4-6.8-8.25-17.55Q79.6 1.8 108.8 3.85M45.05 41h.05q-7.8 8.1-17.95 8.9-10.15.75-16.8-6.2-2.3-2.45-3.65-5.25Q.3 23.8 4.35 3.85q29.2-2.05 48.95 19.6Q51.45 34.2 45.05 41z"
                    id="AccGla030__Symbol_52_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla030__Symbol_118_0_Layer0_0_FILL"
                transform="translate(189.6 162.95)"
                id="AccGla030__col2n"
            />
            <use
                xlinkHref="#AccGla030__Symbol_91_0_Layer0_0_FILL"
                transform="translate(136.7 142.8)"
                id="AccGla030__col2l"
            />
            <use
                xlinkHref="#AccGla030__Symbol_52_0_Layer0_0_FILL"
                transform="translate(135.95 141)"
                id="AccGla030__col1n"
            />
        </g></g>
    )
}

export default AccGla030
