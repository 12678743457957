import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2n": "#666666"
}

function LegSho012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M137.2 4.75l-.15-.15.05-4.6H10.3l-.05 4.9-.05-.1q-.094-.047-.2 0-1.1.1-2.15.2-6.4.95-7.75 4.5L0 11.65l.5.5h146.3l.5-.5-.1-2.45q-1.45-3.25-7.65-4.2-1.05-.1-2.15-.2-.1-.05-.2-.05z"
                    id="LegSho012__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.9 0H0v10.55q.1-.05.2-.05.3-.1.55-.2.05 0 .1-.05l.05.05h.05q.4.15.95.35V0z"
                    id="LegSho012__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M147.3 4.3V1.4l-64.85-.1Q77.4 1.1 74.8.4h-.05Q74.2.2 73.8.05h-.05L73.7 0q-.05.05-.1.05-.25.1-.55.2-.1 0-.2.05-2.6.8-7.9 1L0 1.4v2.9h64.25q5.95 0 8.6-1.3.4-.2.75-.45l.1-.1.1.1q.4.3.95.55 2.65 1.2 8.4 1.2h64.15z"
                    id="LegSho012__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.05 1.45q13.3-.55 20.7-.5h.35q7.35-.05 20.65.5L37.4.5Q30.8 0 24.75 0q-5.9 0-12.3.5l-8.4.95M53.4 4.1l-9.95-1.05q-10.25-.5-18-.7Q11.3 2.2 0 3.45l31.45-.1 21.95.75m38.7-.75l31.45.1q-11.3-1.25-25.45-1.1-7.75.2-18 .7L70.15 4.1l21.95-.75M110.3.5Q103.7 0 97.65 0q-5.9 0-12.3.5l-8.4.95q13.3-.55 20.7-.5H98q7.35-.05 20.65.5L110.3.5z"
                    id="LegSho012__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho012__Symbol_28_0_Layer0_0_FILL"
                transform="translate(118.8 322.4)"
                id="LegSho012__col1n"
            />
            <use
                xlinkHref="#LegSho012__Symbol_27_0_Layer0_0_FILL"
                transform="translate(191.65 322.4)"
                id="LegSho012__col1d"
            />
            <use
                xlinkHref="#LegSho012__Symbol_26_0_Layer0_0_FILL"
                transform="translate(118.8 332.65)"
                id="LegSho012__col1s"
            />
            <use
                xlinkHref="#LegSho012__Symbol_25_0_Layer0_0_FILL"
                transform="translate(131.1 323.6)"
                id="LegSho012__col2n"
            />
        </g></g>
    )
}

export default LegSho012
