import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M105.35 24.4q-1.2-4.7-3.8-8.85-1.7-2.75-4.05-5.3-.15-.2-.3-.35Q88.85.9 77.85.1 66.8-.75 59.55 7q-5.35 5.75-6.3 13.7-1-7.95-6.35-13.7Q39.65-.75 28.65.1 17.6.9 9.25 9.9q-.3.3-.55.65l-.3.3q-5 5.65-7 12.4Q.35 26.8.1 30.65q-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9 3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.2-3.2-1-6.2z"
                    id="FacEye008__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M8.8 4.25q0-1.75-1.3-3.05Q6.2-.05 4.45 0H4.4Q2.5-.05 1.3 1.2 0 2.5 0 4.25q0 1.8 1.3 3.05 1.2 1.3 3.1 1.35h.05Q6.2 8.6 7.5 7.3q1.3-1.25 1.3-3.05m-2 0q0 .95-.65 1.65l-.05.05q-.2.2-.45.35-.3.15-.65.25-.3.1-.55.1H4.4q-1-.05-1.65-.7H2.7q-.5-.5-.6-1.1-.1-.3-.1-.6V4q.05-.65.5-1.2l.2-.2h.05q.65-.7 1.65-.65h.05q.95-.05 1.65.65l.1.1q.35.4.55.95.05.25.05.6M39.5 1.2h.05Q38.3-.05 36.5 0h-.05Q34.6-.05 33.3 1.2 32 2.5 32 4.25q0 1.8 1.3 3.05 1.3 1.3 3.15 1.35h.05q1.8-.05 3.05-1.35h-.05q1.3-1.25 1.3-3.05 0-1.75-1.3-3.05m-3.05.75h.05q.95-.05 1.6.65.25.25.45.55.25.5.25 1.1v.35q-.1.15-.05.3-.15.45-.45.9-.1.05-.15.15h-.05q-.25.25-.55.4-.5.25-1.05.3h-.05q-.25 0-.45-.05h-.1q-.05-.05-.1-.05-.1-.05-.2-.05-.45-.15-.8-.45l-.1-.1q-.25-.25-.4-.6-.05 0-.05-.05Q34 4.85 34 4.25q0-.55.25-.95.15-.4.45-.7.7-.7 1.75-.65z"
                    id="FacEye008__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <g id="FacEye008__col1n" transform="translate(139.3 136.2)">
                <use
                    xlinkHref="#FacEye008__Symbol_1_0_Layer0_0_FILL"
                    id="FacEye008__col1n_FL"
                />
            </g>
            <g id="FacEye008__col2n" transform="translate(172.05 162.5)">
                <use
                    xlinkHref="#FacEye008__Symbol_11_0_Layer0_0_FILL"
                    id="FacEye008__col2n_FL"
                />
            </g>
        </g></g>
    )
}

export default FacEye008
