import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#B4DBFF",
    "col2n": "#FFFFFF"
}

function LegPan015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7 19.6V.05l.05-.05q-12.6 2.45-25.05 3.95-3.5.4-6.9.75-12.4 1.2-24.7 1.5-4.95.1-9.85.05Q44.5 6.1 29.9 4.6q-5.05-.55-10.05-1.25Q13.8 2.55 7.8 1.5 3.9.8 0 0q0 .05.05.1v19.5q1.45-.25 2.95-.45 5.15-.8 10.25-1.2 25.7-2.1 49.6 4.4 7-1.9 14.2-3.05 6.1-1 12.35-1.45 17.65-1.3 36.3 1.75z"
                    id="LegPan015__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.4 1.85l.3-.4Q3.85.75 0 0q0 .05.05.1v4.5q.65.1 1.4.1 2.9 0 4.95-1.8.55-.5 1-1.05M12.7 9q0-.05-.05-.1Q10.6 6.85 7.7 6.85l-.75.05q-.15 0-.25.05-2.05.25-3.65 1.7-.05 0-.05.05-.05 0-.05.05-.1.05-.2.15-.15.15-.3.35l-.5.6-.3.55q-.1.05-.1.15-.75 1.35-.85 3v.3q0 .65.1 1.25 0 .1.05.2.05.35.2.7.15.55.45 1.1 0 .05.05.1.45.85 1.2 1.6l.1.2.15.15q5.15-.8 10.25-1.2v-.05l.45-.4q.5-.8.75-1.6.1-.3.15-.6v-.2q.1-.45.1-.95v-.3q0-2.25-1.25-4-.3-.45-.75-.85m17.2-4.3v-.1q-5.05-.55-10.05-1.25l-.15.15v.1q0 .4.1.8.05.05.05.1v.1q.1.5.3.95.1.3.3.6.3.5.75.95 1.35 1.35 3.25 1.5h.4q2.1 0 3.55-1.4l.1-.1.3-.3q.15-.15.25-.35l.1-.1.1-.2q.2-.3.35-.6l.1-.2q.1-.3.15-.55h.05v-.1m16.05 6q-.2-.3-.5-.6l-.25-.25q-1.2-1-2.75-1t-2.7 1q-.15.1-.3.25-.45.45-.75 1.05-.1.1-.1.2-.25.5-.35 1.05-.05.35-.05.7v.1q0 .2.05.45.05.85.5 1.65l.2.3q.8 1.1 2 1.5.25.1.5.15.1.05.25.05.1.05.25.05h.15q1.2.1 2.3-.45l.1-.05q.2-.1.4-.25.75-.55 1.2-1.3l.1-.2q.5-.9.5-2 0-1.35-.75-2.4M69.1 6.3v-.1q-4.95.1-9.85.05l-.1.1q.05.35.2.65.1.3.25.6.3.65.9 1.25 1.5 1.5 3.6 1.5t3.55-1.5q.45-.4.8-.9.25-.45.45-.95l.2-.7m21 11.55l-.1-.65v-.15q-.05-.35-.1-.65-.15-1-.65-1.9-.5-.9-1.3-1.7-2-2-4.85-2-2.85 0-4.9 2-.95.95-1.4 2.1-.45.95-.55 2.05-.05.35-.05.75 0 .55.1 1.05 0 .15.05.3l.1.4.6-.1q.05-.05.1-.05 5.85-.95 11.8-1.4h.3v.05l.85-.1M100.35 5.9q.05-.15.15-.25.15-.5.2-1.05v-.25-.3-.1q-3.5.4-6.9.75-.05.05-.05.1.05.65.35 1.2.05.05.1.15v.05q.1.1.2.3l.05.05.25.25q1.05 1.05 2.5 1.05t2.45-1.05l.3-.3q.15-.2.3-.45.05-.1.1-.15m14.5 1.05Q113.7 5.8 112.1 5.8q-.9 0-1.65.35-.6.3-1.1.8-1.15 1.15-1.15 2.75v.15q.05 1.5 1.15 2.6.5.5 1.1.8.75.35 1.65.35 1.6 0 2.75-1.15 1.1-1.1 1.15-2.6V9.7q0-1.6-1.15-2.75m9.05-3.7q-.45.3-.9.7-.15.1-.35.3-.15.15-.3.35-1.65 1.85-1.65 4.35 0 .45.05.9.3 2.2 1.9 3.8.3.3.6.55.6.5 1.3.8l.2.1q.25.1.55.2.15-.1.25-.15.1-.05.15-.1V3q-.05-.1-.15-.15-.1-.1-.25-.2-.65.15-1.2.5l-.2.1z"
                    id="LegPan015__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan015__Symbol_24_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan015__col1n"
            />
            <use
                xlinkHref="#LegPan015__Symbol_25_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan015__col2n"
            />
        </g></g>
    )
}

export default LegPan015
