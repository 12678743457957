import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function ObjMus041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M195.95.85Q195.1 0 193.9 0q-1.2 0-2.05.85-.7.7-.8 1.6-.05.2-.05.4v9.95q0 .55.15.95.25.6.7 1.05.5.5 1.15.7v.1q-.1.45-.15.95-1.75 11.3-2.6 16.05-1.2 6.25-3.9 15.05-7.55 24.25-24.5 34-9.25 5.3-19.55 7.95-7.95 2-24.7 4.2-10.75 1.35-17.55 6.45-5.4 3.95-7.3 9.35h-.1q-.1 0-.2.05Q92 108.3 91.3 107q-2.15-4-6.45-7.25-4.25-3.2-15.05-7.85-13.35-5.4-19.5-7.2-6.05-1.85-13.95-6.3-8-4.5-15.2-10.8h.05q-7.15-6.3-10.95-17.75-3.9-11.65-4.8-19.95-.6-5.65-1.65-13.35-.1-.5-.15-1 .75-.2 1.3-.75.7-.7.8-1.6.05-.2.05-.4V2.85q0-.55-.15-.95-.25-.6-.7-1.05Q4.1 0 2.9 0 1.7 0 .85.85q-.7.7-.8 1.6-.05.2-.05.4v9.95q0 .55.15.95.25.6.7 1.05.6.6 1.4.8 0 .1.05.2l-.05-.05.1.8q1.1 7.8 1.7 13.5.9 8.45 4.85 20.25 3.95 11.8 11.35 18.35 7.3 6.35 15.4 10.95 8.1 4.55 14.25 6.45 6.05 1.75 19.35 7.15Q79.8 97.75 84 100.85q4.05 3.05 6.05 6.8.75 1.45 1.2 2.8-.45.7-.45 1.65 0 1.05.55 1.75.2.25.4.45l-.05.2q-.05 2.6.35 6.7.25 4.5 9.35 7.95 6.75 2.6 15.55 3.05 12.95.7 27.6 7.25 13 5.8 18.4 11.8.2.2.5.2.25 0 .5-.15.2-.25.2-.5 0-.3-.15-.5-5.55-6.15-18.9-12.15-14.9-6.65-28.1-7.35-8.55-.45-15.1-2.95-8-3.05-8.45-6.75-.4-4-.35-6.5 0-.05.05-.05.45-.2.8-.7.55-.7.55-1.75 0-1-.5-1.75 1.75-5.2 6.9-8.95 6.55-4.85 16.9-6.2 16.85-2.2 24.85-4.25 10.45-2.7 19.9-8.1Q180 72.9 187.7 48.05q2.75-8.9 3.95-15.2.85-4.8 2.65-16.3l.1-.8v-.15q.9-.15 1.55-.8.7-.7.8-1.6.05-.2.05-.4V2.85q0-.55-.15-.95-.25-.6-.7-1.05z"
                    id="ObjMus041__Symbol_125_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus041__Symbol_125_0_Layer0_0_FILL"
                transform="translate(94.05 157.25)"
                id="ObjMus041__col1n"
            />
        </g></g>
    )
}

export default ObjMus041
