import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2d": "#252525",
    "col2l": "#333333",
    "col2n": "#000000",
    "col2s": "#4F4F4F"
}

function ShiLon036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M12 2.85q-.55-.65-1.2-1.2Q9.7.75 8.3.15 6.85.1 5.4 0h-.2q2 .9 4.2 2.65l.4.3.1.1q.95-.25 2.1-.2M19.05 0q-1.5.1-3 .15-2.25.95-3.7 2.7.9.05 1.8.3.1-.05.2-.15l.35-.3Q16.95.9 19.05 0m4.75 13.65q.25-.6.55-1.2l-.8.1q-.5.05-1 0-3.4-.2-7.15-3.1l-.05-.05q-.15-.1-.25-.15-.05 0-.05-.05-.05 0-.05-.05-.1-.05-.2-.15-.1-.1-.25-.2l-.05-.05q-2 .75-3.95.15-.25-.05-.45-.15v.05q-.25.15-.45.3l-.1.1q-.35.25-.75.5-.65.4-1.25.8-3.05 1.85-5.7 2.05h-1q-.45 0-.85-.05 4.1 8.7 9 17.25l.8 1.4v-1.4q.1.2.25.4.6 1.1 1.7 2.7l.05.05q.1.15.25.35.1.15.25.35v.05l.3-.4q.75-1.15 1.3-2 .35-.6.65-1v1.6q.3-.5.55-1l.1-.1q.1-.25.2-.45 4.45-8.05 8.4-16.65z"
                    id="ShiLon036__Symbol_346_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M36.75.1h-.05q-1.8.3-3.6.55-1 .15-1.95.25L22.55 5h-7.2L6.5.9l-.05.05v-.1q-.8-.1-1.65-.2L3.3.45Q1.95.2.6 0q.05.05.05.1Q0 .1 0 .65 0 1.05.55 3q.5 1.8.65 2.15.15.35.3 1.1.2.65.5 1.1.2.35.4 1.1.15.85.3 1.1.45 1 1.85 4.55 1.1 2.75 1.95 4.55.95 2.1 2.3 4.5.8 1.4 2.7 4.4l3 4.45q2.8 3.8 3.55 4.3v.05q.15.25.5.25h.25q.4 0 .5-.3.4-.2.95-.55 1.25-.8 1.5-1.05 1.75-1.9 2-2.2 2.25-2.85 3.2-4.4 1.65-3.25 2.3-4.5.35-.75 1.4-2.3 1.1-1.6 1.4-2.2 1.2-3.15 1.75-4.45.65-1.5.95-2.25.5-1.35.55-2.3 1.9-7.65 1.9-9.2 0-.35-.35-.65-.1-.05-.15-.05 0-.05.05-.1m-14.7 10.8l.05.05q.45.9 1.1 1.55.55.6 2.55 1.55 2.05.95 2.85.95h.05v.05Q26.8 20.1 24.7 24.6l-2.3 3.75q-.85 1.3-2.3 3.8-.55.85-.85 1.45-.25-.3-.6-.7-.2-.3-.45-.65-1.15-1.55-1.45-2-1.6-2.35-2.2-3.5-.45-.85-1.5-2.5-2.05-4.4-3.8-9.3 2.6-.65 5.15-3.05.6-.4 1.25-.8l.7-.5.1-.1v.15q.6.5.95.65.2.05.65.1h.8q1.2 0 2.35-.6v-.45l.85.6v-.05M1.5 2.3v-.15q.002.053.05.15H1.5z"
                    id="ShiLon036__Symbol_344_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.1 20.95q.3-.35.3-.8 0-.45-.3-.75-.35-.35-.8-.35-.45 0-.75.35-.35.3-.35.75t.35.8q.3.3.75.3t.8-.3m0-9.45q-.35-.35-.8-.35-.45 0-.75.35-.35.3-.35.75t.35.8q.3.3.75.3t.8-.3q.3-.35.3-.8 0-.45-.3-.75M2.2 4.65q0 .25.15.5.05.15.2.3.3.3.75.3t.8-.3l.2-.3q.1-.25.1-.5 0-.45-.3-.75-.35-.35-.8-.35-.45 0-.75.35-.35.3-.35.75M.8.35l-.1.1-.7.5v20l.8 1.4v-22m5.2 0Q5.9.3 5.8.2 5.7.1 5.55 0V23.05q.3-.5.55-1l.1-.1q.1-.25.2-.45V.7L6.35.65Q6.2.55 6.1.45q-.05 0-.05-.05Q6 .4 6 .35z"
                    id="ShiLon036__Symbol_345_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M84.35 23.85q-.9-.25-1.8-.3h-.35q-1.15-.05-2.1.2l-.1-.1-.4-.3q-2.2-1.75-4.2-2.65l-.9-.05q-2.1-.15-4.15-.4-.2 0-.35-.05v.1q-.05.3-.05.55-.35 2.35-.35 4.85-.05 2.3.15 4.75.1 1.2.25 2.35 0 .2.05.35.05 0 .15.05.4.05.85.05h1q2.65-.2 5.7-2.05.6-.4 1.25-.8.4-.25.75-.5l.1-.1q.2-.15.45-.3v-.05q.2.1.45.15 1.95.6 3.95-.15l.05.05q.15.1.25.2.1.05.15.1l.05.05q0 .05.05.05 0 .05.05.05.1.05.25.15l.05.05q3.75 2.9 7.15 3.1.5.05 1 0l.8-.1h.05q0-.1.05-.2.1-1.1.25-2.15.2-2.4.2-4.65-.05-2.95-.4-5.65v-.25q-.75.1-1.45.15l-1.7.2q-.5 0-1.05.05l-1.1.05h-.15q-2.1.9-4.35 2.7l-.35.3q-.1.1-.2.15M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-3.75 2.6-7.65 4.8-.25.1-.5.25-11.95 6.55-25.45 9.3-.6.1-1.15.2l7.15 6.8h.9l-29.3 35 3.3-6.6-.2.3-.05.05q-.15.25-.35.4-.45.3-1-.1L79.3 60.2h-.05l-.1-.1L55.65 26h.95l6.7-6.8q-.55-.15-1.15-.25-13.85-2.85-26-9.65-3.85-2.15-7.5-4.65L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.1l-.8 4.4q12 5.75 45.9 8.05 2.65.2 5.4.45 1.65.1 3.35.2.7.05 1.35.1 8.8.35 10.15-5.1h1.85q1.85 5.35 10.4 5.15l.3-.05q.65-.05 1.35-.1 3.05-.2 6.1-.45l2.5-.2q38.4-2.9 45.9-8l-1.15-4.45-.5.1.3-.1h.2l-5.9-22.8q.15.1.3.15l-.2-.8q.05-.05.1-.05.3-.35.6-.65 4.45-4.3 11.95-4l2.05.2q.15 0 .3.05 4.05.75 7.05 3.35V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.35-1.55-.65-3.05l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5M148 81.05h.1l-1.3.3q.55-.15 1.15-.3h.05z"
                    id="ShiLon036__Symbol_331_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.75 11.2q-.1-.9.05-1.75L.85 9q-1.75 5.45.2 19v.15q.05.1.05.25.7 2.95 1.6 6.2h1.85Q3.3 32 2.6 29q-.1-.3-.15-.6l-.1-.4Q2 26.6 1.8 25.4q-.05-.15-.05-.3-.15-.65-.25-1.2-.25-1.6-.1-9.65.1-8.1-.65-3.05M4.8 0l-.2.3-.05.05Q4.4.6 4.2.75q-.45.3-1-.1Q-.4 1.9.45 5.8l1.05.85V6.6Q5.4 5.1 4.8 0z"
                    id="ShiLon036__Symbol_332_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.6}
                    d="M5.5 1.85q.95.5 1.45 1.05.35.4.2.95-.15 0-.2.05H6.8l.1-.1-.15.1-1.55-.3q-.85 0-2.7.4v.5q.2.2.35.3.25.25.6.25l1.7-.25q1.2 0 1.2 1.1 0 .4-1.25 1.1-1.3.75-1.3 1.35 0 .15.2.35.2.2.3.2.45 0 1.45-.5 1.05-.5 1.7-.5.4 0 1.15.2.6.15.95.4l-.2-.5q-.4-1.2-.4-2.1 0-1.15.45-2.1l.2-.3q.05-.05.05-.1l-.4-.3Q7.05 1.35 5.05.45L4.15.4Q2.05.25 0 0l.05.05Q1.1.6 2.55.9q1.8.35 2.95.95M21.3 4.5V4q-1.85-.4-2.7-.4l-2.15.4q0-.05-.05-.1-.15-.15-.15-.25 0-.9 4.75-2.65 1.05-.45 1.9-.85l-1.7.2q-.5 0-1.05.05l-1.1.05h-.15q-2.1.9-4.35 2.7l-.35.3q.05.05.05.1.1.15.15.25.45.95.45 2.1 0 .9-.4 2.1l-.2.5q.35-.25.95-.4.75-.2 1.15-.2.65 0 1.7.5 1 .5 1.45.5.1 0 .3-.2.2-.2.2-.35 0-.6-1.3-1.35-1.25-.7-1.25-1.1 0-1.1 1.2-1.1l1.7.25q.35 0 .6-.25.15-.1.35-.3z"
                    id="ShiLon036__Symbol_333_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M64.85 63.05q.45-.5.45-1.2 0-.65-.45-1.1-.55-.55-1.2-.55-.7 0-1.15.55-.5.45-.5 1.1 0 .7.5 1.2.45.45 1.15.45.65 0 1.2-.45M5.2 26.6v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L0 47.8v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4m28.05-10.2l6.7-6.75Q26.1 6.8 13.95 0l8.4 13.45 10.9 2.95m8.7-6.35q-.45-.1-.85-.15l-6.7 6.8h-10q13.25 19.2 31.9 33.55l.2.15q.25.2.55.5h.05l2.75-5.15v-.05q-.15-.1-.3-.25-.05-.1-.1-.15-.1-.15-.15-.25-.75-1.3-1.5-2.5l-.8-1.4q-4.9-8.55-9-17.25-.1-.05-.15-.05-.05-.15-.05-.35-3.15-6.7-5.85-13.45m74.45 16.3q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-28.65-10l9.4-2.65L106 .4Q94.05 6.95 80.55 9.7l7.2 6.65m7.8.35h-9L79.4 9.9q-.65.15-1.3.25-.3.65-.55 1.35-2.45 6.2-5.1 12.15-.05.1-.05.2h-.05q-.3.6-.55 1.2-3.95 8.6-8.4 16.65-.1.2-.2.45l-.1.1q-.25.5-.55 1-.2.4-.45.8l-.3.5q-.15.15-.25.35l-.1.2-3.3 6.6v.05q-.45 1.1-.65 2.5-.1 1.05-.1 2.05-.2 4.7.35 9.55 0-7.95 8.05-16.55l.55-.55q0-.05.05-.1l.3-.3q1-1 2.1-1.9 10.4-8.75 18.85-19.1 4.2-5.15 7.9-10.65z"
                    id="ShiLon036__Symbol_334_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M241.3 271.55l-.2-2.8-20.35 2.55.05 2.9 20.5-2.65z"
                    id="ShiLon036__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon036__Symbol_346_0_Layer0_0_FILL"
                transform="translate(180.2 249.3)"
                id="ShiLon036__col1n"
            />
            <use
                xlinkHref="#ShiLon036__Symbol_344_0_Layer0_0_FILL"
                transform="translate(173.55 247.95)"
                id="ShiLon036__col1d"
            />
            <use
                xlinkHref="#ShiLon036__Symbol_345_0_Layer0_0_FILL"
                transform="translate(189.2 258.1)"
                id="ShiLon036__col1s"
            />
            <use
                xlinkHref="#ShiLon036__Symbol_331_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon036__col2n"
            />
            <use
                xlinkHref="#ShiLon036__Symbol_332_0_Layer0_0_FILL"
                transform="translate(188.85 283)"
                id="ShiLon036__col2d"
            />
            <use
                xlinkHref="#ShiLon036__Symbol_333_0_Layer0_0_FILL"
                transform="translate(180.35 248.85)"
                id="ShiLon036__col2l"
            />
            <use
                xlinkHref="#ShiLon036__Symbol_334_0_Layer0_0_FILL"
                transform="translate(132.2 237.9)"
                id="ShiLon036__col2s"
            />
            <use xlinkHref="#ShiLon036__Layer1_0_FILL" />
        </g></g>
    )
}

export default ShiLon036
