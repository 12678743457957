import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M171.9 35.2q-3-3.6-6.45-7-12.9-12.95-28.3-19.95Q119 0 97.4 0T57.65 8.25q-15.45 7-28.4 19.95-8.35 8.35-14.2 17.7-3.1 4.35-5.55 9.2Q1.1 71.75 0 94.65v93.7l.05.05q2.05 9.4 11.45 11.25.45.1.9.15.2.05.45.05V181.9q1.2-1.2 2.5-2.2 2-1.6 4.3-2.8 6.1-3.2 14-3.45h1.7l-.05-.05q-1.75-1.45-3.4-3-1.65-1.5-3.25-3.1-2.55-2.4-4.8-5.15-2.2-2.5-4.2-5.05-1.9-2.5-3.6-5.05-1.7-2.55-3.2-5.1l.05-48.75q.4-13.9 3.85-26.25 1.3-4.75 2.9-9.6l.4-1.2H172.6l.5 1.2q1.6 4.85 2.9 9.6 3.45 12.35 3.85 26.25l.05 48.75q-1.5 2.55-3.2 5.1-1.7 2.55-3.6 5.05-2 2.55-4.2 5.05-2.25 2.75-4.8 5.15-1.6 1.6-3.25 3.1-1.65 1.55-3.4 3h1.75q7.85.25 13.9 3.45v.05q2.3 1.2 4.3 2.8 1.3 1 2.5 2.2v17.95q.25 0 .45-.05.45-.05.9-.15 9.4-1.85 11.45-11.25l.05-.05v-93.7q-.85-17.7-6.05-31.65-5.95-15.55-14.8-27.8z"
                    id="HaiMed031__Symbol_87_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.2 135.8q-1.7-2.55-3.2-5.1v34.85q2.25-2.25 4.9-3.9.95-.6 1.9-1.05 6.05-3.2 13.9-3.45h1.75q-1.75-1.45-3.4-3-1.65-1.5-3.25-3.1-2.55-2.4-4.8-5.15-2.2-2.5-4.2-5.05-1.9-2.5-3.6-5.05m17.55-90.9h5.8Q28.8 27.2 36.6 10.35 24.75 26.1 20.75 44.9m-13.55 0h5.25Q19.6 20.6 34.65 0q-18 18.55-27.45 44.9m34.9-16.85L44 23.3q-3.4 4-4.55 6.2-1.15 2.15-1.35 2.75-.25.6-1.7 4.2-1.5 3.55-1.85 8.45h4.5q.05-3.2.4-5.45l.1-.5q.7-5.6 2.55-10.9M76.75 44.9h3.95q.45-8.85-.25-15.35-.75-6.55-.6-8.2L78.6 31.6q-.85 5.4-1.05 7.1-.2 1.7-.45 3.7-.25 1.95-.35 2.5m-16.9-21.8v-.95q-.15.3-.25.55-4.3 8.7-6.1 22v.2h5.45q-.1-1-.1-2.35 0-.8.05-2.8 0-2.05.5-9.8.45-7.3.45-6.85M94.1 39.45q-.05 2.6-.1 5.45h5.2l-.2-1.05q-.5-2.15-.75-4.4-.15-.95-3.05-14.6-.5-2.3-.85-3.95 0 5.25-.25 18.55m15.05-18.05l.6 3.15v.9l.85 9.5q.2 2.65.35 4.5.15 2.25.15 3.4 0 .8.15 2.05h4.85q-2.3-12.5-6.95-23.5m31.4 23.5h6.25q-2.8-15.1-15.1-34.8 8.4 21.15 8.85 34.8M135.05.25l.5.7 5.05 7.7q10.85 18 14 36.25h5.15q-4.4-16.6-13.95-31.75-4.8-6.6-10.75-12.9M121.5 19.3v.3q5.75 14.25 7.2 25.3h5.15q-.7-2.35-1.4-4.6-.7-2.3-3.2-9-2.55-6.7-7.75-12m45.55 111.4q-1.5 2.55-3.2 5.1-1.7 2.55-3.6 5.05-2 2.55-4.2 5.05-2.25 2.75-4.8 5.15-1.6 1.6-3.25 3.1-1.65 1.55-3.4 3h1.75q7.85.25 13.9 3.45.95.45 1.9 1.05 2.65 1.65 4.9 3.9V130.7z"
                    id="HaiMed031__Symbol_54_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed031__Symbol_87_0_Layer0_0_FILL"
                transform="translate(96.15 54.05)"
                id="HaiMed031__col1n"
            />
            <use
                xlinkHref="#HaiMed031__Symbol_54_0_Layer0_0_FILL"
                transform="translate(109 70.3)"
                id="HaiMed031__col1d"
            />
        </g></g>
    )
}

export default HaiMed031
