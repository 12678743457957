import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF9D43",
    "col1n": "#FF7B00",
    "col2l": "#333333",
    "col2n": "#000000"
}

function ObjFoo038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17 70.65q1.65 4.6 6.95 3.25l18.1-6.55q1.3-.65 2.2-1.4.15-.2.35-.35 2.1-2.2 1.15-5.3l-1.2-3.45Q29.4 59.9 15.8 67.2l1.2 3.45M29.5 15.3q-2.05-4.55-7.35-6.05-2.35-1.15-4.5-3-.15-.15-.25-.2-.05-.15-.1-.2.15-.35.15-.8.25-1.6-1.4-2.6l-.9-2.4q-8-.5-13.1 4.7L3 7.15Q2.35 9 3.45 10.1q.35.3.75.45.05.1 0 .2-.05.2-.1.35-.85 3.7-2.9 7.25Q-.95 22.2.75 25.6l1.1 3Q17.4 26.35 30.6 18.25l-1.1-2.95z"
                    id="ObjFoo038__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M31.85 61.3q-9.7 5.1-19.35 6 .95 2.15 4.85 1.25l15.1-5.45q3.05-.9 4.55-3.8l-1.35-3.8-5.25 1.9 1.45 3.9M5.75 3.6l2.05-.75L6.75 0 4.7.7l1.05 2.9m2.75.3l-8.35 3L0 10.35l11.1-4L8.5 3.9m2.9 4q0 .2 1.25 1.95 1.3 1.75 2.4 4.35.55 1.2.7 1.7l4.8-1.75q-2.1-4.1-9.15-6.25z"
                    id="ObjFoo038__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M27.3 65.45q0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-2.9 1.1-5.4.7l1.75 4.85L50.3 79.35l-13.95-38.6L7.6 51.1 12 63.2q.5-.45 1.15-.75 4.45-1.85 10.2 0 2.25 1 3.95 3M14.95 6.7L12.6.1q-1.85-.2-3.65.1-1.65.2-3.25.85Q2.7 2.1 0 4.6l2.4 6.85 2.2.45q-.05.35-.05.6 0 .05-.05.1 0 1.2.05 2.35.2 3.05 1 6l2.25 6.3 13.1-4.7-2.3-6.3-.6-1.3q-1.7-3.25-3.95-5.8.05 0 .05-.05-.25-.2-.45-.5l1.3-1.9z"
                    id="ObjFoo038__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M28.5 43.05l-4.85 1.8 12.9 35.65 4.85-1.75-12.9-35.7M6.45 9.65l-2.75 1q2 1.95 3.75 4.1 3 3.7 5.25 8.05-.95-4.45-2.8-8.05-1.45-2.8-3.45-5.1m-2.1-6.5L3.25 0Q1.6.2 0 .85L1.25 4.3l3.1-1.15M4.9 4.5L1.75 5.65l1.35 3.7L6.2 8.2 4.9 4.5z"
                    id="ObjFoo038__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo038__Symbol_99_0_Layer0_0_FILL"
                transform="translate(101.15 247.25)"
                id="ObjFoo038__col1n"
            />
            <use
                xlinkHref="#ObjFoo038__Symbol_98_0_Layer0_0_FILL"
                transform="translate(107.85 251.05)"
                id="ObjFoo038__col1l"
            />
            <use
                xlinkHref="#ObjFoo038__Symbol_97_0_Layer0_0_FILL"
                transform="translate(95.4 224.75)"
                id="ObjFoo038__col2n"
            />
            <use
                xlinkHref="#ObjFoo038__Symbol_2_0_Layer0_0_FILL"
                transform="translate(101.1 224.95)"
                id="ObjFoo038__col2l"
            />
        </g></g>
    )
}

export default ObjFoo038
