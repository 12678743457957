import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.8 32.85q-.05-.25-.2-.35-9.15-6.5-10.85-12.95l-.2-.8v.05q-.1-.65-.15-1.25Q37.8 9.25 49.6.9q.15-.1.2-.3.05-.2-.1-.35-.1-.2-.3-.25-.25-.05-.4.1-12.4 8.75-11.6 17.55 0 .1.05.2h-25.1q.05-.1.05-.2Q13.2 8.85.8.1.65-.05.4 0 .2.05.1.25-.05.4 0 .6q.05.2.2.3Q12 9.25 11.4 17.55q-.05.6-.15 1.25v-.05l-.2.8Q9.35 26 .2 32.5q-.15.1-.2.35-.05.2.1.35.1.15.3.2.25.05.4-.1 9.5-6.75 11.2-13.5.15.05.3.05h25.2q.15 0 .3-.05 1.7 6.75 11.2 13.5.15.15.4.1.2-.05.3-.2.15-.15.1-.35z"
                    id="FacMou007__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou007__Symbol_32_0_Layer0_0_FILL"
                transform="translate(167.6 206.65)"
                id="FacMou007__col1n"
            />
        </g></g>
    )
}

export default FacMou007
