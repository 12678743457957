import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function AccMor062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.05.65q-.1.2 0 .5Q15.2 50 45.15 78.4h.15q-3.5 5.15-4.15 12.35-1.1 12 6.4 22.5T66.7 126.6q11.7 2.9 20.75-3.5 3.1-2.2 5.25-5.1v.65q15.95 15.15 33.65 17.7h.35q0-1 .35-1.85-.2-.1-.4-.15-17.35-2.5-33-17.55l-.05-.05q.05-.05.05-.1 3.25-5.05 3.85-12 1.15-11.95-6.35-22.45t-19.2-13.4q-11.7-2.85-20.7 3.6-2.8 2-4.8 4.5Q16.95 48.8 1.95.55 1.85.2 1.55 0l-.2.2Q.65.45.05.65z"
                    id="AccMor062__Symbol_159_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor062__Symbol_159_0_Layer0_0_FILL"
                transform="translate(150.05 67.1)"
                id="AccMor062__col1n"
            />
        </g></g>
    )
}

export default AccMor062
