import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#0033FF"
}

function ObjToy073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.85 11.25q.15-1.15 0-2.25-.3-2.5-2-4.7Q16.35.85 12.1.15 7.75-.5 4.2 2 2.15 3.55 1.05 5.75.3 7.1.05 8.7v.1q-.1.3.1 2.3.2 1.95 2.55 5.3 2.3 3.3 4.65 3.8 2.3.45 3.2.45 1.65 0 3.2-.5-.6 1.15-.85 2.5v.1q-.5 3.4 1.5 6.15.5.6 1 1.1 1.85 1.8 4.45 2.2 2.7.45 4.95-.65.35-.2.7-.45.3-.15.55-.35 2.7-1.9 3.3-5.05 0-.2.05-.3v-.1q.25-1.35-.35-3.85-.1-.3-.15-.6 0-.05-.1-.35-.2-.25-.5-.95-.4-.75-.85-1.1-.45-.45-.65-.6l-.1-.1q-1-.95-2.35-1.45-.85-.4-1.85-.5-.65-.15-1.35-.15-.55 0-1.1.1h-.45q-.1 0-.2.05.9-1.45 1.3-3.2 0-.3.05-.6.05-.4.1-.75m41.1-3.05q-.3-.05-.55-.05-3.8 0-6.8 2.65-3.15 2.8-3.4 7.25-.05.85.05 1.75.05.6.2 1.15-.55-.3-1.1-.5-.85-.4-1.85-.5-.65-.15-1.35-.15-.55 0-1.1.1h-.45q-1.8.35-3.35 1.5-2.75 2-3.3 5.4v.1q-.55 3.4 1.45 6.15.5.6 1 1.1 1.85 1.8 4.5 2.2 2.65.45 4.9-.65.35-.2.7-.45.3-.15.55-.35 2.7-1.9 3.3-5.05 0-.2.05-.3v-.1q.15-.8 0-2 .65.3 1.5.7 1.55.75 3.3.95h.65q.4 0 .75.05 1.15 0 2.2-.25 2.45-.55 4.5-2.45 3.2-2.8 3.5-7.1.2-4.4-2.65-7.7-1.75-1.9-4.05-2.75-1.4-.6-3.05-.7h-.1z"
                    id="ObjToy073__Symbol_137_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M84.9 26.7l-.1-.7q-.85-9.5-4.6-15.7-.2-.1-.4-.15-4.8-2.8-9.9-1.8h-.3l-2.05.7-4.45-.7-.4-.1q-.05.05-.1.15l-1.8.4q-7.9 1.8-12 3.5-1.45.6-2.45 1.2-.35-.6-.9-1.2Q43 9.5 37.2 5.4q-1-.7-2.05-1.45V3.9l-4.75-.75-1.75-1.3q-.15 0-.3-.1-4.5-2.6-10-1.3-.2-.05-.4-.05Q12.5 5.2 8.75 14q-.1.25-.25.6-.45.8-.85 1.65.6 0 1.15.2.55.15 1.1.5 1 .5 1.95 1.55.1.1.15.2.15.15.3.35 1 1.15 1.7 2.55 1.4 2.75 1.4 6.3 0 .85-.15 2-.35 2.2-1.1 4-1.1 2.65-3.05 4.05-2.15 1.6-5.3 1.6-2.45 0-4.35-.8l-.8-.4q-.15-.1-.4-.2-1 5.95 2.4 9.35 3.2 3.2 8.15 2.05 3.05-1.2 5.85-5.4 3.3-3.95 6-9.25 1.25-1.85 2.75-1.55l.05.1q1.95 2.65 5.25 3.15 3.3.55 6-1.4.15-.1.3-.25l14.45 2.3q.05.15.2.35 2 2.65 5.3 3.2 3.3.5 5.95-1.45l.05-.05q1.55.15 2.2 2.3.9 5.9 2.8 10.65 1.4 4.9 3.95 7 4.35 2.65 8.35.55 4.7-2.4 5.25-9.25 1.4-12.15-.6-23.85m-2.95-5.05v.4q0 .6-.05 1.3l-.1.7q-.05.3-.05.55-.75 3.5-3.65 5.65-3.2 2.25-7.05 1.65-.9-.15-1.75-.4-2.65-.9-4.4-3.35-2.15-2.95-1.8-6.5 0-.25.05-.55.1-.6.3-1.15.15-.55.35-1.05.95-2.35 3.15-3.95 3.15-2.3 7.05-1.7 1.75.25 3.15 1.1 1.75.95 3 2.7 1.55 2.15 1.8 4.6m-31.05 9.4v-.1q.5-3 2.95-4.8 1.4-1 3-1.35h.4q.5-.05 1-.05.6 0 1.2.1.9.1 1.65.45 1.2.45 2.1 1.3l.1.1q.15.1.55.5.4.35.75 1 .3.6.45.85.1.25.1.3.05.25.15.55.5 2.2.3 3.4v.1q-.05.1-.05.25-.55 2.8-2.95 4.5-.2.2-.5.3-.3.25-.6.4-2 1-4.4.6-2.35-.35-4-1.95-.45-.45-.9-1-1.75-2.45-1.3-5.45M23 5.15q3.85.6 6.1 3.8 1.5 2 1.8 4.3.1 1 0 2.05l-.1.7q-.05.3-.05.55-.75 3.45-3.7 5.6-2.5 1.8-5.45 1.8-.8 0-1.55-.15-3.9-.6-6.2-3.75-2.1-2.95-1.75-6.45 0-.25.05-.55V13q.25-1.5.9-2.75 1-2 2.85-3.4 3.2-2.3 7.1-1.7m5.85 16.2q.15-.1.25-.15 1.1-.55 2.35-.6.85-.05 1.75.05.7.15 1.4.35.55.2 1.1.45.5.3.95.65.8.65 1.45 1.55 1.85 2.5 1.35 5.5v.1q-.55 3-3 4.75-.55.4-1.1.7-2.05 1-4.4.65-2.4-.4-4-2-.3-.25-.5-.5-.15-.2-.4-.5-1.75-2.45-1.3-5.45v-.1q.25-1.55 1-2.55.15-.3.35-.5.6-.75 1.25-1.35.85-.85 1.35-1 .05-.05.15-.05z"
                    id="ObjToy073__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M2.65 42.5q3.2 3.2 8.15 2.05 3.05-1.2 5.85-5.4-2.85 1.75-9.15-.75-3.9-1.55-6.05-4.65l-.8-.4q-.15-.1-.4-.2-1 5.95 2.4 9.35M79.5 11q-2.9-2.9-7-2.9T65.45 11q-2.65 2.7-2.85 6.35l-.05.05v.65q.05 1.9.8 3.6l-.3-.5.2.5.05.05q.15.1.55.5.4.35.75 1 .3.6.45.85.1.25.1.3.05.25.15.55.5 2.2.3 3.4v.1q-.05.1-.05.25-.55 2.8-2.95 4.5-.2.2-.5.3-.3.25-.6.4-2 1-4.4.6-2.2-.3-3.75-1.7l-.2-.2-.05-.05q-.45-.45-.9-1-.25-.35-.4-.65-1.3-2.2-.9-4.8v-.1q.5-3 2.95-4.8 1.4-1 3-1.35-1.8.3-3.4 1.5-2.45 1.75-3.1 4.65-.1.3-.15.6-.35 2.15.4 4.1l-12.25-2q1.25-1.55 1.6-3.7.05-.3.05-.6.25-3-1.5-5.45-.85-1.1-1.85-1.85-.45-.35-.95-.6.5.3.95.65.8.65 1.45 1.55 1.85 2.5 1.35 5.5v.1q-.55 3-3 4.75-.55.4-1.1.7-2.05 1-4.4.65-2.4-.4-4-2-.3-.25-.5-.5-.15-.2-.4-.5-1.75-2.45-1.3-5.45v-.1q.25-1.55 1-2.55.15-.3.35-.5.6-.75 1.25-1.35.85-.85 1.35-1 .05-.05.15-.05.15-.1.25-.15 2.25-2.65 2.25-6.25 0-4.1-2.95-7.05-2.9-2.9-7-2.9t-7.05 2.9q-2.9 2.95-2.9 7.05 0 2.2.85 4.1 1 1.15 1.7 2.55l.35.35q1.85 1.85 4.15 2.55 1.35.4 2.9.4.25 0 .75.15.5.1.95.5.4.35.6 1.55.2 1.15.2 1.3-.1 2.55 1.3 4.7.1.15.2.25l.05.1q1.95 2.65 5.25 3.15 3.3.55 6-1.4.1-.1.25-.2l14.55 2.3q0 .1.15.3 2 2.65 5.3 3.2 3.3.5 5.95-1.45l.05-.05q.1-.05.3-.2 1.95-1.55 2.65-3.85.75-1.8 1.3-2.5.55-.65 2-.3 1.55.55 3.3.55 4.1 0 7-2.95 2.95-2.9 2.95-7T79.5 11m2.3 8.05q-.05.3-.05.55-.75 3.5-3.65 5.65-3.2 2.25-7.05 1.65-.9-.15-1.75-.4-2.65-.9-4.4-3.35-2.15-2.95-1.8-6.5 0-.25.05-.55.1-.6.3-1.15.15-.55.35-1.05.95-2.35 3.15-3.95 3.15-2.3 7.05-1.7 1.75.25 3.15 1.1 1.75.95 3 2.7 1.55 2.15 1.8 4.6v.4q0 .6-.05 1.3l-.1.7M30.9 8.25q.1 1 0 2.05l-.1.7q-.05.3-.05.55-.75 3.45-3.7 5.6-2.5 1.8-5.45 1.8-.8 0-1.55-.15-3.9-.6-6.2-3.75-2.1-2.95-1.75-6.45 0-.25.05-.55V8q.25-1.5.9-2.75 1-2 2.85-3.4 3.2-2.3 7.1-1.7 3.85.6 6.1 3.8 1.5 2 1.8 4.3m54.6 37.3q-1.55 1.7-3.6 2.65-2.25 1-5 1.2-2.95.2-5-.2-2.65-.45-3.95-1.95 1.4 4.9 3.95 7 4.35 2.65 8.35.55 4.7-2.4 5.25-9.25z"
                    id="ObjToy073__Symbol_62_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M15.9 3q17.7 3.05.1-1.55Q6.15-.45 0 .15L15.9 3z"
                    id="ObjToy073__Symbol_49_0_Layer0_0_FILL"
                />
                <path
                    fill="#4A4A4A"
                    d="M126.6 288q.913.532 2.35.9 1.487.368 3-.05l.4-2.45-2.15-3.1-3.2 2.3-.4 2.4m-2.8-3.85l2.5.4 3.1-2.2-2.3-3.2-2.5-.35q-.996 1.2-1.15 2.65-.104 1.45.35 2.7m9.7-4.9l.4-2.5q-1.05-.788-2.5-.95-1.4-.113-2.9.15l-.35 2.5 2.15 3.15 3.2-2.35m3.3 4.2q.275-1.45-.1-2.75l-2.45-.4-3.1 2.2 2.3 3.15 2.4.4q.725-1.15.95-2.6z"
                    id="ObjToy073__Layer5_1_FILL"
                />
                <path
                    fill="#171717"
                    d="M189.55 289.7q-.4-.55-.95-.8-.35-.2-.85-.3-1.1-.15-2.05.5-.9.7-1.1 1.85-.05.2-.05.4 0 .9.55 1.65.3.5.8.75.45.3.95.35 1.15.2 2.1-.5.75-.5 1-1.4l.1-.4q.2-1.15-.5-2.1m-10.75 4.35q-.5.45-.8 1.05-.15.4-.25.8-.15 1.15.45 2.05.7.95 1.85 1.15.55.05 1-.05.6-.15 1.05-.45.65-.45.9-1.2.15-.3.2-.55v-.15q.15-1.1-.5-2-.65-.95-1.8-1.1-1.15-.2-2.1.45m-.65-3.75q.05-.15.1-.35.2-1.15-.5-2.1-.6-.8-1.5-1.05-.15-.05-.3-.05-.15-.05-.3-.05-.2 0-.45.05-.1-.05-.15 0-.6.1-1.2.5-.9.65-1.1 1.8-.05.2 0 .4-.05.95.5 1.7.4.5 1.05.8l.05.1q.3.15.7.2.4.05.85 0 .1 0 .15-.05.55-.1 1.1-.45.75-.55 1-1.45m2.55-8.05q-.55.45-.85 1v.1q-.15.35-.2.75-.1.4-.05.75.05.7.5 1.3.65.95 1.8 1.15 1.15.15 2.1-.5.8-.6 1.05-1.55 0-.15.05-.3 0-.15.05-.3 0-.3-.1-.6-.05-.65-.45-1.15-.7-.95-1.85-1.1-1.15-.2-2.05.45z"
                    id="ObjToy073__Layer5_2_FILL"
                />
                <path
                    fill="#171717"
                    d="M171.5 296.85q-1.25-1.75-3.4-2.1-2.15-.3-3.95.95-1.75 1.3-2.1 3.45v.15q-.3 2 .95 3.75 1.3 1.75 3.45 2.05 2.15.35 3.9-.9 1.7-1.25 2.1-3.25v-.15q.35-2.15-.95-3.95m-7.3 0q1.25-1.25 3.05-1.25 1.8 0 3.05 1.25 1.25 1.25 1.25 3.05 0 1.8-1.25 3.05-1.25 1.25-3.05 1.25-1.8 0-3.05-1.25-1.25-1.25-1.25-3.05 0-1.8 1.25-3.05m9.25-13.4q.55.1 1.05-.2.5-.4.6-.95v-.1l.1-.35q-.12.366-.55.7-.55.3-1.1.2-.6-.05-.9-.55-.3-.4-.3-.85l-.1.7q0 .45.25.85.35.5.95.55m5.6-8.1l-.15.9q4.9.15 9.9 1.8l.15-.9q-4.85-1.65-9.9-1.85v.05m-41 16.2q-1.8 1.3-2.15 3.45v.15q-.3 2 1 3.75 1.25 1.75 3.4 2.1 2.15.3 3.95-.95 1.65-1.25 2.05-3.25v-.15q.35-2.15-.9-3.95-1.3-1.75-3.45-2.05-2.15-.35-3.9.9m-1.25 4.2q0-1.8 1.25-3.05 1.25-1.25 3.05-1.25 1.8 0 3.05 1.25 1.25 1.25 1.25 3.05 0 1.8-1.25 3.05-1.25 1.25-3.05 1.25-1.8 0-3.05-1.25-1.25-1.25-1.25-3.05m-7.8-7.1q-1.45-.3-2.4-.65l-.1.6q2.3 1.6 5.35.85l.1-.6q-1.45.1-2.95-.2m-5-7.2q.35-1.45.6-2.65l-.55-.1q-.95 1.45-1.05 3.1-.05 1.05.2 2.25l.6.1q-.1-1.25.2-2.7m13.3-.65l-.6-.1q0 1.3-.25 2.75-.2 1.45-.6 2.6l.6.1q.65-.95.9-2.05.35-1.5-.05-3.3m-3.4-4.05l.05-.55q-2.25-1.55-5.35-.85l-.1.6q1.5.05 2.9.2 1.45.2 2.5.6m7.7.55q-.5.35-1.1.25-.55-.1-.9-.55-.25-.4-.3-.9l-.1.7q.05.5.25.9.4.45.95.55.6.1 1.05-.25.5-.4.6-.95v-.1l.1-.35q-.12.366-.55.7m-14.25-8.95q5.3-.05 10 1.3l.15-.9v-.05q-4.85-1.4-10-1.25l-.15.9z"
                    id="ObjToy073__Layer5_3_FILL"
                />
                <path
                    fill="#252525"
                    d="M148.4 275.05l-1.25-.2q.933-.034 1.95 0 3.758-.129 8.6.6 5.6.85 9.45 2.3.661.249 1.2.5l2.85.45.4-2.4q.05-.1.1-.15l-27.55-4.35v.05l-.4 2.5 4.65.7z"
                    id="ObjToy073__Layer5_4_FILL"
                />
                <g id="ObjToy073__Layer5_0_FILL">
                    <path
                        fill="#4A4A4A"
                        d="M188.95 277.15q-4.4-3.5-9.9-1.85l9.9 1.85m-20.85 17.6q-2.15-.3-3.95.95-1.75 1.3-2.1 3.45v.15q-.3 2 .95 3.75 1.3 1.75 3.45 2.05 2.15.35 3.9-.9 1.7-1.25 2.1-3.25v-.15q.35-2.15-.95-3.95-1.25-1.75-3.4-2.1m6.35-17.5q-.6-.05-1.1.3-.45.3-.5.85l-.6 3.65q0 .45.25.85.35.5.95.55.55.1 1.05-.2.5-.4.6-.95v-.1l.1-.35v-.25-.1l.4-2.6q.1-.55-.25-1-.35-.55-.9-.65m-36.4 14.3q-1.8 1.3-2.15 3.45v.15q-.3 2 1 3.75 1.25 1.75 3.4 2.1 2.15.3 3.95-.95 1.65-1.25 2.05-3.25v-.15q.35-2.15-.9-3.95-1.3-1.75-3.45-2.05-2.15-.35-3.9.9m2.25-19.2q-.4.25-.5.8l-.6 3.65q.05.5.25.9.4.45.95.55.6.1 1.05-.25.5-.4.6-.95v-.1l.1-.35v-.25-.1l.4-2.6q.1-.55-.25-1-.3-.5-.9-.6-.55-.1-1.1.3m-12.8-4.9q5.15.963 10 1.25-4.7-3.25-10-1.25z"
                    />
                    <path
                        fill="#171717"
                        d="M154.5 295.5q-1.1-.2-2.05.5-.8.55-1 1.6-.05.05-.05.1-.15 1.1.45 2 .7.9 1.8 1.1 1.05.15 1.95-.5.9-.65 1.05-1.75 0-.05.05-.1.1-1.05-.5-1.85-.65-.95-1.7-1.1z"
                    />
                    <path d="M145.75 274.2q-.98.068-2 .15l-1.75 11q-.15 1.3 1.4 1.85l24.2 3.85q1.7-.05 1.9-1.35l1.7-11q-.391-.179-.8-.35-.934-.029-4.15-.45l-19.1-3.05q-.735-.333-1.4-.65z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy073__Symbol_137_0_Layer0_0_FILL"
                transform="translate(119.85 271.85)"
                id="ObjToy073__col1n"
            />
            <use
                xlinkHref="#ObjToy073__Symbol_84_0_Layer0_0_FILL"
                transform="translate(109 267.9)"
                id="ObjToy073__col1d"
            />
            <use
                xlinkHref="#ObjToy073__Symbol_62_0_Layer0_0_FILL"
                transform="translate(109 272.9)"
                id="ObjToy073__col1s"
            />
            <use
                xlinkHref="#ObjToy073__Symbol_49_0_Layer0_0_FILL"
                transform="translate(145 274.5)"
                id="ObjToy073__col2n"
            />
            <use xlinkHref="#ObjToy073__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy073__Layer5_1_FILL" />
            <use xlinkHref="#ObjToy073__Layer5_2_FILL" />
            <use xlinkHref="#ObjToy073__Layer5_3_FILL" />
            <use xlinkHref="#ObjToy073__Layer5_4_FILL" />
        </g></g>
    )
}

export default ObjToy073
