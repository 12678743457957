import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou087({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M209.6 225v-.05q-.044-2.265-1.65-4.55-1.602-2.3-3.75-2.75h-.05q-1.597-.3-3.6-.5-.375-.05-.8-.1-.013-.003-.05 0-2.467-.2-3.95-.25h-.2q-.598-.049-1.1-.05-.725-.052-1.4 0H192.4q-2.399.099-5.15.4-1.577.211-2.95.5-2.806.588-4.75 1.6-2.862 1.452-3.1 5.65-.052.512-.05 1 .024 4.84 4.35 6 1.101.316 3.4.5 9.825.797 18.35 0 7.167-1.213 7.1-7.2v-.2z"
                    id="FacMou087__Layer9_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M7.1 12.95q2.75.2 7.15.25V7.3H7.1v5.65M6.3 7.3H.05Q0 7.55 0 7.8q0 3.75 3.35 4.7.95.25 2.95.4V7.3M.1 6.5h6.2l-.05-5.45q-2.25.5-3.8 1.4Q.35 3.5.1 6.5m14.15 0V.1q-2.2.1-4.6.35-1.4.15-2.6.45l.05 5.6h7.15m8 6.65l.05-5.85h-7.25v5.9h1.25q.35 0 1.35.05 2.05 0 4.6-.1m.8-.05l.8-.1q6.35-.85 6.55-5.7h-7.3l-.05 5.8M26.15.95Q24.75.7 23.1.5v6h7.3q-.2-1.7-1.35-3.4-1.3-1.8-2.95-2.15h.05M22.3 6.5V.4q-.2 0-.45-.05h-.05Q19.4.15 17.95.1h-.3Q17.1.05 16.6.05h-.05Q15.9 0 15.3.05h-.25V6.5h7.25z"
                    id="FacMou087__Symbol_43_0_Layer0_0_FILL"
                />
                <g id="FacMou087__Layer1_0_FILL">
                    <path
                        fill="#666"
                        d="M189.9 223.7q.3-.4.45-.9h-2.95q.1.5.4.9.45.6 1.05.6.6 0 1.05-.6m7.95-.9h-2.95q.1.5.4.9.45.6 1.05.6.6 0 1.05-.6.3-.4.45-.9m-17.4.9q.45.6 1.05.6.6 0 1.05-.6.3-.4.45-.9h-2.95q.1.5.4.9m14.45-1.9v.1h2.95q-.05-.05-.05-.1-.1-.55-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.3.4-.4.95m-7.5.1h2.95q-.05-.05-.05-.1-.1-.55-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.3.4-.4.95v.1m-4.4 0q-.05-.05-.05-.1-.1-.55-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.3.4-.4.95v.1H183m22.3 1.8q.3-.4.45-.9h-2.95q.1.5.4.9.45.6 1.05.6.6 0 1.05-.6m-2.5-1.8h2.95q-.05-.05-.05-.1-.1-.55-.4-.95-.45-.55-1.05-.55-.6 0-1.05.55-.3.4-.4.95v.1z"
                    />
                    <path
                        fill="#999"
                        d="M205.75 221.9h-27.2l-.25.9h29.5l-.35-.9h-1.7z"
                    />
                </g>
            </defs>
            <use xlinkHref="#FacMou087__Layer9_0_FILL" />
            <use
                xlinkHref="#FacMou087__Symbol_43_0_Layer0_0_FILL"
                transform="translate(177.8 218.1)"
                id="FacMou087__col1n"
            />
            <use xlinkHref="#FacMou087__Layer1_0_FILL" />
        </g></g>
    )
}

export default FacMou087
