import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000",
    "col2l": "#333333",
    "col2n": "#000000"
}

function AccHat086({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M100.55 86q11.3-2.6 20.35-7.85l.1-.05q.1-.05.25-.15 6.9-4.1 9.25-8.9 2.75-7.15 0-15.15l-.2-.6L111.9 0H20L1.6 53.3l-.2.6q-2.75 8 0 15.15 2.35 4.8 9.25 8.9.15.1.25.15.05.05.1.05Q20.05 83.4 31.35 86q14.75 3.6 33.35 3.6h2.5q18.6 0 33.35-3.6z"
                    id="AccHat086__Symbol_303_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M91.65 7.4l-.3-.4q-1.7-1.95-8.05-3.5-.2-.05-.35-.1l.05.05Q75.65 2 68.45 1.2 57.25 0 46.1 0h-.2Q34.75 0 23.55 1.2 16.35 2 9 3.45l.05-.05q-.15.05-.35.1Q2.35 5.05.65 7l-.3.4.05-.05L.05 8.4q0 .15-.05.3.05.45.35.9Q1.4 11 5 12.3q.25.05.5.15v-.1q3.65 1.55 9.65 2.35 5.55.9 12.05 1.45l17.15.6h3.3l17.15-.6q6.5-.55 12.05-1.45 6-.8 9.65-2.35v.1q.25-.1.5-.15 3.6-1.3 4.65-2.7.3-.45.35-.9-.05-.15-.05-.3l-.35-1.05.05.05z"
                    id="AccHat086__Symbol_201_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M44.25 28.1q.05-1.2-2.3-14l-.65-3.35q-.4-1.95-1.6-3.95-.6-1-1.3-1.75-.95-1-2.05-1.65-.55-.3-4.9-2.5-4.8-.9-8.1.1-1.05.3-1.65.65V0H5.6v5.05q-.4-.15-.95-.15Q1.9 4.9.7 7.75 0 9.4 0 11.15v.55q.05.15.1.25v.2l.15.05q.15.3.85.3 1.05 0 1.7-.45.5-.4.5-.9l-.1-1.1q0-.75.05-.85.05-.25.4-.85.45-.35 1.15-.9h.65q.1 0 .15.05v4.6h16.1V9.7q.2.05.5.05 6.85 0 10.1 6.6 1.25 2.6 2 6.3.75 3.7.95 13.5-.05.25 0 .5l.9 69.05h-.05l-.05 7.3H39l1.5-10.2q1.6 3 2.85 9.5l.1-.05q10.6-3.35 15.85-9.05l-2.9-15q-8.35-34.85-12.15-60.1z"
                    id="AccHat086__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M15.4 3.45V0H0v.85h.1V.9l.05.15Q0 1.6 0 2.4q0 .05.05.85v.05H0v1.25h15.4q-.05-.45-.05-.5l.05-.6z"
                    id="AccHat086__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat086__Symbol_303_0_Layer0_0_FILL"
                transform="translate(126.35 26.2)"
                id="AccHat086__col1n"
            />
            <use
                xlinkHref="#AccHat086__Symbol_201_0_Layer0_0_FILL"
                transform="translate(146.3 17.8)"
                id="AccHat086__col1d"
            />
            <use
                xlinkHref="#AccHat086__Symbol_105_0_Layer0_0_FILL"
                transform="translate(190.85 16.15)"
                id="AccHat086__col2n"
            />
            <use
                xlinkHref="#AccHat086__Symbol_50_0_Layer0_0_FILL"
                transform="translate(196.45 20.35)"
                id="AccHat086__col2l"
            />
        </g></g>
    )
}

export default AccHat086
