import { AssetSvgProps } from "../../../shared/assets"

function ShiMot063({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="red"
                    d="M159.2 258v35.8h66.5V258h-66.5z"
                    id="ShiMot063__Layer5_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M196.05 277.45l3.15 1.25-1.85-2.9 2.1-2.65-3.25.9-1.9-2.85-.25 3.45-3.15.85 3.15 1.2-.15 3.4 2.15-2.65m-5.6-7.05q.75.75 1.25 1.55-.75-1.6-2.05-2.9-2.8-2.8-6.7-2.8-3.95 0-6.75 2.8-2.75 2.75-2.75 6.7t2.75 6.7q2.8 2.8 6.75 2.8 3.9 0 6.7-2.8 1.5-1.55 2.25-3.4-.55 1-1.45 1.9-2.15 2.15-5.25 2.2-3.1-.05-5.25-2.2-2.2-2.2-2.2-5.25 0-3.1 2.2-5.3 2.15-2.15 5.25-2.15t5.25 2.15z"
                    id="ShiMot063__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot063__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot063__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot063
