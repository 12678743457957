import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2l": "#FF62B6",
    "col2n": "#FF008C",
    "col2s": "#A10058"
}
function HaiHig032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1l}
                    d="M28.25 81.95q.3 9.7-.35 13.65-.65 3.95-.9 5.7-.2 1.75-.95 3.05-.75 1.25-1.8 3.4-.9 1.9-2.3 3.45-.75.8-1.6 1.5-5 4.15-13.2 4.15-3.6 0-4.85-.35-.75-.2-2.3-.7.6.9 1.25 1.3 2.75 1.85 4.2 2.55 2.4 1.15 6.6 1.55h2.55q2.55-.15 4.6-.9.05 0 0 .15-.05.1 2.4-1.3 2.5-1.4 5.2-4.45 5.45-6.55 5.45-16.15 0-.65-.05-1.3-.1-2.05-.65-4.55-1.15-5.1-3.3-10.75m62.8-71.7q-2.5-2.3-3.45-2.85-3.65-2.2-8.75-4.4-1.05-.45-2.15-.85-1.7-.45-3.75-1Q71.6.8 69.5.4q-.6-.1-1.6-.25Q65.05 0 61.1 0 43.85 0 31.85 13.5q-11 12.4-11 27.5 0 6 2.15 12.35 1 3.05 3.15 8.75-.9-4.15-1-9.9-.1-5.8 2.65-15.6t9.15-15.85q6.45-6.1 13.65-9.65 7.25-3.55 13.6-3.8 4.2-.2 9.15.05l.25.05q.4 0 .85.05h1.8q1.15.1 2.35.3h.2q2.85.45 6.3 1.4 5.1 1.4 7.25 2.45-.25-.3-.55-.65-.05-.1-.15-.15-.25-.25-.6-.55z"
                    id="HaiHig032__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M107.3 9.7q.3-.35.55-.65-11.2-7.7-22.4-5.3l-4.25 1.3q-6.5 2.6-11.85 8.25-9 11.1-6.2 30.45.6 3.85 1.6 8 1.4 4.1 2.2 10.65.85 6.55.65 14.1-.25 7.55-4.05 16.05-1.65 5.95-7.45 12.95-.8 1-1.7 1.9-9.25 9.8-22.1 9.8-10.6 0-19.95-5.75Q.15 103.95.15 90.3q0-.15-.05-.25v.05q-.2 3.15.05 6.05 1 6.6 3.75 12.15 2.2 4.4 5.5 8.2 3.1 3.55 6.55 5.9 2.05 1.3 4.15 2.25.45.15.85.3 2.3.9 4.75 1.35 1.85.45 3.7.8l1.2.15q5.8.85 11.05.15l.5-.1q8.9-1.35 16.1-7.2 3-2.45 5.7-5.6-9.75 16.8-24.35 18.85-28.1 3.2-37.7-16.25v1.85q.75 9.4 11.15 16.6 1.9 1.3 3.9 2.4 2.9 1.75 5.9 3 9.65 3.9 20.25 2.45 1.3-.2 2.55-.4 7.05-1.35 14.5-5.05 5.5-2.65 9-5.25 4.4-3.2 7.5-7.5 1.8-2.45 3.15-4.95 10.35-23.95 0-47.9-.75-2.45-1.55-4.85-4.1-11.95-4.55-23.35-.1-3.6.3-6.7.1-1.75.3-3.35 3.2-25.7 33-24.4m20.15 5.1q.4-.4.75-.85 1.1-1.35 1.5-3 .3-1.1.3-2.3 0-3.55-2.55-6.1Q125.5.6 123 .15h-.15q-.1-.05-.3-.05-.55-.1-1.2-.1-1.6 0-2.95.5-.9.3-1.7.85-.8.5-1.5 1.2-.45.5-.85 1-1.05 1.4-1.45 3.1l-.2 1.3v.7q0 3.6 2.5 6.15 1.95 1.9 4.45 2.35.85.15 1.7.15 3.55 0 6.1-2.5z"
                    id="HaiHig032__Symbol_34_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M51.45 0q-.3 1.5-.9 8.55V37.3q.2 11.95-3.05 20.45t-4.85 9.75q-1.25 2.8-4.5 9.05 6.15-3.1 10.4-6.9 3.05-2.75 4.6-6.05-1.05 2.8-1.1 3.05-2.3 13.85-7.95 22.1-1.05 1.5-2.1 2.95-16.9 20-42 15.15 9.65 3.9 20.25 2.45 1.3-.2 2.55-.4 7.05-1.35 14.5-5.05 5.5-2.65 9-5.25 4.4-3.2 7.5-7.5 1.8-2.45 3.15-4.95 10.35-23.95 0-47.9-.75-2.45-1.55-4.85-4.1-11.95-4.55-23.35-.1-3.6.3-6.7.1-1.75.3-3.35z"
                    id="HaiHig032__Symbol_35_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M14.75 2.55Q12.8.6 10.3.15h-.15q-.1-.05-.3-.05Q9.3 0 8.65 0 7.05 0 5.7.5q-.9.3-1.7.85-.8.5-1.5 1.2-.45.5-.85 1Q.6 4.95.2 6.65L0 7.95v.7q0 3.6 2.5 6.15 1.95 1.9 4.45 2.35.85.15 1.7.15 3.55 0 6.1-2.5.4-.4.75-.85 1.1-1.35 1.5-3 .3-1.1.3-2.3 0-3.55-2.55-6.1z"
                    id="HaiHig032__Symbol_36_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M5.45 10.3q-.95.2-1.8.7-.8.45-1.55 1.2l-.3.3q-.25.3-.55.65Q0 14.95 0 17.3q0 3 2.1 5.1 2.1 2.1 5.1 2.1 3 0 5.1-2.1.85-.85 1.3-1.8-2.5-.45-4.45-2.35-2.5-2.55-2.5-6.15v-.7l.2-1.3q-.7.05-1.4.2m18.5 1.8q0 1.2-.3 2.3 2.75-.15 4.7-2.1 2.1-2.1 2.1-5.1 0-3-2.1-5.1-2.1-2.1-5.1-2.1-3 0-5.1 2.1-.7.7-1.2 1.5 2.5.45 4.45 2.4 2.55 2.55 2.55 6.1z"
                    id="HaiHig032__Symbol_37_0_Layer0_0_FILL"
                />
                <g
                    id="HaiHig032__Symbol_33_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M122.85 24.95q-.3-.7-.65-1.35-1.55-2.3-3.7-4.45-1.8-2.25-3.95-4.35Q99.6 0 78.45 0 57.35 0 42.4 14.8 27.45 29.6 27.45 50.5v1.85q-.05 1.55-.05 3.15 0 1.35.15 2.9.1.4.15.85 1.2 5.9 3.3 12.2 4.9 14.45 4.9 25.2 0 2.9-.1 5.25v.75q0 8.1-5.1 13.8-1.15 1.25-2.45 2.3-1.45 1-3.3 2.05-.9.4-1.9.7-2.25.7-4.8.7-7.3 0-12.4-5.75-1.65-1.8-2.75-3.8-.75-1.35-1.3-2.85-.35-1.1-.6-2.2-.8 1.05-1.05 3.3-.25 1.85-.05 3.8v.15q.05.1.05.25 0 13.65 12.2 21.15 7.4 4.55 15.55 5.5-4.35 1.85-11.95 5.45-7.35-.35-14.05-5.3 10.75 22.45 37.65 18.75 16.3-2.15 23.4-18.8 3.2.55 4.15-26 .25-7 .5-14.55.2-7.55-.65-14.1-.75-6.15.05-18.9-.25 3.1.2-7.35.5-12.45 25.25-30.5l15.4 3.4.3-.3q.75-.75 1.55-1.2.85-.5 1.8-.7.7-.15 1.4-.2.4-1.7 1.45-3.1.4-.5.85-1 .7-.7 1.5-1.2.8-.55 1.7-.85 1.35-.5 2.95-.5.65 0 1.2.1.2 0 .3.05M41.7 73.2l-.1-.2q.048-.388.05-.55.001.168.05.75m-24 58.2q-.65-.3-1.15-.65.2.1.45.25l.7.4z" />
                    <path d="M16.55 130.75q.5.35 1.15.65l-.7-.4q-.25-.15-.45-.25M41.6 73l.1.2q-.049-.582-.05-.75-.002.162-.05.55z" />
                </g>
            </defs>
            <use
                xlinkHref="#HaiHig032__Symbol_33_0_Layer0_0_FILL"
                transform="translate(27.5 34.75)"
                id="HaiHig032__col1n"
            />
            <use
                xlinkHref="#HaiHig032__Symbol_32_0_Layer0_0_FILL"
                transform="translate(42.8 45.25)"
                id="HaiHig032__col1l"
            />
            <use
                xlinkHref="#HaiHig032__Symbol_34_0_Layer0_0_FILL"
                transform="translate(27.5 59.55)"
                id="HaiHig032__col2n"
            />
            <use
                xlinkHref="#HaiHig032__Symbol_35_0_Layer0_0_FILL"
                transform="translate(50.35 93.65)"
                id="HaiHig032__col2d"
            />
            <use
                xlinkHref="#HaiHig032__Symbol_36_0_Layer0_0_FILL"
                transform="translate(140.2 59.55)"
                id="HaiHig032__col2l"
            />
            <use
                xlinkHref="#HaiHig032__Symbol_37_0_Layer0_0_FILL"
                transform="translate(133.55 56.1)"
                id="HaiHig032__col2s"
            />
        </g></g>
    )
}
export default HaiHig032
