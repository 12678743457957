import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22 2.4q-.05-.1-.1-.15-1.45-1.6-3.5-1.6-1.3 0-2.35.55-1.05.45-2.1.45-1.4 0-2.5-.55-.2-.1-.35-.2Q8.55-.9 6.25.9q-.8.5-2.25 1.6T1.3 5.4Q0 7.15 0 7.7v.35q.15 2.15 1.85 0 .4-.6 1.25-1.65 1.2-1.5 2-2.15.75-.65 1.85-1.45Q8 1.95 8.85 2q1.5.1 2.8.95l.5.3q2 1.25 3.5 0 .1-.1.95-.45.8-.35 1.5-.3.65.05 2.1 1.65 1.4 1.6 2.2.15.45-.9-.4-1.9z"
                    id="FacMou006__Symbol_31_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou006__Symbol_31_0_Layer0_0_FILL"
                transform="translate(180.3 219.2)"
                id="FacMou006__col1n"
            />
        </g></g>
    )
}

export default FacMou006
