import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#F1FFDB"
}

function ObjMor052({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#43A01B"
                    d="M281.25 267.35q-3.35-1.4-5.2-1.5h-2.65q-.65.1-.65.6v.05q-.136-.074-.25-.15-.85 2.35-2 5.8-.174.419-.35.85-.04.169-.1.35h.15l-11 24.6-.95 1.45-4.55 1.2-14.7 2.55.25.85.35.65 12.75-2.35-.25 4.05-2.5 5.45-2.4 4.05q2.9-2.75 7.1-7.8l-5.55 11.1.3-.5 10.05-10.35-.6 6.45q.083-.234.15-.5l9.3-13.1.05.05 1.25-2.55q12.016-3.714 20.55-5.8v-.05q-2.44.488-4.3.8 2.736-1.066 11.3-5.5.508.113 1.6.4 1.85.45 2.65.45.184.25.5.25.65 0 .65-.7 0-2.65-2.8-6.25-1.35-1.7-5.55-5.95-1.9-2-2.95-3-1.85-1.75-3.8-2.95-2.85-1.7-5.85-3z"
                    id="ObjMor052__Layer14_0_FILL"
                />
                <path
                    fill="#71E041"
                    d="M268 301.2l-2.9-4.15-6.4 7.25-9.95 14.85-.2.4q.75.75 1.25.75.05 0 4.6-6.05 2.75-3.6 4.7-5.95 1.6-1.95 2.7-3-1.05 3.1-3.3 9.45-.65 1.9-1.45 4.05v3.25q.75 0 1.25.5h1.25q-.25-5.9 5-14.25.55-.9 1.25-2.15-.089-.034-.2-.1l2.4-4.85m-27.45 6.6v.25q.65 1.3 3 .7 4.9-1.05 8.25-3.7v.6q0 1.85-8 10.65 1.4-.35 2 .75.65-.55 1.4-1.2l8.8-14.6q.859-.91 1.55-1.7l-3.85 1.05.7-.1q-.85.8-1.7 1.5-.2.15-.35.3-5.35 4.3-11.8 5.5m49.25-14.9h.05q.352-.106.7-.2 8.07-2.441 12.05-3.4-.9-.25-1.35-1.05-.4-1.15-.4-1.2V287q-1.95.8-2.7.8-.35 0-.7-.05l-9.3 3.2q-2.898 1.087-7.15 2.75-4.252 1.713 8.8-.9v.1m-11.85-9.2l-.7.7q.023.031.05.05-.4.436-.4 1.6 0 .75.15 1.1.15.4.85 1.25.45.5 1.85 1.05 1.4.5 2.25.5.097 0 .15-.05.015.067 0 .1.2-.1.35-.15l.75-.25 10.7-5.15q-.35-.6-.6-1.3-.05-.05-.05-.1-.15.35-.9 1.45-.3.25-.5.45-1 .9-3.55 1l-.1-.05q-.45.25-.95.4-.4.35-1.15.35-2.1 0-2.75-1.5-.3-.65-.3-1.7v-.25q-.2.05-.35.1-.7 1-2.95 1-1.25 0-1.85-.6m-2.85-16.8q-.75 0-1.35-.1l-2.8 6.65q-.037.175-.05.4-.05.55-.25 1.05-.2.45-.2.85 0 .65.15 1 .037.091.05.15-.006.118-.05.2.079-.03.15-.05.435.55 1.45.55.9 0 1.35-.4.029.07.05.15l3-4.2q-.15-.05-.25-.05-2.15-.35-2.2-.35-1.05-.45-1.05-2.3 0-2.05 2.75-3.5-.1-.05-.2-.05h-.55z"
                    id="ObjMor052__Layer14_1_FILL"
                />
                <path
                    fill="#22D3FF"
                    d="M278.15 253.5q-.15.35-.5 1.75-.65 2.3-1.45 7.15v3.85q-.067.135-.5.9h-.05q-2.75 1.45-2.75 3.5 0 1.85 1.05 2.3.05 0 2.2.35.1 0 .25.05.05 0 .1.05.25 0 .6.1 0 .5-.25 1.6.311-.089.75-.15.428.26 1.35.55l2.15.6q1 .55 1.75 1.4.45.5 1.1 1.45.6.8 1.8 2.8.3.6.95 1.45 1.05 1.25 1.8 1.25 1.2 0 2.1-1.25.45-.6.75-1.4h.7q1.9.5 3.1 1.95.9 1.1.95 1.1.9.9 1.7.9 1.5 0 3-1.5.8-.75 1.35-1.6.4-.5 1-1.8.7-1 2.45-1.15h1.4q.95.15 2.65.75h3.1q2.2-1 2.9-1.6.75-.6.75-1.55 0-.95-1.15-2.75-1.2-1.8-1.2-2.7 0-.9.25-1.85.25-.9.25-1.55 0-1.5-.7-2.6-.45-.7-1.35-1.4-1.05-.85-1.85-.9-3.05-.3-5.5-1.4-1.208-.543-1.85-1.1.126-.488.35-1.1.295-.885.4-1.45.04.23.05.45.95-1 .95-2.85 0-3.35-3.35-3.35-.35 0-.6.05-.1-.1-.2-.15.15-.55.15-1.15-.2-1.65-2.25-1.65-1.1 0-2 .65.3.45.3 1.35 0 1.05-.3 1.65h.1q.25 0 .95-.35l-.1.1q-.4.2-1 .35-.15.25-.35.45-.324.185-.75.2l-.85-.75q-2.1-1.75-2.85-2.3-1.9-1.2-3.9-1.45h-2.45q-1.25.25-2.95.8-2.05.65-2.85 1.25-1.2.8-1.65 1.7m25.55 3.75h-.15q-.4 0-.6.4-.15.5-.2.55 0-.69-.2-1-.017-.488-.6-.95.288.013.55.1.55.1.95.55.127.127.25.35z"
                    id="ObjMor052__Layer14_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M20.4 5.95q.55-1.15.55-1.55 0-.25-.05-.4-.2-.75-1.05-.75h-.4q-.5.1-1.55.5.4-.75.65-2.65.05-.85-.3-1Q18.1.05 18 .05h-.05V0h-.6l-.2.5q-.3.75-.6 1.45-1.8 4.05-3.7 6.75-2.3 2.75-3.2 4.05-1.6 2.25-1.55 4.2.2 6.25-4.45 8.35-.15.05-.25.15h-.05q-.8.9-1.65 1.7-.85.8-1.7 1.5.25.1.55.2.15.05.35.1l.5-.2q.8-.4 1.9-.85.9-.45 2.05-.95h.15q.1-.05.25-.05.5 0 .5.9 0 .4-.2.85-.15.25-.55.95-.2.35-.75.95.05.25.35.4.45.1.9 0 .9-.2 1.85-1.2 1.6-1.7 2.25-1.8v3l.25.25q2.4-1.2 2.5-2.25.05 1.2-.35 2.9-.05.7.6.95.95-1.7 2.1-4.05 2.05-3.2 5.65-4.3 9.5-2.9 16.25-4.9v-.1q-.7-.95-1-1.35-.3-.35-.65-.5-.4-.15-.85-.15-.3 0-.6.1.2-.4.2-.6.05-1-1.35-1l-1.3.05-.8.15-.2.1-.75.25q-.15.05-.35.15.55-1.2.55-1.7 0-.6-.05-.75-.05-.15-.45-.7h-.35l-.05-.05q-.3 0-.65.05h-.5q-.75-.05-2.45.45-.35.1-.6.2.3-.8.3-1.2 0-.75-.35-1.45-.15-.25-.3-.45-.6-.75-1.7-.75h-.7q-.15.05-.3.05.1-.1.2-.25.8-1.2.8-1.95 0-1.4-1.15-1.4-.05 0-.95.05l-.3.05q-.3.05-.6.2-.1.05-.2.05.2-.2.35-.45.2-.3.4-.7m.9 8.75q1.1 0 1.25 1.25 0 1.25-1.25 1.25-.7 0-1.05-.6-.2-.6-.2-.65 0-1.25 1.25-1.25m5.75 4.1q0 1.25-1.25 1.25t-1.25-1.25q0-1.25 1.25-1.25 1.1 0 1.25 1.25M16.55 7.05q1.1 0 1.25 1.25 0 1.25-1.25 1.25T15.3 8.3q0-1.25 1.25-1.25m1.6 6.65q-.7 0-1.05-.6-.2-.6-.2-.65 0-1.25 1.25-1.25 1.1 0 1.25 1.25 0 1.25-1.25 1.25z"
                    id="ObjMor052__Symbol_147_0_Layer0_0_FILL"
                />
                <g id="ObjMor052__Layer14_2_MEMBER_0_FILL">
                    <path
                        fill="#FDE724"
                        d="M300.4 275.25q-.85.55-.85 2.35 0 .85.75 2.2l.05-.05v.05q.3.55.8 1.2-.45-.3-1.25-.4-.65-.1-1.45-.1-1.65 0-1.55 1.55-.3.45-.3 1.25v.05q0 2.45 2.5 3.1.7.2 1.25.4.15.05.3.15v.25q0 .05.4 1.2.45.8 1.35 1.05.4.1.9.1.6 0 1.15-.15 1.65-.35 3.9-2.45v.25q-.2.7-.2 1.8 0 3.2 3.4 3.2 1.8 0 3.05-1.55.15-.2.35-.4 1.45 1.3 2.4 1.3 2.75 0 2.7-3.6.1 0 1.95.35l.1-.1v.15l.6.1q2.7 0 2.7-2.7 0-1.2-1.5-2.55-.4-.4-2.8-1V282q.75-1.15.75-2.5 0-1.5-.25-2-1.1-.55-2.3-.7-.45-.05-2.2.2-1.35.2-2.9.95.1-.5.1-1.15 0-3.3-3.2-3.3-1.2 0-1.8.5-2.15.5-2.15 3.05l.05.25q-.1.05-.1.1-1.05-2.65-3.8-2.65-1.4 0-2.2.2-.45.1-.7.3m-3.3-28.5q0-1-1.5-1.2h-1.95q.25-.55.25-1 0-2.7-2.5-2.7-1.6 0-2.65 1-.3.3-.5.6h-.1q-.9-1.85-3.05-1.85-3.2 0-3.2 2.65 0 .8.15 1.35-.75-.35-1.75-.35-3 0-3 2.65 0 2.3 1.95 2.9.05.1.1.25.05.1.1.25-.4.55-.5 1.05-.05.15-.05.3 0 .65.65 1.45 1 1.25 3.1 1.25 1.85 0 3.05-1.15.55-.55.75-1l.4-.6q.1.3.3.8.8 1.35 2.45 1.35.8 0 1.3-.25 1-.5 1-1.85l.2-.4q1.35 2 3.5 2 .45 0 .8-.2.2-.2.35-.45.05-.05.05-.1.3-.6.3-1.65 0-.9-.3-1.35l-.1-.1q-.75-.7-1.1-1.4 1.5-1 1.5-2.25z"
                    />
                    <path
                        fill="#F22"
                        d="M292.85 272q-.3-2-2.65-2-.9 0-2.85 1.75-1.4 1.3-1.8 2.15.55-.15 1.35-.15 3.15 0 3.15 2.6 0 .85-.55 1.85-.3.55-.65.9-.35.6-1 .65 0 .25-.25.25 2 .95 2 3 0 2.2-1.55 3.1l.1.05q2.55-.1 3.55-1 .2-.2.5-.45.75-1.1.9-1.45 0 .05.05.1.25.7.6 1.3 1.45 2.85 3.5 3.3.35.05.7.05.75 0 2.7-.8v-.2q-.15-.1-.3-.15-.55-.2-1.25-.4-2.5-.65-2.5-3.1v-.05q0-.8.3-1.25-.1-1.55 1.55-1.55.8 0 1.45.1.8.1 1.25.4-.5-.65-.8-1.2v-.05l-.05.05q-.75-1.35-.75-2.2 0-1.8.85-2.35-1.2-1.05-2.35-1.35.1-.5.1-1 0-2.45-1.95-2.45-.65 0-1.9.35-.05 0-.4.3-.6.3-1.05.9m20.85-5.5q-.5 0-.95.1-.35.05-.65.15.6.65.75 1.85 0 1.55-1.25 2.65-1.15 1-2.4 1-2.1 0-2.85-.65-.9-.8-1-3.1-2.5 2.65-4.8 2.75-.2.8-.2 1.75 0 1.35.75 1.95.8-.2 2.2-.2 2.75 0 3.8 2.65 0-.05.1-.1l-.05-.25q0-2.55 2.15-3.05.6-.5 1.8-.5 3.2 0 3.2 3.3 0 .65-.1 1.15 1.55-.75 2.9-.95 1.75-.25 2.2-.2-1.8-4.5-3.95-4.55h.25q.25-.55 1.75-1.8 1.25-1.05 1.25-2.1 0-1.8-2.5-3.35h-.9q-.15.5-.45.85-.15.5-.5.6-.3 0-.55.05m-30.25-10q-1.25 0-2 .25-.8.55-.8 1.9 0 1.45.95 2.45-1.8.15-2.5.65-.9.55-.9 1.85t.9 2.05q.85.65 2.25.7h.45q-.2.55-.2 1-.05.1-.05.25v.25q0 .95.35 1.65.2.4.45.7.85 1 2.2 1 1.8 0 3.1-1.4.3-.35.85-1.3 0-.05.1-.45h.05v.05q.45.55 1.05.75 1.05.4 2.5.4.85 0 2.1-.85.55-.4.55-2 0-1.5-1.3-2.6-.7-.6-1.55-.9.6-1 .6-2.55 0-.9-.75-1.85-.2-.25-.4-.4-.65-.6-1.35-.6-1.5 0-1.95.65-.35.5-.7 2.4.1-1.2-1-2.5-1.35-1.55-3-1.55z"
                    />
                    <path
                        fill="#336BEE"
                        d="M307.95 257q-.65 0-2.15 1-1.4 1.1-1.7 1.25v-.3q-.05-1-.35-1.6-.15-.3-.3-.45-.4-.45-.95-.55-.3-.1-.65-.1-3 0-3.2 3.25-.8-.5-1.9-.5-2.6 0-2.6 2.3 0 3.8 4.3 3.8.45 0 .8-.15.2.05.35.05-1.25.65-1.4 1.05-.1.25-.1 1.8 0 3.4 2.25 3.4h.2q2.3-.1 4.8-2.75.1 2.3 1 3.1.75.65 2.85.65 1.25 0 2.4-1 1.25-1.1 1.25-2.65-.15-1.2-.75-1.85.3-.1.65-.15.45-.1.95-.1.25-.05.55-.05.35-.1.5-.6.3-.35.45-.85.1-.4.1-.95 0-3.2-4.6-3.2-.35 0-.6.05.05-3.9-2.15-3.9m-26.35 13.8q-.5-.2-1-.2-2.95 0-2.95 3.25 0 .1.05 1.1-.5.05-.85.15-.25 0-.45.05-1 .15-1.5.75-.55.5-.55 1.45 0 1.15 1.1 1.95 1 .75 2.25.55-.55.7-.55 2.2 0 .1.2 1.3.15.3.4.55.6.6 1.85.6 2.25 0 2.95-1 .15-.05.35-.1v.25q0 1.05.3 1.7.65 1.5 2.75 1.5.75 0 1.15-.35.5-.15.95-.4 1.55-.9 1.55-3.1 0-2.05-2-3 .25 0 .25-.25.65-.05 1-.65.35-.35.65-.9.55-1 .55-1.85 0-2.6-3.15-2.6-.8 0-1.35.15-.4.05-.65.2-.1-.55-.3-.95-.35-2.05-3-2.35m-2.7-18.15q0-.15.05-.3-.2.05-.35.15-2-2-2.4-2-2.15 0-1.85 5.25-1.5-.3-2.95.75-1.55 1.1-1.55 2.75 0 1.85 3.05 2.95-.4.35-.7.9-.65 1.15-.65 1.9 0 1 .75 1.55.45.3 1.25.45.6.1 1.35.1h.55q.1 0 .2.05.15 0 .3.05-.05.1-.05.2-.05 0-.05.05t.05.05v.1q0 2.25 3.75 2.25 1.4 0 2.25-.35-.35-.7-.35-1.65v-.25q0-.15.05-.25 0-.45.2-1h-.45q-1.4-.05-2.25-.7-.9-.75-.9-2.05 0-1.3.9-1.85.7-.5 2.5-.65-.95-1-.95-2.45 0-1.35.8-1.9.75-.25 2-.25 1.65 0 3 1.55 1.1 1.3 1 2.5.35-1.9.7-2.4.45-.65 1.95-.65.7 0 1.35.6.15-1.75.15-2.25 0-1-.6-1.3-.05 0-.1-.05-.5.25-1.3.25-1.65 0-2.45-1.35-.2-.5-.3-.8l-.4.6q-.2.45-.75 1-1.2 1.15-3.05 1.15-2.1 0-3.1-1.25-.65-.8-.65-1.45z"
                    />
                </g>
                <g id="ObjMor052__Layer14_2_MEMBER_0_MEMBER_0_FILL">
                    <path
                        fill="#E45112"
                        d="M317.4 282q-.95 0-1.65.65-.65.7-.65 1.65t.65 1.6q.7.7 1.65.7t1.6-.7q.7-.65.7-1.6 0-.95-.7-1.65-.65-.65-1.6-.65m-14.1-1.45q-.45.5-.45 1.2v.05q0 .2.05.4.1.4.4.7.5.5 1.2.5t1.15-.5q.5-.45.5-1.15t-.5-1.2q-.45-.45-1.15-.45t-1.2.45m-16.05-35.35q-.7 0-1.2.4l-.15.15q-.55.55-.55 1.35t.55 1.35q.55.55 1.3.55h.05q.8 0 1.35-.55t.55-1.35q0-.4-.15-.75l-.2-.4-.15-.15-.05-.05q-.25-.25-.5-.35-.4-.2-.85-.2z"
                    />
                    <path
                        fill="#FC0"
                        d="M293.6 275.5q-2.5 0-2.5 2.5 0 .05.35 1.25.7 1.25 2.15 1.25 2.5 0 2.5-2.55-.15-2.45-2.5-2.45m-10.65-11.75q0 .05.35 1.25.4.7 1 1 .35.15.75.25h.4q.85 0 1.45-.3 1.05-.6 1.05-2.25-.15-1.65-1.2-2.15-.55-.3-1.3-.3-2.5 0-2.5 2.5z"
                    />
                    <path
                        fill="#FFE922"
                        d="M303.85 263.6q0 1 .6 1.7.65.7 1.55.7t1.5-.7q.65-.7.65-1.7t-.65-1.7q-.6-.7-1.5-.7t-1.55.7q-.6.7-.6 1.7m-23 15q0 .8.45 1.35.1.15.25.25.4.3.95.3.7 0 1.15-.55.5-.55.5-1.35 0-.45-.15-.8-.15-.3-.35-.55-.45-.55-1.15-.55t-1.2.55q-.45.55-.45 1.35z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor052__Layer14_0_FILL" />
            <use xlinkHref="#ObjMor052__Layer14_1_FILL" />
            <use xlinkHref="#ObjMor052__Layer14_2_FILL" />
            <use xlinkHref="#ObjMor052__Layer14_2_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor052__Layer14_2_MEMBER_0_MEMBER_0_FILL" />
            <use
                xlinkHref="#ObjMor052__Symbol_147_0_Layer0_0_FILL"
                transform="translate(252.7 273.35)"
                id="ObjMor052__col1n"
            />
        </g></g>
    )
}

export default ObjMor052
