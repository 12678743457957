import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#005100",
    "col1n": "#008300",
    "col2n": "#FF0000"
}

function ShiSpe028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M152.8 19.45v-.05L139.05.8V.75L138.5 0l-12.65 11-29.8 11.75L49.2 18.1 19 2.2 8.45 18.95 8.4 19q-.3.65-.6 1.35-.15.35-.3.75-1.15 2.65-2.15 5.45l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7q-.05.2-.1.35v.15h.05q-.15.5-.25 1l2.05 3.05 16.75 3.9 1.3 4.2q-.186-.046-.4-.25l.15.15-2.8 11.75-.1.5q-.5 1.85-.95 3.7l-.9 3.6-4.95 8.75 2.9-.2q3.2-.2 6.55-.2 3.65 0 7.55.15 6.8.3 14.25 1.1h.15q.2 0 .45.05 3.4.45 6.85.85.25 0 .5.05l.6.1q5.8.85 11.95 1.95 26.9 4.9 56-.75 15.65-4 25.7-4 3.55 0 6.4.5l-.55-1.15q-.4-1.15-3.55-7l-4.3-17.3h-.05l-.5-2.2H141l-1.4-6.1 17.6-1.9 3.8-3.25h.05v-.05q-.1-.45-.2-.85-.05-.35-.15-.65-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-.1-.25-.2-.45-.25-.55-.45-1.05-.2-.45-.4-.85m-13.85 27.2l-.4-1.8.05.05.35 1.75m-114-10.6l-.05.05v-.05h.05m-4 15.85l-.05.5h-.05l-.2.9v-.2l.4-1.95q-.05.35-.1.75M15.1 75.75l.15-.35h.05q-.1.2-.2.35z"
                    id="ShiSpe028__Symbol_208_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M15.8 36.55q-.1.05-.2.15-1 .7-2.75 2.35-2.1 1.95-2.9 3.45Q7.55 38.1 7 31.95L6 33.3v-.05q-.2.3-.45.65-2.95 4.45-4.4 9.95-.05 0-.1-.05-.15-.05-.25-.1-.3-.15-.6-.25v.15h.05q-.15.5-.25 1l2.05 3.05 16.75 3.9.85 2.75q.25-.1.4-.75L22.6 43q-.7.45-.8.6-.1.1-2.8 2.2-1.55-5.1-1-10.8l-2.2 1.55m145.25 8.1v-.05q-.1-.45-.2-.85l-.75.55q-1.65-6-5.7-11.65-1.15 5.55-3.45 10.5-3.5-5.3-7.6-8.3 0 5.65-.9 11.3-1.95-1.15-3.85-3.1l2.25 6.65 16.35-1.8 3.8-3.25h.05m-8.6-24.05q.2.1.4.25.2.1.4.25.2.1.4.25-.25-.55-.45-1.05-.2-.45-.4-.85v-.05L139.05.8V.75L138.5 0l-12.65 11-29.8 11.75L49.2 18.1 19 2.2 8.45 18.95 8.4 19q-.3.65-.6 1.35-.15.35-.3.75.15-.15.3-.25 10.55-6.5 20.75-6.1-1.8 8.95 2.2 18.3l1.05 2.65q10.8-7.95 19.8-8.9.1-.05.2-.05-.1 10.55 10.25 19.4 0-.05.05-.05.15.15.35.3.15-.3.35-.65 3.65-6.95 9.05-11.05 3.55-2.75 7.9-4.2 2.2.75 4.25 1.85l1.3.75 3.35 2.45q4.8 4 8.15 10.45l.2.4q.25-.25.55-.45.1-.1.2-.15 10.05-8.7 10.05-19 .15.05 1.05.15.6.05.55.1 10.1 1.75 18.3 8.6l.95-2.4q.1-.3.25-.6 3.7-9.05 2.05-17.8 8.75-.15 16.85 3.45l4.65 2.3z"
                    id="ShiSpe028__Symbol_207_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 43v1.25q0 3.05.75 7 .65 3.4 2.35 5.85 1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q1.45.8 2.7 1.9.45.4.9.9.6.65.85-.5L27.9 45.2q-.1.1-.15.25-1.1 1.45-2.2 2.75-1.25 1.35-2.25 1.85-1.2-3.25-1.1-10.75-2.35 1.6-4.5 4.55-2.15 2.95-2.6 3.75-2.4-4.75-3.5-10.35-3.3 4.7-4.85 10.6-.05 0-.1-.05-.15-.05-.25-.1-.7-.3-1.3-.55-.45-.2-.8-.4-.65-.4-1.2-.8Q1.1 44 0 43m26.1 10.85q-.012.292-.05.6v-.1l.05-.5m117.7-51.7q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2H85q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95-10.65-2.75-20.3-7.95-4.35-2.4-8.5-5.25L30.2 4.25q-1.45-1.05-2.8-2.1L25.55.75Q25.1.35 24.7 0q-.45.35-1.5 1.15-1.7 1.35-4.95 5.55-3.25 4.2-6.8 16.4 1-.85 2.05-1.55 6.8-4.95 14.65-6.25l5.65-.45q-2.4 10.25 2.75 21 8.05-7.7 20.7-10.25Q57 36.35 67.5 45.35q6.1-12.1 17.3-15.9 11.1 3.8 17.25 15.9 10.5-9 10.25-19.75 12.65 2.55 20.7 10.25 5.15-10.75 2.75-21l2.95.15q9.35.85 19.2 7l1.3.8Q155.15 8.2 145.9.55q-.1.1-.25.2l-1.85 1.4M171.25 43q-1.1 1-3.1 2.95-.55.4-1.2.8l-.1.1q-.35.15-.7.35-.75.3-1.65.65-1.55-5.9-4.85-10.6-1.1 5.6-3.5 10.35-.45-.8-2.6-3.75t-4.5-4.55q.1 7.5-1.1 10.75-1-.5-2.25-1.85-.95-1.1-1.9-2.35-.15-.2-.25-.4-.1-.15-.2-.25v.05l-.1-.1 1.45 7.2.4 5.25 1.05.85-.05-.25q.55-.6 1.15-1.1 1.2-1.1 2.65-1.85.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05 1.65-2.65 2.15-4.9.1-.45.2-.95.75-3.95.75-7V43z"
                    id="ShiSpe028__Symbol_206_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiSpe028__Layer2_0_FILL"
                />
                <path
                    d="M134.05 306.8q2.555.348 5.1.65 22.107 2.81 44.5 3.25 1.555-.262 2.85-.55v.3h10.45q1.364.125 2.8.25 28.8-.5 58-4.9h.15l-1.9-7.5q-28.276 4.16-56.25 4.65-1.289.123-2.5.25v-.2h-.55q-4.5.05-9 0h-1.2v.7q-1.295-.365-2.85-.75-22.377-.44-44.5-3.25-5.119-.648-10.25-1.45-.439 1.891-.9 3.75-.467 1.8-.95 3.6v.05l-.05.15h.35l.05-.1v.1h.1l.5.1q.388.06.75.1 2.667.424 5.3.8z"
                    id="ShiSpe028__Layer2_1_FILL"
                />
                <path
                    fill="#FF0"
                    d="M199.75 300.5h-16.1v12.65h16.1V300.5m-3.05 2.5h.55v2.4h-7.45v2.7h7.45v2.35H186.5V303h1.2q4.5.05 9 0z"
                    id="ShiSpe028__Layer2_2_FILL"
                />
                <path
                    fill="#FFB711"
                    d="M197.25 308.1v-2.7h-1.15v2.7h1.15z"
                    id="ShiSpe028__Layer2_3_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe028__Symbol_208_0_Layer0_0_FILL"
                transform="translate(112 230.4)"
                id="ShiSpe028__col1n"
            />
            <use
                xlinkHref="#ShiSpe028__Symbol_207_0_Layer0_0_FILL"
                transform="translate(112 230.4)"
                id="ShiSpe028__col1d"
            />
            <use
                xlinkHref="#ShiSpe028__Symbol_206_0_Layer0_0_FILL"
                transform="translate(106.9 227.85)"
                id="ShiSpe028__col2n"
            />
            <use xlinkHref="#ShiSpe028__Layer2_0_FILL" />
            <use xlinkHref="#ShiSpe028__Layer2_1_FILL" />
            <use xlinkHref="#ShiSpe028__Layer2_2_FILL" />
            <use xlinkHref="#ShiSpe028__Layer2_3_FILL" />
        </g></g>
    )
}

export default ShiSpe028
