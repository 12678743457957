import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00AFAF",
    "col1n": "#00DEDE",
    "col2d": "#FFE000",
    "col2n": "#FFFF00"
}

function AccHat008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M179.35 76.8q5.05.5 10.15 1.05-.2-1.05-.25-1.35-2.65-10.95-6.85-20-12.35-26.75-33.45-40.2-2.8-1.8-5.75-3.35-.2-.1-.35-.2-11.05-6.05-22.05-9.3-23.5-6.6-49.75-.65Q14.8 19 .9 73.85q-.5 2-.9 4.1 5.55-.6 11.1-1.1.95-.1 1.95-.2 83.9-7.85 164.3-.05l2 .2z"
                    id="AccHat008__Symbol_227_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M179.35 76.8q5.05.5 10.15 1.05-.2-1.05-.25-1.35-2.65-10.95-6.85-20-12.35-26.75-33.45-40.2-2.8-1.8-5.75-3.35-.2-.1-.35-.2-11.05-6.05-22.05-9.3-23.5-6.6-49.75-.65Q14.8 19 .9 73.85q-.5 2-.9 4.1 5.55-.6 11.1-1.1.95-.1 1.95-.2.15-.75.3-1.45 5.1-25.7 22.45-45.6Q51.25 11.85 70.85 5.35q.5-.15.95-.3.05-.05.1-.05l.3-.1q1.75-.6 3.6-1.05 3.95-1 8.1-1.6.45-.1.95-.15h.2Q90 1.45 95.2 1.45q8.65 0 16.7 1.75.3.05.6.15.7.15 1.45.35l.4.1q.3.05.6.15h.05q.4.1.85.25 21.8 6 38.75 25.4 17.3 19.85 22.45 45.45.15.75.3 1.55l2 .2z"
                    id="AccHat008__Symbol_505_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M117.6 11.7q.1-.9-.3-2.1-.35-.9-1-1.75-2.75-3.5-6.8-3.7-.35-.05-.7-.4-1.55-1.8-3.55-2.9l-.8-.4Q102.6-.4 100.6.4q-.25.25-.5.45-3.1 2.85-7.1.5Q92.45 1 91.85.9q-.1-.05-.25-.05-1-.15-1.9 0-2.45.4-4.25 2.9-2.2 1.35-4.8 1.65-3.95.35-5.45 2.65-.45.9-.65 2.1-.05 0-.05.05-.25 1.45.05 2.55.3 1.1 1.25 1.85 3.45 2.7 7.05.55 2.7-1.7 4-4.7.7 1.85 1.2 3.8.8 3.05 3.55 2 2.6-1.1 5.35-1.6.4-.05.85-.1 1.05 1 2.25 1.9.45.3 1.05.35 4.2.75 7.8-1.6.15-.1.35-.1.8.85 2.6 1.35 1.8.45 2.85-.2 1-.7 1.35-1 .35-.35.7-1.1.3-.75.7-1.8.1-.3.15-.65M181 90.95q5.1.45 10.2 1.05l1.1.1q2.9.1 3.6-3.15v-.5q0-3.9-4-4.2-6.1-.8-12.2-1.45-79.05-8.5-163.7.15-6.25.6-12.5 1.35-3.55.55-3.5 4.25-.05 4.8 4.6 3.95 5-.55 10.05-1.05 83.8-8.55 166.35-.5z"
                    id="AccHat008__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M4.6 83.65q4.9-.55 9.85-1.05.1 0-.55-2.15-.7-2.2 2.1-6.35l-1.9.2q-.673.054-1.3.15l-9.3 1Q-.05 76 0 79.7q-.05 4.8 4.6 3.95m112.7-82.4l-.1-.2q-.3.25-.65.5-.25.15-.5.35l-.5.2q-.2.1-.4.15-1.45.5-3.45.5l-1.8-.2q-1.6-.25-1.7-.35l-.05-.05q-1 .05-2.5.95-1.6.95-2.5.95-.65 0-2.7-1.2-1.7-1.05-2.8-1.15 0-.1-.05-.15-.3-.4-3.45.4-3.6 1-3.85 1-.75 0-1.55-.5-.7-.5-1.2-1.3-.05-.05-.05-.15-.25-.4-.4-.8l-.05-.05q-.05.05-.1.05-1.4.5-2.7.95-2.65.9-3.85.9-1.45 0-2.45-.1-.95-.1-1.5-.3Q75.2 1.2 75.05 0q-.35.8-.5 1.8-.05 0-.05.05-.1.6-.1 1.15 0 .5.05.95 0 .15.05.3.05.05.05.15.3 1.1 1.25 1.85 3.45 2.7 7.05.55 2.7-1.7 4-4.7.7 1.85 1.2 3.8.8 3.05 3.55 2 2.6-1.1 5.35-1.6.4-.05.85-.1 1.05 1 2.25 1.9.45.3 1.05.35 4.2.75 7.8-1.6.15-.1.35-.1.8.85 2.6 1.35 1.8.45 2.85-.2 1-.7 1.35-1 .35-.35.7-1.1.3-.75.7-1.8.2-.5.2-1.15-.05-.15-.05-.25 0-.15-.05-.3-.05-.5-.25-1.05m64.5 72.95q-1.05-.15-2.1-.25 2.95 4.35 1.3 8.15 1.25.1 2.5.25l7.7.8 1.1.1q2.9.1 3.6-3.15v-.5q0-3.9-4-4.2-5.05-.65-10.1-1.2z"
                    id="AccHat008__Symbol_506_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat008__Symbol_227_0_Layer0_0_FILL"
                transform="translate(97.3 54.55)"
                id="AccHat008__col1n"
            />
            <use
                xlinkHref="#AccHat008__Symbol_505_0_Layer0_0_FILL"
                transform="translate(97.3 54.55)"
                id="AccHat008__col1d"
            />
            <use
                xlinkHref="#AccHat008__Symbol_54_0_Layer0_0_FILL"
                transform="translate(94.65 46.8)"
                id="AccHat008__col2n"
            />
            <use
                xlinkHref="#AccHat008__Symbol_506_0_Layer0_0_FILL"
                transform="translate(94.65 55.65)"
                id="AccHat008__col2d"
            />
        </g></g>
    )
}

export default AccHat008
