import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M99.4 34.2q3.75-4 5.35-9.15.4-1.35.7-2.75L53.75.05q-.7 2.2-1 4.65-.3-2.45-1-4.7L0 22.3q.3 1.8.9 3.5 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75 7.8-8.4 9.05-19.25 1.2 10.85 9 19.25 8.35 8.95 19.4 9.75 11 .85 18.25-6.9z"
                    id="FacEye016__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.4 6.65Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35 0 1.4 1 2.35.95 1 2.4 1.05m32.05 0q1.4-.05 2.35-1.05 1-.95 1-2.35 0-1.35-1-2.35-.95-.95-2.35-.9Q34-.05 33 .9q-1 1-1 2.35 0 1.4 1 2.35 1 1 2.45 1.05z"
                    id="FacEye016__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.251}
                    d="M186.2 143.3q-2.55-2.75-5.5-4.35-5.65-3.25-12.9-2.7-11.1.8-19.35 9.85-5.02 5.226-7.35 11.5l-.75 2.4q-.236.854-.45 1.7-.23 1.199-.4 2.4-.116.89-.2 1.8.004.05 0 .1-.026.394-.05.8l-.05 3.3q.05 2.306.45 4.45l1.5-.65q-.326-1.833-.35-3.35v-.45q-.041-.637-.05-1.3.025-.931.1-1.9v-.45q.052-.226.05-.45.03-.05 0-.1.104-.91.2-1.8h.05q.157-1.051.35-2.1l.55-2q.57-1.95 1.4-3.8 2.185-4.838 6.15-9 7.8-8.55 18.3-9.35 6.75-.55 12 2.5h.05q2.7 1.5 5.1 4.05 3.37 3.753 4.9 8.45l1.45-.6q-1.628-4.998-5.2-8.95m18.85-2.95h.05q5.25-3.05 12-2.5 10.5.8 18.3 9.35 6.43 6.75 8.15 15.25.179.834.6 4.15l-.05-.3q.032.305.05.6.036.464.05.9.162 3.22-.35 6.1l1.5.65q.588-3.065.45-6.5-.003-.602-.05-1.25-.01-.166-.05-.35l.05.05q-.386-3.364-.6-4.3-1.797-9.017-8.6-16.1-8.25-9.05-19.35-9.85-7.25-.55-12.9 2.7-2.95 1.6-5.5 4.35-3.572 3.952-5.2 8.95l1.45.6q1.53-4.697 4.9-8.45 2.4-2.55 5.1-4.05z"
                    id="FacEye016__Layer6_0_FILL"
                />
            </defs>
            <g id="FacEye016__col1n" transform="translate(139.8 152.2)">
                <use
                    xlinkHref="#FacEye016__Symbol_19_0_Layer0_0_FILL"
                    id="FacEye016__col1n_FL"
                />
            </g>
            <g id="FacEye016__col2n" transform="translate(173 163.45)">
                <use
                    xlinkHref="#FacEye016__Symbol_4_0_Layer0_0_FILL"
                    id="FacEye016__col2n_FL"
                />
            </g>
            <use xlinkHref="#FacEye016__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye016
