import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#0000CC",
    "col2l": "#EEEEEE",
    "col2n": "#D9D9D9"
}

function ObjToo040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#D19532"
                    d="M141.9 314.85v-.05q-2.7-.2-4.8-1.4-3.35-1.6-5.1-5.05-.15-.4-.3-.7l-.5-1.1-.45.45.35-.5h.05l-.05-.1q-.66-.377-1.75-2.05-1.45-2.5-1.95-3.3-1.203-1.96-2-3.35.132 1.394 0 2.85-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.026.024-.05.05.6 2.405.6 4.85v.35l.1.25q1.15 5.65-2.7 10.95-.05 0 0 .05-2.25 4.4 3.65 1.5 2.318-1.452 3.95-3.4.903-1.013 1.6-2.15.59-.983 1.05-2.05 4.15 1.55 8.75.65.6-.1 1.15-.25 6.1-2.2 1.35-3.65m-31.45-58q-3.81 1.345-5.95 1.95-.95.25-3 1.3-1.167.609-1.75 1.05.087.12.1.3 1.496-1.21 5.05-2.45 4.317-1.544 5.65-2.05l-.1-.1m1.05-.55q.688.653 2.4 2.5l.7.7q1.885 1.238 2.45 1.85.8.85 1.3 2.05.5 1.15.5 2.05 0 1.3-1.05 3.3-.2.4-1.7 2.8-.65.95-1.95 3.35-1.35 1.95-3 1.95-.9 0-3-.35-1.8-.35-2.35-.5-2.75-.9-4.55-1.75-3.45-1.65-3.45-5.1 0-1.4.6-4.15.1-1.35.25-2.3.065-.454.15-.8l-.1.15q-.3.3-.55.8-.25.35-.35.65l-4.75 9.4q-.1.5.15 1 0 .05.55 1 .6 1.1.8 1.6.45.95 1.15 2.95.45 1 .65 1.05l.65-.15q.4 0 1.05-.1.75-.25 2.75-.2 2.95-.05 4.45.7 2.2.95 3.4 2.55.7.85 1.8 1.85.455.62.85 1.25 3.418-.497 7.45.8 2.25 1 3.95 3-.148-.575-.3-1.2-1.15-4.55-1.15-5.15v-.25l-.1-.25q0-.1.05-.2-.05-.05-.05-.1-.05-.15-.1-.4-.35-.9-.45-1.85-.1-1.45-.2-2.6-.15-1.4.1-2.95.2-.7.4-1.3.55-1.7 2.65-3.85 1.25-1.4 1.85-1.75.7-.4 1-.75l-3.7-8.35-8.85-2.55h-.3q-.2-.1-.5-.1-.1-.05-.25-.1-.25-.05-.4-.15-.4 0-.7-.05-.112.019-.2 0z"
                    id="ObjToo040__Layer14_0_FILL"
                />
                <path
                    fill="#ECB752"
                    d="M125.05 302.6q-.9 3.4-2.6 5.1-.634.592-1.35.95l.35.85q.003.077 0 .1h.05q.577.938 1.3 2.9l.3.7 7.65-6.2.35-.5h.05l-3.65-8.55q-.007-.068-.05-.15-.461-.361-.9-.8-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55m-3.95-19.35q-.65.65-1.25 1.15-.5.5-1.05.8-.715.516-1.4.85-.175.118-.35.2-.393.169-.8.3 1.218.237 2.5.65 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-.457.05-.9l-.15-.3v-.05l-3.1-7.1m-.25-23.6l-9.15-3.35q-.3.05-.5 0h-.2q-.064.22-.15.4-4.097 1.463-6.35 2.1-.95.25-3 1.3-1.87.976-2.25 1.5-.058-.054-.15-.1l-.1.1-.3.45-3.85 8.85 2.5 5.7q5.3-.95 9.45 1.3 2.35 1.2 4.35 3.3l6-2.6q-.15-2.95.6-5.45.4-1.2.85-2.25 1.6-3.15 4.7-5.55l-2.45-5.7m-13.4-.3l-.4.2q.2.35.5.9.4.75 1.2 1.15.9.45 2.8.75 2 .4 2.9.8.6.3 1.1.7.5.45.8 1.15.35.85.35 1.5 0 .5-.2.95-.3.35-1.5 1.85-.2.3-1.45 1.65-.3.4-.5.75-.15.1-.4.3l-1.3 1.3-1.85.1q-.3 0-.5.05-.15-.05-.2-.05-.4 0-.75-.15-1.85.05-2.2 0-1.9-.15-2.3-.25-1.1-.3-1.8-1.65-.5-1.25.15-2.75.2-.8 1.3-2.55 1.1-1.55 1.25-2.6.5-1.3-.3-2.6l-.35.05q-.05.15-.35.2-.35.3-1 .6-1.05.45-1.7.4-.426-.039-1.2-.4 1.449-1.34 5.35-2.7 4.818-1.724 5.9-2.15-.216.742-.4 1-.5.6-1.5 1-1.25.55-1.45.5z"
                    id="ObjToo040__Layer14_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M3.4 22.7q-.65.6-1.3 1l.35.8 8.1-6.65.05-.05-.7-1.65-.05.05-4.7 3.85Q4.4 21.7 3.4 22.7m3.45-11.25l-.55.45q.15 1 .15 2.1l1.7-1.4q-.35-.3-.65-.6-.3-.3-.55-.65l-.1.1M0 2.35q.9.4 1.7 1.05l1.95-1.55L2.85 0 0 2.35M5.3 5.7l-.15-.35-.9.75q.5.85.95 1.75l.05-.1V7.7l.1-.05q-.1-.55-.1-1.05 0-.45.05-.9z"
                    id="ObjToo040__Symbol_200_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.25 16.35Q10.35 2.6 1.75 0q-5.35 11 5.8 33.75Q10.4 41 15.35 50.2L61.6 154.7l.1-.1q1.1.55 1.65.6.65.05 1.7-.4.65-.3 1-.6.3-.05.35-.2l.35-.05q.8 1.3.3 2.6-.15 1.05-1.25 2.6-1.1 1.75-1.3 2.55-.65 1.5-.15 2.75.7 1.35 1.8 1.65.4.1 2.3.25.35.05 2.2 0 .35.15.75.15.05 0 .2.05.2-.05.5-.05l1.85-.1 1.3-1.3q.25-.2.4-.3.2-.35.5-.75 1.25-1.35 1.45-1.65 1.2-1.5 1.5-1.85.2-.45.2-.95 0-.65-.35-1.5-.3-.7-.8-1.15-.5-.4-1.1-.7-.9-.4-2.9-.8-1.9-.3-2.8-.75-.8-.4-1.2-1.15-.3-.55-.5-.9l.4-.2q.2.05 1.45-.5 1-.4 1.5-1 .25-.35.6-1.55h.2l-1.3-3.1.05.05-2.7-6.25h-.05l-7.1-16.7h.05l-34.5-80.75q-.55-1.5-1.1-2.85-1.75-4.45-3.45-8.15l-.7-1.4-.6-1.35q-.85-2.3-1.7-4.4-1.75-4.45-3.45-8.15z"
                    id="ObjToo040__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M18.3 37.85Q12 22.8 4.45 8.3 2.25 4.1 0 0q1.35 4.6 2.85 9.15 5 15.5 11.75 30.3.6 1.25 1.2 2.55l31.8 73.1-.3-.65 13.9 32.35q.3-.05.35-.2l.35-.05q.8 1.3.3 2.6l4.35 9.95q.05 0 .2.05.2-.05.5-.05l1.85-.1 1.2-1.2-5-11.65q-.3-.55-.5-.9L52.75 117.5q-.329-.74-.65-1.5h-.05q-.25-.55-.5-1.15-.15-.4-.3-.75-3.35-8.45-10.05-23.5L18.3 37.85z"
                    id="ObjToo040__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjToo040__Layer14_0_FILL" />
            <use xlinkHref="#ObjToo040__Layer14_1_FILL" />
            <use
                xlinkHref="#ObjToo040__Symbol_200_0_Layer0_0_FILL"
                transform="translate(119.05 285)"
                id="ObjToo040__col1n"
            />
            <use
                xlinkHref="#ObjToo040__Symbol_80_0_Layer0_0_FILL"
                transform="translate(37.4 106.9)"
                id="ObjToo040__col2n"
            />
            <use
                xlinkHref="#ObjToo040__Symbol_39_0_Layer0_0_FILL"
                transform="translate(42.25 114.3)"
                id="ObjToo040__col2l"
            />
        </g></g>
    )
}

export default ObjToo040
