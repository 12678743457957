import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00284D",
    "col1n": "#00498A"
}

function AccMor042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.5 4.5q.95-1.95.15-4.5Q12.3.8 1.5 0q-.9 2.55.05 4.5 1.65 3.2 4 6.65l.2.3q.7 1.05.9 1.1L0 45.8 12.6 63l.25-.5L25.1 45.8l-6.65-33.25q.15-.05.85-1.1l.2-.3q2.35-3.45 4-6.65z"
                    id="AccMor042__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.1 0q-3 .75-5.9.75h-.6Q2.95.7.35 0L0 2.05q6.15 1.7 12.5 0L12.1 0z"
                    id="AccMor042__Symbol_82_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor042__Symbol_140_0_Layer0_0_FILL"
                transform="translate(179.95 248.95)"
                id="AccMor042__col1n"
            />
            <use
                xlinkHref="#AccMor042__Symbol_82_0_Layer0_0_FILL"
                transform="translate(186.25 261.5)"
                id="AccMor042__col1d"
            />
        </g></g>
    )
}

export default AccMor042
