import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M58.5 12.35q23.65-14.55 43.85 4.4-20.2-31.15-43.85-4.4M0 16.75q20.25-18.95 43.95-4.3Q20.25-14.3 0 16.75z"
                    id="FacBro021__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro021__Symbol_21_0_Layer0_0_FILL"
                transform="translate(141.35 115.45)"
                id="FacBro021__col1n"
            />
        </g></g>
    )
}

export default FacBro021
