import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiSpe130({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.55.2V.15l-.05-.1-.05-.05q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l.65-2.55q.55-2.75 1.1-5.45l2.7-14q.05-.45.1-.85.25-2.3.4-4.45 0-.2.05-.35l.1-2.4q.7-16.85-4.4-27.2M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35-.05.1-.1.25-4.8 9.55-4.55 24.75 0 1.2.05 2.4.05 1.35.15 2.75.15 2.15.4 4.45.05.4.1.85l2.85 14.75q.65 3.05 1.3 6.15l.25 1.1q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe130__Symbol_311_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe130__Symbol_311_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe130__col1n"
            />
        </g></g>
    )
}

export default ShiSpe130
