import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col1s": "#3E576D"
}

function FacNos009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.3 15.95l-.05.1q1.95-2.45 3.1-4.4 2.65-4.6 1.1-6.85Q24.65.15 17.2 0H14.8Q7.4.2.55 4.85-.95 7.1 1.7 11.7q1.1 1.95 3.05 4.4L4.7 16q3.5 3.4 7.2 4.5 1.45.45 2.9.5h1.45v-.05q.05 0 .1.05h.85q1.45-.05 2.95-.5 3.65-1.1 7.15-4.55z"
                    id="FacNos009__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M31.7 12.35q2.55-4.85.45-7.35l-.2-.2Q25.1.15 17.65.05q-.5-.05-1 0-.6-.05-1.2 0Q8 .15 1.15 4.8l-.2.2q-2.1 2.5.45 7.35 1.2 2.3 3.45 5.1l.05.05q1.8 1.8 3.7 2.95 3.35 2.1 6.85 2.15h2.2q3.5-.05 6.85-2.15 1.9-1.15 3.7-2.95l.05-.05q2.25-2.8 3.45-5.1m-4.65 3.95q-3.25 3.15-6.65 4.15-1.4.4-2.75.45h-.8q-.05-.05-.1-.05v.05h-1.3q-1.35-.05-2.7-.45-3.45-1-6.7-4.15l.05.1q-1.8-2.25-2.85-4.05Q.8 8.15 2.2 6.1q6.35-4.25 13.25-4.4h2.2q6.9.15 13.25 4.4 1.4 2.05-1.05 6.25-1.05 1.8-2.85 4.05l.05-.1z"
                    id="FacNos009__Symbol_7_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M3.9 3.8q-.15-.3-.25-.6-.3-.7-.45-1.2Q2.25-.3 1.15.2 0 .65 0 2.2q0 .4.25 1 .05.15.15.35.1.1.2.25.4.55.8.95L3.25 6.3q1.9 1.65 1.6 0L3.9 3.8m13-.25q.1-.2.2-.35.2-.6.2-1 0-1.55-1-2.1-1-.6-2.2 1.9-.15.5-.4 1.2-.1.3-.25.6-.4 1.05-1 2.5-.3 1.65 1.6 0l1.85-1.55q.4-.4.85-.95.05-.15.15-.25z"
                    id="FacNos009__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos009__Symbol_18_0_Layer0_0_FILL"
                transform="translate(176.5 189.85)"
                id="FacNos009__col1n"
            />
            <use
                xlinkHref="#FacNos009__Symbol_7_0_Layer0_0_FILL"
                transform="translate(175.95 188.8)"
                id="FacNos009__col1d"
            />
            <use
                xlinkHref="#FacNos009__Symbol_4_0_Layer0_0_FILL"
                transform="translate(183.85 197.25)"
                id="FacNos009__col1s"
            />
        </g></g>
    )
}

export default FacNos009
