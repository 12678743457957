import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaChi002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0l4.05 16L8.1 0H0z"
                    id="BeaChi002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <g id="BeaChi002__col1n" transform="translate(188.45 236.65)">
                <use
                    xlinkHref="#BeaChi002__Symbol_2_0_Layer0_0_FILL"
                    id="BeaChi002__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default BeaChi002
