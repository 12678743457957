import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFEE00",
    "col1n": "#FFFF00",
    "col2d": "#BFBFBF",
    "col2n": "#D9D9D9"
}

function ShiSpe091({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M262.5 310.1l.1-.05q-.05-.05-.15-.05-.05-.05-.1-.05l.05.2q.05-.05.1-.05m-6.8-82.25l.15-.05v-.05l-.15.1m-132.95 82.3l.05-.2q-.05 0-.1.05-.1 0-.15.05l.1.05q.05 0 .1.05m6.55-82.35l.15.05-.15-.1v.05m9.35 5.45l-1.55-1.15q-.252-.182-.5-.4l-.9-.6q-.695-.552-1.4-1.1l-1.8-1.35v-.15l-1.25-1-9.7 2.85q.5 1.15 1.05 2.3.4.75.8 1.55l.3.5.35.7q4.073 7.19 8.45 11.4 1.164 1.137 2.35 2.05.644.51 1.3.95h.05l.05.05v-.05l.8.6q.096 6.245-.75 13.5-.007.047-.05.1h.05v.05l-.05.2v.05L133.6 278q-.568 2.765-1.15 5.5l-3.4 14.3q-.49 1.948-1 3.9l-1.9 7.7v.05q.123.03.25.05.258.08.5.15h.15q1.13.258 2.8.45.843.133 1.3.2v.05l60.15 10.25h2.55l60.3-10.05.35-.05q.74-.126 1.3-.3 1.09-.255 2.15-.5h.15q.25-.05.5-.15.15 0 .25-.05l-.05-.1h-.05v-.2l-6.05-24.25q-.61-3.092-1.25-6.2l-3.05-17.55q-.518-5.677-.45-10.7l1.35-.9q.147-.102.3-.2 2.493-1.791 4.9-4.5 3.389-3.832 6.6-9.5l.35-.7.3-.5q.4-.8.8-1.55.55-1.15 1.05-2.3l-9.45-2.8q-.123-.06-.25-.15l-.1.05h-.05l-.15.15v.05l-2.9 2.35q-.297.231-.6.45-.397.324-.8.6-.69.535-1.4 1.05l-1.55 1.15q-1.307.903-2.65 1.7-18.18 11.336-43.2 14.3h-.05l-1.05.05q-3.083.184-6.25.2h-1.25q-3.193-.014-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16m41.8 61.55l-.4.05v-.05h.4m24.25 0h.4v.05l-.4-.05m1.4 2.35h.05v.05h-.2q.097-.048.15-.05m-27.1 0h.05q.053.002.15.05h-.2v-.05z"
                    id="ShiSpe091__Layer20_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M118.1 59.1q-1.35.15-2.7.35-10.8 1.35-21.4 2.1-1.1.1-2.15.15-2.4.15-4.75.3-4.6.2-9.2.35h-.85l-4.65.1q-.65 0-1.3.05-.25-.05-.5 0h-1.15q-.25-.05-.5 0-.65-.05-1.3-.05l-4.65-.1h-.85q-4.6-.15-9.2-.35-2.35-.15-4.75-.3-1.05-.05-2.15-.15-10.6-.75-21.4-2.1-1.35-.2-2.7-.35-.25-.05-.45-.05l-.7-.1q-3.35-.45-6.7-.95l-.7-.1q-1.9-.3-3.75-.6h-.1L7.9 64.2q.05.05.1.05 2 .35 4 .65.1 0 .25.05.2 0 .45.05 2.85.45 5.7.85 1.05.15 2.15.3.1 0 .25.05 14.8 1.95 29.1 2.85.95.05 1.9.15 1.3.05 2.65.15.05 0 .15.05H55.05q.25.05.85.05.55.05 1.45.15l-.9-.15v-.05h.05q.15 0 .3.05l3.3.1q.55 0 1.15.05.4 0 .85.05h.85l6.5.1h1.15l6.5-.1h.85q.45-.05.85-.05.6-.05 1.15-.05l3.3-.1q.15-.05.3-.05h.05v.05l-.9.15q.9-.1 1.45-.15.6 0 .85-.05h.45q.1-.05.15-.05 1.35-.1 2.65-.15.95-.1 1.9-.15 14.3-.9 29.1-2.85.15-.05.25-.05 1.1-.15 2.15-.3 2.85-.4 5.7-.85l.7-.1q.2-.05.45-.05 1.7-.3 3.45-.55l-1.75-6.95-3.2.5q-.2 0-.35.05-3.7.55-7.4 1.05l-.7.1q-.2 0-.45.05m-35.55 7.95v.05l-.4-.05h.4m-24.9 0h.25l-.4.05v-.05h.15z"
                    id="ShiSpe091__Symbol_200_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6.2.7L5.5.6Q3.6.3 1.75 0h-.1L0 6.9q.05.05.1.05 2 .35 4 .65.1 0 .25.05L6.2.7M124.05 7L122.3.05l-3.2.5 1.5 7q1.7-.3 3.45-.55z"
                    id="ShiSpe091__Symbol_201_0_Layer0_0_FILL"
                />
                <path
                    fill="#D2AD00"
                    d="M204.7 294.75l.25.05h.15q1.6.3 3.8.1-.1 1.15-1.25 1.7-.9.4-1.5.5-.2.05-.35.05h-.3l.3.05q.148-.05.3-.05h.05v.05l-.9.15q.9-.1 1.45-.15.6-.05.85-.1h.45q.1-.05.15-.05.7-.4 1.15-.9.6-.7.35-1.35 1.15-.4 1.75-.95.55-.55.8-1.25.25-.75.75-.9 1.75-.55 2.45-2.25-.5 0-1 .05-.7 1.5-2.9 2.1-1.35.35-5.4.65.55.25 1.25.3.8 0 2.1-.05 1.25-.1 1.5-.25h.25q-.2 1.65-2.15 2.05-.5.15-4.35.45m-4.25-4.65h-.85q3.1 3.2.05 7.25h.85q3-4.1-.05-7.25m-11.35 3.2h2.6v3.25h1.75v-3.25h2.6v-2.15h-6.95v2.15m-3.55-3.2h-.85q-3.05 3.15-.05 7.25h.85q-3.05-4.05.05-7.25m-14.8-.6q-.5-.05-1-.05.7 1.7 2.45 2.25.5.15.75.9.25.7.8 1.25.6.55 1.75.95-.25.65.35 1.35.45.5 1.15.9.05 0 .15.05H177.6q.25.05.85.1.55.05 1.45.15l-.9-.15v-.05h.05q.152 0 .3.05l.3-.05h-.3q-.15 0-.35-.05-.6-.1-1.5-.5-1.15-.55-1.25-1.7 2.2.2 3.8-.1h.15l.25-.05q-3.85-.3-4.35-.45-1.95-.4-2.15-2.05h.25q.25.15 1.5.25 1.3.05 2.1.05.7-.05 1.25-.3-4.05-.3-5.4-.65-2.2-.6-2.9-2.1z"
                    id="ShiSpe091__Layer16_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.75 47.7H7.3q-2.95.15-5.1 2.2Q.55 51.6.15 53.85 0 54.6 0 55.4q0 1.15.3 2.2.25 1 .8 1.9.3.5.7.95.2.25.4.45 1.05 1 2.25 1.6 1.5.7 3.3.7 3.2 0 5.5-2.3 1.45-1.45 1.95-3.3.3-1.05.3-2.2 0-1.15-.3-2.2-.1-.45-.3-.85-.55-1.35-1.65-2.45l-.05-.05q-2.3-2.15-5.45-2.15m-2.5-27.85q-1.7.5-3.05 1.8-1.65 1.7-2.05 3.9v.05q-.05.2-.05.4-.1.55-.1 1.15 0 .65.1 1.3l.1.6q.05.1.05.2.15.5.35 1.05.55 1.3 1.6 2.35 1.05 1 2.25 1.6 1.5.7 3.3.7 3.2 0 5.5-2.3.65-.65 1.15-1.35 0-.1.05-.15.3-.5.5-1 .15-.4.25-.75.05-.2.1-.35l.1-.6q.1-.65.1-1.3v-.55q-.05-.3-.05-.6-.05-.25-.1-.45-.05-.3-.15-.6-.1-.45-.3-.85-.55-1.35-1.65-2.45-2.3-2.2-5.5-2.2-1.35 0-2.5.4m9.05-7.9q.8-1.25 1.05-2.75.05-.1.05-.25.1-.6.1-1.25 0-.5-.05-1-.15-1.25-.65-2.35-.6-1.2-1.55-2.15Q11.55.55 9.3.15 8.55 0 7.75 0 4.55 0 2.2 2.2.35 4.1.05 6.7 0 7.15 0 7.7q0 .65.1 1.25.25 1.65 1.1 3 .45.65 1 1.25 1 1 2.2 1.55 1.5.75 3.35.75 3.2 0 5.5-2.3.6-.6 1.05-1.25M49.4 47.7h-.45q-2.95.15-5.1 2.2-1.65 1.7-2.05 3.95-.15.75-.15 1.55 0 1.15.3 2.2.25 1 .8 1.9.3.5.7.95.2.25.4.45 1.05 1 2.25 1.6 1.5.7 3.3.7 3.2 0 5.5-2.3 1.45-1.45 1.95-3.3.3-1.05.3-2.2 0-1.15-.3-2.2-.1-.45-.3-.85Q56 51 54.9 49.9l-.05-.05q-2.3-2.15-5.45-2.15m0-28.25q-1.35 0-2.5.4-1.7.5-3.05 1.8-1.65 1.7-2.05 3.9v.05q-.05.2-.05.4-.1.55-.1 1.15 0 .65.1 1.3l.1.6q.05.1.05.2.15.5.35 1.05.55 1.3 1.6 2.35 1.05 1 2.25 1.6 1.5.7 3.3.7 3.2 0 5.5-2.3.65-.65 1.15-1.35 0-.1.05-.15.3-.5.5-1 .15-.4.25-.75.05-.2.1-.35l.1-.6q.1-.65.1-1.3v-.55q-.05-.3-.05-.6-.05-.25-.1-.45-.05-.3-.15-.6-.1-.45-.3-.85-.55-1.35-1.65-2.45-2.3-2.2-5.5-2.2m7.65-10.5q.1-.6.1-1.25 0-.5-.05-1-.15-1.25-.65-2.35-.6-1.2-1.55-2.15Q53.2.55 50.95.15 50.2 0 49.4 0q-3.2 0-5.55 2.2Q42 4.1 41.7 6.7q-.05.45-.05 1 0 .65.1 1.25.25 1.65 1.1 3 .45.65 1 1.25 1 1 2.2 1.55 1.5.75 3.35.75 3.2 0 5.5-2.3.6-.6 1.05-1.25.8-1.25 1.05-2.75.05-.1.05-.25z"
                    id="ShiSpe091__Symbol_202_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M10.5 48h-.1q-4.15.2-7.2 3.2-.95.95-1.6 2.05-1.4 2.2-1.55 4.9 1.5.7 3.3.7 3.2 0 5.5-2.3 1.45-1.45 1.95-3.3.3-1.05.3-2.2 0-1.15-.3-2.2-.1-.45-.3-.85M5.9 21q-1.45.75-2.7 1.95-.8.85-1.4 1.75Q.25 27.05.05 29.9q1.5.7 3.3.7 3.2 0 5.5-2.3.65-.65 1.15-1.35 0-.1.05-.15.3-.5.5-1 .15-.4.25-.75.05-.2.1-.35l.1-.6q.1-.65.1-1.3v-.55q-.05-.3-.05-.6-.05-.25-.1-.45-.05-.3-.15-.6-.1-.45-.3-.85h-.1q-2.45.1-4.5 1.25M11 4.6q.1-.6.1-1.25 0-.5-.05-1Q10.9 1.1 10.4 0 9.1.05 7.95.4 5.3 1.1 3.2 3.2.2 6.25 0 10.4q1.5.75 3.35.75 3.2 0 5.5-2.3.6-.6 1.05-1.25.8-1.25 1.05-2.75.05-.1.05-.25M52.15 48h-.1q-4.15.2-7.2 3.2-.95.95-1.6 2.05-1.4 2.2-1.55 4.9 1.5.7 3.3.7 3.2 0 5.5-2.3 1.45-1.45 1.95-3.3.3-1.05.3-2.2 0-1.15-.3-2.2-.1-.45-.3-.85m-7.3-25.05q-.8.85-1.4 1.75-1.55 2.35-1.75 5.2 1.5.7 3.3.7 3.2 0 5.5-2.3.65-.65 1.15-1.35 0-.1.05-.15.3-.5.5-1 .15-.4.25-.75.05-.2.1-.35l.1-.6q.1-.65.1-1.3v-.55q-.05-.3-.05-.6-.05-.25-.1-.45-.05-.3-.15-.6-.1-.45-.3-.85h-.1q-2.45.1-4.5 1.25-1.45.75-2.7 1.95m7.8-18.35q.1-.6.1-1.25 0-.5-.05-1-.15-1.25-.65-2.35-1.3.05-2.45.4-2.65.7-4.75 2.8-3 3.05-3.2 7.2 1.5.75 3.35.75 3.2 0 5.5-2.3.6-.6 1.05-1.25.8-1.25 1.05-2.75.05-.1.05-.25z"
                    id="ShiSpe091__Symbol_203_0_Layer0_0_FILL"
                />
                <path
                    fill="#034D7C"
                    d="M193 297.5h-1v23.1h1.15v-23.1H193m-.4-48H192v40.7q.074.04.15.05h1V249.5h-.55m58.4 43.05q-.184.068-.4.1l-.7.1q-2.85.45-5.7.85 5.5-.3 7.65 5.35 1 2.55 1.3 6.35l1 5.25.35-.05q.74-.126 1.3-.3 1.09-.255 2.15-.5h.15q.25-.05.5-.15.15 0 .25-.05l-.05-.1h-.05v-.2l-4.3-17.2q-1.695.321-3.45.55m-5.4-32.35h-.05q-.3 2.7-1.75 3.5-1.45.8-5.45 1.7-3.9.85-6.55 1.85 4.6 1.05 8.05 1.25 3.5.15 5.3 2.2.85 1.55 1.55 4.65.25 1 .65 2.4v.05l-.1.1q1.55 6.85-5.45 8.7-.55.1-1.15.25.25-.05.45-.05l.7-.1q3.7-.5 7.4-1.05l.3-.05q1.66-.26 3.25-.55l-.05-.1q-.61-3.092-1.25-6.2l-3.05-17.55q-.518-5.677-.45-10.7.026-2.437.2-4.75l-2.55 14.45m16.05-26h.1q.4-.8.8-1.55.55-1.15 1.05-2.3l-9.45-2.8q-.123-.06-.25-.15l-.1.05h-.05l-.15.15v.05l-2.9 2.35q-.297.231-.6.45-.397.324-.8.6l12.35 3.15M130.55 292l-.1-.05-1.4 5.85q-.49 1.948-1 3.9l-1.9 7.7v.05q.123.03.25.05.258.08.5.15h.15q1.13.258 2.8.45.843.133 1.3.2l.85-5q.3-3.8 1.3-6.35 2.15-5.65 7.65-5.35-2.85-.4-5.7-.85-.23-.033-.45-.1-.13.017-.25 0-2-.3-4-.65m14.75-23.5q3.45-.2 8.05-1.25-2.65-1-6.55-1.85-4-.9-5.45-1.7-1.45-.8-1.75-3.5h-.05l-2.85-16.25q.32 3.082.35 6.45.096 6.245-.75 13.5-.007.047-.05.1h.05v.05l-.05.2v.05L133.6 278q-.568 2.765-1.15 5.5l-.35 1.55h.1q1.85.3 3.75.6.356.053.7.1 3.356.498 6.7.95l.7.1q.2 0 .45.05-.6-.15-1.15-.25-7-1.85-5.45-8.7l-.1-.1v-.05q.4-1.4.65-2.4.7-3.1 1.55-4.65 1.8-2.05 5.3-2.2m-11-38.5l-1.8-1.35v-.15l-1.25-1-9.7 2.85q.5 1.15 1.05 2.3.4.75.8 1.55h.1l12.2-3.1q-.695-.552-1.4-1.1z"
                    id="ShiSpe091__Layer11_0_FILL"
                />
            </defs>
            <use xlinkHref="#ShiSpe091__Layer20_0_FILL" />
            <use
                xlinkHref="#ShiSpe091__Symbol_200_0_Layer0_0_FILL"
                transform="translate(122.55 227.75)"
                id="ShiSpe091__col1n"
            />
            <use
                xlinkHref="#ShiSpe091__Symbol_201_0_Layer0_0_FILL"
                transform="translate(130.45 285.05)"
                id="ShiSpe091__col1d"
            />
            <use xlinkHref="#ShiSpe091__Layer16_0_FILL" />
            <use
                xlinkHref="#ShiSpe091__Symbol_202_0_Layer0_0_FILL"
                transform="translate(164 251.8)"
                id="ShiSpe091__col2n"
            />
            <use
                xlinkHref="#ShiSpe091__Symbol_203_0_Layer0_0_FILL"
                transform="translate(168.4 256.15)"
                id="ShiSpe091__col2d"
            />
            <use xlinkHref="#ShiSpe091__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe091
