import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiSpe127({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.6.1l-1.1.05q-3.1.2-6.3.2H9q-3.2 0-6.3-.2L1.6.1 0 0v68.3q.8 0 1.6.05 4 .1 8 .1t8-.1q.85-.05 1.65-.05V0q-.3 0-.6.05-.5 0-1.05.05z"
                    id="ShiSpe127__Symbol_308_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe127__Symbol_308_0_Layer0_0_FILL"
                transform="translate(182.9 249.15)"
                id="ShiSpe127__col1n"
            />
        </g></g>
    )
}

export default ShiSpe127
