import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M86.8 14q.35-.8.6-1.45-4.55 3.7-11.15 3.7-3.15 0-6-2.5-1.6-1.45-4.7-5.55-4.75-6.45-9.3-7.75-5.2-1.5-10.55 2.3-.7.45-1.35 1l-.65.5q-.25-.2-.35-.25-6.2-5.3-12.2-3.55-4.55 1.3-9.3 7.75-3.1 4.1-4.7 5.55-2.85 2.5-6 2.5-6.6 0-11.15-3.7.25.65.6 1.45 3.05 6.75 10.9 7.3h2.25q2.2-.05 4.5-.55 1.45-.35 3-.9 6.5-2.75 11.9-7.1.75-.6 1.5-1.05 4.3-2.8 9-2.7h.1q4.7-.05 9 2.7.75.45 1.5 1.05 5.4 4.35 11.9 7.1 1.55.55 3 .9 2.3.5 4.5.55h2.25q7.85-.55 10.9-7.3z"
                    id="BeaMus018__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus018__Symbol_35_0_Layer0_0_FILL"
                transform="translate(148.8 198.1)"
                id="BeaMus018__col1n"
            />
        </g></g>
    )
}

export default BeaMus018
