import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1l": "#FFFFFF",
    "col1n": "#EEEEEE",
    "col1s": "#AFAFAF"
}

function AccJew029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.85 5.35q-1.1-1.25-2.8-2.3-.25-.15-.4-.3-.05-.05-.1-.05-1.5-.9-3.35-1.55h-.05l-.6-.15-.35-.2H17l-.25-.1Q15.5.35 14.35.2q-.25 0-.45-.05h-.2Q13.45.1 13.25.1q-1.45-.15-2.9-.1h-.5Q9.8 0 9.7.05L9.65 0H9.2Q7.5.2 5.85.8q-.15 0-.2-.05-.15.05-.35.1Q5.2.8 5.2.9l-.1.05q-.6.25-1.05.4-.7.2-1.3.6H2.6l-.4.2q.1 0 .05.05l-.15.1v.25q0 .05-.1.15v.1q-.45 1.35-.75 2.6 0 .35-.05.6-.05.1-.05.35l-.05.05v.15q-.1.35-.15.7Q.7 8.4.4 9.55q-.05.5-.1 1.1-.05.75-.15 1.45v.05q-.1.7-.15 1.4h.1v.2q.45-.6 1.05-1.1.3-.3.65-.55.65-.5 1.4-.9.35-.25.75-.4 1.75-.8 3.9-1.05.35-.05.7-.05.85-.1 1.8-.05l1.1.1h.35q.4.05.75.1.2.05.45.05 1.35.2 2.5.55.05.1.2.15l.15.05.1.05q.2.05.5.2 1.4.6 2.7 1.5l3.6 2.8 2.1-9.85z"
                    id="AccJew029__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.05.4q-.7.2-1.3.6H2.6l-.4.2q.1 0 .05.05l-.15.1v.25q0 .05-.1.15v.1q-.45 1.35-.75 2.6-.05.55 0 1-.1.05-.15.1v.05q-.1.35-.15.7Q.7 7.45.4 8.6q0 .55.05 1-.1.05-.15.1-.05.75-.15 1.45v.05q-.1.7-.15 1.4h.1v.2q.45-.6 1.05-1.1.3-.3.65-.55.65-.5 1.4-.9L5.1 0q-.6.25-1.05.4m20.8 4q-1.1-1.25-2.8-2.3l-.6 3.85-.05-.05-.6 3.65h-.05l-.45 2.7 2.45 2 .5-2.4-.05-.05q.1-.4.25-.95l.55-2.6V8.2q.05-.35.2-.75l.65-3.05z"
                    id="AccJew029__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M5.3.15h-.15Q4.9.1 4.7.1 3.25-.05 1.8 0L.55 6.2H.6L0 9.7q.85-.1 1.8-.05l1.1.1h.35L5.3.15M1.85 3.4l.05-.05.05.05h-.1z"
                    id="AccJew029__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M21.65 3.05q-.25-.15-.4-.3l-.4 2.35q-1.5-.9-3.15-1.45l.4-2.35q-.15-.1-.3-.15h-.05l-.4-.1-.45 2.35q-1.6-.5-3.35-.75L14 .2h-.05q-.25 0-.45-.05H13.2l-.45 2.4q-1.2-.1-2.45-.1h-.8L9.95 0h-.5Q9.4 0 9.3.05L9.25 0h-.1l-.5 2.5q-2.1.15-4 .6l.4-2.3Q5 .85 4.9.85 4.8.8 4.8.9l-.1.05q-.3.1-.55.25L3.8 3.35q-1.4.4-2.65 1-.2.55-.3 1.05 1.35-.7 2.8-1.15L3.1 7.2Q1.65 7.7.3 8.45L0 9.55q1.4-.9 2.95-1.45l-.6 3.35q.2-.15.45-.25.25-.15.5-.25l.55-3.15q1.9-.65 4-.8L7.3 9.8q.1-.05.15-.05.35-.05.7-.05l.55-2.75q.45-.05.95-.05 1.15 0 2.3.1l-.5 2.75.7.1q.05 0 .1.05l.5-2.8q1.7.2 3.3.7l-.5 2.9q.2.05.5.2.15.05.25.1l.55-2.9q1.7.6 3.25 1.55L19.5 13l.75.55.6-3.45q.1.05.2.15 1.1.8 2 1.7l.2-.95q-.8-.75-1.75-1.4-.25-.2-.5-.35l.45-2.8q.1.05.25.15 1 .7 2.05 1.9l.2-.95q-.9-1-1.8-1.6-.3-.2-.55-.35l.4-2.3q-.15-.15-.35-.25m-.95 2.9l-.45 2.85Q18.7 7.85 17 7.25l.55-2.8q1.65.6 3.15 1.5m-10.4-2.7q1.2 0 2.3.1l-.5 2.85q-1.2-.1-2.45-.1h-.8l.5-2.85h.95M16.2 7q-1.6-.45-3.3-.7l.5-2.85q1.75.2 3.35.7L16.2 7M8.5 3.3L8 6.15q-2.1.15-4 .75L4.5 4q1.9-.55 4-.7z"
                    id="AccJew029__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew029__Symbol_98_0_Layer0_0_FILL"
                transform="translate(110 272.2)"
                id="AccJew029__col1n"
            />
            <use
                xlinkHref="#AccJew029__Symbol_67_0_Layer0_0_FILL"
                transform="translate(110 273.15)"
                id="AccJew029__col1d"
            />
            <use
                xlinkHref="#AccJew029__Symbol_55_0_Layer0_0_FILL"
                transform="translate(118.55 272.2)"
                id="AccJew029__col1l"
            />
            <use
                xlinkHref="#AccJew029__Symbol_46_0_Layer0_0_FILL"
                transform="translate(110.4 272.2)"
                id="AccJew029__col1s"
            />
        </g></g>
    )
}

export default AccJew029
