import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col2n": "#000000"
}

function LegPan010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7 39.35l.05-.05q-2.2.45-4.4.85-.2 0-.4.05-.45.1-.95.2-4.45.75-8.85 1.45Q59.1 49.7 8.35 40.9l-3.8-.7q-.45-.1-.95-.2-.85-.15-1.65-.3-1-.2-1.95-.4 0 .05.05.1v19.5q.95-.2 1.9-.3.85-.15 1.65-.25.5-.1.95-.15 1.9-.25 3.8-.45 28.35-3.2 54.5 3.9 23.25-6.35 48.3-4.5 4.4.35 8.85.9.45.05.95.15.2 0 .4.05 2.15.25 4.35.65V39.35z"
                    id="LegPan010__Symbol_14_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.95.4Q.95.2 0 0q0 .05.05.1v19.5q.95-.2 1.9-.3.85-.15 1.65-.25.5-.1.95-.15V.9Q4.1.8 3.6.7 2.75.55 1.95.4M125.7.05l.05-.05q-2.2.45-4.4.85v18.1q2.15.25 4.35.65V.05z"
                    id="LegPan010__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan010__Symbol_14_0_Layer0_0_FILL"
                transform="translate(129.65 270.95)"
                id="LegPan010__col1n"
            />
            <use
                xlinkHref="#LegPan010__Symbol_15_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan010__col2n"
            />
        </g></g>
    )
}

export default LegPan010
