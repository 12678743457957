import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou054({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.05 4.65q-.65-.5-1.55-1.1-1.15-.8-2.6-1.5Q15.8-.9 8.1.35 2.5.65.1 5q-.7 2.05 3.2 3.25 1.6.45 3.2.25l.6-.1h.2q.2-.1.45-.15h.2q6.4-1.2 12.55.05.2 0 .4.05.1.05.2.05 2.15.5 3.7.85 1.6.35 1.75.3.45.05 1.1.05h.4q4.45-1.3 0-4.95z"
                    id="FacMou054__Symbol_79_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou054__Symbol_79_0_Layer0_0_FILL"
                transform="translate(177.4 218.05)"
                id="FacMou054__col1n"
            />
        </g></g>
    )
}

export default FacMou054
