import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.1 14.05q1.2-1.25 1.7-2.4 1.25-3.15-2.8-5.9-.25-.15-.45-.3-2-1-4.75-3Q34.8.3 32.15.1 29.5-.1 28.5.15q-2.2.3-3.8 2.1-1.15 1.3-1.65 1.95-.5-.65-1.65-1.95-1.6-1.8-3.8-2.1-1-.25-3.65-.05T8.3 2.45q-2.75 2-4.75 3-.2.15-.45.3-.6.4-1.1.85-3.95 3.45 0 7.45l.45.45Q5.5 17.1 9 18.85q5.6 2.85 12.2 3.4 2.1.1 4.2-.05 6.15-.7 11.5-3.35 3.8-1.9 7.2-4.8z"
                    id="FacMou039__Symbol_64_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M30.35 2.4q.45-.5.6-1 .35-1.45-2.7-1.4-.05 0-.4.05h-1q-.65 0-3.1.35-2.45.3-8.35.35h-.1Q9.4.7 7 .4 4.55.05 3.85.05h-1Q2.55 0 2.5 0-.05.2 0 1.4q0 .8 2.2 2.4.6.45 2.45 1.4Q9 7.4 15.3 7.6h.1q6.3-.2 10.65-2.4 1.95-1 2.85-1.65.9-.65 1.45-1.15z"
                    id="FacMou039__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou039__Symbol_64_0_Layer0_0_FILL"
                transform="translate(169.5 211.2)"
                id="FacMou039__col1n"
            />
            <use
                xlinkHref="#FacMou039__Symbol_17_0_Layer0_0_FILL"
                transform="translate(177.2 221.45)"
                id="FacMou039__col1l"
            />
        </g></g>
    )
}

export default FacMou039
