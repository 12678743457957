import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col2n": "#FFFFFF"
}

function FacMou034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M45.65 4.1q-2.45.45-3.25.25-4.7-.55-8.2-2-1.4-.65-2.85-1.05-7.95-2.55-17.05 0-1.5.4-2.9 1.05-3.4 1.45-8.15 2-.8.2-3.25-.25 5.95 4.1 8 5 1.85.75 5.65 1.65 3.65.75 9 .75h.3q5.45.15 9.05-.75 3.8-.9 5.7-1.65 1.95-.9 7.95-5z"
                    id="FacMou034__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M44.45 15.1q1.3-1.15 2.2-2.1 1.5-1.55 3.15-2.95 1.65-1.4 5.2-4.9-7.15 1.3-15.65-1.7-1.95-.8-4.45-1.75-.2-.1-.55-.2-.55-.25-1.1-.4-.6-.25-1.2-.4-4.4-1.4-9.1 0-.6.15-1.2.4-.55.15-1.1.4-.35.1-.55.2-2.5.95-4.45 1.75-8.5 3-15.65 1.7 3.55 3.5 5.2 4.9 1.65 1.4 3.15 2.95.9.95 2.2 2.1 3 3 9.9 4.8 7.25 2 14.1 0 6.9-1.8 9.9-4.8m4.85-7.55q-5.8 3-7.7 3.65-1.85.55-5.55 1.2-3.5.65-8.75.55H27q-5.2 0-8.7-.55-3.7-.65-5.5-1.2-2-.65-7.75-3.65 2.35.3 3.15.2 4.6-.4 7.9-1.45 1.35-.5 2.8-.8 8.85-1.85 16.55 0 1.4.3 2.75.8 3.4 1.05 7.95 1.45.75.1 3.15-.2z"
                    id="FacMou034__Symbol_59_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou034__Symbol_119_0_Layer0_0_FILL"
                transform="translate(169.7 213)"
                id="FacMou034__col2n"
            />
            <use
                xlinkHref="#FacMou034__Symbol_59_0_Layer0_0_FILL"
                transform="translate(165.05 209.85)"
                id="FacMou034__col1n"
            />
        </g></g>
    )
}

export default FacMou034
