import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#999999",
    "col2l": "#D9D9D9",
    "col2n": "#BFBFBF"
}

function AccHat161({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M183.3 30.3q.75.15 1.55.3 2.75.2 2.35-2.8l-3.3-13.7q-.5-2.5-3.55-3Q136.75.05 93.6 0 50.45.05 6.85 11.1q-3.05.5-3.55 3L.15 27.35q-.7 3.3 1.65 3.35l2.2-.4q45.7-8.5 89.6-8.85 43.9.35 89.7 8.85z"
                    id="AccHat161__Symbol_524_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M181.25 16.25l.3.25-.6-2.4q-.5-2.5-3.55-3Q133.8.05 90.65 0 47.5.05 3.9 11.1q-3.05.5-3.55 3L0 15.65q.8-1.55 3.15-1.95Q47 2.65 90.35 2.6q43.4.05 87.2 11.1 1.75.3 2.65 1.25.45.25.8.6.3.35.25.7z"
                    id="AccHat161__Symbol_525_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M42.5 42.5q7.3-7.25 7.3-17.6 0-10.35-7.3-17.6-1-1-2-1.8-.1-.1-.2-.15-.4-.35-.8-.65-.4-.3-.8-.55Q32.65 0 24.9 0 14.55 0 7.25 7.3.6 13.95.05 23.25 0 23.6 0 24v.9q0 10.35 7.25 17.6 7.3 7.25 17.65 7.25 10.3 0 17.6-7.25z"
                    id="AccHat161__Symbol_526_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M38.7 4.7v-.55Q32.65 0 24.9 0 14.55 0 7.25 7.3.6 13.95.05 23.25 0 23.6 0 24q6.1 4 14 4 10.25 0 17.5-6.8 7.1-6.75 7.2-16.25V4.7z"
                    id="AccHat161__Symbol_527_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M41.1 4.65V4.6l-.1-.1q-.4-.25-.75-.45-.4-.25-.75-.5Q33.6 0 26.2 0 15.35 0 7.65 7.7 1.2 14.15.2 23q-.05.4-.1.85v.25q-.1 1-.1 2.1 0 10.85 7.65 18.5 7.7 7.65 18.55 7.65 10.85 0 18.55-7.65 7.65-7.65 7.65-18.5T44.75 7.7Q43 6 41.1 4.65m.1 2.5q.1.05.2.15 1 .75 1.95 1.75Q50.5 16.1 50.5 26.2q0 10.05-7.15 17.15-7.1 7.05-17.15 7.05-10.05 0-17.15-7.05-7.1-7.1-7.1-17.15v-.45-.2-.25q0-.4.05-.7.55-9.1 7.05-15.55 7.1-7.1 17.15-7.1 7.55 0 13.45 4.05.4.2.8.5t.75.65M26.25 23.3q-1.25 0-2.15.9-.9.9-.9 2.15t.9 2.15q.9.9 2.15.9t2.15-.9q.9-.9.9-2.15t-.9-2.15q-.9-.9-2.15-.9z"
                    id="AccHat161__Symbol_528_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat161__Symbol_524_0_Layer0_0_FILL"
                transform="translate(98.8 95.8)"
                id="AccHat161__col1n"
            />
            <use
                xlinkHref="#AccHat161__Symbol_525_0_Layer0_0_FILL"
                transform="translate(101.65 95.8)"
                id="AccHat161__col1d"
            />
            <use
                xlinkHref="#AccHat161__Symbol_526_0_Layer0_0_FILL"
                transform="translate(167.5 74)"
                id="AccHat161__col2n"
            />
            <use
                xlinkHref="#AccHat161__Symbol_527_0_Layer0_0_FILL"
                transform="translate(167.5 74)"
                id="AccHat161__col2l"
            />
            <use
                xlinkHref="#AccHat161__Symbol_528_0_Layer0_0_FILL"
                transform="translate(166.15 72.7)"
                id="AccHat161__col2d"
            />
        </g></g>
    )
}

export default AccHat161
