import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFDE",
    "col2n": "#D7A5B9"
}

function FacMou083({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M42.25 3.8q-.5.2-1.4.4-.35.05-.8.15h-.15q-.1 0-.2.05-.5 0-1 .05h-2.3q-1.7-.3-2.35-.8l-.15-.15q-.45-.5-.45-1.45 0-.7.1-.8l.1-.3q-.35.35-.7.6-1 .8-2.15 1.1-.4.05-.8.05-1.05 0-1.65-.4-.45-.15-.75-.95Q27.45.8 27.45.3q.05-.2.05-.3-.6.6-1.1.8-.95.7-2.2.9-.4.05-.75.05-1.6 0-2.1-1.75-.55 1.75-2.05 1.75-.45 0-.8-.05-1.3-.2-2.2-.9-.55-.2-1.15-.8 0 .1.05.3 0 .5-.15 1.05-.25.8-.75.95-.6.4-1.6.4-.45 0-.8-.05-1.1-.3-2.15-1.1-.4-.25-.7-.6.1.15.15.3.05.1.05.8 0 .95-.5 1.45l-.15.15q-.65.5-2.3.8H4.05q-.6-.05-1.1-.1H2.6Q1.15 4.1.45 3.8.2 3.65 0 3.4v.1q.5 1.1 1.85 1.55.4.2.85.3.3.05.65.15.1 0 .25.05.2 0 .45.05 2.65.4 5.1-.55 1.35-.25 2.3-.5 3.3-1 5.6-1.35h3.1q.6 0 1.2.05.6-.05 1.15-.05h3.15q2.3.35 5.55 1.35 1 .25 2.4.5 2.35.95 5.05.55.2-.05.4-.05.2-.05.45-.1.6-.1 1.3-.3 0-.1.05-.1 1.35-.45 1.8-1.55v-.1q-.15.25-.4.4z"
                    id="FacMou083__Symbol_134_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M7.4 11.4Q4.3 6.25 2.65 0q-5.3 10.25-.05 19.85h.05q.2.25.45.4.7.3 2.15.55H5.6q.5.05 1.1.1h2.25q1.65-.3 2.3-.8l.15-.15q.5-.5.5-1.45 0-.7-.05-.8-.05-.15-.15-.3-1.45-1.9-2.4-3.2 0-.05-.05-.15-.4-.5-.75-1-.1-.15-.2-.25l-.05-.05-.1-.1Q7.8 12 7.4 11.4m14.55 6.8q1.5 0 2.05-1.75-.05-2-.3-3.7-.5-1.85-2.1-2.05h-1.8q-1.65.2-2.1 1.25-.4.45-.4 1.6 0 .15.05.35.188 1.406.4 2.2-.514-1.629-.95-2.55-1.15-2.15-3.25-2-1.15.1-1.95.45-1.05.4-1.25 1-.1.25-.1.55 0 .35.1.75.15.6.45 1.15.25.5.5 1.25l.4.7q.3.35.7.6 1.05.8 2.15 1.1.35.05.8.05 1 0 1.6-.4.5-.15.75-.95.15-.55.15-1.05-.05-.2-.05-.3.6.6 1.15.8.9.7 2.2.9.35.05.8.05M36.4 12q-.7-.3-1.6-.45-2.45-.25-3.3 1.55-.65.9-1.15 2.85.2-.95.35-2.4v-.15q-.05-1.05-.45-1.45-.5-1.1-2.05-1.25h-1.75q-1.7.15-2.15 1.95-.05.2-.05.45-.2 1.55-.25 3.35.5 1.75 2.1 1.75.35 0 .75-.05 1.25-.2 2.2-.9.5-.2 1.1-.8 0 .1-.05.3 0 .5.15 1.05.3.8.75.95.6.4 1.65.4.4 0 .8-.05 1.15-.3 2.15-1.1.35-.25.7-.6l.35-.7q.3-.75.55-1.25.25-.55.45-1.15.25-.65.05-1.2-.05-.05-.05-.1-.25-.6-1.25-1m8.9-12q-1.6 6.25-4.7 11.4-.4.6-.75 1.2-.1.05-.15.15v.05l-.25.25q-.25.35-.5.75-.1.1-.2.25-.95 1.4-2.45 3.35l-.1.3q-.1.1-.1.8 0 .95.45 1.45l.15.15q.65.5 2.35.8h2.3q.5-.05 1-.05.1-.05.2-.05h.15q.45-.1.8-.15.9-.2 1.4-.4.25-.15.4-.4h.05q5.3-9.6-.05-19.85z"
                    id="FacMou083__Symbol_108_0_Layer0_0_FILL"
                />
                <g id="FacMou083__Layer13_0_FILL">
                    <path
                        fillOpacity={0.4}
                        d="M206.45 233.3h-2.85q-.2-.05-2.55-.35-.45-.1-.95-.15l-1.2-.25q-1.85-.4-6.4-.5-4.6.1-6.35.5l-1.3.25q-.4.05-.9.15-2.35.3-2.6.35h-2.75q-2-.15-3.4-1-.75-.4-1.35-.9.25.65.7 1.2.65.65 2.05 1.25.1 0 .2.05.65.3 1.35.5 1.75.35 3.55.35 1.1 0 2.2-.2.45-.05.95-.15 5.65-.8 7.65-.6 2-.2 7.6.6.5.1.95.15 1.1.2 2.25.2 1.8 0 3.55-.35.7-.2 1.3-.5 1.15-.45 1.85-.85.25-.2.45-.45.25-.35.5-.75.1-.2.2-.45-1.85 1.65-4.7 1.9z"
                    />
                    <path d="M209.1 219.05q-.35-.2-.65-.35-.6-.25-1.2-.55-.1-.05-.15-.1-1.9-.85-3.95-1.4-4.7-1.2-10.5-1.2-5.85 0-10.5 1.25-2.2.55-4.1 1.45l-.7.3q-.55.3-1 .55-.05 0-.1.05t-.15.1q.05.1.15.2l-.55 7.2 31.55.85 1.7-7.7q.2-.3.35-.55l-.2-.1z" />
                </g>
            </defs>
            <use xlinkHref="#FacMou083__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou083__Symbol_134_0_Layer0_0_FILL"
                transform="translate(171.2 224.2)"
                id="FacMou083__col2n"
            />
            <use
                xlinkHref="#FacMou083__Symbol_108_0_Layer0_0_FILL"
                transform="translate(168.5 207.75)"
                id="FacMou083__col1n"
            />
        </g></g>
    )
}

export default FacMou083
