import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF9AFF",
    "col2n": "#621F00"
}

function ObjFoo015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M28.85 12.25q0-5.05-3.8-8.65Q21.15 0 15.8 0q-5.4 0-9.2 3.6-3.85 3.6-3.85 8.65 0 4.1 2.55 7.3-1 .65-1.85 1.6Q0 24.8 0 30v.55q.15 4.85 3.45 8.3 1.45 1.55 3.2 2.5.25.15.55.25l1.2 1.3 20.3-4.4V14.1q.15-.9.15-1.85z"
                    id="ObjFoo015__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M20.5 19.25q2.55-3.7 1.5-9.65-.6-2.8-2.35-5.05-.4-.5-.8-.95Q15.4 0 10.55 0q-.75 0-1.5.1-3.9.45-6.75 3.5Q.85 5.1 0 6.85L.15 7q.1.05.2.15.9 1 1.55 2.1Q3.75 12.2 3.75 16q0 2.85-1.05 5.25 1.75 1.6 3.9 2.4.15 0 4.25-.35 4.05-.35 9.65-4.05z"
                    id="ObjFoo015__Symbol_8_0_Layer0_0_FILL"
                />
                <g id="ObjFoo015__Layer7_0_FILL">
                    <path
                        fill="#FC0"
                        d="M131.3 299.2q-2.732-.182-4.75-2.2-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-.724.275-1.45.45l3.7 6.6v-.05l3.95 7.3q2.3 4.35 2.45-.05l2.05-23.95m-11.35-26.55q-1.346-.244-1.85-.4l-3.25.85q-.532.405-1 .75-.547.448-.7.6-6.05 1.3-12.2 2.15l6.35 11.4q.53-.454 1.25-.8 4.45-1.85 10.2 0 2.25 1 3.95 3 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4 2.25-2.25 5.4-2.25h.6l1.1-12.7v-.35l.25-2.6v-.05q-.208.078-.45.15-6.698 2.504-13.1 4.25h-.4z"
                    />
                    <path
                        fill="#FFF"
                        fillOpacity={0.651}
                        d="M118.8 238.6q-3.9-3.6-9.25-3.6-5.4 0-9.2 3.6-3.85 3.6-3.85 8.65 0 4.1 2.55 7.3 2.8-2.05 6.4-2.05 4.65 0 7.95 3.35.85-1.75 2.3-3.25 2.85-3.05 6.75-3.5.15-.9.15-1.85 0-5.05-3.8-8.65z"
                    />
                </g>
                <g id="ObjFoo015__Layer7_1_FILL">
                    <path
                        fill="#CAA200"
                        d="M102.35 279.1l.9 1.7q16.55-1.2 30.2-7.75l.2-1.8q-15.35 6.8-31.3 7.85z"
                    />
                    <path
                        fill="#960"
                        d="M116.1 270.25l-.7 1.35q-.663 1.197-1.65 2.25-.2.15-.3.3l-.3.3q.25-.1.55-.15 2.8-.65 5.6-1.35.404-.121.85-.25.094-.021.2-.05h-.4q-2.092-.792-3.85-2.4z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo015__Symbol_52_0_Layer0_0_FILL"
                transform="translate(93.75 235)"
                id="ObjFoo015__col1n"
            />
            <use
                xlinkHref="#ObjFoo015__Symbol_8_0_Layer0_0_FILL"
                transform="translate(113.4 249)"
                id="ObjFoo015__col2n"
            />
            <use xlinkHref="#ObjFoo015__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo015__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo015
