import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function ShiMot020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M17.95 36.45h-3.3v4.45q.4.15.8.25 2.6.65 5.45.65.8 0 1.55-.05h.1q.75.05 1.55.05 2.85 0 5.45-.65.4-.1.8-.25v-4.45h-3.3v1.65q0 .15-.1.3-.15.1-.3.1-.2 0-.3-.1-.1-.15-.1-.3v-1.65h-3.3v1.65q-.05.15-.15.3-.1.1-.25.1h-.1q-.15 0-.25-.1-.1-.15-.15-.3v-1.65h-3.3v1.65q0 .15-.1.3-.1.1-.3.1-.15 0-.3-.1-.1-.15-.1-.3v-1.65M45 20.9q0-3.05-.8-5.8-1.4-5-5.35-8.95Q36 3.3 32.7 1.8q-10.6-3.55-20.35 0Q9 3.3 6.15 6.15 2.2 10.1.8 15.1 0 17.85 0 20.9q.05 3.2.85 6.1.85 2.85 2.55 5.35.15.2.25.4h11v2.9h3.3V33.2q0-.2.1-.3.15-.15.3-.15.2 0 .3.15.1.1.1.3v2.45h3.3V33.2q.05-.2.15-.3.1-.15.25-.15h.1q.15 0 .25.15.1.1.15.3v2.45h3.3V33.2q0-.2.1-.3.1-.15.3-.15.15 0 .3.15.1.1.1.3v2.45h3.3v-2.9h11q.1-.2.25-.4 1.7-2.5 2.55-5.35.8-2.9.85-6.1m-19.7-1.75q-.05-1.8 1.15-3.25 2.6-2.5 6.35 0 2 1.35 2.95 4.3.45 1.4.3 2.65-.05.75-.2 1.65-.15.6-.4 1.1-3.2 2.8-7.15 0-1.65-1.25-2.6-3.85-.5-1.4-.4-2.6m-16.35 3.7q-.15-1.25.3-2.65.95-2.95 2.95-4.3 3.75-2.35 6.35 0 1.2 1.45 1.15 3.25.1 1.2-.4 2.6-.95 2.6-2.55 3.85-4.55 2.7-7.2 0-.25-.5-.4-1.1-.15-.9-.2-1.65z"
                    id="ShiMot020__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot020__Symbol_26_0_Layer0_0_FILL"
                transform="translate(170.05 260.3)"
                id="ShiMot020__col1n"
            />
        </g></g>
    )
}

export default ShiMot020
