import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}

function ObjToo069({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M58.9 32.15Q53.65 16.85 43.75 0L0 26.05q.7.85 1.6 2.05l48.95 83.15v.05q1.35 2.85 1.9 4.1 2.25-2.65 4.15-5.6v-.1l.1.05q5.6-8.75 8.35-20.3 3.1-14.1.5-31.2Q63.7 46 58.9 32.15m-4.3 73.8v-.1l.05.05-.2.3.15-.25z"
                    id="ObjToo069__Symbol_99_0_Layer0_0_FILL"
                />
                <path
                    fill="#999"
                    d="M288.85 215.2q-.13-.276-.3-.55l.35.65-5.15-9.75-93.8 54.35 3.9 6.25h.05l.2.4q.927 1.491 1.85 2.95 13.885 21.818 27.95 36.1 31.671 30.157 69.75 24.9 21.746-28.255 14.35-66.8-2.39-10.359-6.8-21.8-1.013-2.607-2.15-5.3-.062-.183-.15-.4-.3-.71-.6-1.45-.367-.824-.75-1.7-.302-.66-.6-1.35l-8.1-16.5m6 30.35q4.45 12.85 6.15 24.25 2.326 15.83-.55 28.85-2.555 10.688-7.85 18.75l.05.05-.2.3.1-.3q-1.835 2.842-4.05 5.35l.05.05q-14.802 1.402-27.55-2.6l-6.6-2.6q-27.966-12.91-53.65-55.55l33.7-19.45h.05l46.35-27q9.15 15.65 14 29.9m-12.4 77.85v.05q-.9.05-1.7.05l1.7-.1z"
                    id="ObjToo069__Layer5_0_FILL"
                />
                <g
                    id="ObjToo069__Symbol_227_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M101.2 32.15Q95.95 16.85 86.05 0l-44.3 26.35v.05l-5.5 3.25h-.05L0 51.05q27.8 45.7 57.9 59.4l7.05 2.7q13.75 4.15 29.6 2.5v-.05l.2-.2q2.25-2.65 4.15-5.6v-.1l.1.05q5.6-8.75 8.35-20.3 3.1-14.1.5-31.2Q106 46 101.2 32.15m-4.45 74.05l.15-.25v-.1l.05.05-.2.3m-10 5.65v.05q-.9.05-1.7.05l1.7-.1z" />
                    <path d="M86.75 111.9v-.05l-1.7.1q.8 0 1.7-.05zM96.9 105.95l-.15.25.2-.3-.05-.05v.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo069__Symbol_227_0_Layer0_0_FILL"
                transform="translate(195.7 211.55)"
                id="ObjToo069__col1n"
            />
            <use
                xlinkHref="#ObjToo069__Symbol_99_0_Layer0_0_FILL"
                transform="translate(238 211.55)"
                id="ObjToo069__col2n"
            />
            <use xlinkHref="#ObjToo069__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToo069
