import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#434343",
    "col1n": "#252525",
    "col2n": "#000000"
}

function ObjMor038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#666"
                    d="M240.5 76.1q-3.482-20.506 1.4 33.6l20.35 156.45 5.2 9.15-21.55-166.15q-1.918-12.494-5.4-33.05M230.45-3.4q-1.6-1.1-2.9.4l.55 8.3q5.188 53.856 4-.55l-1.65-8.15z"
                    id="ObjMor038__Layer10_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M295.55 57.3q3.35-1.1 6.8-1.8-3.95-3.85-8-7.6-50.7-46.2-98.7-47Q143.9-.05 143.5 0h-.5v.25q0 .3-.1.6-.05.2-.2.4-.45.65-1.4.65-.75 0-1.2-.25-.3-.15-.45-.4-.05-.05-.05-.1l-.2-.8-.4.1q-.4.1-50.05 14.4Q39.1 29.2 0 95.35q24.85-1.7 44.75 15.8 38.75-7.5 68.85 5.55 19.3-7.5 38.45-10.55.5-.05.9-.15 1-.1 1.95-.25v.1q1.2-.15 2.5-.3.4-.05.9-.15 19.35-2.05 39.9.25 25.65-20.5 65.05-23.25 12.55-18.9 32.3-25.1z"
                    id="ObjMor038__Symbol_213_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M98 .6q.3.65 18.7 17.45 18.5 16.7 36.75 86.75 25.65-20.5 65.05-23.25-42.6-60.6-81.45-70.9Q100.5.9 98.15 0q-.05.2-.2.4.05.1.05.2m-2.65.3V.8q-.3-.15-.45-.4-2.75 1.9-34.55 20.35Q25.45 40.7 0 110.3q38.75-7.5 68.85 5.55-.55-72.35 13-93.3 13.4-21 13.5-21.65z"
                    id="ObjMor038__Symbol_214_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M29.8.65q-1.7.3-1.2 2.3l3.3 21.95v.1l.15.8q.05.5.05 1v.1q.15 4.2-2.45 7.75l.05-.05q-3.1 4.15-8.25 4.95-5.15.75-9.25-2.3-2.45-1.8-3.7-4.25-1.3-3.9-4.35-3.5l-1.35.2q-3.65.45-2.5 4.35 1.95 5.75 7.15 9.65 6.75 5 15.2 3.75 3.8-.6 7-2.3-.05-1.85-.05-2.2v-.05-1.2q0-1.6.3-2.75.05-1.15.55-2.4Q31.7 31.65 36 28q.3-.3.65-.6.5-.4 1.1-.75.85-.5 1.9-.85-.05-1.55-.05-2.4-.05-.9 0-.95l-3-20.2Q36.05-.1 34.55 0L29.8.65z"
                    id="ObjMor038__Symbol_215_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor038__Layer10_0_FILL" />
            <use
                xlinkHref="#ObjMor038__Symbol_213_0_Layer0_0_FILL"
                transform="translate(88.75 3.75)"
                id="ObjMor038__col1n"
            />
            <use
                xlinkHref="#ObjMor038__Symbol_214_0_Layer0_0_FILL"
                transform="translate(133.5 4.6)"
                id="ObjMor038__col1l"
            />
            <use
                xlinkHref="#ObjMor038__Symbol_215_0_Layer0_0_FILL"
                transform="translate(231.55 260)"
                id="ObjMor038__col2n"
            />
        </g></g>
    )
}

export default ObjMor038
