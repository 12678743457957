import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999"
}

function AccHat158({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fillOpacity={0.4}
                    d="M235.25 121.8q1.7.6 3.4 1.3 2.4.95 4.8 2 21.2 10.2 32.85 35.5.35.75.7 1.55.85 2 1.7 4.1.8 2.3 1.5 4.65.5 1.707.9 3.55l5.6 9.45-5.9-48.25-29.9-44.85H128.85L96.5 163.6l7.05 6.85q.078 2.085.25 4.15.475-1.905 1-3.7.7-2.35 1.5-4.65.45-1.2.95-2.3l.35-.75q.4-1 .85-1.95.1-.35.25-.65 11.65-25.3 32.85-35.5 2.4-1.05 4.8-2 1.7-.7 3.4-1.3 1.4-.5 2.75-1l5.25-1.7q34.8-10.15 69.6.05l5.15 1.65q1.35.5 2.75 1z"
                    id="AccHat158__Layer16_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M83.8 160.1q6.5 3.3 9.35 8.8 2.75-5.35 9-8.65-9 4.3-18.35-.15m24.55 9.85q.15-.1.3-.15 6.6-2.7 18.15-4.4 4.7-.7 9.55-1.45.25-.05.45-.1 1.65-.3 3.35-.55.8-.15 1.5-.25 9.85-2.15 20.05-7.9l.05-.05-1.6.7q-1.05.45-2.15.9-.9.35-1.85.7-3.9 1.35-8.3 2.45-.5.05-.95.15-13.55 1.85-20.1 2.8-11.55 1.7-18.15 4.4-5.5 2.25-9.3 5.7-.5.4-.9.85-.9.85-1.75 1.4-.25.15-.5.35-.3.1-.55.25-.35.15-.65.25 1.45-.4 2.95-1 1.45-.6 2.45-1.1.95-.55 2.05-1.15 1.1-.6 1.75-.9l1.9-.85q1.05-.6 2.25-1.05m-69.9-10.1q-5.65-1.4-10.5-3.3-.45-.2-.9-.35 9.9 5.4 23.2 7.8l1.25.2q1.85.3 3.65.55 2.15.35 4.35.65 10.8 1.6 17.3 4.05.15.05.25.1.3.15.6.25 1.4.55 2.7 1.25l3.75 1.7q3.864 2.427 7.5 3.35-.481-.116-.95-.35-.6-.3-1.2-.7-.65-.45-1.3-1.05l-.25-.25q-4.1-4.05-10.25-6.55-6.6-2.7-18.15-4.4-6.55-.95-20.1-2.8-.45-.1-.95-.15M6.95 65.2q-.8 2.3-1.5 4.65-1.2 4.1-1.95 8.95-.55 3.75-.85 7.95-.3 4.15-.35 8.8 0 1.45.05 3 .1 1.4.25 2.8 1.6 12.55 10.45 26.25 3.6 5.45 7.25 9.3 4.05 4.25 10 7.35Q36.25 147.3 45 150q1.65.5 3.35 1 .15 0 .3.05.65.2 1.35.4 2.9.75 6.05 1.4 3 .6 6.35 1.2-34.25-9.85-50.6-41.15-6.15-13.25-7.3-25.3-1.25-13.1 3.4-24.7-.5 1.1-.95 2.3M61.45.35q-.6-.2-1.2-.35-17.3-.5-36.95 11.6-6.6 4.65-12.65 10.75-2.9 2.95-6.95 7.45Q1.25 35.7 0 42.05q2-3.4 4.4-6.8 1.05-1.6 2.2-3.05.05-.1.15-.2 1.4-1.8 2.95-3.45.65-.65 1.3-1.25 1.35-1.3 2.7-2.45Q33.1 8.2 61.45.35m117.9 64.85q-.85-2.1-1.7-4.1 5 13.1 4.15 26.3-.8 11.45-5.95 22.95-15 33.5-55.2 44.4 1.45-.3 3.2-.6l.2-.05q.35-.1.65-.15 2.45-.5 5.55-1.1 5.9-1.25 11.05-2.85 8.75-2.7 14.7-5.75 5.95-3.1 10-7.35 2.85-3 5.65-6.95.8-1.15 1.6-2.35 7.25-11.2 9.65-21.65.5-2.35.8-4.6.15-1.4.25-2.8.05-1.55.05-3-.05-4.65-.35-8.8-.3-4.2-.85-7.95-.75-4.85-1.95-8.95-.7-2.35-1.5-4.65M173 19.75q-4.15-3.85-8.45-7.05l-2.85-2Q145.35.05 126.15 0q-.65.15-1.25.35 23.8 6.6 41.3 19.4 3.3 2.45 6.4 5.1 1.35 1.15 2.7 2.45.65.6 1.3 1.25 1.55 1.65 2.95 3.45.1.1.15.2 1.15 1.45 2.2 3.05 2.4 3.4 4.4 6.8-1.25-6.35-3.7-12.25-4.05-4.5-6.95-7.45-1.35-1.35-2.65-2.6z"
                    id="AccHat158__Symbol_426_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat158__Symbol_499_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path
                        fillOpacity={0.4}
                        d="M104.3 234h-.05q.045.181.05.35V234m-1.35 0h-.05v.35q.005-.169.05-.35z"
                    />
                    <path d="M176.9 47.4q-.7-.9-1.35-1.8l-.3-.4q-7.45-9.85-16.45-17.6-5.1-5.2-10.65-9.6-2-1.55-4-3-2.8-2-5.7-3.8-5.05-3.15-10.5-5.75-11-5.2-23.65-5.45h-1.4Q90.25.25 79.25 5.45q-5.45 2.6-10.5 5.75-2.9 1.8-5.65 3.8-2.05 1.45-4 3-5.6 4.4-10.7 9.6-9 7.75-16.45 17.6l-.3.4-1.3 1.8q-7.5 10.4-13.35 23l-2.85 6.45q-2.45 5.9-3.7 12.25-2.6 4.55-4.4 9.15-1 3.35-2.25 7.85-4 14.6-3.8 29.3.15 15 4.75 30.2 7.4 20.45 22.75 32.35 3.55 2.4 7.8 4.3.55.25 1.15.55.1 0 .2.05.3.15.65.3l.2.1q9.9 5.4 23.2 7.8l1.25.2q1.85.3 3.65.55 2.15.35 4.35.65 10.8 1.6 17.3 4.05.15.05.25.1.3.15.6.25 1.4.55 2.7 1.25l3.75 1.7q3.9 2.45 7.6 3.4 1.05.25 2.05.15.65-.1 1.25-.3 1.45-.4 2.95-1 1.45-.6 2.45-1.1.95-.55 2.05-1.15 1.1-.6 1.75-.9l1.9-.85q1.05-.6 2.25-1.05.15-.1.3-.15 6.6-2.7 18.15-4.4 4.7-.7 9.55-1.45.25-.05.45-.1 1.65-.3 3.35-.55.8-.15 1.5-.25 9.85-2.15 20.05-7.9l.05-.05.2-.1q3.95-1.85 7.3-4.1 10.8-8.35 17.65-20.95 2.9-5.3 5.1-11.4 4.6-15.15 4.8-30.2.15-14.7-3.85-29.3-1.25-4.5-2.25-7.85-1.8-4.6-4.4-9.15-1.25-6.35-3.7-12.25l-2.85-6.45-1.7-3.6q-.8-1.6-1.6-3.15-4.6-8.7-10-16.25m11.2 60.75q5 13.1 4.15 26.3-.8 11.45-5.95 22.95-15 33.5-55.2 44.4-1.55.3-2.75.55-6.8 1.45-10.85 2.85-2.1.7-3.9 1.6l-1 .5q-9 4.3-18.35-.15-.3-.2-.65-.35-1.8-.9-3.9-1.6-4.05-1.4-10.85-2.85-2.1-.4-5.5-1.1l-.6-.1H73q-.103-.003-.15-.05-34.25-9.85-50.6-41.15-6.15-13.25-7.3-25.3-1.25-13.1 3.4-24.7l.35-.75q.4-1 .85-1.95.1-.35.25-.65 11.65-25.3 32.85-35.5 2.4-1.05 4.8-2 1.7-.7 3.4-1.3 1.4-.5 2.75-1l5.25-1.7q34.8-10.15 69.6.05l5.15 1.65q1.35.5 2.75 1 1.7.6 3.4 1.3 2.4.95 4.8 2 21.2 10.2 32.85 35.5.35.75.7 1.55z" />
                </g>
            </defs>
            <use xlinkHref="#AccHat158__Layer16_0_FILL" />
            <use
                xlinkHref="#AccHat158__Symbol_499_0_Layer0_0_FILL"
                transform="translate(88.9 40)"
                id="AccHat158__col1n"
            />
            <use
                xlinkHref="#AccHat158__Symbol_426_0_Layer0_0_FILL"
                transform="translate(99.35 87.05)"
                id="AccHat158__col1d"
            />
        </g></g>
    )
}

export default AccHat158
