import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#830000"
}

function AccGla027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    fillOpacity={0.902}
                    d="M45.4 20.1q-1.25-2.3-3.45-4.1-2.75-2.2-6.85-3.4-8.45-2.3-17.85 1.25Q7.9 17.4 3.1 24.75q-2 3.1-2.7 6.1-.8 3.25-.05 6.35 2.95 5.5 10.35 7.5 8.5 2.45 17.85-1.1 9.4-3.55 14.15-11.05 4.15-6.35 2.7-12.45M99.9 26q6.05-4.7 6.65-10.9-.45-2.65-1.9-4.95-1.85-3-5.4-5.45-3.35-2.3-7.3-3.5Q87.4-.15 82 0q-4.25.15-7.95 1.2-5 1.45-9 4.6Q62.1 8 60.5 10.6q-1.8 2.85-2.1 6 .55 3.25 2.55 6 1.8 2.45 4.75 4.5 7.3 5 17.3 4.7 10.05-.3 16.9-5.8z"
                    id="AccGla027__Symbol_115_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M59.85 23.1q-3.65-7-12.55-9.8-10.15-3.1-21.15 1.1-11.05 4.2-16.6 13.2-1.4 2.35-2.2 4.65L.8 42.3q-2.1 5.45 1.15 10.55.65 1 1.5 2 3.4 3.95 10.15 7.7l11.1-1.6q8.9 1.9 18.45-1.75 11-4.2 16.5-13.3 3.7-6.1 3.5-12l-1.05-3.45q-.35-4.05-2.25-7.35m-12.9-2.4q4.1 1.2 6.85 3.4 2.2 1.8 3.45 4.1 1.45 6.1-2.7 12.45-4.75 7.5-14.15 11.05-9.35 3.55-17.85 1.1-7.4-2-10.35-7.5-.75-3.1.05-6.35.7-3 2.7-6.1 4.8-7.35 14.15-10.9 9.4-3.55 17.85-1.25m82.65 7.15q.75-4.25-.4-9.25l-10.35-7.95q-.55-.7-1.1-1.35-1.7-1.8-3.9-3.45-8.6-6.2-20.4-5.85-11.8.35-19.95 7.15-1.25 1.05-2.3 2.15-5.35 5.6-5.6 12.7h-.05q-.3 3.75 1.15 7.45l.65 4.15q2.15 4.7 7.2 8.35 8.6 6.3 20.4 5.95 10.9-.3 18.7-6.2l8.2-1.85q5.7-4.25 7.35-10.15.25-.9.4-1.85M103.8 9.3q3.95 1.2 7.3 3.5 3.55 2.45 5.4 5.45 1.45 2.3 1.9 4.95-.6 6.2-6.65 10.9-6.85 5.5-16.9 5.8-10 .3-17.3-4.7-2.95-2.05-4.75-4.5-2-2.75-2.55-6 .3-3.15 2.1-6 1.6-2.6 4.55-4.8 4-3.15 9-4.6 3.7-1.05 7.95-1.2 5.4-.15 9.95 1.2z"
                    id="AccGla027__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M62 27.55q-.05.4-.05.8-.05.7-.15 1.45-.6 4.35-3.25 8.8Q53 47.75 42 51.95q-11.05 4.2-21.2.95Q11.5 50 7.95 42.5q-.3-.7-.55-1.4-2.45-6.5-.05-13.2L.8 37.95Q-1.3 43.4 1.95 48.5q.65 1 1.5 2 3.4 3.95 10.15 7.7l11.1-1.6q8.9 1.9 18.45-1.75 11-4.2 16.5-13.3 3.7-6.1 3.5-12L62.1 26.1l-.15.05q.05.7.05 1.4m-4.75-3.7l-.6-1.7q-2.6-6.85-11-9.3-8.45-2.35-17.85 1.2Q18.45 17.6 13.65 25q-4.8 7.45-2.2 14.3.3.85.75 1.65-.75-3.1.05-6.35.7-3 2.7-6.1 4.8-7.35 14.15-10.9 9.4-3.55 17.85-1.25 4.1 1.2 6.85 3.4 2.2 1.8 3.45 4.1m61.15-6.8q-.25-7.15-6.95-12.1-.25-.15-.45-.3Q103.8-.3 93.75 0 84.5.3 77.9 4.95l-1.1.8q-6.95 5.45-6.7 12.8 0 .9.15 1.8.3-3.15 2.1-6 1.6-2.6 4.55-4.8 4-3.15 9-4.6 3.7-1.05 7.95-1.2 5.4-.15 9.95 1.2 3.95 1.2 7.3 3.5 3.55 2.45 5.4 5.45 1.45 2.3 1.9 4.95v-1.8m11.2 6.45q.75-4.25-.4-9.25L118.85 6.3q3.7 4.8 3.9 10.85v1.55q-.45 8.3-7.9 14.5-8.2 6.9-20 7.25-11.8.35-20.35-5.95-3.7-2.7-5.85-5.95-.45-.65-.8-1.3l-.4-.8q-.35-.65-.6-1.35h-.15l.65 4.15q2.15 4.7 7.2 8.35 8.6 6.3 20.4 5.95 10.9-.3 18.7-6.2l8.2-1.85q5.7-4.25 7.35-10.15.25-.9.4-1.85z"
                    id="AccGla027__Symbol_19_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M8.65 23.7q-4 2-6.5 6.55-2.35 3.9-2.15 7.8 4.75-2.95 9.8-3.8-3.25-5.1-1.15-10.55m65.9-12.85Q73.1 7.15 73.4 3.4l-5.7 1.1q1.9 3.3 2.25 7.35l.65 2.1 4.35-.8-.4-2.3m98.85-4.7q-3-1.95-6.75-2.85Q152 .1 137.05 0q1.15 5 .4 9.25 20.15.2 40.3 4.3-.2-3.7-4.35-7.4z"
                    id="AccGla027__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla027__Symbol_115_0_Layer0_0_FILL"
                transform="translate(118.65 83.6)"
                id="AccGla027__col2n"
            />
            <use
                xlinkHref="#AccGla027__Symbol_50_0_Layer0_0_FILL"
                transform="translate(106.8 75.5)"
                id="AccGla027__col1n"
            />
            <use
                xlinkHref="#AccGla027__Symbol_19_0_Layer0_0_FILL"
                transform="translate(106.8 79.85)"
                id="AccGla027__col1d"
            />
            <use
                xlinkHref="#AccGla027__Symbol_10_0_Layer0_0_FILL"
                transform="translate(98.95 94.1)"
                id="AccGla027__col1s"
            />
        </g></g>
    )
}

export default AccGla027
