import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiHig043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M177.5 44.1v-.4q-.15-7.5-5.45-12.95-3.35-3.35-7.6-4.65-2.65-.85-5.6-.85-.4 0-.85.05 1.1-2.7 1.1-5.75 0-7.65-6.75-13.05-6.8-5.4-16.4-5.4-1.45 0-2.85.15Q121.05 1.1 99.3.5q-6.4-.2-54.9.75-1.4-.15-2.85-.15-9.6 0-16.4 5.4-6.75 5.4-6.75 13.05 0 3.05 1.1 5.75-.45-.05-.85-.05-2.95 0-5.6.85-4.25 1.3-7.6 4.65Q.15 36.2 0 43.7v.4q0 6.5 3.8 11.4.75 1 1.65 1.9 5.5 5.55 13.2 5.55 3.4 0 6.35-1.15 2.7-.75 4.55-2.5 1.15-.85 2.15-1.9 1.1-1.1 2-2.3 1.85-2.5 2.75-5.4.65.3 1.3.55 1.55.6 3.25 1 2.6.55 5.5.55 6.55 0 11.55-2.85 1.15-.75 2.3-1.55 1.5-1.15 2.6-2.45 2-2.35 2.75-5.1l.05.05v-.2q-.15-.1 4.4-2.3 3.7-1.9 20.65-8.1l22.15 9.3q-.2.25-1.15 1.25.75 2.75 2.75 5.1 1.1 1.3 2.6 2.45 1.15.8 2.3 1.55 5 2.85 11.55 2.85 2.9 0 5.5-.55 1.7-.4 3.25-1 .65-.25 1.3-.55.9 2.9 2.75 5.4.9 1.2 2 2.3 1 1.05 2.15 1.9 1.85 1.75 4.55 2.5 2.95 1.15 6.35 1.15 7.7 0 13.2-5.55.9-.9 1.65-1.9 3.8-4.9 3.8-11.4z"
                    id="HaiHig043__Symbol_149_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.25 7Q1.9 8.1.9 10.45T0 14.5q.05 1.65.25 3.65.2 1.95 1.15 3.35.9 1.35 2.3 3.15 1.85-2.5 2.75-5.4.65.3 1.3.55-2.15-2-3.2-3.1-.6-.7-.85-1.1-2-3.2-1.45-8.6m33.5 2.25q-.15-.05-.85-.8-.75-.75-1.95-2.15T31.35 3q-.4-1.95-.45-2.25-.7 1.5-.85 2.7-.05.4-.05 2.3 0 2 .5 3.85.4 1.45 1.1 2.8.35.65 1.35 2.1 2-2.35 2.75-5.1l.05.05v-.2m51.75-3.5q0-1.9-.05-2.3-.15-1.2-.85-2.7 0 .45-.6 2.7-.6 2.2-2.65 4.4l-.4.3q-.2.25-1.15 1.25.75 2.75 2.75 5.1 1-1.45 1.35-2.1.7-1.35 1.1-2.8.5-1.85.5-3.85m29.1 4.7q-1-2.35-1.35-3.45.55 5.4-1.45 8.6-.25.4-.85 1.1-1.05 1.1-3.2 3.1.65-.25 1.3-.55.9 2.9 2.75 5.4 1.4-1.8 2.3-3.15.95-1.4 1.15-3.35.2-2 .25-3.65.1-1.7-.9-4.05z"
                    id="HaiHig043__Symbol_148_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M6.2 17.5q-3.5 1.3-5 4.15Q.1 23.7 0 26.8q.4 1.55 1.45 2.7.7.75 1.65 1.3 1.05.6 2.3.9 1.05.2 1.05.05H6q.304-.006.45-.25v.25h1.5q-2.9-1.7-3.55-2.25-.65-.65-.75-1.8-.15-1.15-.05-2.55.05-1.4.95-3.75.9-2.4 1.65-3.9M31.8 4.6q-.1-1.65-.2-2.15Q31.25 1.1 29.95.5 28.85 0 26.8 0q-.55 0-1.15.15 1.2.85 2.2 2.55.6 1.05 1 2.2.6 1.75.6 3.7 0 1.15-.45 2.6l1.3-2.25q1.55-2.7 1.5-4.35m95.25.3q.4-1.15 1-2.2 1-1.7 2.2-2.55-.6-.15-1.15-.15-2.05 0-3.15.5-1.3.6-1.65 1.95-.1.5-.1 1.05 0 1.8.8 4.35.65 2.15 1.9 3.35-.45-1.45-.45-2.6 0-1.95.6-3.7m24 16.2q.55 2.1.8 3.1.2 1 .15 2.8-.05 1.8-.6 2.45-.55.6-3.45 2.3h1.5v-.25q.146.244.4.25h-.4q0 .15 1.05-.05 1.25-.3 2.3-.9.95-.55 1.65-1.3 1.05-1.15 1.45-2.7-.1-3.1-1.2-5.15-1.5-2.85-5-4.15.75 1.5 1.35 3.6z"
                    id="HaiHig043__Symbol_147_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M88.8 19.85q-.2-5.1-4.9-8.8-2.6-2-5.75-2.95-2.9-.9-6.3-.9-5.25 0-9.35 2.15-.7.35-1.4.8l-.3-.6Q59.6 7.3 57.65 5.3 52.35 0 44.95 0q-.3 0-.55.05-.25-.05-.55-.05-7.4 0-12.7 5.3-.6.6-1.1 1.2-1.45 1.7-2.35 3.65-.7-.45-1.4-.8-4.1-2.15-9.35-2.15-3.4 0-6.3.9-3.15.95-5.75 2.95-4.7 3.7-4.9 8.8.45 7.85 5 12.45 5.6 5.75 13.6 6.2-2.2 4.25-2.2 10.85 0 1.1.15 2.25.05.3.45 2.25.4 1.9 1.6 3.3 1.2 1.4 1.95 2.15.7.75.85.8v.2q.9.5 1.85.95 3.45 1.55 7.65 1.55 6.6 0 11.25-3.8.25-.2.45-.4.6-.5 1.15-1l.6-.7.05.05.05-.05.6.7q.55.5 1.15 1 .2.2.45.4 4.65 3.8 11.25 3.8 4.2 0 7.65-1.55.95-.45 1.85-.95l.05-.05q.95-1 1.15-1.25l.4-.3q2.05-2.2 2.65-4.4.6-2.25.6-2.7.15-1.15.15-2.25 0-6.6-2.2-10.75 8-.55 13.6-6.3 4.55-4.6 5-12.45z"
                    id="HaiHig043__Symbol_146_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M12.75 34.15q.8.1 1.55.25 1.7.4 3.5 1.4 1 .5 1.8 1.1.6.8 1.1 1.8 1.3 2.4 1.55 4.75.05.15.05.25.05-.05.1-.05.1-.35.45-1.05.3-.75.4-1.2.05-.45.2-1.3.1-.9-.1-1.65Q22 35.9 21.4 35.1q-.6-.6-3.1-1.8-2.5-1.3-5.55.85M4.25 8q-1.75.55-3 2.3Q0 12.1 0 15.3q0 2.05.65 3.3.3.7 1.55 1.9 1.2 1.2 4.45 1.3 3.25.05 3.8-1.75-3.95.3-5.35-1.3-1.45-1.6-1.65-2.95-.2-1.35-.2-3.15 0-1.65.6-3.8.2-.75.4-.85M31.5 2.35q1 .15 2.05.45 1.95.6 3.45 1.55.65.4 1.25.9-.6-3.6-3.15-4.5-3.5-1.45-7.2 0-2.55.9-3.15 4.5.6-.5 1.25-.9 1.5-.95 3.45-1.55 1.05-.3 2.05-.45M43.4 36.9q.8-.6 1.8-1.1 1.8-1 3.5-1.4.75-.15 1.55-.25Q47.2 32 44.7 33.3q-2.5 1.2-3.1 1.8-.5.65-1.45 2.4-.15.55-.3 1.05t-.1 1.1v.9q.05.35.1.8 0 1 .1 1.3l.3.6q.05.1.15.2.1.15.3.25 0-.1.05-.25.25-2.35 1.55-4.75.5-1 1.1-1.8m17.55-16.45q1.1-1.15 1.4-1.85.65-1.25.65-3.3 0-3.2-.95-5.1-1-1.9-3.9-2.9.75.65 1 1.55.6 2.15.65 3.9 0 1.7-.2 3.15-.25 1.45-1.6 2.95-1.4 1.5-4.75 1.15.75.8 3.65 1.2 2.9.4 4.05-.75z"
                    id="HaiHig043__Symbol_145_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig043__Symbol_149_0_Layer0_0_FILL"
                transform="translate(103 79.55)"
                id="HaiHig043__col1n"
            />
            <use
                xlinkHref="#HaiHig043__Symbol_148_0_Layer0_0_FILL"
                transform="translate(133 110)"
                id="HaiHig043__col1d"
            />
            <use
                xlinkHref="#HaiHig043__Symbol_147_0_Layer0_0_FILL"
                transform="translate(113.8 98)"
                id="HaiHig043__col1l"
            />
            <use
                xlinkHref="#HaiHig043__Symbol_146_0_Layer0_0_FILL"
                transform="translate(147.35 59.15)"
                id="HaiHig043__col2n"
            />
            <use
                xlinkHref="#HaiHig043__Symbol_145_0_Layer0_0_FILL"
                transform="translate(160.25 67.95)"
                id="HaiHig043__col2l"
            />
        </g></g>
    )
}
export default HaiHig043
