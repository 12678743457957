import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2n": "#FFFFFF"
}

function ShiSpe067({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.95 42.85L63.55.05V0h-.1q-1.1.4-2.25.75V.8q-1.2.35-2.4.75-9.2 2.55-19.1 3.3l-5.85.2H31.7l-7.9-.25Q13 4 3.2 1 2.75.85 2.35.75 1.2.4.1 0H0l18.7 43.15 3.3 28.9h.25q.85.05 1.7.05h.3q1.1.05 2.25.05h2.7q.2 0 .4.05.1-.05.15-.05h2.7q1.9 0 3.8-.05h3.65q.7 0 1.4-.05h.25l3.4-29.2z"
                    id="ShiSpe067__Symbol_315_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.35.75Q1.2.4.1 0H0l18.7 43.15 3.3 28.9h.25q.85.05 1.7.05V43.05L2.35.75m42.6 42.1L63.55.05V0h-.1q-1.1.4-2.25.75l-21.6 42.3V72.1h.3q.7 0 1.4-.05h.25l3.4-29.2z"
                    id="ShiSpe067__Symbol_313_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.5 0l.05.05h-.05Q21 2.1 19.6 4.25l-.7 1q-5.2 8-9.25 17.2-1.25 2.9-2.4 5.95-2.2 5.7-3.95 11.85l-.05.05q-.2.65-.35 1.35-.1.2-.1.4-.1.3-.15.6-.25.8-.4 1.6-1.4 5.6-2.15 11.5-.05.3-.1.65 4-3.6 10-3.7h1.25q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15 2.85 1.05 5.5 3.25l.3.3V57q.05.05.15.1l.25.25.05.05-4.95 20.4v13.4q7 .75 13.9 1.2 15.7 1 31.2-.35 5.4-.45 10.75-1.15v-32l-22-43.05Q39.6 12 29.65 5.25l-.9-.6L22.55 0h-.05m140.25 44.3q-.2-.8-.4-1.65l-.1-.4q-.1-.25-.15-.55-.15-.7-.35-1.35l-.05-.05q-1.75-6.2-3.95-11.95-1.15-3-2.4-5.85-4.05-9.2-9.25-17.2l-.7-1Q144 2.15 142.45.1l.05-.05h-.05l-6.2 4.65q-1.15.8-2.3 1.55-.5.3-1 .65-8.85 5.6-18.65 9l-22 43v32.3q10.65 1.15 21.25 1.4 1.25 0 2.5.05 6.95.05 13.95-.25.15-.05.25 0 8.65-.45 17.3-1.45.05-.05.55-.25V77.8l-4.95-20.35.05-.05q.2-.2.4-.35V57q2.75-2.45 5.8-3.55.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05H155q6 .1 10 3.7-.05-.35-.1-.65-.65-5-1.7-9.75-.25-.9-.45-1.75z"
                    id="ShiSpe067__Symbol_316_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.6}
                    d="M223.3 307.8q-.1.1-.1.25v3.75q0 .15.05.25.1.1.25.2h.25l23-5.75q.15-.05.25-.15.1-.15.1-.3v-3.75q0-.2-.15-.3-.1-.15-.3-.15-.2 0-.35.15l-22.75 5.65q-.15 0-.25.15m.8.6h.05l22.05-5.5v2.85l-22.05 5.5h-.05v-2.85m-8.65-26.85q0 .2.15.35.1.1.3.1h26.3q.2 0 .35-.1.1-.15.1-.35 0-.2-.1-.3-.15-.15-.35-.15h-26.3q-.2 0-.3.15-.15.1-.15.3m32.2-17.45q-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15m-1.6-30.9l-.9.6.15 11.4-12.9 5.35q-.15.05-.25.25-.1.15-.05.3.05.2.2.3l5.45 4.4-35.45 31.7v1.3l36.55-32.65q.15-.15.15-.25.05-.15 0-.3t-.15-.25l-5.3-4.2 12.45-5.2q.15-.05.2-.15.1-.15.1-.3l-.15-12.35-.1.05m-84.3 74.6q-.1-.15-.25-.15L138.75 302q-.15-.15-.35-.15-.2 0-.3.15-.15.1-.15.3v3.75q0 .15.1.3.1.1.25.15l23 5.75h.25q.15-.1.25-.2.05-.1.05-.25v-3.75q0-.15-.1-.25m-1 .55l.15.05h.05v2.85h-.05l-.1-.05-21.95-5.45v-2.85l21.9 5.45m-23.35-43.9q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05m2.45-30.65l-.9-.6-.1-.05-.15 12.35q0 .15.1.3.05.1.2.15l12.45 5.2-5.3 4.2q-.1.1-.15.25-.05.15 0 .3 0 .1.15.25l36.6 32.7v-1.35l-35.5-31.7 5.45-4.4q.15-.1.2-.3.05-.15-.05-.3-.1-.2-.25-.25l-12.9-5.35.15-11.4z"
                    id="ShiSpe067__Layer2_0_FILL"
                />
                <path
                    fill="red"
                    d="M154.25 270.35h-3.7v5h-5v3.75h5v5.5h3.7v-5.5h5v-3.75h-5v-5z"
                    id="ShiSpe067__Layer2_0_MEMBER_1_FILL"
                />
                <g id="ShiSpe067__Layer2_0_MEMBER_0_FILL">
                    <path
                        fill="#CCC"
                        d="M218.55 278.85v10.4h2.75v-10.4h-2.75m10.35 0H226.05v10.4h2.85v-10.4m11.85.45h-3v6.1h3v-6.1m5.85 14.7v-7.4h-14.7v7.4h14.7m-2.9-3.85v-.1q-1.2 1.15-1.6 1.5-.2 0-.2-.05.1-.35.3-.6v-1.95l-.25-.25q-1.45 1-.5 2.05l-.15.15q-.45-.85-.45-.9 0-.5.1-.9.25-.75.8-.75.9 0 .9.85 0 .5-.15 1.4.35-.15 1.25-1.05.05 0 .25.8.4-.25 1.05-1.25.15.45.15 1.3 0 .8-.1 1.15h-.2v-1.4h-.1q-.55.9-.8 1.05-.05 0-.3-1.05m-5.1-1.05l.6.05.05.1v2.6l-.15.1q-.15-.75-.15-2.4v-.1q-1.35.25-2.15 1.45-.25-1.5-.4-2.05-1.4 1.5-1.65 2.05h-.35v-2.05q-.85 2.05-.75 2.25l-.25.1-.05-.6q0-.65 1.05-2.7h.3v2.25q1.6-1.6 1.7-1.95h.4v1.95q.5-.15 1.8-1.05m-1.2 1.7h.2q.1.1.1.35 0 1.1-2.4 1.1-2.15 0-2.35-.3v-.15l2.35.05q1.7 0 2-.3.2-.2.1-.75z"
                    />
                    <path
                        fill="#0C5235"
                        d="M220.75 270.55q-.35-.5-.8-.6-.1-.05-.25-.05t-.25.05q-.2-.05-.35-.05-1.05 0-1.6 2.25-.15.65-.3 1.55v7.4h1.35v-2.25h2.85v-6.4q-.25-1.25-.65-1.9z"
                    />
                    <path
                        fill="#3351C4"
                        d="M226.55 269.9h-.1q-1.3.1-1.8 3.85v7.35h1.4v-2.25h2.8v-6.4q-.55-2.55-1.7-2.55h-.1q-.1 0-.15.05l-.1-.05h-.25z"
                    />
                    <path
                        fill="#484848"
                        d="M248 295.25v-9.85h-17.45v9.85H248m-1.4-8.65v7.4h-14.7v-7.4h14.7z"
                    />
                    <path
                        fill="#253A8D"
                        d="M237.6 290.8h-.2q.1.55-.1.75-.3.3-2 .3l-2.35-.05v.15q.2.3 2.35.3 2.4 0 2.4-1.1 0-.25-.1-.35m1.6-1.65l-.6-.05q-1.3.9-1.8 1.05v-1.95h-.4q-.1.35-1.7 1.95v-2.25h-.3q-1.05 2.05-1.05 2.7l.05.6.25-.1q-.1-.2.75-2.25v2.05h.35q.25-.55 1.65-2.05.15.55.4 2.05.8-1.2 2.15-1.45v.1q0 1.65.15 2.4l.15-.1v-2.6l-.05-.1m4.5.9v.1q.25 1.05.3 1.05.25-.15.8-1.05h.1v1.4h.2q.1-.35.1-1.15 0-.85-.15-1.3-.65 1-1.05 1.25-.2-.8-.25-.8-.9.9-1.25 1.05.15-.9.15-1.4 0-.85-.9-.85-.55 0-.8.75-.1.4-.1.9 0 .05.45.9l.15-.15q-.95-1.05.5-2.05l.25.25v1.95q-.2.25-.3.6 0 .05.2.05.4-.35 1.6-1.5z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe067__Symbol_315_0_Layer0_0_FILL"
                transform="translate(160.75 244.4)"
                id="ShiSpe067__col1n"
            />
            <use
                xlinkHref="#ShiSpe067__Symbol_313_0_Layer0_0_FILL"
                transform="translate(160.75 244.4)"
                id="ShiSpe067__col1d"
            />
            <use
                xlinkHref="#ShiSpe067__Symbol_316_0_Layer0_0_FILL"
                transform="translate(110 228.55)"
                id="ShiSpe067__col2n"
            />
            <use xlinkHref="#ShiSpe067__Layer2_0_FILL" />
            <use xlinkHref="#ShiSpe067__Layer2_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiSpe067__Layer2_0_MEMBER_1_FILL" />
        </g></g>
    )
}

export default ShiSpe067
