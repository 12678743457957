import { AssetSvgProps } from "../../../shared/assets"

function SkiMor017({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <radialGradient
                    id="SkiMor017__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.338}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(227.5 233)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={7}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(241.85 206.1)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_3"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.338}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(142.5 223.8)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_4"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={11.2}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(136.3 206.45)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_5"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.338}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(130.25 176.5)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_6"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.275}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(112.1 191.75)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_7"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.338}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(120 105)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_8"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={8.95}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(139.8 116.75)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_9"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={6.275}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(148.85 92.8)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_10"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={9.363}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(263.75 197.05)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_11"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.337}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(261.85 175.9)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_12"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={8.675}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(255.3 106.3)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_13"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.338}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(203.5 196.3)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_14"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={9.1}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(193.2 110)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="SkiMor017__Gradient_15"
                    gradientUnits="userSpaceOnUse"
                    cx={0}
                    cy={0}
                    r={5.338}
                    fx={0}
                    fy={0}
                    gradientTransform="translate(209.35 122.15)"
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#F77" />
                    <stop
                        offset="63.529%"
                        stopColor="#FF9494"
                        stopOpacity={0.145}
                    />
                    <stop offset="100%" stopColor="#F99" stopOpacity={0} />
                </radialGradient>
                <g id="SkiMor017__Layer3_0_FILL">
                    <path
                        fill="url(#SkiMor017__Gradient_1)"
                        d="M224.1 236.35q1.4 1.4 3.4 1.4 1.95 0 3.35-1.4 1.4-1.35 1.4-3.35t-1.4-3.4q-1.4-1.35-3.35-1.35-2 0-3.4 1.35-1.35 1.4-1.35 3.4t1.35 3.35z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_2)"
                        d="M246.25 210.5q1.85-1.8 1.85-4.4 0-2.6-1.85-4.45-1.8-1.8-4.4-1.8-2.6 0-4.45 1.8-1.8 1.85-1.8 4.45t1.8 4.4q1.85 1.85 4.45 1.85t4.4-1.85z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_3)"
                        d="M145.85 220.4q-1.4-1.35-3.35-1.35-2 0-3.4 1.35-1.35 1.4-1.35 3.4t1.35 3.35q1.4 1.4 3.4 1.4 1.95 0 3.35-1.4 1.4-1.35 1.4-3.35t-1.4-3.4z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_4)"
                        d="M143.35 213.5q2.95-2.9 2.95-7.05t-2.95-7.1q-2.9-2.9-7.05-2.9t-7.1 2.9q-2.9 2.95-2.9 7.1 0 4.15 2.9 7.05 2.95 2.95 7.1 2.95 4.15 0 7.05-2.95z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_5)"
                        d="M133.6 173.1q-1.4-1.35-3.35-1.35-2 0-3.4 1.35-1.35 1.4-1.35 3.4t1.35 3.35q1.4 1.4 3.4 1.4 1.95 0 3.35-1.4 1.4-1.35 1.4-3.35t-1.4-3.4z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_6)"
                        d="M112.1 196.45q2 0 3.35-1.35 1.4-1.4 1.4-3.35 0-1.95-1.4-3.35-1.35-1.35-3.35-1.35-1.95 0-3.35 1.35-1.35 1.4-1.35 3.35 0 1.95 1.35 3.35 1.4 1.35 3.35 1.35z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_7)"
                        d="M123.35 108.35q1.4-1.35 1.4-3.35t-1.4-3.4q-1.4-1.35-3.35-1.35-2 0-3.4 1.35-1.35 1.4-1.35 3.4t1.35 3.35q1.4 1.4 3.4 1.4 1.95 0 3.35-1.4z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_8)"
                        d="M147.8 116.75q0-3.35-2.4-5.65-2.3-2.35-5.6-2.35t-5.7 2.35q-2.3 2.3-2.3 5.65 0 3.35 2.3 5.65 2.4 2.35 5.7 2.35 3.3 0 5.6-2.35 2.4-2.3 2.4-5.65z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_9)"
                        d="M143.25 92.8q0 2.3 1.65 3.95 1.65 1.65 3.95 1.65 2.35 0 3.95-1.65 1.65-1.65 1.65-3.95 0-2.35-1.65-4-1.6-1.6-3.95-1.6-2.3 0-3.95 1.6-1.65 1.65-1.65 4z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_10)"
                        d="M257.8 191.1q-2.4 2.45-2.4 5.95 0 3.45 2.4 5.85 2.5 2.5 5.95 2.5 3.5 0 5.9-2.5 2.5-2.4 2.5-5.85 0-3.5-2.5-5.95-2.4-2.45-5.9-2.45-3.45 0-5.95 2.45z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_11)"
                        d="M261.85 171.15q-2 0-3.4 1.35-1.35 1.4-1.35 3.4t1.35 3.35q1.4 1.4 3.4 1.4 1.95 0 3.35-1.4 1.4-1.35 1.4-3.35t-1.4-3.4q-1.4-1.35-3.35-1.35z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_12)"
                        d="M247.55 106.3q0 3.25 2.2 5.45 2.3 2.3 5.55 2.3 3.2 0 5.4-2.3 2.3-2.2 2.3-5.45 0-3.2-2.3-5.5-2.2-2.25-5.4-2.25-3.25 0-5.55 2.25-2.2 2.3-2.2 5.5z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_13)"
                        d="M200.1 192.9q-1.35 1.4-1.35 3.4t1.35 3.35q1.4 1.4 3.4 1.4 1.95 0 3.35-1.4 1.4-1.35 1.4-3.35t-1.4-3.4q-1.4-1.35-3.35-1.35-2 0-3.4 1.35z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_14)"
                        d="M187.4 104.25q-2.35 2.4-2.35 5.75 0 3.4 2.35 5.75 2.4 2.4 5.8 2.4 3.35 0 5.7-2.4 2.4-2.35 2.4-5.75 0-3.35-2.4-5.75-2.35-2.35-5.7-2.35-3.4 0-5.8 2.35z"
                    />
                    <path
                        fill="url(#SkiMor017__Gradient_15)"
                        d="M204.6 122.15q0 2 1.35 3.35 1.4 1.4 3.4 1.4 1.95 0 3.35-1.4 1.4-1.35 1.4-3.35t-1.4-3.4q-1.4-1.35-3.35-1.35-2 0-3.4 1.35-1.35 1.4-1.35 3.4z"
                    />
                </g>
            </defs>
            <use xlinkHref="#SkiMor017__Layer3_0_FILL" />
        </g></g>
    )
}

export default SkiMor017
