import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#77BFFF",
    "col1n": "#43A6FF",
    "col2d": "#C40000",
    "col2l": "#FF5D5D",
    "col2n": "#FF0000"
}

function ObjMor049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.6 33V2.7l-.6-.75V2Q95.7-1.95 68.4 2v-.05H68V2H51.6v-.05h-.15V2Q26.05-1.25 0 2v31q4.55 7.8 5.5 10.9.25.2.45.4 2.15 2.15 2.2 5.1-.05.05 0 .1-.05.05 0 .1-.05 2.95-2.2 5.05-2.1 2.15-5.1 2.15-.45 0-.85-.05v23.6h51.6q11.7-9.2 16.4 0h53.6V56.8q-1.3 0-2.4-.4-.1-.05-.2-.05-1.4-.55-2.55-1.7-2.1-2.1-2.1-5.05-.05-.05 0-.1-.05-.05 0-.1 0-2.25 1.25-4-.35-2.05 6-12.4z"
                    id="ObjMor049__Symbol_146_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M121 12.8L107.05 0h-42.7q-4 4.1-9.85 0H13.75L0 12.85h51.45v-.05h.15v.05H68v-.05h.4v.05H121v-.05z"
                    id="ObjMor049__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M121.6 42.75v-9.2H68v-31q-8.6-5.1-16.4 0v31H0v9.25q.4-.05.85-.05 3 0 5.1 2.1 2.15 2.15 2.2 5.1H51.6V80.9H68V49.95h46.35q0-2.95 2.1-5.1 1.15-1.15 2.55-1.65.1-.05.2-.05 1.1-.4 2.4-.4z"
                    id="ObjMor049__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M12.75 0H2.9L0 12.1v.75h16.4v-.1L12.75 0z"
                    id="ObjMor049__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 0v1.8h16.4V0H0m16.4 20v-1.8H0V20h16.4z"
                    id="ObjMor049__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor049__Symbol_146_0_Layer0_0_FILL"
                transform="translate(131.7 242.1)"
                id="ObjMor049__col1n"
            />
            <use
                xlinkHref="#ObjMor049__Symbol_105_0_Layer0_0_FILL"
                transform="translate(131.7 231.25)"
                id="ObjMor049__col1l"
            />
            <use
                xlinkHref="#ObjMor049__Symbol_42_0_Layer0_0_FILL"
                transform="translate(131.7 241.55)"
                id="ObjMor049__col2n"
            />
            <use
                xlinkHref="#ObjMor049__Symbol_18_0_Layer0_0_FILL"
                transform="translate(183.3 231.25)"
                id="ObjMor049__col2l"
            />
            <use
                xlinkHref="#ObjMor049__Symbol_12_0_Layer0_0_FILL"
                transform="translate(183.3 273.3)"
                id="ObjMor049__col2d"
            />
        </g></g>
    )
}

export default ObjMor049
