import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M6.2 7.45q1.1.6 1.45 1.35.4.15.6.15.4 0 .6-.4.15-.3.15-.5 0-1.4-2.95-1.9-1.7-.35-2.4 0 2.55 1.2 2.55 1.3m-4 1.95q0 .05.1.5.2.45.9.45.35 0 .65-.2.2-.2.3-.4-.1 0-.15-.05-1.05-.55-1.65-.75-.15.15-.15.45M.85 11.1q-.75 0-.85.6.7.5 1.2 1.1l.15-.15q.35-.25.35-.65 0-.9-.85-.9m13.7-3.8q.9 0 .9-.85 0-1.25-3.55-2.55-2.35-.85-3.15-.4.15.05.35.15 3.55 1.85 4.55 3.4.55.25.9.25m8-1.75q.95 1.2 1.1 1.2.35 0 .6-.35.2-.25.2-.65 0-2.8-4.65-4.3-3.25-1.05-4.25-.1 2.75.1 5.05 1.9 1.6 1.3 1.95 2.3M31.4 6.7q.35 0 .6-.4.2-.35.2-.75 0-2.9-4.5-4.7Q25.1-.2 24 .05q1.25.7 4.3 2.85.5.65 1.55 1.75.55.7.75 1.3.6.75.8.75m6.8-.55q0-2.7-2.6-4.9Q34.85.6 34.3.4 37 3.75 37 6.85v.6q.15.15.4.15.8 0 .8-1.45m19.65.7q0-3.1 2.7-6.45-.55.2-1.3.85-2.6 2.2-2.6 4.9 0 1.45.8 1.45.25 0 .4-.15v-.6m13-6.8q-1.1-.25-3.7.8-4.5 1.8-4.5 4.7 0 .4.2.75.25.4.6.4.2 0 .8-.75.2-.6.75-1.3 1.05-1.1 1.55-1.75Q69.6.75 70.85.05m8.45 1.3q-1-.95-4.25.1-4.65 1.5-4.65 4.3 0 .4.2.65.25.35.6.35.15 0 1.1-1.2.35-1 1.95-2.3 2.3-1.8 5.05-1.9m6.45 2.3q.2-.1.35-.15-.8-.45-3.15.4-3.55 1.3-3.55 2.55 0 .85.9.85.35 0 .9-.25 1-1.55 4.55-3.4m.1 4.4q0 .2.15.5.2.4.6.4.2 0 .6-.15.35-.75 1.45-1.35 0-.1 2.55-1.3-.7-.35-2.4 0-2.95.5-2.95 1.9m9 3.65q-.1-.6-.85-.6-.85 0-.85.9 0 .4.35.65l.15.15q.5-.6 1.2-1.1m-2.2-2.3q0-.3-.15-.45-.6.2-1.65.75-.05.05-.15.05.1.2.3.4.3.2.65.2.7 0 .9-.45.1-.45.1-.5z"
                    id="FacBro006__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro006__Symbol_6_0_Layer0_0_FILL"
                transform="translate(145.1 122.2)"
                id="FacBro006__col1n"
            />
        </g></g>
    )
}

export default FacBro006
