import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#200041",
    "col1n": "#3F0080",
    "col2d": "#5F00C2",
    "col2l": "#9C3AFF",
    "col2n": "#7E00FF"
}

function AccHat018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M182.5 20.95q-.1-.15-.25-.3-1.35-1.6-3.25-2.9-6.4-2.9-12.75-5.3-9.2-3.55-18.45-6.15-51.85-12.55-103.65 0-8.3 2.35-16.65 5.45-7.25 2.7-14.55 6-1.95 1.3-3.25 2.9-.15.15-.25.3-.2.25-.35.5Q.9 32.3 0 45.15q1.7 2.4 7.15.4 6.3-2.3 12.7-4.1.95-.25 1.95-.5l-.05-.15q75.25-26.75 149 .15v.15q.65.15 1.35.35 6.3 1.8 12.65 4.1 5.5 2 7.15-.4-.9-12.85-9-23.7l-.4-.5z"
                    id="AccHat018__Symbol_236_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M182.9 21.45l-.4-.5-.1-.15-.15-.15q-1.05-1.25-2.4-2.3-.45-.3-.85-.6l-.5-.2-10.95-4.6q-.35-.15-.75-.3l.05.05q-.3-.15-.6-.25-9.2-3.55-18.45-6.15-51.85-12.55-103.65 0-8.3 2.35-16.65 5.45-2.6.95-5.15 2-4.7 1.9-9.4 4-1.95 1.3-3.25 2.9-.1.1-.2.25l-.05.05q-.2.25-.35.5Q1.95 30.9.35 41.9.1 43.5 0 45.15q1.7 2.4 7.15.4 3.15-1.15 6.3-2.15-3.7.85-5.05-1.05.1-1.6.35-3.15 1.6-11.35 9.1-21.05 79.35-30.55 154.85-.7l.6.7q7.45 9.7 9.1 21.05.2 1.55.3 3.15-1 1.5-3.5 1.3 2.8.9 5.55 1.9 5.5 2 7.15-.4-.1-1.5-.3-2.95-1.5-11.15-8.7-20.75z"
                    id="AccHat018__Symbol_148_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M132.35 38.95l4.5-4.55 14.9-13.1q-8-7.55-21.15-12.25-5.2-1.7-10.4-3.05-44.95-11.8-88.5-.1-5.25 1.4-10.45 3.15Q8 13.75 0 21.3l14.9 13.1 4.5 4.55q5.9 4.15 15.05 2.65 7.05-2.4 13.8-4.15 1.5-.4 2.95-.75l6.4-1.35q17.05-3.05 32.95-.7 4.95.8 10.1 2.05 1.55.35 3.1.8 6.65 1.7 13.55 4.1 9.15 1.5 15.05-2.65M69.2 1.45h-.15v-.1h.15v.1z"
                    id="AccHat018__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M51.85 34.85Q56.8 19.9 67.65 7.7q1.8-2 3.65-3.8-5.65-1.2-11.25-2.05-24.9-3.65-49.35 0Q5.3 2.65 0 3.8q1.85 1.85 3.7 3.9 10.9 12.25 15.9 27.25 15.85-2.55 30.75-.35.75.1 1.5.25m-23-33.45v-.1H29v.1h-.15z"
                    id="AccHat018__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M140.3 7.6l-9.8 7.8q-5.9 4.15-15.05 2.65-8.55-2.95-16.65-4.9-5.15-1.25-10.1-2.05-15.9-2.35-32.95.7l-6.4 1.35q-8.15 1.95-16.75 4.9-9.15 1.5-15.05-2.65-6.5-5.4-9.2-7.7Q2.95 4.05 0 1.8l13.05 11.45 4.5 4.55q5.9 4.15 15.05 2.65 8.6-2.95 16.75-4.9l6.4-1.35q17.05-3.05 32.95-.7 4.95.8 10.1 2.05 8.1 1.95 16.65 4.9 9.15 1.5 15.05-2.65l4.5-4.55L149.9.15l-.15-.15q-4 3.25-9.45 7.6z"
                    id="AccHat018__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat018__Symbol_236_0_Layer0_0_FILL"
                transform="translate(96.05 83.75)"
                id="AccHat018__col1n"
            />
            <use
                xlinkHref="#AccHat018__Symbol_148_0_Layer0_0_FILL"
                transform="translate(96.05 83.75)"
                id="AccHat018__col1d"
            />
            <use
                xlinkHref="#AccHat018__Symbol_61_0_Layer0_0_FILL"
                transform="translate(116.5 101.8)"
                id="AccHat018__col2n"
            />
            <use
                xlinkHref="#AccHat018__Symbol_38_0_Layer0_0_FILL"
                transform="translate(156.7 101.85)"
                id="AccHat018__col2l"
            />
            <use
                xlinkHref="#AccHat018__Symbol_14_0_Layer0_0_FILL"
                transform="translate(118.35 122.95)"
                id="AccHat018__col2d"
            />
        </g></g>
    )
}

export default AccHat018
