import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.5 3.5q.35.2.7.15.4-.1.6-.45.2-.35.15-.7-.1-.4-.45-.6Q29.45 0 26.75 0q-3.15 0-5.9 1.6-2.3 1.25-3.85 1.25-1.75-.05-4.35-1.2-3.1-1.4-5.7-1.4-2.5 0-4.35.9h.05q-.9.35-2.3 1.45-.3.25-.35.65-.05.4.2.7.25.3.65.35.4.05.7-.2 1.1-.9 1.85-1.2v-.05q1.55-.7 3.55-.7 2.2 0 4.9 1.25h.05q3.05 1.3 5.05 1.35 2 .1 4.85-1.5 2.3-1.35 4.95-1.35 2.2 0 4.75 1.6z"
                    id="FacMou005__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou005__Symbol_5_0_Layer0_0_FILL"
                transform="translate(176 223.3)"
                id="FacMou005__col1n"
            />
        </g></g>
    )
}

export default FacMou005
