import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#644D27"
}

function ObjMor080({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M52.05 59.5q-.7-.5-1.3-.9-1.95-1.2-3.75-1.2-1.65 0-1.75 1.75 0 .9.05 2.35-.5 1.05-1.25 2.2-.5.9-.5 2.05 0 1.35.55 1.85.4.35 1.2.95.65.55.75.9.5 1.9.65 2.15.85 1.6 2.7 1.6 1.45 0 2-1.05l.05-.1q.4-.6.3-1.35-.05-.45-.05-.9.05-.8.2-1.2.2-.55 1.15-1.35 1.15-1.05 1.2-1.1.5-.7.6-1.35.05-.25.05-1.1 0-1.1-.2-1.65-.4-.95-1.6-1.7-.5-.45-1.05-.85m9.6-2.7q.1-.25.1-1.15 0-1.25-.25-1.7-.25-.45-1.55-1.6-.2-.15-.3-.3-.3-.55-.5-.95-.1-.2-.15-.45-.25-.85-.25-.9-.45-.6-1.6-.6-1.1 0-1.65.5-.25.15-.35.4v.15q-.15.3-.15.75 0 .65.2 1 .05.3.15.5.45.8.75 2v1.45q.2.85 1.2 1.35.55.4 1.4.5.1.05.2.05h1.45q1.05-.45 1.3-1m-52.9-4.35q-2.2.05-2.2 2.3 0 .05.25 1.55.5 1.5 1.85 1.5 1.65 0 2.1-1.1.15-.4.15-1.5 0-2.85-2.15-2.75m.7-6.25l-.3-.3q-.85-.6-1.8-.75-.2-.05-.4-.05-1 0-1.3.55-.15.25-.15.85 0 .85.25 1.65.3.9.8 1.2 1.5.7 2.75-.15.45-.45.5-1.1.05-1.05-.35-1.9m-.75-9.35q-.75-1.9-2.45-1.9-.9 0-1.45.4-1.6 1.15-1.7 1.2H1.55q-1.1.3-1.35.6-.2.25-.2 1.1 0 1.05.5 2.2.5 1.2 1.45 1.6 1.3 1.15 3.7 1.15 2.35 0 3.15-1.4.35-.65.35-1.85v-.65-.2q0-1.15-.45-2.25m-3.9-.7h-.05q0-.05.05-.05v.05m63 12.1q-.1.05-.25.1-.05.1-.1.25-.15.2-.25.35 0 .4-.05.75-.2 1.45.4 2.65.9.5 1.75 0 .15-.3.2-.65.45-1.55-.05-3l-.3-.3q-.35-.2-.7-.3-.35.05-.65.15m31.3 38.3q0 .05.35 1.95.75 1.95 2.9 1.95h.55q.65 0 1.4-.25.15-.25.3-.45 0-.05.05-.1.55-.55.6-1.15.65-1.35.9-2.75l.1-.3q.25-.85.25-2.25.15-1.1-.1-2.15-.5-2.15-2.4-2.15-1.45 0-1.85.45-.25.3-1.75 3.25-1.3 2.3-1.3 3.95m-13.5-71.2q0-.7-.4-1.4-.65-1.1-2.05-1.1-2 0-2 1.6 0 .5.25 1.1.25.55.25 1.1 0 .3-.15.55-.15.25-.15.6 0 .6.35 1.2.5.85 1.5.85 1.6 0 1.6-1.25l-.1-.95q0-.6.45-1.15.45-.5.45-1.15m-.55-6.1q2.35 0 2.35-3.45 0-1-.35-1.65-.35-.65-1.4-1.65Q82.25 0 80.6 0q-.75 0-.9.55v.9q0 1.25.2 2.2.1.5.65 2.3.45 1.55 1 2.15.05.1.15.15.05.05.05.1l.3.2q1.1.7 3 .7z"
                    id="ObjMor080__Symbol_169_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor080__Symbol_169_0_Layer0_0_FILL"
                transform="translate(134.2 216.9)"
                id="ObjMor080__col1n"
            />
        </g></g>
    )
}

export default ObjMor080
