import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor086({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#8C2984"
                    d="M236.25 272.05q.2-.866.2-1.8t-.2-1.8h-9.8l-6.35 2.25 6.5 1.35h9.65z"
                    id="AccMor086__Layer10_1_FILL"
                />
                <path
                    d="M221.3 264q-2.55 2.6-2.55 6.25t2.55 6.25q.217.209.45.4l6.6-6.55-6.7-6.7q-.16.168-.35.35z"
                    id="AccMor086__Layer10_1_MEMBER_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor086__Symbol_182_0_Layer0_0_FILL"
                />
                <g id="AccMor086__Layer10_0_FILL">
                    <path
                        fill="#FFF"
                        d="M236.25 268.45q-.466-2.516-2.4-4.45-2.6-2.6-6.25-2.6-3.348 0-5.95 2.25.542 3.783 4.8 4.8 4.913 1.967 9.8 0z"
                    />
                    <path
                        fill="#AAA"
                        d="M236.25 272.05q-4.89-1.722-9.65 0-3.203 1.319-4.85 4.85 2.54 2.2 5.85 2.2 3.65 0 6.25-2.6 1.934-1.934 2.4-4.45z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccMor086__Layer10_0_FILL" />
            <use xlinkHref="#AccMor086__Layer10_1_FILL" />
            <use xlinkHref="#AccMor086__Layer10_1_MEMBER_0_FILL" />
            <use
                xlinkHref="#AccMor086__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor086__col1l"
            />
        </g></g>
    )
}

export default AccMor086
