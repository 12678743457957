import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#C1D2E2",
    "col1n": "#8FAFCD"
}
function FacEar008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M46.45 43.15q-.6-6.2-3.2-12.65-7.95-19.85-11.5-27.8-2.7-6.05-11.2 2.85-8.35 8.6-13.2 17-2.05 3.45-3.5 6.8-12.2 29.2 16.95 56.3Q27.15 62.4 45.45 44.1q.5-.5 1-.95m172-20.6q-4.85-8.4-13.2-17-8.5-8.9-11.2-2.85-3.3 7.35-10.3 24.8-.6 1.45-1.2 3-2.6 6.5-3.15 12.75l.85.85q18.35 18.35 24.7 41.65 29.2-27.2 17-56.4-1.45-3.35-3.5-6.8z"
                    id="FacEar008__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M20.4 0Q4.5 15.8.1 34.85-1 51.8 12.6 68.6-1.85 37.95 20.4 0m170.9 0q22.25 37.95 7.8 68.6 13.6-16.8 12.5-33.75Q207.2 15.8 191.3 0z"
                    id="FacEar008__Symbol_17_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar008__Symbol_26_0_Layer0_0_FILL"
                transform="translate(79.6 42.2)"
                id="FacEar008__col1n"
            />
            <use
                xlinkHref="#FacEar008__Symbol_17_0_Layer0_0_FILL"
                transform="translate(86.6 49.4)"
                id="FacEar008__col1l"
            />
        </g></g>
    )
}
export default FacEar008
