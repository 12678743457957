import { AssetSvgProps } from "../../../shared/assets"

function ShiMot092({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M180.55 258q-12.477 17.9 0 35.8h22.6q9.848-17.143 0-35.8h-22.6z"
                    id="ShiMot092__Layer1_0_FILL"
                />
                <path
                    fill="#006431"
                    d="M225.7 293.8V258h-22.55v35.8h22.55M159.2 258v35.8h21.35V258H159.2z"
                    id="ShiMot092__Layer1_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot092__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot092__Layer1_1_FILL" />
        </g></g>
    )
}

export default ShiMot092
