import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D9D9D9",
    "col1n": "#EEEEEE"
}

function ObjFoo029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.35 6.2Q46.9 3.85 43.4 3.7h-1q-.8 0-1.65.1V0h-27v23.8h-6.5v-.05H0l5.4 4h30.05q.3-.4.7-.8 2.25-2.25 5.4-2.25 3.15 0 5.4 2.25.4.4.7.8h1.65l6.75-4h-15.3v-2.7h2.65q3.5-.2 5.95-2.6 2.7-2.5 2.7-6.05 0-3.6-2.7-6.2m-8.6.95q1.4 0 2.65.2 1.8.25 2.8 1.2 1.5 1.35 1.95 3.7.4 2.35-1.5 4.1-1.35 1.2-3.25 1.55-1.25.15-2.65.15V7.15z"
                    id="ObjFoo029__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M27 0H0v1.45h27V0z"
                    id="ObjFoo029__Symbol_43_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo029__Symbol_104_0_Layer0_0_FILL"
                transform="translate(90.4 259.25)"
                id="ObjFoo029__col1n"
            />
            <use
                xlinkHref="#ObjFoo029__Symbol_43_0_Layer0_0_FILL"
                transform="translate(104.15 281.6)"
                id="ObjFoo029__col1d"
            />
        </g></g>
    )
}

export default ObjFoo029
