import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function FacNos004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M40.35 19q0-7.95-6-13.5Q28.5 0 20.1 0 11.8 0 5.85 5.5 0 11.05 0 19q0 7.85 5.85 13.35 5.95 5.6 14.25 5.6 8.4 0 14.25-5.6 6-5.5 6-13.35z"
                    id="FacNos004__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M41.9 19.7q0-.9-.1-1.65-.4-3.6-2-6.65-1.5-3-4.15-5.55-3.1-2.9-6.85-4.4-.95-.35-1.9-.65Q21.35-.75 15 .8q-1 .3-1.9.65-3.75 1.5-6.85 4.4-2.7 2.55-4.2 5.55Q1 13.5.5 15.8q-.95 3.75 0 8.3.5 2.25 1.55 4.35 1.5 3 4.2 5.55 6.05 5.7 14.7 5.95 8.6-.25 14.7-5.95 2.65-2.55 4.15-5.55 1.5-2.95 1.9-6.3.1-.2.1-.4.1-.75.1-1.6v-.45m-21.05-18q8.05 0 13.7 5.3 5.75 5.35 5.75 12.95 0 7.55-5.75 12.85-5.65 5.4-13.7 5.4-8 0-13.7-5.4Q1.5 27.5 1.5 19.95q0-7.6 5.65-12.95 5.7-5.3 13.7-5.3z"
                    id="FacNos004__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos004__Symbol_13_0_Layer0_0_FILL"
                transform="translate(172.4 183.85)"
                id="FacNos004__col1n"
            />
            <use
                xlinkHref="#FacNos004__Symbol_6_0_Layer0_0_FILL"
                transform="translate(171.55 182.75)"
                id="FacNos004__col1d"
            />
        </g></g>
    )
}

export default FacNos004
