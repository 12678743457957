import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E5B000"
}

function AccJew030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.4 4q0-.45-.3-.9-.55-.85-1.6-1.25-.95-.4-1.9-.15-.15.05-.25.1-.15-.3-.4-.55-.7-.75-1.8-.95-1-.2-1.9.25-.3.15-.5.35-.2-.25-.45-.4-.8-.6-1.85-.5-1.1.05-1.9.7-.25.2-.45.5-.1-.1-.25-.15Q6.95.7 5.95 1q-1.1.3-1.7 1-.2.25-.25.5-.25-.05-.55-.1-1-.1-1.9.45Q.6 3.4.2 4.3q-.4.85.05 1.55.4.75 1.4.9.95.15 1.9-.4T4.9 4.9q.05-.05.05-.15.05.05.1.05.9.4 1.95.1 1.05-.3 1.65-1.1.1-.1.15-.2.8.45 1.8.4 1.1-.1 1.85-.7.05-.05.15-.1.05.05.1.15.7.75 1.75.95 1.05.2 1.9-.2l.2.3q.5.85 1.5 1.25.9.3 1.75.15 0 .1.05.25.15 1 .95 1.75.8.75 1.8.85 1 .15 1.55-.5.55-.6.4-1.6-.2-.95-.95-1.7-.8-.75-1.75-.85h-.45m-.5 1.35q.1-.15.25-.3.25-.2.7-.15.6.1 1.1.55.55.45.6 1 .15.6-.1.9-.3.35-.95.2Q22 7.5 21.45 7q-.5-.45-.65-1.05-.05-.3.1-.6M17.95 2.5q.55-.05 1.2.2.6.25.95.65.35.5.15.9-.15.4-.7.55-.5.2-1.15-.05-.65-.25-1-.8-.25-.35-.2-.65.1-.2.15-.35.2-.3.6-.45m-1.9-.85q.4.45.35.85-.1.45-.65.65-.5.3-1.15.15-.65-.15-1.1-.55-.3-.3-.3-.6V1.9q.05-.4.6-.6.55-.25 1.15-.1.7.1 1.1.45m-3.95.2q0 .45-.5.7-.4.4-1.05.45-.7 0-1.25-.3-.15-.1-.2-.15-.05-.2-.1-.35-.05-.2-.15-.35.05-.35.4-.6Q9.7.9 10.4.9q.65-.05 1.15.2.5.3.55.75m-4.2.4q0 .2.1.4-.05.3-.3.65-.35.45-1 .6-.65.2-1.2.05-.3-.1-.45-.25-.05-.25-.15-.45l-.1-.1q0-.35.3-.7.35-.45 1.05-.65.6-.15 1.15 0 .4.15.6.45M1.1 5.4q-.2-.4-.05-.95.3-.5.85-.85.6-.35 1.15-.3.65.05.85.45.25.4-.05.9-.25.5-.85.85-.6.3-1.15.25-.5 0-.75-.35z"
                    id="AccJew030__Symbol_99_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew030__Symbol_99_0_Layer0_0_FILL"
                transform="translate(109.35 276)"
                id="AccJew030__col1n"
            />
        </g></g>
    )
}

export default AccJew030
