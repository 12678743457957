import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#B4DBFF",
    "col1n": "#77BFFF",
    "col2l": "#FFD95B",
    "col2n": "#FFC400"
}

function ObjFoo031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M23.45 8.35v-8q-.4 1-3.35 1.7-3.45.9-8.35.9-4.85 0-8.3-.9Q.05 1.2 0 0v8q.1.6 1.05 1.2.25.15.55.25.45.2.95.3.15.05.35.15.25.1.55.15 3.45.85 8.3.85 4.9 0 8.35-.85.25-.05.4-.1l.9-.3q.6-.2 1-.4.9-.45 1.05-.9z"
                    id="ObjFoo031__Symbol_62_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M23.45 2.4v-.2Q23.4 1.45 22.4.9 21.35.3 20.95.2l-.6-.15q-.05.05-.05.1-.15.15-.3.4h-.05q-1.25 1.6-3.5 2.2Q12.7 3.6 8.6 3q-.4-.05-.85-.15-.25-.05-.55-.05 0-.05-.05-.05Q4.45 2.15 3.6.7L3.55.65q-.1-.2-.25-.4-.05-.05-.05-.1-.1-.1-.1-.15-.05.05-.2.05l-.3.1Q0 .95 0 2.05q.05 1.2 3.45 2.05 3.45.9 8.3.9 4.9 0 8.35-.9 2.95-.7 3.35-1.7z"
                    id="ObjFoo031__Symbol_25_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.4 6.75q.4-1.05.4-1.85 0-2.25-1.15-3.6-3.55-2.6-7.1 0Q4.4 2.65 4.4 4.9q0 .8.35 1.85l.4 1.05q0 1.8-.9 2.95-.55.75-1.9 1.6-.4.2-.75.45-.95.75-1.3 1.65-.7 1.55.2 3.45 0 .05.1.15 0 .05.05.1.15.2.25.4l.05.05q.85 1.45 3.55 2.05.05 0 .05.05.3 0 .55.05.45.1.85.15 4.1.6 7.85-.25 2.25-.6 3.5-2.2h.05q.15-.25.3-.4 0-.05.05-.1.9-1.95.2-3.5-.4-.9-1.35-1.65-.3-.25-.75-.45-1.3-.85-1.85-1.6-.9-1.15-.9-2.95l.35-1.05M11.45 18.6h-.1l.05-.05.05.05z"
                    id="ObjFoo031__Symbol_13_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.35 9.45Q.2 9.4.1 9.5q-.05 0-.1.1 0 .2.05.35.05.2.15.4 1.05 1.75 1.1 2.15.1.1.1.55v.25q.05.45.05 1.2 0 .4-.05.8t-.1.75q-.05.35-.1.6-.05.35-.05.4 0 .05-.2.7.25.3.95.3.6 0 1.1-.25.75-.5 1.1-1.15 0-.05.05-.1.1-.15.25-.4.15-.85.15-1 0-.65-.15-1.35-.05 0-.1-.05l-.4-.9q-.25-.6-.9-1.1-.45-.3-.95-.55-.8-.55-1.3-1.25-.25-.35-.4-.5m1.5 7.75h-.1l.05-.05.05.05m.8-14.75V2.1q-.1-.6-.6-1.05Q1.85.65.6.25.35.15.15.05.05 0 0 .05q.3.25.5.9.15.6.2 1.25 0 .05-.15 1.75-.15 1-.5 1.45L0 5.55q.7-.15 1.05-.15.55-.1 1-.85.6-.95.6-2.1z"
                    id="ObjFoo031__Symbol_1_0_Layer0_0_FILL"
                />
                <path
                    fill="#C4E2F7"
                    fillOpacity={0.561}
                    d="M118.45 259.35q-.3-.1-.55-.25-.6 1-1.1 2.05-2.85 5.2-2.3 10.8.2 1.6.55 3.05v.15q1.95 5.95 2.3 11.5h-.05q.002.07 0 .15.717.164 1.45.4 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-3.08 1.171-5.7.65.867 4.143 3.5 10.15l.1.2q.3.5 1.3.95.65.3 1.55.55h.05q2.7.75 6.3.85h1.7l1.6-.1q2.65-.2 4.75-.75h.05q.9-.25 1.6-.55.95-.45 1.3-.95.05-.1.05-.2 3.95-8.9 3.9-13.8-.05-3.65-1.15-7.5-1.85-5.9-1.65-11.75.15-4.75 1.65-9.45.8-2.4 1.1-5.25.6-5.6-2.3-10.8-.45-1-1.05-2-.4.2-1 .4l-.9.3q-.15.05-.4.1-3.45.85-8.35.85-4.85 0-8.3-.85-.3-.05-.55-.15-.2-.1-.35-.15-.5-.1-.95-.3z"
                    id="ObjFoo031__Layer7_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M120.1 270.7q-2.45.35-3.15.8-.15.1-.25.2-.05.1-.05.2 0 .15.15.3.15 1.5.5 2.8v.15q1.95 5.95 2.25 11.5h.05q0 .15.05.3.01.354 0 .7 1.703.966 3.05 2.55 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-1.744.663-3.35.75.729 3.366 2.55 7.9.05.2.15.3l.1.4q1.1 1.6 5.95 2.25.85.15 1.7.2h.2q.7.05 1.35 0 .9 0 1.75-.15.1-.05.15-.05 4.9-.65 5.95-2.25.1-.2.15-.4l.15-.3q2.95-7.35 2.9-11.65-.05-3.65-1.1-7.5-1.85-5.9-1.65-11.75.15-4.75 1.65-9.45.75-2.35 1.05-5 .2-.15.2-.3t-.15-.2q-.05-.1-.05-.15l-.05-.05q-.8-.45-3.25-.8-3.45-.5-8.4-.5t-8.45.5z"
                    id="ObjFoo031__Layer7_1_FILL"
                />
                <path
                    fill="#DEDEDE"
                    d="M116.8 272.2q.5.5 3.3.95 3.5.5 8.45.5 4.95 0 8.4-.5 2.75-.45 3.3-.95.2-.15.2-.3t-.15-.2q-.05-.1-.05-.15l-.05-.05q-.75.45-3.25.8-3.45.5-8.4.5t-8.45-.5q-2.45-.35-3.15-.8-.15.1-.25.2-.05.1-.05.2 0 .15.15.3z"
                    id="ObjFoo031__Layer7_2_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjFoo031__Symbol_62_0_Layer0_0_FILL"
                transform="translate(116.85 249.9)"
                id="ObjFoo031__col1n"
            />
            <use
                xlinkHref="#ObjFoo031__Symbol_25_0_Layer0_0_FILL"
                transform="translate(116.85 247.85)"
                id="ObjFoo031__col1l"
            />
            <use
                xlinkHref="#ObjFoo031__Symbol_13_0_Layer0_0_FILL"
                transform="translate(119.5 229.95)"
                id="ObjFoo031__col2n"
            />
            <use
                xlinkHref="#ObjFoo031__Symbol_1_0_Layer0_0_FILL"
                transform="translate(129.1 231.35)"
                id="ObjFoo031__col2l"
            />
            <use xlinkHref="#ObjFoo031__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo031__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo031__Layer7_2_FILL" />
        </g></g>
    )
}

export default ObjFoo031
