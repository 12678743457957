import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2d": "#D23900",
    "col2l": "#FF8220",
    "col2n": "#FF5400"
}

function AccHat081({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.749}
                    d="M158.75 21.25Q131.5 0 93 0T27.25 21.25Q0 42.6 0 72.8q0 30.15 27.25 51.45Q54.5 145.6 93 145.6t65.75-21.35Q186 102.95 186 72.8q0-30.2-27.25-51.55z"
                    id="AccHat081__Symbol_298_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M186.4 43.55q-5.25-7.5-12.1-14.35-13.8-13.8-30.35-21.1Q125.85.15 104.6 0 83.35.15 65.25 8.1 48.7 15.4 34.9 29.2q-6.85 6.85-12.1 14.35v.1h-.05q-.1.15-.25.3Q10.4 61.55 6.95 82.7q-1.9.9-3.3 2.3-.85.9-1.55 2Q-1 92.1.5 101.6q.4 2.75 1.2 5.85.1.15.1.35.3 1.15.6 2.25 6.1 23.5 16.95 40.75l9.35 12.75.6.7q2.65 3.1 5.6 6 28.95 28.95 69.7 29.25 40.75-.3 69.7-29.25 2.95-2.9 5.6-6l.6-.7 9.35-12.75q10.85-17.25 16.95-40.75.3-1.1.6-2.25 0-.2.1-.35.8-3.1 1.2-5.85 1.5-9.5-1.6-14.6-.7-1.1-1.55-2-1.4-1.4-3.3-2.3-3.45-21.15-15.55-38.75-.15-.15-.25-.3-.05-.05-.05-.1m-8.75 83.6l-10.35 5.05-25.85 30.75h-19.1l-5.35-5H92.2l-5.35 5h-19.1L41.9 132.2l-10.35-5.05-9.1-53.1L48.9 50.2q.35.25.7.55l.4.3.4.3q3.4 2.5 7.9 4.75 12.7 6.55 28.75 9l6.65 6.25h21.8l6.65-6.25q16.05-2.45 28.75-9 4.5-2.25 7.9-4.75l.4-.3.4-.3q.35-.3.7-.55l26.45 23.85-9.1 53.1z"
                    id="AccHat081__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M87.05 168.25L81 178.45q32.85-5.95 42.8-26.7-18.8 19.05-36.75 16.5m-50.3 0Q18.8 170.8 0 151.75q9.95 20.75 42.8 26.7l-6.05-10.2M61.9 0Q34.15.3 12.85 12.55 34.15 6 61.9 5.85q27.75.15 49.05 6.7Q89.65.3 61.9 0z"
                    id="AccHat081__Symbol_49_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.8 75.05L0 82.7l8 48.5-6.2-56.15m171.75 88.5l9.35-12.75q1.3-2.25 2.5-4.5-6.9 12.2-18.05 22.7-26.1 24.65-61.8 27.35-3.9.35-7.9.35t-7.9-.35q-35.7-2.7-61.8-27.35Q16.8 158.5 9.9 146.3q1.2 2.25 2.5 4.5l9.35 12.75.6.7q2.65 3.1 5.6 6 28.95 28.95 69.7 29.25 40.75-.3 69.7-29.25 2.95-2.9 5.6-6l.6-.7M163.3 135.7l12.05-4.9 9.7-58.1-28.25-25.5-3.4 3.05 26.4 23.8-9.1 53.1-10.35 5.05-25.85 30.75h-19.1l-5.35-5h-24.8l-5.35 5H60.8L34.95 132.2l-10.35-5.05-9.1-53.1 26.4-23.8-3.4-3.05-28.25 25.5 9.7 58.1L32 135.7l26 31.5h23.5l10.75 25.25-5.25-30.5h21.3l-5.25 30.5 10.75-25.25h23.5l26-31.5m32-53l-1.8-7.65-6.2 56.15 8-48.5M170 33.4q3.5 3.35 6.65 6.9 1.436 1.634 2.8 3.3v-.05q-5.25-7.5-12.1-14.35Q153.55 15.4 137 8.1 118.9.15 97.65 0 76.4.15 58.3 8.1q-16.55 7.3-30.35 21.1-6.85 6.85-12.1 14.35v.1q1.35-1.7 2.8-3.35 3.15-3.55 6.65-6.9 30-28.65 72.35-28.75Q140 4.75 170 33.4z"
                    id="AccHat081__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat081__Symbol_298_0_Layer0_0_FILL"
                transform="translate(99.6 78.2)"
                id="AccHat081__col1n"
            />
            <use
                xlinkHref="#AccHat081__Symbol_102_0_Layer0_0_FILL"
                transform="translate(87.9 50.55)"
                id="AccHat081__col2n"
            />
            <use
                xlinkHref="#AccHat081__Symbol_49_0_Layer0_0_FILL"
                transform="translate(130.6 62.5)"
                id="AccHat081__col2l"
            />
            <use
                xlinkHref="#AccHat081__Symbol_36_0_Layer0_0_FILL"
                transform="translate(94.85 50.55)"
                id="AccHat081__col2d"
            />
        </g></g>
    )
}

export default AccHat081
