import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#A37831",
    "col1n": "#D19532"
}

function ObjToo022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M96.2 79q7.95-1.8 14.75-3.25 1-.2 2.25-.55 5.2-1.7 8.5-4.6 2.25-2.05 2.75-3.6 1.15-3.05-1.55-5.55-2.5-2-6.95-1.8-24.3 2.5-48.8 4.85-8.65.85-14.45-.8l-.55-.15q-.6-.2-1.15-.35-.3-.1-.6-.25l-3.85-2q-.2-.2-.45-.3-.45-.4-.95-.8l-.4-.35q-4.65-3.95-8.85-11.55-11.8-21.55-23.5-43Q10.05 1.15 7 .2 4.8-.3 3.25.5 2.3 1 1.5 2.05q-1 1.3-1.35 4.3-.55 4.4 1 9.65.35 1.2.75 2.2 2.75 6.4 5.65 13.95 4.35 11.35 14.3 33.6 3.3 7.05 7.4 11.7Q30.5 79 31.9 80.4q4.45 4.2 10.75 5.65 1.9.4 3.9.5 2 .25 4.1.35-.45-1-.45-2.05 0-2.95 3.3-5 3.35-2.1 8.1-2.1 4.8 0 8.1 2.1 2.8 1.7 3.3 4h.05q.2 0 1-.15.3-.1.7-.2 1.05-.25 1.25-.25 1.1-.1 2.15-.45h.05q11.15-2.15 18-3.8z"
                    id="ObjToo022__Symbol_182_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M105.6 75.05q-1.35.25-2.85.6-1.6.4-1.85.4L76.15 81.2l-.05-.05q-.45.1-1.25.25l-1.6.25q-.65.15-1.15.25.7.9.9 1.95h.05q.2 0 1-.15.3-.1.7-.2 1.05-.25 1.25-.25 1.1-.1 2.15-.45h.05q11.15-2.15 18-3.8 7.95-1.8 14.75-3.25 1-.2 2.25-.55 5.2-1.7 8.5-4.6 2.25-2.05 2.75-3.6 1.15-3.05-1.55-5.55-2.5-2-6.95-1.8-24.3 2.5-48.8 4.85-8.65.85-14.45-.8l-.55-.15q-.6-.2-1.15-.35-.3-.1-.6-.25l-3.85-2q-.2-.2-.45-.3-.45-.4-.95-.8l-.4-.35q-4.65-3.95-8.85-11.55-11.8-21.55-23.5-43Q10.05 1.15 7 .2 4.8-.3 3.25.5 2.3 1 1.5 2.05q-1 1.3-1.35 4.3-.55 4.4 1 9.65.35 1.2.75 2.2 2.75 6.4 5.65 13.95 4.35 11.35 14.3 33.6 3.3 7.05 7.4 11.7Q30.5 79 31.9 80.4q4.45 4.2 10.75 5.65 1.9.4 3.9.5 2 .25 4.1.35-.4-.9-.45-1.9-12.15-.45-18.85-7.65-.3-.35-.6-.75-.45-.5-.85-1-3.25-3.9-6-9.45L7.3 26.6q-.1-.2-.65-1.7-.55-1.45-1.1-2.75Q5 20.8 5 20.75q-3.05-8.3-3.35-11.4-.25-3.15.75-5.4.9-2.2 3.45-2.1 3.4.85 5.2 4.45l3.5 6.25q.05.05.05.1l21.5 38.5q4.05 6.4 8.25 9.9h-.05l.4.3q.2.2.45.35l.1.05q.7.6 1.4 1l2.9 1.5q.3.05.55.15.45.15 1 .3l.5.15q5.25 1.5 12.85 1.3l43.85-4h.15l7.1-.6q4.05-.5 6.65 1.8 1.5 2.05.15 4-1.25 2.1-3.95 3.65-2.75 1.6-11.35 3.65-.05.05-1.45.4z"
                    id="ObjToo022__Symbol_123_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo022__Symbol_182_0_Layer0_0_FILL"
                transform="translate(58.2 218.45)"
                id="ObjToo022__col1n"
            />
            <use
                xlinkHref="#ObjToo022__Symbol_123_0_Layer0_0_FILL"
                transform="translate(58.2 218.45)"
                id="ObjToo022__col1d"
            />
        </g></g>
    )
}

export default ObjToo022
