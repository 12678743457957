import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400",
    "col2l": "#97FA00",
    "col2n": "#86DE00"
}

function ObjToo077({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M117.5 15.2L113.45 0 67.9 12.2l-1.1 1.1-.1-.05q.8-.3-.15-.65-8.65 5.45-19.7 5.25-.6.4-.55.55h-.8l-.05-.15L0 30.4l4.05 15.25L117.5 15.2z"
                    id="ObjToo077__Symbol_244_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.05 23.6q-.5-.3-.85-.05-.35.2-.65.1-.35-.05-.65-.2-.3-.05-.65 0-.3-.05-.5 0Q.15 26.9 0 31.9q.1.4.4.6.15.1.3.25.9 2.2 3.25 1.35.15.05.2 0h1l.05-.25q3.65-3.45 3.55-7.8-.3-.55-.75-1-.45-1.15-.95-1.45m46.7-12.4q3.25-1.3 2.1-2.9l-.9-.5q.8-.3-.15-.65L35.1 12.4q-.6.4-.55.55.05.15.7.1l-.3.5Q34.6 16.3 38 15.4q8.25-.55 15.75-4.2m38.9-.85q0-.15.2-.2.35-.35.65-.55 1.35-4.8-2.45-8.85-.3-.15-.5-.15l-.05.05q-.35-.1-.55-.15-.45-.3-.85-.4-.4-.15-.6-.1-.2.05-.75.2-.55.15-1.1 1.4-.6.45-1.05.9Q84.25 6.95 88 11q.45.1.9.15.15.05.35.05 1.9 1.4 3.4-.85z"
                    id="ObjToo077__Symbol_245_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M5.2 33.35q3.65-3.45 3.55-7.8-.3-.55-.75-1L3.95 33.6q.15.05.2 0h1l.05-.25m-.95-10.4q-.3-.05-.5 0Q.15 26.4 0 31.4q.1.4.4.6.15.1.3.25l4.2-9.3q-.3-.05-.65 0M54.95 7.3q.8-.3-.15-.65L35.1 11.9q-.6.4-.55.55.05.15.7.1l19.7-5.25M88 10.5q.45.1.9.15.15.05.35.05l-.15-.35-2.45-9.25q-.6.45-1.05.9-1.35 4.45 2.4 8.5m4.65-.65q0-.15.2-.2.35-.35.65-.55 1.35-4.8-2.45-8.85-.3-.15-.5-.15l-.05.05q-.35-.1-.55-.15l2.7 9.85z"
                    id="ObjToo077__Symbol_246_0_Layer0_0_FILL"
                />
                <g id="ObjToo077__Layer1_0_FILL">
                    <path d="M225.65 292.45q-.7.45-1.25 1-2 1.9-2 4.55.012.298.05.6l.15.8q0 .1.05.25l.2.7q.45 1.15 1.45 2.2.3.3.7.6l.05.05q1.2.85 2.55 1.1l.75.1q.2 0 .5.05l.8-.05q1.05-.15 1.85-.55l.9-.55q.55-.4.95-.75.2-.2.25-.35.05 0 .1-.05 1.1-1.25 1.35-2.7l.2-.6q.05-.45.05-.9l-.05-.85q-.35-2.05-1.9-3.65-1.8-1.95-4.35-1.9l-.3-.05q-1.55.15-2.9.85l-.15.1m3.2.2q.35-.05.65 0 .3.15.65.2l.6.2q.15 0 .4.1.75.4 1.45 1.1.45.45.75 1l-3.55 7.8-.05.25h-1q-.05.05-.2 0-1.8-.1-3.25-1.35-.15-.15-.3-.25-.3-.2-.4-.6l3.75-8.45q.2-.05.5 0m-16 1.5l-3.35.9 4.05 15.2 3.35-.85-4.05-15.25M319.5 271.2q-1.55-2.05-4.05-2.35l-.3-.15q-2.55-.4-4.6 1.25-2.15 1.5-2.55 4.15-.45 2.55 1.15 4.75 1.65 2.15 4.25 2.55 2.6.4 4.7-1.15 2.2-1.6 2.55-4.25.45-2.55-1.15-4.8m-6.7-1.2q.25-.15.4-.2.25 0 .55-.05.4 0 .8-.05.2.05.55.15l.05-.05q.2 0 .5.15l2.45 8.85q-.3.2-.65.55-.2.05-.2.2-1.6.95-3.4.85-.2 0-.35-.05-.45-.05-.9-.15l-2.4-8.5q.45-.45 1.05-.9.7-.65 1.55-.8M260 282.75l-.3-1.15-1.4.4.5 1.85q.1.35.25.6.95 2.15 3.3 1.6l16.95-4.55q2.25-.65 2-3 .1-.35-.05-.7l-.5-1.85-1.35.4.15.65.2.5q.55 2.3-1.4 2.9l-15.75 4.2q-1.95.45-2.6-1.85m66.3-19l4.05 15.2 3.25-.85-4.05-15.25-3.25.9z" />
                    <path
                        fill="#FFF"
                        d="M229.3 300.55q.4-1.05.35-1.85-.05-.9-.5-1.1-.5-.2-1.2.35-.65.55-1.1 1.55-.5 1-.4 1.9 0 .85.5 1.1.55.15 1.2-.35.7-.6 1.15-1.6m83.3-30.45q-.55.15-.7 1.05 0 .75.25 1.85.3 1.1.85 1.7.6.65 1.15.55.5-.15.7-1.1.15-.8-.15-1.8-.3-1.1-.95-1.75-.65-.65-1.15-.5m-35.3 7.7q0-.15-.1-.2l-6.85 1.85v.25q.05.15.2.3.3.45 1.2.6 1.1.2 2.5-.2.85-.2 1.5-.5.5-.35.9-.6.9-.75.65-1.5z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo077__Symbol_244_0_Layer0_0_FILL"
                transform="translate(212.85 263.75)"
                id="ObjToo077__col1n"
            />
            <use
                xlinkHref="#ObjToo077__Symbol_245_0_Layer0_0_FILL"
                transform="translate(224.6 269.2)"
                id="ObjToo077__col2n"
            />
            <use
                xlinkHref="#ObjToo077__Symbol_246_0_Layer0_0_FILL"
                transform="translate(224.6 269.7)"
                id="ObjToo077__col2l"
            />
            <use xlinkHref="#ObjToo077__Layer1_0_FILL" />
        </g></g>
    )
}

export default ObjToo077
