import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF0000",
    "col2d": "#FFEE00",
    "col2n": "#FFFF62"
}

function AccJew026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M106.15 0q-6.35 4.3-11.5 6.8-12.35 18.9-41.9 39.05-29-20-41.5-38.75Q5.75 4.7 0 .75q13.75 27.9 45 47.85l.7.45h14.4l.65-.45Q92.4 28.4 106.15 0z"
                    id="AccJew026__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M22.25 22.25q3.85-3.8 3.85-9.2 0-5.4-3.85-9.25-1-.95-2.05-1.65-.6-.4-1.25-.75-.55-.3-1.1-.5Q17 .55 16.1.35 14.6 0 13.05 0q-1.5 0-2.85.25-1.05.25-2.1.7-.5.2-.95.45-.7.35-1.35.75-1.1.7-2.05 1.65Q0 7.65 0 13.05t3.75 9.2q3.9 3.85 9.3 3.85 5.35 0 9.2-3.85m-6.4-15v.2q-.05-.05-.05-.15 0-.05.05-.05m-10.8 5H5l.05-.05v.05z"
                    id="AccJew026__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M26.1 13.05q0-5.4-3.85-9.25-1-.95-2.05-1.65-.6-.4-1.25-.75-.55-.3-1.1-.5Q17 .55 16.1.35 14.6 0 13.05 0q-1.5 0-2.85.25-1.05.25-2.1.7-.5.2-.95.45-.7.35-1.35.75-1.1.7-2.05 1.65Q0 7.65 0 13.05t3.75 9.2q3.9 3.85 9.3 3.85 5.35 0 9.2-3.85 3.85-3.8 3.85-9.2M20.45 5.4q3.15 3.15 3.15 7.55 0 4.45-3.15 7.55-3.15 3.15-7.55 3.15T5.35 20.5q-3.1-3.1-3.1-7.55 0-4.4 3.1-7.55 2.3-2.25 5.25-2.9 1.15-.2 2.3-.2 1.3 0 2.5.3 2.85.6 5.05 2.8m-9.1 6.15l.05.65-.05.55v2.2q-.05 1.15-.25 2-.05.3-.05.75-.05.35-.85 1.3.05.3.4.55.5 0 1.35.1l4.2.1q.2-.35.25-.4v-.8q-.1-.15-.15-.3L16 18.1q-.5-.25-.5-.8l-.3-3.15Q15 13.3 15 10q0-1.65.1-2 .25-.55.75-.95v-.4l-4.45-.1q-.3.45-1.15 1.15-.55.55-1.4 1.25-.25.1-1 .5-.2.1-.35.2.45.35 1.1 1.2.8.95 1.15 1.45.8-.85 1.15-.9 0-.05.05-.05.2-.15.4.2z"
                    id="AccJew026__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew026__Symbol_95_0_Layer0_0_FILL"
                transform="translate(139.8 233.4)"
                id="AccJew026__col1n"
            />
            <use
                xlinkHref="#AccJew026__Symbol_38_0_Layer0_0_FILL"
                transform="translate(179.7 280.3)"
                id="AccJew026__col2n"
            />
            <use
                xlinkHref="#AccJew026__Symbol_8_0_Layer0_0_FILL"
                transform="translate(179.7 280.3)"
                id="AccJew026__col2d"
            />
        </g></g>
    )
}

export default AccJew026
