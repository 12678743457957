import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A06001",
    "col2d": "#FFC400",
    "col2l": "#FFEAAD",
    "col2n": "#FFD446"
}

function AccJew024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.55.95q.15-.2.15-.4 0-.25-.15-.4-.2-.15-.4-.15-.25 0-.4.2Q98.3 23.75 65.1 39.1l-.05.05q-2.25 1.2-4.4 1.2-3.65-.9-4.05-1.1l-.05-.05-.3-.1Q23.25 23.6.95.2.8 0 .6 0 .35 0 .2.15 0 .3 0 .55q0 .2.15.4Q22.6 24.5 55.85 40.1l.05.05q.1 0 .15.05.45.25 4.45 1.25h.1q2.4 0 4.95-1.35Q99 24.65 121.55.95z"
                    id="AccJew024__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.6 1.25q.3-.2.4-.2Q7.55.95 8.6.3 7.8 0 7.15 0H3.7Q2.65.05 1.8.85q-.35.3-.7.8Q0 3.3 0 5.65t1.1 4q1.1 1.6 2.6 1.65h3.4q1.55 0 2.7-1.65 1.1-1.65 1.1-4t-1.1-4q-.2-.3-.4-.5l-2.65.9q.85 1.5.85 3.6 0 2.35-1.1 4-.2.25-.4.55-.05-.05-.1-.05Q5.35 9.8 4.85 9q-.9-1.45-.9-3.45t.9-3.4q.2-.35.4-.55.2-.3.35-.35z"
                    id="AccJew024__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M5.35 1.4q.3.3.6.75.9 1.4.9 3.4T5.95 9q-.9 1.4-2.2 1.4-.5 0-.95-.2-.05-.05-.1-.05Q2.05 9.8 1.55 9q-.9-1.45-.9-3.45t.9-3.4q.2-.35.4-.55.2-.3.35-.35.3-.2.4-.2Q4.25.95 5.3.3 4.5 0 3.85 0H3.6q-.75 0-1.45.5-.55.35-.95 1.05l-.1.1Q0 3.3 0 5.65t1.1 4q.45.7 1.05 1.1.7.5 1.55.55h.1q1.55 0 2.7-1.65 1.1-1.65 1.1-4t-1.1-4q-.2-.3-.4-.5l-.75.25z"
                    id="AccJew024__Symbol_18_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M2.55 0L.65.65q.85 1.5.85 3.6 0 2.35-1.1 4-.2.25-.4.55.45.2.95.2 1.3 0 2.2-1.4.9-1.45.9-3.45t-.9-3.4Q2.85.3 2.55 0z"
                    id="AccJew024__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew024__Symbol_94_0_Layer0_0_FILL"
                transform="translate(131.65 228.65)"
                id="AccJew024__col1n"
            />
            <use
                xlinkHref="#AccJew024__Symbol_37_0_Layer0_0_FILL"
                transform="translate(187 267.95)"
                id="AccJew024__col2n"
            />
            <use
                xlinkHref="#AccJew024__Symbol_18_0_Layer0_0_FILL"
                transform="translate(190.3 267.95)"
                id="AccJew024__col2l"
            />
            <use
                xlinkHref="#AccJew024__Symbol_7_0_Layer0_0_FILL"
                transform="translate(193.1 269.35)"
                id="AccJew024__col2d"
            />
        </g></g>
    )
}

export default AccJew024
