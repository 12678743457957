import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFD446",
    "col2n": "#000000"
}

function AccJew020({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.7 1.4q.25-.25.2-.6-.05-.35-.3-.6T121 0q-.35.05-.6.3-20.15 24.2-50.7 34.4-1.15.4-7.25 2.3-.7-.2-1.5-.2-1 0-1.9.35-5.75-2.05-6.8-2.45Q21.65 24.5 1.5.3 1.25.05.95 0 .55-.05.3.2T0 .8q-.05.35.2.6Q20.65 26 51.7 36.3h-.05q.95.35 5.5 1.95-.8.7-1.5 1.7-1.1 1.6-1.65 3.55l-.55 4v.1l.55 4.05q.55 1.9 1.65 3.5.6.85 1.25 1.45l.5.6h.2q1.5 1.1 3.35 1.1 1.8 0 3.3-1h.1q.15-.2.35-.35.8-.75 1.55-1.8 1.1-1.6 1.65-3.5l.55-4.05v-.1l-.55-4q-.55-1.95-1.65-3.55-.6-.85-1.25-1.5l-.35-.35q4.6-1.45 5.6-1.8 31-10.3 51.45-34.9z"
                    id="AccJew020__Symbol_90_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M191.15 268.5q-.4.2-.75.55-.6.5-1.15 1.35-1.3 2.2-1.35 5.2v1.05q0 .25.05.5 0 .6.15 1.2.3 1.75 1.15 3.2 1.45 2.3 3.45 2.3t3.35-2.25l.05-.05q1.45-2.3 1.45-5.55v-.4q-.1-3-1.45-5.2-.75-1.25-1.7-1.8-.05-.05-.15-.1-1.45-.8-3.1 0z"
                    id="AccJew020__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.851}
                    d="M192.8 269.85q.25.3.55.85.2.45.7 1.9.2.85.2 1.25v1.05q.2 1 1 1 .7 0 .75-.7.05-.15.1-.35 0-.35-.05-.65-.15-1.5-.8-2.55-.85-1.3-2.45-1.9v.1z"
                    id="AccJew020__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew020__Symbol_90_0_Layer0_0_FILL"
                transform="translate(131.55 228.55)"
                id="AccJew020__col1n"
            />
            <use
                xlinkHref="#AccJew020__Symbol_33_0_Layer0_0_FILL"
                id="AccJew020__col2n"
            />
            <use xlinkHref="#AccJew020__Layer5_0_FILL" />
        </g></g>
    )
}

export default AccJew020
