import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho068({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M189.35 79.65l-.05-.25q-8.25-20.25-25.05-35-11.45-11.25-22.45-17.75-12.8-8.25-25.75-9.15-11.75-.7-26.05 5.6l.05-.3H90q.05-.1.05-.25 0-.05.05-.1 1.95-9.3 7.85-16.55 2.3-2.7 4.35-4.5.85-.75 1.7-1.35V0q-2.15.45-4.05 1.4-3.85 1.9-6.65 5.8-.45.65-.9 1.35-3.9 5.7-4.9 10.3-1.5-4.35-6.25-8.9-1.35-1.25-2.4-2.1-.95-.8-2.2-1.8Q72.9 3 71.95 3h-.05l5.7 6.55q1 1.05 1.9 2.2 3.65 4.6 4.65 10.35 0 .1.05.2 0 .2.05.4h-.05l.1.35q-2.65-.75-5.3-1.1-17.7-1.7-36.5 11.55-.2.15-.45.3-6.9 5.15-14 12.45l-.95 1q-.5.5-.95 1.05-11.8 13.45-19.6 36.5-1.5 3.8-2.65 7.7-2.7 9-3.65 18.6-.95 12.75 2.1 25.65.4 0 .8-.7.5-.95.9-3 .05-.85.15-1.65.1-1 .25-2t.35-1.9q.7-3.8 2-7.25 4.25-11.35 14.5-18.75.1-.15.3-.25 0-.05.05-.1Q30.2 95.5 36.7 89.2q.5-.55 1-1.05 4.35-4.2 8.95-6.25 1.8-.7 3.65-1.3 7.65-2.55 15.9-3.55 1.55-.3 3.3-.4l12-29.2q1.05 12.95 9.9 25.6.05.15.15.25 12.15 1.5 23.65-3.45l.6-.3 2.45 6.6 4.45 1.05q13.7 2.4 23 4.6 13.75 2 19.45 10.6 2.9 3.95 4.65 5.85 2.75 3 3.6 3.65 10.35 9.85 13.35 21.5 1.3 5.5.5 16.7-.4 7.5 2.15 4.4 4.5-10.7 5.55-25.45l.2-5.25v-.85q0-.5.05-1.05v-3.4l-.1-2.9q-.8-13.25-5.75-25.95z"
                    id="HaiSho068__Symbol_189_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M81.05 32.1Q82.3 44.5 91.3 56.15q-6.05-13.65-9-32.55-9.65 17.35-12.85 35.85l11.6-27.35m34.5 20.3l2.1 6.8q2.3.4 4.5.8l-3.4-9.2-3.2 1.6M84.7 9.45l-.7-3.9Q82.35 5 80 4.6q-9.05-1.4-18.15 1.45Q53.7 8.7 46.45 13 35.2 19.6 26.1 30.05 16.85 40.2 11.4 53.9q-5 11-7.6 20-1.05 3.4-1.7 6.5Q.5 87.15 0 93.95q10.85-44.2 31.75-65.15 5.35-5.15 11.4-9.2 6.65-4.5 14.1-7.6 16.1-6.2 27.45-2.55m94 33.1q-5.75-7.95-13.45-15Q143.85 7.4 124.4 1.4q-15.55-4.2-34.65 4.25L89.1 9.7q17.75-7.85 33.75-4.7l2.15.45h.05q29.45 8.9 57.05 46.3 12.85 21.1 12.8 44.9.2-8.1-.55-17.25-2.3-12.8-7.9-24.1-3.3-6.65-7.75-12.75z"
                    id="HaiSho068__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M55.5 4.5l.25-.25q-6.3 3.4-11.15 7.15-4.6 3.8-6.6 5.35 0 .25-.25.25v-1.5q.15-.25.25-.85.15-.65.25-.9V12.5H35.5q-7.55 1.45-10.1 3.4-1.3 1.15-2 1.8-1.3 1.15-3.15 2.05.1-1.2 1.1-3.8l-1.6 1.1q-1.65 1.1-8.15 7.45Q5.05 30.9 0 42q7.7-7.4 12.55-10.5 4.3-2.5 6.8-2.5.55 0 3.65.65 3.1.6 3.5.6 1.5 0 2.7-1.3 2.75-2.9 4.05-3.95.8-.65 2.45-.65 1.85.05 2.55-.35.75-.45 2.8-3.6 2.6-3.9 5.45-6.9 2.1-2.2 5.1-5.45 2.25-2.4 3.9-3.55m73.9 25.75q3.05 1.85 7.85 4.75h.25q-4.55-8.3-13.15-15.65-9.7-8.3-20.1-11.1v4q-.75-.6-3.4-2.05-2.8-1.5-3.6-1.95-1.7-1-3.05-.8-2 .35-2.2.3-3.05-.5-5.3-2.5-.05-.05-4.95-5.25H81q0 2.5-.4 4.6Q80 8 78.6 8q-1.25 0-1.75-.6-.65-1.1-1.6-2.15-1.15.5-1.6 1.4.45 1 1 2.2 4.15 9.35 4.9 12 .75 2.6.95 2.9 2.55-.7 7.35-3.5 4.25-2.5 5.4-2.5 3.3 0 6.25 8.75.65-.85 2-2.25 1.45-1.5 1.6-1.5 1.1 0 3 1.25.95.65 1.75 1.25.65 0 1.7-1.1.5-.6.95-1.15.05 0 3.35 2 3.9 2.35 6.65 3.75.75-1.5.75-2.25 2.5.35 8.15 3.75z"
                    id="HaiSho068__Symbol_49_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho068__Symbol_189_0_Layer0_0_FILL"
                transform="translate(95.8 34.6)"
                id="HaiSho068__col1n"
            />
            <use
                xlinkHref="#HaiSho068__Symbol_101_0_Layer0_0_FILL"
                transform="translate(96.05 51.75)"
                id="HaiSho068__col1d"
            />
            <use
                xlinkHref="#HaiSho068__Symbol_49_0_Layer0_0_FILL"
                transform="translate(120 64.25)"
                id="HaiSho068__col1l"
            />
        </g></g>
    )
}

export default HaiSho068
