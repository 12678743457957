import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525"
}
function HaiHig045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.15 47.9H30q-4.6.25-8.5 1.15-1.35.2-2.6.5-3 1-6 2.55-3.95 2.1-5.9 4.15-4.75 5-5.9 8.8-.45 1.4-.9 6-.05.55-.15 1-.05.6-.05 1.15 0 1.05.55 5.5.35 1 .75 1.9.2.35.45.7.4.65 1.4 2.4.3.45 1 .9-.35-1.15-.6-2.5-.65-8.15-.65-8.5 0-4.2 1.4-7.7.35-1.7.95-2.75 1-1.7 2.8-3.25 5.85-6.5 18.1-7.95v-.05h4.8q3.4.45 8.45.9.45-.4.45-1.05 0-.45-.25-.75-.25-2.5-6.45-3.1M91 12.2q-4.35-3.85-4.7-4.1-3-2.3-6.6-3.6L74.05 3q-4.45-.55-10.6 2.45-.4.15-.75.5Q61.85 6.6 61 7q-.9.9-3.15 1.55-2.85.85-5.25.5-.75 0-1.45-.1L45.5 7q-3.3-.7-7.5-4.2-1.9-1.55-2.8-2.8-.05.35-.05.75.55 2.1 2 3.55 2.2 2.2 3.35 2.9 1.1.65 6.15 2.85l5.75 1.55q3.15.5 6.8.1 3.6-.5 5.1-1.55 3.1-2.35 3.55-2.55 1.65-.75 4.05-.75.75-.05 1.6.05.55.1 1.2.25l3.35.9q.05 0 .1.05 1.1.3 2.95 1.75.25.25.55.5 2.7 1.8 5.5 4.55v.05q.05.15.15.25 1.45 1.5 2.1 2.4 1.45 2.05 2.65 4.45.6.5 1.1.65 2.25.6 2.75-1.15.15-.4.05-.8.05-.05.05-.1.2-.65-2.3-4.45-2.2-3.55-2.7-4m107.35 55.3h.05q1.05-.45 3.1-.45 5.35 0 8.9 5.85 2.5 4.1 3 8.25v.6l.1.6q.15.95.15 2.85.2 1.15.35 2.45.05.35.35 3.95.2 2.35.75 3.95 1.1 3.2 1.75 4 1.9 2.4 6.8 3.25h3.4q.65-.2 2.75-1.1h-4.3q-5.45-1.3-7.4-4.15-1.2-1.75-1.2-4.75 0-1.8.3-4.35t.3-6q0-2.35-.05-3.25-.15-1.8-.7-3.2-.1-.2-.95-2.45-.7-1.2-1.2-2.15-2.25-2.75-4.1-4.2-3.4-2.65-7.6-3.2H199q-3.75.65-5.85 1.9-2.25 1.35-2.25 3.2 0 .85 1.95 1.3h.05q.8-.2 3.35-1.65 1.3-.75 2.1-1.2v-.05m-34.1-51.05q.7-.1 1.5-.1 1.45 0 4.95.9.6.2 1.45.55 9.9 3.25 14.85 11.15 1.25 1.95 2.1 4.1-.3-1.4-.65-2.65-1.4-4.9-6-9.45Q177 15.6 169.05 13H164q-3 .5-5.6 1.35-1.85.75-3.6 1.95-2.2 1.45-3.3 2.9-4.25 5.4-4.25 11.65v.5q.7 2.55 3.1 2.55.25 0 .55-.05.3-.3 1.1-1.05v-6.45q.95-3.65 2.95-5.8 3.1-3.45 9.3-4.1z"
                    id="HaiHig045__Symbol_150_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig045__Symbol_150_0_Layer0_0_FILL"
                transform="translate(83.6 45)"
                id="HaiHig045__col1n"
            />
        </g></g>
    )
}
export default HaiHig045
