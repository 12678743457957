import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#3F0080",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35z"
                    id="AccGla006__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M135 40.6l.85-2.25q1.55-4.65 1.95-10.25l.1-16.95Q125.2 2.6 112.45.6q-6-.85-12.45-.5-7.1.5-13.9 2.85-4 1.35-7.85 3.35-4.05 1.6-5.95 4.1-.35.5-.7.9-.7 1.45-1.4 2.2-1.25.9-2.5 0-.7-.75-1.4-2.2-.35-.4-.7-.9-1.9-2.5-5.95-4.1Q49.15.9 37.9.1q-6.45-.35-12.45.5Q12.7 2.6 0 11.15l.05 2.2H0L.1 28.1q.4 5.6 1.95 10.25l.85 2.25q5.8 13.5 25.2 18.35 9 1.85 17.75-.3.9-.2 1.85-.45 12.25-4.05 18.5-16.85 2.35-4.65 2.8-9.5.4 4.85 2.9 9.5 6.05 12.8 18.3 16.85.95.25 1.85.45 8.75 2.15 17.75.3Q129.2 54.1 135 40.6z"
                    id="AccGla006__Symbol_76_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M165.45 15.8h24.35q2.35.35 3.05-1.15.4-1.15-.3-2.2-1.1-1-2.65-.9h-23.55q-1.15-.85-3.25-2.2-6.6-4.55-14.3-6.55-18-5.95-36.15.85-.15.05-.35.15-1.1.4-2.05.8l-1.1.4q-.5.15-1 .35l-.9.3q-3.1 1.1-5.8 1.75-4.7 1.05-8.8.25l-.3-.05-.7-.2q-2.75-.65-5.9-1.75l-.9-.3q-.5-.2-1-.35l-1.1-.4q-.95-.4-2.05-.8-.2-.1-.35-.15Q62.2-3.15 44.2 2.8q-7.7 2-14.3 6.55-2.1 1.35-3.25 2.2H3.1q-1.55-.1-2.65.9-.7 1.05-.3 2.2.7 1.5 3.05 1.15h24.35Q40.25 7.25 53 5.25q6-.85 12.45-.5 11.25.8 21.75 6.2 4.05 1.6 5.95 4.1.35.5.7.9.7 1.45 1.4 2.2 1.25.9 2.5 0 .7-.75 1.4-2.2.35-.4.7-.9 1.9-2.5 5.95-4.1 3.85-2 7.85-3.35 6.8-2.35 13.9-2.85 6.45-.35 12.45.5 12.75 2 25.45 10.55z"
                    id="AccGla006__Symbol_29_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla006__Symbol_97_0_Layer0_0_FILL"
                transform="translate(191.3 141.75)"
                id="AccGla006__col2n"
            />
            <use
                xlinkHref="#AccGla006__Symbol_76_0_Layer0_0_FILL"
                transform="translate(123.55 134.9)"
                id="AccGla006__col2l"
            />
            <use
                xlinkHref="#AccGla006__Symbol_29_0_Layer0_0_FILL"
                transform="translate(96 132.45)"
                id="AccGla006__col1n"
            />
        </g></g>
    )
}

export default AccGla006
