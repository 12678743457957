import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00"
}

function ObjMor073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M157 16.5q0-1.65-1.35-3.2-.35-.4-.8-.8-4.9-4.35-20.85-7.7Q111 0 78.5 0T23 4.8Q6.95 8.2 2.1 12.55l-.55.55Q0 14.75 0 16.5q0 6.85 23 11.65Q46 33 78.5 33t55.5-4.85q23-4.8 23-11.65m-6-.5q0 1.1-3.05 2.1l-1.85.5q-.15 0-.3.05-5.3 1.25-16.05 2.3Q108.55 23 78.5 23t-51.3-2.05q-7.25-.7-12.05-1.5l-5.55-1.2Q6 17.2 6 16q0-.1.05-.15.5-2.8 21.15-4.8Q48.45 9 78.5 9q30.05 0 51.25 2.05 18.95 1.85 21 4.35.25.3.25.6z"
                    id="ObjMor073__Symbol_164_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M154.1 13.3q-.35-.4-.8-.8-4.9-4.35-20.85-7.7-23-4.8-55.5-4.8t-55.5 4.8Q5.4 8.2.55 12.55L0 13.1q1.05 3.25 8.05 5.15-3.6-1.05-3.6-2.25 0-.1.05-.15.5-2.8 21.15-4.8Q46.9 9 76.95 9 107 9 128.2 11.05q18.95 1.85 21 4.35.25.3.25.6 0 1.1-3.05 2.1 6.5-1.85 7.7-4.8z"
                    id="ObjMor073__Symbol_92_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor073__Symbol_164_0_Layer0_0_FILL"
                transform="translate(114 9.3)"
                id="ObjMor073__col1n"
            />
            <use
                xlinkHref="#ObjMor073__Symbol_92_0_Layer0_0_FILL"
                transform="translate(115.55 9.3)"
                id="ObjMor073__col1d"
            />
        </g></g>
    )
}

export default ObjMor073
