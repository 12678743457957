import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFDE",
    "col2d": "#FFE000",
    "col2n": "#FFFF00"
}

function ObjToy027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.85 1.6Q46.45 0 42.2 0q-3.5 0-6.75 1.65-10.7 4.15-21.4 0Q10.8 0 7.35 0 3.1 0 1.65 1.6 0 3.45 0 9.15 0 17 8.5 20.85q6.45 2.9 15.95 2.95h.6q9.5-.05 15.95-2.95 8.5-3.85 8.5-11.7 0-5.7-1.65-7.55z"
                    id="ObjToy027__Symbol_116_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M83.5 25.75V0H66.45v25.75H69l-1.45 126.3Q66.8 172.7 52.6 183.7q-14 11.05-35.35 9.3-2.55 0-5-.15Q3.45 191.3.2 197.7q-.15.5-.2 1.05.1 4.65 7 6.1 16.1 3.9 31.4 0 2.3-.45 4.7-1 12.95-3.45 23-14.3 14.4-15.4 14.9-37V25.75h2.5M30.25 193.4q-.55 0-1.1-.05-.8 0-.7-.05l1.8.1z"
                    id="ObjToy027__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 4h12V0H0v4z"
                    id="ObjToy027__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy027__Symbol_116_0_Layer0_0_FILL"
                transform="translate(167.6 213.45)"
                id="ObjToy027__col1n"
            />
            <use
                xlinkHref="#ObjToy027__Symbol_31_0_Layer0_0_FILL"
                transform="translate(180.95 27.15)"
                id="ObjToy027__col2n"
            />
            <use
                xlinkHref="#ObjToy027__Symbol_5_0_Layer0_0_FILL"
                transform="translate(249.95 52.9)"
                id="ObjToy027__col2d"
            />
        </g></g>
    )
}

export default ObjToy027
