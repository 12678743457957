import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343",
    "col1s": "#121212",
    "col2d": "#EEAA00",
    "col2n": "#FFC400"
}

function ObjToy031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M63.35 42l-3.4-17.05-38.05 20.4-13.5 5.8Q1.65 53.5.1 58.45q-.4 5.5 1.15 12.2Q2.7 79.1 7.45 89.6q2.65 3.85 6.2 5.4 4.95 2.15 11.6 0 4.25-1.35 9.15-4.45l7.1-3.7v-.05l6-3.05q.35-.25.75-.45 1-.6 1.95-1.1l.05.05 2.85-1.45v.05L76.75 68.6H75.7q-2.15 0-4.1-.5-.65-.2-1.3-.45-.1-.05-.2-.05-.1-.05-.15-.1h-.05l-.2-.1q-.4-.15-.75-.35-.4-.2-.75-.4-.1-.05-.15-.1-.05-.05-.1-.05-1.55-.95-2.9-2.3-3.25-3.25-4.1-7.5l-.3-.1q-1.2-.55-2.15-1.55-2.3-2.25-2.3-5.45t2.3-5.45q1.65-1.65 3.85-2.1.15-.05 1-.05m66.05-5.3q9.5-12.2 4.3-23.8-1.25-3.75-1.7-4.25-.15-.2-.25-.25-.5-.8-1.15-1.55-1.45-2.1-3.15-3.55-6.2-5.1-16.3-2.35-4.25.95-8.35 2.35Q95 5.95 87.7 9.95l-9.5 5.1q4.7 13.2 7.45 27.3.05 0 .05.05.15.1.35.25l.05.05v.05q.1.1.25.2.2.25.45.5l.1.1q.1.15.25.3 3.6 4.15 3.65 9.75 0 2.6-.8 4.9-.85 2.5-2.6 4.6l16.35-8.5v-.05l6-3.05q.35-.25.7-.45 1-.55 1.95-1.1 1.45-.85 2.75-1.65 10.6-6.45 13.95-11.25l.3-.35z"
                    id="ObjToy031__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M10.75 44.5q1.4 1.55 2.95.2 1.5-1.5.2-2.95-1.35-1.55-2.9-.15-.45.35-.65.95-.4 1.05.4 1.95m-8 1.1q-.4 1.05.45 2 1.35 1.55 2.95.15 1.5-1.45.2-2.95-1.4-1.55-2.95-.2-.45.4-.65 1M.8 35.9q-.05.05-.65.95-.4 1.05.45 2 1.35 1.5 2.95.15 1.45-1.45.2-2.9-1.4-1.6-2.95-.2m9.95.05q1.4-1.55.15-2.95-1.4-1.55-2.9-.2-.45.35-.65.95-.4 1.1.45 2.05 1.35 1.55 2.95.15m52.4-20.05q1.5 1.45 3-.05 1.35-1.6-.1-3-1.5-1.45-2.9 0-.4.45-.6 1.05-.3 1.1.6 2M66.8 3.75q1.5 1.45 2.95-.05 1.3-1.7 0-2.95Q68.2-.7 66.8.75q-.4.4-.6 1-.3 1.1.6 2m-4 3.55q1.4-1.6 0-2.95-1.5-1.45-2.95.05-.05 0-.55 1-.3 1.1.6 1.95 1.45 1.4 2.9-.05m10.6 4.85q1.4-1.55-.05-2.95-1.45-1.4-2.9.1-.4.35-.55.95-.3 1.1.55 1.95 1.5 1.45 2.95-.05z"
                    id="ObjToy031__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M6.2 82.75q2.65 3.85 6.2 5.4 4.95 2.15 11.6 0 4.25-1.35 9.15-4.45l7.1-3.7v-.05l6-3.05q.35-.25.75-.45 1-.6 1.95-1.1l.05.05 2.85-1.45V74L75.5 61.75h-1.05q-2.15 0-4.1-.5L32.6 80.75q-18.35 11.55-27-.9Q1.65 71.1 0 63.8q1.45 8.45 6.2 18.95m126.25-73.4q.1 1.35 0 2.75-.4 6.1-4.55 12.25-2.75 6.1-15.6 14.6-1.35.85-2.8 1.75l-1.6 1-19.15 9.95q-.85 2.5-2.6 4.6l16.35-8.5v-.05l6-3.05q.35-.25.7-.45 1-.55 1.95-1.1 1.45-.85 2.75-1.65Q124.5 35 127.85 30.2l.3-.35q9.5-12.2 4.3-23.8-1.25-3.75-1.7-4.25-.15-.2-.25-.25-.5-.8-1.15-1.55.2.25.4.55.05.1.15.25l.3.6q.45.95.85 1.95.95 2.5 1.3 5.05.05.45.1.95z"
                    id="ObjToy031__Symbol_57_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M12.95 35.05q-.7-1.4-1.35-2.5v-.05l-2.85 1.45-.05-.05q-.95.5-1.95 1.1-.4.2-.75.45L0 38.5v.05q.15.55 1.05 2.1.85 1.6 2.1 3.4 1.15 1.7 1.65 2.2l.05.05q.2.2.45.4 1.25 1.05 3.15.95 1.65-.05 3.15-.95.5-.35 1.05-.8 1.9-1.7 2.3-3.95.15-1 .05-1.8l-.05.05q-.1-.45-.3-1.05-.5-1.5-1.7-4.1M76.9 6.95q-.5-1.55-1.75-4.15-.75-1.55-1.5-2.8-1.3.8-2.75 1.65-.95.55-1.95 1.1-.35.2-.7.45l-6 3.05v.05q.15.5 1 2.15.9 1.6 2.1 3.4 1.2 1.7 1.7 2.15v.05q1.35 1.45 3.6 1.4 2.3-.1 4.2-1.75 1.9-1.7 2.35-3.95.15-1 0-1.8h-.05q-.1-.45-.25-1z"
                    id="ObjToy031__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M12.95 35.05q-.7-1.4-1.35-2.5v-.05l-2.85 1.45-.05-.05q-.95.5-1.95 1.1-.4.2-.75.45L0 38.5v.05q.15.55 1.05 2.1.85 1.6 2.1 3.4 1.15 1.7 1.65 2.2-1.35-1.55-.95-3.8.35-2.3 2.3-3.95 1.9-1.7 4.2-1.8 2.3-.1 3.65 1.45.4.45.65 1-.5-1.5-1.7-4.1M76.2 5.9q.4.45.7 1.05-.5-1.55-1.75-4.15-.75-1.55-1.5-2.8-1.3.8-2.75 1.65-.95.55-1.95 1.1-.35.2-.7.45l-6 3.05v.05q.15.5 1 2.15.9 1.6 2.1 3.4 1.2 1.7 1.7 2.15-1.4-1.55-1-3.8.4-2.25 2.3-3.95 1.9-1.7 4.2-1.75 2.3-.15 3.65 1.4z"
                    id="ObjToy031__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M304.9 299.35q-.3-.35-.6-.5-1.35-.8-3.45 1.05-.5.4-.75 1-.3.65-.2 1.15.05.25.2.45.05.1.15.2 1.45 1.6 3.9-.5 1.55-1.25 1.05-2.4-.1-.25-.3-.45m-67 33.8q-.35.6-.2 1.15.05.2.2.4.05.1.15.2 1.45 1.65 3.85-.45 1.6-1.25 1.1-2.4-.15-.25-.35-.5-.25-.3-.55-.45-1.4-.8-3.5 1.05-.45.4-.7 1z"
                    id="ObjToy031__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy031__Symbol_120_0_Layer0_0_FILL"
                transform="translate(189.2 242)"
                id="ObjToy031__col1n"
            />
            <use
                xlinkHref="#ObjToy031__Symbol_89_0_Layer0_0_FILL"
                transform="translate(219.55 260.9)"
                id="ObjToy031__col1l"
            />
            <use
                xlinkHref="#ObjToy031__Symbol_57_0_Layer0_0_FILL"
                transform="translate(190.45 248.85)"
                id="ObjToy031__col1s"
            />
            <use
                xlinkHref="#ObjToy031__Symbol_33_0_Layer0_0_FILL"
                transform="translate(230.7 290.3)"
                id="ObjToy031__col2n"
            />
            <use
                xlinkHref="#ObjToy031__Symbol_6_0_Layer0_0_FILL"
                transform="translate(230.7 290.3)"
                id="ObjToy031__col2d"
            />
            <use xlinkHref="#ObjToy031__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToy031
