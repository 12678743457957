import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}

function FacMou018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M202.5 232.4q7.167-1.213 7.1-7.2v-.25q-.044-2.265-1.65-4.55-1.602-2.3-3.75-2.75h-.05q-1.597-.3-3.6-.5-.375-.05-.8-.1-.013-.003-.05 0-2.467-.2-3.95-.25h-.2q-.598-.049-1.1-.05-.725-.052-1.4 0H192.4q-2.399.099-5.15.4-1.577.211-2.95.5-2.806.588-4.75 1.6-2.862 1.452-3.1 5.65-.052.512-.05 1 .024 4.84 4.35 6 1.101.316 3.4.5 9.825.797 18.35 0z"
                    id="FacMou018__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M7.1 12.95q2.75.2 7.15.25V7.3H7.1v5.65M.1 6.5h6.2l-.05-5.45q-2.25.5-3.8 1.4Q.35 3.5.1 6.5m6.2.8H.05Q0 7.55 0 7.8q0 3.75 3.35 4.7.95.25 2.95.4V7.3m7.95-.8V.1q-2.2.1-4.6.35-1.4.15-2.6.45l.05 5.6h7.15m8 6.65l.05-5.85h-7.25v5.9h1.25q.35 0 1.35.05 2.05 0 4.6-.1m.8-.05l.8-.1q6.35-.85 6.55-5.7h-7.3l-.05 5.8M21.85.35h-.05Q19.4.15 17.95.1h-.3Q17.1.05 16.6.05h-.05Q15.9 0 15.3.05h-.25V6.5h7.25V.4q-.2 0-.45-.05m4.3.6Q24.75.7 23.1.5v6h7.3q-.2-1.7-1.35-3.4-1.3-1.8-2.95-2.15h.05z"
                    id="FacMou018__Symbol_43_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou018__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou018__Symbol_43_0_Layer0_0_FILL"
                transform="translate(177.8 218.1)"
                id="FacMou018__col1n"
            />
        </g></g>
    )
}

export default FacMou018
