import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.85 8.5q.15-.25.35-.55h-.05q-3.85-.25-5.5-4.65-.65-1.6-.8-3.3l-.1.3q-.15.3-.2.75-.7 2.35.35 4.15-.4.25-2.9.95-2.55.65-3.35.9-.8.25-5.15.85-4.35.6-9.25.05-4.9-.6-5.15-.65-.55-.1-1.1-.25 1.65 1.1 3.15 1.7 9.6 2.8 19.05 0 1.7-.35 3.7-.95 1.5-.45 2.1-.65.6.85 1.45 1.35 2.3 1.4 3.4 0z"
                    id="FacMou008__Symbol_33_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou008__Symbol_33_0_Layer0_0_FILL"
                transform="translate(175.95 217)"
                id="FacMou008__col1n"
            />
        </g></g>
    )
}

export default FacMou008
