import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF",
    "col1n": "#EEEEEE",
    "col2l": "#E1E8EE",
    "col2n": "#C1D2E2"
}

function AccJew031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.95 5.85q-.3-.05-.55-.1-.55 0-1.05.2V6L16.4 3.1q-.05-.1-.05-.2-.25-1-1-1.8l-.3-.2Q14 .05 12.65 0 11.4-.05 10.4.65l-.4.3q-.5.5-.8 1.15l-.05.1-5.6 2.15q-.2-.15-.5-.2-.15-.05-.45-.05-.65-.1-1.2.25-.4.1-.65.35Q0 5.45 0 6.55q-.05 1 .5 1.7.05.05.15.05.7.85 1.75.9 1.05 0 1.85-.7.7-.65.75-1.6l-.05-.05 4.55-.9q0 .05.05.1 0 .1.05.15.1.05.15.2 1.1 1.15 2.65 1.2 1.6.05 2.75-1l.05-.05.1-.1 4.45 1.7q0 1.05.65 1.85.75.8 1.8.8.35.05.6 0 .75-.15 1.3-.7.45-.5.7-1.15.1-.25.05-.6.05-1.05-.65-1.8-.3-.3-.6-.5-.35-.15-.65-.2z"
                    id="AccJew031__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M.3 4.45q-.3.4-.3.95 0 .55.3.9.45.35 1 .35.5 0 .9-.35.35-.35.35-.9t-.35-.95q-.4-.35-.9-.35-.55 0-1 .35m9.9-2.9q0 .65.4 1.05.5.45 1.15.45.6 0 1.05-.45.4-.4.4-1.05t-.4-1.1Q12.35 0 11.75 0q-.65 0-1.15.45-.4.45-.4 1.1m12.3 4.5q-.4-.35-.9-.35-.55 0-1 .35-.3.4-.3.95 0 .55.3.9.45.35 1 .35.5 0 .9-.35.35-.35.35-.9t-.35-.95z"
                    id="AccJew031__Symbol_56_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.65 1.05Q5.6 1 5.55 1L5.4.85Q4.65.15 3.7.1L3.4 0q-.35 0-.6.05-.55.1-.9.3-.25.1-.45.3-.2.1-.3.25-.05.05-.1.15Q.05 1.9 0 3.1v.2q0 1.1.95 2.1.3.25.5.45.75.5 1.75.55 1.25.05 2.25-.8.3-.35.55-.6-.8-1.1-.75-2.45 0-.7.3-1.3.05-.15.1-.2m12.15 3.4q.05-1.3-.75-2.35l-.1-.1q-.1 0-.15-.05-.35-.35-.7-.5-.6-.25-1.3-.3-.35-.05-.65.05-.5.05-.85.3-.25.05-.4.25l-.1.1q.05.45.05 1.05-.05 1.5-1.2 2.6.3.55.7.95.95 1.05 2.25 1.1.15-.05.35-.05.65 0 1.25-.4.4-.2.65-.45.4-.35.6-.7.3-.5.35-1.05v-.45z"
                    id="AccJew031__Symbol_40_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M0 1.5q0 .65.35 1.05.5.4 1.15.4.6 0 1-.4t.4-1.05q0-.65-.4-1.05Q2.1 0 1.5 0 .85 0 .35.45 0 .85 0 1.5m11.95 1.15q0 .65.35 1.05.5.4 1.15.4.6 0 1-.4t.4-1.05q0-.65-.4-1.05-.4-.45-1-.45-.65 0-1.15.45-.35.4-.35 1.05z"
                    id="AccJew031__Symbol_19_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew031__Symbol_100_0_Layer0_0_FILL"
                transform="translate(109.35 272.75)"
                id="AccJew031__col1n"
            />
            <use
                xlinkHref="#AccJew031__Symbol_56_0_Layer0_0_FILL"
                transform="translate(110.3 273.65)"
                id="AccJew031__col1l"
            />
            <use
                xlinkHref="#AccJew031__Symbol_40_0_Layer0_0_FILL"
                transform="translate(112.9 273.8)"
                id="AccJew031__col2n"
            />
            <use
                xlinkHref="#AccJew031__Symbol_19_0_Layer0_0_FILL"
                transform="translate(114.45 274.9)"
                id="AccJew031__col2l"
            />
        </g></g>
    )
}

export default AccJew031
