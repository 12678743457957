import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#001500",
    "col1n": "#002A00",
    "col1s": "#000000",
    "col2n": "#FFFFFF",
    "col2s": "#C2C2C2"
}

function ShiLon098({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-13.7 9.5-29.7 13.45-6.8 2.3-13.45 3.4-11.35 1.8-22.15.05-.25-.05-.45-.05l-2.5-.5q-5.4-1.15-10.55-3.1-14.65-3.8-27.4-12.2-.3-.2-.55-.4h-.05q-.45-.35-.9-.65L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.2q7.65-.3 12.15 4.2 0-.05.05-.05.2.15.4.35l.05.05v.15l-.05.05-2.95 12.15h.05l-2.6 10.7q-.05.1-.1.45-.1.25-.15.7l-2.6 10.75h.2q.4.05.75.1 2.1.15 4.1.35l2.2.2q33.95 2.8 48.7 1.25 8.7-.9 10.7-3.35.55-.45 1-1.25.2-.5.3-.65v-.05h.05q.05-.05.05 0h2.1V89q.75 1.35 1.3 1.8 2 2.45 10.7 3.35 14.7 1.55 48.5-1.25l2.2-.2q2.15-.2 4.35-.35l.8-.1-8.2-34.7-.25-.1q4.55-5.05 12.65-4.7l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5m-12.55-32.5l.05.05q.05.05.05.1h-.05l-.05-.15m-47.1 17.5H103l.2-.05-.15.05m43.75 51.8v.05h-.25q.1-.05.25-.05M39.25 21.1l.2-.35-.2.4v-.05z"
                    id="ShiLon098__Symbol_538_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.55 41.35q-.1-1.55-.75-3.65-.4-1.25-.5-2.2L8.75 56.6l-.35.3-.05.05-2.95 12.2h.05l-2.6 10.7q-.05.1-.1.45-.1.25-.15.7L0 91.75h.2q.4.05.75.1 2.1.15 4.1.35.1-.7.2-1.6.25-2.65.6-3.85 1.15-3.8 4.2-7.4 1.45-1.8 2.95-2.95 2.1-1.65 2.3-1.8 1.5-1.45 3.8-5 1.85-3.5 1.85-5.9 0-3.9-2.15-6.75-2.65-3.5-3.65-8.7-.35-1.55-.6-6.9m108.8 0q-.25 5.35-.6 6.9-1 5.2-3.65 8.7-2.15 2.85-2.15 6.75 0 2.4 1.85 5.9 2.3 3.55 3.8 5 .2.15 2.3 1.8 1.5 1.15 2.95 2.95 3.05 3.6 4.2 7.4.35 1.2.6 3.85.1.9.2 1.6 2.15-.2 4.35-.35l.8-.1-8.25-34.8-.8-.3-4.35-21.15q-.1.95-.5 2.2-.65 2.1-.75 3.65m9.75 39.9q.1-.05.25-.05v.05h-.25M127.35.85q.4-.4.85-.8l-5.4 4.05q-1.15.8-2.35 1.6-11.35 7.35-24.25 11.05-1.05.25-2.05.55h-.1q-7.25 2.55-14.3 3.7-11.35 1.8-22.15.05-.25-.05-.45-.05l-2.5-.5Q48.7 19.25 43 16.95h-.1q-1-.3-1.95-.55-13.7-4.1-25.65-12.35L9.9 0q.45.4.9.85 1.5 1.4 3.1 2.75 11.75 10 25.75 14.95-.05.05-.05.15 1.05.15 2 1 4.9 1.7 5.95 1.95 1 .25 1.85.45.4-.35.9-.6 3.25.65 6.6 1.1-.15.45-.35.9 2.4.35 5 .55-.35-.5-.8-.95l-.1-.1q4.1.35 8.4.35 5.6 0 10.95-.6l.2.5q.6-.1 1.25-.1h.3q3.15-.65 8.15-1.75.5-.15 4.6-1.4 1.75-.45 3.05-.75-.05-.2-.1-.35 12.5-4.2 23.25-12.4 3.4-2.65 6.65-5.65M16.7 5.2q-.3-.2-.55-.4-.15-.1-.25-.2.4.3.8.55v.05m120 6.65q-.25.2-1.35 1.55-1.8 2.15-6.55 6.2-4.8 4.05-10 8.55-5.2 4.45-10.5 6.35-4.7 1.6-10.25 2.95-12.35 1.05-20.9 10.5-4.35 5.7-7.9 11.4-3.6-6.9-9.3-12.75-6.35-7.55-16.2-8.5l-1.9-.35q-5.7-.55-11.1-2.95-5.55-2.4-11.5-6.45-5.95-4.1-10.1-8.5Q5 15.45 4.1 14.8q-.95-.7-1.45-1.3-.2-.25-.35-.5l-.7-.9q-.05 0-.05-.05l-.1-.1-.05-.05-.05.15H1.3q0-.05.05-.1l.05-.05v-.05q-.05-.05-.15-.2 1.2 13.9 19.9 23.95 8.05 3.5 17.95 5.3.5 0 .95.1 15.6 1.75 25.15 14.75l.9 1.3.25.4q.35.5.75 1.1 0 .05.05.1h.1l1.85 2.7 1.85-2.7H71q0-.05.05-.1.6-.95 1.25-1.85Q81.9 42.8 98.05 41q.45-.1.95-.1 10.4-1.85 18.8-5.65 17.9-9.9 19.05-23.6-.15.15-.15.2m.05.1q.05.05.05.1h-.05v-.1z"
                    id="ShiLon098__Symbol_500_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M12.55 24.7q0-.25-.1-.4-.1-.2-.3-.2-.2-.05-.3 0-.05 0-.05.05-.05 0-.1.05t-.1.2q-.05.05-.05.1L7.3 46.2q.4.7.85.15l.1-.3v-.15h.05l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85l3.25-16.8.05-.05m111.25-.5q0-.05-.05-.05-.1-.05-.3 0-.2 0-.3.2-.1.15-.1.4l.05.05 3.25 16.8q.05.1.2.85.05.1.05.25v.05q.2.6.4 1.55.15.65.35 1.55v.05l-.05.05h.05v.15l.1.3q.45.55.85-.15l-4.25-21.7v-.05l-.05-.05q-.05-.15-.1-.2-.05-.05-.1-.05M.15 1.25L.1 1.3q-.05.05-.05.1H.1l.05-.15m135.3-.05q.05-.05.15-.2-.3-.5-.55-1l-2.25 2.9-2.1 2.45q-.65.85-3.1 3.4-.4.45-.85.95-4 4.45-9.25 8.05-3.05 2.05-6.5 3.85l-1.2.6q-1.95.95-4 1.8-5.3 2.2-13.55 3.3-8.2 1.1-13.5 6.35-2.9 2.85-5.45 6.05-2.55 3.15-4.6 6.65l-.65 1.05q-.05 0-.25.1-.168-.126-.25-.1l.1.15-.75-1.2q-2.05-3.5-4.6-6.65-2.55-3.2-5.45-6.05-5.3-5.25-13.5-6.35Q35.1 26.2 29.8 24q-2.05-.85-4-1.8l-1.2-.6q-3.45-1.8-6.5-3.85-5.25-3.6-9.25-8.05-.45-.5-.85-.95-2.45-2.55-3.1-3.4L2.8 2.9.55 0Q.3.5 0 1q.1.15.15.2v.05l.05.05.1.1q0 .05.05.05.05.1.15.2.25.35.55.7.15.25.35.5l1 1.2L4 5.95q6.7 7.75 14.1 12.85.35.2.7.45 1.9 1.25 3.85 2.35.4.25.8.45 2 1.1 4.05 2 6.2 2.7 12.8 3.75h.05q.15 0 .3.05 10 1.1 15.65 6.75 3.75 3.7 5.45 6 .85 1.1 1.2 1.6.05.1.1.15.1.2.15.25l3.6 5.55q.2.4.35.6h.05V77.8h1.2V48.75h.05q.05-.2.35-.6l3.6-5.55q.05-.05.15-.25.05-.05.1-.15.35-.5 1.2-1.6 1.7-2.3 5.45-6 5.65-5.65 15.65-6.75.15-.05.3-.05h.05q6.6-1.05 12.8-3.75 2.05-.9 4.05-2 .4-.2.8-.45 1.95-1.1 3.85-2.35.35-.25.7-.45 7.4-5.1 14.1-12.85l1.6-1.9 1-1.2q.2-.25.35-.5.3-.35.55-.7.1-.1.15-.2.05 0 .05-.05l.1-.1.05-.05V1.2m0 .05l.05.15h.05q0-.05-.05-.1l-.05-.05z"
                    id="ShiLon098__Symbol_467_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M53.8 4.2q0-2.2-1.15-3.85Q44.4 2.5 35.5 3.15l-5.85.2H27.5l-1.75-.05v.25h-.05q.048-.144.05-.25l-6.15-.2Q10.1 2.35 1.4 0 1 .5.75 1.05 0 2.45 0 4.2q0 2.45 1.4 4.3.25.3.6.65 2.05 2.05 4.95 2.05h.2q-.3.55-.45 1.2-.25.9-.25 1.9 0 1.6.65 2.95.15.35.4.75.05.1.15.25.35.5.85 1 1.05 1.05 2.35 1.6.95.35 2.05.45h1.15q.25-.05.55-.05.85-.15 1.65-.45.35 1.55 1.55 2.75 1.75 1.75 4.25 1.75h.3q.9-.05 1.7-.3-.15.5-.15 1.1 0 1.4.75 2.45.2.3.5.6 1.25 1.25 3 1.25h.05q1.8 0 3.05-1.25.3-.3.55-.6.75-1.05.75-2.45v-.55q-.05-.15-.05-.3.4.05.85.05h.3q2.3-.1 3.95-1.75.9-.9 1.4-2.05.05-.15.1-.25h1.05q1.45-.15 2.65-.8.8-.5 1.5-1.2 2.05-2.05 2.05-4.95 0-1.55-.5-2.8l-.2-.4q.55.1 1.15.1h.5q1.6-.1 2.95-.85.8-.5 1.5-1.2l.2-.2q1.85-2 1.85-4.75z"
                    id="ShiLon098__Symbol_441_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M20.3 11q.9-.55 2.5-.55 1.65 0 2.95.7.85.45 2.3 1.8.05.05.15.1h.2q.25 0 .35-.15l.15-.15v-.2q0-.25-.2-.35-1.5-1.4-2.45-1.9l-.05-.05q-1.45-.8-3.4-.8-2 0-3.05.7-.85.55-1.35 1.7-.45-1.2-1.35-1.7-1.05-.7-3.05-.7-1.95 0-3.4.8l-.05.05q-.95.5-2.45 1.9-.2.1-.2.35 0 .2.15.35.1.15.35.15.2 0 .35-.1 1.45-1.35 2.3-1.8 1.3-.7 2.95-.7 1.6 0 2.5.55.85.55 1.25 1.95 0 .1.15.25.1.1.25.1h.5q.15 0 .25-.1.15-.15.15-.25.4-1.4 1.25-1.95m15.6-6.7q.15.15.35.15.2 0 .35-.1.2-.15.2-.35 0-.2-.15-.35-.1-.2-.25-.35-.7-.8-1.5-1.35-.95-.75-1.95-1.15-.3-.15-.6-.25-1.25-.5-2.65-.5-3.25 0-4.9 3.3-.5-.8-2.25-2Q21.8.8 21.05.5q-.85-.35-1.7-.4h.35l-.2-.05q0 .05-.75 0Q18-.05 17.3.1q-.85.1-1.6.4-.9.35-1.65.95Q12.4 2.7 12 3.35 10.35.05 7.1.05q-1.4 0-2.65.5-.3.1-.6.25-1 .4-1.95 1.15Q1.1 2.5.4 3.3q-.15.15-.25.35Q0 3.8 0 4q0 .2.2.35.15.1.35.1.2 0 .35-.15.75-.95 1.6-1.55.75-.6 1.55-.95.35-.2.75-.3 1.1-.45 2.3-.45 2.85.05 4.2 3.15.1.2.5.4.35.2.65-.05.25-.3 1.95-1.85 1.65-1.55 3.65-1.65.15-.05 1.4.05 1.25.05 3.2 1.75 1.9 1.65 2.05 1.8.1.15.45-.05.3-.2.35-.4 1.35-3.1 4.2-3.15 1.2 0 2.3.45.4.1.75.3.5.2 1 .55.3.2.55.4.7.5 1.35 1.25.1.15.25.3z"
                    id="ShiLon098__Symbol_388_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon098__Symbol_538_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiLon098__col1n"
            />
            <use
                xlinkHref="#ShiLon098__Symbol_500_0_Layer0_0_FILL"
                transform="translate(123.45 229.15)"
                id="ShiLon098__col1d"
            />
            <use
                xlinkHref="#ShiLon098__Symbol_467_0_Layer0_0_FILL"
                transform="translate(124.7 239.8)"
                id="ShiLon098__col1s"
            />
            <use
                xlinkHref="#ShiLon098__Symbol_441_0_Layer0_0_FILL"
                transform="translate(164.95 246.1)"
                id="ShiLon098__col2n"
            />
            <use
                xlinkHref="#ShiLon098__Symbol_388_0_Layer0_0_FILL"
                transform="translate(173.45 252.75)"
                id="ShiLon098__col2s"
            />
        </g></g>
    )
}

export default ShiLon098
