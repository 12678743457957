import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#F7DD00",
    "col2n": "#F7F7F7"
}

function ObjToy011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M20.25 9.55q.05.35 0 .8-.45 1.7-.4 2.1l-.35 1.05-.1.4q-.15.25-.3.55-1.2 1.1-4.25 4.15-1.25.65-2.55.65-1.95 0-2.85-.95-.7-.8-1.75-3.65-.4-1.05-1.6-2.75-1.6-2.15-2.55-2.15-1.75 0-2.8 2.4-.7 1.6-.75 2.7v.1q0 .25.1.45.05.05.1.15 0 .05.05.1.05 0 .1.05 0 .25.05.5.4 1.1 1 2.15.05.05.25.1-.3-.4-.5-1.05-.3-1.5-.3-1.55 0-.55.75-2.4.85-2.2 1.6-2.2.2 0 .4.15.45.3 1.85 2.45.7 1.1 1.2 2.5 1.45 4.5 5.65 4.5 3.95 0 7.6-4.8.35-.35 1.55-2.75.7-1.75.7-2.8 0-2.95-3.85-4.4-2.8-.85-4.1-1.3-1.4-.7-2.05-1.15-.35-.45-.35-1 0-.15.05-.25.25-.25.75-.55.9-.3 2.85-.25 2.9.1 3.5-.45.1-.1.1-.25l-.5-.35Q17.6.3 17 .05h-.5Q15.85 0 15.1 0q-4.9 0-4.9 2.55 0 2.85 4.1 4 3.35.85 4.3 1.5 1.2.8 1.65 1.5z"
                    id="ObjToy011__Symbol_23_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToy011__Symbol_105_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M.45 16.25H.5q-.048-.048-.05-.1v.1z" />
                    <path d="M21.6 21.6q3.7-3.75 3.7-8.95 0-5.25-3.7-8.95-1.15-1.15-2.4-1.95.1-.1.1-.25l-.5-.35q-.95-.25-1.55-.5h-.5Q14.8 0 12.65 0 7.4-.05 3.7 3.7 0 7.4 0 12.65q-.05 1.45.25 2.8v.1q0 .25.1.45.05.05.1.15.002.052.05.1H.45q.1.25.2.55.4 1.1 1 2.15l.1.2q.8 1.25 1.95 2.45 3.7 3.7 8.95 3.7 5.2 0 8.95-3.7z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy011__Symbol_105_0_Layer0_0_FILL"
                transform="translate(247.2 351.8)"
                id="ObjToy011__col1n"
            />
            <use
                xlinkHref="#ObjToy011__Symbol_23_0_Layer0_0_FILL"
                transform="translate(247.45 352.4)"
                id="ObjToy011__col2n"
            />
        </g></g>
    )
}

export default ObjToy011
