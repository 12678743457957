import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M131.7 48.8q-1.65-5.6-5.45-14.7-3.85-9.1-12.6-17.7l4.7 18.55-15.2-23.7q-.8 1.7-2.5 15.8-1.1-5.2-9.3-20.4-.2 4.1-2.65 19.7-.8-6.5-4.1-12.95Q81.3 6.9 79 0q-1.3 7.75-4.45 15.65-3.2 7.85-3.95 8.45-3.35-9.8-7.1-23.3-1.95 8.85-5.7 16.6-3.85 7.85-4.9 9.2h-.45q.2-2.7-.15-7.5-.4-5.45-.7-11.2-.85 4.7-6.2 13.5-5.05 8.3-6.2 9.4V13q-.75 1.55-6.15 9.05-5.55 7.75-7.1 12.9V19q-6.1 5.3-16.8 27.75Q2.35 67.4.2 79.45q-.1.65-.2 1.25 1.35-2.6 3.7-5.65 7.9-10.35 9.45-14.65v19.2h.4q3.3-6.85 5.15-10.8 3.45-7.25 3.85-8.85l5.6 20.15q2.75-6.45 8-20.7 1.3 3.85 3.1 8.1 2.95 7 4.05 12.05 2.95-9.8 6.5-18.35l.9-2.9q4.65 16.75 7.75 20.95.9-6.15 2.35-11.3 1.45-5.15 2.85-9.75 5.9 16.3 6.85 21.45l-.15.7q1.7-6.15 3.35-14.65 1.6-8.55 2.65-5.3 1.05 3.3 3.05 9 2 5.7 3.4 9.8l4.95-19.45q-.05 1.55 2.3 6.65 2.9 6.25 5.6 12.6 2.3-9.6 4.8-19.6.2 1.25 2.5 5.6 3.1 5.95 6.75 14.15l2.75-20Q121.8 77.4 124.8 78.6V60.4q.55 4.1 11.85 18.85.45-3.5-.6-9.55-2.7-15.3-4.35-20.9z"
                    id="HaiHig002__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <g id="HaiHig002__col1n" transform="translate(124.1 57.7)">
                <use
                    xlinkHref="#HaiHig002__Symbol_2_0_Layer0_0_FILL"
                    id="HaiHig002__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default HaiHig002
