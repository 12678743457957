import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#775C31",
    "col1n": "#A37831"
}

function AccHat113({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M306.05 81.8q2.1-.25 2.2-1-.2-.75-2.2-1.9L157.85.35q-1.25-.65-2.5 0L1.7 78.85Q.15 80 0 80.8q.05.7 1.7.95 158.15 11.95 304.35.05z"
                    id="AccHat113__Symbol_459_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M306.05.85L304.5 0Q154.7 11.05 3.3 0L1.7.8q-3.4 2.55 0 3.1 158.15 11.95 304.35.05 4.35-.55 0-3.1z"
                    id="AccHat113__Symbol_402_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat113__Symbol_459_0_Layer0_0_FILL"
                transform="translate(36.35 39.55)"
                id="AccHat113__col1n"
            />
            <use
                xlinkHref="#AccHat113__Symbol_402_0_Layer0_0_FILL"
                transform="translate(36.35 117.6)"
                id="AccHat113__col1d"
            />
        </g></g>
    )
}

export default AccHat113
