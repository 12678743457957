import { AssetSvgProps } from "../../../shared/assets"

function PlaOut037({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="PlaOut037__Layer8_0_FILL">
                    <path
                        fill="#75696B"
                        d="M50.45 98.15H65.9V0H.25v98.15h50.2V5v93.15z"
                    />
                    <path
                        fill="#FFF"
                        d="M385 385V0H65.9v98.15H50.45v5.15l57 32.75-57-32.75v5.35-5.35l-5-2.85 5 2.85v-5.15H.25v7H0V385h385z"
                    />
                </g>
                <g id="PlaOut037__Layer7_0_FILL">
                    <path fill="#3E3A48" d="M385 385v-84.3H0V385h385z" />
                    <path
                        fill="#616A59"
                        d="M273.85 136.05V120.3q59.482-81.334 0-120.3H.25v105.15H0v18.65l82 17.1v-.05h25.45v-4.8h166.4z"
                    />
                    <path
                        fill="#75696B"
                        d="M344.4 237.8q21.349 5.114 40.6 10.45V219.3q-14.547-2.432-34.4-7.1-37.64-8.782-41.25-9.55-23.86-5.06-43.05-6.7-24.012-2.017-46.6.6-20.71 2.422-44.4-1.2-16.616-2.505-39.85-9.1-5.4-1.54-10.4-4.2Q85.005 162.587 82 140.9L0 123.8v43.55q3.883 4.52 8.85 9.45 6.948 6.81 22.95 20.55 8.408 7.125 23.9 8.8 8.749 1.02 25.65 1.5 11.519 1.104 28.1 1.1 9.345-.002 29.25 2.65 37.31 5.18 53.4 9.15 16.1 3.985 37.4 5.35 21.358 1.419 57.05 4 35.675 2.58 57.85 7.9z"
                    />
                </g>
                <g id="PlaOut037__Layer6_0_FILL">
                    <path
                        fill="#63392B"
                        d="M.25 91.6H56.6V0H.25v91.6M202.1 45.9V0H101.65v45.9H202.1z"
                    />
                    <path
                        fill="#4C3736"
                        d="M75.9 91.6H.25v13.55H0v35.7h107.45v-20.6H92.3v-15.1H75.9V91.6z"
                    />
                    <path
                        fill="#29563F"
                        d="M385 121.3V0H273.85v120.3q51.233 14.788 111.15 1z"
                    />
                </g>
                <g id="PlaOut037__Layer6_1_FILL">
                    <path
                        fill="#5F4D3F"
                        d="M45.2 7.35q-.068.097-.2.1H4.1q-.107-.003-.2-.1-.097-.093-.1-.25V0H.25v91.6h50.7V0H45.3v7.1q.022.157-.1.25m-.45 5.05v30.85H30.8V12.4h13.95m-.1-5.6V0H4.4v6.8h40.25z"
                    />
                    <path
                        fill="#666"
                        d="M44.75 43.25V12.4H30.8v30.85h13.95m-4-24.4q1.224 1.232 1.2 2.9.024 1.712-1.2 2.85-1.138 1.21-2.85 1.2-1.656.01-2.85-1.2-1.155-1.138-1.15-2.85-.005-1.668 1.15-2.9 1.194-1.13 2.85-1.15 1.712.02 2.85 1.15M36.8 38.7v-7.45h1.6v7.45h-1.6z"
                    />
                    <path
                        fill="#333"
                        d="M36.8 31.25v7.45h1.6v-7.45h-1.6m5.15-9.5q.024-1.668-1.2-2.9-1.138-1.13-2.85-1.15-1.656.02-2.85 1.15-1.155 1.232-1.15 2.9-.005 1.712 1.15 2.85 1.194 1.21 2.85 1.2 1.712.01 2.85-1.2 1.224-1.138 1.2-2.85z"
                    />
                    <path d="M44.65 0v6.8H4.4V0h-.6v7.1q.003.157.1.25.093.097.2.1H45q.132-.003.2-.1.122-.093.1-.25V0h-.65z" />
                    <path
                        fill="#3D9AA2"
                        d="M149 36.55V0h-39.95v36.55H149m46.65 0V0h-38.6v36.55h38.6z"
                    />
                </g>
                <path
                    id="PlaOut037__Layer8_0_1_STROKES"
                    stroke="#0B863C"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M50.45 98.15v5.175l57 32.725m-57-37.9V5m0 103.65v-5.325l-5-2.875"
                />
            </defs>
            <g>
                <use xlinkHref="#PlaOut037__Layer8_0_FILL" />
                <use xlinkHref="#PlaOut037__Layer8_0_1_STROKES" />
            </g>
            <use xlinkHref="#PlaOut037__Layer7_0_FILL" />
            <use xlinkHref="#PlaOut037__Layer6_0_FILL" />
            <use xlinkHref="#PlaOut037__Layer6_1_FILL" />
        </g></g>
    )
}

export default PlaOut037
