import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 0v9.5h15.65V0H0z"
                    id="BeaMus006__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus006__Symbol_23_0_Layer0_0_FILL"
                transform="translate(184.7 201.1)"
                id="BeaMus006__col1n"
            />
        </g></g>
    )
}

export default BeaMus006
