import { lazy, StrictMode, Suspense } from "react"
import { createRoot } from "react-dom/client"

import "./styles/fonts.css"
import "./styles/general.css"
import "./styles/bootstrap-form.css"
import "./styles/loading.css"
import "./styles/loading-placeholder.css"
import "./styles/view.css"
import "./styles/app.css"
import "./styles/stage.css"
import "./styles/categories.css"
import "./styles/item-config.css"
import "./styles/color-choose.css"
import "./styles/social-links.css"
import "./styles/responsive.css"

import * as serviceWorker from "./serviceWorker"
import CacheBuster from "./components/CacheBuster"
import { AppWrapperPlaceholder } from "./components/loading-placeholders/AppWrapperPlaceholder"
import { AppProvider } from "./components/AppProvider"

const root = createRoot(document.getElementById("root")!)

const AppWrapper = lazy(() => import("./components/AppWrapper"))

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`)

// We listen to the resize event
window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
})

root.render(
    <StrictMode>
        <AppProvider>
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }: any) => {
                    if (loading) return null
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload()
                    }

                    return (
                        <Suspense fallback={<AppWrapperPlaceholder />}>
                            <AppWrapper />
                        </Suspense>
                    )
                }}
            </CacheBuster>
        </AppProvider>
    </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
