import { AssetSvgProps } from "../../../shared/assets"

function ObjToy055({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#1C1C1C"
                    d="M272.75 298.35q5.537 6.956 9.3 14.25 26.204 50.921-34.5 119.5-.113.175-.1.35.016.234.15.35.175.163.35.15.234-.017.35-.2 60.991-68.889 34.9-120.15-3.852-7.62-9.65-14.85-.137-.17-.35-.2-.196-.026-.35.1-.17.137-.2.35-.026.209.1.35z"
                    id="ObjToy055__Layer14_0_FILL"
                />
                <path
                    d="M260.35 283.4q-.65.45-1.3.6-.4.05-1.2.3v-.1q-.05-.05-.3-.05-2.2 0-3.55-1.4l-.55-8.3q-.2.05-.35.1-2.85.9-5.05 2.6-1.55 1.2-2.45 2.6-.5.85-.85 1.65h-3.6q-.9-.45-2.15-.45-1.75 0-2.9.8-.1.05-.2.15-.4.2-.75.55-1.1 1-1.1 2.3 0 1.4.95 2.3 1 .85 3 1.1.3.05.65.1l6.3-.05h.55q1 1.6 3.2 3.05 3.65 2.3 8.3 2.5h1.75q3.95-.2 7-1.7 1.5-.7 2.8-1.75.85-.7 1.55-1.45 2.35-2.6 2.2-5.75-.15-2.85-2.2-4.95-.9-1.05-2.25-1.9-1-.65-2.1-1.15-2.3-1-5-1.2l1.05 8.05q-.7.9-1.45 1.45z"
                    id="ObjToy055__Layer5_0_MEMBER_0_FILL"
                />
                <path
                    fill="red"
                    d="M243 283.9v-.25q0-.2-.05-.35-.1-.9-1.1-1.55l-.2-.1q-.25-.1-.4-.25h-.1q-.9-.45-2.15-.45-1.75 0-2.9.8-.1.05-.2.15-.95.75-1 1.6v1.55q.05 1.1 1.2 1.85t2.9.75q.25 0 .6-.1 1.3-.1 2.25-.65 1-.65 1.1-1.55.05-.1.05-.15v-.2-.25q-.05-.05-.05-.1V284q0-.05.05-.1z"
                    id="ObjToy055__Layer5_0_MEMBER_1_FILL"
                />
                <path
                    fill="#900"
                    d="M243 285.2v-.2-.25q-.05-.05-.05-.1V284q-.002.11-.05.3-.3.7-1.05 1.2-.25.2-.55.3-1.05.45-2.3.45-1.75 0-2.9-.75-.8-.5-1.1-1.2-.097-.29-.1-.45v1.2q.05 1.1 1.2 1.85t2.9.75q.25 0 .6-.1 1.3-.1 2.25-.65 1-.65 1.1-1.55.05-.1.05-.15z"
                    id="ObjToy055__Layer5_0_MEMBER_1_MEMBER_0_FILL"
                />
                <path
                    fill="#333"
                    d="M257.3 260.95q.081-.048.15-.1l-.2.1-1.05.8q-.242-.089-.5-.2-1.433-.51-3.3-1.2l1.6 22.4q1.35 1.4 3.55 1.4.25 0 .3.05v.1q.8-.25 1.2-.3.65-.15 1.3-.6.75-.55 1.45-1.45l-2.8-21.6q-.921.334-1.7.6m-1.05 1.15h-.05q1.313-.253.7-.1h-.05q-.265.056-.6.1m.25-.1q0-.05.1-.05h.15l-.25.05z"
                    id="ObjToy055__Layer5_0_MEMBER_2_FILL"
                />
                <path
                    fill="#4D4D4D"
                    d="M282.35 280.85q.05.8-.75 1.9l-18.3 17.15q-.15.1-.4.25-1.65 1.1-4.05.3l-27.1-13.55q-.541-.492-.55-.85v5.05q0 1.3 1 1.7.1.05.25.05l25.6 13.3q3.3 2 6.05-.85l17.05-15.5.65-.65q.5-.65.6-1.35v-6.95h-.05m-28.75.2q.1-.1.3-.15l-.1-1.5-.3.1q-.55.25-1 .55-2.25 1.45-2.1 3.25.1 1.85 2.35 3.05 1.9.95 4.25 1.05h1.25q3.15-.15 5.35-1.6 2.1-1.35 2.15-3v-.15q-.15-1.9-2.45-3.15-.9-.45-1.9-.7l.2 1.5q.15.05.35.15.5.2.85.5.8.65.85 1.5.05 1.2-1.55 2.15-1.6.9-3.9 1-.65.1-1.2 0-1.6-.05-2.9-.6-1.65-.8-1.7-2.05-.05-1.05 1.2-1.9m7.35-5.6l.2 1.6q2.1.35 3.8 1.4.45.25.8.55 2.25 1.65 2.35 3.95.1 2.6-2.35 4.6-.15.1-.35.25-2.85 2.05-6.95 2.25-.7.05-1.45 0-3.25-.1-5.7-1.6-3.05-1.8-3.15-4.55-.15-2.7 2.7-4.85 1.15-.8 2.5-1.3l.3-.1-.1-1.65q-.15 0-.3.05-2.15.75-3.9 2.1-3.35 2.65-3.25 6.15.25 3.5 3.8 5.85 3.05 1.95 7.1 2.15.75 0 1.55-.05 4.15-.2 7.2-2.1.6-.35 1.15-.8 3.4-2.65 3.2-6.15-.15-3.55-3.8-5.85-.25-.15-.55-.3-2.15-1.25-4.8-1.6z"
                    id="ObjToy055__Layer5_0_MEMBER_3_FILL"
                />
                <path
                    d="M258.95 259.7q-.2-.55-.9-1-.95-.6-2.35-.6-1.35 0-2.35.6-.9.55-.95 1.3v.35q.1.7.95 1.2.95.6 2.3.6h.1q.05-.05.15-.05h.35q1.15-.15 1.8-.55l.3-.2q.55-.45.65-1l-.05-.65m-2.35 2.25h.15l-.25.05q0-.05.1-.05z"
                    id="ObjToy055__Layer5_0_MEMBER_3_MEMBER_0_FILL"
                />
                <g id="ObjToy055__Layer5_0_FILL">
                    <path
                        fill="#666"
                        d="M256.5 262l.25-.05h-.15q-.1 0-.1.05z"
                    />
                    <path
                        fill="#1C1C1C"
                        d="M253 268.2l-.05-.65-8.1 6.55q-.3.15-1.25.95-.75.55-.4.4l-11.45 9.2q-.55.6-.55 1.2 1.114 2.625 0 5.25 0 1.3 1 1.7v1.8q0 .9.55 1.35.1.1.25.15l26.25 13.5q2.05 1.1 3.95-.3l17.45-15.7q.95-.8 1.15-2.05v-2.4q.5-.65.6-1.35-1.548-2.507 0-6.95h-.05q0-.1-.05-.25v-.05q-.15-.65-.85-1.15l-21.25-9.75.1 1q-.99-.665-3.7-1.6-2.547-.878-3.6-.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy055__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy055__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy055__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy055__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy055__Layer5_0_MEMBER_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy055__Layer5_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjToy055__Layer5_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjToy055__Layer5_0_MEMBER_3_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjToy055
