import { AssetSvgProps } from "../../../shared/assets"

function ObjToy043({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjToy043__Layer14_0_FILL">
                    <path
                        fill="#E76DAB"
                        d="M306.15 336.85l-10.05-7.2h-30.2q2.453 5.3 0 10.6h3.1l10.05 7.25q-6.91 10.8 0 21.6 14.535-4.926 27.1 0-7.255-15.698 0-32.25z"
                    />
                    <path
                        fill="#5E9BF7"
                        d="M238.8 340.25q3.208 15.3 0 30.6 7.878 2.27 8 8.85 17.505-5.982 32.25 0-7.075-5.772 0-10.6-6.91-10.8 0-21.6L269 340.25H238.8z"
                    />
                    <path
                        fill="#F98717"
                        d="M338.3 332.8l10.05 7.2q-4.81 9.2 0 18.4 11.095-5.28 23.15 0-7.33-15.636 0-32.25l-10.05-7.2h-30.2q2.162 6.925 0 13.85h7.05z"
                    />
                    <path
                        fill="#5CD926"
                        d="M348.35 340l-10.05-7.2h-30.2q3.06 15.3 0 30.6 7.186 2.434 8 8.85 17.345-4.879 32.25 0-2.786-6.925 0-13.85-4.81-9.2 0-18.4z"
                    />
                </g>
                <g id="ObjToy043__Layer14_1_FILL">
                    <path
                        fill="#89C5FE"
                        d="M279.05 347.5H246.8v32.2h32.25v-32.2z"
                    />
                    <path
                        fill="#F59AD1"
                        d="M273.9 336.85v6.95l5.15 3.7v21.6h27.1v-32.25H273.9z"
                    />
                    <path
                        fill="#E3579E"
                        d="M269 340.25l4.9 3.55v-6.95l-8-7.2v10.6h3.1z"
                    />
                    <path
                        fill="#3E88F4"
                        d="M238.8 340.25v30.6l8 8.85v-32.2l-8-7.25z"
                    />
                    <path
                        fill="#56CA24"
                        d="M316.1 340l-8-7.2v30.6l8 8.85V340z"
                    />
                    <path
                        fill="#9DED67"
                        d="M348.35 372.25V340H316.1v32.25h32.25z"
                    />
                    <path
                        fill="#FDA92D"
                        d="M339.25 333.5l9.1 6.5v18.4h23.15v-32.25h-32.25v7.35z"
                    />
                    <path
                        fill="#E87606"
                        d="M338.3 332.8l.95.7v-7.35l-8-7.2v13.85h7.05z"
                    />
                </g>
                <g id="ObjToy043__Layer5_0_FILL">
                    <path
                        fill="#FBD0EA"
                        d="M291.15 356.4l-3.05-7.05-3.15 7.05h6.2m-1.25-13.95h.3l7.4 16.8q1.05 2.4 1.75 3.05.5.45 1.45.55v.55h-9.9v-.55h.4q1.2 0 1.65-.35.35-.25.35-.7 0-.25-.1-.55 0-.15-.45-1.15l-1.1-2.55h-7.25l-.85 2q-.4 1-.4 1.65 0 .85.7 1.25.4.25 2 .4v.55h-6.8v1.95h23.35V340.4h-24.9v6l1.55 1.1v15.35q1.1-.2 1.8-.95t1.75-3.1l7.3-16.35z"
                    />
                    <path
                        fill="#C4E1FF"
                        d="M250.35 375.95h24.95v-24.9h-24.95v24.9m16.8-21.8q.2.1.35.15 1.2.65 1.95 1.7.85 1.25.85 2.65 0 1.15-.65 2.15-.2.25-.45.5-.5.55-1.4 1-.95.5-2.35.85 2.9.65 4.1 1.65 1.7 1.35 1.7 3.55 0 2.3-1.85 3.85-2.3 1.8-6.65 1.8H252.3v-.55q1.4 0 1.95-.25.5-.25.7-.7.2-.45.2-2.1v-13.3q0-1.65-.2-2.1-.2-.45-.75-.7-.5-.25-1.9-.25v-.55h9.85q2.35 0 3.75.3.75.15 1.25.35m-1.25 14.4q0-1.45-.65-2.6-.7-1.15-1.85-1.6-1.15-.45-3.45-.45l-.05 7.35q0 .8.45 1.25.4.4 1.2.4 1.2 0 2.25-.55 1-.55 1.55-1.55t.55-2.25m-.7-9.9q0-1.35-.5-2.25-.55-.85-1.55-1.3-1-.45-3.2-.45v8.1q2.15 0 3.15-.5t1.6-1.35q.5-.9.5-2.25z"
                    />
                    <path
                        fill="#D0FFA2"
                        d="M344.6 368.5v-24.9h-24.9v24.9h24.9m-13.15-22.45q.75 0 1.4.05 2.45.1 3.6.6.45.2.85.45.9.55 1.45 1.4.3.45.5.9.35.85.35 1.75 0 .85-.35 1.6-.25.55-.7 1.05-.75.75-2.15 1.35-.75.25-1.65.45 1.8.45 2.9.95.75.35 1.2.75.2.15.4.35.35.3.6.7.7 1.05.7 2.5 0 2.3-1.85 3.8-2.3 1.85-6.65 1.85h-10.4V366q1.4 0 1.9-.25.5-.3.7-.7.2-.45.2-2.1v-13.3q0-1.65-.2-2.1-.2-.45-.7-.7-.5-.25-1.9-.25v-.55h9.8m1.3 10.85q-1.2-.45-3.5-.45v7.35q0 .8.4 1.2.45.45 1.25.45 1.2 0 2.2-.55 1.05-.55 1.6-1.55.5-1.05.5-2.3 0-1.4-.65-2.55t-1.8-1.6m1.55-4.05q.25-.7.25-1.65 0-1.3-.45-2.1-.05-.05-.1-.15-.4-.65-1.05-1.05-.2-.15-.5-.25-.45-.25-1.2-.35-.85-.1-2-.1v8.05q2.15 0 3.2-.45 1-.5 1.55-1.35l.3-.6z"
                    />
                    <path
                        fill="#FED9A3"
                        d="M342.85 329.7v6.35l5.5 3.95v14.65h19.4V329.7h-24.9m23.3 3.05q-1 .1-1.55.6-.75.6-2.4 3.45l-4.45 7.4v4.95q0 1.6.2 2 .15.4.65.7.45.3 1.3.3h1.15v.55H349.5v-.55h1.1q.9 0 1.45-.35.35-.2.6-.7.15-.4.15-1.95v-4.1l-4.8-8.8q-1.45-2.6-2-3.05-.6-.45-1.6-.45v-.6h9.8v.6h-.4q-.9 0-1.25.25t-.35.55q0 .55 1.2 2.7l3.7 6.8 3.7-6.2q1.35-2.25 1.35-3 0-.4-.4-.65-.5-.4-1.85-.45v-.6h6.25v.6z"
                    />
                </g>
                <g id="ObjToy043__Layer5_1_FILL">
                    <path
                        fill="#E776AF"
                        d="M271.95 342.4v-3.45L267.5 335v5.25h1.5l2.95 2.15z"
                    />
                    <path
                        fill="#7DAFF9"
                        d="M244.55 341.7l4 4h24.35l-5.3-4h-23.05z"
                    />
                    <path
                        fill="#5CD926"
                        d="M314.15 342.1l-4.45-3.95v23.5l4.45 4.75v-24.3z"
                    />
                    <path
                        fill="#EA84B7"
                        d="M300.05 335.05l-5.35-3.95h-23.05l4.05 3.95h24.35z"
                    />
                    <path
                        fill="#5E9BF7"
                        d="M240.4 345.6v23.5l4.4 4.75v-24.3l-4.4-3.95z"
                    />
                    <path
                        fill="#93E66F"
                        d="M342.25 338.25l-5.35-4h-23.05l4.05 4h24.35z"
                    />
                    <path
                        fill="#F9840F"
                        d="M332.85 332.8h4.45v-4.55l-4.45-3.95v8.5z"
                    />
                    <path
                        fill="#FBA248"
                        d="M360.05 320.4H337l4.05 3.95h24.35l-5.35-3.95z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy043__Layer14_0_FILL" />
            <use xlinkHref="#ObjToy043__Layer14_1_FILL" />
            <use xlinkHref="#ObjToy043__Layer5_0_FILL" />
            <use xlinkHref="#ObjToy043__Layer5_1_FILL" />
        </g></g>
    )
}

export default ObjToy043
