import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666",
    "col2d": "#DDDDDD",
    "col2n": "#EEEEEE"
}
function BodTai005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M110.65 13.5Q108.8 9 108 5.45q-1.2-.15-2.15-.35-1.15-.3-2-.65-3.2-1.3-4.15-4.45-.45.7-.8 1.2-1.65 2.15-4.05 2.15-.5 0-1.6-.3-.15-.05-.3-.05.7 7.05 2.9 13.2 3.25 9.05 3.25 17.05 0 6.55-6.5 11.5-8.95 6.8-27.5 6.8-8.05 0-24.2-4.9t-23.8-4.9q-7.55 0-12.15 2-2.3 1-3.5 2.4-.3.3-.55.7-.45 1.25-.7 2.4-1.3 6.3 4.95 8.05 3.15.85 8.3.5h9.8q7.5 1.2 17.3 4.05 9.25 2.7 18.05 3.9 10.5 1.6 21 0 1.1-.2 2.2-.45 16.1-3.4 24.1-10.75 1.5-1.45 2.75-3 5.5-7.05 5.5-19V31q-.25-9.45-3.5-17.5z"
                    id="BodTai005__Symbol_9_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M108 .35Q106.8.2 105.85 0q.25 3.2 2.2 8.3 2.15 5.65 2.7 16.25.15 1.85-.45 7.9-.018.165-.05.3-.425 3.278-2.4 6.45-4.9 9.446-16.95 14.05-4.338 1.946-8.9 2.55-.412.054-.85.1-2.65.4-5.35.55-14.05.5-20.95-1.15-6.9-1.7-7.45-1.85-.55-.1-12.45-3.4Q23 46.7 9.3 48.75q-8 .45-9.1-4.6-1.3 6.3 4.95 8.05 3.15.85 8.3.5h9.8q7.5 1.2 17.3 4.05 9.25 2.7 18.05 3.9 10.5 1.6 21 0 1.1-.2 2.2-.45 16.1-3.4 24.1-10.75 1.5-1.45 2.75-3 5.5-7.05 5.5-19V25.9q-.25-9.45-3.5-17.5Q108.8 3.9 108 .35z"
                    id="BodTai005__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M21.4 13.85q1.15-2.25 1.15-5.75 0-4.75-3.05-6.8Q16.8-.55 12.75.2 9.95.75 7.7 2.3 3.2 5.8 1.2 12.45h-.1q-.05.1-.05.25Q.8 14.1.6 15.95 0 20.8 0 23.1q0 1.25.05 2.45.05 2.3.3 4.55.15 0 .3.05 1.1.3 1.6.3 2.4 0 4.05-2.15.35-.5.8-1.2.95 3.15 4.15 4.45.85.35 2 .65.95.2 2.15.35-.2-.65-.3-1.25-.05-.65-.05-1.3 0-8.2 3.75-12.7.4-.45.75-.85 1.4-1.8 1.85-2.6z"
                    id="BodTai005__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M1.9 14.85q-.05-.65-.05-1.3 0-8.2 3.75-12.7Q6 .4 6.35 0 4.4 1.9 2.95 4.3 0 9.3 0 14.75q0 .45.05 1 .95.2 2.15.35-.2-.65-.3-1.25z"
                    id="BodTai005__Symbol_12_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai005__Symbol_9_0_Layer0_0_FILL"
                transform="translate(231.95 235.5)"
                id="BodTai005__col1n"
            />
            <use
                xlinkHref="#BodTai005__Symbol_10_0_Layer0_0_FILL"
                transform="translate(231.95 240.6)"
                id="BodTai005__col1d"
            />
            <g id="BodTai005__col2n" transform="translate(324.55 208.4)">
                <use
                    xlinkHref="#BodTai005__Symbol_11_0_Layer0_0_FILL"
                    id="BodTai005__col2n_FL"
                />
            </g>
            <g id="BodTai005__col2d" transform="translate(337.75 224.85)">
                <use
                    xlinkHref="#BodTai005__Symbol_12_0_Layer0_0_FILL"
                    id="BodTai005__col2d_FL"
                />
            </g>
        </g></g>
    )
}
export default BodTai005
