import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#E0E0E0",
    "col2n": "#FFFFFF"
}

function PetPet026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M59.5 29.75q0-12.3-8.75-21.05-8.7-8.7-21-8.7T8.7 8.7Q0 17.45 0 29.75t8.7 21q8.75 8.75 21.05 8.75t21-8.75q8.75-8.7 8.75-21z"
                    id="PetPet026__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M169.65 40.7q.45-.7 3.05-2.5 2.25-1.5 2.25-2.5 0-1.05-2.6-4.5-.4-.55-.75-1-11.2-13.15-27-21.55-4-2.35-8.15-4.35-13.2-7-27.9-2.65-9.35 2.5-17 9.4l-4-.35q-1.4-.15-2.75-.2h-.35q-13.95-.15-29.8 13.2l-.85.75q-3.75 3.35-8.6 7.7l-.5.4q.15-.15.2-.25l-4.05 2.45q-5.4-1.6-11.55-.15-17.8 5.15-27.05 20.8 5.2 2.7 8.6 6.45 1.55 1.65 2.55 3.4 4.35 9.3-3.95 11.05-4.95.15-9.2-2Q0 75.45 0 76.1q0 1.6.35 3.1 0 .05.95 3.25.35 1.35 1.45 3.7.8 1.85 1.2 2.3.1.15.2.15l.15-.3q3.6-10.65 21.75-10.5 8.4-.3 31.7 6.85 22.95 9.45 52.4-3.6 1.75-1.05 5.05-3.6.45-.3 2.35-2.15l1.4.1q15.3-.1 27.3-5.1-.05-.15-.05-.3 0-.7.25-1.65v-.3q0-2.55 1.35-4.8 1.4-2.4 4.15-4.1 1.85-1.75 5.05-3.25 3.4-1.6 5.2-1.85.65-1.35 1.35-3.05 1.35-3.35 2.5-5.05 2.3-3.3 3.6-5.25m-44.3-32.75q6.15-.45 12.2 2.15l1.3.6q1.25.6 2.4 1.35 7.9 4.85 11 14.05 1.35 3.95 1.5 7.85.25 6.35-2.75 12.5-.45.9-.9 1.75-3 5.05-7.65 8.25-3.05 2.2-6.85 3.45-6.9 2.35-13.55 1.05-.45-.1-.85-.2-1.95-.5-3.85-1.25-1.05-.4-2.1-.9-8.15-4-12-11.75l-1-2.3-.5-1.3q0-.05-.05-.15l-.7-2.55q-1.6-6.75.35-13.3.65-2.2 1.75-4.4.4-.8.85-1.6Q108.8 12.7 118.4 9.4q3.5-1.15 6.95-1.45z"
                    id="PetPet026__Symbol_44_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M51.75 63.05q15.55 3.9 27.3 4.3.45-.3 2.35-2.15-.35 0-.7-.05.1-.05.25-.05-39.2-1.4-56.9-18.95 12.55 13.05 27.7 16.9m-43-40.85L4.7 24.65Q-2.95 44.4 1.85 55.9q-4.1-19.05 6.7-33.45.15-.15.2-.25M55.4.95l-4-.35Q39 21.4 41.6 39 41.95 17.05 54 2.5q.65-.8 1.4-1.55m29.65 49.7q-1.95-.5-3.85-1.25-1.05-.4-2.1-.9-8.15-4-12-11.75l-1-2.3-.5-1.3q0-.05-.05-.15l-.7-2.55q-1.6-6.75.35-13.3-4 10.4-2.35 17.7 1.2 5.4 5.6 9.15 5.65 4.8 16.6 6.65M101.4 0l1.3.6q1.25.6 2.4 1.35Q113 6.8 116.1 16q1.35 3.95 1.5 7.85.25 6.35-2.75 12.5-.45.9-.9 1.75 5.05-7.4 6.6-13.5 2.9-11.35-6.1-18.35l-.05-.05q-4.8-3.7-13-6.2z"
                    id="PetPet026__Symbol_27_0_Layer0_0_FILL"
                />
                <g id="PetPet026__Layer4_0_FILL">
                    <path
                        fill="#333"
                        d="M219.4 267.8q.1-.2-.1-.25-.1-.15-.2-.15-.15 0-.4.15-.2.05-.25.2-1.85 8.15 4.7 12.75.1 0 .3.1.25-.1.35-.2.2-.15.2-.25.05-.15-.15-.3-6.05-4.35-4.45-12.05z"
                    />
                    <path
                        fill="#FFF"
                        d="M251.75 274.3q-1.05-4.4-4.95-6.15-2.9-1.3-6.1-.65 2.95-1.4 4.45-4.15 2-3.85.2-7.9-1.7-4.15-6.25-6.15-4.5-2.1-9.15-.85-4.6 1.25-6.6 5.05-2 3.75-.3 7.85 1.75 4.15 6.3 6.2 4.2 1.9 8.5.95-4 1.85-6.15 5.95-2.4 4.35-1.25 8.7 1.15 4.3 5.05 6.05t8.35-.05q4.4-1.85 6.7-6.2 2.35-4.4 1.2-8.65m-18.65-12.1q0-.7.45-1.2.5-.45 1.2-.45t1.15.45q.5.5.5 1.2t-.5 1.15q-.45.5-1.15.5t-1.2-.5q-.45-.45-.45-1.15m7 11q.5.5.5 1.2t-.5 1.15q-.45.5-1.15.5t-1.2-.5q-.45-.45-.45-1.15t.45-1.2q.5-.45 1.2-.45t1.15.45z"
                    />
                    <path d="M240.6 274.4q0-.7-.5-1.2-.45-.45-1.15-.45t-1.2.45q-.45.5-.45 1.2t.45 1.15q.5.5 1.2.5t1.15-.5q.5-.45.5-1.15m-7.05-13.4q-.45.5-.45 1.2t.45 1.15q.5.5 1.2.5t1.15-.5q.5-.45.5-1.15t-.5-1.2q-.45-.45-1.15-.45t-1.2.45z" />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet026__Symbol_97_0_Layer0_0_FILL"
                transform="translate(207.5 240.3)"
                id="PetPet026__col1n"
            />
            <use
                xlinkHref="#PetPet026__Symbol_44_0_Layer0_0_FILL"
                transform="translate(111.6 233.55)"
                id="PetPet026__col2n"
            />
            <use
                xlinkHref="#PetPet026__Symbol_27_0_Layer0_0_FILL"
                transform="translate(147.75 243.65)"
                id="PetPet026__col2d"
            />
            <use xlinkHref="#PetPet026__Layer4_0_FILL" />
        </g></g>
    )
}

export default PetPet026
