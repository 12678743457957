import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor084({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#8B3F99"
                    d="M236.25 268.45H218.9q-.15.866-.15 1.8t.15 1.8h17.35q.2-.866.2-1.8t-.2-1.8z"
                    id="AccMor084__Layer10_1_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor084__Symbol_182_0_Layer0_0_FILL"
                />
                <g id="AccMor084__Layer10_0_FILL">
                    <path
                        fill="#24429A"
                        d="M227.6 279.1q3.65 0 6.25-2.6 1.934-1.934 2.4-4.45-8.85-1.396-17.35 0 .503 2.516 2.4 4.45 2.7 2.6 6.3 2.6z"
                    />
                    <path
                        fill="#DF1970"
                        d="M236.25 268.45q-.466-2.516-2.4-4.45-2.6-2.6-6.25-2.6-3.6 0-6.3 2.6-1.897 1.934-2.4 4.45 8.8 2.394 17.35 0z"
                    />
                </g>
            </defs>
            <use xlinkHref="#AccMor084__Layer10_0_FILL" />
            <use xlinkHref="#AccMor084__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor084__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor084__col1l"
            />
        </g></g>
    )
}

export default AccMor084
