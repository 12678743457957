import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF"
}

function AccMor026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.2 9.05q0-.6-.25-1-.3-.6-1.15-.75.3-.5.55-1.2.2-.55.25-1.1.05-.35.05-.7 0-1.7-.85-2.65-.35-.45-.9-.75-.2-.15-.45-.25-1.3-.6-4.15-.65h-.15l-.95.05q-.25.05-.5.15-29.05 5.55-58.1 5.6Q37.55 5.75 8.5.2 8.25.1 8 .05L7.05 0H6.9Q4.05.05 2.75.65 2.5.75 2.3.9q-.55.3-.9.75Q.55 2.6.55 4.3q0 .35.05.7.05.55.25 1.1.25.7.55 1.2-.85.15-1.15.75-.25.4-.25 1v.2q-.2 2.15 3.1 2.45 1.25.25 2.35.45.85.15 1.6.3.65.1 1.25.2.4.05.75.15.4.05.75.1.75.15 1.5.25l3.4.6q6.9 1.1 13.75 1.9 1.65.2 3.25.4 35.7 5.2 69.8 0 1.6-.2 3.25-.4 6.85-.8 13.75-1.9l3.4-.6q.75-.1 1.5-.25.35-.05.75-.1.35-.1.75-.15.6-.1 1.25-.2.75-.15 1.6-.3 1.1-.2 2.35-.45 3.3-.3 3.1-2.45v-.2M80.45 6.3v-.05q.05 0 .05.05h-.05m-27.7-.05v.05h-.05q0-.05.05-.05z"
                    id="AccMor026__Symbol_124_0_Layer0_0_FILL"
                />
                <g
                    id="AccMor026__Symbol_75_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M49.3 11.6q1.05-.05 2.15-.05Q35 10.35 16.6 7.35q-.5-.1-.95-.15Q1.55 4.25 0 5q.05.55.25 1.1.25.7.55 1.2v-.1q1.45-.3 3.75.2 2.25.45 2.65.5 20.95 3.2 42.1 3.7M129.85.65q-1.3-.6-4.15-.65h-.15l-.95.05q-.25.05-.5.15Q95.05 5.75 66 5.8 36.95 5.75 7.9.2 7.65.1 7.4.05L6.45 0H6.3Q3.45.05 2.15.65q2.8-1.15 8.8.55.4.1.85.25Q17.3 3 23.65 3.95q8.4 1.25 15 1.7.15 0 .3.05h.35Q52.25 7.15 66 7q13.75.15 26.7-1.3h.35q.15-.05.3-.05 6.6-.45 15-1.7 6.35-.95 11.85-2.5.45-.15.85-.25 6-1.7 8.8-.55m-50 5.55H80.25q1.55-.05 3.1-.05l-3.45.15h-.05v-.1m-27.7 0v.1h-.05l-3.45-.15q1.55 0 3.1.05H52.15m79.6-.1q.2-.55.25-1.1-1.55-.75-15.65 2.2-.45.05-.95.15-18.4 3-34.85 4.2 1.1 0 2.15.05 21.15-.5 42.1-3.7.4-.05 2.65-.5 2.3-.5 3.75-.2v.1q.3-.5.55-1.2z" />
                    <path d="M52.1 6.3l-.35-.1q-1.55-.05-3.1-.05l3.45.15m28.15-.1l-.35.1 3.45-.15q-1.55 0-3.1.05zM52.1 6.3v-.1h-.35l.35.1m27.8-.1v.1l.35-.1h-.35zM52.1 6.2v.1q.002-.05.05-.05V6.2h-.05m27.8.1v-.1h-.05v.05q.048 0 .05.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccMor026__Symbol_124_0_Layer0_0_FILL"
                transform="translate(125.8 300.75)"
                id="AccMor026__col1n"
            />
            <use
                xlinkHref="#AccMor026__Symbol_75_0_Layer0_0_FILL"
                transform="translate(126.4 300.75)"
                id="AccMor026__col1d"
            />
        </g></g>
    )
}

export default AccMor026
