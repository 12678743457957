import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2n": "#FFFFFF"
}

function ShiDre046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.65 24.25l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45Q157 1.8 156.3 0q-48.75 5.65-74.1 9.9-11.9-1.85-29.25-4.1Q9 .1 8.75 0 8 1.8 7.35 3.65l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.1.45-.2.95l-.2 1.1Q0 31.3 0 31.5q.35-.35.75-.6.85-.7 1.85-1.2 1.45-.75 3.1-1.2.4-.1.85-.2 1.55-.3 3.3-.35h1.25q.2 0 .45.05 1.5.05 3 .4.2.05.45.1.2.05.45.15.75.25 1.45.6.5.2 1 .45l.2.1q.25.1.45.25 1.85 1.1 3.3 2.7l-4.3 17.05q.05.05.1.05v.05l-1.9 7.4-.2.7-1.15 4.6Q12 72.7 7.55 79.65q6.9-2.4 14.1-3.9 1.35-.3 2.7-.55 6.85-1.3 14-1.75 2-.15 4-.2 3.45-.1 7-.05 5.55.1 11.2.7 1.05.1 2.15.25l.9.1q1.05.1 2.05.25 2.1.3 4 .6 2.4.4 4.55.85 4.9 1 8 2.1.15-.05.3-.05t.3.05q3.1-1.1 8-2.1 2.15-.45 4.55-.85 1.9-.3 4-.6 1-.15 2.05-.25l.9-.1q1.1-.15 2.15-.25 5.65-.6 11.2-.7 3.55-.05 7 .05 2 .05 4 .2 7.15.45 14 1.75 1.35.25 2.7.55 7.2 1.5 14.1 3.9Q153 72.7 150.6 62.6l-1.15-4.6-.2-.7-1.85-7.4-.05-.05q.05 0 .1-.05L143 32.75q1.45-1.65 3.3-2.7.25-.15.5-.25.25-.15.5-.25.8-.4 1.7-.7.25-.1.55-.2.25-.1.45-.15.25-.05.45-.1 1.5-.35 3-.4.25-.05.45-.05h1.25q1.75.05 3.3.35.45.1.85.2 1.65.45 3.1 1.2 1 .5 1.85 1.2.4.3.8.65v-.05q0-.15-.05-.35l-.2-1.1q-.2-1-.35-1.95l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85z"
                    id="ShiDre046__Symbol_243_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M110.1 6.8q.4.55.6 1.15.5 1.65.5 2.5 0 5.85-8.3 13.65-.75.8-1.9 1.8 2 .05 4 .2 7.15.45 14 1.75 1.35.25 2.7.55-5.6-8.55-4.15-12.75 1.3-3.75-.15-8.85l-.4-1.3q-.6-1.75-1.45-2.95-2.1-2.8-5.85-2.5-4.15.3-8.7 2.5-1.3.6-2.6 1.4-3.2 1.85-6.1 2.85-2.55.85-4.9 1.05-4.85.4-24.45-.8l-1.7-.1q-.2 0-.4.05-.2-.05-.4-.05-21.1 1.3-26.15.9-2.35-.2-4.9-1.05-2.9-1-6.1-2.85-1.3-.8-2.6-1.4Q16.15.35 12 .05q-3.75-.3-5.8 2.5-.9 1.2-1.5 2.95l-.4 1.3q-1.45 5.1-.15 8.85 1.1 3.2-1.85 8.9-.95 1.8-2.3 3.85 1.35-.3 2.7-.55 6.85-1.3 14-1.75 2-.15 4-.2-.85-.75-1.45-1.35l-.45-.45q-8.3-7.8-8.3-13.65 0-.85.5-2.5.2-.6.6-1.15.95-1.3 3.15-2.3 3.15-1.45 6.85.8 1.6.9 2.9 1.5 1.75.8 2.95 1.05 2.1.35 6.15 1.2 4.1.85 17.35 1.15 1 8.35-1.35 14.35-.65 1.7-1.6 3.2 2.4.4 4.55.85 4.9 1 8 2.1.15-.05.3-.05t.3.05q3.1-1.1 8-2.1 2.15-.45 4.55-.85-4.25-6.8-2.95-17.55Q84 9.9 88.1 9.05q4.05-.85 6.15-1.2 1.2-.25 3-1.05 1.25-.6 2.85-1.5 3.7-2.25 6.85-.8 2.2 1 3.15 2.3z"
                    id="ShiDre046__Symbol_206_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65M116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4z"
                    id="ShiDre046__Symbol_185_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M147.55 24.7q-.25-.55-.45-1.1-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65l-1.7-2.3-.05-.05-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-.45.35-.95.65-3.25 2.2-6.65 4.1-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95Q38.05 15.1 28.4 9.9q-2.7-1.5-5.35-3.15-1.6-1-3.15-2.1L18.35 3.5q-1.45-1.05-2.8-2.1L13.7 0q-.1.05-.15.15-3.05 4.1-5.7 8.55Q7 10.15 6.2 11.6q-1.5 2.7-2.85 5.55-.5.95-.95 1.95-1.25 2.75-2.4 5.6l.5.2q11.3 4.5 22.7 7.6 10.7 3 21.45 4.95 2.8.5 5.65.9Q62 39.95 73.75 40q11.75-.05 23.45-1.65 2.85-.4 5.65-.9 10.75-1.95 21.45-4.95 11.4-3.1 22.7-7.6.25-.1.55-.2z"
                    id="ShiDre046__Symbol_166_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre046__Symbol_243_0_Layer0_0_FILL"
                transform="translate(110 253.3)"
                id="ShiDre046__col1n"
            />
            <use
                xlinkHref="#ShiDre046__Symbol_206_0_Layer0_0_FILL"
                transform="translate(131.65 300.65)"
                id="ShiDre046__col1d"
            />
            <use
                xlinkHref="#ShiDre046__Symbol_185_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiDre046__col1s"
            />
            <use
                xlinkHref="#ShiDre046__Symbol_166_0_Layer0_0_FILL"
                transform="translate(118.75 228.6)"
                id="ShiDre046__col2n"
            />
        </g></g>
    )
}

export default ShiDre046
