import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#00B600",
    "col1n": "#008300",
    "col2d": "#830000",
    "col2n": "#FF0000",
    "col2s": "#510000"
}

function LegPan016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7.05l.05-.05q-3.1.6-6.15 1.15-4.4.75-8.8 1.45-8.2 1.2-16.4 2.05-3.2.3-6.4.55-2.85.25-5.7.4-2.65.2-5.3.3-2.15.1-4.25.2-7.45.2-14.85.1-3.05-.05-6.05-.15-3.05-.1-6.1-.25-5.35-.3-10.65-.75l-2.5-.2q-8.1-.8-16.15-1.95-5.2-.8-10.35-1.7L0 0q0 .05.05.1v19.5l1.7-.3h.1q2.15-.35 4.3-.6 5.2-.65 10.35-.95 3.8-.25 7.6-.3 4.3-.05 8.55.15 6.65.3 13.2 1.25 3 .4 5.95 1 3.05.55 6.05 1.25 2.5.55 5 1.25 4.9-1.35 9.9-2.3 2-.4 4-.7 2.8-.5 5.65-.85 5.95-.75 12.05-.95 0-.1-.05-.2h.05q.05 0 .05.1 0 .05-.05.1 4.25-.15 8.55-.05 3.9.05 7.8.35 4.3.3 8.65.85 1.75.2 3.55.45 1.35.2 2.7.45V.05z"
                    id="LegPan016__Symbol_26_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M.05 13.5q-.05.05-.05.15 5.2-.65 10.35-.95-.05-.2-.1-.3l.15-1H.05v2.1M10.4 5.75V.1H.05v5.65H10.4m16.15 6.65q-.05.05-.05.15 6.65.3 13.2 1.25v-.15q-.05-.05-.05-.1V11.4h-13.1v1M39.6 1.5V.75Q34.25.45 28.95 0h-2.5v.4h.1v5.35h13.1V1.5h-.05m27 4.25v-4.7q-7.45.2-14.85.1v4.6H66.6m.15 8.65v-3h-15v3.1h-.05v1.55q2.5.55 5 1.25 4.9-1.35 9.9-2.3.05-.35.15-.6m21.55-2.6h-.05v-.4h-12.1v.9h.05v.65q.05.25.05.5 5.95-.75 12.05-.95 0-.1-.05-.2h.05v-.5M87.85.15l-.1.05V.15h-5.9q-2.85.25-5.7.4v5.2h12.1V.15h-.4M113.3 12.2l.15-.8h-8.8l-.15.8v.1q.1.2.15.45v.05q4.3.3 8.65.85V12.2m.15-6.45V.1h-8.8v5.65h8.8z"
                    id="LegPan016__Symbol_27_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.35 6.15V.5H.1v5.65h4.25m0 5.65H.1v1.8q-.05.05-.1.15v.9q2.15-.35 4.3-.6 0-.1.05-.15v-2.1m26.45 1.15q0-.1.05-.15v-1h-8.6v1q4.3-.05 8.55.15m.05-6.8V.8h-.1V.4H27.1q.3.05.65.1h-5.5v5.65h8.6M49.95 15v.2q3.05.55 6.05 1.25V14.9h.05v-3.1H50v2.45q-.05.15-.05.65h-.05v.05q.05 0 .05.05m6.1-8.85v-4.6Q53 1.5 50 1.4v4.75h6.05m24.4 5.65H75.2v1.6h-.05v1q-.2.1-.25.3 2.8-.5 5.65-.85 0-.25-.05-.5v-.65h-.05v-.9M75.2 2v4.15h5.25V.95q-2.65.2-5.3.3V2h.05m17.35-2q-3.2.3-6.4.55h6.4V0m16.4 6.15V.5h-7.8v5.65h7.8m-.15 6.45l.15-.8h-7.8v1.05q3.9.05 7.8.35v-.05q-.05-.25-.15-.45v-.1m15.05 2.35V11.8h-2.65v2q-.05.4-.05.7 1.35.2 2.7.45m0-8.8V.5h-2.55v5.65h2.55z"
                    id="LegPan016__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.1 9.65v5.65h10.35V9.65H.1m10.25-7.6v-.3Q5.15.95 0 .05V.1h.1V4h10.35V2.1q-.05-.05-.1-.05M26.6 15.3h13.1V9.65H26.6v5.65m25.2-5.65v5.65h14.85V9.65H51.8m36.5 5.65V9.65H76.2v5.65h12.1M113.5 4V0q-4.4.75-8.8 1.45V4h8.8m0 11.3V9.65h-8.8v5.65h8.8z"
                    id="LegPan016__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M1.9 11.3H0v3.15l1.7-.3h.1v-.9q.05-.1.1-.15v-1.8M0 0v5.65h1.9V0H0m24.05 12.3v-1H16.5l-.15 1q.05.1.1.3 3.8-.25 7.6-.3m0-6.65V0H16.5v5.65h7.55m21.75 7.9v.15q3 .4 5.95 1v-.2q0-.05-.05-.05v-.05h.05q0-.5.05-.65V11.3h-6.05v2.15q0 .05.05.1m6-7.9V.9Q48.75.8 45.7.65v.75h.05v4.25h6.05M77 11.3h-4.15v3q-.1.25-.15.6 2-.4 4-.7.05-.2.25-.3v-1H77v-1.6M72.7.95v4.7H77V1.5h-.05V.75q-2.15.1-4.25.2m30.25 4.7V0h-8.6v5.65h8.6m0 6.7V11.3h-8.6v.4h.05v.5q.05 0 .05.1 0 .05-.05.1 4.25-.15 8.55-.05m20.05.95v-2h-3.45l-.15.8v1.45q1.75.2 3.55.45 0-.3.05-.7m.1-7.65V0h-3.55v5.65h3.55z"
                    id="LegPan016__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan016__Symbol_26_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan016__col1n"
            />
            <use
                xlinkHref="#LegPan016__Symbol_27_0_Layer0_0_FILL"
                transform="translate(135.8 315.3)"
                id="LegPan016__col1l"
            />
            <use
                xlinkHref="#LegPan016__Symbol_28_0_Layer0_0_FILL"
                transform="translate(131.5 314.9)"
                id="LegPan016__col2n"
            />
            <use
                xlinkHref="#LegPan016__Symbol_29_0_Layer0_0_FILL"
                transform="translate(135.75 311.4)"
                id="LegPan016__col2d"
            />
            <use
                xlinkHref="#LegPan016__Symbol_30_0_Layer0_0_FILL"
                transform="translate(129.7 315.4)"
                id="LegPan016__col2s"
            />
        </g></g>
    )
}

export default LegPan016
