import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#621F00",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjToo046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M2.45 187.15q.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1l-.05.05v26h4.9v-36.05q-.4-.35-.75-.7-1.2-1.2-1.75-2.6M.15 3.85L0 37.35h.05V182.6q.15.2.3.35 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.35-.35.75-.65L4.9 1.85Q2.55-1.8.2 1.85v2H.15z"
                    id="ObjToo046__Symbol_206_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M6.1 74.8v15.45h2.85L9.1 0 0 66.75h6.15l-.05 2.5v5.55z"
                    id="ObjToo046__Symbol_44_0_Layer0_0_FILL"
                />
                <g
                    id="ObjToo046__Symbol_85_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M11.75 66.75h5.05L9.1 0 0 66.75h6.15l-.05 2.5v21h5.65v-23.5zM11.7 5.8h.05v-.2l-.05.2z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToo046__Symbol_206_0_Layer0_0_FILL"
                transform="translate(122.35 107.25)"
                id="ObjToo046__col1n"
            />
            <use
                xlinkHref="#ObjToo046__Symbol_85_0_Layer0_0_FILL"
                transform="translate(115.85 18.85)"
                id="ObjToo046__col2n"
            />
            <use
                xlinkHref="#ObjToo046__Symbol_44_0_Layer0_0_FILL"
                transform="translate(115.85 18.85)"
                id="ObjToo046__col2l"
            />
        </g></g>
    )
}

export default ObjToo046
