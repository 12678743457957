import { AssetSvgProps } from "../../../shared/assets"

function ShiMot051({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 269.8v12.1q33.25 10.064 66.5 0v-12.1q-33.25-9.647-66.5 0z"
                    id="ShiMot051__Layer5_0_FILL"
                />
                <g id="ShiMot051__Layer5_1_FILL">
                    <path fill="red" d="M159.2 258v11.8h66.5V258h-66.5z" />
                    <path
                        fill="#0000A4"
                        d="M159.2 281.9v11.9h66.5v-11.9h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot051__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot051__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot051
