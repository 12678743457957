import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525"
}
function SkiMak023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M144.15 44.65q-2.2-16.2-13.85-29.25-6.8-7.4-14.35-11.3-5.75-2.95-11.9-3.8-4.7-.6-8.85 0-2.05.3-4 1-.5.05-1 .2-2.35.85-4.6 2.2Q78 8.3 74.4 16.9q-.15.25-.25.6-.15.4-.25.8-.55 1.3-.95 2.75l-.65 2.4v.1q-.05-.05-.05-.1l-.65-2.4q-.4-1.45-.95-2.75-.1-.4-.25-.8-.1-.35-.25-.6-3.6-8.6-11.2-13.2-2.25-1.35-4.6-2.2-.5-.15-1-.2-1.95-.7-4-1-4.15-.6-8.85 0-6.15.85-11.9 3.8Q21.05 8 14.25 15.4 2.6 28.45.4 44.65q-.45 3.65-.4 7-.15 9.8 4.4 18.8 7.1 14.1 21.45 17.9 14.4 3.75 27.65-4.9 8.95-5.8 13.9-14.95 2.6-4.55 3.9-8.45 1.15-3.65 1-2.85.15.3 1.05 3.05 1.15 3.8 3.85 8.25 4.9 9.15 13.85 14.95 13.25 8.65 27.65 4.9 14.35-3.8 21.45-17.9 4.55-9 4.4-18.8.05-3.35-.4-7z"
                    id="SkiMak023__Symbol_30_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak023__Symbol_30_0_Layer0_0_FILL"
                transform="translate(120.25 118.3)"
                id="SkiMak023__col1n"
            />
        </g></g>
    )
}
export default SkiMak023
