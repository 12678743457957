import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E0BF81"
}
function ObjMus047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjMus047__Layer1_0_FILL">
                    <path
                        fill="#FF5400"
                        d="M263.95 257.85q.1.1.15.25l.05.05.2.5q9.1 5.559 19.1 5.1l.4-.3q.05 0 .05-.05.15-.05.3-.15.5-.3.9-.65.45-.3.85-.65.1-.05.2-.15 1.45-1.15 2.65-2.6.1-.15.25-.3l-27.1-7.3q.05.25.05.45.3 1.8.95 3.55.05.1.15.25.15.45.35.95.25.55.5 1.05M121 258.1q.05-.15.15-.25.25-.5.5-1.05.2-.5.35-.95.1-.15.15-.25.65-1.75.95-3.55 0-.2.05-.45l-27.1 7.3q.15.15.25.3 1.2 1.45 2.65 2.6.1.1.2.15.4.35.85.65.4.35.9.65.15.1.3.15 0 .05.05.05l.4.3q10.477 2.863 19.1-5.1l.2-.5.05-.05z"
                    />
                    <path
                        fill="#FF0"
                        d="M262.6 241.85q-.45 1.7-.7 3.35 13.908 9.953 30.35 8.15.65-1.55 1.1-3.25.25-.75.4-1.5.1-.45.2-.85l.1-.3-30.65-8.2-.1.3q-.05.1-.05.25-.35 1-.65 2.05m-140.8-2.3l-.1-.3q-15.231 3.454-30.65 8.2l.1.3q.1.4.2.85.15.75.4 1.5.45 1.7 1.1 3.25 16.936 1.807 30.35-8.15-.25-1.65-.7-3.35-.3-1.05-.65-2.05 0-.15-.05-.25z"
                    />
                    <path
                        fill="#48BDFF"
                        d="M294.6 241.2q-.05-.8-.2-1.6v-.15q-.05-.3-.15-.6-.444-2.245-1-3.65-.517-1.367-6-3.25-5.433-1.833-10.8-2.6-5.306-.756-5.4-.7-2 1.35-3.6 3.05-.2.2-.4.45-.05.05-.05.1-.55.6-1 1.3 13.95 8.179 28.6 7.65m-196.75-8.6q-5.403 1.794-5.45 1.85-1.1 2.15-1.55 4.4-.1.3-.15.6v.15q-.15.8-.2 1.6 15.307.528 28.6-7.65-.45-.7-1-1.3 0-.05-.05-.1-.204-.25-1.25-1.3-1.037-1.048-2.75-2.35-1.666-1.31-6.25.5-4.54 1.866-9.95 3.6z"
                    />
                </g>
                <g id="ObjMus047__Layer1_1_FILL">
                    <path
                        fill="#D70000"
                        d="M266.5 264.5l.2 1.2q5.05 3.55 11.2 2.95.35-.45.8-.85.35-.45.75-.9.1-.05.2-.15 1.65-1.3 3.8-3-10-.95-19.1-5.1 1.05 2.5 1.8 4.45l.1.3q.15.55.25 1.1m-160.85 2.4q.4.45.75.9.45.4.8.85 6.15.6 11.2-2.95l.2-1.2q.1-.55.25-1.1l.1-.3q.75-1.95 1.8-4.45-9.1 4.15-19.1 5.1 2.15 1.7 3.8 3 .1.1.2.15z"
                    />
                    <path
                        fill="#FFA800"
                        d="M261.9 245.2q-.1.9-.15 1.75-.15 2.4.2 4.65 13.25 5.85 27.1 7.3 1.45-1.8 2.5-3.95.4-.8.7-1.6-15.95-1.15-30.35-8.15m-138.55 1.75q-.05-.85-.15-1.75-14.4 7-30.35 8.15.3.8.7 1.6 1.05 2.15 2.5 3.95 13.85-1.45 27.1-7.3.35-2.25.2-4.65z"
                    />
                    <path
                        fill="#00F030"
                        d="M294.55 244.8q.2-1.85.05-3.6-14.65-1.35-28.6-7.65-1 1.45-1.7 3.1-.5 1.25-.9 2.6 14.75 6.15 30.65 8.2.35-1.35.5-2.65M90.5 241.2q-.15 1.75.05 3.6.15 1.3.5 2.65 15.9-2.05 30.65-8.2-.4-1.35-.9-2.6-.7-1.65-1.7-3.1-13.95 6.3-28.6 7.65z"
                    />
                    <path
                        fill="#2337FF"
                        d="M291.95 233.1q-2.75-4.4-7.35-6.1-.65-.25-1.4-.45-2.55-.65-5.1-.45-2.9.25-5.7 1.75-.25.15-.55.3l-.3.2q-.15.1-.35.2 10.2 4.7 21.4 5.75-.1-.2-.2-.35-.1-.2-.15-.35l-.3-.5m-199.25.85q-.1.15-.2.35 11.2-1.05 21.4-5.75-.2-.1-.35-.2l-.3-.2q-.3-.15-.55-.3-2.8-1.5-5.7-1.75-2.55-.2-5.1.45-.75.2-1.4.45-4.6 1.7-7.35 6.1l-.3.5q-.05.15-.15.35z"
                    />
                </g>
                <path
                    fill={colors.col1n}
                    d="M10.85 4.8q-.05-2.5.35-4.8Q5.956-1.296 0 2.95q1.5 1.9 2.75 4 2.25 4 3.6 9.1.25 1.35.65 3.05.1.4.15.8.3 1.4.65 3.05.65.15 1.35.35 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.3.3-.65.55.9 2.8 2.65 4.35 1.75.65 3.4 0 1.7-.25 2.95-1.7 1.2-3.6-1.3-9.1-4.15-12.55-6.45-19.7-.7-2.1-1.2-3.75-1.4-5.05-1.4-9.65m157.1 2.15q1.25-2.1 2.75-4-3.54-6.167-11.2-2.95.4 2.3.35 4.8 0 4.6-1.4 9.65-.5 1.65-1.2 3.75-2.3 7.15-6.45 19.7-2.5 5.5-1.3 9.1 1.25 1.45 2.95 1.7 1.65.65 3.4 0 1.75-1.55 2.65-4.35-.35-.25-.65-.55-1.7-1.7-2.6-5.1l-.1-.55q-.15-.75-.25-1.5-.5-5.5 2.35-9.9.15-.2.3-.35.05-.05.05-.1 1.7-2 3.95-3 .7-.2 1.35-.35.35-1.65.65-3.05.05-.4.15-.8.4-1.7.65-3.05 1.35-5.1 3.6-9.1z"
                    id="ObjMus047__Symbol_141_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMus047__Symbol_141_0_Layer0_0_FILL"
                transform="translate(107.2 265.7)"
                id="ObjMus047__col1n"
            />
            <use xlinkHref="#ObjMus047__Layer1_0_FILL" />
            <use xlinkHref="#ObjMus047__Layer1_1_FILL" />
        </g></g>
    )
}
export default ObjMus047
