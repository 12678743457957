import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M163.7 42.1q2.15.35 4.3.8 1.1.2 2.2.5.6.15 1.15.3 1.65.45 3.3 1 .7.25 1.35.6 2.65 1.25 5.15 3.35 3.85 3.3 7.25 8.65 4.05 6.4 7.35 15.75.2.6.45 1.2 5.75 25.25 2.15 50.95-.2 2-.45 4.05-.95 9.45-2.25 18.75-.15 1-1.3 5.75 6-11.2 9.9-23.3 3.95-12.45 4.35-21.35.35-8.9.45-10.6.45 1.6.85 3.25 3.3 12.8 5.65 25.75 0 .05.05.1v-.15q.3 1.6.55 3.15-5.3 19.55-19.05 35.6 3.8.5 8.4 0l.15-.05 3.05-.45q.25-.05.55-.1l1-.2.45-.1h-.1q8.55-2.1 14.2-6.95 1.15-1.1 2.35-2.3 2.6-2.65 5.45-6.05.75-1.45 1.45-2.9l-.3 2.5q-1.1 7.3-3.3 14.45 5.65-4.55 9-10.6.05-.15.15-.25 1.9-3.65 3.05-7.75 3-10.75 2-21.6-3.85-32.8-19.85-67-16.9-40.7-50.75-51.75-3.15-1.4-6.35-2.35-12.2-4.9-24.35 0-1.75.8-8.6 3.2-6.9 2.3-11.75 1.2-4.9-1.1-7.05-1.85-2.15-.8-3.4-1.55-.9-.5-1.3-.75-1.4-.65-2.8-1.15-9.5-3.4-20.55-.5l-1.7.5q-1.5.45-2.95.95-19.5 6.6-34.4 18.9-28 24.7-42.15 82.35l-.5 4.6q-.7 1.25-1.3 2.55-5.4 10.75-4.85 22.85.45 8.15 2.15 16 3.3 14.8 17.4 19.2Q7.65 162.4 5.55 151q-2.1-11.45-.4-19.25.3 7.85 1.65 15.5 1.6 8.35 8.5 13 .45.3 1 .65 11.85 6.4 25 7.95 3.6.45 7.35.5-.175-.044-.4-.05 2.376-.006 4.4-.45-5.2-5.7-9.3-11.8-12.9-19.2-15-42.85.55-1.9 1.15-3.8l.1-.65q.45-1.15.7-1.95l.1-.4q.25-.95.55-1.9.35-1.15.7-2.35.5-1.85 1.05-3.75 1.2-4.2 2.9-8.2-.1 7.85-.15 15.7-.1 14.8 3.85 26.05 3.95 11.2 8.6 15.5 2.2 2.05 4 4.75-1.8-4.95-4.1-13.25-5-17.9 4.1-63.1 1.45-4.7 3.1-8.75.55-1.35 1.15-2.65 7-15.5 16.75-20.15.65-.35 1.35-.6 1.65-.55 3.3-1 .5-.1 1.05-.2 1.4-.35 2.85-.65 1.85-.4 3.75-.75 3.7-.7 7.5-1.15 1.95-.25 3.95-.4 1.35-.15 2.7-.2 14.65-.7 24.85 5.75l.3.2q.1-.1.65-.45 1.75-1.1 3.6-1.95 8.95-4.1 20.8-3.55 1.35.05 2.7.2 2 .15 3.95.4 3.8.45 7.5 1.15M55.9 54.85q.056-.11.15-.25l.05.05q-.14.094-.2.2z"
                    id="HaiMed004__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.9 88.4q-.15.45-.3.95-.25.65-.5 1.35-.25.75-.4 1.5-3.1 8.75-3.7 20.4-.6 11.65 16.65 20.6-7.6 1.55-15.7-2.85 2.7 4.6 12.95 8.1.8.3 1.7.6 1.45.45 3.15.95.9.05 1.8.15 2.45.2 4.95.45 2.5 0 4.65-.45-5.2-5.7-9.3-11.8-12.9-19.2-15-42.85-.4 1.2-.8 2.45-.1.2-.15.45m96.8-71q-.2-.15-.35-.3-.2-.1-.3-.2l-.4-.35.4.3v-.05Q78.65-8.8 56.25 4.65q-11.7 6.15-18.9 21.05-2 4.45-3.7 9.05-.6 1.6-1.15 3.2-1.75 5-3.1 10.15-.035.105-.05.2.016-.081.05-.15 1.45-4.7 3.1-8.75.55-1.35 1.15-2.65 7-15.5 16.75-20.15.65-.35 1.35-.6 1.65-.55 3.3-1 .55-.15 1.15-.3 1.1-.3 2.2-.5 2.15-.45 4.3-.8 3.7-.7 7.5-1.15 1.95-.25 3.95-.4 1.35-.15 2.7-.2 14.65-.7 24.85 5.75m93.25 92.45q-.25-1-.5-3.05-.15-.9-.25-1.75-.25-1.55-.55-3.15-5.3 19.55-19.05 35.6 3.8.5 8.4 0l3.2-.5q.25-.05.55-.1l1-.2.45-.1h-.1q-2.4-.3-4.35-1.95l-.05-.05q-.9-.9-1.7-2.15 14.15-3 13.6-19.3-.05-1.25-.65-3.3m-21.25-64.3q.5 1.55 1 3.15-.5-1.8-1-3.55-.25-.75-.45-1.45-2.8-9.2-6.7-18-.3-.65-.65-1.3-.3-.6-.6-1.15-6.95-12.95-17.65-18.6-22.4-13.45-44.8 12.15v.05l.4-.3-.4.35q-.1.1-.3.2-.145.145-.25.3.108-.153.35-.25 1.75-1.1 3.6-1.95 8.95-4.1 20.8-3.55 1.35.05 2.7.2 2 .15 3.95.4 3.8.45 7.5 1.15 2.15.35 4.3.8 1.1.2 2.2.5.6.15 1.15.3 1.65.45 3.3 1 .7.25 1.35.6 2.65 1.25 5.15 3.35 3.85 3.3 7.25 8.65 4.05 6.4 7.35 15.75.2.6.45 1.2z"
                    id="HaiMed004__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed004__Symbol_60_0_Layer0_0_FILL"
                transform="translate(68.75 49.25)"
                id="HaiMed004__col1n"
            />
            <use
                xlinkHref="#HaiMed004__Symbol_32_0_Layer0_0_FILL"
                transform="translate(91.25 77.95)"
                id="HaiMed004__col1d"
            />
        </g></g>
    )
}

export default HaiMed004
