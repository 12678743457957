import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M12.8 10.65l5.65-4.3h-7L9.2 0 6.9 6.35H0l5.55 4.3-1.95 6.5 5.6-3.85 5.55 3.85-1.95-6.5z"
                    id="SkiTat003__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat003__Symbol_3_0_Layer0_0_FILL"
                transform="translate(196.7 292.05)"
                id="SkiTat003__col1n"
            />
        </g></g>
    )
}

export default SkiTat003
