import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF008C"
}

function ShiMot005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.2.25q-4.45 0-7.6 3.15-2.85 2.85-3.1 6.7-.25-4.05-3.15-6.95Q15.2 0 10.75 0 6.3 0 3.15 3.15 0 6.3 0 10.75q0 3.65 2.1 6.4.15.2.35.4.3.4.7.8.5.5 1.05.9l17.65 17.3 17.3-17.3.65-.65.65-.65q2.5-2.95 2.5-6.95 0-4.45-3.15-7.6Q36.65.25 32.2.25z"
                    id="ShiMot005__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <g id="ShiMot005__col1n" transform="translate(171.05 264.05)">
                <use
                    xlinkHref="#ShiMot005__Symbol_5_0_Layer0_0_FILL"
                    id="ShiMot005__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default ShiMot005
