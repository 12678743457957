import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#F3B701",
    "col2n": "#FFD446",
    "col2s": "#E09F00"
}

function AccJew022({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M8.2 11.75V2Q8.2.8 7.45.3 7 0 6.2 0H2Q1.2 0 .75.3 0 .8 0 2v9.75q0 2 2 2h4.2q2 0 2-2z"
                    id="AccJew022__Symbol_35_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M0 9.8h4.6V0h-.8v9H0v.8z"
                    id="AccJew022__Symbol_1_0_Layer0_0_FILL"
                />
                <g
                    id="AccJew022__Symbol_92_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M59.15 38.95l-.1-.1-.2.1h.3z" />
                    <path d="M121.55.95q.15-.2.15-.4 0-.25-.15-.4-.2-.15-.4-.15-.25 0-.4.2Q97.9 24.1 64 39.6q-.25.1-2.8 1.5-.15.05-.3.15-.2-.1-.45-.2-2.5-1.35-2.7-1.45Q23.8 24.1.95.2.8 0 .6 0 .35 0 .2.15 0 .3 0 .55q0 .2.15.4Q23.1 25 57.3 40.6q.2.1 1.55.8.75.35 1.2.35-1 .6.05.3.55-.2 1.05-.35.15-.05.35-.1.7-.2 1.4-.45 1.3-.45 1.55-.55Q98.6 25 121.55.95z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccJew022__Symbol_92_0_Layer0_0_FILL"
                transform="translate(131.65 228.65)"
                id="AccJew022__col1n"
            />
            <use
                xlinkHref="#AccJew022__Symbol_35_0_Layer0_0_FILL"
                transform="translate(188.4 268.45)"
                id="AccJew022__col2n"
            />
            <use
                xlinkHref="#AccJew022__Symbol_1_0_Layer0_0_FILL"
                transform="translate(190.05 270.45)"
                id="AccJew022__col2s"
            />
        </g></g>
    )
}

export default AccJew022
