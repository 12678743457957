import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#B4DBFF",
    "col1n": "#E0F0FF"
}

function SkiMor028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.05 22.6l-.2.5Q0 25 0 27.2q0 4.2 2.95 7.15t7.15 2.95q4.15 0 7.15-2.95 2.95-2.95 2.95-7.15 0-2.85-.95-4.65L10.4 0 1.05 22.6m9.25-2.05l.5.5q1.3 1.65 1.3 3.95 0 2.25-1.3 3.85-.25.25-.5.55-.25-.3-.5-.55-1.35-1.6-1.35-3.85 0-2.3 1.35-3.95l.5-.5z"
                    id="SkiMor028__Symbol_34_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.4 15.2Q0 18.6 0 20.55v.1q.05 2.8 2 4.75 2 2 4.8 2 2.85 0 4.85-2 2-2 2-4.75v-.1q0-1.45-.75-3.65-.45-1.35-1.15-2.95l-.5-1.1L6.9 0 1.4 15.2z"
                    id="SkiMor028__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M259.35 137.8q0 2.25 1.25 3.85 1.3 1.65 3 1.65 1.8 0 3-1.65.7-.8 1-1.75.3-1 .3-2.1 0-2.25-1.3-3.9-1.2-1.6-3-1.6-1 0-1.85.6-.65.35-1.15 1-1.25 1.65-1.25 3.9z"
                    id="SkiMor028__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor028__Symbol_34_0_Layer0_0_FILL"
                transform="translate(251 112)"
                id="SkiMor028__col1n"
            />
            <use
                xlinkHref="#SkiMor028__Symbol_33_0_Layer0_0_FILL"
                transform="translate(254.5 118.65)"
                id="SkiMor028__col1d"
            />
            <use xlinkHref="#SkiMor028__Layer1_0_FILL" />
        </g></g>
    )
}

export default SkiMor028
