import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSpe009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M32.75 131.8l-.55.1h.55v-.1m138-65.4q-3.2-3.9-6.95-7.65-16.7-16.7-37.5-23.45 0-.05-.05-.05l-.75-.1q0-.05.05-.1.1-.15.25-.25 1-1.25 2.1-2.45l1.1-1.2q.45-.55.95-1.1l1.2-1.7q-1.5-.6-6.4-1.45-4.9-.9-7-1.05 1.15-5.2 2.75-11.85.4-1.75.85-3.65-2.4 1.7-4.6 3.65-2.4 2.1-4.55 4.45-.5-1.95-1-4.45-1.05-5.35-1.9-13.2-3.75 8.25-6.75 13.2-1.95 3.15-3.55 4.95-.95-2.1-1.65-4.95-1.4-5.55-1.85-14h-.3l-8.7 18.45Q85.3 16.1 84.3 14q-3.1-6.5-4.2-10.6-.75 5.25-1 10.6-.3 6.7.1 13.5-4.95-7-5.1-11.15-1.2 8.3-.25 16.6-26.05 5.75-46.1 25.8-5.6 5.6-10.1 11.7l.3-.4q-.128.197-.25.4Q1.15 93 0 121.65l6 6q1.1-8.85 3-16.8 2.25-9.8 5.65-18.2Q21.8 74.9 35.1 72.05q3-.65 5.25-.8l12.9-.8q8.2-.2 12.9-2.1l.7-.3q1.05-.5 2.05-.95 5.15-2.15 9.85-2.3.65 1.7 1.5 3.05l.4.6q1.4 2 3.25 3.2h.15q4.45 2.7 8.4-1.7 2 1.35 5.3 3.25 4.15 2.45 6.7 1.3l3.8-6.05q.4-.65.85-1.35 9.15 3.45 16.65 4.65 7.5 1.15 11.25-.15 3.7-1.4 8.05-1.2 2.2.1 5 .5 2.85.4 6.25 1.15 13.3 2.85 20.45 20.6 3.25 8.05 5.5 17.35.1.4.2.85 1.85 7.95 2.95 16.8l6-6q-1.15-28.95-17.5-51.2-1.5-2.05-3.1-4l-.05-.05z"
                    id="HaiSpe009__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M41.8 6.45q1-1.25 2.1-2.45L45 2.8q.45-.55.95-1.1l1.2-1.7L27.9 3.4q0 .05-.05.1l-.05.05-.2.3q-1.1 1.35-2.25 2.9-.1.05-.15.15h-.1q-1.2 1.45-5.45 6.45-4.3 5-13.25 10.25 3.85-.7 7.4-1.55 3.55-.85 7.35-1.75.05-.05.15-.05-.05.05-.05.1-5.2 7.1-11.85 12.85-1.25 1.1-2.35 2.4-3.6 4-7.05 7.65.05 0 .05.05 4.45 2.7 8.4-1.7 2 1.35 5.3 3.25 4.15 2.45 6.7 1.3l3.8-6.05q.4-.65.85-1.35l.2-.3q2.15-4.15 4.4-7.65 2.8-4.45 5.75-7.85l.75-.7-2.65.65q-1.2.25-2.45.55-2.15.45-4.4 1.15-.5.15-3 .35 2-2.75 2.7-3.6.7-.85.8-.85h-.1q.05-.05.1-.05.05-.05.1-.05 1.1-1 2.1-1.95 2.65-2.5 4.75-4.45 4.9-4.6 6.75-6.55.2-.25.4-.4.1-.15.2-.25 0-.05.05-.1.1-.15.25-.25m-16.35.2v.05l-.05.05q.049-.052.05-.1z"
                    id="HaiSpe009__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M88.15 18.2q-.85-1.65-1.65-3.15Q81.55 5.4 80.1 0q-1.65 11.9-.9 24.1-4.95-7-5.1-11.15-1.2 8.3-.25 16.6v.05l-1.4.35q-.15.05-.25.05.15-.05.25-.1l1.4-.35q-26.05 5.75-46.1 25.8-5.6 5.6-10.1 11.7l.3-.4q-.128.197-.25.4Q1.15 89.6 0 118.25l6 6q1.1-8.85 3-16.8 6.5-31.65 15.8-40.4 4.8-4.55 10.8-7.85 6-4 12.45-6.9 13.4-5.95 27.2-8.45 1.6 6.1 2.65 12.95.25 1.7.45 3.5h.1v.25q.15.45.3.85.65 1.7 1.5 3.05l.4.6q1.4 2 3.25 3.2h.15q0-.05-.05-.05 3.45-3.65 7.05-7.65 1.1-1.3 2.35-2.4 6.65-5.75 11.85-12.85l-.1-.05q-3.8.9-7.35 1.75t-7.4 1.55q8.95-5.25 13.25-10.25 4.25-5 5.45-6.45h.1q.05-.1.15-.15 1.15-1.55 2.25-2.9l.2-.3q-.75 0-18.15 5.8l-3.05 1.05q-.1.05-.15.05l.1-.1L98.6 22q-2.65.85-5.35 2.1-1.1.5-1.8.75l-3.3-6.65m85.75 48.85q-1.5-2.05-3.1-4l-.05-.05q-3.2-3.9-6.95-7.65-16.25-16.25-36.4-23.1-.55-.2-.9-.3-.2-.05-.35-.1-.069 0-.3-.05-.1-.05-.15-.05h-.2q-.1.1-.2.25-.15.1-.25.25-.1.05-.15.15-1.85 1.95-6.75 6.55-2.1 1.95-4.75 4.45l3.25.55q9.4 1.75 18.65 5.1 4.05 1.45 8.05 3.25 6.45 2.9 12.45 6.9 6 3.3 10.8 7.85 9.15 8.65 15.65 39.55.1.4.2.85 1.85 7.95 2.95 16.8l6-6q-1.15-28.95-17.5-51.2z"
                    id="HaiSpe009__Symbol_56_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe009__Symbol_141_0_Layer0_0_FILL"
                transform="translate(96.7 27)"
                id="HaiSpe009__col1n"
            />
            <use
                xlinkHref="#HaiSpe009__Symbol_80_0_Layer0_0_FILL"
                transform="translate(180.7 55.35)"
                id="HaiSpe009__col1d"
            />
            <use
                xlinkHref="#HaiSpe009__Symbol_56_0_Layer0_0_FILL"
                transform="translate(96.7 30.4)"
                id="HaiSpe009__col1l"
            />
        </g></g>
    )
}

export default HaiSpe009
