import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho054({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M203.55 33.85v-.2q-18.4-6.8-35.2-11.05-24.15-6.05-45-6.75-3.35-.15-6.6-.1 2.7-1.1 6.6-2.35 5.3-1.65 12.9-3.5 13.1-3.25 18.95-4.8l-4.7-.2q-17.05-.1-27.15.9-2.95.25-5.3.6-8.75 1.3-14.2 2.6-6.8 1.5-15.5 4.5-.55.1-.95.3 1.5-2.35 3.1-4.05Q94.95 4.8 103.7 0q-.85.1-2 .2-24.25 2.3-34.85 25-1.95-1.15-4.2-2.25-7.75-3.65-14.8-3.65-6.05 0-8.95 1.95 4.45.35 8.7 1.9 4.2 1.55 7.2 4.4-18.05-3.35-28.95 5.25 9.3-1.4 17.35 1.8h-.4q-15.25 3.3-27.15 13.35-4.5 3.8-8.5 8.6Q2.3 61.4 1.1 64.7v.25q4.55-3.8 10.6-6.9 1.6-.8 3.95-1.85 2.65-1.3 6.25-2.9Q-1.3 70 .05 104.5l.65 8.4q1.15-17.65 5.7-24.3 4.35-6.7 6.7-6.7l.45-.2q-2.15 2.35-3.6 5.25Q5.1 95.4 5 107.75q-.05 21.85 12.9 32.4-5.15-13.45-3.7-28.1.05-.95.2-1.9l.3-2.3q-.1.7.75 7.85.1.6.2 1.25.95 4.6 4.5 10.35 1.6 2.55 3.7 5.3-.5-2.8-.85-5.3-1.3-8.4-1.2-13.85.1-7.25.35-13.9.2-6.8 3.45-17.2v.2q-4.1 15.3 3.75 31.4-.75-19.05 5.9-28.8-.3 1.8 1.5 7.1 1.75 5.15 3.65 8.6v-.25q1.6-13.55 5.15-22.25 3.55-8.6 5.7-9.9.9 2.95 2.5 6.6 2.15 4.6 6.35 6.85.2.1.5.35-2.05-13.4 4.55-25.8.05 2.95.95 6.45.85 3.5 2.7 5.9 4.4 7.5 15.05 10.1-7-8.6-6.15-14.1 12.05 17.8 34 18.75l-.35-.05q-8.35-4.9-11.9-10.2-3.7-5.25-4.85-10.75 15.05 16.8 38.6 26.15 19.75 8.7 20.75 14.05-1.4-9.45-13.45-22.25-3.75-3.95-6.9-6.75 1.2.75 2.5 1.7 11.6 9.15 20.2 16.35 14.85 16.35 19.8 35.1h.2v-1.95q-.85-10-7.95-27.15-7.45-16.45-8.6-19.55v-.2q.05.05.3.5 19.35 22.9 26.55 50.3v-2.4q-.65-12.55-6.45-29.7-2.35-7-4.5-12.25 17.05 21.9 24.15 49.5 2.3-10.75 1.9-14.9 1.75 5.7 4.2 19.4l.15 1.5q.25 2.75.2 5.4.65-11.8.75-17 0-18.2-4.75-33.15 2.55 1.85 2.75 2 .6.4 1.2.85 9.7 7.15 16.5 18.05-.9-3.55-1.75-6.95-.55-2.25-1.4-4.55-8.1-25.4-22.85-40.6 8.7 1.25 21.8 6.75 8.55 3.6 13.05 6.65-14-20.8-53.8-38.05-5.65-2.5-9.4-4-2.4-1-4-1.55 2-.25 4-.4 16.05-1.45 33.4.2.95.1 1.8.2m-111.3-4.3l.1-.1q-.098.103 0 .3-.1-.1-.1-.2z"
                    id="HaiSho054__Symbol_175_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M24.4 12.25Q30.1 4.95 39.65 0 27.1 3.7 23.15 7.05q-4 3.35-5.85 7.15-7-5.1-10.55-3.25H6.3q4.55 1.7 6 4.9-4.55-.3-7.8 1.7Q1.2 19.5 0 21.4q1.25-.45 2.8-.9 8.35-2.25 10 .05-2.55.9-5.4 6.2-2.9 5.3-3.25 8.3.8-1 1.55-1.95.3-.35.5-.65 4.9-6 9.45-9.25-.55 4.25 1.7 10.45 2.25 6.2 5.2 8.7Q21.45 38.5 20.1 29q-1.4-9.55 4.3-16.75M81.7 40.8q3.95 2.65 6.55 5.05 2.55 2.25 12.9 14.3-4.4-16.6-18.3-29.25-.15-.15-.2-.3 22.3 10.2 35.2 32.7l.4.7q-.05-.2-.05-.4-3.05-14.2-7.05-21-4.1-6.9-6.45-10.25 1.8.1 6.2 2.35 4.3 2.4 8.95 5.65 4.5 3.2 7.75 7.1 1.8 2.25 8.15 12.25-5.7-12.25-11.15-19.15-4.65-5.85-9-9.05 1 .1 2.35.2 8.75.55 28 7.55-21.15-13.55-38.35-19.6-7.55-3-16-4.85 5.9-3.85 13.95-5.15Q89.3 5.6 80.7 5.45q-3.75-.1-8.8 0-5.05.55-6.65.75l-7.35.75q-.3.15-.6.25 7.05.65 10.95.85 6.55.45 19 2.7-9.3 4.65-10.2 5.85 6.45 1.75 14.65 3.8 8.15 2.05 19.95 7.1-.3-.05-.6-.05-.7-.1-1.4-.15-9.9-1.6-19.7-1.95 4.95 3.75 8.15 8.75-10.4-6.7-31.55-13.3-.376-.235-.55-.4l.3.3q3.55 3.75 8.95 8 5.7 4.4 9 7.45v.45q-8.6-5.35-18.9-9.2Q55 23.55 51.9 20.1q2.8 6.45 6.65 14.95l3.15 6.6-1.4-.95Q45.45 30.2 36.85 17.4q-3.95 9.55-1.8 21.55l1.7 5.75q.3-13.35 1.6-17.8l.05.05Q51.85 45.3 71.25 53.2 64.9 42.55 61.4 30.55q4.35 2.9 9.85 5Q77.7 38 81.7 40.8z"
                    id="HaiSho054__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho054__Symbol_175_0_Layer0_0_FILL"
                transform="translate(84.6 36.7)"
                id="HaiSho054__col1n"
            />
            <use
                xlinkHref="#HaiSho054__Symbol_40_0_Layer0_0_FILL"
                transform="translate(138.65 55.1)"
                id="HaiSho054__col1l"
            />
        </g></g>
    )
}

export default HaiSho054
