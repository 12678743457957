import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5400"
}

function ShiSho019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.5 6.1q-.35-.8-.7-1.55-1.15-2.35-2.3-4.55Q77.35 23.8 6.75.2 5.2 3.05 3.8 6.1q-.1.2-.2.45l-2.15 5q-.35.85-.65 1.7-.25.55-.45 1.15-.2.45-.35.95l18.95 7.05v.05l-.5 2.7q.05 0 .15.05l-.6 2.9q-.1.15-.2.35l-1.55 8q-.55 2.7-1.1 5.45L11.7 56.2q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H140.75q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75 19.1-7.1q-.2-.5-.35-.95-.45-1.1-.85-2.15-1.25-3.15-2.65-6.15z"
                    id="ShiSho019__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho019__Symbol_27_0_Layer0_0_FILL"
                transform="translate(117.35 241.6)"
                id="ShiSho019__col1n"
            />
        </g></g>
    )
}

export default ShiSho019
