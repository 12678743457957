import { AssetSvgProps } from "../../../shared/assets"

function ShiMot043({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 269.8v12.15q34.195 6.238 66.5 0V269.8q-33.25-4.961-66.5 0z"
                    id="ShiMot043__Layer5_0_FILL"
                />
                <path
                    fill="red"
                    d="M159.2 281.95v11.85h66.5v-11.85h-66.5m0-23.95v11.8h66.5V258h-66.5z"
                    id="ShiMot043__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot043__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot043__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot043
