import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#86DE00"
}

function ShiDre061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M137.4 39.2h-.05l-3.45-15q-1.05-2.55-2.35-4.85-5.35-9.3-15.95-15.05-8.3-3.8-16.45-4-12.3.6-23.8 10.6-.1.05-.2.15-2.25 1.85-4.5.4-.4-.35-.75-.65Q57.6 1.3 46.05 0 38.9 0 28.5 5.85q-12.7 9.55-16.85 22.9h-.05L8.65 40.5q-.2.15-.35.3-1.8 1.25-3.2 2.5-2.25 2-3.45 4-2.4 3.75-1.35 7.55 2.05-2.9 6.95-5 .4 3.5 3.3 7.5 2.7-3.7 8.3-6.3 1.3 4.1 6.6 7.6 3.9-3.05 8.35-4.65 2.8 3.55 7.6 6.2 4.55-3 10.65-4.05 2.5 3.5 5.75 6.05l7.3-5 8 5.4 6.45-5.5 7.55 4.35q3.95-1.85 7-5.2 4.4 1.45 8.6 4.35 4.25-2.6 7.2-6.85 5.5 1.2 9.9 4.85 4.85-2.25 7.25-7.1 6 .15 9.25 4.75l1.05-1.2q1-1.2 2.65-5.5 5.45 1.75 8.55 5.2-.4-10.8-11-14.7l-.15-.85z"
                    id="ShiDre061__Symbol_258_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre061__Symbol_258_0_Layer0_0_FILL"
                transform="translate(119.25 263)"
                id="ShiDre061__col1n"
            />
        </g></g>
    )
}

export default ShiDre061
