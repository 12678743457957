import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF7B00",
    "col2l": "#43A6FF",
    "col2n": "#0087FF"
}

function ObjMus003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#151515"
                    d="M246.15 300.5v2.15q2.075 2.91 4.15 0v-2.15h-4.15m-19.35 7.1q-3.012 5.05 0 10.1 12.098 3.186 23.4 0 2.454-5.05 0-10.1-11.7-3.092-23.4 0m13.5-7.1v2.15q2.075 3.405 4.15 0v-2.15h-4.15m-1.4 2.15v-2.15h-4.25v2.15q1.358 3.452 4.25 0m60.3-127l4.85-1.6q-4.978 48.886-57.3 71.25-5.717 2.7-17 6-11.255 3.298-20.7 3.55-9.703.255-15.1 3.55h.05q-4.541 2.658-7.05 9.75H186.5q-3.686-8.878-19.5-14.45v-.05q-20.444-5.76-28-8.9-.688-.3-1.35-.55-51.752-21.908-56.75-70.15l4.9 1.6q21.637-11.95 0-23.9l-4.65 1.35q1.48-29.416 19.75-53.2l-2.95-1.45q-17.777 23.185-21.75 54.4-1.536 11.005.05 19.65l2.75.9v.05l.35.1q.084.231.15.45 4.9 49.192 57.6 71.5h.05q.638.25 1.3.55 7.594 3.16 28.15 8.95h-.05q14.934 5.273 18.6 13.55-.332.237-.55.6-.35.55-.35 1.15 0 .95.3 1.75.409 1.041 1.2 1.3.025.814.05 1.7l.25 3.2q.041.108.05.2.262 4.493 9.35 7.95 6.628 2.54 15.8 6.7v.05q8.272 3.746 13.2 6.25.546.277 1 .5.699.364 1.3.75h.05q1.21.726 2.2 1.5.343.248.65.5l.5 1.6 1.4.85q-.2-2.4-.15-2.45h.35q-.717-.692-1.9-1.6-1.06-.826-2.35-1.6-.495-.32-1.05-.6-.16-.093-.35-.2-5.083-2.623-14.25-6.8-9.228-4.19-15.9-6.75-8.014-3.044-8.45-6.75v-.05q-.171-1.717-.25-3.2-.061-.908-.1-1.75.851-.156 1.2-.75.3-.5.3-1.55 0-1.387-.45-2.1 2.286-6.806 6.5-9.35 5.153-3.105 14.4-3.35 9.605-.248 21.05-3.6 11.417-3.35 17.2-6.1l-.05.05q53.248-22.76 58.2-72.6l.15-.5 3.1-1q1.6-8.65 0-19.65-3.9-31.2-21.7-54.35l-2.95 1.45q18.25 23.75 19.15 52.95l-4.05-1.15q-9.105 13.088 0 23.9z"
                    id="ObjMus003__Layer13_0_FILL"
                />
                <path
                    fill="#999"
                    d="M184.4 58.6v.1q1.88-.133 3.75-.25l3.85-.05h1l3.8.05q1.924.117 3.8.25v-.1q40.918 2.505 70.55 32 26.65 26.55 31.3 62.2l2.8.9q-2.9-30-21.15-53.75-5-6.5-11.15-12.55Q241.287 56.458 197 55.25h-9.05q-44.235 1.205-75.9 32.15-6.154 6.033-11.15 12.5-18.27 23.784-21.15 53.8l2.8-.9q4.672-35.654 31.3-62.2 29.633-29.495 70.55-32z"
                    id="ObjMus003__Layer13_1_FILL"
                />
                <path
                    fill="#4D4D4D"
                    d="M228.7 309.35v6.35h19.55v-6.35H228.7m3.5 4.1q-.2-.15-.25-.35-.2-.25-.2-.6 0-.3.2-.5.05-.15.25-.4.35-.35.9-.35.2 0 .45.05.25.1.45.3.4.4.4.9 0 .55-.4.95-.2.25-.45.35-.25.05-.45.05-.55 0-.9-.4m10.25 0q-.4-.4-.4-.95 0-.5.4-.9.35-.35.9-.35.6 0 .9.35.45.4.45.9 0 .55-.45.95-.3.4-.9.4-.55 0-.9-.4z"
                    id="ObjMus003__Layer13_2_FILL"
                />
                <path
                    fill="#797979"
                    d="M240.95 312.45v.15q0 .95.7 1.65.75.75 1.7.75 1.05 0 1.75-.75.75-.7.75-1.75 0-.95-.75-1.7-.7-.7-1.75-.7-.95 0-1.7.7-.7.75-.7 1.65m1.1.05q0-.5.4-.9.35-.35.9-.35.6 0 .9.35.45.4.45.9 0 .55-.45.95-.3.4-.9.4-.55 0-.9-.4-.4-.4-.4-.95m-10.65-1.7q-.25.3-.4.55-.35.55-.35 1.15 0 .65.35 1.3.05.1.2.25l.2.2q.7.75 1.7.75h.45q.75-.15 1.35-.75.7-.7.7-1.75 0-.95-.7-1.7-.6-.55-1.35-.65-.25-.05-.45-.05-.65 0-1.15.35-.3.1-.55.35m.55 1.2q.05-.15.25-.4.35-.35.9-.35.2 0 .45.05.25.1.45.3.4.4.4.9 0 .55-.4.95-.2.25-.45.35-.25.05-.45.05-.55 0-.9-.4-.2-.15-.25-.35-.2-.25-.2-.6 0-.3.2-.5z"
                    id="ObjMus003__Layer13_3_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M15.95 48.7V7q-.4-2.65-2.25-4.55Q11.35 0 8.05 0q-3.4 0-5.7 2.45Q.25 4.65 0 7.5v40.7q.25 2.85 2.35 5.05 2.3 2.45 5.7 2.45 3.3 0 5.65-2.45 1.85-1.9 2.25-4.55m197.45-.5V7.5q-.25-2.85-2.35-5.05-2.3-2.45-5.7-2.45-3.3 0-5.65 2.45-1.85 1.9-2.25 4.55v41.7q.4 2.65 2.25 4.55 2.35 2.45 5.65 2.45 3.4 0 5.7-2.45 2.1-2.2 2.35-5.05z"
                    id="ObjMus003__Symbol_89_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M28.25 19.35V0H7.05Q7 .05 5.8.05V0H0v19.35h28.25m-2.4-14.4v10.1H2.45V4.95h23.4z"
                    id="ObjMus003__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M28.25 0H7.05Q7 .05 5.8.05V0H0v2.6h28.25V0z"
                    id="ObjMus003__Symbol_6_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMus003__Layer13_0_FILL" />
            <use xlinkHref="#ObjMus003__Layer13_1_FILL" />
            <use xlinkHref="#ObjMus003__Layer13_2_FILL" />
            <use xlinkHref="#ObjMus003__Layer13_3_FILL" />
            <use
                xlinkHref="#ObjMus003__Symbol_89_0_Layer0_0_FILL"
                transform="translate(85.8 135.85)"
                id="ObjMus003__col1n"
            />
            <use
                xlinkHref="#ObjMus003__Symbol_20_0_Layer0_0_FILL"
                transform="translate(224.35 302.65)"
                id="ObjMus003__col2n"
            />
            <use
                xlinkHref="#ObjMus003__Symbol_6_0_Layer0_0_FILL"
                transform="translate(224.35 302.65)"
                id="ObjMus003__col2l"
            />
        </g></g>
    )
}

export default ObjMus003
