import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#3F0080",
    "col1n": "#5F00C2"
}

function ObjToy083({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#2F2F2F"
                    d="M330.75 388q.052.038.05.05 81.19 29.468 154.25 44.6.324.1.6-.1.267-.167.3-.5.1-.323-.1-.6-.155-.267-.5-.35-72.928-15.118-154-44.55-3.773-1.652-9.15-3.9-5.082-2.123-7.05-3.05h.05q-5.693-2.766-18-9.45-2.798-1.546-9.2-5.8-6.817-4.528-8.9-5.85-18.123-11.81-31-26.8-8.6-9.976-17.7-25.4-10.698-18.015-23.95-27.6-13.004-9.412-26.75-9.4-9.706.004-14.35 2.45-3.546 1.845-5.75 6.25-.114.292 0 .6.105.32.4.45.292.164.6.05.32-.105.45-.4 1.946-3.895 5.05-5.55 4.406-2.255 13.6-2.25 13.254.013 25.8 9.1 12.998 9.415 23.5 27.1 9.2 15.575 17.9 25.65 13.023 15.159 31.35 27.1 2.067 1.328 8.85 5.85 6.498 4.296 9.35 5.85 12.343 6.716 18.05 9.5 1.982.923 7.1 3.05v.05q5.387 2.237 9.15 3.85z"
                    id="ObjToy083__Layer11_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M8.4 31.25q.1-.2.25-.3-.05 0-.1.05-.1.1-.15.25m58-17.35q-.85-.75-1.7-1.4-6.15-4.95-13.45-7.8l-4.3-1.6Q38.7.25 30 .2q-1 0-2.15.05-1.2.05-2.3.1Q23.2-.2 20.9.15q-1.7.3-3.4 1.15-4.55 2.15-6.25 7-.5 1.45-.7 2.95-.35.55-.7 1.2-.7 1.2-1.35 2.45l.05.05.45.95q.25.3.5.75.45.85 2.1 3.25 1.4 2.35 1.4 6.55 0 2.4-1.75 5.2Q8.7 35.7 4 35.7q-2.1 0-3.1-.3-.4 1.6-.8 3.3-.6 4.35 2.55 5.45 3.75.8 5.6-2.7 2.6-5.05 4.75-8.65.05-.1.1-.15 1.9-2.85 4.55-5.15.5.85.75 2.05 0 .25.05.4.05 1.6.75 3.15.8 1.55 1.9 2.55 1.2 1.15 2.85 1.7 1.7.6 3.4.5 1.5-.1 3-.8 3.1-1.5 4.3-4.8 1.1-3.2-.35-6.35-.1-.3-.25-.55-.65-1.6-.45-3.15l.2.05h.25q.1.05 3.15.3 3 .2 6.15 2 3.1 1.8 3.75 2.2.65.35.8.5l.05.05q-1.05 1.4-2.75 2.45-3.1 1.5-4.25 4.75-1.2 3.25.3 6.4.8 1.55 1.9 2.55 1.2 1.15 2.85 1.7 1.7.65 3.35.55 1.55-.15 3.05-.85 1.5-.7 2.5-1.85l2.2-1.5q.6 3.55.35 6.8v.2q-.65 4.15-1.85 9.7-.7 3.9 2.75 5.65 3.15 1.15 5.45-2.6 6.9-14.15 8.9-26.55.1-.75.15-1.4.85-1.25 1.4-2.75 1.7-4.8-.55-9.4-.8-1.65-1.95-3-1.5-1.7-3.75-2.75l-1.6-1.5z"
                    id="ObjToy083__Symbol_208_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.25 30.7q-.05 0-.1.05-.1.1-.15.25.1-.2.25-.3m19.9-6.8q-.55-.2-1.1-.3-1.6-.2-3.1.5-.35.15-.7.4-.6.4-1 .9-.75.8-1.15 1.95-.8 2.15.2 4.2 1 2.1 3.15 2.9 2.25.75 4.3-.2 2.1-1.05 2.85-3.2.8-2.2-.2-4.25-.95-2.05-3.05-2.85-.1 0-.2-.05M2.15 11q-.35.55-.7 1.2-.15 2.9 1.2 5.85Q4.2 21.2 6.95 23q1.5 1.15 2.1 3.2l-.1.1q-1.9 2.15-4.25 6.1 1.9-2.85 4.55-5.15.5.85.75 2.05-.05-.5.05-1.1 0-.8-.1-1.45-.05-.55-.2-1-.6-2.1-2.1-3.3-2.8-1.8-4.35-4.95-1.55-3.2-1.15-6.5m19.9 3.5q1.15-3.25-.3-6.3-1.5-3.1-4.7-4.3-3.2-1.1-6.35.4Q7.65 5.75 6.5 9q-1.15 3.25.3 6.25 1.5 3.15 4.7 4.3t6.35-.4q3.1-1.4 4.2-4.65M46.6 42.65l-.1.1 2.2-1.5q.6 3.55.35 6.8.65-4.7.5-7.5 1.7-1.25 3.65-1.25 3.3.35 6.4-1.1 2.95-1.4 4.7-3.75.1-.75.15-1.4-1.8 2.75-4.95 4.3-3.15 1.55-6.45 1.2-1.95-.05-3.7 1.25-.4.3-.9.7-.5.4-.95.95-.4.7-.9 1.2M42.55 22.1q1.7-4.85 6.3-7.05 2.9-1.35 5.95-1.15 1.75.1 3.5.7.7.25 1.3.55l-1.6-1.5q-1.55-.55-3.15-.65-3.3-.2-6.45 1.35-4.9 2.3-6.7 7.45-.25.75-.4 1.55-.05.85-.35 1.55-.2.4-.75 1.25-6.7-4.3-15-5.35.2-.95.35-1.35.2-.75.85-1.4.3-.75.55-1.5 1.8-5.15-.5-10-1.55-3.2-4.2-5.05Q21 .6 19.45 0q-1.2.05-2.3.1.7.15 1.4.4 1.8.7 3.25 1.7 2.35 1.7 3.75 4.65 2.25 4.6.6 9.45-.45 1.1-1 2l-.2.4q-.45.85-.45 1.5-.05.15 0 .3.05.1.05.8 0 .15-.05.35.05.15 0 .25.05 0 0 .05V22q.05.05.05.15.1 1.15.65 2.15.1.25.3.6l.05.05q0 .05.1.15-.65-1.6-.45-3.15l.2.05h.25l.2.1q5.95 1.05 11.1 3.5 1.1.55 2.1 1.15.3.1.45.25l.05.05q-1.05 1.4-2.75 2.45.4-.2.85-.35h.1l.5-.2q1.1-.5 1.9-1.3.05-.1.15-.2.1-.15.25-.25.55-.75.6-.8.1-.1.2-.25.1-.05.15-.15.25-.55.4-1.3.05-.25.05-.45.2-1.05.6-2.15z"
                    id="ObjToy083__Symbol_207_0_Layer0_0_FILL"
                />
                <g id="ObjToy083__Layer2_0_FILL">
                    <path
                        fill="red"
                        d="M167.9 301q.45-1.15-.1-2.2-.5-1.05-1.65-1.45-1.15-.4-2.2.05-1.05.55-1.5 1.7-.4 1.1.15 2.15.5 1.15 1.65 1.55 1.1.35 2.2-.15 1.05-.5 1.45-1.65z"
                    />
                    <path
                        fill="#D9D9D9"
                        d="M182.25 296.5q-.6-.2-1.2-.1-.65.15-.95.7-.1.55.05 1.55.25 1.1-.15 1.85-.25.75-.2 1.6.1 1 .9 1.35.95.3 1.75-.45.6-.6 1.05-1.9.4-1.05.25-2.45-.25-1.7-1.5-2.15m-11.2-4.45q.05.6.65.85.55.15 1.55-.1 1.1-.1 1.9.2.75.3 1.55.2 1.05-.1 1.35-.85.3-1-.4-1.75-.65-.65-1.9-1.1-1.1-.4-2.5-.2-1.7.2-2.1 1.45-.2.65-.1 1.3m-28.9 13.6l1-3.05-2.9-1.05 1.05-2.95-3-1.1-1.1 2.95-3-.95-1.05 3 2.95 1-1 2.95 3 1.1 1.05-3 3 1.1m13.5-12.35q.25-.75-.1-1.5-.3-.7-1.1-.95-.75-.25-1.55.05-.7.35-.95 1.15-.25.7.1 1.4.3.8 1.05 1.05.8.25 1.55-.05.75-.35 1-1.15m-20.25-12.15q-1.55-.55-3 .15-1.45.65-2.05 2.25-.5 1.55.2 3t2.25 2.05q1 .35 2 .2.1-.05.25-.05.4-.1.75-.35 1.55-.7 2.05-2.25.55-1.5-.15-3-.7-1.45-2.3-2m1.95-9.35q-2.05-.2-3.6.4-1.05.35-1.4.95 2.3-.4 4.65.2 1.1-.15 2.3-.15 1.1-.05 2.15 0-.25-.3-.7-.5-1.35-.8-3.4-.9z"
                    />
                    <path
                        fill="#00DEDE"
                        d="M175.45 295.1q-1.6-.55-3.15.15-1.6.75-2.15 2.4-.6 1.55.15 3.15.75 1.6 2.4 2.15 1.6.6 3.15-.15 1.55-.75 2.2-2.35.5-1.65-.2-3.25-.8-1.5-2.4-2.1z"
                    />
                    <path
                        fill="#3F0080"
                        d="M177.8 286.9q.85.7 1.7 1.5 2.15.95 3.7 2.75.15-.65-.5-1.65-.8-1.4-2.5-2.5-1.7-1.25-3.3-1.5-.4-.05-.75-.05.85.65 1.65 1.45z"
                    />
                    <path
                        fill="#E09F00"
                        d="M157.95 305.2l-2.95 3.25.3 4.3 3.15 2.85 4.4-.2 2.85-3.2-.2-4.35-3.25-2.9-4.3.25m-.8 3.95q.45-1.35 1.8-1.95 1.25-.65 2.6-.15 1.4.5 1.95 1.85.6 1.2.15 2.55-.45 1.4-1.8 2-.3.15-.65.2-.15.1-.25.1-.85.15-1.7-.15-1.35-.45-2-1.8-.65-1.3-.1-2.65z"
                    />
                    <path
                        fill="#FFD446"
                        d="M158.95 307.2q-1.35.6-1.8 1.95-.55 1.35.1 2.65.65 1.35 2 1.8.85.3 1.7.15.1 0 .25-.1.35-.05.65-.2 1.35-.6 1.8-2 .45-1.35-.15-2.55-.55-1.35-1.95-1.85-1.35-.5-2.6.15z"
                    />
                    <path
                        fill="#999"
                        d="M136.05 279.6l-4.35.25-2.95 3.15.3 4.35 3.2 2.95 4.35-.3 2.8-3.2-.2-4.35-3.15-2.85m-3.65 1.7q1.45-.7 3-.15 1.6.55 2.3 2 .7 1.5.15 3-.5 1.55-2.05 2.25-.35.25-.75.35-.15 0-.25.05-1 .15-2-.2-1.55-.6-2.25-2.05-.7-1.45-.2-3 .6-1.6 2.05-2.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjToy083__Layer11_0_FILL" />
            <use
                xlinkHref="#ObjToy083__Symbol_208_0_Layer0_0_FILL"
                transform="translate(111.45 273)"
                id="ObjToy083__col1n"
            />
            <use
                xlinkHref="#ObjToy083__Symbol_207_0_Layer0_0_FILL"
                transform="translate(119.85 273.25)"
                id="ObjToy083__col1d"
            />
            <use xlinkHref="#ObjToy083__Layer2_0_FILL" />
        </g></g>
    )
}

export default ObjToy083
