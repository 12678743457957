import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col1s": "#49657E"
}

function SkiSki001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M191.5 95.8q0-39.7-28.05-67.75-16.2-16.2-36.3-23.05-14.7-5-31.4-5t-31.4 5q-20.1 6.85-36.3 23.05Q0 56.1 0 95.8q0 32.25 18.5 56.85l.05.05q.6.75 1.2 1.55l1 1.2L28 163.5q0 .05.05.05l.7.7q3.5 3.4 7.15 6.4v.05l-.05-.05q-5.81 7.718-10.6 17.75-.078.183-.2.35l-.3.7q-.06.17-.15.35-.117.253-.25.5l-2.6 6.15q-5.345 13.608-8.3 30.1-.05.2-.05.4-.15 2.45.15 4.85Q15.7 245.9 31 249.35q.2.05.45.1l.05-.05q.15.05.25.05.2.6.4 1.15.4.95 1.1 1.75 0 .05.05.1v19.45q-3.9.7-7.8 2.5-.6.267-1.2.55-.94.67 0 .8h143.25q1 0 0-.6-.775-.405-1.55-.75-3.9-1.8-7.8-2.5v-19.45q.05-.05.05-.1.7-.8 1.1-1.75.2-.55.4-1.15.1 0 .25-.05l.05.05q.25-.05.45-.1 15.3-3.45 17.45-17.55.3-2.4.15-4.85 0-.2-.05-.4-3.7-20.65-11.15-36.75-4.8-10.45-11.15-18.95h-.05q0-.1-.1-.2 3.65-3 7.15-6.4l.7-.7q.05 0 .05-.05l7.25-8.05 1-1.2q.6-.8 1.2-1.55l.05-.05q18.5-24.6 18.5-56.85z"
                    id="SkiSki001__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M-36.05 227.75h-.2l-3.9.15-1.3.1q-8.5.6-13.15 1.65-4.7 1-5.35 1.3-.15.05-.25.1 8-1.3 16.1-1.8.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q15.4-.65 30.05 2l4.15.7q1.15.2 2.3.45 1.75.35 3.45.75l.3.1q.1 0 .2.05.15 0 .3.05-.4-.15-.8-.25-1.7-.6-3.45-1.05-.55-.15-1.05-.3-.15-.05-.25-.05-10.8-3-22-3.6-.4-.05-.8-.05-.55-.05-1.15-.05l-6.5-.05m3.4-52.2q3.05 1.2 6.25 0 1.6-1.65.35-3.6-.1-.15-.15-.25-3.05-1-6.9 0-.5.7-.6 1.4-.2 1.1.8 2.25.1.1.25.2M3.2 203.7q-1.25-1.15-2.25 0-.05.25 0 .55.25 1.6.15 3.25l.1.05q.7.3 1.05-.35l.1-.2q.15-.45.3-.85.25-.8.4-1.55.05-.15.05-.3l.1-.6m55.4 25.95q-4.65-1.05-13.15-1.65l-1.3-.1-3.9-.15h-.2l-6.5.05q-.6 0-1.15.05-.4 0-.8.05-11.2.6-22 3.6-.1 0-.25.05-.5.15-1.05.3-1.75.45-3.45 1.05-.4.1-.8.25.15-.05.3-.05.1-.05.2-.05l.3-.1q1.7-.4 3.45-.75 1.15-.25 2.3-.45l4.15-.7q14.65-2.65 30.05-2h.3q.15 0 .35.05l2 .1q.35 0 .65.05 8.1.5 16.1 1.8-.1-.05-.25-.1-.65-.3-5.35-1.3M29.55 173.1q-.2 1.1.8 2.25.1.1.25.2 3.05 1.2 6.25 0 1.6-1.65.35-3.6-.1-.15-.15-.25-3.05-1-6.9 0-.5.7-.6 1.4z"
                    id="SkiSki001__hll_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M-54 165.45q-.05.05-.05.1l-4.3 21.85q-.45 2.1-.85 4.05l-.1.6q-.2.8-.35 1.6-1.5 7.35-2.3 12.15-.75 4.4.45-.6.4-2.5 1.45-6.25.1-.6.25-1.25.7-3.15 1.3-5.65.65-3.05 1.15-5.1h.05l-.05-.05v-.05q.2-.9.35-1.55.2-.95.4-1.55v-.05q0-.15.05-.25.15-.75.2-.85l3.25-16.8.05-.05q0-.25-.1-.4-.1-.2-.3-.2-.2-.05-.3 0-.05 0-.05.05-.05 0-.1.05t-.1.2m81.8 53.1q5.828-.808 11.3-1.95-.15 0-.3.05-.95.05-1.9.15-.75.05-1.5.15-13.1 1.2-26.3 1.45-4.5.1-9 .1-17-.1-34.1-1.8-.65-.1-1.2-.15 5.808 1.176 11.85 2 11.28 1.525 23.45 1.85h.55v.1l1.4 12.2v-.4l.05.4 1.4-12.2v-.1h.05q.15 0 .35.05l5.2-.2q9.762-.455 18.7-1.7m30.1-53.2l-.05-.05q-.15-.05-.35 0-.2 0-.3.2-.1.15-.05.4l3.25 16.85q.1.2 1.05 4.3v.05q.45 2 1.1 4.95.55 2.4 1.2 5.4l.1.4q.25 1.2.5 2.25 1 3.35 1.35 5.75 1.05 4.3.3.1-.9-5.3-2.65-13.9-.4-2.15-.9-4.5L58.1 165.7v-.1q-.1-.2-.2-.25m2.25-34.1q-1.35 1.05-2.8 2.1l-1.55 1.15q-20.35 14.1-45.8 16l-1.1.05q-3.35.2-6.8.2h-.2q-3.45 0-6.8-.2l-1.1-.05q-25.45-1.9-45.8-16l-1.55-1.15q-1.45-1.05-2.8-2.1l-1.15-.9q10.019 8.81 21.45 14.1 17.928 7.84 37.75 7.85h.2q19.823-.01 37.75-7.85 11.45-5.3 21.45-14.05l-1.15.85z"
                    id="SkiSki001__hdd_0_Layer0_0_FILL"
                />
                <filter
                    id="SkiSki001__Filter_1"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    colorInterpolationFilters="sRGB"
                >
                    <feColorMatrix
                        in="SourceGraphic"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 0.75 0"
                        result="result1"
                    />
                </filter>
            </defs>
            <use
                xlinkHref="#SkiSki001__Symbol_10_0_Layer0_0_FILL"
                transform="translate(96.75 58)"
                id="SkiSki001__col1n"
            />
            <use
                xlinkHref="#SkiSki001__hll_0_Layer0_0_FILL"
                transform="matrix(1.00052 0 0 1 190.5 98.75)"
                id="SkiSki001__col1d"
            />
            <use
                filter="url(#SkiSki001__Filter_1)"
                xlinkHref="#SkiSki001__hdd_0_Layer0_0_FILL"
                transform="matrix(1.00052 0 0 1 190.5 98.75)"
                id="SkiSki001__col1s"
            />
        </g></g>
    )
}

export default SkiSki001
