import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#999999"
}

function ObjMor014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M18.3 32.35q-2.15-2.15-2.15-5.15-2.45-.5-3.5 1.5 2.4.5 4.2 2.8 2.1 2.65 2.4 6.25v1q0 3.3-1.45 5.85.2.35.4.75.05.15.15.3 5.55 10.3 7.9 11.8 1.1.7 1.4-.6l.1.15q.6-2.25-2.45-11.65-.35-1.15-.8-2.4-.7-2.2-1.4-4.35l-1.9-4.4q-1.6-.55-2.9-1.85M6.3.9Q5.65.7 5.55.75h-.1q-.2-.2-.6-.4Q4.1 0 3.3 0 1 0 1 2.05q0 .2-.15.4Q.7 2.6.5 3.4q-.2.65-.2.9H.25l.05.05v.25q-.05.05-.05-.05-.25.3-.25.8Q0 6.9 1.1 6.9h.25l8.2 19.2h.05l.15.3q3.85 0 6.8-2.95L7.2 1.85q0-.05-.05-.2-.1-.05-.15-.1V1.5q-.3-.3-.6-.5-.05-.05-.15-.1h.05z"
                    id="ObjMor014__Symbol_124_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M18.45 28.9l-.25-.55-6.8 2.95.9 2.2q.25-.05.5-.05.75 0 1.5.15l3.5-1.5V32q0-1.7.65-3.1M.45 9.8q-.2.1-.25.25-.05.2 0 .4 3.35 11.35 9.05 21.2.05.2.25.25.2.1.4 0 .15-.05.2-.25 0-.05.05-.1.05-.1 0-.25-.1-.15-.15-.35-4.65-12.35-7.85-18.8.35-.05.9-.25l-.8-1.8h.05l-.4-.9-1.45.6M0 1.95q.05.05 0 .1l2.4 5.7q1.5-2.65 4.8-2.1L4.85.1q-.65-.15-.8-.1Q1.9.05.25 1.65q-.15.05-.25.3z"
                    id="ObjMor014__Symbol_28_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M136 321.7l-.1-.15-1.4.6h-.05l1.6 3.7q.05.15.4.25.25.05.55-.05.3-.15.45-.4.1-.25.05-.4l-1.5-3.55z"
                    id="ObjMor014__Layer4_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor014__Symbol_124_0_Layer0_0_FILL"
                transform="translate(108.25 264.7)"
                id="ObjMor014__col1n"
            />
            <use
                xlinkHref="#ObjMor014__Symbol_28_0_Layer0_0_FILL"
                transform="translate(106.6 259.8)"
                id="ObjMor014__col2n"
            />
            <use xlinkHref="#ObjMor014__Layer4_0_FILL" />
        </g></g>
    )
}

export default ObjMor014
