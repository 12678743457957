import { AssetSvgProps } from "../../../shared/assets"

function ObjMor050({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ObjMor050__Layer5_0_FILL">
                    <path
                        fill="#004621"
                        d="M261.45 292.35l4.7-24.75q-19-7.095-29.65-17.9-19.462 2.315-36.95-4.25l-18.3-2.1-2.4 12.65 11.85-.05 31.6 14.15q-7.054 23.7-3.2 47.4 20.385-3.918 36.95 3.55-1.898-14.734 5.4-28.7z"
                    />
                    <path
                        fill="#6CF"
                        d="M272.2 237.5q-7.946 1.5-15.3-3.5l-.3.05-9.3-2.05.3-.1-5-1.1-7.6-1.65q-16.043 7.022-36.35 8.05l.9 8.25q17.488 6.565 36.95 4.25 10.65 10.805 29.65 17.9l-4.7 24.75 10.75-2.75q-4.677-26.05 0-52.1z"
                    />
                    <path
                        fill="#EF3B68"
                        d="M132.3 299.65l-.55 8.4q21.132 3.462 30.4 17.8 29.239-7.15 56.5-1.45-2.83-3.45.45-6.9-3.854-23.7 3.2-47.4l-31.6-14.15-11.85.05q-21.03 3.966-43.75.3 3.839 21.675-2.8 43.35z"
                    />
                    <path
                        fill="#78D917"
                        d="M153.85 229.95q-5.875 5.828-11.75.4-12.728 9.573-31 15.45 8.165 27.05 5.9 54.1 8.578-4.766 15.3-.25 6.639-21.675 2.8-43.35 22.72 3.666 43.75-.3l2.4-12.65 18.3 2.1-.9-8.25q-4.92-4.387-.95-8.8-22.823 3.47-43.85 1.55z"
                    />
                    <path
                        fill="red"
                        d="M154.75 217.85q4.975 6.05-.9 12.1 21.027 1.92 43.85-1.55-3.97 4.412.95 8.8 20.307-1.028 36.35-8.05l7.6 1.65 1.1-14.5-.6.15q-28.432.459-55.05-8.15-14.164 7.758-33.3 9.55z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_0_FILL">
                    <path
                        fill="#C60000"
                        d="M184.15 277.75l22.35 3.75 4.1-54.9-22.4-3.5q-16.147 27.325-4.05 54.65m-29.4-59.9l-4 54.25 21.65 3.7q8.688-26.466 4.05-54.5l-21.7-3.45z"
                    />
                    <path
                        fill="red"
                        d="M224.35 222.35h.05q1.904 26.75-4 53.5l8.4-3.35q-8.093-26.4 3.9-52.8l10.4-3.25-55.05-8.15-33.3 9.55 21.7 3.45q4.638 28.034-4.05 54.5l11.75 1.95q-12.097-27.325 4.05-54.65l22.4 3.5 13.75-4.25z"
                    />
                    <path
                        fill="#8E0000"
                        d="M224.4 222.35h-.05l-13.75 4.25-4.1 54.9 13.9-5.65q5.904-26.75 4-53.5m19.3-6.05l-.6.15-10.4 3.25q-11.993 26.4-3.9 52.8l11.05-4.55 3.85-51.65z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_0_MEMBER_0_FILL">
                    <path
                        fill="#003ABD"
                        d="M176.5 221.3h-.05l-4.05 54.5 11.75 1.95 4.05-54.65q-.305-5.059-11.7-1.8z"
                    />
                    <path
                        fill="#001F64"
                        d="M232.7 219.7q-12.62-1.028-8.3 2.65l-4 53.5 8.4-3.35 3.9-52.8z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_1_FILL">
                    <path
                        fill="#6CF"
                        d="M210.75 240.35l-13.3-2.85v52.1l13.3 3.45q2.881-26.35 0-52.7m9.05 1.95h-.05q-6.713 27.207 0 53l.3.1 14.8 3.75.05.05v-.05l.05-50.75-.05-2.85-15.1-3.25z"
                    />
                    <path
                        fill="#28A9E9"
                        d="M249.9 295.3q6.256-26.5 0-53l-14.9 3.2-.05 2.9-.05 50.75 15-3.85m22.3-57.8l-13.3 2.85q-6.084 26.756 0 52.7l13.3-3.45v-52.1z"
                    />
                    <path
                        fill="#9FDFFF"
                        d="M272.2 237.5l-15.3-3.5-.3.05-9.3-2.05.3-.1-12.6-2.75-37.55 8.35 13.3 2.85q2.881 26.35 0 52.7l9 2.25q-6.713-25.793 0-53h.05l15.1 3.25.05 2.85.05-2.9 14.9-3.2q6.256 26.5 0 53l9-2.25q-6.084-25.944 0-52.7l13.3-2.85z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_1_MEMBER_0_FILL">
                    <path
                        fill="#D40045"
                        d="M219.75 242.3l-9-1.95v52.7l9 2.25v-53z"
                    />
                    <path
                        fill="#87002C"
                        d="M258.9 240.35l-.1.05-8.9 1.9v53l9-2.25v-52.7z"
                    />
                    <path
                        fill="#FF4F89"
                        d="M249.65 242.25h-.1q0 .05.05.1l.3-.05 8.9-1.9q-.05-.05-.05-.1h-.1l-14.8-3.4 12.75-2.85-9.3-2.05-12.45 2.85L222.4 232l-9.35 2.05 12.75 2.85-14.8 3.4 9.05 1.95 14.8-3.3 14.8 3.3z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_2_FILL">
                    <path
                        fill="#5DB801"
                        d="M197.7 228.4l-17.35 9.15h-.05l-12.75 6.7L173.5 299l29.8-19.1-5.6-51.5z"
                    />
                    <path
                        fill="#78D917"
                        d="M111.1 245.8l5.9 54.1 56.5-.9-5.95-54.75-56.45 1.55z"
                    />
                    <path
                        fill="#A3FF48"
                        d="M142.1 230.35l-31 15.45 56.45-1.55 12.75-6.7h.05l17.35-9.15-55.6 1.95z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_2_MEMBER_0_FILL">
                    <path
                        fill="#3000A8"
                        d="M188.6 233.25l-8.25 4.3 5.8 53.4 8.15-5.25-5.7-52.45z"
                    />
                    <path
                        fill="#9469FF"
                        d="M133.05 245.25l11.9-.4 12.3-6.7 23.1-.6 8.25-4.3-23.3.55 8.45-4.55-12.25.4-8.2 4.5-19.9.55-8.8 4.4 20.75-.6-12.3 6.75z"
                    />
                    <path
                        fill="#5B1EF0"
                        d="M144.95 244.85l-11.9.4 5.9 54.3 11.9-.2-5.9-54.5z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_3_FILL">
                    <path
                        fill="#004621"
                        d="M266.15 267.6L210 261.5l-10.25 54.15 56.3 5.4 10.1-53.45z"
                    />
                    <path
                        fill="#001E0E"
                        d="M197.85 253.8h-.05l-16.55-10.45-9.65 51.1 28.15 21.2L210 261.5l-12.15-7.7z"
                    />
                    <path
                        fill="#007939"
                        d="M197.8 253.8h.05l12.15 7.7 56.15 6.1-29.65-17.9-55.25-6.35 16.55 10.45z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_3_MEMBER_0_FILL">
                    <path
                        fill="#BFDBE9"
                        d="M222.25 317.8l11.9 1.15 10.2-53.7q-5.925-5.248-11.85-1.3l-10.25 53.85z"
                    />
                    <path
                        fill="#99B9C9"
                        d="M197.8 253.8q-.266-2.812-7.35-4.65l-9.9 52 7.25 5.4 10-52.75z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_4_FILL">
                    <path
                        fill="#EF3B68"
                        d="M200.3 270.4l-34.5.45-3.65 55 56.5-1.45 3.65-54.3-21.95.35-.05-.05z"
                    />
                    <path
                        fill="#B9225A"
                        d="M152.75 264.7l-17.65-8.4-3.35 51.75 30.4 17.8 3.65-55-13-6.15h-.05z"
                    />
                    <path
                        fill="#FF648B"
                        d="M135.1 256.3l17.65 8.4h.05l13 6.15 34.5-.45.05.05 21.95-.35-31.6-14.15-55.6.35z"
                    />
                </g>
                <g id="ObjMor050__Layer5_1_MEMBER_4_MEMBER_0_FILL">
                    <path
                        fill="#FFC300"
                        d="M200.35 270.45l-.05-.05q-5.925-5.235-11.85.2l-3.7 54.7 11.95-.35 3.65-54.5z"
                    />
                    <path
                        fill="#EA0"
                        d="M152.75 264.7q-2.494-4.22-8.5-4.05l-3.45 52.7 8.35 4.9 3.6-53.55z"
                    />
                </g>
                <path
                    fill="#0452FF"
                    d="M224.4 222.35l8.3-2.65v-.25l-22.45-3.5 9.15-3-12.1-1.8-8.9 2.9-19.65-3.05-9.5 2.7 20.55 3.25-13.3 4.35 11.7 1.8 13.35-4.35 22.8 3.6h.05z"
                    id="ObjMor050__Layer5_1_MEMBER_0_MEMBER_1_FILL"
                />
                <path
                    fill="#3000A8"
                    d="M131.95 218.65l-6.55 1.7q.4 2.55 2.85 5.45 4.05 4.9 11.35 7.9 7.3 3.05 13.65 2.45 1.35-.15 2.45-.35l1.3-.4q.6.15 1.4.4 1.1.25 2.45.4 6.25.75 13.65-2.2 7.4-2.85 11.6-7.75 2.45-2.8 2.9-5.35l-6.6-1.8q-6.4 10.75-22.55 11.05-.65-.05-.8.4-.15.25-.35.45-.15.15-.3.35-.1.2-.3.5-.55.75-1.05 1.7-.5-.95-1.05-1.75-.25-.25-.4-.45-.15-.2-.15-.4-.15-.2-.35-.45-.2-.45-.85-.4-16-.6-22.3-11.45z"
                    id="ObjMor050__Layer5_1_MEMBER_2_MEMBER_1_FILL"
                />
                <path
                    fill="#9469FF"
                    d="M177.7 260.4l7.15-7.2q-6.45-17.8-21-21.75-2.5 2.35-4.55 5.1 13.05 6.4 18.4 23.85m-23.25-23.95q-1.85-2.75-4.4-5.1Q135.4 235 128.6 252.7l7.2 7.3q5.5-17.35 18.65-23.55m2.1-.05h1.65q9.35-13.2 30.7-14.95.65-6.9-5.15-9.55-12.55 1.8-20.8 9.1-3.8 3.1-6.3 8.9-1.85-5.25-5.85-9.05l-.1-.1q-7.75-7.3-20.2-9.35-5.8 2.6-5.35 9.45 21.4 2.15 30.35 15.5.3.05 1.05.05z"
                    id="ObjMor050__Layer5_1_MEMBER_2_MEMBER_1_MEMBER_0_FILL"
                />
                <path
                    fill="#E9F7FF"
                    d="M253.25 259.8l-8.4-5.05L225 252.6l-7.85-5.1-12.1-1.4 8.05 5.2-22.6-2.4-.05.25 7.35 4.65h.05l22.9 2.5 11.75 7.65 11.85 1.3-11.75-7.7 20.65 2.25z"
                    id="ObjMor050__Layer5_1_MEMBER_3_MEMBER_1_FILL"
                />
                <path
                    fill="#FFEB93"
                    d="M144.25 260.65l8.5 4.05 23.15-.3 12.55 6.2 11.85-.2-12.5-6.15 20.7-.25-8.95-4.05-19.9.25-8.45-4.1H159l8.6 4.25-23.35.3z"
                    id="ObjMor050__Layer5_1_MEMBER_4_MEMBER_1_FILL"
                />
                <path
                    fill="#EA0"
                    d="M148.75 247.6q.25 2.55 2.55 5.6 3.8 5.1 11 8.45 7.15 3.4 13.5 3.1 1.35-.1 2.45-.25.7-.15 1.35-.35.6.2 1.35.5 1.1.3 2.45.5 6.2 1.05 13.7-1.55 7.6-2.5 12-7.15 2.6-2.75 3.15-5.25l-6.5-2.15q-6.95 10.5-23.05 10-.65-.05-.85.4-.15.2-.35.4-.15.15-.3.35-.15.2-.35.5-.6.7-1.1 1.65-.5-1-1-1.8-.2-.3-.35-.5-.15-.2-.15-.4-.15-.2-.3-.45-.2-.45-.85-.45-15.95-1.35-21.75-12.55l-6.6 1.4z"
                    id="ObjMor050__Layer5_1_MEMBER_4_MEMBER_2_FILL"
                />
                <path
                    fill="#FFEB93"
                    d="M186.75 260.05q-2.6 2.2-4.8 4.85 12.75 7 17.25 24.7l7.55-6.85q-5.65-18.1-20-22.7M154.4 238.4q-5.95 2.3-5.8 9.2 21.3 3.15 29.6 16.95.3.05 1.05.1.65 0 1.65.05 9.95-12.75 31.4-13.5.95-6.85-4.7-9.75-12.65 1.2-21.25 8.05-3.95 2.95-6.7 8.65-1.6-5.35-5.4-9.35l-.1-.1q-7.4-7.65-19.75-10.3m-3.85 41.15l6.8 7.65q6.4-17.05 19.8-22.6-1.75-2.85-4.15-5.3-14.8 2.95-22.45 20.25z"
                    id="ObjMor050__Layer5_1_MEMBER_4_MEMBER_2_MEMBER_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor050__Layer5_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_2_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_2_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_2_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_2_MEMBER_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_3_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_3_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_3_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_4_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_4_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_4_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_4_MEMBER_2_FILL" />
            <use xlinkHref="#ObjMor050__Layer5_1_MEMBER_4_MEMBER_2_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjMor050
