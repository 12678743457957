import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF4DAD",
    "col1l": "#FFCDE8",
    "col1n": "#FF98D0"
}

function ObjMor082({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.4 10.7q-1.2-.95-2.55-1.55-.2 0-.25-.05-1.2-.45-2.45-.5-.55-.05-1.2-.05-1.2 0-2.35.2-.05-.3-.05-.5-.15-.45-1.2-2.85-.35-.9-2.05-2.65-1.95-1.8-4-2.2Q31.75.1 29.85 0h-2.6q-1.5.2-2.25.4-1 .15-1.2.75-.2.8 1.2 2.55 1.15 1.45 1.15 2.75 0 2.1-3.15 4.9-.85.75-2 1.55-.1.05-.15.15-.35.25-.65.45-1.5 1.25-2.5 2.7-.7.8-.95 1.7-.2.25-.35.65-.1.45-.3.95-.35-.1-.8-.1-1.3 0-2.35.4-.8.3-1.55.75-1 .6-1.9 1.7-.4.4-.65.9Q6.9 25.7 6.9 29.1q0 .35.05.65H6.8q-.8 0-1.5.2-.75.2-1.4.7-.8.45-1.45 1.35-.75.85-1.25 2.2-.15.25-.3.75-.9 2.2-.9 4.3 0 2.9 1.9 5.7l.25.25q1.2 1.7 3.15 3.3.7.7 1.65 1.4 4.3 3.15 8.35 4.7.4.15 4.6 1.5.2.1.55.2 14.1 3.2 26.65 0 3.1-.9 6.8-2.4.35-.1.6-.3.95-.4 1.85-.8h.1q4.8-2.4 7.45-5.25.25-.25.6-.45V47q1.05-1 1.75-2.05 1.5-2.3 1.5-5.05 0-2.8-1.55-5.15-.75-1.1-1.7-1.95-.2-.15-.25-.2-.75-.7-1.4-1.1.8-1.75.8-3.8 0-2.95-1.7-5.3-.6-.95-1.6-1.85-.45-.45-1-.85-2.8-2.1-6.45-2.1h-.2V17q0-3.5-2.9-6-.2-.1-.4-.3z"
                    id="ObjMor082__Symbol_171_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M3.35 1Q2.35.55.8 0 .6.8 2 2.55 3.15 4 3.15 5.3q0 2.1-3.15 4.9.25-.2.55-.35Q2.6 9 3.35 8.6q2.7-1.7 2.7-4.1 0-2.1-2.7-3.5m34 18.4q-.45-.45-1-.85 3.7 3.5 1.2 9.4-1.6 3.65-5.15 6.15 1.3.7 4.45-1.35 1.45-1.05 3-2.4.8-1.75.8-3.8 0-2.95-1.7-5.3-.6-.95-1.6-1.85M26.8 9.85q-.2-.1-.4-.3Q25.2 8.6 23.85 8q4.15 2.15 2.9 7.85-.85 3.65-4.25 6.35 1.55.6 4.2-1.1 1.95-1.9 3-4.65v-.6q0-3.5-2.9-6m14.7 36q1.05-1 1.75-2.05 1.5-2.3 1.5-5.05 0-2.8-1.55-5.15-.75-1.1-1.7-1.95-.2-.15-.25-.2 2.5 3.8-.1 10.85-2.4 5.4-7.7 9.35 4.8-2.4 7.45-5.25.25-.25.6-.45v-.1z"
                    id="ObjMor082__Symbol_113_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9.5 13.7q-.4.4-.65.9-1.95 2.55-1.95 5.95 0 .35.05.65H6.8q1.55 2.25 3.3 4 4.2 3.5 8.9 2.6-5-2.35-7.6-6l-.05-.1q-.1-.15-.15-.3l-.65-1.15q-2.25-4.3.85-8.25-1 .6-1.9 1.7M21 4.35q-.1.05-.15.15-.35.25-.65.45-1.5 1.25-2.5 2.7-.7.8-.95 1.7-.2.25-.35.65-.1.45-.3.95.8 1.65 1.95 2.7 3.75 2.95 7.95 2.8-4.05-2.6-5.3-5.2-.7-1.5-.8-3.3.3-2.45 3.1-5.15-.85.75-2 1.55m26.1 43.4q3.1-.9 6.8-2.4.35-.1.6-.3-.8.2-1.6.35-21.15 5.5-37.6-3.55-1.1-.55-1.95-1.15-2.1-1.25-3.85-2.8-4.2-2.85-5.9-6.8-.15-.2-.15-.4-2-6.4 1.85-9.3-.75.2-1.4.7-.8.45-1.45 1.35-.75.85-1.25 2.2-.15.25-.3.75-.9 2.2-.9 4.3 0 2.9 1.9 5.7l.25.25q1.2 1.7 3.15 3.3.7.7 1.65 1.4 4.3 3.15 8.35 4.7.4.15 4.6 1.5.2.1.55.2 14.1 3.2 26.65 0M40.85 6.2q3.45-2.1 3.3-6.15Q43.6 0 42.95 0q-1.2 0-2.35.2-.2 5.2-4.55 7.25 2.6-.2 4.8-1.25z"
                    id="ObjMor082__Symbol_96_0_Layer0_0_FILL"
                />
                <path
                    d="M312.95 342.5q-.15-.05-.35-.05-1.75 0-3.05 1.75-1.25 1.7-1.25 4.15 0 2.4 1.25 4.15 1.3 1.75 3.05 1.75H313q1.55-.2 2.7-1.75 1.3-1.75 1.3-4.15 0-2.45-1.3-4.15-1.15-1.55-2.7-1.7h-.05m-17-11.75q1.7 1.95 2.5 2.6-.05.3-.05.65v1.1q-.4-.3-.95-.85-.75-.55-1.2-.75h-.1q-.7-.3-.7.5 0 .3 1.3 1.45.95.8 1.6 1.15l-.05 1.15q0 .3.2.5t.45.2q.2.05.35-.05l.1-.15q.2-.15.2-.45l.1-3.5v-.15q0-2 1.45-3.5 1.45-1.4 3.45-1.4h.35q.15 0 .3.05 1.65.15 2.85 1.35 1.45 1.45 1.45 3.5v4q0 .3.2.45.25.2.6.1.05-.05.15-.05l.05-.05q.2-.05.25-.05.05-.1.05-.4v-3.9-.1q0-2.55-1.85-4.4v-.05q-1.7-1.65-4.05-1.75h-.35q-2.55 0-4.4 1.75l-.05.05q-.6.65-1 1.4-.15.2-.25.5-.1.2-.15.45-1.3-1.5-2-1.9l-.05-.05q-.75-.4-.75.6m28.7-1v-.05q-1.8-1.75-4.4-1.75-2.55 0-4.45 1.75v.05q-1.8 1.85-1.8 4.4v4q0 .3.1.4.1.05.45.15.35.1.5-.1.25-.15.25-.45v-3.9-.1q0-2.05 1.5-3.5 1.4-1.4 3.45-1.4 2 0 3.5 1.4 1.45 1.5 1.45 3.5v.15l.05 3.5q.05.3.2.45.25.25.5.2.25 0 .45-.2.15-.2.15-.5v-1.15q.65-.35 1.6-1.15 1.3-1.15 1.3-1.45 0-1.3-2 .25-.55.55-.95.85v-.7-.25q0-.4-.05-.8.8-.65 2.5-2.6 0-1.9-2.8 1.35-.4-1.3-1.5-2.35z"
                    id="ObjMor082__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor082__Symbol_171_0_Layer0_0_FILL"
                transform="translate(276.5 300.15)"
                id="ObjMor082__col1n"
            />
            <use
                xlinkHref="#ObjMor082__Symbol_113_0_Layer0_0_FILL"
                transform="translate(299.5 301.3)"
                id="ObjMor082__col1l"
            />
            <use
                xlinkHref="#ObjMor082__Symbol_96_0_Layer0_0_FILL"
                transform="translate(276.5 308.7)"
                id="ObjMor082__col1d"
            />
            <use xlinkHref="#ObjMor082__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjMor082
