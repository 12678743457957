import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#06ECEC",
    "col1n": "#6BFFFF"
}

function AccMas001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M177.1 2.75l.55-2.75q-33.2 21.25-59.55 27.25-.15-1.85-.2-2.85-28.35 6.45-58.7 5.85.05 1.5.15 3Q30.75 35.8 0 28.1l1.5 3.05q30.5 7.5 58.05 6.1 1.75 12.8 6.95 23.95-17.1 1.1-34.25-3.1L24.4 56l3.15 2.3 5.7 1.75q2.15.4 10.95 2.15 8.8 1.75 23.6 1.7.4.7 1.2 2.15h.15q22.85 3.3 47.65-5.7.1-.65.15-1.3 11.95-1.05 31.75-8.1l3.15-2.95q-22.35 7.95-34.65 8.9 1.25-13 .95-25.45 30.65-8.05 58.95-28.7z"
                    id="AccMas001__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9.95 36.95l-1.25-2.7q-.5.05-1.05.05l.85 2.65h1.45M0 10.4q.9-.05 1.75-.1l.1.7-.3-4.7Q.8 6.35 0 6.45v3.95m59.4 19.55l-.2 2.15q.7-.05 1.45-.15v-2.1q-.65.05-1.25.1m2.25-25.8V0q-.7.15-1.35.3l.05 4.2q.65-.15 1.3-.35z"
                    id="AccMas001__Symbol_58_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas001__Symbol_86_0_Layer0_0_FILL"
                transform="translate(108.8 173.15)"
                id="AccMas001__col1n"
            />
            <use
                xlinkHref="#AccMas001__Symbol_58_0_Layer0_0_FILL"
                transform="translate(166.6 200.1)"
                id="AccMas001__col1d"
            />
        </g></g>
    )
}

export default AccMas001
