import { AssetSvgProps } from "../../../shared/assets"

function SkiWou008({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#D19532"
                    d="M135.3 189.7l-12.6 4.25 5.65 16.1 12.5-4.55-5.55-15.8m-8 6.45q.7-.2 1.35.1.7.3.95 1 .2.7-.1 1.3-.3.7-1 .95-.7.2-1.4-.1-.65-.3-.9-1-.2-.7.1-1.35.3-.65 1-.9m6.9 1.35q-.7.25-1.4-.1-.6-.35-.9-.95-.25-.7.05-1.4.35-.65 1.1-.85.6-.3 1.25.05.7.3.9 1.05.3.6 0 1.2-.4.75-1 1m-.25 4.55q-.25-.65.05-1.25.35-.7 1-1 .65-.2 1.35.1.65.35.9.95.25.75-.1 1.4-.35.65-1 .9t-1.35-.1q-.65-.3-.85-1m-4.65-.15q.7-.25 1.3 0 .7.3.95 1.05.25.65-.1 1.3-.3.7-.95 1-.7.2-1.4-.15-.65-.35-.85-.95-.3-.75.05-1.4.3-.65 1-.85z"
                    id="SkiWou008__Layer7_1_FILL"
                />
                <g id="SkiWou008__Layer7_0_FILL">
                    <path
                        fill="#E4B670"
                        d="M142.1 214.75q1.3-1.95.7-3.8l-1.95-5.45q-7.968-1.352-12.5 4.55l.05.1 1.9 5.4q.65 1.75 2.85 2.35 2.35.75 4.95-.2 2.6-.9 4-2.95m-20.7-30.25q-1.35 1.95-.8 3.8l2.1 5.65q7.68.635 12.6-4.25l-2.05-5.8q-.7-1.75-2.95-2.5-2.35-.7-4.9.25-2.6.95-4 2.85z"
                    />
                    <path
                        fill="#FFF"
                        d="M125.35 196.55q-.2.35-.2 1.4 0 1.15.2 1.95.15.5.8 2.25.2.65 1 2.05l.35 1.05q-.05.3.3.65.4.4.95.4 1.15 0 5.3-1.7 1.1-.5 2.75-1.05 1.1-.45 1.1-1.3 0-.7-.5-2.4-.6-1.7-.7-2.1-.75-2-.9-2.25-1-1.95-2.25-1.95-.7 0-1.9.25-1.15.3-1.3.3-.9.15-1.75.55-.85.45-1.25.6-1.6.65-2 1.3m.9.45q.179-.296.45-.5-.25.225-.4.55-.3.65-.1 1.35l-.1-.05V198q0-.575.15-1m9-.55l-.05.05q.03-.06.05-.15.013.061 0 .1z"
                    />
                </g>
            </defs>
            <use xlinkHref="#SkiWou008__Layer7_0_FILL" />
            <use xlinkHref="#SkiWou008__Layer7_1_FILL" />
        </g></g>
    )
}

export default SkiWou008
