import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#F7E700",
    "col1n": "#FFFF00"
}

function LegSho005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M147.1 10.6V7.8q-.2-1.15-1.8-1.55-.75-.25-1.85-.3h-5.9V6h-.4V0h-127v6h-.6v-.05h-5.9q-1.1.05-1.85.3Q.2 6.65 0 7.8v2.8h147.1z"
                    id="LegSho005__Symbol_8_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M127 1.35V0H0v1.35h127z"
                    id="LegSho005__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho005__Symbol_8_0_Layer0_0_FILL"
                transform="translate(118.95 323.9)"
                id="LegSho005__col1n"
            />
            <use
                xlinkHref="#LegSho005__Symbol_9_0_Layer0_0_FILL"
                transform="translate(129.1 328.55)"
                id="LegSho005__col1d"
            />
        </g></g>
    )
}

export default LegSho005
