import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#FFFFFF"
}
function FacNos013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M26.45 12.55q4.3-3.75 4.75-6.65 0-2.65-3.05-3.9Q16.1-2 3.1 2q-.85.4-1.5.85Q0 4 0 5.9q.5 3.05 5.3 6.95 2.55 2.15 4.8 3.4.8.45 1.6.8 3.55 1.7 7.9 0 .7-.3 1.55-.8.8-.45 1.75-1.05 1.45-1 3.1-2.35.25-.15.45-.3z"
                    id="FacNos013__Symbol_24_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M13.1 0H4.2Q1.3.05.55.15 0 .3 0 .7q0 .85 2.45 1.45 5.85 1.55 12.45 0 2.4-.6 2.4-1.45 0-.4-.55-.55Q16 .05 13.1 0z"
                    id="FacNos013__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos013__Symbol_24_0_Layer0_0_FILL"
                transform="translate(176.9 191.95)"
                id="FacNos013__col1n"
            />
            <use
                xlinkHref="#FacNos013__Symbol_23_0_Layer0_0_FILL"
                transform="translate(183.85 197.5)"
                id="FacNos013__col2n"
            />
        </g></g>
    )
}
export default FacNos013
