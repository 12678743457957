import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8A6900"
}

function ShiMot035({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M9.15.55Q9.1.7 9 .9 3.2 12.4.55 25.9l-.05.2q-1.4 5.8.95 2.6 1.05-1.2 3.25-3.5 2.15-2.25 5.3-4.3 3.15-2.1 5.55.75l3.2 5.3q.4.55.4 0v-.5q-1.7-8.8-5.25-17.6-1.45-4.3-3.35-8-.7-1.55-1.4-.3z"
                    id="ShiMot035__Symbol_41_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot035__Symbol_41_0_Layer0_0_FILL"
                transform="translate(183 263.6)"
                id="ShiMot035__col1n"
            />
        </g></g>
    )
}

export default ShiMot035
