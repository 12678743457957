import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF"
}
function ObjMor098({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M189.55 271q-8.2 5.05-10.05 10.1h10.05V271m-22.85-8.15l11-1.7q-3.65-4.75-11.5-8.45l-11 1.7q1.2 4.75 11.5 8.45m21.95-3.35l-1.5-10-9.95 1.55q.85 6.4 11.45 8.45m-43.9 6.7l10.95-1.65q-3.1-4.75-11.45-8.45l-11 1.65q1.5 5.7 11.5 8.45m-28.6 17.9v3.65q8.545 1.744 8.85 9.7v1q-.107 4.739-2.65 8.05-2.6 3.5-6.2 3.55v5.9q0 5.45 5.45 5.45h59.65q5.45 0 5.45-5.45V284.1q-7.629-3.274-15.25-5.15 4.937-3.785 7-7.95h-11.1l-6.1 6.1q-4.532-.53-9.05-.6 2.69-2.598 4.05-5.5H145.1q-4.887 3.357-7.75 6.7-3.359.593-6.75 1.45 2.476-4.916 3.4-8.15h-11.9l6.45 8.7q-6.191 1.736-12.4 4.4m-4.4-23.05l1.55 9.95 3.25-5.65 10.9 3.5 6.3-.95q-3.6-5.85-11.5-8.45l-10.5 1.6z"
                    id="ObjMor098__Layer10_0_FILL"
                />
                <path
                    fill="#434343"
                    d="M113.3 263v18.1h16.3V271l-8-8h-8.3z"
                    id="ObjMor098__Layer10_1_FILL"
                />
                <path
                    fill="#666"
                    d="M123.2 276.55q0 .8.5 1.35.6.6 1.4.6.75 0 1.3-.6.6-.55.6-1.35 0-.75-.6-1.35-.55-.55-1.3-.55-.8 0-1.4.55-.5.6-.5 1.35m-4.55 1.35q.6-.55.6-1.35 0-.75-.6-1.35-.55-.55-1.35-.55-.75 0-1.35.55-.55.6-.55 1.35 0 .8.55 1.35.6.6 1.35.6.8 0 1.35-.6m-2.7-9.4q.6.6 1.35.6.8 0 1.35-.6.6-.55.6-1.35 0-.75-.6-1.35-.55-.55-1.35-.55-.75 0-1.35.55-.55.6-.55 1.35 0 .8.55 1.35z"
                    id="ObjMor098__Layer10_2_FILL"
                />
                <path
                    fill="#FFF"
                    d="M179.5 281.1l10.05-10.05h-11.1l-10.1 10.05h11.15m-13.3-28.35l11.5 8.45 10.95-1.7-11.45-8.45-11 1.7m-20.05 28.35h11.1l10.1-10.05H156.2l-10.05 10.05m-16.55 0h5.45l10.05-10.05H134l-4.4 4.35v5.7m-7.35-21.65l11.5 8.45 10.95-1.7-11.45-8.45-11 1.7m22-3.35l11.45 8.45 11-1.7-11.5-8.45-10.95 1.7z"
                    id="ObjMor098__Layer10_3_FILL"
                />
                <path
                    d="M116.15 281.1v3h70.55v-3h-70.55z"
                    id="ObjMor098__Layer10_4_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M40.6 29.3h.8v-7.95h20.3v-.8H0v.8h19.8v7.95h.85v-7.95H40.6v7.95M61.7 7.6v-.8H15.1v.8h46.6m0 6.8v-.8H15.1v.8h46.6m0-13.6V0H15.1v.8h46.6z"
                    id="ObjMor098__Symbol_246_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor098__Layer10_0_FILL" />
            <use xlinkHref="#ObjMor098__Layer10_1_FILL" />
            <use xlinkHref="#ObjMor098__Layer10_2_FILL" />
            <use xlinkHref="#ObjMor098__Layer10_3_FILL" />
            <use xlinkHref="#ObjMor098__Layer10_4_FILL" />
            <use
                xlinkHref="#ObjMor098__Symbol_246_0_Layer0_0_FILL"
                transform="translate(120.8 289.55)"
                id="ObjMor098__col1n"
            />
        </g></g>
    )
}
export default ObjMor098
