import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.4 2.65q-1.25-.5-3.7-1.55-3.25-1.65-6.55-.8-2.3.6-4.3 2.35-.4.25-.85.6-.2.1-.25.15-.15-.05-.3-.15-.45-.35-.85-.6Q14.6.9 12.3.3 9-.55 5.75 1.1 3.3 2.15 2.05 2.65 1 2.95.7 3.1l-.7.2 3.25 2.1 14.45.9h.05l14.45-.9 3.25-2.1-.7-.2q-.3-.15-1.35-.45z"
                    id="FacMou042__Symbol_67_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M26.1.5q-3.8.5-5.05 1.25-.3.15-.7.25h.1q-1.15.45-2.4.4h-.65q-1.25.05-2.4-.4h.1q-.45-.1-.7-.25Q13.15 1 9.35.5 5.55.05 0 0q4.45 7.3 10.65 9.45 7.6 2.2 14.2 0Q31 7.3 35.45 0 29.9.05 26.1.5z"
                    id="FacMou042__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou042__Symbol_67_0_Layer0_0_FILL"
                transform="translate(174.8 217.25)"
                id="FacMou042__col1n"
            />
            <use
                xlinkHref="#FacMou042__Symbol_20_0_Layer0_0_FILL"
                transform="translate(174.8 220.55)"
                id="FacMou042__col1l"
            />
        </g></g>
    )
}

export default FacMou042
