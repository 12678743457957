import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#9AFF00"
}

function ObjToy064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#A5B5B8"
                    d="M350.05 238.6q.439-9.679 1.2-19.2l-21-3.45q-12.794 48.666-6.65 74.8 6.192 26.177 6.45 29.25l21.05-16.85q-.35-4.585-.6-9.15.057-.005.1-.05h.05q1.039-.325 2.25-.7.363-.075.75-.2h.05q.05.005.1 0 .101.004.2-.05.554-.158 1.2-.25.025-.012.05-.05 2.002-.431 6.1-.95 1.176-.147 2.5-.3 6-.74 12.05-.05h.05q4.761.446 10.9 3.05.026.013.05 0 .25.104.5.2.025.037.05.05 7.866 3.341 18.05 8.95 7.852 4.39 14.3 7.3 12.081 5.43 32.35 9.4 17.58 3.465 24.95 3.15.293-.007.45-.2.21-.203.2-.5-.006-.243-.2-.45-.203-.16-.5-.15-7.28.285-24.65-3.15-20.081-3.93-32.05-9.3-6.402-2.89-14.2-7.25-10.233-5.623-18.15-9-.024.01-.05 0-.325-.162-.65-.3-6.331-2.648-11.25-3.1-6.213-.708-12.35.05-1.237.146-2.35.3-4.315.538-6.4 1h.05q-.717.103-1.35.3-.168.017-.35.05h.05q-.413.125-.8.2-1.139.347-2.15.65-.014-.763-.1-2.3v-.1q-1.302-26.361-.25-51.65z"
                    id="ObjToy064__Layer11_0_FILL"
                />
                <path
                    fill="#DFE3E2"
                    d="M330.25 215.95q-1.7-.175-3.4-.3-7.55-.595-15.1-.35-4.7.15-9.45.65 7.268 48.95 2.55 97.9-.15 1.9-.35 3.8 8.701 4.395 22.35 2.8 1.568-.187 3.2-.45-.259-3.076-.5-6.15-3.63-49.238.7-97.9z"
                    id="ObjToy064__Layer11_1_FILL"
                />
                <path
                    fill="url(#ObjToy064__Gradient_1)"
                    d="M317.3 284.25q-3.35 0-5.75 2.35-2.35 2.4-2.35 5.75 0 3.3 2.35 5.7 2.4 2.35 5.75 2.4 3.3-.05 5.7-2.4 2.35-2.4 2.4-5.7-.05-3.35-2.4-5.75-2.4-2.35-5.7-2.35z"
                    id="ObjToy064__Layer11_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M9.35 1.6Q7.75 0 5.5 0 3.2 0 1.6 1.6 0 3.15 0 5.45t1.6 3.9q1.6 1.55 3.9 1.6 2.25-.05 3.85-1.6 1.6-1.6 1.6-3.9T9.35 1.6m-.65.75q1.3 1.3 1.3 3.2 0 1.85-1.3 3.2-1.35 1.3-3.2 1.3t-3.15-1.3Q1 7.4 1.05 5.55q-.05-1.9 1.3-3.2 1.3-1.3 3.15-1.3 1.85 0 3.2 1.3m-2.45 2.5q-.3-.3-.75-.3t-.7.3q-.3.25-.3.7 0 .45.3.75.25.25.7.3.45-.05.75-.3.25-.3.3-.75-.05-.45-.3-.7z"
                    id="ObjToy064__Symbol_183_0_Layer0_0_FILL"
                />
                <path
                    fill="#D0D9D9"
                    d="M309.95 270.7q-.35.3-.35.75t.35.8q.3.3.75.3t.8-.3h-.05q.3-.35.35-.8-.05-.45-.35-.75h.05q-.35-.35-.8-.35-.45 0-.75.35m.55-16.7h-.05q-.35 0-.6.3-.3.25-.3.6v11.2q0 .35.3.65.25.2.65.25.35-.05.65-.25.25-.3.25-.65v-11.2q0-.35-.25-.6h-.05q-.25-.3-.6-.3m.8-16q0-.45-.3-.75-.35-.35-.8-.35-.45 0-.75.35-.35.3-.3.75v11.2q-.05.4.3.75.3.3.75.3t.8-.3q.3-.35.3-.8V238z"
                    id="ObjToy064__Layer2_0_MEMBER_1_FILL"
                />
                <path
                    fill="#777"
                    d="M337.85 212.7l-2.75 4.05 1.3.2 1.8-2.85 11.25 2.35v-1.05l-11.6-2.7z"
                    id="ObjToy064__Layer2_0_MEMBER_2_FILL"
                />
                <linearGradient
                    id="ObjToy064__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={311.075}
                    y1={287.125}
                    x2={323.325}
                    y2={297.375}
                    spreadMethod="pad"
                >
                    <stop offset="30.196%" stopColor="#8A9B9F" />
                    <stop offset="76.078%" stopColor="#FFF" />
                </linearGradient>
                <linearGradient
                    id="ObjToy064__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={322.675}
                    y1={224.65}
                    x2={315.425}
                    y2={218.55}
                    spreadMethod="pad"
                >
                    <stop offset="30.196%" stopColor="#8A9B9F" />
                    <stop offset="76.078%" stopColor="#FFF" />
                </linearGradient>
                <g id="ObjToy064__Layer2_0_FILL">
                    <path
                        fill="#737373"
                        d="M312.1 305.55q-.3 0-.55.2-.2.25-.2.55 0 .3.2.5.25.25.55.25.3 0 .5-.25.25-.2.25-.5t-.25-.55q-.2-.2-.5-.2m9.25.4q-.3 0-.55.2-.2.25-.2.55 0 .3.2.5.25.25.55.25.3 0 .5-.25.25-.2.25-.5t-.25-.55q-.2-.2-.5-.2m-12-35.8h.05q-.6.55-.6 1.3t.6 1.35h-.05q.5.55 1.35.55.75 0 1.35-.55.5-.6.55-1.3v-.05q-.05-.75-.55-1.3-.6-.6-1.35-.6-.85 0-1.35.6m1.15-16.95h-.05q-.7 0-1.15.5l-.05.05q-.5.45-.5 1.15v11.2q0 .65.55 1.2.4.45 1.15.5h.1q.7-.05 1.15-.5.5-.55.5-1.2v-11.2q0-.7-.5-1.15v-.05q-.5-.5-1.2-.5m1.05-16.5q-.6-.6-1.35-.6-.75 0-1.3.6v-.05q-.6.5-.55 1.35v11.15q-.05.75.55 1.35.55.55 1.3.55t1.35-.55q.55-.6.55-1.35V238q0-.85-.55-1.35v.05m7.25-16.25q-.4.2-.75.55-.7.7-.7 1.7v48.65q0 1 .7 1.7.35.35.75.55.45.15 1 .2h.05q.4-.05.75-.15.5-.2.95-.6.7-.7.7-1.7V222.7q0-1-.7-1.7-.45-.45-.95-.6-.35-.15-.75-.1h-.05q-.55-.05-1 .15m8.3 93v-.05q.106-1.591-1.1-2.8-1.14-1.194-2.85-1.3 0-.003-.05 0-6.358.639-11.9-.8-.037-.003-.1 0-1.717-.106-3.05.9-1.302 1.084-1.35 2.7v.05q-.046 1.046.4 1.85h-.05q.253.506.65.9 1.172 1.172 2.85 1.6 5.67 1.41 12.15.8 1.687-.158 2.95-1.25 1.165-.803 1.4-2.15.055-.212.05-.45m-.75.3v.05q-.176 1.06-1.1 1.7h-.05q-1.087.958-2.55 1.1-6.37.59-11.95-.8-1.472-.372-2.5-1.4-.303-.307-.5-.7v-.05q-.35-.636-.3-1.5v-.05q.051-1.285 1.1-2.15 1.089-.821 2.5-.75 5.637 1.456 12.1.8h.05q1.407.112 2.35 1.1.984.982.9 2.25v.1q-.005.162-.05.3z"
                    />
                    <path d="M311.5 226.1q-.6-.5-1.45-.5t-1.5.5q-.6.5-.55 1.2v5.1q-.05.6.4 1.05l.1.1q.027.023.05.05.35.25.8.4.3.1.7.1.85 0 1.45-.5t.6-1.2v-5.1q0-.7-.6-1.2m23.6-9.35l2.75-4.05H313.9l-2.15 2.6q9.25-.3 18.5.65l4.85.8z" />
                    <path
                        fill="url(#ObjToy064__Gradient_2)"
                        d="M318.05 221q.35-.35.75-.55.45-.2 1-.15h.05q.4-.05.75.1.5.15.95.6.7.7.7 1.7v3q.05-.05.15-.1l.4-.4q1.25-1.25 1.25-3 0-1.8-1.25-3.05-1.25-1.25-3-1.2-1.8-.05-3.05 1.2-1.25 1.25-1.25 3.05 0 1.75 1.15 2.95.05.05.1.05.25.3.6.55v-3.05q0-1 .7-1.7z"
                    />
                    <path
                        fill="#5A7072"
                        d="M338.6 219.8l-1.2-.2v6.15l1.2.15v-6.1m-2.35-.4l-1.2-.2v6.25l1.15.15.05-6.2m-2.35-.35l-1.25-.2v6.3l1.2.15.05-6.25m7.1 1.1l-1.2-.2v6.1l1.2.15v-6.05m2.4.4l-1.2-.2v6l1.2.15v-5.95m4.8.75h-.05l-1.15-.15v5.8l1.2.15v-5.8m-2.4-.35l-1.2-.2v5.9l1.2.15v-5.85z"
                    />
                    <path
                        fill="#2B2B2B"
                        d="M349.45 219.1v-3.7l-11.6-2.7-2.75 4.05 14.35 2.35z"
                    />
                </g>
                <g id="ObjToy064__Layer2_0_MEMBER_0_FILL">
                    <path
                        fill="#999"
                        d="M320.6 226.9h-1.8v46.7q.45.15 1 .2h.05q.4-.05.75-.15V226.9m-.8-6.6q-.55-.05-1 .15v5.7h1.8v-5.75q-.35-.15-.75-.1h-.05z"
                    />
                    <path d="M317.35 226.9h4.9v-.75h-4.9v.75z" />
                </g>
            </defs>
            <use xlinkHref="#ObjToy064__Layer11_0_FILL" />
            <use xlinkHref="#ObjToy064__Layer11_1_FILL" />
            <use xlinkHref="#ObjToy064__Layer11_2_FILL" />
            <use
                xlinkHref="#ObjToy064__Symbol_183_0_Layer0_0_FILL"
                transform="translate(311.55 286.95)"
                id="ObjToy064__col1n"
            />
            <use xlinkHref="#ObjToy064__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy064__Layer2_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjToy064__Layer2_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjToy064__Layer2_0_MEMBER_2_FILL" />
        </g></g>
    )
}

export default ObjToy064
