import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#3E576D",
    "col1n": "#6486A6",
    "col2n": "#C40000"
}

function ShiSpe099({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M135.7 7.95q-1.9-4.6-4.75-7.95-21.65 7.25-34.9 21.1-2.55 2.65-4.8 5.5h-46q-2.25-2.85-4.8-5.5Q27.2 7.25 5.55 0 2.7 3.35.8 7.95L0 9.75q13.75 4.75 22.3 10.8 8.5 6 13.55 13.3V56.2q-11.75 8.25-29.8 9.55Q4 71 3.85 74.95v.1q-.05.6 0 2.2 25.25-3.2 39.35-11.9h50.1q14.1 8.7 39.35 11.9.05-1.6 0-2.2v-.1q-.15-3.95-2.2-9.2-18.05-1.3-29.8-9.55V33.85q5.05-7.3 13.55-13.3 8.55-6.05 22.3-10.8l-.8-1.8z"
                    id="ShiSpe099__Symbol_168_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M25.85 13.15Q15.85 5.2.8 0L0 1.8q13.75 4.75 22.3 10.8 8.5 6 13.55 13.3v-2.45q-4.15-5.65-10-10.3m106.8 56.15q.05-1.6 0-2.2-25.25-2.2-39.25-11.9H43.1q-14 9.7-39.25 11.9-.05.6 0 2.2Q29.1 66.1 43.2 57.4h50.1q14.1 8.7 39.35 11.9m3.85-67.5l-.8-1.8q-15.05 5.2-25.05 13.15-5.85 4.65-10 10.3v2.45q5.05-7.3 13.55-13.3 8.55-6.05 22.3-10.8z"
                    id="ShiSpe099__Symbol_169_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M12.3 18.85q-.1.35-.15.6-2.15 5.85-7.6 9.8H24.7q-5.45-3.95-7.6-9.8-.05-.25-.15-.5-1 .5-2.25.5-1.3 0-2.4-.6m-2.5-6.7Q3.95 10 0 4.55V24.7q3.95-5.45 9.8-7.6.25-.1.5-.15-.65-1.1-.65-2.55 0-1.2.5-2.15-.2-.05-.35-.1m2.35-2.35q0 .1.05.2 1.1-.65 2.5-.65 1.3 0 2.4.6V9.8q2.15-5.85 7.6-9.8H4.55q5.45 3.95 7.6 9.8m.5 1.2q-.45.25-.75.6-.45.45-.75 1.05-.4.8-.4 1.75 0 1.2.6 2.15v-.05q.2.35.55.7.4.4.85.65.9.5 1.95.5 1.05 0 1.85-.4.5-.3.95-.75.35-.35.6-.65h-.05q.6-.95.6-2.15 0-1-.4-1.8v.05q-.3-.6-.75-1.05-.4-.4-.85-.65-.85-.5-1.95-.5-1.15 0-2.05.55m16.6 13.7V4.55q-3.95 5.45-9.8 7.6-.1 0-.15.05.45 1 .45 2.2 0 1.45-.65 2.6.15.05.35.1 5.85 2.15 9.8 7.6z"
                    id="ShiSpe099__Symbol_170_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe099__Symbol_168_0_Layer0_0_FILL"
                transform="translate(124.25 231.4)"
                id="ShiSpe099__col1n"
            />
            <use
                xlinkHref="#ShiSpe099__Symbol_169_0_Layer0_0_FILL"
                transform="translate(124.25 239.35)"
                id="ShiSpe099__col1d"
            />
            <use
                xlinkHref="#ShiSpe099__Symbol_170_0_Layer0_0_FILL"
                transform="translate(177.05 261.6)"
                id="ShiSpe099__col2n"
            />
        </g></g>
    )
}

export default ShiSpe099
