import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function SkiMor005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M37 46.8v.3q0 1.25 1.85 4.3 2.5 4.1 5.75 4.1 1.1 0 1.55-.65-3.35-.2-6.9-4.8-1.45-1.9-2.25-3.25M9.35 0Q7.8.05 5.55 1.4l-.3.2Q0 4.85 0 8.95q0 .05.1.9.25.6.85.8v-2.4Q.9 6.35 3.6 4.1q2.65-2.25 4.25-2.7 1.6-.45 2.9-.85-.4-.55-1.4-.55M34.5 23.65l.3-.5q.2-.25.4-.4-.1-.05-.2-.05t-.2.05q-.9.3-2.9 3.4-1.9 2.85-2.1 5.3-.05.45-.05.9.1 2.75.7 4.6.25 1.45.85 2.25-.15-.55-.2-.95V34.3q-.05-.15-.1-.95-.05-.85.1-1.9.1-1.1.95-3 .8-1.9 2.45-4.8m7.95-1.35q3.75-3.9 5.15-3.55-.4-.35-1.25-.2-1.65.3-3.9 2.35-5.7 5.05-5.7 10.15v1.5q.3 1.35 1.55 1.4-.7-.3-.15-4.05.5-3.75 4.3-7.6m56.4 23.5q-.65 1.65-2.2 3.35-.65.75-1.75 1.75-.3.25-.5.5-.85.9-2 1.5-1.8 1.2-3.05 1.3.4.55 1.35.55 2.95 0 6-4.15.75-1 1.3-1.85.15-.55.4-1.4.25-.75.45-1.3v-.15-.1m1.2-24.6q.3.9.95 2.55.8 2.05 1.05 3 .25.9.2 3.05-.05 2.1-.25 3.5-.25 1.4-.65 2.15h.35q1.9 0 1.9-5.6 0-4.1-2.4-7.5-.65-.85-1.15-1.15m-10.7-2.65q.75.25 4 3.35 3.2 3.05 3.5 5.8.25 2.75.25 2.85.45-.25.6-.75.15-.4.15-1.45 0-3.75-4.8-7.95-1.95-1.7-3.3-1.85h-.4M124.2 2.2q2.25 1.9 2.3 1.95 1.15 1.05 1.65 2 .2.35.35.75.4.45.6 1 .6 1.5.7 2.3.05.3.05 1.45.25.95.25 1.95v.35q.7-.25.9-1.1v-1.6q0-4.4-4.55-8.2-1.85-1.55-3.15-1.6.4.35.9.75z"
                    id="SkiMor005__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMor005__Symbol_9_0_Layer0_0_FILL"
                transform="translate(127 186.15)"
                id="SkiMor005__col1n"
            />
        </g></g>
    )
}

export default SkiMor005
