import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFC400",
    "col1n": "#FFD446"
}

function AccMor053({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.5 3.7q.15-.55.25-1.2-.4-2.5-2.25-2.5-2.2 0-4.6 1-2.6 1.35-3.85 1.9-.3.15-.9.55-3.2 3.15-7.35 5.75-.8.5-1.4.9-1.35.65-7.3 3.3-1.2.55-2.2 1-1.45.55-2.6.9-1.6.45-2.9.85-.05 0-.1.05-6.4 1.6-15.2 2.55h-.2q-8.4-1.3-15.95 0-.9.25-1.75.65-.15.05-.25.1-.3.1-.65.2-1.9.6-3.05.85-.25-.1-.5-.15-8.65-3.55-16.7-1.4-.85.05-1.05.1h-2.2l-3.5-.45q-3.95-.6-7.35-1.15-1.15-.3-1.6-.3h-.05q-1.75-.3-3.35-.6-.45-.1-.85-.15-.55-.15-1.05-.25l-3.7-1q-4.2-1.35-8.45-3.6-1-.5-2.35-1.15-.95-.6-4.3-2.35l-.15-.05q-1.45-.7-3.2-1.75-.75-.45-1.4-.85h-.05q-.75-.3-1.25-.4-.25-.15-.55-.35Q8.4 3.2 6.3 2.25 0-.65 0 3.35 0 5 .4 6.1.85 7.4 2.25 9q.65.75 2.55 1.25.15.05.35.1 1.6.85 3.8 1.75.55.5 1.1.95.3.45.85 1.15l.35.35q.45 1.15 1.1 2.8 0 .05 1 2.65.65 1.7.9 2.65 1.05 3.65 4.65 4.95 5.9 2.35 7.7 2.9.7.25 1.55.55l3.6 1.25q2 .7 3.6 1.4.2.2.55.35.5.25 1.55.65.5.2 1.2.65.25.2.6.5.8.7 1.55 1 2.15 1.1 3.4 1.4 1.85.55 7.85.8h1.6l.1.05h2q1.15 1 5.9 2.85 2.25.6 3.5.9 2.1.45 5.65.75h1.5q3.7-.35 6.95-1.25 3.6-1.1 5.8-1.75 2.8-.85 6.05-2 1.8-.55 3.2-1 2.55-.55 5.25-1.2.3-.1.6-.15 1.8-.35 2.3-.35 1.4 0 3.2.55 10 .1 14.25-5.4l.2-.2v-.05q.4-.55 1.05-1.05.35-.2.75-.45.45-.2 1.65-.55 1.15-.3 1.7-.55 1.3-.6 3-1.7 1.9-1.25 2.85-1.85 1.1-.75 2.4-2.05 1.25-1.25 1.9-2.2.9-1.2 1.55-2.1 1.2-1.75 1.2-2.4 0-.2-.1-.35v-.05q-.05-.35-.6-1.3-.4-.65-.55-1.3 0-.85.95-1.9.05-.05.1-.05 1.2-.4 3.3-1.55 3.25-1.75 3.85-2.75 1.05-1.55 1.4-2.95z"
                    id="AccMor053__Symbol_150_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M174.75 254.95q-.45-.25-.95-.5-1.8-.95-5.35-3.85-2.1-1.55-3.15-2.2-.1-.1.25 1.35.35 1.45 2.75 3 1.65 1.05 3.65 1.85h.25q1 0 2.55.35m22.5-20.05l-4.25 1.15q10.9 2.05 21.05 5.4-7.25-4.7-16.8-6.55m52.1 5.5l-3.1.95q-5.3 1.3-8.1 2.05-.8.2-7.75 2.15-3 .8-5.7 1.45l2.4-.05q6.5-.3 10.75-1.05 1-.2 1.95-.45.4-.1.85-.2.05-.1.2-.1l1.15-.3q1.1-.35 2.35-.8 3.4-2.3 4.05-2.85 0-.1.95-.8m6.35-12.95q.3-.4.45-.75-.55.2-1 .35l-.2.1q-1.55.5-2 .65-.25.05-.35.05-.85.35-1.05.35-3.95 1.15-7.7 2.25-3 .9-3.6 1.8 3.95-.4 5.7-.85 1.65-.4 6.5-1.95.45-.2.95-.35.85-.4 1.7-.85.3-.35.5-.65.05-.1.1-.15m-101.45 13q-.2.35-.2 1 0 2.2 11.75 4.9 3.35.75 7.7 1.6 1.95.4 3.95.75 7.25 1.3 15.05 2.35 7.15.9 12.15 0 1.55-.05 2.5-.1 5.2-.5 10.25 0 .15-.05.4-.05.05 0 2.05.5h.05q.3 0 2.25.25 2 .25 2.8.45h.5q-3.2-.85-4.45-1.25-2.75-.85-3.7-1.15-1-.35-3.45-.55-2.45-.25-2.95-.2h-14.95q-38.25-3.65-41.7-8.5m-10.95 2.6q.05.1.2.2 1.2 1.25 6.05 4.4 1.2.5 4.65 1.85-1.6-1.2-3.95-3.35-4.15-2.6-6.35-3.8-1.15-.7-3.3-2.5 1.25 1.85 2.7 3.2m-8.5-11.7q-.4-.2-.65-.3-1.7-.75-2.55-1-.45-.2-.85-.35-1.15-.6-2.1-1.4-.85-.4-1.5-.75 1.25 1.2 1.9 2.05.7.75 1.9 1.55 1.5.9 3.75 1.85 1.7.6 3.8 1.3 3.8 1.2 5.5 1.95v-.4-.2q-1.1-.8-1.3-.9-1.2-.65-1.95-1.05-.35-.2-.65-.35-.95-.35-1.75-.6-1.45-.5-3.55-1.4z"
                    id="AccMor053__Symbol_92_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor053__Symbol_150_0_Layer0_0_FILL"
                transform="translate(118.5 215.6)"
                id="AccMor053__col1n"
            />
            <use
                xlinkHref="#AccMor053__Symbol_92_0_Layer0_0_FILL"
                id="AccMor053__col1d"
            />
        </g></g>
    )
}

export default AccMor053
