import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D"
}

function SkiMak017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M14.4 0Q8.45 0 4.2 2.25 0 4.5 0 7.65q0 3.15 4.2 5.4 4.25 2.25 10.2 2.25 5.95 0 10.15-2.25 4.25-2.25 4.25-5.4 0-3.15-4.25-5.4Q20.35 0 14.4 0m122.15 2.25Q132.35 0 126.4 0t-10.2 2.25Q112 4.5 112 7.65q0 3.15 4.2 5.4 4.25 2.25 10.2 2.25 5.95 0 10.15-2.25 4.25-2.25 4.25-5.4 0-3.15-4.25-5.4z"
                    id="SkiMak017__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak017__Symbol_23_0_Layer0_0_FILL"
                transform="translate(122.1 196.95)"
                id="SkiMak017__col1n"
            />
        </g></g>
    )
}

export default SkiMak017
