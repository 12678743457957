import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M171.35 30.85q6.8 3.95 13.8 12.4-4.3-13.4-13.8-22.8-.05-.1-.15-.15-1.95-1.9-4.05-3.65-2.55-1.75-5.1-3.35-42.9-26.6-86.5 0-2.6 1.6-8.35 5.2-1.25.85-2.55 1.8-5.05 3.65-10.15 8-4.5-.5-12.7 1.1-4.05.75-8.05 3.7Q19.25 45.25 9.7 67.05 8 71.25 6.1 77 2.2 88.9.85 101.2q-1.4 12.3-.5 15 .85 2.7 2.45-1.85 1.6-4.55 6.95-20.15 4.9-14.3 15.05-26.85.7-.9 1.45-1.75.85-1.1 1.8-2.15.1-.15.2-.25Q35.4 54.8 49 48.95q6.2-3 13.25-4.05 15.15-3.1 30.35 3.15.35.15.75.3.85.75 1.6 1.7.45.6.9 1.3.4.55.85 1.15 1.15 1.7 2.25 3.5 12.9 20.9 17.7 50.55l.8 4.9q.45 2.9.8 6.15 1.55 13.3 2.15 32.1.6 19.85 1.25 27.1.15 2.25.3 3.55 1.15-1.8 3.4-5 7.35-10.7 15.7-40.3v3.35q.05 12.1-.35 19.2-.5 9.3-1.8 19.2-.5 3.95-1.15 8-1 5.9-2.2 12-.45 2.1-.9 4.25 1.3-2.2 4.45-6.4 3.2-4.25 6.3-9.2 3.15-5 4.8-7.85 1.65-2.9 3-4.5v3.7q-.05 3.8-.05 8-.05 3.8-.05 7.9 2.45-4.05 4.65-7.9 2.3-4.1 4.3-8 4.95-9.8 7.8-18.6 2.5 21.1 0 42.2 1.1-.95 2.1-1.85.85-.85 1.7-1.75 4.4-4.75 8.4-12 2-3.7 3.9-8 4.75-10.7 8.8-25.3 5.5-26.4 0-49.1 3.6 3.7 6.15 7.7 1 1.95 1.55 3.85v-7.35q-4.15-49.9-31.1-71.15-6-4.75-13.1-8.05 6.45-.45 13.1 3.45z"
                    id="HaiSho057__Symbol_178_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M65.85 13.8q-2.3-4.95-8.3-8.7Q52.5 1.5 45.5.45 42.55 0 39.55 0q-16.3-.25-26.8 11.6-3.15 3.9-6.15 7.8-4.95 6.45-6.6 9.7.4-.35.75-.7 2.4-2.35 4.25-3.95Q34.5 2.1 65.6 13.85q-.2-.2-.4-.35.25.15.6.3 0 .05.05.05v-.05z"
                    id="HaiSho057__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M85.2 82.25q1.25-4.9 1.4-6.2 2.15 8.9 3.55 35.55 10.25-46.95-4.35-69.3 2.65 12.25-2.5 28.15-1.85-15.2-9.45-34.45.05 3.45.15 5.5v.05q-.05.05.1.05.1 0 .15 5.8l.05 3.8q.1 12.05-1.05 16.4-5.1-16.55-12.8-36.5 1.75 20.2.05 27.45Q50 31.75 43.4 24.7q.45 12.05-.45 17.3t-1.7 13.95q-1.6-7.7-4-14.4Q34.8 34.8 23.6 14.7q3.65 11.45 5.05 28.45v5.75q-1.65-6.15-4-12.15Q15.8 14.25 0 0q9.25 15.6 13.55 25.4 12.4 34.7 14.55 70.9.45 10.25.9 23.05 6-41.2 1.5-65.35 7.5 14 9.15 34.1Q48.9 64 48.9 57.6q.75 3.65 3.9 11.75 3.1 8.05 3.95 35.65Q67.05 80.8 67 71.45q7.25 16.1 9.3 34.95 7.05-17.1 8.9-24.15z"
                    id="HaiSho057__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho057__Symbol_178_0_Layer0_0_FILL"
                transform="translate(96.1 38.9)"
                id="HaiSho057__col1n"
            />
            <use
                xlinkHref="#HaiSho057__Symbol_94_0_Layer0_0_FILL"
                transform="translate(123.85 73.4)"
                id="HaiSho057__col1d"
            />
            <use
                xlinkHref="#HaiSho057__Symbol_42_0_Layer0_0_FILL"
                transform="translate(193.95 78.5)"
                id="HaiSho057__col1l"
            />
        </g></g>
    )
}

export default HaiSho057
