import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#332F54",
    "col1n": "#56518C",
    "col2n": "#000000"
}

function AccHat045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M353.4 61.45q-3-1.4-7.55-2.5-5.5-1.35-13.2-2.35-1.2-.15-2.35-.3h-.15q-14.6-2.7-29.5-3.5-.3-.1-.5-.1-42.15-4.65-83.35-4.5l-9-25.4q-3.7-10.7-11.4-15.45-5.7-3.25-11.85-5.4-10.4-3.6-27.55-.55t-23.5 11.4q-3.05 3.85-4.95 9.9-1.95 6.15-2.75 14.7-.1.95-.2 1.95h-.15L124 54.8q-46.5 6.45-92.3 18.3Q15.75 77.25 0 83.45v.1l100.6-5.05h.5q1.55.05 27.45 1.6 2.9.15 6.15.35 31.9 1.9 58.8-6.25 26.85-8.15 31.2-8.95 4.3-.8 8.05-1.4 2.35-.35 4.75-.7l1.2-.2 114.7-1.5z"
                    id="AccHat045__Symbol_263_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M96.05 35.15q2.35-4.8 8-12.9-9.75-.55-19.7-1.5l-3.3-.3q-.5-.05-1-.05Q62.2 19 44.8 20.35q-1.1.1-2.15.2-4.05.15-8 .5-16.4 1.5-31.7 6.25l-.3.1Q1.6 28.1 1 28.75q-1.95 2.05 0 3.5 2.1 1.5 8.85 2.3.35 0 .65.05.15-.05.25 0l.05.1q30.8-1 55.2 3.85.15 0 .3.05 5.35 1.15 11.25 2.55 6.85 1.6 13.6 3.5.6-.85 1.55-2.8.95-1.95 3.35-6.7m254.9-11.75q.7-.4 1.35-.8 17.7-11.2 4.05-17.4-3.8-.9-7.65-1.6Q302.5-5 240.45 6.9l6.85 5.95 2.4 2.5q2.4 2.55 4.65 5.35l.1.1.3.35q.15.2.3.45h.05l2.55 3.5q1.25 1.7 2.45 3.55l.1.05 1 1.7.05.05.5 1q.15.3.4.65 1.35 2.3 2.6 4.6.8 1.55 1.55 3.15.2.4.4.85l.1.1v.05q.1.3.25.6.05.1.9 1.55.75 1.35 2.35 4.5.4-.05.45.1 47.25-5.45 80.2-24.2z"
                    id="AccHat045__Symbol_171_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M92.8 23.4L85.95 4.05Q44.4-6.75 4.55 11.3q-1.4.6-2.75 1.3-.1.95-.2 1.95v-1.9L0 30q.65-.1 1.35-.2 1.6-.2 3.2-.45.85-.1 1.75-.2 28.9-3.85 58.35-5.1 11.5-.5 23.15-.6 2.5-.05 5-.05z"
                    id="AccHat045__Symbol_85_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat045__Symbol_263_0_Layer0_0_FILL"
                transform="translate(15.15 16.9)"
                id="AccHat045__col1n"
            />
            <use
                xlinkHref="#AccHat045__Symbol_171_0_Layer0_0_FILL"
                transform="translate(12.2 73.15)"
                id="AccHat045__col1d"
            />
            <use
                xlinkHref="#AccHat045__Symbol_85_0_Layer0_0_FILL"
                transform="translate(139.15 41.7)"
                id="AccHat045__col2n"
            />
        </g></g>
    )
}

export default AccHat045
