import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#666666"
}

function PlaFor031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M371.95 39.7V0H0v39.7h371.95z"
                    id="PlaFor031__Symbol_34_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M373.45 40.45V.75q0-.3-.2-.5Q373 0 372.7 0H.75q-.3 0-.5.25-.25.2-.25.5v39.7q0 .3.25.55.2.2.5.2H372.7q.3 0 .55-.2.2-.25.2-.55m-1.5-38.95v38.2H1.5V1.5h370.45z"
                    id="PlaFor031__Symbol_45_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor031__Symbol_34_0_Layer0_0_FILL"
                transform="translate(6.55 339.75)"
                id="PlaFor031__col1n"
            />
            <use
                xlinkHref="#PlaFor031__Symbol_45_0_Layer0_0_FILL"
                transform="translate(5.8 339)"
                id="PlaFor031__col2n"
            />
        </g></g>
    )
}

export default PlaFor031
