import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig005({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M98.85 24.15q-2.8-1.15-9.9-.55 2.1-1.25 7.8-6.3L87 16.55q2.9-2.9 7.75-5.25-6.5-1.2-10.75-1.25 0-.25-.25-.25.75-1.1 6.75-4.5l-11.45-.75q1.15.05 7.2-4.5h-.5q-4.05-.2-9.25.3-37.7 3.7-59.95 31.85-.35.55-.75 1.1Q0 56.35 0 96.85q0 13.05 2.35 21.45Q4.3 125.2 13 142.15q-.55-13.75-.45-23.95.1-10.2 5.1-26.75-1.05 19.5 1.75 30.55 2.85 11.05 10.6 18.15-5.75-27.55-1.25-42.65 1.4 15.3 4.65 24.2 3.25 8.9 10.6 15.45-2.65-8.95-.7-25.95 2-17.05 6.2-21.1.85 15.75 2.25 20.95 1.4 5.15 3.75 9.6 2.35 4.4 2.9 5.3-.9-26.45 7-50.15 11.95-39.85 33.45-51.65m-31.35 7.4q.75-.05 1.5-.05v.05h-1.5z"
                    id="HaiHig005__Symbol_9_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig005__Symbol_9_0_Layer0_0_FILL"
                transform="translate(103.7 59.55)"
                id="HaiHig005__col1n"
            />
        </g></g>
    )
}

export default HaiHig005
