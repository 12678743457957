import { AssetSvgProps } from "../../../shared/assets"

function ShiMot098({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot098__Layer1_0_FILL">
                    <path
                        fill="#FF0"
                        d="M174.35 270q-.947 3.191 7.7 6.05 21.837 3.681 43.65 0V270q-25.662-3.534-51.35 0z"
                    />
                    <path
                        fill="#0040D4"
                        d="M174.65 282.05q-7.631 2.693-7.65 6 29.363 1.753 58.7 0v-6q-25.512-2.889-51.05 0z"
                    />
                    <path d="M166.65 276.05l.1.1.1-.1h-.2m3.85 0l.05.05.1-.05h-.15m4 0h-.15l.05.05.1-.05z" />
                    <path
                        fill="red"
                        d="M159.2 258.1h.05q3.363 5.272 7.5 5.9 29.475 3.06 58.95 0v-6h-66.5v.1z"
                    />
                </g>
                <g id="ShiMot098__Layer1_0_MEMBER_0_FILL">
                    <path
                        fill="#9B00C2"
                        d="M225.7 294.1v-6.05H167q-4.54 1.76-7.7 6.05h66.4z"
                    />
                    <path
                        fill="#F90"
                        d="M166.75 264q-3.61 3.76 7.6 6h51.35v-6h-58.95z"
                    />
                    <path
                        fill="#009C2D"
                        d="M182.05 276.05l.1.05.05.05q-8.666 2.424-7.55 5.9h51.05v-6h-43.65z"
                    />
                </g>
                <g id="ShiMot098__Layer1_0_MEMBER_1_FILL">
                    <path
                        fill="#FFF"
                        d="M159.2 270.1v12.05q5.06-.428 7.55-6l-.1-.1q-1.904-4.431-7.45-5.95z"
                    />
                    <path
                        fill="#66C9EE"
                        d="M159.2 285.1v3.05q9.534-4.478 15.2-12.05l-.05-.05q-5.938-6.546-15.15-12v3q3.45 5.246 11.3 9h.15l-.1.05q-6.717 3.024-11.35 9z"
                    />
                    <path d="M159.2 258.1v2.85q6.59 8.113 19.05 15.1l.05.05q-10.579 6.92-19.1 15.1v2.9h.1l22.9-17.95-.05-.05-22.9-18h-.05z" />
                </g>
                <g id="ShiMot098__Layer1_0_MEMBER_1_MEMBER_0_FILL">
                    <path
                        fill="#FAB5C7"
                        d="M159.2 282.15v2.95l11.35-9-.05-.05-11.3-9v3.05l7.45 5.95h.2l-.1.1-7.55 6z"
                    />
                    <path
                        fill="#82491E"
                        d="M159.2 288.15v3.05l19.1-15.1-.05-.05-19.05-15.1v3.1l15.15 12h.15l-.1.05-15.2 12.05z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot098__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot098__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiMot098__Layer1_0_MEMBER_1_FILL" />
            <use xlinkHref="#ShiMot098__Layer1_0_MEMBER_1_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot098
