import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1n": "#E4B670",
    "col2n": "#2E0E00"
}

function ObjMor057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M52.75 42.55q-2.85-9.05-9.35-14.5l-.6-.6-.1-.1q-.1-.05-.1-.1-.45-.4-.85-.75-.65-.6-1.25-1.2-1.8-1.9-3-3.25-2.35-2.6-3.65-5.75-.9-2.15.1-3.65.35-.6 1-1.05l.35-.3v-.05h.05q.05-.05.15-.1.05-.1.1-.1l.2-.2q.1-.1.25-.2.2-.25.45-.5.2-.2.4-.45 6.95-7.45 1.7-7.15-.75 0-1.7.2-.35.05-.85.05h-.5q-1.75 0-2.65-.5-.35-.15-.55-.4-.3-.45-.6-.8-.15-.3-.35-.5-1-1.15-2.35 0-.3.2-.55.5-.2.2-.4.35-2.05 1.75-4 1.8-2 .05-4.2-.8-.15-.05-.35-.15-.35-.15-.65-.25-1.9-.6-2.6.6-.2.3-.3.7-.1.2-.15.45-.1.4.05 2.5.1 2.1 1.4 3.5 1.25 1.4 1.65 1.7.4.25 1.35 1.4.9 1.1.2 1.25-.7.15-.9 1.8-.25 1.65-1.15 3.5-.9 1.85-5.45 6.85-.5.45-1.05.95 0 .05-.1.1l-.1.1-.4.4q-.6.6-1.25 1.2Q4.5 33.9 1.95 42.2q-.4 1.2-.65 2.5-.35 1.7-.5 3.5-.1.95-.1 1.95.05.9.1 6.3.05.05.05.1-.1.65-.65 3.35-.95 4.15 2.4 5.65 2.15 1 7.3 3.05 2.1.9 4.3 1.55 11.35 3.45 26.1 0 2.2-.65 4.3-1.55 5.15-2.05 7.3-3.05 3.35-1.5 2.4-5.65-.55-2.7-.65-3.35 0-.05.05-.1 0-1.75.05-3l-.05.35q0-.55.05-1.05.05-1.9.1-2.6-.05-3.95-1.1-7.6z"
                    id="ObjMor057__Symbol_150_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.1 46.75Q0 47.7 0 48.7q.05.9.1 6.3.05.05.05.1 2.2 4.75 8.45 9.3-7-7.65-8.5-17.65M30.55 11.7Q35.1 7.1 37.9 1.1q-.75 0-1.7.2-.35.05-.85.05h-.5q-1.75 0-2.65-.5-.4 5.65-3.65 11.25-2.05.3-4.15.1 3.2-6.25 3-12.2-2.05 1.75-4 1.8-2 .05-4.2-.8 3.25 1.85 3.8 11-1.25-.25-2.5-.6-4.55-5.7-5.2-9.5-.1.2-.15.45-.1.4.05 2.5.1 2.1 1.4 3.5 1.25 1.4 1.65 1.7.4.25 1.35 1.4.9 1.1.2 1.25-.7.15-.9 1.8-.25 1.65-1.15 3.5-.9 1.85-5.45 6.85-.5.45-1.05.95 0 .05-.1.1l-.1.1-.4.4q5.65-3.4 8.85-8.5.8.15 1.9.2-2.65 7.9-10.8 13.55 9.55-4.85 13.1-13.45h.7q.85 0 1.7-.05 1.45-.15 2.9-.5 2.55 7.55 10.45 11.75-6.55-5.35-8.25-12.45-.15-3.25 1.15-.5 3.55 5.2 8.7 8.6-.65-.6-1.25-1.2-1.8-1.9-3-3.25-2.35-2.6-3.65-5.75-.9-2.15.1-3.65v-.5q-.8.4-1.65.65-.55.2-1.05.35m22.55 37q0-1-.1-1.95-.4 2.7-1.2 5.25-2.2 6.8-7.3 12.4 6.25-4.55 8.45-9.3 0-.05.05-.1 0-1.75.05-3l-.05.35q0-.55.05-1.05 0-2.05.05-2.6z"
                    id="ObjMor057__Symbol_84_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M8.4 16.7q0-.2-.1-.3-.05-.1-.2-.1h-1q-.1 0-.2.1-.05.05-.05.3l-.15 2.4q-1 .15-2 .55t-1.7 1Q1.8 21.5 1 23q-.9 1.4-.9 3.85 0 1.9.55 3.3.55 1.35 1.75 2.35.7.65 1.7 1.15.95.5 2.3.85l-.3 9.75q-.9-.55-1.65-1.3-.8-.7-1.35-1.6-.5-1.3-.65-2.3-.15-1-.2-1.9 0-.2-.05-.25-.1-.05-.2-.05l-.95-.1q-.15 0-.25.1-.1.05-.1.25-.05.4-.1 1.35-.1 1-.2 2.2-.15 1.1-.2 2.2-.15 1.1-.2 1.8 0 .4.1.5.1.2.3.2.2 0 .4-.05t.25-.15q.2-.45.4-.65t.35-.2q.1 0 .4.15.2.05.6.3.6.35 1.45.75.85.35 1.8.65l-.15 2.6q0 .2.1.35.05.05.25.05h1q.15 0 .2-.05.05-.1.05-.35l.15-2.35q.2.1.65.1.5.05.9.05.45.05.7 0l-.05 2.2q0 .2.05.35.05.05.25.05h.9q.2 0 .25-.05.15-.1.15-.35V46.4q2.3-.45 3.9-1.55 1.55-1.2 2.35-2.9.8-1.65.8-3.5 0-2.5-.75-4.1-.8-1.6-2.25-2.6t-3.65-1.5l.3-9q.7.35 1.2.95.5.5.75.9.6.8.95 1.95.3 1.05.6 2l.1.1h.15l.95-.1q.1 0 .2-.1v-.25-1.65q-.1-1.2-.1-2.75-.05-1.45-.05-2.65 0-.55-.35-.55h-.3q-.15.1-.2.2l-.55.55q-.15.15-.35.15h-.4q-.8-.3-1.4-.4-.6-.2-1.2-.35l.1-2.55q0-.2-.1-.3-.05-.1-.25-.1h-1q-.15 0-.2.1-.05.05-.05.3l-.1 2.35q-.5-.05-1.1-.15H8.4v-2.2m-.1 3.8q.5-.1 1.15-.05.6.05 1.1.25l-.25 9.15q-.7-.15-1.3-.2-.6-.15-.95-.35l.25-8.8m-3.45 2.3q.4-1.2 1.85-1.95l-.2 7.9q-1-.45-1.5-1.3-.6-.85-.6-2.35 0-1.1.45-2.3M8 34.85q.3.1.85.25t1.4.35l-.3 9.5q-.65.1-1.3 0-.65-.05-1-.2l.35-9.9m5.55 2.85q.6 1.15.6 2.25-.05 2-.8 3.2-.7 1.15-1.85 1.6l.25-8.8q1.2.6 1.8 1.75m3.6-35.45q.55-.8.2-1.8-.2-.5-.8-.45-.35 0-.85.2Q9.5 2.6 3.3.35 3.05.25 2.9.2 2.75.15 2.7.1h-.05l-.1.1q-1.3 1.55.1 2.9.05.05.1.05l.65.25q1.9.4 4 .6h.2q1.55.1 3.7-.15.25-.05.5-.05.7-.15 1.5-.3-.4.35-1.75.7-.5.1-1.15.2-1.2.2-3.15.15-1.8-.05-4.25-.3h-.5q-.4.05-.65.25l-.1.1q-.5.3-.5 1.1 0 .4.15.65l.2.2q.15.15.35.3.5.3 1.55.45.7.15 1.7.2.65.05 1.35.1H7.9q.8 0 1.55-.05 1.3-.15 2.6-.5.35-.1.65-.2.7-.2 1.35-.5.7-.25 1.35-.6.4-.25.85-.5l.2-.1q.25-.15.5-.35.65-.55.65-1.35t-.7-1q.15-.1.25-.2z"
                    id="ObjMor057__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor057__Symbol_150_0_Layer0_0_FILL"
                transform="translate(245.5 276.15)"
                id="ObjMor057__col1n"
            />
            <use
                xlinkHref="#ObjMor057__Symbol_84_0_Layer0_0_FILL"
                transform="translate(246.2 277.6)"
                id="ObjMor057__col1d"
            />
            <use
                xlinkHref="#ObjMor057__Symbol_44_0_Layer0_0_FILL"
                transform="translate(262.65 288.3)"
                id="ObjMor057__col2n"
            />
        </g></g>
    )
}

export default ObjMor057
