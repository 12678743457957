import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M36.1 7.6q.45-.9.7-1.65.55-3.1 0-4.55-8.45-2.85-16.95.1-4.75.95-9.45 2.5-.85.25-1.8.6-1.6.6-2.35 1.45Q6.7 14.5 5 22.25q-3 9.3-3.45 10.25-3.4 8.65.7 15.95l4.95-14.6q.2-.55.45-1.15 1-3 1.4-7.2.5-6.25 3.05-11.85.2-.45.4-.8.75-1.15 1.25-1.8.45-.2.95-.3 2.4-.65 12.7-2.55-.4.1.4 0 1.55-.15 4.2 1.05 2.65 1.15 4.1-1.65M11.9 14q.15-.4.3-.75.05-.15.15-.25-.25.55-.45 1m58.85-7.65l-.4-.4q-.8-.75-2.25-1.35-1-.35-1.8-.6-4.7-1.55-9.45-2.5-9-2.35-17.05-.05-.9 2.15 0 4.3.25.8.8 1.85 1.4 2.8 3.65 1.65 2.25-1.2 4-1.2 1.7 0 2.8.25 8.3 1.75 10.95 2.45.5.1 1.85.45.85.75.9 2.45 2.15 14.85 4.6 19.05l.1.2v.05l6.3 15.2q2.95-6.75.7-13-.7-1.25-1.25-2.45-.45-.9-.85-1.75Q68.95 18.8 70.75 8V6.35z"
                    id="BeaMus034__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus034__Symbol_51_0_Layer0_0_FILL"
                transform="translate(153.8 196.05)"
                id="BeaMus034__col1n"
            />
        </g></g>
    )
}

export default BeaMus034
