import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function AccHat062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M134.15 10.35q-3-.35-6.2-.65-2.05-.25-4.2-.4-1.25-.15-2.45-.2Q103.65 0 78.85 0 53.5 0 35.6 9.45h-.05q-1.2.1-2.35.25-1.3.15-2.5.25-3.15.35-6.15.75l-.8.1Q3.3 13.6.6 17.35q-.75 2-.55 1.95L13.6 55.15q3.5 1.6 7.7 3.1 2.6.95 5.35 1.75 21.95 6.65 51.6 6.65 29.65 0 51.55-6.65 2.7-.8 5.3-1.75 5.2-1.85 9.3-3.85.1-.05.25-.1L161 17.8q-1.55-4.05-23.45-7-1.7-.2-3.4-.45z"
                    id="AccHat062__Symbol_280_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.15.6q-1.3.15-2.5.25l-3.9 2.7q2.1-.3 4.3-.5 1-.7 2.1-1.35Q15.3 1.05 16.5.35q-1.2.1-2.35.25m105.7 16.2q-.7-1.8-1.75-3.6-54.55 7.3-116.55 0-1 1.5-1.55 3.1 2.2.3 4.7.65 1.8.25 3.7.5l39.55 2.45q6.6.2 13.65.2 6.85 0 13.3-.2l38.6-2.3q2.5-.35 5-.65.65-.1 1.35-.15M103.05.35q.8.45 1.65.95 1.15.65 2.25 1.35 2.7.25 5.25.5L108.9.6q-2.05-.25-4.2-.4-1.25-.15-2.45-.2.4.15.8.35z"
                    id="AccHat062__Symbol_186_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M29 .4q-2.2.2-4.3.5-.8.1-1.65.2-1.4.15-2.75.35Q0 4.15 0 7.85q0 .8 1.1 1.6 2.6 1.4 5.75 2.2 3.95 1 9.7 1.9l.4.1q.55-1.6 1.55-3.1Q21.8 5.1 29 .4m125.85 9.8q2.25-1.15 2.25-2.35 0-3.95-22.95-6.75h-.2q-2.35-.4-4.8-.6-2.55-.25-5.25-.5 7.65 4.85 11.15 10.55 1.05 1.8 1.75 3.6.15-.05.35-.05 5.45-.8 9.55-1.65 5.1-1.05 8.15-2.25z"
                    id="AccHat062__Symbol_135_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat062__Symbol_280_0_Layer0_0_FILL"
                transform="translate(112 36.2)"
                id="AccHat062__col1n"
            />
            <use
                xlinkHref="#AccHat062__Symbol_186_0_Layer0_0_FILL"
                transform="translate(131.05 45.3)"
                id="AccHat062__col1d"
            />
            <use
                xlinkHref="#AccHat062__Symbol_135_0_Layer0_0_FILL"
                transform="translate(114.1 47.95)"
                id="AccHat062__col1s"
            />
        </g></g>
    )
}

export default AccHat062
