import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400",
    "col2n": "#FFC400"
}

function ObjMor048({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M93.6 39.55v-.7-1.75q-.1-4-.9-7.7-2.65-12.15-12.85-21.45-5.45-5-12.7-6.75Q65.7.95 63.5.55h-.25q-5.1-1.1-11.25 0-.1 0-.2.05l-.1.05q-1 .3-4.65 1.6-.3.1-.5.2-1.4-.75-2.25-1.15-7.2-2.55-16.7 0-.1.05-.2.05-1.5.35-2.9.75-8.1 2.55-14.25 9.35T1.65 26.5q-.4 1.4-.7 2.9Q0 33.6 0 38.15q0 17.7 13.65 30.2 3.3 3 6.9 5.25 1.45.9 3.05 1.8 1.7.85 3.4 1.6 1.1.45 2.15.85 1.95.45 5.05 1.3l2.95.5q1.6.6 2.85.75 1.25.15 1.2.1-.05-.05 1.55.15.9.05 1.85.1h.4q.35.05.8.05.45.05 1 .05h3.05q1.5-.05 2.8-.1 3-.7 3.65-1.05.65-.35 1.2-.55l.7-.35.1-.05q.25-.1.65-.3 2.5.15 6.9-1.4 1.7-.6 3.9-1.6.5-.25 1-.55.3-.2.7-.4 4.45-2.55 8.4-6.15 3.05-2.75 5.4-5.8.4-.45.75-.95 1.6-2.2 2.9-4.5 4.4-8 4.7-17.55z"
                    id="ObjMor048__Symbol_192_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M39.2 31.7q-.35 1.45-1 2.95V80.8q.45.05 1 .05V31.7m30.3-20.2q-2.95-4.25-6.3-6.95-1.3-1.05-2.7-1.85-.2-.15-.45-.25-2.15-1.2-4.4-1.9-5.1-1.1-11.25 0-.1 0-.2.05l-.1.05q-1 .3-4.65 1.6-.3.1-.5.2-1.4-.75-2.25-1.15-7.2-2.55-16.7 0-.1.05-.2.05Q13.3 4 8.1 11.5 0 23.2 0 39.7t8.1 28.25q2.3 3.25 4.85 5.65 1.45.9 3.05 1.8 1.7.85 3.4 1.6 1.1.45 2.15.85 1.95.45 5.05 1.3l2.95.5q1.6.6 2.85.75 1.25.15 1.2.1-.05-.05 1.55.15-2.1-.45-4-1.3-1.15-.55-2.2-1.2-4.55-2.8-8.3-8.25-7.7-11.2-7.7-27.1 0-15.9 7.7-27.1Q26 7.9 33 5.55q2.35-.7 5.2-.95v17.65q.6.95 1 2.15l.4-19.95q.05.05.1.05.45 0 .95.05h.3Q50.7 5.4 57.8 15.7q7.65 11.2 7.7 27.1 0 6.95-1.5 13.05-1.35 5.15-.3 3.55l-2.95 5.65-.1-.05q-1.25 2.55-2.85 4.9-3.8 5.5-8.35 8.3-1.2.7-2.4 1.25-2.3 1-4.8 1.4 1.5-.05 2.8-.1 3-.7 3.65-1.05.65-.35 1.2-.55l.7-.35.1-.05q.25-.1.65-.3 2.5.15 6.9-1.4 1.7-.6 3.9-1.6.5-.25 1-.55 3.35-2.7 6.35-6.95 2.05-3 3.65-6.3.35-.85.7-1.65.15-.45.3-.85.2-.5.35-.95.35-.9.65-1.75.15-.55.3-1.05.25-.9.5-1.85.1-.3.15-.6l.3-1.3q.9-4.3 1.15-8.95 0-.65.05-1.3v-1.7q0-9.05-2.4-16.65-.55-1.7-1.2-3.25-1.8-4.4-4.5-8.3m-1.05 1.35q2.15 3.05 3.7 6.55.5 1.15 1 2.4 3 8.1 3 18.1 0 1.2-.05 2.4v.6q-.25 4.85-1.2 9.3-.15.35-.2.75-.55 2.1-1.2 4.05-.6 1.65-1.25 3.25-.45.9-.85 1.85l-.05.05-.4.8q-1.15 2.1-2.5 4.05-6.6 9.6-15.55 11-.45.05-.85.1 3.6-2.75 6.75-7.3 2.6-3.7 4.4-7.95.3-.9.65-1.8l-.4.55q.75-2.15 2-5.75 1.45-6.2 1.5-13.25-.05-16.55-8.15-28.25-6.85-9.85-16-11.4 1.05-.4 2.15-.7 1.8-.45 3.75-.55.6-.1 1.2-.05 2.8 0 5.5.75 2.6.75 5 2.3 4.4 2.8 8.05 8.15m-32.1-9.5q-1.9.05-2.75.35-7.9 2.1-13.8 10.65-8.1 11.7-8.1 28.25 0 16.5 8.1 28.2 3.2 4.6 6.9 7.4-1.7-.1-3.25-.4-8.3-1.75-14.5-10.8-7.7-11.2-7.7-27.1 0-15.9 7.7-27.05 5.85-8.6 13.65-10.6 1.2-.3 2.5-.45 6.35-.45 10.9 1.4l.35.15z"
                    id="ObjMor048__Symbol_193_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M41.75 23.65q3.9-1.85 4.85-6.35.9-4.45-1.6-8.9-2.55-4.55-7.1-6.55-.7-.3-1.3-.5-.95-.3-1.85-.4-2.8-.35-5.3.8-3.85 1.8-4.7 6.3h-.15q-.05-.45-.15-.9-.1-.65-.3-1.2-.4-1.2-1-2.15Q21.9 1.9 19.7.9 16.45-.65 12.75.45q-.7.2-1.45.55-.15 0-.25.1-4.4 1.95-6.85 6.35-2.55 4.5-1.65 9 .95 4.45 4.8 6.3h.05q.55.25 1.15.45 1.7.5 3.45.4 2.3.3 4.9-.9 3.85-1.85 6-6 .1-.3.25-.5.65-1.5 1-2.95.05-.25.1-.45l.05-.05q.3 2.35 1.55 4.75 2.15 4.2 6 6 2.55 1.2 4.85.9 1 .1 2 .05.7-.05 1.45-.2.8-.25 1.6-.6m.05 15.8l3.9 7.15 7.15-9.15-.1-.05-.05.05-7.35 1.05L43.1 35l-5.9 1.5-2.9-2.85-18.8 1.65L6.65 39l-6.3-2.9-.35-.5 4.25 9.8 5.35-4.75 2.85 4.3 6.8-6.55 3.9 5.15 8.8-6.15 1.45 7.15.1-.05 8.3-5.05z"
                    id="ObjMor048__Symbol_194_0_Layer0_0_FILL"
                />
                <g id="ObjMor048__Layer1_0_FILL">
                    <path d="M340.05 333l-3.5-5.15-8.2 4.75-2.9-5.15-4.7 4.7-4.55-2.25-4.1 6.4-8.8-2.9.05.1.4 1 4.65 6.1 5.95-3.9 2.7 3.7 5.95-5.15 3.5 4.1 10.85-5.55 1.05 7 8-4.5 3.05 5.75 7.05-5.75h-6.85v-6.4l-9.6 3.1m6.8-16.3q.75-4.2-1.4-8.4-1.55-3.05-3.95-4.8-.95-.75-2.05-1.25-3.85-1.8-7.15-.1-2.85 1.45-3.8 4.8-.1.15-.1.35l-.2.7q-.048.145-.05.3-.002-.155-.05-.3l-.1-.8q-.05-.3-.1-.55-.35-1.45-1-2.6-1.1-1.75-2.95-2.7-3.3-1.75-7.15.1-.75.35-1.45.75-1.05.7-1.95 1.6-1.55 1.5-2.6 3.7-2.2 4.15-1.4 8.35.45 2.35 1.7 3.95.5.65 1.2 1.2.55.4 1.2.75 1.1.55 2.25.75 2.3.3 4.9-.9 3.85-1.85 6-6 .1-.3.25-.5.65-1.5 1-2.95.05-.25.1-.45 0-.2.05-.35V311.65q.3 2.35 1.55 4.75 2.15 4.2 6 6 2.55 1.2 4.85.9 1-.15 2-.55.15-.1.3-.15.6-.35 1.2-.75 2.25-1.75 2.9-5.15z" />
                    <path
                        fill="#005100"
                        d="M327.5 274.25l-.05-.2-1.05-6.45-7.2.85 7.2 14.95h2.6l-.7-4.3v-.15l-.8-4.7z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMor048__Symbol_192_0_Layer0_0_FILL"
                transform="translate(281.1 280.45)"
                id="ObjMor048__col1n"
            />
            <use
                xlinkHref="#ObjMor048__Symbol_193_0_Layer0_0_FILL"
                transform="translate(288.7 280.45)"
                id="ObjMor048__col1d"
            />
            <use
                xlinkHref="#ObjMor048__Symbol_194_0_Layer0_0_FILL"
                transform="translate(303.75 298.9)"
                id="ObjMor048__col2n"
            />
            <use xlinkHref="#ObjMor048__Layer1_0_FILL" />
        </g></g>
    )
}

export default ObjMor048
