import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function BeaChi006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.6 8q1.95-2.9 1.35-4.85-.2-.6-.55-1.1-2.65 1.4-5.45 1.8-3.65.4-6.9-.5-1.1-.3-2.2-.75-.2-.1-.4-.15l-.2-.1q-1.3-.6-2.1-1.35H19q-.1.3-.55 1.2-.5.85-2.65 1.6-2.1.75-4.3.25-2.2-.5-4.65-1.35Q4.4 1.85 1 0 .3.65.1 1.75v.05q-.5 2.15 1.7 5.5 1.9 2.9 5.9 6.7.2.15.4.35.7.6 1.6 1.35 8-2.45 15.4.75l.55.25q1.1-.9 1.55-1.25 3-1.75 5.35-4 1.5-1.45 2.5-2.75l.55-.7z"
                    id="BeaChi006__Symbol_6_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M33.9 0q-.7.7-1.2 1.05-6.3 3.1-8.75 3.1-.55 0-3.1-.55-2.6-.55-4.75-.55-1.8 0-4.05.4Q9.8 3.8 9 3.8q-4.3 0-7.4-2.55Q.9.65.35 0H0q1.9 2.9 5.9 6.7.2.15.4.35.7.6 1.6 1.35 8-2.45 15.4.75l.55.25q1.1-.9 1.55-1.25 3-1.75 5.35-4 1.5-1.45 2.5-2.75l.55-.7.4-.7h-.3z"
                    id="BeaChi006__Symbol_7_0_Layer0_0_FILL"
                />
            </defs>
            <g id="BeaChi006__col1n" transform="translate(174.05 241.8)">
                <use
                    xlinkHref="#BeaChi006__Symbol_6_0_Layer0_0_FILL"
                    id="BeaChi006__col1n_FL"
                />
            </g>
            <g id="BeaChi006__col1d" transform="translate(175.85 249.1)">
                <use
                    xlinkHref="#BeaChi006__Symbol_7_0_Layer0_0_FILL"
                    id="BeaChi006__col1d_FL"
                />
            </g>
        </g></g>
    )
}

export default BeaChi006
