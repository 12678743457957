import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2n": "#FFFFFF"
}

function ObjMus037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#1C1C1C"
                    d="M227.95 261.35q0-.05.1-.1l.3-.2q.151-.13.3-.25.201-.158.4-.3 1.786-1.458 3.4-3 10.365-9.98 13.05-23.45-3.75 2.8-10.25 5.75-3.772 8.172-14.05 17.7-.343.324-.7.65-1.436 1.34-3 2.65-2.13 1.778-4.5 3.5-.446.328-.9.65l-.4.3-4.95 3.8 1.95-.65v.05l-1.2.4.5.35 1.7-.55q4.029.196 7.7-1.3-.743.494.35-.15.563-.28 1.5-.85 1.416-.761 2.5-1.4 3.684-2.044 3.95-2.15l.4-.2q.85-.55 1.65-1.15l.2-.1m-3.9 2.05l.15-.05q-.694.408-1.35.75.215-.121.4-.3.725-.38.8-.4z"
                    id="ObjMus037__Layer13_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M257.8 309.25h.05l-.2-.1q.05.05.15.1m-143.35-42.8q.02-.1.05-.2.04-.135.05-.3l-41-8.55q-4.55.15-3.9 3.8 8.85 21.85 22.25 42.1 10.35 15.7 23.5 30.45 3 3.4 6.15 6.7 5.45 5.1 11.45.65l32.75-22.5 60.55.4q10-2.5 2.85-8.05l-15.9-9.6q-.4-.3-.85-.5l-10.2-6.15q-5.45-4.05 6.1-14.75 3.55-7.2-5.45-7.4-9.8-.2-12-3.15l-4.1-5.85q-3.35-3.35-9-1.35l-26.45 11.5-16.1-3.35-2.65 13.55q.495.038.55.05.25.05.55.1 2.15.45 3.8 2.1 2.25 2.25 2.25 5.45t-2.25 5.45q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.85 4.2-4.1 7.45-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-2.5.95-5.4.95-6.25 0-10.65-4.4-4.45-4.4-4.45-10.6 0-5.6 3.6-9.75l.3-.3.05-.05q0-.05.05-.05.2-.25.45-.5l.2-.2.05-.05q0-.05.05-.05.15-.15.35-.25.05-.1.1-.15 1.693-9.372 4.2-17.8z"
                    id="ObjMus037__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M116.5 10.8L92.45 4.05q-.05 0-.05.05l-9.65-2.8-.8-.15-2.3-.65q-3.2-.95-3.45 0 .05 1.65-1 3.15-.3.45-.7.85-.7.6-.95.95-.4.35-.85.8-1.1.65-2.2 1.25-.3.2-.55.35-.15.05-.25.1.05 2.7-1.7.6l-25.05 8.3v-.05l-2.95.95.05.05-4.75 1.55-.4.2q-.35.15-.65.25-.45.2-.85.3l-.15.05-.4.1-.1.05-7.85 2.6v-.05l-6.2 2.05v.05l-.8.25v-.05l-1.95.65h-.05l-3.5 1.15h-.05V27h-.05l-.5.15.05.05.05.2q-.4 0-.85.05L11 27.4l-5.05 1.65v.1L0 31l.05.15v.05l.05.05.45 1.55H.6l2.1 7.3-.1-.4 1.2 4.15h-.05l.45 1.5 1.15-.45.05.05V45l9.75-3.5.85-.5.05.15 56.3-19.85q.45-1.55 1.6-.6l-.05-.15.9-.2q.95-.3 1.95-.6Q90.6 17.2 103.5 18.4q3.35.6 6.7.9 3.75.3 7.5.25 4.45-.55 4.35-3.85-.7-3.65-5.55-4.9z"
                    id="ObjMus037__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill="#999"
                    d="M133.3 303.35l-.1-.25q-.95 1.65-2.4 3.1-.233.225-.5.45L132 312q2.85-.45 3.25-3l-.45-1.25-1-2.95-.5-1.45z"
                    id="ObjMus037__Layer4_1_MEMBER_0_FILL"
                />
                <path
                    fill="#D9D9D9"
                    d="M134.6 307.35q.226.571.3.85l128.45-45.55 30.6-4.8q.212-.027.3-.2.132-.134.1-.35-.027-.162-.2-.3-.134-.082-.35-.05l-30.6 4.8q-.012.009-.05 0l-128.55 45.6m-1.25-3.3q.196.435.35.8l128.65-45 24.3-4q.212-.04.3-.2.132-.147.1-.35-.04-.162-.2-.3-.147-.082-.35-.05l-24.3 4q-.013.01-.05 0l-128.8 45.1m1.2-4.4q-.038.418-.4 1.1l127-43.7 18.4-3.3q.213-.04.3-.2.132-.148.1-.35-.04-.163-.2-.3-.148-.082-.35-.05l-18.4 3.3q-.013.01-.05 0l-126.4 43.5m4.4-4.85q-.016.044-.05.05l121.6-40.75h-.05l10.55-3q.206-.02.3-.2.084-.142 0-.35-.02-.156-.2-.25-.142-.083-.35-.05l-10.55 3-121 40.55q-.014.099-.05.15-.02.353-.2.85z"
                    id="ObjMus037__Layer4_2_FILL"
                />
                <path
                    id="ObjMus037__Layer4_0_1_STROKES"
                    stroke="#369"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M196.75 272.4l-.75.25"
                />
                <g
                    id="ObjMus037__Symbol_47_0_Layer0_0_FILL"
                    fill={colors.col2n}
                >
                    <path d="M81.4 36.15q4.7 0 1.5-3l-24.75-15q-5.8-2.95-6.3-6.45l-4.95 1.8-.3-.9-.6-1.8q-.1-.2-.1-.3l-.1-.35-.05-.05L42.5.55h.05V0q-20.1 6.8-27.8 9.35l1.15 4.4-.3-.9 1.8 5.85-.15-.6 1.6 5.2-8.8 3.05-.1.05L0 29.85l2.25 5.85 79.15.45m-14.2-7.8q.55.75.55 1.8 0 1-.55 1.8-.15.2-.35.4-.9.9-2.25.9-1.3 0-2.2-.9-.85-.85-.9-1.95v-.25q0-1.35.9-2.25.9-.9 2.2-.9 1.35 0 2.25.9.2.2.35.45z" />
                    <path d="M64.6 27q-1.3 0-2.2.9-.9.9-.9 2.25v.25q.05 1.1.9 1.95.9.9 2.2.9V27zM67.75 30.15q0-1.05-.55-1.8-.15-.25-.35-.45-.9-.9-2.25-.9v6.25q1.35 0 2.25-.9.2-.2.35-.4.55-.8.55-1.8z" />
                </g>
                <g id="ObjMus037__Layer4_0_FILL">
                    <path
                        fill="#E30000"
                        d="M257.6 309.2q.05.05.15.1l-.15-.1z"
                    />
                    <path
                        fill="#CCC"
                        d="M206.45 306.05q-.68 0-1.25.25v5.75q.57.25 1.25.25 1.35 0 2.25-.9.2-.2.35-.4.55-.8.55-1.8 0-1.05-.55-1.8-.15-.25-.35-.45-.9-.9-2.25-.9z"
                    />
                    <path
                        fill="#AFAFAF"
                        d="M204.35 276.25l-1.7-5.8-.8.25 2.7 9.4h.05l.5 1.7h-.05l.9 2.95.9-.35-.5-1.5-.8-2.7v-.1l-1.2-3.85m10.4.4q-.05.1-.05.25 0 .982.95 1H215.8l.5-.15q.1-.1.2-.25.2-.3.2-.6 0-.65-.3-.85-1.113-.564-1.65.6m-1.05-6.35l-.2.05q-.355.118-.5.4-.1.185-.1.45 0 .31.1.5.123.296.4.4.675.301 1.35-.35.15-.3.15-.55 0-1.1-1-.95-.1 0-.15.05h-.05m2-4.15l.6 1.9h-.05l3.5 11.85.9-.35-4.15-13.7-.8.3m-6.15 5.3h.05l1.55 5.35h-.05l.25.9h.05l.5 1.75h-.05l.4 1.35h.05l.45 1.55.9-.35-4.15-13.8-.8.25.85 3m-6.6-1.25v-.05l-.05.05h.05m30.3-10l-.8.25L236.4 274l.9-.3-4.05-13.5m-1.45 8.8q0-1.1-1-.95-1 .1-1 .95 0 .95.95 1l.7-.25q.1-.05.15-.15.025-.038.05-.1.15-.238.15-.5m-6.75-5.95q-.462.179-.85.3l4 13.55.9-.3-4.05-13.55m-1.4.45l-.1.05.5-.15q-.21.055-.4.1m-27.45 24.8v-.1l-.05-.05v.1l.05.05m4.6-9.75q0 1 1 1 .5 0 .8-.4.2-.3.2-.6 0-1.1-1-.95-1 .1-1 .95m-7.8 3.3l.35 1.1-.35-1.1m1.55 1.75h-.05l-2.8-9.45-.05-.1v-.1l-.85.3.05.15v.05l.05.05.45 1.55 2.15 7.3-.15-.4 1.2 4.15.45 1.5.9-.35-.45-1.55h-.05l-.85-3.1m2.3-10.9l-.1-.3-.75.25.05.3v.15l1.1 3.7q.168.574.3 1.15.455 1.434.85 2.85l.9 3.05.9 2.95.9-.3-4.1-13.7-.05-.1m46.45-7.05h-.05l1.15 3.95h.05l.4 1.4.95-.3-1.35-4.4-2.65-8.8v-.05l-.85.25.65 2.2h-.05l.9 2.85h-.05l.3 1.05h.05l.55 1.85m6.5-1.3h.05l.9-.35-.95.35m-.25-1.6q0-.75-.5-.95h-.1q-.674-.17-1.2.35-.2.25-.2.6 0 1 1 1 .3 0 .5-.1l.3-.3q.2-.3.2-.6m2.05-8.55l-.85.25 1.8 6.2h.05l.5 1.7h-.05l1.55 5.2.9-.3-.8-2.6-3.1-10.45z"
                    />
                    <path d="M193.35 282.95l.15.4-2.15-7.3-.45-1.55-.05-.05v-.05l-.05-.15-.45-1.25-7.25 2.5.9 2.9h-.05l.4 1.2 3.25 9.55v.05l.1.3q.074.169.1.3.34.932.65 1.85l.3.9 4.5-1.65h.05l2.15-.8-.45-1.5-.45-1.5-1.2-4.15-.35-1.1.35 1.1M184 276.6h-.05l.05-.05v.05m-37 12.45l.15.4q.127.437.25.85h-.05l1.05 3.45h-.05q1.399 4.7 2.75 9.4l.25.8.45 1.5.1-.05 8.8-3.05-1.65-5.25.2.65-1.8-5.85.3.9-1.65-5.25-1-3.25-8.65 2.75.1.45.05.05.4 1.5z" />
                    <path fill="#FFF" d="M183.95 276.6h.05v-.05l-.05.05z" />
                    <path
                        fill="#D9D9D9"
                        d="M288.55 251.5q-1.1 0-1.9.75-.699.699-.8 1.65.074.314.15.75.147.733.4 1.15.115.165.25.3.8.8 1.9.8 1.1 0 1.85-.8.8-.8.8-1.9 0-1.15-.8-1.95-.75-.75-1.85-.75m9.9 4.7q0-1.15-.8-1.95-.8-.75-1.9-.75-1.1 0-1.9.75-.775.775-.95 2.45.108.343.35.55.21.175.55.8l.05.05q.8.8 1.9.8 1.1 0 1.9-.8.8-.8.8-1.9m-17.6-7.15q-1.1 0-1.9.75-.8.8-.8 1.95v.25l.55 1.1q.288.612.65 1 1.286.35 2.75-.15.3.05.65-.3.8-.8.8-1.9 0-1.15-.8-1.95-.8-.75-1.9-.75m-8.4-2.45q-1.1 0-1.9.75-.8.8-.8 1.95 0 .705.35 1.25.348.307 1.3 1.25.48.2 1.05.2 1.1 0 1.9-.8.8-.8.8-1.9 0-1.15-.8-1.95-.8-.75-1.9-.75z"
                    />
                    <path
                        fill="#999"
                        d="M308.15 252.7l2.05-3.9-7.75-2.25-2.1 4.1 1.75.55q-.35.65-.6 1.25l4.35 1.2q.35-.8.6-1.2l1.7.25m-28.05-8.05l2.05-3.85-7.55-2.25-2 3.8 1.65.55q-.45.85-.7 1.65l4.25 1.2q.05-.15.15-.25l.65-1.3 1.5.45m20.5 1.35l-7.4-2.1-2 4 1.75.55q-.4.7-.6 1.4l4.3 1.25.7-1.4.8.3q.25-.05.45 0l2-4m-12.35.95l1.35.4 2-3.95-7.4-2.05-2 3.9 1.6.45q-.35.85-.55 1.6l4.25 1.2q.45-1 .75-1.55z"
                    />
                    <path
                        fill="#FAEAD3"
                        d="M264.7 263.8l.35-.1-4.35-12.65q-.09.095-.2.15l-1.75.6 4.4 12.7 1.6-.55-.05-.15z"
                    />
                    <path
                        fill="#666"
                        d="M96.85 315l3-1-4.1-5.75-3.3 1.05 4.4 5.7z"
                    />
                    <path
                        fill="#6A6A6A"
                        d="M112.65 319.85q-.15.45-.15 1.05 0 .55.15 1.05.2.55.7 1.05.9.9 2.15.9t2.15-.9q.9-.85.9-2.1 0-.8-.3-1.35-.2-.45-.6-.8-.9-.9-2.15-.9t-2.15.9q-.35.35-.55.8-.1.1-.15.3m18.8 1.05q0-.8-.3-1.35-.2-.45-.6-.8-.9-.9-2.15-.9t-2.15.9q-.35.35-.55.8-.05.05-.1.15-.05.05-.05.15-.15.45-.15 1.05 0 .55.15 1.05 0 .05.05.15.05.05.05.1.2.4.6.8.9.9 2.15.9t2.15-.9q.4-.35.6-.8.3-.6.3-1.3m12 0q0-.8-.3-1.35-.2-.45-.6-.8-.9-.9-2.15-.9t-2.15.9q-.35.35-.55.8-.1.1-.15.3-.15.45-.15 1.05 0 .55.15 1.05.05.15.1.25.2.4.6.8.9.9 2.15.9t2.15-.9q.4-.35.6-.8.3-.6.3-1.3z"
                    />
                    <path
                        fill="#BFBFBF"
                        d="M131.05 305.9q-.119.169-.25.3-.233.225-.5.45-1.15 1.058-2.4 1.85l-.1.05q-.05.05-.15.1l-.6.3.95 2.85q2.4.4 4 .2.575-.09 1.05-.3-1.224-3.355-2-5.8m16.1-16.45l-.15-.4q-2.456-.76-3.7 1.6l.35.9h-.05l2.35 7.5v.05l.4 1.3.5 1.6.45 1.45.5 1.65q1.6.35 3.55-1.15l-.25-.8q-1.351-4.7-2.75-9.4h.05l-1.05-3.45h.05q-.123-.413-.25-.85z"
                    />
                    <path fill="#C71212" d="M102.45 273l-.05.05.05.05v-.1z" />
                </g>
                <g id="ObjMus037__Layer4_1_FILL">
                    <path
                        fill="#999"
                        d="M206.45 306.05q-1.3 0-2.2.9-.9.9-.9 2.25v.25q.05 1.1.9 1.95.9.9 2.2.9v-6.25z"
                    />
                    <path
                        fill="#6A6A6A"
                        d="M287.5 255.15q.4.4.95.4.5 0 .9-.4t.4-.95q0-.5-.4-.95-.4-.35-.9-.35-.55 0-.95.35-.4.45-.4.95 0 .55.4.95m8.15 2.4q.5 0 .9-.4t.4-.95q0-.5-.4-.95-.4-.35-.9-.35-.55 0-.95.35-.4.45-.4.95 0 .55.4.95.4.4.95.4m2.5-7.55q.25-.05.45 0l.55-1.1-7.35-2.15-.6 1.15 6.15 1.8.8.3m10 2.7l.55-1.05-7.75-2.25-.6 1.25 6.1 1.8 1.7.25m-35.8-2.05q.5 0 .9-.4t.4-.95q0-.5-.4-.95-.4-.35-.9-.35-.55 0-.95.35-.4.45-.4.95 0 .55.4.95.4.4.95.4m7.45 2.05q.4.4.95.4.5 0 .9-.4t.4-.95q0-.5-.4-.95-.4-.35-.9-.35-.55 0-.95.35-.4.45-.4.95 0 .55.4.95m-6.65-11.4l-.55 1.05 7.5 2.3.6-1.15-7.55-2.2m16.45 6.05l.5-1.1-7.35-2.15-.55 1.15 7.4 2.1z"
                    />
                    <path
                        fill="#333"
                        d="M112.55 321.15l.1.4q.15.45.4.85.1.1.2.15.6.6 1.4.7.2.05.4.05 1 0 1.7-.75.65-.6.8-1.4v-.35q0-.2-.05-.4-.1-.5-.3-.85-.2-.25-.45-.5-.05-.1-.2-.2-.6-.55-1.5-.55-1.05 0-1.8.75l-.4.5q-.1.2-.2.45-.1.4-.1.8v.35m13.6-2.1l-.4.5q-.05.15-.15.35 0 .05-.05.1-.1.4-.1.8v.35l.1.4q0 .05.05.15.1.25.25.5l.1.2q.1.1.2.15.6.6 1.4.7.2.05.4.05 1 0 1.7-.75.2-.15.35-.35.35-.45.45-1.05v-.35q0-.2-.05-.4-.1-.5-.3-.85-.2-.25-.45-.5-.05-.1-.2-.2-.6-.55-1.5-.55-1.05 0-1.8.75m16.25 1.35q-.1-.5-.3-.85-.2-.25-.45-.5-.05-.1-.2-.2-.6-.55-1.5-.55-1.05 0-1.8.75l-.4.5q-.1.2-.2.45-.1.4-.1.8v.35l.1.4q.1.35.3.65l.1.2q.1.1.2.15.6.6 1.4.7.2.05.4.05 1 0 1.7-.75.2-.15.35-.35.35-.45.45-1.05v-.35q0-.2-.05-.4z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMus037__Layer13_0_FILL" />
            <use
                xlinkHref="#ObjMus037__Symbol_121_0_Layer0_0_FILL"
                id="ObjMus037__col1n"
            />
            <use
                xlinkHref="#ObjMus037__Symbol_60_0_Layer0_0_FILL"
                transform="translate(190.8 243.25)"
                id="ObjMus037__col1s"
            />
            <use
                xlinkHref="#ObjMus037__Symbol_47_0_Layer0_0_FILL"
                transform="translate(141.85 279.05)"
                id="ObjMus037__col2n"
            />
            <g>
                <use xlinkHref="#ObjMus037__Layer4_0_FILL" />
                <use xlinkHref="#ObjMus037__Layer4_0_1_STROKES" />
            </g>
            <use xlinkHref="#ObjMus037__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus037__Layer4_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMus037__Layer4_2_FILL" />
        </g></g>
    )
}

export default ObjMus037
