import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D23900",
    "col1n": "#FF5400",
    "col1s": "#932800",
    "col2n": "#FFC400"
}

function ShiSho049({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M148.1 8q-1.4-2-4.55-3.15l.1-1.15Q143-.2 139.3.2q-.2 0-.4.05l-1.65 1.35-.6.5q-.15.1-.3.25l-1.8 1.3q-.05 0-.1.05l-1.55 1.15q-.45.3-.9.65-6.4 4.25-13.2 7.4-2 .85-3.95 1.65-.15.05-.25.1-4.8 1.9-9.8 3.25-.35.1-.65.2-.15 0-.25.05-1.4 2.55-3.9 4.3-5.7 3.95-9.9 7.35-3.4 3-6.25 6.45-3 3.6-5.4 7.7-.25.4-.45.75-.05.05-.05.1l-.1.1q-.8-.45-.75-.85-.5-3.35-3.4-7.15-.3-.35-.65-.7-4.75-4.85-9.85-7.15-7.3-3.3-11-11.45-.1-.05-.15-.05-3-.85-5.95-1.9-2.4-.9-4.75-1.85-8.45-3.6-16.15-8.95L23.65 3.7q-.25-.2-.5-.35-.3-.25-.55-.45-.25-.15-.5-.35-.1-.05-.2-.15-.3-.2-.6-.45L19.15.25q-4.9-1.25-5.1 3.4v.05q0 .05.05.15l.5 1Q11.45 6 10.05 8.05q-1.35 2-1 4.85 0 .25.05.55h.1l.2.9v.05H8.8q-.1 0-.1.05-6.35.7-8.2 7.05-.7 1.85-.45 4.1.05.75.25 1.6 1.15 2.4 4.45 2.9 1.65.2 3.85-.05 7.3-.45 12.6 3.95l1.65 1.6v.05l-2.7 14q-.55 2.7-1.1 5.45L15.6 69.4q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H13.5q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H144.65q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75 1.3-1.2q.1-.1.25-.2 5.3-4.6 12.7-4.2 1.8.15 3.25 0 4.1-.4 5.3-3.15 0-.2.05-.4.5-2.75-.35-5-1.85-6.35-8.2-7.05 0-.05-.1-.05H148.75v-.05l.2-.9h.1q.05-.3.05-.55.4-2.9-1-4.9M99.3 30.45h-.05l.2-.05-.15.05m-18 34.9h.05q-.047.094-.05.2v-.2m-45.75-43.7l-.05.05v-.05l.2-.35-.15.35z"
                    id="ShiSho049__Symbol_128_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 25.6q.05.75.25 1.6 1.15 2.4 4.45 2.9 1.65.2 3.85-.05 7.3-.45 12.6 3.95l1.3 1.3q-.4-4.65-4-6.3-2.4-1.1-8.35-1.25-2.15-.05-3.8-.2-3.05-.25-4.45-.8Q.75 26.3 0 25.6m23.9-8.35q1.1.75 2.15 1.65.5.4.65.7v-1q-1.5-4.8-6.35-7.35-1.3-.7-2.95-1.3-3.15-1.15-7.4-1.9-1.35 2-1 4.85 0 .25.05.55h.1q3.05-.5 6.05.05 4.45.75 8.7 3.75m-3.65-11.8q.55.2 1.05.45.95.45 1.85.95 2.45 1.35 4.3 3.25l.2.2q-1-2.25-2.35-3.95-.85-1.1-3.25-3.75l-.2-.2q-.3-.2-.6-.45L19.1.25Q14.2-1 14 3.65v.05l.7.15.5.1q2.7.55 5.05 1.5m25.8 10.2l-1 2.8-13.2 12.1 9.55 4.3 4.65-19.2m6.1 1.95q-.1-.05-.15-.05.45 5.55 3.55 9.95 2.5 3.6 6.35 5.9 3.55 2.15 9 5.3 3.95 2.6 6.05 5.85l.1-.5q-.5-3.35-3.4-7.15-.3-.35-.65-.7-4.75-4.85-9.85-7.15-7.3-3.3-11-11.45m51.95.5q-.15 0-.25.05-1.4 2.55-3.9 4.3-5.7 3.95-9.9 7.35-3.4 3-6.25 6.45-3 3.6-5.4 7.7-.25.4-.45.75-.05.05-.05.1 2.05-3.55 7-6.4 5.95-3.05 8.85-4.8 5.1-3.05 7.55-7 .4-.65.75-1.3 1.55-3.15 2.05-7.2m10.7-3.55q-.15.05-.25.1L115.5 35l10.3-5.25-11-15.2m24.4 14.2q-4 1.8-4.1 6.9l1.3-1.2q.1-.1.25-.2 5.3-4.6 12.7-4.2 1.8.15 3.25 0 4.1-.4 5.3-3.15 0-.2.05-.4-.45.2-.95.35-1.7.55-5.9.7-1.4.05-3.05.05-6.2 0-8.85 1.15m3.65-24.8q.25-.05.75-.25-.65-3.9-4.35-3.5-.2 0-.4.05L137.2 1.6l-.6.5q-.15.1-.3.25l-1.8 1.3q-3.15 4-4.3 6.05v.8q.15-.2.4-.4 1.3-1.35 2.95-2.4.65-.45 1.35-.85.9-.5 1.85-.95.65-.3 1.3-.55 2.2-.85 4.8-1.4m5.2 4.05q-1.85.15-3.4.4-2.3.4-4.2 1.05-7.623 2.59-8.45 9.45.75-.65 1.55-1.2.3-.25.6-.45 4.25-3 8.7-3.75 3-.55 6.05-.05h.1q.05-.3.05-.55.4-2.9-1-4.9z"
                    id="ShiSho049__Symbol_129_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M55.7 67.05V67q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55m0-14.15v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6M10.55.9l-5.8 7.85-.05.05L0 15.1l9.6 4.05 5.7-16.4q-2.4-.9-4.75-1.85m44.5 39.5q.65-.65.65-1.6 0-.95-.65-1.6-.65-.65-1.6-.65-.95 0-1.6.65-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65m29-38.75L86.7 19.3l9.05-5.2L88 0q-2 .85-3.95 1.65z"
                    id="ShiSho049__Symbol_131_0_Layer0_0_FILL"
                />
                <g
                    id="ShiSho049__Symbol_130_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path d="M.9 10.65q.15-.05.4-.05 2.1-.2 4.15.1 4.8.75 9.4 4.2.45.3.85.65.4.3.8.65 0 .05.05.05.95.45 1.05 0 0-.15-.05-.35-.15-.3-.65-.7-1.05-.9-2.15-1.65-4.25-3-8.7-3.75-3-.55-6.05-.05l.2.9v.1q.05-.05.1-.05.3-.05.6-.05m9.65-8q1 .4 1.95.85l1.1.6q2.6 1.4 4.45 3.45.7.6 1.05.35.6-.2-.6-1.3l-.2-.2Q16.45 4.5 14 3.15q-.9-.5-1.85-.95-.5-.25-1.05-.45Q8.75.8 6.05.25l-.5-.1-.7-.15q0 .05.05.15l.5 1q.1.05.25.05 2.65.55 4.9 1.45m57.35 37.7l-.1.5q-.05.1-.05.2-.55 2-1 4.2h-.05q-.15.8-.3 1.55-1.25 5.35-1.15 10.75V85.4h1.2V57.85q-.2-5.45.95-10.95.15-.7.3-1.45.55-2.5 1-4.25v-.05l-.05.05q-.8-.45-.75-.85m71.65-29.7l.2-.9q-3.05-.5-6.05.05-4.45.75-8.7 3.75-.3.2-.6.45-.8.55-1.55 1.2v.05q-.75.55-.7 1v.05q.1.4 1.05-.05.05 0 .05-.05.4-.35.8-.65.15-.15.35-.3.25-.2.5-.35 4.55-3.45 9.35-4.15 2.05-.35 4.2-.15.25 0 .4.05.3 0 .6.05.05 0 .1.05v-.1M134.45 0q-.5.2-.75.25-2.6.55-4.8 1.4-.65.25-1.3.55-.95.45-1.85.95-.7.4-1.35.85-1.65 1.05-2.95 2.4-.25.2-.4.4-.6.6-.6.9 0 .15.2.2.2.15.5.05.25-.15.55-.4 1.2-1.3 2.7-2.35.85-.6 1.75-1.1l1.1-.6q1.05-.5 2.2-.95 2.15-.85 4.65-1.35.15 0 .25-.05l.1-1.15zM26.3 18l.05-.05.15-.35-.2.35V18zM66 18.15h.05v-.25q-.002.106-.05.25zM90.05 26.75h.05l.15-.05-.2.05z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSho049__Symbol_128_0_Layer0_0_FILL"
                transform="translate(113.45 228.4)"
                id="ShiSho049__col1n"
            />
            <use
                xlinkHref="#ShiSho049__Symbol_129_0_Layer0_0_FILL"
                transform="translate(113.5 228.4)"
                id="ShiSho049__col1d"
            />
            <use
                xlinkHref="#ShiSho049__Symbol_130_0_Layer0_0_FILL"
                transform="translate(122.65 232.1)"
                id="ShiSho049__col1s"
            />
            <use
                xlinkHref="#ShiSho049__Symbol_131_0_Layer0_0_FILL"
                transform="translate(144.25 241.3)"
                id="ShiSho049__col2n"
            />
        </g></g>
    )
}

export default ShiSho049
