import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M52.4 31.55q9-5.4 9-13.05t-9-13.1Q43.4 0 30.7 0T9 5.4q-9 5.45-9 13.1 0 7.65 9 13.05Q18 37 30.7 37t21.7-5.45z"
                    id="FacMou056__Symbol_81_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou056__Symbol_81_0_Layer0_0_FILL"
                transform="translate(162 203.2)"
                id="FacMou056__col1n"
            />
        </g></g>
    )
}

export default FacMou056
