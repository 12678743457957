import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#006AC9",
    "col1s": "#00284D",
    "col2d": "#999999",
    "col2n": "#BFBFBF"
}

function ShiSho074({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M129.35 4.1q-1.4-2.1-2.9-4.1l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-2.85 1.95-5.8 3.7l-3.25 6.15q.5 2.25 1.45 3.65l-.95-.6q-2.4 1.15-2.25 4.9l.9.5q-1.6-.1-3.6.45l-6.2 12.25q.9 3.7 2.85 5.2-4.4 2.95-6.95 4.95-14.95 1.9-27.9 2.3h-4.3q-13-.4-27.95-2.3-2.65-1.3-7.9-3.45 3.65-.6 3.85-6.7l-6.2-12.25q-1.55-.9-2.85-1.25.5-2.75-1.85-4.1.35-1.45.25-3.55l-3.25-6.15q-2.95-1.75-5.8-3.7L11 3.5Q9.55 2.45 8.2 1.4L6.35 0q-.1.05-.15.15-1.4 1.9-2.75 3.9L12 19.9q2.5 1.1 4.35 1.35-1.1 2.55 1.4 3.35-.75 1.65-.95 4.3l6.1 12.15q1.3.6 2.4.95-.45.8-.95 1.85-.2.1-.1-.15v-.1L8.75 59.95q-1 .7-2 .75-.7 0-1.35-.25h-.35l.05-.1h-.05l-2.1 8.85q-.55 2.1-1.05 4.2L1 77l-.95 3.6v.25L0 80.9h.2q.25.1.5.15H.85q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5-20.15h-.25q-.65.25-1.35.25-1-.05-2-.75l-15.5-16.35v.1q.1.25-.1.15-.9-1.15-1.6-2 1.35-.1 3.05-.8L116 28.9q-.45-2.15-1.4-3.5l.1.05q2.5-1.4 2-4.75l-.25-.15q1.85.2 4.35-.65l8.55-15.8z"
                    id="ShiSho074__Symbol_210_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M54.25 24.65q.15-.15.15-.35V.5q0-.25-.15-.4-.05-.05-.1-.05-.05-.05-.1-.05H.35Q.3 0 .25.05.2.05.15.1 0 .25 0 .5v23.8q0 .2.15.35l.1.1.3.1h53.3l.3-.1.1-.1m-.9-23.6V6.9H1.05V1.05h52.3m0 7v15.7H1.05V8.05h52.3z"
                    id="ShiSho074__Symbol_207_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M16.8 30.1q-.05 0-.1.05-1.4 1.4-1.4 3.3 0 1.95 1.4 3.35.2.2.45.4 1.25.95 2.85.95 1.95 0 3.3-1.35 1.4-1.4 1.4-3.35 0-.85-.3-1.6-.1-.25-.25-.5 0-.05-.05-.1-.3-.6-.8-1.1-.85-.9-2-1.2 1.25-1.4.8-4.2l-1.85-3.6-9.35 5.4 1.3 2.6.05-.05v.25h.1q2.5.4 4.45.75M0 5.4l4.8 9 9.25-5.5L9.6 0 0 5.4m11.35 2.55l-5.25 3-2-4.75 5-3.15 2.25 4.9m85.1 21.4h.1v-.15-.1l.05.05 1.3-2.6-9.35-5.4-1.85 3.6q-.45 2.8.8 4.2-1.15.3-2 1.2-.5.5-.8 1.1-.05.05-.05.1-.15.25-.25.5-.3.75-.3 1.6 0 1.95 1.4 3.35 1.35 1.35 3.3 1.35 1.6 0 2.85-.95.25-.2.45-.4 1.4-1.4 1.4-3.35 0-1.9-1.4-3.3-.05-.05-.1-.05 1.95-.35 4.45-.75M104 14.4l4.8-9L99.2 0l-4.45 8.9 9.25 5.5m.7-8.2l-2 4.75-5.25-3 2.25-4.9 5 3.15z"
                    id="ShiSho074__Symbol_209_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 2.55q.9 1.2 1.85 2.35.25.3.55.2 4.7-.45 2-5.1L0 2.55M77.1 0q-2.7 4.65 2 5.1.3.1.55-.2.95-1.15 1.85-2.35L77.1 0z"
                    id="ShiSho074__Symbol_208_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho074__Symbol_210_0_Layer0_0_FILL"
                transform="translate(126.1 228.6)"
                id="ShiSho074__col1n"
            />
            <use
                xlinkHref="#ShiSho074__Symbol_207_0_Layer0_0_FILL"
                transform="translate(165.3 282.6)"
                id="ShiSho074__col1s"
            />
            <use
                xlinkHref="#ShiSho074__Symbol_209_0_Layer0_0_FILL"
                transform="translate(138.1 243.1)"
                id="ShiSho074__col2n"
            />
            <use
                xlinkHref="#ShiSho074__Symbol_208_0_Layer0_0_FILL"
                transform="translate(151.75 265.55)"
                id="ShiSho074__col2d"
            />
        </g></g>
    )
}

export default ShiSho074
