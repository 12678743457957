import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#BFBFBF",
    "col2n": "#000000"
}

function ShiMot039({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.5 2.2Q26.15.35 22 .15 21.1 0 20.15 0q-.9 0-1.85.15-4.15.2-7.5 2.05-3.95 2.1-6.2 5.05-.35.3-.45.5Q.1 13.8 0 20.6q-.15 3.15.65 6 .5 2.05 1.15 4.15Q2.85 33 3.65 34.7q.55 1.1.95 2.2-.2-.5-.45-1 .3.7.55 1.3l.2.5q.3.8.65 1.4 1.65 3.35 3.8 6.5 5.45 8.35 10.8 8.7 5.45-.35 10.8-8.7 2.2-3.15 3.85-6.5l.45-.9q.095-.202.15-.5.114-.245.45-.95.331-.707 1-2.3-.1.3-.2.55.8-1.8 1.85-4.25.65-2.1 1.2-4.15.75-2.85.65-6-.15-6.8-4.1-12.85-.2-.2-.55-.5-2.15-2.95-6.2-5.05z"
                    id="ShiMot039__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.25 0L0 .1q.1 4.25 1.45 7.8.35.8.75 1.5 1.15 2.05 2.8 3.7 4 3.95 11.4 4.9h.1l.1-.1Q18.7 5.1 1.15.1.9 0 .55 0h-.3M35.4 0q-.35 0-.6.1-17.55 5-15.5 17.8l.1.1h.15q7.35-.95 11.4-4.9 1.65-1.65 2.75-3.7.45-.7.8-1.5 1.35-3.55 1.45-7.8L35.7 0h-.3z"
                    id="ShiMot039__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot039__Symbol_43_0_Layer0_0_FILL"
                transform="translate(172.35 256.55)"
                id="ShiMot039__col1n"
            />
            <use
                xlinkHref="#ShiMot039__Symbol_44_0_Layer0_0_FILL"
                transform="translate(174.55 274.9)"
                id="ShiMot039__col2n"
            />
        </g></g>
    )
}

export default ShiMot039
