import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.2 28.65q7.184-7.733 8.85-17.55Q25.8-11.1.2 11.1q-.05.4-.1.85-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4 7.25 7.75 18.25 6.9 11.05-.8 19.4-9.75m55.7 2.85q3.75-4 5.35-9.15.75-2.4 1-5.05.3-2.6.1-5.4-.05-.4-.05-.8-28.4-22.05-52.8 0-.05 0 0-.05h-.05q1.6 9.85 8.8 17.6 8.35 8.95 19.4 9.75 11 .85 18.25-6.9z"
                    id="FacEye017__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 3.25q0 1.4 1 2.35.95 1 2.4 1.05Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35m52.4 0q0 1.4 1 2.35 1 1 2.45 1.05 1.4-.05 2.35-1.05 1-.95 1-2.35 0-1.35-1-2.35-.95-.95-2.35-.9-1.45-.05-2.45.9-1 1-1 2.35z"
                    id="FacEye017__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.251}
                    d="M217.1 136.3q-7.25-.55-12.9 2.7-2.95 1.6-5.5 4.35-3.98 4.404-5.55 10.1-.293 1.029-.5 2.1-.05.2-.05.35-.053.382-.1.75-.044-.393-.1-.8 0-.15-.05-.35-.202-1.045-.5-2.05-1.552-5.726-5.55-10.15-2.55-2.75-5.5-4.35-5.65-3.25-12.9-2.7-11.1.8-19.35 9.85-8.212 8.55-9.15 19.9l1.7-1.35q.874-9.34 8.6-17.45 7.8-8.55 18.3-9.35 6.75-.55 12 2.5h.05q2.7 1.5 5.1 4.05 3.574 3.98 5.05 9.05.363 1.169.45 1.8.144.635.7 3.05.55 2.415.6 3.25.333 3.262.4 4.4l.45.05q.019-.124.4-3.65.387-3.523.7-4.8.308-1.28.4-2.1.141-.81.5-2 1.493-5.04 5.05-9 2.4-2.55 5.1-4.05h.05q5.25-3.05 12-2.5 10.5.8 18.3 9.35 7.708 8.091 8.7 17.6l1.6 1.15q-.956-11.319-9.15-19.85-8.25-9.05-19.35-9.85z"
                    id="FacEye017__Layer6_0_FILL"
                />
            </defs>
            <g id="FacEye017__col1n" transform="translate(139.3 154.9)">
                <use
                    xlinkHref="#FacEye017__Symbol_20_0_Layer0_0_FILL"
                    id="FacEye017__col1n_FL"
                />
            </g>
            <g id="FacEye017__col2n" transform="translate(162.9 155.05)">
                <use
                    xlinkHref="#FacEye017__Symbol_22_0_Layer0_0_FILL"
                    id="FacEye017__col2n_FL"
                />
            </g>
            <use xlinkHref="#FacEye017__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye017
