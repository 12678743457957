import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#D9D9D9",
    "col2n": "#999999"
}

function ObjToy057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.75 40l3.3-34.55q-.15-1.75-1.85-1.9L8.8.05Q6.6-.35 6 1.55L0 63.8q-.1 2 2.05 2.05l29.1 2.8q1.35.1 2.55-.05 4.5-.55 6.65-4.4-.4.05-.85.05-3 0-4.6-1.8-.45-.5-.75-1.1-.35-.6-.55-1.35-.45-1.4-.45-5 0-3.35 1.75-8 .2-.5.4-.95l.05-.1v-.05q.05-.25.15-.45.95-2.45 2.55-3.6Q39.95 40 42.4 40q.2 0 .45.05.2-.05.4-.05h.5z"
                    id="ObjToy057__Symbol_135_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M0 26.65l29.1 2.8q5.4-.55 6.05-4.8l2.05-21.3L2.55 0 0 26.65z"
                    id="ObjToy057__Symbol_47_0_Layer0_0_FILL"
                />
                <g id="ObjToy057__Layer5_0_FILL">
                    <path
                        fill="#666"
                        d="M251.2 301.9q-2.55.8-2.7.9-2.55.85-3.05 1.4-.15.15-.1.3 0 .1.1.2v.15q0 .15.25.2.75.15 3.35-.7 2.5-.9 3-1.4.15-.2.1-.35-.1-.15-.1-.2-.05-.1-.25-.35l-.35-.15h-.25m-13.75 1.75q-.15.15-.1.35 0 .05.1.2v.15q0 .1.25.2.75.15 3.35-.7 2.5-.9 3-1.4.15-.2.1-.35-.1-.15-.1-.2-.05-.15-.25-.35l-.35-.15-.25-.05-2.7.9q-2.55.9-3.05 1.4z"
                    />
                    <path d="M230.95 290l-.35 3.8 3.25.35-.3 3.2 3.8.4.3-3.25 3.25.3.35-3.8-3.25-.3.3-3.25-3.8-.35-.3 3.2-3.25-.3z" />
                    <path
                        fill="#D70000"
                        d="M236.15 262.4q.45 0 .75-.3t.3-.75q0-.45-.3-.75t-.75-.3q-.45 0-.75.3t-.3.75q0 .45.3.75t.75.3z"
                    />
                    <path
                        fill="#B50F73"
                        d="M258.35 293.7q-.7-.8-1.7-.9-1.05-.1-1.85.6-.85.65-.9 1.7-.15 1 .55 1.85.65.8 1.7.9 1.05.1 1.85-.55.8-.7.9-1.7.1-1.1-.55-1.9z"
                    />
                    <path
                        fill="#787200"
                        d="M260.9 276.95l1.9-19.95-21.9-2.1-1.95 19.95 21.95 2.1z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy057__Symbol_135_0_Layer0_0_FILL"
                transform="translate(225.8 246.15)"
                id="ObjToy057__col1n"
            />
            <use
                xlinkHref="#ObjToy057__Symbol_47_0_Layer0_0_FILL"
                transform="translate(232.15 251)"
                id="ObjToy057__col2n"
            />
            <use xlinkHref="#ObjToy057__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToy057
