import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col2n": "#FFFF00"
}

function ObjMus036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.55 36Q31 24.2 34.3 9.45q1-4.3 1.7-9.45-7.5 5.4-16.85 9.1-7 19.2-19.1 25.65.3.25 0 3.1t1.6 3q1.9.05 1.4 1.95 6.1 2.95 17.5-6.8z"
                    id="ObjMus036__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M103.85 4.5q-.9-.55-1.75-1-.75-.4-1.5-.75-2.85-1.35-5.9-2.1l-.3-.1q-5.1-1.1-10 0-4.9 1-7.9 4.9-2.85 3.8-7 6.35-1.25.65-2.25.8-4.8 1.15-9.9-.5-2.4-.8-4.85-1.6-.4-.15-.75-.3-11.25-4.55-22.1-1.8L27 21.95q.15 0 .3.05.25.05.55.1 2.15.45 3.8 2.1 2.25 2.25 2.25 5.45t-2.25 5.45q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.85 4.2-4.1 7.45-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-2.5.95-5.4.95-6.25 0-10.65-4.4-1.95-1.9-3-4.15-.05.15-.1.35-.25 1.25-.4 2.5-.05.25-.05.5-.6 6.2 1.25 11.85l.3.9q1.2 3.25 3.25 6.35.8 1.25 1.8 2.5.3.35.65.7.95.95 1.95 1.85.7.65 1.5 1.3 11.85 7.7 27.35 7.85 7.25 0 14.75-1.75 3-.7 5.7-2.05 3.5-1.75 6.6-4.55 1.1-1.1 2.25-2.2 2.3-2.45 4.35-5.2 8.4-9.85 16.25-9.5.85-.05 1.75.05 1.85.3 3.7.65.55.1 1.15.2 2.15.4 4.25.95 3.05.65 5.85.15.1-.05.25-.05.05-.05.1-.05l.85-.2q3.2-1.05 3-3.65-.4-4.25-6.05-5.85-.45-.15-.95-.2h-.05q-5.4-.9-4.7-4.4l.15-.5q.25-.9.9-1.95.4-.8.9-1.55.2-.35 1.65-2.25 1.4-1.9 3.2-2.85 1.8-1.05 2.55-1.45.7-.4 1.5-1.2 2.05-2.15 2.75-5 1.45-9.2-4.65-16.1-.5-.45-1-.8-.65-.55-1.35-.95z"
                    id="ObjMus036__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill="#C6C6C6"
                    d="M302.6 259.85q-.6-.35-1.3-.4h-.1q-1.1 0-1.9.8-.3.3-.45.65-.25.4-.3.9-.05.15-.05.35 0 1.1.8 1.9.7.7 1.6.8h.3q1 0 1.8-.65.05-.1.1-.15.8-.8.8-1.9 0-1.1-.8-1.9-.25-.25-.5-.4m8.2-3.45q-1.1 0-1.9.8-.5.5-.65 1.1-.05.1-.05.15-.1.3-.1.65 0 1.1.8 1.9.7.7 1.6.8h.3q1.05 0 1.8-.7l.1-.1q.4-.4.6-.85.2-.5.2-1.05 0-1.1-.8-1.9l-.25-.25q-.75-.55-1.65-.55m8.9-2.95h-.1q-.45 0-.8.15-.55.2-1 .65l-.1.1q-.7.75-.7 1.8 0 1.1.8 1.9.7.7 1.6.8h.3q1 0 1.8-.65.05-.1.1-.15.8-.8.8-1.9 0-1.1-.8-1.9-.8-.8-1.9-.8m-7.65-20.35q-.55.2-1 .65l-.1.1q-.7.75-.7 1.8 0 1.1.8 1.9.7.7 1.6.8h.3q1 0 1.8-.65.05-.1.1-.15.8-.8.8-1.9 0-1.1-.8-1.9-.8-.8-1.9-.8h-.1q-.45 0-.8.15m-10.6 4.85q-.1.3-.1.65 0 1.1.8 1.9.7.7 1.6.8h.3q1.05 0 1.8-.7l.1-.1q.4-.4.6-.85.2-.5.2-1.05 0-1.1-.8-1.9l-.25-.25q-.75-.55-1.65-.55-1.1 0-1.9.8-.5.5-.65 1.1-.05.1-.05.15m-9.65 3.35q-.05.15-.05.35 0 1.1.8 1.9.7.7 1.6.8h.3q1 0 1.8-.65.05-.1.1-.15.8-.8.8-1.9 0-1.1-.8-1.9-.25-.25-.5-.4-.6-.35-1.3-.4h-.1q-1.1 0-1.9.8-.3.3-.45.65-.25.4-.3.9m-152.75 88.55q1 1.05 2.4 1.05t2.4-1.05q1.05-1 1.05-2.4t-1.05-2.4q-1-1-2.4-1t-2.4 1q-1 1-1 2.4t1 2.4M134.2 317q-1 1-1 2.4t1 2.4q1 1.05 2.4 1.05t2.4-1.05q1.05-1 1.05-2.4T139 317q-1-1-2.4-1t-2.4 1z"
                    id="ObjMus036__Layer4_0_FILL"
                />
                <path
                    d="M326.75 254.4q-.3-3.8-.2-6.75.05-3 .45-5.05l-10.05-8.7q-4.6 2.85-10.8 5.35-6.95 2.25-14.15 2.8-2.2 3.25-5.05 5.65-2.4 1.95-5.3 3.35l4.25 12.4q2.85-1.1 5.3-1.35 4.2-.4 7.95 1.5 4.2-2.3 8.45-4.05 7.5-3.05 15.25-4.4 2.25-.4 3.9-.75z"
                    id="ObjMus036__Layer4_1_FILL"
                />
                <path
                    fill="#666"
                    d="M193.7 284.2h-.05l-.4-1.1h.05l-.7-1.85-.45-.9h-2l4.4 13.15-.25-.7 1.7 5.05-7.9 2.8 2.25.7 8.15-2.7q-.504-1.333-1.2-3.3l-2.55-8.25v-.05h-.05l-1-2.85m-34.8 27l-.1.05 2.4.4 10.1-3.45-5.9-18.8h-2.95l1.05 3.3h.1l.35-.1-.45.15.35 1.05h-.05l.05.1.6 1.85.2.3h-.1l.4 1.25 3.3 10.2-9.45 3.35.1.35z"
                    id="ObjMus036__Layer4_3_FILL"
                />
                <path
                    fill="#666"
                    d="M140 325.95q-.6.6-.6 1.45 0 .9.6 1.45.6.6 1.45.6t1.45-.6q.6-.55.6-1.45 0-.85-.6-1.45t-1.45-.6q-.85 0-1.45.6m-3.4-4.55q.85 0 1.45-.6.6-.55.6-1.45 0-.85-.6-1.45t-1.45-.6q-.85 0-1.45.6t-.6 1.45q0 .9.6 1.45.6.6 1.45.6z"
                    id="ObjMus036__Layer4_4_FILL"
                />
                <path
                    d="M225.25 282.25l3-1-3.15-8.45-3.1 1 3.25 8.45m-10 3.55l2.15-.75-3.35-8.7-2.05.65 3.25 8.8m-9.75-6.7l-3 1 3.2 8.95 2.95-1-3.15-8.95m11.55-3.7l3.1 8.7 1.75-.65-3-8.65-1.85.6m20.9-6.8l-3.05 1 3.25 8.2 3.1-1.1-3.3-8.1m-6.75 2.2l-2.2.7 3.45 8.3 2.1-.7-3.35-8.3m-32.5 10.5l-1.75.6 3.15 9.15 2.1-.75-3.5-9m46.05-5.8l3.1-1.05-2.8-8.15-3.3 1.1 3 8.1M258 262.1l2.95 7.95 2.05-.85-3.25-7.7-1.75.6m-.05 8.95l-3.05-7.95-3.25 1.05 2.9 8.1 3.4-1.2m22.95-16.4l-3.35 1.1 2.65 7.35 3.4-1.3-2.7-7.15m-9.75 3.15l3.15 7.45 3.4-1.2-2.85-7.45-3.7 1.2m-2 9.3l-3.05-7.65-3.25 1.05 3.25 7.65 3.05-1.05z"
                    id="ObjMus036__Layer4_5_FILL"
                />
                <path
                    fill="#D9D9D9"
                    d="M161.35 304.35q-.172.085-.25.25-.084.172-.05.35.084.172.25.25.172.084.35 0l124.2-43.65-.05.05 23.55-5.45q.178-.042.25-.2.133-.163.1-.35-.042-.178-.2-.3-.163-.083-.35-.05l-23.55 5.45q-.025.011-.05 0l-124.2 43.65m-2.25-5.75q.17.084.35 0l125.1-42q.025.014.05 0l30.15-14.85q.166-.079.2-.25.084-.18 0-.35-.078-.166-.25-.25-.179-.034-.35.05l-30.1 14.8-125.1 42q-.172.084-.25.25-.084.17-.05.35.084.171.25.25m1.15 2.35q-.172.084-.25.25-.084.17-.05.35.084.172.25.25.17.084.35 0l124.65-42.75-.05.05 33.45-7.5q.178-.042.25-.2.133-.163.1-.35-.042-.178-.2-.3-.163-.083-.35-.05l-33.45 7.5q-.026.012-.05 0l-124.65 42.75M158 295.3q.17.084.35 0l125.55-41q.025.014.05 0l21.45-10.35q.165-.078.2-.25.084-.178 0-.35-.078-.166-.25-.25-.178-.034-.35.05l-21.4 10.3-125.55 41q-.171.083-.25.25-.084.17-.05.35.083.17.25.25z"
                    id="ObjMus036__Layer4_6_FILL"
                />
                <path
                    fill="#C6C6C6"
                    d="M297.4 257.55q0 .6.25 1.1.2.45.55.8.8.8 1.9.8 1.1 0 1.9-.8.55-.55.7-1.2.05-.15.05-.25.05-.25.05-.45 0-1.1-.8-1.9-.75-.8-1.8-.85h-.1q-.95 0-1.7.7-.1.05-.2.15-.8.8-.8 1.9m13.35-4.95q-.7-.7-1.6-.75-.15-.05-.3-.05-1.05 0-1.8.7l-.1.1q-.8.8-.8 1.9 0 1.05.7 1.85l.1.1q.35.35.75.55.4.2.9.25h.25q1.1 0 1.9-.8.6-.6.75-1.4.05-.3.05-.55 0-1.1-.8-1.9m9.75.25q.55-.7.55-1.65 0-1.1-.8-1.9-.7-.7-1.65-.8h-.25q-1.05 0-1.8.75l-.05.05h-.05v.05q-.8.75-.8 1.85t.8 1.9q.2.2.4.35.65.45 1.5.45 1.1 0 1.9-.8l.25-.25m-7.9-13.5q-.7.75-.7 1.8 0 1.1.8 1.9.7.7 1.6.8h.3q1 0 1.8-.65.05-.1.1-.15.8-.8.8-1.9 0-1.1-.8-1.9-.8-.8-1.9-.8h-.1q-.45 0-.8.15-.55.2-1 .65l-.1.1m-9.5 4.1q-.1.3-.1.65 0 1.1.8 1.9.7.7 1.6.8h.3q1.05 0 1.8-.7l.1-.1q.4-.4.6-.85.2-.5.2-1.05 0-1.1-.8-1.9l-.25-.25q-.75-.55-1.65-.55-1.1 0-1.9.8-.5.5-.65 1.1-.05.1-.05.15m-9.65 3.35q-.05.15-.05.35 0 1.1.8 1.9.7.7 1.6.8h.3q1 0 1.8-.65.05-.1.1-.15.8-.8.8-1.9 0-1.1-.8-1.9-.25-.25-.5-.4-.6-.35-1.3-.4h-.1q-1.1 0-1.9.8-.3.3-.45.65-.25.4-.3.9z"
                    id="ObjMus036__Layer4_7_FILL"
                />
                <path
                    id="ObjMus036__Layer4_2_1_STROKES"
                    stroke="#FFF"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M285.65 262.85l-.525-1.425"
                />
                <g id="ObjMus036__Layer4_2_FILL">
                    <path
                        fill="#666"
                        d="M308.6 257.25q-.5-.05-.9-.25v.05l.1.1q.25.1.35.1h.45m-5.5-13.8l-5.6 1.4q.071.043.15.1-.563.13-1.45.55-2.802 1.444-2.75 1.3l-6.55 5.85 2.3 7.2 8.45-1.2q3.83-.242 5.1-.65l4.1-1.65q-.047-.054-.1-.1 4.799-1.49 4.75-1.2.05-.25.1-.4 1.9-.6 3.8-1 .45-.15.9-.2h.55l-.2-.15q1.614-.144 3.85-.45l.3-.05q.05-.05.05-.1l.5-.1q.75-.1 1.3-.3-.55-2.7-.5-4.85.05-2.15.2-3.2.05-.15.2-.5l-1.9-1.3q-.35-.2-.7-.5-1.05-.95-2.45-2.75l-1.35-2-1.65 1.25h.1q-2.034 1.344-2.8 1.75.046.067.05.15-1.55.65-3.1 1.15-.15.05-.25.1-.5.15-1.05.3l.05.1-.1-.05h-.05l.05.05q-1.664.552-4.3 1.45m-4 10.7l-.2.05.15-.05h.05z"
                    />
                    <path
                        fill="#CCC"
                        d="M193.3 283.1l-.7-1.85-.45-.9h-2l-7.9 2.55 1.45 4.55h.05l.65 1.95h-.05l.3.95.05.15 1.35 4.1h.05l2 6.05 2.25.7 8.15-2.7q-.504-1.333-1.2-3.3l-.3-.95-2.25-7.3v-.05h-.05l-1-2.85h-.05l-.4-1.1h.05m-34.5 28.15l2.4.4 10.1-3.45-5.9-18.8h-2.95l-9.55 3.15 6 18.65-.1.05m-1.1-16.7l-.05-.1h.1v.1h-.05m-.65 7.35l-.15.05-.1-.05h.25z"
                    />
                    <path
                        fill="#FFF"
                        d="M283.05 255.25l-.95-2.9-89.5 28.9.7 1.85h-.05l.4 1.1h.05l1 2.85h.05v.05l2.25 7.3 88.65-31.55-.55-1.45-.35-1.05-.35-1.05-.45-1.3.05-.05-.35-1.05-.05.05-.55-1.7m-22.9 13.9l.1.15-.1.05v-.2m38.75-14.95l.2-.05h-.05l-.15.05z"
                    />
                    <path fill="#333" d="M260.25 269.3l-.1-.15v.2l.1-.05z" />
                    <path d="M307.45 241.9l-.05.05.1.05-.05-.1z" />
                    <path
                        fill="#FEFD96"
                        d="M127.85 333.3v-.05q-.1.05-.1.1l.1-.05z"
                    />
                    <path
                        fill="#73613C"
                        d="M157.65 294.45l.05.1h.05v-.1h-.1z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus036__Symbol_120_0_Layer0_0_FILL"
                transform="translate(210.95 232.7)"
                id="ObjMus036__col1n"
            />
            <use
                xlinkHref="#ObjMus036__Symbol_46_0_Layer0_0_FILL"
                transform="translate(105.8 261.95)"
                id="ObjMus036__col2n"
            />
            <use xlinkHref="#ObjMus036__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus036__Layer4_1_FILL" />
            <g>
                <use xlinkHref="#ObjMus036__Layer4_2_FILL" />
                <use xlinkHref="#ObjMus036__Layer4_2_1_STROKES" />
            </g>
            <use xlinkHref="#ObjMus036__Layer4_3_FILL" />
            <use xlinkHref="#ObjMus036__Layer4_4_FILL" />
            <use xlinkHref="#ObjMus036__Layer4_5_FILL" />
            <use xlinkHref="#ObjMus036__Layer4_6_FILL" />
            <use xlinkHref="#ObjMus036__Layer4_7_FILL" />
        </g></g>
    )
}

export default ObjMus036
