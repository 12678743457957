import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#AC8268",
    "col1n": "#C0A594",
    "col2d": "#4D2E1B",
    "col2n": "#794D32"
}

function BodTai009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M98.35 27q4.35-6.45 7.55-13.3.15-.25.25-.5.2-.4.35-.8.2-.5.4-.95L80.85.4l.1-.4Q70.6 18.9 46.55 32.5 26.5 41.75 0 42.35q.3 4.05 2.55 7.25.6.9 1.35 1.7.55.65 1.2 1.25 10.05 10.65 24.55 12 5.6.3 12.1-.2 18.15-1.7 32.75-8.85 19.8-10.35 27.1-28.6-1.05.05-1.7.1-2.05.05-1.55 0z"
                    id="BodTai009__Symbol_33_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M106.15 1.75q.2-.4.35-.8.2-.5.4-.95l-6.55.65L99.7.5h-.1q-.15.5-.35 1v.05q-1.65 4.1-7.75 13.6-1 1.5-1.75 2.6.9.15 2.05.25 2.55.2 4.1.1-7.6 14.7-24.7 22.35Q66 42.8 59.45 45.1q-3 1.1-6.05 1.95-11.55 3.15-24.2 2.1-2.65-.25-5.35-.65Q6.5 45.45 0 30.9q.3 4.05 2.55 7.25.6.9 1.35 1.7.55.65 1.2 1.25 10.05 10.65 24.55 12 5.6.3 12.1-.2 18.15-1.7 32.75-8.85 19.8-10.35 27.1-28.6-1.05.05-1.7.1-2.05.05-1.55 0 4.35-6.45 7.55-13.3.15-.25.25-.5z"
                    id="BodTai009__Symbol_25_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.35 9.15q-1.55 6.2-3.9 12.55-1.9 6.2-3.9 10.15-.65 1.3-1.3 2.55l-1.2-3.9Q2.45 34.85.2 38.95q-1.3 3.85 7.05 7.85 1 .5 2.15 1 .2.05.4.15 3.7 1.55 8.15 2.7.45.1.9.25h.1l.65.15q4.95 1 6.55-.65 1.35-3.4 2.05-6.9.9-4.4.9-8.9v-1.05q-.25-6.75-2.45-13.75Q23.55 9.85 16.5 0q-.2.75-.4 1.8-.35 1.3-.65 2.8-.5 2.25-1.1 4.55z"
                    id="BodTai009__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M10.15 19.8Q7.05 9.85 0 0q6 13.35 4.75 35.8-.05 2.45-.35 5-.05.3-.05.6-.35 2.5-.85 5.1-.4 2.1-1.05 4.2-.05.05-.1.2h.1l.65.15q4.95 1 6.55-.65Q11 47 11.7 43.5q.9-4.4.9-8.9v-1.05q-.25-6.75-2.45-13.75z"
                    id="BodTai009__Symbol_15_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai009__Symbol_33_0_Layer0_0_FILL"
                transform="translate(219.3 252.3)"
                id="BodTai009__col1n"
            />
            <use
                xlinkHref="#BodTai009__Symbol_25_0_Layer0_0_FILL"
                transform="translate(219.3 263.75)"
                id="BodTai009__col1d"
            />
            <use
                xlinkHref="#BodTai009__Symbol_20_0_Layer0_0_FILL"
                transform="translate(300.05 213.35)"
                id="BodTai009__col2n"
            />
            <use
                xlinkHref="#BodTai009__Symbol_15_0_Layer0_0_FILL"
                transform="translate(316.55 213.35)"
                id="BodTai009__col2d"
            />
        </g></g>
    )
}

export default BodTai009
