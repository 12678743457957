import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#416495"
}

function SkiTat004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.2 12q-.1-.5.3-.9.5-.5 1.2-.6.7-.15 1.15-.55.65-.7.6-3.45-1.15 2.4-2.15 2.45-2 .15-2.5.65t-.5 1.45q0 .9-.6 1.55-.9.85-1.55.85-.25 0-1.1-.35-1.25-.45-2.9 1.55-1.1-.65-2.35-.85-.55-1.75.4-2.85 1.1-1.3 1.15-2.4.05-1.1-.2-1.55-.2-.4-.8-.85-.4-.3-.4-.8 0-.75.4-1.3.4-.6.4-1.2 0-.95-2-2.85.9 2.5.25 3.25-1.35 1.5-1.35 2.2 0 .75.7 1.4.65.6.65 1.55 0 1.2-.5 1.7-.15.1-1 .55-1.2.55-.95 3.25-1.25.25-2.3 1-1.9-.85-2-2.4-.15-1.75-.9-2.5-.75-.85-1.25-1-.45-.1-1.15 0-.5.1-.9-.3-.5-.5-.6-1.2-.15-.7-.55-1.1-.7-.7-3.5-.65 2.45 1.1 2.55 2.15.1 2 .6 2.5t1.45.5q.9 0 1.55.65.85.85.85 1.5 0 .2-.35 1.1-.5 1.3 1.9 3.2-.65 1-.9 2.2-1.9.7-3.05-.3-1.35-1.15-2.4-1.15-1.1-.05-1.6.2-.4.2-.8.8-.3.4-.85.4-.75 0-1.25-.4-.6-.4-1.2-.4-.95 0-2.9 2 2.5-.95 3.3-.25 1.5 1.35 2.2 1.35.7-.05 1.35-.7.65-.65 1.6-.65 1.2 0 1.65.5.15.15.55 1 .55 1.25 3.45.95.3 1.25 1.05 2.35-.85 1.5-2.2 1.65-1.75.1-2.5.85-.85.75-1 1.3-.1.4 0 1.1.05.5-.3.9-.55.5-1.2.6-.7.1-1.1.55-.7.7-.65 3.5 1.1-2.45 2.15-2.5 2-.15 2.5-.65t.5-1.4q0-.95.65-1.6.85-.85 1.5-.85.2 0 1.1.35 1.25.45 2.95-1.55 1.1.7 2.45.9.45 1.6-.45 2.65-1.15 1.3-1.15 2.4-.05 1.05.2 1.55.2.4.8.8.4.3.4.85 0 .75-.4 1.3T19 39.1q0 .95 2.05 2.9-.95-2.5-.3-3.25 1.35-1.55 1.35-2.2 0-.75-.65-1.4-.7-.65-.7-1.55 0-1.25.5-1.7.15-.15 1-.55 1.15-.55 1-3.05 1.3-.35 2.45-1.2 1.55.8 1.65 2.25.15 1.7.9 2.55.7.8 1.25.95.4.1 1.15 0 .5-.1.9.3.5.5.6 1.2.1.7.55 1.1.65.7 3.45.65-2.45-1.15-2.5-2.15-.15-2.05-.6-2.5-.55-.55-1.45-.5-.95 0-1.6-.65-.85-.85-.85-1.5 0-.25.35-1.1.45-1.3-1.65-3 .65-1.2.85-2.5 1.7-.55 2.8.4 1.3 1.1 2.4 1.15 1.1.05 1.55-.2.4-.25.85-.8.25-.45.85-.4.7-.05 1.25.4.55.4 1.2.4.95-.05 2.9-2.05-2.55.95-3.3.3-1.5-1.35-2.2-1.35-.75 0-1.4.65-.65.65-1.55.7-1.2 0-1.7-.5-.15-.15-.5-1-.6-1.25-3.35-1-.3-1.1-1-2 .85-1.55 2.25-1.65 1.7-.15 2.5-.85.8-.75.95-1.25.1-.45 0-1.15m-8.8 4.9q.25.2.5.45 1.5 1.5 1.55 3.65-.05 2.2-1.55 3.75-1.55 1.5-3.7 1.5-1.15 0-2.05-.4L18 25.2l-.45-.45Q16 23.2 16 21.05q0-2.15 1.55-3.7 1.5-1.55 3.7-1.5 1.1-.05 2.05.4l.6.3.5.35z"
                    id="SkiTat004__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiTat004__Symbol_4_0_Layer0_0_FILL"
                transform="translate(118.55 235.6)"
                id="SkiTat004__col1n"
            />
        </g></g>
    )
}

export default SkiTat004
