import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#0F1900",
    "col1n": "#2D4A00",
    "col2d": "#AC8268",
    "col2n": "#C0A594"
}
function BodHan026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M33.75 6.9q-.25-.4-.45-.75-1.5-2.2-3.4-3.6-1.36-1.049-2.55-1.5-3-1.25-6.5-1-.75.05-1.5.15-3.3.45-5.95 2.25-3.5 2.45-4.15 6-.1.1-.15.3l-.2.5-.1.2q-.75 1.35-1.9 2.25-.7.6-1.5 1.3-2 1.75-3.1 3.3l-.05.05Q-.15 19.3 0 21.7q.05 1 .5 2.2l.1.2q3.95 6.5 11.45 5.7.55-.15 1.05-.35.8-.35 1.55-1.15.35-.3.65-.75.45-.6.65-1.35.2-.7.25-1.45V23.7q-.05-.1-.05-.25-.05-.1 0-.2l-.1-.5v-.55q.1-.65.55-.85.1-.05.25-.05.55-.1.95.15.55.5.5 1.2v.05q-.05.05-.05.1-.15.15-.2.35l-.1.2q-.25.6-.35 1.35-.25 1.55.3 3.45 1.7 2.95 4.85 4.3 6.75 2.05 9.4-1.65.7-1.6.45-3.15-.5-2.8.05-5.85.6-3.05 1.05-4.6-.4.75.5-1.25.35-.75.55-1.5v-.1q.05-.3.15-.55.2-1 .15-1.9-.2-3-1.1-4.65-.1-.2-.2-.35M11 28.65q.05 0 .1.05-.1 0-.15-.05H11M186 16.3q-1.1-1.55-3.1-3.3-.8-.7-1.5-1.3-1.15-.9-1.9-2.25l-.1-.2-.2-.5q-.05-.2-.15-.3-.65-3.55-4.15-6-2.65-1.8-5.95-2.25-.75-.1-1.5-.15-3.5-.25-6.5 1-1.45.55-2.55 1.5-1.9 1.4-3.4 3.6-.2.35-.45.75-.1.15-.2.35-.9 1.65-1.1 4.65-.05.9.15 1.9.1.25.15.55v.1q.2.75.55 1.5.9 2 .5 1.25.45 1.55 1.05 4.6.55 3.05.05 5.85-.25 1.55.45 3.15 2.65 3.7 9.4 1.65 3.15-1.35 4.85-4.3.55-1.9.3-3.45-.1-.75-.35-1.35l-.1-.2q-.05-.2-.2-.35 0-.05-.05-.1v-.05q-.05-.7.5-1.2.4-.25.95-.15.15 0 .25.05.45.2.55.85v.55l-.1.5q.05.1 0 .2 0 .15-.05.25v1.05q.05.75.25 1.45.2.75.65 1.35.3.45.65.75.75.8 1.55 1.15.5.2 1.05.35 7.5.8 11.45-5.7l.1-.2q.45-1.2.5-2.2.15-2.4-2.25-5.35l-.05-.05m-8.7 12.35h.05q-.05.05-.15.05.05-.05.1-.05z"
                    id="BodHan026__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M11.25 4.6q-.2-.25-.5-.8 0-.05-.05-.05-.15-.2-.2-.2l-1.35-1.4Q8.35 1.3 7.5.85 7.4.75 6.4.3 6.05.15 5.8.15 5.65.1 5.55.1 5-.05 4.5.1 2.1.65 1 2.8q-.15.35-.3.85 0 .05-.05.1L.6 3.8.25 4.85q-.05.1 0 .25-.5 1.6-.05 3.35.6 2.75 1.95 4.3.75 1.1 1.55 1.8 1.55 1.7 2.8 1.1.05-.05.05-.15.35-.25.5-.65.15-.45.25-.65-.1-.2-.05-.4.15-.95.45-1.6.1-1 1.9-3.2.3-.35.8-.95.85-1 1-1.35.35-.5.2-1.2-.15-.45-.35-.9m11.35.2q-.5-.1-1 .1-2.4.65-3.3 2.9-.15.4-.3.85 0 .05-.05.1l-.05.05-.2 1.1q-.05.1-.05.25-.35 1.6.15 3.3.8 2.75 2.2 4.25.85.95 1.65 1.6 1.7 1.6 2.8.85l.1-.1q.35-.25.45-.6.15-.5.2-.75-.1-.15 0-.4.05-.85.25-1.5.15-1 1.75-3.25.25-.45.7-1.05.8-1.05.95-1.4.25-.6.05-1.3-.1-.45-.35-.8-.2-.25-.6-.85 0-.05-.05-.05-.1-.1-.15-.1l-1.4-1.3q-.9-.85-1.75-1.25-.1-.1-1.15-.45-.35-.15-.6-.15-.15-.05-.25-.05m142.4.1q-.5-.2-1-.1-.1 0-.25.05-.25 0-.6.15-1.05.35-1.15.45-.85.4-1.75 1.25l-1.4 1.3q-.05 0-.15.1-.05 0-.05.05-.4.6-.6.85-.25.35-.35.8-.2.7.05 1.3.15.35.95 1.4.45.6.7 1.05 1.6 2.25 1.75 3.25.2.65.25 1.5.1.25 0 .4.05.25.2.75.1.35.45.6l.1.1q1.1.75 2.8-.85.8-.65 1.65-1.6 1.4-1.5 2.2-4.25.5-1.7.15-3.3 0-.15-.05-.25l-.2-1.1-.05-.05q-.05-.05-.05-.1-.15-.45-.3-.85-.9-2.25-3.3-2.9m21.4 3.55q.45-1.75-.05-3.35.05-.15 0-.25L186 3.8l-.05-.05q-.05-.05-.05-.1-.15-.5-.3-.85-1.1-2.15-3.5-2.7-.5-.15-1.05 0-.1 0-.25.05-.25 0-.6.15-1 .45-1.1.55-.85.45-1.65 1.3l-1.35 1.4q-.05 0-.2.2-.05 0-.05.05-.3.55-.5.8-.2.45-.35.9-.15.7.2 1.2.15.35 1 1.35.5.6.8.95 1.8 2.2 1.9 3.2.3.65.45 1.6.05.2-.05.4.1.2.25.65.15.4.5.65 0 .1.05.15 1.25.6 2.8-1.1.8-.7 1.55-1.8 1.35-1.55 1.95-4.3z"
                    id="BodHan026__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M5.3 1.2Q5.15 1 5.1 1l-.95-1v.05q.35 1.9-.8 3.3-1.2 1.4-1.7 2.05Q1.2 6.15.5 7.9q-.7 1.65-.4 2.8.15.75.5 1.4.25.6.55.85.35-.25.5-.65.15-.45.25-.65-.1-.2-.05-.4.15-.95.45-1.6.1-1 1.9-3.2.3-.35.8-.95.85-1 1-1.35.35-.5.2-1.2-.15-.45-.35-.9-.2-.25-.5-.8 0-.05-.05-.05m16 3.3v.05q.5 1.85-.55 3.35-1.1 1.45-1.5 2.2-.45.7-1 2.5-.6 1.7-.2 2.8.15.65.55 1.3.3.6.55.8.35-.25.45-.6.15-.5.2-.75-.1-.15 0-.4.05-.85.25-1.5.15-1 1.75-3.25.25-.45.7-1.05.8-1.05.95-1.4.25-.6.05-1.3-.1-.45-.35-.8-.2-.25-.6-.85 0-.05-.05-.05-.1-.1-.15-.1L21.3 4.5m132 1.05q-.05 0-.05.05-.4.6-.6.85-.25.35-.35.8-.2.7.05 1.3.15.35.95 1.4.45.6.7 1.05 1.6 2.25 1.75 3.25.2.65.25 1.5.1.25 0 .4.05.25.2.75.1.35.45.6.25-.2.55-.8.4-.65.55-1.3.4-1.1-.2-2.8-.55-1.8-1-2.5-.4-.75-1.5-2.2-1.05-1.5-.55-3.35V4.5l-1.05.95q-.05 0-.15.1m20.2 4.1q.3.65.45 1.6.05.2-.05.4.1.2.25.65.15.4.5.65.3-.25.55-.85.35-.65.5-1.4.3-1.15-.4-2.8-.7-1.75-1.15-2.5-.5-.65-1.7-2.05-1.15-1.4-.8-3.3V0l-.95 1q-.05 0-.2.2-.05 0-.05.05-.3.55-.5.8-.2.45-.35.9-.15.7.2 1.2.15.35 1 1.35.5.6.8.95 1.8 2.2 1.9 3.2z"
                    id="BodHan026__Symbol_56_0_Layer0_0_FILL"
                />
                <g
                    id="BodHan026__Symbol_58_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M2.9 12.65q-.2.15-.35.3Q.75 14.5.35 16.1q-.4 1.5-.35 2.65 0 .25.05.55.1-.65.55-.85.1-.05.25-.05.55-.1.95.15.545.495.5 1.15l.05-.1q.7-1.15 1.95-2.1 2.2-3.05 1.6-4.7-1-1.5-3-.15M17.75 4q-.25-.4-.45-.75Q15.95 1.3 14.35 0q.1.15.2.4.45.8 1.8 3.25 1.35 2.4 1.35 4.75 0 2.3-1.25 5.55T14.85 20q-.346 2.619-.1 4.95V25l.05.25q.1.85.1 1.95 0 1.55-1 2.5l-.2.15q1.5-.6 2.45-1.95.7-1.6.45-3.15-.5-2.8.05-5.85.6-3.05 1.35-5.35l.2-.5q.35-.75.55-1.5v-.1q.05-.3.15-.55.2-1 .15-1.9-.2-3-1.1-4.65-.1-.2-.2-.35m136 8.95q-.15-.15-.35-.3-2-1.35-3 .15-.6 1.65 1.6 4.7 1.25.95 1.95 2.1l.05.1q-.044-.655.5-1.15.4-.25.95-.15.15 0 .25.05.45.2.55.85.05-.3.05-.55.05-1.15-.35-2.65-.4-1.6-2.2-3.15M141.75.4q.1-.25.2-.4-1.65 1.3-2.95 3.25-.2.35-.45.75-.1.15-.2.35-.9 1.65-1.1 4.65-.05.9.15 1.9.1.25.15.55v.1q.2.75.55 1.5l.2.5q.5 1.5.95 3.3l.4 2.05q.55 3.05.05 5.85-.25 1.55.45 3.15l1.05 1.15q.65.5 1.4.8l-.2-.15q-1-.95-1-2.5 0-1.1.1-1.95l.05-.25q.25-2.35-.1-5-.35-2.8-1.6-6.05T138.6 8.4q0-2.35 1.35-4.75 1.35-2.45 1.8-3.25z" />
                    <path d="M14.6 24.25q-.05-.25-.15-.45l.35 1.45-.05-.25v-.05q-.056-.419-.15-.7z" />
                </g>
            </defs>
            <use
                xlinkHref="#BodHan026__Symbol_53_0_Layer0_0_FILL"
                transform="translate(98.35 280.65)"
                id="BodHan026__col1n"
            />
            <use
                xlinkHref="#BodHan026__Symbol_58_0_Layer0_0_FILL"
                transform="translate(114.35 283.55)"
                id="BodHan026__col1d"
            />
            <use
                xlinkHref="#BodHan026__Symbol_55_0_Layer0_0_FILL"
                transform="translate(99.2 301)"
                id="BodHan026__col2n"
            />
            <use
                xlinkHref="#BodHan026__Symbol_56_0_Layer0_0_FILL"
                transform="translate(104.6 303.55)"
                id="BodHan026__col2d"
            />
        </g></g>
    )
}
export default BodHan026
