import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF",
    "col2d": "#434343",
    "col2n": "#666666"
}

function ObjMor033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M63.5 70.95q-.1-.05-.2-.05v-4.5H59v4.55h-.1q-1.3.45-2.4 1.55-1.95 1.95-1.95 4.7t1.95 4.65q1.95 1.95 4.7 1.95t4.65-1.95q1.95-1.9 1.95-4.65 0-2.75-1.95-4.7-1.05-1.1-2.35-1.55M53.1 42.3q.1 0 .15.05 4.3-1.35 0-3.05-20.1-2.2-35.1-15.6Q7 13.65 2.95 1.05 2.75.45 2.2.2 1.65-.1 1.05.1.45.25.2.85q-.3.5-.1 1.1 4.3 13.4 16.05 24Q31.9 40.05 53.1 42.3M122.3 2q.15-.6-.1-1.15-.3-.6-.85-.75-.6-.2-1.15.1-.6.25-.75.85-4.15 12.6-15.35 22.65-14.95 13.45-35.05 15.6-3.35 1.7 0 3.05.1-.05.15-.05 21.2-2.2 36.9-16.35Q117.9 15.35 122.3 2z"
                    id="ObjMor033__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M7.2 1.65l.05.05V0h-4.3v1.75q.95-.35 2-.4h.2q.85 0 1.65.25h.1q.15 0 .3.05m1.45 2.7q-1.5-1.5-3.55-1.5t-3.6 1.5Q0 5.9 0 7.95q0 2.1 1.5 3.6.6.6 1.25.95 1.05.55 2.35.55 1 0 1.9-.35.9-.4 1.65-1.15 1.55-1.5 1.55-3.6 0-2.05-1.55-3.6M5.1 3.9q1.65 0 2.8 1.2 1.2 1.2 1.2 2.85 0 .8-.25 1.5-.3.7-.95 1.35-1.15 1.15-2.8 1.15T2.25 10.8q-.8-.8-1-1.8-.2-.5-.2-1.05 0-1.65 1.2-2.85 1.2-1.2 2.85-1.2z"
                    id="ObjMor033__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.25 19.25L3.1 2.05q-1.5-4.1-3 0L0 19.25h3.25z"
                    id="ObjMor033__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M10.3 10.7V5.2h.1q2.65 0 5.25-.2.05 0 .15-.05V0q-2.6.2-5.25.2h-.25Q9.1.2 7.9.15 6.7.2 5.5.2h-.25Q2.6.2 0 0v4.95Q.1 5 .15 5q2.6.2 5.25.2h.1v5.5h4.8z"
                    id="ObjMor033__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor033__Symbol_141_0_Layer0_0_FILL"
                transform="translate(131.35 227.7)"
                id="ObjMor033__col1n"
            />
            <use
                xlinkHref="#ObjMor033__Symbol_79_0_Layer0_0_FILL"
                transform="translate(187.4 296.9)"
                id="ObjMor033__col1d"
            />
            <use
                xlinkHref="#ObjMor033__Symbol_41_0_Layer0_0_FILL"
                transform="translate(190.9 274.85)"
                id="ObjMor033__col2n"
            />
            <use
                xlinkHref="#ObjMor033__Symbol_11_0_Layer0_0_FILL"
                transform="translate(184.6 266.2)"
                id="ObjMor033__col2d"
            />
        </g></g>
    )
}

export default ObjMor033
