import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function FacEye014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M52.4 18.6q.4-1.45.65-2.95L8.5 1.4q-.7-.25-1.35-.5Q6.35.65 5.6.35 2.8 4.5 1.4 9.15.35 12.7.1 16.55q-.2 2.7.05 5.15.25 3.15 1.25 6 1.65 4.65 5.15 8.4Q13.8 43.85 24.8 43q11.05-.8 19.4-9.75 6.1-6.6 8.2-14.65m53.85 3.3q.3-2.6.1-5.4-.2-3.2-1-6.2-1.2-4.7-3.8-8.85Q101.1.7 100.6 0l-1.3.5q-.3.1-.55.25L53.5 15.7q.2 1.45.6 2.85 2.05 8.1 8.15 14.7Q70.6 42.2 81.65 43q11 .85 18.25-6.9 3.75-4 5.35-9.15.75-2.4 1-5.05z"
                    id="FacEye014__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M3.4 6.65Q4.8 6.6 5.8 5.6q1-.95 1-2.35 0-1.35-1-2.35-1-.95-2.4-.9Q1.95-.05 1 .9q-1 1-1 2.35 0 1.4 1 2.35.95 1 2.4 1.05m32.05 0q1.4-.05 2.35-1.05 1-.95 1-2.35 0-1.35-1-2.35-.95-.95-2.35-.9Q34-.05 33 .9q-1 1-1 2.35 0 1.4 1 2.35 1 1 2.45 1.05z"
                    id="FacEye014__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.251}
                    d="M217 137.9q10.5.8 18.3 9.35 1.743 1.83 3.15 3.8l1.45-.75q-1.524-2.145-3.45-4.15-8.25-9.05-19.35-9.85-7.25-.55-12.9 2.7-2.95 1.6-5.5 4.35-3.98 4.404-5.55 10.1-.293 1.029-.5 2.1-.05.2-.05.35-.053.382-.1.75-.044-.393-.1-.8 0-.15-.05-.35-.202-1.045-.5-2.05-1.552-5.726-5.55-10.15-2.55-2.75-5.5-4.35-5.65-3.25-12.9-2.7-11.1.8-19.35 9.85-2.06 2.145-3.65 4.45l1.45.7q1.496-2.103 3.35-4.05 7.8-8.55 18.3-9.35 6.75-.55 12 2.5h.05q2.7 1.5 5.1 4.05 3.574 3.98 5.05 9.05.363 1.169.45 1.8.144.635.7 3.05.55 2.415.6 3.25.333 3.262.4 4.4l.45.05q.019-.124.4-3.65.387-3.523.7-4.8.308-1.28.4-2.1.141-.81.5-2 1.493-5.04 5.05-9 2.4-2.55 5.1-4.05h.05q5.25-3.05 12-2.5z"
                    id="FacEye014__Layer6_0_FILL"
                />
            </defs>
            <g id="FacEye014__col1n" transform="translate(139.3 150.3)">
                <use
                    xlinkHref="#FacEye014__Symbol_17_0_Layer0_0_FILL"
                    id="FacEye014__col1n_FL"
                />
            </g>
            <g id="FacEye014__col2n" transform="translate(173 163.45)">
                <use
                    xlinkHref="#FacEye014__Symbol_4_0_Layer0_0_FILL"
                    id="FacEye014__col2n_FL"
                />
            </g>
            <use xlinkHref="#FacEye014__Layer6_0_FILL" />
        </g></g>
    )
}

export default FacEye014
