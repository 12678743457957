import { AssetSvgProps } from "../../../shared/assets"

function PlaInd035({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#96BCD2"
                    d="M268.95 70V41h-104v53H51.45V60.5H0V118h313.95V70h-45z"
                    id="PlaInd035__Layer4_1_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.4}
                    d="M20.1 117.85h55.45L110.15 0h-53.6L20.1 117.85M47 0H36.5L.65 117.85h9.45L47 0m82.4 0h-10.45L86.1 117.85h9.4L129.4 0m63.55 1.1H182.5l-30.55 118.1h9.45L192.95 1.1M255.7 0h-10.35L217 117.85h9.45L255.7 0m54.55 40.1L306.45 0H265.9l-28.95 117.85h55.45l17.85-77.75M336.9 0h-10.5l-25.35 117.85h9.45L336.9 0z"
                    id="PlaInd035__Layer4_2_FILL"
                />
                <path
                    fill="#B1ECD7"
                    fillOpacity={0.749}
                    d="M385 385V243.1q-22.296-50.458-68.05-86.1-56.857-5.72-114.65-7.25Q102.528 147.121 0 157v228h385z"
                    id="PlaInd035__Layer4_3_FILL"
                />
                <path
                    fill="#6CC2A3"
                    d="M385 243.1V0h-68.05q-3.797 60.844 0 120-4.703 19.676 0 37L385 243.1z"
                    id="PlaInd035__Layer3_0_FILL"
                />
                <path
                    fill="#56A689"
                    d="M316.95 157v-37L0 122.5V157h316.95z"
                    id="PlaInd035__Layer3_1_FILL"
                />
                <path
                    fill="#728291"
                    d="M316.95 0h-5.55v117.2H195.2V0h-5.95v117.2H73.6V0h-5.55v117.2H-.2v5.3h317.15V0z"
                    id="PlaInd035__Layer3_2_FILL"
                />
                <path
                    fill="#00AFAF"
                    d="M355.95 375.8v-3Q179.4 362.15 0 372.8v3h355.95z"
                    id="PlaInd035__Layer2_0_MEMBER_0_FILL"
                />
                <path
                    fill="#00DEDE"
                    d="M0 297.2v75.6h355.95L305 297.2H0z"
                    id="PlaInd035__Layer2_0_MEMBER_1_FILL"
                />
                <path
                    fill="#999"
                    d="M249.8 215.55V199.7q-1-3.75-3.9 0v15.85q2.35 1.2 3.9 0m-18.15-20.45q2 3.65 3.95 0v-5.85q-1.95-6.65-3.95-5.85v11.7m125.05-14.25l-3.75-4.85-.05 43.95-4.4-5.5V199.7q-1.3-2.8-4.05 0v9.75l-7.75-9.75q-4.85-3.55-4.75 0l12.75 16.4-.4-.55 7.15 9.2h.1l3.45 4.35q3.2 2.15 5.6 0l-3.9-4.7v-43.55z"
                    id="PlaInd035__Layer2_1_FILL"
                />
                <path
                    fill="#434343"
                    d="M350.75 195.95q-.3-.25-.6-.35-.4-.2-.95-.2-53.494-2.551-100.1 0-.703-3.908-19.55-18.05-.1.2-.05.55v2.8l13 19.2h108.85v-2.25q-.05-.9-.4-1.45-.1-.15-.2-.25z"
                    id="PlaInd035__Layer2_1_MEMBER_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M327.9 141.1q-.35-.35-.8-.35-.45 0-.8.35-.25.3-.25.75t.25.75l.2.2q.05 0 .1.15v.05l.95 1.1v-.5q1.757 2.447 2.9 3.95v.15l31.65 40.5v-4.4l-34.2-42.75v.05z"
                    id="PlaInd035__Layer2_1_MEMBER_2_FILL"
                />
                <path
                    d="M243.6 215.75v4.5h8.1v-4.5h-8.1m-6.15-20.45h-8.1v4.5h8.1v-4.5m116.3 34v4.5h8.35v-4.5h-8.35z"
                    id="PlaInd035__Layer2_1_MEMBER_3_FILL"
                />
                <path
                    fill="#999"
                    d="M330.45 147.7v-2.35l-1.95-2.4q-.9-.15-.95.65v3.8l.8.9v27.65q2.362 6.481 3.2 0V149.1l-1.1-1.4z"
                    id="PlaInd035__Layer2_1_MEMBER_4_FILL"
                />
                <path
                    d="M349.2 195.4q.55 0 .95.2.3.1.6.35l-13.95-18.4q-1.2-1.75-3.45-1.6H231.9q-2.05.25-2.35 1.4l13.6 19q.65-.8 2-.95H349.2z"
                    id="PlaInd035__Layer2_1_MEMBER_5_FILL"
                />
                <path
                    fill="#666"
                    d="M287.15 144.25q0 1 .4 1.8l2.8 4.6q-.1.05-.15.1-3.05 3.05-3.05 7.4 0 3.25 1.7 5.75h-.05q.15.2.35.35.15.15.3.2 1.1.7 2.65 1.1 5.9 1.3 11.75 0 1.1-.4 2-1.1.1-.05.2-.2l.25-.25v.05q1.75-2.6 1.75-5.9 0-4.35-3.05-7.4l-.2-.2 2.75-4.5q.35-.8.35-1.8 0-1.75-1.15-2.95-.55-.45-1.15-.75-7.9-2.95-16.1 0-.65.3-1.15.75-1.2 1.2-1.2 2.95m3.5 1.75q-1.25-3.15 2.9-3.85 1.8-.3 3.65-.35h.65q1.9.05 3.75.35h.1q3.9.8 2.75 3.85l-1.65 3.05q-2.35-1.35-5.2-1.35-2.95 0-5.25 1.4l-1.7-3.1z"
                    id="PlaInd035__Layer2_2_MEMBER_0_FILL"
                />
                <path
                    fill="#666"
                    d="M260.95 145.3q0 .9.35 1.6l2.45 4q-.1.05-.15.1-2.65 2.65-2.65 6.5 0 2.8 1.45 5h-.05q.15.2.35.3.1.15.25.2.95.6 2.3.95 5.2 1.15 10.3 0 .95-.35 1.75-.95.1-.05.2-.2l.2-.2v.05q1.55-2.3 1.55-5.15 0-3.85-2.7-6.5-.1-.1-.15-.2l2.4-3.9q.3-.7.3-1.6 0-1.55-1-2.6-.5-.35-1-.65-6.95-2.55-14.1 0-.6.3-1 .65-1.05 1.05-1.05 2.6m5.6-1.85q1.55-.25 3.2-.3h.55q1.65.05 3.3.3h.05q3.45.7 2.4 3.4l-1.4 2.65q-2.1-1.15-4.55-1.15-2.6 0-4.6 1.2l-1.5-2.7q-1.1-2.75 2.55-3.4z"
                    id="PlaInd035__Layer2_2_MEMBER_1_FILL"
                />
                <path
                    fill="#666"
                    d="M285.1 145.7q-.85.85-.85 2.05 0 .75.25 1.3l2 3.2q-.1.05-.15.05-2.1 2.15-2.1 5.2 0 2.25 1.15 4h-.05q.15.15.3.25.05.1.2.15.75.45 1.8.75 4.15.9 8.25 0 .75-.3 1.4-.75l.15-.15.15-.15q1.25-1.8 1.25-4.1 0-3.05-2.15-5.2-.1-.05-.15-.15l1.95-3.1q.2-.55.2-1.3 0-1.2-.8-2.05-.35-.3-.75-.5-5.55-2.05-11.25 0-.5.2-.8.5m6.15.35H291.7q1.3.05 2.65.25 2.8.55 1.95 2.7l-1.15 2.1q-1.65-.9-3.6-.9-2.1 0-3.7.95l-1.2-2.15q-.85-2.2 2.05-2.7 1.25-.2 2.55-.25z"
                    id="PlaInd035__Layer2_2_MEMBER_2_FILL"
                />
                <path
                    fill="#999"
                    d="M109.05 148.35H90l25.65 11.95-6.6-11.95M54.2 140.1q-1.35-1.35-2.7-2.45-5.2-4-11.65-5.1l4.5-1.45-13.65-5H19.55l-9.8 18 3.2-.5q-4.85 6.75-4.85 15.6 0 11.15 7.9 19.1 1.65 1.65 3.5 2.95l-9.25 9h6l3.95-3.85q1.05-1 2-2l1-.9q.05 0 .1.05 5.3 2.65 11.8 2.65 11.2 0 19.1-7.9 7.95-7.95 7.95-19.1 0-11.2-7.95-19.1z"
                    id="PlaInd035__Symbol_1_0_Layer0_1_FILL"
                />
                <path
                    fill="#666"
                    d="M35.1 129.65q-12.15 0-20.7 8.6-8.6 8.6-8.6 20.7 0 8.7 4.45 15.55 1.75 2.75 4.15 5.15 1.7 1.65 3.5 3 1.25.9 2.6 1.7.45.3 1.05.65 1.45.75 2.95 1.4 4.7 1.7 10.05 1.8h66.7q7.15-.15 12.3-5.25 5.25-5.35 5.25-12.8 0-7.5-5.25-12.8-4-3.95-8.85-5.6L46 131.65q-5.05-2-10.9-2m-16.95 12.6q7-6.95 16.8-6.95 9.85 0 16.85 6.95 6.95 7 6.95 16.85 0 9.85-6.95 16.8-7 7-16.85 7-3.25 0-6.25-.8-1.6-.4-3.15-1.1-.6-.2-1.2-.55-1.4-.7-2.65-1.6-1.9-1.25-3.55-2.95-6.95-6.95-6.95-16.8 0-9.85 6.95-16.85z"
                    id="PlaInd035__Symbol_1_0_Layer0_2_FILL"
                />
                <path
                    fill="#666"
                    d="M30.6 163.45q1.8 1.8 4.35 1.8 2.6 0 4.4-1.8 1.8-1.75 1.8-4.35 0-2.6-1.8-4.4-1.8-1.85-4.4-1.85-2.55 0-4.35 1.85-1.85 1.8-1.85 4.4 0 2.6 1.85 4.35z"
                    id="PlaInd035__Symbol_1_0_Layer0_3_FILL"
                />
                <path
                    fill="#CCC"
                    d="M36.65 157.4q-.7-.7-1.7-.7-.95 0-1.7.7-.65.7-.65 1.7 0 .95.65 1.65.75.7 1.7.7.35 0 .55-.05 0-.05.05-.05l11.75 11.7 2.85-.5-12.8-13.05v-.4q0-1-.7-1.7z"
                    id="PlaInd035__Symbol_1_0_Layer0_4_FILL"
                />
                <path
                    d="M55.85 173.4q-2.15-2.1-5.15-2.1-3.05 0-5.25 2.1-2 2.15-2.15 5.05h14.65q-.05-2.9-2.1-5.05z"
                    id="PlaInd035__Symbol_1_0_Layer0_4_MEMBER_0_FILL"
                />
                <path
                    fill="#333"
                    d="M29.05 109.25v-6.05H13.6v6.05h15.45z"
                    id="PlaInd035__Symbol_1_0_Layer0_5_FILL"
                />
                <path
                    d="M44.2 96.6H21.5q-7-.35-7.95 6.7H50.3v-1.45q-.2-4.45-6.1-5.25z"
                    id="PlaInd035__Symbol_1_0_Layer0_6_FILL"
                />
                <g id="PlaInd035__Layer4_0_FILL">
                    <path fill="#A1DDFF" d="M313.95 118V0H0v118h313.95z" />
                    <path fill="#FFF" d="M385 385V120.55H0V385h385z" />
                </g>
                <g id="PlaInd035__Layer2_1_MEMBER_1_FILL">
                    <path d="M327.85 141.6q-.35.35-.65.7-2.65 3.05-2.65 7.2 0 1.35.3 2.5.4 2.1 1.65 3.9.35.4.7.85l.2.2q.2.25.45.5 1.35 1.35 2.9 2.15 2.3 1.1 5 1.1 4.5 0 7.65-3 .2-.15.25-.25 3.35-3.3 3.35-7.95 0-3.55-1.95-6.3-.05-.05-.05-.15l-.7-.9q-.3-.25-.65-.55-3.25-3.25-7.9-3.25-3.45 0-6.1 1.8-1 .6-1.8 1.45z" />
                    <path
                        fill="#666"
                        d="M327.2 139.75l-.5.4q-3.25 3.25-3.25 7.9 0 1.35.2 2.5.5 2.1 1.75 3.9l1.1 1.45q-1.25-1.8-1.65-3.9-.3-1.15-.3-2.5 0-4.15 2.65-7.2.3-.35.65-.7.8-.85 1.8-1.45 2.65-1.8 6.1-1.8 4.65 0 7.9 3.25.35.3.65.55l-1.35-1.6-.4-.4q-3.25-3.25-7.9-3.25-3.5 0-6.15 1.85-.65.4-1.3 1z"
                    />
                </g>
                <g id="PlaInd035__Layer2_1_MEMBER_6_FILL">
                    <path d="M357.9 193.5q0 1.5.35 2.8.45 2.35 1.85 4.35.35.45.75.95.15.1.25.25.2.25.5.55 1.5 1.5 3.25 2.4 2.55 1.25 5.6 1.25 5 0 8.55-3.35l.3-.3q3.7-3.7 3.7-8.9 0-4-2.15-7.05-.05-.05-.05-.15l-.8-1q-.35-.3-.7-.65-3.65-3.65-8.85-3.65-3.85 0-6.85 2.05-1.1.65-2 1.6-.4.4-.75.8-2.95 3.4-2.95 8.05m9.9 2.4q-.15-.1-.15-.25 0-.25 2-1.75.25-.2.55-.3-.8-.9-1.3-1.55.75.35 1.75 1.2.35-.25.6-.4.85-.6 1.4-.8l.15.15q-.55.4-1.3.9l-.5.4q.85.75 1.9 1.95-.1.2-.2.2-.3 0-1.65-1.25-.25-.3-.55-.55-1.2.85-2.7 2.05z" />
                    <path
                        fill="#0FF"
                        d="M367.65 195.65q0 .15.15.25 1.5-1.2 2.7-2.05.3.25.55.55 1.35 1.25 1.65 1.25.1 0 .2-.2-1.05-1.2-1.9-1.95l.5-.4q.75-.5 1.3-.9l-.15-.15q-.55.2-1.4.8-.25.15-.6.4-1-.85-1.75-1.2.5.65 1.3 1.55-.3.1-.55.3-2 1.5-2 1.75z"
                    />
                    <path
                        fill="#666"
                        d="M356.65 191.9q0 1.5.25 2.8.55 2.35 1.95 4.35l1.25 1.6q-1.4-2-1.85-4.35-.35-1.3-.35-2.8 0-4.65 2.95-8.05.35-.4.75-.8.9-.95 2-1.6 3-2.05 6.85-2.05 5.2 0 8.85 3.65.35.35.7.65l-1.5-1.8-.45-.45q-3.65-3.65-8.85-3.65-3.9 0-6.9 2.05-.7.45-1.45 1.15-.25.2-.55.45-3.65 3.65-3.65 8.85z"
                    />
                </g>
                <g id="PlaInd035__Layer2_1_MEMBER_6_MEMBER_0_FILL">
                    <path d="M362.4 194.55q0 1.05.15 2.05.4 1.65 1.4 3.1.3.45.7.9.2.2.3.4 1.15 1.1 2.45 1.7.4.2.8.35 1.45.55 3.2.55 3.65 0 6.15-2.45.2-.1.25-.15 2.7-2.65 2.7-6.45v-.3q0-.7-.1-1.35-.35-1.9-1.5-3.45l-.05-.05q-.25-.4-.55-.75l-.15-.2-.05-.05q-.15-.1-.3-.25-2.6-2.65-6.4-2.65-2.8 0-4.95 1.45-.75.55-1.5 1.2-2.55 2.65-2.55 6.4z" />
                    <path
                        fill="#666"
                        d="M362.55 196.6q-.15-1-.15-2.05 0-3.75 2.55-6.4.75-.65 1.5-1.2 2.15-1.45 4.95-1.45 3.8 0 6.4 2.65.15.15.3.25l.05.05.15.2q.3.35.55.75l.05.05q1.15 1.55 1.5 3.45l.05-.05q-.3-2.45-2.25-4.65l-.9-1.1q-.2-.2-.3-.4-2.75-2.7-6.6-2.7-2.9 0-5.1 1.6-.8.45-1.5 1.1-2.7 2.75-2.7 6.6 0 1.1.2 2.1.35 1.7 1.4 3.25l.95 1.15q1.8 2.15 4.5 3.3l.05-.05q-.4-.15-.8-.35-1.3-.6-2.45-1.7-.1-.2-.3-.4-.4-.45-.7-.9-1-1.45-1.4-3.1z"
                    />
                </g>
                <g id="PlaInd035__Layer2_1_MEMBER_6_MEMBER_0_MEMBER_0_FILL">
                    <path d="M369.45 190.8q-.55.35-.95.75-1.65 1.65-1.65 4.05 0 .7.15 1.35.15.9.65 1.65l.2.3.4.6q.15.1.25.25.75.65 1.5 1.05 1.2.6 2.6.6 2.3 0 3.9-1.5l.15-.15q1.75-1.75 1.75-4.15 0-.75-.15-1.4-.2-.75-.55-1.4l-.2-.3q-.05-.1-.1-.15l-.55-.65-.05-.05q-.05-.05-.15-.1-1.65-1.75-4.05-1.75-1.8 0-3.15 1z" />
                    <path
                        fill="#666"
                        d="M368.5 191.55q.4-.4.95-.75 1.35-1 3.15-1 2.4 0 4.05 1.75.1.05.15.1l.05.05-.75-1q-.1-.05-.2-.2-1.7-1.7-4.1-1.7-1.75 0-3.15 1-.45.25-.95.7-1.65 1.7-1.65 4.1 0 .65.15 1.25.15.8.5 1.5.05.05.1.15l.1.2q.1.1 1.1 1.5.65.9.25.3l-.4-.6-.2-.3q-.5-.75-.65-1.65-.15-.65-.15-1.35 0-2.4 1.65-4.05z"
                    />
                </g>
                <g id="PlaInd035__Symbol_1_0_Layer0_0_FILL">
                    <path
                        fill="#999"
                        d="M104.45 137.95h6.05l-5.95-5.85q-.55.25-1.1.55-1.45.75-2.95 1.4l3.95 3.9z"
                    />
                    <path d="M110.5 137.95h-8.2v4.35h12.5v-4.35h-4.3m-98 4.15v-4.3H0v4.3h12.5z" />
                    <path
                        fill="#CCC"
                        d="M23.95 78.4L16.9 51.95h-6.4L17.95 80l6-1.6m91.85-38.15h-6.45l-6.95 25.9h.05l-8.9 32.9 6 1.65 16.25-60.45z"
                    />
                </g>
                <g id="PlaInd035__Symbol_1_0_Layer0_7_FILL">
                    <path d="M113.1 87l3.65 1.05 5.1-18.25-3.85-1.05L113.1 87z" />
                    <path
                        fill="#666"
                        d="M116.75 88.05L113.1 87l-1.55 5.95 14.95 3.95 3.05-11.45q.5-1.9 1.05-3.8l1.4-5.4 9.8-2.85 5.3-19.75-4.15-1.1-4.65 17.25-1 .25.05-.05-8 1.75-1.9 7.1v-.05l-1.3 4.8.05-.05-1.8 6.45-2.65-.65 4.9-18.25-4.8-1.3-5.1 18.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaInd035__Layer4_0_FILL" />
            <use xlinkHref="#PlaInd035__Layer4_1_FILL" />
            <use xlinkHref="#PlaInd035__Layer4_2_FILL" />
            <use xlinkHref="#PlaInd035__Layer4_3_FILL" />
            <use xlinkHref="#PlaInd035__Layer3_0_FILL" />
            <use xlinkHref="#PlaInd035__Layer3_1_FILL" />
            <use xlinkHref="#PlaInd035__Layer3_2_FILL" />
            <use xlinkHref="#PlaInd035__Layer2_0_MEMBER_0_FILL" />
            <use xlinkHref="#PlaInd035__Layer2_0_MEMBER_1_FILL" />
            <use xlinkHref="#PlaInd035__Layer2_1_FILL" />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_0_FILL"
                transform="translate(.2 -.2)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_1_FILL"
                transform="translate(-97.2 -47.5) scale(1.26428)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_2_FILL"
                transform="translate(.2 -.2)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_3_FILL"
                transform="translate(.2 -.2)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_4_FILL"
                transform="translate(.2 -.2)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_5_FILL"
                transform="translate(.2 -.2)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_6_FILL"
                transform="matrix(1.27274 0 0 1.27274 -108.9 -60.5)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_6_MEMBER_0_FILL"
                transform="matrix(1.27269 0 0 1.27269 -108.75 -60.35)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_1_MEMBER_6_MEMBER_0_MEMBER_0_FILL"
                transform="matrix(1.27264 0 0 1.27264 -108.75 -60.35)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_2_MEMBER_0_FILL"
                transform="translate(14.95 22.35) scale(.85298)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_2_MEMBER_1_FILL"
                transform="translate(72.75 31.85) scale(.79782)"
            />
            <use
                xlinkHref="#PlaInd035__Layer2_2_MEMBER_2_FILL"
                transform="matrix(.84589 0 0 .84589 58.15 23.55)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_0_FILL"
                transform="matrix(.91766 0 0 .91766 5.15 56.95)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_1_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_2_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_3_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_4_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_4_MEMBER_0_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_5_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_6_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <use
                xlinkHref="#PlaInd035__Symbol_1_0_Layer0_7_FILL"
                transform="matrix(.91766 0 0 .91766 .057 8.727)"
            />
            <g>
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_0_FILL"
                    transform="matrix(.94617 0 0 .94617 34.55 107.55)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_1_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_2_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_3_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_4_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_4_MEMBER_0_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_5_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_6_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
                <use
                    xlinkHref="#PlaInd035__Symbol_1_0_Layer0_7_FILL"
                    transform="matrix(.94617 0 0 .94617 29.299 57.829)"
                />
            </g>
        </g></g>
    )
}

export default PlaInd035
