import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000"
}

function AccMor025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.8 1.6q0-.95-.55-1.3-.1-.05-.65-.25V0h-.05l-.15.05Q114.3 3.3 96.75 4.8q-1.15.05-2.3.15-.65-.25-1.4-.3-.15-.05-1.15 0h-.15l.05.05q-.6.1-1.1.5.05 0 .05.05h-.1q-1.5-.55-2.55-.7-1.75-.35-3.9-.35-1.8 0-2.9.25-1.4.15-2.3.65-.1 0-.15.05-.55.25-.9.65h-.35l-.6.05h-.8q-31.05.65-63.5-4.2-.15-.05-.3-.05-.45-.1-.95-.15L5.1.4Q1.15-.45 1.15 2.95q-.05.1 0 .2t.1.15q.05.05.05.1.3.4.3.95 0 .65-.65 1.3-.05.05-.15.1-.8.7-.8 1.7 0 2.2 1.35 2.3v.05l.3.05q35.35 5 70.45 4.65h1.85q-.05.1-.05.2h-.05q-.35.85-.6 1.8-.55 2-.55 4.45.2 1.6.7 3.2.2.7.5 1.3.8 1.9 2.05 3.35Q77 28 78.6 27.2q.35-.2 2.05-.75 1.35-.45 1.7-.9-.25-.25-.5-.45-.3-.25-.55-.55-.2-.2-.4-.45-.75-1-.9-1.55-.3-1-.3-1.15-.05-.15-.05-.85 0-2.6 3.3-4.8.25-.15.45-.35.45.05.9.05 1.95 0 2.5-.05 1.1-.15 1.7-.4l.05-.05V15q-.006-.082.2 0 .4.1.25.15h.05q1.2.15 2.2.15 1.7 0 3.2-1.35.6.65 1.15 1.3 1.45 1.65 2.2 3.1 1.6 3 1.8 7.45v.15q2.7-2.05 5.55-2.95 2.2-.7 2.2-.75 0-1.15-1.45-4.35l-1.45-2.95-.5-.8q-.4-.55-.75-1 .6-.05 1.25-.1 14.25-1.15 28.8-3.45.5-.2.7-.5.25-.4.25-1.4 0-1.05-.8-1.9-.3-.3-.45-.65-.35-.65-.35-1.55v-.7l.2-1.3M79.5 5.65l.05.05h-.1q0-.05.05-.05z"
                    id="AccMor025__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M.65 1.45q0 .65-.65 1.3L7.15 3.8q15 2.8 37.6 2.5-10.4-.9-18.15-1.7Q10.65 2.6.2.25q.05.1.1.15.05.05.05.1.3.4.3.95m72.3 10.35h-.05q-.35.85-.6 1.8.4-1.2.65-1.8m19.3-7.25q-.15-1.3-1-2.3-.2-.25-.4-.45-.6.1-1.1.5.05 0 .05.05h-.1q.105.002.2.05.4.4.65 1.1.35.9.35 1.95 0 1-1 3h-.15Q86.65 7.2 84.8 5.7t-4.15-2H79.2q-.8 0-1.85.55-.85.45-1 .9-.2.15-.3.35L76 5.35q0-1.5.95-2.45h-.3l-.6.05h-.8q-.1.15-.15.35-.25.75-.25 2.25v.3h-.6q-3.45.75-8.25.9-4.55.2-9.45.5 6.2.4 11.45.5 3 .05 5.6 0-1.3 1.45-1.6 1.9-.65.9-.85 1.95H73q.7-1.55 1.75-2.8.35-.45 1.25-1.1l.5-.4q.55-.4 1.3-.9.65-.45 1.15-.9.35-.1.75-.1.75 0 2.45 1.15 1.15.8 1.75 1-.85 2-3.3 3.8-.05.05-.05.1-.25.15-.45.35-2.45 2-2.45 4.45 0 .45.1 1 .25 1.2 1.15 2.55.5.75 1.05 1.4-.75-1-.9-1.55-.3-1-.3-1.15-.05-.15-.05-.85 0-2.6 3.3-4.8.25-.15.45-.35.65-.55 1.2-1.15.25-.35.45-.7.2-.45 1.25-2.6 2.4 1.05 4.35.8-.7 1.05-1.5 2.3-.3.4-.6.9v.05q-.006-.082.2 0 .4.1.25.15h.05q.75-.2 1.45-1 .05-.1.15-.2.7-.8 1.75-2.3.95-2.6.8-4.2m10 5.7q.6-.05 1.25-.1-.7-1.8-2.35-3.4-1.9-1.8-3.9-2.2-.5-1.4-1.45-2.65-1.15.05-2.3.15.55.2 1 .5 1.65 1.05 1.65 3.05 0 2.2-1.65 4.35l-.1.1q-.35.45-.7.8l-.2.2q.6.65 1.15 1.3 1.45 1.65 2.2 3.1 1.6 3 1.8 7.45 0-5.55-1.05-8.6-.7-1.95-1.9-3.25.1-.2.25-.35.55-.8.8-1.35.55-1.05.55-2.3v-.3h.05q-.05-.45-.05-.55l.2.1q.2.1.9.5h.05q.35.2.7.45 1.6 1.1 3.1 3m29.75-8q-.35-.65-.35-1.55V0q-10.2 3.2-23.9 4.95 14-.1 24.25-2.7z"
                    id="AccMor025__Symbol_74_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor025__Symbol_123_0_Layer0_0_FILL"
                transform="translate(123.9 296.35)"
                id="AccMor025__col1n"
            />
            <use
                xlinkHref="#AccMor025__Symbol_74_0_Layer0_0_FILL"
                transform="translate(124.85 299.25)"
                id="AccMor025__col1d"
            />
        </g></g>
    )
}

export default AccMor025
