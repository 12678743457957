import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FFFFFF"
}

function HaiSpe031({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M197.9 72.1q-3.1-9.75-8.4-19.45-17.25-29.8-43.7-42.7Q126.8.7 103.1.2 62.95-2.35 32.5 27.3q-9.7 9.5-18.45 22.35Q1.1 70.75.1 93.4q-1.5 32 20.9 67.05 1.65 2.6 4.85 5.9v7.7q-5.15 3.35-6.55 8.4-4.25 14.85 11.75 45.4 16-31.2 13.15-45.4-1.05-5.3-3.9-8.25v-8.75q.5-2.05 1.2-5 2.15-9.55-2.75-25.8-.5-1.65-2.4-5.8-4.85-9.75 2.3-15.25 77.55-44.35 87.75-78.1 1.3 22.85 38.15 48.05 18.75 19.05 5.85 38.05-11.4 19.3-11.9 33.4-.4 2.05.45 5.45.5 2.3 1.6 5.25v8.5q-3.7 2.95-4.75 8.25-2.85 14.2 13.15 45.4 16-30.55 11.8-45.4-1.45-5.05-5.2-8.25v-8.5l3.85-5.9q32.15-44 18.5-87.7z"
                    id="HaiSpe031__Symbol_163_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M20.1 113.05q-.4 5.05-1.9 8-2.1 4.1-7.8 4.1-3.95 0-6.55-1.35-2-1.05-3.85-3.3 1.3 2.2 2.75 4.45 1.65 2.6 4.85 5.9 1.95 3.85 0 7.7-5.15 3.35-6.55 8.4-4.25 14.85 11.75 45.4l.05-.05Q9.7 169.65 9.7 163.75q0-5.2 2.1-9.35.45-.85.95-1.6.65.1.95 1.25.25.85.25 1.85 0 3.2-.9 9.25v1.75h.15q.8-1.65 2.6-6.9 1.45-4.4 2.75-6.2.5.15.85 1.9.3 1.45.3 2.5 0 3.7-3.5 16.95v.15q1.65-2.35 3-5.05.45-1 2.35-5.35l1.4-6.1q-.05-2.2-.05-3.5-.05-2.5 1.1-2.5.35 0 .7.2.05 0 .05.05.9 1.5 1.3 3.2.7-5.3-.1-9.3-1.05-5.3-3.9-8.25-2.55-4.35 0-8.75.5-2.05 1.2-5 2.15-9.55-2.75-25.8-.35-1.2-1.45-3.7.4 1 .95 6.8.5 5.75.1 10.8m83.85-103.3q2.75-5 4.2-9.75l-.1.05q-.3.1-1.4.8-1.1.65-2.35 2.75t-2.05 3.2q-.85 1.05-1.15 1.45-1.15 1.4-1.8 2.1-4.8 5.6-11.35 11-7.55 6.2-12.15 9.75-6.45 5-12.95 9.35-5.4 3.65-15.2 9.45-9.8 5.8-15.55 11-5.7 5.2-11 10.7-5.3 5.45-5.3 12.75 0 2.3.4 2.75-.45-5.4 4.2-9 54.75-31.3 75.95-57.35 1.95-2.4 3.6-4.7 2.25-3.2 4-6.3m33 20.85q-7.05-5.45-8.45-6.6-4.05-3.35-8.5-7.8-4.5-4.45-5.55-5.95-1.1-1.5-3.05-5.4-1.9-3.9-3.25-4.85.3 5.45 2.65 11.1.65 1.5 1.4 3 .7 1.35 1.5 2.7.7 1.2 1.55 2.45 9.35 13.95 31.05 28.8 11 11.2 11.1 22.4.15-1.1.15-2.2 0-4.75-2.2-10.9-2.85-6.85-4.15-10-1.9-4.6-5.5-8.9-2.25-2.75-8.75-7.85m26.4 124.2v-1.15q0-3.7-.85-6.7-1.45-5.05-5.2-8.25-3.5-4.25 0-8.5l3.85-5.9q.5-.7 1.05-1.4-3.35 2.25-7.2 2.25-3.55 0-5.4-.65-2.35-.8-3.65-2.95-1.15-1.9-1.85-4.75-.75-2.95-.75-5.7 0-5.15 1.6-10.05.75-2.3 3.1-7.45-7.4 14.6-7.8 25.9-.4 2.05.45 5.45.5 2.3 1.6 5.25 3.4 3.75 0 8.5-3.7 2.95-4.75 8.25-2.85 14.2 13.1 45.3.15-6.45-1.2-15.9-1.6-10.95-1.6-15.95 0-3.55.9-6.1.1-.3.2-.55.1-.1.25-.15.1-.05.75-.05 1.25 0 1.5 2v3.25q0 3.35-.75 5.7-.75 2.3-.75 2.9v.25q1.4-1.15 3.25-6.75.35-1.8.8-3.3.95-3.05 2-3.05 1.2 0 1.2 4.55 0 2.05-.6 4.6-.8 3.3-.9 3.85-.25 1.7-.5 4.1l-.5 2q0 .4-.25 1l-.1.5v.75q.3-.85 2.35-5.35 1.5-3.25 1.9-5.5l1.1-6.5q.15-2.45.4-3.5.4-1.75 2-1.75.35 0 .65.1.3.65.6 1.4z"
                    id="HaiSpe031__Symbol_95_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M266.35 231.25v-8.5h-15v8.5h15m-135.1 0v-8.5h-15v8.5h15z"
                    id="HaiSpe031__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe031__Symbol_163_0_Layer0_0_FILL"
                transform="translate(90.8 57.05)"
                id="HaiSpe031__col1n"
            />
            <use
                xlinkHref="#HaiSpe031__Symbol_95_0_Layer0_0_FILL"
                transform="translate(109.05 92.55)"
                id="HaiSpe031__col1d"
            />
            <use
                xlinkHref="#HaiSpe031__Symbol_24_0_Layer0_0_FILL"
                id="HaiSpe031__col2n"
            />
        </g></g>
    )
}

export default HaiSpe031
