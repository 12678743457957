import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343",
    "col2l": "#C1D2E2",
    "col2n": "#8FAFCD"
}

function AccGla034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M2.15 2.1q.35-.35.35-.85t-.35-.9Q1.75 0 1.25 0T.4.35q-.4.4-.4.9t.4.85q.35.4.85.4t.9-.4z"
                    id="AccGla034__Symbol_93_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    fillOpacity={0.502}
                    d="M64.65 32.35q0-13.35-9.5-22.9Q45.7 0 32.3 0 18.95 0 9.45 9.45 0 19 0 32.35q0 13.35 9.45 22.8 9.5 9.5 22.85 9.5 13.4 0 22.85-9.5 9.5-9.45 9.5-22.8m56.75-22.9Q111.95 0 98.55 0 85.2 0 75.7 9.45q-9.45 9.55-9.45 22.9 0 13.35 9.45 22.8 9.5 9.5 22.85 9.5 13.4 0 22.85-9.5 9.5-9.45 9.5-22.8 0-13.35-9.5-22.9z"
                    id="AccGla034__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M168.7 28.05l23.4-7.2v-2.6l-24 5.6q-3.7.55-6.05-1.05-2.45-7.05-8.15-12.75Q143.85 0 129.65 0q-14.25 0-24.3 10.05-7.25 7.2-9.3 16.6-2.05-9.4-9.3-16.6Q76.7 0 62.45 0 48.25 0 38.2 10.05q-5.7 5.7-8.15 12.75-2.35 1.6-6.05 1.05l-24-5.6v2.6l23.4 7.2q2.6.7 4.85 3.2l-.1 1.3q-.05.9-.05 1.8 0 14.25 10.1 24.3Q48.25 68.7 62.45 68.7q14.25 0 24.3-10.05 7.25-7.2 9.3-16.6 2.05 9.4 9.3 16.6 10.05 10.05 24.3 10.05 14.2 0 24.25-10.05Q164 48.6 164 34.35q0-.9-.05-1.8l-.1-1.3q2.25-2.5 4.85-3.2M129.65 3.4q12.8 0 21.85 9.05 6.95 6.9 8.55 16.1v.05l.2.85q.2 1.6.3 3.3.05.8.05 1.6 0 12.85-9.1 21.9-9.05 9.05-21.85 9.05-12.85 0-21.9-9.05-9.1-9.05-9.1-21.9 0-12.85 9.1-21.9 9.05-9.05 21.9-9.05m-45.3 9.05q9.1 9.05 9.1 21.9 0 12.85-9.1 21.9-9.05 9.05-21.9 9.05-12.8 0-21.85-9.05-9.1-9.05-9.1-21.9 0-.8.05-1.6.1-1.7.3-3.3l.2-.85v-.05q1.6-9.2 8.55-16.1Q49.65 3.4 62.45 3.4q12.85 0 21.9 9.05z"
                    id="AccGla034__Symbol_124_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccGla034__Symbol_93_0_Layer0_0_FILL"
                transform="translate(191.3 159.75)"
                id="AccGla034__col2n"
            />
            <use
                xlinkHref="#AccGla034__Symbol_123_0_Layer0_0_FILL"
                transform="translate(127.05 130.45)"
                id="AccGla034__col2l"
            />
            <use
                xlinkHref="#AccGla034__Symbol_124_0_Layer0_0_FILL"
                transform="translate(96.45 128.6)"
                id="AccGla034__col1n"
            />
        </g></g>
    )
}

export default AccGla034
