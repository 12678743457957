import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col1s": "#4F4F4F",
    "col2n": "#FFFFFF"
}

function ShiSpe064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-.1-.2-.2-.35-.9-1.65-1.85-3.2l-.05-.05q-.6-.95-1.15-1.9-.1-.1-.15-.2l-1.4-2.1q-.45-.7-.9-1.35-.5-.65-.95-1.3L143.3 1l-.6-.8q-.1-.1-.1-.15l-.05-.05-1.85 1.4q-.15.1-.3.25l-.2.2q-1.25 1.2-2.65 2.4-.95.8-2 1.65-1.6 1.25-3.35 2.5-1.25.9-12.85 6.5-21.5 6.85-23.7 7.15v.1q-.35.05-16.35 1-9.6-.95-9.9-1v-.05q-.25-.2-3-.9t-10-2.55q-7.3-1.85-9.65-3.35-2.4-1.5-6.7-3.2-4.35-1.7-7.2-3.7-.75-.5-1.4-1-.65-.5-1.3-.95-1-.85-1.95-1.65-.55-.45-1-.85-1.15-1-2.2-2-.35-.3-.7-.55L22.45 0q-.1.05-.1.15h-.05q-.1.15-.2.25-.05.05-.05.15-.2.2-.35.45h-.05q-.2.3-.4.55-.45.6-.85 1.25l-1.2 1.8q-.4.55-.75 1.1-.95 1.5-1.85 3-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 26 5.9 51.85 6.85h.1q.8 0 1.55.05h1.25q3.35.1 6.65.1 3.35 0 6.7-.05h1.25v-.05q.75-.05 1.5-.05.05-.05.1 0Q118 87.9 143.8 82q2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe064__Symbol_363_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M67.25 28.9h-.4v60.05h1.25V28.9q-.15 0-.35.05v-.1q-.2 0-.45.05h-.05m-13.75.05q-.65-.05-1.25-.1V88.9h1.25V28.95m-6.45-6.85q-1-.15-2.1-.3-7.6-1.15-13-3.05-5.45-1.95-8.9-3.55-3.5-1.6-6.6-3.2-3.1-1.6-5.95-3.6-.75-.5-1.4-1-.65-.5-1.3-.95-1-.85-1.95-1.65-.55-.45-1-.85-1.15-1-2.2-2-.35-.3-.7-.55L.1 0Q0 .05 0 .15.1.3.2.5q.15.3.4.65.15.2.35.5.05.1.15.25.65.95 1.35 1.9.8 1.05 1.75 2.15.5.6 1.1 1.25 1.4 1.55 3 3.05 1.35 1.25 2.8 2.5 1.2.95 2.5 1.95.1.1.25.2 11.6 8.6 26.1 12.05l2 .4q1.95.4 3.95.75.55.05 1.15.15.4.05.85.15v-6.15q-.45-.05-.85-.1v-.05M120.35.2q-.1-.1-.1-.15L120.2 0l-1.85 1.4q-.15.1-.3.25l-.2.2q-1.25 1.2-2.65 2.4-.95.8-2 1.65-1.6 1.25-3.35 2.5-1.45 1.05-6.2 3.65-4.75 2.6-9 4.4-4.3 1.75-7.8 2.9-3.55 1.1-9.1 2.05-2.2.35-4.45.65v.1l-.9.1v6.1l.9-.1q1.25-.2 2.45-.4 7.05-1.25 13.45-3.6 2.6-1 5.15-2.15 6.5-3.05 12.35-7.4.85-.6 1.65-1.25 4.75-3.8 8.2-8 .15-.25.35-.45.25-.35.55-.7.1-.15.2-.25l.2-.2q1.35-1.8 2.5-3.65z"
                    id="ShiSpe064__Symbol_364_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.45.8q-.2.1-.25.3L0 22.55v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4V1.3q.05-.2-.05-.35Q5 .75 4.8.7q-.2-.05-.35.1M59.7 39.5q-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05q-.15-.6-.6-1.05-.65-.65-1.6-.65m1.6-35.65q.65-.65.65-1.6 0-.95-.65-1.6Q60.65 0 59.7 0q-.95 0-1.6.65-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65M61.95 22v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6M115.4 1.3v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4 1.1q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35z"
                    id="ShiSpe064__Symbol_365_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M110.55 4.25q1.05-.85 2-1.65 1.4-1.2 2.65-2.4l.2-.2q-.5.35-.95.7-.75.6-1.55 1.15L111.35 3q-3.7 2.55-7.6 4.75-8.05 4.45-16.8 7.2-.2.05-.4.15-.8.25-1.65.5-1.15.3-2.25.6-1.7.45-3.4.8-3.05.65-6.15 1.15-1.2.2-2.45.35l-.9.1q-1 .15-2 .25-.6 0-1.2.1-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2H56.75q-3.1 0-6.15-.2L49.5 19q-1.35-.1-2.65-.25-.8-.05-1.6-.15-.45-.05-.85-.1-.6-.1-1.15-.15l-1.4-.2q-1.3-.2-2.55-.45-.85-.15-1.65-.3-.2-.05-.35-.05-2.45-.55-4.85-1.15-5.7-1.45-11.05-3.6-2.65-1.1-5.2-2.3-1.05-.5-2.05-1-1-.55-2-1.05-1.25-.7-2.45-1.4Q6.6 5.05 3.65 3L2.1 1.85Q1 1.05 0 .3q1.05 1 2.2 2 .45.4 1 .85.95.8 1.95 1.65.65.45 1.3.95.65.5 1.4 1 2.85 2 5.95 3.6t6.6 3.2q3.45 1.6 8.9 3.55 5.4 1.9 13 3.05 1.1.15 2.1.3v.05q.4.05.85.1v6.15l1.6.2 1.1.1h.1q.75.1 1.55.15.6.05 1.25.1 2.9.2 5.9.2h.75q3.4 0 6.7-.25h.45q.25-.05.45-.05.95-.1 1.85-.15.05-.05.1 0l.7-.1q1-.1 2-.25v-6.1l.9-.1v-.1q2.25-.3 4.45-.65 5.55-.95 9.1-2.05 3.5-1.15 7.8-2.9 4.25-1.8 9-4.4 4.75-2.6 6.2-3.65 1.75-1.25 3.35-2.5z"
                    id="ShiSpe064__Symbol_366_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe064__Symbol_363_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe064__col1n"
            />
            <use
                xlinkHref="#ShiSpe064__Symbol_364_0_Layer0_0_FILL"
                transform="translate(132.35 228.6)"
                id="ShiSpe064__col1d"
            />
            <use
                xlinkHref="#ShiSpe064__Symbol_365_0_Layer0_0_FILL"
                transform="translate(132.2 263.15)"
                id="ShiSpe064__col1s"
            />
            <use
                xlinkHref="#ShiSpe064__Symbol_366_0_Layer0_0_FILL"
                transform="translate(135 230.25)"
                id="ShiSpe064__col2n"
            />
        </g></g>
    )
}

export default ShiSpe064
