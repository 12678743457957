import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col2d": "#747474",
    "col2n": "#999999"
}

function AccMor019({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M132.45 3.75l.1-1.45q-.05-1.25-.8-1.85-.55-.4-1.3-.25-.25.05-.6.15l-1 .2q-1.8.3-3.55.65l-1.2.2q-1.8.3-3.65.6-1.35.2-2.75.45-1.45.2-2.95.45-2.9.4-5.75.75-4.2.55-8.45.95-1.5.15-3.05.3-1.5.15-2.95.25l-1.3.1q-.15 0-.35.05-.6 0-1.25.1l-1.2.1h-.55l-1.2.1h-.35q-.15 0-.3.05-.05-.05-.1 0h-.3q-.35 0-.7.05H86.5v-.4h-4.9q-.5.25-.75.7-.75 0-1.45.05h-.9q-.85.05-1.75.05L74 6.2H70.85q-.55 0-1.1.05-.35-.05-.75 0-.15-.05-.35 0-.2-.05-.4 0H66q-.8-.05-1.6-.05H59.35l-2 .65-.65-.75q-1-.05-1.95-.1-6.1-.25-12.25-.65-2-.2-4.05-.35-2.35-.2-4.75-.4l-4.6-.5q-2.1-.25-4.2-.45-4.75-.6-9.5-1.3l-3.4-.5q-.75-.15-1.5-.25-.35-.05-.75-.1-.35-.1-.75-.15-.6-.1-1.25-.2l-.5-.1Q6.7.95 6.2.9h-.15Q5 .7 3.85.45 2.7.15 1.5 0h-.3Q.3-.05.15 1.05-.05 2.3 0 2.3v.95q.15 1.15 1.75 1.5l.3.05q2.2.45 4 .85h.15q.5.1 1.05.2l.5.1q.65.1 1.25.2.4.05.75.15.4.05.75.1.75.15 1.5.25l3.4.6q6.9 1.1 13.7 1.9 2.3.3 4.6.55 2.4.2 4.75.45 2.05.15 4.05.35 6.55.5 13.05.7.55 0 1.05.05h.1l.95-.4 1.5.5H66.05v.05H78.5q.45-.05.9-.05l1.1-.1q.15.5.5.85h5.45q.05 0 .05-.05V10.9h.15q.1-.05.6-.1t.7-.05h.75l11.15-.15q1.05 0 5.7.35 4.05-.05 4.05-2.1V8.6l2.8-.4q1.15-.15 2.35-.35 1.5-.2 2.95-.45 1.4-.2 2.75-.45 1.75-.3 3.65-.6.25-.05.55-.1.3-.05.65-.1 1.7-.3 3.55-.65.8-.1 1.6-.25 2-.5 2-1.5z"
                    id="AccMor019__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.45 2.35Q.7 2.35 0 2.4v5.3l1.1-.1h.4q.15-.05.25-.05v-5.2q-.1-.05-.25 0h-.05m6-.3H7.1v5.2h.15q.1-.05.6-.1t.7-.05H8.95V1.95q-.15 0-.3.05-.05-.05-.1 0h-.3q-.35 0-.7.05h-.1m24.4-1.2Q30.55.05 29.6 0q-4.2.55-8.45.95-1.5.15-3.05.3-1.5.15-2.95.25 2.25 0 5 .1 2.7.1 6.95-.05 3 .15 3.1 3.4l2.8-.4q.1-2.95-1.15-3.7z"
                    id="AccMor019__Symbol_68_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M5.1 1.8h4.85q0-.2-.05-.35Q9.9.4 8.6 0h-5Q2.15 0 1 1.2q0 .05-.05.05-.1.25-.2.45l-.1.1Q0 2.9 0 4.3q0 1.5.75 2.55 0 .05.05.1.1.1.2.3 1.05 1.25 2.6 1.3l5.1.05q1.05-.2 1.2-1.15V7.4l.2-.4v-.05H3.2q-.3-.2-.6-.55-.35-.45-.45-1H5.6q.75-1.05 0-2.15H2.2q.15-.45.4-.8.2-.25.4-.4.2-.1.4-.25H5.1z"
                    id="AccMor019__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.15.35q-.15.4-.15.8 0 .35.05.65 0 .2.1.35H1.2Q.75 1.1 1.2 0h-1Q.15.15.15.35z"
                    id="AccMor019__Symbol_5_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor019__Symbol_117_0_Layer0_0_FILL"
                transform="translate(126.6 306.7)"
                id="AccMor019__col1n"
            />
            <use
                xlinkHref="#AccMor019__Symbol_68_0_Layer0_0_FILL"
                transform="translate(206 310.35)"
                id="AccMor019__col1d"
            />
            <use
                xlinkHref="#AccMor019__Symbol_22_0_Layer0_0_FILL"
                transform="translate(182.55 311.1)"
                id="AccMor019__col2n"
            />
            <use
                xlinkHref="#AccMor019__Symbol_5_0_Layer0_0_FILL"
                transform="translate(184.55 314.35)"
                id="AccMor019__col2d"
            />
        </g></g>
    )
}

export default AccMor019
