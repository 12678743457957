import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD",
    "col2n": "#6486A6"
}
function FacEar013({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M56.3 14.5Q59.85 1.15 50.4 1 47.3.45 41.65.05 30.1-.7 24.6 14q-5.45 14.7-5.8 33.3v.55q-.05 4.6-.6 8.95-.45 3.45-1.15 6.65-1.2 5.45-3.4 10.95-1.35 4.45-3.15 9.6-5.3 15.8-7.9 24.6-5.2 13.25 0 22.85 2.15 2.8 7.55 5.65 5.05 2.7 11.05 8.35 3.8 3.6 8.7 3.6 5.55 0 9.05-3.9 4.35-8.7 5.6-18.85.05-.25.15-.45.05-.25.15-.45l.15-.75q3.8-9.7 17.8-9.2-6.3-7.35-10.85-14.9-10.15-16.35-12-31.5-.95-8.3-.75-15.65 0-13.65 3.95-26.8h.05q.55.4.65.55.1.05.45.3.25.05.6.2.05.05.15.05 4.3.55 7.3-3.85.1-.2.25-.4 2.3-3.9 3.7-8.95m193.45 32.8q-.35-18.6-5.8-33.3Q238.45-.7 226.9.05q-5.65.4-8.75.95-9.45.15-5.9 13.5 1.4 5.05 3.7 8.95.15.2.25.4 3 4.4 7.3 3.85.1 0 .15-.05.35-.15.6-.2.35-.25.45-.3.1-.15.65-.55h.05q3.95 13.15 3.95 26.8.2 7.35-.75 15.65-1.85 15.15-12 31.5-4.55 7.55-10.85 14.9 14-.5 17.8 9.2l.15.75q.1.2.15.45.1.2.15.45 1.25 10.15 5.6 18.85 3.5 3.9 9.05 3.9 4.9 0 8.7-3.6 6-5.65 11.05-8.35 5.4-2.85 7.55-5.65 5.2-9.6 0-22.85-2.6-8.8-7.9-24.6-1.8-5.15-3.15-9.6-2.2-5.5-3.4-10.95-.7-3.2-1.15-6.65-.55-4.35-.6-8.95v-.55z"
                    id="FacEar013__Symbol_31_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.1 1Q-1.25 5.8.55 16.55q1.85 10.7 6.5 36.6Q8.4 60.6 9.5 66.65q1.95 9.45 3.25 17.55 2.15 13.65 2.35 23.5.25-.85.55-1.6 3.8-9.7 17.8-9.2-6.3-7.35-10.85-14.9-10.15-16.35-12-31.5-.95-8.3-.75-15.65 0-13.65 3.95-26.8.05-.1.05-.15-.1-.05-.15-.15-.45-.4-1.75-2.35-.45-.8-1.1-1.8Q8.45 0 5.45 0q-.6 0-1.3.15Q3.6.45 3.1 1m203.65 0q-.5-.55-1.05-.85Q205 0 204.4 0q-3 0-5.4 3.6-.65 1-1.1 1.8-1.3 1.95-1.75 2.35-.05.1-.15.15 0 .05.05.15Q200 21.2 200 34.85q.2 7.35-.75 15.65-1.85 15.15-12 31.5-4.55 7.55-10.85 14.9 14-.5 17.8 9.2.3.75.55 1.6.2-9.85 2.35-23.5 1.3-8.1 3.25-17.55 1.1-6.05 2.45-13.5 4.65-25.9 6.5-36.6Q211.1 5.8 206.75 1z"
                    id="FacEar013__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.45 126.2q-2.35 0-4.1 1.65-1.7 1.75-1.7 4.15 0 2.4 1.7 4.05 1.75 1.75 4.1 1.75 2.4 0 4.1-1.75 1.7-1.65 1.7-4.05t-1.7-4.15q-1.7-1.65-4.1-1.65m6.1-42.55q-4.25-4.25-10.2-4.45h-.6q-2.8 0-5.2.85-.1.3-.2.55-.55 1.65-1.15 3.35Q2.4 95.3 0 103q.8 1.2 1.9 2.3 1.1 1.1 2.35 1.95 3.75 2.55 8.5 2.55h.5q6.05-.2 10.3-4.5 4.5-4.45 4.5-10.8 0-6.35-4.5-10.85M36.35 5q0-2.75-1.2-5Q30.5.3 27 3.4q-4.05 3.5-6.7 10.55-.05.15-.1.25 1.95 1.2 4.25 1.45.6.05 1.2.05H26.25q1.35-.1 2.65-.5 2.35-.75 4.3-2.7 3.15-3.1 3.15-7.5m206.9 110.7q0 3.45 2.45 5.9 2.45 2.5 5.95 2.5 3.45 0 5.9-2.5 2.5-2.45 2.5-5.9 0-3.5-2.5-5.95-2.45-2.45-5.9-2.45-3.5 0-5.95 2.45t-2.45 5.95m-6.75 21.35q-4.25 4.25-4.25 10.25 0 .8.1 1.55.95.15 2 .15 4.9 0 8.7-3.6 6-5.65 11.05-8.35.95-.5 1.8-1-3.9-3.25-9.15-3.25-6 0-10.25 4.25m8.95-89.8q-.05-3.35-.3-6.6-.05-1-.15-1.95-1.25.95-2.4 2.1-1.7 1.7-3 3.55-4.7 6.6-4.7 15.15 0 10.95 7.7 18.65 1.6 1.6 3.4 2.9 3.75 2.7 8.1 3.95-.2-.55-.35-1.05-1.2-3.45-2.2-6.6-.5-1.55-.95-3-2.2-5.5-3.4-10.95-.7-3.2-1.15-6.65-.55-4.35-.6-8.95v-.55z"
                    id="FacEar013__Symbol_35_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar013__Symbol_31_0_Layer0_0_FILL"
                transform="translate(58.25 101.55)"
                id="FacEar013__col1n"
            />
            <use
                xlinkHref="#FacEar013__Symbol_22_0_Layer0_0_FILL"
                transform="translate(87.6 120.1)"
                id="FacEar013__col1d"
            />
            <use
                xlinkHref="#FacEar013__Symbol_35_0_Layer0_0_FILL"
                transform="translate(62.55 101.6)"
                id="FacEar013__col2n"
            />
        </g></g>
    )
}
export default FacEar013
