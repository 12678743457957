import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000",
    "col2d": "#00284D",
    "col2n": "#00498A",
    "col2s": "#000D19"
}

function ShiLon101({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M156.9 243l.05.05.15.4L174.95 287h32.6l20-43.75q-11.843 4.591-25.05 5.85l-10.2 7.8-12.45-8.1q-1.504-.193-3-.45-10.417-1.557-19.95-5.35z"
                    id="ShiLon101__Layer18_0_FILL"
                />
                <path
                    fill="#BBC6CC"
                    d="M200 258.05l-2.45-1.6-5.4 3.1-5.95-3.1-.05-.05-1.95 1.2.35.1q-1.381.668-3.9 2.15l-6.35 4q-7.122 4.77-3.35 3.3 3.817-1.415 4.35-1.35.594.055.65.15l5.85-3.75q3.051-2.783 4-4l1.7.65q2.15.85 4.6 1.05 1.25 0 4.25-.8l.6-.2-.55.2 1.35-.4q1.091 1.242 3.75 3.6l5.95 3.5q.05-.046 1.25.45 1.2.49 3.95 1.35 2.75.914-3.8-3.8l-6.35-4.15q-1.703-.933-2.9-1.5l.4-.1z"
                    id="ShiLon101__Layer18_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M20.9 4.7q1.35-1.3 1-2.65L19.4.45 15.8 1.5l.55-.2-.6.2q-3 .8-4.25.8-2.45-.2-4.6-1.05L3.6 0 .05 2.25q-.3 1.2 1.15 2.35l5.5 4.2L4.35 18q4.65 6.1 6.1 7.45h.95q1.3-1.3 7.7-8.65l-2.45-6.3-.45-1.7 4.7-4.1z"
                    id="ShiLon101__Symbol_541_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.8 16.3L4.35 18l6.95 8.05 7.8-9.25-.5-1.25L11.5 22l-6.7-5.7m11.85-5.8l-.45-1.7q-4.75.45-9.5 0l-.35 1.75q5.4 1.15 10.3-.05m4.25-5.8q1.35-1.3 1-2.65L19.4.45 15.8 1.5l.55-.2-.6.2q-3 .8-4.25.8-2.45-.2-4.6-1.05L3.6 0 .05 2.25q-.3 1.2 1.15 2.35l3.9-2.25 6.35 1.2 6-1L20.9 4.7z"
                    id="ShiLon101__Symbol_503_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M60.9 24.2q.05-.35.1-.65 0-.15.05-.35 0-.4.1-.85Q62.5 11.8 66.75 2.5L56.1 19.3l-.65 1.55-4.75-2.9q-.15.45-.75 1.25-.3.35-.55.7-.05 0-.1.05-3.65 4.45-7.75 8.6-2.35 2.3-4.85 4.55l-2.25 1.85q-.15.15-.3.25l-.6.75-.65-.75.05.1-.05.05-.05-.15-.3-.2v-.05q-3.05-2.6-5.75-5.2-5.05-4.8-9-9.65-.05-.1-.15-.15-.35-.45-.65-.85-.45-.55-.85-1.1l-4.4 2.75L0 0q4.55 11.2 5.9 22.45.1.65.15 1.35.05.25.05.5.5 5.35.5 12.15l-.05-1.4v30.6h.1v8.65q12.35.9 17.75.3 5.35-.65 6.6-1.7 1.65.8 9.05 1.6 6.95.7 18.9-.1l1.4-.1v-.7h-.05v-.2h-.2v-.05h.25v-35.3q.15-1.9.05-4.7 0-2.45.25-6.35.1-1.45.25-2.8M32.05 72.1l-.3-.1q-.05-.05-.05-.1.15.1.35.2m-1.3.5h-.05l-.05-.05h.05l.05.05m-11.4-.7l.05-.05v.05h-.05z"
                    id="ShiLon101__Symbol_444_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M66.45 3.15q.15-.3.3-.65L56.1 19.3l-.65 1.55-4.75-2.9q-.15.45-.75 1.25-.3.35-.55.7l6.75 3.85 4.8-9.6q-2.05 4.55-1.55 9.5-.35.25-.65.5-.7.45-1.3.9Q43.9 34.5 30.25 38.8q.3-.35.7-.7l.1-.1q1.65-1.45 2.5-2.05l-.65-.75.05.1-.05.05-.25.1q-1.1.8-1.95 1.55-7.05-2.9-13-6.35l-.7-.4q-4-2.35-7.45-4.95-1.05-.75-2-1.5-.1-5.1-1.35-9.8l5.55 9.55 5.9-3.6q-.35-.45-.65-.85-.45-.55-.85-1.1l-4.4 2.75L0 0l.9 2.2 5.75 72.1q12.35.9 17.75.3 5.35-.65 6.6-1.7 1.65.8 9.05 1.6 6.95.7 18.9-.1l1.4-.1 6.1-71.15M49 31.65q.5-.3 1.05-.55l8.9-4.05v19.8h-12.6l-1.2-7-.6.6-.25 8.6 14.65-.15v24.7l-9.35.2q-1.25.1-5.15 0-3.9-.1-8.45-.7-3.15-.55-3.95-1l-.3-.1-.05-.05q-.25-.15-.45-.35-.05 0-.1-.05-.05 0-.1-.05l-.6-.5q-.1-.1-.2-.15-.9-.9-1.45-1.9l-.3-.6q-.4-.95-.55-2l-.2-.4v-23.3l.1-.1q.05-.75 1.05-2.2 11.55-4.05 20.1-8.7M7.95 26.9l10.25 5.25q1.35.75 2.75 1.45 4.4 2.15 9.2 3.9-.9.8-1.55 1.55-1.15 1.25-1.6 2.4-.25.7-.25 1.35v24.15q.55 2.7 2.8 4.5l.15.15q.3.25.65.6-1.95 1.15-3.75 1.25-1.8.1-6.3.25-4.55.15-8.85-.05l-3.5-.2V26.9m22.8 45.7h-.05l-.05-.05h.05l.05.05z"
                    id="ShiLon101__Symbol_412_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M3.75 20.15Q3.6 20 3.4 19.9q-.55-.4-1.2-.4-.45 0-.8.15-.4.15-.75.5Q0 20.8 0 21.7t.65 1.55q.65.65 1.55.65t1.55-.65q.65-.65.65-1.55t-.65-1.55m.65-7.85q0-.9-.65-1.55T2.2 10.1q-.9 0-1.55.65T0 12.3q0 .9.65 1.55.15.15.35.3.5.35 1.2.35.25 0 .5-.05.55-.1 1.05-.6.65-.65.65-1.55M3.75.65q-.5-.5-1.1-.6Q2.45 0 2.2 0q-.15 0-.3.05-.15 0-.25.05-.55.1-1 .55Q0 1.3 0 2.2q0 .25.05.5.1.35.3.65.1.2.3.4.45.45 1 .6.1 0 .25.05h.3q.25 0 .5-.05.6-.15 1.05-.6.65-.65.65-1.55T3.75.65z"
                    id="ShiLon101__Symbol_390_0_Layer0_0_FILL"
                />
                <path
                    fill="#B39244"
                    d="M256.7 234.6q-1.173-1.861-2.45-3.65-.83-1.191-1.7-2.35h-.05l-1.8 1.4h-.05q-.58.49-1.2.95-.756.575-1.55 1.15l-1.55 1.15q-.52.36-1.05.7-8.451 5.683-17.75 9.3l-.4.65q-1.116 1.995-2.05 4.05l-.2.4q-4.25 9.3-5.6 19.85-.1.45-.1.85-.05.2-.05.35-.05.3-.1.65-.15 1.35-.25 2.8-.25 3.9-.25 6.35.1 2.8-.05 4.7v40.05q20.25 3.9 41.9 0l-1.5-14.2h-.05l-5.3-21.3v.05l-.55-2.45q4.55-5.05 12.65-4.7l2.05.15q.15.047.3.05 2.153.43 4 1.35 1.646.82 3.05 2.05V284.8q-.006-.175-.05-.35-.276-1.54-.6-3.05-.01-.2-.05-.4-.036-.2-.1-.4-.198-1.106-.45-2.2-.062-.426-.15-.85-.068-.263-.15-.55-.137-.687-.3-1.4-.07-.3-.15-.6-.166-.751-.35-1.5-.33-1.357-.7-2.7-.066-.313-.15-.65-.206-.76-.45-1.55-.72-2.697-1.55-5.3-.31-.982-.65-1.95-.25-.804-.55-1.6-.039-.175-.1-.35-.427-1.233-.9-2.45-.864-2.418-1.85-4.75-.928-2.292-1.95-4.5-1.804-3.927-3.85-7.6-1.022-1.837-2.1-3.6-.58-.965-1.2-1.9m-118.05-1.35l-1.55-1.15q-1.362-.986-2.65-2-.068-.036-.15-.1l-1.85-1.4-.05-.05q-.04.11-.1.2-3.033 4.08-5.7 8.55-.84 1.43-1.65 2.9-1.508 2.707-2.9 5.55-.449.967-.9 1.95-2.06 4.453-3.85 9.25-.417 1.19-.85 2.4-.147.424-.3.85-.695 2.07-1.35 4.2-.872 2.84-1.65 5.8-.081.3-.15.6-.369 1.338-.7 2.7-.066.254-.15.5h.05q-.096.499-.25 1-.032.3-.1.6-.162.698-.35 1.4-.344 1.68-.7 3.4-.007.088-.05.15-.155.931-.35 1.85-.168 1.017-.35 2.05-.05.2-.05.4v.1q3-2.6 7.05-3.35.15-.05.3-.05l1.85-.25q8.103-.279 12.65 4.75l-2.8 11.75q-.529 2.099-1.05 4.2-.44 1.7-.9 3.4 0 .1-.05.2l-.9 3.95v.05h.05q-.3 2.5-.45 3.65l.05.05-1.15 10.65q16.7 2.55 31.05 1.25.3-.05.65-.05 4.3-.45 8.35-1.2l.1.05v-12.5h-.1v-30.6l.05 1.4q0-6.8-.5-12.15 0-.25-.05-.5-.05-.7-.15-1.35-1.35-11.25-5.9-22.45-.3-.7-.55-1.35-.356-.747-.7-1.5-9.45-3.707-18.05-9.6-.086-.071-.2-.15z"
                    id="ShiLon101__Layer9_1_FILL"
                />
                <path
                    fill="#6F592B"
                    d="M247.6 264.45v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35m-1.15-29.75q.221-.923.45-1.9l-.55.45q-.52.36-1.05.7-.11.793-.25 1.6-1.65 8.7-7.15 17.75l-11.75.15 9.35 7.25q-12.4 18.55-17 43.05v4.65q.6-1.9 1.05-3.75 6.8-29.2 18.05-44.1l-8.45-6.25 9.35.1q4.4-5.55 7.95-19.7m-93 64.55q-.65-.65-1.55-.65t-1.5.65q-.65.65-.65 1.55t.65 1.55l.3.3q.5.35 1.2.35.25 0 .5-.05.55-.1 1.05-.6.65-.65.65-1.55t-.65-1.55m-.35 9.15q-.55-.4-1.2-.4-.45 0-.8.15-.4.15-.7.5-.65.65-.65 1.55t.65 1.55q.6.65 1.5.65t1.55-.65q.65-.65.65-1.55t-.65-1.55q-.15-.15-.35-.25m-2.7-19.25q-.65.65-.65 1.55 0 .25.05.5.1.35.3.65.1.2.3.4.4.45.95.6.1 0 .25.05h.3q.25 0 .5-.05.6-.15 1.05-.6.65-.65.65-1.55t-.65-1.55q-.5-.5-1.1-.6-.2-.05-.45-.05-.15 0-.3.05-.15 0-.25.05-.55.1-.95.55m1.95-10.6q-.2-.05-.45-.05-.15 0-.3.05-.15 0-.25.05-.55.1-.95.55-.65.65-.65 1.55 0 .25.05.5.1.35.3.65.1.2.3.4.4.45.95.6.1 0 .25.05h.3q.25 0 .5-.05.6-.15 1.05-.6.65-.65.65-1.55t-.65-1.55q-.5-.5-1.1-.6m-15.7-14.6q-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1m2-30.7l-1.45-1.1q.378 1.694.8 3.3 3.5 13.55 7.75 18.95l9.35-.1-8.45 6.25q11.25 14.9 18.05 44.1v-2.95q-3.2-21.95-15.95-41l9.35-7.25-11.75-.15q-5.35-8.7-7-17.15-.286-1.388-.5-2.75-.086-.071-.2-.15z"
                    id="ShiLon101__Layer9_2_FILL"
                />
                <path
                    fill="#9A7D3A"
                    d="M241.4 299v9.35h17.15l-.75-2.95h-14.6l-1.2-7-.6.6m11.1-70.4l-1.8 1.4h-.05q-.537 1.44-1.05 2.85-.3.8-.6 1.65-.55 1.75-1.05 3.55-1.2 4.35-1.7 8.65-.1.65-.15 1.3-.1 1.2-.2 2.45-.15 2.8.05 5.6.25 4.1 1.8 8.95l.9-.45q-1.4-3.55-1.6-6.35-.25-2.95-.15-5.9.1-3.5.6-7 .15-.75.25-1.5.4-2.15.95-4.3.45-1.85 1.05-3.65.65-2 1.4-3.95l.4-.9q.45-1.2.95-2.35v-.05M143.8 299l-.6-.6-1.2 7h-14.9q0 .1-.05.2l-.7 2.65h17.45V299m-11.2-70.3q1.115 2.506 2 4.95l.7 2.2q.6 1.8 1.05 3.65.55 2.15.95 4.3.1.75.25 1.5.5 3.5.6 7 .1 2.95-.15 5.9-.2 2.8-1.55 6.35l.85.5q1.55-4.9 1.8-9 .2-2.8.05-5.6-.1-1.25-.2-2.45-.05-.65-.15-1.3-.5-4.3-1.7-8.65-.5-1.8-1.05-3.55v-.1q-.742-2.102-1.6-4.3-.068-.036-.15-.1l-1.7-1.3z"
                    id="ShiLon101__Layer9_3_FILL"
                />
                <g id="ShiLon101__Layer9_0_FILL">
                    <path fill="#DFE8EC" d="M177.5 317.75h.05v-.05l-.05.05z" />
                    <path
                        fill="#1750A4"
                        d="M188.85 318.4h-.05l.05.05h.05l-.05-.05m29.65.8h-.05l.05.05v-.05m-27.45-38l.05-.05-.1-.1.05.15z"
                    />
                    <path
                        fill="#0C2852"
                        d="M190.2 317.95q-.2-.1-.35-.2 0 .047.05.1l.3.1z"
                    />
                    <path fill="#17468A" d="M218.5 319.25h-.05v.2h.05v-.2z" />
                    <path fill="#857257" d="M218.45 319.25v-.05h-.2v.05h.2z" />
                    <path fill="#715E4A" d="M218.45 319.2v.05h.05l-.05-.05z" />
                </g>
            </defs>
            <use xlinkHref="#ShiLon101__Layer18_0_FILL" />
            <use xlinkHref="#ShiLon101__Layer18_1_FILL" />
            <use
                xlinkHref="#ShiLon101__Symbol_541_0_Layer0_0_FILL"
                transform="translate(180.6 257.6)"
                id="ShiLon101__col1n"
            />
            <use
                xlinkHref="#ShiLon101__Symbol_503_0_Layer0_0_FILL"
                transform="translate(180.6 257.6)"
                id="ShiLon101__col1d"
            />
            <use
                xlinkHref="#ShiLon101__Symbol_444_0_Layer0_0_FILL"
                transform="translate(158.15 245.85)"
                id="ShiLon101__col2n"
            />
            <use
                xlinkHref="#ShiLon101__Symbol_412_0_Layer0_0_FILL"
                transform="translate(158.15 245.85)"
                id="ShiLon101__col2d"
            />
            <use
                xlinkHref="#ShiLon101__Symbol_390_0_Layer0_0_FILL"
                transform="translate(189.4 288.5)"
                id="ShiLon101__col2s"
            />
            <use xlinkHref="#ShiLon101__Layer9_0_FILL" />
            <use xlinkHref="#ShiLon101__Layer9_1_FILL" />
            <use xlinkHref="#ShiLon101__Layer9_2_FILL" />
            <use xlinkHref="#ShiLon101__Layer9_3_FILL" />
        </g></g>
    )
}

export default ShiLon101
