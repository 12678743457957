import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#F3CCB4",
    "col1n": "#E9AF8B",
    "col1s": "#BB7E59",
    "col2n": "#773900"
}

function ShiDre060({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.55.75q-.1-.15-.2-.25-.05-.1-.1-.15-.1-.15-.2-.2-.05-.1-.1-.15-.7 1.15-1.35 2.3-5.45 8.75-10.9 14.95-2.25 2.55-4.55 4.65Q104.1 26.5 99 29q-1.75.85-3.45 1.45-.75.2-1.6.5-.9.25-1.95.55-.7.2-1.45.4-4.75 1.35-7.5 2.5-.45.15-.85.35-2.85 1.25-9.25 5.4-6.45 4.15-7.05 4.7-4.05-3.2-11.6-8.05-1.35-.9-2.55-1.6-.85-.55-1.75-.95-.7-.35-1.45-.65-7.35-2.65-14.7 0-1.65.65-3.3 1.6-2.35 1.3-4.85 3.1-8.85 6.35-13.6 6.35h-.45q-1.35-.05-1.75-.1-.5-.1-1.1-.25-.2-.15-.45-.2-.1 0-.2.05L7 49.95q-.1.6-.25 1.2l.2.1q-.05 0-.05.05-.3.35-.2.85l-.2 3.15q-.15 1-1 3.6-.45 1.4-.65 2.05H4.7q-.1.05-.15.1l-2.1 8.65q-.3 1.15-.55 2.3l-.25 1.25q0 .05.05.1-.05.05-.15.3-.1.2-.6 2.05-.5 1.85.35-.85L0 81.45l.65.2v-.05q.25.1.6.25 2.65 1.05 8 2.35.15.05.4.1l.7.2q13.2 3.55 23.45 6 .4.05.8.2 1.4.3 2.8.65.3.05.65.15 14.6 3.35 22.55 4.05.35 0 .65.05 2.15.15 3.8.1l.6-1.6.55 1.4q6.2.3 17.75-1.75.35-.05.75-.1 9.2-1.75 21.7-4.9.15-.05.35-.1 1.55-.4 3.25-.8.3-.1.65-.2 5.1-1.35 10.7-2.85l.1-.05q1.2-.45 2.3-.8.35-.15.75-.25 6.45-2.2 2.25-.7 5.25-1.55 5.2-1.75l.45-.1-6.25-24.3q-.5-2.4-1-4.75l-.3-1.4-2.15-11.15v-.25q0-.15-.15-.5l-.05-.1q-.35-.7-1.2-2.05-1.5-2.4-1.7-4.95-.05-2.55-.1-3.8v-.65q.8-1 1.6-1.95l.5-.7.25-.35q.1-.2.25-.35v.05q6.7-8.9 9.1-16.9-1.65-2.4-3.4-4.75-.6-.75-1.15-1.45l-.1-.1M128.5 67l-.15-.6h.05q.044.306.1.6z"
                    id="ShiDre060__Symbol_257_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M10.9 77.25q-.25-.05-.4-.1-5.35-1.3-8-2.35-.35-.15-.6-.25v.05l-.65-.2-1.25 6 2 .65q2.7 1 5.4 1.95l.2.05q.25.05.5.15 11 3.8 22.2 6.25l1 .2.6.1q1.2.25 2.45.5 12.5 2.45 25.3 3.35.3 0 .6.05 2.05.1 4.15.2l1.9-5.2q-1.65.05-3.8-.1-.3-.05-.65-.05-7.95-.7-22.55-4.05-.35-.1-.65-.15-1.4-.35-2.8-.65-.4-.15-.8-.2-10.25-2.45-23.45-6l-.7-.2m5.8-32.5q-3.65-.3-7.5-.5-.35-.05-.65-.05H8.1l-6.45 9.1 4.3.6h.2q.6.05 1.25.1 1.75.15 3.55.3l5.75-9.55M85.95 86.6q-.4.05-.75.1-11.55 2.05-17.75 1.75l2.2 5.4q8.9-.3 17.55-1.4l.8-.1q8.45-1.1 16.65-2.95 2.4-.55 4.8-1.15.15-.05.8-.25 1.2-.3 2.9-.75.25-.05.55-.15 6.9-1.95 13.6-4.4.4-.15.6-.2 3.15-1.25 6.2-2.45l1.05-.35-1.5-5.6-.45.1q.05.2-5.2 1.75 4.2-1.5-2.25.7-.4.1-.75.25-1.1.35-2.3.8l-.1.05q-5.6 1.5-10.7 2.85-.35.1-.65.2-1.7.4-3.25.8-.2.05-.35.1-12.5 3.15-21.7 4.9m-60.3-41.1q-2.55-.25-5.15-.45-1.85-.15-3.75-.3L14.5 54.6q3.65.35 7.3.75.35.05.75.1 5.7.6 11.4 1.45 5.8.8 11.55 1.8 5.65 1.2 11.45 2.45 2.3.45 4.45.9.65.15.8.3 3.4.6 6.3 1.2l1.3-12.45q-1.2.05-2.4.05-.65 0-1.7-.05-.4 0-.8-.05-2.2-.15-4.35-.45-.2-.05-.35-.05-.15 0-.25-.05-.45-.05-.9-.15l-.65-.15q-.45-.1-.85-.15-13.5-2.8-31.9-4.55M140.4 6.7L132.45 0q-2.4 8-9.1 16.9v-.05q-.15.15-.25.35l-.25.35-.5.7q-1.5 1.8-3.1 3.7-1.4 1.55-2.85 3.15l-1 1q-10.05 10-19.7 16.25l-3.55 2.15q-.5.3-1 .55-3.45 1.8-6.9 3.1-.4.1-.7.25-6.85 2.4-13.75 2.7l3.35 12.75q7.65-1.95 14.65-5.2.6-.25.8-.35 4.55-2.15 8.95-4.8.4-.25.8-.45.2-.1.35-.2l10.15-7.25q.7-.6 1.45-1.2l11.65-10.85q.8-.8 1.55-1.65.1-.15.25-.25l.2-.2q.1-.15.2-.25.5-.5.95-1 .45-.55.9-1.05.15-.15.65-.55 2.5-3.1 5.2-6.65-.3-.65-.65-1.4-3.45-7.65-3.45-8.55v-.9q1.85 1.5 4 4.35.45.6 2.35 3.3 2.7-4.2 6.3-12.05z"
                    id="ShiDre060__Symbol_227_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M55.1 71.7q-.3-.05-.65-.05-.35.8-.7 1.65-.7 1.6-1.5 3.45.3 0 .6.05.2-.5.4-.95.6-1.35 1.15-2.55.35-.85.7-1.6m24.4.9q-.6-1.55-.95-2.85-.4.05-.75.1.4 1.45 1.05 3.25l.2.6q.35.85.75 1.9l.8-.1q-.4-1.1-.9-2.4l-.2-.5m-75.3-12l-.7-.2Q2.05 62.65 0 66.15l.2.05q.25.05.5.15 2.3-3.7 3.5-5.75m22.75 12.8q.45.05.9.15.3-.4.55-.75.5-.75.95-1.4 1.45-2 2.55-3.8-.35-.1-.65-.15-2.3 3.4-2.35 3.45-.15.2-1.45 1.85-.25.3-.5.65m1.5-6.6q-.4-.15-.8-.2-.85 1.6-2.25 3.85-.6.95-1.4 2.2-.05.05-.1.15l.6.1q.1-.1.2-.25l.7-1.1q1.9-3 3.05-4.75M15.4 36.05l-1 2.45q.35.05.75.1 2.95-6.65 4-9.85-.5-.05-.9-.1-1 2.85-2.85 7.4m61.45-4.75q-.4.1-.7.25.85 2.05 2.6 5.85 1.1 2.4 1.65 4.4.6-.25.8-.35-.75-1.6-1.5-3.4-1.9-4.45-2.85-6.75m-24.3 2.35h-.15q-1.55 3.85-3.65 10.45l.8.2q.5-1.75 1.55-4.95 1-3.2 2.05-5.6-.2-.05-.35-.05-.15 0-.25-.05m5.75.6q-.4 0-.8-.05-1.05 2.9-2.4 7.3-.65 2.05-1.1 3.7.65.15.8.3.4-2.05 1.65-6.05 1.15-4.05 1.85-5.2m47.45 36.15q.25-.05.55-.15-1.05-3.8-1.8-6.5-.35.1-.65.2.4 1.45 1.1 3.8.45 1.45.8 2.65m-2.9.75q-.5-.55-1-2.3-.5-1.75-1.25-4.1-.2.05-.35.1.4 2.3 1.8 6.55.15-.05.8-.25m15.5-11.35q-.4.1-.75.25 1.35 3.4 2.3 5.8.4-.15.6-.2l-2.15-5.85M86.4 26.65l-.65.4q.25.55.55 1.15 1.35 2.95 3.4 7.6.2.45.45.85.4-.25.8-.45-2.05-3.8-3.85-8-.35-.75-.7-1.55m32.85-14.9q-.5-2-1.3-4.4-.35-.95-.7-2.25-.6-2.05-1.3-5.05V0q-.15.15-.25.35l-.25.35 3.15 11.6q.15-.15.65-.55z"
                    id="ShiDre060__Symbol_192_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M128.9.1q-.1-.05-.15-.1-6.95 1.8-13.2 3.35-10.95 2.7-19.9 4.6-19.3 4.1-29.2 4.6-10.75.45-33.9-4.6-8.7-1.9-19.15-4.6Q8.1 2 2.4.45 1.6.2.85 0 .7.1.6.2L.5.3Q.35.4.3.5q-.55.6-.1 1.2l.05.05L.5 2q2.2.7 4.35 1.35 38.1 11.7 60.2 13.05 19.3-.75 59.75-13.05 2.15-.65 4.4-1.35.05-.05.1-.15.15-.2.25-.4.25-.65-.35-1.15l-.3-.2z"
                    id="ShiDre060__Symbol_178_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiDre060__Symbol_257_0_Layer0_0_FILL"
                transform="translate(126.6 228.1)"
                id="ShiDre060__col1n"
            />
            <use
                xlinkHref="#ShiDre060__Symbol_227_0_Layer0_0_FILL"
                transform="translate(125.35 235.15)"
                id="ShiDre060__col1l"
            />
            <use
                xlinkHref="#ShiDre060__Symbol_192_0_Layer0_0_FILL"
                transform="translate(132.75 252)"
                id="ShiDre060__col1s"
            />
            <use
                xlinkHref="#ShiDre060__Symbol_178_0_Layer0_0_FILL"
                transform="translate(127.7 301.25)"
                id="ShiDre060__col2n"
            />
        </g></g>
    )
}

export default ShiDre060
