import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1l": "#BFBFBF",
    "col1n": "#999999",
    "col1s": "#434343"
}

function ObjMor006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M42.8 13.35q.2-3.75-1.9-6.6L37.25 1.8Q36 .85 34.7.5q-1.85-.6-3.3-.5-4.05.7-5.15 4.35l-5.05 27q-.6 3.05 1.35 6.15.05 0 .05.05-.35.45-.45 1.05L22 39.65 0 64.95l2.2 5-.05.05.85 1.35q1.55 1.65 4.95 1.8h.45q3.3 0 8.6-1l.15-.05q1-.25 2-.8.6-.35 1.2-.8l.3-.2q-.05-.35-.05-.6-1.2-2.4-.3-4.85 2.05-6.8 7.4-9.3.7-.4 1.5-.6 2.15-.6 4.75.35l2-1.55L38 51.5l14.35-4.25q.2-.25.4-.45.45-.7.35-1.4-.4-2.1-1.55-1.8l-10.5 2.1q.65-.7-1.3-2.9 1.2-.75 1.7-2.45l1.35-27m-14.15 24.5l.65.35-.65-.3v-.05z"
                    id="ObjMor006__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M31 34.95q-.05 1 .4 1.75.5.75 1.15.75.6 0 1.05-.75.45-.75.5-1.75-.05-1.05-.5-1.8-.45-.7-1.05-.7-.65 0-1.15.7-.45.75-.4 1.8M38.95 4.7q.13.087.3.25l-.15-.15.65.4-2.5-3.4Q36 .85 34.7.5q-1.85-.6-3.3-.5-4.05.7-5.15 4.35l-5.05 27q-.6 3.05 1.35 6.15.05 0 .05.05-.35.45-.45 1.05L22 39.65 0 64.95l1.95 4.5L21.75 49q5.35-5.4 10.55-1.35h.05q.5.2.9.6 1.85 1.5 2.7 5.5L38 51.5l-1.65.5q-2.35-1.9-.15-5.05l.1-.1.15-.15 2.75 1.05q1.2-1 1.6-2.4.35-1.45-1.6-2.25l-10.95-5.35q-.25-.55-.5-.8-1.1-1.55-.45-3.95l3.25-27.65Q30.9 3.7 33.1 3.2q.8-.2 1.85-.2.45 0 .9.1.45.05.9.25.2.05.4.15.9.4 1.8 1.2M25.3 36.2v-.05l.05.05h-.05z"
                    id="ObjMor006__Symbol_100_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M24.65 30q-2.65-2-.4-4.3l-.75.15q-2 2.9.5 4.35l.65-.2M2.45 17.35q-.35.05-.65.2-.25.1-.45.25-.1.05-.15.1l-.7.65q.05 0 .05.05-.35.45-.45 1.05l-.1.9.35 1.3q.2.4.55.75.45.45 1 .7l.2.1 15.35 5.8q1.25-.55 1.55-2.45.65-.7-1.3-2.9l-13.2-6.3q-.1-.05-.15-.05-.5-.25-1.05-.25H3.15q-.35 0-.7.1m-1.25 1.1q.1-.1.25-.2.65-.5 1.2-.55.35-.05.5 0 .2-.05.35 0 .4.05.7.2.2.05.35.15.25.15.5.4.85.85.85 1.95t-.85 1.9q-.8.85-1.9.85-.35 0-.65-.05-.3-.1-.55-.25-.4-.2-.75-.55-.4-.4-.6-.85-.2-.5-.2-1.05 0-.8.4-1.45.15-.25.4-.5m4.15 4.15q.95-.9.95-2.2 0-1.05-.6-1.85l.9.4v-.05l10.55 5.25q1.65 1.35 1.4 2.7-.2.95-1.4 1.95L3.6 23.55q1-.15 1.75-.95m3.05-6.55q0 1.3.55 2.2.6.95 1.4.95.8 0 1.35-.95.6-.9.6-2.2 0-1.3-.6-2.25-.55-.9-1.35-.9-.7 0-1.25.75-.1.05-.15.15-.55.95-.55 2.25m.95 1.7Q8.9 17 8.95 16q-.05-1.05.4-1.8.5-.7 1.15-.7.6 0 1.05.7.45.75.5 1.8-.05 1-.5 1.75t-1.05.75q-.65 0-1.15-.75M17.1 4.2L10.25 0 9.5 9.2l7.05 3.7.55-8.7z"
                    id="ObjMor006__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M22.35 40.75l.05.05-.35-1.3-.05.15-22 25.3.4.85 21.95-25.05M37.25 1.8Q36 .85 34.7.5q-1.85-.6-3.3-.5-4.05.7-5.15 4.35l-5.05 27q-.6 3.05 1.35 6.15l.7-.65q-1.6-2.65-1.05-5.25l5.1-27.25q1.25-3.2 4.4-3.8 2.45-.4 5.75 1.55l-.2-.3z"
                    id="ObjMor006__Symbol_51_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor006__Symbol_119_0_Layer0_0_FILL"
                transform="translate(240.9 229.5)"
                id="ObjMor006__col1n"
            />
            <use
                xlinkHref="#ObjMor006__Symbol_100_0_Layer0_0_FILL"
                transform="translate(240.9 229.5)"
                id="ObjMor006__col1l"
            />
            <use
                xlinkHref="#ObjMor006__Symbol_66_0_Layer0_0_FILL"
                transform="translate(262.95 248.45)"
                id="ObjMor006__col1d"
            />
            <use
                xlinkHref="#ObjMor006__Symbol_51_0_Layer0_0_FILL"
                transform="translate(240.9 229.5)"
                id="ObjMor006__col1s"
            />
        </g></g>
    )
}

export default ObjMor006
