import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A37831",
    "col1s": "#43341A",
    "col2n": "#BFBFBF"
}

function AccMor007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.45 43q.85-1.7.85-3.45 0-4.4-.85-6.15-.15-.35-.35-.6-.15-.2-.35-.4-4.3-3.45-5.35-4.25-2.35-1.8-3.85-5.05l-2.45-5.45Q30.05 11.25 27.25 7q-2.2-3.3-5.2-5.2-.8-.5-1.6-.9-.05-.05-.1-.05Q16.4-.7 10.5.5 7.45 3.95 0 10.7l.3.2q6.35 8.25 10.25 17.85-.05.05-.05.1 1.05 2.25 2.05 4.55l.1.2h.05q5.15 10.95 7.25 22.1l-.05.1q1.95 7.75 2.35 12.85-.2 3.65-1.05 6.7-.6 2.3-1.5 4.2 6.9 2 13.65-2 .8-.55 1.95-1.5 1.3-1.1 2.25-2.5t1.95-4.5q1.6-4.95 1.6-12.7 0-4.6-.85-7.65-.1-.4-.25-.75v-.1l.1-.1q3.1-2.3 4.35-4.75z"
                    id="AccMor007__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M23 9.05l.1-.1q.1-.1.15-.05.1.1.2.25 2.15 3.75 4.15 7.7.6 1.2 1.2 2.45.65-1.5.95-5.75.25-4.25-.7-6.7-1-2.5-1.6-3.6-3-4.6-8-2.65L18 1.4q-4.15 2.15-8.9 6.35l-.05-.05q-.1.1-.15.2-.1.15-.2.35-1.65 1.8-3.7 4.95-2.6 3.9-3.9 7.05-2.2 5.45 0 11.3.8 1.4 2.4 4.15 1.15 1.95 3 4.1.4.6.65.95Q5 45.85 5.4 50.4q.25 6.1 4.45 6.85.1 0 .2.05 1.15.1 2.4.1 2.8-.15 5.8-1.25l-.5-2.3Q11 55.95 9.5 53.2q-.05-.05-.05-.15-.25-.75-.35-1.45-.5-2.45.1-4.9.25-1.15.75-2.25l.1-.3 4.6 4.9.15.15 2.55 2.75-1.15-6.05-4.2-5.25-.05-.05.2-.25q.3-.55.6-.8.75-.95 3.7-2.9l.6-.4q1.5-.85 2.95-1.55.15-.1.35-.15Q25.5 32 31 31.9h.35q.6 0 1.25.1.75.15 1.5.35 1.15 3.05.9 2.6 1.15 3.8 2.1 7.15.6 2.15 1.1 4.15l.6-3.85q.1-.3.15-.55 0-.05.05-.1l.8-5.15h.05l.15-1.2v-.05-.85-.25q.4-3.45-.95-6.65-1.55-3.1-6.85-3.5-.3-.05-.55-.05h-.45q-.15-.05-.25 0-.25-.05-.45-.05-.8.05-1.55.15-.25.05-.35.1-5.6.65-10.1 3.5v-.05q-2.8 1.65-5.4 4-.7.75-1.3 1.55-1.25 1.35-2.25 2.85l-.3.45-.2.4-.2-.3Q5.8 32.15 6.2 26.8q.35-3.15 1.5-5.7 1.75-3.85 5.35-6.4l-.1.1 9.6-5.5q.4-.25.45-.25z"
                    id="AccMor007__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M32.65 26.75q-.2-.15-.45-.3-1.3-.75-2.8-1.2 1.15 3.05.9 2.6 1.15 3.8 2.1 7.15l.1-.7v-.05l1-5.8q0-.7-.35-1.2-.2-.3-.5-.5M4.4 44.5q-.05-.15-.05-.25-.4-2.35.15-4.65-.45 1-.65 1.7-.25 1-.25 2.75v.25q0 2.6.6 3.75.85 1.65 3.55 2.25 2.8-.15 5.8-1.25l-.55-2.8q-7.15 2.7-8.45-.95-.1-.4-.15-.8m2.9-10.95q-.3.5-.7 1.1-.868 1.39-1.25 2.4l4.6 4.9.15.15 2.7 3.1-1.3-6.4-4.2-5.25M1.5 19.7Q1.85 16.55 3 14q-.7 1.15-1.3 2.5Q0 20.2 0 23.3q0 3.7.35 5.35.5 2.55 2.3 4.85.65-1.95 1.7-3.65l-.2-.3Q1.1 25.05 1.5 19.7M20.95.25q-.2.05-.35.15l-1.95 1.25-.1.15q.1.1.2.25 2.15 3.75 4.15 7.7l.1-.3q.05-.2.15-.45v-.1q.95-3.9-.05-7.7-.05-.2-.1-.35-.45-1.3-2.05-.6m32.9 31.9q-.2-.9-.4-.75-.25.1-1.95 1.15-1.75 1.05-2.55 1.5-.8.4-1.95.8-.65.2-2.1.15h-.35q-3.05-.15-4.5-2.05l-.45-.5q-.4-.35-.7-.65-.8-.85-1.45-1.4-.05-.1-.15-.15l-2.05-2-.15 1.2L39.5 34q1.45 1.85 4.35 1.95.5.05 1.05.05 2.4.25 4-.7l.35-.2q.25-.2.5-.35l4.1-2.6z"
                    id="AccMor007__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M14.15 8.55V8.4q-.05-.4-.3-.8l-3.8-6.55Q9.85.7 9.6.45l-.3-.2h-.4V.1q-.65-.25-1.5.05L.65 4.05q-1.15 1.1-.3 2.2L4.6 13.6q.7 1 2.3.1l6.05-3.5q1.15-.7 1.2-1.65m-8.3 3.25l-3.45-6 5.85-3.4 3.45 6-5.85 3.4m14.7 11.4q-.15-.4-.3-.7-.65-.9-2.05-.85l-7.55 2.05q-1.4.75-.85 2.05l2.25 8.2q.35 1.1 2.15.65l6.75-1.8q1.3-.35 1.6-1.25 0-.05.05-.15.05-.2 0-.45t-.1-.45l-1.95-7.25v-.05m-6.85 9.3l-1.8-6.7 6.55-1.75 1.8 6.7-6.55 1.75m27.5 15.65v-6q-1.15-.05-2.2-.2v7.4q.05 1.85 2.1 1.3l4.35-1.4q1.45-.7 1.5-2.25v-6.7l-.9.6q-.25.15-.5.35l-.35.2v5.6l-4 1.1z"
                    id="AccMor007__Symbol_16_0_Layer0_0_FILL"
                />
                <path
                    id="AccMor007__Symbol_63_0_Layer0_0_1_STROKES"
                    stroke="#F3C"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M9.55 36.1l-.3.45"
                />
            </defs>
            <use
                xlinkHref="#AccMor007__Symbol_105_0_Layer0_0_FILL"
                transform="translate(252.5 217.85)"
                id="AccMor007__col1n"
            />
            <g transform="translate(234.2 227.4)" id="AccMor007__col1d">
                <use xlinkHref="#AccMor007__Symbol_63_0_Layer0_0_FILL" />
                <use xlinkHref="#AccMor007__Symbol_63_0_Layer0_0_1_STROKES" />
            </g>
            <use
                xlinkHref="#AccMor007__Symbol_38_0_Layer0_0_FILL"
                transform="translate(238.9 234.5)"
                id="AccMor007__col1s"
            />
            <use
                xlinkHref="#AccMor007__Symbol_16_0_Layer0_0_FILL"
                transform="translate(242.6 228.35)"
                id="AccMor007__col2n"
            />
        </g></g>
    )
}

export default AccMor007
