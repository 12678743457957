import { AssetSvgProps } from "../../../shared/assets"

function PlaInd026({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#B38602"
                    d="M385 385V235.5q-192.377-36.57-303.35-15.8-67.924 12.712-76.9 15.8H0V385h385z"
                    id="PlaInd026__Layer7_0_FILL"
                />
                <path
                    fill="#AA58AB"
                    d="M385 235.5V0H0v235.5h17.15v.05q1.514.052 3 .05 4.515.116 9 .15.015-.113 0-.25H385z"
                    id="PlaInd026__Layer7_1_FILL"
                />
                <path
                    d="M0 237.7v1.65h5.35v36.5H0v1.6h6.2q.337.031.55-.2.256-.25.25-.6V238.5q.004-.309-.25-.55-.213-.231-.55-.25H0m7.4-45.4q.006-.337-.25-.6-.213-.206-.55-.2H0v1.65h5.8v36.45H0v1.65h6.6q.337-.006.55-.25.256-.226.25-.55V192.3z"
                    id="PlaInd026__Layer4_0_FILL"
                />
                <g id="PlaInd026__Layer6_0_FILL">
                    <path
                        fill="#783D01"
                        d="M17.15 179.3H0v105.35h17.15V179.3z"
                    />
                    <path
                        fill="#2C5F3A"
                        d="M385 71.45q-10.106-1.078-23.15-.85-24.657 1.108-47.85 10.95-19.305 7.148-41.8 0-23.98-7.457-38.95-8.9-28.97-1.61-56.75 12.55-7.127 3.232-14.95 3.95-12.521 1.225-26.8-3.95-14.731-6.845-31.25-11.1-2.983-.755-6.05-1.45Q58.302 61.49 37.4 68.6q-19.527 6.221-23.5 38.9-.655 21.29 11.55 51.55 1.076 2.602 2.2 5.25.77 1.759 1.45 3.5.036.05.05.05 1.835 4.676 3.35 9.4 4.212 13.158 5.95 26.9h.2q.128 4.412.5 8.75.094 1.214.2 2.4h-.05l.1.35q-1.329 1.295-2.55 2.7-1.243 1.469-2.4 3.05-.46.665-.95 1.35-.457.734-.65 1.5h-.35q-.077.174-.15.3-.677 1.217-1.2 2.45-.226.568-.45 1.1-.37 1.137-.65 2.3-.423 1.652-.7 3.25-.066.754-.15 1.45-.014.349-.05.65-.717 9.68 4.8 18.35.402.632.85 1.1.37.44.75.75 0-.362-.05-.75h26.25v-.25h8.85v-.2h10.2v.45h301.25v-.2H385V71.45M149.8 241.6l-.1-.35.2.3q-.025.039-.1.05z"
                    />
                </g>
                <g id="PlaInd026__Layer5_0_FILL">
                    <path
                        fill="#630"
                        d="M70.6 254.95h-8.85v30.75H80.8v-30.95H70.6v.2m311.45.05v.2h-8.85v30.75H385V255h-2.95z"
                    />
                    <path
                        fill="red"
                        d="M32.5 224.25q-.002.222 0 .4.166-.195.35-.4h-.35m6.9-8.6q-.023-.173-.05-.35h-.05l.1.35m-.75-11.55q-.1.05-.2.05h.2v-.05z"
                    />
                    <path
                        fill="#D80137"
                        d="M149.8 241.6q.075-.011.1-.05l-.2-.3.1.35z"
                    />
                    <path d="M139.7 161.4q-.05-.05-.15 0-.05 0-.1.1-.05.05 0 .15l.1.1q.15.05.35.2 3.5 1.65 6.05 3.55 4.3 3.4 6 7.95.05.1.1.15h.2l.1-.1q.05-.1.05-.15-2.85-7.65-12.7-11.95m-4.95-6.75q-.35-.4-.75-.6-.95-.55-2.1-.55-1.15 0-2.05.55-.45.2-.8.6-1.2 1.2-1.2 2.85 0 1.7 1.2 2.85 1.2 1.15 2.85 1.2 1.65-.05 2.85-1.2 1.15-1.15 1.15-2.85 0-1.65-1.15-2.85m-2.85-.7q.8 0 1.55.3.55.25 1 .75 1.05 1.05 1.05 2.5t-1.05 2.5q-1.05 1.05-2.55 1.1-1.45-.05-2.55-1.1-1-1.05-1-2.5t1-2.5q.5-.5 1.1-.75.65-.3 1.45-.3m1.95.25q-.338-.16-.75-.25.207.036.4.1.192.072.35.15M117 177.35q0 .15.05.2.05.05.2.1.05 0 .15-.05.05-.05.05-.1 1.45-8.9 8.5-14.25.1-.05.1-.15 0-.1-.05-.2-.05-.05-.1-.05-.1-.05-.2 0v.05q-7.2 5.5-8.7 14.45m-5.4-33.7q-.05.05-.05.15 0 .05.05.15.05.05.15.05 9.65.2 14.25 8.1.05.05.05.15l.05.05q0 .05.05.05h.1q0-.05.1-.05v-.15q.05-.05.05-.1v-.15q-4.7-8.15-14.65-8.35-.1-.05-.15.1m36.65-4.85v-.2q-.05-.05-.15-.05-.1-.05-.15 0-.1.05-.1.15-2.05 9.5-9.8 13.75-.1.05-.1.15-.05.05 0 .2.05.05.2.1h.15q7.85-4.35 9.95-14.1m160.25 24.45q.1-.05.1-.15 0-.1-.05-.2-.05-.05-.1-.05-.1-.05-.2 0v.05q-7.2 5.5-8.7 14.45 0 .15.05.2.05.05.2.1.05 0 .15-.05.05-.05.05-.1 1.45-8.9 8.5-14.25m8.8-8.6q-.35-.4-.75-.6-.95-.55-2.1-.55-1.15 0-2.05.55-.45.2-.8.6-1.2 1.2-1.2 2.85 0 1.7 1.2 2.85 1.2 1.15 2.85 1.2 1.65-.05 2.85-1.2 1.15-1.15 1.15-2.85 0-1.65-1.15-2.85m-1.6-.7q.163.038.35.1.213.08.4.2-.362-.201-.75-.3m-1.25 0q.8 0 1.55.3.55.25 1 .75 1.05 1.05 1.05 2.5T317 160q-1.05 1.05-2.55 1.1-1.45-.05-2.55-1.1-1-1.05-1-2.5t1-2.5q.5-.5 1.1-.75.65-.3 1.45-.3m-20.3-10.3q-.05.05-.05.15 0 .05.05.15.05.05.15.05 9.65.2 14.25 8.1.05.05.05.15l.05.05q0 .05.05.05h.1q0-.05.1-.05v-.15q.05-.05.05-.1v-.15q-4.7-8.15-14.65-8.35-.1-.05-.15.1m40.45 29.95h.2l.1-.1q.05-.1.05-.15-2.85-7.65-12.7-11.95-.05-.05-.15 0-.05 0-.1.1-.05.05 0 .15l.1.1q.15.05.35.2 3.5 1.65 6.05 3.55 4.3 3.4 6 7.95.05.1.1.15m-3.8-34.8v-.2q-.05-.05-.15-.05-.1-.05-.15 0-.1.05-.1.15-2.05 9.5-9.8 13.75-.1.05-.1.15-.05.05 0 .2.05.05.2.1h.15q7.85-4.35 9.95-14.1z" />
                    <path
                        fill="#C553C6"
                        d="M29.15 167.85q-.014 0-.05-.05l-.1.05q.073.026.15 0z"
                    />
                    <path
                        fill="#653E10"
                        d="M369.35 220.65q-.023.038-.05.05h.05v-.05z"
                    />
                    <path
                        fill="#1E3C1E"
                        d="M369.35 220.7h4.1q.065-.012.1-.05H385v-1.4H216.65v.05l-75.6.05q0-.05.05-.1H42.15l-1.1.8h.8l-.8.55h64.6v.05h12.9v.05h5.8v-.05l79 .05q.847.013 1.7 0h1.7l10.35-.1.05.05h29.2v-.05h89.45l.05.1h33.45q.027-.012.05-.05v.05z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaInd026__Layer7_0_FILL" />
            <use xlinkHref="#PlaInd026__Layer7_1_FILL" />
            <use xlinkHref="#PlaInd026__Layer6_0_FILL" />
            <use xlinkHref="#PlaInd026__Layer5_0_FILL" />
            <use xlinkHref="#PlaInd026__Layer4_0_FILL" />
        </g></g>
    )
}

export default PlaInd026
