import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}
function FacMou092({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M26.45.1Q14.95 7 1.2 2.75q-.45-.1-.75.1-.3.1-.4.55-.15.35.05.7.15.3.5.5 14.65 4.45 26.8-2.85.35-.2.45-.55.1-.45-.1-.75-.2-.35-.55-.4-.45-.15-.75.05z"
                    id="FacMou092__Symbol_165_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M17.4 2.4l5.05 5.8 1.1-8.45Q19.157 1.987 17.4 2.4z"
                    id="FacMou092__Kopie_von_Symbol_90_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou092__Symbol_165_0_Layer0_0_FILL"
                transform="translate(178.6 222.6)"
                id="FacMou092__col2n"
            />
            <use
                xlinkHref="#FacMou092__Kopie_von_Symbol_90_0_Layer0_0_FILL"
                transform="translate(181.75 225)"
                id="FacMou092__col1n"
            />
        </g></g>
    )
}
export default FacMou092
