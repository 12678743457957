import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#121212",
    "col2n": "#000000"
}

function ShiSho021({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.65 47.15q-.65-3.1-1.3-6.15l-2.85-14.75h.05L117.75 0Q65.25 26.3 15.9 0l-5.55 25.95-.1.25v.05h.1l-.05.25v.05l-.1.5v.1q-.1 0-.15.05L7.5 40.25q-.55 2.7-1.1 5.45L2.95 60Q2 63.7 1.1 67.4l-.25 1v.05l-.8 2.95v.25L0 71.7h.2q.25.1.5.15H.85q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25z"
                    id="ShiSho021__Symbol_29_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M42.45 8.4q4.1.4 7.9.6.5 0 1.05.05-8.05-.95-16.75-2.35-2.95-.45-6-1Q16.8 3.65 3.8.8 2.75.55 1.7.35 1.25.2.75.1L.35 0 .1 1v.05l-.1.3.45.1q.45.1.85.2.85.15 1.7.35 4.75 1 9.3 1.9 16.85 3.2 30.15 4.5M129.6.25q-1.25.3-2.5.55-16.5 3.65-31.5 5.9-1.75.25-3.4.5-6.25.9-12.25 1.55-.5.05-.95.1h.95q13.95-.65 32.1-3.75 7.3-1.3 15.3-2.95l2.9-.6q.3-.1.6-.15.15-.05.35-.1l-.3-1.3q-.2 0-.4.05l-.9.2z"
                    id="ShiSho021__Symbol_55_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M146.5 19.1q-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2-.6-.95-1.15-1.9-.95-1.45-1.95-2.85-1.1-1.6-2.25-3.15l-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65q-6.55 4.5-13.55 7.8-15 6.9-32.3 8.2l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-17.2-1.3-32.05-8.1-7.15-3.3-13.8-7.9L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-1.1 1.45-2.1 3-1.9 2.7-3.6 5.55-.3.5-.6 1.05-.55.9-1.05 1.85-2 3.65-3.8 7.5-1.2 2.55-2.25 5.25l-.1.25q-.1.15-.15.35-.1.15-.15.3-.4 1.05-.8 2.15-.2.45-.35.95l19 7.05 2.2-6.1q.1-.3.2-.55 3.2-9.85 7.6-12.9.1-.15.25-.2.55-.35 1.1-.6l.7-.35q13.3 6.55 28.45 8.7 7.6 1.1 15.65 1.1 23.55 0 43.3-9.4.4-.2.8-.35l.2.1v.05q.3.15.6.35 6.6 3.85 9.7 14.8l1.55 5.4 19.05-7.1q-.2-.5-.35-.95l-.8-2.1q-.05 0-.05-.05l-.15-.45q-1.2-2.9-2.5-5.7z"
                    id="ShiSho021__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M.15.65Q.05.8 0 1h.1l18.15 6.7Q10.7 3.55 2.05.5 1.75.4 1.5.35 1 .15.55 0H.4Q.3.2.25.4l-.1.25m145.5.25q-8.05 2.9-15.1 6.8l17.35-6.35q-.05 0-.05-.05l-.15-.45q-.15-.3-.25-.55-.3.05-.55.15-.55.2-1.05.4-.1 0-.2.05z"
                    id="ShiSho021__Symbol_97_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho021__Symbol_29_0_Layer0_0_FILL"
                transform="translate(126.1 237.8)"
                id="ShiSho021__col1n"
            />
            <use
                xlinkHref="#ShiSho021__Symbol_55_0_Layer0_0_FILL"
                transform="translate(126.85 305.2)"
                id="ShiSho021__col1d"
            />
            <use
                xlinkHref="#ShiSho021__Symbol_77_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho021__col2n"
            />
            <use
                xlinkHref="#ShiSho021__Symbol_97_0_Layer0_0_FILL"
                transform="translate(118.65 252.55)"
                id="ShiSho021__col2d"
            />
        </g></g>
    )
}

export default ShiSho021
