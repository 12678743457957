import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#004D1E",
    "col1n": "#007E31",
    "col2d": "#3C1B14",
    "col2n": "#5D342C"
}
function BodTai016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M113.65 45.95q-6.7-1.85-10.8-3.15-4.15-1.3-12.25-4.35-8.15-3.05-22.35-9.7-6.6-3.15-12.4-6.8-5.65-3.6-10.5-7.7-.95-.8-1.85-1.6Q36.15 6.1 32.65 2.9 29.95.9 26.7.3 12.5-1.55 3.25 8.55q-1.95 3.1-2.75 6-1.75 6.55 2.75 12.15 1.85 1.95 4.65 3.95.55.4 1.2.85 40.3 21.55 85.8 25.2 23.4 1.75 43.7-2.95 3.8-.7 6.45-1.15.3-.05.4-.1.85-.6-9.35-2.05-11.65-1.7-13.7-2.15-2.1-.5-8.75-2.35z"
                    id="BodTai016__Symbol_52_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M145.05 38.05q.3-.05.4-.1-82.7 5.85-139.55-29Q-.05 4.85.5 0q-1.75 6.55 2.75 12.15Q5.1 14.1 7.9 16.1q.55.4 1.2.85 40.3 21.55 85.8 25.2 23.4 1.75 43.7-2.95 3.8-.7 6.45-1.15z"
                    id="BodTai016__Symbol_53_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10.45 16.65q2.3-1.1 3.7-3.8 1.45-2.8 1.45-8.05L15.35 0h-.6q-5.4 1.45-9.3 3.6Q.25 6.4 0 9.5l3.6 5 6.85 2.15m20.4 3.75q0-3.35-1.2-8.25-.05-.1-.05-.15-4.85 1.8-7.3 3.85-3.6 2.95-3.8 7.15l3.65 3.5 6.8 1.8q1.9-2.95 1.9-7.9m41.2 20.05q-2.05 2.2-2.5 4.4l3.1 2.35 5.35-.15q.25-.95.25-1.7 0-2.85-3.25-7.5-.15.1-.35.25-1.4 1.05-2.6 2.35M55.3 34.4q-2.1 2.3-2.85 5l3.5 2.8 5 .1q.3-1.1.3-2.75 0-2.85-2.65-8.05-.05.05-.15.2-.65.6-3.15 2.7M44 35.75q2-2.9 2-5.15 0-1.9-.5-4.75-.4-2-1-2.65-6.25 3.15-8.05 9.5l2.75 3.35 4.65-.05q.05-.15.15-.25m45.35 7.1q-1.4 1-2.55 2.25-1.65 1.75-1.85 3.5l2.85 1.75 4.55-.3q-.35-3.1-2.65-6.7-.2-.25-.35-.5m12.75 4.5q-.05.05-.1.15-2.25 1.4-2.65 3.75L102 52.4l2.85-.35q-.25-1.6-.35-1.75-.45-1.15-2.4-2.95z"
                    id="BodTai016__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M15.3 0h-.55q-5.4 1.45-9.3 3.6Q.25 6.4 0 9.5l3.6 5q1-4 2.8-6.25Q8.15 6 10.45 3.8T14.1.85q.5-.4 1.2-.85m14.35 12.15q-.05-.1-.05-.15-4.85 1.8-7.3 3.85-3.6 2.95-3.8 7.15l3.65 3.5q1.3-4.05 2.55-6.45 1.2-2.45 1.75-3.4.55-.95 2.95-4.2.1-.15.25-.3m13.2 14.1q.95-1.9 1.65-3.05-6.25 3.15-8.05 9.5l2.75 3.35q2.15-6.45 3.65-9.8m29.8 20.95q.6-5 1.5-7.75.25-.65.5-1.35-1.4 1.05-2.6 2.35-2.05 2.2-2.5 4.4l3.1 2.35M55.3 34.4q-2.1 2.3-2.85 5l3.5 2.8q.95-5.85 1.95-9.05.25-.75.55-1.45-.65.6-3.15 2.7m44.05 16.85L102 52.4v-4.9q-2.25 1.4-2.65 3.75m-10-8.4q-1.4 1-2.55 2.25-1.65 1.75-1.85 3.5l2.85 1.75q.5-3.75 1-5.1.5-1.05.9-1.9-.2-.25-.35-.5z"
                    id="BodTai016__Symbol_55_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodTai016__Symbol_52_0_Layer0_0_FILL"
                transform="translate(222.65 278.75)"
                id="BodTai016__col1n"
            />
            <use
                xlinkHref="#BodTai016__Symbol_53_0_Layer0_0_FILL"
                transform="translate(222.65 293.3)"
                id="BodTai016__col1d"
            />
            <use
                xlinkHref="#BodTai016__Symbol_54_0_Layer0_0_FILL"
                transform="translate(252.05 278.4)"
                id="BodTai016__col2n"
            />
            <use
                xlinkHref="#BodTai016__Symbol_55_0_Layer0_0_FILL"
                transform="translate(252.05 278.4)"
                id="BodTai016__col2d"
            />
        </g></g>
    )
}
export default BodTai016
