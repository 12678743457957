import { AssetSvgProps } from "../../../shared/assets"

function ObjFoo004({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#BBB"
                    d="M129.85 266q2.107-4.129.25-9.05h.3l21.3-54.7q-39.875-3.883-79.75 0l-20.6 54.7q-2.127 1.038-3.05 1.85-1.726-1.801-4.4-2.1.42 4.65 5.95 9.3l22.2 34.65h90.45L129.85 266z"
                    id="ObjFoo004__Layer7_0_FILL"
                />
                <path
                    fill="#A2A2A2"
                    d="M130.4 256.95l21.3-54.7q-39.875-3.883-79.75 0l-20.6 54.7h79.05M95.3 266q-5.037.537-8.6 0H49.8l22.25 34.65h90.45L129.85 266H95.3z"
                    id="ObjFoo004__Layer7_1_FILL"
                />
                <path
                    fill="#D5D5D5"
                    d="M130.1 256.95l-.25 9.05 32.65 34.65H72.05L43.9 256.7l-.1.1v9.3l27.85 47.4q56.27-11.854 103.65 0v-12.85l-45.2-43.7m0 0l6.35 3.55 23-58.25-6.2-7.35q-43.6 7.669-87.2 0L43.9 256.7l6.15 4.7 1.3-4.45 20.6-54.7q39.875-3.883 79.75 0l-21.3 54.7h-.3z"
                    id="ObjFoo004__Layer7_2_FILL"
                />
                <path
                    fill="#FBC06A"
                    d="M117.7 264.8q-1.5-.4-2.95-.75-.5-.05-.9-.2-1-.2-2.05-.45-7.55-1.3-15.95-1.3-8.45 0-15.25 1.3-1.35.3-2.55.65-1.35.35-2.6.75-1.8.5-3.4 1.2-1.95.8-3.7 1.85-10.4 5.75-8.05 14 .05.5.2.9l8.9 13.8q2.3 1.6 5.35 3.05.6.25 1.2.55.6.25 1.15.5h59.25q1.1-.5 2.05-1.2.5-.35.95-.65 4.1-3.1 4.9-6.85.55-2.4-.2-5-.3-1.3-1.3-4.25 0-.5-.1-.85-.65-2.15-1.95-4.05-.5-.65-1.1-1.4l-1.2-1.3q-2.35-2.4-6-4.45-.75-.4-1.5-.75-2-1.05-4.3-2.05-1.4-.7-3.05-1.15-.85-.35-1.75-.7-2-.7-4.1-1.2z"
                    id="ObjFoo004__Layer7_3_FILL"
                />
                <path
                    fill="#FFF"
                    d="M71.65 300.65v12.85H175.3v-12.85H71.65m81.6-105.75h-87.2l5.9 7.35h87.5l-6.2-7.35z"
                    id="ObjFoo004__Layer7_4_FILL"
                />
                <g id="ObjFoo004__Layer7_5_FILL">
                    <path
                        fill="#FADB96"
                        d="M70.65 293.15q-1.75-1.05-3.25-2.05-2-1.45-3.45-3l5.45 8.45q2.3 1.6 5.35 3.05.6.25 1.2.55.6.25 1.15.5h17.15q-3.3-.45-6.55-1.2-1.5-.35-2.95-.65-4.4-1.3-8.45-3-.2-.05-.35-.1-2.9-1.3-5.3-2.55m64.25 2.55q-.25.05-.3.1-3.15 1.7-6.85 3-1.3.3-2.5.65-2.85.75-5.95 1.2h17.05q1.1-.5 2.05-1.2.5-.35.95-.65 4.1-3.1 4.9-6.85.55-2.4-.2-5-.3-1.3-1.3-4.25.35 2.95 0 4.15-1.15 5-7.85 8.85z"
                    />
                    <path
                        fill="red"
                        d="M120.95 269.65q-14.55-4.35-26.2-4.2-14.4.65-22.5 4.25-5.25 2.5-7 5.7-1.15 1.2-1.15 3 0 6.6 13 12.5 13.9 6.3 31.5 6.3 15.9 0 23.55-4.25.5-.35 1-.65.4-.15.75-.35 2.2-1.35 3.45-2.9V289q0-.05.75-1.7.75-1.7.75-3.1 0-5.7-8.5-10.55-4.7-2.05-6.85-3.15-1.2-.45-2.55-.85z"
                    />
                </g>
                <g id="ObjFoo004__Layer7_6_FILL">
                    <path
                        fill="#FBEF8C"
                        d="M70.75 282.65q-.2-.25-.55-.8-.35-.4-1-.4-.85 0-.95 1 0 1.9 3.05 3.6 2.55 1.4 3.85 1.4 1.35 0 1.35-1 0-.5-.3-.6-.25-.15-1.75-.5-1.1-.4-2.15-1.25l-1.55-1.45m60.7 6.7q-.75.65-2.05 1.2-.7.3-.7 1.1 0 .95 1.1.95.8 0 2.35-.9 1.2-.7 1.5-1 .8-.9.8-2.35 0-1.65-1-1.65-.8 0-.95 1.3-.3.65-1.05 1.35M110.2 289q.25-.1.75-.35.75-.4.85-.45.45-.3.45-.95 0-.35-.1-.45l-.25-.2-.15-.05h-.5q-1.05 0-2.25.9-.6.45-1.15 1-1.2 1-1.2 2.1 0 .75.65 2 .9 1.65 2.15 1.65.75 0 .9-.35.05-.1.05-.55 0-.55-.55-1l-.3-.2q-.1-.1-.2-.25-.5-.7-.5-1.05 0-.95 1.1-1.65.15-.1.25-.15m9.95-7.15q.4 0 .95-.4.55-.4.85-.4.55 0 .85.8.15.35.2.75 0 .3-.3 1.95.35 3.15 2.25 3.15.95 0 1.3-.65l.2-.65q0-.55-.9-1.45-.85-.95-.85-1.9l.25-1.5q.05-.55 0-.8-.05-.35-.5-.85-.5-.6-1.05-.85-.35-.2-.7-.25l-.4-.1h-.65q-1.5 0-2.25 1.05-.45.6-.45 1.1 0 1 1.2 1m-1.5-9.05q0 .9 1.3 1.55.8.4 1.9.6.55.15 1.1.15H123.9q1.55 0 2.1-.5.3-.3.3-.9 0-.3-.2-.6-.25-.4-.6-.4-.15 0-.6.25-.5.2-1.35.2-.7 0-2.1-.7-1.45-.7-1.7-.7-1.1-.25-1.1 1.05M96.5 288.15q.4.55.4.8 0 .15-1.95 1.1Q93 291 93 292.6q0 .55.45 1.35.65 1.1 1.8 1.1.6 0 .8-.35.05-.05.05-.1.1-.2.1-.4 0-.35-.65-.7-.6-.35-.6-.75 0-.45 2.05-1.1 1.9-.5 1.9-2.25 0-.75-.9-1.6-.1-.1-.25-.15-.35-.85-1-.85-.5 0-.6.6 0 .2.35.75M83.35 275.7q-.35.1-.7.1-1.05 0-1.65-1-.3-.5-.45-1.2-.3-.85-.7-1.1-.5-.4-.9-.45l-.7-.25h-.8q-1.9 0-3.25.85-1.1.7-1.1 1.35 0 .8 1.15.8.6 0 .95-.15.2-.15.35-.2 1.05-.15 1.15-.25h1.4q.25.35.45.65.25.55.7 1.6.6 1.65 3.2 1.65 1.7 0 2.25-1.05.1-.15.15-.3.05-.45.05-.5 0-.7-.85-.7-.3 0-.7.15m10.6 3.8q.35-.5.35-.7 0-.6-.75-.6-.45 0-1.25.7-.6.5-.85.8-.35.55-.4.9-.05.2-.05.85 0 1.1.5 2.15.55 1.1 1.35 1.25.15.05.3.05.6 0 .6-.65v-.05q0-.05-.1-.25-.15-.35-.4-.9-.45-1-.45-1.4 0-.35.5-1.2.65-.85.65-.95m11.2-8.3q0-.8-.35-1.8-.5-1.6-1.55-1.6-.65 0-.6.8 0 .45.5 2.55 0 .6-.2 1-.1.2-.15.5-.7.6-2.25.8-.5.05-.85.15-.05 0-.1-.05-.35 0-.55.25-.15.2-.15.35 0 .4.5.7.6.35 1.6.35t2.3-.8q1-.6 1.25-1 .45-.55.55-1.15.05-.2.05-1.05z"
                    />
                    <path
                        fill="#F2B095"
                        d="M69.55 275.05h-.6q-1.05 0-1.9 1.4-.35.6-.55 1.15-.15.15-.25.65-.15.45-.15.8 0 .5.3 1.3.25 1.2.85 1.2H67.4q.75 0 .85-1.15-.05-1.25.05-1.6.35-1 1.25-1.55.7-.45.7-1.15 0-.4-.15-.65l-.25-.25-.3-.15m57.65 15.15q0-.5-.05-.6-.15-.4-.75-.4h-.05q-.05 0-.15.05-.65.4-1.65 3.25 0 .4.3.95.45.75 1.25.75 1.05 0 1.05-.9-.35-1.15-.35-1.4 0-.2.2-.75t.2-.95m3.3-12.75q.75 0 1.1-.5.2-.3.2-.65 0-.45-.55-.75-.6-.35-1.65-.35-1.7 0-2.85.75-.95.6-.95 1.2 0 .35.3.65.45.4 1.25.4.1 0 1.05-.35 1-.4 2.1-.4m-26.95 11.25q-1.05 0-1 1.1 0 .5.55 1.35.6.9.6 1.5 0 .4-.95 1.15-.95.75-.95 1.15 0 .65 1.55 1h.05q1.4-.05 1.9-1.7.25-.8.25-1.9 0-1.1-.25-1.95-.5-1.7-1.75-1.7m4.4-6.15q-.3.45-.3.7 0 .4.25.75.35.5 1.15.5 1.1 0 1.55-1.25l.2-1.25q0-2.35-1.9-3.65-1.85-1.3-1.85.95 0 .4.6 1.35.6.9.6 1.35 0 .1-.3.55m6.6-1.8q0 1.1.65 2.6 1 2.2 2.7 2.2.7 0 .85-.45.05-.45.05-.5 0-.35-.15-.65v-.1q0-.1-.3-.35-.15-.15-.4-.35l-.45-.45q-.45-.3-.75-.65-.25-.4-.25-.85.2-.9.2-1.35 0-1.5-1.1-1.05-1.05.5-1.05 1.95m-32.6 8.85q0-.1-.05-.2v-.1q0-.15-.4-.5-.1-.25-.35-.55-.8-1-.8-1.85l.25-1.35q0-.95-1.15-.95-1.2 0-1.2 1.85.55 4.5 2.45 4.5.7 0 1.05-.4.2-.25.2-.45m.2-7q.8.6 1.8.6.9 0 1.6-.25.6-.05 1.5-.7.7-.55 1.05-1 .65-.9.65-1.75 0-1.45-1.15-1.45-.5 0-.6.6-.1.15-.15.35 0 .05-.05.1-.2.3-.55.85-.1.1-.2.25-.3.2-1 .45-1.1.5-1.55.5-.05 0-.4-.15-.35-.2-.6-.2-.9 0-1 .8 0 .45.65 1m14.55-8.2q-.1-.2-.3-.75-.25-.45-1-.45-.7.25-.85.6-.05.15-.05.75 0 1.05.55 2.1.9 1.65 2.75 1.65.7 0 1-.65.2-.65.2-.7 0-.3-.05-.35-.05-.05-.55-.25-.25-.15-1.05-.55-.25-.25-.65-1.4m-1.8-7.3q-.4 0-.7.5-.2.35-.2.6 0 .55.6 1.05.75.6 1.85.6.4 0 .9-.15l.5-.2q.2 0 .3.2l.1.2-.1.8q0 1.5.85 1.5.9 0 1.15-.65.1-.65.1-.7 0-.4-.05-.45v-.05-.15q0-1.05-1.05-1.85-.85-.7-1.75-.7l-.75.1q-.4 0-.7-.3-.2-.15-.45-.2-.15-.15-.6-.15m-13.8 3.85q.55.5.65.85.05.15.1.85.05.25.15.4.05 1.25.95 1.25.7 0 .95-.75.1-.75.1-.8 0-1.25-.6-2.35-.85-1.5-2.55-1.95h-.5q-.8 0-1.25.5-.35.35-.35.7 0 .3.35.6.05.15.15.2.2.05.55.1.25.05.55.05h.4l.35.35z"
                    />
                    <path
                        fill="#9E0303"
                        d="M72.35 282.65q.9.65 2.8.65 2.55 0 3.7-1.4.75-.9.75-1.7 0-.9-.35-1.5-.1-1.35-1.3-2.25-1.25-.85-2.95-.8-4.3.2-4.3 4.45 0 .8.6 1.65.45.6 1.05.9m63.2-1.05q0-.85-.3-1.4-.1-.3-.3-.6-.85-1.35-2.9-1.35-.95 0-1.8.25-3.9.4-3.9 3.85 0 .4.1.75 0 1.1.65 1.75.1.15.25.35 1.15 1.55 3.15 1.55 2.65 0 4.1-2.35.95-1.55.95-2.8m-21.35 12.15q.6.8 1.45 1.2 1.3.7 3.4.7 2.1 0 3.45-1.9 1.05-1.5 1.05-3 0-1.25-1.1-2.5-1.65-1.9-4.65-1.35-3.7.7-4.1 3.45-.2.65-.2 1.4 0 .95.7 2m-25.9-9.35h-2.75q-1.35.1-2.2 1.3-.9.7-.9 2.25v.3q0 1.1.65 2.25 1.1 1.9 3.4 1.9 3.15 0 4.35-2 .6-1 .6-2.45 0-1.15-.6-1.9-.75-.85-2.55-1.65m12.4-5.85q-.95.1-2 .8-1.65.6-2.2 1.85-.6.85-.6 2.1 0 1.1.8 1.95.25.3.55.5 1.55 1.9 4.3 1.9 2.45 0 3.7-1.55 1.05-1.3 1.05-3.1 0-5.55-5.6-4.45m-15-9.05q-.65.95-.65 2.35 0 1.15.85 2.4 1.2 1.65 3.3 1.65 2.25 0 3.35-1.2 1.3-.85 1.7-2.95 0-2.6-2.35-3.1-.7-.4-1.7-.7-3.25-.2-4.5 1.55m30.05.4q-1.4-1-3.4-.9-4.9.25-4.9 4.65 0 1.2.8 2.15 1.25 1.45 4.1 1.45h.1q.35.05.75.05 1.9 0 2.9-1.1.45-.4.75-.95.45-.8.45-2.35 0-1.95-1.55-3z"
                    />
                    <path
                        fill="#FADB96"
                        d="M134.9 295.2q-.25.05-.3.1-3.15 1.7-6.85 3-1.3.3-2.5.65-2.85.75-5.95 1.2h17.05q1.1-.5 2.05-1.2.5-.35.95-.65 4.1-3.1 4.9-6.85.55-2.4-.2-5-.3-1.3-1.3-4.25.35 2.95 0 4.15-1.15 5-7.85 8.85z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjFoo004__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo004__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo004__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo004__Layer7_3_FILL" />
            <use xlinkHref="#ObjFoo004__Layer7_4_FILL" />
            <use xlinkHref="#ObjFoo004__Layer7_5_FILL" />
            <use xlinkHref="#ObjFoo004__Layer7_6_FILL" />
        </g></g>
    )
}

export default ObjFoo004
