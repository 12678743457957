import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#0000DB",
    "col1n": "#00006D",
    "col2n": "#FFFFFF"
}

function AccHat157({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M253.3 145.75l-.8.3-1.95 1.35 5.3 14.05q.1 32-16.65 61.65-.9 1.55-10.3 19.85l6.25-2.7q6.804-11.151 12.15-23.25 11.536-25.88 16.4-56 23.85-8.8 28.25-43.9-105.085 5.09-205.75 36.7 12 26.55 41.15 30.75 16.388 33.43 38.15 61.25l5.6 1.5q-30.05-43.9-36.9-67.6l-.2-.6.65-13.7-2.5-.95q-2.75-13.7 56.75-26.25 59.35-9.05 64.2 6.85l.2.7z"
                    id="AccHat157__Layer16_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M265.45 68q-6.8-5.05-15.3-8.65-7.65-3.2-16.6-5.25-1.72-.528-3.5-1-10.552-2.715-22.55-2.8-2.037-.015-4.15.05-10.95.3-23 2.75-6.551 1.302-13.45 3.2l-3.65 1.1q-10 3-20.7 7.3-3 1.2-6.05 2.55-4.078 2.22-7.85 4.45-.365.265-.75.5-10.2 6.1-18.2 12.45-.69.543-1.4 1.05-5.612 4.596-10.15 9.25-3.755 3.842-6.75 7.75-15.15 19.8-10.6 41.1l95.6-15.6 114-21.9q-6.35-24.55-24.95-38.3m-13.55 2.7l-1 20.75-16.3 13.4-.6-19.45 17.9-14.7m-40.5-1.5l3.7 22.5-12.4 15.75-4.35-18.05 13.05-20.2M168.2 95l4.25 20.2-16.75-12.45L147.3 80l20.9 15m-36.5 9.15l9.45 16.2-19.35-1.65-12.45-20.65 22.35 6.1z"
                    id="AccHat157__Symbol_498_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M264.5 86.95l.95-18.95q-6.8-5.05-15.3-8.65l-17.25 11.7-2.85-17.95q-10.552-2.715-22.55-2.8l-12.35 17.85-.3.65-14.5-15.7q-6.551 1.302-13.45 3.2l2.55 23 .3 1.1-27.2-15.7q-3 1.2-6.05 2.55-4.078 2.22-7.85 4.45l8.5 20.75-.15.95-1.05-.05-27.65-7.65q-5.612 4.596-10.15 9.25l16.75 30.2 39.8 2.6-11.65-20.15q.09-.755.15-1.6l.8-3.15 19.6 14.2v.05l9.75 7.05 6.7-1.2-4.8-28.25 8.25-8.25 8.7 4.65 3.4 13.25h.05l-.05.1 3.8 15.05h.05l28.45-32.9 1.85 27.95h.05l34.7-27.6M251.9 70.7l-1 20.75-16.3 13.4-.6-19.45 17.9-14.7m-40.5-1.5l3.7 22.5-12.4 15.75-4.35-18.05 13.05-20.2M168.2 95l4.25 20.2-16.75-12.45L147.3 80l20.9 15m-36.5 9.15l9.45 16.2-19.35-1.65-12.45-20.65 22.35 6.1z"
                    id="AccHat157__Symbol_442_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M144.4 95.45l.1-5.7-3.65 4 .25.25-5.95-2.3.05.2 2.75 5.45-3.9 5.5 6.1-1.75 3.05 4.9 1.5-5.9 4.35-2.65-4.65-2m23.05 19.7l-3.9-2.05.45-4.9-3.35 3.2.15.2-5-2.35.05.2 2 4.85-3.7 4.55 5.35-1.1 2.3 4.35 1.75-4.95.05-.05 3.85-1.95m28.2-10.8h-.05l-5.9.85-3.45-5.7-1.15 6.2.35.15-.35.05h-.05l-7 1.35.2.15 6.15 3.75-.45 8 5-5.55 6.1 3-2.1-6.9 2.7-5.35m33.15-.55l-4.6-1.85-.1-5.6-3.45 4 .25.25-.3-.1h-.05l-5.6-1.95 2.9 5.45-3.65 5.55 5.95-1.9 3.15 4.7 1.35-5.8 4.15-2.75m-2.7-30.7l.05.15 2 4.6-3.4 4.35 5.05-1.15 2.3 4.1 1.5-4.75 3.65-2-3.7-1.85.3-4.65-3.1 3.1.15.25-4.8-2.15m-35.9.05l-5.9.85-3.45-5.75-1.15 6.2.35.15-.35.05h-.05l-7 1.35.2.2 6.1 3.75-.4 7.95 5-5.55 6.1 3-2.1-6.85 2.65-5.35z"
                    id="AccHat157__Symbol_372_0_Layer0_0_FILL"
                />
                <path
                    fill="#666"
                    d="M229.75 114.55l-5.9.9q-9.75 1.519-19.5 3.2-2.45.418-4.9.85h-.05q-7.45 1.25-14.9 2.65-2.25.35-4.45.8l-6.7 1.2q-9.35 1.75-18.65 3.6h-.05q-36.55 7.2-73.85 16.05l2.7 10.45 209.6-37.35-2.7-10.6q-27.554 3.21-55.55 7.45-.105.032-.25.05-2.382.374-4.8.75h-.05z"
                    id="AccHat157__Layer7_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccHat157__Layer16_0_FILL" />
            <use
                xlinkHref="#AccHat157__Symbol_498_0_Layer0_0_FILL"
                id="AccHat157__col1n"
            />
            <use
                xlinkHref="#AccHat157__Symbol_442_0_Layer0_0_FILL"
                id="AccHat157__col1l"
            />
            <use
                xlinkHref="#AccHat157__Symbol_372_0_Layer0_0_FILL"
                id="AccHat157__col2n"
            />
            <use xlinkHref="#AccHat157__Layer7_0_FILL" />
        </g></g>
    )
}

export default AccHat157
