import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjToo043({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29.05 196.1q-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.35 1.35-.85 2.45l.65 4.75h-.05l3.55 26.75 5.2-.7-3.95-30v-.1l-.95-7.25q-1.15-.55-2.1-1.5M5.2 0L0 .7l24.75 188.1q.2.2.45.5 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.2-.2.4-.35v-.25l-.1-.7L5.2 0z"
                    id="ObjToo043__Symbol_203_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M89.25 4.8q-.7.1-1.4.25Q37 15.15 6.25 49 3 52.55 0 56.35q41.9-25.4 88.8-26.95 1-.05 2.05-.05 1.25 0 2.5.05 3.5.05 7.05.35l.95 6.95h.2l5.2-.7-4.7-36-5.45.75.4 2.9q-3.5.45-6.9.95-.4.1-.85.2z"
                    id="ObjToo043__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M94 0l-1.65.15 4.75 36 1.6-.15L94 0m-7.65 23.35h7l-2.6-20q-3.5.45-6.9.95-.4.1-.85.2-.7.1-1.4.25Q30.75 14.85 0 48.7q39.45-22.3 82.65-25.2l1.2-.1q1.25.05 2.5-.05z"
                    id="ObjToo043__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    id="ObjToo043__Symbol_203_0_Layer0_0_1_STROKES"
                    stroke="#AD8383"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M29.45 184.7l-.1-.7"
                />
            </defs>
            <g transform="translate(97.5 100.9)" id="ObjToo043__col1n">
                <use xlinkHref="#ObjToo043__Symbol_203_0_Layer0_0_FILL" />
                <use xlinkHref="#ObjToo043__Symbol_203_0_Layer0_0_1_STROKES" />
            </g>
            <use
                xlinkHref="#ObjToo043__Symbol_82_0_Layer0_0_FILL"
                transform="translate(-4.05 64.9)"
                id="ObjToo043__col2n"
            />
            <use
                xlinkHref="#ObjToo043__Symbol_41_0_Layer0_0_FILL"
                transform="translate(2.2 65.2)"
                id="ObjToo043__col2l"
            />
        </g></g>
    )
}

export default ObjToo043
