import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col1s": "#666666"
}

function LegSoc002({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M146.25 23.4q-1.3-.75-2.6-1.35-3.05-1.4-6.05-2.1-.65-.15-1.2-.25v-.05q-.175-.035-.35-.1V.05l.05-.05h-.05q-.2.05-.35.1Q72.45 12.35 11.2.2q-.25-.1-.5-.1-.15-.05-.25-.05-.05-.05-.1-.05 0 .05.05.1v19.5q-3.8.65-7.6 2.45-1.35.6-2.65 1.35H.1q-.05 0-.05.05-.05.05 0 .1l.05.05q0 .05.05.05h146.1q.05 0 .1-.05 0-.05.05-.05v-.1q-.05-.05-.1-.05h-.05z"
                    id="LegSoc002__Symbol_2_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M54.9 11.8q-.15-.05-.25-.05-10.8-3-22-3.6-.4-.05-.8-.05-.55-.05-1.15-.05L24.2 8H24l-3.9.15-1.3.1q-8.55.6-13.2 1.65-4.7 1-5.35 1.3-.15.05-.25.1Q8 10 16.15 9.5q.3-.05.65-.05l2-.1q.2-.05.35-.05h.3q15.4-.65 30.05 2l4.15.7q1.15.2 2.3.45 1.75.35 3.45.75l.05.05q.1 0 .2.05h.05q.1 0 .2.05.1 0 .25.05-.05-.05-.1-.05l-.4-.1q-.1-.05-.15-.05l-.1-.05q-1.7-.6-3.45-1.05-.55-.15-1.05-.3m8-6.5l-.3-3.85q-.35-2.9-.6 0l-.2 3.85.5 7.6v-.4l.05.4.55-7.6m61.6 6q-.1-.05-.25-.1-.65-.3-5.35-1.3-4.65-1.05-13.2-1.65l-1.3-.1-3.9-.15h-.2l-6.5.05q-.6 0-1.15.05-.4 0-.8.05-11.2.6-22 3.6-.1 0-.25.05-.5.15-1.05.3-1.75.45-3.45 1.05-.4.1-.8.25.15-.05.3-.05.1-.05.2-.05l.3-.1q1.7-.4 3.45-.75 1.15-.25 2.3-.45l4.15-.7q14.65-2.65 30.05-2h.3q.15 0 .35.05l2 .1q.35 0 .65.05 8.15.5 16.15 1.8z"
                    id="LegSoc002__Symbol_3_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSoc002__Symbol_2_0_Layer0_0_FILL"
                transform="translate(119.3 310.25)"
                id="LegSoc002__col1n"
            />
            <use
                xlinkHref="#LegSoc002__Symbol_3_0_Layer0_0_FILL"
                transform="translate(130.25 318.5)"
                id="LegSoc002__col1s"
            />
        </g></g>
    )
}

export default LegSoc002
