import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#9A3200",
    "col1n": "#D24400",
    "col2n": "#333333"
}

function ObjMus029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M50.1 133.9q-2.55 6.95-7.9 7.3l-.25 2.55q1.3.1 2.55.6 4.4 1.85 5.45 8.2.4 2.45.2 5-.3 4.1-2.1 8.45-2.95 7.15-8.2 10.9-5.25 3.75-9.65 1.9-2.7-1.15-4.2-3.9l-3.6 3.35q1.5 4.75-3.75 10.25-1.7 1.8-3.3 3.65Q1.05 208.65 0 225.2q.05 1.6.3 3.15 2.75 19.95 28.05 31.1.15.05.25.1l.05-.1 13.3 5.65q.7.3 1.4.55.5.2 1.1.4.5.2 1.05.35 25.45 8.8 41.05-5.2 3.35-3.8 5.85-8.45-3.25-1.25-4.95-4.75-.85-1.7-1.25-3.8-.2-1.2-.2-1.9 0-5.1 3.65-9.15 3.25-3.6 8.05-5.2.7-.25 1.4-.4.2-2.7.25-5.5.2-7.6 4.6-9.85l-.15-4.95q-2.95.95-5.65-.15-4.45-1.85-5.45-8.2-1.05-6.4 1.9-13.55 2.95-7.1 8.2-10.8 5.2-3.75 9.65-1.9 1.25.5 2.2 1.35l1.65-1.95q-3.5-4.05-.35-10.75 15.55-20.05 10.9-34.35l-.05-.2q-.1-.35-.2-.6-4.4-13.5-19.3-21.35l-.2-.1v.05l-10.7-4.4.05-.1q-16.15-5.15-28.95 1.3-.25.1-.55.3l-.2.1q-13.4 6.8-16.65 31.95m97.25-122.45q.35-1.6.6-2.6.05-1.15-1.65-1.7l1.05-4.25-7-2.9-2.25 3.75q-1.6-.75-2.35.05-.55.85-1.55 2.15l-4.3-1.8q-3.45 7.75-1.55 7.8l3.7 1.55-2.1 3.25 2.9 1.2-10.7 21.5q.4 1.8 1.7.7 8.85-12.25 9.95-19.6.6 2.65 3.55 1.5-5.65 10.1-6.95 20.85.15 1.35 1.7.7.048-.048.1-.05h-.05l3.2-9.4-.05-.05h.05l4.45-13.25 2.95 1.2.9-3.75 3.7 1.55q1.35 1.35 4.35-6.6l-4.35-1.8z"
                    id="ObjMus029__Symbol_113_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4.5 14.55l-.45.95 7.05 2.95.35-1-6.95-2.9m1.35-12q-4.2 6-5.85 6l3.7 1.55L9.75.35Q8.15-.4 7.4.4q-.55.85-1.55 2.15m13.75 2.9q.05-1.15-1.65-1.7L15.3 14.9l3.7 1.55q-1.25-1.2 0-8.4.35-1.6.6-2.6z"
                    id="ObjMus029__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M17.8 184.7q.05.7 0 1.5-.2 4.6-2.5 10.45-3.5 9.8-8.35 19.1L4.3 220.5l-3.4-1.4-.9 2.25 2.7 1.15-.6 1.45 1 .45-.2.4q2.45 2 6.05 2.45l.15-.3.9.35.6-1.5 2.75 1.1.9-2.25-2.75-1.1q-.2-.15-.45-.25 1.65-6.2 1.95-7.1 2.95-9 7.05-17.6 2.4-5.4 5.3-8.7l.35-.4q.5-.65 1.05-1.1 3.85-3.45 6.4-10.1v-.05l-1.25-.5v.05l-.35-.15v-.05l-2.85-1.2v.05l-.35-.15v-.05l-6.1-2.5.05-.05-.4-.15-.05.05-1.35-.6q-2.95 6.5-2.7 11.7M103.25 3.85l-1.25-.5q-.3-.45-1.35-1.3-1.7-1.4-3-1.4-1.35 0-1.75.15L94.05 0 92.9 2.35l1.65.7-54.5 120.15 14.7 6.15L101.45 5.9l1.7.7.85-2.45q-.2-.1-.4-.15-.2-.1-.35-.15z"
                    id="ObjMus029__Symbol_39_0_Layer0_0_FILL"
                />
                <path
                    fill="#BFBFBF"
                    d="M306.5 91.5q-.044-.158-.2-.25-.15-.039-.3 0-.158.094-.25.25l-74.5 187.1q-.039.15 0 .3.094.158.25.2.15.089.3 0 .158-.044.2-.2l74.5-187.1q.089-.15 0-.3m-.3-6.6q-.043-.157-.2-.25-.15-.039-.3 0-.157.093-.25.25L224.95 276q-.039.15 0 .3.092.157.25.2.15.089.3 0 .156-.043.2-.2l80.5-191.1q.088-.15 0-.3m2.8-3.85q-.15-.039-.3 0-.157.093-.25.25l-80.3 196.05q-.038.15 0 .3.093.157.25.2.15.089.3 0 .157-.043.2-.2L309.2 81.6q.088-.15 0-.3-.043-.157-.2-.25m-8.8 13.5q-.15-.039-.3 0-.156.093-.25.25L221.8 274.55q-.039.15 0 .3.092.156.25.2.15.089.3 0 .156-.043.2-.2L300.4 95.1q.089-.15 0-.3-.042-.156-.2-.25z"
                    id="ObjMus029__Layer4_0_MEMBER_0_FILL"
                />
                <path
                    fill="#997A53"
                    d="M203.75 320.15q-.15-.8-.7-1.05-.55-.2-1.2.25-.7.5-1.1 1.4-.35.95-.25 1.75.15.85.7 1.05.55.25 1.2-.25.45-.3.75-.75.15 0 .35.1.45.15.8.35l-.3.75 6.1 2.5 1-2.4-6.1-2.5-.3.75-.05-.05q.045.048-.45-.15-.45-.2-.6-.25.25-.8.15-1.5z"
                    id="ObjMus029__Layer4_0_MEMBER_1_FILL"
                />
                <g id="ObjMus029__Layer4_0_FILL">
                    <path
                        fill="#333"
                        d="M198 350.35l-3-1.25-1.95 4.8 3 1.25 1.95-4.8z"
                    />
                    <path
                        fill="#999"
                        d="M208.95 325.8q-.873-2.489-3.65-1.55L196 348.1q.385 2.176 1.85.75l11.1-23.05z"
                    />
                    <path d="M206.9 243.05l-.1.2q-.2.6-.1 2.55v-.2q.05.8.25 1.3.45.95.95 1.45.6.65 1.65 1.1 1.8.75 5.5-1.4 1.45-.85 2.25-1.6 1.1-.95 1.95-2.2 2.8-4.55 4.95-10.1 2.85-7.05 5.4-8.75.35-.2.85-.45.45-.3 1-.5.65-.2 1.05-.05.55.25 1.35 2.15-.3-.05-.65.05-.6.3-.8.85-.25.55 0 1.1.25.6.8.8.55.25 1.15 0 .35-.15.6-.5 1.05-1.65-.9-4.4-.35-.3-.85-.55-.6-.35-1.65-.45-4.05.25-6.7 4.25-2.65 3.6-4.7 8.6l-.1.2-.5 1.2q-3.3 8.3-7 10.25l-.25.1q-.1.1-.2.1-1.7.8-2.5.5-.8-.35-1.1-1.35-.2-.65-.1-1.5.55.1 1.05-.15.75-.35 1.1-1.1.3-.75-.05-1.5-.25-.8-1.05-1.1-.75-.3-1.55 0-.7.35-1 1.1m54.2-6.25q.25.5.35 1.05.15.5.3.9.5 3-2.4 10.05-2.45 5.4-3.65 10.6-.35 1.45-.25 2.9.1 1.15.45 2.75 1.1 4.15 2.9 4.9 1 .4 1.9.35.75 0 1.8-.3.5-.2 1.05-.75l-.1.15q1.4-1.3 1.7-1.9.05-.05.1-.15.3-.75-.05-1.55-.25-.75-1.05-1.1-.75-.3-1.55 0-.7.35-1 1.1-.35.75-.05 1.5.25.6.7.9-.6.65-1.2.95-.9.5-1.75.15-.75-.3-1.4-2.1-.05-.05-.1-.2l-.05-.25q-1.25-4 2.3-12.2.25-.6.55-1.2l.1-.2q2-5 2.7-9.4.95-4.7-1.7-7.75-.75-.7-1.45-.85-.5-.15-.95-.2-3.35.55-3.8 2.45-.1.4.1.8.2.55.75.8t1.15 0q.55-.25.8-.8.2-.55 0-1.15-.15-.3-.45-.5 1.9-.8 2.5-.6.35.2.75.85m48.15-145.2l-.75 2.05 5.6 2.35-.6 1.4 4.75 1.95q1 .35 2-.05 1.05-.45 1.5-1.5.4-1 0-2.1-.35-.85-1.15-1.3l-4.95-2.05-.7 1.6-5.7-2.4h-.05l.05.05m-10.85 6.3v-.1l.35.15v.1l.9.35v-.05l.35.15v.05l1.5.65v-.05l.35.15v.05l1.25.5v-.05l.35.15v.05l.85.35v-.05l6.95-20.8-3.55-1.5-9.95 19.6.65.3m-6.6-7.4l.7-1.6-4.95-2.05q-.9-.25-1.75.1-1.1.45-1.5 1.5-.45 1.05 0 2.1.4 1 1.3 1.45l4.75 1.95.6-1.45 6.05 2.55.95-1.95-6.15-2.6m2.85-6.85l.6-1.45L302 85l.95-1.95-6.85-2.85.65-1.65-4.95-2.05q-.85-.25-1.75.1-1.05.5-1.5 1.55-.4 1 .05 2.05.4 1 1.3 1.5l4.75 1.95m31.05 4.65q.4-1.05 0-2.1-.35-.85-1.15-1.3l-4.95-2.05-.7 1.6-6.35-2.65-.7 2.05 6.2 2.6-.55 1.45 4.75 1.95q.95.35 1.95-.05 1.05-.45 1.5-1.5z" />
                    <path
                        fill="#FFF"
                        d="M245 246.4q-.486.388-.8.65l-8.5-3.55q.024-.397 0-.95l-4.85-2.05-1.1 2.7 7.7 3.2h.05l.35.15h-.05l6.05 2.6h.05l4.7 1.9 1.1-2.7-4.7-1.95z"
                    />
                </g>
                <g id="ObjMus029__Layer4_0_MEMBER_2_FILL">
                    <path
                        fill="#997A53"
                        d="M195 349.1l3 1.25.55-1.2-3-1.25-.55 1.2z"
                    />
                    <path
                        fill="#C8B19D"
                        d="M209.05 323l-2.05-.85-1 2.4 2.05.85 1-2.4z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus029__Symbol_113_0_Layer0_0_FILL"
                transform="translate(173.9 57.45)"
                id="ObjMus029__col1n"
            />
            <use
                xlinkHref="#ObjMus029__Symbol_86_0_Layer0_0_FILL"
                transform="translate(302.25 60.85)"
                id="ObjMus029__col1d"
            />
            <use
                xlinkHref="#ObjMus029__Symbol_39_0_Layer0_0_FILL"
                transform="translate(202 96.9)"
                id="ObjMus029__col2n"
            />
            <use xlinkHref="#ObjMus029__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus029__Layer4_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMus029__Layer4_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMus029__Layer4_0_MEMBER_2_FILL" />
        </g></g>
    )
}

export default ObjMus029
