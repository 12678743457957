import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FF8E8E",
    "col1n": "#FF5D5D"
}

function FacMou048({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M27.3 10h.1v-.2q-.9-.5-1.65-1.05-1.7-1.15-3-2.65-1.2-2-2.15-3.45Q18.65 0 17.25 0q-.8 0-2.3 1.75-.55.65-.9 1.15v-.05q-.2.2-.35.4-.1-.2-.3-.4v.05q-.35-.5-.9-1.15Q11 0 10.15 0 8.8 0 6.85 2.65 5.9 4.1 4.7 6.1 2.75 8.3 0 9.8v.2H1.5q3.15.05 5.3 1 2.1.9 4.5 1 2.35.05 2.85.1.5-.25 2.6-.55 2.1-.3 3.65-.6 1.55-.3 1.75-.25l5-.7h.15z"
                    id="FacMou048__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M26.15.1l.6-.1-4.7.1q-.75.05-1.35.1-2.6.25-5.65 1.4-.8.25-1.3.5-.45-.2-1.1-.4Q8.75.45 6.15.2 4.25.05 1.1 0H0l.7.05q.9.2 1.1.25 2.6 1.15 3.4 2.05.75.85 1.45 1.75l.3.4Q8 6 8.85 7.55q.1.15.2.25 2.1 2.45 4.1 2.55h.4q1.8-.1 3.7-2.1.2-.2.4-.45.1-.1.2-.25.85-1.55 1.9-3.05l.3-.4q.7-.9 1.45-1.75.8-.9 3.4-2.05.2-.1 1.25-.2z"
                    id="FacMou048__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou048__Symbol_73_0_Layer0_0_FILL"
                transform="translate(178.75 213.9)"
                id="FacMou048__col1n"
            />
            <use
                xlinkHref="#FacMou048__Symbol_26_0_Layer0_0_FILL"
                transform="translate(179.15 223.9)"
                id="FacMou048__col1l"
            />
        </g></g>
    )
}

export default FacMou048
