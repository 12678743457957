import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF"
}

function AccMor073({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="AccMor073__Layer10_0_FILL">
                    <path
                        fill="#0040D4"
                        d="M219.25 273.25q.566 1.639 1.8 3 6.537.873 13.05 0 1.268-1.361 1.85-3-8.35-.95-16.7 0z"
                    />
                    <path
                        fill="#FF0"
                        d="M236.45 270.25q0-1.601-.5-3-8.35-1.362-16.7 0-.5 1.399-.5 3 9.041 1.15 17.7 0z"
                    />
                    <path
                        fill="red"
                        d="M233.85 264q-2.6-2.6-6.25-2.6-3.6 0-6.3 2.6-.146.148-.3.3 6.881 1.272 13.15 0l-.3-.3z"
                    />
                </g>
                <g id="AccMor073__Layer10_1_FILL">
                    <path
                        fill="#008E29"
                        d="M219.25 273.25h16.7q.5-1.399.5-3h-17.7q0 1.601.5 3z"
                    />
                    <path
                        fill="#841D9A"
                        d="M221.05 276.25q.129.126.25.25 2.7 2.6 6.3 2.6 3.65 0 6.25-2.6l.25-.25h-13.05z"
                    />
                    <path
                        fill="#F90"
                        d="M235.95 267.25q-.57-1.608-1.8-2.95H221q-1.194 1.342-1.75 2.95h16.7z"
                    />
                </g>
                <path
                    fill={colors.col1l}
                    fillOpacity={0.502}
                    d="M1.45 7Q2 4.95 3.65 3.3 6.15.8 9.8.8q.85 0 1.65.15Q9.8 0 7.8 0 4.6 0 2.3 2.35 0 4.65 0 7.85q0 2.55 1.55 4.55-.5-1.35-.5-2.85 0-1.35.4-2.55z"
                    id="AccMor073__Symbol_182_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#AccMor073__Layer10_0_FILL" />
            <use xlinkHref="#AccMor073__Layer10_1_FILL" />
            <use
                xlinkHref="#AccMor073__Symbol_182_0_Layer0_0_FILL"
                transform="translate(219.8 262.25)"
                id="AccMor073__col1l"
            />
        </g></g>
    )
}

export default AccMor073
