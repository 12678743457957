import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#747474",
    "col1n": "#999999",
    "col2d": "#000000",
    "col2n": "#434343"
}
function BodTai017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#999"
                    d="M302.85 329.7h-.5q2.5.2 4.85.2-.5-.05-1.05-.05-1.15 0-2.2-.1-.5 0-1.05-.05h-.05z"
                    id="BodTai017__Layer4_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M94.05 20.15q.75-1.15 1.35-2.6-22.95 7.2-43.95 3.4Q33.95 17.3 7.6 2.05 3.15 0 2 0 1.35 0 .75.25 0 .55 0 1.1q0 1.35 1.15 2.55.65.7 3.3 2.75.15.05.35.2Q22.85 17.45 39.55 23q27.05 8.9 50.4 3.8.05-.1.05-.15.4-.1.9-.15 4.1-.8 4.95-2.4.85-1.65 1.15-1.9-1.85-1.7-2.95-2.05z"
                    id="BodTai017__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M50.45 4.45q.4-.1.9-.15 4.1-.8 4.95-2.4.85-1.65 1.15-1.9Q29.95 8.05 0 .8q27.05 8.9 50.4 3.8.05-.1.05-.15z"
                    id="BodTai017__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M54.05 8.45Q49.7 5.6 46.6 4.25q3.5-.1 5.1.95Q42.15-4 25.65 3.05l-1.05.45-.7.3q-6.2 2.65-12.25 4.6Q6.7 10.7 0 14.4q9.55-1.85 13.25-1.35-.25.1-.75.35-.4.2-.95.45-.25.1-.5.25-1.8.85-2.7 1.55-1 .8-1.05.85-.7.55-1.1 1.15.15 0 .25-.05.15 0 .25-.05h.05q.55-.1 1-.2 1.05-.1 2.15-.35l1.1-.2q2.3-.5 4.35-.8 5.95-.95 12.2-.85 8.8.1 12.35 1.85h.05q-.2-.65-2.3-2.85 1.3.3 3.3 1 4.35 1.25 8.15 3.7 3.9 2.3 4.25 2.55.3.35-.3-1.1-.7-1.55-1.55-2.8-1-1.35-2.15-2.35 5.55 1.7 8.6 4.8 2.6 2.6 3.85 4.4.7 1 .95 1.75-1.8-13.15-8.7-17.65z"
                    id="BodTai017__Symbol_62_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M55.6 16.4Q51.4 8.95 43.95 4.35 36.5-.25 25.15 0l3.55 2.5h-.25q-.9-.2-2.85-.25-1.9-.1-1.9 0-5.6 0-17.05 3.1-.2.05-.35.1-.4.2-.95.45-.25.1-.5.25-1.8.85-2.7 1.55-1 .8-1.05.85Q.4 9.1 0 9.7q.15 0 .25-.05.15 0 .25-.05h.05q.55-.1 1-.2 1.05-.1 2.15-.35l1.1-.2q2.3-.5 4.35-.8 5.95-.95 12.2-.85 8.8.1 12.35 1.85h.05q-.2-.65-2.3-2.85 1.3.3 3.3 1 4.35 1.25 8.15 3.7 3.9 2.3 4.25 2.55.3.35-.3-1.1-.7-1.55-1.55-2.8-1-1.35-2.15-2.35 5.55 1.7 8.6 4.8 2.6 2.6 3.85 4.4z"
                    id="BodTai017__Symbol_64_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#BodTai017__Layer4_0_FILL" />
            <use
                xlinkHref="#BodTai017__Symbol_61_0_Layer0_0_FILL"
                transform="translate(219.35 297.6)"
                id="BodTai017__col1n"
            />
            <use
                xlinkHref="#BodTai017__Symbol_60_0_Layer0_0_FILL"
                transform="translate(258.9 319.8)"
                id="BodTai017__col1d"
            />
            <use
                xlinkHref="#BodTai017__Symbol_62_0_Layer0_0_FILL"
                transform="translate(303.1 306.75)"
                id="BodTai017__col2n"
            />
            <use
                xlinkHref="#BodTai017__Symbol_64_0_Layer0_0_FILL"
                transform="translate(309.3 314.7)"
                id="BodTai017__col2d"
            />
        </g></g>
    )
}
export default BodTai017
