import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D2E1B",
    "col1n": "#794D32",
    "col1s": "#2A180D"
}

function ShiLon056({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1d}
                    d="M20.8 72.35q.3-1.3.3-4.4 0-11.5-9.9-11.5l-1.05.05Q2.9 56.5.8 61.85q-.05.1-.05.2Q0 64.1 0 69.4q0 3.5 2.45 9.25 1.7 3.95 3.85 5.95 2.1 1.9 4.65 1.9 2.2 0 4.25-1.7 1.45-1.2 1.9-2.2.3-.7.55-1.25.2-.4.4-.85.3-.7.6-1.45.5-1.35.85-2.6 1.15-3.55 1.3-4.1m7-36.85l-.25-.25Q24.45 52.9 23.7 55.8q4.7 3.3 2.1 14.45-.8 2.85-3.3 8.95-2.55 6.05-4.4 7.75-.45.45-.55.75-.1.25.15.45.55.4 2.7.25 4.1-.3 5.55-3.9.05-.15.15-.25v-.05q1.2-1.95 2.95-5.45 1.45-2.85 2.5-5 2.25-4.25 3.65-8.55 4.3-11.1 0-23-1.85-4.3-5.6-6-1.6-.6-1.8-.7m18.55-21.15q1.95-.05 3.95.15-10.8-3.9-20.45-10.6L28.3 2.75Q26.85 1.7 25.5.65L25.35.5l-.8-.35q-3.2-.65-5.3 1.35-.15.1-.25.25-.2.25-.4.55-3 4.9 0 10.05 1.05 1.75 3.05 3.05 1.5 1 3.35 1.6 2.75.9 6.15.9 5 0 8.8-1.75 3.8-1.75 6.4-1.8m6.25 8.05q-2.35-.95-5.05-1.15-2.8-.15-11.1 4.15-8.45 4.35-13.4 4.45.15.1.35.1 3.6 0 11.95-2.1Q43.7 25.7 45.9 25.7q7.65 0 11.55 1.9 1.85.9 4.65 3.65-.9-1.4-1.65-2.4-.2-.15-.35-.35-.85-.9-1.6-1.55-.65-.8-1.25-1.4-2.35-2.3-4.65-3.15m79.15 5.45q6.5 1.65 10.1 2-4.5-.75-11.3-4.25-8.3-4.3-11.1-4.15-1.05.1-2.05.3v.1q-.3.05-.6.15-.95.25-1.8.6-2.3.85-4.65 3.15-.6.6-1.25 1.4-.75.65-1.6 1.55l-.6.7-.9 1.05q-1.1 1.25-.4.8 2.8-2.75 4.65-3.65 2.5-1.2 6.55-1.65.3 0 .6-.05v-.05q1.75-.15 3.8-.15 2.2 0 10.55 2.15m7.4 7.75q-.4.15-1.65.6-3.75 1.7-5.6 6-4.3 11.9 0 23 1.4 4.3 3.65 8.55 1.05 2.15 2.5 5 1.75 3.5 2.95 5.45v.05q.1.1.15.25 1.45 3.6 5.55 3.9 4.15.25 2.3-1.45-1.85-1.7-4.4-7.75-2.5-6.1-3.3-8.95-2.5-10.85 1.95-14.3l-4.1-20.35m9.35-33.3q-.2-.3-.4-.55-.1-.15-.25-.25-2.05-1.9-5-1.4-.45.2-1.8 1.3-.5.4-.9.7-.25.1-1.05.65l-1.55 1.15q-9.6 6.65-20.3 10.6 1.75-.2 3.5-.15 2.6.05 6.4 1.8 3.8 1.75 8.8 1.75 2.05 0 3.85-.3 3.2-.6 5.65-2.2 2-1.3 3.05-3.05 3-5.15 0-10.05m18.6 67.1q0-5.3-.75-7.35 0-.1-.05-.2-2.1-5.35-9.35-5.35l-1.05-.05q-9.9 0-9.9 11.5 0 3.1.3 4.4.15.55 1.3 4.1.35 1.25.85 2.6.3.75.6 1.45.2.45.4.85.25.55.55 1.25.45 1 1.9 2.2 2.05 1.7 4.25 1.7 2.55 0 4.65-1.9 2.15-2 3.85-5.95 2.45-5.75 2.45-9.25z"
                    id="ShiLon056__Symbol_274_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M19 14.9v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3L13.8 36.1v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L19 14.9m34.7 10.8q-.15-.15-.35-.15-.2 0-.35.15-.15.2-.15.45v47.2q0 .25.15.4.15.15.35.2.2-.05.35-.2.15-.15.15-.4v-47.2q0-.25-.15-.45m-4.8-19q.75.65 1.6 1.55.15.2.35.35Q52 9.85 52.6 10.65q-.7-1.2-1.35-2.3-1.55-2.4-2.9-3.75-2.55-2.5-5-3.4Q40.85.2 38 0q-2.95-.25-11.6 4.25-8.4 4.3-13.15 4.3-2.65 0-4.95-.95-.7-.3-1.35-.65-.65-.4-1.25-.85l-.05-.05q-.45-.35-3-3-.2-.1-.45-.25-.55-.3-1.2-.7L.8 2l-.5-.25.7.4H.95v.1L.9 2.3q-.05.15-.1.25 3.75 3.9 4.25 4.3.9.7 1.9 1.2 1.25.65 2.65 1.1 1.75.45 3.65.45h.2q4.95-.1 13.4-4.45Q35.15.85 37.95 1q2.7.2 5.05 1.15 2.3.85 4.65 3.15.6.6 1.25 1.4m45.95 19q-.15-.15-.35-.15-.2 0-.35.15-.15.2-.15.45v47.2q0 .25.15.4.15.15.35.2.2-.05.35-.2.15-.15.15-.4v-47.2q0-.25-.15-.45m35.1-11.35q-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3M147.2 2.1q-1.25.7-2.05 1.15-2.55 2.65-3 3l-.05.05q-.6.45-1.25.85-.65.35-1.35.65-2.3.95-4.95.95-4.75 0-13.15-4.3-8.65-4.5-11.6-4.25-1 .05-2 .25-.3.2-.6.3-1.1.2-2.15.65-2.45.9-5 3.4-1.35 1.35-2.9 3.75-.65 1.1-1.35 2.3.25-.3.6-.65l.9-1.05.6-.7q.85-.9 1.6-1.55.65-.8 1.25-1.4 2.35-2.3 4.65-3.15.85-.35 1.8-.6.3-.1.6-.15v-.1q1-.2 2.05-.3 2.8-.15 11.1 4.15 6.8 3.5 11.3 4.25 1.25.2 2.3.2 1.9 0 3.65-.45 1.4-.4 2.65-1.1 1-.5 1.9-1.2.5-.4 4.6-4.65-.1-.15-.15-.3z"
                    id="ShiLon056__Symbol_252_0_Layer0_0_FILL"
                />
                <path
                    fillOpacity={0.502}
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiLon056__Layer11_0_FILL"
                />
                <g
                    id="ShiLon056__Symbol_327_0_Layer0_0_FILL"
                    fill={colors.col1n}
                >
                    <path d="M67.85 26.9q-3.8-7.1-11.65-10.35-1.05-.35-2.1-.75-10.8-3.9-20.45-10.6L32.1 4.05Q30.65 3 29.3 1.95l-.15-.15-1.7-.8Q25.5 0 22.9 0q-.8 0-1.75.15-.1.05-.2.15Q16 3.05 12.6 9.4q-1.3 2.75-1.85 6.5-.6 3.6 2.35 7.05l1.3.75h-.05v.1l-.05.05q-.05.15-.1.25-1 2.35-1.85 4.8l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.3 1.35-.6 2.75-7.8 10.15-5.4 19.15 2.35 9 4.5 13.4 1.95 4 4.1 5.8l.1.05v.05q.15.05.25.15 5.4 3.65 11.35-1 .2-.15.4-.25.05-.05.1-.05-.1.25.15.45v11.2q9-2 17.7-2.7 15.3-1.4 29.75 1.35.9.15 1.75.35v-52.8q-.25-11.2-1.75-16.9-.3-1.25-.7-2.2-.2-.45-.4-.85M14.4 23.65l-.2-.1h.25q-.05.05-.05.1m1.35.75l-.15-.05q-.481-.263-1.05-.6.48.27 1.2.65m148.5 8.9q-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75l-.1-.2q-.1-.15-.15-.3v-.1l.15.4.4-.4h-.2q.1-.15.15-.25 5.15-7.5 0-16.1-2-3.1-4.45-5.1-1-.6-2.25-1.05-3.5-1.75-7 0-.4.15-.75.35-.45.2-1.8 1.3-.5.4-.9.7-.25.1-1.05.65l-1.55 1.15q-9.6 6.65-20.3 10.6-.25.05-.45.15-.1 0-.6.15-9.25 2.55-13.8 12.75-.1.4-.25.85-1.6 5.7-1.9 17.15v52.8q.95-.2 1.9-.35 7.5-1.4 15.25-1.7 2.05-.1 4.15-.1 6.8-.05 13.75.75 7.1.8 14.4 2.4v-11.8q.1.1.25.2.2.1.4.25 5.95 4.65 11.35 1 .1-.1.25-.15v-.05l.1-.05q2.15-1.8 4.1-5.8 2.15-4.4 4-13.4t-4.75-18.7q-.2-.9-.35-1.8-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95z" />
                    <path fillOpacity={0.502} d="M103.45 57.2l-.1-.1v.1h.1z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiLon056__Symbol_327_0_Layer0_0_FILL"
                transform="translate(105 228.05)"
                id="ShiLon056__col1n"
            />
            <use
                xlinkHref="#ShiLon056__Symbol_274_0_Layer0_0_FILL"
                transform="translate(108.8 229.35)"
                id="ShiLon056__col1d"
            />
            <use
                xlinkHref="#ShiLon056__Symbol_252_0_Layer0_0_FILL"
                transform="translate(118.4 249.6)"
                id="ShiLon056__col1s"
            />
            <use xlinkHref="#ShiLon056__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiLon056
