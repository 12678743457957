import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFE69A",
    "col2n": "#FF5D5D"
}

function FacMou061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    d="M183.7 215.3q-.05.035-.1.05l-1.8 3.25-2.65.85q-.009.05-.05.1-1.55 1.8-2.7 3.85 2.2 2.05 4.7 3.35l.4.2q2.428 1.288 5.25 1.95.025-.006.05 0 .099.023.2.05l13.6-7.05q.066-.183.15-.35 2.25-4.55 8-5.5-5.95.3-10.7-2.05-2.75-1.35-5.4-1.5-.05-.003-.1 0l-3.65 2.5-3.6-.65v.05h-.1q-.6.35-1.25.75-.1.05-.25.15z"
                    id="FacMou061__Layer13_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.7 7.7q.55.1 1.1.15 2.45.35 4.4 0 4.55-.8 5.9-5.45.3-1.3.85-2.4-3.25-.05-6.2.6Q2.1 1.75 0 7.35q.85.2 1.7.35z"
                    id="FacMou061__Symbol_128_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M4.6 2.8Q1.95 4.7 0 7.05q.3 2.05.9 4.1 2.7-1 6.15-1.25-.8-1.3-1.35-2.65Q4.9 5.2 4.6 2.8m9.45 3.55q-.3-3.15-.5-6.35Q9.8-.2 6.2 1.9h-.1q.95 2.4 1.5 5.05 3.25-.25 6.45-.6z"
                    id="FacMou061__Symbol_86_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#FacMou061__Layer13_0_FILL" />
            <use
                xlinkHref="#FacMou061__Symbol_128_0_Layer0_0_FILL"
                transform="translate(186.8 221.55)"
                id="FacMou061__col2n"
            />
            <use
                xlinkHref="#FacMou061__Symbol_86_0_Layer0_0_FILL"
                transform="translate(179.1 212.5)"
                id="FacMou061__col1n"
            />
        </g></g>
    )
}

export default FacMou061
