import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#000000",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}

function ObjMor093({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M92.1 245.8q-.05-.15-.15-.25l-1.15-2.95-60.45-9.25-1.45 2.35q-3.45 4.7-4.95 10.35-1.4 4.65-1.4 9.95v1.85q.4 8.2 4.2 15.1 1.75 3.15 4.15 5.95-1 .55-1.85 1.3l-.5.3q-1.2.8-2.25 1.7-1.05.7-2 1.6-12.15 10.95-13.65 25.85-.1.95-.15 2.05-.05 1.05-.05 2.15 0 2.15.2 4.25.9 8.7 5.5 16.15.35.6.7 1.1.9 1.3 1.9 2.55l.1.5q.55 1.95.55 4.1-2.35 6.5-8.3 9.7-2.2 1.1-5.85 2.9-2.6 1.4-2.6 2.45 0 2.95 11.1 2.95 4.35 0 9.15-1 4.8-1.15 10.4-1.15 4.05 0 11.2 1.15 11.65 2.6 22.95 0 .65-.1 1.35-.2 6.2-1 12.35-2 2.65 0 10.8 1.65 8.2 1.55 15.15 1.55h5.55q1.15-.1 1.35-1.6.15-1.5-.45-2.8-.8-1.6-2.55-2.9-1.85-1.45-7.05-3.75-5.05-2.25-6.55-3.2-1.55-1-2.75-2.95-.35-.35-.75-2.15 3.15-3.3 5.45-6.9 5.65-9.2 5.65-20.4 0-1.1-.05-2.15-.8-16.2-13.8-27.9-.85-.7-1.75-1.45-1.15-.9-2.2-1.75-.15-.1-.25-.2-.05 0-.1-.05-.95-.7-1.95-1.35 6.9-7.95 8.25-18.3.15-1.45.2-2.85.05-.95.05-1.85 0-5.35-1.3-10.2m-5.05-10.15l-.05.1h.15l-.1-.1z"
                    id="ObjMor093__Layer10_0_FILL"
                />
                <path
                    fill="#DE5500"
                    d="M64.95 258.9h-.1q-1 .65-1.9 1.9-1.1 1.4-1.15 2.65-4-2-5.4-3.5-.2 1.15.15 2.35.65 2.45 2.25 3.4 1.6.9 3.15 1.5 1.55.6 3.7 1.1 2.15.55 4.5 1.2 2.45.6 7.45.85 4.4.2 1.35-3.4-.05.85-.5 1.05-.3.15-1.65.15-2.7 0-7-1.4.2-1.4 1.15-4.5-1.5 1.55-2.25 2.6-.45.6-.6 1.25-1.55-.6-3.25-1.3-.95-.4-1.8-.75.5-2.15.8-3.25.3-1.2 1.1-1.9z"
                    id="ObjMor093__Layer10_1_MEMBER_0_FILL"
                />
                <path
                    fill="#DEF0F7"
                    d="M93.9 341.15q-.5.45-.95.9-.8.85-1.8 1.75-5.55 5-15.75 9.25 3.9-.3 9.65-2.95 5.65-2.7 9.6-6.8-.35-.35-.75-2.15m-75.05-2.75q.55 1.95.55 4.1 6.05 6.2 11.05 8.2 5 2 11 3.1-9.45-3.1-17.15-10-.95-.9-1.8-1.75-2.05-2-3.75-4.15l.1.5M84.9 279q-.9 1.05-1.9 2-10.3 10.4-25 10.4T32.9 281q-.1-.05-.1-.1-1-.9-1.9-2-1 .55-1.85 1.3.05.1.15.2 1.3 1.5 2.85 2.95 10.8 10.05 26.05 10.05t26-10.05q1-.9 1.85-1.8.45-.65.9-1.2-.95-.7-1.95-1.35m7.05-33.45l-1.15-2.95-60.45-9.25-1.45 2.35q.75.25 1.55.5 14.75 4.85 29.45 6.9 15.4 2.7 32.05 2.45z"
                    id="ObjMor093__Layer10_2_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M69.65 8.5q-1.9-1.75-6.55-1.8-1.9-.15-3.7-.25-8.5-.75-15.55-1.75Q37.1 3.55 29.3 1.75 27.4 1.3 25.45.8q-4.55-1.6-8.1 0-1.35.95-1.6 2.75l-.4 10.5q2.75 4.75-.4 9.5Q9.8 22.1 4.8 20.15q-.35-.1-.65-.2-2.9-.95-3.6 1.75l-.5 3.5q-.45 2.9 2 4.25 1.25.45 2.4.95 1.5.55 2.95 1.05 15.55 5.25 31.05 7.45Q54 41.6 70.5 41.35l2.95-.1q1.25-.1 2.5-.1 2.75-.65 3.25-3.45l.55-3.45q.15-2.85-2.85-2.85h-.7q-5.2.25-10.45.15H65.45l.1-.1Q61 26.9 68 22.35l2.85-10.15q.45-2.25-1.2-3.7z"
                    id="ObjMor093__Symbol_228_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.35 1.75Q12.45 1.3 10.5.8 5.95-.8 2.4.8 1.05 1.75.8 3.55l-.4 10.5q2.75 4.75-.4 9.5 3.6 1.1 7.25 2.05l.55-9.55h-.05V5.3q.2-4.85 6.6-3.55m40.9 7.4Q53.7 6.85 49.7 6.7q-4.05-.15-5.9-.3 6.25.8 5 5.4L45.5 22h-.05L43 31.25q3.8.25 7.55.3-1.5-5.5 2.55-9.2l2.8-10.1q.3-1.8-.65-3.1z"
                    id="ObjMor093__Symbol_227_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M50.5 17.5l.1-.1 2.45-9.1q-6.6-.2-12.75-.75-7.15-.65-13.8-1.8-6.55-.95-13.5-2.5-2.6-.6-5.2-1.25Q4.1 1.1.4 0L0 9.5q3.6 1.1 7.25 2.05 8.8 2.2 17.8 3.45 12.65 2.25 25.4 2.5h.05z"
                    id="ObjMor093__Symbol_229_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 9.5q3.6 1.1 7.25 2.05L7.8 2Q4.1 1.1.4 0L0 9.5m45.5-1.6l-2.55 9.25q3.75.25 7.55.35l2.65-9.15Q49.3 8.2 45.5 7.9z"
                    id="ObjMor093__Symbol_230_0_Layer0_0_FILL"
                />
                <g id="ObjMor093__Layer10_1_FILL">
                    <path d="M64.15 346.45q1.75 0 2.95-1.25 1.15-1.2 1.3-2.75v-.2q0-1.8-1.3-3-1.2-1.25-2.95-1.25-1.65 0-2.9 1.15l-.1.1q-1.2 1.2-1.2 3 0 1.7 1.2 2.95 1.2 1.25 3 1.25m6.85-17.2V329q0-1.75-1.25-3-1.2-1.2-2.95-1.2-1.7 0-2.9 1.1l-.1.1q-1.2 1.25-1.2 3t1.2 2.95q1.2 1.3 3 1.3 1.75 0 2.95-1.3 1.15-1.15 1.25-2.7m-8.4-13.45q0 1.75 1.2 2.95 1.2 1.25 3 1.25 1.75 0 2.95-1.25 1.15-1.1 1.25-2.75v-.2q0-1.8-1.25-3-1.2-1.2-2.95-1.2-1.7 0-2.9 1.15-.05 0-.1.05-1.2 1.2-1.2 3m4.5-10.25q1.15-1.15 1.3-2.75v-.2q0-1.8-1.3-3-1.2-1.25-2.95-1.25-1.65 0-2.9 1.15l-.1.1q-1.2 1.2-1.2 3 0 1.7 1.2 2.95 1.2 1.25 3 1.25 1.75 0 2.95-1.25m1.7-30.05h-.05q-.6-.6-1.4-.6-.8 0-1.4.6-.65.6-.65 1.45v.1q.15.8.65 1.3.6.65 1.4.65.85 0 1.45-.65.65-.6.65-1.4 0-.85-.65-1.45m3.9.95q.8 0 1.4-.6.6-.6.6-1.45t-.6-1.4H74q-.55-.65-1.3-.65-.9 0-1.55.65-.5.55-.5 1.4v.1q.05.8.5 1.35.65.6 1.55.6m-20.05-1.95v-.1q0-.85-.65-1.4-.6-.65-1.45-.65-.8 0-1.35.65h-.05q-.6.55-.6 1.4 0 .85.6 1.45t1.4.6q.85 0 1.45-.6.55-.55.65-1.35m7.55 1.85q-.1 0-.1.05-.55.6-.55 1.45t.55 1.4q.6.65 1.45.65.9 0 1.45-.65.55-.5.6-1.3v-.1q0-.85-.6-1.45-.55-.6-1.45-.6-.8 0-1.35.55m-2.3.7v-.1q0-.85-.6-1.45-.55-.6-1.45-.6-.8 0-1.35.6h-.1q-.55.6-.55 1.45 0 .8.55 1.4.6.65 1.45.65.9 0 1.45-.65.55-.5.6-1.3m-6.15-24.2v-.25q0-1.75-1.3-3-1.2-1.2-2.9-1.2t-2.9 1.15q-.05 0-.15.05-1.2 1.25-1.2 3t1.2 2.95q1.25 1.3 3.05 1.3 1.7 0 2.9-1.3 1.2-1.1 1.3-2.7M80 252.6q0-1.75-1.25-3-1.2-1.2-2.95-1.2-1.7 0-2.9 1.15-.05 0-.1.05-1.2 1.25-1.2 3t1.2 2.95q1.2 1.3 3 1.3 1.75 0 2.95-1.3 1.15-1.1 1.25-2.7v-.25z" />
                    <path
                        fill="#FF7B00"
                        d="M65.65 256.8q-.2-.2-.8-.5-2.15-1.2-4.5-.5-2.15.5-3.4 2.4-.4.85-.55 1.75-.2 1.15.15 2.35.65 2.45 2.25 3.4 1.6.9 3.15 1.5 1.55.6 3.7 1.1 2.15.55 4.5 1.2 2.45.6 7.45.85 4.4.2 1.35-3.4-.35-.5-.9-1-4.5-4.7-8.35-6.9-3.9-2.2-4.05-2.25z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor093__Layer10_0_FILL" />
            <use xlinkHref="#ObjMor093__Layer10_1_FILL" />
            <use xlinkHref="#ObjMor093__Layer10_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor093__Layer10_2_FILL" />
            <use
                xlinkHref="#ObjMor093__Symbol_228_0_Layer0_0_FILL"
                transform="translate(21.75 202.05)"
                id="ObjMor093__col1n"
            />
            <use
                xlinkHref="#ObjMor093__Symbol_227_0_Layer0_0_FILL"
                transform="translate(36.7 202.05)"
                id="ObjMor093__col1d"
            />
            <use
                xlinkHref="#ObjMor093__Symbol_229_0_Layer0_0_FILL"
                transform="translate(36.7 216.1)"
                id="ObjMor093__col2n"
            />
            <use
                xlinkHref="#ObjMor093__Symbol_230_0_Layer0_0_FILL"
                transform="translate(36.7 216.1)"
                id="ObjMor093__col2d"
            />
        </g></g>
    )
}

export default ObjMor093
