import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#9A9956",
    "col2n": "#644D27"
}

function LegSho003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M146 4.25V0H0v4.25h146z"
                    id="LegSho003__Symbol_5_0_Layer0_0_FILL"
                />
                <g id="LegSho003__Symbol_4_0_Layer0_0_FILL" fill={colors.col1n}>
                    <path d="M0 .8l11 6.15q6.2 1.7 11.3 0L9.15.45Q8.9.3 8.3 0 3.95-.05 0 .8m36.1 1.85q.45-.5.85-.95Q33.9.75 29.85.55l-1 .9q-.15.05-.2.05L22.3 6.95q4.05 1.95 8.4 0l5.4-4.3m39.05-1.2l-1-.9q-4.05.2-7.1 1.15.4.45.85.95l5.4 4.3q4.75 2 8.4 0L75.35 1.5q-.05 0-.2-.05z" />
                    <path d="M93 6.95L104 .8q-3.95-.85-8.3-.8-.6.3-.85.45L81.7 6.95q3.5 2.7 11.3 0z" />
                </g>
            </defs>
            <use
                xlinkHref="#LegSho003__Symbol_4_0_Layer0_0_FILL"
                transform="translate(140.5 326.75)"
                id="LegSho003__col1n"
            />
            <use
                xlinkHref="#LegSho003__Symbol_5_0_Layer0_0_FILL"
                transform="translate(119.5 333.75)"
                id="LegSho003__col2n"
            />
        </g></g>
    )
}

export default LegSho003
