import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaStu001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    fillOpacity={0.502}
                    d="M80.35 8.55Q66.5 0 47.05 0q-19.5 0-33.3 8.55Q0 17.2 0 29.25q0 12.15 13.75 20.7 13.8 8.55 33.3 8.55 19.45 0 33.3-8.55Q94.1 41.4 94.1 29.25q0-12.05-13.75-20.7z"
                    id="BeaStu001__Symbol_2_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaStu001__Symbol_2_0_Layer0_0_FILL"
                transform="translate(145.45 191.4)"
                id="BeaStu001__col1n"
            />
        </g></g>
    )
}

export default BeaStu001
