import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#008300",
    "col1n": "#00B600",
    "col1s": "#034803",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}

function ShiSpe027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M133.8 1.15q-.1 0-.15.1L129 4.7q-18.25 12.65-40.6 15.5l-.5.45L75.85 37.3l-.5.3-.25-.15-12.6-17.3q-7.75-.95-14.95-3.05Q33.6 13.05 21.5 4.65L15.3 0h-.05l.05.05h-.05q-1.5 2.05-2.9 4.2l-.7 1-1.5 2.4q-.1.1-.15.2-4.2 6.9-7.6 14.6-1.25 2.9-2.4 5.95l19.05 6.95q-.05.2-.05.4L14.6 57.4v.05l-4.25 17.6v.05l-1.2 4.8v.1l-.2.85q-3.45 4.1-5.1 9.05 2.4-.6 4.75-.75 1.05-.1 2.15-.05 4.85 0 10.7 2.45h.05q.85.4 1.75.85 6.9 3.3 13.85 1.85 7-1.4 11.25-1.35 4.25 0 12.1-.1.4-.1.8 0 7.45-.2 14.75-.4 7.7-.25 16.65.95 1.5.2 2.1.2 6.35.85 13.15 1.05 9.2.2 17.75-2.05 7.2-1.8 14.6-2.3 3-.25 7.2-.05-1.6-4.45-5.5-8.9l-.35-.35-.05-.1-2.15-9.1v-.05l-.05-.05-3.45-14.2v-.05l-4.4-21.6v-.05q-.05-.2-.05-.4l19.05-7q-1.15-3-2.4-5.85-4.05-9.2-9.25-17.2l-.7-1Q136.9 2.4 135.6.6l-.4-.5h.05V.05h-.05l-1.4 1.1M23.45 59q.005.169.05.35h-.05V59M39.9 70.6q-.048.048-.05.1v-.1h.05m-26.6 6.3l-.1.1.05-.1h.05z"
                    id="ShiSpe027__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M27.2 3.35q-1.5.15-2.95.35Q14.1 4.85 5.85 9.9H5.8q-.2.05-1.35.8-8.9 4.75 0 1.65.2-.15.45-.2.05-.05.15-.05.3-.1 1.45-.65 1.1-.55 1.5-.7.35-.15 4.55-1.85 4.2-1.7 5.55-1.85 1.35-.2 4.25-.8 2.85-.6 3.4-.65.55-.1 3.05-.35 2.45-.3 5.9-1.05 3.4-.8.7-1.3-2.75.05-5.5.25l-2.7.2m61.45 6.4q-.45-.35-.85-.65-7.35-6.05-17-8.2-1-.35-5.3-.8-4.35-.45-9.65 1.15l-.1.2q8.4 4 17.75 4.35 5.5.15 8.95 4.25.05.1.15.2 9.4 7.05 6.05-.5z"
                    id="ShiSpe027__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M118.8 1.15q-.1 0-.15.1L91.6 35.85l-11.7-10.6q-.15-.15-.3-.2-.25 0-.4.15L60.35 37.6l-16.2-10.9.8.6-3-2q-.15-.15-.4-.15-.15.05-.3.2l-11.7 10.6-4.8-6.15v.1L2.15 1.4.3 0H.25L.3.05H.25Q.1.25 0 .45L29.05 37.1q.1.1.2.15.2.05.35.05.15-.05.25-.15l11.85-10.7 3.25 2.15-.3-.25 14.7 9.9q.7.45.8.45.05.05.15.05h.1q.2 0 .35-.1l18.7-12.3 11.85 10.7q.1.1.25.15.15 0 .35-.05.1-.05.2-.15L120.6.6l-.4-.5h.05V.05h-.05l-1.4 1.1z"
                    id="ShiSpe027__Symbol_121_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.25 14V5.95q-.55.3-1.25.6-.15.05-.25.1-4.4 1.85-8.55 1.55-.4-.05-.75-.1-2.55-.25-4.75-.8-3.05-.75-5.4-2-1.6-.85-2.9-1.9v.05L.2 8.25v.1L0 9.2q.15.05.35.05l-.3-.1q.4.1.5.15H.6q.15.05.3.05.05.05.1.05 1.3.35 1.5.35l.2.1-.65-.1q.25.05.7.15h.05q.15.05.4.05.15.05.35.1h.05q.3.05.65.15 2.6.55 5.15 1.1 1.5.25 3.05.55.25.05.55.1 6.15 1.2 12.25 2.05M89.9 6.45q-3.2.15-6.4.25l-5 .2q-1.25 0-2.55.05V6.9H74.2v.05q-9.45.1-18.1-.05l-2.6-.05q-.55-.05-1.1-.05h-.7l-3.55-.2q-7.1-.3-14.15-.8v9.35q3.6.4 7.2.75h-.25q3.9.15 6.7.3 2.75.15 4.65.15l3.8.15q10.45.65 18.25.4l-.15.05 1.75-.1v.05q4.85-.2 9.7-.5h.05q2.1-.15 4.2-.35.9-.1 1.85-.15l1.9-.2V6.2q-1.9.1-3.75.25m34.85.6q-.05.05-.1.05-1.95 1.1-4.25 1.1-5.4.9-10.95-3.2-3 .3-6.05.5-2.9.25-5.8.45v9.4q14.6-1.65 29.25-4.7v-.05l5.75-1.4-2.15-9.1V.05L130.4 0q-1.1 4.65-5.65 7.05z"
                    id="ShiSpe027__Symbol_124_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M25.25 7.6V5.95q-.55.3-1.25.6-.15.05-.25.1-4.4 1.85-8.55 1.55-.4-.05-.75-.1-2.55-.25-4.75-.8-3.05-.75-5.4-2-1.6-.85-2.9-1.9v.05L.2 8.25v.1L0 9.2q.15.05.35.05l-.3-.1q.4.1.5.15H.6q.15.05.3.05.05.05.1.05 1.3.35 1.5.35l.2.1-.65-.1q.25.05.7.15h.05q.15.05.4.05.15.05.35.1h.05q.3.05.65.15 2.6.55 5.15 1.1l.1-1.6q.75 0 3.7.3 2.9.3 6.1-.3 3.15-.6 3.95-1.05.75-.5 2-1.05m27.05-.8v9.55l3.8.15V6.9l-2.6-.05q-.55-.05-1.1-.05h-.1m39.45 9.1l1.9-.2V6.2q-1.9.1-3.75.25v9.6q.9-.1 1.85-.15m35.1-5.3l5.75-1.4-2.15-9.1V.05L130.4 0q-1.1 4.65-5.65 7.05-.05.05-.1.05-1.95 1.1-4.25 1.1-5.4.9-10.95-3.2-3 .3-6.05.5.75 2.15 5.3 4.1 1.1.5 4 1.05 3.05.55 4.75.55 3.75 0 6.3-.8 1.25-.4 2.35-.95l.75 1.15z"
                    id="ShiSpe027__Symbol_125_0_Layer0_0_FILL"
                />
                <g id="ShiSpe027__Layer11_0_FILL">
                    <path
                        fill="#F3E200"
                        d="M182.3 304.35v13.35h18.1v-13.35h-18.1m8.8 5.4v2.65h6.65v2.65h-12.8v-7.9q6.4.1 12.8.05v2.55h-6.65z"
                    />
                    <path
                        fill="#AD5300"
                        d="M132.7 287.6q-.594-.05-1.25-.05l-3.55 14.7q.752-.183 1.55-.05h.05q.9.1 3.6-.05h.35q.4-.05.85-.05.15-.05.35-.05 6.15-.35 10.9-.85 2.5-.45 3.9-.65.75-.1 5.9-1.5 5.15-1.35 13-4.35l3.65-1.45q4.1-1.75 7.45-3.35.064-.028.1-.05.64-.673 2.9-2.35 2.65-1.95 3.4-2.35 1.1-.7 4.1-2.2 3.6-1.8 4.5-1.8h.1q5.985-4.533 13.35-11.4l.15-.15 1.85-1.75q.15-.15.35-.3v-.05q.15-.15.35-.3.1-.15.25-.25l.4-.4q2.659-2.659 4.35-5.45.555-.899 1-1.8l-4.9-4.4-18.7 12.3q-.15.1-.35.1h-.1q-.1 0-.15-.05-.1 0-.8-.45l-2.4-1.75q-2.65 2.7-5.65 5.15h-.05q-.328 1.552-5.35 4.9-1.9 1.15-4.75 2.9-2.2 1.15-3.8 1.15h-.25l-.1.05q-2.4 1.15-4.85 2.2-2.25.9-4.55 1.7-4.75 1.65-9.85 2.9-.15.05-.25.1-4.45.85-9.05 1.2-3.9.3-8 .3m8-.15q.006.231.05.45h-.05v-.45z"
                    />
                </g>
                <g id="ShiSpe027__Layer11_0_MEMBER_0_FILL">
                    <path
                        fill="#F3E200"
                        d="M169.25 279.2l1.7 2.2 8.5 8.5q1.6-.7 3.15-1.55.75-.4 1.55-.8l.6-.3q.551-.395 2.55-1.5 1.997-1.114 3-1.8 1.003-.636 1.95-1.3.949-.605 2.25-1.45l-8.65-8.65-2.3-2.35q-1.2.9-2.45 1.85-4 3-8.6 5.5-1.6.85-3.25 1.65m11.1 7.1l-6.25-6.05q5.39-2.684 9-5.65l6.35 5.95q-3.35 3-9.1 5.75z"
                    />
                    <path
                        fill="#773900"
                        d="M193 267.2q-.15.1-.35.1h-.1q-.1 0-.15-.05-.1 0-.8-.45l-2.4-1.75q-2.65 2.7-5.65 5.15l2.3 2.35L200.2 264l7.85 5.6 1.85-1.75q.15-.15.35-.3l-7.1-5.25 8.7-5.15 3.75 3.95q.555-.899 1-1.8l-4.9-4.4-18.7 12.3m-61.55 20.35v.1q.3.05.65.15v.1q0-.05.55-.05h.1q.6-.05 2.25.05 1.6.05 2.5.05.8-.05 1.25-.1v.05h1.95v-.45q.006.231.05.45h.2q1.8 0 3.65-.1v.2l.95 13.2q2.5-.45 3.9-.65l-.85-13q6.6-.75 13-2.6l6.75 9.75 3.65-1.45-6.05-9.75q2.5-.95 5-2.1l-1.7-2.2q-2.4 1.15-4.85 2.2-2.25.9-4.55 1.7-4.75 1.65-9.85 2.9-.15.05-.25.1-4.45.85-9.05 1.2-3.9.3-8 .3-.594-.05-1.25-.05z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe027__Symbol_123_0_Layer0_0_FILL"
                transform="translate(117.25 228.55)"
                id="ShiSpe027__col1n"
            />
            <use
                xlinkHref="#ShiSpe027__Symbol_122_0_Layer0_0_FILL"
                transform="translate(147 295.45)"
                id="ShiSpe027__col1d"
            />
            <use
                xlinkHref="#ShiSpe027__Symbol_121_0_Layer0_0_FILL"
                transform="translate(132.25 228.55)"
                id="ShiSpe027__col1s"
            />
            <use
                xlinkHref="#ShiSpe027__Symbol_124_0_Layer0_0_FILL"
                transform="translate(126.2 300.2)"
                id="ShiSpe027__col2n"
            />
            <use
                xlinkHref="#ShiSpe027__Symbol_125_0_Layer0_0_FILL"
                transform="translate(126.2 300.2)"
                id="ShiSpe027__col2d"
            />
            <use xlinkHref="#ShiSpe027__Layer11_0_FILL" />
            <use xlinkHref="#ShiSpe027__Layer11_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiSpe027
