import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M18.65 3.7Q28.6 3.05 29 3.05q6.95.2 10.45.55 3.45.3 3.35-2.1Q40.3-.05 35.35.05q-3-.1-6.95 0-20.05.2-28.4 12Q8.8 4.3 18.65 3.7m92.25 8.35Q102.65.25 82.85.05q-4.05-.1-6.85 0-5.2-.1-7.5 1.6-.45 1.75 3.65 1.85 3.75-.25 10.05-.45.45 0 10.2.65 9.8.6 18.5 8.35z"
                    id="FacBro011__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro011__Symbol_11_0_Layer0_0_FILL"
                transform="translate(137.05 120.25)"
                id="FacBro011__col1n"
            />
        </g></g>
    )
}

export default FacBro011
