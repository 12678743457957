import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#86DE00"
}

function ShiMot030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M39.1 12.6q-1.5-3.4-4.35-6.25-.9-.85-1.8-1.6Q27.45.3 20.1.3q-4.65 0-8.55 1.8-1.5.7-2.9 1.65-1.55 1-2.95 2.35v.05q-.1.1-.25.2-2 2.05-3.35 4.35-.45.75-.8 1.55Q-.6 16.2-.6 21q0 5.4 2.4 9.8l.3.5v.05q.6 1.05 1.4 2.05.5.65 1.05 1.3.45.45.9.95 1.3 1.3 2.75 2.3 1.05.75 2.15 1.35 1.7.95 3.5 1.5h.05q1.65.5 3.4.75 1.35.15 2.8.15 1.25 0 2.5-.1v-.05q.45-.05.95-.1 4.5-.7 8.25-3.3v-.05q1.55-1.05 2.95-2.45Q40.8 29.55 40.8 21q0-4.55-1.7-8.4m-7.45-3.15q2.25 2.25 3.45 4.95 1.35 3 1.35 6.6 0 5.3-2.95 9.4L22.35 19.25V4.8q4.4.55 7.85 3.3.75.65 1.45 1.35m-18.3-3.4q2-.9 4.2-1.2v14.4l-11 10.95q-.35-.5-.7-1.05v-.05l-.2-.4q-1.9-3.45-1.9-7.7 0-3.75 1.5-6.9v-.05q.25-.6.6-1.15 1.1-1.85 2.7-3.45l.2-.2V9.2q1.1-1.05 2.35-1.85 1.05-.75 2.25-1.3m-.9 29.45q-.9-.5-1.75-1.05-.6-.45-1.15-.95l8-8.05V37.2q-1.25-.2-2.4-.55-1.4-.45-2.7-1.15m10.4 1.65q-.25.05-.5.05V25.45l8.15 8.2q-.6.5-1.2.9-2.9 2.05-6.45 2.6z"
                    id="ShiMot030__Symbol_36_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot030__Symbol_36_0_Layer0_0_FILL"
                transform="translate(172.3 261.45)"
                id="ShiMot030__col1n"
            />
        </g></g>
    )
}

export default ShiMot030
