import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#999999"
}

function AccJew047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.4 10.7q.05.05.1.15-.25-1.95-.5-3.75-.35-2-.75-3.8-.05-.1-.05-.15-.1-.3-.3-1.15L20.95.8q-2-.2-5.35-.65h-.15Q14.1 0 12.85 0q-4 .05-7.55 1.85-1.35.65-2.6 1.55-1.35 1-2.6 2.25l-.1.1 1.65 7.4Q2.8 12 4.05 11.1l.3-.2.4-.3h.05q.2-.2.45-.35.9-.6 1.85-1.1l.2-.1Q9.95 7.7 12.8 7.4q4-.55 8.45 1.15l.4.2q1.45.8 2.75 1.95z"
                    id="AccJew047__col1n_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M7.85.4L3.05 0Q.4-.2.3 2.4L0 5.9q-.15 2.6 2.5 2.75l3.5.3-.05.7 1.5.1.05-.7q2.4 0 2.6-2.45l.25-3.45Q10.45.5 7.85.4z"
                    id="AccJew047__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    d="M269.95 274.3l-4.85-.4q-1.85-.15-1.95 1.7l-.3 3.5q-.1 1.85 1.8 2l4.8.4q1.9.05 2-1.8l.3-3.5q.1-1.8-1.8-1.9z"
                    id="AccJew047__Layer4_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M270.1 277.8q.05-1.15-.65-2-.8-.9-1.9-.95-1.15-.1-2 .7-.9.65-.95 1.8-.15 1.2.65 2.15.8.7 2 .8 1 .15 1.9-.6.85-.75.95-1.9m-1.25-1.55q.55.65.5 1.4-.1.9-.7 1.55-.6.5-1.35.45-.9-.05-1.45-.7h-.1q-.5-.7-.45-1.55.05-.8.75-1.35.6-.45 1.5-.4.75.05 1.3.6z"
                    id="AccJew047__Layer4_0_MEMBER_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew047__col1n_0_Layer0_0_FILL"
                transform="translate(250.4 273.55)"
                id="AccJew047__col1n"
            />
            <use
                xlinkHref="#AccJew047__Symbol_117_0_Layer0_0_FILL"
                transform="translate(262.1 273.15)"
                id="AccJew047__col2n"
            />
            <use xlinkHref="#AccJew047__Layer4_0_FILL" />
            <use xlinkHref="#AccJew047__Layer4_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default AccJew047
