import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#6D6D3B",
    "col1s": "#1C1C0A",
    "col2n": "#3A4620"
}

function ShiSpe026({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.75 23.4q-.3-1.3-.65-2.6-.15-.7-.35-1.35l-.05-.05q-.3-1.05-.6-2.05l-.7-2.4q-.05-.05-.05-.1-.05-.1-.1-.25-.05-.2-.15-.45h-13.6V2.75h-16.2v11.4h-18.2V2.75H93.8v11.4H83.1V0H81.9v14.15h-11V2.75H52.6v11.4h-17V2.75H18.2v11.4H4.9q-.15.35-.25.7v.1q-.4 1.2-.75 2.4-.35 1-.6 2l-.05.05q-.2.65-.35 1.35-.35 1.3-.65 2.6-1.4 5.6-2.15 11.5-.05.3-.1.65 4-3.6 10-3.7h1.25q.2 0 .45.05 1.7.05 3.45.5.2.05.45.15 3.05 1.1 5.8 3.55v.05q.2.15.4.35l.05.05-5.7 23.5q.1.05.25.05.25.1.5.15h.15q2.6.6 5.25 1.2l5 1.1q2.5.5 4.95.95.65.1 1.35.25.15 0 .3.05 6.4 1.1 12.75 1.9 3.8.5 7.6.9.7.1 1.45.15l1.8.2h.25l5.65.45q1.85.15 3.7.25 2.4.1 4.8.25h.8l2.1.1h1q.35 0 1.2.05h1.55q.95 0 1.85.05H84.6v.05q1.7-.05 3.4-.05 1.1-.05 2.2-.05 1.1-.05 2.2-.05.5-.05 1-.05 1.45-.1 2.9-.15 1.25-.1 2.5-.15l1.7-.1 1.1-.1 5.65-.45h.25l1.8-.2q.75-.05 1.45-.15 3.8-.4 7.6-.9 6.35-.8 12.75-1.9.15-.05.3-.05.7-.15 1.35-.25 2.45-.45 4.95-.95l5-1.1q2.65-.6 5.25-1.2h.15q.25-.05.5-.15.15 0 .25-.05l-5.7-23.5.05-.05q.2-.2.4-.35v-.05q2.75-2.45 5.8-3.55.25-.1.45-.15 1.75-.45 3.45-.5.25-.05.45-.05H155q6 .1 10 3.7-.05-.35-.1-.65-.75-5.9-2.15-11.5z"
                    id="ShiSpe026__Symbol_118_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.45.1q-.2.1-.25.3L0 21.85v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35L5.2.65V.6q.05-.2-.05-.35Q5 .05 4.8 0q-.2-.05-.35.1M115.4.6v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2L116.4.4q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35z"
                    id="ShiSpe026__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M150.95 23.65q-.25-.6-.5-1.15-4.05-9.2-9.25-17.2l-.7-1q-1.4-2.15-2.95-4.2l.05-.05h-.05l-6.2 4.65Q111.7 18.3 87.3 20.55q-.85.1-1.75.15l-5.85.2h-1.5v.9q-.55-.45-1.2-.9l-7.35-.25q-.2-.05-.35-.05-.85-.05-1.65-.15-24.25-2.25-43.8-15.8L17.65 0h-.05l.05.05h-.05q-1.5 2.05-2.9 4.2l-.7 1q-5.2 8-9.25 17.2l-.5 1.2q-.15.3-.25.6-.9 2.05-1.65 4.15-1.2 3.05-2.25 6.25l-.1.4h13.3v-11.4h17.4v11.4h17v-11.4H66v11.4h11q.65-.4 1.2-.75v.75h10.7v-11.4h18.3v11.4h18.2v-11.4h16.2v11.4h13.6l-.1-.4q-.05-.1-.1-.25l-1.1-3.2q-.5-1.45-1.05-2.85-.9-2.4-1.9-4.7z"
                    id="ShiSpe026__Symbol_120_0_Layer0_0_FILL"
                />
                <path
                    d="M193 249.45h-.55l-.55-.05v23.05l-4-4q-.167-.156-.4-.15-.233-.006-.4.15-.156.167-.15.4-.006.233.15.4l4.65 4.65-5.15 5.1q-.156.167-.15.4-.006.233.15.4.167.155.4.15.233.005.4-.15l4.5-4.45v13.35l-4-4q-.167-.156-.4-.15-.233-.006-.4.15-.156.167-.15.4-.006.233.15.4l4.65 4.65-5.15 5.1q-.156.167-.15.4-.006.233.15.4.167.155.4.15.233.005.4-.15l4.5-4.45v13.85l-4-4q-.167-.156-.4-.15-.233-.006-.4.15-.156.167-.15.4-.006.233.15.4l4.65 4.65-5.15 5.1q-.156.167-.15.4-.006.233.15.4.167.155.4.15.233.005.4-.15l4.5-4.45v9.15h1.2v-9.25l4.5 4.5q.167.155.4.15.233.005.4-.15.155-.167.15-.4.005-.233-.15-.4l-5.05-5.05 5.05-5.1q.156-.131.15-.35-.007-.234-.2-.4-.13-.156-.35-.15-.234.008-.4.15l-4.5 4.55v-14.1l4.5 4.5q.167.155.4.15.233.005.4-.15.155-.167.15-.4.005-.233-.15-.4l-5.05-5.05 5-5.05q.192-.166.2-.4.006-.22-.15-.4-.166-.143-.4-.15-.219-.006-.4.15l-4.5 4.55v-13.6l4.5 4.5q.167.155.4.15.233.005.4-.15.155-.167.15-.4.005-.233-.15-.4l-5.05-5.05 5.05-5.1q.156-.131.15-.35-.007-.234-.2-.4-.13-.156-.35-.15-.234.008-.4.15l-4.5 4.55v-23.15h-.1z"
                    id="ShiSpe026__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe026__Symbol_118_0_Layer0_0_FILL"
                transform="translate(110 249.45)"
                id="ShiSpe026__col1n"
            />
            <use
                xlinkHref="#ShiSpe026__Symbol_119_0_Layer0_0_FILL"
                transform="translate(132.2 263.85)"
                id="ShiSpe026__col1s"
            />
            <use
                xlinkHref="#ShiSpe026__Symbol_120_0_Layer0_0_FILL"
                transform="translate(114.9 228.55)"
                id="ShiSpe026__col2n"
            />
            <use xlinkHref="#ShiSpe026__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe026
