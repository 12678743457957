import { AssetSvgProps } from "../../../shared/assets"

function ShiMot110({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="ShiMot110__Layer1_0_FILL">
                    <path d="M159.2 258v9q33.25 7.516 66.5 0v-9h-66.5z" />
                    <path
                        fill="#FFF"
                        d="M159.2 276v9q32.434 5.716 66.5 0v-9q-32.815-3.042-66.5 0z"
                    />
                </g>
                <g id="ShiMot110__Layer1_0_MEMBER_0_FILL">
                    <path fill="#AAA" d="M159.2 267v9h66.5v-9h-66.5z" />
                    <path fill="#8C2984" d="M159.2 285v9.1h66.5V285h-66.5z" />
                </g>
            </defs>
            <use xlinkHref="#ShiMot110__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot110__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ShiMot110
