import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaAni001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M38.5 12.7q-.1-.15-.25-.2L3.8.05q-.15-.05-.3 0T3.3.3q-.1.1 0 .25.05.15.25.2l33.1 12H.4q-.15-.05-.3.1-.1.1-.1.25t.1.3q.15.1.3.1h35.5L3.55 24.8q-.2.05-.25.2-.1.15 0 .3.05.15.2.2.15.05.3 0l34.45-12.05q.15-.05.25-.2.05-.15 0-.25.05-.15 0-.3M109.4.55q.05-.15 0-.25-.05-.2-.2-.25-.15-.05-.3 0L74.45 12.5q-.15.05-.25.2-.05.15 0 .3-.05.1 0 .25.1.15.25.2L108.9 25.5q.15.05.3 0t.2-.2q.05-.15 0-.3t-.2-.2L76.8 13.5h35.5q.15 0 .3-.1.1-.15.1-.3t-.1-.25q-.15-.15-.3-.1H76.05l33.15-12q.15-.05.2-.2z"
                    id="BeaAni001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaAni001__Symbol_1_0_Layer0_0_FILL"
                transform="translate(136.15 196.05)"
                id="BeaAni001__col1n"
            />
        </g></g>
    )
}

export default BeaAni001
