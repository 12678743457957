import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#8A6900",
    "col1n": "#BD9100"
}

function ObjMor059({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M7.6 12.3q-1.9-.65-3.65 0-.2.9.2 1.4.3.75 2.7 2.15.15.1 1.25 1.25.6.6.9.85v.1q-.2.9-.85 1.55l-.1.1q-.9.95-2.2.95-.25 0-.5-.05-.25 0-.5-.1H4.8q-.7-.3-1.2-.8-.45-.45-.7-1.05-.15-.5-.15-1.1 0-1.25.75-2.1.15-1.8-2.05-1.85l.15.15q-.85.85-1.25 2.1-.8 2.2.25 4.35 1.05 2.1 3.3 2.9 1.4.45 2.85.25l6.5 13.3-3.65 1.8 2.7 5.45 2.8-1.35 1.35 2.75-2.8 1.35.95 2 6.1-3L9.25 22.2q1.35-.95 1.9-2.6 0-.05.05-.1.05-.2.15-.4l.1-.6q.15-1.4-.4-2.8l-.2-.5Q9.8 13.05 7.6 12.3m13.3-.35q-.05-.05-.1-.05-1.15-.5-2.45-.4-2.4.1-3.95 1.85-.95 1.05-1.3 2.35 2.5 1.25 2.75.25.2-1 .4-1.2.95-.9 2.25-.9h.35v.05q0 .15.75.8.8.7 1.25.7.2 0 .35-.05.45.7.45 1.65 0 .65-.25 1.25-.2.45-.6.8l-.1.1q-.9.95-2.2.95-.25 0-.5-.05l-.4-.1q-.75-.2-1.35-.8-.45-.45-.7-1.05v-.05q-1.2-1.05-2.6.45h.1q0 .05.05.15.4 1.55 1.7 2.75 1.7 1.6 4.05 1.45 1.55 0 2.75-.8l10.95 10-2.75 3 1.35 1.2 1.85-2 1.75 1.6-1.85 2 2.05 1.9 1.75-1.9 1.7 1.5-1.75 1.9 1.55 1.45 4.6-5-19.25-17.55q.85-1.5.8-3.2-.05-.05-.05-.1-.1-1.7-1.05-3-.05-.1-.15-.15v-.05l-.05-.05q.1-.15.1-.4 0-1.4-1.5-1.4-.5 0-.75.15m25.45 7l2.95 1.4-.95 1.95 4.8 2.25 2.9-6.2-23.55-11q.4-1.6-.2-3.25-.05-.05 0-.05-.8-2.2-2.95-3.2Q27.7.1 26.05.4h-.15q-.25-.4-.8-.4-.8 0-1.25.6-.3.4-.3.7v.15q-.05 0-.1.05-1.1.8-1.7 2.1-1 2.15-.2 4.35.3.9.85 1.55 1.1-.55 3.1-4.55.6-1.3.9-2.05h.05q.15-.05.35-.05 1.3 0 2.2.9.95.9.95 2.25 0 .65-.25 1.25-.2.45-.6.8l-.1.1q-.9.95-2.2.95-.25 0-.5-.05l-.4-.1q-.15-.05-.25-.1-2.45.6-1 2.35.15.05.3.15 1.9.75 3.9 0 1.4-.5 2.4-1.55l13.4 6.25-1.75 3.7 2.5 1.15.95-1.95z"
                    id="ObjMor059__Symbol_152_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M16.9 28.3l.7-.2q1.15-.35 2.25-.9 1.35-.65 2.5-1.5l.2-.1q-.2-.35-.5-.6-.85-.85-2-.9-.85.65-2.65 1.3l-.1.05q-.55.45-2.85.5-1.05.1-2.05 0 .55 1.4.4 2.8h1.5l2.6-.45M5.3 22.55q-.4-.4-.75-.85-.25.45-2 1.85.15.15.25.3l2.6 2.4.5.3q1.4.85 3 1.4.35.1.75.25l.7.1v-.5q0-1.35-.95-2.25-.7-.7-1.7-.85-1.45-1-2.4-2.15m22.55-7.4q.05-.55.05-1.1l-.15-.9q-1.05.1-1.85.85-.55.55-.7 1.3-.15.4-.15.9-.35 2.15-1.3 3.55-.65 1.3-1.6 2.4 1.15.3 2.25 1.65l.05.1v.05q.9-1.25 1.55-2.5L27.05 19l.1-.3q.6-1.8.7-3.55m-.45-4.5q-.4-1.3-1-2.6-2.25-4.55-6.7-6.6-.75-.35-1.55-.6-5.6-1.95-10.9.65Q1.9 4.15 0 9.8q1.25.25 2.55 1.05 0-.1.05-.2Q4.15 6.15 8.45 4q4.3-2.1 8.8-.55 1.35.45 2.45 1.15 2.7 1.65 4.2 4.7.6 1.2.9 2.45 1.3-1.05 2.6-1.1z"
                    id="ObjMor059__Symbol_85_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor059__Symbol_152_0_Layer0_0_FILL"
                transform="translate(256.25 284.6)"
                id="ObjMor059__col1n"
            />
            <use
                xlinkHref="#ObjMor059__Symbol_85_0_Layer0_0_FILL"
                transform="translate(254.9 274.35)"
                id="ObjMor059__col1d"
            />
        </g></g>
    )
}

export default ObjMor059
