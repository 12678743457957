import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FFFFFF"
}

function HaiSpe033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M182.25 76.3q4.15 9.05 5.5 18.45 1.3 9.4 6.1 15.4 3.9 4.9 8.8 11.05 0 .05.05.15 0 .15.05.3l1.4 5.65q.05.3.15.6l2.75 8.85q3.8 12.9 8.5 15.8.75-14.95 4.7-12 3.95 2.95 9.3 6.6-.3-1.8-2.65-10.3l-1.95-6.7q5.5-2.1 7.75-4.15v-.3q0-4.35-18.5-7.95-3.15-1.25-2.95-4.35l.05-.05q0-.1.05-.15 3.75-28.45-5.9-52.85-2.5-5.2-5.85-10.65-8.8-12.7-18.35-22.15-11.35-12.1-25.8-18.9Q148.1 5.2 140 3.1q-25.45-6.15-49.95 0Q46.7 13.45 26.3 56.95 18.8 72.55 17.1 90q-2.5 22.2 6.3 44.45 1.05 4.7-2.65 8.45-3.9.25-15.2 7.65-1.85 1.2-5.55 5.65 9.6 2.05 14.5.4.2-1.25 1.8 14.9 3.75-.9 8.7-7.25l5.15 8.15q6-6.9 3.05-19.55l-.1-.3q-.05-.35-.05-.7l-1.4-9.75q0-.15-.05-.35-3.4-27.45 1.45-41.8.25-.55.55-1.05 2.8-5.5 5.85-9.6 6.45-8.7 14-11.4 11.15-4.05 21.35-9.7 10.2-5.65 15.15-9.4 4.95-3.75 15.8-14.65 2 1.55 4.4 2.95 1.6 1 17.15 7.9 12.8 5.85 31.8 9.05 18.95 3.15 23.15 12.25z"
                    id="HaiSpe033__Symbol_165_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.5 81.35q0 5.3.95 11.45.85 5.5 1.05 7.8.05.3.15.6.1.35.25.65-1.35-1-2.55-1.95-.55 1.55-1.75 2.95-.2.6-.35 1.25-.1.5-.15 1-.1.85-.1 3.9 0 3.6.1 4.4.25 2.35 1.4 3.6 2.65-3.75 4-4.5 1.7 3.4 3.1 10.7.85 4.6 1.35 9.1 5.2-6.85 2.45-18.8l-.1-.3q-.05-.35-.05-.7l-.85-5.95q-1.7-1.2-3.3-2.3.25-.2.4-.5.5-.95.5-3.25 0-4.7-3.2-12.8-3.2-7.25-3.3-7.45v1.1M155 27.25q-3.85-4.8-16.95-7.55-13.15-2.75-21.7-4.4-8.55-1.7-13.35-3.55Q98.85 10.1 92.35 6q-6.7-4.35-8.1-6Q75.1 10.65 61.5 18.6q-8.95 5.25-24.75 11.9-6.6 2.75-13.75 11.3-5.65 6.75-8.5 14.65 2-3.6 4.15-6.5 6.45-8.7 14-11.4Q43.8 34.5 54 28.85q10.2-5.65 15.15-9.4Q74.1 15.7 84.95 4.8q2 1.55 4.4 2.95 1.6 1 17.15 7.9 12.8 5.85 31.8 9.05 16.3 2.7 21.7 9.85-1.2-2.5-5-7.3M186 71q-2.05-2.4-3-6.8-.75-4.6-1.25-6.2-.6 2.2-.7 3.95-.05.85-.05 4.65 0 5.75.2 7.45.5 3.75 1.9 6.25.55.3 1.6.9 1.35.8 2.6.8-2.1 1.9-4.05 3.75-.1.1-.35.3l.45 1.9q0 .1.05.25t.1.35l2.75 8.85q3.8 12.9 8.5 15.8v-.2q.1-1.8.25-3.35 0-5.65-.1-7-.45-6.75-2.3-11.7 3.25 2.05 7.65 4.05-1.3-5.35-4.55-10.75-2.2-3.2-3.75-5.55.25-.2.55-.45h.55q-.55-.25-.95-.55-.85.75-1.65 1.45-.2-2.25-1.5-4.35-.6-1.05-2.95-3.8z"
                    id="HaiSpe033__Symbol_97_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.8 21.55q-.1 0-.35.05-7.25.95-2.65 8.35.05.05.15.1.95.7 2.15 1.55.55.2 5.25 3.85Q14 39.05 14.1 39q4.95-4.3-.15-8.65-.6-.55-1.25-1.1-.1-.05-.15-.1-1.55-1.35-3.5-2.9-2.4-2.3-4.25-4.7M195.05 5.2v-.9q.65-5.75-2.7-3.8l-.4.2-.3.2q-2 2-3.85 3.8-1.6 1.45-3.55 3.6l-.25.25q-.1.15-.2.25-2.25 3-.35 4.3 1.85 1.3 1.8 1.35 2.575.222 5.4-2.95 1.8-2.1 4.4-6.3z"
                    id="HaiSpe033__Symbol_25_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe033__Symbol_165_0_Layer0_0_FILL"
                transform="translate(78.45 56.4)"
                id="HaiSpe033__col1n"
            />
            <use
                xlinkHref="#HaiSpe033__Symbol_97_0_Layer0_0_FILL"
                transform="translate(99.25 95.75)"
                id="HaiSpe033__col1d"
            />
            <use
                xlinkHref="#HaiSpe033__Symbol_25_0_Layer0_0_FILL"
                transform="translate(97.4 169.25)"
                id="HaiSpe033__col2n"
            />
        </g></g>
    )
}

export default HaiSpe033
