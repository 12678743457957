import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.7 30.25q-.25-1.05-.35-2.3-.1-1.85-.2-3.2Q46 20.95 43.3 19q-3.4-2.4-12.65-3.55-6.4-.8-12-7.75-3.9-4.85-7.6-6.6-4.85-2.15-9.7 0-.6.35-1.35.85v.4q4.6.1 6.15.6 3.9 1.25 7.6 6.4 1.05 1.45 3.45 4.85 2.05 2.85 2.95 3.65 4.1 3.8 10.25 5.5 4.2 1.15 5.7 2.35 1.9 1.55 1.9 4.55v.2q0 1.05-.35 2.25-.4 1.25-.4 2.1 0 1.9 2.5 4.8t2.5 6.55q0 1.7-.85 4.45-.9 2.8-.9 3.55 0 2.85 3.15 5.4 3.1 2.55 3.1 4.75 0 1.8-2 4.5t-2 4.9q0 2.75 1.95 5.45t1.95 4v.45q-.75 1.2-1.95 2.95-.6.85-1.05 1.65-1.35 2.2-1.8 3.9-1.25 4.65 3.3 8.45.6.45 1.45 1.05 4.9 3.3 3.6 9.45-.45 3.6-2.5 5.85-2.65 2.85-2.65 5.35 0 2.65 2.9 5.9 2.15 2.5 2.7 5.3.15 5.05-3.65 7.15-3.45 3.1-3.45 7.75v2.75q.05 2.45.9 4.3 1.8 4.15 6.85 4.15 3.1 0 3.65-1.75h-.15q-2.1 0-3.65-1.15-.45-.2-.9-.55-.2-.3-.5-.65-1.2-1.45-1.2-4.1 0-2.65 1.25-4.05t6.2-4.35q1.3-.8 2.15-2.2 3.1-5.45.4-10.95-4.1-5.8.55-9.3 2.75-2.3 3.55-3.4 2.85-6.2 0-12.35-1.45-3.75-4.6-6.4-4.1-3.15-4.55-3.65-2-2-2.15-4.25-.6-2.7 1.8-5.6 1.6-2 2.1-2.95.75-1.45.75-3.2 0-1.1-1.5-3.05-1.5-1.95-1.5-4.4 0-1.45 1.25-4.85 1.25-3.35 1.25-5.3 0-1.8-1.6-3.75-1.65-1.95-1.65-3.65 0-1 1.25-4.35 1.25-3.35 1.25-5.7 0-3.2-3.4-6.9-1.6-1.75-2.45-3.85-.85-1.05-1.2-2.65z"
                    id="HaiHig012__Symbol_16_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig012__Symbol_16_0_Layer0_0_FILL"
                transform="translate(213.55 70.5)"
                id="HaiHig012__col1n"
            />
        </g></g>
    )
}

export default HaiHig012
