import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#BFBFBF",
    "col1n": "#999999"
}

function AccJew045({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4.3 0Q2.45 0 1.2 1.2 0 2.5 0 4.3t1.2 3q1.25 1.3 3.1 1.3 1.8 0 3-1.3 1.3-1.2 1.3-3T7.3 1.2Q6.1 0 4.3 0z"
                    id="AccJew045__Symbol_113_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M192.65 238.8q.75 0 1.25-.55.55-.55.55-1.25 0-.75-.55-1.25-.5-.55-1.25-.55-.7 0-1.25.55-.55.5-.55 1.25 0 .7.55 1.25t1.25.55z"
                    id="AccJew045__Symbol_60_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew045__Symbol_113_0_Layer0_0_FILL"
                transform="translate(188.2 233.95)"
                id="AccJew045__col1n"
            />
            <use
                xlinkHref="#AccJew045__Symbol_60_0_Layer0_0_FILL"
                id="AccJew045__col1l"
            />
        </g></g>
    )
}

export default AccJew045
