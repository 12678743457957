import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#D19532",
    "col1n": "#E4B670",
    "col1s": "#957032",
    "col2n": "#000000"
}
function FacMou096({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M51.8 15.6l13.5-7.9V0H0v8.8l15 6.8h36.8z"
                    id="FacMou096__Symbol_156_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M62.25 12.1q.4-1.25.4-2.5 0-2.35-1.45-4.05-1.85-2.15-5.65-2.75h-1.1q-.1-.05-.3-.05h-1.6q-1.4.2-2.65.7L48.05 2.4q-1.7-.7-3.5-1.2-10.2-2.4-19.95 0-1.85.5-3.55 1.2L19.2 3.45q-1.25-.5-2.65-.7h-1.6q-.2 0-.3.05h-1.1q-3.8.6-5.65 2.75-1.45 1.7-1.45 4.05 0 1.25.4 2.5 0 .05.05.1 0 .15.1.4 0 .05.05.15l-1.3 1.6Q-.7 22.7.1 33.85q.9 8.65 9 13.05.4.25.85.5 2.2 1.55 5.4 3.7.2.15.4.25 3.85 2.3 7.75 3.55 11.65 3.6 22.15 0 3.85-1.25 7.7-3.55.2-.1.4-.25 3.2-2.15 5.4-3.7.45-.25.85-.5 8.1-4.4 9-13.05.8-11.15-5.65-19.5l-1.3-1.6q.05-.1.05-.15.1-.25.1-.4.05-.05.05-.1m-21.6 26.25q12.75-.3 19.1-3.9 2.7-1.65 4.3-3.9 1.15-2 1.8-3.15v.45q0 2.35-1.3 4.6-1.6 2.25-4.35 3.9-6.5 3.6-19.45 3.9h-12.4q-12.95-.3-19.45-3.9-2.75-1.65-4.35-3.9-1.2-2.1-1.3-4.25.3.4.65.85 2.75 3.75 5.45 5.4 6.4 3.6 19.15 3.9h12.15z"
                    id="FacMou096__Symbol_155_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1 10l-1 1.25q1.15 3.8 6.25 5.6l1.5.5q-.15-.1-.35-.2h-.05Q2.15 14.15 1 10M13.15.7q1.8.7 3.15 2.05 1.55 1.7 1.6 1.8.1.1.15.2Q16.7.95 14.45 0l-1.3.7m27.8 40.45l.05-.05q-.35.05-.65.05-5.8.8-11.7.85h-.35q-5.8-.05-11.7-.85-.25 0-.6-.05l.05.05q12.45 4.95 24.9 0m-.25-38.4Q42.05 1.4 43.85.7l-1.3-.7q-2.25.95-3.6 4.75.05-.1.15-.2.05-.1 1.6-1.8m8.95 14.4h-.05q-.2.1-.35.2l1.5-.5q5.1-1.8 6.25-5.6L56 10q-1.15 4.15-6.35 7.15z"
                    id="FacMou096__Symbol_153_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M6.3 1.35q-.4-.6-1-.95Q4.7.1 4 0h-.2Q2.35-.15 1.2.7q-.15.1-.2.25-.6.6-.9 1.5-.05.2-.1.45v.4q-.05.4.05.8.15.9.65 2 .7 1.25 1.75 2 1.3 1.3 3.45 1.6.55.05 1.05.1-1.3-1.85-2.8-5.65.25-.1.5-.15 2.3-.75 2.6.2-.55-2.25-.95-2.85M50.4 2.9q-.05-.25-.1-.45-.3-.9-.9-1.5-.05-.15-.2-.25-1.15-.85-2.6-.7h-.2q-.7.1-1.3.4-.6.35-1 .95t-.95 2.85q.3-.95 3.2-.7h.5q-.2.5-.4 1.05-1.6 4.5-3 5.25.5-.05 1.05-.1 2.15-.3 3.45-1.6 1.05-.75 1.75-2 .5-1.1.65-2 .1-.4.05-.8v-.4z"
                    id="FacMou096__Symbol_154_0_Layer0_0_FILL"
                />
                <path
                    d="M174.8 202.75q-.2-.4-.25-.8-.3-.95-1-1.45-.85-.55-1.6-.65-.95-.15-1.5.45-.45.45-.6 1.3-.4 3.2 2.15 4.85.85.55 2.25 1.1.95-.15 1.6-.5.65-.45.75-1.15.05-.35 0-.65l-.05-.05q-1-1.1-1.1-1.15-.4-.55-.65-1.3m40.35-1.15q-.15-.85-.6-1.3-.55-.6-1.5-.45-.75.1-1.6.65-.7.5-1 1.45-.05.4-.25.8-.25.75-.65 1.3-.1.05-1.1 1.15l-.05.05q-.05.3 0 .65.1.7.75 1.15.65.35 1.6.5 1.4-.55 2.25-1.1 2.55-1.65 2.15-4.85z"
                    id="FacMou096__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou096__Symbol_156_0_Layer0_0_FILL"
                transform="translate(159.8 217.5)"
                id="FacMou096__col2n"
            />
            <use
                xlinkHref="#FacMou096__Symbol_155_0_Layer0_0_FILL"
                transform="translate(157.95 191)"
                id="FacMou096__col1n"
            />
            <use
                xlinkHref="#FacMou096__Symbol_153_0_Layer0_0_FILL"
                transform="translate(164 193.75)"
                id="FacMou096__col1d"
            />
            <use
                xlinkHref="#FacMou096__Symbol_154_0_Layer0_0_FILL"
                transform="translate(167.3 197.75)"
                id="FacMou096__col1s"
            />
            <use xlinkHref="#FacMou096__Layer1_0_FILL" />
        </g></g>
    )
}
export default FacMou096
