import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#621F00",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function ObjToo044({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M4.25 158.45q-1.2-1.2-1.75-2.6.9 2.85.6 6.15-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1l-.15.15v26.3h4.9v-36.55q-.35-.3-.65-.6M4.9 1.05Q2.65-1 0 1.05V151.2q.2.2.4.45 0 .05.05.1.15.15.3.35.95 1.5 1.55 3.1-.3-1.05-.3-2.15 0-3.15 2.25-5.4.3-.3.65-.55V1.05z"
                    id="ObjToo044__Symbol_204_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M84.9 25.05h1l-8.95 38h9.2v6.3q-9-.2-16.5-.95-.15-.05-.3-.05-1.05-.15-2.05-.25-4.05-.6-7.8-1.7-1.3-.4-2.55-.85-22.35-7.8-32.2-34.75-3.8 3.9-6.65 7.3-1.65 2-2.95 3.85-26.5 36.4-6.6 74.15 3.85 7.3 9.45 14.7.8 1.05 1.65 2.1.2.2.35.45 2.75 3.35 5.8 6.75 1-2.3 1.95-4.45 1.3-2.5 2.65-4.85 5.1-8.5 11.9-14.7 6.55-5.95 14.65-9.9 1.25-.6 2.55-1.15 9.85-4.6 22.1-5.95v.05q1.7-.25 3.35-.35l1.2-.1v29.95h5.65v-29.6h6q.35-2.4 1.25-4.7 2.5-6.05 7.85-6.05 3.1 0 4.9 1.35 3.8 1.8 9.15 6.75-3.55-20-27.15-26.15-.65-.05-.9-.4-.75-.05-.9-.6l-.2-.1v-44.1h-1V0l-5.9 25.05M98.2 78.6q-.7-.05-.5-.25-.15-.45-.2-.85.25-.45.7-.7.6.25.6.8v.55q-.55.4-.6.45m2.45.1q-.2-.75-.2-.85.4-.55.7-.75.7.35.7.9 0 .85-.1.5-.4.4-.6.45-.7-.05-.5-.25m-6.3-2.3q.7.35.7.9 0 .7-.7.9l-.6-.2v-.95q.2-.4.6-.65m-.55 8.5q.7.35.7.85 0 .85-.7.95h-.05v-1.75l.05-.05m.95-3.45q0 .8-.65 1l-.35-.15V81q.2-.25.35-.35.65.5.65.8m2.6-.45q.6.5.6.8v.6q-.25.25-.6.4-.65-.35-.65-1.05.45-.6.65-.75M64.3 73.3q.7.2 1.05.5 0 .75-1.05 1.25-.7-.2-1.05-.6v-.35q0-.65 1.05-.8m-14.2-4.05q3.65 0 3.65 2.55 0 2.65-3.4 2.65-3.45 0-3.5-2.65.2-2.55 3.25-2.55m3.65 14.3q0 .05-.45 1.4-.85 1.25-2.95 1.3-3.45-.05-3.5-2.65.15-2.65 3.5-2.6 3.4-.05 3.4 2.55m2 14.15q-.8 1.25-2.9 1.25-3.45 0-3.5-2.6.15-2.65 3.5-2.65 3.4 0 3.35 2.55.05.05-.45 1.45M74.1 75.15l-.15-.35Q74 74.05 75 74q.8.2 1.1.45 0 .85-1.1 1.35-.85-.45-.9-.65m1.8 8.8q-.35.5-.9.5-1.1 0-.85-.25l-.2-.5q.05-.6 1.05-1.05 1.1.15 1.1.8 0 .95-.2.5m-12.75-1.6q1.4-.05 1.4.75 0 .35-.4.85l-1 .15q-1 0-1.2-.3v-.75q.45-.75 1.2-.7M64.3 92.5q1.05.1 1.05.75t-1.05 1q-.75-.2-1.05-.55v-.45q0-.65 1.05-.75m11-.4q0-.75 1.05-1 1.05.1 1.05.75 0 .85-.2.5-.4.5-.85.5-1.1 0-.85-.3l-.2-.45z"
                    id="ObjToo044__Symbol_83_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M18.1 37.9q-1.65 2-2.95 3.85-26.5 36.4-6.6 74.15 3.85 7.3 9.45 14.7.8 1.05 1.65 2.1l9.8-15.3-.8-1.5q-14.5-28.2.8-58.7L18.1 37.9m66.8-13.05h1l-8.95 38h9.2v65.6h5.65V24.85h-1V0h-.05L84.9 24.85z"
                    id="ObjToo044__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo044__Symbol_204_0_Layer0_0_FILL"
                transform="translate(122.3 138.55)"
                id="ObjToo044__col1n"
            />
            <use
                xlinkHref="#ObjToo044__Symbol_83_0_Layer0_0_FILL"
                transform="translate(35.7 10.95)"
                id="ObjToo044__col2n"
            />
            <use
                xlinkHref="#ObjToo044__Symbol_42_0_Layer0_0_FILL"
                transform="translate(35.7 11.15)"
                id="ObjToo044__col2l"
            />
        </g></g>
    )
}

export default ObjToo044
