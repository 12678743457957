import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#644D27",
    "col2n": "#A37831"
}

function ShiSpe024({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M127.8.25q.05-.15.1-.25l-1.85 1.4q-1.3 1-2.7 2.05-.05 0-.1.05l-1.75 1.3q-.3.2-.6.45-11.65 7.8-24.85 11.75-1 4.85-6.55 9.9-9.6 7.1-23.65 14.25l-.5.35q-.3-.25-.9-.55Q39 28.15 37.35 16.3q-11.05-3.65-20.9-10Q15.2 5.5 14 4.65L12.45 3.5q-.25-.2-.5-.35-.05-.05-.15-.1 0-.05-.05-.05l-.9-.7-1.2-.9L7.85.05l.05.1V.2q.5 1 .95 2.1 1.45 3.55 2.35 7.8 1.5 7.55 1.1 17.3l-.1 2.4q-.2 2.35-.45 4.8l-.1.8v.05l-2.7 14q-.55 2.7-1.1 5.45L4.4 69.2q-.65 2.5-1.25 5.05l-1.5 6.1h.05L.05 86.2q-.4 1.75 2.6 2.3.6.05 1.25.15 6.6.9 20.1 2.8 20 2.8 22.95 2.8 2.9 0 8.65-1.25 5.75-1.3 8.6-1.3 6.3 0 21.75 2.55 4.55.75 9.1-.35 1.8-.3 4.25-.8 6.2-1.3 8.15-1.9 6.25-2 16.7-2 1.65 0 5.8-.25 4.15-.3 5.15-.95.4-.3.7-.7.6-1.6 0-2.8l-1.7-4 .05.25q-.05-.1-.05-.15l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75q-.05-.45-.1-.85-.4-3.75-.55-7.2-.05-1.2-.05-2.4-.2-12.95 3.25-21.8.1-.3.25-.6h-.05q.5-1.2 1.1-2.35z"
                    id="ShiSpe024__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M131.05.05q-1 .1-1.25.1-.45.05-.85.1Q99.35 2.7 69.1 2.8h-6.9l-1.6-.05v.05h-1.85Q31.55 2.45.9 0L0 3.75q66 6.2 132 0l-.95-3.7z"
                    id="ShiSpe024__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill="#1C150B"
                    d="M189.9 270.1h.1l-.05-.05q-.266-.187-.85-.5-.15 0-.3.1-3.6 2.65-4.35 2.95-.15 0-.2.15-.15.1-.15.25t.05.3q.176.342.3.65.65 1.413.65 2.35v1.3q-.2.15-.75.55-.15.05-.2.2-.05.15-.05.3.05.15.15.25.45.5 2.5 1-.45.5-1.15 1-1.65 1.1-1.85 1.3-.15.15-.15.3 0 .2.1.35.1.1.25.2.1.05 2.1 1.45 1.2.85 2.1.75-1.45 1.3-2.2 2.2-1.7 2.15-.8 4.3.05.1.15.2.05.05.2.1.65.2 2.95.9 1.6.75 1.65 2.1 0 .836-.15 1.4.05.103.05.2 0 .36-.45.85-.423.466-1.25 1.05-1.233.87-1.6 1.45-.023.145-.1.3V300.45l.05.2q.45 2 .45 6.4v.05h.95l-.5-6.75q1.3-1.15 2.65-2.1.05 0 .1-.05.8-.85.8-3.15.05-2-2.3-3.05h-.05q-1.95-.6-2.75-.85-.45-1.55.75-3.1v.05q.85-1.05 2.7-2.65v-.05q-.09-.356-1.25-1-.463-.168-1.2-.6-1.45-.9-2-1.25.4-.35 1.15-.8 1.4-.95 1.9-1.95.05-.15.05-.3-.05-.2-.15-.3-.1-.1-.25-.15-1.4-.3-2.15-.55.35-.25.45-.35 0-.1.05-.15.05-.15.05-1.7 0-1.17-.75-2.95l4.55-3.25z"
                    id="ShiSpe024__Layer11_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe024__Symbol_50_0_Layer0_0_FILL"
                transform="translate(124.65 228.6)"
                id="ShiSpe024__col1n"
            />
            <use
                xlinkHref="#ShiSpe024__Symbol_48_0_Layer0_0_FILL"
                transform="translate(126.5 304.35)"
                id="ShiSpe024__col2n"
            />
            <use xlinkHref="#ShiSpe024__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe024
