import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#2B3C4A"
}

function ShiMot034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M43.6 8.4q-.05-3.5-2.5-5.95T35.15 0q-3.5 0-5.9 2.45-2.5 2.45-2.5 5.95 0 1.95.8 3.65-2.5-1.65-5.7-1.65-3.3 0-5.85 1.75.8-1.7.8-3.75 0-3.5-2.45-5.95T8.4 0Q4.9 0 2.5 2.45 0 4.9 0 8.4t2.5 5.95q2.4 2.45 5.9 2.45 1.3 0 2.5-.35-2.6 3.4-2.55 7.9v.5q.15 5.15 3.85 8.9 3.9 3.85 9.4 3.85t9.4-3.85q3.7-3.75 3.9-8.9v-.5q0-4.6-2.7-8.05 1.35.5 2.95.5 3.5 0 5.95-2.45t2.5-5.95m-31.2 7.45q-.106.056-.2.15v-.05l.2-.1z"
                    id="ShiMot034__Symbol_40_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot034__Symbol_40_0_Layer0_0_FILL"
                transform="translate(170.65 263)"
                id="ShiMot034__col1n"
            />
        </g></g>
    )
}

export default ShiMot034
