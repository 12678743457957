import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFDC00",
    "col2d": "#C40000",
    "col2n": "#FF0000",
    "col2s": "#770707"
}

function ShiSho041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M124.4 41.2l-2.85-14.75-.5-1.8L116.8 0Q65.6 29.85 14.05 0l-4.8 26.4v.05l-2.7 14q-.55 2.7-1.1 5.45L2 60.2Q1 64.1.05 68l-.05.15q.3.1.65.15.15.05.25.1h.05l1 .2q61.9 18.35 127 0l1.9-.5-5.15-20.75q-.65-3.1-1.3-6.15z"
                    id="ShiSho041__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M140.55 77.1l-1.9.5-2.2.5q-60.95 14-122.7 0-.15-.05-.3-.05l-2.8-.65h-.05q-.1-.05-.25-.1-.35-.05-.65-.15l-.9 3.45v.25l-.05.05h.2q.25.1.5.15H9.6q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H140.75q.25-.05.5-.15h.2l-.05-.2v-.1l-.85-3.5m-57.1-35.5h2.5l-1.4-1.15-.15-.15q-1.55-1.2-3.75-1.3H68.1q-1.35-.05-2.65 0-3.3.1-6.5.75-.75.15-1.45.35l-.8.2q-3.05.95-6.15 2.7-6.5 3.35-14.6 3.8-1.15 0-2.15.05-4.6.3-6.05 2.75v1.75h1.5v3.2h-.95q.25.35.55.65 2.95 3.4 8.95 3.6h7.05q0-.25.05-.55.2-2.05 1.7-3.55 1.7-1.7 4.15-1.7t4.15 1.7q1.7 1.7 1.7 4.1v.05q0 .5-.05.95H99.3q-.1-.45-.1-.95v-.05q0-.25.05-.55.2-2.05 1.65-3.55 1.75-1.7 4.2-1.7 2.4 0 4.15 1.7 1.7 1.7 1.7 4.1v.05H117.35q1.55-.25 2-1.65.05-.2.1-.35v-2.5h2.3v-3q-.8-1.6-5.7-2.6l-24.45-2.3h-2.95l-5.2-4.85m-2.15 0l5.35 4.85H60.5l9.45-4.85H81.3m65.2-22.5q-1.8-3.95-3.85-7.6-.95-1.75-1.95-3.4-.1-.1-.15-.2-.6-.95-1.15-1.9-2-3.1-4.2-6l-1.85 1.4-.6.5q-1.05.8-2.2 1.6L129 4.65q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16L19.75 3.5q-.25-.2-.5-.35-1.2-.9-2.3-1.75L15.1 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.3.5-.6 1.05-.55.9-1.05 1.85-2 3.65-3.8 7.5-1.4 3-2.65 6.15-.4 1.05-.8 2.15-.2.45-.35.95l18.95 7.05 1-3.85q0-.05.05-.05 3.55-13.3 9.95-17.3.1-.1.2-.15 2.1 1.2 4.3 2.25 11.85 5.2 25.05 7.1 7.6 1.1 15.65 1.1 23.55 0 43.3-9.4.2-.1 1.45-.65 6.8 5.05 9.8 15.55l1.55 5.45 19.1-7.1q-.2-.5-.35-.95-.45-1.1-.85-2.15-1.25-3.15-2.65-6.15z"
                    id="ShiSho041__Symbol_94_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M91.15 5.5V3h2.3V0H.95v3.2H0q.25.35.55.65 2.95 3.4 8.95 3.6h4.85l.1-.55v-.1q.1-.8.25-1.45 1-4.6 5.4-4.95h4.8q3.05.1 3.8 2.25l1.35 5.8H68.6l1.6-3.7q1.25-3.15 3.7-3.45.2-.05.4-.05h5.4q3.6 0 3.8 3.35v2.9h5.55q1.55-.25 2-1.65.05-.2.1-.35z"
                    id="ShiSho041__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M2.2 18.35q0-.25.05-.55.2-2.05 1.7-3.55 1.7-1.7 4.15-1.7t4.15 1.7q1.7 1.7 1.7 4.1v.05q0 .5-.05.95h1.8q.25-2.6-1.35-5.8-.75-2.15-3.8-2.25h-4.8q-4.4.35-5.4 4.95Q.2 16.9.1 17.7v.1l-.1.55h2.2m25.1-17.2h11.35L37.35 0H27L14.15 6h3.7l9.45-4.85m16 0L41.9 0h-2.35l1.2 1.15h2.55m19.15 11.4q2.4 0 4.15 1.7 1.7 1.7 1.7 4.1v.05h.85v-2.9q-.2-3.35-3.8-3.35h-5.4q-.2 0-.4.05-2.45.3-3.7 3.45l-1.6 3.7h2.4q-.1-.45-.1-.95v-.05q0-.25.05-.55.2-2.05 1.65-3.55 1.75-1.7 4.2-1.7z"
                    id="ShiSho041__Symbol_106_0_Layer0_0_FILL"
                />
                <g id="ShiSho041__Layer3_0_FILL">
                    <path
                        fill="#A3A3A3"
                        d="M239.35 282.95h-2.55v2.5q-.05.15-.1.35H237.55q.35-.05.7-.35l1.1-1.5q.75-1 0-1m-20.2 4.45v.05q0 1.4 1 2.35.95 1 2.35 1 1.4 0 2.35-1 1-.95 1-2.35v-.05q0-1.35-1-2.3-.95-1-2.35-1-1.4 0-2.35 1-1 .95-1 2.3m2.8-.55q.25-.25.6-.25.3 0 .55.25.25.25.25.55v.05q0 .3-.25.55-.25.25-.55.25-.35 0-.6-.25t-.25-.55v-.05q0-.3.25-.55m-50.45.6v-.05q0-1.35-.95-2.3-1-1-2.4-1-1.35 0-2.35 1-1 .95-1 2.3v.05q0 1.4 1 2.35 1 1 2.35 1 1.4 0 2.4-1 .95-.95.95-2.35m-3.9-.6q.25-.25.6-.25t.55.25q.25.25.25.55v.05q0 .3-.25.55-.2.25-.55.25-.35 0-.6-.25-.2-.25-.2-.55v-.05q0-.3.2-.55m-21-6.9h-1.5q-1.25-.2-.85.8l.45 1.4v.1l.1.2q.3.7.75.7h1.05v-3.2z"
                    />
                    <path
                        fill="#BBE6F0"
                        d="M206 275.05h2.95l-5.65-4.85h-2.55l5.25 4.85m-7.35-4.85H187.3l-9.45 4.85H204l-5.35-4.85z"
                    />
                    <path d="M222.45 281.6q-2.45 0-4.2 1.7-1.45 1.5-1.65 3.55-.05.3-.05.55v.05q0 .5.1.95.25 1.85 1.6 3.2 1.75 1.75 4.2 1.75 2.4 0 4.15-1.75 1.35-1.35 1.65-3.2.05-.45.05-.95v-.05q0-2.4-1.7-4.1-1.75-1.7-4.15-1.7m-3.3 5.85v-.05q0-1.35 1-2.3.95-1 2.35-1 1.4 0 2.35 1 1 .95 1 2.3v.05q0 1.4-1 2.35-.95 1-2.35 1-1.4 0-2.35-1-1-.95-1-2.35m-51.05-5.85q-2.45 0-4.15 1.7-1.5 1.5-1.7 3.55-.05.3-.05.55v.05q0 2.45 1.75 4.15 1.7 1.75 4.15 1.75t4.15-1.75q1.35-1.35 1.65-3.2.05-.45.05-.95v-.05q0-2.4-1.7-4.1-1.7-1.7-4.15-1.7m3.4 5.8v.05q0 1.4-.95 2.35-1 1-2.4 1-1.35 0-2.35-1-1-.95-1-2.35v-.05q0-1.35 1-2.3 1-1 2.35-1 1.4 0 2.4 1 .95.95.95 2.3z" />
                    <path
                        fill="#4D4D4D"
                        d="M222.55 286.6q-.35 0-.6.25t-.25.55v.05q0 .3.25.55.25.25.6.25.3 0 .55-.25.25-.25.25-.55v-.05q0-.3-.25-.55-.25-.25-.55-.25m-54.35 0q-.35 0-.6.25-.2.25-.2.55v.05q0 .3.2.55.25.25.6.25t.55-.25q.25-.25.25-.55v-.05q0-.3-.25-.55-.2-.25-.55-.25z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSho041__Symbol_46_0_Layer0_0_FILL"
                transform="translate(127.05 237.6)"
                id="ShiSho041__col1n"
            />
            <use
                xlinkHref="#ShiSho041__Symbol_94_0_Layer0_0_FILL"
                transform="translate(117.35 228.6)"
                id="ShiSho041__col2n"
            />
            <use
                xlinkHref="#ShiSho041__Symbol_102_0_Layer0_0_FILL"
                transform="translate(145.65 279.95)"
                id="ShiSho041__col2d"
            />
            <use
                xlinkHref="#ShiSho041__Symbol_106_0_Layer0_0_FILL"
                transform="translate(160 269.05)"
                id="ShiSho041__col2s"
            />
            <use xlinkHref="#ShiSho041__Layer3_0_FILL" />
        </g></g>
    )
}

export default ShiSho041
