import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#FF0000"
}

function LegPan014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.7 19.6V.05l.05-.05h-.05q-.2.05-.35.1-3.35.65-6.7 1.2-.55.1-1.15.2-.7.15-1.45.25l-1 .15-2.5.4-4.6.7q-.15 0-.35.05-.25.05-.55.1-.2 0-.4.05l-.8.1-2.45.35q-2 .2-3.95.45l-.6.1q-.8.05-1.6.15-.35.05-.75.1-.25 0-.5.05-.45.05-.95.1l-.95.1q-1.5.1-3.05.25l-.95.1-1 .1-9.55.65q-1.05.05-2.1.15-.6 0-1.25.1l-5.15.2q-1.45 0-3 .05-.6 0-1.25.05h-4.35q-2.5 0-5.05-.05h-1.25q-4.1-.1-8.15-.35h-.3l-.4-.05Q23.9 4.75.85.2.6.1.35.1.2.05.1.05.05 0 0 0q0 .05.05.1v19.5q.35-.1.7-.15h.1q5.4-.9 10.8-1.35 21.2-1.95 41.2 1.95 5.05.95 10 2.3 5.05-1.4 10.2-2.35 19.5-3.8 40.2-2 5.9.5 11.9 1.5.15 0 .3.05.15 0 .25.05z"
                    id="LegPan014__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M125.65 12.3V8.4q-32.4 4.9-63.15 3.85h-.65Q31.7 13.3 0 8.65v3.9q5.8.85 11.6 1.55 21.2-1.95 41.2 1.95 4.6-.1 9.15-.4.25 0 .45.05 5.25.15 10.6.3 19.5-3.8 40.2-2 6.2-.75 12.45-1.7m0-8.15V0Q94.4 6.5 62.35 5.15H62Q30.6 6.5 0 .3v4.2q31.4 5.45 61.95 4.2h.45q31.2 1.25 63.25-4.55z"
                    id="LegPan014__Symbol_23_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan014__Symbol_22_0_Layer0_0_FILL"
                transform="translate(129.65 310.25)"
                id="LegPan014__col1n"
            />
            <use
                xlinkHref="#LegPan014__Symbol_23_0_Layer0_0_FILL"
                transform="translate(129.7 314.25)"
                id="LegPan014__col2n"
            />
        </g></g>
    )
}

export default LegPan014
