import { AssetSvgProps } from "../../../shared/assets"

function ObjMor002({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#AF9170"
                    d="M158.8 235.1l-4.9 2.45v59.75l4.9-3.5v-58.7z"
                    id="ObjMor002__Layer5_0_MEMBER_1_FILL"
                />
                <path
                    fill="#DEC6AB"
                    d="M87.75 230.2l-5.5-.6v59.75l5.5.6V230.2z"
                    id="ObjMor002__Layer5_0_MEMBER_2_FILL"
                />
                <path
                    fill="#DEC6AB"
                    d="M154.95 305.15q2.927-2.75 0-5.5l-29.25-3.2q1.35 1.85 2.65 5.7l-.05.05 26.65 2.95m-77.8-14.1v5.5l28.45 3.15h.05v-.05q.1-1.05.5-1.95.65-1.95 2-3.2l-31-3.45z"
                    id="ObjMor002__Layer5_0_MEMBER_3_FILL"
                />
                <path
                    fill="#AF9170"
                    d="M154.95 299.65v5.5l9.9-7.4v-5.1l-9.9 7z"
                    id="ObjMor002__Layer5_0_MEMBER_4_FILL"
                />
                <g id="ObjMor002__Layer5_0_FILL">
                    <path
                        fill="#666"
                        d="M90.25 280.7l2.4.3 55.75 6.2v-1.15l-55.75-6.2-2.4-.3v1.15m2.4-11.95l-2.4-.3v1.15l2.4.3 55.75 6.2v-1.15l-55.75-6.2m-2.4-10.5l2.4.3 55.75 6.2v-1.15l-55.75-6.2-2.4-.3v1.15m0-12.5v1.15l2.4.3 55.75 6.2v-1.15l-55.75-6.2-2.4-.3m58.15-3.3v-1.15l-55.75-6.2v1.15l55.75 6.2z"
                    />
                    <path
                        fill="#C8AC8D"
                        d="M87.75 289.95q-4.128-1.65-5.5-1.8v-.9l-5.1 3.8q15.5 5.586 31 3.45 2.2-2.15 6.3-2.45.55-.05 1.1-.05 6.25-.15 10.15 4.45 15.456 5.34 29.25 3.2 7.299 2.138 9.9-7v-.1l-6.05-.75v.95q-.358-2.255-4.9 4.55l-5.5-.6v-6.05l-55.75-6.15v1.95l-4.9 3.5m-5.5-60.35q2.273 5.542 5.5.6l4.9-2.45-5.25-.6-5.15 2.45m71.3 4.9l-5.15 2.45 5.5.6q3.72 2.163 4.9-2.45l-5.25-.6z"
                    />
                    <path
                        fill="#AF9170"
                        d="M92.65 235.1v-7.35l-4.9 2.45q-3.822 29.875 0 59.75l4.9-3.5V281l-2.4-.3v-1.15l2.4.3v-9.95l-2.4-.3v-1.15l2.4.3v-10.2l-2.4-.3v-1.15l2.4.3v-10.2l-2.4-.3v-1.15l2.4.3V235.1z"
                    />
                    <path
                        fill="#DEC6AB"
                        d="M148.4 236.95V296.7l5.5.6q3.86-29.875 0-59.75l-5.5-.6z"
                    />
                </g>
                <g id="ObjMor002__Layer5_0_MEMBER_0_FILL">
                    <path
                        fill="#0C0"
                        d="M141.45 287.2q0 .15.05.3.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.75.05 3.05-1.15l.25-.25V283l-.05-.05q-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.05.05-.05.15-.35.5-.55 1.15-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .5.15 1m-28.8-7.5q.05-.05.1-.05-.2-.3-.45-.6-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5l-.1.2q-.35.5-.5 1.1-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .55.15 1.1 0 .1.05.2.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.65.05 2.9-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05m1.1.05l-.05.05q-.3.2-.5.5 0 .05-.05.1-.35.55-.55 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .6.15 1.2.05.05.05.1.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.75.05 3.05-1.15.25-.25.45-.5.45-.55.75-1.25l-1.1-.15q-.1-.05-.2-.15-.15-.15-.15-.35v-.05q0-.25.15-.45.15-.1.35-.15l1.1.15v-.05-.15-.5q-.05-.1-.05-.2-.2-1.15-1.1-2.15-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1m-16.05-1.8l.05-.05q.05-.05.15-.1-.25-.3-.5-.55-1.15-1.25-2.75-1.35h-.4q-.2 0-.35.05-1.3.05-2.35.95l-.1.1-.05.05q-.8.8-1.1 1.7-.05.15-.1.35 0 .1-.05.2-.1.25-.1.55v.4q0 .1.05.2.05 1.4 1.1 2.55l.05.05.1.1q1 .95 2.3 1.2h.55q1.65.05 2.85-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6m1.15 0l-.05.05q-.3.2-.5.5-.4.6-.6 1.3-.05.1-.05.25 0 .1-.05.2v.3q-.05.1-.05.2v.45q0 .1.05.2 0 .65.2 1.3.25.65.8 1.25l.05.05q1 1 2.35 1.25H101.7q1.65.05 2.85-1 0-.05-.05-.05l-.35-.7q-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.1-.05.1-.1q-.25-.3-.5-.55-1.15-1.25-2.75-1.35H101.6q-.2 0-.35.05-1.4.1-2.4 1.1z"
                    />
                    <path
                        fill="#005DFF"
                        d="M144.95 271q-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.05.05-.05.15-.35.5-.55 1.15-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .5.15 1 0 .15.05.3.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.75.05 3.05-1.15l.25-.25v-5.65l-.05-.05q-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05m-54.9-1.55q0 .1.05.2.05 1.4 1.1 2.55l.05.05.1.1q1 .95 2.3 1.2h.55q1.65.05 2.85-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.15-.1-.25-.3-.5-.55-1-1.1-2.3-1.3-.25-.05-.45-.05h-.4q-.2 0-.35.05-1.3.05-2.35.95l-.1.1-.05.05q-.8.8-1.1 1.7-.05.15-.1.35 0 .1-.05.2-.1.25-.1.55v.4m23.7-.5l-.05.05q-.3.2-.5.5 0 .05-.05.1-.35.55-.55 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .6.15 1.2.05.05.05.1.25.65.8 1.25l.05.05.15.15q.95.85 2.2 1.1h.55q1.7.05 3-1.1l.05-.05q.25-.25.45-.5.55-.65.8-1.5v-.05l-1.1-.15q-.1-.05-.2-.15-.15-.15-.15-.35v-.05q0-.25.15-.45.15-.1.35-.15l1.05.15v-.4q-.05-.1-.05-.2-.2-1.15-1.1-2.15-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1m-7.95-.35l-.1.2q-.35.5-.5 1.1-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .55.15 1.1 0 .1.05.2.25.65.8 1.25l.05.05q.7.7 1.65 1.05.3.15.7.2h.55q.8 0 1.5-.2.75-.25 1.4-.8-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.1-.05-.2-.3-.45-.6-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5m-3.65-2.6H101.6q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.4.6-.6 1.3-.05.1-.05.25 0 .1-.05.2v.3q-.05.1-.05.2v.45q0 .1.05.2 0 .65.2 1.3.25.65.8 1.25l.05.05q1 1 2.35 1.25H101.7q1.65.05 2.85-1 0-.05-.05-.05l-.35-.7q-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.1-.05.1-.1q-.25-.3-.5-.55-1.15-1.25-2.75-1.35z"
                    />
                    <path
                        fill="#B80000"
                        d="M141.9 260.7q-.1.05-.15.1l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.2-.65-.2-1.3-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.6-1.3-.05 0-.05.05-.15-.4-.35-.75m-44-4.8q-.1.05-.15.1l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.2-.65-.2-1.3-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.6-1.3-.05 0-.05.05-.15-.4-.35-.75m7.4.9q-.05 0-.05.05-.05 0-.1.05l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75.05-.1.15-.15.3-.25.5-.5l.05-.05.05.05q0-.05-.05-.1-.15-.6-.15-1.2-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.65.55-1.2.05-.05.05-.1-.05 0-.05.05-.15-.4-.35-.75z"
                    />
                    <path
                        fill="red"
                        d="M141.75 260.8q.05-.05.15-.1-.25-.3-.5-.55-1.15-1.25-2.75-1.35h-.4q-.2 0-.35.05-1.3.05-2.35.95l-.1.1-.05.05q-.8.8-1.1 1.7-.05.15-.1.35 0 .1-.05.2-.1.25-.1.55v.4q0 .1.05.2.05 1.4 1.1 2.55l.05.05.1.1q1 .95 2.3 1.2h.55q1.65.05 2.85-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05m4.25-1.05q-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.4.6-.6 1.3-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.2 1.3.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.65.05 2.9-1v-6.4q-1.1-.95-2.45-1m-54.55-4.65l-.05.05q-.8.8-1.1 1.7-.05.15-.1.35 0 .1-.05.2-.1.25-.1.55v.4q0 .1.05.2.05 1.4 1.1 2.55l.05.05.1.1q1 .95 2.3 1.2h.55q1.65.05 2.85-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.15-.1-.25-.3-.5-.55-1.15-1.25-2.75-1.35h-.4q-.2 0-.35.05-1.3.05-2.35.95l-.1.1m10.15-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.4.6-.6 1.3-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.2 1.3.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.65.05 2.9-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.1-.05-.2-.3-.45-.6-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2m11.7 4.4q-.2-1.15-1.1-2.15-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5 0 .05-.05.1-.35.55-.55 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .6.15 1.2.05.05.05.1.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.75.05 3.05-1.15.25-.25.45-.5.55-.65.8-1.5 0-.1.05-.3-.7-.05-1.2-.1l-.1-.1q-.15-.15-.15-.35v-.05q0-.25.15-.45.15-.1.35-.15l1 .15v-.25q-.05-.1-.05-.2z"
                    />
                    <path
                        fill="#009C00"
                        d="M105.7 279.6l.1-.2q-.05 0-.05.05-.15-.4-.35-.75l-.1.1-.1.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25l.35.7q.05 0 .05.05l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.05-.1-.05-.2-.15-.55-.15-1.1-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.15-.6.5-1.1m7.1 0q-.05 0-.05.05-.05 0-.1.05l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75.05-.1.15-.15.3-.25.5-.5l.05-.05.05.05q0-.05-.05-.1-.15-.6-.15-1.2-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.65.55-1.2.05-.05.05-.1-.05 0-.05.05-.15-.4-.35-.75m-15.05-1.7l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.2-.65-.2-1.3-.05-.1-.05-.2v-.45q0-.1.05-.2v-.3q.05-.1.05-.2 0-.15.05-.25.2-.7.6-1.3-.05 0-.05.05-.15-.4-.35-.75-.1.05-.15.1z"
                    />
                    <path
                        fill="#0045BD"
                        d="M97.9 267q-.1.05-.15.1l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.2-.65-.2-1.3-.05-.1-.05-.2v-.45q0-.1.05-.2v-.3q.05-.1.05-.2 0-.15.05-.25.2-.7.6-1.3-.05 0-.05.05-.15-.4-.35-.75m13.35 4.85v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75.05-.1.15-.15.3-.25.5-.5l.05-.05.05.05q0-.05-.05-.1-.15-.6-.15-1.2-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.65.55-1.2.05-.05.05-.1-.05 0-.05.05-.15-.4-.35-.75-.05 0-.05.05-.05 0-.1.05l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65m-5.55-3.05l.1-.2q-.05 0-.05.05-.15-.4-.35-.75l-.1.1-.1.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25l.35.7q.05 0 .05.05l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.05-.1-.05-.2-.15-.55-.15-1.1-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.15-.6.5-1.1z"
                    />
                    <path
                        fill="#F90"
                        d="M97.7 246.4q-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.2 1.3.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.75.05 3.05-1.15.25-.25.45-.5.55-.65.8-1.5v-.1q-.9-.1-1.2-.2-.35-.15-.35-.35V248q0-.25.15-.45.15-.1.35-.15l1.15.15v-.45q-.05-.1-.05-.2-.2-1.15-1.1-2.15-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.4.6-.6 1.3m-7.5-.7q0 .1-.05.2-.1.25-.1.55v.4q0 .1.05.2.05 1.4 1.1 2.55l.05.05.1.1q1 .95 2.3 1.2h.55q1.65.05 2.85-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.15-.1-.25-.3-.5-.55-1.15-1.25-2.75-1.35h-.4q-.2 0-.35.05-1.3.05-2.35.95l-.1.1-.05.05q-.8.8-1.1 1.7-.05.15-.1.35m56 2.7q-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5 0 .05-.05.1-.35.55-.55 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .6.15 1.2.05.05.05.1.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.5.05 2.7-.85v-6.75q-1-.75-2.25-.8m-7.6-.95h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.4.6-.6 1.3-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.2 1.3.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.65.05 2.9-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.1-.05-.2-.3-.45-.6-1.2-1.25-2.8-1.3-.1-.05-.2-.05m-7.55-.9q-.2 0-.35.05-1.3.05-2.35.95l-.1.1-.05.05q-.8.8-1.1 1.7-.05.15-.1.35 0 .1-.05.2-.1.25-.1.55v.4q0 .1.05.2.05 1.4 1.1 2.55l.05.05.1.1q1 .95 2.3 1.2h.55q1.65.05 2.85-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.15-.1-.25-.3-.5-.55-1.15-1.25-2.75-1.35h-.4z"
                    />
                    <path
                        fill="#DB6C00"
                        d="M97.75 244.5l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.2-.65-.2-1.3-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.6-1.3-.05 0-.05.05-.15-.4-.35-.75-.1.05-.15.1m36.75 5.95q.2-.7.6-1.3-.05 0-.05.05-.15-.4-.35-.75-.1.05-.15.1l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.2-.65-.2-1.3-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25m8-.4q-.05 0-.05.05-.15-.4-.35-.75-.05 0-.05.05-.05 0-.1.05l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75.05-.1.15-.15.3-.25.5-.5l.05-.05.05.05q0-.05-.05-.1-.15-.6-.15-1.2-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.65.55-1.2.05-.05.05-.1z"
                    />
                    <path
                        fill="#FF0"
                        d="M91.55 232.75l-.05.05q-.3.2-.5.5-.05.05-.05.15-.35.5-.55 1.15-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .5.15 1 0 .15.05.3.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.75.05 3.05-1.15.25-.25.45-.5.55-.65.8-1.45h.05v-.05q-.25-.1-.45-.1-1.1-.05-1.25-.2-.2-.15-.2-.4v-.05q0-.25.15-.45.15-.1.35-.15l1.45.2v-.45q-.05-.1-.05-.2-.2-1.15-1.1-2.15-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1m50.15 6.35q0 .05-.05.1-.35.55-.55 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .6.15 1.2.05.05.05.1.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.75.05 3.05-1.15.25-.25.45-.5v-5.15q-.1-.15-.25-.3-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5m-4.1-2.6q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5l-.1.2q-.35.5-.5 1.1-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .55.15 1.1 0 .1.05.2.25.65.8 1.25l.05.05q1 1 2.35 1.25h.55q1.65.05 2.9-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.1-.05-.2-.3-.45-.6-1.2-1.25-2.8-1.3-.1-.05-.2-.05h-.2m-6.95-.9H130.1q-.2 0-.35.05-1.4.1-2.4 1.1l-.05.05q-.3.2-.5.5-.4.6-.6 1.3-.05.1-.05.25 0 .1-.05.2v.3q-.05.1-.05.2v.45q0 .1.05.2 0 .65.2 1.3.25.65.8 1.25l.05.05q1 1 2.35 1.25H130.2q1.65.05 2.85-1 0-.05-.05-.05l-.35-.7q-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.1-.05.1-.1q-.25-.3-.5-.55-1.15-1.25-2.75-1.35m-7.9-.9q-.2 0-.35.05-1.3.05-2.35.95l-.1.1-.05.05q-.8.8-1.1 1.7-.05.15-.1.35 0 .1-.05.2-.1.25-.1.55v.4q0 .1.05.2.05 1.4 1.1 2.55l.05.05.1.1q1 .95 2.3 1.2h.55q1.65.05 2.85-1-.25-.4-.4-.75-.25-.6-.25-1.25-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.7.65-1.2.2-.3.55-.6l.05-.05q.05-.05.15-.1-.25-.3-.5-.55-1.15-1.25-2.75-1.35h-.4z"
                    />
                    <path
                        fill="#FFE000"
                        d="M133.9 237.5l-.1.1-.1.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25l.35.7q.05 0 .05.05l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.05-.1-.05-.2-.15-.55-.15-1.1-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.15-.6.5-1.1l.1-.2q-.05 0-.05.05-.15-.4-.35-.75m7.75 1.7q.05-.05.05-.1-.05 0-.05.05-.15-.4-.35-.75-.05 0-.05.05-.05 0-.1.05l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75.05-.1.15-.15.3-.25.5-.5l.05-.05.05.05q0-.05-.05-.1-.15-.6-.15-1.2-.05-.1-.05-.2v-.3q0-.35.05-.65.05-.1.05-.2 0-.15.05-.25.2-.65.55-1.2m-14.85-1.9q-.05 0-.05.05-.15-.4-.35-.75-.1.05-.15.1l-.05.05q-.35.3-.55.6-.45.5-.65 1.2-.05.1-.05.25 0 .1-.05.2-.05.3-.05.65v.3q0 .1.05.2 0 .65.25 1.25.15.35.4.75l.15-.15q.3-.25.5-.5l.05-.05.05.05q-.2-.65-.2-1.3-.05-.1-.05-.2v-.45q0-.1.05-.2v-.3q.05-.1.05-.2 0-.15.05-.25.2-.7.6-1.3z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMor002__Layer5_0_FILL" />
            <use xlinkHref="#ObjMor002__Layer5_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjMor002__Layer5_0_MEMBER_1_FILL" />
            <use xlinkHref="#ObjMor002__Layer5_0_MEMBER_2_FILL" />
            <use xlinkHref="#ObjMor002__Layer5_0_MEMBER_3_FILL" />
            <use xlinkHref="#ObjMor002__Layer5_0_MEMBER_4_FILL" />
        </g></g>
    )
}

export default ObjMor002
