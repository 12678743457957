import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2d": "#252525",
    "col2n": "#434343"
}

function AccHat038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M247.85 95.7q-.3-.65-.75-1.35-.1-.55-1.5-1.45-.35-.25-.8-.5-2.2-1.25-7.8-2.2-13.75-2.35-27.75-4.2-4.3-.55-8.65-1.05l-2.4-.3q-.7-.1-1.4-.15-.45-.1-.9-.1-.7-.1-1.4-.15h-.4v-20.2h-.05v.25l-.5-2.7q-2.8-14.55-7.35-25.2-3.9-11.05-10.6-19.4-6.6-8.15-15.8-13.8-2.1-1.3-4.35-2.45-7.05-2.3-13.7 2.45-1.65 1.2-3.3 2.8-2.6 2.5-5.1 6.1-3.7 3.9-9.05 3.6h-.4q-5.35.3-9.05-3.6-2.5-3.6-5.1-6.1-1.65-1.6-3.25-2.8Q99.85-1.55 92.75.75 90.5 1.9 88.4 3.2 79.2 8.85 72.65 17 65.95 25.4 62 36.4q-4.6 10.65-7.35 25.2l-.5 2.7v-.25h-.05v20.2h-.4q-.7.05-1.4.15-.45 0-.9.1-.7.05-1.4.15-1.4.15-2.8.35-4.15.5-8.25 1-14 1.85-27.75 4.2-5.6.95-7.8 2.2-.45.25-.75.5-1.45.9-1.55 1.45-.45.7-.75 1.35-.3.8-.35 1.5-.05 1.6 1.2 2.85 1 .3 2.05.6 3 .7 9.55 0 4.1-.5 34.4-2.85 1.25-.1 2.55-.2.8-.1 1.6-.15h.05q6.1-.5 13.15-1 29.15-2.25 59.35-2.4.2-.05.4 0 30.2.15 59.35 2.4 7.05.5 13.15 1h.05q.8.05 1.6.15 1.1.1 2.15.2 30.7 2.35 34.8 2.85 6.55.7 9.55 0 1.05-.3 2.05-.6 1.25-1.25 1.2-2.85-.05-.7-.35-1.5z"
                    id="AccHat038__Symbol_256_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M93 8.35q2.1-1.3 4.3-2.4 0-.05.05-.05 7.1-2.3 13.75 2.45 1.3 1 2.65 2.25-1.95-2.65-4-4.6-1.65-1.6-3.25-2.8Q99.85-1.55 92.75.75 90.5 1.9 88.4 3.2q-9.35 5.75-15.95 14.05T62 36.4q-4.6 10.65-7.35 25.2l-.5 2.7v-.25h-.05V78h4.6v-8.8h.05v.25l.5-2.7Q62 52.2 66.6 41.55 74.35 19.8 93 8.35m73.6 8.9q9.9 9.9 15 24.3 4.55 10.65 7.35 25.2l.5 2.7v-.25h.05V78h4.6V64.05h-.05v.25l-.5-2.7q-2.8-14.55-7.35-25.2-3.85-10.85-10.4-19.15-6.65-8.3-16-14.05-2.1-1.3-4.35-2.45-7.05-2.3-13.7 2.45-1.65 1.2-3.3 2.8-2.05 1.95-4 4.6 1.35-1.25 2.7-2.25 6.65-4.75 13.7-2.45 2.25 1.15 4.35 2.45 6.3 3.85 11.4 8.9m81.25 78.45q-.3-.65-.75-1.35-.1-.55-1.5-1.45.5 1.5-.15 2.8-.3.6-.85 1.15-.6.2-1.2.35-.4.15-.85.25-1.05.25-2.55.35-2.05-.1-4.6-.35-4.1-.5-34.8-2.85-1.05-.1-2.15-.2-.8-.1-1.6-.15h-.05q-6.1-.5-13.15-1-7-.55-14.05-1l-42.9-1.4h-2.4q-.2-.05-.4 0L81 92.25q-8.25.35-16.45 1-7.05.5-13.15 1h-.05q-.8.05-1.6.15-1.3.1-2.55.2-30.3 2.35-34.4 2.85-2.45.25-4.4.35-.5-.05-.95-.05-1.05-.1-1.8-.3-1.05-.3-2.05-.6-.55-.55-.85-1.15-.65-1.3-.1-2.8-1.45.9-1.55 1.45-.45.7-.75 1.35-.3.8-.35 1.5-.05 1.6 1.2 2.85 1 .3 2.05.6 3 .7 9.55 0 4.1-.5 34.4-2.85 1.25-.1 2.55-.2.8-.1 1.6-.15h.05q6.1-.5 13.15-1 29.15-2.25 59.35-2.4.2-.05.4 0 30.2.15 59.35 2.4 7.05.5 13.15 1h.05q.8.05 1.6.15 1.1.1 2.15.2 30.7 2.35 34.8 2.85 6.55.7 9.55 0 1.05-.3 2.05-.6 1.25-1.25 1.2-2.85-.05-.7-.35-1.5z"
                    id="AccHat038__Symbol_165_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M13.15.8q-.15.15-.2.3-.1.2-.1.45H13q2.2 20.05-13 32 .1.2.25.35.35.3.85.35.55 0 .95-.25 7.85-6.05 11.1-16 2.5-7.8 2.15-16.55 0-.45-.35-.7-.4-.3-.9-.25-.55 0-.9.3m31 18.85q2.9 10.4 10.4 14.85.4.25.9.25.5-.1.8-.4.15-.2.2-.35Q44.7 27.2 41.35.3h.05l-.05-.05Q41-.05 40.5 0q-.5 0-.85.3t-.35.7q-.05.6.1 1.2 2 7.9 4.75 17.45z"
                    id="AccHat038__Symbol_123_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M143.45 19.4V0H0v19.4q71.15-6 143.45 0z"
                    id="AccHat038__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M8.9 0H0v19.4l8.9-.7V0m134.55 0h-8.95v18.7q4.45.35 8.95.7V0z"
                    id="AccHat038__Symbol_21_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat038__Symbol_256_0_Layer0_0_FILL"
                transform="translate(68.4 4)"
                id="AccHat038__col1n"
            />
            <use
                xlinkHref="#AccHat038__Symbol_165_0_Layer0_0_FILL"
                transform="translate(68.4 4)"
                id="AccHat038__col1d"
            />
            <use
                xlinkHref="#AccHat038__Symbol_123_0_Layer0_0_FILL"
                transform="translate(164.2 28.85)"
                id="AccHat038__col1s"
            />
            <use
                xlinkHref="#AccHat038__Symbol_79_0_Layer0_0_FILL"
                transform="translate(120.7 69)"
                id="AccHat038__col2n"
            />
            <use
                xlinkHref="#AccHat038__Symbol_21_0_Layer0_0_FILL"
                transform="translate(120.7 69)"
                id="AccHat038__col2d"
            />
        </g></g>
    )
}

export default AccHat038
