import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#007070",
    "col1n": "#00AFAF"
}

function LegPan008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M134.85 4.45q-.05-.15-.1-.25l-.45-.65Q133.7-.1 132 0l-.35.1-1.1.1Q68.05 12.1 7.45.05 7.35 0 7.25 0 5.8-.3 4 4v.15l-.35.6H3.6q-.15.2-.5.4-.05.05-.65.35-.25.2-.6.5Q.4 7.55.4 9.4q0 .65.45 1.65l.7.7q.5.5.75 1.1.25.6.3 1.4-1.4.1-2.6.3l1.45 6.55q4.85-2.05 19.6-3.4 24.8-1.85 47.95 4.4.2.05.45.1.1-.05.25-.05 19.55-5.3 40.4-4.8 14.7.4 25.95 3.4l.85-4.55-2.5-.65q0-.05.05-.05.05-.2.1-.35.1-.45.25-.85 1.85-5.65 1.85-6.25 0-1-.9-2.2-.65-.9-.9-1.4z"
                    id="LegPan008__Symbol_10_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.35 3.6q.4-.45.6-1-4.8 0-9.2-1.5l-.8-.3-.5-.2-.35.6.4.2q.55.25 1.25.5 2.95 1 8.6 1.7m27.2 5.65q-4.4-.95-10-1.3-12.9 0-25.8 1.5-1.4.15-2.75.35.05.4.1.85 1.2-.15 2.65-.2Q12.4 9.35 13.8 9.3q8.15-.9 24.75-.05m34.65-2.9q-.6.15-2.2.7-1.85.6-2.2.8-.4.2-1.15.45-.8.2-1.15.35-.4.15-3.5-2l-1.6-.7q2.35 2.05 3.55 3.8.85 1.55.85 3.45 0 1.25-.1 1.65h-.5q-1.95-1.1-4.7-1.4.75.5 3.65 1.6 2.35 1.35 2.3 3.5.2.05.45.1.1-.05.25-.05.2-1.55 1.05-2.5 1-1.15 3.8-2.15h-.95q-1.7.2-2.4.2-1.1 0-1.35-.7-.1-.25-.1-1.3 0-2.05 1.4-3.1 2.5-1.35 4.7-2.7h-.1m58.7 5.6q.05-.2.1-.35-1.3-.65-2.4-1.15-.55-.3-1.05-.5-.25-.05-.45-.1-15.3-4.5-28.15-1.3 12.1-.9 22.4 1.2.35.05.75.15l.25.05q.95.2 1.95.45l6.6 1.55m.3-11.3l-.45-.65q-1.15.2-2.15.45-5.85 1.15-9.75.85.05.05-.05.9 4.45.05 7.65-.5 1.2-.2 2.15-.4 1.6-.35 2.6-.65z"
                    id="LegPan008__Symbol_11_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegPan008__Symbol_10_0_Layer0_0_FILL"
                transform="translate(123.05 310.4)"
                id="LegPan008__col1n"
            />
            <use
                xlinkHref="#LegPan008__Symbol_11_0_Layer0_0_FILL"
                transform="translate(125.6 313.95)"
                id="LegPan008__col1d"
            />
        </g></g>
    )
}

export default LegPan008
