import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col2n": "#000000"
}

function AccMor032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 17.55l22.7 5.25.1.05 3-14.65q0-.05.1-.5L5.15 0Q1.5 9.2 0 17.55z"
                    id="AccMor032__Symbol_130_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M129.75 263.45q-1.1-.35-2.1.15-1.05.55-1.4 1.7-.35 1.1.2 2.15.55 1.05 1.65 1.4 1.15.35 2.15-.2 1.05-.55 1.4-1.65.35-1.15-.2-2.2-.55-1-1.7-1.35m-9.1 5.95q-1.05.55-1.4 1.65-.25.85 0 1.65.05.25.2.5.55 1.05 1.65 1.4.15.05.25.1 1 .2 1.9-.3.35-.15.65-.4.55-.5.8-1.25.35-1.1-.25-2.15-.2-.4-.45-.7-.5-.5-1.2-.7-1.1-.35-2.15.2m-.05-8.8q-1.1-.35-2.1.15-1.05.55-1.4 1.7-.35 1.1.2 2.15.55 1.05 1.65 1.4 1.15.35 2.15-.2 1.05-.55 1.4-1.65.35-1.15-.2-2.2-.55-1-1.7-1.35z"
                    id="AccMor032__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMor032__Symbol_130_0_Layer0_0_FILL"
                transform="translate(110.6 256.05)"
                id="AccMor032__col1n"
            />
            <use
                xlinkHref="#AccMor032__Symbol_28_0_Layer0_0_FILL"
                id="AccMor032__col2n"
            />
        </g></g>
    )
}

export default AccMor032
