import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A19CCD",
    "col1s": "#56518C"
}

function SkiWou006({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M50.5 20q-2.3-5.35-6.55-9.95-4.45-4.9-9.65-7.4-1.15-.55-2.35-1-8.65-3.25-18.15 0-1.05.45-2.05 1-1.5.85-3.3 2.5-8.1 6.5-8.45 17.2l18.35 2.35q4.45.65 12.4.85 7.95.2 20.95-2.4-.1-.9-1.2-3.15m1.85 20.55l.6-3.1q-27.35-5.6-50.75.1l1.35 3.05H3.5q2.15 4 5.4 7.6 8.4 9.1 19.45 9.95 11.05.85 18.3-7.05 4.2-4.6 5.7-10.55z"
                    id="SkiWou006__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M3.3 29.9q-.1.2 0 .4.05.15.25.25t.4.05q13.3-4.9 34.8-.15.2.05.4-.05.15-.15.2-.35.05-.2-.05-.35-.15-.2-.35-.25-21.8-4.85-35.35.2-.2.05-.3.25m10.75 5.15q-.1.15 0 .35.1.2.3.3.15.05.35-.05 7.5-3.15 17.75.6.15.05.35-.05.2-.1.3-.25.05-.2-.05-.4t-.25-.25q-10.7-3.9-18.5-.55-.2.1-.25.3M31.25.4Q31.2.2 31 .1q-.2-.15-.35-.05Q19 3.1 8.85.95q-.2-.05-.35.1-.2.1-.25.3-.05.2.1.4.1.15.3.2Q19 4.1 30.9 1q.15-.05.3-.25.1-.2.05-.35M36.9 6q.1-.2.05-.4-.1-.2-.25-.3-.2-.1-.4 0-3.4 1.15-5.4 1.45-.15 0-.25.05Q16.05 8.95.6 5.45q-.2-.05-.35.1-.2.1-.2.3-.1.2.05.4.1.15.3.2 15.65 3.5 30.45 1.35.05 0 .15-.05h.05q2.05-.3 5.6-1.5.15-.1.25-.25z"
                    id="SkiWou006__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiWou006__Symbol_12_0_Layer0_0_FILL"
                transform="translate(192.5 135.85)"
                id="SkiWou006__col1n"
            />
            <use
                xlinkHref="#SkiWou006__Symbol_13_0_Layer0_0_FILL"
                transform="translate(198.5 147.65)"
                id="SkiWou006__col1s"
            />
        </g></g>
    )
}

export default SkiWou006
