import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function HaiHig001({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M93.5 46.65q-.1-3.25-1.4-7Q88 30.7 76.75 29.5q-1.3-.1-2.65-.1 6.25-6.05 15.55-7.1-6.7-2.4-13.65-2.7-7.25-.35-11.6 2.1.45-4.1 5.45-7.45 5-3.4 7.3-4.3 2.3-.95 4.8-1.55-12.2-1.1-20.4 2.65-8.25 3.7-8.5 4.85-.25-2.85 2.15-6.4Q57.6 5.9 66.75 0q-14.85 2.2-20 8.2-5.15-6-20-8.2Q35.9 5.9 38.3 9.5q2.4 3.55 2.15 6.4-.25-1.15-8.5-4.85-8.2-3.75-20.4-2.65 2.5.6 4.8 1.55 2.3.9 7.3 4.3 5 3.35 5.45 7.45-4.35-2.45-11.6-2.1-6.95.3-13.65 2.7 9.3 1.05 15.55 7.1-1.35 0-2.65.1Q5.5 30.7 1.4 39.65q-1.3 3.75-1.4 7 .15 0 3.95-2.85 3.85-2.85 8.55-3.1 4.75-.3 6.5.45-8.6 3.1-9.4 15.55 11.3-5.25 18.35-5.15-3.85 5.5-4.7 7-.1.25-.25.5 13-3.3 17.75 2.2 2.2 3.55 5.9 8.25l.1-.1.1.1q3.7-4.7 5.9-8.25 4.75-5.5 17.75-2.2-.15-.25-.25-.5-.85-1.5-4.7-7 7.05-.1 18.35 5.15-.8-12.45-9.4-15.55 1.75-.75 6.5-.45 4.7.25 8.55 3.1 3.8 2.85 3.95 2.85m-53.85 12.6q.15.3.35.65l-.4-.65h.05m14.2 0h.05l-.4.65q.2-.35.35-.65z"
                    id="HaiHig001__Symbol_1_0_Layer0_0_FILL"
                />
            </defs>
            <g id="HaiHig001__col1n" transform="translate(145.75 35.75)">
                <use
                    xlinkHref="#HaiHig001__Symbol_1_0_Layer0_0_FILL"
                    id="HaiHig001__col1n_FL"
                />
            </g>
        </g></g>
    )
}

export default HaiHig001
