import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2n": "#FF008C"
}
function HaiHig027({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#252525"
                    d="M351.2 149.25q-.15.05-.25.1l.45-.1h-.2z"
                    id="HaiHig027__Layer8_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M70 19.2l-.2-.2Q49.65-5 20.55 1.3 14.6 2.75 9.1 6.4 7.25 7.65 5.6 9.15l-.7.5q-2.05 1.6-4.9 4 .5-.4.2.15.6.3 1.7 3.05 1.05 2.75 1.25 2.45.15-.35 1.7 3.2Q6.4 26 8.4 26.9q1.95.85 2.55 1.7.55.8-2.65-1.7 3.7 4.05 4 4.5.55.7 1 1.35 2.95 5.6 12.5 6.5 10.05.9 11.3 1 1.85.1 3.75.4l1.3.1q8.05 1.3 16.2 6.45 1.8 1.05 3.3 2.35 7.5 5.95 10.15 15.4 2.15-2.8 3.3-6.05 1.05-2.85 1.5-5.55 1.25-7.95-4.6-17.4-.8-1.25-1.8-2.5-1.4-2-2.65-4.8 1.4.3 2.8.85 1.25.45 2.5 1.15 1.75 1.1 6.35 4.5-.2-.65-.5-1.25-.05-.25-.15-.35-.8-2.25-1.85-4.35-2.8-5.55-6.7-10z"
                    id="HaiHig027__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M68.25 22.35q1.25.45 2.5 1.15 1.75 1.1 6.35 4.5-.2-.65-.5-1.25-.05-.25-.15-.35-.8-2.25-1.85-4.35-1.25-1.3-3.15-2.6-1.95-1.35-4-2.3-3.5-1.7-7.45-2.3-1.3-.2-2.85-.2-4.4-.1-10.45 1.3-8.25 1.85-17.9.3-4.1-.65-7.45-1.55-2.3-.25-4.2-.75.3-.15 1.5-1.95 1.25-1.8 6.65-3.85 5.6-2.2 16.55-4.55-.95-.25-1.95-.5Q19.7-4.45 2 6.4q-.15.05-.25.15l-.5.4-.8.8Q.3 7.65.2 7.6q-.1.1-.2.3.35.2.7.5 3.6 2.95 6.45 8.5 1.1 2.2 1.7 3.9.55 1.45.75 2.55l.6.9q.55.7 1 1.35 2.95 5.6 12.5 6.5 10.05.9 11.3 1 1.85.1 3.75.4l1.3.1q8.05 1.3 16.2 6.45 1.8 1.05 3.3 2.35 7.5 5.95 10.15 15.4 2.15-2.8 3.3-6.05 1.05-2.85 1.5-5.55 1.25-7.95-4.6-17.4-.8-1.25-1.8-2.5-1.4-2-2.65-4.8 1.4.3 2.8.85z"
                    id="HaiHig027__Symbol_77_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M6.1 2.6q-.35-.3-.7-.5-.3-.25-.6-.45-.7-.5-1.3-.8-.1-.1-.2-.15h-.1Q3 .6 2.85.55 2.6.45 2.35.4 1.2 0 0 0q.7 1.1 1.35 2.25 1.55 2.5 2.95 5.2 3.5 7.05 5.75 14.25.3.8.5 1.7.2.7.45 1.4 0-.1.15-.2h.05l-.2.2q.95-.6 1.25-.8.65-.5 1.1-1.05.15-.1.35-.25l-.05-.05q.05-.05.1-.05.15-.25.4-.45.8-1.15.95-2.4.05-.2.05-.35.05-.65-.1-1.55 0-.15-.05-.3-.2-1.1-.75-2.55-.6-1.7-1.7-3.9Q9.7 5.55 6.1 2.6z"
                    id="HaiHig027__Symbol_79_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#HaiHig027__Layer8_0_FILL" />
            <use
                xlinkHref="#HaiHig027__Symbol_78_0_Layer0_0_FILL"
                transform="translate(276.05 88.1)"
                id="HaiHig027__col1n"
            />
            <use
                xlinkHref="#HaiHig027__Symbol_77_0_Layer0_0_FILL"
                transform="translate(278.15 95.25)"
                id="HaiHig027__col1d"
            />
            <use
                xlinkHref="#HaiHig027__Symbol_79_0_Layer0_0_FILL"
                transform="translate(272.75 101.05)"
                id="HaiHig027__col2n"
            />
        </g></g>
    )
}
export default HaiHig027
