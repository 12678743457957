import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col2n": "#FFD446"
}

function AccJew009({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M105 9.85q.5-.45 1.05-.85l-3.75 2Q85.4 18.4 67 19.35q-21.8-.55-32.35-5.05t-12.5-5.55q-1.55-.75-3-1.5 2.5 2.4 5.65 4.65 16.05 11.4 38.75 11.4t38.7-11.4q1.45-1 2.75-2.05m13.6-8.45q-2.35 2.15-5.35 3.55-1.45.85-2.9 1.65-.35.3-.45.5l-.6.9q-1.55 2.25-3.1 4.35-.1.15-.25.3L96.5 23.1Q81.75 36.55 63.8 36.55q-16.25 0-29.85-11.05L23.6 15.1q-.2-.25-.35-.4-2.55-3.2-5.05-6.85-.35-.5-.7-1.05-.2-.35-.4-.65-2.55-1.4-5.15-3l-.2-.15-.85.05 10.45 14.1q.1.15.25.3.9 1.2 1.9 2.3 17.8 20.8 40.55 20.8 18.05 0 33-13.05 5.4-4.75 10.4-11.2.95-1.3 1.95-2.6l.3-.4 8.9-11.9m7.95-.55h-.05q-.15-1-.7-.85-2.85 6.35-6.9 12.7-7.25 12.2-17.3 20.55l-3 2.4q-4.05 3-8.3 5.3-4 2.1-8.15 3.55-19.95 5.05-40.3-1.05-18.05-7.25-32.3-27.4-4.6-6.5-8.2-13.55-.2-.35-.35-.7-.1-.05-.2-.05-.5 0-.65.15-.15.15-.15.7 0 .6.1.85l.05.1q.6 1.4 1.25 2.8 6.25 12.3 16.3 23.25 18.35 18.3 41.65 19.8l1.2.1q1.1.05 2.25.05h.75q10.55 0 20.1-3.3 13.2-5.25 22-13.7l.5-.5q1.05-1.15 2.1-2.25 8.8-9.5 14.25-19.9 2.15-4.3 4.05-8.75v-.3z"
                    id="AccJew009__Symbol_80_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M108.35 11.5q-.1.05-.2.2-.55.4-1.05.85-1.3 1.05-2.75 2.05-16 11.4-38.7 11.4-22.7 0-38.75-11.4-3.15-2.25-5.65-4.65-1.05-.55-2.05-1.1.2.3.4.65.35.55.7 1.05 1.75 1.8 3.7 3.55 17.1 15.15 41.3 15.15 24.2 0 41.3-15.15 1.8-1.6 3.45-3.25.2-.25.45-.45-1.1.55-2.15 1.1m21.75-4.45q-.2.35-.4.75-.35.55-.65 1.15-1.1 1.9-2.25 3.8Q102.55 55.1 75.35 59.2q-.65.1-1.3.25-4.5.8-9.15.8-5.25 0-10.3-1.05-6.6-1.35-12.75-4.55-.6-.3-1.15-.6l-4.25-2.55Q15.3 38.05 2.3 9q-.3-.55-.6-1.05-.1-.25-.2-.45-.2-.15-.35-.15-.35 0-.6.2Q0 7.9 0 9.05l.1.75q1.6 3.55 3.35 6.95 2.2 4.25 4.65 8.3 14.65 24.2 33.1 33.5l.4.2q6.25 2.7 13.1 3.85 5 .8 10.25.8 1.3 0 2.6-.05 7.9-.3 15.2-2.55 25.75-9.1 47.05-49.05.1-.25.25-.5.65-1.35 1.25-2.75.15-.6.15-.75 0-.3-.2-.75-.3-.55-.65-.55l-.2.05q-.15.25-.3.55m-6.5-5q0-.05.05-.1-1.05.8-3 2.2-.4.25-.8.55l-.9.6q-.05.05-.05.1-.6.45-1.8 1.2-.4.2-.85.5-.25.1-.5.25l-.2.1q-.05 0-.1.05-.05 0-.1.05v.1q-.15.4-.2.45-.6.7-1.2 1.45-1.1 1.2-2.25 2.4l-2 2.1q-1.15 1.05-2.25 2.1-17.85 16.1-42.05 16.1-22 0-38.75-13.3-3.35-2.7-6.5-5.9l-1.1-1.1q-2.4-2.55-4.5-5.2l-.7-1.3q.05.2.05.3 0 .1-.15-.1l-.1-.05v-.05Q9.5 2.9 6.1 0q4.35 7.4 10.85 14 .6.6 1.2 1.15 11.8 22.35 26.4 31.25 9.85 3.85 20.85 3.85 9.55 0 18.25-2.85 14.2-7.9 25.9-28.4-5 6.45-10.4 11.2-15.1 18.55-34 18.55-22.5 0-39.55-26.3-1.1-1.25-2.15-2.6l1 .8q4.95 3.8 10.4 6.55 13.8 7.05 30.55 7.05 18.35 0 33.2-8.45 3.5-2.05 6.85-4.5 2.65-2 5.2-4.25.55-.55 1.15-1.05l2-2q2.4-2.4 4.75-5.25l.05-.05q.7-.9 1.45-1.8.5-.7 1.05-1.4 1.25-1.7 2.5-3.45z"
                    id="AccJew009__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccJew009__Symbol_80_0_Layer0_0_FILL"
                transform="translate(128.5 231.25)"
                id="AccJew009__col1n"
            />
            <use
                xlinkHref="#AccJew009__Symbol_28_0_Layer0_0_FILL"
                transform="translate(126.4 228.55)"
                id="AccJew009__col2n"
            />
        </g></g>
    )
}

export default AccJew009
