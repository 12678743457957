import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiMot087({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M29 1.55Q25.15 0 21.05 0q-4.2 0-8 1.55Q9.2 3.2 6.2 6.05q-2.9 3.1-4.55 6.8Q0 16.65 0 20.9q-.05 4.15 1.45 7.9 1.65 3.85 4.5 6.7 2.9 3 6.75 4.6 3.75 1.55 8 1.55 4.1 0 7.9-1.55 3.85-1.6 6.8-4.6 3.05-2.85 4.65-6.7 1.55-3.75 1.6-7.9 0-4.25-1.45-8.05-1.55-3.85-4.55-6.8-2.8-2.85-6.65-4.5M22.75 3.9l-.15 6.85q-.8-.1-1.65-.1-.9 0-1.75.1l.15-6.85q.85-.05 1.7-.05t1.7.05m2.85 12.15q1.05.9 1.5 2.15.55 1.3.55 2.7 0 2.35-1.6 4.15-1.35 1.85-3.65 2.35l-.05 10.4q-.85.1-1.65.1-1.1 0-2.2-.15h-.25l-1.6-.4q-.5-.1-.9-.15l-2.8-1.1-3.1-2.1-1.15-1.25 2.35-2.35q-1.85-1.9-2.8-4.35-1.1-2.5-1.05-5.15 0-2.9 1.1-5.45 1.2-2.4 3-4.25l2.5 2.35q-1.6 1.4-2.35 3.3-.8 1.95-.8 4.05 0 1.95.65 3.9.75 1.75 2.15 3.2l-2.4 2.4q1.65 1.6 3.55 2.55 2 1.1 4.35 1.4l.1-6.95q-2.25-.5-3.65-2.35-1.35-1.8-1.35-4.15 0-1.4.55-2.7.45-1.25 1.45-2.15.95-.9 2.2-1.5 1.3-.5 2.7-.5 1.4 0 2.55.5 1.2.6 2.1 1.5m4.5.55q-.7-1.65-1.8-2.95l2.35-2.35q1.15 1.15 2.05 2.75l-2.6 2.55M35 30.15q-.9 1.45-2.15 2.6L27.95 28q1.3-1.25 2.05-2.85l5 5M23.25 18.4q-1.05-1-2.35-1-1.5 0-2.5 1-.95 1-.95 2.5 0 1.35.95 2.35.95.95 2.4.95 1.35 0 2.45-.95 1-1 1-2.35 0-1.5-1-2.5z"
                    id="ShiMot087__Symbol_53_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot087__Symbol_53_0_Layer0_0_FILL"
                transform="translate(171.7 263)"
                id="ShiMot087__col1n"
            />
        </g></g>
    )
}

export default ShiMot087
