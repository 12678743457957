import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacBro010({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 11.6Q18.2-5.35 39.5 6.55V5.3Q19.5-7.1 0 9.5v2.1m96.75 0V9.5q-19.5-16.6-39.5-4.2v1.25q21.3-11.9 39.5 5.05z"
                    id="FacBro010__Symbol_10_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacBro010__Symbol_10_0_Layer0_0_FILL"
                transform="translate(144.15 121.9)"
                id="FacBro010__col1n"
            />
        </g></g>
    )
}

export default FacBro010
