import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#CB9AFF",
    "col2l": "#FF00FF",
    "col2n": "#C600C6",
    "col2s": "#3F023F"
}

function ObjMus038({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M0 46.35l6.7-2.15 9.45-2.3q17.1 1.9 32.5-41.9-6.7 5.1-13.8 8.75Q22.5 36.35 0 46.35z"
                    id="ObjMus038__Symbol_122_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M36.35 40.25l-.3-.05L0 41.8l32.15 22.6Q30.1 61 30.1 56.85q0-5.6 3.6-9.75l.3-.3.05-.05q0-.05.05-.05.2-.25.45-.5l.2-.2.05-.05q0-.05.05-.05.15-.15.35-.25.05-.1.1-.15.5-2.65 1.05-5.25m96.35-4.2q-.05-.05-.05-.15l-16.15.7q-.1 0-.15.05l-3.1.15q0-.05.05-.05l-16 .7q-.1-.05-.2-.05l-38.3 1.8-1.2 5.95.9.15h.2q2.15.45 3.8 2.1 2.25 2.25 2.25 5.45T62.5 58.3q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-.85 4.2-4.1 7.45-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-1.45.55-3.05.8-.25.05-.5.05-.9.1-1.85.1-4.7 0-8.35-2.5l-20.2 43.35L134.1 48.4v.05q.9-.4 1.35-.5l-2.8-11.9h.05M228.75 8.2l.85 3.5q1 4.25 4.4 17.55L262.25 0l-33.5 8.2z"
                    id="ObjMus038__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M.05 26.45Q-.3 33.85 2.9 40.1l1.9-.5q.45-7.5-2.85-13.6l-1.9.45m7.05-1.7q-.3 7.3 2.8 13.4l1.85-.5q.9-7.3-2.75-13.35l-1.9.45m6.05-11.65q0-.1.05-.2l-.2-.1q.1.1.15.3m3.35 9.35l-1.9.45q-1.05 7.35 2.75 13.2l1.85-.55q2.05-7.7-2.7-13.1m7.7-1.85l-1.85.45Q21 27.95 25 33.95l1.85-.5q1-7.3-2.65-12.85m7.7-1.9l-1.9.45q-1.4 7.15 2.65 12.7l1.85-.5q.55-7.05-2.6-12.65M42.85 29l-.05-.3-.35.05.35-.1q2.25-7.1-2.5-12l-1.9.45q-2.2 7.3 2.6 12.45l1.85-.55m4.1-1.5l.7-.2-.7.15v.05m10.45-15q-1.15 6.9 2.45 11.8l1.85-.5q.65-6.15-2.4-11.75l-1.9.45m-7.65 1.85l-1.9.45q-.65 6.75 2.55 12.15l1.85-.55q-.1-6.1-2.5-12.05m25.45-6.1l-.75.25.75-.2v-.05m-2.9 12.6q.15-6.2-2.35-11.4l-1.9.45q-.45 6.1 2.4 11.45l1.85-.5m10.35-2.9l1.9-.5q.15-5.9-2.25-11l-1.9.45q-.5 5.6 2.25 11.05M94.85 3.4l-1.9.45q-2 6.2 2.2 10.65L97 14q.55-5.8-2.15-10.6m3.3-.8l.1.1q0-.098.05-.1h-.15M108.3 0l-1.9.5q-2.4 5.6 2.15 10.3l1.9-.55L108.3 0m-5.05 22.7l-.15-.3q-.05 0-.15.05.15.1.3.25z"
                    id="ObjMus038__Symbol_11_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M4 63.25h-.05q-.1.1-.15.2v.05l.2-.25m-1.95-.5l.15-.3-.1.1q-.05.1-.05.2m8-5q-.8-.8-2-.8t-2.05.8q-.8.85-.8 2.05 0 1.25.8 2.05.85.85 2.05.85 1.2 0 2-.85.85-.8.85-2.05 0-1.2-.85-2.05m-9.8-3.2H.2l.05.15v-.15m-.2-.75v-.05H0l.05.05M27 35.55l2.85 13.6 5.1-1.45-2.8-13.4L27 35.55m-4.8 12l.15 1.1h.05l.4 2.4 5.15-1.4L25.1 36l-4.75 1.15q-2.55 5.2 2.05 13.4l-.2-3m17.45-15.1l-5.6 1.4L36.8 47.2l5.6-1.55-2.75-13.2m-1.4-10l-.2-.1q.1.1.15.3 0-.1.05-.2m27.1 3.75l2.5 12-.35.1.35-.05.05.3 7.55-2.05-2.55-12.15-7.55 1.85m-8.4 2.05l2.6 12.65 6.5-1.8-2.6-12.45-6.5 1.6M51.9 43l5.8-1.6-2.65-12.7-5.8 1.45L51.9 43m-4.5-12.4L41.55 32l2.7 13.1 5.8-1.6-2.65-12.9m58.05-14.15L95 19l2.35 11.4 10.35-2.9-2.25-11.05M84.9 33.85l-2.45-11.8-7.65 1.85 2.5 12.05 7.6-2.1m8.2-14.4l-8.75 2.15 2.4 11.75 8.75-2.45-2.4-11.45m27.1 4.6L118 13.4 107.35 16l2.25 11 10.6-2.95m7.95 7.9q-.05 0-.15.05.15.1.3.25l-.15-.3m-4.55-19.9V12l-.25.05v.05l-3.45.85 2.15 10.6 11.55-3.2-2.15-10.3-4.25 1.05v.05l-3.6.9m26.6 3.7l-2.65-9.65-13.8 3.4h-.15l-.25.05 2.15 10.25 14.7-4.05m7.55-4.55q-.75-.05-1.35.5-.55.55-.5 1.3-.05.65.4 1.2l.1.1q.6.55 1.35.6.7-.05 1.25-.6.05-.05.15-.1.4-.55.45-1.2-.05-.75-.6-1.3-.55-.55-1.25-.5m4.75-.7q.05-.05.15-.1.4-.55.45-1.2-.05-.75-.6-1.3-.55-.55-1.25-.5-.75-.05-1.35.5-.55.55-.5 1.3-.05.65.4 1.2l.1.1q.6.55 1.35.6.7-.05 1.25-.6m2.45-6.6q-.75-.05-1.35.5-.55.55-.5 1.3-.05.65.4 1.2l.1.1q.6.55 1.35.6.7-.05 1.25-.6.05-.05.15-.1.4-.55.45-1.2-.05-.75-.6-1.3-.55-.55-1.25-.5m5.2-.9q.4-.55.45-1.2-.05-.75-.6-1.3-.55-.55-1.25-.5-.75-.05-1.35.5-.55.55-.5 1.3-.05.65.4 1.2l.1.1q.6.55 1.35.6.7-.05 1.25-.6.05-.05.15-.1z"
                    id="ObjMus038__Symbol_4_0_Layer0_0_FILL"
                />
                <path
                    d="M177.45 281.75q-.327-1.486-.6-2.7L168.4 281l4.1 20.1 7.95-2.2-.95-5.65h-.05l-.2-1.1h.05l-1.85-10.4z"
                    id="ObjMus038__Layer4_2_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M216.65 285.45l-2.65-12.6-1.85.45 2.6 12.7 1.9-.55m-7.7 2.15l-2.65-12.9-1.85.45 2.7 12.95 1.8-.5m13.5-16.85l-1.9.5 2.55 12.4 1.9-.5-.1-.35-.3.1.3-.1-2.45-12.05m19-4.6l-1.9.45 2.4 11.85 1.9-.55-2.4-11.75m-9.55 2.35l-1.9.45 2.5 12.1 1.9-.5-2.5-12.05m-46.85 25.75l1.85-.55-2.8-13.6-1.9.45 2.85 13.7m6.05-15.85l-1.85.45L192 292.3l1.9-.55-2.8-13.35m7.5-1.8l-1.85.45 2.7 13.15 1.9-.5-2.75-13.1m93.95-12.2l-2.1-10.25-1.9.45 2.15 10.3 1.85-.5m-40.45-.85l-1.9.45 2.35 11.5 1.9-.55-2.35-11.4m14.55 8l-2.25-11-1.9.45 2.3 11.1 1.85-.55m10.6-2.9l1.9-.55-2.2-10.6-1.9.45 2.2 10.7z"
                    id="ObjMus038__Layer4_3_FILL"
                />
                <path
                    fill="#EEE"
                    d="M131.45 305.5q-.31.36-.65.7-.304.293-.6.55l182.8-49.3q-.007-.528.35-1l-181.9 49.05m6-8.45q-.44.44-.9.8l183.8-46.75q-.183-.374-.15-.8v-.05L137.85 296.6q-.186.236-.4.45m-3.3 4.15q-.214.555-.5 1.05l182.9-47.95q-.07-.237-.05-.5-.014-.207 0-.4l-182.35 47.8m190.35-53.5l-.1-.1q-.266-.325-.35-.7L139.55 293q-.052.503-.2.95L324.5 247.7z"
                    id="ObjMus038__Layer4_4_FILL"
                />
                <g id="ObjMus038__Layer4_0_FILL">
                    <path
                        fill="#666"
                        d="M268.1 335.25h-.05q-.25.15-.45.3.3-.15.6-.2-.05-.1-.1-.1z"
                    />
                    <path fill="#F3CFA5" d="M219.4 252.35l.1-.05-.15.05h.05z" />
                    <path fill="#B5B5B5" d="M195.25 267.05h.1l-.2-.1.1.1z" />
                    <path d="M195.35 267.05h-.1q0 .1.05.2 0-.1.05-.2M135 342.85q.05-.05.15-.05.05-.05.1-.05h.05q.1-.1.25-.15l-.55.05v.2m-7.25-10.6v-.05q-.1.05-.1.1l.1-.05m21.25-11.6l.15.15-.05-.2-.1.05z" />
                </g>
                <g id="ObjMus038__Layer4_1_FILL">
                    <path
                        fill="#666"
                        d="M268.2 335.35q-.05-.1-.1-.1h-.05q-.25.15-.45.3.3-.15.6-.2z"
                    />
                    <path fill="#F3CFA5" d="M219.4 252.35l.1-.05-.15.05h.05z" />
                    <path
                        fill="#37438C"
                        d="M135.15 342.8q.05-.05.1-.05h.05q.1-.1.25-.15l-.55.05v.2q.05-.05.15-.05z"
                    />
                    <path
                        fill="#FEFD96"
                        d="M127.75 332.25v-.05q-.1.05-.1.1l.1-.05m21.25-11.6l.15.15-.05-.2-.1.05z"
                    />
                    <path
                        fill="#A30101"
                        d="M132.45 312l.05.05.1-.1-.15.05m24.3-17.95v.05l.2-.1q-.106.05-.2.05m-.35-.9v.05h.05l-.05-.05m-.75-4.15l-.05-.1v.2l.05-.1z"
                    />
                    <path fill="#3C0" d="M126.3 309.45l-.25.1h.05l.2-.1z" />
                    <path d="M130.9 306.05q-.03.08-.1.15-1.154 1.113-2.4 1.95.002.091-.4.3v-.05q-.041.063-.1.1l-.1.05q-.05.05-.15.1l-.8.4q-.081.046-.2.1 0 .05-.05.05-.131.116-.3.2l.3-.15q-.048.079-.1.1l-.2.1-.2.1h-.05q-.05 0-.1.05-.75.25-1.55.5l1 4 6.85-1.7-1.35-6.35z" />
                    <path fill="#960" d="M155.05 298.15l.05-.05-.1.05h.05z" />
                    <path fill="#FFF" d="M156.4 293.15l.05.05v-.05h-.05z" />
                    <path fill="#FBD8AE" d="M142.1 292.4h-.05l.05.25v-.25z" />
                </g>
            </defs>
            <use
                xlinkHref="#ObjMus038__Symbol_122_0_Layer0_0_FILL"
                transform="translate(201.6 230.4)"
                id="ObjMus038__col1n"
            />
            <use
                xlinkHref="#ObjMus038__Symbol_48_0_Layer0_0_FILL"
                transform="translate(74.95 238.75)"
                id="ObjMus038__col2n"
            />
            <use
                xlinkHref="#ObjMus038__Symbol_11_0_Layer0_0_FILL"
                transform="translate(182.15 254.15)"
                id="ObjMus038__col2l"
            />
            <use
                xlinkHref="#ObjMus038__Symbol_4_0_Layer0_0_FILL"
                transform="translate(157.1 244.6)"
                id="ObjMus038__col2s"
            />
            <use xlinkHref="#ObjMus038__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus038__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus038__Layer4_2_FILL" />
            <use xlinkHref="#ObjMus038__Layer4_3_FILL" />
            <use xlinkHref="#ObjMus038__Layer4_4_FILL" />
        </g></g>
    )
}

export default ObjMus038
