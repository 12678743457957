import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#705240",
    "col1n": "#583E2E"
}
function BodHor007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M11.7 5.25q.4-1.05.7-1.85.1-.35.15-.6.1-.35.15-.65Q12.95.6 12.35.2q-.1-.1-.25-.1Q10.25-.6 7.95 3l-.1.1-.4.6Q5.9 6 4.65 8.5-.4 18.7.05 32.15q0 15.6 6.1 27.45 1.25 2.45 2.8 4.75 5.7 10.6 8.75 28.25.6 3.85 1.15 8.05 1.3 3.9 3.7 6.15 7.5 5.3 15.75 0 3-2.45 3.5-7.35-.35-3.5-.9-6.85-.1-.8-.25-1.55-2.95-17.3-11.4-31.45-3.55-6-8.15-11.45l.8.75q-.15-.2-.3-.35-.3-.4-.6-.75Q4.05 24.45 11.7 5.25M130.1.1q-.15 0-.25.1-.6.4-.35 1.95.05.3.15.65.05.25.15.6.3.8.7 1.85 7.65 19.2-9.3 42.55-.3.35-.6.75-.15.15-.3.35l.8-.75q-15.35 18.25-19.55 42.9-.15.75-.25 1.55-.55 3.35-.9 6.85.5 4.9 3.55 7.35 7.85 5.7 15.7 0 2.4-2.25 3.7-6.15.55-4.2 1.15-8.05 3.05-17.65 8.75-28.25 8.9-13.35 8.9-32.2.45-13.45-4.6-23.65-1.25-2.5-2.8-4.8l-.4-.6-.1-.1q-2.3-3.6-4.15-2.9z"
                    id="BodHor007__Symbol_15_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M0 0q1.25 13.6 7.7 24 .4.75.9 1.45 5.7 10.6 8.75 28.25.45 2.8.85 5.85.85 3.1 4.9 3.5 4.15-.7 4.65-5.65-.1-.35-.15-.65Q24.5 38.65 15.45 24q-3.3-5.3-7.4-10.15l.8.75q-.15-.2-.4-.45-.3-.25-3.25-4.1Q2.45 6.45 0 0m128.8 0q-2.45 6.45-5.2 10.05-2.95 3.85-3.25 4.1-.25.25-.4.45l.8-.75Q105.4 32.1 101.2 56.75q-.05.3-.15.65.5 4.95 4.65 5.65 4.05-.4 4.9-3.5.4-3.05.85-5.85 3.05-17.65 8.75-28.25 7.25-10.9 8.6-25.45z"
                    id="BodHor007__Symbol_14_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHor007__Symbol_15_0_Layer0_0_FILL"
                transform="translate(121.4 4)"
                id="BodHor007__col1n"
            />
            <use
                xlinkHref="#BodHor007__Symbol_14_0_Layer0_0_FILL"
                transform="translate(128.1 39.6)"
                id="BodHor007__col1l"
            />
        </g></g>
    )
}
export default BodHor007
