import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#004D1E",
    "col1s": "#121212"
}

function ShiSho061({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M126.6 48.85q-.65-3.1-1.3-6.15l-.15-.85v.05l-.05-.15q-.2-.8-.3-1.55-.15-.05-.3.05-2.3-2.25-4.2-5.75-5.5-9.75-5.5-23.6 0-5.8 1.05-10.9-8.15 5-17.05 8.15-12.4 19.25-22.25 27.5Q68 42.85 66.5 44.3q-.4.1-.8-.05-19.1-16.4-26-26.8-4.55-6.95-6.35-9.55Q25.1 4.95 17.55.4q.85 4.9.85 10.5 0 13.85-5.55 23.6-2.05 3.7-4.4 6-.4 0-.6.2v.1l-.4 1.75q-.05-.05-.1-.05l-1 4.9L2.9 61.7q-1 3.9-1.95 7.8L0 73.1l.15.15-.05.1q.1.05.25.05l.1.1q.1.05.2.05H.8q2.05.5 4.15.95 6.85 1.55 13.7 2.75 7.95 1.4 15.85 2.35 1.9.15 4 .35.55.05 1.15.1l1.8.2h.25l5.35.45q1.8.15 3.85.35 33 1.9 65.75-4.25 5.5-1.05 11-2.3 2.1-.45 4.15-.95H131.95q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25z"
                    id="ShiSho061__Symbol_171_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1.6 37.4L1.55 0q-.2.2-.4.3-.4.1-.8-.05Q.2.15 0 0v37.4h1.6z"
                    id="ShiSho061__Symbol_200_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho061__Symbol_171_0_Layer0_0_FILL"
                transform="translate(126.15 236.1)"
                id="ShiSho061__col1n"
            />
            <use
                xlinkHref="#ShiSho061__Symbol_200_0_Layer0_0_FILL"
                transform="translate(191.5 280.1)"
                id="ShiSho061__col1s"
            />
        </g></g>
    )
}

export default ShiSho061
