import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#EEAA00",
    "col1l": "#FFEAAD",
    "col1n": "#FFC400"
}

function ObjFoo051({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.2 11.85h-.05q0 .1-.05.25H.1q-.05-.15-.05-.25H0v18.5q.75.15 1.5.4 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.9 3.4-2.6 5.1-.75.7-1.6 1.1l-.2.1h-.05q-1.75.65-3.35.8v2.15q.15 1.45 2.25 2.65.9.5 2.3.95 4.55 1.6 11 1.6 6.5 0 11.1-1.6 1.35-.45 2.3-.95 1.9-1.1 2.25-2.4v-43.8z"
                    id="ObjFoo051__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M28.95 2.65Q28 2.1 26.65 1.6 22.05 0 15.55 0q-6.45 0-11 1.6-1.4.5-2.3 1.05Q.15 3.8 0 5.25v.6q0 .15.05.3Q.5 8 4.55 9.4 8.4 10.75 13.6 11h1.95q6.5 0 11.1-1.6 4-1.4 4.5-3.25l.05-.1v-1q-.35-1.35-2.25-2.4z"
                    id="ObjFoo051__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    fillOpacity={0.749}
                    d="M13.25 10.9q-.4-1.2-1.5-1.6L5.5 7.4q-.8-.25-1.45-.2-.6 0-1.05.3-.65.45-.9.95-.25.25-.3.45L.25 13.55q-.1.4-.15.7-.05.2 0 .3L.05 16.5H.1q.673.336 1.55.85.883.56 1.35.9.456.396 1 1.05.15.15 1 1.75.697 1.356.85 1.95l2.15.6q2.6.8 3.4-1.4l1.85-4.45q.35-.7.3-1.3l.05-.3-.35-5.25M23 .4q-1.1-.6-2.15-.25l-6 2.75q-.7.35-1.2.75-.4.4-.6.9-.2.8-.05 1.35 0 .3.15.55l1.8 4.5q.2.4.35.65l.2.2 3.1 3.7q.15.3.4.55.95.85 2.8.1l5.55-2.65q2.55-1.2 1.7-3.35L27.5 5.6q-.2-.75-.65-1.2l-.15-.2L23 .4z"
                    id="ObjFoo051__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M120.85 284.85l-1.1 2.85q1.578.919 2.85 2.4l.2-.15q1.55-1.3 2-1.6.45-.35 1.9-.65 1.45-.25 2.35-.15l1.8.1q.3 0 .6-.25.25-.3.25-.5-.3-.35-.8-.6-.15-.1-.3-.15-.2-.1-.5-.2l-6.15-1.8q-.5-.15-.8-.2-.9-.1-1.45.15-.1.05-.15.05-.3.2-.7.7z"
                    id="ObjFoo051__Layer7_0_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M136.5 278.4q-.75.5-1 1.1-.05.1-.05.15-.15.35-.15.95l1.7 4.85q.2.2.55.4.6.3 1.2.3.55 0 .65-.95 0-1.1.05-1.4.1-.6.4-1.3.35-.85.85-1.4.5-.5 2.9-2.3 1.55-1.2 1.55-2.1 0-.7-.8-1.05-.55-.2-1.3-.25l-5.85 2.65q-.45.2-.7.35z"
                    id="ObjFoo051__Layer7_2_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M137.1 273.95q-.05 0-.05-.05-.6 0-1.55-.15-1.1-.15-1.2-.15-.55 0-1.05.3-.4.25-.5.6-.05.05-.05.1.2.7.5 1.25.15.2.35.4.75 1 1.8 1 .6 0 2.1-.6 1.1-.45 1.85-.85 1.25-.5 1.9-.8 1.2-.5 1.2-1 0-.55-1.1-.6h-.1q-.3 0-2.05.25-1.8.3-2.05.3z"
                    id="ObjFoo051__Layer7_3_FILL"
                />
                <path
                    fill="#FFF"
                    fillOpacity={0.502}
                    d="M125.25 279.75q-.7-.85-.8-.95-.4-.35-1-.4-.45-.1-.75.05l-.1.1q-.3.65-.45 1.25-.05.3-.05.55-.05 1.2.75 1.9.45.4 1.95.95 1.15.35 2 .6 1.25.4 1.95.6 1.25.4 1.55.05.4-.45-.45-1.15 0-.05-.05-.05-.25-.25-1.7-1.2-1.6-.95-1.75-1.1l-.05-.05q-.4-.4-1.05-1.15z"
                    id="ObjFoo051__Layer7_4_FILL"
                />
                <g id="ObjFoo051__Layer7_1_FILL" fill="#C1D2E2">
                    <path
                        fillOpacity={0.702}
                        d="M119.5 264.8q-2.1-1.15-2.3-2.6v5.8q0 .15.05.3.5 1.9 4.5 3.25 3.85 1.4 9.1 1.6.8.05 1.65.05h.3q6.5 0 11.1-1.65 4-1.35 4.5-3.25v-5.85q-.3 1.3-2.25 2.35-.95.55-2.25 1-4.6 1.6-11.1 1.6-6.45 0-11.05-1.6-1.35-.45-2.25-1z"
                    />
                    <path
                        fillOpacity={0.349}
                        d="M117.2 262.2q.2 1.45 2.3 2.6.9-.55 2.25-1 4.6-1.6 11.05-1.6 6.5 0 11.1 1.6 1.3.5 2.25 1 1.95-1.05 2.25-2.35v-1q-.45-2-4.5-3.4-4.6-1.6-11.1-1.6-6.45 0-11.05 1.6-4.3 1.5-4.55 3.6v.55z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo051__Symbol_79_0_Layer0_0_FILL"
                transform="translate(117.25 256.45)"
                id="ObjFoo051__col1n"
            />
            <use
                xlinkHref="#ObjFoo051__Symbol_48_0_Layer0_0_FILL"
                transform="translate(117.25 262.15)"
                id="ObjFoo051__col1d"
            />
            <use
                xlinkHref="#ObjFoo051__Symbol_78_0_Layer0_0_FILL"
                transform="translate(118.55 270.55)"
                id="ObjFoo051__col1l"
            />
            <use xlinkHref="#ObjFoo051__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo051__Layer7_1_FILL" />
            <use xlinkHref="#ObjFoo051__Layer7_2_FILL" />
            <use xlinkHref="#ObjFoo051__Layer7_3_FILL" />
            <use xlinkHref="#ObjFoo051__Layer7_4_FILL" />
        </g></g>
    )
}

export default ObjFoo051
