import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#830000",
    "col1n": "#C40000"
}

function BodWin004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.65 0Q61 2.6 47.2 28q-17.25 31.6-34.25 86.2-14.2 57-12.85 83.55l1.3 26.45q.05.3.05.65 11.3-17.35 22.75-19.95v-.05q11.3-1.95 22.65 6.1-.2-.4-.45-.75.2.1.35.2.6.4 1.2.9.15.25.25.55 3.4-24.55 21-31.6h.05q9.05-4.1 15.8 1.75h.05v-.05q-.85-32 15.1-37.75.2-.45 18.4.55 11.7.6 12.8-1.6.35-.15.7-.4v-6.55q-1.7-8.05-6.25-10.5-11.25-6.05-21.05-14.15Q95 103.4 86.65 96.2q-23.2-25-20.75-67.85Q63.7 4.65 65.65 0M84.5 181.1q.25.4.55.85l-.9-1.1q.2.1.35.25m263 15.3q.6-27.85-12.85-81.05-18.15-57.55-35.05-88.5-9.85-18-16.45-25.1-.9 3.4-.05 21.35-2.3 49.45-21.25 74.1-6.7 10.65-23.1 18.9-14.45 7.25-22.4 22.8v4q.25.15.5.25 1.1 2.2 12.8 1.6 18.2-1 18.4-.55 15.95 5.75 15.1 37.75v.05h.05q6.75-5.85 15.8-1.75h.05q17.6 7.05 21 31.6.1-.3.25-.55.6-.5 1.2-.9.15-.1.35-.2-.25.35-.45.75 11.35-8.05 22.65-6.1v.05q11.45 2.6 22.75 19.95 0-.35.05-.65l.65-27.8m-83.75-15.3q.15-.15.35-.25l-.9 1.1q.3-.45.55-.85z"
                    id="BodWin004__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M67.65 6.5q-2.6 4.05-5.15 8.15Q-11.25 133.7 2 238.5q.05-.05.05-.1Q-1 137.6 58.35 27.35 17.7 168.8 47.05 224.4q.2.1.35.2.6.4 1.2.9-27.2-66.15 11.6-194.35-15.45 111.5 24.6 163.9.2.1.35.25-37-59.1-21.05-173.25h.1q-5.45 90.5 46.25 116.4.2.05.35.15l-.2.3q13.65 6.1 20.25 13.55 2.3 2.65 1.2 4.9 2-.85 4.45-5 2.4-7.8-4.9-13.5-1.5-1-3.25-2.2-8.15-5.4-23-14.1Q70.2 102 67.8 37.3 66.2 12.45 70.95 0q-.45.75-3.3 6.5M347.5 238.4q0 .05.05.1 13.25-104.8-60.5-223.85-2.55-4.1-5.15-8.15-2.85-5.75-3.3-6.5 4.75 12.45 3.15 37.3-2.4 64.7-37.55 85.25-14.85 8.7-23 14.1-1.75 1.2-3.25 2.2-7.3 5.7-4.9 13.5 2.45 4.15 4.45 5-1.1-2.25 1.2-4.9 6.6-7.45 20.25-13.55l-.2-.3q.15-.1.35-.15 51.7-25.9 46.25-116.4h.1Q301.4 136.2 264.4 195.3q.15-.15.35-.25 40.05-52.4 24.6-163.9 38.8 128.2 11.6 194.35.6-.5 1.2-.9.15-.1.35-.2 29.35-55.6-11.3-197.05 59.35 110.25 56.3 211.05z"
                    id="BodWin004__Symbol_8_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodWin004__Symbol_12_0_Layer0_0_FILL"
                transform="translate(18.4 110.9)"
                id="BodWin004__col1n"
            />
            <use
                xlinkHref="#BodWin004__Symbol_8_0_Layer0_0_FILL"
                transform="translate(17.75 96.7)"
                id="BodWin004__col1d"
            />
        </g></g>
    )
}

export default BodWin004
