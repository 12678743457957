import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiSho016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M121.85 27.4q-.7-16.9 4.5-27.15.05-.15.1-.25l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-20.4 14.1-45.85 16l-1.1.05q-3.1.2-6.3.2h-1.2q-3.2 0-6.3-.2l-1.1-.05Q35.35 18.95 16.45 7.2q-2-1.2-3.9-2.55L11 3.5Q9.55 2.45 8.2 1.4L6.4.05l.05.1V.2q5.1 10.35 4.4 27.2-.15 3.45-.55 7.2-.05.4-.1.85l-2.7 14q-.55 2.7-1.1 5.45L2.95 69.2Q1.95 73.1 1 77l-.95 3.6v.25L0 80.9h.2q.25.1.5.15H.85q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75q-.05-.45-.1-.85-.4-3.75-.55-7.2z"
                    id="ShiSho016__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho016__Symbol_24_0_Layer0_0_FILL"
                transform="translate(126.1 228.6)"
                id="ShiSho016__col1n"
            />
        </g></g>
    )
}

export default ShiSho016
