import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2"
}

function ObjToy069({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M49.85 16.25l-.35.4-.3.3q-1 1-2.15 1.55l-.3.1q-.05 0-.1.05-1 .4-2.15.5l-.75.05h-.1l-1.35-.1q-1.1.1-1.4-.4-1.5-.55-2.7-1.75-2.05-2.05-2.2-4.9L0 30.6q9.5 5.5 19.95 8.45 16.25-8.6 32.1-22.15l-1.85-1.1q-.15.25-.35.45m125.4 23.6q.05.2 0 .4-.35 1.85-3.9 4.1-5.8 3.7-15.35 3.7-6.65 0-12.95-.85-6.4-.9-14.1-.9-13.65 0-23.25 3.7-6.25 2.35-6.35 2.4-.1 0-.2.05-3.5 1.1-7.3 1.1-6.45 0-9.7-2.5-3.95-3.15-7.7-4.1-4.1-1.15-6.3-2.95-2.6-2.2-4.2-6.5-5.1-14.15-13.65-14.85h-.7q-3.15.25-.55 1.3h1.15q7.75.8 12.55 14 1.7 4.65 4.55 7.05 2.35 2 6.8 3.2 3.5.9 7.25 3.85v.05q3.55 2.75 10.5 2.75 4 0 7.7-1.15h-.15q.4-.1 6.75-2.5 9.45-3.6 22.8-3.6 7.6 0 13.9.9h.05q6.35.85 13.1.85 9.95 0 16.05-3.9 4.25-2.65 4.5-5 .05-.2.05-.35v-.15l-1.2-.55q-.2.2-.15.45m9.6-37.8h-.15q-1.35.6-2.65 1 1.4-.65 2.65-1.55Q182.2.1 180 0q-.4-.1-2 1.25h-.3q-.35-.45-.95-1.15-.45-.15-1.4-.05-2 0-4.35 1.45.8.7 1.6 1.2-.85-.3-1.75-.7-.45 1.75-.8 3.65.1.15.25.25l.1.1q1.6 1.6 2.1 3.65.2.9.2 1.85 0 3.2-2.3 5.45-.45.45-.85.8-.15.1-.3.25.15 8.85 2.45 15.25.7 1.95 1.5 3.4-.25-2.4-.25-11.85 0-1.2.15-2.2-.3-1.25-.3-2.7.15-1.75.15-1.95 0-2.4.8-5.3.05-.55.15-1.05.05-.35.15-.65.4-.75.95-1.3 2.95-3.05 9.7-1.85l1.5.3-1.15-5.15q-.05-.25-.1-.45-.1-.25-.1-.45z"
                    id="ObjToy069__Symbol_204_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M31.55 9.75L35.7 0 3.6 22.15q-3.7.9-1.1 2.55-3.65 2.35-2 4.7h.05q1.95-1.6 3.65-2.95 4.05-3.25 6.65-5.05 4.1-2.8 4.75-2.3l2.15.95 13.8-10.3m137.7 1.45q-1.4.45-3.55.45-6.95 0-8.7-5-.15-.45-.25-.95-.15 1-.15 2.2 0 9.45.25 11.85h.05q.95 1.8 2.15 2.75l1.2.55v.15q.3.1.65.15.3.05.6.05 3.6 0 6.1-7.05.75-2 1.65-5.15z"
                    id="ObjToy069__Symbol_205_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M28.15 26.4l-3.75 2.45-3.65-1.8-3.65 1.85 4 1.85-4.3 2.6 3.55 1.95 4.3-2.9 4.75 2.3 3.25-2.2-4.95-2.1 4.1-2.3-3.65-1.7M1.05 42.5q.5.55 1.05 1.1l.45.45q5.1 4.7 12.65 5.3.45.05.9.05.9.05 1.8.05l1.7-.1h.45l2.05-4.7h-.05l1.1-2.55L3.2 33.65 0 41.25q.5.65 1.05 1.25m32.2-19.7q-.1.1-.15.2-.35.4-.45.95v.35q-.05.8.6 1.45.15.15.35.3.45.3 1.05.35h.1q.8 0 1.45-.65.65-.65.65-1.45 0-.7-.45-1.25-.1-.15-.2-.25-.65-.65-1.45-.6-.85-.05-1.5.6m2.35-4.65q-.1.1-.15.25-.15.25-.15.55 0 .45.3.75.2.2.45.3.15.05.35.05.45 0 .75-.35.35-.3.35-.75t-.35-.8q-.3-.3-.75-.3t-.8.3M44.85 22q-.15-.1-.3-.1h-.15q-.35 0-.6.2-.1 0-.2.1t-.15.25q-.15.25-.15.55 0 .1.05.25.05.3.25.5t.45.3q.15.05.35.05.45 0 .75-.35.2-.15.3-.35.05-.2.05-.4 0-.4-.3-.7 0-.05-.05-.1-.15-.15-.3-.2m-4-.45q.35-.3.35-.75t-.35-.8q-.3-.3-.75-.3t-.8.3q-.1.1-.15.25-.15.25-.15.55 0 .45.3.75.1.1.25.2l.2.1q.15.05.35.05.25 0 .5-.1.15-.1.25-.25M188 5.05l-.1-.5q-2.75 1.2-5.5 1.45-4.1.3-8.2-1.45-.05.25-.15.5 7 3.2 13.85.05h.15q-.05-.002-.05-.05M187.5 1q0-.4-.15-.75l-.2-.25H175.6q-.1 0-.15.05-.25.15-.25.95 0 .5.1.75l.05.1h.05l.15.15h4.4v2.3h2.45V2h4.5q.2-.05.35-.1.05 0 .1-.05h.05q.1-.25.1-.85z"
                    id="ObjToy069__Symbol_206_0_Layer0_0_FILL"
                />
                <path
                    d="M103.05 319l-12.25-5.2-2.55 5.85q3.75 4.05 12.65 5.1l2.15-5.75z"
                    id="ObjToy069__Layer2_0_MEMBER_0_FILL"
                />
                <g id="ObjToy069__Layer2_0_FILL">
                    <path
                        fillOpacity={0.302}
                        d="M90 315.6q1.724.314.25-.5L87 313.75l-.25.5L90 315.6m48.75-14.95l.4-.9-32.45 22.2-4.1-1.7q-1.742-.136-.2.5l4.35 1.8 32-21.9z"
                    />
                    <path
                        fill="#E00"
                        d="M108.2 315.05q-.5 0-.85.3-.3.35-.3.85t.3.8q.35.35.85.35t.8-.35q.35-.3.35-.8t-.35-.85q-.3-.3-.8-.3z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjToy069__Symbol_204_0_Layer0_0_FILL"
                transform="translate(88.25 280.1)"
                id="ObjToy069__col1n"
            />
            <use
                xlinkHref="#ObjToy069__Symbol_205_0_Layer0_0_FILL"
                transform="translate(104.6 297)"
                id="ObjToy069__col1d"
            />
            <use
                xlinkHref="#ObjToy069__Symbol_206_0_Layer0_0_FILL"
                transform="translate(85.05 277.05)"
                id="ObjToy069__col1s"
            />
            <use xlinkHref="#ObjToy069__Layer2_0_FILL" />
            <use xlinkHref="#ObjToy069__Layer2_0_MEMBER_0_FILL" />
        </g></g>
    )
}

export default ObjToy069
