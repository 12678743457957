import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#0000BB",
    "col2n": "#A37831"
}

function ObjMus033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M115.65 28.05q-16.25 1-30.9 12.5Q78.9 45.9 73.8 49.1q-.8.45-1.5.9l-1.7.9q-7.9 3.85-16.15 2-.45-.15-.95-.25-3.65-1.05-5.55-1.55t-2.45-.7l-.55-.25-3.4 17.5q.25 0 .5.05t.55.1q2.15.45 3.8 2.1 2.25 2.25 2.25 5.45T46.4 80.8q-.35.35-.7.65-.7.55-1.45.9l-.3.1q-.05 0-.1.05-.85 4.2-4.1 7.45-1.4 1.35-2.9 2.3l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-2.5.95-5.4.95-6.25 0-10.65-4.4Q14 85.55 14 79.35q0-5.6 3.6-9.75l.3-.3.05-.05q0-.05.05-.05.2-.25.45-.5l.2-.2.05-.05q0-.05.05-.05.15-.15.35-.25.05-.1.1-.15 1.7-9.3 4.15-17.6-.55.05-1.25.25-2.65.85-5.15 2.15-4 2.05-5.75 4.1v-.1l-.05.05q-1.35 1.1-2.5 2.4-.65.8-1.2 1.6-2.85 4-4.65 8.2-.15.35-.3.8-1.1 2.7-1.7 5.55-.2.85-.35 1.75-.7 4.15-.3 8.5Q.4 92.5 2.5 98q.85 2.3 2 4.35.05-.05.15-.05.3.7.65 1l-.1.1q1.35 2.1 3.1 3.95l.5.5q.1-.05.2-.05.2.25.45.55.5.55.9.8h-.15q.1.1.2.15 1.95 1.65 4.25 3.05 18.4 11.85 37.3 6.4 3.9-.9 7.4-2.75 4.95-2.75 8.95-7.5.05-.1.1-.1l.05-.05q1.4-1.55 2.75-3.15l.4-.5q3.9-5.4 12.05-5.1 1.6-.1 3.35.15 2.5.3 5.1.4 2.75.05 5.55-.2 16.15-3.65 15.95-14.1-3.25-.65-5.4-2.45-.55-.35-1.1-.95-.75-.9-1.4-2.1-.9-1.6-1.3-4.15-.3-2.55-.55-3.8-.25.1-.5.15l-2.1.75v-.05l-.1.05q-3.3-1.2-5.4 1.95l-.45-1.45v-.05l-.05-.05h.05L94 67.35q-.35-2.75-2-4.85h-.05l-.5-1.8q3.6 1.2 4.7-1.55l.15-.05v-.05l2.55-.75h3.95l.45.7.1-.4v1q.7-4.4 3.2-9 3.1-5.7 6.75-8.35 3.65-2.65 4.35-3.2.1-.05.15-.15.3-.15.65-.3 9.45-5.6 0-10.2-1.25-.3-2.8-.35M92.7 60.45h-.05l.05-.05v.05M278.15 7.7q0-3.05-1.4-4.4-1.85-1.8-7.9-3.25-2.6-.1-4.55-.05-4.2.85-11.95 4-10.9 4.35-15.9 4.35-.85 0-1.45-.05-.3 0-.5.05-2.15 3.1-5 5.45-.4.3-1.3 1-.95.7-1.85 1.55-.95.8-.35.35.3 6.35 3.7 11.15l2.9-.1q1.5-.25 2.85-.25 3.25.05 5.9 1.6 2.1-1.2 4.2-2.2 9.1-4.35 18.65-5.95l1.6-.3q1-.15 2.05-.25-.9-.75-1.8-2.85-1.05-3.15-1.05-3.2 0-1.45.55-2.9.95-2.35 3-2.35 1.7 0 2.3 1.8.5 1.55 2.75 1.8 1.05-.2 1.85-.5.6-.25 1.15-.6.35-.3.6-.65.95-1.15.95-3.25z"
                    id="ObjMus033__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M20.4 37.35l-.05.05-1.8.6 5.2 13.65 6.9-2.4L25.5 35.7l-5.1 1.65m-4.7 1.55l-8.3 2.7 5.1 14.05.95-.35v.05l7.55-2.7-.1-.3-.05.05-.1-.2-5.05-13.3M.5 45.8h.05l2.05 7.05h.05L3.85 57H3.8l.05.05v.05l.45 1.45 5.4-1.95-5-14.15L0 44l.5 1.8m.75-2.05H1.2l.05-.05v.05m49.9-16.6l4.75 13.2 8.25-2.95-4.55-13-8.45 2.75M40.5 40.75h.05l-3.35-8.9-8.8 2.9 5.3 13.45 8.5-3.05-1.7-4.4m7.6-12.6l-4.75 1.55-.2.1-.3.2-2.9.95L44.9 44.2l7.85-2.75-3.5-10.15h.05l-.35-1.05h-.1l-.75-2v-.1M70.05 21l-7.5 2.45 4.3 13.05 7.7-2.75L70.05 21m10.1-3.2l-6.95 2.25 4.2 12.65 6.95-2.45-4.2-12.45m10.65-3.45l-7.7 2.5 4.25 12.35L95 26.5l-4.2-12.15m25.35-8.3l-.35.15h-.05L105.7 9.5l4.5 11.65 10.65-3.8-4.7-11.3M107 22.25l-4.55-11.7-8.9 2.9 4.35 12 9.1-3.2m16.75-5.95l9-3.2L128.5 2l-9.3 3 4.55 11.3m7.3-15.15l4.1 11.15 3.1-1.15L134.55 0l-3.5 1.15z"
                    id="ObjMus033__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M106.25 301.55q-.849-1.917-1.1-4.1l-13.85 7.5q.05.45.05 1.2.55 5.95 3.4 11.2.2.25.45.55l22.95-2.15-1.45-5.55q-4.069-.869-7.2-4l-.3-.3q-1.91-1.985-2.95-4.35M92.3 304.7h-.1v-.05l.1-.05v.1m62.2-15.55l2.8 10.8v.1l.05.05 1.65 6.35q.35 2.8-3.4 4.5l-32 10.4q-.5.3-.9.6-3.8 3.55 0 7.15 2.6 2.6 7.5 2.95 10.4.95 19.9-3.85 1.1-.7 2.15-1.45 3.95-2.8 6.25-6.35 2.45-3.5 6.25-5.45l22.8-8.55q2.673-1.24 2.6-3.2-.002-.57-.25-1.2l-3.15-10.5-.4-1.45V290h-.05v-.05l-3.3-11.2-.9-3.2q-1.35-5.35-6.55-2.85-1 .4-2.35.95-3.613 1.477-6.6 2.6-2.676 1.018-4.85 1.75-1.25.4-4.25 1.1-4.918 1.266-4.2 5.15.026.094.05.2l1.15 4.7m8.7-6.4l8-2.45 3.85 13.5v.05l.4 1.45-7.95 2.75-4.3-15.3m-23.9 11.3q-.448 1.454-1.55 2.65h.05l-.9.8 4 13.9 11-3.9-7-24.4-8 2.55q.287.237.55.5 1.615 1.615 2.05 3.7.2.848.2 1.75 0 .723-.15 1.4-.065.541-.25 1.05M351.35 238q.2 0 .3.5.1.45.45 1.3-.1 0-.6.3-.7.4-1.65 1.1.5 1.2.7 1.7.5 1.1.55 1.35.15.35.35.85.3.05.6.05 1.6 0 3.3-.45 1.5-.45 3.05-1.25 1.2-.65 1.95-1.15l-1.45-4.3q-.2 0-.5.05h-.25q-.15 0-.5.05-.6 0-1.8.25v-.1q-.25-.7-.2-1.2-1 .15-2.3.4-1.3.2-2 .55m-14.5 5.85q.15.4.4 1v.05q-1.15.65-2.2 1.45-.35.25-.65.55l1.6 4.25q2.75-.15 6.65-2.45 2.2-1.3 3.2-2.3l-.3-.7q-.4-1-.6-1.65-.4-1.05-.65-1.7-1.2 0-3.05.7h-.1q-.05 0-.05.05-.5-1.4-.55-1.75 0-.05-1.1.45-1.15.45-1.9.85-.75.4-.7.45h-.2q.1.35.2.75m-10.25-22.7q.15.35.3.75.2.55.45 1.35 1.2.1 2.1-.25l.4-.1h.1q.05-.05.2-.1h.05q0-.05.05-.05t.15-.05q0-.05.1-.05.05 0 .15-.05.4.9.7 1.55 3.05-.7 3.75-.95-.4-1.4-.65-1.85l2.45-1.4q-.45-1.45-.7-2.15-.45-1.4-.75-2.25-1.55-.35-3.9.5-2.35.85-3.1 1.3-.8.4-1.7 1.2-.5.4-.75.7.2.85.6 1.9m24.4-8.45q-.4-1.55-.5-2-1.05-.3-2.5.05-4.25.9-6.15 2.35-1 .75-1.45 1.35.4 1.35.65 2.2.2.8.25 1.15 0 .2.1.3.05.1.45.05.35-.05 1.3-.25.9-.2 1.5-.4.5.85.65 1.5.1.65.3.35 1-.35 2.3-.8 1.5-.6 1.9-.65l-.55-2q.45-.25.85-.5.85-.6 1.4-.95-.4-1.45-.5-1.75z"
                    id="ObjMus033__Layer4_0_FILL"
                />
                <path
                    fill="#999"
                    d="M347.6 234.05q.202-.04.3-.25.092-.158 0-.35-.04-.202-.25-.3-.158-.092-.35-.05l-219.1 75.2q-.153.107-.3.2l-.1.05q-.05.05-.15.1l-.8.4q-.35.2-.75.35-.1.05-.15.1h-.05q-.1.05-.15.1-.1 0-.2.05-1.81.688-3.85.85l-4.65 1.6.35.95 230.2-79m-.45-2.6q.202-.04.3-.25.092-.158 0-.35-.04-.202-.25-.3-.158-.092-.35-.05L133.5 302.6q-.355.678-.8 1.3l214.45-72.45m.4-5.35q.201-.04.3-.25.092-.157 0-.35-.04-.201-.25-.3-.157-.092-.35-.05l-207.65 67.7q-.083.572-.25 1.1l208.2-67.85m-.05 2.45q.201-.04.3-.25.092-.157 0-.35-.04-.201-.25-.3-.157-.092-.35-.05l-210.4 70.05q-.721.578-1.5.95l-.3.1q-.05 0-.1.05-.064.315-.15.6l212.75-70.8z"
                    id="ObjMus033__Layer4_1_FILL"
                />
                <g id="ObjMus033__Layer13_0_FILL">
                    <path d="M257.55 309.3l-.15-.1q.05.05.15.1z" />
                    <path
                        fill="#171717"
                        d="M195.55 271.15q-.067.033-.15.05l-.25.9-.05.05-.25.75q2.352.997 3.3-1.1l4.7-1.45q-.1-.05-.15-.05h-.05l.05-.05v.05q.002-.05.1-.05l.1-.05v.1q3.215.758 5.1-1.7l2.75-.9v.05l.15-.05q6.727 2.207 8.8-2.9l5.65-1.85.3-.2.2-.1q.85-.55 1.65-1.15.35-.25.7-.45l1.8-1.4q1.35-1.05 2.6-2.1 1.852-1.61 3.4-3.25 12.916-12.494 16.2-25.7-9.722 7.952-20.6 12.8-12.999 17.581-36.05 29.75z"
                    />
                    <path
                        fill="#FFF"
                        d="M288.15 242.45l-3.2 1q-3.655 3.874-8.9 2.95l-2.8.9q-2.845 3.173-7.65 2.45l-2.95 1q-1.961 3.963-6.95 2.25l-.2.05-.5.2v-.05l-.05-.05-2.4.8q-3.192 4.08-7.55 2.45l-2.95.95q-4.005 4.114-8.4 2.75l-.15.05v-.05l-2.85.9-.1.05.05.05q-2.72 3.702-4.75 1.55l-.2.1-.3.2-5.65 1.85q-2.073 5.107-8.8 2.9l-.15.05v-.05l-2.75.9q-1.885 2.458-5.1 1.7l-.05.05-4.7 1.45q-.948 2.097-3.3 1.1l-.55 2.35-.45-.7h-3.95l-2.55.75v.05l-.15.05q-1.1 2.74-4.7 1.55l.5 1.8q1.677 2.104 2 4.85l1.3 6.35v.05h.05v.05l.4 1.45q2.125-3.174 5.4-1.95l.15-.05v.05l3.65-1.3v.05q3.407-3.743 7.55-2.7l2.75-1q1.06-4.6 6.9-2.45l3-1.05q2.508-6.001 8.5-3h.05l2.7-.95q2.482-3.371 7.85-2.8l3.1-1.1q2.565-3.022 8.25-2.9l2.7-.9q1.587-3.37 7.7-2.8l2.9-1.05q3.002-4.04 6.95-2.45l3-1.05q2.631-4.098 7.65-2.65l2.9-1.05q3.656-4.102 9.1-3.2l3.15-1.15q4.392-5.705 10.65-3.8l2.95-1q3.58-4.547 9-3.2l2.2-.75.15-.1q.422-3.063 3.15-1.1l-.35-1.15h-.05l-.35-1.05h.05l-2.65-3.15 1.4 1.25q-.472-.616-.5-1.5-.452 2.062-.55-3.4h.05l-.75-2.15q-1.597 2.213-3.55 1.1l-2.5.85q-3.547 2.64-9.3 3.05l-3.45 1.15q-3.673 4.222-10.1 3.35m-42 23.65l-.1.05v-.2l.1.15z"
                    />
                </g>
                <g id="ObjMus033__Layer4_2_FILL">
                    <path d="M102.95 303.45l-.35-1.7-2 .7 3.25 13.15 1.7-.45-.25-1.3-2.35-10.4z" />
                    <path
                        fill="#6B6B6B"
                        d="M109.5 306.05l-.3-.3 1.6 7.3 6.4-1-.5-1.95q-4.05-.9-7.2-4.05m20.9 18.25q-.2-.8-.85-1.45-.85-.85-2.05-.85-1.25 0-2.15.85-.85.9-.85 2.1 0 .2.05.4.05.5.25.95.2.4.55.75.9.9 2.15.9 1.2 0 2.05-.9.75-.7.9-1.7v-.4q0-.35-.05-.65m-2.75-1q.65 0 1.1.45.45.5.45 1.15 0 .65-.45 1.1-.45.45-1.1.45-.65 0-1.15-.45-.45-.45-.45-1.1 0-.65.45-1.15.5-.45 1.15-.45m8.9 2.4q-.85-.85-2.05-.85-1.25 0-2.15.85-.85.9-.85 2.1 0 1.25.85 2.1.9.9 2.15.9 1.2 0 2.05-.9.9-.85.9-2.1 0-1.2-.9-2.1m-3.5 2.05q0-.65.45-1.15.5-.45 1.15-.45.65 0 1.1.45.45.5.45 1.15 0 .65-.45 1.1-.45.45-1.1.45-.65 0-1.15-.45-.45-.45-.45-1.1m3.3-8.8q-.85.9-.85 2.1 0 .2.05.4.1 1 .8 1.7.9.9 2.15.9 1.2 0 2.05-.9.75-.7.9-1.7v-.4q0-1.2-.9-2.1-.85-.85-2.05-.85-1.25 0-2.15.85m.7 2.05q0-.65.45-1.15.5-.45 1.15-.45.65 0 1.1.45.45.5.45 1.15 0 .65-.45 1.1-.45.45-1.1.45-.65 0-1.15-.45-.45-.45-.45-1.1m17.05-6.15q-.75 0-1.3.5-.5.55-.5 1.3 0 .2.05.4.1.5.45.85.55.55 1.3.55.3 0 .55-.1.4-.1.7-.45.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5m-6.4 6.3q-.4-.1-.8-.1-1.25 0-2.15.85-.85.9-.85 2.1 0 .95.5 1.7.15.2.35.4.9.9 2.15.9 1.2 0 2.05-.9.9-.85.9-2.1 0-1.2-.9-2.1-.55-.55-1.25-.75m.45 1.65l.05.05q.4.5.4 1.1 0 .65-.45 1.1-.45.45-1.1.45-.25 0-.4-.05-.4-.1-.75-.4-.45-.45-.45-1.1 0-.65.45-1.15.5-.45 1.15-.45.65 0 1.1.45z"
                    />
                    <path
                        fill="#A8A8A8"
                        d="M106.25 301.45l-3.3 2 2.35 10.4 5.5-.8-1.6-7.3q-1.9-2-2.95-4.3m245.25-61.5q-.7.4-1.65 1.1.5 1.2.7 1.7.95-.15 2.1-.5 1.1-.35 2.45-.9 1.35-.6 2.65-1.05 1.05-.45 1.75-.7l-.6-1.75q-.2 0-.5.05h-.25q-.15 0-.5.05-.6 0-1.8.25-.8.2-1.8.6-1 .35-1.95.85-.1 0-.6.3m-16.45 6.25q-.35.25-.65.55l.75 2q2.05-.75 4.9-2.1 3.15-1.5 4.9-2.75-.4-1.05-.65-1.7-1.2 0-3.05.7h-.1q-.05 0-.05.05l-.05-.05q-1.05.4-1.7.7-1.15.45-2.1 1.15-1.15.65-2.2 1.45M326.6 221q.15.35.3.75.2.55.45 1.35 1.2.1 2.1-.25l.4-.1h.1q.05-.05.2-.1h.05q0-.05.05-.05t.15-.05q0-.05.1-.05.05 0 .15-.05 1.4-.35 2.95-.95.5-.2.85-.3l2.45-1.4q-.45-1.45-.7-2.15-1.85.3-5 1.5-3.15 1.15-4.6 1.85m24.9-6.7q-.4-1.45-.5-1.75-3.3.8-4.2 1.15-.9.3-2.95 1.3-2.1.95-2.8 1.5.2.8.25 1.15 0 .2.1.3.05.1.45.05.35-.05 1.3-.25.9-.2 1.5-.4 3.7-1.35 4.35-1.6.6-.3 1.1-.5.85-.6 1.4-.95z"
                    />
                    <path
                        fill="#FFF"
                        d="M142.95 288.7l-3.4 1.05q.15.8.15 1.7 0 .7-.1 1.4l3.9 14.3 4.1-1.45-4.65-17z"
                    />
                    <path
                        fill="#CCC"
                        d="M347.6 230.3h-.2q-.5.05-.9.25-.1.05-.2.15-.3.15-.5.35-.5.5-.65 1.1v.1q-.1.3-.1.6v.35q.05.25.15.45.05.2.2.45l.05.05q.1.25.35.5.75.75 1.8.75t1.8-.75q.75-.75.75-1.8t-.75-1.8q-.6-.6-1.35-.7h-.1q-.2-.05-.35-.05m1.85-2.5q.75-.75.75-1.8t-.75-1.8q-.75-.75-1.8-.75t-1.8.75q-.75.75-.75 1.8 0 .15.05.3 0 .4.2.8t.5.7q.15.15.3.25.65.5 1.5.5h.2q.35-.05.9-.3.15 0 .35-.15.15-.1.35-.3m-13.2 6.3q-.4 0-.75.1l-.35.2q-.4.15-.7.45-.45.45-.6 1.05-.15.35-.15.75 0 .15.05.3v.05q0 .4.2.75.05.1.15.25.15.25.35.45.15.15.3.25.65.5 1.5.5h.2q.35-.05.9-.3.15 0 .35-.15.15-.1.35-.3.75-.75.75-1.8v-.3q-.1-.55-.3-.95-.2-.3-.45-.55-.15-.15-.35-.3-.65-.45-1.45-.45m2.85-6.35q-.75-.75-1.8-.75t-1.8.75q-.75.75-.75 1.8v.05q0 .15.05.25 0 .35.15.75.05 0 .05.05.2.4.5.7.1.1.25.2 0 .05.05.05.65.5 1.5.5h.35l.6-.2q.05-.05.15-.1h.05l.2-.05.1-.1q.15-.1.35-.3.5-.5.7-1.15.05-.3.05-.65 0-.3-.05-.6-.1-.45-.4-.85-.15-.2-.3-.35z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ObjMus033__Layer13_0_FILL" />
            <use
                xlinkHref="#ObjMus033__Symbol_117_0_Layer0_0_FILL"
                transform="translate(91.05 216.25)"
                id="ObjMus033__col1n"
            />
            <use
                xlinkHref="#ObjMus033__Symbol_43_0_Layer0_0_FILL"
                transform="translate(182.5 232.95)"
                id="ObjMus033__col2n"
            />
            <use xlinkHref="#ObjMus033__Layer4_0_FILL" />
            <use xlinkHref="#ObjMus033__Layer4_1_FILL" />
            <use xlinkHref="#ObjMus033__Layer4_2_FILL" />
        </g></g>
    )
}

export default ObjMus033
