import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#006AC9",
    "col1l": "#43A6FF",
    "col1n": "#0087FF"
}

function AccMas014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M196.1 19.3l.05-.05q.4-1.05.65-2.05.8-3.55-.65-7.1-2.5-4.9-9.2-4.15-26.45 1.75-50.15-4.7-.15-.05-.3-.05-15.45-3-29.05 1.55-4.45 1.65-8.9 4.1-4.45-2.45-8.9-4.1Q76.05-1.8 60.6 1.2q-.15 0-.3.05-23.7 6.45-50.15 4.7-6.7-.75-9.2 4.15Q-.5 13.65.3 17.2q.25 1 .65 2.05l.05.05q3.15 7.7.5 16.3Q0 39.35.55 43.2q.3 2.05 1.25 4.15l.1.2q.2.55.4 1.05.3.65.55 1.3 1.25 2.8 2.65 5.5.25.95.25 1.9 0 .35-.2 2.05-.05.8-.2 1.95 0 2.9 1.4 4.05t4.7 2.3q2 .7 2.25.8L53.6 78q1.75.5 3.45.85 15.8 3.5 30.9-.85 5-1.65 10.6-4.05 5.6 2.4 10.6 4.05 13.65 3.95 27.9 1.45 3.2-.55 6.45-1.45l39.9-9.55q.25-.1 2.25-.8 3.3-1.15 4.7-2.3 1.4-1.15 1.4-4.05-.15-1.15-.2-1.95-.2-1.7-.2-2.05 0-.95.25-1.9 1.4-2.7 2.65-5.5.25-.65.55-1.3.2-.5.4-1.05l.1-.2q.95-2.1 1.25-4.15.55-3.85-.95-7.6-2.65-8.6.5-16.3m-53.75 3.95q8.25 8.8 9 20.45.85 11.6-6.3 19.2-4.9 5.25-11.55 6.45-3 .55-6.3.3-1.3-.1-2.5-.3-9.35-1.55-16.55-9.3-7.3-7.8-8.8-17.85l-.75-2.5q-1.35 5.7-2.1 8.1-2.25 6.7-7.45 12.3-1.05 1.15-2.15 2.15-6.45 5.85-14.4 7.15-1.25.2-2.5.3h-.05q-3.3.25-6.3-.3-2.95-.55-5.55-1.85-3.25-1.7-5.95-4.6-7.2-7.6-6.35-19.2.75-11.65 9.05-20.45.45-.5.9-.95 7.85-7.95 18.15-8.7 10.75-.85 17.85 6.75 3.1 3.35 4.75 7.55.75 2.05 1.95 7l.4-.8.45-1.25q1.65-7.7 6.15-12.55 7.05-7.6 17.8-6.75h.05q10.85.8 19.05 9.65z"
                    id="AccMas014__Symbol_98_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M35.8 57.3q-10-3.05-13.9-4.25l-.05.05q-.45.05-6.2-1.5-5-1.4-6.55-1.4-.8.05-1.55.2-1.45.3-1.45.9 0 1.2.9 2.25.2.25.5.5Q68.35 70 78.3 63.4q-23.8-.4-42.5-6.1m-19-21.25q-5.1-1.4-5.8-2.25v-.4q6.35-.35 9.2-.7 5-.7 5.2-2.5-4.65-.45-10.6-.45H9q-3.45.05-8.5.05-.35.3-.45.75Q0 30.8 0 31.9q0 2.2 9.35 4.8 1.35.35 11.05 2.5h2q-.8-1.85-5.6-3.15M15.85 2.8q.65-.4.65-1.2 0-.7-1.8-1.2-1.2-.3-2.55-.35Q11.8 0 11.4 0q-3 0-5.5 1.2-2.2 1.05-2.2 1.9 0 1.5 1.25 2.15.55.3 1.65.75l.9.45q1.65.65 3.8.75h2.8q-.15-.95-1.45-1.4-1.25-.4-1.35-1.6 3.5-.8 4.55-1.4M181.1 54.05q.3-.25.5-.5.9-1.05.9-2.25 0-.6-1.45-.9-.75-.15-1.55-.2-1.55 0-6.55 1.4-5.75 1.55-6.2 1.5l-.05-.05q-3.9 1.2-13.9 4.25-18.7 5.7-42.5 6.1 9.95 6.6 70.8-9.35m-5.3-20.65v.4q-.7.85-5.8 2.25-4.8 1.3-5.6 3.15h2q9.7-2.15 11.05-2.5 9.35-2.6 9.35-4.8 0-1.1-.05-1.35-.1-.45-.45-.75-5.05 0-8.5-.05H172q-5.95 0-10.6.45.2 1.8 5.2 2.5 2.85.35 9.2.7m7.85-28.15q1.25-.65 1.25-2.15 0-.85-2.2-1.9-2.5-1.2-5.5-1.2-1.75 0-3.3.4-1.8.5-1.8 1.2 0 .8.65 1.2 1.05.6 4.55 1.4-.1 1.2-1.35 1.6-1.3.45-1.45 1.4h2.8q2.15-.1 3.8-.75L182 6q1.1-.45 1.65-.75z"
                    id="AccMas014__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M3.85 48.55q-.65-.7-1.3-1.7 1.25 2.8 2.65 5.5.25.95.25 1.9 0 .35-.2 2.05 1-1.15 2.7-1.25 1.95.15 2.7.15 1.2 0 1.2-.85 0-.9-2.05-2l-3.35-1.6q-1.5-.9-2.6-2.2M0 14.15q.25 1 .65 2.05l.05.05q3.15 7.7.5 16.3-1.5 3.75-.95 7.6.7-5.25 4-7.65.15-.1 2.9-1.6 1.3-.75 1.3-1.65 0-.75-1.3-1.5t-1.3-1.7q0-1.3 1.25-2 1.05-.55 2.55-.5Q9.3 22.9 4.2 19 1.25 16.7 0 14.15m140.75-7Q122.4-5.2 98.25 3.8q-24.15-9-42.5 3.35Q83-1.3 98.25 12.95q15.25-14.25 42.5-5.8m53.2 39.7q-.65 1-1.3 1.7-1.1 1.3-2.6 2.2l-3.35 1.6q-2.05 1.1-2.05 2 0 .85 1.2.85.75 0 2.7-.15 1.7.1 2.7 1.25-.2-1.7-.2-2.05 0-.95.25-1.9 1.4-2.7 2.65-5.5m2.55-32.7q-1.25 2.55-4.2 4.85-5.1 3.9-5.45 4.55 1.5-.05 2.55.5 1.25.7 1.25 2 0 .95-1.3 1.7t-1.3 1.5q0 .9 1.3 1.65 2.75 1.5 2.9 1.6 3.3 2.4 4 7.65.55-3.85-.95-7.6-2.65-8.6.5-16.3l.05-.05q.4-1.05.65-2.05z"
                    id="AccMas014__Symbol_60_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccMas014__Symbol_98_0_Layer0_0_FILL"
                transform="translate(93.95 123)"
                id="AccMas014__col1n"
            />
            <use
                xlinkHref="#AccMas014__Symbol_79_0_Layer0_0_FILL"
                transform="translate(98.2 133.8)"
                id="AccMas014__col1l"
            />
            <use
                xlinkHref="#AccMas014__Symbol_60_0_Layer0_0_FILL"
                transform="translate(94.25 126.05)"
                id="AccMas014__col1d"
            />
        </g></g>
    )
}

export default AccMas014
