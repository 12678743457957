import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2n": "#FF008C"
}
function HaiHig034({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M90.35 7.55q4.2.5 8.2 1.5 1 .05 2.05.1-3.7-1.25-8.35-3.35-4.65-2.1-12.7-3.75Q71.45.4 68.7.2 26.55-2.4 10.45 30.5v-1.7q0-10 7.85-20.3h-.2q-5.25 3.05-9.6 8.85-11.55 13.3-7.4 24.3.4 1.15 1 2.25l.2.4q5.5-.9 10.95-1.3 7.1-.55 14.1-.3 0-.1.05-.15.15-.6.3-1.15 1.35-.15 3-.4 2.8-.5 4.35-.5 8.45 0 14.4 3.7.5.3 3.55 2.5.85.6 1.55.9-.35.15-.65.35 2.8.95 5.5 2.15 16.25 7 29.75 20.5 28.05 28.05 28.05 67.75 0 3.6-.2 7.15-.3 4.15-.9 8.15-.45 2.8-1 5.5-5 23.95-22.1 42.9-1.8 2-3.7 3.9l-.15.15-1.45 1.45q-.9.85-1.75 1.65-1.1 1-2.2 1.95l-2.3 2q3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5.2.4.45.8.3.6.65 1.2.75 1.45 1.5 3l.65 1.25.3.7q.05.1.1.25.05.1.15.25l.3.7q.35.75.7 1.55l.8 1.9q.15.45.45 1.45v.05l.1.2q.7 1.75 1.4 3.55 7.75 17.75 24.55 23.5 11.1 3.8 26.15 2.35 2.2-.2 4.45-.5 1.8-.25 2.6-.4.4-.05.75-.1-5.2-1.05-11.9-5.6-1.75-1.2-3.2-2.05 20.95 7 40.9-9.25.2-.2.35-.3.45-.4 3.05-4.1-3.2 1.65-6.3 2.65-3.3 1.1-7.35 1.65-.85-.25-1.9-.45-7.15-1.2-9-1.75-3.5-1-5.25-2.8-1.65-2.3-2.9-3.6-.85-.9-7.7-6.85-5.25-4.55-6.55-7-3.25-6.1-5.45-13.7-1.9-6.6-2.25-11.8 2.7-12.5 6.6-23.8 2.1-6.05 7.15-17.95l.4-.85q.45-1.1.9-2.15l.55-1.25q.05-.2.15-.4 5.3-12.4 7.4-20.3 2.9-11.05 3.05-23.95.4-5.45.4-10.95 0-6.2-.15-8.7-.3-5-1.35-8.7-.4-1.45-1.65-4.4-1.25-3-1.6-4.2-.3-.95-.9-2-.75-2.45-1.45-4.1-.3-.75-.85-1.85-1.05-4.25-2-6.7-.85-1.5-1.6-2.9-.094-.094-.2-.15l.2.1q1.05.7 2.3 1.85 3 2.8 6.8 8.1 2.959 4.142 4.65 7.9.027.048.05.1 0-.036-.05-.1v-.25q-4.15-16.55-7.6-21.95-.15-.25-.25-.5-27.8-50.4-82.15-35.6-.1-.2.15-.45-2.1.35-3.9.75.7-.5 1.45-1 1.25-.85 3.5-2.65 2-1.6 3.45-2.5 1.95-1.2 6.05-2.1 1.9-.35 2.4-1.4 4.9.1 8.75.55z"
                    id="HaiHig034__Symbol_63_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M69.35 40.2q-6.6-8.5-12.3-13.35-5-4.3-11.8-7.7.2 0 .4-.25 1.75-.2 7.4-.2 12.95 0 17.3 4.5-6.95-10.25-15.3-15.35Q42.25 0 21.55 0 12.7 0 5.9 2.3 2.95 3.3 0 4.9q16.25 7 29.75 20.5Q57.8 53.45 57.8 93.15q0 3.6-.2 7.15-.3 4.15-.9 8.15-.45 2.8-1 5.5-5 23.95-22.1 42.9-1.8 2-3.7 3.9l-.15.15-1.45 1.45q-.9.85-1.75 1.65-1.1 1-2.2 1.95l-2.3 2q3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5.2.4.45.8.3.6.65 1.2.75 1.45 1.5 3l.65 1.25.3.7q.05.1.1.25.05.1.15.25l.3.7q.35.75.7 1.55l.8 1.9q.15.45.45 1.45v.05l.1.2q.7 1.75 1.4 3.55Q45 214.65 61.8 220.4q11.1 3.8 26.15 2.35-11.9-1.05-22.05-5.2-16.45-6.7-18.75-19.35 2.5 2.2 8.2 5.95 5.7 3.7 13.5 5.45-9.2-8.05-13.9-14.5-4.75-6.5-6.05-18.55v-4.75q1.5 4.4 7.75 12.1 6.2 7.7 15.55 10.95h.85q-8.45-8.2-11.4-13.75-4.25-8.05-4.25-20.55 0-11.5 2.95-22.55 1.9-7.2 6.5-18.4 4.95-12.1 6.5-17.35 2.95-9.85 2.95-19.3 0-3.4-1.05-9.7-.55-3.15-.9-4.95 0-.05-.05-.15-3.7-9.7-10.15-17.05 4.3 1.2 15.3 13.1.65.75 1.2 1.8-.45-1.55-1-3.7-1.75-6.6-4.85-12.7-3.4-6.8-5.45-9.4z"
                    id="HaiHig034__Symbol_65_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M158.5 74.4q-4.15-16.55-7.6-21.95-.15-.25-.25-.5-27.8-50.4-82.15-35.6-.1-.2.15-.45-6.55 4.8-11.95 11.95h.1q6.85-4.55 16.65-7.35 9.85-2.9 27.35-.65 17.5 2.2 31.35 15.8Q146 49.25 150.4 56.2q4.35 6.85 8.1 18.4.014.028 0 .05v-.25M100.6 9.15Q96.9 7.9 92.25 5.8q-4.65-2.1-12.7-3.75Q71.45.4 68.7.2 26.55-2.4 10.45 30.5v-1.7q0-10 7.85-20.3h-.2q-5.25 3.05-9.6 8.85-11.55 13.3-7.4 24.3.4 1.15 1 2.25l.2.4q5.5-.9 10.95-1.3 3.8-13.1 9.05-19.4l.05-.15q.1-.15.2-.25 4.35-6.3 10.3-10.5 13-9.25 34.45-9.6.95-.05 1.9-.05 7.7 0 12.85 1.1 1.05.2 18.55 5z"
                    id="HaiHig034__Symbol_66_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M107.35 71.7q7.3 6.75 12.35 19.3 5.15 12.85 5.15 24.45 0 2.45-.4 4.5-.45 2.05-.4 4.65-.7 3.25-1.6 6.7-.1-.3-.15-.5-.8-2.55-5.65-14.95l1.95 6.65q.75 10.95-.95 18.4-1.75 7.45-4.95 13.65-.1.2-6.15 14.3-6 16.25-6 27.65 0 18.9 8.1 29.5 6.5 8.5 16 9.65h-.2q-.5.5-1.9.6-.55.05-3.35.05-5.9-.2-8-.2 2.1 1.55 7.8 3.85 8.75 3.5 16.4 3.5 9.05 0 15.3-2.1 3.1-1 6.3-2.65.1-.1.15-.2-16 5.65-26.7-2.45h1.3q3.65-.1 6.6-2l3.1-2.05q-18.65 0-26.25-11.8-.8-1.35-1.5-2.85-1.1-2.45-1.85-5.2-5.65-21.05 2.65-42.05 2.25-6.7 6.25-17.05.8-2.15 1.6-4.1 10-27.2 9.65-50.75-.45-24.65-12.25-45.35-1.15-2-2.35-2.95.75 1.4 1.6 2.9 1.2 3.1 2.6 9.15.7 2.6 1.65 7.4.55 2.75 1.65 8.45.2 1.05.6 5.45.4 4.7.9 7.85.15 1 .25 3-2.4-5.35-5.6-9.55-4.35-5.65-13.7-12.85m-.95-24.5q-5.9-10-20.1-15.8-14-5.75-31.85-5.75-11.3 0-19.55 1.95 1.15-.65 2.45-1.3 4.25-2.25 6-2.8Q50.5 21.1 59 19.85q7.55-1.1 11.55-.7-5.5-1.4-6-1.45-.85-.1-8.15-.1-11.55 0-20.2 2.05-4.4 1.05-7.85 2.65.45-.7.95-1.3 5.45-7.2 12-12.05.1-.15.25-.3 1.4-1.25 7.35-4.25 6.45-3.25 22.3-2.3-4-1-8.2-1.5Q58.1 0 51.4 0q-5.1 0-8.85.4-2.1.2-3.75.5-.65.1-1.3.3-1.05.2-2.1.5Q6.35 8.55.05 35.6q-.05.05-.05.15.6.05 5 .45 4.45.4 8.45 1.3 4 .9 5.15 1.2 1.15.25 3.05.8 1.85.55 4.9 1.5 3.8-2.1 11.2-4.15Q45.1 34.8 48.6 34.1q3.5-.7 17.5-.6 13.95.1 21.25 2.8 7.3 2.65 19.05 10.9z"
                    id="HaiHig034__Symbol_64_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig034__Symbol_63_0_Layer0_0_FILL"
                transform="translate(171.05 15.45)"
                id="HaiHig034__col1n"
            />
            <use
                xlinkHref="#HaiHig034__Symbol_65_0_Layer0_0_FILL"
                transform="translate(230.45 60.65)"
                id="HaiHig034__col1d"
            />
            <use
                xlinkHref="#HaiHig034__Symbol_66_0_Layer0_0_FILL"
                transform="translate(171.05 15.45)"
                id="HaiHig034__col1l"
            />
            <use
                xlinkHref="#HaiHig034__Symbol_64_0_Layer0_0_FILL"
                transform="translate(198.4 22.4)"
                id="HaiHig034__col2n"
            />
        </g></g>
    )
}
export default HaiHig034
