import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.35 2.95V0L0 5v2.85l24.35-4.9M54.5 7.9V5.05L30.1.1V3l24.4 4.9z"
                    id="BeaMus003__Symbol_20_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus003__Symbol_20_0_Layer0_0_FILL"
                transform="translate(165.25 202.2)"
                id="BeaMus003__col1n"
            />
        </g></g>
    )
}

export default BeaMus003
