import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF5D5D",
    "col2n": "#FFFFFF"
}

function FacMou032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col2n}
                    d="M40.65 2.5L42 2.15 29.9 0H10.6L0 2.1l.65.4h-.1q2.15.85 4.85 1.65 1.9.45 3.75.95 3.25.8 6.4 1.1 2.2.25 4.2.25H21.45q2 0 4.2-.25 3.15-.3 6.4-1.1 1.85-.5 3.75-.95 2.7-.8 4.85-1.65z"
                    id="FacMou032__Symbol_117_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M47.1 5.3q-2.5.3-5.9-1.35-3.45-1.75-6.7-3.2-3.15-1.6-6.55.2-1.35.75-2.5 1.2-1.9.9-3.8 0-1.15-.45-2.5-1.2-3.4-1.8-6.55-.2-3.25 1.45-6.7 3.2Q2.5 5.6 0 5.3q3.7 4.4 5.35 7.05 3.35 5.5 7.05 8.2 1.3 1 2.7 1.65.75.3 1.65.65 1 .3 1.95.55 4.85 1.45 9.75 0 .95-.25 1.9-.55.9-.35 1.65-.65 1.4-.65 2.7-1.65 3.7-2.7 7.05-8.2Q43.4 9.7 47.1 5.3m-5.55 1.15q1.4.2 2.05.3l-8.6 4.4H9.75L3.5 6.75q.65-.1 2.05-.3 2.2-.35 5.85-.65 3.7-.5 4.45-.5.9-.2 4.35.7l3.3.65h.1L26.9 6q3.45-.9 4.35-.7.75 0 4.45.5 3.65.3 5.85.65z"
                    id="FacMou032__Symbol_57_0_Layer0_0_FILL"
                />
                <path
                    fill="#830000"
                    d="M212.55 216.35q-2.15.85-4.85 1.65-1.9.45-3.75.95-3.25.8-6.4 1.1-2.2.25-4.2.25H191.65q-2 0-4.2-.25-3.15-.3-6.4-1.1-1.85-.5-3.75-.95-2.7-.8-4.85-1.65 1.05 1.45 2.2 2.75 3.55 3.65 8.75 5.8 1.75.65 3.5 1.1 2.35.5 4.75.7H193.35q2.4-.2 4.75-.7 1.75-.45 3.5-1.1 5.2-2.15 8.75-5.8 1.15-1.3 2.2-2.75z"
                    id="FacMou032__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou032__Symbol_117_0_Layer0_0_FILL"
                transform="translate(171.9 213.85)"
                id="FacMou032__col2n"
            />
            <use
                xlinkHref="#FacMou032__Symbol_57_0_Layer0_0_FILL"
                transform="translate(168.95 209.6)"
                id="FacMou032__col1n"
            />
            <use xlinkHref="#FacMou032__Layer5_0_FILL" />
        </g></g>
    )
}

export default FacMou032
