import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#434343"
}

function BeaMus032({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M44.45 10.2q4.55 1.05 6.45 7.05.6 1.9.95 4.25.3 2.5.4 5.6.05.3.05.45v3.05q.1.85-.1 2.4l-.1.7q-.1.85-.25 1.6l-.5 4.05q3.25 10.3 5.7 7.15.8-1.6 1.25-4.75v-.2q-.05-2.05-.4-5.4-.3-3.35-.6-6.95-.1-1.9-.1-1.25L57 21.5q.25-2.25.15-4.25-.1-1.85-.5-3.5-.85-3.7-1.4-3.8-6-2.7-11.9-5.5-3.3-1.55-6.1-3.85l-.5-.1q-5.8-1.15-11.6.25Q14.4 3.4 8.2 8q-5.3 3.85-5.55 5.4-.05.1 0 .2v.05q-.05.15-.05.4v.15l-1.15 9.25q0-.15-.05-.3l-.3 5.2q.05-1.5-.1.85-.3 3.6-.6 6.95-.35 3.35-.4 5.4.2 2.1 1.3 3.3l.1.1q3.2 1.2 6.25-7L7.3 33.5q-.05-.3-.05-.55-.4-4.1-.15-11.45.1-2.05.2-4.3.2-.5.4-.95 4.55-10.5 18.6-9.5 9.65-.05 18.15 3.45z"
                    id="BeaMus032__Symbol_49_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BeaMus032__Symbol_49_0_Layer0_0_FILL"
                transform="translate(163.35 200.85)"
                id="BeaMus032__col1n"
            />
        </g></g>
    )
}

export default BeaMus032
