import { AssetSvgProps } from "../../../shared/assets"

function PlaInd034({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <g id="PlaInd034__Layer4_0_FILL">
                    <path fill="#E9DD00" d="M108.2 6.8v26h13v-26h-13z" />
                    <path
                        fill="#6B6B6B"
                        d="M385 177.1V76.3L266.4 62.2l3.45-8h14.95v-6.6H100.6v1.2q-14.918 2.7 0 5.4H117l-.1-1.2 1.35 9.55L0 76.65V177.1h385z"
                    />
                    <path
                        fill="gray"
                        d="M385 76.3V65L367.8 6.8H121.2v26h-13v-26H35.3L.4 67.25l-.4.05v9.35l118.25-14.1L116.9 53l.1 1.2h-16.4q-14.918-2.7 0-5.4v-1.2h184.2v6.6h-14.95l-3.45 8L385 76.3m-64-15.5v-.05l1.1.1h-.4q-.35-.05-.7-.05z"
                    />
                    <path
                        fill="#91A164"
                        d="M321 60.75v.05q.35 0 .7.05h.4l-1.1-.1z"
                    />
                </g>
                <g id="PlaInd034__Layer4_1_FILL">
                    <path
                        fill="#60AD79"
                        d="M37.5 63.4h-8.05q.122-.048.2-.1l-1.7-1.6L0 52.8v14.5l37.5-3.9M284.8 9.1q.332-.757.65-1.5h-52.1q.216-2.054.4-4H214.5q-.771 1.946-1.55 4-.271.743-.55 1.5l-.4-1.5q-.565-2.027-1.15-4h-106q-1.535 1.973-3.1 4l-1.15 1.5Q82.813 21.15 90.15 33.2q3.07 5 10.45 10h184.2V41q-2.26-3.732-3.3-7.8-2.73-10.854 3.3-24.1z"
                    />
                    <path
                        fill="#9BB3B6"
                        d="M37.5 9.1q-.052-.76-.1-1.5-.166-2.082-.35-4h-36Q.55 4.5 0 5.5v47.3l27.95 8.9 1.7 1.6q-.078.052-.2.1h8.05q10.992-15.903 10.3-30.2-.549-12.682-10.3-24.1z"
                    />
                    <path
                        fill="gray"
                        d="M90.15 33.2Q82.813 21.15 100.6 9.1l1.15-1.5H37.4q.048.74.1 1.5 9.751 11.418 10.3 24.1h42.35z"
                    />
                    <path
                        fill="#B4A278"
                        d="M323.5 9.1q-.027-.757-.05-1.5-.09-2.054-.2-4H287.3q-.918 1.946-1.85 4-.318.743-.65 1.5-6.03 13.246-3.3 24.1 1.04 4.068 3.3 7.8V56.9l36.2 3.8v.05l2.5.2v-.15q3.812-13.8 3.55-27.6-.221-12.05-3.55-24.1z"
                    />
                    <path
                        fill="#9C8B63"
                        d="M327.05 33.2h8.9q.47-11.66 4.85-24.1.367-.76.7-1.5h-18.05q.023.743.05 1.5 3.329 12.05 3.55 24.1z"
                    />
                    <path
                        fill="#E7F0CB"
                        d="M362.45 3.6h-9v57.3h2V64L385 67V3.6h-20.55v3.1q-.975-1.524-2-3.1z"
                    />
                </g>
                <g id="PlaInd034__Layer4_1_MEMBER_0_FILL">
                    <path
                        fill="#417752"
                        d="M18.65 46.35q-.191.007-.4.05-.669.154-1.1.45-.084.058-.15.1-.7.594-.7 1.6 0 .05.15.8.037.093.05.15-.652.148-1.1.7-.65.8-.4 2.2-1 .3-1.25.5-.45.35-.45 1.35 0 .35.15.75t.15.7q0 .2-.15.55-.15.4-.15.85 0 .3.3.9.3.6.3 1.1l-.75.75q-.15.2-.15.55 0 2.41 2.05 3h.05q.377.9 2.5.9 1.8 0 2.8-.7.071-.05.1-.1.064.136.1.3.5.9 2 .9 1.703 0 2.7-1.3h.2q.6.6 1.7.6 1.163 0 2.25-.6.168-.07.3-.15 1.15-.75 1.15-1.35 0-.771-.35-1-.007-.126-.05-.25.608-.178.9-.55.3-.4.3-1.2 0-1.537-1.55-2.2.45-.53.45-1.75 0-1.402-1.05-2.1.25-.433.25-1.15 0-1.798-1.5-2.85.12-.107.2-.25.124-.228.2-.5.4-.655.4-1.3 0-1.15-.6-1.9-.67-.67-1.1-.95.1-.216.1-.4 0-.6-.2-1.05-.385-2.344-3.1-1.75-.244.057-.5.1-.465.159-.9.3-1.44.67-1.75 1.75-.087.13-.2.25-1.927.056-2.2 1.3-.25.388-.25.85 0 .45.1.7.022.053.05.1.038.132.1.25z"
                    />
                    <path
                        fill="#2D583B"
                        d="M14.75 31.9q-1.238.595-3.05 1.6-.15-2.534-2.35-2.3-2.132.181-2.35 1.4-.07-.067-.2-.15-.49-1.099-2-1-2.3.2-2.3 2.15-.013.124-.05.25-.007.127-.05.25-.418-.413-.6-.55-.3-.25-.7-.25-.35 0-.65.3l-.45.45V63.4h.3q.015.154.05.35.35.85 1.3.85 1.041 0 2.05-.95.662.45 1.85.45 1.089 0 2.1-.7h7.4q-2.05-.59-2.05-3 0-.35.15-.55l.75-.75q0-.5-.3-1.1-.3-.6-.3-.9 0-.45.15-.85.15-.35.15-.55 0-.3-.15-.7-.15-.4-.15-.75 0-1 .45-1.35.25-.2 1.25-.5-.25-1.4.4-2.2.448-.552 1.1-.7-.013-.057-.05-.15-.15-.75-.15-.8 0-1.006.7-1.6.066-.042.15-.1.431-.296 1.1-.45.209-.043.4-.05-.062-.118-.1-.25-.028-.047-.05-.1-.1-.25-.1-.7 0-.462.25-.85.273-1.244 2.2-1.3.113-.12.2-.25.31-1.08 1.75-1.75.435-.141.9-.3.256-.043.5-.1v-.3q0-.832-1.5-1.45.292-.15.5-.45.3-.5.3-1.05 0-1.3-1.35-1.7-.09-.027-.2-.05-.3-.106-.75-.05-.174.016-.35.05.05-.209.05-.45 0-.8-.5-1.4-.55-.7-1.45-.7-.4 0-.75.2-.55.3-.6.3.2-1.25-.2-1.75-.35-.45-1.3-.45h-.55q-.366.186-.8.4z"
                    />
                    <path
                        fill="#585858"
                        d="M119.8 64.3v-1.9L0 76.65v2.1L119.8 64.3m165-15.5v-1.2H100.6v1.2h184.2M385 78.5v-2.2L265.2 62.05v1.9L385 78.5z"
                    />
                    <path
                        fill="#BFCAE5"
                        d="M284.8 41V9.1q0-2.9-.15-5.5h-46.1q-2.6 2.6-5.35 5.5V41h51.6z"
                    />
                    <path
                        fill="#B3CED2"
                        d="M100.6 9.1q-1.05-2.85-2.1-5.5H40.05Q38.8 6.25 37.5 9.1v54.3l63.1-6.45V9.1z"
                    />
                    <path
                        fill="#A19067"
                        d="M341.35 6.45q.15-1.1.35-2.15h-15.95q-1.1 2.65-2.25 5.5v51.15h31.95V6.45h-14.1z"
                    />
                </g>
                <g id="PlaInd034__Layer3_0_FILL">
                    <path
                        fill="#BDB300"
                        d="M200.55 87.3v1.75q-.025.069-.05.15v20.6h18.7V77.95q.916-.079 0-.1-1.688.022-9.6.4-8.2 1.6-9.05 9.05m-25.4-8.85q-5.036-.246-7.55-.35v31.7h16.6V87.5q-.85-7.45-9.05-9.05z"
                    />
                    <path
                        fill="#252525"
                        d="M385 385V146q-78.66.952-85.9-36.2-36.864 23.05-79.9 0h-51.6q-45.118 17.733-81.75 0Q49.935 138.396 0 146v239h385M86.7 276.25q10.42 7.238 0 18.1h-.6v.05q-5.8 7.7-11.2 0v-.05h-1.6q-11.405-9.05 0-18.1v-9.45h13.4v9.45m212.2 18.15v-.05h-.6q-12.046-9.05 0-18.1v-9.45h13.4v9.45q5.477 8.46 0 18.1h-1.6v.05q-4.571 4.114-11.2 0z"
                    />
                    <path
                        fill="#434343"
                        d="M298.9 294.35v.05q6.629 4.114 11.2 0v-.05h1.6q5.477-9.64 0-18.1-6.7-3.463-13.4 0-12.046 9.05 0 18.1h.6m-212.2 0q10.42-10.863 0-18.1-5.793-3.808-13.4 0-11.405 9.05 0 18.1h1.6v.05q5.4 7.7 11.2 0v-.05h.6z"
                    />
                    <path
                        fill="#F3E200"
                        d="M376.95 17.05L385 15.5V0H0v15.5l8.05 1.55v81.9l-8.05 2V146q49.935-7.604 85.85-36.2 36.632 17.733 81.75 0V78.1q2.514.104 7.55.35 8.2 1.6 9.05 9.05v22.3h16.3V89.2q.025-.081.05-.15V87.3q.85-7.45 9.05-9.05 7.912-.378 9.6-.4.916.021 0 .1v31.85q43.036 23.05 79.9 0 7.24 37.152 85.9 36.2v-45.05l-8.05-2v-81.9m-32.9 73.65V23.2L370 18.35V97.2l-25.95-6.5m-6.5-66.25V89.1L315 83.45v-54.8l22.55-4.2m-43.7 8.15l16.8-3.15V82.3l-16.8-4.15V32.6m-17.05 3.15q1.7-.35 3.4-.65l10.2-2.1v44.3l-13.6-3.4V35.75m-6-6.65v1.15l-1.15-1.15h1.15m0 3.7v.25l-.25-.25-.2-.2h.45v.2m-8.3 3.5v35.95h-62.65V36.3h62.65m-77.35 0v35.95H122.5V36.3h62.65m85.65 70.85h-1.35v-.2h1.35v.2m-166-72.05q1.7.3 3.4.65V73.9l-13.6 3.4V33l10.2 2.1m9.85-2.5l-.2.2-.25.25v-.45h.45m-23.5 0v45.55l-16.8 4.15V29.45l16.8 3.15m-43.7-8.15L70 28.65v54.8L47.45 89.1V24.45M15 18.35l25.95 4.85v67.5L15 97.2V18.35m100.55 88.8h-1.35v-.2h1.35v.2z"
                    />
                    <path
                        fill="#8C8400"
                        d="M114.2 33.05l.25-.25h-.25v.25m156.6 0v-.25h-.25l.25.25m0-2.8V29.1h-1.15l1.15 1.15z"
                    />
                </g>
                <g id="PlaInd034__Layer3_1_FILL">
                    <path
                        fill="#918900"
                        d="M134 10.65h-1.4q-17.14-.907-18.35 16.75v-.05.55q-.036.595-.05 1.2h.05l-.05 77.85h1.35V29.1q.5-6.65 2.8-10.75 4.2-7.7 14.45-6.55l1.2-1.15m117 0l1.2 1.15q10.25-1.15 14.45 6.55 2.3 4.1 2.8 10.75v77.85h1.35V29.1q-.45-19.4-18.4-18.45H251z"
                    />
                    <path
                        fill="#8C8400"
                        d="M252.2 11.8l-1.2-1.15H134l-1.2 1.15h119.4z"
                    />
                </g>
                <g id="PlaInd034__Layer2_0_FILL">
                    <path
                        fill="#5B4D4F"
                        d="M133.4 181.95h-.75q-66.325 6.555-132.65 0v23.5q70.952-9.285 130.65-.35 3.463-5.77 10.4-16.2-1.25-6.45-7.65-6.95m251.6 23.5v-23.5q-65.187 5.31-132.65 0h-.75q-6.4.5-7.65 6.95l11.6 16.05q65.99-9.273 129.45.5z"
                    />
                    <path
                        fill="#584B4D"
                        d="M155.95 162.35q-.65-3.6-3.5-4.9-1.25-.6-2.95-.7h-8.45v18.85H149q.482-.052.85-.1l6.1-13.15m83.75-5.6h-4.2q-1.7.1-2.95.7-2.85 1.3-3.5 4.9l7.3 13.25h7.6v-18.85h-4.25z"
                    />
                    <path
                        fill="#726164"
                        d="M42.95 119.4q1.802-1.173 18.6.45 16.836 1.632 49.9-3.25 35.785-5.002 29.6 31.25v8.9h8.45q1.7.1 2.95.7 2.85 1.3 3.5 4.9V133.25q0-.5.05-1v-1.2q0-.85-.05-1.6v-.2q4.74-29.34-25.25-24.75h-.4q-1.5-.05-3.1 0-32.025 3.248-64.05 0-1.7-.15-3.35 0H23.45Q5.072 103.941 0 116.2v.4l42.95 2.8M385 116.6v-.4q-5.071-12.259-23.45-11.7H325.2q-1.65-.15-3.35 0-31.747 2.775-64.05 0-1.6-.05-3.1 0h-.4q-30.21-4.082-25.25 24.75v.2q-.05.75-.05 1.6v1.2q.05.5.05 1V162.35q.65-3.6 3.5-4.9 1.25-.6 2.95-.7h8.45v-8.9q-5.93-36.717 29.6-31.25 31.825 2.502 63.65 0 1.9-.1 3.85 0H385z"
                    />
                    <path
                        fill="#7B686C"
                        d="M61.55 119.85q-16.798-1.623-18.6-.45L0 116.6v65.35q66.325 6.555 132.65 0h.75q6.4.5 7.65 6.95V147.85q6.185-36.252-29.6-31.25-33.064 4.882-49.9 3.25m279.5-3.25q-1.95-.1-3.85 0-31.825 2.502-63.65 0-35.53-5.467-29.6 31.25v41.05q1.25-6.45 7.65-6.95h.75q67.463 5.31 132.65 0V116.6h-43.95z"
                    />
                    <path
                        fill="#4A3E40"
                        d="M165.15 141.95q-.5-3-2.9-4.05-1-.5-2.4-.6h-3.9v15.6h3.45q.488-.05.85-.05l4.9-10.9m60-4.65q-1.4.1-2.4.6-2.4 1.05-2.9 4.05L223 153.2q.74-.491 2.6-.3h3.45v-15.6h-3.9z"
                    />
                    <path
                        fill="#43383A"
                        d="M166.3 118.75h-1.15v12h3.5q4.1-.45 4.45-3.45v-5q-.45-2.3-2.25-3.1-.8-.4-1.9-.45H166.3m49.75 0q-1.1.05-1.9.45-1.8.8-2.25 3.1v5q.35 3 4.45 3.45h3.5v-12h-3.8z"
                    />
                    <path
                        fill="#66585B"
                        d="M165.2 117v-.95q0-.7-.05-1.35v-.15q6.83-24.697-20.9-20.5h-.3q-1.25-.05-2.6 0-26.5 4.269-53 0-1.4-.1-2.75 0H55.5q-15.776-.47-19.75 10.45H59.8q1.65-.15 3.35 0 32.025 3.248 64.05 0 1.6-.05 3.1 0h.4q29.99-4.59 25.25 24.75v.2q.05.75.05 1.6v1.2q-.05.5-.05 1v4.05h3.9q1.4.1 2.4.6 2.4 1.05 2.9 4.05V117.85q0-.4.05-.85m160-12.5h24q-3.923-10.92-19.7-10.45h-30.1q-1.35-.1-2.75 0-24.626 2.248-53 0-1.35-.05-2.6 0h-.3q-25.532-4.099-20.9 20.5v.15q-.05.65-.05 1.35v.95q.05.45.05.85v24.1q.5-3 2.9-4.05 1-.5 2.4-.6h3.9v-4.05q0-.5-.05-1v-1.2q0-.85.05-1.6v-.2q-4.96-28.832 25.25-24.75h.4q1.5-.05 3.1 0 32.303 2.775 64.05 0 1.7-.15 3.35 0z"
                    />
                    <path
                        fill="#352D2E"
                        d="M174.5 105.6h-1.4v9.9h3.35q3.4-.35 3.65-2.85v-4.1q-.35-1.9-1.85-2.6-.65-.3-1.55-.35h-2.2m32.25.35q-1.5.7-1.85 2.6v4.1q.25 2.5 3.65 2.85h3.35v-9.9h-3.6q-.9.05-1.55.35z"
                    />
                    <path
                        fill="#5D4E51"
                        d="M173.1 101.35v-.15q.6-16.3-16.1-15.75h-.3q-.95-.05-1.95 0l-41.25 1.2q-.134-.268-.2-.45l-15.5-.75h-9.2q-12.545-.412-15.35 8.6H85.6q1.35-.1 2.75 0 26.5 4.269 53 0 1.35-.05 2.6 0h.3q27.73-4.197 20.9 20.5v.15q.05.65.05 1.35v.95q-.05.45-.05.85v.9h3.8q1.1.05 1.9.45 1.8.8 2.25 3.1v-18.55-2.3-.1m100.15-15.9q-1.1-.1-2.15 0-20.15 3.03-40.85 0-1-.05-1.95 0h-.3q-16.7-.55-16.1 15.75v21.1q.45-2.3 2.25-3.1.8-.4 1.9-.45H219.85v-.9q0-.4-.05-.85v-.95q0-.7.05-1.35v-.15q-4.632-24.599 20.9-20.5h.3q1.25-.05 2.6 0 28.374 2.248 53 0 1.4-.1 2.75 0h12.35q-2.805-9.012-15.35-8.6h-23.15z"
                    />
                    <path
                        fill="#4A3F41"
                        d="M166.65 78.15H165q-15.81 3.723-33.65 0-.85-.05-1.75 0-9.8 2.756-19.05 0-10.483-.303-12.75 7.3l15.5.75q.066.182.2.45l41.25-1.2q1-.05 1.95 0h.3q16.7-.55 16.1 15.75v4.4h3.6q.9.05 1.55.35 1.5.7 1.85 2.6v-15.3-1.15-.75-.1-.1q.5-13.4-13.25-13h-.2m104.45 7.3q1.05-.1 2.15 0h13.95q-2.267-7.603-12.75-7.3-8.875 1.734-19.05 0-.9-.05-1.75 0-16.513 1.874-33.65 0H218.15q-13.75-.4-13.25 13V108.55q.35-1.9 1.85-2.6.65-.3 1.55-.35h3.6v-1.85-2.3-.25q-.6-16.3 16.1-15.75h.3q.95-.05 1.95 0 20.7 3.03 40.85 0z"
                    />
                </g>
                <g id="PlaInd034__Layer1_0_FILL">
                    <path
                        fill="#352D2E"
                        d="M174.85 107.6H100.6q-2.65.2-3.3 2.5h-.05q0 .15-.05.3v2.25q.3 2.5 3.65 2.85h75.6q3.4-.35 3.65-2.85v-2.25q-.05-.15-.05-.3H180q-.45-1.55-1.75-2.15-.65-.3-1.55-.35h-1.85z"
                    />
                    <path
                        fill="#231D1E"
                        d="M176.7 105.6h-76.1q-2.9.2-3.4 2.95v1.85q.05-.15.05-.3h.05q.65-2.3 3.3-2.5H176.7q.9.05 1.55.35 1.3.6 1.75 2.15h.05q0 .15.05.3v-1.85q-.35-1.9-1.85-2.6-.65-.3-1.55-.35z"
                    />
                    <path
                        fill="#4A3F41"
                        d="M166.65 80.15h-35.3q-.85-.05-1.75 0h-19.05q-12.8-.4-13.35 11V91.85q-.05.65 0 1.4v15.3q.5-2.75 3.4-2.95H176.7q.9.05 1.55.35 1.5.7 1.85 2.6v-15.3-1.15-.75-.1-.1q-.55-11.4-13.25-11h-.2z"
                    />
                    <path
                        fill="#5B4E50"
                        d="M166.85 78.15h-35.5q-.85-.05-1.75 0h-19.05q-13.85-.4-13.35 13 .55-11.4 13.35-11h19.05q.9-.05 1.75 0h35.5q12.7-.4 13.25 11 .5-13.4-13.25-13z"
                    />
                </g>
                <g id="PlaInd034__Layer1_1_FILL">
                    <path
                        fill="#43383A"
                        d="M170.85 121.6q-.8-.35-1.9-.45h-92.4q-3.25.25-4 3.05h-.05q-.047.188-.05.3v2.8q.35 3 4.4 3.45h91.8q4.1-.45 4.45-3.45v-2.75q-.05-.15-.1-.35-.55-1.85-2.15-2.6z"
                    />
                    <path
                        fill="#382D2F"
                        d="M168.95 121.15q1.1.1 1.9.45 1.6.75 2.15 2.6.05.2.1.35v-2.25q-.45-2.3-2.25-3.1-.8-.4-1.9-.45h-92.4q-3.5.25-4.1 3.55v2.2q.003-.112.05-.3h.05q.75-2.8 4-3.05h92.4z"
                    />
                    <path
                        fill="#69585C"
                        d="M173.1 101.35v-.15q.6-16.3-16.1-15.75h-.3q-.95-.05-1.95 0H113.9q-1.05-.1-2.15 0H88.6q-16.75-.55-16.15 15.7v.3q.55-14.1 16.15-13.6h23.15q1.1-.1 2.15 0h40.85q1-.05 1.95 0h.3q15.5-.5 16.1 13.5z"
                    />
                    <path
                        fill="#5D4E51"
                        d="M173.1 101.45v-.1q-.6-14-16.1-13.5h-.3q-.95-.05-1.95 0H113.9q-1.05-.1-2.15 0H88.6q-15.6-.5-16.15 13.6v.6q-.1.8 0 1.7v18.55q.6-3.3 4.1-3.55h92.4q1.1.05 1.9.45 1.8.8 2.25 3.1v-18.55-2.3z"
                    />
                </g>
                <g id="PlaInd034__Layer1_2_FILL">
                    <path
                        fill="#4A3E40"
                        d="M165.05 144.4q-.7-2.4-2.8-3.35-1-.5-2.4-.6h-120q-4.25.35-5.2 3.95h-.05q-.05.25-.1.45v3.6q.45 3.9 5.75 4.45H159.4q5.35-.55 5.75-4.45v-3.7q-.005-.169-.05-.35h-.05z"
                    />
                    <path
                        fill="#403436"
                        d="M162.25 141.05q2.1.95 2.8 3.35h.05q.045.181.05.35v-2.8q-.5-3-2.9-4.05-1-.5-2.4-.6h-120q-4.55.35-5.35 4.65v2.9q.05-.2.1-.45h.05q.95-3.6 5.2-3.95H159.85q1.4.1 2.4.6z"
                    />
                    <path
                        fill="#746569"
                        d="M165.15 115.05q0 .5.05 1 0-.7-.05-1.35v-.15q.8-21.15-20.9-20.5h-.3q-1.25-.05-2.6 0h-53q-1.4-.1-2.75 0H55.5q-21.8-.65-21 20.45v.35q.7-18.25 21-17.65h30.1q1.35-.15 2.75 0h53q1.35-.05 2.6 0h.3q20.2-.6 20.9 17.65v.2z"
                    />
                    <path
                        fill="#66585B"
                        d="M165.15 117.85q0-.4.05-.85v-.95q-.05-.5-.05-1v-.2q-.7-18.25-20.9-17.65h-.3q-1.25-.05-2.6 0h-53q-1.4-.15-2.75 0H55.5q-20.3-.6-21 17.65v.75q-.1 1.1 0 2.25v24.1q.8-4.3 5.35-4.65H159.85q1.4.1 2.4.6 2.4 1.05 2.9 4.05v-24.1z"
                    />
                </g>
                <g id="PlaInd034__Layer1_3_FILL">
                    <path
                        fill="#584B4D"
                        d="M0 173.8q1.745 1.438 5.05 1.8H149q6.45-.7 6.95-5.4v-4.35l-.1-.5h-.05q-.85-2.95-3.35-4.1-1.25-.6-2.95-.7H4.55q-2.924.23-4.55 1.75v11.5z"
                    />
                    <path
                        fill="#483B3D"
                        d="M4.55 156.75q-2.913.212-4.55 1.75v3.8q1.626-1.52 4.55-1.75H149.5q1.7.1 2.95.7 2.5 1.15 3.35 4.1h.05l.1.5v-3.5q-.65-3.6-3.5-4.9-1.25-.6-2.95-.7H4.55z"
                    />
                    <path
                        fill="#7E6C70"
                        d="M59.8 108.3q1.65-.15 3.35 0h64.05q1.6-.05 3.1 0h.4q24.4-.75 25.25 21.35v.2q0 .6.05 1.2 0-.85-.05-1.6v-.2q.95-25.55-25.25-24.75h-.4q-1.5-.05-3.1 0H63.15q-1.7-.15-3.35 0H23.45Q5.072 103.941 0 116.2v3.8q5.07-12.263 23.45-11.7H59.8z"
                    />
                    <path
                        fill="#726164"
                        d="M63.15 108.3q-1.7-.15-3.35 0H23.45Q5.07 107.737 0 120v38.5q1.637-1.538 4.55-1.75H149.5q1.7.1 2.95.7 2.85 1.3 3.5 4.9v-29.1q0-.5.05-1v-1.2q-.05-.6-.05-1.2v-.2q-.85-22.1-25.25-21.35h-.4q-1.5-.05-3.1 0H63.15z"
                    />
                </g>
                <g id="PlaInd034__Layer1_4_FILL">
                    <path
                        fill="#5B4D4F"
                        d="M133.4 187.45H0v18h132.9q7.5-.9 8.15-6.75v-4.3q-1.25-6.45-7.65-6.95z"
                    />
                    <path
                        fill="#514345"
                        d="M0 181.95v5.5h133.4q6.4.5 7.65 6.95v-5.5q-1.25-6.45-7.65-6.95H0z"
                    />
                    <path
                        fill="#8C7B7E"
                        d="M43.95 121.4q1.95-.1 3.85 0h63.65q28.5-1 29.6 26.6v-.15q1.2-32.35-29.6-31.25H47.8q-1.9-.1-3.85 0H0v4.8h43.95z"
                    />
                    <path
                        fill="#7B686C"
                        d="M47.8 121.4q-1.9-.1-3.85 0H0v60.55h133.4q6.4.5 7.65 6.95V148q-1.1-27.6-29.6-26.6H47.8z"
                    />
                </g>
                <g id="PlaInd034__Layer1_5_FILL">
                    <path
                        fill="#66575B"
                        d="M127.35 236.05v-4.2q-1.55-8.35-9.95-8.95H0v21.95h116.75q9.75-1.15 10.6-8.8z"
                    />
                    <path
                        fill="#5B4D50"
                        d="M127.35 231.85v-8.6q-.55-2.95-1.95-4.95t-3.7-3q-1.85-.85-4.3-1H0v8.6h117.4q8.4.6 9.95 8.95z"
                    />
                    <path
                        fill="#8E757A"
                        d="M8.35 137.3q-2.55-.15-5.05 0H0v77h117.4q2.45.15 4.3 1 2.3 1 3.7 3 1.4 2 1.95 4.95V178.2q0-.3.05-.6v-.3-.1q.05-1.9 0-3.65 0-1-.05-1.9v-.1-.4-.15q-.1-1.8-.3-3.45-3.45-31.4-38.15-30.25H8.35z"
                    />
                    <path
                        fill="#9C8A8D"
                        d="M0 130.9v6.4h3.3q2.5-.15 5.05 0H88.9q34.7-1.15 38.15 30.25.2 1.65.3 3.45v.4q.1-2.2.05-4.25-.05-3.15-.35-6-3.45-31.4-38.15-30.25H8.35q-2.55-.15-5.05 0H0z"
                    />
                </g>
                <g id="PlaInd034__Layer1_6_FILL">
                    <path
                        fill="#796C6F"
                        d="M106.85 257.85v-1.1h-.25q-2-7.7-10.3-8.3H0v22.7h95.6q10.35-1.2 11.25-9.3v-3.9-.1z"
                    />
                    <path
                        fill="#6D5E62"
                        d="M94.05 238.8v-.05H0v9.7h96.3q8.3.6 10.3 8.3h.25v-8.55q-1.7-8.75-10.55-9.4h-2.25z"
                    />
                    <path
                        fill="#9F868B"
                        d="M106.9 190.45v-2.2h-.05q0 1.05.05 2.2M66.1 156.9H0v81.85h94.05v.05h2.25q8.85.65 10.55 9.4v-55.7h.05v-.75h-.15q-2.4-36.15-40.65-34.85z"
                    />
                    <path
                        fill="#AA9397"
                        d="M0 148.65v8.25h66.1q38.25-1.3 40.65 34.85h.15v-.5-.8q-.05-1.15-.05-2.2h.05v-.5q-.35-40.5-40.8-39.1H0z"
                    />
                </g>
                <g id="PlaInd034__Layer1_7_FILL">
                    <path
                        fill="#352D2E"
                        d="M206.75 107.95q-1.3.6-1.75 2.15h-.05q0 .15-.05.3v2.25q.25 2.5 3.65 2.85h75.6q3.35-.35 3.65-2.85v-2.25q-.05-.15-.05-.3h-.05q-.65-2.3-3.3-2.5H208.3q-.9.05-1.55.35z"
                    />
                    <path
                        fill="#231D1E"
                        d="M204.9 108.55v1.85q.05-.15.05-.3h.05q.45-1.55 1.75-2.15.65-.3 1.55-.35h76.1q2.65.2 3.3 2.5h.05q0 .15.05.3v-1.85q-.5-2.75-3.4-2.95H208.3q-.9.05-1.55.35-1.5.7-1.85 2.6z"
                    />
                    <path
                        fill="#4A3F41"
                        d="M274.45 80.15H255.4q-.9-.05-1.75 0h-35.5q-12.7-.4-13.25 11V108.55q.35-1.9 1.85-2.6.65-.3 1.55-.35h76.1q2.9.2 3.4 2.95v-15.3q.05-.75 0-1.4v-.1-.4-.1-.1q-.55-11.4-13.35-11z"
                    />
                    <path
                        fill="#5B4E50"
                        d="M255.4 78.15q-.9-.05-1.75 0h-35.5q-13.75-.4-13.25 13 .55-11.4 13.25-11h35.5q.85-.05 1.75 0h19.05q12.8-.4 13.35 11 .5-13.4-13.35-13H255.4z"
                    />
                </g>
                <g id="PlaInd034__Layer1_8_FILL">
                    <path
                        fill="#43383A"
                        d="M218.7 121.15H216.05q-1.1.1-1.9.45-1.6.75-2.15 2.6-.05.2-.1.35v2.75q.35 3 4.45 3.45h91.8q4.05-.45 4.4-3.45v-2.8q-.003-.112-.05-.3h-.05q-.75-2.8-4-3.05H218.7z"
                    />
                    <path
                        fill="#382D2F"
                        d="M216.05 118.75q-1.1.05-1.9.45-1.8.8-2.25 3.1v2.25q.05-.15.1-.35.55-1.85 2.15-2.6.8-.35 1.9-.45h92.4q3.25.25 4 3.05h.05q.047.188.05.3v-2.2q-.6-3.3-4.1-3.55h-92.4z"
                    />
                    <path
                        fill="#5D4E51"
                        d="M273.25 87.85q-1.1-.1-2.15 0h-40.85q-1-.05-1.95 0h-.3q-15.5-.5-16.1 13.5v20.95q.45-2.3 2.25-3.1.8-.4 1.9-.45h92.4q3.5.25 4.1 3.55v-18.55q.1-.9 0-1.7v-.15V101.45q-.55-14.1-16.15-13.6h-23.15z"
                    />
                    <path
                        fill="#69585C"
                        d="M273.25 85.45q-1.1-.1-2.15 0h-40.85q-1-.05-1.95 0h-.3q-16.7-.55-16.1 15.75v.15q.6-14 16.1-13.5h.3q.95-.05 1.95 0h40.85q1.05-.1 2.15 0h23.15q15.6-.5 16.15 13.6v-.3q.6-16.25-16.15-15.7h-23.15z"
                    />
                </g>
                <g id="PlaInd034__Layer1_9_FILL">
                    <path
                        fill="#403436"
                        d="M350.5 144.85v-2.9q-.8-4.3-5.35-4.65H225.15q-1.4.1-2.4.6-2.4 1.05-2.9 4.05v2.8q.005-.169.05-.35h.05q.7-2.4 2.8-3.35 1-.5 2.4-.6h120q4.25.35 5.2 3.95h.05q.05.25.1.45z"
                    />
                    <path
                        fill="#4A3E40"
                        d="M350.5 148.45v-3.6q-.05-.2-.1-.45h-.05q-.95-3.6-5.2-3.95H225.15q-1.4.1-2.4.6-2.1.95-2.8 3.35h-.05q-.045.181-.05.35v3.7q.4 3.9 5.75 4.45h119.15q5.3-.55 5.75-4.45z"
                    />
                    <path
                        fill="#746569"
                        d="M350.5 114.65v-.15q.8-21.1-21-20.45h-30.1q-1.35-.1-2.75 0h-53q-1.35-.05-2.6 0h-.3q-21.7-.65-20.9 20.5v.15q-.05.65-.05 1.35.05-.5.05-1v-.2q.7-18.25 20.9-17.65h.3q1.25-.05 2.6 0h53q1.4-.15 2.75 0h30.1q20.3-.6 21 17.65v-.2z"
                    />
                    <path
                        fill="#66585B"
                        d="M350.5 115v-.15q-.7-18.25-21-17.65h-30.1q-1.35-.15-2.75 0h-53q-1.35-.05-2.6 0h-.3q-20.2-.6-20.9 17.65v.2q0 .5-.05 1v.95q.05.45.05.85v24.1q.5-3 2.9-4.05 1-.5 2.4-.6h120q4.55.35 5.35 4.65v-24.1q.1-1.15 0-2.25v-.15-.45z"
                    />
                </g>
                <g id="PlaInd034__Layer1_10_FILL">
                    <path
                        fill="#584B4D"
                        d="M385 173.8v-11.5q-1.625-1.52-4.55-1.75H235.5q-1.7.1-2.95.7-2.5 1.15-3.35 4.1h-.05l-.1.5v4.35q.5 4.7 6.95 5.4h143.95q3.305-.362 5.05-1.8z"
                    />
                    <path
                        fill="#483B3D"
                        d="M385 162.3v-3.8q-1.637-1.538-4.55-1.75H235.5q-1.7.1-2.95.7-2.85 1.3-3.5 4.9v3.5l.1-.5h.05q.85-2.95 3.35-4.1 1.25-.6 2.95-.7h144.95q2.925.23 4.55 1.75z"
                    />
                    <path
                        fill="#726164"
                        d="M361.55 108.3H325.2q-1.65-.15-3.35 0H257.8q-1.6-.05-3.1 0h-.4q-24.4-.75-25.25 21.35v.2q0 .6-.05 1.2v1.2q.05.5.05 1v29.1q.65-3.6 3.5-4.9 1.25-.6 2.95-.7h144.95q2.913.212 4.55 1.75V120q-5.07-12.263-23.45-11.7z"
                    />
                    <path
                        fill="#7E6C70"
                        d="M385 120v-3.8q-5.071-12.259-23.45-11.7H325.2q-1.65-.15-3.35 0H257.8q-1.6-.05-3.1 0h-.4q-26.2-.8-25.25 24.75v.2q-.05.75-.05 1.6.05-.6.05-1.2v-.2q.85-22.1 25.25-21.35h.4q1.5-.05 3.1 0h64.05q1.7-.15 3.35 0h36.35q18.38-.563 23.45 11.7z"
                    />
                </g>
                <g id="PlaInd034__Layer1_11_FILL">
                    <path
                        fill="#5B4D4F"
                        d="M385 205.45v-18H251.6q-6.4.5-7.65 6.95v4.3q.65 5.85 8.15 6.75H385z"
                    />
                    <path
                        fill="#514345"
                        d="M385 187.45v-5.5H251.6q-6.4.5-7.65 6.95v5.5q1.25-6.45 7.65-6.95H385z"
                    />
                    <path
                        fill="#7B686C"
                        d="M341.05 121.4q-1.95-.1-3.85 0h-63.65q-28.5-1-29.6 26.6v40.9q1.25-6.45 7.65-6.95H385V121.4h-43.95z"
                    />
                    <path
                        fill="#8C7B7E"
                        d="M385 121.4v-4.8h-43.95q-1.95-.1-3.85 0h-63.65q-30.8-1.1-29.6 31.25v.15q1.1-27.6 29.6-26.6h63.65q1.9-.1 3.85 0H385z"
                    />
                </g>
                <g id="PlaInd034__Layer1_12_FILL">
                    <path
                        fill="#66575B"
                        d="M385 244.85V222.9H267.6q-8.4.6-9.95 8.95v4.2q.85 7.65 10.6 8.8H385z"
                    />
                    <path
                        fill="#5B4D50"
                        d="M385 222.9v-8.6H267.6q-2.45.15-4.3 1-2.3 1-3.7 3-1.4 2-1.95 4.95v8.6q1.55-8.35 9.95-8.95H385z"
                    />
                    <path
                        fill="#8E757A"
                        d="M381.7 137.3q-2.5-.15-5.05 0H296.1q-34.7-1.15-38.15 30.25-.2 1.65-.3 3.45v.65q-.05.9-.05 1.9-.05 1.75 0 3.65v.4q.05.3.05.6v45.05q.55-2.95 1.95-4.95t3.7-3q1.85-.85 4.3-1H385v-77h-3.3z"
                    />
                    <path
                        fill="#9C8A8D"
                        d="M381.7 130.9q-2.5-.15-5.05 0H296.1q-34.7-1.15-38.15 30.25-.3 2.85-.35 6-.05 2.05.05 4.25v-.25-.15q.1-1.8.3-3.45 3.45-31.4 38.15-30.25h80.55q2.55-.15 5.05 0h3.3v-6.4h-3.3z"
                    />
                </g>
                <g id="PlaInd034__Layer1_13_FILL">
                    <path
                        fill="#9F868B"
                        d="M278.1 188.25v2.2q.05-1.15.05-2.2h-.05m106.9 50.5V156.9h-66.1q-38.25-1.3-40.65 34.85h-.15v.75h.05v55.7q1.7-8.75 10.55-9.4h2.25v-.05H385z"
                    />
                    <path
                        fill="#6D5E62"
                        d="M385 248.45v-9.7h-94.05v.05h-2.25q-8.85.65-10.55 9.4v8.55h.25q2-7.7 10.3-8.3H385z"
                    />
                    <path
                        fill="#796C6F"
                        d="M385 271.15v-22.7h-96.3q-8.3.6-10.3 8.3h-.25v5.1q.9 8.1 11.25 9.3H385z"
                    />
                    <path
                        fill="#AA9397"
                        d="M385 156.9v-8.25h-66.1q-40.45-1.4-40.8 39.1v.5h.05q0 1.05-.05 2.2v1.3h.15q2.4-36.15 40.65-34.85H385z"
                    />
                </g>
                <path
                    fill="#353535"
                    d="M298.3 276.25h13.4v-9.45h-13.4v9.45z"
                    id="PlaInd034__Layer3_2_FILL"
                />
                <path
                    fill="#353535"
                    d="M86.7 276.25v-9.45H73.3v9.45h13.4z"
                    id="PlaInd034__Layer3_3_FILL"
                />
                <path
                    fill="#121212"
                    d="M85.85 109.8L0 146v158.1h132.25l51.95-194.3H85.85m.25 184.55v.05H74.9v-.05h-1.6V266.8h13.4v27.55h-.6M385 304.1V146l-85.9-36.2h-98.6l52.25 194.3H385m-86.7-9.75V266.8h13.4v27.55h-1.6v.05h-11.2v-.05h-.6z"
                    id="PlaInd034__Layer3_4_FILL"
                />
            </defs>
            <use xlinkHref="#PlaInd034__Layer4_0_FILL" />
            <use xlinkHref="#PlaInd034__Layer4_1_FILL" />
            <use xlinkHref="#PlaInd034__Layer4_1_MEMBER_0_FILL" />
            <use xlinkHref="#PlaInd034__Layer3_0_FILL" />
            <use xlinkHref="#PlaInd034__Layer3_1_FILL" />
            <use xlinkHref="#PlaInd034__Layer3_2_FILL" />
            <use xlinkHref="#PlaInd034__Layer3_3_FILL" />
            <use xlinkHref="#PlaInd034__Layer3_4_FILL" />
            <use xlinkHref="#PlaInd034__Layer2_0_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_0_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_1_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_2_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_3_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_4_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_5_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_6_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_7_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_8_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_9_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_10_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_11_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_12_FILL" />
            <use xlinkHref="#PlaInd034__Layer1_13_FILL" />
        </g></g>
    )
}

export default PlaInd034
