import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#6486A6",
    "col1n": "#8FAFCD"
}

function BodHan008({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M31.8 8.45q0-.1-.05-.2V8.2l-.2-.6q-.5-1.7-2.05-3.5-.05 0-.05-.05-2.2-2.4-5.9-3.45-6.6-1.7-13.15 1.5-.35.2-.8.45-2.55 1.8-4.5 3.9Q3 8.7 1.65 11.4 1.2 12.45.8 13.5q-1.95 6.25.85 12.55.4.95 1 2.2.7 1.7 1.5 2.65l.8.8q.6.6 2.1.95 1.45.3 2.65-.4h.15l1.05-.65q1.55.85 5.1-1.2-.1.1 1-.6 2.05 1.35 5.7-.55 0-.05.05-.05h.1l.1-.1.05.05v-.05l1.4-.7q1.7.8 3.7-.1 4.15-2.2 3.55-6v-.25q-.05-.3-.05-.4v-.05q-.85-4.2-.4-6.55.2-1.3.35-2.05.7-2.2.25-4.4v-.15m-10.4 20.2l.2-.05h.05l-.25.05m157-14.85q-.4-1.05-.85-2.1-1.35-2.7-3.45-4.95-1.95-2.1-4.5-3.9-.45-.25-.8-.45-6.55-3.2-13.15-1.5-5.35 1.5-7.5 5.85-.4.9-.75 2-.05.35-.05.7-.35 2.1.3 4.2.15.75.35 2.05.45 2.35-.4 6.55v.05q-.8 4 3.5 6.3 2 .9 3.7.1l1.4.7v.05l.05-.05.1.1h.1q.05 0 .05.05 3.65 1.9 5.7.55 1.1.7 1 .6 3.65 2.1 5.15 1.2l1.05.65h.1q1.2.7 2.65.4 1.5-.35 2.1-.95l.8-.8q.8-.95 1.5-2.65.6-1.25 1-2.2 2.8-6.3.85-12.55m-20.85 15.1h.05l.2.05-.25-.05z"
                    id="BodHan008__Symbol_17_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M12.75 14.15v.1q-.05.1-.05.2-.1.45-.15.8-.05 0-.05.05v.2q0 5.4 3.5 8.85.2.2.7.65l.05.05V25l1.4-.7q-.05-.05-.15-.1l-.05.05-.15-.15q-.25-.15-.4-.25-.6-.5-.8-.75-2.25-2.4-3.3-7.6-.1-.35-.15-.5-.1-.35-.15-.85-.05-.15-.05-.35-.15.1-.15.25-.05.05-.05.1m-12.4 3.7q-.05.05-.05.2-.05.1-.05.2-.15.45-.2.8-.05 0-.05.05v.2q0 4.2 2.15 7.2.1.15.25.35.1.1.2.25.25.35.6.65 0 .05.3.4h.1l1.05-.65-.1-.1q-.25-.25-.45-.5l-.05-.05q-.7-.8-1.25-1.8-1.25-2.2-1.95-5.75-.1-.35-.15-.5-.15-.5-.2-1.2-.15.1-.15.25M6.5 16.8q-.15-.5-.2-1.25-.15.1-.15.3-.05.05-.05.2-.05.1-.05.2-.1.35-.2 1.2.05 4.35 2.35 7.35.3.4.7.8 0 .05.1.1.1.15.75.6-.1.1 1-.6-.1-.05-.15-.1-.1-.1-.15-.1l-.05-.05v-.05q-.25-.25-.5-.55l-.05-.05q-2.15-2.35-3.2-7.5-.1-.35-.15-.5M25.55 4.5v-.15q0-.1-.05-.2V4.1l-.2-.6q-.5-1.7-2.05-3.5 2.45 4.35 1.25 8.15l-.1.2q-.2.3-.35.6-1.3 3.5 0 6.6.1.2.2.45.95 2.2 1 2.35.05.15.15.25v-.4-.25q-.05-.3-.05-.4v-.05q-.85-4.2-.4-6.55.2-1.3.35-2.05.7-2.2.25-4.4M160.8 17.65q-.1-.85-.2-1.2 0-.1-.05-.2 0-.15-.05-.2 0-.2-.15-.3-.05.75-.2 1.25-.05.15-.15.5-1.05 5.15-3.2 7.5l-.05.05q-.25.3-.5.55v.05l-.05.05q-.05 0-.15.1-.05.05-.15.1 1.1.7 1 .6.65-.45.75-.6.1-.05.1-.1.4-.4.7-.8 2.3-3 2.35-7.35m-10.75 5.65q-.2.25-.8.75-.15.1-.4.25l-.15.15-.05-.05q-.1.05-.15.1l1.4.7v.05l.05-.05q.5-.45.7-.65 3.5-3.45 3.5-8.85v-.05-.15q0-.05-.05-.05-.05-.35-.15-.8 0-.1-.05-.2v-.1q0-.05-.05-.1 0-.15-.15-.25 0 .2-.05.35-.05.5-.15.85-.05.15-.15.5-1.05 5.2-3.3 7.6m-7.4-13.7q-.15-.3-.35-.6l-.1-.2Q141 4.95 143.5.6q-1.6 1.8-2.1 3.55-.2.6-.3 1.2-.35 2.1.3 4.2.15.75.35 2.05.45 2.35-.4 6.55v.05q0 .1-.05.4v.65q.1-.1.15-.25.05-.15 1-2.35.1-.25.2-.45 1.3-3.1 0-6.6m23.75 8.85q0-.1-.05-.2 0-.15-.05-.2 0-.15-.15-.25-.05.7-.2 1.2-.05.15-.15.5-.7 3.55-1.95 5.75-.55 1-1.25 1.8l-.05.05q-.2.25-.45.5l-.1.1.1.1h.05l.9.55h.1q.3-.35.3-.4.35-.3.6-.65.1-.15.2-.25.15-.2.25-.35 2.15-3 2.15-7.2v-.05-.15q0-.05-.05-.05-.05-.35-.2-.8z"
                    id="BodHan008__Symbol_18_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#BodHan008__Symbol_17_0_Layer0_0_FILL"
                transform="translate(103.25 280.3)"
                id="BodHan008__col1n"
            />
            <use
                xlinkHref="#BodHan008__Symbol_18_0_Layer0_0_FILL"
                transform="translate(109.5 284.4)"
                id="BodHan008__col1d"
            />
        </g></g>
    )
}

export default BodHan008
