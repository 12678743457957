import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#252525",
    "col1s": "#000000",
    "col2n": "#FFFF00"
}

function ShiSpe065({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#806D59"
                    d="M200.5 249.25l-1.1.05q-3.107.186-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-3.632-.271-7.15-.8v18.45h30.25l-.05-18.45q-2.958.466-6 .7-.524.06-1.05.1z"
                    id="ShiSpe065__Layer10_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M162.7 44.9q-.25-1.1-.5-2.15-.1-.25-.15-.55l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.15-.45-.3-.95-.3-.75-.55-1.5-.9-2.4-1.85-4.75-.05-.1-.1-.15-.9-2.2-1.9-4.35-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.9-1.2-1.75-2.35l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75-.3.2-.55.45L112.65 20.1q-9.05 5.35-25.25 10l-4.8 5.7-7.05-7.05Q58.5 25.3 52.7 20.3L36.3 9.45q-.1-.05-.15-.1-3.85-2.15-7.5-4.7L27.1 3.5q-1.45-1.05-2.8-2.1L22.45 0q-.1.05-.15.15-3.05 4.1-5.7 8.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-1 2.15-1.9 4.35-1 2.4-1.9 4.9-.3.75-.55 1.55-.2.4-.35.85-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6-.05.25-.15.55-.3 1.05-.55 2.15l-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.2.7-.3 1.45Q1 50.8.8 51.8v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l-2.8 11.75q-.35 1.45-.7 2.85-.2.7-.35 1.35l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 29.75 6.75 59.3 7h1.5l1.2-.05V89q3.8 0 7.6-.1 26.65-.8 53.1-6.9 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.55-2.45q0-.05.05-.05 0-.05.05-.05 1.6-1.8 3.7-2.85.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.2q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.05-.45-2.1v-.1q-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe065__Symbol_339_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M4.45 26q-.2.1-.25.3L0 47.75v.2q0 .1.1.15v.05q.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1m110.95.5v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45q-.05-.2-.25-.3-.15-.15-.35-.1l-.2.1q-.1.05-.15.15-.1.15-.05.35m-10.55-25q.8-.75 1.7-1.45-10 5.55-21.1 8.45-4.95 1.3-10.05 2.05-6.45 4-10.85 9.35-1.65 2-3 4.2-.6.85-1.05 1.8-.05 0-.1.05-.1-.05-.2-.05h-.05q-.2-.1-.35-.4h-.05v-.05q-.048-.048-.05-.1v.05q-.3-.65-.7-1.25v-.05q-.15-.2-.25-.45l-.1-.15q-.2-.25-.35-.5-1.05-1.6-2.3-3.1-4.4-5.4-10.85-9.4-.25-.05-.5-.05-4.8-.75-9.4-1.95Q24.6 5.75 14.95.55q-.5-.3-1-.55.9.75 1.75 1.5 7.75 6.9 10.95 13.15 1.7 3.25 2.15 6.3 14.85-7.75 29.5 4.2.25.15.45.35.15.1.25.2v.05q.35.3.7.65v53.25h1.2V26.2q.3-.25.65-.5 15-12.7 30.2-4.75.45-3.05 2.15-6.3Q97.1 8.4 104.85 1.5z"
                    id="ShiSpe065__Symbol_340_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M19.5 48.8l-1.9-.3h-.15q-.2.05-.35.1-.2.7-.35 1.35l-.9 3.6-.1.25.4.2h.05l1.5.3q30.75 6.05 61.55 6h1.4v-6.9h-1.4q-30.1-.05-59.75-4.6m5.8-37.2l1.2-5.3L8 0Q7 2.4 6.1 4.9q-.3.75-.55 1.55l19.15 5.6V12q.3.15.5.1.05-.1.15-.15 0-.15-.05-.35m-2.05 10.5l-2.55-1.45Q14.65 16.8 5.8 18q-1.5.35-3.25.95-.45.15-.95.35-.3 1.05-.55 2.15l-.1.5H1q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.2.7-.3 1.45.25-.15.5-.25 9.2-4.05 18.2.55.3.1.55.25.35.15.65.35.25.15.45.3.15.05.3.15.15.1 1.3.85l1.3-6.5m56.7-7.4h-.7q-26.6-.1-53.1-2.1L24.9 19q27.85 3.5 54.35 3.65h1.4V14.7h-.7m64.9 33.8l-1.9.3q-30 4.6-60.45 4.6h-.65v6.9h.65q31.15.1 62.25-6l1.5-.3h.05q.2-.15.35-.3l-1.25-5.15q-.2 0-.4-.05h-.15m-7.2-29.5l-1.25-6.4q-26.95 2.05-53.9 2.1h-.65v7.95h.65q26.85-.1 55.15-3.65m18.8-14.1q-.9-2.4-1.85-4.75-.05-.1-.1-.15l-18.55 6.3 1.2 5.3q0 .25.05.35.05.1.1.15h.1l-.05.05v.05L157 6.4q-.3-.75-.55-1.5m6.15 21.55v-.1q-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.25-1.1-.5-2.15-.55-.15-1.05-.35-1.75-.6-3.25-.95-8.85-1.2-14.9 2.65l-2 1.2q-.25.2-.4.5l1.2 6.15q1.05-.65 1.25-.75.15-.1.3-.15.2-.15.45-.3.3-.2.65-.35.25-.15.55-.25 9-4.6 18.2-.55.3.15.65.3z"
                    id="ShiSpe065__Symbol_341_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ShiSpe065__Layer10_0_FILL" />
            <use
                xlinkHref="#ShiSpe065__Symbol_339_0_Layer0_0_FILL"
                transform="translate(110 228.6)"
                id="ShiSpe065__col1n"
            />
            <use
                xlinkHref="#ShiSpe065__Symbol_340_0_Layer0_0_FILL"
                transform="translate(132.2 237.95)"
                id="ShiSpe065__col1s"
            />
            <use
                xlinkHref="#ShiSpe065__Symbol_341_0_Layer0_0_FILL"
                transform="translate(111.25 252.05)"
                id="ShiSpe065__col2n"
            />
        </g></g>
    )
}

export default ShiSpe065
