import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#0087FF",
    "col2l": "#FF5D5D",
    "col2n": "#FF0000"
}

function ShiSpe121({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M162.7 45.1q-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-.55-1.2-1.05-2.4-.05-.15-.1-.25h.2q2.05-1.15 3.65-1.95-.1-.1-.15-.2-.5-.7-1.05-1.5 0-.05-.05-.05Q147.1 2.4 142.8.1l-.1-.1-1.85 1.4-.4.3q-1.15.9-2.4 1.8l-1.55 1.15Q132.35 7.5 128 9.9q-.65.35-1.35.7v.1q-9.05 4.75-19 7.35-4.5 1.15-9.1 1.9-4.6 5.4-8.1 8.75-4.6 4.35-7.2 5.2-.1-.05-.15 0H82.4q-4.4-.25-16.3-14.05-4.45-.7-8.65-1.8Q47.5 15.5 38.5 10.8q-.7-.35-1.35-.7-4.35-2.4-8.5-5.25L27.1 3.7q-1.25-.9-2.4-1.8l-.4-.3L22.45.2l-.1.1q-4.3 2.3-14.5 17.35-.05 0-.05.05-.55.8-1.05 1.5-.25.35-.45.7 1.7.6 3.95 1.45-1.55 3.5-2.9 7.2l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4Q1.15 50.3.8 52v.2l-.2.9q-.1.45-.15.9-.2 1-.4 2.05-.05.2-.05.4v.1q1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.15 3.75 2.95l-2.8 11.75q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H16.95q2.05.5 4.15.95 22.2 5.05 44.3 6.45 8.55.55 17.1.55 3.35 0 6.7-.05 27.4-.7 54.6-6.95 2.05-.45 4.1-.95h.2q.25-.05.5-.15h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.55-2.45q1.65-1.85 3.8-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05V56.4q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5z"
                    id="ShiSpe121__Symbol_148_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M42.4 20.9q3.15-8.75 0-17.5l-.05-.15-.2-.3q-.3-.5-.7-1-.2-.3-.45-.55-2.65-2.55-5.3-.45-.1 0-.15.1l-5.5 4.5q-.05-.15-.15-.3-.55-1.05-1.35-2l-.35-.35Q25.65.35 22.05.35q-3.6 0-6.2 2.55-.3.35-.55.7-.8.9-1.25 2-.05.05-.05.1-.05 0-.05.05l-5.7-4.7q-.05-.1-.1-.1-2.7-2.1-5.35.45-.2.2-.35.4l-.1.15q-.3.35-.55.75-3.6 9.6 0 19.2 2.45 3.1 5.55.75H7.2q.1-.05.15-.1.55-.45 1.15-1.05l6.45-7.05q.05.1.2.3l.1.1.05.05h-.05l.15.15h.05q.1.1.25.3l.1.1.2.2q2.15 2.05 5.05 2.3.45.05.9.05 1.85 0 3.4-.65l.95-.5.45-.3q.75-.5 1.45-1.2.4-.4.75-.85l6.4 7q1.15 1.25 2.3 1.75.05 0 .1.05h-.1q2.4 1.1 4.4-1.5.2-.45.35-.9z"
                    id="ShiSpe121__Symbol_149_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M10.7 6.3v-.1q-1.05-.5-3.3-3.55Q5.15-.35 2.35.9-.5 2.15.1 3.55.7 4.9 4.95 5.6q4.25.75 4.65 1.65 1.9 1.7-1.75 4.2l-5.65 1.8q0 .05-.5.75-.5.65-.7 2.6-.25 1.95 1.1 2.85 1.3.8 2.5.35.1-.05.15-.1.55-.45 1.15-1.05l6.45-7.05q.05.1.2.3v-.15Q10.7 9.4 10.7 6.3m14.15.5q-2.25-.65-3.95 2.9-.65 1.4-2.9 1.75-2 .3-2 1.2 0 1.65 2.15 1.85 2.1.2 3.3-.2 1.15-.4 2.75-2.15 1.55-1.8 1.55-3.6t-.9-1.75m14-3.05Q37.95 1.45 37 .4q-1-1.1-3.05.7-3.7 2.6-5.95 3.3.2.9.2 1.9 0 3.05-1.85 5.35l6.4 7q1.15 1.25 2.3 1.75.05 0 .1.05.75-.3 1.55-.95 1.55-1.25 1.55-2.6 0-2.15-1.3-3.5-.55-.55-1.2-.95-.95-.1-3-.5-3.85-.75-3.95-3.5-.15-2.8 5.4-2.55 5.55.2 4.65-2.15z"
                    id="ShiSpe121__Symbol_150_0_Layer0_0_FILL"
                />
                <g id="ShiSpe121__Layer11_0_FILL">
                    <path
                        fill="#FF0"
                        d="M250.75 275.25l.65 3.45q10.769-8.39 22.1-1.7-.137-.687-.3-1.4-.07-.3-.15-.6-.166-.751-.35-1.5-11.664-6.376-21.95 1.75m8.7-29.75q-.4-.05-.8-.05-12.1-.3-22 1.2-7.15 1.1-13.1 3.15-15.723 4.437-23.15 15.25.108.124.15.25l5.5-4.5q.05-.1.15-.1 2.106-1.67 4.2-.4 16.324-11.877 48.25-11.05 2.25.05 4.45.2h.75q.2 0 .35.05H264.65q.113.033.2.05h.05q3.1.2 5.2.3-.75-1.05-1.7-2.45-.5-.7-1.05-1.5 0-.05-.05-.05l-5.25-.25q-.2-.05-.45-.05h-.55q-.75-.05-1.55-.05h-.05m-147.15 28q-.066.254-.15.5h.05q-.096.499-.25 1-.032.3-.1.6-.162.698-.35 1.4 11.359-6.67 22.05 1.7l.7-3.45q-10.283-8.127-21.95-1.75m13.35-27.85q-.78.042-1.55.05-.275.036-.55.05-.238.011-.5 0l-5.25.25q.004.057-.05.1-.515.793-1 1.5-.955 1.39-1.7 2.45 2.11-.113 5.15-.35h.05q.293.016.55 0 .07-.014.15-.05h1.1q2.2-.15 4.45-.2 31.625-.82 47.9 10.8 2.123-1.204 4.25.45.05 0 .1.1l5.7 4.7q0-.05.05-.05 0-.05.05-.1l.1-.25q-7.41-10.686-23.05-15.1-5.962-2.052-13.1-3.15-9.875-1.514-22-1.2-.4.008-.8 0h-.05z"
                    />
                    <path d="M247.6 264.45v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-1.4-7-.65-3.45-2.15-11q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35m-110.6-.6q-.2-.05-.35.1-.2.1-.25.3l-2.15 11-.7 3.45-1.35 7q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4v-.05q.05-.2-.05-.35-.15-.2-.35-.25z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiSpe121__Symbol_148_0_Layer0_0_FILL"
                transform="translate(110 228.4)"
                id="ShiSpe121__col1n"
            />
            <use
                xlinkHref="#ShiSpe121__Symbol_149_0_Layer0_0_FILL"
                transform="translate(170.5 259.75)"
                id="ShiSpe121__col2n"
            />
            <use
                xlinkHref="#ShiSpe121__Symbol_150_0_Layer0_0_FILL"
                transform="translate(173.1 262.6)"
                id="ShiSpe121__col2l"
            />
            <use xlinkHref="#ShiSpe121__Layer11_0_FILL" />
        </g></g>
    )
}

export default ShiSpe121
