import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#FFFFFF",
    "col1n": "#EEEEEE",
    "col2d": "#747474",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}

function AccHat103({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M1.5 43.15l1.25 4.6Q6.55 60.9 14.5 70.3 33.25 91.85 60 92.45v-.05q3.2-6.15 4.95-9.55 8.5-10.5-2.5-25.8Q26.9 53.7 5.45 0H5.4q-.4.5-.75 1.05-7.2 23.35-3.3 41.35.05.35.15.75m61.55 41.8q-1.05 1.6-.6.55.3-.25.6-.55m232.8-42.5q3.9-18-3.3-41.35-.1-.2-.2-.35-.3-.35-.55-.7h-.05q-.15.35-.25.7-.25.5-.45 1.05-21.35 52-56.3 55.3-12.25 22.25-6.15 27.15-.95 0 1.05 1.1 7 5.2 7.55 7.1v.05h.3q26.55-.7 45.2-22.15 5-5.9 8.35-13.3 2-4.35 3.4-9.25l1.25-4.6q.1-.4.15-.75z"
                    id="AccHat103__Symbol_449_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M58.55 77.8q19.05-5.8 0-12.3Q13.45 65.05.65 0-1.2 18.3 2.1 32.6q5.75 24.9 27.05 37.7 15.4 8.15 29.4 7.5M287.3 32.65q3.3-14.3 1.45-32.6-.75 3.8-1.6 7.35-13.85 57.75-56.3 58.15-28.35 9.55 0 12.3h.05q13.95.65 29.35-7.5 21.05-12.65 26.9-37.05.1-.35.15-.65z"
                    id="AccHat103__Symbol_429_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M183.5 43.75h-18.85l-.3-.25q-.5-.55-1.05-1.05-21.5-21.45-50-25.9l-.35-.05v-7h-6.65L97.65 0 89 9.5h-6.75v7h-.05l-.2.05Q53.5 21 32 42.45q-.55.5-1.05 1.05l-.35.35H11.7V71.9l-.2 1.05q-.6 1.45-1.15 2.95 0 .1-.05.2-.7 1.85-1.25 3.75v.05Q4.5 80.65 0 81.5v21.75q2.5-.3 50.3-5 23.7-2.3 47.2-2.4h.3q23.5.1 47.2 2.4 47.8 4.7 50.3 5V81.5q-4.35-.85-8.75-1.55h-.3v-.05q-.55-1.9-1.25-3.75v-.05q-.05-.1-.05-.15-.55-1.5-1.15-2.95v-.2l-.3-.7V43.75z"
                    id="AccHat103__Symbol_346_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M13 12.3q0-2.7-1.9-4.6-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9Q0 9.6 0 12.3q0 2.7 1.9 4.6 1.9 1.9 4.6 1.9 2.7 0 4.6-1.9Q13 15 13 12.3m41.85-9.15q-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9-1.9 1.95-1.9 4.65t1.9 4.6q1.9 1.9 4.6 1.9 2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.65m40.8 3.4q0-2.7-1.9-4.65-1.9-1.9-4.6-1.9-2.2 0-3.9 1.3-.35.25-.7.6-1.9 1.95-1.9 4.65t1.9 4.6Q86.2 12.8 88.4 13q.35.05.75.05 2.7 0 4.6-1.9 1.9-1.9 1.9-4.6m33.4 7.75q2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.65-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9-1.9 1.95-1.9 4.65t1.9 4.6q1.9 1.9 4.6 1.9m39.15-6.6q-1.9 1.9-1.9 4.6 0 2.7 1.9 4.6 1.9 1.9 4.6 1.9 2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.6-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9z"
                    id="AccHat103__Symbol_337_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M21.9 34H2.45v29.45Q8.95 47.35 21.9 34M89 5.95q7.5-.05 14.55 1l.7.1V0h-31.3v7.05l.7-.1q7.05-1.05 14.55-1h.8m85.75 57.5V34H155.3q12.95 13.35 19.45 29.45m0 6.5q1.2.2 2.35.4.05 0 .1.05-.55-1.9-1.25-3.75v-.05q-.1-.05-.2-.05-.5-.1-1-.15-18.3-2.95-36.5-4.65-3-.3-6-.5-21.8-1.8-43.5-1.75h-.3q-21.7-.05-43.5 1.75-3 .2-6 .5-18.2 1.7-36.5 4.65-.5.05-1 .15-.1 0-.2.05-.7 1.85-1.25 3.75v.05q.002-.05.1-.05 1.15-.2 2.35-.4 18.3-3.05 36.5-4.8 3-.3 6-.55 21.8-1.85 43.5-1.85h.3q21.7 0 43.5 1.85 3 .25 6 .55 18.2 1.75 36.5 4.8z"
                    id="AccHat103__Symbol_323_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat103__Symbol_449_0_Layer0_0_FILL"
                transform="translate(44.4 19.25)"
                id="AccHat103__col1n"
            />
            <use
                xlinkHref="#AccHat103__Symbol_429_0_Layer0_0_FILL"
                transform="translate(48.3 26.95)"
                id="AccHat103__col1l"
            />
            <use
                xlinkHref="#AccHat103__Symbol_346_0_Layer0_0_FILL"
                transform="translate(95.35 31.8)"
                id="AccHat103__col2n"
            />
            <use
                xlinkHref="#AccHat103__Symbol_337_0_Layer0_0_FILL"
                transform="translate(103.35 109.05)"
                id="AccHat103__col2l"
            />
            <use
                xlinkHref="#AccHat103__Symbol_323_0_Layer0_0_FILL"
                transform="translate(104.4 41.3)"
                id="AccHat103__col2d"
            />
        </g></g>
    )
}

export default AccHat103
