import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col1s": "#121212"
}

function HaiSpe007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M129.05 21.95q-.2-.05-.4-.05h-.15q-.05-.1-.05-.2-3.65-6.95-16.4-11.75-9.45-3.9-17.7-3.3-.6-.1-1.3-.25l.3.2-.55-.35-.05-.05h-.1q-3.05-2.1-4.9-2.8Q76.35-1.05 63.7.35 50.15 1.5 43.1 9.15q-.05 0-.05.05H42.9q-11.05-1.5-17.75 3.7-5.35 3.4-9.1 9.35-3.5.25-5.65 1.05Q3.1 26.35.15 34.75q-.2.6-.15 1.2 0 .9.5 1.65.05.1.1.15.6.85 1.75 1.5 1.9 1 5.4 1.55 2.65-.1 6.5-.4 2.65.05 5 .25.55.05 1.05.1 7.45.65 11.45 2.65.45.25 1.2.6 3.4 1.7 12.45 6.2 3.25 1.45 6.25 3.1 20.45 11.2 41.1-.6 1.85-1.4 4.85-3.05 2.85-1.6 8.65-4.75 3.3-2.05 5.45-2.6 7-2.1 12.85-2.75.95-.1 2.85-.2 3.15-.25 8.85-.5 4.25 0 6.25-.8 1.05-.45 1.5-1.15.1-.15.15-.35.15-.5.1-1.05-1.4-4.4-3.8-7.35-4.7-6.05-11.15-6.15-.15-.05-.25-.05m-1.6-.55v-.05l.05.05h-.05z"
                    id="HaiSpe007__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.251}
                    d="M43.55 3.85q-.5-.05-1.05-.1-2.35-.2-5-.25-3.85.3-6.5.4-3.5-.55-5.4-1.55-1.15-.65-1.75-1.5-.05-.05-.1-.15Q0 27.35 0 63.9q0 2.75.15 5.45h.1q0 .75.05 1.5Q11.8 62.3 17.65 49q5.8-13.35 6.85-15.2 1.85-3.3 3.85-6.55Q37.3 14.65 56.2 7.1q-.75-.35-1.2-.6-4-2-11.45-2.65M165.5 30.1q1.4 2.7 2.85 5.45.9 1.75 5.15 14.75 4.2 13 17.75 22.7.35-4.5.35-9.1 0-37-24.35-63.9-.45.7-1.5 1.15-2 .8-6.25.8-5.7.25-8.85.5-1.9.1-2.85.2-5.85.65-12.85 2.75 22.25 11.35 30.55 24.7z"
                    id="HaiSpe007__Symbol_78_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M69 23.3l.05-.3.1-1.25v-.1l.05-.25q-.049.638-.15 1.6l1.6-8.5q-2.8 2.35-5.5 3.7-4.2 2-4.35 2.35.05-6.75 2.1-13.4-10 6.5-13.05 7.95L37.85 0 33.3 21.2q-8.1-.9-15.05-5.55l3.4 8.45L0 21.4q5.05 5.85 9.75 7.95 1.4.65 6.05 3.25-4.5-3.1-5.65-6.2 1.65.65 3.65 1.2l.4.1q1.4.35 3.1.65l10.45 2.15-3.35-7.25q6.25 2.45 13.1 4V27q-.3-6.75 1.85-14.15Q43.1 19 44.3 25.15l.05-.15q.05-.05.2-.4.45-1.05 2.55-3 2.1-2 8.15-3.35l-2.1 10.45q.7-.85 4-1.8.45-.25 3.8-1 .2-.05 3.2-.45L64 34.4q3.45-2.8 10.75-6.55 6.85-3.1 11.9-3.45-3.05-1-8.75-.5t-9.25 3.5l.35-4.1z"
                    id="HaiSpe007__Symbol_54_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M44.25 39.8q.7.4 1.15.75l.05-.05q.1-.25 0-.45-2.8-6.2-8.05-11.2-.3-.3-.6-.55l-.05-.05q-3.2-2.95-7.25-5.5-.1-.05-.25-.15-3.25-2-7.05-3.8h-.1q-12-3.3-20.4-3.7-.1-.05-.25-.05-.3-.05-.6-.05L0 16q9.3.3 21.8 3.7 4.2 1.95 7.7 4.2l.05.05q2.65 1.7 4.95 3.6.25.2.45.4 6.1 5.25 9.3 11.85m3.25-20.45q0 .1.05.15.9 2.4 1.75 5.2 1.05 3.4 1.55 5.8t.65 2.45q.15.05.25-.8l.2-1.7q-.8-3.1-1.65-5.8l-.1-.3q-1-3.25-2-5.9-.05-.05-.05-.1-4.1-10.3-8.9-12.6-4.8-2.35-8.35-3.05-.8-.2-1.55-.25-.95-.15-1.6-.25l-.2.1-.55.65q.15 0 .45.05.5.1 2.95.45 2.4.3 8.4 3.2 4.65 2.35 8.7 12.7M76.85 1q.25-.1.5-.15.2-.1.3-.25.05-.15.05-.25V.3h-.1q-.35 0-.6-.15-.1-.05-.2-.15h-.1q-.7.25-1.3.45-.2.1-.4.15-8.8 3.35-13 10.5-1.1 1.8-1.9 3.8v.05q-1.9 4.35-2.55 9.9-.05.05-.05.15-.3 2.35-.4 4.9.25.8.8 2.8 0 .05.05.15 0 .3.05.2.05-.2.1-2.1 0-2.85.5-6.5 0-.05.05-.1.6-4.75 2.2-8.6v-.05q.85-2.2 2-4.1 4.45-7.45 14-10.6m34.8 14.05q-.15 0-.25.05-8.4.4-20.4 3.7h-.1q-3.8 1.8-7.05 3.8-.15.1-.25.15-4.05 2.55-7.25 5.5l-.05.05q-.3.25-.6.55-5.25 5-8.05 11.2-.1.2 0 .45l.05.05q.45-.35 1.15-.75 3.2-6.6 9.3-11.85.2-.2.45-.4 2.3-1.9 4.95-3.6l.05-.05q3.5-2.25 7.7-4.2 12.35-3.35 21.55-3.8l-.75-.9q-.25 0-.45.05z"
                    id="HaiSpe007__Symbol_44_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSpe007__Symbol_139_0_Layer0_0_FILL"
                transform="translate(119.9 53)"
                id="HaiSpe007__col1n"
            />
            <use
                xlinkHref="#HaiSpe007__Symbol_78_0_Layer0_0_FILL"
                transform="translate(96.65 89.9)"
                id="HaiSpe007__col1d"
            />
            <use
                xlinkHref="#HaiSpe007__Symbol_54_0_Layer0_0_FILL"
                transform="translate(149.5 62)"
                id="HaiSpe007__col1l"
            />
            <use
                xlinkHref="#HaiSpe007__Symbol_44_0_Layer0_0_FILL"
                transform="translate(135.95 59.25)"
                id="HaiSpe007__col1s"
            />
        </g></g>
    )
}

export default HaiSpe007
