import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFFFF",
    "col2n": "#000000"
}

function ShiLon057({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M142.45.15q-.6 1.2-1.15 2.5-.313.689-.6 1.4.269-.712.55-1.4.5-1.35 1.1-2.6L140.7 1.3q-1.35 1.05-2.8 2.1l-1.55 1.15q-20 13.8-44.8 15.95-.5 0-1.05.05l-1.1.05q-3.1.2-6.3.2h-.75L47.9 23.25 22.75.3l-.05.05q-.05-.1-.1-.15-.05-.05-.05-.1T22.5 0l-.2.05V.1q-.1.1-.15.2-2.95 3.95-5.55 8.3-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5h.05q-.15.5-.25 1-.1.3-.15.6l-.3 1.4q-.4 1.7-.75 3.4v.2q-.2.9-.35 1.8-.2 1-.4 2.05-.05.2-.05.4l.05.1q3-2.6 7-3.35.15-.05.3-.05l1.85-.2q8.1-.35 12.65 4.7l.35-1.3L82.5 88.9q30.75 0 61.3-7 2.1-.45 4.15-.95H148.1q.25-.05.5-.15h.2l-.05-.2v-.05l.05.05v-.1h-.05l-5.15-20.7h-.05l-.8-3.55h.25L142.65 0l-.2.15m-36.9 86.7l.05.45q-1.1-.15-2.15-.25l2.1-.2z"
                    id="ShiLon057__Symbol_348_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M58.4 20.6q-3.9-.3-7.65-.85-18.3-2.8-33.8-12.3-.3-.15-.5-.3Q16.2 7 16 6.9q-1.75-1.1-3.45-2.3L11 3.45Q9.55 2.4 8.2 1.35L6.4 0l.05.1v.05q0 .05.05.1t.1.15q.55 1.15 1.05 2.4v.05q3.8 9.75 3.2 24.5l-.1 2.4q-.2 2.35-.45 4.8-.05.4-.1.85l-2.7 14q-.55 2.7-1.1 5.45l-3.45 14.3q-.55 2.1-1.05 4.2l-.9 3.6-.95 3.6v.25l-.05.05h.2q.25.1.5.15H.85q2.05.5 4.15.95 30.55 6.9 60.9 7h.5v-68.1h-.6q-3.2 0-6.3-.2l-1.1-.05m75.5-9.15q-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.9-2.9-3.9-5.6l-.2-.3-.2.15q-.6 1.2-1.15 2.5-.5 1.1-.9 2.35-2.65 9.1-2.5 18.25-.05.8 0 1.65 0 1.2.05 2.4.15 3.45.55 7.2.05.4.1.85l2.85 14.75q.6 2.95 1.25 5.9 0 .1.05.25l.25 1.1.05-.05q4.55-5 12.6-4.65l2.05.2q.15 0 .3.05 2.15.4 4 1.3 1.6.8 3 2.05l.05-.1v-.05q0-.15-.05-.35-.3-1.55-.55-3.05l-.1-.4q-.05-.2-.05-.4-.25-1.1-.45-2.2-.1-.45-.2-.85l-.1-.5q-.2-.75-.35-1.45-.05-.3-.15-.6-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6z"
                    id="ShiLon057__Symbol_347_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiLon057__Symbol_348_0_Layer0_0_FILL"
                transform="translate(110 228.7)"
                id="ShiLon057__col1n"
            />
            <use
                xlinkHref="#ShiLon057__Symbol_347_0_Layer0_0_FILL"
                transform="translate(126.1 228.65)"
                id="ShiLon057__col2n"
            />
        </g></g>
    )
}

export default ShiLon057
