import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343"
}

function HaiMed017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M184.2 73.05h-.05q.1.2.15.4.05.2.1.35V75q0 .65-.2 1.4-.15.5-.25 1.1-.8 4.8 2.5 10 .55.85.95 1.95 2.3 5.25 2.3 9.4 0 2.05-1.15 6-9.5 28.7 4.8 41.15l1.05.6-.9 1.8q-7.55 14.2-12.5 18.8-2 1.75-2.9 2.55h.8q7.45-.1 14.4-1.95 16.8-4.2 23.7-18.2 13-1.9 16.1-21.65 4.25-37.25-18.25-52.9l-4.65-3.1q-6.15-24.65-25.3-43.8Q161.45 4.75 129.95.8q-6.4-.8-13.1-.8-6.7 0-13.1.8-31.5 3.95-54.9 27.35Q29.6 47.4 23.55 72.1l-4.6 2.95Q-3.55 90.7.7 127.95q3.1 19.75 16.1 21.65 6.9 14 23.7 18.2 6.1 1.65 12.6 1.9h2.65q-9.05-7.9-15.45-21.3l-.9-1.8 1.05-.6q14.3-12.45 4.8-41.15-1.15-3.95-1.15-6 0-4.15 2.3-9.4.4-1.1.95-1.95 3.3-5.2 2.5-10-.1-.6-.25-1.1-.2-.75-.2-1.4l.05-1.2q.05-.4.2-.75.45-1.45 2-2.85l.3-.3q.75-.65 1.65-1.25.85-.5 1.75-1.15.85-.5 3-1.65.2-.1.3-.1 2.5-1.05 6.65-2.6 4.75-1.8 14.9-3.3 2.6-.4 5.55-.8 4.1-.55 7.9-1.4 15.5-3.75 23.25-12.5 7.75 8.75 23.25 12.5 3.8.85 7.9 1.4 4.1.55 7.55 1.1 8.65 1.4 12.9 3 4.15 1.55 6.65 2.6.1 0 .3.1 2.15 1.15 3 1.65.9.65 1.75 1.15.9.6 1.65 1.25l.3.3q1.55 1.4 2.05 2.85z"
                    id="HaiMed017__Symbol_73_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M9.65 110.35q3.95 2.3 7 2.2 3.05-.1 5.2-1.15l-1.6-3.2q-1.85.9-3.75 1.45-3.4.95-6.85.7M32.5 31.8l-.2.1q-.3.25-.55.55-.45.25-.8.55-1.25.8-2.15 1.65-5.6 4.65-6.3 11.25 1.85-5.2 8.05-7.6l-.3-1.7.05-1.2q.05-.4.2-.75.45-1.45 2-2.85M.4 40.75q.15 2.2.95 4.15.8 1.95 1 2.3.2.3.4.75-.2-2.4-.05-5.55.65-4.45 1.7-8.7L0 36.55l.15 1.2q.05.8.25 3m128.5-20.1q1.55.2 3 .4Q108.3 17.35 98.65 0 84.4 17.8 66.6 20.65q4.1-.55 7.9-1.4Q90 15.5 97.75 6.75q7.75 8.75 23.25 12.5 3.8.85 7.9 1.4m50.15 91.9q3 0 6.8-2.2-3.5.25-6.9-.7-1.85-.55-3.7-1.45l-1.95 3.85q2.7.5 5.75.5m16.35-75.6v-.5l-4.35-2.9q1.1 4.4 1.8 9 .05 2.15-.15 6.25.7-1.95.75-2.3 0-.35.6-2.1.55-1.75.85-3.25.25-1.45.35-1.9.15-1.25.15-2.3m-31.65-4.5q-.25-.3-.55-.55l-.2-.1q1.55 1.4 2.05 2.85H165q.1.2.15.4.05.2.1.35v1.2l-.35 1.85q6.25 2.25 8.1 7.45-.7-6.6-6.3-11.25-.9-.85-2.15-1.65-.35-.3-.8-.55z"
                    id="HaiMed017__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiMed017__Symbol_73_0_Layer0_0_FILL"
                transform="translate(75.6 57.6)"
                id="HaiMed017__col1n"
            />
            <use
                xlinkHref="#HaiMed017__Symbol_42_0_Layer0_0_FILL"
                transform="translate(94.75 96)"
                id="HaiMed017__col1d"
            />
        </g></g>
    )
}

export default HaiMed017
