import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#EEAA00",
    "col2n": "#FFC400"
}

function AccHat153({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M170.3 5.45q-3.25-2.85-7.15-4.2Q160.2.2 156.9.05q-.7-.05-3.25.05t-5.55 1.3q-3 1.2-4.05 1.65-1.05.45-1.5.6-1.05.4-2 .75-7.75 2.8-13.3 4-7.4 1.55-11.15 2.15-17.75 2.8-37.3 1.3-3.8-.3-7.7-.75-12.8-1.45-26.3-4.7-3.25-.75-6.5-1.6-.75-.2-1.45-.4l-.8-.2q-.9-.25-1.8-.55-.6-.15-1.65-.65-1.1-.5-4.55-1.75Q24.55 0 18.45 0T6.2 5.45Q3.3 8 1.8 11.05q-.6 1.1-.95 2.3-.4 1.15-.6 2.4Q0 17.1 0 18.55-.1 26.2 3.4 29.4q.55.5 1.2 1 .05.05.15.1.05.05.1.05.25.2.55.4Q6.95 32 9.05 32.9q.2.1.5.2 1 .4 2.1.8 1.35.45 2.9.9.65.15 1.2.35 4.45 1.25 7.55 1.95.3 0 .6-.05Q31 39.15 38 40.8q5.75 1.35 11.45 2.4 3.75.75 7.5 1.35 19.85 3.15 38.4 2.9 3.65-.1 7.2-.25 20.25-1.1 38.85-6.4 3.35-1.05 6.7-2.15 2.3-.8 4.55-1.6h.3q13.4-4.65 19.6-8.5.35-.3.65-.6l.6-.6q2.9-3.35 2.8-8.95-.1-3.3-1.25-6.15-.35-.75-.7-1.45l-.25-.45q-.1-.15-.15-.25-.65-1.1-1.5-2.15-1.1-1.3-2.45-2.5z"
                    id="AccHat153__Symbol_495_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    fillOpacity={0.4}
                    d="M276.75 233.85q.2-3.25-1.8-5.1-5.85-5.4-5.9-14.9-4.7 7.15 2 17.75 5.577 7.502-6.75 17.65.114.224.2.4 12.102-4.414 12.25-15.8m-15.7-13.25l-.05.05q1.338 11.68-2.55 16.65-.061.183.05.55.796 1.388 1.55 2.8 6.028-5.02 1-20.05m-151.65 8.15q-2 1.85-1.8 5.1.15 11.55 12.6 15.95.05-.15.15-.25-12.7-10.35-7.05-17.95 6.7-10.6 2-17.75-.05 9.5-5.9 14.9m15.15 12.1q.064.053.1.1l1.75-3.1q-.239-.257-.5-.55-3.95-5.05-2.5-17-5.3 15.6 1.15 20.55m-11-133.9q-.3-.1-.5-.2-2.102-.907-3.65-1.95-.3-.197-.6-.4-.016-.025-.05-.05l-.05.05-.4.4q-5.79 5.83-10.15 12.4-4.69 7.16-7.7 15.2-.139.449-.3.9-4.1 11.6-4.65 25.1-.7 17.4 1.95 36.55 3.85 27.6-.7 41.25-3.4 11.3 1.1 22.85 1.85 4.7 3.4 9.35h1.25q-3.35-29.15 11.9-50.35.415-.304.8-.7.055-.03.05-.05.082-.058.15-.15.036-.013.05-.05.067-.045.1-.1.094-.103.15-.2l5-11.65q.149-.61.3-1.25 6.1-26.1.05-54.35-2-7.1-1.25-16.25.036-.447.05-.9.683-6.998 2.85-15.2 1.256-4.54 2.95-9.45-1.1-.4-2.1-.8m-7.75 94.9q1.2-26.65-3.5-53.3 11.05 23.1 3.5 53.3m188.05-69.45q-2.925-7.935-7.85-15.2-4.488-6.683-10.65-12.8-.317-.326-.65-.65-.103-.068-.2-.15l-5.5 2.75q-.312.232-.6.3 1.98 5.736 3.25 10.55 2.175 8.302 2.8 15.2.064.453.1.9.75 9.15-1.25 16.25-6.05 28.25.05 54.35.143.607.3 1.2 2.566 10.208 6.3 12.95 15.25 21.2 11.9 50.35h1.25q1.55-4.65 3.4-9.35 4.5-11.55 1.1-22.85-4.55-13.65-.7-41.25 2.65-19.15 1.95-36.55-.55-13.5-4.65-25.1-.16-.451-.35-.9m-11.8 16.15q-4.7 26.65-3.5 53.3-7.55-30.2 3.5-53.3z"
                    id="AccHat153__Symbol_423_0_Layer0_1_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M35.75 6.35q-3.25-.75-6.5-1.6-.75-.2-1.45-.4l-.8-.2h-.55Q15.9 5.55 7.9 14.25l-.8.9q-.45.5-.85 1-.45.55-.95 1.15-.2.3-.35.6Q1.65 23.95 0 32.85q.2.1.5.2 1 .4 2.1.8 1.35.45 2.9.9.65.15 1.2.35 1.45-8.55 6.15-14.8 7.4-9.85 22.9-13.95M47.9 44.5q-4.05-7.7 2.45-16.45 5.65-7.7 19.4-16.25-3.8-.3-7.7-.75-15.65 6.3-20.55 15.7-3.75 7.25-1.1 16.4 3.75.75 7.5 1.35m48.4-15.45q6.1-9.9 21.9-20.7-7.4 1.55-11.15 2.15-15.3 8.05-19.85 19.1-3.35 8.05-.9 17.8 3.65-.1 7.2-.25-3.1-8.65 2.8-18.1m42.75 9.55q-.7-7.4 4.15-18.35 3.75-8.45 10.9-19.05-2.95-1.05-6.25-1.2-10.1 11.85-13.8 22.75-3.2 9.3-1.7 18 3.35-1.05 6.7-2.15z"
                    id="AccHat153__Symbol_501_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M4.95 0Q1.65 6.05 0 14.95q.2.1.5.2 1 .4 2.1.8 1.35.45 2.9.9.65.15 1.2.35 1.45-8.55 6.15-14.8Q8.9 1.25 4.95 0M47.9 26.6q-4.05-7.7 2.45-16.45-4.4-.6-8.85-1.3-3.75 7.25-1.1 16.4 3.75.75 7.5 1.35m48.4-15.45q-4.55.35-9.1.55-3.35 8.05-.9 17.8 3.65-.1 7.2-.25-3.1-8.65 2.8-18.1m37.75-6.3q-3.2 9.3-1.7 18 3.35-1.05 6.7-2.15-.7-7.4 4.15-18.35-4.6 1.35-9.15 2.5z"
                    id="AccHat153__Symbol_334_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat153__Symbol_423_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path
                        fillOpacity={0.302}
                        d="M23.55 18.3q-.05 0-.1-.05l-.05.05-.4.4q-5.8 5.85-10.1 12.4-4.75 7.15-7.7 15.2-.2.45-.35.9Q.75 58.8.2 72.3q-.7 17.4 1.95 36.55 3.85 27.6-.7 41.25-3.4 11.3 1.1 22.85 1.85 4.7 3.4 9.35H8.7q16.5-15.3 23.45-11.35 1.75-4.8 3.8-9.25 1.6-3.55 3.45-6.85.85-1.55 1.75-3.05 2.75-4.8 5.95-9.05h.05q0-.1.1-.2-3.65-3-7.15-6.4l-.7-.7-.05-.05q-3.45-3.45-6.45-7.05l-.9-1.1-.9-1.1-1.15-1.45q-2.35-3.1-4.35-6.3-.1.3-.2.65.15-.6.3-1.25 6.1-26.1.05-54.35-2-7.1-1.25-16.25l.1-.9q.65-7 2.85-15.2 1.2-4.55 2.9-9.45-1.1-.4-2.1-.8-.3-.1-.5-.2-2.1-.9-3.65-1.95-.3-.2-.55-.4M208.9 47.2l-.3-.9q-3-7.95-7.85-15.2-4.55-6.7-10.7-12.8-.3-.35-.65-.65-.1-.05-.15-.1-2.4 1.45-5.75 2.85-.15.05-.35.15-.05 0-.1-.05 0 .05.05.1 2 5.75 3.25 10.5 2.2 8.3 2.85 15.2 0 .45.05.9.75 9.15-1.25 16.25-6.05 28.25.05 54.35l.3 1.2q-1.85 2.8-3.9 5.55l-.05.05q-.6.75-1.2 1.55l-1 1.2-7.25 8.05q0 .05-.05.05l-.7.7q-3.5 3.4-7.15 6.4.1.1.1.2h.05q3.2 4.25 6 9.05.8 1.35 1.55 2.75 1.9 3.45 3.6 7.15l.7 1.5q.05.15.15.4 1.55 3.45 2.9 7.1 6.95-3.2 22.95 11.6h2.75q1.55-4.65 3.4-9.35 4.5-11.55 1.1-22.85-4.55-13.65-.7-41.25 2.65-19.15 1.95-36.55-.55-13.5-4.65-25.1z"
                    />
                    <path d="M195.3 6.15q-.1-3.3-1.25-6.15-13.45 5.25-23.1 8.05-4.6 1.35-9.15 2.5-18.85 4.75-37.75 6.3-4.55.35-9.1.55-18.4.7-36.85-1.55-4.4-.6-8.85-1.3Q54.95 12.2 40.6 8.1q-3.95-1.15-7.9-2.4-6.45-2.1-13.15-4.6-.4 1.15-.6 2.4-.25 1.35-.25 2.8-.1 7.65 3.4 10.85.55.5 1.2 1 .05.05.15.1.05.05.1.05.25.2.55.4 1.55 1.05 3.65 1.95.2.1.5.2 1 .4 2.1.8 1.35.45 2.9.9.65.15 1.2.35 4.45 1.25 7.55 1.95.3 0 .6-.05 7.1 2.1 14.1 3.75 5.75 1.35 11.45 2.4 3.75.75 7.5 1.35 19.85 3.15 38.4 2.9 3.65-.1 7.2-.25 20.25-1.1 38.85-6.4 3.35-1.05 6.7-2.15 1.3-.45 2.65-.9 8-2.45 13.65-4.9-.05-.05-.05-.1.05.05.1.05.2-.1.35-.15 3.35-1.4 5.75-2.85 1.35-.85 2.5-1.7l.15-.15.6-.6q2.9-3.35 2.8-8.95z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat153__Symbol_495_0_Layer0_0_FILL"
                transform="translate(104 73.85)"
                id="AccHat153__col1n"
            />
            <g id="AccHat153__col1d">
                <use
                    xlinkHref="#AccHat153__Symbol_423_0_Layer0_0_FILL"
                    transform="translate(85.3 86.1)"
                />
                <use xlinkHref="#AccHat153__Symbol_423_0_Layer0_1_FILL" />
            </g>
            <use
                xlinkHref="#AccHat153__Symbol_501_0_Layer0_0_FILL"
                transform="translate(113.05 73.9)"
                id="AccHat153__col2n"
            />
            <use
                xlinkHref="#AccHat153__Symbol_334_0_Layer0_0_FILL"
                transform="translate(113.05 91.8)"
                id="AccHat153__col2d"
            />
        </g></g>
    )
}

export default AccHat153
