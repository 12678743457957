import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#BFBFBF"
}

function ShiDre069({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M160 56.25v-.05q0-.15-.05-.35v.45l.05.05v-.1M144.95 11.5q-1-1.85-2.1-3.6-.6-.95-1.15-1.9-1.2-1.85-2.45-3.65-.6-.8-1.15-1.55l-.6-.8-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15q-3.7 2.55-7.6 4.75-5.1 2.8-10.45 5-.4.1-.8.3-15.8 9.95-34.4 16.8l-.15.05h-.95q-.05 0-.1-.05-18.5-7.3-37.75-18.2-3.55-1.55-7-3.4-4.35-2.4-8.5-5.25L22.05 3.5q-1.45-1.05-2.8-2.1L17.4 0q-.1.05-.15.15-.25.3-.45.65-2.8 3.75-5.25 7.9-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25-.15.4-.3.75-.3.8-.6 1.65-.15.4-.3.85-.25.7-.45 1.4l-.2.6q-.15.4-.25.8l-.2.9 19.9 7.4-1.35 6.75q-.1.35-.15.7-.5 2.4-.95 4.75L13.2 72.35q-.1.5-.25 1.05-.2.7-.35 1.4l-.35 1.4q-.05.1-.05.2-.1.3-.15.6l-.05.15-.9 3.45v.15L6.4 93q70.9 7.45 141.8 0l-4.65-12.1h.2l-.05-.2v-.1l-5.15-20.7h-.05l-.8-3.55q-.65-3.1-1.3-6.15v-.05l-1.45-7.4 19.8-7.35-.3-1h.25q-.25-.85-.5-1.65-.3-.8-.55-1.6-.1-.2-.15-.35l-.6-1.7q-.15-.35-.25-.75-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6z"
                    id="ShiDre069__Symbol_269_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M1.05.05H0v52.5h1.2V0l-.15.05z"
                    id="ShiDre069__Symbol_271_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M4.45 33.2q-.15-.6-.6-1.05-.65-.65-1.6-.65-.95 0-1.6.65-.45.45-.6 1.05v.05q-.05.25-.05.5v.05q0 .9.65 1.55t1.6.65q.95 0 1.6-.65.65-.65.65-1.55v-.05q0-.25-.05-.5v-.05m-2.2-17.45q-.95 0-1.6.65-.55.55-.6 1.3-.05.15-.05.3 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.65-1.6v-.3q-.1-.75-.65-1.3-.65-.65-1.6-.65M4.5 2.25q0-.95-.65-1.6Q3.2 0 2.25 0 1.3 0 .65.65 0 1.3 0 2.25q0 .7.35 1.25.1.15.3.35.65.65 1.6.65.8 0 1.4-.45l.2-.2q.65-.65.65-1.6z"
                    id="ShiDre069__Symbol_272_0_Layer0_0_FILL"
                />
                <g
                    id="ShiDre069__Symbol_270_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M18.45 35.95L17.1 42.4l.8.3 1.35-7.2v-.1L.3 28.35q-.15.4-.3.75l18.45 6.85m56.4-4.3q-8.15 2.25-15.35 8L16.3.7l-.9-.7q6.4 6.65 13.7 13.6l30.4 27.35Q66.25 35 74.85 32.7v-1.05m61.9 46.45q2.1-.45 4.1-.95l-.2-.95q-1.9.5-3.9.9-28.2 6.5-56.6 6.95-2.05.05-4.1.05v-.05h-1.2v.05h-.7q-29.95-.15-60.1-7-2-.4-3.8-.9-.05.1-.05.2-.1.3-.15.6l-.05.15q1.95.5 4.05.95 61.75 14 122.7 0m4.1 2.95q-2.05.5-4.1.95-30.55 7-61.3 7-30.6 0-61.4-7-2.1-.45-4.15-.95H9.75q-.25-.05-.5-.15h-.2l.05-.05v-.1l-.4 1.05q3.95.95 5.35 1.25 61.75 14 122.7 0 1.5-.3 5.15-1.2l-.35-.95q-.25.1-.5.15h-.2M133.4 41.9l-1.1-5.95 18.6-6.85q-.15-.35-.25-.75l-19.1 7.1v.05l1.4 7.25.65-.25h-.15l-.05-.6M135.1.35l-.3.2L91.55 39.5q-7.25-5.8-15.5-8v1.2q8.65 2.4 15.5 8.1l20.85-18.7q2.95-2.7 5.9-5.35 8.6-8.15 16.8-16.4zM157.95 56.3l.05.05V56.2q0-.15-.05-.35v.45z" />
                </g>
            </defs>
            <use
                xlinkHref="#ShiDre069__Symbol_269_0_Layer0_0_FILL"
                transform="translate(115.05 228.6)"
                id="ShiDre069__col1n"
            />
            <use
                xlinkHref="#ShiDre069__Symbol_270_0_Layer0_0_FILL"
                transform="translate(117.05 228.6)"
                id="ShiDre069__col1d"
            />
            <use
                xlinkHref="#ShiDre069__Symbol_271_0_Layer0_0_FILL"
                transform="translate(191.9 260.1)"
                id="ShiDre069__col1s"
            />
            <use
                xlinkHref="#ShiDre069__Symbol_272_0_Layer0_0_FILL"
                transform="translate(180.4 271.1)"
                id="ShiDre069__col2n"
            />
        </g></g>
    )
}

export default ShiDre069
