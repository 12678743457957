import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#DB6F02",
    "col1n": "#FD8A17"
}

function ObjToy012({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M15.6 2.65Q13.95 1 11.9.4q-.65-.2-1.3-.3Q9.9 0 9.15 0q-3 0-5.35 1.7l-.5.4q-.35.25-.65.55-.3.3-.55.6l-.35.5Q.5 5.4.15 7.4l-.05.3Q0 8.3 0 9v.55q.1 2.25 1.15 4.1.25.45.6.9.4.55.9 1.05 2.7 2.7 6.5 2.7t6.45-2.7q2.7-2.65 2.7-6.45 0-3.8-2.7-6.5z"
                    id="ObjToy012__Symbol_106_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M14.45 2.55Q12.8.9 10.75.3 10.1.1 9.45 0q.35.15.7.4 1.2.55 2.25 1.6 2.5 2.5 2.5 5.95 0 3.5-2.5 5.9-2.45 2.5-5.9 2.5-2.3 0-4.15-1.05-.65-.35-1.25-.8-.55-.4-1-.85l-.1-.1q.25.45.6.9.4.55.9 1.05 2.7 2.7 6.5 2.7t6.45-2.7q2.7-2.65 2.7-6.45 0-3.8-2.7-6.5z"
                    id="ObjToy012__Symbol_66_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy012__Symbol_106_0_Layer0_0_FILL"
                transform="translate(217.4 345)"
                id="ObjToy012__col1n"
            />
            <use
                xlinkHref="#ObjToy012__Symbol_66_0_Layer0_0_FILL"
                transform="translate(218.55 345.1)"
                id="ObjToy012__col1d"
            />
        </g></g>
    )
}

export default ObjToy012
