import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343"
}

function HaiSho052({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M180.5 42.55l-1.35-1.2q-10-9.05-17.65-12.55 2.75-.95 6.4-2.9-6.75-1.05-14.55-4.35l-1.4-.6q-8.65-3.9-16.2-7.95-25.35-16.15-60.5-6.3l.3-.5q2.8-4.05 4.85-6.15L80.35 0Q71.2 2.2 62.3 8.35q-6.8 4.55-13.95 11.8-1.4-3.85-.55-6.35-4.85 4.75-6.4 6.25l-.75.65q-27.4 28.45-37.7 57.25Q.55 88.6 0 100.25l3-1q-.75 10.45 2.75 19.25 6.45-8.9 8.25-16.3 1.95-8 2.5-9.45.6-1 1.25-2.05.4-.65.9-1.2 2.8-3.9 6.65-7.1-1.05-.2-5.15.8 2.9-9.25 11.95-11.65-3.4-3.25-3.4-6.95-.1-.05-.2-.05l.15-.1.05.15q3.3.1 5.8.8v-.1q-.4-.6-.75-1.8-.25-1.15-.3-2.25 0-3.95 2.05-6.05 2.1-2.1 5.9-2.15h.25q2.6-.05 7.25 2 2.8 1.25 9.5 4.55 14 6.65 25.25 6.55 3.45-.05 6.15-.5-6.6-.95-11.25-6.55-.05 0-.1-.05h.05l.05.05q3.65 1.7 6.85 2.7 6.9 2.15 16.4 2.2 6.05 0 18.05-.05 9.8.4 15.05 3.4-.4-3.1-3.8-6.4 1.2.55 2.35 1.2.95.4 3.45 1.5 1.95.85 3.25 1.3 8 2.85 13.2 4.4.95.25 1.85.5 2.15.65 7.5 1.85 2.8.7 6.2 1.6 11.4 1.85 13.05 19.5l1.65 23.95q.4 9.4 8.6 14.25.2-.65.8-2 .6-1.4 1.3-2.8.7-1.45 1.95-4.8 2-5.4 3.95-12.15v-.15q-2.1 1-4.2 1.05h-.1l.35-1.1-.25 1.1q.4-1.6.55-1.95.9-2.45 1.55-7 .25-1.65.45-3.1.85-7.8-1.4-14.65-.15-.4-.3-.85-3.1-9.85-4.45-20.7l-.1-.5q-2-9.65-11.05-18.15-.4-.35-.75-.7m-25.2-4.3h.05v.05l-.05-.05z"
                    id="HaiSho052__Symbol_173_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M147.85 33.2q-9.7.15-22.85-2.8-1.2-.25-2.4-.55-8.75-2.15-18.15-5.5Q91.9 19 85.25 16.2q-6.7-2.75-12.5-4.25 1.35-.05 3.85-.1 12.35-.1 25.65 4.2 3.65 1.2 10.75 3.7 5.3 1.8 9.6 2.9.95.2 1.85.4l.15-.2-.05-.05q-.9-.55-1.95-1.15-6.35-3.7-18.25-9.1Q86.6 1.9 66.05 4.25q-.5.05-.95.1L43.6 6.7Q46.75 4.15 62.25.8V.6Q32.8-3.1 10.65 16.5 5.1 21.4 0 27.75q3.2-1.3 8.6-3.45 1.05-.4 2.05-.7 7.8-2.65 12.25-2.7 6.55-.05 12.65 1.55 3.65.9 11.1 3.75 4.6 1.7 11.85 2.7 4 .6 12 1.3-6.9-2.05-19-8.55 3.1-.85 4.1-.95.75-.1 5.3-.15 7.7-.05 16.8 2.2 4 1 16.4 5 15.35 4.9 28.5 6.4 6.35.7 12.2.65 5.2-.05 13.05-1.6z"
                    id="HaiSho052__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiSho052__Symbol_173_0_Layer0_0_FILL"
                transform="translate(92.1 50.95)"
                id="HaiSho052__col1n"
            />
            <use
                xlinkHref="#HaiSho052__Symbol_39_0_Layer0_0_FILL"
                transform="translate(122.85 66.4)"
                id="HaiSho052__col1l"
            />
        </g></g>
    )
}

export default HaiSho052
