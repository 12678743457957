import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2l": "#FF62B6",
    "col2n": "#FF008C"
}
function HaiLon037({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M103.7 1.3Q98.9.25 94.15.15q-41.6-2.45-69.1 41.6-17.6 28.2-20.2 61.45-2.3 21.6 1.85 44.5 2.3 24.7-3.35 44.8-4.3 11.1-3.05 19.85 1.5 6.6 5.75 9.9.45.35 1 .7-.5-2.6-.5-6.55 0-1.35.05-2.45.1-3.1.5-4.75.35-2.3 1.1-4 .1-.25.2-.45l.5-1.3q-2.05 10.25 2.25 16.8 3.2 4.8 9 5.25h2.6q1.15-.05 2.4-.1-1.45-2.85-.8-8.45v-.05q.25.7.6 1.5t.7 1.5q2.65 5.35 6.2 5-1.45-2.15-1.25-4.9.2.45 1.4 1.9l.55.55q1.7 1.9 6.75 2.15-1.55-2.85-2.7-7.95-2.4-10.5-1.55-20.95.65-7.75.7-9.75l.8-1.5q1.3-2.3 2.7-4.5.15-.25.3-.45 1.4-2.2 4.7-7.1-2.35-1.9-4.6-4-1.1-1-2.2-2.1.05-1 .1-1.85 0-.15.05-.3 0-.3.05-.6h-.05q4.45-19.1-.55-37.45l-.2-.55q-.9-2.95-1.65-6-2.95-21.1 1.25-39.95v.7q.5-2.65 1.25-5.5l.45-1.6q3-10.65 9.5-24.7.2-.45.4-.75-2.75 9.6-3.9 13.65Q40 77.2 54.2 79.45q.05-.1.1-.15-1.85-3.65 4.15-19.95-2.45 12.35 1.5 21.4Q63.2 63.4 72.4 40.15q-5.35 16.5-5.7 27.9l.1.9q.05 8.25 6.8 10.85 2.9.75 7.1.5-2.15-10.6 4.25-26.05-3.7 14.35-.25 22.1 1 2.2 4.3 3.35-.25-.8-.85-4.6-.65-3.85.45-9 .1-.6.25-1.05-1.35 15.05 14.3 15.5-1.5-12 1.05-23.65 11.2-29.1 0-55.55h-.15q-.2-.05-.35-.05m-55 45.55q-.15.7-.05.05l.05-.05m80.25-1.6h.05q-.048-.146-.05-.2v.2z"
                    id="HaiLon037__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M4 2.1Q2.9 1.1 1.8 0q-.05.2-.05.35-.35 4.4-1 10.9-.4 3.9-.7 7.05 0 .3-.05.65.1 0 .1.7l.8-1.5q1.3-2.3 2.7-4.5.15-.25.3-.45Q5.3 11 8.6 6.1 6.25 4.2 4 2.1z"
                    id="HaiLon037__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M22.6 151.75q-3.95 29.35-4.75 33.6-1.8 9.75-2.1 17.05 0 4 .1 4.9.1.85.3 1.7v-.05q.25.7.6 1.5t.7 1.5q2.65 5.35 6.2 5-1.45-2.15-1.25-4.9.2.45 1.4 1.9-1.15-2.3-2.75-9.35-1.6-7.1-.8-15.15.8-8.1 2.35-37.7m-7.45-12.95q-1.2-7.65-4.65-16.7 2.1 28.5 1.85 36.2-.3 8.25-2.1 14.6T4.9 185.8q-3.6 6.5-4.9 11.4v.05q.1-.25.2-.45l.5-1.3q-2.05 10.25 2.25 16.8 3.2 4.8 9 5.25h2.6q-1.15-.45-1.9-1.15-.8-.75-.9-1.1-.1-.35-2.15-4.4-2.1-4.1-2.1-13.1t2.8-17.5q2.8-8.5 4.8-18.6 2-10.05.05-22.9M93.05 3.45q-.25 2.2-.55 3.9-1.65 8.1-2.9 15.05-.4-1.25-.65-4.75l-.5-7.1q-.8 1.55-1.7 3.75-.3.8-1.75 4.7 1.1-4.85 2.6-16.15-2.2 3.2-3.25 5.5-.6 1.25-1.6 3.15l-.15.15L83 1q-.95 2-4.8 6.35-3.95 4.35-4.7 5.15l-3.4 3.9q-.05.1-.1.25v-.15l.1-.1q1.75-4.65 5.55-10.65Q78.5 1.2 79.6 0q-9.55 6.8-18.05 16.25-3.1 3.5-6.15 7.4 1.6-3.45 4.2-8.05 3.75-6.75 8.05-13.15l-.8.4q-7.1 3.3-16.85 12.3Q39.55 24.85 34.7 33v.15q3.95-6.4 13.65-13.95 6-4.7 9.75-6.9-3.1 5.2-4.9 9.2-.85 1.8-4.85 10.2-2.35 6.5-3.15 8.45-.8 1.95-.85 2.1-.2.55-.35 1.15v.1q1.65-3.8 7.5-11.5 6.25-8.2 12.7-14.25-2.65 4.75-4 9.45-.9 3.2-1.6 5.55Q60 29.1 66.75 22.5q4.7-4.6 11-9.75h.1Q73.7 20 71.75 24.2q-1.7 3.55-5.45 22.95 1.95-7.1 6.4-15.75Q79.75 17.7 83 14.9q.2-.25.75-2.05.45-1.8-3.25 14.15-.6 7.75-.65 9 .45-1.35 2.9-5.75 2.95-5.35 4.4-8.1-.85 1.9-.2 10.75.4 5.6 1.2 14.1-.05-5.85 1.4-10.4 3.4-11.05 6.2-17.95v-.15q.1.35.25.85v-5.8q-1.5-3.1-2.95-10.1z"
                    id="HaiLon037__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M94.6 78.6q-2.85-15.1-10.35-28.25-1.4-2.4-2.9-4.75Q53.25-2.45 9.9.15 7.15.2 4.4.6q-.6.05-1.25.2-1.4.2-2.8.5-.15 0-.35.05h.15V56.9q2.2 9.15 2.55 23.55 2 .05 4.1-4.15 2.05-4.2 1.8-40.5.75 14.55 2.3 25.05Q12.4 71.25 9 80.3l11-.2q12.25-1.5 10.8-14.85 3.45 7.8 1.05 15.05 8.4-8.6 4.3-29.05 7.15 21.4 2.15 29.45 6.3.95 10.15-.4 6.25-2.35 5.8-20.15-.6-8.85-2.45-17.7.65 2.2 1.7 5.4 3.4 10.05 3.45 32.45 4.05.65 7.35-1.9 5-7.25.9-17.9 8.6 12.85 7.3 46.05-.7 9.05-2.6 17.2-6.5 17.3-3.55 40.7.05 1 .15 2.2l-1.85 1.75q-2.25 2.05-4.5 3.95 3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5.15.3.35.6l.1.2q-.3 0 .6 10.45.85 10.45-1.55 20.95-1.3 5.75-1.85 7.55.7-.55.75-.65.1-.1 1.6-1.45 1.45-1.4 3.5-5.1 2-3.6 4.3-13.6.1-.35.15-.65.45 14.7-5.8 21.6 4.1.55 7.15.55 5.75-.15 7.45-2.6 1.35-1.4 4.25-9.9l.45-.3q.05-.65.05-1.25.048-.048.05-.1v.05q.1 1.15-.35 4.55-.5 3.35-.95 5.35-.45 1.95-1.15 3.05l-.5.75q0 .3.25.15 4.8-1.2 7.5-7.2.1.55.1 1.1.1 3-2.2 6.55h1.5q3.45 0 5.8-2.25l.5-.5q.25-.35.5-.65.65-.75 1.5-2.25.4-1.35.7-2.65 3.45-10.25-2-24.3-5.65-20.1-3.35-44.8 5.85-32.2-1.05-61.9-.1-.4-.25-.85-.05-.25-.15-.5-.5-2.9-1.15-5.85m-2.65.05v-.05h.1l-.1.05m-67-33.4h-.05v-.2q.002.055.05.2z"
                    id="HaiLon037__Symbol_105_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M4.5 1.75Q2.25 3.8 0 5.7q3.25 4.95 4.65 7.15.15.2.3.45 1.4 2.2 2.7 4.5.15.3.35.6l.1.2q-.05-.35-.05-.65-.3-3.15-.7-7.05-.65-6.5-1-10.9L4.5 1.75z"
                    id="HaiLon037__Symbol_107_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M91.25 180.5q-3.85-14.7-3.3-26.35-.1.95-.3 2.95-.4 3.45-.4 4.75 0 6.65 1.25 13.45 1.25 6.8 1.25 12.3v1.9q-.15 3.25-.6 13.1.048-.048.05-.1v.05q.1 1.15-.35 4.55-.5 3.35-.95 5.35-.45 1.95-1.15 3.05l-.5.75q0 .3.25.15 4.8-1.2 7.5-7.2.1.55.1 1.1 1.4-4.65 1.4-9.05 0-3.95-1.35-9.75-1.9-7.1-2.9-11M77.3 125.65q-2.15 7-2.9 11.85-1.7 10.8-1.75 18.6-.1 7.75 1.75 14.85t1.85 13.9v2.65q0 3.5-.25 4.5-.3 1.75-.55 3.15.1-.35.15-.65.45 14.7-5.8 21.6 4.1.55 7.15.55 3.1-6.3 4.25-9.3 2.05-5.6 2.05-12.75 0-9-3-20t-3.3-18.45q-.25-6.25.35-30.5M43.95 16.35l-1.05-.9Q31 5.55 25.45 2.9L23.9 1l1.2 1.7q-.8-.35-1.5-.6 1.55 1.25 3.85 4.05 2.3 2.75 4 6.35 1.65 3.55 4.6 8.35l-3.2-4q-2.35-3.65-7.1-7.7-4.8-4.1-10.6-9.15 2 4.85 3.6 9.5 1.15 3.4 1.6 5.2v.5q-1.35-.9-4.9-3.95-3.15-2.8-5.35-4.9H10V7.7q2.9 3.65 3.95 15.55L11.1 18.7q-.8-1.9-4.95-8.65-4-6.4-4.05-6.6H2V4.7q.35 1.95.35 3.5v2.5q0 2.4-.15 3.8-.2 1.45-.2 2.6v.1q-.15-.2-2-3.95v5.8q.65 2.4 3.45 18.4-.05-9.25 1.9-16.75.9-5.05 1-5.35 4.75 6.6 10.2 21.4 1.9 5.15 2.75 8.65l.2-3.35q0-6.5-.55-13.7-.65-7.95-1.6-11.5v-.15l9.25 13.65q-.45-7.05-.75-9.85-.5-4.55-1.5-7.15.9.55 5 5 4.05 4.45 7.2 8.95 3.05 4.4 8.05 14.65-.35-1.7-.8-3.9-1.55-7.2-2.7-10.7-1.8-5.5-3.4-9.7-.5-1.4-.8-2.4 1 .65 3.2 1.7 5.8 2.8 9.9 7.35 4.1 4.5 6.85 9.8l.15.1q-.2-.45-.4-.95-1.3-4.55-2.35-7.7-.65-1.9-1.3-3.5-1.4-3.45-3-5.55 1.9.95 3.85 2.55 7.15 5.75 15.15 19.15h.15q-1.7-5.4-5-10.3-3.75-5.5-10.15-11.45-2.4-2.25-9.6-7.2-7.8-5.4-9.35-5.4h-.3q1.25 1.3 2.3 2.55 1 1.25 1.85 2.35.8 1.05 1.75 2.2.9 1.1 3.65 5.6l-.3-.2z"
                    id="HaiLon037__Symbol_106_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiLon037__Symbol_102_0_Layer0_0_FILL"
                transform="translate(88.3 56.25)"
                id="HaiLon037__col1n"
            />
            <use
                xlinkHref="#HaiLon037__Symbol_103_0_Layer0_0_FILL"
                transform="translate(123.95 222.55)"
                id="HaiLon037__col1d"
            />
            <use
                xlinkHref="#HaiLon037__Symbol_104_0_Layer0_0_FILL"
                transform="translate(96.5 64.2)"
                id="HaiLon037__col1l"
            />
            <use
                xlinkHref="#HaiLon037__Symbol_105_0_Layer0_0_FILL"
                transform="translate(192.35 56.25)"
                id="HaiLon037__col2n"
            />
            <use
                xlinkHref="#HaiLon037__Symbol_107_0_Layer0_0_FILL"
                transform="translate(252.5 222.9)"
                id="HaiLon037__col2d"
            />
            <use
                xlinkHref="#HaiLon037__Symbol_106_0_Layer0_0_FILL"
                transform="translate(192.5 64.5)"
                id="HaiLon037__col2l"
            />
        </g></g>
    )
}
export default HaiLon037
