import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFD756",
    "col1n": "#FFE69A"
}

function AccHat042({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M312.2 73.65q0-.65.15-1.3L195.05 58.5l-5.8-20.35Q176.2-.5 157 .1v.1q-20.55-2.8-34.2 37.85l-6 21.35L.25 72.5q-.05.35-.05.8 0 .85-.2 1.35Q4.55 92 19.2 100.2q46.95 5.4 79.9 7.1l9.9.5q24 1.1 48 1.1v.1q23-.05 46-1.1 4.95-.25 9.95-.5 32.9-1.7 79.85-7.1 15-8.4 19.4-26.35-.4.25 0-.3z"
                    id="AccHat042__Symbol_260_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M117.4 0q-4.4.15-8.65.35-38.2 1.4-63.6 3.85-10.05.95-17.95 2.05Q3.6 9.35.5 13.65-1.3 18.55 31 24.4q6.7 1.05 14.15 1.75 26.05 2.5 58.1 3.65 2.7.05 5.5.2h.25l3.05.1h.45l.2-1.75-.15.25.4-2.55.15-.25 3.55-23.25.75-2.55m177.65 21.6q9.35-1.7 14.7-4.6 2-1.1 2.3-2.1-1.3-4.8-28.55-8.65-7.6-1.05-17.15-1.95-25.4-2.45-63.6-3.85-4.25-.2-8.65-.35l.75 2.55 3.45 22.4.15.25.4 2.55-.15-.25.35 2.3v.3l3.5-.1h.2q2.8-.15 5.55-.2 32-1.15 58.05-3.65 9.05-.9 17.05-2.25 6.15-1.05 11.65-2.4z"
                    id="AccHat042__Symbol_168_0_Layer0_0_FILL"
                />
                <path
                    fill="#D01515"
                    d="M193.35 90.85v.05l28.45-.25q5.8-.25 11.4-.5 1.2-.05 2.45-.1l-.35-2.3.15.25-.4-2.55-.15-.25-3.1-20.3h-78.9l-3.2 21.05-.15.25-.4 2.55.15-.25-.25 1.45q1.4.05 2.85.15.1-.05.25 0 4.95.2 10.2.4.2 0 .45.05l30.55.3z"
                    id="AccHat042__Layer8_0_FILL"
                />
                <path
                    fill="#FEF0BC"
                    d="M232.5 69.4l-.35-2.25h-79.6l-.35 2.25h80.3m-67.25 21.15h2.45l7.65-12.15 7.25 12.35h2.6l7.7-12.25 7.65 12.35h.75l8.4-12.35 7.95 12.15h1.05l8.25-12 6.85 11.45q.929-.013 1.85-.05l-.35-2.3.15.25-.4-2.55L226.9 72l-8.5 14-8.5-14-8.55 14.1L192.8 72l-8.7 14.35L175.4 72l-8.6 14.2-8.65-14.2-8.6 14.2-.4 2.55.15-.25-.25 1.45q.898.032 1.8.05l7.2-11.75 7.2 12.3z"
                    id="AccHat042__Layer8_1_FILL"
                />
                <g id="AccHat042__Layer8_2_FILL">
                    <path
                        fill="#FF0"
                        d="M219.75 72.05q-.5-.5-1.25-.5t-1.3.5q-.5.55-.5 1.3t.5 1.25q.55.55 1.3.55t1.25-.55q.55-.5.55-1.25t-.55-1.3m-52.6-.5q-.75 0-1.3.5-.5.55-.5 1.3 0 .6.35 1.05.05.1.15.2.55.55 1.3.55t1.25-.55q.3-.25.45-.55.1-.35.1-.7 0-.75-.55-1.3-.5-.5-1.25-.5m34.4 0q-.75 0-1.3.5-.5.55-.5 1.3t.5 1.25q.55.55 1.3.55t1.25-.55q.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5m-17.3 0q-.75 0-1.3.5-.5.55-.5 1.3t.5 1.25q.55.55 1.3.55t1.25-.55q.55-.5.55-1.25t-.55-1.3q-.5-.5-1.25-.5z"
                    />
                    <path
                        fill="#243FA8"
                        d="M164.7 90.55l4.15.05 6.55-10.8 6.65 10.95h4.1l6.65-10.95 6.7 11.05h3.7l6.7-11.05 6.6 10.9 3.8-.05 6.6-10.85 6.3 10.35q1.2-.05 2.45-.1l-.2-1.3-8.55-14.1-8.5 14-8.5-14-8.55 14.1-8.55-14.1L184.1 89l-8.7-14.35-8.6 14.2-8.65-14.2-9 14.85-.1.45q1.4.05 2.85.15l6.25-10.3 6.55 10.75z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat042__Symbol_260_0_Layer0_0_FILL"
                transform="translate(36.35 3.8)"
                id="AccHat042__col1n"
            />
            <use
                xlinkHref="#AccHat042__Symbol_168_0_Layer0_0_FILL"
                transform="translate(36.6 60.15)"
                id="AccHat042__col1d"
            />
            <use xlinkHref="#AccHat042__Layer8_0_FILL" />
            <use xlinkHref="#AccHat042__Layer8_1_FILL" />
            <use xlinkHref="#AccHat042__Layer8_2_FILL" />
        </g></g>
    )
}

export default AccHat042
