import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4C7E00",
    "col1n": "#6EB800",
    "col2n": "#000000"
}

function ObjToy030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M47.15 38.2L60.65 0h-2.5l-40 7.2-.05.1L0 38.2q24.05-3.85 47.15 0m105.8 0l-18.1-30.9-.05-.1h-.5L94.8 0h-2.35l2.2 6.7v-.1l11.15 31.6q23.3-2.75 47.15 0z"
                    id="ObjToy030__Symbol_119_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M29.95 2.35Q13.6-5.1 2.45 11.2q-.55.8-1.1 1.7L0 15.2h47.15q-9.2-9.25-17.2-12.85M150.5 11.2Q139.35-5.1 123 2.35q-8 3.6-17.2 12.85h47.15l-1.35-2.3q-.55-.9-1.1-1.7z"
                    id="ObjToy030__Symbol_72_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M65.9 53h-3.65q-.5 3.6 0 7.2h3.65l.15-.45L65.9 53m-31.55-2.75q-2.25 7.8 0 14.95H57.7q2.1-7.55 0-14.95-11.5-4.75-23.35 0M25.7 53v6.65l.2.55h3.85q5.45-3.8 0-7.2H25.7m-9.35-40.7q-.05-.4 0-.75-.15-6.75 6.1-8.3Q20.9-.6 14.65.1q-.1.05-.2.15V.4q1.3 1.9.95 5.4-.4 3.75-2 7.15Q8.8 24.35.5 24l-.5.1q.05.1.1.25.3.85.95 1.8.6.9 1.4 1.6 1.15.7 1.75 1-.05.05-.05.1.5.25 1.05.4 2.6.6 5.25-1.4 1-.75.95-2-.05-1.45-.45-2.6 2.45-.5 3.55-2.05 1-1.45 1-4.15v-.55q1.2-.1 2.35-.75-1.3-1.75-1.5-3.45m126.2 47.9l.2-.55V53h-4.05q-2.8 3.8 0 7.2h3.85m-40 0h3.65q1.8-3.6 0-7.2h-3.65l-.15 6.75.15.45m8.2-9.95q-3.35 7.9 0 14.95h23.35q3.05-7.45 0-14.95-11.5-1.9-23.35 0m53.55-21.4q-.15-.35.3-.4.4-.1.8-.3.35-.2.35-.45t.25.05q.8-.7 1.4-1.6.75-1.1 1.05-2.05-1.2-.2-2.35-.6-8.05-2.65-10.8-10.15-1.85-3.55-2.25-7.55-.35-3.5.95-5.4V.35v-.1q-.05-.05-.1-.05l-.1-.1q-6.25-.7-7.75 3.35 2.55.7 3.95 2.05 3.6 3.8 1.9 8.4-.35 1.05-1.05 1.95 1.05.55 2.15.65v.25q0 6.05 4.5 6.6-.4 1.1-.45 2.5-.05 1.25.95 2 2.65 2 5.25 1.4.55-.15 1.05-.4z"
                    id="ObjToy030__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill="#999"
                    d="M272.5 308.95q-.05.3.1.65.05 0 .05.1l32.5 45.45-7.1 4.4q-.35.2-.45.65-.05.4.15.75t.6.45q.45.05.75-.15l7.2-4.5 3.45 4.85q.25.3.65.4.45.05.75-.2.35-.25.4-.6.1-.45-.15-.75l-3.4-4.75 6.1-3.85q.4-.2.45-.6.1-.45-.1-.75-.2-.4-.65-.45-.4-.1-.75.1l-6.2 3.9-32.55-45.5v-.05q-.25-.3-.65-.35-.4-.1-.75.15-.3.25-.4.65M219.05 331h23.35v14.95h4.6v-20.7h-32.5v20.7h4.55V331m-81 14.95h4.6V331H166v14.95h4.55v-20.7h-32.5v20.7m-67.1 5.9l6.1 3.85-3.4 4.75q-.25.3-.15.75.05.35.4.6.3.25.75.2.4-.1.65-.4l3.45-4.85 7.2 4.5q.3.2.75.15.4-.1.6-.45.2-.35.15-.75-.1-.45-.45-.65l-7.1-4.4 32.5-45.45q0-.1.05-.1.15-.35.1-.65-.1-.4-.4-.65-.35-.25-.75-.15-.4.05-.65.35v.05l-32.55 45.5-6.2-3.9q-.35-.2-.75-.1-.45.05-.65.45-.2.3-.1.75.05.4.45.6z"
                    id="ObjToy030__Layer5_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToy030__Symbol_119_0_Layer0_0_FILL"
                transform="translate(116.05 333.75)"
                id="ObjToy030__col1n"
            />
            <use
                xlinkHref="#ObjToy030__Symbol_72_0_Layer0_0_FILL"
                transform="translate(116.05 356.75)"
                id="ObjToy030__col1d"
            />
            <use
                xlinkHref="#ObjToy030__Symbol_32_0_Layer0_0_FILL"
                transform="translate(108.3 280.75)"
                id="ObjToy030__col2n"
            />
            <use xlinkHref="#ObjToy030__Layer5_0_FILL" />
        </g></g>
    )
}

export default ObjToy030
