import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1n": "#000000",
    "col2n": "#666666"
}

function ShiSho004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M125.35 22.25l-2.3-11.7h.05L123 10q-.35-.25-.4-.3l-.1-.1.45.3-.05-.2q-1.9-1.45-2.6-2-1.3-1-3.05-1.95-1.75-1.05-2.75-1.55Q106.1-.85 95.15.95q-1.95.3-3.85.75-8.5 1.8-16.05 6.7l-9.05 6.35-9.95-7.3q-.4-.25-.75-.5-8.7-5.7-18.6-6.8-10.05-.95-19.8 4.9-2.4 1.15-4.6 2.85l-.15.15V8l-2.5 1.45L7.5 21.5q-.55 2.7-1.1 5.45l-3.45 14.3q-1 3.9-1.95 7.8l-.95 3.6v.25l-.05.05h.2q.25.1.5.15H.85q2.05.5 4.15.95 61.75 14 122.7 0 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.65-3.1-1.3-6.15M9.85 10.2q-.05-.05-.1-.05l.1-.05v.1z"
                    id="ShiSho004__Symbol_12_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M0 .2l.2.6q1.65 5.05 2.35 10.7Q4 25.05.9 40.85q0 .1-.05.25l2.45.45.05-.3q3.4-15.5 1.95-28.55Q4.4 5.45.1 0q.05.05-.1.2m104.55 0q-.15-.15-.1-.2-4.3 5.45-5.2 12.7-1.45 12.85 1.85 28.05l.1.5.05.3 2.45-.45q-.1-.4-.15-.8-2.95-15.5-1.55-28.8.75-6 2.55-11.3z"
                    id="ShiSho004__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.8 34.1q-.15-.15-.35-.1-.2.05-.35.2-.15.15-.1.35.05.2.2.35l3.75 2.9L.2 40.7q-.15.1-.2.35-.05.2.1.35.1.15.35.2.2.05.35-.1l3.95-3.05 4.2 3.25q.15.15.35.1.2-.05.35-.2.15-.15.1-.35-.05-.2-.2-.35L5.6 37.8l3.95-3.1q.15-.15.2-.35.05-.2-.1-.35-.15-.15-.35-.2-.2-.05-.35.1l-4.2 3.3L.8 34.1m0-11.25q-.15-.15-.35-.1-.2.05-.35.2-.15.15-.1.35.05.2.2.35l3.75 2.95L.2 29.5q-.15.1-.2.35-.05.2.1.35.1.15.35.2.2.05.35-.1l3.95-3.05 4.2 3.2q.15.15.35.1.2-.05.35-.2.15-.15.1-.35-.05-.2-.2-.35L5.6 26.6l3.95-3.15q.15-.15.2-.35.05-.2-.1-.35-.15-.15-.35-.2-.2-.05-.35.1L4.75 26 .8 22.85m0-11.3q-.15-.15-.35-.1-.2.05-.35.2-.15.15-.1.35.05.2.2.35l3.75 2.9-3.75 2.9q-.15.1-.2.35-.05.2.1.35.1.15.35.2.2.05.35-.1l3.95-3.05 4.2 3.25q.15.15.35.1.2-.05.35-.2.15-.15.1-.35-.05-.2-.2-.35l-3.95-3.1 3.95-3.1q.15-.15.2-.35.05-.2-.1-.35-.15-.15-.35-.2-.2-.05-.35.1l-4.2 3.3-3.95-3.1M9.65.2Q9.5.05 9.3 0q-.2-.05-.35.1l-4.2 3.35L.8.3Q.65.15.45.2.25.25.1.4q-.15.15-.1.35.05.2.2.35l3.75 2.95L.2 6.95q-.15.1-.2.35-.05.2.1.35.1.15.35.2.2.05.35-.1L4.75 4.7l4.2 3.2q.15.15.35.1.2-.05.35-.2.15-.15.1-.35-.05-.2-.2-.35L5.6 4.05 9.55.9q.15-.15.2-.35.05-.2-.1-.35z"
                    id="ShiSho004__Symbol_69_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho004__Symbol_12_0_Layer0_0_FILL"
                transform="translate(126.1 256.55)"
                id="ShiSho004__col1n"
            />
            <use
                xlinkHref="#ShiSho004__Symbol_51_0_Layer0_0_FILL"
                transform="translate(140.25 269.1)"
                id="ShiSho004__col1d"
            />
            <use
                xlinkHref="#ShiSho004__Symbol_69_0_Layer0_0_FILL"
                transform="translate(187.65 271.75)"
                id="ShiSho004__col2n"
            />
        </g></g>
    )
}

export default ShiSho004
