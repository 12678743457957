import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiMot036({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M35.55.35q0-.15-.1-.25-.15-.15-.3-.1H.35Q.2-.05.1.1q-.15.1-.1.25v34.8q-.05.15.1.3.1.1.25.1h34.8q.15 0 .3-.1.1-.15.1-.3V.35m-.75.4V11.6H23.95V.75H34.8m-11.6 0V11.6H12.35V.75H23.2m0 11.6V23.2H12.35V12.35H23.2m.75 11.6H34.8V34.8H23.95V23.95m0-11.6H34.8V23.2H23.95V12.35M11.6.75V11.6H.75V.75H11.6M.75 23.95H11.6V34.8H.75V23.95m10.85-.75H.75V12.35H11.6V23.2m.75 11.6V23.95H23.2V34.8H12.35m1.3-5.25q0 1.65 1.15 2.8t2.85 1.2q1.65-.05 2.8-1.2t1.15-2.8q0-1.7-1.15-2.85-1.15-1.15-2.8-1.15-1.7 0-2.85 1.15-1.15 1.15-1.15 2.85m-3.7 0q0-1.7-1.15-2.85-1.2-1.15-2.85-1.15-1.65 0-2.8 1.15-1.2 1.15-1.2 2.85 0 1.65 1.2 2.8 1.15 1.15 2.8 1.2 1.65-.05 2.85-1.2 1.15-1.15 1.15-2.8m16.6-8.95q1.15 1.15 2.85 1.2 1.6-.05 2.8-1.2 1.15-1.15 1.15-2.8 0-1.55-1-2.7l-.15-.15Q31 13.8 29.4 13.8q-1.7 0-2.85 1.15l-.15.15q-1 1.15-1 2.7 0 1.65 1.15 2.8m5.65 11.75q1.15-1.15 1.15-2.8 0-1.7-1.15-2.85-1.2-1.15-2.8-1.15-1.7 0-2.85 1.15-1.15 1.15-1.15 2.85 0 1.65 1.15 2.8t2.85 1.2q1.6-.05 2.8-1.2M21.6 6.2q0-1.65-1.15-2.8-1.15-1.2-2.8-1.2-1.7 0-2.85 1.2-1.15 1.15-1.15 2.8t1.15 2.85q1.15 1.15 2.85 1.15 1.65 0 2.8-1.15 1.15-1.2 1.15-2.85z"
                    id="ShiMot036__Symbol_42_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot036__Symbol_42_0_Layer0_0_FILL"
                transform="translate(174.85 262.1)"
                id="ShiMot036__col1n"
            />
        </g></g>
    )
}

export default ShiMot036
