import { AssetSvgProps } from "../../../shared/assets"

function ShiMot093({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFCE00"
                    d="M159.2 269.6v12.15q33.25 9.013 66.5 0V269.6q-33.25-7.526-66.5 0z"
                    id="ShiMot093__Layer1_0_FILL"
                />
                <g id="ShiMot093__Layer1_1_FILL">
                    <path fill="red" d="M159.2 257.8v11.8h66.5v-11.8h-66.5z" />
                    <path
                        fill="#006431"
                        d="M159.2 281.75v11.85h66.5v-11.85h-66.5z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiMot093__Layer1_0_FILL" />
            <use xlinkHref="#ShiMot093__Layer1_1_FILL" />
        </g></g>
    )
}

export default ShiMot093
