import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#A37831"
}

function ShiMot028({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M3.5 47.9V23.45h12.9V16.5H3.5V3.6h-6.95v12.9H-16.4v6.95h12.95V47.9H3.5z"
                    id="ShiMot028__Symbol_34_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot028__Symbol_34_0_Layer0_0_FILL"
                transform="translate(192.5 258.05)"
                id="ShiMot028__col1n"
            />
        </g></g>
    )
}

export default ShiMot028
