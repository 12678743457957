import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#411F00",
    "col1n": "#773900",
    "col1s": "#000000",
    "col2d": "#E2C700",
    "col2n": "#F3E200"
}

function LegSho011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M137.2 4.75l-.15-.15.05-4.6H10.3l-.05 4.9-.05-.1q-.094-.047-.2 0-1.1.1-2.15.2-6.4.95-7.75 4.5L0 11.65l.5.5h146.3l.5-.5-.1-2.45q-1.45-3.25-7.65-4.2-1.05-.1-2.15-.2-.1-.05-.2-.05z"
                    id="LegSho011__Symbol_20_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M44.85 4.8q.9 0 1.65.05 2.55-1.9 0-3.6H.05L0 4.85q.25 0 .55-.05h44.3M64.5 0h-1.9v10.55q.1-.05.2-.05.3-.1.55-.2.05 0 .1-.05l.05.05h.05q.4.15.95.35V0m61.55 4.8q.3.05.55.05l-.05-3.6H80.1q-2.55 1.7 0 3.6.75-.05 1.65-.05h44.3z"
                    id="LegSho011__Symbol_21_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M147.3 4.3V1.4l-64.85-.1Q77.4 1.1 74.8.4h-.05Q74.2.2 73.8.05h-.05L73.7 0q-.05.05-.1.05-.25.1-.55.2-.1 0-.2.05-2.6.8-7.9 1L0 1.4v2.9h64.25q5.95 0 8.6-1.3.4-.2.75-.45l.1-.1.1.1q.4.3.95.55 2.65 1.2 8.4 1.2h64.15z"
                    id="LegSho011__Symbol_22_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M10 4.8V0H0v4.8h10M8.8.95V3.8H1.15v-.95H4.8v-1.1H1.15v-.8H8.8M76.6 4.8V0h-10v4.8h10m-4.8-1.95h3.65v.95H67.8V.95h7.65v.8H71.8v1.1z"
                    id="LegSho011__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M0 1.1h3.65V0H0v1.1M70.65 0v1.1h3.65V0h-3.65z"
                    id="LegSho011__Symbol_24_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho011__Symbol_20_0_Layer0_0_FILL"
                transform="translate(118.8 322.4)"
                id="LegSho011__col1n"
            />
            <use
                xlinkHref="#LegSho011__Symbol_21_0_Layer0_0_FILL"
                transform="translate(129.05 322.4)"
                id="LegSho011__col1d"
            />
            <use
                xlinkHref="#LegSho011__Symbol_22_0_Layer0_0_FILL"
                transform="translate(118.8 332.65)"
                id="LegSho011__col1s"
            />
            <use
                xlinkHref="#LegSho011__Symbol_23_0_Layer0_0_FILL"
                transform="translate(154.05 323.3)"
                id="LegSho011__col2n"
            />
            <use
                xlinkHref="#LegSho011__Symbol_24_0_Layer0_0_FILL"
                transform="translate(155.2 325.05)"
                id="LegSho011__col2d"
            />
        </g></g>
    )
}

export default LegSho011
