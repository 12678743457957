import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#666666",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2l": "#FF62B6",
    "col2n": "#FF008C",
    "col2s": "#A10058"
}
function HaiHig033({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M86.95 96.65q0-10.75 4.9-25.2 2.1-6.3 3.3-12.2.05-.45.15-.85.15-1.55.15-2.9 0-1.6-.05-3.15V50.5q0-20.9-14.95-35.7Q65.5 0 44.4 0 23.25 0 8.3 14.8q-2.15 2.1-3.95 4.35Q2.2 21.3.65 23.6q-.35.65-.65 1.35.1-.05.3-.05.55-.1 1.2-.1 1.6 0 2.95.5.9.3 1.7.85.8.5 1.5 1.2.45.5.85 1 1.05 1.4 1.45 3.1.7.05 1.4.2.95.2 1.8.7.8.45 1.55 1.2l.3.3q11.2-7.7 22.65-4 8.4 4.45 12.95 10 7.6 9.2 8.05 20.9.45 11.65-2.8 15.4 1.6.3-2.7 8.95-4.3 8.65.45 35.85 4.75 27.15 5.7 19.2 9.45 19.65 24.5 19.9 27.55 1.3 37.15-18.15-6.7 4.95-14.05 5.3-9.2-3-16-5.2 10.4-.1 19.6-5.75 12.2-7.5 12.2-21.15 0-.15.05-.25v-.15q.2-1.95-.05-3.8-.25-2.25-1.05-3.3-.25 1.1-.6 2.2-.55 1.5-1.3 2.85-1.1 2-2.75 3.8-5.1 5.75-12.4 5.75-2.55 0-4.8-.7-1-.3-1.9-.7-1.85-1.05-3.3-2.05-1.3-1.05-2.45-2.3-5.1-5.7-5.1-13.8v-.75q-.1-2.35-.1-5.25z"
                    id="HaiHig033__Symbol_43_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M64.45 95.6q-.65-3.95-.35-13.65-2.15 5.65-3.3 10.75-.55 2.5-.65 4.55-.05.65-.05 1.3 0 9.6 5.45 16.15 2.7 3.05 5.2 4.45 2.45 1.4 2.4 1.3-.05-.15 0-.15 2.05.75 4.6.9h2.55q4.2-.4 6.6-1.55 1.45-.7 4.2-2.55.65-.4 1.25-1.3-1.55.5-2.3.7-1.25.35-4.85.35-8.2 0-13.2-4.15-.85-.7-1.6-1.5-1.4-1.55-2.3-3.45-1.05-2.15-1.8-3.4-.75-1.3-.95-3.05-.25-1.75-.9-5.7m-22.7-84.5q7.2 3.55 13.65 9.65 6.4 6.05 9.15 15.85t2.65 15.6q-.1 5.75-1 9.9 2.15-5.7 3.15-8.75Q71.5 47 71.5 41q0-15.1-11-27.5Q48.5 0 31.25 0q-3.95 0-6.8.15-1 .15-1.6.25-2.1.4-3.45.75-2.05.55-3.75 1-1.1.4-2.15.85-5.1 2.2-8.75 4.4-.95.55-3.45 2.85-.35.3-.6.55-.1.05-.15.15-.3.35-.55.65 2.15-1.05 7.25-2.45 3.45-.95 6.3-1.4h.2q1.2-.2 2.35-.3h1.95q.35-.05.7-.05l.25-.05q4.95-.25 9.15-.05 6.35.25 13.6 3.8z"
                    id="HaiHig033__Symbol_42_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M15.65 3.55q-.4-.5-.85-1-.7-.7-1.5-1.2Q12.5.8 11.6.5 10.25 0 8.65 0 8 0 7.45.1q-.2 0-.3.05H7Q4.5.6 2.55 2.55 0 5.1 0 8.65q0 1.2.3 2.3.4 1.65 1.5 3 .35.45.75.85 2.55 2.5 6.1 2.5.85 0 1.7-.15 2.5-.45 4.45-2.35 2.5-2.55 2.5-6.15v-.7l-.2-1.3q-.4-1.7-1.45-3.1m114.2 92.6q.25-2.9.05-6.05v-.05q-.05.1-.05.25 0 13.65-12.2 21.15-9.35 5.75-19.95 5.75-12.85 0-22.1-9.8-.9-.9-1.7-1.9-5.8-7-7.45-12.95-3.8-8.5-4.05-16.05-.2-7.55.65-14.1.8-6.55 2.2-10.65 1-4.15 1.6-8 2.8-19.35-6.2-30.45Q55.3 7.65 48.8 5.05l-4.25-1.3q-11.2-2.4-22.4 5.3.25.3.55.65 29.8-1.3 33 24.4.2 1.6.3 3.35.4 3.1.3 6.7-.45 11.4-4.55 23.35-.8 2.4-1.55 4.85-10.35 23.95 0 47.9 1.35 2.5 3.15 4.95 3.1 4.3 7.5 7.5 3.5 2.6 9 5.25 7.45 3.7 14.5 5.05 1.25.2 2.55.4 10.6 1.45 20.25-2.45 3-1.25 5.9-3 2-1.1 3.9-2.4 10.4-7.2 11.15-16.6v-1.85q-9.6 19.45-37.7 16.25-14.6-2.05-24.35-18.85 2.7 3.15 5.7 5.6 7.2 5.85 16.1 7.2l.5.1q5.25.7 11.05-.15l1.2-.15q1.85-.35 3.7-.8 2.45-.45 4.75-1.35.4-.15.85-.3 2.1-.95 4.15-2.25 3.45-2.35 6.55-5.9 3.3-3.8 5.5-8.2 2.75-5.55 3.75-12.15z"
                    id="HaiHig033__Symbol_40_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M11.6 8.55Q11 1.5 10.7 0q.2 1.6.3 3.35.4 3.1.3 6.7-.45 11.4-4.55 23.35-.8 2.4-1.55 4.85-10.35 23.95 0 47.9 1.35 2.5 3.15 4.95 3.1 4.3 7.5 7.5 3.5 2.6 9 5.25 7.45 3.7 14.5 5.05 1.25.2 2.55.4 10.6 1.45 20.25-2.45-25.1 4.85-42-15.15-1.05-1.45-2.1-2.95-5.65-8.25-7.95-22.1-.05-.25-1.1-3.05 1.55 3.3 4.6 6.05 4.25 3.8 10.4 6.9-3.25-6.25-4.5-9.05-1.6-1.25-4.85-9.75-3.25-8.5-3.05-20.45V8.55z"
                    id="HaiHig033__Symbol_41_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M15.65 3.55q-.4-.5-.85-1-.7-.7-1.5-1.2Q12.5.8 11.6.5 10.25 0 8.65 0 8 0 7.45.1q-.2 0-.3.05H7Q4.5.6 2.55 2.55 0 5.1 0 8.65q0 1.2.3 2.3.4 1.65 1.5 3 .35.45.75.85 2.55 2.5 6.1 2.5.85 0 1.7-.15 2.5-.45 4.45-2.35 2.5-2.55 2.5-6.15v-.7l-.2-1.3q-.4-1.7-1.45-3.1z"
                    id="HaiHig033__Symbol_38_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M13.5 3.6q-.5-.8-1.2-1.5Q10.2 0 7.2 0q-3 0-5.1 2.1Q0 4.2 0 7.2q0 3 2.1 5.1 1.95 1.95 4.7 2.1-.3-1.1-.3-2.3 0-3.55 2.55-6.1Q11 4.05 13.5 3.6m14.85 8.6q-.75-.75-1.55-1.2-.85-.5-1.8-.7-.7-.15-1.4-.2l.2 1.3v.7q0 3.6-2.5 6.15-1.95 1.9-4.45 2.35.45.95 1.3 1.8 2.1 2.1 5.1 2.1 3 0 5.1-2.1 2.1-2.1 2.1-5.1 0-2.35-1.25-4.15-.3-.35-.55-.65l-.3-.3z"
                    id="HaiHig033__Symbol_39_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig033__Symbol_43_0_Layer0_0_FILL"
                transform="translate(234.65 34.75)"
                id="HaiHig033__col1n"
            />
            <use
                xlinkHref="#HaiHig033__Symbol_42_0_Layer0_0_FILL"
                transform="translate(249.85 45.25)"
                id="HaiHig033__col1l"
            />
            <use
                xlinkHref="#HaiHig033__Symbol_40_0_Layer0_0_FILL"
                transform="translate(227.5 59.55)"
                id="HaiHig033__col2n"
            />
            <use
                xlinkHref="#HaiHig033__Symbol_41_0_Layer0_0_FILL"
                transform="translate(272.5 93.65)"
                id="HaiHig033__col2d"
            />
            <use
                xlinkHref="#HaiHig033__Symbol_38_0_Layer0_0_FILL"
                transform="translate(227.5 59.55)"
                id="HaiHig033__col2l"
            />
            <use
                xlinkHref="#HaiHig033__Symbol_39_0_Layer0_0_FILL"
                transform="translate(221 56.1)"
                id="HaiHig033__col2s"
            />
        </g></g>
    )
}
export default HaiHig033
