import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#007070",
    "col1n": "#00AFAF",
    "col2d": "#E1E1E1",
    "col2n": "#FFFFFF"
}

function AccHat023({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M167.4 83.9q-.1-.95-.15-1.85-.05-.9-2.35-22-2.25-21.15-5.4-30.05-6.45-16.05-26.5-23.2-4.75-1.7-10.3-2.9-2.65-.75-6.25-1.4-3.95-.7-8.9-1.3-24.95-2.4-51.3 0-4.8.8-9.55 1.95-6.45 1.3-11.9 3.25-22.5 8-27.85 27.25L.3 83.9l-.3-.1 2.05.75.05.05v-.05l.75.15q.15 0 .3.05.15 0 .35.05 62.35 16.35 151.45 2.1l.2-.05q2.05-.15 2.2-.15.1-.05.2-.05 2.1-.15 4.25-.35.25 0 .6-.05 4.65-.4 5-2.1v-.1-.15z"
                    id="AccHat023__Symbol_241_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M132.65 6.2q.75-.1 1.45-.1 2-.15 4-.15V5.8q-.8-5.9-8.85-5.8-3.45.05-6.05 1.55-2.7 1.55-2.7 4l.15.6v.1h2.25q.7-.05 1.3 0h3q1.05 0 2.05.1h.7q.9-.1 1.95-.15h.75m-3-2.65h.45q-.15.003-.15.1-.194-.097-.3-.1m72.45 89.7l-18.25 1.25h-.35q-.35 0-.65.05-1.15.05-2.15.15-.5 0-1 .05-51 2.8-95.8-.55-8.2-.55-16.2-1.4h-.05q-.5-.05-.95-.1h-.05v-.1l-3.1-.25v.05q-.5-.1-.95-.15-1.3-.15-2.55-.3-3.8-.45-7.45-.9h-.2q-.25-.05-.5-.05-.15-.05-.3-.05l-.75-.15v.05l-.05-.05-2.05-.75-44.45 12.1q-.65.15-1.2.35l-1.2.4q-.65.25-1.1.45-.048.048-.1.05h.05l-.3.2q-.9 1.1 0 2.2 2.65 1.5 12.85 2.95 120.95 14.7 174.25-1.05 17.4-4.7 14.85-13.75-.2-.35-.3-.7z"
                    id="AccHat023__Symbol_66_0_Layer0_0_FILL"
                />
                <g
                    id="AccHat023__Symbol_153_0_Layer0_0_FILL"
                    fill={colors.col1d}
                >
                    <path d="M50.15 1.3l-1.7.4Q55.3.25 55.8.15 50.1.2 46.05.7q-.8.05-1.6.15-6.45.9-12.8 2.45-1.95.45-3.9 1Q20 6.15 16.3 9.85 8.15 18 7.35 31.7L0 86.15l2.15.15v.05l.95.05h.05l7.35-57.95q1.75-9.8 7.55-16.15 2.9-3.65 8.25-4.85Q37.95 3.9 50.15 1.3M86.05 3q-.75-.2-1.5-.35Q72.1-.4 62.8.05h-.85V.2q13.85 2.15 29.9 6.95.4.1.8.25 5.1 1.35 8.45 5 5.7 6.3 7.4 16.05l7.65 59.95q1-.1 2.15-.15.3-.05.65-.05h.35l-7.6-56.5q-.85-13.65-8.9-21.7-2.6-3.25-7.55-4.45-4.6-1.5-9.2-2.55zM61.9.2h.05V.05L61.9.2z" />
                </g>
                <g
                    id="AccHat023__Symbol_15_0_Layer0_0_FILL"
                    fill={colors.col2d}
                >
                    <path d="M202.1.8l.35-.05q-.05 0-.05-.05-.2-.35-.3-.7l-4.85.35q2.275-.087 4.55-.25.1.35.3.7z" />
                    <path d="M202.45.75l-.35.05q-.7 4.6-9.7 8.5-.55.25-1.15.45-34.05 9.55-76.8 9.55Q50.1 17.85.75 10.15l-.3.2q-.9 1.1 0 2.2 2.65 1.5 12.85 2.95 120.95 14.7 174.25-1.05 17.35-4.7 14.9-13.7z" />
                </g>
            </defs>
            <use
                xlinkHref="#AccHat023__Symbol_241_0_Layer0_0_FILL"
                transform="translate(107.2 16.2)"
                id="AccHat023__col1n"
            />
            <use
                xlinkHref="#AccHat023__Symbol_153_0_Layer0_0_FILL"
                transform="translate(123 16.25)"
                id="AccHat023__col1d"
            />
            <use
                xlinkHref="#AccHat023__Symbol_66_0_Layer0_0_FILL"
                transform="translate(58.45 9.95)"
                id="AccHat023__col2n"
            />
            <use
                xlinkHref="#AccHat023__Symbol_15_0_Layer0_0_FILL"
                transform="translate(58.45 103.2)"
                id="AccHat023__col2d"
            />
        </g></g>
    )
}

export default AccHat023
