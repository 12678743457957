import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col1s": "#C2C2C2",
    "col2n": "#AD5300",
    "col2s": "#271200"
}

function AccHat040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M245.1 84.25q.15-1.9.1-4.15-.2-15.6-10.3-21.1-1.1-.6-2.35-1.1-.15-.1-.35-.15-15.9-6.4-27.1-.45-2 1-3.85 1.7-.2.1-.4.15-.15.05-.35.15-3.25 1.15-6.45 2.7l-1.2.6q-.05 0-.1.05-.35-.95-.75-1.9-1.4-3.15-2.9-6.05v-.05q-2.7-5.85-6.85-14.25-4.1-8.4-11.05-11.8-6.8-3.3-15.1-5.35h-.9q-1.9.1-3.55.25-.2-.5-.35-.95v-.05h-.15q-1.25-3.05-1.8-4-.8-1.55-1.55-3.1l-.4-.8q-.9-2.25-2.55-4.2Q141.25 6 134.3 3q-11.7-5.95-23.35 0-7 3-10.6 7.4-1.65 1.95-2.55 4.2l-.4.8q-.75 1.55-1.55 3.1-.55.95-1.8 4h-.15v.05q-.25.65-.45 1.35-1.55-.35-3.2-.7-.55.05-1.15.05Q80.8 25.3 74 28.6 67.05 32 62.95 40.4q-4.15 8.4-6.85 14.25v.05q-1.5 2.9-2.9 6.05l-.35 2.1q-.25-.15-.5-.25l-1.2-.6q-3.2-1.55-6.45-2.7-.2-.1-.35-.15-.2-.05-.4-.15-1.85-.7-3.35-1.45-11.7-6.2-27.6.2-.2.05-.35.15-1.25.5-2.35 1.1Q.2 64.5 0 80.1q-.05 2.25.1 4.15Q1.3 94.7 27.4 92.2q.35-.05.7-.05.05-.05.15 0 .2-.05.5-.05 7.8-.15 15.6.05 2.85.05 5.7.15 6.35.2 12.65.65 1.2.1 2.45.2 9.6.95 21.95 4.15 7.35 1.9 16.25 3.25 19.75 3.9 38.5 0 8.9-1.35 16.25-3.25 12.35-3.2 21.95-4.15 1.25-.1 2.45-.2 6.3-.45 12.65-.65 2.85-.1 5.7-.15 7.8-.2 15.6-.05.3 0 .5.05.1-.05.15 0 .35 0 .7.05 26.1 2.5 27.3-7.95M80.75 45.9q-.55.45-1.1.95-.15.1-.3.25.55-.8.8-1.15.05 0 .6-.05z"
                    id="AccHat040__Symbol_258_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M6.45 22.05q-.4.85-.75 1.7-1.75 3.45-3.6 6.95-.35.6-.6 1.2-.3.75-.4 1.4l-.3.4q-.1.05-.15.15-.35.4-.65.8.35.15.65.3l.7.4q.8.4 5.65 2.65 1.4-3.15 2.9-6.05v-.05q2.7-5.85 6.85-14.25 4.1-8.4 11.05-11.8Q34.6 2.55 42.9.5 19.6-1.8 14.85 6q-1.05 1.2-1.9 2.8-.15.2-.25.4-3.3 6.2-6.25 12.85m140.65 1.7q-.35-.85-.75-1.7-2.95-6.65-6.25-12.85-.1-.2-.25-.4-.85-1.6-1.9-2.8Q133.2-1.8 109.9.5q8.3 2.05 15.1 5.35 6.95 3.4 11.05 11.8 4.15 8.4 6.85 14.25v.05q1.5 2.9 2.9 6.05 4.85-2.25 5.65-2.65l.7-.4q.3-.15.65-.3-.3-.4-.65-.8-.05-.1-.15-.15l-.3-.4q-.1-.65-.4-1.4-.25-.6-.6-1.2-1.85-3.5-3.6-6.95z"
                    id="AccHat040__Symbol_214_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M.2 36.5l-.2.3q3.25 1.15 6.45 2.7l1.2.6q2.6 1.2 5.2 2.3 3.05 1.35 6.15 2.55 2.5 1 5.65 2.5.1.05.25.1l3.35-.3q-.25-.15-.5-.25-8.45-3.85-14.05-6.35-3-1.4-5.2-2.4Q3.65 36 2.85 35.6l-.7-.4q-.3-.15-.65-.3L.2 36.5M48.95 0q-.15.05-.35.2-.9.4-3.05.5-5.85 15.4-8.25 19.45-2.45 4-1.85 3.25l-.8 1.2q.15-.15.3-.25 2.1-1.85 3.7-3.75 1.55-1.9 2.5-3.35 2.2-3.55 4.1-7.4l.3-.6q0-.1.1-.2Q47.1 5.8 48.4 2.4q.05-.3.15-.55.05-.15.15-.3h-.05l.05-.05q.25-.75.5-1.45V0h-.25m57.65.05q.25.7.5 1.45l.05.05h-.05q.1.15.15.3.1.25.15.55 1.3 3.4 2.75 6.65.1.1.1.2l.3.6q1.9 3.85 4.1 7.4.95 1.45 2.5 3.35 1.6 1.9 3.7 3.75.15.1.3.25-1.05-1.55-2.2-4.1Q112.7 8.85 110.5.75 108.15.6 107.2.2q-.2-.15-.35-.2h-.25v.05m24.3 47.5q.15-.05.25-.1 3.15-1.5 5.65-2.5 3.1-1.2 6.15-2.55 2.6-1.1 5.2-2.3l1.2-.6q3.2-1.55 6.45-2.7l-.2-.3-1.3-1.6q-.35.15-.65.3l-.7.4q-.8.4-5.65 2.65-2.2 1-5.2 2.4-5.6 2.5-14.05 6.35-.25.1-.5.25l3.35.3z"
                    id="AccHat040__Symbol_124_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M86.8 8.7q6.3-2.85 11.5-5.2-3.4-.5-6.8-.9l-2.25-.2Q83.7 1.75 78.2 1.3q-.3-.05-.6-.05Q63.2.05 49.3 0H49Q35.1.05 20.7 1.25q-.3 0-.6.05-5.5.45-11.05 1.1l-2.25.2Q3.4 3 0 3.5q5.2 2.35 11.5 5.2 18.45 8.4 37.5 8.35h.3q19.05.05 37.5-8.35z"
                    id="AccHat040__Symbol_81_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M106 .3l-3.35-.3q-.25.05-.5.2-5.2 2.35-11.5 5.2-18.45 8.4-37.5 8.35h-.3q-19.05.05-37.5-8.35Q9.05 2.55 3.85.2 3.6.05 3.35 0L0 .3q4.55 2.1 10.35 5.15 10 5.2 29.35 9.75.1.05.2.05 3.5.85 6.85 1.35 6.25.8 12.5 0 3.3-.5 6.85-1.35.1 0 .25-.05 19.3-4.55 29.3-9.75Q101.45 2.4 106 .3z"
                    id="AccHat040__Symbol_4_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat040__Symbol_258_0_Layer0_0_FILL"
                transform="translate(69.9 27)"
                id="AccHat040__col1n"
            />
            <use
                xlinkHref="#AccHat040__Symbol_214_0_Layer0_0_FILL"
                transform="translate(116.1 49.75)"
                id="AccHat040__col1l"
            />
            <use
                xlinkHref="#AccHat040__Symbol_124_0_Layer0_0_FILL"
                transform="translate(114.6 49.5)"
                id="AccHat040__col1s"
            />
            <use
                xlinkHref="#AccHat040__Symbol_81_0_Layer0_0_FILL"
                transform="translate(143.35 93.45)"
                id="AccHat040__col2n"
            />
            <use
                xlinkHref="#AccHat040__Symbol_4_0_Layer0_0_FILL"
                transform="translate(139.5 96.75)"
                id="AccHat040__col2s"
            />
        </g></g>
    )
}

export default AccHat040
