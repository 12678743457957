import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#411F00",
    "col1n": "#773900",
    "col1s": "#000000",
    "col2n": "#E5B000"
}

function ShiSho078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M123.6.6q-.1.1-.2.15-1.85 1.4-4.15 2.65-2.8 1.6-5.9 2.75l-1.1.4Q103.85 9.4 93.3 9.4q-15.25 0-26.05-6-.35-.2-.55-.5Q64.25 4.6 56.5 7q-.1 0-.15.05l-1.35.4q-.15 0-.25.05-1.05.25-2.05.5l-1 .25q-.2 0-.35.05Q45.6 9.4 39 9.4q-13.9 0-24.1-4.95-1-.5-1.95-1.05-1.1-.6-2.05-1.25Q9.45 1.25 8.3.3q-.05 0-.05-.05l-.75 3.9q-.05.2-.1.45-.1.45-.2.95L7 6.65q-.1.45-.2.95-.05.05-.05.2-.2.9-.35 1.8L2.95 23.9q-1 3.9-1.95 7.8l-.95 3.6v.25L0 35.6h.2q.25.1.5.15H.85q2.05.5 4.15.95 4.25.95 8.5 1.8 1.15.2 2.3.45l12.5 2.35q.3 0 .6.05L63.45 47 66 43.7h.7l2.55 3.3 45.15-7.55q.15-.05.35-.1.95-.15 1.95-.35 5.5-1.05 11-2.3 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-6-24.25q-.4-1.8-.75-3.55-.1-.45-.15-.85l-.2-.9q-.1-.45-.2-.85L124.4 0h-.05q-.35.3-.75.6z"
                    id="ShiSho078__Symbol_216_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M63.45 47l2.3-3-36.85-6q-3.6-.5-7.15-1.05-1.55-.3-3.05-.55-6.85-1.2-13.7-2.75-2.1-.45-4.15-.95h-.1l-.7 2.6v.25L0 35.6h.2q.25.1.5.15H.85q2.05.5 4.15.95 4.25.95 8.5 1.8 1.15.2 2.3.45l12.5 2.35q.3 0 .6.05L63.45 47M132 32.7H131.85q-2.05.5-4.15.95-5.5 1.25-11 2.3-2.45.45-4.85.85l-.4.1L66.9 44l2.35 3 45.15-7.55q.15-.05.35-.1.95-.15 1.95-.35 5.5-1.05 11-2.3 2.1-.45 4.15-.95H132q.25-.05.5-.15h.2l-.05-.2v-.1l-.65-2.6m-8.6-29.25q.1-.05.2-.15.15-.15 1.25-.95L124.4 0h-.05q-.35.3-.75.6-.1.1-.2.15-1.85 1.4-4.15 2.65-2.8 1.6-5.9 2.75l-1.1.4Q103.85 9.4 93.3 9.4q-15.25 0-26.05-6-.35-.2-.55-.5Q64.25 4.6 56.5 7q-.1 0-.15.05l-1.35.4q-.15 0-.25.05-1.05.25-2.05.5l-1 .25q-.2 0-.35.05Q45.6 9.4 39 9.4q-13.9 0-24.1-4.95-1-.5-1.95-1.05-1.1-.6-2.05-1.25Q9.45 1.25 8.3.3q-.05 0-.05-.05l-.5 2.6q2.15 1.4 3.15 2 .95.65 2.05 1.25.95.55 1.95 1.05Q25.1 12.1 39 12.1q6.95 0 13-1.25.9-.2 1.85-.4Q61.9 8.6 66.6 5.7q.3.2.65.4 10.8 6 26.05 6 10.55 0 18.95-2.85l1.1-.4q3.1-1.15 5.9-2.75 2.3-1.25 4.15-2.65z"
                    id="ShiSho078__Symbol_217_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M0 .35V38.2q.9.1 1.05-.05l.15.2V.3L.65 0 0 .35z"
                    id="ShiSho078__Symbol_218_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.6 29.2q0-.65 1.1-1.15.65-.25 1.45-.35v-.85q-1.5.1-2.65.65-1.5.75-1.5 1.8t1.5 1.8q1.15.6 2.65.7v-1.05q-.8-.15-1.45-.45-1.1-.45-1.1-1.1m6.35-1.7q-1.15-.55-2.65-.65v.85q.8.1 1.45.35 1.1.5 1.1 1.15 0 .65-1.1 1.1-.65.3-1.45.45v1.05q1.5-.1 2.65-.7 1.5-.75 1.5-1.8t-1.5-1.8m-3.8-9.6q-1.5.1-2.65.65-1.5.75-1.5 1.8t1.5 1.8q1.15.6 2.65.7V21.8q-.8-.15-1.45-.45-1.1-.45-1.1-1.1 0-.65 1.1-1.15.65-.25 1.45-.35v-.85m3.8.65Q6.8 18 5.3 17.9v.85q.8.1 1.45.35 1.1.5 1.1 1.15 0 .65-1.1 1.1-.65.3-1.45.45v1.05q1.5-.1 2.65-.7 1.5-.75 1.5-1.8t-1.5-1.8m0-8.95Q6.8 9.05 5.3 8.95v.85q.8.1 1.45.35 1.1.5 1.1 1.15 0 .65-1.1 1.1-.65.3-1.45.45v1.05q1.5-.1 2.65-.7 1.5-.75 1.5-1.8t-1.5-1.8m-5.25.55q.65-.25 1.45-.35v-.85q-1.5.1-2.65.65-1.5.75-1.5 1.8t1.5 1.8q1.15.6 2.65.7v-1.05q-.8-.15-1.45-.45-1.1-.45-1.1-1.1 0-.65 1.1-1.15M4.15.85V0Q2.65.1 1.5.65 0 1.4 0 2.45t1.5 1.8q1.15.6 2.65.7V3.9q-.8-.15-1.45-.45Q1.6 3 1.6 2.35q0-.65 1.1-1.15.65-.25 1.45-.35m5.3 1.6q0-1.05-1.5-1.8Q6.8.1 5.3 0v.85q.8.1 1.45.35 1.1.5 1.1 1.15 0 .65-1.1 1.1-.65.3-1.45.45v1.05q1.5-.1 2.65-.7 1.5-.75 1.5-1.8z"
                    id="ShiSho078__Symbol_219_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSho078__Symbol_216_0_Layer0_0_FILL"
                transform="translate(126.1 273.9)"
                id="ShiSho078__col1n"
            />
            <use
                xlinkHref="#ShiSho078__Symbol_217_0_Layer0_0_FILL"
                transform="translate(126.1 273.9)"
                id="ShiSho078__col1d"
            />
            <use
                xlinkHref="#ShiSho078__Symbol_218_0_Layer0_0_FILL"
                transform="translate(191.9 279.65)"
                id="ShiSho078__col1s"
            />
            <use
                xlinkHref="#ShiSho078__Symbol_219_0_Layer0_0_FILL"
                transform="translate(187.75 283.3)"
                id="ShiSho078__col2n"
            />
        </g></g>
    )
}

export default ShiSho078
