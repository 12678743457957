import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#C40000",
    "col1n": "#FF0000"
}

function ObjFoo011({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M24.55 2.05q-.6.05-1.1.15L17.9 0 9.2.5 7.95 2.85Q6.2 3.8 4.6 5.3 1.2 8.55 0 12.95q2.1.15 4.5.9 2.25 1 3.95 3 0 .05.05.1.15.15.3.35 2.85 4.4 2.35 9.9-.1.75-.25 1.5l-.1.55q-.65 2.45-1.7 4.05 1.5.55 3.2.8 13.75 2.1 18.3 0 2.6-1 4.85-3.15 1.9-1.85 3.15-4.05 3.65-7.35 1.05-14.7-.9-2.45-2.55-4.6-3.8-4.75-10.95-5.55-.75-.05-1.45 0h-.15z"
                    id="ObjFoo011__Symbol_87_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M6.85 8.9Q7 2.6 1.6 0h-.15Q.85.05.35.15L0 2q1.5 1.15 2.8 2.8 1.35 1.65 1.45 6.85v1.15l-.05.55v1q-.35 2.25-.7 3.55 3.15-3.45 3.35-9z"
                    id="ObjFoo011__Symbol_37_0_Layer0_0_FILL"
                />
                <path
                    fill="#008300"
                    d="M120.65 260.65q-1.75 4.7-1.1 11.25.25 1.3 2.65 4.3 3.5-1.9 7.65-1.6 2.85.2 5.25 1.45l.05-1.5q-.85-1.3-2.15-2.3-1.35-1.05-6.1-2.65-4.75-1.65-6.25-8.95z"
                    id="ObjFoo011__Layer7_1_FILL"
                />
                <g id="ObjFoo011__Layer7_0_FILL">
                    <path
                        fill="#693D1F"
                        d="M132.7 306.55q-.55.7-1 1.25-.15-.05-.25 0l-.1.1q-.25.4-.35.85 1 .05 1.5.7 1 .05 1.75-.45.6.05 2.05 1.1l.1-1.7q-.2-.15-.35-.25-.8-.6-.9-1.15-.1-.4-1.1-.45-.8-.1-1.35 0z"
                    />
                    <path
                        fill="#644D27"
                        d="M139.7 267.4l-4.4-.45-.2 7.6q-.45.119-.45.7 0 .2.15.45.117.204.3.3v.05q.1 0 .25.1.05.05.2.1.9.5 1.8 1.15l2.35-10z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo011__Symbol_87_0_Layer0_0_FILL"
                transform="translate(114.25 273.35)"
                id="ObjFoo011__col1n"
            />
            <use
                xlinkHref="#ObjFoo011__Symbol_37_0_Layer0_0_FILL"
                transform="translate(137.35 275.4)"
                id="ObjFoo011__col1d"
            />
            <use xlinkHref="#ObjFoo011__Layer7_0_FILL" />
            <use xlinkHref="#ObjFoo011__Layer7_1_FILL" />
        </g></g>
    )
}

export default ObjFoo011
