import { AssetSvgProps } from "../../../shared/assets"

function PlaOut032({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <linearGradient
                    id="PlaOut032__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={194.1}
                    y1={134.075}
                    x2={194.1}
                    y2={20.025}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#008E82" />
                    <stop offset="100%" stopColor="#002427" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={36.75}
                    y1={105.55}
                    x2={36.75}
                    y2={49.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#001E1F" />
                    <stop offset="100%" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_3"
                    gradientUnits="userSpaceOnUse"
                    x1={192}
                    y1={181.75}
                    x2={192}
                    y2={557.25}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="100%" stopColor="#002427" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_4"
                    gradientUnits="userSpaceOnUse"
                    x1={195.05}
                    y1={197.025}
                    x2={195.05}
                    y2={274.575}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="100%" stopColor="#002427" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_5"
                    gradientUnits="userSpaceOnUse"
                    x1={76}
                    y1={225.075}
                    x2={76}
                    y2={41.525}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#006E65" />
                    <stop offset="100%" stopColor="#001719" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_6"
                    gradientUnits="userSpaceOnUse"
                    x1={129}
                    y1={217.55}
                    x2={129}
                    y2={60.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#006E65" />
                    <stop offset="100%" stopColor="#001719" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_7"
                    gradientUnits="userSpaceOnUse"
                    x1={198}
                    y1={252.55}
                    x2={198}
                    y2={91.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#006E65" />
                    <stop offset="100%" stopColor="#001719" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_8"
                    gradientUnits="userSpaceOnUse"
                    x1={317.8}
                    y1={203.55}
                    x2={317.8}
                    y2={76.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#006E65" />
                    <stop offset="100%" stopColor="#001719" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_9"
                    gradientUnits="userSpaceOnUse"
                    x1={270.85}
                    y1={233.55}
                    x2={270.85}
                    y2={48.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#006E65" />
                    <stop offset="100%" stopColor="#001719" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_10"
                    gradientUnits="userSpaceOnUse"
                    x1={299.8}
                    y1={194.05}
                    x2={299.8}
                    y2={71.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#001E1F" />
                    <stop offset="100%" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_11"
                    gradientUnits="userSpaceOnUse"
                    x1={360.35}
                    y1={134.55}
                    x2={360.35}
                    y2={105.05}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#001E1F" />
                    <stop offset="100%" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_12"
                    gradientUnits="userSpaceOnUse"
                    x1={30.25}
                    y1={124.05}
                    x2={30.25}
                    y2={90.55}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#001E1F" />
                    <stop offset="100%" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_13"
                    gradientUnits="userSpaceOnUse"
                    x1={16.05}
                    y1={123.575}
                    x2={16.05}
                    y2={32.525}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#006E65" />
                    <stop offset="100%" stopColor="#001719" />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_14"
                    gradientUnits="userSpaceOnUse"
                    x1={343.025}
                    y1={107.1}
                    x2={213.575}
                    y2={114}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" stopOpacity={0.259} />
                    <stop offset="100%" stopColor="#F6FF62" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_15"
                    gradientUnits="userSpaceOnUse"
                    x1={57.275}
                    y1={145.55}
                    x2={186.725}
                    y2={138.65}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" stopOpacity={0.259} />
                    <stop offset="100%" stopColor="#F6FF62" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_16"
                    gradientUnits="userSpaceOnUse"
                    x1={369.7}
                    y1={107.625}
                    x2={454.4}
                    y2={9.475}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" stopOpacity={0.259} />
                    <stop offset="100%" stopColor="#F6FF62" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_17"
                    gradientUnits="userSpaceOnUse"
                    x1={352.038}
                    y1={99.088}
                    x2={383.462}
                    y2={34.813}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" />
                    <stop
                        offset="100%"
                        stopColor="#F6FF62"
                        stopOpacity={0.141}
                    />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_18"
                    gradientUnits="userSpaceOnUse"
                    x1={77.712}
                    y1={105.288}
                    x2={169.387}
                    y2={13.612}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" stopOpacity={0.259} />
                    <stop offset="100%" stopColor="#F6FF62" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_19"
                    gradientUnits="userSpaceOnUse"
                    x1={238.688}
                    y1={54.263}
                    x2={207.712}
                    y2={0.737}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" />
                    <stop
                        offset="100%"
                        stopColor="#F6FF62"
                        stopOpacity={0.141}
                    />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_20"
                    gradientUnits="userSpaceOnUse"
                    x1={76.575}
                    y1={51.475}
                    x2={132.025}
                    y2={3.525}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" />
                    <stop
                        offset="100%"
                        stopColor="#F6FF62"
                        stopOpacity={0.141}
                    />
                </linearGradient>
                <linearGradient
                    id="PlaOut032__Gradient_21"
                    gradientUnits="userSpaceOnUse"
                    x1={36.7}
                    y1={130.725}
                    x2={29.8}
                    y2={1.275}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#FFFD83" stopOpacity={0.259} />
                    <stop offset="100%" stopColor="#F6FF62" stopOpacity={0} />
                </linearGradient>
                <g id="PlaOut032__Layer7_0_FILL">
                    <path d="M385 385V191.35H0V385h385z" />
                    <path
                        fill="url(#PlaOut032__Gradient_1)"
                        d="M385 191.35V0H0v191.35h385M40 82.1V82l.8 1.4-.8-1.3z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_2)"
                        d="M40 82v.1l.8 1.3L40 82z"
                    />
                </g>
                <g id="PlaOut032__Layer6_0_FILL">
                    <path d="M85 189.05h-6.95v38H85v-38M29.55 23.5H0v87.05h71.5v-66.5H29.55V23.5M40 82.1V82l.8 1.4-.8-1.3m254.6-33.75l-.65 1.05q-1.091 1.926-2.15 4.2-5.6 28.3-5.25 79.45v65.5h-8.5v10.5h24v-73.5h11v-99q-10.6-1.1-18.45 11.8m90.4 51.2h-49.9v36H385v-36z" />
                    <path
                        fill="url(#PlaOut032__Gradient_3)"
                        d="M219.5 135.85h-44.1V201h44.1v-65.15m0-14.5h-44.1v.45h44.1v-.45z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_4)"
                        d="M271.35 40.45l-24.1 14.6h-35.7v63.35l-5.3 2.95h13.25v.45h-44.1v-.45h19.5L184.5 81.8V20.5H160q-14.92 4.583-19.8 31.65l-21.65 88.4H100.7l-29.2-96.5v66.5H0v168h78.05v-89.5H85v89.5h33.5V201h56.9v-65.15h44.1V201h28.45v77.55h30.1v-80h8.5v-65.5q-.35-51.15 5.25-79.45 1.059-2.274 2.15-4.2l-22.6-8.95m63.75 95.1v-36h31V12h-43.55v58l-9.5 20.45v45.1h-11v143H385v-143h-49.9z"
                    />
                </g>
                <g id="PlaOut032__Layer6_1_FILL">
                    <path
                        fill="url(#PlaOut032__Gradient_5)"
                        d="M50.5 31v13.05h21v66.5h6.55v78.5H85v-48.5h16.55V31H50.5z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_6)"
                        d="M138.5 65.05q.55-6.15 1.45-11.5h-21.9v87h20.45v-75.5z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_7)"
                        d="M184.4 184.65v2.5h-.1v.4h27.25v-2.9H184.4m12.15-102.6H184.5v43.6h-.1v2.9h.1v6.85h-.1v2.9h.1v6.85h-.1v2.9h.1v6.85h-.1v2.9h.1v6.85h-.1v3.4h.1v6.85h-.1v2.9h.1v6.75h27.05v-67h-15v-35.5z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_8)"
                        d="M322.55 71.05h-9.5v64.5h9.5v-64.5z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_9)"
                        d="M294.6 48.35V36.5h-47.5v18.55h30.95v143.5h8.5v-65.5q-.35-51.15 5.25-79.45 1.35-2.9 2.8-5.25z"
                    />
                </g>
                <g id="PlaOut032__Layer6_2_FILL">
                    <path
                        fill="url(#PlaOut032__Gradient_10)"
                        d="M313.05 36.55q-10.6-1.1-18.45 11.8l-.65 1.05q-1.091 1.926-2.15 4.2-5.6 28.3-5.25 79.45v65.5h-8.5v10.5h24v-73.5h11v-99z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_11)"
                        d="M385 135.55v-36h-49.9v36H385z"
                    />
                </g>
                <g id="PlaOut032__Layer6_3_FILL">
                    <path
                        fill="url(#PlaOut032__Gradient_2)"
                        d="M85 189.05h-6.95v38H85v-38m-55.45-145H0V82h40q7.1 8.6 12.2 28.55h19.3v-66.5H29.55z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_12)"
                        d="M40 82H0v28.55h52.2Q47.1 90.6 40 82l.8 1.4-.8-1.3V82z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_13)"
                        d="M0 44.05h29.55V23.5H0v20.55z"
                    />
                </g>
                <g id="PlaOut032__Layer5_1_FILL">
                    <path
                        fill="#120041"
                        d="M135.8 213.8v9.3q-.014 8.315 8.2 10.3 2.279.554 5.25.55h66.9q13.733 0 13.5-10.85v-9.3q.233-10.85-13.5-10.85h-66.9q-2.971-.004-5.25.55-8.214 1.985-8.2 10.3z"
                    />
                    <path
                        fill="#004D4D"
                        d="M51 132.65h-9.3q-10.85-.233-10.85 13.5v66.9q-.003 12.892 9.7 13.45.115.004.2 0H51q1.171.001 2.2-.2 8.644-1.14 8.65-13.25v-66.9q0-13.733-10.85-13.5z"
                    />
                    <path
                        fill="#4A004A"
                        d="M353.4 180.65q0-13.733-10.85-13.5h-9.3q-10.85-.233-10.85 13.5v66.9q0 13.68 10.85 13.45h9.3q10.85.23 10.85-13.45v-66.9z"
                    />
                </g>
                <g id="PlaOut032__Layer5_2_FILL">
                    <path
                        fill="url(#PlaOut032__Gradient_14)"
                        d="M322.8 118.4v-10.2l-115.5-9.4v33l115.5-13.4z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_15)"
                        d="M193 153.85v-33l-115.5 13.4v10.2l115.5 9.4z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_16)"
                        d="M385.75 89.75v-17.9l-21 27.65h9.95l11.05-9.75z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_17)"
                        d="M385.75 49.15V10.5l-38.65 89h5.55l33.1-50.35z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_18)"
                        d="M191.15-.75h-31.7L71.5 98.9v7.65L201.55 7.5l-10.4-8.25z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_19)"
                        d="M217.55-.75H198.2l40.4 56.3h3.95l-25-56.3z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_20)"
                        d="M154.7-.75h-35.25L71.5 53.9v7.65l83.2-62.3z"
                    />
                    <path
                        fill="url(#PlaOut032__Gradient_21)"
                        d="M44.7-.75H12.5L17.7 44h23.35L44.7-.75z"
                    />
                </g>
                <g id="PlaOut032__Layer5_4_FILL">
                    <path
                        fill="#170017"
                        d="M98.9 221.3q-5.1.15-5.1 7.1V279h17.35v-50.6q0-6.95-5.15-7.1h-7.1m1.65 9.4q0 .6-.4 1.05-.45.4-1.05.4-.2.05-.75-.15t-.85-.75q-.25-.5-.05-1.1.2-.55.7-.8.55-.3 1.1-.1.5.1.9.4.4.45.4 1.05m-2.9 10.75q-.55 0-1-.4-.45-.4-.45-1.05-.05-.55.35-1 .45-.45 1-.45l5.9-.3h.05q2.55 0 4 1.1 1.85 1.3 1.85 3.8.05 3.95-3.95 5.45-.55.25-1.1 0-.55-.2-.8-.8-.15-.55.05-1.1.25-.55.8-.8 2.05-.8 2.1-2.75 0-.9-.6-1.4l-.05-.05q-.8-.55-2.25-.55l-5.9.3m2.05 2.05q.4.45.4 1.05v3.4q0 .6-.4 1-.45.45-1.05.45-.6 0-1-.45-.45-.4-.45-1v-3.4q0-.6.45-1.05.4-.4 1-.4t1.05.4m4.8-18.3q.45.4.45 1v8.35q0 .6-.45 1-.4.45-1 .45t-1.05-.45q-.4-.4-.4-1v-8.35q0-.6.4-1 .45-.45 1.05-.45.6 0 1 .45m4.65 26.45q.4.45.4 1.05 0 .6-.4 1-.4.45-1.05.45h-5.6q-.6 0-1.05-.45-.4-.4-.4-1t.4-1.05q.45-.4 1.05-.4h5.6q.65 0 1.05.4m-4.2 5.45q.45-.4 1-.35.6.05 1 .5t.4 1.05q-.2 2.8-1.6 3.95h-.05q-1.3 1.3-4.25 1.3-4.15.15-4.65-4.85-.15-2 0-6 .05-.55.45-.95.45-.45 1-.4.65.05 1.05.45.4.45.4 1.05-.15 3.75 0 5.6.15 2.1 1.75 2.2 1.6 0 2.35-.5v-.05q.5-.65.65-2 0-.6.5-1m-3.3 11.05v3.8q0 .65-.4 1.05-.45.4-1.05.4-.6 0-1-.4-.45-.4-.45-1.05v-3.8q0-.65.45-1.05.4-.4 1-.4t1.05.4q.4.4.4 1.05m2.8 1.1h.05q.75-1.55 1.55-2.2.5-.35 1.1-.3.6.1.95.55.4.45.35 1.05-.1.6-.55.95-.4.4-.8 1.2-.4.95-.45 1.8 0 .85.3 2.3.15.75.35 1.45h1.3q.65 0 1.05.4.4.45.4 1.05 0 .6-.4 1-.4.45-1.05.45H97.2q-.6 0-1-.45-.45-.4-.45-1t.45-1.05q.4-.4 1-.4h7.1q-.1-.45-.15-.85-.4-1.8-.4-2.9-.05-1.4.7-3.05z"
                    />
                    <path
                        fill="#510051"
                        d="M104.5 269.25h-.05q-.75 1.65-.7 3.05 0 1.1.4 2.9.05.4.15.85h-7.1q-.6 0-1 .4-.45.45-.45 1.05 0 .6.45 1 .4.45 1 .45h11.4q.65 0 1.05-.45.4-.4.4-1t-.4-1.05q-.4-.4-1.05-.4h-1.3q-.2-.7-.35-1.45-.3-1.45-.3-2.3.05-.85.45-1.8.4-.8.8-1.2.45-.35.55-.95.05-.6-.35-1.05-.35-.45-.95-.55-.6-.05-1.1.3-.8.65-1.55 2.2m-2.85 2.7v-3.8q0-.65-.4-1.05-.45-.4-1.05-.4-.6 0-1 .4-.45.4-.45 1.05v3.8q0 .65.45 1.05.4.4 1 .4t1.05-.4q.4-.4.4-1.05m4.3-15.2q-.55-.05-1 .35-.5.4-.5 1-.15 1.35-.65 2v.05q-.75.5-2.35.5-1.6-.1-1.75-2.2-.15-1.85 0-5.6 0-.6-.4-1.05-.4-.4-1.05-.45-.55-.05-1 .4-.4.4-.45.95-.15 4 0 6 .5 5 4.65 4.85 2.95 0 4.25-1.3h.05q1.4-1.15 1.6-3.95 0-.6-.4-1.05-.4-.45-1-.5m3.6-4.05q0-.6-.4-1.05-.4-.4-1.05-.4h-5.6q-.6 0-1.05.4-.4.45-.4 1.05 0 .6.4 1 .45.45 1.05.45h5.6q.65 0 1.05-.45.4-.4.4-1m-4.6-26.5q0-.6-.45-1-.4-.45-1-.45t-1.05.45q-.4.4-.4 1v8.35q0 .6.4 1 .45.45 1.05.45.6 0 1-.45.45-.4.45-1v-8.35m-4.85 18.35q0-.6-.4-1.05-.45-.4-1.05-.4-.6 0-1 .4-.45.45-.45 1.05v3.4q0 .6.45 1 .4.45 1 .45t1.05-.45q.4-.4.4-1v-3.4m-3.45-3.5q.45.4 1 .4l5.9-.3q1.45 0 2.25.55l.05.05q.6.5.6 1.4-.05 1.95-2.1 2.75-.55.25-.8.8-.2.55-.05 1.1.25.6.8.8.55.25 1.1 0 4-1.5 3.95-5.45 0-2.5-1.85-3.8-1.45-1.1-4-1.1h-.05l-5.9.3q-.55 0-1 .45-.4.45-.35 1 0 .65.45 1.05m3.5-9.3q.4-.45.4-1.05 0-.6-.4-1.05-.4-.3-.9-.4-.55-.2-1.1.1-.5.25-.7.8-.2.6.05 1.1.3.55.85.75.55.2.75.15.6 0 1.05-.4m-6.35-3.35q0-6.95 5.1-7.1h7.1q5.15.15 5.15 7.1V279h3.1v-50.6q0-10.4-8.25-10.2h-7.1q-8.25-.2-8.25 10.2V279h3.15v-50.6z"
                    />
                </g>
                <g id="PlaOut032__Layer5_5_FILL">
                    <path
                        fill="#205151"
                        d="M258.15 181.65q-6.65-.15-6.65 8.2v40.9q0 8.4 6.65 8.25h5.65q6.65.15 6.65-8.25v-40.9q0-8.35-6.65-8.2h-5.65m-4.15 8.2q0-5.55 4.15-5.7h5.65q4.15.15 4.15 5.7v40.9q0 5.6-4.15 5.75h-5.65q-4.15-.15-4.15-5.75v-40.9m3.55 38.4q-.05.05-.15.1-.15.05-1.05.6-.4.3-.5.75-.05.45.15.9.3.4.75.5.5.05.9-.15.5-.35.7-.45h.5q.5 0 .8.25 1 1.15 1.7 1.45.05 0 .1.05.6.3 2 .3h1.65q.5 0 .8-.35.35-.35.35-.8 0-.5-.35-.85-.3-.35-.8-.35h-1.65q-.75 0-1.05-.1-.4-.25-1.05-.9-.85-1-2.5-1-1.2 0-1.3.05m2.8-6.5q0-.5-.35-.8-.35-.35-.85-.35-.45 0-.8.35-.35.3-.35.8v3.1q0 .5.35.8.35.35.8.35.5 0 .85-.35.35-.3.35-.8v-3.1m-3-13.55q-.5 0-.85.35-.35.3-.35.8t.35.85q.35.3.85.3h4.5q.5 0 .85-.3.3-.35.3-.85t-.3-.8q-.35-.35-.85-.35h-4.5m1.1-7.1h-.05q-.45-.15-.9.05-.4.2-.55.65-.15.45.05.9t.65.6q.5.15.65.1.45 0 .8-.35.35-.3.35-.8t-.35-.85q-.3-.25-.65-.3m6.65 17.4q.5 0 .8-.35.35-.35.35-.8 0-.5-.35-.85-.3-.35-.8-.35h-1.65q-.75 0-1.05-.1-.4-.25-1.05-.9-.85-1.05-2.5-1.05-1.2 0-1.3.1-.05.05-.15.1-.15.05-1.05.6-.4.3-.5.75-.05.45.15.9.3.4.75.5.5.05.9-.2.5-.3.7-.4h.5q.5 0 .8.25 1 1.15 1.7 1.45.05 0 .1.05.6.3 2 .3h1.65m-2.5-20.7q-.3-.35-.8-.35t-.85.35q-.3.35-.3.85v6.7q0 .45.3.8.35.35.85.35t.8-.35q.35-.35.35-.8v-6.7q0-.5-.35-.85m-3.85-10.15q0-.45-.3-.8-.35-.35-.8-.4-.5-.05-.85.3t-.4.85q-.1 3.1 0 4.75.4 4 3.8 3.9 2.35 0 3.35-1 0-.05.05-.05 1.15-.95 1.3-3.15 0-.5-.3-.85-.35-.4-.8-.4-.5-.05-.85.3-.35.3-.4.75-.1 1.15-.55 1.65h.05q-.6.45-1.85.45-1.3-.1-1.45-1.8-.15-1.5 0-4.5z"
                    />
                    <path
                        fill="#051A0E"
                        d="M258.15 184.15q-4.15.15-4.15 5.7v40.9q0 5.6 4.15 5.75h5.65q4.15-.15 4.15-5.75v-40.9q0-5.55-4.15-5.7h-5.65m.3 2.7q.3.35.3.8-.15 3 0 4.5.15 1.7 1.45 1.8 1.25 0 1.85-.45H262q.45-.5.55-1.65.05-.45.4-.75.35-.35.85-.3.45 0 .8.4.3.35.3.85-.15 2.2-1.3 3.15-.05 0-.05.05-1 1-3.35 1-3.4.1-3.8-3.9-.1-1.65 0-4.75.05-.5.4-.85.35-.35.85-.3.45.05.8.4m3.35 10.6q.5 0 .8.35.35.35.35.85v6.7q0 .45-.35.8-.3.35-.8.35t-.85-.35q-.3-.35-.3-.8v-6.7q0-.5.3-.85.35-.35.85-.35m4.1 20.7q-.3.35-.8.35h-1.65q-1.4 0-2-.3-.05-.05-.1-.05-.7-.3-1.7-1.45-.3-.25-.8-.25h-.5q-.2.1-.7.4-.4.25-.9.2-.45-.1-.75-.5-.2-.45-.15-.9.1-.45.5-.75.9-.55 1.05-.6.1-.05.15-.1.1-.1 1.3-.1 1.65 0 2.5 1.05.65.65 1.05.9.3.1 1.05.1h1.65q.5 0 .8.35.35.35.35.85 0 .45-.35.8m-7.5-17.05h.05q.35.05.65.3.35.35.35.85t-.35.8q-.35.35-.8.35-.15.05-.65-.1-.45-.15-.65-.6-.2-.45-.05-.9.15-.45.55-.65.45-.2.9-.05m-1.9 7.45q.35-.35.85-.35h4.5q.5 0 .85.35.3.3.3.8t-.3.85q-.35.3-.85.3h-4.5q-.5 0-.85-.3-.35-.35-.35-.85t.35-.8m3.5 12.4q.35.3.35.8v3.1q0 .5-.35.8-.35.35-.85.35-.45 0-.8-.35-.35-.3-.35-.8v-3.1q0-.5.35-.8.35-.35.8-.35.5 0 .85.35m-2.6 7.4q.1-.05.15-.1.1-.05 1.3-.05 1.65 0 2.5 1 .65.65 1.05.9.3.1 1.05.1h1.65q.5 0 .8.35.35.35.35.85 0 .45-.35.8-.3.35-.8.35h-1.65q-1.4 0-2-.3-.05-.05-.1-.05-.7-.3-1.7-1.45-.3-.25-.8-.25h-.5q-.2.1-.7.45-.4.2-.9.15-.45-.1-.75-.5-.2-.45-.15-.9.1-.45.5-.75.9-.55 1.05-.6z"
                    />
                </g>
                <path
                    fill="#006F67"
                    d="M69 247.5v-3.6H2v3.6h67m-67 8.4v3.6h67v-3.6H2M2 268v3.6h67V268H2m67-44.7v-3.6H2v3.6h67m0 12.1v-3.6H2v3.6h67m0-24.2v-3.6H2v3.6h67m57.5 32.7v3.6h115.25v-3.6H126.5m0 12v3.6h115.25v-3.6H126.5m0 12.1v3.6h115.25V268H126.5M69 195.5H2v3.6h67v-3.6M2 147.1v3.6h67v-3.6H2m0 12.1v3.6h67v-3.6H2m0 24.2v3.6h67v-3.6H2m0-12.1v3.6h67v-3.6H2m67-44.8v-3.6H2v3.6h67m0 12.1V135H2v3.6h67m115.4 16.3v2.9h27.15v-2.9H184.4m0 9.75v3.4h27.15v-3.4H184.4m0 10.25v2.9h27.15v-2.9H184.4m-.1 12.25v.4h27.25v-2.9H184.4v2.5h-.1m27.25-39.1v-2.9H184.4v2.9h27.15m0-9.75v-2.9H184.4v2.9h27.15m0-9.75v-2.9H184.4v2.9h27.15M385 258.35v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385m0-60.5v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385m0-96.8v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385m0 12.1v-3.6h-73.45v3.6H385z"
                    id="PlaOut032__Layer5_0_FILL"
                />
                <path
                    fill="#F0F"
                    d="M342.55 167.15h-9.3q-10.85-.233-10.85 13.5v66.9q0 13.68 10.85 13.45h9.3q10.85.23 10.85-13.45v-66.9q0-13.733-10.85-13.5m-9.3 4.1h9.3q6.75.224 6.75 9.4v66.9q0 9.129-6.75 9.35h-9.3q-6.75-.221-6.75-9.35v-66.9q0-9.176 6.75-9.4m9.05 71.4q-.79-.003-1.35.55-.553.56-.55 1.35-.003.79.55 1.35.476.47 1.1.55h.05q.745.251 1.45-.1.703-.355.95-1.1.252-.745-.1-1.45-.355-.702-1.1-.95-.742-.249-1-.2m-4.4-4.5q-.56-.553-1.35-.55-.79-.003-1.35.55-.553.56-.55 1.35v10.95q-.003.79.55 1.35.56.553 1.35.55.79.003 1.35-.55.553-.56.55-1.35V239.5q.003-.79-.55-1.35m-1.45-16.1q-.27-.732-1-1.05-.72-.314-1.45-.05-5.203 2.07-5.15 7.25.004 3.291 2.4 4.95 1.895 1.446 5.25 1.45h.05l7.75-.35q.81-.026 1.35-.6.533-.585.5-1.4-.026-.761-.6-1.3-.584-.532-1.4-.5l-7.75.35q-1.843-.01-2.95-.75-.804-.64-.8-1.85.053-2.62 2.75-3.7.731-.271 1.05-1 .315-.72 0-1.45M329.1 217q.56.553 1.35.55h7.4q.79.003 1.35-.55.553-.56.55-1.35.003-.79-.55-1.35-.56-.553-1.35-.55h-7.4q-.79-.003-1.35.55-.553.56-.55 1.35-.003.79.55 1.35m16.25-9.25v-.05q-.636-6.509-6.15-6.35-3.834-.003-5.55 1.65-.022.038-.05.05-1.837 1.548-2.1 5.2-.048.793.45 1.4.542.595 1.3.65.805.049 1.4-.5.594-.48.65-1.25.137-1.85.9-2.7h-.05q.98-.698 3.05-.7 2.076.15 2.35 2.9.192 2.474 0 7.35-.033.8.5 1.35.538.611 1.3.65.802.033 1.35-.5.61-.537.65-1.35.208-5.174 0-7.8m-.55 14.15q.003-.79-.55-1.35-.56-.553-1.35-.55-.79-.003-1.35.55-.553.56-.55 1.35v4.45q-.003.79.55 1.35.56.553 1.35.55.79.003 1.35-.55.553-.56.55-1.35v-4.45m-2.05-31.6q.003-.79-.55-1.35-.56-.553-1.35-.55-.79-.003-1.35.55-.553.56-.55 1.35v5.05q-.003.79.55 1.35.56.553 1.35.55.79.003 1.35-.55.553-.56.55-1.35v-5.05m-12.95-5.35h1.7q.276.919.5 1.9.395 1.903.4 3.05-.026 1.079-.65 2.35l.05-.05q-.527 1.1-1.05 1.55-.614.523-.7 1.3-.077.784.4 1.4.521.615 1.3.7.783.076 1.4-.45 1.076-.799 2.05-2.85 1.026-2.129 1-3.95.005-1.453-.5-3.85-.121-.56-.25-1.1h9.35q.79.003 1.35-.55.553-.56.55-1.35.003-.79-.55-1.35-.56-.553-1.35-.55h-15q-.79-.003-1.35.55-.553.56-.55 1.35-.003.79.55 1.35.56.553 1.35.55z"
                    id="PlaOut032__Layer5_1_MEMBER_0_FILL"
                />
                <path
                    fill="#5A17FF"
                    d="M149.25 202.95q-2.971-.004-5.25.55-8.214 1.985-8.2 10.3v9.3q-.014 8.315 8.2 10.3 2.279.554 5.25.55h66.9q13.733 0 13.5-10.85v-9.3q.233-10.85-13.5-10.85h-66.9m-4.3 4.55q1.871-.446 4.3-.45h66.9q9.176 0 9.4 6.75v9.3q-.224 6.75-9.4 6.75h-66.9q-2.429-.004-4.3-.45-5.036-1.215-5.05-6.3v-9.3q.014-5.085 5.05-6.3m18.65 7.7q-.79-.003-1.35.55-.553.56-.55 1.35-.003.79.55 1.35.56.553 1.35.55h10.95q.79.003 1.35-.55.553-.56.55-1.35.003-.79-.55-1.35-.56-.553-1.35-.55H163.6m7.25 6.3q-.56-.553-1.35-.55-.79-.003-1.35.55-.47.476-.55 1.1v.05q-.251.745.1 1.45.355.703 1.1.95.745.252 1.45-.1.702-.355.95-1.1.249-.742.2-1 .003-.79-.55-1.35m12.2-4.5q.003-.79-.55-1.35-.56-.553-1.35-.55-.79-.003-1.35.55-.553.56-.55 1.35v7.4q-.003.79.55 1.35.56.553 1.35.55.79.003 1.35-.55.553-.56.55-1.35V217m12.5-6.65q-.56-.553-1.35-.55-.79-.003-1.35.55-.553.56-.55 1.35v2.7q-.004 1.19-.2 1.75-.388.669-1.5 1.7-1.65 1.424-1.65 4.1.001 1.894.15 2.1.04.123.1.25.1.235 1.05 1.7.432.647 1.2.8.766.172 1.4-.25.686-.432.85-1.2.171-.777-.25-1.45-.526-.796-.75-1.15.049-.152.05-.8 0-.826.4-1.3h.05q1.833-1.717 2.35-2.85.024-.052.05-.1.505-1.028.5-3.3v-2.7q.003-.79-.55-1.35m12.85 11.05q.003-.79-.55-1.35-.56-.553-1.35-.55h-5.05q-.79-.003-1.35.55-.553.56-.55 1.35-.003.79.55 1.35.56.553 1.35.55h5.05q.79.003 1.35-.55.553-.56.55-1.35m10.15-11.05q-.56-.553-1.35-.55-.79-.003-1.35.55-.553.56-.55 1.35v2.7q-.004 1.19-.2 1.75-.388.669-1.5 1.7-1.65 1.424-1.65 4.1.001 1.894.15 2.1.04.123.1.25.1.235 1.05 1.7.432.647 1.2.8.766.172 1.4-.25.686-.432.85-1.2.171-.777-.25-1.45-.526-.796-.75-1.15.049-.152.05-.8 0-.826.4-1.3h.05q1.833-1.717 2.35-2.85.024-.052.05-.1.505-1.028.5-3.3v-2.7q.003-.79-.55-1.35M145.6 222.1q-.75-.033-1.35.5-.561.538-.6 1.3-.033.802.5 1.35.537.61 1.3.65 5.224.208 7.85 0 6.559-.636 6.4-6.15.002-3.834-1.7-5.55.011-.022 0-.05-1.548-1.837-5.2-2.1-.793-.048-1.4.45-.595.542-.65 1.3-.049.805.45 1.4.53.594 1.3.65 1.85.137 2.7.9l-.05-.05q.747.98.75 3.05-.15 2.076-2.9 2.35-2.474.192-7.4 0z"
                    id="PlaOut032__Layer5_1_MEMBER_1_FILL"
                />
                <path
                    fill="#6FF"
                    d="M51 132.65h-9.3q-10.85-.233-10.85 13.5v66.9q-.003 12.892 9.7 13.45.115.004.2 0H51q1.171.001 2.2-.2 8.644-1.14 8.65-13.25v-66.9q0-13.733-10.85-13.5m-9.3 4.1H51q6.75.224 6.75 9.4v66.9q.006 8.1-5.2 9.2-.721.148-1.55.15H40.9q-5.947-.734-5.95-9.35v-66.9q0-9.176 6.75-9.4m.5 73.4q-.355-.702-1.1-.95-.742-.249-1-.2-.79-.003-1.35.55-.553.56-.55 1.35-.003.79.55 1.35.476.47 1.1.55h.05q.745.251 1.45-.1.703-.355.95-1.1.252-.745-.1-1.45m10.2-7.1q-.79-.003-1.35.55-.553.56-.55 1.35v5.9q-.276 2.63-1.75 3.55-.695.4-.95 1.15-.2.753.15 1.45.398.695 1.15.9.753.25 1.45-.15 3.225-1.527 3.75-6.5.005-.1 0-.2v-6.1q.003-.79-.55-1.35-.56-.553-1.35-.55M45 162.1q-.79-.003-1.35.55-.553.56-.55 1.35v10.95q-.003.79.55 1.35.56.553 1.35.55.79.003 1.35-.55.553-.56.55-1.35V164q.003-.79-.55-1.35-.56-.553-1.35-.55m5.5 8.85h.05q.745.251 1.45-.1.703-.355.95-1.1.252-.745-.1-1.45-.355-.702-1.1-.95-.742-.249-1-.2-.79-.003-1.35.55-.553.56-.55 1.35-.003.79.55 1.35.476.47 1.1.55M38.6 195.4q.006 1.373.4 2.55.248.745.95 1.1.705.352 1.45.1.745-.247 1.1-.95.351-.705.1-1.45-.206-.623-.2-1.35-.01-1.756 1.15-3l.05-.05q1.244-1.16 3-1.15 1.751-.005 2.95 1.2 1.247 1.24 1.25 3 .001.727-.2 1.35-.251.745.1 1.45.355.703 1.1.95.745.252 1.45-.1.702-.355.95-1.1.399-1.176.4-2.55.003-3.34-2.35-5.7-2.302-2.295-5.65-2.3-3.326.01-5.7 2.3-2.29 2.374-2.3 5.7m1-14.95q-.752.01-1.3.55-.554.589-.55 1.35.01.803.55 1.35.588.554 1.35.55l12.85-.15q.802-.01 1.35-.6.554-.539.55-1.35-.01-.753-.6-1.3-.538-.554-1.35-.55l-12.85.15m9.1-33.15q-3.882-3.096-6.95-5.2-.628-.42-1.4-.3-.775.173-1.25.8-.42.677-.3 1.45.174.774.8 1.2 2.981 2.046 6.75 5.05 3.09 2.384 4.4 4.25-3.099.1-10.9.15-.79.01-1.35.55-.554.576-.55 1.35.01.79.55 1.35.576.554 1.35.55 8.036-.05 11.15-.15 2.743-.085 3.45-1.4 1.383-1.305-.05-3.8-1.453-2.555-5.7-5.85z"
                    id="PlaOut032__Layer5_1_MEMBER_2_FILL"
                />
                <path
                    fill="#243233"
                    d="M385 301.55v-18.5H0v18.5h385z"
                    id="PlaOut032__Layer5_3_FILL"
                />
            </defs>
            <use xlinkHref="#PlaOut032__Layer7_0_FILL" />
            <use xlinkHref="#PlaOut032__Layer6_0_FILL" />
            <use xlinkHref="#PlaOut032__Layer6_1_FILL" />
            <use xlinkHref="#PlaOut032__Layer6_2_FILL" />
            <use xlinkHref="#PlaOut032__Layer6_3_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_0_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_1_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_1_MEMBER_0_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_1_MEMBER_1_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_1_MEMBER_2_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_2_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_3_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_4_FILL" />
            <use xlinkHref="#PlaOut032__Layer5_5_FILL" />
        </g></g>
    )
}

export default PlaOut032
