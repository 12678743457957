import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FF8E8E",
    "col1s": "#C92323"
}

function PetPet007({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M83.6 37.75l-.5-1.3q-.35-.9-.8-1.8v-.25-.85q0-1.25-.1-2.5-.45-5.25-2.7-9.8-.95-1.9-2.15-3.65Q82.65 8.8 81.9 0 71.7-.1 63.2 5.75q-1.7-.85-3.45-1.5-7.65-3-16.8-3-11.75 0-20.95 4.9Q13.55.5 3.55.55q-.8 8.8 4.6 17.6l-.1.2.05.05q-2.05 3.05-3.15 6.45-.9 2.7-1.2 5.65-.05.3-.05.55-.05.25-.05.45-.05.55-.05 1.1V34.4q-.5 1.05-.9 2.05l-.5 1.3q-1.5 3.9-1.95 7.5-.3 2.05-.25 3.95.15 9.55 8 16.6l.15.15V78.1h13.6v-3.8q2.6 1.2 5.2 2.1 1.6.6 3.25 1.05 12.65 4.2 25.35 0 1.65-.45 3.3-1.05 2.6-.9 5.2-2.1v3.8h13.6V65.95l.15-.15q7.85-7.05 8.05-16.6.05-1.9-.25-3.95-.5-3.6-2-7.5z"
                    id="PetPet007__Symbol_79_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M16.35 4.85l-.1-.2Q10.55 1 3.8.55 4.1 6 7.85 12q1.9-2.15 4.35-4.15 2-1.65 4.15-3M41.7 28.8q-.2-.05-.4-.05h-.15q-.4-.05-.7-.05-.45 0-.9.05h-.1q-.25 0-.45.05-4.15.35-7.25 3.05-3.35 3-3.55 7.15-.05.25-.05.55v.5q.05.9.25 1.8.55 2.6 2.6 4.65l-.65.45q-3.5 2.35-6.5 3.85-.15 0-.25.1-8.35 3.95-12.5.7-3.35-3.05-5.55-6.45-1.1-1.6-1.85-3.3-.45-.95-.8-1.9Q1.25 35.7 1.15 31q-.5 1.05-.9 2.05L0 34.4q.45 3.6 1.85 6.3.25.55.5 1.15 1.45 2.95 3.2 5.35Q8 50.55 11.1 52.75h.05q4.1 3.25 12.45-.7.1-.1.35 0 7.8 3.55 16.2 3.65 4.05.15 6.7-.5 4.55-.8 9-2.65.5-.25 1.05-.5.25-.1.4 0l.3.1q8.1 3.75 12.15.6 3.1-2.2 5.6-5.55 1.75-2.4 3.2-5.35.25-.6.55-1.15 1.35-2.7 1.8-6.3l-.25-1.35q-.35-.9-.8-1.8-.05-.15-.1-.25-.05 1.5-.25 2.95-.4 3.1-1.5 6-.35.95-.8 1.9-.8 1.7-1.85 3.3-2.25 3.4-5.6 6.45-4.05 3.2-12.15-.55-.15-.1-.3-.15-.15-.1-.25-.1-3-1.5-6.5-3.85l-.65-.45-.55.55 6.45 4.25q-7.95 3.4-15.65 3.45-7.45-.2-15.05-3.45l6.45-4.25.2.2q3.6 3.15 8.7 3.15 5.05 0 8.6-3.15.15-.15.3-.25.25-.25.55-.5.3-.35.55-.7 1.5-1.8 2-3.95.25-1.1.25-2.3v-.15-.1q0-.45-.05-.9-.45-3.8-3.6-6.55-3.1-2.75-7.35-3.05m-.45.65h.05q.1 0 .3.05h.05q4 .3 6.95 2.9 2.95 2.55 3.3 6.05v.05q.05.35.1.8v.25q-.05 1.2-.3 2.3-.45 1.75-1.55 3.25-.65.85-1.55 1.6-3.4 3-8.15 3-4.85 0-8.25-3-.05 0-.05-.05-.3-.25-.5-.55-1.95-1.9-2.5-4.25-.25-.95-.3-1.95v-.35q0-.2.05-.45.15-3.9 3.3-6.7 2.95-2.6 6.9-2.9.15-.05.35-.05.5-.05 1 0 .35-.05.8 0m31.4-18q3.75-6 4.1-11.45-6.9.45-12.65 4.25 2.15 1.35 4.25 3.05 2.4 1.95 4.3 4.15z"
                    id="PetPet007__Symbol_48_0_Layer0_0_FILL"
                />
                <g id="PetPet007__Layer4_0_FILL">
                    <path d="M183.05 365.15v2.65h13.6v-2.65h-13.6m-16.55-38.1V327q-.5-1.6-1.15-1.6-.15 0-.25.1-.55.25-.9 1.5-.5 1.65-.45 3.95-.05 1.05.1 2 .1 1.05.35 1.9 0 .1.05.25h.05v.15q.1.3.25.55.25.45.55.6.1.05.25.05.65 0 1.15-1.6.15-.55.3-1.2l.1-.7q.1-.95.1-2 0-.6-.05-1.15-.1-1.55-.45-2.75m-25.75 38.1h-13.6v2.65h13.6v-2.65m19.3-37.9q-.05-.1-.05-.25-.5-1.6-1.15-1.6-.2 0-.3.1-.4.15-.7.9-.1.25-.2.6-.45 1.65-.45 3.95 0 1.05.1 2 .1.9.3 1.7.05.1.05.2.05.25.15.5.35.85.75 1.05.1.05.2.05h.1q.5 0 .9-1 .1-.3.25-.6.1-.35.2-.75.05-.45.1-.85 0-.15.05-.3v-.05q.1-.9.1-1.95 0-2.15-.4-3.7m10.9-12.25q0-1-.65-1.65-.65-.65-1.6-.65-.95 0-1.65.65-.65.65-.65 1.65 0 .95.65 1.6.7.65 1.65.65t1.6-.65q.65-.65.65-1.6m-14.5-1.65q-.65-.65-1.6-.65-.95 0-1.6.65-.65.65-.65 1.65 0 .95.65 1.6.65.65 1.6.65.95 0 1.6-.65.65-.65.7-1.6-.05-1-.7-1.65z" />
                    <path
                        fill="#FFF"
                        d="M161.55 308.6q-.55-1.75-1.45-3.4-2.8-5.15-8.05-7.2-.2-.1-.35-.15-.9-.3-1.85-.5-6.9-1.75-13.2 2.05-3.55 2.1-5.7 5.25-1.5 2.2-2.35 4.85-.15.4-.3.85-2.1 7.15 1.25 13.4 1.8 3.25 4.6 5.25.35.3.75.55 2.2 1.4 4.95 2.1 4.6 1.1 9-.2.2-.1.45-.15.15-.05.3-.15V331v-.35q0-.3.05-.55.2-4.15 3.55-7.15 3.1-2.7 7.25-3.05.2-.05.45-.05h.1q.25-.65.45-1.3.15-.6.3-1.1 0 .15.1.3.3 1.1.75 2.1h.15q.2 0 .4.05 4.25.3 7.35 3.05 3.15 2.75 3.6 6.55.05.45.05.9v.1l.05.05q.1 0 .25.05 2.25.7 4.6.75 2.15 0 4.4-.5 2.7-.7 4.85-2.1.4-.3.8-.55 2.8-2.05 4.6-5.3 3.05-5.65 1.6-12.05l-.3-1.3-.2-.6q-.95-2.8-2.5-5.05-2.2-3.15-5.65-5.3-3.85-2.3-7.9-2.55-2.65-.2-5.35.45-.95.2-1.8.5-4 1.4-6.6 4.6-1 1.25-1.85 2.75-1.1 2.05-1.6 4.25m-6.7 4.1q.95 0 1.6.65.65.65.7 1.65-.05.95-.7 1.6-.65.65-1.6.65-.95 0-1.6-.65-.65-.65-.65-1.6 0-1 .65-1.65.65-.65 1.6-.65m15.45.65q.65.65.65 1.65 0 .95-.65 1.6-.65.65-1.6.65-.95 0-1.65-.65-.65-.65-.65-1.6 0-1 .65-1.65.7-.65 1.65-.65t1.6.65z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#PetPet007__Symbol_79_0_Layer0_0_FILL"
                transform="translate(119 287.7)"
                id="PetPet007__col1n"
            />
            <use
                xlinkHref="#PetPet007__Symbol_48_0_Layer0_0_FILL"
                transform="translate(121.45 291.1)"
                id="PetPet007__col1s"
            />
            <use xlinkHref="#PetPet007__Layer4_0_FILL" />
        </g></g>
    )
}

export default PetPet007
