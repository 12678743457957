import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function ShiMot083({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M20.15 2.35q-1.43-.622-2.95-1.2Q15.905.552 14.75.7q-1.32.204-2.45 1.4L9.8 6.3l6.55 3.15 3.95-7q-.07-.032-.15-.1m16.6 18.55q.73-2.11.05-3.85-.631-1.119-1.4-2.2L26.15 24q1.05 2.85 3.9 3.65l6.7-6.75m0 1.95l-.05-.05-6.65 6.6q-3.95-1.15-5.1-4.5L22.2 35.3Q19.65 24 9.95 19.55l1.75-3.5q6.1 3.35 9.05-1.5.4-1.35-1-2l-.2-.1L9.35 7.7q-1.6-.85-2.4.35l-4.2 6.1Q-1.4 19 .7 22.6l.2.3 10.35 14.5-2 14.4h20.7V39.65l5.25-10.2q1.5-3.25 1.55-6.6M26.1 39.65q-1.177 1.523-3.15 2.25L21 50.3l-.2-8.5q-2.155-1.482-3.2-3.3 3.6 2.4 8.5 1.15M21.25 11.8l.5.25q.85.85.65 2.15v.1l-.35 1.6 6.2-8.45q-.077-.062-.15-.15-2.924-2.315-6.15-4.05-.172-.058-.35-.15l-4.05 6.95 3.7 1.75m8.1-3.5h-.05l-8.15 10.95q.7 2.85 4.15 3.9l9.3-9.4q-2.25-2.85-5.25-5.45z"
                    id="ShiMot083__Symbol_46_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot083__Symbol_46_0_Layer0_0_FILL"
                transform="matrix(.92146 0 0 .92146 174.95 260.5)"
                id="ShiMot083__col1n"
            />
        </g></g>
    )
}

export default ShiMot083
