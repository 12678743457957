import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#999999",
    "col1n": "#BFBFBF"
}

function ObjToo003({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M18.9 90.45l-.2.1h-.05q-1.1.45-2.15.65v.25q0 .35.05.7.25 2.8 1.4 4.3 1.4 1.75 4.35 1.35 5.45-2.05 2.2-8.6-.75-2.3-1.45-4.65-.95 3.2-2.55 4.8-.75.7-1.6 1.1m-3.45-43.9L6.95 1.8v-.05Q6.1-.45 4.6.15 2.05.4.6 8.7q-2.55 18.5 5.9 34.85.4.75.85 1.6.4.7 1.9 5.05.55 1.9 1.35 4.85.4 1.7.85 3.45.35 1.4.7 2.9.7 3.2 1.4 6.7 1.55.2 3.25.75 1.4.6 2.6 1.65-1.3-6-2.25-12.35-.05-1.35-.75-4.7-.65-3.35-.95-6.9z"
                    id="ObjToo003__Symbol_163_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M8.15 44.4q.45 2.75.5 2.25.8 2.3 2.85 3.05-1.4-1.6-1.95-3.45T9 44.1v-.05q-.05.05-.1.05l-.2.1h-.05l-.5.2M1.35 2.2Q1.6 1 2.95 0-.35.9.05 3l1.2 4.45Q3.2 14.9 4.55 21.9q.55.1 1.15.3-.6-2.95-1.25-5.8-.25-.85-.45-1.7-.05-.15-.05-.25v-.05q-2.35-9-2.6-12.2z"
                    id="ObjToo003__Symbol_117_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo003__Symbol_163_0_Layer0_0_FILL"
                transform="translate(101.95 219.15)"
                id="ObjToo003__col1n"
            />
            <use
                xlinkHref="#ObjToo003__Symbol_117_0_Layer0_0_FILL"
                transform="translate(111.95 265.5)"
                id="ObjToo003__col1d"
            />
        </g></g>
    )
}

export default ObjToo003
