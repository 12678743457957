import { AssetSvgProps } from "../../../shared/assets"

function ShiMot059({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFF"
                    d="M159.2 275.75v17.85h66.5v-17.85q-33.25-7.993-66.5 0z"
                    id="ShiMot059__Layer5_0_FILL"
                />
                <path
                    fill="red"
                    d="M159.2 257.85v17.9h66.5v-17.9h-66.5z"
                    id="ShiMot059__Layer5_1_FILL"
                />
            </defs>
            <use xlinkHref="#ShiMot059__Layer5_0_FILL" />
            <use xlinkHref="#ShiMot059__Layer5_1_FILL" />
        </g></g>
    )
}

export default ShiMot059
