import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}

function FacMou052({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M12.75 12.9q2.2-2.2 2.2-5.35 0-3.15-2.2-5.35Q10.6 0 7.5 0T2.2 2.2Q0 4.4 0 7.55q0 3.15 2.2 5.35 2.2 2.2 5.3 2.2 3.1 0 5.25-2.2z"
                    id="FacMou052__Symbol_77_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou052__Symbol_77_0_Layer0_0_FILL"
                transform="translate(185 216.45)"
                id="FacMou052__col1n"
            />
        </g></g>
    )
}

export default FacMou052
